import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import './index.css';
import 'antd/dist/antd.css';
import './assets/css/style.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import '../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-bnb-gallery/dist/style.css';
import '../node_modules/antd-table-infinity/index.css';

import * as serviceWorker from './serviceWorker';
import App from './app.jsx';

// import GoogleMap from './components/google-map/google-map';
// import OrganizationTree from './pages/report/organization-tree';
// import list from './pages/report/organization-mock-data.json';
// import OrganizationComponentTest from './pages/report/organization-component-test';
//import MapView from './pages/monitor-v1/map-view-bak/map-vew';
//import TaskDetailTracking from './pages/monitor-v1/component/tracking-details';
//import Assignee from './pages/monitor-v1/component/assignee-details';
//import AssigneeTaskDetails from './pages/monitor-v1/component/assignee-task-details';
//import AssigneeViewMap from './pages/monitor-v1/component/map-tabs';
// import SubTaskTabs from './pages/monitor-v1/component/sub-task-tabs';
//import SubTaskTabs from './pages/monitor-v1/component/sub-task-tabs-bak';

// import AddUser from '../../kg-wfm-frontend/src/pages/users/user/add-user/index';
// import Print from './components/print/index';

//hide log on production
if (process.env.NODE_ENV === 'production') {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root')
);

serviceWorker.register();
