import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Card, Spin, Row, Col, Button, Icon } from 'antd';
import Provider from './provider';
import Details from './details';
import Member from './member/index';
import Shift from './shift/view/index';
import ShiftV1 from './shift-v1/index';
import ShiftNew from './shift-v1/index_New';
import Services from './services/index';
import cssStyle from './css/index.css';
import { Tabs } from 'antd';
import { PageSettings } from '../../config/page-settings';
import ShiftSetting from './shift-setting/index';
import styled from 'styled-components';
import { useQuery } from '../../helpers/general-helper';
import _ from 'lodash';
import ShiftV2 from './shift-v2/index';
import { StickyContainer, Sticky } from 'react-sticky';

const { TabPane } = Tabs;

export default (props) => {
  const query = useQuery();
  const app = Provider.useAppContext();

  const [key, setKey] = useState(query.get('tab') ? query.get('tab') : undefined);
  const [tabsDisabled, setTabsDisabled] = useState(false);

  // console.log('key:::: ', key);

  const handleChangeTab = (key) => {
    setKey(key);
    app.fnc.setKeyDetail(key);
    app.fnc.setKeyTabsMain(key);
    app.fnc.setTriggerTabChange(!app.state.triggerTabChange);
  };

  useEffect(() => {
    if (key != undefined && key != 'details' && app.state.addNewOrganization === false) {
      setKey(key);
    } else {
      setKey('details');
    }
  }, [app.state.orgId]);

  useEffect(() => {
    if (props.newForm) {
      setTabsDisabled(true);
    } else {
      setTabsDisabled(false);
    }
  }, [props.newForm]);

  const handleRenderTopBar = (props, DefaultTabBar) => (
    <Sticky bottomOffset={80}>
      {({ style }) => (
        <Row>
          <Col span={1} className="col-button-hide">
            <Button
              className="button-disabled-summary"
              style={{ border: 'unset' }}
              // disabled={keyTabMonitor !== 'listView' ? false : true}
              // onClick={handleVisibleSummary}
            >
              <Icon
                type="menu-unfold"
                // type={statusSummary ? "menu-unfold" : "menu-fold"}
                style={{ color: '#e73845' }}
              />
            </Button>
          </Col>
          <Col span={23}>
            <DefaultTabBar {...props} style={{ ...style, zIndex: 1, background: '#fff' }} />
          </Col>
        </Row>
      )}
    </Sticky>
  );

  return (
    <PageSettings.Consumer>
      {({ checkPermissionAction }) =>
        checkPermissionAction('P3PG1C1', 'P3PG1C1A1') &&
        (checkPermissionAction('P3PG1C2', 'P3PG1C2A1') ||
          checkPermissionAction('P3PG1C5', 'P3PG1C5A1') ||
          checkPermissionAction('P3PG1C6', 'P3PG1C6A1') ||
          checkPermissionAction('P3PG1C8', 'P3PG1C8A1') ||
          checkPermissionAction('P3PG1C7', 'P3PG1C7A1')) ? (
          <Spin spinning={app.state.loading}>
            <div
              style={{
                minHeight: 'calc(100vh - 112px)',
                padding: '0px',
                backgroundColor: '#ffffff',
                border: '1px solid #e8e8e8',
              }}
            >
              <StickyContainer>
                <Tabs
                  className="organization-tabs"
                  activeKey={key}
                  onChange={handleChangeTab}
                  size="large"
                  animated={false}
                  renderTabBar={handleRenderTopBar}
                >
                  {checkPermissionAction('P3PG1C2', 'P3PG1C2A1') ? (
                    <TabPane tab={<FormattedMessage id="orgTabDetail" defaultMessage="Detail" />} key="details">
                      <Details keyTab={key} newForm={props.newForm} />
                    </TabPane>
                  ) : null}
                  {checkPermissionAction('P3PG1C5', 'P3PG1C5A1') ? (
                    <TabPane tab={<FormattedMessage id="orgTabMember" defaultMessage="Member" />} key="member" disabled={tabsDisabled}>
                      <Member keyTab={key} comId={app.state.comId} orgId={app.state.orgId} />
                    </TabPane>
                  ) : null}
                  {checkPermissionAction('P3PG1C6', 'P3PG1C6A1') ? (
                    <TabPane tab={<FormattedMessage id="orgTabService" defaultMessage="Service" />} key="services" disabled={tabsDisabled}>
                      <Services keyTab={key} comId={app.state.comId} orgId={app.state.orgId} />
                    </TabPane>
                  ) : null}
                  {checkPermissionAction('P3PG1C8', 'P3PG1C8A1') ? (
                    <TabPane
                      tab={<FormattedMessage id="orgTabShiftSetting" defaultMessage="Shift Setting" />}
                      key="shiftSetting"
                      disabled={tabsDisabled}
                    >
                      <ShiftSetting keyTab={key} comId={app.state.comId} orgId={app.state.orgId} />
                    </TabPane>
                  ) : null}
                  {checkPermissionAction('P3PG1C7', 'P3PG1C7A1') ? (
                    <TabPane tab={<FormattedMessage id="orgTabShift" defaultMessage="Shift" />} key="shift" disabled={tabsDisabled}>
                      {/* <Shift
                      keyTab={key}
                      resources={app.state.members || []}
                      shifts={app.state.shifts}
                      key={key}
                      orgId={app.state.orgId}
                      refresh={app.state.refresh}
                    /> */}
                      {/* <ShiftV1 tabskey={key} orgIdSelect={props.orgIdSelect} refreshMain={refreshMain} refreshSaveFromMain={refreshSaveFromMain} /> */}
                      {/* <ShiftNew tabskey={key} orgIdSelect={props.orgIdSelect} refreshMain={refreshMain} refreshSaveFromMain={refreshSaveFromMain} /> */}
                      <ShiftV2
                        comId={app.state.comId}
                        orgId={app.state.orgId}
                        orgDetail={app.state.organizationsDetail}
                        shiftType={app.state.shiftsType}
                      />
                    </TabPane>
                  ) : null}
                </Tabs>
              </StickyContainer>
            </div>
          </Spin>
        ) : null
      }
    </PageSettings.Consumer>
  );
};
