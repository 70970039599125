import React from 'react';
import { Checkbox, Col, DatePicker, Divider, Form, Icon, Input, Modal, Row, Select, Table, TimePicker, Upload } from 'antd';
import { FormattedMessage } from 'react-intl';
import LabeRequireForm from '../label-required-form';
import Button_02 from '../v2/button_02';
import Button_01 from '../v2/button_01';
import _ from 'lodash';
import './index.css';
const { Option } = Select;
const parcelModalReOrder = ({
  form,
  title,
  visible,
  setVisible,
  trigger,
  setTrigger,
  subTaskWfId,
  intl,
  checkedResend,
  setCheckedResend,
}) => {
  const { getFieldDecorator, validateFields, resetFields, getFieldsValue, getFieldValue, setFieldsValue } = form;
  const status = getFieldValue('status');
  const handleSave = () => {
    validateFields(async (err, values) => {
      if (err) {
        return;
      }

      try {
        setVisible(false);
        setTrigger((prev) => !prev);
        resetFields();
      } catch (error) {}
    });
  };

  const handleCancel = () => {
    setVisible(false);
    resetFields();
  };

  const handleResendOrder = (e) => {
    console.log('handleResendOrder', e.target.checked);
    const checked = e.target.checked;
    setCheckedResend(checked);
  };

  const TitleCreateResendOrder = () => {
    return (
      <div style={{ display: 'flex' }}>
        <div style={{ paddingRight: '10px' }}>
          <Checkbox value={checkedResend} onChange={handleResendOrder} />
        </div>
        <span>{title}</span>
      </div>
    );
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
      render: (text, record, index) => index + 1,
    },
    {
      title: intl.formatMessage({ id: 'paecelFormTextTrackingNo', defaultMessage: 'Tracking No.' }),
      dataIndex: 'trackingNo',
      key: 'trackingNo',
      sorter: (a, b) => a.trackingNo.localeCompare(b.trackingNo),
      sortDirections: ['descend', 'ascend'],
      width: 200,
    },
    {
      title: intl.formatMessage({ id: 'paecelFormTextRemark', defaultMessage: 'Remark' }),
      dataIndex: 'remark',
      key: 'remark',
    },
  ];

  return (
    <Modal
      title={<TitleCreateResendOrder />}
      visible={visible}
      onOk={handleSave}
      onCancel={handleCancel}
      width={600}
      bodyStyle={{ padding: '0px' }}
      footer={[
        <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleCancel}>
          <FormattedMessage id="userDetailModalBtnCancel" defaultMessage="Cancel" />
        </Button_02>,
        <Button_01 key="submit" type="primary" btnsize="wd_df" onClick={handleSave}>
          <FormattedMessage id="userDetailModalBtnSave" defaultMessage="Save" />
        </Button_01>,
      ]}
    >
      <Form>
        <div style={{ padding: '16px 24px 16px' }}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item>
                <span className="text-style">
                  <FormattedMessage id="paecelFormTextDate" defaultMessage="Date" />
                  <LabeRequireForm req={true} />
                </span>
                {getFieldDecorator('date', {
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'paecelFormTextValidateDate', defaultMessage: 'Please Select Date' }),
                    },
                  ],
                })(
                  <DatePicker
                    style={{ width: '100%' }}
                    placeholder={intl.formatMessage({ id: 'paecelFormTextPlaceholderDate', defaultMessage: 'Select Date' })}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item>
                <span className="text-style">
                  <FormattedMessage id="paecelFormTextTime" defaultMessage="Time" />
                  <LabeRequireForm req={true} />
                </span>
                {getFieldDecorator('time', {
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'paecelFormTextValidateTime', defaultMessage: 'Please Select Time' }),
                    },
                  ],
                })(
                  <TimePicker
                    style={{ width: '100%' }}
                    placeholder={intl.formatMessage({ id: 'paecelFormTextPlaceholderTime', defaultMessage: 'Select Time' })}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item>
                <span className="text-style">
                  <FormattedMessage id="paecelFormTextStatus" defaultMessage="Status" />
                  <LabeRequireForm req={true} />
                </span>
                {getFieldDecorator('status', {
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'paecelFormTextValidateStatus', defaultMessage: 'Please Select Status' }),
                    },
                  ],
                })(
                  <Select
                    placeholder={intl.formatMessage({ id: 'paecelFormTextPlaceholderStatus', defaultMessage: 'Select Status' })}
                    autoComplete="off"
                  >
                    <Option value={'1'}>Status 1</Option>
                    <Option value={'2'}>Status 2</Option>
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item>
                <span className="text-style">
                  <FormattedMessage id="paecelFormTextReason" defaultMessage="Reason" />
                  <LabeRequireForm req={true} />
                </span>
                {getFieldDecorator('reason', {
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'paecelFormTextValidateReason', defaultMessage: 'Please Select Reason' }),
                    },
                  ],
                })(
                  <Select
                    placeholder={intl.formatMessage({ id: 'paecelFormTextPlaceholderReason', defaultMessage: 'Select Reason' })}
                    autoComplete="off"
                    disabled={status ? false : true}
                  >
                    <Option value={'1'}>Reason 1</Option>
                    <Option value={'2'}>Reason 2</Option>
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item>
                <span className="text-style">
                  <FormattedMessage id="paecelFormTextRemark" defaultMessage="Remark" />
                </span>
                {getFieldDecorator(
                  'remark',
                  {}
                )(
                  <Input
                    placeholder={intl.formatMessage({ id: 'paecelFormTextPlaceholderRemark', defaultMessage: 'Enter Remark' })}
                    autoComplete="off"
                  ></Input>
                )}
              </Form.Item>
            </Col>
          </Row>
        </div>

        <span style={{ padding: '0px 24px', fontSize: '14px', fontWeight: 500, color: '#1D3557' }}>
          <FormattedMessage id="paecelFormTextReturntostore" defaultMessage="Return to Store" />
        </span>
        <Divider style={{ margin: '12px 0px' }} />
        <div style={{ padding: '0px 24px 16px' }}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item>
                <span className="text-style">
                  <FormattedMessage id="paecelFormTextDate" defaultMessage="Date" />
                  <LabeRequireForm req={true} />
                </span>
                {getFieldDecorator('dateStore', {
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'paecelFormTextValidateDate', defaultMessage: 'Please Select Date' }),
                    },
                  ],
                })(
                  <DatePicker
                    disabled={checkedResend ? false : true}
                    style={{ width: '100%' }}
                    placeholder={intl.formatMessage({ id: 'paecelFormTextPlaceholderDate', defaultMessage: 'Select Date' })}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item>
                <span className="text-style">
                  <FormattedMessage id="paecelFormTextTime" defaultMessage="Time" />
                  <LabeRequireForm req={true} />
                </span>
                {getFieldDecorator('timeStore', {
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'paecelFormTextValidateTime', defaultMessage: 'Please Select Time' }),
                    },
                  ],
                })(
                  <TimePicker
                    disabled={checkedResend ? false : true}
                    style={{ width: '100%' }}
                    placeholder={intl.formatMessage({ id: 'paecelFormTextPlaceholderTime', defaultMessage: 'Select Time' })}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item>
                <span className="text-style">
                  <FormattedMessage id="paecelFormTextStore" defaultMessage="Store" />
                  <LabeRequireForm req={true} />
                </span>
                {getFieldDecorator('reason', {
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'paecelFormTextValidateStore', defaultMessage: 'Please Select Store' }),
                    },
                  ],
                })(
                  <Select
                    placeholder={intl.formatMessage({ id: 'paecelFormTextPlaceholderStore', defaultMessage: 'Select Store' })}
                    autoComplete="off"
                    disabled={checkedResend ? false : true}
                    style={{ width: '100%' }}
                  >
                    <Option value={'1'}>Store 1</Option>
                    <Option value={'2'}>Store 2</Option>
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
        </div>

        <span style={{ padding: '0px 24px', fontSize: '14px', fontWeight: 500, color: '#1D3557' }}>
          <FormattedMessage id="paecelFormTextResendParcel" defaultMessage="Resend Parcel" />
        </span>
        <Divider style={{ margin: '12px 0px' }} />
        <div style={{ padding: '0px 24px 16px' }}>
          <Row gutter={16}>
            <Col span={24}>
              <Table columns={columns} dataSource={[]} pagination={false} />
            </Col>
          </Row>
        </div>
      </Form>
    </Modal>
  );
};
const ParcelReOrderModal = Form.create({
  name: 'modal_parcel_form',
  mapPropsToFields(props) {},
})(parcelModalReOrder);

export default ParcelReOrderModal;
