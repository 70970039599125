import { Button, Col, DatePicker, Form, Input, Row, Select, Tag } from 'antd';
import React, { useContext, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import LabeRequireForm from '../../../components/label-required-form';
import CustomTableComponent from '../components/table';
import CollapseCustom from '../../../components/collapse-custom';
import { messageLabel } from '../../../components/message-component';
import _ from 'lodash';
import { numberFormatDecimal, numberFormatNoDecimal } from '../../../component-function/fnc-number-format-decimal';
import { AccountReceiveContext } from '../context';
import QuotationModal from '../modal/quotation/quotation-modal';
import moment from 'moment';
import SummaryWaiting from '../components/summary-waiting';

const { Option } = Select;
const { RangePicker } = DatePicker;
const dateFormat = 'ddd, MMM DD YYYY';

const CanceledQuotation = () => {
  const { state, setState, func } = useContext(AccountReceiveContext);
  const { isOpenQuotation, reportDataCanceled, summaryData, customerList } = state;
  const { seIsOpenQuotation, setReportDataCanceled } = setState;
  const { handleOpenModalQuotation, handleCustomerPopupScroll } = func;
  const { quotationList, quotationTotal, paginationPage, loading, rangePickerDate } = reportDataCanceled;
  const intl = useIntl();

  const handleFieldSort = (value) => {
    setReportDataCanceled((prev) => ({ ...prev, fieldSort: value }));
  };

  const handleOrderSort = (value) => {
    setReportDataCanceled((prev) => ({ ...prev, orderSort: value }));
  };

  const handlePaginationPage = (value) => {
    setReportDataCanceled((prev) => ({ ...prev, paginationPage: value }));
  };

  const handlePaginationSize = (value) => {
    setReportDataCanceled((prev) => ({ ...prev, paginationSize: value }));
  };

  const handleSelectRangePicker = (value) => {
    setReportDataCanceled((prev) => ({ ...prev, rangePickerDate: value }));
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      fixed: 'left',
      width: 70,
    },
    {
      title: messageLabel('quotationLabelSaleOrderNo', 'Sale Order No.'),
      dataIndex: 'saleOrderNo',
      key: 'saleOrderNo',
      sorter: true,
      fixed: 'left',
      width: 150,
      ellipsis: true,
    },
    {
      title: messageLabel('ARInvoiceNo', 'Invoice No.'),
      dataIndex: 'invoiceNo',
      key: 'invoiceNo',
      sorter: true,
      
      width: 170,
    },
    {
      title: messageLabel('quotationLabelCustomer', 'Customer'),
      dataIndex: 'customerName',
      key: 'customerName',
      sorter: true,
      
      // width: 200,
    },

    {
      title: messageLabel('quotationLabelTotal', 'Total'),
      dataIndex: 'totalTxt',
      key: 'totalTxt',
      sorter: true,
      width: 200,
      align: 'right',
    },
    {
      title: messageLabel('ARRemark', 'Remark'),
      dataIndex: 'remark',
      key: 'remark',
      sorter: true,
      width: 200,
    },
    {
      title: messageLabel('quotationLabelIssuedBy', 'Issued by'),
      dataIndex: 'issueBy',
      key: 'issueBy',
      sorter: true,
      // render: (text, record, index) => '',
    },
    {
      title: messageLabel('quotationLabelIssuedDate', 'Issued Date'),
      dataIndex: 'issueDateTxt',
      key: 'issueDateTxt',
      sorter: true,
      // render: (text, record, index) =>
      //   _.get(record, 'issueDate') ? moment(_.get(record, 'issueDate'), 'YYYY-MM-DD').format('ddd, MMM DD YYYY') : null,
    },
  ];

  const advancedFilter = (action, value) => {
    const handleChange = (value, key) => {
      action((prev) => ({ ...prev, [key]: value }));
    };

    return (
      <Form colon={false}>
        <Row gutter={[24, 2]}>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'quotationLabelSaleOrderNo', defaultMessage: 'Sale Order No.' })}
                  req={false}
                />
              }
            >
              <Input
                allowClear
                value={_.get(value, 'saleOrderNo')}
                onChange={(e) => handleChange(e.target.value, 'saleOrderNo')}
                placeholder={intl.formatMessage({ id: 'quotationHintSaleOrderNo', defaultMessage: 'Enter Sale Order No.' })}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<LabeRequireForm text={intl.formatMessage({ id: 'ARInvoiceNo', defaultMessage: 'Invoice No.' })} req={false} />}
            >
              <Input
                allowClear
                value={_.get(value, 'invoice')}
                onChange={(e) => handleChange(e.target.value, 'invoice')}
                placeholder={intl.formatMessage({ id: 'ARInvoiceNoPalceHolder', defaultMessage: 'Enter Invoice No.' })}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'quotationLabelCustomer', defaultMessage: 'Customer' })} req={false} />
              }
            >
              <Select
                allowClear
                showSearch
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                placeholder={intl.formatMessage({ id: 'VoucherCustomerPlaceHolder', defaultMessage: 'Select Customer' })}
                onPopupScroll={handleCustomerPopupScroll}
                onChange={(e) => handleChange(e, 'customerName')}
              >
                {_.map(customerList, (item, index) => (
                  <Option key={item.customerId} value={item.customerId}>
                    {item.customerName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  };

  const contentAction = (record) => {
    // console.log('content', record);
    return (
      <div style={{ display: 'grid' }}>
        <div>
          <Button style={{ width: 100 }} type="link" ghost onClick={() => handleOpenModalQuotation(record, 'canceled')}>
            <FormattedMessage id="btnView" defaultMessage="View" />
          </Button>
        </div>
        {/* <div>
          <Button style={{ width: 100 }} type="link" ghost onClick={() => handleOpenModalQuotation(record, 'edit')}>
            <FormattedMessage id="btnEdit" defaultMessage="Edit" />
          </Button>
        </div> */}
      </div>
    );
  };

  const summary = {
    balance: _.filter(summaryData, (o) => {
      return o.summaryTypeCode === 'balance';
    }),
    ondue: _.filter(summaryData, (o) => {
      return o.summaryTypeCode === 'ondue';
    }),
    overdue: _.filter(summaryData, (o) => {
      return o.summaryTypeCode === 'overdue';
    }),
  };

  return (
    <div className="warehouse-layout-content warehouse-tabs-details scroll-sm">
      <div style={{ paddingTop: 20 }}>
        <CollapseCustom
          label={intl.formatMessage({ id: `btnFilter`, defaultMessage: 'Filter' })}
          extra={
            <RangePicker
              placeholder={
                intl.formatMessage({ id: 'ARStartDate', defaultMessage: 'Start Date' }) +
                ' ~ ' +
                intl.formatMessage({ id: 'AREndDate', defaultMessage: 'End Date' })
              }
              value={rangePickerDate}
              format={dateFormat}
              onChange={handleSelectRangePicker}
              allowClear={true}
            />
          }
        >
          {advancedFilter(setReportDataCanceled, reportDataCanceled)}
        </CollapseCustom>
      </div>
      <div className="content-body-report">
        <CustomTableComponent
          rowKey={(record) => record.arId}
          columns={columns}
          dataSource={quotationList}
          scroll={{ x: true }}
          role={true}
          onRow={true}
          total={quotationTotal}
          loading={loading}
          paginationPage={paginationPage}
          setFieldSort={handleFieldSort}
          setOrderSort={handleOrderSort}
          setPaginationSize={handlePaginationSize}
          setPaginationPage={handlePaginationPage}
          contentAction={contentAction}
        />
      </div>
    </div>
  );
};

export default CanceledQuotation;
