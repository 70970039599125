import React from 'react'
import { Checkbox, Row, Col } from 'antd';
import '../../css/index.css'
import { FormattedMessage } from 'react-intl';
import _ from 'lodash'
import Nodata from '../../../image/Nodata.svg'

const SearchWarehouse = (props) => {
  const { warehouse } = props

  const handleCheckboxChange = (checkedList) => {
    warehouse.setWarehouseSelect(checkedList);
    warehouse.setWarehouseInterminate(!!checkedList.length && checkedList.length < warehouse.warehouseData.length);
    warehouse.setWarehouseCheckAll(checkedList.length === warehouse.warehouseData.length);
  };

  const handleCheckAllChange = (e) => {
    warehouse.setWarehouseSelect(e.target.checked ? _.map(warehouse.warehouseData, (item) => { return item.warehouseId }) : []);
    warehouse.setWarehouseInterminate(false);
    warehouse.setWarehouseCheckAll(e.target.checked);
  };

  return (
    <div className='searchItemRequestContent'>
      <div className='searchItemRequestContentHeader'>
        <div>
          <span>{_.size(warehouse.warehouseSelect)} <FormattedMessage id="textItem" defaultMessage="item" /></span>
        </div>
        <div>
          <span><FormattedMessage id="searchItemRequestTextWarehouse" defaultMessage="Warehouse" /></span>
        </div>
      </div>
      <div className='searchItemRequestContentCheckAll'>
        <Checkbox
          indeterminate={warehouse.warehouseInterminate}
          onChange={handleCheckAllChange}
          checked={warehouse.warehouseCheckAll}
        >
          <FormattedMessage id="searchItemRequestTextWarehouse" defaultMessage="Warehouse" />
        </Checkbox>
      </div>
      <div style={{ maxHeight: '360px', overflowY: _.size(warehouse.warehouseData) < 9 ? 'hidden' : 'scroll' }}>
        {
          _.size(warehouse.warehouseData) > 0 ? (
            <Checkbox.Group
              style={{ width: '100%' }}
              value={warehouse.warehouseSelect}
              onChange={handleCheckboxChange}
            >
              {
                _.map(warehouse.warehouseData, (item) => (
                  <Row className='searchItemRequestContentDetail' style={{ paddingTop: '12px' }}>
                    <Col span={24}>
                      <Checkbox value={item.warehouseId}>{item.warehouseName}</Checkbox>
                    </Col>
                  </Row>
                ))
              }
            </Checkbox.Group>
          ) : (
            <div className='searchItemRequestContentNoData'>
              <div>
                <img src={Nodata} style={{ width: '100px' }}></img>
              </div>
              <div style={{ color: '#e4e4e4' }}>
                <FormattedMessage id="searchItemRequestTextWarehouseNoData" defaultMessage="No Warehouse" />
              </div>
            </div >
          )
        }
      </div >
    </div >
  )
}

export default SearchWarehouse
