import React, { useContext } from 'react';
import './css/index.css';
import { Input, Menu, Icon, Row, Col, Popover, Button, Divider } from 'antd';
import { CompanyLocation } from './context';
import { useIntl } from 'react-intl';
import { FormattedMessage } from 'react-intl';
import _, { debounce } from 'lodash';
import Button_01 from '../v2/button_01';

export default () => {
  const { companyState, companySetState, companyFNC } = useContext(CompanyLocation);
  const intl = useIntl();

  const onChange = (value) => {
    console.log('debount value => ', value);
    companyFNC.handleSearchLocation(value);
    // companySetState.setSearchValue(value)
  };

  const debountcedOnChange = debounce(onChange, 900);

  console.log('adddddddddress', companyState.addressData, _.get(companyState, 'keyMenu[0]'));
  const renderMenu = () =>
    companyState.addressData &&
    companyState.addressData.map((item) => (
      <Menu.Item className="location-company-detail-div-map" key={item.addressBookId} title={item.address}>
        <div>
          <Row>
            <Col span={23} className="location-company-detail-address-name-col">
              <span
                className={
                  item.addressBookId === _.get(companyState, 'keyMenu[0]')
                    ? 'location-company-detail-address-name2'
                    : 'location-company-detail-address-name'
                }
              >
                {item.name}{' '}
                {item.status === 'normal_address' ? `(${intl.formatMessage({ id: 'unsaveText', defaultMessage: 'Unsaved' })})` : `(${intl.formatMessage({ id: 'saveText', defaultMessage: 'Saved' })})`}
              </span>
            </Col>
            <Col span={1}>
              {/* {item.status === 'customer_address' ? (
                <Popover
                  key={item.addressBookId}
                  placement="leftTop"
                  trigger="hover"
                  content={
                    <div style={{ display: 'grid' }}>
                      <Button style={{ width: 100 }} type="link" ghost onClick={() => companyFNC.handleOpenEdit(item)}>
                        <FormattedMessage id="btnEdit" defaultMessage="Edit" />
                      </Button>
                      <Button style={{ width: 100 }} type="link" ghost onClick={() => companyFNC.deletedAddress(item)}>
                        <FormattedMessage id="btnDelete" defaultMessage="Delete" />
                      </Button>
                    </div>
                  }
                >
                  <Icon type="more" />
                </Popover>
              ) : null} */}
            </Col>
          </Row>
          <div
            className={
              item.addressBookId === _.get(companyState, 'keyMenu[0]')
                ? 'location-company-detail-address2'
                : 'location-company-detail-address'
            }
          >
            {item.address}
          </div>
        </div>
      </Menu.Item>
    ));

  return (
    <>
      <div className="location-company-detail-padding">
        <div className="header-action-address">
          <span className="header-title-action-address">
            <FormattedMessage id="companyInformationTextAddress" defaultMessage="Address" />
          </span>
          <Button_01
            key="submit"
            type="primary"
            style={{ margin: 'unset' }}
            btnsize="wd_df"
            onClick={() => companyFNC.handleCreateRefresh()}
            disabled={false}
          >
            <FormattedMessage id="btnCreate" defaultMessage="Create" />
          </Button_01>
        </div>
      </div>
      <Divider style={{ margin: 'unset' }} />
      <div className="location-company-detail-padding">
        <div>
          <Input
            className="action-report-input-7"
            placeholder={intl.formatMessage({ id: 'hintSearch', defaultMessage: 'Search' })}
            prefix={<Icon style={{ color: 'rgba(0,0,0,.25)' }} type="search" />}
            onChange={(e) => {
              // companySetState.setSearchValue(e.target.value)
              // companyFNC.handleSearchLocation(e.target.value)
              debountcedOnChange(e.target.value);
            }}
            autoComplete="off"
            // value={companyState.searchValue}
            // defaultValue={companyState.searchValue}
          />
        </div>
        <div>
          <Menu
            className="location-company-detail-scroll"
            mode="inline"
            onSelect={companyFNC.handleClickMenu}
            selectedKeys={companyState.keyMenu}
          >
            {renderMenu()}
          </Menu>
        </div>
      </div>
    </>
  );
};
