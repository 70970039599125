import React from 'react'
import Style from './css/add.css'
import { Form, Row, Col } from 'antd'
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';


const ViewFormInsurance = (props) => {
  const { insuranceById } = props
  const data = _.get(insuranceById, 'assetInsuranceInfo')
  const LabeRequire = (props) => {
    const { text, req } = props;
    return (
      <span>
        {text}&nbsp;
        {req ? <span style={{ fontSize: '13px', color: '#ff0000' }}>*</span> : ''}
      </span>
    );
  };

  return (
    <div>
      <Form>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item>
              <p className='formText-formate'><FormattedMessage id="AssetModalTitleFormInsurance" defaultMessage="Insurance" /><LabeRequire req={false} /></p>
              <p className='formText-formate-value'>{_.get(data, 'insuranceName')}</p>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item>
              <p className='formText-formate'><FormattedMessage id="AssetModalTitleFormInsuranceDate" defaultMessage="Insurance Date" /><LabeRequire req={false} /></p>
              <p className='formText-formate-value'>{_.get(data, 'insuranceDateFromTxt')} - {_.get(data, 'insuranceDateToTxt')} </p>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item>
              <p className='formText-formate'><FormattedMessage id="AssetModalTitleFormInsuranceType" defaultMessage="Insurance Type" /><LabeRequire req={false} /></p>
              <p className='formText-formate-value'>{_.get(data, 'insuranceTypeName')}</p>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item>
              <p className='formText-formate'><FormattedMessage id="AssetModalTitleFormInsurancePremium" defaultMessage="Premium" /><LabeRequire req={false} /></p>
              <p className='formText-formate-value'>{_.get(data, 'premium')}</p>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item>
              <p className='formText-formate'><FormattedMessage id="AssetModalTitleFormInsuranceDiscount" defaultMessage="Discount" /><LabeRequire req={false} /></p>
              <p className='formText-formate-value'>{_.get(data, 'discount')}</p>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item>
              <p className='formText-formate'><FormattedMessage id="AssetModalTitleFormInsuranceDiscountPremium" defaultMessage="Premium after discount" /><LabeRequire req={false} /></p>
              <p className='formText-formate-value'>{_.get(data, 'premiumAftDiscount')}</p>
            </Form.Item>
          </Col>
        </Row>

      </Form>
    </div >
  )
}

export default ViewFormInsurance
