import React, { useContext } from 'react';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import Button01 from '../../../components/v2/button_01';
import Button02 from '../../../components/v2/button_02';
import { saveMemberExpense } from '../../../controllers/member-expense/member-expense-api';
import { PageSettings } from '../../../config/page-settings';

const ModalTabs = [
  {
    text: <FormattedMessage id="memberExpenseModalDetail" defaultMessage="Details" />,
    code: 'details',
  },
  {
    text: <FormattedMessage id="memberExpenseModalHistory" defaultMessage="History" />,
    code: 'histiory',
  },
];

const TitleModal = (type) => {
  let title;
  if (type === 'view' || type === 'verify') {
    title = <FormattedMessage id="memberExpenseTitleView" defaultMessage="Member Expense" />;
  } else if (type === 'edit') {
    title = <FormattedMessage id="memberExpenseTitleEdit" defaultMessage="Edit Member Expense" />;
  } else if (type === 'create') {
    title = <FormattedMessage id="memberExpenseTitleCreate" defaultMessage="Create Member Expense" />;
  }
  return title;
};

const FooterModal = (props) => {
  const { data, type, fnc } = props;
  const { handleSave, handleChangeEdit, handleCancel, setTrigger, setDefaultModal, handleChangeStatus, handleReject,mouseOver,handleStatus } = fnc;
  const app = useContext(PageSettings);
  const { checkPermissionAction } = app;
  const permissonEdit = checkPermissionAction('P81PG1C1', 'P81PG1C1A2') 
  const permissonPending = checkPermissionAction('P81PG1C1', 'P81PG1C1A3') 
  const permissonSendApp = checkPermissionAction('P81PG1C1', 'P81PG1C1A4') 
  const permissonPayment = checkPermissionAction('P81PG1C1', 'P81PG1C1A5') 
  const permissonReject = checkPermissionAction('P81PG1C1', 'P81PG1C1A6') 
  const permissonPaid = checkPermissionAction('P81PG1C1', 'P81PG1C1A7') 
  const permissonCancel = checkPermissionAction('P81PG1C1', 'P81PG1C1A8') 
  const marginbutton = { margin: '0px 10px 0px 0px',width: '100px', height: '32px'   }
  const sizeButton = {width: '100px', height: '32px' }
  const sizeButtonRight = {width: '100px', height: '32px',margin: 'unset' }
  
  if (type === 'create') {
    return [
      <Button02 style={sizeButton} key="back" btnsize="wd_df" onClick={() => handleCancel()}>
        <FormattedMessage id="btnClose" defaultMessage="Close" />
      </Button02>,
      <Button01  onClick={() => {handleSave('pending')}} onMouseOver={mouseOver } style={sizeButtonRight} key="draft" type="primary" btnsize="wd_px">
        <FormattedMessage id="btnSave" defaultMessage="Save" />
      </Button01>,
      checkPermissionAction('P81PG1C1', 'P81PG1C1A4') ? (
      <Button01
        onClick={() => {
          handleStatus('waiting',  { statusCode: 'pending' }, handleSave);
        }}
        style={{minWidth: '100px' }}
        onMouseOver={mouseOver}
        key="draft"
        type="primary"
        btnsize="wd_at"
      >
        <FormattedMessage id="btnSendApproval" defaultMessage="Send Approval" />
      </Button01>
      ) : null,
    ];
  } else if (type === 'edit') {
    return [
      <Button02 style={sizeButton} key="back" btnsize="wd_df" onClick={() => handleCancel()}>
        <FormattedMessage id="btnClose" defaultMessage="Close" />
      </Button02>,
      <Button01 style={marginbutton} onClick={() => {handleSave('pending')}} onMouseOver={mouseOver} key="draft" type="primary" btnsize="wd_px">
        <FormattedMessage id="btnSave" defaultMessage="Save" />
      </Button01>,
      permissonSendApp ? (
      <Button01
      onClick={() => {
        handleStatus('waiting',  { statusCode: 'pending' }, handleSave);
      }}
        onMouseOver={mouseOver}
        style={{minWidth: '100px' ,margin: 'unset'}}
        key="draft"
        type="primary"
        btnsize="wd_at"
      >
        <FormattedMessage id="btnSendApproval" defaultMessage="Send Approval" />
      </Button01> ): null,
    ];
  } else if (type === 'verify' || type === 'view') {
    if (_.get(data, 'statusCode') === 'pending' && _.get(data, 'fromAllowance') === true) {
      return [
        <Button02 style={marginbutton} key="back" btnsize="wd_df" onClick={() => handleCancel()}>
          <FormattedMessage id="btnClose" defaultMessage="Close" />
        </Button02>,
         permissonEdit ? (
        <Button01 style={marginbutton} onClick={() => handleChangeEdit('edit')} key="draft" type="primary" btnsize="wd_px">
          <FormattedMessage id="btnEdit" defaultMessage="Edit" />
        </Button01> ): null,
        permissonSendApp ? (
        <Button01
          onClick={() => handleStatus('waiting', data, handleChangeStatus)}
          style={{ minWidth: '100px' , margin: 'unset'}}
          key="draft"
          type="primary"
          btnsize="wd_at"
        >
          <FormattedMessage id="btnSendApproval" defaultMessage="Send Approval" />
        </Button01> ): null,
      ];
    } else if (_.get(data, 'statusCode') === 'pending' && _.get(data, 'fromAllowance') === false) {
      return [
        <Button02 style={marginbutton} key="back" btnsize="wd_df" onClick={() => handleCancel()}>
          <FormattedMessage id="btnClose" defaultMessage="Close" />
        </Button02>,
         permissonCancel ? (
        <Button01
        style={marginbutton}
          key="draft"
          type="primary"
          btnsize="wd_px"
          onClick={() => handleStatus('cancel', data, handleChangeStatus)}
        >
          <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
        </Button01>): null,
         permissonEdit ? (
        <Button01 style={marginbutton} onClick={() => handleChangeEdit('edit')} type="primary" btnsize="wd_px">
          <FormattedMessage id="btnEdit" defaultMessage="Edit" />
        </Button01>): null,
        permissonSendApp ? (
        <Button01
          onClick={() => handleStatus('waiting', data, handleChangeStatus)}
          style={{minWidth: '100px' ,margin: 'unset'}}
          key="draft"
          type="primary"
          btnsize="wd_at"
        >
          <FormattedMessage id="btnSendApproval" defaultMessage="Send Approval" />
        </Button01>): null,
      ];
    } else if (_.get(data, 'statusCode') === 'waiting') {
      return [
        <Button02 style={marginbutton} key="back" btnsize="wd_df" onClick={() => handleCancel()}>
          <FormattedMessage id="btnClose" defaultMessage="Close" />
        </Button02>,
         permissonPending ? (
        <Button01
        style={marginbutton}
          key="draft"
          type="primary"
          btnsize="wd_px"
          onClick={() => handleStatus('pending', { statusCode: 'waiting' }, handleChangeStatus)}
        >
          <FormattedMessage id="btnPending" defaultMessage="Pending" />
        </Button01>): null,
        permissonReject ? (
        <Button01 style={marginbutton} onClick={() => handleReject()} key="draft" type="primary" btnsize="wd_px">
          <FormattedMessage id="btnReject" defaultMessage="Reject" />
        </Button01>): null,
         permissonPayment ? (
        <Button01
          onClick={() => handleStatus('waitingpaid', data, handleChangeStatus)}
          style={sizeButtonRight}
          key="draft"
          type="primary"
         btnsize="wd_px"
        >
          <FormattedMessage id="btnApprove" defaultMessage="Approval" />
        </Button01>): null,
      ];
    } else if (_.get(data, 'statusCode') === 'waitingpaid') {
      return [
        <Button02 style={sizeButton} key="back" btnsize="wd_df" onClick={() => handleCancel()}>
          <FormattedMessage id="btnClose" defaultMessage="Close" />
        </Button02>,
         permissonPending ? (
        <Button01
          style={sizeButtonRight}
          key="draft"
          type="primary"
          btnsize="wd_px"
          onClick={() => handleStatus('pending', { statusCode: 'waitingpaid' }, handleChangeStatus)}
        >
          <FormattedMessage id="btnPending" defaultMessage="Pending" />
        </Button01>): null,
         permissonPaid ? (
        <Button01 style={sizeButton} onClick={() => handleStatus('paid', data, handleChangeStatus)} key="draft" type="primary" btnsize="wd_px">
          <FormattedMessage id="btnPaid" defaultMessage="Paid" />
        </Button01>): null,
      ];
    } else if (_.get(data, 'statusCode') === 'reject') {
      return [
        <Button02 style={sizeButton} key="back" btnsize="wd_df" onClick={() => handleCancel()}>
          <FormattedMessage id="btnClose" defaultMessage="Close" />
        </Button02>,
        permissonPending ? (
        <Button01
          style={sizeButtonRight}
          key="draft"
          type="primary"
          btnsize="wd_px"
          onClick={() => handleStatus('pending', { statusCode: 'reject' }, handleChangeStatus)}
        >
          <FormattedMessage id="btnPending" defaultMessage="Pending" />
        </Button01>): null,
         permissonPayment ? (
        <Button01  style={sizeButton} onClick={() => handleStatus('waitingpaid', data, handleChangeStatus)} key="draft" type="primary" btnsize="wd_px">
          <FormattedMessage id="btnApprove" defaultMessage="Approval" />
        </Button01>): null,
      ];
    } else {
      return [
        <Button02 style={sizeButton} key="back" btnsize="wd_df" onClick={() => handleCancel()}>
          <FormattedMessage id="btnClose" defaultMessage="Close" />
        </Button02>,
      ];
    }
  } else {
    return [];
  }
};

const fncApi = async (type, payload) => {
  if (type === 'create' || type === 'edit') {
    const response = await saveMemberExpense(payload);
    return response;
  }
};

export { ModalTabs, TitleModal, FooterModal, fncApi };
