import React, { useEffect, useState } from 'react';
import { getCustomerLoadMore } from '../../../../controllers/getCustomer/get-customer-new';
import { useIntl } from 'react-intl';
import { Select } from 'antd';
import _, { debounce } from 'lodash';
import httpClient from '../../../../components/axiosClient';
const { Option } = Select;

const comCode = localStorage.getItem('comCode');

const SelectCustomerHook = () => {
  const intl = useIntl();
  const [customerListMaster, setCustomerListMaster] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [customerPage, setCustomerPage] = useState(0);
  const [customerPageSize, setCustomerPageSize] = useState(10);
  const [customerLoading, setCustomerLoading] = useState(false);
  const [customerSelect, setCustomerSelect] = useState();
  const [customerSearch, setCustomerSearch] = useState('');
  const [customerObj, setCustomerObj] = useState();
  const [customerListView, setCustomerListView] = useState();
  const [isCustomerLoadMore, setIsCustomerLoadMore] = useState(true);

  const [loadingContact, setLoadingContact] = useState(false);
  const [contactList, setContactList] = useState([]);
  const [contactSelect, setContactSelect] = useState();
  const [contactObj, setContactObj] = useState();

  const [loadingAddress, setLoadingAddress] = useState(false);
  const [addressList, setAddressList] = useState([]);
  const [addressSelect, setAddressSelect] = useState();
  const [addressObj, setAddressObj] = useState();

  useEffect(() => {
    console.log('useeffect customerSearch',customerSearch, isCustomerLoadMore)

    const getData = async () => {
      const pageStart = customerPage * customerPageSize + 1;
      const pageEnd = customerPage * customerPageSize + 10;

      const data = await getCustomerLoadMore(pageStart, pageEnd, customerSearch);
      console.log('customerSearchx1', data);

      const setCustomerListData = _.map(_.get(data, 'customers'), (o) => {
        return setDataCustomer(o);
      });

      console.log('customerSearchx2', setCustomerListData);

      const customers = customerSearch ? setCustomerListData : _.uniqBy(_.concat(customerList, setCustomerListData), 'customerId');
      console.log('customerSearchx3', customers);

      setCustomerList(customers);
      setCustomerListMaster(customers);
      setCustomerLoading(false);
      setIsCustomerLoadMore(_.size(setCustomerListData) > 0 ? true : false);
    };
    if (isCustomerLoadMore || true) {
      getData();
    }

  }, [customerPage, customerSearch]);

  const setDataCustomer = (customer) => {
    return {
      customerId: _.get(customer, 'customerId'),
      customerName: _.get(customer, 'customerName'),
      customerTaxNo: _.get(customer, 'taxNo'),
    };
  };

  const handleCustomerPopupScroll = (e) => {
    let target = e.target;
    if (!customerLoading && target.scrollTop + target.offsetHeight >= target.scrollHeight - 50) {
      setCustomerLoading(true);
      setCustomerPage(customerPage + 1);
    }
  };
  const handleCustomerChangeSelect = (value) => {
    console.log('handleCustomerSearchChange', value, customerSelect)
    if (value !== customerSelect) {
      const filterCustomer =
        customerListMaster &&
        customerListMaster
          .map((item) => {
            return {
              customerId: item.customerId,
              customerName: item.customerName,
              customerTaxNo: item.customerTaxNo,
            };
          })
          .filter((e) => e.customerId === value);
      console.log('filterCustomer', filterCustomer);
      setCustomerList(customerListMaster);
      setCustomerSelect(value);
      setCustomerListView(filterCustomer);
      setCustomerObj(_.get(filterCustomer, '[0]'));
    }
  };

  const handleCustomerChange = (value) => {
    console.log('handleCustomerChange', value)
    if (!value) {
      setCustomerSelect();
      setCustomerListView([]);
    }
  };


  const resetCustomer = (isOpen) => {
    console.log('resetCustomer', isOpen, customerSearch)
    if(isOpen) {
      setCustomerSearch('');
      // setCustomerList([])
    }
  }

  const handleCustomerSearchChange = (value) => {
    console.log('handleCustomerSearchChange', value);
    setCustomerPage(0);
    setCustomerSearch(value);
  };

  useEffect(() => {
    const getContactData = async () => {
      setLoadingContact(true);
      try {
        const body = {
          pageNumber: 1,
          limit: 100,
          orderBy: 'customerContactName',
          orderType: 'asc',
          searchObj: {
            customerContactName: '',
            customerContactEmail: '',
            customerContactPhone: '',
            position: '',
          },
          customerId: customerSelect,
        };

        const response = await httpClient.post(`/v5/business-partner/manager/company/${comCode}/web/customer-contacts/search`, body);

        if (_.get(response, 'status') == 200) {
          setContactList(_.get(response, 'data.data.list'));
          setLoadingContact(false);
        }
      } catch (error) {}
      setLoadingContact(false);
    };

    if (customerSelect) {
      getContactData();
    }
  }, [customerSelect]);

  const handleSelectContact = (value) => {
    if (value !== contactSelect) {
      const filterContact = contactList && contactList.filter((e) => e.customerContactId === value);
      setContactSelect(value);
      setContactObj(_.get(filterContact, '[0]'));
    }
  };

  useEffect(() => {
    const getAddressData = async () => {
      setLoadingAddress(true);
      try {
        const body = {
          pageNumber: 1,
          limit: 100,
          orderBy: 'addressName',
          orderType: 'asc',
          searchObj: {
            address: '',
            addressName: '',
          },
          customerId: customerSelect,
        };

        const response = await httpClient.post(`/v5/business-partner/manager/company/${comCode}/web/customer-address/search`, body);
        if (_.get(response, 'status') == 200) {
          setAddressList(_.get(response, 'data.data.list'));
          setLoadingAddress(false);
        }
      } catch (error) {}
      setLoadingAddress(false);
    };

    if (customerSelect) {
      getAddressData();
    }
  }, [customerSelect]);

  const handleSelectAddress = (value) => {
    if (value !== addressSelect) {
      const filterAddress = addressList && addressList.filter((e) => e.customerAddressBookId === value);
      setAddressSelect(value);
      setAddressObj(_.get(filterAddress, '[0]'));
    }
  };

  return {
    customerList,
    customerSelect,
    customerLoading,
    handleCustomerPopupScroll,
    handleCustomerChangeSelect,
    handleCustomerChange,
    contactList,
    contactSelect,
    loadingContact,
    handleSelectContact,
    addressList,
    addressSelect,
    loadingAddress,
    handleSelectAddress,
    customerObj,
    setCustomerObj,
    contactObj,
    setContactObj,
    addressObj,
    setAddressObj,
    setCustomerSearch,
    customerSearch,
    handleCustomerSearchChange,
    resetCustomer,
  };
};

export { SelectCustomerHook };
