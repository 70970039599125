import React, { useState, useEffect } from 'react';
import PriceReportV1 from '../../components/price-report/price-report-v1.jsx';
import ActionReportV2 from '../../components/action-report/action-report-v2.jsx';
import CreateSaleOrder from '../../components/create-sale-order/index';
import EditSaleOrder from '../../components/edit-sale-order/index';
import SaleOrderReportTable from './order-report-table.jsx';
import getSaleOrderData from '../../controllers/sale-order/get-sale-order';
import getSaleOrderDataById from '../../controllers/sale-order/get-sale-order-byid.js';
import { useIntl, FormattedMessage } from 'react-intl';
import { Card, Icon, Tag, Popover, Button, Modal } from 'antd';
import _, { debounce } from 'lodash';
import moment from 'moment';
import styles from './css/index.css';
import NumberFormat from 'react-number-format';
import { PageSettings } from '../../config/page-settings';
import Button01 from '../../components/v2/button_01.js';
import ReactToPrint from 'react-to-print';
import { successNotification, errorNotification, warningNotification } from '../../components/v2/notification';
import httpClient from '../../components/axiosClient';
import ViewSaleOrder from '../../components/view-sale-order/index.js';

const today = new Date();
const dateFormat = 'ddd, MMM DD YYYY';
const { confirm } = Modal;

export default function Order(props) {
  const {} = props;
  const intl = useIntl();
  const comCode = localStorage.getItem('comCode');
  const [visiblePopover, setVisiblePopover] = useState(false);

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: 70,
      align: 'center',
    },
    {
      title: intl.formatMessage({
        id: 'saleOrderColumnsOrderNo',
        defaultMessage: 'Order No.',
      }),
      dataIndex: 'orderNo',
      key: 'orderNo',
      width: 250,
      sorter: (a, b) => a.orderNo - b.orderNo,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({
        id: 'saleOrderColumnsCustomerName',
        defaultMessage: 'Customer Name',
      }),
      dataIndex: 'customerName',
      key: 'customerName',
      width: 250,
      sorter: (a, b) => a.customerName - b.customerName,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({
        id: 'saleOrderColumnsTotalItem',
        defaultMessage: 'Total Item',
      }),
      dataIndex: 'totalItem',
      key: 'totalItem',
      align: 'center',
      sorter: (a, b) => a.totalItem - b.totalItem,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({
        id: 'saleOrderColumnsTotalBaht',
        defaultMessage: 'Total (Baht)',
      }),
      dataIndex: 'totalBath',
      key: 'totalBath',
      align: 'right',
      sorter: (a, b) => a.totalBath - b.totalBath,
      sortDirections: ['descend', 'ascend'],
      render: (text, record, index) => (
        <NumberFormat value={parseFloat(record.totalBath).toFixed(2)} displayType={'text'} thousandSeparator={true} />
      ),
    },
    {
      title: intl.formatMessage({
        id: 'saleOrderColumnsCreateDate',
        defaultMessage: 'Create Date',
      }),
      dataIndex: 'createDate',
      key: 'createDate',
      align: 'center',
      sorter: (a, b) => a.createDate - b.createDate,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({
        id: 'saleOrderColumnsStatus',
        defaultMessage: 'Status',
      }),
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      sorter: (a, b) => a.status - b.status,
      sortDirections: ['descend', 'ascend'],
      render: (text, record, index) => {
        if (record.status == 'draft') {
          return (
            <Tag className="tag-status-draft" color="#707070">
              <FormattedMessage id="saleOrderColumnsStatusDraft" defaultMessage="Draft" />
            </Tag>
          );
        } else if (record.status == 'cancel') {
          return (
            <Tag className="tag-status-cancel" color="#9a9999">
              <FormattedMessage id="saleOrderColumnsStatusCancelled" defaultMessage="Cancelled" />
            </Tag>
          );
        } else if (record.status == 'completed') {
          return (
            <Tag className="tag-status-completed" color="#21723e">
              <FormattedMessage id="saleOrderColumnsStatusCompleted" defaultMessage="Completed" />
            </Tag>
          );
        }
      },
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      width: 70,
      render: (text, record, index) => fncActionTable(record, index),
    },
  ];

  const setShowColumn = {
    index: true,
    orderNo: true,
    customerName: true,
    totalItem: true,
    totalBath: true,
    createDate: true,
    status: true,
    action: true,
  };

  const setShowColumnArr = ['index', 'orderNo', 'customerName', 'totalItem', 'totalBath', 'createDate', 'status', 'action'];

  const ignoreColumn = [];
  const newColumns = columns.filter((col) => !ignoreColumn.includes(col.key));
  const ignoreFilterColumns = ['index', 'orderNo', 'customerName', 'action'];
  const ignoreSearchColumns = ['index', 'createDate', 'action'];

  const [dataEditSaleOrder, setDataEditSaleOrder] = useState(null);
  const [loading, setLoading] = useState(false);
  const [saleOrderData, setSaleOrderData] = useState([]);
  const [paginationPage, setPaginationPage] = useState(1);
  const [paginationSize, setPaginationSize] = useState(10);
  const [total, setTotal] = useState();
  const [extraSort, setExtraSort] = useState();
  const [fieldSort, setFieldSort] = useState();
  const [orderSort, setOrderSort] = useState();

  const [dateTrigger, setDateTrigger] = useState('');
  const [fieldChang, setFieldChang] = useState('');
  const [fliterByColumn, setFliterByColumn] = useState('all');
  const [startDate, setStartDate] = useState(moment(today).format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment(today).format('YYYY-MM-DD'));

  const [triggerCreate, setTriggerCreate] = useState(false);
  const [triggerEdit, setTriggerEdit] = useState(false);

  const [defaultShowColumn, setDefaultShowColumn] = useState({
    ...setShowColumn,
  });
  const [listArrayColumns, setListArrayColumns] = useState([...setShowColumnArr]);
  const [textErrorSelectColumn, setTextErrorSelectColumn] = useState('');
  const [newDataColumns, setNewDataColumns] = useState([...newColumns]);
  const [visible, setVisible] = useState(false);
  const [priceReportDetail, setPriceReportDetail] = useState({});
  const [triggerDeleted, setTriggerDeleted] = useState(false);
  const [triggerCancel, setTriggerCancel] = useState(false);

  // --------------------- State Modal Create Order -----------------------------------
  const [visibleModal, setVisibleModal] = useState(false);

  // --------------------- State Modal Edit Order -----------------------------------
  const [visibleEditModal, setVisibleEditModal] = useState(false);

  //---------------------- State Modal View Order ------------------------------------
  const [visbleViewModal, setVisbleViewModal] = useState(false);
  const [viewData, setViewData] = useState();

  useEffect(() => {
    const getApi = async () => {
      setLoading(true);
      const response = await getSaleOrderData({
        fieldChang,
        fliterByColumn,
        startDate,
        endDate,
        fieldSort,
        orderSort,
        paginationPage,
        paginationSize,
      });

      setSaleOrderData(response.data);
      setPriceReportDetail({
        excludeTax: response.excludeTax,
        includeTax: response.includeTax,
        total: response.total,
      });
      setTotal(response.allRows);
      setLoading(false);
    };
    getApi();
  }, [dateTrigger, fieldChang, fliterByColumn, extraSort, triggerCreate, triggerEdit, triggerDeleted, triggerCancel]);

  const modalConfirmDelete = (orderId) => {
    confirm({
      className: 'holiday-modal-confirm',
      icon: 'none',
      title: intl.formatMessage({
        id: 'saleOrderTitleConfirmDeleted',
        defaultMessage: 'Are you sure delete this sale orders ?',
      }),
      okType: 'danger',
      centered: true,
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      okText: intl.formatMessage({
        id: 'settingHolidaysModalBtnConfirm',
        defaultMessage: 'Confirm',
      }),
      cancelText: intl.formatMessage({
        id: 'btnCancel',
        defaultMessage: 'Cancel',
      }),
      async onOk() {
        const body = {
          deleted: true,
        };
        try {
          const response = await httpClient.put(`/v3/order/manager/company/${comCode}/order/updatedata?orderid=${orderId}`, body);
          if (response.status === 200) {
            successNotification(response.data.message);
            setTriggerDeleted((current) => !current);
          }
        } catch (error) {
          return;
        }
      },
      onCancel() {},
    });
  };

  const modalConfirmCancel = (orderId) => {
    confirm({
      className: 'holiday-modal-confirm',
      icon: 'none',
      title: intl.formatMessage({
        id: 'saleOrderTitleConfirmCancel',
        defaultMessage: 'Are you sure cancel sale order ?',
      }),
      okType: 'danger',
      centered: true,
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      okText: intl.formatMessage({
        id: 'settingHolidaysModalBtnConfirm',
        defaultMessage: 'Confirm',
      }),
      cancelText: intl.formatMessage({
        id: 'btnCancel',
        defaultMessage: 'Cancel',
      }),
      async onOk() {
        const body = {
          orderStatus: 'cancel',
        };
        try {
          const response = await httpClient.put(`/v3/order/manager/company/${comCode}/order/updatedata?orderid=${orderId}`, body);
          if (response.status === 200) {
            successNotification(response.data.message);
            setTriggerCancel((current) => !current);
          }
        } catch (error) {
          return;
        }
      },
      onCancel() {},
    });
  };

  const handleViewModal = async (record) => {
    if (record) {
      const response = await getSaleOrderDataById(record.orderId);
      if (response.data) {
        setVisbleViewModal(true);
        setViewData(response.data[0]);
      }
    }
  };

  const handleEditSaleOrder = (record) => {
    if (record) {
      setDataEditSaleOrder(record);
    }
    setVisibleEditModal(true);
  };

  const onChangeSearch = debounce((value) => {
    setFieldChang(value);
  }, 500);

  const onFliterByColumn = (value) => {
    setFliterByColumn(value);
  };

  const onSelectRangePicker = (date, dateString) => {
    setStartDate(moment(date[0]).format('YYYY-MM-DD'));
    setEndDate(moment(date[1]).format('YYYY-MM-DD'));
    setDateTrigger(dateString);
  };

  const handleCreateOrder = async () => {
    setVisibleModal(true);
  };

  const handleChange = (pagination, sorter, extra) => {
    let field = extra.field;
    if (extra.order) {
      if (extra.order === 'descend') {
        setOrderSort('desc');
      } else {
        setOrderSort('asc');
      }
    } else {
      setOrderSort();
    }
    setExtraSort(extra);
    setFieldSort(field);
  };

  const handlePagination = (page) => {
    setPaginationPage(page);
  };

  const handleSizeChange = (current, size) => {
    setPaginationSize(size);
    setPaginationPage(current);
  };

  const checkedValuecolumns = (e) => {
    let checked = e.target.checked;
    let val = e.target.value;
    let showColumn = { ...defaultShowColumn };
    if (checked === true) {
      const found = listArrayColumns.find((element) => element === val);
      if (found === undefined) {
        setListArrayColumns([...listArrayColumns, val]);
      }
      showColumn[val] = true;
    } else {
      const newListArray = [...listArrayColumns];
      const found = listArrayColumns.findIndex((element) => element === val);
      if (found >= 0) {
        newListArray.splice(found, 1);
        setListArrayColumns(newListArray);
      }
      showColumn[val] = false;
    }
    setDefaultShowColumn(showColumn);
  };

  const handleCheckOk = () => {
    const setNewArr = [];
    if (listArrayColumns.length <= 4) {
      setTextErrorSelectColumn(<FormattedMessage id="lblSelectAtLeastColumn" defaultMessage="Select at least 1 column." />);
      return;
    } else {
      setTextErrorSelectColumn('');
    }
    for (let i = 0; i < columns.length; i++) {
      const found = listArrayColumns.find((element) => element == columns[i].key);
      if (found != undefined) {
        setNewArr.push(columns[i]);
      }
    }
    setNewDataColumns(setNewArr);
    handleOnVisiblecolumns();
  };

  const handleReset = () => {
    const newListArrayColumns = [];
    newColumns.forEach((element) => {
      newListArrayColumns.push(element.key);
    });
    setNewDataColumns(newColumns);
    setListArrayColumns(newListArrayColumns);
    handleOnVisiblecolumns();
    setDefaultShowColumn(setShowColumn);
    setTextErrorSelectColumn('');
  };

  const handleOnVisiblecolumns = () => {
    setVisible(!visible);
  };

  const handleVisibleChange = (flag) => {
    setVisible(flag);
    if (!flag) {
      // FNC คืนค่า Default อัติโนมัติ
      const newListArrayColumns = [];
      newDataColumns.forEach((element) => {
        newListArrayColumns.push(element.key);
      });

      const reduceOBJ = _.reduce(
        newListArrayColumns,
        function(result, value, key) {
          result[value] = true;
          return result;
        },
        {}
      );
      setListArrayColumns(newListArrayColumns);
      setDefaultShowColumn(reduceOBJ);
      setTextErrorSelectColumn('');
    }
  };

  const handleOnVisiblePopover = (visiblePopover) => {
    setVisiblePopover(visiblePopover);
  };

  const fncActionTable = (record, index) => {
    if (record.status === 'draft') {
      return (
        <Popover
          key={index}
          placement="leftTop"
          trigger="hover"
          visiblePopover={visiblePopover}
          onVisibleChange={handleOnVisiblePopover}
          zIndex={999}
          content={
            <div style={{ display: 'grid' }}>
              <>
                <PageSettings.Consumer>
                  {({ checkPermissionAction }) => (
                    <div>
                      {checkPermissionAction('P11PG1C1', 'P11PG1C1A3') ? (
                        <Button style={{ width: 100 }} type="link" ghost onClick={() => handleEditSaleOrder(record)}>
                          <FormattedMessage id="btnEdit" defaultMessage="Edit" />
                        </Button>
                      ) : null}
                    </div>
                  )}
                </PageSettings.Consumer>
              </>

              <>
                <PageSettings.Consumer>
                  {({ checkPermissionAction }) => (
                    <div>
                      {checkPermissionAction('P11PG1C1', 'P11PG1C1A4') ? (
                        <Button style={{ width: 100 }} type="link" ghost onClick={() => modalConfirmDelete(record.orderId)}>
                          <FormattedMessage id="btnDelete" defaultMessage="Delete" />
                        </Button>
                      ) : null}
                    </div>
                  )}
                </PageSettings.Consumer>
              </>
            </div>
          }
        >
          <Icon type="more" />
        </Popover>
      );
    } else if (record.status === 'completed') {
      return (
        <Popover
          key={index}
          placement="leftTop"
          trigger="hover"
          visiblePopover={visiblePopover}
          onVisibleChange={handleOnVisiblePopover}
          zIndex={999}
          content={
            <div style={{ display: 'grid' }}>
              <>
                <PageSettings.Consumer>
                  {({ checkPermissionAction }) => (
                    <div>
                      {checkPermissionAction('P11PG1C1', 'P11PG1C1A8') ? (
                        <Button style={{ width: 100 }} type="link" ghost onClick={() => handleViewModal(record)}>
                          <FormattedMessage id="btnView" defaultMessage="View" />
                        </Button>
                      ) : null}
                    </div>
                  )}
                </PageSettings.Consumer>
              </>

              <>
                <PageSettings.Consumer>
                  {({ checkPermissionAction }) => (
                    <div>
                      {checkPermissionAction('P11PG1C1', 'P11PG1C1A5') ? (
                        <Button style={{ width: 100 }} type="link" ghost onClick={() => modalConfirmCancel(record.orderId)}>
                          <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
                        </Button>
                      ) : null}
                    </div>
                  )}
                </PageSettings.Consumer>
              </>
            </div>
          }
        >
          <Icon type="more" />
        </Popover>
      );
    } else {
      return (
        <Popover
          key={index}
          placement="leftTop"
          trigger="hover"
          visiblePopover={visiblePopover}
          onVisibleChange={handleOnVisiblePopover}
          zIndex={999}
          content={
            <div style={{ display: 'grid' }}>
              <>
                <PageSettings.Consumer>
                  {({ checkPermissionAction }) => (
                    <div>
                      <Button style={{ width: 100 }} type="link" ghost onClick={() => handleViewModal(record)}>
                        <FormattedMessage id="btnView" defaultMessage="View" />
                      </Button>
                    </div>
                  )}
                </PageSettings.Consumer>
              </>
            </div>
          }
        >
          <Icon type="more" />
        </Popover>
      );
    }
  };

  return (
    <div className="order">
      <PageSettings.Consumer>
        {({ checkPermissionAction }) => (
          <div>
            {checkPermissionAction('P11PG1C1', 'P11PG1C1A1') ? (
              <div>
                <Card
                  className="order-card"
                  title={intl.formatMessage({
                    id: 'menuSaleOrder',
                    defaultMessage: 'Sale Order',
                  })}
                  extra={
                    checkPermissionAction('P11PG1C1', 'P11PG1C1A2') ? (
                      <Button01 btnsize="wd_sm" className="order-card-extra" type="primary" href="#" onClick={handleCreateOrder}>
                        <FormattedMessage id="saleOrderCreateOrderButton" defaultMessage="Create Order" />
                      </Button01>
                    ) : null
                  }
                >
                  <PriceReportV1 priceReportDetail={priceReportDetail} />
                  <div className="action-saleOrder-container">
                    <ActionReportV2
                      value={{
                        columns,
                        ignoreFilterColumns,
                        ignoreSearchColumns,
                        fliterByColumn,
                        visible,
                        defaultShowColumn,
                        textErrorSelectColumn,
                      }}
                      fnc={{
                        onChangeSearch,
                        onFliterByColumn,
                        onSelectRangePicker,
                        checkedValuecolumns,
                        handleCheckOk,
                        handleReset,
                        handleVisibleChange,
                        handleOnVisiblecolumns,
                      }}
                    />
                  </div>

                  <SaleOrderReportTable
                    loading={loading}
                    dataSource={saleOrderData}
                    columns={newDataColumns}
                    total={total}
                    fnc={{
                      handleChange,
                      handlePagination,
                      handleSizeChange,
                    }}
                  />
                </Card>

                <CreateSaleOrder
                  visibleModal={visibleModal}
                  setVisibleModal={setVisibleModal}
                  triggerCreate={triggerCreate}
                  setTriggerCreate={setTriggerCreate}
                />

                <EditSaleOrder
                  dataEditSaleOrder={dataEditSaleOrder}
                  setDataEditSaleOrder={setDataEditSaleOrder}
                  visibleEditModal={visibleEditModal}
                  setVisibleEditModal={setVisibleEditModal}
                  triggerEdit={triggerEdit}
                  setTriggerEdit={setTriggerEdit}
                />

                <ViewSaleOrder visible={visbleViewModal} setVisible={setVisbleViewModal} setData={setViewData} data={viewData} />
              </div>
            ) : null}
          </div>
        )}
      </PageSettings.Consumer>
    </div>
  );
}
