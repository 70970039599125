import React, { useEffect, useState, useContext } from 'react';
import { Form, Modal, Input, Row, DatePicker, Switch } from 'antd';
import Button01 from '../../../components/v2/button_01';
import Button02 from '../../../components/v2/button_02';
import styled from 'styled-components';
import { ShiftSettings } from '../config/shift-type-settings';
import httpClient from '../../../components/axiosClient';
import cssStyle from './css/modal-form.css';
import { PageSettings } from '../../../config/page-settings';
import moment from 'moment';
import _ from 'lodash';
import { useIntl, FormattedMessage } from 'react-intl';

const languages = [
  {
    id: 'th',
    languageName: 'Thailand',
  },
  {
    id: 'en',
    languageName: 'English',
  },
  {
    id: 'ch',
    languageName: 'China',
  },
];
const LabelRequire = styled.label`
  color: #ff1010;
`;
const LabeRequire = (props) => {
  const { text, req } = props;
  return (
    <span>
      {text}&nbsp;
      {req ? <LabelRequire>*</LabelRequire> : ''}
    </span>
  );
};

const { confirm } = Modal;

const ModalPublicHolidays = ({ languageLocal, form,langLocal,record }) => {
  const { state, fnc } = useContext(ShiftSettings);
  const { getFieldDecorator, validateFields, resetFields, getFieldsValue, getFieldValue } = form;
  const intl = useIntl();

  console.log('languageLocal', _.get(languageLocal,'length'));

  const comId = localStorage.getItem('comId');

  useEffect(() => {}, []);

  // const onCreate = async () => {
  //   validateFields(async (err, values) => {
  //     if (err) {
  //       return false;
  //     }

  //     const dateForm = moment(values.date._d, 'YYYY-MM-DD').format('YYYY-MM-DD');

  //     const repeatBoo = values.annuallyRepeat === undefined ? false : true;

  //     let eventName = {};
  //     for (let lang of languageLocal) {
  //       eventName[lang] = values[lang];
  //     }

  //     const body = {
  //       comId: comId,
  //       eventName: eventName,
  //       date: dateForm,
  //       annuallyRepeat: repeatBoo,
  //     };

  //     try {
  //       const results = await httpClient.post(`/resource/manager/company/${comId}/public/holiday`, body);
  //       fnc.successNotification(results.data.message);
  //       fnc.refreshDataServices();
  //       fnc.setVisible(false);
  //       resetFields();
  //     } catch {
  //       fnc.failedNotification('fail!');
  //     }
  //   });
  //   fnc.setVisible(false);
  //   resetFields();
  // };

  const onCreate = async (e) => {
    //e.preventDefault();
    validateFields(async (err, values) => {
      if (err) {
        return false;
      }
      confirm({
        className: 'customer-modal-confirm',
        icon: 'none',
        title: intl.formatMessage({ id: 'settingHolidaysModalConfirm', defaultMessage: 'Are you sure to add public holiday?' }),
        okText: intl.formatMessage({ id: 'settingHolidaysModalBtnConfirm', defaultMessage: 'Confirm' }),
        okButtonProps: {
          style: {
            width: '96px',
          },
          type: 'primary',
        },
        cancelButtonProps: {
          style: {
            width: '96px',
          },
          type: 'danger',
        },
        cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
        //width: '330px',

        centered: true,
        async onOk() {
          const dateForm = moment(values.date._d, 'YYYY-MM-DD').format('YYYY-MM-DD');

          let eventName = {};
          for (let lang of langLocal) {
            eventName[lang] = values[lang];
          }

          const comCalId = getFieldValue('comCalId');
          console.log('Holiday1',values);
          if (comCalId) {
            if (values.annullyRepeat === true) {
              console.log('Holiday2',values);
              const body = {
                eventName: eventName,
                date:
                  dateForm !== record.dateDefault || values.annullyRepeat !== record.annullyRepeatStatus ? dateForm : record.dateDefault,
                annuallyRepeat: values.annullyRepeat,
              };

              try {
                const results = await httpClient.put(`/resource/manager/company/${comId}/public/holiday/${comCalId}`, body);
                fnc.successNotification(results.data.message);
                fnc.refreshDataServices();
                fnc.setVisible(false);
                resetFields();
              } catch {
                fnc.failedNotification('fail!');
              }
            } else if (values.annullyRepeat === false) {
              console.log('Holiday1',values);
              const body = {
                eventName: eventName,
                date:
                  dateForm !== record.dateDefault && values.annullyRepeat === record.annullyRepeatStatus ? dateForm : record.dateDefault,
                annuallyRepeat: values.annullyRepeat,
              };
              try {
                const results = await httpClient.put(`/resource/manager/company/${comId}/public/holiday/${comCalId}`, body);
                fnc.successNotification(results.data.message);
                fnc.refreshDataServices();
                fnc.setVisible(false);
                resetFields();
              } catch {
                fnc.failedNotification('fail!');
              }
            }
          } else {
            //Add
            const body = {
              comId: comId,
              eventName: eventName,
              date: dateForm,
              annuallyRepeat: values.annullyRepeat,
            };

            try {
              const results = await httpClient.post(`/resource/manager/company/${comId}/public/holiday`, body);
              fnc.successNotification(results.data.message);
              fnc.refreshDataServices();
              fnc.setVisible(false);
              resetFields();
            } catch {
              fnc.failedNotification('fail!');
            }
          }
        },
        onCancel() {},
      });
    });
  };

  const handleCancel = () => {
    fnc.setVisible(false);
    resetFields();
  };

  const dateChange = (date, dateString) => {};

  const repeatChange = (checked) => {};

  const modalConfirm = () => {
    confirm({
      title: 'Are you sure delete this task?',
      okText: 'Confirm',
      okType: 'danger',
      cancelText: 'Cancel',
      centered: true,
      onOk() {
        onCreate();
      },
      onCancel() {},
    });
  };

  return (
    <ShiftSettings.Consumer>
      {({ state, fnc }) => (
        <Modal
          centered
          title="Public Holiday"
          width={600}
          visible={state.visible}
          languageLocal={languageLocal}
          onCancel={handleCancel}
          onOk={modalConfirm}
          footer={[
            <Button02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleCancel}>
              Cancel
            </Button02>,
            <Button01 key="submit" type="primary" btnsize="wd_df" onClick={onCreate}>
              Save
            </Button01>,
          ]}
        >
          <Form>
            <Row>
              <Form.Item className="holidays-form-item" label={<LabeRequire text="Holiday name" req={true} />}>
                {_.get(languageLocal,'length') !== 0 ? (
                  languageLocal.map((v, i) =>
                    getFieldDecorator(v, {
                      rules: [{ required: true, message: 'Please input Holiday Name!' }],
                    })(<Input className="addOnBefore" key={i} addonBefore={v} placeholder="Enter holiday name" autoComplete="off"></Input>)
                  )
                ) : (
                  <>
                    {getFieldDecorator('TH', {
                      rules: [{ required: true, message: 'Please input Holiday Name!' }],
                    })
                    (<Input className="addOnBefore" addonBefore="TH ไทย" placeholder="Enter holiday name" autoComplete="off" />)}
                    {getFieldDecorator('EN', {
                      rules: [{ required: true, message: 'Please input Holiday Name!' }],
                    })
                    (<Input className="addOnBefore" addonBefore="EN English" placeholder="Enter holiday name" autoComplete="off" />)}
                    
                  </>
                )}
              </Form.Item>
            </Row>
            <Row>
              <Form.Item className="holidays-form-item" label={<LabeRequire text="Date" req={true} />}>
                {getFieldDecorator('date', {
                  rules: [{ required: true, message: 'Please input Date!' }],
                })(<DatePicker className="date-width" onChange={dateChange} placeholder="Select date" />)}
              </Form.Item>
            </Row>
            <Row className="style-switch">
              <Form.Item className="style-switch holidays-form-item" label={<LabeRequire text="Annually Repeat" req={false} />}>
                {getFieldDecorator('annuallyRepeat', { valuePropName: 'checked' })(<Switch onChange={repeatChange} />)}
              </Form.Item>
            </Row>
          </Form>
        </Modal>
      )}
    </ShiftSettings.Consumer>
  );
};

const ModalForm =  Form.create({
  name: 'modal_holoday_form',
  mapPropsToFields(props) {
    const { record, languageLocal } = props;

    let holidayDate = undefined;
    if (record) {
      holidayDate = moment(record.date, 'YYYY-MM-DD');
    }

    let nameRows = {};
    for (let lang of languageLocal) {
      nameRows[lang] = Form.createFormField({
        value: record ? record.eventName[lang] : '',
      });
    }

    return {
      comCalId: Form.createFormField({
        value: record ? record.comCalId : '',
      }),
      eventName: Form.createFormField({
        value: nameRows ? nameRows : '',
      }),
      date: Form.createFormField({
        value: holidayDate ? holidayDate : '',
      }),
      annullyRepeat: Form.createFormField({
        value: record ? record.annullyRepeatStatus : '',
      }),
      ...nameRows,
    };
  },
})(ModalPublicHolidays);

export default ModalForm;
