import React from 'react'
import { Row, Col, Divider, Avatar } from 'antd'
import './css/index.css'
import _ from 'lodash'
import { FormattedMessage } from 'react-intl'
import moment from 'moment'

export default (props) => {
  const { viewData } = props
  return (
    <div className='modal-view-project-detail-padding '>
      <Row>
        <Col span={6} style={{ textAlign: 'center' }}>
          <Avatar
            shape='circle'
            style={{ width: '150px', height: '150px' }}
            src={_.get(viewData, 'pathIMG')}
          />
        </Col>
        <Col span={18}>
          <Row gutter={[16, 16]}>
            <Col span={8}>
              <p className='modal-view-project-text-header'><FormattedMessage id="modalProjectTextProjectNo" defaultMessage="Project No." /></p>
              <p className='modal-view-project-text-value'>{_.get(viewData, 'projectNo')}</p>
            </Col>
            <Col span={8}>
              <p className='modal-view-project-text-header'><FormattedMessage id="modalProjectTextProjectName" defaultMessage="Project Name" /></p>
              <p className='modal-view-project-text-value'>{_.get(viewData, 'projectName')}</p>
            </Col>
            <Col span={8}>
              <p className='modal-view-project-text-header'><FormattedMessage id="modalProjectTextProjectType" defaultMessage="Project Type" /></p>
              <p className='modal-view-project-text-value'>{_.get(viewData, 'projectType')}</p>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={8}>
              <p className='modal-view-project-text-header'><FormattedMessage id="modalProjectTextPriority" defaultMessage="Priority" /></p>
              <p className='modal-view-project-text-value'> <img src={_.get(viewData, 'projectPriorityIcon')} style={{ width: '18px', height: '16px' }} />&nbsp;{_.get(viewData, 'priorityName')}</p>
            </Col>
            <Col span={8}>
              <p className='modal-view-project-text-header'><FormattedMessage id="modalProjectTextCustomer" defaultMessage="Customer" /></p>
              <p className='modal-view-project-text-value'>{_.get(viewData, 'customerName') || '-'}</p>
            </Col>
            <Col span={8}>
              <p className='modal-view-project-text-header'><FormattedMessage id="modalProjectTextProjectManager" defaultMessage="Project Manager" /></p>
              <p className='modal-view-project-text-value'>{_.get(viewData, 'projectManagerName')}</p>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={8}>
              <p className='modal-view-project-text-header'><FormattedMessage id="modalProjectTextPlanStart" defaultMessage="Plan Start" /></p>
              <p className='modal-view-project-text-value'>{moment(_.get(viewData, 'planStartText'), 'YYYY-MM-DD').format('ddd, MMM DD, YYYY')}</p>
            </Col>
            <Col span={8}>
              <p className='modal-view-project-text-header'><FormattedMessage id="modalProjectTextPlanFinish" defaultMessage="Plan Finish" /></p>
              <p className='modal-view-project-text-value'>{moment(_.get(viewData, 'planFinishText'), 'YYYY-MM-DD').format('ddd, MMM DD, YYYY')}</p>
            </Col>
            <Col span={8}>
              <p className='modal-view-project-text-header'><FormattedMessage id="modalProjectTextReferenceNo" defaultMessage="Reference No." /></p>
              <p className='modal-view-project-text-value'>{_.get(viewData, 'referenceNo') || '-'}</p>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Divider type='horizontal' />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <p className='modal-view-project-text-header'><FormattedMessage id="modalProjectTextRemark" defaultMessage="Remark" /></p>
              <p className='modal-view-project-text-value'>{_.get(viewData, 'remark')}</p>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}
