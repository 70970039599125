import { Button, Col, DatePicker, Form, Input, Row, Select, Tag } from 'antd';
import React, { useContext, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import LabeRequireForm from '../../../components/label-required-form';
import CustomTableComponent from '../components/table';
import CollapseCustom from '../../../components/collapse-custom';
import { messageLabel } from '../../../components/message-component';
import _ from 'lodash';
import { numberFormatDecimal, numberFormatNoDecimal } from '../../../component-function/fnc-number-format-decimal';
import { SaleOrderContext } from '../context';
import QuotationModal from '../modal/saleorder/saleorder-modal';
import moment from 'moment';

const { Option } = Select;
const { RangePicker } = DatePicker;
const dateFormat = 'DD/MM/YYYY';

const CanceledQuotation = () => {
  const { state, setState, func } = useContext(SaleOrderContext);
  const { isOpenQuotation, reportDataCanceled } = state;
  const { seIsOpenQuotation, setReportDataCanceled } = setState;
  const { handleOpenModalQuotation } = func;
  const { quotationList, quotationTotal, paginationPage, loading, rangePickerDate } = reportDataCanceled;
  const intl = useIntl();

  const handleFieldSort = (value) => {
    setReportDataCanceled((prev) => ({ ...prev, fieldSort: value }));
  };

  const handleOrderSort = (value) => {
    setReportDataCanceled((prev) => ({ ...prev, orderSort: value }));
  };

  const handlePaginationPage = (value) => {
    setReportDataCanceled((prev) => ({ ...prev, paginationPage: value }));
  };

  const handlePaginationSize = (value) => {
    setReportDataCanceled((prev) => ({ ...prev, paginationSize: value }));
  };

  const handleSelectRangePicker = (value) => {
    setReportDataCanceled((prev) => ({ ...prev, rangePickerDate: value, paginationPage: 1, }));
  };


  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      fixed: 'left',
      width: 70,
      render: (text, record, index) => record.index,
    },
    // {
    //   title: messageLabel('quotationLabelQuotationNo', 'Quotation No'),
    //   dataIndex: 'quotationNo',
    //   key: 'quotationNo',
    //   sorter: true,
    //   fixed: 'left',
    //   width: 150,
    // },
    {
      title: messageLabel('quotationLabelSaleOrderNo', 'Sale Order No.'),
      dataIndex: 'saleOrderNo',
      key: 'saleOrderNo',
      sorter: true,
      fixed: 'left',
      width: 150,
      ellipsis: true,
    },
    {
      title: messageLabel('quotationLabelCustomer', 'Customer'),
      dataIndex: 'customerName',
      key: 'customerName',
      sorter: true,
      // width: 200,
    },
    {
      title: messageLabel('quotationLabelItem', 'Item'),
      dataIndex: 'totalItem',
      key: 'totalItem',
      sorter: true,
      render: (text, record, index) => _.get(record, 'totalItem'),
    },
    {
      title: messageLabel('quotationLabelTotal', 'Total'),
      dataIndex: 'total',
      key: 'total',
      sorter: true,
      width: 200,
      align: 'right',
      render: (text, record, index) =>
        // _.get(record, 'total') ? (
          <span>
            {numberFormatDecimal(_.get(record, 'total'))} <FormattedMessage id="labelTHB" defaultMessage="THB" />
          </span>
        // ) : null,
    },
    {
      title: messageLabel('quotationLabelStatus', 'Status'),
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      width: 200,
      align: 'center',
      render: (text, record, index) => (
        <div className="tag-center-div">
          <Tag className="tag-center-style" color={record.status.color}>
            {record.status.txt}
          </Tag>
        </div>
      ),
    },
    {
      title: messageLabel('quotationLabelIssuedBy', 'Issued by'),
      dataIndex: 'issuedName',
      key: 'issuedName',
      sorter: true,
      // render: (text, record, index) => '',
    },
    {
      title: messageLabel('quotationLabelIssuedDate', 'Issued Date'),
      dataIndex: 'issueDate',
      key: 'issueDate',
      sorter: true,
      render: (text, record, index) =>
        _.get(record, 'issueDate') ? moment(_.get(record, 'issueDate'), 'YYYY-MM-DD').format(dateFormat) : null,
    },
    {
      title: messageLabel('quotationLabelQuotationNo', 'Quatation No.'),
      dataIndex: 'quotationNo',
      key: 'quotationNo',
      sorter: true,
      render: (text, record, index) => record.quotationNo || '-',
    },
  ];

  const advancedFilter = (action, value) => {
    const handleChange = (value, key) => {
      action((prev) => ({ ...prev, [key]: value }));
    };

    return (
      <Form colon={false}>
        <Row gutter={[24, 2]}>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'quotationLabelQuotationNo', defaultMessage: 'Quotation No.' })}
                  req={false}
                />
              }
            >
              <Input
                allowClear
                value={_.get(value, 'quotationNo')}
                onChange={(e) => handleChange(e.target.value, 'quotationNo')}
                placeholder={intl.formatMessage({ id: 'quotationHintQuotationNo', defaultMessage: 'Enter Quotation No.' })}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'quotationLabelSaleOrderNo', defaultMessage: 'Sale Order No.' })}
                  req={false}
                />
              }
            >
              <Input
                allowClear
                value={_.get(value, 'saleOrderNo')}
                onChange={(e) => handleChange(e.target.value, 'saleOrderNo')}
                placeholder={intl.formatMessage({ id: 'quotationHintSaleOrderNo', defaultMessage: 'Enter Sale Order No.' })}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'quotationLabelCustomer', defaultMessage: 'Customer' })} req={false} />
              }
            >
              <Input
                allowClear
                value={_.get(value, 'customerName')}
                onChange={(e) => handleChange(e.target.value, 'customerName')}
                placeholder={intl.formatMessage({ id: 'quotationHintCustomer', defaultMessage: 'Enter Customer' })}
              />
            </Form.Item>
          </Col>

          <Col span={8}></Col>
        </Row>
      </Form>
    );
  };

  const contentAction = (record) => {
    // console.log('content', record);
    return (
      <div style={{ display: 'grid' }}>
        <div>
          <Button style={{ width: 100 }} type="link" ghost onClick={() => handleOpenModalQuotation(record, 'view')}>
            <FormattedMessage id="btnView" defaultMessage="View" />
          </Button>
        </div>
        {/* <div>
          <Button style={{ width: 100 }} type="link" ghost onClick={() => handleOpenModalQuotation(record, 'edit')}>
            <FormattedMessage id="btnEdit" defaultMessage="Edit" />
          </Button>
        </div> */}
      </div>
    );
  };

  return (
    <div className="warehouse-layout-content warehouse-tabs-details scroll-sm">
      <div style={{ paddingTop: 20 }}>
        <CollapseCustom
          label={intl.formatMessage({ id: `btnFilter`, defaultMessage: 'Filter' })}
          extra={
            <RangePicker 
              value={rangePickerDate} 
              format={dateFormat} 
              onChange={handleSelectRangePicker} 
              allowClear={true} 
              placeholder={[
                intl.formatMessage({ id: 'saleorderFilterPHFormDueDate', defaultMessage: 'Select Form Date' }),
                intl.formatMessage({ id: 'saleorderFilterPHToDueDate', defaultMessage: 'Selet To Date' })
              ]}
            />
          }
        >
          {advancedFilter(setReportDataCanceled, reportDataCanceled)}
        </CollapseCustom>
      </div>
      <div className="content-body-report">
      <CustomTableComponent
          rowKey={(record) => record.saleOrderId}
          columns={columns}
          dataSource={quotationList}
          scroll={{ x: true }}
          role={true}
          onRow={true}
          total={quotationTotal}
          loading={loading}
          paginationPage={paginationPage}
          setFieldSort={handleFieldSort}
          setOrderSort={handleOrderSort}
          setPaginationSize={handlePaginationSize}
          setPaginationPage={handlePaginationPage}
          contentAction={contentAction}
        />
      </div>
    </div>
  );
};

export default CanceledQuotation;
