import React, { useEffect, useState } from 'react';
import { List, Avatar, Input, Icon, Popover, Badge, Checkbox } from 'antd';
import _ from 'lodash';
import { useIntl, FormattedMessage } from 'react-intl';
import { MonitorContext } from '../../transportation-context';
import MemberInfomationModal from '../../../../components/member-information/index';
import moment from 'moment';
import '../css/summary.css';

export default React.memo(
  (props) => {
    const {
      onClick,
      members,
      date,
      onClickTrack,
      setMembersView,
      membersView,
      indeterminate,
      onCheckAllChange,
      checkAll,
      handleClearMemberList,
      countChecked,
      memberCountList,
      LangCode,
    } = props;
    const intl = useIntl();
    const [visibleModal, setVisibleModal] = useState(false);
    const [dataMemberInfo, setDataMemberInfo] = useState(null);
    const [searchMember, setSearchMember] = useState();

    const handleClickMember = (memComId) => (_.isFunction(onClick) ? onClick(memComId) : null);

    const renderMember = (item) => {
      const valueLowerCase = _.lowerCase(searchMember);
      const fullnameLowerCase = _.lowerCase(item.fullname);
      const phoneLowerCase = _.lowerCase(item.phone);
      let isShow = _.includes(fullnameLowerCase, valueLowerCase) || _.includes(phoneLowerCase, valueLowerCase);

      return isShow ? (
        <div key={item.memComId} className="member-item-div" onClick={() => handleClickMember(item)}>
          <div className="member-item-div-avatar">
            <div className="member-item-div-image">
              <Badge
                className="badge-style-member"
                count={
                  item.checked === true ? (
                    <Icon type="check-circle" theme="filled" style={{ color: '#1D3557' }} className="icon-correct-member" />
                  ) : null
                }
              >
                <Avatar src={item.pathImage} style={{ width: '34px', height: '34px' }} />
              </Badge>
            </div>
            <div className="member-item-div-detail">
              <div className="member-item-div-name">{item.fullname}</div>
              <div className="member-item-div-phone">{item.phone}</div>
            </div>
          </div>
        </div>
      ) : null;
    };

    const setMembersSearch = _.debounce((value) => {
      setSearchMember(value);
    }, 100);

    const handleSearchMember = (e) => {
      setMembersSearch(e.target.value);
    };

    return (
      <div className="member-mapview-div">
        <div className="member-mapview-input-padding">
          <Input
            className="member-mapview-input"
            placeholder={intl.formatMessage({ id: 'hintSearch', defaultMessage: 'Search' })}
            prefix={<Icon style={{ color: 'rgba(0,0,0,.25)' }} type="search" />}
            allowClear
            onChange={handleSearchMember}
            autoComplete="off"
          />
        </div>
        <div className="member-mapview-div-header">
          <div className="member-mapview-div-header-list">
            <div>
              <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
                <FormattedMessage id={`filterTaskSelected${LangCode}`} defaultMessage="Selected" />
              </Checkbox>
            </div>
            <div>
              {countChecked} of {memberCountList}
            </div>
          </div>
          <div style={{ cursor: 'pointer' }} onClick={() => handleClearMemberList()}>
            <FormattedMessage id={`filterTaskClearAll${LangCode}`} defaultMessage=" Clear All" />
          </div>
        </div>
        <div className="member-view-list scroll-sm">{membersView && membersView.map((item) => renderMember(item))}</div>
      </div>
    );
  },
  (prev, next) => {}
);
