import React, { useContext, useEffect, useState } from 'react';
import { DatePicker, Input, InputNumber } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import AssetSummaryContext from '../context';
import _ from 'lodash';
import moment from 'moment';
import './css/advanceAssetClass.css'

const dateFormat = 'ddd, MMM DD YYYY';

const AssetAdvanceSearch = () => {
  const { state, setState, fnc } = useContext(AssetSummaryContext);
  const {
    purChaseStartDate,
    purChaseEndDate,
    purChaseMinPrice,
    purChaseMaxPrice,
    price
  } = state

  const {
    setPurChaseStartDate,
    setPurChaseEndDate,
    setPurChaseMinPrice,
    setPurChaseMaxPrice,
    setPrice
  } = setState

  const intl = useIntl();

  const [dateStart, setDateStart] = useState();
  const [dateEnd, setDateEnd] = useState();
  const [searchMin, setSearchMin] = useState();
  const [searchMax, setSearchMax] = useState();

  useEffect(() => {
    filterDate(dateStart, dateEnd)
  }, [dateStart, dateEnd])

  useEffect(() => {
    filterPurchase(searchMin, searchMax)
  }, [searchMin, searchMax])

  const handleDateMin = (value) => {
    const dateMin = value ? moment(value, dateFormat).format('YYYY-MM-DD') : undefined
    setDateStart(dateMin)
  }

  const handleDateMax = (value) => {
    const dateMax = value ? moment(value, dateFormat).format('YYYY-MM-DD') : undefined
    setDateEnd(dateMax)
  }

  const handleSearchMin = (value) => {
    setSearchMin(value);
  }

  const handleSearchMax = (value) => {
    setSearchMax(value);
  }

  const filterDate = (startDate, endDate) => {
    let sDate = startDate
    let eDate = endDate

    if (sDate && !eDate) {
      eDate = sDate
    }

    if (eDate && !sDate) {
      sDate = eDate
    }

    if (!eDate && !sDate) {
      sDate = undefined
      eDate = undefined
    }

    setPurChaseStartDate(sDate)
    setPurChaseEndDate(eDate)
  }


  const filterPurchase = (min, max) => {
    let sMin = min
    let eMax = max
    let arr = []

    if (sMin && !eMax) {
      eMax = sMin
    }

    if (eMax && !sMin) {
      sMin = eMax
    }

    if (sMin && eMax) {
      arr[0] = sMin
      arr[1] = eMax
    } else {
      arr = []
    }

    setPrice(arr)
  }


  const handleKeyDownNumberDecimal = (e) => {
    let keyCode = e.key.charCodeAt(0);
    const splitValue = _.split(e.target.value, '.');
    if ((keyCode >= 48 && keyCode <= 57) || keyCode === 65 || keyCode === 66 || keyCode === 46) {
      if (keyCode === 46 && e.target.value.indexOf('.') > 1) e.preventDefault();
      if (keyCode === 46) {
        let sumDot = _.sumBy(e.target.value, (o) => { return o.charCodeAt(0) === 46 ? 1 : 0 });
        if (sumDot >= 1) e.preventDefault();
      }
    }
    else if (keyCode === 45) {
      e.preventDefault();
    }
    else {
      e.preventDefault();
    }
    if (_.get(splitValue, '[1]')) {
      if (_.size(splitValue[1]) >= 2 && keyCode !== 66 && e.target.selectionStart > e.target.value.indexOf('.')) e.preventDefault();
    }
  }

  return (
    <div>
      <div>
        <div style={{ marginBottom: '6px', marginTop: '4px' }}>
          <span className='asset-datepicker-label'><FormattedMessage id="assetCardListAssetAdvancePurchaseDate" defaultMessage="Purchase Date" /></span>
        </div>
        <div className='asset-datepicker-item'>
          <DatePicker className='asset-datepicker'
            format={dateFormat}
            onChange={handleDateMin}
            placeholder={intl.formatMessage({ id: 'assetFilterDateMin', defaultMessage: 'Select date min' })}
          />
        </div>
        <div className='asset-datepicker-item'>
          <DatePicker className='asset-datepicker'
            format={dateFormat}
            onChange={handleDateMax}
            placeholder={intl.formatMessage({ id: 'assetFilterDateMax', defaultMessage: 'Select date max' })}
          />
        </div>
      </div>
      <div>
        <div style={{ marginBottom: '6px' }}>
          <span className='asset-datepicker-label'><FormattedMessage id="assetCardListAssetAdvancePrice" defaultMessage="Price (Baht)" /></span>
        </div>
        <div className="asset-search-max-min-item">
          <div>
            <InputNumber
              placeholder={intl.formatMessage({ id: 'assetFilterMin', defaultMessage: 'Min' })}
              className='asset-item-inputNumber'
              min={0}
              step={0.01}
              max={9999999999}
              onKeyDown={handleKeyDownNumberDecimal}
              onChange={handleSearchMin}
              style={{ width: '100%' }}
              formatter={value => value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={value => value.replace(/\$\s?|(,*)/g, '')}
            />
          </div>
          <div style={{ padding: '0px 10px' }}>
            -
          </div>
          <div>
            <InputNumber
              placeholder={intl.formatMessage({ id: 'assetFilterMax', defaultMessage: 'Max' })}
              className='asset-item-inputNumber'
              min={0}
              step={0.01}
              max={9999999999}
              onKeyDown={handleKeyDownNumberDecimal}
              onChange={handleSearchMax}
              style={{ width: '100%' }}
              formatter={value => value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={value => value.replace(/\$\s?|(,*)/g, '')}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default AssetAdvanceSearch