import React, { useState, useEffect, useContext } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';

import Styles from './css/list-view.css';
import { ListViewContext } from '../monitor-context';
import { Table } from 'antd';

export default () => {
  const intl = useIntl();
  const { state, setState, fnc } = useContext(ListViewContext);

  const showTotal = (total) => {
    return <span style={{ fontSize: '13px' }}><FormattedMessage id="lblRowPerPage" defaultMessage="Rows per page:" /></span>
  };
  return (
    <ListViewContext.Consumer>
      {({ state, setState, fnc }) => (
        // <div className='scroller'>
        <div>
          <Table
            className='list-view-table'
            rowKey={record => record.index}
            onChange={fnc.handleChange}
            loading={state.loading}
            dataSource={state.listViewReport}
            columns={state.newDataColumns}
            // scroll={state.listViewReport.length > 0 ? { x: 2500, y: 180 } : { x: 'max-content', y: 550 }}
            // scroll={state.listViewReport.length > 0 ? { x: 2500 , y: 'auto'} : { x: 'max-content', y: '36vh' }}
            scroll={{ x: 2500, y: `calc(100vh - 496px)` }}
            pagination={{
              total: state.total,
              showTotal: showTotal,
              defaultCurrent: 1,
              pageSizeOptions: ['10', '20', '30', '40', '50'],
              showSizeChanger: true,
              locale: { items_per_page: '' },
              onChange: fnc.handlePagination,
              onShowSizeChange: fnc.handleSizeChange,
            }}
          />
          {state.listViewReport && state.listViewReport.length !== 0 ? (
            <div className="total-items-list-view">
              <span style={{ fontSize: '13px' }}>
                <FormattedMessage id="lblTotal" defaultMessage="Total" />
                {` ${state.tasks || 0} `}
                <FormattedMessage id="lblitems" defaultMessage="items" />
              </span>
            </div>
          ) : null}
        </div>
      )}
    </ListViewContext.Consumer>
  );
};
