import React, { useContext, useEffect, useMemo, useState } from 'react';
// import { Modal, Button } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import { MonitorContext, GanttViewContext } from '../monitor-context';
import moment from 'moment';
import {
  Day,
  TimelineViews,
  TimelineWeek,
  TimelineMonth,
  ScheduleComponent,
  ViewsDirective,
  ViewDirective,
  ResourcesDirective,
  ResourceDirective,
  Inject,
  HeaderRowsDirective,
  HeaderRowDirective,
  Resize,
  DragAndDrop,
} from '@syncfusion/ej2-react-schedule';
import {
  Internationalization,
  extend,
  loadCldr,
  L10n
} from '@syncfusion/ej2-base';
import _ from 'lodash';
import { fncDragStopApi, fncResizeStopApi } from './fnc-action-api';
import MemberInfomationModal from '../../../components/member-information/index';
import { useAppContext } from '../../../includes/indexProvider';
import Styles from './css/gentt-view.css';

let instance = new Internationalization();

L10n.load({
  'en-US': {
    'schedule': {
      timelineDay: 'Day',
      timelineWeek: 'Week',
      timelineMonth: 'Month',
    },
  },
  'th': {
    'schedule': {
      timelineDay: 'วัน',
      timelineWeek: 'สัปดาห์',
      timelineMonth: 'เดือน',
    },
  },
});

const dateHeaderTemplate = (props) => {
  return (
    <div>
      <div>
        {getDateHeaderText(props.date, 'Hm')}
      </div>
    </div>
  )
}

const getDateHeaderText = (value, skeleton) => {
  return instance.formatDate(value, { skeleton: skeleton });
}

const onEventRendered = (args) => {
  args.element.querySelector('.e-inner-wrap').style.padding = '0px 8px 0px 8px';
  args.element.style.backgroundColor = args.data.Color;
  args.element.querySelector('.e-appointment-details').style.justifyContent =
    'center';
  args.element.querySelector('.e-appointment-details').style.alignItems =
    'center';
  args.element.querySelector('.e-appointment-details').style.display = 'flex';
  args.element.querySelector('.e-time').style.display = 'none';
  args.element.querySelector('.e-subject').style.justifyContent = 'center';
  args.element.querySelector('.e-subject').style.alignItems = 'center';
  args.element.querySelector('.e-subject').style.fontSize = '12px';
  args.element.querySelector('.e-subject').innerHTML =
    args.data.TaskNo +
    ' ' +
    `<span style='width: 5px; height: 5px; background-color: #ffffff; border-radius: 50%; display: inline-flex; margin: 0 4px 2px 3px;'></span>` +
    ' ' +
    args.data.CustomerName;
};

const tooltipData = (props) => {
  let start = moment(props.StartTime).format(dateTimeNew);
  let end = moment(props.EndTime).format(dateTimeNew);
  return (
    <div style={{ backgroundColor: 'whtie', padding: '13px' }}>
      <div style={{ padding: '2px' }}>
        <span style={{ paddingRight: '4px' }}>{props.TaskNo}</span>
        &nbsp;<span className='dot-tooltip' />&nbsp;
        <span style={{ paddingLeft: '4px' }}>{props.CustomerName}</span>
      </div>
      <div style={{ padding: '2px' }}>Task Name : {props.Subject}</div>
      <div style={{ padding: '2px' }}>Reference : {props.Reference}</div>
      <div style={{ padding: '2px' }}>Status : {props.Status}</div>
      <div style={{ padding: '2px' }}>{start} - {end}</div>
      <div style={{ padding: '2px' }}>Remark : {props.Remark}</div>
      <br />
      <div style={{ padding: '2px' }}>Address : {props.ToAddress}</div>
    </div>
  )
}

const cellDoubleClick = (args) => {
  args.cancel = true;
}

// const dateApi = 'YYYY-MM-DD';
const dateTimeApi = 'YYYY-MM-DD HH:mm';
const dateTimeNew = 'ddd, MMM DD,YYYY h:mm A';

export default React.memo((props) => {
  const { resourceData, taskData, navigateBar, setNavigateBar, organizationTeam, LangCode } = props;
  const typeApi = LangCode && LangCode === `WorkDesk` ? `menuType=workdesk` : ``;
  const { state, fnc } = useContext(GanttViewContext);
  const { stateMain, setStateMain, fncMain } = useContext(MonitorContext);
  const intl = useIntl();
  // const app = useAppContext();
  // const langValue = app.state.langValue;
  let mainDate = moment(stateMain.mainDateStartAction).format('MM/DD/YYYY');

  // console.log('keyTabMonitor:::: ', stateMain.keyTabMonitor);

  const [visibleModal, setVisibleModal] = useState(false);
  const [dataMemberInfo, setDataMemberInfo] = useState(null);

  // const resourceData = extend([], state.resourceData, null, true);
  // const taskData = extend([], state.taskData, null, true);

  const resourceData_Sch = extend([], resourceData, null, true);
  const taskData_Sch = extend([], taskData, null, true);

  // console.log('GanttView taskData::: ', taskData);
  // console.log('GanttView resourceData::: ', resourceData);
  // console.log('GanttView taskData_Sch::: ', taskData_Sch);


  const handleDragStop = async (args) => {
    // console.log('handleDragStop args::: ', args);
    let newTask = args.data;
    // console.log('handleDragStop args newTask::: ', newTask);

    const events = stateMain.scheduleObjNew.current.getEvents();
    // console.log('handleDragStop scheduleObjNew events::: ', events);

    const filterTaskDuplicateWithResource = _.filter(events, (o) => o.Id !== newTask.Id && o.TaskId === newTask.TaskId && o.ResourceId === newTask.ResourceId && o.memComOldId === newTask.ResourceId);

    if (_.size(filterTaskDuplicateWithResource)) {
      args.cancel = true;
    }

    if (!args.cancel) {
      let newEventData = {};
      if (newTask) {
        newEventData = {
          OrgId: newTask.OrgId,
          ResourceId: newTask.ResourceId,
          memComOldId: newTask.memComOldId,
          StartDate: newTask.StartDate,
          DueDate: newTask.DueDate,
          StartTime: moment(newTask.StartTime).format(dateTimeApi),
          EndTime: moment(newTask.EndTime).format(dateTimeApi),
          Color: newTask.Color,
          CustomerName: newTask.CustomerName,
          Id: newTask.Id,
          TaskId: newTask.TaskId,
          TaskNo: newTask.TaskNo,
          Subject: newTask.Subject,
          Status: newTask.Status,
          ToAddress: newTask.ToAddress,
          AssigneeName: newTask.AssigneeName,
          Reference: newTask.Reference,
          Remark: newTask.Remark
        }
      }
      const responseDrag = await fncDragStopApi(args, typeApi);
      if (responseDrag.data.status.code === 200) {
        stateMain.scheduleObjNew.current.saveEvent(newEventData);
        setEventCurrentOnChange(newEventData);
      }
    }
  }

  const onResizeStop = async (args) => {
    let newTask = args.data;
    const newEventData = {
      OrgId: newTask.OrgId,
      ResourceId: newTask.ResourceId,
      memComOldId: newTask.memComOldId,
      StartDate: newTask.StartDate,
      DueDate: newTask.DueDate,
      StartTime: moment(newTask.StartTime).format(dateTimeApi),
      EndTime: moment(newTask.EndTime).format(dateTimeApi),
      Color: newTask.Color,
      CustomerName: newTask.CustomerName,
      Id: newTask.Id,
      TaskId: newTask.TaskId,
      TaskNo: newTask.TaskNo,
      Subject: newTask.Subject,
      Status: newTask.Status,
      ToAddress: newTask.ToAddress,
      AssigneeName: newTask.AssigneeName,
      Reference: newTask.Reference,
      Remark: newTask.Remark
    };

    const responseResize = await fncResizeStopApi(args, typeApi);
    if (responseResize.status.code === 200) {
      stateMain.scheduleObjNew.current.saveEvent(newEventData);
      setEventCurrentOnChange(newEventData);
    }
  }

  const setEventCurrentOnChange = (newEventData) => {
    const events = stateMain.scheduleObjNew.current.getEvents();
    const filterTaskById = _.filter(events, (o) => o.TaskId === newEventData.TaskId && o.ResourceId !== newEventData.ResourceId);
    if (_.size(filterTaskById)) {
      for (let i = 0; i < _.size(filterTaskById); i++) {
        const event = filterTaskById[i];
        const setEvent = {
          OrgId: event.OrgId,
          ResourceId: event.ResourceId,
          memComOldId: event.memComOldId,
          StartDate: event.StartDate,
          DueDate: event.DueDate,
          StartTime: newEventData.StartTime,
          EndTime: newEventData.EndTime,
          Color: event.Color,
          CustomerName: event.CustomerName,
          Id: event.Id,
          TaskId: event.TaskId,
          TaskNo: event.TaskNo,
          Subject: event.Subject,
          Status: event.Status,
          ToAddress: event.ToAddress,
          AssigneeName: event.AssigneeName,
          Reference: event.Reference,
          Remark: event.Remark
        };
        stateMain.scheduleObjNew.current.saveEvent(setEvent);
      }
    }
  }

  const handleEventClick = (args) => {
    args.cancel = true;

    // console.log('handleEventClick args::: ', args);

    const dblClick = document.getElementsByClassName("e-appointment e-lib e-draggable");
    // console.log('handleEventClick dblClick::: ', dblClick);

    if (args.event.Id) {
      setStateMain.setMainTaskId(args.event.TaskId);
      setStateMain.setSelectTaskData(args.event);
      setStateMain.setVisibleTaskDetailModal(true);
    }

    // dblClick[1].addEventListener('dblclick', function (e) {
    //     if (args.event.Id) {
    //       setStateMain.setMainTaskId(args.event.TaskId);
    //       setStateMain.setSelectTaskData(args.event);
    //       setStateMain.setVisibleTaskDetailModal(true);
    //     }
    //   });

    // dblClick.forEach((item, index) => {
    //   item.addEventListener('dblclick', function (e) {
    //     if (args.event.Id) {
    //       setStateMain.setMainTaskId(args.event.TaskId);
    //       setStateMain.setSelectTaskData(args.event);
    //       setStateMain.setVisibleTaskDetailModal(true);
    //     }
    //   });
    // })
  };

  const onPopupOpen = (args) => {
    args.cancel = true;
  }

  const onActionComplete = async (args) => {
    // console.log('onActionComplete scheduleObjNew::: ', stateMain.scheduleObjNew);

    let scheduleElement = document.getElementById('schedule');

    // console.log('onActionComplete scheduleElement::: ', scheduleElement);

    if (args.requestType === 'toolBarItemRendered') {
      let time15m = scheduleElement.querySelector('.e-schedule-timeScale-15m');
      let time30m = scheduleElement.querySelector('.e-schedule-timeScale-30m');
      let time1h = scheduleElement.querySelector('.e-schedule-timeScale-1h');
      time15m.onclick = () => {
        stateMain.scheduleObjNew.current.timeScale.slotCount = 4;
      }
      time30m.onclick = () => {
        stateMain.scheduleObjNew.current.timeScale.slotCount = 2;
      }
      time1h.onclick = () => {
        stateMain.scheduleObjNew.current.timeScale.slotCount = 1;
      }
    }

    // console.log('onActionComplete requestType:::: ', args.requestType);

    if (stateMain.keyTabMonitor === 'ganttView') {
      if (resourceData_Sch.length > 0) {
        if (args.requestType === 'toolBarItemRendered' || args.requestType === 'viewNavigate' || args.requestType === 'dateNavigate') {
          // stateMain.scheduleObjNew.current.scrollTo(moment().subtract(4.5, 'hours').format('HH:mm'), new Date());
          setTimeout(() => {
            stateMain.scheduleObjNew.current.scrollTo(moment().subtract(4.5, 'hours').format('HH:mm'), new Date())
          }, 5000);
        }
      }
    }
  }

  const onActionBegin = (args) => {
    // console.log('onActionBegin requestType:::: ', args.requestType);

    if (args.requestType === 'toolbarItemRendering') {
      let timeScale15m = {
        align: 'Right',
        text: intl.formatMessage({ id: `monitorGanttViewTime5mWorkDesk`, defaultMessage: '15m', }),
        cssClass: 'e-schedule-timeScale-15m'
      };
      let timeScale30m = {
        align: 'Right',
        text: intl.formatMessage({ id: `monitorGanttViewTime30mWorkDesk`, defaultMessage: '30m', }),
        cssClass: 'e-schedule-timeScale-30m'
      };
      let timeScale1h = {
        align: 'Right',
        text: intl.formatMessage({ id: `monitorGanttViewTime1hWorkDesk`, defaultMessage: '1h', }),
        cssClass: 'e-schedule-timeScale-1h'
      };
      args.items.push(timeScale15m);
      args.items.push(timeScale30m);
      args.items.push(timeScale1h);
    }
  }

  const onDataBinding = (e) => {
    let items = e.result;
    let scheduleData = [];
    if (items.length > 0) {
      items.forEach(item => {
        scheduleData.push(item)
      })
    }
    e.result = scheduleData;
  }

  const onCreatedNowClock = () => {
    if (resourceData_Sch.length > 0) {
      // console.log('onCreatedNowClock resourceData_Sch::: ', resourceData_Sch);
      setTimeout(() => {
        stateMain.scheduleObjNew.current.scrollTo(moment().subtract(4.5, 'hours').format('HH:mm'), new Date());
      }, 1000);
    } else {
      return
    }
  }

  const majorSlotTemplate = (props) => {
    const slotCount = stateMain.scheduleObjNew.current.timeScale;
    return (
      <div>{_.get(slotCount, 'properties.slotCount') === 1 ? instance.formatDate(props.date, { skeleton: 'H' }) : instance.formatDate(props.date, { skeleton: 'hm' })}</div>
    );
  }

  const resourceHeaderTemplate = (props) => {
    const pathImage = _.get(props, 'resourceData.pathImage');
    const field = _.get(props, 'resource.field');
    return (
      <div className='scheduler-header'>
        <div style={{ marginRight: '10px' }}>
          {field === 'OrgId' ? null : <img className='scheduler-header-img' alt='Avatar' src={pathImage} />}
        </div>
        <div>
          <span className='scheduler-header-name'>{getName(props)}</span>
        </div>
      </div>
    )
  }

  const getName = (value) => {
    return value.resourceData[value.resource.textField];
  };

  const handleClickResource = (args) => {
    let getStartDate = '';
    let getEndDate = '';

    const expr = _.get(stateMain, 'scheduleObjNew.current.activeViewOptions.option');
    switch (expr) {
      case 'TimelineDay':
        getStartDate = _.get(stateMain, 'scheduleObjNew.current.activeView.renderDates')[0];
        getEndDate = _.get(stateMain, 'scheduleObjNew.current.activeView.renderDates')[0];
        break;
      case 'TimelineWeek':
        let maxLength = _.get(stateMain, 'scheduleObjNew.current.activeView.renderDates').length - 1;
        getStartDate = _.get(stateMain, 'scheduleObjNew.current.activeView.renderDates')[0];
        getEndDate = _.get(stateMain, 'scheduleObjNew.current.activeView.renderDates')[maxLength];
        break;
      case 'TimelineMonth':
        getStartDate = _.get(stateMain, 'scheduleObjNew.current.activeView.monthDates.start');
        getEndDate = _.get(stateMain, 'scheduleObjNew.current.activeView.monthDates.end');
        break;
      default:
        getStartDate = '';
        getEndDate = '';
    }

    const getElementClassName = args.target.querySelector('.fullname');
    const getClassName = args.target.getAttribute('class');
    let getMemComId = args.target.getAttribute('memComId');
    let getOrgId = args.target.getAttribute('orgId');

    if (!getMemComId || !getOrgId) return;

    const dataAPI = {
      orgId: getOrgId,
      memComId: getMemComId,
      startDate: moment(getStartDate).format('YYYY-MM-DD'),
      endDate: moment(getEndDate).format('YYYY-MM-DD'),
      defailtStartDate: getStartDate,
      defailtEndDate: getEndDate,
    }

    if (getElementClassName
      || _.includes(getClassName, 'fullname')
      || _.includes(getClassName, 'fullname-img')
      || _.includes(getClassName, 'fullname-p')
      || _.includes(getClassName, 'fullname-c')
    ) {
      handleOpenModal(dataAPI);
    }
  }

  const handleOpenModal = (dataAPI) => {
    setDataMemberInfo(dataAPI);
    setVisibleModal(true);
  }

  const handleCloseModal = () => {
    setVisibleModal(false);
    setTimeout(() => {
      setDataMemberInfo(null)
    }, 1000);
  }

  const onRenderCell = (args) => {
    let txtName = intl.formatMessage({ id: 'monitorGanttViewTxtName', defaultMessage: 'Name' });
    if (
      args.elementType === 'emptyCells' &&
      args.element.classList.contains('e-resource-left-td')
    ) {
      let target = args.element.querySelector('.e-resource-text');
      target.innerHTML =
        `<div style='padding: 0px 15px;'><span style='font-size: 14px;'>${txtName}</span></div>`;
    }
  };

  const dateFormat = 'ddd, MMM DD YYYY';

  return (
    <div>
      <ScheduleComponent
        id='schedule'
        cssClass='schedule-scheduleComponent'
        ref={stateMain.scheduleObjNew}
        width='100%'
        height='unset'
        delayUpdate='true'
        currentView='TimelineDay'
        // selectedDate={new Date(2018, 11, 31)}
        locale='en-US'
        // locale='th'
        // dateFormat='yyyy/MM/dd'
        // selectedDate={`${mainDate}`}
        showQuickInfo={false}
        showHeaderBar={true}
        rowAutoHeight={true}
        allowDragAndDrop={true}
        navigating={fnc.onNavigating}
        renderCell={onRenderCell}
        eventRendered={onEventRendered}
        cellDoubleClick={cellDoubleClick}
        resizeStop={onResizeStop}
        dragStop={handleDragStop}
        eventClick={handleEventClick}
        actionBegin={onActionBegin}
        actionComplete={onActionComplete}
        created={onCreatedNowClock}
        // showTimeIndicator={true}
        popupOpen={onPopupOpen}
        dataBinding={onDataBinding}
        onClick={handleClickResource}
        timeScale={{
          enable: true,
          interval: 60,
          slotCount: 2,
          // majorSlotTemplate: majorSlotTemplate
        }}
        eventSettings={{
          dataSource: taskData_Sch,
          filds: {
            id: 'Id',
            subject: { name: 'Subject' },
            startTime: { name: 'StartTime' },
            endTime: { name: 'EndTime' },
            assigneeName: { name: 'AssigneeName' },
            color: { name: 'Color' },
            customerName: { name: 'CustomerName' },
            dueDate: { name: 'DueDate' },
            orgId: { name: 'OrgId' },
            reference: { name: 'Reference' },
            resourceId: { name: 'ResourceId' },
            startDate: { name: 'StartDate' },
            status: { name: 'Status' },
            taskNo: { name: 'TaskNo' },
            toAddress: { name: 'ToAddress' },
            memComOldId: { name: 'memComOldId' },
            remark: { name: 'Remark' },
          },
          enableTooltip: true,
          tooltipTemplate: tooltipData,
        }}
        group={{ resources: ['Groupteam', 'Resources'] }}
      // resourceHeaderTemplate={resourceHeaderTemplate}
      >
        <ResourcesDirective className='resourceDirective'>
          <ResourceDirective
            className='resourceDirective-group-team'
            field='OrgId'
            title={intl.formatMessage({ id: `monitorTabGanttViewTitleResourceDirectiveGroupTeamWorkDesk`, defaultMessage: 'Groupteam' })}
            name='Groupteam'
            dataSource={organizationTeam}
            allowMultiple={false}
            textField='orgName'
            idField='orgId'
          />

          <ResourceDirective
            className='resourceDirective-img'
            field='ResourceId'
            title={intl.formatMessage({ id: `monitorTabGanttViewTitleResourceDirectiveWorkDesk`, defaultMessage: 'Resource' })}
            name='Resources'
            dataSource={resourceData_Sch}
            allowMultiple={true}
            textField='fullname'
            idField='memComId'
            groupIDField='orgId'
          />
        </ResourcesDirective>
        <ViewsDirective>
          <ViewDirective option='TimelineDay' />
          <ViewDirective option='TimelineWeek' />
          <ViewDirective option='TimelineMonth' />
        </ViewsDirective>
        <Inject services={[TimelineViews, TimelineMonth, Resize, DragAndDrop]} />
      </ScheduleComponent>

      <MemberInfomationModal
        dataAPI={dataMemberInfo}
        title={<FormattedMessage id={`memberInformationModalTitleMemberInformationWorkDesk`} defaultMessage='Member Information' />}
        visible={visibleModal}
        onCancel={handleCloseModal}
        LangCode={LangCode}
      />
    </div>
  )
}, (prev, next) => {


  // if (_.isMatch(prev.taskData, next.taskData)) {

  // }
  // return _.isMatch(prev.resourceData, next.resourceData) && _.isMatch(prev.taskData, next.taskData)
});

