import React from 'react'
import { Checkbox, Table, Form } from 'antd'
import { FormattedMessage, useIntl } from 'react-intl'
import _ from 'lodash'
import LabeRequireForm from '../../../../../components/label-required-form'

const ListItemDetail = (props) => {
  const { columns, handleClickRow, onChange, itemList, dataForm } = props
  const intl = useIntl()

  return (
    <div style={{ height: '565px', padding: "16px" }}>
      <div style={{ marginBottom: '24px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'end' }}>
          <Form layout="vertical">
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'purchesModalFormVendor', defaultMessage: 'Vendor or Supplier' })} req={false} />
              }
            >
              {_.get(dataForm, 'customerName')}
            </Form.Item>
          </Form>
          <Checkbox onChange={(e) => onChange(e.target.checked, 'purchase')}><FormattedMessage id="modalListItemDetailRecent" defaultMessage="Recently Purchased Items" /></Checkbox>
        </div>
      </div>
      <div>
        <Table
          columns={columns}
          dataSource={itemList}
          scroll={{ y: 380 }}
          pagination={false}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                if (!event.target.href) {
                  handleClickRow(record)
                }
              },
            };
          }}
        />
      </div>
    </div>
  )
}

export default ListItemDetail