import React, { useState, useEffect } from 'react'
import { Modal, Divider } from 'antd'
import { FormattedMessage, useIntl } from 'react-intl';
import Button01 from '../v2/button_01';
import LocationSearch from './location-search';
import LocationMap from './location-map';
import './css/index.css'
import _ from 'lodash';
import getSearchLocation from '../../function/search-location/getLocation';
import { useAppContext } from '../../includes/indexProvider';

export default (props) => {
  const intl = useIntl();
  const app = useAppContext();
  const latlng = _.get(app, 'state.latlngCompany')
  const { value, customer, fnc } = props
  const { visibleModalLocation,
    locationData,
    center,
    position,
    visibleInfo,
    selectAddress,
    dragMarker,
    latLng,
    visibleCreateAddress,
    toLocation,
    fieldChange,
    valueInput,
    triggerApi,
    addressData,
    selectAddressData,
    editPostData,
  } = value
  const { setVisibleModalLocation,
    setAddressData,
    setCenter,
    setPosition,
    setVisibleInfo,
    setSelectAddress,
    setDragMarker,
    setLatLng,
    setVisibleCreateAddress,
    setToLocation,
    setFieldChange,
    setValueInput,
    setTriggerApi,
    setLocationData,
    setSelectAddressData,
  } = fnc

  const [loading, setLoading] = useState(false)

  const options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0

  };

  useEffect(() => {
    if (_.size(selectAddress) === 0 && editPostData === undefined) {
      // if (navigator.geolocation) {
      //   navigator.geolocation.getCurrentPosition(positionMyself, errorNavigator, options);
      // }
      errorNavigator()
    }
  }, [selectAddress])

  const errorNavigator = async (err) => {
    const area = await getSearchLocation({ address: latlng });
    setSelectAddress([
      {
        address: _.get(area, 'address'),
        lat: _.get(area, 'lat'),
        lng: _.get(area, 'lng'),
        name: _.get(area, 'name'),
        addressId: null
      }
    ])
    setLatLng(
      {
        lat: parseFloat(_.get(area, 'lat')),
        lng: parseFloat(_.get(area, 'lng')),
      }
    )
    setCenter(
      {
        lat: parseFloat(_.get(area, 'lat')),
        lng: parseFloat(_.get(area, 'lng')),
      }
    )
    setPosition(
      {
        lat: parseFloat(_.get(area, 'lat')),
        lng: parseFloat(_.get(area, 'lng')),
      }
    )
  }

  // const positionMyself = async (position) => {
  //   if (position && position !== null && position !== undefined) {
  //     const objLatLng = {
  //       lat: _.get(position, 'coords.latitude'),
  //       lng: _.get(position, 'coords.longitude'),
  //     }
  //     const area = await getSearchLocation({ address: objLatLng });
  //     setSelectAddress([
  //       {
  //         address: _.get(area, 'address'),
  //         lat: _.get(area, 'lat'),
  //         lng: _.get(area, 'lng'),
  //         name: _.get(area, 'name'),
  //         addressId: null
  //       }
  //     ])
  //     setLatLng(
  //       {
  //         lat: parseFloat(_.get(area, 'lat')),
  //         lng: parseFloat(_.get(area, 'lng')),
  //       }
  //     )
  //     setCenter(
  //       {
  //         lat: parseFloat(_.get(area, 'lat')),
  //         lng: parseFloat(_.get(area, 'lng')),
  //       }
  //     )
  //     setPosition(
  //       {
  //         lat: parseFloat(_.get(area, 'lat')),
  //         lng: parseFloat(_.get(area, 'lng')),
  //       }
  //     )
  //   }
  // }

  useEffect(() => {
    const getLocation = async () => {
      if (visibleModalLocation === true) {
        setLoading(true)
        const area = await getSearchLocation({ name: fieldChange });
        setLocationData(area)
        setLoading(false)
      }
    }
    getLocation();
  }, [fieldChange, triggerApi, visibleModalLocation])


  const handleCancel = () => {
    if (addressData) {
      setSelectAddress([{
        address: _.get(addressData, 'address'),
        lat: _.get(addressData, 'lat'),
        lng: _.get(addressData, 'lng'),
        name: _.get(addressData, 'name'),
        addressId: _.get(addressData, 'addressId')
      }])
      setLatLng(
        {
          lat: parseFloat(_.get(addressData, 'lat')),
          lng: parseFloat(_.get(addressData, 'lng')),
        }
      )
      setCenter(
        {
          lat: parseFloat(_.get(addressData, 'lat')),
          lng: parseFloat(_.get(addressData, 'lng')),
        }
      )
      setPosition(
        {
          lat: parseFloat(_.get(addressData, 'lat')),
          lng: parseFloat(_.get(addressData, 'lng')),
        }
      )
      setDragMarker();
      setVisibleInfo(false);
      setLoading(false);
      setSelectAddressData();
      setFieldChange("");
      setValueInput(undefined);

    }
    setVisibleModalLocation(false);
  }

  const handleCreate = () => {
    setAddressData(selectAddress[0]);
    setVisibleModalLocation(false);
    setSelectAddressData();
    setFieldChange("");
    setValueInput(undefined);
    setLoading(false);
  }

  const handleInput = (fieldChang) => {
    setLocationData([]);
    setValueInput(fieldChang);
    handleSearch(fieldChang);
    setSelectAddressData();
  }

  const handleSearch = _.debounce((fieldChang) => {
    setFieldChange(fieldChang ? fieldChang : '');
  }, 500);


  const handleSelectLocation = (e) => {
    setSelectAddressData(e.key)
    const filterData = locationData && locationData.filter((element, index) => {
      return parseFloat(index) === parseFloat(e.key)
    })
    setSelectAddress([
      {
        index: e.key,
        address: _.get(filterData[0], 'address'),
        lat: _.get(filterData[0], 'lat'),
        lng: _.get(filterData[0], 'lng'),
        name: _.get(filterData[0], 'name'),
        addressId: _.get(filterData[0], 'addressBookId') === '' ? null : _.get(filterData[0], 'addressBookId')
      }
    ])

    setLatLng(
      {
        lat: _.get(filterData[0], 'lat'),
        lng: _.get(filterData[0], 'lng'),
      }
    )
    setCenter(
      {
        lat: _.get(filterData[0], 'lat'),
        lng: _.get(filterData[0], 'lng'),
      }
    )
    setPosition(
      {
        lat: _.get(filterData[0], 'lat'),
        lng: _.get(filterData[0], 'lng'),
      }
    )
    setTimeout(() => {
      setVisibleInfo(true)
      setDragMarker(_.get(filterData[0], 'name'));
    }, 500);

  }



  return (
    <div>
      <Modal
        title={intl.formatMessage({ id: 'modalSelectLocationTitleHeader', defaultMessage: 'Select Location' })}
        className='modal-select-location'
        visible={visibleModalLocation}
        centered={true}
        onCancel={handleCancel}
        width={1200}
        footer={[
          <div>
            <Button01 key="submit" type="primary" btnsize='wd_df' onClick={handleCancel}>
              <FormattedMessage id="btnClose" defaultMessage="Close" />
            </Button01>
          </div>
        ]}
      >{
          visibleModalLocation ? (
            <div className='modal-select-location-body'>
              <div className='modal-select-location-left'>
                <LocationSearch
                  value={{
                    locationData,
                    valueInput,
                    selectAddress,
                    loading,
                    selectAddressData,
                  }}
                  fnc={{
                    handleInput,
                    handleSelectLocation,
                  }}
                />
              </div>
              <Divider type='vertical' className='modal-select-location-divider' />
              <div className='modal-select-location-right'>
                <LocationMap
                  value={{
                    center,
                    position,
                    visibleInfo,
                    selectAddress,
                    latLng,
                    dragMarker,
                    setCenter,
                    setPosition,
                  }}
                  fnc={{
                    setVisibleInfo,
                    setSelectAddress,
                    setLatLng,
                    setDragMarker,
                    setVisibleCreateAddress,
                    handleCreate,
                  }}
                />
              </div>
            </div>
          ) : null
        }

      </Modal>
    </div>
  )
}
