import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import _, { debounce } from 'lodash';
import { Select, Divider, Table } from 'antd';
import Button01 from '../v2/button_01';
import moment from 'moment';
import stylesModal from './css/index.css';

const { Option } = Select;

const QuotationReport = (props) => {
  const intl = useIntl();
  const { columns, item, onOpen } = props;

  return (
    <div>
      <div className="quotation-button-add-item">
        <Button01 type="primary" onClick={() => onOpen()}>
          <FormattedMessage id="quotationModalBtnAddItem" defaultMessage="Add Item" />
        </Button01>
      </div>
      <Divider className="card-modal-divider" />
      <div className="card-modal-report">
        <Table
          className="qutation-modal-report-table"
          rowKey={(record, index) => index}
          dataSource={item}
          scroll={item && item.length !== 0 ? { y: 163 } : { x: 'max-content' }}
          columns={columns}
          pagination={false}
        />
      </div>
    </div>
  );
};

export default QuotationReport;
