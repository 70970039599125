import React, { useContext, useEffect, useState } from 'react';
import { Col, Form, Icon, Modal, Row, Select, Tag } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import _ from 'lodash';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import ListItemFilter from './filter';
import { messageLabel } from '../message-component';
import Button_01 from '../v2/button_01';
import { searchPo } from '../../controllers/po/po';
import NoDataBackground from '../no-data-page';
import CustomTableComponent from '../../pages/billing-note/components/table';
import { getTicketData } from '../../controllers/ticket/ticket';
import { savetListReferenceTab } from '../../controllers/reference-tab';

const { Option } = Select;

const SelectReferenceModal = ({
  form,
  title,
  visible,
  onOk,
  onCancel,
  dataForm,
  setSelectItem,
  selectItem,
  refType,
  typeModal,
  dataform,
  typeRef,
  setEventRef
}) => {
  const [reportDataPending, setReportDataPending] = useState({
    quotationList: [],
    quotationTotal: 0,
    extraSort: undefined,
    fieldSort: '',
    orderSort: undefined,
    orderBy: undefined,
    paginationPage: 1,
    paginationSize: 10,
    loading: false,
    quotationNo: '',
    invoiceNo: '',
    paymentTermNo: '',
    saleOrderNo: '',
    customerName: '',
    rangePickerDate: [],
    statusCode: [],
    issueDateStart: '',
    issueDateEnd: '',
  });
  const clearReport = reportDataPending;
  console.log('dataFormCus', dataform);
  const { getFieldDecorator, setFieldsValue, resetFields, getFieldValue } = form;
  const [pageTable, setPageTable] = useState(1);
  const [sizeTable, setSizeTable] = useState(10);
  // const { customerList, contactList, addressList, handleSearchCustomer, resetCustomer } = SelectCustomerHook();
  const [event, setEvent] = useState([]);
  const [tempchange, setTempChange] = useState({});

  const intl = useIntl();

  useEffect(() => {
    setReportDataPending((prev) => ({ ...prev, loading: true }));

    const getApi = async () => {
      let mapOut = [];
      mapOut = _.map(selectItem, (item) => {
        return _.get(item, 'referenceId');
      });

      if (_.get(reportDataPending, 'referenceType') === 'po') {
        mapOut.push(_.get(dataform, 'poId'));
        SearchData(mapOut);
      }

      if (_.get(reportDataPending, 'referenceType') === 'ticket') {
        mapOut.push(_.get(dataform, 'ticketId'));
        getDataTableTicket(mapOut);
      }
    };
    setReportDataPending((prev) => ({ ...prev, loading: false }));
    if (visible === true) {
      getApi();
    }
  }, [
    visible,
    _.get(reportDataPending, 'paginationPage'),
    _.get(reportDataPending, 'paginationSize'),
    _.get(reportDataPending, 'fieldSort'),
    _.get(reportDataPending, 'orderSort'),
    _.get(reportDataPending, 'statusCode'),
    _.get(reportDataPending, 'customerName'),
    pageTable,
    sizeTable,
    event,
  ]);

  const SearchData = async (id) => {
    const body = {
      page: pageTable,
      limit: sizeTable,
      statusCode: _.get(reportDataPending, 'status') || [],
      statusGroup: 'waiting',
      poNo: _.get(reportDataPending, 'referenceNo') || '',
      createdDateFrom: _.get(reportDataPending, 'issueDateStart')
        ? moment(_.get(reportDataPending, 'issueDateStart')).format('YYYY-MM-DD')
        : '',
      createdDateTo: _.get(reportDataPending, 'issueDateEnd') ? moment(_.get(reportDataPending, 'issueDateEnd')).format('YYYY-MM-DD') : '',
      vendorSupplierId: _.get(reportDataPending, 'businessPartnerName') || [],
      orderBy: _.get(reportDataPending, 'fieldSort'),
      orderType: _.get(reportDataPending, 'orderSort'),
      toReference: true,
      ninId: id,
    };
    const reesponse = await searchPo(body);

    setReportDataPending((prev) => ({
      ...prev,
      quotationList: _.get(reesponse, 'data.data.poList'),
      quotationTotal: _.get(reesponse, 'data.data.totalItem'),
    }));
  };

  const getDataTableTicket = async (id) => {
    const body = {
      teamList: [],
      ticketNo: _.get(reportDataPending, 'referenceNo') || '',
      startDate: _.get(reportDataPending, 'issueDateStart') ? moment(_.get(reportDataPending, 'issueDateStart')).format('YYYY-MM-DD') : '',
      endDate: _.get(reportDataPending, 'issueDateEnd') ? moment(_.get(reportDataPending, 'issueDateEnd')).format('YYYY-MM-DD') : '',
      statusList: _.get(reportDataPending, 'status') || [],
      ticketTypeList: [],
      pageNumber: pageTable,
      limit: sizeTable,
      orderBy: _.get(reportDataPending, 'fieldSort'),
      orderType: _.get(reportDataPending, 'orderSort'),
      toReference: true,
      ninId: id,
    };

    const responase = await getTicketData(body);
    setReportDataPending((prev) => ({
      ...prev,
      quotationList: _.get(responase, 'data.data.ticketList'),
      quotationTotal: _.get(responase, 'data.data.totalItems'),
    }));
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      fixed: 'left',
      width: 70,
      render: (text, record, index) => record.index,
    },
    {
      title: messageLabel('ReferenceColumnReferenceNo', 'Reference No.'),
      dataIndex: 'invoiceNo',
      key: 'invoiceNo',
      sorter: true,
      fixed: 'left',
      width: 150,
      ellipsis: true,
    },
    {
      title: messageLabel('ReferenceColumnReferenceType', 'Reference Type'),
      dataIndex: 'saleOrderNo',
      key: 'saleOrderNo',
      sorter: true,
      // width: 200,
    },
    {
      title: messageLabel('ReferenceColumnBussinessPartner', 'Bussiness Partner'),
      dataIndex: 'dueDateTxt',
      key: 'dueDateTxt',
      sorter: true,
      // render: (text, record, index) => _.get(record, 'customerName'),
    },
    {
      title: messageLabel('ReferenceColumnCreatedby', 'Created by'),
      dataIndex: 'issueDateTxt',
      key: 'issueDateTxt',
      sorter: true,
    },
    {
      title: messageLabel('ReferenceColumnCreatedDate', 'Created Date'),
      dataIndex: 'totalTxt',
      key: 'totalTxt',
      sorter: true,
      width: 150,
      align: 'right',
      // render: (text, record, index) => (
      //   // _.get(record, 'total') ? (
      //   <span>{numberFormatDecimal(_.get(record, 'totalTxt'))}</span>
      // ),
      // ) : null,
    },
    // {
    //   title: messageLabel('quotationLabelIssuedBy', 'Issued by'),
    //   dataIndex: 'issueBy',
    //   key: 'issueBy',
    //   sorter: true,
    //   // render: (text, record, index) => '',
    // },
    {
      title: messageLabel('BLRemark', 'Remark'),
      dataIndex: 'remark',
      key: 'remark',
      sorter: true,
      render: (text, record, index) => _.get(record, 'remark') || '-',
    },
  ];

  const columnsPO = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      fixed: 'left',
      align: 'center',
      width: 70,
    },
    {
      title: <FormattedMessage id="purchesOrderColumnsPONo" defaultMessage="PO No." />,
      dataIndex: 'poNo',
      key: 'poNo',
      fixed: 'left',
      width: 180,
      sorter: true,
    },
    {
      title: <FormattedMessage id="purchesOrderColumnsVendorOrSup" defaultMessage="Vendor or Supplier" />,
      dataIndex: 'vendorSupplier',
      key: 'vendorSupplier',
      sorter: true,
      width: 200,
    },
    {
      title: <FormattedMessage id="purchesOrderColumnsItem" defaultMessage="Item" />,
      dataIndex: 'itemAmount',
      key: 'itemAmount',
      sorter: true,
      width: 100,
    },
    {
      title: <FormattedMessage id="purchesOrderColumnsTotalPrice" defaultMessage="Total Price" />,
      dataIndex: 'totolPrice',
      key: 'totolPrice',
      sorter: true,
      align: 'right',
      width: 180,
    },
    {
      title: <FormattedMessage id="purchesOrderColumnsCreatedDate" defaultMessage="Created Date" />,
      dataIndex: 'poDate',
      key: 'poDate',
      sorter: true,
      width: 150,
      render: (text, record, index) => moment(_.get(record, 'poDate'), 'YYYY-MM-DD').format('DD/MM/YYYY'),
    },
    {
      title: <FormattedMessage id="purchesOrderColumnsStatus" defaultMessage="Status" />,
      dataIndex: 'statusName',
      key: 'statusName',
      sorter: true,
      width: 180,
      render: (text, record, index) => (
        <div style={{ textAlign: 'center', display: 'flex' }}>
          <Tag className="tag-center-style" color={record.statusColor}>
            {record.statusName}
          </Tag>
        </div>
      ),
    },
    {
      title: <FormattedMessage id="purchesOrderColumnsIssuedBy" defaultMessage="Issued By" />,
      dataIndex: 'createdBy',
      key: 'createdBy',
      sorter: true,
      width: 150,
    },
    {
      title: <FormattedMessage id="purchesOrderColumnsRemark" defaultMessage="Remark" />,
      dataIndex: 'remark',
      key: 'remark',
      width: 200,
    },
  ];

  const columnsTicket = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: 50,
      fixed: 'left',
      render: (text, record, index) => <span>{index + 1}</span>,
    },
    {
      title: <FormattedMessage id="AllTicketTicketNo" defaultMessage="Ticket No." />,
      dataIndex: 'ticketNo',
      key: 'ticketNo',
      sorter: true,
      fixed: 'left',
      width: 200,
    },
    {
      title: <FormattedMessage id="AllTicketTicketType" defaultMessage="Ticket Type" />,
      dataIndex: 'ticketType',
      key: 'ticketType',
      sorter: true,
      // width: 150,
    },
    {
      title: <FormattedMessage id="AllTicketStatus" defaultMessage="Status" />,
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      width: 150,
      render: (data, record) => {
        return (
          <span>
            <p
              style={{
                // backgroundColor: record['statusColor'],
                backgroundColor: _.get(record, 'statusColor'),
                color: 'white',
                textAlign: 'center',
                borderRadius: '10px',
                height: '23px',
                width: '95px',
              }}
            >
              {data}
            </p>
          </span>
        );
      },
      // width: 200,
    },
    {
      title: <FormattedMessage id="AllTicketdetail" defaultMessage="Ticket Details" />,
      key: 'ticketDetail',
      dataIndex: 'ticketDetail',
      width: 500,
      ellipsis: true,
    },
    {
      title: <FormattedMessage id="AllTicketRequestTeam" defaultMessage="Team." />,
      key: 'team',
      dataIndex: 'team',
      sorter: true,
      width: 200,
    },
    {
      title: <FormattedMessage id="AllticketSubmittedDate" defaultMessage="Submitted Date" />,
      key: 'submittedDate',
      dataIndex: 'submittedDateTxt',
      width: 200,
      sorter: true,
    },
    {
      title: <FormattedMessage id="AllTicketRequiredDate" defaultMessage="Required Date" />,
      key: 'requiredDate',
      dataIndex: 'requiredDateTxt',
      width: 200,
      sorter: true,
    },
    // {
    //   title: <FormattedMessage id="AllTicketdate" defaultMessage="Ticket Date" />,
    //   key: 'ticketDate',
    //   dataIndex: 'ticketDateTxt',
    //   width: 200,
    // }
  ];

  const handleClose = () => {
    onCancel();
    setReportDataPending({
      quotationList: [],
      quotationTotal: 0,
      extraSort: undefined,
      fieldSort: '',
      orderSort: 'asc',
      paginationPage: 1,
      paginationSize: 10,
      loading: false,
      quotationNo: '',
      invoiceNo: '',
      paymentTermNo: '',
      saleOrderNo: '',
      customerName: '',
      rangePickerDate: [],
      statusCode: [],
      issueDateStart: '',
      issueDateEnd: '',
    });
  };

  const onSelectQuotationRow = async (value) => {
    console.log('selelele', value);
    let temp = [...selectItem];
    const payload =
      _.get(reportDataPending, 'referenceType') === 'po'
        ? {
            referenceType: _.get(reportDataPending, 'referenceType'),
            referenceId: _.get(value, 'poId'),
            referenceNo: _.get(value, 'poNo'),
            businessPartnerName: _.get(value, 'vendorSupplier'),
            businessPartnerId: _.get(value, 'vendorSupplierId'),
            createdBy: _.get(value, 'createdById'),
            createdByName: _.get(value, 'createdBy'),
            createdDate: moment(_.get(value, 'createdDate'), 'DD/MM/YYYY').format('YYYY-MM-DD'),
          }
        : {
            referenceType: _.get(reportDataPending, 'referenceType'),
            referenceId: _.get(value, 'ticketId'),
            referenceNo: _.get(value, 'ticketNo'),
            businessPartnerName: _.get(value, 'contactName'),
            businessPartnerId: _.get(value, 'contactRefId'),
            createdBy: _.get(value, 'submittedBy'),
            createdByName: _.get(value, 'submittedByName'),
            createdDate: moment(_.get(value, 'ticketDateTxt'), 'DD/MM/YYYY').format('YYYY-MM-DD'),
          };
    temp.push(payload);
    const mapselectitem = _.map(temp, (item, i) => {
      return {
        ...item,
        index: i + 1,
      };
    });
    setSelectItem(mapselectitem);

    if (typeModal === 'view') {
      const body =
        typeRef === 'po'
          ? {
              referenceType: 'po',
              referenceId: _.get(dataform, 'poId'),
              referenceNo: _.get(dataform, 'poNo'),
              businessPartnerName: _.get(dataform, 'vendorSupplier'),
              businessPartnerId: _.get(dataform, 'vendorSupplierId'),
              createdBy: localStorage.getItem('memComId'),
              // createdByName: _.get(dataform, 'createdBy'),
              createdDate: moment(_.get(dataform, 'issueDate')).format('YYYY-MM-DD'),
              refToList: mapselectitem,
            }
          : {
              referenceType: 'ticket',
              referenceId: _.get(dataform, 'ticketId'),
              referenceNo: _.get(dataform, 'ticketNo'),
              businessPartnerName: _.get(dataform, 'contactName'),
              businessPartnerId: _.get(dataform, 'contactRefId'),
              createdBy: _.get(dataform, 'submittedBy'),
              // createdByName: _.get(dataform, 'submittedByName'),
              createdDate: _.get(dataform, 'submittedDate'),
              // createdDate: moment(_.get(dataform, 'submittedDateTxt')).format('YYYY-DD-MM'),
              refToList: mapselectitem,
            };
      await savetListReferenceTab(body);
      setEventRef((prev) => !prev);
    }
    // setSelectItem((prev) => [...prev, value]);
    handleClose();
  };

  const handleSave = async (value) => {
    // handleOpenModalQuotationPending(value);
    onCancel();
  };

  const handleReplace = (value) => {
    Modal.confirm({
      className: 'maintenance-report-modal-confirm',
      centered: true,
      icon: null,
      title: intl.formatMessage({ id: 'lblReplace', defaultMessage: 'Are you sure you want to replace ?' }),
      okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        if (value) {
          console.log('saleselectvalue', value);
          // handleOpenModalQuotationPending(value);
          onCancel();
        }
      },
      onCancel() {},
    });
  };

  const handleChange = (value, key) => {
    if (key === 'referenceType') {
      setTempChange({ value: value, key: key });
    } else {
      setReportDataPending((prev) => ({ ...prev, [key]: value }));
    }
  };

  const onAllowClear = (value, code) => {
    if (value) {
      setReportDataPending({ ...reportDataPending, [code]: '' });
    }
  };

  const onClick = () => {
    if (tempchange) {
      setReportDataPending((prev) => ({ ...prev, [_.get(tempchange, 'key')]: _.get(tempchange, 'value') }));
      setTempChange();
    }
    setPageTable(1);
    setEvent((prev) => !prev);
  };

  return (
    <Modal
      title={intl.formatMessage({ id: 'SelectReferenceHead', defaultMessage: 'Select Reference' })}
      visible={visible}
      onOk={handleClose}
      onCancel={handleClose}
      width={1120}
      zIndex={1000}
      centered={true}
      footer={[
        <Button_01 key="close" type="primary" btnsize="wd_df" onClick={() => handleClose()}>
          <FormattedMessage id="btnClose" defaultMessage="Close" />
        </Button_01>,
      ]}
    >
      <Row>
        <Col span={6}>
          <div className="listItemTextHeader" style={{ padding: '0px 16px 16px 0px' }}>
            <FormattedMessage id="modalListItemFilterText" defaultMessage="Filter" />
          </div>
          <div className="listItemDividerCenter">
            <ListItemFilter
              onChange={handleChange}
              onAllowClear={onAllowClear}
              searchGroup={reportDataPending}
              onClick={onClick}
              refType={refType}
              tempchange={tempchange}
            />
          </div>
        </Col>
        <Col span={18}>
          <div className="listItemTextHeader" style={{ paddingTop: '0px' }}>
            <FormattedMessage id="ReferenceTitleModal" defaultMessage="Reference" />
          </div>
          <div style={{ height: '625px', padding: '16px' }}>
            {_.get(reportDataPending, 'referenceType') && _.size(_.get(reportDataPending, 'quotationList')) > 0 ? (
              <CustomTableComponent
                columns={_.get(reportDataPending, 'referenceType') === 'po' ? columnsPO : columnsTicket}
                rowKey={(record) => record.quotationId}
                dataSource={_.get(reportDataPending, 'quotationList')}
                // scroll={{ x: true }}
                total={_.get(reportDataPending, 'quotationTotal')}
                role={false}
                onRow={false}
                onRowClick={(o) => onSelectQuotationRow(o)}
                paginationPage={pageTable}
                setPaginationPage={setPageTable}
                paginationShow={sizeTable}
                setPaginationSize={setSizeTable}
                scroll={{ x: true }}
              />
            ) : (
              <div style={{ padding: '24px', backgroundColor: '#fff' }}>
                <NoDataBackground
                  text1={{ id: 'NodataReferenceType', text: 'Please Select Reference Type' }}
                  text2={{ id: 'NodataReferenceType2.', text: 'Item will appear here.' }}
                  paddingCustom={0}
                />
              </div>
            )}
          </div>
        </Col>
      </Row>
    </Modal>
  );
};

SelectReferenceModal.defaultProps = {
  // const intl = useIntl();
  // title: ,
  // title: intl.formatMessage({ id: 'quotationLabelSelectCustomer', defaultMessage: 'Select Customer' }),
  visible: false,
  onOk: () => console.warn('onOk not function '),
  onCancel: () => console.warn('onCancel not function '),
};

const ModalSelectReferenceForm = Form.create({ name: 'select_qt_Form' })(SelectReferenceModal);

export default ModalSelectReferenceForm;
