import React from 'react';
import { PageSettings } from '../../config/page-settings';
import { Card, Empty } from 'antd';
import { FormattedMessage } from 'react-intl';
import image from '../../../src/components/image/Home_Storyset.svg';
import styles from './css/dashboard.css';

export default () => {
  return (
    <PageSettings.Consumer>
      {({ checkPermissionAction }) => (
        <Card className="dashboard-card">
          <Empty
            className="dashboard-empty"
            image={image}
            imageStyle={{
              width: '276px',
              height: '276px',
              margin: '0px 21px',
              padding: '49.2 0 48.9',
              backgroundColor: '#ffffff',
              justifyContent: 'center',
              display: 'inline-flex',
              alignItems: 'center',
            }}
            description={
              <div>
                <div className="inline-tx">
                  <p className="p-label">
                    <FormattedMessage id="dashboardLblWelcome" defaultMessage="Welcome to" />
                  </p>
                  <li></li>
                  <p className="p-rapidwork-label">
                    <FormattedMessage id="dashboardLblWelcome2" defaultMessage="RapidWork" />
                  </p>
                </div>

                <p className="p-text">
                  <FormattedMessage id="dashboardTxtWorkDOne" defaultMessage="Maximize your business resources with the right" /> <br />
                  <FormattedMessage id="dashboardTxtWorkDTwo" defaultMessage="management and tracking solution :D" />
                </p>
              </div>
            }
          />
        </Card>
      )}
    </PageSettings.Consumer>
  );
};
