import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal, notification } from 'antd';
import Button01 from '../../../components/v2/button_01';
import Button02 from '../../../components/v2/button_02';
import Tag from './tag';
import Role from './role';
import ApiInvite from '../../../components/httpClient';
import clientNew from '../../../components/axiosClient';
import '../css/inviteUser.css';

let client = ApiInvite();

export default (props) => {
  const [visible, setVisible] = useState(false);
  const [roleSelectOption, setRoleSelectOption] = useState([]);
  const [roleId, setRoleId] = useState([]);
  const [roleName, setRoleName] = useState([]);
  const [refreshState, setRefreshState] = useState();

  const [roleSelected, setRoleSelected] = useState();
  const [tagSelected, setTagSelected] = useState();
  const [inviteData, setInviteData] = useState(true);
  const [data, setData] = useState();

  localStorage.setItem('roleSelect', JSON.stringify(roleSelectOption));
  localStorage.setItem('roleId', JSON.stringify(roleId));
  localStorage.setItem('roleName', JSON.stringify(roleName));

  const memComId = localStorage.getItem('memComId');
  const comId = localStorage.getItem('comId');

  const getRoleFunction = (role) => {
    setRoleSelected(role);
    checkValueSelected();
  };

  const getTagFunction = (tag) => {
    setTagSelected(tag);
    checkValueSelected();
  };

  const checkValueSelected = () => {
    if (roleSelected == undefined || roleSelected == '' || tagSelected == undefined || tagSelected == '') {
      setInviteData(true);
    } else {
      setInviteData(false);
    }
  };

  useEffect(() => {
    getRoleApi();
  }, []);

  useEffect(() => {
    checkValueSelected();
  }, [roleSelected, tagSelected]);

  const langValue = localStorage.getItem('langValue');

  const getApi = async (email, role) => {
    const data = {
      company_id: comId,
      created_by: memComId,
      email: email,
      role: role,
    };

    client.defaults.headers = {
      'Content-Type': 'application/json',
    };
    const res = await clientNew.post(`/v2/invite`, data);
    const reponseText = res.data.data;

    if (res.data.status == 200) {
      successNotification('success', reponseText);
      setData(res);
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    } else if (res.status == 202) {
      successNotification('error', reponseText ? reponseText : 'These users have invited already');
    }
  };

  const getRoleApi = async (id) => {
    try {
      const res = await clientNew.get(`/v2/permissiongroups/${memComId}`);
      let newCompany = [];
      let newCompanyId = [];
      let newCompanyName = [];
      for (let i = 0; i < res.data.length; i++) {
        newCompany.push(res.data[i]);
        newCompanyId.push(`${res.data[i].per_gro_id}`);
        newCompanyName.push(`${res.data[i].name}`);
      }
      setRoleSelectOption(newCompany);
      setRoleId(newCompanyId);
      setRoleName(newCompanyName);
    } catch (error) {}
  };

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = (e) => {
    setVisible(false);
    const email = JSON.parse(localStorage.getItem('emailTag'));
    const role = JSON.parse(localStorage.getItem('selectedRole'));

    getApi(email, role);
    setRefreshState('OK');
  };

  const handleCancel = (e) => {
    setVisible(false);
    setRoleSelected();
    setTagSelected();
  };

  const successNotification = (type, e) => {
    notification[type]({
      message: e,
      duration: 4.5,
    });
  };

  return (
    <div style={{ textAlign: 'center' }}>
      <Button01 type="primary" btnsize="wd_at" onClick={showModal}>
        <FormattedMessage id="userMgntUsersBtnInviteUsers" defaultMessage="Invite Users" />
      </Button01>

      <Modal
        className={'modal-style'}
        title={
          <span className="title-modal-text">
            <FormattedMessage id="userMgntUserModalLblInvite" defaultMessage="Invite Users" />
          </span>
        }
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button02 style={{ margin: '0px 0px 0px 10px' }} className="invitebtn_user_ex" key="back" btnsize="wd_df" onClick={handleCancel}>
            <FormattedMessage id="userMgntUserModalBtnCancel" defaultMessage="Cancel" />
          </Button02>,
          <Button01 className="invitebtn_user_ex2" key="submit" btnsize="wd_at" type="primary" onClick={handleOk}>
            <FormattedMessage id="userMgntUserModalBtnAdd" defaultMessage="Invite" />
          </Button01>,
        ]}
      >
        <span className="suggest-text-style">
          <FormattedMessage
            id="userMgntUserModalLblEmailPhone"
            defaultMessage="We'll invite your new users to the site and onboard them. During signup they'll enter their full name."
          />
        </span>

        <Tag comId={comId} lang={langValue} pageCode={props.pageCode} visible={visible} functionCallTag={getTagFunction} />
        <p style={{ color: '#000000CC', marginBottom: '24px', marginTop: '10px' }}>
          <FormattedMessage
            id="userMgntUserModalTxtEmailPhone"
            defaultMessage="Enter up to 10 email addresses or phone numbers, separated by a comma."
          />
        </p>
        <span className="role-text-style">
          <FormattedMessage id="userMgntUserModalLblRole" defaultMessage="Roles" />{' '}
        </span>
        <Role comId={comId} lang={langValue} pageCode={props.pageCode} visible={visible} functionCallRole={getRoleFunction} />
        <p style={{ color: '#000000CC', fontSize: '14px', marginTop: '10px' }}>
          <FormattedMessage id="userMgntUserModalTxtRole" defaultMessage="Customized roles give users access to specific functions." />
        </p>
      </Modal>
    </div>
  );
};
