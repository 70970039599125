import React from 'react'
import { Form, Input, Select, Modal } from 'antd'
import { useIntl, FormattedMessage } from 'react-intl'
import Button02 from '../../../../components/v2/button_02'
import Button01 from '../../../../components/v2/button_01'
import '../../index.css'
import LabeRequireForm from '../../../../components/label-required-form'
import _ from 'lodash'

const { Option } = Select
const { TextArea } = Input

const ModalStatusForm = (props) => {
  const { visible, setVisible, data, form, handleSave, rejectList,title } = props
  const { resetFields, validateFields, getFieldDecorator } = form
  const intl = useIntl()

  const handleCancel = () => {
    setVisible()
    resetFields()
  }

  const handleConfirmStatus = () => {
    validateFields(async (err, values) => {
      if (err) {
        return;
      }
      handleSave({ status: 'reject', remark: _.get(values, 'remark'), reasonId: _.get(values, 'reason') })
      setVisible()
      resetFields()
    })

  }

  return (
    <Modal
      title={title}
      visible={visible}
      width={370}
      centered={true}
      onCancel={handleCancel}
      footer={[
        <Button02 key="back" btnsize="wd_df" onClick={handleCancel}>
          <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
        </Button02 >,
        <Button01 key="submit" type="primary" btnsize="wd_df" onClick={handleConfirmStatus}>
          <FormattedMessage id="btnSave" defaultMessage="Save" />
        </Button01>
      ]}
    >
      <Form form={form} layout="vertical" className="resourceAllowanceForm">
        <Form.Item
          label={
            <LabeRequireForm text={intl.formatMessage({ id: 'resourceAllowanceModalStatusReason', defaultMessage: 'Reason' })} req={true} />
          }
        >
          {getFieldDecorator('reason', {
            rules: [
              {
                required: true,
                message: intl.formatMessage({ id: 'resourceAllowanceModalStatusReasonVLD', defaultMessage: 'Please Select Reason' }),
              },
            ],
          })(
            <Select
              placeholder={intl.formatMessage({ id: 'resourceAllowanceModalStatusReasonPHD', defaultMessage: 'Select Reason' })}
            >
              {
                _.map(rejectList, (item) => <Option key={item.id ? item.id :item.reasonId}>{item.txt}</Option>)
              }
            </Select>
          )}
        </Form.Item>
        <Form.Item
          label={
            <LabeRequireForm text={intl.formatMessage({ id: 'resourceAllowanceModalStatusRemark', defaultMessage: 'Remark' })} req={false} />
          }
        >
          {getFieldDecorator('remark', {
          })(
            <TextArea
              autosize={{ minRows: 3, maxRows: 3 }}
              maxLength={250}
            />
          )}
        </Form.Item>
      </Form>
    </Modal>
  )
}
const ModalStatus = Form.create({
  name: 'allowance-status-form',
  mapPropsToFields() { },
})(ModalStatusForm);

export default ModalStatus
