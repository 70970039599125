import React, { useState, useEffect, useRef } from 'react';
import { Checkbox, Col, Row, Collapse, Tree, Icon } from 'antd';
import '../css/summary.css';
import _ from 'lodash';
import { useIntl } from 'react-intl';

const CheckboxGroup = Checkbox.Group;

export default React.memo(
  (props) => {
    const {
      data,
      type,
      checkedListStage,
      checkedListSla,
      checkedListAssign,
      checkedListMap,
      checkedListTaskType,
      setCheckedListStage,
      setCheckedListSla,
      setCheckedListAssign,
      setCheckedListMap,
      setCheckedListTaskType,
      mainOrgId,
      checkedListStageCount,
      checkedListAssignCount,
      summaryData,
      setPaginationPage,
      LangCode,
      setCheckedListTeam,
      checkedListTeam,
      orgData,
      setMainOrgId,
      setOrgIdRaw,
      orgIdRaw,
      selectGroupBy,
      keyTabMonitor,
      warningCheck,
      deleyCheck,
      compleCheck,
      setEventSelectGroup,
    } = props;

    const intl = useIntl();

    const planOptionsMap = data && _.get(data, 'mapOption.mapOptionList').map((item) => item.name);

    console.log('Dataplan', planOptionsMap);

    const stage = _.get(summaryData, 'stage') ? _.get(summaryData, 'stage') : [];
    const assignment = _.get(summaryData, 'assignment') ? _.get(summaryData, 'assignment') : [];
    const team = _.get(summaryData, 'teamChkbox') ? _.get(summaryData, 'teamChkbox') : [];

    const planOptions =
      data &&
      _.get(data, 'statusStage.stageList').map((item, index) => {
        if (_.size(stage)) {
          const count = item.statusGroupId === (stage && stage[index].statusGroupId) ? stage[index].count : 0;
          return {
            label: item.statusGroupName + ' ' + `(${count || 0})`,
            value: item.statusGroupName,
          };
        } else {
          return {
            label: item.statusGroupName + ' ' + `(${0})`,
            value: item.statusGroupName,
          };
        }
      });

    const planOptionsTaskType = 
      data && _.get(data, 'selectTaskType.mapOptionList').map((item, index) => {
        return {
          label: item.name,
          value: item.name
        }
      })

    const planOptionsAssignment =
      data &&
      _.get(data, 'assignment.assignmentList').map((item, index) => {
        if (_.size(assignment)) {
          const count = item.code === (assignment && assignment[index].code) ? assignment[index].count : 0;
          return {
            label: item.name + ' ' + `(${count || 0})`,
            value: item.name,
          };
        } else {
          return {
            label: item.name + ' ' + `(${0})`,
            value: item.name,
          };
        }
      });

    const planOptionsSla =
      data &&
      _.get(data, 'sla.slaList').map((item) => {
        if (item.code === 'on_track') {
          return {
            label: item.name + ' ' + `(${_.get(summaryData, 'dataOntrack') || 0})`,
            value: item.name,
          };
        } else if (item.code === 'warning') {
          return {
            label: item.name + ' ' + `(${_.get(summaryData, 'dataWarning') || 0})`,
            value: item.name,
          };
        } else if (item.code === 'delay') {
          return {
            label: item.name + ' ' + `(${_.get(summaryData, 'dataTimeout') || 0})`,
            value: item.name,
          };
        }
      });

    const planOptionsTeam =
      data &&
      _.get(data, 'teamChkbox.teamChkboxList').map((item, index) => {
        if (_.size(team)) {
          // const count = item.orgId === (team && team[index].orgId) ? team[index].count : `(0/0)`;
          // const filter =
          //   team &&
          //   team.filter((e) => {
          //     return e.orgId === item.orgId;
          //   });

          return {
            // label: item.orgName + ' ' + _.get(filter, '[0].count') || `(0/0)`,
            label: item.orgName,
            value: item.orgId,
          };
        } else {
          return {
            // label: item.orgName + ' ' + `(0/0)`,
            label: item.orgName,
            value: item.orgId,
          };
        }
      });

    const [visiblePanelStage, setVisiblePanelStage] = useState(false);
    const [checkAllStage, setCheckAllStage] = useState(true);
    const [indeterminateStage, setIndeterminateStage] = useState(false);

    const [visiblePanelSla, setVisiblePanelSla] = useState(false);
    const [checkAllSla, setCheckAllSla] = useState(true);
    const [indeterminateSla, setIndeterminateSla] = useState(false);

    const [visiblePanelAssign, setVisiblePanelAssign] = useState(false);
    const [checkAllAssign, setCheckAllAssign] = useState(true);
    const [indeterminateAssign, setIndeterminateAssign] = useState(false);

    const [visiblePanelMap, setVisiblePanelMap] = useState(false);
    const [checkAllMap, setCheckAllMap] = useState(false);
    const [indeterminateMap, setIndeterminateMap] = useState(false);

    const [visiblePanelTeam, setVisiblePanelTeam] = useState(false);
    const [checkAllTeam, setCheckAllTeam] = useState(false);
    const [indeterminateTeam, setIndeterminateTeam] = useState(false);

    const [visiblePanelTaskType, setVisiblePanelTaskType] = useState(false);
    const [checkAllTaskType, setCheckAllTaskType] = useState(true);
    const [indeterminateTaskType, setIndeterminateTaskType] = useState(false);

    useEffect(() => {
      if (_.get(warningCheck, 'length') !== 0) {
        setCheckedListSla(warningCheck);
        setCheckAllSla(false);
        setIndeterminateSla(true);
        setIndeterminateStage(false);
        setCheckAllStage(true);
        const defaultCheckedList =
          data && _.get(data, 'statusStage.stageList').map((item) => (item.checked === true ? item.statusGroupName : undefined));
        setCheckedListStage(defaultCheckedList);
      }
    }, [warningCheck]);

    useEffect(() => {
      if (_.get(deleyCheck, 'length') !== 0) {
        setCheckedListSla(deleyCheck);
        setCheckAllSla(false);
        setIndeterminateSla(true);
        setIndeterminateStage(false);
        setCheckAllStage(true);
        const defaultCheckedList =
          data && _.get(data, 'statusStage.stageList').map((item) => (item.checked === true ? item.statusGroupName : undefined));
        setCheckedListStage(defaultCheckedList);
      }
    }, [deleyCheck]);

    useEffect(() => {
      console.log('testcome', compleCheck);
      if (_.get(compleCheck, 'length') !== 0) {
        setCheckedListStage(compleCheck);
        setCheckAllStage(false);
        setIndeterminateStage(true);
        setIndeterminateSla(false);
        setCheckAllSla(true);
        const defaultCheckedList = data && _.get(data, 'sla.slaList').map((item) => (item.checked === true ? item.name : undefined));
        setCheckedListSla(defaultCheckedList);
      }
    }, [compleCheck]);

    useEffect(() => {
      if (_.get(data, 'statusStage')) {
        const defaultCheckedList =
          data && _.get(data, 'statusStage.stageList').map((item) => (item.checked === true ? item.statusGroupName : undefined));
        if (setCheckedListStage) {
          setCheckedListStage(defaultCheckedList);
        }
        setIndeterminateStage(_.size(defaultCheckedList) === _.size(data.statusStage.stageList) ? false : true);
      }
      if (_.get(data, 'sla')) {
        const defaultCheckedList = data && _.get(data, 'sla.slaList').map((item) => (item.checked === true ? item.name : undefined));
        if (setCheckedListSla) {
          setCheckedListSla(defaultCheckedList);
        }
        // else if(warningCheck) {
        //   console.log('defaultCheckedList1',warningCheck);
        //   setCheckedListSla(warningCheck);
        //   setIndeterminateSla(_.size(warningCheck) === _.size(data.sla.slaList) ? false : true);
        // }
        setIndeterminateSla(_.size(defaultCheckedList) === _.size(data.sla.slaList) ? false : true);
      }

      if (_.get(data, 'selectTaskType')) { // รอทำ
        const defaultCheckedList =
          data && _.get(data, 'selectTaskType.mapOptionList').map((item) => 
            (item.checked === true ? item.name : undefined)
        );
        if (setCheckedListTaskType) {
          setCheckedListTaskType(defaultCheckedList);
        }
        setIndeterminateTaskType(false);
        // setIndeterminateTaskType(_.size(defaultCheckedList) === _.size(data.assignment.assignmentList) ? false : true);
      }


      if (_.get(data, 'assignment')) {
        const defaultCheckedList =
          data && _.get(data, 'assignment.assignmentList').map((item) => (item.checked === true ? item.name : undefined));
        if (setCheckedListAssign) {
          setCheckedListAssign(defaultCheckedList);
        }
        setIndeterminateAssign(_.size(defaultCheckedList) === _.size(data.assignment.assignmentList) ? false : true);
      }
      if (_.get(data, 'mapOption') && _.size(mainOrgId) !== 0) {
        const defaultCheckedList =
          data && _.get(data, 'mapOption.mapOptionList').map((item) => (item.checked === true ? item.name : undefined));
        if (setCheckedListMap) {
          // setCheckedListMap(defaultCheckedList);
        }
        setIndeterminateMap(_.size(defaultCheckedList) === _.size(data.mapOption.mapOptionList) ? false : true);
      }

      if (_.get(data, 'teamChkbox')) {
        const defaultCheckedList =
          data && _.get(data, 'teamChkbox.teamChkboxList').map((item) => (item.checked === true ? item.orgId : undefined));
        if (setCheckedListTeam) {
          // setCheckedListTeam(defaultCheckedList);
        }
        // setIndeterminateTeam(_.size(defaultCheckedList) === _.size(_.get(data, 'teamChkbox.teamChkboxList')) ? false : true);
      }
    }, [data]);

    useEffect(() => {
      const stringOrgId =
        orgIdRaw &&
        orgIdRaw.map((item) => {
          return item.toString();
        });
      if (_.size(orgIdRaw) === _.size(planOptionsTeam)) {
        setCheckAllTeam(planOptionsTeam);
      } else {
        setCheckAllTeam(false);
      }
      setIndeterminateTeam(!!orgIdRaw.length && orgIdRaw.length < planOptionsTeam.length);
      setCheckedListTeam(stringOrgId);
    }, [orgIdRaw]);

    useEffect(() => {
      const defaultCheckedList =
        data && _.get(data, 'teamChkbox.teamChkboxList').map((item) => (item.checked === true ? item.orgId : undefined));
      // console.log('checked :::', defaultCheckedList, checkedListTeam);
      if (selectGroupBy === 'customer' && keyTabMonitor === '1') {
        if (_.get(data, 'teamChkbox')) {
          if (setCheckedListTeam) {
            setCheckAllTeam(true);
            setCheckedListTeam(defaultCheckedList);
            setOrgIdRaw(defaultCheckedList);
          }
          setIndeterminateTeam(_.size(defaultCheckedList) === _.size(_.get(data, 'teamChkbox.teamChkboxList')) ? false : true);
        }
      }
      // else if (keyTabMonitor === '2') {
      //   if (_.size(checkedListTeam) === 0) {
      //     if (_.get(data, 'teamChkbox')) {
      //       if (setCheckedListTeam) {
      //         setCheckAllTeam(true);
      //         setCheckedListTeam(defaultCheckedList);
      //         setOrgIdRaw(defaultCheckedList);
      //       }
      //       setIndeterminateTeam(_.size(defaultCheckedList) === _.size(_.get(data, 'teamChkbox.teamChkboxList')) ? false : true);
      //     }
      //   }
      // }

      // if (selectGroupBy === 'customer' || keyTabMonitor === '2') {
      //   if (_.get(data, 'teamChkbox')) {
      //     if (setCheckedListTeam) {
      //       setCheckAllTeam(true);
      //       setCheckedListTeam(defaultCheckedList);
      //       setOrgIdRaw(defaultCheckedList);
      //     }
      //     setIndeterminateTeam(_.size(defaultCheckedList) === _.size(_.get(data, 'teamChkbox.teamChkboxList')) ? false : true);
      //   }
      // } else {
      //   if (keyTabMonitor === '2') {
      //     setCheckAllTeam(false);
      //   }
      // }
    }, [selectGroupBy, keyTabMonitor]);

    const handleCheckAllStage = (e) => {
      const planOptionss =
        data &&
        _.get(data, 'statusStage.stageList').map((item, index) => {
          if (_.size(stage)) {
            const count = item.statusGroupId === (stage && stage[index].statusGroupId) ? stage[index].count : 0;
            return item.statusGroupName;
          } else {
            return item.statusGroupName;
          }
        });
      setCheckedListStage(e.target.checked ? planOptionss : []);
      setIndeterminateStage(false);
      setCheckAllStage(e.target.checked);
      setPaginationPage(1);
    };

    const handleCheckAllSla = (e) => {
      const planOptionsSlaa =
        data &&
        _.get(data, 'sla.slaList').map((item) => {
          if (item.code === 'on_track') {
            return item.name;
          } else if (item.code === 'warning') {
            return item.name;
          } else if (item.code === 'delay') {
            return item.name;
          }
        });
      setCheckedListSla(e.target.checked ? planOptionsSlaa : []);
      setIndeterminateSla(false);
      setCheckAllSla(e.target.checked);
      setPaginationPage(1);
    };

    const handleCheckAllTaskType = (e) => { //รอทำ
      const planOptionsTaskType =
        data &&
        _.get(data, 'selectTaskType.mapOptionList').map((item, index) => {
          if (_.size(assignment)) {
            // const count = item.code === (assignment && assignment[index].code) ? assignment[index].count : 0;
            return item.name;
          } else {
            return item.name;
          }
        });
      setCheckedListTaskType(e.target.checked ? planOptionsTaskType : []);
      setIndeterminateTaskType(false);
      setCheckAllTaskType(e.target.checked);
      setPaginationPage(1);
    }

    const handleCheckAllAssign = (e) => {
      const planOptionsAssignmentt =
        data &&
        _.get(data, 'assignment.assignmentList').map((item, index) => {
          if (_.size(assignment)) {
            const count = item.code === (assignment && assignment[index].code) ? assignment[index].count : 0;
            return item.name;
          } else {
            return item.name;
          }
        });
      setCheckedListAssign(e.target.checked ? planOptionsAssignmentt : []);
      setIndeterminateAssign(false);
      setCheckAllAssign(e.target.checked);
      setPaginationPage(1);
    };

    const handleCheckAllMap = (e) => {
      setCheckedListMap(e.target.checked ? planOptionsMap : []);
      setIndeterminateMap(false);
      setCheckAllMap(e.target.checked);
      setPaginationPage(1);
    };

    const handleCheckAllTeam = (e) => {
      const planOptionsTeamm = data && _.get(data, 'teamChkbox.teamChkboxList').map((item) => item.orgId);
      console.log('Allteam',e);
      setCheckedListTeam(e.target.checked ? planOptionsTeamm : []);
      setOrgIdRaw(e.target.checked ? planOptionsTeamm : []);
      setIndeterminateTeam(false);
      setCheckAllTeam(e.target.checked);
      setPaginationPage(1);
      setEventSelectGroup(true)
    };

    const onChangeGroupStage = (checkedList) => {
      setCheckedListStage(checkedList);
      setCheckAllStage(checkedList.length === planOptions.length);
      setIndeterminateStage(!!checkedList.length && checkedList.length < planOptions.length);
      setPaginationPage(1);
    };

    const onChangeGroupSla = (checkedList) => {
      console.log('checkSla', checkedList);
      setCheckedListSla(checkedList);
      setCheckAllSla(checkedList.length === planOptionsSla.length);
      setIndeterminateSla(!!checkedList.length && checkedList.length < planOptionsSla.length);
      setPaginationPage(1);
    };

    const onChangeGroupTaskType = (checkedList) => { // รอทำ
      setCheckedListTaskType(checkedList);
      setCheckAllTaskType(checkedList.length === planOptionsTaskType.length);
      setIndeterminateTaskType(!!checkedList.length && checkedList.length < planOptionsTaskType.length);
      setPaginationPage(1);
    }

    const onChangeGroupAssign = (checkedList) => {
      setCheckedListAssign(checkedList);
      setCheckAllAssign(checkedList.length === planOptionsAssignment.length);
      setIndeterminateAssign(!!checkedList.length && checkedList.length < planOptionsAssignment.length);
      setPaginationPage(1);
    };

    const onChangeGroupMap = (checkedList) => {
      console.log('MapCheck', checkedList);
      setCheckedListMap(checkedList);
      setCheckAllMap(checkedList.length === planOptionsMap.length);
      setIndeterminateMap(!!checkedList.length && checkedList.length < planOptionsMap.length);
      setPaginationPage(1);
    };

    const onChangeGroupTeam = (checkedList) => {
      console.log('checkedList', checkedList);
      setCheckedListTeam(checkedList);
      setOrgIdRaw(checkedList);
      setCheckAllTeam(checkedList.length === planOptionsTeam.length);
      setIndeterminateTeam(!!checkedList.length && checkedList.length < planOptionsTeam.length);
      setPaginationPage(1);
      setEventSelectGroup(true)
    };

    const headerTitleStage = (res) => {
      return (
        <div>
          <Row>
            <Col span={14}>
              <Checkbox indeterminate={indeterminateStage} onChange={handleCheckAllStage} checked={checkAllStage}>
                <span>{_.get(res, 'stageName')}</span>
              </Checkbox>
            </Col>
            <Col span={8} style={{textAlign: 'right'}}>
              <span className="select-text-panel-style">{`${intl.formatMessage({
                id: `filterTaskSelected${LangCode}`,
                defaultMessage: 'Selected',
              })} ${_.size(checkedListStage)}`}</span>
            </Col>
            <Col span={2} onClick={() => setVisiblePanelStage((current) => !current)} style={{ cursor: 'pointer',textAlign: 'right' }}>
              {visiblePanelStage ? <Icon type="up" /> : <Icon type="down" />}
            </Col>
          </Row>
        </div>
      );
    };

    const headerTitleTaskType = (res) => { // รอทำต่อ

      return (
        <div>
          <Row>
            <Col span={14}>
              <Checkbox indeterminate={indeterminateTaskType} onChange={handleCheckAllTaskType} checked={checkAllTaskType}>
                <span>{_.get(res, 'taskTypeName')}</span>
              </Checkbox>
            </Col>
            <Col span={8} style={{textAlign: 'right'}}>
              <span className="select-text-panel-style">{`${intl.formatMessage({
                id: `filterTaskSelected${LangCode}`,
                defaultMessage: 'Selected',
              })} ${_.size(checkedListTaskType)}`}</span>
            </Col>
            <Col span={2} onClick={() => setVisiblePanelTaskType((current) => !current)} style={{ cursor: 'pointer',textAlign: 'right' }}>
              {visiblePanelTaskType ? <Icon type="up" /> : <Icon type="down" />}
            </Col>
          </Row>
        </div>
      );
    };

    const headerTitleSla = (res) => {
      return (
        <div>
          <Row>
            <Col span={14}>
              <Checkbox indeterminate={indeterminateSla} onChange={handleCheckAllSla} checked={checkAllSla}>
                <span>{_.get(res, 'slaName')}</span>
              </Checkbox>
            </Col>
            <Col span={8} style={{textAlign: 'right'}}>
              <span className="select-text-panel-style">{`${intl.formatMessage({
                id: `filterTaskSelected${LangCode}`,
                defaultMessage: 'Selected',
              })} ${_.size(checkedListSla)}`}</span>
            </Col>
            <Col span={2} onClick={() => setVisiblePanelSla((current) => !current)} style={{ cursor: 'pointer',textAlign: 'right' }}>
              {visiblePanelSla ? <Icon type="up" /> : <Icon type="down" />}
            </Col>
          </Row>
        </div>
      );
    };

    const headerTitleAssign = (res) => {
      return (
        <div>
          <Row>
            <Col span={14}>
              <Checkbox indeterminate={indeterminateAssign} onChange={handleCheckAllAssign} checked={checkAllAssign}>
                <span>{_.get(res, 'assignmentName')}</span>
              </Checkbox>
            </Col>
            <Col span={8} style={{textAlign: 'right'}}>
              <span className="select-text-panel-style">{`${intl.formatMessage({
                id: `filterTaskSelected${LangCode}`,
                defaultMessage: 'Selected',
              })} ${_.size(checkedListAssign)}`}</span>
            </Col>
            <Col span={2} onClick={() => setVisiblePanelAssign((current) => !current)} style={{ cursor: 'pointer',textAlign: 'right' }}>
              {visiblePanelAssign ? <Icon type="up" /> : <Icon type="down" />}
            </Col>
          </Row>
        </div>
      );
    };

    const headerTitleMap = (res) => {
      return (
        <div>
          <Row>
            <Col span={14}>
              <Checkbox
                indeterminate={indeterminateMap}
                onChange={handleCheckAllMap}
                checked={checkAllMap}
                disabled={_.size(mainOrgId) !== 0 ? false : true}
              >
                <span>{_.get(res, 'mapOptionName')}</span>
              </Checkbox>
            </Col>
            <Col span={8} style={{textAlign: 'right'}}>
              <span className="select-text-panel-style">{`${intl.formatMessage({
                id: `filterTaskSelected${LangCode}`,
                defaultMessage: 'Selected',
              })} ${_.size(checkedListMap)}`}</span>
            </Col>
            <Col span={2} onClick={() => setVisiblePanelMap((current) => !current)} style={{ cursor: 'pointer' ,textAlign: 'right' }}>
              {visiblePanelMap ? <Icon type="up" /> : <Icon type="down" />}
            </Col>
          </Row>
        </div>
      );
    };

    const headerTitleTeam = (res) => {
      return (
        <div>
          <Row>
            <Col span={14}>
              <Checkbox indeterminate={indeterminateTeam} onChange={handleCheckAllTeam} checked={checkAllTeam}>
                <span>{_.get(res, 'teamChkboxName')}</span>
              </Checkbox>
            </Col>
            <Col span={8} style={{textAlign: 'right'}}>
              <span className="select-text-panel-style">{`${intl.formatMessage({
                id: `filterTaskSelected${LangCode}`,
                defaultMessage: 'Selected',
              })} ${_.size(checkedListTeam)}`}</span>
            </Col>
            <Col span={2} onClick={() => setVisiblePanelTeam((current) => !current)} style={{ cursor: 'pointer',textAlign: 'right' }}>
              {visiblePanelTeam ? <Icon type="up" /> : <Icon type="down" />}
            </Col>
          </Row>
        </div>
      );
    };

    return (
      <div>
        <div className="filter-text-summary" style={{ borderTop: '1px solid #e4e6eb' }}>
          <div>
            {headerTitleStage(_.get(data, 'statusStage'))}
            {visiblePanelStage ? (
              <div>
                <CheckboxGroup
                  className="select-group-style"
                  options={planOptions}
                  value={checkedListStage}
                  onChange={onChangeGroupStage}
                />
              </div>
            ) : null}
          </div>
        </div>
        <div className="filter-text-summary" style={{ borderTop: '1px solid #e4e6eb' }}>
          <div>
            {headerTitleTaskType(_.get(data, 'selectTaskType'))}
            {visiblePanelTaskType ? (
              <div>
                <CheckboxGroup
                  className="select-group-style"
                  options={planOptionsTaskType}
                  value={checkedListTaskType}
                  onChange={onChangeGroupTaskType}
                />
              </div>
            ) : null}
          </div>
        </div>
        {type === 'mapView' || type === 'listView' ? (
          <div className="filter-text-summary" style={{ borderTop: '1px solid #e4e6eb' }}>
            <div>
              {headerTitleAssign(_.get(data, 'assignment'))}
              {visiblePanelAssign ? (
                <div>
                  <CheckboxGroup
                    className="select-group-style"
                    options={planOptionsAssignment}
                    value={checkedListAssign}
                    onChange={onChangeGroupAssign}
                  />
                </div>
              ) : null}
            </div>
          </div>
        ) : null}
        {type === 'ganttView' || type === 'mapView' ? (
          <div className="filter-text-summary" style={{ borderTop: '1px solid #e4e6eb' }}>
            <div>
              {headerTitleSla(_.get(data, 'sla'))}
              {visiblePanelSla ? (
                <div>
                  <CheckboxGroup
                    className="select-group-style"
                    options={planOptionsSla}
                    value={checkedListSla}
                    onChange={onChangeGroupSla}
                  />
                </div>
              ) : null}
            </div>
          </div>
        ) : null}

        {type === 'mapView' ? (
          <div className="filter-text-summary" style={{ borderTop: '1px solid #e4e6eb' }}>
            <div>
              {headerTitleMap(_.get(data, 'mapOption'))}
              {visiblePanelMap ? (
                <div>
                  {console.log('planOption', planOptionsMap)}
                  {console.log('planOption1', checkedListMap)}
                  <CheckboxGroup
                    className="select-group-style"
                    options={planOptionsMap}
                    value={checkedListMap}
                    onChange={onChangeGroupMap}
                    disabled={_.size(mainOrgId) !== 0 ? false : true}
                  />
                </div>
              ) : null}
            </div>
          </div>
        ) : null}

        {type === 'ganttView' || type === 'mapView' || type === 'listView' ? (
          <div className="filter-text-summary" style={{ borderTop: '1px solid #e4e6eb' }}>
            <div>
              {headerTitleTeam(_.get(data, 'teamChkbox'))}
              {visiblePanelTeam ? (
                <div>
                  <CheckboxGroup
                    className="select-group-style"
                    options={planOptionsTeam}
                    value={checkedListTeam}
                    onChange={onChangeGroupTeam}
                  />
                </div>
              ) : null}
            </div>
          </div>
        ) : null}
        <div></div>
      </div>
    );
  },
  (prev, next) => {
    // return _.get(prev, 'data') === _.get(next, 'data') ||
    //   _.size(prev.checkedListSla) === _.size(next.checkedListSla) || _.size(prev.checkedListStage) === _.size(next.checkedListStage) ||
    //   _.size(prev.checkedListAssign) === _.size(next.checkedListAssign) || _.size(prev.checkedListMap) === _.size(next.checkedListMap);
    // return true;
  }
);
