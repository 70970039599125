import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Spin } from 'antd';
import './css/index.css'
import FilterTravelExpense from './filter';
import DetailTravelExpense from './detail';
import _ from 'lodash'
import moment from 'moment';
import { getTeamOrg } from '../../../controllers/team/team';
import getMemberSelf from '../../../controllers/getCustomer/get-member-self'
import { useDebounce } from '../../../controllers/debounce'
import ModalTravelExpense from '../../../components/travel-expense';
import { searchTravelExpense, recalculateTravelExpense } from '../../../controllers/travel-expenses/travel-expenses';
import { errorNotification } from '../../../components/v2/notification'
import { TravelExpenseContext } from './context'
import { PageSettings } from '../../../config/page-settings';

const TravelExpense = () => {
  const [searchGroup, setSearchGroup] = useState()
  const [paginationPage, setPaginationPage] = useState(1)
  const [paginationSize, setPaginationSize] = useState(10);
  const [fieldSort, setFieldSort] = useState();
  const [orderSort, setOrderSort] = useState();
  const [toggle, setToggle] = useState(false)
  const [tab, setTab] = useState('waiting')
  const [data, setData] = useState([])
  const [total, setTotal] = useState(0)
  const [teamData, setTeamData] = useState([])
  const [employeeData, setEmployeeData] = useState([])
  const [initialDetail, setInitialDetail] = useState()
  const [visibleModal, setVisibleModal] = useState(false)
  const debounceGroup = useDebounce(searchGroup, 500)
  const ref = useRef();
  const [visiblePopoverTable, setVisiblePopoverTable] = useState();
  const [recordPopover, setRecordPopover] = useState();
  const [offsetPopover, setOffsetPopover] = useState([0, 0]);
  const [travelExpenseId, setTravelExpenseId] = useState()
  const [mainTrigger, setMainTrigger] = useState(false)
  const [spin, setSpin] = useState(false)
  const [headerTotal, setHeaderTotal] = useState()

  console.log("debounceGroup", debounceGroup)

  useEffect(() => {
    async function initialData() {
      const response = await getTeamOrg()
      setTeamData(_.get(response, 'data'))
    }
    initialData()
  }, [])

  useEffect(() => {
    async function getMember() {
      const response = await getMemberSelf(_.get(searchGroup, 'team'))
      setEmployeeData(_.get(response, 'data.members'))
    }
    if (_.get(searchGroup, 'team')) {
      getMember()
    }
  }, [_.get(searchGroup, 'team')])

  useEffect(() => {
    async function getSearchData() {
      setSpin(true)
      const body = {
        "teamList": _.get(debounceGroup, 'team') ? [_.toString(_.get(debounceGroup, 'team'))] : [],
        "status": tab,
        "employeeList": _.get(debounceGroup, 'employee') || [],
        "searchNo": _.get(debounceGroup, 'tripOrTask') || "",
        "startDate": _.get(debounceGroup, 'startDate') ? moment(_.get(debounceGroup, 'startDate')).format("YYYY-MM-DD") : "",
        "endDate": _.get(debounceGroup, 'endDate') ? moment(_.get(debounceGroup, 'endDate')).format("YYYY-MM-DD") : "",
        "pageNumber": paginationPage,
        "limit": paginationSize,
        "orderBy": fieldSort,
        "orderType": orderSort
      }
      const response = await searchTravelExpense(body)
      setData(_.get(response, 'data.data.travelExpenseTable.list'))
      setTotal(_.get(response, 'data.data.travelExpenseTable.totalItems'))
      setInitialDetail({
        additionalItemList: _.get(response, 'data.data.additionalItemList'),
        additionalItemTypeList: _.get(response, 'data.data.additionalItemTypeList'),
        reason: _.get(response, 'data.data.reason'),
      })
      setHeaderTotal({
        waitingTotal: _.get(response, 'data.data.watingTotal'),
      })
      setSpin(false)
    }
    getSearchData()
  }, [tab, debounceGroup, paginationPage, paginationSize, fieldSort, mainTrigger])


  const onChange = (value, code) => {
    setSearchGroup({ ...searchGroup, [code]: value });
    setPaginationPage(1);
    if (code === "team") {
      setSearchGroup(prevData => {
        const newData = { ...prevData };
        delete newData["employee"];
        return newData;
      });
    }
  };

  const handleToggle = () => {
    setToggle(!toggle);
  };

  const changeTab = (value) => {
    setTab(value)
    setPaginationPage(1)
  }

  const handleView = (value) => {
    setVisibleModal(true)
    setTravelExpenseId(_.get(value, 'travelExpenseId'))
  }

  const handleChange = (pagination, sorter, extra) => {
    let field = extra.field;
    if (extra.order) {
      if (extra.order === 'descend') {
        setOrderSort('desc');
      } else {
        setOrderSort('asc');
      }
    } else {
      setOrderSort('');
    }
    setFieldSort(field);
    setPaginationSize(pagination.pageSize);
    setPaginationPage(pagination.current);
  };

  const handleMouseLeave = () => {
    setVisiblePopoverTable(false);
    setRecordPopover();
    setOffsetPopover([0, 0]);
  };

  const recalculate = async () => {
    try {
      setSpin(true)
      const response = await recalculateTravelExpense()
      if (_.get(response, 'data.status.code') === 200) {
        setMainTrigger(current => !current)
        setSpin(false)
      } else {
        errorNotification(_.get(response, 'data.status.message'))
        setSpin(false)
      }
    } catch (error) {
      errorNotification(_.get(error, 'response.data.status.message'))
      setSpin(false)
    }
  }

  return (
    <PageSettings.Consumer>
      {({ checkPermissionAction }) => (
        <>
          {checkPermissionAction('P50PG1C1', 'P50PG1C1A1') ? (
            <TravelExpenseContext.Provider
              value={{
                state: {
                  teamData,
                  employeeData,
                  searchGroup,
                  toggle,
                  tab,
                  paginationPage,
                  data,
                  headerTotal,
                  ref,
                  visiblePopoverTable,
                  recordPopover,
                  offsetPopover,
                  total
                },
                setState: {
                  setVisiblePopoverTable,
                  setRecordPopover,
                  setOffsetPopover,
                },
                fnc: {
                  onChange,
                  handleToggle,
                  changeTab,
                  handleView,
                  handleChange,
                  recalculate,
                  handleMouseLeave,
                },
              }}

            >
              <Spin spinning={spin}>
                <Row gutter={[16, 16]}>
                  <Col span={toggle ? 0 : 6}>
                    <FilterTravelExpense />
                  </Col>
                  <Col span={toggle ? 24 : 18}>
                    <DetailTravelExpense />
                  </Col>
                </Row>

                <ModalTravelExpense
                  visible={visibleModal}
                  setVisible={setVisibleModal}
                  initialDetail={initialDetail}
                  travelExpenseId={travelExpenseId}
                  tab={tab}
                  setMainTrigger={setMainTrigger}
                />
              </Spin>
            </TravelExpenseContext.Provider>
          ) : null}
        </>

      )}
    </PageSettings.Consumer>
  )
}

export default TravelExpense