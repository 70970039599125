import httpClient from '../../components/axiosClient';

const comCode = localStorage.getItem('comCode');

const createAverageSpeed = async (payload) => {
  try {
    const response = await httpClient.post(`/v5/resource/manager/company/${comCode}/web/averagespeed`, payload);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return error.response;
  }
};

const updateAverageSpeed = async (id, payload) => {
  try {
    const response = await httpClient.post(`/v5/resource/manager/company/${comCode}/web/averagespeed/${id}`, payload);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return error.response;
  }
};

const deleteAverageSpeed = async (id) => {
  try {
    const response = await httpClient.delete(`/v5/resource/manager/company/${comCode}/web/averagespeed/${id}`);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return error.response;
  }
};
export { createAverageSpeed, updateAverageSpeed, deleteAverageSpeed };
