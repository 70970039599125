import React, { useContext, useState } from 'react';
import { PageSettings } from '../../config/page-settings';
import { InvoiceContext } from './context';
import { StickyContainer } from 'react-sticky';
import { Button, Card, Col, Dropdown, Menu, Row, Tabs } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';

import Button_01 from '../../components/v2/button_01';
import { DownOutlined } from '@ant-design/icons';
import WaitingQuotation from './component-cardpage/invoice-cardpage';
import ModalCustomerSaleOrderForm from './modal/saleorder/invoice-modal';
import UploadFileModal from './modal/upload-file/upload-modal';
import './css/index.css';
import _ from 'lodash';
import InvoiceCard from './component-cardpage/invoice-cardpage';
const { TabPane } = Tabs;

const InvoicePage = () => {
  const intl = useIntl();
  const { state, setState, func } = useContext(InvoiceContext);
  const {
    isOpenQuotation,
    openUpload,
    attachmentData,
    typeAttachment,
    defaultAttachment,
    initialMemberList,
    record,
    reportDataWaiting,
  } = state;
  const { seIsOpenQuotation, setAttachmentData, setTypeAttachment, setDefaultAttachment, setOpenUpload, setTabKey } = setState;
  const { handleOpenModalQuotation, handleSaveQuotation, handleCancelQuotation, refreshModalSaleOrder, handleExport } = func;
  const { quotationList, quotationTotal, paginationPage, loading, rangePickerDate } = reportDataWaiting;

  const [visible, setVisible] = useState(false);

  console.log('record', record);

  const handleOpenModal = () => {
    handleOpenModalQuotation();
    setVisible(!visible);
  };

  const menuColumn = () => {
    return (
      // <PageSettings.Consumer>
      //   {({ checkPermissionAction }) => (
      <Menu>
        {/* {checkPermissionAction(`P49PG2C2`, `P49PG2C2A2`) ? ( */}
        <Menu.Item key="1">
          <Button key="1" ghost type="link" style={{ padding: 'unset' }} onClick={() => handleOpenModal()}>
            <FormattedMessage id="btnCreateInvoice" defaultMessage="Create Invoice" />
          </Button>
        </Menu.Item>
        {/* ) : null} */}
        {/* {checkPermissionAction(`P49PG2C2`, `P49PG2C2A3`) ? ( */}
        <Menu.Item key="2">
          <Button key="2" ghost type="link" style={{ padding: 'unset' }} onClick={() => handleExport()}>
            <FormattedMessage id="quotationLabelExportExcel" defaultMessage="Export Excel" />
          </Button>
        </Menu.Item>
        {/* ) : null} */}
      </Menu>
      //   )}
      // </PageSettings.Consumer>
    );
  };

  const callback = (key) => {
    setTabKey(key);
  };

  return (
    <PageSettings.Consumer>
      {({ checkPermissionAction }) => (
        <div>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Card
                title={<FormattedMessage id="InvoiceTitleCard" defaultMessage="Invoice" />}
                className="monitor-main-card"
                extra={
                  <Dropdown
                    overlay={menuColumn}
                    trigger={['click']}
                    onVisibleChange={setVisible}
                    visible={visible}
                    placement="bottomRight"
                    className="ant-dropdown-custom"
                  >
                    <Button_01 key="submit" type="primary" btnsize="wd_df" onClick={() => {}}>
                      <FormattedMessage id="btnActionGroup" defaultMessage="Action" />
                      <DownOutlined style={{ fontSize: '14px', paddingLeft: '22%' }} />
                    </Button_01>
                  </Dropdown>
                }
              >
                <InvoiceCard />
              </Card>
              {/* ) : null} */}
            </Col>
          </Row>

          <ModalCustomerSaleOrderForm
            title={
              _.get(record, 'code') === 'view'
                ? `${intl.formatMessage({ id: `invoiceLabelInvoice`, defaultMessage: 'Invoice' })} · ${record.invoiceNo}`
                : _.get(record, 'code') === 'edit'
                ? `${intl.formatMessage({ id: `invoiceLabelEditInvoice`, defaultMessage: 'Edit Invoice' })} · ${record.invoiceNo}`
                : intl.formatMessage({ id: `invoiceLabelCreateInvoice`, defaultMessage: 'Create Invoice' })
            }
            visible={isOpenQuotation}
            onOk={handleSaveQuotation}
            onCancel={handleCancelQuotation}
            initialMemberList={initialMemberList}
            attData={attachmentData}
            setAttData={setAttachmentData}
            record={record}
            refreshModalSaleOrder={refreshModalSaleOrder}
          />

          <UploadFileModal
            visible={openUpload}
            setVisible={setOpenUpload}
            setAttData={setAttachmentData}
            attData={attachmentData}
            typeAtt={typeAttachment}
            defaultAtt={defaultAttachment}
          />
        </div>
      )}
    </PageSettings.Consumer>
  );
};

export default InvoicePage;
