import React from 'react';
import CollapseCustom from '../../../components/collapse-custom';
import { useIntl, FormattedMessage } from 'react-intl';
import ReportItemSalesActionColumns from './action-columns';
import { Input, Form, Select, DatePicker, Row, Col } from 'antd';
import LabeRequireForm from '../../../components/label-required-form';
import _ from 'lodash';
import Button_01 from '../../../components/v2/button_01';
import Button_02 from '../../../components/v2/button_02';

const { RangePicker } = DatePicker;
const { Option } = Select;

const ReportItemSalesAction = (props) => {
  const intl = useIntl();
  const { search, setSearch, setPage, setIsFetch, onFilterOrder, actionColumns, refTypeList, brand, model } = props;

  const handleApply = () => {
    setIsFetch((event) => !event)
    setPage(1)
  }

  const handleReset = () => {
    setIsFetch((event) => !event)
    setSearch((prev) => ({...prev, soNo: '', customer: '', item: '', brand: [], model: [], purchaseOrderNo: '', quotationNo: ''}))
    setPage(1)
  }

  const formFilter = () => {
    return (
      <Form colon={false} className="deliveryForm">
        <Row gutter={[24, 2]}>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'reportItemSalesColumnsSoNo', defaultMessage: 'SO No.' })} req={false} />
              }
            >
              <Input
                allowClear={true}
                placeholder={intl.formatMessage({ id: 'reportItemSalesPHRefSoNo', defaultMessage: 'Enter SO No.' })}
                onChange={(e) => onFilterOrder(e.target.value, 'soNo')}
                value={_.get(search, 'soNo') || undefined}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'reportItemSalesColumnsCustomer', defaultMessage: 'Customer' })}
                  req={false}
                />
              }
            >
              <Input
                allowClear={true}
                placeholder={intl.formatMessage({
                  id: 'reportItemSalesPHRefCustomer',
                  defaultMessage: 'Enter Customer Code or Customer Name',
                })}
                onChange={(e) => onFilterOrder(e.target.value, 'customer')}
                value={_.get(search, 'customer') || undefined}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'reportItemSalesColumnsItem', defaultMessage: 'Item' })} req={false} />
              }
            >
              <Input
                allowClear={true}
                placeholder={intl.formatMessage({ id: 'reportItemSalesPHRefItem', defaultMessage: 'Enter Item Code or Item Name' })}
                onChange={(e) => onFilterOrder(e.target.value, 'item')}
                value={_.get(search, 'item') || undefined}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[24, 2]}>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'menuReportItemSalesBrand', defaultMessage: 'Brand' })} req={false} />
              }
            >
              <Select
                showSearch
                allowClear={true}
                mode="multiple"
                onSearch={(value) => brand.setBrandSearch(value)}
                onChange={(value) => onFilterOrder(value, 'brand')}
                dataSource={brand.optionsBrand}
                filterOption={false}
                value={_.get(search, 'brand')}
                placeholder={intl.formatMessage({ id: 'reportItemSalesPHRefBrand', defaultMessage: 'Select Brand' })}
                // style={{ width: '100%' }}
                notFoundContent={brand.loadingBrand ? 'Loading...' : null}
              >
                {_.map(brand.optionsBrand, (item) => (
                  <Option value={item.item_brand_id}>{item.item_brand_name}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<LabeRequireForm text={intl.formatMessage({ id: 'menuReportItemSalesModel', defaultMessage: 'Model' })} req={false} />}
            >
              <Select
                showSearch
                allowClear={true}
                mode='multiple'
                onSearch={(value) => model.setModelSearch(value)}
                onChange={(value) => onFilterOrder(value, 'model')}
                dataSource={model.optionsModel}
                filterOption={false}
                value={_.size(_.get(search, 'brand')) ? _.get(search, 'model') : []}
                placeholder={intl.formatMessage({ id: 'reportItemSalesPHRefModel', defaultMessage: 'Select Model' })}
                style={{ width: '100%' }}
                notFoundContent={model.loadingModel ? 'Loading...' : null}
                disabled={_.size(_.get(search, 'brand')) ? false : true}
              >
                {_.map(model.optionsModel, (item) =>
                  <Option value={item.item_model_id}>
                    {item.item_model_name}
                  </Option>
                )}
              </Select>
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'reportItemSalesColumnsPoNo', defaultMessage: 'PO No.' })} req={false} />
              }
            >
              <Input
                allowClear={true}
                placeholder={intl.formatMessage({ id: 'reportItemSalesPHRefPoNo', defaultMessage: 'Enter PO No.' })}
                onChange={(e) => onFilterOrder(e.target.value, 'purchaseOrderNo')}
                value={_.get(search, 'purchaseOrderNo') || undefined}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[24, 2]}>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'reportItemSalesColumnsQtNo', defaultMessage: 'QT No.' })} req={false} />
              }
            >
              <Input
                allowClear={true}
                placeholder={intl.formatMessage({ id: 'reportItemSalesPHRefQtNo', defaultMessage: 'Enter QT No.' })}
                onChange={(e) => onFilterOrder(e.target.value, 'quotationNo')}
                value={_.get(search, 'quotationNo') || undefined}
              />
            </Form.Item>
          </Col>
          <Col span={8}></Col>
          <Col span={8} style={{ textAlign: 'right' }}>
          <Form.Item
              label={<LabeRequireForm text={''} req={false} />}
            >
              <Button_01 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleReset}>
                <FormattedMessage id="btnResetFilter" defaultMessage="Reset" />
              </Button_01>
              <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="print" type="primary" btnsize="wd_df" onClick={handleApply}>
                <FormattedMessage id="btnApplyFilter" defaultMessage="Apply" />
              </Button_02>
          </Form.Item>
          </Col>

          {/* <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'reportItemSalesColumnsINVNo', defaultMessage: 'INV No.' })} req={false} />
              }
            >
              <Input
                allowClear={true}
                placeholder={intl.formatMessage({ id: 'reportItemSalesPHRefInvNo', defaultMessage: 'Enter INV No.' })}
                onChange={(e) => onFilterOrder(e.target.value, 'invNo')}
              />
            </Form.Item>
          </Col> */}
        </Row>
      </Form>
    );
  };

  return (
    <div style={{ marginTop: '16px' }}>
      <CollapseCustom
        label={intl.formatMessage({ id: `btnFilter`, defaultMessage: 'Filter' })}
        extra={
          <div style={{ display: 'flex' }}>
            <RangePicker
              allowClear={true}
              placeholder={[
                intl.formatMessage({ id: 'reportReconcilePHExpiredDateFrom', defaultMessage: 'Select From Date' }),
                intl.formatMessage({ id: 'reportReconcilePHExpiredDateTo', defaultMessage: 'Select To Date' }),
              ]}
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              onChange={(value) => onFilterOrder(value, 'searchDate')}
              style={{ width: '100%' }}
              format={['DD/MM/YYYY', 'DD/MM/YYYY']}
              value={_.get(search, 'searchDate')}
            />
            <ReportItemSalesActionColumns actionColumns={actionColumns} />
          </div>
        }
      >
        {formFilter()}
      </CollapseCustom>
    </div>
  );
};

export default ReportItemSalesAction;
