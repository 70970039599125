import React from 'react'
import './index.css'
import { MemberExpenseContext } from './context'
import MemberExpenseContent from './content'

const PageMemberExpense = () => {
  return (
    <MemberExpenseContext>
      <MemberExpenseContent />
    </MemberExpenseContext>
  )
}

export default PageMemberExpense
