import _ from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './css/index.css';

const ServiceAreaView = (props) => {
  const { areaData } = props;

  return (
    <div className="service-area-view">
      <div className="service-area-view-detail">
        <p className="service-area-view-label">
          <FormattedMessage id="serviseAreaLblServicesAreaName" defaultMessage="Services Area Name" />
        </p>
        <p className="service-area-view-text">{_.get(areaData, 'areaName')}</p>
      </div>
    </div>
  );
};

export default ServiceAreaView;
