import httpClient from '../../components/axiosClient';

const GetRole = async (value) => {

  try {
    const response = await httpClient.get(`/company/${value}/role`)
    if (response.status === 200) {

      return response.data
    } else {
      return []
    }
  } catch (error) {
  }
}

const GetRequestType = async () => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.get(`/v4/resource/manager/company/${comCode}/approver/type`)
    if (response.status === 200) {
      return response.data
    } else {
      return []
    }
  } catch (error) {
  }
}

const SaveApprover = async (memComId, userTostring, role, orgId, requestType, approverId) => {
  const comCode = localStorage.getItem('comCode');
  const body = {
    memComId: memComId,
    users: userTostring,
    roles: role,
    orgId: orgId,
    requestId: requestType,
    approverId: approverId ? approverId : undefined
  }
  try {
    const response = await httpClient.post(`/v4/resource/manager/company/${comCode}/save/approver-organization/`, body)
    if (response.status === 200) {

      return response.data
    } else {
      return []
    }
  } catch (error) {
    return error.response.data
  }
}


export { GetRole, GetRequestType, SaveApprover };