import React, { useState, useEffect } from 'react'
import { AutoComplete, Form } from 'antd'
import _ from 'lodash'
import { useIntl } from 'react-intl'
import LabeRequireForm from '../label-required-form'
import { getProjectList } from '../../controllers/project/project-api'

const { Option } = AutoComplete

const LoadmoreProject = (props) => {
  const intl = useIntl()
  const { form, data, setData, required, initialId, initialName, visible,notSpec } = props
  const getFieldDecorator = form ? form.getFieldDecorator : null
  const [projectData, setProjectData] = useState({})
  const [projectDataMaster, setProjectDataMaster] = useState({})
  const [projectList, setProjectList] = useState([])
  const [projectListMaster, setProjectListMaster] = useState([])
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState("")
  const [firstStep, setFirstStep] = useState(false)

  useEffect(() => {
    if (initialId && visible) {
      setSearch(initialName)
      setFirstStep(true)
    } else {
      setSearch("")
      setFirstStep(false)
      setProjectData({})
      setProjectDataMaster({})
      setProjectList([])
      setProjectListMaster([])
      setPage(1)
    }
  }, [initialId, visible])

  useEffect(() => {
    if (firstStep) getProject()
  }, [page, firstStep])

  const getProject = async () => {
    const payload = {
      search: search,
      searchBy: "projectName",
      paging: 1,
      rowsPerPages: 20,
    }
    const response = await getProjectList(payload)
    setProjectData(response);
    setProjectDataMaster(response)
    setProjectList([...projectList, ..._.get(response, 'data', [])]);
    setProjectListMaster([...projectListMaster, ..._.get(response, 'data', [])])
  }

  const handleSelect = (value, option) => {
    if (form) {
      setData(_.get(option, 'props.data'));
      form.setFieldsValue({ project: value });
    } else {
      setData((prev) => ({ ...prev, project: value }))
    }
  }

  const handleChange = (value) => {
    const findProject = _.find(projectList, (item) => { return item.projectName === value || item.projectId === value })
    setData((prev) => ({ ...prev, project: findProject, projectSearch: value }))
  }

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    if (scrollTop + clientHeight >= scrollHeight) {
      if (_.get(projectData, 'total') !== _.size(projectList)) setPage(page + 1)
    }
  };

  const handleSearch = _.debounce(async (value) => {
    setSearch(value)
    if (value && value !== "") {
      const payload = {
        search: value,
        searchBy: "projectName",
        paging: 1,
        rowsPerPages: 20,
      }
      const response = await getProjectList(payload)
      setProjectData(response);
      setProjectList(_.get(response, 'data', []));
    } else {
      setProjectData(projectDataMaster)
      setProjectList(projectListMaster)
    }
  }, 500)

  return (
    <Form.Item onClick={() => setFirstStep(true)} label={
      <LabeRequireForm
        text={intl.formatMessage({ id: 'projectHeader', defaultMessage: 'Project' })}
        req={required}
      />
    }>
      {
        form ? getFieldDecorator('project', {
          initialValue: initialId,
          rules: [
            {
              required: required,
              message: intl.formatMessage({
                id: 'modalTaskSettingProjectVLD',
                defaultMessage: 'Please Select Project',
              }),
            },
          ],
        })(
          <AutoComplete
            onSelect={handleSelect}
            onPopupScroll={handleScroll}
            onSearch={handleSearch}
            placeholder={intl.formatMessage({ id: 'projectPlaceholder', defaultMessage: 'Select Project' })}
          >
            {
              search && !_.includes(search, 'ไม่กำหนด') ? null : (
                <Option key="notSpecified" value="notSpecified" data={{ id: "notSpecified" }}>
                  ไม่กำหนด
                </Option>
              )
            }
            {
              _.map(projectList, (item) => (
                <Option key={_.get(item, 'projectId')} value={_.get(item, 'projectId')} data={item}>
                  {_.get(item, 'projectNo') + " " + _.get(item, 'projectName')}
                </Option>
              ))
            }
          </AutoComplete>
        ) : (
          <AutoComplete
            // onSelect={handleSelect}
            onChange={handleChange}
            onPopupScroll={handleScroll}
            onSearch={handleSearch}
            placeholder={intl.formatMessage({ id: 'projectPlaceholder', defaultMessage: 'Select Project' })}
            value={_.get(data, 'projectSearch')}
            allowClear={true}
          >
            {
              search && !_.includes(search, 'ไม่กำหนด') || notSpec ? null : (
                <Option key="notSpecified" value="notSpecified" data={{ id: "notSpecified" }}>
                  ไม่กำหนด
                </Option>
              )
            }
            {
              _.map(projectList, (item) => (
                <Option key={_.get(item, 'projectId')} value={_.get(item, 'projectId')} data={item}>
                  {_.get(item, 'projectNo') + " " + _.get(item, 'projectName')}
                </Option>
              ))
            }
          </AutoComplete>
        )
      }
    </Form.Item>

  )

}

LoadmoreProject.defaultProps = {
  required: true,
}


export default LoadmoreProject