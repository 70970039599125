import React, { useState, useEffect } from 'react';
import { Card, Select, Row, List, Avatar, Col, Tag, Badge, Checkbox, Icon, Divider } from 'antd';
import './css/index.css';
import _ from 'lodash';
import { useIntl, FormattedMessage } from 'react-intl';
import NumberFormat from 'react-number-format';

const { Option } = Select;
let arry = [];

export default React.memo(
  ({ value, fnc }) => {
    const intl = useIntl();

    const { assigneeData, teamData, checkedAll, defaultChecked, tabStatusData, memberIdStr } = value;
    const { handleSelectOrg, setCheckedAll, setMemberIdStr, onSelectMember, setDefaultChecked, checkAllAssignee } = fnc;

    const headerSelectAllAssignee = () => {
      return (
        <div>
          <Checkbox checked={defaultChecked} onChange={checkAllAssignee}>
            <span>
              <FormattedMessage id="travelExpensesLeftCardTitle" defaultMessage="Assignees" />
            </span>
          </Checkbox>
        </div>
      );
    };

    const StatusTagMember = ({ item }) => {
      if (
        _.find(item.statusesCount, ['statusCode', 'paid']) === undefined ||
        _.find(item.statusesCount, ['statusCode', 'verified']) === undefined ||
        _.find(item.statusesCount, ['statusCode', 'waiting']) === undefined
      ) {
        return null;
      } else {
        if (item && _.find(item.statusesCount, ['statusCode', 'paid']).count > 0) {
          return (
            <div>
              <RenderTag tag={item.statusesCount} isStatus={['waiting', 'verified', 'paid']} />
            </div>
          );
        } else if (_.find(item.statusesCount, ['statusCode', 'verified']).count > 0) {
          return (
            <div>
              <RenderTag tag={item.statusesCount} isStatus={['waiting', 'verified']} />
            </div>
          );
        } else if (_.find(item.statusesCount, ['statusCode', 'waiting']).count > 0) {
          return (
            <div>
              <RenderTag tag={item.statusesCount} isStatus={['waiting']} />
            </div>
          );
        } else {
          return null;
        }
      }
    };

    const RenderTag = ({ tag, isStatus }) => {
      return (
        <div>
          {tag.map((i) =>
            _.indexOf(isStatus, i.statusCode) > -1 ? (
              <Tag Tag className="border-cal-status" color={i.color}>
                <div className="tag-style-member-count">
                  <NumberFormat value={i.count} displayType={'text'} thousandSeparator={true} />
                </div>
              </Tag>
            ) : null
          )}
        </div>
      );
    };

    const listMember = () =>
      assigneeData &&
      assigneeData.map((item, index) => {
        return (
          <div key={item.memComId} onClick={() => onSelectMember(item.memComId, defaultChecked)} className="list-member-travel">
            <Row className="padding-style-list-member">
              <Col span={6}>
                <Badge
                  className="badge-style-member"
                  count={
                    item.selected === true ? (
                      <Icon type="check-circle" theme="filled" style={{ color: '#1D3557' }} className="icon-correct-member" />
                    ) : (
                      undefined
                    )
                  }
                >
                  <Avatar size="large" src={item.memberImg} shape="circle" />
                </Badge>
              </Col>
              <Col span={18}>
                <div style={{ display: 'grid' }}>
                  <span className="name-p">{item.name}</span>
                  {/* {
                _.get(item, 'statuses') ? ( */}
                  <StatusTagMember item={item} />
                  {/* ) : null
              }  */}
                </div>
              </Col>
            </Row>
            <Divider className="divider-style-member" type="horizontal" />
          </div>
        );
      });

    return (
      <Card title={headerSelectAllAssignee()} className="card-select-org">
        <div className="data-all-left">
          <div className="data-all-select-team">
            <Select
              className="select-travel-assignee"
              placeholder={intl.formatMessage({ id: 'travelExpensesLeftCardPlaceholerSelectTeam', defaultMessage: 'Select a Team' })}
              showSearch
              onChange={handleSelectOrg}
              filterOption={(input, option) =>
                option.props.children
                  .toString()
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {teamData && teamData.map((e) => <Option value={e.orgId}>{e.name}</Option>)}
            </Select>
          </div>
          <div className="select-assignee-travel">{listMember()}</div>

          <div className="sum-data-tag">
            {tabStatusData.map((item) => (
              <div>
                <Badge color={item.color} text={item.name} />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </div>
            ))}
          </div>
        </div>
      </Card>
    );
  },
  (prev, next) => {
    // return _.isMatch(_.get(prev, 'value.assigneeData'), _.get(next, 'value.assigneeData')) &&
    //   _.isMatch(_.get(prev, 'value.teamData'), _.get(next, 'value.teamData'))
  }
);
