import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Form, Icon, Input, Modal, Row, Select, Tag } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import _ from 'lodash';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import ListItemFilter from './filter';
import { messageLabel } from '../message-component';
import Button_01 from '../v2/button_01';
import { searchPo } from '../../controllers/po/po';
import NoDataBackground from '../no-data-page';
import CustomTableComponent from '../../pages/billing-note/components/table';
import { getTicketData } from '../../controllers/ticket/ticket';
import { savetListReferenceTab } from '../../controllers/reference-tab';
import {
  createGroupData,
  createModelData,
  createTypeData,
  getBrandDataList,
  getGroupDataList,
  getModelDataList,
  getTypelDataList,
} from '../../controllers/createBrandModel';
import ListItemBrand from './brandlist';
import LabeRequireForm from '../label-required-form';
import Button_02 from '../v2/button_02';
import CollapseCustom from '../collapse-custom';
import ModalCreateModelForm from './createmodel';
import ModalCreateBrandForm from './createBrand';
import ModalViewBrandModal from './createBrand/view';
import { errorNotification, successNotification } from '../v2/notification';
import filterimg from '../../assets/svg/filter.svg';
import { right } from 'glamor';

const { Option } = Select;

const CreateGroupTypeModal = ({
  form,
  visible,
  onCancel,
  refType,
  dataform,
  setCreateItem,
  setBrandId,
  setEventDataDefault,
  groupModalType,
}) => {
  const [reportBrand, setReportBrand] = useState({
    itemBrandsActive: [],
    itemBrandsInactive: [],
    totalItemBrandsActive: 0,
    totalItemBrandsInactive: 0,
    totalItem: 0,
    extraSort: undefined,
    fieldSort: '',
    orderSort: undefined,
    orderBy: undefined,
    paginationPage: 1,
    paginationSize: 10,
    loading: false,
    brand: '',
    model: '',
    paymentTermNo: '',
    saleOrderNo: '',
    customerName: '',
    rangePickerDate: [],
    statusCode: [],
    issueDateStart: '',
    issueDateEnd: '',
    apply: false,
  });
  const [reportGrouptype, setReportGrouptype] = useState({
    list: [],
    totalItem: 0,
    extraSort: undefined,
    fieldSort: '',
    orderSort: undefined,
    orderBy: undefined,
    paginationPage: 1,
    paginationSize: 10,
    loading: false,
    name: '',
    code: '',
    active: 'true',
    itemMDescription: '',
    customerName: '',
    rangePickerDate: [],
    statusCode: [],
    issueDateStart: '',
    issueDateEnd: '',
  });
  const clearReport = reportGrouptype;
  console.log('dataFormCus', dataform);
  const { getFieldDecorator, setFieldsValue, resetFields, getFieldValue } = form;
  const [pageTable, setPageTable] = useState(1);
  const [sizeTable, setSizeTable] = useState(10);
  // const { customerList, contactList, addressList, handleSearchCustomer, resetCustomer } = SelectCustomerHook();
  const [event, setEvent] = useState([]);
  const [eventmodel, setEventModel] = useState([]);
  const [tempchange, setTempChange] = useState({});
  const [toggleBrand, setToggleBrand] = useState(false);
  const [selectBrand, setSelectBrand] = useState('');
  const [statusList, setStatusList] = useState([]);
  const [recordBrand, setRecordBrand] = useState({});
  const [recordModel, setRecordModel] = useState([]);
  const [visibleCreateModel, setVisibleCreateModel] = useState(false);
  const [visibleCreateBrand, setVisibleCreateBrand] = useState(false);
  const [visibleViewBrand, setvisibleViewBrand] = useState(false);
  const [isCollapse, setIsCollapse] = useState();

  // const [event, setEvent = useState(false);
  const intl = useIntl();

  useEffect(() => {
    setReportGrouptype((prev) => ({ ...prev, loading: true }));

    const getApi = async () => {
      const body = {
        itemGroupName: _.get(reportGrouptype, 'name'),
        itemGroupNo: _.get(reportGrouptype, 'code'),
        active: _.get(reportGrouptype, 'active'),
        itemGroupDescription: _.get(reportGrouptype, 'itemGroupDescription'),
        pageNumber: pageTable,
        limit: sizeTable,
        orderBy: _.get(reportGrouptype, 'orderBy'),
        orderType: _.get(reportGrouptype, 'orderSort'),
      };
      const reesponse = await getGroupDataList(body);

      setReportGrouptype((prev) => ({
        ...prev,
        list: _.get(reesponse, 'data.itemGroupsList'),
        totalItem: _.get(reesponse, 'data.totalGroup'),
      }));
      setStatusList(_.get(reesponse, 'data.statusList'));
    };
    setReportGrouptype((prev) => ({ ...prev, loading: false }));
    if (visible === true && groupModalType === 'group') {
      getApi();
    }
  }, [
    eventmodel,
    selectBrand,
    _.get(reportGrouptype, 'paginationPage'),
    _.get(reportGrouptype, 'paginationSize'),
    _.get(reportGrouptype, 'fieldSort'),
    _.get(reportGrouptype, 'orderSort'),
    _.get(reportGrouptype, 'statusCode'),
    _.get(reportGrouptype, 'customerName'),
    _.get(reportGrouptype, 'itemModelName'),
    pageTable,
    sizeTable,
    event,
  ]);

  useEffect(() => {
    setReportGrouptype((prev) => ({ ...prev, loading: true }));

    const getApi = async () => {
      const body = {
        itemTypeName: _.get(reportGrouptype, 'name'),
        itemTypeNo: _.get(reportGrouptype, 'code'),
        active: _.get(reportGrouptype, 'active'),
        itemTypeDescription: _.get(reportGrouptype, 'itemTypeDescription'),
        pageNumber: pageTable,
        limit: sizeTable,
        orderBy: _.get(reportGrouptype, 'orderBy'),
        orderType: _.get(reportGrouptype, 'orderSort'),
      };
      const reesponse = await getTypelDataList(body);

      setReportGrouptype((prev) => ({
        ...prev,
        list: _.get(reesponse, 'data.itemTypeList'),
        totalItem: _.get(reesponse, 'data.totalType'),
      }));
      setStatusList(_.get(reesponse, 'data.statusList'));
    };
    setReportGrouptype((prev) => ({ ...prev, loading: false }));
    if (visible === true && groupModalType === 'type') {
      getApi();
    }
  }, [
    eventmodel,
    selectBrand,
    _.get(reportGrouptype, 'paginationPage'),
    _.get(reportGrouptype, 'paginationSize'),
    _.get(reportGrouptype, 'fieldSort'),
    _.get(reportGrouptype, 'orderSort'),
    _.get(reportGrouptype, 'statusCode'),
    _.get(reportGrouptype, 'customerName'),
    _.get(reportGrouptype, 'itemModelName'),
    pageTable,
    sizeTable,
    event,
  ]);

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
      render: (text, record, index) => record.index,
    },
    {
      title: messageLabel('modalCreateGroupTypeColumnGroupCode', 'Group Code'),
      dataIndex: 'itemGroupNo',
      key: 'itemGroupNo',
      sorter: true,
      width: 180,
      ellipsis: true,
    },
    {
      title: messageLabel('modalCreateGroupTypeColumnGroupName', 'Group Name'),
      dataIndex: 'itemGroupName',
      key: 'itemGroupName',
      sorter: true,
      width: 200,
    },
    {
      title: messageLabel('modalCreateGroupTypeColumnStatus', 'Status'),
      dataIndex: 'statusName',
      key: 'statusName',
      sorter: true,
      width: 150,
      render: (text, record, index) => (
        <div className="tag-center-div">
          <Tag className="tag-center-style" color={_.get(record, 'statusColor')}>
            {_.get(record, 'statusName')}
          </Tag>
        </div>
      ),
    },
    {
      title: messageLabel('modalCreateGroupTypeColumnRemark', 'Remark'),
      dataIndex: 'itemGroupDescription',
      key: 'itemModelDescription',
      sorter: true,
      width: 300,
    },
    {
      title: messageLabel('modalCreateGroupTypeColumnCreatedby', 'Created by'),
      dataIndex: 'createdByName',
      key: 'createdByName',
      width: 150,
      sorter: true,
      // render: (text, record, index) => '',
    },
    {
      title: messageLabel('modalCreateGroupTypeColumnCreatedDate', 'Created Date'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: true,
      width: 150,
      // render: (text, record, index) => (
      //   // _.get(record, 'total') ? (
      //   <span>{moment(_.get(record, 'createdAt'), 'YYYY-MM-DD').format('DD/MM/YYYY')}</span>
      // ),
    },
    {
      title: messageLabel('modalCreateGroupTypeColumnDeletedby', 'Deleted by'),
      dataIndex: 'deletedByName',
      key: 'deletedByName',
      width: 150,
      sorter: true,
      // render: (text, record, index) => '',
    },
    {
      title: messageLabel('modalCreateGroupTypeColumnDeletedDate', 'Deleted Date'),
      dataIndex: 'deletedAt',
      key: 'deletedAt',
      sorter: true,
      width: 150,
      // render: (text, record, index) => (
      //   // _.get(record, 'total') ? (
      //   <span>
      //     {_.get(record, 'deletedAt') === '-'
      //       ? _.get(record, 'deletedAt')
      //       : moment(_.get(record, 'deletedAt'), 'YYYY-MM-DD').format('DD/MM/YYYY')}
      //   </span>
      // ),
    },
  ];

  const columnsType = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
      render: (text, record, index) => record.index,
    },
    {
      title: messageLabel('modalCreateGroupTypeColumnTypeCode', 'Type Code'),
      dataIndex: 'itemTypeNo',
      key: 'itemTypeNo',
      sorter: true,
      width: 180,
      ellipsis: true,
    },
    {
      title: messageLabel('modalCreateGroupTypeColumnTypeName', 'Type Name'),
      dataIndex: 'itemTypeName',
      key: 'itemTypeName',
      sorter: true,
      width: 200,
    },
    {
      title: messageLabel('modalCreateGroupTypeColumnStatus', 'Status'),
      dataIndex: 'statusName',
      key: 'statusName',
      sorter: true,
      width: 150,
      render: (text, record, index) => (
        <div className="tag-center-div">
          <Tag className="tag-center-style" color={_.get(record, 'statusColor')}>
            {_.get(record, 'statusName')}
          </Tag>
        </div>
      ),
    },
    {
      title: messageLabel('modalCreateGroupTypeColumnRemark', 'Remark'),
      dataIndex: 'itemTypeDescription',
      key: 'itemTypeDescription',
      sorter: true,
      width: 300,
    },
    {
      title: messageLabel('modalCreateGroupTypeColumnCreatedby', 'Created by'),
      dataIndex: 'createdByName',
      key: 'createdByName',
      width: 150,
      sorter: true,
      // render: (text, record, index) => '',
    },
    {
      title: messageLabel('modalCreateGroupTypeColumnCreatedDate', 'Created Date'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: true,
      width: 150,
    },
    {
      title: messageLabel('modalCreateGroupTypeColumnDeletedby', 'Deleted by'),
      dataIndex: 'deletedByName',
      key: 'deletedByName',
      width: 150,
      sorter: true,
    },
    {
      title: messageLabel('modalCreateGroupTypeColumnDeletedDate', 'Deleted Date'),
      dataIndex: 'deletedAt',
      key: 'deletedAt',
      sorter: true,
      width: 150,
    },
  ];

  const handleClose = () => {
    onCancel();
    setReportGrouptype({
      list: [],
      totalItem: 0,
      extraSort: undefined,
      fieldSort: '',
      orderSort: undefined,
      orderBy: undefined,
      paginationPage: 1,
      paginationSize: 10,
      loading: false,
      name: '',
      code: '',
      active: undefined,
      itemMDescription: '',
      customerName: '',
      rangePickerDate: [],
      statusCode: [],
      issueDateStart: '',
      issueDateEnd: '',
    });
  };

  const onSelectRow = async (value) => {
    const body = {
      groupId: _.get(value, 'itemGroupId'),
      typeId: _.get(value, 'itemTypeId'),
    };
    setBrandId(selectBrand);
    setCreateItem(body);
    handleClose();
    setEventDataDefault((prev) => !prev);
    setEvent((prev) => !prev);
  };

  const handleResetBrand = (value) => {
    setReportBrand((prev) => ({ ...prev, invoiceNo: '', saleOrderNo: '', customerId: '', statusCode: [], paginationPage: 1 }));
    setEvent((event) => !event);
  };

  const handleResetModel = (value) => {
    setReportGrouptype((prev) => ({ ...prev, active: '', itemModelDescription: '', paginationPage: 1 }));
    setEventModel((event) => !event);
  };

  const handleToggleBrand = (value, key) => {
    setToggleBrand((prev) => !prev);
  };

  const handleChangeBrand = (value, key) => {
    setReportBrand((prev) => ({ ...prev, [key]: value }));
  };

  // const handleClickApplyBrand = () => {
  //   setReportBrand((prev) => ({ ...prev, apply: !_.get(prev, 'apply') }));
  //   setEvent(prev => !prev)
  // };

  const handleCancelBrand = () => {
    setReportBrand((prev) => ({ ...prev, brand: '', status: undefined, remark: '', apply: !_.get(prev, 'apply') }));
  };

  const handleChange = (value, key) => {
    // console.log('handleChange', value, key, statusList, reportGrouptype)
    setReportGrouptype((prev) => ({ ...prev, [key]: value }));
  };

  const handleopenModalBrand = (value) => {
    if (value === 'create') {
      setRecordBrand({});
    }
    setVisibleCreateBrand(true);
  };

  const handleopenModalEditBrand = (value) => {
    setRecordBrand(value);
    setVisibleCreateBrand(true);
  };

  const handleCancelModalBrand = (value, key) => {
    setVisibleCreateBrand(false);
    setEventDataDefault((prev) => !prev);
    setRecordBrand({});
  };

  const handleopenModalModel = () => {
    setVisibleCreateModel(true);
    setRecordModel({ itemBrandId: selectBrand });
  };

  const handleCancelModalModel = (value, key) => {
    setVisibleCreateModel(false);
  };

  const handleopenModalViewBrand = (value) => {
    setRecordBrand(value);
    setvisibleViewBrand(true);
  };

  const handleCancelModalViewBrand = (value, key) => {
    setvisibleViewBrand(false);
    setRecordBrand({});
  };

  const handleopenModalEditModel = (value) => {
    setRecordModel({ ...value, mode: 'edit' });
    setVisibleCreateModel(true);
  };

  const handleCancelModalEditModel = () => {
    setVisibleCreateModel(false);
    setRecordModel([]);
  };

  const handleApplyBrand = () => {
    // setReportGrouptype((prev) => ({ ...prev, paginationPage: 1 }));
    setPageTable(1);
    setEvent((prev) => !prev);
  };

  const handleFieldSort = (value) => {
    setReportGrouptype((prev) => ({ ...prev, orderBy: value }));
  };

  const handleOrderSort = (value) => {
    setReportGrouptype((prev) => ({ ...prev, orderSort: value }));
  };

  const advancedFilter = (action, value) => {
    return (
      <Form colon={false}>
        <Row gutter={[24, 2]}>
          <Col span={12}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'modalCreateBrandFilterStatus', defaultMessage: 'Status' })} req={false} />
              }
            >
              <Select
                allowClear
                value={_.get(value, 'active') || undefined}
                onChange={(value) => handleChange(value, 'active')}
                placeholder={intl.formatMessage({ id: 'modalCreateBrandFilterStatusPlaceholder', defaultMessage: 'Select Status' })}
                // mode="multiple"
              >
                {statusList &&
                  statusList.map((item) => (
                    <Option key={item.active} value={item.active}>
                      {item.statusName}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'modalCreateGroupFilterDescription', defaultMessage: 'Description' })}
                  req={false}
                />
              }
            >
              <Input
                allowClear
                value={_.get(value, 'itemModelDescription')}
                onChange={(e) => handleChange(e.target.value, 'itemModelDescription')}
                placeholder={intl.formatMessage({
                  id: 'modalCreateGroupFilterDescriptionPlaceHolder',
                  defaultMessage: 'Enter Description',
                })}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[24, 2]}>
          <Col span={8}></Col>
          <Col span={8}></Col>
          <Col span={8} style={{ textAlign: 'right' }}>
            <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleResetModel}>
              <FormattedMessage id="btnResetFilter" defaultMessage="Reset" />
            </Button_02>

            <Button_01
              style={{ margin: '0px 0px 0px 10px' }}
              key="print"
              type="primary"
              btnsize="wd_df"
              onClick={() => {
                handleApplyBrand();
              }}
            >
              <FormattedMessage id="btnApplyFilter" defaultMessage="Apply" />
            </Button_01>
          </Col>
        </Row>
      </Form>
    );
  };

  const contentAction = (record) => {
    if (_.get(record, 'active') === 'true') {
      return (
        <div style={{ display: 'grid' }}>
          <div>
            <Button style={{ width: 100 }} type="link" ghost onClick={() => onSelectRow(record)}>
              <FormattedMessage id="btnSelect" defaultMessage="Select" />
            </Button>
          </div>
          <div>
            <Button style={{ width: 100 }} type="link" ghost onClick={() => handleopenModalEditBrand(record)}>
              <FormattedMessage id="btnEdit" defaultMessage="Edit" />
            </Button>
          </div>
          <div>
            <Button style={{ width: 100 }} type="link" ghost onClick={() => handleDelete(record)}>
              <FormattedMessage id="btnDelete" defaultMessage="Delete" />
            </Button>
          </div>
        </div>
      );
    }
  };

  const handleDelete = (record) => {
    Modal.confirm({
      className: 'customer-modal-confirm',
      centered: true,
      icon: null,
      title:
        groupModalType === 'type'
          ? intl.formatMessage({ id: 'modalCreateGroupTypeDeletedType', defaultMessage: 'Are you sure to delete Type ?' })
          : intl.formatMessage({ id: 'modalCreateGroupTypeDeletedGroup', defaultMessage: 'Are you sure to delete Group ?' }),
      okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        const payload = groupModalType === 'type' ? {
          itemTypeId:  _.get(record, 'itemTypeId'),
          itemTypeName:  _.get(record, 'itemTypeName'),
          itemTypeNo: _.get(record, 'itemTypeNo'),
          itemTypeDescription: _.get(record, 'itemTypeDescription'),
          active: 'false',
        } : {
          itemGroupId: _.get(record, 'itemGroupId'),
          itemGroupName: _.get(record, 'itemGroupName'),
          itemGroupNo: _.get(record, 'itemGroupNo'),
          itemGroupDescription: _.get(record, 'itemGroupDescription'),
          active: 'false',
        };

        try {
          const response = groupModalType === 'type' ? await createTypeData(payload) : await createGroupData(payload);

          if (_.get(response, 'status.code') === 200) {
            successNotification(response.status.message);
            setEventDataDefault((prev) => !prev);
            setEvent((prev) => !prev);
          } else {
            errorNotification(_.get(response, 'status.message'));
          }
        } catch (error) {
          errorNotification(error.response.data.data.message);
        }
      },
    });
  };

  return (
    <Modal
      title={
        groupModalType === 'type'
          ? intl.formatMessage({ id: 'modalCreatepTypeTitle', defaultMessage: 'Type' })
          : intl.formatMessage({ id: 'modalCreateGroupTitle', defaultMessage: 'Group' })
      }
      visible={visible}
      onOk={handleClose}
      onCancel={handleClose}
      width={1122}
      zIndex={1000}
      centered={true}
      bodyStyle={{ padding: '0px 24px',marginTop: '-9px' }}
      style={{ height: 'calc(130vh - 300px)', margin: '0px 0px' }}
      className="modalBrandAndModel"
      footer={[
        <Button_01 key="close" type="primary" btnsize="wd_df" onClick={() => handleClose()}>
          <FormattedMessage id="btnClose" defaultMessage="Close" />
        </Button_01>,
      ]}
    >
      <Row style={{ marginTop: '10px' }}>
        <Col span={6} style={{  }}>
          <div
            className="listItemTextHeader"
            style={{ padding: '24px 16px 16px 0px',marginLeft: '-24px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', }}
          >
            <span style={{ width: '50%',paddingLeft: '16px' }}>
              <FormattedMessage id="modalListItemFilterText" defaultMessage="Filter" />
            </span>
          </div>
          <div style={{ marginLeft: '-24px' }}>
            {/* {toggleBrand === true ? ( */}
            <ListItemFilter
              onChange={handleChange}
              onAllowClear={{}}
              searchGroup={reportGrouptype}
              onClick={handleApplyBrand}
              onCancel={handleCancelBrand}
              refType={statusList}
              tempchange={tempchange}
              groupModalType={groupModalType}
            />
            <div style={{ padding: '5px 16px 0px 24px' }}>
              <Button_01
                style={{ margin: '0px', width: '100%' }}
                key="print"
                type="primary"
                btnsize="wd_df"
                onClick={() => handleApplyBrand()}
              >
                <FormattedMessage id="btnApplyFilter" defaultMessage="Apply" />
              </Button_01>
            </div>
          </div>
        </Col>
        <Col span={18} style={{borderLeft: '1px solid #e8e8e8'}}>
          <div
            className="listItemTextHeader"
            style={{ padding: '14px 24px 15px 24px',marginRight: '-24px' , display: 'flex', justifyContent: 'space-between', alignItems: 'center',}}
          >
            <span>
              {groupModalType === 'type' ? (
                <FormattedMessage id="modalCreateGroupTypeTitleType" defaultMessage="Type" />
              ) : (
                <FormattedMessage id="modalCreateGroupTypeTitleGroup" defaultMessage="Group" />
              )}
            </span>

            <Button_01 key="close" type="primary" btnsize="wd_df" onClick={() => handleopenModalBrand('create')}>
              <FormattedMessage id="btnCreate" defaultMessage="Create" />
            </Button_01>
          </div>
          <div style={{ height: isCollapse ? `calc(90vh - 357px)` : `calc(118vh - 357px)`, padding: '16px' }}>
            {/* {_.get(reportGrouptype, 'referenceType') && _.size(_.get(reportGrouptype, 'list')) > 0 ? ( */}
            <CustomTableComponent
              columns={groupModalType === 'type' ? columnsType : columns}
              dataSource={_.get(reportGrouptype, 'list')}
              total={_.get(reportGrouptype, 'totalItem')}
              onRow={true}
              role={true}
              paginationPage={pageTable}
              setPaginationPage={setPageTable}
              paginationShow={true}
              setPaginationSize={setSizeTable}
              paginationSize={sizeTable}
              scroll={{ x: true, y: isCollapse ? `calc(72vh - 357px)` : `calc(100vh - 357px)` }}
              contentAction={contentAction}
              setFieldSort={handleFieldSort}
              setOrderSort={handleOrderSort}
            />
          </div>
        </Col>
      </Row>
      <ModalCreateBrandForm
        title={
          groupModalType === 'type'
            ? // ? _.get(recordModel, 'itemBrandId')
            _.size(recordBrand) > 0
              ? intl.formatMessage({ id: `modalCreateGroupTitleEditType`, defaultMessage: 'Edit Type' })
              : intl.formatMessage({ id: `modalCreateGroupTitleCreateType`, defaultMessage: 'Create Type' })
            : _.size(recordBrand) > 0
            ? intl.formatMessage({ id: `modalCreateGroupTitleEditGroup`, defaultMessage: 'Edit Group' })
            : intl.formatMessage({ id: `modalCreateGroupTitleCreateGroup`, defaultMessage: 'Create Group' })
        }
        visible={visibleCreateBrand}
        onOk={handleCancelModalBrand}
        onCancel={handleCancelModalBrand}
        setEvent={setEvent}
        recordBrand={recordBrand}
        setRecordBrand={setRecordBrand}
        groupModalType={groupModalType}
      />
      <ModalCreateModelForm
        title={
          _.get(recordModel, 'itemModelId')
            ? intl.formatMessage({ id: `modalCreateGroupTitleEditType`, defaultMessage: 'Edit Type' })
            : intl.formatMessage({ id: `modalCreateGroupTitleCreateType`, defaultMessage: 'Create Type' })
        }
        visible={visibleCreateModel}
        onOk={handleCancelModalModel}
        onCancel={recordModel ? handleCancelModalEditModel : handleCancelModalModel}
        list={_.get(reportBrand, 'itemBrandsActive')}
        setEvent={setEvent}
        recordModel={recordModel}
      />
      <ModalViewBrandModal
        title={
          intl.formatMessage({ id: `modalCreateGroupTitleViewGroup`, defaultMessage: 'Group' }) + '・' + _.get(recordBrand, 'itemBrandNo')
        }
        visible={visibleViewBrand}
        onOk={handleCancelModalViewBrand}
        onCancel={handleCancelModalViewBrand}
        setEvent={setEvent}
        recordBrand={recordBrand}
        handleopenModalBrand={handleopenModalBrand}
      />
    </Modal>
  );
};

CreateGroupTypeModal.defaultProps = {
  // const intl = useIntl();
  // title: ,
  // title: intl.formatMessage({ id: 'quotationLabelSelectCustomer', defaultMessage: 'Select Customer' }),
  visible: false,
  onOk: () => console.warn('onOk not function '),
  onCancel: () => console.warn('onCancel not function '),
};

const ModalCreateGroupType = Form.create({ name: 'select_qt_Form' })(CreateGroupTypeModal);

export default ModalCreateGroupType;
