import React, { useState, useEffect, useRef } from 'react';
import { Form, Modal, Input, Row, Col, Select, Divider, Table, Progress, InputNumber } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import Button01 from '../../../../../components/v2/button_01';
import Button02 from '../../../../../components/v2/button_02';
import LabeRequireForm from '../../../../../components/label-required-form';
import { getStockListById, getConvertUoM, getConvertUoMBigger } from '../../../../../controllers/po/po';
import _ from 'lodash';
import { handleKeyDownDecimal2Fixed, handleKeyDownNumber } from '../../../../../component-function/fnc-number';
import { numberFormatter, numberParser } from '../../../../../component-function/fnc-inputnumber-currency';
import SuffixInput from '../../../../../components/v2/suffix';
import { v4 as uuidv4 } from 'uuid';
import CustomTableComponent from '../../../components/table';
import { Round } from '../../../../../component-function/lodash';

const { Option } = Select;

const ListItemCreate = (props) => {
  const { data, visible, setVisible, form, selectItem, setSelectItem } = props;
  const { getFieldDecorator, validateFields, resetFields, getFieldValue, setFieldsValue } = form;
  const [stockList, setStockList] = useState([]);
  const [uomList, setUomList] = useState([]);
  const [paginationPage, setPaginationPage] = useState(1)
  const intl = useIntl();
  const qty = parseInt(getFieldValue('qty') || 0);
  const price = parseFloat(getFieldValue('price') || 0.0);
  const discount = parseInt(getFieldValue('discount') || 0);
  const amount = Round(qty * price)

  const discountNumber = parseFloat(getFieldValue('discountNumber') || 0.0);
  const sumQty = _.sumBy(stockList, 'qty') || 0

  const inputNumberDiscountRef = useRef();
  const inputNumberDiscountNumberRef = useRef();

  useEffect(() => {
    const getStockList = async () => {
      const response = await getStockListById(_.get(data, 'itemMasterId'));
      // const unitData = await getConvertUoM(_.get(data, 'itemMasterId'));
      const unitData = await getConvertUoMBigger(_.get(data, 'itemMasterId'));

      setStockList(_.get(response, 'data.data.stockList'), paginationPage, 10);
      setUomList(_.get(unitData, 'data.data'));
    };
    if (data && visible) {
      getStockList();
    }
  }, [data, visible]);

  useEffect(() => {
    if (qty || price) {
      setFieldsValue({
        ['amount']: numberFormatter(qty * price)
      });
    }
  }, [qty, price])

  useEffect(() => {
    if (price && price > 0) {
      const percentNumber = (price * discount) / 100;
      const total = (qty * price) - (percentNumber * qty)
      setFieldsValue({
        ['discountNumber']: (((price * discount) / 100) * qty).toFixed(2),
        ['total']: numberFormatter(total),
      });
    } else {
      setFieldsValue({
        ['discount']: 0.00,
        ['discountNumber']: 0.0,
        ['total']: 0.0,
      });
    }
  }, [price, getFieldValue('amount')]);

  // useEffect(() => {
  //   if (discountNumber && discountNumber > 0) {
  //     const total = (qty * price) - discountNumber;
  //     setFieldsValue({
  //       ['discount']: ((discountNumber / (qty * price)) * 100).toFixed(2),
  //       ['total']: numberFormatter(total),
  //     });
  //   } else {
  //     setFieldsValue({
  //       ['discount']: 0.00,
  //       ['discountNumber']: 0.0,
  //       ['total']: numberFormatter(price * qty),
  //     });
  //   }
  // }, [discountNumber]);

  // useEffect(() => {
  //   if (discount && discount > 0) {
  //     const percentNumber = (price * discount) / 100;
  //     const total = (qty * price) - (percentNumber * qty);
  //     setFieldsValue({
  //       ['discountNumber']: (((price * discount) / 100) * qty).toFixed(2),
  //       ['total']: numberFormatter(total),
  //     });
  //   } else {
  //     setFieldsValue({
  //       ['discount']: 0.00,
  //       ['discountNumber']: 0.0,
  //       ['total']: numberFormatter(price * qty),
  //     });
  //   }
  // }, [discount]);

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: 70,
      align: 'center',
    },
    {
      title: intl.formatMessage({ id: 'modalListColumnsWarehouseCode', defaultMessage: 'Warehouse Code' }),
      dataIndex: 'warehouseCode',
      key: 'warehouseCode',
      width: 160,
    },
    {
      title: intl.formatMessage({ id: 'modalListColumnsWarehouse', defaultMessage: 'Warehouse' }),
      dataIndex: 'warehouseName',
      key: 'warehouseName',
      width: 200,
    },
    {
      title: intl.formatMessage({ id: 'modalListColumnsQty', defaultMessage: 'Qty' }),
      dataIndex: 'qty',
      key: 'qty',
      render: (text, record, index) => renderProgress(record),
    },
    {
      title: intl.formatMessage({ id: 'modalListColumnsMinQty', defaultMessage: 'Min Qty' }),
      dataIndex: 'minQty',
      key: 'minQty',
      width: 100,
    },
    {
      title: intl.formatMessage({ id: 'modalListColumnsMaxQty', defaultMessage: 'Max Qty' }),
      dataIndex: 'maxQty',
      key: 'maxQty',
      width: 100,
    },
  ];

  const renderProgress = (record) => {
    let color = '';
    let percent = 0;

    if (record.qty === 0) {
      color = '#e73845';
      percent = 100;
    } else if (record.qty < record.minQty) {
      color = '#febb02';
      percent = (record.qty / record.maxQty) * 100;
    } else if (record.qty > record.minQty) {
      color = '#6490cf';
      percent = (record.qty / record.maxQty) * 100;
    }

    return (
      <div className="progress-item">
        <Progress className="bar-progress-custom" strokeColor={color} percent={percent} showInfo={false} />{' '}
        <span className="progress-qty-value">{record.qtyDisplay}</span>
      </div>
    );
  };

  const handleSave = () => {
    validateFields(async (err, values) => {
      if (err) {
        return;
      }
      const filterUoM = _.filter(uomList, (item) => {
        return item.uomId === _.get(values, 'unit');
      });

      // const filterPromotion = _.filter(initialCampaignList, (item) => {
      //   return item.campaignId === _.get(values, 'promotion');
      // });
      const itemBody = {
        index: _.size(selectItem) + 1,
        itemMasterId: _.get(data, 'itemMasterId'),
        itemCode: _.get(data, 'itemCode'),
        itemName: _.get(data, 'itemName'),
        qty: parseFloat(_.get(values, 'qty')),
        count: parseFloat(_.get(values, 'qty')),
        uomId: _.get(values, 'unit'),
        uomName: _.get(filterUoM, '[0].name'),
        pricePerUnit: parseFloat(_.get(values, 'price')),
        // promotionCampaignId: _.get(values, 'promotion'),
        // promotionCampaign: _.get(filterPromotion, '[0].campaignName'),
        discountPercentage: parseFloat(_.get(values, 'discount')),
        discountPrice: parseFloat(_.get(values, 'discountNumber')),
        totalPrice: numberParser(_.get(values, 'total')),
        remark: _.get(values, 'remark') || '',
        instock: 0,
        credits: _.get(values, 'credits') || '',
        uuid: uuidv4(),
        isOutOfStock: false,
        type: "product"
      };

      const filterSelectItemMaster = _.filter(selectItem, (o) =>
        o.itemMasterId === itemBody.itemMasterId &&
        o.uomId === itemBody.uomId &&
        o.pricePerUnit === itemBody.pricePerUnit &&
        o.discountPercentage === itemBody.discountPercentage &&
        _.toString(o.credits) === _.toString(itemBody.credits)
      ).map((obj) => {
        return {
          ...obj,
          totalPrice: parseFloat(obj.totalPrice) + parseFloat(itemBody.totalPrice),
          qty: _.toNumber(obj.qty) + _.toNumber(itemBody.qty),
          discountPrice: ((parseFloat(itemBody.pricePerUnit) * parseFloat(itemBody.discountPercentage)) / 100) * (_.toNumber(obj.qty) + _.toNumber(itemBody.qty)),
        }
      })
      // console.log('filterSelectItemMaster add', filterSelectItemMaster, itemBody, selectItem)

      if (_.size(filterSelectItemMaster) > 0) {
        // setSelectItem([...selectItem, itemBody]);
        setSelectItem(_.map(selectItem, (o) => {
          if (_.get(filterSelectItemMaster, '[0].uuid') === o.uuid) {
            return { ..._.get(filterSelectItemMaster, '[0]') }
          } else {
            return { ...o }
          }
        }))
      } else {
        setSelectItem([...selectItem, itemBody]);
      }

      // console.log('itemBody', itemBody);
      setVisible(false);
      resetFields();
    });
  };

  const handleCancel = () => {
    setVisible(false);
    resetFields();
  };

  // console.log('selectItemQty', selectItem, data, sumQty)

  const validatorQty = (rule, value, callback) => {
    if (value) {
      const sumBySelectItem = _.sumBy(_.filter(selectItem, (o) => o.itemMasterId === _.get(data, 'itemMasterId')), (qty) => _.toNumber(qty.qty))
      const qtyRemaining = _.toNumber(sumQty) - _.toNumber(sumBySelectItem)

      // console.log('selectItemQty sumBySelectItem', sumBySelectItem, qtyRemaining,value,'/',getFieldValue('unit'))


      const FilterUom = _.filter(uomList, (item) => {
        return item.uomId === getFieldValue('unit');
      });

      const altQty = _.get(FilterUom, '[0].altQty');

      const parsValue = parseFloat(value * altQty || 0);

      // console.log('altQty',altQty);
      if (parsValue === 0) {
        callback('QTY must more than 0');
      } else if (parsValue > sumQty || parsValue > qtyRemaining) {
        callback('Qty more than stock');
      }
      // else if (parsValue > qtyRemaining){
      //   callback(`Remaining stock ${qtyRemaining} items`)
      // } 
      else {
        callback();
      }
    }
    else {
      callback();
    }
  }

  const currencyFormatter = (value) => {
    console.log('currencyFormatterx1', value);
    if (parseFloat(value) >= 0) {
      return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
  };

  const currencyParser = (value) => {
    // console.log('currencyParserx2', value);
    if (parseFloat(value) >= 0) {
      return value.replace(/\$\s?|(,*)/g, '');
    }
  };

  const mouseOver = () => {
    inputNumberDiscountRef.current.blur()
    inputNumberDiscountNumberRef.current.blur()
  }

  const handleChangeDiscountNumber = (e) => {
    const calPercent = Round((parseFloat(e) / amount) * 100)
    const calTotalPrice = amount - parseFloat(e)
    if (e && e > 0) {
      setFieldsValue({
        ['discount']: calPercent.toFixed(2),
        ['total']: numberFormatter(calTotalPrice),
      });
    } else {
      setFieldsValue({
        ['discount']: 0.0,
        ['discountNumber']: 0.0,
        ['total']: numberFormatter(amount),
      });
    }
  };

  const handleChangeDiscount = (e) => {
    const calPercent = Round((amount * parseFloat(e)) / 100)
    const calTotalPrice = amount - calPercent
    console.log("calPercent", calPercent, calTotalPrice)
    if (e && e > 0) {
      setFieldsValue({
        ['discountNumber']: calPercent.toFixed(2),
        ['total']: numberFormatter(calTotalPrice),
      });
    } else {
      setFieldsValue({
        ['discount']: 0.0,
        ['discountNumber']: 0.0,
        ['total']: numberFormatter(amount),
      });
    }
  };

  const handleChangeQTY = (e) => {
    const calAmount = Round(parseFloat(e) * price)
    if (e && e > 0) {
      setFieldsValue({
        ['discount']: 0.00,
        ['discountNumber']: 0.00,
        ['total']: numberFormatter(calAmount),
      });
    } else {
      setFieldsValue({
        ['price']: 0.0,
        ['discount']: 0.0,
        ['discountNumber']: 0.0,
        ['total']: 0.0,
      });
    }
  };

  const handleChangePrice = (e) => {
    const amount = Round(qty * parseFloat(e))
    const calTotalPercent = Round((amount * discount) / 100)
    const calTotalPrice = amount - calTotalPercent

    if (e && e > 0) {
      setFieldsValue({
        ['discountNumber']: calTotalPercent.toFixed(2),
        ['total']: numberFormatter(calTotalPrice),
      });
    } else {
      setFieldsValue({
        ['discount']: 0.0,
        ['discountNumber']: 0.0,
        ['total']: 0.0,
      });
    }
  };

  return (
    <Modal
      title={intl.formatMessage({ id: `modalListItemTitle`, defaultMessage: 'Add Item' })}
      centered={true}
      width={900}
      onCancel={handleCancel}
      visible={visible}
      destroyOnClose={true}
      bodyStyle={{ padding: 'unset', height: '600px' }}
      footer={[
        <Button02
          key="back"
          btnsize="wd_df"
          onClick={handleCancel}
          onMouseOver={mouseOver}
        >
          <FormattedMessage id="userDetailModalBtnCancel" defaultMessage="Cancel" />
        </Button02>,
        <Button01
          key="submit"
          type="primary"
          btnsize="wd_df"
          onClick={handleSave}
          disabled={sumQty === 0 ? true : false}
          style={{ margin: 'unset' }}
          onMouseOver={mouseOver}
        >
          <FormattedMessage id="userDetailModalBtnSave" defaultMessage="Save" />
        </Button01>,
      ]}
    >
      <div style={{ padding: '24px', height: '600px', overflowY: 'auto' }}>
        <div style={{ marginBottom: '28px' }}>
          <div style={{ marginBottom: '8px', color: '#1D3557', fontSize: '12px' }}>
            <LabeRequireForm text={intl.formatMessage({ id: 'modalListItemFormTitleItem', defaultMessage: 'Item' })} req={false} />
          </div>
          <div style={{ color: '#050505', fontSize: '13px' }}>
            {_.get(data, 'itemCode')} {_.get(data, 'itemName')}
          </div>
        </div>
        <Form form={form} layout="vertical" className='po-form'>
          <Row gutter={[16]}>
            <Col span={8}>
              <Form.Item
                label={<LabeRequireForm text={intl.formatMessage({ id: 'modalListItemFormTitleQty', defaultMessage: 'Qty' })} req={true} />}
              >
                {getFieldDecorator('qty', {
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'modalListItemFormValidateQty', defaultMessage: 'Please Enter Qty' }),
                    },
                    {
                      validator: validatorQty
                    }
                  ],
                })(
                  <InputNumber
                    className='disabled-handle-count'
                    placeholder={intl.formatMessage({ id: 'modalListItemFormPlaceholderQty', defaultMessage: 'Enter Qty' })}
                    onKeyDown={handleKeyDownNumber}
                    ////formatter={currencyFormatter}
                    parser={currencyParser}
                    controls={false}
                    onChange={handleChangeQTY}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={
                  <LabeRequireForm text={intl.formatMessage({ id: 'modalListItemFormTitleUnit', defaultMessage: 'Unit' })} req={true} />
                }
              >
                {getFieldDecorator('unit', {
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'modalListItemFormValidateUnit', defaultMessage: 'Please Select Unit' }),
                    },
                  ],
                })(
                  <Select
                    placeholder={intl.formatMessage({ id: 'modalListItemFormPlaceholderUnit', defaultMessage: 'Select Unit' })}
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    showSearch
                  >
                    {_.map(uomList, (item) => (
                      <Option value={item.uomId}>{item.name}</Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={
                  <LabeRequireForm
                    text={intl.formatMessage({ id: 'modalListItemFormTitlePrice', defaultMessage: 'Price/Unit' })}
                    req={true}
                  />
                }
              >
                {getFieldDecorator('price', {
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'modalListItemFormValidatePrice', defaultMessage: 'Please Enter Price/Unit' }),
                    },
                  ],
                })(
                  <InputNumber
                    className='disabled-handle-count'
                    placeholder={intl.formatMessage({ id: 'modalListItemFormPlaceholderPrice', defaultMessage: 'Enter Price/Unit' })}
                    onKeyDown={handleKeyDownDecimal2Fixed}
                    ////formatter={currencyFormatter}
                    parser={currencyParser}
                    controls={false}
                    onChange={handleChangePrice}
                  />
                )}
                <SuffixInput text="THB" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16]}>
            <Col span={8}>
              <Form.Item
                label={
                  <LabeRequireForm text={intl.formatMessage({ id: 'quotationLblColumnsAmount', defaultMessage: 'Amount' })} req={false} />
                }
              >
                {getFieldDecorator('amount', {
                  initialValue: '0.00',
                })(
                  <Input
                    maxLength={100}
                    disabled={true}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item
                label={
                  <LabeRequireForm
                    text={intl.formatMessage({ id: 'modalListItemFormTitleDiscount', defaultMessage: 'Discount' })}
                    req={false}
                  />
                }
              >
                {getFieldDecorator('discount', {
                  initialValue: '0.00',
                })(
                  <InputNumber
                    className='disabled-handle-count'
                    onKeyDown={handleKeyDownDecimal2Fixed}
                    ////formatter={currencyFormatter}
                    parser={currencyParser}
                    controls={false}
                    ref={inputNumberDiscountRef}
                    onChange={handleChangeDiscount}
                  />
                )}
                <SuffixInput text="%" />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item label={<LabeRequireForm text="" req={false} />}>
                {getFieldDecorator('discountNumber', {
                  initialValue: '0.00',
                })(
                  <InputNumber
                    className='disabled-handle-count'
                    suffix='THB'
                    onKeyDown={handleKeyDownDecimal2Fixed}
                    ////formatter={currencyFormatter}
                    parser={currencyParser}
                    controls={false}
                    ref={inputNumberDiscountNumberRef}
                    onChange={handleChangeDiscountNumber}
                  />
                )}
                <SuffixInput text="THB" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={
                  <LabeRequireForm text={intl.formatMessage({ id: 'modalListItemFormTitleTotal', defaultMessage: 'Total' })} req={false} />
                }
              >
                {getFieldDecorator('total', {
                  initialValue: '0.00',
                })(
                  <Input
                    placeholder={intl.formatMessage({ id: 'modalListItemFormPlaceholderTotal', defaultMessage: 'Enter Total' })}
                    maxLength={100}
                    onKeyDown={handleKeyDownDecimal2Fixed}
                    suffix="THB"
                    readOnly={true}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16]}>
            <Col span={8}>
              <Form.Item
                label={
                  <LabeRequireForm
                    text={intl.formatMessage({ id: 'modalListItemFormTitleCredits', defaultMessage: 'Credit' })}
                    req={true}
                  />
                }
              >
                {getFieldDecorator('credits', {
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'modalListItemFormValidateCredits', defaultMessage: 'Please Enter Credit' }),
                    },
                  ],
                })(<Input suffix={intl.formatMessage({ id: 'creditsLblDay', defaultMessage: 'Days' })} />)}
              </Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item
                label={
                  <LabeRequireForm
                    text={intl.formatMessage({ id: 'modalListItemFormTitleRemark', defaultMessage: 'Remark' })}
                    req={false}
                  />
                }
              >
                {getFieldDecorator('remark', {})(<Input maxLength={100} />)}
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <div style={{ marginTop: '32px' }}>
          <div>
            <FormattedMessage id="modalListItemFilterTextStockBalance" defaultMessage="Stock Balance" />
          </div>
          <Divider type="horizontal" />
          <div style={{ marginBottom: '10px' }}>
            {/* <Table columns={columns} dataSource={stockList} scroll={{ y: 120 }} pagination={false} /> */}
            <CustomTableComponent
              columns={columns}
              dataSource={stockList}
              scroll={{ y: 85 }}
              role={false}
              onRow={false}
              total={_.size(stockList)}
              setPaginationPage={setPaginationPage}
            />
          </div>
          <div style={{ height: '10px' }}></div>
        </div>
      </div>
    </Modal>
  );
};

const ModalCreateListItem = Form.create({
  name: 'assignTeam-form',
  mapPropsToFields() { },
})(ListItemCreate);

export default ModalCreateListItem;
