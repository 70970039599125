import React, { useState, useEffect, useCallback, useContext } from 'react';
import {useIntl, FormattedMessage} from 'react-intl';
import ModalForm from './modalForm';
import { Icon, Popover, Modal, notification, Row, Col, Divider } from 'antd';
import Table from '../../../components/v2/table';
// import '../../../components/css/card.css';
import cssStyle from './css/index.css';
import Button01 from '../../../components/v2/button_01';
import client from '../../../components/axiosClient';
// import HttpClient from '../../../components/httpClient';
import Provider from '../provider';
import styled from 'styled-components';
import { PageSettings } from '../../../config/page-settings';
import { OrganizationContext } from '../organization-context';


const DivDetail = styled.div`
  /* padding: 0px 24px; */
  margin-top: 36px;
  margin-bottom: 24px;
`;

// let client = HttpClient();

const Contacts = (props) => {
  const intl = useIntl();
  const mApp = useContext(PageSettings);
  const { stateMain, setStateMain } = useContext(OrganizationContext);
  // const { setRefreshSaveFrom } = props;

  // const app = Provider.useAppContext();
  // const languageValue = app.state.languageValue;

  // const orgId = props.orgId;
  
  const orgId = stateMain.orgId;
  const dataContacts = props.data;
  const created_by = 1;

  const [visible, setVisible] = useState(false);
  const [formRef, setFormRef] = useState(null);

  const columns = [
    {
      title: '#',
      select: 'Index',
      align: 'center',
      width: 70,
      key: 'index',
      render: (text, record, index) => index + 1,
    },
    {
      title: intl.formatMessage({id: 'orgTabDetailColumnCantactsName', defaultMessage: 'Name'}),
      dataIndex: 'name',
      key: 'name',
      render: (text, record, index) => {
        return record.name ? record.name : '-';
      },
    },
    {
      title: intl.formatMessage({id: 'orgTabDetailColumnCantactsPhone', defaultMessage: 'Phone Number'}),
      dataIndex: 'phone',
      key: 'phone',
      render: (text, record, index) => {
        return record.phone ? record.phone : '-';
      },
    },
    {
      title: intl.formatMessage({id: 'orgTabDetailColumnCantactsEmail', defaultMessage: 'Email'}),
      dataIndex: 'email',
      key: 'email',
      render: (text, record, index) => {
        return record.email != 'undefined' ? record.email : '-';
      },
    },
    {
      title: intl.formatMessage({id: 'orgTabDetailColumnCantactsDescription', defaultMessage: 'Description'}),
      dataIndex: 'description',
      key: 'description',
      render: (text, record, index) => {
        return record.description != 'undefined' ? record.description : '-';
      },
    },
    {
      title: '',
      key: 'options',
      dataIndex: 'options',
      align: 'center',
      width: '5%',
      render: (text, record, index) => {
        return (record.options = (
          <PageSettings.Consumer>
            {({ checkPermissionAction }) => (
              <div>
                {checkPermissionAction('P3PG1C3', 'P3PG1C3A3') ? (
                  <Icon
                    onClick={() => handleDelete(record)}
                    type={'delete'}
                  />
                ) : null}
              </div>
            )}
          </PageSettings.Consumer>
        ));
      },
    },
  ];

  const showModal = (e) => {
    setVisible(true);
  };

  const handleCancel = (e) => {
    formRef.validateFields(async (err, values) => {
      if (err) {
        return;
      }
      formRef.resetFields();
    });
    formRef.resetFields();
    setVisible(false);
  };

  const handleDelete = (contact) => {
    Modal.confirm({
      className: 'contacts-modal-confirm',
      icon: <Icon type="delete" style={{ color: '#FF0000' }} />,
      title: intl.formatMessage({id: 'lblDelete', defaultMessage: 'Are you sure you want to delete ?'}),
      okText: intl.formatMessage({id: 'modalConfirmBtnYes', defaultMessage: 'Yes'}),
      cancelText: intl.formatMessage({id: 'modalConfirmBtnNo', defaultMessage: 'No'}),
      centered: true,
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      onOk() {
        deleteContact(contact.org_con_id);
      },
      onCancel() {},
    });
  };

  const deleteContact = async (org_con_id) => {
    props.setLoading(true);
    try {
      const result = await client.delete(
        `/v2/organization/contacts/${org_con_id}/${created_by}?comId=${mApp.comId}`,
      );
      deletedNotification(result.data.message);
      // app.fnc.getRefresh();
      props.setRefreshSaveFrom(curren => !curren);
      props.setLoading(false);
    } catch (error) {
      props.setLoading(false);
      return
    }
  };

  const handleCreate = async () => {
    props.setLoading(true);
    formRef.validateFields(async (err, values) => {
      if (err) {
        return;
      }
      formRef.resetFields();
      try {
        const body = {
          name: `${values.name}`,
          phone: `${values.phone}`,
          email: `${values.email}`,
          description: `${values.description}`,
          created_by: orgId,
          org_id: orgId,
        };
        const result = await client.post(
          `/v2/organization/contacts/create?comId=${mApp.comId}`,
          body,
        );
        successNotification(result.data.message);
        // app.fnc.getRefresh();
        props.setRefreshSaveFrom(curren => !curren);
        props.setLoading(false);
      } catch (error) {
        props.setLoading(false);
        return
      }
      setVisible(false);
    });
  };

  const saveFormRef = useCallback((node) => {
    if (node !== null) {
      setFormRef(node);
    }
  }, []);

  const successNotification = (message) => {
    notification.success({
      message: message,
    });
  };

  const deletedNotification = (message) => {
    notification.success({
      message: message,
    });
  };

  return (
    <div className="contacts-card">
      <DivDetail>
        <Row className="contacts-row-header" gutter={[16, 16]} align="middle">
          <Col className="gutter-row" span={20}>
            <p className="contacts-p">
              <FormattedMessage id="orgTabDetailTitleContacts" defaultMessage="Contacts" />
            </p>
          </Col>

          <PageSettings.Consumer>
            {({ checkPermissionAction }) => (
              <Col
                className="gutter-row"
                span={4}
                style={{ textAlign: 'right' }}
              >
                {checkPermissionAction('P3PG1C3', 'P3PG1C3A2') ? (
                  <Button01
                    type="primary"
                    fontsize="sm"
                    btnsize="wd_df"
                    onClick={showModal}
                  >
                    <FormattedMessage id="orgTabDetailContactsBtnAddNew" defaultMessage="Add New" />
                  </Button01>
                ) : null}
              </Col>
            )}
          </PageSettings.Consumer>
        </Row>
      </DivDetail>

      <Divider className="contacts-divider" />

      <Table
        // className="contacts-table"
        rowKey={(record) => record.org_con_id}
        size={'middle'}
        dataSource={dataContacts}
        columns={columns}
        sizeWidth={'lg'}
        pagination={false}
      />

      <ModalForm
        ref={saveFormRef}
        visible={visible}
        onCancel={() => handleCancel()}
        onCreate={() => handleCreate()}
        // languageValue={languageValue}
      />
    </div>
  );
};

export default Contacts;
