import axios from 'axios';
import keycloak from '../components/v2.1.0/keycloak/keycloak';

export const language = async () => {
  const url = `${process.env.REACT_APP_IMG_HOST}`;
  const comId = localStorage.getItem('comId');
  const pageCode = localStorage.getItem('pageCode');
  const lang = localStorage.getItem('langValue') === 'undefined' ? 'EN' : localStorage.getItem('langValue');
  const memComId = localStorage.getItem('memComId');

  const axiosConfig = {
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + keycloak.token,
      'end-user': keycloak.subject,
      'memComId': memComId,
    },
  };
  
  const body = {
    companyId: comId,
    lang: lang,
    pageCode: pageCode,
    
  }

  try {
    const langValue = await axios.post(`${url}/language/company`, body, axiosConfig)
    if (langValue.status === 200) {
      return langValue.data[0];
    } else {
      return [];
    }
  } catch(err) {
    if (err) {
      return []
    }
  }
}

