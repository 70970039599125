import httpClient from '../../components/axiosClient';

const handleSearch = async (name) => {
  const comCode = localStorage.getItem('comCode');
  const body = {
    fullname: name
  }
  try {
    const response = await httpClient.post(`/v3/resource/manager/company/${comCode}/member/search-fullname`, body);
    if (response.status === 200) {
      return response.data
    } else {
      return [];
    }
  } catch (error) { return []; }
}

export default handleSearch;