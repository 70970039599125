import React, { useEffect, useContext, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import { TaskDetail } from '../../task-detail-context';
import { GetDataMoreDetail, UploadImage, GetDataMoreDetailTicket } from '../../../../../../controllers/more-detail/more-detail-api';
import _ from 'lodash';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import { v4 as uuidv4 } from 'uuid';
import { errorNotification } from '../../../../../v2/notification';
import { Icon, Card } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import ReactBnbGallery from 'react-bnb-gallery';
import { Preview01, Preview02, Preview03, Preview04, Preview05, PreviewPlus } from '../../../../../note-monitor/preview-picture';
import './index.css';
import NoDataBackgroundModal from '../../../../../no-data-page/modal-no-data';

export default () => {
  const intl = useIntl();
  const { stateTask, setStateTask } = useContext(TaskDetail);

  const { editorStaet, imgSrc, allSrc } = stateTask;
  const { setEditorStaet, setImgSrc, setAllSrc } = setStateTask;
  const tabKey = stateTask && stateTask.tabKey ? _.get(stateTask, 'tabKey') : undefined;

  const taskDetailId = _.get(stateTask, 'taskId');
  const taskReferenceType = _.get(stateTask.viewInformationData.information, 'reference_type');
  const taskReference = _.get(stateTask.viewInformationData.information, 'reference');

  const [data, setData] = useState();
  const [dataAttach, setDataAttach] = useState([]);
  const [OpenGallery, setOpenGallery] = useState(false);
  const [galleryImg, setGalleryImg] = useState([]);
  const [numberImage, setNumberImage] = useState(0);

  const renderTopicObj = _.get(data, 'topicHelpDesk') && _.get(data, 'topicHelpDesk').map((i) => i.requireItemName);
  const renderTopic = renderTopicObj && renderTopicObj.join(', ');

  useEffect(() => {
    const getData = async () => {
      if (taskDetailId && taskReferenceType !== 'mobile_ticket') {
        const respone = await GetDataMoreDetail(taskDetailId);
        if (_.size(respone) !== 0) {
          setStateTask.setTextValue(_.get(respone, 'data[0].detail'));
          setStateTask.setMoreDetailId(_.get(respone, 'data[0].id'));
          const html = _.get(respone, 'data[0].detail');
          if (html) {
            const contentBlock = htmlToDraft(html);
            if (contentBlock) {
              const content = ContentState.createFromBlockArray(contentBlock.contentBlocks);
              setEditorStaet(EditorState.createWithContent(content));
              setStateTask.setContentMoreDetail(true);
            }
          } else {
            setEditorStaet(EditorState.createEmpty());
            setStateTask.setContentMoreDetail(false);
          }
        }
      }
    };
    if (taskReferenceType !== 'mobile_ticket' && tabKey === 'moreDetail') {
      getData();
    }
  }, [taskDetailId, stateTask.statusView, tabKey]);

  useEffect(() => {
    const getData = async () => {
      if (taskDetailId && taskReferenceType === 'mobile_ticket') {
        const respone = await GetDataMoreDetailTicket(taskReference);
        if (respone) {
          setData(_.get(respone.data.data, 'ticketInfo'));
          setDataAttach(_.get(respone.data.data, 'attachment'));
        }
      }
    };
    if (taskReferenceType === 'mobile_ticket' && tabKey === 'moreDetail') {
      getData();
    }
  }, [taskDetailId, tabKey]);

  const uploadCallback = (file) => {
    return new Promise(async (resolve, reject) => {
      const nameFolder = `task_more_detail/${uuidv4()}`;
      const data = new FormData();
      data.append('file', file);
      data.append('moduleName', nameFolder);
      const response = await UploadImage(data);
      if (_.get(response, 'status') === 200) {
        allSrc.push({
          fileCodeIs: _.get(response, 'data.data.fileCodeIs'),
          fullPathIs: _.get(response, 'data.data.fullPathIs'),
        });
        setAllSrc(allSrc);
        resolve({ data: { link: _.get(response, 'data.data.fullPathIs') } });
      } else {
        errorNotification(_.get(response, 'data.status.message'));
        return reject({});
      }
    });
  };

  const handleEditStateChange = (value) => {
    const covertData = convertToRaw(value.getCurrentContent());
    const logImg = [];
    _.map(covertData.entityMap, (i) => {
      logImg.push({
        fullPathIs: i.data.src,
      });
    });
    setImgSrc(logImg);
    setEditorStaet(value);
    convertHTML(value);
  };

  const convertHTML = (value) => {
    let currentContentAsHTML = draftToHtml(convertToRaw(value.getCurrentContent()));
    setStateTask.setTextValue(currentContentAsHTML);
  };

  const config = {
    options: ['inline', 'list', 'textAlign', 'link', 'image', 'history'],
    inline: { options: ['bold', 'italic', 'underline'] },
    list: { options: ['unordered', 'ordered'] },
    link: { options: ['link'] },
    image: {
      uploadCallback: uploadCallback,
      previewImage: true,
      inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
      defaultSize: {
        height: '100',
        width: '80',
      },
      className: undefined,
      component: undefined,
      popupClassName: undefined,
      urlEnabled: true,
      uploadEnabled: true,
      alignmentEnabled: true,
    },
  };

  const handlePreviewImage = (page, data, itemData) => {
    // fnc.handleOpenViewModuleAction('post', postData.postId);
    const mapPhoto =
      data && _.size(data) !== 0
        ? data.map((item, index) => {
            return {
              photo: _.get(item, 'uri'),
              number: index,
              caption: _.get(item, 'descriptionPhoto'),
              subcaption: `${intl.formatMessage({ id: `moreDetailPhotoBy`, defaultMessage: 'Photo By' })} ${_.get(item, 'photoBy')}`,
              thumbnail: _.get(item, 'uri'),
            };
          })
        : [];
    setGalleryImg(mapPhoto);
    setOpenGallery(true);
    setNumberImage(page);
  };

  const handleAddress = (data) => {
    if (data) {
      window.open(`https://www.google.com/maps/search/?api=1&query=${_.get(data, 'addressLat')}, ${_.get(data, 'addressLng')}`, '_blank');
    }
  };

  const fncPreview = (imageList, item) => {
    let sum = imageList.length;
    switch (sum) {
      case 1:
        return <Preview01 imageList={imageList} handlePreviewImage={handlePreviewImage} itemData={item} />;
      case 2:
        return <Preview02 imageList={imageList} handlePreviewImage={handlePreviewImage} itemData={item} />;
      case 3:
        return <Preview03 imageList={imageList} handlePreviewImage={handlePreviewImage} itemData={item} />;
      case 4:
        return <Preview04 imageList={imageList} handlePreviewImage={handlePreviewImage} itemData={item} />;
      case 5:
        return <Preview05 imageList={imageList} handlePreviewImage={handlePreviewImage} itemData={item} />;
      default:
        return <PreviewPlus imageList={imageList} handlePreviewImage={handlePreviewImage} itemData={item} />;
    }
  };

  return (
    <>
      {taskReferenceType === 'mobile_ticket' ? (
        <div className="container-ticket-detail">
          <div className="div-ticket-detail">
            {renderTopic ? (
              <div className="header-ticket-detail">
                <p className="header-required">{renderTopic}</p>
              </div>
            ) : null}
            <div className="detail-ticket-detail">
              <p className={_.get(data, 'descriptionAddress') ? 'detail-required' : 'detail-required-no-address'}>
                {intl.formatMessage({ id: `moreDetailSubmitDate`, defaultMessage: 'Submitted Date' })} : {_.get(data, 'submitDateTxt')}
                {_.get(data, 'requiredDateTxt') !== '-' ? ',' : null}{' '}
                {_.get(data, 'requiredDateTxt') !== '-'
                  ? `${intl.formatMessage({ id: `moreDetailRequiredDate`, defaultMessage: 'Required Date' })} : ${_.get(
                      data,
                      'requiredDateTxt'
                    )}`
                  : null}
                {_.get(data, 'description') ? ',' : null} {_.get(data, 'description')}
              </p>
            </div>
            {_.get(data, 'descriptionAddress') ? (
              <div className="map-ticket-detail">
                <div
                  className="ticket-card-text"
                  onClick={() =>
                    handleAddress({
                      addressLat: _.get(data, 'descriptionLat'),
                      addressLng: _.get(data, 'descriptionLng'),
                    })
                  }
                >
                  <p className="address-length-padding">- {intl.formatMessage({ id: `moreDetailAt`, defaultMessage: 'at' })}</p>
                  <p title={_.get(data, 'descriptionAddress')} className="address-length-p">
                    {_.get(data, 'descriptionAddress')}
                  </p>
                  <p className="address-length-padding-at">{`>`}</p>
                </div>
              </div>
            ) : null}
          </div>
          <div className="picture-ticket-detail">{dataAttach ? fncPreview(dataAttach, dataAttach) : null}</div>
          <ReactBnbGallery show={OpenGallery} photos={galleryImg} onClose={() => setOpenGallery(false)} activePhotoIndex={numberImage} />
        </div>
      ) : (
        <div style={{ padding: '16px 24px' }}>
          {stateTask.statusView ? (
            <Editor
              editorState={editorStaet}
              toolbar={config}
              onEditorStateChange={handleEditStateChange}
              editorStyle={stateTask.statusView ? { border: '1px solid #F1F1F1', height: '400px' } : null}
              toolbarHidden={stateTask.statusView ? false : true}
              readOnly={stateTask.statusView ? false : true}
            />
          ) : stateTask.contentMoreDetail === true ? (
            <Editor
              editorState={editorStaet}
              toolbar={config}
              onEditorStateChange={handleEditStateChange}
              editorStyle={stateTask.statusView ? { border: '1px solid #F1F1F1', height: '400px' } : null}
              toolbarHidden={stateTask.statusView ? false : true}
              readOnly={stateTask.statusView ? false : true}
            />
          ) : (
            <Card className="task-status-card" style={{ height: '479px' }}>
              {/* <div className="task-status-content-detail">
                  <Icon className="task-status-content-icon" type="profile" />
                  <p className="task-status-content-text">
                    <FormattedMessage
                      id='taskDetailNoMoreDetail'
                      defaultMessage="No More Detail" />
                  </p>
                </div> */}
              <NoDataBackgroundModal text1={{ id: 'taskDetailNoMoreDetail', text: 'No More Detail' }} />
            </Card>
          )}
        </div>
      )}
    </>
  );
};
