import React, { useState, useEffect, useContext, useRef } from 'react';
import { Modal, Tabs, Form, Tag } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import PurchaseCreateDetail from './detail';
import PurchaseCreateDelivery from './delivery';
import Button01 from '../../../../components/v2/button_01';
import Button02 from '../../../../components/v2/button_02';
import _ from 'lodash';
import { numberFormatter } from '../../../../component-function/fnc-inputnumber-currency';
import {
  getPoDetail,
  getPoAttachment,
  updateStatusPo,
  GetSignaturePrintPO,
  POPDFDownloader,
  POPDFDownloaderA5,
} from '../../../../controllers/po/po';
import ModalPOStatusForm from './status';
import { successNotification, errorNotification } from '../../../../components/v2/notification';
import ReactBnbGallery from 'react-bnb-gallery';
import { PageSettings } from '../../../../config/page-settings';
import { useReactToPrint } from 'react-to-print';
import POPrint from '../../../../components/modelprint/POPrint';
import { GetSignaturePrint } from '../../../../controllers/quotation/quotation-api-new';
import ReferenceTab from '../../../../components/reference';
import { GeneratorPDF, ThaiNumberToText, UrlPDF } from '../../../../component-function/generator-pdf';
import ModalPDFViewver from '../../../../component-function/pdf-viewver';

const { TabPane } = Tabs;

const PurchaseView = (props) => {
  const { visible, setVisible, form, setTrigger, defaultData, setDefaultData, keyTabMonitor, nextStatus, handleEdit } = props;
  const { checkPermissionAction } = useContext(PageSettings);
  const intl = useIntl();
  const [tabChange, setTabChange] = useState('1');
  const [selectItem, setSelectItem] = useState([]);
  const [deliveryList, setDeliveryList] = useState([]);
  const [attachmentData, setAttachmentData] = useState([]);
  const [editDefault, setEditDefault] = useState();
  const [visibleStatus, setVisibleStatus] = useState(false);
  const [type, setType] = useState();
  const [stateModal, setStateModal] = useState(false);
  const [galleryImg, setGalleryImg] = useState([]);
  const [numberImage, setNumberImage] = useState(0);
  const [signatureUser, setSignatureUser] = useState();
  const POPrintrRef = useRef();
  const [refItem, setRefItem] = useState([]);
  const [visiblePrint, setVisiblePrint] = useState(false);
  const [urlPrint, setUrlPrint] = useState();
  const statusCancel =
    _.get(defaultData, 'statusPaymentCode') === 'waitingpaid' && _.get(defaultData, 'statusReceiveCode') === 'waiting' ? true : false;

  useEffect(() => {
    const getDataDefault = async () => {
      const response = await getPoDetail(_.get(defaultData, 'poId'));
      const responseSignature = await GetSignaturePrintPO(_.get(response, 'data.data.approverMemComId'));
      setEditDefault(_.get(response, 'data.data'));
      setSelectItem(_.get(response, 'data.data.itemPo'));
      setDeliveryList(_.get(response, 'data.data.deliveryPo'));
      setSignatureUser(_.get(responseSignature, 'data'));
    };
    if (defaultData && visible) {
      getDataDefault();
    }
  }, [defaultData, visible]);

  useEffect(() => {
    const getFileDefault = async () => {
      const att = await getPoAttachment(_.get(defaultData, 'poId'));
      const mapAtt = _.map(_.get(att, 'data.data.list'), (item, index) => {
        return {
          id: item.fileHash,
          index: item.index,
          attachmentName: item.fileName,
          remark: item.remark,
          file: [...item.fileUrl],
          fileRaw: item.fileUrl,
        };
      });
      setAttachmentData(mapAtt);
    };
    if (defaultData && visible) {
      getFileDefault();
    }
  }, [defaultData, visible]);

  const columnsDetail = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
      fixed: 'left',
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsItemCode', defaultMessage: 'Item Code' }),
      dataIndex: 'itemCode',
      key: 'itemCode',
      sorter: true,
      width: 120,
      fixed: 'left',
      render: (text, record, index) => (record.itemCode !== 'undefined' ? record.itemCode : ''),
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsItem', defaultMessage: 'Item' }),
      dataIndex: 'itemName',
      key: 'itemName',
      sorter: true,
      render: (text, record, index) => (record.itemName !== 'undefined' ? record.itemName : ''),
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsQty', defaultMessage: 'Qty' }),
      dataIndex: 'qty',
      key: 'qty',
      sorter: true,
      render: (text, record, index) => record.qty + ' ' + record.uomName,
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnPrice', defaultMessage: 'Price/Unit' }),
      dataIndex: 'price',
      key: 'price',
      sorter: true,
      render: (text, record, index) => numberFormatter(record.pricePerUnit) + ' ' + 'THB',
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnPromotionCampaign', defaultMessage: 'Promotion or Campaign' }),
      dataIndex: 'promotionCampaign',
      key: 'promotionCampaign',
      render: (text, record, index) => record.promotionCampaign || '-',
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsDiscountPercentage', defaultMessage: 'Discount Percentage' }),
      dataIndex: 'discountPercentage',
      key: 'discountPercentage',
      render: (text, record, index) => numberFormatter(record.discountPercentage) + ' ' + '%',
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsDiscount', defaultMessage: 'Discount' }),
      dataIndex: 'discountPrice',
      key: 'discountPrice',
      render: (text, record, index) => numberFormatter(record.discountPrice) + ' ' + 'THB',
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsRemark', defaultMessage: 'Remark' }),
      dataIndex: 'remark',
      key: 'remark',
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsAmount', defaultMessage: 'Amount' }),
      dataIndex: 'totalPrice',
      key: 'totalPrice',
      sorter: true,
      fixed: 'right',
      width: 120,
      render: (text, record, index) => numberFormatter(record.totalPrice) + ' ' + 'THB',
    },
  ];

  const columnsAtt = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
      fixed: 'left',
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsFileName', defaultMessage: 'File Name' }),
      dataIndex: 'attachmentName',
      key: 'attachmentName',
      sorter: true,
      render: (text, record, index) => (
        <span style={{ cursor: 'pointer' }} onClick={() => handlePreview(record, attachmentData)}>
          {record.attachmentName}
        </span>
      ),
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsRemark', defaultMessage: 'Remark' }),
      dataIndex: 'remark',
      key: 'remark',
    },
  ];

  const columnsDelivery = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: 70,
      align: 'center',
      render: (text, record, index) => index + 1,
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsDeliveryDate', defaultMessage: 'Delivery Date' }),
      dataIndex: 'deliveryDateTxt',
      key: 'deliveryDateTxt',
      width: 150,
      sorter: true,
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsDeliveryVehicle', defaultMessage: 'Vehicle' }),
      dataIndex: 'vehicleTxt',
      key: 'vehicleTxt',
      width: 150,
      sorter: true,
      render: (text, record, index) =>
        (_.get(record, 'vehicleTypeCode') === 'internal' ? 'Internal' : 'Other') +
        ' - ' +
        (_.get(record, 'vehicleTypeCode') === 'internal' ? _.get(record, 'serialNo') : _.get(record, 'assetName') || ''),
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsDeliveryType', defaultMessage: 'Delivery Type' }),
      dataIndex: 'deliveryTypeTxt',
      key: 'deliveryTypeTxt',
      width: 150,
      sorter: true,
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsDeliveryAddress', defaultMessage: 'Delivery Address' }),
      dataIndex: 'deliveryAddressDetail',
      key: 'deliveryAddressDetail',
      width: 300,
      render: (text, record, index) => record.deliveryAddressDetail.addressName + ' - ' + record.deliveryAddressDetail.fullAddress,
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnTotal Items', defaultMessage: 'Total Items' }),
      dataIndex: 'total',
      key: 'total',
      sorter: true,
      width: 150,
      render: (text, record, index) => _.sumBy(record.itemList, 'qty') + ' - ' + 'Items',
    },
  ];

  const handlePreview = (record, data) => {
    const isPDF = _.includes(_.get(record, 'attachmentName'), 'pdf');
    if (isPDF) return window.open(_.get(record, 'fileRaw'), '_blank');
    const mapPhoto = data.map((item, index) => {
      return {
        photo: _.get(item, 'fileRaw'),
        number: index,
        caption: _.get(item, 'attachmentName'),
        subcaption: _.get(item, 'remark'),
        thumbnail: _.get(item, 'fileRaw'),
      };
    });
    const filerDefault = _.filter(mapPhoto, (item) => {
      return item.photo === record.fileRaw;
    });
    setGalleryImg(mapPhoto);
    setNumberImage(_.get(filerDefault, '[0].number'));
    setStateModal(true);
  };

  const handleSave = async (type) => {
    setType(type);
    setVisibleStatus(true);
  };

  const handleCancel = () => {
    setVisible(false);
    setDeliveryList([]);
    setSelectItem([]);
    setTabChange('1');
    setAttachmentData([]);
    setEditDefault();
    setDefaultData();
  };

  const handleTabChange = (tab) => {
    setTabChange(tab);
  };

  const operations = (
    <div style={{ textAlign: 'center', display: 'flex', padding: '10px' }}>
      <Tag className="tag-center-style" color={_.get(editDefault, 'statusColor') || ''}>
        {_.get(editDefault, 'statusName') || ''}
      </Tag>
    </div>
  );

  // const handlePrint = useReactToPrint({
  //   content: () => POPrintrRef.current,
  //   documentTitle: 'data',
  //   copyStyles: true,
  // });

  const handlePrint = async () => {
    const formateData = fomatePDF(editDefault);
    const response = await POPDFDownloader(_.get(editDefault, 'poNo'), formateData);
    if (_.get(response, 'status') === 200) {
      const url = await UrlPDF(response);
      if (url) {
        setUrlPrint(url);
        setVisiblePrint(true);
      }
      // GeneratorPDF(response)
    }
  };

  const handlePrintA5 = async () => {
    const formateData = fomatePDF(editDefault);
    const response = await POPDFDownloaderA5(_.get(editDefault, 'poNo'), formateData);
    if (_.get(response, 'status') === 200) {
      const url = await UrlPDF(response);
      if (url) {
        setUrlPrint(url);
        setVisiblePrint(true);
      }
      // GeneratorPDF(response)
    }
  };

  const fomatePDF = (props) => {
    const totalNum = _.get(props, 'totalNum') ? _.get(props, 'totalNum') : 0;
    return {
      ...editDefault,
      totalNum: totalNum,
      totalNumberText: ThaiNumberToText(totalNum.toFixed(2)),
    };
  };

  const handleDelete = async (id) => {
    Modal.confirm({
      className: 'customer-modal-confirm',
      centered: true,
      icon: null,
      title: intl.formatMessage({ id: 'lblDelete', defaultMessage: 'Are you sure you want to delete ?' }),
      okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        const response = await updateStatusPo(id, {
          statusCode: 'deleted',
          remark: '',
          reason: '',
          actionBy: localStorage.getItem('memComId'),
        });
        if (response.status === 200) {
          successNotification(_.get(response, 'data.status.message'));
          setTrigger((cur) => !cur);
          setVisible(false);
        } else {
          errorNotification(_.get(response, 'data.status.message'));
        }
      },
      onCancel() {},
    });
  };

  const handleButton = (data, defaultProp, status) => {
    if (data && defaultProp) {
      console.log('defaultProp', defaultProp);
      if (_.get(data, 'statusCode') === 'waiting') {
        if (checkPermissionAction('P56PG1C1', 'P56PG1C1A2')) {
          if (checkPermissionAction('P56PG1C1', 'P56PG1C1A5')) {
            return [
              <Button02 key="back" btnsize="wd_df" onClick={handleCancel}>
                <FormattedMessage id="btnClose" defaultMessage="Close" />
              </Button02>,
              <Button01
                style={{ margin: 'unset' }}
                key="submit"
                type="primary"
                btnsize="wd_df"
                onClick={() => handleDelete(_.get(defaultProp, 'poId'))}
              >
                <FormattedMessage id="btnDelete" defaultMessage="Delete" />
              </Button01>,
              <Button01 key="submit" type="primary" btnsize="wd_df" onClick={() => handleSave('canceled')}>
                <FormattedMessage id="btnCancelPo" defaultMessage="Cancel PO" />
              </Button01>,
              <Button01 style={{ margin: 'unset' }} key="submit" type="primary" btnsize="wd_df" onClick={() => handleSave('rejected')}>
                <FormattedMessage id="btnReject" defaultMessage="Reject" />
              </Button01>,
              <Button01 key="submit" type="primary" btnsize="wd_df" onClick={() => handleSave('approved')}>
                <FormattedMessage id="btnApprove" defaultMessage="Approve" />
              </Button01>,
            ];
          } else {
            return [
              <Button02 style={{ margin: 'unset' }} key="back" btnsize="wd_df" onClick={handleCancel}>
                <FormattedMessage id="btnClose" defaultMessage="Close" />
              </Button02>,
              <Button01 style={{ margin: '0px 8px' }} key="submit" type="primary" btnsize="wd_df" onClick={() => handleSave('canceled')}>
                <FormattedMessage id="btnCancelPo" defaultMessage="Cancel PO" />
              </Button01>,
              <Button01 style={{ margin: 'unset' }} key="submit" type="primary" btnsize="wd_df" onClick={() => handleSave('rejected')}>
                <FormattedMessage id="btnReject" defaultMessage="Reject" />
              </Button01>,
              <Button01 key="submit" type="primary" btnsize="wd_df" onClick={() => handleSave('approved')}>
                <FormattedMessage id="btnApprove" defaultMessage="Approve" />
              </Button01>,
            ];
          }
        } else {
          return [
            <Button02 key="back" btnsize="wd_df" onClick={handleCancel}>
              <FormattedMessage id="btnClose" defaultMessage="Close" />
            </Button02>,
            <Button01
              style={{ margin: 'unset' }}
              key="submit"
              type="primary"
              btnsize="wd_df"
              onClick={() => handleDelete(_.get(defaultProp, 'poId'))}
            >
              <FormattedMessage id="btnDelete" defaultMessage="Delete" />
            </Button01>,
            <Button01 key="submit" type="primary" btnsize="wd_df" onClick={() => handleSave('canceled')}>
              <FormattedMessage id="btnCancelPo" defaultMessage="Cancel PO" />
            </Button01>,
          ];
        }
      } else if (_.get(data, 'statusCode') === 'approved') {
        if (checkPermissionAction('P56PG1C1', 'P56PG1C1A2')) {
          if (status) {
            return [
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <div>
                  <Button01 style={{ margin: 'unset' }} key="print" type="primary" btnsize="wd_df" onClick={() => handlePrint()}>
                    <FormattedMessage id="btnPrint" defaultMessage="Print" />
                  </Button01>
                </div>
                <div>
                  <Button02 style={{ margin: 'unset', marginLeft: '8px' }} key="back" btnsize="wd_df" onClick={handleCancel}>
                    <FormattedMessage id="btnClose" defaultMessage="Close" />
                  </Button02>
                  <Button01
                    key="submit"
                    type="primary"
                    btnsize="wd_df"
                    onClick={() => handleSave('canceled')}
                    style={{ marginRight: 'unset' }}
                  >
                    <FormattedMessage id="btnCancelPo" defaultMessage="Cancel PO" />
                  </Button01>
                  <Button01
                    style={{ margin: 'unset', marginLeft: '8px' }}
                    key="submit"
                    type="primary"
                    btnsize="wd_df"
                    onClick={() => handleSave('waiting')}
                  >
                    <FormattedMessage id="btnWating" defaultMessage="Waiting" />
                  </Button01>
                  <Button01
                    style={{ margin: 'unset', marginLeft: '8px' }}
                    key="submit"
                    type="primary"
                    btnsize="wd_df"
                    onClick={() => handleSave('rejected')}
                  >
                    <FormattedMessage id="btnReject" defaultMessage="Reject" />
                  </Button01>
                  <Button01
                    style={{ margin: 'unset', marginLeft: '8px' }}
                    key="submit"
                    type="primary"
                    btnsize="wd_df"
                    onClick={() => handleSave('torevise')}
                  >
                    <FormattedMessage id="btnToRevise" defaultMessage="To Revise" />
                  </Button01>
                </div>
              </div>,
            ];
          } else {
            return [
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <div>
                  <Button01 style={{ margin: 'unset' }} key="print" type="primary" btnsize="wd_df" onClick={() => handlePrint()}>
                    <FormattedMessage id="btnPrint" defaultMessage="Print" />
                  </Button01>
                </div>
                <div>
                  <Button02 style={{ margin: 'unset', marginLeft: '8px' }} key="back" btnsize="wd_df" onClick={handleCancel}>
                    <FormattedMessage id="btnClose" defaultMessage="Close" />
                  </Button02>
                  <Button01
                    style={{ margin: 'unset', marginLeft: '8px' }}
                    key="submit"
                    type="primary"
                    btnsize="wd_df"
                    onClick={() => handleSave('waiting')}
                  >
                    <FormattedMessage id="btnWating" defaultMessage="Waiting" />
                  </Button01>
                  <Button01
                    style={{ margin: 'unset', marginLeft: '8px' }}
                    key="submit"
                    type="primary"
                    btnsize="wd_df"
                    onClick={() => handleSave('rejected')}
                  >
                    <FormattedMessage id="btnReject" defaultMessage="Reject" />
                  </Button01>
                  <Button01
                    style={{ margin: 'unset', marginLeft: '8px' }}
                    key="submit"
                    type="primary"
                    btnsize="wd_df"
                    onClick={() => handleSave('torevise')}
                  >
                    <FormattedMessage id="btnToRevise" defaultMessage="To Revise" />
                  </Button01>
                </div>
              </div>,
            ];
          }
        } else {
          return [
            <Button02 key="back" btnsize="wd_df" onClick={handleCancel}>
              <FormattedMessage id="btnClose" defaultMessage="Close" />
            </Button02>,
            <Button01 style={{ margin: 'unset' }} key="submit" type="primary" btnsize="wd_df" onClick={() => handleSave('waiting')}>
              <FormattedMessage id="btnWating" defaultMessage="Waiting" />
            </Button01>,
          ];
        }
      } else if (_.get(data, 'statusCode') === 'torevise') {
        if (checkPermissionAction('P56PG1C1', 'P56PG1C1A2')) {
          return [
            <Button02 key="back" btnsize="wd_df" onClick={handleCancel}>
              <FormattedMessage id="btnClose" defaultMessage="Close" />
            </Button02>,
            <>
              {_.get(data, 'reOpen') ? (
                <Button01 style={{ margin: 'unset' }} key="submit" type="primary" btnsize="wd_df" onClick={() => handleSave('reOpen')}>
                  <FormattedMessage id="btnReOpen" defaultMessage="Reopen" />
                </Button01>
              ) : null}
            </>,
          ];
        } else {
          return [
            <Button02 key="back" btnsize="wd_df" onClick={handleCancel}>
              <FormattedMessage id="btnClose" defaultMessage="Close" />
            </Button02>,
          ];
        }
      } else if (_.get(data, 'statusCode') === 'rejected') {
        if (checkPermissionAction('P56PG1C1', 'P56PG1C1A2')) {
          return [
            <Button02 key="back" style={{ margin: 'unset' }} btnsize="wd_df" onClick={handleCancel}>
              <FormattedMessage id="btnClose" defaultMessage="Close" />
            </Button02>,
            // <Button01 style={{ margin: 'unset' }} key="submit" type="primary" btnsize="wd_df" onClick={() => handleSave('draft')} >
            //   <FormattedMessage id="btnDraft" defaultMessage="Draft" />
            // </Button01>,
            <Button01 key="submit" type="primary" btnsize="wd_df" onClick={() => handleSave('waiting')}>
              <FormattedMessage id="btnWating" defaultMessage="Waiting" />
            </Button01>,
            <Button01 style={{ margin: 'unset' }} key="submit" type="primary" btnsize="wd_df" onClick={() => handleSave('approved')}>
              <FormattedMessage id="btnApprove" defaultMessage="Approve" />
            </Button01>,
          ];
        } else {
          return [
            <Button02 style={{ margin: 'unset' }} key="back" btnsize="wd_df" onClick={handleCancel}>
              <FormattedMessage id="btnClose" defaultMessage="Close" />
            </Button02>,
            // <Button01 style={{ margin: 'unset' }} key="submit" type="primary" btnsize="wd_df" onClick={() => handleSave('draft')} >
            //   <FormattedMessage id="btnDraft" defaultMessage="Draft" />
            // </Button01>,
            <Button01 key="submit" type="primary" btnsize="wd_df" onClick={() => handleSave('waiting')}>
              <FormattedMessage id="btnWating" defaultMessage="Waiting" />
            </Button01>,
          ];
        }
      } else {
        return [
          <Button01 style={{ margin: 'unset' }} key="submit" type="primary" btnsize="wd_df" onClick={handleCancel}>
            <FormattedMessage id="btnClose" defaultMessage="Close" />
          </Button01>,
        ];
      }
    } else {
      return [];
    }
  };

  const renderButtons = () => {
    const buttons = [];

    buttons.push(
      <Button02 style={{ margin: '0px 5px' }} key="back" btnsize="wd_df" onClick={handleCancel}>
        <FormattedMessage id="btnClose" defaultMessage="Close" />
      </Button02>
    );

      if (_.includes(nextStatus, 'canceled') && checkPermissionAction('P56PG1C1', 'P56PG1C1A10')) {
        buttons.push(
          <Button01 style={{ margin: '0px 5px' }} key="submit" type="primary" btnsize="wd_df" onClick={() => handleSave('canceled')}>
            <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
          </Button01>
        );
      }

      if (_.includes(nextStatus, 'torevise') && checkPermissionAction('P56PG1C1', 'P56PG1C1A7')) {
        buttons.push(
          <Button01 style={{ margin: '0px 5px' }} key="submit" type="primary" btnsize="wd_df" onClick={() => handleSave('torevise')}>
            <FormattedMessage id="btnToRevise" defaultMessage="To Revise" />
          </Button01>
        );
      }

      if (_.includes(nextStatus, 'deleted') && checkPermissionAction('P56PG1C1', 'P56PG1C1A5')) {
        buttons.push(
          <Button01
            style={{ margin: '0px 5px' }}
            key="submit"
            type="primary"
            btnsize="wd_df"
            onClick={() => handleDelete(_.get(defaultData, 'poId'))}
          >
            <FormattedMessage id="btnDelete" defaultMessage="Delete" />
          </Button01>
        );
      }

      if ((keyTabMonitor === 'torevise' || keyTabMonitor === 'draft') && checkPermissionAction('P56PG1C1', 'P56PG1C1A4')) {
        buttons.push(
          <Button01 style={{ margin: '0px 5px' }} key="submit" type="primary" btnsize="wd_df" onClick={() => handleEdit()}>
            <FormattedMessage id="btnEdit" defaultMessage="Edit" />
          </Button01>
        );
      }

      if (_.includes(nextStatus, 'waiting') && checkPermissionAction('P56PG1C1', 'P56PG1C1A8')) {
        buttons.push(
          <Button01
            style={{ margin: '0px 5px', width: 'auto' }}
            key="submit"
            type="primary"
            btnsize="wd_df"
            onClick={() => handleSave('waiting')}
          >
            <FormattedMessage id="btnSendApprove" defaultMessage="Send Approve" />
          </Button01>
        );
      }
      if (_.includes(nextStatus, 'approved') && checkPermissionAction('P56PG1C1', 'P56PG1C1A2')) {
        buttons.push(
          <Button01 style={{ margin: '0px 5px' }} key="submit" type="primary" btnsize="wd_df" onClick={() => handleSave('approved')}>
            <FormattedMessage id="btnApprove" defaultMessage="Approve" />
          </Button01>
        );
      }
      if (_.includes(nextStatus, 'rejected') && checkPermissionAction('P56PG1C1', 'P56PG1C1A9')) {
        buttons.push(
          <Button01 style={{ margin: '0px 5px' }} key="submit" type="primary" btnsize="wd_df" onClick={() => handleSave('rejected')}>
            <FormattedMessage id="btnReject" defaultMessage="Reject" />
          </Button01>
        );
      }

    return buttons;
  };

  const handleButton3 = () => {
    return [
      <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        <div>
          <Button01 style={{ margin: 'unset' }} key="print" type="primary" btnsize="wd_df" onClick={() => handlePrint()}>
            <FormattedMessage id="btnPrint" defaultMessage="Print" />
          </Button01>
        </div>
        <div>{renderButtons()}</div>
      </div>,
    ];
  };


  console.log('handleButton2', editDefault, defaultData, statusCancel, nextStatus);

  return (
    <Modal
      title={
        keyTabMonitor === 'draft'
          ? intl.formatMessage({ id: `purchesModalTitlePurchase`, defaultMessage: 'Purchase Order' })
          : `${intl.formatMessage({ id: `purchesModalTitlePurchase`, defaultMessage: 'Purchase Order' })} ・ ${_.get(defaultData, 'poNo')}`
      }
      visible={visible}
      onCancel={handleCancel}
      width={800}
      centered={true}
      bodyStyle={{ padding: 'unset' }}
      footer={handleButton3(editDefault, defaultData, statusCancel)}
      zIndex={1}
    >
      <Tabs animated={false} onChange={handleTabChange} activeKey={tabChange} tabBarExtraContent={operations} className="po-tabs-modal">
        <TabPane tab={intl.formatMessage({ id: `purchesModalTitleDetail`, defaultMessage: 'Detail' })} key="1">
          <PurchaseCreateDetail
            form={form}
            columns={columnsDetail}
            columnsAtt={columnsAtt}
            selectItem={selectItem}
            attachmentData={attachmentData}
            editDefault={editDefault}
          />
        </TabPane>
        <TabPane tab={intl.formatMessage({ id: `purchesModalTitleDelivery`, defaultMessage: 'Delivery' })} key="2">
          <PurchaseCreateDelivery columns={columnsDelivery} deliveryList={deliveryList} />
        </TabPane>
        <TabPane tab={intl.formatMessage({ id: `purchesModalTitleReference`, defaultMessage: 'Reference' })} key="3">
          <ReferenceTab
            visiblemodal={visible}
            selectItem={refItem}
            setSelectItem={setRefItem}
            dataform={defaultData}
            typeModal={'view'}
            typeRef={'po'}
          />
        </TabPane>
      </Tabs>
      <div style={{ display: 'none', fontFamily: 'SF ThonBuri' }}>
        {/* <div style={{fontFamily: 'SF ThonBuri'}}> */}
        <POPrint ref={POPrintrRef} record={editDefault} signatureUser={signatureUser} />
      </div>
      <ModalPOStatusForm
        visible={visibleStatus}
        setVisible={setVisibleStatus}
        setVisibleMain={setVisible}
        type={type}
        data={defaultData}
        setTrigger={setTrigger}
        setEditDefault={setEditDefault}
        setDefaultData={setDefaultData}
        keyTabMonitor={keyTabMonitor}
      />

      <ModalPDFViewver
        visible={visiblePrint}
        setVisible={setVisiblePrint}
        url={urlPrint}
        handlePrint={handlePrint}
        handlePrintA5={handlePrintA5}
        singlePage={true}
      />

      {attachmentData.length > 0 ? (
        <ReactBnbGallery show={stateModal} photos={galleryImg} onClose={() => setStateModal(false)} activePhotoIndex={numberImage} />
      ) : null}
    </Modal>
  );
};

const ViewPurchase = Form.create({
  name: 'po-form',
  mapPropsToFields() {},
})(PurchaseView);

export default ViewPurchase;
