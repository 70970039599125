import React from 'react'
import { Table } from 'antd'
import _ from 'lodash'

const PurchaseCreateDelivery = (props) => {
  const { columns, deliveryList } = props

  return (
    <div style={{ height: '540px', overflowY: 'auto' }}>
      <div style={{ padding: '0 24px 24px 24px' }}>
        <Table
          columns={columns}
          dataSource={deliveryList}
          pagination={false}
          scroll={{ x: _.sumBy(columns, 'width') }}
        />
      </div>
    </div>
  )
}

export default PurchaseCreateDelivery
