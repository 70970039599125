import React, { useState, useEffect } from 'react'
import style from './css/action-invoice.css'
import { useIntl, FormattedMessage } from 'react-intl';
import { Input, Select, DatePicker, Row, Col, Icon, List, Dropdown, Menu, Checkbox, Button ,ConfigProvider} from 'antd'
import Button01 from '../../../components/v2/button_01';
import moment from 'moment';
import enUS from 'antd/es/locale/en_US';
import thTH from 'antd/es/locale/th_TH';
import 'moment/locale/th';
import { useAppContext } from '../../../includes/indexProvider';

const { Option } = Select;
const { RangePicker } = DatePicker;
export default ({
  columns,
  checkedValuecolumns,
  checked,
  textErrorSelectColumn,
  handleCheckOk,
  handleReset,
  visible,
  handleVisibleChange,
  handleOnVisiblecolumns,
  onSelectRangePicker,
  onChange,
  onSelect,
  selectSearchData,
}) => {

  const intl = useIntl();
  const app = useAppContext();
  const langValue = app.state.langValue;
  const ignoreSearchColumn = ['index', 'edit', 'totalTask', 'totalBath', 'createDate'];
  const columnNewSearch = columns.filter((col) => !ignoreSearchColumn.includes(col.key));

  const ignoreColumn = ['index', 'edit', 'invoiceNo', 'customerName'];
  const columnNew = columns.filter((col) => !ignoreColumn.includes(col.key));

  const dateFormat = 'ddd, MMM DD YYYY';
  const today = new Date();

  const [locale, setLocale] = useState(enUS);

  useEffect(() => {
    changeLocale();
  }, []);

  const changeLocale = () => {
    let codeLang = langValue ? langValue : 'EN';
    let changeLang = codeLang === 'EN' ? enUS : thTH;
    setLocale(changeLang);
    if (!changeLang) {
      moment.locale('en');
    } else {
      moment.locale('th-th');
    }
  };

  const menuColumn = () => {
    return (
      <Menu>
        <Menu.Item style={{ backgroundColor: '#ffffff' }}>
          {columnNew.map((item, i) => (
            <div key={i} style={{ display: 'block', marginBottom: '10px' }}>
              <Checkbox
                value={item.key}
                onChange={checkedValuecolumns}
                checked={checked[item.key] ? true : false}
              >
                <span style={{ fontSize: '13px' }}>{item.title}</span>
              </Checkbox>
            </div>
          ))}
        </Menu.Item>

        <Menu.Item style={{ color: '#FF1C00', textAlign: 'left', backgroundColor: '#ffffff' }}>
          {textErrorSelectColumn}
        </Menu.Item>

        <Menu.Item style={{ backgroundColor: '#ffffff' }}>
          <Button.Group style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              onClick={handleCheckOk}
              style={{ width: '50%', fontSize: '13px' }}>
              <FormattedMessage id="btnOK" defaultMessage="OK" />
            </Button>
            <Button
              onClick={handleReset}
              style={{ width: '50%', fontSize: '13px' }}>
              <FormattedMessage id="btnReset" defaultMessage="Reset" />
            </Button>
          </Button.Group>
        </Menu.Item>
      </Menu>
    )
  }

  return (
    <Row>
      <Col span={12}>
        <List.Item className="invoice-list-left">
          <Input
            className='action-input'
            placeholder='Seaech'
            prefix={<Icon style={{ color: "rgba(0,0,0,.25)" }} type="search" />}
            onChange={(e) => onChange(e.target.value)}
            autoComplete="off"
          />
          <Select
            className='invoice-select action-select'
            placeholder={intl.formatMessage({
              id: 'hintSelect',
              defaultMessage: 'Select',
            })}
            onSelect={(value) => onSelect(value)}
            value={selectSearchData}
          >
            <Option value="all">
              All Columns
            </Option>
            {columnNewSearch.map((item, i) => (
              <Option key={i} value={item.key}>
                {item.title}
              </Option>
            ))}
          </Select>
        </List.Item>
      </Col>
      <Col span={12}>
        <Row>
          <List.Item className='invoice-list-right'>
            <ConfigProvider locale={locale}>
              <RangePicker
                className='invoice-rangePicker'
                onChange={onSelectRangePicker}
                defaultValue={
                  langValue === 'EN'
                    ? [moment(today, dateFormat), moment(today, dateFormat)]
                    : [moment(today, dateFormat), moment(today, dateFormat)]
                }
                format={dateFormat}
                allowClear={false}
                size="small"
              />
            </ConfigProvider>
            <Dropdown
              className="task-report-dropdown"
              overlay={menuColumn}
              trigger={['click']}
              onVisibleChange={handleVisibleChange}
              visible={visible}
              placement="bottomRight"
            >
              <Button01
                type="primary"
                btnsize="wd_at"
                fontSize="sm"
                onClick={handleOnVisiblecolumns}
              >
                <FormattedMessage id="reportTaskReportBtnColumns" defaultMessage="Columns" />{" "}
                <Icon type="down" />
              </Button01>
            </Dropdown>
          </List.Item>
        </Row>
      </Col>
    </Row>
  )
}
