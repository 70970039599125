import httpClient from '../../components/axiosClient';
const comCode = localStorage.getItem('comCode');
const comId = localStorage.getItem('comId');

const removeGasprice = async (id, body) => {
  try {
    const response = await httpClient.put(
      `/v1/travelexpense/manager/company/${comCode}/gas-price/remove?fuel_type_id=${id}
    `,
      body
    );
    if (response.status === 200) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false
  }
};

export default removeGasprice;
