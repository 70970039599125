import React, { forwardRef } from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';
import _ from 'lodash';
// import Select from '../../../../components/v2.1.0/select';
import styled from 'styled-components';
import Provider from '../../provider';

const { MonthPicker } = DatePicker;

const CustomFilter = styled.div`
  padding: 8px 24px 16px 24px;
`;

const months = moment.months();
const years = [2022, 2021, 2020, 2019, 2018, 2017];

export default forwardRef((props) => {
  const app = Provider.useAppContext();
  const { month, year, onChangeMonth, onChangeYear, monthRef, yearRef } = props;

  const dateTimeApi = 'YYYY-MM-DD HH:mm';
  // const shiftsDateNow = moment(today).format(dateTimeApi);
  
  const datepickerMonth = moment().month(month).format('MM');

  const optionsMonth = months.map((element, index) => {
    return {
      key: index,
      value: element
    };
  });

  const settingSelectMonth = {
    width: '160px',
    defaultValue: moment().month(month).format('MMMM'),
    onChange: _.isObject(onChangeMonth) ? onChangeMonth : null,
    options: optionsMonth
  };

  const optionsYear = years.map((element, index) => {
    return {
      key: index,
      value: element
    };
  }); 

  const settingSelectYear = {
    width: '160px',
    defaultValue: year,
    onChange: _.isObject(onChangeYear) ? onChangeYear : null,
    options: optionsYear
  };

  const handleChangeMonthPicker = (date, dateString) => {

    app.fnc.setMainShiftsDateTimePicker(moment(dateString).format(dateTimeApi));
    onChangeMonth(moment(date.format('MM'), 'MM').local('EN').format('MMMM'));
    onChangeYear(date.format('YYYY'));
    // app.fnc.setShiftsYear(date.format('YYYY'));
    // app.fnc.setShiftsMonth(date.format('MMMM'));
  }

  return (
    <CustomFilter>
      <MonthPicker 
        className='shift-month-picker'
        defaultValue={moment(`${year}-${datepickerMonth}`, 'YYYY-MM').locale(localStorage.getItem('langValue'))} 
        format={'MMMM YYYY'} 
        allowClear={false}
        onChange={handleChangeMonthPicker}
        // size={'large'} 
        style={{
          width: 170
        }} />
    </CustomFilter>
  );
});