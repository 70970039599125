import { Modal, Col, Row, Form, Select, Input, InputNumber, DatePicker, Icon, Divider, Spin, TreeSelect, Tabs } from 'antd';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import './css/index.css';
import { FormattedMessage, useIntl } from 'react-intl';
import _ from 'lodash';
import moment from 'moment';
import { AllticketContext } from '../allticket-context';
import { handleKeyDownDecimal2Fixed } from '../../../component-function/fnc-number';
import Button_01 from '../../../components/v2/button_01';
import Button_02 from '../../../components/v2/button_02';
import { successNotification } from '../../../components/v2/notification';
import { fncGetUsers } from '../../../controllers/user/users';
import TicketAssetModal from './asset-summary-report';
import { CompanyLocation } from '../../../components/modal-select-company-location/context';
import CreateCustomerLocation from '../../../components/create-customer-location';
import { useAppContext } from '../../../includes/indexProvider';
import TicketLocationModal from './location-modal';
import Resizer from 'react-image-file-resizer';
import { v4 as uuidv4 } from 'uuid';
import { errorNotification } from '../../transportation/gantt-view/notification';
import {
  Preview01,
  Preview02,
  Preview03,
  Preview04,
  Preview05,
  PreviewPlus,
  PreviewSingle,
} from '../../../components/note-monitor/modal-add-note/preview-picture';
import { RenderTag } from '../../../components/post-component/post-tag';
import { RenderTag as RenderTagEdit } from './post-tag';
import PostAddress from './post-address';
import { createTicket, saveImageTicketDescription, updateTicket } from '../../../controllers/ticket/ticket';
import { teamAsset } from '../../../controllers/asset-resource/asset-controller';
import ReferenceTab from '../../../components/reference';
import { savetListReferenceTab } from '../../../controllers/reference-tab';
const { Option, OptGroup } = Select;
const { TextArea } = Input;
const InputGroup = Input.Group;
const { Search } = Input;
const { TabPane } = Tabs;

const CreateModal = (props) => {
  const { form, distance } = props;
  const { state, setState, fnc } = useContext(AllticketContext);
  const { getFieldDecorator, validateFields, resetFields, getFieldValue, setFieldsValue } = form;
  const intl = useIntl();
  const imageRef = useRef();
  const fileRef = useRef();
  const memComId = localStorage.getItem('memComId');
  const { visibleModal, record, editRecord, dataFine, visibleCreate, menuList, ticketData, ticketId, datarecord } = state;
  const { setVisibleModal, setEvent, setVisibleCreate, setEditRecord, setTicketId } = setState;
  const { getRecord, handleVisibleCreate } = fnc;
  const paddinginputLeft = '16px 10px 0px 10px';
  const paddinginputRight = '16px 10px 0px 10px';
  const paddingTopic = '0px 10px 0px 10px';
  const dateFormat = 'ddd, DD MMM YYYY';
  const dataSource = _.get(dataFine, 'rows');
  const [employee, setEmployee] = useState([]);
  const app = useAppContext();
  const latlng = _.get(app, 'state.latlngCompany');
  const [switchTicket, setSwitchTicket] = useState('repair');
  const [switchContact, setSwitchContact] = useState('employee');
  const teamData = _.get(menuList, 'data.team');
  const topicData = _.get(ticketData, 'data.topicListNew');
  const ServiceData = _.get(ticketData, 'data.ticketServicesTypeList');
  const ticketType = _.get(ticketData, 'data.ticketTypeList');
  const contactType = _.get(ticketData, 'data.contactTypeList');
  const statusList = _.get(ticketData, 'data.statusList');

  const [serviceGroup, setServiceGroup] = useState([]);
  const [phoneForm, setPhoneForm] = useState('');
  const [switchEvent, setSwitchEvent] = useState(false);

  //-------------------Asset------------------------//

  const [visibleAsset, setVisibleAsset] = useState(false);
  const [selectAssest, setSelectAssest] = useState();

  //---------------------------------------------------//

  //-------------------Location------------------------//
  const [visibleLocation, setVisibleLocation] = useState(false);
  const [selectLocation, setSelectLocation] = useState();

  const [visibleInfoWindow, setVisibleInfoWindow] = useState(true);
  const [center, setCenter] = useState('');
  const [position, setPosition] = useState('');

  //---------------------------------------------------//

  //---------------------Post--------------------------//

  const [loading, setLoading] = useState(false);
  const [selectedImageBlob, setSelectedImageBlob] = useState([]);
  const [selectedFilesBlob, setSelectedFilesBlob] = useState([]);
  const [message, setMessage] = useState('');
  const [checked, setChecked] = useState('default');
  const [selectPostAddress, setSelectPostAddress] = useState();
  const [typeAddress, setTypeAddress] = useState('1');

  //----------------------------------------------------

  const [tabChange, setTabChange] = useState('1');
  const [refItem, setRefItem] = useState([]);

  //---------------------------------------------------//

  useEffect(() => {
    getEmployee();
  }, []);

  useEffect(() => {
    const result = _.chain(ServiceData)
      .groupBy((o) => {
        return o.parentCode;
      })
      .map((o) => {
        return {
          parentCode: _.get(o, '[0].parentCode'),
          parentName: _.get(o, '[0].parentName'),
          list: o,
        };
      })
      .value();
    setServiceGroup(result);
    setSwitchEvent(false);
    if (_.size(editRecord) > 0) {
      if (_.get(editRecord, 'ticketTypeDetail.assetId')) {
        setSelectAssest(_.get(editRecord, 'ticketTypeDetail'));
      }
      setSwitchTicket(_.get(editRecord, 'ticketTypeCode'));
      setSwitchContact(_.get(editRecord, 'contactTypeCode'));
      setPhoneForm(_.get(editRecord, 'contactPhone'));
      setMessage(_.get(editRecord, 'ticketDescriptionList.description'));
      if (_.get(editRecord, 'ticketDescriptionList.address')) {
        setSelectPostAddress({
          address: _.get(editRecord, 'ticketDescriptionList.address'),
          lat: _.get(editRecord, 'ticketDescriptionList.lat'),
          lng: _.get(editRecord, 'ticketDescriptionList.lng'),
        });
      }

      setSelectLocation({
        serviceLocationAddress: _.get(editRecord, 'serviceLocationAddress'),
        serviceLocationLat: _.get(editRecord, 'serviceLocationLat'),
        serviceLocationLng: _.get(editRecord, 'serviceLocationLng'),
        serviceLocationRefId: _.get(editRecord, 'serviceLocationRefId'),
      });

      const mapImage = _.get(editRecord, 'ticketDescriptionList.attachmentsPhoto').map((item) => {
        return {
          attachmentId: item.fileHash,
          description: item.descriptionPhoto,
          pathImage: item.fileUrl,
          file: item.fileUrl,
          fileType: 'url',
        };
      });
      const mapFile = _.get(editRecord, 'ticketDescriptionList.attachmentsFile').map((item) => {
        return {
          attachmentId: item.fileHash,
          description: item.descriptionPhoto,
          file: item.fileUrl,
          name: item.descriptionPhoto,
          fileType: 'url',
        };
      });
      setSelectedImageBlob(mapImage);
      setSelectedFilesBlob(mapFile);
    } else {
      setSwitchTicket('repair');
      setSwitchContact('employee');
    }
  }, [visibleCreate]);

  const getEmployee = async () => {
    const mockData = [];

    const comId = localStorage.getItem('comId');
    const users = await fncGetUsers(memComId, comId, true);
    for (let i = 0; i < users.length; i++) {
      const data = {
        key: users[i].mem_com_id,
        fullname: users[i].fullname,
        email: users[i].email,
        com_id: users[i].com_id,
        mem_id: users[i].mem_id,
        phone: users[i].phone,
      };
      mockData.push(data);
    }
    setEmployee(mockData);
  };

  const resetFiledsAll = () => {
    setVisibleCreate(false);
    setEditRecord([]);
    setSelectAssest([]);
    setSelectLocation([]);
    setSelectedFilesBlob([]);
    setSelectedImageBlob([]);
    setSelectPostAddress([]);
    setMessage([]);
    setChecked('default');
    resetFields();
    setRefItem([]);
  };

  const closeAsset = () => {
    setVisibleAsset(false);
  };

  const handleOK = (type, values, id) => {
    Modal.confirm({
      className: 'customer-modal-confirm',
      centered: true,
      icon: null,
      title:
        type === 'draft'
          ? intl.formatMessage({ id: 'ConfirmDraftTicket', defaultMessage: 'Are you sure to save draft ?' })
          : _.size(editRecord) > 0
          ? intl.formatMessage({ id: 'ConfirmSaveTicket', defaultMessage: 'Are you sure to save a ticket ?' })
          : intl.formatMessage({ id: 'ConfirmCreateTicket', defaultMessage: 'Are you sure to submit a ticket ?' }),
      okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
          background: '#6490cf',
          border: '1px solid #6490cf',
          borderRadius: '8px',
          hover: {
            background: '#ffffff',
            color: '#6490cf',
          },
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
          background: '#6490cf',
          border: '1px solid #6490cf',
          borderRadius: '8px',
        },
        type: 'danger',
      },
      async onOk() {
        let ticketdetatil;
        if (switchTicket === 'service') {
          const serviceTemp = _.filter(ServiceData, (item) => {
            return item.serviceCode === getFieldValue('ticketdetailService');
          });

          ticketdetatil = {
            parentCode: _.get(serviceTemp, '[0].parentCode'),
            parentName: _.get(serviceTemp, '[0].parentName'),
            serviceCode: _.get(serviceTemp, '[0].serviceCode'),
            serviceName: _.get(serviceTemp, '[0].serviceCode'),
            toComCode: _.get(serviceTemp, '[0].toComCode'),
            toOrgId: _.get(serviceTemp, '[0].toOrgId'),
          };
        } else {
          ticketdetatil = {
            assetId: _.get(selectAssest, 'assetId'),
            assetNo: _.get(selectAssest, 'assetNo'),
            assetView: _.get(selectAssest, 'assetView'),
            pathImage: _.get(selectAssest, 'pathImage'),
            serialNo: _.get(selectAssest, 'serialNo'),
          };
        }

        let mapPhoto = [];
        let mapFile = [];

        let newEditimage = {};

        if (_.size(editRecord) > 0) {
          newEditimage = await uploadEdit();
        } else {
          for (let index = 0; index < _.size(selectedImageBlob); index++) {
            let formData = new FormData();
            formData.append('file', selectedImageBlob[index].file);
            formData.append('fileType', 'photo');
            const fileResponse = await saveImageTicketDescription(formData);
            if (fileResponse.status === 200) {
              mapPhoto.push({
                fileHash: _.get(fileResponse, 'data.data.fileHash'),
                descriptionPhoto: selectedImageBlob[index].description,
                type: 'photo',
              });
            }
          }
          for (let index = 0; index < _.size(selectedFilesBlob); index++) {
            let formData = new FormData();
            formData.append('file', selectedFilesBlob[index].file);
            formData.append('fileType', 'file');
            const fileResponse = await saveImageTicketDescription(formData);
            mapFile.push({
              fileHash: _.get(fileResponse, 'data.data.fileHash'),
              descriptionPhoto: selectedFilesBlob[index].name,
              type: 'file',
            });
          }
        }

        const payload = {
          ticketTypeCode: switchTicket,
          ticketTypeDetail: ticketdetatil,
          orgId: _.get(values, 'team'),
          submitBy: _.get(values, 'submittedby'),
          submitDate: moment(_.get(values, 'subdate')).format('YYYY-MM-DD'),
          requiredDate: moment(_.get(values, 'requirdate')).format('YYYY-MM-DD'),
          contactTypeCode: switchContact,
          contactRefId: switchContact === 'employee' ? _.get(values, 'contact1') : undefined,
          contactName:
            switchContact === 'employee'
              ? _.get(
                  _.filter(employee, (item) => {
                    return item.key === _.get(values, 'contact1');
                  }),
                  '[0].fullname'
                )
              : _.get(values, 'contact2'),
          contactPhone: _.get(values, 'phone'),
          serviceLocationAddress: _.get(selectLocation, 'serviceLocationAddress') || _.get(selectLocation, '[0].address'),
          serviceLocationLat: _.get(selectLocation, 'serviceLocationLat') || _.get(selectLocation, '[0].lat'),
          serviceLocationLng: _.get(selectLocation, 'serviceLocationLng') || _.get(selectLocation, '[0].lng'),
          serviceLocationRefId: _.get(selectLocation, 'serviceLocationRefId') || _.get(selectLocation, '[0].addressBookId'),
          statusCode: type === 'draft' ? 'draft' : 'open',
          topicIdList: _.get(values, 'topic'),
          ticketDescriptionList: {
            attachmentsPhoto: _.size(editRecord) > 0 ? _.get(newEditimage, 'concatImage') : mapPhoto,
            attachmentsFile: _.size(editRecord) > 0 ? _.get(newEditimage, 'concatFile') : mapFile,
            address: _.get(selectPostAddress, 'address'),
            description: message,
            lat: _.get(selectPostAddress, 'lat'),
            lng: _.get(selectPostAddress, 'lng'),
          },
          ref1: _.get(values, 'ref1'),
          ref2: _.get(values, 'ref2'),
        };
        const response = _.size(editRecord) > 0 ? await updateTicket(payload, ticketId) : await createTicket(payload);
        // const response = await createTicket(payload);
        if (_.size(refItem) > 0) {
          const dataform = _.get(response, 'data.data');
          const body = {
            referenceType: 'ticket',
            referenceId: _.get(dataform, 'ticketId'),
            referenceNo: _.get(dataform, 'ticketNo'),
            businessPartnerName: _.get(dataform, 'contact.contactName'),
            businessPartnerId: _.get(dataform, 'contact.contactRefId'),
            createdBy: _.get(dataform, 'submittedBy'),
            // createdByName: _.get(dataform, 'createdBy') ? _.get(dataform, 'createdBy') : undefined,
            createdDate: _.get(dataform, 'submittedDate'),
            refToList: refItem,
          };
          await savetListReferenceTab(body);
        }

        successNotification(_.get(response, 'data.status.message'));
        // getRecord(_.get(record, 'fineId'));
        setEvent((current) => !current);
        resetFiledsAll();
      },
      onCancel() {},
    });
  };

  const handleSave = (type) => {
    validateFields(async (err, values) => {
      if (err) return;

      handleOK(type, values);
    });
  };

  const uploadEdit = async () => {
    let mapImageAll = [];
    let mapFileAll = [];
    const filterBlobImg = _.filter(selectedImageBlob, (item) => {
      return item.fileType === 'Blob';
    });
    const filterBlobFile = _.filter(selectedFilesBlob, (item) => {
      return item.fileType === 'Blob';
    });
    for (let index = 0; index < _.size(filterBlobImg); index++) {
      let formData = new FormData();
      formData.append('file', filterBlobImg[index].file);
      formData.append('fileType', 'photo');
      const fileResponse = await saveImageTicketDescription(formData);
      if (fileResponse.status === 200) {
        mapImageAll.push({
          fileHash: _.get(fileResponse, 'data.data.fileHash'),
          descriptionPhoto: filterBlobImg[index].description,
          type: 'photo',
        });
      }
    }

    for (let index = 0; index < _.size(filterBlobFile); index++) {
      let formData = new FormData();
      formData.append('file', filterBlobFile[index].file);
      formData.append('fileType', 'file');
      const fileResponse = await saveImageTicketDescription(formData);
      mapFileAll.push({
        fileHash: _.get(fileResponse, 'data.data.fileHash'),
        descriptionPhoto: filterBlobFile[index].name,
        type: 'file',
      });
    }
    const filterUrlImg = _.filter(selectedImageBlob, (item) => {
      return item.fileType === 'url';
    });
    const filterUrlFile = _.filter(selectedFilesBlob, (item) => {
      return item.fileType === 'url';
    });
    const mapImage = _.map(filterUrlImg, (item) => {
      return {
        fileHash: item.attachmentId,
        descriptionPhoto: item.descriptionPhoto || '',
        type: 'photo',
      };
    });
    const mapFile = _.map(filterUrlFile, (item) => {
      return {
        fileHash: item.attachmentId,
        descriptionPhoto: item.name,
        type: 'file',
      };
    });

    const concatImage = mapImage.concat(mapImageAll);
    const concatFile = mapFile.concat(mapFileAll);
    return {
      concatImage,
      concatFile,
    };
  };

  const LabeRequire = (props) => {
    const { text, req } = props;
    return (
      <span>
        {text}&nbsp;
        {req ? <span style={{ fontSize: '13px', color: '#ff0000' }}>*</span> : ''}
      </span>
    );
  };

  const showOpenFileDialog = (e) => {
    imageRef.current.value = null;
    if (loading !== true) {
      imageRef.current.click();
    }
  };

  const showOpenFileDialogFile = () => {
    fileRef.current.value = null;
    if (loading !== true) {
      fileRef.current.click();
    }
  };

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        720,
        720,
        'JPEG',
        70,
        0,
        (uri) => {
          resolve(uri);
        },
        'base64'
      );
    });

  const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    for (var i = 0; i < bstr.length; i++) {
      u8arr[i] = bstr.charCodeAt(i);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const handleChangePicture = async (e) => {
    let newArr = [...selectedImageBlob];
    const files = e.target.files;

    for (let i = 0; i < files.length; i++) {
      const file = await resizeFile(files[i]);
      const fileResize = dataURLtoFile(file, files[i].name);

      newArr.push({
        attachmentId: uuidv4(),
        description: '',
        pathImage: URL.createObjectURL(fileResize),
        file: fileResize,
        fileType: 'Blob',
      });
    }
    setSelectedImageBlob(newArr);

    Array.from(newArr).map(
      (file) => URL.revokeObjectURL(file) // avoid memory leak
    );
  };

  const handleChangeFile = (e) => {
    let newArr = [...selectedFilesBlob];
    const file = e.target.files;

    if (file) {
      let fileMin = _.filter(file, (el) => el.size <= 2097152);

      if (file.length !== fileMin.length) {
        let fileCount = file.length - fileMin.length;
        errorNotification(
          intl.formatMessage({
            id: 'errorImageAndFileMoreThan2MB',
            defaultMessage: 'Upload failed, please upload a file that is less than 2 MB.',
          })
        );
      }

      for (let i = 0; i < fileMin.length; i++) {
        if (fileMin[i].name.match(/\.(pdf|PDF)$/) && fileMin[i].size <= 2097152) {
          newArr.push({
            attachmentId: uuidv4(),
            description: '',
            file: fileMin[i],
            name: fileMin[i].name,
            fileType: 'Blob',
          });
          setSelectedFilesBlob(newArr);
        }
      }
    }
  };

  const onChangeMessage = async (text) => {
    setMessage(text);
  };

  const renderTagFile = (fileList, removeFile) => {
    return (
      <div className="post-tags-view-modal">
        <div className="post-tags-view">
          {fileList &&
            fileList.map((item) => {
              return _.size(editRecord) > 0 && _.size(selectedImageBlob) > 0 ? (
                _.get(item, 'file') ? (
                  <RenderTagEdit fileList={item} handleTags={handleTags} handleRemove={removeFile} type={'create'} />
                ) : (
                  <RenderTagEdit fileList={item} handleTags={handleTagsEdit} handleRemove={removeFile} type={'edit'} />
                )
              ) : (
                <RenderTagEdit fileList={item} handleTags={handleTags} handleRemove={removeFile} type={'create'} />
              );
            })}
        </div>
      </div>
    );
  };

  const handleTagsEdit = (data) => {
    window.open(data.fileUrl, '_blank');
  };

  const handleTags = (data) => {
    if (_.get(data, 'file')) {
      const fileTag = URL.createObjectURL(data.file);
      let isPDF = /pdf/g.test(data.name.toLowerCase());
      if (isPDF) return window.open(fileTag, '_blank');
    }
  };

  const removeFile = (val) => {
    let newArr = [...selectedFilesBlob];
    const remove = _.filter(newArr, (item) => {
      return item.attachmentId !== val;
    });
    setSelectedFilesBlob(remove);
  };

  const renderPreview = (imageList) => {
    let sum = imageList.length;
    switch (sum) {
      case 1:
        return (
          <>
            <Preview01 imageList={imageList} handlePreviewImage={handlePreviewImage} />
          </>
        );
      case 2:
        return <Preview02 imageList={imageList} handlePreviewImage={handlePreviewImage} />;
      case 3:
        return <Preview03 imageList={imageList} handlePreviewImage={handlePreviewImage} />;
      case 4:
        return <Preview04 imageList={imageList} handlePreviewImage={handlePreviewImage} />;
      case 5:
        return <Preview05 imageList={imageList} handlePreviewImage={handlePreviewImage} />;
      default:
        return <PreviewPlus imageList={imageList} handlePreviewImage={handlePreviewImage} />;
    }
  };

  const handleDeleteSingleImage = (data) => {
    let newArr = [...selectedImageBlob];
    if (data && data.attachmentId) {
      const remove = _.filter(newArr, (item) => {
        return item.attachmentId !== data.attachmentId;
      });
      setSelectedImageBlob(remove);
    }
  };

  const onChangeCommentImg = (e, item, index) => {
    let newArr = [...selectedImageBlob];
    if (e.target.value.length >= 0) {
      newArr[index].description = e.target.value;
    }

    setSelectedImageBlob(newArr);
  };

  const handleBack = (data) => {
    setChecked('default');
  };

  const handlePreviewImage = (data) => {
    setChecked('actionView');
  };

  const handleAddress = (data) => {
    if (data) {
      window.open(`https://www.google.com/maps/search/?api=1&query=${_.get(data, 'addressLat')}, ${_.get(data, 'addressLng')}`, '_blank');
    } else if (editRecord) {
      window.open(`https://www.google.com/maps/search/?api=1&query=${_.get(data, 'lat')}, ${_.get(data, 'lng')}`, '_blank');
    }
  };

  const handleServiceLocation = () => {
    setTypeAddress('Service');
    setVisibleLocation(true);
  };

  const handlePostLocation = () => {
    setTypeAddress('Post');
    setVisibleLocation(true);
  };

  const handleCloseAddress = () => {
    setSelectPostAddress([]);
  };

  const handleSelectEmployee = (value) => {
    let phone = _.filter(employee, (item) => {
      return item.key === value;
    });
    setPhoneForm(_.get(phone, '[0].phone'));
  };

  const handleSwitchContact = (value) => {
    setSwitchEvent(true);
    setSwitchContact(value);
    setPhoneForm('');
  };

  const TreeNode = ({ level, title }) => <span style={{ fontWeight: level === 1 ? 'bold' : 'normal' }}>{title}</span>;

  const renderTreeNodes = (data, level) =>
    _.map(data, (item) => (
      <TreeSelect.TreeNode title={<TreeNode level={level} title={item.title} />} value={item.value} key={item.value}>
        {item.children && renderTreeNodes(item.children, level + 1)}
      </TreeSelect.TreeNode>
    ));

  const handleTabChange = (tab) => {
    setTabChange(tab);
  };

  const onSelectAsset = (data) => {
    setFieldsValue({
      ticketdetailRepair:
        _.get(data, 'assetNo') +
        ', ' +
        _.get(data, 'assetView') +
        (_.size(_.get(data, 'serialNo')) > 0 ? ', ' + _.get(data, 'serialNo') : ''),
    });
    setSelectAssest(data)
    // _.get(selectAssest, 'assetNo') +
    //   ', ' +
    //   _.get(selectAssest, 'assetView') +
    //   (_.size(_.get(selectAssest, 'serialNo')) > 0 ? ', ' + _.get(selectAssest, 'serialNo') : '');
    // setTabChange(tab);
  };

  return (
    <Modal
      visible={visibleCreate}
      onCancel={() => resetFiledsAll()}
      centered={true}
      title={
        editRecord && editRecord.length !== 0 ? (
          <FormattedMessage id="EditTicket" defaultMessage="Edit Ticket" />
        ) : (
          <FormattedMessage id="CreateTicket" defaultMessage="Submit a Ticket" />
        )
      }
      zIndex={999}
      width={866}
      style={{ marginTop: '15px' }}
      bodyStyle={{ padding: 'unset' }}
      footer={[
        <Row>
          {/* <div className="modal-create-post-footer-icon"> */}
          <Col span={4} style={{ display: 'flex', alignItems: 'center', paddingTop: '8px' }}>
            <div style={{ paddingLeft: '10px' }}>
              <Icon type="camera" onClick={showOpenFileDialog} style={{ color: '#6490CF', marginRight: '10px', fontSize: '20px' }} />
              {/* </div>
            <div style={{ paddingRight: '18px' }}> */}
              <Icon
                type="environment"
                style={{ cursor: 'pointer', color: '#6490CF', marginRight: '10px', fontSize: '20px' }}
                onClick={handlePostLocation}
              />
              {/* </div>
            <div> */}
              <Icon type="file-text" onClick={showOpenFileDialogFile} style={{ color: '#6490CF', marginRight: '10px', fontSize: '20px' }} />
            </div>
          </Col>
          {/* </div> */}
          <Col span={20}>
            <Button_02 key="back" btnsize="wd_df" onClick={() => resetFiledsAll()}>
              <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
            </Button_02>
            {_.size(editRecord) > 0 ? null : (
              <Button_01 style={{ margin: '0px' }} key="draft" type="primary" btnsize="wd_df" onClick={() => handleSave('draft')}>
                <FormattedMessage id="btnDraft" defaultMessage="Draft" />
              </Button_01>
            )}
            <Button_01 key="submit" type="primary" btnsize="wd_df" onClick={() => handleSave('submit')}>
              <FormattedMessage id="btnSave" defaultMessage="Save" />
            </Button_01>
          </Col>
        </Row>,
        // <div className="modal-create-post-footer-group">

        // </div>,
      ]}
    >
      <Tabs animated={false} onChange={handleTabChange} activeKey={tabChange} className="viewTicketTab">
        <TabPane tab={intl.formatMessage({ id: `purchesModalTitleDetail`, defaultMessage: 'Detail' })} key="1">
          <div className="warehouse-tabs-details scroll-sm" style={{ padding: '5px 16px' }}>
            <Form form={form}>
              <Row>
                <Col span={16} style={{ padding: paddinginputLeft }}>
                  <Form.Item>
                    <span className="formText-formate">
                      <FormattedMessage id="AllTicketDetails" defaultMessage="Ticket Details" />
                      <LabeRequire req={true} />
                    </span>
                    <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                      <Select
                        className="allticketinput"
                        defaultValue={'repair'}
                        style={{ width: '23%' }}
                        onSelect={(e) => setSwitchTicket(e)}
                        value={switchTicket}
                      >
                        {ticketType &&
                          _.map(ticketType, (item, index) => {
                            return (
                              <Option key={_.get(item, 'code')} value={_.get(item, 'code')}>
                                {_.get(item, 'name')}
                              </Option>
                            );
                          })}
                      </Select>
                      {switchTicket === 'service'
                        ? getFieldDecorator('ticketdetailService', {
                            initialValue: _.size(editRecord) > 0 ? _.get(editRecord, 'ticketTypeDetail.serviceCode') : undefined,
                            rules: [
                              {
                                required: true,
                                message: intl.formatMessage({
                                  id: 'AllTicketFormDetail',
                                  defaultMessage: 'Please Select Asset or Service',
                                }),
                              },
                            ],
                          })(
                            <Select
                              className="allticketSelect"
                              style={{ width: '100%' }}
                              placeholder={intl.formatMessage({ id: 'AllTicketSelectDetail', defaultMessage: 'Select Asset or Service' })}
                              // onSelect={handleDistance}
                            >
                              {serviceGroup &&
                                _.map(serviceGroup, (item, index) => {
                                  return (
                                    <OptGroup key={_.get(item, 'parentCode')} label={_.get(item, 'parentName')}>
                                      {_.map(_.get(item, 'list'), (item, index) => {
                                        return (
                                          <Option key={_.get(item, 'serviceCode')} value={_.get(item, 'serviceCode')}>
                                            {_.get(item, 'serviceCode')}
                                          </Option>
                                        );
                                      })}
                                    </OptGroup>
                                  );
                                })}
                            </Select>
                          )
                        : getFieldDecorator('ticketdetailRepair', {
                            initialValue:
                              _.size(selectAssest) > 0
                                ? _.get(selectAssest, 'assetNo') +
                                  ', ' +
                                  _.get(selectAssest, 'assetView') +
                                  (_.size(_.get(selectAssest, 'serialNo')) > 0 ? ', ' + _.get(selectAssest, 'serialNo') : '')
                                : undefined,
                            rules: [
                              {
                                required: true,
                                message: intl.formatMessage({
                                  id: 'AllTicketFormDetail',
                                  defaultMessage: 'Please Select Asset or Service',
                                }),
                              },
                            ],
                          })(
                            <Search
                              className="allticketinput"
                              readOnly={true}
                              style={{ width: '100%', marginTop: '2px', cursor: 'pointer' }}
                              placeholder={intl.formatMessage({ id: 'AllTicketSelectDetail', defaultMessage: 'Select Asset or Service' })}
                              onSearch={() => setVisibleAsset(true)}
                              onClick={() => setVisibleAsset(true)}
                              enterButton
                              value={
                                _.size(selectAssest) > 0
                                  ? _.get(selectAssest, 'assetNo') +
                                    ', ' +
                                    _.get(selectAssest, 'assetView') +
                                    (_.size(_.get(selectAssest, 'serialNo')) > 0 ? ', ' + _.get(selectAssest, 'serialNo') : '')
                                  : undefined
                              }
                            />
                          )}
                    </div>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={8} style={{ padding: paddinginputLeft }}>
                  <Form.Item>
                    <span className="formText-formate">
                      <FormattedMessage id="AllTicketTeam" defaultMessage="Team" />
                      <LabeRequire req={true} />
                    </span>
                    {getFieldDecorator('team', {
                      initialValue: _.size(editRecord) > 0 ? _.get(editRecord, 'orgId') : undefined,
                      rules: [
                        {
                          required: true,
                          message: intl.formatMessage({
                            id: 'AllTicketFormTeam',
                            defaultMessage: 'Please Select Team',
                          }),
                        },
                      ],
                    })(
                      <Select
                        style={{ width: '100%' }}
                        placeholder={intl.formatMessage({ id: 'AllticketSelectTeam', defaultMessage: 'Select Team' })}
                        // onSelect={handleDistance}
                      >
                        {teamData &&
                          _.map(teamData, (item, index) => {
                            return (
                              <Option key={_.get(item, 'orgId')} value={_.get(item, 'orgId')}>
                                {_.get(item, 'teamName')}
                              </Option>
                            );
                          })}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col span={8} style={{ padding: paddinginputRight }}>
                  <Form.Item>
                    <span className="formText-formate">
                      <FormattedMessage id="AllticketSubmittedBy" defaultMessage="Submitted By" />
                      <LabeRequire req={true} />
                    </span>
                    {getFieldDecorator('submittedby', {
                      initialValue: _.size(editRecord) > 0 ? parseInt(_.get(editRecord, 'submitBy')) : parseInt(memComId),
                      // _.get(
                      //     _.filter(employee, (item) => {
                      //       return item.key.toString() === memComId;
                      //     }),
                      //     '[0].key'
                      //   ),
                      rules: [
                        {
                          required: true,
                          message: intl.formatMessage({
                            id: 'AllTicketSelectSubmittedBy',
                            defaultMessage: 'Please Select Submitted By',
                          }),
                        },
                      ],
                    })(
                      <Select
                        style={{ width: '100%' }}
                        placeholder={intl.formatMessage({ id: 'AllticketSelectSubmittedBy', defaultMessage: 'Select SubmittedBy' })}
                        // onSelect={handleDistance}
                      >
                        {employee &&
                          _.map(employee, (item, index) => {
                            return (
                              <Option key={_.get(item, 'key')} value={_.get(item, 'key')}>
                                {_.get(item, 'fullname')}
                              </Option>
                            );
                          })}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col span={8} style={{ padding: paddinginputRight }}>
                  <Form.Item>
                    <span className="formText-formate">
                      <FormattedMessage id="AllticketSubmittedDate" defaultMessage="Submitted Date" />
                      <LabeRequire req={true} />
                    </span>
                    {getFieldDecorator('subdate', {
                      initialValue: _.size(editRecord) > 0 ? moment(_.get(editRecord, 'submitDate'), 'YYYY-MM-DD') : moment(),
                      rules: [
                        {
                          required: true,
                          message: intl.formatMessage({
                            id: 'AllTicketSelectSubmitDate',
                            defaultMessage: 'Please Select Submitted Date',
                          }),
                        },
                      ],
                    })(
                      <DatePicker
                        format={dateFormat}
                        style={{ width: '100%' }}
                        placeholder={intl.formatMessage({ id: 'AllTicketSelectSubmittedDate', defaultMessage: 'Select Submitted Date' })}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              {/*--------------------------------------------------------------------------------------------*/}
              <Row>
                <Col span={8} style={{ padding: paddinginputLeft }}>
                  <Form.Item>
                    <span className="formText-formate">
                      <FormattedMessage id="AllTicketRequiredDate" defaultMessage="Required Date" />
                      <LabeRequire req={true} />
                    </span>
                    {getFieldDecorator('requirdate', {
                      initialValue: _.size(editRecord) > 0 ? moment(_.get(editRecord, 'requiredDate'), 'YYYY-MM-DD hh:mm:ss') : undefined,
                      rules: [
                        {
                          required: true,
                          message: intl.formatMessage({
                            id: 'AllTicketFormRequiredDate',
                            defaultMessage: 'Please Select Required Date',
                          }),
                        },
                      ],
                    })(
                      <DatePicker
                        format={dateFormat}
                        style={{ width: '100%' }}
                        placeholder={intl.formatMessage({ id: 'AllTicketSelectRequiredDate', defaultMessage: 'Select Required Date' })}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={8} style={{ padding: paddinginputRight }}>
                  <Form.Item>
                    <span className="formText-formate">
                      <FormattedMessage id="AllTicketContact" defaultMessage="Contact" />
                      <LabeRequire req={true} />
                    </span>
                    <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                      <Select
                        className="allticketinput"
                        defaultValue="employee"
                        value={switchContact}
                        style={{ width: '40%' }}
                        onSelect={handleSwitchContact}
                      >
                        {contactType &&
                          _.map(contactType, (item, index) => {
                            return (
                              <Option key={_.get(item, 'contactTypeCode')} value={_.get(item, 'contactTypeCode')}>
                                {_.get(item, 'contactTypeName')}
                              </Option>
                            );
                          })}
                      </Select>
                      {switchContact === 'employee'
                        ? getFieldDecorator('contact1', {
                            initialValue:
                              _.size(editRecord) > 0
                                ? switchEvent === true
                                  ? undefined
                                  : parseInt(_.get(editRecord, 'contactRefId'))
                                : undefined,
                            rules: [
                              {
                                required: true,
                                message: intl.formatMessage({
                                  id: 'AllTicketFormContact',
                                  defaultMessage: 'Please Select Contact',
                                }),
                              },
                            ],
                          })(
                            <Select
                              className="allticketSelect"
                              style={{ width: '60%' }}
                              placeholder={intl.formatMessage({ id: 'AllTicketSelectContact', defaultMessage: 'Select Employee or Other' })}
                              onSelect={handleSelectEmployee}
                            >
                              {employee &&
                                _.map(employee, (item, index) => {
                                  return (
                                    <Option key={_.get(item, 'key')} value={_.get(item, 'key')}>
                                      {_.get(item, 'fullname')}
                                    </Option>
                                  );
                                })}
                            </Select>
                          )
                        : getFieldDecorator('contact2', {
                            initialValue: _.size(editRecord) > 0 ? (switchEvent ? undefined : _.get(editRecord, 'contactName')) : undefined,
                            rules: [
                              {
                                required: true,
                                message: intl.formatMessage({
                                  id: 'AllTicketFormContact',
                                  defaultMessage: 'Please Select Contact',
                                }),
                              },
                            ],
                          })(
                            <Input
                              className="allticketSelect"
                              style={{
                                width: '100%',
                                borderBottomLeftRadius: '0px !important',
                                borderTopLeftRadius: '0px !important',
                              }}
                              placeholder={intl.formatMessage({ id: 'AllTicketSelectContact', defaultMessage: 'Select Employee or Other' })}
                            />
                          )}
                    </div>
                  </Form.Item>
                </Col>
                <Col span={8} style={{ padding: paddinginputRight }}>
                  <Form.Item>
                    <span className="formText-formate">
                      <FormattedMessage id="AllTicketPhone" defaultMessage="Phone" />
                      <LabeRequire req={true} />
                    </span>

                    {getFieldDecorator('phone', {
                      initialValue:
                        _.size(editRecord) > 0
                          ? phoneForm
                          : // ? switchEvent === true
                          //   ? undefined
                          //   : phoneForm
                          // : _.get(editRecord, 'contactPhone')
                          getFieldValue('contact1')
                          ? phoneForm
                          : undefined,
                      rules: [
                        {
                          required: true,
                          message: intl.formatMessage({
                            id: 'AllTicketFormPhone',
                            defaultMessage: 'Please Enter Phone',
                          }),
                        },
                      ],
                    })(
                      <Input
                        style={{ width: '100%' }}
                        placeholder={intl.formatMessage({ id: 'AllTicketEnterPhone', defaultMessage: 'Enter Phone' })}
                        onKeyDown={handleKeyDownDecimal2Fixed}
                        value={phoneForm}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col span={8} style={{ padding: paddinginputLeft }}>
                  <Form.Item>
                    <span className="formText-formate">
                      <FormattedMessage id="AllTicketMileage" defaultMessage="Mileage" />
                      <LabeRequire req={false} />
                    </span>
                    {getFieldDecorator('ref1', {
                      // initialValue: _.size(editRecord) > 0 ? moment(_.get(editRecord, 'requiredDate'), 'YYYY-MMM-DD hh:mm:ss') : undefined,
                    })(
                      <Input
                        style={{ width: '100%' }}
                        placeholder={intl.formatMessage({ id: 'AllTicketEnterMileage', defaultMessage: 'Enter Mileage' })}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={8} style={{ padding: paddinginputRight }}>
                  {' '}
                  <Form.Item>
                    <span className="formText-formate">
                      <FormattedMessage id="AllTicketInsurance" defaultMessage="Insurance Claim No." />
                      <LabeRequire req={false} />
                    </span>
                    {getFieldDecorator('ref2', {
                      // initialValue: _.size(editRecord) > 0 ? moment(_.get(editRecord, 'requiredDate'), 'YYYY-MMM-DD hh:mm:ss') : undefined,
                    })(
                      <Input
                        style={{ width: '100%' }}
                        placeholder={intl.formatMessage({ id: 'AllTicketEnterInsurance', defaultMessage: 'Enter Insurance Claim No.' })}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              {/*--------------------------------------------------------------------------------------------*/}
              <Row>
                <Col span={24} style={{ padding: paddinginputLeft }}>
                  <Form.Item>
                    <span className="formText-formate">
                      <FormattedMessage id="AllTicketLocation" defaultMessage="Service Location" />
                      <LabeRequire req={true} />
                    </span>
                    <Row>
                      <Col span={1}>
                        <Icon type="environment" style={{ fontSize: '12px', color: '#1D3557', marginRight: '0px' }} />
                      </Col>
                      <Col span={23} style={{ marginLeft: '-10px' }}>
                        {_.size(selectLocation) > 0 ? (
                          <div>
                            <div onClick={handleServiceLocation} style={{ cursor: 'pointer' }}>
                              <span className="task-detail-information-text-address-value">
                                {_.size(editRecord) > 0 && _.get(selectLocation, 'serviceLocationLat')
                                  ? _.get(editRecord, 'serviceLocationAddress')
                                  : `${_.get(selectLocation, '[0].name')} · ${_.get(selectLocation, '[0].address')}`}
                              </span>
                            </div>
                          </div>
                        ) : (
                          <div>
                            {getFieldDecorator('location', {
                              initialValue: _.get(editRecord, 'serviceLocationAddress'),
                              rules: [
                                {
                                  required: true,
                                  message: intl.formatMessage({
                                    id: 'AllticketFromLocation',
                                    defaultMessage: 'Please select a Service Location',
                                  }),
                                },
                              ],
                            })(
                              <Input
                                readOnly={true}
                                placeholder={intl.formatMessage({
                                  id: 'AllticketSelectLocation',
                                  defaultMessage: 'Select Service Location',
                                })}
                                // onClick={() => (getFieldValue('customer') ? CreateSetState.setVisibleAddCustomerAddress(true) : null)}
                                onClick={handleServiceLocation}
                                style={
                                  { border: 'unset', cursor: 'pointer', paddingLeft: '0px' }
                                  // getFieldValue('customer') ? { border: 'unset', cursor: 'pointer' } : { border: 'unset', cursor: 'not-allowed' }
                                }
                              />
                            )}
                          </div>
                        )}
                      </Col>
                    </Row>
                  </Form.Item>
                </Col>
              </Row>
              <Divider />
              <Row>
                <Col span={16} style={{ padding: paddingTopic }}>
                  <Form.Item>
                    <span className="formText-formate">
                      <FormattedMessage id="AllTicketTopic" defaultMessage="Topic" />
                      <LabeRequire req={true} />
                    </span>
                    {getFieldDecorator('topic', {
                      initialValue: _.get(editRecord, 'topicIdList'),
                      rules: [
                        {
                          required: true,
                          message: intl.formatMessage({
                            id: 'AllticketFromTopic',
                            defaultMessage: 'Please select Topic',
                          }),
                        },
                      ],
                    })(
                      <TreeSelect
                        // className="action-report-tree-select-team"
                        style={{ width: '100%' }}
                        dropdownStyle={{ maxHeight: '460px', overflow: 'auto', width: '300px' }}
                        placeholder={intl.formatMessage({ id: 'AllticketSelecttopic', defaultMessage: 'Select Topic' })}
                        allowClear
                        multiple
                        treeNodeFilterProp={'title'}
                        treeDefaultExpandAll
                        maxTagCount={3}
                        // onChange={fnc.handleChangeTeam}
                      >
                        {' '}
                        {renderTreeNodes(topicData, 1)}
                      </TreeSelect>
                      // <Select
                      //   style={{ width: '100%' }}
                      //   placeholder={intl.formatMessage({ id: 'AllticketSelecttopic', defaultMessage: 'Select Topic' })}
                      //   mode="multiple"
                      //   // onSelect={handleDistance}
                      // >
                      //   {topicData &&
                      //     _.map(topicData, (item, index) => {
                      //       return (
                      //         <OptGroup label='Rapidtech'>
                      //           <Option key={_.get(item, 'topicId')} value={_.get(item, 'topicId')}>
                      //             {_.get(item, 'name')}
                      //           </Option>
                      //         </OptGroup>
                      //       );
                      //     })}
                      // </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Form>

            <Spin spinning={loading}>
              <div className="engagement-modal-body" style={{ paddingLeft: '13px' }}>
                <input
                  ref={imageRef}
                  type="file"
                  multiple
                  style={{ display: 'none' }}
                  accept=".jpg, .png, .JPG, .PNG"
                  onChange={handleChangePicture}
                />
                <input ref={fileRef} type="file" multiple style={{ display: 'none' }} accept=".pdf" onChange={handleChangeFile} />
                <div className="engagement-modal-create-post" style={{ padding: '0px 0px' }}>
                  {/* -------------------------------------------- #hashtag lib เก็บไว้ก่อนๆ -----------------------------*/}
                  {/* <WriteHashTag setMessage={setMessage} visible={visibleAddPost} /> */}

                  <TextArea
                    placeholder={intl.formatMessage({
                      id: 'AllTicketTextArea',
                      defaultMessage: 'Type a message or hashtag for describe',
                    })}
                    className="text-area-post"
                    rows={1}
                    onChange={(e) => onChangeMessage(e.target.value)}
                    value={message}
                    autoSize={{ minRows: 1, maxRows: 4 }}
                  ></TextArea>

                  {_.size(selectPostAddress) > 0 ? (
                    <PostAddress
                      action="create"
                      postData={{
                        addressLat: _.get(selectPostAddress, 'lat'),
                        addressLng: _.get(selectPostAddress, 'lng'),
                        addressName: _.get(selectPostAddress, 'address'),
                        addressId: _.get(selectPostAddress, 'addressBookId'),
                      }}
                      handleAddress={handleAddress}
                      handleCloseAddress={handleCloseAddress}
                    />
                  ) : null}

                  {_.size(selectedFilesBlob) > 0 ? renderTagFile(selectedFilesBlob, removeFile) : null}
                </div>

                {selectedImageBlob && _.size(selectedImageBlob) > 0 ? (
                  checked === 'default' ? (
                    renderPreview(selectedImageBlob)
                  ) : (
                    <div>
                      <PreviewSingle
                        action="actionView"
                        imageList={selectedImageBlob}
                        handleDeleteSingleImage={handleDeleteSingleImage}
                        onChangeCommentImg={onChangeCommentImg}
                        handleBack={handleBack}
                        type="add"
                      />
                    </div>
                  )
                ) : null}
              </div>
            </Spin>
          </div>
        </TabPane>
        <TabPane tab={intl.formatMessage({ id: `purchesModalTitleReference`, defaultMessage: 'Reference' })} key="2">
          <ReferenceTab
            visiblemodal={visibleCreate}
            selectItem={refItem}
            setSelectItem={setRefItem}
            typeRef={'ticket'}
            dataform={datarecord}
          />
        </TabPane>
      </Tabs>
      <TicketAssetModal
        visibleAsset={visibleAsset}
        closeAsset={closeAsset}
        setSelectAssest={onSelectAsset}
        setVisibleAsset={setVisibleAsset}
      />

      <TicketLocationModal
        visibleLocation={visibleLocation}
        selectLocation={selectLocation}
        setSelectLocation={setSelectLocation}
        setVisibleLocation={setVisibleLocation}
        center={center}
        setCenter={setCenter}
        position={position}
        setPosition={setPosition}
        visibleInfoWindow={visibleInfoWindow}
        setVisibleInfoWindow={setVisibleInfoWindow}
        selectPostAddress={selectPostAddress}
        setSelectPostAddress={setSelectPostAddress}
        typeAddress={typeAddress}
      />
    </Modal>
  );
};

const CreateModalForm = Form.create({
  name: 'Allticket_create_form',
  mapPropsToFields() {},
})(CreateModal);

export default CreateModalForm;
