import React, { useState, useEffect } from 'react';
import { OrganizationContext } from './organization-context';
import { Row, Col, Icon, Card } from 'antd';
import OrganizationTree from './organizationTree-new';
import Tabs from './tabs-new';
import Helper from '../../modules/helper';
import Provider from './provider';
import Organization from './organization';
import GetLang from '../../includes/language';
import { language } from '../../language/language2.js';
import { useHistory } from 'react-router-dom';
import httpClient from '../../components/axiosClient';
import _ from 'lodash';
import moment from 'moment';
import { dd, useQuery } from '../../helpers/general-helper';
import { FormattedMessage } from 'react-intl';
import stylesOrg from './css/index.css';
import { getApprover } from '../../controllers/organization/getApprover';
import NoDataBackground from '../../components/no-data-page';

const helper = new Helper();
const today = new Date();
const dateTimeApi = 'YYYY-MM-DD HH:mm';

export default (props) => {
  const comId = localStorage.getItem('comId');
  const history = useHistory();
  const query = useQuery();
  const memComId = localStorage.getItem('memComId');
  const pageCode = localStorage.getItem('pageCode');

  const [users, setUsers] = useState([]);

  const [orgId, setOrgId] = useState();
  const [orgIdSelect, setOrgIdSelect] = useState();
  const [menuTree, setMenuTree] = useState([]);
  const [triggerApi, setTriggerApi] = useState(false);

  const [teamTypeData, setTeamTypeData] = useState();
  const [organizations, setOrganizations] = useState([]);
  const [organizationsDetail, setOrganizationsDetail] = useState({});
  const [organizationsContacts, setOrganizationsContacts] = useState([]);
  const [organizationsApprovers, setOrganizationsApprovers] = useState([]);
  const [organizationTree, setOrganizationTree] = useState([]);
  const [orgSelectTree, setOrgSelectTree] = useState('');

  const [gData, setGData] = useState([]);

  const [parentOrgId, setParentOrgId] = useState();
  const [parentId, setParentId] = useState();
  const [pageDefault, setPageDefault] = useState(false);
  const [addNewOrganization, setAddNewOrganization] = useState(true);
  const [keyDetail, setKeyDetail] = useState('');
  const [keyTabsMain, setKeyTabsMain] = useState();

  const [shiftsType, setShiftsType] = useState([]);
  const [triggerTabChange, setTriggerTabChange] = useState(false);
  const [valueDefault, setValueDefault] = useState();
  const [statusMenuTree, setStatusMenuTree] = useState(true);
  const [rowCol, setRowCol] = useState(18);
  const [approverData, setApproverData] = useState([]);
  const [memberData, setMemberData] = useState([]);
  const [checkApprover, setCheckApprover] = useState();
  const [checkMember, setCheckMember] = useState();
  const [triggerApprover, setTriggerApprover] = useState(false);
  const [triggerMember, setTriggerMember] = useState(false);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [page, setPage] = useState(1);
  const [teamName, setTeamName] = useState('')

  console.log('orgId', orgId)

  useEffect(() => {
    getOrganizationTreeManu();
  }, [orgId, triggerApi]);

  useEffect(() => {
    getUsers();
  }, []);

  // useEffect(() => {
  //   // getOrganization();
  //   // if (orgId && orgId !== parseInt(query.get('orgId'))) history.push('/main/resource/organization');
  // }, [orgId, triggerApi]);

  useEffect(() => {
    const getApproverData = async () => {
      const response = await getApprover();
      if (response !== false) {
        setApproverData(_.get(response, 'memberOrganizationV4'));
        setMemberData(_.get(response, 'memberOrganization'));
      }
      // console.log('getApproverData ::', response)
    };
    getApproverData();
  }, [triggerApprover, triggerMember]);

  // console.log('Check Approver', checkApprover)

  useEffect(() => {
    if (_.size(approverData) !== 0 && orgId) {
      const filer =
        approverData &&
        approverData.filter((item) => {
          return parseInt(item.orgId) === orgId;
        });
      if (_.size(filer) !== 0) {
        const filterApprover = _.get(filer[0], 'approverTypeCode').filter((item) => {
          return item.code === 'shift';
        });
        setCheckApprover(_.get(filterApprover, '[0]'));
      } else {
        setCheckApprover();
      }
    }
  }, [orgId, _.size(approverData)]);

  useEffect(() => {
    if (_.size(memberData) !== 0 && orgId) {
      const filter =
        memberData &&
        memberData.filter((item) => {
          return parseInt(item) === orgId;
        });
      if (_.size(filter) !== 0) {
        setCheckMember(_.get(filter, '[0]'));
        // console.log('approverData approverData::')
      }
      // console.log('filter ::',filter)
    }
  }, [orgId, _.size(memberData)]);

  const getOrganization = async () => {
    const data = {
      com_id: comId,
      org_id: orgId,
      search_team: {
        name: '',
      },
      members: {
        conditional: [],
        order: 'fullname ASC',
        limit: '100',
        index_page: '1',
      },
      services: {},
      shift: {
        date: `${'YYYY'}-${moment().month('MMMM').format('MM')}`,
      },
    };

    const response = await httpClient.post('/v2/organizationdetails', data);

    if (response.status === 200) {
      const data = _.isObject(response.data.treeTeams) ? response.data.treeTeams : [];
      const teamDetails = _.isObject(response.data.organization) ? response.data.organization.teamDetails[0] : [];
      const teamType = _.isObject(response.data.organization) ? response.data.organization.teamType : [];
      const detail = {
        ...teamDetails,
        teamType: [...teamType],
      };
      const contacts = _.isObject(response.data.organization) ? response.data.organization.teamContact : [];
      const approvers = _.isObject(response.data.organization) ? response.data.organization.teamApprover : [];

      setTeamTypeData(response.data.organization.teamDetails[0]);
      setOrganizations(data);
      setOrganizationsDetail(detail);
      setOrganizationsContacts(contacts);
      setOrganizationsApprovers(approvers);
    }
  };

  const getOrganizationTreeManu = async () => {
    try {
      const response = await httpClient.get(`/v1/resource/manager/company/${comId}/organizations`);
      if (response.status === 200) {
        const data = _.isObject(response.data) ? response.data : [];
        const startOrgId = _.minBy(data, (o) => {
          return o.parent === '' ? '' : parseInt(o.parent);
        });
        const dataSortBy = _.sortBy(data, 'name');
        await setMenuTree(creatingTree(dataSortBy, startOrgId.parent));
      }
    } catch (error) {
      return;
    }
  };

  const getUsers = async () => {
    try {
      const response = await httpClient.get(`/v2/users/${memComId}?comId=${comId}`);
      if (response.status === 200) {
        await setUsers(response.data);
      }
    } catch (error) {
      return;
    }
  };

  // console.log('orgIdSelect ::', orgIdSelect)

  useEffect(() => {
    if (orgIdSelect) {
      getShiftType();
    }
  }, [orgIdSelect]);

  const getShiftType = async () => {
    try {
      const response = await httpClient.get(`/resource-old/companies/${comId}/shift-types?orgId=${orgIdSelect}`);

      if (response.status === 200) {
        await setShiftsType(response.data);
      }
    } catch (error) {
      return;
    }
  };

  const getRefresh = () => {
    setTriggerApi((curren) => !curren);
  };

  const handleVisibleMenuTree = () => {
    setStatusMenuTree((current) => !current);
  };

  useEffect(() => {
    if (statusMenuTree === false) {
      setRowCol(24);
    } else {
      setRowCol(18);
    }
  }, [statusMenuTree]);

  return (
    <div className="organization">
      <OrganizationContext.Provider
        value={{
          stateMain: {
            comId,
            orgId,
            orgIdSelect,
            menuTree,
            users,
            teamTypeData,
            organizations,
            organizationsDetail,
            organizationsContacts,
            organizationsApprovers,
            parentId,
            pageDefault,
            addNewOrganization,
            parentOrgId,
            keyDetail,
            keyTabsMain,
            triggerTabChange,
            valueDefault,
            shiftsType,
            orgSelectTree,
            checkApprover,
            checkMember,
            expandedKeys,
            page,
            teamName
          },
          setStateMain: {
            setOrgId,
            setParentId,
            setPageDefault,
            setAddNewOrganization,
            setParentOrgId,
            setKeyDetail,
            setKeyTabsMain,
            setTriggerTabChange,
            setValueDefault,
            setTriggerApi,
            setOrgIdSelect,
            setOrgSelectTree,
            setTriggerApprover,
            setCheckApprover,
            setCheckMember,
            setTriggerMember,
            setExpandedKeys,
            setPage,
            setTeamName,
          },
          fncMain: {
            getRefresh,
          },
        }}
      >
        <div className="organization-container">
          <Row gutter={[16, 16]} type="flex">
            {statusMenuTree ? (
              <Col span={6} key="orgtree">
                <div className="organization-col-manu">
                  <OrganizationTree setOrgIdSelect={setOrgIdSelect} data={organizationTree} gData={gData} />
                </div>
              </Col>
            ) : null}
            <Col span={rowCol} key="tabs" className="organization-default-col-card">
              <div className="organization-col-org">
                {orgId || pageDefault == true ? (
                  <Tabs
                    newForm={addNewOrganization}
                    orgIdSelect={orgIdSelect}
                    handleVisibleMenuTree={handleVisibleMenuTree}
                    statusMenuTree={statusMenuTree}
                  />
                ) : (
                  <Card className="organization-default-card">
                    {/* <div className="organization-default-card-content-detail">
                      <div className="organization-default-content-detail-div-icon">
                        <Icon className="organization-default-card-content-icon" type="cluster" />
                      </div>
                      <div className="organization-default-content-detail-div-p">
                        <p className="organization-default-card-content-text">
                          <FormattedMessage id="UserManagementOrgTxtDefaultPage1" defaultMessage="Please select a team" /> <br />
                          <FormattedMessage id="UserManagementOrgTxtDefaultPage2" defaultMessage="Oreganization will appear here." />
                        </p>
                      </div>
                    </div> */}
                    <NoDataBackground
                      text1={{ id: 'nodataXOrganization1', text: 'Please select a Team' }}
                      text2={{ id: 'nodataXOrganization2', text: 'Organization will appear here.' }}
                    />
                  </Card>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </OrganizationContext.Provider>
    </div>
  );
};

const creatingTree = (items, orgId = '', link = 'parent') => {
  return items
    .filter((item) => parseInt(item[link]) === orgId || item[link] === orgId)
    .map((item) => ({
      ...item,
      key: item.org_id,
      title: item.name,
      value: item.org_id,
      children: creatingTree(items, item.org_id),
    }));
};
