import httpClient from '../../components/axiosClient';

const getAddressBook = async (customer) => {

  const body = {
    customerId:customer
  }
  const comCode = localStorage.getItem('comCode');

  try {
    const response = await httpClient.post(`/v3/business-partner/manager/company/${comCode}/customer-address-books/from-to-address`,body);


    if (response.status == 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
}

export default getAddressBook;