import React, { useContext } from 'react'
import { TaskDetailContext } from '../context'
import NavigatorSVG from '../../image/navigator.svg';
import ArrowSVG from '../../image/arrow_location.svg';
import _ from 'lodash'
import { Row, Col, Form, Icon } from 'antd'
import { useIntl, FormattedMessage } from 'react-intl'
import LabeRequireForm from '../../label-required-form'

const ModalTaskDetailAddress = () => {
  const { state } = useContext(TaskDetailContext)
  const { detailData } = state
  const intl = useIntl()
  const information = _.get(detailData, 'information')
  const ArrowIcon = () => <img src={ArrowSVG} style={{ width: '32px' }}></img>;
  const NavigatorIcon = () => <img src={NavigatorSVG} style={{ marginBottom: '10px', marginLeft: '3.4px' }}></img>;

  const NavigatorClick = async () => {
    if (_.get(information, 'address.fromLocationId') && _.get(information, 'address.toLocationId')) {
      window.open(`https://www.google.es/maps/dir/'${parseFloat(_.get(information, 'address.fromlat'))},${parseFloat(_.get(information, 'address.fromlng'))}'/'${parseFloat(_.get(information, 'address.lat'))},${parseFloat(_.get(information, 'address.lng'))}'`);
    } else if (_.get(information, 'address.fromLocationId')) {
      window.open(`https://www.google.es/maps/dir/'${parseFloat(_.get(information, 'address.fromlat'))},${parseFloat(_.get(information, 'address.fromlng'))}'`);
    } else if (_.get(information, 'address.toLocationId')) {
      window.open(`https://www.google.es/maps/dir/'${parseFloat(_.get(information, 'address.lat'))},${parseFloat(_.get(information, 'address.lng'))}'`);
    }

  };

  return (
    <Form style={{ padding: '24px' }} layout="vertical" className="taskDetailForm">
      <div style={{ paddingBottom: '5px' }}>
        <span className="taskDetailTextAddress">
          <FormattedMessage id="taskDetailInformationTextAddress" defaultMessage="Address" />
        </span>
        <Icon component={NavigatorIcon} className="taskDetailTextAddressIcon" onClick={NavigatorClick} />
      </div>
      <Row>
        <Col span={10}>
          <Form.Item
            label={
              <LabeRequireForm text={intl.formatMessage({ id: `taskDetailInformationTextFrom`, defaultMessage: 'From' })} req={false} />
            }
          >
            <Row>
              <Col span={2}>
                <Icon type="environment" style={{ fontSize: '12px', color: '#0c4da2', marginRight: '5px' }} />
              </Col>
              <Col span={22}>
                {
                  _.get(information, 'address.fromLocationName') ? (
                    <span className="taskDetailTextAddressValue">
                      {`${_.get(information, 'address.fromLocationName')} · ${_.get(information, 'address.fromLocation')}`}
                    </span>
                  ) : '-'
                }

              </Col>
            </Row>
          </Form.Item>
        </Col>
        <Col span={4}>
          <div>
            <Icon component={ArrowIcon} />
          </div>
          <div>
            <span style={{ fontSize: '12px', color: '#707070' }}>
              {_.get(information, 'disTance') ? ` ~ ${_.get(information, 'disTance')}` : ''}
            </span>
          </div>
        </Col>
        <Col span={10}>
          <Form.Item
            label={
              <LabeRequireForm
                text={intl.formatMessage({ id: `taskDetailInformationTextDestination`, defaultMessage: 'Destination' })}
                req={false}
              />
            }
          >
            <Row>
              <Col span={2}>
                <Icon type="environment" style={{ fontSize: '12px', color: '#1D3557', marginRight: '5px' }} />
              </Col>
              <Col span={22}>
                <span className="taskDetailTextAddressValue">{`${_.get(information, 'address.toLocationName')} · ${_.get(information, 'address.toLocation')}`}</span>
              </Col>
            </Row>
          </Form.Item>
        </Col>
      </Row>
    </Form >
  )
}

export default ModalTaskDetailAddress
