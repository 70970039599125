import React from 'react';
import { Row, Col, Avatar } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import { useAppContext } from '../../includes/indexProvider';
import * as moment from 'moment';
import _ from 'lodash';
import enUS from 'antd/es/locale/en_US';
import thTH from 'antd/es/locale/th_TH';
import 'moment/locale/th';
import styles from './css/index.css';

const dateFormat = 'ddd, MMM DD YYYY';

export default function MemberInformationDetial({ dataMemberInfo, dataAPI, LangCode }) {
  const intl = useIntl();
  const app = useAppContext();
  const langValueState = _.get(app, 'state.langValue');
  moment.locale(langValueState);
  const data = dataMemberInfo ? dataMemberInfo.infomation : '';
  const checkDate = dataAPI && dataAPI.startDate.includes(dataAPI.endDate);

  return (
    <div className="member-information-detial">
      <div className="member-detial-container">
        <Row>
          <Col span={6}>
            <div className="member-detial-avatar">
              <Avatar size={124} icon="user" src={data ? data.pathImage : null} />
            </div>
          </Col>
          <Col span={18}>
            <div className="member-detial">
              <p className="member-detial-text-fullname">{data ? data.fullname : "-"}</p>
              <div className="member-detial-text">
                <div className="detial-text-div-head">
                  <span className="detial-text-head">
                    <FormattedMessage id={`memberInformationModaldetialTextPhone${LangCode}`} defaultMessage="Phone" />:
                  </span>
                </div>
                <span className="detial-text">{data ? data.phone : "-"}</span>
              </div>
              <div className="member-detial-text">
                <div className="detial-text-div-head">
                  <span className="detial-text-head">
                    <FormattedMessage id={`memberInformationModaldetialTextTeam${LangCode}`} defaultMessage="Team" />:
                  </span>
                </div>
                <span className="detial-text">{data ? data.OrgName : "-"}</span>
              </div>
              <div className="member-detial-text">
                <div className="detial-text-div-head">
                  <span className="detial-text-head">
                    <FormattedMessage id={`memberInformationModaldetialTextDate${LangCode}`} defaultMessage="Date" />:
                  </span>
                </div>
                {checkDate === true ? (
                  <span className="detial-text">
                    {checkDate ? moment(_.get(dataAPI, 'defailtStartDate')).format(dateFormat) : "-"}
                  </span>
                ) : (
                  <div className="detial-date">
                    <span className="detial-text">
                      {moment(_.get(dataAPI, 'defailtStartDate')).format(dateFormat)}
                    </span>
                    &nbsp;-&nbsp;
                    <span className="detial-text">
                      {moment(_.get(dataAPI, 'defailtEndDate')).format(dateFormat)}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}
