import { Col, Input, Modal, Row } from 'antd';
import { Form, Button, Checkbox } from 'antd';
import React, { useState } from 'react';
import Button_01 from '../../../components/v2/button_01';
import { useIntl, FormattedMessage } from 'react-intl';
import { successNotification, errorNotification } from '../../../components/v2/notification';

import createGasprice from '../../../controllers/gasprice/create-gasprice-api';
import removeGasprice from '../../../controllers/gasprice/remove-gasprice-api';
import { handleKeyDownDecimal2Fixed } from '../../../component-function/fnc-number';

function ModalGasprice(props) {
  const intl = useIntl();
  const { title, showModal, onCloseModal, dataUpdate, form } = props;
  const { getFieldDecorator, validateFields, resetFields } = form;

  const { TextArea } = Input;

  const LabeRequire = (props) => {
    const { text, req } = props;
    return (
      <span>
        {text}&nbsp;
        {req ? <span style={{ fontSize: '12px', color: '#ff0000' }}>*</span> : ''}
      </span>
    );
  };

  const handleSubmit = () => {
    validateFields(async (err, values) => {
      if (err) return;

      console.log('values', values);

      const data = {
        fuel_name: values?.gasType,
        fuel_cost: values?.price,
        description: values?.description || "-"
      };

      if (createGasprice(data)) {
        resetFields();
        onCloseModal();
        successNotification(intl.formatMessage({ id: 'Savedatasuccess', defaultMessage: 'Save data success' }));

      } else {
        resetFields();
        onCloseModal();
        errorNotification(intl.formatMessage({ id: 'Failtosavedata', defaultMessage: 'Fail to save data' }));

      }
    });
  };

  const handleEdit = () => {
    validateFields(async (err, values) => {
      if (err) return;

      const data1 = {
        fuel_type_id: dataUpdate?.fuel_type_id,
        fuel_name: values?.gasType,
        fuel_cost: values?.price,
        description: values?.description || "-"
        
        // description: _.get(values,"description")
      };

      console.log('values =>', values);

      if (removeGasprice(dataUpdate?.fuel_type_id, data1)) {
        onCloseModal();
        resetFields();
        successNotification(intl.formatMessage({ id: 'Editdatasuccess', defaultMessage: 'Edit data success' }));
      } else {
        onCloseModal();
        resetFields();
        errorNotification(intl.formatMessage({ id: 'Failtoeditdata', defaultMessage: 'Fail to edit data' }));
      }
    });
  };

  const onCancel = () => {
    resetFields();
    onCloseModal();
  };

  const buttonModal = (tag) => {
    return (
      <>
        {tag === '0' ? (
          <Button_01 key="submit" type="pimary" onClick={handleSubmit}>
            <FormattedMessage id="btnSave" defaultMessage="Save" />
          </Button_01>
        ) : (
          <Button_01 key="submit" type="pimary" onClick={handleEdit}>
            <FormattedMessage id="btnSave" defaultMessage="Save" />
          </Button_01>
        )}
      </>
    );
  };

  return (
    <Modal
      title={
        title === '0' ? (
          <span style={{ fontWeight: 'bold', fontSize: '16px' }}>
            <FormattedMessage id="ModalCreaetGasPrice" defaultMessage="Creaet Gas Price" />
          </span>
        ) : (
          <span style={{ fontWeight: 'bold', fontSize: '16px' }}>
            <FormattedMessage id="ModalEditGasPrice" defaultMessage="Edit Gas Price" />
          </span>
        )
      }
      visible={showModal}
      centered="true"
      width={740}
      onOk={onCloseModal}
      onCancel={onCloseModal}
      footer={[
        <>
          <Button_01 onClick={onCancel} style={{ marginRight: '-3px' }}>
            <FormattedMessage id="userMgntUserModalBtnCancel" defaultMessage="Cancel" />
          </Button_01>
          {buttonModal(title)}
        </>,
      ]}
    >
      <Form>
        <Row>
          <Col span={12}>
            <Form.Item name="note">
              <span style={{ fontWeight: 'bold' }}>
                {' '}
                <LabeRequire text={intl.formatMessage({ id: 'GasTypeTableC2', defaultMessage: 'Gas Type' })} req={true} />
              </span>
              {getFieldDecorator('gasType', {
                initialValue: title !== '0' ? dataUpdate?.fuel_name : undefined,
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({
                      id: 'PleaseEnterGasType',
                      defaultMessage: 'Please Enter Gas Type',
                    }),
                  },
                ],
              })(
                <Input
                  style={{ height: '40px' }}
                  placeholder={intl.formatMessage({ id: 'EnterGasType', defaultMessage: 'Enter Gas Type' })}
              
                ></Input>
              )}
            </Form.Item>
          </Col>
          <Col span={11} style={{ marginLeft: '23px' }}>
            <Form.Item name="note">
              <span style={{ fontWeight: 'bold' }}>
                {' '}
                <LabeRequire text={intl.formatMessage({ id: 'GasTypeTableC3', defaultMessage: 'Price/L' })} req={true} />
              </span>
              {getFieldDecorator('price', {
                initialValue: title !== '0' ? dataUpdate?.fuel_cost : undefined,
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({
                      id: 'PleaseEnetrPrice/L',
                      defaultMessage: 'Please Enetr Price/L',
                    }),
                  },
                ],
              })(
                <Input
                  style={{ height: '40px' }}
                  placeholder={intl.formatMessage({ id: 'EnterGasPrice/L', defaultMessage: 'Enetr Price/L' })}
                  onKeyDown={handleKeyDownDecimal2Fixed}
                ></Input>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ marginTop: '13px' }}>
          <Form.Item name="note">
            <span style={{ fontWeight: 'bold' }}>
              {' '}
              <FormattedMessage id="GasTypeTableC4" defaultMessage="Description" />
            </span>
            {getFieldDecorator('description', {
              initialValue: title !== '0' ? dataUpdate?.description : undefined,
            })(<TextArea rows={3} style={{ marginTop: '10px' }}></TextArea>)}
          </Form.Item>
        </Row>
      </Form>
    </Modal>
  );
}

const ModalGaspriceForm = Form.create({
  name: 'ModalGaspriceForm',
  mapPropsToFields() {},
})(ModalGasprice);

export default ModalGaspriceForm;
