import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Icon,
  Row,
  Col,
  Modal,
  Form,
  DatePicker,
  Select,
} from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import { useAppContext } from '../../../includes/indexProvider';
import { ShiftContext } from './shift-context';
import { PageSettings } from '../../../config/page-settings';
import Provider from '../provider';
import Button01 from '../../../components/v2/button_01';
import Button02 from '../../../components/v2/button_02';
import moment from 'moment';
import _ from 'lodash';
import styled from 'styled-components';
import stylesShiftIndex from './css/index.css';

const { RangePicker } = DatePicker;
const { Option } = Select;

const ModalFormComponent = ({ formData, visible, onCancel, onCreate, statePlanning, rangePickerDisabled, form, rangePickerValue }) => {
  const { getFieldDecorator } = form;
  const intl = useIntl();
  const appGetlang = useAppContext();
  const langValueState = _.get(appGetlang, 'state.langValue');
  const today = new Date();
  const dateFormat = 'ddd, MMM DD YYYY';

  moment.locale(langValueState);

  let formDataCheck = formData === undefined ? '' : formData;
  let shiftTypeRule = statePlanning && statePlanning.shiftTypeRule ? statePlanning.shiftTypeRule : [];
  let thisYearMonth = statePlanning && statePlanning.thisYearMonth ? statePlanning.thisYearMonth : '';

  const [check, setCheck] = useState();

  // useEffect(() => {
  //   if (rangePickerDisabled === 'true') {
  //     setCheck(disabledDate())
  //   } else {

  //   }
  // }, [])

  const disabledDate = (current) => {
    return thisYearMonth !== current.format('YYYY-MM') ? true : false;
  }

  return (
    <Modal
      className="shift-modal-schedule"
      title={intl.formatMessage({
        id: 'orgShiftPlanningModalTitleAdd',
        defaultMessage: 'Add Shift',
      })}
      centered
      width={611}
      visible={visible}
      okText="Submit"
      onCancel={onCancel}
      onOk={onCreate}
      footer={[
        <Button02 style={{margin : '0px 0px 0px 10px'}} key="back" fontsize="sm" btnsize="wd_df" onClick={onCancel}>
          <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
        </Button02>,
        <Button01
          key="submit"
          fontsize="sm"
          btnsize="wd_df"
          type="primary"
          onClick={() => onCreate()}
        >
          <FormattedMessage id="btnSave" defaultMessage="Save" />
        </Button01>,
      ]}
    >
      <Form>
        <Row gutter={[16]}>
          <Col span={8}>
            <div className="shift-modal-avatar">
              <Avatar size={114} icon="user" src={formDataCheck.pathImage || ''} />
            </div>
          </Col>
          <Col span={16}>
            <div>
              <div className="shift-modal-div">
                <span className="shift-modal-text-fullname">{formDataCheck.name || ''}</span>
              </div>
              <div className="shift-modal-div">
                <Icon className="shift-modal-icon" type="mail" />
                <span className="shift-modal-text">{formDataCheck.email || ''}</span>
              </div>
              <div className="shift-modal-div">
                <Icon className="shift-modal-icon" type="phone" />
                <span className="shift-modal-text">{formDataCheck.phone || ''}</span>
              </div>
              <div className="shift-modal-form">
                <Form.Item>
                  {getFieldDecorator('range-picker', {
                    rules: [
                      {
                        type: 'array',
                        required: true,
                        message: intl.formatMessage({
                          id: 'orgShiftPlanningValidateTime',
                          defaultMessage: 'Please select time.',
                        }),
                      },
                    ],
                  })(
                    <div className="shift-modal-form-item">
                      <Icon className="shift-modal-icon" type="calendar" />
                      <RangePicker
                        className="shift-modal-form-rangePicker"
                        placeholder={[
                          intl.formatMessage({
                            id: 'orgShiftPlanningModalHintStartDate',
                            defaultMessage: 'Please input start date',
                          }),
                          intl.formatMessage({
                            id: 'orgShiftPlanningModalHintEndDate',
                            defaultMessage: 'Please input end date',
                          }),
                        ]}
                        value={[
                          rangePickerValue && rangePickerValue.length >= 0 ? moment(rangePickerValue[0]) : '',
                          rangePickerValue && rangePickerValue.length >= 0 ? moment(rangePickerValue[0]) : ''
                        ]}
                        format={dateFormat}
                        allowClear={false}
                        disabledDate={rangePickerDisabled === 'true' ? disabledDate : false}
                      />
                    </div>,
                  )}
                </Form.Item>
                <div className="shift-modal-form-item">
                  <Icon className="shift-modal-icon" type="menu" />
                  <Form.Item>
                  {getFieldDecorator('shift', {
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({
                          id: 'orgShiftPlanningModalHintSelectShift',
                          defaultMessage: 'Please select shift',
                        }),
                      },
                    ],
                  })(
                    <Select
                      className="shift-modal-form-select"
                      placeholder={intl.formatMessage({
                        id: 'orgShiftPlanningModalHintSelectShift',
                        defaultMessage: 'Please select shift',
                      })}
                    >
                      {shiftTypeRule && shiftTypeRule.map((item, index) => {
                        return <Option key={item.shiftId} value={item.shiftId}>{item.shiftName}</Option>
                      })}
                    </Select>
                  )}
                </Form.Item>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

const ModalForm = Form.create({
  name: 'modal_form',
  mapPropsToFields(props) {
    let rangePickerValue = props.rangePickerValue && props.rangePickerValue.length >= 0 ? moment(props.rangePickerValue[0]) : '';
    // const setDataRangePicker = [rangePickerValue, rangePickerValue];
    return {
      'range-picker': Form.createFormField({
        value: [rangePickerValue, rangePickerValue]
      })
    }
  },
  onFieldsChange(props, changedFields, allFields) {
   
  }
})(ModalFormComponent);

export default ModalForm;
