import React, { useState, useContext, useMemo, useEffect } from 'react';
import { Tabs, Card, Spin } from 'antd';
import { MonitorContext } from '../monitor-context';
import { useIntl, FormattedMessage } from 'react-intl';
import Summary from './summary';
import Members from './members';
import TaskPool from './task-pool';
import styled from 'styled-components';
import './css/index.css';
import httpClient from '../../../components/axiosClient';
import _ from 'lodash';

const { TabPane } = Tabs;

export default () => {
  const intl = useIntl();
  const { stateMain, setStateMain } = useContext(MonitorContext);
  const { LangCode, mainOrgIdArr } = stateMain;
  // const typeApi = LangCode && LangCode === `WorkForce` ? `menuType=workforce` : ``
  const comId = localStorage.getItem('comId');

  const [tabKey, setTabKey] = useState();
  const [dafailtTab, setDafailtTab] = useState('');
  const [totalTaskPool, setTotalTaskPool] = useState('0');
  const [taskPoolData, setTaskPoolData] = useState([]);
  const [triggerApi, setTriggerApi] = useState(false);

  useEffect(() => {
    controllerTaskPool();
    // const interval = setInterval(() => {
    //   controllerTaskPool();
    // }, 180000);
    // return () => clearInterval(interval);
  }, [
    triggerApi,
    tabKey,
    stateMain.mainOrgIdArrString,
    stateMain.valueModalVisibleStatus,
    stateMain.refreshDataGanttView,
    stateMain.refreshDataTaskPool,
  ]);

  useEffect(() => {
    if (stateMain.mainTaskId !== null || stateMain.mainTaskId !== undefined) {
      const dataSelect = _.filter(taskPoolData, (ele) => ele.Id === stateMain.mainTaskId);
      setStateMain.setSelectTaskData(_.get(dataSelect, '[0]'));
    }
  }, [stateMain.mainTaskId]);

  const controllerTaskPool = async () => {
    setStateMain.setSpinLoading(true);
    let newTaskPoolData = [];
    try {
      const response = await httpClient.get(
        `/v3/task/manager/taskpool?com_id=${comId}&org_id=${stateMain.mainOrgIdArrString}&menuType=workdesk`
      );

      if (response.status === 200) {
        setTotalTaskPool(response.data.total);
        response.data.data.map((item, index) => {
          newTaskPoolData.push({
            Index: index,
            Id: item.taskId ? item.taskId : '',
            TaskNo: item.task_no ? item.task_no : '',
            OrgName: item.orgName ? item.orgName : '',
            CustomerName: item.customerName ? item.customerName : '',
            Color: item.statusColor ? item.statusColor : '',
            TaskTypeName: item.taskTypeName ? item.taskTypeName : '',
            TaskName: item.taskName ? item.taskName : '',
            StartDateView: item.startdate ? item.startdate : '',
            EndDateView: item.enddate ? item.enddate : '',
            StartTimeView: item.startTime ? item.startTime : '',
            EndTimeView: item.endTime ? item.endTime : '',
            Planstart: item.planstart ? item.planstart : '',
            Planfinish: item.planfinish ? item.planfinish : '',
            StatusName: item.statusName ? item.statusName : '',
            Address: item.address ? item.address : '',
            FromAddress: item.from_address ? item.from_address : '',
            ToAddress: item.to_address ? item.to_address : '',
            Planstart1: item.planstart1 ? item.planstart1 : '',
            Planfinish1: item.planfinish1 ? item.planfinish1 : '',
          });
        });
        setTaskPoolData(newTaskPoolData);
        setStateMain.setSpinLoading(false);
      }
    } catch (error) {}
  };

  const callback = (key) => {
    setTabKey(key);
  };

  const disabledTabTaskPool = useMemo(() => {
    let disabled;
    if (stateMain.keyTabMonitor === 'ganttView' || stateMain.keyTabMonitor === 'calendarView') {
      disabled = false;
    } else {
      disabled = true;
      if (tabKey === '2') {
        setDafailtTab('1');
      }
    }
    return disabled;
  }, [stateMain.keyTabMonitor]);

  // const StylesTabs = styled(Tabs)`
  //   .ant-tabs-nav .ant-tabs-tab-active {
  //     color: ${disabledTabTaskPool === true ? "#1D3557" : "#1D3557"};
  //   }
  //   .ant-tabs-ink-bar {
  //     background-color: ${disabledTabTaskPool === true ? "unset" : "#1D3557"};
  //   }
  // `
  const TabTaskPool = (
    <div>
      <span>
        {intl.formatMessage({ id: `monitorTabTaskPoolTitle`, defaultMessage: 'Task Pool' })}
        {` (${totalTaskPool})`}
      </span>
    </div>
  );

  const TabMember = (
    <div>
      <span>
        <FormattedMessage id={`monitorTabMembeTitle`} defaultMessage="Member" />{' '}
        {_.size(stateMain.mainMapViewResourceData) !== 0 ? `(${_.size(stateMain.mainMapViewResourceData)})` : `(0)`}
      </span>
    </div>
  );

  const handleClickMember = (e) => {
    // console.log('-----handleClickMember-----');
    // console.log(e);
    // window.monitor.map.panTo({lat: _.toNumber(e.lat), lng: _.toNumber(e.lng)});
    // window.monitor.map.setZoom(21);
    // setTimeout(() => {
    //   document.getElementById(`resource-marker-${e.memComId}`).click();
    // }, (500));
    // const position = {lat: _.toNumber(e.lat), lng: _.toNumber(e.lng)};
    // setStateMain.setGoogleMapcenter(position);
    // setStateMain.setGoogleMapzoom(21);
    // document.getElementById(`monitor-map-view`).click()
    setStateMain.setSelectResourceData(e);
    setStateMain.setResourceId(e.memComId);
    // setStateMain.setPopoverInfoMemComId(e.memComId);
  };

  return (
    <Card className="card-manu-monitor">
      <Spin spinning={stateMain.spinLoading}>
        <Tabs className="card-tabs-manu-monitor" size="large" defaultActiveKey="1" onChange={callback} animated={false}>
          <TabPane
            className="tabPane-manu-monitor"
            tab={intl.formatMessage({ id: `monitorTabSummaryTitle`, defaultMessage: 'Summary' })}
            key="1"
          >
            <Summary stateMain={stateMain} LangCode={LangCode} />
          </TabPane>
          {disabledTabTaskPool === true && stateMain.keyTabMonitor !== 'ganttView' && stateMain.keyTabMonitor !== 'calendarView' ? (
            <TabPane className="tabPane-manu-monitor" tab={TabMember} key="member">
              <Members
                mainOrgIdArr={mainOrgIdArr}
                // dataSource={stateMain.mainMapViewResourceData}
                onClick={handleClickMember}
              />
            </TabPane>
          ) : (
            <TabPane className="tabPane-manu-monitor" tab={TabTaskPool} key="2" disabled={disabledTabTaskPool}>
              <TaskPool
                stateMain={stateMain}
                setStateMain={setStateMain}
                setTotalTaskPool={setTotalTaskPool}
                tabKey={tabKey}
                setTaskPoolData={setTaskPoolData}
                taskPoolData={taskPoolData}
                triggerApi={triggerApi}
                setTriggerApi={setTriggerApi}
                LangCode={LangCode}
              />
            </TabPane>
          )}
        </Tabs>
      </Spin>
    </Card>
  );
};
