import React from 'react';
import { Col, Form, Modal, Row, Select, Input } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';

import _ from 'lodash';
import LabeRequireForm from '../../label-required-form';
import Button_02 from '../../v2/button_02';
import Button_01 from '../../v2/button_01';
import { createModelData } from '../../../controllers/createBrandModel';
import { errorNotification, successNotification } from '../../v2/notification';

const { Option } = Select;
const { TextArea } = Input;

const CreateModelModal = ({ form, title, visible, onOk, onCancel, list,setEvent,recordModel }) => {
  const { getFieldDecorator, resetFields } = form;
  const intl = useIntl();

  const handleSave = () => {
    form.validateFields(async (err, values) => {
      console.log('values', values);
      if (err) {
        return;
      }

      const payload = {
        itemModelId: _.get(recordModel,'itemModelId') ? _.get(recordModel,'itemModelId')  :undefined,
        itemBrandId: _.get(values, 'itemBrandId'),
        itemModelName:  _.get(values, 'itemModelName'),
        itemModelNo: _.get(values, 'itemModelNo'),
        itemModelDescription: _.get(values, 'itemModelDescription'),
        active: 'true',
        
      };

      try {
        const response = await createModelData(payload);

        if (_.get(response,'status.code') === 200) {
          successNotification(response.status.message);
          handleCancel();
          setEvent((prev) => !prev);
        }
        else {
          errorNotification(_.get(response,'status.message'));
        }
      } catch (error) {
        errorNotification(error.response.data.data.message);
      }
    });
  };

  const handleCancel = () => {
    onCancel();
    resetFields();
  };

  const setReasonOption = (reason) => {
    return (
      <Option key={`${reason.itemBrandId}`} value={`${reason.itemBrandId}`}>
        {reason.itemBrandName}
      </Option>
    );
  };

  return (
    <Modal
      title={title}
      visible={visible}
      onOk={handleSave}
      onCancel={onCancel}
      width={767}
      centered={true}
      footer={[
        <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={() => handleCancel()}>
          <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
        </Button_02>,
        <Button_01 key="submit" type="primary" btnsize="wd_df" onClick={() => handleSave()}>
          <FormattedMessage id="btnSave" defaultMessage="Save" />
        </Button_01>,
      ]}
    >
      <Form name="status" colon={false}>
        <Row gutter={[24]}>
          <Col span={12}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'modalCreateGroupGroup', defaultMessage: 'Group' })}
                  req={true}
                />
              }
            >
              {getFieldDecorator('itemBrandId', {
                initialValue: _.get(recordModel, 'itemBrandId') ? _.get(recordModel, 'itemBrandId') : undefined,
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'quotationWarnReason', defaultMessage: 'please select Reason' }),
                  },
                ],
              })(
                <Select
                  className="select-list-customer-monitor"
                  placeholder={intl.formatMessage({ id: 'quotationHintReason', defaultMessage: 'Select Reason' })}
                  defaultActiveFirstOption={false}
                  allowClear={false}
                >
                  {list && list.map((list) => setReasonOption(list))}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24]}>
          <Col span={12}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'modalCreateGroupTypeCode', defaultMessage: 'Type Code' })}
                  req={true}
                />
              }
            >
              {getFieldDecorator('itemModelNo', {
                initialValue: _.get(recordModel, 'itemModelNo') ? _.get(recordModel, 'itemModelNo') : '',
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({
                      id: 'modalCreateGroupTypeCodeValidator',
                      defaultMessage: 'Please Enter Type Code',
                    }),
                  },
                ],
              })(
                <Input
                  placeholder={intl.formatMessage({
                    id: 'modalCreateGroupTypeCodePlaceHolder',
                    defaultMessage: 'Enter Type Code',
                  })}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'modalCreateGroupTypeName', defaultMessage: 'Type Name' })}
                  req={true}
                />
              }
            >
              {getFieldDecorator('itemModelName', {
                initialValue: _.get(recordModel, 'itemModelName') ? _.get(recordModel, 'itemModelName') : '',
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({
                      id: 'modalCreateGroupTypeNameValidator',
                      defaultMessage: 'Please Enter Type Name',
                    }),
                  },
                ],
              })(
                <Input
                  placeholder={intl.formatMessage({
                    id: 'modalCreateGroupTypeNamePlaceHolder',
                    defaultMessage: 'Enter Type Name',
                  })}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24]}>
          <Col span={24}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'modalCreateGroupDescription', defaultMessage: 'Description' })}
                  req={false}
                />
              }
            >
              {getFieldDecorator('itemModelDescription',{
                initialValue: _.get(recordModel, 'itemModelDescription') ? _.get(recordModel, 'itemModelDescription') : '',
              })(
                <TextArea
                  autoSize={{ minRows: 3, maxRows: 3 }}
                  maxLength={250}
                  placeholder={intl.formatMessage({
                    id: 'modalCreateGroupDescriptionPlaceHolder',
                    defaultMessage: 'Enter Description',
                  })}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

CreateModelModal.defaultProps = {
  title: 'Select Customer',
  // title: intl.formatMessage({ id: 'quotationLabelSelectCustomer', defaultMessage: 'Select Customer' }),
  visible: false,
  onOk: () => console.warn('onOk not function '),
  onCancel: () => console.warn('onCancel not function '),
};

const ModalCreateModelForm = Form.create({ name: 'reject_Form' })(CreateModelModal);

export default ModalCreateModelForm;
