import React, { useState, useEffect } from 'react'
import { Modal, Input } from 'antd'
import { useIntl, FormattedMessage } from 'react-intl'
import Button_02 from '../../../../../components/v2/button_02'
import Button_01 from '../../../../../components/v2/button_01'
import { handleKeyDownDecimal2Fixed } from '../../../../../component-function/fnc-number'
import _ from 'lodash'
import { getWeight } from '../../../../../controllers/orders-controller/api';

const DeliveryWeightPacel = (props) => {
  const { visible, setVisible, setWeight, weight } = props
  const intl = useIntl()
  const [weightDefault, setWeightDefault] = useState()
  const [weightLocal, setWeightLocal] = useState('0.00');

  useEffect(() => {
    if (visible) {
      setWeightDefault(weight)
    }
  }, [visible])

  useEffect(() => {
    if (visible) {
      const fetchData = async () => {
        try {
          const response = await getWeight();
          const newData = response.weighlive;
          if (newData && newData !== weightLocal && newData !== 0 && newData !== "") {
            setWeightLocal(_.toString(newData));
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      if (visible) fetchData();
      const interval = setInterval(fetchData, 5000);
      return () => clearInterval(interval);
    }
  }, [weightLocal, visible]);

  useEffect(() => {
    if (weightLocal) {
      setWeightDefault(weightLocal);
    }
  }, [weightLocal]);


  const handleSave = () => {
    // setWeight(weightDefault ? calWaight(weightDefault) : weightDefault)
    setWeightDefault(_.ceil(weightDefault))
    setWeight(_.ceil(weightDefault))
    setVisible(false)
  }

  const handleCancel = () => {
    setVisible(false)
    setWeightDefault()
  }

  const handleReset = () => {
    setWeightDefault('0.00')
    setWeightLocal('0.00')
  }

  const handleChange = (value) => {
    setWeightDefault(value)
  }

  // const calWaight = (value) => {
  //   const calParcel = _.get(data, 'calculationFormulaForOrder') || '';
  //   const result = calParcel.replace('${weight}', value ? parseFloat(value) : 0);
  //   const calResult = new Function('return ' + result)();
  //   return calResult;
  // };

  return (
    <Modal
      title={intl.formatMessage({ id: `deliveryWeightParcel`, defaultMessage: 'Edit Parcel Weight' })}
      centered={true}
      width={380}
      visible={visible}
      onCancel={handleCancel}
      footer={[
        <Button_02 key="back" btnsize="wd_df" onClick={() => handleCancel()}>
          <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
        </Button_02>,
        <Button_02 style={{ margin: 'unset' }} key="back" btnsize="wd_at" onClick={() => handleReset()} margin="unset">
          <FormattedMessage id="deliveryResetWeight" defaultMessage="Reset Weight" />
        </Button_02>,
        <Button_01 key="submit" type="primary" btnsize="wd_df" onClick={() => handleSave()} disabled={(parseFloat(weightDefault || 0) === 0 || weightDefault === '.') ? true : false}>
          <FormattedMessage id="btnSave" defaultMessage="Save" />
        </Button_01>,
      ]}
    >
      <div style={{ textAlign: 'center' }}>
        <div style={{ color: '#1D3557', fontSize: '12px', fontWeight: 600, marginBottom: '8px' }}>
          <FormattedMessage id="deliveryWeightParcelText" defaultMessage="Weight(kg)" />
        </div>
        <div>
          <Input
            className='deliveryInputNumber'
            onKeyDown={handleKeyDownDecimal2Fixed}
            value={weightDefault ?_.ceil(weightDefault) : 0}
            onChange={(e) => handleChange(e.target.value)}
          />
        </div>
      </div>
    </Modal>
  )
}

export default DeliveryWeightPacel
