import React, { useState, useRef } from 'react'
import { Table, Button, Popover } from 'antd'
import { FormattedMessage } from 'react-intl'
import { PageSettings } from '../../config/page-settings'

const UomTable = (props) => {
  const { columns, dataSource, handleEdit, handleDelete } = props
  const ref = useRef();
  const [visiblePopoverTable, setVisiblePopoverTable] = useState();
  const [recordPopover, setRecordPopover] = useState();
  const [offsetPopover, setOffsetPopover] = useState([0, 0]);

  const handleMouseLeave = () => {
    setVisiblePopoverTable(false);
    setRecordPopover();
    setOffsetPopover([0, 0]);
  };

  const fncActionTable = (record, offsetPopover, visiblePopoverTable, ref) => {
    return (
      <div>
        <Popover
          placement="right"
          trigger="hover"
          align={{ offset: offsetPopover }}
          visible={visiblePopoverTable}
          zIndex={10000}
          content={
            <div style={{ display: 'grid' }}>

              <PageSettings.Consumer>
                {({ checkPermissionAction }) => (
                  <>
                    <Button style={{ width: 100 }} type="link" ghost onClick={() => handleEdit(record)}>
                      <FormattedMessage id="btnEdit" defaultMessage="Edit" />
                    </Button>
                    <Button style={{ width: 100 }} type="link" ghost onClick={() => handleDelete(record)}>
                      <FormattedMessage id="btnDelete" defaultMessage="Delete" />
                    </Button>
                  </>

                )}
              </PageSettings.Consumer>
            </div>
          }
        >
          <div style={{ width: 0, height: 0 }} ref={ref}></div>
        </Popover>
      </div>
    );
  };

  return (
    <div onMouseLeave={handleMouseLeave}>
      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              if (!event.target.href) {
                const { x, y } = ref.current.getBoundingClientRect();
                setVisiblePopoverTable(true);
                setOffsetPopover([event.pageX - x, event.pageY - y]);
                setRecordPopover(record);
              }
            },
          };
        }}
      />
      {fncActionTable(recordPopover, offsetPopover, visiblePopoverTable, ref)}
    </div>
  )
}

export default UomTable
