import React, { useContext, useEffect, useState } from 'react'
import { Modal, Form, InputNumber, Input, Col, DatePicker, TimePicker, Row } from 'antd'
import Button01 from '../v2/button_01'
import Button02 from '../v2/button_02'
import { useIntl, FormattedMessage } from 'react-intl';
import style from './css/index.css'
import moment from 'moment';
import _ from 'lodash';
import enUS from 'antd/es/locale/en_US';
import thTH from 'antd/es/locale/th_TH';
import 'moment/locale/th';
import { useAppContext } from '../../includes/indexProvider';

const ModalCopyTask = ({ form, visible, onCancel, onCreate, taskDetailsData, LangCode }) => {
  const { TextArea } = Input;
  const intl = useIntl();
  const { getFieldDecorator, validateFields, getFieldValue } = form;
  const information = _.get(taskDetailsData, 'information')
  const planStart = _.get(information, 'startDate')
  const planFinish = _.get(information, 'dueDate')
  const startTime = _.get(information, 'durationStart')
  const dueTime = _.get(information, 'durationFinish')

  const [locale, setLocale] = useState(enUS);
  const app = useAppContext();

  const langValueState = _.get(app, 'state.langValue');
  moment.locale(langValueState);

  useEffect(() => {
    changeLocale();
  }, []);

  const format = langValueState == 'EN' ? 'h:mm A' : 'HH:mm';


  const changeLocale = () => {
    let changeLang = langValueState === 'EN' ? enUS : thTH;
    setLocale(changeLang);
    if (changeLang == 'EN') {
      moment.locale('en');
    } else if (changeLang == 'TH') {
      moment.locale('th-th');
    }
  };

  const momentStartDate = planStart && planStart !== 'Invalid date' ? moment(planStart, 'YYYY-MM-DD') : undefined
  const momentDueDate = planFinish && planFinish !== 'Invalid date' ? moment(planFinish, 'YYYY-MM-DD') : undefined
  const momentStartTime = startTime && startTime !== 'Invalid date' ? moment(startTime, 'h:mm A') : undefined
  const momentDueTime = dueTime && dueTime !== 'Invalid date' ? moment(dueTime, 'h:mm A') : undefined




  const LabeRequire = (props) => {
    const { text, req } = props;
    return (
      <span>
        {text}&nbsp;
        {req ? <span style={{ fontSize: '13px', color: '#ff0000' }}>*</span> : ''}
      </span>
    );
  };

  const validatorStartDate = (rule, value, callback) => {
    const dueDate = getFieldValue(`dueDate`);
    const startTime = getFieldValue(`startTime`);
    const duetime = getFieldValue(`dueTime`);

    const formateStartDate = moment(value, 'ddd, MMM DD, YYYY').format('YYYY-MM-DD');
    const formateDueDate = moment(dueDate, 'ddd, MMM DD, YYYY').format('YYYY-MM-DD');
    const formateStartTime = moment(startTime, 'HH:mm: A').format('HH:mm:SS');
    const formateDueTime = moment(duetime, 'HH:mm: A').format('HH:mm:SS');

    if (value !== undefined && dueDate !== undefined && startTime !== undefined && duetime !== undefined) {
      if (formateStartDate == formateDueDate) {
        if (formateStartTime == formateDueTime) {
          callback(<FormattedMessage id={`modalCopyTaskValidationTimeEqual`} defaultMessage="Start time equal due time" />)

        }
        else if (formateStartTime > formateDueTime) {
          callback(<FormattedMessage id={`modalCopyTaskValidationMoreThanTime`} defaultMessage="Start time more than due time" />)
        }
        else {
          callback();
        }
      }
      else if (formateStartDate > formateDueDate) {
        callback(<FormattedMessage id={`modalCopyTaskValidationMoreThanDate`} defaultMessage="Start date more than due date" />)

      }
      else {
        callback();
      }
    } else {
      callback();
    }

  }

  const validatorDueDate = (rule, value, callback) => {
    const startDate = getFieldValue(`startDate`);
    const startTime = getFieldValue(`startTime`);
    const duetime = getFieldValue(`dueTime`);

    const formateStartDate = moment(startDate, 'ddd, MMM DD, YYYY').format('YYYY-MM-DD');
    const formateDueDate = moment(value, 'ddd, MMM DD, YYYY').format('YYYY-MM-DD');
    const formateStartTime = moment(startTime, 'HH:mm: A').format('HH:mm:SS');
    const formateDueTime = moment(duetime, 'HH:mm: A').format('HH:mm:SS');

    if (value !== undefined && startDate !== undefined && startTime !== undefined && duetime !== undefined) {
      if (formateStartDate == formateDueDate) {
        if (formateStartTime == formateDueTime) {
          callback(<FormattedMessage id={`modalCopyTaskValidationTimeEqual`} defaultMessage="Start time equal due time" />)

        }
        else if (formateStartTime > formateDueTime) {
          callback(<FormattedMessage id={`modalCopyTaskValidationMoreThanTime`} defaultMessage="Start time more than due time" />)
        }
        else {
          callback();
        }
      }
      else if (formateStartDate > formateDueDate) {
        callback(<FormattedMessage id={`modalCopyTaskValidationMoreThanDate`} defaultMessage="Start date more than due date" />)

      }
      else {
        callback();
      }
    } else {
      callback();
    }
  }

  const validatorStartTime = (rule, value, callback) => {
    const startDate = getFieldValue(`startDate`);
    const dueDate = getFieldValue(`dueDate`);
    const duetime = getFieldValue(`dueTime`);

    const formateStartDate = moment(startDate, 'ddd, MMM DD, YYYY').format('YYYY-MM-DD');
    const formateDueDate = moment(dueDate, 'ddd, MMM DD, YYYY').format('YYYY-MM-DD');
    const formateStartTime = moment(value, 'HH:mm: A').format('HH:mm:SS');
    const formateDueTime = moment(duetime, 'HH:mm: A').format('HH:mm:SS');

    if (value !== undefined && dueDate !== undefined && startDate !== undefined && duetime !== undefined) {
      if (formateStartDate == formateDueDate) {
        if (formateStartTime == formateDueTime) {
          callback(<FormattedMessage id={`modalCopyTaskValidationTimeEqual`} defaultMessage="Start time equal due time" />)

        }
        else if (formateStartTime > formateDueTime) {
          callback(<FormattedMessage id={`modalCopyTaskValidationMoreThanTime`} defaultMessage="Start time more than due time" />)
        }
        else {
          callback();
        }
      }
      else if (formateStartDate > formateDueDate) {
        callback(<FormattedMessage id={`modalCopyTaskValidationMoreThanDate`} defaultMessage="Start date more than due date" />)

      }
      else {
        callback();
      }
    } else {
      callback();
    }
  };

  const validatorDueTime = (rule, value, callback) => {
    const startDate = getFieldValue(`startDate`);
    const dueDate = getFieldValue(`dueDate`);
    const startTime = getFieldValue(`startTime`);

    const formateStartDate = moment(startDate, 'ddd, MMM DD yyyy');
    const formateDueDate = moment(dueDate, 'ddd, MMM DD yyyy');
    const formateStartTime = moment(startTime, 'HH:mm: A').format('HH:mm:SS');
    const formateDueTime = moment(value, 'HH:mm: A').format('HH:mm:SS');

    if (value !== undefined && dueDate !== undefined && startTime !== undefined && startDate !== undefined) {
      if (formateStartDate == formateDueDate) {
        if (formateStartTime == formateDueTime) {
          callback(<FormattedMessage id={`modalCopyTaskValidationTimeEqual`} defaultMessage="Start time equal due time" />)

        }
        else if (formateStartTime > formateDueTime) {
          callback(<FormattedMessage id={`modalCopyTaskValidationMoreThanTime`} defaultMessage="Start time more than due time" />)
        }
        else {
          callback();
        }
      }
      else if (formateStartDate > formateDueDate) {
        callback(<FormattedMessage id={`modalCopyTaskValidationMoreThanDate`} defaultMessage="Start date more than due date" />)

      }
      else {
        callback();
      }
    } else {
      callback();
    }
  };

  const validatorCopyAmount = (rule, value, callback) => {

    if (value > 10) {
      callback(<FormattedMessage id={`modalCopyTaskMaximimTask`} defaultMessage="Maximum 10 Task" />);
    } else {
      callback();
    }
  };



  // const validatePhone = async (rule, value, callback) => {
  //   try {
  //     if (value) {
  //       throw new Error('Something wrong!');
  //     }
  //     callback();

  //   } catch (error) {
  //     callback(error)
  //   }
  // }





  return (
    <Modal
      title={intl.formatMessage({ id: `monitorTabDetailButtonCloneTask`, defaultMessage: 'Clone Task' })}
      centered={true}
      visible={visible}
      onCancel={onCancel}
      onOk={onCreate}
      // onOk={validatorStartDate}

      footer={[
        <Button02 style={{margin : '0px 0px 0px 10px'}} key="back" btnsize="wd_df" onClick={onCancel}>
          <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
        </Button02>,
        <Button01
          key="submit"
          type="primary"
          btnsize="wd_df"
          onClick={onCreate}
        >
          <FormattedMessage id="btnSave" defaultMessage="Save" />
        </Button01>,
      ]}
    >
      <Form>
        <Row>
          <Row gutter={[12, 12]}>
            <Col span={12} className='span-text-style'>
              <p className='tex-header-style'><FormattedMessage id={`modalCopyTaskCloneAmount`} defaultMessage="Clone Amount" /><LabeRequire req={true} /></p>
              <p className='text-maximum-style'><FormattedMessage id={`modalCopyTaskMaximimTask`} defaultMessage="Maximum 10 Task" /></p>
            </Col>
            <Col span={12} className='span-text-style'>
              <Form.Item>
                {getFieldDecorator('numberCloneTask', {
                  initialValue: 1,
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: `modalCopyTaskValidateCloneAmount`, defaultMessage: 'Please enter number clone task' })
                    },
                    {
                      validator: validatorCopyAmount
                    }
                  ],
                })(
                  <InputNumber
                    min={1}
                    type="number"
                    size='default'
                    className='input-number-style '
                  />
                  // <Input
                  //   className='input-number-style'
                  //   type="number"
                  //   max={10}
                  //   min={1}
                  // />
                )}
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[12, 12]}>
            <Col span={12} className='span-text-style'>
              <span><FormattedMessage id={`modalCopyTaskStartDate`} defaultMessage="Start Date" /></span>
              <Form.Item>
                {getFieldDecorator('startDate', {
                  initialValue: momentStartDate,
                  rules: [
                    {
                      validator: validatorStartDate
                    }
                  ],
                })(<DatePicker className='date-picker-style' format='ddd, MMM DD YYYY' allowClear={false} />)}
              </Form.Item>
            </Col>
            <Col span={12} className='span-text-style'>
              <span><FormattedMessage id={`modalCopyTaskStartTime`} defaultMessage="Start Time" /></span>
              <Form.Item>
                {getFieldDecorator('startTime', {
                  initialValue: momentStartTime,
                  rules: [
                    // {
                    //   required: true,
                    //   message: 'Please enter start time',
                    // },
                    {
                      validator: validatorStartTime
                    }
                  ],
                })(<TimePicker use12Hours format={format} className='time-picker-style' allowClear={false} />)}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[12, 12]}>
            <Col span={12} className='span-text-style'>
              <span><FormattedMessage id={`modalCopyTaskDueDate`} defaultMessage="Due Date" /></span>
              <Form.Item>
                {getFieldDecorator('dueDate', {
                  initialValue: momentDueDate,
                  rules: [
                    // {
                    //   required: true,
                    //   message: 'Please enter due date',
                    // },
                    {
                      validator: validatorDueDate
                    }
                  ],
                })(<DatePicker className='date-picker-style' format='ddd, MMM DD YYYY' allowClear={false} />)}
              </Form.Item>
            </Col>
            <Col span={12} className='span-text-style'>
              <span><FormattedMessage id={`modalCopyTaskDueTime`} defaultMessage="Due Time" /></span>
              <Form.Item>
                {getFieldDecorator('dueTime', {
                  initialValue: momentDueTime,
                  rules: [
                    // {
                    //   required: true,
                    //   message: 'Please enter due time',
                    // },
                    {
                      validator: validatorDueTime
                    }
                  ],
                })(<TimePicker use12Hours format={format} className='time-picker-style' allowClear={false} />)}
              </Form.Item>
            </Col>
          </Row>

          <Col span={24} className='span-text-style'>
            <span><FormattedMessage id={`modalCopyTaskComment`} defaultMessage="Comment" /><LabeRequire req={true} /></span>
          </Col>
          <Col span={24} className='span-text-style'>
            <Form.Item>
              {getFieldDecorator('comment', {
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({ id: `modalCopyTaskValidateComment`, defaultMessage: 'Please enter comment' })

                  },
                ],
              })(<TextArea allowClear={false} rows={3} autoSize={{ minRows: 3, maxRows: 3 }} />)}
            </Form.Item>
          </Col>

        </Row>

      </Form>

    </Modal>
  )

}



const ModalCopy = Form.create({
  name: 'modal_form',
})(ModalCopyTask);

export default React.memo(ModalCopy);