import _ from 'lodash';
import HTTPClient from '../../components/axiosClient';

const comCode = localStorage.getItem('comCode');

const getFromLocation = async (payload = {}) => {
  try {
    const result = await HTTPClient.post(`/v3/business-partner/manager/company/${comCode}/company-distance/to/customer-address/initial-data`, payload);
    return _.get(result, 'data.data');
  }
  catch(e) {
    return {};
  }
}

const getCustomerAddressBook = async (payload) => {
  try {
    const result = await HTTPClient.post(`/v3/business-partner/manager/company/${comCode}/company-distance/to/customer-address`, payload);
    return _.get(result, 'data.data');
  }
  catch(e) {
    return {};
  }
}

const setCustomerAddressBook = async (payload) => {
  try {
    const result = await HTTPClient.post(`/v3/business-partner/manager/company/${comCode}/save/company-distance/to/customer-address`, payload);
    return _.get(result, 'data');
  }
  catch(e) {
    return {};
  }
}


export {
  getFromLocation,
  getCustomerAddressBook,
  setCustomerAddressBook
};