import React, { useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Card, Modal, List, Select, Upload, Row, Col, Typography, notification, Icon, Collapse } from 'antd';
import Button01 from '../../../../components/v2/button_01';
import Button03 from '../../../../components/v2/button_03';
import httpClients from '../../../../components/axiosClient';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import styled from 'styled-components';
import pathUrt from '../../../../function/pathUrl';
import _ from 'lodash';
import { useAppContext } from '../../../../includes/indexProvider';
import { PageSettings } from '../../../../config/page-settings';
import style from './css/index.css'
import Resizer from 'react-image-file-resizer';

import logoPDF from '../../../../pages/login/image/pdf.png';
import { error } from 'jquery';

const checkPathUrl = pathUrt('/user-management/my-profile');
const { Option } = Select;
const { confirm } = Modal;
const { Panel } = Collapse;

const { Title } = Typography;

const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export default (props) => {
  const intl = useIntl();
  const app = useAppContext();
  const buttonUploadDoc = app.state.show.buttonUploadDoc;
  const checkPathUrl = pathUrt('/user-management/my-profile');

  const { memComId, documentCompany } = props;
  const comId = localStorage.getItem('comId');

  const [stateModal, setStateModal] = useState(false);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [title, setTitle] = useState('');
  const [modalView, setModalView] = useState();

  const [startIndexGallery, setStartIndexGallery] = useState(0);

  const [showButton, setShowButton] = useState(false);

  // console.log('users documentCompany::: ', documentCompany);

  const handlePreview = async (item, title, file) => {
  
    let isPDF = /pdf/g.test(file.type)
    if (isPDF) return window.open(file.originalPath, "_blank");
    item = item.reduce((prev, current) => {
      if (!/pdf/g.test(current.type)) {
        prev.push(current)
      }
      return prev
    }, [])
    item = item.filter(current => !/pdf/g.test(current.type))

    const found = item.findIndex((element) => element.uid === file.uid);

    setTitle(title);
    setModalView(item);
    setStartIndexGallery(found);
    setStateModal(!stateModal);
  };

  const handleCancel = () => {
    setStateModal(false);
  };

  const handleCancelModalUpload = () => {
    setPreviewVisible(false);
  }

  const HandlerModal = (item) => {
    return (
      <div>
        <StyleModal
          visible={stateModal}
          onCancel={handleCancel}
          footer={null}
          width={'100%'}
        >
          <ImageGallery
            className={style.imagegallery}
            items={modalView}
            showPlayButton={false}
            showIndex={true}
            startIndex={startIndexGallery}
            showFullscreenButton={false}
          />
        </StyleModal>
      </div>
    );
  };

  const action = async (file, docComId, index) => {
    if (file.type === "image/jpeg" || file.type === "image/png") {
      const image = await resizeFile(file);
      const fileChange = dataURLtoFile(image, file.name.toLowerCase());
      let formData = new FormData();
      formData.append('file', fileChange);
      formData.append('memComId', memComId);
      formData.append('docComId', docComId);

      await httpClients.post(`/upload/members-documents`, formData)
        .then((res) => {
          if (res.status === 200) {
            openNotificationSuccess(res.data);
          } else {
            openNotificationError(res.data);
          }
        }).catch((error) => {
          openNotificationWarning(error.response.data.message || error.response.data);
        });
    } else if (file.type === "application/pdf") {
      let formDataPDF = new FormData();
      formDataPDF.append("file", file);
      formDataPDF.append("memComId", memComId);
      formDataPDF.append("docComId", docComId);

      await httpClients.post(`/upload/members-documents`, formDataPDF)
        .then((resDoc) => {
          if (resDoc.status === 200) {
            openNotificationSuccess(resDoc.data);
          } else {
            openNotificationError(resDoc.data);
          }
        }).catch((error) => {
          openNotificationWarning(error.response.data.message || error.response.data);
        });
    } else {
      openNotificationWarningNoFile('warning');
    }
  }

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        720,
        720,
        'JPG',
        70,
        0,
        (uri) => {
          resolve(uri);
        },
        'base64',
      );
    }
  );

  const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    for (var i = 0; i < bstr.length; i++) {
      u8arr[i] = bstr.charCodeAt(i);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const handleRemove = (file, index) => {
    let documentId = file.document_id;
    confirm({
      className: "document-modal-confirm",
      title: intl.formatMessage({ id: 'lblDelete', defaultMessage: 'Are you sure you want to delete ?' }),
      okText: intl.formatMessage({ id: 'modalConfirmBtnYes', defaultMessage: 'Yes' }),
      cancelText: intl.formatMessage({ id: 'modalConfirmBtnNo', defaultMessage: 'No' }),
      okType: 'danger',
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: "primary",
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: "dashed",
      },
      onOk: async () => {
        try {
          const resDeleteDoc = await httpClients.delete(`/members-documents/${documentId}`);
          if (resDeleteDoc.status === 200) {
            props.onChangeFile2();
            openNotificationSuccess(resDeleteDoc.data);
          } else {
            openNotificationError(resDeleteDoc.data);
          }
        } catch {
          console.error();
        }
      },
      onCancel() { },
    })
  }

  const openNotificationSuccess = (message) => {
    notification.success({
      message: message,
    });
  }

  const openNotificationError = (message) => {
    notification.error({
      message: message,
    });
  }

  const openNotificationWarning = (message) => {
    notification.warning({
      message: message,
    });
  }

  const openNotificationWarningNoFile = (type) => {
    notification[type]({
      message: intl.formatMessage({ id: 'userMgntUsersDetailTxtMustFileDocument', defaultMessage: 'Must be file .png, .jpg and .pdf only' }),
    });
  }
  const handleButton = (e) => {
    e.preventDefault()
    setShowButton(!showButton);
  }

  const uploadButton = (
    <div>
      <Icon type="plus" />
      <div className="ant-upload-text">
         <FormattedMessage id="userMgntUsersDetailBtnDocUpload" defaultMessage="Upload" />
      </div>
    </div>
  );

  const showButtonx = (
    <div>
      <Button01
        key="submit"
        type="primary"
        btnsize="wd_df"
        value={showButton}
        onClick={(e) => handleButton(e)}                         
      >
        {showButton === false ? '' : null}
        <span className="span-assign">
          {showButton === false ?
            <FormattedMessage id="userMgntUsersDetailBtnDocUpload" defaultMessage="Upload" />
            : <FormattedMessage id="userMgntUsersDetailBtnDocComplete" defaultMessage="Complete" />
          }
        </span>
      </Button01>
    </div>
  );

  return (
    <PageSettings.Consumer>
      {({ checkPermissionAction }) => (
        <Card
          className='document-card'
          title={
            <FormattedMessage id="userMgntUsersDetailTitleDocument" defaultMessage="Document" />
          }
          extra={
            <div>
              {checkPermissionAction('P2S1PG2C7', 'P2S1PG2C7A2') ?
                buttonUploadDoc === false ? null : showButtonx : null
              }
            </div>
          }
        >
          <Collapse 
            className='document-collapse'
            bordered={false} 
            defaultActiveKey={['1']}
            expandIconPosition='right'
          >
            {documentCompany && documentCompany.length > 0 ? documentCompany.map((item, index) => (
              <Panel 
                className='document-collapse-panel'
                header={item.name} 
                key={item.docComId}
              >
                <Upload
                  className={style.upload}
                  onChange={() => {
                    props.onChangeFile(index);
                  }}
                  action={(file) => action(file, item.docComId, index)}
                  listType="picture-card"
                  fileList={item.files}
                  onPreview={(file) => handlePreview(item.files || [], item.name, file)}
                  onRemove={(file) => handleRemove(file, index)}
                  accept=".pdf, .jpg, .png"
                  showUploadList={{
                    showPreviewIcon: checkPermissionAction('P2S1PG2C7', 'P2S1PG2C7A4') ? true : false,
                    showRemoveIcon: checkPermissionAction('P2S1PG2C7', 'P2S1PG2C7A3') ? true : false,
                  }}
                >
                  {showButton === false ? null : uploadButton}
                </Upload>
              </Panel>
            )) : null}
          </Collapse>

          {/* <List
            dataSource={documentCompany}
            renderItem={(item, index) => (
              <List.Item >
                <Row className="content-document">
                  <Col span={24}>
                    <List.Item.Meta description={item.name} />
                  </Col>
                  <Col span={24} style={{ paddingTop: '5px' }}>
                    <Upload
                      className={style.upload}
                      onChange={() => {
                        props.onChangeFile(index);
                      }}
                      action={(file) => action(file, item.docComId, index)}
                      listType="picture-card"
                      fileList={item.files}
                      onPreview={(file) => handlePreview(item.files || [], item.name, file)}
                      onRemove={(file) => handleRemove(file, index)}
                      accept=".pdf, .jpg, .png"
                      showUploadList={{
                        showPreviewIcon: checkPermissionAction('P2S1PG2C7', 'P2S1PG2C7A4') ? true : false,
                        showRemoveIcon: checkPermissionAction('P2S1PG2C7', 'P2S1PG2C7A3') ? true : false,
                      }}
                    >
                      {showButton === false ? null : uploadButton}
                    </Upload>
                    <Modal
                      visible={previewVisible}
                      footer={null}
                      onCancel={handleCancelModalUpload}
                    >
                      <img alt="example" src={previewImage} />
                    </Modal>
                  </Col>
                </Row>
              </List.Item>
            )}
          /> */}
          <HandlerModal />
        </Card>
      )}
    </PageSettings.Consumer>
  );
};

const StyleModal = styled(Modal)`
  .ant-modal-content {
    background-color: transparent;
    box-shadow: none;
  }

  .image-gallery-slide .image-gallery-image {
    max-width: 400px;
    max-height: 500px;
  }

  .ant-modal-close {
    color: #FFFFFF;
    right: 667px;
  }

  .ant-modal-close-x {
    font-size: 24px
  }

  .image-gallery-index {
    margin: 0px 20px 0px 20px;
  }

  .image-gallery-right-nav {
    right: 30%;
  }

  .image-gallery-left-nav {
    left: 30%;
  }
`;


