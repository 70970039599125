import React, { useState, useRef } from 'react'
import { Modal, InputNumber } from 'antd'
import { useIntl, FormattedMessage } from 'react-intl'
import Button_02 from '../../../../../components/v2/button_02'
import Button_01 from '../../../../../components/v2/button_01'
import { handleKeyDownDecimal2Fixed } from '../../../../../component-function/fnc-number'
import QRCode from 'react-qr-code'
import _ from 'lodash'
import { numberFormatDecimal } from '../../../../../component-function/fnc-number-format-decimal'
import { payOrder } from '../../../../../controllers/orders-controller/api'
import { successNotification, errorNotification } from '../../../../../components/v2/notification'
import html2canvas from 'html2canvas'
import { getQueueMessages } from '../../../../../hooks/use-queue-api'

const comCode = localStorage.getItem('comCode');

const DeliveryPay = (props) => {
  const { visible, setVisible, orderData, setTrigger } = props
  const intl = useIntl()
  const [receive, setReceive] = useState()
  const qrCodeRef = useRef(null);

  const handleSave = async () => {
    const body = {
      "payAmount": receive,
      "amountChange": Math.abs((_.get(orderData, 'amount') || 0) - (receive || 0))
    }

    const payload = {
      apiMethod: 'post',
      urlQueue: `/queue/manager/company/${comCode}/channgedeliveryorder`,
      apiUrl: `${process.env.REACT_APP_URL_MANAGER}/order-manager/v5/order/manager/company/${comCode}/web/deliveryorder/${_.get(orderData, 'orderId')}/paid`,
      payload: body,
    };

    try {
      const response = await getQueueMessages(payload);
      if (_.get(response, 'status.code') === 200) {
        successNotification(_.get(response, 'status.message'));
        setTrigger(cur => !cur)
        setVisible(false)
        setReceive()
      } else {
        errorNotification(_.get(response, 'status.message'));
      }
    } catch (error) {
      errorNotification(_.get(error, 'response.status.message'));
    }

    // const response = await payOrder(_.get(orderData, 'orderId'), body)
    // if (_.get(response, 'data.status.code') === 200) {
    //   successNotification(_.get(response, 'data.status.message'));
    //   setTrigger(cur => !cur)
    //   setVisible(false)
    //   setReceive()
    // } else {
    //   errorNotification(_.get(response, 'data.status.message'));
    // }

  }

  const handleCancel = () => {
    setVisible(false)
    setReceive()
  }

  const handleChange = (value) => {
    setReceive(value)
  }

  const downloadQRCode = async () => {
    const qrCodeContainer = document.getElementById('qr-code-container');
    const canvas = await html2canvas(qrCodeContainer);

    // Trigger the download by creating a link with the image data
    const link = document.createElement('a');
    link.href = canvas.toDataURL('image/png');
    link.download = 'qrcode.png';
    link.click();
  };

  const currencyFormatter = (value) => {
    return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const currencyParser = (value) => {
    return value.replace(/\$\s?|(,*)/g, '');
  };

  return (
    <Modal
      title={`${intl.formatMessage({ id: `deliveryPayTitle`, defaultMessage: 'Pay Order No.' })} ${_.get(orderData, 'orderNo')}`}
      centered={true}
      width={420}
      visible={visible}
      onCancel={handleCancel}
      bodyStyle={{ padding: '26px 47px' }}
      footer={[
        <Button_02 key="back" btnsize="wd_df" onClick={() => handleCancel()}>
          <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
        </Button_02>,
        <Button_01 style={{ margin: 'unset' }} key="submit" type="primary" btnsize="wd_df" onClick={downloadQRCode}>
          <FormattedMessage id="deliveryPaySaveQr" defaultMessage="Save QR" />
        </Button_01>,
        <Button_01 key="submit" type="primary" btnsize="wd_df" onClick={() => handleSave()}
          disabled={parseFloat(receive || 0) >= _.get(orderData, 'amount') ? false : true}
        >
          <FormattedMessage id="btnSave" defaultMessage="Save" />
        </Button_01>,
      ]}
    >
      <div style={{ textAlign: 'center' }}>
        <div style={{ marginBottom: '8px' }} id="qr-code-container">
          <QRCode id="qrcode" value="hey" size={256} ref={qrCodeRef} />
        </div>
        <div style={{ color: '#898989', fontSize: '12px', marginBottom: '21px' }}>
        <FormattedMessage id="deliveryPayExpiredDate" defaultMessage="Expired Date" />: 01/01/2023 10:10
        </div>
        <div style={{ color: '#050505', fontSize: '14px', fontWeight: 600, marginBottom: '20px' }}>
        <FormattedMessage id="deliveryPayRef" defaultMessage="Ref." /> {_.get(orderData, 'orderNo')}
        </div>
        <div style={{ backgroundColor: '#F2F6FD', textAlign: 'center', padding: '10px 0', marginBottom: '28px' }}>
          <div style={{ marginBottom: '8px', color: '#1D3557', fontSize: '12px' }}>
            <FormattedMessage id="deliveryPayTotalPrice" defaultMessage="Total Shipping Price" />
          </div>
          <div style={{ color: '#6390CF', fontSize: '18px' }}>
            {numberFormatDecimal(_.get(orderData, 'amount'))} <FormattedMessage id="deliveryTHB" defaultMessage="THB" />
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ textAlign: 'left' }}>
            <div style={{ color: '#1D3557', fontSize: '12px', fontWeight: 600, marginBottom: '8px' }}>
              <FormattedMessage id="deliveryPayReceive" defaultMessage="Receive" />
            </div>
            <div>
              <InputNumber
                className='disabled-handle-count'
                placeholder={intl.formatMessage({ id: `deliveryPayPHReceive`, defaultMessage: 'Enter Amount' })}
                onKeyDown={handleKeyDownDecimal2Fixed}
                formatter={currencyFormatter}
                parser={currencyParser}
                controls={false}
                onChange={(e) => handleChange(e)}
                value={receive}
              />
            </div>
          </div>
          <div style={{ textAlign: 'end' }}>
            <div style={{ color: '#1D3557', fontSize: '12px', fontWeight: 600, marginBottom: '8px' }}>
              <FormattedMessage id="deliveryPayChange" defaultMessage="Change" />
            </div>
            <div style={{ color: '#6390CF', fontSize: '18px' }}>
              {numberFormatDecimal(Math.abs((_.get(orderData, 'amount') || 0) - (receive || 0)))} <FormattedMessage id="deliveryTHB" defaultMessage="THB" />
            </div>
          </div>
        </div>

      </div>
    </Modal>
  )
}

export default DeliveryPay
