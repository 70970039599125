import React from 'react'
import { Collapse, Select, Badge, Icon, TreeSelect, Checkbox, Input } from 'antd';
import SettingSvg from '../../../components/image/settings.svg'
import '../css/engagement-filter.css';
import _ from 'lodash'
import { useIntl, FormattedMessage } from 'react-intl';

const { Panel } = Collapse;
const { Option } = Select;
const { TreeNode } = TreeSelect;

export default React.memo(({ filterCustomer, customerIsFilter, onSelectActivities, onSearch, onSelectTeam, customerCountChecked, customerCountAll, customerCheckAll, onChecked, customerIndeterminate }) => {
  const intl = useIntl();

  const filterSvg = () => <img className="filterSvg" src={SettingSvg} />

  const extraIcon = () => {
    return (
      <div style={{ display: 'flex', padding: '0px 0px 0px 24px', marginTop: '-16px', marginRight: '8px' }}>
        <Badge
          className='dot-filter'
          dot={customerIsFilter === true ? true : false}
        >
          <Icon component={filterSvg} />
        </Badge>
      </div>
    )
  }

  const headerTitle = () => {
    return (
      <div style={{ display: 'flex', padding: '0px 0px 0px 14px', marginTop: '2px' }}>
        <FormattedMessage id='engagementTabCustomerTextFilter' defaultMessage='Filter' />
      </div>
    )
  }

  return (
    <div>
      <Collapse
        expandIconPosition={false}
        className='header-style-engage'
        showArrow={false}
      >
        <Panel
          header={headerTitle()}
          key="filter"
          extra={extraIcon()}
          className='header-style-panel'
          showArrow={false}
        >
          <div className='customer-engagement-filter'>
            <div style={{ padding: '8px 0px' }}>
              <p className='span-title-slect-filter' > <FormattedMessage id='engagementTabCustomerTextLblSearch' defaultMessage='Customer Name, Type, or Business' /></p>
              <Input
                placeholder={intl.formatMessage({ id: 'lblSeleteHintEnterName', defaultMessage: 'Search Customer Name, Type, or Business' })}
                onChange={(e) => onSearch(e.target.value)}
                allowClear={true}
                prefix={<Icon style={{ color: "rgba(0,0,0,.25)" }} type="search" />}
              />
            </div>

            {/* <input type='text' onChange={(e) => onSearch(e.target.value)} /> */}
            {/* {filterCustomer.map((ele, i) => (
              <div key={i}>
                {ele.filterCode === 'team' ?
                  (
                    <div className='space-padding-select-filter'>
                      <p className='span-title-slect-filter'>{ele.filterName}</p>
                      <TreeSelect
                        style={{ width: '100%' }}
                        dropdownStyle={{ maxHeight: '370px', overflow: 'auto', width: '273px' }}
                        placeholder={intl.formatMessage({ id: 'lblSeleteHintSelete', defaultMessage: 'Please Select' })}
                        allowClear
                        treeDefaultExpandAll
                        treeData={ele.filterList}
                        onChange={onSelectTeam}
                      />
                    </div>
                  )
                  :
                  ele.filterCode === 'activities' ?
                    (
                      <div className='space-padding-select-filter'>
                        <p className='span-title-slect-filter' >{ele.filterName}</p>
                        <Select
                          style={{ width: '100%' }}
                          placeholder={intl.formatMessage({ id: 'engagementTabCustomerTextSelectAct', defaultMessage: 'Filter' })}
                          onChange={onSelectActivities}
                          allowClear
                        >
                          {ele.filterList.map((e) => (
                            <Option key={e.code} value={e.code}>{e.value}</Option>
                          ))}
                        </Select>
                      </div>
                    )
                    : null
                }
              </div>
            ))} */}
          </div>
        </Panel>
      </Collapse>
      <div className='summary-customer-checked'>
        <div>
          <Checkbox
            checked={customerCheckAll}
            indeterminate={customerIndeterminate}
            onChange={onChecked}
          >
            <FormattedMessage id='engagementTabCustomerTextSelected' defaultMessage='Selected' /></Checkbox>
        </div>
        <div>
          {customerCountChecked} of {customerCountAll}
        </div>
      </div>
    </div>
  )
}, (prev, next) => {
  return _.isMatch(prev, next)
})