import React, { useState } from 'react'
import './index.css'
import { Card, Table } from 'antd'
import { FormattedMessage } from 'react-intl'
import ReportItemRequestAction from './action'
import { useIntl } from 'react-intl'
import _ from 'lodash'

const WarehouseReportItemRequest = () => {
  const intl = useIntl()

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
      fixed: 'left',
      default: true,
    },
    {
      title: intl.formatMessage({ id: 'reportItemRequestColumnsItemNo', defaultMessage: 'Item Request No.' }),
      dataIndex: 'itemRequestNo',
      key: 'itemRequestNo',
      fixed: 'left',
      sorter: true,
      width: 150,
      default: true,
    },
    {
      title: intl.formatMessage({ id: 'reportItemRequestColumnsRequestor', defaultMessage: 'Requestor' }),
      dataIndex: 'requestor',
      key: 'requestor',
      sorter: true,
      width: 150,
      default: true,
    },
    {
      title: intl.formatMessage({ id: 'reportItemRequestColumnsExpectedDate', defaultMessage: 'ExpectedDate' }),
      dataIndex: 'expectedDate',
      key: 'expectedDate',
      sorter: true,
      width: 100,
      default: true,
    },
    {
      title: intl.formatMessage({ id: 'reportItemRequestColumnsRecipent', defaultMessage: 'Recipent' }),
      dataIndex: 'recipent',
      key: 'recipent',
      width: 150,
      sorter: true,
      default: true,
    },
    {
      title: intl.formatMessage({ id: 'reportItemRequestColumnsPhone', defaultMessage: 'Phone' }),
      dataIndex: 'phone',
      key: 'phone',
      width: 120,
      sorter: true,
      default: true,
    },
    {
      title: intl.formatMessage({ id: 'reportItemRequestColumnsItem', defaultMessage: 'Item' }),
      dataIndex: 'item',
      key: 'item',
      width: 150,
      sorter: true,
      default: true,
    },
    {
      title: intl.formatMessage({ id: 'reportItemRequestColumnsQty', defaultMessage: 'Qty' }),
      dataIndex: 'qty',
      key: 'qty',
      width: 120,
      sorter: true,
      default: true,
    },
    {
      title: intl.formatMessage({ id: 'reportItemRequestColumnsStatus', defaultMessage: 'Status' }),
      dataIndex: 'status',
      key: 'status',
      width: 150,
      sorter: true,
      default: true,
    },
    {
      title: intl.formatMessage({ id: 'reportItemRequestColumnsRefType', defaultMessage: 'Reference Type' }),
      dataIndex: 'refType',
      key: 'refType',
      width: 150,
      sorter: true,
      default: true,
    },
    {
      title: intl.formatMessage({ id: 'reportItemRequestColumnsRefDetail', defaultMessage: 'Reference Detail' }),
      dataIndex: 'refDetail',
      key: 'refDetail',
      width: 150,
      default: true,
      sorter: true,
    },
    {
      title: intl.formatMessage({ id: 'reportItemRequestColumnsRemark', defaultMessage: 'Remark' }),
      dataIndex: 'remark',
      key: 'remark',
      width: 150,
      default: true,
      sorter: true,
    },
    {
      title: intl.formatMessage({ id: 'reportItemRequestColumnsRequestedDate', defaultMessage: 'Requested Date' }),
      dataIndex: 'requestedDate',
      key: 'requestedDate',
      width: 150,
      default: true,
      sorter: true,
    },
  ];
  const setShowColumnArr = _.map(columns, (item) => { return item.dataIndex })
  const setShowColumn = columns.reduce((obj, item) => {
    obj[item.dataIndex] = item.default;
    return obj;
  }, {});
  const ignoreColumn = _.map(_.filter(columns, (item) => { return item.default === false }), (item) => { return item.dataIndex })
  const newColumns = columns.filter((col) => !ignoreColumn.includes(col.key));
  const [searchOrder, setSearchOrder] = useState({});
  const [newDataColumns, setNewDataColumns] = useState([...newColumns]);
  const [listArrayColumns, setListArrayColumns] = useState([...setShowColumnArr]);
  const [defaultShowColumn, setDefaultShowColumn] = useState({ ...setShowColumn });
  const [textErrorSelectColumn, setTextErrorSelectColumn] = useState('');
  const [visible, setVisible] = useState(false);
  const columnMinimum = 2
  const [sortOrder, setSortOrder] = useState('')
  const [sortField, setSortField] = useState('')
  const [page, setPage] = useState(1)
  const [size, setSize] = useState(10)
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)
  const [listData, setListData] = useState([])


  const onFilterOrder = async (value, code) => {
    setSearchOrder({ ...searchOrder, [code]: value });
  };

  const handleChange = (pagination, sorter, extra) => {
    let field = extra.field;
    if (extra.order) {
      if (extra.order === 'descend') {
        setSortOrder('desc');
      } else {
        setSortOrder('asc');
      }
    } else {
      setSortOrder('');
    }
    setSortField(field);
    setPage(pagination.current);
    setSize(pagination.pageSize);
  };

  const showTotal = () => {
    return (
      <span style={{ fontSize: '13px' }}>
        <FormattedMessage id="lblRowPerPage" defaultMessage="Rows per page: " />
      </span>
    );
  };

  return (
    <div>
      <Card
        className="reportItemRequestCard"
        title={intl.formatMessage({ id: 'menuWarehouseItemRequest', defaultMessage: 'Item Request' })}
        bodyStyle={{ padding: 'unset' }}
      >
        <ReportItemRequestAction
          onFilterOrder={onFilterOrder}
          actionColumns={{
            columns,
            setShowColumn,
            newDataColumns,
            setNewDataColumns,
            setListArrayColumns,
            setDefaultShowColumn,
            listArrayColumns,
            defaultShowColumn,
            newColumns,
            textErrorSelectColumn,
            setTextErrorSelectColumn,
            visible,
            setVisible,
            columnMinimum
          }}
        />
        <div style={{ padding: '16px 24px' }}>
          <Table
            dataSource={listData}
            columns={newDataColumns}
            scroll={{ x: _.sumBy(newDataColumns, 'width') }}
            onChange={handleChange}
            loading={loading}
            pagination={{
              total: total,
              showTotal: showTotal,
              defaultCurrent: 1,
              current: page,
              pageSizeOptions: ['10', '20', '30', '40', '50'],
              showSizeChanger: true,
              locale: { items_per_page: '' },
            }}
          />
          {_.size(listData) > 0 ? (
            <div className="total-items-member">
              <span style={{ fontSize: '13px' }}>
                <FormattedMessage id="lblTotal" defaultMessage="Total" />
                {` ${total || 0} `}
                <FormattedMessage id="lblitems" defaultMessage="items" />
              </span>
            </div>
          ) : null}
        </div>
      </Card>
    </div>
  )
}

export default WarehouseReportItemRequest

