import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Card, Row, Col } from 'antd';
import { PermissionProvider } from './PermissionContext';
import PermissionRole from './PermissionRole';
import PermissionAccess from './PermissionAccess';

export default () => {

  return (
    <PermissionProvider>
      <Row gutter={[16, 16]}>
        <Col span={6}>
          <PermissionRole />
        </Col>
        <Col span={18}>
          <div style={{ overflow: 'auto',backgroundColor:'white'}}>
            <PermissionAccess />
          </div>
        </Col>
      </Row>

    </PermissionProvider>
  );
}