import { Col } from 'antd';
import React from 'react'
import _ from 'lodash'

const FeedNoteImage = (props) => {
  const { isMemComId, image, item, fncPreview } = props
  return (
    <Col
      span={24}
      style={isMemComId ? { display: 'flex', justifyContent: 'end', textAlign: 'right' } : undefined}
    >
      <div style={{ width: '100%' }}>{_.size(image) > 0 ? fncPreview(image, item) : null}</div>
    </Col>
  )
}

export default FeedNoteImage
