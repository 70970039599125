import {
  Table,
  Card,
  Col,
  Row,
  Tabs,
  Button,
  Icon,
  Menu,
  Dropdown,
  Popover,
  Checkbox,
  Form,
  Input,
  Select,
  DatePicker,
  Divider,
} from 'antd';
import React, { useContext, useState, useRef } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';

import Css from './css/index.css';
import _ from 'lodash';
import { DownOutlined } from '@ant-design/icons';
import { PageSettings } from '../../../config/page-settings';
import Button_01 from '../../../components/v2/button_01';
import CollapseCustom from '../../../components/collapse-custom';
import LabeRequireForm from '../../../components/label-required-form';
import moment from 'moment';
import { VoucherContext } from '../voucher-context';
import ButtonActionGroup from '../../../components/action-button';
import { messageLabel } from '../../../components/message-component';
import Tag from '../../users/inviteUser/tag';
import Button_02 from '../../../components/v2/button_02';
import VoucherAction from './actionColumns';

const { RangePicker } = DatePicker;
const { Option } = Select;
const dateFormat = 'ddd, MMM DD YYYY';

const CardTableVoucher = (props) => {
  const { state, setState, fnc } = useContext(VoucherContext);
  const { voucherList, isLoading, page, total, searchGroup, statusList, memberList, brandList, dataModel } = state;
  const { setEvent, setSort, setSearchGroup, setPaginationPage, dataDefault, setBrandId } = setState;
  const { handleExport, handleBrandPopupScroll, handleModelPopupScroll } = fnc;
  const { handleChangePage, handleChangeSize, handleVisibleCreate, handleView, handleEdit, handleCustomerPopupScroll, handleSearchModel, handleSearchBrand } = fnc;
  const totalItems = total;
  const [scrollTableX, setScrollTableX] = useState(2500);
  const intl = useIntl();
  const ref = useRef();
  const [visiblePopoverTable, setVisiblePopoverTable] = useState();
  const [recordPopover, setRecordPopover] = useState();
  const [offsetPopover, setOffsetPopover] = useState([0, 0]);
  const [textErrorSelectColumn, setTextErrorSelectColumn] = useState('');
  const [visible, setVisible] = useState(false);
  const itemGroupCode = _.get(dataDefault, 'itemGroup');
  const itemTypeCode = _.get(dataDefault, 'itemType');
  const itemBrand = _.get(dataDefault, 'itemBrand');
  // console.log('dataDefault', dataDefault);
  const data = [{ total: '1' }];

  const setShowColumn = {
    voucherNo: true,
    voucherName: true,
    customerName: true,
    totalTxt: true,
    voucherBrandName: true,
    voucherModelName: true,
    statusTxt: true,
    voucherAmount: true,
    balaceTxt: true,
    minSpendTxt: true,
    minUseTxt: true,
    maxUseTxt: true,
    voucherDate: true,
    reference: true,
    description: true,
    team: false,
    createdBy: true,
    createdDateTxt: true,
  };

  // const setShowColumnArr = [
  //   'voucherNo',
  //   'voucherName',
  //   'customerName',
  //   'totalTxt',
  //   'voucherBrandName',
  //   'voucherModelName',
  //   'statusTxt',
  //   'voucherAmount',
  //   'balaceTxt',
  //   'minSpendTxt',
  //   'minUseTxt',
  //   'maxUseTxt',
  //   'voucherDate',
  //   'reference',
  //   'description',
  //   // 'team',
  //   'createdBy',
  //   'createdDateTxt',
  // ];


  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      fixed: 'left',
      width: 70,
      
    },
    {
      title: messageLabel('VoucherNo', 'Voucher No.'),
      dataIndex: 'voucherNo',
      key: 'voucherNo',
      sorter: true,
      fixed: 'left',
      width: 150,
      ellipsis: true,
    },
    {
      title: messageLabel('VoucherName', 'Voucher Name'),
      dataIndex: 'voucherName',
      key: 'voucherName',
      sorter: true,
      width: 250,
    },
    {
      title: messageLabel('VoucherPaidTo', 'Paid To'),
      dataIndex: 'customerName',
      key: 'customerName',
      width: 250,
      render: (text, record, index) => _.get(record, 'ownerType') === 'company' 
      ? _.get(record, 'comCode') + ' ' + _.get(record, 'customerName')
      : _.get(record, 'customerCode')  + ' ' + _.get(record, 'customerName') 
    },

    // {
    //   title: messageLabel('VoucherTotalAmount', 'Total Amount'),
    //   dataIndex: 'totalTxt',
    //   key: 'totalTxt',
    //   sorter: true,
    //   width: 150,
    //   align: 'right'
    //   // render: (text, record, index) => _.get(record, 'paymentTermNo'),
    // },

    {
      title: messageLabel('VoucherBrand', 'Brand'),
      dataIndex: 'voucherBrandName',
      key: 'voucherBrandName',
      sorter: true,
      width: 200,
      
      // render: (text, record, index) => _.get(record, 'paymentTermNo'),
    },

    {
      title: messageLabel('VoucherModel', 'Model'),
      dataIndex: 'voucherModelName',
      key: 'voucherModelName',
      sorter: true,
      width: 200,
      
      // render: (text, record, index) => _.get(record, 'paymentTermNo'),
    },

    {
      title: messageLabel('VoucherStatus', 'Status'),
      dataIndex: 'statusTxt',
      key: 'statusTxt',
      width: 200,
      align: 'center',
      sorter: true,
      render: (text, record, index) => (
        <span>
          <p
            style={{
              // backgroundColor: record['statusColor'],
              backgroundColor: _.get(record, 'statusColor'),
              color: 'white',
              textAlign: 'center',
              borderRadius: '10px',
              // height: '23px',
              // width: '95px',
            }}
          >
            {record.statusTxt}
          </p>
        </span>
      ),
    },

    {
      title: messageLabel('VoucherVoucherAmount', 'Voucher Amount'),
      dataIndex: 'totalTxt',
      key: 'totalTxt',
      sorter: true,
      width: 200,
      align: 'right'
      // render: (text, record, index) => _.get(record, 'paymentTermNo'),
    },

    // {
    //   title: messageLabel('VoucherUsedAmount', 'Used Amount'),
    //   dataIndex: 'usedTxt',
    //   key: 'usedTxt',
    //   sorter: true,
    //   // render: (text, record, index) => _.get(record, 'paymentTermNo'),
    // },

    {
      title: messageLabel('VoucherBalance', 'Balance'),
      dataIndex: 'balaceTxt',
      key: 'balaceTxt',
      sorter: true,
      width: 200,
      align: 'right'
      // render: (text, record, index) => _.get(record, 'paymentTermNo'),
    },

    {
      title: messageLabel('VoucherMinSpend', 'Min Spend'),
      dataIndex: 'minSpendTxt',
      key: 'minSpendTxt',
      width: 200,
      align: 'right'
    },

    {
      title: messageLabel('VoucherMinUse', 'Min Use'),
      dataIndex: 'minUseTxt',
      key: 'minUseTxt',
      width: 200,
      align: 'right'
    },

    {
      title: messageLabel('VoucherMaxUse', 'Max Use'),
      dataIndex: 'maxUseTxt',
      key: 'maxUseTxt',
      width: 200,
      align: 'right'
    },

    {
      title: messageLabel('VoucherDate', 'Voucher Date'),
      dataIndex: 'voucherDate',
      key: 'voucherDate',
      width: 200,
      
    },
    {
      title: messageLabel('VoucherReference', 'Reference'),
      dataIndex: 'reference',
      key: 'reference',
      width: 200,
      
    },

    {
      title: messageLabel('VoucherRemark', 'Remark'),
      dataIndex: 'description',
      key: 'description',
      // render: (text, record, index) => '',
      width: 200,
      
    },
    {
      title: messageLabel('VoucherTeam', 'Team'),
      dataIndex: 'team',
      key: 'team',
      // render: (text, record, index) => '',
      width: 250,
      sorter: true,
      default: false,
    },

    {
      title: messageLabel('VoucherCreatedBy', 'Created By'),
      dataIndex: 'createdBy',
      key: 'createdBy',
      width: 200,
      sorter: true,
      
    },

    {
      title: messageLabel('VoucherCreatedDate', 'Created Date'),
      dataIndex: 'createdDateTxt',
      key: 'createdDateTxt',
      sorter: true,
      width: 200,
      
    },
  ];
  
  const setShowColumnArr = _.map(columns, (item) => {
    return item.dataIndex;
  });

  const [defaultShowColumn, setDefaultShowColumn] = useState({ ...setShowColumn });
  const [listArrayColumns, setListArrayColumns] = useState([...setShowColumnArr]);

  const columnMinimum = 2

  const ignoreColumn = _.map(
    _.filter(columns, (item) => {
      return item.default === false;
    }),
    (item) => {
      return item.dataIndex;
    }
  );

  const newColumns = columns.filter((col) => !ignoreColumn.includes(col.key));
  const ignoreColumnButton = ['movementId', 'warehouseName'];
  const columnNew = columns.filter((col) => !ignoreColumnButton.includes(col.key));
  const [newDataColumns, setNewDataColumns] = useState([...newColumns]);

  const showTotal = () => {
    return (
      <span style={{ fontSize: '13px' }}>
        <FormattedMessage id="lblRowPerPage" defaultMessage="Rows per page: " />
      </span>
    );
  };

  const handleMouseLeave = () => {
    setVisiblePopoverTable(false);
    setRecordPopover();
    setOffsetPopover([0, 0]);
  };

  const fncActionTable = (record, offsetPopover, visiblePopoverTable, ref) => {
    return (
      <div>
        <Popover
          placement="right"
          trigger="hover"
          align={{ offset: offsetPopover }}
          visible={visiblePopoverTable}
          zIndex={10000}
          content={
            <div style={{ display: 'grid' }}>
              <PageSettings.Consumer>
                {({ checkPermissionAction }) => (
                  <>
                    <Button style={{ width: 100 }} type="link" ghost onClick={() => handleView(record)}>
                      <FormattedMessage id="btnView" defaultMessage="View" />
                    </Button>
                    {_.get(record, 'statusCode') !== 'canceled' && _.get(record, 'statusCode') !== 'expired' ? (
                      <Button style={{ width: 100 }} type="link" ghost onClick={() => handleEdit(record)}>
                        <FormattedMessage id="btnEdit" defaultMessage="Edit" />
                      </Button>
                    ) : null}
                  </>
                )}
              </PageSettings.Consumer>
            </div>
          }
        >
          <div style={{ width: 0, height: 0 }} ref={ref}></div>
        </Popover>
      </div>
    );
  };

  const onChangeTable = (value, index, sort) => {
    setSort(sort);
    setEvent((current) => !current);
  };

  const onChange = (value, code) => {
    setSearchGroup({ ...searchGroup, [code]: value });
    setPaginationPage(1);
  };

  const onSearch = () => {
    setEvent((event) => !event)
  }

  const onReset = () => {
    setSearchGroup({ ...searchGroup, Voucherno: '', customer: undefined, brand: undefined, model: undefined, status: undefined, exdate: null, createDate: null, ref: '',  });
    setEvent((event) => !event)
  }

  const formFilterStock = () => {
    return (
      <Form colon={false}>
        <Row gutter={[24, 2]}>
          <Col span={8}>
            <Form.Item
              label={<LabeRequireForm text={intl.formatMessage({ id: 'VoucherNo', defaultMessage: 'Voucher No.' })} req={false} />}
            >
              <Input
                allowClear
                value={_.get(searchGroup, 'Voucherno')}
                onChange={(e) => onChange(e.target.value, 'Voucherno')}
                placeholder={intl.formatMessage({ id: 'VoucherNoAndNamePlaceholder', defaultMessage: 'Enter Voucher No. or Voucher Name' })}
              />
            </Form.Item>
          </Col>
          {/* <Col span={8}>
            <Form.Item
              label={<LabeRequireForm text={intl.formatMessage({ id: 'VoucherName', defaultMessage: 'Voucher Name' })} req={false} />}
            >
              <Input
                allowClear
                onChange={(e) => onChange(e.target.value, 'vouchername')}
                placeholder={intl.formatMessage({ id: 'VoucherNamePlaceHolder', defaultMessage: 'Enter Voucher Name' })}
              />
            </Form.Item>
          </Col> */}
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'VoucherPaidTo', defaultMessage: 'Paid To' })} req={false} />
              }
            >
              <Select
                placeholder={intl.formatMessage({ id: 'VoucherPaidToPlaceHolder', defaultMessage: 'Select Paid To' })}
                onChange={(e) => onChange(e, 'customer')}
                showSearch
                allowClear
                filterOption={(input, option) =>
                  option.props.children
                    .toString()
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                onPopupScroll={handleCustomerPopupScroll}
                value={_.get(searchGroup, 'customer')}
              >
                {memberList &&
                  memberList.map((item, index) => {
                    return (
                      <Option key={index} value={item.customerId}>
                        {item.customerName}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label={<LabeRequireForm text={intl.formatMessage({ id: 'recivePOBrand', defaultMessage: 'Brand' })} req={false} />}
            >
              <Select
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.props.children
                    .toString()
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                value={_.get(searchGroup, 'brand')}
                onChange={(e) => {
                  onChange(e, 'brand')
                  setBrandId(e);
                  // handleChangeBrand(value);
                }}
                onSearch={handleSearchBrand}
                onSelect={(e) => {
                  // if (e === '*') {
                  //   setBrandSelectAll(true);
                  //   setModelSelectAll(true);
                  //   setFieldsValue({ ['model']: '*' });
                  // } else {
                  //   setBrandSelectAll(false);
                  //   setModelSelectAll(true);
                  //   setFieldsValue({ ['model']: '*' });
                  // }
                }}
                placeholder={intl.formatMessage({ id: 'recivePOPlaceholderBrand', defaultMessage: 'Select Brand' })}
                onPopupScroll={handleBrandPopupScroll}
              >
                {brandList && brandList.map((item, i) => <Option value={item.item_brand_id}>{item.item_brand_name}</Option>)}
              </Select>
            </Form.Item>
          </Col>

        </Row>
        <Row gutter={[24, 2]}>

          <Col span={8}>
            <Form.Item label={<LabeRequireForm text={intl.formatMessage({ id: 'recivePOModel', defaultMessage: 'Model' })} />}>
              <Select
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.props.children
                    .toString()
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                value={_.get(searchGroup, 'model')}
                disabled={!_.get(searchGroup, 'brand')}
                placeholder={intl.formatMessage({ id: 'recivePOPlaceholderModel', defaultMessage: 'Select Model' })}
                onPopupScroll={handleModelPopupScroll}
                onChange={(e) => {
                  onChange(e, 'model')
                }}
                onSearch={handleSearchModel}
                onSelect={(e) => {
                  // if (e === '*') {
                  //   setModelSelectAll(true);
                  // } else {
                  //   setModelSelectAll(false);
                  // }
                }}
              >
                {dataModel &&
                  dataModel.map((item) => (
                    <Option key={item.item_model_id} value={item.item_model_id}>
                      {item.item_model_name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label={<LabeRequireForm text={intl.formatMessage({ id: 'AllticketStatusForm', defaultMessage: 'Status' })} req={false} />}
            >
              <Select
                value={_.get(searchGroup, 'status')}
                placeholder={intl.formatMessage({ id: 'AllticketEndStatueSelectForm', defaultMessage: 'Select Status' })}
                showSearch
                allowClear
                onChange={(e) => onChange(e, 'status')}
                filterOption={(input, option) =>
                  option.props.children
                    .toString()
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {statusList &&
                  statusList.map((item, index) => {
                    return (
                      <Option key={index} value={item.statusCode}>
                        {item.statusName}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'voucherExpiredDate', defaultMessage: 'Expired Date' })} req={false} />
              }
            >
              <RangePicker
                allowClear
                value={_.get(searchGroup, 'exdate')}
                style={{ width: '100%' }}
                onChange={(value) => onChange(value, 'exdate')}
                format={['DD/MM/YYYY', 'DD/MM/YYYY']}
                placeholder={[
                  intl.formatMessage({ id: 'recivePOPlaceholderManufacturingDateFrom', defaultMessage: 'Select From Date' }),
                  intl.formatMessage({ id: 'recivePOPlaceholderManufacturingDateTo', defaultMessage: 'Select To Date' }),
                ]}
              />
            </Form.Item>
          </Col>
          
          
        </Row>

        <Row gutter={[24, 2]}>

        <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'voucherCreatedDate', defaultMessage: 'Created Date'  })} req={false} />
              }
            >
              <RangePicker
                value={_.get(searchGroup, 'createDate')}
                allowClear
                style={{ width: '100%' }}
                onChange={(value) => onChange(value, 'createDate')}
                format={['DD/MM/YYYY', 'DD/MM/YYYY']}
                placeholder={[
                  intl.formatMessage({ id: 'voucherholderCreatedDateFrom', defaultMessage: 'Select From Date' }),
                  intl.formatMessage({ id: 'voucherholderCreatedDateTo', defaultMessage: 'Select To Date' }),
                ]}
              />
            </Form.Item>
          </Col>
          
          <Col span={8}>
            <Form.Item
              label={<LabeRequireForm text={intl.formatMessage({ id: 'VoucherReference', defaultMessage: 'Reference' })} req={false} />}
            >
              <Input
                value={_.get(searchGroup, 'ref')}
                allowClear
                onChange={(e) => onChange(e.target.value, 'ref')}
                placeholder={intl.formatMessage({ id: 'VoucherReferencePlaceHolder', defaultMessage: 'Enter Reference' })}
              />
            </Form.Item>
          </Col>

          <Col span={8} style={{ textAlign: 'right' }}>
            <Form.Item
                label={<LabeRequireForm text={''} req={false} />}
              >
                <Button_01 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={onReset}>
                  <FormattedMessage id="btnResetFilter" defaultMessage="Reset" />
                </Button_01>
                <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="print" type="primary" btnsize="wd_df" onClick={onSearch}>
                  <FormattedMessage id="btnApplyFilter" defaultMessage="Apply" />
                </Button_02>
            </Form.Item>
          </Col>

        </Row>
      </Form>
    );
  };

  const menuColumn = (
    <Menu>
      <Menu.Item>
        <Button
          ghost
          type="link"
          onClick={() => {
            handleVisibleCreate();
          }}
        >
          <FormattedMessage id="VoucherCreateVoucher" defaultMessage="Create Voucher" />
        </Button>
      </Menu.Item>
    </Menu>
  );

  return (
    <div>
      <PageSettings.Consumer>
        {({ checkPermissionAction }) =>
          checkPermissionAction(`P1PG1`, `P1PG1A1`) ? (
            <Card
              title={<FormattedMessage id="menuVoucher" defaultMessage="Voucher" />}
              className="right-cards"
              style={{ margin: '8px' }}
              extra={<ButtonActionGroup menu={menuColumn} />}
            >
              <div style={{ marginTop: '20px' }}>
                <CollapseCustom
                  label={intl.formatMessage({ id: `btnFilter`, defaultMessage: 'Filter' })}
                  extra={
                    <div style={{ display: 'flex' }}>
                      <RangePicker
                        allowClear
                        style={{ width: '100%' }}
                        onChange={(value) => onChange(value, 'effdate')}
                        value={_.get(searchGroup, 'effdate')}
                        format={['DD/MM/YYYY', 'DD/MM/YYYY']}
                        placeholder={[
                          intl.formatMessage({ id: 'recivePOPlaceholderManufacturingDateFrom', defaultMessage: 'Select From Date' }),
                          intl.formatMessage({ id: 'recivePOPlaceholderManufacturingDateTo', defaultMessage: 'Select To Date' }),
                        ]}
                      />
                      <VoucherAction
                        actionColumns={{
                          columns,
                          setShowColumn,
                          newDataColumns,
                          setNewDataColumns,
                          setListArrayColumns,
                          setDefaultShowColumn,
                          listArrayColumns,
                          defaultShowColumn,
                          newColumns,
                          textErrorSelectColumn,
                          setTextErrorSelectColumn,
                          visible,
                          setVisible,
                          columnMinimum,
                        }}
                      />
                    </div>
                  }
                >
                  {formFilterStock()}
                </CollapseCustom>
              </div>
              <div className="containerTables" onMouseLeave={handleMouseLeave}>
                <Row gutter={16}>
                  <Table
                    className="custom-table-claim"
                    dataSource={voucherList || []}
                    columns={newDataColumns}
                    scroll={{ x: scrollTableX, y: `calc(100vh - 366px)` }}
                    loading={isLoading}
                    onChange={onChangeTable}
                    pagination={{
                      total: totalItems,
                      current: page,
                      defaultCurrent: 1,
                      pageSizeOptions: ['10', '20', '30', '40', '50'],
                      showSizeChanger: true,
                      locale: { items_per_page: '' },
                      showTotal: showTotal,
                      onChange: handleChangePage,
                      onShowSizeChange: handleChangeSize,
                    }}
                    onRow={(record, rowIndex) => {
                      return {
                        onClick: (event) => {
                          if (!event.target.href) {
                            const { x, y } = ref.current.getBoundingClientRect();
                            setVisiblePopoverTable(true);
                            setOffsetPopover([event.pageX - x, event.pageY - y]);
                            setRecordPopover(record);
                          }
                        },
                      };
                    }}
                  />
                  {fncActionTable(recordPopover, offsetPopover, visiblePopoverTable, ref)}
                  <div className="total-items-member">
                    <span style={{ fontSize: '13px' }}>
                      <FormattedMessage id="lblTotal" defaultMessage="Total" />
                      {` ${totalItems || 0} `}
                      <FormattedMessage id="lblitems" defaultMessage="items" />
                    </span>
                  </div>
                </Row>
              </div>
            </Card>
          ) : null
        }
      </PageSettings.Consumer>
    </div>
  );
};

export default CardTableVoucher;
