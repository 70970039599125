
import React, { useState, useEffect } from 'react'
import { DatePicker, Form, Modal, Select, Row, Col, Input } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl'
import Button01 from '../v2/button_01'
import Button02 from '../v2/button_02'
import './index.css'
import LabeRequireForm from '../label-required-form';
import { handleKeyDownNumber, handleKeyDownDecimal2Fixed } from '../../component-function/fnc-number'
import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid';

const { Option } = Select

const ModalReceiveLotOrSerial = (props) => {
  const { form, visible, setVisible, lotNo, setLotNo, binLocationList, uomData, defaultData, setDefaultData, numbertypeList } = props
  const intl = useIntl()
  const { getFieldDecorator, validateFields, resetFields } = form
  const [lotNoSelect, setLotNoSelect] = useState("lot")
  // const [lotNoTemp, setlotNoTemp] = useState()

  useEffect(() => {
    if (defaultData && visible) {
      setLotNoSelect(_.get(defaultData, 'lotNoSelect'))
    }
  }, [defaultData, visible])

  console.log("lotNoSelect", lotNoSelect)


  const handleSave = (type) => {
    validateFields(async (err, values) => {
      if (err) {
        return;
      }
      if (defaultData) {
        const filterUoM = _.filter(uomData, (item) => { return item.uomId === values.uom })
        const filterBin = _.filter(binLocationList, (item) => { return item.binLoationId === values.binLocation })
        const body = {
          ..._.get(filterUoM, '[0]'),
          binlocationData: _.get(filterBin, '[0]'),
          ...values,
          lotNoSelect: lotNoSelect,
          index: _.get(defaultData, 'index'),
          id: _.get(defaultData, 'id'),
        }
        setLotNo(prevData => {
          const newData = prevData.map(item => {
            if (item.id === _.get(defaultData, 'id')) {
              return { ...item, ...body };
            }
            return item;
          });
          return newData;
        });
      } else {
        const filterUoM = _.filter(uomData, (item) => { return item.uomId === values.uom })
        const filterBin = _.filter(binLocationList, (item) => { return item.binLoationId === values.binLocation })
        const body = {
          ..._.get(filterUoM, '[0]'),
          binlocationData: _.get(filterBin, '[0]'),
          ...values,
          lotNoSelect: lotNoSelect,
          index: _.size(lotNo) + 1,
          id: uuidv4()
        }
        setLotNo(prevItems => [...prevItems, body])
      }

      if (type === "save" || defaultData) setVisible(false)
      resetFields()
      setDefaultData()
    })
  }

  const handleCancel = () => {
    setVisible(false)
    resetFields()
    setDefaultData()
  }

  const handleSelectLot = (value) => {
    setLotNoSelect(value)
  }

  console.log("lotNoSelect", lotNoSelect)

  const checkAdd = defaultData ? [
    <Button02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleCancel}>
      <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
    </Button02>,
    <Button01 key="submit" type="primary" btnsize="wd_df" onClick={() => handleSave("save")}>
      <FormattedMessage id="btnSave" defaultMessage="Save" />
    </Button01>,
  ] : [
    <Button02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleCancel}>
      <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
    </Button02>,
    <Button01 key="submit" type="primary" btnsize="wd_df" onClick={() => handleSave("save")}>
      <FormattedMessage id="btnSave" defaultMessage="Save" />
    </Button01>,
    <Button01 key="submit" type="primary" btnsize="wd_ct" onClick={() => handleSave("continue")} style={{ margin: 'unset' }}>
      <FormattedMessage id="btnSaveAndContinue" defaultMessage="Save and Continue" />
    </Button01>
  ]

  return (
    <Modal
      title={intl.formatMessage({ id: 'modalLotNoHeader', defaultMessage: 'Add Lot No. or Serial No.' })}
      visible={visible}
      width={659}
      centered={true}
      bodyStyle={{ padding: '12px 24px 24px 24px' }}
      onCancel={handleCancel}
      footer={checkAdd}
    >
      <Form form={form} layout="vertical" className='modalLotNoForm'>
        <Row gutter={[16]}>
          <Col span={12}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'modalLotNoLotNoText', defaultMessage: 'Add Lot No. or Serial No.' })} req={true} />
              }
            >
              <div style={{ display: 'flex', width: '100%' }}>
                <div style={{ width: '40%' }}>
                  <Select className='modalAddLotNoSelect' value={lotNoSelect} onSelect={handleSelectLot}>
                    {
                      _.map(numbertypeList, (item) => (
                        <Option value={item.code}>{item.name}</Option>
                      ))
                    }
                  </Select>
                </div>
                <div style={{ width: '60%' }}>
                  {getFieldDecorator('lotNo', {
                    initialValue: _.get(defaultData, 'lotNo') || undefined,
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({ id: 'modalLotNoLotNoValidate', defaultMessage: 'Please Enter Lot No. or Serial No.' }),
                      },
                    ],
                  })(
                    <Input
                      className='modalAddLotNoInput'
                      placeholder={intl.formatMessage({ id: 'modalLotNoLotNoPlaceholeder', defaultMessage: 'Enter Lot No. or Serial No.' })}
                    />
                  )}
                </div>
              </div>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'modalLotNoCostText', defaultMessage: 'Cost' })} req={true} />
              }
            >
              {getFieldDecorator('cost', {
                initialValue: _.get(defaultData, 'cost') || undefined,
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'modalLotNoCostValidate', defaultMessage: 'Please Enter Cost' }),
                  },
                ],
              })(
                <Input
                  placeholder={intl.formatMessage({ id: 'modalLotNoCostPlaceholeder', defaultMessage: 'Enter Cost' })}
                  onKeyDown={handleKeyDownDecimal2Fixed}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'modalLotNoQtyText', defaultMessage: 'Qty' })} req={true} />
              }
            >
              {getFieldDecorator('qty', {
                initialValue: _.get(defaultData, 'qty') ? _.get(defaultData, 'qty') : lotNoSelect === "serial" ? "1" : undefined,
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'modalLotNoQtyValidate', defaultMessage: 'Please Enter Qty' }),
                  },
                ],
              })(
                <Input
                  placeholder={intl.formatMessage({ id: 'modalLotNoQtyPlaceholeder', defaultMessage: 'Enter Qty' })}
                  onKeyDown={handleKeyDownNumber}
                  disabled={lotNoSelect === "serial" ? true : false}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'modalLotNoUoMText', defaultMessage: 'UoM' })} req={true} />
              }
            >
              {getFieldDecorator('uom', {
                initialValue: _.get(defaultData, 'uom') || undefined,
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'modalLotNoUoMValidate', defaultMessage: 'Please Select UoM' }),
                  },
                ],
              })(
                <Select
                  placeholder={intl.formatMessage({ id: 'modalLotNoUoMPlaceholeder', defaultMessage: 'Select UoM' })}
                  showSearch
                  filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {uomData &&
                    uomData.map((item) => (
                      <Option key={item.uomId} value={item.uomId}>
                        {item.name}
                      </Option>
                    ))}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'modalLotNoManufacturingDateText', defaultMessage: 'Manufacturing Date' })} />
              }
            >
              {getFieldDecorator('manufacturingDate', {
                initialValue: _.get(defaultData, 'manufacturingDate') || undefined,
              })(
                <DatePicker
                  placeholder={intl.formatMessage({ id: 'modalLotNoManufacturingDatePlaceholeder', defaultMessage: 'Select Manufacturing Date' })}
                  style={{ width: '100%' }}
                  format={'ddd, DD MMM YYYY'}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'modalLotNoExpiredDateText', defaultMessage: 'Expired Date' })} />
              }
            >
              {getFieldDecorator('expiredDate', {
                initialValue: _.get(defaultData, 'expiredDate') || undefined,
              })(
                <DatePicker
                  placeholder={intl.formatMessage({ id: 'modalLotNoExpiredDatePlaceholeder', defaultMessage: 'Select Expired Date' })}
                  style={{ width: '100%' }}
                  format={'ddd, DD MMM YYYY'}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'modalLotNoFDAText', defaultMessage: 'FDA' })} req={false} />
              }
            >
              {getFieldDecorator('fda', {
                initialValue: _.get(defaultData, 'fda') || undefined,
              })(
                <Input
                  placeholder={intl.formatMessage({ id: 'modalLotNoFDAPlaceholeder', defaultMessage: 'Enter FDA' })}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'modalLotNoBinLocationText', defaultMessage: 'Bin Location' })} req={false} />
              }
            >
              {getFieldDecorator('binLocation', {
                initialValue: _.get(defaultData, 'binlocationData.binLoationId') || undefined,
                rules: [
                  {
                    required: false,
                    message: intl.formatMessage({ id: 'modalLotNoBinLocationValidate', defaultMessage: 'Please Select Bin Location' }),
                  },
                ]
              })(
                <Select
                  placeholder={intl.formatMessage({ id: 'modalLotNoBinLocationPlaceholeder', defaultMessage: 'Select Bin Location' })}
                  showSearch
                  filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {binLocationList &&
                    binLocationList.map((item) => (
                      <Option key={item.binLoationId} value={item.binLoationId} label={item.name}>
                        {item.name}
                      </Option>
                    ))}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal >
  )
}

const ModalFormReceiveLotOrSerial = Form.create({
  name: 'modal_form',
})(ModalReceiveLotOrSerial);


export default ModalFormReceiveLotOrSerial