import httpClientNoKeyCloak from '../../components/axiosClientNoKeyCloak'
import _ from 'lodash';

const getMenuOrder = async (comCode, id) => {

  try {
    const response = await httpClientNoKeyCloak.get(`/v1/warehouse/manager/company/${comCode}/web/orderrequest/${id}`);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
}

export { getMenuOrder }