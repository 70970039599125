import React, { useEffect, useState, useCallback } from 'react';
import { Form, Input, Row, Col, Icon, Select, Modal, Divider } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import Button01 from '../../components/v2/button_01';
import Button02 from '../../components/v2/button_02';
import { CustomerContext } from './add-customer-context';
import AppAvatar from '../avatar';
import './css/index.css';
import { getBusinessType, getCustomerType } from '../add-customer/controller/controller';
import Resizer from 'react-image-file-resizer';
import DefaultProfile from '../../components/image/Profile_Default.png';
import InformationCustomer from './add-info-customer';
import { successNotification, errorNotification } from '../../components/v2/notification';
import httpClient from '../../components/axiosClient';
import ModalMapLocationFrom from '../create-customer-location/index';
import ModalEditMapLocationFrom from '../edit-customer-location/index';
import MapLocation from './map-view-customer';
import _, { debounce } from 'lodash';
import ModalCreatePrivacy from '../modal-privacy/index';
import EditModalPrivacy from '../edit-modal-privacy/index';
import PrivacyController from '../../controllers/getCustomer/get-privacy-type';

const { Option, OptGroup } = Select;

const ModalFormCustomer = ({ visible, onCancel, onCreate, form, image, setCustomer, setDataPrivacy, dataPrivacy }) => {
  const intl = useIntl();
  const comCode = localStorage.getItem('comCode');
  const { imageRef, imageCustomer, imageFile, setImageCustomer, setImageFile } = image;
  const { getFieldDecorator, getFieldsValue, getFieldValue } = form;
  const [businessType, setBusinessType] = useState([]);
  const [customerType, setCustomerType] = useState([]);
  const [visibled, setVisibled] = useState(false);
  const [visibleText, setVisibleText] = useState(false);
  const [imageFileDefault, setImageFileDefault] = useState('');
  const [newImage, setNewImage] = useState();

  const [customerID, setCustomerID] = useState('');
  const [custName, setCustName] = useState('');

  const [disableSelete, setDisableSelete] = useState(true);

  const [addressList, setAddressList] = useState([]);
  const [filterCustomerLocation, setFilterCustomerLocation] = useState(undefined);

  const [latlngCustomer, setLatlngCustomer] = useState({});
  const [valueAddress, setValueAddress] = useState('');

  const [locationFormRef, setLocationFormRef] = useState(null);
  const [editLocationFormRef, setEditLocationFormRef] = useState(null);
  const [refModalPrivacy, setRefModalPrivacy] = useState(null);
  const [refModalEditPrivacy, setRefModalEditPrivacy] = useState(null);

  const [visibleModalFrom, setVisibleModalFrom] = useState(false);
  const [visibleModalFromEdit, setVisibleModalFromEdit] = useState(false);

  const [dataFromLocationEdit, setDataFromLocationEdit] = useState({});

  const [allFieldsValue, setAllFieldsValue] = useState({});

  const [cusAddressBookId, setCusAddressBookId] = useState(undefined);

  const [customerName, setCusShortName] = useState('');
  const [customerShortName, setCustomerShortName] = useState('');

  const [triggerApi, setTriggerApi] = useState(false);
  const [visibleModalPrivacy, setVisibleModalPrivacy] = useState(false);
  const [visibleModalEditPrivacy, setVisibleModalEditPrivacy] = useState(false);

  const [dataPrivacyType, setDataPrivacyType] = useState();
  const [dataTeam, setDataTeam] = useState();

  let getFieldsValueAll = getFieldsValue();

  useEffect(() => {
    getPrivacyType();
  }, []);

  useEffect(() => {
    setImageFileDefault(DefaultProfile);
  }, [DefaultProfile]);

  useEffect(() => {
    const getApiBusinessTypeTnfo = async () => {
      const getBusinessTypeTnfo = await getBusinessType();
      setBusinessType(getBusinessTypeTnfo);
    };
    getApiBusinessTypeTnfo();
  }, []);

  useEffect(() => {
    const getApiCustomerTypeTnfo = async () => {
      const getCustomerTypeTnfo = await getCustomerType();
      setCustomerType(getCustomerTypeTnfo);
    };
    getApiCustomerTypeTnfo();
  }, []);

  const getPrivacyType = async () => {
    const response = await PrivacyController();
    if (response.status.code == 200) {
      setDataPrivacyType(response.data.privacyTypes);
      setDataTeam(response.data.teams);
    }
  };

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        720,
        720,
        'JPEG',
        70,
        0,
        (uri) => {
          resolve(uri);
        },
        'base64'
      );
    });

  const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    for (var i = 0; i < bstr.length; i++) {
      u8arr[i] = bstr.charCodeAt(i);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const handleChangePicture = async (event) => {
    event.preventDefault();
    let reader = new FileReader();
    const file = event.target.files[0];

    if (file) {
      setVisibleText(false);
      if (file.name.match(/\.(jpg|jpeg|png|JPG|PNG|JPEG)$/)) {
        const image = await resizeFile(file);
        reader.onloadend = () => {
          const image = reader.result;
          //setImage(image);
          setImageFile(image);
        };
        reader.readAsDataURL(file);

        const fileChange = dataURLtoFile(image, file.name);
        setImageCustomer(fileChange);
      }
    }
  };

  const showOpenFileDialog = (e) => {
    imageRef.current.click();
  };

  const deleteImage = (e) => {
    setImageFile('');
    setImageCustomer();
    imageRef.current.value = null;
  };

  // -------------------------- Create Form Location Customer BY TAE---------------------------------------------

  const handleCreateFromLocation = () => {
    locationFormRef.validateFields(async (err, values) => {
      if (err) {
        return;
      }

      const body = {
        // customerId: customerID,
        // customerName: custName,
        addressName: values.locationName,
        fullAddress: values.location,
        address: values.location,
        description: values.description,
        lat: latlngCustomer.lat,
        lng: latlngCustomer.lng,
        subDistrict: '',
        province: '',
        postCode: '',
      };

      setFilterCustomerLocation(body);
      setCustomer.setCusDataBodyApi(body);
      setVisibleModalFrom(false);
      // locationFormRef.resetFields();
    });
  };

  const handleEditModalFrom = () => {
    editLocationFormRef.validateFields(async (err, values) => {
      if (err) {
        return;
      }

      if (dataFromLocationEdit && dataFromLocationEdit.customerAddressBookId !== '') {
        try {
          const body = {
            addressName: values.locationName,
            fullAddress: values.location,
            address: values.location,
            description: values.description,
            lat: latlngCustomer.lat,
            lng: latlngCustomer.lng,
            customerName: dataFromLocationEdit.customerName,
            contactName: '',
            subDistrict: '',
            district: '',
            province: '',
            postCode: '',
          };

          const response = await httpClient.put(
            `/v3/business-partner/manager/company/${comCode}/customer-address-books/${dataFromLocationEdit.customerAddressBookId}`,
            body
          );

          if (response.status === 200) {
            setTriggerApi(!triggerApi);
            successNotification(response.data.status.message);
            setLatlngCustomer({});
            setTimeout(() => {
              setCusAddressBookId(response.data.data.customerAddressBookId);
              setFilterCustomerLocation(response.data.data);
              setCustomer.setCusDataBodyApi(response.data.data);
            }, 500);
          } else {
            errorNotification(response.data.status.message);
          }
        } catch (error) {}
      }
      setVisibleModalFromEdit(false);
      editLocationFormRef.resetFields();
    });
  };

  // -------------------------- Cancel Modal Form Location Customer BY TAE---------------------------------------------

  const handleCancelModalFrom = () => {
    // setCustomerID("");
    // setCustName("");

    setLatlngCustomer({});
    setVisibleModalFrom(false);
    if (filterCustomerLocation == undefined) {
      setFilterCustomerLocation(undefined);
      locationFormRef.resetFields();
      setValueAddress(undefined);
    }
    // setFilterCustomerLocation({});
    setCustomer.setCusDataBodyApi({});
    setCustomer.setCustomerIdApi('');
    setDataFromLocationEdit({});
    // locationFormRef.resetFields();
  };

  const handleCancelEditModalFrom = () => {
    setLatlngCustomer({});
    setVisibleModalFromEdit(false);
    setValueAddress(undefined);
    setDataFromLocationEdit({});
    setCustomer.setCusDataBodyApi({});
    editLocationFormRef.resetFields();
  };

  const handleCusCode = debounce((value) => {
    setCusShortName(value);
  }, 500);

  const handleCusName = debounce((value) => {
    setCustomerShortName(value);
  }, 500);

  // const handleCusCode = debounce(value => {
  //   setTimeout(() => {
  //     setCusShortName(value);
  //   }, 3000);
  // }, 3000);

  // const handleCusName = debounce(value => {
  //   setTimeout(() => {
  //     setCustomerShortName(value);
  //   }, 3000);
  // }, 3000);

  const fncSelectFromAddress = (value) => {
    const newCustomer = addressList?.filter((ele) => ele.customerAddressBookId === value);

    setFilterCustomerLocation(newCustomer[0]);
    setCusAddressBookId(newCustomer[0].customerAddressBookId);
  };

  // -------------------------- Save Form Location Customer BY TAE---------------------------------------------

  const saveCustomerFormRef = useCallback((node) => {
    if (node !== null) {
      setLocationFormRef(node);
    }
  }, []);

  const saveEditCustomerFormRef = useCallback((node) => {
    if (node !== null) {
      setEditLocationFormRef(node);
    }
  }, []);

  const savePrivacy = useCallback((node) => {
    if (node !== null) {
      setRefModalPrivacy(node);
    }
  }, []);

  const saveEditPrivacy = useCallback((node) => {
    if (node !== null) {
      setRefModalEditPrivacy(node);
    }
  }, []);

  const handleEditFrom = () => {
    setVisibleModalFromEdit(true);
    setDataFromLocationEdit(filterCustomerLocation);
  };

  const fncOnCancel = (e) => {
    onCancel();
    setDisableSelete(true);
    setDataFromLocationEdit({});
    setFilterCustomerLocation(undefined);
    setLatlngCustomer({});
    setAddressList([]);
    setCusAddressBookId(undefined);
    locationFormRef.resetFields();
    setValueAddress(undefined);
    setVisibleModalPrivacy(false);
  };

  const fncOnCreate = () => {
    onCreate();
    locationFormRef.resetFields();
    setValueAddress(undefined);
    setTimeout(() => {
      setFilterCustomerLocation(undefined);
      setAddressList([]);
      setCusAddressBookId(undefined);
    }, 10000);
  };

  const handleCreatePrivacy = () => {
    refModalPrivacy.validateFields((err, values) => {
      if (err) {
        return;
      } else {
        setDataPrivacy(values);
        setVisibleModalPrivacy(false);
      }
    });
  };

  const handleCreateEditPrivacy = () => {
    refModalEditPrivacy.validateFields((err, values) => {
      if (err) {
        return;
      } else {
        setDataPrivacy(values);
        setVisibleModalEditPrivacy(false);
      }
    });
  };

  return (
    <CustomerContext.Provider
      value={{
        stateCustomer: {
          valueAddress,
          filterCustomerLocation,
          dataFromLocationEdit,
        },
        setStateCustomer: {
          setValueAddress,
          setDataFromLocationEdit,
        },
        fncCustomer: {
          handleCusCode,
          handleCusName,
        },
      }}
    >
      <Modal
        title={intl.formatMessage({ id: 'ModalCustomerCreateHeader', defaultMessage: 'Add New Customer' })}
        centered="true"
        visible={visible}
        onCancel={fncOnCancel}
        onOk={fncOnCreate}
        width={700}
        footer={[
          <Button02 style={{margin : '0px 0px 0px 10px'}} key="back" btnsize="wd_df" onClick={fncOnCancel}>
            <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
          </Button02>,
          <Button01 key="submit" type="primary" btnsize="wd_df" onClick={fncOnCreate}>
            <FormattedMessage id="btnSave" defaultMessage="Save" />
          </Button01>,
        ]}
      >
        <Form>
          <Row>
            <Col span={8}>
              <div className="image-container">
                <AppAvatar size={150} src={`${imageFile ? imageFile : imageFileDefault}`} />
                <input
                  ref={imageRef}
                  type="file"
                  style={{ display: 'none' }}
                  accept=".jpg, .png, .JPG, .PNG"
                  onChange={handleChangePicture}
                />
                {imageFile ? (
                  <div className="image-container after" style={{ paddingTop: 63, paddingLeft: 42, paddingRight: 42 }}>
                    <Icon type="upload" theme="outlined" style={{ fontSize: '25px' }} onClick={showOpenFileDialog} />
                    &nbsp; &nbsp;
                    <Icon type="delete" theme="outlined" style={{ fontSize: '25px' }} onClick={deleteImage} />
                  </div>
                ) : (
                  <div className="image-container after" style={{ paddingTop: 63, paddingLeft: 62, paddingRight: 62 }}>
                    <Icon type="upload" theme="outlined" style={{ fontSize: '25px' }} onClick={showOpenFileDialog} />
                  </div>
                )}
              </div>
            </Col>
            <Col span={16}>
              <Row>
                <InformationCustomer
                  form={form}
                  businessType={businessType}
                  customerType={customerType}
                  intl={intl}
                  setVisibleModalPrivacy={setVisibleModalPrivacy}
                  dataPrivacy={dataPrivacy}
                  dataPrivacyType={dataPrivacyType}
                  setVisibleModalEditPrivacy={setVisibleModalEditPrivacy}
                />
              </Row>
              <Row>
                <Divider className="divider-style-customer" />
                <div>
                  <div>
                    <p className="text-address-local">
                      <FormattedMessage id="ModalCustomerAddressText" defaultMessage="Address" />
                    </p>
                  </div>
                  <Row gutter={[16, 16]}>
                    <Col span={12}>
                      <MapLocation />
                    </Col>
                    <Col span={12}>
                      <div>
                        {filterCustomerLocation && filterCustomerLocation !== undefined ? (
                          <Form.Item className="form-line-height">
                            <div className="text-location-short">
                              <span className="span-format" style={{ color: '#1D3557' }} onClick={() => setVisibleModalFrom(true)}>
                                {_.get(filterCustomerLocation, 'addressName')}
                              </span>
                            </div>
                          </Form.Item>
                        ) : (
                          <Form.Item className="form-line-height">
                            {getFieldDecorator('addressname', {
                              // initialValue: cusAddressBookId,
                              // initialValue: filterCustomerLocation && filterCustomerLocation !== undefined ? _.get(filterCustomerLocation, 'addressName') : undefined,
                              rules: [
                                {
                                  required: true,
                                  message: intl.formatMessage({
                                    id: 'ModalCustomerValidateLocationName',
                                    defaultMessage: 'Please enter location name',
                                  }),
                                },
                              ],
                            })(
                              <Input
                                placeholder={intl.formatMessage({
                                  id: 'ModalCustomerPlacholderLocationName',
                                  defaultMessage: 'Enter Location Name',
                                })}
                                onClick={() => setVisibleModalFrom(true)}
                                className="new-input-add-user-name"
                                style={{ padding: 'unset', cursor: 'pointer' }}
                                autoComplete="off"
                              />
                            )}
                          </Form.Item>
                        )}

                        <Form.Item>
                          <div className="text-location-short2">
                            {_.get(filterCustomerLocation, 'description') ? (
                              <span className="span-format" style={{ color: '#1D3557', fontSize: '14px' }}>
                                {_.get(filterCustomerLocation, 'description')}
                              </span>
                            ) : (
                              <span className="span-format" style={{ color: '#bfbfbf', fontSize: '14px' }}>
                                <FormattedMessage id="ModalLocationLocationAddress" defaultMessage="Address" />
                              </span>
                            )}
                          </div>
                        </Form.Item>

                        <Form.Item>
                          <div>
                            <div className="text-location-long">
                              <Icon
                                type="environment"
                                style={{
                                  fontSize: '12px',
                                  color: '#1D3557',
                                  paddingLeft: '0px',
                                  paddingRight: '4px',
                                  cursor: 'pointer',
                                }}
                              />
                              {_.get(filterCustomerLocation, 'fullAddress') ? (
                                <span className="span-format" style={{ color: '#1D3557', fontSize: '14px' }}>
                                  {_.get(filterCustomerLocation, 'fullAddress')}
                                </span>
                              ) : (
                                <span className="span-format" style={{ color: '#bfbfbf', fontSize: '14px' }}>
                                  <FormattedMessage id="ModalLocationLocationMapLocation" defaultMessage="Map Location" />
                                </span>
                              )}
                            </div>
                          </div>
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Row>
            </Col>
          </Row>
        </Form>
      </Modal>

      <ModalMapLocationFrom
        ref={saveCustomerFormRef}
        visible={visibleModalFrom}
        setLatlngCustomer={setLatlngCustomer}
        onCancel={() => handleCancelModalFrom()}
        onCreate={() => handleCreateFromLocation()}
        stateCustomer={{
          valueAddress,
          filterCustomerLocation,
          dataFromLocationEdit,
        }}
        setStateCustomer={{
          setValueAddress,
          setDataFromLocationEdit,
        }}
      />

      <ModalEditMapLocationFrom
        ref={saveEditCustomerFormRef}
        visible={visibleModalFromEdit}
        setLatlngCustomer={setLatlngCustomer}
        onCancel={() => handleCancelEditModalFrom()}
        onCreate={() => handleEditModalFrom()}
        stateCustomer={{
          valueAddress,
          filterCustomerLocation,
          dataFromLocationEdit,
        }}
        setStateCustomer={{
          setValueAddress,
          setDataFromLocationEdit,
        }}
      />

      <ModalCreatePrivacy
        ref={savePrivacy}
        onCreate={() => handleCreatePrivacy()}
        visibleModalPrivacy={visibleModalPrivacy}
        setVisibleModalPrivacy={setVisibleModalPrivacy}
        dataPrivacyType={dataPrivacyType}
        dataTeam={dataTeam}
        intl={intl}
      />

      <EditModalPrivacy
        ref={saveEditPrivacy}
        onCreate={() => handleCreateEditPrivacy()}
        setVisibleModalPrivacy={setVisibleModalEditPrivacy}
        visibleModalPrivacy={visibleModalEditPrivacy}
        dataPrivacyType={dataPrivacyType}
        dataTeam={dataTeam}
        dataPrivacy={dataPrivacy}
        intl={intl}
      />
    </CustomerContext.Provider>
  );
};

const ModalForm = Form.create({ name: 'modal_form' })(ModalFormCustomer);

export default ModalForm;
