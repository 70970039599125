import { Button, Col, Divider, Icon, Input, Modal, Popover, Row, Select, Table, Form, AutoComplete, Progress } from 'antd';
import _ from 'lodash';
import React, { useState, useRef, useEffect } from 'react';
import { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { numberFormatDecimal, numberFormatNoDecimal } from '../../../component-function/fnc-number-format-decimal';
import CollapseCustom from '../../../components/collapse-custom';
import LabeRequireForm from '../../../components/label-required-form';
import { messageLabel } from '../../../components/message-component';
import Button_01 from '../../../components/v2/button_01';
import { errorNotification, successNotification } from '../../../components/v2/notification';
import { deleteWarehouseStock, getWarehouseStock, getWarehouseStockById } from '../../../controllers/warehouse/warehouse';
import WarehouseContext from '../context';
import WarehouseStockModalForm from '../warehouse-modal/warehouseModalStockForm';
import ActionColumn from './action-column';
import './css/index.css';
import WarehouseAdjustModalForm from '../warehouse-modal/warehouseModalAdjustForm';
import { PageSettings } from '../../../config/page-settings';

const { Option } = Select;
const CardWarehouseStock = () => {
  const intl = useIntl();
  const ref = useRef();
  const app = useContext(PageSettings);
  const { checkPermissionAction } = app;

  const { state, setState, fnc } = useContext(WarehouseContext);
  const { setTrigger, setEvent } = setState;
  const { warehouseServiceDataList, warehouseId, key, roleCheck } = state;

  const [isOpen, setIsopen] = useState(false);
  const [record, setRecord] = useState();
  const [visiblePopoverTable, setVisiblePopoverTable] = useState();
  const [recordPopover, setRecordPopover] = useState();
  const [offsetPopover, setOffsetPopover] = useState([0, 0]);

  const [total, setTotal] = useState();
  const [fieldSort, setFieldSort] = useState();
  const [orderSort, setOrderSort] = useState();
  const [paginationPage, setPaginationPage] = useState(1);
  const [paginationSize, setPaginationSize] = useState(10);
  const [loading, setLoading] = useState(false);
  const [searchGroup, setSearchGroup] = useState();
  const [dataStockSummary, setDataStockSummary] = useState();
  const [dataStockList, setDataStockList] = useState([]);
  const [triggerStock, setTriggerStock] = useState(false);
  // console.log(paginationPage, paginationSize);

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      fixed: 'left',
      width: 70,
    },
    {
      title: messageLabel('stockLabelItemCode', 'Item Code'),
      dataIndex: 'itemMasterDetail.itemCode',
      key: 'itemMasterDetail.itemCode',
      sorter: true,
      fixed: 'left',
      width: 140,
    },
    {
      title: messageLabel('stockLabelItem', 'Item'),
      dataIndex: 'itemMasterDetail.itemName',
      key: 'itemMasterDetail.itemName',
      sorter: true,

      width: 150,
      ellipsis: true,
    },
    {
      title: messageLabel('stockLabelQty', 'Qty'),
      dataIndex: 'qty',
      key: 'qty',
      sorter: true,

      // width: 200,
      render: (text, record, index) => renderProgress(record),
    },
    {
      title: messageLabel('stockLabelItemGroup', 'Item Group'),
      dataIndex: 'itemMasterDetail.itemGroupCode',
      key: 'itemMasterDetail.itemGroupCode',
      sorter: true,
    },
    {
      title: messageLabel('stockLabelItemType', 'Item Type'),
      dataIndex: 'itemMasterDetail.itemTypeCode',
      key: 'itemMasterDetail.itemTypeCode',
      sorter: true,
    },
    {
      title: messageLabel('stockLabelTotal', 'Total'),
      dataIndex: 'totalCost',
      key: 'totalCost',
      sorter: true,
      align: 'right',
      render: (text, record, index) => _.get(record, 'totalCost'),
    },
    {
      title: messageLabel('stockLabelAvg', 'Avg Cost'),
      dataIndex: 'avgCost',
      key: 'avgCost',
      sorter: true,
      align: 'right',
      render: (text, record, index) => _.get(record, 'avgCost'),
    },
    {
      title: messageLabel('stockLabelMinQty', 'Min Qty'),
      dataIndex: 'minQty',
      key: 'minQty',
      sorter: true,
      width: 120,
      render: (text, record, index) => numberFormatNoDecimal(_.get(record, 'minQty')),
    },
    {
      title: messageLabel('stockLabelMaxQty', 'Max Qty'),
      dataIndex: 'maxQty',
      key: 'maxQty',
      sorter: true,
      width: 120,
      render: (text, record, index) => numberFormatNoDecimal(_.get(record, 'maxQty')),
    },
    {
      title: messageLabel('stockLabelDimension', 'Dimension'),
      dataIndex: 'dimension',
      key: 'dimension',
      sorter: true,
    },
    {
      title: messageLabel('stockLabelWeight', 'Weight (kg)'),
      dataIndex: 'weight',
      key: 'weight',
      sorter: true,
      width: 180,
      render: (text, record, index) => numberFormatDecimal(_.get(record, 'weight')),
    },
    {
      title: messageLabel('stockLabelLeadTime', 'Lead Time'),
      dataIndex: 'leadTime',
      key: 'leadTime',
      sorter: true,
      width: 180,
      render: (text, record, index) => numberFormatNoDecimal(_.get(record, 'leadTime')),
    },
    {
      title: messageLabel('stockLabelVendorOrSupplier', 'Vendor or Supplier'),
      dataIndex: 'vendorSupplier',
      key: 'vendorSupplier',
      sorter: true,
      width: 200,
    },
  ];

  const renderProgress = (record) => {
    let color = '';
    let percent = 0;

    if (record.qty === 0) {
      color = '#e73845';
      percent = 100;
    } else if (record.qty < record.minQty) {
      color = '#febb02';
      percent = (record.qty / record.maxQty) * 100;
    } else if (record.qty > record.minQty) {
      color = '#6490cf';
      percent = (record.qty / record.maxQty) * 100;
    }

    return (
      <div className="progress-item">
        <Progress className="bar-progress-custom" style={{width: '50%'}} strokeColor={color} percent={percent} showInfo={false} />{' '}
        <span className="progress-qty-value" style={{width: '150px'}}>{record.qtyDisplay}</span>
      </div>
    );
  };

  const status = [
    {
      value: 'reorder',
      name: messageLabel('stockReorder', 'Re-order'),
    },
    {
      value: 'outstock',
      name: messageLabel('stockOutofStock', 'Out of Stock'),
    },
    {
      value: 'instock',
      name: messageLabel('stockInStock', 'In Stock'),
    },
  ];

  const setShowColumn = {
    index: true,
    'itemMasterDetail.itemCode': true,
    'itemMasterDetail.itemName': true,
    qty: true,
    'itemMasterDetail.itemGroupCode': false,
    'itemMasterDetail.itemTypeCode': false,
    totalCost: true,
    avgCost: true,
    minQty: true,
    maxQty: true,
    dimension: true,
    weight: true,
    leadTime: true,
    vendorSupplier: false,
  };

  const setShowColumnArr = [
    'index',
    'itemMasterDetail.itemCode',
    'itemMasterDetail.itemName',
    'qty',
    'totalCost',
    'avgCost',
    'minQty',
    'maxQty',
    'dimension',
    'weight',
    'leadTime',
  ];

  const ignoreColumn = ['itemMasterDetail.itemGroupCode', 'itemMasterDetail.itemTypeCode', 'vendorSupplier'];
  const newColumns = columns.filter((col) => !ignoreColumn.includes(col.key));

  const [newDataColumns, setNewDataColumns] = useState([...newColumns]);
  const [defaultShowColumn, setDefaultShowColumn] = useState({ ...setShowColumn });
  const [listArrayColumns, setListArrayColumns] = useState([...setShowColumnArr]);
  const [scrollTableX, setScrollTableX] = useState(2000);
  const [textErrorSelectColumn, setTextErrorSelectColumn] = useState('');
  const [visible, setVisible] = useState(false);
  const [visibleAdjust, setVisibleAdjust] = useState(false);
  const [defaultAdjust, setDefaultAdjust] = useState();

  useEffect(() => {
    if (warehouseId) {
      setFieldSort();
      setOrderSort();
      setPaginationPage(1);
      setPaginationSize(10);
      setLoading(false);
      setSearchGroup();
    }
  }, [warehouseId]);

  useEffect(() => {
    setLoading(true);
    const getApi = async () => {
      let search = {};

      if (_.get(searchGroup, 'code') || _.get(searchGroup, 'name') || _.get(searchGroup, 'status')) {
        search['itemMasterDetail.itemCode'] = _.get(searchGroup, 'code');
        search['itemMasterDetail.itemName'] = _.get(searchGroup, 'name');
        search['status'] = _.get(searchGroup, 'status');
      } else {
        search = {};
      }
      const payload = {
        warehouseId: warehouseId, //warehouseId
        search: search,
        pageNumber: paginationPage,
        limit: paginationSize,
        orderBy: fieldSort,
        orderType: orderSort,
      };
      const response = await getWarehouseStock(payload);
      setDataStockList(_.get(response.data.data, 'itemList'));
      setDataStockSummary(_.get(response.data.data, 'summary'));
      setTotal(_.get(response.data.data, 'summary.totalItems'));
      setLoading(false);
    };

    if (warehouseId && key === 'stock') {
      getApi();
    }
  }, [warehouseId, paginationPage, paginationSize, fieldSort, orderSort, searchGroup, triggerStock, key]);

  const openModal = () => {
    setIsopen(true);
  };

  const openModalEdit = async (record) => {
    const stockId = _.get(record, 'stockId');
    if (stockId) {
      const response = await getWarehouseStockById(stockId);
      setRecord(_.get(response.data, 'data'));
      setIsopen(true);
    }
  };

  const openModalAdjust = (value) => {
    setVisibleAdjust(true);
    setDefaultAdjust(value);
  };

  const handleDelete = (id) => {
    Modal.confirm({
      className: 'maintenance-report-modal-confirm',
      centered: true,
      icon: null,
      title: intl.formatMessage({ id: 'lblDelete', defaultMessage: 'Are you sure you want to delete ?' }),
      okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        try {
          const response = await deleteWarehouseStock(id);
          if (response.status === 200) {
            successNotification(_.get(response.data.status, 'message'));
            setTrigger((current) => !current);
            setTriggerStock((current) => !current);
            setEvent((current) => !current);
          } else {
            errorNotification(_.get(response.data.status, 'message'));
          }
        } catch (error) {
          errorNotification(_.get(error.response.data.status, 'message'));
        }
      },
      onCancel() {},
    });
  };

  const handleMouseLeave = () => {
    setVisiblePopoverTable(false);
    setRecordPopover();
    setOffsetPopover([0, 0]);
  };

  const fncActionTable = (record, offsetPopover, visiblePopoverTable, ref) => {
    return (
      <div>
        <Popover
          placement="right"
          trigger="hover"
          align={{ offset: offsetPopover }}
          visible={visiblePopoverTable}
          zIndex={10000}
          content={
            <div style={{ display: 'grid' }}>
              {checkPermissionAction('P52PG1C4', 'P52PG1C4A3') ? (
                <div>
                  <Button style={{ width: 100 }} type="link" ghost onClick={() => openModalEdit(record)}>
                    <FormattedMessage id="orgTabMemberBtnEdit" defaultMessage="Edit" />
                  </Button>
                </div>
              ) : null}
              {checkPermissionAction('P52PG1C4', 'P52PG1C4A4') ? (
                <div>
                  <Button style={{ width: 100 }} type="link" ghost onClick={() => openModalAdjust(record)}>
                    <FormattedMessage id="btnAdjust" defaultMessage="Adjust" />
                  </Button>
                </div>
              ) : null}
              {checkPermissionAction('P52PG1C4', 'P52PG1C4A5') ? (
                <div>
                  <Button style={{ width: 100 }} type="link" ghost onClick={() => handleDelete(record.stockId)}>
                    <FormattedMessage id="btnDelete" defaultMessage="Delete" />
                  </Button>
                </div>
              ) : null}
            </div>
          }
        >
          <div style={{ width: 0, height: 0 }} ref={ref}></div>
        </Popover>
      </div>
    );
  };

  const SummaryItem = ({ value = '', text = '', color = '#505' }) => {
    return (
      <div className="layout-summary-item">
        <p style={{ color: color }} className="layout-summary-item-value">
          {value}
        </p>
        <p style={{ color: color }} className="layout-summary-item-text">
          {text}
        </p>
      </div>
    );
  };

  const onChange = (value, code) => {
    setSearchGroup({ ...searchGroup, [code]: value });
    setPaginationPage(1);
  };

  const formFilterStock = () => {
    return (
      <Form colon={false}>
        <Row gutter={[24, 2]}>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'warehouseModalItemCode', defaultMessage: 'Item Code' })} req={false} />
              }
            >
              <Input
                allowClear
                value={_.get(searchGroup, 'code')}
                onChange={(e) => onChange(e.target.value, 'code')}
                placeholder={intl.formatMessage({ id: 'ModalLocationPlaceholderItemCode', defaultMessage: 'Enter Item Code' })}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'warehouseModalItemName', defaultMessage: 'Item Name' })} req={false} />
              }
            >
              <Input
                allowClear
                value={_.get(searchGroup, 'name')}
                onChange={(e) => onChange(e.target.value, 'name')}
                placeholder={intl.formatMessage({ id: 'ModalLocationPlaceholderItemName', defaultMessage: 'Enter Item Name' })}
              />
            </Form.Item>
          </Col>
          <Col span={8}></Col>
        </Row>
        <Row gutter={[24, 2]}>
          <Col span={8}>
            <Form.Item
              label={<LabeRequireForm text={intl.formatMessage({ id: 'warehouseModalSatus', defaultMessage: 'Status' })} req={false} />}
            >
              <Select
                allowClear
                value={_.get(searchGroup, 'status')}
                onChange={(value) => onChange(value, 'status')}
                placeholder={intl.formatMessage({ id: 'ModalLocationPlaceholderStatus', defaultMessage: 'Select Status' })}
              >
                {status.map((item) => (
                  <Option key={item.value} value={item.value}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}></Col>
          <Col span={8}></Col>
        </Row>
      </Form>
    );
  };

  const handleChange = (pagination, sorter, extra) => {
    let field = extra.field;
    if (extra.order) {
      if (extra.order === 'descend') {
        setOrderSort('desc');
      } else {
        setOrderSort('asc');
      }
    } else {
      setOrderSort('');
    }
    setFieldSort(field);
    setPaginationSize(pagination.pageSize);
    setPaginationPage(pagination.current);
  };

  const showTotal = (total) => {
    return (
      <span style={{ fontSize: '13px' }}>
        <FormattedMessage id="lblRowPerPage" defaultMessage="Rows per page: " />
      </span>
    );
  };

  return (
    <div className="warehouse-layout-content warehouse-tabs-details scroll-sm">
      <div className="layout-summary">
        <SummaryItem
          value={_.get(dataStockSummary, 'totalValue')}
          text={intl.formatMessage({ id: `warehouseStockSummaryTotal`, defaultMessage: 'Total Cost In Stock' })}
          color="#050505"
        />
        <SummaryItem
          value={_.get(dataStockSummary, 'totalItems')}
          text={intl.formatMessage({ id: `warehouseStockSummaryTotalItems`, defaultMessage: 'Total Items' })}
          color="#1d3557"
        />
        <SummaryItem
          value={_.get(dataStockSummary, 'reorder')}
          text={intl.formatMessage({ id: `warehouseStockSummaryReorder`, defaultMessage: 'Re-Order' })}
          color="#f88512"
        />
        <SummaryItem
          value={_.get(dataStockSummary, 'outStock')}
          text={intl.formatMessage({ id: `warehouseStockSummaryOutOfStock`, defaultMessage: 'Out of Stock' })}
          color="#e73845"
        />
      </div>
      <div>
        <CollapseCustom
          label={intl.formatMessage({ id: `btnFilter`, defaultMessage: 'Filter' })}
          extra={
            <div style={{ display: 'flex' }}>
              <ActionColumn
                columns={columns}
                setShowColumn={setShowColumn}
                newDataColumns={newDataColumns}
                listArrayColumns={listArrayColumns}
                defaultShowColumn={defaultShowColumn}
                newColumns={newColumns}
                setNewDataColumns={setNewDataColumns}
                setListArrayColumns={setListArrayColumns}
                setDefaultShowColumn={setDefaultShowColumn}
                textErrorSelectColumn={textErrorSelectColumn}
                setTextErrorSelectColumn={setTextErrorSelectColumn}
                visible={visible}
                setVisible={setVisible}
              />
              {checkPermissionAction('P52PG1C4', 'P52PG1C4A2') ? (
                roleCheck ? (
                  <Button_01 key="submit" type="primary" btnsize="wd_df" style={{ marginRight: 'unset' }} onClick={() => openModal()}>
                    <FormattedMessage id="btnAdd" defaultMessage="Add" />
                  </Button_01>
                ) : null
              ) : null}
            </div>
          }
        >
          {formFilterStock()}
        </CollapseCustom>
      </div>
      <div className="warehouse-layout-stock" onMouseLeave={handleMouseLeave}>
        <Table
          className="table-global-scroll"
          rowKey={(record) => record.stockId}
          dataSource={dataStockList}
          scroll={_.size(dataStockList) ? { x: true } : { x: 'max-content' }}
          columns={newDataColumns}
          onChange={handleChange}
          loading={loading}
          pagination={{
            total: total,
            current: paginationPage,
            showTotal: showTotal,
            defaultCurrent: 1,
            pageSizeOptions: ['10', '20', '30', '40', '50'],
            showSizeChanger: true,
            locale: { items_per_page: '' },
          }}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                if (
                  checkPermissionAction('P52PG1C4', 'P52PG1C4A3') ||
                  checkPermissionAction('P52PG1C4', 'P52PG1C4A4') ||
                  checkPermissionAction('P52PG1C4', 'P52PG1C4A5')
                ) {
                  if (!event.target.href && roleCheck) {
                    const { x, y } = ref.current.getBoundingClientRect();
                    setVisiblePopoverTable(true);
                    setOffsetPopover([event.pageX - x, event.pageY - y]);
                    setRecordPopover(record);
                  }
                }
              },
            };
          }}
        />
        {roleCheck ? fncActionTable(recordPopover, offsetPopover, visiblePopoverTable, ref) : null}
        {_.size(dataStockList) > 0 ? (
          <div className="total-items-member">
            <span style={{ fontSize: '13px' }}>
              <FormattedMessage id="lblTotal" defaultMessage="Total" />
              {` ${total || 0} `}
              <FormattedMessage id="lblitems" defaultMessage="items" />
            </span>
          </div>
        ) : null}
      </div>
      <WarehouseStockModalForm
        visible={isOpen}
        setVisible={setIsopen}
        setTriggerStock={setTriggerStock}
        setRecord={setRecord}
        record={record}
      />

      <WarehouseAdjustModalForm
        visible={visibleAdjust}
        setVisible={setVisibleAdjust}
        defaultAdjust={defaultAdjust}
        warehouseId={warehouseId}
        setTrigger={setTriggerStock}
      />
    </div>
  );
};

export default CardWarehouseStock;
