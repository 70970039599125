import React, { useRef, useState } from 'react';
import './css/index.css';
import { Card, Table, Popover, Button, Icon, Modal } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import Button01 from '../../../components/v2/button_01';
import { PageSettings } from '../../../config/page-settings';
import FromAddress from '../../../components/modal-create-from-address-map/index';
import FromAddressEdit from '../../../components/modal-edit-from-address-map/index';
import deletedFromAddress from '../../../controllers/company-profile/deleted-from-address';
import _ from 'lodash';
import { successNotification, errorNotification, warningNotification } from '../../../components/v2/notification';

const { confirm } = Modal;

export default (props) => {
  const { addressList, setTriggerApi, handleChangePage, handleChangeSize, isLoading } = props;
  const intl = useIntl();

  const [visiblePopover, setVisiblePopover] = useState(false);
  const [visiblePopoverTable, setVisiblePopoverTable] = useState();
  const [recordPopover, setRecordPopover] = useState();
  const [offsetPopover, setOffsetPopover] = useState([0, 0]);
  const [scrollTableX, setScrollTableX] = useState(1000);
  const totalItems = _.get(addressList, 'length') || 0;
  const ref = useRef();

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: '2%',
      align: 'center',
      // render: (text, record, index) => index + 1,
    },
    {
      title: intl.formatMessage({ id: 'companyProfileColumnsAddressName', defaultMessage: 'Address Name' }),
      dataIndex: 'addressName',
      key: 'addressName',
      width: '10%',
    },
    {
      title: intl.formatMessage({ id: 'companyProfileColumnsAddress', defaultMessage: 'Address' }),
      dataIndex: 'fullAdress',
      key: 'fullAdress',
      width: '30%',
    },
    // {
    //   title: intl.formatMessage({ id: 'companyProfileColumnsMapLocation', defaultMessage: 'Map Location' }),
    //   dataIndex: 'mapLocation',
    //   key: 'mapLocation',
    //   width: '30%',
    // },
  ];

  const [visibleModalCreate, setVisibleModalCreate] = useState(false);
  const [visibleModalEdit, setVisibleModalEdit] = useState(false);
  const [valuEdit, setValuEdit] = useState();

  const deletedAddress = async (record) => {
    const langValue = localStorage.getItem('langValue');
    const comId = localStorage.getItem('comId');
    // console.log("langValue",langValue);
    // console.log("compid",comId);

    confirm({
      className: 'customer-modal-confirm',
      centered: true,
      icon: null,
      title: intl.formatMessage({ id: 'companyProfileDeleteAddress', defaultMessage: 'Are you sure to delete address ?' }),
      okText: intl.formatMessage({ id: 'modalBtnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'modalBtnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        try {
          const response = await deletedFromAddress(record.companyAddressBookId);
          if (_.size(response) !== 0) {
            setTriggerApi((curent) => !curent);
            successNotification(response.status.message);
          }
        } catch (error) {
          return;
        }
      },
      onCancel() {
        return;
      },
    });
  };

  const handleOnVisiblePopover = (visiblePopover) => {
    setVisiblePopover(visiblePopover);
  };

  const fncActionTable = (record, offsetPopover, visiblePopoverTable, ref) => {
    return (
      <div>
        <Popover
          placement="right"
          trigger="hover"
          align={{ offset: offsetPopover }}
          visible={visiblePopoverTable}
          zIndex={10000}
          content={
            <div style={{ display: 'grid' }}>
              <>
                <PageSettings.Consumer>
                  {({ checkPermissionAction }) => (
                    <div>
                      {checkPermissionAction('P19PG1C2', 'P19PG1C2A3') ? (
                        <Button
                          style={{ width: 100 }}
                          type="link"
                          ghost
                          onClick={() => {
                            setVisibleModalEdit(true);
                            setValuEdit(record);
                          }}
                        >
                          <FormattedMessage id="btnEdit" defaultMessage="Edit" />
                        </Button>
                      ) : null}
                    </div>
                  )}
                </PageSettings.Consumer>
              </>

              <>
                <PageSettings.Consumer>
                  {({ checkPermissionAction }) => (
                    <div>
                      {checkPermissionAction('P19PG1C2', 'P19PG1C2A4') ? (
                        <Button style={{ width: 100 }} type="link" ghost onClick={() => deletedAddress(record)}>
                          <FormattedMessage id="btnDelete" defaultMessage="Delete" />
                        </Button>
                      ) : null}
                    </div>
                  )}
                </PageSettings.Consumer>
              </>
            </div>
          }
        >
          <div style={{ width: 0, height: 0 }} ref={ref}></div>
        </Popover>
      </div>
    );
  };

  // const fncActionTable = (record, index) => {
  //   return (
  //     <div>
  //       <Popover
  //         key={index}
  //         placement="leftTop"
  //         trigger="hover"
  //         visiblePopover={visiblePopover}
  //         onVisibleChange={handleOnVisiblePopover}
  //         zIndex={999}
  //         content={
  //           <div style={{ display: 'grid' }}>
  //             <>
  //               <PageSettings.Consumer>
  //                 {({ checkPermissionAction }) => (
  //                   <div>
  //                     {checkPermissionAction('P19PG1C2', 'P19PG1C2A3') ? (
  //                       <Button
  //                         style={{ width: 100 }}
  //                         type="link"
  //                         ghost
  //                         onClick={() => {
  //                           setVisibleModalEdit(true);
  //                           setValuEdit(record);
  //                         }}
  //                       >
  //                         <FormattedMessage id="btnEdit" defaultMessage="Edit" />
  //                       </Button>
  //                     ) : null}
  //                   </div>
  //                 )}
  //               </PageSettings.Consumer>
  //             </>

  //             <>
  //               <PageSettings.Consumer>
  //                 {({ checkPermissionAction }) => (
  //                   <div>
  //                     {checkPermissionAction('P19PG1C2', 'P19PG1C2A4') ? (
  //                       <Button style={{ width: 100 }} type="link" ghost onClick={() => deletedAddress(record)}>
  //                         <FormattedMessage id="btnDelete" defaultMessage="Delete" />
  //                       </Button>
  //                     ) : null}
  //                   </div>
  //                 )}
  //               </PageSettings.Consumer>
  //             </>
  //           </div>
  //         }
  //       >
  //         <Icon type="more" />
  //       </Popover>
  //     </div>
  //   );
  // };

  const showTotal = () => {
    return (
      <span style={{ fontSize: '13px' }}>
        <FormattedMessage id="lblRowPerPage" defaultMessage="Rows per page: " />
      </span>
    );
  };

  const handleMouseLeave = () => {
    setVisiblePopoverTable(false);
    setRecordPopover();
    setOffsetPopover([0, 0]);
  };

  return (
    <PageSettings.Consumer>
      {({ checkPermissionAction }) =>
        checkPermissionAction('P19PG1C2', 'P19PG1C2A1') ? (
          <div>
            <Card
              title={intl.formatMessage({ id: 'companyProfileTextHeaderAddressBook', defaultMessage: 'Address Book' })}
              className="company-address-height-card"
              extra={
                checkPermissionAction('P19PG1C2', 'P19PG1C2A2') ? (
                  <Button01 type="primary" styleMargin="mg_cs3" onClick={() => setVisibleModalCreate(true)}>
                    <FormattedMessage id="btnAdd" defaultMessage="Add" />
                  </Button01>
                ) : null
              }
            >
              <div onMouseLeave={handleMouseLeave}>
                <Table
                  className="company-address-table-address"
                  columns={columns}
                  dataSource={addressList}
                  scroll={{ x: scrollTableX, y: `calc(100vh - 373px)` }}
                  loading={isLoading}
                  pagination={{
                    total: totalItems,
                    defaultCurrent: 1,
                    pageSizeOptions: ['10', '20', '30', '40', '50'],
                    showSizeChanger: true,
                    locale: { items_per_page: '' },
                    showTotal: showTotal,
                    onChange: handleChangePage,
                    onShowSizeChange: handleChangeSize,
                  }}
                  onRow={(record, rowIndex) => {
                    return {
                      onClick: (event) => {
                        const { x, y } = ref.current.getBoundingClientRect();
                        setVisiblePopoverTable(true);
                        setOffsetPopover([event.pageX - x, event.pageY - y]);
                        setRecordPopover(record);
                      },
                    };
                  }}
                />
                {fncActionTable(recordPopover, offsetPopover, visiblePopoverTable, ref)}
                <div className="total-items-member">
                  <span style={{ fontSize: '13px' }}>
                    <FormattedMessage id="lblTotal" defaultMessage="Total" />
                    {` ${totalItems || 0} `}
                    <FormattedMessage id="lblitems" defaultMessage="items" />
                  </span>
                </div>
              </div>
            </Card>

            <FromAddress visible={visibleModalCreate} setVisible={setVisibleModalCreate} setTrigger={setTriggerApi} />

            <FromAddressEdit
              visible={visibleModalEdit}
              setVisible={setVisibleModalEdit}
              editData={valuEdit}
              setEditData={setValuEdit}
              setTrigger={setTriggerApi}
            />
          </div>
        ) : null
      }
    </PageSettings.Consumer>
  );
};
