
const pathUrt = (pathName) => {
  const path = window.location.pathname;
  const patt = new RegExp(pathName);
  const res = patt.test(path);
  return res;
}


export default pathUrt;
