import React, { useState, useEffect } from 'react'
import { AutoComplete, Form } from 'antd'
import { useIntl } from 'react-intl'
import _ from 'lodash'
import LabeRequireForm from '../label-required-form'
import { getSaleOrderList } from '../../controllers/sale-order/sale-order-api-new'

const { Option } = AutoComplete

const LoadmoreSaleOrder = (props) => {
  const intl = useIntl()
  const { form, data, setData, required, initialId, initialName, visible, title, placeholder } = props
  const getFieldDecorator = form ? form.getFieldDecorator : null
  const [dataInfo, setDataInfo] = useState({})
  const [dataInfoMaster, setDataInfoMaster] = useState({})
  const [list, setList] = useState([])
  const [listMaster, setListMaster] = useState([])
  

  const [page, setPage] = useState(1)
  const [search, setSearch] = useState("")
  const [firstStep, setFirstStep] = useState(false)

  // ยังไม่ได้ทำผ่าน form

  useEffect(() => {
    if (initialId && visible) {
      setSearch(initialName)
      setFirstStep(true)
    } else {
      setSearch("")
      setFirstStep(false)
      setDataInfo({})
      setDataInfoMaster({})
      setList([])
      setListMaster([])
      setPage(1)
    }
  }, [initialId, visible])


  useEffect(() => {
    if (firstStep) getSaleOrderData()
  }, [page, firstStep])


  const getSaleOrderData = async () => {
    const body = {
      tab: 'approved',
      paging: page,
      rowsPerPages: 20,
      filter: {
        saleOrderNo: search,
      }
    }
    try {
      const response = await getSaleOrderList(body)
      const res = _.get(response, 'data')
      setDataInfo(res);
      setDataInfoMaster(res)
      setList([...list, ..._.get(res, 'data', [])]);
      setListMaster([...listMaster, ..._.get(res, 'data', [])])
    } catch (error) {
    }

  }

  const handleSelect = (value, option) => {
    if (form) {
      form.setFieldsValue({ customer: value });
      setData(_.get(option, 'props.data'));
    } else {
      setData((prev) => ({ ...prev, saleOrder: value }))
    }
  }

  const handleChange = (value) => {
    const find = _.find(list, (item) => { return item.saleOrderNo === value || item.saleOrderId === value })
    setData((prev) => ({ ...prev, saleOrder: find, saleOrderSearch: value }))
  }

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    if (scrollTop + clientHeight >= scrollHeight) {
      if (_.get(dataInfo, 'total') !== _.size(list)) setPage(page + 1)
    }
  };

  const handleSearch = _.debounce(async (value) => {
    setSearch(value)
    if (value && value !== "") {
      const body = {
        tab: 'approved',
        paging: 1,
        rowsPerPages: 20,
        filter: {
          saleOrderNo: value,
        }
      }
      const response = await getSaleOrderList(body)
      const res = _.get(response, 'data')
      setDataInfo(res);
      setList(_.get(res, 'data', []));
    } else {
      setDataInfo(dataInfoMaster)
      setList(listMaster)
    }
  }, 500)

  // const handleBlurForm = () => {
  //   const selectedValue = getFieldValue('saleOrderNo');
  //   const isValid = _.some(saleOrderList, (item) => item.saleOrderId === selectedValue);
  //   if (!isValid) {
  //     setFieldsValue({ saleOrderNo: undefined });
  //     setSelectSaleOrder();
  //   }
  // };

  const handleBlur = () => {
    const selectedValue = _.get(data, 'saleOrderSearch')
    const isValid = _.some(list, (item) => item.saleOrderId === selectedValue)
    if (!isValid) {
      setData((prev) => ({ ...prev, saleOrder: undefined, saleOrderSearch: undefined }))
      setSearch('')
      setDataInfo(dataInfoMaster)
      setList(listMaster)
    }
  }


  return (
    <Form.Item onClick={() => setFirstStep(true)} label={
      <LabeRequireForm
        text={title}
        req={required}
      />
    }>
      {
        form ? getFieldDecorator('saleOrder', {
          initialValue: initialId,
          rules: [
            {
              required: required,
              message: intl.formatMessage({
                id: 'saleOrderValidate',
                defaultMessage: 'Please Select Sale Order',
              }),
            },
          ],
        })(
          <AutoComplete
            onSelect={handleSelect}
            onPopupScroll={handleScroll}
            onSearch={handleSearch}
            placeholder={placeholder}
          >
            {
              _.map(list, (item) => (
                <Option key={_.get(item, 'saleOrderId')} value={_.get(item, 'saleOrderId')} data={item}>
                  {_.get(item, 'saleOrderNo')}
                </Option>
              ))
            }
          </AutoComplete>
        )
          : (
            <AutoComplete
              // onSelect={handleSelect}
              onChange={handleChange}
              onPopupScroll={handleScroll}
              onSearch={handleSearch}
              placeholder={placeholder}
              value={_.get(data, 'saleOrderSearch', "")}
              allowClear={true}
              onBlur={handleBlur}
            >
              {
                _.map(list, (item) => (
                  <Option key={_.get(item, 'saleOrderId')} value={_.get(item, 'saleOrderId')} data={item}>
                    {_.get(item, 'saleOrderNo')}
                  </Option>
                ))
              }
            </AutoComplete>
          )
      }

    </Form.Item>
  )
}

LoadmoreSaleOrder.defaultProps = {
  required: true,
}


export default LoadmoreSaleOrder
