import React, { useContext, useEffect, useState } from 'react';
import { Icon, Avatar, Badge } from 'antd';
import '../css/assignee.css';
import { TaskDetail } from '../../task-detail-context';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import AssigneeModal from './assignee-modal';

export default () => {
  const { stateTask, setStateTask, fncStateTask } = useContext(TaskDetail);

  useEffect(() => {
    const mapAssignee = _.get(stateTask, 'assignee').map((item) => {
      return item.memComId;
    });
    setStateTask.setKeys(mapAssignee);
  }, [_.get(stateTask, 'assignee')]);

  const NoAssignee = () => {
    return (
      <div>
        {stateTask.statusView ? (
          <AddAssignee />
        ) : (
          <div className="task-detail-assignee-padding-div">
            <div style={{ marginBottom: '14px' }}>
              <Icon type="team" className="task-detail-assignee-icon" />
            </div>
            <div>
              <div>
                <span style={{ color: '#BCC5D3' }}>
                  <FormattedMessage id="taskDetailAssigneeNoData1" defaultMessage="No Assignee" />
                </span>
              </div>
              <div>
                <span style={{ color: '#BCC5D3' }}>
                  <FormattedMessage id="taskDetailAssigneeNoData2" defaultMessage="Edit a task to add assignees" />
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };
  const handleSelect = (memComId) => {
    const thisKeys = [...stateTask.keys];

    const findIndexMemComId = _.findIndex(thisKeys, (val) => {
      return val === memComId ? true : false;
    });
    if (findIndexMemComId > -1) {
      thisKeys.splice(findIndexMemComId, 1);
    } else {
      thisKeys.push(memComId);
    }
    setStateTask.setKeys(thisKeys);
  };

  const assigneeDetailData =
    _.get(stateTask, 'assignee') &&
    _.get(stateTask, 'assignee').map((item) => {
      const findIndexMemComId = _.findIndex(stateTask.keys, (val) => {
        return val === item.memComId ? true : false;
      });
      return (
        <div className="task-detail-assignee-padding-value">
          <div
            className={item.active === 0 ? 'task-detail-assignee-detail ' : 'task-detail-assignee-detail-disable'}
            onClick={() => handleSelect(item.memComId)}
          >
            <Badge
              className="badge-style-member"
              count={
                findIndexMemComId > -1 ? (
                  <Icon type="check-circle" theme="filled" style={{ color: '#1D3557' }} className="icon-correct-member" />
                ) : null
              }
            >
              <Avatar size="large" shape="circle" src={item.pathImage} />
            </Badge>
          </div>
          <div style={{ marginLeft: '20px' }}>
            <div className="task-detail-assignee-text-name">{item.fullname}</div>
            <div className="task-detail-assignee-text-phone">{item.phone || '-'}</div>
            {item.subtask &&
              item.subtask.map((item, i) => {
                const iconExcel = () => {
                  if (item.done == true) {
                    return <img key={i} className="table-status-img" src={item.icon} />;
                  } else {
                    return <img key={i} className="table-status-img" src={item.icon} style={{ filter: 'grayscale(100%)', opacity: 0.5 }} />;
                  }
                };

                return (
                  <div className="table-status">
                    <div key={i} className="table-status-key">
                      <Icon className="table-status-icon" component={iconExcel} />
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      );
    });

  const AddAssignee = () => {
    return (
      <div>
        {stateTask.statusView ? (
          <div className="task-detail-assignee-icon-plus" onClick={() => setStateTask.setVisibleAssignee(true)}>
            <Avatar icon="plus" size="large" style={{ marginRight: '16px' }} />
            <div>
              <p className="name-p">
                <p>
                  <FormattedMessage id="taskDetailAssigneeAddText" defaultMessage="Add or Remove Assignee" />
                </p>
              </p>
            </div>
          </div>
        ) : null}
      </div>
    );
  };

  return (
    <div className="task-detail-assignee-padding">
      <span className="task-detail-assignee-text ">
        <FormattedMessage id="taskDetailAssigneeText" defaultMessage="Assignee" />
      </span>
      {_.size(stateTask.assignee) > 0 ? (
        <div style={{ marginTop: '15px' }}>
          {assigneeDetailData}
          <AddAssignee />
        </div>
      ) : (
        <NoAssignee />
      )}
      <AssigneeModal />
    </div>
  );
};
