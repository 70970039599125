import React, { useState, useRef, useEffect } from 'react';
import { Row, Col, Modal, Button, Card, Icon, Form, Input, DatePicker, Select, Divider } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';

import Button01 from '../../../../components/v2/button_01';
import Button02 from '../../../../components/v2/button_02';
import AppAvatar from '../../../../components/avatar';
import css from '../../user/add-user/css/index.css';
import styled from 'styled-components';
import PositionModalForm from '../../../../components/action-position-name/position-modal.js';
import TaskIcon from '../../../../components/image/TaskBlack.svg';
import LineIcon from '../../../../components/image/line.svg';
import DefaultProfile from '../../../../components/image/Profile_Default.png';
import { addNewUserControl, getGenderAndPosition } from './controller/controller-api.js';
import Resizer from 'react-image-file-resizer';
import { successNotification, errorNotification, warningNotification } from '../../../../components/v2/notification';

const { Option } = Select;
const { confirm } = Modal;

export default React.memo(
  Form.create({ name: 'add_use_form' })((props) => {
    const intl = useIntl();

    const { getFieldDecorator, validateFields, resetFields, getFieldValue, setFieldsValue } = props.form;
    const imageRef = useRef();
    const [visible, setVisible] = useState(false);
    const [visibleText, setVisibleText] = useState(false);
    const [imageFile, setImageFile] = useState();
    const [imageFileDefault, setImageFileDefault] = useState('');
    const [newImage, setNewImage] = useState();
    const [dataDropDown, setDataDropDown] = useState([]);

    const [isOpenModalPosition, setIsOpenModalPosition] = useState(false);
    const [triggerPositionApi, setTriggerPositionApi] = useState(false);
    const [positionMasterData, setPositionMasterData] = useState([]);
    const [teamnMasterData, setTeamMasterData] = useState([]);
    const [employeeMasterData, setEmployeeMasterData] = useState([]);
    const [seletePositionData, setSeletePositionData] = useState({});
    const [statusOpen, setStatusOpen] = useState();

    const comCode = localStorage.getItem('comCode');

    useEffect(() => {
      setImageFileDefault(DefaultProfile);
    }, [DefaultProfile]);

    useEffect(() => {
      getGenderAndPosition(comCode, setDataDropDown);
    }, [comCode, triggerPositionApi]);

    const showModal = () => {
      setVisible(!visible);
      setDataDropDown(dataDropDown);
      setVisibleText(false);
    };

    const closeModal = () => {
      setVisible();
      resetFields();
      setImageFile('');
      setNewImage();
      imageRef.current.value = null;
    };

    const addDataUser = () => {
      validateFields((err, values) => {
        if (!imageFile && visibleText === false) {
          setVisibleText(!visibleText);
          return false;
        }

        // if (visibleText === true) {
        //   return true
        // } else {
        if (err) {
          return false;
        } else {
          confirm({
            className: 'add-modal-confirm',
            title: 'Do you want to add this user?',
            okText: intl.formatMessage({ id: 'settingHolidaysModalBtnConfirm', defaultMessage: 'Confirm' }),
            centered: true,
            icon: 'none',
            okButtonProps: {
              style: {
                width: '96px',
              },
              type: 'primary',
            },
            cancelButtonProps: {
              style: {
                width: '96px',
              },
              type: 'danger',
            },
            cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
            async onOk() {
              const resposne = await addNewUserControl(values, newImage, comCode);
              if (resposne.status === 200) {
                props.setRefreshTable(true);
                setVisible();
                resetFields();
                setImageFile('');
                imageRef.current.value = null;
                setNewImage();
              } else {
                props.setRefreshTable(true);
              }
              props.setRefreshTable(false);
            },
            onCancel() {},
          });
        }
        // }
      });
    };

    const [genderIcon, setGenderIcon] = useState('male');
    const onGenderChange = (e) => {
      setGenderIcon(e);
    };

    const onPositionChange = (e) => {};
    const BagIcon = () => <img src={TaskIcon} style={{ width: 14 }}></img>;
    const LineIconn = () => <img src={LineIcon} style={{ width: 14 }}></img>;

    const resizeFile = (file) =>
      new Promise((resolve) => {
        Resizer.imageFileResizer(
          file,
          720,
          720,
          'JPEG',
          70,
          0,
          (uri) => {
            resolve(uri);
          },
          'base64'
        );
      });

    const dataURLtoFile = (dataurl, filename) => {
      const arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      for (var i = 0; i < bstr.length; i++) {
        u8arr[i] = bstr.charCodeAt(i);
      }
      return new File([u8arr], filename, { type: mime });
    };

    const handleChangePicture = async (event) => {
      event.preventDefault();
      let reader = new FileReader();
      const file = event.target.files[0];

      if (file) {
        setVisibleText(false);
        if (file.name.match(/\.(jpg|jpeg|png|JPG|PNG|JPEG)$/)) {
          const image = await resizeFile(file);

          reader.onloadend = () => {
            const image = reader.result;
            //setImage(image);
            setImageFile(image);
          };
          reader.readAsDataURL(file);

          const fileChange = dataURLtoFile(image, file.name);
          setNewImage(fileChange);
        } else {
          errorNotification(intl.formatMessage({ id: 'UserManagementAddImageFail', defaultMessage: 'This file is not supported' }));
        }
      }

      ////////////////////////////////////////////////
      // const file = event.target.files[0];

      // if (file) {
      //   setVisibleText(false);
      //   //setImageFile(URL.createObjectURL(file));

      //   if (file.name.match(/\.(jpg|jpeg|png|JPG|PNG|JPEG)$/)) {
      //     const image = await resizeFile(file);

      //     const fileChange = dataURLtoFile(image, file.name);
      //     setNewImage(fileChange);

      //   }
      // }
    };

    const showOpenFileDialog = (e) => {
      imageRef.current.click();
    };

    const deleteImage = (e) => {
      setImageFile('');
      setNewImage();
      setVisibleText(true);
      imageRef.current.value = null;
    };

    const handleKeyDownEmpcode = (e) => {
      let keyCode = e.key.charCodeAt(0);
      if (
        (keyCode >= 48 && keyCode <= 57) ||
        (keyCode >= 65 && keyCode <= 90) ||
        (keyCode >= 97 && keyCode <= 122) ||
        keyCode === 65 ||
        keyCode === 66
      ) {
        return true;
      } else {
        e.preventDefault();
      }
    };

    const handleKeyDownPhone = (e) => {
      let keyCode = e.key.charCodeAt(0);
      if ((keyCode >= 48 && keyCode <= 57) || keyCode === 65 || keyCode === 32 || keyCode === 66) {
        return true;
      } else {
        e.preventDefault();
      }
    };

    const handleKeyDownEmail = (e) => {
      let keyCode = e.key.charCodeAt(0);
      if (
        (keyCode >= 48 && keyCode <= 57) ||
        (keyCode >= 65 && keyCode <= 90) ||
        (keyCode >= 97 && keyCode <= 122) ||
        keyCode === 65 ||
        keyCode === 66 ||
        keyCode === 95 ||
        keyCode === 45 ||
        keyCode === 64 ||
        keyCode === 46
      ) {
        return true;
      } else {
        e.preventDefault();
      }
    };

    const handleKeyDownName = (e) => {
      let keyCode = e.key.charCodeAt(0);
      if ((keyCode >= 3585 && keyCode <= 3662) || (keyCode >= 65 && keyCode <= 90) || (keyCode >= 97 && keyCode <= 122) || keyCode === 32) {
        return true;
      } else {
        e.preventDefault();
      }
    };

    const handleAddPosition = () => {
      setSeletePositionData({});
      setStatusOpen('add');
      setIsOpenModalPosition(true);
    };

    const handleEditPosition = (positionId) => {
      const filterPosition = dataDropDown.positionMaster.filter((item) => item.positionId.toString() === positionId.toString());

      setSeletePositionData(filterPosition[0]);
      setStatusOpen('edit');
      setIsOpenModalPosition(true);
    };

    return (
      <div>
        <Button01 type="primary" fontsize="wd_at" onClick={showModal}>
          <FormattedMessage id="UserManagementAddNewUserTxtButton" defaultMessage="Add User" />
        </Button01>
        <Modal
          title={intl.formatMessage({ id: 'UserManagementAddNewUserTiltle', defaultMessage: 'Add New User' })}
          width={780}
          visible={visible}
          onCancel={closeModal}
          onOk={addDataUser}
          centered
          footer={[
            <Button02 style={{margin : '0px 0px 0px 10px'}} key="back" btnsize="wd_df" onClick={closeModal}>
              <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
            </Button02>,
            <Button01 key="submit" type="primary" btnsize="wd_df" onClick={addDataUser}>
              <FormattedMessage id="btnSave" defaultMessage="Save" />
            </Button01>,
          ]}
        >
          <Form>
            <Row>
              <Col span={8}>
                <div className="image-container">
                  <AppAvatar
                    size={150}
                    //icon={imageFile ? 'user' : ''}
                    src={`${imageFile ? imageFile : imageFileDefault}`}
                  />

                  <input
                    ref={imageRef}
                    type="file"
                    style={{ display: 'none' }}
                    accept=".jpg, .png, .JPG, .PNG"
                    onChange={handleChangePicture}
                  />
                  {imageFile ? (
                    <div className="image-container after" style={{ paddingTop: 63, paddingLeft: 42, paddingRight: 42 }}>
                      <Icon type="upload" theme="outlined" style={{ fontSize: '25px' }} onClick={showOpenFileDialog} />
                      &nbsp; &nbsp;
                      <Icon type="delete" theme="outlined" style={{ fontSize: '25px' }} onClick={deleteImage} />
                    </div>
                  ) : (
                    <div className="image-container after" style={{ paddingTop: 63, paddingLeft: 62, paddingRight: 62 }}>
                      <Icon type="upload" theme="outlined" style={{ fontSize: '25px' }} onClick={showOpenFileDialog} />
                    </div>
                  )}
                  {/* {visibleText ? <span style={{ color: '#f5222d' }}>
                  <FormattedMessage id="UserManagementAddNewUserUpdloadImage" defaultMessage="Please upload profile" />
                </span> : ''} */}
                </div>
              </Col>
              <Col span={16}>
                <Row pan={12}>
                  <div className="icon-input">
                    <Form.Item className="css-validate">
                      {getFieldDecorator('name', {
                        rules: [
                          {
                            required: true,
                            message: intl.formatMessage({
                              id: 'UserManagementAddNewUserValidateName',
                              defaultMessage: 'Please input your Name',
                            }),
                          },
                        ],
                      })(
                        <Input
                          size="large"
                          className="new-input-add-user-name"
                          onKeyDown={handleKeyDownName}
                          placeholder={intl.formatMessage({ id: 'UserManagementAddNewUserPlaceHolderName', defaultMessage: 'Enter Name' })}
                          autoComplete="off"
                        />
                      )}
                    </Form.Item>
                  </div>
                </Row>
                <Row pan={12}></Row>
                <Row>
                  <Col span={12}>
                    <div className="icon-input">
                      <Icon type="solution" className="style-icon" />
                      <Form.Item>
                        {getFieldDecorator('empId', {
                          rules: [
                            {
                              required: true,
                              message: intl.formatMessage({
                                id: 'UserManagementAddNewUserValidateEmployeeId',
                                defaultMessage: 'Please input Employee ID',
                              }),
                            },
                          ],
                        })(
                          <Input
                            className="new-input-add-user"
                            type="text"
                            placeholder={intl.formatMessage({
                              id: 'UserManagementAddNewUserPlaceHolderEmployeeID',
                              defaultMessage: 'Enter Employee ID',
                            })}
                            onKeyDown={handleKeyDownEmpcode}
                            autoComplete="off"
                          />
                        )}
                      </Form.Item>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className="icon-input">
                      <Icon type="calendar" className="style-icon" />
                      <Form.Item>
                        {getFieldDecorator('startDate', {
                          rules: [
                            {
                              required: true,
                              message: intl.formatMessage({
                                id: 'UserManagementAddNewUserValidateDate',
                                defaultMessage: 'Please select start Date',
                              }),
                            },
                          ],
                        })(
                          <DatePicker
                            placeholder={intl.formatMessage({
                              id: 'UserManagementAddNewUserPlaceHolderDate',
                              defaultMessage: 'Select Start Date',
                            })}
                            className="birthday-startwork"
                            dropdownClassName="addUser-dropdown-datePicker"
                            allowClear={false}
                            format="ddd, MMM DD YYYY"
                            suffixIcon
                          />
                        )}
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <div className="icon-input">
                      <Icon type="mail" className="style-icon" />
                      <Form.Item>
                        {getFieldDecorator('mail', {
                          rules: [
                            {
                              // required: true,
                              type: 'email',
                              pattern: '/^w+([.-]?w+)*@w+([.-]?w+)*(.w{2,3})+$/',
                              message: intl.formatMessage({
                                id: 'UserManagementAddNewUserValidateEmailWrong',
                                defaultMessage: 'Please fill in the email format.',
                              }),
                            },
                          ],
                        })(
                          <Input
                            className="new-input-add-user"
                            placeholder={intl.formatMessage({
                              id: 'UserManagementAddNewUserPlaceHolderEmail',
                              defaultMessage: 'Enter Email',
                            })}
                            type="text"
                            onKeyDown={handleKeyDownEmail}
                            autoComplete="off"
                          />
                        )}
                      </Form.Item>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className="icon-input">
                      <Icon type="phone" className="style-icon" />
                      <Form.Item
                      // validateStatus={errorWord ? 'error' : 'success'}
                      // help={errorWord ? 'please input 6 charactos' : ''}
                      >
                        {getFieldDecorator('phone', {
                          rules: [
                            {
                              required: true,
                              message: intl.formatMessage({
                                id: 'UserManagementAddNewUserValidatePhoneNumber',
                                defaultMessage: 'Please input Phone Number',
                              }),
                            },
                            {
                              min: 6,
                              message: intl.formatMessage({
                                id: 'UserManagementAddNewUserValidateInputCharactor',
                                defaultMessage: 'Numbers cannot be less than 6 characters.',
                              }),
                            },
                            // {
                            //   validator: validateMobileNumber
                            // }
                          ],
                        })(
                          <Input
                            className="new-input-add-user"
                            placeholder={intl.formatMessage({
                              id: 'UserManagementAddNewUserPlaceHolderPhoneNumber',
                              defaultMessage: 'Enter Phone Number',
                            })}
                            type="text"
                            onKeyDown={handleKeyDownPhone}
                            autoComplete="off"
                            //onChange={validateMobileNumber}
                          />
                        )}
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <div className="icon-input">
                      <Icon component={LineIconn} className="style-icon" />
                      <Form.Item>
                        {getFieldDecorator('lineId', {
                          rules: [
                            {
                              required: false,
                              message: intl.formatMessage({
                                id: 'UserManagementAddNewUserValidateInputLineID',
                                defaultMessage: 'Please input Line ID',
                              }),
                            },
                          ],
                        })(
                          <Input
                            className="new-input-add-user"
                            placeholder={intl.formatMessage({
                              id: 'UserManagementAddNewUserPlaceHolderLineID',
                              defaultMessage: 'Enter Line ID',
                            })}
                            type="text"
                            autoComplete="off"
                          />
                        )}
                      </Form.Item>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className="icon-input">
                      <Icon type="facebook" className="style-icon" />
                      <Form.Item>
                        {getFieldDecorator('facebook', {
                          rules: [
                            {
                              required: false,
                              message: intl.formatMessage({
                                id: 'UserManagementAddNewUserValidateInputFaceBook',
                                defaultMessage: 'Please input Facebook',
                              }),
                            },
                          ],
                        })(
                          <Input
                            className="new-input-add-user"
                            placeholder={intl.formatMessage({
                              id: 'UserManagementAddNewUserPlaceHolderFaceBook',
                              defaultMessage: 'Enter Facebook',
                            })}
                            type="text"
                            autoComplete="off"
                          />
                        )}
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <div className="icon-input">
                      <Icon component={BagIcon} className="style-icon" />
                      <Form.Item>
                        {getFieldDecorator('position', {
                          rules: [
                            {
                              required: false,
                              message: intl.formatMessage({
                                id: 'UserManagementAddNewUserValidatePosition',
                                defaultMessage: 'Please input your Position',
                              }),
                            },
                          ],
                        })(
                          <Select
                            className="select-hidden-border"
                            placeholder={intl.formatMessage({
                              id: 'UserManagementAddNewUserPlaceHolderPosition',
                              defaultMessage: 'Select Position',
                            })}
                            onChange={(e) => onPositionChange(e)}
                            optionLabelProp="label"
                            allowClear
                            showSearch
                            filterOption={(input, option) =>
                              option.props.label
                                .toString()
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            dropdownRender={(menu) => (
                              <div className="assign-team-modal-dropdownRender">
                                {menu}
                                <Divider style={{ margin: '4px 0' }} />
                                <div
                                  style={{ padding: '12px 16px', cursor: 'pointer', color: '#1D3557' }}
                                  onMouseDown={(e) => e.preventDefault()}
                                  onClick={handleAddPosition}
                                >
                                  Add New Position
                                </div>
                              </div>
                            )}
                          >
                            {dataDropDown.length !== 0 && dataDropDown.positionMaster
                              ? dataDropDown.positionMaster.map((item) => (
                                  <Option className="assign-team-modal-option" key={item.positionId} label={item.positionName}>
                                    <Row>
                                      <Col className="assign-team-modal-option-text" span={21}>
                                        <span>{item.positionName}</span>
                                      </Col>
                                      <Col span={3}>
                                        <span onClick={() => handleEditPosition(item.positionId)}>Edit</span>
                                      </Col>
                                    </Row>
                                  </Option>
                                ))
                              : null}
                          </Select>
                        )}
                      </Form.Item>
                    </div>
                  </Col>
                  <Col span={12} />
                </Row>
                <Divider />
                <Row>
                  <Col span={12}>
                    <div className="icon-input">
                      <Icon type="idcard" className="style-icon" />
                      <Form.Item>
                        {getFieldDecorator('id', {
                          rules: [
                            {
                              required: true,
                              message: intl.formatMessage({
                                id: 'UserManagementAddNewUserValidateNationID',
                                defaultMessage: 'Please input your ID',
                              }),
                            },
                            {
                              max: 13,
                              message: intl.formatMessage({
                                id: 'UserManagementAddNewUserValidateInputCharactorId',
                                defaultMessage: 'Numbers can not be more than 13 characters.',
                              }),
                            },
                          ],
                        })(
                          <Input
                            className="new-input-add-user"
                            placeholder={intl.formatMessage({
                              id: 'UserManagementAddNewUserPlaceHolderNationID',
                              defaultMessage: 'Enter Civil National ID',
                            })}
                            type="text"
                            onKeyDown={handleKeyDownEmpcode}
                            autoComplete="off"
                          />
                        )}
                      </Form.Item>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className="icon-input">
                      <Icon type="gift" className="style-icon" />
                      <Form.Item>
                        {getFieldDecorator('birthDate', {
                          rules: [
                            {
                              required: false,
                              message: intl.formatMessage({
                                id: 'UserManagementAddNewUserValidateBirthDate',
                                defaultMessage: 'Please input birthDate',
                              }),
                            },
                          ],
                        })(
                          <DatePicker
                            className="birthday-startwork"
                            dropdownClassName="addUser-dropdown-datePicker"
                            allowClear={true}
                            format="DD/MM/YYYY"
                            suffixIcon
                            placeholder={intl.formatMessage({
                              id: 'UserManagementAddNewUserPlaceHolderBirthDate',
                              defaultMessage: 'Select Birthday Date',
                            })}
                          />
                        )}
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <div className="icon-input">
                      <Icon type={genderIcon.toLowerCase() === 'male' || genderIcon === 'ชาย' ? 'man' : 'woman'} className="style-icon" />
                      <Form.Item>
                        {getFieldDecorator('gender', {
                          rules: [
                            {
                              required: true,
                              message: intl.formatMessage({
                                id: 'UserManagementAddNewUserValidateYourName',
                                defaultMessage: 'Please input your Name',
                              }),
                            },
                          ],
                        })(
                          <Select
                            className="select-hidden-border"
                            placeholder={intl.formatMessage({
                              id: 'UserManagementAddNewUserPlaceHolderGender',
                              defaultMessage: 'Select Gender',
                            })}
                            onChange={(e) => onGenderChange(e)}
                          >
                            {dataDropDown.length !== 0 &&
                              dataDropDown.gender.map((e, i) => (
                                <Option key={e.key} value={e.key}>
                                  {e.genderName}
                                </Option>
                              ))}
                          </Select>
                        )}
                      </Form.Item>
                    </div>
                  </Col>
                  <Col span={12} />
                </Row>
              </Col>
            </Row>
          </Form>
        </Modal>

        <PositionModalForm
          statusOpen={statusOpen}
          isOpenModalPosition={isOpenModalPosition}
          setIsOpenModalPosition={setIsOpenModalPosition}
          seletePositionData={seletePositionData}
          setTriggerPositionApi={setTriggerPositionApi}
          setFieldsValue={setFieldsValue}
        />
      </div>
    );
  })
);
