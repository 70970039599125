import React, { useEffect, useState } from 'react'
import style from './css/summary-invoice.css'
import { Row, Col, Input, Select } from 'antd'
import _ from 'lodash';
import CalculateInvoice from './calculate-invoice'
import { useIntl, FormattedMessage } from 'react-intl';


const { TextArea } = Input;
const { Option } = Select;
export default ({ tax,
  discount,
  dataSelection,
  setRemark,

  subTotal,
  totalPrice,
  taxPrice,
  discountPrice,
  subTotalLessPrice,

  setSubTotal,
  setTotalPrice,
  setDiscountPrice,
  setTaxPrice,
  setSubTotalLessPrice,

  setTaxID,
  setDiscountID,
  refTax,
  remark,
  textBath,
  setTextBath,
  data,

  taxID,
  discountID,
  triggerID,

  saveFormRefDiscount,

  PersentTax,
  setPersentTax,
  PersentDiscount,
  setPersentDiscount,
}) => {

  const intl = useIntl()

  useEffect(() => {
    setRemark(_.get(data, 'invoiceRemark'))
  }, [_.get(data, 'invoiceRemark')])

  const handleOnChange = (e) => {
    setRemark(e.target.value)
  }




  return (
    <div className='div-style-summary'>
      <Row>
        <Col span={12}>
          <p><FormattedMessage id="modalInvoiceSummaryRemarkText" defaultMessage="Remark" /></p>
          <TextArea rows={5} onChange={(e) => handleOnChange(e)} autoSize={{ minRows: 5, maxRows: 5 }} value={remark && remark !== undefined ? remark : ''}
            maxLength={250}
          />
        </Col>
        <Col span={12} className='align-text-center'>

          <CalculateInvoice
            discount={discount}
            refTax={refTax}
            data={data}
            tax={tax}
            textBath={textBath}
            setSubTotal={setSubTotal}
            dataSelection={dataSelection}
            subTotal={subTotal}
            totalPrice={totalPrice}
            taxPrice={taxPrice}
            discountPrice={discountPrice}
            subTotalLessPrice={subTotalLessPrice}
            setTotalPrice={setTotalPrice}
            setDiscountPrice={setDiscountPrice}
            setTaxPrice={setTaxPrice}
            setSubTotalLessPrice={setSubTotalLessPrice}
            setDiscountID={setDiscountID}
            setTextBath={setTextBath}
            setTaxID={setTaxID}
            discountID={discountID}
            taxID={taxID}
            triggerID={triggerID}
            saveFormRefDiscount={saveFormRefDiscount}
            PersentTax={PersentTax}
            setPersentTax={setPersentTax}
            PersentDiscount={PersentDiscount}
            setPersentDiscount={setPersentDiscount}
          />


        </Col>
      </Row>
    </div>
  )
}
