import React, { useContext, useState } from 'react';
import { Modal, Form, Input } from 'antd';
import Button01 from '../../../../../v2/button_01'
import Button02 from '../../../../../v2/button_02'
import { TaskDetail } from '../../task-detail-context';
import httpClient from '../../../../../axiosClient';
import {
  successNotification,
  errorNotification,
} from '../../../../../v2/notification';
import _ from 'lodash';
import { useIntl, FormattedMessage } from 'react-intl';
import '../css/status.css'

const { TextArea } = Input;


const FormComment = React.memo((props) => {
  const intl = useIntl();
  const { stateTask, setStateTask } = useContext(TaskDetail);
  const { visibleComment, statusValue, setVisibleComment, setStatusValue, setTriggerApi } = props;
  const { getFieldDecorator, validateFields, resetFields } = props.form;
  const [disabledButton, setDisabledButton] = useState(false)

  const mainTaskID = _.get(stateTask, 'taskId');


  const handleSave = () => {
    validateFields(async (err, values, rule) => {
      if (err) {
        return false;
      }
      setDisabledButton(true)
      if (statusValue.value) {
        try {
          const body = {
            status_id: statusValue.value,
            comment: values.comment
          }
          const response = await httpClient.post(`/v3/task/manager/taskinform/update/taskid/${mainTaskID}`, body);
          if (response.status === 200) {
            setStateTask.setTriggerTaskDetail(current => !current)
            successNotification(response.data.message);
            setVisibleComment(false);
            setTriggerApi(current => !current);
            resetFields();
            setDisabledButton(false)
          } else {
            errorNotification(response.data.message);
            setVisibleComment(false);
            resetFields();
          }
        } catch (error) {
          errorNotification(error.response.data.message);
          setVisibleComment(false);
          resetFields();
        }
      }
    });
  }

  const handleCancel = () => {
    setVisibleComment(false);
    setStatusValue(null);
    resetFields();
    setDisabledButton(false)
  };

  const LabeRequire = (props) => {
    const { text, req } = props;
    return (
      <span>
        {text}&nbsp;
        {req ? <span style={{ fontSize: '13px', color: '#ff1010' }}>*</span> : ''}
      </span>
    );
  };

  return (
    <div>
      <Modal
        title={intl.formatMessage({ id: 'monitorTabDetailModalCommentStatusTitle', defaultMessage: 'Change Status' })}
        centered
        visible={visibleComment}
        okText={intl.formatMessage({ id: 'btnSave', defaultMessage: 'Save' })}
        onOk={handleSave}
        onCancel={handleCancel}
        cancelText={intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' })}
        className='modal-date-time'
        width={550}
        bodyStyle={{ height: '200px', padding: '0px 0px 0px 24px' }}
        footer={[
          <Button02 style={{margin : '0px 0px 0px 10px'}} key="back" btnsize='wd_df' onClick={handleCancel}>
            <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
          </Button02>,
          <Button01 key="submit" type="primary" btnsize='wd_df'
            onClick={handleSave}
            disabled={disabledButton}
          >
            <FormattedMessage id="btnSave" defaultMessage="Save" />
          </Button01>
        ]}
      >
        <Form
          className="task-detail-status-label"
        >
          <p style={{ paddingTop: '25px' }}>
            <FormattedMessage id="monitorTabDetailModalCommentStatusFormTitle"
              defaultMessage="To change status, please give a status changing reason." />
          </p>
          <Form.Item
            label={
              <LabeRequire text={intl.formatMessage({ id: 'monitorTabDetailModalCommentTitle', defaultMessage: 'Comment' })} req={true} />
            }
            style={{ fontSize: '13px' }}
          >
            {getFieldDecorator('comment', {
              rules: [
                {
                  required: true,
                  message: intl.formatMessage({ id: 'monitorTabDetailModalCommentStatusValidateForm', defaultMessage: 'Please input Comment!' })
                }
              ],
            })(
              <TextArea rows={3} style={{ width: '500px' }} autoSize={{ minRows: 3, maxRows: 3 }} />
            )}
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
});


const ModalFormComment = Form.create({ name: 'modal_comment' })(FormComment)
export default ModalFormComment;