import React, { useState, useEffect, createContext, useContext } from 'react';
import httpClient from '../../components/axiosClient';

const RoleContext = createContext();

const RoleProvider = (props) => {
  const { children } = props;
  const comId = localStorage.getItem('comId');
  const memComId = localStorage.getItem('memComId');
  const [roles, setRoles] = useState([]);
  const [rolesSelect, setRolesSelect] = useState('');
  const [rolesAddNew, setRolesAddNew] = useState('');
  const [rolesSelectName, setRolesSelectName] = useState('');
  const [triggerGetApi , setTriggerGetApi] = useState(false);
  const [roleTracking, setRoleTracking] = useState();
  const [refreshSwitch, setRefreshSwitch] = useState(true)
  const [alamRequeset, setAlamRequeset] = useState()
  const [alamTask, setAlamTask] = useState()
  const [alamRequestSwitch, setAlamRequestSwitch] = useState(false);
  const [alamTaskSwitch, setAlamTaskSwitch] = useState(false);
  const [alamRoleTracking, setAlamRoleTracking] = useState(false);



  useEffect(() => {
    const getRole = async () => {
      try {
        const response = await httpClient.get(`/company/${comId}/role`);
        if(response.status === 200) {
          if(rolesSelectName) {
            const found = response.data.data.findIndex(elements => elements.name === rolesSelectName);
            if(found !== -1) {
              setRolesSelect(response.data.data[found].per_gro_id.toString());

            }
          }
          setRoles(response.data.data);
        }
        else {
        }
      }
      catch (error) {
      }
    }
    getRole();
  }, [rolesSelectName, triggerGetApi]);

  return (
    <RoleContext.Provider
      value={{
        state: {
          comId,
          memComId,
          roles,
          rolesSelect,
          rolesAddNew,
          rolesSelectName,
          triggerGetApi,
          roleTracking,
          refreshSwitch,
          alamRequeset,
          alamTask,
          alamRequestSwitch,
          alamTaskSwitch,
          alamRoleTracking
        },
        fnc: {
          setRolesSelect,
          setRolesAddNew,
          setRolesSelectName,
          setTriggerGetApi,
          setRoleTracking,
          setRefreshSwitch,
          setAlamTask,
          setAlamRequeset,
          setAlamRequestSwitch,
          setAlamTaskSwitch,
          setAlamRoleTracking
        }
      }}
    >
      {children}
    </RoleContext.Provider>
  );
};

const useRoleContext = () => useContext(RoleContext);

export {
  RoleProvider,
  useRoleContext
}
