import React, { useState, useEffect } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import {
  Button,
  Row,
  Menu,
  Col,
  DatePicker,
  Select,
  List,
  Icon,
  Dropdown,
  Checkbox,
  ConfigProvider,
} from 'antd';
import Input from '../../../components/v2/input';
import Button01 from '../../../components/v2/button_01';
import Button02 from '../../../components/v2/button_02';
import moment from 'moment';
import enUS from 'antd/es/locale/en_US';
import thTH from 'antd/es/locale/th_TH';
import 'moment/locale/th';
import cssStyle from './css/index.css';
import cssStyleCenter from '../report-center.css';

const { Option } = Select;
const { MonthPicker, RangePicker } = DatePicker;

export default (props) => {
  const {
    onChange,
    onSelect,
    selectSearchData,
    langValue,
    labelShow,
    onSelectMonthPicker,
    onSelectRangePicker,
    columns,
    checkedValuecolumns,
    checked,
    handleReset,
    handleCheckOk,
    visible,
    handleOnVisiblecolumns,
    handleVisibleChange,
    textErrorSelectColumn,
  } = props;
  const intl = useIntl();
  const ignoreColumn = ['index', 'employeeCode', 'name', 'team'];
  const columnNew = columns.filter((col) => !ignoreColumn.includes(col.key));

  const ignoreSearchColumn = ['employeeCode', 'name', 'team', 'shiftName', 'status', 'approverClockIn', 'approverClockOut'];
  const selectSearchColumn = columns.filter((col) =>
    ignoreSearchColumn.includes(col.key),
  );

  const [locale, setLocale] = useState(enUS);

  const monthFormat = 'MMMM YYYY';
  const dateFormat = 'DD/MM/YYYY';
  const date = moment().format('YYYY-MM-DD');
  const today = new Date();
  const startDate = moment(today).startOf("month").format(dateFormat)
  const endDate = moment(today).endOf("month").format(dateFormat)

  useEffect(() => {
    changeLocale();
  }, []);

  const disabledDate = (current) => {
    if (langValue === 'EN') {
      return current && current > moment().endOf('day');
    } else {
      return (
        current &&
        current >
        moment()
          .add(543, 'year')
          .endOf('day')
      );
    }
  };

  const changeLocale = () => {
    let codeLang = langValue ? langValue : 'EN';
    let changeLang = codeLang === 'EN' ? enUS : thTH;

    setLocale(changeLang);
    if (!changeLang) {
      moment.locale('en');
    } else {
      moment.locale('th-th');
    }
  };

  const menuColumn = () => {
    return (
      <Menu>
        <Menu.Item style={{ backgroundColor: '#ffffff' }}>
          {columnNew.map((item, i) => (
            <div key={i} style={{ display: 'block', marginBottom: '10px' }}>
              <Checkbox
                value={item.key}
                onChange={checkedValuecolumns}
                checked={checked[item.key] ? true : false}
              >
                <span style={{ fontSize: '13px' }}>{item.title}</span>
              </Checkbox>
            </div>
          ))}
        </Menu.Item>

        <Menu.Item style={{ color: '#FF1C00', textAlign: 'left', backgroundColor: '#ffffff' }}>
          {textErrorSelectColumn}
        </Menu.Item>

        <Menu.Item style={{ backgroundColor: '#ffffff' }}>
          <Button.Group style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              onClick={handleCheckOk}
              style={{ width: '50%', fontSize: '13px' }}
            >
              <FormattedMessage id="btnOK" defaultMessage="OK" />
            </Button>
            <Button
              onClick={handleReset}
              style={{ width: '50%', fontSize: '13px' }}
            >
              <FormattedMessage id="btnReset" defaultMessage="Reset" />
            </Button>
          </Button.Group>
        </Menu.Item>
      </Menu>
    );
  };

  return (
    <div>
      <Row>
        <Col span={12} style={{ margin: 0 }}>
          <Row>
            <List.Item className="time-attendance-list-left">
              <Input
                className="time-attendance-input action-input"
                onChange={(e) => onChange(e.target.value)}
                placeholder={intl.formatMessage({
                  id: 'hintSearch',
                  defaultMessage: 'Search',
                })}
                prefix={<Icon style={{ color: "rgba(0,0,0,.25)" }} type="search" />}
                autoComplete="off"
              />
              <Select
                className="time-attendance-select action-select"
                placeholder={
                  <FormattedMessage id="hintSelect" defaultMessage="Select" />
                }
                onSelect={(value) => onSelect(value)}
                value={selectSearchData}
              >
                <Option value="all">
                  {
                    <FormattedMessage
                      id="reportTimeAttendanceHintSeleteAllColumns"
                      defaultMessage="All columns"
                    />
                  }
                </Option>
                {selectSearchColumn.map((item, i) => (
                  <Option key={i} value={item.key}>
                    {item.title}
                  </Option>
                ))}
              </Select>
            </List.Item>
          </Row>
        </Col>
        <Col span={12}>
          <Row>
            <List.Item className="time-attendance-list-right">
              <ConfigProvider locale={locale}>
                <RangePicker
                  className="report-rangePicker"
                  onChange={onSelectRangePicker}
                  defaultValue={
                    langValue === 'EN'
                      ? [moment(startDate, dateFormat), moment(endDate, dateFormat)]
                      : [moment(startDate, dateFormat), moment(endDate, dateFormat)]
                  }
                  format={dateFormat}
                  allowClear={false}
                  size="small"
                />
                {/* <MonthPicker
                  className="report-monthPicker-new"
                  onChange={onSelectMonthPicker}
                  allowClear={false}
                  size="small"
                  defaultValue={moment(today, 'YYYY/MM')}
                /> */}
              </ConfigProvider>

              <Dropdown
                className="time-attendance-dropdown"
                overlay={menuColumn}
                trigger={['click']}
                onVisibleChange={handleVisibleChange}
                visible={visible}
                placement="bottomRight"
              >
                <Button01
                  type="primary"
                  btnsize="wd_at"
                  fontSize="sm"
                  onClick={handleOnVisiblecolumns}
                >
                  <FormattedMessage id="reportTimeAttendanceBtnColumns" defaultMessage="Columns" />{" "}
                  <Icon type="down" />
                </Button01>
              </Dropdown>
            </List.Item>
          </Row>
        </Col>
      </Row>
    </div>
  );
};
