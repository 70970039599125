import httpClient from '../../components/axiosClient';
import qs from 'qs';

const comCode = localStorage.getItem('comCode');

const getWareHouseList = async (payload) => {
  const { indexStart, indexEnd, searchLeftMenu, notWarehouseId,isNotCheckPermission } = payload;
  try {
    const query = {
      indexStart: indexStart,
      indexEnd: indexEnd,
      searchLeftMenu: searchLeftMenu,
      notWarehouseId: notWarehouseId,
      isNotCheckPermission: isNotCheckPermission,
    };

    const response = await httpClient.get(`/v1/warehouse/manager/company/${comCode}/web/warehouse-leftmenu-new?${qs.stringify(query)}`);
    if (response.status === 200) {
      return response;
    } else {
      return false;
    }
  } catch (error) {
    return error.response;
  }
};

const getWareHouseDetail = async (payload) => {
  const { warehouseId } = payload;
  try {
    const response = await httpClient.get(`/v1/warehouse/manager/company/${comCode}/web/warehouse-detail/${warehouseId}`);
    if (response.status === 200) {
      return response;
    } else {
      return false;
    }
  } catch (error) {
    return error.response;
  }
};

const deleteWareHouse = async (warehouseId) => {
  try {
    const response = await httpClient.delete(`/v1/warehouse/manager/company/${comCode}/web/warehouse-detail/${warehouseId}`);
    if (response.status === 200) {
      return response;
    } else {
      return false;
    }
  } catch (error) {
    return error.response;
  }
};

const getItemsMaster = async (payload) => {
  const { name, indexStart, indexEnd, categoryCode } = payload;
  const loadmore = indexStart === 0 && indexEnd > 0 ? `&indexStart=${indexStart}&indexEnd=${indexEnd}` : '';
  const category = categoryCode ? `&categoryCode=${categoryCode}` : '';
  try {
    const response = await httpClient.get(
      `/v1/warehouse/manager/company/${comCode}/web/itemmaster-search/itemName?searchName=${name}${loadmore}${category}`
    );
    if (response.status === 200) {
      return response;
    } else {
      return false;
    }
  } catch (error) {
    return error.response;
  }
};

const getItemsMasterNew = async (payload) => {
  try {
    const response = await httpClient.post(`/v1/warehouse/manager/company/${comCode}/web/itemmaster-search/itemName`, payload);
    if (response.status === 200) {
      return response;
    } else {
      return false;
    }
  } catch (error) {
    return error.response;
  }
};

const getItemsCode = async (payload) => {
  const { code, indexStart, indexEnd, categoryCode } = payload;
  const loadmore = indexStart === 0 && indexEnd > 0 ? `&indexStart=${indexStart}&indexEnd=${indexEnd}` : '';
  const category = categoryCode ? `&categoryCode=${categoryCode}` : '';

  try {
    const response = await httpClient.get(
      `/v1/warehouse/manager/company/${comCode}/web/itemmaster-search/itemCode?searchName=${code}${loadmore}${category}`
    );
    if (response.status === 200) {
      return response;
    } else {
      return false;
    }
  } catch (error) {
    return error.response;
  }
};

const getItemsCodeNew = async (payload) => {
  try {
    const response = await httpClient.post(`/v1/warehouse/manager/company/${comCode}/web/itemmaster-search/itemCode`, payload);
    if (response.status === 200) {
      return response;
    } else {
      return false;
    }
  } catch (error) {
    return error.response;
  }
};

const saveWareHouse = async (payload) => {
  try {
    const response = await httpClient.post(`/v1/warehouse/manager/company/${comCode}/web/warehouse-detail`, payload);
    if (response.status === 200) {
      return response;
    } else {
      return false;
    }
  } catch (error) {
    return error.response;
  }
};

const updateWareHouse = async (id, payload) => {
  try {
    const response = await httpClient.put(`/v1/warehouse/manager/company/${comCode}/web/warehouse-detail/${id}`, payload);
    if (response.status === 200) {
      return response;
    } else {
      return false;
    }
  } catch (error) {
    return error.response;
  }
};

const uploadImageWarehouse = async (payload) => {
  try {
    const response = await httpClient.post(`/v1/warehouse/manager/company/${comCode}/web/warehouse-img`, payload);
    if (response.status === 200) {
      return response;
    } else {
      return false;
    }
  } catch (error) {
    return error.response;
  }
};

const getBinLocation = async (payload) => {
  const { name } = payload;
  try {
    const response = await httpClient.get(`/v1/warehouse/manager/company/${comCode}/web/binlocation?searchName=${name}`);
    if (response.status === 200) {
      return response;
    } else {
      return false;
    }
  } catch (error) {
    return error.response;
  }
};

const getBinLocationForSwap = async (payload) => {
  const { binId, name, itemCodeSearch } = payload;
  try {
    const response = await httpClient.get(
      `/v1/warehouse/manager/company/${comCode}/web/binlocation/swap/${binId}/?searchName=${name}${itemCodeSearch}`
    );
    if (response.status === 200) {
      return response;
    } else {
      return false;
    }
  } catch (error) {
    return error.response;
  }
};

const getItemBinLocationForSwap = async (payload) => {
  const { binId, name } = payload;
  try {
    const response = await httpClient.get(
      `/v1/warehouse/manager/company/${comCode}/web/binlocation/swap/${binId}/itemName?searchName=${name}`
    );
    if (response.status === 200) {
      return response;
    } else {
      return false;
    }
  } catch (error) {
    return error.response;
  }
};

const getStockPage = async (payload) => {
  try {
    const response = await httpClient.post(`/v1/warehouse/manager/company/${comCode}/web/warehouse-stockpage`, payload);
    if (response.status === 200) {
      return response;
    } else {
      return false;
    }
  } catch (error) {
    return error.response;
  }
};

const updateWarehouseDetail = async (id, payload) => {
  try {
    const response = await httpClient.put(`/v1/warehouse/manager/company/${comCode}/web/warehouse-detail/${id}`, payload);
    if (response.status === 200) {
      return response;
    } else {
      return false;
    }
  } catch (error) {
    return error.response;
  }
};

const saveWarehouseSetting = async (payload) => {
  try {
    const response = await httpClient.post(`/v1/warehouse/manager/company/${comCode}/web/warehouse-servicetime`, payload);
    if (response.status === 200) {
      return response;
    } else {
      return false;
    }
  } catch (error) {
    return error.response;
  }
};

const updateWarehouseSetting = async (id, payload) => {
  try {
    const response = await httpClient.put(`/v1/warehouse/manager/company/${comCode}/web/warehouse-servicetime/${id}`, payload);
    if (response.status === 200) {
      return response;
    } else {
      return false;
    }
  } catch (error) {
    return error.response;
  }
};

const deleteWarehouseSetting = async (id) => {
  try {
    const response = await httpClient.delete(`/v1/warehouse/manager/company/${comCode}/web/warehouse-servicetime/${id}`);
    if (response.status === 200) {
      return response;
    } else {
      return false;
    }
  } catch (error) {
    return error.response;
  }
};

const saveWarehouseManager = async (payload) => {
  try {
    const response = await httpClient.post(`/v1/warehouse/manager/company/${comCode}/web/warehouse-manager`, payload);
    if (response.status === 200) {
      return response;
    } else {
      return false;
    }
  } catch (error) {
    return error.response;
  }
};

const updateWarehouseManager = async (id, payload) => {
  try {
    const response = await httpClient.put(`/v1/warehouse/manager/company/${comCode}/web/warehouse-manager/${id}`, payload);
    if (response.status === 200) {
      return response;
    } else {
      return false;
    }
  } catch (error) {
    return error.response;
  }
};

const deleteWarehouseManager = async (id) => {
  try {
    const response = await httpClient.delete(`/v1/warehouse/manager/company/${comCode}/web/warehouse-manager/${id}`);
    if (response.status === 200) {
      return response;
    } else {
      return false;
    }
  } catch (error) {
    return error.response;
  }
};

const getWarehouseStock = async (payload) => {
  try {
    const response = await httpClient.post(`/v1/warehouse/manager/company/${comCode}/web/warehouse-stockpage`, payload);
    if (response.status === 200) {
      return response;
    } else {
      return false;
    }
  } catch (error) {
    return error.response;
  }
};

const saveWarehouseStock = async (payload) => {
  try {
    const response = await httpClient.post(`/v1/warehouse/manager/company/${comCode}/web/warehouse-stockitem`, payload);
    if (response.status === 200) {
      return response;
    } else {
      return false;
    }
  } catch (error) {
    return error.response;
  }
};

const updateWarehouseStock = async (id, payload) => {
  try {
    const response = await httpClient.put(`/v1/warehouse/manager/company/${comCode}/web/warehouse-stockitem/${id}`, payload);
    if (response.status === 200) {
      return response;
    } else {
      return false;
    }
  } catch (error) {
    return error.response;
  }
};

const deleteWarehouseStock = async (id) => {
  try {
    const response = await httpClient.delete(`/v1/warehouse/manager/company/${comCode}/web/warehouse-stockitem/${id}`);
    if (response.status === 200) {
      return response;
    } else {
      return false;
    }
  } catch (error) {
    return error.response;
  }
};

const getWarehouseStockById = async (id) => {
  try {
    const response = await httpClient.get(`/v1/warehouse/manager/company/${comCode}/web/warehouse-stockitem/${id}`);
    if (response.status === 200) {
      return response;
    } else {
      return false;
    }
  } catch (error) {
    return error.response;
  }
};

const getLocationByItem = async (payload) => {
  try {
    const response = await httpClient.post(`/v1/warehouse/manager/company/${comCode}/web/binlocation/createstock`, payload);
    if (response.status === 200) {
      return response;
    } else {
      return false;
    }
  } catch (error) {
    return error.response;
  }
};

const getWarehouseLocation = async (payload) => {
  try {
    const response = await httpClient.post(`/v1/warehouse/manager/company/${comCode}/web/binlocationpage`, payload);
    if (response.status === 200) {
      return response;
    } else {
      return false;
    }
  } catch (error) {
    return error.response;
  }
};

const saveWarehouseLocation = async (payload) => {
  try {
    const response = await httpClient.post(`/v1/warehouse/manager/company/${comCode}/web/binlocation`, payload);
    if (response.status === 200) {
      return response;
    } else {
      return false;
    }
  } catch (error) {
    return error.response;
  }
};

const updateWarehouseLocation = async (id, payload) => {
  try {
    const response = await httpClient.put(`/v1/warehouse/manager/company/${comCode}/web/binlocation/${id}`, payload);
    if (response.status === 200) {
      return response;
    } else {
      return false;
    }
  } catch (error) {
    return error.response;
  }
};

const deleteWarehouseLocation = async (id) => {
  try {
    const response = await httpClient.delete(`/v1/warehouse/manager/company/${comCode}/web/binlocation/${id}`);
    if (response.status === 200) {
      return response;
    } else {
      return false;
    }
  } catch (error) {
    return error.response;
  }
};

const createWarehouseNoBinLocation = async (id, payload) => {
  try {
    const response = await httpClient.post(`/v1/warehouse/manager/company/${comCode}/web/nobinlocation/${id}`, payload);
    if (response.status === 200) {
      return response;
    } else {
      return false;
    }
  } catch (error) {
    return error.response;
  }
};

const getItemsNameSwap = async (payload) => {
  const { binId, value } = payload;
  try {
    const response = await httpClient.get(
      `/v1/warehouse/manager/company/${comCode}/web/binlocation/swap/${binId}/itemName?searchName=${value}`
    );
    if (response.status === 200) {
      return response;
    } else {
      return false;
    }
  } catch (error) {
    return error.response;
  }
};

const getItemsCodeSwap = async (payload) => {
  const { binId, value } = payload;
  try {
    const response = await httpClient.get(
      `/v1/warehouse/manager/company/${comCode}/web/binlocation/swap/${binId}/itemCode?searchName=${value}`
    );
    if (response.status === 200) {
      return response;
    } else {
      return false;
    }
  } catch (error) {
    return error.response;
  }
};

const saveSwapLocation = async (payload) => {
  try {
    const response = await httpClient.post(`/v1/warehouse/manager/company/${comCode}/web/binlocation/swap`, payload);
    if (response.status === 200) {
      return response;
    } else {
      return false;
    }
  } catch (error) {
    return error.response;
  }
};

const getMovementStock = async (payload) => {
  try {
    const response = await httpClient.post(`/v1/warehouse/manager/company/${comCode}/web/movementpage`, payload);
    if (response.status === 200) {
      return response;
    } else {
      return false;
    }
  } catch (error) {
    return error.response;
  }
};

const saveMovementStock = async (payload) => {
  try {
    const response = await httpClient.post(`/v1/warehouse/manager/company/${comCode}/web/receiveitemstock`, payload);
    if (response.status === 200) {
      return response;
    } else {
      return false;
    }
  } catch (error) {
    return error.response;
  }
};

const getByIdMovementStock = async (id) => {
  try {
    const response = await httpClient.get(`/v1/warehouse/manager/company/${comCode}/web/movement/${id}`);
    if (response.status === 200) {
      return response;
    } else {
      return false;
    }
  } catch (error) {
    return error.response;
  }
};

const getWarehouseAdjust = async (body) => {
  try {
    const response = await httpClient.post(`/v1/warehouse/manager/company/${comCode}/web/adjustout/listnumber`, body);
    if (response.status === 200) {
      return response;
    } else {
      return false;
    }
  } catch (error) {
    return error.response;
  }
};

const warehouseAdjustIn = async (body) => {
  try {
    const response = await httpClient.post(`/v1/warehouse/manager/company/${comCode}/web/adjustin`, body);
    if (response.status === 200) {
      return response;
    } else {
      return false;
    }
  } catch (error) {
    return error.response;
  }
};

const warehouseAdjustOut = async (body) => {
  try {
    const response = await httpClient.post(`/v1/warehouse/manager/company/${comCode}/web/adjustout`, body);
    if (response.status === 200) {
      return response;
    } else {
      return false;
    }
  } catch (error) {
    return error.response;
  }
};

const warehouseReportInstock = async (body) => {
  try {
    const response = await httpClient.post(`/v1/warehouse/manager/company/${comCode}/web/instockreport`, body);
    if (response.status === 200) {
      return response;
    } else {
      return false;
    }
  } catch (error) {
    return error.response;
  }
};

const warehouseReportInstockExport = async (body) => {
  try {
    const response = await httpClient.post(`/v1/warehouse/manager/company/${comCode}/web/exportinstockreport`, body);
    if (response.status === 200) {
      return response;
    } else {
      return false;
    }
  } catch (error) {
    return error.response;
  }
};

const warehouseReportMovementExport = async (body) => {
  try {
    const response = await httpClient.post(`/v1/warehouse/manager/company/${comCode}/web/movementexport`, body);
    if (response.status === 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    return error.response;
  }
};

const warehouseListExport = async (body, start, end, left) => {
  try {
    const response = await httpClient.post(
      `/v1/warehouse/manager/company/${comCode}/web/warehouselist/export?indexStart=${start}&indexEnd=${end}&searchLeftMenu=${left}`,
      body
    );
    if (response.status === 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    return error.response;
  }
};

export {
  getWareHouseList,
  getWareHouseDetail,
  getItemsMaster,
  getBinLocation,
  getStockPage,
  updateWarehouseDetail,
  getItemsCode,
  saveWareHouse,
  deleteWareHouse,
  saveWarehouseSetting,
  updateWarehouseSetting,
  deleteWarehouseSetting,
  saveWarehouseManager,
  updateWarehouseManager,
  deleteWarehouseManager,
  uploadImageWarehouse,
  updateWareHouse,
  getWarehouseStock,
  saveWarehouseStock,
  updateWarehouseStock,
  deleteWarehouseStock,
  getWarehouseStockById,
  getLocationByItem,
  getWarehouseLocation,
  saveWarehouseLocation,
  updateWarehouseLocation,
  deleteWarehouseLocation,
  createWarehouseNoBinLocation,
  getBinLocationForSwap,
  getItemBinLocationForSwap,
  getItemsNameSwap,
  getItemsCodeSwap,
  saveSwapLocation,
  getMovementStock,
  saveMovementStock,
  getByIdMovementStock,
  getWarehouseAdjust,
  warehouseAdjustIn,
  warehouseAdjustOut,
  getItemsMasterNew,
  getItemsCodeNew,
  warehouseReportInstock,
  warehouseReportInstockExport,
  warehouseReportMovementExport,
  warehouseListExport,
};
