import httpClient from '../../components/axiosClient';

const searchTravelExpense = async (payload) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v1/travelexpense/manager/company/${comCode}/web/travelexpense/list`, payload);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return error.responase;
  }
}

const getTravelExpenseDetail = async (id) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.get(`/v1/travelexpense/manager/company/${comCode}/web/travelexpense/${id}`);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return error.responase;
  }
}

const addTravelExpense = async (payload) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v1/travelexpense/manager/company/${comCode}/web/travelexpense/detail`, payload);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return error.responase;
  }
}

const editTravelExpense = async (id, payload) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.put(`/v1/travelexpense/manager/company/${comCode}/web/travelexpense/detail/${id}`, payload);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return error.responase;
  }
}

const deleteTravelExpense = async (id) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.delete(`/v1/travelexpense/manager/company/${comCode}/web/travelexpense/detail/${id}`);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return error.responase;
  }
}

const recalculateTravelExpense = async () => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v1/travelexpense/manager/company/${comCode}/web/travelexpense/regenerate`);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return error.responase;
  }
}

const updateStatusTravelExpense = async (id,payload) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.put(`/v1/travelexpense/manager/company/${comCode}/web/travelexpense/status/${id}`, payload);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return error.responase;
  }
}


export {
  searchTravelExpense, getTravelExpenseDetail, addTravelExpense, editTravelExpense,
  deleteTravelExpense, recalculateTravelExpense, updateStatusTravelExpense
}

