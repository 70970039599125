import React, { useState, useEffect, createContext } from 'react';
import { getDataAll, getModelName } from '../../controllers/item-master/get-data-all';
import { useIntl, FormattedMessage } from 'react-intl';
import _, { debounce } from 'lodash';
import './css/index.css';
import ModalForm from '../../components/create-edit-item-master/index';
import { getWareHouseListData } from '../../controllers/receice-po/receive';

// export const ItemMasterDetail = createContext();
const ItemMasterMainModal = ({ visibleModal, setVisibleModal,typeModal,filterDataNewItemMaster }) => {
  const intl = useIntl();

  // const [visibleModal, setVisibleModal] = useState(false);

  const [recordItemMaster, setRecordItemMaster] = useState();

  const [triggerAPI, setTriggerAPI] = useState(false);

  const [dataDefault, setDataDefault] = useState();
  const [eventDataDefault, setEventDataDefault] = useState(false);
  const [brandId, setBrandId] = useState('');
  const [dataModel, setDataModel] = useState([]);
  const [createItem, setCreateItem] = useState();
  //--------------------------------------------------------
  const [warehouseData, setWarehouseData] = useState([])
  const [warhousePage, setWarhousePage] = useState(10);
  const [warhouseLoading, setWarhouseLoading] = useState(false);
  const [warehouseSerch, setWarehouseSerch] = useState('');
  const [warehouseTotal, setWarehouseTotal] = useState('');
  //--------------------------------------------------------
  const [returnData, setReturnData] = useState([])

  useEffect(() => {
    async function getAPI() {
      const response = await getDataAll();
      // console.log('responseSetDataDefault', response)

      await setDataDefault(response);
    }
    getAPI();
  }, [eventDataDefault]);

  useEffect(() => {
    const tempBID = _.get(createItem, 'brandId');
    async function getAPI() {
      // console.log('brandId useEffect', brandId, _.size(brandId))
      if (brandId) {
        const response = await getModelName({ brandId });
        await setDataModel(response);
        // console.log('BrandIDEditResponse', response)
      }
    }
    if ((brandId && _.size(brandId)) || createItem) {
      // console.log('BrandIDEdit is true', brandId, '/createitem:', tempBID, _.size(brandId));
      getAPI();
    }
  }, [brandId, visibleModal, createItem]);

  useEffect(() => {
    const getWarehouse = async () => {
      const body = {
        indexStart: 0,
        indexEnd: warhousePage,
        searchName: warehouseSerch || '',
      };
  
      const responase = await getWareHouseListData(body);
      setWarehouseData(_.get(responase, 'data.data.warehouseList'));
      setWarhouseLoading(false);
      setWarehouseTotal(_.get(responase, 'data.data.totalItem'))
    };
    getWarehouse();
  }, [warhousePage,warehouseSerch]);

  const handleCustomerPopupScroll = (e) => {
    let target = e.target;
    if (!warhouseLoading && target.scrollTop + target.offsetHeight >= target.scrollHeight - 50 && warhousePage < warehouseTotal ) {
      setWarhousePage(warhousePage + 10);
      setWarhouseLoading(true);
    }
  };

  const handleSearchWarhouse = debounce((e) => {
    setWarehouseSerch(e)
  },500);

  const fncReturnData = (e) => {
    if (_.isFunction(filterDataNewItemMaster)) {
      filterDataNewItemMaster(e)
    }

  };

  return (
    <ModalForm
      visible={visibleModal}
      setVisible={setVisibleModal}
      setTriggerAPI={setTriggerAPI}
      record={recordItemMaster}
      setRecord={setRecordItemMaster}
      dataDefault={dataDefault}
      setBrandId={setBrandId}
      dataModel={dataModel}
      setCreateItem={setCreateItem}
      createItem={createItem}
      setEventDataDefault={setEventDataDefault}
      typeModal={typeModal}
      warehouseData={warehouseData}
      handleCustomerPopupScroll={handleCustomerPopupScroll}
      handleSearchWarhouse={handleSearchWarhouse}
      fncReturnData={fncReturnData}
    />
  );
};

export default ItemMasterMainModal;
