import React, { useRef, useCallback } from 'react';
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';
import Geocode from "react-geocode";
import _ from 'lodash';
import { getSearchLocation } from '../../controllers/support-service/api';

Geocode.setApiKey(`${localStorage.getItem('GEO_APIKEY')}`);
Geocode.enableDebug();

export default ({ position, center, setLatlngTo, setValueAddress }) => {
  
  const libraries = ["places"];
  const mapContainerStyle = {
    height: `260px`,
    width: '100%',
    position: 'relative'
  }

  const defaultMapOption = {
    fullscreenControl: false,
    fullscreenControlOptions: false,
    streetViewControl: false,
    clickableIcons: false,
    zoomControl: true,
    gestureHandling: "cooperative",
    mapTypeControl: true,
    mapTypeControlOptions: {
      style: 1,
      position: 3,
    },
  };

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: localStorage.getItem('GEO_APIKEY'),
    libraries,
  });

  const mapRef = useRef();
  const onMapLoad = useCallback((map) => {
    mapRef.current = map
  }, []);

  if (loadError) return "Error loading maps";
  if (!isLoaded) return "Loading Maps";

  const onDragEnd = async (evt) => {
    setLatlngTo({ lat: evt.latLng.lat(), lng: evt.latLng.lng() });
    let area = await getSearchLocation({ location: evt.latLng });
    setValueAddress(area.address);
  }

  return (
    <div>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={center}
        zoom={10}
        onLoad={onMapLoad}
        options={defaultMapOption}
      >
        <Marker
          position={position}
          animation={window.google.maps.Animation.DROP}
          draggable={true}
          onDragEnd={onDragEnd}
        />
      </GoogleMap>
    </div>
  )
}
