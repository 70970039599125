import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import { MaintenanceContext } from './maintenance-context';
import getItemUnit from '../../controllers/maintenance-report/get-item-unit';
import getTaxDiscount from '../../controllers/maintenance-report/get-tax-discount';
import getByIdMaintenanceReport from '../../controllers/maintenance-report/get-byId-maintenance-report';
import getByIdAttachmentDetails from '../../controllers/maintenance-report/get-byId-attachment-details';
import MaintenanceDetail from './maintenance-detail';
import MaintenanceParts from './maintenance-parts';
import MaintenanceLabors from './maintenance-labors';
import MaintenanceCalculate from './maintenance-calculate';
import MaintenanceAttachment from './maintenance-attachment';
import ModalAddParts from './modal-add-item-parts';
import ModalAddLabors from './modal-add-item-labors';
import ModalAddAttachment from './modal-add-attachment';
import ModalEditAttachment from './modal-edit-attachment';
import axios from 'axios';
import { useIntl, FormattedMessage } from 'react-intl';
import NumberFormat from 'react-number-format';
import { Card, Icon, Tag, Modal, Spin } from 'antd';
import _, { debounce } from 'lodash';
import moment from 'moment';
import Button01 from '../v2/button_01';
import Button02 from '../v2/button_02';
import httpClient from '../axiosClient';
import {
  successNotification,
  errorNotification,
  warningNotification,
} from '../v2/notification';
import { PageSettings } from '../../config/page-settings';
import './css/index.css';
import getCustomerVenderApi from '../../controllers/getCustomer/get-customer-vender';
import Resizer from 'react-image-file-resizer';
import { v4 as uuidv4 } from 'uuid';
import { checkDocument } from '../../controllers/maintenance-report/getDocumentNo';
import logoPDF from '../../pages/login/image/pdf.png';

const CreateMaintenanceReport = (props) => {
  const {
    visibleModal,
    setVisibleModal,
    setTriggerAPI,
    defalseMaintenData,
    setDefalseMaintenData,
    view
  } = props;
  const memComId = localStorage.getItem('memComId');
  const intl = useIntl();
  const comCode = localStorage.getItem('comCode');
  const today = new Date();

  const columnsParts = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
      render: (text, record, index) => index + 1,
    },
    {
      title: intl.formatMessage({
        id: 'modalMaintenanceColumnsPartCode',
        defaultMessage: 'Part Code',
      }),
      dataIndex: 'partCode',
      key: 'partCode',
      width: 150,
    },
    {
      title: intl.formatMessage({
        id: 'modalMaintenanceColumnsPartNo',
        defaultMessage: 'Part No.',
      }),
      dataIndex: 'partNo',
      key: 'partNo',
    },
    {
      title: intl.formatMessage({
        id: 'modalMaintenanceColumnsPartName',
        defaultMessage: 'Part Name',
      }),
      dataIndex: 'partDescription',
      key: 'partDescription',
    },
    {
      title: intl.formatMessage({
        id: 'modalMaintenanceColumnsQty',
        defaultMessage: 'Qty',
      }),
      dataIndex: 'partQty',
      key: 'partQty',
      align: 'center',
      width: 100,
      render: (text, record, index) => (
        <NumberFormat
          value={parseFloat(record.partQty)}
          displayType={'text'}
          thousandSeparator={true}
        />
      ),
    },
    {
      title: intl.formatMessage({
        id: 'modalMaintenanceColumnsUnit',
        defaultMessage: 'Unit',
      }),
      dataIndex: 'itemUnitName',
      key: 'itemUnitName',
      // align: 'right',
      width: 100,
    },
    {
      title: intl.formatMessage({
        id: 'modalMaintenanceColumnsUnitPrice',
        defaultMessage: 'Unit Price (Baht)',
      }),
      dataIndex: 'partPrice',
      key: 'partPrice',
      align: 'right',
      width: 160,
      render: (text, record, index) => (
        <NumberFormat
          value={parseFloat(record.partPrice).toFixed(2)}
          displayType={'text'}
          thousandSeparator={true}
        />
      ),
    },
    {
      title: intl.formatMessage({
        id: 'modalMaintenanceColumnsTotal',
        defaultMessage: 'Total (Baht)',
      }),
      dataIndex: 'chargeAmount',
      key: 'chargeAmount',
      align: 'right',
      width: 130,
      render: (text, record, index) => (
        <NumberFormat
          value={parseFloat(record.chargeAmount).toFixed(2)}
          displayType={'text'}
          thousandSeparator={true}
        />
      ),
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      width: 50,
      render: (text, record, index) => {
        return view ? <span></span> :
          <div
            className="table-column-delete-dot"
            onClick={() => handleDeleteItemParts(index)}
          >
            <div className="table-column-delete-line-style" />
          </div>
      },
    },
  ];

  const columnsLabors = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
      render: (text, record, index) => index + 1,
    },
    {
      title: intl.formatMessage({
        id: 'modalMaintenanceColumnsDescription',
        defaultMessage: 'Description',
      }),
      dataIndex: 'laborDescription',
      key: 'laborDescription',
    },
    {
      title: intl.formatMessage({
        id: 'modalMaintenanceColumnsChargeAmount',
        defaultMessage: 'Charge Amount (Baht)',
      }),
      dataIndex: 'chargeAmount',
      key: 'chargeAmount',
      width: 250,
      align: 'right',
      render: (text, record, index) => (
        <NumberFormat
          value={parseFloat(record.chargeAmount).toFixed(2)}
          displayType={'text'}
          thousandSeparator={true}
        />
      ),
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      width: 50,
      render: (text, record, index) => {
        return view ? <span /> :
          <div
            className="table-column-delete-dot"
            onClick={() => handleDeleteItemLabors(index)}
          >
            <div className="table-column-delete-line-style" />
          </div>
      },
    },
  ];

  const [formRefAddItemParts, setFormRefAddItemParts] = useState(null);
  const [formRefAddItemLabors, setFormRefAddItemLabors] = useState(null);
  const [loading, setLoading] = useState(false)

  //-----------------------maintenanceDetail --------------------------
  const [maintenanceDetail, setMaintenanceDetail] = useState(null);
  const [dataMaintenById, setDataMaintenById] = useState(null);
  const [dataAttachmentById, setDataAttachmentById] = useState(null);
  const [checkAttachmentDefault, setCheckAttachmentDefault] = useState(null);

  const refAddress = useRef();
  const [disabledSave, setDisabledSave] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const [vendorData, setVendorData] = useState([]);
  const [vendorAddress, setVendorAddress] = useState();
  const [itemName, setItemName] = useState([]);
  const [itemNo, setItemNo] = useState([]);
  const [itemCode, setItemCode] = useState([]);
  const [itemNoAll, setItemNoAll] = useState([]);
  const [venderId, setVenderId] = useState();
  const [itemMasterId, setItemMasterId] = useState();
  const [addressId, setAddressId] = useState();
  const [itemNoId, setItemNoId] = useState();
  const [numberState, setNumberState] = useState()
  //-------------------------------------------------------------------
  const [dataParts, setDataParts] = useState([]); // Data API Parts
  const [visibleParts, setVisibleParts] = useState(false);
  const [focusInputParts, setFocusInputParts] = useState(true);
  const [triggerAddItemParts, setTriggerAddItemParts] = useState(false);
  const [sumSubtotalParts, setSumSubtotalParts] = useState(0);

  const [dataLabors, setDatalabors] = useState([]); // Data API Labors
  const [visibleLabors, setVisibleLabors] = useState(false);
  const [focusInputLabors, setFocusInputLabors] = useState(true);
  const [triggerAddItemLabors, setTriggerAddItemLabors] = useState(false);
  const [sumSubtotalLabors, setSumSubtotalLabors] = useState(0);

  const [visibleAttachment, setVisibleAttachment] = useState(false);
  const [visibleEditAttachment, setVisibleEditAttachment] = useState(false);
  const [attachmentData, setAttachmentData] = useState([]);
  const [unitData, setUnitData] = useState([]);
  const [taxDiscountData, setTaxDiscountData] = useState();

  const [sumCalculate, setSumCalculate] = useState({});
  const [discountSelete, setDiscountSelete] = useState({});
  const [taxSelete, setTaxSelete] = useState({});
  const [discountData, setDiscountData] = useState([]);
  const [taxData, setTaxData] = useState([]);
  const [valueInputDiscount, setValueInputDiscount] = useState(0.0);
  const [valueInputAdjust, setValueInputAdjust] = useState(0.0);
  const [valueInputRemark, setValueInputRemark] = useState('');
  const [editAttachmentData, setEditAttachmentData] = useState();

  //-------------------------- Detail FNC ------------------------------------
  useEffect(() => {
    if (visibleModal === true) {
      const getDataVender = async () => {
        const response = await getCustomerVenderApi(10000);
        if (response) {
          setVendorData(response.customers);
        }
      };
      getDataVender();
    }
  }, [trigger, visibleModal]);

  //--------------------------------------------------------------------------

  useEffect(() => {
    setLoading(true);
    if (defalseMaintenData && defalseMaintenData.maintenanceId) {
      let maintenanceId = defalseMaintenData.maintenanceId;
      async function getAPI() {
        axios
          .all([
            await getItemUnit(),
            await getTaxDiscount(),
            await getByIdMaintenanceReport(maintenanceId),
            await getByIdAttachmentDetails(maintenanceId),
          ])
          .then((resData) => {

            let unit = resData[0];
            let taxDiscount = resData[1];
            let maintenanceById = resData[2];

            const attachmentById = resData[3].map((item, index) => {
              return {
                attachments: item.attachments.map((e, i) => ({
                  createdAt: e.createdAt,
                  type: e.type,
                  name: e.fileName,
                  size: '',
                  maintenanceAttachmentId: e.maintenanceAttachmentId,
                  uid: uuidv4(),
                  originalPath: e.attachmentUrl,
                  attachmentUrl: e.type.indexOf('pdf') > -1 ? logoPDF : e.attachmentUrl,
                  original: e.type.indexOf('pdf') > -1 ? logoPDF : e.attachmentUrl,
                  // originalPath: e.type.indexOf('pdf') > -1 ? logoPDF : e.attachmentUrl,
                  thumbnail: e.type.indexOf('pdf') > -1 ? logoPDF : e.attachmentUrl,
                  thumbUrl: e.type.indexOf('pdf') > -1 ? logoPDF : e.attachmentUrl,
                  url: e.type.indexOf('pdf') > -1 ? logoPDF : e.attachmentUrl,
                  // attachmentUrl: e.attachmentUrl,
                  // original: e.attachmentUrl,
                  // originalPath: e.attachmentUrl,
                  // thumbnail: e.attachmentUrl,
                  // thumbUrl: e.attachmentUrl,
                  // url: e.attachmentUrl,
                })),
                createdAt: item.createdAt,
                createdBy: item.createdBy,
                description: item.description,
                maintenanceAttachmentDetailId:
                  item.maintenanceAttachmentDetailId,
                maintenanceId: item.maintenanceId,
                name: item.name,
                id: uuidv4(),
              };
            });

            setUnitData(unit);
            setTaxDiscountData(taxDiscount);
            fncTaxDiscount(taxDiscount, maintenanceById);
            setDataMaintenById(maintenanceById);
            setDataAttachmentById(attachmentById);
            setCheckAttachmentDefault(attachmentById);
            setDataParts(maintenanceById.partDetail);
            setDatalabors(maintenanceById.laborDetail);
            setValueInputRemark(maintenanceById.remark);
            setValueInputDiscount(maintenanceById.discount);
            setValueInputAdjust(maintenanceById.adjust);
          });
        setTimeout(() => {
          setLoading(false);
        }, 1000);

      }
      getAPI();
    }
    setLoading(false);
  }, [defalseMaintenData]);

  const fncTaxDiscount = (taxDiscount, maintenanceById) => {
    if (taxDiscount !== undefined) {
      const resultDiscount =
        taxDiscount &&
        taxDiscount.discount.find((discountData) => {
          return discountData.discountId === maintenanceById.discountId;
        });

      const resultTax =
        taxDiscount &&
        taxDiscount.tax.find((taxData) => {
          return taxData.taxId === maintenanceById.taxId;
        });

      setDiscountSelete(resultDiscount);
      setTaxSelete(resultTax);
    } else {
      setDiscountSelete({
        discountId: '1689d686-4b8f-4fdb-aff2-1cc4acd78dcd',
        isBath: false,
        isdefault: true,
        percentage: 0,
        titleCode: '0%',
      });
      setTaxSelete({
        isdefault: true,
        percentage: 0,
        taxId: 'fe41cd1b-8579-4d5c-82dc-ae1b3f932fc0',
        titleCode: '0%',
      });
    }
  };

  const saveMaintenanceDetail = useCallback((node) => {
    if (node !== null) {
      setMaintenanceDetail(node);
    }
  }, []);

  const handleCancel = (e) => {
    setVisibleModal(false);
    setDisabledSave(false);
    setVendorAddress();
    maintenanceDetail.resetFields();
    refAddress.current.innerHTML = 'Address';
    setValueInputDiscount(0.0);
    setValueInputAdjust(0.0);
    setValueInputRemark('');
    setDiscountSelete({});
    setTaxSelete({});
    setItemName([]);
    setItemNo([]);
    setItemCode([]);
    setDataParts([]);
    setDatalabors([]);
    setVenderId();
    setItemMasterId();
    setAddressId();
    setAttachmentData([]);
    setItemNoId();
    setDefalseMaintenData(null);
    setDataMaintenById(null);
    setDataAttachmentById(null);
    setCheckAttachmentDefault(null);
    setNumberState();
  };

  const handleEdit = async (e) => {
    maintenanceDetail.validateFields(async (err, values) => {
      if (err) {
        return;
      }

      try {
        const valCheck = await checkDocument(values.docNo, dataMaintenById.maintenanceId);

        if (valCheck === false) {
          maintenanceDetail.setFields({
            docNo: {
              value: values.docNo,
              errors: [new Error(intl.formatMessage({ id: 'duplicateDataDocNo', defaultMessage: 'docNo is duplicate' }))]
            }
          })
          return;
        }
      } catch (error) {
        return
      }

      if (dataParts.length === 0) {
        errorNotification(intl.formatMessage({ id: 'errorMaintenanceNoParts', defaultMessage: 'parts is no data' }))
        return;
      }
      if (dataLabors.length === 0) {
        errorNotification(intl.formatMessage({ id: 'errorMaintenanceNoLabors', defaultMessage: 'labors is no data' }))
        return;
      }

      setDisabledSave(true);
      const busPartnerName =
        vendorData &&
        vendorData.find((data) => {
          return data.customerId === values.venderId;
        });

      const busPartnerAddress =
        vendorAddress &&
        vendorAddress.find((data) => {
          return data.customerAddressBookId === values.address;
        });

      const itemData =
        itemName &&
        itemName.find((data) => {
          return data.itemMasterId === values.itemName;
        });

      let arr = [];
      _.map(itemNoAll, (el) => {
        arr.push(...el.item);
      });

      const FilterItemNoId =
        arr &&
        arr.find((data) => {
          return data.itemId === values.itemNo;
        });


      const body = {
        referenceDocNo: values.docNo,
        itemId: values.itemNo,
        itemNo: FilterItemNoId.itemNo,
        itemCode: itemData.itemCode,
        itemName: itemData.itemName,
        itemMasterId: itemData.itemMasterId,
        reference1: undefined,
        reference2: undefined,
        reference3: undefined,
        remark: valueInputRemark,
        maintenanceServiceBy: `${memComId}`,
        maintenanceServiceDate: moment(values.maintenanceDate).format(
          'YYYY-MM-DD 00:00:00',
        ),
        nextMaintenceServiceDate: values.nextMaintenanceDate ? moment(values.nextMaintenanceDate).format('YYYY-MM-DD 00:00:00') : null,
        businessPartnerId: values.venderId,
        businessPartnerName: busPartnerName.customerName,
        businessPartnerAddress: busPartnerAddress.address,
        businessPartnerAddressId: values.address,
        businessPartnerPhone: values.phone,
        taxId: taxSelete.taxId,
        discountId: discountSelete.discountId,
        adjust: valueInputAdjust,
        laborCharge: Number(sumSubtotalLabors),
        partCharge: Number(sumSubtotalParts),
        discount: Number(parseFloat(sumCalculate.discount).toFixed(2)),
        tax: Number(parseFloat(sumCalculate.tax).toFixed(2)),
        total: Number(parseFloat(sumCalculate.total).toFixed(2)),
        laborDetail: dataLabors,
        partDetail: dataParts,
      };


      if (parseFloat(sumCalculate.total).toFixed(2) <= 0) {
        errorNotification(<FormattedMessage id="maintenanceTexNotiSaveLessThan" defaultMessage="The amount cannot be negative." />);
        setDisabledSave(false);
      } else {
        try {
          const response = await httpClient.put(
            `/v3/maintenance/manager/company/${comCode}/maintenance/${dataMaintenById.maintenanceId}`,
            body,
          );

          if (response.status == 200) {

            if (response.data.status.code === 400) {
              errorNotification(response.data.status.message)
              setDisabledSave(false);
            } else {
              await fncUpdateAttachment(
                response.data.data.maintenance.maintenanceId,
              );
              setVisibleModal(false);
              setTriggerAPI((current) => !current);
              setDisabledSave(false);
              maintenanceDetail.resetFields();
              refAddress.current.innerHTML = 'Address'
              setValueInputDiscount(0.00);
              setValueInputAdjust(0.00);
              setValueInputRemark('');
              setDiscountSelete({});
              setTaxSelete({});
              setItemName([]);
              setItemNo([]);
              setItemCode([]);
              setVenderId();
              setItemMasterId();
              setAddressId();
              setDataParts([]);
              setDatalabors([]);
              setAttachmentData([]);
              successNotification(response.data.status.message);
              setItemNoId();
              setNumberState();
              setDataMaintenById(null);
            }

          }
        } catch (error) {
          // if (error.response && error.response.status === 500) {
          //   errorNotification(error.response.data.error);
          // }
          setVisibleModal(false);
          setDisabledSave(false);
          // return;
        }
      }

    });
  };

  const handleVisibleView = () => {
    setVisibleModal(false);
    setDisabledSave(false);
    setVendorAddress();
    maintenanceDetail.resetFields();
    refAddress.current.innerHTML = 'Address';
    setValueInputDiscount(0.0);
    setValueInputAdjust(0.0);
    setValueInputRemark('');
    setDiscountSelete({});
    setTaxSelete({});
    setItemName([]);
    setItemNo([]);
    setItemCode([]);
    setDataParts([]);
    setDatalabors([]);
    setVenderId();
    setItemMasterId();
    setAddressId();
    setAttachmentData([]);
    setItemNoId();
    setDefalseMaintenData(null);
    setDataMaintenById(null);
    setDataAttachmentById(null);
    setCheckAttachmentDefault(null);
    setNumberState();
  }

  //--------------------------cal table-------------------------------
  const subTotalResultParts = useMemo(() => {
    const totalItemPart =
      dataParts &&
      dataParts.map((item) => {
        return item.chargeAmount;
      });
    const numbers = totalItemPart.map((n) => {
      return parseFloat(n, 10);
    });
    const sumTotal = numbers && numbers.reduce((a, b) => a + b, 0);
    setSumSubtotalParts(_.floor(sumTotal, 2).toFixed(2));
    return _.floor(sumTotal, 2).toFixed(2);
  }, [dataParts.length]);

  const subTotalResultLabors = useMemo(() => {
    const totalItemLabors =
      dataLabors &&
      dataLabors.map((item) => {
        return item.chargeAmount;
      });
    const numbers = totalItemLabors.map((n) => {
      return parseFloat(n, 10);
    });
    const sumTotal = numbers && numbers.reduce((a, b) => a + b, 0);
    setSumSubtotalLabors(_.floor(sumTotal, 2).toFixed(2));
    return _.floor(sumTotal, 2).toFixed(2);
  }, [dataLabors.length]);

  const saveFormRefAddItemParts = useCallback((node) => {
    if (node !== null) {
      setFormRefAddItemParts(node);
    }
  }, []);

  const saveFormRefAddItemLabors = useCallback((node) => {
    if (node !== null) {
      setFormRefAddItemLabors(node);
    }
  }, []);

  const addItemParts = () => {
    const ItemDataParts = [...dataParts];
    formRefAddItemParts.validateFields((err, values) => {
      if (err) {
        return;
      } else {
        if (values.partNo) {
          const validatePartNo = ItemDataParts.filter((col) => {
            return values.partNo === col.partNo
          });
          if (validatePartNo.length > 0) {
            formRefAddItemParts.setFields({
              partNo: {
                value: values.partNo,
                errors: [new Error(intl.formatMessage({ id: 'duplicatePartNo', defaultMessage: 'part no. is  duplicate' }))]
              }
            })
            return;
          } else {
            const unitName = _.filter(unitData, (e) => { return e.itemsUnitId === values.unit });
            const unitNameString = _.map(unitName, 'itemsUnitName');

            ItemDataParts.push({
              partCode: values.partCode,
              partNo: values.partNo,
              partDescription: values.partName,
              partQty: Number(values.qty),
              partPrice: values.unitPrice === '.' ? _.floor(0, 2).toFixed(2) : _.floor(values.unitPrice, 2).toFixed(2),
              chargeAmount: _.floor(values.total, 2).toFixed(2),
              itemUnitId: values.unit,
              itemUnitName: unitNameString.toString(),
            })
            setDataParts(ItemDataParts);
            formRefAddItemParts.resetFields()
            setTriggerAddItemParts(current => !current)
          }
        }

      }
    })

  }

  const fncUpdateAttachment = async (maintenanceId) => {
    const arrDelete = _.differenceBy(
      checkAttachmentDefault,
      dataAttachmentById,
      'maintenanceAttachmentDetailId',
    );

    if (arrDelete.length > 0) {
      arrDelete.forEach(async (item) => {
        try {
          await httpClient.delete(
            `/v3/maintenance/manager/company/${comCode}/maintenance/attachment/detail/${item.maintenanceAttachmentDetailId}`,
          );
        } catch (error) {
          return;
        }
      });
    }

    if (maintenanceId) {
      dataAttachmentById.forEach(async (item) => {
        if (item.maintenanceAttachmentDetailId) {
          try {
            const body = {
              name: item.name,
              description: item.description,
            };
            await httpClient.put(
              `/v3/maintenance/manager/company/${comCode}/maintenance/attachment/detail/${item.maintenanceAttachmentDetailId}`,
              body,
            );

            const findIndexDataAttachmentDetail = _.find(checkAttachmentDefault, ['maintenanceAttachmentDetailId', item.maintenanceAttachmentDetailId]);
            if (findIndexDataAttachmentDetail) {
              const files = _.differenceBy(findIndexDataAttachmentDetail.attachments, item.attachments, 'maintenanceAttachmentId');
              if (_.size(files)) {
                for (let j = 0; j < _.size(files); j++) {
                  const maintenanceAttachmentId = _.get(files[j], 'maintenanceAttachmentId');
                  await deleteUploadFile(maintenanceAttachmentId);
                }
              }
              if (_.size(item.attachments)) {
                const maintenanceAttachmentDetailId = _.get(item, 'maintenanceAttachmentDetailId');
                for (let j = 0; j < _.size(item.attachments); j++) {
                  const maintenanceAttachmentId = _.get(item.attachments[j], 'maintenanceAttachmentId');
                  if (!maintenanceAttachmentId) await uploadFile(item.attachments[j], maintenanceAttachmentDetailId, maintenanceId);
                }
              }
            }
          } catch (error) {
            return;
          }
        } else {
          try {
            const body = {
              maintenanceId: maintenanceId,
              name: item.name,
              description: item.description,
            };
            const response = await httpClient.post(
              `/v3/maintenance/manager/company/${comCode}/maintenance/new-attachment/detail`,
              body,
            );
            if (response.status === 200) {
              if (_.size(item.attachments)) {
                const maintenanceAttachmentDetailId = _.get(response.data, 'data.added.maintenanceAttachmentDetailId');
                for (let j = 0; j < _.size(item.attachments); j++) {
                  const maintenanceAttachmentId = _.get(item.attachments[j], 'maintenanceAttachmentId');
                  if (!maintenanceAttachmentId) await uploadFile(item.attachments[j], maintenanceAttachmentDetailId, maintenanceId);
                }
              }
            }
          } catch (error) {
            return;
          }
        }
      });
    }
  };

  const uploadFile = async (files, maintenanceAttachmentDetailId, maintenanceId) => {
    // upload file

    if (files.name.match(/\.(jpg|jpeg|png|JPG|PNG|JPEG)$/)) {
      const image = await resizeFile(files.originFileObj);
      const fileChange = dataURLtoFile(image, files.originFileObj.name.toLowerCase());

      let formData = new FormData();
      formData.append('file', files.size < 92160 ? files.originFileObj : fileChange);
      formData.append('type', 'photo');
      formData.append('maintenanceAttachmentDetailId', maintenanceAttachmentDetailId);
      formData.append('maintenanceId', maintenanceId);
      try {
        await httpClient.post(`/v3/maintenance/manager/company/${comCode}/maintenance/new-attachment`, formData);
      } catch (error) {
        return
      }
    } else if (files.name.match(/\.(pdf|PDF)$/)) {
      let formDataPDF = new FormData();
      formDataPDF.append('file', files.originFileObj);
      formDataPDF.append('type', 'pdf');
      formDataPDF.append('maintenanceAttachmentDetailId', maintenanceAttachmentDetailId);
      formDataPDF.append('maintenanceId', maintenanceId);
      try {
        await httpClient.post(`/v3/maintenance/manager/company/${comCode}/maintenance/new-attachment`, formDataPDF);
      } catch (error) {
        return
      }
    }
  }

  const deleteUploadFile = async (id = null) => {
    if (id) {
      await httpClient.put(`/v3/maintenance/manager/company/${comCode}/delete-maintenanceAttachment/${id}`);
    }
  }

  const handleKeyUpParts = (event) => {
    const ItemDataParts = [...dataParts];
    if (event.keyCode === 13) {

      formRefAddItemParts.validateFields((err, values) => {
        if (err) {
          return;
        } else {
          if (values.partNo) {
            const validatePartNo = ItemDataParts.filter((col) => {
              return _.trim(values.partNo) === _.trim(col.partNo)
            });
            if (validatePartNo.length > 0) {
              formRefAddItemParts.setFields({
                partNo: {
                  value: values.partNo,
                  errors: [new Error(intl.formatMessage({ id: 'duplicatePartNo', defaultMessage: 'part no. is  duplicate' }))]
                }
              })
              return;
            } else {
              const unitName = _.filter(unitData, (e) => { return e.itemsUnitId === values.unit });
              const unitNameString = _.map(unitName, 'itemsUnitName');

              ItemDataParts.push({
                partCode: values.partCode,
                partNo: values.partNo,
                partDescription: values.partName,
                partQty: Number(values.qty),
                partPrice: values.unitPrice === '.' ? _.floor(0, 2).toFixed(2) : _.floor(values.unitPrice, 2).toFixed(2),
                chargeAmount: _.floor(values.total, 2).toFixed(2),
                itemUnitId: values.unit,
                itemUnitName: unitNameString.toString(),
              })
              setDataParts(ItemDataParts);
              formRefAddItemParts.resetFields()
              formRefAddItemParts.setFieldsValue({ ['unitPrice']: undefined })
              setTriggerAddItemParts(current => !current)
            }
          }
        }
      })

    }
  }

  const addItemLabors = () => {
    const ItemDataLabors = [...dataLabors];
    formRefAddItemLabors.validateFields((err, values) => {
      if (err) {
        return;
      } else {
        if (values.description) {
          const validateDescription = ItemDataLabors.filter((col) => {
            return _.trim(values.description) === _.trim(col.laborDescription)
          });
          if (validateDescription.length > 0) {
            formRefAddItemLabors.setFields({
              description: {
                value: values.description,
                errors: [new Error(intl.formatMessage({ id: 'duplicateDescription', defaultMessage: 'description is duplicate' }))]

              }
            })
            return;
          } else {
            ItemDataLabors.push({
              laborCode: undefined,
              laborDescription: values.description,
              chargeAmount: values.amount === '.' ? _.floor(0, 2).toFixed(2) : _.floor(values.amount, 2).toFixed(2),
            })
            setDatalabors(ItemDataLabors);
            formRefAddItemLabors.resetFields()
            setTriggerAddItemLabors(current => !current)
          }
        }
      }
    })
  }

  const handleKeyUpLabors = (event) => {
    const ItemDataLabors = [...dataLabors];
    if (event.keyCode === 13) {
      formRefAddItemLabors.validateFields((err, values) => {
        if (err) {
          return;
        } else {
          if (values.description) {
            const validateDescription = ItemDataLabors.filter((col) => {
              return _.trim(values.description) === _.trim(col.laborDescription)
            });
            if (validateDescription.length > 0) {
              formRefAddItemLabors.setFields({
                description: {
                  value: values.description,
                  errors: [new Error(intl.formatMessage({ id: 'duplicateDescription', defaultMessage: 'description is duplicate' }))]
                }
              })
              return;
            } else {
              ItemDataLabors.push({
                laborCode: undefined,
                laborDescription: values.description,
                chargeAmount: values.amount === '.' ? _.floor(0, 2).toFixed(2) : _.floor(values.amount, 2).toFixed(2),
              })
              setDatalabors(ItemDataLabors);
              formRefAddItemLabors.resetFields()
              formRefAddItemLabors.setFieldsValue({ ['amount']: undefined })
              setTriggerAddItemLabors(current => !current)
            }
          }
        }
      })
    }
  }

  const handleCancelParts = () => {
    setVisibleParts(false);
    formRefAddItemParts.resetFields();
  };

  const handleCancelLabors = () => {
    setVisibleLabors(false);
    formRefAddItemLabors.resetFields();
  };

  const handleDeleteItemParts = (index) => {
    const dataItemNew = dataParts && dataParts.filter((col, i) => i !== index);
    setDataParts(dataItemNew);
  };

  const handleDeleteItemLabors = (index) => {
    const dataItemNew =
      dataLabors && dataLabors.filter((col, i) => i !== index);
    setDatalabors(dataItemNew);
  };

  const modalEditAttachment = (record) => {
    setEditAttachmentData(record);
    setVisibleEditAttachment(true);
  };

  const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    for (var i = 0; i < bstr.length; i++) {
      u8arr[i] = bstr.charCodeAt(i);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        720,
        720,
        'JPG',
        70,
        0,
        (uri) => {
          resolve(uri);
        },
        'base64',
      );
    });

  return (
    <div className="create-maintenance-report">
      <MaintenanceContext.Provider
        value={{
          mainState: {
            attachmentData,
            taxDiscountData,
            discountSelete,
            taxSelete,
            valueInputDiscount,
            valueInputAdjust,
            valueInputRemark,
            dataMaintenById,
            dataAttachmentById,
          },
          setMainState: {
            setAttachmentData,
            setDiscountSelete,
            setTaxSelete,
            setValueInputDiscount,
            setValueInputAdjust,
            setSumCalculate,
            setValueInputRemark,
            setDataAttachmentById,
          },
          fncMain: {
            modalEditAttachment,
          },
        }}
      >
        <Modal
          className="maintenance-report-modal"
          title={intl.formatMessage({
            id: 'modalMaintenanceReportTitleEdit',
            defaultMessage: 'Edit Maintenance Report',
          })}
          centered="true"
          width={1100}
          visible={visibleModal}
          onCancel={handleCancel}
          footer={[
            view ?
              <> <Button02 style={{margin : '0px 0px 0px 10px'}} key="back" btnsize="wd_df" onClick={handleCancel}>
                <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
              </Button02>
                <Button01
                  key="submit"
                  btnsize="wd_df"
                  type="primary"
                  disabled={disabledSave}
                  onClick={handleVisibleView}
                >
                  <FormattedMessage id="btnOk" defaultMessage="Ok" />
                </Button01> </>
              :
              <>
                <Button02 style={{margin : '0px 0px 0px 10px'}} key="back" btnsize="wd_df" onClick={handleCancel}>
                  <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
                </Button02>
                <Button01
                  key="submit"
                  btnsize="wd_df"
                  type="primary"
                  disabled={disabledSave}
                  onClick={handleEdit}
                >
                  <FormattedMessage id="btnSave" defaultMessage="Save" />
                </Button01>
              </>
          ]}
        >
          <Spin spinning={loading}>
            <div className="maintenance-modal-body">
              <div className="modal-container">
                <MaintenanceDetail
                  ref={saveMaintenanceDetail}
                  refAddress={refAddress}
                  vendorData={vendorData}
                  vendorAddress={vendorAddress}
                  setTrigger={setTrigger}
                  setVendorAddress={setVendorAddress}
                  itemName={itemName}
                  setItemName={setItemName}
                  itemNo={itemNo}
                  setItemNo={setItemNo}
                  itemCode={itemCode}
                  setItemCode={setItemCode}
                  itemNoAll={itemNoAll}
                  setItemNoAll={setItemNoAll}
                  setVenderId={setVenderId}
                  venderId={venderId}
                  itemMasterId={itemMasterId}
                  setItemMasterId={setItemMasterId}
                  addressId={addressId}
                  setAddressId={setAddressId}
                  itemNoId={itemNoId}
                  setItemNoId={setItemNoId}
                  numberState={numberState}
                  setNumberState={setNumberState}
                  view={view}
                />
                <MaintenanceParts
                  columns={columnsParts}
                  dataSource={dataParts}
                  setVisible={setVisibleParts}
                  view={view}
                />
                <MaintenanceLabors
                  columns={columnsLabors}
                  dataSource={dataLabors}
                  setVisible={setVisibleLabors}
                  view={view}
                />
                <MaintenanceCalculate
                  sumSubtotalParts={sumSubtotalParts}
                  sumSubtotalLabors={sumSubtotalLabors}
                  view={view}
                />
                <MaintenanceAttachment setVisible={setVisibleAttachment} view={view} />
              </div>
            </div>
          </Spin>
        </Modal>

        <ModalAddAttachment
          visible={visibleAttachment}
          setVisible={setVisibleAttachment}
          defaultData={dataAttachmentById}
        />

        <ModalEditAttachment
          visible={visibleEditAttachment}
          setVisible={setVisibleEditAttachment}
          defaultData={editAttachmentData}
          setEditAttachmentData={setEditAttachmentData}
          view={view}
        />
      </MaintenanceContext.Provider>

      <ModalAddParts
        ref={saveFormRefAddItemParts}
        visible={visibleParts}
        focusInput={focusInputParts}
        triggerAddItem={triggerAddItemParts}
        handleCancel={handleCancelParts}
        handleAddItem={addItemParts}
        handleKeyUp={handleKeyUpParts}
        unitData={unitData}
      />

      <ModalAddLabors
        ref={saveFormRefAddItemLabors}
        visible={visibleLabors}
        focusInput={focusInputLabors}
        triggerAddItem={triggerAddItemLabors}
        handleCancel={handleCancelLabors}
        handleAddItem={addItemLabors}
        handleKeyUp={handleKeyUpLabors}
      />
    </div>
  );
};

export default CreateMaintenanceReport;
