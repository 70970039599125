import React, { useContext, useState } from 'react';
import { PageSettings } from '../../config/page-settings';
import { OverviewContext } from './context';
import { StickyContainer } from 'react-sticky';
import { Button, Card, Col, Dropdown, Menu, Row, Tabs } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import Button_01 from '../../components/v2/button_01';
import _ from 'lodash';
import moment from 'moment';
import TabMapOverview from './map/index';
import Talaadthai from './component/talaadthai';
import ItemTop from './component/item-top';

const { TabPane } = Tabs;

const OverviewPage = ({ fullscreenPageRef }) => {
  const intl = useIntl();
  const { state, setState, func } = useContext(OverviewContext);

  const { tabKey } = state;
  const { setTabKey } = setState;
  const {} = func;

  const callback = (key) => {
    setTabKey(key);
  };

  return (
    <PageSettings.Consumer>
      {({ checkPermissionAction }) => (
        <div>
          <Row gutter={[16, 16]} style={{ backgroundColor: "#fafafa"}}>
            <Col span={24}>
              <Talaadthai fullscreenPageRef={fullscreenPageRef} />
            </Col>
            <Col span={24}>
              <ItemTop/>
            </Col>
            <Col span={24}>
              <Card className="monitor-main-card">
                <StickyContainer>
                  <Tabs
                    className="monitor-main-tabs customer-tab-margin"
                    size="large"
                    defaultActiveKey="map"
                    onChange={callback}
                    animated={false}
                    tabBarStyle={{ padding: '0 24px' }}
                  >
                    <TabPane
                      tab={intl.formatMessage({ id: `OverviewTabMap`, defaultMessage: 'Map' })}
                      key="map"
                      style={{ overflowY: 'scroll' }}
                    >
                      <TabMapOverview/>
                    </TabPane>

                    <TabPane tab={intl.formatMessage({ id: `OverviewTabRevenue`, defaultMessage: 'Revenue' })} key="revenue"></TabPane>

                    <TabPane
                      tab={intl.formatMessage({ id: `OverviewTabService`, defaultMessage: 'Service (Unlonding)' })}
                      key="service"
                    ></TabPane>

                    <TabPane tab={intl.formatMessage({ id: `OverviewTabVechicle`, defaultMessage: 'Vechicle' })} key="vechicle"></TabPane>
                  </Tabs>
                </StickyContainer>
              </Card>
            </Col>
          </Row>
        </div>
      )}
    </PageSettings.Consumer>
  );
};

export default OverviewPage;
