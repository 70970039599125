import React, { useContext, useState } from 'react';
import { TaskDetailsContext } from '../../monitor-context';
import _ from 'lodash';
import styles from './css/assign.css';
import stylesComponent from '../../component/css/component.css';
import { Row, Col, Timeline, Badge } from 'antd';
import styled from 'styled-components';
import CommentDetails from '../../component/comment-details';
import { useIntl, FormattedMessage } from 'react-intl';

const StatusName = styled.span`
  font-weight: bold;
  font-size: 13px;
  color: #1d3557;
`;

const TimeStamp = styled.span`
  font-size: 12px;
  color: #1d3557;
`;

const trackingData = [
  {
    statusName: 'In Progress',
    statusColor: '#51ab19',
    timeStamp: 'Mon, Feb 27, 2020 8:00 AM',
    comments: [
      {
        pathImage: 'http://192.168.11.181:8203/img/employee_profile/620184.jpg',
        name: 'กิตติชัย โปทาวี',
        timeStamp: 'Mon, Feb 27, 2020 8:00 AM',
        comments: 'xxxxxxxxxxxxxxxxxxxxxxxxx',
      },
      {
        pathImage: 'http://192.168.11.181:8203/img/1614158836_123',
        name: 'Nisakorn Pancharoen',
        timeStamp: 'Mon, Feb 27, 2020 8:00 AM',
        comments: 'sssssssssssssssssssssssssss',
      },
      {
        pathImage: 'http://192.168.11.181:8203/img/1612252597_620020',
        name: 'ewqe 32323',
        timeStamp: 'Mon, Feb 27, 2020 8:00 AM',
        comments: 'zzzzzzzzzzzzzzzzzzzzzzzzzz',
      },
    ],
  },
  {
    statusName: 'Accepted',
    statusColor: '#f88512',
    timeStamp: 'Mon, Feb 27, 2020 8:00 AM',
    comments: [
      {
        pathImage: 'http://192.168.11.181:8203/img/1612252597_620020',
        name: 'ewqe 32323',
        timeStamp: 'Mon, Feb 27, 2020 8:00 AM',
        comments: 'zzzzzzzzzzzzzzzzzzzzzzzzzz',
      },
    ],
  },
  {
    statusName: 'Assigned',
    statusColor: '#0c4da2',
    timeStamp: 'Mon, Feb 27, 2020 8:00 AM',
  },
  {
    statusName: 'Scheduled',
    statusColor: '#BCC5D3',
    timeStamp: 'Mon, Feb 27, 2020 8:00 AM',
    comments: [
      {
        pathImage: 'http://192.168.11.181:8203/img/1614158836_123',
        name: 'Nisakorn Pancharoen',
        timeStamp: 'Mon, Feb 27, 2020 8:00 AM',
        comments: 'sssssssssssssssssssssssssss',
      },
    ],
  },
  {
    statusName: 'New',
    statusColor: '#1890ff',
    timeStamp: 'Mon, Feb 27, 2020 8:00 AM',
  },
];

export default React.memo((props) => {
  const { tracking, LangCode } = props;

  const trackingTimeline =
    tracking &&
    tracking.map((item, index) => (
      <Timeline.Item key={index} dot={<Badge className="dot-badge" color={item.statusColor || '#BCC5D3'} />}>
        <div className="div-name-comment">
          <StatusName>{item.statusName || '-'}</StatusName>
          {item.comments ? <CommentDetails comments={item.comments || []} /> : null}
        </div>
        <TimeStamp>{item.timestampView || '-'}</TimeStamp>
      </Timeline.Item>
    ));

  return (
    <div>
      <Row className="tracking-row">
        <Col span={24} className="title-col-label">
          <span className="tracking-title-label">
            <FormattedMessage id={`monitorTabDetailModalAssigneeResourceTrackingTxtTitle${LangCode}`} defaultMessage="Tracking" />
          </span>
        </Col>
      </Row>
      <Row className="tracking-row">
        <Col span={24}>
          <Timeline className="assignee-tracking-timeline">{trackingTimeline}</Timeline>
        </Col>
      </Row>
    </div>
  );
});
