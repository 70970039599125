import React, { useRef } from 'react';
import { Row, Col, Form, Divider, Table, Popover, Button } from 'antd';
import '../../index.css';
import { useIntl, FormattedMessage } from 'react-intl';
import LabeRequireForm from '../../../../components/label-required-form';
import Button01 from '../../../../components/v2/button_01';
import _ from 'lodash';
import { numberFormatter, numberParser } from '../../../../component-function/fnc-inputnumber-currency';

const ModalAllowanceDetail = (props) => {
  const intl = useIntl();
  const { handleAddOrEdit, data, allowanceItem, handleDeleteItem, popOver, permissonEdit,isEdit } = props;
  const ref = useRef();
  const { visiblePopoverTable, recordPopover, offsetPopover, setVisiblePopoverTable, setRecordPopover, setOffsetPopover } = popOver;

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: 70,
      align: 'center',
      // render: (text, record, index) => index + 1
    },
    {
      title: intl.formatMessage({ id: 'resourceAllowanceAssignee', defaultMessage: 'Assignee' }),
      dataIndex: 'assignee',
      key: 'assignee',
      sorter: true,
    },
    {
      title: intl.formatMessage({ id: 'resourceAllowanceTeam', defaultMessage: 'Team' }),
      dataIndex: 'team',
      key: 'team',
      sorter: true,
    },
    {
      title: intl.formatMessage({ id: 'resourceAllowanceItem', defaultMessage: 'Item' }),
      dataIndex: 'item',
      key: 'item',
      sorter: true,
    },
    {
      title: intl.formatMessage({ id: 'resourceAllowanceRemark', defaultMessage: 'Remark' }),
      dataIndex: 'remark',
      key: 'remark',
      render: (text, record, index) => _.get(record, 'remark', '-'),
    },
    {
      title: intl.formatMessage({ id: 'resourceAllowanceAmount', defaultMessage: 'Amount' }),
      dataIndex: 'amount',
      key: 'amount',
      sorter: true,
      align: 'right',
      render: (text, record, index) => numberFormatter(_.get(record, 'amount', 0)) + ' ' + 'THB',
    },
  ];

  const fncActionTable = (record, offsetPopover, visiblePopoverTable, ref) => {
    return (
      <div>
        <Popover
          placement="right"
          trigger="hover"
          align={{ offset: offsetPopover }}
          visible={visiblePopoverTable}
          zIndex={10000}
          content={
            <div style={{ display: 'grid' }}>
              <div>
                <Button style={{ width: 100 }} type="link" ghost onClick={() => handleAddOrEdit(record)}>
                  <FormattedMessage id="btnEdit" defaultMessage="Edit" />
                </Button>
              </div>
              {_.get(record, 'isDefault') ? null : (
                <div>
                  <Button style={{ width: 100 }} type="link" ghost onClick={() => handleDeleteItem(record)}>
                    <FormattedMessage id="btnDelete" defaultMessage="Delete" />
                  </Button>
                </div>
              )}
            </div>
          }
        >
          <div style={{ width: 0, height: 0 }} ref={ref}></div>
        </Popover>
      </div>
    );
  };

  const handleMouseLeave = () => {
    setVisiblePopoverTable(false);
    setRecordPopover();
    setOffsetPopover([0, 0]);
  };

  return (
    <div>
      <Form layout="vertical" className="resourceAllowanceModalForm" style={{ padding: '24px' }}>
        <Row gutter={[16, 8]}>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'resourceAllowanceModalTaskNo', defaultMessage: 'Task No.' })}
                  req={false}
                />
              }
            >
              {_.get(data, 'taskNo', '-')}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'resourceAllowanceModalType', defaultMessage: 'Type' })} req={false} />
              }
            >
              {_.get(data, 'allowanceType', '-')}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'resourceAllowanceModalCustomer', defaultMessage: 'Customer' })}
                  req={false}
                />
              }
            >
              {_.get(data, 'customerName', '-')}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'resourceAllowanceModalProject', defaultMessage: 'Project' })}
                  req={false}
                />
              }
            >
              {_.get(data, 'project', '-')}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'resourceAllowanceModalTaskType', defaultMessage: 'Task Type' })}
                  req={false}
                />
              }
            >
              {_.get(data, 'taskType', '-')}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'resourceAllowanceModalSource', defaultMessage: 'Source' })} req={false} />
              }
            >
              {_.get(data, 'source', '-')}・{_.get(data, 'sourceFullAddress', '-')}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'resourceAllowanceModalDestination', defaultMessage: 'Destination' })}
                  req={false}
                />
              }
            >
              {_.get(data, 'destination', '-')} 2・{_.get(data, 'destinationFullAddress', '-')}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'resourceAllowanceModalDistance', defaultMessage: 'Distance (km)' })}
                  req={false}
                />
              }
            >
              {/* {_.get(data, 'allowanceTypeCode') === 'tripallowance' ? '-' : numberFormatter((numberParser(_.get(data, 'distance', '0')) / 1000).toFixed(2))} */}
              {_.get(data, 'allowanceTypeCode') === 'tripallowance' ? '-' : _.get(data, 'distance', '0')}
            </Form.Item>
          </Col>
        </Row>
        {/* {_.get(data, 'statusCode') === 'approve' ? (
          <>
            <Divider type="horizontal" />
            <Row gutter={[16, 8]}>
              <Col span={8}>
                <Form.Item
                  label={
                    <LabeRequireForm
                      text={intl.formatMessage({ id: 'resourceAllowanceModalApproveBy', defaultMessage: 'Approved by' })}
                      req={false}
                    />
                  }
                >
                  {_.get(data, 'approveBy', '-')}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label={
                    <LabeRequireForm
                      text={intl.formatMessage({ id: 'resourceAllowanceModalApproveDate', defaultMessage: 'Approved Date' })}
                      req={false}
                    />
                  }
                >
                  {_.get(data, 'approveDate', '-')}
                </Form.Item>
              </Col>
            </Row>
          </>
        ) : null}
        {_.get(data, 'statusCode') === 'reject' ? (
          <>
            <Divider type="horizontal" />
            <Row gutter={[16, 8]}>
              <Col span={8}>
                <Form.Item
                  label={
                    <LabeRequireForm
                      text={intl.formatMessage({ id: 'resourceAllowanceModalRejectBy', defaultMessage: 'Reject by' })}
                      req={false}
                    />
                  }
                >
                  {_.get(data, 'rejectBy', '-')}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label={
                    <LabeRequireForm
                      text={intl.formatMessage({ id: 'resourceAllowanceModalRejectDate', defaultMessage: 'Reject Date' })}
                      req={false}
                    />
                  }
                >
                  {_.get(data, 'rejectDate', '-')}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label={
                    <LabeRequireForm
                      text={intl.formatMessage({ id: 'resourceAllowanceModalRejectReason', defaultMessage: 'Reject Reason' })}
                      req={false}
                    />
                  }
                >
                  {_.get(data, 'rejectReason', '-')}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 8]}>
              <Col span={24}>
                <Form.Item
                  label={
                    <LabeRequireForm
                      text={intl.formatMessage({ id: 'resourceAllowanceModalRejectRemark', defaultMessage: 'Reject Remark' })}
                      req={false}
                    />
                  }
                >
                  {_.get(data, 'rejectRemark', '-')}
                </Form.Item>
              </Col>
            </Row>
          </>
        ) : null} */}
      </Form>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', padding: '0 24px' }}>
        <div style={{ fontSize: '14px', color: '#1D3557', fontWeight: 'bold' }}>
          <FormattedMessage id="resourceAllowanceModalAllowanceItem" defaultMessage="Allowance Item" />
        </div>
        {_.get(data, 'statusCode') === 'pending' && permissonEdit && isEdit ? (
          <div>
            <Button01 key="submit" type="primary" btnsize="wd_px" onClick={() => handleAddOrEdit()}>
              <FormattedMessage id="btnAdd" defaultMessage="Add" />
            </Button01>
          </div>
        ) : null}
      </div>
      <Divider type="horizontal" />
      <div style={{ padding: '0 24px' }} onMouseLeave={handleMouseLeave}>
        <Table
          dataSource={allowanceItem}
          columns={columns}
          pagination={false}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                if (!event.target.href && _.get(data, 'statusCode') === 'pending' && permissonEdit && isEdit) {
                  const { x, y } = ref.current.getBoundingClientRect();
                  setVisiblePopoverTable(true);
                  setOffsetPopover([event.pageX - x, event.pageY - y]);
                  setRecordPopover(record);
                }
              },
            };
          }}
        />
        {permissonEdit ? fncActionTable(recordPopover, offsetPopover, visiblePopoverTable, ref) : null}
      </div>
    </div>
  );
};

export default ModalAllowanceDetail;
