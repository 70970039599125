import httpClient from '../../components/axiosClient';

const comCode = localStorage.getItem('comCode');

const getAllorderList = async (payload) => {
  try {
    const response = await httpClient.post(`/v1/warehouse/manager/company/${comCode}/web/allorderrequest/page`, payload);
    if (response.status === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {}
};


const getAllorderDetail = async (id) => {
  try {
    const response = await httpClient.get(`/v1/warehouse/manager/company/${comCode}/web/orderrequestallinfo/${id}`);
    if (response.status === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {}
};

const getNextStatus = async (payload) => {
  try {
    const response = await httpClient.post(`/v1/warehouse/manager/company/${comCode}/web/orderrequest/nextstatus`, payload);
    if (response.status === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {}
};

const UpdateStatusAllOrder = async (payload) => {
  try {
    const response = await httpClient.put(`/v1/warehouse/manager/company/${comCode}/web/orderrequest/nextstatus`, payload);
    if (response.status === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {}
};


export {
  getAllorderList,
  getAllorderDetail,
  getNextStatus,
  UpdateStatusAllOrder,
};