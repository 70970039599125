import React, { useState, useRef, useEffect, useContext } from 'react'
import { Modal, Row, Col, Divider, Table, Popover, Button } from 'antd'
import { useIntl, FormattedMessage } from 'react-intl'
import Button02 from '../v2/button_02'
import Button01 from '../v2/button_01'
import _ from 'lodash'
import './index.css'
import AddTravelExpense from './components/add-edit'
import { getTravelExpenseDetail, deleteTravelExpense } from '../../controllers/travel-expenses/travel-expenses'
import { successNotification, errorNotification } from '../v2/notification'
import FormModalStatus from './components/status'
import { PageSettings } from '../../config/page-settings'

const ModalTravelExpense = (props) => {
  const { checkPermissionAction } = useContext(PageSettings);
  const { visible, setVisible, initialDetail, travelExpenseId, tab, setMainTrigger } = props
  const intl = useIntl()
  const [visibleModal, setVisibleModal] = useState(false)
  const [typeModal, setTypeModal] = useState("")
  const ref = useRef();
  const [visiblePopoverTable, setVisiblePopoverTable] = useState();
  const [recordPopover, setRecordPopover] = useState();
  const [offsetPopover, setOffsetPopover] = useState([0, 0]);
  const [detailData, setDetailData] = useState([])
  const [defaultData, setDefaultData] = useState()
  const [trigger, setTrigger] = useState(false)
  const [visibleModalStatus, setVisibleModalStatus] = useState(false)
  const [statusSave, setStatusSave] = useState()

  useEffect(() => {
    async function getDetail() {
      const response = await getTravelExpenseDetail(travelExpenseId)
      setDetailData(_.get(response, 'data.data'))
      // console.log("getTravelExpenseDetail", response)
    }
    if (travelExpenseId) {
      getDetail()
    }
  }, [travelExpenseId, trigger])


  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
      render: (text, record, index) => index + 1
    },
    {
      title: 'Item',
      dataIndex: 'additionalItemName',
      key: 'additionalItemName',
      sorter: true,
      width: 150
    },
    {
      title: 'Type',
      dataIndex: 'additionalItemTypeName',
      key: 'additionalItemTypeName',
      sorter: true,
      width: 100
    },
    {
      title: 'Amount',
      dataIndex: 'amountTxt',
      key: 'amountTxt',
      sorter: true,
      width: 150
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      sorter: true,
      width: 400
    },
  ]

  const handleSave = async (status) => {
    setVisibleModalStatus(true)
    setStatusSave(status)
  }

  const handleClose = () => {
    setVisible(false)
    setMainTrigger(current => !current)
  }

  const handleAdd = (value, data) => {
    setVisibleModal(true)
    setTypeModal(value)
    setDefaultData(data)
  }

  const handleMouseLeave = () => {
    setVisiblePopoverTable(false);
    setRecordPopover();
    setOffsetPopover([0, 0]);
  };

  const checkStatus = tab === "waiting" && checkPermissionAction("P50PG1C6", "P50PG1C6A4") ? [
    <Button02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleClose}>
      <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
    </Button02>,
    <Button01 key="submit" type="primary" btnsize="wd_df" onClick={() => handleSave("approve")} style={{ marginRight: 'unset' }}>
      <FormattedMessage id="btnApproved" defaultMessage="Approved" />
    </Button01>,
    <Button01 key="submit" type="primary" btnsize="wd_df" onClick={() => handleSave("paid")}>
      <FormattedMessage id="btnPaid" defaultMessage="Paid" />
    </Button01>,
    <Button01 key="submit" type="primary" btnsize="wd_df" onClick={() => handleSave("reject")} style={{ margin: 'unset' }}>
      <FormattedMessage id="btnRejected" defaultMessage="Rejected" />
    </Button01>,
  ] : tab === "approve" && checkPermissionAction("P50PG1C6", "P50PG1C6A1") ? [
    <Button02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleClose}>
      <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
    </Button02>,
    <Button01 key="submit" type="primary" btnsize="wd_df" onClick={() => handleSave("waiting")} style={{ marginRight: 'unset' }}>
      <FormattedMessage id="btnWaiting" defaultMessage="Waiting" />
    </Button01>,
    <Button01 key="submit" type="primary" btnsize="wd_df" onClick={() => handleSave("paid")}>
      <FormattedMessage id="btnPaid" defaultMessage="Paid" />
    </Button01>,
    <Button01 key="submit" type="primary" btnsize="wd_df" onClick={() => handleSave("reject")} style={{ margin: 'unset' }}>
      <FormattedMessage id="btnRejected" defaultMessage="Rejected" />
    </Button01>,
  ] : tab === "paid" && checkPermissionAction("P50PG1C6", "P50PG1C6A2") ? [
    <Button02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleClose}>
      <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
    </Button02>,
    <Button01 key="submit" type="primary" btnsize="wd_df" onClick={() => handleSave("waiting")} style={{ marginRight: 'unset' }}>
      <FormattedMessage id="btnWaiting" defaultMessage="Waiting" />
    </Button01>,
    <Button01 key="submit" type="primary" btnsize="wd_df" onClick={() => handleSave("approve")}>
      <FormattedMessage id="btnApproved" defaultMessage="Approved" />
    </Button01>,
    <Button01 key="submit" type="primary" btnsize="wd_df" onClick={() => handleSave("reject")} style={{ margin: 'unset' }}>
      <FormattedMessage id="btnRejected" defaultMessage="Rejected" />
    </Button01>,
  ] : tab === "reject" && checkPermissionAction("P50PG1C6", "P50PG1C6A3") ? [
    <Button02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleClose}>
      <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
    </Button02>,
    <Button01 key="submit" type="primary" btnsize="wd_df" onClick={() => handleSave("waiting")} style={{ marginRight: 'unset' }}>
      <FormattedMessage id="btnWaiting" defaultMessage="Waiting" />
    </Button01>,
    <Button01 key="submit" type="primary" btnsize="wd_df" onClick={() => handleSave("approve")}>
      <FormattedMessage id="btnApproved" defaultMessage="Approved" />
    </Button01>,
    <Button01 key="submit" type="primary" btnsize="wd_df" onClick={() => handleSave("paid")} style={{ margin: 'unset' }}>
      <FormattedMessage id="btnPaid" defaultMessage="Paid" />
    </Button01>,
  ] : [
    <Button02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleClose}>
      <FormattedMessage id="btnClose" defaultMessage="Close" />
    </Button02>,
  ]

  const fncActionTable = (record, offsetPopover, visiblePopoverTable, ref) => {
    return (
      <PageSettings.Consumer>
        {({ checkPermissionAction }) => (
          <div>
            {
              checkPermissionAction("P50PG1C6", "P50PG1C6A6") || checkPermissionAction("P50PG1C6", "P50PG1C6A7") ? (
                <Popover
                  placement="right"
                  trigger="hover"
                  align={{ offset: offsetPopover }}
                  visible={visiblePopoverTable}
                  zIndex={10000}
                  content={
                    <div style={{ display: 'grid' }}>
                      {
                        checkPermissionAction("P50PG1C6", "P50PG1C6A6") ? (
                          <Button style={{ width: 100 }} type="link" ghost onClick={() => handleAdd("edit", record)}>
                            <FormattedMessage id="btnEdit" defaultMessage="Edit" />
                          </Button>
                        ) : null
                      }
                      {
                        checkPermissionAction("P50PG1C6", "P50PG1C6A7") ? (
                          <Button style={{ width: 100 }} type="link" ghost onClick={() => handleDelete(record.travelExpenseDetailId)}>
                            <FormattedMessage id="btnDelete" defaultMessage="Delete" />
                          </Button>
                        ) : null
                      }

                    </div>
                  }
                >
                  <div style={{ width: 0, height: 0 }} ref={ref}></div>
                </Popover>
              ) : null
            }

          </div>
        )}
      </PageSettings.Consumer>

    );
  };

  const handleDelete = (id) => {
    Modal.confirm({
      className: 'middleConfirmModal',
      centered: true,
      icon: null,
      title: intl.formatMessage({ id: 'lblDelete', defaultMessage: 'Are you sure you want to delete ?' }),
      okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        try {
          const response = await deleteTravelExpense(id)
          if (_.get(response, 'data.status.code') === 200) {
            successNotification(_.get(response, 'data.status.message'))
            setTrigger(current => !current)
          } else {
            errorNotification(_.get(response, 'data.status.message'))
          }
        } catch (error) {
          errorNotification(_.get(error, 'response.data.status.message'))
        }

      },
      onCancel() { },
    });
  };



  return (
    <PageSettings.Consumer>
      {({ checkPermissionAction }) => (
        <>
          <Modal
            title={intl.formatMessage({ id: 'modalTravelExpenseText', defaultMessage: 'Travelling Expense' })}
            width={700}
            centered={true}
            visible={visible}
            onCancel={handleClose}
            bodyStyle={{ padding: 'unset', height: '636px', overflowY: 'scroll' }}
            footer={checkStatus}
          >
            <div className='modalTravelExpenseContent'>
              <Row gutter={[16, 16]}>
                <Col span={8}>
                  <div className='modalTravelExpenseHead'>
                    <FormattedMessage id="modalTravelExpenseTextEmployee" defaultMessage="Employee" />
                  </div>
                  <div className='modalTravelExpenseDetail'>
                    {_.get(detailData, 'employee')}
                  </div>
                </Col>
                <Col span={8}>
                  <div className='modalTravelExpenseHead'>
                    <FormattedMessage id="modalTravelExpenseTextTeam" defaultMessage="Team" />
                  </div>
                  <div className='modalTravelExpenseDetail'>
                    {_.get(detailData, 'tesm')}
                  </div>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={8}>
                  <div className='modalTravelExpenseHead'>
                    <FormattedMessage id="modalTravelExpenseTextTripOrTask" defaultMessage="Trip or Task No." />
                  </div>
                  <div className='modalTravelExpenseDetail'>
                    {_.get(detailData, 'tripTaskNo')}
                  </div>
                </Col>
                <Col span={8}>
                  <div className='modalTravelExpenseHead'>
                    <FormattedMessage id="modalTravelExpenseTextStartDate" defaultMessage="Start Date" />
                  </div>
                  <div className='modalTravelExpenseDetail'>
                    {_.get(detailData, 'startDateTxt')}
                  </div>
                </Col>
                <Col span={8}>
                  <div className='modalTravelExpenseHead'>
                    <FormattedMessage id="modalTravelExpenseTextDueDate" defaultMessage="Due Date" />
                  </div>
                  <div className='modalTravelExpenseDetail'>
                    {_.get(detailData, 'dueDateTxt')}
                  </div>
                </Col>
              </Row>
            </div>
            <div style={{ padding: '0 24px' }}>
              <div className='modalTravelExpenseContentBetween'>
                <div className='modalTravelExpenseTextMiddle'>
                  <FormattedMessage id="modalTravelExpenseText" defaultMessage="Travelling Expense" />
                </div>
                {
                  checkPermissionAction("P50PG1C6", "P50PG1C6A5") ? (
                    <div>
                      <Button01 key="submit" type="primary" btnsize="wd_df" onClick={() => handleAdd("add")}>
                        <FormattedMessage id="btnAdd" defaultMessage="Add" />
                      </Button01>
                    </div>
                  ) : null
                }
              </div>
            </div>
            <Divider type="horizontal" style={{ margin: '16px 0' }} />
            <div style={{ padding: '0 24px', height: '254px' }} onMouseLeave={handleMouseLeave}>
              <Table
                columns={columns}
                dataSource={_.get(detailData, 'travelExpenseDetails')}
                pagination={false}
                scroll={{ x: _.sumBy(columns, 'width'), y: 200 }}
                onRow={(record, rowIndex) => {
                  return {
                    onClick: (event) => {
                      if (!event.target.href && record.isManualCreate) {
                        const { x, y } = ref.current.getBoundingClientRect();
                        setVisiblePopoverTable(true);
                        setOffsetPopover([event.pageX - x, event.pageY - y]);
                        setRecordPopover(record);
                      }
                    },
                  };
                }}
              />
              {fncActionTable(recordPopover, offsetPopover, visiblePopoverTable, ref)}
            </div>
            <div style={{ padding: '16px 24px' }}>
              <Row gutter={[16, 16]}>
                <Col span={8}>
                  <div className='modalTravelExpenseSummaryText'>
                    <FormattedMessage id="modalTravelTripAllowanceText" defaultMessage="Trip Allowance" />
                  </div>
                </Col>
                <Col span={8}>
                  <div style={{ textAlign: 'end' }} className='modalTravelExpenseSummaryText'>
                    <FormattedMessage id="modalTravelTotaltext" defaultMessage="Total" />
                  </div>
                </Col>
                <Col span={8}>
                  <div style={{ textAlign: 'end' }} className='modalTravelExpenseSummaryValue'>
                    {_.get(detailData, 'travelExpenseSummary.toalTxt')}
                  </div>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={8}>
                  <Row>
                    <Col span={12}>
                      <div className='modalTravelExpenseSummaryValue'>
                        <FormattedMessage id="modalTravelTextDriver1" defaultMessage="Driver 1" />
                      </div>
                    </Col>
                    <Col span={12}>
                      <div style={{ textAlign: 'end' }} className='modalTravelExpenseSummaryValue'>
                        {_.get(detailData, 'travelExpenseSummary.driver1Txt')}
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col span={8}>
                  <div style={{ textAlign: 'end' }} className='modalTravelExpenseSummaryText'>
                    <FormattedMessage id="modalTravelTripAllowancetext" defaultMessage="Trip Allowance" />
                  </div>
                </Col>
                <Col span={8}>
                  <div style={{ textAlign: 'end' }} className='modalTravelExpenseSummaryValue'>
                    {_.get(detailData, 'travelExpenseSummary.tripAllowanceTxt')}
                  </div>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={8}>
                  <Row>
                    <Col span={12}>
                      <div className='modalTravelExpenseSummaryValue'>
                      <FormattedMessage id="modalTravelTextDriver2" defaultMessage="Driver 2" />
                      </div>
                    </Col>
                    <Col span={12}>
                      <div style={{ textAlign: 'end' }} className='modalTravelExpenseSummaryValue'>
                        {_.get(detailData, 'travelExpenseSummary.driver2Txt')}
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col span={8}>
                  <div style={{ textAlign: 'end' }} className='modalTravelExpenseSummaryText'>
                    <FormattedMessage id="modalTravelTotalExpensetext" defaultMessage="Total Expense" />
                  </div>
                </Col>
                <Col span={8}>
                  <div style={{ textAlign: 'end' }} className='modalTravelExpenseSummaryValue'>
                    {_.get(detailData, 'travelExpenseSummary.totalExpenseTxt')}
                  </div>
                </Col>
              </Row>
            </div>
            <AddTravelExpense
              visible={visibleModal}
              setVisible={setVisibleModal}
              type={typeModal}
              initialDetail={initialDetail}
              defaultData={defaultData}
              detailData={detailData}
              setTrigger={setTrigger}
            />

            <FormModalStatus
              visible={visibleModalStatus}
              setVisible={setVisibleModalStatus}
              status={statusSave}
              detailData={detailData}
              initialDetail={initialDetail}
              setVisibleCenter={setVisible}
              setMainTrigger={setMainTrigger}
            />
          </Modal >
        </>
      )}
    </PageSettings.Consumer>
  )
}

export default ModalTravelExpense
