import React, { useState, useEffect } from 'react';
import Filterrequest from './filterrequest';
import { MyrequestContext } from './myrequest-context';
import { Card, Col, Row, Tabs } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import TableRequest from './tablerequest';
import _ from 'lodash';
import NoDataBackground from '../../components/no-data-page';

import {
  getMenuFilter,
  getSearchApproval,
  approveAllRequest,
  rejectAllRequest,
  cancelItemRequest,
} from '../../controllers/approval/approval-controller';

import { PageSettings } from '../../config/page-settings';
import getMyrequest from '../../controllers/myrequest/myrequest-api';
import MainTypeItemRequest from '../../components/modal-request-item';
import { errorNotification, successNotification } from '../../components/v2/notification';

function MyRequest() {
  const [checkedListRequest, setCheckedListRequest] = useState();
  const [checkteam, setCheckTeam] = useState();
  const [orgIdRaw, setOrgIdRaw] = useState();
  const [spanRight, setSpanRight] = useState(18);
  const [spanLeft, setSpanLeft] = useState(6);
  const [menuList, setMenuList] = useState([]);
  const [indeterminateRequest, setIndeterminateRequest] = useState(false);
  const [indeterminateTeam, setIndeterminateTeam] = useState(false);
  const [checkedKeysRequest, setCheckedKeysRequest] = useState([]);
  const [checkedKeysTeam, setCheckedKeysTeam] = useState([]);
  const [searchListMyrequest, setSearchListMyrequest] = useState([]);
  const [changeStatus, setChangeStatus] = useState([]);
  const [changeTab, setChangeTab] = useState('1');
  const [checkAllRequest, setCheckAllRequest] = useState(false);
  const [checkAllTeam, setCheckAllTeam] = useState(false);
  const [fillter, setFillter] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [toggle, setToggle] = useState(true);
  const [event, setEvent] = useState(false);
  const [disbleApply, setDisbleApply] = useState(true);
  const [apiDisplay, setApiDisplay] = useState(false);
  const [rightCardDisplay, setRightCardDisplay] = useState(false);

  //----------------------------------------------------------
  const [visibleItemRequest, setVisibleItemRequest] = useState(false);
  const [mainTrigger, setMainTrigger] = useState(false);
  const [recordRequest, setRecordRequest] = useState([]);
  //------------------------------------------------------------
  const [fieldSort, setFieldSort] = useState();
  const [orderSort, setOrderSort] = useState();
  const [paginationPage, setPaginationPage] = useState(1);
  const [paginationSize, setPaginationSize] = useState(10);

  const handleToggle = () => {
    setToggle(!toggle);
  };

  useEffect(() => {
    const getApiMenu = async () => {
      const response = await getMenuFilter();
      setMenuList(response.data);
    };
    getApiMenu();
    // getApiSearch();
  }, []);

  useEffect(() => {
    if (_.size(fillter) > 0) {
      setDisbleApply(false);
    } else {
      setDisbleApply(true);
    }
  }, [fillter]);

  useEffect(() => {
    setIsLoading(true);
    setDisbleApply(true);
    setApiDisplay(true);
    if (apiDisplay === true) {
      setTimeout(() => {
        getApiSearch();
        setIsLoading(false);
      }, 1000);
    }
  }, [changeTab, event,paginationPage,paginationSize,fieldSort,orderSort]);

  const getApiSearch = async (value) => {
    let payload = {
      requestTypes: checkedKeysRequest,
      teams: checkedKeysTeam,
      pageNumber: paginationPage,
      limit: paginationSize,
      requestor: _.get(fillter, 'requestor'),
      requestNo: _.get(fillter, 'reno'),
      submitDateFrom: _.get(fillter, 'pmdatestart'),
      submitDateTo: _.get(fillter, 'pmdateto'),
      statusTxt: _.get(fillter, 'statusTxt'),
      statusCodeList: _.get(fillter, 'statusTxt') ? [_.get(fillter, 'statusTxt')] : undefined,
      orderBy: fieldSort ? fieldSort : undefined,
      orderType: orderSort ? orderSort : undefined,
    };
    const response = await getMyrequest(payload);
    console.log('resSearch', response.data);
    setSearchListMyrequest(response.data);
  };

  const changeToApproval = async () => {
    setIsLoading(true);
    let payload = { requestIdList: changeStatus };
    const response = await approveAllRequest(payload);
    console.log('changeStatusApp', response);
    if (_.get(response.status, 'code') === 1) {
      setTimeout(() => {
        getApiSearch();
        setIsLoading(false);
      }, 2000);
    }
  };

  const changeToReject = async () => {
    setIsLoading(true);
    let payload = { requestIdList: changeStatus };
    const response = await rejectAllRequest(payload);
    console.log('changeStatusRe', response);
    setTimeout(() => {
      getApiSearch();
      setIsLoading(false);
    }, 2000);
  };

  let getAllKeys = (tree) => {
    let result = [];

    _.forEach(tree, (x) => {
      let childKeys = [];

      if (x.reqTypeList) {
        childKeys = getAllKeys(x.reqTypeList);
      }
      result.push(...[x.reqTypeCode, ...childKeys]);
    });

    return result;
  };
  const allKeysRequest = getAllKeys(_.get(menuList, 'data.reqTypeGroup'));
  console.log('main allKeysRequest:::', allKeysRequest);
  const onCheckRequest = (checkedKeys) => {
    console.log('checkedKeys', checkedKeys, checkAllRequest);
    setCheckedKeysRequest(checkedKeys);
    setIndeterminateRequest(!!_.size(checkedKeys) && _.size(checkedKeys) < _.size(allKeysRequest));
    setDisbleApply(false);
    setCheckAllRequest(_.size(checkedKeys) === _.size(allKeysRequest));
  };
  const allKeysTeam = getAllKeys(_.get(menuList, 'data.team'));
  const onCheckTeam = (checkedKeys) => {
    setCheckedKeysTeam(checkedKeys);
    setIndeterminateTeam(!!_.size(checkedKeys) && _.size(checkedKeys) < _.size(allKeysTeam));
    setCheckAllTeam(_.size(checkedKeys) === _.size(allKeysTeam));
    setDisbleApply(false);
  };

  const handleItemRequest = (value) => {
    setVisibleItemRequest(true);
    setRecordRequest(value);
  };

  const changeToCancel = async (value) => {
    setIsLoading(true);
    const response = await cancelItemRequest(_.get(recordRequest, 'memReqId'), value);
    if (_.get(response.status, 'code') === 200) {
      successNotification(_.get(response.status, 'message'));
      setTimeout(() => {
        getApiSearch();
        setIsLoading(false);
        setVisibleItemRequest(false);
      }, 2000);
    } else {
      errorNotification(_.get(response.status, 'message'));
      setIsLoading(false);
    }
  };

  const handleChange = (pagination, sorter, extra) => {
    let field = extra.field;
    if (extra.order) {
      if (extra.order === 'descend') {
        setOrderSort('desc');
      } else {
        setOrderSort('asc');
      }
    } else {
      setOrderSort();
    }
    setFieldSort(field);
    setPaginationSize(pagination.pageSize);
    setPaginationPage(pagination.current);
  };

  return (
    <PageSettings.Consumer>
      {({ checkPermissionAction }) =>
        checkPermissionAction(`P49PG1C1`, `P49PG1C1A1`) ? (
          <MyrequestContext.Provider
            value={{
              state: {
                toggle,
                checkedListRequest,
                checkteam,
                orgIdRaw,
                paginationPage,
                spanRight,
                spanLeft,
                menuList,
                indeterminateRequest,
                indeterminateTeam,
                checkedKeysRequest,
                checkedKeysTeam,
                searchListMyrequest,
                checkAllRequest,
                checkAllTeam,
                changeTab,
                isLoading,
                disbleApply,
                rightCardDisplay,
              },
              setState: {
                setSpanRight,
                setSpanLeft,
                setCheckedListRequest,
                setCheckTeam,
                setOrgIdRaw,
                setPaginationPage,
                setCheckedKeysRequest,
                setCheckedKeysTeam,
                setIndeterminateRequest,
                setIndeterminateTeam,
                setChangeStatus,
                setChangeTab,
                setCheckAllRequest,
                setCheckAllTeam,
                setIsLoading,
                setFillter,
                setEvent,
                setDisbleApply,
                setRightCardDisplay,
              },
              fnc: {
                handleToggle,
                onCheckRequest,
                onCheckTeam,
                getApiSearch,
                changeToApproval,
                changeToReject,
                handleItemRequest,
                handleChange,
              },
            }}
          >
            <Row gutter={[16, 16]}>
              <Col span={toggle ? 6 : 0}>
                <Filterrequest />
              </Col>
              <Col span={toggle ? 18 : 24}>
                <TableRequest />
              </Col>
            </Row>
            <MainTypeItemRequest
              visible={visibleItemRequest}
              setVisible={setVisibleItemRequest}
              setMainTrigger={setMainTrigger}
              typeModal={'my'}
              recordRequest={recordRequest}
              changeToCancel={changeToCancel}
              changeTab={'2'}
            />
          </MyrequestContext.Provider>
        ) : null
      }
    </PageSettings.Consumer>
  );
}

export default MyRequest;
