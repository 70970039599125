import httpClient from '../../components/axiosClient';

const getPrivacyType = async () => {

  const comCode = localStorage.getItem('comCode');

  try {
    const response = await httpClient.get(`/v3/business-partner/manager/company/${comCode}/privacy-types`);


    if (response.status == 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
}

export default getPrivacyType;