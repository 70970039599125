import { Button, Divider, Modal, Popover, Table } from 'antd';
import _ from 'lodash';
import React, { useState, useRef } from 'react';
import { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Button_01 from '../../../components/v2/button_01';
import { errorNotification, successNotification } from '../../../components/v2/notification';
import { deleteWarehouseSetting } from '../../../controllers/warehouse/warehouse';
import WarehouseContext from '../context';
import WarehouseSettingModalForm from '../warehouse-modal/warehouseModalSettingForm';
import './css/index.css';
import { PageSettings } from '../../../config/page-settings';

const CardWarehouseSetting = () => {
  const intl = useIntl();
  const ref = useRef();
  const app = useContext(PageSettings);
  const { checkPermissionAction } = app;

  const { state, setState, fnc } = useContext(WarehouseContext);
  const { setTrigger } = setState;
  const { warehouseServiceDataList, roleCheck } = state;

  const [isOpen, setIsopen] = useState(false);
  const [serviceRecord, setServiceRecord] = useState();
  const [visiblePopoverTable, setVisiblePopoverTable] = useState();
  const [recordPopover, setRecordPopover] = useState();
  const [offsetPopover, setOffsetPopover] = useState([0, 0]);
  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
      render: (text, record, index) => index + 1,
    },
    {
      title: intl.formatMessage({
        id: `warehouseSettingDay`,
        defaultMessage: 'Day',
      }),
      dataIndex: 'day',
      key: 'day',
      sorter: (a, b) => a.day.localeCompare(b.day),
    },
    {
      title: intl.formatMessage({
        id: `warehouseSettingServiceArea`,
        defaultMessage: 'Service Area',
      }),
      dataIndex: 'areaName',
      key: 'areaName',
    },
    {
      title: intl.formatMessage({
        id: `warehouseSettingPeriod`,
        defaultMessage: 'Period',
      }),
      dataIndex: 'period',
      key: 'period',
    },
  ];

  const openModal = () => {
    setIsopen(true);
  };

  const openModalEdit = (record) => {
    setIsopen(true);
    setServiceRecord(record);
  };

  const handleDelete = (id) => {
    Modal.confirm({
      className: 'maintenance-report-modal-confirm',
      centered: true,
      icon: null,
      title: intl.formatMessage({ id: 'lblDelete', defaultMessage: 'Are you sure you want to delete ?' }),
      okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        try {
          const response = await deleteWarehouseSetting(id);
          if (response.status === 200) {
            successNotification(_.get(response.data.status, 'message'));
            setTrigger((current) => !current);
          } else {
            errorNotification(_.get(response.data.status, 'message'));
          }
        } catch (error) {
          errorNotification(_.get(error.response.data.status, 'message'));
        }
      },
      onCancel() { },
    });
  };

  const handleMouseLeave = () => {
    setVisiblePopoverTable(false);
    setRecordPopover();
    setOffsetPopover([0, 0]);
  };

  const fncActionTable = (record, offsetPopover, visiblePopoverTable, ref) => {
    return (
      <div>
        <Popover
          placement="right"
          trigger="hover"
          align={{ offset: offsetPopover }}
          visible={visiblePopoverTable}
          zIndex={10000}
          content={
            <div style={{ display: 'grid' }}>
              {checkPermissionAction(`P52PG1C7`, `P52PG1C7A3`) ? (
              <div>
                <Button style={{ width: 100 }} type="link" ghost onClick={() => openModalEdit(record)}>
                  <FormattedMessage id="orgTabMemberBtnEdit" defaultMessage="Edit" />
                </Button>
              </div>
              ) : null}
              {checkPermissionAction(`P52PG1C7`, `P52PG1C7A4`) ? (
              <div>
                <Button style={{ width: 100 }} type="link" ghost onClick={() => handleDelete(record.id)}>
                  <FormattedMessage id="btnDelete" defaultMessage="Delete" />
                </Button>
              </div>
               ) : null}
            </div>
          }
        >
          <div style={{ width: 0, height: 0 }} ref={ref}></div>
        </Popover>
      </div>
    );
  };

  return (
    <div>
      <div className="layout-wh-manager">
        <div>
          <p className="layout-wh-manager-title">
            <FormattedMessage id="warehouseServiceAndTimeTitle" defaultMessage="Service Area and Time" />
          </p>
        </div>
        <div>
          { checkPermissionAction(`P52PG1C7`, `P52PG1C7A2`) ? 
            roleCheck ? (
              <Button_01 key="submit" type="primary" btnsize="wd_df" style={{ marginRight: 'unset' }} onClick={() => openModal()}>
                <FormattedMessage id="btnCreate" defaultMessage="Create" />
              </Button_01>
            ) : null : null
          }
        </div>
      </div>
      <Divider type="horizontal" style={{ marginTop: '12px', marginBottom: '12px' }} />
      <div className="warehouse-layout-details" onMouseLeave={handleMouseLeave}>
        <Table
          rowKey={(record) => record.id}
          dataSource={warehouseServiceDataList}
          columns={columns}
          pagination={false}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                if (
                  checkPermissionAction('P52PG1C7', 'P52PG1C7A3') ||
                  checkPermissionAction('P52PG1C7', 'P52PG1C7A4') 
                ) {
                if (!event.target.href && roleCheck) {
                  const { x, y } = ref.current.getBoundingClientRect();
                  setVisiblePopoverTable(true);
                  setOffsetPopover([event.pageX - x, event.pageY - y]);
                  setRecordPopover(record);
                }
              }
              },
            };
          }}
        />
        {roleCheck ? fncActionTable(recordPopover, offsetPopover, visiblePopoverTable, ref) : null}
      </div>
      <WarehouseSettingModalForm record={serviceRecord} setRecord={setServiceRecord} visible={isOpen} setVisible={setIsopen} />
    </div>
  );
};

export default CardWarehouseSetting;
