import React from 'react'
import { Form, Modal, Input, Select, Row, Col } from 'antd'
import Button02 from '../../v2/button_02'
import Button01 from '../../v2/button_01'
import { useIntl, FormattedMessage } from 'react-intl'
import _ from 'lodash'
import '../index.css'
import LabeRequireForm from '../../label-required-form'
import { updateStatusTravelExpense } from '../../../controllers/travel-expenses/travel-expenses'
import { successNotification, errorNotification } from '../../v2/notification'

const { TextArea } = Input
const { Option } = Select

const ModalStatus = (props) => {
  const { visible, setVisible, status, detailData, form, initialDetail, setVisibleCenter, setMainTrigger } = props
  const { getFieldDecorator, resetFields, validateFields } = form;
  const intl = useIntl()
  const handleSave = () => {
    validateFields(async (err, values) => {
      if (err) {
        return;
      }
      try {
        const body = {
          "status": status,
          "reason": _.get(values, 'reason') || "",
          "remark": _.get(values, 'remark')
        }
        const response = await updateStatusTravelExpense(_.get(detailData, 'travelExpenseId'), body)
        if (_.get(response, 'data.status.code') === 200) {
          successNotification(_.get(response, 'data.status.message'))
          setVisible(false)
          setVisibleCenter(false)
          resetFields()
          setMainTrigger(curent => !curent)
        } else {
          errorNotification(_.get(response, 'data.status.message'))
        }
      } catch (error) {
        errorNotification(_.get(error, 'response.data.status.message'))
      }
    })
  }

  const handleCancel = () => {
    setVisible(false)
    resetFields()
  }

  const Header = () => (<span>{status === "waiting" ? intl.formatMessage({ id: 'btnWaiting', defaultMessage: 'Waiting' })
    : status === "approve" ? intl.formatMessage({ id: 'btnApproved', defaultMessage: 'Approved' })
      : status === "paid" ? intl.formatMessage({ id: 'btnPaid', defaultMessage: 'Paid' })
        : intl.formatMessage({ id: 'btnRejected', defaultMessage: 'Rejected' })}・{_.get(detailData, 'tripTaskNo')}</span>)

  return (
    <Modal
      title={<Header />}
      visible={visible}
      onCancel={handleCancel}
      centered={true}
      width={373}
      bodyStyle={{ padding: '16px 24px' }}
      footer={[
        <Button02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleCancel}>
          <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
        </Button02>,
        <Button01 key="submit" type="primary" btnsize="wd_df" onClick={handleSave}>
          <FormattedMessage id="btnSave" defaultMessage="Save" />
        </Button01>,
      ]}
    >
      <Form form={form} layout="vertical" className='modalTravelExpenseAddForm'>
        {
          status === "reject" ? (
            <Row gutter={[8, 8]}>
              <Col span={24}>
                <Form.Item
                  label={
                    <LabeRequireForm text={intl.formatMessage({ id: 'modalTravelExpenseStatusReasonText', defaultMessage: 'Reason' })} req={true} />
                  }
                >
                  {getFieldDecorator('reason', {
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({ id: 'modalTravelExpenseStatusReasonValidate', defaultMessage: 'Please Select Reason' }),
                      },
                    ],
                  })(
                    <Select
                      placeholder={intl.formatMessage({ id: 'modalTravelExpenseStatusReasonPlaceholder', defaultMessage: 'Select Reason' })}
                    >
                      {
                        _.map(_.get(initialDetail, 'reason'), (item) => (
                          <Option key={item.id}>
                            {item.message}
                          </Option>
                        ))
                      }
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>
          ) : null
        }
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'modalTravelExpenseStatusRemarkText', defaultMessage: 'Remark' })} req={false} />
              }
            >
              {getFieldDecorator('remark', {
              })(
                <TextArea
                  autosize={{ minRows: 3, maxRows: 3 }}
                  maxLength={250}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

const FormModalStatus = Form.create({
  name: 'modal_form',
  mapPropsToFields(props) { },
})(ModalStatus);

export default FormModalStatus
