import React from 'react'
import { Checkbox, Row, Col, Icon, Divider, Select, Button, Input } from 'antd';
import _ from 'lodash'
import { FormattedMessage, useIntl } from 'react-intl'
import Button_01 from '../../../../../components/v2/button_01';

const { Option } = Select

const ListItemFilter = (props) => {
  const { onChange, searchGroup, itemCode, itemName, onClick } = props
  const intl = useIntl()

  return (
    <div>
      <div style={{ overflowY: 'auto', height: '500px' }}>
        <div style={{ padding: '16px' }}>
          <div style={{ marginBottom: '16px' }}>
            <div className='listItemFilterContentHeadText' style={{ marginBottom: '8px' }}>
              <FormattedMessage id="modalListItemFilterTextItemCode" defaultMessage="Item Code" />
            </div>
            <div>
              <Input
                allowClear={true}
                onChange={(e) => onChange(e.target.value, 'itemCode')}
                value={_.get(searchGroup, 'itemCode')}
                placeholder={intl.formatMessage({ id: 'modalListItemFilterTextItemCodePlaceholder', defaultMessage: 'Enter Item Code' })}
              />
            </div>
          </div>
          <div style={{ marginBottom: '16px' }}>
            <div className='listItemFilterContentHeadText' style={{ marginBottom: '8px' }}>
              <FormattedMessage id="modalListItemFilterTextItemName" defaultMessage="Item Name" />
            </div>
            <div>
              <Input
                allowClear={true}
                onChange={(e) => onChange(e.target.value, 'itemName')}
                value={_.get(searchGroup, 'itemName')}
                placeholder={intl.formatMessage({ id: 'modalListItemFilterTextItemNamePlaceholder', defaultMessage: 'Enter Item Name' })}
              />
            </div>
          </div>
        </div>
      </div>
      <div style={{ textAlign: 'center', paddingBottom: '16px' }}>
        <Button_01 key="submit" type="primary" style={{ width: '250px', margin: 'unset' }} onClick={onClick}>
          <FormattedMessage id="btnApply" defaultMessage="Apply" />
        </Button_01>
      </div>
    </div>

  )
}

export default ListItemFilter
