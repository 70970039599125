import React, { useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Card, Row, Col, Icon, Tag, Modal, Select, List, Button } from 'antd';
import { usePermissionContext } from './PermissionContext';
import { notificationWithIcon } from '../../components/notification';
import httpClient from '../../components/axiosClient';
import Button01 from '../../components/v2/button_01';
import Button02 from '../../components/v2/button_02';
import _ from 'lodash';
import NoDataBackground from '../../components/no-data-page';
// import role from '../users/user/role';
// import styled, { css } from "styled-components";
// import cssStyle from './css/permission.css'

const { Option } = Select;

export default (props) => {
  const intl = useIntl();
  const app = usePermissionContext();
  const comCode = localStorage.getItem('comCode');
  const [visible, setVisible] = useState(false);
  const [visibleApplyAll, setVisibleApplyAll] = useState(false);
  const [code, setCode] = useState('');
  const [selectRoles, setSelectRoles] = useState([]);
  const [selectUsers, setSelectUsers] = useState([]);

  const [listRolesAll, setListRolesAll] = useState([]);

  const handlerClickEdit = (code, roles, users) => {
    const rolesMap = app.state.rolesArr.filter((objectRole) => roles.includes(objectRole.per_gro_id.toString()));
    let rolesId = [];
    for (let i = 0; i < rolesMap.length; i++) {
      rolesId.push(rolesMap[i].per_gro_id.toString());
    }
    setCode(code);
    setSelectRoles(rolesId);
    setSelectUsers(users);
    setVisible(true);
  };

  const handlerCloseModal = () => {
    setSelectRoles([]);
    setSelectUsers([]);
    setVisible(false);
  };

  const handlerOpenApplyAllModal = () => {
    setVisibleApplyAll(true);
  };

  const handlerCloseApplyAllModal = () => {
    setListRolesAll([]);
    setVisibleApplyAll(false);
  };

  const handleCloseTag = async (roleOrUserId, roleOrUser, actionCode, roles, users) => {
    try {
      if (roleOrUser === 'role') {
        const found = roles.findIndex((roleId) => Number(roleId) === Number(roleOrUserId));
        if (found > -1) roles.splice(found, 1);
      } else {
        const found = users.findIndex((userId) => Number(userId) === Number(roleOrUserId));
        if (found > -1) users.splice(found, 1);
      }

      const data = {
        code: actionCode,
        comId: app.state.comId,
        roles: roles.toString(),
        users: users.toString(),
        organizations: '',
        createdBy: app.state.memComId,
      };
      const setPermissions = await httpClient.post(`/v3/authorization/manager/company/${comCode}/permission/update`, data);
      if (setPermissions.status === 200) {
        notificationWithIcon('success', setPermissions.data.status.message);
        handlerCloseModal();
        app.fnc.setRefresh(!app.state.refresh);
      } else {
        notificationWithIcon('error', setPermissions.data.status.message);
        handlerCloseModal();
        app.fnc.setRefresh(!app.state.refresh);
      }
    } catch (error) {
      notificationWithIcon('error', error.response.data.message);
      handlerCloseModal();
      app.fnc.setRefresh(!app.state.refresh);
    }
  };

  const roleChildren = app.state.rolesArr.map((elements) => {
    return <Option key={elements.per_gro_id}>{elements.name}</Option>;
  });

  const userChildren =
    app.state.usersArr &&
    app.state.usersArr.map &&
    app.state.usersArr.map((elements) => {
      return <Option key={elements.mem_com_id}>{elements.fullname}</Option>;
    });

  const access = app.state.actions.map((elements) => {
    return (
      <Col span={24}>
        <Card className="permission-body-card-list" title={elements.description}>
          <div style={{ padding: 10 }}>
            {elements.action.map((ele) => {
              const arrRole = ele.permission.length > 0 ? (ele.permission[0].roles !== '' ? ele.permission[0].roles.split(',') : []) : [];
              const arrUser = ele.permission.length > 0 ? (ele.permission[0].users !== '' ? ele.permission[0].users.split(',') : []) : [];

              const tagRole = arrRole.map((roleId) => {
                const found = app.state.rolesArr.findIndex((objectRole) => objectRole.per_gro_id === Number(roleId));
                const key = found > -1 ? app.state.rolesArr[found].per_gro_id : roleId;
                const roleName = found > -1 ? app.state.rolesArr[found].name : null;

                return roleName ? (
                  <Tag
                    className="premissions-tag"
                    key={key}
                    closable
                    onClose={(e) => handleCloseTag(roleId, 'role', ele.code, arrRole, arrUser)}
                  >
                    {roleName}
                  </Tag>
                ) : null;
              });

              const tagUser = arrUser.map((userId) => {
                const found = app.state.usersArr.findIndex((objectUser) => objectUser.mem_com_id === Number(userId));
                const key = found > -1 ? app.state.usersArr[found].mem_com_id : userId;
                const userName = found > -1 ? app.state.usersArr[found].fullname : null;
                return userName ? (
                  <Tag
                    className="premissions-tag"
                    key={key}
                    closable
                    onClose={(e) => handleCloseTag(userId, 'user', ele.code, arrRole, arrUser)}
                  >
                    {userName}
                  </Tag>
                ) : null;
              });

              return (
                <Row gutter={[24, 16]} style={{ borderBottom: '1px solid #e8e8e8' }}>
                  <Col span={5}>{ele.name}</Col>
                  <Col span={18} style={{ textAlign: 'right' }}>
                    {tagRole} {tagUser}
                  </Col>
                  <Col span={1}>
                    <Icon type="edit" onClick={() => handlerClickEdit(ele.code, arrRole, arrUser)} />
                  </Col>
                </Row>
              );
            })}
          </div>
        </Card>
      </Col>
    );
  });

  /**********************************List*******************************************/

  const listAccess = app.state.actions.map((elements) => {
    // const arrRole = elements.action.permission.length > 0 ? (elements.action.permission[0].roles !== '' ? elements.action.permission[0].roles.split(',') : []) : [];
    // const arrUser = elements.action.permission.length > 0 ? (elements.action.permission[0].users !== '' ? elements.action.permission[0].users.split(',') : []) : [];

    return (
      <Row gutter={[16, 16]} className="permission-row-list">
        <Col span={24}>
          <List
            header={<div>{elements.description}</div>}
            bordered
            dataSource={elements.action}
            renderItem={(itemAction) => (
              <List.Item key={itemAction.code} actions={[<Icon onClick={() => handleAssign(itemAction.code)} type="edit" />]}>
                <List.Item.Meta title={itemAction.name} />

                {itemAction.permission.map((permission) => (
                  <Tag
                    className="per-role-tag"
                    key={permission.code}
                    closable
                    onClose={() => log(permission.code)}
                    onChange={onChange(permission.code)}
                    afterClose={afterClose(permission.code)}
                  >
                    {permission.users}
                  </Tag>
                ))}
              </List.Item>
            )}
          />
        </Col>
      </Row>
    );
  });

  const log = (e) => {};

  const onChange = (checked) => {};

  const afterClose = (roleId) => {};

  const handleAssign = (actionCode) => {
    setVisible(true);
  };

  /**********************************List*******************************************/

  const handlerChangeRoleSelect = (value) => {
    setSelectRoles(value);
  };

  const handlerChangeUserSelect = (value) => {
    setSelectUsers(value);
  };

  const handlerSavePermission = async () => {
    try {
      const data = {
        code: code,
        comId: app.state.comId,
        roles: selectRoles.toString(),
        users: selectUsers.toString(),
        organizations: '',
        createdBy: app.state.memComId,
      };
      // const setPermissions = await httpClient.post(`/company/${app.state.comId}/permission/find-update`, data);
      const setPermissions = await httpClient.post(`/v3/authorization/manager/company/${comCode}/permission/update`, data);
      if (setPermissions.status === 200) {
        notificationWithIcon('success', setPermissions.data.status.message);
        handlerCloseModal();
        app.fnc.setRefresh(!app.state.refresh);
      } else {
        notificationWithIcon('error', setPermissions.data.status.message);
        handlerCloseModal();
        app.fnc.setRefresh(!app.state.refresh);
      }
    } catch (error) {
      notificationWithIcon('error', error.message);
      handlerCloseModal();
      app.fnc.setRefresh(!app.state.refresh);
    }
  };

  const handlerChangeRoleSelectAll = (value) => {
    console.log('handlerChangeRoleSelectAll', value);
    setListRolesAll(value);
  };

  const handlerSaveApplyAll = async () => {
    if (!listRolesAll.length) {
      notificationWithIcon('error', 'Please select at least one role to apply.');
      return;
    }

    try {
      const data = {
        comId: app.state.comId,
        pageCode: app.state.rolesSelect,
        roles: listRolesAll,
        memComId: app.state.memComId,
      };

      await Promise.all(
        app.state.actions.map(async (action) => {
          const setPermissions = await httpClient.post(`/v3/authorization/manager/company/RT/permission/applyallpage/save-role`, data);
          if (setPermissions.status === 200) {
            notificationWithIcon('success', `Roles applied to ${action.description}`);
            console.log('success', data);
          } else {
            notificationWithIcon('error', `Failed to apply roles to ${action.description}`);
            console.log('fail', data);
          }
        })
      );

      handlerCloseApplyAllModal();
      app.fnc.setRefresh(!app.state.refresh);
    } catch (error) {
      notificationWithIcon('error', error.message);
      handlerCloseApplyAllModal();
      app.fnc.setRefresh(!app.state.refresh);
    }
  };

  console.log('paggegegecodeeee', app.state.rolesSelect);

  return (
    <Card
      title={intl.formatMessage({ id: 'UserManagementPermissionsTitle', defaultMessage: 'Permission' })}
      className="card-permission-permission"
      extra={
        <>
          {app.state.rolesSelect !== '' ? (
            <Button onClick={handlerOpenApplyAllModal}>
              <FormattedMessage id="PermissionsApplyAll" defaultMessage="Apply All" />
            </Button>
          ) : null}

          {/* <FormattedMessage id="PermissionsApplyAll" defaultMessage="Apply All" />
          <Select
            className="permission-select"
            mode="multiple"
            style={{ width: '400px', marginLeft: '10px' }}
            placeholder={intl.formatMessage({ id: 'UserManagementPermissionsModalHintRole', defaultMessage: 'Please enter role' })}
            value={listRolesAll}
            onChange={handlerChangeRoleSelectAll}
            filterOption={(input, option) =>
              option.props.children !== null && option.props.children.toLowerCase().includes(input.toLowerCase())
            }
            maxTagCount={3}
            maxTagTextLength={10}
          >
            {roleChildren}
          </Select> */}
        </>
      }
    >
      {/* <div style={{ paddingTop: 24, paddingRight: 24 }}> */}
      <div>
        <Row gutter={[24, 16]}>
          {_.size(access) > 0 ? (
            access
          ) : (
            <NoDataBackground
              text1={{ id: 'nodataXPermission1', text: 'Please select a Function' }}
              text2={{ id: 'nodataXPermission2', text: 'Permission will appear here.' }}
            />
          )}
          {/* xxxxxxxx {access} */}
        </Row>
        {/* {listAccess} */}

        <Modal
          title={<FormattedMessage id="UserManagementPermissionsModalTitleAssign" defaultMessage="Assign" />}
          visible={visible}
          onOk={handlerSavePermission}
          onCancel={handlerCloseModal}
          footer={[
            <Button02 style={{ margin: '0px 0px 0px 10px' }} btnsize="wd_df" key="back" onClick={handlerCloseModal}>
              <FormattedMessage id="modalBtnCancel" defaultMessage="Cancel" />
            </Button02>,
            <Button01 btnsize="wd_df" type="primary" key="submit" onClick={handlerSavePermission}>
              <FormattedMessage id="modalBtnSave" defaultMessage="Save" />
            </Button01>,
          ]}
        >
          <p style={{ fontWeight: 'bold' }}>
            <FormattedMessage id="UserManagementPermissionsModalLblRole" defaultMessage="Role" />
          </p>
          <Select
            className="permission-select"
            mode="multiple"
            style={{ width: '100%' }}
            placeholder={intl.formatMessage({ id: 'UserManagementPermissionsModalHintRole', defaultMessage: 'Please enter role' })}
            value={selectRoles}
            onChange={handlerChangeRoleSelect}
            filterOption={(input, option) =>
              option.props.children !== null && option.props.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {roleChildren}
          </Select>

          <p style={{ fontWeight: 'bold', marginTop: 15 }}>
            <FormattedMessage id="UserManagementPermissionsModalLblUser" defaultMessage="User" />
          </p>
          <Select
            className="permission-select"
            mode="multiple"
            style={{ width: '100%' }}
            placeholder={intl.formatMessage({ id: 'UserManagementPermissionsModalHintUser', defaultMessage: 'Please enter user' })}
            value={selectUsers}
            onChange={handlerChangeUserSelect}
            filterOption={(input, option) =>
              option.props.children !== null && option.props.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {userChildren}
          </Select>
        </Modal>

        <Modal
          title={<FormattedMessage id="UserManagementPermissionsApplyAllTitleAssign" defaultMessage="Apply Role to All" />}
          visible={visibleApplyAll}
          onOk={handlerSaveApplyAll}
          onCancel={handlerCloseApplyAllModal}
          footer={[
            <Button02 key="back" onClick={handlerCloseApplyAllModal}>
              <FormattedMessage id="modalBtnCancel" defaultMessage="Cancel" />
            </Button02>,
            <Button01 type="primary" key="submit" onClick={handlerSaveApplyAll}>
              <FormattedMessage id="modalBtnSave" defaultMessage="Save" />
            </Button01>,
          ]}
        >
          <p style={{ fontWeight: 'bold' }}>
            <FormattedMessage id="UserManagementPermissionsApplyAllModalRole" defaultMessage="Select Role to Apply All" />
          </p>
          <Select
            className="permission-select"
            mode="multiple"
            style={{ width: '100%', paddingTop: '10px' }}
            placeholder={intl.formatMessage({ id: 'UserManagementPermissionsModalHintRoles', defaultMessage: 'Please enter roles' })}
            value={listRolesAll}
            onChange={handlerChangeRoleSelectAll}
            filterOption={(input, option) =>
              option.props.children !== null && option.props.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {roleChildren}
          </Select>
        </Modal>
      </div>
    </Card>
  );
};
