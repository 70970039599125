import React, { useState, useEffect } from 'react'
// import Style from './css/add.css'
import { Modal, Form } from 'antd'
import { FormattedMessage } from 'react-intl';
import UploadFileModal from '../modal-upload-insurance';
import Button_02 from '../../v2/button_02';
import Button_01 from '../../v2/button_01';
import AddFormInsurance from './insurance-form';
import ViewFormInsurance from './insurance-view';
import UploadTable from '../table-upload-insurance';
import { deleteAssetImageInsurance, getInsuranceOrg, saveInsurance, getInsuranceById, updateAssetInsurance, deleteDocByHash } from '../../../controllers/asset-resource/asset-controller';
import _ from 'lodash';
import ReactBnbGallery from 'react-bnb-gallery';
import moment from 'moment';
import { successNotification, errorNotification } from '../../v2/notification';

const AddForm = (props) => {
  const { visible, setVisible, form, typeModal, assetId, setTriggerApi, startInsuranceData, setTypeModalInsurance, sectionModalInsurance } = props
  const [openUpload, setOpenUpload] = useState(false);
  const [attData, setAttData] = useState([]);
  const [typeAtt, setTypeAtt] = useState("add");
  const [defaultAtt, setDefaultAtt] = useState();
  const [stateModal, setStateModal] = useState(false);
  const [modalView, setModalView] = useState();
  const [insuranceData, setInsuranceData] = useState([]);
  const [insuranceTypeData, setInsuranceTypeData] = useState([]);
  const [insuranceById, setInsuranceById] = useState([]);
  const [disbled, setDisbled] = useState(false);
  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    const getDefault = async () => {
      const response = await getInsuranceOrg()
      setInsuranceData(_.get(response, 'data.data.insurance'))
    }
    getDefault();
  }, []);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    const getDefaultByID = async () => {
      if (visible) {
        if (typeModal !== "add" && _.get(startInsuranceData, 'assetInsuranceId')) {
          const response = await getInsuranceById(_.get(startInsuranceData, 'assetInsuranceId'))
          console.log("response ::", response)
          const docData = _.get(response, 'data.data.assetInsuranceDoc')
          const loopDoc = _.map(docData, (item, index) => {
            return {
              "no": index + 1,
              "name": item.fileName,
              "size": item.fileSizeMb,
              "type": item.fileType,
              "remark": item.remark,
              "url": item.filePath,
              "fileHash": item.fileHash
            }
          })
          setInsuranceById(_.get(response, 'data.data'))
          setAttData(loopDoc)
        } else {
          setInsuranceById([])
          setAttData([])
        }
      }
    }
    getDefaultByID();
  }, [visible, _.get(startInsuranceData, 'assetInsuranceId'), trigger]);

  const handleCreate = () => {
    form.validateFields(async (err, values) => {
      if (err) {
        return;
      }
      setDisbled(true)
      const filterInsurance = _.filter(insuranceData, (item) => { return item.insuranceId === _.get(values, 'insuranceName') })
      const filterInsuranceType = _.filter(_.get(filterInsurance, '[0].insuranceTypeList'), (item) => { return item.insuranceTypeId === _.get(values, 'insuranceType') })
      console.log("insuranceData::", values)
      if (typeModal === "add") {
        const body = {
          "assetId": assetId,
          "insuranceId": _.get(values, 'insuranceName'),
          "insuranceTypeId": _.get(values, 'insuranceType'),
          "insuranceName": _.get(filterInsurance, '[0].insuranceName'),
          "insuranceDateFrom": moment(_.get(values, 'insuranceDate[0]')).format('YYYY-MM-DD'),
          "insuranceDateTo": moment(_.get(values, 'insuranceDate[1]')).format('YYYY-MM-DD'),
          "typeNameEn": _.get(filterInsuranceType, '[0].typeNameEn'),
          "typeNameTh": _.get(filterInsuranceType, '[0].typeNameTh'),
          "premium": parseFloat(_.get(values, 'insuranceTypePremium') || 0),
          "discount": parseFloat(_.get(values, 'insuranceDiscount') || 0),
          "premiumAftDis": parseFloat(_.get(values, 'insuranceDiscountPremium') || 0),
          "listFileHash": _.map(attData, (item) => { return item.fileHash })
        }
        const response = await saveInsurance(body)
        if (response.status === 200) {
          successNotification(response.data.status.message);
          setTriggerApi(current => !current)
          setVisible(false)
        } else {
          errorNotification(response.data.status.message);
        }
      } else {
        const body = {
          "insuranceId": _.get(values, 'insuranceName'),
          "insuranceTypeId": _.get(values, 'insuranceType'),
          "insuranceName": _.get(filterInsurance, '[0].insuranceName'),
          "insuranceDateFrom": moment(_.get(values, 'insuranceDate[0]')).format('YYYY-MM-DD'),
          "insuranceDateTo": moment(_.get(values, 'insuranceDate[1]')).format('YYYY-MM-DD'),
          "typeNameEn": _.get(filterInsuranceType, '[0].typeNameEn'),
          "typeNameTh": _.get(filterInsuranceType, '[0].typeNameTh'),
          "premium": parseFloat(_.get(values, 'insuranceTypePremium') || 0),
          "discount": parseFloat(_.get(values, 'insuranceDiscount') || 0),
          "premiumAftDis": parseFloat(_.get(values, 'insuranceDiscountPremium') || 0),
          "listFileHash": _.map(attData, (item) => { return item.fileHash })
        }
        const response = await updateAssetInsurance(_.get(startInsuranceData, 'assetInsuranceId'), body)
        if (response.status === 200) {
          successNotification(response.data.status.message);
          setTriggerApi(current => !current)
          setVisible(false)
          setAttData([])
          setTrigger(current => !current)
        } else {
          errorNotification(response.data.status.message);
        }
      }
      setDisbled(false)
    })
  }

  const handleCancel = () => {
    console.log("sectionModalInsurance :", sectionModalInsurance)
    if (sectionModalInsurance) {
      form.resetFields()
      setTypeModalInsurance("view")
      const diff = _.differenceBy(attData, _.get(insuranceById, 'assetInsuranceDoc'), 'fileHash')
      _.map(diff, async (item) => {
        await deleteDocByHash(item.fileHash)
      })
    } else {
      if (typeModal === "add") {
        _.map(attData, async (item) => {
          await deleteDocByHash(item.fileHash)
        })
      } else {
        const diff = _.differenceBy(attData, _.get(insuranceById, 'assetInsuranceDoc'), 'fileHash')
        _.map(diff, async (item) => {
          await deleteDocByHash(item.fileHash)
        })
      }
      setVisible(false)
      form.resetFields()
      setAttData([])
    }
  }

  const handleClose = () => {
    setVisible(false)
    form.resetFields()
    setAttData([])
  }

  const handleEdit = () => {
    setTypeModalInsurance("edit")
    // setSectionModalInsurance(false)
  }

  const handleDeleteIMG = async (hash) => {
    await deleteAssetImageInsurance(hash)
    const filter = _.filter(attData, (item) => {
      return item.fileHash !== hash
    })
    setAttData(filter)
  }


  const handlePreview = async (file) => {
    console.log('filehandlePreview', file)
    let isPDF = _.get(file, 'type') === 'application/pdf' ? true : false;
    if (isPDF) return window.open(_.get(file, 'url'), '_blank');
    const mapdata = {
      photo: _.get(file, 'url'),
      number: 1,
      caption: _.get(file, 'remark') !== "undefined" ? _.get(file, 'remark') : undefined,
      thumbnail: _.get(file, 'url'),
    }
    setModalView(mapdata);
    setStateModal(!stateModal);

  };

  const HandlerModal = (item) => {
    return (
      <div>
        <ReactBnbGallery show={stateModal} photos={modalView} onClose={() => setStateModal(false)} />
      </div>
    );
  };


  return (
    <div>
      <Modal
        visible={visible}
        onCancel={handleClose}
        onOk={handleCreate}
        centered={true}
        title={
          typeModal === "add" ?
            <FormattedMessage id="AssetModalTitleAdd" defaultMessage="Add New Insurance" /> :
            typeModal === "view" ?
              <FormattedMessage id="AssetModalTitleView" defaultMessage="View New Insurance" /> :
              <FormattedMessage id="AssetModalTitleEdit" defaultMessage="Edit New Insurance" />}
        width={760}
        bodyStyle={{ padding: "24px" }}
        footer={typeModal === "view" ? [
          <Button_02 key="back" btnsize="wd_df" onClick={handleClose} style={{ margin: 'unset' }}>
            <FormattedMessage id="btnClose" defaultMessage="Close" />
          </Button_02>,
          <Button_01 key="submit" type="primary" btnsize="wd_df" onClick={handleEdit}>
            <FormattedMessage id="btnEdit" defaultMessage="Edit" />
          </Button_01>
        ]
          : [
            <Button_02 key="back" btnsize="wd_df" onClick={handleCancel} disbled={disbled} style={{ margin: 'unset' }}>
              <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
            </Button_02>,
            <Button_01 key="submit" type="primary" btnsize="wd_df" onClick={handleCreate} disbled={disbled}>
              <FormattedMessage id="btnSave" defaultMessage="Save" />
            </Button_01>
          ]}
      >
        {
          typeModal === "add" || typeModal === "edit" ? (
            <AddFormInsurance
              form={form}
              insuranceData={insuranceData}
              insuranceTypeData={insuranceTypeData}
              setInsuranceTypeData={setInsuranceTypeData}
              typeModal={typeModal}
              insuranceById={insuranceById}
            />
          ) : typeModal === "view" ? (
            <ViewFormInsurance
              insuranceById={insuranceById}
            />
          ) : null
          // (
          //   <EditFormInsurance
          //     form={form}
          //     insuranceData={insuranceData}
          //     insuranceTypeData={insuranceTypeData}
          //     setInsuranceTypeData={setInsuranceTypeData}
          //     insuranceById={insuranceById}
          //   />
          // )
        }
        <UploadTable
          setVisible={setOpenUpload}
          checkedType={typeModal}
          attData={attData}
          setTypeAtt={setTypeAtt}
          setDefaultAtt={setDefaultAtt}
          handleDeleteIMG={handleDeleteIMG}
          handlePreview={handlePreview}
        />
      </Modal>
      <UploadFileModal
        visible={openUpload}
        setVisible={setOpenUpload}
        attData={attData}
        setAttData={setAttData}
        typeAtt={typeAtt}
        defaultAtt={defaultAtt}
      />
      <HandlerModal />
    </div >
  )
}


const InsuranceAdd = Form.create({
  name: "modal_form",
  mapPropsToFields() {

  }
})(AddForm);

export default InsuranceAdd
