import _ from 'lodash';
import React from 'react';

const numberFormatter = (value) => {
  if (value !== undefined) {
    // console.log("value", (Number(value)).toFixed(2))
      const roundedValue = (Number(value)).toFixed(2); // ปัดเศษทศนิยมให้เหลือ 2 ตำแหน่ง
      return roundedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ','); // ใช้ตัวคั่นหลักพัน
  }
  return value;
};
const numberFormatterCheckDot = (value) => {
  if (value !== undefined) {
      const toString = _.toString((Number(value))); 
      const include = _.includes(toString, '.')
      if (include) {
        const roundedValue = (Number(value)).toFixed(2);
        return roundedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      } else {
        return Number(value).toLocaleString('en-US', {maximumFractionDigits: 0})
      }
  }
  return value;
};
const numberParser = (value) => {
  if (value !== undefined) {
    // if (value) {
    // ลบตัวคั่นหลักพันก่อนที่จะแปลงเป็นตัวเลข
    // const parsedValue = Number(value.replace(/[^0-9.-]+/g, ''));
    
    const parsedValue = Number(String(value).replace(/[^0-9.-]+/g, ''));
    return parsedValue;
  }
  return value;
};
const cutStrNumberFormatter = (value) => {
  if (value !== undefined) {
    const strValue = _.toString(value).slice(0, _.toString(value).indexOf('.') + 3)
    return strValue
  }
  return value
}

export { numberFormatter, numberFormatterCheckDot, numberParser, cutStrNumberFormatter };