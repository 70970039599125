import { Divider, Form, Icon, Input, InputNumber, Modal, Select, Typography } from 'antd';
import React, { useState } from 'react';
import Button02 from '../../../../components/v2/button_02';
import Button01 from '../../../../components/v2/button_02';
import ModalSelectItemProject from '../../../../components/modal-select-item-project';
import _ from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';
import LabeRequireForm from '../../../../components/label-required-form';
import { PageSettings } from '../../../../config/page-settings';
import { handleKeyDownNumber } from '../../../../component-function/fnc-number';

const { Title } = Typography;
const { Option } = Select;
const { TextArea } = Input;

const ModalReimbursement = (props) => {

  const intl = useIntl();
  const { visible, setVisibel, setTrigger, record, setRecord } = props
  const { form } = props;
  const { getFieldDecorator, resetFields, getFieldValue, setFieldsValue } = form;

  
  const [open, setOpen] = useState(false)
  const [triggerModal, setTriggerModal] = useState(false)
  const [listItem, setListItem] = useState([])


  const handleSave = () => {
    form.validateFields(async (err, values) => {
      if (err) {
        return;
      }

      console.log('handleSave', values, record)

      // const payload =
      //   _.get(record, 'action') === 'edit'
      //     ? {
      //         basePayId: _.get(values, 'basePayId'),
      //         amount: _.get(values, 'amount') || 0,
      //       }
      //     : {
      //         basePayId: _.get(values, 'basePayId'),
      //         userId: userId,
      //         amount: _.get(values, 'amount') || 0,
      //       };

      // try {
      //   const response =
      //     _.get(record, 'action') === 'edit'
      //       ? await updateUserBasePay(payload, userId, _.get(record, 'basePayMemberId'))
      //       : await creatUserBasePay(payload);

      //   if (_.get(response, 'data.status.code') === 200) {
      //     successNotification(_.get(response, 'data.status.message'));
      //     handleCancel();
      //   } else {
      //     errorNotification(_.get(response, 'data.status.message'));
      //   }
      // } catch (error) {
      //   errorNotification(error.response.data.data.message);
      // }

      setTrigger((event) => !event);
    });
  }

  const handleCancel = () => {
    form.resetFields()
    setRecord({})
    setVisibel(false)
  }

  let option = listItem.map((item, index) => (
    <Option key={item.basePayId} value={item.basePayId}>
      {item.basePayName}
    </Option>
  ));

  const currencyFormatter = (value) => {
    if (parseFloat(value) >= 0) {
      return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
  };

  const currencyParser = (value) => {
    if (parseFloat(value) >= 0) {
      return value.replace(/\$\s?|(,*)/g, '');
    }
  };

  const onSelectItem = (itemId) => {
    setFieldsValue({
      ['reimbursement']: itemId,
    });
  };

  return (
    <div>
      
    <Modal
      visible={visible}
      onOk={handleSave}
      onCancel={handleCancel}
      className="button-modal"
      title={
        _.get(record, 'action') === 'edit' ? (
          <Title style={{ fontSize: '15px', fontWeight: 400, color: '#1D3557' }}>
            <FormattedMessage id="projectDetailSettingModalEditReimbursement" defaultMessage="Edit Reimbursement" />
          </Title>
        ) : (
          <Title style={{ fontSize: '15px', fontWeight: 400, color: '#1D3557' }}>
            <FormattedMessage id="projectDetailSettingModalAddReimbursement" defaultMessage="Add Reimbursement" />
          </Title>
        )
      }
      footer={[
        <Button02
          style={{ margin: '0px 0px 0px 10px', fontSize: '13px' }}
          className="btn-style-new"
          key="back"
          fontsize="sm"
          btnsize="wd_df"
          onClick={handleCancel}
        >
          <FormattedMessage id="btnCloseBasePay" defaultMessage="Close" />
        </Button02>,
        <Button01
          style={{ fontSize: '13px' }}
          className="btn-style-new2"
          key="submit"
          fontsize="sm"
          btnsize="wd_df"
          type="primary"
          onClick={handleSave}
        >
          <FormattedMessage id="btnSave" defaultMessage="Save" />
        </Button01>,
      ]}
    >
      <Form layout="vertical">
        <Form.Item label={<LabeRequireForm text={intl.formatMessage({ id: 'projectSettingLabReimbursement', defaultMessage: 'Reimbursement' })} req={true} />}>
          {getFieldDecorator('reimbursement', {
            initialValue: _.get(record, 'reimbursement'),
            rules: [
              {
                required: true,
                message: intl.formatMessage({
                  id: 'projectSettingReimbursementValidateReimbursement',
                  defaultMessage: 'Please Select Reimbursement',
                }),
              },
            ],
          })(
            <Select
              placeholder={intl.formatMessage({ id: 'projectSettingReimbursementPlaceReimbursement', defaultMessage: 'Select Reimbursement' })}
              className="user-management-role-select"
              style={{ width: '100%' }}
              showSearch
              allowClear
              filterOption={(input, option) =>
                option.props.children
                  .toString()
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              dropdownRender={(menu) => (
                <div>
                  {menu}
                  <PageSettings.Consumer>
                    {({ checkPermissionAction }) => (
                      <>
                        {checkPermissionAction('P2S1PG2C16', 'P2S1PG2C16A5') ? (
                          <div>
                            <Divider style={{ margin: '4px 0' }} />
                            <div
                              style={{ padding: '4px 8px', cursor: 'pointer' }}
                              onMouseDown={(e) => e.preventDefault()}
                              onClick={() => {
                                setOpen(true);
                              }}
                            >
                              <Icon type="plus" /> <FormattedMessage id="projectSettingReimbursementAddItem" defaultMessage="Add Item" />
                            </div>
                          </div>
                        ) : null}
                      </>
                    )}
                  </PageSettings.Consumer>
                </div>
              )}
            >
              {option}
            </Select>
          )}
        </Form.Item>

        <Form.Item
          label={<LabeRequireForm text={intl.formatMessage({ id: 'projectSettingLabLimit', defaultMessage: 'Limit' })} req={true} />}
        >
          {getFieldDecorator('limit', {
            initialValue: _.get(record, 'limit', 0),
            rules: [
              {
                required: true,
                message: intl.formatMessage({
                  id: 'projectSettingReimbursementValidatelimit',
                  defaultMessage: 'Please Enter Limit',
                }),
              },
            ],
          })(
            <InputNumber
              className="disabled-handle-count"
              placeholder={intl.formatMessage({ id: 'projectSettingReimbursementPlaceLimit', defaultMessage: 'Enter Limit' })}
              suffix="THB"
              onKeyDown={handleKeyDownNumber}
              // formatter={currencyFormatter}
              // parser={currencyParser}
            />
          )}
        </Form.Item>

        <Form.Item
          label={<LabeRequireForm text={intl.formatMessage({ id: 'projectSettingLabRemark', defaultMessage: 'Remark' })} req={false} />}
        >
          {getFieldDecorator('remark', {
            initialValue: _.get(record, 'remark',),
          })(
            <TextArea
              rows={3}
              placeholder={intl.formatMessage({ id: 'projectSettingReimbursementPlaceRemark', defaultMessage: 'Enter Remark' })}
            />
          )}
        </Form.Item>
      </Form>
      
    </Modal>

    <ModalSelectItemProject visible={open} setVisible={setOpen} setTrigger={setTriggerModal} menuCode={'reimbursement'} onSelectItem={onSelectItem}/>

    </div>
  );
};

const ModalReimbursementForm = Form.create({
  name: 'global_state',
})(ModalReimbursement);

export default ModalReimbursementForm
