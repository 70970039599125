import React, { useState, useEffect } from 'react'
import { Card, Upload, Modal } from 'antd'
import ModalSignature from '../../../components/modal-signature';
import { PageSettings } from '../../../config/page-settings';
import Button02 from '../../../components/v2/button_02';
import './css/signature.css'
import SignatureIMG from '../../../components/image/signature.svg'
import _ from 'lodash'
import { DeleteSignature, GetSignature } from '../../../controllers/user/signature';
import { successNotification, errorNotification } from '../../../components/v2/notification'
import { useIntl, FormattedMessage } from 'react-intl';

export default (props) => {
  const { view, memComId } = props
  const intl = useIntl()
  const [visibleModal, setVisibleModal] = useState(false)
  const [visiblePreview, setVisiblePreview] = useState(false)
  const [trigger, setTrigger] = useState(false)
  const [statusImage, setStatusImage] = useState()
  const [fileList, setFileList] = useState([])

  console.log("fileList", fileList, view)

  useEffect(() => {
    const getData = async () => {
      const response = await GetSignature(memComId)
      if (_.size(response) !== 0) {
        if (_.size(response.data) !== 0) {
          setFileList([
            {
              uid: _.get(response, 'data[0].fileHash'),
              name: _.get(response, 'data[0].fileName'),
              status: 'done',
              url: _.get(response, 'data[0].fileUrl'),
            }
          ])
        } else {
          setFileList([])
        }
      }

    }
    getData();
  }, [memComId, trigger])

  const handleModal = () => {
    setVisibleModal(true)
  }

  const handleRemove = async (file) => {
    // console.log('file ::', file.uid)
    Modal.confirm({
      className: 'maintenance-report-modal-confirm',
      centered: true,
      icon: null,
      title: intl.formatMessage({ id: 'lblDelete', defaultMessage: 'Are you sure you want to delete ?' }),
      okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: "primary",
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: "danger",
      },
      async onOk() {
        const response = await DeleteSignature(_.get(file, 'uid'), memComId)
        if (_.size(response) !== 0) {
          if (_.get(response, 'status.code') === 200) {
            successNotification(_.get(response, 'status.message'))
          } else {
            errorNotification(_.get(response, 'status.message'))
          }
        } else {
          errorNotification('error')
        }
        setFileList([])
        setTrigger(current => !current)
      },
      onCancel() {
      },
    });
  }



  return (
    <PageSettings.Consumer>
      {({ checkPermissionAction }) =>
        <div>
          <Card
            title={intl.formatMessage({ id: 'signatureCardTitle', defaultMessage: 'Signature' })}
            extra={
              _.size(fileList) >= 1 || view ? null : (
                <Button02 style={{ margin: '0px 0px 0px 10px' }}
                  key="submit"
                  type="primary"
                  btnsize="wd_df"
                  onClick={handleModal}
                  style={{ margin: '0px' }}
                >
                  <FormattedMessage id='signatureCardButtonUpload' defaultMessage='Upload' />
                </Button02>
              )
            }
          >
            {
              _.size(fileList) > 0 ? (
                <div>
                  <Upload
                    name="avatar"
                    listType="picture-card"
                    className="uploader-image-background-main"
                    fileList={fileList}
                    onPreview={() => setVisiblePreview(true)}
                    onRemove={handleRemove}
                    maxCount={1}
                    disabled={view ? true : false}
                  >
                  </Upload>
                </div>
              ) : (
                <div className="blank-signature-content">
                  <div>
                    <img src={SignatureIMG} className='blank-signature-icon ' />
                  </div>
                  <div>
                    <p className="blank-signature-text">
                      <FormattedMessage id='signatureCardTextDescription1' defaultMessage='Please upload Signature' /> <br />
                      <FormattedMessage id='signatureCardTextDescription2' defaultMessage='Signature will preview here.' />
                    </p>
                  </div>
                </div>
              )
            }
          </Card >

          <ModalSignature
            visible={visibleModal}
            setVisible={setVisibleModal}
            memComId={memComId}
            setTrigger={setTrigger}
          />

          <Modal
            visible={visiblePreview}
            onCancel={() => setVisiblePreview(false)}
            footer={null}
            centered={true}
          >
            <img style={{ width: '100%' }} src={_.get(fileList, '[0].url')} />
          </Modal>

        </div >}
    </PageSettings.Consumer >
  )
}
