import React, { useState, useEffect } from 'react';
import { Card, Avatar, Icon, Modal } from 'antd';
import {
  Preview01,
  Preview02,
  Preview03,
  Preview04,
  Preview05,
  PreviewPlus,
} from '../preview-picture/preview-picture.js';
import PostHeader from './post-header';
import PostMessage from './post-message';
import PostAddress from './post-address';
import PostTags from './post-tags.js';
import _ from 'lodash';
import { useIntl } from 'react-intl';
import { successNotification, errorNotification } from '../v2/notification';
import ReactBnbGallery from 'react-bnb-gallery';
import stylePost from './css/post.css';

export default (props) => {
  const { fnc, postData } = props;
  const intl = useIntl();
  const [OpenGallery, setOpenGallery] = useState(false)
  const [numberImage, setNumberImage] = useState(0)

  const handleHashtag = (data) => {
    fnc.handleChangeTxtSearch(data);
  }

  const handleAddress = (data) => {
    if (data) {
      window.open(`https://www.google.com/maps/search/?api=1&query=${_.get(data, 'addressLat')}, ${_.get(data, 'addressLng',)}`, '_blank');
    }
  }

  const handlePreviewImage = (page) => {
    // fnc.handleOpenViewModuleAction('post', postData.postId);
    setOpenGallery(true)
    setNumberImage(page)
  }

  const handleEditPost = (data) => {
    fnc.handleOpenEditModuleAction('post', postData.postId);
  }

  const handleDeletePost = (data) => {
    Modal.confirm({
      className: 'maintenance-report-modal-confirm',
      centered: true,
      icon: null,
      title: intl.formatMessage({ id: 'lblDelete', defaultMessage: 'Are you sure you want to delete ?' }),
      okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        const response = await fnc.handleDeletedModuleAction(_.get(data, 'moduleCode'), _.get(data, 'postId'));
        if (response.success === true) {
          successNotification(response.message)
        }
        else {
          errorNotification(response.message)
        }
      },
      onCancel() {
      },
    });
  }

  const handleTags = (data) => {
    if (data) {
      let isPDF = /pdf/g.test(data.name.toLowerCase());
      if (isPDF) return window.open(data.pathImage, '_blank');
    }
  }

  const fncPreview = (imageList) => {
    let sum = imageList.length;
    switch (sum) {
      case 1:
        return <Preview01 imageList={imageList} handlePreviewImage={handlePreviewImage} />;
      case 2:
        return <Preview02 imageList={imageList} handlePreviewImage={handlePreviewImage} />;
      case 3:
        return <Preview03 imageList={imageList} handlePreviewImage={handlePreviewImage} />;
      case 4:
        return <Preview04 imageList={imageList} handlePreviewImage={handlePreviewImage} />;
      case 5:
        return <Preview05 imageList={imageList} handlePreviewImage={handlePreviewImage} />;
      default:
        return <PreviewPlus imageList={imageList} handlePreviewImage={handlePreviewImage} />;
    }
  };

  const mapPhoto = _.size(postData.imageList) !== 0 ? _.get(postData, 'imageList').map((item, index) => {
    return {
      photo: _.get(item, 'pathImage'),
      number: index,
      caption: _.get(item, 'description'),
      subcaption: `Posted By ${_.get(postData, 'postedBy')}`,
      thumbnail: _.get(item, 'pathImage'),
    }
  }
  ) : []

  return (
    <div className='post'>
      <Card className='post-card'>
        <div className='post-card-body'>
          <PostHeader postData={postData} handleEditPost={handleEditPost} handleDeletePost={handleDeletePost} />

          <div className='post-card-create-post'>
            <PostMessage postData={postData} handleHashtag={handleHashtag} />
            {postData && (postData.addressName !== '') ? (
              <PostAddress postData={postData} handleAddress={handleAddress} />
            ) : null}
            <PostTags postData={postData.fileList} handleTags={handleTags} />
          </div>

          <div>{postData ? fncPreview(postData.imageList) : null}</div>
          <ReactBnbGallery
            show={OpenGallery}
            photos={mapPhoto}
            onClose={() => setOpenGallery(false)}
            activePhotoIndex={numberImage}
          />
        </div>
      </Card >
    </div >
  );
};
