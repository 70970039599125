import React, { useState, useEffect } from 'react';
import './index.css';
import { Row, Col, Select, Icon } from 'antd';
import AppAvatar from '../../../../components/avatar';
import _ from 'lodash';
import DefaultProfile from '../../../../components/image/NoImageR.png';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { PageSettings } from '../../../../config/page-settings';

const { Option } = Select;

const ViewDetail = (props) => {
  const { viewData, setVisibleComment, setCommentData } = props;
  const [imageFileDefault, setImageFileDefault] = useState('');

  useEffect(() => {
    setImageFileDefault(DefaultProfile);
  }, [DefaultProfile]);

  const SelectStatus = styled(Select)`
    width: 'auto';
    .ant-select-selection--single {
      border-radius: 20px;
      height: 24px;
      color: #fff;
      width: fit-content;
      font-size: 13px;
      background-color: ${_.get(viewData, 'statusColor')};
      border: 1px solid ${_.get(viewData, 'statusColor')};
    }
    .ant-select-selection-selected-value {
      margin-right: 5px;
      margin-top: 0px;
    }
    .ant-select-arrow .ant-select-arrow-icon svg {
      fill: #fff;
    }
    .ant-select-focused .ant-select-selection,
    .ant-select-selection:focus,
    .ant-select-selection:active,
    .ant-select-selection:hover,
    .ant-select-open .ant-select-selection {
      border-color: ${_.get(viewData, 'statusColor')} !important;
      box-shadow: none !important;
    }
  `;

  const onChangeStatus = (value, e) => {
    setCommentData(e.props);
    setVisibleComment(true);
  };

  return (
    <div className="warehouse-layout-content warehouse-tabs-details scroll-sm" style={{ padding: '24px' }}>
      <Row gutter={[16]}>
        <Col span={6}>
          <AppAvatar
            size={150}
            src={`${_.get(viewData, 'ticketImg') ? _.get(viewData, 'ticketImg') : imageFileDefault}`}
            shape="square"
          />
        </Col>
        <Col span={18}>
          <div>
            <div>
              <div className="viewTicketTextHeader">{_.get(viewData, 'ticketDetail')}</div>
              <div style={{ marginTop: '8px' }}>
                <PageSettings.Consumer>
                  {({ checkPermissionAction }) =>
                    checkPermissionAction(`P53PG1C1`, `P53PG1C1A3`) ? (
                      <SelectStatus
                        dropdownStyle={{ minWidth: '200px' }}
                        size="small"
                        value={_.get(viewData, 'statusName')}
                        showArrow={true}
                        onChange={onChangeStatus}
                      >
                        {_.map(_.get(viewData, 'nexStatusCode'), (item) => (
                          <Option value={item.code}>{item.name}</Option>
                        ))}
                      </SelectStatus>
                    ) :  <p
                    style={{
                      // backgroundColor: record['statusColor'],
                      backgroundColor: _.get(viewData, 'statusColor'),
                      color: 'white',
                      textAlign: 'center',
                      borderRadius: '10px',
                      height: '23px',
                      width: '95px',
                      border: `1px solid ${_.get(viewData, 'statusColor')}`,
                    }}
                  >
                    {_.get(viewData, 'statusName')}
                  </p>
                  }
                </PageSettings.Consumer>
              </div>
            </div>
            <div style={{ marginTop: '26px' }}>
              <Row gutter={[8, 8]}>
                <Col span={8}>
                  <div className="viewTicketTextTopic">
                    <FormattedMessage id="AllTicketType" defaultMessage="Ticket Type" />
                  </div>
                  <div className="viewTicketTextValue">{_.get(viewData, 'ticketTypeDetail.name')}</div>
                </Col>
                <Col span={8}>
                  <div className="viewTicketTextTopic">
                    <FormattedMessage id="AllTicketTeam" defaultMessage="Team" />
                  </div>
                  <div className="viewTicketTextValue">{_.get(viewData, 'teamName')}</div>
                </Col>
              </Row>
              <Row gutter={[8, 8]}>
                <Col span={8}>
                  <div className="viewTicketTextTopic">
                    <FormattedMessage id="AllticketSubmittedBy" defaultMessage="Submitted by" />
                  </div>
                  <div className="viewTicketTextValue">{_.get(viewData, 'submitBy')}</div>
                </Col>
                <Col span={8}>
                  <div className="viewTicketTextTopic">
                    <FormattedMessage id="AllticketSubmittedDate" defaultMessage="Submitted Date" />
                  </div>
                  <div className="viewTicketTextValue">{_.get(viewData, 'submittedBy')}</div>
                </Col>
                <Col span={8}>
                  <div className="viewTicketTextTopic">
                    <FormattedMessage id="AllTicketRequiredDate" defaultMessage="Required Date" />
                  </div>
                  <div className="viewTicketTextValue">{_.get(viewData, 'requiredDate')}</div>
                </Col>
              </Row>
              <Row gutter={[8, 8]}>
                <Col span={8}>
                  <div className="viewTicketTextTopic">
                    <FormattedMessage id="AllTicketContactType" defaultMessage="Contact Type" />
                  </div>
                  <div className="viewTicketTextValue">{_.get(viewData, 'contactTypeName')}</div>
                </Col>
                <Col span={8}>
                  <div className="viewTicketTextTopic">
                    <FormattedMessage id="AllTicketContact" defaultMessage="Contact" />
                  </div>
                  <div className="viewTicketTextValue">{_.get(viewData, 'contactName')}</div>
                </Col>
                <Col span={8}>
                  <div className="viewTicketTextTopic">
                    <FormattedMessage id="AllTicketPhone" defaultMessage="Phone" />
                  </div>
                  <div className="viewTicketTextValue">{_.get(viewData, 'contactPhone')}</div>
                </Col>
              </Row>
              <Row gutter={[8, 8]}>
                <Col span={8}>
                  <div className="viewTicketTextTopic">
                    <FormattedMessage id="AllTicketMileage" defaultMessage="Mileage" />
                  </div>
                  <div className="viewTicketTextValue">{_.get(viewData, 'ref1')}</div>
                </Col>
                <Col span={8}>
                  <div className="viewTicketTextTopic">
                    <FormattedMessage id="AllTicketInsurance" defaultMessage="Insurance Claim No." />
                  </div>
                  <div className="viewTicketTextValue">{_.get(viewData, 'ref2')}</div>
                </Col>
              </Row>
            </div>
            <div style={{ marginTop: '24px' }}>
              <div className="viewTicketTextTopic">
                <FormattedMessage id="AllTicketLocation" defaultMessage="Service Location" />
              </div>
              <div style={{ marginTop: '8px' }}>
                <Icon type="environment" style={{ fontSize: '12px', color: '#1D3557', marginRight: '5px' }} />
                <span className="viewTicketTextValue" style={{ margin: 'unset' }}>
                  {_.get(viewData, 'serviceLocationAddress')}
                </span>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ViewDetail;
