import React, { useState, useEffect } from 'react'
import { useIntl, FormattedMessage } from 'react-intl';
import {
  Button,
  Row,
  Input,
  Col,
  DatePicker,
  Select,
  List,
  Icon,
  Dropdown,
  Checkbox,
  ConfigProvider,
  Menu,
  TreeSelect,
} from 'antd';
import { useAppContext } from '../../includes/indexProvider';
import Button01 from '../v2/button_01';
import moment from 'moment';
import enUS from 'antd/es/locale/en_US';
import thTH from 'antd/es/locale/th_TH';
import styles from './css/action-report.css';
import _ from 'lodash';

const { Option } = Select;
const { RangePicker } = DatePicker;

const ActionReportV2 = (props) => {
  const { value, fnc } = props;
  const intl = useIntl();
  const app = useAppContext();
  const langValue = app.state.langValue;
  const dateFormat = 'ddd, MMM DD YYYY';
  const [locale, setLocale] = useState(enUS);

  useEffect(() => {
    changeLocale();
  }, []);

  const changeLocale = () => {
    let codeLang = langValue ? langValue : 'EN';
    let changeLang = codeLang === 'EN' ? enUS : thTH;

    setLocale(changeLang);
    if (!changeLang) {
      moment.locale('en');
    } else {
      moment.locale('th-th');
    }
  };

  const columnNewSearc = value.columns.filter((col) => !value.ignoreSearchColumns.includes(col.key));

  return (
    <div className="action-report">
      <Row className="action-report-row">
        <Col span={12} className="action-report-col">
          <div className="action-report-div-left">
            <Input
              className="action-report-input action-input"
              placeholder={intl.formatMessage({ id: 'hintSearch', defaultMessage: 'Search' })}
              prefix={<Icon style={{ color: "rgba(0,0,0,.25)" }} type="search" />}
              onChange={(e) => fnc.onChangeSearch(e.target.value)}
              autoComplete="off" 
            />
            <Select
              className="action-report-select-column action-select"
              placeholder={intl.formatMessage({ id: 'hintSelect', defaultMessage: 'Select' })}
              value={value.fliterByColumn}
              onSelect={(value) => fnc.onFliterByColumn(value)}
            >
              <Option value="all">
              {/* คอลัมน์ทั้งหมด */}
                <FormattedMessage id="lblSeleteAllColumns" defaultMessage="All columns" />  
              </Option>
              {columnNewSearc.map((item, i) => (
                <Option key={i} value={item.key}>
                  {item.title}
                </Option>
              ))}
            </Select>
          </div>
        </Col>
        <Col span={12} className="action-report-col">
          <div className="action-report-div-right">
            <TreeSelect
              className="action-report-tree-select-team"
              showSearch
              dropdownStyle={{ maxHeight: '460px', overflow: 'auto', width: '300px' }}
              placeholder={intl.formatMessage({id: 'lblSeleteHintSeleteTeam', defaultMessage: 'Please Select'})}
              allowClear
              multiple
              treeNodeFilterProp={'title'}
              treeDefaultExpandAll
              treeData={_.get(value,'teamData')}
              maxTagCount={2}
              onChange={fnc.handleChangeTeam}
            ></TreeSelect>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default ActionReportV2;
