import httpClient from '../../components/axiosClient';

const getListViewData = async (body) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v4/task/manager/company/${comCode}/monitor/listview`, body);
    if (response.status == 200) {
      return response;
    } else {
      return response;
    }
  } catch (error) {
    return error.response;
  }

}

const exportListViewData = async (body) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v4/task/manager/company/${comCode}/monitor/listview/export/v2`, body);
    if (response.status == 200) {
      return response;
    } else {
      return response;
    }
  } catch (error) {
    return error.response;
  }

}

const getURLexportTask = async (id) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.get(`/v4/task/manager/company/${comCode}/monitor/listview/export/${id}`);
    if (response.status == 200) {
      return response;
    } else {
      return response;
    }
  } catch (error) {
    return error.response;
  }

}



const getTaskDetailInformation = async (id) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.get(`/v4/task/manager/task-information/${comCode}/taskinform/${id}/information`);
    if (response.status == 200) {
      return response;
    } else {
      return response;
    }
  } catch (error) {
    return error.response;
  }

}



export { getListViewData, exportListViewData,getURLexportTask,getTaskDetailInformation };