import { Col, DatePicker, Divider, Form, Row, Select, Input, Icon, Badge, Modal } from 'antd';
import React, { useContext, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import LabeRequireForm from '../../../../../components/label-required-form';
// import ModalCustomerQuotationForm from '....//select-customer/customer-modal';
import _ from 'lodash';
import moment from 'moment';
import { numberFormatter } from '../../../../../component-function/fnc-inputnumber-currency';
import Button_02 from '../../../../../components/v2/button_02';
import Button_01 from '../../../../../components/v2/button_01';
import { handleKeyDownNumber } from '../../../../../component-function/fnc-number';
import { AccountReceiveContext } from '../../../context';
import { v4 as uuidv4 } from 'uuid';

const { Option } = Select;
const { TextArea } = Input;
const dateFormat = 'ddd, MMM DD YYYY';

const ModalChequeAR = ({
  form,
  title,
  visible,
  onOk,
  onCancel,
  saleOrderId,
  code,
  setSelectItem,
  selectItem,
  setReceiveData,
  receiveData,
  dataForm,
  totalCal,
  handleKeyDownNumberDecimal
}) => {
  const intl = useIntl();
  const { state, setState, func } = useContext(AccountReceiveContext);
  const { paidData } = state;
  const { setAttachmentPaidData, setPaidData } = setState;
  const { handleOpenModalUpload } = func;
  const { getFieldDecorator, validateFields, resetFields } = form;

  const handleSavePayment = (type) => {
    validateFields(async (err, values) => {
      if (err) {
        return;
      }
      console.log('sizeSee', selectItem);
      const body = {
        id: uuidv4(),
        index: _.size(selectItem) + 1,
        statusCode: 'success',
        paymentId: _.get(paidData, 'paymentId'),
        description: values.remark,
        reasonId: '',
        methodName: 'Cheque',
        bankName: values.bankCode,
        statusTxt: 'Success',
        reasonTxt: '',
        amountTxt: numberFormatter(values.amount),
        detail: {
          ...values,
          amount: parseFloat(values.amount),
          methodCode: 'cheque',
          paidDate: moment(values.paidDate).format('YYYY-MM-DD'),
          chequeDate: moment(values.chequeDate).format('YYYY-MM-DD'),
        },
      };
      setSelectItem((prevItems) => [...prevItems, body]);
      // }
      setReceiveData(parseFloat(receiveData) + parseFloat(values.amount));
      onCancel();
      resetFields();
    });
  };

  const MemberOption = (o, i) => {
    return (
      <Option key={i} value={o.bankCode}>
        {o.bankName}
      </Option>
    );
  };

  const CreditOption = (o, i) => {
    return (
      <Option key={i} value={o.creditCardCode}>
        {o.creditCardName}
      </Option>
    );
  };

  const handleAmount = (e, value) => {
    if (parseFloat(value) > totalCal) {
      e.target.value = totalCal;
    }
  };

  return (
    <Modal
      title={title}
      visible={visible}
      onCancel={() => {
        onCancel();
        resetFields();
      }}
      width={618}
      centered={true}
      bodyStyle={{ padding: '16px 24px' }}
      footer={[
        <Button_02
          style={{ margin: '0px 0px 0px 10px' }}
          key="back"
          btnsize="wd_df"
          onClick={() => {
            onCancel();
            resetFields();
          }}
        >
          <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
        </Button_02>,
        <Button_01 style={{ margin: '0px 0px 0px 10px' }} key="submit" type="primary" btnsize="wd_df" onClick={() => handleSavePayment()}>
          <FormattedMessage id="btnSave" defaultMessage="Save" />
        </Button_01>,
      ]}
    >
      <Form name="cash" colon={false}>
        <Row gutter={[24]}>
          <Col span={12}>
            <Form.Item
              label={<LabeRequireForm text={intl.formatMessage({ id: 'ARBank', defaultMessage: 'Bank' })} req={true} bold={true} />}
            >
              {getFieldDecorator('bankCode', {
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'ARBankValidate', defaultMessage: 'Please Select Bank' }),
                  },
                ],
              })(
                <Select
                  placeholder={intl.formatMessage({ id: 'ARBankPlaceHolder', defaultMessage: 'Select Bank' })}
                  showSearch
                  filterOption={(input, option) =>
                    option.props.children
                      .toString()
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {_.get(dataForm, 'bankTypeList') && _.map(_.get(dataForm, 'bankTypeList'), (item, index) => MemberOption(item, index))}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'ARChequeNo', defaultMessage: 'Cheque No.' })} req={true} bold={true} />
              }
            >
              {getFieldDecorator('chequeNo', {
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'ARChequeNoValidate', defaultMessage: 'Please Enter Cheque No.' }),
                  },
                ],
              })(
                <Input
                  onKeyDown={handleKeyDownNumber}
                  placeholder={intl.formatMessage({
                    id: 'ARChequeNoPlaceHolder',
                    defaultMessage: 'Enter Cheque No.',
                  })}
                />
              )}
              {/* : _.get(dataForm, 'issueDate')? _.get(dataForm, 'issueDateTxt'): '-' */}
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[24]}>
          <Col span={12}>
            <Form.Item
              label={<LabeRequireForm text={intl.formatMessage({ id: 'ARPayto', defaultMessage: 'Pay to' })} req={true} bold={true} />}
            >
              {getFieldDecorator('payTo', {
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'ARPaytoValidate', defaultMessage: 'Please Enter Name' }),
                  },
                ],
              })(<Input placeholder={intl.formatMessage({ id: 'ARPaytoPlaceHolder', defaultMessage: 'Enter Name' })} />)}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'ARChequeDate', defaultMessage: 'Cheque Date' })} req={true} bold={true} />
              }
            >
              {getFieldDecorator('chequeDate', {
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'ARChequeDateValidate', defaultMessage: 'Please Select Cheque Date' }),
                  },
                ],
              })(
                <DatePicker
                  allowClear={false}
                  format={dateFormat}
                  style={{ width: '100%' }}
                  placeholder={intl.formatMessage({ id: 'ARChequeDatePlaceHolder', defaultMessage: 'Select Cheque Date' })}
                />
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[24]}>
          <Col span={12}>
            <Form.Item
              label={<LabeRequireForm text={intl.formatMessage({ id: 'ARAmount', defaultMessage: 'Amount' })} req={true} bold={true} />}
            >
              {getFieldDecorator('amount', {
                // initialValue: _.get(dataForm, 'issueBy') || '',
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'ARAmountValidate', defaultMessage: 'Please Enter Amount' }),
                  },
                ],
              })(
                <Input
                  onChange={(e) => handleAmount(e, e.target.value)}
                  onKeyDown={handleKeyDownNumberDecimal}
                  placeholder={intl.formatMessage({ id: 'ARAmountPlaceHolder', defaultMessage: 'Enter Amount' })}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'ARPaidDate', defaultMessage: 'Paid Date' })} req={true} bold={true} />
              }
            >
              {getFieldDecorator('paidDate', {
                // initialValue: _.get(dataForm, 'issueDate') ? moment(_.get(dataForm, 'issueDate'), 'YYYY-MM-DD') : undefined,
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'ARPaidDateValidate', defaultMessage: 'Please Select Paid Date' }),
                  },
                ],
              })(
                <DatePicker
                  allowClear={false}
                  format={dateFormat}
                  style={{ width: '100%' }}
                  placeholder={intl.formatMessage({ id: 'ARPaidDatePlaceHolder', defaultMessage: 'Select Paid Date' })}
                />
              )}
              {/* : _.get(dataForm, 'issueDate')? _.get(dataForm, 'issueDateTxt'): '-' */}
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[24]}>
          <Col span={24}>
            <Form.Item
              colon={false}
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'quotationLabelValidRemark', defaultMessage: 'Remark' })} req={false} />
              }
            >
              {getFieldDecorator(
                'remark',
                {}
              )(<Input placeholder={intl.formatMessage({ id: 'ARRemarkPlaceHolder', defaultMessage: 'Enter Remark' })} />)}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

ModalChequeAR.defaultProps = {
  title: 'Modal',
  visible: false,
  onOk: () => console.warn('onOk not function '),
  onCancel: () => console.warn('onCancel not function '),
};

const ModalChequeARForm = Form.create({ name: 'Customer_Form' })(ModalChequeAR);

export default ModalChequeARForm;
