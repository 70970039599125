import React, { useEffect } from 'react';
import { Form, Input, Col, Row, Divider, Select, AutoComplete, InputNumber } from 'antd';
import _ from 'lodash';
import { useIntl, FormattedMessage } from 'react-intl';
import './css/index.css';
import { handleKeyDownDecimal2Fixed } from '../../component-function/fnc-number';
import { getUoMData } from '../../controllers/item/getUomByCategory';
import { numberFormatter, numberParser } from "../../component-function/fnc-inputnumber-currency";

const { TextArea } = Input;

const CreateItemMasterDetail = ({ form, record, dataDefault, setBrandId, dataModel, setUomOption, uomOption }) => {
  const { Option } = Select;
  const intl = useIntl();
  const { getFieldDecorator, validateFields, resetFields, getFieldsValue, getFieldValue, setFieldsValue } = form;
  const itemGroupCode = _.get(dataDefault, 'itemGroup');
  const itemTypeCode = _.get(dataDefault, 'itemType');
  const itemBrand = _.get(dataDefault, 'itemBrand');
  const pickingType = _.get(dataDefault, 'pickingType');
  const uomCategory = _.get(dataDefault, 'uomCategory');
  const uomCategoryForm = getFieldValue('uomCategory')

  const x = getFieldValue('brandName') ? getFieldValue('brandName') : '';

  console.log('recordCate',record);

  useEffect(() => {
    console.log('FormDrtail');
    setBrandId(x);
    if (x === '') {
      setFieldsValue({ ['modelName']: undefined });
    }
  }, [getFieldValue('brandName')]);

  useEffect(() => {
    setFieldsValue({ ['modelName']: _.get(record, 'modelId') });
  }, [_.get(record, 'modelId')]);


  useEffect(() => {
    const handleGetUoM = async () => {
      const response = await getUoMData(uomCategoryForm)
      setUomOption(_.get(response, 'data'))
    }
    if (uomCategoryForm) {
      handleGetUoM();
    }
  }, [uomCategoryForm])

  const LabeRequire = (props) => {
    const { text, req } = props;
    return (
      <span>
        {text}&nbsp;
        {req ? <span style={{ fontSize: '13px', color: '#fa383e' }}>*</span> : ''}
      </span>
    );
  };

  const children =
    dataModel &&
    dataModel.map((item) => (
      <Option key={item.itemModelId} value={item.itemModelId}>
        {item.itemModelName}
      </Option>
    ));

  const handleKeyDownNumberDecimal = (e) => {
    let keyCode = e.key.charCodeAt(0);

    const splitValue = _.split(e.target.value, '.');

    if ((keyCode >= 48 && keyCode <= 57) || keyCode === 65 || keyCode === 66 || keyCode === 46) {
      if (keyCode === 46 && e.target.value.indexOf('.') > 1) {
        e.preventDefault();
      }
      if (keyCode === 46) {
        let sumDot = _.sumBy(e.target.value, (o) => {
          return o.charCodeAt(0) === 46 ? 1 : 0;
        });
        if (sumDot >= 1) {
          e.preventDefault();
        }
      }
    } else if (keyCode === 45) {
      e.preventDefault();
    } else {
      e.preventDefault();
    }

    if (_.get(splitValue, '[1]')) {
      if (_.size(splitValue[1]) >= 2 && keyCode !== 66 && e.target.selectionStart > e.target.value.indexOf('.')) {
        e.preventDefault();
      }
    }
  };

  const handleSelectModel = (key, opt) => {
    const brandData = dataModel && dataModel.filter((col) => key.includes(col.itemModelId));
    setFieldsValue({ ['brandName']: _.get(brandData[0], 'itemBrandId') });
  };

  const onSelectChange = (e) => {
    if (e) {
      setFieldsValue({ ['modelName']: undefined });
    }
  };

  return (
    <div>
      <Form layout='vertical'>
        <div className="padding-div-top">
          <Row gutter={[12, 5]}>
            <Col span={6}>
              <span className="item-label-detail">
                <FormattedMessage id="modalItemMasterFormItemNameTextWidth" defaultMessage="Width (m)" />
                <LabeRequire
                  req={false}
                />
              </span>
              <Form.Item>
                {getFieldDecorator('width', {
                  initialValue: record && record.width ? _.get(record, 'width') : undefined,
                  // rules: [
                  //   {
                  //     required: true,
                  //     message: intl.formatMessage({ id: 'modalItemMasterFormItemNameValidateWidth', defaultMessage: 'Please Enter Width' }),
                  //   },
                  // ],
                })(
                  <Input
                    onKeyDown={handleKeyDownDecimal2Fixed}
                    placeholder={intl.formatMessage({
                      id: 'modalItemMasterFormItemPlaceholderWidth',
                      defaultMessage: 'Enter Width(m)',
                    })}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={6}>
              <span className="item-label-detail">
                <FormattedMessage id="modalItemMasterFormItemNameTextLength" defaultMessage="Length (m)" />
                <LabeRequire
                  req={false}
                />
              </span>
              <Form.Item>
                {getFieldDecorator('length', {
                  initialValue: record && record.length ? _.get(record, 'length') : undefined,
                  // rules: [
                  //   {
                  //     required: true,
                  //     message: intl.formatMessage({ id: 'modalItemMasterFormItemNameValidateLength', defaultMessage: 'Please Enter Length' }),
                  //   },
                  // ],
                })(
                  <Input
                    onKeyDown={handleKeyDownDecimal2Fixed}
                    placeholder={intl.formatMessage({
                      id: 'modalItemMasterFormItemPlaceholderLength',
                      defaultMessage: 'Enter Length(m)',
                    })}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={6}>
              <span className="item-label-detail">
                <FormattedMessage id="modalItemMasterFormItemNameTextHeight" defaultMessage="Height (m)" />
                <LabeRequire
                  req={false}
                />
              </span>
              <Form.Item>
                {getFieldDecorator('height', {
                  initialValue: record && record.height ? _.get(record, 'height') : undefined,
                  // rules: [
                  //   {
                  //     required: true,
                  //     message: intl.formatMessage({ id: 'modalItemMasterFormItemNameValidateHeight', defaultMessage: 'Please Enter Height' }),
                  //   },
                  // ],
                })(
                  <Input
                    onKeyDown={handleKeyDownDecimal2Fixed}
                    placeholder={intl.formatMessage({
                      id: 'modalItemMasterFormItemPlaceholderHeight',
                      defaultMessage: 'Enter Height(m)',
                    })}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={6}>
              <span className="item-label-detail">
                <FormattedMessage id="modalItemMasterFormItemNameTextWeight" defaultMessage="Weight (kg)" />
                <LabeRequire
                  req={false}
                />
              </span>
              <Form.Item>
                {getFieldDecorator('weight', {
                  initialValue: record && record.weight ? _.get(record, 'weight') : undefined,
                  // rules: [
                  //   {
                  //     required: true,
                  //     message: intl.formatMessage({ id: 'modalItemMasterFormItemNameValidateWeight', defaultMessage: 'Please Enter Weight' }),
                  //   },
                  // ],
                })(
                  <Input
                    onKeyDown={handleKeyDownDecimal2Fixed}
                    placeholder={intl.formatMessage({
                      id: 'modalItemMasterFormItemPlaceholderWeight',
                      defaultMessage: 'Enter Weight(kg)',
                    })}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[6, 5]}>
            <Col span={12}>
              <span className="item-label-detail">
                <FormattedMessage id="modalItemMasterFormItemNamePickingType" defaultMessage="Picking Type" />
                <LabeRequire
                  req={false}
                />
              </span>
              <Form.Item>
                {getFieldDecorator('pickingType', {
                  initialValue: record && record.pickingTypeId ? _.get(record, 'pickingTypeId') : undefined,
                })(
                  <Select
                    placeholder={intl.formatMessage({
                      id: 'modalItemMasterFormItemPlaceholderPickingType',
                      defaultMessage: 'Select Picking Type',
                    })}
                  >
                    {
                      _.map(pickingType, (item) => (
                        <Option key={item.pickingTypeId}>
                          {item.name}
                        </Option>
                      ))
                    }
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={12} style={{paddingLeft: '8px'}}>
              <span className="item-label-detail">
                <FormattedMessage id="modalItemMasterFormItemTextCredit" defaultMessage="Credit (Days)" />
                <LabeRequire
                  req={false}
                />
              </span>
              <Form.Item>
                {getFieldDecorator('credit', {
                  initialValue: record && record.creditDay ? _.get(record, 'creditDay') : 0,
                })(
                  <Input
                  placeholder={intl.formatMessage({ id: 'modalItemMasterFormItemCredit', defaultMessage: 'Enter Credit (Days)' })}
                  onKeyDown={handleKeyDownDecimal2Fixed}
                />
                )}

              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[12, 5]}>
            <Col span={12}>
              <span className="item-label-detail">
                <FormattedMessage id="modalItemMasterFormItemNameStandardCost" defaultMessage="Standard Cost" />
                <LabeRequire
                  req={false}
                />
              </span>
              <Form.Item>
                {getFieldDecorator('standardCost', {
                  initialValue: record && record.standardCost ? _.get(record, 'standardCost') : 0,
                })(
                  <Input
                  onKeyDown={handleKeyDownDecimal2Fixed}
                />
                )}

              </Form.Item>
            </Col>
            <Col span={12} style={{paddingLeft: '8px'}}>
              <span className="item-label-detail">
                <FormattedMessage id="modalItemMasterFormItemNameStandardPrice" defaultMessage="Standard Price" />
                <LabeRequire
                  req={false}
                />
              </span>
              <Form.Item>
                {getFieldDecorator('standardPrice', {
                  initialValue: record && record.standardPrice ? _.get(record, 'standardPrice') : 0,
                })(
                  <Input
                    onKeyDown={handleKeyDownDecimal2Fixed}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[12, 5]}>
            <Col span={12}>
              <span className="item-label-detail">
                <FormattedMessage id="modalItemMasterFormItemTextFDA" defaultMessage="FDA" />
                <LabeRequire
                  req={false}
                />
              </span>
              <Form.Item>
                {getFieldDecorator('fda', {
                  initialValue: record && record.FDA ? _.get(record, 'FDA') : undefined,
                })(
                  <Input
                  placeholder={intl.formatMessage({ id: 'modalItemMasterFormItemFDA', defaultMessage: 'Enter FDA' })}
                  // onKeyDown={handleKeyDownDecimal2Fixed}
                />
                )}

              </Form.Item>
            </Col>
          </Row>
        </div>
      </Form>
    </div>
  );
};

const ModalFormDetail = Form.create({
  name: 'global_state',
})(CreateItemMasterDetail);

export default ModalFormDetail;
