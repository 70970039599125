import { Col, DatePicker, Divider, Form, Row, Select, Input, Icon, Badge } from 'antd';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import LabeRequireForm from '../../../../components/label-required-form';
import _ from 'lodash';
import ModalSelectQuotationForm from '../select-quotation/quotation-select-modal';
import moment from 'moment';

const { Option } = Select;
const { Search } = Input;
// const dateFormat = 'ddd, MMM DD YYYY';
const dateFormat = 'DD/MM/YYYY'

const CustomerForm = ({ form, setDataForm, dataForm, initialMemberList, handleOpen, record, setSelectItem }) => {
  const { getFieldDecorator, setFieldsValue } = form;
  const intl = useIntl();
  const [isOpenQT, setIsOpenQT] = useState(false);

  const handleOpenQuotationNo = () => {
    setIsOpenQT(true);
  };

  const handleSelectQuotationNo = (value) => {
    setIsOpenQT(false);
  };

  const handleCancelQuotationNo = () => {
    setIsOpenQT(false);
  };

  const onChangeAllowClear = (e) => {
    console.log('onChangeAllowClear', e.target.value);
    if (!e.target.value) {
      setFieldsValue({ ['customerName']: '' });
      setDataForm({});
      setSelectItem([]);
      handleCancelQuotationNo()
    }
  };

  const MemberOption = (o, i) => {
    return (
      <Option key={i} value={o.memComId}>
        {o.fullname}
      </Option>
    );
  };

  return (
    <Form name="customer" colon={false} className="po-form">
      <Row gutter={[24]}>
        <Col span={8}>
          <Form.Item
            label={<LabeRequireForm text={intl.formatMessage({ id: 'quotationLabelCustomer', defaultMessage: 'Customer' })} req={true} />}
          >
            {getFieldDecorator('customerName', {
              initialValue: _.get(record, 'customerName') || '',
              rules: [
                {
                  required: true,
                  message: intl.formatMessage({ id: 'quotationWarnCustomer', defaultMessage: 'please select customer' }),
                },
              ],
            })(
              <Search
                placeholder={intl.formatMessage({ id: 'quotationHintCustomer', defaultMessage: 'Select Customer' })}
                onSearch={(value) => handleOpen()}
                enterButton
                className="search-icon-input"
                readOnly
              />
            )}
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={
              <LabeRequireForm
                text={intl.formatMessage({ id: 'quotationLabelCustomerAddress', defaultMessage: 'Customer Address' })}
                req={false}
              />
            }
            className="form-line-height"
          >
            {_.get(dataForm, 'customerAddressBookId') ? (
              <div className="text-group-customer">
                <div className="text-location-short">
                  <Icon
                    type="environment"
                    style={{
                      fontSize: '12px',
                      color: '#1D3557',
                      paddingLeft: '0px',
                      paddingRight: '4px',
                      cursor: 'pointer',
                    }}
                  />
                  <span
                    title={`${_.get(dataForm, 'address')}} ${(<Badge color="#000000" className="badge-text" />)} ${_.get(
                      dataForm,
                      'fullAddress'
                    )}`}
                    style={{ color: '#050505' }}
                  >
                    {_.get(dataForm, 'address')} <Badge color="#000000" className="badge-text" /> {_.get(dataForm, 'fullAddress')}
                  </span>
                </div>
                <div className="text-short-placeholer">
                  <span style={{ color: '#898989' }}>
                    {intl.formatMessage({ id: 'quotationLabelTaxNo', defaultMessage: 'Tax No.' })} {_.get(dataForm, 'customerTaxNo') || '-'}
                  </span>
                </div>
              </div>
            ) : (
              <div className="text-group-customer">
                <div className="text-short-placeholer">
                  <Icon
                    type="environment"
                    style={{
                      fontSize: '12px',
                      color: '#1D3557',
                      paddingLeft: '0px',
                      paddingRight: '4px',
                      cursor: 'pointer',
                    }}
                  />
                  <span style={{ color: '#898989' }}>
                    {intl.formatMessage({ id: 'quotationLabelCustomerAddress', defaultMessage: 'Customer Address' })}
                  </span>
                </div>
                <div className="text-short-placeholer">
                  <span style={{ color: '#898989' }}>{intl.formatMessage({ id: 'quotationLabelTaxNo', defaultMessage: 'Tax No.' })}</span>
                </div>
              </div>
            )}
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            label={
              <LabeRequireForm text={intl.formatMessage({ id: 'quotationLabelCustomerContact', defaultMessage: 'Contact' })} req={false} />
            }
            className="form-line-height"
          >
            {_.get(dataForm, 'customerContactId') ? (
              <div className="text-group-customer">
                <div className="text-location-short">
                  <span style={{ color: '#050505' }}>{_.get(dataForm, 'customerContactName')}</span>
                </div>
                <div className="text-location-short">
                  <span style={{ color: '#050505' }}>
                    {_.get(dataForm, 'customerContactPhone')}, {_.get(dataForm, 'customerContactEmail')}
                  </span>
                </div>
              </div>
            ) : (
              <div className="text-group-customer">
                <div className="text-short-placeholer">
                  <span style={{ color: '#898989' }}>
                    {intl.formatMessage({ id: 'quotationLabelCustomerContact', defaultMessage: 'Contact' })}
                  </span>
                </div>
                <div className="text-short-placeholer">
                  <span style={{ color: '#898989' }}>
                    {intl.formatMessage({ id: 'quotationLabelPhone', defaultMessage: 'Email' })},{' '}
                    {intl.formatMessage({ id: 'quotationLabelPhone', defaultMessage: 'Phone,' })}
                  </span>
                </div>
              </div>
            )}
          </Form.Item>
        </Col>
      </Row>
      <Divider type="horizontal" style={{ marginBottom: '10px' }} />
      <Row gutter={[24]}>
        <Col span={8}>
          <Form.Item
            label={<LabeRequireForm text={intl.formatMessage({ id: 'quotationLabelIssueBy', defaultMessage: 'Issue by' })} req={false} />}
          >
            {getFieldDecorator('by', {
              initialValue: _.get(record, 'issueBy') || localStorage.getItem('memComId'),
            })(
              <Select
                placeholder={intl.formatMessage({ id: 'quotationHintIssueBy', defaultMessage: 'Select Issue by' })}
                showSearch
                filterOption={(input, option) =>
                  option.props.children
                    .toString()
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {initialMemberList && initialMemberList.map((item, index) => MemberOption(item, index))}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={
              <LabeRequireForm text={intl.formatMessage({ id: 'quotationLabelIssueDate', defaultMessage: 'Issue Date' })} req={true} />
            }
          >
            {getFieldDecorator('date', {
              initialValue: _.get(record, 'issueDate') ? moment(_.get(record, 'issueDate'), 'YYYY-MM-DD') : moment(),
              rules: [
                {
                  required: true,
                  message: intl.formatMessage({ id: 'quotationWarnIssueDate', defaultMessage: 'please select Issue Date' }),
                },
              ],
            })(
              <DatePicker
                allowClear={false}
                format={dateFormat}
                style={{ width: '100%' }}
                placeholder={intl.formatMessage({ id: 'quotationHintIssueBy', defaultMessage: 'Select Issue by' })}
              />
            )}
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={
              <LabeRequireForm
                text={intl.formatMessage({ id: 'quotationLabelReferenceNo', defaultMessage: 'Reference No.' })}
                req={false}
              />
            }
          >
            {getFieldDecorator('refNo', { initialValue: _.get(record, 'referenceNo') || '' })(<Input />)}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[24]}>
        <Col span={8}>
          <Form.Item
            label={
              <LabeRequireForm
                text={intl.formatMessage({ id: 'quotationLabelPurchaseOrderNo', defaultMessage: 'Purchase Order No.' })}
                req={false}
              />
            }
          >
            {getFieldDecorator('poOrderNo', { initialValue: _.get(record, 'purchaseOrderNo') || '' })(
              <Input placeholder={intl.formatMessage({ id: 'quotationHintPurchaseOrderNo', defaultMessage: 'Enter Purchase Order No.' })} />
            )}
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={
              <LabeRequireForm
                text={intl.formatMessage({ id: 'quotationLabelPurchaseDate', defaultMessage: 'Purchase Date' })}
                req={false}
              />
            }
          >
            {getFieldDecorator('poDate', {
              initialValue: _.get(record, 'purchaseOrderDate') ? moment(_.get(record, 'purchaseOrderDate'), 'YYYY-MM-DD') : '',
              rules: [
                {
                  required: false,
                  message: intl.formatMessage({ id: 'quotationWarnPurchaseDate', defaultMessage: 'please select Purchase Date' }),
                },
              ],
            })(
              <DatePicker
                allowClear={false}
                format={dateFormat}
                style={{ width: '100%' }}
                placeholder={intl.formatMessage({ id: 'quotationHintPurchaseDate', defaultMessage: 'Select Purchase Date' })}
              />
            )}
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={
              <LabeRequireForm
                text={intl.formatMessage({ id: 'quotationLabelQuotationNo', defaultMessage: 'Quotation No.' })}
                req={false}
              />
            }
          >
            {getFieldDecorator('quotationNo', { initialValue: _.get(record, 'quotationNo') || '' })(
              <Search
                placeholder={intl.formatMessage({ id: 'quotationHintQuotationNo', defaultMessage: 'Quotation No.' })}
                onSearch={(value) => handleOpenQuotationNo()}
                enterButton
                className="search-icon-input"
                // readOnly
                allowClear={true}
                onChange={(e) => onChangeAllowClear(e)}
              />
            )}
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[24]}>
        <Col span={8}>
          <Form.Item
            label={
              <LabeRequireForm
                text={intl.formatMessage({ id: 'quotationLabelReferenceSalesDocumentNo', defaultMessage: 'Reference Sales Document No.' })}
                req={false}
              />
            }
          >
            {getFieldDecorator('reference', { initialValue: _.get(record, 'reference') || '' })(
              <Input placeholder={intl.formatMessage({ id: 'quotationHintReferenceSalesDocumentNo', defaultMessage: 'Enter Reference Sales Document No.' })} />
            )}
          </Form.Item>
        </Col>
      </Row>

      <ModalSelectQuotationForm
        visible={isOpenQT}
        onOk={handleSelectQuotationNo}
        onCancel={handleCancelQuotationNo}
        setDataForm={setDataForm}
        dataForm={dataForm}
        setSelectItem={setSelectItem}
      />
    </Form>
  );
};

CustomerForm.defaultProps = {};

export default CustomerForm;
