import React, { useState, useEffect, useContext } from 'react';
import { Avatar, Col, Modal, Row, Table, Tabs, Divider, Icon } from 'antd';
import { MonitorContext } from '../monitor-context';
import { PageSettings } from '../../../config/page-settings';
import Button01 from '../../../components/v2/button_01';
import Button02 from '../../../components/v2/button_02';
import TrackingDetails from './tracking-details';
import AssigneeTaskDetails from './assignee-task-details';
import styles from './css/assignee-resource.css';
import stylesAssigneeDetails from './css/assignee-details.css';
import styled from 'styled-components';
import _ from 'lodash';

const { TabPane } = Tabs;

const StylesModal = styled(Modal)`
.ant-modal-body {
  padding: unset;
}
/* .ant-modal-content {
  height: 696px
}
.ant-modal {
  top: 55px;
} */
`;

export default React.memo((props) => {
  const { stateMain, setStateMain, fncMain } = useContext(MonitorContext);

  // const {
  //   visibleAssignee,
  //   setVisibleAssignee,
  //   confirmAssigee,
  // } = props;

 

  const information = stateMain.resourceTaskData && _.get(stateMain, 'resourceTaskData.information');
  const tracking = stateMain.resourceTaskData && _.get(stateMain, 'resourceTaskData.tracking');



  const columnsAssignee = [
    {
      dataIndex: 'profile',
      key: 'profile',
      align: 'center',
      width: 70,
      render: (text, record, index) => <Avatar src={record.profile} />,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Shift',
      dataIndex: 'shift',
      key: 'shift',
    },
    {
      title: 'Distance',
      dataIndex: 'distance',
      key: 'distance',
    },
    {
      title: 'Sub Task',
      dataIndex: 'subtasks',
      key: 'subtasks',
      width: 140,
      render: (text, record, index) => record.subtasks.map((item, i) => {
        const iconExcel = () => (
          <img key={i} className="table-status-img" src={item.icon} />
        );

        return <div className="table-status">
          <div key={i} className="table-status-key">
            <Icon className="table-status-icon" component={iconExcel} />
          </div>
        </div>
      })
    },
    {
      dataIndex: 'options',
      key: 'options',
      render: (text, record, index) => {
        return <div>
          <Button02 style={{margin : '0px 0px 0px 10px'}} btnsize="wd_at">Remove</Button02>
        </div>
      },
    },
  ];

  const columnsCandidate = [
    {
      dataIndex: 'partImage',
      key: 'partImage',
      align: 'center',
      width: 70,
      render: (text, record, index) => <Avatar src={record.partImage} />,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Shift',
      dataIndex: 'shift',
      key: 'shift',
    },
    {
      title: 'Distance',
      dataIndex: 'distance',
      key: 'distance',
    },
    {
      title: 'Sub Task',
      dataIndex: 'subtasks',
      key: 'subtasks',
      width: 140,
      render: (text, record, index) => { },
    },
    {
      dataIndex: 'options',
      key: 'options',
      render: (text, record, index) => {
        return <div>
          <Button02 style={{margin : '0px 0px 0px 10px'}} btnsize="wd_at">Remove</Button02>
        </div>
      },
    },
  ];

  const columnsAvailable = [
    {
      dataIndex: 'profile',
      key: 'profile',
      align: 'center',
      width: 70,
      render: (text, record, index) => <Avatar src={record.profile} />,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Shift',
      dataIndex: 'shift',
      key: 'shift',
    },
    {
      title: 'Distance',
      dataIndex: 'distance',
      key: 'distance',
    },
    {
      title: 'Sub Task',
      dataIndex: 'subtasks',
      key: 'subtasks',
      width: 140,
      render: (text, record, index) => record.subtasks.map((item, i) => {
        const iconExcel = () => (
          <img key={i} className="table-status-img" src={item.icon} />
        );

        return <div className="table-status">
          <div key={i} className="table-status-key">
            <Icon className="table-status-icon" component={iconExcel} />
          </div>
        </div>
      })
    },
    {
      dataIndex: 'options',
      key: 'options',
      render: (text, record, index) => {
        return <div>
          <Button02 style={{margin : '0px 0px 0px 10px'}} btnsize="wd_at">Remove</Button02>
        </div>
      },
    },
  ];

  const columnsOnGoing = [
    {
      dataIndex: 'profile',
      key: 'profile',
      align: 'center',
      width: 70,
      render: (text, record, index) => <Avatar src={record.profile} />,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Shift',
      dataIndex: 'shift',
      key: 'shift',
    },
    {
      title: 'Distance',
      dataIndex: 'distance',
      key: 'distance',
    },
    {
      title: 'Sub Task',
      dataIndex: 'subtasks',
      key: 'subtasks',
      width: 140,
      render: (text, record, index) => { },
    },
    {
      dataIndex: 'options',
      key: 'options',
      render: (text, record, index) => {
        return <div>
          <Button02 style={{margin : '0px 0px 0px 10px'}} btnsize="wd_at">Remove</Button02>
        </div>
      },
    },
  ];

  const columnsOff = [
    {
      dataIndex: 'profile',
      key: 'profile',
      align: 'center',
      width: 70,
      render: (text, record, index) => <Avatar src={record.profile} />,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Shift',
      dataIndex: 'shift',
      key: 'shift',
    },
    {
      title: 'Distance',
      dataIndex: 'distance',
      key: 'distance',
    },
    {
      title: 'Sub Task',
      dataIndex: 'subtasks',
      key: 'subtasks',
      width: 140,
      render: (text, record, index) => { },
    },
    {
      dataIndex: 'options',
      key: 'options',
      render: (text, record, index) => {
        return <div>
          <Button02 style={{margin : '0px 0px 0px 10px'}} btnsize="wd_at">Remove</Button02>
        </div>
      },
    },
  ];


  return (
    <div>
      <StylesModal
        className="assignee-resource-modal"
        centered={true}
        title={<span className="assignee-details-title">Delivery</span>}
        footer={null}
        width={1200}
        visible={stateMain.visibleAssignee}
        onCancel={() => setStateMain.setVisibleAssignee(false)}
      >
        <Row>
          <Col span={16} className="assignee-resource-col-left">
            <div className="assignee-resource-col-div">
              <Row>
                <Col className="assignee-resource-col-col-left">
                  <p className="assignee-label">Assignee</p>
                  <Table
                    className="assignee-resource-table"
                    scroll={{ y: 290 }}
                    dataSource={stateMain.resourceTaskData.assignee || []}
                    columns={columnsAssignee}
                  />
                </Col>
              </Row>

              <Row>
                <Col className="assignee-resource-col-col-right" >
                  <Tabs defaultActiveKey="1">
                    <TabPane tab="Candidate" key="1">
                      <Table
                        className="assignee-resource-table"
                        scroll={{ y: 290 }}
                        dataSource={stateMain.resourceTaskData.candidates || []}
                        columns={columnsCandidate}
                      />
                    </TabPane>
                    <TabPane tab="Available" key="2">
                      <Table
                        className="assignee-resource-table"
                        scroll={{ y: 290 }}
                        dataSource={stateMain.resourceTaskData.available || []}
                        columns={columnsAvailable}
                      />
                    </TabPane>
                    <TabPane tab="On Going" key="3">
                      On Going
                      <Table
                        className="assignee-resource-table"
                        scroll={{ y: 290 }}
                        dataSource={stateMain.resourceTaskData.onGoing || []}
                        columns={columnsOnGoing}
                      />
                    </TabPane>
                    <TabPane tab="Off" key="4">
                      Off
                      <Table
                        className="assignee-resource-table"
                        scroll={{ y: 290 }}
                        dataSource={stateMain.resourceTaskData.off || []}
                        columns={columnsOff}
                      />
                    </TabPane>
                  </Tabs>
                </Col>
              </Row>
              </div>
              <Divider className="assignee-resource-footer-divider" />
              <div className="assignee-resource-footer">
                <Button02 style={{margin : '0px 0px 0px 10px'}} btnsize="wd_df" onClick={() => setStateMain.setVisibleAssignee(false)}>
                  Cancel
                </Button02>
                <Button01 btnsize="wd_df" type="primary" onCilck={() => setStateMain.setVisibleAssignee(false)}>
                  Confirm
                </Button01>
              </div>
          </Col>
          <Divider className="assignee-resource-divider" type="vertical" />
          <Col span={8} className="assignee-resource-col-right">
            <Row>
              <Col span={24}>
                <AssigneeTaskDetails information={information} />
              </Col>
            </Row>
            <Divider className="assignee-resource-divider-trackingDetails" />
            <Row>
              <Col span={24}>
                <TrackingDetails tracking={tracking} />
              </Col>
            </Row>
          </Col>
        </Row>
      </StylesModal>
    </div>
  );
},
  // (prev, next) => {
  //   return prev.stateMain === next.stateMain
  //     && prev.taskDetailData === next.taskDetailData
  //     && prev.visible === next.visible
  //     && prev.setVisible === next.setVisible
  // }
);
