import React, { useEffect, useState } from 'react';
import TaskType from './task-type';
import './css/index.css';
import { getTaskType, saveTaskType, getOrderType } from '../../../../controllers/setting/task-type';
import { successNotification, errorNotification } from '../../../../components/v2/notification'
import { PageSettings } from '../../../../config/page-settings'
import _ from 'lodash';
import Ordertype from './order-type';

export default () => {
  const [taskTypeData, setTaskTypeData] = useState([]);
  const [toggleEdit, setToggleEdit] = useState(false);
  const [dataTaskTypeNew, setDataTaskTypeNew] = useState([]);
  const [visibleOrderType, setVisibleOrderType] = useState(false)
  const [defaultData, setDefaultData] = useState()
  const [orderData, setOrderData] = useState([])
  const [trigger, setTrigger] = useState(false)
  // const taskTypeDataClone = [...taskTypeData]


  useEffect(() => {
    const getOrder = async () => {
      const response = await getOrderType()
      setOrderData(_.get(response, 'data'))
    }
    getOrder()
  }, [])


  useEffect(() => {
    const getApiTaskType = async () => {
      const data = await getTaskType('menuType=transportation');
      setTaskTypeData(data);
    }
    getApiTaskType();
  }, [toggleEdit, dataTaskTypeNew, trigger])


  const handleSave = async (e) => {
    e.preventDefault();
    if (dataTaskTypeNew.length > 0) {
      try {
        const response = await saveTaskType(dataTaskTypeNew);
        if (response.statusCode) {
          successNotification(response.message);
          setToggleEdit(false);
        } else {
          errorNotification(response.message);
        }
      } catch (error) {
        errorNotification(error.response.data.message);
      }
    } else {
      setToggleEdit(false);
    }
  }

  const handleOpenModal = (value) => {
    setVisibleOrderType(true)
    setDefaultData(value)
  }

  return (
    <PageSettings.Consumer>
      {
        ({ checkPermissionAction }) => (
          checkPermissionAction(`P6S3PG1C1`, `P6S3PG1C1A1`) ?
            <div>
              <TaskType
                data={taskTypeData}
                handleOpenModal={handleOpenModal}
                setTrigger={setTrigger}
              />

              <Ordertype
                visible={visibleOrderType}
                setVisible={setVisibleOrderType}
                defaultData={defaultData}
                orderData={orderData}
                setTrigger={setTrigger}
              />
            </div>
            : null
        )
      }

    </PageSettings.Consumer>
  )
}