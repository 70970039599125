import React, { useState } from 'react';
import CustomerForm from './customer-paid';
import { FormattedMessage, useIntl } from 'react-intl';
import Button_01 from '../../../../../components/v2/button_01';
import CustomTableComponent from '../../../components/table';
import PriceForm from './price-form-paid';
import { Button, Divider, Dropdown, Menu } from 'antd';
import { numberFormatter } from '../../../../../component-function/fnc-inputnumber-currency';
import _ from 'lodash';
import ButtonActionGroup from '../../../../../components/action-button';
import moment from 'moment';
import ReactBnbGallery from 'react-bnb-gallery';

const DetailsTabPaid = ({ form, property }) => {
  const intl = useIntl();
  const {
    handleOpenModalUpload,
    setDataForm,
    dataForm,
    setVisibleItem,
    initialMemberList,
    handleOpen,
    selectItem,
    setSelectItem,
    attData,
    setAttData,
    initialTaxList,
    receiveData,
    setReceiveData,
    handleOpenCash,
    handleOpenCancelPaid,
    handleOpenModalUploadPaid,
    handleOpenBank,
    handleOpenCredit,
    handleOpenCheque,
    handleOpenVoucher,
    handleOpenCreditNote,
    visiblePaid,
    saleId,
    blNo,
    typeChecked,
  } = property;

  const [stateModal, setStateModal] = useState(false);
  const [modalView, setModalView] = useState();

  // console.log('PaiddataForm', dataForm);
  // console.log('AttPaid',attData);
  // console.log('selectItem', selectItem)

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
      fixed: 'left',
    },
    {
      title: intl.formatMessage({ id: 'ARMethod', defaultMessage: 'Method' }),
      dataIndex: 'methodName',
      key: 'methodName',

      width: 150,
      fixed: 'left',
    },
    {
      title: intl.formatMessage({ id: 'ARAmount', defaultMessage: 'Amount' }),
      dataIndex: 'amountTxt',
      key: 'amountTxt',
    },
    {
      title: intl.formatMessage({ id: 'ARPaidDate', defaultMessage: 'Paid Date' }),
      dataIndex: 'detail',
      key: 'detail',
      render: (text, record, index) => moment(_.get(record, 'detail.paidDate')).format('DD/MM/YYYY'),
    },
    {
      title: intl.formatMessage({ id: 'ARBank', defaultMessage: 'Bank' }),
      dataIndex: 'bankName',
      key: 'bankName',
    },
    {
      title: intl.formatMessage({ id: 'ARDescription', defaultMessage: 'Description' }),
      dataIndex: 'description',
      key: 'description',
      width: 300,
      render: (text, record, index) => (
        <div>
          {record.detail.methodCode === 'cash' && <>{record.description}</>}
          {record.detail.methodCode === 'banktransfer' && (
            <>
              {record.description === undefined || record.description === ''
                ? intl.formatMessage({ id: 'ARaccountno', defaultMessage: 'account no.' }) + ' ' + _.get(record, 'detail.accountNo')
                : intl.formatMessage({ id: 'ARaccountno', defaultMessage: 'account no.' }) +
                  _.get(record, 'detail.accountNo') +
                  ', ' +
                  record.description}
            </>
          )}
          {record.detail.methodCode === 'creditcard' &&
            (record.description === undefined || record.description === ''
              ? _.get(record, 'detail.creditCardTypeTxt') +
                ' ' +
                _.get(record, 'detail.creditCardNo') +
                ' ' +
                _.get(record, 'detail.cardHolderName')
              : _.get(record, 'detail.creditCardTypeTxt') +
                ' ' +
                _.get(record, 'detail.creditCardNo') +
                ' ' +
                _.get(record, 'detail.cardHolderName') +
                ', ' +
                record.description)}
          {record.detail.methodCode === 'cheque' &&
            (record.description === undefined || record.description === ''
              ? _.get(record, 'detail.chequeNo') +
                ' ' +
                _.get(record, 'detail.payTo') +
                ' ' +
                intl.formatMessage({ id: 'ARChequeDate', defaultMessage: 'Cheque Date' }) +
                moment(_.get(record, 'detail.chequeDate')).format('DD/MM/YYYY')
              : _.get(record, 'detail.chequeNo') +
                ' ' +
                _.get(record, 'detail.payTo') +
                ' ' +
                intl.formatMessage({ id: 'ARChequeDate', defaultMessage: 'Cheque Date' }) +
                moment(_.get(record, 'detail.chequeDate')).format('DD/MM/YYYY') +
                ', ' +
                record.description)}
          {record.detail.methodCode === 'voucher' &&
            (record.description === undefined || record.description === ''
              ? _.get(record, 'detail.voucherNo')
                ? _.get(record, 'detail.voucherNo') + ' ' + _.get(record, 'detail.voucherName')
                : +' ' + _.get(record, 'detail.voucherName')
              : _.get(record, 'detail.voucherNo')
              ? _.get(record, 'detail.voucherNo') + ' ' + _.get(record, 'detail.voucherName') + ', ' + record.description
              : _.get(record, 'detail.voucherNo') + ' ' + _.get(record, 'detail.voucherName') + ', ' + record.description)}
          {record.detail.methodCode === 'claim' && <>{record.description}</>}
          {record.detail.methodCode === 'creditnote' &&
            `${intl.formatMessage({ id: 'ARCreditNoteNo', defaultMessage: 'Credit Note No.' })} ${_.get(
              record,
              'detail.creditNoteNo'
            )}, ${moment(_.get(record, 'detail.docDate')).format('DD/MM/YYYY') || ''}${_.get(record, 'description') ? ',' : ''} ${
              _.get(record, 'description') ? _.get(record, 'description') : ''
            }`}
        </div>
      ),
    },
    {
      title: intl.formatMessage({ id: 'ARStatus', defaultMessage: 'Status' }),
      dataIndex: 'statusTxt',
      key: 'statusTxt',
    },
    {
      title: intl.formatMessage({ id: 'ARReson', defaultMessage: 'Reason' }),
      dataIndex: 'reasonTxt',
      key: 'reasonTxt',
    },
  ];

  const columnsAttach = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
      fixed: 'left',
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsFileName', defaultMessage: 'File Name' }),
      dataIndex: 'attachmentName',
      key: 'attachmentName',
      sorter: true,
      render: (x, data) =>
        _.get(dataForm, 'code') !== 'waiting' || _.get(dataForm, 'code') === 'partialpayment' ? (
          <span onClick={() => handlePreview(data)}>{data.fileName}</span>
        ) : (
          data.attachmentName
        ),
    },
    // {
    //   title: intl.formatMessage({ id: 'purchesOrderModalColumnsFileName', defaultMessage: 'File Name' }),
    //   dataIndex: 'fileName',
    //   key: 'fileName',
    //   sorter: true,
    //   render: (text, record, index) => (
    //     <Button
    //       onClick={() => {
    //         window.open(_.get(record, 'fileUrl'), _.get(record, 'fileName', '_blank'));
    //       }}
    //       type="link"
    //     >
    //       {_.get(record, 'fileName')}
    //     </Button>
    //   ),
    // },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsRemark', defaultMessage: 'Remark' }),
      dataIndex: 'remark',
      key: 'remark',
    },
  ];

  const menuColumn = (
    <Menu>
      <Menu.Item
        onClick={() => {
          handleOpenCash('cash');
        }}
      >
        <Button
          ghost
          type="link"
          onClick={() => {
            handleOpenCash('cash');
          }}
          key={'1'}
        >
          <FormattedMessage id="ARCash" defaultMessage="Cash" />
        </Button>
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          handleOpenBank();
        }}
      >
        <Button
          ghost
          type="link"
          onClick={() => {
            handleOpenBank();
          }}
          key={'2'}
        >
          <FormattedMessage id="ARBankTransfer" defaultMessage="Bank Transfer" />
        </Button>
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          handleOpenCredit();
        }}
      >
        <Button
          ghost
          type="link"
          onClick={() => {
            handleOpenCredit();
          }}
          key={'3'}
        >
          <FormattedMessage id="ARCreditCard" defaultMessage="Credit Card" />
        </Button>
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          handleOpenCheque();
        }}
      >
        <Button
          ghost
          type="link"
          onClick={() => {
            handleOpenCheque();
          }}
          key={'4'}
        >
          <FormattedMessage id="ARCheque" defaultMessage="Cheque" />
        </Button>
      </Menu.Item>
      {typeChecked === 'task' ? null : (
        <Menu.Item
          onClick={() => {
            handleOpenVoucher();
          }}
        >
          <Button
            ghost
            type="link"
            onClick={() => {
              handleOpenVoucher();
            }}
            key={'5'}
          >
            <FormattedMessage id="ARVoucher" defaultMessage="Voucher" />
          </Button>
        </Menu.Item>
      )}

      <Menu.Item
        onClick={() => {
          handleOpenCash('claim');
        }}
      >
        <Button
          ghost
          type="link"
          onClick={() => {
            handleOpenCash('claim');
          }}
          key={'5'}
        >
          <FormattedMessage id="ARClaim" defaultMessage="Claim" />
        </Button>
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          handleOpenCreditNote('creditnote');
        }}
      >
        <Button
          ghost
          type="link"
          onClick={() => {
            handleOpenCreditNote('creditnote');
          }}
          key={'6'}
        >
          <FormattedMessage id="ARCreditNote" defaultMessage="Credit Note" />
        </Button>
      </Menu.Item>
    </Menu>
  );

  const deleteAtt = (value) => {
    const filterSelect = _.filter(attData, (item) => {
      return item.fileHash !== value;
    });
    setAttData(filterSelect);
  };

  const contentActionItem = (record) => {
    console.log('contentAction', record);
    return _.get(record, 'statusCode') === 'success' ? (
      <div style={{ display: 'grid' }}>
        <div>
          <Button
            style={{ width: 100 }}
            type="link"
            ghost
            onClick={() => {
              handleOpenCancelPaid(record);
            }}
          >
            <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
          </Button>
        </div>
      </div>
    ) : null;
  };

  const contentAction = (record) => {
    console.log('contentAction', record);
    return _.get(dataForm, 'code') === 'waiting' || _.get(dataForm, 'code') === 'partialpayment' ? (
      <div style={{ display: 'grid' }}>
        <div>
          <Button style={{ width: 100 }} type="link" ghost onClick={() => handleOpenModalUploadPaid(record)}>
            <FormattedMessage id="orgTabMemberBtnEdit" defaultMessage="Edit" />
          </Button>
        </div>
        <div>
          <Button style={{ width: 100 }} type="link" ghost onClick={() => deleteAtt(record.fileHash)}>
            <FormattedMessage id="btnDelete" defaultMessage="Delete" />
          </Button>
        </div>
      </div>
    ) : null;
  };

  const HandlerModal = (item) => {
    return (
      <div>
        <ReactBnbGallery show={stateModal} photos={modalView} onClose={() => setStateModal(false)} />
      </div>
    );
  };

  const handlePreview = async (file) => {
    console.log('filehandlePreview', file);
    let isPDF = _.get(file, 'fileName').includes('.pdf');
    if (isPDF) return window.open(_.get(file, 'fileUrl'), '_blank');
    const mapdata = {
      photo: _.get(file, 'fileUrl'),
      number: 1,
      caption: _.get(file, 'remark') !== 'undefined' ? _.get(file, 'remark') : undefined,
      thumbnail: _.get(file, 'fileUrl'),
    };

    console.log('mapdata', mapdata);
    setModalView(mapdata);
    setStateModal(!stateModal);
  };

  return (
    <div className="scroll-sm scroll-height">
      <div className="content-body">
        <CustomerForm
          form={form}
          setDataForm={setDataForm}
          dataForm={dataForm}
          initialMemberList={initialMemberList}
          handleOpen={handleOpen}
          saleId={saleId}
          blNo={blNo}
        />
      </div>
      <div>
        <div className="item-title">
          <span className="item-title-text">
            <FormattedMessage id="ARPaymentMethod" defaultMessage="Payment Method" />
          </span>
          <div>
            {_.get(dataForm, 'code') === 'waiting' || _.get(dataForm, 'code') === 'partialpayment' ? (
              <Dropdown overlay={menuColumn} trigger={['click']} placement="bottomRight">
                <Button_01 key="add" style={{ margin: '0px 0px 0px 10px' }} type="primary" btnsize="wd_df">
                  <FormattedMessage id="btnAdd" defaultMessage="Add" />
                </Button_01>
              </Dropdown>
            ) : null}
          </div>
        </div>
        <Divider type="horizontal" style={{ marginBottom: '10px', marginTop: '10px' }} />
        <div className="content-body">
          <CustomTableComponent
            onRow={true}
            role={true}
            columns={columns}
            dataSource={selectItem}
            scroll={{ x: 1500 }}
            contentAction={contentActionItem}
          />
          <div className="content-body-price">
            <PriceForm
              form={form}
              dataForm={dataForm}
              initialMemberList={initialMemberList}
              receiveData={receiveData}
              visiblePaid={visiblePaid}
            />
          </div>
        </div>
      </div>
      <div>
        <div className="item-title">
          <span className="item-title-text">
            <FormattedMessage id="quotationLabelAttactment" defaultMessage="Attactment" />
          </span>
          <div>
            {_.get(dataForm, 'code') === 'waiting' || _.get(dataForm, 'code') === 'partialpayment' ? (
              <Button_01
                key="add"
                style={{ margin: '0px 0px 0px 10px' }}
                type="primary"
                btnsize="wd_df"
                onClick={() => handleOpenModalUploadPaid()}
              >
                <FormattedMessage id="btnUpload" defaultMessage="Upload" />
              </Button_01>
            ) : null}
          </div>
        </div>
        <Divider type="horizontal" style={{ marginBottom: '10px', marginTop: '10px' }} />
        <div className="content-body">
          <CustomTableComponent
            onRow={true}
            role={true}
            columns={columnsAttach}
            dataSource={attData}
            scroll={{ x: true }}
            contentAction={contentAction}
          />
          <HandlerModal />
        </div>
      </div>
    </div>
  );
};

export default DetailsTabPaid;
