import { Card, Col, Row, Tabs } from 'antd';
import React, { useContext, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { AllticketContext } from '../allticket-context';
import Filter from './filter';
import FillterForm from './filterform';
import Button_01 from '../../../../../components/v2/button_01';


const LeftCardAllTicket = () => {
  const intl = useIntl();
  const { state, setState, fnc } = useContext(AllticketContext);
  const { menuList, indeterminateRequest, indeterminateTeam, checkedKeysRequest, checkedKeysTeam, checkAllRequest, checkAllTeam } = state;
  const {
    setCheckedKeysRequest,
    setCheckedKeysTeam,
    setIndeterminateTeam,
    setIndeterminateRequest,
    setCheckAllRequest,
    setCheckAllTeam,
    setEvent,
    setPage,
  } = setState;
  const { onCheckRequest, onCheckTeam } = fnc;
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);

  const onExpand = (expandedKeys) => {
    setExpandedKeys(expandedKeys);
    setAutoExpandParent(false);
  };

  return (
    <Card className="left-card-all-ticket">
      <div>
        <div className="filter-text-summary-text" style={{borderBottom: '1px solid #e4e6eb'}}>
          <span className="filter-text-summary-text-size">{intl.formatMessage({ id: `TicketFilter`, defaultMessage: 'Filter' })}</span>
        </div>
        <div className="filterBackgrounds" style={{ overflowY: 'auto', height: 'calc(100vh - 221px)' }}>
        {/* <Filter
          Data={menuList.data}
          indeterminateTeam={indeterminateTeam}
          setCheckedListTeam={setState.setCheckTeam}
          setIndeterminateTeam={setIndeterminateTeam}
          setOrgIdRaw={setState.setOrgIdRaw}
          orgIdRaw={state.orgIdRaw}
          setPaginationPage={setState.setPaginationPage}
          checkedKeysTeam={checkedKeysTeam}
          autoExpandParent={autoExpandParent}
          expandedKeys={expandedKeys}
          onCheckTeam={onCheckTeam}
          onExpand={onExpand}
          setCheckAllTeam={setCheckAllTeam}
          checkAllTeam={checkAllTeam}
        ></Filter> */}
        <FillterForm />
        </div>
        <div>
          <Row>
            <Col span={24} style={{ padding: '0px 16px 16px 16px' }}>
              <Button_01
                key="submit"
                type="primary"
                style={{ width: '100%', margin: 'unset' }}
                onClick={() => {setEvent((current) => !current);setPage(1)}}
              >
                <FormattedMessage id="btnFilterApply" defaultMessage="Apply" />
              </Button_01>
            </Col>
          </Row>
        </div>
      </div>
    </Card>
  );
};

export default LeftCardAllTicket;
