import React, { useEffect, useState } from 'react';
import { ResponsiveLine, Line } from '@nivo/line';
import _ from 'lodash';
import moment from 'moment';
import { useIntl, FormattedMessage } from 'react-intl';
import { Icon, Tag, Button } from 'antd';
import MyTag from '../../../../../components/v2/checkableTag';
import ButtonCircle from '../../../../../components/v2/button-circle';
import './css/index.css';

const commonProperties = {
  margin: { top: 10, right: 50, bottom: 50, left: 40 },
  enableSlices: 'x',
};

export default (props) => {
  const { trackingResource, setTrackingResource, setlatLngResource, latLngResource, date, LangCode } = props;
  let carRegistration = _.get(trackingResource, 'dataSpeedTrack.dataNoVehicle');
  let profile = _.get(trackingResource, 'dataSpeedTrack.memberProfile') ? _.get(trackingResource, 'dataSpeedTrack.memberProfile') : '';

  let xMin = moment(date, 'YYYY-MM-DD')
    .startOf('day')
    .toDate();
  let xMax = moment(date, 'YYYY-MM-DD')
    .endOf('day')
    .toDate();

  const [dataLine, setDataLine] = useState([]);
  const [dataLineAll, setDataLineAll] = useState([]);
  const [tempData, setTempData] = useState([]);
  const [datatrackConfig, setDatatrackConfig] = useState([]);
  const [isMobileGPS, setIsMobileGPS] = useState(true);
  const [isVehicleGPS, setIsVehicleGPS] = useState(false);
  const [isTemperature, setIsTemperature] = useState(false);

  useEffect(() => {
    const fncMapData = async () => {
      const polylineData = _.get(trackingResource, 'dataSpeedTrack.directions');

      const dataSpeedTrack = _.get(trackingResource, 'dataSpeedTrack');

      const trackingConfig = _.get(trackingResource, 'dataSpeedTrack.trackingConfig');

      if (polylineData) {
        const newData = [
          {
            id: 'mobileGPS',
            color: '#1D3557',
            data: dataSpeedTrack.directions.map((e) => {
              return {
                x: moment(e.date + ' ' + e.time, 'YYYY-MM-DD hh:mm A').toDate(),
                y: e.speed,
              };
            }),
          },
          {
            id: 'vehicleGPS',
            color: '#79c749',
            data: dataSpeedTrack.directionsGPS.map((e) => {
              return {
                x: moment(e.date + ' ' + e.time, 'YYYY-MM-DD hh:mm A').toDate(),
                y: e.speed,
              };
            }),
          },
        ];

        const newTemperatureData = [
          {
            id: 'temperature',
            color: '#1890ff',
            data: dataSpeedTrack.directionsGPS.map((e) => {
              return {
                x: moment(e.date + ' ' + e.time, 'YYYY-MM-DD hh:mm A').toDate(),
                y: e.temperature,
              };
            }),
          },
        ];

        setDataLine(newData);
        setTempData(newTemperatureData);

        setDataLineAll(newData.concat(newTemperatureData));
      }

      if (trackingConfig) {
        const newConfig =
          trackingConfig &&
          trackingConfig
            .filter((item) => item.mobileUse === true)
            .map((o) => {
              return {
                axis: 'y',
                value: o.speed,
                lineStyle: {
                  stroke: o.codeColor,
                  strokeWidth: 1,
                  strokeDasharray: 5,
                },
              };
            });
        setDatatrackConfig(newConfig);
      }
    };
    fncMapData();
  }, [trackingResource]);

  useEffect(() => {
    const dataSpeedTrack = _.get(trackingResource, 'dataSpeedTrack') ? _.get(trackingResource, 'dataSpeedTrack') : [];
    const directions = _.get(dataSpeedTrack, 'directions') ? _.get(dataSpeedTrack, 'directions') : [];

    if (isMobileGPS === true && isVehicleGPS === false && isTemperature === false) {
      const newData = [
        {
          id: 'mobileGPS',
          color: '#1D3557',
          data: directions.map((e) => {
            return {
              x: moment(e.date + ' ' + e.time, 'YYYY-MM-DD hh:mm A').toDate(),
              y: e.speed,
            };
          }),
        },
        {
          id: 'vehicleGPS',
          color: '#79c749',
          data: [],
        },
      ];

      const newTemperatureData = [
        {
          id: 'temperature',
          color: '#1890ff',
          data: [],
        },
      ];
      setTempData(newTemperatureData);
      setDataLine(newData);
    }
  }, [_.get(trackingResource, 'dataSpeedTrack')]);

  const handleCloseSpeedTrack = () => {
    setTrackingResource({
      resId: null,
      openSpeedTrack: false,
      dataSpeedTrack: null,
      dataTask: [],
      statusColor: null,
    });
  };

  const handleChangeMobile = (checked) => {
    const dataSpeedTrack = _.get(trackingResource, 'dataSpeedTrack');
    if (checked === false && isVehicleGPS === true) {
      const newData = [
        {
          id: 'mobileGPS',
          color: '#1D3557',
          data: [],
        },
        {
          id: 'vehicleGPS',
          color: '#79c749',
          data: dataSpeedTrack.directionsGPS.map((e) => {
            return {
              x: moment(e.date + ' ' + e.time, 'YYYY-MM-DD hh:mm A').toDate(),
              y: e.speed,
            };
          }),
        },
      ];
      setDataLine(newData);
      setIsMobileGPS(checked);
    } else if (checked === true && isVehicleGPS === false) {
      const newData = [
        {
          id: 'mobileGPS',
          color: '#1D3557',
          data: dataSpeedTrack.directions.map((e) => {
            return {
              x: moment(e.date + ' ' + e.time, 'YYYY-MM-DD hh:mm A').toDate(),
              y: e.speed,
            };
          }),
        },
        {
          id: 'vehicleGPS',
          color: '#79c749',
          data: [],
        },
      ];
      setDataLine(newData);
      setIsMobileGPS(checked);
    } else if (checked === true && isVehicleGPS === true) {
      const newData = [
        {
          id: 'mobileGPS',
          color: '#1D3557',
          data: dataSpeedTrack.directions.map((e) => {
            return {
              x: moment(e.date + ' ' + e.time, 'YYYY-MM-DD hh:mm A').toDate(),
              y: e.speed,
            };
          }),
        },
        {
          id: 'vehicleGPS',
          color: '#79c749',
          data: dataSpeedTrack.directionsGPS.map((e) => {
            return {
              x: moment(e.date + ' ' + e.time, 'YYYY-MM-DD hh:mm A').toDate(),
              y: e.speed,
            };
          }),
        },
      ];
      setDataLine(newData);
      setIsMobileGPS(checked);
    } else if (checked === false && isVehicleGPS === false) {
      if (checked === false && isVehicleGPS === false && isTemperature === false) {
        return;
      } else {
        const newData = [
          {
            id: 'mobileGPS',
            color: '#1D3557',
            data: [],
          },
          {
            id: 'vehicleGPS',
            color: '#79c749',
            data: [],
          },
        ];
        setDataLine(newData);
      }
      setIsMobileGPS(checked);
    }
  };

  const handleChangeVehicle = (checked) => {
    const dataSpeedTrack = _.get(trackingResource, 'dataSpeedTrack');

    if (checked === false && isMobileGPS === true) {
      const newData = [
        {
          id: 'mobileGPS',
          color: '#1D3557',
          data: dataSpeedTrack.directions.map((e) => {
            return {
              x: moment(e.date + ' ' + e.time, 'YYYY-MM-DD hh:mm A').toDate(),
              y: e.speed,
            };
          }),
        },
        {
          id: 'vehicleGPS',
          color: '#79c749',
          data: [],
        },
      ];
      setDataLine(newData);
      setIsVehicleGPS(checked);
    } else if (checked === true && isMobileGPS === false) {
      const newData = [
        {
          id: 'mobileGPS',
          color: '#1D3557',
          data: [],
        },
        {
          id: 'vehicleGPS',
          color: '#79c749',
          data: dataSpeedTrack.directionsGPS.map((e) => {
            return {
              x: moment(e.date + ' ' + e.time, 'YYYY-MM-DD hh:mm A').toDate(),
              y: e.speed,
            };
          }),
        },
      ];
      setDataLine(newData);
      setIsVehicleGPS(checked);
    } else if (checked === true && isMobileGPS === true) {
      const newData = [
        {
          id: 'mobileGPS',
          color: '#1D3557',
          data: dataSpeedTrack.directions.map((e) => {
            return {
              x: moment(e.date + ' ' + e.time, 'YYYY-MM-DD hh:mm A').toDate(),
              y: e.speed,
            };
          }),
        },
        {
          id: 'vehicleGPS',
          color: '#79c749',
          data: dataSpeedTrack.directionsGPS.map((e) => {
            return {
              x: moment(e.date + ' ' + e.time, 'YYYY-MM-DD hh:mm A').toDate(),
              y: e.speed,
            };
          }),
        },
      ];
      setDataLine(newData);
      setIsVehicleGPS(checked);
    } else if (checked === false && isMobileGPS === false) {
      if (checked === false && isMobileGPS === false && isTemperature === false) {
        return;
      } else {
        const newData = [
          {
            id: 'mobileGPS',
            color: '#1D3557',
            data: [],
          },
          {
            id: 'vehicleGPS',
            color: '#79c749',
            data: [],
          },
        ];
        setDataLine(newData);
      }
      setIsVehicleGPS(checked);
    }
  };

  const handleChangeTemperature = (checked) => {
    const dataSpeedTrack = _.get(trackingResource, 'dataSpeedTrack');
    if (checked === true) {
      const newTemperatureData = [
        {
          id: 'temperature',
          color: '#1890ff',
          data: dataSpeedTrack.directionsGPS.map((e) => {
            return {
              x: moment(e.date + ' ' + e.time, 'YYYY-MM-DD hh:mm A').toDate(),
              y: e.temperature,
            };
          }),
        },
      ];
      setTempData(newTemperatureData);
      setIsTemperature(checked);
    } else {
      if (checked === false && isMobileGPS === false && isVehicleGPS === false) {
        return;
      } else {
        const newTemperatureData = [
          {
            id: 'temperature',
            color: '#1890ff',
            data: [],
          },
        ];
        setTempData(newTemperatureData);
      }
      setIsTemperature(checked);
    }
  };

  return (
    <div className="graph-speed-tracking">
      <div className="graph-speed-tracking-container">
        <div className="graph-speed-tracking-detail">
          <div className="speed-tracking-detail">
            <span className="speed-tracking-text">{`${profile.name} ${profile.lastname}`}</span>
            <span className="speed-tracking-text-dot" />
            <span className="speed-tracking-text">{profile.phone}</span>
            {carRegistration &&
              carRegistration.map((item) => (
                <Tag className="speed-tracking-text-tag" key={item.itemId}>
                  {item.ItemNo}
                </Tag>
              ))}
          </div>
          <div className="speed-tracking-detail-button">
            <MyTag styleCss={'checkable-tag-orange'} checked={isMobileGPS} onChange={handleChangeMobile}>
              <FormattedMessage id={`monitorSpeedTrakingMobileGPS${LangCode}`} defaultMessage="Mobile GPS" />
            </MyTag>
            <MyTag styleCss={'checkable-tag-green'} checked={isVehicleGPS} onChange={handleChangeVehicle}>
              <FormattedMessage id={`monitorSpeedTrakingVehicleGPS${LangCode}`} defaultMessage="Vehicle GPS" />
            </MyTag>
            <MyTag styleCss={'checkable-tag-bule'} checked={isTemperature} onChange={handleChangeTemperature}>
              <FormattedMessage id={`monitorSpeedTrakingTemperature${LangCode}`} defaultMessage="Temperature" />
            </MyTag>

            <ButtonCircle
              style={{ marginLeft: '15px', marginRight: '10px' }}
              backColor={'#f0f2f5'}
              shape="circle"
              onClick={() => handleCloseSpeedTrack()}
            >
              <Icon type="close" />
            </ButtonCircle>
          </div>
        </div>
        <div className="graph-container">
          <div className="graph-text">
            <div className="graph-text-km-hr">km/hr</div>
            <div className="graph-text-C">ํC</div>
          </div>
          <div className="graph-speed-tracking-wrapper">
            <div className="graph-speed-tracking-graph">
              <ResponsiveLine
                {...commonProperties}
                // curve="linear"
                // isInteractive={false}
                // useMesh={false}
                enableGridX={false}
                enableGridY={false}
                enablePoints={false}
                lineWidth={2}
                layers={['grid', 'axes', 'lines', 'markers', 'legends']}
                data={dataLine}
                xScale={{
                  type: 'time',
                  format: 'native',
                  min: xMin,
                  max: xMax,
                }}
                yScale={{
                  type: 'linear',
                  min: 0,
                  max: 120,
                }}
                axisLeft={{
                  tickValues: [0, 60, 80, 120],
                  legendPosition: 'middle',
                  legendOffset: -40,
                  tickSize: 5,
                  tickPadding: 8,
                }}
                axisBottom={{
                  format: '%H:%M',
                  tickValues: 'every 1 hours',
                  legendPosition: 'middle',
                  tickSize: 5,
                  tickPadding: 12,
                }}
                colors={{ datum: 'color' }}
                // tooltip={({ point  }) => {
                //     return (
                //       <div className="speed-tooltip">
                //         Graph 1
                //       </div>
                //     )
                //   }}
                // sliceTooltip={speedTooltip}
                // sliceTooltip={({ slice }) => {
                //   return (
                //     <div className="speed-tooltip">
                //       Graph1
                //     </div>
                //   )
                // }}
                markers={datatrackConfig}
                // theme={getColoredAxis('#1D3557')}
                // theme={getColoredAxis('##F7F8F8')}
                // fill={'#1D3557'}
              />
            </div>

            <div className="graph-speed-tracking-secondGraph">
              <SecondGraph dataLine={dataLine} tempData={tempData} xMin={xMin} xMax={xMax} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const SecondGraph = ({ dataLine, tempData, xMin, xMax }) => {
  const sumData = dataLine.concat(tempData);

  return (
    <div className="graph-speed-tracking-secondGraph">
      <ResponsiveLine
        {...commonProperties}
        // curve="linear"
        data={tempData}
        colors={{ datum: 'color' }}
        // theme={getColoredAxis('#1D3557')}
        // theme={getColoredAxis('##F7F8F8')}
        // fill={'#1D3557'}
        axisRight={{
          tickValues: [-33, -18, 4, 15, 40],
          legendPosition: 'middle',
          legendOffset: 40,
          tickSize: 5,
          tickPadding: 8,
        }}
        yScale={{
          type: 'linear',
          min: -33,
          max: 40,
        }}
        xScale={{
          type: 'time',
          format: 'native',
          min: xMin,
          max: xMax,
        }}
        // axisBottom={{
        //   format: '%H:%M',
        //   tickValues: 'every 1 hours',
        //   legendPosition: 'middle',
        //   tickSize: 5,
        //   tickPadding: 12,
        // }}
        axisBottom={null}
        isInteractive={false}
        useMesh={false}
        axisLeft={null}
        axisTop={null}
        lineWidth={2}
        enablePoints={false}
        enableGridX={false}
        enableGridY={false}
        // tooltip={({ point  }) => {
        //   return (
        //     <div className="speed-tooltip">
        //       Graph 2 point
        //     </div>
        //   )
        // }}
        // sliceTooltip={({ slice }) => {
        //   return (
        //     <div className="speed-tooltip">
        //       Graph2
        //     </div>
        //   )
        // }}
      />
    </div>
  );
};

const speedTooltip = ({ slice }) => {
  let points = slice.points[0].data;

  return (
    <div className="speed-tooltip">
      <div className="speed-tooltip-content">
        <span className="speed-tooltip-text">speed:</span>
        <span className="speed-tooltip-text">&nbsp;{points.yFormatted}&nbsp;</span>
        <span className="speed-tooltip-text">km/hr</span>
      </div>
      <div>
        <span className="speed-tooltip-text">{moment(points.xFormatted).format('hh:mm A')}</span>
      </div>
    </div>
  );
};

const getColoredAxis = (color) => {
  return {
    axis: {
      ticks: {
        line: {
          stroke: color,
        },
        text: { fill: color },
      },
      legend: {
        text: {
          fill: color,
        },
      },
    },
  };
};
