import React, { useEffect, useState } from 'react';
import { List, Avatar, Input, Icon, Popover } from 'antd';
import _ from 'lodash';
import { useIntl, FormattedMessage } from 'react-intl';
import { MonitorContext } from '../../transportation-context';
import MemberInfomationModal from '../../../../components/member-information/index';
import moment from 'moment';
import '../css/summary.css';

export default React.memo(
  (props) => {
    const { onClick, members, date, onClickTrack, setMembersView, membersView, LangCode } = props;
    const intl = useIntl();
    const [visibleModal, setVisibleModal] = useState(false);
    const [dataMemberInfo, setDataMemberInfo] = useState(null);

    const handleClickMember = (memComId) => (_.isFunction(onClick) ? onClick(memComId) : null);

    const setMembersSearch = _.debounce((value) => {
      const valueLowerCase = _.lowerCase(value);
      const search = _.filter(members, (o) => {
        return (
          _.includes(_.lowerCase(o.fullname), valueLowerCase) ||
          _.includes(_.lowerCase(o.phone), valueLowerCase) ||
          _.includes(_.lowerCase(o.orgNameMapView), valueLowerCase)
        );
      });
      setMembersView(search);
    }, 100);

    const handleSearchMember = (e) => {
      setMembersSearch(e.target.value);
    };

    const handleOpenModal = (value) => {
      const dataAPI = {
        defailtEndDate: moment(date),
        defailtStartDate: moment(date),
        endDate: moment(date).format('YYYY-MM-DD'),
        memComId: String(value.memComId),
        orgId: String(value.OrgId),
        startDate: moment(date).format('YYYY-MM-DD'),
      };
      setDataMemberInfo(dataAPI);
      setVisibleModal(true);
    };

    const handleCloseModal = () => {
      setVisibleModal(false);
      setTimeout(() => {
        setDataMemberInfo(null);
      }, 1000);
    };

    const content = (value) => {
      return (
        <div className="popover-content">
          <div className="popover-content-info" onClick={() => handleOpenModal(value)}>
            <FormattedMessage id={`monitorMemberInfoTitle${LangCode}`} defaultMessage="Member Info" />
          </div>
          <div className="popover-content-info" onClick={() => onClickTrack(value.memComId)}>
            <FormattedMessage id={`monitorSpeedTrackingTitle${LangCode}`} defaultMessage="Speed Tracking" />
          </div>
        </div>
      );
    };

    const memberPopover = (value) => (
      <Popover placement="leftTop" content={content(value)} trigger="hover">
        <Icon type="more" />
      </Popover>
    );

    return (
      <div className="member-mapview-div">
        <div className="member-mapview-input-padding">
          <Input
            className="member-mapview-input"
            placeholder={intl.formatMessage({ id: 'hintSearch', defaultMessage: 'Search' })}
            prefix={<Icon style={{ color: 'rgba(0,0,0,.25)' }} type="search" />}
            allowClear
            onChange={handleSearchMember}
            autoComplete="off"
          />
        </div>
        <div className="member-mapview-list scroll-sm">
          {membersView &&
            membersView.map((item) => (
              <div key={item.memComId} className="member-item-div" onClick={() => handleClickMember(item)}>
                <div className="member-item-div-avatar">
                  <div className="member-item-div-image">
                    <Avatar src={item.pathImage} style={{ width: '34px', height: '34px' }} loading="lazy" />
                  </div>
                  <div className="member-item-div-detail">
                    <div className="member-item-div-name">{item.fullname}</div>
                    <div className="member-item-div-phone">{item.phone}</div>
                    <div className="member-item-div-phone" title={item.orgNameMapView}>
                      {_.truncate(item.orgNameMapView, 25)}
                    </div>
                  </div>
                </div>
                <div className="member-item-div-more">
                  <div className="member-item-div-colon">{memberPopover(item)}</div>
                </div>
              </div>
            ))}
        </div>

        <MemberInfomationModal
          dataAPI={dataMemberInfo}
          title={<FormattedMessage id={`memberInformationModalTitleMemberInformationWorkForce`} defaultMessage="Member Information" />}
          visible={visibleModal}
          onCancel={handleCloseModal}
          LangCode=""
        />
      </div>
    );
  },
  (prev, next) => {}
);
