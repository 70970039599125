import httpClient from '../../components/axiosClient';

const getByCategoryData = async (id) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.get(`/v5/item/manager/company/${comCode}/web/uombycategory/${id}`);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return error.responase;
  }
}

const getByCategoryDataByItemMaster = async (id) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.get(`/v5/item/manager/company/${comCode}/web/uomcategory/itemmaster/${id}`);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return error.responase;
  }
}




const createUoMCategory = async (body) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v5/item/manager/company/${comCode}/web/uomcategory`, body);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return error.responase;
  }
}

const editUoMCategory = async (id, body) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.put(`/v5/item/manager/company/${comCode}/web/uomcategory/${id}`, body);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return error.responase;
  }
}

const deleteUoMCategory = async (id) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.delete(`/v5/item/manager/company/${comCode}/web/uomcategory/${id}`);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return error.responase;
  }
}

const createUoM = async (body) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v5/item/manager/company/${comCode}/web/uom`, body);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return error.responase;
  }
}

const editUoM = async (id, body) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.put(`/v5/item/manager/company/${comCode}/web/uom/${id}`, body);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return error.responase;
  }
}

const deleteUoM = async (id) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.delete(`/v5/item/manager/company/${comCode}/web/uom/${id}`);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return error.response;
  }
}

const searchConvertUoM = async (body) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v5/item/manager/company/${comCode}/web/uomconvert/list`, body);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return error.responase;
  }
}

const createConvertUoM = async (body) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v5/item/manager/company/${comCode}/web/uomconvert/create`, body);
    if (response.status === 200) {
      return response;
    } else {
      return response;
    }
  } catch (error) {
    return error.response;
  }
}

const editConvertUoM = async (id, body) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.put(`/v5/item/manager/company/${comCode}/web/uomconvert/update/${id}`, body);
    if (response.status === 200) {
      return response;
    } else {
      return response;
    }
  } catch (error) {
    return error.response;
  }
}

const deleteConvertUoM = async (id) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.delete(`/v5/item/manager/company/${comCode}/web/uomconvert/delete/${id}`);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return error.responase;
  }
}

const getByConvertDataByItemMaster = async (body) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v5/item/manager/company/${comCode}/web/uom/convertto`,body);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return error.response;
  }
}



export {
  getByCategoryData, createUoMCategory, editUoMCategory, deleteUoMCategory, createUoM,
  editUoM, deleteUoM, searchConvertUoM, createConvertUoM, editConvertUoM, deleteConvertUoM, getByCategoryDataByItemMaster,getByConvertDataByItemMaster
};