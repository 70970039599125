import React, { useContext, useEffect, useState } from 'react';
import FilterSetting from '../components/filterSetting';
import { Card, Dropdown, Menu, Button, Modal } from 'antd';
import _ from 'lodash';
import Button_01 from '../../../components/v2/button_01';
import TableTransporationSetting from '../components/table';
import { FormattedMessage, useIntl } from 'react-intl';
import { numberFormatter } from '../../../component-function/fnc-inputnumber-currency';
import { DownOutlined } from '@ant-design/icons';
import ModalSettting from '../components/modal';
import { ModalConfirmSetting } from '../components/modelConfirm';
import { deleteTripAllowance, getListTripAllowance, settingTripAllowanceExport } from '../../../controllers/task/setting';
import { errorNotification, successNotification } from '../../../components/v2/notification';
import moment from 'moment';
import { PageSettings } from '../../../config/page-settings';

const PageTripAllowance = () => {
  const app = useContext(PageSettings);
  const { checkPermissionAction } = app;
  const intl = useIntl();
  const memComId = localStorage.getItem('memComId');
  const name = localStorage.getItem('name');
  const [search, setSearch] = useState();
  const [isFetch, setIsFetch] = useState(false);

  const [visible, setVisible] = useState(false);

  const [listData, setListData] = useState([]);
  const [listTotal, setListTotal] = useState(0);
  const [editData, setEditData] = useState();
  const [scrollX, setScrollX] = useState(2500);
  const tampData = [
    {
      index: 1,
      soNo: '',
    },
    {
      index: 2,
      soNo: '',
    },
    {
      index: 3,
      soNo: '',
    },
  ];
  const [tableChange, setTableChange] = useState({
    page: 1,
    size: 10,
    total: 0,
    loading: false,
    sortField: '',
    sortOrder: '',
  });

  useEffect(() => {
    const getAPI = async () => {
      console.log('search', search, tableChange);
      const payload = {
        where: {
          'customerDetail.id': _.get(search, 'customer.customerId')
            ? _.get(search, 'customer.customerId') || undefined
            : _.get(search, 'customerSearch') === 'notSpecified'
              ? null
              : _.get(search, 'customerSearch'),
          'projectDetail.id': _.get(search, 'project.projectId')
            ? _.get(search, 'project.projectId') || undefined
            : _.get(search, 'projectSearch') === 'notSpecified'
              ? null
              : _.get(search, 'projectSearch'),
          'taskTypeDetail.id': _.get(search, 'taskType', undefined) === 'notSpecified' ? null : _.get(search, 'taskType'),
          'sourceLocationDetail.name': _.get(search, 'source') ? { $regex: _.get(search, 'source') } : undefined,
          'destinationLocationDetail.name': _.get(search, 'destination') ? { $regex: _.get(search, 'destination') } : undefined,
        },
        page: _.get(tableChange, 'page'),
        pageSize: _.get(tableChange, 'size'),
        orderBy: _.get(tableChange, 'sortField') ? _.get(tableChange, 'sortField') : undefined,
        order: _.get(tableChange, 'sortOrder') !== '' ? _.get(tableChange, 'sortOrder') : undefined,
      };

      const response = await getListTripAllowance(payload);

      setListData(_.get(response, 'data.data.docs'));
      setListTotal(_.get(response, 'data.data.total'));
    };
    getAPI();
  }, [isFetch, tableChange]);

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
      fixed: 'left',
      default: true,
    },
    {
      title: intl.formatMessage({ id: 'TripAllowanceSettingColumnCustomer', defaultMessage: 'Customer' }),
      dataIndex: 'customerDetail.name',
      key: 'customerDetail.name',
      sorter: true,
      default: true,
      fixed: 'left',
      width: 250,
      render: (text, record, index) => _.get(record, 'customerDetail.name', 'ไม่กำหนด') || 'ไม่กำหนด',
    },
    {
      title: intl.formatMessage({ id: 'TripAllowanceSettingColumnProject', defaultMessage: 'Project' }),
      dataIndex: 'projectDetail.name',
      key: 'projectDetail.name',
      sorter: true,
      default: false,
      width: 150,
      render: (text, record, index) => (
        <>
          {_.get(record, 'projectDetail.no') !== 'ไม่กำหนด' ? _.get(record, 'projectDetail.no', '') + ' ' + _.get(record, 'projectDetail.name', '') : _.get(record, 'projectDetail.no')}
        </>
      ),
    },
    {
      title: intl.formatMessage({ id: 'TripAllowanceSettingColumnTaskType', defaultMessage: 'Task Type' }),
      dataIndex: 'taskTypeDetail.name',
      key: 'taskTypeDetail.name',
      sorter: true,
      default: true,
      width: 150,
      render: (text, record, index) => _.get(record, 'taskTypeDetail.name', 'ไม่กำหนด') || 'ไม่กำหนด',
    },
    {
      title: intl.formatMessage({ id: 'AllowanceSettingColumnSource', defaultMessage: 'Source' }),
      dataIndex: 'sourceLocationDetail.name',
      key: 'sourceLocationDetail.name',
      default: true,
      width: 500,
      ellipsis: true,
      render: (text, record, index) => (
        <>
          {_.get(record, 'sourceLocationDetail.id') ? _.get(record, 'sourceLocationDetail.name') + ' ' + _.get(record, 'sourceLocationDetail.fullName', '') : _.get(record, 'sourceLocationDetail.name')}
        </>
      ),
    },
    {
      title: intl.formatMessage({ id: 'AllowanceSettingColumnDestination', defaultMessage: 'Destination' }),
      dataIndex: 'destinationLocationDetail.name',
      key: 'destinationLocationDetail.name',
      default: true,
      width: 500,
      ellipsis: true,
      render: (text, record, index) => (
        <>
          {_.get(record, 'destinationLocationDetail.id') ? _.get(record, 'destinationLocationDetail.name') + ' ' + _.get(record, 'destinationLocationDetail.fullName', '') : _.get(record, 'destinationLocationDetail.name')}
        </>
      ),
    },

    {
      title: intl.formatMessage({ id: 'TripAllowanceSettingColumnTripAllowance', defaultMessage: 'Trip Allowance' }),
      dataIndex: 'detail.price',
      key: 'detail.price',
      sorter: true,
      default: false,
      width: 200,
      render: (text, record, index) => numberFormatter(_.get(record, 'detail.price') || 0) + ' THB',
    },

    {
      title: intl.formatMessage({ id: 'TripAllowanceSettingColumnDrive1', defaultMessage: 'Drive 1' }),
      dataIndex: 'detail.price1',
      key: 'detail.price1',
      sorter: true,
      default: false,
      width: 150,
      render: (text, record, index) => numberFormatter(_.get(record, 'detail.price1') || 0) + ' THB',
    },

    {
      title: intl.formatMessage({ id: 'TripAllowanceSettingColumnDrive2', defaultMessage: 'Drive 2' }),
      dataIndex: 'detail.price2',
      key: 'detail.price2',
      sorter: true,
      default: false,
      width: 150,
      render: (text, record, index) => numberFormatter(_.get(record, 'detail.price2') || 0) + ' THB',
    },

    {
      title: intl.formatMessage({ id: 'TripAllowanceSettingColumnUpdateby', defaultMessage: 'Update By' }),
      dataIndex: 'updatedName',
      key: 'updatedName',
      sorter: true,
      default: false,
      width: 150,
      render: (text, record, index) => _.get(record, 'updatedName') || _.get(record, 'createdName'),
    },
    {
      title: intl.formatMessage({ id: 'TripAllowanceSettingColumnUpdateDate', defaultMessage: 'Update Date' }),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      sorter: true,
      default: true,
      width: 150,
      render: (text, record, index) => moment.unix(_.get(record, 'updatedAt')).format('DD/MM/YYYY'),
    },
  ];

  const handleApply = () => {
    setIsFetch((event) => !event);
    setTableChange((prev) => ({ ...prev, page: 1 }));
  };

  const handleReset = () => {
    setIsFetch((event) => !event);
    setSearch((prev) => ({ customer: undefined, project: undefined, taskType: undefined, source: '', destination: '' }));
    setTableChange((prev) => ({ ...prev, page: 1 }));
  };

  const onFilterOrder = async (value, code) => {
    setSearch({ ...search, [code]: value });
  };

  const menuColumn = (
    <Menu>
      {checkPermissionAction('P78PG1C1', 'P78PG1C1A2') ? (
        <Menu.Item>
          <Button
            style={{ padding: 'unset' }}
            ghost
            type="link"
            onClick={() => {
              setVisible(true);
            }}
          >
            <FormattedMessage id="btnCreateTripAllowance" defaultMessage="Create Trip Allowance" />
          </Button>
        </Menu.Item>
      ) : null}
      <Menu.Item>
        <Button style={{ padding: 'unset' }} ghost type="link" onClick={() => handleExport()}>
          <FormattedMessage id="expenseBtnExport" defaultMessage="Export Excel" />
        </Button>
      </Menu.Item>
    </Menu>
  );

  const contentAction = (record) => {
    return (
      <div style={{ display: 'grid' }}>
        {checkPermissionAction('P78PG1C1', 'P78PG1C1A3') ? (
          <div>
            <Button
              style={{ width: 100 }}
              type="link"
              ghost
              onClick={() => {
                console.log('record', record);
                setVisible(true);
                setEditData(record);
              }}
            >
              <FormattedMessage id="btnEdit" defaultMessage="Edit" />
            </Button>
          </div>
        ) : null}
        {checkPermissionAction('P78PG1C1', 'P78PG1C1A4') ? (
          <div>
            <Button style={{ width: 100 }} type="link" ghost onClick={() => handleDeleteTripAllowance(record._id)}>
              <FormattedMessage id="btnDelete" defaultMessage="Delete" />
            </Button>
          </div>
        ) : null}
      </div>
    );
  };

  const handleDeleteTripAllowance = (id) => {
    Modal.confirm({
      className: 'maintenance-report-modal-confirm',
      centered: true,
      icon: null,
      title: intl.formatMessage({ id: 'lblDelete', defaultMessage: 'Are you sure you want to delete ?' }),
      okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        const body = {
          updatedBy: parseFloat(memComId),
          updatedName: name,
        };
        const response = await deleteTripAllowance(body, id);
        if (_.get(response, 'data.status.code') === 200) {
          successNotification(_.get(response, 'data.status.message'));
          setIsFetch((current) => !current);
        } else {
          errorNotification(_.get(response, 'data.status.message'));
        }
      },
      onCancel() { },
    });
  };

  const handleExport = async () => {
    try {
      let distance = [];

      if (_.get(search, 'distance1')) {
        distance.push({
          'detail.distance': {
            $gte: parseFloat(_.get(search, 'distance1')),
          },
        });
      }

      if (_.get(search, 'distance2')) {
        distance.push({
          'detail.distance': {
            $lte: parseFloat(_.get(search, 'distance2')),
          },
        });
      }
      const body = {
        where: {
          'customerDetail.id': _.get(search, 'customer.customerId')
            ? _.get(search, 'customer.customerId') || undefined
            : _.get(search, 'customerSearch') === 'notSpecified'
              ? null
              : _.get(search, 'customerSearch'),
          'projectDetail.id': _.get(search, 'project.projectId')
            ? _.get(search, 'project.projectId') || undefined
            : _.get(search, 'projectSearch') === 'notSpecified'
              ? null
              : _.get(search, 'projectSearch'),
          'taskTypeDetail.id': _.get(search, 'taskType', undefined) === 'notSpecified' ? null : _.get(search, 'taskType'),
          'sourceLocationDetail.name': _.get(search, 'source')
            ? {
              $regex: _.get(search, 'source'),
            }
            : undefined,
          'destinationLocationDetail.name': _.get(search, 'destination')
            ? {
              $regex: _.get(search, 'destination'),
            }
            : undefined,
          $and: _.size(distance) ? distance : undefined,
        },
        page: 1,
        pageSize: listTotal,
        orderBy: _.get(tableChange, 'sortField') ? _.get(tableChange, 'sortField') : undefined,
        order: _.get(tableChange, 'sortOrder') !== '' ? _.get(tableChange, 'sortOrder') : undefined,

        viewFormat: [
          {
            colName: intl.formatMessage({ id: 'AllowanceSettingColumnCustomer', defaultMessage: 'Customer' }),
            sequence: 1,
            colCode: 'customerDetail.name',
          },
          {
            colName: intl.formatMessage({ id: 'AllowanceSettingColumnProject', defaultMessage: 'Project' }),
            sequence: 2,
            colCode: 'projectDetail.name',
          },
          {
            colName: intl.formatMessage({ id: 'AllowanceSettingColumnTaskType', defaultMessage: 'Task Type' }),
            sequence: 3,
            colCode: 'taskTypeDetail.name',
          },
          {
            colName: intl.formatMessage({ id: 'AllowanceSettingColumnSource', defaultMessage: 'Source' }),
            sequence: 4,
            colCode: 'sourceLocationDetail.name',
          },
          {
            colName: intl.formatMessage({ id: 'AllowanceSettingColumnDestination', defaultMessage: 'Destination' }),
            sequence: 5,
            colCode: 'destinationLocationDetail.name',
          },
          {
            colName: intl.formatMessage({ id: 'TripAllowanceSettingColumnTripAllowance', defaultMessage: 'Trip Allowance' }),
            sequence: 6,
            colCode: 'detail.price',
          },
          {
            colName: intl.formatMessage({ id: 'AllowanceSettingColumnDrive1', defaultMessage: 'Drive 1' }),
            sequence: 7,
            colCode: 'detail.price1',
          },
          {
            colName: intl.formatMessage({ id: 'AllowanceSettingColumnDrive2', defaultMessage: 'Drive 2' }),
            sequence: 8,
            colCode: 'detail.price2',
          },
          {
            colName: intl.formatMessage({ id: 'AllowanceSettingColumnUpdateby', defaultMessage: 'Update by' }),
            sequence: 9,
            colCode: 'updatedName',
          },
          {
            colName: intl.formatMessage({ id: 'AllowanceSettingColumnUpdateDate', defaultMessage: 'Update Date' }),
            sequence: 10,
            colCode: 'updatedAt',
          },
        ],
      };
      const response = await settingTripAllowanceExport(body);
      console.log('EXP', response.data.data)
      if (response.status === 200) {


        window.open(response.data.data.url);
        successNotification(response.data.status.message);
      } else {
        errorNotification(response.data.status.message);
      }
    } catch (error) { }
  };

  return (
    <div>
      {checkPermissionAction('P78PG1C1', 'P78PG1C1A1') ? (
        <Card
          className="reportItemSalesCard"
          title={intl.formatMessage({ id: 'menuTripAllowanceSetting', defaultMessage: 'Trip Allowance' })}
          bodyStyle={{ padding: 'unset', height: 'calc(100vh - 170px)', overflowY: 'auto' }}
          extra={
            // checkPermissionAction('P78PG1C1', 'P78PG1C1A2') ? (
            <Dropdown overlay={menuColumn} trigger={['click']} placement="bottomRight">
              <Button_01 key="submit" type="primary" btnsize="wd_df">
                <FormattedMessage id="btnActionGroup" defaultMessage="Action" />
                <DownOutlined style={{ fontSize: '14px', paddingLeft: '22%' }} />
              </Button_01>
            </Dropdown>
            // ) : null
          }
        >
          <FilterSetting
            type={'tripAllowance'}
            search={search}
            setSearch={setSearch}
            handleApply={handleApply}
            handleReset={handleReset}
            onFilterOrder={onFilterOrder}
          />

          <TableTransporationSetting
            columns={columns}
            listData={listData}
            tableChange={tableChange}
            setTableChange={setTableChange}
            scrollX={scrollX}
            contentAction={contentAction}
            onRow={true}
            listTotal={listTotal}
            popupPermisson={
              checkPermissionAction('P78PG1C1', 'P78PG1C1A3') || checkPermissionAction('P78PG1C1', 'P78PG1C1A4') ? true : false
            }
          />

          {visible && (
            <ModalSettting
              visible={visible}
              setVisible={setVisible}
              type="tripAllowance"
              data={editData}
              setData={setEditData}
              setTrigger={setIsFetch}
            />
          )}
        </Card>
      ) : null}
    </div>
  );
};

export default PageTripAllowance;
