import React, { useState, useEffect } from 'react';
import { Modal, Button, Spin } from 'antd';
import Button01 from '../v2/button_01';
import { useIntl, FormattedMessage } from 'react-intl';
import MemberInformationDetial from './member-information-detial.jsx';
import MemberInformationReport from './member-information-report.jsx';
import getMemberInformation from '../../controllers/get-member-information/get-member-information';
import styles from './css/index.css';

export default function MemberInformation(props) {
  const { dataAPI, title, visible, onCancel,LangCode } = props;
  const intl = useIntl();

  const [dataMemberInfo, setDataMemberInfo] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getApi = async () => {
      setLoading(true);
      if (dataAPI !== null) {
        const data = await getMemberInformation(dataAPI);
        setDataMemberInfo(data.member);
      }
      setLoading(false);
    }
    getApi();
  }, [dataAPI]);

  const handleOnCancel = () => {
    onCancel();
    setTimeout(() => {
      setDataMemberInfo({});
    }, 500);
  }

  return (
    <div className="member-information">
      <Modal 
        className="member-information-modal"
        title={title}
        visible={visible}
        onCancel={handleOnCancel}
        centered
        width={654}
        footer={[
          <Button01 htmlType="submit" key="submit" type="primary" btnsize='wd_df' onClick={onCancel}>
            <FormattedMessage id="btnClose" defaultMessage="Close" />
          </Button01>
        ]}
      >
        <Spin spinning={loading}>
          <div className="member-information-container">
            <MemberInformationDetial dataMemberInfo={dataMemberInfo} dataAPI={dataAPI} LangCode={LangCode}/>
            <MemberInformationReport dataMemberInfo={dataMemberInfo} LangCode={LangCode}/>
          </div>
        </Spin>
      </Modal>
    </div>
  )
}
