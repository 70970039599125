import httpCiient from '../../components/axiosClient';

const comCode = localStorage.getItem('comCode');

const getListAllowance = async () => {
  try {
    const response = await httpCiient.get(`/v5/resource/manager/company/${comCode}/web/menufilterrequest/myrequest`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getListAllowancePage = async (payload) => {
  try {
    const response = await httpCiient.post(`/v1/task-setting/manager/${comCode}/allowance/page`, payload);
    return response.data;
  } catch (error) {
    return error.response;
  }
};

const getAllowanceDetail = async (id) => {
  try {
    const response = await httpCiient.get(`/v1/task-setting/manager/${comCode}/allowance/${id}`);
    return response;
  } catch (error) {
    return error.response;
  }
};

const updateAllowanceDetail = async (allowanceId, payload) => {
  try {
    const response = await httpCiient.put(`/v1/task-setting/manager/${comCode}/allowance/${allowanceId}`, payload);
    return response;
  } catch (error) {
    return error.response;
  }
};

const exportAllowanceResource = async (payload) => {
  try {
    const response = await httpCiient.post(`/v1/task-setting/manager/${comCode}/allowance/page/export`, payload);
    return response;
  } catch (error) {
    return error.response;
  }
};

export {
  getListAllowancePage,
  getAllowanceDetail,
  updateAllowanceDetail,
  exportAllowanceResource
};
