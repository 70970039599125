import { Modal, Col, Row, Form, Select, Input, InputNumber, DatePicker } from 'antd';
import React, { useContext, useEffect, useMemo, useState } from 'react';

import { FormattedMessage, useIntl } from 'react-intl';
import _ from 'lodash';
import { FineContext } from './finecontext';
import Button_02 from '../../components/v2/button_02';
import Button_01 from '../../components/v2/button_01';
import { createFine, updateFine } from '../../controllers/fine/fineapi';
import { errorNotification, successNotification } from '../../components/v2/notification';
import moment from 'moment';
import { fncGetUsers } from '../../controllers/user/users';
import { handleKeyDownDecimal2Fixed } from '../../component-function/fnc-number';
const { Option } = Select;
const { TextArea } = Input;

const FineModal = (props) => {
  const { form, distance, } = props;
  const { state, setState, fnc } = useContext(FineContext);
  const { getFieldDecorator, validateFields, resetFields, getFieldValue, setFieldsValue } = form;
  const intl = useIntl();
  const { visibleModal, record, reason, dataFine } = state;
  const { setVisibleModal, setEvent, setDistance } = setState;
  const { getRecord } = fnc;
  const paddinginputLeft = '16px 10px 0px 10px';
  const paddinginputRight = '16px 10px 0px 10px';
  const dateFormat = 'ddd, DD MMM YYYY';
  const dataSource = _.get(dataFine, 'rows');
  const [employee, setEmployee] = useState([]);


  useEffect(() => {
    getEmployee();
  }, []);

  // useEffect(() => {
  //   if (getFieldValue('source') && getFieldValue('destination')) {
  //     searchDistance(getFieldValue('source'), getFieldValue('destination'));
  //   }
  // }, [getFieldValue('source'), getFieldValue('destination')]);

  // const searchDistance = async (source, destination) => {
  //   try {
  //     let payload = {
  //       sourceId: source,
  //       destinationId: destination,
  //     };

  //     const response = await getDistance(payload);
  //     if (response.status.code === 1) {
  //       const responeDistance = _.get(response, 'data.distance');
  //       await setFieldsValue({ ['distance']: responeDistance });
  //       handleChangeDistance();
  //       // setDistance(_.get(response, 'data.distance'));
  //     }
  //   } catch (error) {}
  // };

  const getEmployee = async () => {
    const mockData = [];
    const users = await fncGetUsers(1827, null, true);
    for (let i = 0; i < users.length; i++) {
      const data = {
        key: users[i].mem_com_id,
        fullname: users[i].fullname,
        email: users[i].email,
        com_id: users[i].com_id,
        mem_id: users[i].mem_id,
      };
      mockData.push(data);
    }
    setEmployee(mockData);
  };

  const resetFiledsAll = () => {
    setVisibleModal(false);
    resetFields();
  };

  const handleSave = () => {
    validateFields(async (err, values) => {
      if (err) return;

      console.log('valuesAllowance', values);
      // try {
      const payload = {
        memComId: values.employee,
        fineAmount: values.amount,
        fineDate: moment(values.finddate).format('YYYY-MM-DD'),
        fineReasonId: values.reason,
        fineDescription: values.description,
        createdBy: values.employee,
      };
      console.log('payloadFine', payload);

      const response = record ? await updateFine(payload, _.get(record, 'fineId')) : await createFine(payload);
      if (_.get(response.status, 'code') === 200) {
        successNotification(_.get(response.status, 'message'));
        getRecord(_.get(record, 'fineId'));
        setEvent((current) => !current);
        resetFiledsAll();
      }
      // } catch (error) {
      //   console.log('errorFine', error.response);
      //   errorNotification(_.get(error.response, 'status.message'));
      // }
    });
  };

  // const handleUpdate = () => {
  //   console.log('updateFine');
  //   validateFields(async (err, values) => {
  //     if (err) return;

  //     if (values) {
  //       console.log('valuesAllowance', values);
  //       try {
  //         const payload = {
  //           memComId: values.employee,
  //           fineAmount: values.amount,
  //           fineDate: moment(values.finddate).format('YYYY-MM-DD'),
  //           fineReasonId: values.reason,
  //           fineDescription: values.description,
  //           createdBy: values.employee,
  //         };
  //         console.log('payloadFine', payload);
  //         const response = await updateFine(payload,_.get(record,'fineId'));
  //         console.log('response', response);
  //         if (_.get(response.status, 'code') === 200) {
  //           successNotification(_.get(response.status, 'message'));
  //           setEvent((current) => !current);
  //           resetFiledsAll();
  //         }
  //       } catch (error) {
  //         console.log('errorFine',error.response);
  //         errorNotification(_.get(error.response, 'status.message'));
  //       }
  //     }

  //   });
  // };

  const LabeRequire = (props) => {
    const { text, req } = props;
    return (
      <span>
        {text}&nbsp;
        {req ? <span style={{ fontSize: '13px', color: '#ff0000' }}>*</span> : ''}
      </span>
    );
  };

  return (
    <Modal
      visible={visibleModal}
      onCancel={() => resetFiledsAll()}
      centered={true}
      title={
        record ? (
          <FormattedMessage id="EditFine" defaultMessage="Edit Fine" />
        ) : (
          <FormattedMessage id="CreateFine" defaultMessage="Create Fine" />
        )
      }
      zIndex={999}
      width={760}
      style={{ marginTop: '15px' }}
      bodyStyle={{ padding: '5px 24px' }}
      footer={[
        <Button_02 key="back" btnsize="wd_df" onClick={() => resetFiledsAll()} style={{ margin: 'unset' }}>
          <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
        </Button_02>,
        <Button_01 key="submit" type="primary" btnsize="wd_df" onClick={handleSave}>
          <FormattedMessage id="btnSave" defaultMessage="Save" />
        </Button_01>,
      ]}
    >
      <Form form={form} >
        <Row gutter={24}>
          <Col span={12} style={{ padding: paddinginputLeft }}>
            <Form.Item>
              <span className="formText-formate">
                <FormattedMessage id="FineEmployee" defaultMessage="Employee" />
                <LabeRequire req={true} />
              </span>
              {getFieldDecorator('employee', {
                initialValue: record ? _.get(_.filter(employee, { fullname: _.get(record, 'employeeName') })[0], 'key') : undefined,
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({
                      id: 'FineFormEmployee',
                      defaultMessage: 'Please Select Employee',
                    }),
                  },
                ],
              })(
                <Select
                  style={{ width: '100%' }}
                  placeholder={intl.formatMessage({ id: 'FineSelectEmployee', defaultMessage: 'Select Employee' })}
                  // onSelect={handleDistance}
                >
                  {employee &&
                    _.map(employee, (item, index) => {
                      return (
                        <Option key={_.get(item, 'key')} value={_.get(item, 'key')}>
                          {_.get(item, 'fullname')}
                        </Option>
                      );
                    })}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={12} style={{ padding: paddinginputRight }}>
            <Form.Item>
              <span className="formText-formate">
                <FormattedMessage id="FineAmountTHB" defaultMessage="Amount (THB)" />
                <LabeRequire req={true} />
              </span>
              {getFieldDecorator('amount', {
                initialValue: _.get(record, 'amount'),
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({
                      id: 'AllowanceFormDriver1',
                      defaultMessage: 'Please Enter Amount (THB)',
                    }),
                  },
                ],
              })(
                <Input
                  style={{ width: '100%' }}
                  placeholder={intl.formatMessage({ id: 'FineEnterAmountTHB', defaultMessage: 'Enter Amount (THB)' })}
                  onKeyDown={handleKeyDownDecimal2Fixed}
                  // min={0}
                  // step={0.01}
                  // onChange={handleChangeDrive1}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        {/*--------------------------------------------------------------------------------------------*/}
        <Row gutter={24}>
          <Col span={12} style={{ padding: paddinginputLeft }}>
            <Form.Item>
              <span className="formText-formate">
                <FormattedMessage id="FineDate" defaultMessage="Fine Date" />
                <LabeRequire req={true} />
              </span>
              {getFieldDecorator('finedate', {
                initialValue: record ? moment(_.get(record, 'fineDate'), 'YYYY-MMM-DD hh:mm:ss') : undefined,
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({
                      id: 'FineFormDate',
                      defaultMessage: 'Please Select Fine Date',
                    }),
                  },
                ],
              })(
                <DatePicker
                  format={dateFormat}
                  style={{ width: '100%' }}
                  placeholder={intl.formatMessage({ id: 'FineSelectDate', defaultMessage: 'Select Fine Date' })}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={12} style={{ padding: paddinginputRight }}>
            <Form.Item>
              <span className="formText-formate">
                <FormattedMessage id="FineFormReason" defaultMessage="Reason" />
                <LabeRequire req={true} />
              </span>
              {getFieldDecorator('reason', {
                initialValue: _.get(record, 'fineReasonId'),
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({
                      id: 'FineFormReason',
                      defaultMessage: 'Please Select Reason',
                    }),
                  },
                ],
              })(
                <Select
                  style={{ width: '100%' }}
                  placeholder={intl.formatMessage({ id: 'FineSelectReason', defaultMessage: 'Select Reason' })}
                >
                  {reason &&
                    _.map(reason, (item, index) => {
                      return (
                        <Option key={_.get(item, 'fineReasonId')} value={_.get(item, 'fineReasonId')}>
                          {_.get(item, 'fineReasonName')}
                        </Option>
                      );
                    })}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
        {/*--------------------------------------------------------------------------------------------*/}
        <Row gutter={24}>
          <Col span={24} style={{ padding: paddinginputLeft }}>
            <Form.Item>
              <span className="formText-formate">
                <FormattedMessage id="FineDescription" defaultMessage="Description" />
              </span>
              {getFieldDecorator('description', {
                initialValue: _.get(record, 'description'),
              })(
                <TextArea
                  style={{ width: '100%' }}
                  placeholder={intl.formatMessage({ id: 'FineEnterDescription', defaultMessage: 'Enter Description' })}
                  // onChange={handleChangeDrive2}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

const FineModalForm = Form.create({
  name: 'Allowance_create_form',
  mapPropsToFields() {},
})(FineModal);

export default FineModalForm;
