import React, { useState } from 'react';
import Card from '../../../components/v2/card';
import { useIntl, FormattedMessage } from 'react-intl';
import Button01 from '../../../components/v2/button_01';
import { Collapse, Upload, Modal, Icon, notification } from 'antd';
import style from '../css/index.css';
import Resizer from 'react-image-file-resizer';
import ImageGallery from 'react-image-gallery';
import ImageViewer from 'react-simple-image-viewer';
import styled from 'styled-components';
import _ from 'lodash';
// import httpClients from '../../../components/axiosClient'
import { uploadAssetDoc, deleteAssetDoc } from '../../../controllers/asset-resource/asset-controller';
import ImgPDF from '../../../components/image/pdf_file.png';
import ReactBnbGallery from 'react-bnb-gallery';

const { Panel } = Collapse;
const { confirm } = Modal;

const AssetAttachment = (props) => {
  const { dataById, setTriggerApi } = props;
  const intl = useIntl();
  const [checkUploadAtt, setCheckUploadAtt] = useState(false);
  const [documentCompany, setDocumentCompany] = useState([]);
  const [modalView, setModalView] = useState();
  const [startIndexGallery, setStartIndexGallery] = useState(0);
  const [stateModal, setStateModal] = useState(false);
  const [expankey, setExpankey] = useState([]);
  const comCode = localStorage.getItem('comCode');

  const handleClickbutton = (value) => {
    setCheckUploadAtt(value);
    if (value) {
      const mapData = _.get(dataById, 'dataDocumentType').map((item, index) => { return item.assetDocTypeId })
      setExpankey(mapData)
    } else {
      setExpankey([])
    }
  };

  const openNotificationSuccess = (message) => {
    notification.success({
      message: message,
    });
  };

  const openNotificationError = (message) => {
    notification.error({
      message: message,
    });
  };

  const openNotificationWarning = (message) => {
    notification.warning({
      message: message,
    });
  };

  const openNotificationWarningNoFile = (type) => {
    notification[type]({
      message: intl.formatMessage({
        id: 'userMgntUsersDetailTxtMustFileDocument',
        defaultMessage: 'Must be file .png, .jpg and .pdf only',
      }),
    });
  };

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        720,
        720,
        'JPG',
        70,
        0,
        (uri) => {
          resolve(uri);
        },
        'base64'
      );
    });

  const action = async (file, docComId, index, assetDocTypeId) => {
    console.log('resizeFile', file)
    if (file.type === 'image/jpeg' || file.type === 'image/png') {
      const image = await resizeFile(file);
      const fileChange = dataURLtoFile(image, file.name.toLowerCase());
      let formData = new FormData();
      formData.append('file', fileChange);
      formData.append('fileName', file.name.toLowerCase());

      const uploadresponse = await uploadAssetDoc(_.get(dataById, 'data[0].assetId'), assetDocTypeId, formData);
      if (uploadresponse.status === 200) {
        openNotificationSuccess(uploadresponse.data.status.message);
        setTriggerApi((current) => !current);
      } else {
        openNotificationError(uploadresponse.data.status.message);
      }
    } else if (file.type === 'application/pdf') {
      let formDataPDF = new FormData();
      formDataPDF.append('file', file);
      formDataPDF.append('fileName', file.name.toLowerCase());
      const uploadresponse = await uploadAssetDoc(_.get(dataById, 'data[0].assetId'), assetDocTypeId, formDataPDF);
      if (uploadresponse.status === 200) {
        openNotificationSuccess(uploadresponse.data.status.message);
        setTriggerApi((current) => !current);
      } else {
        openNotificationError(uploadresponse.data.status.message);
      }
    } else {
      // openNotificationWarningNoFile('warning');
    }
  };

  const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    for (var i = 0; i < bstr.length; i++) {
      u8arr[i] = bstr.charCodeAt(i);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const handlePreview = async (item, title, file) => {
    console.log('setModalView ::', file);
    // let isPDF = /pdf/g.test(file.type)
    let isPDF = file.fileType === 'application/pdf' ? true : false;
    if (isPDF) return window.open(file.pdfPath, '_blank');
    item = item.reduce((prev, current) => {
      if (!/pdf/g.test(current.type)) {
        prev.push(current);
      }
      return prev;
    }, []);
    item = item.filter((current) => !/pdf/g.test(current.type));

    const found = item.findIndex((element) => element.uid === file.uid);

    // setTitle(title);
    const ignorePDF = _.filter(item, (e) => { return e.fileType !== "application/pdf" })
    const mapdata =
      ignorePDF &&
      _.map(ignorePDF, (e, index) => {
        return {
          photo: _.get(e, 'url'),
          number: index,
          caption: _.get(e, 'remark'),
          // subcaption: `Noted By ${_.get(itemData, 'member.fullname')}`,
          thumbnail: _.get(e, 'url'),
        };
      });
    setModalView(mapdata);
    setStartIndexGallery(found);
    setStateModal(!stateModal);
  };

  const handleRemove = (file, index) => {
    let documentId = file.uid;
    confirm({
      className: 'document-modal-confirm',
      title: intl.formatMessage({ id: 'lblDelete', defaultMessage: 'Are you sure you want to delete ?' }),
      okText: intl.formatMessage({ id: 'modalConfirmBtnYes', defaultMessage: 'Yes' }),
      cancelText: intl.formatMessage({ id: 'modalConfirmBtnNo', defaultMessage: 'No' }),
      okType: 'danger',
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'dashed',
      },
      onOk: async () => {
        const resDeleteDoc = await deleteAssetDoc(documentId);
        if (resDeleteDoc.status === 200) {
          openNotificationSuccess(resDeleteDoc.data.status.message);
          setTriggerApi((current) => !current);
        } else {
          openNotificationError(resDeleteDoc.data.status.message);
        }
      },
      onCancel() { },
    });
  };

  const uploadButton = (
    <div>
      <Icon type="plus" />
      <div className="ant-upload-text">
        <FormattedMessage id="userMgntUsersDetailBtnDocUpload" defaultMessage="Upload" />
      </div>
    </div>
  );

  const handleCancel = () => {
    setStateModal(false);
  };

  const HandlerModal = (item) => {
    return (
      <div>
        <ReactBnbGallery show={stateModal} photos={modalView} onClose={handleCancel} activePhotoIndex={startIndexGallery} />
      </div>
    );
  };

  return (
    <div>
      <Card
        title={<FormattedMessage id="AssetAttachmentTitle" defaultMessage="Attachment" />}
        bodyStyle={{ padding: '8px 8px 10px 8px' }}
        extra={
          <div className="extra-user-details">
            <div>
              {checkUploadAtt ? (
                <Button01 key="submit" type="primary" btnsize="wd_df" onClick={() => handleClickbutton(false)}>
                  <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
                </Button01>
              ) : (
                <Button01 key="submit" type="primary" btnsize="wd_df" onClick={() => handleClickbutton(true)}>
                  <FormattedMessage id="btnUpload" defaultMessage="Upload" />
                </Button01>
              )}
            </div>
          </div>
        }
      >
        <Collapse
          className="document-collapse"
          bordered={false}
          activeKey={expankey}
          onChange={(e) => setExpankey(e)}
          expandIconPosition="right"
          style={{ backgroundColor: 'white' }}
        >
          {_.get(dataById, 'dataDocumentType') && _.size(dataById.dataDocumentType) > 0
            ? _.get(dataById, 'dataDocumentType').map((item, index) => {
              const filterData = _.filter(_.get(dataById, 'dataDocumentUploaded'), (e) => {
                return e.assetDocTypeId === item.assetDocTypeId;
              });
              const mapData = _.map(filterData, (e) => {
                return {
                  uid: e.fileHash,
                  name: e.fileName,
                  url: e.fileType === 'application/pdf' ? ImgPDF : e.filePath,
                  fileType: e.fileType,
                  remark: e.remark,
                  pdfPath: e.filePath,
                };
              });

              // console.log("array", index, (_.size(dataById.dataDocumentType) - 1))
              return (
                <Panel
                  className="document-collapse-panel"
                  header={item.docTypeName}
                  key={item.assetDocTypeId}
                  style={index === _.size(dataById.dataDocumentType) - 1 ? { borderBottom: 'unset' } : null}
                >
                  <Upload
                    className={style.upload}
                    onChange={() => {
                      // props.onChangeFile(index);
                    }}
                    action={(file) => action(file, item.docComId, index, item.assetDocTypeId)}
                    listType="picture-card"
                    fileList={mapData}
                    onPreview={(file) => handlePreview(mapData, file.name, file)}
                    onRemove={(file) => handleRemove(file, index)}
                    accept=".pdf, .jpg, .png"
                    showUploadList={{
                      // showPreviewIcon: checkPermissionAction('P2S1PG2C7', 'P2S1PG2C7A4') ? true : false,
                      // showRemoveIcon: checkPermissionAction('P2S1PG2C7', 'P2S1PG2C7A3') ? true : false,
                      showPreviewIcon: true,
                      showRemoveIcon: true,
                    }}
                  >
                    {checkUploadAtt === false ? null : uploadButton}
                  </Upload>
                </Panel>
              );
            })
            : null}
        </Collapse>
        <HandlerModal />
      </Card>
    </div>
  );
};

const StyleModal = styled(Modal)`
  .ant-modal-content {
    background-color: transparent;
    box-shadow: none;
  }

  .image-gallery-slide .image-gallery-image {
    max-width: 400px;
    max-height: 500px;
  }

  .ant-modal-close {
    color: #ffffff;
    right: 667px;
  }

  .ant-modal-close-x {
    font-size: 24px;
  }

  .image-gallery-index {
    margin: 0px 20px 0px 20px;
  }

  .image-gallery-right-nav {
    right: 30%;
  }

  .image-gallery-left-nav {
    left: 30%;
  }
`;

export default AssetAttachment;
