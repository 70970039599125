import React, { useEffect, useState } from 'react';
import { Col, Form, Modal, Row, Select, Input } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import _ from 'lodash';
import Button_02 from '../v2/button_02';
import Button_01 from '../v2/button_01';
import LabeRequireForm from '../label-required-form';
import { handleKeyDownNumber } from '../../component-function/fnc-number';
import { getSizeList } from '../../controllers/delivery-order/size';
import { v4 as uuidv4 } from 'uuid';

const { Option } = Select;
const { TextArea } = Input;

const ModalParcelAddForm = ({ form, visible, onCancel, setTableData, tableData, type, record }) => {
  const { getFieldDecorator, resetFields, getFieldValue, validateFields } = form;
  const intl = useIntl();
  const [sizeList, setSizeList] = useState([]);

  useEffect(() => {
    const initialData = async () => {
      const sizeData = await getSizeList({
        where: {},
        page: 1,
        limit: 100000,
      });
      setSizeList(_.get(sizeData, 'data.data.list'));
    };
    if (visible) {
      initialData();
    }
  }, [visible]);

  const handleSave = () => {
    validateFields(async (err, values) => {
      if (err) {
        return;
      }

      let tempData = tableData;

      const filterItemZero = _.filter(sizeList, (i) => {
        return _.get(values, 'size') === i.id;
      });

      const filterItem = _.map(filterItemZero, (i) => ({
        ...i,
        sizeId: i.id,
      }));

      console.log('filterItemZero', filterItem);

      if (_.size(record) > 0) {
        tempData = _.map(tempData, (i) => {
          if (i.parcelId === _.get(record, 'parcelId')) {
            return {
              ...i,
              weight: _.get(values, 'weight'),
              size: _.get(filterItem, '[0]'),
            };
          } else {
            return i;
          }
        });
      } else {
        tempData.push({
          parcelNo: '-',
          parcelId: uuidv4(),
          weight: _.get(values, 'weight'),
          shippingPrice: '0',
          statusCode: 'open',
          size: _.get(filterItem, '[0]'),
          shippingPrice: '0',
        });
      }

      console.log('tempData', tempData);

      setTableData(tempData);
      resetFields();
      onCancel();
    });
  };

  const handleCancel = () => {
    onCancel();
    resetFields();
  }

  return (
    <Modal
      title={intl.formatMessage({ id: 'deliveryOrderAddParcel', defaultMessage: 'Add Parcel' })}
      visible={visible}
      onOk={handleSave}
      onCancel={handleCancel}
      width={500}
      centered={true}
      footer={[
        <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={() => handleCancel()}>
          <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
        </Button_02>,
        <Button_01 key="submit" type="primary" btnsize="wd_df" onClick={() => handleSave()}>
          <FormattedMessage id="btnSave" defaultMessage="Save" />
        </Button_01>,
      ]}
    >
      <Form name="status" colon={false}>
        <Row gutter={[24]}>
          <Col span={24}>
            <Form.Item
              label={<LabeRequireForm text={intl.formatMessage({ id: 'deliveryOrderSize', defaultMessage: 'Size' })} req={true} />}
            >
              {getFieldDecorator('size', {
                initialValue: _.size(record) > 0 ? _.get(record, 'size.sizeId') : undefined,
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'deliveryOrderSizeVD', defaultMessage: 'Please Enter Size' }),
                  },
                ],
              })(
                <Select
                  style={{ width: '100%' }}
                  placeholder={intl.formatMessage({ id: `deliveryParcelPHSize`, defaultMessage: 'Select Size' })}
                  showSearch
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {_.map(sizeList, (item) => (
                    <Select.Option value={item.id}>{item.sizeName}</Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24]}>
          <Col span={24}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'deliveryOrderWeight', defaultMessage: 'Weight (kg.)' })} req={true} />
              }
            >
              {getFieldDecorator('weight', {
                initialValue: _.size(record) > 0 ? _.get(record, 'weight') : '',
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'deliveryOrderWeightVD', defaultMessage: 'Please Enter Weight' }),
                  },
                ],
              })(
                <Input
                  onKeyDown={handleKeyDownNumber}
                  placeholder={intl.formatMessage({ id: 'deliveryOrderWeightPH', defaultMessage: 'Enter Weight' })}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

ModalParcelAddForm.defaultProps = {
  visible: false,
  record: [],
  onCancel: () => console.warn('onCancel not function '),
  type: 'add',
};

const ModalParcelAdd = Form.create({ name: 'select_qt_Form' })(ModalParcelAddForm);

export default ModalParcelAdd;
