import React, { createContext, useEffect, useState } from 'react';
import {
  createSaleOrder,
  getIntialListSaleOrder,
  getMemberIssueBy,
  getSaleOrderById,
  getSaleOrderList,
  updateSaleOrder,
  updateStatusSaleOrder,
  uploadAttachment,
} from '../../controllers/sale-order/sale-order-api-new';
import _ from 'lodash';
import moment from 'moment';
import { getMemberAsset } from '../../controllers/asset-resource/asset-controller';
import { errorNotification, successNotification } from '../../components/v2/notification';
import {
  getSummaryAccount,
  getSaleOrderTOARList,
  getARDetail,
  getSaveAR,
  uploadARAttachment,
  getARPaidDetail,
} from '../../controllers/account-receive';
import { getCustomerLoadMore } from '../../controllers/getCustomer/get-customer-new';
import { getItemListDetail, getSaveTranfer, getTranferItemDetail, getTranferList, updateTFStatus } from '../../controllers/tranferinventory/tranferitem';
import { getWareHouseList } from '../../controllers/warehouse/warehouse';

const AccountReceiveContext = createContext();

const SaleOrderProvider = ({ children }) => {
  const [isOpenQuotation, seIsOpenQuotation] = useState(false);
  const [isFetch, setIsFetch] = useState(false);
  const [record, setRecord] = useState();

  const [openUpload, setOpenUpload] = useState(false);
  const [openUploadPaid, setOpenUploadPaid] = useState(false);
  const [attachmentData, setAttachmentData] = useState([]);
  const [attachmentPaidData, setAttachmentPaidData] = useState([]);
  const [typeAttachment, setTypeAttachment] = useState('add');
  const [defaultAttachment, setDefaultAttachment] = useState();
  const [summaryData, setSummaryData] = useState([]);
  const [viewData, setViewData] = useState([]);
  const [pageTable, setPageTable] = useState(1);
  const [sizeTable, setSizeTable] = useState(10);

  const [tabKey, setTabKey] = useState('waiting');
  const [initialMemberList, setInitialMemberList] = useState([]);
  const [initialTaxList, setInitialTaxList] = useState([]);
  const [initialReasonList, setInitialReasonList] = useState([]);
  const [initialCampaignList, setInitialCampaignList] = useState([]);
  const [cancelItemData, setCancelItemData] = useState([]);
  const [event, setEvent] = useState(false);
  const [typepatment, setTypePayment] = useState('');
  const [tempVoucher, setTempVoucher] = useState([]);
  const [tempVoucherBrand, setTempVoucherBrand] = useState([]);
  const [tempVoucherModel, setTempVoucherModel] = useState([]);
  const [tempVoucherItem, setTempVoucherItem] = useState([]);
  const [wareHouseFromList, setWareHouseFromList] = useState([]);
  const [wareHouseFromPage, setWareHouseFromPage] = useState(10);
  const [wareHouseFromLoading, setWareHouseFromLoading] = useState(false);
  const [wareHouseFromName, setWareHouseFromName] = useState('');
  const [wareHouseFromMaxLoading, setWareHouseFromMaxLoading] = useState(false)
  const [notWarehouseFromId, setNotWarehouseFromId] = useState();
  const [wareHouseToList, setWareHouseToList] = useState([]);
  const [wareHouseToPage, setWareHouseToPage] = useState(10);
  const [wareHouseToLoading, setWareHouseToLoading] = useState(false);
  const [wareHouseToName, setWareHouseToName] = useState('');
  const [wareHouseToMaxLoading, setWareHouseToMaxLoading] = useState(false)
  const [notWarehouseToId, setNotWarehouseToId] = useState();

  const [visibleApprove, setVisibleApprove] = useState(false);
  const [visibleComplete, setVisibleComplete] = useState(false);
  const [visibleReject, setVisibleReject] = useState(false);
  const [visibleCancel, setVisibleCancel] = useState(false);
  const [visibleCancelPaid, setVisibleCancelPaid] = useState(false);
  const [visibleDelete, setVisibleDelete] = useState(false);
  const [visibleWaiting, setVisibleWaiting] = useState(false);
  const [visibleView, setVisibleView] = useState(false);
  const [visibleCash, setVisibleCash] = useState(false);
  const [visibleBank, setVisibleBank] = useState(false);
  const [visibleCredit, setVisibleCredit] = useState(false);
  const [visibleCheque, setVisibleCheque] = useState(false);
  const [visibleVoucher, setVisibleVoucher] = useState(false);
  const [visibleSelectVoucher, setVisibleSelectVoucher] = useState(false);
  const [reportDataWaiting, setReportDataWaiting] = useState({
    tranferList: [],
    tranferTotal: 0,
    extraSort: undefined,
    fieldSort: '',
    orderSort: 'asc',
    paginationPage: 1,
    paginationSize: 10,
    loading: false,
    transferNo: '',
    fromWarehouseNo: '',
    paymentTermNo: '',
    toWarehouseNo: '',
    assignTo: '',
    rangePickerDate: [],
    event: false,
  });

  const [reportDataApprove, setReportDataApprove] = useState({
    tranferList: [],
    tranferTotal: 0,
    extraSort: undefined,
    fieldSort: '',
    orderSort: 'asc',
    paginationPage: 1,
    paginationSize: 10,
    loading: false,
    transferNo: '',
    fromWarehouseNo: '',
    paymentTermNo: '',
    toWarehouseNo: '',
    assignTo: '',
    rangePickerDate: [],
    event: false,
  });

  const [reportDataCompleted, setReportDataCompleted] = useState({
    tranferList: [],
    tranferTotal: 0,
    extraSort: undefined,
    fieldSort: '',
    orderSort: 'asc',
    paginationPage: 1,
    paginationSize: 10,
    loading: false,
    transferNo: '',
    fromWarehouseNo: '',
    paymentTermNo: '',
    toWarehouseNo: '',
    assignTo: '',
    rangePickerDate: [],
    event: false,
  });

  const [reportDataReject, setReportDataReject] = useState({
    tranferList: [],
    tranferTotal: 0,
    extraSort: undefined,
    fieldSort: '',
    orderSort: 'asc',
    paginationPage: 1,
    paginationSize: 10,
    loading: false,
    transferNo: '',
    fromWarehouseNo: '',
    paymentTermNo: '',
    toWarehouseNo: '',
    assignTo: '',
    event: false,
    // rangePickerDate: [moment().add(-3, 'month'), moment().add('month')],
  });

  const [reportDataCanceled, setReportDataCanceled] = useState({
    tranferList: [],
    tranferTotal: 0,
    extraSort: undefined,
    fieldSort: '',
    orderSort: 'asc',
    paginationPage: 1,
    paginationSize: 10,
    loading: false,
    transferNo: '',
    fromWarehouseNo: '',
    paymentTermNo: '',
    toWarehouseNo: '',
    assignTo: '',
    event: false,
  });

  const resertFilter = {
    tranferList: [],
    tranferTotal: _.get(reportDataWaiting,'tranferTotal'),
    extraSort: undefined,
    fieldSort: '',
    orderSort: 'asc',
    paginationPage: 1,
    paginationSize: 10,
    loading: false,
    transferNo: '',
    fromWarehouseNo: '',
    paymentTermNo: '',
    toWarehouseNo: '',
    assignTo: '',
    rangePickerDate: [],
  };

  useEffect(() => {
    setReportDataWaiting((prev) => ({ ...prev, loading: true }));
    const getApi = async () => {
      const payload = {
        tabCode: 'waiting',
        pageNumber: _.get(reportDataWaiting, 'paginationPage'),
        limit: _.get(reportDataWaiting, 'paginationSize'),
        orderBy: _.get(reportDataWaiting, 'fieldSort'),
        orderType: _.get(reportDataWaiting, 'orderSort'),
        transferNo: _.get(reportDataWaiting, 'transferNo'),
        fromWarehouseNo: _.get(reportDataWaiting, 'fromWarehouseNo'),
        toWarehouseNo: _.get(reportDataWaiting, 'toWarehouseNo'),
        assignTo: _.get(reportDataWaiting, 'assignTo') ? _.get(reportDataWaiting, 'assignTo') : '',
      };
      const response = await getTranferList(payload);

      if (_.size(_.get(response, 'data.transferList')) === 0 &&  _.get(reportDataWaiting, 'paginationPage') > 1 ) {
        setReportDataWaiting((prev) => ({ ...prev, paginationPage: _.get(reportDataWaiting, 'paginationPage') - 1 }));
      }
      else {
        setReportDataWaiting((prev) => ({
          ...prev,
          tranferList: _.get(response, 'data.transferList'),
          tranferTotal: _.get(response, 'data.totalItem'),
        }));
      }

      
      // console.log('WaitingPage2', _.get(response, 'data.transferList'));
    };
    setReportDataWaiting((prev) => ({ ...prev, loading: false }));
    // console.log('tabKey', tabKey);
    if (tabKey === 'waiting') {
      getApi();
    }
  }, [
    isFetch,
    tabKey,
    // _.get(reportDataWaiting, 'rangePickerDate[0]'),
    // _.get(reportDataWaiting, 'rangePickerDate[1]'),
    _.get(reportDataWaiting, 'paginationPage'),
    _.get(reportDataWaiting, 'paginationSize'),
    _.get(reportDataWaiting, 'fieldSort'),
    _.get(reportDataWaiting, 'orderSort'),
    // _.get(reportDataWaiting, 'transferNo'),
    // _.get(reportDataWaiting, 'fromWarehouseNo'),
    // _.get(reportDataWaiting, 'toWarehouseNo'),
    // _.get(reportDataWaiting, 'assignTo'),
    _.get(reportDataWaiting, 'event'),
  ]);

  useEffect(() => {
    setReportDataApprove((prev) => ({ ...prev, loading: true }));
    const getApi = async () => {
      const payload = {
        tabCode: 'approved',
        pageNumber: _.get(reportDataApprove, 'paginationPage'),
        limit: _.get(reportDataApprove, 'paginationSize'),
        orderBy: _.get(reportDataApprove, 'fieldSort'),
        orderType: _.get(reportDataApprove, 'orderSort'),
        transferNo: _.get(reportDataApprove, 'transferNo'),
        fromWarehouseNo: _.get(reportDataApprove, 'fromWarehouseNo'),
        toWarehouseNo: _.get(reportDataApprove, 'toWarehouseNo'),
        assignTo: _.get(reportDataApprove, 'assignTo') ? _.get(reportDataApprove, 'assignTo') : '',
      };
      const response = await getTranferList(payload);

      if (_.size(_.get(response, 'data.transferList')) === 0 &&  _.get(reportDataApprove, 'paginationPage') > 1 ) {
        setReportDataWaiting((prev) => ({ ...prev, paginationPage: _.get(reportDataApprove, 'paginationPage') - 1 }));
      }
      else {
        setReportDataApprove((prev) => ({
          ...prev,
          tranferList: _.get(response, 'data.transferList'),
          tranferTotal: _.get(response, 'data.totalItem'),
        }));
      }


    };
    setReportDataApprove((prev) => ({ ...prev, loading: false }));
    if (tabKey === 'approved') {
      getApi();
    }
  }, [
    isFetch,
    tabKey,
    // _.get(reportDataApprove, 'rangePickerDate[0]'),
    // _.get(reportDataApprove, 'rangePickerDate[1]'),
    _.get(reportDataApprove, 'paginationPage'),
    _.get(reportDataApprove, 'paginationSize'),
    _.get(reportDataApprove, 'fieldSort'),
    _.get(reportDataApprove, 'orderSort'),
    // _.get(reportDataApprove, 'transferNo'),
    // _.get(reportDataApprove, 'fromWarehouseNo'),
    // _.get(reportDataApprove, 'toWarehouseNo'),
    // _.get(reportDataApprove, 'assignTo'),
    _.get(reportDataApprove, 'event'),
  ]);

  useEffect(() => {
    setReportDataCompleted((prev) => ({ ...prev, loading: true }));
    const getApi = async () => {
      const payload = {
        tabCode: 'completed',
        pageNumber: _.get(reportDataCompleted, 'paginationPage'),
        limit: _.get(reportDataCompleted, 'paginationSize'),
        orderBy: _.get(reportDataCompleted, 'fieldSort'),
        orderType: _.get(reportDataCompleted, 'orderSort'),
        transferNo: _.get(reportDataCompleted, 'transferNo'),
        fromWarehouseNo: _.get(reportDataCompleted, 'fromWarehouseNo'),
        toWarehouseNo: _.get(reportDataCompleted, 'toWarehouseNo'),
        assignTo: _.get(reportDataCompleted, 'assignTo') ? _.get(reportDataCompleted, 'assignTo') : '',
      };
      const response = await getTranferList(payload);

      if (_.size(_.get(response, 'data.transferList')) === 0 &&  _.get(reportDataCompleted, 'paginationPage') > 1 ) {
        setReportDataWaiting((prev) => ({ ...prev, paginationPage: _.get(reportDataCompleted, 'paginationPage') - 1 }));
      }
      else {
        setReportDataCompleted((prev) => ({
          ...prev,
          tranferList: _.get(response, 'data.transferList'),
          tranferTotal: _.get(response, 'data.totalItem'),
        }));
      }


    };
    setReportDataCompleted((prev) => ({ ...prev, loading: false }));
    if (tabKey === 'completed') {
      getApi();
    }
  }, [
    isFetch,
    tabKey,
    // _.get(reportDataCompleted, 'rangePickerDate[0]'),
    // _.get(reportDataCompleted, 'rangePickerDate[1]'),
    _.get(reportDataCompleted, 'paginationPage'),
    _.get(reportDataCompleted, 'paginationSize'),
    _.get(reportDataCompleted, 'fieldSort'),
    _.get(reportDataCompleted, 'orderSort'),
    // _.get(reportDataCompleted, 'transferNo'),
    // _.get(reportDataCompleted, 'fromWarehouseNo'),
    // _.get(reportDataCompleted, 'toWarehouseNo'),
    // _.get(reportDataCompleted, 'assignTo'),
    _.get(reportDataCompleted, 'event'),
  ]);

  useEffect(() => {
    setReportDataReject((prev) => ({ ...prev, loading: true }));
    const getApi = async () => {
      const payload = {
        tabCode: 'rejected',
        pageNumber: _.get(reportDataReject, 'paginationPage'),
        limit: _.get(reportDataReject, 'paginationSize'),
        orderBy: _.get(reportDataReject, 'fieldSort'),
        orderType: _.get(reportDataReject, 'orderSort'),
        transferNo: _.get(reportDataReject, 'transferNo'),
        fromWarehouseNo: _.get(reportDataReject, 'fromWarehouseNo'),
        toWarehouseNo: _.get(reportDataReject, 'toWarehouseNo'),
        assignTo: _.get(reportDataReject, 'assignTo') ? _.get(reportDataReject, 'assignTo') : '',
      };
      const response = await getTranferList(payload);

      if (_.size(_.get(response, 'data.transferList')) === 0 &&  _.get(reportDataReject, 'paginationPage') > 1 ) {
        setReportDataWaiting((prev) => ({ ...prev, paginationPage: _.get(reportDataReject, 'paginationPage') - 1 }));
      }
      else {
        setReportDataReject((prev) => ({
          ...prev,
          tranferList: _.get(response, 'data.transferList'),
          tranferTotal: _.get(response, 'data.totalItem'),
        }));
      }


      // console.log('WaitingPage2', _.get(response, 'data.transferList'));
    };
    setReportDataReject((prev) => ({ ...prev, loading: false }));
    // console.log('tabKey', tabKey);
    if (tabKey === 'rejected') {
      getApi();
    }
  }, [
    isFetch,
    tabKey,
    // _.get(reportDataReject, 'rangePickerDate[0]'),
    // _.get(reportDataReject, 'rangePickerDate[1]'),
    _.get(reportDataReject, 'paginationPage'),
    _.get(reportDataReject, 'paginationSize'),
    _.get(reportDataReject, 'fieldSort'),
    _.get(reportDataReject, 'orderSort'),
    // _.get(reportDataReject, 'transferNo'),
    // _.get(reportDataReject, 'fromWarehouseNo'),
    // _.get(reportDataReject, 'toWarehouseNo'),
    // _.get(reportDataReject, 'assignTo'),
    _.get(reportDataReject, 'event'),
  ]);

  useEffect(() => {
    setReportDataCanceled((prev) => ({ ...prev, loading: true }));
    const getApi = async () => {
      const payload = {
        tabCode: 'canceled',
        pageNumber: _.get(reportDataCanceled, 'paginationPage'),
        limit: _.get(reportDataCanceled, 'paginationSize'),
        orderBy: _.get(reportDataCanceled, 'fieldSort'),
        orderType: _.get(reportDataCanceled, 'orderSort'),
        transferNo: _.get(reportDataCanceled, 'transferNo'),
        fromWarehouseNo: _.get(reportDataCanceled, 'fromWarehouseNo'),
        toWarehouseNo: _.get(reportDataCanceled, 'toWarehouseNo'),
        assignTo: _.get(reportDataCanceled, 'assignTo') ? _.get(reportDataCanceled, 'assignTo') : '',
      };

      const response = await getTranferList(payload);
      setReportDataCanceled((prev) => ({
        ...prev,
        tranferList: _.get(response, 'data.transferList'),
        tranferTotal: _.get(response, 'data.totalItem'),
      }));
    };
    setReportDataCanceled((prev) => ({ ...prev, loading: false }));
    if (tabKey === 'canceled') {
      getApi();
    }
  }, [
    isFetch,
    tabKey,
    // _.get(reportDataCanceled, 'rangePickerDate[0]'),
    // _.get(reportDataCanceled, 'rangePickerDate[1]'),
    _.get(reportDataCanceled, 'paginationPage'),
    _.get(reportDataCanceled, 'paginationSize'),
    _.get(reportDataCanceled, 'fieldSort'),
    _.get(reportDataCanceled, 'orderSort'),
    // _.get(reportDataCanceled, 'transferNo'),
    // _.get(reportDataCanceled, 'fromWarehouseNo'),
    // _.get(reportDataCanceled, 'toWarehouseNo'),
    // _.get(reportDataCanceled, 'assignTo'),
    _.get(reportDataCanceled, 'event'),
  ]);

  useEffect(() => {
    const getApi = async () => {
      let payload = {};
      const result = await getMemberIssueBy(payload);
      setInitialMemberList(result || []);
    };
    getApi();
  }, []);

  useEffect(() => {
    async function getWarHouseFrom() {
      try {
        const pageStart = 0;
        const pageEnd = wareHouseFromPage;

        const payload = {
          indexStart: pageStart,
          indexEnd: pageEnd,
          searchLeftMenu: wareHouseFromName,
          notWarehouseId: notWarehouseToId,
          isNotCheckPermission: true,
        };

        if (!wareHouseFromMaxLoading) {
          const response = await getWareHouseList(payload);
          // console.log('response', response, _.get(response, 'data.data.allItem') !== _.size(_.get(response, 'data.data.warehouseList')) ? false : true, wareHouseFromLoading , _.get(response, 'data.data.allItem'), _.size(_.get(response, 'data.data.warehouseList')))

          // const data = await getCustomerLoadMore(pageStart, pageEnd);
          // console.log('CustomerVoucher', _.get(response, 'data.data.warehouseList'));
  
          setWareHouseFromList(_.get(response, 'data.data.warehouseList'));
          setWareHouseFromMaxLoading(_.get(response, 'data.data.allItem') === _.size(_.get(response, 'data.data.warehouseList')) ? true : false)
          setWareHouseFromLoading(false);
        }
        
      } catch (error) {
        // console.log('response error', error)
      }
    }

    getWarHouseFrom();
  }, [wareHouseFromPage, notWarehouseToId, wareHouseFromName]);

  useEffect(() => {
    async function getWarHouseTo() {
      try {
        const pageStart = 0;
        const pageEnd = wareHouseToPage;

        const payload = {
          indexStart: pageStart,
          indexEnd: pageEnd,
          searchLeftMenu: wareHouseToName,
          notWarehouseId: notWarehouseFromId,
          isNotCheckPermission: true,
        };
        if(!wareHouseToMaxLoading) {
          const response = await getWareHouseList(payload);
          // console.log('response', response)
          // const data = await getCustomerLoadMore(pageStart, pageEnd);
          // console.log('CustomerVoucher', _.get(response, 'data.data.warehouseList'));
          setWareHouseToList(_.get(response, 'data.data.warehouseList'));
          setWareHouseToMaxLoading(_.get(response, 'data.data.allItem') === _.size(_.get(response, 'data.data.warehouseList')) ? true : false)
          setWareHouseToLoading(false);
        }
        
      } catch (error) {}
    }

    getWarHouseTo();
  }, [wareHouseToPage, notWarehouseFromId, wareHouseToName]);

  useEffect(() => {
    const getApi = async () => {
      const resultTax = await getIntialListSaleOrder('tax');
      const resultReason = await getIntialListSaleOrder('reason');
      const resultCampaign = await getIntialListSaleOrder('campaign');
      setInitialTaxList(resultTax || []);
      setInitialReasonList(resultReason || []);
      setInitialCampaignList(resultCampaign || []);
    };
    getApi();
  }, []);

  // console.log('initialReasonList', initialReasonList);
  const handleOpenModalTransfer = async (row, code, types) => {
    if (row) {
      try {
        const response = await getTranferItemDetail(_.get(row, 'transferId'));
        // console.log('getSaleOrderById', code, response);
        setNotWarehouseFromId(_.get(response, 'data.warehouseFromId'))
        setRecord({
          ..._.get(response, 'data'),
          code: code,
          type: types,
          itemList: await handleChangePageTable(row, _.get(response, 'data.warehouseToId')),
        });
        seIsOpenQuotation(true);
      } catch (error) {}
    } else {
      seIsOpenQuotation(true);
      setRecord({ code: code });
    }
  };

  const handleChangePageTable = async (row, id) => {
    // console.log('rowTable', row);
    try {
      const payload = {
        limit: sizeTable,
        pageNumber: pageTable,
        transferId: _.get(row, 'transferId'),
        warehouseToId: id,
      };
      const response = await getItemListDetail(payload);
      const listReturn = _.map(_.get(response, 'data.listItems'),(eitem) => ({
        ...eitem,
        qty: _.get(eitem,'qty'),
        qtyStack: _.get(eitem,'qty'),
        qtyNum: _.get(eitem,'warehouseQty')
      }))

      return listReturn;
    } catch (error) {}
  };

  const handleWarhouseFromPopupScroll = (e) => {
    let target = e.target;
    if (!wareHouseFromLoading && target.scrollTop + target.offsetHeight >= target.scrollHeight - 50) {
      setWareHouseFromPage(wareHouseFromPage + 10);
      setWareHouseFromLoading(true);
    }
  };

  const handleSearchFromWarehouse = (value) => {
    setWareHouseFromName(value)
    setWareHouseFromPage(10)
    setWareHouseFromMaxLoading(false)
  }

  const handleWarhouseToPopupScroll = (e) => {
    let target = e.target;
    if (!wareHouseToLoading && target.scrollTop + target.offsetHeight >= target.scrollHeight - 50) {
      setWareHouseToPage(wareHouseToPage + 10);
      setWareHouseToLoading(true);
    }
  };

  const handleSearchToWarehouse = (value) => {
    setWareHouseToName(value)
    setWareHouseToPage(10)
    setWareHouseToMaxLoading(false)
  }

  const handleOpenModalUpload = (row) => {
    if (row) {
      setOpenUpload(true);
      setDefaultAttachment(row);
      setTypeAttachment('edit');
    } else {
      setOpenUpload(true);
      setDefaultAttachment();
      setTypeAttachment('add');
    }
  };

  const handleSaveTranfer = async (payload) => {
    try {
      let response;

      if (payload.code === 'edit') {
        response = await updateSaleOrder(payload);
      } else {
        response = await getSaveTranfer(payload);
      }

      // console.log(response,'response');
      
      if (_.get(response, 'status.code') === 200 ) {
        successNotification(_.get(response, 'status.message'));
      }
      else {
        errorNotification(_.get(response, 'status.message'));
      }

      setIsFetch((prev) => !prev);
      setVisibleView(false);
      seIsOpenQuotation(false);
    } catch (error) {
      errorNotification(_.get(error, 'response.status.message'));
      return;
    }
  };

  const handleCancelQuotation = () => {
    seIsOpenQuotation(false);
    setRecord();
  };

  const handleOpenView = async (data, code) => {
    try {
      const response = await getTranferItemDetail(_.get(data, 'transferId'));

      setViewData({ ..._.get(response, 'data'), code: code });
      setVisibleView(true);
    } catch (error) {}
  };

  const handleCancelView = (value) => {
    setVisibleView(false);
    setViewData([]);
  };

  const handleOpenApprove = (value) => {
    setVisibleApprove(true);
  };

  const handleSaveApprove = async (value,id) => {
    try {
      const response = await updateTFStatus(value,id);
      // console.log('updateStatusSaleOrder', response);

      if (_.get(response, 'status.code') === 200 ) {
        successNotification(_.get(response, 'status.message'));
        handleCancelQuotation();
      }
      else {
        errorNotification(_.get(response, 'status.message'));
      }

      setIsFetch((prev) => !prev);
      setVisibleApprove(false);
    } catch (error) {
      errorNotification(_.get(error, 'response.status.message'));
      return;
    }
  };

  const handleCancelApprove = () => {
    setVisibleApprove(false);
  };

  const handleOpenComplete = (value) => {
    setVisibleComplete(true);
  };

  const handleSaveComplete = async (value,id) => {
    try {
      const response = await updateTFStatus(value,id);
      // console.log('updateStatusSaleOrder', response);

      if (_.get(response, 'status.code') === 200 ) {
        successNotification(_.get(response, 'status.message'));
        handleCancelQuotation();
      }
      else {
        errorNotification(_.get(response, 'status.message'));
      }
      setIsFetch((prev) => !prev);
      setVisibleComplete(false);
    } catch (error) {
      errorNotification(_.get(error, 'response.status.message'));
      return;
    }
  };

  const handleCancelComplete = () => {
    setVisibleComplete(false);
  };

  const handleOpenReject = (value) => {
    setVisibleReject(true);
  };

  const handleSaveReject = async (value,id) => {
    try {
      const response = await updateTFStatus(value,id);
      // console.log('updateStatusSaleOrder', response);

      if (_.get(response, 'status.code') === 200 ) {
        successNotification(_.get(response, 'status.message'));
        handleCancelQuotation();
      }
      else {
        errorNotification(_.get(response, 'status.message'));
      }
      setIsFetch((prev) => !prev);
      setVisibleReject(false);
    } catch (error) {
      errorNotification(_.get(error, 'response.status.message'));
      return;
    }
  };

  const handleCancelReject = (value) => {
    setVisibleReject(false);
  };

  const handleOpenCancel = (value) => {
    setVisibleCancel(true);
  };

  const handleSaveCancel = async (value,id) => {
    try {
      const response = await updateTFStatus(value,id);
      // console.log('updateStatusSaleOrder', response);

      if (_.get(response, 'status.code') === 200 ) {
        successNotification(_.get(response, 'status.message'));
        handleCancelQuotation();
      }
      else {
        errorNotification(_.get(response, 'status.message'));
      }
      setIsFetch((prev) => !prev);
      setVisibleCancel(false);
    } catch (error) {
      errorNotification(_.get(error, 'response.status.message'));
      return;
    }
  };

  const handleCancelCancelQT = (value) => {
    setVisibleCancel(false);
  };

  const handleOpenDelete = (value) => {
    setVisibleDelete(true);
  };

  const handleSaveDelete = async (value) => {
    try {
      const response = await updateStatusSaleOrder(value);
      // console.log('updateStatusSaleOrder', response);

      if (response) {
        const response2 = await getSaleOrderById(response.saleOrderId);
        // console.log('getSaleOrderById', value.key, response2, response);
        if (_.get(response2, 'status.code')) {
          setRecord({ ..._.get(response2, 'data[0]'), code: value.code });
          successNotification('Success');
        }
      }
      // if (_.get(response, 'status.code') === 200) {
      //   successNotification(_.get(response, 'status.message'));
      // }
      setIsFetch((prev) => !prev);
      setVisibleDelete(false);
      seIsOpenQuotation(false);
    } catch (error) {
      errorNotification(_.get(error, 'response.status.message'));
      return;
    }
  };

  const handleCancelDelete = (value) => {
    setVisibleDelete(false);
  };

  const handleOpenWaiting = (value) => {
    setVisibleWaiting(true);
  };

  const handleSaveWaiting = async (value) => {
    try {
      const response = await updateTFStatus(value,_.get(value,'id'));
      // console.log('updateStatusSaleOrder', response);

      if (response) {
        successNotification('Success');
        handleCancelQuotation();
        // const response2 = await getSaleOrderById(response.saleOrderId);
        // console.log('getSaleOrderById', value.key, response2, response);
        // if (_.get(response2, 'status.code')) {
        //   setRecord({ ..._.get(response2, 'data[0]'), code: value.code });
        //   successNotification('Success');
        // }
      }
      // if (_.get(response, 'status.code') === 200) {
      //   successNotification(_.get(response, 'status.message'));
      // }
      setIsFetch((prev) => !prev);
      setVisibleWaiting(false);
    } catch (error) {
      errorNotification(_.get(error, 'response.status.message'));
      return;
    }
  };

  const handleCancelWaiting = (value) => {
    setVisibleWaiting(false);
  };

  const handleOpenModalUploadPaid = (row) => {
    if (row) {
      setOpenUploadPaid(true);
      setDefaultAttachment(row);
      setTypeAttachment('edit');
    } else {
      setOpenUploadPaid(true);
      setDefaultAttachment();
      setTypeAttachment('add');
    }
  };

  const handleOpenCancelPaid = (value) => {
    setVisibleCancelPaid(true);
    setCancelItemData(value);
  };

  const handleCloseCancelPaid = () => {
    setVisibleCancelPaid(false);
  };

  const handleOpenCash = (value) => {
    setVisibleCash(true);
    setTypePayment(value);
  };

  const handleCancelCash = (value) => {
    setVisibleCash(false);
  };

  const handleOpenBank = (value) => {
    setVisibleBank(true);
  };

  const handleCancelBank = (value) => {
    //visibleBank
    setVisibleBank(false);
  };

  const handleOpenCredit = (value) => {
    setVisibleCredit(true);
  };

  const handleCancelCredit = (value) => {
    //visibleCredit
    setVisibleCredit(false);
  };

  const handleOpenCheque = (value) => {
    setVisibleCheque(true);
  };

  const handleCancelCheque = (value) => {
    //visibleCheque
    setVisibleCheque(false);
  };

  const handleOpenVoucher = (value) => {
    setVisibleVoucher(true);
  };

  const handleCancelVoucher = (value) => {
    //visibleVoucher
    setVisibleVoucher(false);
  };

  const openVoucherListModal = (value) => {
    setVisibleSelectVoucher(true);
  };

  const callbackTab = (key) => {
    setTabKey(key);
    setReportDataWaiting(resertFilter);
    setReportDataApprove(resertFilter);
    setReportDataCompleted(resertFilter);
    setReportDataReject(resertFilter);
    setReportDataCanceled(resertFilter);

    handleSearchFromWarehouse('')
    handleSearchToWarehouse('')
  };

  const onReset = () => {
    setReportDataWaiting({...resertFilter, event: !_.get(reportDataWaiting, 'event')});
    setReportDataApprove({...resertFilter, event: !_.get(reportDataApprove, 'event')});
    setReportDataCompleted({...resertFilter, event: !_.get(reportDataCompleted, 'event')});
    setReportDataReject({...resertFilter, event: !_.get(reportDataReject, 'event')});
    setReportDataCanceled({...resertFilter, event: !_.get(reportDataCanceled, 'event')});

    handleSearchFromWarehouse('')
    handleSearchToWarehouse('')
  }

 

  return (
    <AccountReceiveContext.Provider
      value={{
        state: {
          isOpenQuotation,
          isFetch,
          openUpload,
          attachmentData,
          typeAttachment,
          defaultAttachment,
          reportDataApprove,
          reportDataWaiting,
          reportDataCompleted,
          reportDataReject,
          reportDataCanceled,
          initialMemberList,
          initialTaxList,
          initialReasonList,
          initialCampaignList,
          record,
          visibleApprove,
          visibleReject,
          visibleCancel,
          visibleDelete,
          visibleWaiting,
          visibleView,
          visibleCash,
          summaryData,
          attachmentPaidData,
          viewData,
          visibleCancelPaid,
          cancelItemData,
          openUploadPaid,
          visibleBank,
          visibleCredit,
          visibleCheque,
          visibleVoucher,
          typepatment,
          visibleSelectVoucher,
          tempVoucher,
          tempVoucherBrand,
          tempVoucherModel,
          tempVoucherItem,
          wareHouseFromList,
          wareHouseFromLoading,
          wareHouseFromPage,
          wareHouseToList,
          wareHouseToLoading,
          wareHouseToPage,
          notWarehouseFromId,
          notWarehouseToId,
          pageTable,
          sizeTable,
          visibleComplete,
        },
        setState: {
          seIsOpenQuotation,
          setIsFetch,
          setOpenUpload,
          setAttachmentData,
          setTypeAttachment,
          setDefaultAttachment,
          setTabKey,
          setReportDataApprove,
          setReportDataWaiting,
          setReportDataCompleted,
          setReportDataReject,
          setReportDataCanceled,
          setRecord,
          setAttachmentPaidData,
          setViewData,
          setVisibleCash,
          setVisibleCancelPaid,
          setOpenUploadPaid,
          setVisibleBank,
          setTypePayment,
          setVisibleSelectVoucher,
          setTempVoucher,
          setTempVoucherBrand,
          setTempVoucherModel,
          setTempVoucherItem,
          setWareHouseFromPage,
          setWareHouseFromLoading,
          setWareHouseToPage,
          setWareHouseToLoading,
          setNotWarehouseFromId,
          setNotWarehouseToId,
          setPageTable,
          setSizeTable,
        },
        func: {
          handleOpenModalTransfer,
          handleSaveTranfer,
          handleOpenModalUpload,
          handleCancelQuotation,
          handleOpenModalUpload,
          handleOpenApprove,
          handleSaveApprove,
          handleCancelApprove,
          handleOpenReject,
          handleSaveReject,
          handleCancelReject,
          handleOpenCancel,
          handleSaveCancel,
          handleCancelCancelQT,
          handleOpenDelete,
          handleSaveDelete,
          handleCancelDelete,
          handleOpenWaiting,
          handleSaveWaiting,
          handleCancelWaiting,
          handleOpenView,
          handleCancelView,
          handleOpenCash,
          handleCancelCash,
          handleOpenCancelPaid,
          handleCloseCancelPaid,
          handleOpenModalUploadPaid,
          handleOpenBank,
          handleCancelBank,
          handleOpenCredit,
          handleCancelCredit,
          handleOpenCheque,
          handleCancelCheque,
          handleOpenVoucher,
          handleCancelVoucher,
          openVoucherListModal,
          handleWarhouseFromPopupScroll,
          handleWarhouseToPopupScroll,
          handleOpenComplete,
          handleSaveComplete,
          handleCancelComplete,
          callbackTab,
          handleSearchFromWarehouse,
          handleSearchToWarehouse,
          onReset,
        },
      }}
    >
      {children}
    </AccountReceiveContext.Provider>
  );
};

export { AccountReceiveContext, SaleOrderProvider };
