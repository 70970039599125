import React, { useState } from 'react'
import { Modal, Input, Form, Row, Col, Icon, Select } from 'antd'
import { useIntl, FormattedMessage } from 'react-intl'
import Button_02 from '../../../../../components/v2/button_02'
import Button_01 from '../../../../../components/v2/button_01'
import { handleKeyDownDecimal2Fixed } from '../../../../../component-function/fnc-number'
import LabeRequireForm from '../../../../../components/label-required-form'
import _ from 'lodash'

const DeliveryShippingPriceForm = (props) => {
  const { visible, setVisible, form, editData, setEditData, reasonList, orderData } = props
  const intl = useIntl()
  const { getFieldDecorator, getFieldValue, validateFields, resetFields, setFieldsValue } = form

  const handleSave = () => {
    validateFields(async (err, values) => {
      if (err) {
        return;
      }
      const filterReason = _.filter(reasonList, (item) => { return item.reasonCode === _.get(values, 'reason') })
      setEditData({
        ...editData,
        ['amount']: parseFloat(_.get(values, 'total')),
        ['reasonDetail']: {
          ..._.get(filterReason, '[0]'),
          ['remark']: _.get(values, 'remark'),
        }
      })
      setVisible(false)
      resetFields()
    })
  }

  const handleCancel = () => {
    setVisible(false)
    resetFields()
  }

  const Discount = () => {
    const initial = _.get(orderData, 'amount')
    const total = parseFloat(getFieldValue('total') || 0.00)
    console.log("initial", initial, total)
    return (
      <div>
        {
          initial !== total ? (
            <div>
              {
                initial > total ? (
                  <span><FormattedMessage id="deliveryShippingPriceDiscount" defaultMessage="Discount" /> <span style={{ color: '#E73845' }}>{(initial - total).toFixed(2)} <FormattedMessage id="deliveryTHB" defaultMessage="THB" /></span> <FormattedMessage id="deliveryfrom" defaultMessage="from" /> {initial} <FormattedMessage id="deliveryTHB" defaultMessage="THB" /></span>
                ) : (
                  <span><FormattedMessage id="deliveryShippingPriceMarkup" defaultMessage="Markup" /> <span style={{ color: '#3BA223' }}>{(total - initial).toFixed(2)} <FormattedMessage id="deliveryTHB" defaultMessage="THB" /></span> <FormattedMessage id="deliveryfrom" defaultMessage="from" /> {initial} <FormattedMessage id="deliveryTHB" defaultMessage="THB" /></span>
                )
              }
            </div>
          ) : (
            <span><FormattedMessage id="deliveryShippingPriceNoPrice" defaultMessage="No price markup or discount." /></span>
          )
        }
      </div >
    )
  }

  return (
    <Modal
      title={intl.formatMessage({ id: `deliveryEditShipping`, defaultMessage: 'Edit Shipping Price' })}
      centered={true}
      width={380}
      visible={visible}
      onCancel={handleCancel}
      footer={[
        <Button_02 key="back" btnsize="wd_df" onClick={() => handleCancel()}>
          <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
        </Button_02>,
        <Button_01 key="submit" type="primary" btnsize="wd_df" onClick={() => handleSave()}>
          <FormattedMessage id="btnSave" defaultMessage="Save" />
        </Button_01>,
      ]}
    >
      < Form className='deliveryForm' colon={false} form={form}>
        <Row gutter={[16, 8]}>
          <Col span={24}>
            <Form.Item
              label={<LabeRequireForm text={intl.formatMessage({ id: 'deliveryFormShippingTotal', defaultMessage: 'Total Shipping Price' })} req={true} />}
            >
              {getFieldDecorator('total', {
                initialValue: _.get(editData, 'amount'),
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'deliveryFormReqShippingTotal', defaultMessage: 'Please Enter Total Shipping Price' }),
                  },
                ],
              })(
                <Input
                  className='deliveryShippingInput'
                  suffix={<Icon type="undo" onClick={() => setFieldsValue({ ['total']: 0.00 })} />}
                  onKeyDown={handleKeyDownDecimal2Fixed}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col span={24}>
            <div>
              {Discount()}
            </div>
          </Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col span={24}>
            <Form.Item
              label={<LabeRequireForm text={intl.formatMessage({ id: 'deliveryFormShippingReason', defaultMessage: 'Reason' })} req={true} />}
            >
              {getFieldDecorator('reason', {
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'deliveryFormReqShippingReason', defaultMessage: 'Please Select Reason' }),
                  },
                ],
              })(
                <Select
                  placeholder={intl.formatMessage({ id: 'deliveryFormPHShippingReason', defaultMessage: 'Select Reason' })}
                  style={{ width: '100%' }}
                >
                  {
                    _.map(reasonList, (item) => (
                      <Select.Option value={item.reasonCode}>
                        {item.reasonName.en}
                      </Select.Option>
                    ))
                  }
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col span={24}>
            <Form.Item
              label={<LabeRequireForm text={intl.formatMessage({ id: 'deliveryFormShippingRemark', defaultMessage: 'Remark' })} req={false} />}
            >
              {getFieldDecorator('remark', {
              })(
                <Input.TextArea
                  autoSize={{ minRows: 5, maxRows: 5 }}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal >
  )
}

const DeliveryShippingPrice = Form.create({
  name: 'assignTeam-form',
  mapPropsToFields(props) { },
})(DeliveryShippingPriceForm);

export default DeliveryShippingPrice
