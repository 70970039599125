import React from 'react';
import { Tag } from 'antd';

import stylePost from './css/post.css';

export default ((props) => {
  const { postData, handleTags } = props;
  return (
    <div className='post-tags-view-modal' >
      <div className='post-tags-view'>
        {postData && postData.map((item, index) => (
          <Tag className='post-tags' key={index} onClick={() => handleTags(item)}>
            {item.name}
          </Tag>
        ))}
      </div>
    </div>
  )
})