import React from 'react';
import { Card, Row, Col, Divider } from 'antd';
import './css/index.css';
import { useIntl, FormattedMessage } from 'react-intl';
import _ from 'lodash';

const Summary = (props) => {
  const intl = useIntl();
  const { data, blank, handleSelectModule, moduleIdArr } = props;
  const moduleId = _.get(moduleIdArr, '[0]');

  return (
    <div>
      <Card
        className="summary-card scroll-md"
        title={intl.formatMessage({
          id: 'cardTitleSummaryModule',
          defaultMessage: 'Summary Module',
        })}
      >
        <div className="xxxxxxxx scroll-md">
          {data &&
            data.map((item) => (
              <div>
                <div
                  className={item.licenseModuleId === moduleId ? 'summary-text-display-active' : 'summary-text-display'}
                  key={item.licenseModuleId}
                  onClick={() => handleSelectModule(item.licenseModuleId)}
                >
                  <div className={item.licenseModuleId === moduleId ? 'summary-text-left-active' : 'summary-text-left'}>
                    <span>{item.module}</span>
                  </div>
                  <div className={item.licenseModuleId === moduleId ? 'summary-text-right-active' : 'summary-text-right'}>
                    <span>{item.txtTotal}</span>
                  </div>
                </div>
                <Divider type="horizontal" className="div-license-com" />
              </div>
            ))}
        </div>
      </Card>
    </div>
  );
};

export default Summary;
