import React, { useEffect, useState } from 'react';
import { Modal, Tabs } from 'antd';
import './css/index.css';
import ViewDetail from './view-detail';
import ViewStakeholder from './view-stakeholder';
import { FormattedMessage, useIntl } from 'react-intl';
import Button01 from '../../v2/button_01';
import { getProjectById, getSearchStakeHolder } from '../../../controllers/project/project-api';
import _ from 'lodash';
import Nodata from '../../image/Nodata.svg';

const { TabPane } = Tabs;

export default (props) => {
  const intl = useIntl();
  const { visible, setVisible, id } = props;
  const [viewData, setViewData] = useState({});
  const [stakeholderData, setStakeholderData] = useState([]);
  const [total, setTotal] = useState(0);
  const [paginationPage, setPaginationPage] = useState(1);
  const [paginationSize, setPaginationSize] = useState(10);
  const [selectBy, setSelectBy] = useState('all');
  const [searchRaw, setSearchRaw] = useState();
  const [searchValue, setSearchValue] = useState();
  const [statusProject, setStatusProject] = useState(true);

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
    },
    {
      title: intl.formatMessage({ id: 'modalViewProjectColumnsName', defaultMessage: 'Name' }),
      dataIndex: 'stackholderName',
      key: 'stackholderName',
    },
    // {
    //   title: intl.formatMessage({ id: 'modalViewProjectColumnsTeam', defaultMessage: 'Team' }),
    //   dataIndex: 'team',
    //   key: 'team',
    // },
    {
      title: intl.formatMessage({ id: 'modalViewProjectColumnsPosition', defaultMessage: 'Position' }),
      dataIndex: 'stackholderPosition',
      key: 'stackholderPosition',
    },
    {
      title: intl.formatMessage({ id: 'modalViewProjectColumnsPhone', defaultMessage: 'Phone' }),
      dataIndex: 'stackholderPhone',
      key: 'stackholderPhone',
    },
  ];

  useEffect(() => {
    const GetById = async () => {
      const response = await getProjectById(id);

      if (_.get(response, 'data.projectNo')) {
        setViewData({
          pathIMG: response.data.projectImgProfile,
          projectNo: response.data.projectNo,
          projectName: response.data.projectName,
          projectType: response.data.projectTypeName,
          priorityName: response.data.projectPriorityName,
          customerName: response.data.customerName,
          projectManagerName: response.data.projectMangerName,
          planStartText: response.data.planStart,
          planFinishText: response.data.planFinish,
          referenceNo: response.data.referenceNo,
          remark: response.data.remark,
          projectPriorityIcon: response.data.projectPriorityIcon,
        });
        setStatusProject(true);
      } else {
        setStatusProject(false);
      }
    };
    if (visible) {
      GetById();
    }
  }, [id, visible]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setSearchValue(searchRaw);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchRaw]);

  useEffect(() => {
    const SearchData = async () => {
      const body = {
        search: searchValue,
        searchBy: selectBy,
        orderType: 'desc',
        orderBy: '',
        paging: searchValue ? 1 : paginationPage,
        rowsPerPages: paginationSize,
      };
      const response = await getSearchStakeHolder(body, id);
      setTotal(response.data.total);
      setStakeholderData(response.data.data);
    };
    if (visible) {
      SearchData();
    }
  }, [id, searchValue, selectBy, paginationSize, paginationPage, visible]);

  const handleCancel = () => {
    setVisible(false);
  };

  const handlePagination = (page) => {
    setPaginationPage(page);
  };

  const handleSizeChange = (current, size) => {
    setPaginationPage(current);
    setPaginationSize(size);
  };

  const handleSelectColumns = (value) => {
    setSelectBy(value);
  };

  return (
    <div>
      <Modal
        title={intl.formatMessage({ id: `modalViewProjectTextHeader`, defaultMessage: 'Project' })}
        width={850}
        visible={visible}
        onCancel={handleCancel}
        centered={true}
        bodyStyle={{ padding: 'unset', maxHeight: '450px', height: '450px' }}
        footer={
          <Button01 key="submit" type="primary" btnsize="wd_df" style={{ margin: 'unset' }} onClick={handleCancel}>
            <FormattedMessage id="modalConfirmBtnClose" defaultMessage="Close" />
          </Button01>
        }
      >
        {statusProject ? (
          <Tabs className="modal-view-project-tab">
            <TabPane tab={intl.formatMessage({ id: `modalViewProjectTextDetail`, defaultMessage: 'Detail' })} key="1">
              <ViewDetail viewData={viewData} />
            </TabPane>
            <TabPane tab={intl.formatMessage({ id: `modalViewProjectTextStakeholder`, defaultMessage: 'Stakeholder' })} key="2">
              <ViewStakeholder
                columns={columns}
                stakeholderData={stakeholderData}
                total={total}
                handlePagination={handlePagination}
                handleSizeChange={handleSizeChange}
                handleSelectColumns={handleSelectColumns}
                selectBy={selectBy}
                setSearchRaw={setSearchRaw}
                searchRaw={searchRaw}
              />
            </TabPane>
          </Tabs>
        ) : (
          <div>
            <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '100px' }}>
              <img style={{ height: '150px' }} src={Nodata} />
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <p>Project Not Found</p>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};
