import React, { useContext } from 'react'
import { Card, Dropdown, Menu, Button, Row, Col, Tag, Divider, Table } from 'antd'
import { useIntl, FormattedMessage } from 'react-intl'
import Button01 from '../../../components/v2/button_01.js'
import { DownOutlined } from '@ant-design/icons'
import PettyCashDetailAction from './action.js'
import { PettyCashDetailProvider } from './context.js'
import _ from 'lodash'
import moment from 'moment'
import { numberFormatter } from '../../../component-function/fnc-inputnumber-currency.js'
import { PageSettings } from '../../../config/page-settings.js'

const PettyCashDetailContent = () => {
  const intl = useIntl()
  const { state, fnc } = useContext(PettyCashDetailProvider)
  const { newDataColumns, dataSource, total, tableChange, defaultData, summary } = state
  const { handleOpenModal, handleTableChange, handleDeleteItem, handleExport } = fnc
  const { checkPermissionAction } = useContext(PageSettings);

  const menuColumn = (
    <Menu>
      {
        checkPermissionAction('P83PG1C1', 'P83PG1C1A3') ? (
          <Menu.Item onClick={() => handleOpenModal('edit', defaultData)}>
            <Button style={{ padding: 'unset' }} ghost type="link" >
              <FormattedMessage id="btnEdit" defaultMessage="Edit" />
            </Button>
          </Menu.Item>
        ) : null
      }
      {
        checkPermissionAction('P83PG1C2', 'P83PG1C2A2') ? (
          <Menu.Item onClick={() => handleOpenModal('tranfer', defaultData)}>
            <Button style={{ padding: 'unset' }} ghost type="link" >
              <FormattedMessage id="btnTranfer" defaultMessage="Tranfer" />
            </Button>
          </Menu.Item>
        ) : null
      }
      {
        checkPermissionAction('P83PG1C2', 'P83PG1C2A3') ? (
          <Menu.Item onClick={() => handleOpenModal('receive', defaultData)}>
            <Button style={{ padding: 'unset' }} ghost type="link">
              <FormattedMessage id="btnReceive" defaultMessage="Receive" />
            </Button>
          </Menu.Item>
        ) : null
      }
      {
        checkPermissionAction('P83PG1C2', 'P83PG1C2A4') ? (
          <Menu.Item onClick={() => handleOpenModal('paid', defaultData)}>
            <Button style={{ padding: 'unset' }} ghost type="link">
              <FormattedMessage id="btnPaid" defaultMessage="Paid" />
            </Button>
          </Menu.Item>
        ) : null
      }
      {
        checkPermissionAction('P83PG1C1', 'P83PG1C1A4') ? (
          <Menu.Item onClick={() => handleDeleteItem(defaultData)}>
            <Button style={{ padding: 'unset' }} ghost type="link">
              <FormattedMessage id="btnClosePettyCash" defaultMessage="Close Petty Cash" />
            </Button>
          </Menu.Item>
        ) : null
      }
      {
        checkPermissionAction('P83PG1C2', 'P83PG1C2A5') ? (
          <Menu.Item style={{ borderTop: '1px solid #F2F3F5' }} onClick={() => handleExport()}>
            <Button style={{ padding: 'unset' }} ghost type="link">
              <FormattedMessage id="btnExport" defaultMessage="Export Excel" />
            </Button>
          </Menu.Item>
        ) : null
      }
    </Menu>
  );

  const menuColumnExport = (
    <Menu>
      {
        checkPermissionAction('P83PG1C2', 'P83PG1C2A5') ? (
          <Menu.Item onClick={() => handleExport()}>
            <Button style={{ padding: 'unset' }} ghost type="link">
              <FormattedMessage id="btnExport" defaultMessage="Export Excel" />
            </Button>
          </Menu.Item>
        ) : null
      }
    </Menu>
  )


  const showTotal = () => {
    return (
      <span style={{ fontSize: '13px' }}>
        <FormattedMessage id="lblRowPerPage" defaultMessage="Rows per page: " />
      </span>
    );
  };

  return (
    <Card
      title={intl.formatMessage({ id: 'pettyCashCardTitleDetail', defaultMessage: 'Information' })}
      bodyStyle={{ padding: 'unset', height: 'calc(100vh - 165px)', overflowY: 'auto' }}
      extra={<Dropdown overlay={_.get(defaultData, 'statusCode') !== 'closed' ? menuColumn : menuColumnExport} trigger={['click']} placement="bottomRight">
        <Button01 key="submit" type="primary" btnsize="wd_df" style={{ margin: 'unset' }}>
          <FormattedMessage id="btnActionGroup" defaultMessage="Action" />
          <DownOutlined style={{ fontSize: '14px', paddingLeft: '22%' }} />
        </Button01>
      </Dropdown>}
    >
      {
        checkPermissionAction('P83PG1C1', 'P83PG1C1A1') ? (
          <>
            <PettyCashDetailHeader defaultData={defaultData} />
          </>
        ) : null
      }
      {
        checkPermissionAction('P83PG1C2', 'P83PG1C2A1') ? (
          <>
            <div className='pettyCashHeaderSummaryDetail' style={{ margin: checkPermissionAction('P83PG1C1', 'P83PG1C1A1') ? '24px 0 0 0' : 'unset', padding: '24px' }}>
              <FormattedMessage id="pettyCashHeaderTransaction" defaultMessage="Transaction" />
            </div>
            <Divider type='horizontal' style={{ margin: 'unset' }} />
            <PettyCashDetailSummary summary={summary} />
            <PettyCashDetailTable
              newDataColumns={newDataColumns}
              dataSource={dataSource}
              total={total}
              handleTableChange={handleTableChange}
              tableChange={tableChange}
              showTotal={showTotal}
            />
          </>
        ) : null
      }

    </Card>
  )
}

const PettyCashDetailHeader = (props) => {
  const { defaultData } = props
  const lang = localStorage.getItem('langValue')
  return (
    <div style={{ padding: '24px' }}>
      <Row gutter={[16, 8]}>
        <Col span={24}>
          <div className='pettyCashHeader' style={{ marginBottom: '10px' }}>
            {_.get(defaultData, 'pettyCashNo', "")} · {_.get(defaultData, 'pettyCashName', "")}
          </div>
          <Tag className="pattyCashHeaderStatus" color={_.get(defaultData, `statusColor`)}>
            {_.get(defaultData, `status[${lang}]`)}
          </Tag>
        </Col>
      </Row>
      <Row gutter={[16, 8]} style={{ marginTop: '24px' }}>
        <Col span={8}>
          <div className='pettyCashHeaderDetail'>
            <FormattedMessage id="pettyCashHeaderTeam" defaultMessage="Team" />
          </div>
          <div className='pettyCashHeaderValue'>
            {_.isObject(_.get(defaultData, 'team.teamName')) ? "" : _.get(defaultData, 'team.teamName', "")}
          </div>
        </Col>
        <Col span={8}>
          <div className='pettyCashHeaderDetail'>
            <FormattedMessage id="pettyCashHeaderResponsibleby" defaultMessage="Responsible by" />
          </div>
          <div className='pettyCashHeaderValue'>
            {_.isObject(_.get(defaultData, 'responsibleBy.responsibleBy')) ? "" : _.get(defaultData, 'responsibleBy.responsibleBy')}
          </div>
        </Col>
        <Col span={8}>
          <div className='pettyCashHeaderDetail'>
            <FormattedMessage id="pettyCashHeaderCreatedby" defaultMessage="Created by" />
          </div>
          <div className='pettyCashHeaderValue'>
            {_.get(defaultData, 'createdBy', "")}
          </div>
        </Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col span={8}>
          <div className='pettyCashHeaderDetail'>
            <FormattedMessage id="pettyCashHeaderCreatedDate" defaultMessage="Created Date" />
          </div>
          <div className='pettyCashHeaderValue'>
            {moment.unix(_.get(defaultData, 'createdAt')).format('DD/MM/YYYY')}
          </div>
        </Col>
        <Col span={8}>
          <div className='pettyCashHeaderDetail'>
            <FormattedMessage id="pettyCashHeaderRemark" defaultMessage="Remark" />
          </div>
          <div className='pettyCashHeaderValue'>
            {_.get(defaultData, 'remark', "-") !== "" ? _.get(defaultData, 'remark', "-") : "-"}
          </div>
        </Col>
      </Row>
    </div >
  )
}

const PettyCashDetailSummary = (props) => {
  const { summary } = props
  return (
    <div style={{ padding: '44px 149px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <div style={{ textAlign: 'center' }}>
        <div className='pettyCashSummaryDetail'>
          <FormattedMessage id="pettyCashSummaryCredit" defaultMessage="Credit" />
        </div>
        <div className='pettyCashSummaryValue'>
          {numberFormatter(_.get(summary, 'credit', 0))} THB
        </div>
      </div>
      <div style={{ textAlign: 'center' }}>
        <div className='pettyCashSummaryDetail'>
          <FormattedMessage id="pettyCashSummaryDebit" defaultMessage="Debit" />
        </div>
        <div className='pettyCashSummaryValue'>
          {numberFormatter(_.get(summary, 'debit', 0))} THB
        </div>
      </div>
      <div style={{ textAlign: 'center' }}>
        <div className='pettyCashSummaryDetail'>
          <FormattedMessage id="pettyCashSummaryBalance" defaultMessage="Balance" />
        </div>
        <div className='pettyCashSummaryValue'>
          {numberFormatter(_.get(summary, 'balance', 0))} THB
        </div>
      </div>
    </div >
  )
}

const PettyCashDetailTable = (props) => {
  const { newDataColumns, dataSource, handleTableChange, total, tableChange, showTotal } = props
  return (
    <div>
      <PettyCashDetailAction />
      <div style={{ padding: '0 24px 24px 24px' }}>
        <Table
          columns={newDataColumns}
          dataSource={dataSource}
          scroll={{ x: _.sumBy(newDataColumns, 'width') }}
          onChange={handleTableChange}
          pagination={{
            total: total,
            current: tableChange.page,
            showTotal: showTotal,
            defaultCurrent: 1,
            pageSizeOptions: ['10', '20', '30', '40', '50'],
            showSizeChanger: true,
            locale: { items_per_page: '' },
          }}
        />
        {total > 0 ? (
          <div className="total-items-member">
            <span style={{ fontSize: '13px' }}>
              <FormattedMessage id="lblTotal" defaultMessage="Total" />
              {` ${total || 0} `}
              <FormattedMessage id="lblitems" defaultMessage="items" />
            </span>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default PettyCashDetailContent
