import httpClient from '../../components/axiosClient';

const getDataDriverTask = async (body) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v4/task/manager/company/${comCode}/report/driverexpenses?menuType=transportation`, body);

    if (response.status === 200) {
      return response.data
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }

}

const exportDriverTask = async (body) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v4/task/manager/company/${comCode}/report/driverexpenses/export?menuType=transportation`, body);

    if (response.status === 200) {
      return response
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }

}

export { getDataDriverTask, exportDriverTask };