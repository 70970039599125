import React, { useState, useEffect } from 'react';
import { Modal, Transfer, Table } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import Button01 from '../../v2/button_01';
import Button02 from '../../v2/button_02';
import './css/index.css';
import { getMembersLoadmore, saveSkateholder, getSearchStakeHolder } from '../../../controllers/project/project-api';
import _ from 'lodash';
import difference from 'lodash/difference';
import { successNotification, errorNotification } from '../../v2/notification';

export default (props) => {
  const { visible, setVisible, id, setTriggerStake } = props;
  const [memberData, setMemberData] = useState([]);
  const [target, setTarget] = useState([]);
  const [selectKey, setSelectKey] = useState([]);
  const intl = useIntl();

  useEffect(() => {
    const getSkate = async () => {
      const body = {
        search: '',
        searchBy: 'all',
        orderType: 'desc',
        orderBy: '',
        paging: 1,
        rowsPerPages: 10000,
      };
      const skate = await getSearchStakeHolder(body, id);
      const mapData =
        skate &&
        skate.data.data.map((item) => {
          return item.stackholderId;
        });
      setTarget(mapData);
    };
    if (visible) {
      getSkate();
    }
  }, [id, visible]);

  const leftTableColumns = [
    {
      dataIndex: 'title',
      title: intl.formatMessage({ id: `modalProjectStakeholderName`, defaultMessage: 'Name ' }),
    },
    {
      dataIndex: 'description',
      title: intl.formatMessage({ id: `modalProjectStakeholderEmail`, defaultMessage: 'Email ' }),
    },
  ];

  const rightTableColumns = [
    {
      dataIndex: 'title',
      title: intl.formatMessage({ id: `modalProjectStakeholderName`, defaultMessage: 'Name ' }),
    },
    {
      dataIndex: 'description',
      title: intl.formatMessage({ id: `modalProjectStakeholderEmail`, defaultMessage: 'Email ' }),
    },
  ];

  useEffect(() => {
    const getmemeber = async () => {
      let payload = {
        indexStart: 10000,
        indexEnd: 10000,
        // managerName: managerSearch,
      };
      const response = await getMembersLoadmore(payload);
      const filterNull = _.get(response, 'data.data').filter((item) => {
        return item.mem_com_id !== null && item.fullname !== null;
      });

      const mapData = filterNull.map((item) => {
        return {
          key: item.mem_com_id !== null ? item.mem_com_id.toString() : null,
          title: cutText(item.fullname, 'name'),
          description: item.email !== null ? cutText(item.email, 'email') : item.email,
          position: item.position,
          phone: item.phone,
        };
      });
      setMemberData(mapData);
    };
    getmemeber();
  }, []);

  const cutText = (input, type) => {
    if (type === 'name') {
      // if (input.length > 10) {
      //   return input.substring(0, 10) + '...';
      // }
      return input;
    }
    if (type === 'email') {
      // if (input.length > 10) {
      //   return input.substring(0, 10) + '...';
      // }
      return input;
    }
  };

  const handleCreate = async () => {
    const mapData =
      target &&
      target.map((item) => {
        const filter =
          memberData &&
          memberData.filter((e) => {
            return item === e.key;
          });

        return {
          projectId: id,
          stackholderId: _.get(filter, '[0].key'),
          stackholderName: _.get(filter, '[0].title'),
          stackholderPosition: _.get(filter, '[0].position'),
          stackholderEmail: _.get(filter, '[0].description'),
          stackholderPhone: _.get(filter, '[0].phone'),
        };
      });

    const response = await saveSkateholder(mapData, id);
    if (response.status === 200) {
      setTriggerStake((current) => !current);
      setVisible(false);
      successNotification(response.data.massage);
    } else {
      errorNotification(response.data.massage);
    }
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleChange = (nextTargetKeys, direction, moveKeys) => {
    setTarget(nextTargetKeys);
  };

  const TableTransfer = ({ leftColumns, rightColumns, ...restProps }) => (
    <Transfer {...restProps} showSelectAll={false}>
      {({ direction, filteredItems, onItemSelectAll, onItemSelect, selectedKeys: listSelectedKeys, disabled: listDisabled }) => {
        const columns = direction === 'left' ? leftColumns : rightColumns;

        const rowSelection = {
          getCheckboxProps: (item) => ({ disabled: listDisabled || item.disabled }),
          onSelectAll(selected, selectedRows) {
            const treeSelectedKeys = selectedRows.filter((item) => !item.disabled).map(({ key }) => key);
            const diffKeys = selected ? difference(treeSelectedKeys, listSelectedKeys) : difference(listSelectedKeys, treeSelectedKeys);
            onItemSelectAll(diffKeys, selected);
          },
          onSelect({ key }, selected) {
            onItemSelect(key, selected);
          },
          selectedRowKeys: listSelectedKeys,
        };

        return (
          <Table
            rowSelection={rowSelection}
            columns={columns}
            dataSource={filteredItems}
            pagination={false}
            scroll={{ y: 263 }}
            size="small"
            style={{ pointerEvents: listDisabled ? 'none' : null }}
            onRow={({ key, disabled: itemDisabled }) => ({
              onClick: () => {
                if (itemDisabled || listDisabled) return;
                onItemSelect(key, !listSelectedKeys.includes(key));
              },
            })}
          />
        );
      }}
    </Transfer>
  );

  return (
    <div>
      <Modal
        title={intl.formatMessage({ id: `modalStakeHolderTextHeader`, defaultMessage: 'Stakeholder ' })}
        centered={true}
        width={'85%'}
        visible={visible}
        onOk={handleCreate}
        onCancel={handleCancel}
        footer={[
          <Button02 style={{margin : '0px 10px 0px 10px'}} key="back" btnsize="wd_df" onClick={handleCancel}>
            <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
          </Button02>,
          <Button01 key="submit" type="primary" btnsize="wd_df" onClick={handleCreate} style={{ margin: 'unset' }}>
            <FormattedMessage id="btnSave" defaultMessage="Save" />
          </Button01>,
        ]}
      >
        <TableTransfer
          className="modal-stakeholder-table-tranfer"
          titles={[
            intl.formatMessage({ id: `modalStakeHolderTextHeaderUser`, defaultMessage: 'User' }),
            intl.formatMessage({ id: `modalStakeHolderTextHeaderStakeholder`, defaultMessage: 'Stakeholder' }),
          ]}
          showSearch={true}
          filterOption={(inputValue, item) => item.title.indexOf(inputValue) !== -1 || item.description.indexOf(inputValue) !== -1}
          dataSource={memberData}
          leftColumns={leftTableColumns}
          rightColumns={rightTableColumns}
          targetKeys={target}
          onChange={handleChange}
        />
      </Modal>
    </div>
  );
};
