import React, { useState, useContext } from 'react'
import DeliveryInformation from './component/information'
import DeliveryManager from './component/manager'
import DeliveryMember from './component/member'
import { DeliveryContext } from '../..'
import ManagerModalForm from './modal/manager'
import MemberModal from './modal/member'
import { deleteBranchManagerList, updateBranchManagerList, deleteBranchMemberList } from '../../../../controllers/delivery-order/branch'
import { successNotification, errorNotification } from '../../../../components/v2/notification'
import { Modal } from 'antd'
import _ from 'lodash'
import { useIntl } from 'react-intl'
import { PageSettings } from '../../../../config/page-settings'

export const DeliveryDetailContext = React.createContext();

const DeliveryDetail = () => {
  const intl = useIntl()
  const { checkPermissionAction } = useContext(PageSettings)
  const { managerList, roleList, userList, manageList, selectBranchData,
    setTriggerMember, setTriggerManager, selectBranch, memberList, memberTotal,
    searchMember, memberAll, memberLoading } = useContext(DeliveryContext)
  const [visibleManager, setVisibleManager] = useState(false)
  const [visibleMember, setVisibleMember] = useState(false)
  const [record, setRecord] = useState()

  const handleOpenModalManager = (type) => {
    setVisibleManager(true)
    setRecord(type)
  }

  const handleOpenModalMember = () => {
    setVisibleMember(true)
  }

  const handleDeleteManager = async (id) => {
    Modal.confirm({
      className: 'customer-modal-confirm',
      centered: true,
      icon: null,
      title: intl.formatMessage({ id: 'lblDelete', defaultMessage: 'Are you sure you want to delete ?' }),
      okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        const response = await deleteBranchManagerList(id)
        if (_.get(response, 'status') === 200) {
          successNotification(_.get(response, 'data.status.message'))
          setTriggerManager(cur => !cur)
        } else {
          errorNotification(_.get(response, 'data.status.message'))
        }
      },
      onCancel() { },
    });

  }

  const handleDeleteMember = async (id) => {
    Modal.confirm({
      className: 'customer-modal-confirm',
      centered: true,
      icon: null,
      title: intl.formatMessage({ id: 'lblDelete', defaultMessage: 'Are you sure you want to delete ?' }),
      okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        const response = await deleteBranchMemberList(selectBranch, id)
        if (_.get(response, 'status') === 200) {
          successNotification(_.get(response, 'data.status.message'))
          setTriggerMember(cur => !cur)
        } else {
          errorNotification(_.get(response, 'data.status.message'))
        }
      },
      onCancel() { },
    });

  }

  const handleDeleteUserOrRole = async (record, item) => {
    const filerRole = _.filter(record.roleIds, (e) => {
      return e.roleId !== item.id
    })
    const filerUser = _.filter(record.userIds, (e) => {
      return e.userId !== item.id
    })
    const body = {
      "branchId": _.get(record, 'branchId'),
      "branchCode": _.get(record, 'branchCode'),
      "branchName": _.get(record, 'branchName'),
      "branchManagerCode": _.map(_.get(record, 'branchManagerCode'), (e) => { return e.code }),
      "roleIds": item.type === "role" ? filerRole : _.get(record, 'roleIds'),
      "userIds": item.type === "user" ? filerUser : _.get(record, 'userIds'),
    }
    console.log("item", item, record)
    const response = await updateBranchManagerList(_.get(record, 'id'), body)
    if (_.get(response, 'status') === 200) {
      successNotification(_.get(response, 'data.status.message'))
      setTriggerMember(cur => !cur)
    } else {
      errorNotification(_.get(response, 'data.status.message'))
    }
  }

  const handleChangeTable = (pagination, sorter, extra) => {
    let field = extra.field;
    if (extra.order) {
      if (extra.order === 'descend') {
        searchMember.setMemberOrder('desc');
      } else {
        searchMember.setMemberOrder('asc');
      }
    } else {
      searchMember.setMemberOrder('');
    }
    searchMember.setMemberField(field)
    searchMember.setMemberPage(pagination.current)
    searchMember.setMemberSize(pagination.pageSize)
  };

  const handleSearchMember = (e) => {
    searchMember.setMemberInput(e.target.value);
  }

  const handleSelectColumnsMember = (value) => {
    searchMember.setMemberColumns(value)
  }

  return (
    <DeliveryDetailContext.Provider
      value={{
        handleOpenModalManager,
        manageList,
        handleDeleteManager,
        handleDeleteUserOrRole,
        handleOpenModalMember,
        memberList,
        memberTotal,
        handleDeleteMember,
        handleChangeTable,
        handleSearchMember,
        handleSelectColumnsMember,
        searchMember,
        memberLoading
      }}
    >
      <div style={{ height: 'calc(100vh - 170px)', overflowY: 'auto' }}>
        <DeliveryInformation />
        {
          checkPermissionAction('P58PG1C2', 'P58PG1C2A1') ? (
            <DeliveryManager />
          ) : null
        }
        {
          checkPermissionAction('P58PG1C3', 'P58PG1C3A1') ? (
            <DeliveryMember />
          ) : null
        }
      </div>

      <ManagerModalForm
        visible={visibleManager}
        setVisible={setVisibleManager}
        record={record}
        setRecord={setRecord}
        managerList={managerList}
        roleList={roleList}
        userList={userList}
        branchData={selectBranchData}
        setTrigger={setTriggerManager}
      />

      <MemberModal
        visible={visibleMember}
        setVisible={setVisibleMember}
        userList={userList}
        id={selectBranch}
        memberList={memberAll}
        setTrigger={setTriggerMember}
      />

    </DeliveryDetailContext.Provider>
  )
}

export default DeliveryDetail
