import { AutoComplete, Col, Divider, Form, Icon, Input, Modal, Row, Select } from 'antd';
import React, { useState, useContext } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import LabeRequireForm from '../../../components/label-required-form';
import UploadImageComponent from '../../../components/upload-image-custom';
import Button_01 from '../../../components/v2/button_01';
import Button_02 from '../../../components/v2/button_02';
import WarehouseContext from '../context';
import ModalMapLocationNew from '../../../components/modal-address-noAPI/index';
import _ from 'lodash';
import { useEffect } from 'react';
import './css/index.css';
import { saveWareHouse, updateWareHouse, uploadImageWarehouse } from '../../../controllers/warehouse/warehouse';
import { errorNotification, successNotification } from '../../../components/v2/notification';
import { handleKeyDownDecimal2Fixed, handleKeyDownPhone } from '../../../component-function/fnc-number';
const { TextArea } = Input;
const { Option } = Select;
const WarehouseModal = ({ form, record, setRecord, visible, setVisible }) => {
  const intl = useIntl();
  const { state, setState, fnc } = useContext(WarehouseContext);
  const { warehouseTypeList } = state;
  const { setWarehouseDataFormResponse, setTrigger , setEventWarehouse} = setState;
  const { getFieldDecorator, validateFields, resetFields, setFieldsValue, getFieldValue } = form;
  const [isOpenModalAddress, setIsOpenModalAddress] = useState(false);
  const [autoComplteLocation, setAutoComplteLocation] = useState();
  const [autoComplteValue, setAutoComplteValue] = useState();
  const [defaultAddress, setDefaultAddress] = useState();
  const [imageFile, setImageFile] = useState();
  const typeMap = _.map(_.get(record, 'warehouseType'), (item) => item.warehouseTypeId);
  const comCode = localStorage.getItem('comCode');

  useEffect(() => {
    if (record && visible === true) {
      setDefaultAddress({
        locationName: _.get(record, 'addressName'),
        address: _.get(record, 'address'),
        mapLocation: _.get(record, 'address'),
        lat: parseFloat(_.get(record, 'lat')),
        lng: parseFloat(_.get(record, 'lng')),
      });
    }
  }, [record, visible]);

  useEffect(() => {
    if (defaultAddress && visible === true) {
      setFieldsValue({ ['address']: _.get(defaultAddress, 'address') });
    }
  }, [defaultAddress, visible]);

  useEffect(() => {
    let grossProfitMargin = getFieldValue('grossProfitMargin');
    if (_.toNumber(grossProfitMargin) > 100.0 && visible === true) {
      setFieldsValue({ ['grossProfitMargin']: '100.00' });
    }
  }, [getFieldValue('grossProfitMargin')]);

  const onOk = () => {
    validateFields(async (err, values) => {
      if (err) {
        return;
      }

      const arrType = warehouseTypeList.filter((item) => {
        return values.type.some((ele) => {
          return ele === item.warehouseTypeId;
        });
      });

      const payload = {
        warehouseCode: values.code,
        warehouseName: values.name,
        warehousePhone: values.phone,
        warehouseType: arrType,
        grossProfitMargin: comCode === 'EBE' ? _.toNumber(values.grossProfitMargin) : undefined,
        grossProfitMarginWalkIn: comCode === 'EBE' ? _.toNumber(values.grossProfitMarginWalkIn) : undefined,
        address: _.get(defaultAddress, 'address'),
        addressName: _.get(defaultAddress, 'locationName'),
        lat: _.get(defaultAddress, 'lat'),
        lng: _.get(defaultAddress, 'lng'),
        statusActive: true,
        remark: values.remark,
      };

      console.log('payload', payload);
      let dataLeftPage;

      if (record) {
        /////update
        try {
          const response = await updateWareHouse(_.get(record, 'warehouseId'), payload);
          console.log('updateWareHouse', response);
          if (_.get(response, 'status') === 200) {
            dataLeftPage = _.get(response.data.data, 'warehouseLeftPage');

            if (_.get(response.data.data.warehouseLeftPage, 'warehouseId') && _.get(imageFile, 'file')) {
              let whId = _.get(response.data.data.warehouseLeftPage, 'warehouseId');
              let formData = new FormData();
              formData.append('file', imageFile.file);
              formData.append('warehouseId', whId);
              const res = await uploadImageWarehouse(formData);

              if (_.get(res.data.data, 'fileUrl')) {
                let url = _.get(res.data.data, 'fileUrl');
                dataLeftPage.warehouseImg = url;
              }
            }
            successNotification(_.get(response.data.status, 'message'));
            setWarehouseDataFormResponse(dataLeftPage);
            setDefaultAddress();
            setImageFile();
            setVisible(false);
            setRecord();
            setTrigger((current) => !current);
            setEventWarehouse((current) => !current);
            resetFields();
          } else {
            errorNotification(_.get(response.data.status, 'message'));
          }
        } catch (error) {}
      } else {
        /////create
        try {
          const response = await saveWareHouse(payload);
          console.log('saveWareHouse', response);
          if (_.get(response, 'status') === 200) {
            dataLeftPage = _.get(response.data.data, 'warehouseLeftPage');
            if (_.get(response.data.data.warehouseLeftPage, 'warehouseId') && _.get(imageFile, 'file')) {
              let whId = _.get(response.data.data.warehouseLeftPage, 'warehouseId');
              let formData = new FormData();
              formData.append('file', imageFile.file);
              formData.append('warehouseId', whId);
              const res = await uploadImageWarehouse(formData);

              if (_.get(res.data.data, 'fileUrl')) {
                let url = _.get(res.data.data, 'fileUrl');
                dataLeftPage.warehouseImg = url;
              }
            }
            successNotification(_.get(response.data.status, 'message'));
            setWarehouseDataFormResponse(dataLeftPage);
            setDefaultAddress();
            setImageFile();
            setVisible(false);
            setRecord();
            setEventWarehouse((current) => !current);
            resetFields();
          } else {
            errorNotification(_.get(response.data.status, 'message'));
          }
        } catch (error) {}
      }
    });
  };

  const onCancel = () => {
    setDefaultAddress();
    setRecord();
    setImageFile();
    setVisible(false);
    resetFields();
  };

  const openMapLocation = () => {
    setIsOpenModalAddress(true);
  };

  return (
    <Modal
      title={
        record
          ? intl.formatMessage({ id: `warehouseTitleEdit`, defaultMessage: 'Edit Warehouse' })
          : intl.formatMessage({ id: `warehouseTitleCreate`, defaultMessage: 'Create New Warehouse' })
      }
      visible={visible}
      centered
      width={700}
      // bodyStyle={{ height: 555 }}
      onOk={onOk}
      onCancel={onCancel}
      footer={[
        <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={() => onCancel()} margin=" unset">
          <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
        </Button_02>,
        <Button_01 key="submit" type="primary" btnsize="wd_df" onClick={() => onOk()}>
          <FormattedMessage id="btnSave" defaultMessage="Save" />
        </Button_01>,
      ]}
    >
      <Form colon={false}>
        <Row gutter={[24, 0]}>
          <Col span={7} style={{ display: 'flex', justifyContent: 'center', marginTop: 12 }}>
            <UploadImageComponent record={record} imageFile={imageFile} setImageFile={setImageFile} />
          </Col>
          <Col span={17}>
            <Row gutter={[24, 2]}>
              <Col span={12}>
                <Form.Item
                  label={<LabeRequireForm text={intl.formatMessage({ id: 'warehouseModalCode', defaultMessage: 'Code' })} req={true} />}
                >
                  {getFieldDecorator('code', {
                    initialValue: _.get(record, 'warehouseCode') ? _.get(record, 'warehouseCode') : undefined,
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({ id: 'warehouseModalReqCode', defaultMessage: 'Please enter code' }),
                      },
                    ],
                  })(<Input placeholder={intl.formatMessage({ id: 'ModalLocationPlaceholderCode', defaultMessage: 'Enter Code' })} />)}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={<LabeRequireForm text={intl.formatMessage({ id: 'warehouseModalName', defaultMessage: 'Name' })} req={true} />}
                >
                  {getFieldDecorator('name', {
                    initialValue: _.get(record, 'warehouseName') ? _.get(record, 'warehouseName') : undefined,
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({ id: 'warehouseModalReqName', defaultMessage: 'Please enter name' }),
                      },
                    ],
                  })(<Input placeholder={intl.formatMessage({ id: 'ModalLocationPlaceholderName', defaultMessage: 'Enter  Name' })} />)}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24, 2]}>
              <Col span={12}>
                <Form.Item
                  label={<LabeRequireForm text={intl.formatMessage({ id: 'warehouseModalPhone', defaultMessage: 'Phone' })} req={true} />}
                >
                  {getFieldDecorator('phone', {
                    initialValue: _.get(record, 'warehousePhone') ? _.get(record, 'warehousePhone') : undefined,
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({ id: 'warehouseModalReqPhone', defaultMessage: 'Please enter phone' }),
                      },
                    ],
                  })(
                    <Input
                      maxLength={10}
                      onKeyDown={handleKeyDownPhone}
                      placeholder={intl.formatMessage({ id: 'ModalLocationPlaceholderPhone', defaultMessage: 'Enter phone' })}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={<LabeRequireForm text={intl.formatMessage({ id: 'warehouseModalType', defaultMessage: 'Type' })} req={true} />}
                >
                  {getFieldDecorator('type', {
                    initialValue: _.get(record, 'warehouseType') ? typeMap : undefined,
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({ id: 'warehouseModalReqType', defaultMessage: 'Please select type' }),
                      },
                    ],
                  })(
                    <Select
                      mode="multiple"
                      maxTagCount={1}
                      maxTagTextLength={15}
                      showArrow
                      placeholder={intl.formatMessage({ id: 'ModalLocationPlaceholderType', defaultMessage: 'Select Type' })}
                    >
                      {warehouseTypeList &&
                        warehouseTypeList.map((item) => (
                          <Option key={item.warehouseTypeId} value={item.warehouseTypeId}>
                            {item.warehouseTypeName}
                          </Option>
                        ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24, 2]}>
              {comCode === 'EBE' ? (
                <>
                  {' '}
                  <Col span={12}>
                    <Form.Item
                      label={
                        <LabeRequireForm
                          text={intl.formatMessage({ id: 'warehouseModalDeliveryGP', defaultMessage: 'Delivery GP (%)' })}
                          req={false}
                        />
                      }
                    >
                      {getFieldDecorator('grossProfitMargin', {
                        initialValue: _.get(record, 'grossProfitMarginNum') !== 0 ? _.get(record, 'grossProfitMarginNum') : undefined,
                        rules: [
                          {
                            required: false,
                            message: intl.formatMessage({
                              id: 'warehouseModalReqGrossProfitMargin',
                              defaultMessage: 'Please enter Gross Profit Margin',
                            }),
                          },
                        ],
                      })(
                        <Input
                          maxLength={6}
                          onKeyDown={handleKeyDownDecimal2Fixed}
                          placeholder={intl.formatMessage({
                            id: 'warehouseModalDeliveryGPPlaceHolder',
                            defaultMessage: 'Enter Delivery GP (%)',
                          })}
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label={
                        <LabeRequireForm
                          text={intl.formatMessage({ id: 'warehouseModalWalkInGP', defaultMessage: 'Walk-In GP (%)' })}
                          req={false}
                        />
                      }
                    >
                      {getFieldDecorator('grossProfitMarginWalkIn', {
                        initialValue:
                          _.get(record, 'grossProfitMarginWalkInNum') !== 0 ? _.get(record, 'grossProfitMarginWalkInNum') : undefined,
                        rules: [
                          {
                            required: false,
                            message: intl.formatMessage({
                              id: 'warehouseModalReqGrossProfitMargin',
                              defaultMessage: 'Please enter Gross Profit Margin',
                            }),
                          },
                        ],
                      })(
                        <Input
                          maxLength={6}
                          onKeyDown={handleKeyDownDecimal2Fixed}
                          placeholder={intl.formatMessage({
                            id: 'warehouseModalWalkInGPPlaceHolder',
                            defaultMessage: 'Enter Walk-In GP (%)',
                          })}
                        />
                      )}
                    </Form.Item>
                  </Col>{' '}
                </>
              ) : (
                <Col span={12}>
                  {/* <Form.Item
                    label={
                      <LabeRequireForm
                        text={intl.formatMessage({ id: 'warehouseModalGrossProfitMargin', defaultMessage: 'Gross Profit Margin (%)' })}
                        req={false}
                      />
                    }
                  >
                    {getFieldDecorator('grossProfitMargin', {
                      initialValue: _.get(record, 'grossProfitMarginNum') !== 0 ? _.get(record, 'grossProfitMarginNum') : undefined,
                      rules: [
                        {
                          required: false,
                          message: intl.formatMessage({
                            id: 'warehouseModalReqGrossProfitMargin',
                            defaultMessage: 'Please enter Gross Profit Margin',
                          }),
                        },
                      ],
                    })(
                      <Input
                        maxLength={6}
                        onKeyDown={handleKeyDownDecimal2Fixed}
                        placeholder={intl.formatMessage({
                          id: 'ModalLocationPlaceholderGrossProfitMargin',
                          defaultMessage: 'Enter Gross Profit Margin',
                        })}
                      />
                    )}
                  </Form.Item> */}
                </Col>
              )}
            </Row>
            <Row gutter={[24, 2]}>
              <Col span={24}>
                <Form.Item
                  label={
                    <LabeRequireForm text={intl.formatMessage({ id: 'warehouseModalAddress', defaultMessage: 'Address' })} req={true} />
                  }
                >
                  {getFieldDecorator('address', {
                    initialValue: _.get(record, 'address') ? _.get(record, 'address') : undefined,
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({
                          id: 'warehouseModalReqAddress',
                          defaultMessage: 'Please enter Address',
                        }),
                      },
                    ],
                  })(
                    <Row>
                      <Col span={1}>
                        <Icon type="environment" style={{ fontSize: '12px', color: '#0c4da2', marginRight: '5px' }} />
                      </Col>
                      <Col span={23}>
                        {getFieldValue('address') ? (
                          <span onClick={() => openMapLocation()} className="place-custom-address">
                            {getFieldValue('address')}
                          </span>
                        ) : (
                          <span onClick={() => openMapLocation()} className="place-custom">
                            <FormattedMessage id="warehouseModalPlaceAddress" defaultMessage="Select Address Warehouse" />
                          </span>
                        )}
                      </Col>
                    </Row>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Divider type="horizontal" style={{ margin: '16px 0px' }} />
            <Row gutter={[24, 2]}>
              <Col span={24}>
                <Form.Item
                  label={
                    <LabeRequireForm text={intl.formatMessage({ id: 'warehouseModalRemark', defaultMessage: 'Remark' })} req={false} />
                  }
                >
                  {getFieldDecorator('remark', {
                    initialValue: _.get(record, 'remark') ? _.get(record, 'remark') : undefined,
                    rules: [
                      {
                        required: false,
                        message: intl.formatMessage({
                          id: 'warehouseModalRemarkValidate',
                          defaultMessage: 'Please enter Remark',
                        }),
                      },
                    ],
                  })(<TextArea autoComplete="off" autoSize={{ minRows: 3, maxRows: 3 }} maxLength={250} />)}
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <ModalMapLocationNew
          textId="warehouseModalPlaceAddress"
          textDefault="Select Address Warehouse"
          visible={isOpenModalAddress}
          setVisible={setIsOpenModalAddress}
          defaultAddress={defaultAddress}
          setDefaultAddress={setDefaultAddress}
          autoComplteLocation={autoComplteLocation}
          setAutoComplteLocation={setAutoComplteLocation}
          autoComplteValue={autoComplteValue}
          setAutoComplteValue={setAutoComplteValue}
        />
      </Form>
    </Modal>
  );
};

const WarehouseModalForm = Form.create({
  name: 'warehouse-form',
})(WarehouseModal);

export default WarehouseModalForm;
