import React, { useState, useEffect, createContext, useContext } from 'react';
import { Card, Spin } from 'antd';
import { PageSettings } from './config/page-setting';
import { useAppContext } from '../../../includes/indexProvider';
import ShiftRules from './shift-rules';
import ShiftType from './shift-type';
import httpClient from '../../../components/axiosClient';
import { OrganizationContext } from '../organization-context';
import moment from 'moment';
import enUS from 'antd/es/locale/en_US';
import thTH from 'antd/es/locale/th_TH';
import 'moment/locale/th';
import styles from './css/index.css';

export default (props) => {
  const memComId = localStorage.getItem('memComId');
  const { stateMain, setStateMain } = useContext(OrganizationContext);
  let myRef = React.createRef();
  const app = useAppContext();
  const langValue = app.state.langValue;
  const keyTab = props.keyTab;
  const comId = stateMain.comId;
  const orgId = stateMain.orgId;

  const [shiftData, setShiftData] = useState([]);
  const [workHours, setWorkHours] = useState([]);
  const [holidays, setHolidays] = useState([]);
  const [treeTeams, setTreeTeams] = useState([]);
  const [skillTree, setSkillTree] = useState([]);
  const [skillType, setSkillType] = useState([]);
  const [listRoles, setListRoles] = useState([]);
  const [trigerLoading, setTrigerLoading] = useState(false);
  const [fields, setFields] = useState({});
  const [refreshTable, setRefreshTable] = useState(false);
  const [formData, setFormData] = useState();
  const [shiftReserveTeam, setShiftReserveTeam] = useState([]);
  const [shiftWorkHrRule, setShiftWorkHrRule] = useState([]);
  const [maxOverTimeRule, setMaxOverTimeRule] = useState([]);
  const [switchShiftRules, setSwitchShiftRules] = useState();
  const [locale, setLocale] = useState('enUS');
  const [switchSubteam, setswitchSubteam] = useState();
  const [switchApply, setSwitchApply] = useState();

  const [parentOrg, setParentOrg] = useState();
  const [statusSubTeam, setStatusSubTeam] = useState();
  const [statusApplySubTeam, setStatusApplySubTeam] = useState();
  const [txtApplySubTeam, setTxtApplySubTeam] = useState();

  const [visiblePopover, setVisiblePopover] = useState(false);

  const onScroll = () => {
    const scrollTop = myRef.current.scrollTop
    if ((visiblePopover === true) && scrollTop) {
      setVisiblePopover(false);
    }
  }

  useEffect(() => {
    if (keyTab === 'shiftSetting') {
      shiftSettingData();
      workHourData();
      skillData();
      constShiftTypeData();
    }
  }, [stateMain.orgId, refreshTable, keyTab, switchApply]);

  useEffect(() => {
    changeLocale();
  }, []);

  const changeLocale = () => {
    let codeLang = langValue ? langValue : 'EN';
    let changeLang = codeLang === 'EN' ? enUS : thTH;

    setLocale(changeLang);
    if (!changeLang) {
      moment.locale('en');
    } else {
      moment.locale('th-th');
    }
  };

  const shiftSettingData = async () => {
    setTrigerLoading(true);
    try {
      const result = await httpClient.get(
        `/resource/manager/organization/${stateMain.orgId}/shift/rules?comId=${comId}`,
      );

      if (result.status === 200) {
        setShiftData(result.data);
        setParentOrg(result.data.parentOrg);
        setStatusSubTeam(result.data.statusSubTeam);
        setStatusApplySubTeam(result.data.statusApplySubTeam);
        setTxtApplySubTeam(result.data.txtApplySubTeam);
        setHolidays(result.data.teamHolidays);
        setTreeTeams(root(result.data.treeTeams));
        setListRoles(result.data.listRoles);
        setShiftReserveTeam(result.data.shiftReserveTeamRules.map((item) => item.orgId));
        setShiftWorkHrRule(result.data.shiftWorkHrRule);
        setMaxOverTimeRule(result.data.maxOverTimeRule);

        setSwitchShiftRules(result.data.shiftRuleStatus === "Enable" ? true : false);
        setFormData({
          maxWorkHour: { value: result.data.maxWorkHour },
          maxOverTime: { value: result.data.maxOverTime },
          maxWorkDateType: { value: result.data.maxWorkDateType },
          maxOverDateType: { value: result.data.maxOverDateType },
          swapShift: { value: result.data.swapShift },
          gteLevel: { value: result.data.gteLevel },
          lteLevel: { value: result.data.lteLevel },
          anyShiftType: { value: result.data.anyShiftType },
          continuousWorkDay: { value: result.data.continuousWorkDay },
          shiftReserveTeam: { value: result.data.shiftReserveTeamRules.map((item) => item.orgId) },
          maxOverTimeRule: { value: result.data.maxOverTimeRule },
        });
      }
      setTrigerLoading(false);
    } catch {
      setTrigerLoading(false);
      return
    }
  };

  const workHourData = async () => {
    try {
      const result = await httpClient.get(
        `/resource/manager/company/${comId}/work-hour/templates`,
      );
      if (result.status === 200) {
        setWorkHours(result.data);
      }
    } catch {
      return
    }
  };

  const skillData = async () => {
    try {
      const result = await httpClient.get(`/v2/skills/${memComId}?comId=${comId}`);

      const looptree = (value) => {
        let arr = [];
        let len = value.length;
        for (let i = 0; i < len; i++) {
          let ojb = {
            key: value[i].skill_gro_id.toString(),
            title: value[i].skill_gro_name,
            disabled: true,
            children: [],
          };

          let len2 = value[i].skills.length;
          if (len2) {
            for (let index = 0; index < len2; index++) {
              let ojb2 = {
                key:
                  value[i].skill_gro_id + '-' + value[i].skills[index].skill_id,
                title: value[i].skills[index].name,
                value: value[i].skills[index].skill_id,
                children: [],
              };

              ojb.children.push(ojb2);
            }
          }
          arr.push(ojb);
        }
        setSkillTree(arr);
      };
      looptree(result.data);
    } catch (error) {
      return
    }
  };

  const constShiftTypeData = async () => {
    const body = {
      where: {},
      page: 1,
      pageSize: 1000,
      sortField: 'name',
      sortOrder: 'desc',
    };

    try {
      const result = await httpClient.post(`/resource/manager/company/${comId}/shift-type-list?orgId=${stateMain.orgId}`, body,);
      if (result.status === 200) {
        setSkillType(result.data.rows);
      }
    } catch (error) {
      return
    }
  }

  const root = (items, org_id = orgId, link = 'parent') => (
    items.filter((item) => parseInt(item[link]) === org_id)
      .map((item) => ({
        ...item,
        title: item.name,
        value: item.org_id,
        children: root(items, item.org_id),
      })
      )
  )

  const handleFormChange = changedFields => {
    setFormData(({ formData }) => ({ ...formData, ...changedFields }));
  };


  return (
    <div
      className="shift-setting"
      ref={myRef}
      onScroll={onScroll}
    >
      <PageSettings.Provider
        value={{
          trigerLoading,
          shiftData,
          workHours,
          holidays,
          treeTeams,
          orgId,
          keyTab,
          skillTree,
          skillType,
          listRoles,
          refreshTable,
          shiftReserveTeam,
          setRefreshTable,
          shiftWorkHrRule,
          maxOverTimeRule,
          switchShiftRules,
          setSwitchShiftRules,
          locale,
          switchSubteam,
          setswitchSubteam,
          comId,
          switchApply,
          setSwitchApply,
          setParentOrg,
          parentOrg,
          setStatusSubTeam,
          statusSubTeam,
          setStatusApplySubTeam,
          statusApplySubTeam,
          txtApplySubTeam,
          setTxtApplySubTeam
        }}
      >
        <div className="shift-setting-body">
          <Spin spinning={trigerLoading}>
            <ShiftRules
              formData={formData}
              shiftData={shiftData}
              workHours={workHours}
              fields={fields}
              holidays={holidays}
              orgId={orgId}
              skillTree={skillTree}
              visiblePopover={visiblePopover}
              setVisiblePopover={setVisiblePopover}
            />
            <ShiftType />
          </Spin>
        </div>
      </PageSettings.Provider>
    </div>
  );
};
