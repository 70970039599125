import React, { useState, useEffect, useContext } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Row, Col, Input, Select, Form } from 'antd';
import { SaleOrderContext } from './sale-order-context';
import _, { debounce } from 'lodash';
import stylesCreate from './css/index.css';
import NumberFormat from 'react-number-format';
import CurrencyInput from 'react-currency-input-field';

const { TextArea } = Input;
const { Option } = Select;

const OrderSaleCalculate = (props) => {
  const intl = useIntl();
  const { subtotal, discountSelete, taxSelete, subTotalResult, form } = props;
  const { getFieldDecorator, validateFields, resetFields, getFieldsValue, getFieldValue, setFieldsValue } = form;
  const { mainState, setMainState } = useContext(SaleOrderContext);

  let valueSubTotalResult = parseFloat(subTotalResult).toFixed(2);

  const [valueDiscount, setValueDiscount] = useState("0");
  const [valueLessDiscount, setValueLessDiscount] = useState("0");
  const [valueTax, setValueTax] = useState("0");
  const [valueTotal, setValueTotal] = useState("0");

  useEffect(() => {
    fncCalculate(subTotalResult, discountSelete, taxSelete);
  }, [subTotalResult, discountSelete, taxSelete, mainState.valueInputDiscount]);

  const fncCalculate = (subTotalResult, discount, tax) => {
    let resultDiscount = ""
    if (discount.titleCode !== 'Baht') {
      resultDiscount = _.floor((parseFloat(subTotalResult) * parseFloat(discount.percentage)) / 100, 2);
    } else {
      resultDiscount = mainState.valueInputDiscount !== '' ? parseFloat(mainState.valueInputDiscount) : 0;
    }

    const resultLessDiscount = parseFloat(subTotalResult) - parseFloat(resultDiscount);
    const resultTax = _.floor((parseFloat(resultLessDiscount) * parseFloat(tax.percentage)) / 100, 2);
    const resultTotal = resultLessDiscount + resultTax;

    setValueDiscount(parseFloat(resultDiscount).toFixed(2));
    setValueLessDiscount(parseFloat(resultLessDiscount).toFixed(2));
    setValueTax(parseFloat(resultTax).toFixed(2));
    setValueTotal(parseFloat(resultTotal).toFixed(2));
    setMainState.setSumCalculate({
      discount: parseFloat(resultDiscount).toFixed(2),
      lessDiscount: parseFloat(resultLessDiscount).toFixed(2),
      tax: parseFloat(resultTax).toFixed(2),
      total: parseFloat(resultTotal).toFixed(2),
    });
  }

  const onChangeDiscount = (value) => {
    const result = mainState.discountData.filter((item) => {
      return item.discountId === value
    });
    setMainState.setDiscountSelete(result[0]);
    setMainState.setValueInputDiscount(0.00);
  }

  const onChangeTax = (value) => {
    const result = mainState.taxData.filter((item) => {
      return item.taxId === value
    });
    setMainState.setTaxSelete(result[0]);

  }

  // const onChangeInput = (e) => {
  //   if (e.target.value === "") {
  //     let discount = {
  //       percentage: parseFloat(0.00).toFixed(2)
  //     }
  //     fncCalculate(subTotalResult, { input: discount }, taxSelete);
  //     setMainState.setValueInputDiscount(0.00);
  //   } else {
  //     let discount = {
  //       percentage: parseFloat(e.target.value).toFixed(2)
  //     }
  //     fncCalculate(subTotalResult, { input: discount }, taxSelete);
  //     setMainState.setValueInputDiscount(e.target.value);
  //   }
  // };

  const onChangeInput = (value) => {

    if (value === undefined) {
      setMainState.setValueInputDiscount('');
    } else {
      if (parseFloat(valueSubTotalResult) <= parseFloat(value)) {
        setMainState.setValueInputDiscount(0.00);
      } else {
        setMainState.setValueInputDiscount(value);
      }
    }
  }

  const handleKeyDownNumber = (e) => {
    let keyCode = e.key.charCodeAt(0);

    if ((keyCode >= 48 && keyCode <= 57) || keyCode === 65 || keyCode === 66 || keyCode === 46) {
      if (keyCode === 46 && e.target.value.indexOf('.') > 0) {
        e.preventDefault();
      }
      else {
        return true;
      }
    }
    else {
      e.preventDefault();
    }
  }

  const handleChangeTextArea = (e) => {
    setMainState.setRemark(e.target.value)
  }


  return (
    <Form>
      <div className="sale-oreder-calculate">
        <div className="sale-oreder-calculate-container">
          <Row className="sale-oreder-calculate-row">
            <Col span={12} className="sale-oreder-calculate-col-left">
              <div className="sale-oreder-calculate-col-div">
                <div className="sale-oreder-calculate-col-div-div">
                  <span className="sale-oreder-calculate-text">
                    <FormattedMessage id="saleOrderModalTexRemark" defaultMessage="Remark" />
                  </span>
                </div>
                <TextArea
                  className="sale-oreder-calculate-textArea"
                  placeholder={intl.formatMessage({ id: 'saleOrderModalTextAreaPlaceholderl', defaultMessage: 'Enter Remark' })}
                  rows="5"
                  onChange={handleChangeTextArea}
                  maxLength={250}
                  value={mainState.remark}
                />
              </div>
            </Col>
            <Col span={12} className="sale-oreder-calculate-col-right">
              <div className="sale-oreder-calculate-col-div">

                <div className="sale-oreder-calculate-money">
                  <div className="sale-oreder-calculate-money-left">
                    <span className="sale-oreder-calculate-text">
                      <FormattedMessage id="saleOrderModalTexSubtotal" defaultMessage="Subtotal" />
                    </span>
                  </div>
                  <div className="sale-oreder-calculate-money-right">
                    <NumberFormat value={_.floor(valueSubTotalResult, 2).toFixed(2)} displayType={'text'} thousandSeparator={true}
                      suffix={' ' + intl.formatMessage({ id: 'saleOrderModalTexBaht', defaultMessage: 'Baht' })} />
                  </div>
                </div>

                <div className="sale-oreder-calculate-money">
                  <div className="sale-oreder-calculate-money-left">
                    <span className="sale-oreder-calculate-text">
                      <FormattedMessage id="saleOrderModalTexDiscount" defaultMessage="Discount" />
                    </span>
                    <Select
                      className="sale-oreder-calculate-discount-select"
                      value={discountSelete.discountId}
                      onChange={onChangeDiscount}
                    >
                      {mainState.discountData.map(item => (
                        <Option key={item.discountId} value={item.discountId}>{item.titleCode}</Option>
                      ))}
                    </Select>
                  </div>
                  <div className="sale-oreder-calculate-money-right">
                    {discountSelete.isBath !== true ? (
                      <NumberFormat value={_.floor(valueDiscount, 2).toFixed(2)} displayType={'text'} thousandSeparator={true}
                        suffix={' ' + intl.formatMessage({ id: 'saleOrderModalTexBaht', defaultMessage: 'Baht' })} />
                    ) : (
                      <div className="sale-oreder-calculate-discount-div">
                        {/* <Input
                          className="sale-oreder-calculate-discount-input"
                          value={mainState.valueInputDiscount}
                          onChange={(e) => onChangeInput(e.target.value)}
                          onKeyDown={handleKeyDownNumber}
                        />  */}
                        <CurrencyInput
                          className="sale-oreder-calculate-discount-input"
                          value={mainState.valueInputDiscount}
                          allowDecimals={true}
                          decimalsLimit={2}
                          decimalScale={2}
                          groupSeparator=","
                          onValueChange={(value) => onChangeInput(value)}
                        />
                        {" "}
                        <FormattedMessage id="saleOrderModalTexBaht" defaultMessage="Baht" />
                      </div>
                    )}
                  </div>
                </div>

                <div className="sale-oreder-calculate-money">
                  <div className="sale-oreder-calculate-money-left">
                    <span className="sale-oreder-calculate-text">
                      <FormattedMessage id="saleOrderModalTexSubtotalLessDiscount" defaultMessage="Subtotal Less Discount" />
                    </span>
                  </div>
                  <div className="sale-oreder-calculate-money-right">
                    <NumberFormat value={_.floor(valueLessDiscount, 2).toFixed(2)} displayType={'text'} thousandSeparator={true}
                      suffix={' ' + intl.formatMessage({ id: 'saleOrderModalTexBaht', defaultMessage: 'Baht' })} />
                  </div>
                </div>

                <div className="sale-oreder-calculate-money">
                  <div className="sale-oreder-calculate-money-left">
                    <span className="sale-oreder-calculate-text">
                      <FormattedMessage id="saleOrderModalTexTax" defaultMessage="Tax" />
                    </span>
                    <Select
                      className="sale-oreder-calculate-discount-select"
                      value={taxSelete.taxId}
                      onChange={onChangeTax}
                    >
                      {mainState.taxData.map(item => (
                        <Option key={item.taxId} value={item.taxId}>{item.titleCode}</Option>
                      ))}
                    </Select>
                  </div>
                  <div className="sale-oreder-calculate-money-right">
                    <NumberFormat value={_.floor(valueTax, 2).toFixed(2)} displayType={'text'} thousandSeparator={true}
                      suffix={' ' + intl.formatMessage({ id: 'saleOrderModalTexBaht', defaultMessage: 'Baht' })} />
                  </div>
                </div>

                <div className="sale-oreder-calculate-money">
                  <div className="sale-oreder-calculate-money-left">
                    <span className="sale-oreder-calculate-text">
                      <FormattedMessage id="saleOrderModalTexTotal" defaultMessage="Total" />
                    </span>
                  </div>
                  <div className="sale-oreder-calculate-money-right">
                    <NumberFormat value={_.floor(valueTotal, 2).toFixed(2)} displayType={'text'} thousandSeparator={true}
                      suffix={' ' + intl.formatMessage({ id: 'saleOrderModalTexBaht', defaultMessage: 'Baht' })} />
                  </div>
                </div>

              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Form>
  )
}

const Calculate = Form.create({
  name: "modal_form",
})(OrderSaleCalculate);


export default Calculate;