import httpClient from '../../components/axiosClient';

const createItemBasePay = async (payload) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/expense/manager/company/${comCode}/createBasePay`, payload);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return error.response;
  }
}

const getItemBasePay = async (payload) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/expense/manager/company/${comCode}/getBasePay`, payload);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return error.response;
  }
}

const updateItemBasePay = async (payload, id) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.put(`/expense/manager/company/${comCode}/updateBasePay/${id}`, payload);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return error.response;
  }
};

const creatUserBasePay = async (payload) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/expense/manager/company/${comCode}/createMemberBasePay`, payload);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return error.response;
  }
}

const getUserBasePay = async (payload, id) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/expense/manager/company/${comCode}/getMemberBasePay/${id}`, payload);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return error.response;
  }
}

const updateUserBasePay = async (payload, id, basePayMemberId) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.put(`/expense/manager/company/${comCode}/updateMemberBasePay/${id}/${basePayMemberId}`, payload);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return error.response;
  }
};

const deleteUserBasePay = async (id, basePayMemberId) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.delete(`/expense/manager/company/${comCode}/deleteMemberBasePay/${id}/${basePayMemberId}`)
    if (response.status === 200) {
      return response
    } else {
      return []
    }
  } catch (error) {
    return error.response
  }
}

export {
  createItemBasePay,
  getItemBasePay,
  updateItemBasePay,
  creatUserBasePay,
  getUserBasePay,
  updateUserBasePay,
  deleteUserBasePay,
};
