import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Row, Col, Result, Icon, Card } from 'antd';
import { RoleProvider } from './RoleContext';
import RoleMenu from './RoleMenu';
import RoleContent from './RoleContent';
// import RoleForm from './RoleForm';
// import RoleMember from './RoleMember';
import cssStyle from './css/Role.css';
import NoDataBackground from '../../components/no-data-page';

export default () => {
  const [showRole, setShowRole] = useState();
  const [addNewRole, setAddNewRole] = useState(false);

  const addRole = (params) => {
    setShowRole(params);
  };

  return (
    <RoleProvider>
      <Row className="role-row" gutter={[16, 16]}>
        <Col span={6}>
          <RoleMenu addRole={addRole} setAddNewRole={setAddNewRole} />
        </Col>
        <Col span={18}>
          {showRole === '' && addNewRole == false ? (
            <Card className="organization-default-card">
              {/* <div className="organization-default-card-content-detail">
                <div className="organization-default-content-detail-div-icon">
                  <Icon className="organization-default-card-content-icon" type="user" />
                </div>
                <div className="organization-default-content-detail-div-p">
                  <p className="organization-default-card-content-text">
                    <FormattedMessage id="UserManagementRoleTxtDefaultPage1" defaultMessage="Please select a Role" /> <br />
                    <FormattedMessage id="UserManagementRoleTxtDefaultPage2" defaultMessage="Role Information will appear here." />
                  </p>
                </div>
              </div> */}
              <NoDataBackground
                text1={{ id: 'nodataXRole1', text: 'Please select a Team' }}
                text2={{ id: 'nodataXRole2', text: 'Role will appear here.' }}
              />
            </Card>
          ) : (
            <Card className="role-card-container">
              {/* <div className="role-card-body"> */}
              <RoleContent addNewRole={addNewRole} />
              {/* <RoleMember /> */}
              {/* </div> */}
            </Card>
          )}
        </Col>
      </Row>
    </RoleProvider>
  );
};
