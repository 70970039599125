import React, { useState, useEffect } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import _, { debounce } from 'lodash';
import { Select, InputNumber } from 'antd';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import CurrencyInput from 'react-currency-input-field';
import stylesModal from './css/calculate.css';

const { Option } = Select;

const CalculateV1 = (props) => {
  const intl = useIntl();
  const { subTotalResult, discountSelete, taxSelete, taxData, discountData, valueInputDiscount, valueSubTotalResult, setValues, fnc } = props;


  const [valueDiscount, setValueDiscount] = useState("0");
  const [valueLessDiscount, setValueLessDiscount] = useState("0");
  const [valueTax, setValueTax] = useState("0");
  const [valueTotal, setValueTotal] = useState("0");


  useEffect(() => {
    fncCalculate(subTotalResult, discountSelete, taxSelete);
  }, [subTotalResult, discountSelete, taxSelete, valueInputDiscount]);

  const fncCalculate = (subTotalResult, discount, tax) => {
    let resultDiscount = ""
    if (discount.titleCode !== 'Baht') {
      resultDiscount = _.floor((parseFloat(subTotalResult) * parseFloat(discount.percentage)) / 100, 2);
    } else {
      resultDiscount = valueInputDiscount !== '' ? parseFloat(valueInputDiscount) : 0;
    }

    const resultLessDiscount = parseFloat(subTotalResult) - parseFloat(resultDiscount);
    const resultTax = _.floor((parseFloat(resultLessDiscount) * parseFloat(tax.percentage)) / 100, 2);
    const resultTotal = resultLessDiscount + resultTax;
    setValueDiscount(resultTax ? parseFloat(resultDiscount).toFixed(2) : 0);
    setValueLessDiscount(resultLessDiscount ? parseFloat(resultLessDiscount).toFixed(2) : 0);
    setValueTax(resultTax ? parseFloat(resultTax).toFixed(2) : 0);
    setValueTotal(resultTotal ?parseFloat(resultTotal).toFixed(2) : 0);
    setValues.sumCalculate({
      discount: parseFloat(resultDiscount).toFixed(2),
      lessDiscount: parseFloat(resultLessDiscount).toFixed(2),
      tax: parseFloat(resultTax).toFixed(2),
      total: parseFloat(resultTotal).toFixed(2),
    })
  }

  const handleKeyDownNumberDecimal = (e) => {
    let keyCode = e.key.charCodeAt(0);

    const splitValue = _.split(e.target.value, '.');

    if ((keyCode >= 48 && keyCode <= 57) || keyCode === 65 || keyCode === 66 || keyCode === 46) {

      if (keyCode === 46 && e.target.value.indexOf('.') > 1) {
        e.preventDefault();
      }
      if (keyCode === 46) {
        let sumDot = _.sumBy(e.target.value, (o) => { return o.charCodeAt(0) === 46 ? 1 : 0 })
        if (sumDot >= 1) {
          e.preventDefault();
        }
      }

    } else if (keyCode === 45) {
      e.preventDefault();
    } else {
      e.preventDefault();
    }

    if (_.get(splitValue, '[1]')) {
      if (_.size(splitValue[1]) >= 2 && keyCode !== 66 && e.target.selectionStart > e.target.value.indexOf('.')) {
        e.preventDefault();
      }
    }
  }

  return (
    <div className='calculate-col'>

      <div className="calculate-money">
        <div className="calculate-money-left">
          <span className="calculate-text">
            <FormattedMessage id="quotationModalLblSubtotal" defaultMessage="Subtotal" />
          </span>
        </div>
        <div className="calculate-money-right">
          <NumberFormat value={_.floor(valueSubTotalResult, 2).toFixed(2)} displayType={'text'} thousandSeparator={true}
            suffix={' ' + intl.formatMessage({ id: 'quotationModalLblBaht', defaultMessage: 'Baht' })} />
        </div>
      </div>

      <div className="calculate-money">
        <div className="calculate-money-left">
          <span className="calculate-text">
            <FormattedMessage id="quotationModalLblDiscount" defaultMessage="Discount" />
          </span>
          <Select
            className="calculate-money-discount-select"
            placeholder={intl.formatMessage({ id: 'quotationPlaceholderDiscount', defaultMessage: 'Select discount' })}
            value={discountSelete.discountId}
            onChange={fnc.onChangeDiscount}
          >
            {discountData && discountData.map(item => (
              <Option key={item.discountId} value={item.discountId}>{item.titleCode}</Option>
            ))}
          </Select>
        </div>

        <div className="calculate-money-right">
          {discountSelete && discountSelete.isBath !== true ? (
            <NumberFormat value={_.floor(valueDiscount, 2).toFixed(2)} displayType={'text'} thousandSeparator={true}
              suffix={' ' + intl.formatMessage({ id: 'quotationModalLblBaht', defaultMessage: 'Baht' })} />
          ) : (
            <div className="calculate-money-discount-div">
              <InputNumber
                className='calculate-money-discount-input'
                placeholder={intl.formatMessage({ id: 'quotationPlaceholderDiscount', defaultMessage: 'Select discount' })}
                maxLength={10}
                value={valueInputDiscount}
                onChange={(value) => fnc.onChangeInput(value)}
                onKeyDown={handleKeyDownNumberDecimal}
                formatter={value => value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                autoComplete="off"
              />
              &nbsp;
              <FormattedMessage id="quotationModalLblBaht" defaultMessage="Baht" />
            </div>
          )}
        </div>
      </div>

      <div className="calculate-money">
        <div className="calculate-money-left">
          <span className="calculate-text">
            <FormattedMessage id="quotationModalLblSubtotal Less Discount" defaultMessage="Subtotal Less Discount" />
          </span>
        </div>
        <div className="calculate-money-right">
          <NumberFormat value={_.floor(valueLessDiscount, 2).toFixed(2)} displayType={'text'} thousandSeparator={true}
            suffix={' ' + intl.formatMessage({ id: 'quotationModalLblBaht', defaultMessage: 'Baht' })} />
        </div>
      </div>

      <div className="calculate-money">
        <div className="calculate-money-left">
          <span className="calculate-text">
            <FormattedMessage id="quotationModalLblTax" defaultMessage="Tax" />
          </span>
          <Select
            className="calculate-money-discount-select"
            placeholder={intl.formatMessage({ id: 'quotationPlaceholderTax', defaultMessage: 'Select Tax' })}
            value={taxSelete.taxId}
            onChange={fnc.onChangeTax}
          >
            {taxData && taxData.map(item => (
              <Option key={item.taxId} value={item.taxId}>{item.titleCode}</Option>
            ))}
          </Select>
        </div>
        <div className="sale-oreder-calculate-money-right">
          <NumberFormat value={_.floor(valueTax, 2).toFixed(2)} displayType={'text'} thousandSeparator={true}
            suffix={' ' + intl.formatMessage({ id: 'quotationModalLblBaht', defaultMessage: 'Baht' })} />
        </div>
      </div>

      <div className="calculate-money">
        <div className="calculate-money-left">
          <span className="calculate-text">
            <FormattedMessage id="quotationModalLblTotal" defaultMessage="Total" />
          </span>
        </div>
        <div className="calculate-money-right">
          <NumberFormat value={_.floor(valueTotal, 2).toFixed(2)} displayType={'text'} thousandSeparator={true}
            suffix={' ' + intl.formatMessage({ id: 'quotationModalLblBaht', defaultMessage: 'Baht' })} />
        </div>
      </div>

    </div>
  )
}

export default CalculateV1;
