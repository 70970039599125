import httpClient from '../../components/axiosClient';

const getApiPolyline = async (fromLocation, toLocation , typeApi) => {
  const comCode = localStorage.getItem('comCode');

  try {
    const body = {
      latFrom: Number(fromLocation.lat), 
      lngFrom: Number(fromLocation.lng), 
      latTo: Number(toLocation.lat), 
      lngTo: Number(toLocation.lng),
    }

    const response = await httpClient.post(`/v3/task/manager/company/${comCode}/polyline/point-to-point?${typeApi}`, body);
    if (response.status === 200) {
      return (response.data.data);
    } else {
      return ([]);
    }
  } catch (error) {
    return ([]);
  }  
}

export default getApiPolyline;
