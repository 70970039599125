import React, { useState } from 'react';
import { Avatar, Icon, Popover, Button } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import stylePost from './css/post.css';

export default (props) => {
  const intl = useIntl();
  const { postData, handleEditPost, handleDeletePost } = props;
  const [visiblePopover, setVisiblePopover] = useState(false);

  const handleOnVisiblePopover = (visiblePopover) => {
    setVisiblePopover(visiblePopover);
  }

  return (
    <div className='post-header'>
      <div className='post-card-head'>
        <div className='post-card-head-left'>
          <div className='post-card-head-left-avatar'>
            <Avatar size={52} src={`${postData.pathImage}`} />
          </div>
          <div className='post-card-left-text'>
            <p className='post-card-left-text-head'>{`${postData.fullname}`}</p>
            <div className='post-card-head-left-text'>
              {/* {postData && postData.audience === 'public' ? 
                (
                  <Icon type='global' style={{ fontSize: '12px' }} />
                ) : postData.audience === 'only_me' ? 
                (
                  <Icon type='lock' style={{ fontSize: '12px' }} />
                ) : (
                  <Icon type='team' style={{ fontSize: '12px' }} />
                )
              } */}
              {/* <p className='post-card-left-text-dot'>.</p> */}
              <p className='post-card-text-12'>
                {intl.formatMessage({ id: 'engagementPostTxtPostedBy', defaultMessage: 'Posted by' })} {`${postData.postedBy}`} {intl.formatMessage({ id: 'engagementPostTxtOn', defaultMessage: 'on' })}{' '}
                {`${postData.postedAt}`}
              </p>
            </div>
          </div>
        </div>
        <div className='post-card-head-right'>
          {postData && postData.actions.length > 0 ? (
            <Popover
              key={1}
              placement='leftTop'
              trigger='hover'
              visiblePopover={visiblePopover}
              onVisibleChange={handleOnVisiblePopover}
              zIndex={999}
              content={
                <div style={{ display: 'grid' }}>
                  {postData && postData.actions.includes('edit') ? (
                    <Button
                      style={{ width: 100 }}
                      type='link'
                      ghost
                      onClick={() => handleEditPost(postData)}
                    >
                      <FormattedMessage id='btnEdit' defaultMessage='Edit' />
                    </Button>
                  ) : null}
                  {postData && postData.actions.includes('delete') ? (
                    <Button
                      style={{ width: 100 }}
                      type='link'
                      ghost
                      onClick={() => handleDeletePost(postData)}
                    >
                      <FormattedMessage id='btnDelete' defaultMessage='Delete' />
                    </Button>
                  ) : null}
                </div>
              }
            >
              <Icon type='more' />
            </Popover>
          ) : null}
        </div>
      </div>
    </div>
  );
};
