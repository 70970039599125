import React, { useEffect, useState } from 'react';
import _ from 'lodash';
export default () => {

  const pathName = window.location.pathname
  const [pathURL, setPathURL] = useState(false)

  useEffect(() => {
    const myTextSplit = pathName.includes("/webview")
    const myTextSplitOrder = pathName.includes("/order/view")
    if (myTextSplit) {
      setPathURL(myTextSplit)
    } else if (myTextSplitOrder) {
      setPathURL(myTextSplitOrder)
    }
  }, [pathName])

  return (
    <div>
      {
        pathURL === false ? (
          <div style={{
            position: 'fixed',
            right: 5,
            bottom: 5,
            zIndex: 1,
            opacity: 0.3,
            cursor: 'default'
          }}>
            {process.env.REACT_APP_VERSIONS || ''}
          </div>
        ) : null
      }
    </div>

  );
}