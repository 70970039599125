import React, { useState, useEffect } from 'react';
import { Card, Icon, Modal, Button, Popover, Divider, Col, Row } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import { PageSettings } from '../../../../config/page-settings';
import getTeamList from '../../../../controllers/team/get-team-list';
import getTeamPosition from '../../../../controllers/team/get-team-position';

import pathUrt from '../../../../function/pathUrl';
import Button01 from '../../../../components/v2/button_01';
import Button02 from '../../../../components/v2/button_02';
import AssignTeamModal from './assign-team-modal';
import clientNew from '../../../../components/axiosClient';

import { successNotification, errorNotification } from '../../../../components/v2/notification';
import './css/team.css';

const Team = (props) => {
  const intl = useIntl();
  let checkPathUrl = pathUrt('/user-management/my-profile');
  let userId = props.createdBy;

  // console.log('team props::: ', props);

  const [teamData, setTeamData] = useState([]);
  const [positionMasterData, setPositionMasterData] = useState([]);
  const [teamUpdateData, setTeamUpdateData] = useState({});
  const [isOpenAssign, setIsOpenAssign] = useState(false);
  const [triggerApi, setTriggerApi] = useState(false);
  const [visiblePopover, setVisiblePopover] = useState(false);
  const [statusOpen, setStatusOpen] = useState();

  useEffect(() => {
    const getTeamListApi = async () => {
      if (userId) {
        const responseTeamList = await getTeamList(userId);
        setTeamData(responseTeamList.data.team);
      }
    };

    const getTeamPositionApi = async () => {
      if (userId) {
        const responseTeamPosition = await getTeamPosition(userId);
        setPositionMasterData(responseTeamPosition.data.positionMaster);
      }
    };
    getTeamPositionApi();
    getTeamListApi();
  }, [triggerApi]);

  const Delete = async (id, index) => {
    const del = await clientNew
      .delete(`/v2/team/${userId}/${id}`)
      .then((response) => {
        if (response.status === 200) {
          successNotification(response.data.data);
          setTriggerApi((current) => !current);
        }
      })
      .catch((error) => {
        errorNotification(error.response.data.data);
      });
  };

  const handleOnVisiblePopover = (visiblePopover) => {
    setVisiblePopover(visiblePopover);
  };

  const handlerDelete = async (id, index) => {
    Modal.confirm({
      className: 'team-modal-confirm',
      title: intl.formatMessage({ id: 'lblDelete', defaultMessage: 'Are you sure you want to delete ?' }),
      okText: intl.formatMessage({ id: 'modalConfirmBtnYes', defaultMessage: 'Yes' }),
      cancelText: intl.formatMessage({ id: 'modalConfirmBtnNo', defaultMessage: 'No' }),
      okType: 'danger',
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'dashed',
      },
      onOk() {
        Delete(id, index);
      },
    });
  };

  const handleAssignTeam = () => {
    setTeamUpdateData({});
    setIsOpenAssign(true);
    setStatusOpen('add');
  };

  const handleEditTeam = (item) => {
    setTeamUpdateData(item);
    setIsOpenAssign(true);
    setStatusOpen('edit');
  };

  const fncActionTable = (item, index) => {
    return (
      <div>
        <Popover
          key={index}
          placement="leftTop"
          trigger="hover"
          visiblePopover={visiblePopover}
          onVisibleChange={handleOnVisiblePopover}
          zIndex={999}
          content={
            <div style={{ display: 'grid' }}>
              <PageSettings.Consumer>
                {({ checkPermissionAction }) => (
                  <div>
                    {checkPermissionAction('P2S1PG2C4', 'P2S1PG2C4A6') ? (
                      checkPathUrl === true ? null : (
                        <Button style={{ width: 100 }} type="link" ghost onClick={() => handleEditTeam(item)}>
                          <FormattedMessage id="btnEdit" defaultMessage="Edit" />
                        </Button>
                      )
                    ) : null}
                  </div>
                )}
              </PageSettings.Consumer>

              <PageSettings.Consumer>
                {({ checkPermissionAction }) => (
                  <div>
                    {checkPermissionAction('P2S1PG2C4', 'P2S1PG2C4A3') ? (
                      checkPathUrl === true ? null : (
                        <Button style={{ width: 100 }} type="link" ghost onClick={() => handlerDelete(item.memOrgId)}>
                          <FormattedMessage id="btnDelete" defaultMessage="Delete" />
                        </Button>
                      )
                    ) : null}
                  </div>
                )}
              </PageSettings.Consumer>
            </div>
          }
        >
          <Icon type="more" />
        </Popover>
      </div>
    );
  };

  return (
    <div className="team">
      <Card
        // className='team-card'
        title={<FormattedMessage id="userMgntUsersDetailTitleTeam" defaultMessage="Team" />}
        extra={
          <PageSettings.Consumer>
            {({ checkPermissionAction }) => (
              <div>
                {checkPermissionAction('P2S1PG2C4', 'P2S1PG2C4A2') ? (
                  checkPathUrl === true ? null : (
                    <Button01 key="submit" type="primary" btnsize="wd_df" onClick={() => handleAssignTeam()} disabled={teamData && teamData.length > 0? true : false}>
                      <FormattedMessage id="userMgntUsersDetailBtnAssignNew" defaultMessage="Assign" />
                    </Button01>
                  )
                ) : null}
              </div>
            )}
          </PageSettings.Consumer>
        }
      >
        {teamData && teamData.length > 0 ? (
          teamData.map((item, index) => (
            // <div className='team-box'>
            //   <div className='team-container'>
            //     <div className='team-content'>
            //       {/* <div className='team-content-text'> */}
            //         {item.orgName}{item.positionId ? (
            //           <div>&nbsp;·&nbsp;{item.positionName}</div>
            //         ) : null}
            //       {/* </div> */}

            //       {/* <div className='team-content-text'>
            //         <span>{item.orgName}</span>
            //         {item.positionId ? (
            //           <span className='team-content-text-right'>&nbsp;·&nbsp;{item.positionName}</span>
            //         ) : null}
            //       </div> */}

            //     </div>
            //     <div className='team-content'>
            //       <div style={{ display: 'initial' }}>
            //         {fncActionTable(item, index)}
            //       </div>
            //     </div>
            //   </div>
            //   <Divider className='team-content-divider'/>
            //  </div>

            <div>
              <Row>
                <Col span={20} className="team-content-left">
                  {/* <span>{item.orgName}{item.positionId ? (
                  ` · ${item.positionName}`
                  ) : null} 
                </span> */}
                  <div className="team-content-left-text">
                    {item.positionId ? `${item.orgName} · ${item.positionName}` : `${item.orgName}`}
                  </div>
                  {/* <div>
                  This is an example of a multi-line ellipsis. We just set the number of lines we want to display before the ellipsis takes into effect and make some changes to the CSS and the ellipsis should take into effect once we reach the number of lines we want.
                </div> */}
                </Col>

                <PageSettings.Consumer>
                  {({ checkPermissionAction }) => (
                    <Col span={4} className="team-content-right">
                      <div style={{ display: 'initial' }}>
                        {checkPermissionAction('P2S1PG2C4', 'P2S1PG2C4A3') || checkPermissionAction('P2S1PG2C4', 'P2S1PG2C4A6')
                          ? checkPathUrl === true
                            ? null
                            : fncActionTable(item, index)
                          : null}
                      </div>
                    </Col>
                  )}
                </PageSettings.Consumer>

                {/* <Col span={4} className='team-content-right'>
                <div style={{ display: 'initial' }}>
                  {fncActionTable(item, index)}
                </div>
              </Col> */}
              </Row>
              <Divider className="team-content-divider" />
            </div>
          ))
        ) : (
          <div>
            <FormattedMessage id="userMgntUsersDetailLblTeamTxt" defaultMessage="Please assign to team" />
          </div>
        )}
      </Card>
      <AssignTeamModal
        userId={userId}
        isOpenAssign={isOpenAssign}
        setIsOpenAssign={setIsOpenAssign}
        setTriggerApi={setTriggerApi}
        teamUpdateData={teamUpdateData}
        statusOpen={statusOpen}
        permissions={{
          team: 'P2S1PG2C4',
          edit: 'P2S1PG2C4A5',
          addPosition: 'P2S1PG2C4A4',
        }}
      />
    </div>
  );
};

export default Team;
