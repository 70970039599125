import React, { useState, useEffect, useCallback } from 'react';
import { ShiftSettings } from "./config/shift-type-settings";
import { PageSettings } from '../../config/page-settings';
import Shifttype from './shift-type/shift-type';
import httpClient from '../../components/axiosClient';
import { Card, notification, ConfigProvider } from 'antd';
import { useAppContext } from '../../includes/indexProvider';
import enUS from 'antd/es/locale/en_US';
import thTH from 'antd/es/locale/th_TH';
import 'moment/locale/th';
import moment from 'moment';
import _ from 'lodash';

export default (props) => {

  const app = useAppContext();

  const langValue = app.state.langValue;
  const [locale, setLocale] = useState(enUS);

  const changeLocale = () => {
    let codeLang = langValue ? langValue : 'EN';
    let changeLang = codeLang === 'EN' ? enUS : thTH;
    setLocale(changeLang);
    if (!changeLang) {
      moment.locale('en');
    } else {
      moment.locale('th-th');
    }
  };

  const pageCode = localStorage.getItem('pageCode');
  const [labelShow, setLabelShow] = useState({});
  const { children } = props;

  const [formRef, setFormRef] = useState(null);
  const [visible, setVisible] = useState(false);
  const [visibleAdd, setVisibleAdd] = useState(false);
  const [visibleEdit, setVisibleEdit] = useState(false);


  const [dataShiftType, setDataShiftType] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10)
  const [sortColumn, setSortColumn] = useState('name');
  const [sortOrder, setSortOrder] = useState('ascend');

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [shiftTypesId, setShiftTypesId] = useState();
  const [startTime, setStart] = useState();
  const [endTime, setEndTime] = useState();
  const [timeArr, setTimeArr] = useState([{}]);


  const [shiftTypes, setShiftTypes] = useState();
  const [shiftTypesColor, setShiftTypesColor] = useState("");
  const [shiftTypesIcon, setShiftTypesIcon] = useState([]);

  const [idItem, setIdItem] = useState();
  const [idcolor, setIdColor] = useState("");


  const comId = localStorage.getItem('comId');
  const memComId = localStorage.getItem('memComId');

  const [selectSearch, setSelectSearch] = useState("");
  const [fieldChange, setFieldChange] = useState("");

  const [refreshTable, setRefreshTable] = useState(false);
  const [visiblePopoverEdit, setVisiblePopoverEdit] = useState(false);
  const [formData, setFormData] = useState();
  const [where, setWhere] = useState({});


  useEffect(() => {
    loadShiftTypes();
    itemData();
    changeLocale();
  }, [sortColumn, sortOrder, page, pageSize, refreshTable, fieldChange, where]);

  const loadShiftTypes = async () => {
    setLoading(true);

    const body = {
      where: where,
      page: page,
      pageSize: pageSize,
      sortField: sortColumn ? sortColumn : 'name',
      sortOrder: sortOrder === 'descend' ? 'desc' : 'asc',
    };

    try {
      const result = await httpClient.post(`/resource/manager/company/${comId}/shift-type-list`, body)
      if (result.status === 200) {
        setTotal(result.data.count);
        setDataShiftType(result.data.rows);
        setLoading(false);
      }
    } catch (error) {
      return
    }
  };

  const itemData = async () => {
    try {
      const result = await httpClient.get(`/resource/manager/company/${comId}/shift-type-config`,);
      if (result.status === 200) {
        setShiftTypes(result.data.shiftTypes);
        setShiftTypesColor(result.data.shiftTypesColor);
        setShiftTypesIcon(result.data.shiftTypesIcon);
      }
    } catch { }
  }

  const onCreate = async () => {
    formRef.validateFields(async (err, values) => {
      if (err) {
        return;
      }

      const body = {
        name: values.name,
        description: values.description,
        start: "08:00:00",
        finish: "17:00:00",
        codeRef: "",
        iconCode: idItem,
        color: values.color,
        shiftType: memComId,
        shiftTypesId: values.shiftType,
        createdBy: 1,
        createAt: "2020-10-26 16:00:00",
        timePeriod: [
          {
            startTime: timeArr[0].startTime,
            endTime: timeArr[0].endTime,
          }
        ]
      };

      try {
        const result = await httpClient.post(`/resource/manager/company/${comId}/shift-master`, body);
        if (result.status === 200) {
          successNotification(result.statusText);
        } else {
          failedNotification(result.statusText);
        }
      } catch {
        console.error();
      }
      refreshDataServices();
      formRef.resetFields();
      setVisible(false);
    });
  };

  const handleDelete = async (shiftMasterId) => {
    try {
      const res = await httpClient.delete(`/resource/manager/company/${comId}/shift-master/${shiftMasterId}`);
      if (res.status === 200) {
        successNotification(res.data.message);
        setTimeout(() => {
          refreshDataServices();
        }, 500);
      } else {
        failedNotification(res.data.message);
      }
    }
    catch (error) {
      if (error) {
        failedNotification(error.res.data.message);
      }
    }
  }

  const onCancel = (e) => {
    formRef.validateFields(async (err, values) => {
      if (err) {
        return;
      }
      formRef.resetFields();
    });
    formRef.resetFields();
    setVisible(false);
  };

  const onCancelAdd = (e) => {
    formRef.validateFields(async (err, values) => {
      if (err) {
        return;
      }
      formRef.resetFields();
    });
    formRef.resetFields();
    setVisibleAdd(false);
  };

  const onCancelEdit = (e) => {
    formRef.validateFields(async (err, values) => {
      if (err) {
        return;
      }
      formRef.resetFields();
    });
    formRef.resetFields();
    setVisibleEdit(false);
  };

  const refreshDataServices = () => {
    setRefreshTable(!refreshTable);
  }

  const saveFormRef = useCallback((node) => {
    if (node !== null) {
      setFormRef(node);
    }
  }, []);

  const saveFormRefEdit = useCallback((node) => {
    if (node !== null) {
      setFormRef(node);
    }
  }, []);


  const successNotification = (message) => {
    notification.success({
      message: message
    })
  }

  const failedNotification = (message) => {
    notification.error({
      message: message
    })
  }

  const hidePopoverEdit = (e) => {
    e.preventDefault();
    setVisiblePopoverEdit(false);
  }

  return (
    <PageSettings.Consumer>
      {({ checkPermissionAction }) => (
        <ConfigProvider locale={locale}>
          <ShiftSettings.Provider
            value={{
              state: {
                total,
                dataShiftType,
                page,
                pageSize,
                sortColumn,
                sortOrder,
                formRef,
                visible,
                visibleAdd,
                visibleEdit,
                shiftTypes,
                shiftTypesColor,
                shiftTypesIcon,
                name,
                description,
                shiftTypesId,
                startTime,
                endTime,
                idItem,
                timeArr,
                fieldChange,
                selectSearch,
                idcolor,
                visiblePopoverEdit,
                formData,
                labelShow
              },
              fnc: {
                setPage,
                setPageSize,
                setSortColumn,
                setSortOrder,
                setTotal,
                setDataShiftType,
                setFormRef,
                setVisible,
                onCreate,
                setShiftTypes,
                setShiftTypesColor,
                setShiftTypesIcon,
                saveFormRef,
                saveFormRefEdit,
                setIdItem,
                setTimeArr,
                setFieldChange,
                setSelectSearch,
                onCancel,
                handleDelete,
                setIdColor,
                setVisibleAdd,
                setVisibleEdit,
                hidePopoverEdit,
                setVisiblePopoverEdit,
                onCancelAdd,
                onCancelEdit,
                setFormData,
                refreshDataServices,
                successNotification,
                failedNotification,
                setWhere,
                setLabelShow
              }
            }}
          >
            {checkPermissionAction('P6S2PG1C1', 'P6S2PG1C1A1') ? <Shifttype /> : null}
          </ShiftSettings.Provider>
        </ConfigProvider>
      )}
    </PageSettings.Consumer>
  )
}

