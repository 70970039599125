import React, { useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.css';

import { Table, Divider, Tag, Button, Popover, Modal } from 'antd';
import _ from 'lodash';
import { useIntl, FormattedMessage } from 'react-intl';
import './css/index.css';
import Button_01 from '../../../components/v2/button_01';
import Button_02 from '../../../components/v2/button_02';
import { hover } from 'glamor';

const AssetPreventiveTable = (props) => {
  const { cencelPreventiveApi } = props;
  // const dataSource = _.get(props.pmlist, 'pmList');
  const dataSource = []
  const columns = _.get(props, 'columns') || [];
  const isLoading = _.get(props, 'isLoading');
  const page = _.get(props, 'page');
  // const page = props.page;
  const handleActionViewEdit = _.get(props, 'handleActionViewEdit');
  // const totalItems = _.get(props.pmlist, 'totalRows') || 0;
  const totalItems = 0;
  // const totalItems = props.size || 0;
  const intl = useIntl();

  const [visiblePopoverTable, setVisiblePopoverTable] = useState();
  const [recordPopover, setRecordPopover] = useState();
  const [offsetPopover, setOffsetPopover] = useState([0, 0]);
  const ref = useRef();

  console.log('FillterPM', props.pmlist);

  const fncActionTable = (record, offsetPopover, visiblePopoverTable, ref) => {
    return (
      <div>
        {console.log('recordPM', _.get(record, 'assetPmId'))}
        <Popover
          placement="right"
          trigger="hover"
          align={{ offset: offsetPopover }}
          visible={visiblePopoverTable}
          zIndex={999}
          content={
            <div style={{ display: 'grid' }}>
              {record !== undefined ? (
                <>
                  {record.cancelThisPm === true && (
                    <>
                      <Button style={{ width: 150 }} type="link" ghost onClick={() => handleCancel('1', _.get(record, 'assetPmId'))}>
                        <FormattedMessage id="AssetPMTableOne" defaultMessage="Cancel This PM" />
                      </Button>
                    </>
                  )}
                  {record.cancelThisPmAndAllNext === true && (
                    <>
                      <Button style={{ width: 260 }} type="link" ghost onClick={() => handleCancel('2', _.get(record, 'assetPmId'))}>
                        <FormattedMessage id="AssetPMTableOneMore" defaultMessage="Cancel This PM and All Next Round" />
                      </Button>
                    </>
                  )}
                </>
              ) : null}
            </div>
          }
        >
          <div style={{ width: 0, height: 0 }} ref={ref}></div>
        </Popover>
      </div>
    );
  };

  const showTotal = () => {
    return (
      <span style={{ fontSize: '13px' }}>
        <FormattedMessage id="lblRowPerPage" defaultMessage="Rows per page: " />
      </span>
    );
  };

  const handleMouseLeave = () => {
    setVisiblePopoverTable(false);
    setRecordPopover();
    setOffsetPopover([0, 0]);
  };

  const handleCancel = (type, id) => {
    Modal.confirm({
      className: 'maintenance-report-modal-confirm',
      centered: true,
      icon: null,
      title:
        type === '1'
          ? intl.formatMessage({ id: 'PMDelete', defaultMessage: 'Are you sure to cancel this PM  ?' })
          : intl.formatMessage({ id: 'PMDeleteAll', defaultMessage: 'Are you sure to cancel this PM and all next round ?  ?' }),
      okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
          background: '#6490cf',
          border: '1px solid #6490cf',
          borderRadius: '8px',
          hover: {
            background: '#ffffff',
            color: '#6490cf',
          },
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
          background: '#6490cf',
          border: '1px solid #6490cf',
          borderRadius: '8px',
        },
        type: 'danger',
      },
      async onOk() {
        cencelPreventiveApi(id, type === '2' ? 'false' : 'true');
      },
      onCancel() {},
    });
  };

  return (
    <div onMouseLeave={handleMouseLeave}>
      <Table
        className="custom-table-claim"
        // dataSource={props.filterPM.length !== 0 ? props.filterPM : dataSource}
        dataSource={[]}
        columns={columns || []}
        loading={isLoading}
        scroll={{ x: 1300 }}
        pagination={{
          total: totalItems,
          // current: page,
          defaultCurrent: 1,
          pageSizeOptions: ['10', '20', '30', '40', '50'],
          showSizeChanger: true,
          locale: { items_per_page: '' },
          showTotal: showTotal,
          onChange: props.handlePrePage,
          onShowSizeChange: props.handlePreSize,
        }}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              if (!event.target.href) {
                const { x, y } = ref.current.getBoundingClientRect();
                setVisiblePopoverTable(true);
                setOffsetPopover([event.pageX - x, event.pageY - y]);
                setRecordPopover(record);
              }
            },
          };
        }}
      />

      {fncActionTable(recordPopover, offsetPopover, visiblePopoverTable, ref)}

      {/* <div className="total-items-member">
        <span style={{ fontSize: '13px' }}>
          <FormattedMessage id="lblTotal" defaultMessage="Total" />
          {` ${totalItems || 0} `}
          <FormattedMessage id="lblitems" defaultMessage="items" />
        </span>
      </div> */}
    </div>
  );
};
export default AssetPreventiveTable;
