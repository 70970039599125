import React, { useState, useEffect } from 'react';
import { Modal, Form, Spin } from 'antd';
import Content from './content';
import ContentView from './content-view';
import './css/index.css';
import Button01 from '../../v2/button_01';
import Button02 from '../../v2/button_02';
import { FormattedMessage, useIntl } from 'react-intl';
import AddBusinessPartner from '../../add-business-partner/index';
import { editOrder } from '../../../controllers/orders-controller/api';
import _ from 'lodash';
import moment from 'moment';
import { successNotification, errorNotification } from '../../v2/notification';
import AddFromLocation from '../../from-location/add/index';
import EditFromLocation from '../../from-location/edit/index';
import AddAddressBookV2 from '../../modal-create-adress-map-v5/index';
import EditAddressBook from '../../modal-edit-adress-map-v5/index';
import { PageSettings } from '../../../config/page-settings';

const ModalOrderCreate = (props) => {
  const intl = useIntl();
  const { visible, setVisible, form, setTriggerModal, editData, statusModal, setStatusModal, handleDelete } = props;
  const { validateFields, resetFields } = form;
  const [visibleAddCustomer, setVisibleAddCustomer] = useState(false);
  const [visibleAddFromLocation, setVisibleAddFromLocation] = useState(false);
  const [visibleAddToLocation, setVisibleAddToLocation] = useState(false);
  const [visibleEditFromLocation, setVisibleEditFromLocation] = useState(false);
  const [visibleEditToLocation, setVisibleEditToLocation] = useState(false);
  const [visibleContact, setVisibleContact] = useState(false);
  const [editFromLocationData, setEditFromLocationData] = useState();
  const [editToLocationData, setEditToLocationData] = useState();
  const [updateEditFromLocation, setUpdateEditFromLocation] = useState();
  const [updateEditToLocation, setUpdateEditToLocation] = useState();
  const [triggerEditLocation, setTriggerEditLocation] = useState(false);
  const [newCustomer, setNewCustomer] = useState();
  const [newFromLocation, setNewFromLocation] = useState();
  const [newToLocation, setNewToLocation] = useState();
  const [newContact, setNewContact] = useState();
  const [initialCustomerList, setInitialCustomerList] = useState([]);
  const [initialItemTypeList, setInitialItemTypeList] = useState([]);
  const [initialDestinationList, setInitialDestinationList] = useState([]);
  const [initialSourceList, setInitialSourceList] = useState([]);
  const [contactData, setContactData] = useState([]);
  const [statusData, setStatusData] = useState([]);
  const [triggerContact, setTriggerContact] = useState(false);
  const [temporaryData, setTemporaryData] = useState([]);
  const [statusSave, setStatusSave] = useState();
  const [statusEdit, setStatusEdit] = useState('view');
  const [spinner, setSpinner] = useState(false);
  const [customerSearch, setCustomerSearch] = useState();
  const [itemTypeSearch, setItemTypeSearch] = useState();
  const [destinationSearch, setDestinationSearch] = useState();
  const [sourceSearch, setSourceSearch] = useState();
  const [customerPage, setCustomerPage] = useState(0);
  const [customerSearchPage, setCustomerSearchPage] = useState(0);
  const [itemTypePage, setItemTypePage] = useState(0);
  const [itemTypeSearchPage, setItemTypeSearchPage] = useState(0);
  const [destinationPage, setDestinationPage] = useState(0);
  const [destinationSearchPage, setDestinationSearchPage] = useState(0);
  const [sourcePage, setSourcePage] = useState(0);
  const [sourceSearchPage, setSourceSearchPage] = useState(0);
  const [sizeList, setSizeList] = useState([]);
  const [referenceTypeList, setReferenceTypeList] = useState([]);

  console.log('initialDestinationList', editData);

  useEffect(() => {
    if (statusModal === 'edit') {
      setSpinner(true);
      setTimeout(() => {
        setSpinner(false);
      }, 1000);
    }
  }, [statusModal]);

  const handleSave = () => {
    validateFields(async (err, values) => {
      // console.log('values ::', values)
      if (err) {
        return;
      }

      const filterCustomer =
        initialCustomerList &&
        initialCustomerList.filter((item) => {
          return item.customerId === values.customer;
        });
      const filterCustomerCheck = {
        ..._.get(filterCustomer, '[0]'),
        customerId: _.get(filterCustomer, '[0].customer_temp') ? undefined : _.get(filterCustomer, '[0].customerId'),
        customer_temp: undefined,
      };

      const filterStatus =
        statusData &&
        statusData.filter((item) => {
          return item.statusId === values.status;
        });
      // const filterItemType = initialItemTypeList && initialItemTypeList.filter(item => { return item.item_type_id === values.itemType })
      const filterDestination =
        initialDestinationList &&
        initialDestinationList.filter((item) => {
          return item.customer_address_book_id === values.destination;
        });
      const filterDestinationCheck = {
        ..._.get(filterDestination, '[0]'),
        customer_address_book_id: _.get(filterDestination, '[0].customer_temp')
          ? undefined
          : _.get(filterDestination, '[0].customer_address_book_id'),
        customer_temp: undefined,
      };
      // if (_.get(filterDestination, '[0].customer_temp')) setInitialDestinationList(_.filter(initialDestinationList, (item) => { return item.customer_temp !== true }))

      const filterSource =
        initialSourceList &&
        initialSourceList.filter((item) => {
          return item.company_address_book_id === values.source;
        });
      const filterSourceCheck = {
        ..._.get(filterSource, '[0]'),
        company_address_book_id: _.get(filterSource, '[0].company_temp') ? undefined : _.get(filterSource, '[0].company_address_book_id'),
        company_temp: undefined,
      };

      const filterContact =
        contactData &&
        contactData.filter((item) => {
          return item.customerContactId === values.contact;
        });
      const filterSize =
        sizeList &&
        sizeList.filter((item) => {
          return item.sizeId === values.size;
        });
      const filterRef =
        referenceTypeList &&
        referenceTypeList.filter((item) => {
          return item.refTypeId === values.referenceType;
        });

      const contactDetail = {
        customerContactEmail: _.get(filterContact, '[0].customerContactEmail'),
        customerContactId:
          _.get(filterContact, '[0].customerContactId') !== 'temporary' ? _.get(filterContact, '[0].customerContactId') : undefined,
        customerContactName: _.get(filterContact, '[0].customerContactName'),
        customerContactPhone: _.get(filterContact, '[0].customerContactPhone'),
        position: _.get(filterContact, '[0].position'),
        remark: _.get(filterContact, '[0].remark'),
      };

      const body = {
        customerDetail: filterCustomerCheck,
        statusDetail: _.get(filterStatus, '[0]'),
        // itemTypeDetail: _.get(filterItemType, '[0]'),
        trips: values.trips,
        destinationDetail: filterDestinationCheck,
        sourceDetail: filterSourceCheck,
        deliveryDate: values.deliveryDate ? moment(values.deliveryDate).format('YYYY-MM-DD') : undefined,
        deliveryTime: values.deliveryTime,
        contactDetail: contactDetail,
        orderNo: undefined,
        remark: values.remark,
        size: _.get(filterSize, '[0]'),
        weight: values.weight,
        referenceType: _.get(filterRef, '[0]'),
        referenceNo: values.referenceNo,
      };
      try {
        const response = await editOrder(body, _.get(editData, 'orderId'));
        if (response.status.code === 200) {
          successNotification(response.status.message);
          setVisible(false);
          setStatusEdit('view');
          setStatusModal('view');
          setTriggerModal((current) => !current);
          resetFields();
          setStatusSave();
          setTemporaryData([]);
          setInitialCustomerList([]);
          setInitialItemTypeList([]);
          setInitialDestinationList([]);
          setCustomerSearch();
          setItemTypeSearch();
          setDestinationSearch();
          setSourceSearch();
          setCustomerPage(0);
          setCustomerSearchPage(0);
          setItemTypePage(0);
          setItemTypeSearchPage(0);
          setDestinationPage(0);
          setDestinationSearchPage(0);
          setSourcePage(0);
          setSourceSearchPage(0);
        } else {
          errorNotification(response.status.message);
        }
      } catch (error) {
        errorNotification(error.response.status.message);
      }
    });
  };

  const handleClose = () => {
    setVisible(false);
    setStatusEdit('view');
    setStatusModal('view');
    resetFields();
    setStatusSave();
    setTemporaryData([]);
    setInitialCustomerList([]);
    setInitialItemTypeList([]);
    setInitialDestinationList([]);
    setCustomerSearch();
    setItemTypeSearch();
    setDestinationSearch();
    setSourceSearch();
    setCustomerPage(0);
    setCustomerSearchPage(0);
    setItemTypePage(0);
    setItemTypeSearchPage(0);
    setDestinationPage(0);
    setDestinationSearchPage(0);
    setSourcePage(0);
    setSourceSearchPage(0);
  };

  const handleCancel = () => {
    setStatusEdit('view');
  };

  const handleEditFromLocation = (data) => {
    const dataDefault = {
      address: data.description,
      lat: parseFloat(data.lat),
      lng: parseFloat(data.lng),
      addressBookId: data.company_address_book_id,
      name: data.address_name,
    };
    setEditFromLocationData(dataDefault);
    setVisibleEditFromLocation(true);
  };

  const handleEditToLocation = (data) => {
    const dataDefault = [
      {
        address: data.address,
        lat: parseFloat(data.lat),
        lng: parseFloat(data.lng),
        addressBookId: data.customer_address_book_id,
        name: data.address_name,
      },
    ];
    setEditToLocationData(dataDefault);
    setVisibleEditToLocation(true);
  };

  // console.log('editData :::', editData)

  return (
    <Modal
      title={
        statusModal === 'view' && statusEdit === 'view'
          ? intl.formatMessage({ id: 'modalOrderViewOrder', defaultMessage: 'View Order' })
          : intl.formatMessage({ id: 'modalOrderEditOrder', defaultMessage: 'Edit Order' })
      }
      visible={visible}
      onCancel={handleClose}
      width={580}
      bodyStyle={{ padding: 'unset' }}
      centered={true}
      footer={
        statusModal === 'view'
          ? statusEdit === 'view'
            ? [
                <Button02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleClose}>
                  <FormattedMessage id="btnClose" defaultMessage="Close" />
                </Button02>,
                // _.get(editData, 'statusDetail.statusCode') !== "confirmed" && _.get(editData, 'statusDetail.statusCode') !== 'canceled' ? (
                //   < Button01
                //     key='submit'
                //     type='primary'
                //     btnsize='wd_df'
                //     onClick={() => {
                //       setSpinner(true)
                //       setStatusEdit('edit')
                //       setTimeout(() => {
                //         setSpinner(false)
                //       }, 1000);
                //     }}
                //   >
                //     <FormattedMessage id='btnEdit' defaultMessage='Edit' />
                //   </Button01 >
                // ) : null

                <PageSettings.Consumer>
                  {({ checkPermissionAction }) =>
                    checkPermissionAction('P38PG1C1', 'P38PG1C1A5') ? (
                      
                      <Button01
                        style={{ margin: '0px 0px 0px 10px' }}
                        type="primary"
                        btnwidth="wd_at"
                        onClick={() => handleDelete(editData, 'delete')}
                        disabled={_.get(editData, 'canDeleteOrder') === false  ? true : false}
                      >
                        <FormattedMessage id="btnDelete" defaultMessage="Delete" />
                      </Button01>
                    ) : null
                  }
                </PageSettings.Consumer>,

                _.get(editData, 'canEditInfomation') === false && _.get(editData, 'canEditStatus') === false ? null : (
                  <Button01
                    key="submit"
                    type="primary"
                    btnsize="wd_df"
                    onClick={() => {
                      setSpinner(true);
                      setStatusEdit('edit');
                      setTimeout(() => {
                        setSpinner(false);
                      }, 1000);
                    }}
                  >
                    <FormattedMessage id="btnEdit" defaultMessage="Edit" />
                  </Button01>
                ),
              ]
            : [
                <Button02
                  style={{ margin: '0px 0px 0px 10px' }}
                  key="back"
                  btnsize="wd_df"
                  onClick={handleCancel}
                  //  disabled={disbleButton}
                >
                  <FormattedMessage id="userDetailModalBtnCancel" defaultMessage="Cancel" />
                </Button02>,
                <Button01
                  key="submit"
                  type="primary"
                  btnsize="wd_df"
                  onClick={handleSave}
                  // disabled={disbleButton}
                >
                  <FormattedMessage id="userDetailModalBtnSave" defaultMessage="Save" />
                </Button01>,
              ]
          : [
              <div>
                <Button02 style={{ margin: '0px 0px 0px 10px' }} btnwidth="wd_at" onClick={handleClose}>
                  <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
                </Button02>
                <Button01 type="primary" btnwidth="wd_at" onClick={handleSave}>
                  <FormattedMessage id="btnSave" defaultMessage="Save" />
                </Button01>
              </div>,
            ]
      }
    >
      {statusModal === 'view' && statusEdit === 'view' ? (
        <ContentView editData={editData} />
      ) : (
        <Spin spinning={spinner}>
          <Content
            form={form}
            setVisibleAddCustomer={setVisibleAddCustomer}
            newCustomer={newCustomer}
            initialCustomerList={initialCustomerList}
            setInitialCustomerList={setInitialCustomerList}
            initialItemTypeList={initialItemTypeList}
            setInitialItemTypeList={setInitialItemTypeList}
            initialDestinationList={initialDestinationList}
            setInitialDestinationList={setInitialDestinationList}
            initialSourceList={initialSourceList}
            setInitialSourceList={setInitialSourceList}
            contactData={contactData}
            setContactData={setContactData}
            setStatusData={setStatusData}
            statusData={statusData}
            setVisibleAddFromLocation={setVisibleAddFromLocation}
            newFromLocation={newFromLocation}
            handleEditFromLocation={handleEditFromLocation}
            triggerEditLocation={triggerEditLocation}
            updateEditFromLocation={updateEditFromLocation}
            setNewFromLocation={setNewFromLocation}
            setUpdateEditFromLocation={setUpdateEditFromLocation}
            setVisibleAddToLocation={setVisibleAddToLocation}
            newToLocation={newToLocation}
            setNewToLocation={setNewToLocation}
            handleEditToLocation={handleEditToLocation}
            updateEditToLocation={updateEditToLocation}
            setUpdateEditToLocation={setUpdateEditToLocation}
            setVisibleContact={setVisibleContact}
            visibleContact={visibleContact}
            triggerContact={triggerContact}
            setTriggerContact={setTriggerContact}
            newContact={newContact}
            setNewContact={setNewContact}
            temporaryData={temporaryData}
            setTemporaryData={setTemporaryData}
            statusSave={statusSave}
            setStatusSave={setStatusSave}
            visible={visible}
            editData={editData}
            customerSearch={customerSearch}
            setCustomerSearch={setCustomerSearch}
            itemTypeSearch={itemTypeSearch}
            setItemTypeSearch={setItemTypeSearch}
            destinationSearch={destinationSearch}
            setDestinationSearch={setDestinationSearch}
            sourceSearch={sourceSearch}
            setSourceSearch={setSourceSearch}
            customerPage={customerPage}
            setCustomerPage={setCustomerPage}
            customerSearchPage={customerSearchPage}
            setCustomerSearchPage={setCustomerSearchPage}
            itemTypePage={itemTypePage}
            setItemTypePage={setItemTypePage}
            itemTypeSearchPage={itemTypeSearchPage}
            setItemTypeSearchPage={setItemTypeSearchPage}
            destinationPage={destinationPage}
            setDestinationPage={setDestinationPage}
            destinationSearchPage={destinationSearchPage}
            setDestinationSearchPage={setDestinationSearchPage}
            sourcePage={sourcePage}
            setSourcePage={setSourcePage}
            sourceSearchPag={sourceSearchPage}
            setSourceSearchPage={setSourceSearchPage}
            sizeList={sizeList}
            setSizeList={setSizeList}
            referenceTypeList={referenceTypeList}
            setReferenceTypeList={setReferenceTypeList}
          />
        </Spin>
      )}

      <AddBusinessPartner visible={visibleAddCustomer} setVisible={setVisibleAddCustomer} setCustomerData={setNewCustomer} />

      <AddFromLocation visible={visibleAddFromLocation} setVisible={setVisibleAddFromLocation} toLocation={setNewFromLocation} />

      <EditFromLocation
        visible={visibleEditFromLocation}
        setVisible={setVisibleEditFromLocation}
        valueDefault={editFromLocationData}
        setTrigger={setTriggerEditLocation}
        toLocation={setUpdateEditFromLocation}
      />

      <AddAddressBookV2 visible={visibleAddToLocation} setVisible={setVisibleAddToLocation} toLocation={setNewToLocation} />

      <EditAddressBook
        visible={visibleEditToLocation}
        setVisible={setVisibleEditToLocation}
        selectAddress={editToLocationData}
        toLocation={setUpdateEditToLocation}
      />
    </Modal>
  );
};

const ModalOrder = Form.create({
  name: 'modal_form',
  mapPropsToFields() {},
})(ModalOrderCreate);
export default React.memo(ModalOrder, (prev, next) => {
  return prev.visible === next.visible;
});
