import React, { useState, useCallback, useRef, useEffect } from 'react'
import './css/index.css'
import { GoogleMap, useLoadScript, Marker, InfoWindow, StreetViewPanorama } from '@react-google-maps/api';
import Geocode from "react-geocode";
import _ from 'lodash'
import { Button } from 'antd'
import Button05 from '../v2/button_05';
import Button06 from '../v2/button_06';
import getSearchLocation from '../../function/search-location/getLocation';
import { FormattedMessage, useIntl } from 'react-intl';
import IconMarker from '../image/LocationEngagement.svg'

Geocode.setApiKey(`${localStorage.getItem('GEO_APIKEY')}`);
Geocode.enableDebug();

export default (props) => {
  const intl = useIntl()
  const { value, fnc } = props
  const { center, position, visibleInfo, selectAddress, latLng, dragMarker, setCenter, setPosition } = value
  const { setVisibleInfo, setSelectAddress, setLatLng, setDragMarker, setVisibleCreateAddress, handleCreate } = fnc

  const libraries = ["places"];
  const mapContainerStyle = {
    height: `calc(100vh - 204px)`,
    width: '100%',
    position: 'relative'
  }

  const defaultMapOption = {
    fullscreenControl: false,
    fullscreenControlOptions: false,
    streetViewControl: false,
    clickableIcons: false,
    zoomControl: true,
    gestureHandling: "cooperative",
    mapTypeControl: true,
    mapTypeControlOptions: {
      style: 1,
      position: 3,
    },
  };

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: localStorage.getItem('GEO_APIKEY'),
    libraries,
  });

  const mapRef = useRef();
  const onMapLoad = useCallback((map) => {
    mapRef.current = map
  }, []);

  if (loadError) return "Error loading maps";
  if (!isLoaded) return "Loading Maps";

  const onDragEnd = async (evt) => {
    setLatLng({ lat: evt.latLng.lat(), lng: evt.latLng.lng() });
    const area = await getSearchLocation({ location: evt.latLng });
    if (area) {
      setSelectAddress([{
        address: area.address,
        lat: area.lat,
        lng: area.lng,
        name: area.name,
        addressId: null
      }]);
      setLatLng(
        {
          lat: parseFloat(_.get(area, 'lat')),
          lng: parseFloat(_.get(area, 'lng')),
        }
      )
      setCenter(
        {
          lat: parseFloat(_.get(area, 'lat')),
          lng: parseFloat(_.get(area, 'lng')),
        }
      )
      setPosition(
        {
          lat: parseFloat(_.get(area, 'lat')),
          lng: parseFloat(_.get(area, 'lng')),
        }
      )
      setVisibleInfo(true)
      setDragMarker(area.name)
    }
  }

  const onCloseInfo = () => {
    setVisibleInfo(false)
    setDragMarker()
  }

  const onClickMarkerInfo = (name) => {
    setDragMarker(name)
    setVisibleInfo(true)
  }


  return (
    <div className='location-map-div-padding'>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={center}
        zoom={10}
        onLoad={onMapLoad}
        options={defaultMapOption}
      >
        {selectAddress && selectAddress.map(item => (
          <Marker
            key={item.name}
            position={{ lat: parseFloat(_.get(latLng, 'lat')), lng: parseFloat(_.get(latLng, 'lng')) }}
            draggable={true}
            onDragEnd={onDragEnd}
            onClick={() => onClickMarkerInfo(item.name)}
            icon={{
              url: IconMarker,
              scaledSize: new window.google.maps.Size(50, 50),
              anchor: new window.google.maps.Point(12, 42),
            }}
          >
            {
              dragMarker && dragMarker === item.name ? (
                <InfoWindow
                  onCloseClick={onCloseInfo}
                  visible={visibleInfo}
                  options={{
                    pixelOffset: new window.google.maps.Size(-12.5, 2)
                  }}
                >
                  <div className='location-map-info-padding'>
                    <div className='location-map-info-div-margin'>
                      <p className='location-map-info-header'>{_.get(item, 'name')}</p>
                      <span className='location-map-info-detail'>{_.get(item, 'address')}</span>
                    </div>
                    <div className='location-map-info-button-align'>
                      <Button05 key="submit" type="primary" btnsize='wd_df' btnfontsize='smm' onClick={handleCreate}>
                        <FormattedMessage id="btnSelectLocation" defaultMessage="Select This Location" />
                      </Button05>
                    </div>
                  </div>
                </InfoWindow>
              ) : null
            }
          </Marker>
        ))}
      </GoogleMap>
    </div >
  )
}
