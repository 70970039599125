import httpClient from '../../components/axiosClient';
import moment from 'moment';

const today = new Date();
const dateApi = 'YYYY-MM-DD';
const dateNow = moment(today).format(dateApi);

const getGanttApi = async (props) => {
  const {startDate_new, endDate_new, mainOrgIdArrString, selectFilter, search ,typeApi } = props;
  const comId = localStorage.getItem('comId');
    try {
      const response = await httpClient.get(
        `/v3/task/manager/monitor/task?startDate=${startDate_new}&endDate=${endDate_new}&com_id=${comId}&org_id=${mainOrgIdArrString}&searchBy=${selectFilter}&search=${search}&${typeApi}`
      );
      const data =  await response.data;      
      return data;
    } catch (error) {
      return error.response
    }
}

export default getGanttApi;