import React, { useState, useEffect, useRef } from 'react';
import { Avatar, Icon } from 'antd';
import styles from './css/index.css';
import DefaultProfile from '../../components/image/NoImageR.png';
import Resizer from 'react-image-file-resizer';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';

const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      720,
      720,
      'JPEG',
      70,
      0,
      (uri) => {
        resolve(uri);
      },
      'base64'
    );
  });

const dataURLtoFile = (dataurl, filename) => {
  const arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  for (var i = 0; i < bstr.length; i++) {
    u8arr[i] = bstr.charCodeAt(i);
  }
  return new File([u8arr], filename, { type: mime });
};

const UploadImageComponent = ({ record, imageFile, setImageFile }) => {
  const imageRef = useRef();

  console.log('imageFile', imageFile);

  useEffect(() => {
    if (record !== null) {
      setImageFile({
        url: _.get(record, 'warehouseImg') ? _.get(record, 'warehouseImg') :
          _.get(record, 'branchPhotoUrl') ? checkImg(_.get(record, 'branchPhotoUrl')) : null,
      });
    }
  }, [record]);

  const checkImg = (img) => {
    if (_.includes(img, '/img/null')) {
      return DefaultProfile
    } else {
      return img
    }
  }

  const handleChangePicture = async (event) => {
    event.preventDefault();

    let fileData;
    let reader = new FileReader();
    const file = event.target.files[0];
    if (file) {
      if (file.name.match(/\.(jpg|jpeg|png|JPG|PNG|JPEG)$/)) {
        const image = await resizeFile(file);
        reader.onloadend = () => {
          return reader.result;
        };
        reader.readAsDataURL(file);
        const fileChange = dataURLtoFile(image, file.name);
        fileData = fileChange;
        setImageFile({ ...imageFile, url: image, file: fileData });
      }
    }
  };

  const showOpenFileDialog = (e) => {
    imageRef.current.click();
  };

  const deleteImage = (e) => {
    setImageFile();
    imageRef.current.value = null;
  };

  const deleteImageDef = (e) => {
    setImageFile();
    imageRef.current.value = null;
  };

  return (
    <div className="upload-image-profile">
      {/* <div className="col-md-8 upload-image-profile"> */}
      <div className="upload-image-profile">
        <Avatar shape="square" size={152} src={_.get(imageFile, 'url') ? _.get(imageFile, 'url') : DefaultProfile} />
        {/* <Avatar shape="square" size={152} src={DefaultProfile} /> */}

        <input ref={imageRef} type="file" style={{ display: 'none' }} accept=".jpg, .png, .JPG, .PNG" onChange={handleChangePicture} />

        {record ? (
          <div className="upload-image-profile after" style={{ paddingTop: 63, paddingLeft: 62, paddingRight: 62 }}>
            <Icon type="upload" theme="outlined" style={{ fontSize: '25px' }} onClick={showOpenFileDialog} />
          </div>
        ) : _.get(imageFile, 'url') ? (
          <div className="upload-image-profile after" style={{ paddingTop: 63, paddingLeft: 42, paddingRight: 42 }}>
            <Icon type="upload" theme="outlined" style={{ fontSize: '25px' }} onClick={showOpenFileDialog} />
            &nbsp; &nbsp;
            <Icon type="delete" theme="outlined" style={{ fontSize: '25px' }} onClick={deleteImageDef} />
          </div>
        ) : (
          <div className="upload-image-profile after" style={{ paddingTop: 63, paddingLeft: 62, paddingRight: 62 }}>
            <Icon type="upload" theme="outlined" style={{ fontSize: '25px' }} onClick={showOpenFileDialog} />
          </div>
        )}
        {/* {imageFile ? (
          <div className="upload-image-profile after" style={{ paddingTop: 63, paddingLeft: 42, paddingRight: 42 }}>
            <Icon type="upload" theme="outlined" style={{ fontSize: '25px' }} onClick={showOpenFileDialog} />
            &nbsp; &nbsp;
            <Icon type="delete" theme="outlined" style={{ fontSize: '25px' }} onClick={deleteImage} />
          </div>
        ) : (
          // : imageFileDefault.profileImage ? (
          //   <div className="upload-image-profile after" style={{ paddingTop: 63, paddingLeft: 42, paddingRight: 42 }}>
          //     <Icon type="upload" theme="outlined" style={{ fontSize: '25px' }} onClick={showOpenFileDialog} />
          //     &nbsp; &nbsp;
          //     <Icon type="delete" theme="outlined" style={{ fontSize: '25px' }} onClick={deleteImageDef} />
          //   </div>
          // )
          <div className="upload-image-profile after" style={{ paddingTop: 63, paddingLeft: 62, paddingRight: 62 }}>
            <Icon type="upload" theme="outlined" style={{ fontSize: '25px' }} onClick={showOpenFileDialog} />
          </div>
        )} */}
      </div>
      <p style={{ fontSize: '10px', color: '#1D3557', marginTop: 12 }}>
        <FormattedMessage
          id="modalWarehouseDescription"
          defaultMessage="Only PNG file and recommended size: 356 x 356 pixels or 1:1 ratio"
        />
      </p>
    </div>
  );
};

export default UploadImageComponent;
