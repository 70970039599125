import React, { useRef, useCallback, useContext, useEffect, useState } from 'react';
import { GoogleMap, LoadScript, useLoadScript, Marker, withScriptjs, StandaloneSearchBox } from '@react-google-maps/api';
import Geocode from "react-geocode";
import _ from 'lodash';
import getSearchLocation from '../../function/search-location/getLocation';
import { AutoComplete, Icon } from 'antd'
import { useIntl } from 'react-intl'

Geocode.setApiKey(`${localStorage.getItem('GEO_APIKEY')}`);
Geocode.enableDebug();

export default ({ idMap, position, center, setLatlngTo, setValueAddress, addressArea, handleSearchLocation, handleSelect, children, autoComplteLocation, setAutoComplteLocation }) => {
  const libraries = ["places"];
  const intl = useIntl()
  const mapContainerStyle = {
    height: `400px`,
    width: '100%',
    position: 'relative'
  }

  const defaultMapOption = {
    fullscreenControl: false,
    fullscreenControlOptions: false,
    streetViewControl: false,
    clickableIcons: false,
    zoomControl: true,
    gestureHandling: "cooperative",
    mapTypeControl: true,
    mapTypeControlOptions: {
      style: 1,
      position: 3,
    },
  };

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: localStorage.getItem('GEO_APIKEY'),
    libraries,
  });

  const mapRef = useRef();
  const onMapLoad = useCallback((map) => {
    mapRef.current = map
  }, []);

  if (loadError) return "Error loading maps";
  if (!isLoaded) return "Loading Maps";

  const onDragEnd = async (evt) => {

    setLatlngTo({ lat: evt.latLng.lat(), lng: evt.latLng.lng() });
    // fncStateTask.fncSearchLocation({ location: evt.latLng });
    let area = await getSearchLocation({ location: evt.latLng });

    setValueAddress(area.address);
  }

  return (
    <div>
      <GoogleMap
        id={`${idMap}`}
        mapContainerStyle={mapContainerStyle}
        center={center}
        zoom={10}
        onLoad={onMapLoad}
        options={defaultMapOption}
      >
        <div className="service-area-autocomplete-div">
          <AutoComplete
            className="address-map-autocomplete"
            dataSource={addressArea}
            onSearch={e => {
              handleSearchLocation(e)
              setAutoComplteLocation(e)
            }}
            onSelect={handleSelect}
            filterOption={false}
            placeholder={intl.formatMessage({ id: 'hintSearch', defaultMessage: 'Search' })}
            prefix={<Icon style={{ color: "rgba(0,0,0,.25)" }} type="search" />}
            value={autoComplteLocation}
          >
            {children}
          </AutoComplete>
        </div>
        <Marker
          position={position}
          animation={window.google.maps.Animation.DROP}
          draggable={true}
          onDragEnd={onDragEnd}
        />
      </GoogleMap>
    </div>
  )
}
