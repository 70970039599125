import { Table, Card, Col, Row, Tabs, Button, Icon, Menu, Dropdown, Popover, Checkbox, Form, Input, Select, DatePicker } from 'antd';
import React, { useContext, useState, useRef, useEffect } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';

import Css from './css/index.css';
import _ from 'lodash';
import { DownOutlined } from '@ant-design/icons';
import { AllOrderContext } from '../allorder-context';
import { PageSettings } from '../../../config/page-settings';
import Button_01 from '../../../components/v2/button_01';
import CollapseCustom from '../../../components/collapse-custom';
import LabeRequireForm from '../../../components/label-required-form';
import moment from 'moment';
import { getWareHouseList } from '../../../controllers/warehouse/warehouse';
import { getCustomerLoadMore } from '../../../controllers/getCustomer/get-customer-new';

const { TabPane } = Tabs;
const { Option } = Select;

const CardTableAllOrder = () => {
  const { state, setState, fnc } = useContext(AllOrderContext);
  const { isLoading, toggle, selectedRowKeys, orderList, page, total, searchGroup, statusList, orderTypeList } = state;
  const { setToggle, setEvent, setSort, setSearchGroup, setPage } = setState;
  const { handleChangePage, handleChangeSize, handleVisibleCreate, handleView, handleEdit } = fnc;
  const totalItems = total;
  const [scrollTableX, setScrollTableX] = useState(1770);
  const intl = useIntl();
  const ref = useRef();
  const [visiblePopoverTable, setVisiblePopoverTable] = useState();
  const [recordPopover, setRecordPopover] = useState();
  const [offsetPopover, setOffsetPopover] = useState([0, 0]);
  const [textErrorSelectColumn, setTextErrorSelectColumn] = useState('');
  const [visible, setVisible] = useState(false);
  const [merchantList, setMerchantList] = useState([]);
  const [merchantPage, setMerchantPage] = useState(10);
  const [merchantLoading, setMerchantLoading] = useState(false);
  const [memberList, setMemberList] = useState();
  const [customerPage, setCustomerPage] = useState(10);
  const [customerLoading, setCustomerLoading] = useState(false);
  const dateFormat = 'DD/MM/YYYY';

  const data = [{ total: '1' }];

  const setShowColumn = {
    index: true,
    orderNo: true,
    orderType: true,
    warehouseName: true,
    customerName: true,
    statusName: true,
    subtotal: true,
    deliveryFee: true,
    total: true,
    orderDate: true,
    submittedDate: true,
  };

  const setShowColumnArr = [
    'index',
    'orderNo',
    'orderType',
    'warehouseName',
    'customerName',
    'statusName',
    'subtotal',
    'deliveryFee',
    'total',
    'orderDate',
    'submittedDate',
  ];

  const [defaultShowColumn, setDefaultShowColumn] = useState({ ...setShowColumn });
  const [listArrayColumns, setListArrayColumns] = useState([...setShowColumnArr]);

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: 70,
      fixed: 'left',
      align: 'center',
      // render: (text, record, index) => <span>{9999}</span>,
    },
    {
      title: <FormattedMessage id="AllOrderOrderNo" defaultMessage="Order No." />,
      dataIndex: 'orderNo',
      key: 'orderNo',
      sorter: true,
      fixed: 'left',
      width: 160,
    },
    {
      title: <FormattedMessage id="AllOrderOrderType" defaultMessage="Order Type" />,
      key: 'orderType',
      dataIndex: 'orderType',
      // width: 120,
      sorter: true,
    },
    {
      title: <FormattedMessage id="AllOrderMerchant" defaultMessage="Merchant" />,
      key: 'warehouseName',
      dataIndex: 'warehouseName',
      width: 150,
      sorter: true,
    },
    {
      title: <FormattedMessage id="AllOrderCustomer" defaultMessage="Customer" />,
      key: 'customerName',
      dataIndex: 'customerName',
      sorter: true,
      width: 150,
    },
    {
      title: <FormattedMessage id="AllOrderStatus" defaultMessage="Status" />,
      dataIndex: 'statusName',
      key: 'statusName',
      sorter: true,
      width: 220,
      render: (data, record) => {
        return (
          <span>
            <p
              style={{
                backgroundColor: record.statusColor,
                // backgroundColor: _.get(record, 'statusColor'),
                color: 'white',
                textAlign: 'center',
                borderRadius: '10px',
                height: '23px',
                width: '100%',
              }}
            >
              {record.statusName}
            </p>
          </span>
        );
      },
    },

    {
      title: <FormattedMessage id="AllOrderSubtotal" defaultMessage="Subtotal" />,
      key: 'subtotal',
      dataIndex: 'subtotal',
      sorter: true,
      width: 150,
      align: 'right',
    },
    {
      title: <FormattedMessage id="AllOrderDeliveryFee" defaultMessage="Delivery Fee" />,
      key: 'deliveryFee',
      dataIndex: 'deliveryFee',
      sorter: true,
      width: 150,
      align: 'right',
    },
    {
      title: <FormattedMessage id="AllOrderTotal" defaultMessage="Total" />,
      key: 'total',
      dataIndex: 'total',
      sorter: true,
      width: 150,
      align: 'right',
    },
    {
      title: <FormattedMessage id="AllOrderOrderDate" defaultMessage="Order Date" />,
      key: 'orderDate',
      dataIndex: 'orderDate',
      sorter: true,
      width: 200,
    },
    {
      title: <FormattedMessage id="AllOrderSubmittedDate" defaultMessage="Submitted Date" />,
      key: 'submittedDate',
      dataIndex: 'submittedDate',
      sorter: true,
      width: 200,
    },
  ];

  const ignoreColumn = [];
  const newColumns = columns.filter((col) => !ignoreColumn.includes(col.key));
  const ignoreColumnButton = ['index', 'orderNo'];
  const columnNew = columns.filter((col) => !ignoreColumnButton.includes(col.key));
  const [newDataColumns, setNewDataColumns] = useState([...newColumns]);

  useEffect(() => {
    async function getWarHouseFrom() {
      try {
        const pageStart = 0;
        const pageEnd = merchantPage;

        const payload = {
          indexStart: pageStart,
          indexEnd: pageEnd,
          searchLeftMenu: '',
          notWarehouseId: [],
          isNotCheckPermission: true,
        };
        const response = await getWareHouseList(payload);

        // const data = await getCustomerLoadMore(pageStart, pageEnd);
        console.log('CustomerVoucher', _.get(response, 'data.data.warehouseList'));
        setMerchantList(_.get(response, 'data.data.warehouseList'));
        setMerchantLoading(false);
      } catch (error) {}
    }

    getWarHouseFrom();
  }, [merchantPage]);

  useEffect(() => {
    async function getUser() {
      try {
        const pageStart = 1;
        const pageEnd = customerPage;

        const data = await getCustomerLoadMore(pageStart, pageEnd);
        console.log('CustomerVoucher', data);
        setMemberList(_.get(data, 'customers'));
        setCustomerLoading(false);
      } catch (error) {}
    }

    getUser();
  }, [customerPage]);

  const handleWarhouseFromPopupScroll = (e) => {
    let target = e.target;
    if (!merchantLoading && target.scrollTop + target.offsetHeight >= target.scrollHeight - 50) {
      setMerchantPage(merchantPage + 10);
      setMerchantLoading(true);
    }
  };

  const handleCustomerPopupScroll = (e) => {
    let target = e.target;
    if (!customerLoading && target.scrollTop + target.offsetHeight >= target.scrollHeight - 50) {
      setCustomerPage(customerPage + 10);
      setCustomerLoading(true);
    }
  };

  const menuColumn = () => {
    return (
      <Menu>
        <Menu.Item>
          <Button ghost type="link" onClick={handleVisibleCreate}>
            <FormattedMessage id="btnCreate" defaultMessage="Create" />
          </Button>
        </Menu.Item>
      </Menu>
    );
  };

  const showTotal = () => {
    return (
      <span style={{ fontSize: '13px' }}>
        <FormattedMessage id="lblRowPerPage" defaultMessage="Rows per page: " />
      </span>
    );
  };

  const HeaderAndToggle = () => {
    return (
      <div style={{ display: 'flex' }}>
        <div>
          <p>
            <FormattedMessage id="menuOrderReport" defaultMessage="All Order" />
          </p>
        </div>
      </div>
    );
  };

  const handleToggle = () => {
    setToggle((current) => !current);
  };

  const handleMouseLeave = () => {
    setVisiblePopoverTable(false);
    setRecordPopover();
    setOffsetPopover([0, 0]);
  };

  const fncActionTable = (record, offsetPopover, visiblePopoverTable, ref) => {
    return (
      <div>
        <Popover
          placement="right"
          trigger="hover"
          align={{ offset: offsetPopover }}
          visible={visiblePopoverTable}
          zIndex={10000}
          content={
            <div style={{ display: 'grid' }}>
              <PageSettings.Consumer>
                {({ checkPermissionAction }) => (
                  <>
                    {checkPermissionAction(`P71PG1C1`, `P71PG1C1A2`) ? (
                      <Button style={{ width: 100 }} type="link" ghost onClick={() => handleView(record)}>
                        <FormattedMessage id="btnView" defaultMessage="View" />
                      </Button>
                    ) : null}

                    {_.get(record, 'status') === 'Open' || _.get(record, 'status') === 'Draft' ? (
                      <Button style={{ width: 100 }} type="link" ghost onClick={() => handleEdit(record)}>
                        <FormattedMessage id="btnEdit" defaultMessage="Edit" />
                      </Button>
                    ) : null}
                  </>
                )}
              </PageSettings.Consumer>
            </div>
          }
        >
          <div style={{ width: 0, height: 0 }} ref={ref}></div>
        </Popover>
      </div>
    );
  };

  const onChangeTable = (value, index, sort) => {
    setSort(sort);
    setEvent((current) => !current);
  };

  const menuSelectColumn = () => {
    return (
      <Menu>
        <Menu.Item style={{ backgroundColor: '#ffffff' }}>
          {columnNew.map((item, i) => (
            <div key={i} style={{ display: 'block', marginBottom: '10px' }}>
              <Checkbox value={item.key} onChange={checkedValuecolumns} checked={defaultShowColumn[item.key] ? true : false}>
                <span style={{ fontSize: '13px' }}>{item.title}</span>
              </Checkbox>
            </div>
          ))}
        </Menu.Item>

        <Menu.Item style={{ color: '#FF1C00', textAlign: 'left', backgroundColor: '#ffffff' }}>{textErrorSelectColumn}</Menu.Item>

        <Menu.Item style={{ backgroundColor: '#ffffff' }}>
          <Button.Group style={{ display: 'flex', justifyContent: 'center' }}>
            <Button onClick={handleCheckOk} style={{ width: '50%', fontSize: '13px' }}>
              <FormattedMessage id="btnOK" defaultMessage="OK" />
            </Button>
            <Button onClick={handleReset} style={{ width: '50%', fontSize: '13px' }}>
              <FormattedMessage id="btnReset" defaultMessage="Reset" />
            </Button>
          </Button.Group>
        </Menu.Item>
      </Menu>
    );
  };

  const checkedValuecolumns = (e) => {
    let checked = e.target.checked;
    let val = e.target.value;
    let showColumn = { ...defaultShowColumn };
    if (checked === true) {
      const found = listArrayColumns.find((element) => element === val);
      if (found === undefined) {
        setListArrayColumns([...listArrayColumns, val]);
      }
      showColumn[val] = true;
    } else {
      const newListArray = [...listArrayColumns];
      const found = listArrayColumns.findIndex((element) => element === val);
      if (found >= 0) {
        newListArray.splice(found, 1);
        setListArrayColumns(newListArray);
      }
      showColumn[val] = false;
    }
    setDefaultShowColumn(showColumn);
  };

  const handleCheckOk = () => {
    const setNewArr = [];
    if (listArrayColumns.length <= 3) {
      setTextErrorSelectColumn(<FormattedMessage id="lblSelectAtLeastColumn" defaultMessage="Select at least 1 column." />);
      return;
    } else {
      setTextErrorSelectColumn('');
    }
    for (let i = 0; i < columns.length; i++) {
      const found = listArrayColumns.find((element) => element == columns[i].key);
      if (found != undefined) {
        setNewArr.push(columns[i]);
      }
    }
    setNewDataColumns(setNewArr);
    handleOnVisiblecolumns();
  };

  const handleReset = () => {
    const newListArrayColumns = [];
    newColumns.forEach((element) => {
      newListArrayColumns.push(element.key);
    });
    setNewDataColumns(newColumns);
    setListArrayColumns(newListArrayColumns);
    handleOnVisiblecolumns();
    setDefaultShowColumn(setShowColumn);
    setTextErrorSelectColumn('');
  };

  const handleOnVisiblecolumns = () => {
    setVisible(!visible);
  };

  const handleVisibleChange = (flag) => {
    setVisible(flag);
    if (!flag) {
      // FNC คืนค่า Default อัติโนมัติ
      const newListArrayColumns = [];
      newDataColumns.forEach((element) => {
        newListArrayColumns.push(element.key);
      });

      const reduceOBJ = _.reduce(
        newListArrayColumns,
        function(result, value, key) {
          result[value] = true;
          return result;
        },
        {}
      );
      setListArrayColumns(newListArrayColumns);
      setDefaultShowColumn(reduceOBJ);
      setTextErrorSelectColumn('');
    }
  };

  const onChange = (value, code) => {
    setSearchGroup({ ...searchGroup, [code]: value });
    setPage(1);
  };

  const formFilterStock = () => {
    return (
      <Form colon={false}>
        <Row gutter={[24, 2]}>
          <Col span={8}>
            <Form.Item
              label={<LabeRequireForm text={intl.formatMessage({ id: 'AllOrderOrderNo', defaultMessage: 'Order No.' })} req={false} />}
            >
              <Input
                allowClear
                onChange={(e) => onChange(e.target.value, 'orderno')}
                placeholder={intl.formatMessage({ id: 'AllOrderOrPlaceholderOrderNo', defaultMessage: 'Enter Order No.' })}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<LabeRequireForm text={intl.formatMessage({ id: 'AllOrderOrderType', defaultMessage: 'Order Type' })} req={false} />}
            >
              <Select
                allowClear
                onChange={(value) => onChange(value, 'ordertype')}
                placeholder={intl.formatMessage({ id: 'AllOrderOrPlaceholderOrderType', defaultMessage: 'Select Order Type' })}
              >
                {_.map(orderTypeList, (item) => (
                  <Option value={item.code}>{item.txt}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            {' '}
            <Form.Item
              label={<LabeRequireForm text={intl.formatMessage({ id: 'AllOrderMerchant', defaultMessage: 'Merchant' })} req={false} />}
            >
              <Select
                allowClear
                onChange={(value) => onChange(value, 'merchant')}
                placeholder={intl.formatMessage({ id: 'AllOrderOrPlaceholderMerchant', defaultMessage: 'Select Merchant' })}
                showSearch
                value={_.get(searchGroup, 'merchant') === '' ? undefined : _.get(searchGroup, 'merchant')}
                filterOption={(input, option) =>
                  option.props.children
                    .toString()
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                onPopupScroll={handleWarhouseFromPopupScroll}
              >
                {_.map(merchantList, (item, index) => (
                  <Option key={item.warehouseId} value={item.warehouseId}>
                    {item.warehouseCode} {item.warehouseName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[24, 2]}>
          <Col span={8}>
            <Form.Item
              label={<LabeRequireForm text={intl.formatMessage({ id: 'AllOrderCustomer', defaultMessage: 'Customer' })} req={false} />}
            >
              <Select
                onChange={(value) => onChange(value, 'customer')}
                placeholder={intl.formatMessage({ id: 'AllOrderOrPlaceholderCustomer', defaultMessage: 'Select Customer' })}
                showSearch
                allowClear
                filterOption={(input, option) =>
                  option.props.children
                    .toString()
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                onPopupScroll={handleCustomerPopupScroll}
              >
                {memberList &&
                  memberList.map((item, index) => {
                    return (
                      <Option key={index} value={item.customerId}>
                        {item.customerName}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'AllOrderStartOrderDate', defaultMessage: 'Start Order Date' })}
                  req={false}
                />
              }
            >
              <DatePicker
                className="inputWidth"
                placeholder={intl.formatMessage({ id: 'AllOrderOrPlaceholderStartOrder', defaultMessage: 'Select Start Order Date' })}
                format={dateFormat}
                allowClear={true}
                onChange={(e) => onChange(moment(e).format('YYYY-MM-DD'), 'startorder')}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            {' '}
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'AllOrderEndOrderDate', defaultMessage: 'End Order Date' })} req={false} />
              }
            >
              <DatePicker
                className="inputWidth"
                placeholder={intl.formatMessage({ id: 'AllOrderOrPlaceholderEndOrder', defaultMessage: 'Select End Order Date' })}
                format={dateFormat}
                allowClear={true}
                onChange={(e) => onChange(moment(e).format('YYYY-MM-DD'), 'endorder')}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[24, 2]}>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'AllOrderEndSubmittedDate', defaultMessage: 'End Submitted Date' })}
                  req={false}
                />
              }
            >
              <DatePicker
                className="inputWidth"
                placeholder={intl.formatMessage({
                  id: 'AllOrderOrPlaceholderEndSubmittedDate',
                  defaultMessage: 'Select End Submitted Date',
                })}
                format={dateFormat}
                allowClear={true}
                onChange={(e) => onChange(moment(e).format('YYYY-MM-DD'), 'endsubmit')}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'AllOrderStartSubmittedDate', defaultMessage: 'Start Submitted Datee' })}
                  req={false}
                />
              }
            >
              <DatePicker
                className="inputWidth"
                placeholder={intl.formatMessage({
                  id: 'AllOrderOrPlaceholderStartSubmittedDate',
                  defaultMessage: 'Select Start Submitted Date',
                })}
                format={dateFormat}
                allowClear={true}
                onChange={(e) => onChange(moment(e).format('YYYY-MM-DD'), 'startsubmit')}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<LabeRequireForm text={intl.formatMessage({ id: 'warehouseModalSatus', defaultMessage: 'Status' })} req={false} />}
            >
              <Select
                allowClear
                onChange={(value) => onChange(value, 'status')}
                placeholder={intl.formatMessage({ id: 'ModalLocationPlaceholderStatus', defaultMessage: 'Select Status' })}
              >
                {_.map(statusList, (item) => (
                  <Option value={item.statusCode}>{item.statusName}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  };

  return (
    <div>
      <PageSettings.Consumer>
        {({ checkPermissionAction }) =>
          checkPermissionAction(`P71PG1C1`, `P71PG1C1A1`) ? (
            <Card
              title={<HeaderAndToggle />}
              className="right-cards"
              style={{ margin: '8px' }}
              // extra={
              //   <div className="display-extra-button-group" style={{ padding: '0px 0px' }}>
              //     {/* <ButtonActionGroup menu={menuColumn} /> */}
              //     <Dropdown overlay={menuColumn} trigger={['click']} placement="bottomRight">
              //       <Button_01 key="submit" type="primary" btnsize="wd_df" onClick={() => {}}>
              //         <FormattedMessage id="btnActionGroup" defaultMessage="Action" />
              //         <DownOutlined style={{ fontSize: '14px', paddingLeft: '22%' }} />
              //       </Button_01>
              //     </Dropdown>
              //   </div>
              // }
            >
              <div style={{ marginTop: '20px' }}>
                <CollapseCustom
                  label={intl.formatMessage({ id: `btnFilter`, defaultMessage: 'Filter' })}
                  extra={
                    <Dropdown
                      overlay={menuSelectColumn}
                      trigger={['click']}
                      placement="bottomRight"
                      onVisibleChange={handleVisibleChange}
                      visible={visible}
                    >
                      <Button_01 key="submit" type="primary" btnsize="wd_df" fontSize="sm" onClick={() => {}}>
                        <FormattedMessage id="reportReapirTicketReportBtnColumns" defaultMessage="Columns" />
                        <DownOutlined style={{ fontSize: '14px', paddingLeft: '4%' }} />
                      </Button_01>
                    </Dropdown>
                  }
                >
                  {formFilterStock()}
                </CollapseCustom>
              </div>
              <div className="containerTables" onMouseLeave={handleMouseLeave}>
                <Row gutter={16}>
                  <Table
                    className="custom-table-claim scroll-sm"
                    dataSource={orderList || []}
                    columns={newDataColumns}
                    scroll={{ x: scrollTableX, y: `calc(100vh - 366px)` }}
                    loading={isLoading}
                    onChange={onChangeTable}
                    pagination={{
                      total: totalItems,
                      current: page,
                      defaultCurrent: 1,
                      pageSizeOptions: ['10', '20', '30', '40', '50'],
                      showSizeChanger: true,
                      locale: { items_per_page: '' },
                      showTotal: showTotal,
                      onChange: handleChangePage,
                      onShowSizeChange: handleChangeSize,
                    }}
                    onRow={(record, rowIndex) => {
                      return {
                        onClick: (event) => {
                          if (!event.target.href) {
                            const { x, y } = ref.current.getBoundingClientRect();
                            setVisiblePopoverTable(true);
                            setOffsetPopover([event.pageX - x, event.pageY - y]);
                            setRecordPopover(record);
                          }
                        },
                      };
                    }}
                  />
                  {fncActionTable(recordPopover, offsetPopover, visiblePopoverTable, ref)}
                  <Col span={12}>
                    <div className="total-items-member">
                      <span style={{ fontSize: '13px' }}>
                        <FormattedMessage id="lblTotal" defaultMessage="Total" />
                        {` ${total || 0} `}
                        <FormattedMessage id="lblitems" defaultMessage="items" />
                      </span>
                    </div>
                  </Col>
                </Row>
              </div>
            </Card>
          ) : null
        }
      </PageSettings.Consumer>
    </div>
  );
};

export default CardTableAllOrder;
