import httpClient from "../../components/axiosClient";

const getListAssetExpenseReport = async (body) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v1/report/manager/assets/${comCode}/paginate`, body)
    if (response.status === 200) {

      return response
    } else {
      return []
    }
  } catch (error) {
    return error.response
  }
} 

const getDetailAssetExpenseReport = async (body) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v1/report/manager/assetsDetail/${comCode}/paginate`, body)
    if (response.status === 200) {

      return response
    } else {
      return []
    }
  } catch (error) {
  }
}

const exportAssets = async (body) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v1/report/manager/exportAssets/${comCode}/exportAssets`, body)
    if (response.status === 200) {

      return response
    } else {
      return []
    }
  } catch (error) {
  }
}

const exportAssetsDetail = async (body) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v1/report/manager/exportAssets/${comCode}/exportAssetsDetail`, body)
    if (response.status === 200) {

      return response
    } else {
      return []
    }
  } catch (error) {
  }
}


export { getListAssetExpenseReport, getDetailAssetExpenseReport, exportAssets, exportAssetsDetail};