import React from 'react';
import Card from '../../../components/v2/card';
import { useIntl, FormattedMessage } from 'react-intl';
import Button01 from '../../../components/v2/button_01';
import { Col, Row, Select, DatePicker } from 'antd';
import AssetclaimSummaryTable from './assetclaim-summary-table';
import _ from 'lodash';
import NumberFormat from 'react-number-format';
import './css/index.css';

const { RangePicker } = DatePicker;
const { Option } = Select;
const dateFormat = 'ddd, MMM DD YYYY';
const AssetClaimSummary = ({
  insuranceData,
  claimData,
  isLoading,
  handleChange,
  page,
  handleChangeDate,
  rangeDate,
  handleChangeInsurance,
  handleActionClaimHistory,
  handleActionViewEdit,
}) => {
  const intl = useIntl();

  const claimSummary = _.get(claimData, 'claimSummary');
  console.log('claimSummary', _.get(claimSummary, 'damageValSummary'));

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: 50,
      fixed: 'left',
    },
    {
      title: intl.formatMessage({ id: `claimDate`, defaultMessage: 'Claim Date' }),
      dataIndex: 'claimDate',
      key: 'claimDate',
      sorter: true,
      fixed: 'left',
      width: 180,
    },
    {
      title: intl.formatMessage({ id: `claimInsurance`, defaultMessage: 'Insurance' }),
      dataIndex: 'insurance',
      key: 'insurance',
      sorter: true,
      // fixed: 'left',
      width: 230,
    },
    {
      title: intl.formatMessage({ id: `claimBy`, defaultMessage: 'Claim By' }),
      dataIndex: 'claimBy',
      key: 'claimBy',
      sorter: true,
      // fixed: 'left',
      width: 180,
    },
    {
      title: intl.formatMessage({ id: `claimRef`, defaultMessage: 'Claim Ref.' }),
      key: 'claimRef',
      dataIndex: 'claimRef',
      sorter: true,
    },
    {
      title: intl.formatMessage({ id: `claimDamage`, defaultMessage: 'Damage Value (Baht)' }),
      key: 'damageVal',
      dataIndex: 'damageVal',
      sorter: true,
      render: (text, record, index) => (
        <NumberFormat value={(parseFloat(record.damageVal) || 0).toFixed(2)} displayType={'text'} thousandSeparator={true} />
      ),
    },
    {
      title: intl.formatMessage({ id: `claimAmount`, defaultMessage: 'Claimable Amount (Baht)' }),
      key: 'claimable',
      dataIndex: 'claimable',
      sorter: true,
      render: (text, record, index) => (
        <NumberFormat value={(parseFloat(record.claimable) || 0).toFixed(2)} displayType={'text'} thousandSeparator={true} />
      ),
    },
    {
      title: intl.formatMessage({ id: `claimAttachment`, defaultMessage: 'Attachment' }),
      dataIndex: 'countAttachments',
      key: 'countAttachments',
    },
  ];

  return (
    <div>
      <Card
        title={<FormattedMessage id="AssetClaimSummaryTitle" defaultMessage="Claim Summary" />}
        extra={
          <div className="extra-user-details">
            <div>
              <Select
                placeholder={intl.formatMessage({ id: 'assetDetailPlaceholderInsurance', defaultMessage: 'Select Insurance' })}
                style={{ width: 160 }}
                onChange={handleChangeInsurance}
                allowClear={true}
              >
                {insuranceData &&
                  insuranceData.map((item) => (
                    <Option key={item.assetInsuranceId} value={`${item.assetInsuranceId}`}>
                      {item.insuranceName}
                    </Option>
                  ))}
              </Select>
              <RangePicker
                style={{ width: 280, marginLeft: '11px' }}
                onChange={handleChangeDate}
                defaultValue={rangeDate}
                placeholder={intl.formatMessage({ id: 'maintenanceModalDetailPlaceholderDate', defaultMessage: 'Select Date' })}
                format={dateFormat}
                allowClear={false}
              />
            </div>
          </div>
        }
      >
        <Row gutter={16}>
          <Col span={8} style={{ textAlign: 'center', padding: '16px 0px' }}>
            <div className="total-claim"><FormattedMessage id="assetClaimHistoryLabelTotalClaim" defaultMessage="Total Claim" /></div>
            <div className="total-claim-sum">
              <NumberFormat
                // value={(parseFloat(_.get(claimSummary, 'totalClaim')) || 0).toFixed(2)}
                value={_.get(claimSummary, 'totalClaim')}
                displayType={'text'}
                thousandSeparator={true}
              />
            </div>
          </Col>
          <Col span={8} style={{ textAlign: 'center', padding: '16px 0px' }}>
            <div className="total-claim"><FormattedMessage id="assetClaimHistoryLabelDamageValueBaht" defaultMessage="Damage Value (Baht)" /></div>
            <div className="total-claim-sum">
              <NumberFormat
                value={(parseFloat(_.get(claimSummary, 'damageValSummary')) || 0).toFixed(2)}
                displayType={'text'}
                thousandSeparator={true}
              />
            </div>
          </Col>
          <Col span={8} style={{ textAlign: 'center', padding: '16px 0px' }}>
            <div className="total-claim"><FormattedMessage id="assetClaimHistoryLabelClaimableBaht" defaultMessage="Claimable (Baht)" /></div>
            <div className="total-claim-sum">
              <NumberFormat
                value={(parseFloat(_.get(claimSummary, 'claimableSummary')) || 0).toFixed(2)}
                displayType={'text'}
                thousandSeparator={true}
              />
            </div>
          </Col>
        </Row>
        <Row gutter={16}>
          <Row gutter={16}>
            <div className="frame-header">
              <div className="title-header-claim">
                <FormattedMessage id="AssetClaimHistoryTitle" defaultMessage="Claim History" />
              </div>
              <div>
                <Button01 key="submit" type="primary" btnsize="wd_df" onClick={() => handleActionClaimHistory()}>
                  <FormattedMessage id="btnAdd" defaultMessage="Add" />
                </Button01>
              </div>
            </div>
          </Row>
          <Row gutter={16}>
            <AssetclaimSummaryTable
              handleActionViewEdit={handleActionViewEdit}
              columns={columns}
              claimData={claimData}
              isLoading={isLoading}
              handleChange={handleChange}
              page={page}
            />
          </Row>
        </Row>
      </Card>
    </div>
  );
};

export default AssetClaimSummary;
