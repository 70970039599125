import React, { useState, useEffect, useContext } from 'react';
import { Card, Col, Row, Divider, Button, Tag } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import { PageEngagement } from '../../../config/page-settings';
import ActionReportV7 from '../../../components/action-report/action-report-v7.jsx';
import moment from 'moment';
import _, { debounce } from 'lodash';
import stylesEngAction from '../css/engagement-action.css';
import CurrencyInput from 'react-currency-input-field';

const today = new Date();
const { CheckableTag } = Tag;

const EngagementAction = (props) => {
  const intl = useIntl();
  const { selectAction, selectModule, sorting, txtSearch, rangePicker, customerModules, fnc } = useContext(PageEngagement);

  const [selectedSorting, setSelectedSorting] = useState([sorting]);
  // const [selectedStatus, setSelectedStatus] = useState();


  // useEffect(() => {
  //   if (selectAction) {
  //     const filterStatus = customerModules.filter(ele => ele.moduleCode === selectAction).map(item => { return {children: item.moduleName, eventKey: item.moduleCode} })
  //     // setSelectedStatus(filterStatus[0]);
  //   }
  // }, [selectAction]);

  const handleChangeStatus = (item, checked) => {
    let selectModuleDoc = [...selectModule];
    if(checked) {
      const findSelectModule = _.indexOf(selectModuleDoc, item.moduleCode);
      if(findSelectModule === -1) {
        selectModuleDoc.push(item.moduleCode);
      }
    } else {
      _.remove(selectModuleDoc, (code) => { return code === item.moduleCode ? true : false; });
    }
    fnc.handleSelectModule(selectModuleDoc);
  }

  const handleChangeSorting = (item, checked) => {
    let selectSorting = [...selectedSorting];
    if(checked) {
      const findSelectModule = _.indexOf(selectSorting, item.sortOrder);
      if(findSelectModule === -1) {
        selectSorting = [item.sortOrder]
        fnc.handleSelectSorting(item.sortOrder);
      }
    }
    setSelectedSorting(selectSorting);
  }

  // const onChangeSearch = debounce((value) => {
  //   fnc.handleChangeTxtSearch(value);
  // }, 200);

  const onChangeSearch = (value) => {
    fnc.handleChangeTxtSearch(value);
  }

  const onSelectMenuStatus = ({ item, key }) => {
    // setSelectedStatus(item.props);
    fnc.handleSelectAction(key);
    fnc.handleOpenModalModuleAction(key);
  }

  const sortingData = [
    {
      name: intl.formatMessage({ id: 'engagementActionTxtLatest', defaultMessage: 'Latest' }),
      id: 1,
      sortOrder: 'desc',
      selected: true,
    },
    {
      name: intl.formatMessage({ id: 'engagementActionTxtOldest', defaultMessage: 'Oldest' }),
      id: 2,
      sortOrder: 'asc',
      selected: false,
    }
  ];

  return (
    <div className="engagement-action">
      <Card 
        className="engagement-action-card"
      >
        <div className="engagement-action-container">
          <div className="engagement-action-reportV7">
            <ActionReportV7 
              value={{
                fieldChang: txtSearch,
                rangePickerValue: rangePicker,
                statusModule: customerModules,
                // selectedStatus,
              }}
              fnc={{
                onChangeSearch,
                onSelectRangePicker: fnc.handleChangeRangePicker,
                onSelectMenuStatus,
              }}
            />
          </div>

          <Divider className="engagement-action-divider" />

          <div className="engagement-action-filter">
            <div className="engagement-action-filter-status">
              <div className="engagement-status-filter-text">
                <span className="engagement-status-filter-text-span">
                  <FormattedMessage id='engagementActionTxtFilter' defaultMessage='Filter' />
                </span>
              </div>
              {customerModules && customerModules.map((item, index) => (
                <div key={index}>
                  <CheckableTag
                    className="engagement-checkableTag"
                    key={item.moduleCode}
                    checked={selectModule.indexOf(item.moduleCode) > -1}
                    onChange={checked => handleChangeStatus(item, checked)}
                  >
                    {item.moduleName}
                  </CheckableTag>
                  {index !== (customerModules.length - 1) ? <Divider className="engagement-action-divider" type="vertical" /> : null }
                </div>
              ))}
            </div>
            <div className="engagement-action-filter-sorting">
              <div className="engagement-status-filter-text">
                <span className="engagement-status-filter-text-span">
                  <FormattedMessage id='engagementActionTxtSortBy' defaultMessage='Sort by' />
                </span>
              </div>
              {sortingData && sortingData.map((item, index) => (
                <div key={index}>
                  <CheckableTag
                    className="engagement-checkableTag"
                    key={item.sortOrder}
                    checked={selectedSorting.indexOf(item.sortOrder) > -1}
                    onChange={checked => handleChangeSorting(item, checked)}
                  >
                    {item.name}
                  </CheckableTag>
                  {index !== (sortingData.length - 1) ? <Divider className="engagement-action-divider" type="vertical" /> : null }
                </div>
              ))}
            </div>
          </div>
        </div>
      </Card>
    </div>
  )
}

export default EngagementAction;
