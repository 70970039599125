import React, { useState, useContext } from 'react'
import { Form, Input, Row, Col, Icon, Select, Modal, Badge } from 'antd';
import { CustomerContext } from '../add-customer/add-customer-context';
import styled from 'styled-components';
import AppAvatar from '../avatar';
import style from './css/add-info-customer.css';
import { FormattedMessage } from 'react-intl';
import Button01 from '../v2/button_01';
import Button02 from '../v2/button_02';
import BusinessSvg from '../image/BusinessType2.svg'

const { Option } = Select;


const AddCustomer = ({ form, businessType, customerType, intl, setVisibleModalPrivacy, dataPrivacy,dataPrivacyType,setVisibleModalEditPrivacy }) => {
  const { stateCustomer, fncCustomer } = useContext(CustomerContext);

  const { getFieldDecorator, validateFields, resetFields, getFieldsValue, getFieldValue, setFieldsValue } = form;

  const LabelRequire = styled.label`
  color: #ff1010;
`;
  const LabeRequire = (props) => {
    const { text, req } = props;
    return (
      <span className="font-p">
        {text}&nbsp;
        {req ? <LabelRequire>*</LabelRequire> : ''}
      </span>
    );
  };

  const namePrivacy = dataPrivacy && dataPrivacy !== undefined ? dataPrivacyType.filter((col) => dataPrivacy.privacy.includes(col.code)) : undefined
  const exportName = namePrivacy && namePrivacy !== undefined ?  namePrivacy[0].name : undefined
  const exportCode = namePrivacy && namePrivacy !== undefined ?  namePrivacy[0].code : undefined
  const lengthPrivacy = dataPrivacy && dataPrivacy.team !== undefined ? dataPrivacy.team.length : undefined


  const ImgBusinessType = () => <img src={BusinessSvg} style={{width:'17px',height:'17px'}}></img>

  


  return (
    <div>
      <Form>
        <Row>
          <Col >
            <Row>
              <Col span={9}>
                <Form.Item>
                  {getFieldDecorator("cusCode", {
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({ id: 'ModalCustomerValidateCustomerCode', defaultMessage: 'Please enter code' })
                      }
                    ]
                  })(
                    <Input
                      size="large"
                      className='new-input-add-user-name'
                      placeholder={intl.formatMessage({ id: 'ModalCustomerPlacholderCustomerCode', defaultMessage: 'Enter Customer Code' })}
                      onChange={(e) => fncCustomer.handleCusCode(e.target.value)}
                      style={{ padding: 'unset' }}
                      autoComplete="off" 
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={1}>
                <Form.Item>
                  <Badge color="#000000" className='badge-text' />
                </Form.Item>
              </Col>
              <Col span={14}>
                <Form.Item style={{ marginLeft: '4px' }}>
                  {getFieldDecorator("cusName", {
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({ id: 'ModalCustomerValidateCustomerName', defaultMessage: 'Please enter name' })
                      }
                    ]
                  })(
                    <Input
                      size="large"
                      className='new-input-add-user-name'
                      placeholder={intl.formatMessage({ id: 'ModalCustomerPlacholderCustomerName', defaultMessage: 'Enter Customer Name' })}
                      onChange={(e) => fncCustomer.handleCusName(e.target.value)}
                      style={{ padding: 'unset' }}
                      autoComplete="off" 
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <div className='icon-input'>
                  <Icon type={exportCode == 'team' ? 'team' : exportCode == 'public' ? 'global' : 'lock'} className="style-margin" />
                  {dataPrivacy && dataPrivacy !== undefined ? (
                     <Form.Item style={{ marginLeft: '2px' }}>
                       <span onClick={() => setVisibleModalEditPrivacy(true)} style={{cursor:'pointer'}}>
                         {exportName}&nbsp;{dataPrivacy.privacy == 'team' ? `(${lengthPrivacy})` :null}</span>
                     </Form.Item>
                  ) : (
                  <Form.Item style={{ marginLeft: '2px' }}>
                    {getFieldDecorator("privacy", {
                    initialValue: 'only me',
                      rules: [
                        {
                          required: true,
                          message: 'Please select privacy'
                        }
                      ]
                    })(
                      <Input
                        className='new-input-add-user-name'
                        placeholder='Select Privacy'
                        style={{ padding: 'unset', cursor: 'pointer' }}
                        onClick={() => setVisibleModalPrivacy(true)}
                        readOnly={true}
                        autoComplete="off" 
                      />
                    )}
                  </Form.Item>
                  )}

                </div>
              </Col>
              <Col span={12}>
                <div className='icon-input'>
                  <Icon component={ImgBusinessType} className="style-margin" />
                  <Form.Item>
                    {getFieldDecorator('busType', {
                      rules: [{
                        required: true,
                        message: intl.formatMessage({ id: 'ModalCustomerValidateBusinessType', defaultMessage: 'Please select business type' })
                      }],
                    })(
                      <Select className='select-hidden-border'
                        placeholder={intl.formatMessage({ id: 'ModalCustomerPlacholderBusinessType', defaultMessage: 'Select Business Type' })}
                      >
                        {businessType.map((item, index) => (
                          <Option value={item.businessTypeId}>{item.businessTypeName}</Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </div>
              </Col>
              <Col span={12}>
                <div className='icon-input'>
                  <Icon type='solution' className="style-margin" />
                  <Form.Item>
                    {getFieldDecorator('cusType', {
                      rules: [{
                        required: true,
                        message: intl.formatMessage({ id: 'ModalCustomerValidateCustomerType', defaultMessage: 'Please select customer type' })
                      }],
                    })(
                      <Select className='select-hidden-border'
                        placeholder={intl.formatMessage({ id: 'ModalCustomerPlacholderCustomerType', defaultMessage: 'Select Customer Type' })}
                      >
                        {customerType.map((item, index) => (
                          <Option value={item.customerTypeId}>{item.customerTypeName}</Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>

    </div>
  )
}
const Customer = Form.create({
  name: "modal_form",
  mapPropsToFields(props) {

  }
})(AddCustomer);

export default Customer;