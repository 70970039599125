import React, { useState, useEffect, useRef, createRef, useMemo, memo, useContext, useCallback } from 'react';
import { Avatar, Icon, Row, Col, Modal, Form, notification, Select, Tag } from 'antd';
import {
  TimelineMonth,
  ScheduleComponent,
  ViewsDirective,
  ViewDirective,
  ResourcesDirective,
  ResourceDirective,
  Inject
} from '@syncfusion/ej2-react-schedule';
import { Internationalization, extend } from '@syncfusion/ej2-base';
import { DropDownListComponent, MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import { ShiftContext } from './shift-context'; 
import { useIntl, FormattedMessage } from 'react-intl';
import httpClient from '../../../components/axiosClient';
import Button01 from '../../../components/v2/button_01';
import Button02 from '../../../components/v2/button_02';
import styled from 'styled-components';
import moment from 'moment';
import Provider from '../provider';
import { L10n } from '@syncfusion/ej2-base';
import _ from 'lodash';
import stylesShiftIndex from './css/index.css';
import ShiftFormAddShift from './shift-modal-add-shift';
import ShiftModalDelete from './shift-modal-delete-shift';

const instance = new Internationalization();
const datenow = moment();

L10n.load({
  'en-US': {
    'schedule': {
      'saveButton': 'Add',
      'cancelButton': 'Cancel',
      'deleteButton': 'Remove',
      'newEvent': 'Add Shift',
    },
  }
});

export default React.memo((props) => {
  const intl = useIntl();
  const { orgIdSelect, resourceShiftData, shiftsData, refreshMain, mainShiftsDateTimePicker, resourcesArr, refreshApiShift, setRefreshApiShift } = props;
  const app = Provider.useAppContext();
  const comCode = localStorage.getItem('comCode');
  const langValue = localStorage.getItem('langValue') || 'EN';
  const { stateShiftsMain, setStateShiftsMain, fncShiftsMain } = useContext(ShiftContext);
  const scheduleObj = useRef();
  const comId = localStorage.getItem('comId');

  const dateFormat = 'ddd, MMM DD YYYY';

  let orgIdMain = _.get(app, 'state.orgId');
  // let comIdMain = _.get(app, 'state.comId');
  // let resourcesArr = _.get(stateShiftsMain, 'resourcesArr');

  const [resourceCellgroupIndex, setResourceCellgroupIndex] = useState(null);
  const [resourceCellArgs, setResourceCellArgs] = useState({});
  const [shiftEventArgs, setShiftEventArgs] = useState({});
  const [formVisible, setFormVisible] = useState(false);
  const [visibleDelete, setVisibleDelete] = useState(false);
  const [formData, setFormData] = useState({});
  const [rangePickerValue, setRangePickerValue] = useState([]);
  const [formRef, setFormRef] = useState(null);
  const [disabledBtnDelete, setDisabledBtnDelete] = useState(false);
  const [dateStart, setDateStart] = useState(undefined);
  const [dateEnd, setDateEnd] = useState(undefined);

  // const resourceShiftData_props = props.resourceShiftData;

  const resourceData_Sch = extend([], resourceShiftData, null, true);
  const shiftsData_Sch = extend([], shiftsData, null, true);

  // let resourceData = extend([], stateShiftsMain.resourceShiftData, null, true);
  // let shiftsData = extend([], stateShiftsMain.shiftsData, null, true);


  





  const customRenderCell = (args) => {
    if (args.elementType === 'emptyCells' && args.element.classList.contains('e-resource-left-td')) {
      let sumResource = _.sumBy(scheduleObj.current.resources[0].properties.dataSource, (e) => { return e.Text !== '' ? 1 : 0; });
      let target = args.element.querySelector('.e-resource-text');
      target.innerHTML = `<div style="padding: 5px 15px;"><b> ${intl.formatMessage({ id: 'orgShiftPlanningLblResources', defaultMessage: 'Resources' })} (` + sumResource + ')</b></div>';
    }
  };

  const dateHeaderTemplate = (props) => {
    return (
      <div style={{ height: 50, padding: 5 }}>
        <div style={{ lineHeight: 1.5, fontSize: 15, textAlign: 'center' }}>{getDateHeaderText(props.date, 'E')}</div>
        <div style={{ lineHeight: 1.5, fontSize: 15, textAlign: 'center' }}>{getDateHeaderText(props.date, 'd')}</div>
      </div>
    );
  }

  const customResourceHeaderTemplate = (props) => {
    const checkResourceMockData = props.resourceData.Id.toString().indexOf('mockdata');
    const pathImage = getPathImage(props);
    return (
      checkResourceMockData ? 
        <div className='shift-template-wrap'>
          <Avatar size={32} className='avatar-border' src={pathImage} />
          <span className="shift-scheduler-column-name">{getName(props)}</span>
        </div> : <div></div>
    );
  };

  const onEventRendered = (args) => {
    let height = 38;
    let left = parseInt(args.element.style.left) + 6;
    args.element.style.height = height + 'px';
    args.element.style.color = '#ffffff';
    args.element.style.backgroundColor = args.data.Color;
    args.element.style.margin = '0px';
    args.element.style.borderRadius = '3px';
    args.element.style.left = left + 'px';
    args.element.querySelector('.e-inner-wrap').style.padding = '0px 12px';
    args.element.querySelector('.e-inner-wrap').style.fontSize = '12px';
    args.element.querySelector('.e-time').style.fontSize = '8px';
    args.element.querySelector('.e-time').innerHTML = args.data.TeamName + ' ' + args.data.Start + ' - ' + args.data.Finish;
  }

  const handleClickCell = (args) => {    
    if (args.name === "cellClick") {
      setDateStart(args.startTime);
      setDateEnd(args.startTime);
      setResourceCellgroupIndex(args.groupIndex);
      setResourceCellArgs(args);
      setFormVisible(true);
      // setRefreshApiShift(false);
      // setStateShiftsMain.setRefreshApiShift(false);
    }
  }

  const handleClickEvent = (args) => {
    if (args.event.isHoliday || args.event.isLeave) return;
    if (args.name === "eventClick") {
      fncCheckDeleteShift(args.event.Id, args.event.ThisOrgSelect);
      setFormData({
        Id: args.event.Id,
        Subject: args.event.Subject,
        Color: args.event.Color,
        memId: args.event.ResourceId,
        memComId: args.event.MemComId,
        pathImage: process.env.REACT_APP_IMG_HOST + args.event.PathImage,
        name: args.event.Name,
        email: args.event.Email,
        phone: args.event.Phone,
        startDate: moment(args.event.StartTime).locale(langValue.toLowerCase()).format('DD/MM/YYYY hh:mm A'),
        endDate: moment(args.event.EndTime).locale(langValue.toLowerCase()).format('DD/MM/YYYY hh:mm A')
      });
      // setRefreshApiShift(false);
      // setStateShiftsMain.setRefreshApiShift(false);
      setVisibleDelete(true);
    }
  }

  useMemo(() => {
    resourceData_Sch.filter((obj, index) => index === resourceCellgroupIndex).map(item => {
      setFormData({
        memId: item.Id,
        memComId: item.memComId,
        pathImage: item.pathImage,
        name: item.Text,
        email: item.email || '',
        phone: item.phone || '',
        startDate: resourceCellArgs.startTime,
        endDate: resourceCellArgs.endTime,
      });
    });
    setRangePickerValue([resourceCellArgs.startTime, resourceCellArgs.endTime]);
  }, [resourceCellArgs]);

  const handleActionBegin = async (args) => {
    if (args.requestType === 'eventCreate') {
      args.cancel = true;
      const resourceIndex = _.findIndex(resourceData_Sch, function (o) { return o.Id === parseInt(args.data[0].ResourceId); });
      const resourceDetail = {...resourcesArr[resourceIndex]};
      const shiftIndex = _.findIndex(app.state.shiftsType, function (o) { return o.name === args.data[0].EventType; });
      const shiftDetail = app.state.shiftsType[shiftIndex];

      try {
        const payload = {
          comId: comId,
          orgId: orgIdSelect,
          // comId: stateShiftsMain.comIdMain,
          // orgId: stateShiftsMain.treeOrgIdMain,
          memId: resourceDetail.mem_id,
          memOrgId: resourceDetail.mem_org_id,
          shiftId: shiftDetail.shift_id,
          from: moment(args.data[0].StartTime).format('YYYY-MM-DD'),
          to: moment(args.data[0].EndTime).format('YYYY-MM-DD')
        }

        const response = await httpClient.post('/members-shifts', payload);
        if (response.status === 200) {
          if (!response.data.reject) {
            await fncSetShift(moment(args.data[0].StartTime).format('YYYY-MM'));
            notification({ status: 'success', message: 'Add shift successfully' });
          }
          else {
            args.cancel = true;
            notification({ status: 'error', message: response.data.reject });
          }
        }
        else {
          args.cancel = true;
          notification({ status: 'error', message: response.data.data });
        }
      }
      catch (error) {
        args.cancel = true;
      }
    }

    if (args.requestType === 'eventRemove') {
      const deleteShift = await httpClient.delete(`/members-shifts/${args.data[0].Id}`);
      if (deleteShift.data.status === 200) {
        notification({ status: 'success', message: deleteShift.data.data });
      }
      else {
        notification({ status: 'error', message: deleteShift.data.data });
      }
    }
  }

  const fncSetShift = async (yearMonth) => {
    const shifts = await getShift(`${yearMonth}`);
    const setShifts = generateEvents(shifts);
    scheduleObj.current.eventSettings.dataSource = setShifts;
  }

  const getShift = async (thisDate) => {
    const data = {
      // com_id: stateShiftsMain.comIdMain,
      // org_id: stateShiftsMain.treeOrgIdMain,
      com_id: comId,
      org_id: orgIdSelect,
      search_team: {
        name: '',
      },
      members: {
        conditional: [],
        order: 'fullname ASC',
        limit: '10',
        index_page: '1',
      },
      services: {},
      shift: {
        date: `${thisDate}`
      },
    };

    try {
      const response = await httpClient.post('/v2/organizationdetails', data);
      if (response.status === 200) {
        return response.data.shift
      }
      else {
        return false;
      }
    }
    catch (error) {
      return false;
    }
  }

  const generateEvents = (data) => {
    const shiftsArr = [];
    if (data) {
      data.forEach((object) => {
        object.shifts.forEach((element) => {
          const { mem_shift_id, shift_name, shift_date, mem_id, color } = element;
          shiftsArr.push({
            Id: mem_shift_id,
            Subject: shift_name,
            StartTime: moment(shift_date).format('YYYY-MM-DD 00:00'),
            EndTime: moment(shift_date).format('YYYY-MM-DD 23:59'),
            Color: color,
            ResourceId: mem_id,
            IsAllDay: true
          });
        });
      });
    }
    return shiftsArr;
  }

  const handlePopupOpen = (args) => {
    args.cancel = true;
    if (args.type === 'Editor') {
      if (args.data.isReadonly) args.cancel = true;
    }

    if (args.type === 'QuickInfo') {
      if (!_.isInteger(args.data.Id)) {
        args.cancel = true;
      }
    }

    if (args.type === 'DeleteAlert') {
      // args.cancel = true;
    }
  };

  const getPathImage = (value) => {
    return value.resourceData.pathImage;
  };

  const getName = (value) => {
    return value.resourceData[value.resource.textField];
  };
  
  const getDateHeaderText = (value, skeleton) => {
    return instance.formatDate(value, { skeleton: skeleton });
  }

  const handleAddShiftSubmit = async (e) => {
    formRef.validateFields(async (err, values) => {
      if (err) {
        return;
      }
      const resourceIndex = _.findIndex(resourceData_Sch, function (o) { return o.Id === parseInt(formData.memId); });
      const resourceDetail = { ...resourcesArr[resourceIndex] };


       try {
        const body = {
          // comId: stateShiftsMain.comIdMain,
          // orgId: stateShiftsMain.treeOrgIdMain,
          comId: comId,
          orgId: orgIdSelect,
          memId: resourceDetail.mem_id,
          memOrgId: resourceDetail.mem_org_id,
          shiftId: values.shift,
          from: moment(values.range_picker[0]).format('YYYY-MM-DD'),
          to: moment(values.range_picker[1]).format('YYYY-MM-DD')
        }


        const response = await httpClient.post(`/v2/members-shifts/create`, body);       

        if (response.status === 200) {
          successNotification(response.data.message);
          formRef.resetFields();
          setFormVisible(false);
          setRefreshApiShift(!refreshApiShift);
          // setStateShiftsMain.setRefreshApiShift(true);
        } else {
          errorNotification(response.data.data);
          formRef.resetFields();
          setFormVisible(false);
        }
      } catch (error) {
        setFormVisible(false);
        formRef.resetFields();
      }
    })
  }

  const handleDeleteShift = async () => {
    try {
      const response = await httpClient.delete(`/v2/members-shifts/delete/${formData.Id}`);
      if (response.status === 200) {
        successNotification(response.data.data);
        setVisibleDelete(false)
        setRefreshApiShift(!refreshApiShift);
        // setStateShiftsMain.setRefreshApiShift(true);
      } else {
        errorNotification(response.data.data);
        setVisibleDelete(false)
      }
    } catch (error) {
    }
  }

  const fncCheckDeleteShift = async (memShiftId, ThisOrgSelect) => {

    if (memShiftId) {
      try {
        const response = await httpClient.get(`/v3/resource/manager/company/${comCode}/validate-delete/member-shift/${memShiftId}?orgId=${ThisOrgSelect}`);
        if (response.status === 200) {
          await setDisabledBtnDelete(response.data.data.hasClockIn);
          // successNotification(response.data.status.message);
        }
      } catch (error) {

        setDisabledBtnDelete(true);
        // warningNotification('You can not delete shift');
      };
    }
  }
 
  const handleCancelAddShift = () => {
    setFormVisible(false);
    formRef.resetFields();
  }
 
  const saveFormRef = useCallback(node => {
    if (node !== null) {
      setFormRef(node);
    }
  }, []);

  const successNotification = (message) => {
    notification.success({
      message: message,
    });
  };

  const errorNotification = (message) => {
    notification.error({
      message: message,
    });
  };

  return (
    // <ShiftContext.Consumer>
    //   {({ stateShiftsMain }) => (
        <div className="shift-schedule-div">
          <ScheduleComponent
            ref={scheduleObj}
            cssClass='shift-schedule-cssClass'
            id="schedule"
            width="100%"
            height="unset"
            delayUpdate="true"
            rowAutoHeight={true}
            showHeaderBar={false}
            showQuickInfo={false}
            currentView='TimelineMonth'
            workDays={[0, 1, 2, 3, 4, 5, 6]}
            group={{ resources: ['Resources'] }}
            selectedDate={mainShiftsDateTimePicker}
            renderCell={customRenderCell}
            dateHeaderTemplate={dateHeaderTemplate}
            resourceHeaderTemplate={customResourceHeaderTemplate}
            popupOpen={handlePopupOpen}
            eventRendered={onEventRendered}
            eventClick={handleClickEvent}
            cellClick={handleClickCell}
            // actionBegin={handleActionBegin}
            eventSettings={{ 
              dataSource: shiftsData_Sch,
            }}
          >
            <ViewsDirective>
              <ViewDirective option='TimelineMonth' />
            </ViewsDirective>
            <ResourcesDirective>
              <ResourceDirective
                field='ResourceId'
                title='Resource'
                name='Resources'
                allowMultiple={false}
                textField='Text'
                idField='Id'
                colorField='Color'
                dataSource={resourceData_Sch}
              />
            </ResourcesDirective>
            <Inject services={[ TimelineMonth ]} />
          </ScheduleComponent>

          <ShiftFormAddShift 
            ref={saveFormRef}
            formData={formData}
            dateStart={dateStart}
            dateEnd={dateEnd}
            setDateStart={setDateStart}
            setDateEnd={setDateEnd}
            onCreate={() => handleAddShiftSubmit()} 
            onCancel={() => handleCancelAddShift()}
            rangePickerValue={rangePickerValue}
            visible={formVisible}
            rangePickerDisabled={'false'}
            shiftsType={app.state.shiftsType}
          />

          <Modal
            className="shift-modal-schedule"
            title={intl.formatMessage({ id: 'orgShiftPlanningModalTitleDelete', defaultMessage: 'Delete Shift' })}
            centered={true}
            width={550}
            visible={visibleDelete}
            onOk={() => handleDeleteShift()}
            onCancel={() => setVisibleDelete(false)}
            footer={[
              <Button02 style={{margin : '0px 0px 0px 10px'}} key="back" fontsize="sm" btnsize="wd_df" onClick={() => setVisibleDelete(false)}>
                <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
              </Button02>,
              disabledBtnDelete === false ? (
                <Button01 key="submit" fontsize="sm" btnsize="wd_df" type="primary" onClick={() => handleDeleteShift()} disabled={disabledBtnDelete}>
                  <FormattedMessage id="btnDelete" defaultMessage="Delete" />
                </Button01>
              ) : null
            ]}
          >
            <ShiftModalDelete formData={formData} />
          </Modal>
        </div>
    //   )}
    // </ShiftContext.Consumer>
  )
}, (prev, next) => {


  // if (_.isMatch(prev.taskData, next.taskData)) {

  // }
  return _.isMatch(prev.resourceShiftData, next.resourceShiftData) && _.isMatch(prev.shiftsData, next.shiftsData)
});