import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import httpClient from '../components/axiosClient';

const comCode = localStorage.getItem('comCode');

export const getTabs = async () => {
  let response = [];

  try {
    const getData = await httpClient.get(`/v3/business-partner/manager/company/${comCode}/customer-engagement/get/filter/web`);
    if (_.get(getData.data, 'status.code') === 200) {
      response = _.get(getData.data, 'data');
    }
  }
  catch (e) {
    response = [];
  }

  return response;
}

export const getCustomer = async (page, limit, team, activity, checked, search) => {
  let response = {
    customers: [],
    totalCustomer: 0
  };

  try {
    const payload = {
      indexStart: ((page - 1) * limit) + 1,
      indexEnd: page * limit,
      filter: {
        team: team ? [_.toString(team)] : [],
        activities: activity ? [activity] : [],
        search: search ? search : ''
      }
    };
    const getData = await httpClient.post(`/v3/business-partner/manager/company/${comCode}/customer-engagement/get/filter/data/customer-engagement`, payload);
    if (_.get(getData.data, 'status.code') === 200) {
      const customers = _.get(getData.data, 'data');
      response = {
        customers: _.map(customers, (o) => {
          return {
            customerId: o.customerId,
            fullname: o.fullname,
            pathImage: o.pathImage,
            customerType: o.customerType,
            businessType: o.businessType,
            audience: o.audience,
            selected: checked
          }
        }),
        totalCustomer: _.get(getData.data, 'total')
      }
    }
  }
  catch (e) {
    response = {
      customers: [],
      totalCustomer: 0
    };
  }

  return response;
}

export const getCustomerOne = async (customerId) => {
  let response = {};

  try {
    const customer = await httpClient.get(`/v3/business-partner/manager/company/${comCode}/customer-informations/${customerId}`);
    if (_.get(customer.data, 'status.code') === 200) {
      response['customerId'] = _.get(customer.data, 'data.customerId');
      response['fullname'] = _.get(customer.data, 'data.customerName');
      response['pathImage'] = _.get(customer.data, 'data.profileImg');
      response['customerType'] = _.get(customer.data, 'data.customerType.customerTypeName');
      response['businessType'] = _.get(customer.data, 'data.businessType.businessTypeName');
      response['audience'] = _.get(customer.data, 'data.selectedPrivacy.code');
      response['selected'] = true;
    }
  }
  catch (e) {
    response = {};
  }

  return response;
}

export const getMember = async (page, limit, orgId, checked) => {
  let response = {
    members: [],
    totalMember: 0
  };

  try {
    const payload = {
      indexStart: ((page - 1) * limit) + 1,
      indexEnd: page * limit,
      orgId: orgId ? [orgId] : []
    };
    const getData = await httpClient.post(`/v3/business-partner/manager/company/${comCode}/customer-engagement/get/member/loadmore/data/customer-engagement`, payload);
    if (_.get(getData.data, 'status.code') === 200) {
      const members = _.get(getData.data, 'data');
      response = {
        members: _.map(members, (o) => {
          return {
            memComId: o.memComId,
            fullname: o.fullname,
            phone: o.phone,
            pathImage: o.profileImg,
            selected: checked
          }
        }),
        memComIds: _.get(getData.data, 'MemberAll'),
        totalMember: _.get(getData.data, 'totalMember')
      }
    }
  }
  catch (e) {
    response = {
      members: [],
      totalMember: 0
    };
  }

  return response;
}

export const getEngagementTimeline = async ({ txtSearch, startDate, endDate, filter, orderBy, orderType, tab, orgId }) => {
  let response = [];

  let payload = {
    search: txtSearch,
    startDate: startDate,
    endDate: endDate,
    filter: filter,
    orderBy: orderBy,
    orderType: orderType,
    indexStart: 1,
    indexEnd: 1000,
    tab: tab,
    orgId: orgId
  };

  try {
    const getData = await httpClient.post(`/v3/post/manager/company/${comCode}/post-engagement/search`, payload);
    if (_.get(getData, 'data.status.code') === 200) {
      const posts = _.get(getData, 'data.data');
      response = setPostData(posts);
    }
    else {
      response = [];
    }
  }
  catch (e) {
    response = [];
  }

  return response;
}

export const addPost = async (data) => {
  const { remark, lat, lng, entityRef, entityRefId, imageList, fileList, addressBookId } = data;

  if (!remark && !lat && !lng && !entityRef && !entityRefId) return { success: false, message: 'Please select data.' };
  if (!entityRef) return { success: false, message: 'Ref. Module Type not found.' };
  if (!entityRefId) return { success: false, message: 'Ref. ID not found.' };

  const postDoc = {
    topic: '',
    remark,
    lat,
    lng,
    entityRef,
    entityRefId,
    addressBookId,
    type: 'post'
  };

  try {
    const postNew = await httpClient.post(`/v3/post/manager/company/${comCode}/new-post`, postDoc);

    if (_.get(postNew, 'data.status.code') === 200) {
      let dataPost = _.get(postNew, 'data.data.added');
      let [dataImage, dataFile] = await Promise.all([
        uploadAttachmentPost(imageList, lat, lng, 'photo', dataPost.postId),
        uploadAttachmentPost(fileList, lat, lng, 'file', dataPost.postId)
      ]);

      return { success: true, message: _.get(postNew, 'data.status.message'), data: { dataPost, dataImage, dataFile } };
    }
    else {
      return { success: false, message: _.get(postNew, 'data.status.message') };
    }
  }
  catch (e) {
    return { success: false, message: _.get(e, 'response.data.error') };
  }
}

export const editPost = async (data, postId) => {
  const { remark, lat, lng, imageList, fileList, fileListMaster, addressBookId } = data;
  if (!postId) return { success: false, message: 'Post ID not found.' };

  const postDoc = {
    topic: '',
    remark,
    lat,
    lng,
    addressBookId
  };

  try {
    const postNew = await httpClient.put(`/v3/post/manager/company/${comCode}/post/${postId}`, postDoc);
    if (_.get(postNew, 'data.status.code') === 200) {
      let dataPost = _.get(postNew, 'data.data.edited');

      const filterAttachmentImageNew = _.filter(imageList, (o) => { return o.file ? true : false; });
      const filterAttachmentFileNew = _.filter(fileList, (o) => { return o.file ? true : false; });
      const filterAttachmentUpdate = _.concat(_.filter(imageList, (o) => { return !o.file ? true : false; }), _.filter(fileList, (o) => { return !o.file ? true : false; }));
      const filterAttachmentDelete = _.filter(fileListMaster, (o) => { return !_.find(imageList, ['attachmentId', o.attachmentId]) && !_.find(fileList, ['attachmentId', o.attachmentId]) ? true : false; });

      const [
        dataImage,
        dataFile,
        attachmentUpdate,
        dataDelete
      ] = await Promise.all([
        uploadAttachmentPost(filterAttachmentImageNew, lat, lng, 'photo', postId),
        uploadAttachmentPost(filterAttachmentFileNew, lat, lng, 'file', postId),
        ..._.map(filterAttachmentUpdate, (o) => { return updatePostAttachmentDescription({ description: o.description }, o.attachmentId); }),
        ..._.map(filterAttachmentDelete, (o) => { return deletePostAttachmentDescription(o.attachmentId); })
      ]);

      return { success: true, message: _.get(postNew, 'data.status.message'), data: { dataPost }, dataImage, dataFile, attachmentUpdate, dataDelete };
    }
    else {
      return { success: false, message: _.get(postNew, 'data.status.message') };
    }
  }
  catch (e) {
    return { success: false, message: _.get(e, 'response.data.error') };
  }
}

export const addPostAttachment = async (data, postId) => {
  const { description, lat, lng, type } = data;

  let postAttachmentDoc = {
    postId: postId,
    attachmentId: uuidv4(),
    description: description,
    lat: lat,
    lng: lng,
    type: type
  };

  try {
    const postAttachmentNew = await httpClient.post(`/v3/post/manager/company/${comCode}/post/new-attachment`, postAttachmentDoc);
    if (_.get(postAttachmentNew, 'data.status.code') === 200) {
      let dataPostAttachment = _.get(postAttachmentNew, 'data.data.added');
      return { success: true, message: _.get(postAttachmentNew, 'data.status.message'), data: dataPostAttachment };
    }
    else {
      return { success: false, message: _.get(postAttachmentNew, 'data.status.message') };
    }
  }
  catch (e) {
    return { success: false, message: _.get(e, 'response.data.error') };
  }
}

export const updatePostAttachment = async (data, attachmentId) => {
  const { file, type } = data;

  let postAttachmentDoc = new FormData();
  postAttachmentDoc.append('file', file);
  postAttachmentDoc.append('type', type);

  try {
    const postAttachmentFile = await httpClient.put(`/v3/post/manager/company/${comCode}/post/new-attachment/save/file/${attachmentId}`, postAttachmentDoc);
    if (_.get(postAttachmentFile, 'data.status.code') === 200) {
      let dataPostAttachment = _.get(postAttachmentFile, 'data.data.added');
      return { success: true, message: _.get(postAttachmentFile, 'data.status.message'), data: dataPostAttachment };
    }
    else {
      return { success: false, message: _.get(postAttachmentFile, 'data.status.message') };
    }
  }
  catch (e) {
    return { success: false, message: _.get(e, 'response.data.status.message') };
  }
}

export const updatePostAttachmentDescription = async (data, attachmentId) => {
  const { description, lat, lng } = data;

  let attachmentDoc = {
    description,
    lat,
    lng
  };

  try {
    const attachment = await httpClient.put(`/v3/post/manager/company/${comCode}/post/attachment/${attachmentId}`, attachmentDoc);
    if (_.get(attachment, 'data.status.code') === 200) {
      let dataAttachment = _.get(attachment, 'data.data.edited');
      return { success: true, message: _.get(attachment, 'data.status.message'), data: dataAttachment };
    }
    else {
      return { success: false, message: _.get(attachment, 'data.status.message') };
    }
  }
  catch (e) {
    return { success: false, message: _.get(e, 'response.data.status.message') };
  }
}

export const deletePostAttachmentDescription = async (attachmentId) => {

  try {
    const attachment = await httpClient.delete(`/v3/post/manager/company/${comCode}/post/attachment/${attachmentId}`);
    if (_.get(attachment, 'data.status.code') === 200) {
      return { success: true, message: _.get(attachment, 'data.status.message') };
    }
    else {
      return { success: false, message: _.get(attachment, 'data.status.message') };
    }
  }
  catch (e) {
    return { success: false, message: _.get(e, 'response.data.status.message') };
  }
}

export const getPostOne = async (postId) => {
  let response = [];

  const postDoc = {
    postId: postId,
    tab: ['post']
  };

  try {
    const getData = await httpClient.post(`/v3/post/manager/company/${comCode}/post-engagement/search`, postDoc);
    if (_.get(getData, 'data.status.code') === 200) {
      const posts = _.get(getData, 'data.data');
      response = setPostData(posts);
    }
    else {
      response = [];
    }
  }
  catch (e) {
    response = [];
  }

  return response;
}

export const deletePostOne = async (postId) => {
  try {
    const deleteData = await httpClient.delete(`/v3/post/manager/company/${comCode}/post/${postId}`);
    if (_.get(deleteData, 'data.status.code') === 200) {
      return { success: true, message: _.get(deleteData, 'data.status.message') };
    }
    else {
      return { success: false, message: _.get(deleteData, 'data.status.message') };
    }
  }
  catch (e) {
    return { success: false, message: _.get(e, 'response.data.error') };
  }
}

const uploadAttachmentPost = async (files, lat, lng, type, postId) => {
  let dataFile = [];
  const attachmentArrFnc = [];
  if (_.size(files) > 0) {
    for (let i = 0; i < _.size(files); i++) {
      attachmentArrFnc.push(uploadAttachmentPostFile(files[i], lat, lng, type, postId));
    }
    dataFile = await Promise.all(attachmentArrFnc);
  }
  return dataFile;
}

const uploadAttachmentPostFile = async (file, lat, lng, type, postId) => {
  let dataFile = false;
  const attachmentDoc = {
    description: file.description,
    lat: lat,
    lng: lng
  };
  const attachment = await addPostAttachment(attachmentDoc, postId);
  if (attachment.success) {
    const attachmentFile = await updatePostAttachment({ file: file.file, type: type }, attachment.data.postAttachmentId);
    if (attachmentFile.success) dataFile = attachmentFile;
  }
  return dataFile;
}

const updateAttactmentDescription = async (data, attachmentId) => {
  let dataAttachment = false;
  const attachmentFile = await updatePostAttachmentDescription(data, attachmentId);
  if (attachmentFile.success) dataAttachment = attachmentFile.data;
  return dataAttachment;
}

const setPostData = (posts) => {
  const data = _.map(posts, (o) => {
    if (o.tab === 'post') {
      const actions = [];
      _.forEach(_.get(o, 'postActions'), (key, value) => {
        if (value === 'edit' && key) actions.push(value);
        if (value === 'delete' && key) actions.push(value);
      });

      const imageList = [];
      const fileList = [];
      _.forEach(_.get(o, 'attechment'), (file) => {
        if (file.type === 'photo') {
          imageList.push({
            attachmentId: file.attachmentId,
            name: file.fileName,
            pathImage: file.img,
            description: file.description,
            attachmentType: file.type
          });
        }
        else if (file.type === 'file') {
          fileList.push({
            attachmentId: file.attachmentId,
            name: file.fileName,
            pathImage: file.img,
            description: file.description,
            attachmentType: file.type
          });
        }
      });

      return {
        postId: o.postId,
        pathImage: o.profileCustomer,
        fullname: o.customerName,
        audience: o.privacy,
        postedBy: o.postBy,
        postedAt: o.postAt,
        description: o.remark,
        addressName: _.get(o, 'address.addressName') || '',
        addressLat: _.get(o, 'address.lat') || 0,
        addressLng: _.get(o, 'address.lng') || 0,
        addressBook: {
          addressBookId: _.get(o, 'addressBook[0].addressBookId'),
          addressName: _.get(o, 'addressBook[0].addressName'),
          fullAddress: _.get(o, 'addressBook[0].fullAddress'),
          address: _.get(o, 'addressBook[0].fullAaddressddress'),
          lat: _.get(o, 'addressBook[0].lat'),
          lng: _.get(o, 'addressBook[0].lng'),
        },
        imageList: imageList,
        fileList: fileList,
        actions: actions,
        customerId: o.customerId,
        moduleCode: 'post',
        moduleKey: o.postId
      }
    }
    else if (o.tab === 'quotation') {
      return {
        ...o,
        moduleCode: 'quotation',
        moduleKey: o.quotationId
      };
    }
  });
  // console.log('data api ::', data)
  return data;
}


