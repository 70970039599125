import httpClient from '../../components/axiosClient';

const getTaskMapVeiw = async (payload) => {

  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v4/task/manager/company/${comCode}/monitor/mapview`, payload);
    if (response.status == 200) {
      return response;
    } else {
      return response;
    }
  } catch (error) {
    return error.response;
  }

}

const getMemberMapView = async (docMember) => {

  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v4/resource/manager/company/${comCode}/task-map/member/organization`, docMember);
    if (response.status == 200) {
      return response;
    } else {
      return response;
    }
  } catch (error) {
    return error.response;
  }

}

export { getTaskMapVeiw, getMemberMapView };