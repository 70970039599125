import React from 'react'
import { Input, Icon } from 'antd'
import { useIntl, FormattedMessage } from 'react-intl';
import './index.css'
import _ from 'lodash'

const UOMCategory = (props) => {
  const { data, categoryId, setCategoryId, setSelectCategory, hanldeSearchUoM,setSearchConvert } = props
  const intl = useIntl();

  const handleClickCategory = (value) => {
    const filterData = _.filter(data, (item) => { return item.uomCategoryId === value })
    setCategoryId(value)
    setSelectCategory(_.get(filterData, '[0]'))
    setSearchConvert((prev) => ({...prev, page: 1, active: undefined, baseUomId: undefined, altUomId: undefined}))
  }

  return (
    <div>
      <div className='uomCategoryPannel'>
        <Input
          placeholder={intl.formatMessage({ id: 'hintSearch', defaultMessage: 'Search' })}
          prefix={<Icon style={{ color: 'rgba(0,0,0,.25)' }} type="search" />}
          autoComplete="off"
          onChange={(event) => hanldeSearchUoM(event.target.value)}
        />
      </div>

      <div style={{ overflowY: 'scroll', maxHeight: 'calc(100vh - 228px)' }}>
        {
          _.map(data, (item) => (
            <div
              key={item.uomCategoryId}
              className={`uomCateagoryText ${categoryId === item.uomCategoryId ? 'uomCateagoyActive' : ''}`}
              onClick={() => handleClickCategory(item.uomCategoryId)}
            >
              <span className='uomCateagoryTextLabel'>
                {item.name}
              </span>
            </div>
          ))
        }
      </div>
    </div>
  )
}

export default UOMCategory
