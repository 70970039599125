import httpClient from '../../components/axiosClient';

const getItemMasterDetail = async ({ itemasterId, fieldChang, fliterByColumn, fieldSort, orderSort, paginationPage, paginationSize }) => {

  const comCode = localStorage.getItem('comCode');
  const body = {
    search: fieldChang,
    searchBy: fliterByColumn,
    orderType: orderSort,
    orderBy:  fieldSort,
    paging: fieldChang === '' ? paginationPage : 1,
    rowsPerPages: paginationSize
  }
  try {
    const response = await httpClient.post(`/v3/item/manager/${comCode}/finddataitemmasterbyid/${itemasterId}`, body);
    if (response.status == 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
}

export default getItemMasterDetail;