import React, { useContext } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Card, Input, Tree, Icon } from 'antd';
import { OverTimeContext } from '../report-context';
import _ from 'lodash';
import styles from './css/organization-menu.css'

const { TreeNode } = Tree;

const dataList = [];
const generateList = (data) => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];
    const { key, name } = node;
    dataList.push({ key, title: name });
    if (node.children) {
      generateList(node.children);
    }
  }
};

const getParentKey = (key, tree) => {
  let parentKey;
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i];
    if (node.children) {
      if (node.children.some((item) => item.key === key)) {
        parentKey = node.key;
      } else if (getParentKey(key, node.children)) {
        parentKey = getParentKey(key, node.children);
      }
    }
  }
  return parentKey;
};

export default ((props) => {
  const intl = useIntl();
  const {
    state,
    setState,
    fnc,
  } = useContext(OverTimeContext);

  const menuTree = _.get(state, 'menuTree');
  generateList(menuTree);

  const onChange = e => {
    const { value } = e.target;
    const expandedKeys = dataList.map(item => {
      if (item.title.toLowerCase().indexOf(value.toLowerCase()) > -1) {
        return getParentKey(item.key, menuTree);
      }
      return null;
    })
      .filter((item, i, self) => item && self.indexOf(item) === i);
    setState.setExpandedKeys(expandedKeys);
    setState.setSearchValue(value.toLowerCase());
    setState.setAutoExpandParent(true);
  };

  const renderTreeNodes = (data) => (
    data.map(item => {
      const index = item.title.toLowerCase().indexOf(state.searchValue.toLowerCase());
      const searchMatch = index > -1 ? item.title.substr(index, state.searchValue.length) : '';
      const beforeStr = item.title.substr(0, index);
      const afterStr = item.title.substr(index + state.searchValue.length);
      const title = index > -1 ? (
        <span>
          {beforeStr}
          <span style={{ color: "#f50" }}>{searchMatch}</span>
          {afterStr}
        </span>
      ) : (
        <span>{item.title}</span>
      );
      if (item.children) {
        return (
          <TreeNode title={item.title} key={item.key} title={title}>
            {renderTreeNodes(item.children)}
          </TreeNode>
        );
      }
      return <TreeNode key={item.key} title={title} />
    })
  )

  return (
    <OverTimeContext.Consumer>
      {() => (
        <Card
          className="org-card-menu"
          title={<FormattedMessage id="reportMenuOrganizationTitle" defaultMessage="Organization" />}
        >
          <div className="org-card-menu-body">
            <div className="org-card-search-input">
              <Input
                // style={{ marginBottom: 8 }}
                placeholder={intl.formatMessage({ id: 'hintSearch', defaultMessage: 'Search' })}
                prefix={<Icon style={{ color: "rgba(0,0,0,.25)" }} type="search" />}
                onChange={onChange}
                autoComplete="off"
              />
            </div>

            <div className="org-card-menu-tree">
              <Tree
                checkable
                // selectable={false}
                onExpand={fnc.onExpand}
                expandedKeys={state.expandedKeys}
                autoExpandParent={state.autoExpandParent}
                onCheck={fnc.onCheck}
                checkedKeys={state.checkedKeys}
                defaultCheckedKeys={state.defaultCheckedKeys}
                onSelect={fnc.onSelect}
                selectedKeys={state.selectedKeys}
              >
                {renderTreeNodes(menuTree)}
              </Tree>
            </div>
          </div>
        </Card>
      )}
    </OverTimeContext.Consumer>
  )
})