import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Avatar, Badge, Icon, Divider } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import ModalEditCompany from '../../../components/edit-company/index';
import Button01 from '../../../components/v2/button_01';
import _ from 'lodash';
import './css/index.css';
import { PageSettings } from '../../../config/page-settings';

export default (props) => {
  const { companyProfile, setTriggerApi } = props;
  const intl = useIntl();
  const [visibleEdit, setVisibleEdit] = useState(false);
  const [companyInfoData, setCompanyInfoData] = useState(null);

  const handleEdit = () => {
    setCompanyInfoData(companyProfile);
    setVisibleEdit(true);
  };

  return (
    <div>
      <PageSettings.Consumer>
        {({ checkPermissionAction }) => (
          <div>
            <Card
              className="company-information"
              title={intl.formatMessage({ id: 'companyInformationTitleCompanyInformation', defaultMessage: 'Company Information' })}
              extra={
                <div>
                  {checkPermissionAction('P19PG1C1', 'P19PG1C1A2') ? (
                    <Button01 type="primary" styleMargin="mg_cs3" onClick={handleEdit}>
                      <FormattedMessage id="btnEdit" defaultMessage="Edit" />
                    </Button01>
                  ) : null}
                </div>
              }
            >
              <div className="company-information-container">
                <Row>
                  <Col span={5} className="company-information-avatar">
                    <Avatar size={160} src={_.get(companyProfile, 'profileImage')} />
                  </Col>
                  <Col span={19}>
                    {companyProfile ? (
                      <div className="company-information-detail">
                        <div className="company-information-section">
                          <p className="company-text-name">{_.get(companyProfile, 'comCode')}</p>
                          <Badge color="#1D3557" className="company-badge" />
                          <p className="company-text-name">{_.get(companyProfile, 'name')}</p>
                        </div>

                        <div className="company-information-detail-contact">
                          <div className="company-information-detail-contact-div">
                            <span className="company-information-text-small">
                              <FormattedMessage id="companyInformationTextTaxNo" defaultMessage="Tax No.:" />
                            </span>
                            <span className="company-information-text-default">{_.get(companyProfile, 'taxNo')}</span>
                          </div>
                          <div className="company-information-detail-contact-div">
                            <span className="company-information-text-small">
                              <FormattedMessage id="companyInformationTextPhone" defaultMessage="Phone:" />
                            </span>
                            <span className="company-information-text-default">{_.get(companyProfile, 'phone')}</span>
                          </div>
                        </div>

                        <div className="company-information-detail-address">
                          <div className="company-information-detail-address-div">
                            <p className="company-information-detail-address-text">
                              <FormattedMessage id="companyInformationTextAddress" defaultMessage="Address" />
                            </p>
                          </div>
                          <Icon type="environment" style={{ fontSize: '12px', color: '#1d3557' }} /> &nbsp;
                          <span className="company-information-text-default">{_.get(companyProfile, 'address')}</span>
                        </div>

                        <Divider className="company-information-divider" />

                        <div className="company-information-detail-date">
                          <span className="company-information-text-default">
                            <FormattedMessage id="companyInformationTextJoinedOn" defaultMessage="Joined on" />
                          </span>
                          &nbsp;
                          <span className="company-information-text-default">{_.get(companyProfile, 'joinDate')}</span>
                        </div>
                      </div>
                    ) : null}
                  </Col>
                </Row>
              </div>
            </Card>

            <ModalEditCompany
              companyInfoData={companyInfoData}
              setCompanyInfoData={setCompanyInfoData}
              visible={visibleEdit}
              setVisible={setVisibleEdit}
              setTriggerApi={setTriggerApi}
            />
          </div>
        )}
      </PageSettings.Consumer>
    </div>
  );
};
