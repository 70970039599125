import httpCiient from '../../components/axiosClient';

const comCode = localStorage.getItem('comCode');

const getBrandData = async (payload) => {
  try {
    const response = await httpCiient.post(`/v5/item/manager/company/${comCode}/web/itembrand/loadmore`, payload);
    return response;
  } catch (error) {
    return error.response;
  }
};

const getModelData = async (payload) => {
  try {
    const response = await httpCiient.post(`/v5/item/manager/company/${comCode}/web/itemmodel/loadmore`, payload);
    return response;
  } catch (error) {
    return error.response;
  }
};

const getListItem = async (payload) => {
  try {
    const response = await httpCiient.post(`/v1/warehouse/manager/company/${comCode}/web/warehouse/itemmaster/search`, payload);
    return response;
  } catch (error) {
    return error.response;
  }
};


const getListItemNew = async (payload) => {
  try {
    const response = await httpCiient.post(`/v5/item/manager/company/${comCode}/web/itemrecentlylog/searchitemmaster`, payload);
    return response;
  } catch (error) {
    return error.response;
  }
};

const getStockListById = async (id, page, size) => {
  try {
    const response = await httpCiient.get(`/v1/warehouse/manager/company/${comCode}/web/warehouse/stockbalance/${id}?pageNumber=${page}&limit=${size}`);
    return response;
  } catch (error) {
    return error.response;
  }
};

const getConvertUoM = async (id) => {
  try {
    const response = await httpCiient.get(`/v5/item/manager/company/${comCode}/web/uomconvert/itemmaster/${id}`);
    return response;
  } catch (error) {
    return error.response;
  }
};

const getConvertUoMBigger = async (id) => {
  try {
    const response = await httpCiient.get(`/v5/item/manager/company/${comCode}/web/uomconvertbigger/itemmaster/${id}`);
    return response;
  } catch (error) {
    return error.response;
  }
};

const getStatusList = async (id) => {
  try {
    const response = await httpCiient.get(`/v1/po/manager/company/${comCode}/postatus/list`);
    return response;
  } catch (error) {
    return error.response;
  }
};

const getStatusPaymentList = async (id) => {
  try {
    const response = await httpCiient.get(`/v1/po/manager/company/${comCode}/popaymentstatus/list`);
    return response;
  } catch (error) {
    return error.response;
  }
};

const searchPo = async (body) => {
  try {
    const response = await httpCiient.post(`/v1/po/manager/company/${comCode}/poheader/report`, body);
    return response;
  } catch (error) {
    return error.response;
  }
};


const getDeliveryType = async () => {
  try {
    const response = await httpCiient.get(`/v1/po/manager/company/${comCode}/deliverytype`);
    return response;
  } catch (error) {
    return error.response;
  }
}

const getStockList = async (body) => {
  try {
    const response = await httpCiient.post(`/v1/warehouse/manager/company/${comCode}/web/warehouse/instock`, body);
    return response;
  } catch (error) {
    return error.response;
  }
}

const getTaxList = async () => {
  try {
    const response = await httpCiient.get(`/v1/po/manager/company/${comCode}/potax`);
    return response;
  } catch (error) {
    return error.response;
  }
}

const savePo = async (body) => {
  try {
    const response = await httpCiient.post(`/v1/po/manager/company/${comCode}/poheader`, body);
    return response;
  } catch (error) {
    return error.response;
  }
}

const updateStatusPo = async (id, body) => {
  try {
    const response = await httpCiient.put(`/v1/po/manager/company/${comCode}/poheader/status/${id}`, body);
    return response;
  } catch (error) {
    return error.response;
  }
}

const getPoAttachment = async (id) => {
  try {
    const response = await httpCiient.get(`/v1/po/manager/company/${comCode}/poattachment/${id}`);
    return response;
  } catch (error) {
    return error.response;
  }
}

const uploadPoAttachment = async (formData) => {
  try {
    const response = await httpCiient.post(`/v1/po/manager/company/${comCode}/poattachment`, formData);
    return response;
  } catch (error) {
    return error.response;
  }
}

const updatePoAttachment = async (fileHash, formData) => {
  try {
    const response = await httpCiient.put(`/v1/po/manager/company/${comCode}/poattachment/${fileHash}`, formData);
    return response;
  } catch (error) {
    return error.response;
  }
}

const deletePoAttachment = async (fileHash, formData) => {
  try {
    const response = await httpCiient.delete(`/v1/po/manager/company/${comCode}/poattachment/${fileHash}`);
    return response;
  } catch (error) {
    return error.response;
  }
}

const getPoDetail = async (id) => {
  try {
    const response = await httpCiient.get(`/v1/po/manager/company/${comCode}/poheader/${id}`);
    return response;
  } catch (error) {
    return error.response;
  }
}

const getPoReason = async () => {
  try {
    const response = await httpCiient.get(`/v1/po/manager/company/${comCode}/reason`);
    return response;
  } catch (error) {
    return error.response;
  }
}

const GetSignaturePrintPO = async (memComId) => {

  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpCiient.get(`/v4/resource/manager/company/${comCode}/uploads/type/member-signature-image/member/${memComId}`)
    if (response.status === 200) {

      return response.data
    } else {
      return []
    }
  } catch (error) {
  }
}

const POPDFDownloader = async (no, payload) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpCiient.post(`/v1/po/manager/company/${comCode}/print-pdf/${no}`, payload, {
      responseType: 'blob'
    })
    if (response.status === 200) {
      return response
    } else {
      return []
    }
  } catch (error) {
  }
}

const POPDFDownloaderA5 = async (no, payload) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpCiient.post(`/v1/po/manager/company/${comCode}/print-pdf-a5/${no}`, payload, {
      responseType: 'blob'
    })
    if (response.status === 200) {
      return response
    } else {
      return []
    }
  } catch (error) {
  }
}

const getVehicleList = async (body) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpCiient.post(`/v5/item/manager/company/${comCode}/web/asset/vehiclepo/loadmore`, body)
    if (response.status === 200) {
      return response.data
    } else {
      return []
    }
  } catch (error) {
  }
}

const getStockDelivery = async (body) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpCiient.post(`/v1/warehouse/manager/company/${comCode}/web/warehouse/instock/itemlistqty`, body)
    if (response.status === 200) {
      return response.data
    } else {
      return []
    }
  } catch (error) {
  }
}

const getMemberIssueBy = async (payload) => {
  try {
    const response = await httpCiient.post(`/v3/item/manager/${comCode}/getlistmemberloadmore`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export {
  getBrandData, getModelData, getListItem, getStockListById, getConvertUoM, getStatusList, searchPo,
  getDeliveryType, getStockList, getTaxList, savePo, updateStatusPo, getPoAttachment, uploadPoAttachment,
  updatePoAttachment, deletePoAttachment, getPoDetail, getPoReason, GetSignaturePrintPO, getVehicleList, getStockDelivery,
  getConvertUoMBigger, POPDFDownloader, getListItemNew, getStatusPaymentList, POPDFDownloaderA5, getMemberIssueBy,
}