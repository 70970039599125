import _ from 'lodash';
import httpClient from '../../components/axiosClient';
const comCode = localStorage.getItem('comCode');

const getReimbursementList = async (payload) => {
  try {
    // const response = await httpClient.post(`/v3/project/manager/${comCode}/getFiles`, payload);
    const response = [
      {
        index: '1',
        reimbursement: 'Travel',
        limit: 5000,
        remark: 'Annual trip limit',
        updateBy: 'John Doe',
        updateDate: '2024-12-04',
      },
      {
        index: '2',
        reimbursement: 'Health',
        limit: 10000,
        remark: 'Health insurance claims',
        updateBy: 'Jane Smith',
        updateDate: '2024-12-01',
      },
      {
        index: '3',
        reimbursement: 'Food',
        limit: 2000,
        remark: 'Monthly food allowance',
        updateBy: 'Alice Johnson',
        updateDate: '2024-11-30',
      },
    ];
    return response;
  } catch (error) {
    return error.response.data;
  }
};

const getGasList = async (payload) => {
  try {
    // const response = await httpClient.post(`/v3/project/manager/${comCode}/getFiles`, payload);
    const response = [
      {
        index: '1',
        gasType: 'Diesel',
        gasPrice: 35.5,
        effectiveStartDate: '2024-01-01',
        effectiveEndDate: '2024-12-31',
        updateBy: 'John Doe',
        updateDate: '2024-12-04',
      },
      {
        index: '2',
        gasType: 'Petrol',
        gasPrice: 38.2,
        effectiveStartDate: '2024-06-01',
        effectiveEndDate: '2024-12-31',
        updateBy: 'Jane Smith',
        updateDate: '2024-12-01',
      },
      {
        index: '3',
        gasType: 'LPG',
        gasPrice: 22.5,
        effectiveStartDate: '2024-03-01',
        effectiveEndDate: '2024-09-30',
        updateBy: 'Alice Johnson',
        updateDate: '2024-11-30',
      },
    ];
    return response;
  } catch (error) {
    return error.response.data;
  }
};




export {
  getReimbursementList,
  getGasList
}