import { Col, DatePicker, Divider, Form, Row, Select, Input, Icon, Badge } from 'antd';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import LabeRequireForm from '../../../../../components/label-required-form';
// import ModalCustomerQuotationForm from '....//select-customer/customer-modal';
import _ from 'lodash';
import moment from 'moment';
import { numberFormatter } from '../../../../../component-function/fnc-inputnumber-currency';

const { Option } = Select;
const { Search } = Input;
const dateFormat = 'DD/MM/YYYY';

const CustomerFormViewinvoice = ({ dataForm, form,detailinvoice  }) => {
  const intl = useIntl();
  const { getFieldDecorator } = form;
  const memComId = localStorage.getItem('memComId');

  const MemberOption = (o, i) => {
    return (
      <Option key={i} value={o.memComId}>
        {o.fullname}
      </Option>
    );
  };

  return (
    <Form name="customer" colon={false}>
      {/* <ModalCustomerQuotationForm visible={isOpen} onCancel={handleCancel} onOk={handleSave} setDataForm={setDataForm} dataForm={dataForm} /> */}

      <Row gutter={[24]}>
        <Col span={8}>
          <Form.Item
            label={<LabeRequireForm bold={true} text={intl.formatMessage({ id: 'quotationLabelCustomer', defaultMessage: 'Customer' })} req={false}  />}
            className="form-line-height"
          >
            {/* {_.get(dataForm, 'customerName') || '-'} */}

            <div className="text-group-customer">
              <div className="text-location-short">
                <span style={{ color: '#050505' }}>{_.get(dataForm, 'customerName')}</span>
              </div>
            </div>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={
              <LabeRequireForm bold={true}
                text={intl.formatMessage({ id: 'quotationLabelCustomerAddress', defaultMessage: 'Customer Address' })}
                req={false}
              />
            }
            className="form-line-height"
          >
            <div className="text-group-customer">
              <div className="text-location-short" style={{ textOverflow: 'unset' }}>
                <Icon
                  type="environment"
                  style={{
                    fontSize: '12px',
                    color: '#1D3557',
                    paddingLeft: '0px',
                    paddingRight: '4px',
                    cursor: 'pointer',
                  }}
                />
                <span>
                  {_.get(dataForm, 'customerAddressBookName')
                    ? _.get(dataForm, 'customerAddressBookName')
                    : _.get(dataForm, 'customerAddress') || '-'}
                </span>
              </div>
              <div className="text-short-placeholer">
                <span style={{ color: '#898989' }}>
                  {intl.formatMessage({ id: 'quotationLabelTaxNo', defaultMessage: 'Tax No.' })} {_.get(dataForm, 'customerTaxNo') || '-'}
                </span>
              </div>
            </div>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            label={
              <LabeRequireForm bold={true} text={intl.formatMessage({ id: 'quotationLabelCustomerContact', defaultMessage: 'Contact' })} req={false} />
            }
            className="form-line-height"
          >
            <div className="text-group-customer">
              <div className="text-location-short">
                <span style={{ color: '#050505' }}>
                  {_.get(dataForm, 'customerContactName') ? _.get(dataForm, 'customerContactName') : _.get(dataForm, 'customerContact')}
                </span>
              </div>
              <div className="text-location-short">
                <span style={{ color: '#050505' }}>
                  {_.get(dataForm, 'customerContactPhone') ? _.get(dataForm, 'customerContactPhone') : _.get(dataForm, 'customerPhone')},{' '}
                  {_.get(dataForm, 'customerContactEmail') ? _.get(dataForm, 'customerContactEmail') : _.get(dataForm, 'customerEmail')}
                </span>
              </div>
            </div>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[24]}>

      <Col span={8}>
          <Form.Item
            label={<LabeRequireForm bold={true} text={intl.formatMessage({ id: 'ARSaleOrderDate', defaultMessage: 'Sale Order Date' })} req={false} />}
          >
            {_.get(dataForm, 'issueDate') ? moment(_.get(dataForm, 'issueDate')).format(dateFormat) : '-'}
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            label={<LabeRequireForm bold={true} text={intl.formatMessage({ id: 'ARSalesOrderTotal', defaultMessage: 'Sales Order Total' })} req={false} />}
          >
            {numberFormatter( _.get(dataForm, 'total')) || '-'} <FormattedMessage id="quotationLabelTHB" defaultMessage="THB" />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            label={<LabeRequireForm bold={true} text={intl.formatMessage({ id: 'ARSOIssuedby', defaultMessage: 'SO Issued by' })} req={false} />}
          >
            {_.get(dataForm, 'issuedName') || '-'}
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[24]}>
        <Col span={8}>
          <Form.Item
            label={<LabeRequireForm bold={true} text={intl.formatMessage({ id: 'ARInvoiceAmount', defaultMessage: 'Invoice Amount' })} req={false} />}
          >
            {numberFormatter(_.get(detailinvoice, 'invAmount')) + ' / ' + numberFormatter( _.get(dataForm, 'total')) || '-'} <FormattedMessage id="quotationLabelTHB" defaultMessage="THB" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={<LabeRequireForm bold={true} text={intl.formatMessage({ id: 'ARRemainingAmount', defaultMessage: 'Remaining Amount' })} req={false} />}
          >
            {numberFormatter(_.get(detailinvoice, 'invAmountRemain')) || '-'} <FormattedMessage id="quotationLabelTHB" defaultMessage="THB" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={
              <LabeRequireForm bold={true} text={intl.formatMessage({ id: 'ARTotalInvoice', defaultMessage: 'Total Invoice' })} req={false} />
            }
          >
            {_.get(detailinvoice, 'invCount') || '-'}
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

CustomerFormViewinvoice.defaultProps = {};

export default CustomerFormViewinvoice;
