import React, { useContext } from 'react'
import { Row, Col, Icon, Timeline, Badge, Tooltip } from 'antd'
import styled from 'styled-components';
import { DeliveryShipmentDetailContext } from '..';
import _ from 'lodash'
import moment from 'moment';
import { FormattedMessage } from 'react-intl';

const DeliveryOrderTracking = () => {
  const { orderData, trackingData } = useContext(DeliveryShipmentDetailContext)
  const lang = localStorage.getItem('langValue')
  const StatusName = styled.span`
  font-weight: bold;
  font-size: 13px;
  color: #1d3557;
`;

  const TimeStamp = styled.span`
  font-size: 12px;
  color: #1d3557;
`;

  let arry = []
  for (let index = 0; index < _.size(trackingData.statusGroups); index++) {
    const element = trackingData.statusGroups[index];
    arry.push({
      ...element,
      type: 'icon'
    })
    if ((index + 1) !== _.size(trackingData.statusGroups)) arry.push({ type: 'cursor' })
  }

  const statusBackGround = (groupCode) => {
    const nothighlight = '#909AAF'
    const highlight = '#6490CF'
    const lastTracking = _.get(trackingData, 'statusTracking[0].orderStatus')
    if (lastTracking === 'open' || lastTracking === 'confirmed') {
      if (groupCode === 'toShip_status') {
        return highlight
      } else {
        return nothighlight
      }
    } else if (lastTracking === 'sorting') {
      if (groupCode === 'sorting_status') {
        return highlight
      } else {
        return nothighlight
      }
    } else if (lastTracking === 'ready' || lastTracking === 'outfordelivery') {
      if (groupCode === 'delivering_status') {
        return highlight
      } else {
        return nothighlight
      }
    } else {
      if (groupCode === 'completed_status') {
        return highlight
      } else {
        return nothighlight
      }
    }
  }

  console.log("arry", arry)

  return (
    <div style={{ padding: '24px', height: 'calc(100vh - 165px)', overflowY: 'auto' }}>
      <div>
        <Row gutter={[16]}>
          <Col span={9}>
            <div style={{ fontWeight: 600, fontSize: '12px', color: '#1D3557', marginBottom: '8px' }}>
              <FormattedMessage id="deliveryTrackingTripNo" defaultMessage="Trip No." />
            </div>
            <div style={{ fontSize: '13px', color: '#505050' }}>
              {_.get(orderData, 'tripNo') || '-'}
            </div>
          </Col>
          <Col span={9}>
            <div style={{ fontWeight: 600, fontSize: '12px', color: '#1D3557', marginBottom: '8px' }}>
              <FormattedMessage id="deliveryTrackingTaskNo" defaultMessage="Task No." />
            </div>
            <div style={{ fontSize: '13px', color: '#505050' }}>
              {_.get(orderData, 'taskNo') || '-'}
            </div>
          </Col>
          <Col span={6}>
            <div
              style={{
                backgroundColor: '#F2F3F5',
                width: '123px',
                height: '40px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '10px',
                cursor:'pointer'
              }}
              onClick={() => window.open(_.get(orderData, 'linkTracking'), "_blank")}
            >
              <span style={{ color: '#050505', fontSize: '13px' }}><FormattedMessage id="deliveryTrackingTrackinglink" defaultMessage="Tracking link" /></span>{" "}
              <Icon type="link" style={{ color: '#6490CF' }} />
            </div>
          </Col>
        </Row>
      </div>
      <div style={{ marginTop: '32px', display: 'flex', justifyContent: 'space-around' }}>
        {_.map(arry, (item, index) => {
          return (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              {
                item.type === "icon" ? (
                  <div>
                    <div style={{ width: '60px', height: '60px', backgroundColor: statusBackGround(item.statusGroupCode), borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <object style={{ width: '45px', height: '45px', filter: 'brightness(0) invert(1)' }} data={item.statusGroupImage} type="image/svg+xml">
                      </object>
                    </div>
                    <Tooltip title={item.statusGroupDes[lang]}>
                      <div style={{ marginTop: '24px', fontSize: '14px', textAlign: 'center', color: statusBackGround(item.statusGroupCode) }}>
                        {item.statusGroupTxt[lang]}
                      </div>
                    </Tooltip>
                  </div>
                ) : (
                  <div>
                    <Icon type="right" />
                  </div>
                )
              }
            </div>
          )
        }
        )}
      </div>
      <div style={{ marginTop: '32px' }}>
        <Timeline>
          {
            _.map(_.get(trackingData, 'statusTracking'), (item) => (
              <Timeline.Item key={1} dot={<Badge className="dot-badge" color={'#066738'} />}>
                <div className="div-name-comment" style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Tooltip title={item.statusDescription}>
                    <StatusName>{item.statusName}</StatusName>
                  </Tooltip>
                  <TimeStamp>{moment.unix(item.timestampUnix).format('DD/MM/YYYY HH:mm')}</TimeStamp>
                </div>
              </Timeline.Item>
            ))
          }
        </Timeline>

      </div>
    </div >
  )
}

export default DeliveryOrderTracking