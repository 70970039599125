import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Divider, Table } from 'antd';
import _ from 'lodash';
import styles from './css/index.css';

const OrderSaleReport = (props) => {
  const { itemSaleOrder ,columns } = props;
  const intl = useIntl();

  return (
    <div className="sale-oreder-report">
      <div className="sale-oreder-report-container">
        <Divider className="sale-oreder-modal-divider" />
        <div className="sale-oreder-report-div-table">
          <Table 
            className="sale-oreder-report-table" 
            rowKey={(record, index) => index}
            dataSource={itemSaleOrder} 
            scroll={itemSaleOrder && itemSaleOrder.length !== 0 ? { y: 163 } : { x: 'max-content'}}
            columns={columns} 
            pagination={false}
          />
        </div>
      </div>
    </div>
  )
}

export default OrderSaleReport;