import React from 'react'
import { Form, Row, Col, Input, Select } from 'antd'
import LabeRequireForm from '../../label-required-form/index'
import { useIntl } from 'react-intl'
import _ from 'lodash'

const { TextArea } = Input
const { Option } = Select

const ModalUOMMeasureAdd = (props) => {
  const { form, uomCategoryData, categoryId } = props
  const intl = useIntl()
  const { getFieldDecorator } = form
  return (
    <div>
      <Form form={form} layout="vertical">
        <Row>
          <Col span={24}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'modalCategoryUOMCategorytext', defaultMessage: 'UoM Category' })} req={true} />
              }
            >
              {getFieldDecorator('uomCategoryAdd', {
                initialValue: categoryId,
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'modalCategoryUOMCategoryValidate', defaultMessage: 'Please Enter UoM Category' }),
                  },
                ],
              })(
                <Select
                  placeholder={intl.formatMessage({ id: 'modalCategoryUOMCategoryPlaceholeder', defaultMessage: 'Enter UoM Category' })}
                >
                  {
                    _.map(uomCategoryData, (item) => (
                      <Option key={item.uomCategoryId}>
                        {item.name}
                      </Option>
                    ))
                  }
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'modalCategoryUOMtext', defaultMessage: 'UoM' })} req={true} />
              }
            >
              {getFieldDecorator('uomAdd', {
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'modalCategoryUOMValidate', defaultMessage: 'Please Enter UoM' }),
                  },
                ],
              })(
                <Input
                  placeholder={intl.formatMessage({ id: 'modalCategoryUOMPlaceholeder', defaultMessage: 'Enter UoM' })}
                  maxLength={100}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'modalCategoryUOMDescriptionText', defaultMessage: 'Description' })} req={false} />
              }
            >
              {getFieldDecorator('descriptionAdd', {
              })(
                <TextArea
                  autoSize={{ minRows: 5, maxRows: 5 }}
                />
              )}
            </Form.Item>
          </Col>
        </Row>

      </Form>
    </div >
  )
}

export default ModalUOMMeasureAdd