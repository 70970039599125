import React, { useRef, useState, useEffect } from 'react';
import { Col, Form, Input, Row, Upload, Icon, Modal } from 'antd';
import Button_01 from '../../../../components/v2/button_01';
import { FormattedMessage } from 'react-intl';
import Button_02 from '../../../../components/v2/button_02';
import _ from 'lodash';
import Resizer from 'react-image-file-resizer';
import ReactBnbGallery from 'react-bnb-gallery';
import PDFIcon from '../../../../components/image/pdf_file.png';
import { v4 as uuidv4 } from 'uuid';

const { TextArea } = Input;
const ModalUploadFile = (props) => {
  const { visible, setVisible, attData, setAttData, typeAtt, defaultAtt } = props;
  const [tempData, setTempData] = useState([]);
  const [imageSave, setImageSave] = useState();
  const [tempRemark, setTempRemark] = useState();
  const [stateModal, setStateModal] = useState(false);
  const [modalView, setModalView] = useState();
  const [disable, setDisable] = useState(false);
  const [validateImage, setValidateImage] = useState(false);

  console.log('defaultAtt', defaultAtt,'/',tempData);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    if (visible) {
      if (typeAtt === 'add') {
        setTempData([]);
        setImageSave();
        setTempRemark();
      } else if (typeAtt === 'edit') {
        setTempData(_.get(defaultAtt, 'file'));
        setImageSave(_.get(defaultAtt, 'fileRaw'));
        setTempRemark(_.get(defaultAtt, 'remark') !== 'undefined' ? _.get(defaultAtt, 'remark') : undefined);
      }
    }
  }, [typeAtt, visible]);

  const handleSave = async () => {
    console.log('handleSaveImage', imageSave, defaultAtt, tempData)
    setDisable(true);
    if (imageSave) {
      setValidateImage(false);
      if (typeAtt === 'add') {
        const temp = imageSave;
        console.log('AttSaleNameAdd', temp);
        const setobj = {
          id: uuidv4(),
          index: _.size(attData) + 1,
          attachmentName: _.get(temp, 'name'),
          remark: tempRemark,
          file: [...tempData],
          fileRaw: temp,
        };
        setAttData((oldArray) => [...oldArray, setobj]);
        setVisible(false);
        setTempData([]);
        setTempRemark();
        setImageSave();
      }
    }
    if (typeAtt === 'edit') {
      const temp = imageSave;
      const filterImage = _.filter(attData, (item) => {
        return item.id !== _.get(defaultAtt, 'id');
      });
      console.log('AttSaleNameEdit', temp);
      const setobj = [
        {
          id: _.get(defaultAtt, 'id'),
          index: _.get(defaultAtt, 'index'),
          attachmentName: _.get(temp, 'name') ? _.get(temp, 'name') : _.get(tempData, '[0].name'),
          remark: tempRemark,
          file: [...tempData],
          fileHash: _.get(defaultAtt, 'fileHash'),

        },
      ];
      setAttData(filterImage.concat(setobj));
      setVisible(false);
      setTempData([]);
      setTempRemark();
      setImageSave();
    }

    setDisable(false);
  };

  const onCancel = () => {
    setVisible(false);
    setTempData([]);
    setTempRemark();
    setImageSave();
    setValidateImage(false);
    setDisable(false);
  };

  const uploadButton = (
    <div>
      <Icon type="plus" />
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        720,
        720,
        'JPG',
        70,
        0,
        (uri) => {
          resolve(uri);
        },
        'base64'
      );
    });

  const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    for (var i = 0; i < bstr.length; i++) {
      u8arr[i] = bstr.charCodeAt(i);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const action = async (file) => {
    if (_.size(file.fileList) > 0) {
      setTempData(file.fileList);
      setImageSave(file.file);
    } else {
      setTempData([]);
      setImageSave();
    }
  };

  const handleChangeRemark = (value) => {
    setTempRemark(value);
  };

  const handleRemoveImg = async () => {
    setTempData([]);
    setImageSave();
    setDisable(false);
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleTags = (data) => {
    // if (_.get(data, 'file')) {
    const fileTag = URL.createObjectURL(data.file);
    let isPDF = /pdf/g.test(data.name.toLowerCase());
    if (isPDF) return window.open(fileTag, '_blank');
    // }
  };

  const handlePreview = async (file) => {
    if (typeAtt === 'add') {
      let isPDF = _.get(file, 'type') === 'application/pdf' ? true : false;
      if (isPDF) return window.open(_.get(file, 'url'), '_blank');
      const mapdata = {
        photo: _.get(file, 'url'),
        number: 1,
        caption: _.get(file, 'remark') !== 'undefined' ? _.get(file, 'remark') : undefined,
        thumbnail: _.get(file, 'url'),
      };
      setModalView(mapdata);
      setStateModal(!stateModal);
    } else {
      console.log('filehandlePreview', tempData);
      let isPDF = _.get(tempData, '[0].type') === 'application/pdf' ? true : false;
      if (isPDF) return window.open(_.get(tempData, '[0].url2'), '_blank');
      const mapdata = {
        photo: _.get(tempData, '[0].url'),
        number: 1,
        caption: _.get(tempData, '[0].remark'),
        thumbnail: _.get(tempData, '[0].url'),
      };
      setModalView(mapdata);
      setStateModal(!stateModal);
    }
  };

  const HandlerModal = (item) => {
    return (
      <div>
        <ReactBnbGallery show={stateModal} photos={modalView} onClose={() => setStateModal(false)} />
      </div>
    );
  };

  const LabeRequire = (props) => {
    const { text, req } = props;
    return (
      <span>
        {text}&nbsp;
        {req ? <span style={{ fontSize: '13px', color: '#ff0000' }}>*</span> : ''}
      </span>
    );
  };

  return (
    <Modal
      visible={visible}
      onCancel={() => onCancel()}
      centered={true}
      title={<FormattedMessage id="ModalTitleUploadAttachment" defaultMessage="Upload Attachment" />}
      width={400}
      bodyStyle={{ padding: '24px' }}
      footer={[
        <Button_02 key="back" btnsize="wd_df" onClick={onCancel} disabled={disable} style={{ margin: 'unset' }}>
          <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
        </Button_02>,
        <Button_01 key="submit" type="primary" btnsize="wd_df" onClick={handleSave} disabled={disable}>
          <FormattedMessage id="btnSave" defaultMessage="Save" />
        </Button_01>,
      ]}
    >
      <Row gutter={16}>
        <Col span={24}>
          <span className="formText">
            <FormattedMessage id="AssetModalTitleFormUploadFile" defaultMessage="File" />
            <LabeRequire req={true} />
          </span>
          <Upload
            className="upload-att-display"
            style={{ marginTop: '8px' }}
            onChange={action}
            listType="picture-card"
            fileList={tempData}
            onPreview={(file) => handlePreview(file)}
            onRemove={() => handleRemoveImg()}
            accept=".pdf, .jpg, .png"
            showUploadList={{
              // showPreviewIcon: checkPermissionAction('P2S1PG2C7', 'P2S1PG2C7A4') ? true : false,
              // showRemoveIcon: checkPermissionAction('P2S1PG2C7', 'P2S1PG2C7A3') ? true : false,
              showPreviewIcon: false,
              showRemoveIcon: true,
            }}
            beforeUpload={() => {
              return false;
            }}
          >
            {_.size(tempData) === 0 ? uploadButton : null}
          </Upload>
          {validateImage ? (
            <span style={{ color: '#f5222d', fontSize: '14px' }}>
              <FormattedMessage id="ModalTitleUploadAttachmentValidateImg" defaultMessage="Please select image or file." />
            </span>
          ) : null}
        </Col>
        <Col span={24}>
          <Form.Item>
            <span className="formText">
              <FormattedMessage id="AssetModalTitleFormUplaodRemark" defaultMessage="Remark" />
            </span>

            <TextArea
              autoComplete="off"
              autoSize={{ minRows: 3, maxRows: 3 }}
              onChange={(e) => handleChangeRemark(e.target.value)}
              value={tempRemark}
              maxLength={250}
            />
          </Form.Item>
        </Col>
      </Row>
      <HandlerModal />
    </Modal>
  );
};

export default ModalUploadFile;
