import httpClient from '../../components/axiosClient';

const createTeam = async (props) => {

  const comCode = localStorage.getItem('comCode');

  // console.log('createTeam props::: ', props);
  
  const body = {
    orgId: parseInt(props.orgId),
    memComId: parseInt(props.memComId),
    positionId: props.positionId
  }

  try {
    const response = await httpClient.post(`/v4/organizations/company/${comCode}/organization`, body);
    // console.log('createTeam response api::: ', response);

    if (response.status === 200) {
      return response.data;
    } else {
      return response.data;
    }
  } catch (error) {
    return error.response;
  }
}

export default createTeam;