import React, { useState, useEffect, useRef, useContext } from 'react';
import { Tabs, Row, Col, Card, Button, Icon } from 'antd';
import { PageSettings } from '../../config/page-settings';
import { MonitorContext } from './monitor-context';
import ManuMonitor from './manu-monitor/index';
import GanttView from './gantt-view/index';
import MapView from './map-view/index';
import ListView from './list-view/index';
import IconExcel from '../../components/image/excel.svg';
import httpClient from '../../components/axiosClient';
import { successNotification, errorNotification } from './notification';
import TaskDetail from '../../components/monitor-component/workDesk/task-detail/index';
import AssigneeResource from './map-view/assignee-resource-modal/index';
import _, { unset } from 'lodash';
import { useIntl, FormattedMessage } from 'react-intl';
import moment from 'moment';
import './css/index.css';
import Button01 from '../../components/v2/button_01';
import { v4 as uuidv4 } from 'uuid';
import { StickyContainer, Sticky } from 'react-sticky';
import { getinitialTeamApi } from '../../controllers/organizationTeam/get-orgTeam-api';
import CreateTask from '../../components/monitor-component/workDesk/create-task/index';

const { TabPane } = Tabs;

export default React.memo((props) => {
  const { LangCode } = props;
  // const typeApi = LangCode && LangCode === `WorkDesk` ? `menuType=workdesk` : ``
  // const app = useContext(PageSettings);
  const intl = useIntl();
  const today = new Date();
  const dateApi = 'YYYY-MM-DD';
  const dateTimeApi = 'YYYY-MM-DD HH:mm';
  const dateTitlelModal = 'ddd, MMM DD, YYYY';

  const dateNow = moment(today).format(dateApi);
  const dateNowTitlelModal = moment(today).format(dateTitlelModal);
  const ganttDateTimeNow = moment(today).format(dateTimeApi);

  const comId = localStorage.getItem('comId');
  const memComId = localStorage.getItem('memComId');

  const [mainMapViewResourceData, setMainMapViewResourceData] = useState([]);
  const [mainMapViewTaskData, setMainMapViewTaskData] = useState([]);
  const [mainGanttViewResourceData, setMainGanttViewResourceData] = useState([]);
  const [mainGanttViewTaskDataMain, setMainGanttViewTaskDataMain] = useState([]);
  const [mainGanttViewTaskData, setMainGanttViewTaskData] = useState([]);
  const [mainOrganizationTeamData, setMainOrganizationTeamData] = useState([]);

  //******************ชุดตัวแปล Action ของ Gantt, Map, List view**************************
  const [mainFieldSearch, setMainFieldSearch] = useState('');
  const [mainSearchColumn, setMainSearchColumn] = useState('all');

  const [mainDateStartAction, setMainDateStartAction] = useState(today);
  const [mainDateEndAction, setMainDateEndAction] = useState(today);

  const [mainDate, setMainDate] = useState(dateNow);
  const [mainStartDate, setMainStartDate] = useState(dateNow);
  const [mainEndDate, setMainEndDate] = useState(dateNow);
  const [mainOrgId, setMainOrgId] = useState();

  const [refreshDateAndTime, setRefreshDateAndTime] = useState(false);
  const [showGanttView, setShowGanttView] = useState(true);

  const [mainOrgIdArr, setMainOrgIdArr] = useState([]);

  var orgIDStg = mainOrgIdArr.toString();

  const [mainOrgIdArrString, setMainOrgIdArrString] = useState();
  //******************ชุดตัวแปล Action ของ Gantt**************************
  const [mainGanttViewFieldSearch, setMainGanttViewFieldSearch] = useState('');
  const [mainGanttViewSearchColumn, setMainGanttViewSearchColumn] = useState('all');
  const [mainGanttViewDateTimePicker, setMainGanttViewDateTimePicker] = useState(ganttDateTimeNow);
  const scheduleObjNew = useRef(null);
  const selectRef = useRef();
  const [refreshDataGanttView, setRefreshDataGanttView] = useState(false);
  const [refreshDataTaskPool, setRefreshDataTaskPool] = useState(false);

  //****************************************************************************/

  //******************ชุดตัวแปล Create Task **************************

  const [mainVisibleCreateTask, setMainVisibleCreateTask] = useState(false);

  //****************************************************************************/
  //******************ชุดตัวแปล Update Task **************************
  const [refreshUpdateTask, setRefreshUpdateTask] = useState(false);
  const [statusUpdate, setStatusUpdate] = useState('');
  const [statusUpdateComment, setStatusUpdateComment] = useState('');
  const [orgUpdateDefault, setOrgUpdateDefault] = useState('');
  const [orgUpdate, setOrgUpdate] = useState('');
  const [priorityUpdate, setPriorityUpdate] = useState();

  const [show, setShow] = useState(true);
  const [span, setSpan] = useState(18);

  const [summaryData, setSummaryData] = useState([]);
  const [tasks, setTasks] = useState(0);
  const [warning, setWarning] = useState(0);
  const [timeout, setTimeout] = useState(0);

  const [summaryDataGantt, setSummaryDataGantt] = useState([]);
  const [tasksGantt, setTasksGantt] = useState(0);
  const [warningGantt, setWarningGantt] = useState(0);
  const [timeoutGantt, setTimeoutGantt] = useState(0);

  const [disabled, setDisabled] = useState(false);

  const [fromDate, setFromDate] = useState(moment(today).format('YYYY-MM-DD'));
  const [toDate, setToDate] = useState(moment(today).format('YYYY-MM-DD'));

  const [rangePicker, setRangePicker] = useState();
  const [fieldChang, setFieldChang] = useState('');
  const [selectSearchData, setSelectSearchData] = useState('all');
  const [paginationPage, setPaginationPage] = useState(1);
  const [paginationSize, setPaginationSize] = useState(10);
  const [total, setTotal] = useState([]);
  const [fieldSort, setFieldSort] = useState();
  const [orderSort, setOrderSort] = useState();

  const [visibleTaskDetailModal, setVisibleTaskDetailModal] = useState(false);
  const [visibleTaskStatusModal, setVisibleTaskStatusModal] = useState(false);
  const [valueModalVisibleStatus, setValueModalVisibleStatus] = useState(false);
  const [visibleAssignee, setVisibleAssignee] = useState(false);

  const [keyTabMonitor, setKeyTabMonitor] = useState('ganttView');

  const [mainTaskId, setMainTaskId] = useState(null);
  const [mainTaskIdAssign, setMainTaskIdAssign] = useState();
  const [taskIdAddAssignee, setTaskIdAddAssignee] = useState();
  const [resourceTaskData, setResourceTaskData] = useState([]);
  const [taskInformationData, setTaskInformationData] = useState([]);

  const [mainStatusTaskObject, setMainStatusTaskObject] = useState(null);
  const [mainTextNameStatus, setMainTextNameStatus] = useState('');

  const [mainSelectedResourceId, setMainSelectedResourceId] = useState();

  const [markerStatus, setMarkerStatus] = useState('');

  const [mainResourceMemComId, setMainResourceMemComId] = useState(null);

  const [mainStatusTaskTitle, setMainStatusTaskTitle] = useState();
  const [selectTaskData, setSelectTaskData] = useState();
  const [selectResourceData, setSelectResourceData] = useState(null);

  const [mainDateTitleModal, setMainDateTitleModal] = useState(dateNowTitlelModal);
  const [modalTimeDateVisible, setModalTimeDateVisible] = useState(false);

  const [refreshTaskDetailsData, setRefreshTaskDetailsData] = useState(false);
  const [refreshTable, setRefreshTable] = useState(false);
  const [refreshTaskPool, setRefreshTaskPool] = useState(false);

  const [refreshApiGanttView, setRefreshApiGanttView] = useState(false);

  const [conutrowForExcel, setConutrowForExcel] = useState();
  const [isLoad, setIsLoad] = useState(false);

  const [mainResSpeedTrack, setMainResSpeedTrack] = useState(null);
  const [taskStatusData, setTaskStatusData] = useState([]);
  const [resIdTask, setResIdTask] = useState(null);
  const [resourceId, setResourceId] = useState(null);
  const [googleMapcenter, setGoogleMapcenter] = useState({ lat: 13.90607, lng: 100.51913 });
  const [googleMapzoom, setGoogleMapzoom] = useState(12);
  const [spinLoading, setSpinLoading] = useState(false);

  const [popoverInfoMemComId, setPopoverInfoMemComId] = useState();
  const [statusSummary, setStatusSummary] = useState(false);
  const [totalTaskPool, setTotalTaskPool] = useState();

  const [visibleAddPost, setVisibleAddPost] = useState(false);
  // console.log('monitor pageCode::: ', pageCode);

  useEffect(() => {
    if (mainOrgIdArrString || mainDate) {
      getMapViewResourceDataAndTaskData();
    }
    const interval = setInterval(() => {
      getMapViewResourceDataAndTaskData();
    }, 180000);
    return () => clearInterval(interval);
  }, [
    keyTabMonitor,
    mainOrgIdArrString,
    mainDate,
    mainFieldSearch,
    refreshDateAndTime,
    valueModalVisibleStatus,
    refreshTable,
    refreshUpdateTask,
  ]);

  useEffect(() => {
    setMainResSpeedTrack({
      resId: null,
      openSpeedTrack: false,
      dataSpeedTrack: null,
      dataTask: [],
      statusColor: null,
    });
  }, [keyTabMonitor, mainOrgIdArrString, mainDate, mainFieldSearch]);

  // useEffect(() => {
  //   // if (showGanttView === true) {
  //   //   if (mainOrgIdArrString || mainDate) {
  //   //     getGanttViewResourceDataAndTaskData();
  //   //   }
  //   //   const interval = setInterval(() => {
  //   //     getGanttViewResourceDataAndTaskData();
  //   //   }, 180000);
  //   //   return () => clearInterval(interval);
  //   // }
  // }, [keyTabMonitor, mainOrgIdArrString, mainDate, mainGanttViewFieldSearch, refreshDataGanttView, refreshDateAndTime, valueModalVisibleStatus, refreshTable, refreshUpdateTask]);

  // useEffect(() => {
  //   getOrganizationTeamData();
  // }, []);

  useEffect(() => {
    const getApi = async () => {
      const response = await getinitialTeamApi('menuType=workdesk');
      const dataInitial = response.data.team.map((i) => {
        return {
          orgId: parseInt(i.orgId),
          orgName: i.name,
        };
      });
      setMainOrganizationTeamData(dataInitial);
    };
    getApi();
  }, []);

  const getMapViewResourceDataAndTaskData = async () => {
    try {
      const response = await httpClient.get(
        `/v3/task/manager/map/task?startDate=${mainStartDate}&endDate=${mainEndDate}&com_id=${comId}&org_id=${mainOrgIdArrString}&searchBy=${mainSearchColumn}&search=${mainFieldSearch}&menuType=workdesk`
      );

      // console.log('Monitor Map view getData:::', response.data);

      if (response.status === 200) {
        setMainMapViewResourceData(response.data.data.resource);
        setMainMapViewTaskData(response.data.data.task);

        // setSummaryData(response.data.data.statusSummary);
        // setTasks(response.data.data.totalTasks);
        // setWarning(response.data.data.taskWarning);
        // setTimeout(response.data.data.taskTimeout);

        setSummaryData({
          statusSummary: response.data.data.statusSummary,
          tasks: response.data.data.totalTasks,
          warning: response.data.data.taskWarning,
          timeout: response.data.data.taskTimeout,
        });
      }
    } catch (error) {
      setMainMapViewResourceData([]);
      setMainMapViewTaskData([]);
    }
  };

  const getGanttViewResourceDataAndTaskData = async (startDate = null, endDate = null) => {
    let orgSelectTxt = '';
    if (selectRef.current) {
      const orgSelectArr = selectRef ? selectRef.current.props.value : [];
      orgSelectTxt = orgSelectArr.toString();
    }

    let startDate_new = '';
    let endDate_new = '';
    if (scheduleObjNew.current && scheduleObjNew.current.activeViewOptions.option === 'TimelineDay') {
      let currentDate = _.get(scheduleObjNew, 'current.activeView.renderDates[0]');
      startDate_new = moment(currentDate).format(dateApi);
      endDate_new = moment(currentDate).format(dateApi);
    } else if (scheduleObjNew.current && scheduleObjNew.current.activeViewOptions.option === 'TimelineWeek') {
      let currentDate = _.get(scheduleObjNew, 'current.activeView.renderDates');
      startDate_new = moment(currentDate[0]).format(dateApi);
      endDate_new = moment(currentDate[currentDate.length - 1]).format(dateApi);
    } else if (scheduleObjNew.current && scheduleObjNew.current.activeViewOptions.option === 'TimelineMonth') {
      let currentDate = _.get(scheduleObjNew, 'current.activeView.monthDates');
      startDate_new = moment(currentDate.start).format(dateApi);
      endDate_new = moment(currentDate.end).format(dateApi);
    }

    if (startDate && endDate) {
      startDate_new = startDate;
      endDate_new = endDate;
    }

    try {
      const response = await httpClient.get(
        `/v3/task/manager/monitor/task?startDate=${startDate_new}&endDate=${endDate_new}&com_id=${comId}&org_id=${orgSelectTxt}&searchBy=${mainGanttViewSearchColumn}&search=${mainGanttViewFieldSearch}&menuType=workdesk`
      );

      // if (response.status === 200 && !_.isMatch(mainGanttViewTaskData, response.data.data.task)) {
      if (response.status === 200) {
        setMainGanttViewResourceData(mapResourceDate(response.data.data.resource));
        setMainGanttViewTaskData(mapTaskDate(response.data.data.task));
        setTotalTaskPool(response.data.data.totalTaskpool[0]);
        // setMainGanttViewTaskDataMain(mapTaskDate(response.data.data.task));

        /* ปิดไปก่อน 2021-09-30
        setSummaryData(response.data.data.statusSummary);
        setTasks(response.data.data.totalTasks);
        setWarning(response.data.data.taskWarning);
        setTimeout(response.data.data.taskTimeout);
        setIsLoad(true);
        */
      }
    } catch (error) {
      setMainMapViewResourceData([]);
      setMainMapViewTaskData([]);
    }
  };

  const mapResourceDate = (data) => {
    const newResourceDate = data.map((item, index) => {
      return {
        fullname:
          `<div class='text-dot'><img src=${item.pathImage} class="gantt-avatar" />` +
          `<p class='text-p'>${item.fullname}</p>` +
          `<span class='text-count'>(${item.totalTask})</span></div>`,
        pathImage: item.pathImage,
        memId: item.memId,
        memComId: parseInt(item.memComId) || 999999,
        orgId: item.OrgId,
        assigneeName: item.fullname,
      };
    });
    return newResourceDate;
  };

  const mapTaskDate = (data) => {
    const newTaskDate = data.map((item, key) => {
      return {
        memComOldId: parseInt(item.memComId),
        Id: uuidv4(),
        TaskId: item.taskId,
        TaskNo: item.task_no,
        Subject: item.taskTypeName,
        CustomerName: item.customerName,
        AssigneeName: item.assigneeName,
        Color: item.statusColor,
        OrgId: parseInt(item.orgId),
        ResourceId: parseInt(item.memComId) || 999999,
        StartTime: moment.unix(item.startTime).format(dateTimeApi),
        EndTime: moment.unix(item.endTime).format(dateTimeApi),
        StartDate: item.startDate,
        DueDate: item.dueDate,
        Status: item.statusName,
        Reference: item.reference1,
        ToAddress: item.address,
        Remark: item.remark,
        // StartTime: moment.unix(item.startTime).add(-0, 'hour').format(dateTimeApi),
        // EndTime: moment.unix(item.endTime).add(-0, 'hour').format(dateTimeApi),
      };
    });
    return newTaskDate;
  };

  // const getOrganizationTeamData = async () => {
  //   try {
  //     const response = await httpClient.get(
  //       `/v3/resource/manager/company/${comId}/team-leader-all/${app.memberProfile.member.mem_id}/member/${memComId}?menuType=workdesk`,
  //     );

  //     if (response.status === 200) {
  //       const newOrganization = response.data.data.organization.map(item => {
  //         return {
  //           orgId: parseInt(item.orgId),
  //           orgName: item.orgName,
  //           orgSelected: item.orgSelected
  //         }
  //       })
  //       setMainOrganizationTeamData(newOrganization);
  //     }
  //   } catch (error) {
  //     return
  //   }
  // };

  const handleExport = async () => {
    if (mainOrgIdArr) {
      let mainOrgIdArrStringNew = orgIDStg && orgIDStg.split(',');
      try {
        const body = {
          fromDate: fromDate,
          utilDate: toDate,
          orgId: mainOrgIdArrStringNew,
          search: fieldChang,
          searchBy: selectSearchData,
          paging: paginationPage === 1 ? paginationPage : 1,
          rowsPerPages: conutrowForExcel,
          ordertype: orderSort,
          orderby: fieldSort,
          viewFormat: [
            {
              colName: intl.formatMessage({ id: 'monitorTabListViewTableTaskNoWorkForce', defaultMessage: 'Task No' }),
              sequence: 1,
              colCode: 'taskNo',
            },
            {
              colName: intl.formatMessage({ id: 'monitorTabListViewTableTaskTypeWorkForce', defaultMessage: 'Task Type' }),
              sequence: 2,
              colCode: 'taskType',
            },
            {
              colName: intl.formatMessage({ id: 'monitorTabListViewTableCustomerWorkForce', defaultMessage: 'Customer' }),
              sequence: 3,
              colCode: 'customer',
            },
            {
              colName: intl.formatMessage({ id: 'monitorTabListViewTableContactNameWorkForce', defaultMessage: 'Contact Name' }),
              sequence: 4,
              colCode: 'contactName',
            },
            {
              colName: intl.formatMessage({ id: 'monitorTabListViewTableContactPhoneWorkForce', defaultMessage: 'Contact Phone' }),
              sequence: 5,
              colCode: 'contactPhone',
            },
            {
              colName: intl.formatMessage({ id: 'monitorTabListViewTableFromLocationWorkForce', defaultMessage: 'From Location' }),
              sequence: 6,
              colCode: 'fromLocation',
            },
            {
              colName: intl.formatMessage({ id: 'monitorTabListViewTableToLocationWorkForce', defaultMessage: 'To Location' }),
              sequence: 7,
              colCode: 'toLocation',
            },
            {
              colName: intl.formatMessage({ id: 'monitorTabListViewTableStatusWorkForce', defaultMessage: 'Status' }),
              sequence: 8,
              colCode: 'status',
            },
            {
              colName: intl.formatMessage({ id: 'monitorTabListViewTableStartDateWorkForce', defaultMessage: 'Start Date' }),
              sequence: 9,
              colCode: 'startDate',
            },
            {
              colName: intl.formatMessage({ id: 'monitorTabListViewTableDueDateWorkForce', defaultMessage: 'Due Date' }),
              sequence: 10,
              colCode: 'dueDate',
            },
            {
              colName: intl.formatMessage({ id: 'monitorTabListViewTableActualStartDateWorkForce', defaultMessage: 'Actual Start Date' }),
              sequence: 11,
              colCode: 'actualStartDate',
            },
            {
              colName: intl.formatMessage({
                id: 'monitorTabListViewTableActualCompletedDateWorkForce',
                defaultMessage: 'Actual Completed Date',
              }),
              sequence: 12,
              colCode: 'actualCompletedDate',
            },
            {
              colName: intl.formatMessage({ id: 'monitorTabListViewTableRemarkWorkForce', defaultMessage: 'Remark' }),
              sequence: 13,
              colCode: 'remark',
            },
            {
              colName: intl.formatMessage({ id: 'monitorTabListViewTableReference1WorkForce', defaultMessage: 'Reference 1' }),
              sequence: 14,
              colCode: 'reference1',
            },
            {
              colName: intl.formatMessage({ id: 'monitorTabListViewTableReference2WorkForce', defaultMessage: 'Reference 2' }),
              sequence: 15,
              colCode: 'reference2',
            },
            {
              colName: intl.formatMessage({ id: 'monitorTabListViewTableReference3WorkForce', defaultMessage: 'Reference 3' }),
              sequence: 16,
              colCode: 'reference3',
            },
            {
              colName: intl.formatMessage({ id: 'monitorTabListViewTableCreatedDateWorkForce', defaultMessage: 'Created Date ' }),
              sequence: 17,
              colCode: 'createdDate',
            },
            {
              colName: intl.formatMessage({ id: 'reportTaskReportViewTableAssigneeNameWorkForce', defaultMessage: 'Assignee Name ' }),
              sequence: 18,
              colCode: 'assigneeName',
            },
          ],
        };

        const response = await httpClient.post(`/v3/task/manager/company/${comId}/tasklistview/exportexcel?menuType=workdesk`, body);

        if (response.status === 200) {
          window.open(response.data.data.url);
          successNotification(response.data.status.message);
        } else {
          errorNotification(response.data.status.message);
        }
      } catch (error) {
        errorNotification(error.response.status.message);
      }
    }
  };

  const callback = (key) => {
    setKeyTabMonitor(key);
    // console.log('keytab', key)
    // console.log('statusSummary', statusSummary)

    // if (key === 'listView') {
    //   setShow(false);
    //   setSpan(24);
    // } else {
    //   setShow(true);
    // }
    // if (key === "ganttView") {
    //   setShowGanttView(false);
    // } else {
    //   setShowGanttView(true);
    // }
    // if (key === 'ganttView') {
    //   if (statusSummary) {
    //     setSpan(24);
    //   } else {
    //     setSpan(18);
    //   }
    // }
    // else if (key === 'mapView') {
    //   if (statusSummary) {
    //     setSpan(24);
    //   } else {
    //     setSpan(18);
    //   }
    // }
    // else if (key === 'listView') {
    //   setShow(false);
    //   setSpan(24)
    // }

    // if (statusSummary) {
    //   setSpan(24);
    // } else {
    //   setSpan(18);
    // }
  };

  useEffect(() => {
    if (keyTabMonitor === 'ganttView') {
      if (statusSummary) {
        setSpan(24);
      } else {
        setSpan(18);
        setShow(true);
      }
    } else if (keyTabMonitor === 'mapView') {
      if (statusSummary) {
        setSpan(24);
      } else {
        setSpan(18);
        setShow(true);
      }
    } else if (keyTabMonitor === 'listView') {
      setShow(false);
      setSpan(24);
    }
  }, [statusSummary, keyTabMonitor]);

  const triggerApiGetGanttView = (check) => {
    // setRefreshDataGanttView(!refreshDataGanttView);
    if (check === 'true') {
      setRefreshDataGanttView(true);
    }
  };

  const iconExcel = () => <img className="monitor-icon-excel" src={IconExcel} />;

  const onShowCreateTask = (visible) => {
    setMainVisibleCreateTask(visible);
  };

  const handleVisibleSummary = () => {
    setStatusSummary((current) => !current);
  };

  const handleRenderTopBar = (props, DefaultTabBar) => (
    <Sticky bottomOffset={80}>
      {({ style }) => (
        <Row>
          <Col span={1} className="col-button-hide">
            <Button
              className="button-disabled-summary"
              onClick={handleVisibleSummary}
              style={{ border: 'unset', marginLeft: '12px' }}
              disabled={keyTabMonitor !== 'listView' ? false : true}
            >
              <Icon
                type={statusSummary ? 'menu-unfold' : 'menu-fold'}
                style={keyTabMonitor === 'listView' ? { color: '#BCC5D3' } : { color: '#1D3557' }}
              />
            </Button>
          </Col>
          <Col span={23}>
            <DefaultTabBar {...props} style={{ ...style, zIndex: 1, background: '#fff' }} />
          </Col>
        </Row>
      )}
    </Sticky>
  );

  return (
    <MonitorContext.Provider
      value={{
        stateMain: {
          mainMapViewResourceData,
          mainMapViewTaskData,
          mainGanttViewResourceData,
          mainGanttViewTaskData,
          mainGanttViewTaskDataMain,
          mainOrganizationTeamData,
          mainOrgId,
          mainDateStartAction,
          mainDateEndAction,
          mainGanttViewDateTimePicker,
          mainOrgIdArr,
          mainOrgIdArrString,

          scheduleObjNew,
          selectRef,
          refreshDataGanttView,

          summaryData,
          tasks,
          warning,
          timeout,
          summaryDataGantt,
          tasksGantt,
          warningGantt,
          timeoutGantt,
          fromDate,
          toDate,
          fieldChang,
          selectSearchData,
          paginationPage,
          paginationSize,
          total,
          orderSort,
          fieldSort,
          rangePicker,
          keyTabMonitor,
          mainTaskId,
          resourceTaskData,
          taskInformationData,
          valueModalVisibleStatus,

          visibleAssignee,
          mainStatusTaskObject,
          mainTextNameStatus,
          visibleTaskDetailModal,
          visibleTaskStatusModal,
          mainTaskIdAssign,
          mainDate,
          mainDateTitleModal,
          markerStatus,
          selectTaskData,
          selectResourceData,
          mainResourceMemComId,
          modalTimeDateVisible,
          taskIdAddAssignee,
          refreshTaskDetailsData,
          mainStatusTaskTitle,
          refreshDateAndTime,
          refreshTable,
          refreshTaskPool,

          mainVisibleCreateTask,

          refreshUpdateTask,
          statusUpdate,
          orgUpdate,
          orgUpdateDefault,
          statusUpdateComment,
          priorityUpdate,

          refreshDataTaskPool,
          refreshApiGanttView,

          isLoad,
          mainResSpeedTrack,
          taskStatusData,
          resIdTask,
          resourceId,
          googleMapcenter,
          googleMapzoom,
          spinLoading,
          LangCode,
          popoverInfoMemComId,
          statusSummary,
          totalTaskPool,
        },
        setStateMain: {
          setMainMapViewResourceData,
          setMainMapViewTaskData,

          setMainFieldSearch,
          setMainSearchColumn,
          setMainDateStartAction,
          setMainDateEndAction,
          setMainDate,
          setMainStartDate,
          setMainEndDate,
          setMainOrgId,
          setMainOrgIdArr,
          setMainOrgIdArrString,

          setMainGanttViewFieldSearch,
          setMainGanttViewSearchColumn,
          setMainGanttViewDateTimePicker,
          setRefreshDataGanttView,

          setSummaryData,
          setTasks,
          setWarning,
          setTimeout,
          setSummaryDataGantt,
          setTasksGantt,
          setWarningGantt,
          setTimeoutGantt,
          setFromDate,
          setToDate,
          setFieldChang,
          setSelectSearchData,
          setPaginationPage,
          setPaginationSize,
          setTotal,
          setFieldSort,
          setOrderSort,
          setRangePicker,
          setKeyTabMonitor,
          setMainTaskId,
          setMainTaskIdAssign,
          setValueModalVisibleStatus,

          setVisibleAssignee,
          setMainStatusTaskObject,
          setMainTextNameStatus,
          setMainDateTitleModal,
          setMainSelectedResourceId,
          setVisibleTaskDetailModal,
          setVisibleTaskStatusModal,
          setMarkerStatus,
          setSelectTaskData,
          setSelectResourceData,
          setMainResourceMemComId,
          setModalTimeDateVisible,
          setTaskIdAddAssignee,
          setRefreshTaskDetailsData,
          setMainStatusTaskTitle,

          setRefreshDateAndTime,
          setRefreshTable,
          setConutrowForExcel,

          // setVisibleCreateTask
          setMainVisibleCreateTask,

          setRefreshTaskPool,
          setRefreshUpdateTask,
          setStatusUpdate,
          setOrgUpdate,
          setStatusUpdateComment,
          setOrgUpdateDefault,
          setPriorityUpdate,

          setRefreshDataTaskPool,

          setRefreshApiGanttView,
          setMainResSpeedTrack,
          setTaskStatusData,
          setResIdTask,
          setResourceId,
          setGoogleMapcenter,
          setGoogleMapzoom,
          setSpinLoading,
          setPopoverInfoMemComId,
          setTotalTaskPool,
        },
        fncMain: {
          triggerApiGetGanttView,
          onShowCreateTask,
          // getGanttViewResourceDataAndTaskData
        },
      }}
    >
      <PageSettings.Consumer>
        {({ checkPermissionAction }) => (
          <div>
            <Row gutter={[16, 16]}>
              {checkPermissionAction(`P30PG1C1`, `P30PG1C1A1`) ? (
                show === false || statusSummary ? null : (
                  <Col span={6}>
                    <ManuMonitor />
                  </Col>
                )
              ) : null}
              <Col span={span}>
                {checkPermissionAction(`P30PG1C2`, `P30PG1C2A1`) ||
                checkPermissionAction(`P30PG1C3`, `P30PG1C3A1`) ||
                checkPermissionAction(`P30PG1C4`, `P30PG1C4A1`) ||
                checkPermissionAction(`P30PG1C5`, `P30PG1C5A1`) ? (
                  <Card className="monitor-main-card">
                    <StickyContainer>
                      <Tabs
                        className="monitor-main-tabs"
                        size="large"
                        defaultActiveKey="calendarView"
                        onChange={callback}
                        animated={false}
                        renderTabBar={handleRenderTopBar}
                        tabBarExtraContent={
                          <div>
                            <Row>
                              <Col span={12}>
                                {checkPermissionAction(`P30PG1`, `P30PG1A2`) ? (
                                  <div>
                                    <Button01 btnsize="wd_at" type="primary" onClick={() => onShowCreateTask(true)}>
                                      <FormattedMessage id={`monitorCreateTaskTxtCreateTaskWorkDesk`} defaultMessage="Create Task" />
                                    </Button01>
                                  </div>
                                ) : null}
                              </Col>

                              {show === true || statusSummary ? null : (
                                <Col span={12}>
                                  <Button className="monitor-button-link-export" type="link" onClick={handleExport} disabled={disabled}>
                                    <FormattedMessage id="btnExport" defaultMessage="Export" /> <Icon component={iconExcel} />
                                  </Button>
                                </Col>
                              )}
                            </Row>
                          </div>
                        }
                      >
                        {checkPermissionAction(`P30PG1C3`, `P30PG1C3A1`) ? (
                          <TabPane
                            tab={intl.formatMessage({ id: `monitorTabGanttViewTitleWorkDesk`, defaultMessage: 'Gantt View' })}
                            key="ganttView"
                          >
                            <GanttView />
                          </TabPane>
                        ) : null}

                        {checkPermissionAction(`P30PG1C4`, `P30PG1C4A1`) ? (
                          <TabPane
                            tab={intl.formatMessage({ id: `monitorTabMapViewTitleWorkDesk`, defaultMessage: 'Map View' })}
                            key="mapView"
                          >
                            <MapView />
                            {/* <MapSpeedTracking /> */}
                          </TabPane>
                        ) : null}

                        {checkPermissionAction(`P30PG1C5`, `P30PG1C5A1`) ? (
                          <TabPane
                            tab={intl.formatMessage({ id: `monitorTabListViewTitleWorkDesk`, defaultMessage: 'List View' })}
                            key="listView"
                          >
                            <ListView />
                          </TabPane>
                        ) : null}
                      </Tabs>
                    </StickyContainer>
                  </Card>
                ) : null}
              </Col>
            </Row>
          </div>
        )}
      </PageSettings.Consumer>
      {/* <TaskDetailsModal mainOrgId={mainOrgId} pageCode={pageCode} LangCode={LangCode} /> */}
      <AssigneeResource />
      <TaskDetail
        visible={visibleTaskDetailModal}
        setVisible={setVisibleTaskDetailModal}
        visibleResource={visibleTaskStatusModal}
        setVisibleResource={setVisibleTaskStatusModal}
        mainTaskId={mainTaskId}
        teamData={mainOrganizationTeamData}
        scheduleObjNew={scheduleObjNew}
        mainMapViewResourceData={mainMapViewResourceData}
        mainResourceMemComId={mainResourceMemComId}
        mainDate={mainDate}
        mainStatusTaskTitle={mainStatusTaskTitle}
        mainStatusTaskObject={mainStatusTaskObject}
        mainDateTitleModal={mainDateTitleModal}
        trigger={{
          refreshTable,
          refreshDateAndTime,
          valueModalVisibleStatus,
        }}
        setTrigger={{
          setRefreshTable,
          setRefreshTaskDetailsData,
          setRefreshApiGanttView,
          setRefreshDataTaskPool,
        }}
      />

      <CreateTask
        visible={mainVisibleCreateTask}
        setVisible={setMainVisibleCreateTask}
        setTrigger={{
          setRefreshTable,
          setRefreshTaskDetailsData,
          setRefreshApiGanttView,
          setRefreshDataTaskPool,
        }}
      />
    </MonitorContext.Provider>
  );
});
