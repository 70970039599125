import React, { useEffect, useState, useRef } from 'react';
import { Modal, Divider, Col, Row, Table, Icon, Input } from 'antd';
import Button01 from '../v2/button_01';
import Button02 from '../v2/button_02';
import { useIntl, FormattedMessage } from 'react-intl';
import httpClient from '../../components/axiosClient';
import _ from 'lodash';
import style from './css/index.css';
import moment from 'moment';
import Print from '../../components/print/invoice';
import PrintTest from '../../components/print/print-test';
import ReactToPrint from 'react-to-print';
import CountPrint from '../../controllers/invoice/count-print-invoice';
import NumberFormat from 'react-number-format';
import enUS from 'antd/es/locale/en_US';
import thTH from 'antd/es/locale/th_TH';
import { useAppContext } from '../../includes/indexProvider';

const { TextArea } = Input;
export default ({ data, visible, setVisible, dataCompany, setTriggerPrint, triggerPrint }) => {
  const intl = useIntl();
  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: 70,
      align: 'center',
      // fixed: 'left',
      render: (text, record, index) => index + 1,
    },
    {
      title: intl.formatMessage({ id: 'modalInvoiceColumnTaskNo', defaultMessage: 'Task No' }),
      dataIndex: 'taskNo',
      key: 'taskNo',
    },
    {
      title: intl.formatMessage({ id: 'modalInvoiceColumnTaskType', defaultMessage: 'Task Type' }),
      dataIndex: 'taskTypeName',
      key: 'taskTypeName',
    },
    {
      title: intl.formatMessage({ id: 'modalInvoiceColumnUnitPriceBaht', defaultMessage: 'Unit Price (Baht)' }),
      dataIndex: 'UnitPrice',
      key: 'UnitPrice',
      align: 'center',
      render: (text, record, index) => (
        <NumberFormat value={parseFloat(record.UnitPrice).toFixed(2)} displayType={'text'} thousandSeparator={true} />
      ),
    },
    {
      title: intl.formatMessage({ id: 'modalInvoiceColumnQty', defaultMessage: 'Qty' }),
      dataIndex: 'qty',
      key: 'qty',
      align: 'center',
      render: (text, record, index) => (
        <NumberFormat
          value={parseFloat(record.qty).toFixed(2)}
          displayType={'text'}
          thousandSeparator={true}
          suffix={' ' + record.qtyType}
        />
      ),
    },
    {
      title: intl.formatMessage({ id: 'modalInvoiceColumnTotalBaht', defaultMessage: 'Total (Baht)' }),
      dataIndex: 'total',
      key: 'total',
      align: 'center',
      render: (text, record, index) => (
        <NumberFormat value={parseFloat(record.total).toFixed(2)} displayType={'text'} thousandSeparator={true} />
      ),
    },
  ];

  const dataDetail = _.get(data, 'detail');
  const customerName = _.get(data, 'customerName');
  const customerAddress = _.get(data, 'customerAddress');
  const taxNo = _.get(data, 'taxNo');
  const customerPhone = _.get(data, 'customerPhone');
  const invoiceNo = _.get(data, 'invoiceNo');
  const invoiceId = _.get(data, 'invoiceId');
  const billDate = _.get(data, 'billDate');
  const invoiceRemark = _.get(data, 'invoiceRemark');
  const subTotal = _.get(data, 'subTotal');
  const discount = _.get(data, 'discount');
  const tax = _.get(data, 'tax');
  const total = _.get(data, 'total');
  const discountTitle = _.get(data, 'discountTitle');
  const taxTitle = _.get(data, 'taxTitle');
  let printCount = _.get(data, 'printCount');

  const [countNumberPrint, setCountNumberPrint] = useState();
  const [triggerAPICount, setTriggerAPICount] = useState(false);

  const app = useAppContext();
  const langValueState = _.get(app, 'state.langValue');
  moment.locale(langValueState);

  const [print, setPrint] = useState(false);
  const componentRef = useRef();

  // console.log('Invoice view visible::: ', visible);

  useEffect(() => {
    if (invoiceId && visible === true) {
      const countPrint = async (record) => {
        const comCode = localStorage.getItem('comCode');
        try {
          const response = await httpClient.get(`/v3/invoice/manager/company/${comCode}/invoice/getdatabyid/to-print/${invoiceId}`);
          if (response.status == 200) {
            setCountNumberPrint(response.data.data.items[0].printCount);
          }
        } catch (error) {
          return;
        }
      };
      countPrint();
    }
  }, [visible, invoiceId, triggerAPICount]);

  const handleCancel = () => {
    setVisible(false);
  };

  const countPrintsInvoice = async () => {
    const response = await CountPrint(invoiceId);
    setTriggerPrint(!triggerPrint);
    setTriggerAPICount((current) => !current);
  };

  return (
    <Modal
      className="modal-view"
      title={intl.formatMessage({ id: 'invoiceMainPageCardHeadertext', defaultMessage: 'Invoice' })}
      width={938}
      centered={true}
      visible={visible}
      onCancel={handleCancel}
      bodyStyle={{ height: '595px', overflow: 'auto' }}
      footer={[
        <Button02 style={{margin : '0px 0px 0px 10px'}} key="back" btnsize="wd_df" onClick={handleCancel}>
          <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
        </Button02>,
        <ReactToPrint
          trigger={() => (
            <Button01 key="submit" type="primary" btnsize="wd_df">
              <FormattedMessage id="btnPrint" defaultMessage="Print" />
            </Button01>
          )}
          content={() => componentRef.current}
          onBeforeGetContent={() => setPrint(true)}
          onAfterPrint={() => countPrintsInvoice()}
        />,
        <div style={{ float: 'left' }}>
          <span className="print-style">
            <FormattedMessage id="modalInvoiceViewPrintNumberText" defaultMessage="Printed Numbers" /> : {countNumberPrint}
          </span>
        </div>,
      ]}
    >
      <Row>
        <Col span={12}>
          <p className="margin-8px">
            <FormattedMessage id="modalInvoiceDetailCustomer" defaultMessage="Customer" />
          </p>
          <p className="tex-header" style={{ marginBottom: '20px' }}>
            {customerName}
          </p>
          <div style={{ width: '272px', marginBottom: '8px' }}>
            <span>
              {customerAddress}{' '}
              <Icon
                type="environment"
                style={{
                  fontSize: '12px',
                  color: '#1D3557',
                  paddingLeft: '6px',
                  paddingRight: '0px',
                  cursor: 'pointer',
                }}
              />
            </span>
          </div>
          <div style={{ marginBottom: '8px' }}>
            <span>
              <FormattedMessage id="modalInvoiceDetailTaxNo" defaultMessage="Tax No" /> : <span>{taxNo}</span>
            </span>
          </div>
          <div style={{ marginBottom: '60px' }}>
            <span>
              <FormattedMessage id="modalInvoiceDetailDatePhone" defaultMessage="Phone" /> : <span>{customerPhone}</span>
            </span>
          </div>
        </Col>

        <Col span={12} className="text-right">
          <p className="margin-8px">
            <FormattedMessage id="modalInvoiceDetailInvoiceNo" defaultMessage="Invoice No" />
          </p>
          <p className="tex-header" style={{ marginBottom: '20px' }}>
            {invoiceNo}
          </p>
          <p className="margin-8px">
            <FormattedMessage id="modalInvoiceDetailDate" defaultMessage="Date Of Issue" />
          </p>
          <p className="bill-date">{moment(billDate, 'YYYY-MM-DD').format('ddd, MMM DD YYYY')}</p>
        </Col>
      </Row>
      <Divider type="horizontal" className="margin-divider" />
      <Row>
        <div className="div-style">
          <Table
            columns={columns}
            dataSource={dataDetail}
            pagination={false}
            scroll={dataDetail && dataDetail.length !== 0 ? { y: 163 } : { x: 'max-content' }}
          />
        </div>
      </Row>
      <Divider type="horizontal" className="margin-divider2" />

      <Row>
        <Col span={12}>
          <p className="margin-8px">
            <FormattedMessage id="modalInvoiceSummaryRemarkText" defaultMessage="Remark" />
          </p>
          <TextArea value={invoiceRemark} disabled={true} rows={5} autoSize={{ minRows: 5, maxRows: 5 }} className="text-area" />
        </Col>

        <Col span={12}>
          <Col span={12} className="text-right">
            <p className="margin-9px">
              <FormattedMessage id="modalInvoiceSummarySubtotalText" defaultMessage="Subtotal" />
            </p>
            <p className="margin-9px">
              <FormattedMessage id="modalInvoiceSummaryDiscountText" defaultMessage="Discount" /> {discountTitle}
            </p>
            <p className="margin-9px">
              <FormattedMessage id="modalInvoiceSummarySubtotalLessText" defaultMessage="Subtotal Less Discount" />
            </p>
            <p className="margin-9px">
              <FormattedMessage id="modalInvoiceSummaryTaxText" defaultMessage="Tax" /> {taxTitle}
            </p>
            <p className="margin-9px">
              <FormattedMessage id="modalInvoiceSummaryTotalText" defaultMessage="Total" />
            </p>
          </Col>
          <Col span={12} className="text-right" style={{ padding: '0px 40px' }}>
            <p className="margin-9px">
              <NumberFormat
                value={_.floor(subTotal, 2).toFixed(2)}
                displayType={'text'}
                thousandSeparator={true}
                suffix={' ' + intl.formatMessage({ id: 'invoicetextBaht', defaultMessage: 'Baht' })}
              />
            </p>
            <p className="margin-9px">
              <NumberFormat
                value={_.floor(discount, 2).toFixed(2)}
                displayType={'text'}
                thousandSeparator={true}
                suffix={' ' + intl.formatMessage({ id: 'invoicetextBaht', defaultMessage: 'Baht' })}
              />
            </p>
            <p className="margin-9px">
              <NumberFormat
                value={_.floor(subTotal - discount, 2).toFixed(2)}
                displayType={'text'}
                thousandSeparator={true}
                suffix={' ' + intl.formatMessage({ id: 'invoicetextBaht', defaultMessage: 'Baht' })}
              />
            </p>
            <p className="margin-9px">
              <NumberFormat
                value={_.floor(tax, 2).toFixed(2)}
                displayType={'text'}
                thousandSeparator={true}
                suffix={' ' + intl.formatMessage({ id: 'invoicetextBaht', defaultMessage: 'Baht' })}
              />
            </p>
            <p className="margin-9px">
              <NumberFormat
                value={_.floor(total, 2).toFixed(2)}
                displayType={'text'}
                thousandSeparator={true}
                suffix={' ' + intl.formatMessage({ id: 'invoicetextBaht', defaultMessage: 'Baht' })}
              />
            </p>
          </Col>
        </Col>
      </Row>

      {/* <Print visible={print} setVisible={setPrint}/> */}
      <PrintTest ref={componentRef} data={data} dataCompany={dataCompany} />
    </Modal>
  );
};
