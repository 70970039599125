import React from 'react';
import CustomerForm from './customer-viewinvoice';
import { FormattedMessage, useIntl } from 'react-intl';
import Button_01 from '../../../../../components/v2/button_01';
import CustomTableComponent from '../../../components/table';
import { Button, Divider } from 'antd';
import { numberFormatter } from '../../../../../component-function/fnc-inputnumber-currency';
import _ from 'lodash';
import moment from 'moment';

const DetailsTabPendingViewinvoice = ({ form, property }) => {
  const intl = useIntl();
  const { setDataForm, dataForm, selectItem, setSelectItem,viewDetail,handleOpenViewInvoice } = property;

  console.log('qtyTxt', dataForm);
  const detailcustomer = _.get(dataForm, 'saleOrderDetail');

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
      fixed: 'left',
    },
    {
      title: intl.formatMessage({ id: 'ARInvoiceNo', defaultMessage: 'Invoice No.' }),
      dataIndex: 'invoiceNo',
      key: 'invoiceNo',
      sorter: (a, b) => { return a.invoiceNo.localeCompare(b.invoiceNo)},
      width: 170,
      fixed: 'left',
    },
    {
      title: intl.formatMessage({ id: 'ARItem', defaultMessage: 'Item' }),
      dataIndex: 'totalItem',
      key: 'totalItem',
      sorter: (a, b) => a.totalItem - b.totalItem,
      // sorter: (a, b) => { return a.totalItem.localeCompare(b.totalItem,undefined,{'numeric': true})},
    },
    {
      title: intl.formatMessage({ id: 'ARTotal', defaultMessage: 'Total' }),
      dataIndex: 'totalTxt',
      key: 'totalTxt',
      align: 'right',
      sorter: (a, b) => { return a.totalTxt.localeCompare(b.totalTxt)},
    },
    {
      title: intl.formatMessage({ id: 'ARRemark', defaultMessage: 'Remark' }),
      dataIndex: 'remark',
      key: 'remark',
      sorter: (a, b) => { return a.remark.localeCompare(b.remark)},
      // render: (text, record, index) => console.log('qtyTxt');,
    },
    {
      title: intl.formatMessage({ id: 'ARIssuedby', defaultMessage: 'Issued by' }),
      dataIndex: 'issueBy',
      key: 'issueBy',
      
      sorter: (a, b) => { return a.issueBy.localeCompare(b.issueBy)},
      // render: (text, record, index) => record.pricePerUnit,
    },
    {
      title: intl.formatMessage({ id: 'ARIssuedDate', defaultMessage: 'Issued Date' }),
      dataIndex: 'issueDateTxt',
      key: 'issueDateTxt',
      sorter: (a, b) => moment(a.issueDateTxt).unix() - moment(b.issueDateTxt).unix()
    },
  ];

  const contentAction = (record) => {
    return (
      <div style={{ display: 'grid' }}>
        <div>
          <Button style={{ width: 100 }} type="link" ghost onClick={() => handleOpenViewInvoice(record)}>
            <FormattedMessage id="btnView" defaultMessage="View" />
          </Button>
        </div>
      </div>
    );
  };


  return (
    <div className="scroll-sm scroll-height">

      <div className="content-body">
        <CustomerForm form={form} setDataForm={setDataForm} dataForm={detailcustomer} detailinvoice={dataForm}  />
      </div>

      <div>
        <div className="item-title">
          <span className="item-title-text">
            <FormattedMessage id="Invoice" defaultMessage="Invoice" />
          </span>
        </div>
        <Divider type="horizontal" style={{ marginBottom: '10px', marginTop: '10px' }} />
        <div className="content-body">
          <CustomTableComponent
            onRow={true}
            role={true}
            columns={columns}
            dataSource={viewDetail}
            scroll={{ x: 1500 }}
            contentAction={contentAction}
          />
        </div>
      </div>
    </div>
  );
};

export default DetailsTabPendingViewinvoice;
