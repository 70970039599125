import React, { useState, useEffect, useContext, useRef } from 'react';
import { Modal, Layout, Menu, Avatar, Row, Col } from 'antd';
import Button01 from '../../../components/v2/button_01';
import Button02 from '../../../components/v2/button_02';
import httpClient from '../../../components/axiosClient';
import FormMemberSetting from './form-member-setting';
import Provider from '../provider';
import { MemberSettings } from './page-member-settings';
import notification from '../../../components/v2.1.0/notification';
import { useIntl, FormattedMessage } from 'react-intl';

const { Content, Sider } = Layout;
const { confirm } = Modal;

const menuItemStyled = {
  margin: 0,
  paddingLeft: 24,
  height: 52,
  lineHeight: '52px'
};

export default (props) => {
  const intl = useIntl();
  const app = Provider.useAppContext();
  const appMemberSetting = useContext(MemberSettings);
  const [data, setData] = useState({});
  const [disButton, setDisButton] = useState(false);
  const formRef = useRef();


  useEffect(() => {
    if (appMemberSetting.memComId) {
      getMemberSettings();
    }
  }, [appMemberSetting.memComId]);

  const getMemberSettings = async () => {
    try {
      const result = await httpClient.get(`/v1/resource/manager/organization/${appMemberSetting.orgId}/member/${appMemberSetting.memComId}/setting`);
      if (result.status === 200) {
        setData(result.data);
      }
      else {
        setData({});
      }
    }
    catch (e) {
      setData({});
    }
  }

  const handleSelectedMember = ({ key }) => {
    const found = data.orgMembers.findIndex((e) => String(e.memComId) === key);
    setData({});
    appMemberSetting.setMemComId(key);
    appMemberSetting.setMemOrgId(String(data.orgMembers[found].memOrgId));
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    formRef.current.validateFields((err, values) => {
      if (err) {
        return false
      }
      setDisButton(true);

      confirm({
        className: 'member-modal-confirm',
        title: intl.formatMessage({ id: 'orgMemberConfirmTitle', defaultMessage: 'Do you want to save these member ?' }),
        centered: true,
        okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
        cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
        okButtonProps: {
          style: {
            width: '96px',
          },
          type: "primary",
        },
        cancelButtonProps: {
          style: {
            width: '96px',
          },
          type: "danger",
        },
        async onOk() {
          try {
            const favoriteShiftType = values.favoriteShiftType.map((o) => {
              const found = data.shiftTypeRules.findIndex((e) => e.shiftId === o);
              // const shiftTypeRuleId = found > -1 ? data.shiftTypeRules[found].shiftTypeRuleId : '';
              return {
                shiftId: o,
                shiftTypeRuleId: o
              }
            });

            const gropingMemRestriction = values.gropingMemRestriction.map((o) => {
              const found = data.orgMembers.findIndex((e) => String(e.memComId) === o);
              const memOrgId = found > -1 ? String(data.orgMembers[found].memOrgId) : '';
              return {
                memComId: o,
                memOrgId: memOrgId
              }
            });

            const doc = {
              orgId: appMemberSetting.orgId,
              memComId: appMemberSetting.memComId,
              memOrgId: appMemberSetting.memOrgId,
              priority: values.priority ? Number(values.priority) : null,
              favoriteShiftType: favoriteShiftType,
              gropingMemRestriction: gropingMemRestriction
            }

            const result = await httpClient.post(`/v1/resource/manager/organization/member/setting/save`, doc);
            if (result.status === 200) {
              notification({ status: 'success', message: result.data.message });
              setTimeout(() => {
                appMemberSetting.refreshDataMembers(curren => !curren);
              }, 2000)
              setDisButton(false);
            }
            else {
              notification({ status: 'error', message: result.data.message });
              setDisButton(false);
            }
          } catch (e) {
            notification({ status: 'error', message: 'Network error.' });
            appMemberSetting.handleModalMemberSetting(false);
            setDisButton(false);
          }
        },
        onCancel() {
          if (appMemberSetting.memComId) {
            getMemberSettings();
          }
          setDisButton(false);
        },
      });
    });
  }

  return (
    <MemberSettings.Consumer>
      {({ memberSettingVisibled, handleModalMemberSetting }) => (
        <Modal
          title={<div>
            <span style={{ width: 190, height: 56 }}> <FormattedMessage id="orgTabMemberModalTxtOrgMember" defaultMessage="Organization Member" /> </span>
          </div>}
          visible={memberSettingVisibled}
          onOk={handleSubmit}
          onCancel={() => handleModalMemberSetting(false)}
          centered={true}
          width={650}
          bodyStyle={{
            padding: '0px 16px 0px 0px',
            minHeight: 400
          }}
          footer={[
            <Button02 style={{margin : '0px 0px 0px 10px'}}
              key="back"
              btnsize="wd_df"
              fontsize="sm"
              onClick={() => handleModalMemberSetting(false)}
            >
              <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
            </Button02>,
            <Button01
              key="submit"
              type="primary"
              btnsize="wd_df"
              fontsize="sm"
              onClick={handleSubmit}
              disabled={disButton}
            >
              <FormattedMessage id="btnSave" defaultMessage="Save" />
            </Button01>,
          ]}
        >
          <Row gutter={16, 16}>
            <Col span={8}>
              <Menu
                mode="inline"
                selectedKeys={[String(appMemberSetting.memComId)]}
                style={{ height: 400, overflowY: 'auto', overflowX: 'hidden' }}
                onSelect={handleSelectedMember}
              >
                {data.orgMembers &&
                  data.orgMembers.map &&
                  data.orgMembers.map((o) =>
                    <Menu.Item key={o.memComId} style={menuItemStyled}>{o.name}</Menu.Item>
                  )}
              </Menu>
            </Col>
            <Col span={16}>
              <Row gutter={[8, 8]} style={{ paddingTop: 26 }}>
                <Col span={12} style={{ textAlign: 'center' }}>
                  <Avatar size={96} src={data.memProfiile} />
                </Col>
                <Col span={12}>
                  <p style={{ lineHeight: 1.5, fontWeight: 'bold' }}>{data.memberName}</p>
                  <p style={{ lineHeight: 1.5 }}>{data.memberEmail}</p>
                  <p style={{ lineHeight: 1.5 }}>{data.memberPhone}</p>
                </Col>
              </Row>
              <Row gutter={[8, 8]}>
                <Col span={24}>
                  <FormMemberSetting ref={formRef} data={data} />
                </Col>
              </Row>
            </Col>
          </Row>
        </Modal>
      )}
    </MemberSettings.Consumer>
  );
}