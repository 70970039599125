
import httpClient from '../../components/axiosClient';

const getWareHouse = async () => {
  const comCode = localStorage.getItem('comCode');
  if (comCode) {
    try {
      const response = await httpClient.get(`/v3/order/manager/company/${comCode}/getdetailcreate`);
      if (response.status === 200) {
        return await response.data;
      } else {
        return {};
      }
    } catch (error) {

    }
  }

}

export default getWareHouse;