import React, { useEffect, useRef } from 'react';
import { Form, Select, Icon } from 'antd';
import _ from 'lodash';
import style from './css/detail-invoice.css';
import { useIntl, FormattedMessage } from 'react-intl';

const { Option } = Select;

export default Form.create({
  name: 'customer-form',

  onValuesChange: (props, changedValues, allValues) => {
    const { onChange } = props;

    // onChange(changedValues.customer);
  },
})((props) => {
  const { getFieldDecorator, getFieldValue, resetFields } = props.form;
  const { datasource, customer, onScroll, handleLeaveSelect, loading, data, setCustomerId } = props;
  const addressRef = useRef();
  const taxRef = useRef();
  const phoneRef = useRef();
  const intl = useIntl();

  const handleChangeCustomer = (value) => {
    const resourceSelect = customer && customer.customers.filter((item) => value.includes(item.customerId));

    setCustomerId(_.get(resourceSelect[0], 'customerId'));
    const dataSelect = resourceSelect && resourceSelect !== undefined ? resourceSelect[0] : undefined;
    addressRef.current.innerHTML = dataSelect && dataSelect.address;
    taxRef.current.innerHTML = dataSelect && dataSelect.taxNo;
    phoneRef.current.innerHTML = dataSelect && dataSelect.phone;
  };

  const customerList =
    customer && customer !== undefined
      ? customer.customers.map((item) => {
          return (
            <Option className="select-option" value={item.customerId}>
              {item.customerName}
            </Option>
          );
        })
      : null;

  return (
    <Form>
      <Form.Item style={{ width: '180px' }}>
        <p>
          <FormattedMessage id="modalInvoiceDetailCustomer" defaultMessage="Customer" />
        </p>
        {getFieldDecorator('customer', {
          initialValue: customerList ? _.get(data, 'customerId') : undefined,
          rules: [
            {
              required: true,
              message: intl.formatMessage({ id: 'modalInvoiceDetailCustomerValidate', defaultMessage: 'Please select Customer' }),
            },
          ],
        })(
          <Select
            disabled={true}
            className="customer-select"
            placeholder={intl.formatMessage({ id: 'modalInvoiceDetailCustomerPlaceholder', defaultMessage: 'Select Customer' })}
            size="small"
            showSearch
            filterOption={(input, option) =>
              option.props.children
                .toString()
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            // onPopupScroll={onScroll}
            // onBlur={handleLeaveSelect}
            onChange={handleChangeCustomer}
          >
            {!loading ? customerList : [...customerList, <Option key="loading">Loading...</Option>]}
          </Select>
        )}
      </Form.Item>
      <div>
        <div style={{ width: '272px', marginTop: '10px', marginBottom: '8px' }}>
          <span>
            <span ref={addressRef}>{_.get(data, 'customerAddress')}</span>
            <Icon
              type="environment"
              style={{
                fontSize: '12px',
                color: '#1D3557',
                paddingLeft: '6px',
                paddingRight: '0px',
                cursor: 'pointer',
              }}
            />
          </span>
        </div>
        <div style={{ marginBottom: '8px' }}>
          <span>
            <FormattedMessage id="modalInvoiceDetailTaxNo" defaultMessage="Tax No" /> : <span ref={taxRef}>{_.get(data, 'taxNo')}</span>
          </span>
        </div>
        <div style={{ marginBottom: '8px' }}>
          <span>
            <FormattedMessage id="modalInvoiceDetailDatePhone" defaultMessage="Phone" /> :{' '}
            <span ref={phoneRef}>{_.get(data, 'customerPhone')}</span>
          </span>
        </div>
      </div>
    </Form>
  );
});
