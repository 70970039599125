import { Col, DatePicker, Divider, Form, Row, Select, Input, Icon, Badge } from 'antd';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import LabeRequireForm from '../../../../../components/label-required-form';
// import ModalCustomerQuotationForm from '....//select-customer/customer-modal';
import _ from 'lodash';
import moment from 'moment';

const { Option } = Select;
const { Search } = Input;
const dateFormat = 'DD/MM/YYYY';

const CustomerFormView = ({ dataForm, selectCustomer }) => {
  const intl = useIntl();

  return (
    <Form name="customer" colon={false} className='po-form'>
      {/* <ModalCustomerQuotationForm visible={isOpen} onCancel={handleCancel} onOk={handleSave} setDataForm={setDataForm} dataForm={dataForm} /> */}

      <Row gutter={[24]}>
        <Col span={8}>
          <Form.Item
            label={<LabeRequireForm text={intl.formatMessage({ id: 'quotationLabelCustomer', defaultMessage: 'Customer' })} req={false} />}
            className="form-line-height"
          >
            {/* {_.get(dataForm, 'customerName') || '-'} */}

            <div className="text-group-customer">
              <div className="text-location-short">
                <span style={{ color: '#050505' }}>{_.get(dataForm, 'customerName')}</span>
              </div>
              <div className="text-location-short">
                <span style={{ color: '#E74C3C' }}>
                  {/* {`Over Credit Limit (${'20,000'} THB)`} */}
                </span>
              </div>
            </div>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={
              <LabeRequireForm
                text={intl.formatMessage({ id: 'quotationLabelCustomerAddress', defaultMessage: 'Customer Address' })}
                req={false}
              />
            }
            className="form-line-height"
          >
            {_.get(dataForm, 'customerAddressBookId') ? (
              <div className="text-group-customer">
                <div className="text-location-short">
                  <Icon
                    type="environment"
                    style={{
                      fontSize: '12px',
                      color: '#1D3557',
                      paddingLeft: '0px',
                      paddingRight: '4px',
                      cursor: 'pointer',
                    }}
                  />
                  <span
                    title={`${_.get(dataForm, 'address')}} ${(<Badge color="#000000" className="badge-text" />)} ${_.get(
                      dataForm,
                      'fullAddress'
                    )}`}
                    style={{ color: '#050505' }}
                  >
                    {_.get(dataForm, 'address') || '-'} <Badge color="#000000" className="badge-text" />{' '}
                    {_.get(dataForm, 'customerAddressBookName') || '-'}
                  </span>
                </div>
                <div className="text-short-placeholer">
                  <span style={{ color: '#898989' }}>
                    {intl.formatMessage({ id: 'quotationLabelTaxNo', defaultMessage: 'Tax No.' })} {_.get(dataForm, 'customerTaxNo') || '-'}
                  </span>
                </div>
              </div>
            ) : (
              <div className="text-group-customer">
                <div className="text-short-placeholer">
                  <Icon
                    type="environment"
                    style={{
                      fontSize: '12px',
                      color: '#1D3557',
                      paddingLeft: '0px',
                      paddingRight: '4px',
                      cursor: 'pointer',
                    }}
                  />
                  <span style={{ color: '#898989' }}>
                    {intl.formatMessage({ id: 'quotationLabelCustomerAddress', defaultMessage: 'Customer Address' })}
                  </span>
                </div>
                <div className="text-short-placeholer">
                  <span style={{ color: '#898989' }}>{intl.formatMessage({ id: 'quotationLabelTaxNo', defaultMessage: 'Tax No.' })}</span>
                </div>
              </div>
            )}
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            label={
              <LabeRequireForm text={intl.formatMessage({ id: 'quotationLabelCustomerContact', defaultMessage: 'Contact' })} req={false} />
            }
            className="form-line-height"
          >
            {_.get(dataForm, 'customerContactId') ? (
              <div className="text-group-customer">
                <div className="text-location-short">
                  <span style={{ color: '#050505' }}>{_.get(dataForm, 'customerContactName')}</span>
                </div>
                <div className="text-location-short">
                  <span style={{ color: '#050505' }}>
                    {_.get(dataForm, 'customerContactPhone')}, {_.get(dataForm, 'customerContactEmail')}
                  </span>
                </div>
              </div>
            ) : (
              <div className="text-group-customer">
                <div className="text-short-placeholer">
                  <span style={{ color: '#898989' }}>
                    {intl.formatMessage({ id: 'quotationLabelCustomerContact', defaultMessage: 'Contact' })}
                  </span>
                </div>
                <div className="text-short-placeholer">
                  <span style={{ color: '#898989' }}>
                    {intl.formatMessage({ id: 'quotationLabelPhone', defaultMessage: 'Email' })},{' '}
                    {intl.formatMessage({ id: 'quotationLabelPhone', defaultMessage: 'Phone,' })}
                  </span>
                </div>
              </div>
            )}
          </Form.Item>
        </Col>
      </Row>
      <Divider type="horizontal" style={{ marginBottom: '10px' }} />
      <Row gutter={[24]}>
        <Col span={8}>
          <Form.Item
            label={<LabeRequireForm text={intl.formatMessage({ id: 'quotationLabelIssueBy', defaultMessage: 'Issue by' })} req={false} />}
          >
            {_.get(dataForm, 'issuedName') || '-'}
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={
              <LabeRequireForm text={intl.formatMessage({ id: 'quotationLabelIssueDate', defaultMessage: 'Issue Date' })} req={false} />
            }
          >
            {_.get(dataForm, 'issueDate') ? moment(_.get(dataForm, 'issueDate'), 'YYYY-MM-DD').format(dateFormat) : '-'}
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={
              <LabeRequireForm
                text={intl.formatMessage({ id: 'quotationLabelReferenceNo', defaultMessage: 'Reference No.' })}
                req={false}
              />
            }
          >
            {_.get(dataForm, 'referenceNo') || '-'}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[24]}>
        <Col span={8}>
          <Form.Item
            label={
              <LabeRequireForm
                text={intl.formatMessage({ id: 'quotationLabelPurchaseOrderNo', defaultMessage: 'Purchase Order No.' })}
                req={false}
              />
            }
          >
            {_.get(dataForm, 'purchaseOrderNo') || '-'}
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={
              <LabeRequireForm
                text={intl.formatMessage({ id: 'quotationLabelPurchaseDate', defaultMessage: 'Purchase Date' })}
                req={false}
              />
            }
          >
            {_.get(dataForm, 'purchaseOrderDate')
              ? moment(_.get(dataForm, 'purchaseOrderDate'), 'YYYY-MM-DD').format(dateFormat)
              : '-'}
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={
              <LabeRequireForm text={intl.formatMessage({ id: 'quotationLabelQuotationNo', defaultMessage: 'Quotation No' })} req={false} />
            }
          >
            {_.get(dataForm, 'quotationNo') || '-'}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[24]}>
        <Col span={8}>
          <Form.Item
            label={
              <LabeRequireForm
                text={intl.formatMessage({ id: 'quotationLabelReferenceSalesDocumentNo', defaultMessage: 'Reference Sales Document No.' })}
                req={false}
              />
            }
          >
            {_.get(dataForm, 'reference') || '-'}
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

CustomerFormView.defaultProps = {};

export default CustomerFormView;
