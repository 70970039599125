import React from "react";
import { Card } from 'antd';
import NoDataBackground from "../../../../components/no-data-page";

const HistoryNodata = () => {
  return (
    <div>
      <Card >
        <NoDataBackground style={{ height: 300 }}
          text1={{ id: 'nodataXAssetHistory', text: 'No History' }}
          text2={{ id: 'nodataXAssetHistoryAppearHere', text: 'History will appear here.' }}
        />
      </Card>
    </div>
  )
}

export default HistoryNodata;