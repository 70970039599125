import httpCiient from '../../components/axiosClient';

const comCode = localStorage.getItem('comCode');

const getBrandDataList = async (payload) => {
  try {
    const response = await httpCiient.post(`/v5/item/manager/company/${comCode}/web/itembrand/list/active-inactive`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getModelDataList = async (payload) => {
  try {
    const response = await httpCiient.post(`/v5/item/manager/company/${comCode}/web/itemmodel/list/active-inactive`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const createBrandData = async (payload) => {
  try {
    const response = await httpCiient.post(`/v5/item/manager/company/${comCode}/web/itembrand`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const createModelData = async (payload) => {
  try {
    const response = await httpCiient.post(`/v5/item/manager/company/${comCode}/web/itemmodel`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getGroupDataList = async (payload) => {
  try {
    const response = await httpCiient.post(`/v5/item/manager/company/${comCode}/web/itemgroup/list/active-inactive`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getTypelDataList = async (payload) => {
  try {
    const response = await httpCiient.post(`/v5/item/manager/company/${comCode}/web/itemtype/list/active-inactive`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const createGroupData = async (payload) => {
  try {
    const response = await httpCiient.post(`/v5/item/manager/company/${comCode}/web/itemgroup`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const createTypeData = async (payload) => {
  try {
    const response = await httpCiient.post(`/v5/item/manager/company/${comCode}/web/itemtype`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export {
  getBrandDataList,
  getModelDataList,
  createBrandData,
  createModelData,
  getGroupDataList,
  getTypelDataList,
  createGroupData,
  createTypeData,
};
