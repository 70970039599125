import { Card, Col, Row, Tabs } from 'antd';
import React, { useState, useEffect } from 'react';
// import LeftCardApproval from './left-card';
import { AllticketContext } from './allticket-context';
// import RightCardApproval from './right-card';
import _ from 'lodash';
import {
  getMenuFilter,
  getWaiting,
  getApprove,
  getReject,
  getSearchApproval,
  approveAllRequest,
  rejectAllRequest,
} from '../../controllers/approval/approval-controller';
import { PageSettings } from '../../config/page-settings';
import { errorNotification, successNotification } from '../../components/v2/notification';
import LeftCardAllTicket from './left-card';
import RightCardAllticket from './right-card';
import CreateModalForm from './modal/createmodal';
import ViewModal from './modal/view';
import { getExportTicket, getTicketData, getTicketDetail } from '../../controllers/ticket/ticket';
import { data } from 'jquery';
import { useIntl } from 'react-intl';

const AllTicket = () => {
  const intl = useIntl();
  const [checkedListRequest, setCheckedListRequest] = useState();
  const [checkteam, setCheckTeam] = useState();
  const [orgIdRaw, setOrgIdRaw] = useState();
  const [paginationPage, setPaginationPage] = useState();
  const [spanRight, setSpanRight] = useState(18);
  const [spanLeft, setSpanLeft] = useState(6);
  const [menuList, setMenuList] = useState([]);
  const [indeterminateTeam, setIndeterminateTeam] = useState(false);
  const [checkedKeysTeam, setCheckedKeysTeam] = useState([]);
  const [searchList, setSearchList] = useState([]);
  const [changeStatus, setChangeStatus] = useState([]);
  const [changeTab, setChangeTab] = useState('1');
  const [checkAllTeam, setCheckAllTeam] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [fillter, setFillter] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [visibleCreate, setVisibleCreate] = useState(false);
  const [visibleView, setVisibleView] = useState(false);
  const [ticketId, setTicketId] = useState();
  const [ticketData, setTicketData] = useState([]);
  const [event, setEvent] = useState(false);
  const [editRecord, setEditRecord] = useState();
  const [sort, setSort] = useState([]);
  const [total, setTotal] = useState();
  const [datarecord, setDataRecord] = useState([]);

  useEffect(() => {
    const getApiMenu = async () => {
      const response = await getMenuFilter();
      setMenuList(response.data);
      const allteam = _.map(response.data.data.team, 'orgId');
      onCheckTeam(allteam);
    };
    getApiMenu();
  }, []);

  useEffect(() => {
    const getDataTable = async () => {
      const body = {
        teamList: checkedKeysTeam,
        ticketNo: _.get(fillter, 'reno'),
        startDate: _.get(fillter, 'startdate'),
        endDate: _.get(fillter, 'enddate'),
        statusList: _.get(fillter, 'status'),
        ticketTypeList: [],
        pageNumber: page,
        limit: size,
        orderBy: _.get(sort, 'columnKey'),
        orderType: _.get(sort, 'order') === 'descend' ? 'desc' : 'asc',
      };
      console.log('bodyTicket', body, 'sort', sort);
      const responase = await getTicketData(body);
      const mapIndex = _.map(_.get(responase, 'data.data.ticketList'), (item, index) => {
        return {
          ...item,
          index: (page - 1) * size + index + 1
        }
      })
      setTicketData({
        data: {
          ..._.get(responase, 'data.data'),
          ticketList: mapIndex
        }
      });
      setTotal(_.get(responase, 'data.data.totalItems'));
      setIsLoading(false);
    };
    setIsLoading(true);
    getDataTable();
    console.log('tikcetData', ticketData);
  }, [event, checkedKeysTeam, fillter, page, size]);

  let getAllKeys = (tree) => {
    let result = [];

    _.forEach(tree, (x) => {
      let childKeys = [];

      if (x.reqTypeList) {
        childKeys = getAllKeys(x.reqTypeList);
      }
      result.push(...[x.reqTypeCode, ...childKeys]);
    });

    return result;
  };

  const allKeysTeam = getAllKeys(_.get(menuList, 'data.team'));
  const onCheckTeam = (checkedKeys) => {
    console.log('allKeysTeam', checkedKeys);
    setCheckedKeysTeam(checkedKeys);
    setIndeterminateTeam(!!_.size(checkedKeys) && _.size(checkedKeys) < _.size(allKeysTeam));
    setCheckAllTeam(_.size(checkedKeys) === _.size(allKeysTeam));
  };

  const handleToggle = () => {
    setToggle((current) => !current);
  };

  const handleChangePage = (page) => {
    console.log('pageFine', page);
    setPage(page);
  };

  const handleChangeSize = (current, size) => {
    setSize(size);
    setPage(current);
  };

  const handleVisibleCreate = () => {
    setVisibleCreate(true);
  };

  const handleView = (record) => {
    setTicketId(record.ticketId);
    setVisibleView(true);
    setDataRecord(record);
  };

  const handleEdit = async (record) => {
    const responase = await getTicketDetail(_.get(record, 'ticketId'));
    setDataRecord(record);
    setEditRecord(_.get(responase, 'data.data.rawData'));
    setTicketId(record.ticketId);
    handleVisibleCreate();
    console.log('editRecord1', _.get(responase, 'data.data'));
    // console.log('editRecord',_.get(responase,'data.data.rawData'));
  };

  const handleExport = async () => {
    try {
      const body = {
        teamList: checkedKeysTeam,
        ticketNo: _.get(fillter, 'reno'),
        startDate: _.get(fillter, 'startdate'),
        endDate: _.get(fillter, 'enddate'),
        statusList: _.get(fillter, 'status'),
        ticketTypeList: [],
        pageNumber: 1,
        limit: total,
        orderBy: _.get(sort, 'columnKey'),
        orderType: _.get(sort, 'order') === 'descend' ? 'desc' : 'asc',

        viewFormat: [
          {
            colName: intl.formatMessage({ id: 'exportTicketNo', defaultMessage: 'Ticket NO.' }),
            sequence: 1,
            colCode: 'ticketNo',
          },
          {
            colName: intl.formatMessage({ id: 'exportTicketType', defaultMessage: 'Ticket Type' }),
            sequence: 2,
            colCode: 'ticketType',
          },
          {
            colName: intl.formatMessage({ id: 'exportTicketStatus', defaultMessage: 'Status' }),
            sequence: 3,
            colCode: 'status',
          },
          {
            colName: intl.formatMessage({ id: 'exportTicketDetails', defaultMessage: 'Ticket Details' }),
            sequence: 4,
            colCode: 'ticketDetail',
          },
          {
            colName: intl.formatMessage({ id: 'exportTicketTeam', defaultMessage: 'Team' }),
            sequence: 5,
            colCode: 'team',
          },
          {
            colName: intl.formatMessage({ id: 'exportTicketSubmittedDate', defaultMessage: 'Submitted Date' }),
            sequence: 6,
            colCode: 'submittedDate',
          },
          {
            colName: intl.formatMessage({ id: 'exportTicketRequiredDate', defaultMessage: 'Required Date' }),
            sequence: 7,
            colCode: 'requiredDate',
          }
        ],
      };
      const response = await getExportTicket(body);
      if (response.status === 200) {
        console.log('EEXXPP', response.data.data);
        window.open(response.data.data.data.url);
        successNotification(response.data.status.message);
      } else {
        errorNotification(response.data.status.message);
      }
    } catch (error) { }
  };

  return (
    <PageSettings.Consumer>
      {({ checkPermissionAction }) =>
        checkPermissionAction(`P53PG1C1`, `P53PG1C1A1`) ? (
          <AllticketContext.Provider
            value={{
              state: {
                checkedListRequest,
                checkteam,
                orgIdRaw,
                paginationPage,
                spanRight,
                spanLeft,
                menuList,
                indeterminateTeam,
                checkedKeysTeam,
                searchList,
                checkAllTeam,
                changeTab,
                isLoading,
                fillter,
                selectedRowKeys,
                toggle,
                visibleCreate,
                ticketData,
                event,
                editRecord,
                sort,
                ticketId,
                page,
                total,
                isLoading,
                datarecord,
              },
              setState: {
                setSpanRight,
                setSpanLeft,
                setCheckedListRequest,
                setCheckTeam,
                setOrgIdRaw,
                setPaginationPage,
                setCheckedKeysTeam,
                setIndeterminateTeam,
                setChangeStatus,
                setChangeTab,
                setCheckAllTeam,
                setIsLoading,
                setFillter,
                setSelectedRowKeys,
                setToggle,
                setVisibleCreate,
                setEditRecord,
                setEvent,
                setSort,
                setDataRecord,
                setPage
              },
              fnc: {
                onCheckTeam,
                handleToggle,
                handleChangePage,
                handleChangeSize,
                handleVisibleCreate,
                handleView,
                handleEdit,
                handleExport,
              },
            }}
          >
            <Row gutter={[16, 16]}>
              <Col span={toggle ? 0 : 6}>
                <LeftCardAllTicket />
              </Col>
              <Col span={toggle ? 24 : 18}>
                <RightCardAllticket />
              </Col>
            </Row>
            <CreateModalForm />
            <ViewModal
              visible={visibleView}
              setVisible={setVisibleView}
              ticketId={ticketId}
              datarecord={datarecord}
              setDataRecord={setDataRecord}
            />
          </AllticketContext.Provider>
        ) : null
      }
    </PageSettings.Consumer>
  );
};

export default AllTicket;
