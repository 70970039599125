import React from 'react'
import { Row, Col, Form } from 'antd'
import './css/index.css'
import { useIntl, FormattedMessage } from 'react-intl'
import _ from 'lodash'


export default (props) => {
  const { dataDefault } = props
  const intl = useIntl()
  const LabeRequire = (props) => {
    const { text, req } = props;
    return (
      <span>
        {text}&nbsp;
        {req ? <span style={{ fontSize: '13px', color: '#fa383e' }}>*</span> : ''}
      </span>
    );
  };



  return (
    <div>
      <Form>
        <Row gutter={[16, 8]}>
          <Col span={12}>
            <Form.Item
              className='modal-asset-register-view'
              label={<LabeRequire text={intl.formatMessage({ id: 'modalAssetAssetName', defaultMessage: 'Asset Name' })} req={false} />}>
              <span className='asset-view-text'>{_.get(dataDefault, 'assetName')}</span>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              className='modal-asset-register-view'
              label={<LabeRequire text={intl.formatMessage({ id: 'modalAssetOwner', defaultMessage: 'Owner' })} req={false} />}>
              <span className='asset-view-text'>{_.get(dataDefault, 'orgName')}</span>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col span={12}>
            <Form.Item
              className='modal-asset-register-view'
              label={<LabeRequire text={intl.formatMessage({ id: 'modalAssetAssetClass', defaultMessage: 'Asset Class' })} req={false} />}>
              <span className='asset-view-text'>{_.get(dataDefault, 'assetClassName')}</span>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              className='modal-asset-register-view'
              label={<LabeRequire text={intl.formatMessage({ id: 'modalAssetAssetType', defaultMessage: 'Asset Type' })} req={false} />}>
              <span className='asset-view-text'>{_.get(dataDefault, 'assetTypeName')}</span>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col span={12}>
            <Form.Item
              className='modal-asset-register-view'
              label={<LabeRequire text={intl.formatMessage({ id: 'modalAssetBrand', defaultMessage: 'Brand' })} req={false} />}>
              <span className='asset-view-text'>{_.get(dataDefault, 'assetBrandName')}</span>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              className='modal-asset-register-view'
              label={<LabeRequire text={intl.formatMessage({ id: 'modalAssetModel', defaultMessage: 'Model' })} req={false} />}>
              <span className='asset-view-text'>{_.get(dataDefault, 'assetModelName')}</span>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col span={12}>
            <Form.Item
              className='modal-asset-register-view'
              label={<LabeRequire text={intl.formatMessage({ id: 'modalAssetSerialNo', defaultMessage: 'Serial No.' })} req={false} />}>
              <span className='asset-view-text'>{_.get(dataDefault, 'serialNo') || '-'}</span>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              className='modal-asset-register-view'
              label={<LabeRequire text={intl.formatMessage({ id: 'modalAssetSRegisterBy', defaultMessage: 'Register By' })} req={false} />}>
              <span className='asset-view-text'>{_.get(dataDefault, 'registerName') || '-'}</span>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col span={24}>
            <Form.Item
              className='modal-asset-register-view modal-asset-register-view-remark'
              label={<LabeRequire text={intl.formatMessage({ id: 'modalAssetRemark', defaultMessage: 'Remark' })} req={false} />}>
              <span className='asset-view-text'>{_.get(dataDefault, 'description') || '-'}</span>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  )
}


