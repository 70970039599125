import React, { useState, useEffect } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Row, Col, DatePicker, Table, ConfigProvider } from 'antd';
import AppTable from '../../../../components/v2/table';
import HttpClient from '../../../../components/httpClient';
import cssStyle from './css/paydMyHours.css';
import moment from 'moment';
import client from '../../../../components/axiosClient';
import enUS from 'antd/es/locale/en_US';
import thTH from 'antd/es/locale/th_TH';
import 'moment/locale/th';
moment.locale('en');

const { MonthPicker } = DatePicker;


export default ({ userId, langValue }) => {
  const intl = useIntl();

  const monthFormat = 'MMMM YYYY';
  const date = moment().format('YYYY-MM-DD');
  const today = new Date();
  const [dataMyHours, setDataMyHours] = useState([]);
  const [total, setTotal] = useState();
  const [actual, setActual] = useState();
  const [capacity, setCapasity] = useState();
  const [utilization, setUtilization] = useState();
  const [locale, setLocale] = useState('enUS');


  //-----------------------------------------------รับ object ชื่อ status-------------------------------------------------------

  const columns = [
    {
      title: intl.formatMessage({ id: 'userMgntUsersDetailColHoursTime', defaultMessage: 'Time Attendance' }),
      dataIndex: 'timeAttendace',
      key: 'timeAttendace',
      width: '45%'
    },
    {
      title: intl.formatMessage({ id: 'userMgntUsersDetailColStatus', defaultMessage: 'Status' }),
      dataIndex: 'status',
      key: 'status',
      width: '35%'
    },
    {
      title: intl.formatMessage({ id: 'userMgntUsersDetailColHoursActual', defaultMessage: 'Actual' }),
      dataIndex: 'actual',
      key: 'actual',
      width: '20%',
      render: ((text, record, index) =>
        <p style={{ color: `${record.color}`, margin: '0px' }}>{record.actual}</p>
      )
    },
  ];

  useEffect(() => {
    loadMyHours();
    changeLocale();
  }, []);


  const loadMyHours = async () => {
    const url = `${process.env.REACT_APP_URL_MANAGER}`;
    const port = `${process.env.REACT_APP_RESOURCE_MANAGER_PORT}`;
    client.defaults.baseURL = `${url}:${port}`;

    try {
      const response = await client.get(`/companies/members/${userId}/times-sheet/on-month/${date}`);
      setDataMyHours(response.data.timesLists);
      setTotal(response.data.total);
      setActual(response.data.actual);
      setCapasity(response.data.capacity);
      setUtilization(response.data.utilization);
    } catch {
      console.error();
    }
  }

  const onSelectDate = async (selected) => {
    let dateData = moment(selected).format("-MM-DD");
    if (langValue === 'TH') {
      // let year = parseInt(moment(selected).format("YYYY")) - 543;
      let year = parseInt(moment(selected).format("YYYY"));
      dateData = String(year + dateData);
    } else {
      let year = parseInt(moment(selected).format("YYYY"));
      dateData = String(year + dateData);
    }

    const url = `${process.env.REACT_APP_URL_MANAGER}`;
    const port = `${process.env.REACT_APP_RESOURCE_MANAGER_PORT}`;
    client.defaults.baseURL = `${url}:${port}`;

    try {
      const response = await client.get(`/companies/members/${userId}/times-sheet/on-month/${dateData}`);
      setDataMyHours(response.data.timesLists);
      setTotal(response.data.total);
      setActual(response.data.actual);
      setCapasity(response.data.capacity);
      setUtilization(response.data.utilization);
    } catch {
      console.error();
    }
  }

  const disabledDate = (current) => {
    if (langValue === "EN") {
      return current && current > moment().endOf('day');
    } else {
      // return current && current > moment().add(543, "year").endOf('day');
      return current && current > moment().endOf('day');
    }
  }

  const showTotal = (total) => {
    return (
      <span>
        <FormattedMessage id="lblRowPerPage" defaultMessage="Rows per page: " />
      </span>
    );
  };

  const changeLocale = () => {
    let codeLang = langValue ? langValue : 'EN';
    let changeLang = codeLang === 'EN' ? enUS : thTH;

    setLocale(changeLang);
    if (!changeLang) {
      moment.locale('en');
    } else {
      moment.locale('th-th');
    }
  };

  return (
    <div className="payd-MyHours">
      <Row className="month-row">
        <ConfigProvider locale={locale}>
          <MonthPicker
            className='monthPicker-date'
            key={locale ? locale.locale : 'en'}
            format={monthFormat}
            bordered={false}
            defaultValue={moment(today, monthFormat)}
            // defaultValue={langValue === "EN" ? moment(today, monthFormat) : moment(today, monthFormat).add(543, "year").endOf('day')}
            defaultValue={moment(today, monthFormat)}
            onChange={onSelectDate}
            allowClear={false}
            disabledDate={disabledDate}
          />
        </ConfigProvider>
      </Row>
      <Row className="details-myHours">
        <Col span={8} className="details-capacity">
          <p>
            <FormattedMessage id="userMgntUsersDetailLblHoursCapacity" defaultMessage="Capacity" />
          </p>
          <p className="font-p">{`${capacity || 0}`}</p>
        </Col>
        <Col span={8} className="details-actual">
          <p>
            <FormattedMessage id="userMgntUsersDetailLblHoursActual" defaultMessage="Actual" />
          </p>
          <p className="font-p">{`${actual || 0}`}</p>
        </Col>
        <Col span={8} className="details-utilization">
          <p>
            <FormattedMessage id="userMgntUsersDetailLblHoursUtilization" defaultMessage="Utilization" />
          </p>
          <p className="font-p">{`${utilization || 0} %`}</p>
        </Col>
      </Row>
      <Table
        className="table-myHours"
        columns={columns}
        size="middle"
        dataSource={dataMyHours}
        pagination={{
          total: total,
          showTotal: showTotal,
          defaultCurrent: 1,
          pageSizeOptions: ['10', '20', '30', '40', '50'],
          showSizeChanger: true,
          locale: { items_per_page: '' },
        }}
      />
      <div style={{ display: 'flex', position: 'relative', marginTop: '-37px' }}>
        <span>
          <FormattedMessage id="lblTotal" defaultMessage="Total" />
          {` ${total} `}
          <FormattedMessage id="lblitems" defaultMessage="items" />
        </span>
      </div>
    </div>
  )
}