import { Avatar, Col, Divider, Row, Switch, Tag } from 'antd';
import _ from 'lodash';
import React, { useState, useContext, useEffect } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import Button_01 from '../../../../components/v2/button_01';
import { errorNotification, successNotification } from '../../../../components/v2/notification';
import { updateWarehouseDetail } from '../../../../controllers/warehouse/warehouse';
import WarehouseContext from '../../context';
import '../css/index.css';
import { PageSettings } from '../../../../config/page-settings';

const Details = ({}) => {
  const app = useContext(PageSettings);
  const { checkPermissionAction } = app;
  const intl = useIntl();
  const { state, setState, fnc } = useContext(WarehouseContext);
  const { toggle, warehouseId, warehouseDetail, warehouseActive } = state;
  const { setWarehouseActive, setTrigger, setWarehouseRecord, setWarehouseIsOpen, setEventDetail } = setState;
  const { handleEditWarehouse } = fnc;
  const comCode = localStorage.getItem('comCode');
  const onChange = async (checked) => {
    setWarehouseActive(checked);
    try {
      const payload = {
        statusActive: checked,
      };
      //63a33c996215c000271d2f63
      const response = await updateWarehouseDetail(warehouseId, payload);
      if (_.get(response.data.status, 'code') === 1) {
        successNotification(_.get(response.data.status, 'message'));
      }
      setTrigger((current) => !current);
      setEventDetail((current) => !current);
    } catch (error) {
      errorNotification(_.get(error.response.data.status, 'message'));
    }
  };

  return (
    <div className="warehouse-layout-details">
     
        <div className="warehouse-layout-action">
        {checkPermissionAction(`P52PG1C1`, `P52PG1C1A5`) && _.size(_.get(warehouseDetail, 'isManager')) > 0 ? (
          <div style={{ marginRight: 10 }}>
            <Switch value checked={warehouseActive} onChange={onChange} />
          </div>
        ) : null}
          {checkPermissionAction(`P52PG1C1`, `P52PG1C1A3`) && _.size(_.get(warehouseDetail, 'isManager')) > 0 ? (
          <Button_01
            key="submit"
            type="primary"
            btnsize="wd_df"
            style={{ marginRight: 'unset' }}
            onClick={() => handleEditWarehouse(_.get(warehouseDetail, 'warehouseId'))}
          >
            <FormattedMessage id="btnEdit" defaultMessage="Edit" />
          </Button_01>
          ) : null}
        </div>
      

      <div style={{ marginTop: 20 }}>
        <Row>
          <Col span={6}>
            <div className="profile-warehouse-detail">
              <Avatar className="avartar-asset" shape="square" size={150} src={_.get(warehouseDetail, 'warehouseImg')} />
            </div>
          </Col>
          <Col span={18}>
            <Row>
              <div className="warehouse-layout-row1">
                <p className="warehouse-name-detail">
                  {_.get(warehouseDetail, 'warehouseCode') || '-'} · {_.get(warehouseDetail, 'warehouseName') || '-'}
                </p>
                <Tag className="status-tag" color={_.get(warehouseDetail, 'statusColor') || '-'}>
                  {_.get(warehouseDetail, 'status') || '-'}
                </Tag>
              </div>
            </Row>
            <Row>
              <div className="warehouse-layout-row2">
                <div className="phone-width">
                  <p className="warehouse-label-detail">
                    <FormattedMessage id="warehouseDetailPhone" defaultMessage="Phone" />
                  </p>
                  <p className="warehouse-value-detail">{_.get(warehouseDetail, 'warehousePhone') || '-'}</p>
                </div>
                <div>
                  <p className="warehouse-label-detail">
                    <FormattedMessage id="warehouseDetailType" defaultMessage="Type" />
                  </p>
                  <p className="warehouse-value-detail">{_.get(warehouseDetail, 'warehouseTypeDisplay') || '-'}</p>
                </div>
              </div>
            </Row>
            <Row>
              {comCode === 'EBE' ? (
                <div className="warehouse-layout-row2">
                  <div className="phone-width">
                    <p className="warehouse-label-detail">
                      <FormattedMessage id="warehouseDetailDeliveryGP" defaultMessage="Delivery GP" />
                    </p>
                    <p className="warehouse-value-detail">{_.get(warehouseDetail, 'grossProfitMargin') || '-'}</p>
                  </div>
                  <div>
                    <p className="warehouse-label-detail">
                      <FormattedMessage id="warehouseDetailWalkInGP" defaultMessage="Walk-In GP" />
                    </p>
                    <p className="warehouse-value-detail">{_.get(warehouseDetail, 'grossProfitMarginWalkIn') || '-'}</p>
                  </div>
                </div>
              ) : (
                <div className="warehouse-layout-row3">
                  {/* <p className="warehouse-label-detail">
                    <FormattedMessage id="warehouseDetailGrossProfitMargin" defaultMessage="Gross Profit Margin" />
                  </p>
                  <p className="warehouse-value-detail">
                    {_.get(warehouseDetail, 'grossProfitMarginNum') !== 0 ? _.get(warehouseDetail, 'grossProfitMargin') : '-'}
                  </p> */}
                </div>
              )}
            </Row>
            <Row>
              <div className="warehouse-layout-row4">
                <p className="warehouse-label-detail">
                  <FormattedMessage id="warehouseDetailAddress" defaultMessage="Address" />
                </p>
                <p className="warehouse-value-detail text-long">{_.get(warehouseDetail, 'address') || '-'}</p>
              </div>
            </Row>
            <Divider type="horizontal" style={{ marginTop: '12px', marginBottom: '12px' } || '-'} />
            <Row>
              <div className="warehouse-layout-row5">
                <p className="warehouse-label-detail">
                  <FormattedMessage id="warehouseDetailRemark" defaultMessage="Remark" />
                </p>
                <p className="warehouse-value-detail text-long">{_.get(warehouseDetail, 'remark') || '-'}</p>
              </div>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Details;
