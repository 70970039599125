import React, { useState } from 'react';
import { Modal, Row, Col, Form, InputNumber, Spin, DatePicker } from 'antd';
import Button01 from '../../v2/button_01';
import Button02 from '../../v2/button_02';
import { useIntl, FormattedMessage } from 'react-intl';
import './css/index.css';
import _ from 'lodash';
import LabeRequireForm from '../../label-required-form';
import { useEffect } from 'react';

const ShippingDate = (props) => {
  const intl = useIntl();
  const { data, visible, onCancel, onSave, form, shippingDateOrder } = props;
  const { getFieldDecorator, resetFields, validateFields, setFieldsValue } = form;
  const [loading, setLoading] = useState(false);

  const handleCreate = () => {
    validateFields(async (err, values) => {
      if (err) {
        return;
      }
      setLoading(true);
      onSave(values, setLoading);
      setTimeout(() => {
        resetFields();
      }, 1000);
    });
  };

  const handleCancel = () => {
    onCancel();
    setTimeout(() => {
      resetFields();
    }, 1000);
  };

  return (
    <div>
      <Modal
        title={intl.formatMessage({ id: 'planningTextPlanning', defaultMessage: 'Planning' })}
        centered
        visible={visible}
        onCancel={handleCancel}
        onOk={handleCreate}
        bodyStyle={{ padding: '16px 24px' }}
        width={350}
        footer={[
          <div>
            <Button02 style={{ margin: '0px 0px 0px 10px' }} btnwidth="wd_at" onClick={handleCancel} disabled={loading}>
              <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
            </Button02>
            <Button01 type="primary" btnwidth="wd_at" onClick={loading === false ? handleCreate : null} disabled={loading}>
              <FormattedMessage id="btnSave" defaultMessage="Save" />
            </Button01>
          </div>,
        ]}
      >
        <Form>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item
                colon={false}
                className="order-modal-form"
                label={
                  <LabeRequireForm text={intl.formatMessage({ id: 'vehicleDeliveryDate', defaultMessage: 'Delivery Date' })} req={false} />
                }
              >
                {getFieldDecorator('shippingDate', {
                  initialValue: shippingDateOrder ? shippingDateOrder : undefined,
                  rules: [
                    {
                      required: false,
                    },
                  ],
                })(<DatePicker allowClear={false} style={{ width: '100%' }} format="ddd, MMM DD YYYY" />)}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

const ModalShippingDate = Form.create({
  name: 'modal_form',
  mapPropsToFields(props) {},
})(ShippingDate);

export default ModalShippingDate;
