import _ from 'lodash';
import httpClient from '../../components/axiosClient';

const comCode = localStorage.getItem('comCode');
const memComId = localStorage.getItem('memComId');

const getMemberCompanyAllWhere = async (data) => {
  const payload = {
    user_id: memComId,
    like: [],
    equal: [],
    date: [],
    order: 'fullname asc',
    limit: 1000,
    index_page: 1,
    com_id: comCode,
    notResign: true,
  };

  try {
    const response = await httpClient.post(`/v2/searchusers`, payload);
    if (response.status === 200) {
      return _.chain(response)
        .get('data.data')
        .map((o) => {
          return {
            key: o.mem_com_id,
            memId: o.mem_id,
            memComId: o.mem_com_id,
            fullname: o.fullname,
            phone: o.phone,
            email: o.email,
          };
        })
        .value();
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};

const saveMemberWarehouse = async (data, warehouseId) => {
  const payload = {
    memComIdList: _.map(data, (memComId) => {
      return _.toString(memComId);
    }),
    warehouseId: warehouseId,
  };
  try {
    const response = await httpClient.post(`/v1/warehouse/manager/company/${comCode}/web/warehouse-member`, payload);
    if (response.status === 200) {
      return { status: true, message: _.get(response, 'data.status.message'), data: _.get(response, 'data.data') };
    } else {
      return { status: false, message: _.get(response, 'data.status.message') };
    }
  } catch (error) {
    return { status: false, message: 'เกิดข้อผิดพลาด' };
  }
};

const getMemberWarehouseAllWhere = async (where, warehouseId) => {
  const payload = {
    search: _.get(where, 'search') || {},
    pageNumber: _.get(where, 'pageNumber') || 1,
    limit: _.get(where, 'limit') || 1000,
    orderBy: _.get(where, 'orderBy') || 'createdAt',
    orderType: _.get(where, 'orderType') || 'asc',
    warehouseId: warehouseId,
  };
  try {
    const response = await httpClient.post(`/v1/warehouse/manager/company/${comCode}/web/warehouse-member/search`, payload);
    if (response.status === 200) {
      return {
        data: _.chain(response)
          .get('data.data.memberList')
          .map((o) => {
            return {
              key: o.memComId,
              memComId: _.toNumber(o.memComId),
              fullname: o.name,
              phone: o.phone,
              email: o.email,
              profile: o.profile,
            };
          })
          .value(),
        total: _.get(response, 'data.data.totalItems'),
      };
    } else {
      return { data: [], total: 0 };
    }
  } catch (error) {
    return { data: [], total: 0 };
  }
};

const deleteMemberWarehouseById = async (memComId, warehouseId) => {
  try {
    const response = await httpClient.delete(`/v1/warehouse/manager/company/${comCode}/web/warehouse-member/${warehouseId}/${memComId}`);
    if (response.status === 200) {
      return { status: true, message: _.get(response, 'data.status.message'), data: _.get(response, 'data.data') };
    } else {
      return { status: false, message: _.get(response, 'data.status.message') };
    }
  } catch (error) {
    return { status: false, message: 'เกิดข้อผิดพลาด' };
  }
};

export { getMemberCompanyAllWhere, saveMemberWarehouse, getMemberWarehouseAllWhere, deleteMemberWarehouseById };
