import httpCiient from '../../components/axiosClient';

const comCode = localStorage.getItem('comCode');

const getMenuFilter = async () => {
  try {
    const response = await httpCiient.get(`/v5/resource/manager/company/${comCode}/web/menufilterrequest/myrequest`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getWaiting = async () => {
  try {
    const response = await httpCiient.post(`/v5/resource/manager/company/${comCode}/web/approvalrequest`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getApprove = async (payload) => {
  try {
    const response = await httpCiient.post(`/v5/resource/manager/company/${comCode}/web/approvedrequest`,payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getReject = async (payload) => {
  try {
    const response = await httpCiient.post(`/v5/resource/manager/company/${comCode}/web/rejectedrequest`,payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getSearchApproval = async (payload) => {
  try {
    const response = await httpCiient.post(`/v5/resource/manager/company/${comCode}/web/approvalrequest`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const approveAllRequest = async (payload) => {
  try {
    const response = await httpCiient.put(`/v5/resource/manager/company/${comCode}/web/approveallrequests`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const rejectAllRequest = async (payload) => {
  try {
    const response = await httpCiient.put(`/v5/resource/manager/company/${comCode}/web/rejectallrequests`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getReasonByCode = async (code) => {
  try {
    const response = await httpCiient.get(`/resource/manager/approve/reason/company/requesttype/${code}`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getDetailKYC = async (id) => {
  try {
    const response = await httpCiient.get(`/v5/resource/manager/company/${comCode}/web/request/customerkyc/${id}`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
const getRedeemPoint = async (code) => {
  try {
    const response = await httpCiient.get(`/v5/resource/manager/company/${comCode}/web/request/redeempoint/${code}`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getRegistration = async (code) => {
  try {
    const response = await httpCiient.get(`/v5/resource/manager/company/${comCode}/web/request/registermember/${code}`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getDataModalItemRequest = async (code) => {
  try {
    const response = await httpCiient.get(`/v5/resource/manager/company/${comCode}/web/requestitem/${code}`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const cancelItemRequest = async (code,playload) => {
  try {
    const response = await httpCiient.put(`/v5/resource/manager/company/${comCode}/web/requestitem/cancel/${code}`,playload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getReasonReject = async (code) => {
  try {
    const response = await httpCiient.get(`resource/manager/approve/reason/company/requesttype/${code}`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};


// https://manager.staging.rapidtech.app/resource-manager-new/resource/manager/approve/reason/company/requesttype/equipment
// https://manager.staging.rapidtech.app/resource-manager-new/resource/manager/approve/reason/company/requesttype/{requestTypeCode}



export {
  getMenuFilter,
  getWaiting,
  getApprove,
  getReject,
  getSearchApproval,
  approveAllRequest,
  rejectAllRequest,
  getReasonByCode,
  getDetailKYC,
  getRedeemPoint,
  getRegistration,
  getDataModalItemRequest,
  cancelItemRequest,
  getReasonReject,
};
