import React, { useState, useEffect, useRef } from 'react';
import { Form, Modal, Spin } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import Button01 from '../../../components/v2/button_01';
import Button02 from '../../../components/v2/button_01';
import ModalSetttingForm from './form';
import {
  editGasPrice,
  saveGasPrice,
  saveTripAllowance,
  saveAllowance,
  editAllowance,
  editTripAllowance,
} from '../../../controllers/task/setting';
import _ from 'lodash';
import { successNotification, errorNotification } from '../../../components/v2/notification';

const ModalFormSettting = (props) => {
  const { visible, setVisible, type, form, data, setData, setTrigger } = props;
  const intl = useIntl();
  const TitleType = data ? 'Edit' : 'Create';
  const TitleModal =
    type === 'tripAllowance'
      ? intl.formatMessage({ id: `menuTripAllowanceSetting${TitleType}`, defaultMessage: `${TitleType} Trip Allowance` })
      : type === 'allowance'
        ? intl.formatMessage({ id: `menuAllowanceSetting${TitleType}`, defaultMessage: `${TitleType} Allowance` })
        : type === 'freight'
          ? intl.formatMessage({ id: `menuGasPriceSetting${TitleType}`, defaultMessage: `${TitleType} Freight` })
          : '';
  const [selectData, setSelectData] = useState();
  const [loading, setLoading] = useState(false);
  const inputNumberPrice1 = useRef(null);
  const inputNumberPrice2 = useRef(null);
  const inputNumberPrice3 = useRef(null);

  useEffect(() => {
    if (data && visible) {
      setLoading(true);
      setSelectData({
        customer: {
          customerId: _.get(data, 'customerDetail.id'),
          customerCode: _.get(data, 'customerDetail.code'),
          customerName: _.get(data, 'customerDetail.name'),
        },
        project: {
          projectId: _.get(data, 'projectDetail.id'),
          projectName: _.get(data, 'projectDetail.name'),
          projectNo: _.get(data, 'projectDetail.no'),
        },
        taskType: {
          id: _.get(data, 'taskTypeDetail.id'),
          name: _.get(data, 'taskTypeDetail.name'),
        },
        source: {
          comAddressBookId: _.get(data, 'sourceLocationDetail.id'),
          addressName: _.get(data, 'sourceLocationDetail.name'),
          fullAddress: _.get(data, 'sourceLocationDetail.fullName'),
        },
        destination: {
          addressName: _.get(data, 'destinationLocationDetail.name'),
          customerAddressId: _.get(data, 'destinationLocationDetail.id'),
          address: _.get(data, 'destinationLocationDetail.fullName'),
        },
      });
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [data, visible]);

  const handleSave = (e) => {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (err) {
        return;
      }
      try {
        const comCode = localStorage.getItem('comCode');

        let payload = {
          comCode: comCode,
          customerDetail: {
            id: _.get(selectData, 'customer.customerId', null),
            code: _.get(selectData, 'customer.customerCode', null),
            name: _.get(selectData, 'customer.customerName', null),
          },
          projectDetail: {
            id: _.get(selectData, 'project.projectId', null),
            code: null,
            name: _.get(selectData, 'project.projectName', null),
            no: _.get(selectData, 'project.projectNo', null),
          },
          taskTypeDetail: {
            id: _.get(selectData, 'taskType.id') !== 'notSpecified' ? _.get(selectData, 'taskType.id') : null,
            code: _.get(selectData, 'taskType.prefix', null),
            name: _.get(selectData, 'taskType.name', null),
          },
          sourceLocationDetail: {
            id: _.get(selectData, 'source.comAddressBookId', null),
            code: null,
            name: _.get(selectData, 'source.addressName', null),
            fullName: _.get(selectData, 'source.fullAddress', null),
          },
          destinationLocationDetail: {
            id: _.get(selectData, 'destination.customerAddressId', null),
            code: null,
            name: _.get(selectData, 'destination.addressName', null),
            fullName: _.get(selectData, 'destination.address', null),
          },
          detail: {
            minPrice: parseFloat(_.get(values, 'gMinimum')),
            maxPrice: parseFloat(_.get(values, 'gMaximum')),
            price: type === 'freight' ? parseFloat(_.get(values, 'pricePerTrip')) : parseFloat(_.get(values, 'allowance')),
            price1: _.get(values, 'driver1') ? parseFloat(_.get(values, 'driver1', 0)) : undefined,
            price2: _.get(values, 'driver2') ? parseFloat(_.get(values, 'driver2', 0)) : undefined,
            distance: _.get(values, 'distance') ? parseFloat(_.get(values, 'distance')) : undefined,
          },
        };
        const response = await fncApi(payload);
        if (_.get(response, 'data.status.code') === 200) {
          successNotification(_.get(response, 'data.status.message'));
          setTrigger((cur) => !cur);
          setVisible(false);
          form.resetFields();
          setData();
          setSelectData();
        } else {
          errorNotification(_.get(response, 'data.status.message'));
        }
      } catch (error) {
        console.log('error', error);
        errorNotification(_.get(error, 'response.data.status.message'));
      }
    });
  };

  const fncApi = async (payload) => {
    const memComId = localStorage.getItem('memComId');
    const name = localStorage.getItem('name');
    if (data)
      payload = {
        ...payload,
        updatedBy: parseFloat(memComId),
        updatedName: name,
      };
    if (!data)
      payload = {
        ...payload,
        createdBy: parseFloat(memComId),
        createdName: name,
      };
    if (type === 'freight') {
      if (data) {
        return editGasPrice(payload, _.get(data, '_id'));
      } else {
        return saveGasPrice(payload);
      }
    } else if (type === 'tripAllowance') {
      if (data) {
        return editTripAllowance(payload, _.get(data, '_id'));
      } else {
        return saveTripAllowance(payload);
      }
    } else if (type === 'allowance') {
      if (data) {
        return editAllowance(payload, _.get(data, '_id'));
      } else {
        return saveAllowance(payload);
      }
    }
  };

  const handleCancel = () => {
    setVisible(false);
    form.resetFields();
    setData();
    setSelectData();
  };

  const mouseOver = (value) => {
    if (inputNumberPrice1.current) inputNumberPrice1.current.blur();
    if (inputNumberPrice2.current) inputNumberPrice2.current.blur();
    if (inputNumberPrice3.current) inputNumberPrice3.current.blur();
  };

  return (
    <Modal
      title={TitleModal}
      visible={visible}
      onOk={handleSave}
      onCancel={handleCancel}
      centered={true}
      width={620}
      footer={[
        <Button02 key="back" btnsize="wd_df" onClick={handleCancel}>
          <FormattedMessage id="btnClose" defaultMessage="Close" />
        </Button02>,
        <Button01
          key="submit"
          type="primary"
          btnsize="wd_df"
          onClick={handleSave}
          style={{ margin: 'unset' }}
          onMouseOver={() => mouseOver()}
        >
          <FormattedMessage id="userDetailModalBtnSave" defaultMessage="Save" />
        </Button01>,
      ]}
    >
      <Spin spinning={loading}>
        <ModalSetttingForm
          form={form}
          selectData={selectData}
          setSelectData={setSelectData}
          type={type}
          data={data}
          visible={visible}
          refPrice={{
            inputNumberPrice1,
            inputNumberPrice2,
            inputNumberPrice3,
          }}
        />
      </Spin>
    </Modal>
  );
};

const ModalSetting = Form.create({
  name: 'setting-form',
  mapPropsToFields() { },
})(ModalFormSettting);

export default ModalSetting;
