import React, { createContext, useEffect, useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { errorNotification, successNotification } from '../../components/v2/notification';
import { useIntl } from 'react-intl';
import { getTeacoMarket } from '../../controllers/taeco-overview-api/taeco-overview-api';

const OverviewContext = createContext();

const OverviewProvider = ({ children }) => {
  const intl = useIntl();
  const [tabKey, setTabKey] = useState('waiting');
  const [filterDate, setFilterDate] = useState('today');
  const [filterDateDuration, setFilterDateDuration] = useState([]);
  const [dataBucket, setDataBucket] = useState();

  const [mapItemDashboard, setMapItemDashboard] = useState([])
  const [mapSelectFilter, setMapSelectFilter] = useState(['contributionRate', 'availableLots'])

  useEffect(() => {
    const getTeacoMarketApi = async () => {
      const payload = {
        code: filterDate,
        startDate: filterDateDuration[0],
        endDate: filterDateDuration[1],
      };

      try {
      const response = await getTeacoMarket(payload);
      console.log('getTeacoMarket', response)
      setDataBucket(response)
      onSetItemDashBoard(_.get(response, 'data.marketArea'))
      } catch {}
    };

    getTeacoMarketApi();
  }, [filterDate, filterDateDuration]);

  const onSetItemDashBoard = (data) => {
    setMapItemDashboard(data)
  }

  const ex = () => {

  }

  return (
    <OverviewContext.Provider
      value={{
        state: {
          tabKey,
          filterDate,
          mapSelectFilter,
          mapItemDashboard,
          dataBucket,
        },
        setState: {
          setTabKey,
          setFilterDate,
          setMapSelectFilter,
          setFilterDateDuration,
        },
        func: {
          ex,
        },
      }}
    >
      {children}
    </OverviewContext.Provider>
  );
};

export { OverviewContext, OverviewProvider };
