import React, { useState, useEffect, useRef } from 'react';
import { Form, Modal, Input, Row, Col, Select, Divider, Table, Progress, InputNumber } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import Button01 from '../../../../../components/v2/button_01';
import Button02 from '../../../../../components/v2/button_02';
import LabeRequireForm from '../../../../../components/label-required-form';
import { getStockListById, getConvertUoM, getConvertUoMBigger } from '../../../../../controllers/po/po';
import _, { size } from 'lodash';
import { handleKeyDownDecimal2Fixed, handleKeyDownNumber } from '../../../../../component-function/fnc-number';
import { finddataItemmasterbyId } from '../../../../../controllers/quotation/quotation-api-new';
import CustomTableComponent from '../../../components/table';
import { cutStrNumberFormatter } from '../../../../../component-function/fnc-inputnumber-currency';
import { v4 as uuidv4 } from 'uuid';
import TextArea from 'antd/lib/input/TextArea';
import { Round } from '../../../../../component-function/lodash';
import { numberFormatter, numberParser } from '../../../../../component-function/fnc-inputnumber-currency';

const { Option } = Select;

const ListItemCreate = (props) => {
  const { data, visible, setVisible, form, selectItem, setSelectItem, initialCampaignList, defaultEditItem, setDefaultEditItem } = props;
  const { getFieldDecorator, validateFields, resetFields, getFieldValue, setFieldsValue } = form;
  const [stockList, setStockList] = useState([]);
  const [uomList, setUomList] = useState([]);
  const [dataItemMaster, setDataItemMaster] = useState([]);
  const [discountInfo, setDiscountInfo] = useState(0);
  const [totalInfo, setTotalInfo] = useState(0);
  const intl = useIntl();
  const qty = parseInt(getFieldValue('qty') || 0);
  const price = parseFloat(getFieldValue('price') || 0.0);
  const discount = parseInt(getFieldValue('discount') || 0);
  const amount = Round(qty * price)
  const inputNumberQtyRef = useRef();
  const inputNumberPriceRef = useRef();
  const inputNumberDescountRef = useRef();
  const inputNumberDescountPriceRef = useRef();

  console.log('amountxxxx', amount, qty, price)
  console.log("defaultEditItem", defaultEditItem)

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);

  useEffect(() => {
    const getStockList = async () => {
      const response = await getStockListById(_.get(data, 'itemMasterId'));
      const unitData = await getConvertUoMBigger(_.get(data, 'itemMasterId'));
      const payload = {
        search: '',
        searchBy: 'all',
        paging: page,
        rowsPerPages: pageSize,
      };

      const resDataItemMaster = await finddataItemmasterbyId(_.get(data, 'itemMasterId'), payload);
      setStockList(_.get(response, 'data.data.stockList'));
      setUomList(_.get(unitData, 'data.data'));
      setDataItemMaster(_.get(resDataItemMaster, 'data.[0]'));
    };
    if (data) {
      getStockList();
    }
  }, [data]);

  useEffect(() => {
    const getDefault = async () => {
      const response = await getStockListById(_.get(defaultEditItem, 'itemMasterId'));
      const unitData = await getConvertUoM(_.get(defaultEditItem, 'itemMasterId'));
      setStockList(_.get(response, 'data.data.stockList'));
      setUomList(_.get(unitData, 'data.data'));
    };

    if (_.get(defaultEditItem, 'code') === 'edit') {
      getDefault();
    }
  }, [defaultEditItem]);

  useEffect(() => {
    if (qty || price) {
      setFieldsValue({
        ['amount']: numberFormatter(amount),
      });
    }
  }, [qty, price]);


  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: 70,
      align: 'center',
    },
    {
      title: intl.formatMessage({ id: 'modalListColumnsWarehouseCode', defaultMessage: 'Warehouse Code' }),
      dataIndex: 'warehouseCode',
      key: 'warehouseCode',
      width: 160,
    },
    {
      title: intl.formatMessage({ id: 'modalListColumnsWarehouse', defaultMessage: 'Warehouse' }),
      dataIndex: 'warehouseName',
      key: 'warehouseName',
      width: 200,
    },
    {
      title: intl.formatMessage({ id: 'modalListColumnsQty', defaultMessage: 'Qty' }),
      dataIndex: 'qty',
      key: 'qty',
      render: (text, record, index) => renderProgress(record),
    },
    {
      title: intl.formatMessage({ id: 'modalListColumnsMinQty', defaultMessage: 'Min Qty' }),
      dataIndex: 'minQty',
      key: 'minQty',
      width: 100,
    },
    {
      title: intl.formatMessage({ id: 'modalListColumnsMaxQty', defaultMessage: 'Max Qty' }),
      dataIndex: 'maxQty',
      key: 'maxQty',
      width: 100,
    },
  ];

  const renderProgress = (record) => {
    let color = '';
    let percent = 0;

    if (record.qty === 0) {
      color = '#e73845';
      percent = 100;
    } else if (record.qty < record.minQty) {
      color = '#febb02';
      percent = (record.qty / record.maxQty) * 100;
    } else if (record.qty > record.minQty) {
      color = '#6490cf';
      percent = (record.qty / record.maxQty) * 100;
    }

    return (
      <div className="progress-item">
        <Progress className="bar-progress-custom" strokeColor={color} percent={percent} showInfo={false} />{' '}
        <span className="progress-qty-value">{record.qtyDisplay}</span>
      </div>
    );
  };

  const handleSave = () => {
    validateFields(async (err, values) => {
      if (err) {
        return;
      }
      const filterUoM = _.filter(uomList, (item) => {
        return item.uomId === _.get(values, 'unit');
      });

      const filterPromotion = _.filter(initialCampaignList, (item) => {
        return item.campaignId === _.get(values, 'promotion');
      });

      if (_.get(defaultEditItem, 'code') === 'edit') {
        console.log('Save Edit', defaultEditItem, values);
        const itemBody = {
          index: _.get(defaultEditItem, 'index'),
          itemMasterId: _.get(defaultEditItem, 'itemMasterId'),
          itemCode: _.get(defaultEditItem, 'itemCode'),
          itemName: _.get(defaultEditItem, 'itemName'),
          qty: parseFloat(_.get(values, 'qty')),
          count: parseFloat(_.get(values, 'qty')),
          uomId: _.get(values, 'unit'),
          uomName: _.get(filterUoM, '[0].name'),
          pricePerUnit: parseFloat(_.get(values, 'price')),
          promotionCampaignId: _.get(values, 'promotion'),
          promotionCampaign: _.get(filterPromotion, '[0].campaignName'),
          discountPercentage: parseFloat(_.get(values, 'discount') || 0),
          discountPrice: parseFloat(_.get(values, 'discountNumber') || 0),
          // discountPrice: parseFloat(discountInfo || 0),
          totalPrice: numberParser(_.get(values, 'total')),
          // totalPrice: parseFloat(_.replace(_.get(values, 'total', ''), /,/g, '')),
          // totalPrice: parseFloat(totalInfo),
          remark: _.get(values, 'remark') || '',
          instock: 0,
          credits: _.get(values, 'credits') || '',
          uuid: _.get(defaultEditItem, 'uuid'),
          categoryCode: 'product'
        };

        const updateSelectItem = [...selectItem];

        const filterSelectItemMaster = _.filter(updateSelectItem, (o) =>
          o.uuid !== itemBody.uuid &&
          o.itemMasterId === itemBody.itemMasterId &&
          o.uomId === itemBody.uomId &&
          o.pricePerUnit === itemBody.pricePerUnit &&
          o.discountPercentage === itemBody.discountPercentage &&
          _.toString(o.credits) === _.toString(itemBody.credits)
        ).map((obj) => {
          return {
            ...obj,
            totalPrice: parseFloat(obj.totalPrice) + parseFloat(itemBody.totalPrice),
            qty: _.toNumber(obj.qty) + _.toNumber(itemBody.qty),
            discountPrice: ((parseFloat(itemBody.pricePerUnit) * parseFloat(itemBody.discountPercentage)) / 100) * (_.toNumber(obj.qty) + _.toNumber(itemBody.qty)),
          }
        })

        // console.log('filterSelectItemMaster edit', filterSelectItemMaster, itemBody, selectItem, defaultEditItem)

        if (_.size(filterSelectItemMaster) > 0) {
          const mapItem = _.map(updateSelectItem, (o) => {
            if (o.uuid === _.get(filterSelectItemMaster, '[0].uuid')) {
              return { ..._.get(filterSelectItemMaster, '[0]') }
            } else {
              return { ...o }
            }
          }).filter((obj) => obj.uuid !== itemBody.uuid)

          // console.log('mapItem', mapItem)

          // updateSelectItem = mapItem
          setSelectItem(mapItem);

        } else {
          // updateSelectItem[_.get(defaultEditItem, 'index') - 1] = itemBody;
          // setSelectItem(updateSelectItem);
          const filterSelect = _.filter(selectItem, (item) => {
            return item.index !== _.get(defaultEditItem, 'index');
          });
          setSelectItem(filterSelect.concat([itemBody]));
        }

        // console.log('Save Edit Item', updateSelectItem);

      } else {
        const itemBody = {
          index: _.size(selectItem) + 1,
          itemMasterId: _.get(data, 'itemMasterId'),
          itemCode: _.get(data, 'itemCode'),
          itemName: _.get(data, 'itemName'),
          qty: parseFloat(_.get(values, 'qty')),
          count: parseFloat(_.get(values, 'qty')),
          uomId: _.get(values, 'unit'),
          uomName: _.get(filterUoM, '[0].name'),
          pricePerUnit: parseFloat(_.get(values, 'price')),
          promotionCampaignId: _.get(values, 'promotion'),
          promotionCampaign: _.get(filterPromotion, '[0].campaignName'),
          discountPercentage: parseFloat(_.get(values, 'discount')) || 0,
          discountPrice: parseFloat(_.get(values, 'discountNumber')),
          // discountPrice: discountInfo,
          // totalPrice: parseFloat(_.get(values, 'total')),
          totalPrice: parseFloat(_.replace(_.get(values, 'total', ''), /,/g, '')),
          // totalPrice: totalInfo,
          remark: _.get(values, 'remark') || '',
          instock: 0,
          credits: _.get(values, 'credits') || '',
          uuid: uuidv4(),
          categoryCode: 'product'
        };

        console.log('handleSavegggg', values, itemBody, parseFloat(_.get(values, 'total')), parseFloat(_.replace(_.get(values, 'total', ''), /,/g, '')), _.replace(_.get(values, 'total'), /,/g, ''), _.get(values, 'total'))

        const filterSelectItemMaster = _.filter(selectItem, (o) =>
          o.itemMasterId === itemBody.itemMasterId &&
          o.uomId === itemBody.uomId &&
          o.pricePerUnit === itemBody.pricePerUnit &&
          o.discountPercentage === itemBody.discountPercentage &&
          _.toString(o.credits) === _.toString(itemBody.credits)
        ).map((obj) => {
          return {
            ...obj,
            totalPrice: parseFloat(obj.totalPrice) + parseFloat(itemBody.totalPrice),
            qty: _.toNumber(obj.qty) + _.toNumber(itemBody.qty),
            discountPrice: ((parseFloat(itemBody.pricePerUnit) * parseFloat(itemBody.discountPercentage)) / 100) * (_.toNumber(obj.qty) + _.toNumber(itemBody.qty)),
          }
        })
        // console.log('filterSelectItemMaster add', filterSelectItemMaster, itemBody, selectItem)

        if (_.size(filterSelectItemMaster) > 0) {
          // setSelectItem([...selectItem, itemBody]);
          setSelectItem(_.map(selectItem, (o) => {
            if (_.get(filterSelectItemMaster, '[0].uuid') === o.uuid) {
              return { ..._.get(filterSelectItemMaster, '[0]') }
            } else {
              return { ...o }
            }
          }))
        } else {
          setSelectItem([...selectItem, itemBody]);
        }

        // setSelectItem([...selectItem, itemBody]);

        // console.log('itemBody', itemBody);
      }
      // console.log('defaultEditItem', defaultEditItem);
      setVisible(false);
      setDefaultEditItem({});
      resetFields();
    });
  };

  const handleCancel = () => {
    setVisible(false);
    setDefaultEditItem({});
    resetFields();
  };

  const handleChangeQTY = (e) => {
    const getPrice = parseFloat(getFieldValue('price'))
    const calAmount = Round(parseFloat(e) * getPrice)
    if (e && e > 0) {
      setFieldsValue({
        ['discount']: 0.00,
        ['discountNumber']: 0.00,
        ['total']: numberFormatter(calAmount),
      });
    } else {
      setFieldsValue({
        ['price']: 0.0,
        ['discount']: 0.0,
        ['discountNumber']: 0.0,
        ['total']: 0.0,
      });
    }
  };

  const handleChangeDiscountNumber = (e) => {
    const calPercent = Round((parseFloat(e) / amount) * 100)
    const calTotalPrice = amount - parseFloat(e)
    if (e && e > 0) {
      setFieldsValue({
        ['discount']: calPercent.toFixed(2),
        ['total']: numberFormatter(calTotalPrice),
      });
    } else {
      setFieldsValue({
        ['discount']: 0.0,
        ['discountNumber']: 0.0,
        ['total']: numberFormatter(amount),
      });
    }
  };

  const handleChangeDiscount = (e) => {
    const calPercent = Round((amount * parseFloat(e)) / 100)
    const calTotalPrice = amount - calPercent
    if (e && e > 0) {
      setFieldsValue({
        ['discountNumber']: calPercent.toFixed(2),
        ['total']: numberFormatter(calTotalPrice),
      });
    } else {
      setFieldsValue({
        ['discount']: 0.0,
        ['discountNumber']: 0.0,
        ['total']: numberFormatter(amount),
      });
    }
  };


  const handleChangePrice = (e) => {
    const amount = Round(qty * parseFloat(e))
    const calTotalPercent = Round((amount * discount) / 100)
    const calTotalPrice = amount - calTotalPercent

    if (e && e > 0) {
      setFieldsValue({
        ['discountNumber']: calTotalPercent.toFixed(2),
        ['total']: numberFormatter(calTotalPrice),
      });
    } else {
      setFieldsValue({
        ['discount']: 0.0,
        ['discountNumber']: 0.0,
        ['total']: 0.0,
      });
    }
  };

  return (
    <Modal
      title={_.get(defaultEditItem, 'code') === 'edit' ? intl.formatMessage({ id: `modalListEditItemTitle`, defaultMessage: 'Edit Item' }) : intl.formatMessage({ id: `modalListItemTitle`, defaultMessage: 'Add Item' })}
      centered={true}
      width={900}
      onCancel={handleCancel}
      visible={visible}
      bodyStyle={{ padding: 'unset', height: '550px', overflowY: 'auto' }}
      zIndex={1001}
      footer={[
        <Button02 key="back" btnsize="wd_df" onClick={handleCancel} >
          <FormattedMessage id="userDetailModalBtnCancel" defaultMessage="Cancel" />
        </Button02>,
        <Button01 key="submit" type="primary" btnsize="wd_df" onClick={() => handleSave()} style={{ margin: 'unset' }} >
          <FormattedMessage id="userDetailModalBtnSave" defaultMessage="Save" />
        </Button01>,
      ]}
    >
      <div style={{ padding: '16px' }}>
        <div style={{ marginBottom: '28px' }}>
          <div style={{ marginBottom: '8px', color: '#1D3557', fontSize: '14px', fontWeight: 'bold' }}>
            <LabeRequireForm text={intl.formatMessage({ id: 'modalListItemFormTitleItem', defaultMessage: 'Item' })} req={false} />
          </div>
          <div style={{ color: '#050505', fontSize: '13px' }}>
            {_.get(defaultEditItem, 'itemCode')
              ? _.get(defaultEditItem, 'itemCode') + ' ' + _.get(defaultEditItem, 'itemName')
              : _.get(data, 'itemCode') + ' ' + _.get(data, 'itemName')}
          </div>
        </div>
        <Form form={form} layout="vertical" className="po-form">
          <Row gutter={[16]}>
            <Col span={8}>
              <Form.Item
                label={<LabeRequireForm text={intl.formatMessage({ id: 'modalListItemFormTitleQty', defaultMessage: 'Qty' })} req={true} />}
              >
                {getFieldDecorator('qty', {
                  initialValue: _.get(defaultEditItem, 'code') === 'edit' ? _.get(defaultEditItem, 'qty') || '' : undefined,
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'modalListItemFormValidateQty', defaultMessage: 'Please Enter Qty' }),
                    },
                  ],
                })(
                  <Input
                    placeholder={intl.formatMessage({ id: 'modalListItemFormPlaceholderQty', defaultMessage: 'Enter Qty' })}
                    maxLength={100}
                    onKeyDown={handleKeyDownNumber}
                    onChange={(e) => handleChangeQTY(e.target.value)}
                    ref={inputNumberQtyRef}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={
                  <LabeRequireForm text={intl.formatMessage({ id: 'modalListItemFormTitleUnit', defaultMessage: 'Unit' })} req={true} />
                }
              >
                {getFieldDecorator('unit', {
                  initialValue: _.get(defaultEditItem, 'uomId') || '',
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'modalListItemFormValidateUnit', defaultMessage: 'Please Select Unit' }),
                    },
                  ],
                })(
                  <Select
                    placeholder={intl.formatMessage({ id: 'modalListItemFormPlaceholderUnit', defaultMessage: 'Select Unit' })}
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    showSearch
                  >
                    {_.map(uomList, (item) => (
                      <Option value={item.uomId}>{item.name}</Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={
                  <LabeRequireForm
                    text={intl.formatMessage({ id: 'modalListItemFormTitlePrice', defaultMessage: 'Price/Unit' })}
                    req={true}
                  />
                }
              >
                {getFieldDecorator('price', {
                  initialValue: _.get(defaultEditItem, 'pricePerUnit') || '',
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'modalListItemFormValidatePrice', defaultMessage: 'Please Enter Price/Unit' }),
                    },
                  ],
                })(
                  <Input
                    placeholder={intl.formatMessage({ id: 'modalListItemFormPlaceholderPrice', defaultMessage: 'Enter Price/Unit' })}
                    maxLength={100}
                    onKeyDown={handleKeyDownDecimal2Fixed}
                    suffix="THB"
                    onChange={(e) => handleChangePrice(e.target.value)}
                    ref={inputNumberPriceRef}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16]}>
            <Col span={8}>
              {/* <Form.Item
                label={
                  <LabeRequireForm
                    text={intl.formatMessage({ id: 'modalListItemFormTitlePromotion', defaultMessage: 'Promotion or Campaign' })}
                    req={false}
                  />
                }
              >
                {getFieldDecorator(
                  'promotion',
                  {}
                )(
                  <Select
                    placeholder={intl.formatMessage({
                      id: 'modalListItemFormPlaceholderPromotion',
                      defaultMessage: 'Enter Promotion or Campaign',
                    })}
                    showSearch
                    filterOption={(input, option) =>
                      option.props.children
                        .toString()
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {initialCampaignList &&
                      initialCampaignList.map((o) => (
                        <Option key={o.campaignId} value={o.campaignId}>
                          {o.campaignName}
                        </Option>
                      ))}
                  </Select>
                )}
              </Form.Item> */}

              <Form.Item
                label={
                  <LabeRequireForm text={intl.formatMessage({ id: 'quotationLblColumnsAmount', defaultMessage: 'Amount' })} req={false} />
                }
              >
                {getFieldDecorator('amount', {
                  initialValue: _.get(defaultEditItem, 'pricePerUnit', 0) * _.get(data, 'qty', 0),
                })(
                  <Input
                    placeholder={intl.formatMessage({ id: 'modalTravelExpenseAddAmountPlaceholeder', defaultMessage: 'Enter Amount' })}
                    maxLength={100}
                    onKeyDown={handleKeyDownDecimal2Fixed}
                    suffix="THB"
                    readOnly={true}
                    disabled={true}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item
                label={
                  <LabeRequireForm
                    text={intl.formatMessage({ id: 'modalListItemFormTitleDiscount', defaultMessage: 'Discount' })}
                    req={false}
                  />
                }
              >
                {getFieldDecorator('discount', {
                  initialValue: _.toNumber(_.get(defaultEditItem, 'discountPercentage')) || 0,
                  // initialValue: '0',
                })(
                  <Input
                    maxLength={100}
                    onKeyDown={handleKeyDownDecimal2Fixed}
                    onChange={(e) => handleChangeDiscount(e.target.value)}
                    suffix="%"
                    ref={inputNumberDescountRef}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label={<LabeRequireForm text="" req={false} />}>
                {getFieldDecorator('discountNumber', {
                  initialValue: _.get(defaultEditItem, 'discountPrice') ? parseFloat(_.get(defaultEditItem, 'discountPrice')).toFixed(2) || 0 : 0.00,
                  // initialValue: '0.00',
                })(
                  <Input
                    maxLength={100}
                    onKeyDown={handleKeyDownDecimal2Fixed}
                    suffix="THB"
                    onChange={(e) => handleChangeDiscountNumber(e.target.value)}
                    ref={inputNumberDescountPriceRef}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={
                  <LabeRequireForm text={intl.formatMessage({ id: 'modalListItemFormTitleTotal', defaultMessage: 'Total' })} req={false} />
                }
              >
                {getFieldDecorator('total', {
                  initialValue: _.get(defaultEditItem, 'totalPrice') ? parseFloat(_.get(defaultEditItem, 'totalPrice')).toFixed(2) || 0 : 0.00,
                  // initialValue: '0.00',
                })(
                  <Input
                    placeholder={intl.formatMessage({ id: 'modalListItemFormPlaceholderTotal', defaultMessage: 'Enter Total' })}
                    maxLength={100}
                    onKeyDown={handleKeyDownDecimal2Fixed}
                    suffix="THB"
                    readOnly={true}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16]}>
            <Col span={8}>
              <Form.Item
                label={
                  <LabeRequireForm
                    text={intl.formatMessage({ id: 'modalListItemFormTitleCredits', defaultMessage: 'Credit' })}
                    req={true}
                  />
                }
              >
                {getFieldDecorator('credits', {
                  initialValue: _.toNumber(_.get(defaultEditItem, 'credits')) || _.toNumber(_.get(dataItemMaster, 'creditDay')) || '',
                  // initialValue: _.toString(_.get(dataItemMaster, 'creditDay')) || '',
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'modalListItemFormValidateCredit', defaultMessage: 'Please Enter Credit' }),
                    },
                  ],
                })(
                  <InputNumber
                    // placeholder={intl.formatMessage({ id: 'modalAssetPriceBahtPlaceholder', defaultMessage: 'Enter Price' })}
                    suffix={intl.formatMessage({ id: 'creditsLblDay', defaultMessage: 'Days' })}
                    className="asset-item-inputNumber"
                    min={1}
                    max={9999999999}
                    type="number"
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item
                label={
                  <LabeRequireForm
                    text={intl.formatMessage({ id: 'modalListItemFormTitleRemark', defaultMessage: 'Remark' })}
                    req={false}
                  />
                }
              >
                {getFieldDecorator('remark', { initialValue: _.get(defaultEditItem, 'remark') || '' })(
                  <TextArea
                    autoSize={{ minRows: 1, maxRows: 6 }}
                    maxLength={500}
                  />
                  // <Input 
                  //   maxLength={100} 
                  // />
                )}
              </Form.Item>
            </Col>
            <Col span={24} style={{ textAlign: 'right' }}>
              <LabeRequireForm text={`${_.size(getFieldValue('remark'))} / 500`} />
            </Col>
          </Row>
        </Form>
        <div style={{ marginTop: '32px' }}>
          <div style={{ fontWeight: 'bold', color: '#1D3557' }}>
            <FormattedMessage id="modalListItemFilterTextStockBalance" defaultMessage="Stock Balance" />
          </div>
          <Divider type="horizontal" />
          <div>
            {/* <Table columns={columns} dataSource={stockList} scroll={{ y: 120 }} pagination={false} /> */}
            <CustomTableComponent
              columns={columns}
              dataSource={stockList}
              scroll={{ y: 85 }}
              role={false}
              onRow={false}
              total={_.size(stockList)}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

const ModalCreateListItem = Form.create({
  name: 'assignTeam-form',
  mapPropsToFields() { },
})(ListItemCreate);

export default ModalCreateListItem;
