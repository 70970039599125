import React, { useState, useEffect } from 'react'
import { useIntl, FormattedMessage } from 'react-intl';
import { 
  Button,
  Row,
  Input,
  Col,
  DatePicker,
  Select,
  List,
  Tree,
  Icon,
  Dropdown,
  Checkbox,
  ConfigProvider,
  Menu,
 } from 'antd';
import { useAppContext } from '../../includes/indexProvider';
import Button01 from '../v2/button_01';
import moment from 'moment';
import enUS from 'antd/es/locale/en_US';
import thTH from 'antd/es/locale/th_TH';
import styles from './css/action-report.css';

const { Option } = Select;
const { RangePicker } = DatePicker;

const ActionReportV7 = (props) => {
  const { value, fnc } = props;
  const intl = useIntl();
  const app = useAppContext();
  const langValue = app.state.langValue;
  const dateFormat = 'ddd, MMM DD YYYY';
  const [locale, setLocale] = useState(enUS);

  useEffect(() => {
    changeLocale();
  }, []);

  const changeLocale = () => {
    let codeLang = langValue ? langValue : 'EN';
    let changeLang = codeLang === 'EN' ? enUS : thTH;

    setLocale(changeLang);
    if (!changeLang) {
      moment.locale('en');
    } else {
      moment.locale('th-th');
    }
  };

  const menuStatus = () => {
    return (
      <Menu 
        className="action-report-menu-status"
        onClick={fnc.onSelectMenuStatus}
      >
        {value.statusModule && value.statusModule.map((item, index) => (
          <Menu.Item 
            className="action-menu-status-item"
            key={item.moduleCode} 
          >
            {item.moduleName}
          </Menu.Item>
        ))}
      </Menu>
    );
  }

  return (
    <div className="action-report">
      <Row className="action-report-row">
        <Col span={12} className="action-report-col">
          <div className="action-report-div-left">
            <Input
              className="action-report-input-7"
              placeholder={intl.formatMessage({ id: 'hintSearch', defaultMessage: 'Search' })}
              allowClear
              prefix={<Icon style={{ color: "rgba(0,0,0,.25)" }} type="search" />}
              onChange={(e) => fnc.onChangeSearch(e.target.value)}
              value={value.fieldChang}
              autoComplete="off" 
            />
          </div>
        </Col>
        <Col span={12} className="action-report-col">
          <div className="action-report-div-right">
            <ConfigProvider locale={locale}>
              <RangePicker
                className="action-report-rangePicker"
                defaultValue={value.rangePickerValue}
                format="DD/MM/YYYY"
                onChange={fnc.onSelectRangePicker}
                allowClear={false}
              />
            </ConfigProvider>

            {/* <Dropdown.Button 
              className='action-report-dropdown-button'
              overlayClassName="action-report-dropdown-overlay"
              overlay={menuStatus} 
              icon={<Icon type="down" />}
              onClick={handleClickModuleAction}
            >
              { value.selectedStatus ? `${value.selectedStatus.children}` : ''}
            </Dropdown.Button> */}

            <Dropdown 
              overlayClassName="action-report-dropdown-overlay"
              placement="bottomRight"
              overlay={menuStatus} 
              trigger={['click']}
            >
              <Button01 
                type="primary" 
                btnsize="wd_df" 
                fontSize="sm"
                stylemargin="mg_cs3"
              >
                <FormattedMessage id="btnAddNew" defaultMessage="Add New" />
              </Button01>
            </Dropdown>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default ActionReportV7;
