import React, { useState, useEffect, useContext } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { MonitorContext } from '../monitor-context';
import MapTracking from './map-speed-tracking';
import httpClient from '../../../components/axiosClient';
import GraphSpeedTracking from './graph-speed-tracking';
import moment from 'moment';
import _, { debounce } from 'lodash';
import { Icon, Card, } from 'antd';
import stylesMapSpeedTracking from './css/index.css';

const MapSpeedTracking = (props) => {
  const { } = props;
  const { stateMain, setStateMain } = useContext(MonitorContext);
  const { LangCode } = stateMain
  const typeApi = LangCode && LangCode === `WorkForce` ? `menuType=workforce` : ``
  let mainDate = _.get(stateMain, 'mainDate');
  const comId = localStorage.getItem('comId');

  const [latLngResource, setlatLngResource] = useState({});

  useEffect(() => {
    if (stateMain.resourceId) {
      getTaskStatus();
    }

    const interval = setInterval(() => {
      getTaskStatus();
    }, 180000);
    return () => clearInterval(interval);
  }, [stateMain.resourceId]);

  const getTaskStatus = async () => {
    try {
      const response = await httpClient.get(
        `/v3/task/manager/company/${comId}/my/${stateMain.resourceId}/task/group?startDate=${mainDate}&endDate=${mainDate}&orgId=${stateMain.mainOrgIdArrString}&menuType=workforce`,
      );

      if (response.status === 200) {
        setStateMain.setTaskStatusData(_.toArray(response.data));
        const arrayChunk = _.chunk(_.toArray(response.data), 3);

        let arrTask = [];
        _.map(arrayChunk[0], el => { arrTask.push(...el.tasks) });

        const colorData = _.filter(stateMain.mainMapViewResourceData, (el) => { return el.memComId === stateMain.resourceId }).map((e) => { return e.statusOnGoingColor })
        const colorStatus = colorData && colorData.toString();

        setStateMain.setMainResSpeedTrack({
          resId: stateMain.resourceId,
          openSpeedTrack: true,
          dataSpeedTrack: { ...arrayChunk[1][0] },
          dataTask: arrTask,
          statusColor: colorStatus
        });
      }
    } catch (error) {
      return
    }
  }

  return (
    <div className="map-speed-tracking">
      <div className="map-speed-tracking-container">
        <div className="map-speed-tracking-mapTracking">
          <MapTracking 
            value={{
              latLngResource
            }} 
            setState={{
              setlatLngResource
            }}
          />
        </div>
        <div className="map-speed-tracking-graphSpeedTracking">
          <GraphSpeedTracking 
            value={{
              latLngResource
            }} 
            setState={{
              setlatLngResource
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default MapSpeedTracking;
