import React, { useContext, useState, useRef } from 'react'
import { Table, Button, Popover } from 'antd'
import { AllowanceProvider } from '../context'
import _ from 'lodash'
import AllowanceAction from './action'
import { FormattedMessage } from 'react-intl'
import { PageSettings } from '../../../config/page-settings'

const AllowanceTable = () => {
  const { state, fnc } = useContext(AllowanceProvider)
  const { newDataColumns, datasource, total, tableChange, loading, tabValue } = state
  const { handleOpenModal, handleTableChange } = fnc
  const ref = useRef();
  const [visiblePopoverTable, setVisiblePopoverTable] = useState();
  const [recordPopover, setRecordPopover] = useState();
  const [offsetPopover, setOffsetPopover] = useState([0, 0]);
  const app = useContext(PageSettings);
  const { checkPermissionAction } = app;
  const permissonView = checkPermissionAction('P80PG1C1', 'P80PG1C1A7');

  const showTotal = () => {
    return (
      <span style={{ fontSize: '13px' }}>
        <FormattedMessage id="lblRowPerPage" defaultMessage="Rows per page: " />
      </span>
    );
  };

  const fncActionTable = (record, offsetPopover, visiblePopoverTable, ref) => {
    return (
      <div>
        <Popover
          placement="right"
          trigger="hover"
          align={{ offset: offsetPopover }}
          visible={visiblePopoverTable}
          zIndex={10000}
          content={
            <div style={{ display: 'grid' }}>
              <div>
                <Button style={{ width: 100 }} type="link" ghost onClick={() => handleOpenModal(record)}>
                  {tabValue === 'pending' ? <FormattedMessage id="btnVerify" defaultMessage="Verify" /> : <FormattedMessage id="btnView" defaultMessage="View" />}
                </Button>
              </div>
            </div>
          }
        >
          <div style={{ width: 0, height: 0 }} ref={ref}></div>
        </Popover>
      </div>
    );
  };

  const handleMouseLeave = () => {
    setVisiblePopoverTable(false);
    setRecordPopover();
    setOffsetPopover([0, 0]);
  };

  return (
    <div style={{ height: 'calc(100vh - 190px)', overflowY: 'auto' }}>
      <AllowanceAction />
      <div style={{ padding: '12px 24px' }} onMouseLeave={handleMouseLeave}>
        <Table
          loading={loading}
          dataSource={datasource}
          columns={newDataColumns}
          scroll={{ x: _.sumBy(newDataColumns, 'width') }}
          onChange={handleTableChange}
          pagination={{
            total: total,
            current: tableChange.page,
            showTotal: showTotal,
            defaultCurrent: 1,
            pageSizeOptions: ['10', '20', '30', '40', '50'],
            showSizeChanger: true,
            locale: { items_per_page: '' },
          }}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                if (!event.target.href && permissonView) {
                  const { x, y } = ref.current.getBoundingClientRect();
                  setVisiblePopoverTable(true);
                  setOffsetPopover([event.pageX - x, event.pageY - y]);
                  setRecordPopover(record);
                }
              },
            };
          }}
        />
        {fncActionTable(recordPopover, offsetPopover, visiblePopoverTable, ref)}
        {total > 0 ? (
          <div className="total-items-member">
            <span style={{ fontSize: '13px' }}>
              <FormattedMessage id="lblTotal" defaultMessage="Total" />
              {` ${total || 0} `}
              <FormattedMessage id="lblitems" defaultMessage="items" />
            </span>
          </div>
        ) : null}
      </div>
    </div>

  )
}

export default AllowanceTable
