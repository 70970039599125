import httpClient from '../../../components/axiosClient';

const getReportReceiveItemFormPo = async (body) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v1/report/manager/company/${comCode}/po-receive-report`, body)
    if (response.status === 200) {

      return response
    } else {
      return []
    }
  } catch (error) {
  }
}

const exportReportReceiveItemFormPo = async (body) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v1/report/manager/company/${comCode}/po-receive-report-excel`, body)
    if (response.status === 200) {

      return response
    } else {
      return []
    }
  } catch (error) {
  }
}

export { getReportReceiveItemFormPo, exportReportReceiveItemFormPo };