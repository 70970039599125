import { Col, DatePicker, Divider, Form, Row, Select, Input, Icon, Badge } from 'antd';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import LabeRequireForm  from '../../../../../components/label-required-form';
// import ModalCustomerQuotationForm from '....//select-customer/customer-modal';
import _ from 'lodash';
import moment from 'moment';

const { Option } = Select;
const { Search } = Input;
const dateFormat = 'DD/MM/YYYY';

const CustomerFormView = ({ dataForm, form, setDataForm, initialMemberList, handleOpen }) => {
  const intl = useIntl();
  const { getFieldDecorator } = form;
  const memComId = localStorage.getItem('memComId');

  const MemberOption = (o, i) => {
    return (
      <Option key={i} value={o.memComId}>
        {o.fullname}
      </Option>
    );
  };

  return (
    <Form name="customer" colon={false}>
      {/* <ModalCustomerQuotationForm visible={isOpen} onCancel={handleCancel} onOk={handleSave} setDataForm={setDataForm} dataForm={dataForm} /> */}

      <Row gutter={[24]}>
        <Col span={8}>
          <Form.Item
            label={<LabeRequireForm bold={true} text={intl.formatMessage({ id: 'quotationLabelCustomer', defaultMessage: 'Customer' })} req={false} />}
            className="form-line-height"
          >
            {/* {_.get(dataForm, 'customerName') || '-'} */}

            <div className="text-group-customer">
              <div className="text-location-short">
                <span style={{ color: '#050505' }}>{_.get(dataForm, 'customerName')}</span>
              </div>
            </div>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={
              <LabeRequireForm bold={true}
                text={intl.formatMessage({ id: 'ARLabelCustomerAddress', defaultMessage: 'Customer Address' })}
                req={false}
              />
            }
            className="form-line-height"
          >
            <div className="text-group-customer">
              <div className="text-location-short" style={{ textOverflow: 'unset' }}>
                <Icon
                  type="environment"
                  style={{
                    fontSize: '12px',
                    color: '#1D3557',
                    paddingLeft: '0px',
                    paddingRight: '4px',
                    cursor: 'pointer',
                  }}
                />
                <span>
                  {_.get(dataForm, 'customerAddressBookName')
                    ? _.get(dataForm, 'customerAddressBookName')
                    : _.get(dataForm, 'customerAddress') || '-'}
                </span>
              </div>
              <div className="text-short-placeholer">
                <span style={{ color: '#898989' }}>
                  {intl.formatMessage({ id: 'quotationLabelTaxNo', defaultMessage: 'Tax No.' })} {_.get(dataForm, 'customerTaxNo') || '-'}
                </span>
              </div>
            </div>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            label={
              <LabeRequireForm bold={true} text={intl.formatMessage({ id: 'quotationLabelCustomerContact', defaultMessage: 'Contact' })} req={false} />
            }
            className="form-line-height"
          >
            <div className="text-group-customer">
              <div className="text-location-short">
                <span style={{ color: '#050505' }}>
                  {_.get(dataForm, 'customerContactName') ? _.get(dataForm, 'customerContactName') : _.get(dataForm, 'customerContact')}
                </span>
              </div>
              <div className="text-location-short">
                <span style={{ color: '#050505' }}>
                  {_.get(dataForm, 'customerContactPhone') ? _.get(dataForm, 'customerContactPhone') : _.get(dataForm, 'customerPhone')},{' '}
                  {_.get(dataForm, 'customerContactEmail') ? _.get(dataForm, 'customerContactEmail') : _.get(dataForm, 'customerEmail')}
                </span>
              </div>
            </div>
          </Form.Item>
        </Col>
      </Row>
      <Divider type="horizontal" style={{ marginBottom: '10px' }} />
      <Row gutter={[24]}>
        <Col span={8}>
          <Form.Item
            label={<LabeRequireForm bold={true} text={intl.formatMessage({ id: 'ARSaleOrderNo', defaultMessage: 'Sale Order No.' })} req={false} />}
          >
            {_.get(dataForm, 'saleOrderNo') || '-'}
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={<LabeRequireForm bold={true} text={intl.formatMessage({ id: 'ARSaleOrderDate', defaultMessage: 'Sale Order Date' })} req={false} />}
          >
            {_.get(dataForm, 'issueDate')
              ? moment(_.get(dataForm, 'issueDate')).format(dateFormat)
              : _.get(dataForm, 'saleOrderDateTxt') || '-'}
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={<LabeRequireForm bold={true} text={intl.formatMessage({ id: 'ARSOIssuedby', defaultMessage: 'SO Issued by' })} req={false} />}
          >
            {_.get(dataForm, 'saleOrderIssueBy') ? _.get(dataForm, 'saleOrderIssueBy') : _.get(dataForm, 'issuedName') || '-'}
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[24]}>
        <Col span={8}>
          <Form.Item
            label={<LabeRequireForm bold={true} text={intl.formatMessage({ id: 'quotationLabelIssueBy', defaultMessage: 'Issue by' })} req={false} />}
          >
            {_.get(dataForm, 'code') === 'view'
              ? getFieldDecorator('by', {
                  initialValue: _.get(dataForm, 'issueBy') || memComId,
                })(
                  <Select
                    placeholder={intl.formatMessage({ id: 'quotationHintIssueBy', defaultMessage: 'Select Issue by' })}
                    showSearch
                    filterOption={(input, option) =>
                      option.props.children
                        .toString()
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {initialMemberList && initialMemberList.map((item, index) => MemberOption(item, index))}
                  </Select>
                )
              : _.get(dataForm, 'issueBy') || '-'}
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={
              <LabeRequireForm bold={true} text={intl.formatMessage({ id: 'quotationLabelIssueDate', defaultMessage: 'Issue Date' })} req={false} />
            }
          >
            {_.get(dataForm, 'code') === 'view'
              ? getFieldDecorator('date', {
                  initialValue: moment(),
                  // initialValue: _.get(dataForm, 'issueDate') ? moment(_.get(dataForm, 'issueDate'), 'YYYY-MM-DD') : undefined,
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'quotationWarnIssueDate', defaultMessage: 'please select Issue Date' }),
                    },
                  ],
                })(
                  <DatePicker
                    allowClear={false}
                    format={dateFormat}
                    style={{ width: '100%' }}
                    placeholder={intl.formatMessage({ id: 'ARHintIssueDate', defaultMessage: 'Select Issue Date' })}
                  />
                )
              : _.get(dataForm, 'issueDateTxt') || '-'}
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

CustomerFormView.defaultProps = {};

export default CustomerFormView;
