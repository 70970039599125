import React, { useState, useEffect, useContext, useRef } from 'react';
import { OrganizationContext } from './organization-context';
import Provider from './provider';
import { Spin } from 'antd';
// import FormDetail from './organizationDetail/index';
import FormDetail from './organizationDetail-New/index';
import FormContacts from './contacts/index';
// import FormApprover from './approver/index';
import FormApprover from './approver/approver';
import { PageSettings } from '../../../src/config/page-settings';
import _ from 'lodash';
import httpClient from '../../components/axiosClient';
import moment from 'moment';

const today = new Date();
const dateTimeApi = 'YYYY-MM-DD HH:mm';

export default (props) => {
  const comId = localStorage.getItem('comId');
  const app = Provider.useAppContext();
  const { stateMain, setStateMain } = useContext(OrganizationContext);
  const { newForm, handleDelete, sendDetailBtn, setSendDetailBtn } = props;

  const [teamTypeData, setTeamTypeData] = useState([]);
  const [orgDetail, setOrgDetail] = useState({});
  const [orgContacts, setOrgContacts] = useState([]);
  const [orgApprovers, setOrgApprovers] = useState([]);
  const [requestType, setRequestType] = useState([]);
  const [loading, setLoading] = useState(false);
  const [trigger, setTrigger] = useState(false);

  const [refreshSaveFrom, setRefreshSaveFrom] = useState(false);

  useEffect(() => {
    if (stateMain.orgId) {
      getOrganization();
    }
  }, [stateMain.orgId, refreshSaveFrom, trigger]);

  useEffect(() => {
    if (stateMain.addNewOrganization === true) {
      setOrgDetail({});
    }
  }, [stateMain.addNewOrganization]);

  useEffect(() => {
    getRequestType();
  }, []);

  const getOrganization = async () => {
    setLoading(true);

    try {
      const response = await httpClient.get(`/v4/resource/manager/company/orgid/${stateMain.orgId}/details`);

      if (response.status === 200) {
        const teamDetails = _.isObject(response.data.organization) ? response.data.organization.teamDetails[0] : [];
        const teamType = _.isObject(response.data.organization) ? response.data.organization.teamType : [];
        const detail = {
          ...teamDetails,
          teamType: [...teamType],
        };
        const contacts = _.isObject(response.data.organization) ? response.data.organization.teamContact : [];
        const approvers = _.isObject(response.data.organization) ? response.data.organization.teamApprover : [];

        setOrgDetail(detail);
        setStateMain.setTeamName(_.get(detail, 'name'));
        setOrgContacts(contacts);
        setOrgApprovers(approvers);
        setTeamTypeData(response.data.organization.teamType);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      return;
    }
  };

  const getRequestType = async () => {
    try {
      const response = await httpClient.get(`/v2/request/type/${comId}`);
      if (response.status === 200) {
        await setRequestType([...response.data]);
      }
    } catch (error) {
      return;
    }
  };

  return (
    <PageSettings.Consumer>
      {({ checkPermissionAction }) => (
        <div className="organization-tabs-details">
          <Spin spinning={loading}>
            {checkPermissionAction('P3PG1C2', 'P3PG1C2A1') ? (
              <FormDetail
                orgId={stateMain.orgId}
                comId={stateMain.comId}
                parentId={stateMain.parentId}
                newForm={stateMain.addNewOrganization}
                data={orgDetail}
                valueDefault={stateMain.valueDefault}
                teamDetail={stateMain.teamTypeData}
                setRefreshSaveFrom={setRefreshSaveFrom}
                setLoading={setLoading}
                teamTypeData={teamTypeData}
                handleDelete={handleDelete}
                sendDetailBtn={sendDetailBtn}
                setSendDetailBtn={setSendDetailBtn}
              />
            ) : null}

            {checkPermissionAction('P3PG1C3', 'P3PG1C3A1') ? (
              !newForm ? (
                <FormContacts orgId={stateMain.orgId} data={orgContacts} setRefreshSaveFrom={setRefreshSaveFrom} setLoading={setLoading} />
              ) : (
                ''
              )
            ) : null}

            {checkPermissionAction('P3PG1C4', 'P3PG1C4A1') ? (
              !newForm ? (
                <FormApprover
                  orgId={stateMain.orgId}
                  dataSource={orgApprovers}
                  dataUsers={stateMain.users}
                  requestType={requestType}
                  setRefreshSaveFrom={setRefreshSaveFrom}
                  setLoading={setLoading}
                  setTriggerApprover={setStateMain.setTriggerApprover}
                  setCheckApprover={setStateMain.setCheckApprover}
                  setTrigger={setTrigger}
                />
              ) : (
                ''
              )
            ) : null}
          </Spin>
        </div>
      )}
    </PageSettings.Consumer>
  );
};
