import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLayer, Arrow } from 'react-laag';
import Task from '../map/popover-task';

const InfoBox = styled.div`
  box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  z-index: 1000;
`;

export default (props) => {
  const {
    task,
    resource,
    setStateMain,
    stateMain,
    intl,
    setVisibleTaskDetailModal,
    setMainTaskId,
    setMainTaskIdAssign,
    setVisibleAssignee,
    LangCode,
  } = props;
  const [isOpen, setOpen] = useState(false);

  const { triggerProps, layerProps, arrowProps, renderLayer } = useLayer({
    isOpen,
    triggerOffset: 10,
    auto: true,
    overflowContainer: false,
    onOutsideClick: () => setOpen(false),
  });

  return (
    <div>
      <img
        {...triggerProps}
        onClick={() => setOpen((prev) => !prev)}
        src={task.pathTaskStatus}
        style={{ zIndex: -1, width: '50px', height: '50px' }}
        key={task.taskId}
      />
      {isOpen &&
        renderLayer(
          <InfoBox {...layerProps}>
            <Task
              key={task.taskId}
              task={task}
              resource={resource}
              setVisibleTaskDetailModal={setVisibleTaskDetailModal}
              setMainTaskId={setMainTaskId}
              setMainTaskIdAssign={setMainTaskIdAssign}
              setVisibleAssignee={setVisibleAssignee}
              // setStateMain={setStateMain}
              // stateMain={stateMain}
              intl={intl}
              LangCode={LangCode}
            />
            <Arrow {...arrowProps} />
          </InfoBox>
        )}
    </div>
  );
};
