import React, { useEffect, useState, useContext } from 'react';
import { Modal, Form, Input, Col, Row, Button, Icon, Dropdown, TimePicker, Select, Popover, Avatar, List } from 'antd';
import * as moment from 'moment';
import '../css/shift.css';
import styled from 'styled-components';
import Button01 from '../../../components/v2/button_01';
import Button02 from '../../../components/v2/button_02';
import { ShiftSettings } from '../config/shift-type-settings';
import _ from 'lodash';

const colorData = [
  {
    id: 1,
    color: '#87CEFA',
  },
  {
    id: 2,
    color: '#FBA8C9',
  },
  {
    id: 3,
    color: '#E5E5E5',
  },
  {
    id: 4,
    color: '#c8a08f',
  },
  {
    id: 5,
    color: '#30076f',
  },
  {
    id: 6,
    color: '#fc6104',
  },
  {
    id: 7,
    color: '#000',
  },
  {
    id: 8,
    color: '#FBA8C9',
  },
  {
    id: 9,
    color: '#87CEFA',
  },
  {
    id: 10,
    color: '#c8a08f',
  },
  {
    id: 11,
    color: '#30076f',
  },
  {
    id: 12,
    color: '#fc6104',
  },
  {
    id: 13,
    color: '#c8a08f',
  },
  {
    id: 14,
    color: '#30076f',
  },
  {
    id: 15,
    color: '#fc6104',
  },
  {
    id: 16,
    color: '#30076f',
  },
  {
    id: 17,
    color: '#fc6104',
  },
  {
    id: 18,
    color: '#c8a08f',
  },
  {
    id: 19,
    color: '#30076f',
  },
  {
    id: 20,
    color: '#fc6104',
  },
];

const iconAvatar = [
  { id: 1, icon: 'user' },
  { id: 2, icon: 'android' },
  { id: 3, icon: 'apple' },
  { id: 4, icon: 'ie' },
  { id: 5, icon: 'instagram' },
  { id: 6, icon: 'slack' },
];

const ModalFormComponent = ({ visible, onCancel, onCreate, form }) => {
  const { state, fnc } = useContext(ShiftSettings);

  const { getFieldDecorator, setFieldsValue } = form;
  const format = 'h:mm A';
  const { Option } = Select;

  const [closeTimeA, setCloseTimeA] = useState(false);
  const [closeTimeB, setCloseTimeB] = useState(false);

  const [checkStart, setCheckStart] = useState();
  const [checkEnd, setCheckEnd] = useState();
  const [disable, setDisable] = useState(false);
  const [resetBool, setResetBool] = useState();

  // const [idItem, setIdItem] = useState();
  const [colorItem, setColorItem] = useState();
  const [iconItem, setIconItem] = useState();
  const [valueColor, setValueColor] = useState();
  const [valueIcon, setValueIcon] = useState();

  const [timeAll, setTimeAll] = useState();
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [idTime, setIdTime] = useState();

  //const [timeArr, setTimeArr] = useState([{}]);

  const LabelRequire = styled.label`
    color: #ff1010;
  `;

  useEffect(() => {
    if (checkStart && checkEnd === true) {
      setDisable('');
    } else {
      setDisable(false);
    }
  }, [checkStart, checkEnd, disable]);

  const LabeRequire = (props) => {
    const { text, req } = props;
    return (
      <span>
        {text}&nbsp;
        {req ? <LabelRequire>*</LabelRequire> : ''}
      </span>
    );
  };

  const handleOpenChangeA = (closeTimeA) => {
    setCloseTimeA(closeTimeA);
  };

  const handleOpenChangeB = (closeTimeB) => {
    setCloseTimeB(closeTimeB);
  };

  const handleSelectIcon = (item) => {
    fnc.setIdItem(item.fileHash);
    setIconItem(item.filePath);
    setValueIcon(item.filePath);
  };

  const handleSelectColor = (item) => {
    fnc.setIdColor(item);
    setColorItem(item);
    setValueColor(item);
  };

  const handleStartTime = (start, timestring, index) => {
    let data = [...state.timeArr];
    data[index].startTime = timestring;
    if (timestring) {
      setCheckStart(true);
    } else {
      setCheckStart(false);
    }
    fnc.setTimeArr(data);
  };

  const handleEndTime = (end, timestring, index) => {
    let data = [...state.timeArr];
    data[index].endTime = timestring;
    if (timestring) {
      setCheckEnd(true);
    } else {
      setCheckEnd(false);
    }
    fnc.setTimeArr(data);
  };

  const addTimeAll = () => {
    var obj = {
      startTime: startTime,
      endTime: endTime,
    };
    let data = [...state.timeArr];

    data.push(obj);

    fnc.setTimeArr(data);
    setCheckStart(!checkStart);
    setCheckEnd(!checkEnd);
    setDisable(false);
  };

  const changeCancel = () => {
    //reset time.array
    fnc.setTimeArr([{}]);

    //reset icon,color outside form
    setIconItem('');
    setColorItem('');
  };

  const deleteTime = (index) => {
    let data = [...state.timeArr];

    if (index !== -1) {
      data.splice(index, 1);
      fnc.setTimeArr(data);
    }
  };

  const contentIcon = (icon) => {
    return (
      <div>
        <div className="div-modal-big-icon">
          <List
            dataSource={icon}
            renderItem={(item) => (
              <Col className="gutter-row" span={3} style={{ width: 'auto' }}>
                <div className="div-modal">
                  <Avatar key={item.fileHash} src={item.filePath} onClick={() => handleSelectIcon(item)} size="small" shape="square" />
                </div>
              </Col>
            )}
          />
        </div>
      </div>
    );
  };

  const contentColor = (color) => {
    return (
      <div>
        <div className="div-modal-big">
          <List
            dataSource={color}
            renderItem={(item, index) => (
              <Col className="gutter-row" span={4} style={{ width: 'auto' }}>
                <div className="div-modal">
                  <Avatar
                    icon={item.id === item ? 'check' : ''} // a = if(item.id === idItem){ "check" }else{ "" };
                    shape="circle"
                    key={index}
                    style={{ backgroundColor: item }}
                    onClick={() => handleSelectColor(item)}
                  />
                </div>
              </Col>
            )}
          />
        </div>
      </div>
    );
  };

  const validateChecked = (rule, value, callback) => {
    try {
      if (!value) {
        callback('Icon!');
      } else {
        callback();
      }
    } catch (err) {
      callback(err);
    }
  };

  return (
    <ShiftSettings.Consumer>
      {() => (
        <Modal
          centered
          width={600}
          visible={visible}
          title="Shift type"
          okText="Save"
          onCancel={onCancel}
          onOk={onCreate}
          afterClose={changeCancel}
          footer={[
            <Button02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={onCancel}>
              Cancel
            </Button02>,
            <Button01 key="submit" type="primary" btnsize="wd_df" onClick={onCreate}>
              Save
            </Button01>,
          ]}
        >
          <Form layout="vertical">
            <Row>
              <Col span={2}>
                <Form.Item className="rules" label={<LabeRequire text="Icon" req={true} />}>
                  <Popover placement="bottomLeft" content={contentIcon(state.shiftTypesIcon)} trigger="click">
                    {getFieldDecorator('icon', {
                      initialValue: iconItem,
                      rules: [
                        {
                          required: iconItem ? false : true,
                          //required: true,
                          message: 'Icon!',
                        },
                      ],
                    })(
                      <Button className="button-width">
                        <Avatar className="avatar-selected" size="small" shape="square" src={iconItem} />
                      </Button>
                    )}
                  </Popover>
                </Form.Item>
              </Col>
              <Col span={21} style={{ float: 'right' }}>
                <Form.Item className="rules " label={<LabeRequire text="Name" req={true} />}>
                  {getFieldDecorator('name', {
                    initialValue: '',
                    rules: [
                      {
                        required: true,
                        message: 'Please input shift type name',
                      },
                    ],
                  })(<Input placeholder="Name" autoComplete="off" />)}
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span={3}>
                <Form.Item className="rules" label={<LabeRequire text="Color" req={true} />}>
                  <Popover
                    className="popover-arrow"
                    placement="bottomLeft"
                    content={
                      <div>
                        <div style={{ marginLeft: '18px', marginBottom: '-15px', padding: '10px' }}>Please select a color</div>
                        {contentColor(state.shiftTypesColor)}
                      </div>
                    }
                    trigger="click"
                  >
                    {getFieldDecorator('color', {
                      rules: [
                        {
                          required: colorItem ? false : true,
                          message: 'Color!',
                        },
                      ],
                      initialValue: colorItem,
                    })(
                      <Button
                        shape="circle"
                        className="buton-circle"
                        //icon={colorItem}
                        style={{ backgroundColor: `${colorItem}` }}
                      //value={colorItem}
                      />
                    )}
                  </Popover>
                </Form.Item>
              </Col>
              <Col span={21}>
                <Form.Item className="rules" label={<LabeRequire text="Type" req={true} />}>
                  {getFieldDecorator('shiftType', {
                    initialValue: [],
                    rules: [
                      {
                        required: true,
                        message: 'Please Select type',
                      },
                    ],
                  })(
                    <Select placeholder="Select type">
                      {state.shiftTypes &&
                        state.shiftTypes.map((item) => (
                          <Option
                            key={item.shift_types_id}
                          // value={item.shift_types_id}
                          >
                            {item.shift_types_name}
                          </Option>
                        ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Form.Item className="formItem-timespan" label={<LabeRequire text="Time span" req={true} />}>
                {state.timeArr.map((item, index) => (
                  <Row key={item.id}>
                    <Col span={10}>
                      <Form.Item className="rules">
                        {getFieldDecorator(`startTime-${index}`, {
                          rules: [
                            {
                              required: index > 0 ? false : true,
                              message: 'Please select Time',
                            },
                          ],
                        })(
                          <TimePicker
                            // placeholder='Select Time'
                            use12Hours
                            required

                            format={format}
                            placeholder="Start time"
                            style={{ width: '230px' }}
                            // onChange={(time, timeString) => handleStartTime(time, timeString, index)}
                            // defaultOpenValue={moment('00:00', 'HH:mm')}
                            allowClear={true}
                          // onOpenChange={handleOpenChangeA}
                          // open={closeTimeA}
                          // addon={() => (
                          //   <Button size="small" type="primary" onClick={() => setCloseTimeA(false)}>
                          //     Ok
                          //   </Button>
                          // )}
                          />
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={1}>&nbsp;&nbsp;-&nbsp;&nbsp;</Col>
                    <Col span={10}>
                      <Form.Item className="rules">
                        {getFieldDecorator(`endTime-${index}`, {
                          rules: [
                            {
                              type: 'object',
                              required: true,
                              message: 'Please select Time',
                            },
                          ],
                        })(
                          <TimePicker
                            use12Hours
                            format={format}
                            placeholder="End time "
                            style={{ width: '230px' }}
                            // onChange={(time, timeString) => handleEndTime(time, timeString, index)}
                            // defaultOpenValue={moment('00:00', 'HH:mm')}
                            allowClear={true}
                          // onOpenChange={handleOpenChangeB}
                          // open={closeTimeB}
                          // addon={() => (
                          //   <Button size="small" type="primary" onClick={() => setCloseTimeB(false)}>
                          //     Ok
                          //   </Button>
                          // )}
                          />
                        )}
                      </Form.Item>
                    </Col>

                    <Col span={3} label=" ">
                      <Form.Item className="rules">
                        {state.timeArr.length - 1 === index ? (
                          <Button
                            type="button"
                            className="shift-button-modal"
                            onClick={addTimeAll}
                          // disabled={disable}
                          >
                            Add
                          </Button>
                        ) : (
                          <Button type="button" className="shift-button-modal-delete" onClick={() => deleteTime(index)}>
                            Delete
                          </Button>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                ))}
              </Form.Item>
            </Row>
            <Row>
              <Col>
                <Form.Item className="rules" label={<LabeRequire text="Description" />}>
                  {getFieldDecorator('description', { rules: [{ required: true }] })(
                    <Input type="textarea" placeholder="input describe" autoComplete="off" />
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      )}
    </ShiftSettings.Consumer>
  );
};

const ModalForm = Form.create({
  name: 'modal_form',
  onFieldsChange(props, changedFields) {
    props.onChangeFormData(changedFields);
  },
  mapPropsToFields(props) {
    const { formData, timeArr } = props;

    const timeObject = {};
    if (timeArr.length > 0 && formData) {
      _.forEach(timeArr, (o, i) => {
        timeObject[`startTime-${i}`] = Form.createFormField({
          ...formData[`startTime-${i}`],
          value: o.startTime ? moment(o.startTime, 'LT') : undefined,
        });

        timeObject[`endTime-${i}`] = Form.createFormField({
          ...formData[`endTime-${i}`],
          value: o.endTime ? moment(o.endTime, 'LT') : undefined,
        });
      });
    }

    const test = formData
      ? {
        name: Form.createFormField({
          ...formData.name,
          value: formData.name ? formData.name.value : '',
        }),
        shiftType: Form.createFormField({
          ...formData.shiftType,
          value: formData.shiftType ? formData.shiftType.value : '',
        }),
        description: Form.createFormField({
          ...formData.description,
          value: formData.description ? formData.description.value : '',
        }),
        ...timeObject,
      }
      : null;

    return test;
  },
  onValuesChange(_, values) { },
})(ModalFormComponent);

export default ModalForm;
