import React, { useState, useEffect } from 'react';
import { AutoComplete, Form, Select } from 'antd';
import { useIntl } from 'react-intl';
import _ from 'lodash';
import LabeRequireForm from '../label-required-form';
import { getBranchList } from '../../controllers/delivery-order/branch';

const { Option } = AutoComplete;

const LoadmoreStore = (props) => {
  const intl = useIntl();
  const { form, data, setData, required, initialId, initialName, visible, title, placeholder } = props;
  const getFieldDecorator = form ? form.getFieldDecorator : null;
  const [dataInfo, setDataInfo] = useState({});
  const [dataInfoMaster, setDataInfoMaster] = useState({});
  const [list, setList] = useState([]);
  const [listMaster, setListMaster] = useState([]);

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [firstStep, setFirstStep] = useState(false);

  // ยังไม่ได้ทำผ่าน form

  useEffect(() => {
    if (initialId && visible) {
      setSearch(initialName);
      setFirstStep(true);
    } else {
      setSearch('');
      setFirstStep(false);
      setDataInfo({});
      setDataInfoMaster({});
      setList([]);
      setListMaster([]);
      setPage(1);
    }
  }, [initialId, visible]);

  useEffect(() => {
    if (firstStep) getBranchData();
  }, [page, firstStep]);

  const getBranchData = async () => {
    const body = {
      page: 1, //page: page,
      pageSize: 100, // pageSize: 20,
      sort: 'desc',
      sortBy: 'branchName',
      where: {
        $or: [
          {
            branchCode: {
              $regex: `.*${search || ''}.*`,
            },
          },
          {
            branchName: {
              $regex: `.*${search || ''}.*`,
            },
          },
        ],
      },
    };
    try {
      const response = await getBranchList(body);
      const res = _.get(response, 'data');
      setDataInfo(res);
      setDataInfoMaster(res);
      setList([...list, ..._.get(res, 'data.list', [])]);
      setListMaster([...listMaster, ..._.get(res, 'data.list', [])]);
    } catch (error) {
    }
  };

  const handleSelect = (value, option) => {
    if (form) {
      form.setFieldsValue({ store: value });
      setData(_.get(option, 'props.data'));
    } else {
      setData((prev) => ({ ...prev, store: value }));
    }
  };

  const handleChange = (value) => {
    const find = _.find(list, (item) => {
      return item.id === value || item.id === value;
    });
    setData((prev) => ({ ...prev, store: find, storeSearch: value }));
  };

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    if (scrollTop + clientHeight >= scrollHeight) {
      if (_.get(dataInfo, 'total') !== _.size(list)) setPage(page + 1);
    }
  };

  const handleSearch = _.debounce(async (value) => {
    setSearch(value);
    if (value && value !== '') {
      const body = {
        page: page,
        pageSize: 20,
        sort: 'desc',
        sortBy: 'branchName',
        where: {
          $or: [
            {
              branchCode: {
                $regex: `.*${search || ''}.*`,
              },
            },
            {
              branchName: {
                $regex: `.*${search || ''}.*`,
              },
            },
          ],
        },
      };
      const response = await getBranchList(body);
      const res = _.get(response, 'data');
      setDataInfo(res);
      setList(_.get(res, 'data.list', []));
    } else {
      setDataInfo(dataInfoMaster);
      setList(listMaster);
    }
  }, 500);

  // const handleBlurForm = () => {
  //   const selectedValue = getFieldValue('saleOrderNo');
  //   const isValid = _.some(saleOrderList, (item) => item.saleOrderId === selectedValue);
  //   if (!isValid) {
  //     setFieldsValue({ saleOrderNo: undefined });
  //     setSelectSaleOrder();
  //   }
  // };

  const handleBlur = () => {
    const selectedValue = _.get(data, 'storeSearch');
    const isValid = _.some(list, (item) => item.id === selectedValue);
    if (!isValid) {
      setData((prev) => ({ ...prev, store: undefined, storeSearch: undefined }));
      setSearch('');
      setDataInfo(dataInfoMaster);
      setList(listMaster);
    }
  };

  return (
    <Form.Item onClick={() => setFirstStep(true)} label={<LabeRequireForm text={title} req={required} />}>
      {form ? (
        getFieldDecorator('store', {
          initialValue: initialId,
          rules: [
            {
              required: required,
              message: intl.formatMessage({
                id: 'storeValidate',
                defaultMessage: 'Please Select Store',
              }),
            },
          ],
        })(
          <AutoComplete onSelect={handleSelect} onPopupScroll={handleScroll} onSearch={handleSearch} placeholder={placeholder}>
            {_.map(list, (item) => (
              <Option key={_.get(item, 'id')} value={_.get(item, 'id')} data={item}>
                {_.get(item, 'branchCode') + ' ' + _.get(item, 'branchName')}
              </Option>
            ))}
          </AutoComplete>
        )
      ) : (
        // <AutoComplete
        //   // onSelect={handleSelect}
        //   onChange={handleChange}
        //   onPopupScroll={handleScroll}
        //   onSearch={handleSearch}
        //   placeholder={placeholder}
        //   value={_.get(data, 'storeSearch', '')}
        //   allowClear={true}
        //   onBlur={handleBlur}
        // >
        //   {_.map(list, (item) => (
        //     <Option key={_.get(item, 'id')} value={_.get(item, 'id')} data={item}>
        //       {_.get(item, 'branchCode') + ' ' + _.get(item, 'branchName')}
        //     </Option>
        //   ))}
        // </AutoComplete>
        <Select
          allowClear={true}
          style={{ width: '100%' }}
          placeholder={placeholder}
          filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          showSearch
          onChange={handleChange}
          value={_.get(data, 'storeSearch')}
        >
          {_.map(list, (item) => (
            <Option key={_.get(item, 'id')} value={_.get(item, 'id')}>{_.get(item, 'branchCode') + ' ' + _.get(item, 'branchName')}</Option>
          ))}
        </Select>
      )}
    </Form.Item>
  );
};

LoadmoreStore.defaultProps = {
  required: true,
};

export default LoadmoreStore;
