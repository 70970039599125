import React, { useState, useEffect } from 'react';
import './index.css';
import { Row, Col } from 'antd';
import PointFilter from './components/filter';
import PointDetail from './components/detail';
import ViewPoint from './components/view';
import ImportTransaction from './components/import';
import { searchPoint, getRole, getNameLoadmore, exportPoint, exportPointDetail } from '../../controllers/point/point';
import _ from 'lodash';
import { useDebounce } from '../../controllers/debounce';
import { useIntl } from 'react-intl';

const Point = () => {
  const intl = useIntl();
  const [toggle, setToggle] = useState(true);
  const [pointData, setPointData] = useState([]);
  const [pointTotal, setPointTotal] = useState(0);
  const [pointTypeData, setPointTypeData] = useState([]);
  const [pointTypeInterminate, setPointTypeInterminate] = useState(false);
  const [pointTypeCheckAll, setPointTypeCheckAll] = useState(false);
  const [pointTypeSelect, setPointTypeSelect] = useState([]);
  const [panelPointType, setPanelPointType] = useState(false);
  const [paginationPage, setPaginationPage] = useState(1);
  const [paginationSize, setPaginationSize] = useState(10);
  const [orderSort, setOrderSort] = useState();
  const [fieldSort, setFieldSort] = useState();
  const [visibleView, setVisibleView] = useState(false);
  const [visibleImport, setVisibleImport] = useState(false);
  const [roleData, setRoleData] = useState([]);
  const [memberData, setMemberData] = useState([]);
  const [roleSelect, setRoleSelect] = useState([]);
  const [memberIndex, setMemberIndex] = useState(10);
  const [memberSearch, setMemberSearch] = useState('');
  const [memberSelect, setMemberSelect] = useState([]);
  const debounceScroll = useDebounce(memberIndex, 500);
  const debounceSearch = useDebounce(memberSearch, 500);
  const [defaultData, setDefaultData] = useState();
  const [trigger, setTrigger] = useState(false);
  const [template, setTemplate] = useState();
  const [phoneSearch, setPhoneSearch] = useState('');

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
    },
    {
      title: intl.formatMessage({ id: 'menuCampaignPointColumnsRole', defaultMessage: 'Role' }),
      dataIndex: 'role',
      key: 'role',
      width: 180,

    },
    {
      title: intl.formatMessage({ id: 'menuCampaignPointColumnsName', defaultMessage: 'Name' }),
      dataIndex: 'memberName',
      key: 'memberName',
    },
    {
      title: intl.formatMessage({ id: 'menuCampaignPointColumnsPhone', defaultMessage: 'Phone' }),
      dataIndex: 'memberPhone',
      key: 'memberPhone',
      width: 180,
    },
    {
      title: intl.formatMessage({ id: 'menuCampaignPointColumnsPointType', defaultMessage: 'Point Type' }),
      dataIndex: 'pointTypeCode',
      key: 'pointTypeCode',
      width: 180,
    },
    {
      title: intl.formatMessage({ id: 'menuCampaignPointColumnsBalance', defaultMessage: 'Balance (Pt)' }),
      dataIndex: 'pointAmount',
      key: 'pointAmount',
      width: 180,
    },
  ];

  useEffect(() => {
    const getRoleData = async () => {
      const response = await getRole();
      setRoleData(_.get(response, 'data.data'));
    };
    getRoleData();
  }, []);

  useEffect(() => {
    const getMemberData = async () => {
      const body = {
        searchName: memberSearch,
        indexStart: 0,
        indexEnd: memberIndex,
      };
      const response = await getNameLoadmore(body);
      setMemberData(_.get(response, 'data.data.memberList'));
    };
    getMemberData();
  }, [debounceScroll, debounceSearch]);

  useEffect(() => {
    const SearchData = async () => {
      const body = {
        orderBy: fieldSort,
        orderType: orderSort,
        roleList: _.map(roleSelect, (item) => {
          return _.toString(item);
        }),
        memberIdList: memberSelect,
        pointTypeCodeList: pointTypeSelect,
        pageNumber: paginationPage,
        limit: paginationSize,
        memberPhone: phoneSearch,
      };
      const response = await searchPoint(body);
      setPointData(_.get(response, 'data.data.memberPointList'));
      setPointTotal(_.get(response, 'data.data.totalItem'));
      setPointTypeData(_.get(response, 'data.data.pointTypeList'));
      setTemplate(_.get(response, 'data.data.importPointTemplateXlsx'));
    };

    SearchData();
  }, [fieldSort, orderSort, paginationPage, paginationSize, roleSelect, memberSelect, pointTypeSelect, trigger,phoneSearch]);

  const handleToggle = () => {
    setToggle(!toggle);
  };

  const handleChange = (pagination, sorter, extra) => {
    let field = extra.field;
    if (extra.order) {
      if (extra.order === 'descend') {
        setOrderSort('desc');
      } else {
        setOrderSort('asc');
      }
    } else {
      setOrderSort('');
    }
    setFieldSort(field);
    setPaginationSize(pagination.pageSize);
    setPaginationPage(pagination.current);
  };

  const handleView = async (data) => {
    setDefaultData(data);
    setVisibleView(true);
  };

  const handleImport = () => {
    setVisibleImport(true);
  };

  const handleSelectRole = (value) => {
    setRoleSelect(value);
    setPaginationPage(1);
  };

  const handleSelecMember = (value) => {
    setMemberSelect(value);
    setPaginationPage(1);
  };

  const handleSearchPhone = (value) => {
    setPhoneSearch(value);
    setPaginationPage(1);
  };

  const handleMemberPopupScroll = (e) => {
    let target = e.target;
    if (target.scrollTop + target.offsetHeight >= target.scrollHeight) {
      setMemberIndex(memberIndex + 50);
    }
  };

  const handleSearch = (value) => {
    setMemberSearch(value);
  };

  const exportData = async () => {
    const filterColumns = _.filter(columns, (item) => {
      return item.key !== 'index';
    });
    const body = {
      orderBy: fieldSort,
      orderType: orderSort,
      roleList: roleSelect,
      memberIdList: memberSelect,
      pointTypeCodeList: pointTypeSelect,
      memberPhone: phoneSearch,
      pageNumber: 1,
      limit: pointTotal,
      viewFormat: _.map(filterColumns, (item, index) => {
        return {
          colName: item.title,
          sequence: index + 1,
          colCode: item.key,
        };
      }),
    };
    const response = await exportPoint(body);
    window.open(response.data.data.excelExportUrl, '_blank');
  };

  const exportDataDetail = async () => {
    const body = {
      roleList: roleSelect,
      memberIdList: memberSelect,
      pointTypeCodeList: pointTypeSelect,
      memberPhone: phoneSearch,
    };
    const response = await exportPointDetail(body);
    window.open(response.data.data.excelExportUrl, '_blank');
  };

  return (
    <div>
      <Row gutter={[16]}>
        <Col span={toggle ? 6 : 0}>
          <PointFilter
            pointTypeData={pointTypeData}
            pointTypeSelect={pointTypeSelect}
            setPointTypeSelect={setPointTypeSelect}
            pointTypeInterminate={pointTypeInterminate}
            setPointTypeInterminate={setPointTypeInterminate}
            pointTypeCheckAll={pointTypeCheckAll}
            setPointTypeCheckAll={setPointTypeCheckAll}
            panelPointType={panelPointType}
            setPanelPointType={setPanelPointType}
            roleData={roleData}
            roleSelect={roleSelect}
            handleSelectRole={handleSelectRole}
            memberData={memberData}
            handleMemberPopupScroll={handleMemberPopupScroll}
            handleSearch={handleSearch}
            memberSelect={memberSelect}
            handleSelecMember={handleSelecMember}
            phoneSearch={phoneSearch}
            handleSearchPhone={handleSearchPhone}
          />
        </Col>
        <Col span={toggle ? 18 : 24}>
          <PointDetail
            handleToggle={handleToggle}
            toggle={toggle}
            paginationPage={paginationPage}
            handleChange={handleChange}
            handleView={handleView}
            handleImport={handleImport}
            pointData={pointData}
            pointTotal={pointTotal}
            columns={columns}
            exportData={exportData}
            exportDataDetail={exportDataDetail}
          />
        </Col>
      </Row>

      <ViewPoint visible={visibleView} setVisible={setVisibleView} defaultData={defaultData} />

      <ImportTransaction visible={visibleImport} setVisible={setVisibleImport} setTrigger={setTrigger} template={template} />
    </div>
  );
};

export default Point;
