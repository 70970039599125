import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Divider, Form, Modal, Tabs, Tag } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import Button_02 from '../../../../components/v2/button_02.js';
import Button_01 from '../../../../components/v2/button_01.js';
import '../css/index.css';
import CustomerForm from './customer-form.js';
import CustomTableComponent from '../../components/table.js';
import { messageLabel } from '../../../../components/message-component.js';
import PriceForm from './price-form.js';
import { StickyContainer } from 'react-sticky';
import DetailsTab from './details-tab.js';
import PaymentTab from './payment-tab.js';
import CampainTab from './campain-tab.js';
import UploadFileModal from '../upload-file/upload-modal.js';
import { AccountReceiveContext } from '../../context.js';
import _ from 'lodash';
import ModalCreateListItem from '../list-item/add/index.js';
import ListItem from '../list-item/list/index';
import moment from 'moment';
import ModalSelectCustomerForm from '../select-customer/customer-modal.js';
import { numberFormatter } from '../../../../component-function/fnc-inputnumber-currency.js';
import PaymentTabView from './view/payment-pending.js';
import CampainTabView from './view/campain-pending.js';
import ModalApproveForm from '../approve/approve-modal.js';
import ModalCancelForm from '../cancel/cancel-modal.js';
import ModalRejectForm from '../reject/reject-modal.js';
import ModalWaitingForm from '../waiting/waiting-modal.js';
import ModalDraftForm from '../delete/delete-modal.js';
import ModalSelectQuotationForm from '../select-quotation/quotation-select-modal.js';
import DetailsTabView from './view/detail-pending.js';
import ModalPaidForm from './paid-modal.js.js';
import { uploadARAttachment } from '../../../../controllers/account-receive/index.js';
import { async } from 'q';
import ModalItemTranfer from '../modal-item-tranfer/index.js';
import ModalQTYTranferForm from '../modelqty/index.js';
import ModalCompleteForm from '../compled/compled-modal.js';

const { TabPane } = Tabs;

const TranferModal = ({ form, title, visible, onOk, onCancel, attData, setAttData }) => {
  const { state, setState, func } = useContext(AccountReceiveContext);
  const {
    initialMemberList,
    initialTaxList,
    initialCampaignList,
    initialReasonList,
    record,
    setRecord,
    visibleApprove,
    visibleReject,
    visibleCancel,
    visibleDelete,
    visibleWaiting,
    isOpenQuotation,
    attachmentPaidData,
    wareHouseFromList,
    wareHouseToList,
    notWarehouseFromId,
    notWarehouseToId,
    pageTable,
    sizeTable,
    visibleComplete,
  } = state;
  const { setAttachmentPaidData, setNotWarehouseFromId, setNotWarehouseToId, setPageTable, setSizeTable } = setState;
  const {
    handleOpenModalUpload,
    handleOpenApprove,
    handleSaveApprove,
    handleCancelApprove,
    handleOpenReject,
    handleSaveReject,
    handleCancelReject,
    handleOpenCancel,
    handleSaveCancel,
    handleCancelCancelQT,
    handleOpenDelete,
    handleSaveDelete,
    handleCancelDelete,
    handleSaveWaiting,
    handleCancelWaiting,
    handleOpenWaiting,
    handleOpenPaid,
    handleCancelPaid,
    handleWarhouseFromPopupScroll,
    handleWarhouseToPopupScroll,
    handleOpenComplete,
    handleSaveComplete,
    handleCancelComplete,
  } = func;
  const [dataForm, setDataForm] = useState();
  const [tabkeys, setTabkeys] = useState('detail');
  const [visibleItem, setVisibleItem] = useState(false);
  const [visibleAdd, setVisibleAdd] = useState(false);
  const [selectItem, setSelectItem] = useState([]);
  const [createData, setCreateData] = useState();
  const [customerData, setCustomerData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenQt, setIsOpenQt] = useState(false);
  const [paymentItem, setPaymentItem] = useState([]);
  const [tempARAtt, setTempARAtt] = useState([]);
  const [requestDetailData, setRequestDetailData] = useState([]);
  const [itemRecord, setItemRecord] = useState([]);
  const [typeCode, setTypeCode] = useState('internal');
  const [assignName, setAssignName] = useState('');
  const [typeEdit, setTypeEdit] = useState('add');
  // console.log('form data for save', dataForm, selectItem);
  console.log('TranferModal dataForm', dataForm);

  const intl = useIntl();
  const { setFieldsValue, resetFields } = form;
  const formCustomerRef = useRef();

  console.log('formCustomerRef', record);

  useEffect(() => {
    if (_.get(record, 'quotationId') && visible && _.get(record, 'code')) {
      console.log('dataFormUseEffect', dataForm, record);
      console.log('dataFormUseEffect', record);
      setDataForm(record);
    }
  }, [record, visible]);

  useEffect(() => {
    if (record) {
      setDataForm(record);

      const itemList = _.map(_.get(record, 'itemList'), (o, i) => ({
        ...o,
        index: i + 1,
      }));

      setSelectItem(itemList);

      const setobj = _.map(_.get(record, 'attanchment'), (o, i) => ({
        ...o,
        index: i + 1,
        attachmentName: _.get(o, 'fileHash'),
        remark: _.get(o, 'remark'),
      }));
      setAttData(setobj);
    }
  }, [record]);

  useEffect(() => {
    if (_.get(dataForm, 'customerId')) {
      setFieldsValue({ customerName: _.get(dataForm, 'customerName') });
    }
    if (_.get(dataForm, 'quotationObj.quotationId')) {
      setFieldsValue({ quotationNo: _.get(dataForm, 'quotationObj.quotationNo') });
    }
  }, [dataForm]);

  useEffect(() => {
    console.log('selectItem', selectItem);
    if (notWarehouseFromId) {
      setSelectItem([]);
    }
  }, [notWarehouseFromId]);

  const handleSave = (status) => {
    console.log('handleSave', dataForm, status);

    form.validateFields(async (err, values) => {
      if (err) {
        return;
      }

      const tranferItem = _.map(selectItem, (listtran) => ({
        movementId: _.get(listtran, 'movementId'),
        qty: _.get(listtran, 'qtyStack'),
        remark: _.get(listtran, 'remarkTran'),
        uomId: _.get(listtran, 'uomId'),
      }));

      const assignNameTime = _.filter(initialMemberList, (e) => e.memComId === _.get(values, 'assignToId'));

      const itemList = _.map(selectItem, (o) => ({
        itemId: o.itemMasterId,
        qty: _.toString(o.qty),
        unit: _.toString(o.uomId),
        unitName: _.toString(o.uomName),
        price: _.toString(o.pricePerUnit),
        campaignName: _.toString(o.promotionCampaign),
        campaignId: _.toString(o.promotionCampaignId),
        discountPercent: _.toString(o.discountPercentage),
        discount: _.toString(o.discountPrice),
        total: _.toString(o.totalPrice),
        credit: _.toString(o.credits),
        remark: _.toString(o.remark),
      }));

      // const att = _.map(attData, async (o, index) => {
      //   const file = _.get(o, 'fileRaw');
      //   const formData = new FormData();
      //   formData.append('file', file);
      //   formData.append('saleOrderId', _.get(dataForm, 'saleOrderNo'));
      //   formData.append('remark', o.remark);

      //   if (o.fileHash === undefined) {
      //     const respon = await uploadARAttachment(formData);
      //     console.log('AttFileHash', respon, 'file', _.get(respon, 'data.fileHash'), 'remarl', _.get(respon.data, 'detail.remark'));
      //     return { fileHash: _.get(respon, 'data.fileHash'), remark: _.get(respon.data, 'detail.remark') };
      //   } else {
      //     return { fileHash: _.get(o, 'fileHash'), remark: _.get(o, 'remark') };
      //   }
      // });

      // let att = [];

      // for (let index = 0; index < attData.length; index++) {
      //   const file = _.get(attData[index], 'fileRaw');
      //   const formData = new FormData();
      //   formData.append('file', file);
      //   formData.append('saleOrderId', _.get(dataForm, 'saleOrderNo'));
      //   formData.append('remark', attData[index].remark);

      //   if (attData[index].fileHash === undefined) {
      //     const reaspon = await uploadARAttachment(formData);
      //   }
      // }

      const payloads = {
        transferId: _.get(record, 'transferId') ? _.get(record, 'transferId') : undefined,
        itemAmount: _.size(selectItem),
        remark: _.get(values, 'remark'),
        assignToTypeCode: typeCode,
        assignToId: _.get(values, 'assignToId') || '',
        assignToName: _.get(values, 'assignToName') ? _.get(values, 'assignToName') : _.get(assignNameTime, '[0].fullname'),
        submitById: _.get(values, 'submitById'),
        submitDate: moment(_.get(values, 'submitDate')).format('YYYY-MM-DD'),
        transferDate: moment(_.get(values, 'transferDate')).format('YYYY-MM-DD'),
        transferTime: moment(_.get(values, 'transferTime')).format('HH:mm'),
        warehouseFromId: _.get(values, 'warehouseFromId'),
        warehouseToId: _.get(values, 'warehouseToId'),
        transferItemList: tranferItem,
      };

      console.log('savex payload', payloads);
      onOk(payloads);
      resetFields();
      setDataForm();
      setCreateData();
      setAttData([]);
      setSelectItem([]);
    });
  };

  const handleCancelQuotation = () => {
    onCancel();
    resetFields();
    setDataForm();
    setCreateData();
    setAttData([]);
    setSelectItem([]);
  };

  const callback = (key) => {
    setTabkeys(key);
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleQTYOk = (record) => {
    setItemRecord(record);
    setIsOpenQt(true);
  };

  const handleQTYCancel = () => {
    setIsOpenQt(false);
    setTypeEdit('add')
  };

  const operations = (
    <div className="tag-center-div" style={{ padding: '12px 2px' }}>
      <Tag className="tag-center-style" color={_.get(record, 'status.color')}>
        {_.get(record, 'status.txt')}
      </Tag>
    </div>
  );

  const handleDelete = () => {
    Modal.confirm({
      className: 'maintenance-report-modal-confirm',
      centered: true,
      icon: null,
      title: intl.formatMessage({ id: 'lblDelete', defaultMessage: 'Are you sure you want to delete ?' }),
      okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        const payload = {
          id: _.get(record, 'saleOrderId'),
          code: _.get(record, 'code'),
          status: 'deleted',
        };
        handleSaveDelete(payload);
      },
      onCancel() {},
    });
  };

  const actionFooter =
    _.get(record, 'type') === 'view' && _.get(record, 'code') === 'waiting'
      ? [
          // <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleCancelQuotation}>
          //   <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
          // </Button_02>,
          <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleCancelQuotation}>
            <FormattedMessage id="btnClose" defaultMessage="Close" />
          </Button_02>,
          <Button_01 style={{ margin: '0px 0px 0px 10px', width: '15%' }} key="cancel" type="primary" onClick={() => handleOpenCancel()}>
            <FormattedMessage id="btnCancelTransfer" defaultMessage="Cancel Transfer" />
          </Button_01>,
          <Button_01
            style={{ margin: '0px 0px 0px 10px' }}
            key="approve"
            type="primary"
            btnsize="wd_df"
            onClick={() => handleOpenApprove()}
          >
            <FormattedMessage id="TFbtnApprove" defaultMessage="Approve" />
          </Button_01>,
          <Button_01 style={{ margin: '0px 0px 0px 10px' }} key="approve" type="primary" btnsize="wd_df" onClick={() => handleOpenReject()}>
            <FormattedMessage id="TFbtnReject" defaultMessage="Reject" />
          </Button_01>,
        ]
      : _.get(record, 'code') === 'waiting' && _.get(record, 'type') !== 'view'
      ? [
          <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleCancelQuotation}>
            <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
          </Button_02>,
          <Button_01
            disabled={_.size(selectItem) > 0 ? false : true}
            style={{ margin: '0px 0px 0px 10px' }}
            key="cancelQT"
            type="primary"
            btnsize="wd_df"
            onClick={() => {
              handleSave('waiting');
            }}
          >
            <FormattedMessage id="btnSave" defaultMessage="Save" />
          </Button_01>,
        ]
      : _.get(record, 'code') === 'approved'
      ? [
          <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleCancelQuotation}>
            <FormattedMessage id="btnClose" defaultMessage="Close" />
          </Button_02>,
          <Button_01 style={{ margin: '0px 0px 0px 10px', width: '15%' }} key="cancel" type="primary" onClick={() => handleOpenCancel()}>
            <FormattedMessage id="btnCancelTransfer" defaultMessage="Cancel Transfer" />
          </Button_01>,
          <Button_01
            style={{ margin: '0px 0px 0px 10px' }}
            key="approve"
            type="primary"
            btnsize="wd_df"
            onClick={() => handleOpenReject()}
          >
            <FormattedMessage id="btnReject" defaultMessage="Reject" />
          </Button_01>,
          <Button_01 style={{ margin: '0px 0px 0px 10px',width: '18%' }} key="approve" type="primary" btnsize="wd_df" onClick={() => handleOpenComplete()}>
            <FormattedMessage id="TFComplete" defaultMessage="Complete" />
          </Button_01>,
        ]
      : _.get(record, 'code') === 'canceled'
      ? [
          <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleCancelQuotation}>
            <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
          </Button_02>,
          <Button_01 style={{ margin: '0px 0px 0px 10px', width: '18%' }} key="waiting" type="primary" onClick={() => handleOpenWaiting()}>
            <FormattedMessage id="ARWaitingPayment" defaultMessage="Waiting for Payment" />
          </Button_01>,
        ]
      : [
          <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleCancelQuotation}>
            <FormattedMessage id="btnClose" defaultMessage="Close" />
          </Button_02>,
        ];

  const closeItemTranferModal = (name, size) => {
    setIsOpen(false);
  };

  return (
    <Modal
      title={title}
      visible={visible}
      onOk={handleSave}
      onCancel={handleCancelQuotation}
      width={1000}
      centered={true}
      bodyStyle={{ padding: 'unset' }}
      footer={actionFooter}
    >
      {_.get(record, 'type') === 'view' && (
        <DetailsTabView
          form={form}
          property={{
            record,
            pageTable,
            sizeTable,
            setPageTable,
            setSizeTable,
          }}
        />
      )}
      {_.get(record, 'type') !== 'view' ? (
        /* {_.get(record, 'code') === 'waiting' && _.get(record, 'type') === undefined  ? ( */
        <DetailsTab
          form={form}
          property={{
            handleOpenModalUpload,
            setDataForm,
            dataForm,
            setVisibleItem,
            initialMemberList,
            handleOpen,
            selectItem,
            setSelectItem,
            attData,
            setAttData,
            initialTaxList,
            handleWarhouseFromPopupScroll,
            wareHouseFromList,
            handleWarhouseToPopupScroll,
            wareHouseToList,
            setNotWarehouseFromId,
            setNotWarehouseToId,
            notWarehouseFromId,
            setTypeCode,
            setAssignName,
            handleQTYOk,
            setTypeEdit,
            pageTable,
            sizeTable,
            setPageTable,
            setSizeTable,
            record,
          }}
        />
      ) : null}

      <ModalItemTranfer
        isOpen={isOpen}
        closeTicketModal={closeItemTranferModal}
        requestDetailData={requestDetailData}
        setRequestDetailData={setRequestDetailData}
        notWarehouseFromId={notWarehouseFromId}
        handleQTYOk={handleQTYOk}
        wareHouseFromList={wareHouseFromList}
        
      />

      <ModalQTYTranferForm
        visible={isOpenQt}
        onCancel={handleQTYCancel}
        record={itemRecord}
        closeItemTranferModal={closeItemTranferModal}
        selectItem={selectItem}
        setSelectItem={setSelectItem}
        typeEdit={typeEdit}
      />

      <ModalCancelForm
        title={intl.formatMessage({ id: 'TFCancelTransfer', defaultMessage: 'Cancel Transfer' }) + ' · ' + _.get(record, 'transferNo')}
        visible={visibleCancel}
        onOk={handleSaveCancel}
        onCancel={handleCancelCancelQT}
        initialReasonList={_.get(record, 'reasonCancel')}
        id={_.get(record, 'transferId')}
      />

      <ModalWaitingForm
        title={intl.formatMessage({ id: 'ARWaitingPayment', defaultMessage: 'Waiting for Payment' }) + ' · ' + _.get(record, 'transferNo')}
        visible={visibleWaiting}
        onOk={handleSaveWaiting}
        onCancel={handleCancelWaiting}
        initialReasonList={_.get(record, 'reasonToWaiting')}
        id={_.get(record, 'transferId')}
      />

      <ModalApproveForm
        title={intl.formatMessage({ id: 'TFApproved', defaultMessage: 'Approved' }) + ' · ' + _.get(record, 'transferNo')}
        visible={visibleApprove}
        onOk={handleSaveApprove}
        onCancel={handleCancelApprove}
        id={_.get(record, 'transferId')}
      />

      <ModalRejectForm
        title={intl.formatMessage({ id: 'TFRejected', defaultMessage: 'Rejected' }) + ' · ' + _.get(record, 'transferNo')}
        visible={visibleReject}
        onOk={handleSaveReject}
        onCancel={handleCancelReject}
        initialReasonList={_.get(record, 'reasonReject')}
        id={_.get(record, 'transferId')}
      />

      <ModalCompleteForm
        title={intl.formatMessage({ id: 'TFComplete', defaultMessage: 'Complete' }) + ' · ' + _.get(record, 'transferNo')}
        visible={visibleComplete}
        onOk={handleSaveComplete}
        onCancel={handleCancelComplete}
        id={_.get(record, 'transferId')}
      />
    </Modal>
  );
};

TranferModal.defaultProps = {
  title: 'Modal',
  visible: false,
  onOk: () => console.warn('onOk not function '),
  onCancel: () => console.warn('onCancel not function '),
};

const ModalTranferForm = Form.create({ name: 'Customer_Form' })(TranferModal);

export default ModalTranferForm;
