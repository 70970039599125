import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import MapComponent from './map/index';
import { Modal, Form, Select, notification, TimePicker, Input, Row, Col } from 'antd';
import Button01 from '../../../components/v2/button_01';
import Button02 from '../../../components/v2/button_02';
import httpClient from '../../../components/axiosClient';
import { PageSettings } from '../../../config/page-settings';
import { useAppContext } from '../../../includes/indexProvider';
import { ServicesContext } from './services-context';
import _ from 'lodash';
import moment from 'moment';
import styled from 'styled-components';
import styles from './css/editArea.css';
import stylesIndex from './css/index.css';

const { Option } = Select;

const CollectionCreateForm = Form.create({ name: 'form_in_modal' })(
  class extends React.Component {
    render() {
      const {
        stateServicesMain,
        areaListData,
        areaList,
        taskTypes,
        editServices,
        areaCode,
        taskTypeCode,
        buffer,

        onChange,
        onCancel,
        onCreate,
        onSearch,
        onBlur,
        onFocus,
        intl,
        form,
        langValueState,
        customerData,
      } = this.props;
      const { getFieldDecorator } = form;
      const customerIdArr = _.map(editServices.servicesDetailCustomer, 'customerId');
      const name = _.get(editServices, 'name');
      const format = langValueState == 'EN' ? 'h:mm A' : 'HH:mm';

      const LabeRequire = (props) => {
        const { text, req } = props;
        return (
          <span>
            {text}&nbsp;
            {req ? <LabelRequire>*</LabelRequire> : ''}
          </span>
        );
      };

      const LabelRequire = styled.label`
        color: #ff0000;
      `;

      const compareStartTime = (rule, value, callback) => {
        let valueEndTime = form.getFieldValue(`endTime`);
        if (value >= valueEndTime) {
          callback(
            <span>
              {intl.formatMessage({
                id: 'orgTabServiceValidateStartTime',
                defaultMessage: 'The start time should not be greater than or equal to the end time.',
              })}
            </span>
          );
        }
        callback();
      };

      const compareEndTime = (rule, value, callback) => {
        let valueStartTime = form.getFieldValue(`startTime`);
        if (value <= valueStartTime) {
          callback(
            <span>
              {intl.formatMessage({
                id: 'orgTabServiceValidateEndTime',
                defaultMessage: 'The end time should not be less than or equal to the start time.',
              })}
            </span>
          );
        }
        callback();
      };

      return (
        <Modal
          className="label-from-EditArea"
          title={<FormattedMessage id="orgTabServiceModalEditTitle" defaultMessage="Edit Area" />}
          centered
          visible={stateServicesMain.visibleEditModal}
          bodyStyle={{ height: '600px', overflowY: 'auto' }}
          onOk={onCreate}
          onCancel={onCancel}
          width={790}
          footer={[
            <Button02 style={{ margin: '0px 0px 0px 10px' }} key="back" fontsize="sm" btnsize="wd_df" onClick={onCancel} margin=" unset">
              <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
            </Button02>,
            <Button01 key="submit" fontsize="sm" btnsize="wd_df" type="primary" onClick={onCreate}>
              <FormattedMessage id="btnSave" defaultMessage="Save" />
            </Button01>,
          ]}
        >
          <Form layout="vertical">
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Form.Item
                  className="label-from"
                  label={
                    <LabeRequire
                      text={<FormattedMessage id="orgTabServiceModalLblName" defaultMessage="Services area name" />}
                      req={true}
                    />
                  }
                >
                  {getFieldDecorator('name', {
                    initialValue: name,
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({
                          id: 'orgTabServiceRequireName',
                          defaultMessage: 'Please select your services area name',
                        }),
                      },
                    ],
                  })(
                    <Input
                      placeholder={intl.formatMessage({
                        id: 'orgTabServiceModalHintName',
                        defaultMessage: 'Please enter services area name',
                      })}
                      autoComplete="off"
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  className="label-from"
                  label={
                    <LabeRequire
                      text={<FormattedMessage id="orgTabServiceModalLblAreaName" defaultMessage="Area name or code" />}
                      req={true}
                    />
                  }
                >
                  {getFieldDecorator('area', {
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({
                          id: 'orgTabServiceRequireAreaName',
                          defaultMessage: 'Please select your area.',
                        }),
                      },
                    ],
                    initialValue: `${areaCode}`,
                  })(
                    <Select
                      showSearch
                      placeholder={intl.formatMessage({
                        id: 'orgTabServiceModalHintAreaName',
                        defaultMessage: 'Please select area',
                      })}
                      onSearch={onSearch}
                      onBlur={onBlur}
                      onFocus={onFocus}
                      onChange={onChange}
                      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                      
                      {/* {areaList &&
                        areaList.map((item, i) => (
                          <Option key={i} value={item.areaCode}>
                            {item.areaName}
                          </Option>
                        ))
                      } */}

                      {/* {areaListData &&
                        areaListData.map((item, i) => (
                          <Option key={item.serviceCode}>
                            {item.areaName}
                          </Option>
                        ))
                      } */}

                      {areaList && areaList.map((item, i) => <Option key={item.areaCode} value={item.areaCode}>{item.areaName}</Option>)}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Form.Item className="label-from">
              <MapComponent areaList={stateServicesMain.selectAreaList} />
            </Form.Item>

            <Form.Item
              className="label-from"
              label={
                <LabeRequire
                  text={<FormattedMessage id="orgTabServiceModalLblTaskType" defaultMessage="Task type or task category" />}
                  req={true}
                />
              }
            >
              {getFieldDecorator('category', {
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({
                      id: 'orgTabServiceRequireTaskType',
                      defaultMessage: 'Please select your category.',
                    }),
                  },
                ],
                initialValue: taskTypeCode,
              })(
                <Select
                  className="edit-select-tag"
                  placeholder={intl.formatMessage({
                    id: 'orgTabServiceModalHintTaskType',
                    defaultMessage: 'Please select task type or task category',
                  })}
                  mode="multiple"
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {taskTypes.map((item, i) => (
                    <Option key={i} value={item.taskTypeCode}>
                      {item.taskTypeName}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>

            <div className="services-div-time-period">
              <Row gutter={16}>
                <Col span={16} className="services-row-col-time-period">
                  <Form.Item
                    className="label-from"
                    label={
                      <LabeRequire
                        text={<FormattedMessage id="orgTabServiceModalLblTimeperiod" defaultMessage="Time period" />}
                        req={true}
                      />
                    }
                  >
                    {getFieldDecorator('startTime', {
                      initialValue: editServices && editServices.startTime ? moment(editServices.startTime.val, 'h:mm A') : null,
                      rules: [
                        {
                          required: true,
                          message: intl.formatMessage({
                            id: 'orgTabServiceRequireTimeperiodStart',
                            defaultMessage: 'Please choose your start time.',
                          }),
                        },
                        {
                          validator: compareStartTime,
                        },
                      ],
                    })(
                      <TimePicker
                        className="time-period-timePicker"
                        placeholder={intl.formatMessage({
                          id: 'orgTabServiceModalHintTimeperiodStart',
                          defaultMessage: 'Please enter start time.',
                        })}
                        use12Hours
                        format={format}
                      />
                    )}
                  </Form.Item>
                  <span className="service-span-time-period"> - </span>
                  <Form.Item
                    // className="time-period-end-label-from"
                    label={<span className="service-label-transparent">xxxx</span>}
                  >
                    {getFieldDecorator('endTime', {
                      initialValue: editServices ? moment(editServices.endTime.val, 'h:mm A') : null,
                      rules: [
                        {
                          required: true,
                          message: intl.formatMessage({
                            id: 'orgTabServiceRequireTimeperiodEnd',
                            defaultMessage: 'Please choose your end time.',
                          }),
                        },
                        {
                          validator: compareEndTime,
                        },
                      ],
                    })(
                      <TimePicker
                        className="time-period-timePicker"
                        placeholder={intl.formatMessage({
                          id: 'orgTabServiceModalHintTimeperiodEnd',
                          defaultMessage: 'Please enter end time.',
                        })}
                        use12Hours
                        format={format}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={8} className="services-row-col-time-period">
                  <Form.Item
                    className="label-from"
                    label={<LabeRequire text={<FormattedMessage id="orgTabServiceModalLblSLA" defaultMessage="SLA (min.)" />} req={true} />}
                  >
                    {getFieldDecorator('buffer', {
                      rules: [
                        {
                          required: true,
                          message: intl.formatMessage({
                            id: 'orgTabServiceRequireSLA',
                            defaultMessage: 'Please select your SLA.',
                          }),
                        },
                        {
                          pattern: new RegExp(/^[0-9\b]+$/),
                          message: intl.formatMessage({
                            id: 'orgTabServiceRequireSLAMustNumber',
                            defaultMessage: 'The SLA must only be number.',
                          }),
                        },
                      ],
                      initialValue: buffer,
                    })(
                      <Input
                        placeholder={intl.formatMessage({
                          id: 'orgTabServiceModalHintSLA',
                          defaultMessage: 'Please enter min',
                        })}
                        autoComplete="off"
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Form.Item
                  className="label-from"
                  label={<LabeRequire text={<FormattedMessage id="orgTabServiceModalLblCustomer" defaultMessage="Customer" />} />}
                >
                  {getFieldDecorator('customer', {
                    initialValue: customerIdArr,
                    rules: [
                      {
                        required: false,
                        message: intl.formatMessage({
                          id: 'orgTabServiceRequireCustomer',
                          defaultMessage: 'Please select your customer',
                        }),
                      },
                    ],
                  })(
                    <Select
                      className="addArea-select-tag"
                      placeholder={intl.formatMessage({
                        id: 'orgTabServiceModalHintCustomer',
                        defaultMessage: 'Please select customer',
                      })}
                      mode="multiple"
                      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                      {customerData.map((item, i) => (
                        <Option key={i} value={item.customerId}>
                          {item.customerName}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Row>
            </div>
          </Form>
        </Modal>
      );
    }
  }
);

export default ({
  comId,
  orgId,
  refreshDataServices,
  customerData,
  // editServices,
  // visibleEditModal,
  // selectAreaList,
  // setSelectAreaList,
}) => {
  const intl = useIntl();
  const appGetlang = useAppContext();
  const { stateServicesMain, setStateServicesMain } = useContext(ServicesContext);
  let editServices = stateServicesMain.editServices;
  const langValueState = _.get(appGetlang, 'state.langValue');
  moment.locale(langValueState);

  let serviceCode = editServices.serviceCode;
  const [areaList, setAreaList] = useState([]);
  const [areaListData, setAreaListData] = useState([]);
  const [taskTypes, setTaskTypes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [disButton, setDisButton] = useState(false);

  // const [visible, setVisible] = useState(false);
  // const [selectAreaList, setSelectAreaList] = useState([]);

  const [formRef, setFormRef] = useState(null);
  const [areaCode, setAreaCode] = useState(undefined);
  const [taskTypesCode, setTaskTypesCode] = useState([]);
  const [buffer, setBuffer] = useState();
  const [maxBuffer, setMaxBuffer] = useState();

  useEffect(() => {
    loadServices();
  }, [stateServicesMain.selectAreaList]);

  // useEffect(() => {
  //   if (stateServicesMain.visibleEditModal === true) {
  //     hidePopover();
  //   }
  // }, [stateServicesMain.visibleEditModal]);

  const loadServices = async () => {
    setLoading(true);
    try {
      const result = await httpClient.get(`/organization/services/${comId}/${orgId}`);

      setAreaList(result.data.areaList);
      setAreaListData(result.data.services);
      setTaskTypes(result.data.taskTypes);
      // areaValue(result.data.areaList);
      areaValue(result.data.services);
      taskTypesvalue(result.data.taskTypes);
      setMaxBuffer(result.data.maxBuffer);
      setLoading(false);
    } catch (error) {
      return;
    }
  };

  // const areaValue = (areaListCode) => {

  //   if (editServices) {
  //     areaListCode.filter((element, index, array) => {
  //       if (element.areaName === editServices.areaName) {
  //         setAreaCode(element.areaCode);
  //         setBuffer(editServices.buffer);
  //       }
  //     });
  //   } else {
  //     setAreaCode('');
  //   }
  // };

  const areaValue = (services) => {
    if (editServices) {
      services.filter((element) => {
        if (element.areaName === editServices.areaName) {
          // setAreaCode(element.serviceCode);
          setAreaCode(element.areaName);
          setBuffer(editServices.buffer);
        }
      });
    } else {
      setAreaCode(undefined);
    }
  };

  const taskTypesvalue = (taskTypesCode) => {
    if (editServices) {
      const taskCodeArr = [];
      editServices.taskTypes.forEach((element, index, array) => {
        taskCodeArr.push(element.taskTypeCode);
      });
      setTaskTypesCode(taskCodeArr);
    } else {
      setTaskTypesCode([]);
    }
  };

  const onChange = async (value) => {
    if (value) {
      try {
        const result = await httpClient.get(`/companies/master-areas/${value}`);

        if (result.status === 200) {
          result.data.filter((element) => {
            if (element.areaCode === value) {
              setStateServicesMain.setSelectAreaList(element.coordinates);
            }
          });
        }
      } catch {
        return;
      }
    }
  };

  const handleEdit = async () => {
    formRef.validateFields(async (err, values) => {
      if (err) {
        return;
      }

      // const area = areaListData.filter((item) => {
      //   return parseInt(item.serviceCode) === parseInt(values.area);
      // });
      console.log('areaCode',values.area);
      const area = areaList.filter((item) => {
        return item.areaCode === values.area;
      });

      

      const data = {
        comCode: parseInt(comId),
        orgCode: parseInt(orgId),
        buffer: parseInt(values.buffer),
        name: values.name,
        // areaCode: area.areaName,
        areaCode: _.get(area[0],'areaCode'),
        // areaCode: area[],
        taskTypeId: values.category,
        startTime: moment(values.startTime).format('HH:mm:ss'),
        endTime: moment(values.endTime).format('HH:mm:ss'),
        customerId: values.customer,
      };

      try {
        const result = await httpClient.put(`/organization/services/${serviceCode}`, data);

        if (result.status === 200) {
          createAreaSuccess(result.data);
          refreshDataServices();
        } else {
          createAreaError(result.data);
        }
      } catch (error) {}
      formRef.resetFields();
      setStateServicesMain.setSelectAreaList([]);
      setStateServicesMain.setVisibleEditModal(false);
    });
  };

  const createAreaSuccess = (message) => {
    notification.success({
      message: message,
    });
  };

  const createAreaError = (message) => {
    notification.error({
      message: message,
    });
  };

  const saveFormRef = useCallback((node) => {
    if (node !== null) {
      setFormRef(node);
    }
  }, []);

  const showModal = (e) => {
    e.preventDefault();
    if (editServices) {
      setStateServicesMain.setSelectAreaList(editServices.coordinates);
    }
  };

  const handleCancel = (e) => {
    formRef.validateFields(async (err, values) => {
      if (err) {
        return;
      }
      formRef.resetFields();
      setStateServicesMain.setSelectAreaList([]);
    });
    formRef.resetFields();
    setStateServicesMain.setSelectAreaList([]);

    // setVisible(false);
    setStateServicesMain.setVisibleEditModal(false);
    setTimeout(() => setStateServicesMain.setEditServices(null), 1500);
  };

  return (
    <PageSettings.Consumer>
      {({ checkPermissionAction }) => (
        <div>
          {/* {checkPermissionAction('P3PG1C6', 'P3PG1C6A3') ? (
            <Button01
              className="styledPopDelete"
              btnsize="wd_df"
              type="link"
              ghost
              onClick={(e) => showModal(e)}
            >
              <FormattedMessage id="orgTabServiceBtnEdit" defaultMessage="Edit" />
            </Button01>
          ) : null
          } */}

          <CollectionCreateForm
            stateServicesMain={stateServicesMain}
            areaList={areaList}
            areaListData={areaListData}
            taskTypes={taskTypes}
            editServices={editServices}
            maxBuffer={maxBuffer}
            areaCode={areaCode}
            taskTypeCode={taskTypesCode}
            buffer={buffer}
            ref={saveFormRef}
            customerData={customerData}
            onChange={(value) => onChange(value)}
            onCancel={() => handleCancel()}
            onCreate={() => handleEdit()}
            loading={loading}
            intl={intl}
            langValueState={langValueState}
          />
        </div>
      )}
    </PageSettings.Consumer>
  );
};
