import React, { useState, useEffect, useContext } from 'react';
import { Card, Row, Col, Modal } from 'antd';
import ServiceArea from './service-area';
import Customization from './customization';
import getServiceArea from '../../controllers/service-area/get-service-area';
import getServiceAreaById from '../../controllers/service-area/get-service-area-byid';
import { PageSettings } from '../../config/page-settings';

import CradDefault from '../../components/crad-default/crad-default.jsx';
import { RiBuildingLine } from 'react-icons/ri';
import _ from 'lodash';
import styles from './css/index.css';

export const ServiceAreaContext = React.createContext();

export default (props) => {
  const [serviceAreaData, setServiceAreaData] = useState([]);
  const [serviceAreaDataById, setServiceAreaDataById] = useState({});
  const [serviceAreaId, setServiceAreaId] = useState(null);
  const [actionAddNew, setActionAddNew] = useState('default');
  const [triggerApi, setTriggerApi] = useState(true);
  const [areaName, setAreaName] = useState('');

  useEffect(() => {
    async function getApiById() {
      if (serviceAreaId) {
        const response = await getServiceAreaById({ serviceAreaId });
        setServiceAreaDataById(response.areaMasterDetail);
        setAreaName(_.get(response, 'areaMasterDetail.areaName'));
      }
    }
    getApiById();
  }, [serviceAreaId, triggerApi]);

  useEffect(() => {
    async function getAPI() {
      const response = await getServiceArea();
      setServiceAreaData(response.areaMasterList);
    }
    getAPI();
  }, [triggerApi]);

  const fncAddNewArea = (e) => {
    setActionAddNew('create');
    setServiceAreaId(null);
    setServiceAreaDataById({
      areaCode: '',
      areaMasterId: '',
      areaName: '',
      comCode: '',
      coordinates: [],
    });
  };

  return (
    <div className="setting-service-area">
      <PageSettings.Consumer>
        {({ checkPermissionAction }) =>
          checkPermissionAction('P18PG1', 'P18PG1A1') ? (
            <ServiceAreaContext.Provider
              value={{
                mainState: {
                  serviceAreaData,
                  serviceAreaId,
                  serviceAreaDataById,
                  actionAddNew,
                  triggerApi,
                  areaName,
                },
                mainSetState: {
                  setServiceAreaData,
                  setServiceAreaId,
                  setActionAddNew,
                  setTriggerApi,
                  setServiceAreaDataById,
                  setAreaName,
                },
                mainFnc: {
                  fncAddNewArea,
                },
              }}
            >
              <div className="setting-service-area-container">
                <Row gutter={[16, 16]}>
                  <Col span={6}>
                    <ServiceArea />
                  </Col>
                  <Col span={18}>{checkPermissionAction('P18PG1C1', 'P18PG1C1A1') ? <Customization /> : null}</Col>
                </Row>
              </div>
            </ServiceAreaContext.Provider>
          ) : null
        }
      </PageSettings.Consumer>
    </div>
  );
};
