import React from 'react';
import { Tag, Avatar, Icon } from 'antd';
import _ from 'lodash';
import './css/index.css';
import {FormattedMessage } from 'react-intl';

export default ({ data, fromPage }) => {

  const customer = _.get(data, 'customerName');
  const sale = _.get(data, 'sale');
  const createDate = _.get(data, 'createDate');
  const statusView = _.get(data, 'statusView');
  const statusColor = _.get(data, 'statusColor');
  const profileCustomer = _.get(data, 'profileCustomer');

  return (
    <div className="view-header">
      <div className="view-card-head">
        <div className="modal-view-card-head-left">
          <div className="modal-view-card-head-left-avatar">
            <Avatar size={52} src={profileCustomer} />
          </div>
          <div className="modal-view-card-left-text">
            <div className='modal-view-card-left-text-p'>
              <p className="modal-view-card-left-text-head">
                {customer}
              </p>
              <p className="modal-view-card-left-text-dot">.</p>
              <p className="modal-view-card-text-12">
              <FormattedMessage id="ModalViewQuotationHeaderCreateBy" defaultMessage="Create by" />  {sale} <FormattedMessage id="ModalViewQuotationHeaderOn" defaultMessage="on" /> {createDate}
              </p>
            </div>
            <div>
              <Tag className='status-tag-view' color={statusColor}>{statusView}</Tag>
            </div>
          </div>

        </div>
        <div className="modal-view-card-head-right">
        </div>
      </div>
    </div>
  )
}