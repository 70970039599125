import React from 'react';
import { Col, Form, Modal, Row, Select, Input } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';

import _ from 'lodash';
import LabeRequireForm from '../../label-required-form';
import Button_02 from '../../v2/button_02';
import Button_01 from '../../v2/button_01';
import { createBrandData } from '../../../controllers/createBrandModel';
import { errorNotification, successNotification } from '../../v2/notification';

const { Option } = Select;
const { TextArea } = Input;

const CreateModelBrand = ({ form, title, visible, onOk, onCancel, setEvent, recordBrand, setRecordBrand }) => {
  const { getFieldDecorator, resetFields } = form;
  const intl = useIntl();

  console.log('recordBrand', recordBrand)

  const handleSave = () => {
    form.validateFields(async (err, values) => {
      console.log('values', values);
      if (err) {
        return;
      }

      const payload = {
        itemBrandId: _.get(recordBrand, 'itemBrandId') ? _.get(recordBrand, 'itemBrandId') : undefined,
        itemBrandName: _.get(values, 'itemBrandName'),
        itemBrandNo: _.get(values, 'itemBrandNo'),
        itemBrandDescription: _.get(values, 'itemBrandDescription'),
        active: 'true',
      };

      try {
        const response = await createBrandData(payload);
        setRecordBrand(response.data)

        console.log('setRecordBrand', response, recordBrand)

        if (_.get(response, 'status.code') === 200) {
          successNotification(response.status.message);
          handleCancel();
          setEvent((prev) => !prev);
        } else {
          errorNotification(_.get(response, 'status.message'));
        }
      } catch (error) {
        errorNotification(error.response.data.message);
      }

      // console.log('payload', payload);

      // onOk(payload);
    });
  };

  const handleCancel = () => {
    onCancel();
    resetFields();
  };

  const setReasonOption = (reason) => {
    return (
      <Option key={`${reason.reasonId}`} value={`${reason.reasonId}`}>
        {reason.titleCode}
      </Option>
    );
  };

  return (
    <Modal
      title={title}
      visible={visible}
      onOk={handleSave}
      onCancel={onCancel}
      width={500}
      centered={true}
      zIndex={1200}
      footer={[
        <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={() => handleCancel()}>
          <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
        </Button_02>,
        <Button_01 key="submit" type="primary" btnsize="wd_df" onClick={() => handleSave()}>
          <FormattedMessage id="btnSave" defaultMessage="Save" />
        </Button_01>,
      ]}
    >
      <Form name="status" colon={false}>
        <Row gutter={[24]}>
          <Col span={24}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'modalCreateBrandBrandCode', defaultMessage: 'Brand Code' })} req={true} />
              }
            >
              {getFieldDecorator('itemBrandNo', {
                initialValue: _.get(recordBrand, 'itemBrandNo') ? _.get(recordBrand, 'itemBrandNo') : '',
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'modalCreateBrandBrandCodeValidate', defaultMessage: 'Please Enter Brand Code' }),
                  },
                ],
              })(
                <Input
                  placeholder={intl.formatMessage({ id: 'modalCreateBrandBrandCodePlaceholder', defaultMessage: 'Enter Brand Code' })}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24]}>
          <Col span={24}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'modalCreateBrandBrandName', defaultMessage: 'Brand Name' })} req={true} />
              }
            >
              {getFieldDecorator('itemBrandName', {
                initialValue: _.get(recordBrand, 'itemBrandName') ? _.get(recordBrand, 'itemBrandName') : '',
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'modalCreateBrandBrandNameValidate', defaultMessage: 'Please Enter Brand Name' }),
                  },
                ],
              })(
                <Input
                  placeholder={intl.formatMessage({ id: 'modalCreateBrandBrandNamePlaceholder', defaultMessage: 'Enter Brand Name' })}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24]}>
          <Col span={24}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'modalCreateBrandRemark', defaultMessage: 'Remark' })}
                  req={false}
                />
              }
            >
              {getFieldDecorator('itemBrandDescription', {
                initialValue: _.get(recordBrand, 'itemBrandDescription') ? _.get(recordBrand, 'itemBrandDescription') : '',
              })(
                <TextArea
                  autoSize={{ minRows: 3, maxRows: 3 }}
                  maxLength={250}
                  placeholder={intl.formatMessage({ id: 'modalCreateBrandRemarkPlaceholder', defaultMessage: 'Enter Remark' })}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

CreateModelBrand.defaultProps = {
  title: 'Select Customer',
  // title: intl.formatMessage({ id: 'quotationLabelSelectCustomer', defaultMessage: 'Select Customer' }),
  visible: false,
  onOk: () => console.warn('onOk not function '),
  onCancel: () => console.warn('onCancel not function '),
};

const ModalCreateBrandForm = Form.create({ name: 'reject_Form' })(CreateModelBrand);

export default ModalCreateBrandForm;
