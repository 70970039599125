import React, { useState } from 'react';
import { Card, Row, Col } from 'antd';
import { FormattedMessage } from 'react-intl';
import './css/blankAssetClass.css';
import NodataIMG from '../../../../../components/image/Nodata_web.svg';

const BlankAssetReport = () => {
  return (
    <div className="container-blank-nodata-report">
      <div className="div-blank-nodata">
        <div>
          <img src={NodataIMG} style={{ width: '220px' }}></img>
        </div>
        <span className="header-blank-asset">
          <FormattedMessage id="assetCardListNoAssets" defaultMessage="No Assets" />
        </span>
        <span className="body-blank-asset">
          <FormattedMessage id="assetCardListAssetwillappearhere" defaultMessage="Asset will appear here." />
        </span>
      </div>
    </div>
  );
};

export default BlankAssetReport;
