import React, { useRef, useCallback, useContext } from 'react';
import { GoogleMap, LoadScript, useLoadScript, Polyline, Marker } from '@react-google-maps/api';
import { useIntl, FormattedMessage } from 'react-intl';
import _ from 'lodash';
import '../css/index.css'

export default (props) => {
  const { markerPointView, polylineDataView } = props
  const libraries = ["places"];

  const center = {
    lat: _.get(markerPointView[1], 'lat'),
    lng: _.get(markerPointView[1], 'lng')
  };

  const mapContainerStyle = {
    width: '100%',
    height: '270px',
    position: 'relative'
  };

  const defaultMapOption = {
    fullscreenControl: false,
    fullscreenControlOptions: false,
    streetViewControl: false,
    clickableIcons: false,
    zoomControl: true,
    gestureHandling: "cooperative",
    mapTypeControl: true,
    mapTypeControlOptions: {
      style: 1,
      position: 3,
    },
  };

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: localStorage.getItem('GEO_APIKEY'),
    libraries,
  });
  const mapRef = useRef();
  const onMapLoad = useCallback((map) => {
    mapRef.current = map
  }, []);

  if (loadError) return "Error loading maps";
  if (!isLoaded) return "Loading Maps";

  const options = {
    strokeColor: '#1890ff',
    strokeOpacity: 0.8,
    strokeWeight: 6,
    fillColor: '#1890ff',
    fillOpacity: 1,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: 30000,
    zIndex: 1
  };

  return (
    <div>
      <GoogleMap
        id='map-view'
        mapContainerStyle={mapContainerStyle}
        center={center}
        zoom={12}
        onLoad={onMapLoad}
        options={defaultMapOption}
      >
        {polylineDataView !== undefined ?
          (<div className='modal-task-detail-map-distance-value'>
            <FormattedMessage id="monitorCreateTaskTxtDistance" defaultMessage="Distance" />  {_.get(polylineDataView, 'distance')}
          </div>) : null
        }

        <Polyline
          path={_.get(polylineDataView, 'polyline') || []}
          options={options}
        />

        {markerPointView && markerPointView.map((item) => (
          <Marker
            key={item.key}
            position={item}
            icon={{
              url: item.markerSvg,
              scaledSize: new window.google.maps.Size(40, 40),
            }}
          />
        ))}
      </GoogleMap>
    </div>
  );
}