import { Modal } from 'antd';
import React from 'react';
import Button_01 from '../../../../components/v2/button_01';
import { useIntl, FormattedMessage } from 'react-intl';

function ModalCreate(props) {
  const { showModal, onCloseModal } = props;

  const handleSubmit = (params) => {
    onCloseModal();
  };

  return (
    <Modal
      title="Create Myrequest"
      visible={showModal}
      centered="true"
      width={740}
      onOk={onCloseModal}
      onCancel={onCloseModal}
      footer={[
        <Button_01 key="submit" style={{ marginRight: '-3px' }} onClick={handleSubmit}>
          <FormattedMessage id="userMgntUserModalBtnCancel" defaultMessage="Cancel" />
        </Button_01>,
        <Button_01 key="submit" type="pimary" onClick={handleSubmit}>
          <FormattedMessage id="btnSave" defaultMessage="Save" />
        </Button_01>,
      ]}
    >
      modalCreate
    </Modal>
  );
}

export default ModalCreate;
