import React, { useContext, useEffect } from 'react';
import { Divider, Menu, Table } from 'antd';
import '../css/index.css';
import Button01 from '../../../components/v2/button_01';
import Button02 from '../../../components/v2/button_02';
import { PlanningContext } from '../context';
import _ from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';
import ButtonActionGroup from '../../../components/action-button';
import { DndContext } from '@dnd-kit/core';
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import update from 'immutability-helper';
import { GridComponent, ColumnsDirective, ColumnDirective, Selection, RowDD, Inject } from '@syncfusion/ej2-react-grids';

export default (props) => {
  const intl = useIntl();
  const { column, Data } = props;
  const { state, setState, fnc } = useContext(PlanningContext);
  const { dataById, isDragorder, dragOrder } = state;
  const { setDataById, setIsDragorder, setDragOrder } = setState;
  const { handleDragOrderList } = fnc;
  const dataSource = _.get(Data, 'orderDetail');

  console.log('state.dataById', dataById,Data);

  // useEffect(() => {
  //     setDragOrder(dataSource)
  //     console.log('dragOrder',dragOrder);
  //     if (_.get(dataSource, 'length') !== 0 ) {
  //       handleDragOrderList();
  //     console.log('dataByIdDrag', _.get(dataById, 'orderDetail'));
  //     }

  //   console.log('dataByIdDrag', _.get(dataById, 'orderDetail'));
  // }, []);

  const menu = (
    <Menu>
      {_.get(state.disbleButton, 'dataEnableButtonRePlan') === true ? (
        <Menu.Item key="0">
          <p onClick={() => fnc.handleReCalculate(_.get(state.dataById, 'assetId'))}>
            <FormattedMessage id="buttonRePlanning" defaultMessage="Re-Planning" />
          </p>
        </Menu.Item>
      ) : null}
      {_.get(state.dataById, 'sending') === 'vehicle_waiting' ? (
        <Menu.Item key="1">
          <p onClick={() => setState.setVisibleAddOrRemove(true)}>
            <FormattedMessage id="buttonAddorRemove" defaultMessage="Add or Remove" />
          </p>
        </Menu.Item>
      ) : null}
    </Menu>
  );

  // function DraggableTable(index, record, columnId, tasks, ...props) {
  //   const [{ isDragging }, drag] = useDrag({
  //     // item: { type: 'TABLE' },
  //     type: 'TABLE',
  //     collect: (monitor) => ({
  //       isDragging: monitor.isDragging(),
  //     }),
  //   });

  //   return (
  //     <div ref={drag} style={{ opacity: isDragging ? 0.5 : 1 }}>
  //       {record}
  //     </div>
  //   );
  // }

  const Row = (props) => {
    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
      id: props['data-row-key'],
    });
    const style = {
      ...props.style,
      transform: CSS.Transform.toString(
        transform && {
          ...transform,
          scaleY: 1,
          scaleX: 1,
        }
      ),
      transition,
      cursor: 'move',
      ...(isDragging
        ? {
            // position: 'relative',
            zIndex: 9999,
          }
        : {}),
    };
    return <tr {...props} ref={setNodeRef} style={style} {...attributes} {...listeners} />;
  };

  const onDragEnd = async ({ active, over }) => {
    if (active.id !== over?.id) {
      const activeIndex = dataSource.findIndex((i) => i.index === active.id);
      const overIndex = dataSource.findIndex((i) => i.index === over?.id);
      await setDataById((prev) => ({
        ...prev,
        orderDetail: arrayMove(prev.orderDetail, activeIndex, overIndex),
      }));
      if (_.get(dataById, 'sending') === 'vehicle_sending') {
      } else {
        handleDragOrderList(arrayMove(dataSource, activeIndex, overIndex));
      }
    }
  };

  //   useEffect(() => {

  //     // if (_.get(dataSource, 'length') !== 0 ) {
  //       if (isDragorder === true) {
  //         handleDragOrderList();
  //         console.log('dataByIdDrag', _.get(dataById, 'orderDetail'));
  //       }

  //     // }

  // }, [isDragorder]);

  return (
    <div>
      <div className="detail-order-div-padding">
        <div className="detail-order-text-header">
          <span className="detail-order-text-header" style={{ marginRight: 4 }}>
            <FormattedMessage id="planningTextDeliveryOrders" defaultMessage="Delivery Orders" />
          </span>
          {_.get(state.dataById, 'tripNo') ? (
            <span className="detail-order-text-header">
              ( <FormattedMessage id="planningTextTripNo" defaultMessage="Trip No." /> {_.get(state.dataById, 'tripNo') || '-'} )
            </span>
          ) : null}
        </div>
      </div>
      <Divider type="horizontal" style={{ margin: '12px 0px' }} />
      {_.get(dataById, 'sending') === 'vehicle_sending' ? (
        <div style={{ padding: '0px' }}>
          <Table
            className="scroll-sm"
            columns={column}
            rowKey="index"
            onChange={fnc.handleChangeorderDetail}
            dataSource={dataSource}
            pagination={false}
            scroll={{ x: '2450px', y: 'calc(100vh - 530px)' }}
          />
        </div>
      ) : (
        <DndContext onDragEnd={onDragEnd}>
          <SortableContext items={_.map(dataSource, (i) => i.index)} strategy={verticalListSortingStrategy}>
            <div style={{ padding: '0px' }}>
              <Table
                className="table-waiting-order scroll-sm"
                columns={column}
                components={{
                  body: {
                    row: Row,
                  },
                }}
                rowKey="index"
                onChange={fnc.handleChangeorderDetail}
                dataSource={dataSource}
                pagination={false}
                scroll={{ x: '2450px', y: 'calc(100vh - 530px)' }}
              />
            </div>
          </SortableContext>
        </DndContext>
      )}
    </div>
  );
};
