import React, { useState, useEffect, useRef, useContext } from 'react';
import { Modal, Form, Divider, Table, Row, Col, DatePicker, Select, Input, AutoComplete, Popover, Button, Icon, Switch } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import _ from 'lodash';
import moment from 'moment';
import { getVendor } from '../../../../controllers/customer-vendor/customer-vendor';
import { useDebounce } from '../../../../controllers/debounce';
import { fncGetUsers } from '../../../../controllers/user/users';
import LabeRequireForm from '../../../../components/label-required-form';
import Button_01 from '../../../../components/v2/button_01';
import Button_02 from '../../../../components/v2/button_02';
import { VoucherContext } from '../../voucher-context';
import { errorNotification, successNotification } from '../../../../components/v2/notification';
import { getMemberIssueBy } from '../../../../controllers/quotation/quotation-api-new';
import { getCustomerLoadMore } from '../../../../controllers/getCustomer/get-customer-new';
import { handleKeyDownNumber } from '../../../../component-function/fnc-number';
import { numberFormatter } from '../../../../component-function/fnc-inputnumber-currency';
import { getVoucherDetail, saveVoucher } from '../../../../controllers/voucher/voucher';
import LabeRequireFormHint from '../../../../components/label-required-form/LabeRequireFormHint';

const { Option } = Select;
const { TextArea } = Input;

const ModalCreateVoucher = (props) => {
  const { state, setState, fnc } = useContext(VoucherContext);
  const { visible, setVisible, form } = props;
  const { memberList, brandList, dataModel, brandId, voucherId, toggleActive, customerPage, visibleView } = state;
  const {
    setBrandId,
    setTotalBrand,
    setTrigger,
    setTypeView,
    setVoucherId,
    setBrandList,
    setToggleActive,
    setCustomerPage,
    setCustomerLoading,
    setIsFetch,
  } = setState;
  const { handleBrandPopupScroll, handleModelPopupScroll, handleCustomerPopupScroll, handleSearchModel, handleSearchBrand } = fnc;
  const { getFieldDecorator, setFieldsValue, resetFields, validateFields, getFieldsValue, getFieldValue } = form;
  const intl = useIntl();

  // console.log('dataModel', dataModel)

  const ref = useRef();
  const dateFormat = 'DD/MM/YYYY'

  const [lotNo, setLotNo] = useState([]);
  const [itemCodeList, setItemCodeList] = useState([]);
  const [itemNameList, setItemNameList] = useState([]);
  const [userData, setUserData] = useState(memberList);
  const [rowsOfPageMemberList, setRowsOfPageMemberList] = useState(true)
  const [vendorData, setVendorData] = useState([]);
  const [podata, setPOdata] = useState([]);
  const [viewData, setViewData] = useState([]);
  const [brandSelectAll, setBrandSelectAll] = useState(true);
  const [modelSelectAll, setModelSelectAll] = useState(true);
  //---------------------------- Select List ---------------------------------//

  // console.log('viewData', viewData, userData);

  useEffect(() => {
    async function getUser() {
      try {
        const pageStart = 1;
        const pageEnd = customerPage;

        if (rowsOfPageMemberList) {
          const data = await getCustomerLoadMore(pageStart, pageEnd);

        const company = [
          {customerId: localStorage.getItem('comId'), customerName: localStorage.getItem('comCode') + ' ' + localStorage.getItem('comName')}
        ]
          const custommer = _.get(data, 'customers')
          const allCustommer = [
            {customerId: "*", customerName: intl.formatMessage({ id: 'allCustomer', defaultMessage: 'All Customer' }) }
          ]
  
          const list1 = allCustommer.concat(custommer)
          const list2 = list1.concat(company)
  
          setUserData(list2);
          setRowsOfPageMemberList(data.rowsOfPage === data.allCustomers ? false : true)
          // setUserData(custommer);
  
          setCustomerLoading(false);
        }

        
      } catch (error) {}
    }
    if (visible) {
      getUser();
    }
  }, [visible, customerPage]);

  useEffect(() => {
    async function getVoucher() {
      try {
        const responase = await getVoucherDetail(voucherId);
        setViewData(_.get(responase, 'data'));
        setToggleActive(_.get(responase, 'data.suspend'))
      } catch (error) {}
    }
    if (visible && voucherId !== '') {
      getVoucher();
    }
  }, [visible]);

  useEffect(() => {
    if (_.size(podata) > 0) {
      setFieldsValue({ ['purchase']: _.get(podata, 'poNo') });
    }
  }, [podata]);

  const handleSave = () => {
    validateFields(async (err, values) => {
      if (err) {
        return;
      }
      const brandFilter = _.filter(brandList, (item) => {
        return item.item_brand_id === _.get(values, 'brand');
      });
      const modelFilter = _.filter(dataModel, (item) => {
        return item.item_model_id === _.get(values, 'model');
      });
      const body = {
        voucherId: _.get(viewData, 'voucherId') ? _.get(viewData, 'voucherId') : undefined,
        voucherName: values.Vouchername,
        ownerId: _.get(viewData, 'voucherId') ? undefined : values.customer,
        itemBrandList: _.get(viewData, 'voucherId') ? undefined : brandSelectAll === false ? brandFilter : [],
        itemModelList: _.get(viewData, 'voucherId') ? undefined : modelSelectAll === false ? modelFilter : [],
        isAllBrand: _.get(viewData, 'voucherId') ? undefined : brandSelectAll,
        isAllModel: _.get(viewData, 'voucherId') ? undefined : modelSelectAll,
        voucherAmount: _.get(viewData, 'voucherId') ? undefined : values.voucheramount,
        reference: values.reference,
        effectiveDate: moment(values.effectivedate).format('YYYY-MM-DD'),
        expiredDate: moment(values.expireddate).format('YYYY-MM-DD'),
        remark: values.remark,
        suspend: toggleActive,
        ownerType: _.get(viewData, 'ownerType') ? _.get(viewData, 'ownerType') : _.get(values, 'customer') === localStorage.getItem('comId') ? 'company' : 'customer',
        minSpend: _.get(values, 'voucherMinSpend'),
        minUse: _.get(values, 'voucherMinUse'),
        maxUse: _.get(values, 'voucherMaxUse'),
      };
      try {
        const response = await saveVoucher(body);

        if(response.status === 200) {
          successNotification(response.data.status.message);
          setVisible(false);
          resetFields();
          setBrandSelectAll(true);
          setModelSelectAll(true);
          setLotNo([]);
          setPOdata([]);
          setToggleActive(false);
          setCustomerPage([]);
          setViewData([]);
          setTrigger((current) => !current);
          if (visibleView === true) {
            setIsFetch((current) => !current);
          }
          setVoucherId('');
        } else if (response.status === 400) {
          errorNotification(response.data.status.message);
        }

      } catch (error) {
        // console.log('error', error)
        // errorNotification(error.response.status.message);
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
    resetFields();
    setLotNo([]);
    setPOdata([]);
    setTypeView([]);
    setViewData([]);
    setCustomerPage([]);
    setToggleActive(false);
    setVoucherId('');
    setBrandSelectAll(true);
    setModelSelectAll(true);
  };

  const validatorVoucherAmount = (rule, value, callback) => {

    const voucherAmount = parseFloat(value)
    const minUse = parseFloat(getFieldValue('voucherMinUse'))
    const maxUse = parseFloat(getFieldValue('voucherMaxUse'))

    if (value) {
      if(voucherAmount < minUse || voucherAmount < maxUse) {
        // callback('Voucher Amount less than Min Use or Man Use')
        validateFields(['voucherMinUse'])
        validateFields(['voucherMaxUse'])
      } else {
        callback()
        validateFields(['voucherMinUse'])
        validateFields(['voucherMaxUse'])

      }
    } else {
      callback()
      validateFields(['voucherMinUse'])
      validateFields(['voucherMaxUse'])

    }
  }

  const validatorMinSpend = (rule, value, callback) => {

    const voucherAmount = parseFloat(getFieldValue('voucheramount'))
    const minSpend = parseFloat(value)

    if (value) {
      if(minSpend > voucherAmount) {
        callback('Min Spend more than Voucher Amount')
      } else {
        callback()
      }
    } else {
      callback()
    }
  }

  const validatorMinUse = (rule, value, callback) => {

    const voucherAmount = parseFloat(getFieldValue('voucheramount'))
    const maxUse = parseFloat(getFieldValue('voucherMaxUse'))
    const minUse = parseFloat(value)

    if (value) {
      if(minUse > voucherAmount) {
        callback('Min Use more than Voucher Amount')
      } else if (maxUse && minUse > maxUse) {
        callback('Min Use more than MaxUse')
      } else {
        callback()
        validateFields(['voucherMinUse'])
      }
    } else {
      validateFields(['voucherMaxUse'])
      callback()
    }
  }

  const validatorMaxUse = (rule, value, callback) => {

    const voucherAmount = parseFloat(getFieldValue('voucheramount'))
    const minUse = parseFloat(getFieldValue('voucherMinUse'))
    const maxUse = parseFloat(value)

    if (value) {
      if(maxUse > voucherAmount) {
        callback('Max Use more than Voucher Amount')
      } else if (minUse > maxUse) {
        callback('Min Use more than Max Use')
      } else {
        callback()
        validateFields(['voucherMinUse'])
      }
    } else {
      validateFields(['voucherMinUse'])
      callback()
    }
  }



  const checkView = [
    <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleCancel}>
      <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
    </Button_02>,

    <Button_01
      key="submit"
      type="primary"
      btnsize="wd_df"
      onClick={() => {
        handleSave();
      }}
    >
      <FormattedMessage id="btnSave" defaultMessage="Save" />
    </Button_01>,
  ];

  const handleChangeBrand = (e) => {
    if (e === undefined) {
      setFieldsValue({ ['brand']: 'Vouchername' });
    }
  };

  const handleChangeSwitch = (e) => {
    setToggleActive(!e);
  };

  const handleAmount = (e, value) => {
    if (parseFloat(value) <= 0) {
      e.target.value = parseFloat(1);
    }
    // setFieldsValue({ ['voucherMinSpend']: null });
    // setFieldsValue({ ['voucherMinUse']: null });
    // setFieldsValue({ ['voucherMaxUse']: null });

  };

  const disabledDate = (current) => {
    // Can not select days before today and today
    let customDate = moment(getFieldsValue(['effectivedate']).effectivedate).format('YYYY-MM-DD');
    return current < moment(customDate, 'YYYY-MM-DD');
  };

  const handlechangeDate = (value) => {
    let dateOne = moment(value).format('YYYY-MM-DD');
    let datetwo = getFieldsValue(['expireddate']).expireddate;
    let result = value.diff(datetwo, 'days');

    if (result > 0) {
      setFieldsValue({
        ['expireddate']: value,
      });
    }
  };

  const handleKeyDownNumberDecimal = (e) => {
    let keyCode = e.key.charCodeAt(0);

    const splitValue = _.split(e.target.value, '.');

    if ((keyCode >= 48 && keyCode <= 57) || keyCode === 65 || keyCode === 66 || keyCode === 46) {
      if (keyCode === 46 && e.target.value.indexOf('.') > 1) {
        e.preventDefault();
      }
      if (keyCode === 46) {
        let sumDot = _.sumBy(e.target.value, (o) => {
          return o.charCodeAt(0) === 46 ? 1 : 0;
        });
        if (sumDot >= 1) {
          e.preventDefault();
        }
      }
    } else if (keyCode === 45) {
      e.preventDefault();
    } else {
      e.preventDefault();
    }

    if (_.get(splitValue, '[1]')) {
      if (_.size(splitValue[1]) >= 2 && keyCode !== 66 && e.target.selectionStart > e.target.value.indexOf('.')) {
        e.preventDefault();
      }
    }
  };

  return (
    <Modal
      title={
        _.get(viewData, 'voucherId')
          ? intl.formatMessage({ id: 'VoucherEditVoucher', defaultMessage: 'Edit Voucher' }) + ' · ' + _.get(viewData, 'voucherNo')
          : intl.formatMessage({ id: 'VoucherCreateVoucher', defaultMessage: 'Create Voucher' })
      }
      visible={visible}
      onCancel={handleCancel}
      width={1232}
      centered={true}
      style={{ marginTop: '25px' }}
      bodyStyle={{ padding: 'unset', overflowY: 'auto', height: 'calc(100vh - 236px)' }}
      footer={checkView}
      zIndex={999}
    >
      <div>
        {_.get(viewData, 'voucherId') && _.get(viewData, 'statusCode') !== 'expired' ? (
          <>
            <div className="item-title">
              <span className="item-title-text">
                <FormattedMessage id="quotationLabelDetails" defaultMessage="Details" />
              </span>

              <div style={{ paddingTop: '3px' }}>
                <Switch value={toggleActive} onChange={handleChangeSwitch} defaultChecked={!_.get(viewData, 'suspend')} />
              </div>
            </div>

            <Divider type="horizontal" style={{ marginBottom: '10px', marginTop: '10px' }} />
          </>
        ) : null}

        <div style={{ padding: '24px' }}>
          <Form form={form} layout="vertical" className="modalReceiveItemForm">
            <Row gutter={[24, 24]}>
              <Col span={16}>
                <Form.Item
                  label={
                    <LabeRequireForm
                      text={intl.formatMessage({ id: 'VoucherName', defaultMessage: 'Voucher Name' })}
                      req={_.size(viewData) > 0 ? false : true}
                    />
                  }
                >
                  {getFieldDecorator('Vouchername', {
                    initialValue: _.get(viewData, 'voucherName') ? _.get(viewData, 'voucherName') : undefined,
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({ id: 'VoucherNameValidate', defaultMessage: 'Please Enter Voucher Name' }),
                      },
                    ],
                  })(<Input placeholder={intl.formatMessage({ id: 'VoucherNamePlaceHolder', defaultMessage: 'Enter Voucher Name' })} />)}
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label={
                    <LabeRequireForm
                      text={intl.formatMessage({ id: 'voucherVoucherAmount', defaultMessage: 'Voucher Amount' })}
                      req={_.size(viewData) > 0 ? false : true}
                    />
                  }
                >
                  {getFieldDecorator('voucheramount', {
                    initialValue: _.get(viewData, 'total') ? _.get(viewData, 'total') : numberFormatter(0),
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({
                          id: 'voucherVoucherAmountValidate',
                          defaultMessage: 'Please Enter Voucher Amount',
                        }),
                      },
                      {
                        validator: validatorVoucherAmount
                      }
                    ],
                  })(
                    <Input
                      placeholder={intl.formatMessage({ id: 'voucherVoucherAmountPlaceHolder', defaultMessage: 'Enter Voucher Amount' })}
                      onChange={(e) => handleAmount(e, e.target.value)}
                      disabled={_.get(viewData, 'totalTxt') ? true : false}
                      onKeyDown={handleKeyDownNumberDecimal}
                      suffix={intl.formatMessage({ id: 'quotationLabelTHB', defaultMessage: 'THB' })}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[24, 24]}>
              <Col span={8}>
                <Form.Item
                  label={
                    <>
                    <LabeRequireForm
                      text={intl.formatMessage({ id: 'voucherMinSpend', defaultMessage: 'Min Spend' })}
                      req={true}
                    />  <LabeRequireFormHint text={intl.formatMessage({ id: 'voucherPerOrder', defaultMessage: '(Per Order)' })}/>
                    </>
                  }
                >
                  {getFieldDecorator('voucherMinSpend', {
                    initialValue: _.get(viewData, 'minSpend') ? _.get(viewData, 'minSpend') : numberFormatter(0),
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({
                          id: 'voucherMinSpendValidate',
                          defaultMessage: 'Please Enter Min Spend',
                        }),
                      },
                      // {
                      //   validator: validatorMinSpend
                      // }
                    ],
                  })(
                    <Input
                      // onChange={(e) => handleAmount(e, e.target.value)}
                      // disabled={_.get(viewData, 'totalTxt') ? true : false}
                      placeholder={intl.formatMessage({ id: 'voucherMinSpendPlaceHolder', defaultMessage: 'Enter Min Spend' })}
                      onKeyDown={handleKeyDownNumberDecimal}
                      suffix={intl.formatMessage({ id: 'quotationLabelTHB', defaultMessage: 'THB' })}
                    />
                  )}
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label={
                    <>
                    <LabeRequireForm
                      text={intl.formatMessage({ id: 'voucherMinUse', defaultMessage: 'Min Use' })}
                      req={true}
                    />  <LabeRequireFormHint text={intl.formatMessage({ id: 'voucherPerOrder', defaultMessage: '(Per Order)' })}/>
                    </>
                  }
                >
                  {getFieldDecorator('voucherMinUse', {
                    initialValue: _.get(viewData, 'minUse') ? _.get(viewData, 'minUse') : numberFormatter(0),
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({
                          id: 'voucherMinUseValidate',
                          defaultMessage: 'Please Enter Min Use',
                        }),
                      },
                      {
                        validator: validatorMinUse
                      }
                    ],
                  })(
                    <Input
                      // onChange={(e) => handleAmount(e, e.target.value)}
                      // disabled={_.get(viewData, 'totalTxt') ? true : false}
                      placeholder={intl.formatMessage({ id: 'voucherMinUsePlaceHolder', defaultMessage: 'Enter Min Use' })}
                      onKeyDown={handleKeyDownNumberDecimal}
                      suffix={intl.formatMessage({ id: 'quotationLabelTHB', defaultMessage: 'THB' })}
                    />
                  )}
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label={
                    <>
                    <LabeRequireForm
                      text={intl.formatMessage({ id: 'voucherMaxUse', defaultMessage: 'Max Use' })}
                      req={true}
                    />  <LabeRequireFormHint text={intl.formatMessage({ id: 'voucherPerOrder', defaultMessage: '(Per Order)' })}/>
                    </>
                  }
                >
                  {getFieldDecorator('voucherMaxUse', {
                    initialValue: _.get(viewData, 'maxUse') ? _.get(viewData, 'maxUse') : numberFormatter(0),
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({
                          id: 'voucherMaxUseValidate',
                          defaultMessage: 'Please Enter Max Use',
                        }),
                      },
                      {
                        validator: validatorMaxUse
                      }
                    ],
                  })(
                    <Input
                      // onChange={(e) => handleAmount(e, e.target.value)}
                      // disabled={_.get(viewData, 'totalTxt') ? true : false}
                      placeholder={intl.formatMessage({ id: 'voucherMaxUsePlaceHolder', defaultMessage: 'Enter Max Use' })}
                      onKeyDown={handleKeyDownNumberDecimal}
                      suffix={intl.formatMessage({ id: 'quotationLabelTHB', defaultMessage: 'THB' })}
                    />
                  )}
                </Form.Item>
              </Col>

            </Row>

            <Row gutter={[24, 24]}>
              <Col span={8}>
                <Form.Item
                  label={
                    <LabeRequireForm
                      text={intl.formatMessage({ id: 'voucherEffectiveDate', defaultMessage: 'Effective Date' })}
                      req={_.size(viewData) > 0 ? false : true}
                    />
                  }
                >
                  {getFieldDecorator('effectivedate', {
                    initialValue: _.get(viewData, 'effectiveDate') ? moment(_.get(viewData, 'effectiveDate')) : undefined,
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({
                          id: 'voucherEffectiveDateValidate',
                          defaultMessage: 'Please Select Effective Date',
                        }),
                      },
                    ],
                  })(
                    <DatePicker
                      onChange={handlechangeDate}
                      style={{ width: '100%' }}
                      format={dateFormat}
                      placeholder={intl.formatMessage({
                        id: 'voucherEffectiveDatePlaceHolder',
                        defaultMessage: 'Select Effective Date',
                      })}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label={
                    <LabeRequireForm
                      text={intl.formatMessage({ id: 'voucherExpiredDate', defaultMessage: 'Expired Date' })}
                      req={_.size(viewData) > 0 ? false : true}
                    />
                  }
                >
                  {getFieldDecorator('expireddate', {
                    initialValue: _.get(viewData, 'effectiveDate') ? moment(_.get(viewData, 'expiredDate')) : undefined,
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({
                          id: 'voucherExpiredDateValidate',
                          defaultMessage: 'Please Select Expired Date',
                        }),
                      },
                    ],
                  })(
                    <DatePicker
                      disabled={getFieldsValue(['effectivedate']) !== undefined ? false : true}
                      disabledDate={disabledDate}
                      style={{ width: '100%' }}
                      format={dateFormat}
                      placeholder={intl.formatMessage({
                        id: 'voucherExpiredDatePlaceHolder',
                        defaultMessage: 'Select Expired Date',
                      })}
                    />
                  )}
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item label={<LabeRequireForm text={intl.formatMessage({ id: 'VoucherReference', defaultMessage: 'Reference' })} />}>
                  {getFieldDecorator('reference', {
                    initialValue: _.get(viewData, 'reference') ? _.get(viewData, 'reference') : undefined,
                  })(<Input placeholder={intl.formatMessage({ id: 'VoucherReferencePlaceHolder', defaultMessage: 'Enter Reference' })} />)}
                </Form.Item>
              </Col>
            </Row>

            
          <div style={{ fontWeight: 'bold', color: '#1D3557', marginTop: '32px' }}>
            <FormattedMessage id="VoucherAppliedTo" defaultMessage="Applied To" />
          </div>
          <Divider type="horizontal" />



            <Row gutter={[24, 24]}>
              <Col span={8}>
                <Form.Item
                  label={<LabeRequireForm text={intl.formatMessage({ id: 'recivePOBrand', defaultMessage: 'Brand' })} req={false} />}
                >
                  {getFieldDecorator('brand', {
                    initialValue: _.get(viewData, 'voucherBrandName') ? _.get(viewData, 'voucherBrandName') : '*',
                  })(
                    <Select
                      allowClear
                      showSearch
                      filterOption={(input, option) =>
                        option.props.children
                          .toString()
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onSearch={handleSearchBrand}
                      disabled={_.get(viewData, 'voucherId') ? true : false}
                      onChange={(value) => {
                        setBrandId(value);
                        handleChangeBrand(value);
                      }}
                      onSelect={(e) => {
                        if (e === '*') {
                          setBrandSelectAll(true);
                          setModelSelectAll(true);
                          setFieldsValue({ ['model']: '*' });
                        } else {
                          setBrandSelectAll(false);
                          setModelSelectAll(true);
                          setFieldsValue({ ['model']: '*' });
                        }
                      }}
                      placeholder={intl.formatMessage({ id: 'recivePOPlaceholderBrand', defaultMessage: 'Select Brand' })}
                      onPopupScroll={handleBrandPopupScroll}
                    >
                      {brandList && brandList.map((item, i) => <Option value={item.item_brand_id}>{item.item_brand_name}</Option>)}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label={<LabeRequireForm text={intl.formatMessage({ id: 'recivePOModel', defaultMessage: 'Model' })} />}>
                  {getFieldDecorator('model', {
                    initialValue: _.get(viewData, 'voucherModelName') ? _.get(viewData, 'voucherModelName') : '*',
                  })(
                    <Select
                      allowClear
                      showSearch
                      filterOption={(input, option) =>
                        option.props.children
                          .toString()
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onSearch={handleSearchModel}
                      disabled={brandSelectAll}
                      placeholder={intl.formatMessage({ id: 'recivePOPlaceholderModel', defaultMessage: 'Select Model' })}
                      onPopupScroll={handleModelPopupScroll}
                      onSelect={(e) => {
                        if (e === '*') {
                          setModelSelectAll(true);
                        } else {
                          setModelSelectAll(false);
                        }
                      }}
                    >
                      {dataModel &&
                        dataModel.map((item) => (
                          <Option key={item.item_model_id} value={item.item_model_id}>
                            {item.item_model_name}
                          </Option>
                        ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label={
                    <LabeRequireForm
                      text={intl.formatMessage({ id: 'VoucherPaidTo', defaultMessage: 'Paid To' })}
                      req={true}
                    />
                  }
                >
                  {getFieldDecorator('customer', {
                    initialValue: _.get(viewData, 'ownerType') === 'company'
                    ? _.get(viewData, 'comCode') + ' ' + _.get(viewData, 'customerName')
                    : _.get(viewData, 'customerName')
                    ? _.get(viewData, 'customerCode') + ' ' + _.get(viewData, 'customerName') : '*',
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({ id: 'VoucherPaidToValidate', defaultMessage: 'Please Select Paid To' }),
                      },
                    ],
                  })(
                    <Select
                      disabled={_.get(viewData, 'customerName') ? true : false}
                      showSearch
                      filterOption={(input, option) => 
                        // option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        option.props.children
                          .toString()
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      placeholder={intl.formatMessage({ id: 'VoucherPaidToPlaceHolder', defaultMessage: 'Select Paid To' })}
                      onPopupScroll={handleCustomerPopupScroll}
                    >
                      {_.map(userData, (item, index) => (
                        <Option key={item.customerId} value={item.customerId}>
                          {item.customerCode} {item.customerName}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>
              
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Form.Item
                  label={
                    <LabeRequireForm text={intl.formatMessage({ id: 'modalReceiveRemarkText', defaultMessage: 'Remark' })} req={false} />
                  }
                >
                  {getFieldDecorator('remark', {
                    initialValue: _.get(viewData, 'description') ? _.get(viewData, 'description') : undefined,
                  })(
                  <TextArea 
                    autoSize={{ minRows: 4, maxRows: 4 }} 
                    maxLength={250} 
                    placeholder={intl.formatMessage({ id: 'VoucherRamarkPlaceHolder', defaultMessage: 'Enter Remark' })}
                  />
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </Modal>
  );
};

const ModalFormCreateVoucher = Form.create({
  name: 'modal_form',
})(ModalCreateVoucher);

export default ModalFormCreateVoucher;
