import React, { Component } from 'react';
import _ from 'lodash';
import { Row, Col } from 'antd';
import loopLogo from '../../../../../components/image/loop.png'
import loopContact from '../../../../../components/image/loopContact2.png'
import { QRCodeSVG } from 'qrcode.react';
import './style/print.css';
import styled, { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  @media print {
    @page {
      size: 150mm 100mm !important;
      orientation: landscape !important;
    }

    body {
      margin: 0;
    }
  }
`;

// สร้าง styled component ที่มีผลกระทบเฉพาะบางส่วนเท่านั้น
const PrintableComponent = styled.div`
  @media print {
    page-break-before: always;
  }
`;
export default class PrintLabel extends Component {
  constructor(props) {
    super(props);
    this.state = props;
  }

  render() {
    const numberZipcode = [1, 2, 3, 4, 5];
    const filterConfirm = _.filter(this.props.order.pacelDetails, (item) => { return item.status.statusCode === "confirmed" })
    const index = filterConfirm.findIndex(item => item._id === _.get(this.props.parcel, '_id'));
    return (
      <div ref={this.props.ref}>
        <GlobalStyle />
        <PrintableComponent>
          <div className='printable' style={{ padding: '10px' }}>
            <div
              style={{
                width: '830px',
                height: '540px',
              }}
            >
              <div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '24px',
                  }}
                >
                  <div>
                    <img src={loopLogo} width={150} style={{ margin: 0 }} />
                  </div>
                  <div>
                    <img src={loopContact} width={270} height={60} style={{ margin: 0 }} />
                  </div>
                </div>
              </div>
              <div
                style={{
                  padding: '0px 24px',
                }}
              >
                <div
                  style={{
                    border: '2px solid',
                    height: '370px',
                  }}
                >
                  <div
                    style={{
                      borderBottom: '2px solid',
                    }}
                  >
                    <div style={{ width: '100%', display: 'flex' }}>
                      <div style={{ width: '80%', padding: '10px 24px' }}>
                        <div
                          style={{
                            fontSize: '24px',
                            fontWeight: 'bold',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          <span>ผู้ส่ง : {_.get(this.props.order, 'customerDetail.comtomerName')}</span>
                          <span style={{ marginLeft: '8px' }}> {_.get(this.props.order, 'customerDetail.phone')}</span>
                        </div>
                        <div
                          className='address'
                          style={{
                            marginTop: '8px',
                            fontWeight: 'bold',
                            // fontSize: _.size(_.get(this.props.order, 'sourceDetail.address')) > 130 ? '14px' : '18px',
                            fontSize: '18px',
                            height: '55px',
                        overflowY: 'hidden',
                          }}
                        >
                          {_.get(this.props.order, 'sourceDetail.address')}
                        </div>
                      </div>
                      <div style={{ width: '20%', padding: '10px 24px', textAlign: 'center', borderLeft: '2px solid', }}>
                        <div style={{ fontWeight: 'bold', fontSize: '18px' }}>
                          จำนวนกล่อง
                        </div>
                        <div style={{ fontWeight: 'bold', fontSize: '38px' }}>
                          {index + 1}/{_.size(filterConfirm)}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      height: '105px',
                      // borderBottom: '2px solid',
                      padding: '10px 24px',
                    }}
                  >
                    <div
                      style={{
                        fontSize: '24px',
                        fontWeight: 'bold',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <span>ผู้รับ : {_.get(this.props.order, 'contactDetail.customerContactName')}</span>
                      <span style={{ marginLeft: '8px' }}>{_.get(this.props.order, 'contactDetail.customerContactPhone')}</span>
                    </div>
                    <div
                      class='address'
                      style={{
                        marginTop: '8px',
                        fontWeight: 'bold',
                        fontSize: '18px',
                        height: '55px',
                        overflowY: 'hidden',
                      }}
                    >
                      {_.get(this.props.order, 'destinationDetail.address')}
                      {/* {_.get(this.props.info, 'destination.address')} */}
                    </div>
                  </div>

                  <div
                    style={{
                      height: '50px',
                    }}
                  >
                    <Row>
                      <Col span={19} style={{ padding: '10px 0px 10px 24px' }}>
                        <div
                          className='divZip'
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'start',
                            marginTop: '15px',
                          }}
                        >
                          {_.map(numberZipcode, (number, index) => {
                            return (
                              <Row key={number}>
                                <Col>
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      backgroundColor: '#fff',
                                      height: '85px',
                                      width: '65px',
                                      marginLeft: '8px',
                                      textAlign: 'center',
                                      fontSize: '56px',
                                      fontWeight: 'bold',
                                      border: 'solid 2px',
                                    }}
                                  >
                                    {this.props.zipcode[index]}
                                  </div>
                                </Col>
                              </Row>
                            );
                          })}
                        </div>
                      </Col>
                      <Col span={5}>
                        <div
                          style={{
                            height: '140px',
                            width: '100%',
                            borderLeft: 'solid 2px',
                            borderTop: 'solid 2px',
                            marginBottom: '0px',
                          }}
                        >
                          <div
                            style={{
                              fontSize: '16px',
                              fontWeight: 'bold',
                              textAlign: 'center',
                              paddingTop: '4px',
                            }}
                          >
                            Parcel No.
                            <div style={{ textAlign: 'center' }}>
                              <QRCodeSVG
                                class='qrcode'
                                style={{ marginTop: '4px', marginBottom: '0px', width: '105px', height: '75px' }}
                                value={_.get(this.props.parcel, 'pacelNo')}
                              />
                            </div>
                            <div>
                            {_.get(this.props.parcel, 'pacelNo')}
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </PrintableComponent>
      </div>
    );
  }
}
