import React, { useState, useContext } from 'react'
import { Card, Table, Switch, Avatar, Row, Icon, Popover, Space, Col } from 'antd'
import ActionNotification from './action-notification'
import style from './css/notification-card-detail.css'
import Button01 from '../../components/v2/button_01'
import { NotificationContext } from './notification-context';
import _ from 'lodash';
import { useIntl, FormattedMessage } from 'react-intl';


export default ({ selectedRowKeys, setSelectedRowKeys }) => {

  const intl = useIntl();

  const { notiState, notiSetState, notiFNC } = useContext(NotificationContext);

  const notiName = _.get(notiState.dataFilterNoti, 'name')

  const notiTotal = _.get(notiState.totalNoti)

  // const selectedRowKeys = _.get(notiState.selectedRowKeys)

  // const [selectedRowKeys, setSelectedRowKeys] = useState([]);


  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: 70,
      render: (text, record, index) => record.index
    },
    {
      title: intl.formatMessage({ id: 'notificationColumnName', defaultMessage: 'Name' }),
      dataIndex: 'name',
      key: 'name',
      width: 250,
      render: (text, record, index) =>
        <div>
          <Avatar src={record.profileImage} />
          <span className='margin-avartar' onClick={() => {
            notiSetState.setVisibleModalNoti(true)
            notiSetState.setModalData(record)
          }}>
            {record.name}
          </span>
        </div>

    },
    {
      title: intl.formatMessage({ id: 'notificationColumnPhoneNumber', defaultMessage: 'Phone Number' }),
      dataIndex: 'phone',
      key: 'phone',
      width: 200,
    },
    {
      title: intl.formatMessage({ id: 'notificationColumnDescription', defaultMessage: 'Description' }),
      dataIndex: 'description',
      key: 'description',
      render: (text, record, index) =>
        <div>
          <span>{record.description1}  {record.description2}  {record.description3}</span>
        </div>
    },
    {
      title: intl.formatMessage({ id: 'notificationColumnStatus', defaultMessage: 'Status' }),
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: 200,
      render: (text, record, index) => {
        if (record.status === 'Unread') {
          return (
            <Row style={{ height: '21px' }}>
              <Col span={7}></Col>
              <Col span={12}>
                <div className='div-style-unread'>
                  {/* <FormattedMessage id="notificationUnread" defaultMessage="Unread" /> */}
                  {record.status}
                </div>
              </Col>
              <Col span={5}></Col>
            </Row>
          )
        } else {
          return (
            <Row>
              <Col span={7}></Col>
              <Col span={12}>
                <div className='div-style-acknowledge'>
                  {/* <FormattedMessage id="notificationAcknowledgeLbl" defaultMessage="Acknowledge" /> */}
                  {record.status}
                </div>
              </Col>
              <Col span={5}></Col>
            </Row>

          )
        }
      }
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      if (selectedRowKeys.length == 0) {
        notiSetState.setDisableAcknowledge(false)
        setSelectedRowKeys(selectedRowKeys)

      }
      else {
        notiSetState.setDisableAcknowledge(true)
        // let pushId = []
        // pushId.push(
        //   selectedRowKeys
        // )
        // // notiSetState.setIdSelect(pushId[0])
        // notiSetState.setSelectedRowKeys(pushId[0])
        setSelectedRowKeys(selectedRowKeys)

      }
      // setSelectedRowKeys(selectedRowKeys)

    },
    onSelect: (record, selected, selectedRows) => {

    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      // console.log('rowSelection all:::', selected, selectedRows, changeRows);
      // if(selectedRows.length !== changeRows.length ){
      //   const selectedRowsNew = _.difference(selectedRows, changeRows);
      //   console.log('rowSelection selectedRowsNew:::', selectedRowsNew);
      //   setSelectedRowKeys(selectedRowsNew)
      // }
    },
    getCheckboxProps: record => ({
      disabled: record.statusRead == 'true'
    }),
    selectedRowKeys,
  };

  const showTotal = (total) => {
    return <span style={{ fontSize: '13px' }}><FormattedMessage id="lblRowPerPage" defaultMessage="Rows per page: " /></span>
  };


  return (
    <div>
      <Card
        className='header-noti-detail'
        title={notiName}
        extra={
          <div>
            {notiState.statusPin == true ? (
              <div>
                <span style={{ marginRight: '16px' }}><FormattedMessage id="notficationPin" defaultMessage="Pin" /></span>
                <Switch onChange={(e) => {
                  notiFNC.pinNotification(e)
                }}
                  checked={notiState.defaultPin ? notiState.defaultPin : undefined}
                ></Switch>
              </div>
            )
              : null
            }

          </div>

        }
      >
        <ActionNotification
          columns={columns}
          selectedRowKeys={selectedRowKeys}
        />
        {
          notiName ?
            <div>
              <Table
                // className={notiState.notiData && notiState.notiData.length !== 0 ? 'noti-table' : ''}
                className='table-notofication-page'
                scroll={{ x: 1000, y: `calc(100vh - 372px)` }}
                columns={columns}
                dataSource={notiState.notiData}
                rowSelection={rowSelection}
                rowKey={record => record._id}
                pagination={{
                  total: notiState.total,
                  showTotal: showTotal,
                  defaultCurrent: 1,
                  pageSizeOptions: ['10', '20', '30', '40', '50'],
                  showSizeChanger: true,
                  locale: { items_per_page: '' },
                  // onChange: fnc.handlePagination,
                  // onShowSizeChange: fnc.handleSizeChange,
                }}
              />
              {notiState.notiData !== undefined && notiState.notiData.length !== 0 ? (
                <div className="total-items-list-view">
                  <span style={{ fontSize: '13px' }}>
                    <FormattedMessage id="lblTotal" defaultMessage="Total" />
                    {` ${notiState.totalNoti || 0} `}
                    <FormattedMessage id="lblitems" defaultMessage="items" />
                  </span>
                </div>
              ) : null}
            </div>
            :
            <div className='div-content-body'>
              <Card className="task-notification-card">
                <div className="task-status-content-detail">
                  <Icon className="task-status-content-icon" type="bell" />
                  <p className="task-status-content-text">
                    <FormattedMessage id="notificationDescripLbl1" defaultMessage="Please select a Notification" />
                    {' '} <br />
                    <FormattedMessage id="notificationDescripLbl2" defaultMessage="Notification will appear here." />
                  </p>
                </div>
              </Card>
            </div>
        }
      </Card>


    </div>
  )
}
