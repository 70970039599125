import React, { useState, useEffect } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Button, Row, Input, Col, DatePicker, Select, List, Icon, Dropdown, Checkbox, ConfigProvider, Menu } from 'antd';
import styles from './css/action-report.css';
import { useAppContext } from '../../includes/indexProvider';
import Button01 from '../v2/button_01';
import moment from 'moment';
import enUS from 'antd/es/locale/en_US';
import thTH from 'antd/es/locale/th_TH';

const { Option } = Select;
const { RangePicker } = DatePicker;

const ActionReportV9 = (props) => {
  const { value, fnc } = props;
  const intl = useIntl();
  const app = useAppContext();
  const langValue = app.state.langValue;
  const dateFormat = 'ddd, MMM DD YYYY';
  const today = new Date();

  const [locale, setLocale] = useState(enUS);

  useEffect(() => {
    changeLocale();
  }, []);

  const changeLocale = () => {
    let codeLang = langValue ? langValue : 'EN';
    let changeLang = codeLang === 'EN' ? enUS : thTH;

    setLocale(changeLang);
    if (!changeLang) {
      moment.locale('en');
    } else {
      moment.locale('th-th');
    }
  };

  const columnNew = value.columns.filter((col) => !value.ignoreFilterColumns.includes(col.key));
  const columnNewSearc = value.columns.filter((col) => !value.ignoreSearchColumns.includes(col.key));

  return (
    <div className="action-report">
      <Row className="action-report-row">
        <Col span={12} className="action-report-col">
          <div className="action-report-div-left">
            <Input
              style={{ width: 600 }}
              className="action-report-input action-input"
              placeholder={intl.formatMessage({
                id: 'hintSearch',
                defaultMessage: 'Search',
              })}
              prefix={<Icon style={{ color: 'rgba(0,0,0,.25)' }} type="search" />}
              onChange={(e) => fnc.onChangeSearch(e.target.value)}
              autoComplete="off"
            />
            <Select
              className="action-report-select-column action-select"
              placeholder={intl.formatMessage({
                id: 'hintSelect',
                defaultMessage: 'Select',
              })}
              value={value.fliterByColumn}
              onSelect={(value) => fnc.onFliterByColumn(value)}
            >
              <Option value="all">
                <FormattedMessage id="reportLeaveReportHintSeleteAllColumns" defaultMessage="All columns" />
              </Option>
              {columnNewSearc.map((item, i) => (
                <Option key={i} value={item.key}>
                  {item.title}
                </Option>
              ))}
            </Select>
          </div>
        </Col>
        <Col span={12} className="action-report-col"></Col>
      </Row>
    </div>
  );
};

export default ActionReportV9;
