import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Row, Col } from 'antd';
import NumberFormat from 'react-number-format';
import _ from 'lodash';

const SummaryQuotation = (props) => {
  const intl = useIntl();
  const { summary } = props;
console.log('summary',summary);
  return (
    <div className="price-report">
      <Row className="price-report-row">
        <Col span={6} className="price-report-col">
          <div className="price-report-div-text">
            <p className="price-report-text-p">
              <FormattedMessage id="quotationLbltotalCustomer" defaultMessage="Total Customer" />
            </p>
            <NumberFormat value={_.get(summary, "totalCustomer") || 0} displayType={'text'} thousandSeparator={true} className="price-report-text-span"/>
          </div>
        </Col>
        <Col span={6} className="price-report-col">
          <div className="price-report-div-text">
            <p className="price-report-text-p">
              <FormattedMessage id="quotationLbltotalSaleOrder" defaultMessage="Total Sales Order" />
            </p>
            <NumberFormat value={_.get(summary, "totalSaleOrder")} displayType={'text'} thousandSeparator={true} className="price-report-text-span"/>
          </div>
        </Col>
        <Col span={6} className="price-report-col">
          <div className="price-report-div-text">
            <p className="price-report-text-p">
              <FormattedMessage id="quotationLblrevenue" defaultMessage="Revenue" />
            </p>
            <NumberFormat value={_.floor(summary.revenue, 2).toFixed(2)} displayType={'text'} thousandSeparator={true}
              suffix={' ' + intl.formatMessage({ id: 'saleOrderReportPriceTexBaht', defaultMessage: 'Baht' })} className="price-report-text-span" />
          </div>
        </Col>
        <Col span={6} className="price-report-col">
          <div className="price-report-div-text">
            <p className="price-report-text-p">
              <FormattedMessage id="quotationLblavgOrderValue" defaultMessage="Avg. Order Value" />
            </p>
            <NumberFormat value={_.floor(summary.avgOrderValue, 2).toFixed(2)} displayType={'text'} thousandSeparator={true}
              suffix={' ' + intl.formatMessage({ id: 'saleOrderReportPriceTexBaht', defaultMessage: 'Baht' })} className="price-report-text-span" />
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default SummaryQuotation;
