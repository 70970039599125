import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Row, Col, DatePicker, ConfigProvider } from 'antd';
import moment from 'moment';
import StatementTable from './statementTable';
import '../css/paydStatement.css';
import HttpClient from '../../../../components/httpClient';
import enUS from 'antd/es/locale/en_US';
import thTH from 'antd/es/locale/th_TH';
import 'moment/locale/th';
moment.locale('en');

const client = HttpClient();
const { MonthPicker } = DatePicker;

export default ({ statement, overall, onSelectDate, formatNumber, labelShow, langValue }) => {
  const [locale, setLocale] = useState('enUS');
  const weeks = Object.values(statement === undefined ? [] : statement.weeks);
  const withDrawRate = statement.withDrawRate || '0';
  const totalWithDraw = statement.totalWithDraw || '';
  const summaryBy = statement.summaryBy || '-';
  const today = new Date();

  const monthFormat = 'MMMM YYYY';

  useEffect(() => {
    changeLocale();
  }, []);

  const progressBar = weeks.map((amount, index) => {
    const perWithdraw = (amount / `${overall}`) * 100;
    const color = perWithdraw === 0 ? '#fff' : '#1D3557';
    const checkZero = perWithdraw === 0 ? 0 : perWithdraw;

    return (
      <div key={index}>
        <Row className="progress-row">
          <Col className="progress-col" span={12}>
            <span>
              <FormattedMessage id="userMgntUsersDetailLblStatementWeek" defaultMessage="Week" />
              <span> {index + 1} </span>
            </span>
            <span
              style={{ width: `${perWithdraw}%`, backgroundColor: '#1D3557', height: '14px', borderRadius: '16px', marginLeft: '10px' }}
            />
            <span className="week-withdraw">{formatNumber(amount)}</span>
          </Col>
        </Row>
      </div>
    );
  });

  const disabledDate = (current) => {
    if (langValue === 'EN') {
      return current && current > moment().endOf('day');
    } else {
      return current && current > moment().endOf('day');
      // return current && current > moment().add(543, "year").endOf('day');
    }
  };

  const changeLocale = () => {
    let codeLang = langValue ? langValue : 'EN';
    let changeLang = codeLang === 'EN' ? enUS : thTH;

    setLocale(changeLang);
    if (!changeLang) {
      moment.locale('en');
    } else {
      moment.locale('th-th');
    }
  };

  return (
    <div className="content-space">
      <Row className="statement-row">
        <Col className="week" span={14} push={1}>
          {progressBar}
        </Col>

        <Col span={10} className="col2">
          <Row className="monthPicker-row">
            <Col span={24}>
              <span className="summary-text">
                <FormattedMessage id="userMgntUsersDetailLblStatementSummary" defaultMessage="Week" />
              </span>
              <ConfigProvider locale={locale}>
                <MonthPicker
                  className="statement-date"
                  size="large"
                  format={monthFormat}
                  bordered={false}
                  // defaultValue={langValue ? moment(today, monthFormat) : moment(today, monthFormat)}
                  defaultValue={moment(today, monthFormat)}
                  onChange={onSelectDate}
                  allowClear={false}
                  disabledDate={disabledDate}
                />
              </ConfigProvider>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <div className="withdraw-text">
                <FormattedMessage id="userMgntUsersDetailLblStatementWithdrawRate" defaultMessage="Withdraw Rate" />
              </div>
            </Col>
            <Col span={16}>
              <div className="withdraw-text">
                <FormattedMessage id="userMgntUsersDetailLblStatementTotalWithdraw" defaultMessage="Total Withdraw (Baht)" />
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <span className="withdraw">{`${withDrawRate}%`}</span>
            </Col>
            <Col span={16}>
              <span className="withdraw">{formatNumber(`${totalWithDraw ? totalWithDraw : 0}`)}</span>
            </Col>
          </Row>
        </Col>
      </Row>
      <StatementTable transactions={statement.transactions} formatNumber={formatNumber} />
    </div>
  );
};
