import { AutoComplete, Checkbox, Col, Divider, Form, Icon, Input, Modal, Row, Select, TimePicker } from 'antd';
import React, { useState, useContext } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import LabeRequireForm from '../../../components/label-required-form';
import Button_01 from '../../../components/v2/button_01';
import Button_02 from '../../../components/v2/button_02';
import WarehouseContext from '../context';
import _ from 'lodash';
import { useEffect } from 'react';
import './css/index.css';
import { errorNotification, successNotification } from '../../../components/v2/notification';
import {
  getBinLocationForSwap,
  getItemsCode,
  getItemsCodeSwap,
  getItemsMaster,
  getItemsNameSwap,
  getLocationByItem,
  saveSwapLocation,
  saveWarehouseStock,
  updateWarehouseStock,
} from '../../../controllers/warehouse/warehouse';
import moment from 'moment';
import { handleKeyDownDecimal2Fixed, handleKeyDownPhone } from '../../../component-function/fnc-number';
const { TextArea } = Input;
const { Option } = Select;
const WarehouseLocationSwapModal = ({ form, record, setRecord, visible, setVisible, setTriggerLocation }) => {
  const intl = useIntl();
  const { state, setState, fnc } = useContext(WarehouseContext);
  const { warehouseId } = state;
  const { setTrigger } = setState;
  const { getFieldDecorator, validateFields, resetFields, setFieldsValue, getFieldValue } = form;
  const format = 'HH:mm';
  const [binLocationList, setBinLocationList] = useState([]);

  const [searhByCode, setSearchByCode] = useState('');
  const [searchBySelectCode, setSearchBySelectCode] = useState('');
  const [searhByName, setSearchByName] = useState('');
  const [searhByBin, setSearchByBin] = useState('');
  const [itemCodeList, setItemCodeList] = useState([]);
  const [itemNameList, setItemNameList] = useState([]);

  const [selectItem, setSelectItem] = useState();
  const [selectBinLocation, setSelectBinLocation] = useState();

  console.log('record swap', record);

  // useEffect(() => {
  //   if (record) {
  //     const item = _.get(record, 'itemMasterDetail');
  //     // itemCodeList.push(item);
  //     // itemNameList.push(item);
  //     // setItemCodeList(itemCodeList);
  //     // setItemNameList(itemNameList);
  //     setSelectItem(item);
  //   }
  // }, [record]);

  useEffect(() => {
    const getBinLocation = async () => {
      const payload = {
        binId: _.get(record, 'binLoationId'),
        name: searhByBin,
        itemCodeSearch: searchBySelectCode ? `&itemCode=${searchBySelectCode}` : '',
      };
      const response = await getBinLocationForSwap(payload);
      console.log('getBinLocationForSwap', response);
      const list = _.get(response.data.data, 'binLocationList');
      setBinLocationList(_.uniqBy(_.concat(binLocationList, list), 'binLoationId'));
    };
    if (searhByBin || searchBySelectCode) {
      getBinLocation();
    }
  }, [searhByBin, searchBySelectCode]);

  useEffect(() => {
    const getItemCode = async () => {
      const payload = {
        binId: _.get(record, 'binLoationId'),
        value: searhByCode,
      };
      const response = await getItemsCodeSwap(payload);
      const list = _.get(response.data.data, 'listItems');
      setItemCodeList(_.uniqBy(_.concat(itemCodeList, list), 'itemMasterId'));
    };
    if (searhByCode) {
      getItemCode();
    }
  }, [searhByCode]);

  useEffect(() => {
    const getItemName = async () => {
      const payload = {
        binId: _.get(record, 'binLoationId'),
        value: searhByName,
      };
      const response = await getItemsNameSwap(payload);
      const list = _.get(response.data.data, 'listItems');
      console.log();
      setItemNameList(_.uniqBy(_.concat(itemNameList, list), 'itemMasterId'));
    };
    if (searhByName) {
      getItemName();
    }
  }, [searhByName]);

  const handleSelectCode = (value) => {
    if (value) {
      const filterArr = itemCodeList.filter((item) => item.itemMasterId === value);
      setSelectItem(_.get(filterArr, '[0]'));
      setSearchBySelectCode(_.get(filterArr[0], 'itemCode'));
      setSearchByName(_.get(filterArr[0], 'itemName'));
      setFieldsValue({ toItemName: _.get(filterArr[0], 'itemMasterId') });
    }
  };

  const handleSelectName = (value) => {
    if (value) {
      const filterArr = itemNameList.filter((item) => item.itemMasterId === value);
      setSelectItem(_.get(filterArr, '[0]'));
      setSearchBySelectCode(_.get(filterArr[0], 'itemCode'));
      setSearchByCode(_.get(filterArr[0], 'itemCode'));
      setFieldsValue({ toItemCode: _.get(filterArr[0], 'itemMasterId') });
    }
  };

  const handleSelectTobin = (value) => {
    if (value) {
      const filterArr = binLocationList.filter((item) => item.binLoationId === value);
      setSelectBinLocation(_.get(filterArr, '[0]'));
      setSearchByCode(_.get(filterArr[0], 'itemCode'));
      setSearchByName(_.get(filterArr[0], 'itemName'));
      setFieldsValue({ toItemCode: _.get(filterArr[0], 'itemMasterId'), toItemName: _.get(filterArr[0], 'itemMasterId') });
    }
  };

  const handleSearchToBin = _.debounce((value) => {
    setSearchByBin(value || '');
  }, 500);

  const handleSearchCode = _.debounce((value) => {
    setSearchByCode(value || '');
  }, 500);

  const handleSearchName = _.debounce((value) => {
    setSearchByName(value || '');
  }, 500);

  const onOk = (open) => {
    validateFields(async (err, values) => {
      if (err) {
        console.log('err', err);

        return;
      }

      console.log('err', values);

      if (values) {
        console.log('onOk', values);
        const payload = {
          // swapFromNobin: _.get(record, 'isNoBin') ,
          binLoationIdFrom: _.get(record, 'binLoationId'),
          binLoationIdTo: _.get(selectBinLocation, 'binLoationId'),
        };
        console.log('payload', payload);

        try {
          const response = await saveSwapLocation(payload);
          console.log('saveSwapLocation', response);
          if (_.get(response, 'status') === 200) {
            successNotification(_.get(response.data.status, 'message'));
            setVisible(open);
            utilResetValue();
          } else {
            errorNotification(_.get(response.data.status, 'message'));
          }
        } catch (error) {}
      }
    });
  };

  const payloadToNumber = (value) => {
    return _.toNumber(value);
  };

  const utilResetValue = () => {
    setRecord();
    setTrigger((current) => !current);
    setTriggerLocation((c) => !c);
    setSelectItem();
    setBinLocationList([]);
    setItemCodeList([]);
    setItemNameList([]);
    resetFields();
    setSelectBinLocation();
    setSearchBySelectCode('');
    setSearchByBin('');
    setSearchByCode('');
    setSearchByName('');
  };

  const onCancel = () => {
    setVisible(false);
    setItemCodeList([]);
    setItemNameList([]);
    resetFields();
    setSelectItem();
    setSelectBinLocation();
    setSearchBySelectCode('');
    setBinLocationList([]);
    setSearchByBin('');
    setSearchByCode('');
    setSearchByName('');
    setRecord();
  };

  return (
    <Modal
      title={intl.formatMessage({ id: `warehouseTitleLocation`, defaultMessage: 'Swap or Change Item' })}
      visible={visible}
      centered
      width={700}
      bodyStyle={{ padding: '6px 24px 24px 24px' }}
      onOk={onOk}
      onCancel={onCancel}
      footer={[
        <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={() => onCancel()} margin=" unset">
          <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
        </Button_02>,
        <Button_01 style={{ margin: '0px 0px 0px 10px' }} key="submit" type="primary" btnsize="wd_df" onClick={() => onOk(false)}>
          <FormattedMessage id="btnSave" defaultMessage="Save" />
        </Button_01>,
      ]}
    >
      <Form colon={false}>
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Row gutter={[24, 2]}>
              <Col span={10}>
                <Form.Item
                  label={
                    <LabeRequireForm
                      text={intl.formatMessage({ id: 'stockLabelFromBinLocation', defaultMessage: 'From Bin Location' })}
                      req={true}
                    />
                  }
                >
                  {getFieldDecorator('fromBin', {
                    initialValue: _.get(record, 'name') ? _.get(record, 'name') : undefined,
                    rules: [
                      {
                        required: false,
                        message: intl.formatMessage({
                          id: 'warehouseModalReqFromBinLocation',
                          defaultMessage: 'Please select From Bin Location',
                        }),
                      },
                    ],
                  })(<Input disabled />)}
                </Form.Item>
              </Col>
              <Col span={4}></Col>
              <Col span={10}>
                <Form.Item
                  label={
                    <LabeRequireForm
                      text={intl.formatMessage({ id: 'stockLabelToBinLocation', defaultMessage: 'To Bin Location' })}
                      req={true}
                    />
                  }
                >
                  {getFieldDecorator('toBin', {
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({
                          id: 'warehouseModalReqToBinLocation',
                          defaultMessage: 'Please select To Bin Location',
                        }),
                      },
                    ],
                  })(
                    <AutoComplete
                      onSearch={handleSearchToBin}
                      onSelect={(value, evnt) => handleSelectTobin(value, evnt)}
                      dataSource={binLocationList}
                      filterOption={false}
                      placeholder={intl.formatMessage({
                        id: 'warehouseModalReqNamePlaceholderToBinLocation',
                        defaultMessage: 'Select To Bin Location',
                      })}
                    >
                      {binLocationList &&
                        binLocationList.map((item) => (
                          <Option key={item.binLoationId} value={item.binLoationId} label={item.name}>
                            {item.name}
                          </Option>
                        ))}
                    </AutoComplete>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24, 2]}>
              <Col span={10}>
                <Form.Item
                  label={
                    <LabeRequireForm
                      text={intl.formatMessage({ id: 'stockLabelFormItemCode', defaultMessage: 'Form Item Code' })}
                      req={true}
                    />
                  }
                >
                  {getFieldDecorator('fromItemCode', {
                    initialValue: _.get(record, 'binLoationId') ? _.get(record.itemMasterDetail, 'itemCode') : undefined,
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({ id: 'reqStockLabelItemCode', defaultMessage: 'Please select form item code' }),
                      },
                    ],
                  })(<Input disabled />)}
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label=" ">
                  <div className="icon-center-location-swap">
                    <Icon type="swap-right" style={{ fontSize: 32 }} />
                  </div>
                </Form.Item>
              </Col>
              <Col span={10}>
                <Form.Item
                  label={
                    <LabeRequireForm text={intl.formatMessage({ id: 'stockLabelToItemCode', defaultMessage: 'To Item Code' })} req={true} />
                  }
                >
                  {getFieldDecorator('toItemCode', {
                    // initialValue: _.get(record, '_id') ? _.get(record.itemMasterDetail, 'itemMasterId') : undefined,
                    rules: [
                      {
                        required: false,
                        message: intl.formatMessage({ id: 'reqStockLabelToItemCode', defaultMessage: 'Please select to item code' }),
                      },
                    ],
                  })(
                    <AutoComplete
                      onSearch={handleSearchCode}
                      onSelect={(value, evnt) => handleSelectCode(value, evnt)}
                      dataSource={itemCodeList}
                      filterOption={false}
                      placeholder={intl.formatMessage({ id: 'placeholderStockLabelToItemCode', defaultMessage: 'Select To Item Code' })}
                    >
                      {itemCodeList &&
                        itemCodeList.map((item) => (
                          <Option key={item.itemCode} value={item.itemMasterId}>
                            {item.itemCode}
                          </Option>
                        ))}
                    </AutoComplete>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24, 2]}>
              <Col span={10}>
                <Form.Item
                  label={
                    <LabeRequireForm
                      text={intl.formatMessage({ id: 'stockLabelFromItemName', defaultMessage: 'From Item Name' })}
                      req={true}
                    />
                  }
                >
                  {getFieldDecorator('fromItemName', {
                    initialValue: _.get(record, 'binLoationId') ? _.get(record.itemMasterDetail, 'itemName') : undefined,
                    rules: [
                      {
                        required: false,
                        message: intl.formatMessage({ id: 'reqStockLabelFromItemName', defaultMessage: 'Please select from item Name' }),
                      },
                    ],
                  })(<Input disabled />)}
                </Form.Item>
              </Col>
              <Col span={4}></Col>
              <Col span={10}>
                <Form.Item
                  label={
                    <LabeRequireForm text={intl.formatMessage({ id: 'stockLabelToItemName', defaultMessage: 'To Item Name' })} req={true} />
                  }
                >
                  {getFieldDecorator('toItemName', {
                    // initialValue: _.get(record, '_id') ? _.get(record.itemMasterDetail, 'itemMasterId') : undefined,
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({ id: 'reqStockLabelToItemName', defaultMessage: 'Please select To item Name' }),
                      },
                    ],
                  })(
                    <AutoComplete
                      onSearch={handleSearchName}
                      onSelect={handleSelectName}
                      filterOption={false}
                      dataSource={itemNameList}
                      placeholder={intl.formatMessage({ id: 'placeholderStockLabelToItemName', defaultMessage: 'Select To Item Name' })}
                    >
                      {itemNameList &&
                        itemNameList.map((item, index) => (
                          <Option key={item.itemCode} value={item.itemMasterId}>
                            {item.itemName}
                          </Option>
                        ))}
                    </AutoComplete>
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

const WarehouseLocationSwapModalForm = Form.create({
  name: 'warehouse-form',
})(WarehouseLocationSwapModal);

export default WarehouseLocationSwapModalForm;
