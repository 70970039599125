import httpClient from '../../components/axiosClient';

const getRole = async (userId) => {
  try {
    const response = await httpClient.get(`/v2/roles/${userId}`);
    if (response.status === 200) {
      return response;
    } else {
      return false;
    }
  } catch (error) {
    return error.response;
  }
};

export { getRole }