import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { QuotationContext } from './quotation-context';
import { getDefaultItemAndVehicle, getMembers, getDefaultStarted, createQuotation } from '../../controllers/quotation/quotation-api';
import { PageSettings } from '../../config/page-settings';
import { successNotification, errorNotification, warningNotification } from '../v2/notification';
import { useIntl, FormattedMessage } from 'react-intl';
import { Modal, Spin } from 'antd';
import QuotationSetting from './quotation-setting';
import QuotationReport from './quotation-report';
import QuotationCalculate from './quotation-calculate';

import Button01 from '../v2/button_01';
import Button02 from '../v2/button_02';
import httpClient from '../axiosClient';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import axios from 'axios';
import _, { debounce } from 'lodash';

import stylesModal from './css/index.css';
import GetCustomerApi from '../../controllers/getCustomer/get-customer-api';
import ViewCustomer from '../view-customer/index';
import Addcustomer from '../add-business-partner/index';
import EditbusinessPartner from '../edit-business-partner/index';
import getCustomerInformation from '../../controllers/getCustomer/get-customer-information';
import getWareHouse from '../../controllers/sale-order/get-werehouse';
import AddContact from '../add-contact-v2/index';
import EditContact from '../edit-contact-v2/index';
import QuotationItem from '../quotation-item/index';
import AddAddress from '../modal-create-adress-map-v2/index';
import EditAddress from '../modal-edit-adress-map-v2/index';
import { getItemAll } from '../../controllers/quotation/quotation-api';

const today = new Date();
const dateFormat = 'ddd, MMM DD YYYY';

const CreateQuotation = (props) => {
  const intl = useIntl();
  const { visibleModal, setVisibleModal, setTriggerApi, remark, setRemark, onCustomerSelect } = props;
  const comCode = localStorage.getItem('comCode');

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
      render: (text, record, index) => index + 1,
    },
    {
      title: intl.formatMessage({
        id: 'quotationModalColumnItemName',
        defaultMessage: 'Item Name',
      }),
      dataIndex: 'itemName',
      key: 'itemName',
    },
    {
      title: intl.formatMessage({
        id: 'quotationModalColumnUnitPriceBaht',
        defaultMessage: 'Unit Price (Baht)',
      }),
      dataIndex: 'price',
      key: 'price',
      align: 'right',
      width: 180,
      render: (text, record, index) => (
        <NumberFormat value={parseFloat(record.price).toFixed(2)} displayType={'text'} thousandSeparator={true} />
      ),
    },
    {
      title: intl.formatMessage({
        id: 'quotationModalColumnQty',
        defaultMessage: 'Qty',
      }),
      dataIndex: 'qty',
      key: 'qty',
      align: 'center',
      width: 120,
      render: (text, record, index) => <NumberFormat value={parseFloat(record.qty)} displayType={'text'} thousandSeparator={true} />,
    },
    {
      title: intl.formatMessage({
        id: 'quotationModalColumnUnit',
        defaultMessage: 'Unit',
      }),
      dataIndex: 'itemUnitName',
      key: 'itemUnitName',
      align: 'right',
      width: 120,
    },
    {
      title: intl.formatMessage({
        id: 'quotationModalColumnTotalBaht',
        defaultMessage: 'Total (Baht)',
      }),
      dataIndex: 'totalPrice',
      key: 'totalPrice',
      align: 'right',
      width: 140,
      render: (text, record, index) => (
        <NumberFormat value={parseFloat(record.totalPrice).toFixed(2)} displayType={'text'} thousandSeparator={true} />
      ),
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      width: 50,
      render: (text, record, index) => (
        <div className="table-column-delete-dot" onClick={() => handleDeleteItem(record, index)}>
          <div className="table-column-delete-line-style" />
        </div>
        // <Button className="table-column-delete" shape="circle" icon="minus" size="small" />
      ),
    },
  ];

  const [formRefSetting, setFormRefSetting] = useState(null);
  const [loading, setLoading] = useState(false);
  const [discountSelete, setDiscountSelete] = useState({});
  const [taxSelete, setTaxSelete] = useState({});
  const [discountData, setDiscountData] = useState([]);
  const [taxData, setTaxData] = useState([]);
  const [reasonData, setReasonData] = useState([]);
  const [valueInputDiscount, setValueInputDiscount] = useState(0.0);
  const [sumCalculate, setSumCalculate] = useState({});

  const [triggerCustomer, setTriggerCustomer] = useState(false); //Trigger สร้าง แก้ไข Customer
  const [triggerAddress, setTriggerAddress] = useState(false); // Trigger สร้าง แก้ไข Address
  const [visibleViewCustomer, setVisibleViewCustomer] = useState(false);
  const [visibleAddCustomer, setVisibleAddCustomer] = useState(false);
  const [visibleAddContact, setVisibleAddContact] = useState(false);
  const [visibleAddAddress, setVisibleAddAddress] = useState(false);
  const [visibleEditAddress, setVisibleEditAddress] = useState(false);
  const [visibleEditCustomer, setVisibleEditCustomer] = useState(false);
  const [visibleEditContact, setVisibleEditContact] = useState(false);
  const [customerData, setCustomerData] = useState([]); // data ตั้งต้น Customer
  const [addressData, setAddressData] = useState([]); // data ตั้งต้น Address
  const [contactData, setContactData] = useState([]); // data ตั้งต้น Contact
  const [warehouseData, setWarehouseData] = useState([]); // data ตั้งต้น Warehouse
  const [selectCustomer, setSelectCustomer] = useState(); // เลือก Customer
  const [selectAddress, setSelectAddress] = useState(); // เลือก Address
  const [selectWareHouse, setSelectWareHouse] = useState(); // เลือก Warehouse
  const [selectContactData, setSelectContactData] = useState(); // Data ตั้งต้น Edit contact
  const [selectAddressData, setSelectAddressData] = useState(); // Data ตั้งต้น Edit Address

  const [AddCustomerData, setAddCustomerData] = useState(); // สร้าง New Customer
  const [AddAddressData, setAddAddressData] = useState(); // สร้าง New Address
  const [AddContactData, setAddContactData] = useState(); // สร้าง New Contact
  const [editContactData, setEditContactData] = useState(); // Data แก้ไข Contact
  const [statusSet, setStatusSet] = useState(false);

  const [defaultProducts, setDefaultProducts] = useState([]);
  const [defaultVehicles, setDefaultVehicles] = useState([]);
  const [defaultUnits, setDefaultUnits] = useState([]);
  const [defaultMembers, setDefaultMembers] = useState([]);
  const [checkButton, setCheckButton] = useState(false);
  const [subTotalResult, setSubTotalResult] = useState(0.0);
  const [polyline, setPolyline] = useState([]); // Data Plypline
  const [distance, setDistance] = useState(); // ระยะทาง
  const [marker, setMarker] = useState();

  // const [remark, setRemark] = useState("");

  const [itemObjectData, setItemObjectData] = useState([]);
  const [visibleAddItem, setVisibleAddItem] = useState(false);

  useEffect(() => {
    const getCustomerAll = async () => {
      let dataCustomer = await GetCustomerApi();
      setCustomerData(dataCustomer.customers);
    };
    getCustomerAll();
  }, [triggerCustomer]);

  useEffect(() => {
    const customerId = _.get(selectCustomer, 'customerId');
    const getAddress = async () => {
      if (customerId) {
        setLoading(true);
        const response = await getCustomerInformation(customerId);
        if (response) {
          setContactData(_.get(response, 'contacts'));
          setAddressData(_.get(response, 'addressBooks'));
          setLoading(false);
        }
      }
    };
    getAddress();
  }, [_.get(selectCustomer, 'customerId'), triggerAddress, visibleEditContact]);

  // useEffect(() => {
  //   const getDataWareHouse = async () => {
  //     const response = await getWareHouse();
  //     if (response) {
  //       setWarehouseData(_.get(response, 'Warehouse'));
  //     }
  //   };
  //   getDataWareHouse();
  // }, []);

  useEffect(() => {
    const getApi = async () => {
      const responseMembers = await getMembers();
      setDefaultMembers(_.get(responseMembers.data, 'data'));
    };
    getApi();
  }, []);

  useEffect(() => {
    let customerId = _.get(selectCustomer, 'customerId');
    let lat = _.get(selectAddress, 'lat');
    let lng = _.get(selectAddress, 'lng');
    let warehouseId = _.get(selectWareHouse, 'warehouseId');
    // const distance = distanceMeter === 0 ? undefined : 200000;

    const getItem = async () => {
      const response = await getItemAll(
        customerId,
        lat,
        lng,
        undefined
        // warehouseId
      );
      if (response) {
        setWarehouseData(_.get(response,'data.warehouses'));
      }
    };
    getItem();
  }, [_.get(selectCustomer, 'customerId'), _.get(selectAddress, 'customerAddressBookId'), triggerAddress]);

  useEffect(() => {
    if (visibleModal === true) {
      const getDefault = async () => {
        const response = await getDefaultStarted();
        const resultDiscount =
          response &&
          response.dataDiscount.find((discountData) => {
            return discountData.defaultData === true;
          });

        const resultTax =
          response &&
          response.dataTax.find((taxData) => {
            return taxData.defaultData === true;
          });

        setReasonData(_.get(response, 'dataReason') ? _.get(response, 'dataReason') : []);
        setDiscountData(_.get(response, 'dataDiscount') ? _.get(response, 'dataDiscount') : []);
        setTaxData(_.get(response, 'dataTax') ? _.get(response, 'dataTax') : []);
        setDiscountSelete(resultDiscount ? resultDiscount : undefined);
        setTaxSelete(resultTax ? resultTax : undefined);
      };

      getDefault();
    }
  }, [visibleModal]);

  // const subTotalResult = useMemo(() => {
  //   const totalItem = itemObjectData && itemObjectData.map(item => { return parseFloat(item.totalPrice) });
  //   const numbers = totalItem.map((n) => { return parseFloat(n, 10); });
  //   const sumTotal = numbers && numbers.reduce((a, b) => a + b, 0);
  //   return _.floor(sumTotal, 2).toFixed(2)
  // }, [itemObjectData]);

  useEffect(() => {
    const totalItem =
      itemObjectData &&
      itemObjectData.map((item) => {
        return parseFloat(item.totalPrice);
      });
    const numbers = totalItem.map((n) => {
      return parseFloat(n, 10);
    });
    const sumTotal = numbers && numbers.reduce((a, b) => a + b, 0);
    setSubTotalResult(_.floor(sumTotal, 2).toFixed(2));
  }, [itemObjectData, visibleAddItem]);

  let valueSubTotalResult = parseFloat(subTotalResult).toFixed(2);

  const handleCancel = () => {
    setVisibleModal(false);
    formRefSetting.resetFields();
    setRemark('');
    setItemObjectData([]);
    setSelectCustomer(); // เลือก Customer
    setSelectAddress(); // เลือก Address
    setSelectWareHouse(); // เลือก Warehouse
    setSelectContactData(); // Data ตั้งต้น Edit contact
    setSelectAddressData(); // Data ตั้งต้น Edit Address
    setDiscountSelete({});
    setTaxSelete({});
    setAddCustomerData(); // สร้าง New Customer
    setAddAddressData(); // สร้าง New Address
    setAddContactData(); // สร้าง New Contact
    setStatusSet(false);
    setPolyline([]);
    setDistance();
    setMarker();
  };

  const handleSave = (status) => {
    formRefSetting.validateFields(async (err, values) => {
      if (err) {
        return;
      }
      setCheckButton(true);

      const body = {
        status: status,
        customerId: values.customer,
        addressbookId: values.address,
        contactId: values.contact,
        memComId: values.sale,
        warehouseId: values.warehouse,
        remark: remark,
        taxId: taxSelete.taxId ? taxSelete.taxId : undefined,
        discountId: discountSelete.discountId ? discountSelete.discountId : undefined,
        subTotal: parseFloat(valueSubTotalResult),
        discount: discountSelete.percentage ? discountSelete.percentage : valueInputDiscount,
        tax: taxSelete.percentage ? taxSelete.percentage : 0,
        total: sumCalculate && sumCalculate.total ? parseFloat(sumCalculate.total) : 0,
        print: 0,
        detail: itemObjectData,
      };

      const response = await createQuotation(body);

      if (response.status === 200) {
        setCheckButton(false);
        successNotification(response.message);
        setTriggerApi((current) => !current);
        setVisibleModal(false);
        setRemark('');
        setItemObjectData([]);
        formRefSetting.resetFields();
        setDiscountSelete({});
        setTaxSelete({});
        setSelectCustomer(); // เลือก Customer
        setSelectAddress(); // เลือก Address
        setSelectWareHouse(); // เลือก Warehouse
        setSelectContactData(); // Data ตั้งต้น Edit contact
        setSelectAddressData(); // Data ตั้งต้น Edit Address
        setAddCustomerData(); // สร้าง New Customer
        setAddAddressData(); // สร้าง New Address
        setAddContactData(); // สร้าง New Contact
        setStatusSet(false);
        setValueInputDiscount(0.0);
        if (_.isFunction(onCustomerSelect)) onCustomerSelect(values.customer, true);
        setPolyline([]);
        setDistance();
        setMarker();
      } else {
        setCheckButton(false);
      }
    });
  };

  const saveFormRefSetting = useCallback((node) => {
    if (node !== null) {
      setFormRefSetting(node);
    }
  }, []);

  const handleDeleteItem = (record, index) => {
    const columnNew = itemObjectData && itemObjectData.filter((col, i) => i !== index);
    setItemObjectData(columnNew);
  };

  const onChangeDiscount = (value, option) => {
    if (value) {
      const result =
        discountData &&
        discountData.filter((item) => {
          return item.discountId === value;
        });
      setDiscountSelete(result[0]);
      setValueInputDiscount(0.0);
    }
  };

  const onChangeTax = (value, option) => {
    if (value) {
      const result =
        taxData &&
        taxData.filter((item) => {
          return item.taxId === value;
        });
      setTaxSelete(result[0]);
    }
  };

  const onChangeInput = (value) => {
    if (value === undefined) {
      setValueInputDiscount('');
    } else {
      if (parseFloat(valueSubTotalResult) <= parseFloat(value)) {
        setValueInputDiscount(0.0);
      } else {
        setValueInputDiscount(value);
      }
    }
  };

  const handleOpenAddItem = () => {
    setVisibleAddItem(true);
  };

  const handleAppplyItem = () => {
    setVisibleAddItem(false);
  };

  const handleCancelItem = () => {
    setVisibleAddItem(false);
  };

  return (
    <div className="quotation-modal">
      <QuotationContext.Provider
        value={{
          discountSelete,
          remark,
          taxSelete,
          discountData,
          taxData,
          valueInputDiscount,
          subTotalResult,
          valueSubTotalResult,
          itemObjectData,
          setStateMain: {
            setSumCalculate,
          },
          fnc: {
            onChangeDiscount,
            onChangeTax,
            onChangeInput,
          },
        }}
      >
        <Modal
          className="quotation-card-modal"
          title={intl.formatMessage({ id: 'quotationModalTitleCreateQuotation', defaultMessage: 'Create Quotation' })}
          centered="true"
          width={1100}
          visible={visibleModal}
          onCancel={handleCancel}
          footer={[
            <>
              <Button02 style={{margin : '0px 0px 0px 10px'}} key="back" btnsize="wd_df" onClick={handleCancel}>
                <FormattedMessage id="quotationModalBtnCancel" defaultMessage="Cancel" />
              </Button02>
              <Button02 style={{margin : '0px 0px 0px 10px'}}
                key="back"
                btnsize="wd_df"
                disabled={_.size(itemObjectData) !== 0 || checkButton ? false : true}
                onClick={() => handleSave('draft')}
              >
                <FormattedMessage id="quotationModalBtnDraft" defaultMessage="Draft" />
              </Button02>
              <Button01
                key="submit"
                type="primary"
                disabled={_.size(itemObjectData) !== 0 || checkButton ? false : true}
                btnsize="wd_df"
                onClick={() => handleSave('waiting')}
              >
                <FormattedMessage id="quotationModalBtnSave" defaultMessage="Save" />
              </Button01>
            </>,
          ]}
        >
          {/* <Spin spinning={loading}> */}
          <div className="card-modal-body">
            <div className="modal-container">
              <QuotationSetting
                ref={saveFormRefSetting}
                value={{
                  customerData,
                  addressData,
                  contactData,
                  AddCustomerData,
                  warehouseData,
                  AddContactData,
                  editContactData,
                  AddAddressData,
                  loading,
                  defaultMembers,
                  selectCustomer,
                  selectAddress,
                  selectWareHouse,
                  visibleEditContact,
                  statusSet,
                  triggerAddress,
                }}
                fnc={{
                  setVisibleViewCustomer,
                  setSelectCustomer,
                  setVisibleAddCustomer,
                  setVisibleEditCustomer,
                  setLoading,
                  setVisibleAddContact,
                  setVisibleEditContact,
                  setSelectContactData,
                  setVisibleAddAddress,
                  setSelectAddressData,
                  setVisibleEditAddress,
                  setSelectAddress,
                  setSelectWareHouse,
                  setItemObjectData,
                  setStatusSet,
                  setAddContactData,
                }}
              />
            </div>

            <div className="modal-container">
              <QuotationReport item={itemObjectData} columns={columns} onOpen={handleOpenAddItem} />
            </div>

            <div className="modal-container">
              <QuotationCalculate remark={remark} setRemark={setRemark} />
            </div>
          </div>
          {/* </Spin> */}
        </Modal>

        <QuotationItem
          value={{
            visibleAddItem,
            customerData,
            selectCustomer,
            selectAddress,
            selectWareHouse,
            warehouseData,
            defaultProducts,
            defaultVehicles,
            defaultUnits,
            itemObjectData,
            discountSelete,
            taxSelete,
            taxData,
            discountData,
            valueInputDiscount,
            subTotalResult,
            marker,
            polyline,
            distance,
          }}
          fnc={{
            setItemObjectData,
            handleAppplyItem,
            handleCancelItem,
            setTriggerCustomer,
            onChangeDiscount,
            onChangeTax,
            onChangeInput,
            setSelectAddress,
            setSelectCustomer,
            setSelectWareHouse,
            setStatusSet,

            setSubTotalResult,
            setDiscountSelete,
            setValueInputDiscount,
            setMarker,
            setPolyline,
            setDistance,
            setDefaultProducts,
            setDefaultVehicles,
            setDefaultUnits,
          }}
        />

        <Addcustomer
          visible={visibleAddCustomer}
          setVisible={setVisibleAddCustomer}
          setTrigger={setTriggerCustomer}
          setCustomerData={setAddCustomerData}
        />

        <EditbusinessPartner
          visible={visibleEditCustomer}
          setVisible={setVisibleEditCustomer}
          setTrigger={setTriggerCustomer}
          CustomerData={selectCustomer}
          // setCustomerData={setCustomerDataDefalse}
        />

        <ViewCustomer visible={visibleViewCustomer} setVisible={setVisibleViewCustomer} customer={selectCustomer} />

        <AddContact
          visible={visibleAddContact}
          setVisible={setVisibleAddContact}
          customerId={_.get(selectCustomer, 'customerId')}
          setContactID={setAddContactData}
          setTriggerAPI={setTriggerAddress}
        />

        <EditContact
          visible={visibleEditContact}
          setVisible={setVisibleEditContact}
          customerId={_.get(selectCustomer, 'customerId')}
          setTriggerAPI={setTriggerAddress}
          setContactID={setEditContactData}
          contactData={selectContactData}
        />

        <AddAddress
          visible={visibleAddAddress}
          setVisible={setVisibleAddAddress}
          customerId={_.get(selectCustomer, 'customerId')}
          customerName={_.get(selectCustomer, 'customerName')}
          setTrigger={setTriggerAddress}
          toLocation={setAddAddressData}
        />

        <EditAddress
          visible={visibleEditAddress}
          setVisible={setVisibleEditAddress}
          setTrigger={setTriggerAddress}
          valueDefault={selectAddressData}
          //  setValueDefault={setValueDefaultEdit}
          customerId={_.get(selectCustomer, 'customerId')}
        />
      </QuotationContext.Provider>
    </div>
  );
};

export default CreateQuotation;
