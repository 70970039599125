import React from 'react';
import { Checkbox, Row, Col, Avatar, Badge, Icon, Divider } from 'antd';
import stylesEngFilter from '../css/engagement-filter.css';
import InfiniteScroll from 'react-infinite-scroller';
import _ from 'lodash';
import { useIntl, FormattedMessage } from 'react-intl';
import Nodata from '../../../components/image/Nodata.svg';

export default ({ customers, onSelectCustomer, onLoadMore, customerPageLoading, customerPageHasMore }) => {
  const renderCustomer = () =>
    customers &&
    customers.map((e) => {
      return (
        <div key={e.customerId} className="customer-engagement-list" onClick={() => onSelectCustomer(e.customerId)}>
          <Row className="padding-between-item-engage-list">
            <Col span={5}>
              <Badge
                className="badge-style-member"
                count={
                  e.selected === true ? (
                    <Icon type="check-circle" theme="filled" style={{ color: '#6490cf' }} className="icon-correct-member" />
                  ) : (
                    undefined
                  )
                }
              >
                <Avatar size="large" shape="circle" src={e.pathImage} />
              </Badge>
            </Col>
            <Col span={19}>
              <div>
                <div className="engagement-hidden-text">{e.fullname}</div>
                <div>
                  <span className="custype-bustype-engage">
                    {e.businessType}, {e.customerType}
                  </span>
                </div>
              </div>
            </Col>
          </Row>
          <Divider className="divider-style-enge" type="horizontal" />
        </div>
      );
    });

  return (
    <div className="customer-engagement-list-all">
      <InfiniteScroll
        initialLoad={false}
        pageStart={1}
        loadMore={(page) => onLoadMore(page)}
        hasMore={customerPageLoading && customerPageHasMore}
        useWindow={false}
        threshold={25}
      >
        {_.size(customers) !== 0 ? (
          renderCustomer()
        ) : (
          <div className="postby-no-member">
            <div>
              <img src={Nodata} style={{ width: '100px' }}></img>
            </div>
            <div style={{ color: '#e4e4e4' }}>
              <FormattedMessage id="textNoData" defaultMessage="No Data" />
            </div>
          </div>
        )}
      </InfiniteScroll>
    </div>
  );
};
