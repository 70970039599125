import React, { useState, useEffect, useContext } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useAppContext } from '../../../../includes/indexProvider';
import { Card, Table, Button, notification, Icon } from 'antd';
import { debounce } from 'lodash';
import { OverTimeContext } from '../report-context';
import ActionTaskReport from './action-task-report';
import httpClient from '../../../../components/axiosClient';
import _ from 'lodash';
import moment from 'moment';
import IconExcel from '../../../../components/image/excel.svg';
import './css/task-report.css';
import cssStyleCenter from '../report-center.css';
import { PageSettings } from '../../../../config/page-settings';
import { Link } from 'react-router-dom';

export default (props) => {
  const { LangCode } = props;
  const intl = useIntl();
  const app = useAppContext();
  const langValue = app.state.langValue;
  const { state, setState, fnc } = useContext(OverTimeContext);

  // const today = new Date();

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: 70,
      align: 'center',
      fixed: 'left',
      render: (text, record, index) => record.index,
    },
    {
      title: intl.formatMessage({ id: 'reportTaskReportViewTableTaskNo', defaultMessage: 'Task No' }),
      dataIndex: 'taskNo',
      key: 'taskNo',
      width: 180,
      fixed: 'left',
      sorter: (a, b) => a.taskNo - b.taskNo,
      sortDirections: ['descend', 'ascend'],
      // render: (text, record, index) => (
      //   <a className="list-view-a-task-view" key={record.task_id}>{record.taskNo}</a>
      // )
    },
    {
      title: intl.formatMessage({ id: 'reportTaskReportViewTableTaskType', defaultMessage: 'Task Type' }),
      dataIndex: 'taskType',
      key: 'taskType',
      width: 180,
      // fixed: 'left',
      sorter: (a, b) => a.taskType - b.taskType,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: 'reportTaskReportViewTableCustomer', defaultMessage: 'Customer' }),
      dataIndex: 'customer',
      key: 'customer',
      width: 200,
      // fixed: 'left',
      sorter: (a, b) => a.customer - b.customer,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: 'reportTaskReportViewTableContactName', defaultMessage: 'Contact Name' }),
      dataIndex: 'contactName',
      key: 'contactName',
      width: 220,
      // fixed: 'left',
      sorter: (a, b) => a.contactName - b.contactName,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: 'reportTaskReportViewTableStartDate', defaultMessage: 'Start Date' }),
      dataIndex: 'startDate',
      key: 'startDate',
      sorter: (a, b) => a.startDate - b.startDate,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: 'reportTaskReportViewTableDueDate', defaultMessage: 'Due Date' }),
      dataIndex: 'dueDate',
      key: 'dueDate',
      sorter: (a, b) => a.dueDate - b.dueDate,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: 'reportTaskReportViewTableActualStartDate', defaultMessage: 'Actual Start Date' }),
      dataIndex: 'actualStartDate',
      key: 'actualStartDate',
      sorter: (a, b) => a.actualStartDate - b.actualStartDate,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: 'reportTaskReportViewTableActualCompletedDate', defaultMessage: 'Actual Completed Date' }),
      dataIndex: 'actualCompletedDate',
      key: 'actualCompletedDate',
      sorter: (a, b) => a.actualCompletedDate - b.actualCompletedDate,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: 'reportTaskReportViewTableContactPhone', defaultMessage: 'Contact Phone' }),
      dataIndex: 'contactPhone',
      key: 'contactPhone',
      sorter: (a, b) => a.contactPhone - b.contactPhone,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: 'reportTaskReportViewTableFromLocation', defaultMessage: 'From Location' }),
      dataIndex: 'fromLocation',
      key: 'fromLocation',
    },
    {
      title: intl.formatMessage({ id: 'reportTaskReportViewTableToLocation', defaultMessage: 'To Location' }),
      dataIndex: 'toLocation',
      key: 'toLocation',
    },
    {
      title: intl.formatMessage({ id: 'reportTaskReportViewTableStatus', defaultMessage: 'Status' }),
      dataIndex: 'status',
      key: 'status',
      sorter: (a, b) => a.status - b.status,
      sortDirections: ['descend', 'ascend'],
    },

    {
      title: intl.formatMessage({ id: 'reportTaskReportViewTableRemark', defaultMessage: 'Remark' }),
      dataIndex: 'remark',
      key: 'remark',
    },
    {
      title: intl.formatMessage({ id: 'reportTaskReportViewTableCreatedDate', defaultMessage: 'Created Date' }),
      dataIndex: 'createdDate',
      key: 'createdDate',
      sorter: (a, b) => a.createdDate - b.createdDate,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: 'reportTaskReportViewTableReference1', defaultMessage: 'Reference1' }),
      dataIndex: 'reference1',
      key: 'reference1',
      sorter: (a, b) => a.reference1 - b.reference1,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: 'reportTaskReportViewTableReference2', defaultMessage: 'Reference2' }),
      dataIndex: 'reference2',
      key: 'reference2',
      sorter: (a, b) => a.reference - b.reference,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: 'reportTaskReportViewTableReference3', defaultMessage: 'Reference3' }),
      dataIndex: 'reference3',
      key: 'reference3',
      sorter: (a, b) => a.reference - b.reference,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: 'reportTaskReportViewTableAssigneeName', defaultMessage: 'Assignee Name' }),
      dataIndex: 'assigneeName',
      key: 'assigneeName',
      sorter: (a, b) => a.assigneeName - b.assigneeName,
      sortDirections: ['descend', 'ascend'],
    },
  ];

  const setShowColumn = {
    index: true,
    taskNo: true,
    taskType: true,
    customer: true,
    contactName: true,
    startDate: true,
    dueDate: true,
    actualStartDate: true,
    actualCompletedDate: true,
    contactPhone: false,
    fromLocation: false,
    toLocation: false,
    status: false,
    remark: false,
    createdDate: false,
    reference1: false,
    reference2: false,
    reference3: false,
    assigneeName: false,
  };

  const setShowColumnArr = [
    'index',
    'taskNo',
    'taskType',
    'customer',
    'contactName',
    'startDate',
    'dueDate',
    'actualStartDate',
    'actualCompletedDate',
  ];

  const ignoreColumn = [
    'contactPhone',
    'fromLocation',
    'toLocation',
    'status',
    'remark',
    'createdDate',
    'reference1',
    'reference2',
    'reference3',
    'assigneeName',
  ];
  const newColumns = columns.filter((col) => !ignoreColumn.includes(col.key));

  const today = new Date();
  const dateFormat = 'ddd, MMM DD YYYY';
  const dateNow = moment(today).format('YYYY-MM');
  const dateNowRangePicker = [moment(today, dateFormat), moment(today, dateFormat)];

  const [loading, setLoading] = useState(false);
  const [taskReport, setTaskReport] = useState([]);
  const [visible, setVisible] = useState(false);
  const [defaultShowColumn, setDefaultShowColumn] = useState({ ...setShowColumn });
  const [listArrayColumns, setListArrayColumns] = useState([...setShowColumnArr]);

  const [paginationPage, setPaginationPage] = useState(1);
  const [paginationSize, setPaginationSize] = useState(10);

  const [textErrorSelectColumn, setTextErrorSelectColumn] = useState('');
  const [newDataColumns, setNewDataColumns] = useState([...newColumns]);

  const [selectSearchData, setSelectSearchData] = useState('all');
  const [fieldChang, setFieldChang] = useState('');
  const [refreshTable, setRefreshTable] = useState(false);

  const [rangePicker, setRangePicker] = useState(dateNowRangePicker);
  const [fromDate, setFromDate] = useState(moment(today).format('YYYY-MM-DD'));
  const [toDate, setToDate] = useState(moment(today).format('YYYY-MM-DD'));
  const [total, setTotal] = useState();
  const [conutrowForExcel, setConutrowForExcel] = useState();
  const [extraSort, setExtraSort] = useState();
  const [fieldSort, setFieldSort] = useState();
  const [orderSort, setOrderSort] = useState();
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    getTaskReport();
  }, [fieldChang, rangePicker, state.checkedKeys, extraSort, selectSearchData, state.selectedKeys]);

  const getTaskReport = async () => {
    setLoading(true);
    try {
      const body = {
        fromDate: fromDate,
        utilDate: toDate,
        orgId: _.size(state.checkedKeys) !== 0 ? state.checkedKeys : state.selectedKeys,
        search: fieldChang,
        searchBy: selectSearchData,
        paging: fieldChang === '' ? paginationPage : 1,
        rowsPerPages: '1000',
        ordertype: orderSort,
        orderby: fieldSort,
      };

      //const response = await httpClient.post(`/v1/resource/manager/company/${state.comId}/report/task`, body);
      const response = await httpClient.post(`/v3/task/manager/company/${state.comId}/taskreport?menuType=transportation`, body);
      if (response.status === 200) {
        setTaskReport(_.get(response, 'data.data.result'));
        setTotal(response.data.data.result.length);
        setConutrowForExcel(response.data.summary.conutrowForExcel);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const checkedValuecolumns = (e) => {
    let checked = e.target.checked;
    let val = e.target.value;
    let showColumn = { ...defaultShowColumn };
    if (checked === true) {
      const found = listArrayColumns.find((element) => element === val);
      if (found === undefined) {
        setListArrayColumns([...listArrayColumns, val]);
      }
      showColumn[val] = true;
    } else {
      const newListArray = [...listArrayColumns];
      const found = listArrayColumns.findIndex((element) => element === val);
      if (found >= 0) {
        newListArray.splice(found, 1);
        setListArrayColumns(newListArray);
      }
      showColumn[val] = false;
    }
    setDefaultShowColumn(showColumn);
  };

  const handleCheckOk = () => {
    const setNewArr = [];
    if (listArrayColumns.length <= 5) {
      setTextErrorSelectColumn(<FormattedMessage id="lblSelectAtLeastColumn" defaultMessage="Select at least 1 column." />);
      return;
    } else {
      setTextErrorSelectColumn('');
    }
    for (let i = 0; i < columns.length; i++) {
      const found = listArrayColumns.find((element) => element == columns[i].key);
      if (found != undefined) {
        setNewArr.push(columns[i]);
      }
    }
    setNewDataColumns(setNewArr);
    handleOnVisiblecolumns();
  };

  const handleReset = () => {
    const newListArrayColumns = [];
    newColumns.forEach((element) => {
      newListArrayColumns.push(element.key);
    });
    setNewDataColumns(newColumns);
    setListArrayColumns(newListArrayColumns);
    handleOnVisiblecolumns();
    setDefaultShowColumn(setShowColumn);
    setTextErrorSelectColumn('');
  };

  const handleOnVisiblecolumns = () => {
    setVisible(!visible);
  };

  const handleVisibleChange = (flag) => {
    setVisible(flag);
  };

  const handleSearch = debounce((fieldChang) => {
    setFieldChang(fieldChang ? fieldChang : '');
  }, 500);

  const handleSelectData = (value) => {
    setSelectSearchData(value === undefined ? 'all' : value);
  };

  const onSelectRangePicker = (date, dateString) => {
    setRangePicker(dateString);
    setFromDate(moment(date[0]).format('YYYY-MM-DD'));
    setToDate(moment(date[1]).format('YYYY-MM-DD'));
  };

  const handlePagination = (page) => {
    setPaginationPage(page);
  };

  const handleSizeChange = (current, size) => {
    setPaginationSize(size);
    setPaginationPage(current);
  };

  const handleChange = (pagination, sorter, extra) => {
    let field = extra.field;
    // let order = extra.order === 'descend' ? 'desc' : 'asc';
    if (extra.order) {
      if (extra.order === 'descend') {
        setOrderSort('desc');
      } else {
        setOrderSort('asc');
      }
    } else {
      setOrderSort('');
    }
    setExtraSort(extra);
    setFieldSort(field);
  };

  const handleExport = async () => {
    setDisabled(true);
    try {
      const body = {
        fromDate: fromDate,
        utilDate: toDate,
        orgId: state.checkedKeys,
        search: fieldChang,
        searchBy: selectSearchData,
        paging: paginationPage === 1 ? paginationPage : 1,
        rowsPerPages: conutrowForExcel,
        orderby: fieldSort,
        ordertype: orderSort,
        viewFormat: [
          {
            colName: intl.formatMessage({ id: 'reportTaskReportViewTableTaskNo', defaultMessage: 'Task No' }),
            sequence: 1,
            colCode: 'taskNo',
          },
          {
            colName: intl.formatMessage({ id: 'reportTaskReportViewTableTaskType', defaultMessage: 'Task Type' }),
            sequence: 2,
            colCode: 'taskType',
          },
          {
            colName: intl.formatMessage({ id: 'reportTaskReportViewTableCustomer', defaultMessage: 'Customer' }),
            sequence: 3,
            colCode: 'customer',
          },
          {
            colName: intl.formatMessage({ id: 'reportTaskReportViewTableContactName', defaultMessage: 'Contact Name' }),
            sequence: 4,
            colCode: 'contactName',
          },
          {
            colName: intl.formatMessage({ id: 'reportTaskReportViewTableStartDate', defaultMessage: 'Start Date' }),
            sequence: 5,
            colCode: 'startDate',
          },
          {
            colName: intl.formatMessage({ id: 'reportTaskReportViewTableDueDate', defaultMessage: 'Due Date' }),
            sequence: 6,
            colCode: 'dueDate',
          },
          {
            colName: intl.formatMessage({ id: 'reportTaskReportViewTableActualStartDate', defaultMessage: 'Actual Start Date' }),
            sequence: 7,
            colCode: 'actualStartDate',
          },
          {
            colName: intl.formatMessage({ id: 'reportTaskReportViewTableActualCompletedDate', defaultMessage: 'Actual Completed Date' }),
            sequence: 8,
            colCode: 'actualCompletedDate',
          },
          {
            colName: intl.formatMessage({ id: 'reportTaskReportViewTableContactPhone', defaultMessage: 'Contact Phone' }),
            sequence: 9,
            colCode: 'contactPhone',
          },
          {
            colName: intl.formatMessage({ id: 'reportTaskReportViewTableFromLocation', defaultMessage: 'From Location' }),
            sequence: 10,
            colCode: 'fromLocation',
          },
          {
            colName: intl.formatMessage({ id: 'reportTaskReportViewTableToLocation', defaultMessage: 'To Location' }),
            sequence: 11,
            colCode: 'toLocation',
          },
          {
            colName: intl.formatMessage({ id: 'reportTaskReportViewTableStatus', defaultMessage: 'Status' }),
            sequence: 12,
            colCode: 'status',
          },
          {
            colName: intl.formatMessage({ id: 'reportTaskReportViewTableRemark', defaultMessage: 'Remark' }),
            sequence: 13,
            colCode: 'remark',
          },
          {
            colName: intl.formatMessage({ id: 'reportTaskReportViewTableCreatedDate', defaultMessage: 'Created Date ' }),
            sequence: 14,
            colCode: 'createdDate',
          },
          {
            colName: intl.formatMessage({ id: 'reportTaskReportViewTableReference1', defaultMessage: 'Reference1' }),
            sequence: 15,
            colCode: 'reference1',
          },
          {
            colName: intl.formatMessage({ id: 'reportTaskReportViewTableReference2', defaultMessage: 'Reference2' }),
            sequence: 16,
            colCode: 'reference2',
          },
          {
            colName: intl.formatMessage({ id: 'reportTaskReportViewTableReference3', defaultMessage: 'Reference3' }),
            sequence: 17,
            colCode: 'reference3',
          },
          {
            colName: intl.formatMessage({ id: 'reportTaskReportViewTableAssigneeName', defaultMessage: 'Assignee Name' }),
            sequence: 18,
            colCode: 'assigneeName',
          },
        ],
      };
      // const response = await httpClient.post(`/v1/resource/manager/company/${state.comId}/export/report/overtime`, body);
      const response = await httpClient.post(
        `/v3/task/manager/company/${state.comId}/taskreport/exportexcel?menuType=transportation`,
        body
      );

      if (response.status === 200) {
        window.open(response.data.data.url);
        successNotification(response.data.status.message);
        setDisabled(false);
      } else {
        errorNotification(response.data.status.message);
      }
    } catch (error) {
      //errorNotification(error.response.data.status.message);
    }
  };

  const successNotification = (message) => {
    notification.success({
      message: message,
    });
  };

  const errorNotification = (message) => {
    notification.success({
      message: message,
    });
  };

  const showTotal = (total) => {
    return (
      <span style={{ fontSize: '13px' }}>
        <FormattedMessage id="lblRowPerPage" defaultMessage="Rows per page: " />
      </span>
    );
  };

  const iconExcel = () => <img className="icon-excel" src={IconExcel} />;

  const HeaderAndToggle = () => {
    return (
      <div style={{ display: 'flex' }}>
        <div style={{ cursor: 'pointer' }} onClick={fnc.handleToggle}>
          <Icon type={state.toggle ? 'menu-unfold' : 'menu-fold'} style={{ color: '#e73845' }} />
        </div>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <div>
          <p>
            <FormattedMessage id="reportTaskReportViewTitle" defaultMessage="Task Report" />
          </p>
        </div>
      </div>
    );
  };

  return (
    <OverTimeContext.Consumer>
      {() => (
        <Card
          className="task-report-card"
          title={<HeaderAndToggle />}
          extra={
            <Button className="button-link-export" type="link" onClick={handleExport} disabled={disabled}>
              <FormattedMessage id="btnExport" defaultMessage="Export" /> <Icon component={iconExcel} />
            </Button>
          }
        >
          <ActionTaskReport
            columns={columns}
            handleReset={handleReset}
            handleCheckOk={handleCheckOk}
            visible={visible}
            handleVisibleChange={handleVisibleChange}
            checkedValuecolumns={checkedValuecolumns}
            onChange={handleSearch}
            onSelect={handleSelectData}
            selectSearchData={selectSearchData}
            checked={defaultShowColumn}
            textErrorSelectColumn={textErrorSelectColumn}
            handleOnVisiblecolumns={handleOnVisiblecolumns}
            onSelectRangePicker={onSelectRangePicker}
          />

          <Table
            className="report-table"
            rowKey={(record) => record.index}
            onChange={handleChange}
            loading={loading}
            dataSource={taskReport}
            columns={newDataColumns}
            // scroll={overtimeReport && overtimeReport.length ? { x: 3000 } : { x: null }}
            // scroll={{ x: 3500, y: 550 }}
            scroll={{ x: 3500, y: `calc(100vh - 392px)` }}
            pagination={{
              total: total,
              showTotal: showTotal,
              defaultCurrent: 1,
              pageSizeOptions: ['10', '20', '30', '40', '50'],
              showSizeChanger: true,
              locale: { items_per_page: '' },
              onChange: handlePagination,
              onShowSizeChange: handleSizeChange,
            }}
          />

          {taskReport && taskReport.length !== 0 ? (
            <div className="total-items-task-report">
              <span style={{ fontSize: '13px' }}>
                <FormattedMessage id="lblTotal" defaultMessage="Total" />
                {` ${total || 0} `}
                <FormattedMessage id="lblitems" defaultMessage="items" />
              </span>
            </div>
          ) : null}
        </Card>
      )}
    </OverTimeContext.Consumer>
  );
};
