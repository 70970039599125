import React, { useContext, useEffect, useState } from 'react';
import style from './css/public-holiday.css';
import { Card, Popover, Icon, Row, Col, Select, Button, Modal } from 'antd';
import Table from '../../../components/v2/table';
import Button1 from '../../../components/v2/button_01';
import ModalForm from '../holiday/public-holiday-modal';
import ModalFormAll from './public-holiday-modal-form';
import { ShiftSettings } from '../config/shift-type-settings';
import { PageSettings } from '../../../config/page-settings';
import moment from 'moment';
import { useIntl, FormattedMessage } from 'react-intl';

const { Option } = Select;

export default (props) => {
  const intl = useIntl();
  const { state, fnc } = useContext(ShiftSettings);
  const { mockApi, yearAll, year, publicDay, holidaysRow } = props;

  const [dataLive, setDataLive] = useState();
  const [languageLocal, setLanguageLocal] = useState([]);
  const [visiblePopover, setVisiblePopover] = useState(false);

  const [record, setRecord] = useState();

  const [sorterCol, setSorterCol] = useState();

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      width: '2vh',
      className: 'onTop',
      key: 'index',
      render: (text, record, index) => index + 1,
    },
    {
      title: intl.formatMessage({ id: 'settingHolidaysColDate', defaultMessage: 'Date' }),
      dataIndex: 'dateName',
      key: 'dateName',
      width: '20vh',
      className: 'onTop',
      defaultSortOrder: 'ascend',
      sorterOrder: state.sorter,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: intl.formatMessage({ id: 'settingHolidaysColLang', defaultMessage: 'Language' }),
      dataIndex: 'eventLanguage',
      key: 'eventLanguage',
      className: 'onTop',
      width: '14vh',
      render: (text, record) => Object.values(record.eventLanguage).map((item) => <div key={item}>{item}</div>),
    },
    {
      title: intl.formatMessage({ id: 'settingHolidaysColHolidayName', defaultMessage: 'Holiday name' }),
      dataIndex: 'eventName',
      key: 'eventName',
      className: 'onTop',
      width: '14vh',
      render: (text, record, index) => state.langLocal.map((o, i) => <div key={o}>{record.eventName[o]}</div>),
      // Object.values(record.eventName).map(item =>
      //   <div key={item}>{item}</div>
      // )
    },
    {
      title: intl.formatMessage({ id: 'settingHolidaysColRepeat', defaultMessage: 'Annual repeat' }),
      dataIndex: 'annullyRepeat',
      key: 'annullyRepeat',
      className: 'onTop',
      width: '14vh',
      render: (text, record) => {
        if (record.annullyRepeatStatus === true) {
          return <div>{record.annullyRepeatText}</div>;
        } else {
          return <div></div>;
        }
      },
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      className: 'onTop',
      width: '5%',
      render: (text, record, index) => (
        <PageSettings.Consumer>
          {({ checkPermissionAction }) =>
            checkPermissionAction('P6S4PG1C2', 'P6S4PG1C2A3') || checkPermissionAction('P6S4PG1C2', 'P6S4PG1C2A4') ? (
              <Popover
                key={index}
                content={actionPopover(record)}
                placement="leftTop"
                visiblePopover={visiblePopover}
                onVisibleChange={handleOnVisiblePopover}
              >
                <Icon type="more" />
              </Popover>
            ) : null
          }
        </PageSettings.Consumer>
      ),
    },
  ];

  useEffect(() => {}, []);

  const sorterDate = (pagination, filters, sorter) => {
    let sortCol = sorter.order ? sorter.order : 'ascend';
    fnc.setSorter(sortCol);
  };

  const dataLanguage = () => {
    setLanguageLocal(state.publicDay.language);
  };

  const handleOpenModalAdd = () => {
    setRecord();
    dataLanguage();
    fnc.setVisible(true);
  };

  const hidePopover = (e) => {
    e.preventDefault();
    setVisiblePopover(false);
  };

  const handleOnVisiblePopover = (visiblePopover) => {
    setVisiblePopover(visiblePopover);
  };
  const onChangeYears = (year) => {
    fnc.setFilterYear(year);
  };
  const handleEditHoliday = (record) => {
    if (record) {
      dataLanguage();
      setRecord(record);
      fnc.setVisible(true);
    }
  };

  const actionPopover = (service) => {
    const confirm = (service) => {
      Modal.confirm({
        className: 'services-modal-confirm',
        icon: <Icon type="delete" style={{ color: '#FF0000' }} />,
        title: intl.formatMessage({ id: 'lblDelete', defaultMessage: 'Are you sure you want to delete?' }),
        okText: intl.formatMessage({ id: 'settingHolidaysModalBtnConfirm', defaultMessage: 'Confirm' }),
        cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
        okButtonProps: {
          style: {
            width: '96px',
          },
          type: 'primary',
        },
        cancelButtonProps: {
          style: {
            width: '96px',
          },
          type: 'danger',
        },
        onOk() {
          fnc.handleDelete(service);
        },
        onCancel() {},
      });
    };
    return (
      <PageSettings.Consumer>
        {({ checkPermissionAction }) => (
          <div className="text-left" onClick={hidePopover}>
            {checkPermissionAction('P6S4PG1C2', 'P6S4PG1C2A3') ? (
              <div style={{ paddingTop: 10 }}>
                <Button className="styledPopDelete" btnsize={'wd_df'} type="link" ghost onClick={() => handleEditHoliday(service)}>
                  <p className="action-edit-p">
                    <FormattedMessage id="btnEdit" defaultMessage="Edit" />
                  </p>
                </Button>
              </div>
            ) : null}

            {checkPermissionAction('P6S4PG1C2', 'P6S4PG1C2A4') ? (
              <div>
                <Button className="styledPopDelete" btnsize={'wd_df'} type="link" ghost onClick={() => confirm(service)}>
                  <p className="action-delete-p">
                    <FormattedMessage id="btnDelete" defaultMessage="Delete" />
                  </p>
                </Button>
              </div>
            ) : null}
          </div>
        )}
      </PageSettings.Consumer>
    );
  };

  return (
    <PageSettings.Consumer>
      {({ checkPermissionAction }) => (
        <ShiftSettings.Consumer>
          {({ setVisible }) => (
            <div style={{ width: 'auto' }}>
              <Card
                title={<FormattedMessage id="settingHolidaysTxtPublicHoliday" defaultMessage="Public Holiday" />}
                className="letter-space border-card-top title-holiday"
              >
                <Row style={{ marginTop: '4px', marginBottom: '18px' }}>
                  <Col span={12}>
                    <Select style={{ width: '200px' }} defaultValue={state.filterYear} onChange={onChangeYears}>
                      {state.yearAll.map((item) => (
                        <Option key={item} value={String(item)}>
                          {item}
                        </Option>
                      ))}
                    </Select>
                  </Col>
                  <Col span={12}>
                    {checkPermissionAction('P6S4PG1C2', 'P6S4PG1C2A2') ? (
                      <Button1 className="add-button-right button-font-size" type="primary" btnsize="wd_df" onClick={handleOpenModalAdd}>
                        <FormattedMessage id="settingHolidaysBtnAdd" defaultMessage="Add new" />
                      </Button1>
                    ) : null}
                  </Col>
                </Row>
                <ModalForm record={record} setRecord={setRecord} languageLocal={languageLocal} langLocal={state.langLocal} />
                {/* <ModalFormAll
                  record={record}
                  setRecord={setRecord}
                  languageLocal={languageLocal}
                  langLocal={state.langLocal}
                /> */}

                <Table
                  columns={columns}
                  rowKey={(record) => record.comCalId}
                  dataSource={state.holidaysRow}
                  pagination={false}
                  onChange={sorterDate}
                />
              </Card>
            </div>
          )}
        </ShiftSettings.Consumer>
      )}
    </PageSettings.Consumer>
  );
};
