import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Divider, Form, Modal, Tabs, Tag } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import Button_02 from '../../../../components/v2/button_02';
import Button_01 from '../../../../components/v2/button_01';
import '../css/index.css';
import CustomerForm from './customer-form';
import CustomTableComponent from '../../components/table';
import { messageLabel } from '../../../../components/message-component';
import PriceForm from './price-form';
import { StickyContainer } from 'react-sticky';
import DetailsTab from './details-tab';
import PaymentTab from './payment-tab';
import CampainTab from './campain-tab';
import UploadFileModal from '../upload-file/upload-modal';
import { AccountReceiveContext } from '../../context';
import _ from 'lodash';
import ModalCreateListItem from '../list-item/add';
import ListItem from '../list-item/list/index';
import moment from 'moment';
import ModalSelectCustomerForm from '../select-customer/customer-modal';
import { numberFormatter } from '../../../../component-function/fnc-inputnumber-currency';
import PaymentTabView from './view/payment-pending';
import CampainTabView from './view/campain-pending';
import ModalApproveForm from '../approve/approve-modal';
import ModalCancelForm from '../cancel/cancel-modal';
import ModalRejectForm from '../reject/reject-modal';
import ModalWaitingForm from '../waiting/waiting-modal';
import ModalDraftForm from '../delete/delete-modal';
import ModalSelectQuotationForm from '../select-quotation/quotation-select-modal';
import DetailsTabPending from './view/detail-pending';
import ModalPaidForm from './paid-modal.js';
import { uploadARAttachment, uploadARAttachmentPaid } from '../../../../controllers/account-receive/index.js';
import { async } from 'q';
import ARPrint from '../../../../components/modelprint/ARPrint.js';
import { useReactToPrint } from 'react-to-print';
import ModelAddItemAR from './additem/index.js';
import DetailsTabPendingViewinvoice from './viewinvoice/detail-viewinvoice.js';
import ModalAddARItem from './additem/add-remove.js';

const { TabPane } = Tabs;

const QuotationModal = ({ form, title, visible, onOk, onCancel, attData, setAttData, record, signatureUser }) => {
  const { state, setState, func } = useContext(AccountReceiveContext);
  const {
    initialMemberList,
    initialTaxList,
    initialCampaignList,
    initialReasonList,

    setRecord,
    visibleApprove,
    visibleReject,
    visibleCancel,
    visibleDelete,
    visibleWaiting,
    isOpenQuotation,
    attachmentPaidData,
    visibleAddItem,
    pageTable,
    sizeTable,
    warehouseList,
    saleOrderQTY,
    recordList,
    viewDetail,
    totalWarehouseList,
    recordReport,
  } = state;
  const {
    setAttachmentPaidData,
    setPageTable,
    setSizeTable,
    setWarehouseList,
    setSaleOrderQTY,
    setRecordList,
    setTotalWarehouseList,
    setRecordReport,
  } = setState;
  const {
    handleOpenModalUpload,
    handleOpenApprove,
    handleSaveApprove,
    handleCancelApprove,
    handleOpenReject,
    handleSaveReject,
    handleCancelReject,
    handleOpenCancel,
    handleSaveCancel,
    handleCancelCancelQT,
    handleOpenDelete,
    handleSaveDelete,
    handleCancelDelete,
    handleSaveWaiting,
    handleCancelWaiting,
    handleOpenWaiting,
    handleOpenPaid,
    handleCancelPaid,
    handleOpenAddItem,
    handleCancelAddItem,
    handleCleckSaleOrder,
    handleOpenViewInvoice,
  } = func;
  const [dataForm, setDataForm] = useState();
  const [tabkeys, setTabkeys] = useState('detail');
  const [visibleItem, setVisibleItem] = useState(false);
  const [visibleAdd, setVisibleAdd] = useState(false);
  const [selectItem, setSelectItem] = useState([]);
  const [selectWHData, setSelectWHData] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenQt, setIsOpenQt] = useState(false);
  const [paymentItem, setPaymentItem] = useState([]);
  const [tempARAtt, setTempARAtt] = useState([]);

  // console.log('form data for save', dataForm, selectItem);
  console.log('QuotationModal dataForm', dataForm);

  const intl = useIntl();
  const { setFieldsValue, resetFields } = form;
  const formCustomerRef = useRef();
  const ARPrintrRef = useRef();

  console.log('formCustomerRefAR', record);

  useEffect(() => {
    if (_.get(record, 'quotationId') && visible && _.get(record, 'code')) {
      console.log('dataFormUseEffect', dataForm, record);
      console.log('dataFormUseEffect', record);
      setDataForm(record);
    }
  }, [record, visible]);

  useEffect(() => {
    if (record) {
      if (_.get(record, 'code') === 'view') {
        setDataForm({ ..._.get(record, 'saleOrderDetail'), code: _.get(record, 'code') });
      } else {
        setDataForm(record);
      }

      const itemList = _.map(_.get(record, 'itemList'), (o, i) => ({
        ...o,
        index: i + 1,
      }));

      setSelectItem(itemList);

      const setobj = _.map(_.get(record, 'attanchment'), (o, i) => ({
        ...o,
        index: i + 1,
        attachmentName: _.get(o, 'fileHash'),
        remark: _.get(o, 'remark'),
      }));
      setAttData(setobj);
    }
  }, [record]);

  useEffect(() => {
    if (_.get(dataForm, 'customerId')) {
      setFieldsValue({ customerName: _.get(dataForm, 'customerName') });
    }
    if (_.get(dataForm, 'quotationObj.quotationId')) {
      setFieldsValue({ quotationNo: _.get(dataForm, 'quotationObj.quotationNo') });
    }
  }, [dataForm]);

  useEffect(() => {
    console.log('selectItem', selectItem);
    if (_.size(selectItem) > 0) {
      let cloneItem = [...selectItem];
      console.log('cloneItem', cloneItem);

      let allCredits = [];
      let data = [];

      for (let i = 0; _.size(selectItem) > i; i++) {
        if (!_.includes(allCredits, cloneItem[i].credits)) {
          console.log('allCredits -> ', allCredits, cloneItem[i].credits);

          const filterItem = _.filter(selectItem, (obj) => obj.credits === cloneItem[i].credits);

          let total = cloneItem[i].totalPrice;

          if (_.size(filterItem) > 0) {
            const mapTotal = _.map(filterItem, (v, i) => {
              return {
                totalPrice: _.toNumber(v.totalPrice),
              };
            });
            total = _.sumBy(mapTotal, 'totalPrice');
            allCredits.push(cloneItem[i].credits);
          }
          data.push({
            credit: cloneItem[i].credits,
            amount: total,
          });
        }
      }

      console.log('Quotation soby', data, _.sortBy(data, 'credit'));

      if (_.size(data) > 0) {
        const sortBy = _.sortBy(data, (o) => _.toNumber(o.credit));
        const mapIndex = _.map(sortBy, (o, i) => {
          return {
            ...o,
            index: i + 1,
            remark: '',
            dueDate: moment().add(o.credit, 'day'),
          };
        });
        setPaymentItem(mapIndex);
      }
    }
  }, [selectItem]);

  const handleSave = async (status) => {
    console.log('handleSave', dataForm, status);

    form.validateFields(async (err, values) => {
      if (err) {
        return;
      }

      const itemList = _.map(selectItem, (o) => ({
        saleOrderItemId: o.saleOrderItemId,
        totaQty: o.totaQty,
        warehouseSeletedList: o.warehouseSeletedList,
      }));

      let att = await Promise.all(
        _.map(attData, async (p) => {
          const formData = new FormData();
          formData.append('file', _.get(p, 'fileRaw'));
          formData.append('saleOrderId', _.get(p, 'saleOrderId'));
          formData.append('remark', _.get(p, 'remark') === undefined ? '' : _.get(p, 'remark'));
          formData.append('arId', _.get(record, 'arId'));
          const reaspon = await uploadARAttachment(formData);

          const payload = { fileHash: _.get(reaspon, 'data.fileHash'), remark: _.get(p, 'remark') };
          return payload;
        })
      );

      // for (let index = 0; index < attData.length; index++) {
      //   const file = _.get(attData[index], 'fileRaw');
      //   const formData = new FormData();
      //   formData.append('file', file);
      //   formData.append('saleOrderId', _.get(dataForm, 'saleOrderNo'));
      //   formData.append('remark', attData[index].remark);

      //   if (attData[index].fileHash === undefined) {
      //     const reaspon = await uploadARAttachment(formData);
      //   }
      // }
      console.log("dataForm",dataForm)
      const payloads = {
        saleOrderId: _.get(record, 'saleOrderId'),
        issueById: _.get(values, 'by'),
        issueDate: moment(_.get(values, 'date')).format('YYYY-MM-DD'),
        remark: _.get(values, 'remark') || '',
        attachment: att,
        itemList: itemList,
        subTotal: _.get(dataForm, 'sumTotals'),
        discountPercent: _.get(dataForm, 'discountPercent'),
        discount: _.get(dataForm, 'discountNumber'),
        subTotalAfterDiscount: _.get(dataForm, 'subtotalDiscount'),
        taxPercent: _.get(dataForm, 'taxPercent'),
        tax: _.get(dataForm, 'taxNumber'),
        total: _.get(dataForm, 'totalAll'),
        // receiveById: undefined,
        // receiveDate: undefined,
        // remarkPaid: undefined,
        // attachmentPaid: [],
        // paymentMethodList: [],
        code: _.get(dataForm, 'code'),
        // saleOrderNo: _.get(dataForm, 'saleOrderNo'),
      };

      console.log('savex values', att);
      console.log('savex payload', payloads);
      onOk(payloads);
      resetFields();
      setDataForm();
      setSelectWHData();
      setAttData([]);
      setSelectItem([]);
    });
  };

  const handleCancelQuotation = () => {
    onCancel();
    resetFields();
    setDataForm();
    setSelectWHData();
    setAttData([]);
    setSelectItem([]);
  };

  const callback = (key) => {
    setTabkeys(key);
  };

  const handleOpen = () => {
    setIsOpen(true);
  };



  const handleCloseARItem = () => {
    setVisibleAdd(false);
  };

  const operations = (
    <div className="tag-center-div" style={{ padding: '12px 2px' }}>
      <Tag className="tag-center-style" color={_.get(record, 'status.color')}>
        {_.get(record, 'status.txt')}
      </Tag>
    </div>
  );

  const handlePrint = useReactToPrint({
    content: () => ARPrintrRef.current,
    documentTitle: 'data',
    copyStyles: true,
  });

  const handleDelete = () => {
    Modal.confirm({
      className: 'maintenance-report-modal-confirm',
      centered: true,
      icon: null,
      title: intl.formatMessage({ id: 'lblDelete', defaultMessage: 'Are you sure you want to delete ?' }),
      okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        const payload = {
          id: _.get(record, 'saleOrderId'),
          code: _.get(record, 'code'),
          status: 'deleted',
        };
        handleSaveDelete(payload);
      },
      onCancel() {},
    });
  };

  const actionFooter =
    _.get(record, 'code') === 'view'
      ? [
          <Button_02
            style={{ margin: '0px 0px 0px 10px' }}
            key="back"
            btnsize="wd_df"
            onClick={() => {
              handleCancelQuotation();
              resetFields();
              setDataForm();
              setSelectWHData();
              setAttData([]);
              setSelectItem([]);
            }}
          >
            <FormattedMessage id="btnClose" defaultMessage="Close" />
          </Button_02>,
          <Button_01
            style={{ margin: '0px 0px 0px 10px' }}
            key="submit"
            type="primary"
            btnsize="wd_df"
            onClick={() => handleSave('pending')}
            disabled={_.size(selectItem) ? false : true}
          >
            <FormattedMessage id="btnSave" defaultMessage="Save" />
          </Button_01>,
        ]
      : _.get(record, 'code') === 'waiting'
      ? [
          <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleCancelQuotation}>
            <FormattedMessage id="btnClose" defaultMessage="Close" />
          </Button_02>,
          <Button_01 style={{ margin: '0px 0px 0px 10px' }} key="print" type="primary" btnsize="wd_df" onClick={() => handlePrint()}>
            <FormattedMessage id="btnPrint" defaultMessage="Print" />
          </Button_01>,
          <Button_01 style={{ margin: '0px 0px 0px 10px', width: '15%' }} key="reject" type="primary" onClick={() => handleOpenCancel()}>
            <FormattedMessage id="ARCanceledInvoice" defaultMessage="Canceled Invoice" />
          </Button_01>,
          <Button_01
            style={{ margin: '0px 0px 0px 10px' }}
            key="cancelQT"
            type="primary"
            btnsize="wd_df"
            onClick={() => {
              handleOpenPaid('waiting',_.get(record, 'arId'));
            }}
          >
            <FormattedMessage id="ARPaid" defaultMessage="Paid" />
          </Button_01>,
        ]
      : _.get(record, 'code') === 'paid'
      ? [
          <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleCancelQuotation}>
            <FormattedMessage id="btnClose" defaultMessage="Close" />
          </Button_02>,
          <Button_01 style={{ margin: '0px 0px 0px 10px' }} key="print" type="primary" btnsize="wd_df" onClick={() => handlePrint()}>
            <FormattedMessage id="btnPrint" defaultMessage="Print" />
          </Button_01>,
          <Button_01
            style={{ margin: '0px 0px 0px 10px' }}
            key="cancelQT"
            type="primary"
            onClick={() => {
              handleOpenPaid('paid',_.get(record, 'arId'));
            }}
          >
            <FormattedMessage id="ARPayment" defaultMessage="Payment" />
          </Button_01>,
          // <Button_01 style={{ margin: '0px 0px 0px 10px', width: '15%' }} key="reject" type="primary" onClick={() => handleOpenCancel()}>
          //   <FormattedMessage id="ARCanceledInvoice" defaultMessage="Canceled Invoice" />
          // </Button_01>,
          // <Button_01 style={{ margin: '0px 0px 0px 10px', width: '18%' }} key="waiting" type="primary" onClick={() => handleOpenWaiting()}>
          //   <FormattedMessage id="ARWaitingPayment" defaultMessage="Waiting for Payment" />
          // </Button_01>,
        ]
      : _.get(record, 'code') === 'canceled'
      ? [
          <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleCancelQuotation}>
            <FormattedMessage id="btnClose" defaultMessage="Close" />
          </Button_02>,
          //   <Button_01 style={{ margin: '0px 0px 0px 10px', width: '18%' }} key="waiting" type="primary" onClick={() => handleOpenWaiting()}>
          //     <FormattedMessage id="ARWaitingPayment" defaultMessage="Waiting for Payment" />
          //   </Button_01>,
        ]
      : [
          <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleCancelQuotation}>
            <FormattedMessage id="btnClose" defaultMessage="Close" />
          </Button_02>,
        ];

  return (
    <Modal
      title={title}
      visible={visible}
      onOk={handleSave}
      onCancel={handleCancelQuotation}
      width={1000}
      centered={true}
      bodyStyle={{ padding: 'unset' }}
      footer={actionFooter}
    >
      {_.get(record, 'code') === 'view' && (
        <DetailsTabPending
          form={form}
          property={{
            handleOpenModalUpload,
            setDataForm,
            dataForm,
            setVisibleItem,
            initialMemberList,
            handleOpen,
            selectItem,
            setSelectItem,
            attData,
            setAttData,
            initialTaxList,
            handleOpenAddItem,
            saleOrderQTY,
            setSaleOrderQTY,
            recordList,
          }}
        />
      )}

      {_.get(record, 'code') === 'viewinvoice' && (
        <DetailsTabPendingViewinvoice
          form={form}
          property={{
            setDataForm,
            dataForm,
            selectItem,
            setSelectItem,
            viewDetail,
            handleOpenViewInvoice,
          }}
        />
      )}

      {_.get(record, 'code') === 'waiting' && (
        <DetailsTabPending
          form={form}
          property={{
            handleOpenModalUpload,
            setDataForm,
            dataForm,
            setVisibleItem,
            initialMemberList,
            handleOpen,
            selectItem,
            setSelectItem,
            attData,
            setAttData,
            initialTaxList,
            handleOpenAddItem,
          }}
        />
      )}

      {_.get(record, 'code') === 'paid' && (
        <DetailsTabPending
          form={form}
          property={{
            handleOpenModalUpload,
            setDataForm,
            dataForm,
            setVisibleItem,
            initialMemberList,
            handleOpen,
            selectItem,
            setSelectItem,
            attData,
            setAttData,
            initialTaxList,
            handleOpenAddItem,
          }}
        />
      )}

      {_.get(record, 'code') === 'canceled' && (
        <DetailsTabPending
          form={form}
          property={{
            handleOpenModalUpload,
            setDataForm,
            dataForm,
            setVisibleItem,
            initialMemberList,
            handleOpen,
            selectItem,
            setSelectItem,
            attData,
            setAttData,
            initialTaxList,
          }}
        />
      )}
      <div style={{ display: 'none' }}>
        <ARPrint ref={ARPrintrRef} record={record} signatureUser={signatureUser} recordReport={recordReport} setRecordReport={setRecordReport} />
      </div>
      <ModalCancelForm
        title={intl.formatMessage({ id: 'ARCanceledInvoice', defaultMessage: 'Canceled Invoice' }) + ' · ' + _.get(record, 'saleOrderNo')}
        visible={visibleCancel}
        onOk={handleSaveCancel}
        onCancel={handleCancelCancelQT}
        initialReasonList={_.get(record, 'reasonToCanceled')}
        arId={_.get(record, 'arId')}
        code={_.get(record, 'code')}
      />

      <ModalWaitingForm
        title={intl.formatMessage({ id: 'ARWaitingPayment', defaultMessage: 'Waiting for Payment' }) + ' · ' + _.get(record, 'saleOrderNo')}
        visible={visibleWaiting}
        onOk={handleSaveWaiting}
        onCancel={handleCancelWaiting}
        initialReasonList={_.get(record, 'reasonToWaiting')}
        arId={_.get(record, 'arId')}
        code={_.get(record, 'code')}
      />

      <ModalPaidForm
        title={intl.formatMessage({ id: 'ARPaid', defaultMessage: 'Paid' }) + ' · ' + _.get(record, 'saleOrderNo')}
        onCancel={handleCancelPaid}
        initialMemberList={initialMemberList}
        attData={attachmentPaidData}
        setAttData={setAttachmentPaidData}
        attDatainvoice={attData}
        recordAR={record}
        onOk={onOk}
        saleId={_.get(record, 'saleOrderNoId')}
      />

      <ModelAddItemAR
        title={
          intl.formatMessage({ id: 'ARPendingDeliverie', defaultMessage: 'Pending Deliverie' }) +
          ' · ' +
          `${intl.formatMessage({ id: `ARSONo`, defaultMessage: 'SO No' })}` +
          ' ' +
          _.get(record, 'saleOrderDetail.saleOrderNo')
        }
        visible={visibleAddItem}
        onCancel={handleCancelAddItem}
        initialMemberList={initialMemberList}
        attData={attachmentPaidData}
        setAttData={setAttachmentPaidData}
        attDatainvoice={attData}
        record={record}
        onOk={onOk}
        saleId={_.get(record, 'saleOrderNoId')}
        form={form}
        paginationPage={pageTable}
        setPaginationPage={setPageTable}
        paginationShow={sizeTable}
        setPaginationSize={setSizeTable}
        warehouseList={warehouseList}
        saleOrderQTY={saleOrderQTY}
        setWarehouseList={setWarehouseList}
        setSaleOrderQTY={setSaleOrderQTY}
        handleCleckSaleOrder={handleCleckSaleOrder}
        recordList={recordList}
        setRecordList={setRecordList}
        selectItem={selectItem}
        setSelectItem={setSelectItem}
        totalWarehouseList={totalWarehouseList}
        setTotalWarehouseList={setTotalWarehouseList}

      />


    </Modal>
  );
};

QuotationModal.defaultProps = {
  title: 'Modal',
  visible: false,
  onOk: () => console.warn('onOk not function '),
  onCancel: () => console.warn('onCancel not function '),
};

const ModalCustomerQuotationForm = Form.create({ name: 'Customer_Form' })(QuotationModal);

export default ModalCustomerQuotationForm;
