import React, { useContext } from 'react';
import './css/index.css'
import { Avatar, Icon } from 'antd'
import { CreateTaskContext } from './create-task-context';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash'

export default () => {
  const { CreateState, CreateSetState } = useContext(CreateTaskContext);

  return (
    <div style={{ padding: '20px 16px' }}>
      <div style={{ marginBottom: '16px' }}>
        <span className='create-task-assignee-text'>
          <FormattedMessage
            id={`createTaskMntTitleAssignee`}
            defaultMessage="Assignees"
          /></span>
      </div>
      {
        _.size(CreateState.dataMemberAssignee) !== 0 ? _.get(CreateState, 'dataMemberAssignee').map(item => {

          return (
            <div style={{ display: 'flex', marginBottom: '30px' }}>
              <div>
                <Avatar
                  src={item.profile}
                  size="large"
                  style={{ marginRight: '16px' }}
                />
              </div>
              <div>
                <p className='create-task-assignee-name'>{item.name}</p>
                <p className='create-task-assignee-phone'>{item.phone}</p>
                {
                  item.subtasks.map((item, i) => {
                    const iconExcel = () => {
                      if (item.done === true) {
                        return (<img key={i} className="table-status-img" src={item.icon} />)
                      } else {
                        return (<img key={i} className="table-status-img" src={item.icon} style={{ filter: 'grayscale(100%)' }} />)
                      }
                    };

                    return (
                      <div className="table-status">
                        <div key={i} className="table-status-key">
                          <Icon className="table-status-icon" component={iconExcel} />
                        </div>
                      </div>
                    );
                  })
                }
              </div>
            </div>
          )
        }) : null
      }
      <div className='create-task-assignee-avatar' onClick={() => { CreateState.orgId && CreateState.taskTypeId ? CreateSetState.setVisibleAssignee(true) : CreateSetState.setVisibleAssignee(false) }}>
        <Avatar
          icon='plus'
          size="large"
          style={{ marginRight: '16px' }}
        />
        <div style={{ color: '#050505' }}>
          <FormattedMessage
            id={`createTaskMntLBAssignee`}
            defaultMessage="Add Assignees"
          />
        </div>
      </div>
    </div>
  )
}
