import React, { useState, useEffect } from 'react';
import { Modal, Form, Select, Divider, Row, Col, TreeSelect } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import { PageSettings } from '../../../../config/page-settings';
import getTeamPosition from '../../../../controllers/team/get-team-position';
import Button01 from '../../../../components/v2/button_01';
import Button02 from '../../../../components/v2/button_02';
import createTeam from '../../../../controllers/team/post-create-team';
import updateTeam from '../../../../controllers/team/post-update-team';
import PositionModalForm from '../../../../components/action-position-name/position-modal.js';
import LabeRequireForm from '../../../../components/label-required-form';
import { successNotification, errorNotification } from '../../../../components/v2/notification';

import './css/team.css';

const { Option } = Select;

const AssignTeamForm = (props) => {
  const intl = useIntl();
  const { getFieldDecorator, validateFields, resetFields, getFieldsValue, setFieldsValue } = props.form;

  const [isOpenModalPosition, setIsOpenModalPosition] = useState(false);
  const [triggerPositionApi, setTriggerPositionApi] = useState(false);
  const [teamData, setTeamData] = useState([]);
  const [positionMasterData, setPositionMasterData] = useState([]);
  const [seletePositionData, setSeletePositionData] = useState({});
  const [statusOpen, setStatusOpen] = useState();
  const [valueTeam, setValueTeam] = useState(undefined);
  const [isOpenBtn, setIsOpenBtn] = useState(false);

  // console.log('teamUpdateData props::: ', props.teamUpdateData);
  // console.log('seletePositionData props::: ', seletePositionData);

  useEffect(() => {
    const getTeamPositionApi = async () => {
      if (props.userId) {
        const response = await getTeamPosition(props.userId);
        setTeamData(response.data.team);
        setPositionMasterData(response.data.positionMaster);
      }
    };
    getTeamPositionApi();
  }, [triggerPositionApi]);

  useEffect(() => {
    setSeletePositionData(props.teamUpdateData);
  }, [props.teamUpdateData]);

  const handleAssign = () => {
    validateFields(async (err, values) => {
      if (err) {
        return;
      }
      setIsOpenBtn(true);
      if (props.statusOpen === 'add') {
        if (props.userId) {
          const response = await createTeam({ orgId: values.team, positionId: values.position, memComId: props.userId });
          if (response.status >= 400) {
            errorNotification(response.data.status.message);
            setIsOpenBtn(false);
          } else {
            successNotification(response.status.message);
            setIsOpenBtn(false);
          }
        }
      }

      if (props.statusOpen === 'edit') {
        if (props.userId) {
          const response = await updateTeam({
            orgId: values.team,
            positionId: values.position,
            memComId: props.userId,
            memOrgId: props.teamUpdateData.memOrgId,
          });
          if (response.status >= 400) {
            errorNotification(response.data.status.message);
            setIsOpenBtn(false);
          } else {
            successNotification(response.status.message);
            setIsOpenBtn(false);
          }
        }
      }

      props.setTriggerApi((current) => !current);
      props.setIsOpenAssign(false);
      resetFields();
    });
  };

  const handleCancel = () => {
    props.setIsOpenAssign(false);
    resetFields();
    setIsOpenBtn(false);
  };

  const handleAddPosition = () => {
    setSeletePositionData({});
    setStatusOpen('add');
    setIsOpenModalPosition(true);
  };

  const handleEditPosition = (positionId) => {
    const filterPosition = positionMasterData.filter((item) => item.positionId.toString() === positionId.toString());
    setSeletePositionData(filterPosition[0]);
    setStatusOpen('edit');
    setIsOpenModalPosition(true);
  };

  const onChangeSearchTeam = (value) => {
    setValueTeam(value);
  };

  return (
    <div className="assign-team">
      <Modal
        className="assign-team-modal"
        centered
        width={432}
        visible={props.isOpenAssign}
        title={<FormattedMessage id="userMgntUsersDetailTitleTeam" defaultMessage="Team" />}
        onOk={handleAssign}
        onCancel={handleCancel}
        footer={[
          <Button02 style={{margin : '0px 0px 0px 10px'}} className="btn-style-new" key="back" btnsize="wd_df" onClick={handleCancel}>
            <FormattedMessage id="userDetailModalBtnCancel" defaultMessage="Cancel" />
          </Button02>,
          <Button01 className="btn-style-new2" key="submit" type="primary" btnsize="wd_df" onClick={handleAssign} disabled={isOpenBtn}>
            <FormattedMessage id="userDetailModalBtnSave" defaultMessage="Save" />
          </Button01>,
        ]}
      >
        <Form layout="vertical">
          <Form.Item
            label={<LabeRequireForm text={intl.formatMessage({ id: 'positionCardTxtTeam', defaultMessage: 'Team' })} req={true} />}
          >
            {getFieldDecorator('team', {
              initialValue: props.teamUpdateData !== undefined ? props.teamUpdateData.orgId : undefined,
              rules: [
                {
                  required: true,
                  message: intl.formatMessage({ id: 'positionCardTxtValidateTeam', defaultMessage: '' }),
                },
              ],
            })(
              <TreeSelect
                className="assign-team-treeSelect"
                showSearch
                style={{ width: '100%' }}
                placeholder=""
                placeholder={intl.formatMessage({ id: 'positionCardTxtPlaceTeam', defaultMessage: 'Enter team' })}
                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                treeData={teamData}
                treeDefaultExpandAll
                onSearch={onChangeSearchTeam}
                value={valueTeam}
                filterTreeNode={(inputValue, treeNode) =>
                  treeNode.props.title
                    .toString()
                    .toLowerCase()
                    .indexOf(inputValue.toLowerCase()) >= 0
                }
              />
            )}
          </Form.Item>

          <Form.Item label={intl.formatMessage({ id: 'positionCardTxtPosition', defaultMessage: 'Position' })}>
            {getFieldDecorator('position', {
              initialValue: props.teamUpdateData.positionId !== null ? props.teamUpdateData.positionId : undefined,
            })(
              <Select
                className="assign-team-modal-select"
                placeholder={intl.formatMessage({ id: 'modalMemberAddEditPlaceholerPosition', defaultMessage: 'Select Position' })}
                optionLabelProp="label"
                dropdownRender={(menu) => (
                  <div className="assign-team-modal-dropdownRender">
                    {menu}

                    <PageSettings.Consumer>
                      {({ checkPermissionAction }) => (
                        <div>
                          {checkPermissionAction(`${props.permissions.team}`, `${props.permissions.addPosition}`) ? (
                            <div>
                              <Divider style={{ margin: '4px 0' }} />
                              <div
                                style={{ padding: '12px 16px', cursor: 'pointer', color: '#1D3557' }}
                                onMouseDown={(e) => e.preventDefault()}
                                onClick={handleAddPosition}
                              >
                                <FormattedMessage id="positionAddNew" defaultMessage="Add New Position" />
                              </div>
                            </div>
                          ) : null}
                        </div>
                      )}
                    </PageSettings.Consumer>
                  </div>
                )}
              >
                {positionMasterData && positionMasterData.length > 0
                  ? positionMasterData.map((item) => (
                      <Option className="assign-team-modal-option" key={item.positionId} label={item.positionName}>
                        <Row>
                          <Col className="assign-team-modal-option-text" span={21}>
                            <span>{item.positionName}</span>
                          </Col>

                          <PageSettings.Consumer>
                            {({ checkPermissionAction }) => (
                              <div>
                                {checkPermissionAction(`${props.permissions.team}`, `${props.permissions.edit}`) ? (
                                  <Col span={3}>
                                    <span onClick={() => handleEditPosition(item.positionId)}>
                                      <FormattedMessage id="positionEdit" defaultMessage="Edit" />
                                    </span>
                                  </Col>
                                ) : null}
                              </div>
                            )}
                          </PageSettings.Consumer>

                          {/* <Col span={3}>
                        <span onClick={() => handleEditPosition(item.positionId)}>Edit</span>
                      </Col> */}
                        </Row>
                      </Option>
                    ))
                  : null}
              </Select>
            )}
          </Form.Item>
        </Form>
      </Modal>

      <PositionModalForm
        statusOpen={statusOpen}
        isOpenModalPosition={isOpenModalPosition}
        setIsOpenModalPosition={setIsOpenModalPosition}
        seletePositionData={seletePositionData}
        setTriggerPositionApi={setTriggerPositionApi}
        setSeletePositionData={setSeletePositionData}
        setFieldsValue={setFieldsValue}
      />
    </div>
  );
};

const AssignTeamModal = Form.create({
  name: 'assignTeam-form',
})(AssignTeamForm);

export default AssignTeamModal;
