import React from 'react';
import { Table, Select, Icon, Input } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import _ from 'lodash';

const { Option } = Select;

export default (props) => {
  const { columns, stakeholderData, total, handlePagination, handleSizeChange, handleSelectColumns, selectBy, setSearchRaw, searchRaw } =
    props;
  const intl = useIntl();

  const showTotal = (total) => {
    return (
      <span style={{ fontSize: '13px', float: 'left' }}>
        <FormattedMessage id="lblRowPerPage" defaultMessage="Rows per page:" />
      </span>
    );
  };

  const newColumns = _.filter(columns, (item) => item.key !== 'index');

  return (
    <div className="modal-view-project-stakeholder-padding">
      <div>
        <Input
          className="monitor-view-input-action"
          placeholder={intl.formatMessage({
            id: 'hintSearch',
            defaultMessage: 'Search',
          })}
          prefix={<Icon style={{ color: 'rgba(0,0,0,.25)' }} type="search" />}
          onChange={(e) => {
            setSearchRaw(e.target.value);
          }}
          autoComplete="off"
          value={searchRaw}
          defaultValue=""
        />
        <Select
          className="monitor-select-action"
          placeholder={intl.formatMessage({
            id: 'hintSelect',
            defaultMessage: 'Select',
          })}
          onSelect={(value) => handleSelectColumns(value)}
          defaultValue="all"
          value={selectBy}
        >
          <Option value="all">
            <FormattedMessage id="reportOutsideReportHintSeleteAllColumns" defaultMessage="All columns" />
          </Option>
          {newColumns.map((item, index) => (
            <Option key={index} value={item.key}>
              {item.title}
            </Option>
          ))}
        </Select>
      </div>
      <div style={{ paddingTop: '16px' }}>
        <Table
          columns={columns}
          dataSource={stakeholderData}
          scroll={{ y: 215 }}
          pagination={{
            showTotal: showTotal,
            total: total,
            defaultCurrent: 1,
            pageSizeOptions: ['10', '20', '30', '40', '50'],
            showSizeChanger: true,
            locale: { items_per_page: '' },
            onChange: handlePagination,
            onShowSizeChange: handleSizeChange,
          }}
        />
        {stakeholderData && stakeholderData.length !== 0 ? (
          <div className="total-items-timeAttendance">
            <span style={{ fontSize: '13px' }}>
              <FormattedMessage id="lblTotal" defaultMessage="Total" />
              {` ${total || 0} `}
              <FormattedMessage id="lblitems" defaultMessage="items" />
            </span>
          </div>
        ) : null}
      </div>
    </div>
  );
};
