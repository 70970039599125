import React from 'react';
import _ from 'lodash';
import { PageEngagement } from '../../config/page-settings';
import EngagementPost from './engagement-post/post-post';
import EngagementQuatation from './engagement-quotation/qutation-post';
import NoEngagement from '../../components/image/Nodata_web.svg';
import CradDefaultV3 from '../../components/crad-default/crad-default-v3';
import stylesIndex from './css/index.css';

const description = {
  text1: 'engagementTxtDetailDescription1',
  message1: 'No Engagement',
  text2: 'engagementTxtDetailDescription2',
  message2: 'All customer related activities will appear here.',
};

export default () => {

  const previewImage = () => <img src={NoEngagement} alt="NoEngagement" />


  return (
    <PageEngagement.Consumer>
      {({ engagementTimeline, componentRef, fnc, discount, selectDiscount, triggerAPICountPost }) => (
        <div className='time-line scroll-sm'>
          {_.size(engagementTimeline) > 0 ?
            engagementTimeline.map((o) => {
              switch (o.moduleCode) {
                case 'post':
                  return <EngagementPost data={o} />;
                case 'quotation':
                  return <EngagementQuatation data={o} fncQuatation={fnc} componentRef={componentRef} discount={discount} selectDiscount={selectDiscount} triggerAPICountPost={triggerAPICountPost} />;
                default:
                  return <CradDefaultV3 description={description} fncImage={previewImage} />
              }
            })
            : (
              <CradDefaultV3 description={description} fncImage={previewImage} />
            )
          }
        </div>
      )}
    </PageEngagement.Consumer>
  );
}