import httpClient from '../../components/axiosClient';
import moment from 'moment';

const today = new Date();
const dateApi = 'YYYY-MM-DD';
const dateNow = moment(today).format(dateApi);
const comCode = localStorage.getItem('comCode');

const getMapPolygonApi = async (orgId,typeApi) => {

  const data = {
    organizations: orgId ? orgId : []
  }
  
  try {
    const response = await httpClient.post(`/v3/task/manager/company/${comCode}/all/service-area?${typeApi}`, data);
    return response;
  } catch (error) {
    return error.response
  }
}

export default getMapPolygonApi;