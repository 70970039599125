import React, { useContext } from 'react';
import { Row, Col, Avatar, Badge, Icon, Divider, Empty, Popover, Menu, Button } from 'antd';
import InfiniteScroll from 'react-infinite-scroller';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import './css/index.css';
import WarehouseContext from '../context';
import { PageSettings } from '../../../config/page-settings';

const WarehouseList = ({ warehouse, handleSelectWareHouse, onLoadMore, warehousePageLoading, warehousePageHasMore }) => {
  const app = useContext(PageSettings);
  const { checkPermissionAction } = app;
  const { state, setState, fnc } = useContext(WarehouseContext);
  const { toggle, warehouseId, warehouseDetail, warehouseActive } = state;
  const { setWarehouseActive, setTrigger, setWarehouseRecord, setWarehouseIsOpen } = setState;
  const { handleDeleteWarehouse, handleEditWarehouse } = fnc;

  const renderWarehouse = () =>
    warehouse &&
    warehouse.map((item, index) => {
      return (
        <div
          key={item.warehouseId}
          id={item.warehouseId}
          className={`warehouse-item ${item.warehouseId === warehouseId ? 'warehouse-item-active' : ''}`}
          onClick={() => handleSelectWareHouse(item.warehouseId)}
        >
          <Row>
            <Col span={6}>
              <div className="profile-warehouse">
                <Avatar shape="square" size={40} src={item.warehouseImg} />
              </div>
            </Col>
            <Col span={18}>
              <div style={{ paddingLeft: 6 }}>
                <div className="layout-label-warehouse">
                  <p title={`${item.warehouseCode} · ${item.warehouseName}`} className="label-warehouse warehouse-value-color">
                    {item.warehouseCode} · {item.warehouseName}
                  </p>
                  <p>
                    <Popover
                      placement="leftTop"
                      content={
                        <div>
                            {checkPermissionAction(`P52PG1C1`, `P52PG1C1A3`) && _.size(_.get(warehouseDetail, 'isManager')) > 0 ? (
                          <div>
                            <Button type="link" onClick={() => handleEditWarehouse(item.warehouseId)} ghost>
                              <FormattedMessage id="btnEdit" defaultMessage="Edit" />
                            </Button>
                          </div>
                          ) : null}
                          {checkPermissionAction(`P52PG1C1`, `P52PG1C1A4`) && _.size(_.get(warehouseDetail, 'isManager')) > 0 ? (
                          <div>
                            <Button type="link" onClick={() => handleDeleteWarehouse(item.warehouseId)} ghost>
                              <FormattedMessage id="btnDelete" defaultMessage="Delete" />
                            </Button>
                          </div>
                          ) : null}
                        </div>
                      }
                      trigger="hover"
                    >
                      <Icon type="more" />
                    </Popover>
                  </p>
                </div>

                <p style={{ color: item.statusColor, paddingBottom: 6 }} className="value-warehouse">
                  {item.status}
                </p>
                <p title={item.address} style={{ color: '#65676b' }} className="address-warehouse">
                  {item.warehouseTypeDisplay}
                </p>
                <div style={{ display: 'flex' }}>
                  <p className="label-warehouse warehouse-title-color"><FormattedMessage id="warehouseStockSummaryReorder" defaultMessage="Re-Order" /> : </p>
                  <p style={{ color: item.reorderColor, paddingLeft: 4 }} className="value-warehouse">
                    {item.reorder} {item.reorder <= 1 ? <FormattedMessage id="wareHouseItem" defaultMessage="item" /> : <FormattedMessage id="lblitems" defaultMessage="items" />}
                  </p>
                </div>
                <div style={{ display: 'flex' }}>
                  <p className="label-warehouse warehouse-title-color"><FormattedMessage id="warehouseStockSummaryOutOfStock" defaultMessage="Out of Stock" /> : </p>
                  <p style={{ color: item.outStockColor, paddingLeft: 4 }} className="value-warehouse">
                    {item.outStock} {item.outStock <= 1 ? <FormattedMessage id="wareHouseItem" defaultMessage="item" /> : <FormattedMessage id="lblitems" defaultMessage="items" />}
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      );
    });

  return (
    <InfiniteScroll
      initialLoad={false}
      pageStart={1}
      loadMore={(page) => onLoadMore(page)}
      hasMore={warehousePageLoading && warehousePageHasMore}
      useWindow={false}
      threshold={30}
    >
      {_.size(warehouse) !== 0 ? (
        renderWarehouse()
      ) : (
        <div>
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </div>
      )}
    </InfiniteScroll>
  );
};

export default WarehouseList;
