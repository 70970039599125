import React, { useState, useEffect, useContext } from 'react';
import { Modal, Tabs, Form, Tag, Button, Menu } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import PurchaseCreateDetail from './detail';
import PurchaseCreateDelivery from './delivery';
import Button01 from '../../../../components/v2/button_01';
import Button02 from '../../../../components/v2/button_02';
import ListItem from '../../../../components/list-item/list/index';
import ModalCreateListItem from '../../../../components/list-item-edit/add';
import ModalEditListItem from '../../../../components/list-item-edit/edit';
import ModalDeliveryCreate from '../../../../components/delivery/add';
import ModalDeliveryEdit from '../../../../components/delivery/edit';
import moment from 'moment';
import _ from 'lodash';
import { numberFormatter, numberParser } from '../../../../component-function/fnc-inputnumber-currency';
import { savePo, updateStatusPo, getPoDetail, getPoAttachment, deletePoAttachment, getStockDelivery } from '../../../../controllers/po/po';
import ModalUploadFile from '../../../../components/modal-upload/edit';
import { successNotification, errorNotification } from '../../../../components/v2/notification';
import ModalPOStatusForm from '../view/status';
import { v4 as uuidv4 } from 'uuid';
import { savetListReferenceTab } from '../../../../controllers/reference-tab';
import ReferenceTab from '../../../../components/reference';
import { PageSettings } from '../../../../config/page-settings';
import ModalCreateListServiceItemPO from '../list-item/add-service';
import ModalEditListServiceItemPO from '../list-item/edit-service';
import ListItemServicePO from '../list-item/list-service';
import { Round } from '../../../../component-function/lodash';

const { TabPane } = Tabs;

const PurchaseEdit = (props) => {
  const { visible, setVisible, form, setTrigger, defaultData, initial, nextStatus, actionSaveToStatus } = props;
  const { vendorData, taxList, deliveryType, vehicleList } = initial;
  const intl = useIntl();
  const { validateFields, resetFields, getFieldValue } = form;
  const { checkPermissionAction } = useContext(PageSettings);
  const [tabChange, setTabChange] = useState('1');
  const [visibleItem, setVisibleItem] = useState(false);
  const [visibleAdd, setVisibleAdd] = useState(false);
  const [visibleEdit, setVisibleEdit] = useState(false);
  const [editData, setEditData] = useState();
  const [visibleAddDeli, setVisibleAddDeli] = useState(false);
  const [visibleEditDeli, setVisibleEditDeli] = useState(false);
  const [deliveryDefault, setDeliveryDefault] = useState();
  const [createData, setCreateData] = useState();
  const [contactData, setContactData] = useState([]);
  const [selectItem, setSelectItem] = useState([]);
  const [deliveryList, setDeliveryList] = useState([]);

  const [openUpload, setOpenUpload] = useState(false);
  const [attachmentData, setAttachmentData] = useState([]);
  const [typeAttachment, setTypeAttachment] = useState('add');
  const [defaultAttachment, setDefaultAttachment] = useState();
  const [editDefault, setEditDefault] = useState();
  const [triggerIn, setTriggerIn] = useState(false);
  const [searchGroup, setSearchGroup] = useState({ discount: 0, discountNumber: 0.0 });
  const [visibleStatus, setVisibleStatus] = useState(false);
  const [type, setType] = useState();

  //------------------------------------------
  const [visibleListService, setVisibleListService] = useState(false);
  const [visibleAddService, setVisibleAddService] = useState(false);
  const [visibleEditService, setVisibleEditService] = useState(false);
  const [creatService, setCreatService] = useState();
  const [editService, setEditService] = useState();
  const [loading, setLoading] = useState(false);

  //------------------------------------------
  const [refItem, setRefItem] = useState([]);

  useEffect(() => {
    const getDataDefault = async () => {
      const response = await getPoDetail(_.get(defaultData, 'poId'));
      const deliveryPo = _.get(response, 'data.data.deliveryPo');
      const filterContact = _.filter(vendorData, (item) => {
        return item.customerId === _.get(response, 'data.data.vendorSupplierId');
      });
      let itemCount = [];
      for (let index = 0; index < _.get(deliveryPo, 'length'); index++) {
        const element = deliveryPo[index];
        for (let i = 0; i < element.itemList.length; i++) {
          const itemObj = element.itemList[i];
          itemCount.push(itemObj);
        }
      }
      const newSelectItem = _.map(_.get(response, 'data.data.itemPo'), (item) => {
        const filterDeli = _.filter(itemCount, (e) => {
          return item.itemPoId === e.itemPoId;
        });

        return {
          ...item,
          id: uuidv4(),
          count: parseFloat(_.get(item, 'qty')),
          countTemp: parseFloat(_.get(item, 'qty')) - _.sumBy(filterDeli, 'qty'),
        };
      });
      const newSelectDeli = _.map(_.get(response, 'data.data.itemPo'), (item) => {
        const filterDeli = _.filter(itemCount, (e) => {
          return item.itemPoId === e.itemPoId;
        });

        return {
          ...item,
          count: parseFloat(_.get(item, 'qty')),
          countTemp: _.sumBy(filterDeli, 'qty'),
          type: item.categoryCode,
        };
      });
      setEditDefault(_.get(response, 'data.data'));
      setContactData(_.get(filterContact, '[0].contacts'));
      setSelectItem(
        _.map(newSelectItem, (item) => {
          return { ...item, type: item.categoryCode };
        })
      );
      setSearchGroup({
        discount: _.get(response, 'data.data.discountPercent'),
        discountNumber: parseFloat(_.get(response, 'data.data.discount') || 0.0).toFixed(2),
      });

      setDeliveryList(
        _.map(_.get(response, 'data.data.deliveryPo'), (item, index) => {
          const mapItem = _.map(_.get(item, 'itemList'), (e) => {
            const filterMaster = _.filter(newSelectDeli, (c) => {
              console.log('filterFill', c.itemPoId, '/', e.itemPoId);
              return c.itemPoId === e.itemPoId;
            });
            console.log('filterMaster', filterMaster, '/', newSelectDeli, '/', item.itemList);
            return {
              ...e,
              data: _.get(filterMaster, '[0]'),
            };
          });
          console.log('mapItem', mapItem);
          return {
            ...item,
            index: index + 1,
            total: _.sumBy(item.itemList, 'qty'),
            itemList: mapItem,
            deliveryTypeCode: _.get(item, 'deliveryTypeCode'),
            deliveryTypeName: _.get(item, 'deliveryTypeTxt'),
            vehicleTypeCode: _.get(item, 'vehicleTypeCode'),
            vehicleTypeName: _.get(item, 'vehicleTypeCode') === 'internal' ? 'Internal' : 'Other',
            vehicleInternal: _.get(item, 'assetId')
              ? {
                  assetId: _.get(item, 'assetId'),
                  assetName: _.get(item, 'assetName'),
                  assetView: `${_.get(item, 'serialNo')}, ${_.get(item, 'brandName')} ${_.get(item, 'modelName')}, ${_.get(
                    item,
                    'assetName'
                  )}`,
                  brandName: _.get(item, 'brandName'),
                  modelName: _.get(item, 'modelName'),
                  serialNo: _.get(item, 'serialNo'),
                }
              : null,
            vehicleOther: _.get(item, 'assetName'),
            vehicleTxt: `${
              _.get(item, 'vehicleTypeName')
                ? _.get(item, 'vehicleTypeName')
                : _.get(item, 'vehicleTypeCode') === 'internal'
                ? intl.formatMessage({ id: `modalDeliveryInternal`, defaultMessage: 'Internal' })
                : intl.formatMessage({ id: `modalDeliveryOther`, defaultMessage: 'Other' })
            } - ${_.get(item, 'vehicleTypeCode') === 'internal' ? _.get(item, 'serialNo') : _.get(item, 'assetName')}`,
            // vehicleTxt: `${_.get(item, 'vehicleTypeCode') === 'internal' ? 'Internal' : 'Other'} - ${_.get(item, 'vehicleTypeCode') === 'internal' ? _.get(item, 'serialNo') : _.get(item, 'assetName')}`
          };
        })
      );
    };
    if (defaultData && visible && vendorData) {
      getDataDefault();
    }
  }, [defaultData, visible, vendorData]);

  console.log('deliveryList', deliveryList);

  useEffect(() => {
    const getFileDefault = async () => {
      const att = await getPoAttachment(_.get(defaultData, 'poId'));
      const mapAtt = _.map(_.get(att, 'data.data.list'), (item, index) => {
        return {
          id: item.fileHash,
          index: index + 1,
          attachmentName: item.fileName,
          remark: item.remark,
          file: [...item.fileUrl],
          fileRaw: item.fileUrl,
        };
      });
      setAttachmentData(mapAtt);
    };
    if (defaultData && visible) {
      getFileDefault();
    }
  }, [defaultData, triggerIn, visible]);

  useEffect(() => {
    if (editDefault && visible && _.get(editDefault, 'discountPercent') === _.get(searchGroup, 'discount')) {
      console.log('DPER', editDefault);
      onChange(_.get(editDefault, 'discountPercent'), 'discount');
    }
  }, [editDefault, triggerIn, visible, selectItem]);

  useEffect(() => {
    const ChangeDiscount = () => {
      const sumTotal = _.size(selectItem) > 0 ? _.sumBy(selectItem, 'totalPrice') : 0.0;
      console.log('TestValue', _.get(searchGroup, 'discount'), '/', (sumTotal * parseInt(_.get(searchGroup, 'discount') || 0)) / 100);

      setSearchGroup({
        discount: _.get(searchGroup, 'discount'),
        discountNumber: ((sumTotal * parseFloat(_.get(searchGroup, 'discount') || 0)) / 100).toFixed(2),
      });
    };

    if (_.size(selectItem) > 0) {
      ChangeDiscount();
    }
  }, [selectItem]);

  const columnsDetail = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
      fixed: 'left',
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsItemCode', defaultMessage: 'Item Code' }),
      dataIndex: 'itemCode',
      key: 'itemCode',
      sorter: true,
      width: 120,
      fixed: 'left',
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsItem', defaultMessage: 'Item' }),
      dataIndex: 'itemName',
      key: 'itemName',
      sorter: true,
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsQty', defaultMessage: 'Qty' }),
      dataIndex: 'qty',
      key: 'qty',
      sorter: true,
      render: (text, record, index) => record.qty + ' ' + record.uomName,
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnPrice', defaultMessage: 'Price/Unit' }),
      dataIndex: 'price',
      key: 'price',
      sorter: true,
      render: (text, record, index) => numberFormatter(record.pricePerUnit) + ' ' + 'THB',
    },
    // {
    //   title: intl.formatMessage({ id: 'purchesOrderModalColumnPromotionCampaign', defaultMessage: 'Promotion or Campaign' }),
    //   dataIndex: 'promotionCampaign',
    //   key: 'promotionCampaign',
    //   render: (text, record, index) => record.promotionCampaign || '-',
    // },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsDiscountPercentage', defaultMessage: 'Discount Percentage' }),
      dataIndex: 'discountPercentage',
      key: 'discountPercentage',
      render: (text, record, index) => numberFormatter(record.discountPercentage) + ' ' + '%',
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsDiscount', defaultMessage: 'Discount' }),
      dataIndex: 'discountPrice',
      key: 'discountPrice',
      render: (text, record, index) => numberFormatter(record.discountPrice) + ' ' + 'THB',
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsRemark', defaultMessage: 'Remark' }),
      dataIndex: 'remark',
      key: 'remark',
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsAmount', defaultMessage: 'Amount' }),
      dataIndex: 'totalPrice',
      key: 'totalPrice',
      sorter: true,
      fixed: 'right',
      width: 160,
      render: (text, record, index) => numberFormatter(record.totalPrice) + ' ' + 'THB',
    },
  ];

  const columnsAtt = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
      fixed: 'left',
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsFileName', defaultMessage: 'File Name' }),
      dataIndex: 'attachmentName',
      key: 'attachmentName',
      sorter: true,
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsRemark', defaultMessage: 'Remark' }),
      dataIndex: 'remark',
      key: 'remark',
    },
  ];

  const columnsDelivery = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsDeliveryDate', defaultMessage: 'Delivery Date' }),
      dataIndex: 'deliveryDate',
      key: 'deliveryDate',
      sorter: true,
      render: (text, record, index) => moment(_.get(record, 'deliveryDate'), 'YYYY-MM-DD').format('DD/MM/YYYY'),
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsDeliveryVehicle', defaultMessage: 'Vehicle' }),
      dataIndex: 'vehicleTxt',
      key: 'vehicleTxt',
      width: 150,
      sorter: true,
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsDeliveryType', defaultMessage: 'Delivery Type' }),
      dataIndex: 'deliveryTypeName',
      key: 'deliveryTypeName',
      width: 150,
      sorter: true,
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsDeliveryAddress', defaultMessage: 'Delivery Address' }),
      dataIndex: 'deliveryAddress',
      key: 'deliveryAddress',
      render: (text, record, index) =>
        _.get(record, 'deliveryAddressDetail.addressName') + ' - ' + _.get(record, 'deliveryAddressDetail.fullAddress'),
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnTotalItems', defaultMessage: 'Total Items' }),
      dataIndex: 'total',
      key: 'total',
      sorter: true,
      render: (text, record, index) => _.get(record, 'total') + ' - ' + 'Items',
    },
  ];

  const handleSave = (type) => {
    validateFields(async (err, values) => {
      if (err) {
        setTabChange('1');
        return;
      }
      // const filterDelivery = _.filter(selectItem, (item) => { return item.countTemp !== 0 })
      // if (_.size(filterDelivery) > 0) {
      //   setTabChange('2')
      // } else {
      if (type === 'canceled') {
        setType(type);
        setVisibleStatus(true);
        return;
      }
      const filterContact = _.filter(contactData, (item) => {
        return _.get(item, 'customerContactId') === _.get(values, 'contact');
      });
      console.log('taxError', selectItem);

      const subDis = _.sumBy(selectItem, 'totalPrice') - parseFloat(_.get(searchGroup, 'discountNumber'));

      const body = {
        poId: _.get(defaultData, 'poId'),
        vendorSupplierId: _.get(values, 'vendor'),
        vendorSupplierContactDetail: {
          vendorSupplierContactId: _.get(filterContact, '[0].customerContactId'),
          vendorSupplierContactName: _.get(filterContact, '[0].customerContactName'),
          vendorSupplierContactPhone: _.get(filterContact, '[0].customerContactPhone'),
        },
        paymentTerm: _.get(values, 'paymentTerm'),
        quotationNo: _.get(values, 'quotationNo') || '',
        issueDate: moment(_.get(values, 'issueDate')).format('YYYY-MM-DD'),
        referenceNo: _.get(values, 'referenceNo') || '',
        itemPo: _.map(selectItem, (item) => {
          return {
            index: item.index,
            qty: item.qty,
            uomId: item.uomId,
            itemMasterId: item.itemMasterId,
            pricePerUnit: item.pricePerUnit,
            promotionCampaign: item.promotionCampaign,
            discountPercentage: item.discountPercentage,
            discountPrice: item.discountPrice,
            totalPrice: item.totalPrice,
            remark: item.remark,
            itemPoId: item.itemPoId,
            categoryCode: item.type,
            checkVat: item.checkVat,
          };
        }),
        deliveryPo: _.map(deliveryList, (item) => {
          return {
            ...item,
            itemPoId: item.itemPoId,
            itemList: _.map(item.itemList, (e) => {
              return {
                ...e,
                data: undefined,
                itemPoId: e.itemPoId,
              };
            }),
            vehicleTypeCode: _.get(item, 'vehicleTypeCode'),
            assetId: _.get(item, 'vehicleInternal.assetId'),
            serialNo: _.get(item, 'vehicleInternal.serialNo'),
            brandName: _.get(item, 'vehicleInternal.brandName'),
            modelName: _.get(item, 'vehicleInternal.modelName'),
            assetName: _.get(item, 'vehicleInternal.assetName') || _.get(item, 'vehicleOther'),
          };
        }),
        remark: _.get(values, 'remark') || '',
        subtotal: _.sumBy(selectItem, 'totalPrice'),
        discountPercent: parseFloat(_.get(searchGroup, 'discount')),
        discount: parseFloat(_.get(searchGroup, 'discountNumber')),
        subtotalAfterDis: _.get(values, 'tax') === -7 ? subDis - numberParser(_.get(values, 'taxNumber')) : subDis,
        taxPercent: _.get(values, 'tax'),
        tax: numberParser(_.get(values, 'taxNumber')),
        total: subDis + numberParser(_.get(values, 'tax') === -7 ? 0 : _.get(values, 'taxNumber') || 0),
      };

      const response = await savePo(body);
      if (response.status === 200) {
        successNotification(_.get(response, 'data.status.message'));
        {
          if (type === 'waiting') {
            const reppo = await updateStatusPo(_.get(response, 'data.data.poId'), {
              statusCode: 'waiting',
              remark: '',
              reason: '',
              actionBy: localStorage.getItem('memComId'),
            });
            if (_.size(refItem) > 0) {
              const dataCreate = _.get(reppo, 'data.data');
              const payload = {
                referenceType: 'po',
                referenceId: _.get(dataCreate, 'poId'),
                referenceNo: _.get(dataCreate, 'poNo'),
                businessPartnerName: _.get(dataCreate, 'vendorSupplier'),
                businessPartnerId: _.get(dataCreate, 'vendorSupplierId'),
                createdBy: localStorage.getItem('memComId'),
                createdByName: _.get(dataCreate, 'createdBy'),
                createdDate: _.get(dataCreate, 'issueDate'),
                refToList: refItem,
              };
              await savetListReferenceTab(payload);
            }
          }
        }
        setTrigger((cur) => !cur);
        setVisible(false);
        resetFields();
        setDeliveryList([]);
        setSelectItem([]);
        setTabChange('1');
        setAttachmentData([]);
        setSearchGroup({ discount: 0, discountNumber: 0.0 });
        actionSaveToStatus()
      } else {
        errorNotification(_.get(response, 'data.status.message'));
      }
      // }
    });
  };

  const openModalEdit = (value) => {
    setEditData(value);
    setEditService(value);
    console.log('value', value);
    if (_.get(value, 'type') === 'product') setVisibleEdit(true);
    if (_.get(value, 'type') === 'service') setVisibleEditService(true);
  };

  const handleCancel = () => {
    setVisible(false);
    resetFields();
    setDeliveryList([]);
    setSelectItem([]);
    setTabChange('1');
    setAttachmentData([]);
    setSearchGroup({ discount: 0, discountNumber: 0.0 });
  };

  const handleCreateUpload = () => {
    setTypeAttachment('add');
    setOpenUpload(true);
  };

  const handleEditUpload = (data) => {
    setDefaultAttachment(data);
    setTypeAttachment('edit');
    setOpenUpload(true);
  };

  const handleTabChange = (tab) => {
    setTabChange(tab);
  };

  const handleDeleteDelivery = (value, data) => {
    const filterSelect = _.filter(deliveryList, (item) => {
      return item.index !== value;
    });
    const mapRoleback = _.map(selectItem, (item) => {
      const filterData = _.filter(data.itemList, (e) => {
        return item.itemPoId === e.itemPoId;
      });
      return {
        ...item,
        // count: _.size(filterData) > 0 ? item.count + _.get(filterData, '[0].qty') : item.count
        countTemp: _.size(filterData) > 0 ? _.get(filterData, '[0].qty') + item.countTemp : item.countTemp,
      };
    });
    setDeliveryList(filterSelect);
    setSelectItem(mapRoleback);
  };

  const handleEditDelivery = (value) => {
    console.log('recordDeDF', value);
    setDeliveryDefault(value);
    setVisibleEditDeli(true);
  };

  const operations = (
    <div style={{ textAlign: 'center', display: 'flex', padding: '10px' }}>
      <Tag className="tag-center-style" color={_.get(editDefault, 'statusColor') || ''}>
        {_.get(editDefault, 'statusName') || ''}
      </Tag>
    </div>
  );

  const handleDeleteAttachment = async (value) => {
    Modal.confirm({
      className: 'customer-modal-confirm',
      centered: true,
      icon: null,
      title: intl.formatMessage({ id: 'lblDelete', defaultMessage: 'Are you sure you want to delete ?' }),
      okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        const response = await deletePoAttachment(value);
        if (_.get(response, 'status') === 200) {
          successNotification(_.get(response, 'data.status.message'));
          setTriggerIn((cur) => !cur);
          actionSaveToStatus()
        } else {
          errorNotification(_.get(response, 'data.status.message'));
        }
      },
      onCancel() {},
    });
  };

  const onChange = async (value, code) => {
    const sumTotal = _.size(selectItem) > 0 ? _.sumBy(selectItem, 'totalPrice') : 0.0;
    console.log('TestValue', value, '/', (sumTotal * parseInt(value || 0)) / 100);
    if (code === 'discount') {
      setSearchGroup({ discount: value, discountNumber: Round((sumTotal * parseFloat(value || 0)) / 100) });
    } else if (code === 'discountNumber') {
      setSearchGroup({
        discount: ((parseFloat(value || 0.0) / sumTotal) * 100).toFixed(2),
        discountNumber: Round(value),
      });
    }
  };

  console.log('searchGroup', searchGroup);

  const handleDelete = async (id) => {
    Modal.confirm({
      className: 'customer-modal-confirm',
      centered: true,
      icon: null,
      title: intl.formatMessage({ id: 'lblDelete', defaultMessage: 'Are you sure you want to delete ?' }),
      okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        const response = await updateStatusPo(id, {
          statusCode: 'deleted',
          remark: '',
          reason: '',
          actionBy: localStorage.getItem('memComId'),
        });
        if (response.status === 200) {
          successNotification(_.get(response, 'data.status.message'));
          setTrigger((cur) => !cur);
          setVisible(false);
        } else {
          errorNotification(_.get(response, 'data.status.message'));
        }
      },
      onCancel() {},
    });
  };

  const handleOpenModalListService = () => {
    setVisibleListService(true);
  };

  const menuColumn = (
    <Menu>
      <Menu.Item onClick={() => setVisibleItem(true)}>
        <Button style={{ padding: 'unset' }} ghost type="link">
          <FormattedMessage id="expenseBtnItem" defaultMessage="Item" />
        </Button>
      </Menu.Item>
      <Menu.Item onClick={handleOpenModalListService}>
        <Button style={{ padding: 'unset' }} ghost type="link">
          <FormattedMessage id="expenseBtnService" defaultMessage="Service" />
        </Button>
      </Menu.Item>
    </Menu>
  );

  const renderButtons = () => {
    const buttons = [];
    buttons.push(
      <Button02 style={{ margin: '0px 5px' }} key="back" btnsize="wd_df" onClick={handleCancel}>
        <FormattedMessage id="btnClose" defaultMessage="Close" />
      </Button02>
    );

    if (_.includes(nextStatus, 'deleted') && checkPermissionAction('P56PG1C1', 'P56PG1C1A5')) {
      buttons.push(
        <Button01
          style={{ margin: '0px 5px' }}
          key="delete"
          type="primary"
          btnsize="wd_df"
          onClick={() => handleDelete(_.get(defaultData, 'poId'))}
        >
          <FormattedMessage id="btnDelete" defaultMessage="Delete" />
        </Button01>
      );
    }

    buttons.push(
      <Button01
        style={{ margin: '0px 5px' }}
        key="draft"
        type="primary"
        btnsize="wd_df"
        onClick={() => handleSave('draft')}
        disabled={_.size(selectItem) > 0 && _.size(refItem) <= 0 ? false : true}
      >
        <FormattedMessage id="userDetailModalBtnSave" defaultMessage="Save" />
      </Button01>
    );

    if (checkPermissionAction('P56PG1C1', 'P56PG1C1A8')) {
      buttons.push(
        <Button01
          key="submit"
          type="primary"
          btnsize="wd_df"
          onClick={() => handleSave('waiting')}
          disabled={_.size(selectItem) > 0 ? false : true}
          style={{ margin: '0px 5px', width: 'auto' }}
        >
          <FormattedMessage id="btnSendApprove" defaultMessage="Send Approve" />
        </Button01>
      );
    }
    
    return buttons;
  };

  return (
    <Modal
      title={intl.formatMessage({ id: `purchesModalTitleEdit`, defaultMessage: 'Edit Purchase Order' })}
      visible={visible}
      onCancel={handleCancel}
      width={800}
      centered={true}
      bodyStyle={{ padding: 'unset' }}
      zIndex={100}
      footer={
        renderButtons()
      //   [
      //   <Button02 key="back" btnsize="wd_df" onClick={handleCancel} style={{ margin: 'unset' }}>
      //     <FormattedMessage id="userDetailModalBtnCancel" defaultMessage="Cancel" />
      //   </Button02>,
      //   checkPermissionAction('P56PG1C1', 'P56PG1C1A5') ? (
      //     <Button01
      //       style={{ margin: '0px 0px 0px 8px' }}
      //       key="delete"
      //       type="primary"
      //       btnsize="wd_df"
      //       onClick={() => handleDelete(_.get(defaultData, 'poId'))}
      //     >
      //       <FormattedMessage id="btnDelete" defaultMessage="Delete" />
      //     </Button01>
      //   ) : null,

      //   <Button01 key="cancel" type="primary" btnsize="wd_df" onClick={() => handleSave('canceled')}>
      //     <FormattedMessage id="btnCancelPo" defaultMessage="Cancel PO" />
      //   </Button01>,
      //   <Button01
      //     style={{ margin: 'unset' }}
      //     key="draft"
      //     type="primary"
      //     btnsize="wd_df"
      //     onClick={() => handleSave('draft')}
      //     disabled={_.size(selectItem) > 0 && _.size(refItem) <= 0 ? false : true}
      //   >
      //     <FormattedMessage id="btnSaveDraft" defaultMessage="Save Draft" />
      //   </Button01>,
      //   <Button01
      //     key="submit"
      //     type="primary"
      //     btnsize="wd_df"
      //     onClick={() => handleSave('waiting')}
      //     disabled={_.size(selectItem) > 0 ? false : true}
      //   >
      //     <FormattedMessage id="userDetailModalBtnSave" defaultMessage="Save" />
      //   </Button01>,
      // ]
    }
    >
      <Tabs animated={false} onChange={handleTabChange} activeKey={tabChange} tabBarExtraContent={operations} className="po-tabs-modal">
        <TabPane tab={intl.formatMessage({ id: `purchesModalTitleDetail`, defaultMessage: 'Detail' })} key="1">
          <PurchaseCreateDetail
            form={form}
            columns={columnsDetail}
            columnsAtt={columnsAtt}
            setVisibleItem={setVisibleItem}
            vendorData={vendorData}
            contactData={contactData}
            setContactData={setContactData}
            selectItem={selectItem}
            openModalEdit={openModalEdit}
            setSelectItem={setSelectItem}
            taxList={taxList}
            handleCreateUpload={handleCreateUpload}
            attachmentData={attachmentData}
            setAttachmentData={setAttachmentData}
            handleEditUpload={handleEditUpload}
            editDefault={editDefault}
            handleDeleteAttachment={handleDeleteAttachment}
            onChange={onChange}
            searchGroup={searchGroup}
            deliveryList={deliveryList}
            setDeliveryList={setDeliveryList}
            menuColumn={menuColumn}
          />
        </TabPane>
        <TabPane tab={intl.formatMessage({ id: `purchesModalTitleDelivery`, defaultMessage: 'Delivery' })} key="2">
          <PurchaseCreateDelivery
            columns={columnsDelivery}
            setVisibleAddDeli={setVisibleAddDeli}
            deliveryList={deliveryList}
            selectItem={selectItem}
            handleDeleteDelivery={handleDeleteDelivery}
            handleEditDelivery={handleEditDelivery}
          />
        </TabPane>
        <TabPane tab={intl.formatMessage({ id: `purchesModalTitleReference`, defaultMessage: 'Reference' })} key="3">
          <ReferenceTab visiblemodal={visible} selectItem={refItem} setSelectItem={setRefItem} typeRef={'po'} />
        </TabPane>
      </Tabs>

      <ModalUploadFile
        visible={openUpload}
        setVisible={setOpenUpload}
        attData={attachmentData}
        typeAtt={typeAttachment}
        defaultAtt={defaultAttachment}
        editDefault={editDefault}
        setTriggerIn={setTriggerIn}
      />

      <ListItem
        visible={visibleItem}
        setVisible={setVisibleItem}
        setVisibleAdd={setVisibleAdd}
        setCreateData={setCreateData}
        vendorData={vendorData}
        form={form}
      />

      <ModalCreateListItem
        visible={visibleAdd}
        setVisible={setVisibleAdd}
        data={createData}
        selectItem={selectItem}
        setSelectItem={setSelectItem}
      />

      <ModalEditListItem
        visible={visibleEdit}
        setVisible={setVisibleEdit}
        data={editData}
        selectItem={selectItem}
        setSelectItem={setSelectItem}
        deliveryList={deliveryList}
      />

      <ListItemServicePO
        visible={visibleListService}
        setVisible={setVisibleListService}
        setVisibleAdd={setVisibleAddService}
        setCreateData={setCreatService}
        vendorData={vendorData}
        form={form}
      />
      <ModalCreateListServiceItemPO
        visible={visibleAddService}
        setVisible={setVisibleAddService}
        data={creatService}
        selectItem={selectItem}
        setSelectItem={setSelectItem}
      />
      <ModalEditListServiceItemPO
        visible={visibleEditService}
        setVisible={setVisibleEditService}
        data={editService}
        selectItem={selectItem}
        setSelectItem={setSelectItem}
      />

      <ModalDeliveryCreate
        vendorId={getFieldValue('vendor')}
        visible={visibleAddDeli}
        setVisible={setVisibleAddDeli}
        selectItem={selectItem}
        setSelectItem={setSelectItem}
        deliveryList={deliveryList}
        setDeliveryList={setDeliveryList}
        deliveryType={deliveryType}
        vehicleList={vehicleList}
      />

      <ModalDeliveryEdit
        vendorId={getFieldValue('vendor')}
        visible={visibleEditDeli}
        setVisible={setVisibleEditDeli}
        selectItem={selectItem}
        setSelectItem={setSelectItem}
        deliveryList={deliveryList}
        setDeliveryList={setDeliveryList}
        deliveryType={deliveryType}
        deliveryDefault={deliveryDefault}
        vehicleList={vehicleList}
      />

      <ModalPOStatusForm
        visible={visibleStatus}
        setVisible={setVisibleStatus}
        setVisibleMain={setVisible}
        type={type}
        data={defaultData}
        setTrigger={setTrigger}
      />
    </Modal>
  );
};

const EditPurchase = Form.create({
  name: 'po-form',
  mapPropsToFields() {},
})(PurchaseEdit);

export default EditPurchase;
