import React from 'react';
import CustomerForm from './customer-form';
import { FormattedMessage, useIntl } from 'react-intl';
import Button_01 from '../../../../components/v2/button_01';
import { messageLabel } from '../../../../components/message-component';
import CustomTableComponent from '../../components/table';
import { DatePicker, Divider, Input } from 'antd';
import { numberFormatter } from '../../../../component-function/fnc-inputnumber-currency';
import _ from 'lodash';

const dateFormat = 'ddd, MMM DD YYYY';

const PaymentTab = ({ form, dataSource, setDataSource }) => {

  console.log('PaymentTab', dataSource)

  const intl = useIntl();
  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      fixed: 'left',
      width: 70,
      render: (text, record, index) => index + 1,
    },
    // {
    //   title: messageLabel('quotationLabelDueDate', 'Due Date'),
    //   dataIndex: 'dueDate',
    //   key: 'dueDate',
    //   width: 200,
    //   render: (text, record, index) => (
    //     <DatePicker
    //       allowClear={false}
    //       format={dateFormat}
    //       style={{ width: '100%' }}
    //       placeholder={intl.formatMessage({ id: 'quotationHintDueDate', defaultMessage: 'Enter Due Date' })}
    //     />
    //   ),
    // },
    {
      title: messageLabel('quotationLabelCredit', 'Credit (days)'),
      dataIndex: 'credit',
      key: 'credit',
      ellipsis: true,
    },
    {
      title: messageLabel('quotationLabelAmount', 'Amount'),
      dataIndex: 'amount',
      key: 'amount',
      align : 'right',
      render: (text, record, index) =>
        numberFormatter(record.amount) + ' ' + intl.formatMessage({ id: 'labelTHB', defaultMessage: 'THB' }),
    },
    // {
    //   title: messageLabel('quotationLabelRemark', 'Remark'),
    //   dataIndex: 'remark',
    //   key: 'remark',
    //   render: (text, record, index) => (
    //     <Input value={_.get(record, 'remark')} onChange={(e) => handleChange(index, e.target.value)} placeholder={intl.formatMessage({ id: 'quotationEnterRemark', defaultMessage: 'Enter Remark' })} />
    //   ),
    // },
  ];

  const handleChange = (index, value) => {
    let cloneData = [...dataSource]
    cloneData[index].remark = value
    setDataSource(cloneData)
  }

  // const dataSource = [{ dueDate: null, credit: '2', amount: '0.00', remark: '' }];

  return (
    <div className="scroll-sm scroll-height" style={{marginTop : 24}}>
      <div className="content-body">
        <CustomTableComponent columns={columns} dataSource={dataSource} scroll={{ x: true }} total={0} role={false} onRow={false} />
      </div>
    </div>
  );
};

export default PaymentTab;
