import React, { useState, useEffect } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Input, Icon, Select } from 'antd';
import '../css/action-search.css';
import _ from 'lodash';

const { Option } = Select;

export default (props) => {
  const {
    handleChangeOption,
    mainOrgId,
    orgData,
    setOrgData,
    setOrgIdRaw,
    orgIdRaw,
    selectGroupBy,
    initialCustomerLoading,
    handleCustomerPopupScroll,
    handleCustomerChangeSearch,
    initialCustomerList,
    setMainCustomerId,
    mainCustomerId,
    handleChange,
  } = props;
  const intl = useIntl();
  const mapOrgInt =
    mainOrgId &&
    mainOrgId.map((item) => {
      return parseInt(item);
    });

  // selectGroupBy && selectGroupBy === 'team'

  return (
    <div className="action-select-team-monitor">
      {selectGroupBy ? (
        <Select
          // ref={stateMain.selectRef}
          className="monitor-select-team"
          placeholder={
            selectGroupBy === 'team'
              ? intl.formatMessage({ id: 'hintSelect', defaultMessage: 'Select Team' })
              : intl.formatMessage({ id: 'modalOrderPlaceholderCustomer', defaultMessage: 'Select Customer' })
          }
          defaultActiveFirstOption={false}
          filterOption={
            selectGroupBy === 'team'
              ? (input, option) => option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
              : false
          }
          showSearch
          loading={selectGroupBy === 'team' ? undefined : initialCustomerLoading}
          onPopupScroll={selectGroupBy === 'team' ? undefined : handleCustomerPopupScroll}
          onSearch={selectGroupBy === 'team' ? undefined : handleCustomerChangeSearch}
          maxTagCount={1}
          mode="multiple"
          value={
            selectGroupBy === 'team'
              ? orgIdRaw.map((item) => {
                  return parseInt(item);
                })
              : mainCustomerId.map((item) => {
                  return item.customerId;
                })
          }
          defaultValue={[]}
          onChange={handleChange}
          // filterOption={filterOption}
        >
          {selectGroupBy === 'team'
            ? orgData &&
              orgData.map((e, index) => (
                <Option
                  disabled={mapOrgInt && mapOrgInt.length > 3 ? (mapOrgInt && mapOrgInt.includes(e.orgId) ? false : true) : false}
                  key={index}
                  value={e.orgId}
                >
                  {e.orgName}
                </Option>
              ))
            : initialCustomerList &&
              initialCustomerList.map((item) => (
                <Option
                  disabled={
                    mainCustomerId && mainCustomerId.length > 3
                      ? mainCustomerId && mainCustomerId.includes(item.customerId)
                        ? false
                        : true
                      : false
                  }
                  value={item.customerId}
                  key={item.customerId}
                >
                  {item.comtomerName}
                </Option>
              ))}
        </Select>
      ) : (
        <Select className="monitor-select-team" disabled={true}></Select>
      )}
    </div>
  );
};
