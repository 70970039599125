import React from 'react';
import { Popover, Icon, Avatar, Divider, Card } from 'antd';
import styled from 'styled-components';

export default (props) => {
  const { comments } = props;

  let heightComments = comments && comments.length > 2 ? false : true;


  const StylesCard = styled(Card)`
    .ant-card-body {
      padding: 16px;
      height: ${heightComments === false ? '200px' : 'unset'};
      overflow-y: ${heightComments === false ? 'auto' : 'unset'};
      overflow-x: ${heightComments === false ? 'hidden' : 'unset'};
    }
  `

  const content = comments && comments.map((item, index) => {
    return (
      <div key={index}>
        {index && item.comment !== '' ? <Divider /> : ''}
        {item.comment !== '' ?
          <div className="comments-content">
            <Avatar className="comments-content-avatar" src={item.pathImage} size="large" />
            <div className="comments-content-span">
              <span>{item.fullname}</span>
              <span>{item.timestampView}</span>
              <span>{item.comment}</span>
            </div>
          </div>
          : null}
      </div>
    )
  })


  return (
    <Popover
      className="comment-details"
      content={
        <StylesCard className="comments-content-card" title='Comment'>{content}</StylesCard>
      }
      trigger="click"
    >
      {comments && comments?.length ? (
        <Icon style={{ fontSize: '16px', color: 'rgba(0, 0, 0, 0.2)', paddingLeft: '8px' }} type="message" />
      ) : null}
    </Popover>
  )
}


