import React from 'react';
import { Row, Col, Avatar, Icon } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import _ from 'lodash';

export default (props) => {
  const { assigneeData, keys, setKeys } = props;

  const handleSelect = (memComId) => {
    const thisKeys = [...keys];
    const findIndexMemComId = _.findIndex(thisKeys, (val) => {
      return val === memComId ? true : false;
    });
    if (findIndexMemComId > -1) {
      thisKeys.splice(findIndexMemComId, 1);
    } else {
      thisKeys.push(memComId);
    }

    setKeys(thisKeys);
    // onChange && onChange(thisKeys);
  };

  const AddAssignee = () => {
    return (
      <div>
        <div className="assignee-detail">
          <Avatar icon="plus" size="large" style={{ marginRight: '16px' }} />
          <div>
            <p className="name-p">
              <FormattedMessage id="monitorTabDetailAssigneeDetailAdd" defaultMessage="Add Assignee" />
            </p>
          </div>
        </div>
      </div>
    );
  };

  const assigneeDetail =
    assigneeData &&
    assigneeData.map((item, index) => {
      const findIndexMemComId = _.findIndex(keys, (val) => {
        return val === item.memComId ? true : false;
      });
      return (
        <div
          key={item.memComId}
          className={item.active === 0 ? 'assignee-detail' : 'assignee-detail-disable'}
          onClick={() => handleSelect(item.memComId)}
        >
          <Avatar
            style={{ marginRight: '16px', border: `solid 3px ${findIndexMemComId > -1 ? '#ffffff' : '#1D3557'}` }}
            size="large"
            src={item.pathImage}
            shape="circle"
          />

          <div>
            <p className="name-p">{item.fullname}</p>
            {item.subtask.map((e, i) => {
              const iconExcel = () => {
                if (e.done == true) {
                  return <img key={i} className="table-status-img" src={e.icon} />;
                } else {
                  return <img key={i} className="table-status-img" src={e.icon} style={{ filter: 'grayscale(100%)' }} />;
                }
              };

              return (
                <div className="table-status">
                  <div key={i} className="table-status-key">
                    <Icon className="table-status-icon" component={iconExcel} />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      );
    });

  return (
    <div style={{ padding: '10px 24px 0px 24px' }}>
      <span className="assignee-title-label">
        <FormattedMessage id="monitorTabDetailAssigneeDetailTxtAssignee" defaultMessage="Assignee" />
      </span>
      <Row className="assignee-row">
        <Col span={24}>
          <Row>
            <div style={{ padding: '10px 0px 0px 0px' }}>
              {assigneeDetail}
              <AddAssignee
              // resourceAndTaskID={resourceAndTaskID}
              />
            </div>
          </Row>
        </Col>
      </Row>
    </div>
  );
};
