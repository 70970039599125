import React from 'react';
import { Card, Divider, Dropdown, Modal, Tabs } from 'antd';
import { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams, useHistory } from 'react-router';
import { StickyContainer } from 'react-sticky';
import moment from 'moment';
import _ from 'lodash';
import './css/index.css';

import { successNotification, errorNotification } from '../../../components/v2/notification';
import { PageSettings } from '../../../config/page-settings';
import ProjectDetail from './detail';
import ProjectStakeholder from './stakeholder';
import ModalEditProject from '../../../components/project/modal-edit/index';
import ModalStakeHolder from '../../../components/project/modal-stakeholder/index';
import Button01 from '../../../components/v2/button_01';
import Button02 from '../../../components/v2/button_02';
import Attachment from './attachment';
import MemberProjectDetail from './member';
import ProjectDetailSetting from './setting';
import ModalProjectAddMemeberForm from '../../../components/project/modal-add-member';

import {
  getAvatarList,
  getProjectById,
  deleteStakeholder,
  deleteProject,
  getSearchStakeHolder,
} from '../../../controllers/project/project-api';
import { getMember } from '../../../controllers/project-new/member';

const { TabPane } = Tabs;

const DetailPage = () => {
  const intl = useIntl();
  const [visibleEdit, setVisibleEdit] = useState(false);
  const [visibleAdd, setVisibleAdd] = useState(false);
  const [okAdd, setOkAdd] = useState(false);
  const [cancelAdd, setCancelAdd] = useState(false);
  const [visibleModalStakeHolder, setVisibleModalStakeHolder] = useState(false);
  const { project_id } = useParams();
  const history = useHistory();

  const [listData, setListData] = useState([]);
  const [listTotal, setListTotal] = useState(0);
  const [avatarList, setAvatarList] = useState([]);
  const [projectData, setProjectData] = useState([]);
  const [trigger, setTrigger] = useState(false);
  const [stakedata, setStakeData] = useState();
  const [totalstake, setTotalStake] = useState();
  const [triggerStake, setTriggerStake] = useState(false);
  const [paginationPage, setPaginationPage] = useState(1);
  const [paginationSize, setPaginationSize] = useState(10);
  const [error, setError] = useState(null);

  const [tabKey, setTabKey] = useState('projectDetails');
  const [visibleAtt, setVisibleAtt] = useState(false);

  const [searchMemberValue, setSearchMemberValue] = useState({
    name: '',
    memberType: '',
    emOrTeamType: '',
    phone: '',
    email: '',
    priority: '',
    page: 1,
    limit: 10,
    sortBy: undefined,
    orderBy: undefined,
  });

  useEffect(() => {
    const getAvatarApi = async () => {
      const response = await getAvatarList();
      setAvatarList(response || []);
    };

    getAvatarApi();
  }, []);

  useEffect(() => {
    const getById = async () => {
      const response = await getProjectById(project_id);
      const item = _.get(response, 'data');
      setProjectData({
        id: item.projectId,
        index: item.index,
        projectNo: item.projectNo,
        projectName: item.projectName,
        projectType: item.projectTypeName,
        priority: item.projectPriorityName,
        customer: item.customerName,
        projectManager: item.projectMangerName,
        planDate: item.planDate,
        reference: item.referenceNo,
        remark: item.remark,
        projectImgProfile: item.projectImgProfile,
        projectTypeId: item.projectTypeId,
        projectTypeName: item.projectTypeName,
        projectPriorityId: item.projectPriorityId,
        planStart: moment(item.planStart, 'YYYY-MM-DD'),
        planFinish: moment(item.planFinish, 'YYYY-MM-DD'),
        customerId: item.customerId,
        projectMangerId: parseInt(item.projectMangerId),
        projectPriorityIcon: item.projectPriorityIcon,
        fileHash: item.projectImgProfileFileHash,
      });
    };
    getById();
  }, [project_id, trigger]);

  useEffect(() => {
    const getSkate = async () => {
      const body = {
        search: '',
        searchBy: 'all',
        orderType: 'desc',
        orderBy: '',
        paging: paginationPage,
        rowsPerPages: paginationSize,
      };
      const skate = await getSearchStakeHolder(body, project_id);
      setStakeData(skate.data.data);
      setTotalStake(skate.data.total);
    };
    getSkate();
  }, [project_id, triggerStake, paginationPage, paginationSize]);

  const getMemberData = async () => {
    const body = {
      name: _.get(searchMemberValue, 'name'),
      member_type: _.get(searchMemberValue, 'memberType'),
      type: _.get(searchMemberValue, 'emOrTeamType'),
      phone: _.get(searchMemberValue, 'phone'),
      email: _.get(searchMemberValue, 'email'),
      priority: _.get(searchMemberValue, 'priority'),
      page: _.get(searchMemberValue, 'page'),
      pageSize: _.get(searchMemberValue, 'limit'),
      orderBy: _.get(searchMemberValue, 'sortBy'),
      order: _.get(searchMemberValue, 'orderBy'),
    };
    try {
      const listData = await getMember({ body, projectId: project_id });
      console.log(listData, 'lolo ??');
      setListData(listData.data);
      setListTotal(listData.data.length);
    } catch (err) {
      setError(err.message);
    }
  };

  useEffect(() => {
    getMemberData();
  }, [
    trigger,
    _.get(searchMemberValue, 'page'),
    _.get(searchMemberValue, 'limit'),
    _.get(searchMemberValue, 'orderBy'),
    _.get(searchMemberValue, 'sortBy'),
  ]);

  const handleSearchMember = (code, value) => {
    setSearchMemberValue((prev) => ({ ...prev, [code]: value }));
  };

  const handleSubmitSearch = () => {
    setTrigger((prev) => !prev);
    setSearchMemberValue((prev) => ({
      ...prev,
      page: 1,
    }));
  };

  const handleResetSearch = () => {
    setTrigger((prev) => !prev);
    setSearchMemberValue((prev) => ({
      ...prev,
      name: '',
      memberType: '',
      emOrTeamType: '',
      phone: '',
      email: '',
      priority: '',
      page: 1,
      limit: 10,
      sortBy: undefined,
      orderBy: undefined,
    }));
  };

  const handleEditProject = () => {
    setVisibleEdit(true);
  };

  const handleVisibleAddProjectMember = () => {
    setVisibleAdd(true);
  };

  const handleCancelAddProjectMember = (value) => {
    setCancelAdd(true);
    setVisibleAdd(false);
  };

  const handleOkAddProjectMember = (value) => {
    setOkAdd(true);
    setTrigger((prev) => !prev);
    setVisibleAdd(false);
  };

  const handleDeleteProject = (data) => {
    Modal.confirm({
      className: 'customer-modal-confirm',
      centered: true,
      icon: null,
      title: intl.formatMessage({
        id: `monitorCreateTaskModalConfirmDeleteProject`,
        defaultMessage: 'Are you sure to delete project',
      }),
      okText: intl.formatMessage({
        id: 'modalBtnConfirm',
        defaultMessage: 'Confirm',
      }),
      cancelText: intl.formatMessage({
        id: 'modalBtnCancel',
        defaultMessage: 'Cancel',
      }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        const response = await deleteProject(data.id);
        if (response.status === 200) {
          setTrigger((current) => !current);
          successNotification(response.data.massage);
          historyHome();
        } else {
          errorNotification(response.data.massage);
        }
      },
      onCancel() {},
    });
  };

  const handleDeleteSkateholder = (id) => {
    Modal.confirm({
      className: 'customer-modal-confirm',
      centered: true,
      icon: null,
      title: intl.formatMessage({
        id: `monitorCreateTaskModalConfirmDeleteSkate`,
        defaultMessage: 'Are you sure to delete stakeholder',
      }),
      okText: intl.formatMessage({
        id: 'modalBtnConfirm',
        defaultMessage: 'Confirm',
      }),
      cancelText: intl.formatMessage({
        id: 'modalBtnCancel',
        defaultMessage: 'Cancel',
      }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        const response = await deleteStakeholder(id);
        if (response.status === 200) {
          setTriggerStake((current) => !current);
          successNotification(response.data.massage);
        } else {
          errorNotification(response.data.massage);
        }
      },
      onCancel() {},
    });
  };

  const handlePagination = (page) => {
    setPaginationPage(page);
  };

  const handleSizeChange = (current, size) => {
    setPaginationSize(size);
    setPaginationPage(current);
  };
  const historyHome = () => {
    history.push('/main/project/list');
  };

  ///////////////////////////////////////// newmenu

  const callback = (key) => {
    setTabKey(key);
  };

  const tabBarExtraContent = () => {
    return (
      <div style={{ marginRight: '23px' }}>
        <PageSettings.Consumer>
          {({ checkPermissionAction }) => {
            if (tabKey === 'projectDetails') {
              return (
                <div className="action-button-group-project">
                  {checkPermissionAction('P39PG2C1', 'P39PG2C1A3') ? (
                    <div>
                      <Button02
                        style={{ margin: '0px 0px 0px 10px' }}
                        className="action-button-group-delete"
                        btnsize="wd_df"
                        onClick={() => handleDeleteProject(projectData)}
                      >
                        <FormattedMessage id="btnDelete" defaultMessage="Delete" />
                      </Button02>
                    </div>
                  ) : null}
                  {checkPermissionAction('P39PG2C1', 'P39PG2C1A2') ? (
                    <div>
                      <Button01
                        className="action-button-group-delete"
                        btnsize="wd_df"
                        fontsize="sm"
                        type="primary"
                        onClick={handleEditProject}
                      >
                        <FormattedMessage id="btnEdit" defaultMessage="Edit" />
                      </Button01>
                    </div>
                  ) : null}
                </div>
              );
            } else if (tabKey === 'member') {
              return (
                <div className="action-button-group-project">
                  {checkPermissionAction('P39PG2C1', 'P39PG2C1A2') ? (
                    <div>
                      <Button01
                        className="action-button-group-delete"
                        btnsize="wd_df"
                        fontsize="sm"
                        type="primary"
                        onClick={handleVisibleAddProjectMember}
                      >
                        <FormattedMessage id="btnAdd" defaultMessage="Add" />
                      </Button01>
                    </div>
                  ) : null}
                </div>
              );
            } else {
              return null;
            }
          }}
        </PageSettings.Consumer>
      </div>
    );
  };

  return (
    <PageSettings.Consumer>
      {({ checkPermissionAction }) => (
        <div>
          <Card bodyStyle={{ margin: '0px', padding: '0px' }}>
            <StickyContainer>
              <Tabs
                className="monitor-main-tabs customer-tab-margin"
                size="large"
                defaultActiveKey="projectDetails"
                onChange={callback}
                animated={false}
                tabBarExtraContent={
                  tabBarExtraContent()
                  // <div>
                  //   <Dropdown
                  //     overlay={menuColumn}
                  //     trigger={['click']}
                  //     onVisibleChange={setVisible}
                  //     visible={visible}
                  //     placement="bottomRight"
                  //   >
                  //     <Button_01 key="submit" type="primary" btnsize="wd_df" onClick={() => {}}>
                  //       <FormattedMessage id="btnActionGroup" defaultMessage="Action" />
                  //       <DownOutlined style={{ fontSize: '14px', paddingLeft: '22%' }} />
                  //     </Button_01>
                  //   </Dropdown>
                  // </div>
                }
              >
                <TabPane
                  tab={intl.formatMessage({ id: `projectProfileLabelrojectDetails`, defaultMessage: 'Project Details' })}
                  key="projectDetails"
                >
                  <div className="padding-between-card" style={{ margin: '24px 0px' }}>
                    {checkPermissionAction('P39PG2C1', 'P39PG2C1A1') ? <ProjectDetail record={projectData} /> : null}
                  </div>

                  <div className="item-title">
                    <span className="item-title-text">
                      <FormattedMessage id="stakeholderLabelStakeholder" defaultMessage="Stakeholder" />
                    </span>
                    <div>
                      {checkPermissionAction('P39PG2C1', 'P39PG2C1A1') ? (
                        <Button01 fontsize="sm" type="primary" onClick={() => setVisibleModalStakeHolder(true)}>
                          <FormattedMessage id="btnAdd" defaultMessage="Add" />
                        </Button01>
                      ) : null}
                    </div>
                  </div>
                  <Divider type="horizontal" style={{ margin: '12px 0px' }} />

                  <div style={{ margin: '24px' }}>
                    {checkPermissionAction('P39PG2C2', 'P39PG2C2A1') ? (
                      <ProjectStakeholder
                        setVisible={setVisibleModalStakeHolder}
                        stakedata={stakedata}
                        totalstake={totalstake}
                        paginationPage={paginationPage}
                        handleDeleteSkateholder={handleDeleteSkateholder}
                        handlePagination={handlePagination}
                        handleSizeChange={handleSizeChange}
                      />
                    ) : null}
                  </div>

                  <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', padding: '16px 24px 0px' }}>
                    <span className="item-title-text">
                      <FormattedMessage id="attachmentLabel" defaultMessage="Attachment" />
                    </span>
                    <div>
                      <Button01 fontsize="sm" type="primary" onClick={() => setVisibleAtt(true)}>
                        <FormattedMessage id="btnUpload" defaultMessage="Upload" />
                      </Button01>
                    </div>
                  </div>
                  <Divider type="horizontal" style={{ margin: '12px 0px' }} />

                  <div style={{ margin: '24px' }}>
                    <Attachment visible={visibleAtt} setVisible={setVisibleAtt} id={project_id} />
                  </div>
                </TabPane>

                <TabPane tab={intl.formatMessage({ id: `projectProfileLabelrojectMember`, defaultMessage: 'Member' })} key="member">
                  <MemberProjectDetail
                    id={project_id}
                    trigger={trigger}
                    setTrigger={setTrigger}
                    listData={listData}
                    listTotal={listTotal}
                    searchMemberValue={searchMemberValue}
                    setSearchMemberValue={setSearchMemberValue}
                    handleSearchMember={handleSearchMember}
                    handleSubmitSearch={handleSubmitSearch}
                    handleResetSearch={handleResetSearch}
                  />
                </TabPane>

                {/* <TabPane
                  tab={intl.formatMessage({ id: `projectProfileLabelrojectPricing`, defaultMessage: 'Pricing' })}
                  key="pricing"
                ></TabPane> */}

                <TabPane tab={intl.formatMessage({ id: `projectProfileLabelrojectSetting`, defaultMessage: 'Setting' })} key="setting">
                  <ProjectDetailSetting id={project_id} />
                </TabPane>
              </Tabs>
            </StickyContainer>
          </Card>

          <ModalEditProject
            visible={visibleEdit}
            setVisible={setVisibleEdit}
            avatarList={avatarList}
            projectData={projectData}
            setTrigger={setTrigger}
          />

          <ModalStakeHolder
            visible={visibleModalStakeHolder}
            setVisible={setVisibleModalStakeHolder}
            id={project_id}
            setTriggerStake={setTriggerStake}
            stakedata={stakedata}
          />

          <ModalProjectAddMemeberForm
            visible={visibleAdd}
            setVisible={setVisibleAdd}
            onOk={handleOkAddProjectMember}
            onCancel={handleCancelAddProjectMember}
            setTrigger={setTrigger}
            project_id={project_id}
          />
        </div>
      )}
    </PageSettings.Consumer>
  );
};

export default DetailPage;
