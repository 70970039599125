import React, { useState ,useContext } from 'react';
import { Card, Row, Col } from 'antd'
import { useIntl,FormattedMessage } from 'react-intl'
import SelectAssetClass from './selectAssetClass';
import './css/index.css'
import BlankAssetClass from './blankAssetClass';
import FilterAssetClass from './filterAssetClass';
import AssetSummaryContext from '../context';

const CardCategory = () => {
const intl = useIntl()
  const { state, setState, fnc } = useContext(AssetSummaryContext);
  const { assetSelect } = state;

  return (
    <Card
      title={intl.formatMessage({ id: `assetCardListFilter`, defaultMessage: 'Filter' })}
      className='left-card-asset-modal'
    >
      <div className='div-content-asset'>
        <div className='div-content-asset-select'>
          <SelectAssetClass />
        </div>
        <div className='render-list-asset scroll-sm'>
          {
            assetSelect ?
              <div>
                <FilterAssetClass />
              </div>
              :
              <BlankAssetClass />
          }
        </div>
      </div>
    </Card>
  )
}

export default CardCategory