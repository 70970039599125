import React from 'react';
import { Modal, Form, Row, Col, Input } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import _ from 'lodash';
import { handleKeyDownNumber } from '../../../../../component-function/fnc-number';
import LabeRequireForm from '../../../../../components/label-required-form';
import Button_01 from '../../../../../components/v2/button_01';
import Button_02 from '../../../../../components/v2/button_02';

const AddARItem = (props) => {
  const {
    visible,
    setVisible,
    form,
    record,
    selectItemTemp,
    setSelectItem,
    maxRemain,
    setMaxRemain,
    warehouseList,
    setWarehouseList,
    recordList,
    typeQty,
    handleSaveSale,
  } = props;
  const { getFieldDecorator, validateFields, resetFields } = form;
  const intl = useIntl();

  console.log('selectItemTemp', record);

  const handleSave = () => {
    validateFields(async (err, values) => {
      if (err) {
        return;
      }

      let tempMax = [...warehouseList];
      // let tempSO = [...saleOrderQTY];
      const findex = _.get(record, 'index') - 1;
      const fSoIndex = _.get(recordList, 'index') - 1;
      console.log('findex', tempMax, '/index: ', findex, '/temp[index] : ', tempMax[findex]);

      tempMax[findex].selectQty = parseInt(values.qty);
      // tempSO[fSoIndex].qtyDeliver = _.sumBy(tempMax, 'selectQty');
      // tempMax[findex].push({selectQty: parseInt(values.qty)});
      // setWarehouseList((prev) => ({...prev[findex],selectQty: values.qty}));

      setWarehouseList(tempMax);
      handleSaveSale();

      setVisible(false);
      resetFields();
    });
  };

  const handleCancel = () => {
    if (typeQty === 'edit') {
      const tempMax = [...warehouseList];
      const findex = _.get(record, 'index') - 1;
      tempMax[findex].selectQty = parseInt(_.get(record, 'selectQtyEdit'));
      setWarehouseList(tempMax);
    }
    setVisible(false);
    resetFields();
  };

  const validateAmount = (e, value, callback) => {
    console.log('LogAR', value);
    const maxQTY = _.get(record, 'qty');
    const tempMax = [...warehouseList];
    const sumqty = maxRemain - _.sumBy(tempMax, 'selectQty');

    if (parseInt(value) > maxRemain) {
      if (parseInt(value) > maxQTY) {
        if (parseInt(value) > sumqty) {
          callback(<FormattedMessage id="modalAROverRemain" defaultMessage="Qty more than Remaining QTY." />);
          console.log('maxremain', parseInt(maxRemain) / parseInt(maxQTY));
        } else {
          callback(<FormattedMessage id="modalAROverRStock" defaultMessage="Qty more than Stock." />);
          console.log('maxremain1', parseInt(maxRemain) / parseInt(maxQTY));
        }
      } else {
        callback(<FormattedMessage id="modalAROverRemain" defaultMessage="Qty more than Remaining QTY." />);
      }
    } else {
      if (parseInt(value) > maxQTY) {
        // tempMax[index].selectQty = maxQTY;
        callback(<FormattedMessage id="modalAROverRStock" defaultMessage="Qty more than Stock." />);
        console.log('maxremain4', parseInt(maxRemain) / parseInt(maxQTY));
      } else {
        if (parseInt(value) > sumqty) {
          // tempMax[index].selectQty = parseInt(sumqty);
          callback(<FormattedMessage id="modalAROverRemain" defaultMessage="Qty more than Remaining QTY." />);
          console.log('maxremain5', parseInt(sumqty));
        } else {
          callback();
        }
      }
    }
  };

  const validatorMinMax = (rule, value, callback) => {
    const parsInt = parseInt(value || 0);

    // if (value && typeItem === "add") {
    //   if (parsInt > _.get(record, 'count')) {
    //     callback(<FormattedMessage id="modalDeliveryTitleOverthan" defaultMessage="Qty more than item." />)
    //   } else if (parsInt === 0) {
    //     callback(<FormattedMessage id="modalDeliveryTitleOverthan0" defaultMessage="Qty not eqaul 0" />)
    //   } else {
    //     callback()
    //   }
    // } else if (value && typeItem === "edit") {
    //   if (parsInt > _.get(record, 'count')) {
    //     callback(<FormattedMessage id="modalDeliveryTitleOverthan" defaultMessage="Qty more than item." />)
    //   } else {
    //     callback()
    //   }
    // } else {
    //   callback();
    // }
  };

  const handleAmount = (e, value) => {
    if (parseInt(value) <= 0) {
      e.target.value = 1;
    }
  };

  return (
    <Modal
      title={intl.formatMessage({ id: `modalDeliveryTitleAddItem`, defaultMessage: 'Add Item' })}
      centered={true}
      width={500}
      visible={visible}
      bodyStyle={{ padding: 'unset' }}
      onCancel={handleCancel}
      footer={[
        <Button_02 key="back" btnsize="wd_df" onClick={handleCancel}>
          <FormattedMessage id="userDetailModalBtnCancel" defaultMessage="Cancel" />
        </Button_02>,
        <Button_01 key="submit" type="primary" btnsize="wd_df" onClick={handleSave} style={{ margin: 'unset' }}>
          <FormattedMessage id="userDetailModalBtnSave" defaultMessage="Save" />
        </Button_01>,
      ]}
    >
      <Form form={form} layout="vertical" style={{ padding: '16px' }}>
        <Row gutter={[16]}>
          <Col span={12}>
            <Form.Item
              label={<LabeRequireForm text={intl.formatMessage({ id: 'ARWarehouseCode', defaultMessage: 'Warehouse Code' })} req={false} />}
            >
              {_.get(record, 'warehouseCode')}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={<LabeRequireForm text={intl.formatMessage({ id: 'ARWarehouse', defaultMessage: 'Warehouse' })} req={false} />}
            >
              {_.get(record, 'warehouseName')}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16]}>
          <Col span={12}>
            <Form.Item label={<LabeRequireForm text={intl.formatMessage({ id: 'ARUoM', defaultMessage: 'UoM' })} req={false} />}>
              {_.get(record, 'uom')}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={<LabeRequireForm text={intl.formatMessage({ id: 'modalDeliveryColumnsQty', defaultMessage: 'Qty' })} req={true} />}
            >
              {getFieldDecorator('qty', {
                initialValue: typeQty === 'edit' ? _.get(record, 'selectQtyEdit') : '1',
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'modalDeliveryColumnsQtyValidate', defaultMessage: 'Please Enter Qty' }),
                  },
                  {
                    validator: validateAmount,
                  },
                ],
              })(
                <Input
                  placeholder={intl.formatMessage({ id: 'modalDeliveryColumnsQtyPlaceholder', defaultMessage: 'Enter Qty' })}
                  onKeyDown={handleKeyDownNumber}
                  onChange={(e) => {
                    handleAmount(e, e.target.value);
                  }}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

const ModalAddARItem = Form.create({
  name: 'assignTeam-form',
  mapPropsToFields() {},
})(AddARItem);

export default ModalAddARItem;
