import { Card, Col, Row, Tabs } from 'antd';
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { PageSettings } from '../../config/page-settings';
import { errorNotification, successNotification } from '../../components/v2/notification';
import { AllOrderContext } from './allorder-context';
import CardTableAllOrder from './table';
import ViewModalAllOrder from './modal/view';
import { getAllorderDetail, getAllorderList, getNextStatus } from '../../controllers/allorder';

const AllOrder = () => {
  const [checkteam, setCheckTeam] = useState();
  const [orgIdRaw, setOrgIdRaw] = useState();
  const [paginationPage, setPaginationPage] = useState();
  const [spanRight, setSpanRight] = useState(18);
  const [spanLeft, setSpanLeft] = useState(6);
  const [menuList, setMenuList] = useState([]);
  const [indeterminateTeam, setIndeterminateTeam] = useState(false);
  const [checkedKeysTeam, setCheckedKeysTeam] = useState([]);
  const [searchList, setSearchList] = useState([]);
  const [changeStatus, setChangeStatus] = useState([]);
  const [brandData, setBrandData] = useState([]);
  const [modelData, setModelData] = useState([]);
  const [changeTab, setChangeTab] = useState('1');
  const [checkAllTeam, setCheckAllTeam] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [fillter, setFillter] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [visibleCreate, setVisibleCreate] = useState(false);
  const [visibleView, setVisibleView] = useState(false);
  const [orderId, setOrderId] = useState();
  const [orderList, setOrderList] = useState([]);
  const [event, setEvent] = useState(false);
  const [editRecord, setEditRecord] = useState();
  const [sort, setSort] = useState([]);
  const [total, setTotal] = useState();
  const [searchGroup, setSearchGroup] = useState();
  const [viewData, setViewData] = useState();
  const [statusList, setStatusList] = useState([]);
  const [resonList, setResonList] = useState();
  const [nextStatus, setNextStatus] = useState([]);
  const [orderTypeList, setOrderTypeList] = useState([]);

  // useEffect(() => {
  //   const getApiMenu = async () => {
  //     const response = await getMenuFilter();
  //     setMenuList(response.data);
  //     const allteam = _.map(response.data.data.team,'orgId')
  //     onCheckTeam(allteam)
  //   };
  //   getApiMenu();
  // }, []);

  useEffect(() => {
    const getDataTable = async () => {
      const body = {
        pageNumber: page,
        limit: size,
        orderNo: _.get(searchGroup, 'orderno') ? _.get(searchGroup, 'orderno') :'',
        statusCode: _.get(searchGroup, 'status') ? [_.get(searchGroup, 'status')] : [],
        customerId: _.get(searchGroup, 'customer') ? [_.get(searchGroup, 'customer')] : [],
        warehouseId: _.get(searchGroup, 'merchant') ? [_.get(searchGroup, 'merchant')] : [],
        requestOrderTypeCode: _.get(searchGroup, 'ordertype') ? [_.get(searchGroup, 'ordertype')] : [],
        orderDateFrom: _.get(searchGroup, 'startorder'),
        orderDateTo: _.get(searchGroup, 'endorder'),
        submittedDateFrom: _.get(searchGroup, 'startsubmit'),
        submittedDateTo: _.get(searchGroup, 'endsubmit'),
        team: checkedKeysTeam,
        orderBy: _.get(sort, 'columnKey'),
        orderType: _.get(sort, 'order') === 'descend' ? 'desc' :  _.get(sort, 'order') ? 'asc' : undefined ,
      };

      const responase = await getAllorderList(body);
      setOrderList(_.get(responase, 'data.orderList'));
      setOrderTypeList(_.get(responase, 'data.orderType'))
      setTotal(_.get(responase, 'data.totalItem'));
      setStatusList(_.get(responase, 'data.getAllStatus'));
      setIsLoading(false);
    };
    setIsLoading(true);
    getDataTable();

    console.log('tikcetData', orderList);
  }, [event, searchGroup, fillter, page, size]);

  useEffect(() => {
    const getDetail = async () => {
      const body = {
        cartId: _.get(orderId, 'cartId'),
      };

      const responase = await getAllorderDetail(orderId.cartId);
      const responaseStatus = await getNextStatus(body);


      

      setNextStatus(_.get(responaseStatus, 'data.statusCodeList'));
      setViewData(_.get(responase, 'data.orderDetail'));
      setResonList(_.get(responase, 'data.reasonList'));
    };
    if (visibleView === true) {
      getDetail();
    }
  }, [visibleView]);

  const handleToggle = () => {
    setToggle((current) => !current);
  };

  const handleChangePage = (page) => {
    setPage(page);
  };

  const handleChangeSize = (current, size) => {
    setSize(size);
    setPage(current);
  };

  const handleVisibleCreate = () => {
    setVisibleCreate(true);
  };

  const handleView = (record) => {
    setOrderId(record);
    // setOrderId(record.orderId);
    setVisibleView(true);
  };

  const handleEdit = async (record) => {
    // const responase = await getTicketDetail(_.get(record, 'orderId'));
    // setEditRecord(_.get(responase, 'data.data.rawData'));
    // setOrderId(record.orderId);
    handleVisibleCreate();
  };

  return (
    // <AllOrderContext.Provider
    //   value={{
    //     state: {
    //       checkteam,
    //       orgIdRaw,
    //       paginationPage,
    //       spanRight,
    //       spanLeft,
    //       menuList,
    //       indeterminateTeam,
    //       checkedKeysTeam,
    //       searchList,
    //       checkAllTeam,
    //       changeTab,
    //       isLoading,
    //       fillter,
    //       selectedRowKeys,
    //       toggle,
    //       visibleCreate,
    //       orderList,
    //       event,
    //       editRecord,
    //       sort,
    //       orderId,
    //       page,
    //       total,
    //       isLoading,
    //       brandData,
    //       modelData,
    //       searchGroup,
    //       viewData,
    //       statusList,
    //       nextStatus,
    //     },
    //     setState: {
    //       setSpanRight,
    //       setSpanLeft,
    //       setViewData,
    //       setCheckTeam,
    //       setOrgIdRaw,
    //       setPaginationPage,
    //       setCheckedKeysTeam,
    //       setIndeterminateTeam,
    //       setChangeStatus,
    //       setChangeTab,
    //       setCheckAllTeam,
    //       setIsLoading,
    //       setFillter,
    //       setSelectedRowKeys,
    //       setToggle,
    //       setVisibleCreate,
    //       setEditRecord,
    //       setEvent,
    //       setSort,
    //       setSearchGroup,
    //       setStatusList,
    //     },
    //     fnc: {
    //       handleToggle,
    //       handleChangePage,
    //       handleChangeSize,
    //       handleVisibleCreate,
    //       handleView,
    //       handleEdit,
    //     },
    //   }}
    // >
    //   <Row gutter={[16, 16]}>
    //     <CardTableAllOrder />
    //   </Row>
    //   <ViewModalAllOrder
    //     visible={visibleView}
    //     setVisible={setVisibleView}
    //     orderId={orderId}
    //     viewData={viewData}
    //     setViewData={setViewData}
    //     statusList={statusList}
    //     setStatusList={setStatusList}
    //     resonList={resonList}
    //     nextStatus={nextStatus}
    //   />
    // </AllOrderContext.Provider>
    <PageSettings.Consumer>
      {({ checkPermissionAction }) => (
        <AllOrderContext.Provider
          value={{
            state: {
              checkteam,
              orgIdRaw,
              paginationPage,
              spanRight,
              spanLeft,
              menuList,
              indeterminateTeam,
              checkedKeysTeam,
              searchList,
              checkAllTeam,
              changeTab,
              isLoading,
              fillter,
              selectedRowKeys,
              toggle,
              visibleCreate,
              orderList,
              event,
              editRecord,
              sort,
              orderId,
              page,
              total,
              isLoading,
              brandData,
              modelData,
              searchGroup,
              viewData,
              statusList,
              nextStatus,
              orderTypeList
            },
            setState: {
              setSpanRight,
              setSpanLeft,
              setViewData,
              setCheckTeam,
              setOrgIdRaw,
              setPaginationPage,
              setCheckedKeysTeam,
              setIndeterminateTeam,
              setChangeStatus,
              setChangeTab,
              setCheckAllTeam,
              setIsLoading,
              setFillter,
              setSelectedRowKeys,
              setToggle,
              setVisibleCreate,
              setEditRecord,
              setEvent,
              setSort,
              setSearchGroup,
              setStatusList,
              setPage,
            },
            fnc: {
              handleToggle,
              handleChangePage,
              handleChangeSize,
              handleVisibleCreate,
              handleView,
              handleEdit,
            },
          }}
        >
          {checkPermissionAction(`P71PG1C1`, `P71PG1C1A1`) ? (
            <>
              <Row gutter={[16, 16]}>
                <CardTableAllOrder />
              </Row>
              <ViewModalAllOrder
                visible={visibleView}
                setVisible={setVisibleView}
                orderId={orderId}
                viewData={viewData}
                setViewData={setViewData}
                statusList={statusList}
                setStatusList={setStatusList}
                resonList={resonList}
                nextStatus={nextStatus}
              />
            </>
          ) : null}
        </AllOrderContext.Provider>
      )}
    </PageSettings.Consumer>
  );
};

export default AllOrder;
