import React, { useState, useEffect, useRef, useContext } from 'react'
import { Form, Input, Row, Col, Icon, Select } from 'antd'
import styled from 'styled-components';
import AppAvatar from '../../components/avatar';
import DefaultProfile from '../../components/image/NoImageR.png';
import style from './css/index.css'
import Resizer from 'react-image-file-resizer';
import { useIntl, FormattedMessage } from 'react-intl';
import { itemMasterContext } from './index';
import _ from 'lodash';

export default ({ itemImage, setItemImage, record }) => {
  const imageRef = useRef();
  const { state, setState } = useContext(itemMasterContext);

  const [visible, setVisible] = useState(false);
  const [visibleText, setVisibleText] = useState(false);
  const [imageFile, setImageFile] = useState();
  const [imageFileDefault, setImageFileDefault] = useState('');
  const pathImage = _.get(record, 'pathImage');

  useEffect(() => {
    setImageFileDefault(DefaultProfile)
  }, [DefaultProfile])


  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        720,
        720,
        'PNG',
        70,
        0,
        (uri) => {
          resolve(uri);
        },
        'base64',
      );
    });

  const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    for (var i = 0; i < bstr.length; i++) {
      u8arr[i] = bstr.charCodeAt(i);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const handleChangePicture = async (event) => {
    event.preventDefault();
    let reader = new FileReader();
    const file = event.target.files[0];

    if (file) {
      setVisibleText(false);
      if (file.name.match(/\.(jpg|jpeg|png|JPG|PNG|JPEG)$/)) {

        const image = await resizeFile(file);

        reader.onloadend = () => {
          const image = reader.result;
          //setImage(image);
          setState.setImageFile(image);
        };
        reader.readAsDataURL(file);

        const fileChange = dataURLtoFile(image, file.name);
        setItemImage(fileChange);
      }
    }
  }

  const showOpenFileDialog = (e) => {
    // state.imageRef.current.value = null;
    state.imageRef.current.click();
  };

  const deleteImage = (e) => {
    setState.setImageFile('');
    setItemImage();
    setVisibleText(true);
    state.imageRef.current.value = null;
  };

  return (
    <div className="upload-image-profile">
      <AppAvatar
        size={150}
        //icon={imageFile ? 'user' : ''}
        src={`${state.imageFile ? state.imageFile : pathImage ? pathImage : imageFileDefault}`}
        shape="square"
      />

      {/* <input
        ref={state.imageRef}
        type="file"
        style={{ display: 'none' }}
        accept=".jpg, .png, .JPG, .PNG"
        onChange={handleChangePicture}
      />
      {state.imageFile ?
        <div className='image-container after' style={{ paddingTop: 63, paddingLeft: 42, paddingRight: 42 }}>
          <Icon type="upload" theme='outlined' style={{ fontSize: '25px' }} onClick={showOpenFileDialog} />
          &nbsp;
          &nbsp;
          <Icon type="delete" theme='outlined' style={{ fontSize: '25px' }} onClick={deleteImage} />
        </div>
        :
        <div className="upload-image-profile after" style={{ paddingTop: 63, paddingLeft: 62, paddingRight: 62 }}>
          <Icon type="upload" theme='outlined' style={{ fontSize: '25px' }} onClick={showOpenFileDialog} />
        </div>
      } */}

      <input
        ref={state.imageRef}
        type="file"
        style={{ display: 'none' }}
        accept=".jpg, .png, .JPG, .PNG"
        onChange={handleChangePicture} />

      {state.imageFile ? (
        <div className="upload-image-profile after" style={{ paddingTop: 63, paddingLeft: 62, paddingRight: 62 }}>
          <Icon type="upload" theme="outlined" style={{ fontSize: '25px' }} onClick={showOpenFileDialog} />
        </div>
      ) : _.get(imageFile, 'url') ? (
        <div className="upload-image-profile after" style={{ paddingTop: 63, paddingLeft: 42, paddingRight: 42 }}>
          <Icon type="upload" theme="outlined" style={{ fontSize: '25px' }} onClick={showOpenFileDialog} />
          &nbsp; &nbsp;
          <Icon type="delete" theme="outlined" style={{ fontSize: '25px' }} onClick={deleteImage} />
        </div>
      ) : (
        <div className="upload-image-profile after" style={{ paddingTop: 63, paddingLeft: 62, paddingRight: 62 }}>
          <Icon type="upload" theme="outlined" style={{ fontSize: '25px' }} onClick={showOpenFileDialog} />
        </div>
      )}
    </div>
  )
}