import React, { useContext } from 'react';
import { Card, Icon, Button, Menu, Spin, Divider, Dropdown } from 'antd';
import { PlanningContext } from './context';
import { useIntl, FormattedMessage } from 'react-intl';
import './css/index.css';
import Information from './components/detail-information';
import Order from './components/detail-order';
import Button01 from '../../components/v2/button_01';
import Button02 from '../../components/v2/button_02';
import _ from 'lodash';
import IconExcel from '../../components/image/excel.svg';
import NumberFormat from 'react-number-format';
import NoDataBackground from '../../components/no-data-page';
import ButtonActionGroup from '../../components/action-button';
import { PageSettings } from '../../config/page-settings';
import Button_01 from '../../components/v2/button_01';

const comCode = localStorage.getItem('comCode');

export default ({ column }) => {
  const { state, setState, fnc } = useContext(PlanningContext);
  const intl = useIntl();
  const Data = _.get(state, 'dataById');
  const { isLoading, loadButton, visibleMenu } = state;
  const { setVisibleMenu, setVisibleCreateOrder } = setState;

  const iconExcel = () => <img className="monitor-icon-excel" src={IconExcel} />;

  const menu = (
    <PageSettings.Consumer>
      {({ checkPermissionAction }) => (
        <Menu>
          {_.get(state.disbleButton, 'dataEnableButtonConfirmOrder') === true ? (
            _.get(state, 'reConfirmStatus') === false ? (
              <Menu.Item key="0">
                <p
                  onClick={() => {
                    fnc.handleConfirmOrder(_.get(state.dataById, 'assetId'));
                  }}
                >
                  <FormattedMessage id="buttonConfirmOrder" defaultMessage="Confirm Order" />
                </p>
              </Menu.Item>
            ) : null
          ) : null}

          {_.get(state.disbleButton, 'dataEnableButtonConfirmOrder') === true ? (
            _.get(state, 'reConfirmStatus') === false ? (
              <Menu.Item key="7" onClick={() => setVisibleCreateOrder(true)}>
                <p>
                  <FormattedMessage id="deliveryOrderButtonCreateDeliveryOrder" defaultMessage="Create Delivery Order" />
                </p>
              </Menu.Item>
            ) : null
          ) : null}
          {_.get(state, 'reConfirmStatus') === true ? (
            <Menu.Item key="0">
              <p onClick={() => fnc.handleReConfirmOrder(_.get(state, 'dataById'))}>
                <FormattedMessage id="btnReConfirmOrder" defaultMessage="Reconfirm Order" />
              </p>
            </Menu.Item>
          ) : null}
          {_.get(state.dataById, 'sending') === 'vehicle_waiting' ? (
            <Menu.Item key="1">
              <p onClick={() => setState.setVisibleEditVehicle(true)}>
                <FormattedMessage id="buttonEditVehical" defaultMessage="Edit Vehicle" />
              </p>
            </Menu.Item>
          ) : null}
          <Menu.Item key="2" onMouseEnter={() => {}}>
            <p onClick={fnc.handleExport}>
              <FormattedMessage id="btnExport" defaultMessage="Export" />
            </p>
          </Menu.Item>
          {_.get(state.dataById, 'sending') === 'vehicle_waiting' ? <Divider type="horizontal" style={{ margin: '12px 0px' }} /> : null}
          {_.get(state.dataById, 'sending') === 'vehicle_waiting' ? (
            <Menu.Item key="3">
              <p onClick={() => setState.setVisibleAddOrRemove(true)}>
                <FormattedMessage id="buttonAddorRemove" defaultMessage="Add or Remove" />
              </p>
            </Menu.Item>
          ) : null}
          {_.get(state.disbleButton, 'dataEnableButtonRePlan') === true ? (
            <Menu.Item key="4">
              <p onClick={() => fnc.handleReCalculate(_.get(state.dataById, 'assetId'))}>
                <FormattedMessage id="buttonRePlanning" defaultMessage="Re-Planning" />
              </p>
            </Menu.Item>
          ) : null}
          {_.get(state.disbleButton, 'dataEnableButtonRePlan') === true ? (
            <Menu.Item key="5">
              <p onClick={fnc.handleClearOrderList}>
                <FormattedMessage id="RemoveAllOrder" defaultMessage="Remove All Order" />
              </p>
            </Menu.Item>
          ) : null}

          {_.get(state.dataById, 'sending') === 'vehicle_sending' ? (
            checkPermissionAction(`P34PG1C1`, `P34PG1C1A2`) ? (
              <Menu.Item key="6" onMouseEnter={() => {}}>
                <p onClick={fnc.handleCancelTrip}>
                  <FormattedMessage id="CancelTrip" defaultMessage="Cancel Trip" />
                </p>
              </Menu.Item>
            ) : null
          ) : null}
        </Menu>
      )}
    </PageSettings.Consumer>
  );

  const HeaderAndToggle = () => {
    return (
      <div className="detail-order-div-padding">
        <div>
          <div>
            <Icon
              type={state.toggle ? 'menu-unfold' : 'menu-fold'}
              style={{ color: '#e73845', cursor: 'pointer' }}
              onClick={fnc.handleToggle}
            />
            &nbsp;&nbsp;&nbsp;&nbsp;
            <span>
              {_.get(Data, 'serialNo')} {_.get(Data, 'borrower.fullname') ? ` · ${_.get(Data, 'borrower.fullname')}` : ''}
            </span>
          </div>
        </div>
        <div>
          <Dropdown trigger={['click']} overlay={menu} placement="bottomRight" disabled={loadButton}>
            <Button_01 type="primary" btnsize="wd_at" fontSize="sm" onClick={(e) => e.preventDefault()}>
              <FormattedMessage id="btnActionGroup" defaultMessage="Action" /> <Icon type="down" />
            </Button_01>
          </Dropdown>
        </div>
      </div>
    );
  };

  const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

  return (
    // <div>
    //   <Card className="card-load-and-route-detail" title={<HeaderAndToggle />} bodyStyle={{ padding: 'unset' }}>
    //     <Information Data={Data} />
    //     <Order column={column} Data={Data} />
    //   </Card>
    // </div>
    <div>
      {_.size(state.vehicleItem) !== 0 ? (
        <Spin indicator={antIcon} spinning={isLoading}>
          <Card className="card-load-and-route-detail" title={<HeaderAndToggle />} bodyStyle={{ padding: 'unset' }}>
            <Information Data={Data} />
            <Order column={column} Data={Data} />
          </Card>
        </Spin>
      ) : (
        <div style={{ padding: '24px', backgroundColor: '#fff' }}>
          <Card className="detail-card-blank-page">
            {/* <div className="detail-card-blank-page2">
                <Icon className="detail-card-blank-icon " type="car" />
                <p className="detail-card-blank-text">
                  <FormattedMessage
                    id='planningNoDataDetail'
                    defaultMessage="Please Select Vehicle" />
                </p>
              </div> */}
            <NoDataBackground
              text1={{ id: 'nodataXLoadPlanning1', text: 'Please select a Vehicle' }}
              text2={{ id: 'nodataXLoadPlanning2', text: 'Load Planning will appear here.' }}
            />
          </Card>
        </div>
      )}
    </div>
  );
};
