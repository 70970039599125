import React, { useState, useEffect, useRef } from 'react';
import { Form, Modal, Input, Row, Col, Select, InputNumber, Checkbox } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import Button01 from '../../v2/button_01';
import Button02 from '../../v2/button_02';
import LabeRequireForm from '../../label-required-form';
import { handleKeyDownNumber, handleKeyDownDecimal2Fixed } from '../../../component-function/fnc-number';
import { getConvertUoM, getConvertUoMBigger, getStockList } from '../../../controllers/po/po';
import _ from 'lodash';
import { numberFormatter, numberParser } from '../../../component-function/fnc-inputnumber-currency';
import SuffixInput from '../../v2/suffix';
import chagneVatItem from '../calvat';
import '../css/item.css';

const { Option } = Select;

const ListItemCreate = (props) => {
  const { data, visible, setVisible, form, selectItem, setSelectItem } = props;
  const { getFieldDecorator, validateFields, resetFields, getFieldValue, setFieldsValue } = form;
  const [uomList, setUomList] = useState([]);
  const intl = useIntl();
  const qty = parseFloat(getFieldValue('qty') || 0);
  const price = parseFloat(getFieldValue('price') || 0.0);
  const discount = parseFloat(getFieldValue('discount') || 0);
  const discountNumber = parseFloat(getFieldValue('discountNumber') || 0.0);
  const [checkExVat, setCheckExVat] = useState(false);
  const inputNumberPrice = useRef();

  useEffect(() => {
    const getStockList = async () => {
      // const unitData = await getConvertUoM(_.get(data, 'itemMasterId'));
      const unitData = await getConvertUoMBigger(_.get(data, 'itemMasterId'));
      setUomList(_.get(unitData, 'data.data'));
    };
    if (data && visible) {
      getStockList();
    }
  }, [data, visible]);

  useEffect(() => {
    if (qty || price) {
      setFieldsValue({
        ['amount']: numberFormatter(qty * price),
      });
    }
  }, [qty, price]);

  useEffect(() => {
    if (price && price > 0) {
      const percentNumber = (price * discount) / 100;
      const total = qty * price - percentNumber * qty;
      setFieldsValue({
        ['discountNumber']: (((price * discount) / 100) * qty).toFixed(2),
        ['total']: numberFormatter(total),
      });
    } else {
      setFieldsValue({
        ['discount']: 0.0,
        ['discountNumber']: 0.0,
        ['total']: 0.0,
      });
    }
  }, [price, getFieldValue('amount')]);

  useEffect(() => {
    if (discountNumber && discountNumber > 0) {
      const total = qty * price - discountNumber;
      setFieldsValue({
        ['discount']: ((discountNumber / (qty * price)) * 100).toFixed(2),
        ['total']: numberFormatter(total),
      });
    } else {
      setFieldsValue({
        ['discount']: 0.0,
        ['discountNumber']: 0.0,
        ['total']: numberFormatter(price * qty),
      });
    }
  }, [discountNumber]);

  useEffect(() => {
    if (discount && discount > 0) {
      const percentNumber = (price * discount) / 100;
      const total = qty * price - percentNumber * qty;
      setFieldsValue({
        ['discountNumber']: (((price * discount) / 100) * qty).toFixed(2),
        ['total']: numberFormatter(total),
      });
    } else {
      setFieldsValue({
        ['discount']: 0.0,
        ['discountNumber']: 0.0,
        ['total']: numberFormatter(price * qty),
      });
    }
  }, [discount]);

  const handleSave = async () => {
    validateFields(async (err, values) => {
      if (err) {
        return;
      }
      const response = await getStockList({ itemMasterId: [_.get(data, 'itemMasterId')] });
      const filterUoM = _.filter(uomList, (item) => {
        return item.uomId === _.get(values, 'unit');
      });
      const itemBody = {
        index: _.size(selectItem) + 1,
        itemMasterId: _.get(data, 'itemMasterId'),
        itemCode: _.get(data, 'itemCode'),
        itemName: _.get(data, 'itemName'),
        qty: parseFloat(_.get(values, 'qty')),
        count: parseFloat(_.get(values, 'qty')),
        countTemp: parseFloat(_.get(values, 'qty')),
        uomId: _.get(values, 'unit'),
        uomName: _.get(filterUoM, '[0].name'),
        pricePerUnit: parseFloat(_.get(values, 'price')),
        discountPercentage: parseFloat(_.get(values, 'discount') || 0),
        discountPrice: parseFloat(_.get(values, 'discountNumber') || 0),
        totalPrice: numberParser(_.get(values, 'total')),
        remark: _.get(values, 'remark') || '',
        instock: _.get(response, 'data.data[0].qty') || 0,
        type: 'service',
        checkVat: checkExVat,
      };
      setSelectItem([...selectItem, itemBody]);
      setVisible(false);
      resetFields();
    });
  };

  const handleCancel = () => {
    setVisible(false);
    resetFields();
  };

  const validatorQty = (rule, value, callback) => {
    if (value) {
      const FilterUom = _.filter(uomList, (item) => {
        return item.uomId === getFieldValue('unit');
      });

      const altQty = _.get(FilterUom, '[0].altQty');

      const parsValue = parseFloat(value * altQty || 0);
      if (parsValue === 0) {
        callback('QTY must more than 0');
      } else {
        callback();
      }
    } else {
      callback();
    }
  };

  const currencyFormatter = (value) => {
    return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const currencyParser = (value) => {
    return value.replace(/\$\s?|(,*)/g, '');
  };

  const chagneVat = (check) => {
    const finalcal = chagneVatItem(check, getFieldValue('price'));
    setCheckExVat(check);
    setFieldsValue({
      ['price']: finalcal,
    });
    // handleChangePrice(finalcal);
  };

  return (
    <Modal
      title={intl.formatMessage({ id: `modalListItemTitle`, defaultMessage: 'Add Item' })}
      centered={true}
      width={900}
      onCancel={handleCancel}
      visible={visible}
      bodyStyle={{ padding: 'unset', height: '315px' }}
      footer={[
        <Button02 key="back" btnsize="wd_df" onClick={handleCancel}>
          <FormattedMessage id="userDetailModalBtnCancel" defaultMessage="Cancel" />
        </Button02>,
        <Button01 key="submit" type="primary" btnsize="wd_df" onClick={handleSave} style={{ margin: 'unset' }}>
          <FormattedMessage id="userDetailModalBtnSave" defaultMessage="Save" />
        </Button01>,
      ]}
    >
      <div style={{ padding: '16px' }}>
        <div style={{ marginBottom: '28px' }}>
          <div style={{ marginBottom: '8px', color: '#1D3557', fontSize: '12px' }}>
            <LabeRequireForm text={intl.formatMessage({ id: 'modalListItemFormTitleItem', defaultMessage: 'Item' })} req={false} />
          </div>
          <div style={{ color: '#050505', fontSize: '13px' }}>
            {_.get(data, 'itemCode')} {_.get(data, 'itemName')}
          </div>
        </div>
        <Form form={form} layout="vertical">
          <Row gutter={[16]}>
            <Col span={8}>
              <Form.Item
                label={<LabeRequireForm text={intl.formatMessage({ id: 'modalListItemFormTitleQty', defaultMessage: 'Qty' })} req={true} />}
              >
                {getFieldDecorator('qty', {
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'modalListItemFormValidateQty', defaultMessage: 'Please Enter Qty' }),
                    },
                    {
                      validator: validatorQty,
                    },
                  ],
                })(
                  <InputNumber
                    className="disabled-handle-count"
                    placeholder={intl.formatMessage({ id: 'modalListItemFormPlaceholderQty', defaultMessage: 'Enter Qty' })}
                    onKeyDown={handleKeyDownNumber}
                    formatter={currencyFormatter}
                    parser={currencyParser}
                    controls={false}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={
                  <LabeRequireForm text={intl.formatMessage({ id: 'modalListItemFormTitleUnit', defaultMessage: 'Unit' })} req={true} />
                }
              >
                {getFieldDecorator('unit', {
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'modalListItemFormValidateUnit', defaultMessage: 'Please Select Unit' }),
                    },
                  ],
                })(
                  <Select
                    placeholder={intl.formatMessage({ id: 'modalListItemFormPlaceholderUnit', defaultMessage: 'Select Unit' })}
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    showSearch
                  >
                    {_.map(uomList, (item) => (
                      <Option value={item.uomId}>{item.name}</Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                className="custom-label"
                label={
                  <Row justify="space-between" align="middle" style={{ width: '100%' }}>
                    <Col span={10}>
                      <span>
                        <LabeRequireForm
                          text={intl.formatMessage({ id: 'modalListItemFormTitlePrice', defaultMessage: 'Price/Unit' })}
                          req={true}
                        />
                      </span>
                    </Col>
                    {/* <Col span={14} style={{ textAlign: 'right' }}>
                      <Checkbox onChange={(e) => chagneVat(e.target.checked)}>
                        <FormattedMessage id="PoExclVAT7" defaultMessage="Excl. VAT 7%" />
                      </Checkbox>
                    </Col> */}
                  </Row>
                }
                labelCol={{ style: { width: '100%', paddingBottom: '2px' } }}
              >
                {getFieldDecorator('price', {
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'modalListItemFormValidatePrice', defaultMessage: 'Please Enter Price/Unit' }),
                    },
                  ],
                })(
                  <InputNumber
                    className="disabled-handle-count"
                    placeholder={intl.formatMessage({ id: 'modalListItemFormPlaceholderPrice', defaultMessage: 'Enter Price/Unit' })}
                    suffix="THB"
                    onKeyDown={handleKeyDownDecimal2Fixed}
                    formatter={currencyFormatter}
                    parser={currencyParser}
                    controls={false}
                    ref={inputNumberPrice}
                  />
                )}
                <SuffixInput text="THB" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16]}>
            <Col span={8}>
              <Form.Item
                label={
                  <LabeRequireForm
                    text={intl.formatMessage({ id: 'modalListItemFormTitleAmount', defaultMessage: 'Amount' })}
                    req={false}
                  />
                }
              >
                {getFieldDecorator('amount', {
                  initialValue: '0.00',
                })(<Input maxLength={100} disabled={true} />)}
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item
                label={
                  <LabeRequireForm
                    text={intl.formatMessage({ id: 'modalListItemFormTitleDiscount', defaultMessage: 'Discount' })}
                    req={false}
                  />
                }
              >
                {getFieldDecorator('discount', {
                  initialValue: '0.00',
                })(
                  <InputNumber
                    className="disabled-handle-count"
                    onKeyDown={handleKeyDownDecimal2Fixed}
                    formatter={currencyFormatter}
                    parser={currencyParser}
                    controls={false}
                  />
                )}
                <SuffixInput text="%" />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item label={<LabeRequireForm text="" req={false} />}>
                {getFieldDecorator('discountNumber', {
                  initialValue: '0.00',
                })(
                  <InputNumber
                    className="disabled-handle-count"
                    suffix="THB"
                    onKeyDown={handleKeyDownDecimal2Fixed}
                    formatter={currencyFormatter}
                    parser={currencyParser}
                    controls={false}
                  />
                )}
                <SuffixInput text="THB" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={
                  <LabeRequireForm text={intl.formatMessage({ id: 'modalListItemFormTitleTotal', defaultMessage: 'Total' })} req={false} />
                }
              >
                {getFieldDecorator('total', {
                  initialValue: '0.00',
                })(
                  <Input
                    placeholder={intl.formatMessage({ id: 'modalListItemFormPlaceholderTotal', defaultMessage: 'Enter Total' })}
                    maxLength={100}
                    onKeyDown={handleKeyDownDecimal2Fixed}
                    suffix="THB"
                    readOnly={true}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16]}>
            <Col span={24}>
              <Form.Item
                label={
                  <LabeRequireForm
                    text={intl.formatMessage({ id: 'modalListItemFormTitleRemark', defaultMessage: 'Remark' })}
                    req={false}
                  />
                }
              >
                {getFieldDecorator('remark', {})(<Input maxLength={100} />)}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};

const ModalCreateListServiceItem = Form.create({
  name: 'assignTeam-form',
  mapPropsToFields() {},
})(ListItemCreate);

export default ModalCreateListServiceItem;
