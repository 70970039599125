import React, { useState, useEffect, useRef } from 'react';
import { Checkbox, Col, Row, Collapse, Tree, Icon } from 'antd';
import './css/filter.css';
import _ from 'lodash';
import { useIntl } from 'react-intl';

const CheckboxGroup = Checkbox.Group;
const { TreeNode } = Tree;

export default React.memo((props) => {
  const {
    Data,
    checkedKeysTeam,
    expandedKeys,
    selectedKeys,
    indeterminateTeam,
    setIndeterminateTeam,
    setPaginationPage,
    LangCode,
    onCheckTeam,
    onExpand,
    onSelect,
  } = props;

  const intl = useIntl();
  const teamData = _.get(Data, 'team');
  const requestData = _.get(Data, 'reqTypeGroup');
  const [visiblePanelTeam, setVisiblePanelTeam] = useState(false);
  const [checkAllTeam, setCheckAllTeam] = useState(true);

  let getAllKeys = (tree) => {
    let result = [];

    _.forEach(tree, (x) => {
      let childKeys = [];

      if (x.reqTypeList) {
        childKeys = getAllKeys(x.reqTypeList);
      }
      result.push(...[x.reqTypeCode, ...childKeys]);
    });

    return result;
  };
  const allKeysRequest = getAllKeys(requestData);

  let getAllKeyTeams = (tree) => {
    let result = [];

    _.forEach(tree, (x) => {
      let childKeys = [];
      result.push(...[x.orgId, ...childKeys]);
    });

    return result;
  };
  const allKeysTeam = getAllKeyTeams(teamData);

  const handleCheckAllTeam = (e) => {
    setIndeterminateTeam(false);
    setCheckAllTeam(e.target.checked);
    setPaginationPage(1);
    if (e.target.checked === true) {
      onCheckTeam(allKeysTeam);
    } else {
      onCheckTeam([]);
    }
  };

  const headerTitleTeam = (res) => {
    return (
      <div>
        <Row>
          <Col span={14}>
            <Checkbox onChange={handleCheckAllTeam} indeterminate={indeterminateTeam} checked={checkAllTeam}>
              <span>{_.get(res, 'teamChkboxName')}{intl.formatMessage({
              id: `userMgntUsersDetailLblTeam`,
              defaultMessage: 'Team',
            })}</span>
            </Checkbox>
          </Col>
          <Col span={8} style={{textAlign: 'right'}}>
            <span className="select-text-panel-style">{`${intl.formatMessage({
              id: `filterTaskSelected`,
              defaultMessage: 'Selected',
            })} ${_.size(checkedKeysTeam)}`}</span>
          </Col>
          {/* <Col span={1}></Col> */}
          <Col span={2} onClick={() => setVisiblePanelTeam((current) => !current)} style={{ cursor: 'pointer',textAlign: 'right' }}>
            {visiblePanelTeam ? <Icon type="up" /> : <Icon type="down" />}
          </Col>
        </Row>
      </div>
    );
  };

  const renderTreeNodesTeam = (data) =>
    data.map((item) => {
      const title = <span>{item.teamName}</span>;

      if (item.children) {
        return (
          <TreeNode title={item.name} key={item.orgId}>
            {renderTreeNodesTeam(item.children)}
          </TreeNode>
        );
      }
      return <TreeNode key={item.orgId} title={item.teamName} />;
    });

  return (
    <div>
      <div className="filter-text-summary" style={{ borderTop: '1px solid #e4e6eb', borderBottom: '1px solid #e4e6eb' }}>
        <div>
          {headerTitleTeam()}
          {visiblePanelTeam ? (
            <div>
              <Tree
                checkable
                onExpand={onExpand}
                expandedKeys={expandedKeys}
                autoExpandParent={false}
                onCheck={onCheckTeam}
                checkedKeys={checkedKeysTeam}
              >
                {renderTreeNodesTeam(_.get(Data, 'team'))}
              </Tree>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
});
