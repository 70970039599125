import React, { useEffect, useRef } from 'react';
import { Form, Select, Icon } from 'antd';
import _ from 'lodash';
import style from './css/detail-invoice.css';
import { useIntl, FormattedMessage } from 'react-intl';

const { Option } = Select;

export default Form.create({
  name: 'customer-form',

  onValuesChange: (props, changedValues, allValues) => {
    const { onChange } = props;
  },
})((props) => {
  const { getFieldDecorator, getFieldValue, resetFields } = props.form;
  const { datasource, customer, onScroll, handleLeaveSelect, loading, setCustomerId, setDataSelection } = props;
  const addressRef = useRef();
  const taxRef = useRef();
  const phoneRef = useRef();
  const intl = useIntl();

  const handleChangeCustomer = (value) => {
    const resourceSelect = customer && customer.customers.filter((item) => value.includes(item.customerId));
    setCustomerId(resourceSelect ? resourceSelect[0].customerId : undefined);
    const dataSelect = resourceSelect && resourceSelect !== undefined ? resourceSelect[0] : undefined;
    addressRef.current.innerHTML = dataSelect && dataSelect.address;
    taxRef.current.innerHTML = dataSelect && dataSelect.taxNo;
    phoneRef.current.innerHTML = dataSelect && dataSelect.phone;
    setDataSelection([]);
  };

  const customerList =
    customer && customer !== undefined
      ? customer.customers.map((item) => {
          return (
            <Option className="select-option" value={item.customerId}>
              {item.customerName}
            </Option>
          );
        })
      : null;

  return (
    <Form>
      <Form.Item style={{ width: '180px' }}>
        <p>
          <FormattedMessage id="modalInvoiceDetailCustomer" defaultMessage="Customer" />
        </p>
        {getFieldDecorator('customer', {
          rules: [
            {
              required: true,
              message: intl.formatMessage({ id: 'modalInvoiceDetailCustomerValidate', defaultMessage: 'Please select Customer' }),
            },
          ],
        })(
          <Select
            className="customer-select"
            placeholder={intl.formatMessage({ id: 'modalInvoiceDetailCustomerPlaceholder', defaultMessage: 'Select Customer' })}
            size="small"
            showSearch
            filterOption={(input, option) =>
              option.props.children
                .toString()
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            // onPopupScroll={onScroll}
            // onBlur={handleLeaveSelect}
            onChange={handleChangeCustomer}
          >
            {!loading ? customerList : [...customerList, <Option key="loading">Loading...</Option>]}
          </Select>
        )}
      </Form.Item>
      {customer && customer !== undefined ? (
        <div>
          <div style={{ width: '272px', marginTop: '10px', marginBottom: '8px' }}>
            <span>
              <span ref={addressRef}>
                <FormattedMessage id="modalInvoiceDetailDateAddress" defaultMessage="Address" /> :
              </span>
              <Icon
                type="environment"
                style={{
                  fontSize: '12px',
                  color: '#1D3557',
                  paddingLeft: '6px',
                  paddingRight: '0px',
                  cursor: 'pointer',
                }}
              />
            </span>
          </div>
          <div style={{ marginBottom: '8px' }}>
            <span>
              <FormattedMessage id="modalInvoiceDetailTaxNo" defaultMessage="Tax No" /> : <span ref={taxRef}></span>
            </span>
          </div>
          <div style={{ marginBottom: '8px' }}>
            <span>
              <FormattedMessage id="modalInvoiceDetailDatePhone" defaultMessage="Phone" /> : <span ref={phoneRef}></span>
            </span>
          </div>
        </div>
      ) : (
        <div>
          <div style={{ width: '272px', marginTop: '10px', marginBottom: '8px' }}>
            <span>
              <FormattedMessage id="modalInvoiceDetailDateAddress" defaultMessage="Address" /> :
              <Icon
                type="environment"
                style={{
                  fontSize: '12px',
                  color: '#1D3557',
                  paddingLeft: '6px',
                  paddingRight: '0px',
                  cursor: 'pointer',
                }}
              />
            </span>
          </div>
          <div style={{ marginBottom: '8px' }}>
            <p>
              <FormattedMessage id="modalInvoiceDetailTaxNo" defaultMessage="Tax No" /> :{' '}
            </p>
          </div>
          <div style={{ marginBottom: '8px' }}>
            <p>
              <FormattedMessage id="modalInvoiceDetailDatePhone" defaultMessage="Phone" /> :{' '}
            </p>
          </div>
        </div>
      )}
    </Form>
  );
});
