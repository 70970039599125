import httpClient from '../../components/axiosClient';
import * as qs from 'qs'

const getMenu = async (where = {}) => {

  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.get(`/v3/authorization/manager/company/${comCode}/permission/menu`);
    // const response = await httpClient.get(`/v3/authorization/manager/company/${comCode}/permission/menu?` + qs.stringify(where));
    if (response.status === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }

 
}

export default getMenu;