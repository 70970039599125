import httpClient from '../../components/axiosClient';

const getTeamOrg = async () => {
  const comId = localStorage.getItem('comId');
  try {
    const response = await httpClient.get(`/v1/resource/manager/company/${comId}/organizations`);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return error.response;
  }
}

export { getTeamOrg };