import httpClient from '../../components/axiosClient';


const comCode = localStorage.getItem('comCode');

const getBillingList = async (payload) => {
  try {
    const response = await httpClient.post(`/v3/invoice/manager/company/${comCode}/billing/page`, payload);
    if (response.status === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) { }
};

const getBillingDetail = async (id) => {
  try {
    const response = await httpClient.get(`/v3/invoice/manager/company/${comCode}/billing/detil/${id}`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const createBilling = async (payload) => {
  try {
    const response = await httpClient.post(`/v3/invoice/manager/company/${comCode}/billing`, payload);
    if (response.status === 200) {
      return response.data;
    } else {
      return response.data;
    }
  } catch (error) { }
};

const uploadBLAttachment = async (payload) => {
  try {
    const response = await httpClient.post(`/v3/invoice/manager/company/${comCode}/billing/document`, payload);
    if (response.status === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) { }
};

const getBillingDetailPaid = async (id) => {
  try {
    const response = await httpClient.get(`/v3/invoice/manager/company/${comCode}/billing/paiddetil/${id}`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const createBillingPaid = async (payload) => {
  try {
    const response = await httpClient.post(`/v3/invoice/manager/company/${comCode}/billingpaid`, payload);
    if (response.status === 200) {
      return response.data;
    } else {
      return response.data;
    }
  } catch (error) {
    return error.response.data;
  }
};

const uploadBLAttachmentPaid = async (payload) => {
  try {
    const response = await httpClient.post(`/v3/invoice/manager/company/${comCode}/billing/paiddocument`, payload);
    if (response.status === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) { }
};

const exportBL = async (payload) => {
  try {
    const response = await httpClient.post(`/v3/invoice/manager/company/${comCode}/billing/export`, payload);
    if (response.status === 200) {
      return response.data;
    } else {
      return response.data;
    }
  } catch (error) { }
};

const getSummaryBL = async (payload) => {
  try {
    const response = await httpClient.post(`/v3/invoice/manager/company/${comCode}/billing/pagesummary`, payload);
    if (response.status === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {
    return error.response.data;
  }
};

const updateStatusBL = async (payload) => {
  try {
    const response = await httpClient.put(`/v3/invoice/manager/company/${comCode}/billingstatus`, payload);
    if (response.status === 200) {
      return response.data;
    } else {
      return response.data;
    }
  } catch (error) {
    return error.response.data;
  }
};

const BLPDFDownloader = async (no, payload) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v1/po/manager/company/${comCode}/billing-note-pdf/${no}`, payload, {
      responseType: 'blob'
    })
    if (response.status === 200) {
      return response
    } else {
      return []
    }
  } catch (error) {
  }
}

const BLTaskPDFDownloader = async (no, payload) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v1/po/manager/company/${comCode}/billing-note-task-pdf/${no}`, payload, {
      responseType: 'blob'
    })
    if (response.status === 200) {
      return response
    } else {
      return []
    }
  } catch (error) {
  }
}

export {
  getBillingList,
  createBilling,
  getBillingDetail,
  uploadBLAttachment,
  getBillingDetailPaid,
  createBillingPaid,
  uploadBLAttachmentPaid,
  exportBL,
  getSummaryBL,
  updateStatusBL,
  BLPDFDownloader,
  BLTaskPDFDownloader,
};
