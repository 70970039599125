import React from 'react';
import { Modal, Form, Input, Select } from 'antd';
import { successNotification, errorNotification } from '../v2/notification';
import { updateStatus } from '../../controllers/quotation/quotation-api';
import { useIntl, FormattedMessage } from 'react-intl';
import Button01 from '../v2/button_01';
import Button02 from '../v2/button_02';
import _ from 'lodash';
import styled from 'styled-components';
import './css/index.css';

const { TextArea } = Input;
const { Option } = Select;

const RejectModal = ({ form, visible, setVisible, data, reason, setTriggerApi, setVisibleView }) => {
  // console.log('reason::: reject',reason);
  const intl = useIntl();
  const { getFieldDecorator, validateFields, resetFields } = form;

  const LabelRequire = styled.label`
    color: #ff1010;
    margin-bottom: unset !important;
  `;

  const LabeRequire = (props) => {
    const { text, req } = props;
    return (
      <span className="font-p-qutation">
        {text}&nbsp;
        {req ? <LabelRequire>*</LabelRequire> : ''}
      </span>
    );
  };

  const handleSave = () => {
    validateFields(async (errors, values) => {
      if (errors) {
        return;
      }
      const id = _.get(data, 'quotationId');
      const comment = values.comment ? values.comment : undefined;
      const reason = values.reason ? values.reason : undefined;

      const response = await updateStatus(id, 'reject', comment, reason);
      if (response.status === 200) {
        successNotification(response.message);
        setVisible(false);
        resetFields();
        setTriggerApi(current => !current)
        setVisibleView(false)
      } else {
        errorNotification(response.message);
        setVisible(false);
        resetFields();
        setTriggerApi(current => !current)
        setVisibleView(false)
      }
    });
  }

  const handleCancel = () => {
    setVisible(false);
    resetFields();
  }

  return (
    <Modal
      title={intl.formatMessage({ id: 'modalRejectQuotationTxtRejectQuotations', defaultMessage: 'Reject a Quotations' })}
      centered
      visible={visible}
      onOk={handleSave}
      onCancel={handleCancel}
      footer={[
        <Button02 style={{margin : '0px 0px 0px 10px'}}
          key="back"
          btnsize="wd_df"
          onClick={() => handleCancel()}
        >
          <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
        </Button02>,
        <Button01
          key="submit"
          type="primary"
          btnsize="wd_df"
          onClick={() => handleSave()}
        >
          <FormattedMessage id="btnSave" defaultMessage="Save" />
        </Button01>,
      ]}
    >
      <Form colon={false} layout="vertical">
        <Form.Item
          label={<LabeRequire text={intl.formatMessage({ id: 'modalRejectQuotationTxtReason', defaultMessage: 'Reason' })} req={true} />}
        >
          {getFieldDecorator('reason', {
            rules: [{
              required: true,
              message: intl.formatMessage({ id: 'modalRejectQuotationValidateReason', defaultMessage: 'Please select reason' })
            }],
          })(
            <Select
              style={{ width: '100%' }}
              placeholder={intl.formatMessage({ id: 'modalRejectQuotationPlaceholerReason', defaultMessage: 'Select Reason' })}
            >
              {reason && reason.map((item, index) => (
                <Option key={index} value={item.reasonId}>{item.titleCode}</Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item
          label={<LabeRequire text={intl.formatMessage({ id: 'modalRejectQuotationTxtComment', defaultMessage: 'Comment' })} req={false} />}
        >
          {getFieldDecorator('comment', {
            rules: [{
              required: false,
              message: intl.formatMessage({ id: 'modalRejectQuotationValidateComment', defaultMessage: 'Please input your comment' })
            }],
          })(
            <TextArea
              placeholder={intl.formatMessage({ id: 'modalRejectQuotationPlaceholerComment', defaultMessage: 'Enter Comment' })}
              autoSize={{ minRows: 4, maxRows: 4 }}
            />
          )}
        </Form.Item>
      </Form>
    </Modal>
  )
}


export default Form.create({ name: "modal_form" })(RejectModal);

