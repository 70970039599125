import React, { createContext, useContext, useState, useEffect } from 'react';
import httpClient from '../../components/axiosClient';
import { fncGetUsers } from '../../controllers/user/users';

const PermissionContext = createContext();

const PermissionProvider = (props) => {
  const { children } = props;

  const comId = localStorage.getItem('comId');
  const memComId = localStorage.getItem('memComId');
  const [roles, setRoles] = useState([]);
  const [rolesSelect, setRolesSelect] = useState('');
  const [actions, setActions] = useState([]);
  const [rolesArr, setRolesArr] = useState([]);
  const [usersArr, setUsersArr] = useState([]);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {

    const getPage = async () => {
      try {
        const response = await httpClient.get(`/company/${comId}/permission/page`);
        if(response.status === 200) {
          setRoles(response.data);
        }
        else {

        }
      }
      catch {
        console.error();
      }
    }

    const getRole = async () => {
      try {
        const response = await httpClient.get(`/company/${comId}/role`);
        if(response.status === 200) {
          setRolesArr(response.data.data);
        }
        else {

        }
      }
      catch {
        console.error();
      }
      
    }

    const getUser = async () => {
      try {
        // const response = await httpClient.get(`/v2/users/${comId}`);
        const response = await fncGetUsers(memComId, comId,true);
        // if(response.status === 200) {
          // setUsersArr(response.data);
          setUsersArr(response);
        // }
      }
      catch {
        console.error();
      }
    }
    
    getPage();
    getRole();
    getUser();
  }, []);

  useEffect(() => {

    const getAction = async () => {
      try {
        const getActionList = await httpClient.get(`/company/${comId}/permission/action/${rolesSelect}`);
        if(getActionList.status === 200) {
          setActions(getActionList.data);
        }
      }
      catch {
        console.error();
      }
    }

    if(rolesSelect != '') {
      getAction();
    }
  }, [rolesSelect, refresh]);

  return (
    <PermissionContext.Provider
      value={{
        state: {
          comId,
          memComId,
          roles,
          rolesSelect,
          actions,
          rolesArr,
          usersArr,
          refresh
        },
        fnc: {
          setRolesSelect,
          setRefresh
        }
      }}
    >
      {children}
    </PermissionContext.Provider>
  );
}

const usePermissionContext = () => useContext(PermissionContext);

export {
  PermissionProvider,
  usePermissionContext
}