import React, { useEffect, useState, useContext } from 'react';
import { Form, Modal, Input, Row, DatePicker, Switch, ConfigProvider } from 'antd';
import Button01 from '../../../components/v2/button_01';
import Button02 from '../../../components/v2/button_02';
import styled from 'styled-components';
import { ShiftSettings } from '../config/shift-type-settings';
import httpClient from '../../../components/axiosClient';
import cssStyle from './css/modal-form.css';
import { PageSettings } from '../../../config/page-settings';
import { useIntl, FormattedMessage } from 'react-intl';
import moment from 'moment';
import enUS from 'antd/es/locale/en_US';
import thTH from 'antd/es/locale/th_TH';
import 'moment/locale/th';
import { useAppContext } from '../../../includes/indexProvider';
import _ from 'lodash';

const languages = [
  {
    id: 'th',
    languageName: 'Thailand',
  },
  {
    id: 'en',
    languageName: 'English',
  },
  {
    id: 'ch',
    languageName: 'China',
  },
];
const LabelRequire = styled.label`
  color: #ff1010;
`;
const LabeRequire = (props) => {
  const { text, req } = props;
  return (
    <span>
      {text}&nbsp;
      {req ? <LabelRequire>*</LabelRequire> : ''}
    </span>
  );
};

const areEqual = (prevProps, nextProps) => true;

const { confirm } = Modal;

export default React.memo(
  Form.create({
    name: 'modal_holoday_form',
    mapPropsToFields(props) {
      const { record, languageLocal } = props;

      let holidayDate = undefined;
      if (record) {
        holidayDate = moment(record.date, 'YYYY-MM-DD');
      }

      let nameRows = {};
      for (let lang of languageLocal) {
        nameRows[lang] = Form.createFormField({
          value: record ? record.eventName[lang] : '',
        });
      }

      return {
        comCalId: Form.createFormField({
          value: record ? record.comCalId : '',
        }),
        eventName: Form.createFormField({
          value: nameRows ? nameRows : '',
        }),
        date: Form.createFormField({
          value: holidayDate ? holidayDate : '',
        }),
        annullyRepeat: Form.createFormField({
          value: record ? record.annullyRepeatStatus : '',
        }),
        ...nameRows,
      };
    },
  })((props) => {
    const app = useAppContext();
    const langValue = app.state.langValue;
    const [locale, setLocale] = useState(enUS);

    const langValueState = _.get(app, 'state.langValue');
    // moment.locale(langValueState);

    const changeLocale = () => {
      // let codeLang = langValue ? langValue : 'EN';
      let changeLang = langValueState === 'EN' ? enUS : thTH;
      setLocale(changeLang);
      // if (!changeLang) {
      //   moment.locale('en');
      // } else {
      //   moment.locale('th-th');
      // }
      if (changeLang == 'EN') {
        moment.locale('en');
      } else if (changeLang == 'TH') {
        moment.locale('th-th');
      }
    };

    useEffect(() => {
      changeLocale();
    }, []);

    const { languageLocal, langLocal } = props;

    const { setRecord, record } = props;

    const { state, fnc } = useContext(ShiftSettings);

    const { getFieldDecorator, validateFields, resetFields, getFieldsValue, getFieldValue } = props.form;

    const comId = localStorage.getItem('comId');

    const intl = useIntl();

    const onCreate = async (e) => {
      //e.preventDefault();
      validateFields(async (err, values) => {
        if (err) {
          return false;
        }
        confirm({
          className: 'holiday-modal-confirm',
          icon: 'none',
          title: intl.formatMessage({ id: 'settingHolidaysModalConfirm', defaultMessage: 'Are you sure to add public holiday?' }),
          okText: intl.formatMessage({ id: 'settingHolidaysModalBtnConfirm', defaultMessage: 'Confirm' }),
          okButtonProps: {
            style: {
              width: '96px',
            },
            type: 'primary',
          },
          cancelButtonProps: {
            style: {
              width: '96px',
            },
            type: 'danger',
          },
          cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
          //width: '330px',

          centered: true,
          async onOk() {
            const dateForm = moment(values.date._d, 'YYYY-MM-DD').format('YYYY-MM-DD');

            let eventName = {};
            for (let lang of langLocal) {
              eventName[lang] = values[lang];
            }

            const comCalId = getFieldValue('comCalId');

            if (comCalId) {
              if (values.annullyRepeat === true) {
                const body = {
                  eventName: eventName,
                  date:
                    dateForm !== record.dateDefault || values.annullyRepeat !== record.annullyRepeatStatus ? dateForm : record.dateDefault,
                  annuallyRepeat: values.annullyRepeat,
                };

                try {
                  const results = await httpClient.put(`/resource/manager/company/${comId}/public/holiday/${comCalId}`, body);
                  fnc.successNotification(results.data.message);
                  fnc.refreshDataServices();
                  fnc.setVisible(false);
                  resetFields();
                } catch {
                  fnc.failedNotification('fail!');
                }
              } else if (values.annullyRepeat === false) {
                const body = {
                  eventName: eventName,
                  date:
                    dateForm !== record.dateDefault && values.annullyRepeat === record.annullyRepeatStatus ? dateForm : record.dateDefault,
                  annuallyRepeat: values.annullyRepeat,
                };
                try {
                  const results = await httpClient.put(`/resource/manager/company/${comId}/public/holiday/${comCalId}`, body);
                  fnc.successNotification(results.data.message);
                  fnc.refreshDataServices();
                  fnc.setVisible(false);
                  resetFields();
                } catch {
                  fnc.failedNotification('fail!');
                }
              }
            } else {
              //Add
              const body = {
                comId: comId,
                eventName: eventName,
                date: dateForm,
                annuallyRepeat: values.annullyRepeat,
              };

              try {
                const results = await httpClient.post(`/resource/manager/company/${comId}/public/holiday`, body);
                fnc.successNotification(results.data.message);
                fnc.refreshDataServices();
                fnc.setVisible(false);
                resetFields();
              } catch {
                fnc.failedNotification('fail!');
              }
            }
          },
          onCancel() {},
        });
      });
    };

    const handleCancel = () => {
      if (record) {
        setRecord();
        fnc.setVisible(false);
      } else {
        resetFields();
        fnc.setVisible(false);
      }
    };

    const dateChange = (date, dateString) => {};

    const repeatChange = (checked) => {};

    return (
      <ShiftSettings.Consumer>
        {({ state, fnc }) => (
          <Modal
            centered
            title={<FormattedMessage id="settingHolidaysTxtPublicHoliday" defaultMessage="Public Holiday" />}
            width={650}
            //bodyStyle={{ paddingTop: '14px', paddingBottom: '14px' }}
            visible={state.visible}
            langLocal={langLocal}
            onCancel={handleCancel}
            onOk={onCreate}
            footer={[
              <Button02 style={{margin : '0px 0px 0px 10px'}} key="back" btnsize="wd_df" onClick={handleCancel} margin=" unset">
                <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
              </Button02>,
              <Button01 key="submit" type="primary" btnsize="wd_df" onClick={onCreate}>
                <FormattedMessage id="btnSave" defaultMessage="Save" />
              </Button01>,
            ]}
          >
            <Form>
              <Row>
                {langLocal &&
                  langLocal.map((v, i) => (
                    <Form.Item
                      className="holidays-form-item"
                      label={
                        i === 0 ? (
                          <LabeRequire
                            text={<FormattedMessage id="settingHolidaysModalLblHolidayName" defaultMessage="Holiday name" />}
                            req={true}
                          />
                        ) : null
                      }
                    >
                      {getFieldDecorator(v, {
                        rules: [
                          {
                            required: true,
                            message: intl.formatMessage({
                              id: 'settingHolidaysValidateName',
                              defaultMessage: 'Please enter holiday name.',
                            }),
                          },
                        ],
                      })(
                        <Input
                          className="addOnBefore margin-item-holidayname"
                          key={i}
                          addonBefore={state.localLang[v]}
                          placeholder={intl.formatMessage({
                            id: 'settingHolidaysHintHolidayName',
                            defaultMessage: 'Please enter holiday name',
                          })}
                          autoComplete="off"
                        ></Input>
                      )}
                    </Form.Item>
                  ))}
              </Row>
              <Row>
                <ConfigProvider locale={locale}>
                  <Form.Item
                    className="holidays-form-item"
                    label={<LabeRequire text={<FormattedMessage id="settingHolidaysModalLblDate" defaultMessage="Date" />} req={true} />}
                  >
                    {getFieldDecorator('date', {
                      initalValue: moment(),
                      rules: [
                        {
                          required: true,
                          message: intl.formatMessage({ id: 'settingHolidaysValidateDate', defaultMessage: 'Please select holiday date.' }),
                        },
                      ],
                    })(
                      <DatePicker
                        className="date-width"
                        onChange={dateChange}
                        placeholder={intl.formatMessage({ id: 'settingHolidaysHintDate', defaultMessage: 'Please select holiday date' })}
                      />
                    )}
                  </Form.Item>
                </ConfigProvider>
              </Row>
              <Row className="style-switch">
                <Form.Item
                  className="style-switch holidays-form-item"
                  label={
                    <LabeRequire text={<FormattedMessage id="settingHolidaysModalRepeat" defaultMessage="Annual repeat" />} req={false} />
                  }
                >
                  {getFieldDecorator('annullyRepeat', { valuePropName: 'checked' })(<Switch value onChange={repeatChange} />)}
                </Form.Item>
              </Row>
            </Form>
          </Modal>
        )}
      </ShiftSettings.Consumer>
    );
  }),
  (prev, next) => {
    return prev.langLocal === next.langLocal && prev.record === next.record;
  }
);
