import httpClient from '../../components/axiosClient';

const updateDetailCompany = async (data) => {
  const comCode = localStorage.getItem('comCode');
  if (data.dataForm && data.addressCompany) {
    try {
      const body = {
        comCode: data.dataForm.companyCode,
        name: data.dataForm.companyName,
        taxNo: data.dataForm.taxNo,
        phone: data.dataForm.phone,
        address: data.addressCompany.address,
        locationName: data.addressCompany.locationName,
        lat: data.addressCompany.lat,
        lng: data.addressCompany.lng,
        fullAddress: data.addressCompany.mapLocation
      }
      const response = await httpClient.put(`/v3/resource/manager/updatedetailcompany/${comCode}`, body);
      if (response.status === 200) {
        return response;
      } else {
        return response;
      }
    } catch (error) {
      return error.response;
    }


  }
}

export default updateDetailCompany;