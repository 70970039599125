import React, { useEffect, useState } from 'react';
import './css/index.css';
import { Modal, Select, Form } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import Button01 from '../v2/button_01';
import Button02 from '../v2/button_02';
import { GetRole, GetRequestType, SaveApprover } from '../../controllers/organization/approver';
import _ from 'lodash';
import { fncGetUsers } from '../../controllers/user/users';
import { successNotification, errorNotification } from '../v2/notification';

const { Option } = Select;

const ModalResourceApprover = (props) => {
  const { visible, setVisible, form, orgId, setTrigger, data, setData, setTriggerApprover } = props;
  const intl = useIntl();
  const { getFieldDecorator, validateFields, resetFields, getFieldValue } = form;
  const [roleData, setRoleData] = useState([]);
  const [requestTypeData, setRequestTypeData] = useState([]);
  const [userData, setUserData] = useState([]);

  const LabeRequire = (props) => {
    const { text, req } = props;
    return (
      <span>
        {text}&nbsp;
        {req ? <span style={{ fontSize: '13px', color: '#ff0000' }}>*</span> : ''}
      </span>
    );
  };

  useEffect(() => {
    const getData = async () => {
      const comId = localStorage.getItem('comId');
      const memComId = localStorage.getItem('memComId');
      const response = await GetRole(comId);
      if (_.size(response) !== 0) {
        setRoleData(_.get(response, 'data'));
      }
      const req = await GetRequestType();
      if (_.size(response) !== 0) {
        // console.log('GetRequestType',req)
        setRequestTypeData(req.data);
      }
      const getuser = await fncGetUsers(memComId, comId, true);
      if (_.size(response) !== 0) {
        setUserData(getuser);
      }
    };
    getData();
  }, []);

  const handleCreate = () => {
    validateFields(async (err, values) => {
      if (err) {
        return;
      }
      const requestType = _.get(values, 'requestType');
      const role = _.get(values, 'role');
      const user = _.get(values, 'user');
      const userTostring = user && user.map((item) => item.toString());
      const memComId = localStorage.getItem('memComId');
      const id = _.get(data, 'id') ? _.get(data, 'id') : undefined;
      const response = await SaveApprover(memComId, userTostring, role, orgId, requestType, id);
      // console.log('handleCreate ::', response)
      if (_.size(response) !== 0) {
        if (response.status.code === 200) {
          successNotification(response.status.message);
        } else {
          errorNotification(response.status.message);
        }
      } else {
        errorNotification('error');
      }
      // console.log('response SaveApprover::', response)
      setVisible(false);
      setTrigger((current) => !current);
      setTriggerApprover((current) => !current);
      resetFields();
      setData();
    });
  };

  const handleCancel = () => {
    setVisible(false);
    resetFields();
    setData();
  };

  return (
    <div>
      <Modal
        title={`${
          data
            ? intl.formatMessage({ id: 'modalApproverTitleEdit', defaultMessage: 'Edit New Approver' })
            : intl.formatMessage({ id: 'modalApproverTitleAdd', defaultMessage: 'Add New Approver' })
        }`}
        centered={true}
        bodyStyle={{ padding: 'unset' }}
        visible={visible}
        onCancel={handleCancel}
        onOk={handleCreate}
        footer={[
          <Button02 style={{margin : '0px 0px 0px 10px'}} key="back" fontsize="sm" btnsize="wd_df" onClick={handleCancel} margin=" unset">
            <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
          </Button02>,
          <Button01
            key="submit"
            fontsize="sm"
            type="primary"
            btnsize="wd_df"
            onClick={handleCreate}
            disabled={
              (getFieldValue(`role`) !== undefined && _.size(getFieldValue(`role`)) !== 0) ||
              (getFieldValue(`user`) !== undefined && _.size(getFieldValue(`user`)) !== 0)
                ? false
                : true
            }
          >
            <FormattedMessage id="btnSave" defaultMessage="Save" />
          </Button01>,
        ]}
      >
        <div className="modal-approver-div-content">
          <Form>
            <Form.Item label={intl.formatMessage({ id: 'modalApproverTextRole', defaultMessage: 'Role' })} className="modal-approver-label">
              {getFieldDecorator('role', {
                initialValue: _.get(data, 'role') ? _.get(data, 'role') : undefined,
              })(
                <Select
                  placeholder={intl.formatMessage({ id: 'modalApproverPlaceholderRole', defaultMessage: 'Select Role' })}
                  mode="multiple"
                  showSearch
                  filterOption={(input, option) =>
                    option.props.children !== undefined &&
                    option.props.children !== null &&
                    option.props.children
                      .toString()
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  className="modal-approver-select-option"
                  // maxTagCount={3}
                  // maxTagTextLength={15}
                >
                  {roleData && roleData.map((item) => <Option key={item.per_gro_id}>{item.name}</Option>)}
                </Select>
              )}
            </Form.Item>
            <Form.Item
              label={intl.formatMessage({ id: 'modalApproverTextUser', defaultMessage: 'Users' })}
              className="modal-approver-label"
            >
              {getFieldDecorator('user', {
                initialValue: _.get(data, 'user') ? _.get(data, 'user') : undefined,
              })(
                <Select
                  placeholder={intl.formatMessage({ id: 'modalApproverPlaceholderUser', defaultMessage: 'Select User' })}
                  mode="multiple"
                  showSearch
                  filterOption={(input, option) =>
                    option.props.children !== undefined &&
                    option.props.children !== null &&
                    option.props.children
                      .toString()
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  className="modal-approver-select-option"
                >
                  {userData &&
                    userData.map((item) => (
                      <Option key={item.mem_com_id} value={item.mem_com_id}>
                        {item.fullname}
                      </Option>
                    ))}
                </Select>
              )}
            </Form.Item>
            <Form.Item
              label={
                <LabeRequire
                  text={intl.formatMessage({ id: 'modalApproverTextManageOrApprove', defaultMessage: 'Manage or Approve' })}
                  req={true}
                />
              }
              className="modal-approver-label"
            >
              {getFieldDecorator('requestType', {
                initialValue: _.get(data, 'requestType') ? _.get(data, 'requestType') : undefined,
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({
                      id: 'modalApproverValidateRequestType',
                      defaultMessage: 'Please Select Manage Or Approve',
                    }),
                  },
                ],
              })(
                <Select
                  placeholder={intl.formatMessage({
                    id: 'modalApproverPlaceholderManageOrApprove',
                    defaultMessage: 'Select Manage or Approve',
                  })}
                  mode="multiple"
                  showSearch
                  filterOption={(input, option) =>
                    option.props.children !== undefined &&
                    option.props.children !== null &&
                    option.props.children
                      .toString()
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  className="modal-approver-select-option"
                  // maxTagCount={3}
                  // maxTagTextLength={20}
                >
                  {requestTypeData.map((item) => (
                    <Option key={item.id}>{item.name}</Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </div>
  );
};

const ModalApprover = Form.create({})(ModalResourceApprover);

export default ModalApprover;
