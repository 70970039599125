import React from 'react';
import httpClient from '../../components/axiosClient';

const getServiceAreaById= async (data) => {
  const comCode = localStorage.getItem('comCode');
  if (data.serviceAreaId) {
    try {
      const response = await httpClient.get(`/v3/resource/manager/company/${comCode}/area-master/${data.serviceAreaId}`);
      if (response.status === 200) {
        return response.data.data;
      } else {
        return [];
      }
    } catch (error) {
      return [];
    }
  }
}

export default getServiceAreaById;