import React, { useContext, useRef } from 'react';
import { OverviewProvider } from './context';
import './index.css';
import OverviewPage from './overview-page';
import { ConfigProvider } from 'antd';

const OverviewProviderPage = () => {
  const fullscreenPageRef = useRef(null);

  return (
    <ConfigProvider getPopupContainer={() => fullscreenPageRef.current || document.body}>
      <OverviewProvider>
        <div ref={fullscreenPageRef}>
          <OverviewPage fullscreenPageRef={fullscreenPageRef} />
        </div>
      </OverviewProvider>
    </ConfigProvider>
  );
};

export default OverviewProviderPage;
