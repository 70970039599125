import httpCiient from '../../components/axiosClient';

const comCode = localStorage.getItem('comCode');

const getMyrequest = async (payload) => {
    try {   
      const response = await httpCiient.post(`/v5/resource/manager/company/${comCode}/web/myrequest`,payload);
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  };

export default getMyrequest