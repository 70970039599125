import React, { useContext } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Form, Row, Col, DatePicker, Select } from 'antd';
import CollapseCustom from '../../../components/collapse-custom';
import LabeRequireForm from '../../../components/label-required-form';
import _ from 'lodash';
import { MemberExpenseProvider } from '../context';
import Button_01 from '../../../components/v2/button_01';
import Button_02 from '../../../components/v2/button_02';
import MemberExpenseActionColumn from './actionColumn';

const { Option } = Select;
const { RangePicker } = DatePicker;

const MemberExpenseAction = () => {
  const { state, setState } = useContext(MemberExpenseProvider);
  const intl = useIntl();
  const {
    searchGroup,
    tableChange,
    listArrayColumns,
    defaultShowColumn,
    newColumns,
    textErrorSelectColumn,
    visible,
    actionColumns,
    loading,
    defaultColumnsData,
    activeTab,
    dataFilter,
  } = state;
  const {
    setSearchGroup,
    setTableChange,
    setTrigger,
    setNewDataColumns,
    setListArrayColumns,
    setDefaultShowColumn,
    setTextErrorSelectColumn,
    setVisible,
  } = setState;
  const formatDate = 'DD/MM/YYYY';

  const onChange = (value, code) => {
    setSearchGroup({ ...searchGroup, [code]: value });
  };

  const onApply = () => {
    setTableChange({ ...tableChange, page: 1 });
    setTrigger((cur) => !cur);
  };

  const onReset = () => {
    setSearchGroup({});
    setTableChange({ ...tableChange, page: 1 });
    setTrigger((cur) => !cur);
  };

  const onChangeDate = (value, code) => {
    setSearchGroup({ ...searchGroup, [code]: value });
    setTrigger((cur) => !cur);
  };

  const formFilter = (value) => {
    return (
      <Form colon={false} className="resourceAllowanceForm">
        <Row gutter={[24, 2]}>
          <Col span={8}>
            <Form.Item
              label={<LabeRequireForm text={intl.formatMessage({ id: 'memberExpenseCLMember', defaultMessage: 'Member' })} req={false} />}
            >
              <Select
                allowClear
                showSearch
                value={_.get(searchGroup, 'memberIdList')}
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                placeholder={intl.formatMessage({ id: 'memberExpenseCLMemberPH', defaultMessage: 'Select Member' })}
                onSearch={(e) => onChange(e, 'memberIdList')}
                onChange={(e) => onChange(e, 'memberIdList')}
              >
                {_.map(_.get(dataFilter, 'memberList'), (item, index) => (
                  <Option key={item.memComId} value={item.memComId}>
                    {item.memberName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<LabeRequireForm text={intl.formatMessage({ id: 'memberExpenseCLTeam', defaultMessage: 'Team' })} req={false} />}
            >
              <Select
                allowClear
                showSearch
                value={_.get(searchGroup, 'teamId')}
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                placeholder={intl.formatMessage({ id: 'memberExpenseCLTeamPH', defaultMessage: 'Select Team' })}
                onSearch={(e) => onChange(e, 'teamId')}
                onChange={(e) => onChange(e, 'teamId')}
              >
                {_.map(_.get(dataFilter, 'teamList'), (item, index) => (
                  <Option key={item.teamId} value={item.teamId}>
                    {item.teamName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<LabeRequireForm text={intl.formatMessage({ id: 'memberExpenseCLType', defaultMessage: 'Type' })} req={false} />}
            >
              <Select
                allowClear
                showSearch
                value={_.get(searchGroup, 'memberExpenseTypeCode')}
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                placeholder={intl.formatMessage({ id: 'memberExpenseCLTypePH', defaultMessage: 'Select Type' })}
                onSearch={(e) => onChange(e, 'memberExpenseTypeCode')}
                onChange={(e) => onChange(e, 'memberExpenseTypeCode')}
              >
                {_.map(_.get(dataFilter, 'numberType'), (item, index) => (
                  <Option key={item.basePayId} value={item.basePayId}>
                    {item.basePayName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        {/*--------------------------------------------------------------------------------------------------------------------*/}
        <Row gutter={[1,0]}>
          <Col span={8}></Col>
          <Col span={8}></Col>
          <Col span={8} style={{ textAlign: 'right',marginTop: '-20px' }}>
            <Form.Item label={<LabeRequireForm text={''} req={false} />}>
              <Button_01 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={onReset}>
                <FormattedMessage id="btnResetFilter" defaultMessage="Reset" />
              </Button_01>
              <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="print" type="primary" btnsize="wd_df" onClick={onApply}>
                <FormattedMessage id="btnApplyFilter" defaultMessage="Apply" />
              </Button_02>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  };

  return (
    <div style={{ padding: '12px 5px' }}>
      <CollapseCustom
        label={intl.formatMessage({ id: `btnFilter`, defaultMessage: 'Filter' })}
        extra={
          <div style={{ display: 'flex' }}>
            <RangePicker
                value={_.get(searchGroup,'rangePickerDate')}
                format={formatDate}
                onChange={(e) => onChangeDate(e, 'rangePickerDate')}
                allowClear={true}
                placeholder={[
                  intl.formatMessage({ id: 'saleorderFilterPHFormDueDate', defaultMessage: 'Select Form Date' }),
                  intl.formatMessage({ id: 'saleorderFilterPHToDueDate', defaultMessage: 'Selet To Date' }),
                ]}
              />
            <MemberExpenseActionColumn
              columnsNormal={defaultColumnsData}
              listArrayColumns={listArrayColumns}
              defaultShowColumn={defaultShowColumn}
              newColumns={newColumns}
              textErrorSelectColumn={textErrorSelectColumn}
              visible={visible}
              actionColumns={actionColumns}
              setNewDataColumns={setNewDataColumns}
              setListArrayColumns={setListArrayColumns}
              setDefaultShowColumn={setDefaultShowColumn}
              setTextErrorSelectColumn={setTextErrorSelectColumn}
              setVisible={setVisible}
              loading={loading}
              tabValue={activeTab}
            />
          </div>
        }
      >
        {formFilter(searchGroup)}
      </CollapseCustom>
    </div>
  );
};

export default MemberExpenseAction;
