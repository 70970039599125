import React from 'react'
import { Row, Col } from 'antd'
import Button02 from '../../../../../components/v2/button_02'
import Button01 from '../../../../../components/v2/button_01'
import { FormattedMessage } from 'react-intl'
import _ from 'lodash'

const DeliveryOrderButtons = (props) => {
  const { status, handleCancelShipmentDetail, handleSwtichForm, filterConfirmParcel, editData, handleConfirm, handleOpenModalPay } = props
  if (status === 'open') {
    return (
      <Row gutter={[16]}>
        <Col span={8}>
          <Button02 key="back" btnsize="wd_lg" style={{ margin: 'unset' }} onClick={handleCancelShipmentDetail}>
            <FormattedMessage id="btnBack" defaultMessage="Back" />
          </Button02>
        </Col>
        <Col span={8}>
          <Button01 key="submit" type="primary" btnsize="wd_lg" style={{ margin: 'unset' }} onClick={handleSwtichForm}>
            <FormattedMessage id="btnEdit" defaultMessage="Edit" />
          </Button01>
        </Col>
        <Col span={8}>
          <Button01 key="submit" type="primary" btnsize="wd_lg" style={{ margin: 'unset' }}
            onClick={handleConfirm}
            disabled={parseFloat(_.get(editData, 'amount')) <= 0 && _.size(filterConfirmParcel) !== 0 ? true : false}
          >
            <FormattedMessage id="btnConfirm" defaultMessage="Confirm" />
          </Button01>
        </Col>
      </Row>
    )
  } else if (status === 'confirmed') {
    return (
      <Row gutter={[16]}>
        <Col span={8}>
          <Button02 key="back" btnsize="wd_lg" style={{ margin: 'unset' }} onClick={handleCancelShipmentDetail}>
            <FormattedMessage id="btnBack" defaultMessage="Back" />
          </Button02>
        </Col>
        <Col span={8}>
          <Button01 key="submit" type="primary" btnsize="wd_lg" style={{ margin: 'unset' }} onClick={handleSwtichForm}>
            <FormattedMessage id="btnEdit" defaultMessage="Edit" />
          </Button01>
        </Col>
        <Col span={8}>
          <Button01 key="submit" type="primary" btnsize="wd_lg" disabled={_.get(editData, 'paymentStatus') !== 'paid' ? false : true} style={{ margin: 'unset' }} onClick={handleOpenModalPay}>
            <FormattedMessage id="btnPay" defaultMessage="Pay" />
          </Button01>
        </Col>
      </Row>
    )
  } else if (status === 'rejected' || status === 'canceled') {
    return (
      <Row gutter={[16]}>
        <Col span={8}>
        </Col>
        <Col span={8}>
          <Button02 key="back" btnsize="wd_lg" style={{ margin: 'unset' }} onClick={handleCancelShipmentDetail}>
            <FormattedMessage id="btnBack" defaultMessage="Back" />
          </Button02>
        </Col>
        <Col span={8}>
          <Button01 key="submit" type="primary" btnsize="wd_lg" style={{ margin: 'unset' }} onClick={handleSwtichForm}>
            <FormattedMessage id="btnEdit" defaultMessage="Edit" />
          </Button01>
        </Col>
      </Row>
    )
  } else if (status === 'sorting' || status === 'outfordelivery') {
    return (
      <Row gutter={[16]}>
        <Col span={8}>
        </Col>
        <Col span={8}>
          <Button02 key="back" btnsize="wd_lg" style={{ margin: 'unset' }} onClick={handleCancelShipmentDetail}>
            <FormattedMessage id="btnBack" defaultMessage="Back" />
          </Button02>
        </Col>
        <Col span={8}>
          <Button01 key="submit" type="primary" btnsize="wd_lg" disabled={_.get(editData, 'paymentStatus') !== 'paid' ? false : true} style={{ margin: 'unset' }} onClick={handleOpenModalPay}>
            <FormattedMessage id="btnPay" defaultMessage="Pay" />
          </Button01>
        </Col>
      </Row>
    )
  } else {
    return (
      <Row gutter={[16]}>
        <Col span={8}>
          <Button02 key="back" btnsize="wd_lg" style={{ margin: 'unset' }} onClick={handleCancelShipmentDetail}>
            <FormattedMessage id="btnBack" defaultMessage="Back" />
          </Button02>
        </Col>
        <Col span={8}>
          <Button01 key="submit" type="primary" btnsize="wd_lg" style={{ margin: 'unset' }} onClick={handleSwtichForm}>
            <FormattedMessage id="btnEdit" defaultMessage="Edit" />
          </Button01>
        </Col>
        <Col span={8}>
          <Button01 key="submit" type="primary" btnsize="wd_lg" disabled={_.get(editData, 'paymentStatus') !== 'paid' ? false : true} style={{ margin: 'unset' }} onClick={handleOpenModalPay}>
            <FormattedMessage id="btnPay" defaultMessage="Pay" />
          </Button01>
        </Col>
      </Row >
    )
  }
}

export default DeliveryOrderButtons
