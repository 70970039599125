import httpClient from '../components/axiosClient';

const downloadImage = async (taskId, taskNo, typePhoto,subTaskWf) => {
  try {
    const response = await httpClient.get(`/v3/task/manager/downloadfileforzip?taskid=${taskId}&taskno=${taskNo}&subtaskCode=${typePhoto}&subTaskWfId=${subTaskWf}`, {
      responseType: 'blob'
    });

    if (response.status === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
}

export default downloadImage;