import React, { useState } from 'react';
import { Button, Card } from 'antd';
import { PageSettings } from '../../config/page-settings';
import './css/index.css';
import { ProjectContext } from './context';
import ModalCreateProject from '../../components/project/modal-create';
import ModalEditProject from '../../components/project/modal-edit';
import ProjectList from './project-list';
import { useEffect } from 'react';
import { getAvatarList } from '../../controllers/project/project-api';

const ProjectPages = () => {
  const [visible, setVisible] = useState(false);
  const [visibleEdit, setVisibleEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [fliterByColumn, setFliterByColumn] = useState('all');
  const [fieldChange, setFieldChange] = useState('');
  const [total, setTotal] = useState();
  const [fieldSort, setFieldSort] = useState();
  const [orderSort, setOrderSort] = useState();
  const [paginationPage, setPaginationPage] = useState(1);
  const [paginationSize, setPaginationSize] = useState(10);
  const [projectData, setProjectData] = useState();
  const [avatarList, setAvatarList] = useState([]);
  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    const getAvatarApi = async () => {
      const response = await getAvatarList();
      setAvatarList(response || []);
    };

    getAvatarApi();
  }, []);

  return (
    <ProjectContext.Provider
      value={{
        state: { visible, fliterByColumn, fieldChange, dataSource, total, paginationPage, paginationSize, loading, orderSort, fieldSort, trigger, projectData },
        setState: {
          setVisible,
          setFieldChange,
          setFliterByColumn,
          setDataSource,
          setTotal,
          setOrderSort,
          setFieldSort,
          setLoading,
          setPaginationPage,
          setPaginationSize,
          setVisibleEdit,
          setProjectData,
          setTrigger,
        },
      }}
    >
      <div>
        <ProjectList />
        < ModalCreateProject visible={visible} setVisible={setVisible} avatarList={avatarList} setTrigger={setTrigger} />
        <ModalEditProject visible={visibleEdit} setVisible={setVisibleEdit} avatarList={avatarList} projectData={projectData} setTrigger={setTrigger} />
      </div>
    </ProjectContext.Provider>
  );
};

export default ProjectPages;
