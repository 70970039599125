import { Popover, Select, Table } from 'antd';
import _ from 'lodash';
import React, { useState, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import '../css/index.css';

const { Option } = Select;
const CustomTableComponent = ({
  columns,
  dataSource,
  scroll,
  rowKey,
  loading,
  total,
  role,
  paginationPage,
  setPaginationPage,
  setPaginationSize,
  setFieldSort,
  setOrderSort,
  contentAction,
  setLoading,
  setTotal,
  orderSort,
  fieldSort,
  paginationSize,
  paginationShow,
  onRow,
  onRowClick
}) => {
  const intl = useIntl();
  const ref = useRef();
  const [visiblePopoverTable, setVisiblePopoverTable] = useState();
  const [recordPopover, setRecordPopover] = useState();
  const [offsetPopover, setOffsetPopover] = useState([0, 0]);

  console.log('WaitingPage[4]',dataSource);

  const handleMouseLeave = () => {
    setVisiblePopoverTable(false);
    setRecordPopover();
    setOffsetPopover([0, 0]);
  };

  const fncActionTable = (record, offsetPopover, visiblePopoverTable, ref) => {
    return (
      <div>
        <Popover
          placement="right"
          trigger="click"
          align={{ offset: offsetPopover }}
          visible={visiblePopoverTable}
          zIndex={10000}
          content={contentAction(record)}
        >
          <div style={{ width: 0, height: 0 }} ref={ref}></div>
        </Popover>
      </div>
    );
  };

  const onChange = (pagination, sorter, extra) => {
    let field = extra.field;
    if (extra.order) {
      if (extra.order === 'descend') {
        setOrderSort('desc');
      } else {
        setOrderSort('asc');
      }
    } else {
      setOrderSort('');
    }
    setFieldSort(field);
    setPaginationSize(pagination.pageSize);
    setPaginationPage(pagination.current);
  };

  const showTotal = () => {
    return (
      <span style={{ fontSize: '13px' }}>
        <FormattedMessage id="lblRowPerPage" defaultMessage="Rows per page: " />
      </span>
    );
  };

  return (
    <div onMouseLeave={handleMouseLeave}>
      <Table
        className="table-global-scroll"
        rowKey={rowKey}
        dataSource={dataSource}
        scroll={total ? scroll : { x: 'max-content' }}
        columns={columns}
        onChange={onChange}
        loading={loading}
        onRowClick={onRowClick}
        pagination={
          total
            ? {
                total: total,
                current: paginationPage,
                showTotal: showTotal,
                defaultCurrent: 1,
                pageSizeOptions: ['10', '20', '30', '40', '50'],
                showSizeChanger: paginationShow,
                locale: { items_per_page: '' },
              }
            : false
        }
        onRow={
          _.isFunction(onRow)
            ? onRow
            : onRow
            ? (record, rowIndex) => {
                return {
                  onClick: (event) => {
                    if (!event.target.href) {
                      const { x, y } = ref.current.getBoundingClientRect();
                      setVisiblePopoverTable(true);
                      setOffsetPopover([event.pageX - x, event.pageY - y]);
                      setRecordPopover(record);
                    }
                  },
                };
              }
            : onRow
        }
      />
      {role ? fncActionTable(recordPopover, offsetPopover, visiblePopoverTable, ref) : null}
      {total > 0 ? (
        <div className="total-items-member">
          <span style={{ fontSize: '13px' }}>
            <FormattedMessage id="lblTotal" defaultMessage="Total" />
            {` ${total || 0} `}
            <FormattedMessage id="lblitems" defaultMessage="items" />
          </span>
        </div>
      ) : null}
    </div>
  );
};

CustomTableComponent.defaultProps = {
  columns: [],
  dataSource: [],
  scroll: { x: 'max-content' },
  rowKey: undefined,
  loading: false,
  total: 0,
  role: null,
  paginationPage: 1,
  paginationSize: 10,
  fieldSort: null,
  orderSort: null,
  paginationShow: false,
  contentAction: () => console.warn('contentAction not function '),
  setLoading: () => console.warn('setLoading not function '),
  setPaginationPage: () => console.warn('setPaginationPage not function '),
  setPaginationSize: () => console.warn('setPaginationSize not function '),
  setTotal: () => console.warn('setTotal not function '),
  setOrderSort: () => console.warn('setOrderSort not function '),
  setFieldSort: () => console.warn('setFieldSort not function '),
  onRow: false,
  onRowClick : () => console.warn('onRowClick not function '),
};

export default CustomTableComponent;
