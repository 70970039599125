import React, { useEffect, useState } from 'react';
import CompanyInformation from './company-information';
import CompanyAddress from './company-address';
import { PageSettings } from '../../../config/page-settings';
import { Row, Col } from 'antd';
import getCompanyProfile from '../../../controllers/company/get-company-profile';


import _ from 'lodash';
import './css/index.css';

export default () => {

  const [companyProfile, setCompanyProfile] = useState({});
  const [addressList, setAddressList] = useState([]);
  const [triggerApi, setTriggerApi] = useState(false);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    const getData = async () => {
      const response = await getCompanyProfile()
      if (_.size(response) !== 0) {
        setIsLoading(false)
        setCompanyProfile(response.companyProfile[0]);
        setAddressList(response.AddressBookList);
      }
    }
    setIsLoading(true);
    getData();
  }, [triggerApi, page, size])

  const handleChangePage = (page) => {
    console.log('pageFine', page);
    setPage(page);
  };

  const handleChangeSize = (current, size) => {
    setSize(size);
    setPage(current);
  };

  return (
    <div>
      <PageSettings.Consumer>
        {({ checkPermissionAction }) => (
          <Row gutter={[16, 16]}>
            <Col span={24}>
              {checkPermissionAction('P19PG1C1', 'P19PG1C1A1') ? (
                <CompanyInformation
                  companyProfile={companyProfile}
                  setTriggerApi={setTriggerApi}
                />
              ) : null}

            </Col>
            <Col span={24} >
              <CompanyAddress
                addressList={addressList}
                setTriggerApi={setTriggerApi}
                handleChangePage={handleChangePage}
                handleChangeSize={handleChangeSize}
                isLoading={isLoading}
              />
            </Col>
          </Row>
        )}
      </PageSettings.Consumer>
    </div>
  )
}
