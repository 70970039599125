import React, { useEffect, useRef } from 'react';
import { Form, Select,Row } from 'antd';
import _ from 'lodash';
import style from './css/detail-invoice.css'

const { Option } = Select;

export default Form.create({
  name: 'customer-form',
  // mapPropsToFields: (props) => {
  //   const { defaultTax } = props;

  // //   const customerId = _.get(datasource, 'customerId');

  //   return {
  //     // customer: Form.createFormField({
  //     //   value: customer ? customerId : '',
  //     // }),
  //     tax: Form.createFormField({
  //       value: defaultTax && `${defaultTax[0].taxId}`,
  //     }),
  //   };
  // },
})((props) => {

  const { getFieldDecorator, getFieldValue, resetFields } = props.form;
  const { tax, handleSelectTax, defaultTax} = props;

  const columnNew = tax && tax.filter((col) => {return col.defaultData == true});
  
  return (
    <Form>
        <Form.Item>
          {getFieldDecorator('tax', {
            // initialValue: columnNew && columnNew !== undefined ? columnNew[0].taxId : undefined,
            initialValue: columnNew && columnNew.length !== 0 ? columnNew[0].taxId : undefined,
            rules: [
              {
                required: true,
                message: 'Please select tax'
              }
            ]
          })(
            <Select 
              className='margin-select' 
              onSelect={(value) => handleSelectTax(value)}
            >
              {tax && tax.map(item =>
                <Option 
                  value={item.taxId} 
                  key={item.taxId}
                >
                  {item.titleCode}
                </Option>
              )}
            </Select>
          )}
        </Form.Item>
    </Form>
  );
});