import { useEffect, useState, useContext } from 'react';
import { useIntl } from 'react-intl';
import _ from 'lodash';
import httpClient from '../../../../components/axiosClient';
import { useDebounce } from '../../../../controllers/debounce';
import { getCustomer } from '../../../../controllers/customer-vendor/customer-vendor';
import { SaleOrderContext } from '../../context';

const comCode = localStorage.getItem('comCode');

const SelectCustomerHook = () => {
  const intl = useIntl();
  const { state, setState } = useContext(SaleOrderContext);
  const { selectCustomer } = state
  const { setSelectCustomer } = setState
  const [customerList, setCustomerList] = useState([]);
  const [customerObj, setCustomerObj] = useState();

  const [loadingContact, setLoadingContact] = useState(false);
  const [contactList, setContactList] = useState([]);

  const [loadingAddress, setLoadingAddress] = useState(false);
  const [addressList, setAddressList] = useState([]);

  const [searchCustomer, setSearchCustomer] = useState('')
  const debouceSearchCustomer = useDebounce(searchCustomer, 500)
  console.log('searchCustomer', searchCustomer, debouceSearchCustomer)

  useEffect(() => {
    const getCustomerList = async () => {
      const body = { "searchBy": "all", "searchVal": searchCustomer, "page": 1, "limit": 100, "orderBy": "" }
      const response = await getCustomer(body)
      const setCustomerListData = _.map(_.get(response, 'customers'), (o) => {
        return setDataCustomer(o);
      });
      setCustomerList(setCustomerListData)
    }
    getCustomerList()
  }, [debouceSearchCustomer])

  const setDataCustomer = (customer) => {
    return {
      customerId: _.get(customer, 'customerId'),
      customerName: _.get(customer, 'customerName'),
      customerTaxNo: _.get(customer, 'taxNo'),
    };
  };

  useEffect(() => {
    const getContactData = async () => {
      setLoadingContact(true);
      try {
        const body = {
          pageNumber: 1,
          limit: 100,
          orderBy: 'customerContactName',
          orderType: 'asc',
          searchObj: {
            customerContactName: '',
            customerContactEmail: '',
            customerContactPhone: '',
            position: '',
          },
          customerId: _.get(selectCustomer, 'customerId'),
        };

        const response = await httpClient.post(`/v5/business-partner/manager/company/${comCode}/web/customer-contacts/search`, body);

        if (_.get(response, 'status') == 200) {
          setContactList(_.get(response, 'data.data.list'));
          setLoadingContact(false);
        }
      } catch (error) { }
      setLoadingContact(false);
    };

    if (selectCustomer) {
      getContactData();
    }
  }, [selectCustomer]);

  useEffect(() => {
    const getAddressData = async () => {
      setLoadingAddress(true);
      try {
        const body = {
          pageNumber: 1,
          limit: 100,
          orderBy: 'addressName',
          orderType: 'asc',
          searchObj: {
            address: '',
            addressName: '',
          },
          customerId: _.get(selectCustomer, 'customerId'),
        };

        const response = await httpClient.post(`/v5/business-partner/manager/company/${comCode}/web/customer-address/search`, body);
        if (_.get(response, 'status') == 200) {
          setAddressList(_.get(response, 'data.data.list'));
          setLoadingAddress(false);
        }
      } catch (error) { }
      setLoadingAddress(false);
    };

    if (selectCustomer) {
      getAddressData();
    }
  }, [selectCustomer]);

  const handleSearchCustomer = (value) => {
    setSearchCustomer(value);
  }

  const resetCustomer = (isOpen) => {
    if(isOpen) {
      setSearchCustomer('');
    }
  }


  return {
    customerList,
    contactList,
    loadingContact,
    addressList,
    loadingAddress,
    customerObj,
    setCustomerObj,
    handleSearchCustomer,
    setSelectCustomer,
    resetCustomer
  };
};

export { SelectCustomerHook };
