import React, { useContext } from 'react'
import { Row, Col } from 'antd'
import { FormattedMessage } from 'react-intl'
import { ExpenseContext } from '..'
import { numberFormatDecimal } from '../../../component-function/fnc-number-format-decimal'
import _ from 'lodash'

const ExpenseSummary = () => {
  const { summaryList } = useContext(ExpenseContext)
  return (
    <div style={{ padding: '24px' }}>
      <Row gutter={[16]} style={{ textAlign: 'center' }}>
        <Col span={8}>
          <p className='expenseSummaryHeader'>
            <FormattedMessage id="expenseTxt15Days" defaultMessage="Within 15 days" />
          </p>
          <p className='expenseSummaryValue'>
            {numberFormatDecimal(_.get(summaryList, 'within15day') || 0)} THB
          </p>
        </Col>
        <Col span={8}>
          <p className='expenseSummaryHeader'>
            <FormattedMessage id="expenseTxt3Days" defaultMessage="Within 3 days" />
          </p>
          <p className='expenseSummaryValue'>
            {numberFormatDecimal(_.get(summaryList, 'within3day') || 0)} THB
          </p>
        </Col>
        <Col span={8}>
          <p className='expenseSummaryHeader'>
            <FormattedMessage id="expenseTxtOverdue" defaultMessage="Overdue" />
          </p>
          <p className='expenseSummaryValue'>
            {numberFormatDecimal(_.get(summaryList, 'overDue') || 0)} THB
          </p>
        </Col>
      </Row>
    </div>
  )
}

export default ExpenseSummary
