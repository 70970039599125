import React, { useEffect, useState } from 'react';
import { Row, Col, Icon, Popover, Tag, Modal, Spin } from 'antd';
import Information from './item-master-information';
import ItemMasterOption from './item-master-option';
import { useParams } from 'react-router';
import './css/index.css';
import _, { debounce } from 'lodash';
import getItemMasterDetail from '../../controllers/item-master/get-item-master-detail';
import { cancelItem } from '../../controllers/item/item-sender-receiver';
import { getDataAll, getModelName } from '../../controllers/item-master/get-data-all';
import { useIntl, FormattedMessage } from 'react-intl';
import httpClient from '../../components/axiosClient';
import { successNotification, errorNotification } from '../../components/v2/notification';
// import ItemDetail from './item-detail';
import Summary from './item-master-summary';
import Button01 from '../../components/v2/button_01.js';
import { PageSettings } from '../../config/page-settings';

export default () => {
  const intl = useIntl();
  const paramItemasterId = useParams();
  const comCode = localStorage.getItem('comCode');
  // const { state, setState } = useContext(ItemMasterDetail);

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
      fixed: 'left',
      render: (text, record, index) => record.index,
    },
    {
      title: intl.formatMessage({ id: 'itemLblDeliverbyr', defaultMessage: 'Deliver by' }),
      dataIndex: 'senderName',
      key: 'senderName',
      fixed: 'left',
      width: 250,
      sorter: 'true',
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: 'itemLblDateRecived', defaultMessage: 'Date Received' }),
      dataIndex: 'receiverDate',
      key: 'receiverDate',
      fixed: 'left',
      width: 250,
      sorter: 'true',
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: 'itemLblItemNo', defaultMessage: 'Item No' }),
      dataIndex: 'itemNo',
      key: 'itemNo',
      sorter: 'true',
      width: 300,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: 'itemLblQty', defaultMessage: 'Qty' }),
      dataIndex: 'qty',
      key: 'qty',
      sorter: 'true',
      width: 250,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: 'itemLblUnit', defaultMessage: 'Unit' }),
      dataIndex: 'unit',
      key: 'unit',
      sorter: 'true',
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: 'itemLblStatus', defaultMessage: 'Status' }),
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      render: (text, record, index) =>
        _.get(record, 'status.color') ? (
          <div className="tag-center-div">
            <Tag className="tag-center-style" color={_.get(record, 'status.color')}>
              {_.get(record, 'status.txt')}
            </Tag>
          </div>
        ) : null,
    },
    {
      title: intl.formatMessage({ id: 'itemLblReference', defaultMessage: 'Reference' }),
      dataIndex: 'reference1',
      key: 'reference1',
      sorter: 'true',
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: 'itemLblReceiveby', defaultMessage: 'Receive by' }),
      dataIndex: 'receiverName',
      key: 'receiverName',
      sorter: 'true',
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: 'itemLblRemark', defaultMessage: 'Remark' }),
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      width: 70,
      render: (text, record, index) => fncActionTable(record, index),
    },
  ];

  const setShowColumn = {
    index: true,
    senderName: true,
    receiverDate: true,
    itemNo: true,
    qty: true,
    unit: true,
    status: true,
    reference1: true,
    receiverName: false,
    description: false,
    action: true,
  };

  const setShowColumnArr = [
    'index',
    'senderName',
    'receiverDate',
    'itemNo',
    'qty',
    'unit',
    'status',
    'reference1',
    // 'receiverName',
    // 'description',
    'action',
  ];

  const ignoreColumn = ['receiverName', 'description'];
  const newColumns = columns.filter((col) => !ignoreColumn.includes(col.key));
  const ignoreFilterColumns = ['index', 'senderName', 'receiverDate', 'action'];
  const ignoreSearchColumns = ['index', 'qty', 'unit', 'status', 'reference1', 'action', 'receiverDate', 'description'];

  const itemasterId = _.get(paramItemasterId, 'item_master_id');
  const [itemMasterData, setItemMasterData] = useState([]);
  const [triggerAPI, setTriggerAPI] = useState(false);

  const [dataDefault, setDataDefault] = useState();
  const [brandId, setBrandId] = useState('');
  const [dataModel, setDataModel] = useState([]);
  const [editCheck, setEditCheck] = useState(false);

  const [purchaseLeadTime, setPurchaseLeadTime] = useState();
  const [maximumStock, setMaximumStock] = useState();
  const [minimumStock, setMinimumStock] = useState();
  const [defaultLocationCode, setDefaultLocationCode] = useState('');
  const [disabledButtonSave, setDisableButtonSave] = useState(false);

  const [itemList, setItemList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [paginationPage, setPaginationPage] = useState(1);
  const [paginationSize, setPaginationSize] = useState(10);
  const [total, setTotal] = useState();
  const [extraSort, setExtraSort] = useState();
  const [fieldSort, setFieldSort] = useState();
  const [orderSort, setOrderSort] = useState();

  const [fieldChang, setFieldChang] = useState('');
  const [fliterByColumn, setFliterByColumn] = useState('all');
  const [defaultShowColumn, setDefaultShowColumn] = useState({ ...setShowColumn });
  const [listArrayColumns, setListArrayColumns] = useState([...setShowColumnArr]);
  const [textErrorSelectColumn, setTextErrorSelectColumn] = useState('');
  const [newDataColumns, setNewDataColumns] = useState([...newColumns]);
  const [visible, setVisible] = useState(false);
  const [spin, setSpin] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const categoryCode = _.get(itemMasterData, 'categoryCode');
  //----------------------------------------
  const [createItem, setCreateItem] = useState();
  const [groupTypeId, setGroupTypeId] = useState();
  const [eventDataDefault, setEventDataDefault] = useState(false)

  useEffect(() => {
    async function getAPI() {
      const response = await getDataAll();
      await setDataDefault(response);
    }
    getAPI();
  }, [eventDataDefault]);

  useEffect(() => {
    async function getAPI() {
      setSpin(true);
      const response = await getItemMasterDetail({
        itemasterId,
        fieldChang,
        fliterByColumn,
        extraSort,
        fieldSort,
        orderSort,
        paginationPage,
        paginationSize,
      });
      await setItemMasterData(_.get(response, 'data[0]'));
      await setItemList(_.get(response.data[0].itemList, 'data'));
      await setTotal(_.get(response.data[0].itemList, 'allRows'));
      setSpin(false);
    }
    getAPI();
  }, [itemasterId, triggerAPI, fieldSort, orderSort, paginationPage, paginationSize, fieldChang, fliterByColumn]);

  useEffect(() => {
    async function getAPI() {
      const response = await getModelName({ brandId });
      await setDataModel(response);
    }
    if (brandId || createItem) getAPI();
  }, [brandId, visibleModal,createItem],);

  const onChangePurchaseLeadTime = (e) => {
    setPurchaseLeadTime(e);
  };

  const onChangeMaximumStock = (e) => {
    setMaximumStock(e);
  };

  const onChangeMinimumStock = (e) => {
    setMinimumStock(e);
  };

  const onChangeLocationCode = (e) => {
    setDefaultLocationCode(e);
  };

  const switchEdit = () => {
    setEditCheck(true);
  };

  const switchViewSave = async () => {
    setDisableButtonSave(true);
    if (itemMasterData) {
      const body = {
        itemMasterId: _.get(itemMasterData, 'itemMasterId'),
        purchaseLeadTime: Number(purchaseLeadTime) ? Number(purchaseLeadTime) : undefined,
        minimumStock: Number(minimumStock) ? Number(minimumStock) : undefined,
        maximumStock: Number(maximumStock) ? Number(maximumStock) : undefined,
        defaultLocationCode: defaultLocationCode ? defaultLocationCode : undefined,
      };

      try {
        const response = await httpClient.put(`/v3/item/manager/${comCode}/updatedataitemmaster`, body);
        if (response.status === 200) {
          setTriggerAPI((current) => !current);
          successNotification(response.data.status.message);
          setEditCheck(false);
          setDisableButtonSave(false);
        }
      } catch (error) {
        setDisableButtonSave(false);
        errorNotification(error.response.data.message);
      }
    }
  };

  const switchCancel = () => {
    setEditCheck(false);
    setTriggerAPI((current) => !current);
  };

  const onChangeSearch = debounce((value) => {
    setFieldChang(value);
    // setPaginationPage(1);
  }, 500);

  const onFliterByColumn = (value) => {
    setFliterByColumn(value);
  };

  const handleChange = (pagination, sorter, extra) => {
    let field = extra.field;
    if (extra.order) {
      if (extra.order === 'descend') {
        setOrderSort('desc');
      } else {
        setOrderSort('asc');
      }
    } else {
      setOrderSort();
    }
    setExtraSort(extra);
    setFieldSort(field);
  };

  const handlePagination = (page) => {
    setPaginationPage(page);
  };

  const handleSizeChange = (current, size) => {
    setPaginationSize(size);
    setPaginationPage(current);
  };

  const checkedValuecolumns = (e) => {
    let checked = e.target.checked;
    let val = e.target.value;
    let showColumn = { ...defaultShowColumn };
    if (checked === true) {
      const found = listArrayColumns.find((element) => element === val);
      if (found === undefined) {
        setListArrayColumns([...listArrayColumns, val]);
      }
      showColumn[val] = true;
    } else {
      const newListArray = [...listArrayColumns];
      const found = listArrayColumns.findIndex((element) => element === val);
      if (found >= 0) {
        newListArray.splice(found, 1);
        setListArrayColumns(newListArray);
      }
      showColumn[val] = false;
    }
    setDefaultShowColumn(showColumn);
  };

  const handleCheckOk = () => {
    const setNewArr = [];

    if (listArrayColumns.length < 5) {
      setTextErrorSelectColumn(<FormattedMessage id="lblSelectAtLeastColumn" defaultMessage="Select at least 1 column." />);
      return;
    } else {
      setTextErrorSelectColumn('');
    }
    for (let i = 0; i < columns.length; i++) {
      const found = listArrayColumns.find((element) => element == columns[i].key);
      if (found != undefined) {
        setNewArr.push(columns[i]);
      }
    }
    setNewDataColumns(setNewArr);
    handleOnVisiblecolumns();
  };

  const handleReset = () => {
    const newListArrayColumns = [];
    newColumns.forEach((element) => {
      newListArrayColumns.push(element.key);
    });
    setNewDataColumns(newColumns);
    setListArrayColumns(newListArrayColumns);
    handleOnVisiblecolumns();
    setDefaultShowColumn(setShowColumn);
    setTextErrorSelectColumn('');
  };

  const handleOnVisiblecolumns = () => {
    setVisible(!visible);
  };

  const handleVisibleChange = (flag) => {
    setVisible(flag);
  };

  const fncActionTable = (record, index) => {
    return (
      <PageSettings.Consumer>
        {({ checkPermissionAction }) => (
          <div>
            {record && _.lowerCase(_.get(record, 'status.txt')) === 'cancelled' ? (
              <></>
            ) : (
              <Popover
                key={index}
                zIndex={999}
                placement="leftTop"
                content={
                  <div style={{ display: 'grid' }}>
                    <>
                      <div>
                        <Button01 ghost btnsize="wd_df" type="link" onClick={() => handleCancel(record)}>
                          <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
                        </Button01>
                      </div>
                    </>
                  </div>
                }
              >
                {checkPermissionAction('P16PG1C2', 'P16PG1C2A3') ? <Icon type="more" /> : null}
              </Popover>
            )}
          </div>
        )}
      </PageSettings.Consumer>
    );
  };

  const handleCancel = (record, index) => {
    Modal.confirm({
      className: 'maintenance-report-modal-confirm',
      centered: true,
      icon: null,
      title: intl.formatMessage({ id: 'lblDelete', defaultMessage: 'Are you sure you want to cancel ?' }),
      okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        const item_id = _.get(record, 'itemId');
        try {
          const response = await cancelItem({ item_id });
          if (response.data.status === 200) {
            successNotification(response.data.message);
            setTriggerAPI((current) => !current);
          }
        } catch (error) {
          errorNotification(error.response.status.message);
        }
      },
      onCancel() {},
    });
  };

  return (
    <PageSettings.Consumer>
      {({ checkPermissionAction }) => (
        <div>
          <Spin spinning={spin}>
            <Row gutter={[16, 16]}>
              <Col span={17}>
                <Information
                  data={itemMasterData}
                  triggerAPI={triggerAPI}
                  setTriggerAPI={setTriggerAPI}
                  dataDefault={dataDefault}
                  setBrandId={setBrandId}
                  dataModel={dataModel}
                  visibleModal={visibleModal}
                  setVisibleModal={setVisibleModal}
                  setCreateItem={setCreateItem}
                  createItem={createItem}
                  setEventDataDefault={setEventDataDefault}
                  groupTypeId={groupTypeId}
                  setGroupTypeId={setGroupTypeId}
                />
              </Col>
              <Col span={7}>
                {/* <ItemRequestOption
                data={itemMasterData}
                triggerAPI={triggerAPI}
                setTriggerAPI={setTriggerAPI}
                editCheck={editCheck}
                switchEdit={switchEdit}
                switchViewSave={switchViewSave}
                switchCancel={switchCancel}
                dataDefault={dataDefault}
                disabledButtonSave={disabledButtonSave}
                setDisableButtonSave={setDisableButtonSave}
                fnc={{
                  onChangePurchaseLeadTime,
                  onChangeMaximumStock,
                  onChangeMinimumStock,
                  onChangeLocationCode
                }}
              /> */}
                <ItemMasterOption id={itemasterId} />
                {/* <Inventory
                data={itemMasterData}
                triggerAPI={triggerAPI}
                setTriggerAPI={setTriggerAPI}
                editCheck={editCheck}
                switchEdit={switchEdit}
                switchViewSave={switchViewSave}
                switchCancel={switchCancel}
                dataDefault={dataDefault}
                disabledButtonSave={disabledButtonSave}
                setDisableButtonSave={setDisableButtonSave}
                fnc={{
                  onChangePurchaseLeadTime,
                  onChangeMaximumStock,
                  onChangeMinimumStock,
                  onChangeLocationCode
                }}
              /> */}
              </Col>
            </Row>
            {categoryCode === 'product' ? (
              checkPermissionAction('P16PG1C2', 'P16PG1C2A1') ? (
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Summary itemasterId={itemasterId} data={itemMasterData} />
                  </Col>
                </Row>
              ) : null
            ) : null}
            {/* 
          {checkPermissionAction('P16PG1C2', 'P16PG1C2A1') ? (
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <ItemDetail
                  item={itemList}
                  columns={columns}
                  value={{
                    columns,
                    ignoreFilterColumns,
                    ignoreSearchColumns,
                    fliterByColumn,
                    visible,
                    defaultShowColumn,
                    textErrorSelectColumn,
                    itemMasterData,
                  }}
                  fnc={{
                    onChangeSearch,
                    onFliterByColumn,
                    checkedValuecolumns,
                    handleCheckOk,
                    handleReset,
                    handleVisibleChange,
                    handleOnVisiblecolumns,
                    setTriggerAPI,
                  }}
                  dataTable={{
                    loading,
                    itemList,
                    newDataColumns,
                    total,
                    paginationPage,
                    handleChange,
                    handlePagination,
                    handleSizeChange,
                  }}
                />
              </Col>
            </Row>
          ) : null
          } */}
          </Spin>
        </div>
      )}
    </PageSettings.Consumer>
  );
};
