import React, { useState, useEffect } from 'react'
import { Card, Popover, Button, Icon, Divider, Modal } from 'antd'
import { FormattedMessage, useIntl } from 'react-intl'
import Button01 from '../../components/v2/button_01'
import { getRequestOption, deleteRequestOption } from '../../controllers/item/requestOption'
import Nodata from '../../components/image/Nodata.svg'
import _ from 'lodash'
import RequestOptionModal from '../../components/modal-add-request-option/index'
import EditOptionModal from '../../components/modal-edit-request-option/index'
import { successNotification, errorNotification } from '../../components/v2/notification'
import './css/index.css'

function ItemMasterOption(props) {
  const { id } = props
  const [popOverVisible, setpopOverVisible] = useState(false);
  const [optionData, setOptionData] = useState([])
  const [optionSeletType, setOptionSeletType] = useState([])
  const [addNewRequestOptionModal, setAddNewRequestOptionModal] = useState(false);
  const [editRequestOptionModal, setEditRequestOptionModal] = useState(false);
  const [trigger, setTrigger] = useState(false)
  const [defaultData, setDefaultData] = useState()
  const intl = useIntl()

  const handlePopOverVisible = (newOpen) => {
    setpopOverVisible(newOpen);
  };

  const handleOpenModal = () => {
    setAddNewRequestOptionModal(true)
    setpopOverVisible(false);
  }

  useEffect(() => {
    const getByid = async () => {
      const response = await getRequestOption(id)
      setOptionData(_.get(response, 'data.topicOptions'))
      setOptionSeletType(_.get(response, 'data.topicTypeList'))
      console.log("response", response)
    }
    getByid()
  }, [id, trigger])

  const handelDelete = (id) => {
    Modal.confirm({
      className: 'add-modal-confirm',
      title: intl.formatMessage({ id: `modalOptionDeleteText`, defaultMessage: 'Do you want to delete request option ?' }),
      okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
      centered: true,
      icon: 'none',
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
      async onOk() {
        try {
          const response = await deleteRequestOption(id)
          if (response.status === 200) {
            successNotification(_.get(response, 'data.status.message'))
            setTrigger(current => !current)
          } else {
            errorNotification(_.get(response, 'data.status.message'))
          }
          console.log("handelDelete", response)
        } catch (error) {
          errorNotification(_.get(error, 'response.data.status.message'))
        }
      },
      onCancel() {

      },
    });
  }

  const handleEdit = (data) => {
    setDefaultData(data)
    setEditRequestOptionModal(true)
  }


  return (
    <div>
      <Card
        title={intl.formatMessage({ id: 'itemMasterTextHeaderRequestOption', defaultMessage: "Request Options" })}
        bodyStyle={{ height: '590px' }}
        extra={
          <div style={{ marginRight: '-22px' }}>
            <Popover
              className='optionRequestOptionAction'
              trigger="click"
              placement="bottomRight"
              visible={popOverVisible}
              onVisibleChange={handlePopOverVisible}
              content={
                <div>
                  <Button style={{ width: 100 }} type="link" ghost onClick={handleOpenModal}>
                    <FormattedMessage id="btnAdd" defaultMessage="Add New" />
                  </Button>
                </div>
              }
            >
              <Button01
                btnsize="wd_at"
                fontSize="sm"
                type='link'
              >
                <FormattedMessage id="btnActionGroup" defaultMessage="Action" />{" "}
                <Icon type="down" />
              </Button01>
            </Popover>
          </div>
        }
      >
        {
          _.size(optionData) !== 0 ? (
            <div>
              {
                _.map(_.get(_.chunk(optionData, 4), '[0]'), (item, index) => {
                  return (
                    <div>
                      <div className='optionItemContent'>
                        <div className='optionNormalText'>
                          {_.get(item, 'topicName')}
                        </div>
                        <div>
                          <Popover
                            key={item.topicId}
                            placement="leftTop"
                            trigger="hover"
                            content={
                              <div className="table-action-columns">
                                <>
                                  <Button
                                    style={{ width: 100 }}
                                    type="link"
                                    ghost
                                    onClick={() => handleEdit(item)}
                                  >
                                    <FormattedMessage id="btnEdit" defaultMessage="Edit" />
                                  </Button>
                                </>
                                <>
                                  <Button
                                    style={{ width: 100 }}
                                    type="link"
                                    ghost
                                    onClick={() => handelDelete(item.topicId)}
                                  >
                                    <FormattedMessage id="btnDelete" defaultMessage="Delete" />
                                  </Button>
                                </>
                              </div>
                            }
                          >
                            <Icon type="more" />
                          </Popover>
                        </div>
                      </div>
                      <div className='optionItemContent' style={{ margin: '8px 0' }}>
                        <div className='optionNormalText'>
                          {_.get(item, 'topicTypeName')}
                        </div>
                        <div className='optionRequiredText'>
                          {_.get(item, 'required') ? <FormattedMessage id="modalRequestOptionRequire" defaultMessage="Require" /> : <FormattedMessage id="modalRequestOptionNotRequire" defaultMessage="Not Require" />}
                        </div>
                      </div>
                      <div style={{ display: 'flex' }}>
                        {
                          _.map(_.get(item, 'options'), (e, i) => (
                            <div className='optionNormalText'>
                              {
                                _.get(e, 'name')
                              }
                              {
                                (_.size(_.get(item, 'options')) - 1) !== i ? `,` : ''
                              }
                            </div>
                          ))
                        }
                      </div>
                      {
                        (_.size(_.get(_.chunk(optionData, 4), '[0]')) - 1) !== index ? (
                          <Divider type="horizontal" />
                        ) : null
                      }
                    </div>
                  )
                })
              }
            </div>
          ) : (
            <div style={{ display: 'grid', justifyContent: 'center', margin: '75% 0' }}>
              <div style={{ display: 'grid', justifyContent: 'center' }}>
                <img src={Nodata} alt='noData' className='optionRequestImage' />
              </div>
              <div>
                <FormattedMessage id="itemMasterTextNoRequestOption" defaultMessage="No Request Options" />
              </div>
            </div>
          )
        }

      </Card>

      <RequestOptionModal
        setOpenReqModal={setAddNewRequestOptionModal}
        openReqModal={addNewRequestOptionModal}
        optionSeletType={optionSeletType}
        idItemMaster={id}
        setTrigger={setTrigger}
      />

      <EditOptionModal
        setOpenReqModal={setEditRequestOptionModal}
        openReqModal={editRequestOptionModal}
        optionSeletType={optionSeletType}
        idItemMaster={id}
        setTrigger={setTrigger}
        defaultData={defaultData}
      />

    </div>
  )
}

export default ItemMasterOption
