import React, { useEffect, useState, useContext, useMemo } from 'react';
import styled from 'styled-components';
import { Modal, Badge, Spin, Tabs, Col, Row, Divider } from 'antd';
import getTaskDetail from '../../controllers/task/get-task-detail';
import getTaskWorklogs from '../../controllers/task/get-work-logs';
import _ from 'lodash';
import './css/index.css';
import Information from './Information/index';
import WorkLogs from './work-logs/index';
import SubTasks from './sub-tasks/index';
import getCustomeField from '../../controllers/task/get-custom-field';
import getApiPolyline from '../../controllers/polyline-api/polyline-api';
import TeamSelect from './team/index';
import { PageSettings } from '../../config/page-settings';
import getAllTeamBuMemCom from '../../controllers/task/get-all-team-by-mem-id';
import Assignee from './assignee/index';
import Tracking from './tracking/index';
import MapTab from './map/index';

const { TabPane } = Tabs;

export default (props) => {
  const { visible, setVisible, taskData, setTaskData } = props;

  //--------------------- Data State----------------------------
  const [addressData, setAddressData] = useState();
  const [assigneeData, setAssigneeData] = useState([]);
  const [assignmentLogsData, setAssignmentLogsData] = useState([]);
  const [callLogsData, setCallLogsData] = useState([]);
  const [informationData, setInformationData] = useState();
  const [mapData, setMapData] = useState([]);
  const [moreDetailData, setMoreDetailData] = useState();
  const [statusLogsData, setStatusLogsData] = useState([]);
  const [subTaskData, setSubTaskData] = useState([]);
  const [trackingData, setTrackingData] = useState([]);
  const [travelExpenseLogsData, setTravelExpenseLogsData] = useState([]);

  const [workLogsData, setWorkLogsData] = useState([]);

  //--------------------- Normal State -----------------------------
  const app = useContext(PageSettings);
  const [statusSpin, setStatusSpin] = useState(false);
  const [statusTab, setStatusTab] = useState('information');
  const [teamData, setTeamData] = useState([]);
  const [keys, setKeys] = useState([]);

  //---------------------- Information State -----------------------

  const [cutomeFieldData, setCutomeFieldData] = useState();
  const [polylineDataView, setPolylineDataView] = useState({});
  const [markerPointView, setMarkerPointView] = useState([]);

  const taskId = _.get(taskData, 'taskId');

  //---------------------------Task Data Defalt -------------------------------

  useEffect(() => {
    if (assigneeData) {
      const thisKeys = assigneeData.map((e) => e.memComId);
      setKeys(thisKeys);
    }
  }, []);

  useEffect(() => {
    const getData = async () => {
      if (taskData && visible === true) {
        setStatusSpin(true);
        const response = await getTaskDetail(taskData.memComId, taskData.taskId);
        if (_.size(response) !== 0) {
          setAddressData(response.address);
          setAssigneeData(response.assignee);
          setAssignmentLogsData(response.assignmentLogs);
          setCallLogsData(response.callLogs);
          setInformationData(response.information);
          setMapData(response.map);
          setMoreDetailData(response.moreDetails);
          setStatusLogsData(response.statusLogs);
          setSubTaskData(response.subtask);
          setTrackingData(response.tracking);
          setTravelExpenseLogsData(response.travelExpensesLogs);
          setStatusSpin(false);
        }
        setStatusSpin(false);
      }
    };
    getData();
  }, [visible]);

  //-------------------------------Data Default -----------------------------------

  useEffect(() => {
    const getCustomFieldData = async () => {
      if (statusTab === 'information' && informationData) {
        const response = await getCustomeField(informationData.taskTypeId);
        if (_.size(response) !== 0) {
          setCutomeFieldData(response[0]);
        }
      }
    };

    getCustomFieldData();
  }, [statusTab, informationData]);

  useEffect(() => {
    const getPolyline = async () => {
      if (statusTab === 'information' && addressData) {
        let marker = [];
        let newFromLocation = {
          lat: addressData ? addressData.fromlat : undefined,
          lng: addressData ? addressData.fromlng : undefined,
        };
        let newToLocation = {
          lat: addressData ? addressData.lat : undefined,
          lng: addressData ? addressData.lng : undefined,
        };

        marker.push(
          {
            key: 1,
            lat: addressData ? Number(addressData.fromlat) : undefined,
            lng: addressData ? Number(addressData.fromlng) : undefined,
            markerSvg: addressData ? addressData.fromMarker : undefined,
          },
          {
            key: 2,
            lat: addressData ? Number(addressData.lat) : undefined,
            lng: addressData ? Number(addressData.lng) : undefined,
            markerSvg: addressData ? addressData.toMarker : undefined,
          }
        );

        let newPolyline = await getApiPolyline(newFromLocation, newToLocation);
        setPolylineDataView(newPolyline);
        setMarkerPointView(marker);
      }
    };
    getPolyline();
  }, [statusTab, addressData]);

  useEffect(() => {
    const getTeamData = async () => {
      const response = await getAllTeamBuMemCom(app.memberProfile.member.mem_id);
      if (_.size(response) !== 0) {
        setTeamData(response.data.organization);
      }
    };
    getTeamData();
  }, []);

  useEffect(() => {
    if (assigneeData) {
      const thisKeys = assigneeData.map((e) => e.memComId);
      setKeys(thisKeys);
    }
  }, []);

  useEffect(() => {
    const getDataWorkLog = async () => {
      if (taskData && visible === true) {
        const response = await getTaskWorklogs(taskData.taskId);
        setWorkLogsData(response.data.workLogs);
      }
    };

    getDataWorkLog();
  }, [visible]);

  //-------------------------------- Add on Component ----------------------------------------

  const TitleDetailsModal = () => {
    return (
      <div>
        <div style={{ textAlign: 'left' }}>
          <Badge color={_.get(informationData, 'statusColor')} className="Statusdot" />
          <span className="assignee-details-title">{_.get(informationData, 'taskNo')}</span>
          <Badge color="#1D3557" className="beetwen-badge-dot" />
          <span className="assignee-details-title">{_.get(informationData, 'taskTypeName')}</span>
        </div>
      </div>
    );
  };

  //--------------------------- Control Modal ----------------------------------

  const handleClose = () => {
    setVisible(false);
    setStatusTab('information');
  };

  const handleTabChange = (key) => {
    setStatusTab(key);
  };

  return (
    <div>
      <Modal
        className="modal-task-detail"
        title={<TitleDetailsModal />}
        onCancel={handleClose}
        visible={visible}
        centered
        width={1200}
        style={{ maxHeight: '98%', overflowY: 'visible' }}
        bodyStyle={{ height: 'auto' }}
      >
        <Spin spinning={statusSpin}>
          <Row>
            <Col span={18}>
              <Tabs className="modal-task-detail-tab" onTabClick={handleTabChange}>
                <TabPane tab="Information" key="information">
                  <Information
                    view="view"
                    value={{
                      informationData,
                      cutomeFieldData,
                      markerPointView,
                      polylineDataView,
                      addressData,
                    }}
                    fnc={{}}
                  />
                </TabPane>
                <TabPane tab="Map" key="map">
                  <MapTab
                    value={{
                      addressData,
                      assigneeData,
                      taskData,
                      informationData,
                      keys,
                    }}
                    fnc={{}}
                  />
                </TabPane>
                <TabPane tab="Sub Task" key="subTask" style={{ padding: '9px 24px 0px' }} className="scroll-task-detail">
                  <SubTasks
                    value={{
                      assigneeData,
                      keys,
                      subTaskData,
                    }}
                  />
                </TabPane>
                <TabPane tab="More Details" key="moreDetail" disabled={true}>
                  Test
                </TabPane>
                <TabPane tab="Request" key="request" disabled={true}>
                  Test
                </TabPane>
                <TabPane tab="History" key="workLog" style={{ padding: '9px 24px 0px' }} className="scroll-task-detail">
                  <WorkLogs
                    value={{
                      workLogsData,
                    }}
                  />
                </TabPane>
              </Tabs>
            </Col>

            <Divider className="task-details-right-divider" type="vertical" />
            <Col span={6}>
              <div className="modal-task-detail-tab-tracking">
                <TeamSelect teamData={teamData} orgID={_.get(informationData, 'orgId')} />
                <Assignee assigneeData={assigneeData} keys={keys} setKeys={setKeys} />
                <Tracking trackingData={trackingData} />
              </div>
            </Col>
          </Row>
        </Spin>
      </Modal>
    </div>
  );
};
