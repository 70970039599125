import React from 'react';
import { Tag } from 'antd';

const RenderTag = ({ fileList, handleTags, handleRemove }) => {

  const splitText = (val) => {
    let lastDotIndex = val.lastIndexOf('.');
    let everythingAfterTheFinalDot = val.substring(lastDotIndex + 1);
    return everythingAfterTheFinalDot;
  }

  return (
    <Tag className='post-tags' key={fileList.attachmentId} onClick={() => handleTags(fileList)} onClose={() => handleRemove(fileList.attachmentId,fileList)} closable  >
      <span title={fileList.name}>{fileList.name.length > 30 ? fileList.name.substring(0, 30) + '...' + splitText(fileList.name) : fileList.name}</span>
    </Tag>
  )
}

export { RenderTag };