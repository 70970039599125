import React from 'react';

const LabeRequireFormHint = (props) => {
  const { text } = props;
  return (
    <span style={{color: '#898989', fontSize: '12px'}}>
      {text}&nbsp;
      {/* {req ? <span >*</span> : ''} */}
    </span>
  );
};

export default LabeRequireFormHint;