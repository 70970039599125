import httpClientNoKeyCloak from '../../components/axiosClientNoKeyCloak'
import _ from 'lodash';

const getProductType = async () => {

  const comId = localStorage.getItem('comId');

  try {
    const response = await httpClientNoKeyCloak.get(`/v4/task/manager/company/${comId !== "undefined" ? comId : 29}/ticket/help-desk/product-type`);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
}

const addTicket = async (body) => {

  const comId = localStorage.getItem('comId');

  try {
    const response = await httpClientNoKeyCloak.post(`/v4/task/manager/company/${comId !== "undefined" ? comId : 29}/ticket/help-desk/create`, body);
    if (response.status === 200) {
      return response;
    } else {
      return response;
    }
  } catch (error) {
    return error.response;
  }
}

const getSearchLocation = async (keyword) => {
  try {
    let params = '';
    let typeParam = ''
    if (Object.keys(keyword)[0] === 'location') {
      params = `?lat=${keyword.location.lat()}&lng=${keyword.location.lng()}`;
      typeParam = `latlng`
    }
    else if (Object.keys(keyword)[0] === 'address') {
      params = `latlng/?lat=${keyword.address.lat}&lng=${keyword.address.lng}`;
    }
    else if (Object.keys(keyword)[0] === 'name') {
      params = `?name=${keyword.name}`;
      typeParam = `name`
    } else if (Object.keys(keyword)[0] === 'Default') {
      params = `?lat=${_.get(keyword, 'Default.coords.latitude')}&lng=${_.get(keyword, 'Default.coords.longitude')}`;
      typeParam = `latlng`
    } else if (Object.keys(keyword)[0] === 'latlngPure') {
      params = `?lat=${_.get(keyword, 'latlngPure.lat')}&lng=${_.get(keyword, 'latlngPure.lng')}`;
      typeParam = `latlng`
    } else {
      params = '';
      typeParam = '';
    }


    if (keyword) {
      const response = await httpClientNoKeyCloak.get(`/v1/resource/manager/search/location/type/${typeParam}/manager-call/${params}`);

      if (response.status === 200) {
        if (response?.data && Array.isArray(response?.data)) {
          const newArea = response.data.map((item, index) => {
            return {
              key: index,
              ...item
            }
          });
          return newArea;
        } else {
          return response.data;
        }
      }
    }
  } catch (error) {

  }
}

export { getProductType, addTicket, getSearchLocation }