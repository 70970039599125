import React, { useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Input, Icon, Select } from 'antd'
import '../css/action-search.css'
const { Option } = Select;

export default (props) => {
  const { handleSearch, handleSelect, searchValue, selectBy, setInputValue, inputValue, setSearchValue, selectSearch, setPaginationPage } = props;
  const intl = useIntl();


  return (
    <div className='action-search-all'>
      <Input
        className="monitor-view-input-action action-input"
        placeholder={intl.formatMessage({
          id: 'hintSearch',
          defaultMessage: 'Search',
        })}
        prefix={<Icon style={{ color: "rgba(0,0,0,.25)" }} type="search" />}
        onChange={(e) => {
          // handleSearch(e.target.value)
          setPaginationPage(1)
          setSearchValue(e.target.value)
        }}
        autoComplete="off"
        value={searchValue}
        defaultValue=''
      />
      <Select
        className="monitor-select-action action-select"
        placeholder={intl.formatMessage({
          id: 'hintSelect',
          defaultMessage: 'Select',
        })}
        onSelect={(value) => handleSelect(value)}
        defaultValue='all'
        value={selectBy}
      >
        <Option value="all">
          <FormattedMessage id="reportOutsideReportHintSeleteAllColumns" defaultMessage="All columns" />
        </Option>
        {selectSearch.map((item, index) =>
          <Option key={index} value={item.key}>{item.title}</Option>
        )}
      </Select>
    </div>
  )
}