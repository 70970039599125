import httpClient from '../../components/axiosClient';

const getDataAll = async () => {

  const comCode = localStorage.getItem('comCode');

  try {
    const response = await httpClient.get(`/v3/item/manager/${comCode}/getdataAll`);
    if (response.status === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
}

const getModelName = async ({ brandId }) => {

  const comCode = localStorage.getItem('comCode');

  try {
    const response = await httpClient.get(`/v3/item/manager/${comCode}/getdatamodel?itemBrandId=${brandId}`);
    if (response.status === 200) {
      return response.data.itemModel;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
}

export { getDataAll, getModelName };