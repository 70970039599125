import React, { useState, useEffect } from "react";
import { Modal, Input, Form, Row, Col, Select, Option, Switch, Icon, Button } from "antd";
import Button01 from "../v2/button_01";
import Button02 from "../v2/button_02";
import _ from 'lodash'
import { useIntl, FormattedMessage } from 'react-intl'
import { addRequestOption } from "../../controllers/item/requestOption";
import { successNotification, errorNotification } from '../v2/notification';
import './index.css'

const CreateRequestOption = (props) => {
  const { setOpenReqModal, openReqModal, form, optionSeletType, idItemMaster, setTrigger } = props
  const { getFieldDecorator, validateFields, resetFields, getFieldsValue, getFieldValue, setFieldsValue } = form;
  const [dataOption, setDataOption] = useState(['add'])
  const { Option } = Select;
  const intl = useIntl()
  let id = 1;
  getFieldDecorator('keys', { initialValue: [1] });
  const keys = getFieldValue('keys');
  console.log("keys", keys)

  const LabeRequire = (props) => {
    const { text, req } = props;
    return (
      <span>
        {text}&nbsp;
        {req ? <span style={{ fontSize: '13px', color: '#fa383e' }}>*</span> : ''}
      </span>
    );
  };

  const handleClose = () => {
    setOpenReqModal(false)
    handleReset()
  }


  const handleReset = () => {
    form.resetFields()
    setDataOption(['add'])
  }

  const handleSave = () => {
    validateFields(async (err, values) => {
      if (err) {
        return;
      }
      const getOption = _.filter(_.get(values, 'option'), (item) => { return item !== undefined })
      const mapRex = _.map(getOption, (item) => {
        const processedText = item.replace(/\{.*?\}/g, '');
        const valuesInsideBrackets = _.map((item.match(/\{(.*?)\}/g) || ''), (e) => {
          return e.replace(/[\{\}]/g, '')
        })
        return {
          "name": _.trim(processedText),
          "price": parseInt(_.get(valuesInsideBrackets, '[0]') || 0)
        }
      })
      const body = {
        "itemMasterId": idItemMaster,
        "topicName": _.get(values, 'topic'),
        "topicTypeCode": _.get(values, 'selectType'),
        "required": _.get(values, 'switch') || false,
        "options": mapRex,
      }
      try {
        const response = await addRequestOption(body)
        if (response.status === 200) {
          successNotification(_.get(response, 'data.status.message'))
          setTrigger(current => !current)
          handleClose()
        } else {
          errorNotification(_.get(response, 'data.status.message'))
        }
      } catch (error) {
        errorNotification(_.get(error, 'response.data.status.message'))
      }
      // console.log("validateFields", response)
    })
  }

  const removeForm = (k) => {
    if (keys.length === 1) {
      return;
    }
    setFieldsValue({
      keys: keys.filter((key, index) => index !== k),
    });
  };

  const addForm = () => {
    const nextKeys = keys.concat(id++);
    setFieldsValue({
      keys: nextKeys,
    });
  };

  const formItems = keys.map((k, index) => (
    <Row gutter={[12, 5]} style={{ marginBottom: 10 }} key={index}>
      <Col span={18}>
        <Form.Item
          key={index}
        >
          {getFieldDecorator(`option[${index}]`, {
            rules: [
              {
                required: true,
                message: intl.formatMessage({ id: 'modalRequestOptionValidateOption', defaultMessage: "Please Enter option" }),
              },
            ],
          })(<Input
            placeholder={intl.formatMessage({ id: 'modalRequestOptionPlacholderOption', defaultMessage: 'Enter Option' })}
          />)}

        </Form.Item>
      </Col>
      <Col span={6} >
        {keys.length > 1 && index !== (keys.length - 1) ? (
          <Button02 key="back" btnsize="wd_df" onClick={() => removeForm(index)} style={{ width: '100%', margin: 'unset' }}>
            <FormattedMessage id='modalRequestOptionDelete' defaultMessage='Delete' />
          </Button02>
        ) :
          <Button02 key="back" btnsize="wd_df" onClick={addForm} style={{ width: '100%', margin: 'unset' }}>
            <FormattedMessage id='btnAdd' defaultMessage='Add' />
          </Button02>
        }
      </Col>
    </Row>

  ));




  return (
    <>
      <Modal
        bodyStyle={{ overflowY: 'auto', padding: '10px 24px' }}
        width={500}
        centered
        title={intl.formatMessage({ id: 'itemMasterTextHeaderCreateOption', defaultMessage: 'Create Request Options' })}
        visible={openReqModal}
        onCancel={handleClose}
        onOk={handleSave}
        footer={
          [
            <Button02 key="back" btnsize="wd_df" onClick={handleClose}><FormattedMessage id='btnCancel' defaultMessage='Cancel' /></Button02>,
            <Button01 key="submit" type="primary" btnsize="wd_df" style={{ marginLeft: -5 }} onClick={handleSave}><FormattedMessage id='btnSave' defaultMessage='Save' /></Button01>
          ]
        }
      >
        <div>
          <div>
            <Form
              hideRequiredMark={true}
              colon={false}
              form={form}
              name="requestOptionForm"
              className="requestFormoption"
            >
              <Row gutter={[12, 5]}>
                <Col span={20}>
                  <Form.Item
                    label={<LabeRequire text={intl.formatMessage({ id: 'modalRequestOptionTopic', defaultMessage: 'Topic' })} req={true} />}
                    name='topicForm'

                  >
                    {getFieldDecorator("topic",
                      {
                        rules: [
                          {
                            required: true,
                            message: intl.formatMessage({ id: 'modalRequestOptionValidateTopic', defaultMessage: "Please input topic" }),
                          },
                        ]
                      })
                      (
                        <Input
                          autoComplete="off"
                          placeholder={intl.formatMessage({ id: 'modalRequestOptionPlacholderTopic', defaultMessage: 'Enter Topic' })}
                        />
                      )}
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item
                    label={<LabeRequire text={intl.formatMessage({ id: 'modalRequestOptionRequire', defaultMessage: 'Require' })} req={true} />}
                    name='requireForm'
                  >
                    {getFieldDecorator("switch",
                      {
                      })
                      (<Switch />)}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[12, 5]}>
                <Col>
                  <Form.Item
                    label={<LabeRequire text={intl.formatMessage({ id: 'modalRequestOptionSelectType', defaultMessage: 'Select Type' })} req={true} />}
                  >
                    {getFieldDecorator("selectType",
                      {
                        rules: [
                          {
                            required: true,
                            message: intl.formatMessage({ id: 'modalRequestOptionValidateSelectType', defaultMessage: 'Please select Select Type' })
                          },
                        ]
                      })
                      (<Select
                        autoComplete="off"
                        placeholder={intl.formatMessage({ id: 'modalRequestOptionPlacholderSelectType', defaultMessage: 'Enter Select Type' })}
                      >
                        {
                          _.map(optionSeletType, (item) =>
                            <Option value={item.code}>{item.name}</Option>
                          )
                        }
                      </Select>
                      )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[12, 5]}>
                <Col>
                  <Form.Item
                    label={<LabeRequire text={intl.formatMessage({ id: 'modalRequestOptionOptionText', defaultMessage: 'Option' })} req={true} />}
                  >
                  </Form.Item>
                </Col>
              </Row>
              {formItems}
            </Form>
          </div>
        </div>
      </Modal>
    </>
  )

}



const ModalRequestOption = Form.create({
  name: 'modal_form',
})(CreateRequestOption);

export default ModalRequestOption