import httpClient from '../../components/axiosClient';
import _ from 'lodash';

const getVehicle = async (body) => {
  const comCode = localStorage.getItem('comCode');

  try {
    const response = await httpClient.post(`/v3/item/manager/${comCode}/asset/vehicle/loadmore`, body);

    if (response.status == 200) {
      const data = _.get(response.data, 'data');
      const results = data.map((i) => {
        return {
          id: i.assetId,
          assetNo: i.assetNo,
          assetClassName: i.assetClassName || '',
          icon: i.icon,
          borrower: i.borrower,
          colorCode: i.colorCode,
          loaded: i.loaded,
          loadedView: i.loadedView,
          orderTotal: i.orderTotal,
          itemTotal: i.itemTotal,
          maxCapacity: i.maxCapacity,
          maxWeight: i.maxWeight,
          height: i.height,
          width: i.width,
          length: i.length,
          planStartDate: i.planStartDateFormat,
          planDueDate: i.planDueDateFormat,
          serialNo: i.serialNo,
          sending: i.sending,
        };
      });

      return results;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};

const getWaitingOrder = async (payload) => {
  const comCode = localStorage.getItem('comCode');

  try {
    const response = await httpClient.post(`/v3/order/manager/${comCode}/waitingorder/list`, payload);

    if (response.status == 200) {
      const data = _.get(response, 'data');
      return data;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};

const getDeliveryOrder = async (assetId, orderType, orderBy, date) => {
  const comCode = localStorage.getItem('comCode');

  try {
    const response = await httpClient.get(
      `/v3/order/manager/${comCode}/deliveryorder/list/${assetId}?orderType=${orderType}&orderBy=${orderBy}&shippingDate=${date}`
    );

    if (response.status == 200) {
      const data = _.get(response, 'data');
      return data;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};

const getByid = async (assetid, orderType, orderBy, date, color,groubId) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.get(
      `/v3/item/manager/${comCode}/asset/loadandroute/${assetid}?orderType=${orderType}&orderBy=${orderBy}&shippingDate=${date}&colorCode=${color ? color.substring(1) : color}&orgId=${groubId}`
    );
    if (response.status === 200) {
      return response;
    } else {
      return false;
    }
  } catch (error) {
    return error.response;
  }
};

const getByidExport = async (assetid, orderType, orderBy, body, date) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(
      `/v3/item/manager/${comCode}/asset/loadandroute/${assetid}/export?orderType=${orderType}&orderBy=${orderBy}&shippingDate=${date}`,
      body
    );
    if (response.status === 200) {
      return response;
    } else {
      return false;
    }
  } catch (error) {
    return error.response;
  }
};

const saveEditVehical = async (data, id) => {
  const comCode = localStorage.getItem('comCode');
  const body = {
    assetId: _.get(data, 'id'),
    length: _.get(data, 'length'),
    height: _.get(data, 'height'),
    width: _.get(data, 'width'),
    planStartDate: _.get(data, 'planStartDate'),
    planDueDate: _.get(data, 'planDueDate'),
    maxCapacity: _.get(data, 'maxCapacity'),
    maxWeight: _.get(data, 'maxWeight'),
    loaded: _.get(data, 'loaded'),
    shippingDate: _.get(data, 'shippingDate'),
  };
  try {
    const response = await httpClient.put(`/v3/item/manager/${comCode}/asset/loadandroute/${id}`, body);
    if (response.status === 200) {
      return response;
    } else {
      return false;
    }
  } catch (error) {
    return error.response;
  }
};

const calculateVehicle = async (data) => {
  const comCode = localStorage.getItem('comCode');
  const body = {
    assetId: _.get(data, 'assetId'),
    length: _.get(data, 'length'),
    height: _.get(data, 'height'),
    width: _.get(data, 'width'),
  };
  try {
    const response = await httpClient.post(`/v3/item/manager/${comCode}/asset/calculateloaded`, body);
    if (response.status === 200) {
      return response;
    } else {
      return false;
    }
  } catch (error) {
    return error.response;
  }
};

const reCalculate = async (assetid) => {
  const comCode = localStorage.getItem('comCode');

  try {
    const response = await httpClient.post(`/v3/order/manager/${comCode}/recalculateorder/${assetid}`);
    if (response.status === 200) {
      return response;
    } else {
      return false;
    }
  } catch (error) {
    return error.response;
  }
};

const planningOrder = async (payload) => {
  const comCode = localStorage.getItem('comCode');

  try {
    const response = await httpClient.post(`/v5/order/manager/company/${comCode}/web/deliveryorder/planning`, payload);
    if (response.status === 200) {
      return response;
    } else {
      return false;
    }
  } catch (error) {
    return error.response;
  }
};

const replanningOrder = async (payload) => {
  const comCode = localStorage.getItem('comCode');

  try {
    const response = await httpClient.post(`/v5/order/manager/company/${comCode}/web/deliveryorder/replanning`, payload);
    if (response.status === 200) {
      return response;
    } else {
      return false;
    }
  } catch (error) {
    return error.response;
  }
};

const confirmOrder = async (assetid, date,orgid) => {
  const comCode = localStorage.getItem('comCode');

  try {
    const response = await httpClient.put(`/v4/task/manager/${comCode}/asset/loadandroute/comfirmorder/${assetid}/?shippingDate=${date}&orgId=${orgid}`);
    if (response.status === 200) {
      return response;
    } else {
      return false;
    }
  } catch (error) {
    return error.response;
  }
};

const addItem = async (data) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v3/order/manager/${comCode}/createtrip`, data);
    if (response.status === 200) {
      return response;
    } else {
      return false;
    }
  } catch (error) {
    return error.response;
  }
};

const removeItem = async (data, assetId) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.put(`/v3/order/manager/${comCode}/updatetrip/${assetId}`, data);
    if (response.status === 200) {
      return response;
    } else {
      return false;
    }
  } catch (error) {
    return error.response;
  }
};

const replanWaiting = async () => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v4/order/manager/${comCode}/recalculateordeassignedriver`);
    if (response.status === 200) {
      return response;
    } else {
      return false;
    }
  } catch (error) {
    return error.response;
  }
};

const getTeamGroupDriver = async (date) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.get(`/v3/item/manager/${comCode}/asset/searchteamassetbycompany/?shippingDate=${date}`);
    if (response.status === 200) {
      return response;
    } else {
      return false;
    }
  } catch (error) {
    return error.response;
  }
};

const replanDevliveryOrder = async (payload) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v5/order/manager/company/${comCode}/web/deliveryorder/replanningbyindex`, payload);
    if (response.status === 200) {
      return response;
    } else {
      return false;
    }
  } catch (error) {
    return error.response;
  }
};

const clearDevliveryOrder = async (payload) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v5/order/manager/company/${comCode}/web/deliveryorder/clearorders`, payload);
    if (response.status === 200) {
      return response;
    } else {
      return false;
    }
  } catch (error) {
    return error.response;
  }
};

const checkReConfirm = async (payload) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v4/task/manager/${comCode}/asset/checkenable/reconfirm`, payload);
    if (response.status === 200) {
      return response;
    } else {
      return false;
    }
  } catch (error) {
    return error.response;
  }
}


const reConfirmOrder = async (assetId,tripId, shipping, trip,orgid) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.put(`/v4/task/manager/${comCode}/asset/loadandroute/recomfirmorder/${assetId}?tripId=${tripId}&shippingDate=${shipping}&reconfirmTripNo=${trip}&orgId=${orgid}`);
    if (response.status === 200) {
      return response;
    } else {
      return false;
    }
  } catch (error) {
    return error.response;
  }
}



const checkConfirmOrder = async (body) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v4/task/manager/${comCode}/asset/checkqueue/confirm`,body);
    if (response.status === 200) {
      return response;
    } else {
      return false;
    }
  } catch (error) {
    return error.response;
  }
}

const cancelTrip = async (payload) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.put(`/v5/order/manager/company/${comCode}/web/deliveryorder/canceltrip`, payload);
    if (response.status === 200) {
      return response;
    } else {
      return false;
    }
  } catch (error) {
    return error.response;
  }
}

const checkShipInCar = async (body) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v5/order/manager/company/${comCode}/web/deliveryorder/checkShift`,body);
    if (response.status === 200) {
      return response;
    } else {
      return false;
    }
  } catch (error) {
    return error.response;
  }
}

export {
  getVehicle,
  getWaitingOrder,
  getByid,
  saveEditVehical,
  calculateVehicle,
  reCalculate,
  getDeliveryOrder,
  addItem,
  removeItem,
  confirmOrder,
  getByidExport,
  replanWaiting,
  getTeamGroupDriver,
  replanningOrder,
  planningOrder,
  replanDevliveryOrder,
  clearDevliveryOrder,
  checkReConfirm,
  reConfirmOrder,
  checkConfirmOrder,
  cancelTrip,
  checkShipInCar,
};
