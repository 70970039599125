import React, { useState, useEffect } from 'react';
import './index.css';
import { Card, Table, Dropdown, Menu, Button, Row, Col } from 'antd';
import { FormattedMessage } from 'react-intl';
import ReportARAction from './action';
import { useIntl } from 'react-intl';
import _ from 'lodash';
import { useDebounce } from '../../controllers/debounce';
import moment from 'moment';
import { successNotification, errorNotification } from '../../components/v2/notification';
import Button01 from '../../components/v2/button_01';
import { DownOutlined } from '@ant-design/icons';
import { numberFormatter } from '../../component-function/fnc-inputnumber-currency';
import { getReportReceive, exportReportReceive } from '../../controllers/report/accouting-report/api';
import { PageSettings } from '../../config/page-settings';
import { getARReportExportNew, getARReportListNew } from '../../controllers/account-receive';

const ARReportNew = () => {
  const intl = useIntl();

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
      fixed: 'left',
      default: true,
    },
    {
      title: intl.formatMessage({ id: 'ARreportColumnsBlNo', defaultMessage: 'BL No.' }),
      dataIndex: 'billingNo',
      key: 'billingNo',
      fixed: 'left',
      sorter: true,
      width: 180,
      default: true,
    },
    {
      title: intl.formatMessage({ id: 'ARreportColumnsCustomer', defaultMessage: 'Customer' }),
      dataIndex: 'customerName',
      key: 'customerName',
      sorter: true,
      width: 200,
      default: true,
    },
    {
      title: intl.formatMessage({ id: 'ARreportColumnsType', defaultMessage: 'Type' }),
      dataIndex: 'type',
      key: 'type',
      sorter: true,
      width: 150,
      default: true,
    },
    {
      title: intl.formatMessage({ id: 'ARreportColumnsItems', defaultMessage: 'Items' }),
      dataIndex: 'item',
      key: 'item',
      width: 120,
      align: 'right',
      sorter: false,
      default: true,
    },
    {
      title: intl.formatMessage({ id: 'ARreportColumnsTotalAmount', defaultMessage: 'Total Amount' }),
      dataIndex: 'total',
      key: 'total',
      width: 150,
      align: 'right',
      sorter: true,
      default: true,
      render: (text, record, index) => `${numberFormatter(record.total || 0)} THB`,

    },
    {
      title: intl.formatMessage({ id: 'ARreportColumnsPaidAmount', defaultMessage: 'Paid Amount' }),
      dataIndex: 'paidAmount',
      key: 'paidAmount',
      width: 180,
      align: 'right',
      sorter: true,
      default: true,
      render: (text, record, index) => `${numberFormatter(record.paidAmount || 0)} THB`,

    },
    {
      title: intl.formatMessage({ id: 'ARreportColumnsAccruedAmount', defaultMessage: 'Accrued Amount' }),
      dataIndex: 'accrued',
      key: 'accrued',
      width: 180,
      align: 'right',
      sorter: true,
      default: true,
      render: (text, record, index) => `${numberFormatter(record.accrued || 0)} THB`,
    },
    {
      title: intl.formatMessage({ id: 'ARreportColumnsDueDate', defaultMessage: 'Due Date' }),
      dataIndex: 'dueDate',
      key: 'dueDate',
      width: 150,
      sorter: true,
      default: true,
      render: (text, record, index) => moment(record.dueDate, 'YYYY-MM-DD').format('DD/MM/YYYY')
    },
    {
      title: intl.formatMessage({ id: 'ARreportColumnsStatus', defaultMessage: 'Status' }),
      dataIndex: 'statusCode',
      key: 'statusCode',
      width: 200,
      default: true,
      sorter: true,
      render: (text, record, index) => (
        <span>
        <p
          style={{
            // backgroundColor: record['statusColor'],
            backgroundColor: _.get(record, 'colorCode'),
            color: 'white',
            fontSize: '13px',
            textAlign: 'center',
            alignContent: 'center',
            borderRadius: '10px',
            height: '23px',
            width: '100%',
          }}
        >
          {record.statusCode.label}
        </p>
      </span>
      ),
    },
    {
      title: intl.formatMessage({ id: 'ARreportColumnsTeam', defaultMessage: 'Team' }),
      dataIndex: 'team',
      key: 'team',
      // width: 200,
      default: false,
      sorter: true,
    },
    {
      title: intl.formatMessage({ id: 'ARreportColumnsIssuedBy', defaultMessage: 'Issued by' }),
      dataIndex: 'issueBy',
      key: 'issueBy',
      width: 200,
      default: true,
      sorter: true,
    },
    {
      title: intl.formatMessage({ id: 'ARreportColumnsIssuedDate', defaultMessage: 'Issued Date' }),
      dataIndex: 'issueDate',
      key: 'issueDate',
      width: 150,
      default: true,
      sorter: true,
      render: (text, record, index) => moment(record.issueDate, 'YYYY-MM-DD').format('DD/MM/YYYY')
    },
    
  ];
  // const setShowColumnArr = _.map(columns, (item) => {
  //   if(item.default === true) {
  //     return item.dataIndex;
  //   }
  // });

  const setShowColumnArr = _.map(
    _.filter(columns, (item) => {
      return item.default === true;
    }),
    (item) => {
      return item.dataIndex;
    }
  );

  const setShowColumn = columns.reduce((obj, item) => {
    obj[item.dataIndex] = item.default;
    return obj;
  }, {});
  const ignoreColumn = _.map(
    _.filter(columns, (item) => {
      return item.default === false;
    }),
    (item) => {
      return item.dataIndex;
    }
  );

  const newColumns = columns.filter((col) => !ignoreColumn.includes(col.key));
  const [search, setSearch] = useState({ searchdDate: [moment().startOf('month'), moment().endOf('month')] });
  const [newDataColumns, setNewDataColumns] = useState([...newColumns]);
  const [listArrayColumns, setListArrayColumns] = useState([...setShowColumnArr]);
  const [defaultShowColumn, setDefaultShowColumn] = useState({ ...setShowColumn });
  const [textErrorSelectColumn, setTextErrorSelectColumn] = useState('');
  const [visible, setVisible] = useState(false);
  const columnMinimum = 2;
  const [sortOrder, setSortOrder] = useState('');
  const [sortField, setSortField] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [listData, setListData] = useState([]);
  const searchDebounce = useDebounce(search, 500);
  const [isFetch, setIsFetch] = useState(false);
  const [typeList, setTypeList] = useState([])
  const [statusList, setStatusList] = useState([])

  useEffect(() => {
    const getListDate = async () => {
      setLoading(true);
      console.log('search', search)
      const body = {

        billingNo: _.get(search, 'blNo') || undefined,
        customerId: _.get(search, 'customerSearch') || undefined,
        type: _.get(search, 'type') || undefined,
        status: _.get(search, 'status') || undefined,
        issueDate: _.get(search, 'searchdDate[0]') ? moment(_.get(search, 'searchdDate[0]')).format('YYYY-MM-DD') : undefined,
        issueEndDate: _.get(search, 'searchdDate[1]') ? moment(_.get(search, 'searchdDate[1]')).format('YYYY-MM-DD') : undefined,
        dueDate: _.get(search, 'dueDate[0]') ? moment(_.get(search, 'dueDate[0]')).format('YYYY-MM-DD') : undefined,
        dueEndDate: _.get(search, 'dueDate[1]') ? moment(_.get(search, 'dueDate[1]')).format('YYYY-MM-DD') : undefined,

        // where: {
        //   referenceType: _.get(search, 'refType') || undefined,
        //   referenceNo: _.get(search, 'refNo') || undefined,
        //   taxNo: _.get(search, 'taxNo') || undefined,
        //   code: _.get(search, 'customerCode') || undefined,
        //   name: _.get(search, 'customerName') || undefined,
        //   paymentMethod: _.get(search, 'paymentMethod') || undefined,
        //   referenceDate:
        //     _.get(search, 'searchdDate') && _.size(search.searchdDate)
        //       ? {
        //           startDate: moment(_.get(search, 'searchdDate[0]')).format('YYYY-MM-DD'),
        //           endDate: moment(_.get(search, 'searchdDate[1]')).format('YYYY-MM-DD'),
        //         }
        //       : undefined,
        //   receiveDate:
        //     _.get(search, 'receiveDate') && _.size(search.receiveDate)
        //       ? {
        //           startDate: moment(_.get(search, 'receiveDate[0]')).format('YYYY-MM-DD'),
        //           endDate: moment(_.get(search, 'receiveDate[1]')).format('YYYY-MM-DD'),
        //         }
        //       : undefined,
        // },
        page: page,
        limit: size,
        orderBy: sortField,
        orderType: sortOrder,
      };
      console.log('search body', body)

      const response = await getARReportListNew(body);
      console.log('response', response)
      setListData(_.get(response, 'list'));
      setTotal(_.get(response, 'total'));
      setStatusList(_.get(response, 'status'))
      setTypeList(_.get(response, 'type'))
      setLoading(false);
    };
    getListDate();
  }, [page, size, sortOrder, sortField, isFetch, _.get(searchDebounce, 'searchdDate')]);

  const onFilterOrder = async (value, code) => {
    setSearch({ ...search, [code]: value });
    // setPage(1)
  };

  const handleChange = (pagination, sorter, extra) => {
    console.log('handleChange', extra)
    let field = extra.field;
    if (extra.order) {
      if (extra.order === 'descend') {
        setSortOrder('desc');
      } else {
        setSortOrder('asc');
      }
    } else {
      setSortOrder('');
    }

    let newField
    if (field === 'customerName') {
      newField = 'customerDetail.customerName'
    } else if (field === 'type') {
      newField = 'billingItemType'
    } else if (field === 'statusCode') {
      newField = 'statusDetail.statusCode'
    } else if (field === 'issueBy') {
      newField = 'issueDetail.issueBy'
    } else if (field === 'issueDate') {
      newField = 'issueDetail.issueDate'
    }
    else {
      newField = field
    }


    setSortField(newField);
    setPage(pagination.current);
    setSize(pagination.pageSize);
  };

  const showTotal = () => {
    return (
      <span style={{ fontSize: '13px' }}>
        <FormattedMessage id="lblRowPerPage" defaultMessage="Rows per page: " />
      </span>
    );
  };

  const handleExport = async () => {
    try {
      const body = {
        billingNo: _.get(search, 'blNo') || undefined,
        customerId: _.get(search, 'customerSearch') || undefined,
        type: _.get(search, 'type') || undefined,
        status: _.get(search, 'status') || undefined,
        issueDate: _.get(search, 'searchdDate[0]') ? moment(_.get(search, 'searchdDate[0]')).format('YYYY-MM-DD') : undefined,
        issueEndDate: _.get(search, 'searchdDate[1]') ? moment(_.get(search, 'searchdDate[1]')).format('YYYY-MM-DD') : undefined,
        dueDate: _.get(search, 'dueDate[0]') ? moment(_.get(search, 'dueDate[0]')).format('YYYY-MM-DD') : undefined,
        dueEndDate: _.get(search, 'dueDate[1]') ? moment(_.get(search, 'dueDate[1]')).format('YYYY-MM-DD') : undefined,

        page: 1,
        limit: total,
        orderBy: sortField,
        orderType: sortOrder,


        viewFormat: [
          {
            colName: intl.formatMessage({ id: 'ARreportColumnsBlNo', defaultMessage: 'BL No.' }),
            sequence: 1,
            colCode: 'billingNo',
          },
          {
            colName: intl.formatMessage({ id: 'ARreportColumnsCustomer', defaultMessage: 'Customer' }),
            sequence: 2,
            colCode: 'customerName',
          },
          {
            colName: intl.formatMessage({ id: 'ARreportColumnsType', defaultMessage: 'Type' }),
            sequence: 3,
            colCode: 'type',
          },
          {
            colName: intl.formatMessage({ id: 'ARreportColumnsItems', defaultMessage: 'Items' }),
            sequence: 4,
            colCode: 'item',
          },
          {
            colName: intl.formatMessage({ id: 'ARreportColumnsTotalAmount', defaultMessage: 'Total Amount' }),
            sequence: 5,
            colCode: 'total',
          },
          {
            colName: intl.formatMessage({ id: 'ARreportColumnsPaidAmount', defaultMessage: 'Paid Amount' }),
            sequence: 6,
            colCode: 'paidAmount',
          },
          {
            colName: intl.formatMessage({ id: 'ARreportColumnsAccruedAmount', defaultMessage: 'Accrued Amount' }),
            sequence: 7,
            colCode: 'accrued',
          },
          {
            colName: intl.formatMessage({ id: 'ARreportColumnsDueDate', defaultMessage: 'Due Date' }),
            sequence: 8,
            colCode: 'dueDate',
          },
          {
            colName: intl.formatMessage({ id: 'ARreportColumnsStatus', defaultMessage: 'Status' }),
            sequence: 9,
            colCode: 'statusCode',
          },
          {
            colName: intl.formatMessage({ id: 'ARreportColumnsTeam', defaultMessage: 'Team' }),
            sequence: 10,
            colCode: 'team',
          },
          {
            colName: intl.formatMessage({ id: 'ARreportColumnsIssuedBy', defaultMessage: 'Issued by' }),
            sequence: 11,
            colCode: 'issueBy',
          },
          {
            colName: intl.formatMessage({ id: 'ARreportColumnsIssuedDate', defaultMessage: 'Issued Date' }),
            sequence: 12,
            colCode: 'issueDate',
          },
        ],
      };
      // const response = await httpClient.post(`/v1/resource/manager/company/${state.comId}/export/report/overtime`, body);
      const response = await await getARReportExportNew(body);
      console.log('getARReportExportNew', response)

        window.open(response.data.url);
        successNotification(response.status.message);

    } catch (error) {
      errorNotification(error.response.status.message);
    }
  };

  const menuColumn = (
    <Menu>
      <Menu.Item>
        <Button style={{ padding: 'unset' }} ghost type="link" onClick={handleExport}>
          <FormattedMessage id="expenseBtnExport" defaultMessage="Export Excel" />
        </Button>
      </Menu.Item>
    </Menu>
  );

  return (
    <PageSettings.Consumer>
      {({ checkPermissionAction }) =>
        checkPermissionAction(`P1PG1`, `P1PG1A1`) ? (
          <Card
            className="right-cards scroll-sm"
            title={<FormattedMessage id="ARReport" defaultMessage="AR Report" />}
            bodyStyle={{ padding: 'unset', height: 'calc(100vh - 170px)', overflowY: 'auto' }}
            extra={
              <Dropdown overlay={menuColumn} trigger={['click']} placement="bottomRight">
                <Button01 key="submit" type="primary" btnsize="wd_df">
                  <FormattedMessage id="btnActionGroup" defaultMessage="Action" />
                  <DownOutlined style={{ fontSize: '14px', paddingLeft: '22%' }} />
                </Button01>
              </Dropdown>
            }
          >
            <ReportARAction
              search={search}
              setSearch={setSearch}
              onFilterOrder={onFilterOrder}
              statusList={statusList}
              typeList={typeList}
              setPage={setPage}
              setIsFetch={setIsFetch}
              actionColumns={{
                columns,
                setShowColumn,
                newDataColumns,
                setNewDataColumns,
                setListArrayColumns,
                setDefaultShowColumn,
                listArrayColumns,
                defaultShowColumn,
                newColumns,
                textErrorSelectColumn,
                setTextErrorSelectColumn,
                visible,
                setVisible,
                columnMinimum,
              }}
            />
            <div style={{ padding: '16px 24px' }}>
              <Table
                dataSource={listData}
                columns={newDataColumns}
                scroll={{ x: true }}
                onChange={handleChange}
                loading={loading}
                pagination={{
                  total: total,
                  showTotal: showTotal,
                  defaultCurrent: 1,
                  current: page,
                  pageSizeOptions: ['10', '20', '30', '40', '50'],
                  showSizeChanger: true,
                  locale: { items_per_page: '' },
                }}
              />
              {_.size(listData) > 0 ? (
                <div className="total-items-member">
                  <span style={{ fontSize: '13px' }}>
                    <FormattedMessage id="lblTotal" defaultMessage="Total" />
                    {` ${total || 0} `}
                    <FormattedMessage id="lblitems" defaultMessage="items" />
                  </span>
                </div>
              ) : null}
            </div>
          </Card>
        ) : null
      }
    </PageSettings.Consumer>
  );
};

export default ARReportNew;
