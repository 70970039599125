import React, { useState, useContext, useEffect } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import DeliveryShipmentAction from './component/action';
import _ from 'lodash';
import { Button, Table, Tag } from 'antd';
import { DeliveryContext } from '../..';
import DeliveryCreateOrderForm from '../../../../components/modal-create-order';
import { successNotification, errorNotification } from '../../../../components/v2/notification';
import moment from 'moment';
import { numberFormatter } from '../../../../component-function/fnc-inputnumber-currency';
import CustomTableComponent from './component/table';
import httpClient from '../../../../components/axiosClient';
import DeliveryViewOrder from '../../../../components/view-item-order-delivery';
import { getChangeNextStatus } from '../../../../controllers/orders-controller/api';
import ModalImportOrder from '../../../../components/modal-import-order';

export const DeliveryShipmentContext = React.createContext();

const DeliveryShipment = () => {
  const intl = useIntl();
  const {
    handleClickShipment,
    visibleCreateOrder,
    setVisibleCreateOrder,
    statusData,
    selectBranchData,
    orderList,
    orderTotal,
    searchShipment,
    setTriggerOrder,
    orderData,
    openImport,
    setOpenImport,
    trigger,
    setTrigger,
    loadingOrder,
    customerData,
    onFilterOrder,
    searchOrder,
    setSearchOrder,
    selectedRowKeys,
    setSelectedRowKeys,
    listCreate,
    setListCreate,
    visibleViewOrder,
    setVisibleViewOrder,
    viewData,
    setViewData,
  } = useContext(DeliveryContext);

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
      fixed: 'left',
      default: true,
    },
    {
      // title: intl.formatMessage({ id: 'deliveryShipmentColumnsDO', defaultMessage: 'DO No.' }),
      title: <FormattedMessage id="deliveryShipmentColumnsDO" defaultMessage="DO No." />,
      dataIndex: 'orderNo',
      key: 'orderNo',
      fixed: 'left',
      sorter: true,
      width: 150,
      default: true,
    },
    {
      title: <FormattedMessage id="deliveryShipmentColumnsOrderType" defaultMessage="Order Type" />,
      dataIndex: 'orderTypeCode',
      key: 'orderTypeCode',
      sorter: true,
      width: 200,
      default: true,
    },
    {
      title: <FormattedMessage id="deliveryShipmentColumnsType" defaultMessage="Type" />,
      dataIndex: 'detailTypeTxt',
      key: 'detailTypeTxt',
      sorter: true,
      width: 150,
      default: true,
    },
    {
      title: <FormattedMessage id="deliveryShipmentColumnsCustomer" defaultMessage="Customer" />,
      dataIndex: 'customerName',
      key: 'customerName',
      width: 200,
      sorter: true,
      default: true,
    },
    {
      title: <FormattedMessage id="deliveryShipmentColumnsContact" defaultMessage="Contact" />,
      dataIndex: 'contact',
      key: 'contact',
      width: 200,
      sorter: true,
      default: true,
    },

    {
      title: <FormattedMessage id="deliveryShipmentColumnsPhone" defaultMessage="Phone" />,
      dataIndex: 'customerContactPhone',
      key: 'customerContactPhone',
      width: 150,
      sorter: true,
      default: false,
    },
    {
      title: <FormattedMessage id="deliveryShipmentColumnsOrderStatus" defaultMessage="Order Status" />,
      dataIndex: 'orderStatus',
      key: 'orderStatus',
      width: 150,
      sorter: true,
      default: true,
      render: (text, record, index) => (
        <div className="tag-center-div">
          <Tag className="tag-center-style" color={record.statusColor} style={{ minWidth: '100px', width: '100%' }}>
            {record.orderStatus}
          </Tag>
        </div>
      ),
    },
    {
      title: <FormattedMessage id="deliveryColumnsTotalItem" defaultMessage="Total Item" />,
      dataIndex: 'parcel',
      key: 'parcel',
      width: 150,
      sorter: true,
      default: true,
    },
    {
      title: <FormattedMessage id="deliveryShipmentDestination" defaultMessage="Destination" />,
      dataIndex: 'destination',
      key: 'destination',
      width: 150,
      default: true,
      ellipsis: true,
    },
    {
      title: <FormattedMessage id="deliveryColumnsDestinationAddress" defaultMessage="Destination Address" />,
      dataIndex: 'destinationAddress',
      key: 'destinationAddress',
      width: 350,
      default: true,
      ellipsis: true,
      render: (text) => {
        const displayText = text.length > 45 ? text.slice(0, 45) + '...' : text;
        return displayText;
      },
    },
    {
      title: <FormattedMessage id="deliveryColumnsDestinationPostcode" defaultMessage="Destination Postcode" />,
      dataIndex: 'destinationPostcode',
      key: 'destinationPostcode',
      width: 180,
      default: false,
    },
    {
      title: <FormattedMessage id="deliveryShipmentColumnsDeliveryDate" defaultMessage="Delivery Date" />,
      dataIndex: 'deliveryDate',
      key: 'deliveryDate',
      width: 150,
      default: true,
      // render: (text, record, index) => (record.deliveryDate ? moment(record.deliveryDateTxt, 'YYYY-MM-DD').format('DD/MM/YYYY') : ''),
    },
    {
      title: <FormattedMessage id="deliveryShipmentColumnsDeliveryTime" defaultMessage="Delivery Time" />,
      dataIndex: 'deliveryTime',
      key: 'deliveryTime',
      width: 150,
      default: true,
    },
    {
      title: <FormattedMessage id="deliveryShipmentColumnsSourceName" defaultMessage="Source Name" />,
      dataIndex: 'source',
      key: 'source',
      width: 250,
      default: false,
    },
    {
      title: <FormattedMessage id="deliveryShipmentColumnsSourceAddress" defaultMessage="Source Address" />,
      dataIndex: 'sourceAddress',
      key: 'sourceAddress',
      width: 350,
      default: false,
      ellipsis: true,
      render: (text) => {
        const displayText = text.length > 45 ? text.slice(0, 45) + '...' : text;
        return displayText;
      },
    },
    {
      title: <FormattedMessage id="deliveryColumnsSourcePostcode" defaultMessage="Source Postcode" />,
      dataIndex: 'sourcePostcode',
      key: 'sourcePostcode',
      width: 180,
      default: false,
    },
    {
      title: <FormattedMessage id="deliveryColumnsStore" defaultMessage="Store" />,
      dataIndex: 'branch',
      key: 'branch',
      width: 180,
      default: true,
    }, {
      title: <FormattedMessage id="deliveryColumnsSoNo" defaultMessage="SO No" />,
      dataIndex: 'saleOrderNo',
      key: 'saleOrderNo',
      width: 180,
      default: true,
    },
    {
      title: <FormattedMessage id="deliveryShipmentColumnsReference" defaultMessage="Reference No." />,
      dataIndex: 'referenceNo',
      key: 'referenceNo',
      width: 150,
      default: false,
    },
    {
      title: <FormattedMessage id="deliveryShipmentColumnsRemark" defaultMessage="Remark" />,
      dataIndex: 'remark',
      key: 'remark',
      width: 300,
      default: false,
    },
  ];

  const setShowColumnArr = _.map(columns, (item) => {
    return item.dataIndex;
  });
  const setShowColumn = columns.reduce((obj, item) => {
    obj[item.dataIndex] = item.default;
    return obj;
  }, {});
  // const ignoreColumn = ['payment'];
  const ignoreColumn = _.map(
    _.filter(columns, (item) => {
      return item.default === false;
    }),
    (item) => {
      return item.dataIndex;
    }
  );
  console.log('setShowColumn', ignoreColumn);

  const newColumns = columns.filter((col) => !ignoreColumn.includes(col.key));
  const [searchGroup, setSearchGroup] = useState({});
  const [defaultShowColumn, setDefaultShowColumn] = useState({ ...setShowColumn });
  const [newDataColumns, setNewDataColumns] = useState([...newColumns]);
  const [listArrayColumns, setListArrayColumns] = useState([...setShowColumnArr]);
  const [textErrorSelectColumn, setTextErrorSelectColumn] = useState('');
  const [visible, setVisible] = useState(false);
  const columnMinimum = 2;
  //----------------------------------------------
  const [editData, setEditData] = useState({});

  const handleChange = (pagination, sorter, extra) => {
    let field = extra.field;
    if (extra.order) {
      if (extra.order === 'descend') {
        searchShipment.setShipmentOrder('desc');
      } else {
        searchShipment.setShipmentOrder('asc');
      }
    } else {
      searchShipment.setShipmentOrder();
    }
    searchShipment.setShipmentField(field);
    searchShipment.setShipmentPage(pagination.current);
    searchShipment.setShipmentSize(pagination.pageSize);
  };

  const handleDownLoadTemplate = (status) => {
    let url = '';

    if (status === 'parcel') {
      url = '/file/excel/template-import-DO-parcel';
    } else if (status === 'item') {
      url = '/file/excel/template-import-DO-item';
    }

    httpClient({
      url: url,
      method: 'GET',
      responseType: 'blob', // important for file downloads
    })
      .then((response) => {
        const href = URL.createObjectURL(response.data);

        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', `template-DO-${status}.xlsx`);
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      })
      .catch((error) => {
        console.error('Failed to download template:', error);
      });
  };

  console.log('listCreate', listCreate, selectedRowKeys);

  // id === _id ถ้าต้องการเปลี่ยน เปลี่ยน _id ที่ CustomTableComponent ด้วย
  const rowSelection = {
    selectedRowKeys: selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      console.log('pending start', selectedRowKeys, selectedRows, listCreate);
      let selectList = [];

      const orderStatusCode =
        _.size(listCreate) > 0 ? _.get(listCreate, '[0].orderStatusCode') : _.get(selectedRows, '[0].orderStatusCode');
      const selectedRowsNew =
        _.size(listCreate) === 0 ? _.filter(selectedRows, (row) => row.orderStatusCode === orderStatusCode) : selectedRows;
      const selectedRowKeysNew =
        _.size(listCreate) === 0 ? _.filter(selectedRowKeys, (_id) => _.some(selectedRowsNew, { _id })) : selectedRowKeys;

      setSelectedRowKeys(selectedRowKeysNew);

      // for (let i = 0; _.size(selectedRowKeysNew) > i; i++) {
      //   const checkItemAdd = _.filter(listCreate, (o) => o._id === selectedRowKeysNew[i]); // check item Status
      //   console.log('pending roung', i + 1, '-----------------------------------------------------------');
      //   console.log('pending 1 Check', checkItemAdd, _.size(checkItemAdd) === 0, listCreate, selectedRowKeysNew[i]);
      //   if (_.size(checkItemAdd) === 0) {
      //     // not item Status (Add item)
      //     const item = _.filter(selectedRowsNew, (item) => item._id === selectedRowKeysNew[i]);
      //     console.log('pending 2 Item', item);
      //     if (_.size(item) > 0) {
      //       console.log('pending 3 add item', item);
      //       selectList = _.size(selectList) ? [...selectList, item[0]] : item;
      //     }
      //   } else {
      //     const filterItem = _.filter(listCreate, (item) => item._id === selectedRowKeysNew[i]);
      //     if (_.size(filterItem)) {
      //       console.log('pending 4 delete item', filterItem);
      //       selectList = _.size(selectList) ? [...selectList, filterItem[0]] : filterItem;
      //     }
      //   }

      //   const item = _.filter(selectedRowsNew, (item) => item._id === selectedRowKeysNew[i]);
      //   console.log('pending 2 Item', item);
      //   if (_.size(item) > 0) {
      //     console.log('pending 3 add item', item);
      //     selectList = _.size(selectList) ? [...selectList, item[0]] : item;
      //   }
      // }

      console.log('selectedRowKeysNew', selectedRowKeysNew);

      console.log('pending end SelectList', selectList, selectedRowKeysNew);

      setListCreate(selectedRowsNew);
    },
    getCheckboxProps: (record) => ({
      disabled: _.get(listCreate, '[0]orderStatusCode')
        ? _.get(listCreate, '[0]orderStatusCode') !== _.get(record, 'orderStatusCode')
        : null,
      // invoiceId: record.invoiceId,
    }),
  };

  const contentAction = (record) => {
    console.log('contentAction', record);
    return (
      <div style={{ display: 'grid' }}>
        <div>
          <Button style={{ width: 100 }} type="link" ghost onClick={() => handleOpenView(record)}>
            <FormattedMessage id="orgTabMemberBtnView" defaultMessage="View" />
          </Button>
        </div>
        {/* <div>
          <Button style={{ width: 100 }} type="link" ghost onClick={() => deleteAtt(record.id)}>
            <FormattedMessage id="btnDelete" defaultMessage="Delete" />
          </Button>
        </div> */}
      </div>
    );
  };

  const handleOpenView = async (record) => {
    console.log('handleOpenView', record);
    setViewData(record);
    setVisibleViewOrder(true);
  };

  const onEdit = (data) => {
    setVisibleCreateOrder(true)
    setEditData(data)
  };

  const onCancel = () => {
    setViewData()
    setVisibleViewOrder(false)
  }

  return (
    <DeliveryShipmentContext.Provider
      value={{
        searchGroup,
        setSearchGroup,
        columns,
        setShowColumn,
        newDataColumns,
        listArrayColumns,
        defaultShowColumn,
        newColumns,
        setNewDataColumns,
        setListArrayColumns,
        setDefaultShowColumn,
        textErrorSelectColumn,
        setTextErrorSelectColumn,
        visible,
        setVisible,
        columnMinimum,
        searchShipment,
        orderData,
        customerData,
        onFilterOrder,
        searchOrder,
        setSearchOrder,
        setTrigger,
      }}
    >
      <div style={{ height: 'calc(100vh - 170px)', overflowY: 'auto' }}>
        <DeliveryShipmentAction />
        <div style={{ padding: '16px 24px' }}>
          <CustomTableComponent
            rowKey={(record) => record._id}
            rowSelection={rowSelection}
            columns={newDataColumns}
            dataSource={orderList}
            scroll={{ x: true }}
            role={true}
            onRow={true}
            total={orderTotal}
            loading={loadingOrder}
            paginationPage={searchShipment.shipmentPage}
            paginationSize={searchShipment.shipmentSize}
            // setFieldSort={handleFieldSort}
            // setOrderSort={handleOrderSort}
            // setPaginationSize={handlePaginationSize}
            // setPaginationPage={handlePaginationPage}
            contentAction={contentAction}
            handleChange={handleChange}
          />
        </div>

        <DeliveryCreateOrderForm
          visible={visibleCreateOrder}
          setVisible={setVisibleCreateOrder}
          setTrigger={setTrigger}
          orderData={orderData}
          editData={editData}
          setEditData={setEditData}
        />

        {/* <ModalImportOrder open={openImport} setOpen={setOpenImport} handleDownLoadTemplate={handleDownLoadTemplate} orderData={orderData} /> */}
        <ModalImportOrder open={openImport} setOpen={setOpenImport} orderData={orderData} setTrigger={setTrigger}/>

        <DeliveryViewOrder
          visible={visibleViewOrder}
          // setVisible={setVisibleViewOrder}
          data={viewData}
          onEdit={onEdit}
          trigger={trigger}
          setTrigger={setTrigger}
          onCancel={onCancel}
        />
      </div>
    </DeliveryShipmentContext.Provider>
  );
};

export default DeliveryShipment;
