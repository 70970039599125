import React, { useContext, useEffect, useState } from 'react';
import ModalSettting from '../components/modal';
import { Card, Dropdown, Menu, Button, Icon, Popover, Divider, InputNumber, Modal, Form } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import { DownOutlined } from '@ant-design/icons';
import TableTransporationSetting from '../components/table';
import FilterSetting from '../components/filterSetting';
import _ from 'lodash';
import { getGasPrice, editFreightPrice, deleteGasPrice, settingGasPriceExport } from '../../../controllers/task/setting';
import { numberFormatter } from '../../../component-function/fnc-inputnumber-currency';
import moment from 'moment';
import Button01 from '../../../components/v2/button_01';
import Button02 from '../../../components/v2/button_02';
import { handleKeyDownDecimal2Fixed } from '../../../component-function/fnc-number';
import { errorNotification, successNotification } from '../../../components/v2/notification';
import SuffixInput from '../../../components/v2/suffix';
import { PageSettings } from '../../../config/page-settings';

const PageFreight = () => {
  const intl = useIntl();
  const app = useContext(PageSettings);
  const { checkPermissionAction } = app;
  const memComId = localStorage.getItem('memComId');
  const name = localStorage.getItem('name');
  const [visible, setVisible] = useState(false);
  const [search, setSearch] = useState();
  const [isFetch, setIsFetch] = useState(false);
  const [editData, setEditData] = useState();
  const [tableChange, setTableChange] = useState({
    page: 1,
    size: 10,
    loading: false,
    sortField: undefined,
    sortOrder: undefined,
  });
  const [listData, setListData] = useState([]);
  const [listTotal, setListTotal] = useState(0);
  const [freightData, setFreightData] = useState();
  const [editFreight, setEditFreight] = useState(false);
  const [freightPrice, setFreightPrice] = useState(0);

  useEffect(() => {
    const getData = async () => {
      try {
        const body = {
          where: {
            'customerDetail.id': _.get(search, 'customer.customerId')
              ? _.get(search, 'customer.customerId') || undefined
              : _.get(search, 'customerSearch') === 'notSpecified'
                ? null
                : _.get(search, 'customerSearch'),
            'projectDetail.id': _.get(search, 'project.projectId')
              ? _.get(search, 'project.projectId') || undefined
              : _.get(search, 'projectSearch') === 'notSpecified'
                ? null
                : _.get(search, 'projectSearch'),
            'taskTypeDetail.id': _.get(search, 'taskType', undefined) === 'notSpecified' ? null : _.get(search, 'taskType'),
            'sourceLocationDetail.name': _.get(search, 'source')
              ? {
                $regex: _.get(search, 'source'),
              }
              : undefined,
            'destinationLocationDetail.name': _.get(search, 'destination')
              ? {
                $regex: _.get(search, 'destination'),
              }
              : undefined,
          },
          page: _.get(tableChange, 'page'),
          pageSize: _.get(tableChange, 'size'),
          orderBy: _.get(tableChange, 'sortField'),
          order: _.get(tableChange, 'sortOrder') !== '' ? _.get(tableChange, 'sortOrder') : undefined,
        };
        const response = await getGasPrice(body);
        setListData(_.get(response, 'data.data.docs'));
        setListTotal(_.get(response, 'data.data.total'));
        setFreightData(_.get(response, 'data.data.freightConfig'));
        setFreightPrice(_.get(response, 'data.data.freightConfig.price'));
      } catch (error) {
        console.log('tryCatch', error);
      }
    };

    getData();
  }, [isFetch, tableChange]);

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
      fixed: 'left',
      default: true,
    },
    {
      title: intl.formatMessage({ id: 'freightColumnCustomer', defaultMessage: 'Customer' }),
      dataIndex: 'customerDetail.name',
      key: 'customerDetail.name',
      sorter: true,
      default: true,
      fixed: 'left',
      width: 150,
      render: (text, record, index) => _.get(record, 'customerDetail.name', 'ไม่กำหนด') || 'ไม่กำหนด',
    },
    {
      title: intl.formatMessage({ id: 'freightColumnProject', defaultMessage: 'Project' }),
      dataIndex: 'projectDetail.name',
      key: 'projectDetail.name',
      sorter: true,
      default: false,
      width: 200,
      render: (text, record, index) => (
        <>
          {_.get(record, 'projectDetail.no') !== 'ไม่กำหนด' ? _.get(record, 'projectDetail.no', '') + ' ' + _.get(record, 'projectDetail.name', '') : _.get(record, 'projectDetail.no')}
        </>
      ),
    },
    {
      title: intl.formatMessage({ id: 'freightColumnTaskType', defaultMessage: 'Task Type' }),
      dataIndex: 'taskTypeDetail.name',
      key: 'taskTypeDetail.name',
      sorter: true,
      default: true,
      width: 150,
      render: (text, record, index) => _.get(record, 'taskTypeDetail.name', 'ไม่กำหนด') || 'ไม่กำหนด',
    },
    {
      title: intl.formatMessage({ id: 'freightColumnSource', defaultMessage: 'Source' }),
      dataIndex: 'sourceLocationDetail.name',
      key: 'sourceLocationDetail.name',
      default: true,
      width: 500,
      render: (text, record, index) => (
        <>
          {_.get(record, 'sourceLocationDetail.id') ? _.get(record, 'sourceLocationDetail.name') + ' ' + _.get(record, 'sourceLocationDetail.fullName', '') : _.get(record, 'sourceLocationDetail.name')}
        </>
      ),
    },
    {
      title: intl.formatMessage({ id: 'freightColumnDestination', defaultMessage: 'Destination' }),
      dataIndex: 'destinationLocationDetail.name',
      key: 'destinationLocationDetail.name',
      default: true,
      width: 500,
      render: (text, record, index) => (
        <>
          {_.get(record, 'destinationLocationDetail.id') ? _.get(record, 'destinationLocationDetail.name') + ' ' + _.get(record, 'destinationLocationDetail.fullName', '') : _.get(record, 'destinationLocationDetail.name')}
        </>
      ),
    },
    {
      title: intl.formatMessage({ id: 'freightColumnGMinimum', defaultMessage: 'Gas Price (Minimum)' }),
      dataIndex: 'detail.minPrice',
      key: 'detail.minPrice',
      align: 'right',
      sorter: true,
      default: false,
      width: 200,
      render: (text, record, index) => numberFormatter(_.get(record, 'detail.minPrice', 0)) + ' THB',
    },
    {
      title: intl.formatMessage({ id: 'freightColumnGMaximum', defaultMessage: 'Gas Price (Maximum)' }),
      dataIndex: 'detail.maxPrice',
      key: 'detail.maxPrice',
      align: 'right',
      sorter: true,
      default: false,
      width: 200,
      render: (text, record, index) => numberFormatter(_.get(record, 'detail.maxPrice', 0)) + ' THB',
    },
    {
      title: intl.formatMessage({ id: 'freightColumnPricePerTrip', defaultMessage: 'Price per Trip' }),
      dataIndex: 'detail.price',
      key: 'detail.price',
      align: 'right',
      sorter: true,
      default: false,
      width: 150,
      render: (text, record, index) => numberFormatter(_.get(record, 'detail.price', 0)) + ' THB',
    },
    {
      title: intl.formatMessage({ id: 'freightColumnUpdateby', defaultMessage: 'Update By' }),
      dataIndex: 'updatedName',
      key: 'updatedName',
      sorter: true,
      default: false,
      width: 150,
      render: (text, record, index) => _.get(record, 'updatedName') || _.get(record, 'createdName'),
    },
    {
      title: intl.formatMessage({ id: 'freightColumnUpdateDate', defaultMessage: 'Update Date' }),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      sorter: true,
      default: true,
      width: 150,
      render: (text, record, index) => moment.unix(_.get(record, 'updatedAt')).format('DD/MM/YYYY'),
    },
  ];

  const menuColumn = (
    <Menu>
      {checkPermissionAction('P79PG1C1', 'P79PG1C1A2') ? (
        <Menu.Item>
          <Button style={{ padding: 'unset' }} ghost type="link" onClick={() => setVisible(true)}>
            <FormattedMessage id="freightCreate" defaultMessage="Create Freight" />
          </Button>
        </Menu.Item>
      ) : null}
      <Menu.Item>
        <Button style={{ padding: 'unset' }} ghost type="link" onClick={() => handleExport()}>
          <FormattedMessage id="btnExport" defaultMessage="Export" />
        </Button>
      </Menu.Item>
    </Menu>
  );

  const handleApply = () => {
    setIsFetch((event) => !event);
    setTableChange((prev) => ({ ...prev, page: 1 }));
  };

  const handleReset = () => {
    setIsFetch((event) => !event);
    setSearch((prev) => ({ customer: undefined, project: undefined, taskType: undefined, source: '', destination: '' }));
    setTableChange((prev) => ({ ...prev, page: 1 }));
  };

  const onFilterOrder = async (value, code) => {
    setSearch({ ...search, [code]: value });
  };

  const handleEdit = (value) => {
    setEditData(value);
    setVisible(true);
  };

  const currencyFormatter = (value) => {
    if (parseFloat(value) >= 0) {
      return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
  };

  const currencyParser = (value) => {
    if (parseFloat(value) >= 0) {
      return value.replace(/\$\s?|(,*)/g, '');
    }
  };

  const handleDeleteFreight = (id) => {
    Modal.confirm({
      className: 'maintenance-report-modal-confirm',
      centered: true,
      icon: null,
      title: intl.formatMessage({ id: 'lblDelete', defaultMessage: 'Are you sure you want to delete ?' }),
      okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        const body = {
          updatedBy: parseFloat(memComId),
          updatedName: name,
        };
        const response = await deleteGasPrice(body, id);
        if (_.get(response, 'data.status.code') === 200) {
          successNotification(_.get(response, 'data.status.message'));
          setIsFetch((current) => !current);
        } else {
          errorNotification(_.get(response, 'data.status.message'));
        }
      },
      onCancel() { },
    });
  };

  const contentAction = (record) => {
    return (
      <div style={{ display: 'grid' }}>
        {checkPermissionAction('P79PG1C1', 'P79PG1C1A3') ? (
          <div>
            <Button style={{ width: 100 }} type="link" ghost onClick={() => handleEdit(record)}>
              <FormattedMessage id="btnEdit" defaultMessage="Edit" />
            </Button>
          </div>
        ) : null}
        {checkPermissionAction('P79PG1C1', 'P79PG1C1A4') ? (
          <div>
            <Button style={{ width: 100 }} type="link" ghost onClick={() => handleDeleteFreight(record._id)}>
              <FormattedMessage id="btnDelete" defaultMessage="Delete" />
            </Button>
          </div>
        ) : null}
      </div>
    );
  };

  const handleSaveFreight = async () => {
    const body = {
      comCode: localStorage.getItem('comCode'),
      price: freightPrice,
      updatedBy: parseFloat(memComId),
      updatedName: name,
    };
    const response = await editFreightPrice(body);
    if (_.get(response, 'data.status.code') === 200) {
      successNotification(_.get(response, 'data.status.message'));
      setIsFetch((cur) => !cur);
      setEditFreight(false);
    } else {
      errorNotification(_.get(response, 'data.status.message'));
    }
  };

  const handleCloseFreight = () => {
    setEditFreight(false);
    setFreightPrice(_.get(freightData, 'price'));
  };

  const FreightContent = (
    <div style={{ width: '420px' }}>
      <div style={{ padding: '24px' }}>
        <div style={{ color: '#1D3557', fontSize: '12px' }}>
          <FormattedMessage id="menuGasPriceToday" defaultMessage="Today's fuel price" />
        </div>
        <div style={{ marginTop: '4px' }}>
          <Form.Item>
            <InputNumber
              className="disabled-handle-count"
              value={freightPrice}
              onChange={(e) => setFreightPrice(parseFloat(e || 0))}
              onKeyDown={handleKeyDownDecimal2Fixed}
              style={{ width: '100%' }}
              suffix="THB"
              formatter={currencyFormatter}
              parser={currencyParser}
            />
            <SuffixInput text="THB" />
          </Form.Item>
        </div>
        <div style={{ marginTop: '8px', fontSize: '12px' }}>
          <FormattedMessage id="menuGasPriceLastUpdate" defaultMessage="Latest update" />: {_.get(freightData, 'updatedName')}{' '}
          {moment.unix(_.get(freightData, 'updatedAt')).format('DD/MM/YYYY HH:mm')}
        </div>
      </div>
      <Divider type="horizontal" style={{ margin: 'unset' }} />
      <div style={{ padding: '12px', display: 'flex', justifyContent: 'end' }}>
        <Button02 key="back" btnsize="wd_df" style={{ margin: 'unset' }} onClick={handleCloseFreight}>
          <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
        </Button02>
        <Button01
          key="submit"
          type="primary"
          btnsize="wd_df"
          disabled={_.get(freightData, 'price') === freightPrice ? true : false}
          onClick={handleSaveFreight}
        >
          <FormattedMessage id="btnSave" defaultMessage="Save" />
        </Button01>
      </div>
    </div>
  );

  const extra = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '240px', marginRight: '12px' }}>
      <div>
        <FormattedMessage id="menuGasPriceTodayTHB" defaultMessage="Today's fuel price (THB)" /> :
      </div>
      <div>{numberFormatter(_.get(freightData, 'price', 0).toFixed(2))}</div>
      <Popover visible={editFreight} placement="bottomRight" content={FreightContent}>
        <div style={{ cursor: 'pointer' }} onClick={() => setEditFreight((cur) => !cur)}>
          <Icon type="edit" style={{ color: '#1D3557' }} />
        </div>
      </Popover>
    </div>
  );

  console.log('tableChange', tableChange);

  const handleExport = async () => {
    try {
      let distance = [];

      if (_.get(search, 'distance1')) {
        distance.push({
          'detail.distance': {
            $gte: parseFloat(_.get(search, 'distance1')),
          },
        });
      }

      if (_.get(search, 'distance2')) {
        distance.push({
          'detail.distance': {
            $lte: parseFloat(_.get(search, 'distance2')),
          },
        });
      }
      const body = {
        where: {
          'customerDetail.id': _.get(search, 'customer.customerId')
            ? _.get(search, 'customer.customerId') || undefined
            : _.get(search, 'customerSearch') === 'notSpecified'
              ? null
              : _.get(search, 'customerSearch'),
          'projectDetail.id': _.get(search, 'project.projectId')
            ? _.get(search, 'project.projectId') || undefined
            : _.get(search, 'projectSearch') === 'notSpecified'
              ? null
              : _.get(search, 'projectSearch'),
          'taskTypeDetail.id': _.get(search, 'taskType', undefined) === 'notSpecified' ? null : _.get(search, 'taskType'),
          'sourceLocationDetail.name': _.get(search, 'source')
            ? {
              $regex: _.get(search, 'source'),
            }
            : undefined,
          'destinationLocationDetail.name': _.get(search, 'destination')
            ? {
              $regex: _.get(search, 'destination'),
            }
            : undefined,
          $and: _.size(distance) ? distance : undefined,
        },
        page: 1,
        pageSize: listTotal,
        orderBy: _.get(tableChange, 'sortField') ? _.get(tableChange, 'sortField') : undefined,
        order: _.get(tableChange, 'sortOrder') !== '' ? _.get(tableChange, 'sortOrder') : undefined,

        viewFormat: [
          {
            colName: intl.formatMessage({ id: 'AllowanceSettingColumnCustomer', defaultMessage: 'Customer' }),
            sequence: 1,
            colCode: 'customerDetail.name',
          },
          {
            colName: intl.formatMessage({ id: 'AllowanceSettingColumnProject', defaultMessage: 'Project' }),
            sequence: 2,
            colCode: 'projectDetail.name',
          },
          {
            colName: intl.formatMessage({ id: 'AllowanceSettingColumnTaskType', defaultMessage: 'Task Type' }),
            sequence: 3,
            colCode: 'taskTypeDetail.name',
          },
          {
            colName: intl.formatMessage({ id: 'AllowanceSettingColumnSource', defaultMessage: 'Source' }),
            sequence: 4,
            colCode: 'sourceLocationDetail.name',
          },
          {
            colName: intl.formatMessage({ id: 'AllowanceSettingColumnDestination', defaultMessage: 'Destination' }),
            sequence: 5,
            colCode: 'destinationLocationDetail.name',
          },
          {
            colName: intl.formatMessage({ id: 'freightColumnGMinimum', defaultMessage: 'Gas Price (Minimum)' }),
            sequence: 6,
            colCode: 'detail.minPrice',
          },
          {
            colName: intl.formatMessage({ id: 'freightColumnGMaximum', defaultMessage: 'Gas Price (Maximum)' }),
            sequence: 7,
            colCode: 'detail.maxPrice',
          },
          {
            colName: intl.formatMessage({ id: 'freightColumnPricePerTrip', defaultMessage: 'Price per Trip' }),
            sequence: 8,
            colCode: 'detail.price',
          },
          {
            colName: intl.formatMessage({ id: 'AllowanceSettingColumnUpdateby', defaultMessage: 'Update by' }),
            sequence: 9,
            colCode: 'updatedName',
          },
          {
            colName: intl.formatMessage({ id: 'AllowanceSettingColumnUpdateDate', defaultMessage: 'Update Date' }),
            sequence: 10,
            colCode: 'updatedAt',
          },
        ],
      };
      const response = await settingGasPriceExport(body);
      console.log('EXP', response.data.data)
      if (response.status === 200) {


        window.open(response.data.data.url);
        successNotification(response.data.status.message);
      } else {
        errorNotification(response.data.status.message);
      }
    } catch (error) { }
  };

  return (
    <div>
      {checkPermissionAction('P79PG1C1', 'P79PG1C1A1') ? (
        <Card
          title={intl.formatMessage({ id: 'menuGasPriceSetting', defaultMessage: 'Freight' })}
          extra={
            // checkPermissionAction('P79PG1C1', 'P79PG1C1A2') ? (
            <Dropdown overlay={menuColumn} trigger={['click']} placement="bottomRight">
              <Button01 key="submit" type="primary" btnsize="wd_df">
                <FormattedMessage id="btnActionGroup" defaultMessage="Action" />
                <DownOutlined style={{ fontSize: '14px', paddingLeft: '22%' }} />
              </Button01>
            </Dropdown>
            // ) : null
          }
          bodyStyle={{
            padding: 'unset',
            height: 'calc(100vh - 170px)',
            overflowY: 'auto',
          }}
        >
          <FilterSetting
            type="freight"
            search={search}
            setSearch={setSearch}
            handleApply={handleApply}
            handleReset={handleReset}
            onFilterOrder={onFilterOrder}
            extra={extra}
          />

          <TableTransporationSetting
            columns={columns}
            listData={listData}
            listTotal={listTotal}
            contentAction={contentAction}
            onRow={true}
            tableChange={tableChange}
            setTableChange={setTableChange}
            popupPermisson={
              checkPermissionAction('P79PG1C1', 'P79PG1C1A3') || checkPermissionAction('P79PG1C1', 'P79PG1C1A4') ? true : false
            }
          />
        </Card>
      ) : null}

      <ModalSettting
        visible={visible}
        setVisible={setVisible}
        type="freight"
        data={editData}
        setData={setEditData}
        setTrigger={setIsFetch}
      />
    </div>
  );
};

export default PageFreight;
