import { Col, DatePicker, Form, Row, Input, Select } from 'antd';
import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import LabeRequireForm from '../../../../../components/label-required-form';
import { numberFormatDecimal } from '../../../../../component-function/fnc-number-format-decimal';
import { numberFormatter } from '../../../../../component-function/fnc-inputnumber-currency';
import _ from 'lodash';
import moment from 'moment';

const { TextArea } = Input;
const { Option } = Select;
const dateFormat = 'ddd, MMM DD YYYY';

const PriceFormView = ({ form, selectItem, setDataForm, dataForm, initialTaxList }) => {
  const { getFieldDecorator, getFieldValue, setFieldsValue } = form;
  const intl = useIntl();

  const sumTotal = _.size(selectItem) > 0 ? _.sumBy(selectItem, 'totalNum') : 0.0;
  // const discountNumber = _.size(selectItem) > 0 ? parseFloat(_.get(dataForm, 'discountPercent')) : 0.0;
  const discountNumber = 0.0;
  // const discount = _.size(selectItem) > 0 ? (parseFloat(sumTotal) * discountNumber) / 100 : 0.0;
  const discount = 0.0;
  // const subtotalMinust = _.size(selectItem) > 0 ? _.sumBy(selectItem, 'subtotal') - discount : 0.0;
  // const subtotalDiscount = _.size(selectItem) > 0 ? parseFloat(sumTotal) - discount : 0.0;
  const subtotalMinust = sumTotal;
  const subtotalDiscount = sumTotal;
  // const taxNumber = _.size(selectItem) > 0 ? parseFloat(_.get(dataForm, 'taxPercent')) : 0.0;
  // const tax = _.size(selectItem) > 0 ? (parseFloat(subtotalDiscount) * taxNumber) / 100 : 0.0;
  const taxNumber = 0.0;
  const tax = 0.0;
  const subtotalTax = sumTotal;

  console.log('priceFormPending', dataForm, sumTotal, selectItem)

  console.log('TaxCheck', _.size(selectItem) > 0 && _.get(dataForm, 'taxPercentage') !== undefined);
  console.log('PriceForm', selectItem, dataForm);
  console.log('PriceForm X', discount, discountNumber, tax, taxNumber, '/', subtotalMinust, subtotalDiscount, subtotalTax);

  useEffect(() => {
    if (discount && discount > 0) {
      setDataForm((o) => ({
        ...o,
        sumTotals: sumTotal,
        discountNumber: discount,
        subtotalDiscount: subtotalDiscount,
        taxNumber: tax,
        totalAll: subtotalTax,
      }));
    } else {
      setDataForm((o) => ({
        ...o,
        sumTotals: sumTotal,
        discountNumber: discount,
        subtotalDiscount: subtotalDiscount,
        taxNumber: tax,
        totalAll: subtotalTax,
      }));
    }
  }, [discount, sumTotal]);

  useEffect(() => {
    if (discountNumber && discountNumber > 0) {
      setDataForm((o) => ({
        ...o,
        sumTotal: sumTotal,
        discountNumber: discount,
        subtotalDiscount: subtotalDiscount,
        taxNumber: tax,
        totalAll: subtotalTax,
      }));
    } else {
      setDataForm((o) => ({
        ...o,
        sumTotal: sumTotal,
        discountNumber: discount,
        subtotalDiscount: subtotalDiscount,
        taxNumber: tax,
        totalAll: subtotalTax,
      }));
    }
  }, [discountNumber, sumTotal]);

  useEffect(() => {
    if (tax >= 0) {
      setDataForm((o) => ({
        ...o,
        sumTotal: sumTotal,
        discountNumber: discount,
        subtotalDiscount: subtotalDiscount,
        taxNumber: tax,
        totalAll: subtotalTax,
      }));
    }
  }, [tax, subtotalDiscount]);

  return (
    <Form name="pirce">
      <Row gutter={[24]}>
        <Col span={10}>
          <Row gutter={[24]}>
            <Col span={24}>
              <Form.Item
                colon={false}
                label={
                  <LabeRequireForm
                    bold={true}
                    text={intl.formatMessage({ id: 'quotationLabelValidRemark', defaultMessage: 'Remark' })}
                    req={false}
                  />
                }
              >
                {_.get(dataForm, 'code') === 'view'
                  ? getFieldDecorator('remark', { initialValue: '' })(<TextArea autoSize={{ minRows: 4, maxRows: 4 }} maxLength={250} />)
                  : _.get(dataForm, 'remark') || '-'}
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={4}></Col>
        <Col span={10}>
          <div className="group-form-price">
            <div>
              <span>
                <FormattedMessage id="quotationLabelSubtotal" defaultMessage="Subtotal" />
              </span>
            </div>
            <div>
              <span>
                {/* {_.get(dataForm, 'subtotalTxt') ? numberFormatter(_.get(dataForm, 'subtotal')) : numberFormatter(sumTotal) || '0.00'} */}
                {numberFormatter(sumTotal ? sumTotal : _.get(dataForm, 'total')) || '0.00'}
              </span>{' '}
              <FormattedMessage id="quotationLabelTHB" defaultMessage="THB" />
            </div>
          </div>
          <div className="group-form-price">
            <div className="group-form-item-price-percent-ar">
              <Row>
                <Col span={16}>
                  <FormattedMessage id="quotationLabelDiscount" defaultMessage="Discount" />
                </Col>
                <Col span={8}>
                  {' '}
                  <Input readOnly style={{ backgroundColor: '#F8F8F8', width: '100%' }} value={numberFormatter(discountNumber) + '%'} />
                  {/* {numberFormatter(discountNumber) + '%'} */}
                </Col>
              </Row>
            </div>
            <div>
              <div className="group-form-item-price">
                <div className="group-form-item-price-input-view">
                <Input readOnly style={{ backgroundColor: '#F8F8F8', width: '100%' }} value={numberFormatter(_.get(dataForm, 'discountNum'))} />
                  {/* {_.get(dataForm, 'discountNum') !== undefined
                    ? numberFormatter(_.get(dataForm, 'discountNum'))
                    : numberFormatter(discount) || '0.00'}{' '}
                  <FormattedMessage id="quotationLabelTHB" defaultMessage="THB" /> */}
                </div>
              </div>
            </div>
          </div>
          <div className="group-form-price">
            <div>
              <span>
                <FormattedMessage id="quotationLabelSubtotalAfterDiscount" defaultMessage="Subtotal After Discount" />
              </span>
            </div>
            <div>
              <span>
                {/* {_.get(dataForm, 'subtotalAfterDiscount')
                  ? numberFormatter(_.get(dataForm, 'subtotalAfterDiscount'))
                  : numberFormatter(subtotalDiscount) || '0.00'} */}
                  {numberFormatter(sumTotal ? sumTotal : _.get(dataForm, 'total')) || '0.00'}
                <FormattedMessage id="quotationLabelTHB" defaultMessage="THB" />
              </span>
            </div>
          </div>
          <div className="group-form-price">
            <div className="group-form-item-price-percent-ar">
              <Row>
                <Col span={16}>
                  <FormattedMessage id="quotationLabelTax" defaultMessage="Tax" />
                </Col>
                <Col span={8}>
                <Input readOnly style={{ backgroundColor: '#F8F8F8', width: '100%' }} value={numberFormatter(taxNumber) + '%'} />
                  {/* {_.size(selectItem) > 0 && _.get(dataForm, 'taxPercentage') !== undefined
                    ? numberFormatter(_.get(dataForm, 'taxPercentage')) + '%'
                    : numberFormatter(taxNumber) + '%'} */}
                </Col>
              </Row>
              {/* <span className="group-form-item-price-percent-ar">
              ---
                 ? numberFormatter(_.get(dataForm, 'discountNum'))
                    : _.get(dataForm, 'discount')

              ---
                                   // _.get(dataForm, 'taxTHB')
                    // numberFormatter(_.get(dataForm, 'taxNum'))
              </span>
              <span style={{marginLeft: '10px'}}></span> */}
            </div>
            <div>
              <div className="group-form-item-price">
                <div className="group-form-item-price-input-view">
                <Input readOnly style={{ backgroundColor: '#F8F8F8', width: '100%' }} value={numberFormatter(0.0)} />
                  {/* {_.get(dataForm, 'taxNum') !== undefined ? numberFormatter(_.get(dataForm, 'taxNum')) : numberFormatter(tax)}{' '}
                  <FormattedMessage id="quotationLabelTHB" defaultMessage="THB" /> */}
                </div>
              </div>
            </div>
          </div>
          <div className="group-form-price">
            <div>
              <span>
                <FormattedMessage id="quotationLabelTotal" defaultMessage="Total" />
              </span>
            </div>
            <div>
              <span>
                {/* {_.get(dataForm, 'subtotalTxt') ? numberFormatter(_.get(dataForm, 'totalAll')) : numberFormatter(subtotalTax) || '0.00'}{' '} */}
                {numberFormatter(sumTotal ? sumTotal : _.get(dataForm, 'total')) || '0.00'}
                <FormattedMessage id="quotationLabelTHB" defaultMessage="THB" />
              </span>
            </div>
          </div>
        </Col>
      </Row>
    </Form>
  );
};

PriceFormView.defaultProps = {};

export default PriceFormView;
