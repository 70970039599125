import React from 'react'
import { Table, Row, Col } from 'antd'
import _ from 'lodash'
import { FormattedMessage } from 'react-intl';
import NumberFormat from 'react-number-format';

const { Column, ColumnGroup } = Table;

export default (props) => {
  const { columns, tableData, total, handlePagination, handleSizeChange, handleChange, totalHeader, scrollTableX } = props

  const showTotal = () => {
    return <span style={{ fontSize: '13px' }}><FormattedMessage id="lblRowPerPage" defaultMessage="Rows per page:" /></span>
  };

  const number = (data) => {
    return (
      <NumberFormat value={(parseFloat(data || 0)).toFixed(2)} displayType={'text'} thousandSeparator={true} />
    )
  }

  return (
    <div style={{ marginTop: '16px' }}>
      <Table
        dataSource={tableData}
        // columns={columns}
        scroll={{ x: scrollTableX, y: 'calc(100vh - 465px)' }}
        onChange={handleChange}
        bordered
        pagination={{
          total: total,
          showTotal: showTotal,
          defaultCurrent: 1,
          pageSizeOptions: ['10', '20', '30', '40', '50'],
          showSizeChanger: true,
          locale: { items_per_page: '' },
          onChange: handlePagination,
          onShowSizeChange: handleSizeChange,
          className:'driver-expense-table-pagination'
        }}
      >
        {
          columns.map(item => {
            return item.key === 'heavyCarKm' || item.key === 'lightCarKm' || item.key === 'weight'
              || item.key === 'incentive' || item.key === 'freightBath' ? (
              <ColumnGroup title={item.title}>
                <Column
                  title={item.key === 'heavyCarKm' ? number(_.get(totalHeader, 'TotalHeavyCar')) : item.key === 'lightCarKm' ? number(_.get(totalHeader, 'TotalLightCar'))
                    : item.key === 'weight' ? number(_.get(totalHeader, 'TotalWeight')) : item.key === 'incentive' ? number(_.get(totalHeader, 'TotalIncentive'))
                      : item.key === 'freightBath' ? number(_.get(totalHeader, 'TotalFreight')) : undefined}
                  dataIndex={item.dataIndex}
                  key={item.key}
                  width={item.width}
                  align={_.get(item, 'align') ? _.get(item, 'align') : undefined}
                  fixed={_.get(item, 'fixed') ? _.get(item, 'fixed') : undefined}
                  sorter={_.get(item, 'sorter') ? true : false}
                  sortDirections={_.get(item, 'sortDirections') ? _.get(item, 'sortDirections') : undefined}
                  render={_.get(item, 'render') ? _.get(item, 'render') : undefined}
                >
                </Column>
              </ColumnGroup>
            ) : (
              <Column
                title={item.title}
                dataIndex={item.dataIndex}
                key={item.key}
                width={item.width}
                align={_.get(item, 'align') ? _.get(item, 'align') : undefined}
                fixed={_.get(item, 'fixed') ? _.get(item, 'fixed') : undefined}
                render={_.get(item, 'render') ? _.get(item, 'render') : undefined}
                sorter={_.get(item, 'sorter') ? true : false}
                sortDirections={_.get(item, 'sortDirections') ? _.get(item, 'sortDirections') : undefined}
              ></Column>
            )

          })
        }
      </Table>
      {
        tableData && _.size(tableData) !== 0 ? (
          <div className="total-items-list-view">
            <span style={{ fontSize: '13px' }}>
              <FormattedMessage id="lblTotal" defaultMessage="Total" />
              {` ${total || 0} `}
              <FormattedMessage id="lblitems" defaultMessage="items" />
            </span>
          </div>
        ) : null
      }
    </div >
  )
}
