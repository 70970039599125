import React from 'react';
import { Table, Tabs } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import styles from './css/index.css';

const { TabPane } = Tabs;

export default function MemberInformationReport({ dataMemberInfo, LangCode }) {
  const intl = useIntl();
  const shiftData =
    dataMemberInfo && dataMemberInfo.shift ? dataMemberInfo.shift : [];
  const equipmentData =
    dataMemberInfo && dataMemberInfo.equipment ? dataMemberInfo.equipment : [];
  const skillsData =
    dataMemberInfo && dataMemberInfo.skill ? dataMemberInfo.skill : [];

  const columnsShift = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
    },
    {
      title: intl.formatMessage({
        id: `memberInformationModalColumnsShiftName${LangCode}`,
        defaultMessage: 'Shift Name',
      }),
      dataIndex: 'shiftName',
      key: 'shiftName',
      width: 220,
    },
    {
      title: intl.formatMessage({
        id: `memberInformationModalColumnsTime${LangCode}`,
        defaultMessage: 'Time',
      }),
      dataIndex: 'time',
      key: 'time',
      width: 158,
    },
    {
      title: intl.formatMessage({
        id: `memberInformationModalColumnsDate${LangCode}`,
        defaultMessage: 'Date',
      }),
      dataIndex: 'date',
      key: 'date',
    },
  ];

  const columnsEquipmentTools = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
    },
    {
      title: intl.formatMessage({
        id: `memberInformationModalColumnsItems${LangCode}`,
        defaultMessage: 'Items',
      }),
      dataIndex: 'items',
      key: 'items',
      width: 300,
    },
    {
      title: intl.formatMessage({
        id: `memberInformationModalColumnsBorrowDate${LangCode}`,
        defaultMessage: 'Borrow Date',
      }),
      dataIndex: 'borrowDate',
      key: 'borrowDate',
    },
  ];

  const columnsSkills = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
    },
    {
      title: intl.formatMessage({
        id: `memberInformationModalColumnsSkill${LangCode}`,
        defaultMessage: 'Skill',
      }),
      dataIndex: 'skillName',
      key: 'skillName',
      width: 170,
    },
    {
      title: intl.formatMessage({
        id: `memberInformationModalColumnsSkillGroup${LangCode}`,
        defaultMessage: 'Skill Group',
      }),
      dataIndex: 'skillGroupName',
      key: 'skillGroupName',
      width: 180,
      ellipsis: true,
    },
    {
      title: intl.formatMessage({
        id: `memberInformationModalColumnsLevel${LangCode}`,
        defaultMessage: 'Level',
      }),
      dataIndex: 'skillLevel',
      key: 'skillLevel',
    },
  ];

  return (
    <div className="member-information-report">
      <div className="member-report-container">
        <Tabs className="member-report-Tabs" defaultActiveKey="1">
          <TabPane
            className="member-report-tabpane-shift"
            tab={intl.formatMessage({
              id: `memberInformationModalTabShift${LangCode}`,
              defaultMessage: 'Shift',
            })}
            key="1"
          >
            <div className="member-report-tabpane-div">
              <Table
                rowKey={(record) => record.index}
                className="member-information-table"
                dataSource={shiftData}
                columns={columnsShift}
                pagination={false}
                size="middle"
                scroll={{ y: 170 }}
              />
            </div>
          </TabPane>
          <TabPane
            className="member-report-tabpane"
            tab={intl.formatMessage({
              id: `memberInformationModalTabEquipment${LangCode}`,
              defaultMessage: 'Equipment & Tools',
            })}
            key="2"
          >
            <div className="member-report-tabpane-div">
              <Table
                rowKey={(record) => record.index}
                className="member-information-table"
                dataSource={equipmentData}
                columns={columnsEquipmentTools}
                pagination={false}
                size="middle"
                scroll={{ y: 170 }}
              />
            </div>
          </TabPane>
          <TabPane
            className="member-report-tabpane-skills"
            tab={intl.formatMessage({
              id: `memberInformationModalTabSkill${LangCode}`,
              defaultMessage: 'Skills',
            })}
            key="3"
          >
            <div className="member-report-tabpane-div">
              <Table
                rowKey={(record) => record.index}
                className="member-information-table"
                dataSource={skillsData}
                columns={columnsSkills}
                pagination={false}
                size="middle"
                scroll={{ y: 170 }}
              />
            </div>
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
}
