import React from 'react'
import { Form, Input, Row, Col, Select, InputNumber } from 'antd'
import styled from 'styled-components';
import style from './css/index.css'
import { FormattedMessage } from 'react-intl';


const editTeamService = ({ form, taskType, teamData, onCreate, dataDefault, intl }) => {

  const { Option } = Select;
  const { getFieldDecorator, validateFields, resetFields, getFieldsValue, getFieldValue, setFieldsValue } = form;

  const LabelRequire = styled.label`
  color: #ff1010;
`;
  const LabeRequire = (props) => {
    const { text, req } = props;
    return (
      <span className="font-p">
        {text}&nbsp;
        {req ? <LabelRequire>*</LabelRequire> : ''}
      </span>
    );
  };


  const validatorPriority = (rule, value, callback) => {

    if (value < 1) {
      callback(<FormattedMessage id="ModalTeamServiceValidatorPriorityMinimum" defaultMessage="Minimum 1" />)
    }
    else if (value > 100) {
      callback(<FormattedMessage id="ModalTeamServiceValidatorPriorityMaximum" defaultMessage="Maximum 100" />)
    }
    else {
      callback();
    }
  }




  return (
    <div>
      <Form onSubmit={onCreate}>
        <Row>
          <Row>
            <Col span={24}>
              <Form.Item>
                <span className='text-style'><FormattedMessage id="ModalTeamServiceHeaderTextTeam" defaultMessage="Team" /><LabeRequire req={true} /></span>
                {getFieldDecorator("team", {
                  initialValue: dataDefault && dataDefault !== undefined ? dataDefault.org.map(item => { return item.orgId }) : undefined,
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'ModalTeamServiceValidateTeam', defaultMessage: 'Please select team' })
                    }
                  ]
                })(<Select
                  mode='multiple'
                  placeholder={intl.formatMessage({ id: 'ModalTeamServicePlaceholderTeam', defaultMessage: 'Select Team' })}
                  showSearch
                  filterOption={(input, option) =>
                    option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  className='select-privacy'
                >
                  {teamData.map((item, i) => (
                    <Option value={item.orgId}>
                      {item.name}
                    </Option>
                  ))}
                </Select>)}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16, 16}>
            <Col span={18}>
              <Form.Item>
                <span ><FormattedMessage id="ModalTeamServiceHeaderTextTaskType" defaultMessage="Task Type" /><LabeRequire req={true} /></span>
                {getFieldDecorator("taskType", {
                  initialValue: dataDefault && dataDefault !== undefined ? dataDefault.taskType.map(item => { return item.taskTypeId }) : undefined,
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'ModalTeamServiceValidateTaskType', defaultMessage: 'Please select task type' })
                    }
                  ]
                })(<Select
                  placeholder={intl.formatMessage({ id: 'ModalTeamServicePlaceholderTaskType', defaultMessage: 'Select Task Type' })}
                  mode='multiple'
                  showSearch
                  filterOption={(input, option) =>
                    option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  className='select-privacy'
                >
                  {taskType.map((item, i) => (
                    <Option value={item.taskTypeId}>
                      {item.name}
                    </Option>
                  ))}
                </Select>)}
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item>
                <span ><FormattedMessage id="ModalTeamServiceHeaderPriority" defaultMessage="Priority" /><LabeRequire req={true} /></span>
                {getFieldDecorator("priority", {
                  initialValue: dataDefault && dataDefault !== undefined ? dataDefault.priority : undefined,
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'ModalTeamServiceValidatePriority', defaultMessage: 'Please select priority' })
                    },
                    {
                      validator: validatorPriority
                    }
                  ]
                })(<Input placeholder={intl.formatMessage({ id: 'ModalTeamServicePlaceholderPriority', defaultMessage: 'Select priority' })} type='Number' className='input-height' autoComplete="off" ></Input>)}
              </Form.Item>
            </Col>
          </Row>
        </Row>
      </Form>

    </div>
  )
}
const TeamService = Form.create({
  name: "modal_form",
  mapPropsToFields(props) {

  }
})(editTeamService);

export default TeamService;