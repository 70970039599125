import React from 'react'
import './css/index.css'
import SignaturePad from 'react-signature-canvas'

export default (props) => {

  const { refCanvas, dragStart } = props

  return (
    <div className='uploader-signature-div-padding'>
      <div className='uploader-signature-background'>
        <SignaturePad
          ref={refCanvas}
          onBegin={dragStart}
          canvasProps={{
            className: 'canvas-props-style'
          }}
        />
      </div>
    </div>
  )
}
