import { Col, DatePicker, Form, Input, Row, Select, Tag, Button } from 'antd';
import React, { useContext, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import LabeRequireForm from '../../../components/label-required-form';
import CustomTableComponent from '../components/table';
import CollapseCustom from '../../../components/collapse-custom';
import { messageLabel } from '../../../components/message-component';
import _ from 'lodash';
import { numberFormatDecimal, numberFormatNoDecimal } from '../../../component-function/fnc-number-format-decimal';
import { AccountReceiveContext } from '../context';
import QuotationModal from '../modal/quotation/quotation-modal';
import moment from 'moment';
import SummaryQuotation from '../components/summary-approved';

const { Option } = Select;
const { RangePicker } = DatePicker;
const dateFormat = 'ddd, MMM DD YYYY';

const PendingAR = () => {
  const { state, setState, func } = useContext(AccountReceiveContext);
  const { isOpenQuotation, reportDataPending, summaryData, customerList } = state;
  const { seIsOpenQuotation, setReportDataPending } = setState;
  const { handleOpenModalQuotationPending, handleCustomerPopupScroll, handleOpenModalQuotationInvoice } = func;
  const { quotationList, quotationTotal, paginationPage, loading, rangePickerDate } = reportDataPending;
  const intl = useIntl();
  const statuslist = [
    { Id: 'invoiced', statusName: intl.formatMessage({ id: 'ARInvoiced', defaultMessage: 'Invoiced' }) },
    { Id: 'pending', statusName: intl.formatMessage({ id: 'ARPendingforInvoice', defaultMessage: 'Pending for Invoice' }) },
  ];

  const handleFieldSort = (value) => {
    setReportDataPending((prev) => ({ ...prev, fieldSort: value }));
  };

  const handleOrderSort = (value) => {
    setReportDataPending((prev) => ({ ...prev, orderSort: value }));
  };

  const handlePaginationPage = (value) => {
    setReportDataPending((prev) => ({ ...prev, paginationPage: value }));
  };

  const handlePaginationSize = (value) => {
    setReportDataPending((prev) => ({ ...prev, paginationSize: value }));
  };

  const handleSelectRangePicker = (value) => {
    setReportDataPending((prev) => ({ ...prev, rangePickerDate: value }));
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      fixed: 'left',
      width: 70,
    },
    // {
    //   title: messageLabel('quotationLabelQuotationNo', 'Quotation No'),
    //   dataIndex: 'quotationNo',
    //   key: 'quotationNo',
    //   sorter: true,
    //   fixed: 'left',
    //   width: 150,
    // },
    {
      title: messageLabel('quotationLabelSaleOrderNo', 'Sale Order No.'),
      dataIndex: 'saleOrderNo',
      key: 'saleOrderNo',
      sorter: true,
      fixed: 'left',
      width: 150,
      ellipsis: true,
    },
    {
      title: messageLabel('quotationLabelCustomer', 'Customer'),
      dataIndex: 'customerName',
      key: 'customerName',
      sorter: true,
      
      // width: 200,
    },
    {
      title: messageLabel('ARStatus', 'Status'),
      dataIndex: 'statusTxt',
      key: 'statusTxt',
      sorter: true,
      render: (text, record, index) => (
        <span>
          <p
            style={{
              // backgroundColor: record['statusColor'],
              backgroundColor: _.get(record, 'statusColor'),
              color: 'white',
              textAlign: 'center',
              borderRadius: '10px',
              padding: '0px 15px',
            }}
          >
            {record.statusTxt}
          </p>
        </span>
      ),
    },
    {
      title: messageLabel('quotationLabelTotal', 'Total'),
      dataIndex: 'totalTxt',
      key: 'totalTxt',
      sorter: true,
      width: 200,
      align: 'right',
    },
    {
      title: messageLabel('ARRemark', 'Remark'),
      dataIndex: 'remark',
      key: 'remark',
      sorter: true,
      width: 200,
      render: (text, record, index) => (_.get(record, 'remark') ? _.get(record, 'remark') : '-'),
    },
    {
      title: messageLabel('quotationLabelIssuedBy', 'Issued by'),
      dataIndex: 'issueBy',
      key: 'issueBy',
      sorter: true,
      // render: (text, record, index) => '',
    },
    {
      title: messageLabel('quotationLabelIssuedDate', 'Issued Date'),
      dataIndex: 'issueDateTxt',
      key: 'issueDateTxt',
      sorter: true,
      // render: (text, record, index) =>
      //   _.get(record, 'issueDate') ? moment(_.get(record, 'issueDate'), 'YYYY-MM-DD').format('ddd, MMM DD YYYY') : null,
    },
  ];

  const advancedFilter = (action, value) => {
    const handleChange = (value, key) => {
      action((prev) => ({ ...prev, [key]: value }));
    };

    return (
      <Form colon={false}>
        <Row gutter={[24, 2]}>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'quotationLabelSaleOrderNo', defaultMessage: 'Sale Order No.' })}
                  req={false}
                />
              }
            >
              <Input
                allowClear
                value={_.get(value, 'saleOrderNo')}
                onChange={(e) => handleChange(e.target.value, 'saleOrderNo')}
                placeholder={intl.formatMessage({ id: 'quotationHintSaleOrderNo', defaultMessage: 'Enter Sale Order No.' })}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'quotationLabelCustomer', defaultMessage: 'Customer' })} req={false} />
              }
            >
              <Select
                allowClear
                showSearch
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                placeholder={intl.formatMessage({ id: 'VoucherCustomerPlaceHolder', defaultMessage: 'Select Customer' })}
                onPopupScroll={handleCustomerPopupScroll}
                onChange={(e) => handleChange(e, 'customerName')}
              >
                {_.map(customerList, (item, index) => (
                  <Option key={item.customerId} value={item.customerId}>
                    {item.customerName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item label={<LabeRequireForm text={intl.formatMessage({ id: 'ARStatus', defaultMessage: 'Status' })} req={false} />}>
              <Select
                allowClear
                showSearch
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                placeholder={intl.formatMessage({ id: 'ARStatusPlaceHolder', defaultMessage: 'Select Status' })}
                onChange={(e) => handleChange(e, 'statusCode')}
              >
                {_.map(statuslist, (item, index) => (
                  <Option key={item.Id} value={item.Id}>
                    {item.statusName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  };

  const contentAction = (record) => {
    // console.log('content', record);
    return (
      <div style={{ display: 'grid' }}>
        {_.get(record, 'statusTxt') !== 'Pending for Invoice' && (
          <div>
            <Button
              style={{ width: 150 }}
              type="link"
              ghost
              onClick={() => {
                handleOpenModalQuotationPending(record, 'viewinvoice');
                handleOpenModalQuotationInvoice(record, 'viewinvoice');
              }}
            >
              <FormattedMessage id="btnView" defaultMessage="View" />
            </Button>
          </div>
        )}

        <div>
          <Button style={{ width: 150 }} type="link" ghost onClick={() => handleOpenModalQuotationPending(record, 'view')}>
            <FormattedMessage id="ARCreateInvoice" defaultMessage="Create Invoice" />
          </Button>
        </div>
      </div>
    );
  };

  const summary = {
    within3days: _.filter(summaryData, (o) => {
      return o.summaryTypeCode === 'pendingwithin3days';
    }),
    within7days: _.filter(summaryData, (o) => {
      return o.summaryTypeCode === 'pendingwithin7days';
    }),
    within15days: _.filter(summaryData, (o) => {
      return o.summaryTypeCode === 'pendingwithin15days';
    }),
    overdue: _.filter(summaryData, (o) => {
      return o.summaryTypeCode === 'overdue';
    }),
  };

  return (
    <div className="warehouse-layout-content warehouse-tabs-details scroll-sm">
      {/* <div style={{ paddingTop: 20 }}>
        <SummaryQuotation summary={summary} />
      </div> */}
      <div style={{ paddingTop: 20 }}>
        <CollapseCustom
          label={intl.formatMessage({ id: `btnFilter`, defaultMessage: 'Filter' })}
          extra={
            <RangePicker
              placeholder={
                intl.formatMessage({ id: 'ARStartDate', defaultMessage: 'Start Date' }) +
                ' ~ ' +
                intl.formatMessage({ id: 'AREndDate', defaultMessage: 'End Date' })
              }
              value={rangePickerDate}
              format={dateFormat}
              onChange={handleSelectRangePicker}
              allowClear={true}
            />
          }
        >
          {advancedFilter(setReportDataPending, reportDataPending)}
        </CollapseCustom>
      </div>
      <div className="content-body-report">
        <CustomTableComponent
          rowKey={(record) => record.arId}
          columns={columns}
          dataSource={quotationList}
          scroll={{ x: true }}
          role={true}
          onRow={true}
          total={quotationTotal}
          loading={loading}
          paginationPage={paginationPage}
          setFieldSort={handleFieldSort}
          setOrderSort={handleOrderSort}
          setPaginationSize={handlePaginationSize}
          setPaginationPage={handlePaginationPage}
          contentAction={contentAction}
        />
      </div>
    </div>
  );
};

export default PendingAR;
