import React from 'react'
import { Form, Modal, Input } from 'antd'
import { useIntl, FormattedMessage } from 'react-intl';
import Button01 from '../v2/button_01';
import Button02 from '../v2/button_02';
import './index.css'
import httpClient from '../axiosClient';
import { successNotification, errorNotification } from '../v2/notification';

const ModalResetPassword = (props) => {
  const { visible, setVisible, form, id } = props
  const { getFieldDecorator, validateFields, resetFields, getFieldValue } = form;
  const intl = useIntl();
  const comCode = localStorage.getItem('comCode');

  const LabeRequire = (props) => {
    const { text, req } = props;
    return (
      <span>
        {text}&nbsp;
        {req ? <span style={{ fontSize: '13px', color: '#fa383e' }}>*</span> : ''}
      </span>
    );
  };


  const handleOk = () => {
    validateFields(async (err, values) => {
      if (err) {
        return;
      }
      const body = {
        "newPassword": values.newPassword
      }
      try {
        const response = await httpClient.put(`/v5/business-partner/manager/company/${comCode}/web/businesspartner/${id}/newpassword`, body);
        if (response.status === 200) {
          successNotification(response.data.status.message)
          resetFields();
          setVisible(false)
        }
      } catch (error) {
        errorNotification(error.response.data.status.message)
      }
    })
  }

  const handleCancel = () => {
    setVisible(false)
  }

  const validatorPassword = (rule, value, callback) => {
    if (value) {
      const newPassword = getFieldValue(`newPassword`);
      if (value !== newPassword) {
        callback(<FormattedMessage id="ModalResetPasswordNewPasswordValidator" defaultMessage="Please Enter The Same Password." />);
      } else {
        callback();
      }
    } else {
      callback();
    }
  };

  return (
    <div>
      <Modal
        title={intl.formatMessage({ id: 'customerInformationTextSetPassword', defaultMessage: 'Set Password' })}
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={400}
        centered={true}
        bodyStyle={{ padding: '10px 24px 24px 24px' }}
        footer={[
          <Button02 style={{margin : '0px 0px 0px 10px'}}
            key="back"
            btnsize="wd_df"
            onClick={() => handleCancel()}

          >
            <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
          </Button02>,
          <Button01
            key="submit"
            type="primary"
            btnsize="wd_df"
            onClick={() => handleOk()}
          >
            <FormattedMessage id="btnSave" defaultMessage="Save" />
          </Button01>,
        ]}
      >
        <Form>
          <Form.Item>
            <span className='reset-password-header'><FormattedMessage id="ModalResetPasswordNewPassword" defaultMessage="New Password" /><LabeRequire req={true} /></span>
            {getFieldDecorator("newPassword", {
              rules: [
                {
                  required: true,
                  message: intl.formatMessage({ id: 'ModalResetPasswordNewPasswordVL', defaultMessage: 'Please Enter New Password' })
                }
              ]
            })(<Input.Password placeholder={intl.formatMessage({ id: 'ModalResetPasswordNewPasswordPH', defaultMessage: 'Enter New Password' })} autoComplete="off" />)}
          </Form.Item>
        </Form>
        <Form.Item>
          <span className='reset-password-header'><FormattedMessage id="ModalResetPasswordConfirmPassword" defaultMessage="Confirm Password" /><LabeRequire req={true} /></span>
          {getFieldDecorator("confirmPassword", {
            rules: [
              {
                required: true,
                message: intl.formatMessage({ id: 'ModalResetPasswordConfirmPasswordVL', defaultMessage: 'Please Enter Confirm Password' })
              },
              {
                validator: validatorPassword,
              }
            ]
          })(<Input.Password placeholder={intl.formatMessage({ id: 'ModalResetPasswordConfirmPasswordPH', defaultMessage: 'Enter Confirm Password' })} autoComplete="off" />)}
        </Form.Item>
      </Modal>
    </div >
  )
}
const ResetPassword = Form.create({
  name: "modal_form",
  mapPropsToFields() {

  }
})(ModalResetPassword);

export default ResetPassword