import { Col, DatePicker, Form, Row, Input, Select } from 'antd';
import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import _ from 'lodash';
import moment from 'moment';
import { numberFormatter, numberParser } from '../../../../component-function/fnc-inputnumber-currency';
import LabeRequireForm from '../../../../components/label-required-form';
import { handleKeyDownDecimal2Fixed } from '../../../../component-function/fnc-number';

const { TextArea } = Input;
const { Option } = Select;
const dateFormat = 'ddd, MMM DD YYYY';

const PriceFormInvoice = ({ form, selectItem, setDataForm, dataForm, initialTaxList }) => {
  const { getFieldDecorator, getFieldValue, setFieldsValue } = form;
  const intl = useIntl();

  const sumTotal = _.size(selectItem) > 0 ? _.sumBy(selectItem, 'subtotal') : 0.0;
  const discountNumber =
    _.size(selectItem) > 0
      ? getFieldValue('discount') > 0
        ? parseFloat(getFieldValue('discount'))
        : parseFloat(_.toString(_.get(dataForm, 'discountPercent')))
      : 0.0;
  const discount = _.size(selectItem) > 0 ? (parseFloat(sumTotal) * discountNumber) / 100 : 0.0;
  const subtotalMinust = _.size(selectItem) > 0 ? _.sumBy(selectItem, 'subtotal') - discount : 0.0;
  const subtotalDiscount =
    _.size(selectItem) > 0
      ? getFieldValue('discountNumber')
        ? parseFloat(sumTotal) - numberParser(getFieldValue('discountNumber'))
        : parseFloat(sumTotal) - discount
      : 0.0;
  const taxNumber =
    _.size(selectItem) > 0
      ? getFieldValue('tax')
        ? parseFloat(getFieldValue('tax'))
        : parseFloat(_.toString(_.get(dataForm, 'taxPercent')))
      : 0.0;
  const tax = _.size(selectItem) > 0 ? (parseFloat(subtotalDiscount) * taxNumber) / 100 : 0.0;
  const subtotalTax = _.size(selectItem) > 0 ? parseFloat(subtotalDiscount) + tax : 0.0;

  ///-----------------------

  // console.log('TaxCheck', _.size(selectItem) > 0 && _.get(dataForm, 'taxPercentage') !== undefined);
  // console.log('PriceForm', selectItem, dataForm);
  // console.log('PriceForm X', sumTotal, discount, discountNumber, tax, taxNumber, '/', subtotalMinust, subtotalDiscount, subtotalTax);

  useEffect(() => {
    if (discount && discount > 0) {
      setDataForm((o) => ({
        ...o,
        discountNumber: discountNumber,
        discount: discount,
        subtotalDiscount: subtotalDiscount,
        taxNumber: taxNumber,
        tax: tax,
        totalAll: subtotalTax,
        sumTotals: sumTotal,
      }));
    } else {
      setDataForm((o) => ({
        ...o,
        discountNumber: discountNumber,
        discount: discount,
        subtotalDiscount: subtotalDiscount,
        taxNumber: taxNumber,
        tax: tax,
        totalAll: subtotalTax,
        sumTotals: sumTotal,
      }));
    }
  }, [discount, sumTotal]);

  useEffect(() => {
    if (discountNumber && discountNumber > 0) {
      setDataForm((o) => ({
        ...o,
        discountNumber: discountNumber,
        discount: discount,
        subtotalDiscount: subtotalDiscount,
        taxNumber: taxNumber,
        tax: tax,
        totalAll: subtotalTax,
        sumTotals: sumTotal,
      }));
    } else {
      setDataForm((o) => ({
        ...o,
        discountNumber: discountNumber,
        discount: discount,
        subtotalDiscount: subtotalDiscount,
        taxNumber: taxNumber,
        tax: tax,
        totalAll: subtotalTax,
        sumTotals: sumTotal,
      }));
    }
  }, [discountNumber, sumTotal]);

  useEffect(() => {
    if (tax >= 0) {
      setDataForm((o) => ({
        ...o,
        discountNumber: discountNumber,
        discount: discount,
        subtotalDiscount: subtotalDiscount,
        taxNumber: taxNumber,
        tax: tax,
        totalAll: subtotalTax,
        sumTotals: sumTotal,
      }));
    }
  }, [tax, subtotalDiscount]);

  useEffect(() => {
    if (tax >= 0) {
      setFieldsValue({
        ['taxNumber']: parseFloat(tax).toFixed(2),
      });
      setDataForm((o) => ({
        ...o,
        discountNumber: discountNumber,
        discount: discount,
        subtotalDiscount: subtotalDiscount,
        taxNumber: taxNumber,
        tax: tax,
        totalAll: subtotalTax,
        sumTotals: sumTotal,
      }));
    }
  }, [tax, subtotalDiscount]);

  const onChangeTax = (value) => {
    setDataForm((o) => ({
      ...o,
      discountNumber: discountNumber,
      discount: discount,
      subtotalDiscount: subtotalDiscount,
      taxNumber: taxNumber,
      tax: tax,
      totalAll: subtotalTax,
      sumTotals: sumTotal,
      taxPercent: value,
    }));
  };

  const onChangeDiscount = (value) => {
    console.log('onChangeDiscount', value);
    if (value && value > 0) {
      console.log('PriceForm X discountNumber', (sumTotal * value) / 100, sumTotal * value);
      setFieldsValue({
        // ['discount']: value.toFixed(2),
        ['discountNumber']: ((sumTotal * value) / 100).toFixed(2),
      });
      setDataForm((o) => ({
        ...o,
        discountNumber: discountNumber,
        discount: discount,
        subtotalDiscount: subtotalDiscount,
        taxNumber: taxNumber,
        tax: tax,
        totalAll: subtotalTax,
        sumTotals: sumTotal,
      }));
    } else {
      setFieldsValue({
        ['discount']: 0,
        ['discountNumber']: 0.0,
      });
      setDataForm((o) => ({
        ...o,
        discountNumber: discountNumber,
        discount: discount,
        subtotalDiscount: subtotalDiscount,
        taxNumber: taxNumber,
        tax: tax,
        totalAll: subtotalTax,
        sumTotals: sumTotal,
      }));
    }
  };

  const onChangeDiscountNumber = (value) => {
    if (value && value > 0) {
      setFieldsValue({
        ['discount']: ((value / sumTotal) * 100).toFixed(2),
        // ['discountNumber']: value.toFixed(2),
      });
      setDataForm((o) => ({
        ...o,
        discountNumber: discountNumber,
        discount: discount,
        subtotalDiscount: subtotalDiscount,
        taxNumber: taxNumber,
        tax: tax,
        totalAll: subtotalTax,
        sumTotals: sumTotal,
      }));
    } else {
      setFieldsValue({
        ['discount']: 0,
        ['discountNumber']: 0.0,
      });
      setDataForm((o) => ({
        ...o,
        discountNumber: discountNumber,
        discount: discount,
        subtotalDiscount: subtotalDiscount,
        taxNumber: taxNumber,
        tax: tax,
        totalAll: subtotalTax,
        sumTotals: sumTotal,
      }));
    }
  };

  return (
    <Form name="pirce">
      <Row gutter={[24]}>
        <Col span={10}>
          <Row gutter={[24]}>
            <Col span={24}>
              <Form.Item
                colon={false}
                label={
                  <LabeRequireForm
                    bold={true}
                    text={intl.formatMessage({ id: 'quotationLabelValidRemark', defaultMessage: 'Remark' })}
                    req={false}
                  />
                }
              >
                {_.get(dataForm, 'code') === 'view'
                  ? _.get(dataForm, 'remark') || '-'
                  : getFieldDecorator('remark', { initialValue: _.get(dataForm, 'remark') })(
                      <TextArea autoSize={{ minRows: 4, maxRows: 4 }} maxLength={250} />
                    )}
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={4}></Col>
        <Col span={10}>
          <div className="group-form-price">
            <div>
              <span>
                <FormattedMessage id="quotationLabelSubtotal" defaultMessage="Subtotal" />
              </span>
            </div>
            <div>
              <span>
                {/* {_.get(dataForm, 'subtotalTxt') ? numberFormatter(_.get(dataForm, 'subtotal')) : numberFormatter(sumTotal) || '0.00'} */}
                {numberFormatter(sumTotal) || '0.00'}
              </span>{' '}
              <FormattedMessage id="quotationLabelTHB" defaultMessage="THB" />
            </div>
          </div>
          <div className="group-form-price">
            <div className="group-form-item-price-percent-ar">
              <Row>
                <Col span={_.get(dataForm, 'code') === 'view' ? 8 : 16} style={{ paddingTop: '7px' }}>
                  <FormattedMessage id="quotationLabelDiscount" defaultMessage="Discount" />
                </Col>
                <Col span={8}>
                  {/* {numberFormatter(discountNumber) + '%'} */}
                  <Form.Item colon={false}>
                    {_.get(dataForm, 'code') === 'view'
                      ? _.get(dataForm, 'discountPercent') + ' ' + intl.formatMessage({ id: 'quotationWarnPercent', defaultMessage: '%' })
                      : getFieldDecorator('discount', {
                          initialValue: numberFormatter(discountNumber) ? numberFormatter(discountNumber) : 0.0,
                        })(
                          <Input
                            suffix={intl.formatMessage({ id: 'quotationWarnPercent', defaultMessage: '%' })}
                            onChange={(e) => onChangeDiscount(e.target.value)}
                          />
                        )}
                  </Form.Item>
                </Col>
              </Row>
            </div>
            <div>
              <div className="group-form-item-price">
                <div className="group-form-item-price-input-view">
                  {/* {_.get(dataForm, 'discountNum') !== undefined
                    ? numberFormatter(_.get(dataForm, 'discountNum'))
                    : numberFormatter(discount) || '0.00'}{' '}
                  <FormattedMessage id="quotationLabelTHB" defaultMessage="THB" /> */}
                  <Form.Item colon={false}>
                    {_.get(dataForm, 'code') === 'view'
                      ? numberFormatter(_.get(dataForm, 'discountNum')) +
                        ' ' +
                        intl.formatMessage({ id: 'quotationLabelTHB', defaultMessage: 'THB' })
                      : getFieldDecorator('discountNumber', {
                          initialValue: numberFormatter(discount),
                          // _.get(dataForm, 'discountNum') !== undefined
                          //   ? numberFormatter(_.get(dataForm, 'discountNum'))
                          //   : numberFormatter(discount) || '0.00',
                        })(
                          <Input
                            onKeyDown={handleKeyDownDecimal2Fixed}
                            suffix={intl.formatMessage({ id: 'quotationLabelTHB', defaultMessage: 'THB' })}
                            onChange={(e) => onChangeDiscountNumber(e.target.value)}
                          />
                        )}
                  </Form.Item>
                </div>
              </div>
            </div>
          </div>
          <div className="group-form-price">
            <div>
              <span>
                <FormattedMessage id="quotationLabelSubtotalAfterDiscount" defaultMessage="Subtotal After Discount" />
              </span>
            </div>
            <div>
              <span>
                {_.get(dataForm, 'subtotalAfterDiscount')
                  ? getFieldValue('discountNumber')
                    ? numberFormatter(subtotalDiscount)
                    : numberFormatter(_.get(dataForm, 'subtotalAfterDiscount'))
                  : numberFormatter(subtotalDiscount) || '0.00'}{' '}
                <FormattedMessage id="quotationLabelTHB" defaultMessage="THB" />
              </span>
            </div>
          </div>
          <div className="group-form-price">
            <div className="group-form-item-price-percent-ar">
              <Row>
                <Col span={_.get(dataForm, 'code') === 'view' ? 8 : 16} style={{ paddingTop: '7px' }}>
                  <FormattedMessage id="quotationLabelTax" defaultMessage="Tax" />
                </Col>
                <Col span={8}>
                  {/* {_.size(selectItem) > 0 && _.get(dataForm, 'taxPercentage') !== undefined
                    ? numberFormatter(_.get(dataForm, 'taxPercentage')) + '%'
                    : numberFormatter(taxNumber) + '%'} */}
                  <Form.Item colon={false}>
                    {_.get(dataForm, 'code') === 'view'
                      ? _.get(dataForm, 'taxPercentage') + ' %'
                      : getFieldDecorator('tax', {
                          initialValue:
                            _.size(selectItem) > 0 && _.get(dataForm, 'taxPercent') !== undefined
                              ? numberFormatter(_.get(dataForm, 'taxPercent')) + '%'
                              : numberFormatter(taxNumber) + '%',
                        })(
                          <Select onSelect={onChangeTax}>
                            {_.map(initialTaxList, (item, i) => (
                              <Option key={i} value={item.percentage}>
                                {item.titleCode}
                              </Option>
                            ))}
                          </Select>
                        )}
                  </Form.Item>
                </Col>
              </Row>
              {/* <span className="group-form-item-price-percent-ar">
              ---
                 ? numberFormatter(_.get(dataForm, 'discountNum'))
                    : _.get(dataForm, 'discount')

              ---
                                   // _.get(dataForm, 'taxTHB')
                    // numberFormatter(_.get(dataForm, 'taxNum'))
              </span>
              <span style={{marginLeft: '10px'}}></span> */}
            </div>
            <div>
              <div className="group-form-item-price">
                <div className="group-form-item-price-input-view">
                  {/* {_.get(dataForm, 'taxNum') !== undefined ? numberFormatter(_.get(dataForm, 'taxNum')) : numberFormatter(tax)}{' '}
                  <FormattedMessage id="quotationLabelTHB" defaultMessage="THB" /> */}
                  <Form.Item colon={false}>
                    {_.get(dataForm, 'code') === 'view'
                      ? numberFormatter(_.get(dataForm, 'taxNum')) +
                        ' ' +
                        intl.formatMessage({ id: 'quotationLabelTHB', defaultMessage: 'THB' })
                      : getFieldDecorator('taxNumber', {
                          initialValue: numberFormatter(tax),
                        })(
                          <Input
                            onKeyDown={handleKeyDownDecimal2Fixed}
                            readOnly={true}
                            suffix={intl.formatMessage({ id: 'quotationLabelTHB', defaultMessage: 'THB' })}
                          />
                        )}
                  </Form.Item>
                </div>
              </div>
            </div>
          </div>
          <div className="group-form-price">
            <div>
              <span>
                <FormattedMessage id="quotationLabelTotal" defaultMessage="Total" />
              </span>
            </div>
            <div>
              <span>
                {_.get(dataForm, 'subtotalTxt')
                  ? getFieldValue('discountNumber') || getFieldValue('tax')
                    ? numberFormatter(subtotalTax)
                    : numberFormatter(_.get(dataForm, 'total'))
                  : numberFormatter(subtotalTax) || '0.00'}{' '}
                <FormattedMessage id="quotationLabelTHB" defaultMessage="THB" />
              </span>
            </div>
          </div>
        </Col>
      </Row>
    </Form>
  );
};

PriceFormInvoice.defaultProps = {};

export default PriceFormInvoice;
