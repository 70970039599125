import { Card, Col, Row, Tabs } from 'antd';
import React, { useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import Filter from './filter';
import FillterForm from './filterform';
import { RepairticketContext } from '../repair-ticket-context';
import './css/index.css';

const LeftCardRapirTicket = () => {
  const intl = useIntl();
  const { state, setState, fnc } = useContext(RepairticketContext);
  const { menuList, indeterminateRequest, indeterminateTeam, checkedKeysRequest, checkedKeysTeam, checkAllRequest, checkAllTeam } = state;
  const {
    setCheckedKeysRequest,
    setCheckedKeysTeam,
    setIndeterminateTeam,
    setIndeterminateRequest,
    setCheckAllRequest,
    setCheckAllTeam,
  } = setState;
  const { onCheckRequest, onCheckTeam } = fnc;
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);

  const onExpand = (expandedKeys) => {
    setExpandedKeys(expandedKeys);
    setAutoExpandParent(false);
  };

  return (
    <Card className="left-card-repair">
      <div>
        <div className="filter-text-summary-text">
          <span className="filter-text-summary-text-size">{intl.formatMessage({ id: `TicketFilter`, defaultMessage: 'Filter' })}</span>
        </div>
        <div className="left-card-body scroll-sm">
          <Filter
            Data={menuList.data}
            indeterminateTeam={indeterminateTeam}
            setCheckedListTeam={setState.setCheckTeam}
            setIndeterminateTeam={setIndeterminateTeam}
            setOrgIdRaw={setState.setOrgIdRaw}
            orgIdRaw={state.orgIdRaw}
            setPaginationPage={setState.setPaginationPage}
            checkedKeysTeam={checkedKeysTeam}
            autoExpandParent={autoExpandParent}
            expandedKeys={expandedKeys}
            onCheckTeam={onCheckTeam}
            onExpand={onExpand}
            setCheckAllTeam={setCheckAllTeam}
            checkAllTeam={checkAllTeam}
          ></Filter>
          <FillterForm />
        </div>
      </div>
    </Card>
  );
};

export default LeftCardRapirTicket;
