import React, { useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { Card, Select, DatePicker } from "antd";
import moment from "moment";
import _ from "lodash";
import './css/index.css'

const { Option } = Select;

const today = new Date();
const dateFormat = 'ddd, MMM DD YYYY';


const SummaryFilter = (props) => {
  const {
    durationData,
    initialItemTypeList,
    initialItemTypeLoading,
    handleItemTypeChange,
    handleItemTypeChangeSelect,
    handleItemTypePopupScroll,
    handleItemTypeChangeSearch,
    initialCustomerList,
    initialCustomerLoading,
    handleCustomerChange,
    handleCustomerChangeSelect,
    handleCustomerPopupScroll,
    handleCustomerChangeSearch,
    initialDestinationList,
    initialDestinationLoading,
    handleDestinationChange,
    handleDestinationChangeSelect,
    handleDestinationPopupScroll,
    handleDestinationChangeSearch,
    initialSourceList,
    initialSourceLoading,
    handleSourceChange,
    handleSourceChangeSelect,
    handleSourcePopupScroll,
    handleSourceChangeSearch,
    handleDateChange,
    handleDateChangeSelect,
    handleTimeChange,
    handleTimeChangeSelect,
    customerSelect,
    destinationSelect,
    sizeList,
    handleSizeChange,
    statusListData,
    handleSelectStatus,
    statusSelect
  } = props

  const intl = useIntl();

  return (
    <div>
      {/* <div className="filter-title-summary">
        <FormattedMessage id="ordersTitlefilterNew" defaultMessage="Filter" />
      </div> */}
      <div className="filter-title-summary-list-all scroll-sm">
        <div className="filter-title-summary-list">
          <p className="filter-title-summary-text" ><FormattedMessage id="modalOrderTextDeliveryDate" defaultMessage="Delivery Date" /></p>
          <DatePicker
            placeholder={intl.formatMessage({ id: 'modalOrderPlaceholderDeliveryDate', defaultMessage: 'Select Delivery Date' })}
            style={{ width: '100%' }}
            format='ddd, MMM DD, YYYY'
            defaultValue={moment(today, dateFormat)}
            onChange={handleDateChange}
          />
        </div>
        <div className="filter-title-summary-list">
          <p className="filter-title-summary-text"><FormattedMessage id="ordersTitleDeliveryTime" defaultMessage="Delivery Time" /></p>
          <Select
            placeholder={intl.formatMessage({ id: 'modalOrderTextDeliveryTime', defaultMessage: 'Select Delivery Time' })}
            style={{ width: '100%' }}
            showSearch
            filterOption={(input, option) =>
              option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            onChange={handleTimeChange}
            onSelect={handleTimeChangeSelect}
            allowClear={true}
          >
            {
              durationData && durationData.map(item =>
                <Option key={item.val} value={item.val}>
                  {item.txt}
                </Option>)
            }
          </Select>
        </div>
        {/* <div className="filter-title-summary-list">
          <p className="filter-title-summary-text"><FormattedMessage id="modalOrderTextSize" defaultMessage="Size" /></p>
          <Select
            placeholder={intl.formatMessage({ id: 'modalOrderPlaceholderSize', defaultMessage: 'Select Size' })}
            className='trans-order-team'
            style={{ width: '100%' }}
            defaultActiveFirstOption={false}
            filterOption={false}
            allowClear={true}
            mode="multiple"
            maxTagCount={1}
            showSearch
            loading={initialItemTypeLoading}
            onChange={handleItemTypeChange}
            onSelect={handleItemTypeChangeSelect}
            onPopupScroll={handleItemTypePopupScroll}
            onSearch={handleItemTypeChangeSearch}
          >
            {
              initialItemTypeList && initialItemTypeList.map(item => <Option value={item.item_type_id}>
                {item.item_type_name}
              </Option>)
            }
          </Select>
        </div> */}
        <div className="filter-title-summary-list">
          <p className="filter-title-summary-text"><FormattedMessage id="modalOrderTextSize" defaultMessage="Size" /></p>
          <Select
            placeholder={intl.formatMessage({ id: 'modalOrderPlaceholderSize', defaultMessage: 'Select Size' })}
            className='trans-order-team'
            style={{ width: '100%' }}
            defaultActiveFirstOption={false}
            filterOption={(input, option) =>
              option.props.children
                .toString()
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            allowClear={true}
            mode="multiple"
            maxTagCount={1}
            showSearch
            onChange={handleSizeChange}
            showArrow={true}
          >
            {
              sizeList && sizeList.map(item => <Option key={item.sizeId} value={item.sizeId}>
                {item.sizeName}
              </Option>)
            }
          </Select>
        </div>
        <div className="filter-title-summary-list">
          <p className="filter-title-summary-text"><FormattedMessage id="modalOrderTextCustomer" defaultMessage="Customer" /></p>
          <Select
            placeholder={intl.formatMessage({ id: 'modalOrderPlaceholderCustomer', defaultMessage: 'Select Customer' })}
            style={{ width: '100%' }}
            defaultActiveFirstOption={false}
            filterOption={false}
            allowClear={true}
            showSearch
            loading={initialCustomerLoading}
            onChange={handleCustomerChange}
            onSelect={handleCustomerChangeSelect}
            onPopupScroll={handleCustomerPopupScroll}
            onSearch={handleCustomerChangeSearch}
          >
            {
              initialCustomerList && initialCustomerList.map(item => <Option key={item.customerId} value={item.customerId}>
                {item.comtomerName}
              </Option>)
            }
          </Select>
        </div>
        <div className="filter-title-summary-list">
          <p className="filter-title-summary-text"><FormattedMessage id="modalOrderTextDestination" defaultMessage="Destination" /></p>
          <Select
            placeholder={intl.formatMessage({ id: 'modalOrderPlaceholderDestination', defaultMessage: 'Select Destination' })}
            style={{ width: '100%' }}
            defaultActiveFirstOption={false}
            filterOption={false}
            allowClear={true}
            disabled={customerSelect ? false : true}
            showSearch
            value={_.get(destinationSelect, 'customer_address_book_id') ? _.get(destinationSelect, 'customer_address_book_id') : undefined}
            loading={initialDestinationLoading}
            onChange={handleDestinationChange}
            onSelect={handleDestinationChangeSelect}
            onPopupScroll={handleDestinationPopupScroll}
            onSearch={handleDestinationChangeSearch}
            optionLabelProp="label"
          >
            {
              initialDestinationList && initialDestinationList.map(item =>
                <Option value={item.customer_address_book_id} label={`${item.address_name} · ${item.address}`}>
                  <span className='order-text-select'>{item.address_name} · {item.address}</span>
                </Option>
              )
            }
          </Select>
        </div>
        <div className="filter-title-summary-list">
          <p className="filter-title-summary-text"><FormattedMessage id="modalOrderTextSource" defaultMessage="Source" /></p>
          <Select
            placeholder={intl.formatMessage({ id: 'modalOrderPlaceholderSource', defaultMessage: 'Select Source' })}
            style={{ width: '100%' }}
            defaultActiveFirstOption={false}
            allowClear={true}
            filterOption={false}
            showSearch
            loading={initialSourceLoading}
            onChange={handleSourceChange}
            onSelect={handleSourceChangeSelect}
            onPopupScroll={handleSourcePopupScroll}
            onSearch={handleSourceChangeSearch}
            optionLabelProp="label"
          >
            {
              initialSourceList && initialSourceList.map(item =>
                <Option key={item.company_address_book_id} value={item.company_address_book_id} label={`${item.address_name} · ${item.address}`}>
                  <span className='order-text-select'>{item.address_name} · {item.address}</span>
                </Option>
              )
            }
          </Select>
        </div>
        <div className="filter-title-summary-list">
          <p className="filter-title-summary-text"><FormattedMessage id="modalOrderTextStatus" defaultMessage="Status" /></p>
          <Select
            placeholder={intl.formatMessage({ id: 'modalOrderPlaceholderStatus', defaultMessage: 'Select Status' })}
            style={{ width: '100%' }}
            allowClear={true}
            filterOption={(input, option) =>
              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            showSearch
            onChange={handleSelectStatus}
            value={statusSelect}
            mode="multiple"
            maxTagCount={1}
          >
            {
              statusListData && statusListData.map(item =>
                <Option key={item.statusCode} value={item.statusCode}>
                  {item.statusTxt}
                </Option>
              )
            }
          </Select>
        </div>
      </div>
    </div>
  )
}

export default SummaryFilter;