import React from 'react'
import './index.css'
import { Form } from 'antd'
import { TaskDetailProvider } from './context'
import ModalTaskDetailTab from './detail'

const ModalTaskDetailForm = (props) => {
  const { config, setConfig, form } = props

  return (
    <TaskDetailProvider>
      <ModalTaskDetailTab form={form} config={config} setConfig={setConfig} />
    </TaskDetailProvider>
  )
}

const ModalTaskDetail = Form.create({
  name: 'assignTeam-form',
  mapPropsToFields(props) { return {}; },
})(ModalTaskDetailForm);

export { ModalTaskDetail } 
