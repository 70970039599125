import React, { useState, useEffect, useContext } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useAppContext } from '../../../includes/indexProvider';
import { Card, Table, Button, notification, List, Icon } from 'antd';
import { debounce } from 'lodash';
import { OverTimeContext } from '../report-context';
import ActionEquipmentReport from './action-benefit-deduct-report';
import httpClient from '../../../components/axiosClient';
import _ from 'lodash';
import moment from 'moment';
import IconExcel from '../../../components/image/excel.svg';
import styles from './css/benefit-deduct-report.css';
import cssStyleCenter from '../report-center.css';
import { PageSettings } from '../../../config/page-settings';
import { Link } from 'react-router-dom';
import Button_01 from '../../../components/v2/button_01';

export default () => {
  const intl = useIntl();
  const app = useAppContext();
  const langValue = app.state.langValue;
  const { state, setState, fnc } = useContext(OverTimeContext);

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: 70,
      align: 'center',
      fixed: 'left',
    },
    {
      title: intl.formatMessage({ id: 'reportbenefitDeductColEmployee', defaultMessage: 'Employee Code' }),
      dataIndex: 'emp_code',
      key: 'emp_code',
      width: 180,
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      fixed: 'left',
    },
    {
      title: intl.formatMessage({ id: 'reportbenefitDeductReportColName', defaultMessage: 'Name' }),
      dataIndex: 'member_name',
      key: 'member_name',
      width: 200,
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      fixed: 'left',
    },
    {
      title: intl.formatMessage({ id: 'reportbenefitDeductReportColTeam', defaultMessage: 'Team' }),
      dataIndex: 'team_name',
      key: 'team_name',
      width: 200,
      sorter: true,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: 'reportbenefitDeductReportColEmail', defaultMessage: 'Email' }),
      dataIndex: 'email',
      key: 'email',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      width: 200,
    },
    {
      title: intl.formatMessage({ id: 'reportbenefitDeductReportColOT1', defaultMessage: 'OT x 1 (hr)' }),
      dataIndex: 'ot1x',
      key: 'ot1x',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      width: 180,
    },
    {
      title: intl.formatMessage({ id: 'reportbenefitDeductReportColOT1half', defaultMessage: 'OT x 1.5 (hr)' }),
      dataIndex: 'ot1half',
      key: 'ot1half',
      width: 180,
      sorter: true,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: 'reportbenefitDeductReportColOT2', defaultMessage: 'OT x 2 (hr)' }),
      dataIndex: 'ot2x',
      key: 'ot2x',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      width: 180,
    },
    {
      title: intl.formatMessage({ id: 'reportbenefitDeductReportColOT3', defaultMessage: 'OT x 3 (hr)' }),
      dataIndex: 'ot3x',
      key: 'ot3x',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      width: 180,
    },
    {
      title: intl.formatMessage({ id: 'reportbenefitDeductReportColOTAll', defaultMessage: 'OT x All day (hr)' }),
      dataIndex: 'ot_all',
      key: 'ot_all',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      width: 180,
    },
    {
      title: intl.formatMessage({ id: 'reportbenefitDeductReportColAbsent', defaultMessage: 'Absent (hr)' }),
      dataIndex: 'absent_minute',
      key: 'absent_minute',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      width: 180,
    },
    {
      title: intl.formatMessage({ id: 'reportbenefitDeductReportColLeave', defaultMessage: 'Leave w/o paid (hr)' }),
      dataIndex: 'leave_no_earn_minute',
      key: 'leave_no_earn_minute',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      width: 180,
    },
    {
      title: intl.formatMessage({ id: 'reportbenefitDeductReportColClockedLate', defaultMessage: 'Clocked Late (hr)' }),
      dataIndex: 'late_minute',
      key: 'late_minute',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      width: 180,
    },
    {
      title: intl.formatMessage({ id: 'reportbenefitDeductReportColClockoutearly', defaultMessage: 'Clock out early (hr)' }),
      dataIndex: 'early_minute',
      key: 'early_minute',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      width: 180,
    },
    {
      title: intl.formatMessage({ id: 'reportbenefitDeductReportColNoClockIn', defaultMessage: 'No Clock In (hr)' }),
      dataIndex: 'no_clockin_minute',
      key: 'no_clockin_minute',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      width: 180,
    },
    {
      title: intl.formatMessage({ id: 'reportbenefitDeductReportCoNoClockOut', defaultMessage: 'No Clock Out (hr)' }),
      dataIndex: 'no_clockout_minute',
      key: 'no_clockout_minute',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      width: 180,
    },
  ];

  const setShowColumn = {
    index: true,
    emp_code: true,
    member_name: true,
    team_name: true,
    email: false,
    ot1x: false,
    ot1half: false,
    ot2x: false,
    ot3x: false,
    ot_all: false,
    absent_minute: true,
    leave_no_earn_minute: true,
    late_minute: true,
    early_minute: true,
    no_clockin_minute: true,
    no_clockout_minute: true,
  };

  const setShowColumnArr = [
    'index',
    'emp_code',
    'member_name',
    'team_name',
    'absent_minute',
    'leave_no_earn_minute',
    'late_minute',
    'early_minute',
    'no_clockin_minute',
    'no_clockout_minute',
  ];
  const comCode = localStorage.getItem('comCode');
  const ignoreColumn = ['email', 'ot1x', 'ot1half', 'ot2x', 'ot3x', 'ot_all'];
  const newColumns = columns.filter((col) => !ignoreColumn.includes(col.key));

  const today = new Date();
  const dateFormat = 'ddd, MMM DD YYYY';
  const dateNow = moment(today).format('YYYY-MM');
  const dateNowRangePicker = [moment(today, dateFormat), moment(today, dateFormat)];

  const [loading, setLoading] = useState(false);
  const [benefitDeductReport, setBenefitDeductReport] = useState([]);

  const [visible, setVisible] = useState(false);
  const [defaultShowColumn, setDefaultShowColumn] = useState({ ...setShowColumn });
  const [listArrayColumns, setListArrayColumns] = useState([...setShowColumnArr]);

  const [paginationPage, setPaginationPage] = useState(1);
  const [paginationSize, setPaginationSize] = useState(10);

  const [textErrorSelectColumn, setTextErrorSelectColumn] = useState('');
  const [newDataColumns, setNewDataColumns] = useState([...newColumns]);

  const [selectSearchData, setSelectSearchData] = useState('all');
  const [fieldChang, setFieldChang] = useState('');
  const [refreshTable, setRefreshTable] = useState(false);

  const [rangePicker, setRangePicker] = useState(dateNowRangePicker);

  const [fromDate, setFromDate] = useState(
    moment(today)
      .startOf('month')
      .format('YYYY-MM-DD')
  );
  const [toDate, setToDate] = useState(
    moment(today)
      .endOf('month')
      .format('YYYY-MM-DD')
  );
  const [total, setTotal] = useState();
  const [extraSort, setExtraSort] = useState();
  const [fieldSort, setFieldSort] = useState();
  const [orderSort, setOrderSort] = useState();
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    getBenefitDeduct();
  }, [fieldChang, fromDate, toDate, state.checkedKeys, extraSort, selectSearchData, state.selectedKeys, newDataColumns]);

  const getBenefitDeduct = async () => {
    setLoading(true);

    // const orgDataNew = _.size(state.defaultCheckedKeys) !== _.size(state.checkedKeys) ? state.checkedKeys : 'all'

    // const xxx = _.size(state.checkedKeys) !== 0 ? state.checkedKeys : _.size(state.selectedKeys) !== 0 ? state.selectedKeys : 'all';
    const orgDataNew = _.size(state.checkedKeys) !== 0 ? state.checkedKeys : _.size(state.selectedKeys) !== 0 ? state.selectedKeys : [];

    let search = {};
    if (selectSearchData !== 'all') {
      const result = { [selectSearchData]: fieldChang };
      search = result;
    } else {
      const filterData = newDataColumns.filter((item) => ['ot1x', 'ot1half', 'ot2x', 'ot3x', 'ot_all', 'index'].indexOf(item.key) === -1);
      const result = filterData.reduce((obj, cur) => ({ ...obj, [cur.key]: fieldChang }), {});
      search = result;
    }

    try {
      const body = {
        teamsSelected: orgDataNew,
        search: search,
        pageNumber: fieldChang === '' ? paginationPage : 1,
        limit: paginationSize,
        orderType: orderSort,
        orderBy: fieldSort,
      };

      const response = await httpClient.post(
        `/v4/resource/manager/company/${comCode}/benefit-deduct/report/from/${fromDate}/to/${toDate}`,
        body
      );
      if (response.status === 200) {
        setBenefitDeductReport(_.get(response.data.data, 'table'));
        setTotal(_.get(response.data.data, 'totalRows'));
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const checkedValuecolumns = (e) => {
    let checked = e.target.checked;
    let val = e.target.value;
    let showColumn = { ...defaultShowColumn };
    if (checked === true) {
      const found = listArrayColumns.find((element) => element === val);
      if (found === undefined) {
        setListArrayColumns([...listArrayColumns, val]);
      }
      showColumn[val] = true;
    } else {
      const newListArray = [...listArrayColumns];
      const found = listArrayColumns.findIndex((element) => element === val);
      if (found >= 0) {
        newListArray.splice(found, 1);
        setListArrayColumns(newListArray);
      }
      showColumn[val] = false;
    }
    setDefaultShowColumn(showColumn);
  };

  const handleCheckOk = () => {
    const setNewArr = [];
    if (listArrayColumns.length <= 4) {
      setTextErrorSelectColumn(<FormattedMessage id="lblSelectAtLeastColumn" defaultMessage="Select at least 1 column." />);
      return;
    } else {
      setTextErrorSelectColumn('');
    }
    for (let i = 0; i < columns.length; i++) {
      const found = listArrayColumns.find((element) => element == columns[i].key);
      if (found != undefined) {
        setNewArr.push(columns[i]);
      }
    }
    setNewDataColumns(setNewArr);
    handleOnVisiblecolumns();
  };

  const handleReset = () => {
    const newListArrayColumns = [];
    newColumns.forEach((element) => {
      newListArrayColumns.push(element.key);
    });
    setNewDataColumns(newColumns);
    setListArrayColumns(newListArrayColumns);
    handleOnVisiblecolumns();
    setDefaultShowColumn(setShowColumn);
    setTextErrorSelectColumn('');
  };

  const handleOnVisiblecolumns = () => {
    setVisible(!visible);
  };

  const handleVisibleChange = (flag) => {
    setVisible(flag);
    if (!flag) {
      // FNC คืนค่า Default อัติโนมัติ
      const newListArrayColumns = [];
      newDataColumns.forEach((element) => {
        newListArrayColumns.push(element.key);
      });

      const reduceOBJ = _.reduce(
        newListArrayColumns,
        function(result, value, key) {
          result[value] = true;
          return result;
        },
        {}
      );
      setListArrayColumns(newListArrayColumns);
      setDefaultShowColumn(reduceOBJ);
      setTextErrorSelectColumn('');
    }
  };

  const handleSearch = debounce((fieldChang) => {
    setFieldChang(fieldChang ? fieldChang : '');
  }, 500);

  const handleSelectData = (value) => {
    setSelectSearchData(value === undefined ? 'all' : value);
  };

  const onSelectMonthPicker = (date) => {
    const startOfMonth = moment(date, 'YYYY/MM')
      .startOf('month')
      .format('YYYY-MM-DD');
    const endOfMonth = moment(date, 'YYYY/MM')
      .endOf('month')
      .format('YYYY-MM-DD');
    setFromDate(startOfMonth);
    setToDate(endOfMonth);
  };

  const onSelectRangePicker = (date, dateString) => {
    setRangePicker(dateString);
    setFromDate(moment(date[0]).format('YYYY-MM-DD'));
    setToDate(moment(date[1]).format('YYYY-MM-DD'));
  };

  const handlePagination = (page) => {
    setPaginationPage(page);
  };

  const handleSizeChange = (current, size) => {
    setPaginationSize(size);
    setPaginationPage(current);
  };

  const handleChange = (pagination, sorter, extra) => {
    let field = extra.field;
    if (extra.order) {
      if (extra.order === 'descend') {
        setOrderSort('desc');
      } else {
        setOrderSort('asc');
      }
    } else {
      setOrderSort('');
    }
    setExtraSort(extra);
    setFieldSort(field);
  };

  const handleExport = async () => {
    setDisabled(true);

    // const orgDataNew = _.size(state.defaultCheckedKeys) !== _.size(state.checkedKeys) ? state.checkedKeys : 'all'
    const orgDataNew = _.size(state.checkedKeys) !== 0 ? state.checkedKeys : _.size(state.selectedKeys) !== 0 ? state.selectedKeys : [];
    let search = {};
    if (selectSearchData !== 'all') {
      const result = { [selectSearchData]: fieldChang };
      search = result;
    } else {
      const filterData = newDataColumns.filter((item) => item.key !== 'index');
      const result = filterData.reduce((obj, cur) => ({ ...obj, [cur.key]: fieldChang }), {});
      search = result;
    }

    try {
      const body = {
        teamsSelected: orgDataNew,
        search: search,
        pageNumber: fieldChang === '' ? paginationPage : 1,
        limit: paginationSize,
        orderType: orderSort,
        orderBy: fieldSort,
        viewFormat: [
          {
            colName: intl.formatMessage({ id: 'reportbenefitDeductColEmployee', defaultMessage: 'Employee Code' }),
            sequence: 1,
            colCode: 'emp_code',
          },
          {
            colName: intl.formatMessage({ id: 'reportbenefitDeductReportColName', defaultMessage: 'Name' }),
            sequence: 2,
            colCode: 'member_name',
          },
          {
            colName: intl.formatMessage({ id: 'reportbenefitDeductReportColTeam', defaultMessage: 'Team' }),
            sequence: 3,
            colCode: 'team_name',
          },
          {
            colName: intl.formatMessage({ id: 'reportbenefitDeductReportColEmail', defaultMessage: 'Email' }),
            sequence: 4,
            colCode: 'email',
          },
          {
            colName: intl.formatMessage({ id: 'reportbenefitDeductReportColOT1', defaultMessage: 'OT x 1 (hr)' }),
            sequence: 5,
            colCode: 'ot1x',
          },
          {
            colName: intl.formatMessage({ id: 'reportbenefitDeductReportColOT1half', defaultMessage: 'OT x 1.5 (hr)' }),
            sequence: 6,
            colCode: 'ot1half',
          },
          {
            colName: intl.formatMessage({ id: 'reportbenefitDeductReportColOT2', defaultMessage: 'OT x 2 (hr)' }),
            sequence: 7,
            colCode: 'ot2x',
          },
          {
            colName: intl.formatMessage({ id: 'reportbenefitDeductReportColOT3', defaultMessage: 'OT x 3 (hr)' }),
            sequence: 8,
            colCode: 'ot3x',
          },
          {
            colName: intl.formatMessage({ id: 'reportbenefitDeductReportColOTAll', defaultMessage: 'OT x All day (hr)' }),
            sequence: 9,
            colCode: 'ot_all',
          },
          {
            colName: intl.formatMessage({ id: 'reportbenefitDeductReportColAbsent', defaultMessage: 'Absent (hr)' }),
            sequence: 10,
            colCode: 'absent_minute',
          },
          {
            colName: intl.formatMessage({ id: 'reportbenefitDeductReportColLeave', defaultMessage: 'Leave w/o paid (hr)' }),
            sequence: 11,
            colCode: 'leave_no_earn_minute',
          },
          {
            colName: intl.formatMessage({ id: 'reportbenefitDeductReportColClockedLate', defaultMessage: 'Clocked Late (hr)' }),
            sequence: 12,
            colCode: 'late_minute',
          },
          {
            colName: intl.formatMessage({ id: 'reportbenefitDeductReportColClockoutearly', defaultMessage: 'Clock out early (hr)' }),
            sequence: 13,
            colCode: 'early_minute',
          },
          {
            colName: intl.formatMessage({ id: 'reportbenefitDeductReportColNoClockIn', defaultMessage: 'No Clock In (hr)' }),
            sequence: 14,
            colCode: 'no_clockin_minute',
          },
          {
            colName: intl.formatMessage({ id: 'reportbenefitDeductReportCoNoClockOut', defaultMessage: 'No Clock Out (hr)' }),
            sequence: 15,
            colCode: 'no_clockout_minute',
          },
        ],
      };
      const response = await httpClient.post(
        `/v4/resource/manager/company/${comCode}/benefit-deduct/report/from/${fromDate}/to/${toDate}/export`,
        body
      );
      if (response.status === 200) {
        window.open(response.data.data.url);
        successNotification(response.data.status.message);
        setDisabled(false);
      } else {
        errorNotification(response.data.status.message);
      }
    } catch (error) {
      //errorNotification(error.response.data.status.message);
    }
  };

  const successNotification = (message) => {
    notification.success({
      message: message,
    });
  };

  const errorNotification = (message) => {
    notification.success({
      message: message,
    });
  };

  const showTotal = (total) => {
    return (
      <span style={{ fontSize: '13px' }}>
        <FormattedMessage id="lblRowPerPage" defaultMessage="Rows per page: " />
      </span>
    );
  };

  const iconExcel = () => <img className="icon-excel" src={IconExcel} />;

  const HeaderAndToggle = () => {
    return (
      <div style={{ display: 'flex' }}>
        <div style={{ cursor: 'pointer' }} onClick={fnc.handleToggle}>
          <Icon type={state.toggle ? 'menu-unfold' : 'menu-fold'} style={{ color: '#e73845' }} />
        </div>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <div>
          <p>
            <FormattedMessage id="reportBenefitReportTitleCard" defaultMessage="Benefits & Deductions" />
          </p>
        </div>
      </div>
    );
  };

  return (
    <OverTimeContext.Consumer>
      {() => (
        <Card
          className="overtime-report-card"
          title={<HeaderAndToggle />}
          extra={
            // <Button className="button-link-export" type="link" onClick={handleExport} disabled={disabled}>
            //   <FormattedMessage id="btnExport" defaultMessage="Export" /> <Icon component={iconExcel} />
            // </Button>
            <Button_01 type="primary" onClick={handleExport}>
              <FormattedMessage id="btnExport" defaultMessage="Export" />
            </Button_01>
          }
        >
          <ActionEquipmentReport
            columns={columns}
            handleReset={handleReset}
            handleCheckOk={handleCheckOk}
            visible={visible}
            handleVisibleChange={handleVisibleChange}
            checkedValuecolumns={checkedValuecolumns}
            onChange={handleSearch}
            onSelect={handleSelectData}
            selectSearchData={selectSearchData}
            checked={defaultShowColumn}
            textErrorSelectColumn={textErrorSelectColumn}
            handleOnVisiblecolumns={handleOnVisiblecolumns}
            onSelectRangePicker={onSelectRangePicker}
            onSelectMonthPicker={onSelectMonthPicker}
          />

          <Table
            className="report-table"
            rowKey={(record) => record.index}
            onChange={handleChange}
            loading={loading}
            dataSource={benefitDeductReport}
            columns={newDataColumns}
            // scroll={{ x: 2500, y: 780 }}
            scroll={{ x: 1500, y: `calc(100vh - 392px)` }}
            pagination={{
              total: total,
              showTotal: showTotal,
              defaultCurrent: 1,
              pageSizeOptions: ['10', '20', '30', '40', '50'],
              showSizeChanger: true,
              locale: { items_per_page: '' },
              onChange: handlePagination,
              onShowSizeChange: handleSizeChange,
            }}
          />

          {benefitDeductReport.length !== 0 ? (
            <div className="total-items-overtime-report">
              <span style={{ fontSize: '13px' }}>
                <FormattedMessage id="lblTotal" defaultMessage="Total" />
                {` ${total || 0} `}
                <FormattedMessage id="lblitems" defaultMessage="items" />
              </span>
            </div>
          ) : null}
        </Card>
      )}
    </OverTimeContext.Consumer>
  );
};
