import { Button, Col, Icon, Modal, Row, Spin, Tabs } from 'antd';
import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { StickyContainer, Sticky } from 'react-sticky';
import NoDataBackground2 from '../../../components/no-data-new';
import Button_02 from '../../../components/v2/button_02';
import Button_07 from '../../../components/v2/button_07';
import { errorNotification, successNotification } from '../../../components/v2/notification';
import { deleteWareHouse, getWareHouseDetail, updateWarehouseDetail } from '../../../controllers/warehouse/warehouse';
import WarehouseContext from '../context';
import CardWarehouseLocation from '../warehouse-location';
import CardWarehouseSetting from '../warehouse-setting';
import CardWarehouseStock from '../warehouse-stock';
import CardWarehouseMovement from '../warehouse-movement';
import './css/index.css';
import DetailsPage from './warehouse-detail';
import { getRole } from '../../../controllers/user/role';
import { PageSettings } from '../../../config/page-settings';

const { TabPane } = Tabs;

const CardWarehouseDetails = () => {
  const app = useContext(PageSettings);
  const { checkPermissionAction } = app;
  const intl = useIntl();
  const { state, setState, fnc } = useContext(WarehouseContext);
  const {
    setWarehouseManagerList,
    setWarehouseServiceDataList,
    setToggle,
    setWarehouseDetail,
    setWarehouseActive,
    setWarehouse,
    setTrigger,
    setWarehouseId,
    setLoadingComponent,
    setKey,
    setRoleCheck,
  } = setState;
  const { toggle, warehouseId, warehouseActive, trigger, warehouse, loadingComponent, key,warehouseDetail } = state;
  const { handleDeleteWarehouse } = fnc;

  useEffect(() => {
    setLoadingComponent(true);
    const getApi = async () => {
      const payload = {
        warehouseId: warehouseId,
      };
      const response = await getWareHouseDetail(payload);
      setWarehouseDetail(_.get(response.data, 'data'));
      setWarehouseActive(_.get(response.data, 'data.statusActive'));
      setWarehouseServiceDataList(_.get(response.data, 'data.serviceTime') || []);
      setWarehouseManagerList(_.get(response.data, 'data.warehouseManager') || []);
      if (_.get(response.data, 'data')) {
        const memComId = localStorage.getItem('memComId');
        const responseRole = await getRole(memComId);

        let checkMember = false;
        let checkManage = false;
        const memberData = _.get(response, 'data.data.memberWarehouse') || [];
        const manageData = _.get(response, 'data.data.warehouseManager') || [];
        const roleData = _.get(responseRole, 'data') || [];
        for (let index = 0; index < memberData.length; index++) {
          const element = memberData[index];
          if (element.memComId === memComId) {
            checkMember = true;
          }
        }

        for (let index = 0; index < manageData.length; index++) {
          const element = manageData[index];
          for (let i = 0; i < element.role.length; i++) {
            const elementI = element.role[i];
            for (let e = 0; e < roleData.length; e++) {
              const elementE = roleData[e];
              if (elementE.per_gro_id === parseFloat(elementI.roleId)) {
                checkManage = true;
              }
            }
          }
          for (let i = 0; i < element.user.length; i++) {
            const elementI = element.user[i];
            if (elementI.memComId === memComId) {
              checkManage = true;
            }
          }
        }
        console.log('checkManage', checkManage, checkMember);
        if (checkManage === true || checkMember === true) {
          // console.log('getWareHouseDetail', _.get(response.data, 'data'), responseRole);
          setRoleCheck(true);
        } else {
          setRoleCheck(false);
        }
      }
      setLoadingComponent(false);
    };

    if (warehouseId) {
      getApi();
    }
  }, [warehouseId, trigger]);

  const handleChangeTab = (key) => {
    setKey(key);
  };

  const handleVisibleLeftWarehouse = () => {
    setToggle((current) => !current);
  };

  const handleRenderTopBar = (props, DefaultTabBar) => (
    <Sticky bottomOffset={80}>
      {({ style }) => (
        <Row>
          <Col span={1} className="col-button-hide">
            <Button className="button-disabled-summary" style={{ border: 'unset' }} onClick={handleVisibleLeftWarehouse}>
              <Icon type={toggle ? 'menu-unfold' : 'menu-fold'} style={{ color: '#e73845' }} />
            </Button>
          </Col>
          <Col span={23}>
            <DefaultTabBar {...props} style={{ ...style, zIndex: 1, background: '#fff' }} />
          </Col>
        </Row>
      )}
    </Sticky>
  );

  const operations = (
    <div className="operations-tab-extra-delete-warehouse">
      {checkPermissionAction(`P52PG1C1`, `P52PG1C1A4`) && _.size(_.get(warehouseDetail, 'isManager')) > 0 ? (
        <Button_02 style={{ margin: 'unset' }} onClick={() => handleDeleteWarehouse(warehouseId)}>
          <FormattedMessage id="btnDelete" defaultMessage="Delete" />
        </Button_02>
      ) : null}
    </div>
  );

  return (
    <div
      style={{
        minHeight: 'calc(100vh - 112px)',
        padding: '0px',
        backgroundColor: '#ffffff',
        border: '1px solid #e8e8e8',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {warehouseId ? (
        <Spin spinning={loadingComponent}>
          <StickyContainer style={{ width: '100%' }}>
            <Tabs
              className="warehouse-tabs"
              activeKey={key}
              onChange={handleChangeTab}
              size="large"
              tabBarExtraContent={key === 'details' ? operations : null}
              animated={false}
              renderTabBar={handleRenderTopBar}
            >
              {checkPermissionAction('P52PG1C1', 'P52PG1C1A1') ? (
                <TabPane tab={<FormattedMessage id="warehouseDetail" defaultMessage="Details" />} key="details">
                  <DetailsPage />
                </TabPane>
              ) : null}
              {checkPermissionAction('P52PG1C4', 'P52PG1C4A1') ? (
              <TabPane tab={<FormattedMessage id="warehouseStock" defaultMessage="Stock" />} key="stock">
                <CardWarehouseStock />
              </TabPane>
              ) : null}
              {checkPermissionAction('P52PG1C5', 'P52PG1C5A1') ? (
              <TabPane tab={<FormattedMessage id="warehouseLocation" defaultMessage="Location" />} key="location">
                <CardWarehouseLocation />
              </TabPane>
              ) : null}
              {checkPermissionAction('P52PG1C6', 'P52PG1C6A1') ? (
              <TabPane tab={<FormattedMessage id="warehouseMovement" defaultMessage="Movement" />} key="movement">
                <CardWarehouseMovement />
              </TabPane>
              ) : null}
              {checkPermissionAction('P52PG1C7', 'P52PG1C7A1') ? (
              <TabPane tab={<FormattedMessage id="warehouseSetting" defaultMessage="Setting" />} key="setting">
                <CardWarehouseSetting />
              </TabPane>
              ) : null}
            </Tabs>
          </StickyContainer>
        </Spin>
      ) : (
        <NoDataBackground2
          text1={{ id: 'nodataXWarehouse1', text: 'Please select a warehouse' }}
          text2={{ id: 'nodataXWarehouse2', text: 'Warehouse will appear here.' }}
        />
      )}
    </div>
  );
};

export default CardWarehouseDetails;
