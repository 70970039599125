import React, { useState, useRef, useEffect } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { TreeViewComponent } from "@syncfusion/ej2-react-navigations";
import { successNotification, errorNotification } from '../notification';
import httpClient from '../../../components/axiosClient';
import { closest, addClass } from "@syncfusion/ej2-base";
import { Divider, Input, Icon, Badge, Modal, Collapse, DatePicker, Row, Col } from 'antd';
import _ from 'lodash';
import moment from 'moment';
// import { fncDragTaskPoolApi } from '../gantt-view/fnc-action-api';
import { v4 as uuidv4 } from 'uuid';
import styles from './css/task-pool.css';
import stylesIndex from './css/index.css';
import SettingSvg from '../../../components/image/settings.svg'

const { Panel } = Collapse;

export default ({ stateMain, setStateMain, tabKey, setTotalTaskPool, taskPoolData, setTaskPoolData, triggerApi, setTriggerApi, LangCode }) => {
  const intl = useIntl();
  const comId = localStorage.getItem('comId');
  const memId = localStorage.getItem('memId');
  // const typeApi = LangCode && LangCode === `WorkDesk` ? `menuType=workdesk` : ``
  let allowDragAndDrops = true;
  const treeObj = useRef();

  const [isTreeItemDropped, setIsTreeItemDropped] = useState(false);
  const [draggedItemId, setDraggedItemId] = useState("");
  const [searchTerm, setSearchTerm] = useState(null);
  const [filterResult, setFilterResult] = useState(0);
  const [searchResults, setSearchResults] = useState({
    dataSource: [],
    id: "Id",
    text: "Name"
  });
  const [filterDate, setFilterDate] = useState(null);
  const [isRenderTree, setIsRenderTree] = useState(false);


  let scheduleObjNew = _.get(stateMain, 'scheduleObjNew');



  useEffect(() => {

    if (treeObj.current.dragObj.dragArea !== null) {
      // if (treeObj.dragObj.dragArea !== null && (searchTerm !== null || filterDate !== null)) {

      onFilter(searchTerm, filterDate);
    }


  }, [taskPoolData])

  // useEffect(() => {
  //   controllerTaskPool();
  //   const interval = setInterval(() => {
  //     controllerTaskPool();
  //   }, 300000);
  //   return () => clearInterval(interval);
  // }, [tabKey, stateMain.mainOrgIdArrString, stateMain.valueModalVisibleStatus, stateMain.refreshDateAndTime, stateMain.refreshDataGanttView, stateMain.refreshTaskPool]);

  // const controllerTaskPool = async () => {
  //   let newTaskPoolData = [];
  //   try {
  //     const response = await httpClient.get(`/v3/task/manager/taskpool?com_id=${comId}&org_id=${stateMain.mainOrgIdArrString}`);
  //     if (response.status === 200) {
  //       setTotalTaskPool(response.data.total);
  //       response.data.data.map((item, index) => {
  //         newTaskPoolData.push({
  //           Index: index,
  //           Id: item.taskId,
  //           TaskNo: item.task_no,
  //           OrgName: item.orgName,
  //           CustomerName: item.customerName,
  //           Color: item.statusColor,
  //           TaskTypeName: item.taskTypeName,
  //           StartDateView: item.startdate,
  //           EndDateView: item.enddate,
  //           StartTimeView: item.startTime,
  //           EndTimeView: item.endTime,
  //         })
  //       });
  //       setTaskPoolData(newTaskPoolData);
  //     }
  //   } catch (error) {
  //   }
  // };


  const fields = {
    dataSource: taskPoolData,
    id: "Id",
    text: "Name"
  };

  const onCreate = () => {
    treeObj.current.dragObj.dragArea = "body";
  }



  const onSearchKeyword = (e) => {
    // setSearchTerm(e.target.value.toLowerCase());
    // const excludeColumns = ["Address", "Color", "CustomerName", "EndDateView", "EndTimeView", "FromAddress", "Id",  "Index", "OrgName", "StartDateView", "StartTimeView", "StatusName",  "TaskNo", "TaskTypeName", "ToAddress"];
    // const excludeColumns = [
    //   "Id",
    //   "Color",
    //   "EndDateView",
    //   "EndTimeView",
    //   "Index",
    //   "StartDateView",
    //   "StartTimeView",
    //   "TaskTypeName",
    //   "Planfinish",
    //   "Planstart",
    //   "StatusName",
    //   "TaskTypeName",
    //   "ToAddress",
    //   "FromAddress",
    //   "Address"
    // ];

    const lowercasedValue = e.target.value.toLowerCase();
    const date = filterDate ? filterDate : null;
    onFilter(lowercasedValue, date);

    // if (lowercasedValue.length > -1) {
    //   const taskPoolDataSearch = taskPoolData && taskPoolData.filter((item) => {
    //     return (item.Planstart1 === date || item.Planfinish1 === date || !date) && Object.keys(item).some(key =>
    //       excludeColumns.includes(key) ? false : item[key].toString().toLowerCase().includes(lowercasedValue)
    //     )
    //   });

    //   setSearchResults({
    //     dataSource: taskPoolDataSearch,
    //     id: "Id",
    //     text: "Name"
    //   });
    // } 
    // else {
    //   setSearchResults({
    //     dataSource: taskPoolData,
    //     id: "Id",
    //     text: "Name"
    //   });
    // }
  }

  const onSelectDate = (e) => {
    // const excludeColumns = [
    //   "Id",
    //   "Color",
    //   "EndDateView",
    //   "EndTimeView",
    //   "Index",
    //   "StartDateView",
    //   "StartTimeView",
    //   "TaskTypeName",
    //   "Planfinish",
    //   "Planstart",
    //   "StatusName",
    //   "TaskTypeName",
    //   "ToAddress",
    //   "FromAddress",
    //   "Address"
    // ];


    // if (e) {
    const date = e ? e.format('YYYY/MM/DD') : null;

    setFilterDate(date);

    onFilter(searchTerm, date);

    // const dateResult = _.filter(taskPoolData, (o) => {
    //   return (o.Planstart1 === date || o.Planfinish1 === date || !date) && Object.keys(o).some(key =>
    //     excludeColumns.includes(key) ? false : o[key].toString().toLowerCase().includes(searchTerm)
    //   );
    // });

    // setSearchResults({
    //   dataSource: dateResult,
    //   id: "Id",
    //   text: "Name"
    // });
    // } else {


    //   setSearchResults({
    //     dataSource: taskPoolData,
    //     id: "Id",
    //     text: "Name"
    //   });
    // }
  }

  const onFilter = (keyword, date) => {

    const excludeColumns = [
      "Id",
      "Color",
      "EndDateView",
      "EndTimeView",
      "Index",
      "StartDateView",
      "StartTimeView",
      "Planfinish",
      "Planstart",
      "StatusName",
      "ToAddress",
      "FromAddress",
      "Address",
      "TaskTypeName"
    ];

    const filterFirstDay = moment(date, 'YYYY/MM/DD').startOf('day').format('X');
    const filterLastDay = moment(date, 'YYYY/MM/DD').endOf('day').format('X');
    const taskPoolDataSearch = taskPoolData && taskPoolData.filter((item) => {
      // if(item.TaskNo === 'JL210600045') {

      // }

      return (
        (
          (item.Planstart >= filterFirstDay && item.Planstart <= filterLastDay)
          || (item.Planfinish >= filterFirstDay && item.Planfinish <= filterLastDay)
          || (item.Planstart <= filterFirstDay && item.Planfinish >= filterLastDay)
          || !date
        )
        && Object.keys(item).some(key => excludeColumns.includes(key) ? false : item[key].toString().toLowerCase().includes(keyword ? keyword : ''))
      )
    });


    // if(!_.isMatch(searchResults, taskPoolDataSearch)) {
    // setSearchResults({
    //   dataSource: taskPoolDataSearch,
    //   id: "Id",
    //   text: "Name"
    // });
    // }


    treeObj.current.fields = {
      dataSource: taskPoolDataSearch,
      id: 'Id',
      text: 'Name'
    }

    setFilterDate(date);
    setSearchTerm(keyword);
    setFilterResult(_.size(taskPoolDataSearch));

    // if (searchResults.dataSource.length !== taskPoolDataSearch.length) {
    //   setIsRenderTree(!isRenderTree)
    // }
  }

  const treeTemplate = (props, index) => {
    return (
      <div id="waiting">
        <div id="waitdetails">
          <div id="waitcategory">
            <div className='over-text-long' >
              <span className="task-pool-text-no-p-taskNo">
                {props.TaskName}
              </span>
            </div>
            <div className='over-text-long' >
              <span class="task-pool-text-taskname">
                {props.CustomerName}
                {/* - {props.OrgName} */}
              </span>
            </div>
            {
              (props.StartDateView === "Invalid date") && (props.EndDateView === "Invalid date") ?
                null : (
                  <div>
                    <div class="task-pool-text-date-time">
                      <span class="task-pool-text-date">{props.StartDateView}</span>
                      <span class="task-pool-text-time">{props.StartTimeView}</span>
                    </div>
                    <div class="task-pool-text-date-time">
                      <span class="task-pool-text-date">{props.EndDateView}</span>
                      <span class="task-pool-text-time">{props.EndTimeView}</span>
                    </div>
                  </div>
                )
            }
            <div className='space-taskno-org'>
              <span class="task-pool-text-taskname">{props.TaskNo}</span>
              <span class="task-pool-text-team">{props.OrgName}</span>
            </div>
            <Divider className="task-pool-divider" />
          </div>
        </div>
      </div>
    );
  }

  const handleOnClickTaskId = (args) => {
    let getNodeDetails = treeObj.current.getNode(args.node);
    if (getNodeDetails.id) {
      setStateMain.setMainTaskId(getNodeDetails.id);
      setTimeout(() => {
        setStateMain.setVisibleTaskDetailModal(true);
      }, 500);
    }

  }

  const onItemDrag = (event) => {
    if (scheduleObjNew.current.isAdaptive) {
      let classElement = scheduleObjNew.current.element.querySelector(
        ".e-device-hover"
      );
      if (classElement) {
        classElement.classList.remove("e-device-hover");
      }
      if (event.target.classList.contains("e-work-cells")) {
        addClass([event.target], "e-device-hover");
      }
    }
    if (document.body.style.cursor === "not-allowed") {
      document.body.style.cursor = "";
    }
    if (event.name === "nodeDragging") {
      let dragElementIcon = document.querySelectorAll(
        ".e-drag-item.treeview-external-drag .e-icon-expandable"
      );
      for (let i = 0; i < dragElementIcon.length; i++) {
        dragElementIcon[i].style.display = "none";
      }
    }
  }

  const onTreeDragStop = (event) => {
    const taskCurrent = scheduleObjNew.current.getEvents();


    let treeElement = closest(event.target, ".e-treeview");
    let classElement = scheduleObjNew.current.element.querySelector(".e-device-hover");

    if (classElement) {
      classElement.classList.remove("e-device-hover");
    }

    if (!treeElement) {
      event.cancel = true;
      let scheduleElement = closest(event.target, ".e-content-wrap");

      if (scheduleElement) {

        let treeviewData = treeObj.current.treeData;


        if (event.target.classList.contains("e-work-cells")) {
          const filteredData = treeviewData.filter(
            item => item.Id === event.draggedNodeData.id
          );
          let cellData = scheduleObjNew.current.getCellDetails(event.target);
          let resourceDetails = scheduleObjNew.current.getResourcesByIndex(
            cellData.groupIndex
          );
          setIsTreeItemDropped(true);
          setDraggedItemId(event.draggedNodeData.id);



          // const dateTimeApi = 'YYYY-MM-DD HH:mm:ss';
          // // const StartTime: moment.unix(item.startTime).format(dateTimeApi),
          // // const EndTime: moment.unix(item.endTime).format(dateTimeApi),

          // let cellStartTime = moment(cellData.startTime, dateTimeApi).format(dateTimeApi);;

          // let startDate = moment.unix(filteredData[0].Planstart).format(dateTimeApi);
          // let endDate =  moment.unix(filteredData[0].Planfinish).format(dateTimeApi);


          // const timeDistance = moment.utc(moment(endDate).diff(moment(startDate))).format("HH:mm:ss");


          // const newEventData = {
          //   OrgId: resourceDetails.groupData.OrgId,
          //   ResourceId: resourceDetails.groupData.ResourceId,
          //   memComOldId: resourceDetails.resourceData.memComId,
          //   StartDate: filteredData[0].StartDateView,
          //   DueDate: filteredData[0].EndDateView,
          //   StartTime: cellStartTime,
          //   EndTime: cellEndTime,
          //   Color: filteredData[0].Color,
          //   CustomerName: filteredData[0].CustomerName,
          //   Id: filteredData[0].Id,
          //   TaskNo: filteredData[0].TaskNo,
          //   Subject: filteredData[0].TaskTypeName,
          //   Status: filteredData[0].StatusName,
          //   ToAddress: filteredData[0].ToAddress,
          //   AssigneeName: filteredData[0].Address,
          //   Reference: "",
          // };

          // scheduleObjNew.current.addEvent([newEventData]);

          Modal.confirm({
            className: "task-pool-confirm-assign-task",
            title: intl.formatMessage({ id: `monitorTabTaskPoolModalAssignTitleWorkDesk`, defaultMessage: 'Are you sure to confirm assign task ?' }),
            centered: true,
            icon: null,
            okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
            cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
            okButtonProps: {
              style: {
                width: '96px',
                background: '#6490cf',
                border: '1px solid #6490cf',
                borderRadius: '8px',
                hover: {
                  background: '#ffffff',
                  color: '#6490cf',
                },
              },
              type: 'primary',
            },
            cancelButtonProps: {
              style: {
                width: '96px',
                border: '1px solid #6490cf',
                borderRadius: '8px',
                backgroundColor: '#ffffff',
                color: '#6490CF',
                
              },
              type: 'primary',
            },
            async onOk() {
              let resourceId = _.get(resourceDetails, 'groupData.ResourceId');
              let startTime = moment(cellData.startTime,).unix();
              let endTime = moment(cellData.endTime,).unix();
              let groupTeam = _.get(resourceDetails, 'groupData.OrgId');
              let taskId = filteredData[0].Id;


              try {
                const body = {
                  memComId: resourceId === 999999 ? null : resourceId,
                  planStart: startTime,
                  planFinish: endTime,
                  orgId: groupTeam,
                  memId: Number(memId)
                };

                const response = await httpClient.put(
                  `/v3/task/manager/company/${comId}/monitor/task/${taskId}/reassign/taskpool?menuType=workdesk`,
                  body,
                );



                if (response.status === 200) {
                  successNotification(response.data.status.message);
                  setTriggerApi(!triggerApi)
                  const newEventData = {
                    OrgId: parseInt(response.data.data.orgId),
                    ResourceId: parseInt(response.data.data.memComId),
                    memComOldId: parseInt(response.data.data.memComId),
                    StartDate: response.data.data.planStart,
                    DueDate: response.data.data.planFinish,
                    StartTime: response.data.data.startDatetime,
                    EndTime: response.data.data.dueDatetime,
                    Color: response.data.data.statusColor,
                    CustomerName: response.data.data.customer,
                    // Id: response.data.data.taskId,
                    Id: uuidv4(),
                    TaskId: response.data.data.taskId,
                    TaskNo: response.data.data.taskNo,
                    Subject: response.data.data.taskTypeName,
                    Status: response.data.data.statusName,
                    ToAddress: response.data.data.toLocation,
                    AssigneeName: response.data.data.AssigneeName,
                    Reference: response.data.data.reference_1,
                    Remark: response.data.data.remark,
                  };


                  scheduleObjNew.current.addEvent([newEventData]);
                  // setStateMain.setRefreshApiGanttView(!stateMain.refreshApiGanttView);
                  setStateMain.setMainTaskId(null);
                } else {
                  errorNotification(response.data.status.message);
                }
              } catch (error) {
                if (error.response.status === 400) {
                  errorNotification(error.response.statusText);
                }
                if (error.response.status === 500) {
                  errorNotification(error.response.data.error);
                }
              }
            }
          });
        }
      }
    }
  }

  const deleteItemTaskPoolData = (taskId) => {
    if (taskId) {
      const newData = taskPoolData.filter(item => item.Id !== taskId);
      setTaskPoolData(newData);
    }
  }

  const callback = (key) => {
  }

  const filterSvg = () => <img className="filterSvg" src={SettingSvg} />
  const headerTitle = (res) => {
    return (

      <div style={{ display: 'flex', padding: '0px 0px 0px 14px', marginTop: '2px' }}>
        <span className='color-filter'><FormattedMessage id={`monitorTaskPoolTextFilterTitleWorkDesk`} defaultMessage="Filter" /></span>&nbsp; {(res.dataSource && res.dataSource.length >= 0) && (searchTerm || filterDate) ?
          <span className='text-result-search'>(<FormattedMessage id={`monitorTaskPoolTextFilterResultWorkDesk`} defaultMessage="Result" />: {filterResult})</span>
          : null}
      </div>
    )
  }

  const extraIcon = (res) => {
    return (
      <div style={{ display: 'flex', padding: '0px 0px 0px 24px', marginTop: '-16px', marginRight: '8px' }}>
        <Badge className='dot-filter' dot={(res.dataSource && res.dataSource.length >= 0) && (searchTerm || filterDate) ? true : false}>
          <Icon component={filterSvg} />
        </Badge>
      </div>
    )
  }

  // const TreeView = (props) => {
  //   const { treeTemplate, searchTerm, filterDate, fields, searchResults, onTreeDragStop, onItemDrag, handleOnClickTaskId, allowDragAndDrops, onCreate } = props;
  //   return (
  //     <TreeViewComponent
  //       ref={tree => treeObj = tree}
  //       cssClass="treeview-external-drag"
  //       nodeTemplate={treeTemplate}
  //       fields={searchTerm === null && filterDate === null ? fields : searchResults}
  //       nodeDragStop={onTreeDragStop}
  //       nodeDragging={onItemDrag}
  //       nodeClicked={handleOnClickTaskId}
  //       allowDragAndDrop={allowDragAndDrops}
  //       created={onCreate}
  //     />
  //   )
  // }
  return (
    <div className='all-object'>
      <div>
        <Collapse
          defaultActiveKey={[]}
          onChange={callback}
          expandIconPosition={false}
          className='header-style'
        >
          <Panel
            key="1"
            className='header-style-panel'
            header={headerTitle(searchResults)}
            showArrow={false}
            extra={extraIcon(searchResults)}
          >
            <br />
            <div className="task-pool-div-input">
              <Input
                className="task-pool-input"
                placeholder={intl.formatMessage({
                  id: 'hintSearch',
                  defaultMessage: 'Search',
                })}
                prefix={<Icon style={{ color: "rgba(0,0,0,.25)" }} type="search" />}
                onChange={onSearchKeyword}
                autoComplete="off"
              />
              <DatePicker
                className='task-pool-date'
                allowClear={true}
                format="ddd, MMM DD, YYYY"
                placeholder='Select date'
                onChange={onSelectDate}
              />
            </div>
          </Panel>
        </Collapse>
      </div>

      <div className="task-pool-div-treeViewComponent">
        {/* <div className="control_wrapper"> */}
        {/* <TreeView
          treeTemplate={treeTemplate}
          searchTerm={searchTerm}
          filterDate={filterDate}
          fields={fields}
          searchResults={searchResults}
          onTreeDragStop={onTreeDragStop}
          onItemDrag={onItemDrag}
          handleOnClickTaskId={handleOnClickTaskId}
          allowDragAndDrops={allowDragAndDrops}
          onCreate={onCreate}
        /> */}
        <TreeViewComponent
          ref={treeObj}
          cssClass="treeview-external-drag"
          nodeTemplate={treeTemplate}
          // fields={searchTerm === null && filterDate === null ? fields : searchResults}
          fields={[]}
          nodeDragStop={onTreeDragStop}
          nodeDragging={onItemDrag}
          nodeClicked={handleOnClickTaskId}
          allowDragAndDrop={allowDragAndDrops}
          created={onCreate}
        />
        {/* </div> */}
      </div>
      {/* <div className='bottom-div align-center'> */}
      <Row className='row-center-text' >
        <Col span={2} />
        <Col span={18}>
          <div className='div-text-center'>
            <p className='text-drag'><Icon type="info-circle" /><FormattedMessage id={`monitorTaskPoolTextFilter1WorkDesk`} defaultMessage=" You can schedule and assign tasks " /> </p>
            <p className='text-drag2'><FormattedMessage id={`monitorTaskPoolTextFilter2WorkDesk`} defaultMessage="from Task Pool to Gantt Chart with" /></p>
            <p className='text-drag2'><FormattedMessage id={`monitorTaskPoolTextFilter3WorkDesk`} defaultMessage="drag and drop function." /></p>
          </div>
        </Col>
        <Col span={2} />
      </Row>
      {/* 
      </div> */}
    </div >
  )
}