import React, { useState, useEffect, useRef, useContext } from 'react';
import { Modal, Form, Divider, Table, Row, Col, DatePicker, Select, Input, AutoComplete, Popover, Button, Icon } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import _ from 'lodash';
import moment from 'moment';
import { getVendor } from '../../../../controllers/customer-vendor/customer-vendor';
import { useDebounce } from '../../../../controllers/debounce';
import { fncGetUsers } from '../../../../controllers/user/users';
import LabeRequireForm from '../../../../components/label-required-form';
import Button_01 from '../../../../components/v2/button_01';
import Button_02 from '../../../../components/v2/button_02';
import { VoucherContext } from '../../voucher-context';
import { errorNotification, successNotification } from '../../../../components/v2/notification';
import { getMemberIssueBy } from '../../../../controllers/quotation/quotation-api-new';
import { getCustomerLoadMore } from '../../../../controllers/getCustomer/get-customer-new';
import { handleKeyDownNumber } from '../../../../component-function/fnc-number';
import { numberFormatter } from '../../../../component-function/fnc-inputnumber-currency';
import { cancelVoucherDetail, getVoucherDetail, saveVoucher } from '../../../../controllers/voucher/voucher';
import ModalCancelVoucher from '../cancel/cancel-modal';
import './index.css';
import LabeRequireFormHint from '../../../../components/label-required-form/LabeRequireFormHint';

const { Option } = Select;
const { TextArea } = Input;

const ModalViewVouchers = (props) => {
  const { state, setState, fnc } = useContext(VoucherContext);
  const { visible, setVisible, form } = props;
  const { totalBrand, brandList, dataModel, brandId, voucherId, isFetch } = state;
  const { setBrandId, setTotalBrand, setTrigger, setTypeView, setVoucherId, setIsFetch } = setState;
  const { handleBrandPopupScroll, handleModelPopupScroll, handleEdit } = fnc;
  const { getFieldDecorator, setFieldsValue, resetFields, validateFields, getFieldsValue } = form;
  const intl = useIntl();

  const ref = useRef();

  const [lotNo, setLotNo] = useState([]);
  const [itemCodeList, setItemCodeList] = useState([]);
  const [itemNameList, setItemNameList] = useState([]);
  const [userData, setUserData] = useState([]);
  const [vendorData, setVendorData] = useState([]);
  const [podata, setPOdata] = useState([]);
  const [viewData, setViewData] = useState([]);
  const [brandSelectAll, setBrandSelectAll] = useState(true);
  const [modelSelectAll, setModelSelectAll] = useState(true);
  const [visibleCancel, setVisibleCancel] = useState(false);

  //---------------------------- Select List ---------------------------------//
  const [customerPage, setCustomerPage] = useState(10);
  const [customerLoading, setCustomerLoading] = useState(false);


  useEffect(() => {
    async function getVoucher() {
      try {
        const responase = await getVoucherDetail(voucherId);
        setViewData(_.get(responase, 'data'));
      } catch (error) {}
    }
    if (visible && voucherId !== '') {
      getVoucher();
    }
  }, [visible, isFetch]);

  useEffect(() => {
    if (_.size(podata) > 0) {
      setFieldsValue({ ['purchase']: _.get(podata, 'poNo') });
    }
  }, [podata]);

  const handleCancel = () => {
    setVisible(false);
    resetFields();
    setLotNo([]);
    setPOdata([]);
    setTypeView([]);
    setViewData([]);
    setVoucherId('');
  };

  const checkView =
    _.get(viewData, 'statusCode') === 'canceled' || _.get(viewData, 'statusCode') === 'expired'
      ? [
          <Button_02 key="back" btnsize="wd_df" onClick={handleCancel}>
            <FormattedMessage id="btnClose" defaultMessage="Close" />
          </Button_02>,
        ]
      : [
          <Button_02 key="back" btnsize="wd_df" onClick={handleCancel}>
            <FormattedMessage id="btnClose" defaultMessage="Close" />
          </Button_02>,
          <Button_01
            key="submit"
            type="primary"
            btnsize="wd_df"
            style={{ width: '15%' }}
            onClick={() => {
              setVisibleCancel(true);
            }}
          >
            <FormattedMessage id="btnCancelVoucher" defaultMessage="Cancel Voucher" />
          </Button_01>,
          <Button_01
            key="submit"
            type="primary"
            btnsize="wd_df"
            onClick={() => {
              handleEdit(viewData);
            }}
          >
            <FormattedMessage id="btnEdit" defaultMessage="Edit" />
          </Button_01>,
        ];

  const handleCustomerPopupScroll = (e) => {
    let target = e.target;
    if (!customerLoading && target.scrollTop + target.offsetHeight >= target.scrollHeight - 50) {
      setCustomerPage(customerPage + 10);
      setCustomerLoading(true);
    }
  };

  const handleSaveCancel = async (value) => {
    try {
      const response = await cancelVoucherDetail(voucherId, value);

      if (response) {
        successNotification('Success');
        handleCancelVocuher();
      }
      setTrigger((prev) => !prev);
      setIsFetch((prev) => !prev);
      setVisibleCancel(false);
    } catch (error) {
      errorNotification(_.get(error, 'response.status.message'));
      return;
    }
  };

  const handleCancelVocuher = (value) => {
    setVisibleCancel(false);
  };

  return (
    <Modal
      title={intl.formatMessage({ id: 'menuVoucher', defaultMessage: 'Voucher' }) + ' · ' + _.get(viewData, 'voucherNo')}
      visible={visible}
      onCancel={handleCancel}
      width={1000}
      centered={true}
      style={{ marginTop: '25px' }}
      bodyStyle={{ padding: 'unset', overflowY: 'auto', height: 'calc(100vh - 234px)' }}
      footer={checkView}
      zIndex={998}
    >
      <div>
        <div className="item-title">
          <span className="item-title-text">
            <FormattedMessage id="quotationLabelDetails" defaultMessage="Details" />
          </span>

          <div style={{ width: '15%', paddingTop: '3px', display: 'flex', justifyContent: 'end' }}>
            <p
              style={{
                // backgroundColor: record['statusColor'],
                backgroundColor: _.get(viewData, 'statusColor'),
                color: 'white',
                textAlign: 'center',
                borderRadius: '10px',
                // height: '23px',
                width: '100px',
              }}
            >
              {_.get(viewData, 'statusTxt')}
            </p>
          </div>
        </div>

        <Divider type="horizontal" style={{ marginBottom: '10px', marginTop: '10px' }} />
        <div style={{ padding: '12px 24px 24px 24px' }}>
          <Form form={form} layout="vertical" className="modalReceiveItemForm">
            <Row gutter={[24, 24]}>
              <Col span={16}>
                <Form.Item
                  label={
                    <LabeRequireForm
                      text={intl.formatMessage({ id: 'VoucherName', defaultMessage: 'Voucher Name' })}
                      req={_.size(viewData) > 0 ? false : true}
                    />
                  }
                >
                  <span className="textBold">{_.get(viewData, 'voucherName')}</span>
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label={
                    <LabeRequireForm
                      text={intl.formatMessage({ id: 'voucherVoucherAmount', defaultMessage: 'Voucher Amount' })}
                      req={_.size(viewData) > 0 ? false : true}
                    />
                  }
                >
                  <span className="textBold">{numberFormatter(_.get(viewData, 'used')) + ' / ' +_.get(viewData, 'totalTxt')}</span>
                </Form.Item>
              </Col>

            </Row>

            <Row gutter={[24, 24]}>
              <Col span={8}>
                <Form.Item
                  label={
                    <>
                    <LabeRequireForm
                      text={intl.formatMessage({ id: 'voucherMinSpend', defaultMessage: 'Min Spend' })}
                      req={_.size(viewData) > 0 ? false : true}
                    />
                    <LabeRequireFormHint text={intl.formatMessage({ id: 'voucherPerOrder', defaultMessage: '(Per Order)' })}/>
                    </>
                  }
                >
                  <span className="textBold">{_.get(viewData, 'minSpendTxt')}</span>
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label={
                    <>
                      <LabeRequireForm
                        text={intl.formatMessage({ id: 'voucherMinUse', defaultMessage: 'Min Use' })}
                        req={_.size(viewData) > 0 ? false : true}
                      />
                      <LabeRequireFormHint text={intl.formatMessage({ id: 'voucherPerOrder', defaultMessage: '(Per Order)' })}/>
                    </>
                  }
                >
                  <span className="textBold">{_.get(viewData, 'minUseTxt')}</span>
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label={
                    <>
                      <LabeRequireForm
                        text={intl.formatMessage({ id: 'voucherMaxUse', defaultMessage: 'Max Use' })}
                        req={_.size(viewData) > 0 ? false : true}
                      />
                      <LabeRequireFormHint text={intl.formatMessage({ id: 'voucherPerOrder', defaultMessage: '(Per Order)' })}/>
                    </>
                  }
                >
                  <span className="textBold">{_.get(viewData, 'maxUseTxt')}</span>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[24, 24]}>
              <Col span={8}>
                <Form.Item
                  label={
                    <LabeRequireForm
                      text={intl.formatMessage({ id: 'voucherEffectiveDate', defaultMessage: 'Effective Date' })}
                      req={_.size(viewData) > 0 ? false : true}
                    />
                  }
                >
                  {_.size(viewData) > 0
                    ? <span className="textBold">{_.get(viewData, 'effectiveDateTxt')}</span>
                    : getFieldDecorator('effectivedate', {
                        rules: [
                          {
                            required: true,
                            message: intl.formatMessage({
                              id: 'voucherEffectiveDateValidate',
                              defaultMessage: 'Please Select Effective Date',
                            }),
                          },
                        ],
                      })(
                        <DatePicker
                          style={{ width: '100%' }}
                          format={'ddd, DD MMM YYYY'}
                          placeholder={intl.formatMessage({
                            id: 'voucherEffectiveDatePlaceHolder',
                            defaultMessage: 'Select Effective Date',
                          })}
                        />
                      )}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label={
                    <LabeRequireForm
                      text={intl.formatMessage({ id: 'voucherExpiredDate', defaultMessage: 'Expired Date' })}
                      req={_.size(viewData) > 0 ? false : true}
                    />
                  }
                >
                  {_.size(viewData) > 0
                    ? <span className="textBold">{_.get(viewData, 'expiredDateTxt')}</span>
                    : getFieldDecorator('expireddate', {
                        rules: [
                          {
                            required: true,
                            message: intl.formatMessage({
                              id: 'voucherExpiredDateValidate',
                              defaultMessage: 'Please Select Expired Date',
                            }),
                          },
                        ],
                      })(
                        <DatePicker
                          style={{ width: '100%' }}
                          format={'ddd, DD MMM YYYY'}
                          placeholder={intl.formatMessage({
                            id: 'voucherExpiredDatePlaceHolder',
                            defaultMessage: 'Select Expired Date',
                          })}
                        />
                      )}
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item label={<LabeRequireForm text={intl.formatMessage({ id: 'VoucherReference', defaultMessage: 'Reference' })} />}>
                  {_.size(viewData) > 0
                    ? <span className="textBold">{_.get(viewData, 'reference')}</span>
                    : getFieldDecorator(
                        'reference',
                        {}
                      )(
                        <Input placeholder={intl.formatMessage({ id: 'VoucherReferencePlaceHolder', defaultMessage: 'Enter Reference' })} />
                      )}
                </Form.Item>
              </Col>
            </Row>

            <div style={{ fontWeight: 'bold', color: '#1D3557', marginTop: '32px' }}>
              <FormattedMessage id="VoucherAppliedTo" defaultMessage="Applied To" />
            </div>
            <Divider type="horizontal" />

            <Row gutter={[24, 24]}>

              <Col span={8}>
                <Form.Item
                  label={
                    <LabeRequireForm
                      text={intl.formatMessage({ id: 'VoucherPaidTo', defaultMessage: 'Paid To' })}
                      req={_.size(viewData) > 0 ? false : true}
                    />
                  }
                >
                  <span className="textBold">{_.get(viewData, 'ownerType') === 'company' ? _.get(viewData, 'comCode') + ' ' + _.get(viewData, 'customerName') : _.get(viewData, 'customerCode') + ' ' + _.get(viewData, 'customerName')}</span>
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label={<LabeRequireForm text={intl.formatMessage({ id: 'recivePOBrand', defaultMessage: 'Brand' })} req={false} />}
                >
                  <span className="textBold">{_.get(viewData, 'voucherBrandName')}</span>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label={<LabeRequireForm text={intl.formatMessage({ id: 'recivePOModel', defaultMessage: 'Model' })} />}>
                  <span className="textBold">{_.get(viewData, 'voucherModelName')}</span>
                </Form.Item>
              </Col>
            </Row>

            {/* <Row gutter={[16]}>
              <Col span={12}>
                <Form.Item
                  label={<LabeRequireForm text={intl.formatMessage({ id: 'VoucherTotalAmount', defaultMessage: 'Total Amount' })} req={false} />}
                >
                  <span className="textBold">{_.get(viewData, 'totalTxt')}</span>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label={<LabeRequireForm text={intl.formatMessage({ id: 'VoucherUsedAmount', defaultMessage: 'Used Amount' })} />}>
                  <span className="textBold">{_.get(viewData, 'usedTxt')}</span>
                </Form.Item>
              </Col>
            </Row> */}

            {/* <Row gutter={[16]}>
              <Col span={12}>
                <Form.Item
                  label={
                    <LabeRequireForm
                      text={intl.formatMessage({ id: 'ARBalance', defaultMessage: 'Balance' })}
                      req={_.size(viewData) > 0 ? false : true}
                    />
                  }
                >
                  {_.size(viewData) > 0
                    ? _.get(viewData, 'balaceTxt')
                    : getFieldDecorator('voucheramount', {
                        initialValue: numberFormatter(0),
                        rules: [
                          {
                            required: true,
                            message: intl.formatMessage({
                              id: 'voucherVoucherAmountValidate',
                              defaultMessage: 'Please Enter Voucher Amount',
                            }),
                          },
                        ],
                      })(
                        <Input
                          onKeyDown={handleKeyDownNumber}
                          suffix={intl.formatMessage({ id: 'quotationLabelTHB', defaultMessage: 'THB' })}
                        />
                      )}
                </Form.Item>
              </Col>
              
            </Row> */}
            
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Form.Item
                  label={
                    <LabeRequireForm text={intl.formatMessage({ id: 'modalReceiveRemarkText', defaultMessage: 'Remark' })} req={false} />
                  }
                >
                  {_.size(viewData) > 0
                    ? <span className="textBold">{_.get(viewData, 'description')}</span>
                    : getFieldDecorator('remark', {})(<TextArea autoSize={{ minRows: 4, maxRows: 4 }} maxLength={250} />)}
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
      <ModalCancelVoucher
        title={intl.formatMessage({ id: 'CanceledVoucher', defaultMessage: 'Canceled Vocher' }) + ' · ' + _.get(viewData, 'voucherNo')}
        visible={visibleCancel}
        onOk={handleSaveCancel}
        onCancel={handleCancelVocuher}
        initialReasonList={_.get(viewData, 'reasonCancelList')}
      />
    </Modal>
  );
};

const ModalViewVoucher = Form.create({
  name: 'modal_form',
})(ModalViewVouchers);

export default ModalViewVoucher;
