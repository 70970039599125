import httpClient from '../../components/axiosClient';

const comCode = localStorage.getItem('comCode');

const getListReference = async (payload) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/reference/manager/company/${comCode}/web/referencelist`,payload);
    if (response.status === 200) {
      return response.data;
    } else {
      return response.data;
    }
  } catch (error) {
    return error.responase.data;
  }
};

const savetListReferenceTab = async (payload) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/reference/manager/company/${comCode}/web/reference`, payload);
    if (response.status === 200) {
      return response.data;
    } else {
      return response.data;
    }
  } catch (error) {
    return error.responase.data;
  }
};
// else if (config.url.indexOf('/reference/manager/') !== -1) {
//   port = `${process.env.REACT_APP_REFERENCE_MANAGER_PORT}`;
// }

export {
  getListReference,
  savetListReferenceTab
}