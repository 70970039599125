import React from "react";
import { FormattedMessage } from "react-intl";
import _ from 'lodash'


const LongTitle = (props) => {
  const { no, type } = props
  return (
    <div>
      {
        type === 'edit' ? <FormattedMessage id="pettyCashEdit" defaultMessage="Edit Petty Cash" /> :
          type === 'tranfer' ? <FormattedMessage id="pettyCashTranfer" defaultMessage="Transfer Petty Cash" /> : ""
      }
      ・ {no}
    </div>
  )
}

const ConfigModal = (value) => {
  const type = _.get(value, 'type')
  const data = _.get(value, 'data')
  let config = {};
  if (type === 'create') {
    config = {
      title: <FormattedMessage id="pettyCashCreate" defaultMessage="Create Petty Cash" />,
      width: 660
    }
  } else if (type === 'edit') {
    config = {
      title: <LongTitle no={_.get(data, 'pettyCashNo')} type="edit" />,
      width: 660
    }
  } else if (type === 'tranfer') {
    config = {
      title: <LongTitle no={_.get(data, 'pettyCashNo')} type="tranfer" />,
      width: 730
    }
  } else if (type === 'receive') {
    config = {
      title: <FormattedMessage id="pettyCashReceive" defaultMessage="Receive" />,
      width: 660
    }
  } else if (type === 'paid') {
    config = {
      title: <FormattedMessage id="pettyCashPaid" defaultMessage="Paid" />,
      width: 660
    }
  }
  return config
}

export { ConfigModal }