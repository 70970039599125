import React, { useEffect, useState } from 'react';
import { Select, Checkbox, Table, Form, Input } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import LabeRequireForm from '../../../../../components/label-required-form';
import _ from 'lodash';
import ModalCustomerQuotationForm from '../../select-customer/customer-modal';

const { Option } = Select;
const { Search } = Input;

const ListItemDetail = (props) => {
  const {
    columns,
    handleClickRow,
    onChange,
    vendorData,
    itemList,
    itemListTotal,
    handlePagination,
    handleSizeChange,
    form,
    setDataForm,
    dataForm,
    handleOpen,
    listPage,
    searchGroup
  } = props;
  const intl = useIntl();
  const { getFieldDecorator, getFieldValue } = form;
  // useEffect(() => {
  //   if (getFieldValue('vendor')) {
  //     onChange(getFieldValue('vendor'), 'vendor');
  //   }
  // }, [getFieldValue('vendor')]);

  const showTotal = () => {
    return (
      <span style={{ fontSize: '13px', float: 'left' }}>
        <FormattedMessage id="lblRowPerPage" defaultMessage="Rows per page:" />
      </span>
    );
  };

  return (
    <div style={{ height: '625px', padding: '16px' }}>
      {/* <ModalCustomerQuotationForm
        visible={isOpen}
        onCancel={handleCancel}
        onOk={handleSave}
        setDataForm={setDataForm}
        dataForm={dataForm}
      /> */}
      <div style={{ marginBottom: '24px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }}>
          <Form form={form} layout="vertical" className='po-form'>
            <Form.Item
              label={<LabeRequireForm text={intl.formatMessage({ id: 'quotationLabelCustomer', defaultMessage: 'Customer' })} req={false} />}
              style={{ width: '300px' }}
            >
              {_.get(dataForm, 'customerName') || ''}
              {/* {getFieldDecorator('customerName', {
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'quotationWarnCustomer', defaultMessage: 'please select customer' }),
                  },
                ],
              })(
                <Search
                  placeholder={intl.formatMessage({ id: 'quotationHintCustomer', defaultMessage: 'Select Customer' })}
                  onSearch={(value) => handleOpen()}
                  enterButton
                  className="search-icon-input"
                  readOnly
                />
              )} */}
            </Form.Item>
          </Form>
          <Checkbox onChange={(e) => onChange(e.target.checked, 'purchase')} checked={_.get(searchGroup, 'purchase')}>
            <FormattedMessage id="modalListItemDetailRecent" defaultMessage="Recently Purchased Items" />
          </Checkbox>
        </div>
      </div>
      <div>
        <Table
          columns={columns}
          dataSource={itemList}
          scroll={{ y: 380 }}
          pagination={{
            showTotal: showTotal,
            defaultCurrent: 1,
            current: listPage,
            total: itemListTotal,
            pageSizeOptions: ['10', '20', '30', '40', '50'],
            showSizeChanger: true,
            locale: { items_per_page: '' },
            onChange: handlePagination,
            onShowSizeChange: handleSizeChange,
          }}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                if (!event.target.href) {
                  handleClickRow(record);
                }
              },
            };
          }}
        />
        {itemList && itemList.length !== 0 ? (
          <div className="total-items-timeAttendance">
            <span style={{ fontSize: '13px' }}>
              <FormattedMessage id="lblTotal" defaultMessage="Total" />
              {` ${itemListTotal || 0} `}
              <FormattedMessage id="lblitems" defaultMessage="items" />
            </span>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ListItemDetail;
