import httpClient from '../../components/axiosClient';

const updateImageCompany = async (data) => {
  const comCode = localStorage.getItem('comCode');
  // if (data.file) {
  let formData = new FormData();
  formData.append('file', data.file === 'noimage' ? 'noimage' : data.file);
  try {
    const response = await httpClient.put(`/v3/resource/manager/updatedetailcompany/photo/${comCode}`, formData);
    if (response.status === 200) {
      return response;
    } else {
      return response;
    }
  } catch (error) {
    return error.response;
  }
  // }
}

export default updateImageCompany;