import React, { useState, useEffect, useContext } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useAppContext } from '../../../../includes/indexProvider';
import { Card, Table, Button, notification, Spin, Icon } from 'antd';
import { debounce } from 'lodash';
import { OverTimeContext } from '../report-context';
import ActionTravelReport from './action-travel-report';
import httpClient from '../../../../components/axiosClient';
import _ from 'lodash';
import moment from 'moment';
import IconExcel from '../../../../components/image/excel.svg';
import { PageSettings } from '../../../../config/page-settings';
import cssStyleCenter from '../report-center.css';
import { getStatusTask } from '../../../../controllers/invoice-select/get-select-task';

export default (props) => {
  const { LangCode } = props;
  const intl = useIntl();
  const app = useAppContext();
  const langValue = app.state.langValue;
  const { state, setState, fnc } = useContext(OverTimeContext);
  const comCode = localStorage.getItem('comCode');
  const [firstLoad, sertFirstLoad] = useState(false);

  useEffect(() => {
    const getStatusTaskBegin = async () => {
      sertFirstLoad(true);
      const datastatus = await getStatusTask(comCode);
      if (datastatus) {
        const mapStatus =
          datastatus &&
          datastatus.map((item) => {
            return {
              title: item.txt,
              dataIndex: item.code,
              key: item.code,
              align: 'center',
            };
          });
        const newColumnss = mapStatus && mapStatus.length !== 0 ? columns.concat(mapStatus) : columns;
        let test = {};
        const returnKey =
          mapStatus && mapStatus.length !== 0
            ? mapStatus.map((item) => {
                return (test[item.key] = false);
              })
            : [];
        const concatObject = test !== {} ? { ...setShowColumn, ...test } : setShowColumn;
        const concatArray =
          newColumnss && newColumnss !== {}
            ? newColumnss.map((item) => {
                return item.key.toString();
              })
            : setShowColumnArr;
        const newColumns = newColumnss && newColumnss !== undefined ? newColumnss.filter((col) => !ignoreColumn.includes(col.key)) : [];
        setNewDataColumns([...newColumns]);
        setNewColumnsStatus(newColumnss);
        setDefaultShowColumn({ ...concatObject });
        setDefaultShowColumnsReset({ ...concatObject });
        setExportStatus(mapStatus);
        setTimeout(() => {
          sertFirstLoad(false);
        }, 3000);
      }
    };
    getStatusTaskBegin();
  }, []);

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: 70,
      align: 'center',
      fixed: 'left',
    },
    {
      title: intl.formatMessage({ id: `workDeskTaskSummaryColEmployeeCode`, defaultMessage: 'Employee Code' }),
      dataIndex: 'empCode',
      key: 'empCode',
      width: 160,
      sorter: (a, b) => a.empCode - b.empCode,
      sortDirections: ['descend', 'ascend'],
      fixed: 'left',
    },
    {
      title: intl.formatMessage({ id: `workDeskTaskSummaryColEmployeeName`, defaultMessage: 'Employee Name' }),
      dataIndex: 'Name',
      key: 'Name',
      width: 230,
      sorter: (a, b) => a.Name - b.Name,
      sortDirections: ['descend', 'ascend'],
      fixed: 'left',
    },
    {
      title: intl.formatMessage({ id: `workDeskTaskSummaryColNumberOfTrips`, defaultMessage: 'Number of trips' }),
      dataIndex: 'Round',
      key: 'Round',
      sorter: (a, b) => a.Round - b.Round,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: `workDeskTaskSummaryColNumberOfKilometers`, defaultMessage: 'Number of kilometers' }),
      dataIndex: 'Distance',
      key: 'Distance',
      sorter: (a, b) => a.Distance - b.Distance,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: `workDeskTaskSummaryColRunningHours`, defaultMessage: 'Running hours' }),
      dataIndex: 'Hour',
      key: 'Hour',
      sorter: (a, b) => a.Hour - b.Hour,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: `workDeskTaskSummaryColTravelExpense`, defaultMessage: 'Travel expenses' }),
      dataIndex: 'Summary',
      key: 'Summary',
      sorter: (a, b) => a.Summary - b.Summary,
      sortDirections: ['descend', 'ascend'],
    },
  ];

  const setShowColumn = {
    index: true,
    empCode: true,
    Name: true,
    Round: true,
    Distance: true,
    Hour: true,
    Summary: true,
  };

  const setShowColumnArr = ['index', 'empCode', 'Name', 'Round', 'Distance', 'Hour', 'Summary'];

  const ignoreColumn = ['new', 'scheduled', 'assigned', 'accepted', 'in_progress', 'returned', 'cancelled', 'completed'];
  const [newColumnsStatus, setNewColumnsStatus] = useState();
  const [exportStatus, setExportStatus] = useState([]);

  const newColumns =
    newColumnsStatus && newColumnsStatus !== undefined ? newColumnsStatus.filter((col) => !ignoreColumn.includes(col.key)) : [];

  const today = new Date();
  const dateFormat = 'ddd, MMM DD YYYY';
  const dateNow = moment(today).format('YYYY-MM');
  const dateNowRangePicker = [moment(today, dateFormat), moment(today, dateFormat)];

  const [loading, setLoading] = useState(false);
  const [overtimeReport, setOvertimeReport] = useState([]);

  const [visible, setVisible] = useState(false);
  const [defaultShowColumn, setDefaultShowColumn] = useState({});
  const [listArrayColumns, setListArrayColumns] = useState([...setShowColumnArr]);
  const [defaultShowColumnsReset, setDefaultShowColumnsReset] = useState();

  const [paginationPage, setPaginationPage] = useState(1);
  const [paginationSize, setPaginationSize] = useState(10);

  const [textErrorSelectColumn, setTextErrorSelectColumn] = useState('');
  const [newDataColumns, setNewDataColumns] = useState();

  const [selectSearchData, setSelectSearchData] = useState('all');
  const [fieldChang, setFieldChang] = useState('');
  const [refreshTable, setRefreshTable] = useState(false);

  const [rangePicker, setRangePicker] = useState(dateNowRangePicker);
  const [fromDate, setFromDate] = useState(moment(today).format('YYYY-MM-DD'));
  const [toDate, setToDate] = useState(moment(today).format('YYYY-MM-DD'));
  const [total, setTotal] = useState();
  const [extraSort, setExtraSort] = useState();
  const [fieldSort, setFieldSort] = useState();
  const [orderSort, setOrderSort] = useState();
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    getOvertimeReport();
  }, [fieldChang, rangePicker, state.checkedKeys, extraSort, selectSearchData, state.selectedKeys]);

  const getOvertimeReport = async () => {
    setLoading(true);
    try {
      const body = {
        fromDate: fromDate,
        utilDate: toDate,
        orgId: _.size(state.checkedKeys) !== 0 ? state.checkedKeys : state.selectedKeys,
        search: fieldChang,
        searchBy: selectSearchData,
        paging: fieldChang === '' ? paginationPage : 1,
        rowsPerPages: paginationSize,
        ordertype: orderSort,
        orderby: fieldSort,
      };

      const response = await httpClient.post(`/v3/task/manager/company/${comCode}/getreportsummary?menuType=workdesk`, body);

      if (response.status === 200) {
        setOvertimeReport(_.get(response, 'data.data'));
        setTotal(response.data.allRows);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const checkedValuecolumns = (e) => {
    let checked = e.target.checked;
    let val = e.target.value;
    let showColumn = { ...defaultShowColumn };
    if (checked === true) {
      const found = listArrayColumns.find((element) => element === val);
      if (found === undefined) {
        setListArrayColumns([...listArrayColumns, val]);
      }
      showColumn[val] = true;
    } else {
      const newListArray = [...listArrayColumns];
      const found = listArrayColumns.findIndex((element) => element === val);
      if (found >= 0) {
        newListArray.splice(found, 1);
        setListArrayColumns(newListArray);
      }
      showColumn[val] = false;
    }
    setDefaultShowColumn(showColumn);
  };

  const handleCheckOk = () => {
    const setNewArr = [];
    if (listArrayColumns.length <= 3) {
      setTextErrorSelectColumn(<FormattedMessage id="lblSelectAtLeastColumn" defaultMessage="Select at least 1 column." />);
      return;
    } else {
      setTextErrorSelectColumn('');
    }
    for (let i = 0; i < newColumnsStatus.length; i++) {
      const found = listArrayColumns.find((element) => element == newColumnsStatus[i].key);
      if (found != undefined) {
        setNewArr.push(newColumnsStatus[i]);
      }
    }
    setNewDataColumns(setNewArr);
    handleOnVisiblecolumns();
  };

  const handleReset = () => {
    const newListArrayColumns = [];
    newColumns.forEach((element) => {
      newListArrayColumns.push(element.key);
    });
    setNewDataColumns(newColumns);
    setListArrayColumns(newListArrayColumns);
    handleOnVisiblecolumns();
    setDefaultShowColumn(defaultShowColumnsReset);
    setTextErrorSelectColumn('');
  };

  const handleOnVisiblecolumns = () => {
    setVisible(!visible);
  };

  const handleVisibleChange = (flag) => {
    setVisible(flag);
    if (!flag) {
      // FNC คืนค่า Default อัติโนมัติ
      const newListArrayColumns = [];
      newDataColumns.forEach((element) => {
        newListArrayColumns.push(element.key);
      });

      const reduceOBJ = _.reduce(
        newListArrayColumns,
        function(result, value, key) {
          result[value] = true;
          return result;
        },
        {}
      );
      setListArrayColumns(newListArrayColumns);
      setDefaultShowColumn(reduceOBJ);
      setTextErrorSelectColumn('');
    }
  };

  const handleSearch = debounce((fieldChang) => {
    setFieldChang(fieldChang ? fieldChang : '');
  }, 500);

  const handleSelectData = (value) => {
    setSelectSearchData(value === undefined ? 'all' : value);
  };

  const onSelectRangePicker = (date, dateString) => {
    setRangePicker(dateString);
    setFromDate(moment(date[0]).format('YYYY-MM-DD'));
    setToDate(moment(date[1]).format('YYYY-MM-DD'));
  };

  const handlePagination = (page) => {
    setPaginationPage(page);
  };

  const handleSizeChange = (current, size) => {
    setPaginationSize(size);
    setPaginationPage(current);
  };

  const handleChange = (pagination, sorter, extra) => {
    let field = extra.field;
    if (extra.order) {
      if (extra.order === 'descend') {
        setOrderSort('desc');
      } else {
        setOrderSort('asc');
      }
    } else {
      setOrderSort('');
    }
    setExtraSort(extra);
    setFieldSort(field);
  };

  const handleExport = async () => {
    setDisabled(true);
    let sq = 7;
    const exportColumns =
      exportStatus &&
      exportStatus.map((item) => {
        return {
          colName: item.title,
          sequence: sq++,
          colCode: item.key,
        };
      });

    const formatDefault = [
      {
        colName: intl.formatMessage({ id: 'workdeskreportResourceTaskSummaryColEmployeeCode', defaultMessage: 'Employee Code' }),
        sequence: 1,
        colCode: 'empCode',
      },
      {
        colName: intl.formatMessage({ id: 'workdeskreportResourceTaskSummaryColEmployeeName', defaultMessage: 'Employee Name' }),
        sequence: 2,
        colCode: 'Name',
      },
      {
        colName: intl.formatMessage({ id: 'workdeskreportResourceTaskSummaryColNumberOfTrips', defaultMessage: 'Number of trips' }),
        sequence: 3,
        colCode: 'Round',
      },
      {
        colName: intl.formatMessage({
          id: 'workdeskreportResourceTaskSummaryColNumberOfKilometers',
          defaultMessage: 'Number of kilometers',
        }),
        sequence: 4,
        colCode: 'Distance',
      },
      {
        colName: intl.formatMessage({ id: 'workdeskreportResourceTaskSummaryColRunningHours', defaultMessage: 'Running hours' }),
        sequence: 5,
        colCode: 'Hour',
      },
      {
        colName: intl.formatMessage({ id: 'workdeskreportResourceTaskSummaryColTravelExpense', defaultMessage: 'Travel expenses' }),
        sequence: 6,
        colCode: 'Summary',
      },
    ];

    const ExportData = exportColumns && exportColumns.length !== 0 ? formatDefault.concat(exportColumns) : formatDefault;

    try {
      const body = {
        fromDate: fromDate,
        utilDate: toDate,
        orgId: state.checkedKeys,
        search: fieldChang,
        searchBy: selectSearchData,
        paging: paginationPage === 1 ? paginationPage : 1,
        rowsPerPages: total,
        orderby: orderSort,
        ordertype: fieldSort,
        viewFormat: ExportData,
      };
      const response = await httpClient.post(`/v3/task/manager/company/${comCode}/getreportsummary/export?menuType=workdesk`, body);

      if (response.status === 200) {
        window.open(response.data.data.url);
        successNotification(response.data.status.message);
        setDisabled(false);
      } else {
        errorNotification(response.data.status.message);
      }
    } catch (error) {
      return;
      //errorNotification(error.response.data.status.message);
    }
  };

  const successNotification = (message) => {
    notification.success({
      message: message,
    });
  };

  const errorNotification = (message) => {
    notification.success({
      message: message,
    });
  };

  const showTotal = (total) => {
    return (
      <span style={{ fontSize: '13px' }}>
        <FormattedMessage id="lblRowPerPage" defaultMessage="Rows per page: " />
      </span>
    );
  };

  const iconExcel = () => <img className="icon-excel" src={IconExcel} />;

  const HeaderAndToggle = () => {
    return (
      <div style={{ display: 'flex' }}>
        <div style={{ cursor: 'pointer' }} onClick={fnc.handleToggle}>
          <Icon type={state.toggle ? 'menu-unfold' : 'menu-fold'} style={{ color: '#e73845' }} />
        </div>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <div>
          <p>
            <FormattedMessage id="menuResourceTask" defaultMessage="Resource Task Summary" />
          </p>
        </div>
      </div>
    );
  };

  return (
    <OverTimeContext.Consumer>
      {() => (
        <Card
          className="overtime-report-card"
          title={<HeaderAndToggle />}
          extra={
            <Button className="button-link-export" type="link" onClick={handleExport} disabled={disabled}>
              <FormattedMessage id="btnExport" defaultMessage="Export" /> <Icon component={iconExcel} />
            </Button>
          }
        >
          <Spin spinning={firstLoad}>
            <ActionTravelReport
              columns={newColumnsStatus}
              handleReset={handleReset}
              handleCheckOk={handleCheckOk}
              visible={visible}
              handleVisibleChange={handleVisibleChange}
              checkedValuecolumns={checkedValuecolumns}
              onChange={handleSearch}
              onSelect={handleSelectData}
              selectSearchData={selectSearchData}
              checked={defaultShowColumn}
              textErrorSelectColumn={textErrorSelectColumn}
              handleOnVisiblecolumns={handleOnVisiblecolumns}
              onSelectRangePicker={onSelectRangePicker}
            />
            <Table
              className="report-table"
              rowKey={(record) => record.index}
              onChange={handleChange}
              loading={loading}
              dataSource={overtimeReport}
              columns={newDataColumns && newDataColumns.length !== 0 ? newDataColumns : []}
              scroll={_.size(newDataColumns) > 7 ? { x: 2000, y: `calc(100vh - 392px)` } : { x: 1500, y: `calc(100vh - 392px)` }}
              pagination={{
                total: total,
                showTotal: showTotal,
                defaultCurrent: 1,
                pageSizeOptions: ['10', '20', '30', '40', '50'],
                showSizeChanger: true,
                locale: { items_per_page: '' },
                onChange: handlePagination,
                onShowSizeChange: handleSizeChange,
              }}
            />

            {overtimeReport.length !== 0 ? (
              <div className="total-items-overtime-report">
                <span style={{ fontSize: '13px' }}>
                  <FormattedMessage id="lblTotal" defaultMessage="Total" />
                  {` ${total || 0} `}
                  <FormattedMessage id="lblitems" defaultMessage="items" />
                </span>
              </div>
            ) : null}
          </Spin>
        </Card>
      )}
    </OverTimeContext.Consumer>
  );
};
