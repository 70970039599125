import React from 'react';
import Card from '../../../components/v2/card';
import { useIntl, FormattedMessage } from 'react-intl';
import Button01 from '../../../components/v2/button_01';

const AssetSubAsset = () => {
  return (
    <div>
      <Card
        title={<FormattedMessage id="AssetSubassetTitle" defaultMessage="Sub Assets" />}
        extra={
          <div className="extra-user-details">
            <div>
              <Button01 key="submit" type="primary" btnsize="wd_df">
                <FormattedMessage id="btnEdit" defaultMessage="Edit" />
              </Button01>
            </div>
          </div>
        }
      >
        <FormattedMessage id="AssetSubassetLabelAssetSubAssetSite" defaultMessage="AssetSubAsset site" />
      </Card>
    </div>
  );
};
export default AssetSubAsset;
