import React, { useState, useEffect } from 'react';
import { Modal, Form, Row, Col, DatePicker, Table, Divider, Input, Select, AutoComplete } from 'antd';
import './index.css';
import { useIntl, FormattedMessage } from 'react-intl';
import Button01 from '../../v2/button_01';
import Button02 from '../../v2/button_02';
import LabeRequireForm from '../../label-required-form';
import _ from 'lodash';
import getCompanyProfile from '../../../controllers/company/get-company-profile';
import { getCustomerInformation } from '../../../controllers/customer-vendor/customer-vendor';
import moment from 'moment';
import ModalAddDeliveryItem from '../add-or-remove/add-remove';
import { getStockDelivery } from '../../../controllers/po/po';

const { Option } = Select;

const ModalDelivery = (props) => {
  const {
    visible,
    setVisible,
    form,
    selectItem,
    setSelectItem,
    vendorId,
    deliveryList,
    setDeliveryList,
    deliveryType,
    vehicleList,
  } = props;
  const { getFieldDecorator, validateFields, resetFields, getFieldValue, setFieldsValue } = form;
  const intl = useIntl();
  const [delivery, setDelivery] = useState([]);
  const [companyAddress, setCompanyAddress] = useState([]);
  const [vendorAddress, setVendorAddress] = useState([]);
  const [selectItemTemp, setSelectItemTemp] = useState([]);
  const [record, setRecord] = useState();
  const [typeItem, setTypeItem] = useState('add');
  const [visibleAddItem, setVisibleAddItem] = useState(false);
  const AddressType = getFieldValue('AddressType');
  const vehicleType = getFieldValue('vehicleType');
  const [stockTemp, setStockTemp] = useState([]);

  // useEffect(() => {
  //   if (visible) setSelectItemTemp(selectItem);
  // }, [visible]);

  useEffect(() => {
    const getStock = async () => {
      let tempItemMastIdCheck = '';

      const tempItemMastId = _.map(selectItem, (item, index) => {
        if (tempItemMastIdCheck !== item.itemMasterId) {
          tempItemMastIdCheck = item.itemMasterId;
          return {
            itemMasterId: item.itemMasterId,
            qty: 0,
          };
        }
        else {
          return undefined
        }
      });

      const filterStockUndefined = _.filter(tempItemMastId,(stock) => {
        return stock !== undefined
      })

      const body = {
        itemMasterList: filterStockUndefined,
      };
      const response2 = await getStockDelivery(body);

      const stocklist = _.get(response2,'data');
      setStockTemp(_.get(response2,'data'));

      setSelectItemTemp(selectItem);
    };
    if (visible) {
      getStock();
    }
  }, [visible]);

  useEffect(() => {
    const getStock = async () => {
      console.log('StockDelii',delivery);
      setDelivery(
        _.map(delivery, (item, index) => {
          const filterStock = _.filter(stockTemp,(stock) => {
            return item.itemMasterId === stock.itemMasterId
          })
          console.log('stockItemFill', filterStock);
          item.inStock = _.get(filterStock,'[0].balanced')
          return item;
        })
      );
    };
    if (selectItemTemp) {
      getStock();
    }
  }, [selectItemTemp]);

  const columnsDelivery = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: 70,
      align: 'center',
    },
    {
      title: intl.formatMessage({ id: 'modalDeliveryColumnsItemCode', defaultMessage: 'Item Code' }),
      dataIndex: 'itemCode',
      key: 'itemCode',
    },
    {
      title: intl.formatMessage({ id: 'modalDeliveryColumnsItem', defaultMessage: 'Item' }),
      dataIndex: 'itemName',
      key: 'itemName',
    },
    {
      title: intl.formatMessage({ id: 'modalDeliveryColumnsInStock', defaultMessage: 'In Stock' }),
      dataIndex: 'inStock',
      key: 'inStock',
      render: (text, record, index) => _.get(record,'inStock') ? _.get(record,'inStock') : 0,
    },
    {
      title: intl.formatMessage({ id: 'modalDeliveryColumnsQty', defaultMessage: 'Qty' }),
      dataIndex: 'countTemp',
      key: 'countTemp',
      render: (text, record, index) => (
        _.round(_.get(record,'countTemp'),2)
      ),
    },
    {
      title: intl.formatMessage({ id: 'modalDeliveryColumnsUnit', defaultMessage: 'Unit' }),
      dataIndex: 'uomName',
      key: 'uomName',
    },
    {
      title: '',
      dataIndex: 'button',
      key: 'button',
      width: 134,
      render: (text, record, index) => (
        <Button01 key="submit" type="primary" btnsize="wd_df" style={{ margin: 'unset' }} onClick={() => handleRemove(record)}>
          <FormattedMessage id="btnEdittmodalDelivery" defaultMessage="Edit" />
        </Button01>
      ),
    },
  ];

  const columnsItem = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: 70,
      align: 'center',
    },
    {
      title: intl.formatMessage({ id: 'modalDeliveryColumnsItemCode', defaultMessage: 'Item Code' }),
      dataIndex: 'itemCode',
      key: 'itemCode',
    },
    {
      title: intl.formatMessage({ id: 'modalDeliveryColumnsItem', defaultMessage: 'Item' }),
      dataIndex: 'itemName',
      key: 'itemName',
    },
    {
      title: intl.formatMessage({ id: 'modalDeliveryColumnsQty', defaultMessage: 'Qty' }),
      dataIndex: 'countTemp',
      key: 'countTemp',
      render: (text, record, index) => (
        _.round(_.get(record,'countTemp'),2)
      ),
    },
    {
      title: intl.formatMessage({ id: 'modalDeliveryColumnsUnit', defaultMessage: 'Unit' }),
      dataIndex: 'uomName',
      key: 'uomName',
    },
    {
      title: '',
      dataIndex: 'button',
      key: 'button',
      width: 134,
      render: (text, record, index) => (
        <Button01
          key="submit"
          type="primary"
          btnsize="wd_df"
          style={{ margin: 'unset' }}
          onClick={() => handleAdd(record)}
          disabled={checkDisableAdd(record.id, record.countTemp)}
        >
          <FormattedMessage id="btnAdd" defaultMessage="Add" />
        </Button01>
      ),
    },
  ];

  const checkDisableAdd = (id, countTemp) => {
    const filterDis = _.filter(delivery, (item) => {
      return item.id === id;
    });
    if (_.size(filterDis) > 0 || countTemp === 0) {
      return true;
    } else {
      return false;
    }
  };

  const handleAdd = (value) => {
    setTypeItem('add');
    setRecord(value);
    setVisibleAddItem(true);
  };

  const handleRemove = (value) => {
    setTypeItem('edit');
    setRecord(value);
    setVisibleAddItem(true);
  };

  useEffect(() => {
    const getAddress = async () => {
      if (AddressType === 'delivery') {
        const response = await getCompanyProfile();
        setCompanyAddress(_.get(response, 'AddressBookList'));
      } else {
        if (vendorId) {
          const response = await getCustomerInformation(vendorId);
          setVendorAddress(_.get(response, 'data.data.addressBooks') || []);
        }
      }
    };
    if (AddressType && (_.size(companyAddress) === 0 || _.size(vendorAddress) === 0) && visible) {
      getAddress();
    }
  }, [AddressType, visible]);

  const handleSave = () => {
    validateFields(async (err, values) => {
      if (err) {
        return;
      }
      const filterName = _.filter(deliveryType, (item) => {
        return item.code === _.get(values, 'AddressType');
      });
      const filterVehicle = _.filter(vehicleList, (item) => {
        return item.assetId === _.get(values, 'vehicleInternal');
      });
      const body = {
        index: _.size(deliveryList) + 1,
        deliveryDate: moment(_.get(values, 'date')).format('YYYY-MM-DD'),
        deliveryTypeCode: _.get(values, 'AddressType'),
        deliveryTypeName: _.get(filterName, '[0].txt'),
        deliveryAddressDetail: checkAddress(_.get(values, 'Address')),
        total: _.sum(
          _.map(delivery, (item) => {
            return parseFloat(item.countTemp);
          })
        ),
        itemList: _.map(delivery, (item) => {
          return {
            itemMasterId: item.itemMasterId,
            qty: parseFloat(item.countTemp),
            data: item,
            itemPoId: item.itemPoId,
          };
        }),
        vehicleTypeCode: _.get(values, 'vehicleType'),
        vehicleTypeName: _.get(values, 'vehicleType') === 'internal' ? 'Internal' : 'Other',
        vehicleInternal: _.get(filterVehicle, '[0]') || null,
        vehicleOther: _.get(values, 'vehicleOther'),
        vehicleTxt: `${
          _.get(values, 'vehicleType') === 'internal'
            ? intl.formatMessage({ id: `modalDeliveryInternal`, defaultMessage: 'Internal' })
            : intl.formatMessage({ id: `modalDeliveryOther`, defaultMessage: 'Other' })
        } - ${_.get(values, 'vehicleType') === 'internal' ? _.get(filterVehicle, '[0].serialNo') : _.get(values, 'vehicleOther')}`,
      };

      setDeliveryList([...deliveryList, body]);
      setSelectItem(selectItemTemp);
      setSelectItemTemp([]);
      setVisible(false);
      resetFields();
      setDelivery([]);
      setCompanyAddress([]);
      setVendorAddress([]);
    });
  };

  const handleCancel = () => {
    setVisible(false);
    setDelivery([]);
    setSelectItemTemp([]);
    setCompanyAddress([]);
    setVendorAddress([]);
    resetFields();
  };

  const checkAddress = (id) => {
    if (AddressType === 'delivery') {
      const filterAddress = _.filter(companyAddress, (item) => {
        return item.companyAddressBookId === id;
      });
      return {
        addressId: _.get(filterAddress, '[0].companyAddressBookId'),
        addressName: _.get(filterAddress, '[0].addressName'),
        fullAddress: _.get(filterAddress, '[0].fullAdress'),
        lat: parseFloat(_.get(filterAddress, '[0].lat')),
        lng: parseFloat(_.get(filterAddress, '[0].lng')),
      };
    } else {
      const filterAddress = _.filter(vendorAddress, (item) => {
        return item.customerAddressBookId === id;
      });
      return {
        addressId: _.get(filterAddress, '[0].customerAddressBookId'),
        addressName: _.get(filterAddress, '[0].addressName'),
        fullAddress: _.get(filterAddress, '[0].fullAddress'),
        lat: _.get(filterAddress, '[0].lat'),
        lng: _.get(filterAddress, '[0].lng'),
      };
    }
  };

  useEffect(() => {
    setFieldsValue({
      ['Address']: undefined,
    });
  }, [AddressType]);

  return (
    <Modal
      title={intl.formatMessage({ id: `modalDeliveryTitle`, defaultMessage: 'Add Delivery' })}
      centered={true}
      width={900}
      visible={visible}
      bodyStyle={{ padding: 'unset' }}
      onCancel={handleCancel}
      footer={[
        <Button02 key="back" btnsize="wd_df" onClick={handleCancel}>
          <FormattedMessage id="userDetailModalBtnCancel" defaultMessage="Cancel" />
        </Button02>,
        <Button01
          key="submit"
          type="primary"
          btnsize="wd_df"
          onClick={handleSave}
          style={{ margin: 'unset' }}
          disabled={_.size(delivery) > 0 ? false : true}
        >
          <FormattedMessage id="userDetailModalBtnSave" defaultMessage="Save" />
        </Button01>,
      ]}
    >
      <div style={{ padding: '16px' }}>
        <Form form={form} layout="vertical">
          <Row gutter={[16]}>
            <Col span={6}>
              <Form.Item
                label={<LabeRequireForm text={intl.formatMessage({ id: 'modalDeliveryTitleDate', defaultMessage: 'Date' })} req={true} />}
              >
                {getFieldDecorator('date', {
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'modalDeliveryValidateDate', defaultMessage: 'Please Select Date' }),
                    },
                  ],
                })(
                  <DatePicker
                    style={{ width: '100%' }}
                    format="DD/MM/YYYY"
                    placeholder={intl.formatMessage({ id: 'modalDeliveryPHDate', defaultMessage: 'Select Date' })}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={10}>
              <Row>
                <Col span={8}>
                  <Form.Item
                    label={
                      <LabeRequireForm
                        text={intl.formatMessage({ id: 'modalDeliveryTitleAddress', defaultMessage: 'Address' })}
                        req={true}
                      />
                    }
                  >
                    {getFieldDecorator('AddressType', {
                      initialValue: 'delivery',
                    })(
                      <Select
                        className="address-selection1"
                        style={{ width: '100%', borderTopRightRadius: 'unset', borderBottomRightRadius: 'unset' }}
                      >
                        {_.map(deliveryType, (item) => (
                          <Option value={item.code}>{item.txt}</Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col span={16}>
                  <Form.Item
                    label="
                    "
                  >
                    {getFieldDecorator('Address', {
                      rules: [
                        {
                          required: true,
                          message: intl.formatMessage({ id: 'modalDeliveryValidateAddress', defaultMessage: 'Please Select Address' }),
                        },
                      ],
                    })(
                      <Select
                        className="address-selection2"
                        placeholder={intl.formatMessage({ id: 'modalDeliveryTitleAddress', defaultMessage: 'Select Address' })}
                        style={{ width: '100%', borderTopLeftRadius: 'unset', borderBottomLeftRadius: 'unset' }}
                      >
                        {AddressType === 'delivery'
                          ? _.map(companyAddress, (item) => (
                              <Option value={item.companyAddressBookId}>
                                {item.addressName} · {item.description}
                              </Option>
                            ))
                          : _.map(vendorAddress, (item) => (
                              <Option value={item.customerAddressBookId}>
                                {item.addressName} · {item.address}
                              </Option>
                            ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={8}>
              <Row>
                <Col span={9}>
                  <Form.Item
                    label={
                      <LabeRequireForm
                        text={intl.formatMessage({ id: 'modalDeliveryTitleVehicleType', defaultMessage: 'Vehicle' })}
                        req={true}
                      />
                    }
                  >
                    {getFieldDecorator('vehicleType', {
                      initialValue: 'internal',
                    })(
                      <Select
                        className="address-selection1"
                        style={{ width: '100%', borderTopRightRadius: 'unset', borderBottomRightRadius: 'unset' }}
                      >
                        <Option value="internal">
                          {intl.formatMessage({ id: 'modalDeliveryValidateVehicleSelectInternal', defaultMessage: 'Internal' })}
                        </Option>
                        <Option value="other">
                          {intl.formatMessage({ id: 'modalDeliveryValidateVehicleSelectOther', defaultMessage: 'Other' })}
                        </Option>
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col span={15}>
                  <Form.Item
                    label="
                    "
                  >
                    {vehicleType === 'internal'
                      ? getFieldDecorator('vehicleInternal', {
                          rules: [
                            {
                              required: true,
                              message: intl.formatMessage({
                                id: 'modalDeliveryValidateVehicleSelect',
                                defaultMessage: 'Please Select Vehicle',
                              }),
                            },
                          ],
                        })(
                          <Select
                            className="address-selection2"
                            placeholder={intl.formatMessage({ id: 'modalDeliveryPHVehicleSelect', defaultMessage: 'Select Vehicle' })}
                            style={{ width: '100%', borderTopLeftRadius: 'unset', borderBottomLeftRadius: 'unset' }}
                            showSearch
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                          >
                            {_.map(vehicleList, (item) => (
                              <Option value={item.assetId}>{item.assetView}</Option>
                            ))}
                          </Select>
                        )
                      : getFieldDecorator('vehicleOther', {
                          rules: [
                            {
                              required: true,
                              message: intl.formatMessage({
                                id: 'modalDeliveryValidateVehicleInput',
                                defaultMessage: 'Please Enter Vehicle',
                              }),
                            },
                          ],
                        })(
                          <Input
                            className="input-vehicle"
                            placeholder={intl.formatMessage({ id: 'modalDeliveryPHVehicleInput', defaultMessage: 'Enter Vehicle' })}
                          />
                        )}
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </div>
      <div style={{ padding: '0 16px 16px 16px' }}>
        <Table
          columns={columnsDelivery}
          dataSource={_.sortBy(delivery, [
            function(o) {
              return o.index;
            },
          ])}
          scroll={{ y: 160 }}
        />
      </div>
      <div style={{ padding: '0 16px' }}>
        <FormattedMessage id="modalDeliveryTitleItem" defaultMessage="Items" />
      </div>
      <Divider type="horizontal" />
      <div style={{ padding: '0 16px 16px 16px' }}>
        <Table
          columns={columnsItem}
          dataSource={_.sortBy(selectItemTemp, [
            function(o) {
              return o.index;
            },
          ])}
          scroll={{ y: 160 }}
        />
      </div>

      <ModalAddDeliveryItem
        visible={visibleAddItem}
        setVisible={setVisibleAddItem}
        typeItem={typeItem}
        record={record}
        delivery={delivery}
        setDelivery={setDelivery}
        selectItemTemp={selectItemTemp}
        setSelectItemTemp={setSelectItemTemp}
        typeModal="add"
      />
    </Modal>
  );
};

const ModalDeliveryCreate = Form.create({
  name: 'assignTeam-form',
  mapPropsToFields() {},
})(ModalDelivery);

export default ModalDeliveryCreate;
