import React, { useState, useRef, useEffect } from 'react';
import './css/index.css';
import { Modal } from 'antd';
import PrintPreview from '../print-report/view-quotation';
import HeaderView from '../view-quotation-component/view-header-quotation';
import DetailView from '../view-quotation-component/view-data-quotation';
import CommentView from '../view-quotation-component/view-comment-quotation';
import PrintTotals from '../view-quotation-component/view-print-totals';
import ModalReject from './modal-reject-quotation';
import ApproveQuotation from './modal-approve-quotation';
import Button01 from '../v2/button_01';
import Button02 from '../v2/button_02';
import { useIntl, FormattedMessage } from 'react-intl';
import ReactToPrint from 'react-to-print';
import PrintTest from '../print-report/print-quotation';
import _ from 'lodash';
import { getReason, getQuotationById, getDefaultStarted, countPrint } from '../../controllers/quotation/quotation-api.js';
// import { getDefaultStarted } from '../../controllers/quotation/quotation-api';
// import { countPrint } from '../../controllers/quotation/quotation-api';
import { PageSettings } from '../../config/page-settings'

export default (props) => {

  const componentRef = useRef();
  const intl = useIntl()
  const { componentName, visible, setVisible, status, data, setTriggerApi, dataHeader, setData, setTriggerAPICountPost } = props;
  
  const [visibleModalApprove, setVisibleModalApprove] = useState(false);
  const [visibleModalReject, setVisibleModalReject] = useState(false);
  const [triggerPrint, setTriggerPrint] = useState(false);
  const [reason, setReason] = useState([]);
  const [discout, setDiscout] = useState([]);
  const [selectDiscount, setSelectDiscount] = useState();

  const [countNumberPrint, setCountNumberPrint] = useState();
  const [triggerAPICount, setTriggerAPICount] = useState(false);

  useEffect(() => {
    const getReasonData = async () => {
      const getData = await getReason();
      const defaultStart = await getDefaultStarted()
      setDiscout(_.get(defaultStart, 'dataDiscount'))
      setReason(getData);
    };
    getReasonData();
  }, [])


  useEffect(() => {
    if (discout) {
      const filter = discout && discout.filter(item => { return item.discountId === _.get(data, 'discountId') })
      setSelectDiscount(_.get(filter, '[0]'))
    }
  }, [_.get(data, 'quotationId')])

  useEffect(() => {
    if ((_.get(data, 'quotationId')) && (visible === true)) {
      const countPrint = async () => {
        try {
          const response = await getQuotationById(_.get(data, 'quotationId'))
          if (response.status.code == 200) {
            setCountNumberPrint(_.get(response, 'data[0].print'));
          }
        } catch (error) {
          return
        }
      }
      countPrint();
    }
  }, [visible, triggerAPICount]);

  const handleClose = () => {
    setVisible(false)
    setTriggerPrint(false)
    // setData();
  }

  const onReject = () => {
    setVisibleModalReject(true);
  }

  const onApprove = () => {
    setVisibleModalApprove(true);
  }

  const handlePrint = () => {
    setTriggerPrint(true)
  }

  const hanldeAfterPrint = async (id) => {
    // setTriggerPrint(false)
    await countPrint(id);
    // console.log('hanldeAfterPrint :;',response)
    setTriggerAPICount(current => !current);
    if (componentName && componentName === 'quotation-post') {
      setTriggerAPICountPost(current => !current);
    }
  }

  return (
    <div>
      <Modal
        title={intl.formatMessage({ id: 'ModalViewQuotationHeader', defaultMessage: 'Quotation' })}
        visible={visible}
        onCancel={handleClose}
        centered
        width={900}
        bodyStyle={{ height: 590, padding: 'unset' }}
        footer={
          (data && data.statusCode === 'cancel') || (data && data.statusCode === 'reject') || (data && data.statusCode === 'draft') ?
            [
              <Button01 key="submit" btnsize='wd_df' type="primary" onClick={handleClose} >
                <FormattedMessage id="btnClose" defaultMessage="Close" />
              </Button01>
            ]
            : data && data.statusCode === 'approve' ?
              [
                <PageSettings.Consumer>
                  {({ checkPermissionAction }) =>
                    <div className='div-footer-quotation'>
                      <div className='div-footer-quotation-print'>
                        <PrintTotals data={data} countNumberPrint={countNumberPrint} />
                      </div>
                      <div className='div-footer-quotation-print-button'>
                        {
                          checkPermissionAction('P28PG1C1', 'P28PG1C1A9') ? (
                            <ReactToPrint
                              trigger={() => <Button02 style={{margin : '0px 0px 0px 10px'}} key="print" btnsize='wd_df' onClick={handleClose}>
                                <FormattedMessage id="btnPrint" defaultMessage="Print" />
                              </Button02>}
                              content={() => componentRef.current}
                              onBeforeGetContent={() => handlePrint()}
                              onAfterPrint={() => hanldeAfterPrint(_.get(data, 'quotationId'))}
                            />
                          ) : null
                        }

                        <Button01 key="submit" btnsize='wd_df' type="primary" onClick={handleClose} >
                          <FormattedMessage id="btnClose" defaultMessage="Close" />
                        </Button01>
                      </div>
                    </div>
                  }
                </PageSettings.Consumer>

              ]
              : data && data.statusCode === 'waiting' ?
                [
                  <PageSettings.Consumer>
                    {({ checkPermissionAction }) =>
                      <div>
                        {
                          checkPermissionAction('P28PG1C1', 'P28PG1C1A6') ? (
                            <Button02 style={{margin : '0px 0px 0px 10px'}} key="reject" btnsize='wd_df' onClick={onReject} >
                              <FormattedMessage id="btnReject" defaultMessage="Reject" />
                            </Button02>
                          ) : null
                        }
                        {
                          checkPermissionAction('P28PG1C1', 'P28PG1C1A5') ? (
                            <Button01 key="submit" btnsize='wd_df' type="primary" onClick={onApprove} >
                              <FormattedMessage id="btnApprove" defaultMessage="Approve" />
                            </Button01>
                          ) : null
                        }
                      </div>}
                  </PageSettings.Consumer>,
                ]
                : null
        }
      >

        <div className='layout-view'>
          <div className='layout-view-header'>
            <HeaderView data={data} fromPage='quatation' />
            <DetailView data={data} />
          </div>
          <div className='layout-view-print'>
            <PrintPreview data={data} dataHeader={dataHeader} selectDiscount={selectDiscount} />
          </div>

          {(data && data.statusCode === 'approve') || (data && data.statusCode === 'reject') ?
            <div className='layout-view-comment'>
              <CommentView data={data} />
            </div>
            :
            null
          }

        </div>

        {
          data !== undefined && dataHeader !== undefined && selectDiscount ? (
            <PrintTest ref={componentRef} data={data} dataHeader={dataHeader} selectDiscount={selectDiscount} />
          ) : null
        }

        <ApproveQuotation
          visible={visibleModalApprove}
          setVisible={setVisibleModalApprove}
          setVisibleView={setVisible}
          data={data}
          setTriggerApi={setTriggerApi}
        />

        <ModalReject
          visible={visibleModalReject}
          setVisible={setVisibleModalReject}
          setVisibleView={setVisible}
          data={data}
          setTriggerApi={setTriggerApi}
          reason={reason}
        />
      </Modal>

    </div >
  )
}