import React from 'react';
import Company from '../pages/login/company';
import ForgotPassword from '../pages/login/forgotPassword';
import ValidateCode from '../pages/login/securityCode';
import NewPassword from '../pages/login/newPassword';

const routes = [
  {
    path: '/company',
    title: 'Company',
    component: () => <Company />
  },
  {
    path: '/forgot-password',
    title: 'Forgot Password',
    component: () => <ForgotPassword />
  },
  {
    path: '/validate-code',
    title: 'Validate Code',
    component: () => <ValidateCode />
  },
  {
    path: '/new-password',
    title: 'New Password',
    component: () => <NewPassword />
  },
];

export default routes;