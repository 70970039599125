import { Col } from 'antd';
import React from 'react'
import _ from 'lodash'

const FeedNoteText = (props) => {
  const { isMemComId, text } = props
  return (
    <Col
      span={24}
      style={isMemComId ? { display: 'flex', justifyContent: 'end', textAlign: 'right' } : { undefined }}
    >
      <div className='note-text' style={{ padding: '6px 0px ' }}>{text}</div>
    </Col>
  )
}

export default FeedNoteText
