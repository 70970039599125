import { AutoComplete, Checkbox, Col, Divider, Form, Icon, Input, InputNumber, Modal, Row, Select, TimePicker } from 'antd';
import React, { useState, useContext } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import LabeRequireForm from '../../../components/label-required-form';
import Button_01 from '../../../components/v2/button_01';
import Button_02 from '../../../components/v2/button_02';
import WarehouseContext from '../context';
import _ from 'lodash';
import { useEffect } from 'react';
import './css/index.css';
import { errorNotification, successNotification } from '../../../components/v2/notification';
import {
  createWarehouseNoBinLocation,
  getItemsCode,
  getItemsMaster,
  getLocationByItem,
  saveWarehouseLocation,
  saveWarehouseStock,
  updateWarehouseLocation,
  updateWarehouseStock,
} from '../../../controllers/warehouse/warehouse';
import moment from 'moment';
import { handleKeyDownDecimal2Fixed, handleKeyDownPhone } from '../../../component-function/fnc-number';
const { TextArea } = Input;
const { Option } = Select;
const WarehouseBinLocationNobinModal = ({ form, record, setRecord, visible, setVisible, setTriggerLocation }) => {
  const intl = useIntl();
  const { state, setState, fnc } = useContext(WarehouseContext);
  const { warehouseId } = state;
  const { setTrigger } = setState;
  const { getFieldDecorator, validateFields, resetFields, setFieldsValue, getFieldValue } = form;
  const format = 'HH:mm';

  const [searhByCode, setSearchByCode] = useState('');
  const [searhByName, setSearchByName] = useState('');
  const [itemCodeList, setItemCodeList] = useState([]);
  const [itemNameList, setItemNameList] = useState([]);

  const [selectItem, setSelectItem] = useState();

  const width = getFieldValue('width');
  const length = getFieldValue('length');
  const height = getFieldValue('height');

  const qty = getFieldValue('qty');
  const desiredQty = getFieldValue('desiredQty');

  console.log('record location', record);

  useEffect(() => {
    if (record) {
      const item = _.get(record, 'itemMasterDetail');
      if (item) {
        itemCodeList.push(item);
        itemNameList.push(item);
        setItemCodeList(itemCodeList);
        setItemNameList(itemNameList);
        setSelectItem(item);
      }
    }
  }, [record]);

  useEffect(() => {
    const getItemCode = async () => {
      const payload = {
        code: searhByCode,
      };
      const response = await getItemsCode(payload);
      const list = _.get(response.data.data, 'itemMasterList');
      setItemCodeList(_.uniqBy(_.concat(itemCodeList, list)), 'itemMasterId');
    };

    getItemCode();
  }, [searhByCode]);

  useEffect(() => {
    const getItemCode = async () => {
      const payload = {
        name: searhByName,
      };
      const response = await getItemsMaster(payload);
      const list = _.get(response.data.data, 'itemMasterList');
      setItemNameList(_.uniqBy(_.concat(itemNameList, list)), 'itemMasterId');
    };

    getItemCode();
  }, [searhByName]);

  // useEffect(() => {
  //   if (width && length && height) {
  //     setFieldsValue({ dimension: `${width} * ${length} * ${height}` });
  //   }
  // }, [width, length, height]);

  const handleSelectCode = (value) => {
    if (value) {
      const filterArr = itemCodeList.filter((item) => item.itemMasterId === value);
      setSelectItem(_.get(filterArr, '[0]'));
      setFieldsValue({
        itemName: _.get(filterArr[0], 'itemName'),
      });
    }
  };

  const handleSelectName = (value) => {
    if (value) {
      const filterArr = itemNameList.filter((item) => item.itemMasterId === value);
      setSelectItem(_.get(filterArr, '[0]'));
      setFieldsValue({
        itemCode: _.get(filterArr[0], 'itemCode'),
      });
    }
  };

  const handleChangeCode = (value) => {
    if (!value) {
      setFieldsValue({
        itemName: undefined,
      });
      setSelectItem();
    }
  };

  const handleChangeName = (value) => {
    if (!value) {
      setFieldsValue({
        itemCode: undefined,
      });
      setSelectItem();
    }
  };

  const handleSearchCode = _.debounce((value) => {
    setSearchByCode(value || '');
  }, 500);

  const handleSearchName = _.debounce((value) => {
    setSearchByName(value || '');
  }, 500);

  const onOk = (open) => {
    validateFields(async (err, values) => {
      if (err) {
        return;
      }
      if (values) {
        console.log('onOk', values);
        const payload = {
          warehouseId: warehouseId,
          itemMasterDetail: selectItem,
          name: values.bin,
          width: payloadToNumber(_.get(values, 'width')) || 0,
          length: payloadToNumber(_.get(values, 'length')) || 0,
          height: payloadToNumber(_.get(values, 'height')) || 0,
          maxWeight: payloadToNumber(_.get(values, 'maxWeight')) || 0,
          remark: values.remark,
          desiredQty: payloadToNumber(_.get(values, 'desiredQty')) || 0,
        };
        console.log('payload', payload);
        // return;
        if (_.get(record, 'binLoationId')) {
          try {
            const response = await createWarehouseNoBinLocation(_.get(record, 'binLoationId'), payload);
            console.log('updateWarehouseLocation', response);
            if (_.get(response, 'status') === 200) {
              successNotification(_.get(response.data.status, 'message'));
              setVisible(open);
              utilResetValue();
            } else {
              errorNotification(_.get(response.data.status, 'message'));
            }
          } catch (error) {}
        }
      }
    });
  };

  const payloadToNumber = (value) => {
    return _.toNumber(value);
  };

  const utilResetValue = () => {
    setTrigger((current) => !current);
    setTriggerLocation((c) => !c);
    setSelectItem();
    setItemCodeList([]);
    setItemNameList([]);
    resetFields();
    setRecord();
  };

  const onCancel = () => {
    setVisible(false);
    setItemCodeList([]);
    setItemNameList([]);
    resetFields();
    setSelectItem();
    setRecord();
  };

  const validatorDesiredQty = (rule, value, callback) => {
    if (value > qty) {
      callback(<FormattedMessage id="warehouseModalReqDesiredQty" defaultMessage="Qty is more than No Bin Location Qty." />);
    }
    callback();
  };

  return (
    <Modal
      title={intl.formatMessage({ id: `warehouseTitleLocationCreateBinLocation`, defaultMessage: 'Create Bin Location' })}
      className="body-nobin"
      visible={visible}
      centered
      width={500}
      bodyStyle={{ padding: '6px 24px 24px 24px' }}
      onOk={onOk}
      onCancel={onCancel}
      footer={[
        <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={() => onCancel()} margin=" unset">
          <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
        </Button_02>,
        <Button_01 style={{ margin: '0px 0px 0px 10px' }} key="submit" type="primary" btnsize="wd_df" onClick={() => onOk(false)}>
          <FormattedMessage id="btnSave" defaultMessage="Save" />
        </Button_01>,
      ]}
    >
      <Form colon={false}>
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Row>
              <Row gutter={[24, 2]}>
                <Col span={24}>
                  <Form.Item
                    label={
                      <LabeRequireForm
                        text={intl.formatMessage({ id: 'locationLabelBinLocation', defaultMessage: 'Bin Location' })}
                        req={true}
                      />
                    }
                  >
                    {getFieldDecorator('bin', {
                      // initialValue: _.get(record, 'name') ? _.get(record, 'name') : undefined,
                      rules: [
                        {
                          required: true,
                          message: intl.formatMessage({ id: 'warehouseModalReqBinLocation', defaultMessage: 'Please enter Bin Location' }),
                        },
                      ],
                    })(
                      <Input
                        placeholder={intl.formatMessage({
                          id: 'placeholderLocationLabelBin',
                          defaultMessage: 'Enter Bin Location',
                        })}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[24, 2]}>
                <Col span={12}>
                  <Form.Item
                    label={
                      <LabeRequireForm text={intl.formatMessage({ id: 'locationLabelWidth', defaultMessage: 'Width (m)' })} req={true} />
                    }
                  >
                    {getFieldDecorator('width', {
                      // initialValue: _.get(record, 'width') ? _.get(record, 'width') : 0,
                      rules: [
                        {
                          required: true,
                          message: intl.formatMessage({ id: 'warehouseModalReqWidth', defaultMessage: 'Please enter Width' }),
                        },
                      ],
                    })(
                      <InputNumber
                        className="disabled-handle-count"
                        min={0}
                        step={0.01}
                        max={9999999999}
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        onKeyDown={handleKeyDownPhone}
                        placeholder={intl.formatMessage({
                          id: 'placeholderLocationLabelWidth',
                          defaultMessage: 'Enter Width',
                        })}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={
                      <LabeRequireForm text={intl.formatMessage({ id: 'locationLabelLength', defaultMessage: 'Length (m)' })} req={true} />
                    }
                  >
                    {getFieldDecorator('length', {
                      // initialValue: _.get(record, 'length') ? _.get(record, 'length') : 0,
                      rules: [
                        {
                          required: true,
                          message: intl.formatMessage({ id: 'warehouseModalReqLength', defaultMessage: 'Please enter Length' }),
                        },
                      ],
                    })(
                      <InputNumber
                        className="disabled-handle-count"
                        min={0}
                        step={0.01}
                        max={9999999999}
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        onKeyDown={handleKeyDownPhone}
                        placeholder={intl.formatMessage({
                          id: 'placeholderLocationLabelLength',
                          defaultMessage: 'Enter Length',
                        })}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[24, 2]}>
                <Col span={12}>
                  <Form.Item
                    label={
                      <LabeRequireForm text={intl.formatMessage({ id: 'locationLabelHeight', defaultMessage: 'Height (m)' })} req={true} />
                    }
                  >
                    {getFieldDecorator('height', {
                      // initialValue: _.get(record, 'height') ? _.get(record, 'height') : 0,
                      rules: [
                        {
                          required: true,
                          message: intl.formatMessage({ id: 'warehouseModalReqHeight', defaultMessage: 'Please enter Height' }),
                        },
                      ],
                    })(
                      <InputNumber
                        className="disabled-handle-count"
                        min={0}
                        step={0.01}
                        max={9999999999}
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        onKeyDown={handleKeyDownPhone}
                        placeholder={intl.formatMessage({
                          id: 'placeholderLocationLabelHeight',
                          defaultMessage: 'Enter Height',
                        })}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={
                      <LabeRequireForm
                        text={intl.formatMessage({ id: 'locationLabelMaxWeight', defaultMessage: 'Max Weight (kg)' })}
                        req={true}
                      />
                    }
                  >
                    {getFieldDecorator('maxWeight', {
                      // initialValue: _.get(record, 'maxWeight') ? _.get(record, 'maxWeight') : 0,
                      rules: [
                        {
                          required: true,
                          message: intl.formatMessage({ id: 'warehouseModalReqMaxWeight', defaultMessage: 'Please enter Max Weight' }),
                        },
                      ],
                    })(
                      <InputNumber
                        className="disabled-handle-count"
                        min={0}
                        step={0.01}
                        max={9999999999}
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        onKeyDown={handleKeyDownPhone}
                        placeholder={intl.formatMessage({
                          id: 'placeholderLocationLabelMaxWeight',
                          defaultMessage: 'Enter Max Weight',
                        })}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Row>
            <Row gutter={[24, 2]}>
              <Col span={24}>
                <Form.Item
                  label={
                    <LabeRequireForm text={intl.formatMessage({ id: 'locationLabelItemCode', defaultMessage: 'Item Code' })} req={true} />
                  }
                >
                  {getFieldDecorator('itemCode', {
                    initialValue: _.get(record, 'binLoationId') ? _.get(record.itemMasterDetail, 'itemMasterId') : undefined,
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({ id: 'warehouseModalReqItemCode', defaultMessage: 'Please select Item Code' }),
                      },
                    ],
                  })(
                    <AutoComplete
                      disabled
                      allowClear
                      onSearch={handleSearchCode}
                      // onChange={handleChangeCode}
                      onSelect={(value, evnt) => handleSelectCode(value, evnt)}
                      dataSource={itemCodeList}
                      filterOption={false}
                      placeholder={intl.formatMessage({ id: 'placeholderLocationLabelItemCode', defaultMessage: 'Select Item Code' })}
                    >
                      {itemCodeList &&
                        itemCodeList.map((item) => (
                          <Option key={item.itemCode} value={item.itemMasterId}>
                            {item.itemCode}
                          </Option>
                        ))}
                    </AutoComplete>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24, 2]}>
              <Col span={24}>
                <Form.Item
                  label={
                    <LabeRequireForm text={intl.formatMessage({ id: 'locationLabelItemName', defaultMessage: 'Item Name' })} req={true} />
                  }
                >
                  {getFieldDecorator('itemName', {
                    initialValue: _.get(record, 'binLoationId') ? _.get(record.itemMasterDetail, 'itemMasterId') : undefined,
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({ id: 'warehouseModalReqItemName', defaultMessage: 'Please select Item Name' }),
                      },
                    ],
                  })(
                    <AutoComplete
                      disabled
                      allowClear
                      onSearch={handleSearchName}
                      // onChange={handleChangeName}
                      onSelect={handleSelectName}
                      filterOption={false}
                      dataSource={itemNameList}
                      placeholder={intl.formatMessage({ id: 'placeholderLocationLabelItemName', defaultMessage: 'Select Item Name' })}
                    >
                      {itemNameList &&
                        itemNameList.map((item, index) => (
                          <Option key={item.itemCode} value={item.itemMasterId}>
                            {item.itemName}
                          </Option>
                        ))}
                    </AutoComplete>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24, 2]}>
              <Col span={12}>
                <Form.Item
                  label={
                    <LabeRequireForm
                      text={intl.formatMessage({ id: 'locationLabelItemWeight', defaultMessage: 'Item Weight (kg)' })}
                      req={false}
                    />
                  }
                >
                  {getFieldDecorator('weight', {
                    initialValue: _.get(record, 'binLoationId') ? _.get(record.itemMasterDetail, 'weight') : undefined,
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({ id: 'warehouseModalReqItemWeight', defaultMessage: 'Please enter Item Weight' }),
                      },
                    ],
                  })(
                    <Input
                      disabled={true}
                      onKeyDown={handleKeyDownPhone}
                      placeholder={intl.formatMessage({
                        id: 'placeholderLocationLabelItemWeight',
                        defaultMessage: 'Enter Item Weight',
                      })}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={
                    <LabeRequireForm
                      text={intl.formatMessage({ id: 'locationLabelItemDimension', defaultMessage: 'Item Dimension (m)' })}
                      req={false}
                    />
                  }
                >
                  {getFieldDecorator('dimension', {
                    initialValue: _.get(record, 'binLoationId') ? _.get(record.itemMasterDetail, 'dimension') : undefined,
                    rules: [
                      {
                        required: false,
                        message: intl.formatMessage({
                          id: 'warehouseModalReqItemDimension',
                          defaultMessage: 'Please enter Item Dimension',
                        }),
                      },
                    ],
                  })(
                    <Input
                      disabled={true}
                      onKeyDown={handleKeyDownPhone}
                      placeholder={intl.formatMessage({
                        id: 'placeholderLocationLabelItemDimension',
                        defaultMessage: 'Enter Item Dimension',
                      })}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>{' '}
            <Row gutter={[24, 2]}>
              <Col span={12}>
                <Form.Item
                  label={
                    <LabeRequireForm
                      text={intl.formatMessage({ id: 'locationLabelNoBinLocationQty', defaultMessage: 'No Bin Location Qty' })}
                      req={false}
                    />
                  }
                >
                  {getFieldDecorator('qty', {
                    initialValue: _.get(record, 'qty') ? _.get(record, 'qty') : 0,
                    rules: [
                      {
                        required: false,
                        message: intl.formatMessage({
                          id: 'warehouseModalReqNoBinLocationQty',
                          defaultMessage: 'Please enter No Bin Location Qty',
                        }),
                      },
                    ],
                  })(
                    <InputNumber
                      className="disabled-handle-count"
                      min={0}
                      step={0.01}
                      max={9999999999}
                      formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                      disabled={true}
                      onKeyDown={handleKeyDownPhone}
                      placeholder={intl.formatMessage({
                        id: 'placeholderLocationLabelNoBinLocationQty',
                        defaultMessage: 'Enter No Bin Location Qty',
                      })}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={
                    <LabeRequireForm
                      text={intl.formatMessage({ id: 'locationLabelDesiredQty', defaultMessage: 'Desired Qty' })}
                      req={true}
                    />
                  }
                >
                  {getFieldDecorator('desiredQty', {
                    // initialValue: _.get(record, 'length') ? _.get(record, 'length') : 0,
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({ id: 'warehouseModalReqDesiredQty', defaultMessage: 'Please Desired Qty' }),
                      },
                      {
                        validator: validatorDesiredQty,
                      },
                    ],
                  })(
                    <InputNumber
                      className="disabled-handle-count"
                      min={0}
                      step={0.01}
                      max={9999999999}
                      formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                      onKeyDown={handleKeyDownPhone}
                      placeholder={intl.formatMessage({
                        id: 'placeholderLocationLabelLength',
                        defaultMessage: 'Enter Length',
                      })}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24, 2]}>
              <Col span={24}>
                <Form.Item
                  label={<LabeRequireForm text={intl.formatMessage({ id: 'locationLabelRemark', defaultMessage: 'Remark' })} req={false} />}
                >
                  {getFieldDecorator('remark', {
                    initialValue: _.get(record, 'remark') ? _.get(record, 'remark') : undefined,
                    rules: [],
                  })(<Input.TextArea autoSize={{ minRows: 3, maxRows: 3 }} maxLength={250} />)}
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

const WarehouseBinLocationNobinModalForm = Form.create({
  name: 'warehouse-form',
})(WarehouseBinLocationNobinModal);

export default WarehouseBinLocationNobinModalForm;
