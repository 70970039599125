import React, { useContext, useRef, useState } from 'react';
import {Button, Card, Icon, Popover, Table } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import _ from 'lodash';
import Button01 from '../../components/v2/button_01';
import { MyrequestContext } from './myrequest-context';
import { PageSettings } from '../../config/page-settings';
import ModalCreate from './components/modal/modalCreate';
import NoDataBackground from '../../components/no-data-page';
import '../myrequest/components/css/index.css'

function TableRequest() {
  const intl = useIntl();
  const { state, setState, fnc } = useContext(MyrequestContext);
  const { toggle, checkedKeysRequest, checkedKeysTeam, searchListMyrequest, isLoading, fillter,rightCardDisplay,paginationPage } = state;
  const { setChangeTab, setChangeStatus, setFillter } = setState;
  const { handleToggle,handleItemRequest, getApiSearch, changeToApproval, changeToReject, getApiApprove, getApiReject,handleChange } = fnc;
  const data = _.get(searchListMyrequest, 'requestList');
  // const pag = _.get(searchListMyrequest, 'totalPage') || 1;
  const totalItems = _.get(searchListMyrequest, 'totalRequest') || 0;
  const [isOpenModalCreate, setIsOpenModalCreate] = useState(false);
  //----------------------------------------
  const ref = useRef();
  const [visiblePopoverTable, setVisiblePopoverTable] = useState();
  const [recordPopover, setRecordPopover] = useState();
  const [offsetPopover, setOffsetPopover] = useState([0, 0]);

  const HeaderAndToggle = () => {
    return (
      <div style={{ display: 'flex' }}>
        <div style={{ cursor: 'pointer' }}>
          <Icon type={toggle ? 'menu-unfold' : 'menu-fold'} style={{ color: '#e73845', fontSize: '20px' }} onClick={handleToggle} />
        </div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <div style={{ display: 'flex' }}><FormattedMessage id="menuMyRequestSub" defaultMessage="My Request" /></div>
      </div>
    );
  };

  const showTotal = (total) => {
    return (
      <span style={{ fontSize: '13px' }}>
        <FormattedMessage id="lblRowPerPage" defaultMessage="Rows per page: " />
      </span>
    );
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: 70,
      align: 'center',
      fixed: 'left',
      render: (text, record, index) => <span>{index + 1}</span>,
    },
    {
      title: intl.formatMessage({ id: `myrequestReq`, defaultMessage: 'Request No.' }),
      dataIndex: 'requestNo',
      key: 'requestNo',
      width: 150,
      sorter: true,
      fixed: 'left',
      sorter: (a, b) => a.empCode - b.empCode,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: `myrequestType`, defaultMessage: 'Request Type.' }),
      dataIndex: 'requestTypeName',
      key: 'requestTypeName',
      width: 140,
      sorter: true,
      sorter: (a, b) => a.Name - b.Name,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: `myrequestRequestor`, defaultMessage: 'Requestor' }),
      dataIndex: 'requestor',
      key: 'requestor',
      width: 160,
      sorter: true,
      sorter: (a, b) => a.Name - b.Name,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: `myrequestDate`, defaultMessage: 'Request Date.' }),
      dataIndex: 'requestDate',
      key: 'requestDate',
      width: 200,
      sorter: true,
      sorter: (a, b) => a.Name - b.Name,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: 'myrequestStatus', defaultMessage: 'Status' }),
      dataIndex: ['statusTxt', 'statusColor'],
      key: 'statusTxt',
      width: 120,
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (data, record) => {
        return (
          <span>
            <p
              style={{
                backgroundColor: record['statusColor'],
                color: 'white',
                textAlign: 'center',
                borderRadius: '10px',
                height: '23px',
                width: '95px',
              }}
            >
              {record['statusTxt']}
            </p>
          </span>
        );
      },
    },

    {
      title: intl.formatMessage({ id: `myrequestDetail`, defaultMessage: 'Request Details.' }),
      dataIndex: 'requestDetail',
      key: 'requestDetail',
      width: 400,
      sorter: true,
      sorter: (a, b) => a.Round - b.Round,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: `myrequestTeam`, defaultMessage: 'Team' }),
      dataIndex: 'teamName',
      key: 'teamName',
      width: 150,
      sorter: true,
      sorter: (a, b) => a.Round - b.Round,
      sortDirections: ['descend', 'ascend'],
    },
  ];

  const openModal = () => {
    setIsOpenModalCreate(true);
  };
  const closeModal = () => {
    setIsOpenModalCreate(false);
  };

  const fncActionTable = (record, offsetPopover, visiblePopoverTable, ref) => {
    return (
      <div>
        <Popover
          placement="right"
          trigger="hover"
          align={{ offset: offsetPopover }}
          visible={visiblePopoverTable}
          zIndex={10000}
          content={
            <div style={{ display: 'grid' }}>
              <>
                {/* {_.get(record, 'modalViewCode') === 'customer_KYC' ? ( */}
                  <Button style={{ width: 100 }} type="link" ghost onClick={() => handleItemRequest(record)}>
                    <FormattedMessage id="btnView" defaultMessage="View" />
                  </Button>
                {/* ) : null} */}
              </>
            </div>
          }
        >
          <div style={{ width: 0, height: 0 }} ref={ref}></div>
        </Popover>
      </div>
    );
  };

  const handleMouseLeave = () => {
    setVisiblePopoverTable(false);
    setRecordPopover();
    setOffsetPopover([0, 0]);
  };

  return (
    <>
    
      {rightCardDisplay === true  ? (
        <Card
          className="right-card-myrequst"
          title={<HeaderAndToggle />}
          // extra={
          //   <PageSettings.Consumer>
          //     {({ checkPermissionAction }) =>
          //       checkPermissionAction(`P49PG1C1`, `P49PG1C1A2`) ? (
          //         <Button01 type="primary" onClick={openModal}>
          //           <FormattedMessage id={'btnCreate'} defaultMessage="Create" />
          //         </Button01>
          //       ) : null
          //     }
          //   </PageSettings.Consumer>
          // }
        >
          <div onMouseLeave={handleMouseLeave}>
            <>
              <div style={{ padding: '15px' }}>
                <Table
                  columns={columns}
                  dataSource={data}
                  scroll={{ x: 1800, y: `calc(110vh - 377px)` }}
                  loading={isLoading}
                  onChange={handleChange}
                  pagination={{
                    total: totalItems,
                    pageSizeOptions: ['10', '20', '30', '40', '50'],
                    showSizeChanger: true,
                    locale: { items_per_page: '' },
                    showTotal: showTotal,
                    current: paginationPage,
                    defaultCurrent: 1,
                  }}
                  onRow={(record, rowIndex) => {
                    return {
                      onClick: (event) => {
                        if (!event.target.href && _.get(record,'requestTypeCode') === 'requestitem') {
                          const { x, y } = ref.current.getBoundingClientRect();
                          setVisiblePopoverTable(true);
                          setOffsetPopover([event.pageX - x, event.pageY - y]);
                          setRecordPopover(record);
                        } 
                      },
                    };
                  }}
                />
                {fncActionTable(recordPopover, offsetPopover, visiblePopoverTable, ref)}
                <div className="total-items-member">
                  <span style={{ fontSize: '13px' }}>
                    <FormattedMessage id="lblTotal" defaultMessage="Total" />
                    {` ${totalItems || 0} `}
                    <FormattedMessage id="lblitems" defaultMessage="items" />
                  </span>
                </div>
              </div>
            </>
          </div>
        </Card>
      ) : (
        <div style={{ padding: '24px', backgroundColor: '#fff' }}>
          <Card className="detail-card-blank-page">
            <NoDataBackground
              text1={{ id: 'nodataApproval1', text: 'Please select a Request' }}
              text2={{ id: 'nodataApproval2', text: 'Request will appear here.' }}
            />
          </Card>
        </div>
      )}
      <ModalCreate showModal={isOpenModalCreate} onCloseModal={closeModal} />
    </>
  );
}

export default TableRequest;
