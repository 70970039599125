import React, { useState, useEffect, createContext, useContext } from 'react';
import httpClient from '../../../../components/axiosClient';
import _ from 'lodash';
import moment from 'moment';
import { OverTimeContext } from '../report-context';
import { Row, Col } from 'antd';
import OrganizationMenu from '../../../../components/monitor-component/team-all-report/organization-menu';
import TravelReport from './travel-report'
import { PageSettings } from '../../../../config/page-settings';

export default (props) => {
  const comId = localStorage.getItem('comId');
  const { pageCode, LangCode } = props;
  const [menuTree, setMenuTree] = useState([]);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const [checkedKeys, setCheckedKeys] = useState([]);
  const [defaultCheckedKeys, setDefaultCheckedKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  const [organizationTree, setOrganizationTree] = useState([]);

  const [shiftsYear, setShiftsYear] = useState(moment().format('YYYY'));
  const [shiftsMonth, setShiftsMonth] = useState(moment().format('MMMM'));

  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    getOrganization();
  }, []);

  // console.log('pageCode ::',pageCode)

  const getOrganization = async () => {
    const response = await httpClient.get(`/v1/resource/manager/company/${comId}/organizations`);
    if (response.status === 200) {
      const data = _.isObject(response.data) ? response.data : [];
      const startOrgId = _.minBy(data, (o) => { return o.parent === '' ? '' : parseInt(o.parent); });
      const dataSortBy = _.sortBy(data, 'name');
      setMenuTree(creatingTree(dataSortBy, startOrgId.parent));
      setCheckedKeys(drawOrgId(dataSortBy));
    }
  };

  const creatingTree = (items, orgId = '', link = 'parent') =>
    items.filter(item => parseInt(item[link]) === orgId || item[link] === orgId)
      .map(item => ({
        ...item,
        key: item.org_id,
        title: item.name,
        value: item.org_id,
        children: creatingTree(items, item.org_id)
      })
      );

  const drawOrgId = (data) => {
    const result = data.map(item => {
      return item.org_id.toString()
    })
    return result;
  }

  const onExpand = (expandedKeys) => {
    setExpandedKeys(expandedKeys);
    setAutoExpandParent(false);
  };

  const onCheck = (checkedKeys) => {
    setCheckedKeys(checkedKeys);
  };

  const onSelect = (selectedKeys, info) => {
    setSelectedKeys(selectedKeys);
  };

  const handleToggle = () => {
    setToggle(current => !current);
  }

  return (
    <PageSettings.Consumer>
      {({ checkPermissionAction }) => (
        <OverTimeContext.Provider
          value={{
            state: {
              menuTree,
              autoExpandParent,
              selectedKeys,
              expandedKeys,
              defaultCheckedKeys,
              checkedKeys,
              searchValue,
              comId,
              toggle
            },
            setState: {
              setAutoExpandParent,
              setSearchValue,
              setExpandedKeys,
            },
            fnc: {
              onExpand,
              onCheck,
              onSelect,
              handleToggle
            }
          }}
        >
          {checkPermissionAction(`P24PG1C1`, `P24PG1C1A1`) ?
            <div>
              {toggle ? <TravelReport LangCode={LangCode} /> :
                <Row gutter={[16, 16]}>
                  <Col span={6}>
                    <OrganizationMenu
                      value={{
                        state: {
                          menuTree,
                          autoExpandParent,
                          selectedKeys,
                          expandedKeys,
                          defaultCheckedKeys,
                          checkedKeys,
                          searchValue,
                          comId,
                          toggle
                        },
                        setState: {
                          setAutoExpandParent,
                          setSearchValue,
                          setExpandedKeys,
                        },
                        fnc: {
                          onExpand,
                          onCheck,
                          onSelect,
                          handleToggle
                        }
                      }}
                    />
                  </Col>
                  <Col span={18}>
                    <TravelReport LangCode={LangCode} />
                  </Col>
                </Row>
              }
            </div>
            : null}
        </OverTimeContext.Provider>
      )}
    </PageSettings.Consumer>
  )
}