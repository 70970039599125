import React from 'react'
import { Form, Modal, Row, Col, Input, Select, Icon } from 'antd'
import { useIntl, FormattedMessage } from 'react-intl';
import Button02 from '../v2/button_02';
import Button01 from '../v2/button_01';
import LabeRequireForm from '../label-required-form';
import './index.css'
import { createConvertUoM, editConvertUoM } from '../../controllers/uom/uom';
import { handleKeyDownNumber } from '../../component-function/fnc-number'
import _ from 'lodash'
import { successNotification, errorNotification } from '../v2/notification';

const { Option } = Select
const { TextArea } = Input

const UoMConvert = (props) => {
  const { visible, setVisible, form, uomData, convertType, categoryId, convertDefault, setTrigger } = props
  const intl = useIntl();
  const { getFieldDecorator, validateFields, getFieldValue, resetFields } = form
  const baseUom = getFieldValue("baseUom")
  const altUom = getFieldValue("altUom")

  const handleSave = (type) => {
    validateFields(async (err, values) => {
      if (err) {
        return;
      }
      try {
        const body = {
          "categoryId": convertType === "add" ? categoryId : undefined,
          "baseQty": parseFloat(_.get(values, 'baseQty')),
          "baseUomId": _.get(values, 'baseUom'),
          "altQty": parseFloat(_.get(values, 'altQty')),
          "altUomId": _.get(values, 'altUom'),
          "description": _.get(values, 'description')
        }
        const response = convertType === "add" ? await createConvertUoM(body) : await editConvertUoM(_.get(convertDefault, 'uomConvertId'), body)
        console.log('UOMres',_.get(response, 'data'));
        if (_.get(response, 'data.status.code') === 200) {
          if (type === "save") setVisible(false)
          successNotification(_.get(response, 'data.status.message'))
          setTrigger(current => !current)
          resetFields()
        } else {
          errorNotification(_.get(response, 'data.status.message'))
        }
      } catch (error) {
        errorNotification(_.get(error, 'response.data.status.message'))
      }


    })
  }

  const handleCancel = () => {
    setVisible(false)
    resetFields()
  }

  const checkButton = convertType === "add" ? [
    <Button02 key="back" btnsize="wd_df" onClick={handleCancel}>
      <FormattedMessage id="userDetailModalBtnCancel" defaultMessage="Cancel" />
    </Button02>,
    <Button01 key="submit" type="primary" btnsize="wd_df" style={{ margin: '0' }} onClick={() => handleSave("save")}>
      <FormattedMessage id="btnSave" defaultMessage="Save" />
    </Button01>,
    <Button01 key="submit" type="primary" btnsize="wd_ct" onClick={() => handleSave("continue")}>
      <FormattedMessage id="btnSaveAndContinue" defaultMessage="Save and Continue" />
    </Button01>
  ] : [
    <Button02 key="back" btnsize="wd_df" onClick={handleCancel}>
      <FormattedMessage id="userDetailModalBtnCancel" defaultMessage="Cancel" />
    </Button02>,
    <Button01 key="submit" type="primary" btnsize="wd_df" style={{ margin: '0' }} onClick={() => handleSave("save")}>
      <FormattedMessage id="btnSave" defaultMessage="Save" />
    </Button01>
  ]

  return (
    <Modal
      title={convertType === "add" ?
        intl.formatMessage({ id: 'modalUoMConvertHeadTextAdd', defaultMessage: 'Add UoM Convert' }) :
        intl.formatMessage({ id: 'modalUoMConvertHeadTextEdit', defaultMessage: 'Edit UoM Convert' })
      }
      centered={true}
      visible={visible}
      width={728}
      onCancel={handleCancel}
      footer={checkButton}
      bodyStyle={{ padding: '24px 24px 16px 24px' }}
    >
      <Form form={form} layout="vertical" className='modalConvertForm'>
        <Row gutter={[16]}>
          <Col span={5}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'modalUoMConvertTextBaseQty', defaultMessage: 'Base Qty' })} req={true} />
              }
            >
              {getFieldDecorator('baseQty', {
                initialValue: "1",
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'modalUoMConvertValidateBaseQty', defaultMessage: 'Please Enter Base Qty' }),
                  },
                ],
              })(
                <Input
                  placeholder={intl.formatMessage({ id: 'modalUoMConvertPlaceholderBaseQty', defaultMessage: 'Enter Base Qty' })}
                  disabled={true}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'modalUoMConvertTextBaseUoM', defaultMessage: 'Base UoM' })} req={true} />
              }
            >
              {getFieldDecorator('baseUom', {
                initialValue: convertType === "edit" ? _.get(convertDefault, 'baseUomId') : undefined,
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'modalUoMConvertValidateBaseUoM', defaultMessage: 'Please Enter Base UoM' }),
                  },
                ],
              })(
                <Select
                  placeholder={intl.formatMessage({ id: 'modalUoMConvertPlaceholderBaseUoM', defaultMessage: 'Enter Base UoM' })}
                  allowClear={true}
                >
                  {
                    uomData && uomData.map(item =>
                      <Option key={item.uomId} disabled={item.uomId === altUom ? true : false}>
                        {item.name}
                      </Option>
                    )
                  }
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={2} style={{ display: 'flex', justifyContent: 'center', marginTop: '30px' }}>
            <Icon type="swap-right" style={{ fontSize: '30px' }} />
          </Col>
          <Col span={5}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'modalUoMConvertTextAltQty', defaultMessage: 'Alt Qty' })} req={true} />
              }
            >
              {getFieldDecorator('altQty', {
                initialValue: convertType === "edit" ? _.get(convertDefault, 'altQty') : undefined,
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'modalUoMConvertValidateAltQty', defaultMessage: 'Please Enter Alt Qty' }),
                  },
                ],
              })(
                <Input
                  onKeyDown={handleKeyDownNumber}
                  placeholder={intl.formatMessage({ id: 'modalUoMConvertPlaceholderAltQty', defaultMessage: 'Enter Alt Qty' })}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'modalUoMConvertTextAltUoM', defaultMessage: 'Alt UoM' })} req={true} />
              }
            >
              {getFieldDecorator('altUom', {
                initialValue: convertType === "edit" ? _.get(convertDefault, 'altUomId') : undefined,
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'modalUoMConvertValidateAltUoM', defaultMessage: 'Please Enter Alt UoM' }),
                  },
                ],
              })(
                <Select
                  placeholder={intl.formatMessage({ id: 'modalUoMConvertPlaceholderAltUoM', defaultMessage: 'Enter Alt UoM' })}
                  allowClear={true}
                >
                  {
                    uomData && uomData.map(item =>
                      <Option key={item.uomId} disabled={item.uomId === (convertType === "edit" ? _.get(convertDefault, 'baseUomId') : baseUom )? true : false}>
                        {item.name}
                      </Option>
                    )
                  }
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16]}>
          <Col span={24}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'modalUoMConvertTextDescription', defaultMessage: 'Description' })} req={false} />
              }
            >
              {getFieldDecorator('description', {
                initialValue: convertType === "edit" ? _.get(convertDefault, 'description') : undefined,
              })(
                <TextArea
                  autosize={{ minRows: 4, maxRows: 4 }}
                  maxLength={250}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

const ModalFormUOMConvert = Form.create({
  name: 'modal_form',
})(UoMConvert);


export default ModalFormUOMConvert
