import React, { createContext, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { numberFormatter } from "../../../component-function/fnc-inputnumber-currency";
import _ from 'lodash';
import ModalPettyCash from "../modal";
import { Modal } from "antd";
import { useParams } from 'react-router-dom';
import { closePettyCash, getPettyCash, getPettyCashCategory, getPocketPettyCash, getTransactionPettyCash, exportTransectionPettyCash } from "../../../controllers/petty-cash/petty-cash";
import { fncGetUsers } from "../../../controllers/user/users";
import { getTeamOrg } from "../../../controllers/team/team";
import { errorNotification, successNotification } from "../../../components/v2/notification";

const PettyCashDetailProvider = createContext();

const PettyCashDetailContext = ({ children }) => {
  const intl = useIntl()
  let defaultColumns = {
    columns: [
      {
        title: '#',
        dataIndex: 'index',
        key: 'index',
        width: 70,
        align: 'center',
        fixed: 'left',
      },
      // {
      //   title: intl.formatMessage({ id: 'pettyCashCLDocNo', defaultMessage: 'Document No.' }),
      //   dataIndex: 'documentNo',
      //   key: 'documentNo',
      //   width: 200,
      //   fixed: 'left',
      //   sorter: true,
      // },
      {
        title: intl.formatMessage({ id: 'pettyCashCLCategory', defaultMessage: 'Category' }),
        dataIndex: 'category',
        key: 'category',
        width: 150,
        sorter: true,
      },
      {
        title: intl.formatMessage({ id: 'pettyCashCLRemark', defaultMessage: 'Remark' }),
        dataIndex: 'remark',
        key: 'remark',
        width: 150,
        sorter: true,
        render: (text, record, index) => _.get(record, 'remark', '-') ? _.get(record, 'remark', '-') : "-"
      },
      {
        title: intl.formatMessage({ id: 'pettyCashCLCredit', defaultMessage: 'Credit' }),
        dataIndex: 'credit',
        key: 'credit',
        width: 200,
        sorter: true,
        align: 'right',
        render: (text, record, index) => numberFormatter(_.get(record, 'credit', 0)) + " " + 'THB'
      },
      {
        title: intl.formatMessage({ id: 'pettyCashCLDebit', defaultMessage: 'Debit' }),
        dataIndex: 'debit',
        key: 'debit',
        width: 200,
        sorter: true,
        align: 'right',
        render: (text, record, index) => numberFormatter(_.get(record, 'debit', 0)) + " " + 'THB'
      },
      {
        title: intl.formatMessage({ id: 'pettyCashCLBalance', defaultMessage: 'Balance' }),
        dataIndex: 'balance',
        key: 'balance',
        width: 200,
        sorter: true,
        align: 'right',
        render: (text, record, index) => numberFormatter(_.get(record, 'balance', 0)) + " " + 'THB'
      },
      {
        title: intl.formatMessage({ id: 'pettyCashCLReference', defaultMessage: 'Reference' }),
        dataIndex: 'referenceNo',
        key: 'referenceNo',
        width: 150,
        sorter: true,
        render: (text, record, index) => _.get(record, 'referenceNo', '-') !== "" ? _.get(record, 'referenceNo', '-') : '-'
      },
      {
        title: intl.formatMessage({ id: 'pettyCashCLActionby', defaultMessage: 'Action By' }),
        dataIndex: 'actionBy',
        key: 'actionBy',
        width: 200,
        sorter: true,
        render: (text, record, index) => _.get(record, 'actionBy', '-')
      },
      {
        title: intl.formatMessage({ id: 'pettyCashCLTransactionDate', defaultMessage: 'Transaction Date' }),
        dataIndex: 'transectionDate',
        key: 'transectionDate',
        width: 200,
        render: (text, record, index) => _.get(record, 'transectionDate', '-')
      },
    ],
    setShowColumnArr: [
      'index',
      // 'documentNo',
      'category',
      'remark',
      'credit',
      'debit',
      'balance',
      'referenceNo',
      'actionBy',
      'transectionDate'
    ],
    setShowColumn: {
      index: true,
      // documentNo: true,
      category: true,
      remark: true,
      credit: true,
      debit: true,
      balance: true,
      referenceNo: true,
      actionBy: true,
      transectionDate: true,
    },
  }
  const [searchGroup, setSearchGroup] = useState({});
  const [tableChange, setTableChange] = useState({ page: 1, pageSize: 10, orderBy: null, orderField: null });
  const [listArrayColumns, setListArrayColumns] = useState([..._.get(defaultColumns, 'setShowColumnArr')]);
  const [defaultShowColumn, setDefaultShowColumn] = useState({ ..._.get(defaultColumns, 'setShowColumn') });
  const actionColumns = [..._.get(defaultColumns, 'columns')]
  const ignoreColumn = []
  const newColumns = defaultColumns.columns.filter((col) => !ignoreColumn.includes(col.key));
  const [newDataColumns, setNewDataColumns] = useState([...newColumns]);
  const [textErrorSelectColumn, setTextErrorSelectColumn] = useState('');
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const [dataSource, setDataSource] = useState([])
  const [total, setTotal] = useState(1)
  const [defaultModal, setDefaultModal] = useState({ type: '', data: {}, visible: false })
  const params = useParams();
  const [defaultData, setDefaultData] = useState()
  const [userList, setUserList] = useState({});
  const [teamList, setTeamList] = useState({});
  const [triggerSearch, setTriggerSearch] = useState(false)
  const [pocketList, setPocketList] = useState([])
  const [summary, setSummary] = useState({})
  const [categoryList, setCategoryList] = useState([])
  const [triggerCategory, setTriggerCategory] = useState(false)

  useEffect(() => {
    const getInitialData = async () => {
      const memComId = localStorage.getItem('memComId');
      const getAssignee = await fncGetUsers(memComId);
      const getTeam = await getTeamOrg();
      const responsePocker = await getPocketPettyCash();
      setUserList({
        list: getAssignee,
        master: getAssignee,
      });
      setTeamList({
        list: _.get(getTeam, 'data'),
        master: _.get(getTeam, 'data'),
      });
      setPocketList(_.get(responsePocker, 'data.data'))
    };
    getInitialData();
  }, []);

  useEffect(() => {
    const getInitialDataTrigger = async () => {
      const responseCategory = await getPettyCashCategory()
      setCategoryList(_.get(responseCategory, 'data.result'))
    }
    getInitialDataTrigger()
  }, [triggerCategory])


  useEffect(() => {
    const getDefaultData = async () => {
      const response = await getPettyCash(_.get(params, 'id'))
      setDefaultData(_.get(response, 'data.response[0]'))
    }
    getDefaultData()
  }, [trigger])

  useEffect(() => {
    const getListData = async () => {
      const body = {
        "limit": _.get(tableChange, 'pageSize'),
        "page": _.get(tableChange, 'page'),
        "orderType": _.get(tableChange, 'orderBy', ""),
        "orderBy": _.get(tableChange, 'orderField', ""),
        "documentNo": _.get(searchGroup, 'docNo', ""),
        "category": _.get(searchGroup, 'category', ""),
        "reference": _.get(searchGroup, 'reference', ""),
        "pettyCashId": _.get(params, 'id')
      }
      const response = await getTransactionPettyCash(body)
      setSummary(_.get(response, 'data.summaryResponce', {}))
      setDataSource(_.get(response, 'data.result.docs', []))
      setTotal(_.get(response, 'data.result.total', 0))
    }
    getListData()
  }, [trigger, tableChange, triggerSearch])

  const handleExport = async () => {
    const filterIndex = _.filter(defaultColumns.columns, (item) => { return item.dataIndex !== 'index' })
    const body = {
      "limit": total,
      "page": 1,
      "orderType": _.get(tableChange, 'orderBy', ""),
      "orderBy": _.get(tableChange, 'orderField', ""),
      "documentNo": _.get(searchGroup, 'docNo', ""),
      "category": _.get(searchGroup, 'category', ""),
      "reference": _.get(searchGroup, 'reference', ""),
      "pettyCashId": _.get(params, 'id'),
      "viewFormat": _.map(filterIndex, (item, index) => {
        return {
          colName: item.title,
          sequence: index + 1,
          colCode: item.dataIndex,
        }
      })
    }
    const response = await exportTransectionPettyCash(body)
    if (response.status === 200) {
      window.open(response.data.data.excelExportUrl);
    }
  }



  const handleOpenModal = (type, record) => {
    setDefaultModal({
      type: type,
      data: record,
      visible: true
    })
  }

  const handleTableChange = (pagination, sorter, extra) => {
    let field = extra.field;

    if (extra.order) {
      if (extra.order === 'descend') {
        setTableChange((prev) => ({ ...prev, orderBy: 'desc' }));
      } else {
        setTableChange((prev) => ({ ...prev, orderBy: 'asc' }));
      }
    } else {
      setTableChange((prev) => ({ ...prev, orderBy: '' }));
    }
    setTableChange((prev) => ({ ...prev, orderField: field, page: pagination.current, pageSize: pagination.pageSize }));
  };

  const handleDeleteItem = (record) => {
    const title = _.get(record, 'balance') > 0 ? intl.formatMessage({ id: 'pettyCashCannotCloseMessage', defaultMessage: 'Petty Cash cannot be disabled. Please remove cash from Petty Cash first.' }) :
      intl.formatMessage({ id: 'pettyCashCloseMessage', defaultMessage: 'Do you want to close a petty cash?' })
    console.log("title", title)
    const okText = _.get(record, 'balance') > 0 ? intl.formatMessage({ id: 'btnTranfer', defaultMessage: 'Tranfer' }) :
      intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' })
    Modal.confirm({
      className: 'customer-modal-confirm',
      centered: true,
      icon: null,
      title: title,
      okText: okText,
      cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        if (_.get(record, 'balance') > 0) {
          handleOpenModal('tranfer', defaultData)
        } else if (_.get(record, 'balance') < 0) {
          errorNotification(intl.formatMessage({ id: 'pettyCashConfirmMorethan', defaultMessage: `You can't close this account until the balance is 0.` }))
        } else if (_.get(record, 'balance') === 0) {
          const body = {
            pettyCashId: _.get(record, 'pettyCashId'),
            code: 'closed'
          }
          const response = await closePettyCash(body)
          if (_.get(response, 'data.status.code') === 200) {
            successNotification(_.get(response, 'data.status.message'))
            setTrigger(cur => !cur)
          } else {
            errorNotification(_.get(response, 'data.status.message'))
          }
        }
      },
      onCancel() { },
    });
  };



  return (
    <PettyCashDetailProvider.Provider
      value={{
        state: {
          searchGroup,
          tableChange,
          listArrayColumns,
          defaultShowColumn,
          newColumns,
          textErrorSelectColumn,
          visible,
          actionColumns,
          loading,
          defaultColumns,
          newDataColumns,
          dataSource,
          total,
          defaultData,
          summary,
          categoryList
        },
        setState: {
          setSearchGroup,
          setTableChange,
          setTrigger,
          setNewDataColumns,
          setListArrayColumns,
          setDefaultShowColumn,
          setTextErrorSelectColumn,
          setVisible,
          setTriggerSearch
        },
        fnc: {
          handleOpenModal,
          handleTableChange,
          handleDeleteItem,
          handleExport
        }
      }}
    >
      {children}

      <ModalPettyCash
        defaultModal={defaultModal}
        setDefaultModal={setDefaultModal}
        userListMaster={userList}
        teamListMaster={teamList}
        pocketListMaster={pocketList}
        categoryList={categoryList}
        setTrigger={setTrigger}
        setTriggerCategory={setTriggerCategory}
      />

    </PettyCashDetailProvider.Provider>
  )
}

export { PettyCashDetailProvider, PettyCashDetailContext };