import httpClient from '../../components/axiosClient';

const getBranchList = async (body) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v5/order/manager/company/${comCode}/list-branch`, body);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
}

const createBranch = async (body) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v5/order/manager/company/${comCode}/create-branch`, body);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
}

const uploadImageBranch = async (id, body) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v5/order/manager/company/${comCode}/branch/${id}/upload-profile-branch`, body);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
}



const updateBranch = async (id, body) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.put(`/v5/order/manager/company/${comCode}/branch/${id}/update-branch`, body);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
}


const deleteBranch = async (id) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.delete(`/v5/order/manager/company/${comCode}/branch/${id}/delete-branch`);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
}

const getBranchManager = async () => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.get(`/v5/order/manager/company/${comCode}/initial-branch-manager`);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
}

const getBranchManagerList = async (body) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v5/order/manager/company/${comCode}/list-branch-manager`, body);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
}

const createBranchManagerList = async (body) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v5/order/manager/company/${comCode}/create-branch-manager`, body);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
}

const updateBranchManagerList = async (id, body) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.put(`/v5/order/manager/company/${comCode}/branch-manager/${id}/update-branch-manager`, body);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
}

const deleteBranchManagerList = async (id) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.delete(`/v5/order/manager/company/${comCode}/branch-manager/${id}/delete-branch-manager`);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
}

const getBranchMemberList = async (id, body) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v5/order/manager/company/${comCode}/branch/${id}/list-branch-member`, body);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
}

const updateBranchMemberList = async (id, body) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.put(`/v5/order/manager/company/${comCode}/branch/${id}/update-branch-member`, body);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
}

const deleteBranchMemberList = async (id, memcom) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.delete(`/v5/order/manager/company/${comCode}/branch/${id}/member/${memcom}/delete-branch-member`);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
}



export {
  getBranchList, uploadImageBranch, createBranch, updateBranch, deleteBranch, getBranchManager,
  getBranchManagerList, createBranchManagerList, updateBranchManagerList, deleteBranchManagerList,
  getBranchMemberList, updateBranchMemberList, deleteBranchMemberList
}