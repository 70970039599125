import _ from 'lodash';
import React from 'react';
import NodataIMG from '../image/Nodata_web.svg';
import { FormattedMessage } from 'react-intl';
import './index.css';

const NoDataBackground2 = (props) => {
  const { text1, text2 } = props;

  const id_1 = _.get(text1, 'id') || '';
  const word_1 = _.get(text1, 'text') || '';
  const id_2 = _.get(text2, 'id') || '';
  const word_2 = _.get(text2, 'text') || '';

  return (
    <div style={{ padding: '0px 18px' }} className="no-data-blank-svg-new2">
      <div className="no-data-blank-div-new2">
        <img src={NodataIMG} className="no-data-blank-svg-img-new2" />
        <p className="no-data-blank-text-1-new2">
          <FormattedMessage id={id_1} defaultMessage={word_1} />
        </p>
        <p className="no-data-blank-text-2-new2">
          <FormattedMessage id={id_2} defaultMessage={word_2} />
        </p>
      </div>
    </div>
  );
};

export default NoDataBackground2;
