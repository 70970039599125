import httpClient from '../../components/axiosClient';

const getTaskPoolData = async (orgId, type) => {
  const comId = localStorage.getItem('comId');
  try {
    const response = await httpClient.get(`/v3/task/manager/taskpool?com_id=${comId}&org_id=${orgId}&menuType=${type}`);
    if (response.status == 200) {
      return response;
    } else {
      return response;
    }
  } catch (error) {
    return error.response;
  }
};

const getTaskPoolDataNew = async (payload) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v4/task/manager/company/${comCode}/monitor/taskpool`, payload);
    if (response.status == 200) {
      return response;
    } else {
      return response;
    }
  } catch (error) {
    return error.response;
  }
};

const getTaskGantt = async (body) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v4/task/manager/company/${comCode}/monitor/ganttview`, body);
    if (response.status == 200) {
      return response;
    } else {
      return response;
    }
  } catch (error) {
    return error.response;
  }
};

export { getTaskPoolData, getTaskGantt, getTaskPoolDataNew };
