import React, { useEffect, useState } from "react";
import { Modal, Input, Form, Row, Col, Select, Option, AutoComplete } from "antd";
import Button01 from "../v2/button_01";
import Button02 from "../v2/button_02";
import { useIntl } from 'react-intl';
import LabeRequireForm from '../label-required-form/index'
import { getWarehouseListByItemMaster } from "../../controllers/item-master/warehouse";
import _ from 'lodash'
import { useDebounce } from '../../controllers/debounce'
import { handleKeyDownDecimal2Fixed, handleKeyDownNumber } from "../../component-function/fnc-number";
import { getLocationByItem, updateWarehouseStock, getWarehouseStockById } from "../../controllers/warehouse/warehouse";
import { FormattedMessage } from "react-intl";
import { successNotification, errorNotification } from "../v2/notification";

const AssignWarehouseEditModal = (props) => {
  const { triggerVisible, setTriggerVisible, form, itemasterId, data, setTrigger, stockId } = props
  const { validateFields, resetFields, getFieldDecorator, setFieldsValue, getFieldValue } = form
  const intl = useIntl();
  const [defaultData, setDefaultData] = useState()
  const [searhByCode, setSearchByCode] = useState('');
  const [searhByName, setSearchByName] = useState('');
  const [wareHouseCodeData, setWareHouseCodeData] = useState([])
  const [wareHouseNameData, setWareHouseNameData] = useState([])
  const [binLocationList, setBinLocationList] = useState([]);
  const debouncSearchCode = useDebounce(searhByCode, 500)
  const debouncSearchName = useDebounce(searhByName, 500)
  const extraCost = getFieldValue("extraCost") || 0.00
  const extraPrice = getFieldValue("extraPrice") || 0.00
  const warehouseCode = getFieldValue("warehouseCode")
  const warehouseName = getFieldValue("warehouseName")
  const debouncwarehouseCode = useDebounce(warehouseCode, 500)
  const debouncwarehouseName = useDebounce(warehouseName, 500)

  const { Option } = Select;

  useEffect(() => {
    async function StockDetail() {
      const response = await getWarehouseStockById(stockId)
      setDefaultData(_.get(response, 'data.data'))
      const setWarehouse = [{
        warehouseCode: _.get(response, 'data.data.warehouseCode'),
        warehouseId: _.get(response, 'data.data.warehouseId'),
        warehouseName: _.get(response, 'data.data.warehouseName')
      }]
      const mapBinLocation = _.map(_.get(response, 'data.data.binLocation'), (item) => {
        return {
          binLoationId: item.binLocationId,
          name: item.name
        }
      })
      setWareHouseCodeData(setWarehouse)
      setWareHouseNameData(setWarehouse)
      setBinLocationList(mapBinLocation)
    }
    if (stockId && triggerVisible) {
      StockDetail()
    }
  }, [stockId, triggerVisible])


  const handleSaveModal = (type) => {
    validateFields(async (err, values) => {
      if (err) {
        return;
      }
      const body = {
        "warehouseId": values.warehouseCode || values.warehouseName,
        "itemMasterDetail": data,
        "maxQty": parseFloat(values.maxQty),
        "minQty": parseFloat(values.minQty),
        "leadTime": parseFloat(values.leadTime),
        "standardCost": parseFloat(_.get(data, 'standardCost')),
        "extraCost": parseFloat(values.extraCost || 0),
        "totalCost": parseFloat(values.totalCost || 0),
        "standardPrice": parseFloat(_.get(data, 'standardPrice')),
        "extraPrice": parseFloat(values.extraPrice || 0),
        "totalPrice": parseFloat(values.totalPrice || 0),
        "remark": '',
        "binLocationIdList": [

        ],
      }
      try {
        const response = await updateWarehouseStock(stockId, body)
        if (_.get(response, 'status') === 200) {
          successNotification(_.get(response.data.status, 'message'));
          if (type === "save") setTriggerVisible(false)
          setTrigger(current => !current)
          resetFields();
        } else {
          errorNotification(_.get(response.data.status, 'message'));
        }
      } catch (error) {

      }
    })
  }

  const handleCloseModal = () => {
    setTriggerVisible(false);
    resetFields()
  }

  const handleSearchCode = (value) => {
    setSearchByCode(value || '');
  };

  const handleSearchName = (value) => {
    setSearchByName(value || '');
  }



  const handleSelectCode = (value) => {
    resetFields(['extraCost', 'extraPrice', 'maxQty', 'minQty', 'leadTime', 'binLocation']);
    if (value) {
      setWareHouseNameData(wareHouseCodeData)
      setFieldsValue({
        warehouseName: value,
      });
    }
  };

  const handleSelectName = (value) => {
    resetFields(['extraCost', 'extraPrice', 'maxQty', 'minQty', 'leadTime', 'binLocation']);
    if (value) {
      setWareHouseCodeData(wareHouseNameData);
      setFieldsValue({
        warehouseCode: value,
      });
    }
  };


  useEffect(() => {
    const getWareCode = async () => {
      const payload = {
        searchCode: searhByCode,
      };
      const response = await getWarehouseListByItemMaster(itemasterId, payload);
      const list = _.get(response.data.data, 'listItems');
      setWareHouseCodeData(_.uniqBy(list))
    };
    getWareCode();
  }, [debouncSearchCode]);

  useEffect(() => {
    const getWareName = async () => {
      const payload = {
        searchName: searhByName,
      };
      const response = await getWarehouseListByItemMaster(itemasterId, payload);
      const list = _.get(response.data.data, 'listItems');
      setWareHouseNameData(_.uniqBy(list))
    };
    getWareName();
  }, [debouncSearchName]);

  useEffect(() => {
      const sumCost = parseFloat(_.get(data, 'standardCost') || 0.00) + parseFloat(extraCost)
      setFieldsValue({
        totalCost: parseFloat(sumCost).toFixed(2),
      });
  }, [extraCost, _.get(data, 'standardCost')])

  useEffect(() => {
      const sumPrice = parseFloat(_.get(data, 'standardPrice') || 0.00) + parseFloat(extraPrice)
      setFieldsValue({
        totalPrice: parseFloat(sumPrice).toFixed(2),
      });
  }, [extraPrice, _.get(data, 'standardPrice')])

  useEffect(() => {
    const getLocationForStock = async () => {
      const payload = {
        itemMasterDetail: data,
        warehouseId: warehouseCode || warehouseName,
      };
      const response = await getLocationByItem(payload);
      setBinLocationList(_.get(response.data.data, 'binLocationList'));
    };
    if ((itemasterId) && (warehouseCode || warehouseName)) {
      getLocationForStock();
    }
  }, [itemasterId, debouncwarehouseCode, debouncwarehouseName, stockId]);

  const validatorMinQty = (rule, value, callback) => {
    let max = getFieldValue('maxQty');
    if (value) {
      console.log("payloadToNumber",payloadToNumber(value) , payloadToNumber(max))
      if (payloadToNumber(value) > payloadToNumber(max)) {
        callback(intl.formatMessage({ id: 'modalAssignWareHouseCallbackMinNotBeLessMax', defaultMessage: 'Min Qty must not be more than Max Qty'}));
      } else {
        callback();
      }
    } else {
      callback();
    }

  };

  const validatorMaxQty = (rule, value, callback) => {
    let min = getFieldValue('minQty');
    if (value) {
      if (payloadToNumber(value) < payloadToNumber(min)) {
        callback(intl.formatMessage({ id: 'modalAssignWareHouseCallbackMaxNotBeLessMin', defaultMessage: 'Max Qty must not be less than Min Qty'}));
      } else {
        callback();
      }
    } else {
      callback()
    }

  };

  const payloadToNumber = (value) => {
    return _.toNumber(value);
  };


  return (
    <div>
      <Modal
        title="Assign Warehouse"
        visible={triggerVisible}
        onCancel={handleCloseModal}
        onOk={handleSaveModal}
        centered
        bodyStyle={{ height: '620px', overflowY: 'auto' }}
        width={780}
        footer={[
          <Button02 key="back" btnsize="wd_df" onClick={handleCloseModal}><FormattedMessage id="userDetailModalBtnCancel" defaultMessage="Cancel" /></Button02>,
          <Button01 key="submit" type="primary" btnsize="wd_df" style={{ margin: '0' }} onClick={() => handleSaveModal('save')}><FormattedMessage id="btnSave" defaultMessage="Save" /></Button01>,
        ]}
      >
        <Form form={form} layout="vertical">
          <Row gutter={[24, 12]}>
            <Col span={12}>
              <Form.Item
                label={
                  <LabeRequireForm text={intl.formatMessage({ id: 'modalAssignWareHouseTextWarehouseCode', defaultMessage: 'Warehouse Code' })} req={true} />
                }
              >
                {getFieldDecorator('warehouseCode', {
                  initialValue: _.get(defaultData, 'warehouseId'),
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'modalAssignWareHouseValidateWarehouseCode', defaultMessage: 'Please Select Warehouse' }),
                    },
                  ],
                })(
                  <AutoComplete
                    onSearch={handleSearchCode}
                    onSelect={(value, evnt) => handleSelectCode(value, evnt)}
                    dataSource={wareHouseCodeData}
                    filterOption={false}
                    placeholder={intl.formatMessage({ id: 'modalAssignWareHousePlaceholderWarehouseCode', defaultMessage: 'Select Warehouse' })}
                  >
                    {wareHouseCodeData &&
                      wareHouseCodeData.map((item) => (
                        <Option key={item.warehouseCode} value={item.warehouseId}>
                          {item.warehouseName}
                        </Option>
                      ))}
                  </AutoComplete>
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <LabeRequireForm text={intl.formatMessage({ id: 'modalAssignWareHouseTextWarehouseName', defaultMessage: 'Warehouse Name' })} req={true} />
                }
              >
                {getFieldDecorator('warehouseName', {
                  initialValue: _.get(defaultData, 'warehouseId'),
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'modalAssignWareHouseValidateWarehouseName', defaultMessage: 'Please Select Name' }),
                    },
                  ],
                })(
                  <AutoComplete
                    onSearch={handleSearchName}
                    onSelect={(value, evnt) => handleSelectName(value, evnt)}
                    dataSource={wareHouseNameData}
                    filterOption={false}
                    placeholder={intl.formatMessage({ id: 'modalAssignWareHousePlaceholderWarehouseName', defaultMessage: 'Select Warehouse Name' })}
                  >
                    {wareHouseNameData &&
                      wareHouseNameData.map((item) => (
                        <Option key={item.warehouseCode} value={item.warehouseId}>
                          {item.warehouseName}
                        </Option>
                      ))}
                  </AutoComplete>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[24, 12]}>
            <Col span={12}>
              <Form.Item
                label={
                  <LabeRequireForm text={intl.formatMessage({ id: 'modalAssignWareHouseTextStandardCost', defaultMessage: 'Standard Cost' })} req={false} />
                }
              >
                {getFieldDecorator('standardCost', {
                  initialValue: parseFloat(_.get(defaultData, 'standardCost') || 0.00).toFixed(2)
                })(
                  <Input
                    placeholder={intl.formatMessage({ id: 'modalAssignWareHousePlaceholderStandardCost', defaultMessage: 'Enter Standard Cost' })}
                    disabled={true}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <LabeRequireForm text={intl.formatMessage({ id: 'modalAssignWareHouseTextExtraCost', defaultMessage: 'Extra Cost' })} req={false} />
                }
              >
                {getFieldDecorator('extraCost', {
                  initialValue: parseFloat(_.get(defaultData, 'extraCost') || 0.00).toFixed(2)
                })(
                  <Input
                    placeholder={intl.formatMessage({ id: 'modalAssignWareHousePlaceholderExtraCost', defaultMessage: 'Enter Extra Cost' })}
                    onKeyDown={handleKeyDownDecimal2Fixed}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[24, 12]}>
            <Col span={12}>
              <Form.Item
                label={
                  <LabeRequireForm text={intl.formatMessage({ id: 'modalAssignWareHouseTextTotalCost', defaultMessage: 'Total Cost' })} req={false} />
                }
              >
                {getFieldDecorator('totalCost', {
                  initialValue: parseFloat(_.get(defaultData, 'totalCost') || 0.00).toFixed(2)
                })(
                  <Input
                    placeholder={intl.formatMessage({ id: 'modalAssignWareHousePlaceholderStandardCost', defaultMessage: 'Enter Total Cost' })}
                    disabled={true}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[24, 12]}>
            <Col span={12}>
              <Form.Item
                label={
                  <LabeRequireForm text={intl.formatMessage({ id: 'modalAssignWareHouseTextStandardPrice', defaultMessage: 'Standard Price' })} req={false} />
                }
              >
                {getFieldDecorator('standardPrice', {
                  initialValue: parseFloat(_.get(defaultData, 'standardPrice') || 0.00).toFixed(2)
                })(
                  <Input
                    placeholder={intl.formatMessage({ id: 'modalAssignWareHousePlaceholderStandardPrice', defaultMessage: 'Enter Standard Price' })}
                    disabled={true}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <LabeRequireForm text={intl.formatMessage({ id: 'modalAssignWareHouseTextExtraPrice', defaultMessage: 'Extra Price' })} req={false} />
                }
              >
                {getFieldDecorator('extraPrice', {
                  initialValue: parseFloat(_.get(defaultData, 'extraPrice') || 0.00).toFixed(2)
                })(
                  <Input
                    onKeyDown={handleKeyDownDecimal2Fixed}
                    placeholder={intl.formatMessage({ id: 'modalAssignWareHousePlaceholderExtraPrice', defaultMessage: 'Enter Extra Price' })}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[24, 12]}>
            <Col span={12}>
              <Form.Item
                label={
                  <LabeRequireForm text={intl.formatMessage({ id: 'modalAssignWareHouseTextTotalPrice', defaultMessage: 'Total Price' })} req={false} />
                }
              >
                {getFieldDecorator('totalPrice', {
                  initialValue: parseFloat(_.get(defaultData, 'totalPrice') || 0.00).toFixed(2)
                })(
                  <Input
                    placeholder={intl.formatMessage({ id: 'modalAssignWareHousePlaceholderStandardPrice', defaultMessage: 'Enter Total Price' })}
                    disabled={true}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[24, 12]}>
            <Col span={12}>
              <Form.Item
                label={
                  <LabeRequireForm text={intl.formatMessage({ id: 'modalAssignWareHouseTextMinQty', defaultMessage: 'Min Qty' })} req={true} />
                }
              >
                {getFieldDecorator('minQty', {
                  initialValue: _.get(defaultData, 'minQty'),
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'modalAssignWareHouseValidateMinQty', defaultMessage: 'Please Enter Min Qty' }),
                    },
                    {
                      validator: validatorMinQty,
                    },
                  ],
                })(
                  <Input
                    onKeyDown={handleKeyDownNumber}
                    placeholder={intl.formatMessage({ id: 'modalAssignWareHousePlaceholderMinQty', defaultMessage: 'Enter Min Qty' })}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <LabeRequireForm text={intl.formatMessage({ id: 'modalAssignWareHouseTextMaxQty', defaultMessage: 'Max Qty' })} req={true} />
                }
              >
                {getFieldDecorator('maxQty', {
                  initialValue: _.get(defaultData, 'maxQty'),
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'modalAssignWareHouseValidateMaxQty', defaultMessage: 'Please Enter Max Qty' }),
                    },
                    {
                      validator: validatorMaxQty,
                    },
                  ],
                })(
                  <Input
                    onKeyDown={handleKeyDownNumber}
                    placeholder={intl.formatMessage({ id: 'modalAssignWareHousePlaceholderMaxQty', defaultMessage: 'Enter Max Qty' })}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[24, 12]}>
            <Col span={12}>
              <Form.Item
                label={
                  <LabeRequireForm text={intl.formatMessage({ id: 'modalAssignWareHouseTextLeadTime', defaultMessage: 'Lead Time(Day)' })} req={true} />
                }
              >
                {getFieldDecorator('leadTime', {
                  initialValue: _.get(defaultData, 'leadTime'),
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'modalAssignWareHouseValidateLeadTime', defaultMessage: 'Please Enter Lead Time' }),
                    },
                  ],
                })(
                  <Input
                    onKeyDown={handleKeyDownNumber}
                    placeholder={intl.formatMessage({ id: 'modalAssignWareHousePlaceholderLeadTime', defaultMessage: 'Enter Lead Time' })}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <LabeRequireForm text={intl.formatMessage({ id: 'modalAssignWareHouseTextBinLocation', defaultMessage: 'Bin Location' })} req={false} />
                }
              >
                {getFieldDecorator('binLocation', {
                  initialValue: _.get(defaultData, 'binLocation[0].binLocationId'),
                })(
                  <Select
                    placeholder={intl.formatMessage({ id: 'modalAssignWareHousePlaceholderBinLocation', defaultMessage: 'Select Bin Location' })}
                    maxTagCount={1}
                    mode="multiple"
                    optionLabelProp="label"
                    showSearch
                    filterOption={(input, option) =>
                      option.props.label
                        .toString()
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {binLocationList &&
                      binLocationList.map((item) => (
                        <Option key={item.binLoationId} value={item.binLoationId} label={item.name}>
                          {item.name}
                        </Option>
                      ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  )
}

const ModalFormAssignEditWarehouse = Form.create({
  name: 'modal_form',
})(AssignWarehouseEditModal);

export default ModalFormAssignEditWarehouse