import React, { useState } from 'react';
import { Avatar, Icon, Popover, Button} from 'antd';
import stylePost from './css/post.css';

export default (props) => {
  const { postData } = props;
  const [visiblePopover, setVisiblePopover] = useState(false);

  const handleOnVisiblePopover = (visiblePopover) => {
    setVisiblePopover(visiblePopover);
  }

  return (
    <div className="post-header">
      <div className="post-card-head">
        <div className="modal-post-card-head-left">
          <div className="modal-post-card-head-left-avatar">
            <Avatar size={42} src={`${postData && postData.pathImage}`} />
          </div>
          <div className="modal-post-card-left-text">
            <p className="modal-post-card-left-text-head">{`${postData && postData.fullname}`}</p>
            <div className="modal-post-card-head-left-text">
              {/* {postData && postData.audience === 'public' ? 
                (
                  <Icon type='global' style={{ fontSize: '12px' }} />
                ) : postData.audience === 'only_me' ? 
                (
                  <Icon type='lock' style={{ fontSize: '12px' }} />
                ) : (
                  <Icon type='team' style={{ fontSize: '12px' }} />
                )
              }
              <p className="modal-post-card-left-text-dot">.</p> */}
              <p className="modal-post-card-text-12">
                Posted by {`${postData && postData.postedBy}`} on{' '}
                {`${postData && postData.postedAt}`}
              </p>
            </div>
          </div>
        </div>
        <div className="modal-post-card-head-right">
          {/* {postData && postData.actions.length > 0 ? (
            <Popover
              key={1}
              placement="leftTop"
              trigger="hover"
              visiblePopover={visiblePopover}
              onVisibleChange={handleOnVisiblePopover}
              zIndex={999}
              content={
                <div style={{ display: 'grid' }}>
                  {postData && postData.actions.includes('edit') ? (
                    <Button
                      style={{ width: 100 }}
                      type="link"
                      ghost
                    >
                      Edit
                    </Button>
                  ) : null}
                  {postData && postData.actions.includes("delete") ? (
                    <Button
                      style={{ width: 100 }}
                      type="link"
                      ghost
                    >
                      Delete
                    </Button>
                  ) : null}
                </div>
              }
            >
              <Icon type="more" />
            </Popover>
          ) : null } */}
        </div>
      </div>
    </div>
  );
};
