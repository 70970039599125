import React, { useContext, useEffect } from 'react';
import { Form, Row, Col, Input, Divider, Icon, Select, DatePicker } from 'antd';
import '../css/information.css';
import _ from 'lodash';
import { TaskDetail } from '../../task-detail-context';
import { getTaskInfo } from '../../../../../../controllers/task/task-detail';
import NavigatorSVG from '../../../../../image/navigator.svg';
import ArrowSVG from '../../../../../image/arrow_location.svg';
import {
  getDisTance,
  getCustomeField,
  getTaskTypeData,
  getCustomerLocationAndConatact,
} from '../../../../../../controllers/task/create-task';
import { useIntl, FormattedMessage } from 'react-intl';
import AddContact from '../../../../../add-contact-v2/index';
import AddFromLocation from '../../../../../from-location/add/index';
import EditFromLocation from '../../../../../from-location/edit/index';
import AddAddressBookV2 from '../../../../../modal-create-adress-map-v2/index';
import EditAddressBookV2 from '../../../../../modal-edit-adress-map-v2/index';
import moment from 'moment';
import { useAppContext } from '../../../../../../includes/indexProvider';

const { Option } = Select;

export default (props) => {
  const { form } = props;
  const { getFieldDecorator, getFieldValue, setFieldsValue } = form;
  const { stateTask, setStateTask, fncStateTask } = useContext(TaskDetail);
  const intl = useIntl();
  const app = useAppContext();
  const langValueState = _.get(app, 'state.langValue');
  moment.locale(langValueState);
  const format = langValueState == 'EN' ? 'YYYY-MM-DD h:mm A' : 'YYYY-MM-DD HH:mm';

  useEffect(() => {
    const getDefaultData = async () => {
      if ((_.get(stateTask, 'visible') === true || _.get(stateTask, 'visibleResource') === true) && _.get(stateTask, 'taskId')) {
        setStateTask.setLoading(true);
        const responseInfo = await getTaskInfo(_.get(stateTask, 'taskId'));
        if (responseInfo.status === 200) {
          setStateTask.setViewInformationData(responseInfo.data);
          setStateTask.setSelectTeam(parseFloat(responseInfo.data.information.orgId));
          setStateTask.setStatusTask(responseInfo.data.information.statusName);
          setStateTask.setAssignee(responseInfo.data.assignee);
          const responseCustome = await getCustomeField('task_type', _.get(responseInfo, 'data.information.taskTypeId'), 'workdesk');
          Promise.all([responseCustome]).then((value) => {
            setStateTask.setCustomFieldData(_.get(value, '[0]data'));
          });
          setStateTask.setLoading(false);
        }
      }
    };
    getDefaultData();
  }, [
    _.get(stateTask, 'taskId'),
    _.get(stateTask, 'visible'),
    _.get(stateTask, 'triggerInformation'),
    _.get(stateTask, 'visibleResource'),
    _.get(stateTask, 'triggerApi'),
  ]);

  useEffect(() => {
    const getDefaultDataInfor = async () => {
      const responseTaskType = await getTaskTypeData('workdesk');
      setStateTask.setPriorityData(_.get(responseTaskType, 'data.priority'));
    };

    getDefaultDataInfor();
  }, [stateTask.taskId]);

  useEffect(() => {
    const getContactAndLocation = async () => {
      const customerId = _.get(stateTask, 'viewInformationData.information.customer_id');
      if (customerId) {
        const responseContact = await getCustomerLocationAndConatact(customerId, 'workdesk');
        setStateTask.setContactData(_.get(responseContact, 'data.data.contactCustomer'));
        setStateTask.setToLocationData(_.get(responseContact, 'data.data.toLocations'));
      }
    };
    getContactAndLocation();
  }, [_.get(stateTask, 'viewInformationData.information.customer_id'), _.get(stateTask, 'triggerContactAndLocation')]);

  const NavigatorIcon = () => <img src={NavigatorSVG} style={{ marginBottom: '10px', marginLeft: '3.4px' }}></img>;
  const ArrowIcon = () => <img src={ArrowSVG} style={{ width: '32px' }}></img>;

  const LabeRequire = (props) => {
    const { text, req } = props;
    return (
      <span>
        {text}&nbsp;
        {req ? <span style={{ fontSize: '12px', color: '#ff0000' }}>*</span> : ''}
      </span>
    );
  };

  const NavigatorClick = async () => {
    const toLocation = getFieldValue('toLocation');
    if (stateTask.statusView) {
      if (toLocation) {
        if (toLocation) {
          const filterTo = _.get(stateTask, 'toLocationData').filter((item) => {
            return item.customerAddressBookId === toLocation;
          });
          window.open(
            `https://www.google.com/maps/search/?api=1&query=${parseFloat(_.get(filterTo, '[0].lat'))}, ${parseFloat(
              _.get(filterTo, '[0].lng')
            )}`,
            '_blank'
          );
        }
      }
    } else {
      window.open(
        `https://www.google.com/maps/search/?api=1&query=${parseFloat(_.get(stateTask, 'viewInformationData.address.lat'))}, ${parseFloat(
          _.get(stateTask, 'viewInformationData.address.lng')
        )}`,
        '_blank'
      );
    }
  };

  const CustomToField = (props) => {
    const { taskDetail, taskCustomField } = props;
    const customFieldData = _.get(taskCustomField, 'customFieldData');
    return _.size(customFieldData)
      ? customFieldData.map((o) => (
          <Col span={8} className="information-padding-label">
            <p className="font-p">
              <FormattedMessage key={o.titleCode} id={o.titleCode} defaultMessage={o.defaultTitle} />
            </p>
            <span className="task-detail-information-text-value">{_.get(taskDetail, o.entityToField) || '-'}</span>
          </Col>
        ))
      : null;
  };

  getFieldDecorator('keys', { initialValue: [] });
  const formItems =
    stateTask.customFieldData &&
    stateTask.customFieldData.map((k, index) => {
      const information = _.get(stateTask, 'viewInformationData.information');
      return (
        <div>
          {
            <Col span={8}>
              <Form.Item
                key={k.entityToField}
                className="task-detail-information-form-item"
                label={
                  <LabeRequire
                    text={intl.formatMessage({
                      id: `${k.titleCode}`,
                      defaultMessage: `${k.defaultTitle}`,
                    })}
                    req={false}
                  />
                }
              >
                {getFieldDecorator(`${k.entityToField}`, {
                  initialValue: _.get(information, k.entityToField) || '-',
                  rules: [
                    {
                      required: k.required && k.required !== null ? true : false,
                      whitespace: true,
                      message: 'Please input reference',
                    },
                  ],
                })(<Input placeholder="Enter Reference" autoComplete="off" />)}
              </Form.Item>
            </Col>
          }
        </div>
      );
    });

  useEffect(() => {
    if (_.get(stateTask, 'newContact') && _.get(stateTask, 'statusView')) {
      setTimeout(() => {
        setFieldsValue({
          ['contact']: _.get(stateTask, 'newContact'),
        });
      }, 1000);
    }
  }, [_.get(stateTask, 'newContact')]);

  useEffect(() => {
    const contactId = getFieldValue('contact');
    if (contactId && _.get(stateTask, 'statusView')) {
      const filterData =
        _.get(stateTask, 'contactData') !== undefined &&
        _.get(stateTask, 'contactData').filter((item) => {
          return item.customerContactId === contactId;
        });
      setFieldsValue({
        ['phone']: _.get(filterData, '[0].customerContactPhone'),
      });
    }
  }, [getFieldValue('contact')]);

  useEffect(() => {
    if (_.get(stateTask, 'newCustomerAddress') && _.get(stateTask, 'statusView')) {
      setTimeout(() => {
        setFieldsValue({
          ['toLocation']: _.get(stateTask, 'newCustomerAddress.customerAddressBookId'),
        });
      }, 1000);
    }
  }, [_.get(stateTask, 'newCustomerAddress.customerAddressBookId')]);

  const validatorStartDate = (rule, value, callback) => {
    const startDate = value;
    const dueDate = getFieldValue(`dueDate`);
    const formateStartDate = moment(startDate, 'YYYY-MM-DD HH:mm:ss').toString();
    const formateDueDate = moment(dueDate, 'YYYY-MM-DD HH:mm:ss').toString();

    if (startDate && dueDate) {
      if (startDate > dueDate) {
        callback(
          <FormattedMessage id="monitorTabDetailModalDateTimeValidatorDueDate" defaultMessage="Start date should not less than due date" />
        );
      } else {
        if (formateStartDate === formateDueDate) {
          callback(
            <FormattedMessage id="monitorTabDetailModalDateTimeValidatorStartTime2" defaultMessage="Cannot select the same date and time" />
          );
        } else {
          callback();
        }
      }
    } else {
      callback();
    }
  };

  const validatorDueDate = (rule, value, callback) => {
    const startDate = getFieldValue(`startDate`);
    const dueDate = value;
    const formateStartDate = moment(startDate, 'YYYY-MM-DD HH:mm:ss').toString();
    const formateDueDate = moment(dueDate, 'YYYY-MM-DD HH:mm:ss').toString();

    if (startDate && dueDate) {
      if (startDate > dueDate) {
        callback(
          <FormattedMessage id="monitorTabDetailModalDateTimeValidatorDueDate" defaultMessage="Start date should not less than due date" />
        );
      } else {
        if (formateStartDate === formateDueDate) {
          callback(
            <FormattedMessage id="monitorTabDetailModalDateTimeValidatorStartTime2" defaultMessage="Cannot select the same date and time" />
          );
        } else {
          callback();
        }
      }
    } else {
      callback();
    }
  };

  return (
    <div className="task-detail-information-div-padding" style={stateTask.statusView ? { paddingBottom: 'unset' } : null}>
      <Form>
        {stateTask.statusView ? (
          <Row>
            <Row gutter={[24, 24]}>
              <Col span={8}>
                <Form.Item
                  className="task-detail-information-form-item"
                  label={
                    <LabeRequire
                      text={intl.formatMessage({ id: `taskDetailInformationTextTaskNo`, defaultMessage: 'Task No' })}
                      req={false}
                    />
                  }
                >
                  {getFieldDecorator('taskNo', {
                    initialValue: _.get(stateTask, 'viewInformationData.information.taskNo'),
                    rules: [
                      {
                        required: false,
                        message: intl.formatMessage({ id: `taskDetailInformationTextTaskNo`, defaultMessage: 'Task No' }),
                      },
                    ],
                  })(
                    <Input
                      className="task-detail-information-input-style"
                      placeholder={intl.formatMessage({ id: `taskDetailInformationPlaceHolderTaskNo`, defaultMessage: 'Task No' })}
                      readOnly={true}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={16}>
                <Form.Item
                  className="task-detail-information-form-item"
                  label={
                    <LabeRequire
                      text={intl.formatMessage({ id: `taskDetailInformationTextTaskName`, defaultMessage: 'Task Name' })}
                      req={true}
                    />
                  }
                >
                  {getFieldDecorator('taskName', {
                    initialValue: _.get(stateTask, 'viewInformationData.information.taskName'),
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({
                          id: `taskDetailInformationValidateTaskName`,
                          defaultMessage: 'Please Input Task Name',
                        }),
                      },
                    ],
                  })(
                    <Input
                      className="task-detail-information-input-style"
                      placeholder={intl.formatMessage({
                        id: `taskDetailInformationPlaceHolderTaskName`,
                        defaultMessage: 'Enter Task Name',
                      })}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24]}>
              <Col span={8}>
                <Form.Item
                  className="task-detail-information-form-item"
                  label={
                    <LabeRequire
                      text={intl.formatMessage({ id: `taskDetailInformationTextTaskType`, defaultMessage: 'Task Type' })}
                      req={true}
                    />
                  }
                >
                  {getFieldDecorator('taskType', {
                    initialValue: _.get(stateTask, 'viewInformationData.information.taskTypeName'),
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({
                          id: `taskDetailInformationValidateTaskType`,
                          defaultMessage: 'Please Select Task Type',
                        }),
                      },
                    ],
                  })(
                    <Input
                      className="task-detail-information-input-style"
                      placeholder={intl.formatMessage({ id: `taskDetailInformationPlaceHolderTaskType`, defaultMessage: 'Task Type' })}
                      readOnly={true}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  className="task-detail-information-form-item"
                  label={
                    <LabeRequire
                      text={intl.formatMessage({ id: `taskDetailInformationTextPriority`, defaultMessage: 'Priority' })}
                      req={true}
                    />
                  }
                >
                  {getFieldDecorator('priority', {
                    initialValue: _.get(stateTask, 'viewInformationData.information.priorityId'),
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({
                          id: `taskDetailInformationValidatePriority`,
                          defaultMessage: 'Please Select Priority',
                        }),
                      },
                    ],
                  })(
                    <Select
                      showSearch
                      filterOption={(input, option) =>
                        option.props.children
                          .toString()
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      placeholder={intl.formatMessage({
                        id: `taskDetailInformationPlaceHolderPriority`,
                        defaultMessage: 'Select Priority',
                      })}
                    >
                      {stateTask.priorityData &&
                        stateTask.priorityData.map((item) => (
                          <Option key={item.priorityId}>
                            <img src={item.icon} style={{ width: '7px', height: '7px' }}></img> {item.name}
                          </Option>
                        ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  className="task-detail-information-form-item"
                  label={
                    <LabeRequire
                      text={intl.formatMessage({ id: `taskDetailInformationTextReference`, defaultMessage: 'Reference' })}
                      req={false}
                    />
                  }
                >
                  {getFieldDecorator('reference', {
                    initialValue: _.get(stateTask, 'viewInformationData.information.reference'),
                    rules: [
                      {
                        required: false,
                        message: intl.formatMessage({
                          id: `taskDetailInformationValidateReference`,
                          defaultMessage: 'Please Input Reference',
                        }),
                      },
                    ],
                  })(
                    <Input
                      className="task-detail-information-input-style"
                      placeholder={intl.formatMessage({ id: `taskDetailInformationPlaceHolderReference`, defaultMessage: 'Reference' })}
                      disabled={true}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24, 24]}>
              <Col span={8}>
                <Form.Item
                  className="task-detail-information-form-item"
                  label={
                    <LabeRequire
                      text={intl.formatMessage({ id: `taskDetailInformationTextCustomer`, defaultMessage: 'Customer' })}
                      req={true}
                    />
                  }
                >
                  {getFieldDecorator('customer', {
                    initialValue: _.get(stateTask, 'viewInformationData.information.customer'),
                    rules: [
                      {
                        required: false,
                        message: intl.formatMessage({
                          id: `taskDetailInformationValidateCustomer`,
                          defaultMessage: 'Please Select Customer',
                        }),
                      },
                    ],
                  })(
                    <Input
                      className="task-detail-information-input-style"
                      placeholder={intl.formatMessage({ id: `taskDetailInformationPlaceHolderCustomer`, defaultMessage: 'Customer' })}
                      readOnly={true}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  className="task-detail-information-form-item"
                  label={
                    <LabeRequire
                      text={intl.formatMessage({ id: `taskDetailInformationTextContact`, defaultMessage: 'Contact' })}
                      req={true}
                    />
                  }
                >
                  {getFieldDecorator('contact', {
                    initialValue: _.get(stateTask, 'viewInformationData.information.contact[0].contact_id'),
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({
                          id: `taskDetailInformationValidateContact`,
                          defaultMessage: 'Please Select Contact',
                        }),
                      },
                    ],
                  })(
                    <Select
                      placeholder={intl.formatMessage({ id: `taskDetailInformationPlaceHolderContact`, defaultMessage: 'Select Contact' })}
                      showSearch
                      filterOption={(input, option) =>
                        option.props.children
                          .toString()
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      dropdownRender={(menu) => (
                        <div>
                          {menu}
                          <Divider style={{ margin: '4px 0' }} />
                          <div
                            style={{ padding: '4px 8px', cursor: 'pointer' }}
                            onMouseDown={(e) => e.preventDefault()}
                            onClick={() => {
                              setStateTask.setVisibleAddContact(true);
                            }}
                          >
                            <Icon type="plus" className="customer-icon-plus" />
                            <span className="text-add-customer">
                              <FormattedMessage id="btnAdd" defaultMessage="Add" />{' '}
                              <FormattedMessage id="customerContactCardHeader" defaultMessage="Contact" />
                            </span>
                          </div>
                        </div>
                      )}
                    >
                      {stateTask.contactData &&
                        stateTask.contactData.map((item) => <Option key={item.customerContactId}>{item.customerContactName}</Option>)}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  className="task-detail-information-form-item"
                  label={
                    <LabeRequire text={intl.formatMessage({ id: `taskDetailInformationTextPhone`, defaultMessage: 'Phone' })} req={false} />
                  }
                >
                  {getFieldDecorator('phone', {
                    initialValue: _.get(stateTask, 'viewInformationData.information.contact[0].contact_phone'),
                    rules: [
                      {
                        required: false,
                        message: intl.formatMessage({ id: `taskDetailInformationValidatePhone`, defaultMessage: 'Please Input Phone' }),
                      },
                    ],
                  })(
                    <Input
                      readOnly={true}
                      className="task-detail-information-input-style"
                      placeholder={intl.formatMessage({ id: `taskDetailInformationPlaceHolderPhone`, defaultMessage: 'Phone' })}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24]}>
              <Col span={8}>
                <Form.Item
                  className="task-detail-information-form-item"
                  label={
                    <LabeRequire
                      text={intl.formatMessage({ id: `taskDetailInformationTextStartDate`, defaultMessage: 'Start Date' })}
                      req={false}
                    />
                  }
                >
                  {getFieldDecorator('startDate', {
                    initialValue: moment(
                      _.get(stateTask, 'viewInformationData.information.startDate').concat(
                        ' ',
                        _.get(stateTask, 'viewInformationData.information.startTime')
                      ),
                      format
                    ),
                    rules: [
                      {
                        required: getFieldValue('dueDate') ? true : false,
                        message: intl.formatMessage({
                          id: `taskDetailInformationValidateStartDate`,
                          defaultMessage: 'Please Select Start Date Time',
                        }),
                      },
                      {
                        validator: validatorStartDate,
                      },
                    ],
                  })(
                    <DatePicker
                      showTime={{ format: 'HH:mm' }}
                      format="YYYY-MM-DD HH:mm"
                      placeholder={intl.formatMessage({
                        id: `taskDetailInformationPlaceHolderStartDate`,
                        defaultMessage: 'Select Start Date Time',
                      })}
                      style={{ width: '100%' }}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  className="task-detail-information-form-item"
                  label={
                    <LabeRequire
                      text={intl.formatMessage({ id: `taskDetailInformationTextDueDate`, defaultMessage: 'Due Date' })}
                      req={false}
                    />
                  }
                >
                  {getFieldDecorator('dueDate', {
                    initialValue: moment(
                      _.get(stateTask, 'viewInformationData.information.dueDate').concat(
                        ' ',
                        _.get(stateTask, 'viewInformationData.information.dueTime')
                      ),
                      format
                    ),
                    rules: [
                      {
                        required: getFieldValue('startDate') ? true : false,
                        message: intl.formatMessage({
                          id: `taskDetailInformationValidateDueDate`,
                          defaultMessage: 'Please Select Due Date Time',
                        }),
                      },
                      {
                        validator: validatorDueDate,
                      },
                    ],
                  })(
                    <DatePicker
                      showTime={{ format: 'HH:mm' }}
                      format="YYYY-MM-DD HH:mm"
                      placeholder={intl.formatMessage({
                        id: `taskDetailInformationPlaceHolderDueDate`,
                        defaultMessage: 'Select Due Date Time',
                      })}
                      style={{ width: '100%' }}
                    />
                  )}
                </Form.Item>
              </Col>
              {_.size(stateTask.customFieldData) === 1 ? formItems : null}
            </Row>
            <Row gutter={[24, 24]}>{_.size(stateTask.customFieldData) > 1 ? formItems : null}</Row>
            <Row gutter={_.size(stateTask.customFieldData) > 0 ? [24] : [24, 24]}>
              <Col span={24}>
                <Form.Item
                  className="task-detail-information-form-item"
                  label={
                    <LabeRequire
                      text={intl.formatMessage({ id: `taskDetailInformationTextRemark`, defaultMessage: 'Remark' })}
                      req={false}
                    />
                  }
                >
                  {getFieldDecorator('remark', {
                    initialValue: _.get(stateTask, 'viewInformationData.information.remark'),
                  })(<Input className="task-detail-tab-information-input-style" />)}
                </Form.Item>
              </Col>
            </Row>
            <Divider type="horizontal" />
            <Row>
              <div style={{ paddingBottom: '5px' }}>
                <span className="task-detail-information-text-address">
                  <FormattedMessage id="taskDetailInformationTextAddress" defaultMessage="Address" />
                </span>
                <Icon component={NavigatorIcon} className="task-detail-information-text-icon" onClick={NavigatorClick} />
              </div>
              <Row>
                <Col span={10}>
                  <Form.Item
                    className="task-detail-information-form-item"
                    label={
                      <LabeRequire
                        text={intl.formatMessage({ id: `taskDetailInformationTextDestination`, defaultMessage: 'Destination' })}
                        req={true}
                      />
                    }
                  >
                    <Row>
                      <Col span={1}>
                        <Icon type="environment" style={{ fontSize: '12px', color: '#1D3557', marginRight: '5px' }} />
                      </Col>
                      <Col span={22}>
                        {getFieldDecorator('toLocation', {
                          initialValue: _.get(stateTask, 'viewInformationData.address.toLocationId'),
                          rules: [
                            {
                              required: true,
                              message: intl.formatMessage({
                                id: `taskDetailInformationValidateDestination`,
                                defaultMessage: 'Please Select Destination',
                              }),
                            },
                          ],
                        })(
                          <Select
                            placeholder={intl.formatMessage({
                              id: `taskDetailInformationPlaceHolderDestination`,
                              defaultMessage: 'Select Destination',
                            })}
                            className="create-task-tab-information-select-location"
                            optionLabelProp="label"
                            dropdownRender={(menu) => (
                              <div>
                                {menu}
                                <Divider style={{ margin: '4px 0' }} />
                                <div
                                  style={{ padding: '4px 8px', cursor: 'pointer' }}
                                  onMouseDown={(e) => e.preventDefault()}
                                  onClick={() => {
                                    setStateTask.setVisibleAddCustomerAddress(true);
                                  }}
                                >
                                  <div>
                                    <Icon type="plus" className="text-add-address" />
                                    <span className="text-add-address">
                                      <FormattedMessage id={`monitorCreateTaskTxtAddLocation`} defaultMessage="Add Location" />
                                    </span>
                                  </div>
                                </div>
                              </div>
                            )}
                          >
                            {stateTask.toLocationData &&
                              stateTask.toLocationData.map((item) => (
                                <Option
                                  className="create-task-tab-information-select-option"
                                  style={{ color: '#1D3557' }}
                                  key={item.customerAddressBookId}
                                  label={`${item.addressName} · ${item.address}`}
                                >
                                  <Row>
                                    <Col span={20} className="qutation-setting-text-select">
                                      <span>{item.addressName}</span>
                                    </Col>
                                    <Col span={4}>
                                      <span
                                        onClick={() => {
                                          setStateTask.setVisibleEditCustomerAddress(true);
                                          setStateTask.setEditTolocationData(item);
                                        }}
                                      >
                                        <FormattedMessage id="btnEdit" defaultMessage="Edit" />
                                      </span>
                                    </Col>
                                  </Row>
                                </Option>
                              ))}
                          </Select>
                        )}
                      </Col>
                    </Row>
                  </Form.Item>
                </Col>
              </Row>
            </Row>
          </Row>
        ) : (
          <Row>
            <Row gutter={[24, 24]}>
              <Col span={8}>
                <Form.Item
                  className="task-detail-information-form-item"
                  label={
                    <LabeRequire
                      text={intl.formatMessage({ id: `taskDetailInformationTextTaskNo`, defaultMessage: 'Task No' })}
                      req={false}
                    />
                  }
                >
                  <span className="task-detail-information-text-value">{_.get(stateTask, 'viewInformationData.information.taskNo')}</span>
                </Form.Item>
              </Col>
              <Col span={16}>
                <Form.Item
                  className="task-detail-information-form-item"
                  label={
                    <LabeRequire
                      text={intl.formatMessage({ id: `taskDetailInformationTextTaskName`, defaultMessage: 'Task Name' })}
                      req={false}
                    />
                  }
                >
                  <span className="task-detail-information-text-value">{_.get(stateTask, 'viewInformationData.information.taskName')}</span>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24]}>
              <Col span={8}>
                <Form.Item
                  className="task-detail-information-form-item"
                  label={
                    <LabeRequire
                      text={intl.formatMessage({ id: `taskDetailInformationTextTaskType`, defaultMessage: 'Task Type' })}
                      req={false}
                    />
                  }
                >
                  <span className="task-detail-information-text-value">
                    {_.get(stateTask, 'viewInformationData.information.taskTypeName')}
                  </span>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  className="task-detail-information-form-item"
                  label={
                    <LabeRequire
                      text={intl.formatMessage({ id: `taskDetailInformationTextPriority`, defaultMessage: 'Priority' })}
                      req={false}
                    />
                  }
                >
                  <img src={_.get(stateTask, 'viewInformationData.information.iconPriority')} style={{ width: '7px', height: '7px' }}></img>{' '}
                  <span className="task-detail-information-text-value">
                    <Icon />
                    {_.get(stateTask, 'viewInformationData.information.priorityName')}
                  </span>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  className="task-detail-information-form-item"
                  label={
                    <LabeRequire
                      text={intl.formatMessage({ id: `taskDetailInformationTextReference`, defaultMessage: 'Reference' })}
                      req={false}
                    />
                  }
                >
                  <span className="task-detail-information-text-value">
                    {_.get(stateTask, 'viewInformationData.information.reference') || '-'}
                  </span>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24, 24]}>
              <Col span={8}>
                <Form.Item
                  className="task-detail-information-form-item"
                  label={
                    <LabeRequire
                      text={intl.formatMessage({ id: `taskDetailInformationTextCustomer`, defaultMessage: 'Customer' })}
                      req={false}
                    />
                  }
                >
                  <span className="task-detail-information-text-value">{_.get(stateTask, 'viewInformationData.information.customer')}</span>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  className="task-detail-information-form-item"
                  label={
                    <LabeRequire
                      text={intl.formatMessage({ id: `taskDetailInformationTextContact`, defaultMessage: 'Contact' })}
                      req={false}
                    />
                  }
                >
                  <span className="task-detail-information-text-value">
                    {_.get(stateTask, 'viewInformationData.information.contact[0].contact_name')}
                  </span>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  className="task-detail-information-form-item"
                  label={
                    <LabeRequire text={intl.formatMessage({ id: `taskDetailInformationTextPhone`, defaultMessage: 'Phone' })} req={false} />
                  }
                >
                  <span className="task-detail-information-text-value">
                    {_.get(stateTask, 'viewInformationData.information.contact[0].contact_phone')}
                  </span>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24]}>
              <Col span={8}>
                <Form.Item
                  className="task-detail-information-form-item"
                  label={
                    <LabeRequire
                      text={intl.formatMessage({ id: `taskDetailInformationTextStartDate`, defaultMessage: 'Start Date' })}
                      req={false}
                    />
                  }
                >
                  {_.get(stateTask, 'viewInformationData.information.planStart') !== '' &&
                  _.get(stateTask, 'viewInformationData.information.durationStart') !== '' ? (
                    <span className="task-detail-information-text-value">{`${_.get(
                      stateTask,
                      'viewInformationData.information.planStart'
                    )}  ${_.get(stateTask, 'viewInformationData.information.durationStart')}`}</span>
                  ) : (
                    ' - '
                  )}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  className="task-detail-information-form-item"
                  label={
                    <LabeRequire
                      text={intl.formatMessage({ id: `taskDetailInformationTextDueDate`, defaultMessage: 'Due Date' })}
                      req={false}
                    />
                  }
                >
                  {_.get(stateTask, 'viewInformationData.information.planFinish') !== '' &&
                  _.get(stateTask, 'viewInformationData.information.durationFinish') !== '' ? (
                    <span className="task-detail-information-text-value">{`${_.get(
                      stateTask,
                      'viewInformationData.information.planFinish'
                    )}  ${_.get(stateTask, 'viewInformationData.information.durationFinish')}`}</span>
                  ) : (
                    ' - '
                  )}
                </Form.Item>
              </Col>
              {_.size(stateTask.customFieldData) === 1 ? (
                <CustomToField taskDetail={_.get(stateTask, 'viewInformationData.information')} taskCustomField={stateTask} />
              ) : null}
            </Row>
            <Row gutter={[24, 24]}>
              {_.size(stateTask.customFieldData) > 1 ? (
                <CustomToField taskDetail={_.get(stateTask, 'viewInformationData.information')} taskCustomField={stateTask} />
              ) : null}
            </Row>
            <Row gutter={_.size(stateTask.customFieldData) > 0 ? [24] : [24, 24]}>
              <Col span={24}>
                <Form.Item
                  className="task-detail-information-form-item"
                  label={
                    <LabeRequire
                      text={intl.formatMessage({ id: `taskDetailInformationTextRemark`, defaultMessage: 'Remark' })}
                      req={false}
                    />
                  }
                >
                  <span className="task-detail-information-text-value">
                    {_.get(stateTask, 'viewInformationData.information.remark') || ' - '}
                  </span>
                </Form.Item>
              </Col>
            </Row>
            <Divider type="horizontal" />
            <Row>
              <div style={{ paddingBottom: '5px' }}>
                <span className="task-detail-information-text-address">
                  <FormattedMessage id="taskDetailInformationTextAddress" defaultMessage="Address" />
                </span>
                <Icon component={NavigatorIcon} className="task-detail-information-text-icon" onClick={NavigatorClick} />
              </div>
              <Row>
                <Col span={10}>
                  <Form.Item
                    className="task-detail-information-form-item"
                    label={
                      <LabeRequire
                        text={intl.formatMessage({ id: `taskDetailInformationTextDestination`, defaultMessage: 'Destination' })}
                        req={false}
                      />
                    }
                  >
                    <Row>
                      <Col span={2}>
                        <Icon type="environment" style={{ fontSize: '12px', color: '#1D3557', marginRight: '5px' }} />
                      </Col>
                      <Col span={22}>
                        <span className="task-detail-information-text-address-value">{`${_.get(
                          stateTask,
                          'viewInformationData.address.toLocationName'
                        )} · ${_.get(stateTask, 'viewInformationData.address.toLocation')}`}</span>
                      </Col>
                    </Row>
                  </Form.Item>
                </Col>
              </Row>
            </Row>
          </Row>
        )}
      </Form>

      <AddContact
        visible={stateTask.visibleAddContact}
        setVisible={setStateTask.setVisibleAddContact}
        customerId={_.get(stateTask, 'viewInformationData.information.customer_id')}
        setTriggerAPI={setStateTask.setTriggerContactAndLocation}
        setContactID={setStateTask.setNewConatact}
      />

      <AddFromLocation
        visible={stateTask.visibleAddFromLocation}
        setVisible={setStateTask.setVisibleAddFromLocation}
        setTrigger={setStateTask.setTriggerContactAndLocation}
        toLocation={setStateTask.setNewCompanyAddress}
      />

      <EditFromLocation
        visible={stateTask.visibleEditComapanyAddress}
        setVisible={setStateTask.setVisibleEditComapanyAddress}
        valueDefault={stateTask.editFromLocationData}
        setTrigger={setStateTask.setTriggerContactAndLocation}
      />

      <AddAddressBookV2
        visible={stateTask.visibleAddCustomerAddress}
        setVisible={setStateTask.setVisibleAddCustomerAddress}
        customerId={_.get(stateTask, 'viewInformationData.information.customer_id')}
        customerName={_.get(stateTask, 'viewInformationData.information.customer')}
        toLocation={setStateTask.setNewCustomerAddress}
        setTrigger={setStateTask.setTriggerContactAndLocation}
      />

      <EditAddressBookV2
        visible={stateTask.visibleEditCustomerAddress}
        setVisible={setStateTask.setVisibleEditCustomerAddress}
        customerId={_.get(stateTask, 'viewInformationData.information.customer_id')}
        customerName={_.get(stateTask, 'viewInformationData.information.customer')}
        valueDefault={stateTask.editTolocationData}
        setTrigger={setStateTask.setTriggerContactAndLocation}
      />
    </div>
  );
};
