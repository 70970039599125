import React, { useContext, useEffect, useMemo } from 'react';
import { Card, Col, Row, Icon } from 'antd';
import Schedule from './schedule';
import Action from './action';
import FooterStatusGroup from '../components/footer-status-group';
import _ from 'lodash';
import NodataIMG from '../../../../components/image/Nodata_web.svg';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { MonitorContext } from '../../transportation-context';

export default (props) => {
  const { valueState, valueSetState, valueFnc } = props;
  const {
    scheduleObjNew,
    initialData,
    mainOrgId,
    searchValue,
    selectBy,
    orgData,
    startDate,
    resourceData,
    taskDataGanttView,
    orgGanttData,
    taskPoolData,
    resourceGanttData,
    inputValue,
    orgIdRaw,
    keyTabMonitor,
    LangCode,
    selectGroupBy,
    initialCustomerLoading,
    initialCustomerList,
    mainCustomerId,
    dataResource,
    customerIdRaw,
    checkTaskNo,
  } = valueState;

  const {
    handleChangeOption,
    handleSearch,
    handleSelect,
    handleOpenModal,
    handleSelectGroupBy,
    handleCustomerPopupScroll,
    handleCustomerChangeSearch,
    handleChangeTeam,
  } = valueFnc;

  const {
    setOrgData,
    setOrgGanttData,
    setResourceGanttData,
    setInputValue,
    setSearchValue,
    setNowDate,
    setVisibleTaskDetailModal,
    setMainTaskId,
    setStartDate,
    setOrgIdRaw,
    setTriggerDragDrop,
    setMainCustomerId,
    setTypeGantt,
    setStatusDragDrop,
    setCheckTaskNo,
    setDueDate,
  } = valueSetState;

  const { state, setState, fnc } = useContext(MonitorContext);

  const onNavigating = (args) => {

    setTimeout(() => {
      setNowDate(moment(args.currentDate).unix());
      setStartDate(moment(args.currentDate));
      setDueDate(moment(args.currentDate));
    }, 500);
  };

  const handleEventClick = (args) => {
    args.cancel = true;
    if (args.event.Id) {
      setMainTaskId(args.event.TaskId);
      // setStateMain.setSelectTaskData(args.event);
      setVisibleTaskDetailModal(true);
    }
  };

  const handleClickResource = (args) => {
    let getStartDate = '';
    let getEndDate = '';

    const expr = _.get(scheduleObjNew, 'current.activeViewOptions.option');
    switch (expr) {
      case 'TimelineDay':
        getStartDate = _.get(scheduleObjNew, 'current.activeView.renderDates')[0];
        getEndDate = _.get(scheduleObjNew, 'current.activeView.renderDates')[0];
        break;
      case 'TimelineWeek':
        let maxLength = _.get(scheduleObjNew, 'current.activeView.renderDates').length - 1;
        getStartDate = _.get(scheduleObjNew, 'current.activeView.renderDates')[0];
        getEndDate = _.get(scheduleObjNew, 'current.activeView.renderDates')[maxLength];
        break;
      case 'TimelineMonth':
        getStartDate = _.get(scheduleObjNew, 'current.activeView.monthDates.start');
        getEndDate = _.get(scheduleObjNew, 'current.activeView.monthDates.end');
        break;
      default:
        getStartDate = '';
        getEndDate = '';
    }

    const getElementClassName = args.target.querySelector('.fullname');
    const getClassName = args.target.getAttribute('class');
    let getMemComId = args.target.getAttribute('memComId');
    let getOrgId = args.target.getAttribute('orgId');

    if (!getMemComId || !getOrgId) return;


    const dataAPI = {
      orgId: getOrgId,
      memComId: getMemComId,
      startDate: moment(getStartDate).format('YYYY-MM-DD'),
      endDate: moment(getEndDate).format('YYYY-MM-DD'),
      defailtStartDate: getStartDate,
      defailtEndDate: getEndDate,
    };

    if (
      getElementClassName ||
      _.includes(getClassName, 'fullname') ||
      _.includes(getClassName, 'fullname-img') ||
      _.includes(getClassName, 'fullname-p') ||
      _.includes(getClassName, 'fullname-c')
    ) {
      handleOpenModal(dataAPI);
    }
  };

  // useEffect(() => {
  //   onCreatedNowClock()
  // }, [_.size(resourceData)]);

  // console.log('scheduleObjNew.current iii', scheduleObjNew.current)

  const onCreatedNowClock = () => {
    const current = new Date();
    const time = `${current.getHours() - 1}:${current.getMinutes()}`;
    // console.log('onCreatedNowClock scrollTo::: ', time);
    // if (!scheduleObjNew.current) return
    // if (scheduleObjNew.current && scheduleObjNew.current.isRendered === true && keyTabMonitor === '1' && _.size(resourceData) !== 0) {
    //   setTimeout(() => {
    //     scheduleObjNew.current && scheduleObjNew.current.scrollTo(time);
    //   }, 2000);
    // }
  };

  return (
    <div>
      <div style={{ padding: '18px 18px 0px 18px' }}>
        <Action
          handleChangeOption={handleChangeOption}
          orgData={orgData}
          mainOrgId={mainOrgId}
          handleSearch={handleSearch}
          handleSelect={handleSelect}
          searchValue={searchValue}
          selectBy={selectBy}
          setOrgData={setOrgData}
          setInputValue={setInputValue}
          inputValue={inputValue}
          setSearchValue={setSearchValue}
          setOrgIdRaw={setOrgIdRaw}
          orgIdRaw={orgIdRaw}
          handleSelectGroupBy={handleSelectGroupBy}
          selectGroupBy={selectGroupBy}
          initialCustomerLoading={initialCustomerLoading}
          handleCustomerPopupScroll={handleCustomerPopupScroll}
          handleCustomerChangeSearch={handleCustomerChangeSearch}
          initialCustomerList={initialCustomerList}
          mainCustomerId={mainCustomerId}
          setMainCustomerId={setMainCustomerId}
          setTypeGantt={setTypeGantt}
          handleChangeTeam={handleChangeTeam}
          customerIdRaw={customerIdRaw}
        />
      </div>
      {(selectGroupBy === 'team' && _.size(mainOrgId) === 0) || (selectGroupBy === 'customer' && _.size(mainCustomerId) === 0) ? (
        <div style={{ padding: '0px 18px' }} className="no-data-blank-svg">
          <div className="no-data-blank-div">
            <img src={NodataIMG} className="no-data-blank-svg-img " />
            <p className="no-data-blank-text-1 ">
              <FormattedMessage id={`monitorDescriptionGantt1${LangCode}`} defaultMessage="Please select a Team " />
            </p>
            <p className="no-data-blank-text-2">
              <FormattedMessage id={`monitorDescriptionGantt2${LangCode}`} defaultMessage="Gantt View will appear here. " />
            </p>
          </div>
        </div>
      ) : (
        <div style={{ padding: '0px 18px' }}>
          <Schedule
            scheduleObjNew={scheduleObjNew}
            orgGanttData={orgGanttData}
            taskDataGanttView={taskDataGanttView}
            startDate={startDate}
            onNavigating={onNavigating}
            handleEventClick={handleEventClick}
            handleClickResource={handleClickResource}
            onCreatedNowClock={onCreatedNowClock}
            setTriggerDragDrop={setTriggerDragDrop}
            LangCode={LangCode}
            selectGroupBy={selectGroupBy}
            resourceData={resourceData}
            dataResource={dataResource}
            setStatusDragDrop={setStatusDragDrop}
            checkTaskNo={checkTaskNo}
            setMainVisibleCreateTask={setState.setMainVisibleCreateTask}
            setInfoSchedule={setState.setInfoSchedule}
          />
          <div style={{ marginTop: '10px' }}>
            <FooterStatusGroup
              initialData={initialData}
              checkTaskNo={checkTaskNo}
              setCheckTaskNo={setCheckTaskNo}
              keyTabMonitor={keyTabMonitor}
            />
          </div>
        </div>
      )}
    </div>
  );
};
