import React, { useState, useContext } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import _, { debounce } from 'lodash';
import { Select, Row, Col, Input } from 'antd';
import { QuotationContext } from './quotation-context';
import CalculateV1 from '../calculate/calculate-v1';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import CurrencyInput from 'react-currency-input-field';
import stylesModal from './css/index.css';

const { Option } = Select;
const { TextArea } = Input;

const QuotationCalculate = (props) => {
  const intl = useIntl();
  const { remark, setRemark } = props;
  const { discountSelete, taxSelete, discountData, valueInputDiscount, taxData, subTotalResult, valueSubTotalResult, setStateMain, fnc } = useContext(QuotationContext);

  const handleChangeTextArea = (e) => {
    setRemark(e.target.value);
  }

  return (
    <div className='card-modal-calculate'>
      <Row className="card-modal-calculate-row">
        <Col className="card-modal-calculate-col-left" span={12}>
          <div className="card-modal-calculate-col-left-div">
            <span className="card-modal-calculate-text">
              <FormattedMessage id="quotationModalLblRemark" defaultMessage="Remark" />
            </span>
          </div>
          <TextArea
            className="card-modal-calculate-textArea"
            placeholder={intl.formatMessage({ id: 'quotationModalLblRemark  ', defaultMessage: 'Please enter Remark' })}
            rows="5"
            autoSize={{ minRows: 5, maxRows: 5 }}
            onChange={handleChangeTextArea}
            value={remark}
          />
        </Col>
        <Col className="card-modal-calculate-col-right" span={12}>
          <CalculateV1
            discountSelete={discountSelete}
            taxSelete={taxSelete}
            taxData={taxData}
            discountData={discountData}
            valueInputDiscount={valueInputDiscount}
            subTotalResult={subTotalResult}
            valueSubTotalResult={valueSubTotalResult}
            setValues={{
              sumCalculate: setStateMain.setSumCalculate
            }}
            fnc={{
              onChangeDiscount: fnc.onChangeDiscount,
              onChangeTax: fnc.onChangeTax,
              onChangeInput: fnc.onChangeInput,
            }}
          />
        </Col>
      </Row>
    </div>
  )
}

export default QuotationCalculate;
