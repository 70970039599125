import React, { useEffect } from 'react'
import { Row, Col, Select, Input } from 'antd'
import _ from 'lodash'
import SelectTax from './tax-select'
import DiscountSelect from './discount-select'
import NumberFormat from 'react-number-format';
import { useIntl, FormattedMessage } from 'react-intl';


const { Option } = Select;

export default ({
  discount,
  tax,
  refTax,
  data,
  textBath,
  dataSelection,
  subTotal,
  setSubTotal,
  totalPrice,
  taxPrice,
  discountPrice,
  subTotalLessPrice,
  setTotalPrice,
  setDiscountPrice,
  setTaxPrice,
  setSubTotalLessPrice,
  setDiscountID,
  setTextBath,
  setTaxID,
  discountID,
  taxID,
  triggerID,
  saveFormRefDiscount,
  PersentTax,
  setPersentTax,
  PersentDiscount,
  setPersentDiscount,
}) => {

  const intl = useIntl()
  const totalDataSelectTion = dataSelection.length && dataSelection.map(item => { return item.total })
  const sumTotal = totalDataSelectTion && totalDataSelectTion.reduce((a, b) => a + b, 0)
  useEffect(() => {
    const taxIDFilter = data && data.taxId
    const discountIDFilter = data && data.discountId
    setTaxID(taxIDFilter)
    setDiscountID(discountIDFilter)
  }, [triggerID])


  useEffect(() => {
    if (discountID && taxID) {
      const filterDiscountPirce = discount && discount.filter((col) => discountID.includes(col.discountId));
      const discountPirce = filterDiscountPirce && filterDiscountPirce !== undefined ? filterDiscountPirce[0].percentage : undefined
      const filterTaxPirce = tax && tax.filter((col) => taxID.includes(col.taxId));
      const taxPirce = filterTaxPirce && filterTaxPirce !== undefined ? filterTaxPirce[0].percentage : undefined
      const Bath = filterDiscountPirce && filterDiscountPirce !== undefined ? filterDiscountPirce[0].isBath : undefined
      setPersentTax(taxPirce)
      setPersentDiscount(discountPirce)
      setTextBath(Bath)

      if (sumTotal == 0) {
        if (textBath == true) {
          setSubTotal(sumTotal)
          setSubTotalLessPrice(sumTotal)
          setTaxPrice(sumTotal)
          setTotalPrice(sumTotal)
        } else {
          setSubTotal(sumTotal)
          setDiscountPrice(sumTotal)
          setSubTotalLessPrice(sumTotal)
          setTaxPrice(sumTotal)
          setTotalPrice(sumTotal)
        }

      } else {
        if (textBath == true) {
          setSubTotal(sumTotal)
          setSubTotalLessPrice(sumTotal - discountPrice)
          setTaxPrice(_.ceil(((sumTotal - discountPrice) * PersentTax) / 100, 2))
          setTotalPrice((sumTotal - discountPrice) + _.ceil(((sumTotal - discountPrice) * PersentTax) / 100, 2))
        } else {
          if (Bath !== true) {
            setSubTotal(sumTotal)
            setDiscountPrice(_.ceil(((sumTotal * discountPirce) / 100), 2))
            setSubTotalLessPrice(sumTotal - _.ceil(((sumTotal * discountPirce) / 100), 2))
            setTaxPrice(_.ceil((((sumTotal - _.ceil(((sumTotal * discountPirce) / 100), 2)) * taxPirce) / 100), 2))
            setTotalPrice((sumTotal - _.ceil(((sumTotal * discountPirce) / 100), 2)) + _.ceil((((sumTotal - _.ceil(((sumTotal * discountPirce) / 100), 2)) * taxPirce) / 100), 2))
          }
          else {
            setSubTotal(sumTotal)
            setDiscountPrice(_.get(data, "discount"))
            setSubTotalLessPrice(sumTotal - _.get(data, "discount"))
            setTaxPrice(_.ceil((((sumTotal - _.get(data, "discount")) * taxPirce) / 100), 2))
            setTotalPrice((sumTotal - _.get(data, "discount")) + _.ceil((((sumTotal - _.get(data, "discount")) * taxPirce) / 100), 2))
          }
        }

      }

    }

  }, [sumTotal])




  const handleSelectTax = (value) => {
    if (value && value !== undefined) {
      const filterTaxPirce = tax && tax.filter((col) => value.includes(col.taxId));
      const taxPirce = filterTaxPirce && filterTaxPirce !== undefined ? filterTaxPirce[0].percentage : undefined
      setTaxID(value)
      setPersentTax(taxPirce)
      setTaxPrice(_.ceil(((subTotalLessPrice * taxPirce) / 100), 2));
      setTotalPrice(subTotalLessPrice + _.ceil(((subTotalLessPrice * taxPirce) / 100), 2))

    }



  }

  const handleSelectDiscount = (value) => {
    if (value && value !== undefined) {
      const filterDiscountPirce = discount && discount.filter((col) => value.includes(col.discountId));
      const discountPirce = filterDiscountPirce && filterDiscountPirce !== undefined ? filterDiscountPirce[0].percentage : undefined
      const Bath = filterDiscountPirce && filterDiscountPirce !== undefined ? filterDiscountPirce[0].isBath : undefined
      setPersentDiscount(discountPirce);
      if (Bath == true) {
        setDiscountPrice(0);
        setSubTotalLessPrice(sumTotal)
        if (PersentTax == undefined) {
          setTotalPrice(sumTotal)
        }
        else {
          setTaxPrice(_.ceil(((sumTotal * PersentTax) / 100), 2));
          setTotalPrice(sumTotal + _.ceil(((sumTotal * PersentTax) / 100), 2))
        }
      }
      else {
        setDiscountPrice(_.ceil(((sumTotal * discountPirce) / 100), 2));
        setSubTotalLessPrice((sumTotal - _.ceil(((sumTotal * discountPirce) / 100), 2)))
        if (PersentTax == undefined) {
          setTotalPrice((sumTotal - _.ceil(((sumTotal * discountPirce) / 100), 2)))
        }
        else {
          setTaxPrice(_.ceil(((sumTotal - _.ceil(((sumTotal * discountPirce) / 100), 2)) * PersentTax / 100), 2));
          setTotalPrice((sumTotal - _.ceil(((sumTotal * discountPirce) / 100), 2)) + _.ceil(((sumTotal - _.ceil(((sumTotal * discountPirce) / 100), 2)) * PersentTax / 100), 2))

        }
      }


      setDiscountID(value)
      setTextBath(Bath)

    }

  }

  const handleKeyDownNumber = (e) => {

    let keyCode = e.key.charCodeAt(0);
    if ((keyCode >= 48 && keyCode <= 57) || keyCode === 65 || keyCode === 66 || keyCode === 46) {
      if (keyCode === 46 && e.target.value.indexOf('.') > 0) {
        e.preventDefault();
      }
      else {
        return true;
      }
    }
    else {
      e.preventDefault();
    }
  }

  const handleOnChangeInput = (e) => {
    if (e.target.value) {
      setDiscountPrice(parseFloat(e.target.value));
      if (PersentTax == undefined) {
        setSubTotalLessPrice(sumTotal - e.target.value)
        setTotalPrice(sumTotal - e.target.value)

      } else {
        setSubTotalLessPrice(sumTotal - e.target.value)
        setTaxPrice(_.ceil((((sumTotal - e.target.value) * PersentTax) / 100), 2))
        setTotalPrice((sumTotal - e.target.value) + _.ceil((((sumTotal - e.target.value) * PersentTax) / 100), 2))
      }
    }
    else {
      setDiscountPrice(0);
      if (PersentTax == undefined) {
        setSubTotalLessPrice(sumTotal)
        setTotalPrice(sumTotal)
      }
      else {
        setSubTotalLessPrice(sumTotal)
        setTaxPrice(_.ceil(((sumTotal * PersentTax) / 100), 2))
        setTotalPrice(sumTotal + _.ceil(((sumTotal * PersentTax) / 100), 2))
      }

    }
  }



  return (
    <Row>
      <Col span={12} className='align-text-right'>
        <Row className='margin-row'>
          <span><FormattedMessage id="modalInvoiceSummarySubtotalText" defaultMessage="Subtotal" /></span>
        </Row>

        <Row className='margin-row'>
          <div style={{ width: '60px', display: 'flex', paddingLeft: '100px' }}>
            <span style={{ marginRight: '8px', marginTop: "7px" }}><FormattedMessage id="modalInvoiceSummaryDiscountText" defaultMessage="Discount" /></span>
            <DiscountSelect discount={discount} handleSelectDiscount={handleSelectDiscount} data={data} ref={saveFormRefDiscount} />
          </div>
        </Row>

        <Row className='margin-row'>
          <span><FormattedMessage id="modalInvoiceSummarySubtotalLessText" defaultMessage="Subtotal Less Discount" /></span>
        </Row>

        <Row className='margin-row'>
          <div style={{ width: '60px', display: 'flex', paddingLeft: '134px' }}>
            <span style={{ marginRight: '8px', marginTop: "7px" }}><FormattedMessage id="modalInvoiceSummaryTaxText" defaultMessage="Tax" /></span>
            <SelectTax tax={tax} handleSelectTax={handleSelectTax} ref={refTax} data={data} />
          </div>
        </Row>

        <Row className='margin-row'>
          <span><FormattedMessage id="modalInvoiceSummaryTotalText" defaultMessage="Total" /></span>

        </Row>

      </Col>

      <Col span={9} className='align-text-right'>
        <Row className='margin-row-summary'>
          <NumberFormat value={_.floor(subTotal, 2).toFixed(2)} displayType={'text'} thousandSeparator={true}
            suffix={' ' + intl.formatMessage({ id: 'invoicetextBaht', defaultMessage: 'Baht' })} />
        </Row>
        {textBath && textBath == true ? (
          <Row className='margin-row-summary'>
            <Input className='input-bath' placeholder='0.00' type="text" onKeyDown={handleKeyDownNumber} onChange={(e) => handleOnChangeInput(e)}
              defaultValue={(discountPrice)} autoComplete="off" 
            ></Input>
            <span> <FormattedMessage id="invoicetextBaht" defaultMessage="Baht" /></span>
          </Row>
        ) : (
          <Row className='margin-row-summary'>
            <NumberFormat value={_.floor(discountPrice, 2).toFixed(2)} displayType={'text'} thousandSeparator={true}
              suffix={' ' + intl.formatMessage({ id: 'invoicetextBaht', defaultMessage: 'Baht' })} />
          </Row>
        )
        }

        <Row className='margin-row-summary'>
          <NumberFormat value={_.floor(subTotalLessPrice, 2).toFixed(2)} displayType={'text'} thousandSeparator={true}
            suffix={' ' + intl.formatMessage({ id: 'invoicetextBaht', defaultMessage: 'Baht' })} />
        </Row>



        <Row className='margin-row-summary'>
          <NumberFormat value={_.floor(taxPrice, 2).toFixed(2)} displayType={'text'} thousandSeparator={true}
            suffix={' ' + intl.formatMessage({ id: 'invoicetextBaht', defaultMessage: 'Baht' })} />
        </Row>

        <Row className='margin-row-summary'>
          <NumberFormat value={_.floor(totalPrice, 2).toFixed(2)} displayType={'text'} thousandSeparator={true}
            suffix={' ' + intl.formatMessage({ id: 'invoicetextBaht', defaultMessage: 'Baht' })} />
        </Row>



      </Col>
    </Row>
  )
}
