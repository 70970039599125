import React, { useState, useRef, useContext } from "react"
import { Menu, Button, Dropdown, Card, Table, Popover } from "antd";
import { useIntl, FormattedMessage } from "react-intl";
import Button01 from "../../../components/v2/button_01";
import { DownOutlined } from "@ant-design/icons";
import PettyCashAction from "./action";
import { PettyCashProvider } from "./context";
import _ from 'lodash'
import { PageSettings } from "../../../config/page-settings";

const PettyCashContent = () => {
  const intl = useIntl()
  const { state, fnc } = useContext(PettyCashProvider)
  const { newDataColumns, dataSource, total, tableChange } = state
  const { handleTableChange, linkToView, handleOpenModal, handleExport } = fnc
  const app = useContext(PageSettings);
  const { checkPermissionAction } = app

  const menuColumn = (
    <Menu>
      {
        checkPermissionAction('P83PG1C1', 'P83PG1C1A2') ? (
          <Menu.Item onClick={() => handleOpenModal('create', {})}>
            <Button style={{ padding: 'unset' }} ghost type="link">
              <FormattedMessage id="btnCreatePettyCash" defaultMessage="Create Petty Cash" />
            </Button>
          </Menu.Item>
        ) : null
      }
      {
        checkPermissionAction('P83PG1C1', 'P83PG1C1A5') ? (
          <Menu.Item onClick={() => handleExport()}>
            <Button style={{ padding: 'unset' }} ghost type="link">
              <FormattedMessage id="btnExport" defaultMessage="Export Excel" />
            </Button>
          </Menu.Item>
        ) : null
      }
    </Menu >
  );
  const ref = useRef();
  const [visiblePopoverTable, setVisiblePopoverTable] = useState();
  const [recordPopover, setRecordPopover] = useState();
  const [offsetPopover, setOffsetPopover] = useState([0, 0]);

  const showTotal = () => {
    return (
      <span style={{ fontSize: '13px' }}>
        <FormattedMessage id="lblRowPerPage" defaultMessage="Rows per page: " />
      </span>
    );
  };

  const fncActionTable = (record, offsetPopover, visiblePopoverTable, ref) => {
    return (
      <div>
        <Popover
          placement="right"
          trigger="hover"
          align={{ offset: offsetPopover }}
          visible={visiblePopoverTable}
          zIndex={10000}
          content={
            <div style={{ display: 'grid' }}>
              {
                checkPermissionAction('P83PG1C1', 'P83PG1C1A1') || checkPermissionAction('P83PG1C2', 'P83PG1C2A1') ? (
                  <div>
                    <Button style={{ width: 100 }} type="link" ghost onClick={() => linkToView(record)}>
                      <FormattedMessage id="btnView" defaultMessage="View" />
                    </Button>
                  </div>
                ) : null
              }
              {
                checkPermissionAction('P83PG1C1', 'P83PG1C1A3') && _.get(record, 'statusCode') === 'open' ? (
                  <div>
                    <Button style={{ width: 100 }} type="link" ghost onClick={() => handleOpenModal('edit', record)}>
                      <FormattedMessage id="btnEdit" defaultMessage="Edit" />
                    </Button>
                  </div>
                ) : null
              }
              {
                checkPermissionAction('P83PG1C2', 'P83PG1C2A2') && _.get(record, 'statusCode') === 'open' ? (
                  <div>
                    <Button style={{ width: 100 }} type="link" ghost onClick={() => handleOpenModal('tranfer', record)}>
                      <FormattedMessage id="btnTranfer" defaultMessage="Tranfer" />
                    </Button>
                  </div>
                ) : null
              }
            </div>
          }
        >
          <div style={{ width: 0, height: 0 }} ref={ref}></div>
        </Popover>
      </div>
    );
  };

  const handleMouseLeave = () => {
    setVisiblePopoverTable(false);
    setRecordPopover();
    setOffsetPopover([0, 0]);
  };

  return (
    <Card
      title={intl.formatMessage({ id: 'pettyCashCardTitle', defaultMessage: 'Petty Cash' })}
      bodyStyle={{ padding: 'unset', height: 'calc(100vh - 165px)', overflowY: 'auto' }}
      extra={<Dropdown overlay={menuColumn} trigger={['click']} placement="bottomRight">
        <Button01 key="submit" type="primary" btnsize="wd_df" style={{ margin: 'unset' }}>
          <FormattedMessage id="btnActionGroup" defaultMessage="Action" />
          <DownOutlined style={{ fontSize: '14px', paddingLeft: '22%' }} />
        </Button01>
      </Dropdown>}
    >
      <div style={{ paddingTop: '8px' }}>
        <PettyCashAction />
      </div>
      <div style={{ padding: '0 24px 24px 24px' }} onMouseLeave={handleMouseLeave}>
        <Table
          columns={newDataColumns}
          dataSource={dataSource}
          scroll={{ x: _.sumBy(newDataColumns, 'width') }}
          onChange={handleTableChange}
          pagination={{
            total: total,
            current: tableChange.page,
            showTotal: showTotal,
            defaultCurrent: 1,
            pageSizeOptions: ['10', '20', '30', '40', '50'],
            showSizeChanger: true,
            locale: { items_per_page: '' },
          }}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                if (!event.target.href) {
                  const { x, y } = ref.current.getBoundingClientRect();
                  setVisiblePopoverTable(true);
                  setOffsetPopover([event.pageX - x, event.pageY - y]);
                  setRecordPopover(record);
                }
              },
            };
          }}
        />
        {fncActionTable(recordPopover, offsetPopover, visiblePopoverTable, ref)}
        {total > 0 ? (
          <div className="total-items-member">
            <span style={{ fontSize: '13px' }}>
              <FormattedMessage id="lblTotal" defaultMessage="Total" />
              {` ${total || 0} `}
              <FormattedMessage id="lblitems" defaultMessage="items" />
            </span>
          </div>
        ) : null}
      </div>
    </Card>
  )
}

export default PettyCashContent