import httpClient from '../../components/axiosClient';

const getTeamPosition = async (userId) => {

  const comCode = localStorage.getItem('comCode');

  if (userId) {
    try {
      const response = await httpClient.get(`/v4/organizations/company/${comCode}/member/${userId}`);
      if (response.status === 200) {
        return response.data;
      } else {
        return {};
      }
    } catch (error) {
      return error.response;
    }
  }
}

export default getTeamPosition;