import httpClient from '../../components/axiosClient';

const AddSignature = async (value) => {

  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v4/resource/manager/company/${comCode}/uploads/type/member-signature-image`, value)
    if (response.status === 200) {

      return response.data
    } else {
      return []
    }
  } catch (error) {
  }
}

const DeleteSignature = async (id, memcom) => {

  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.delete(`/v4/resource/manager/company/${comCode}/uploads/type/member-signature-image/file/${id}/member/${memcom}`)
    if (response.status === 200) {

      return response.data
    } else {
      return []
    }
  } catch (error) {
  }
}

const GetSignature = async (memcom) => {

  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.get(`/v4/resource/manager/company/${comCode}/uploads/type/member-signature-image/member/${memcom}`)
    if (response.status === 200) {

      return response.data
    } else {
      return []
    }
  } catch (error) {
  }
}


export { AddSignature, DeleteSignature, GetSignature };