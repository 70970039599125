import { Card, Col, Row, Tabs } from 'antd';
import React, { useContext, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { AllticketContext } from '../allticket-context';
import Filter from './filter';
import FillterForm from './filterform';
import Button_01 from '../../../../../components/v2/button_01';


const LeftCardAllTicket = () => {
  const intl = useIntl();
  const { state, setState, fnc } = useContext(AllticketContext);
  const {  } = state;
  const {

    setEvent,
  } = setState;

  return (
    <Card className="left-card-all-ticket">
      <div>
        <div className="filter-text-summary-text" style={{borderBottom: '1px solid #e4e6eb'}}>
          <span className="filter-text-summary-text-size">{intl.formatMessage({ id: `TicketFilter`, defaultMessage: 'Filter' })}</span>
        </div>
        <div className="filterBackgrounds" style={{ overflowY: 'auto', height: 'calc(100vh - 251px)' }}>
        <FillterForm />
        </div>
        <div>
          <Row>
            <Col span={24} style={{ padding: '0px 16px 16px 16px' }}>
              <Button_01
                key="submit"
                type="primary"
                style={{ width: '100%', margin: 'unset' }}
                onClick={() => setEvent((current) => !current)}
              >
                <FormattedMessage id="btnFilterApply" defaultMessage="Apply" />
              </Button_01>
            </Col>
          </Row>
        </div>
      </div>
    </Card>
  );
};

export default LeftCardAllTicket;
