import React, { useState, useEffect, useRef } from 'react';
import { Avatar, Icon } from 'antd';
import styles from './css/index.css';
import DefaultProfile from '../../components/image/NoImageR.png';
import Resizer from 'react-image-file-resizer';


const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      720,
      720,
      'JPEG',
      70,
      0,
      (uri) => {
        resolve(uri);
      },
      'base64',
    );
  }
);

const dataURLtoFile = (dataurl, filename) => {
  const arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  for (var i = 0; i < bstr.length; i++) {
    u8arr[i] = bstr.charCodeAt(i);
  }
  return new File([u8arr], filename, { type: mime });
};

const UploadImageProfile = ({ defalseFileData, imageProfile, setImageProfile }) => {

  const imageRef = useRef();

  const [imageFile, setImageFile] = useState();
  const [imageFileDefault, setImageFileDefault] = useState('');

 

  useEffect(() => {
    if (defalseFileData !== null) {
      setImageFileDefault(defalseFileData);
      // setImageProfile(defalseFileData)
    }
  }, [defalseFileData]);

  const handleChangePicture = async (event) => {
    event.preventDefault();

    let reader = new FileReader();
    const file = event.target.files[0];
    if (file) {
      if (file.name.match(/\.(jpg|jpeg|png|JPG|PNG|JPEG)$/)) {
        const image = await resizeFile(file);
        reader.onloadend = () => {
          const image = reader.result;
          setImageFile(image);
        };
        reader.readAsDataURL(file);
        const fileChange = dataURLtoFile(image, file.name);
        setImageProfile(fileChange);
      }
    }
  }

  const showOpenFileDialog = (e) => {
    imageRef.current.click();
  };

  const deleteImage = (e) => {
    setImageFile();
    setImageProfile('noimage');
    imageRef.current.value = null;
  };

  const deleteImageDef = (e) => {
    setImageFile('');
    setImageProfile('noimage');
    setImageFileDefault({
      profileImage: DefaultProfile
    });
    imageRef.current.value = null;
  };


  return (
    <div className="upload-image-profile">
      {/* <div className="col-md-8 upload-image-profile"> */}
      <div className="upload-image-profile">
        <Avatar
          size={152}
          src={`${imageFile ? imageFile : imageFileDefault.profileImage}`}
        />

        <input
          ref={imageRef}
          type="file"
          style={{ display: 'none' }}
          accept=".jpg, .png, .JPG, .PNG"
          onChange={handleChangePicture}
        />

        {imageFile ? (
          <div className='upload-image-profile after' style={{ paddingTop: 63, paddingLeft: 42, paddingRight: 42 }}>
            <Icon type="upload" theme='outlined' style={{ fontSize: '25px' }} onClick={showOpenFileDialog} />
            &nbsp;
            &nbsp;
            <Icon type="delete" theme='outlined' style={{ fontSize: '25px' }} onClick={deleteImage} />
          </div> ) : imageFileDefault.profileImage ? (
          <div className='upload-image-profile after' style={{ paddingTop: 63, paddingLeft: 42, paddingRight: 42 }}>
            <Icon type="upload" theme='outlined' style={{ fontSize: '25px' }} onClick={showOpenFileDialog} />
            &nbsp;
            &nbsp;
            <Icon type="delete" theme='outlined' style={{ fontSize: '25px' }} onClick={deleteImageDef} />
          </div> ) : (
            <div className='upload-image-profile after' style={{ paddingTop: 63, paddingLeft: 62, paddingRight: 62 }}>
              <Icon type="upload" theme='outlined' style={{ fontSize: '25px' }} onClick={showOpenFileDialog} />
            </div>
          )
        }
      </div>
    </div>
  )
}

export default UploadImageProfile;
