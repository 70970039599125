import React from 'react'
import CollapseCustom from '../../../components/collapse-custom'
import { useIntl, FormattedMessage } from 'react-intl'
import ReportInstockActionColumns from './action-columns'
import { Input, Form, Select, DatePicker, Row, Col, Dropdown, Menu, Button } from 'antd'
import LabeRequireForm from '../../../components/label-required-form'
import _ from 'lodash'
import Button01 from '../../../components/v2/button_01'
import { DownOutlined } from '@ant-design/icons'

const { RangePicker } = DatePicker
const { Option } = Select

const ReportInstockAction = (props) => {
  const intl = useIntl()
  const { search, onFilterOrder, warehouseData, dataDefault, actionColumns, brand, model, handleExport } = props

  const formFilter = () => {
    return (
      <Form colon={false} className='deliveryForm'>
        <Row gutter={[24, 2]}>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'reportInstockColumnsWarehouse', defaultMessage: 'Warehouse' })}
                  req={false}
                />
              }
            >
              <Select
                allowClear={true}
                placeholder={intl.formatMessage({ id: 'reportInstockPHWarehouse', defaultMessage: 'Select Warehouse' })}
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                showSearch
                onChange={(value) => onFilterOrder(value, 'warehouse')}
              >
                {
                  _.map(warehouseData, (item) => (
                    <Option key={item.warehouseId}>
                      {item.warehouseName}
                    </Option>
                  ))
                }
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'reportInstockColumnsItemName', defaultMessage: 'Item Name' })}
                  req={false}
                />
              }
            >
              <Input
                allowClear={true}
                placeholder={intl.formatMessage({ id: 'reportInstockPHItemName', defaultMessage: 'Enter Item Name' })}
                onChange={(e) => onFilterOrder(e.target.value, 'itemName')}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'reportInstockColumnsLotNo', defaultMessage: 'Lot No. or Serial No.' })}
                  req={false}
                />
              }
            >
              <Input
                allowClear={true}
                placeholder={intl.formatMessage({ id: 'reportInstockPHLotNo', defaultMessage: 'Enter Lot No. or Serial No.' })}
                onChange={(e) => onFilterOrder(e.target.value, 'lotNo')}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 2]}>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'reportInstockColumnsItemGroup', defaultMessage: 'Item Group' })}
                  req={false}
                />
              }
            >
              <Select
                allowClear={true}
                placeholder={intl.formatMessage({ id: 'reportInstockPHItemGroup', defaultMessage: 'Select Item Group' })}
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                showSearch
                onChange={(value) => onFilterOrder(value, 'itemGroup')}
              >
                {
                  _.map(_.get(dataDefault, 'itemGroup'), (item) => (
                    <Option key={item.itemGroupId}>
                      {item.itemGroupName}
                    </Option>
                  ))
                }
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'reportInstockColumnsItemType', defaultMessage: 'Item Type' })}
                  req={false}
                />
              }
            >
              <Select
                allowClear={true}
                placeholder={intl.formatMessage({ id: 'reportInstockPHItemType', defaultMessage: 'Select Item Type' })}
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                showSearch
                onChange={(value) => onFilterOrder(value, 'itemType')}
              >
                {
                  _.map(_.get(dataDefault, 'itemType'), (item) => (
                    <Option key={item.typeId}>
                      {item.itemtypeName}
                    </Option>
                  ))
                }
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'reportInstockColumnsBrand', defaultMessage: 'Brand' })}
                  req={false}
                />
              }
            >
              <Select
                showSearch
                allowClear={true}
                onSearch={(value) => brand.setBrandSearch(value)}
                onChange={(value) => onFilterOrder(value, 'brand')}
                dataSource={brand.optionsBrand}
                filterOption={false}
                value={_.get(search, 'brand')}
                placeholder={intl.formatMessage({ id: 'reportInstockPHBrand', defaultMessage: 'Select Brand' })}
                style={{ width: '100%' }}
                notFoundContent={brand.loadingBrand ? 'Loading...' : null}
              >
                {_.map(brand.optionsBrand, (item) =>
                  <Option value={item.item_brand_id}>
                    {item.item_brand_name}
                  </Option>
                )}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 2]}>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'reportInstockColumnsModel', defaultMessage: 'Model' })}
                  req={false}
                />
              }
            >
              <Select
                showSearch
                allowClear={true}
                onSearch={(value) => model.setModelSearch(value)}
                onChange={(value) => onFilterOrder(value, 'model')}
                dataSource={model.optionsModel}
                filterOption={false}
                value={_.get(search, 'model')}
                placeholder={intl.formatMessage({ id: 'reportInstockPHModel', defaultMessage: 'Select Model' })}
                style={{ width: '100%' }}
                notFoundContent={model.loadingModel ? 'Loading...' : null}
                disabled={_.get(search, 'brand') ? false : true}
              >
                {_.map(model.optionsModel, (item) =>
                  <Option value={item.item_model_id}>
                    {item.item_model_name}
                  </Option>
                )}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'reportInstockColumnsFDA', defaultMessage: 'FDA' })}
                  req={false}
                />
              }
            >
              <Input
                allowClear={true}
                placeholder={intl.formatMessage({ id: 'reportInstockPHFDA', defaultMessage: 'Enter FDA' })}
                onChange={(e) => onFilterOrder(e.target.value, 'fda')}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'reportInstockColumnsManufacturingDate', defaultMessage: 'Manufacturing Date' })}
                  req={false}
                />
              }
            >
              <RangePicker
                allowClear={true}
                placeholder={[intl.formatMessage({ id: 'reportInstockPHManufacturingDateFrom', defaultMessage: 'Select From Date' }), intl.formatMessage({ id: 'reportInstockPHManufacturingDateTo', defaultMessage: 'Select To Date' })]}
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                onChange={(value) => onFilterOrder(value, 'manufacturingDate')}
                style={{ width: '100%' }}
                format={['DD/MM/YYYY', 'DD/MM/YYYY']}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 2]}>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'reportInstockColumnsExpiredDate', defaultMessage: 'Expired Date' })}
                  req={false}
                />
              }
            >
              <RangePicker
                allowClear={true}
                placeholder={[intl.formatMessage({ id: 'reportInstockPHExpiredDateFrom', defaultMessage: 'Select From Date' }), intl.formatMessage({ id: 'reportInstockPHExpiredDateTo', defaultMessage: 'Select To Date' })]}
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                onChange={(value) => onFilterOrder(value, 'expiredDate')}
                style={{ width: '100%' }}
                format={['DD/MM/YYYY', 'DD/MM/YYYY']}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  };

  const menuColumn = (
    <Menu>
      <Menu.Item>
        <Button style={{ padding: 'unset' }} ghost type="link" onClick={handleExport}>
          <FormattedMessage id="expenseBtnExport" defaultMessage="Export Excel" />
        </Button>
      </Menu.Item>
    </Menu>
  );

  return (
    <div style={{ marginTop: '16px' }}>
      <CollapseCustom
        label={intl.formatMessage({ id: `btnFilter`, defaultMessage: 'Filter' })}
        extra={
          <div style={{ display: 'flex' }}>
            <ReportInstockActionColumns
              actionColumns={actionColumns}
            />
            <Dropdown overlay={menuColumn} trigger={['click']} placement="bottomRight">
              <Button01 key="submit" type="primary" btnsize="wd_df">
                <FormattedMessage id="btnActionGroup" defaultMessage="Action" />
                <DownOutlined style={{ fontSize: '14px', paddingLeft: '22%' }} />
              </Button01>
            </Dropdown>
          </div>
        }
      >
        {formFilter()}
      </CollapseCustom>
    </div >
  )
}

export default ReportInstockAction
