import React, { useState } from "react";
import { DatePicker } from 'antd';
import moment from 'moment';
import _ from "lodash";




const AssetDateSelect = (props) => {
  // const [historyDateFilterStart, setHistoryDateFilterStart] = useState([moment().startOf('month'), moment().endOf('month')]);
  const [historyDateFilterEnd, setHistoryDateFilterEnd] = useState();
  const dateFormat = 'YYYY-MM-DD';
  const { MonthPicker, RangePicker } = DatePicker;
  const { setDateFilterSelectedStart, setDateFilterSelectedEnd, historyDateFilterStart, setHistoryDateFilterStart } = props
  function onChange(value) {
    console.log(`Date Is ${value}`);
    setHistoryDateFilterStart(value)
    setDateFilterSelectedStart(moment(_.get(value, '[0]'), 'ddd, MMM DD YYYY').format(dateFormat));
    setDateFilterSelectedEnd(moment(_.get(value, '[1]'), 'ddd, MMM DD YYYY').format(dateFormat));
    console.log(moment(_.get(value, '[0]'), 'ddd, MMM DD YYYY').format(dateFormat));
    console.log(moment(_.get(value, '[1]'), 'ddd, MMM DD YYYY').format(dateFormat));
  }

  return (
    <div>
      <RangePicker
        value={historyDateFilterStart}
        defaultValue={[moment().startOf('month'), moment().endOf('month')]}
        format={dateFormat}
        style={{ width: 240 }}
        onChange={onChange}
      />
    </div>
  )
}


export default AssetDateSelect