import React, { useState, useCallback, useEffect } from 'react';
import { TaskDetail } from './task-detail-context';
import { Form, Modal, Tabs, Col, Row, Spin, Icon, Select, Avatar, Menu, Badge, Divider, Card } from 'antd';
import Button_01 from '../../../v2/button_01';
import Button_02 from '../../../v2/button_02';
import { useIntl, FormattedMessage } from 'react-intl';
import InformationTab from './component/information/index';
import MapTab from './component/map/index';
import NoteTabs from './component/note/index';
import SubtaskTab from './component/subtask/index';
import WorkLogsTab from './component/workLog/index';
import StatusTab from './component/status/index';
import MoreDetailTab from './component/moreDetail/index';
import TeamTab from './component/team/index';
import RequestTab from './component/request/index';
import Assignee from './component/assignee/index';
import TrackingTab from './component/tracking/index';
import _ from 'lodash';
import httpClient from '../../../axiosClient';
import { successNotification } from '../../../v2/notification';
import { UpDateDataMoreDetail, CreateDataMoreDetail, deleteImg } from '../../../../controllers/more-detail/more-detail-api';
import './css/index.css';
import ModalCopyTask from '../../../modal-copy-task/index';
import moment from 'moment';
import { PageSettings } from '../../../../config/page-settings';
import ViewProject from '../../../project/modal-view/index';
import { useAppContext } from '../../../../includes/indexProvider';

const { TabPane } = Tabs;
const { Option } = Select;
const dateTitlelModal = 'ddd, MMM DD, YYYY';
const today = new Date();
const TaskDetailIndex = (props) => {
  const {
    form,
    visible,
    setVisible,
    teamData,
    scheduleObjNew,
    trigger,
    setTrigger,
    mainMapViewResourceData,
    mainResourceMemComId,
    visibleResource,
    setVisibleResource,
    mainTaskId,
    mainDate,
    mainStatusTaskTitle,
    mainStatusTaskObject,
    mainDateTitleModal,
    setTriggerTaskDetail,
    triggerTaskDetail,
    checkedListTeam,
    keyTabMonitor,
    dateCount,
  } = props;
  const app = useAppContext();
  const latlng = _.get(app, 'state.latlngCompany')
  const { validateFields } = form;
  const comId = localStorage.getItem('comId');
  const comCode = localStorage.getItem('comCode');
  const intl = useIntl();
  //------------------- Center State ---------------------------
  const [taskId, setTaskId] = useState();
  const [tabKey, setTabKey] = useState('information');
  const [loading, setLoading] = useState(false);
  const [statusView, setStatusView] = useState();
  const [selectTeam, setSelectTeam] = useState();
  const [statusTask, setStatusTask] = useState();
  const [selectStatus, setSelectStatus] = useState();
  const [assignee, setAssignee] = useState([]);
  const [keys, setKeys] = useState([]);
  const [triggerInformation, setTriggerInformation] = useState(false);
  const [triggerContactAndLocation, setTriggerContactAndLocation] = useState(false);
  const [disTance, setDisTance] = useState();
  const [priorityData, setPriorityData] = useState([]);
  const [contactData, setContactData] = useState([]);
  const [fromLocationData, setFromLocationData] = useState([]);
  const [toLocationData, setToLocationData] = useState([]);
  //--------------------- Normal State ----------------------
  const [viewInformationData, setViewInformationData] = useState();
  const [visibleComment, setVisibleComment] = useState(false);
  const [visibleAddContact, setVisibleAddContact] = useState(false);
  const [visibleAddFromLocation, setVisibleAddFromLocation] = useState(false);
  const [visibleEditComapanyAddress, setVisibleEditComapanyAddress] = useState(false);
  const [visibleAddCustomerAddress, setVisibleAddCustomerAddress] = useState(false);
  const [visibleEditCustomerAddress, setVisibleEditCustomerAddress] = useState(false);
  const [visibleModalCopyTask, setVisibleModalCopyTask] = useState(false);
  const [visibleAssignee, setVisibleAssignee] = useState(false);
  const [customFieldData, setCustomFieldData] = useState();
  const [textValue, setTextValue] = useState('');
  const [moreDetailId, setMoreDetailId] = useState();
  const [editorStaet, setEditorStaet] = useState();
  const [imgSrc, setImgSrc] = useState([]);
  const [allSrc, setAllSrc] = useState([]);
  const [editFromLocationData, setEditFromLocationData] = useState();
  const [editTolocationData, setEditTolocationData] = useState();
  const [saveFromRefCopyTask, setSaveFromRefCopyTask] = useState(null);
  const [resourceMemComId, setResourceMemComId] = useState();
  const [statusTaskData, setStatusTaskData] = useState([]);
  const [statusTaskTitle, setStatusTaskTitle] = useState('');
  const [statusTaskObject, setStatusTaskObject] = useState([]);
  const [selectKeyMenuItem, setSelectKeyMenuItem] = useState('');
  const [menuTaskNo, setMenuTaskNo] = useState('');
  const [menuStatusColor, setMenuStatusColor] = useState('');
  const [menuTaskTypeName, setMenuTaskTypeName] = useState('');
  const [contentMoreDetail, setContentMoreDetail] = useState(false);
  //------------------- New State --------------------------
  const [newContact, setNewConatact] = useState();
  const [newCompanyAddress, setNewCompanyAddress] = useState();
  const [newCustomerAddress, setNewCustomerAddress] = useState();

  const [triggerApi, setTriggerApi] = useState(false);
  const [activeKey, setActiveKey] = useState([]);
  const [statusSave, setStatusSave] = useState(false);
  const [temporaryData, setTemporaryData] = useState([]);

  const [selectFromAddress, setSelectFromAddress] = useState([]);
  const [createFromData, setCreateFromData] = useState();
  const [fromOutSide, setFromOutSide] = useState();
  const [triggerFrom, setTriggerFrom] = useState(false);
  const [visibleAddNewCompany, setVisibleAddNewCompany] = useState(false);
  const [statusFromAdd, setStatuFromsAdd] = useState('in');
  const [keyMenu, setKeyMenu] = useState([]);
  const [centerFrom, setCenterFrom] = useState(latlng);
  const [positionFrom, setPositionFrom] = useState(latlng);
  const [visibleInfoWindowFrom, setVisibleInfoWindowFrom] = useState(false);

  const [selectToAddress, setSelectToAddress] = useState([]);
  const [createToData, setCreateToData] = useState();
  const [toOutSide, setToOutSide] = useState();
  const [triggerTo, setTriggerTo] = useState(false);
  const [visibleAddNewCustomer, setVisibleAddNewCustomer] = useState(false);
  const [statusToAdd, setStatusToAdd] = useState('in');
  const [keyMenuTo, setKeyMenuTo] = useState([]);
  const [centerTo, setCenterTo] = useState(latlng);
  const [positionTo, setPositionTo] = useState(latlng);
  const [visibleInfoWindowTo, setVisibleInfoWindowTo] = useState(false);
  const [visibleViewProject, setVisibleViewProject] = useState(false);
  const [refData, setRefData] = useState([]);
  const orgString = checkedListTeam ? checkedListTeam.toString() : '';

  useEffect(() => {
    if (visible === true) {
      setTaskId(mainTaskId);
    }
  }, [mainTaskId, visible]);

  useEffect(() => {
    if (visibleResource === true) {
      setStatusTaskObject(mainStatusTaskObject);
      setResourceMemComId(mainResourceMemComId);
      setStatusTaskTitle(mainStatusTaskTitle);
    }
  }, [visibleResource, mainStatusTaskObject]);

  useEffect(() => {
    if (resourceMemComId) {
      const startDate = moment(mainDate, 'YYYY-MM-DD')
        .add(-(dateCount === 1 ? 0 : dateCount), 'day')
        .format('YYYY-MM-DD');

      async function getTaskStatus() {
        const response = await httpClient.get(
          `/v3/task/manager/company/${comId}/my/${resourceMemComId}/task/group?startDate=${startDate}&endDate=${mainDate}&orgId=${orgString}&menuType=workforce`
        );
        if (response.status === 200) {
          const filter = response.data;
          delete filter.speed;

          const sortTaskStatusData = [
            ..._.filter(_.toArray(filter), (el) => el.code === 'Todo'),
            ..._.filter(_.toArray(filter), (el) => el.code === 'Doing'),
            ..._.filter(_.toArray(filter), (el) => el.code === 'Done'),
          ];

          setStatusTaskData(sortTaskStatusData);
          newStatusData(_.toArray(response.data));
        }
      }
      getTaskStatus();
    }
  }, [resourceMemComId]);

  const newStatusData = (statusData) => {
    if (statusData) {
      const statusTask =
        statusData &&
        statusData.filter((item) => {
          return item.title.includes(statusTaskTitle);
        });
      setStatusTaskObject(statusTask?.length ? statusTask[0].tasks : []);
    }
  };

  const handleCreate = () => {
    validateFields(async (err, values) => {
      if (err) {
        return;
      }

      Modal.confirm({
        className: 'add-modal-confirm',
        title: intl.formatMessage({ id: `modalTaskDetailSaveEditTask`, defaultMessage: 'Do you want to update task ?' }),
        okText: intl.formatMessage({ id: 'settingHolidaysModalBtnConfirm', defaultMessage: 'Confirm' }),
        centered: true,
        icon: 'none',
        okButtonProps: {
          style: {
            width: '96px',
          },
          type: 'primary',
        },
        cancelButtonProps: {
          style: {
            width: '96px',
          },
          type: 'danger',
        },
        cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
        async onOk() {
          // setLoading(true)
          const filterData =
            contactData &&
            contactData.filter((item) => {
              return item.customerContactId === values.contact;
            });
          const momentStart = moment(values.startDate, 'YYYY-MM-DD hh:mm A').unix();
          const momentDue = moment(values.dueDate, 'YYYY-MM-DD hh:mm A').unix();
          // console.log('handleCreate', values)
          const filterRef =
            refData &&
            refData.filter((col) => {
              return col.referenceNo === values.reference;
            });
          const body = {
            cusId: _.get(viewInformationData, 'information.customer_id'),
            cusName: _.get(viewInformationData, 'information.customer'),
            remark: values.remark ? values.remark : '',
            priorityId: values.priority ? values.priority : undefined,
            customerContactId: statusSave !== true ? values.contact : undefined,
            contacts: [
              {
                name: _.get(filterData, '[0].customerContactName'),
                phone: values.phone ? values.phone : '',
                email: _.get(filterData, '[0].customerContactEmail'),
                position: _.get(filterData, '[0].position'),
                entityRef: filterData && filterData[0].customerContactId !== 'temporary' ? filterData[0].customerContactId : undefined,
                entityType: filterData && filterData[0].customerContactId !== 'temporary' ? 'customer-contact' : undefined,
              },
            ],
            // fromAddressId: _.get(createFromData, 'status') === "customer_address" ? _.get(createFromData, 'addressBookId') : _.get(createFromData, 'status') === "task_address" ? undefined : null,
            // formAddressName: _.get(createFromData, 'name') ? _.get(createFromData, 'name') : '',
            // fromAddress: _.get(createFromData, 'address') ? _.get(createFromData, 'address') : '',
            // fromLat: _.get(createFromData, 'lat') ? _.get(createFromData, 'lat') : '',
            // fromLng: _.get(createFromData, 'lng') ? _.get(createFromData, 'lng') : '',
            toAddressId:
              _.get(createToData, 'status') === 'customer_address'
                ? _.get(createToData, 'addressBookId')
                : _.get(createToData, 'status') === 'task_address'
                  ? undefined
                  : null,
            deliveryAddress: _.get(createToData, 'address') ? _.get(createToData, 'address') : '',
            toAddressName: _.get(createToData, 'name') ? _.get(createToData, 'name') : '',
            lat: _.get(createToData, 'lat') ? _.get(createToData, 'lat') : '',
            lng: _.get(createToData, 'lng') ? _.get(createToData, 'lng') : '',
            taskName: values.taskName ? values.taskName : undefined,
            planStart: momentStart ? momentStart : undefined,
            planFinish: momentDue ? momentDue : undefined,
            version: '2',
            reference_type: _.size(filterRef) !== 0 ? _.get(filterRef, '[0].referenceType') : null,
            reference: _.size(filterRef) !== 0 ? _.get(filterRef, '[0].referenceNo') : values.reference,
            reference_id: _.size(filterRef) !== 0 ? _.get(filterRef, '[0].referenceId') : null,
          };

          if (customFieldData && customFieldData !== undefined) {
            for (let index = 0; index < customFieldData.length; index++) {
              // const element = array[index];
              const entityToField = _.get(customFieldData[index], 'entityToField');
              if (entityToField) {
                body[entityToField] = _.get(values, entityToField) || '';
              }
            }
          }

          try {
            const response = await httpClient.put(`/v3/task/manager/company/${comId}/task-update/${taskId}?menuType=workforce`, body);
            if (response.status === 200) {
              if (textValue) {
                if (moreDetailId) {
                  await UpDateDataMoreDetail(moreDetailId, textValue);
                } else {
                  await CreateDataMoreDetail(taskId, textValue);
                }
                const diff = _.differenceBy(allSrc, imgSrc, 'fullPathIs');
                const mapDiff =
                  diff &&
                  diff.map((item) => {
                    return item.fileCodeIs;
                  });
                await deleteImg(mapDiff);
                setAllSrc([]);
                setImgSrc([]);
              }
              successNotification(response.data.status.message);
              setTriggerInformation((current) => !current);
              setSelectTeam();
              setStatusTask();
              setSelectStatus();
              setDisTance();
              setAssignee([]);
              setCustomFieldData();
              setKeys([]);
              setMoreDetailId();
              setTextValue('');
              setNewConatact();
              setNewCompanyAddress();
              setNewCustomerAddress();
              setStatusView();
              setContentMoreDetail(false);
              setStatusSave(false);
              setFromLocationData([]);
              setToLocationData([]);
              setRefData([]);
              setTriggerTaskDetail((current) => !current);
              // setLoading(false)
            } else {
              successNotification(response.data.status.message);
            }
          } catch (error) { }
        },
        onCancel() {
          setStatusView();
          setTriggerInformation((current) => !current);
        },
      });
    });
  };

  const handleClose = () => {
    setVisible(false);
    setTabKey('information');
    // setViewInformationData()
    setStatusView();
    setSelectTeam();
    setStatusTask();
    setSelectStatus();
    setDisTance();
    setAssignee([]);
    setCustomFieldData();
    setKeys([]);
    setMoreDetailId();
    setTextValue('');
    setNewConatact();
    setNewCompanyAddress();
    setNewCustomerAddress();
    setTaskId();
    setActiveKey([]);
    setContentMoreDetail(false);
    setStatusSave(false);
    setFromLocationData([]);
    setToLocationData([]);
    setRefData([]);
  };

  const handleCloseResource = () => {
    setVisibleResource(false);
    setTabKey('information');
    // setViewInformationData()
    setStatusView();
    setSelectTeam();
    setStatusTask();
    setSelectStatus();
    setDisTance();
    setAssignee([]);
    setCustomFieldData();
    setKeys([]);
    setMoreDetailId();
    setTextValue('');
    setNewConatact();
    setNewCompanyAddress();
    setNewCustomerAddress();
    setResourceMemComId();
    setStatusTaskTitle();
    setSelectKeyMenuItem('');
    setTaskId();
    setMenuStatusColor('');
    setMenuTaskNo('');
    setMenuTaskTypeName('');
    setContentMoreDetail(false);
    setFromLocationData([]);
    setToLocationData([]);
  };

  const handleCancelResource = () => {
    setTaskId();
    setSelectKeyMenuItem('');
    setMenuStatusColor('');
    setMenuTaskNo('');
    setMenuTaskTypeName('');
  };

  const handleEdit = () => {
    setStatusView('edit');
  };

  const handleCancel = () => {
    setTriggerInformation((current) => !current);
    setStatusView();
    setNewConatact();
    setNewCompanyAddress();
    setNewCustomerAddress();
    setStatusSave(false);
  };

  // console.log('Status save', statusSave)

  const handleClickTab = (key) => {
    setTabKey(key);
  };

  const saveFormRefCopyTask = useCallback((node) => {
    if (node !== null) {
      setSaveFromRefCopyTask(node);
    }
  }, []);

  const handleCancelModalCopyTask = () => {
    setVisibleModalCopyTask(false);
    saveFromRefCopyTask.resetFields();
  };

  const handleCopyTask = () => {
    saveFromRefCopyTask.validateFields(async (err, values) => {
      if (err) {
        return;
      }

      Modal.confirm({
        className: 'copy-task-modal-confirm',
        centered: true,
        icon: null,
        title: intl.formatMessage({ id: `modalTaskDetailSaveCloneTask`, defaultMessage: 'Are you sure you want to copy task ?' }),
        okText: intl.formatMessage({ id: 'modalConfirmBtnYes', defaultMessage: 'Yes' }),
        cancelText: intl.formatMessage({ id: 'modalConfirmBtnNo', defaultMessage: 'No' }),
        okButtonProps: {
          style: {
            width: '96px',
            background: '#6490cf',
            border: '1px solid #6490cf',
            borderRadius: '8px',
            hover: {
              background: '#ffffff',
              color: '#6490cf',
            },
          },
          type: 'primary',
        },
        cancelButtonProps: {
          style: {
            width: '96px',
            border: '1px solid #6490cf',
            borderRadius: '8px',
            backgroundColor: '#ffffff',
            color: '#6490CF',
          },
          type: 'primary',
        },
        async onOk() {
          const formatStartDate = moment(values.startDate, 'ddd, MMM DD yyyy').format('YYYY-MM-DD');
          const formatDueDate = moment(values.dueDate, 'ddd, MMM DD yyyy').format('YYYY-MM-DD');
          const formatStartTime = moment(values.startTime, 'h:mm A').format('HH:mm');
          const formatDueTime = moment(values.dueTime, 'h:mm A').format('HH:mm');
          const StartDateTime = formatStartDate + ' ' + formatStartTime;
          const DueDateTime = formatDueDate + ' ' + formatDueTime;

          const body = {
            taskId: taskId,
            commentCreate: values.comment,
            countOfCopy: values.numberCloneTask,
            newPlanStart: values.startDate && values.startDate !== undefined ? StartDateTime : undefined,
            newPlanFinish: values.dueDate && values.dueDate !== undefined ? DueDateTime : undefined,
          };
          try {
            const respone = await httpClient.post(`/v3/task/manager/company/${comCode}/create/copy-task?menuType=workforce`, body);
            if (respone.status == 200) {
              successNotification(respone.data.status.message);
              setVisibleModalCopyTask(false);
              saveFromRefCopyTask.resetFields();
              setTriggerTaskDetail((current) => !current);
            } else {
              saveFromRefCopyTask.resetFields();
              setVisibleModalCopyTask(false);
            }
          } catch (error) {
            saveFromRefCopyTask.resetFields();
            setVisibleModalCopyTask(false);
          }
        },
        onCancel() { },
      });
    });
  };

  const handleChangeResourceId = (memComId) => {
    if (memComId) {
      setResourceMemComId(memComId);
      setTaskId(null);
      setMenuStatusColor('');
      setMenuTaskNo('');
      setMenuTaskTypeName('');
      setSelectKeyMenuItem('');
      setCustomFieldData();
    }
  };

  const handleChangeStatusTask = (value) => {
    setStatusTaskTitle(value);
    setSelectKeyMenuItem('');
    const statusTask =
      statusTaskData &&
      statusTaskData.filter((item) => {
        return item.title.includes(value);
      });
    setStatusTaskObject(statusTask[0].tasks);
  };

  const handleSelectTaskId = ({ item, key, keyPath, selectedKeys, domEvent }) => {
    setTaskId(statusTaskObject[key].taskId);
    setSelectKeyMenuItem(key);
    setMenuStatusColor(item.props.statusColor);
    setMenuTaskNo(item.props.taskNo);
    setMenuTaskTypeName(item.props.taskTypeName);
  };

  const TitleModal = () => {
    return (
      <div>
        <Row>
          <Col className="assignee-details-header-modal" span={5}>
            <span className="assignee-details-title">
              {keyTabMonitor === '2' ? moment(today).format(dateTitlelModal) : mainDateTitleModal}
            </span>
          </Col>
          <Divider className="task-details-title-divider" type="vertical" />
          <Col span={19} className="assignee-details-header-modal">
            {menuStatusColor && menuTaskTypeName && menuTaskNo ? (
              <div style={{ textAlign: 'left' }}>
                <Badge color={menuStatusColor} className="Statusdot" />
                <span className="assignee-details-title">{menuTaskNo}</span>
                <Badge color="#1D3557" className="beetwen-badge-dot" />
                <span className="assignee-details-title">{menuTaskTypeName}</span>
              </div>
            ) : null}
          </Col>
        </Row>
      </div>
    );
  };

  const saveFromAddress = async () => {
    setVisibleAddNewCompany(true);
    setStatuFromsAdd('out');
  };

  const saveToAddress = async () => {
    setVisibleAddNewCustomer(true);
    setStatusToAdd('out');
  };

  const handleViewProject = () => {
    setVisibleViewProject(true);
  };

  return (
    <>
      <TaskDetail.Provider
        value={{
          stateTask: {
            tabKey,
            taskId,
            viewInformationData,
            statusView,
            visible,
            teamData,
            selectTeam,
            triggerInformation,
            statusTask,
            selectStatus,
            visibleComment,
            disTance,
            assignee,
            customFieldData,
            keys,
            textValue,
            moreDetailId,
            editorStaet,
            imgSrc,
            allSrc,
            priorityData,
            contactData,
            fromLocationData,
            toLocationData,
            visibleAddContact,
            triggerContactAndLocation,
            newContact,
            visibleAddFromLocation,
            newCompanyAddress,
            visibleEditComapanyAddress,
            editFromLocationData,
            visibleAddCustomerAddress,
            newCustomerAddress,
            visibleEditCustomerAddress,
            editTolocationData,
            visibleAssignee,
            scheduleObjNew,
            visibleResource,
            triggerApi,
            activeKey,
            contentMoreDetail,
            statusSave,
            temporaryData,
            selectFromAddress,
            createFromData,
            fromOutSide,
            triggerFrom,
            visibleAddNewCompany,
            statusFromAdd,
            selectToAddress,
            createToData,
            toOutSide,
            triggerTo,
            visibleAddNewCustomer,
            statusToAdd,
            keyMenu,
            keyMenuTo,
            centerFrom,
            centerTo,
            positionFrom,
            positionTo,
            visibleInfoWindowFrom,
            visibleInfoWindowTo,
            triggerTaskDetail,
            refData,
          },
          setStateTask: {
            setLoading,
            setViewInformationData,
            setSelectTeam,
            setTriggerInformation,
            setStatusTask,
            setVisibleComment,
            setSelectStatus,
            setDisTance,
            setAssignee,
            setCustomFieldData,
            setKeys,
            setTextValue,
            setMoreDetailId,
            setEditorStaet,
            setImgSrc,
            setAllSrc,
            setPriorityData,
            setContactData,
            setFromLocationData,
            setToLocationData,
            setVisibleAddContact,
            setTriggerContactAndLocation,
            setNewConatact,
            setVisibleAddFromLocation,
            setNewCompanyAddress,
            setVisibleEditComapanyAddress,
            setEditFromLocationData,
            setVisibleAddCustomerAddress,
            setNewCustomerAddress,
            setVisibleEditCustomerAddress,
            setEditTolocationData,
            setVisibleAssignee,
            setTriggerApi,
            setActiveKey,
            setContentMoreDetail,
            setStatusSave,
            setTemporaryData,
            setSelectFromAddress,
            setCreateFromData,
            setFromOutSide,
            setTriggerFrom,
            setVisibleAddNewCompany,
            setStatuFromsAdd,
            setSelectToAddress,
            setCreateToData,
            setToOutSide,
            setTriggerTo,
            setVisibleAddNewCustomer,
            setStatusToAdd,
            setKeyMenu,
            setKeyMenuTo,
            setCenterFrom,
            setCenterTo,
            setPositionFrom,
            setPositionTo,
            setVisibleInfoWindowFrom,
            setVisibleInfoWindowTo,
            setTriggerTaskDetail,
            setRefData,
          },
          fncStateTask: {
            saveFromAddress,
            saveToAddress,
            handleViewProject,
          },
        }}
      >
        <Modal
          title={<TitleModal />}
          className="task-detail-modal-resource "
          centered={true}
          visible={visibleResource}
          onCancel={handleCloseResource}
          width={1400}
          bodyStyle={{ padding: 'unset' }}
          footer={
            taskId ? (
              <PageSettings.Consumer>
                {({ checkPermissionAction }) => (
                  <div>
                    {checkPermissionAction(`P22PG1`, `P22PG1A3`)
                      ? statusView
                        ? [
                          <Button_02 key="back" btnsize="wd_df" onClick={handleCancel} style={{ margin: 'unset' }}>
                            <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
                          </Button_02>,
                          <Button_01 key="submit" type="primary" btnsize="wd_df" onClick={handleCreate} id="saveButton">
                            {' '}
                            <FormattedMessage id="btnSave" defaultMessage="Save" />
                          </Button_01>,
                        ]
                        : [
                          <Button_02 key="back" btnsize="wd_df" onClick={handleCancelResource} style={{ margin: 'unset' }}>
                            <FormattedMessage id="modalConfirmBtnClose" defaultMessage="Close" />
                          </Button_02>,
                          <Button_01 key="submit" type="primary" btnsize="wd_df" onClick={handleEdit}>
                            {' '}
                            <FormattedMessage id="btnEdit" defaultMessage="Edit" />
                          </Button_01>,
                        ]
                      : null}

                    {checkPermissionAction(`P22PG1`, `P22PG1A4`) ? (
                      <div style={{ float: 'left' }}>
                        <span onClick={() => setVisibleModalCopyTask(true)} className="clone-task-style">
                          {' '}
                          <Icon type="copy" style={{ marginRight: '5px' }} />
                          <FormattedMessage id="monitorTabDetailButtonCloneTask" defaultMessage="Clone Task" />
                        </span>
                      </div>
                    ) : null}
                  </div>
                )}
              </PageSettings.Consumer>
            ) : null
          }
        >
          <Spin spinning={loading}>
            <Row>
              <Col span={5}>
                <div style={{ padding: '24px 24px 16px 24px' }}>
                  <Select className="task-detail-tab-select-resource" onChange={handleChangeResourceId} value={resourceMemComId}>
                    {mainMapViewResourceData &&
                      mainMapViewResourceData.map((item, index) => (
                        <Option value={item.memComId} key={item.memComId}>
                          <Avatar className="status-working-avatar" size="large" src={item.pathImage} />
                          {item.fullname}
                        </Option>
                      ))}
                  </Select>
                </div>
                <div style={{ padding: '0px 24px' }}>
                  <Select className="task-status-select" onChange={handleChangeStatusTask} value={statusTaskTitle}>
                    {statusTaskData &&
                      statusTaskData.map((item, index) => (
                        <Option value={item.title} key={index}>
                          {item.title} {`(${item.unit})`}
                        </Option>
                      ))}
                  </Select>
                </div>
                <div className="status-working-div-menu" style={{ height: '405px' }}>
                  <Menu
                    className="task-detail-status-working-menu"
                    mode="inline"
                    onSelect={handleSelectTaskId}
                    selectedKeys={selectKeyMenuItem}
                    style={null}
                  >
                    {statusTaskObject &&
                      statusTaskObject.map((item, index) => {
                        return (
                          <Menu.Item
                            className="status-working-menu-item"
                            key={index}
                            taskNo={item.taskNo}
                            taskTypeName={item.taskTypeName}
                            statusColor={item.statusColor}
                          >
                            <div className="task-status-div">
                              <div className="task-no-div">
                                <Badge className="task-status-badge" color={item.statusColor} />
                                <p className="task-text-no-p">{item.taskNo}</p>
                                <span class="task-status-dot"></span>
                                <p className="task-text-no-p-task-name">{item.taskTypeName}</p>
                              </div>
                              <div class="task-status-text-date-time">
                                <span class="task-status-text-date">{`${item.startDateView}`}</span>
                                <span class="task-status-text-time">{`${item.startTimeView}`}</span>
                              </div>
                              <div class="task-status-text-date-time">
                                <span class="task-status-text-date">{`${item.endDateView}`}</span>
                                <span class="task-status-text-time">{`${item.endTimeView}`}</span>
                              </div>
                            </div>
                            {/* <Divider className="task-status-divider" style={{ marginTop: '10px' }} />*/}
                            {index === statusTaskObject.length - 1 ? null : <Divider className="task-status-divider" />}
                          </Menu.Item>
                        );
                      })}
                  </Menu>
                </div>
              </Col>
              {taskId ? (
                <div>
                  <Col span={14} className="task-detail-boder-div-style-resource ">
                    <Tabs className="task-detail-tab-tabpane-resource" onTabClick={handleClickTab} activeKey={tabKey}>
                      <TabPane
                        key="information"
                        tab={intl.formatMessage({ id: `taskDetailTabInformation`, defaultMessage: 'Information' })}
                        className="task-detail-body-panel"
                      >
                        <InformationTab form={form} />
                      </TabPane>
                      <TabPane
                        key="subTask"
                        tab={intl.formatMessage({ id: `taskDetailTabSubTask`, defaultMessage: 'Sub task' })}
                        className="task-detail-body-panel"
                      >
                        <SubtaskTab />
                      </TabPane>
                      <TabPane
                        key="map"
                        tab={intl.formatMessage({ id: `taskDetailTabMap`, defaultMessage: 'Map' })}
                        className="task-detail-body-panel"
                      >
                        <MapTab />
                      </TabPane>
                      <TabPane
                        key="moreDetail"
                        tab={intl.formatMessage({ id: `taskDetailTabMoreDetail`, defaultMessage: 'More Details' })}
                        className="task-detail-body-panel"
                      >
                        <MoreDetailTab />
                      </TabPane>
                      <TabPane
                        key="note"
                        tab={intl.formatMessage({ id: `taskDetailTabNote`, defaultMessage: 'Note' })}
                        className="task-detail-body-panel"
                      >
                        <NoteTabs />
                      </TabPane>
                      <TabPane
                        key="request"
                        tab={intl.formatMessage({ id: `taskDetailTabRequest`, defaultMessage: 'Request' })}
                        className="task-detail-body-panel"
                      >
                        <RequestTab />
                      </TabPane>
                      <TabPane
                        key="worklog"
                        tab={intl.formatMessage({ id: `taskDetailTabWorkLog`, defaultMessage: 'History' })}
                        className="task-detail-body-panel"
                      >
                        <WorkLogsTab />
                      </TabPane>
                    </Tabs>
                  </Col>
                  <Col span={5}>
                    <StatusTab />
                    <div className="task-detail-body-panel">
                      {/* <TeamTab /> */}
                      <Assignee />
                      <TrackingTab />
                    </div>
                  </Col>
                </div>
              ) : (
                <Col span={19} className="task-detail-boder-div-style-resource" style={{ padding: '24px' }}>
                  <Card className="task-status-card">
                    <div className="task-status-content-detail">
                      <Icon className="task-status-content-icon" type="profile" />
                      <p className="task-status-content-text">
                        <FormattedMessage id={`monitorTabDetailTxtPleaseSelectTask`} defaultMessage="Please select a Task" /> <br />
                        <FormattedMessage
                          id={`monitorTabDetailTxtTaskDetailWillAppearHere`}
                          defaultMessage="Task Detail will appear here."
                        />
                      </p>
                    </div>
                  </Card>
                </Col>
              )}
            </Row>
          </Spin>
        </Modal>

        <Modal
          title={
            statusView
              ? intl.formatMessage({ id: `taskDetailEdtiTaskText`, defaultMessage: 'Edit Task' })
              : intl.formatMessage({ id: `taskDetailTaskText`, defaultMessage: 'Task Details' })
          }
          centered={true}
          visible={visible}
          onCancel={handleClose}
          width={1154}
          bodyStyle={{ padding: 'unset' }}
          footer={
            <PageSettings.Consumer>
              {({ checkPermissionAction }) => (
                <div>
                  {checkPermissionAction(`P22PG1`, `P22PG1A3`)
                    ? statusView
                      ? [
                        <Button_02 key="back" btnsize="wd_df" onClick={handleCancel} style={{ margin: 'unset' }}>
                          <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
                        </Button_02>,
                        <Button_01 key="submit" type="primary" btnsize="wd_df" onClick={handleCreate} id="saveButton">
                          {' '}
                          <FormattedMessage id="btnSave" defaultMessage="Save" />
                        </Button_01>,
                      ]
                      : [
                        <Button_02 key="back" btnsize="wd_df" onClick={handleClose} style={{ margin: 'unset' }}>
                          <FormattedMessage id="modalConfirmBtnClose" defaultMessage="Close" />
                        </Button_02>,
                        <Button_01 key="submit" type="primary" btnsize="wd_df" onClick={handleEdit}>
                          {' '}
                          <FormattedMessage id="btnEdit" defaultMessage="Edit" />
                        </Button_01>,
                      ]
                    : null}

                  {checkPermissionAction(`P22PG1`, `P22PG1A4`) ? (
                    <div style={{ float: 'left' }}>
                      <span onClick={() => setVisibleModalCopyTask(true)} className="clone-task-style">
                        {' '}
                        <Icon type="copy" style={{ marginRight: '5px' }} />
                        <FormattedMessage id="monitorTabDetailButtonCloneTask" defaultMessage="Clone Task" />
                      </span>
                    </div>
                  ) : null}
                </div>
              )}
            </PageSettings.Consumer>
          }
        >
          <Spin spinning={loading}>
            <Row>
              <Col span={18} className="task-detail-boder-div-style ">
                <Tabs className="task-detail-tab-tabpane" onTabClick={handleClickTab} activeKey={tabKey}>
                  <TabPane
                    key="information"
                    tab={intl.formatMessage({ id: `taskDetailTabInformation`, defaultMessage: 'Information' })}
                    className="task-detail-body-panel"
                  >
                    <InformationTab form={form} />
                  </TabPane>
                  <TabPane
                    key="subTask"
                    tab={intl.formatMessage({ id: `taskDetailTabSubTask`, defaultMessage: 'Sub task' })}
                    className="task-detail-body-panel"
                  >
                    <SubtaskTab />
                  </TabPane>
                  <TabPane
                    key="map"
                    tab={intl.formatMessage({ id: `taskDetailTabMap`, defaultMessage: 'Map' })}
                    className="task-detail-body-panel"
                  >
                    <MapTab />
                  </TabPane>
                  <TabPane
                    key="moreDetail"
                    tab={intl.formatMessage({ id: `taskDetailTabMoreDetail`, defaultMessage: 'More Details' })}
                    className="task-detail-body-panel"
                  >
                    <MoreDetailTab />
                  </TabPane>
                  <TabPane
                    key="note"
                    tab={intl.formatMessage({ id: `taskDetailTabNote`, defaultMessage: 'Note' })}
                    className="task-detail-body-panel"
                  >
                    <NoteTabs />
                  </TabPane>
                  <TabPane
                    key="request"
                    tab={intl.formatMessage({ id: `taskDetailTabRequest`, defaultMessage: 'Request' })}
                    className="task-detail-body-panel"
                  >
                    <RequestTab />
                  </TabPane>
                  <TabPane
                    key="worklog"
                    tab={intl.formatMessage({ id: `taskDetailTabWorkLog`, defaultMessage: 'History' })}
                    className="task-detail-body-panel"
                  >
                    <WorkLogsTab />
                  </TabPane>
                </Tabs>
              </Col>
              <Col span={6}>
                <StatusTab />
                <div className="task-detail-body-panel">
                  {/* <TeamTab /> */}
                  <Assignee />
                  <TrackingTab />
                </div>
              </Col>
            </Row>
          </Spin>
        </Modal>
        <ModalCopyTask
          visible={visibleModalCopyTask}
          onCancel={handleCancelModalCopyTask}
          ref={saveFormRefCopyTask}
          onCreate={handleCopyTask}
          taskDetailsData={viewInformationData}
        />

        <ViewProject
          visible={visibleViewProject}
          setVisible={setVisibleViewProject}
          id={_.get(viewInformationData, 'information.reference_id')}
        />
      </TaskDetail.Provider>
    </>
  );
};

const TaskDetailForm = Form.create({
  name: 'modal_form',
})(TaskDetailIndex);

export default TaskDetailForm;
