import React, { useEffect, useMemo, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import _, { debounce } from 'lodash';
import { Row, Col, Form, Select, DatePicker, Divider, Icon, Input, Badge } from 'antd';
import moment from 'moment';
import stylesModal from './css/index.css';
import enUS from 'antd/es/locale/en_US';
import thTH from 'antd/es/locale/th_TH';
import { useAppContext } from '../../includes/indexProvider';
import NumberFormat from 'react-number-format';

const { Option } = Select;

const QuotationSetting = (props) => {
  const intl = useIntl();
  const { value, fnc } = props;
  const {
    customerData,
    addressData,
    contactData,
    AddCustomerData,
    warehouseData,
    AddContactData,
    AddAddressData,
    loading,
    defaultMembers,
    selectCustomer,
    selectAddress,
    selectWareHouse,
    statusSet,
    triggerAddress,
  } = value;
  const {
    setVisibleViewCustomer,
    setSelectCustomer,
    setVisibleAddCustomer,
    setVisibleEditCustomer,
    setLoading,
    setVisibleAddContact,
    setVisibleEditContact,
    setSelectContactData,
    setVisibleAddAddress,
    setSelectAddressData,
    setVisibleEditAddress,
    setSelectAddress,
    setSelectWareHouse,
    setItemObjectData,
    setStatusSet,
    setAddContactData,
  } = fnc;
  const { getFieldDecorator, getFieldValue, setFieldsValue, resetFields } = props.form;
  const dateFormat = 'ddd, MMM DD YYYY';
  const app = useAppContext();
  const langValue = app.state.langValue;
  moment.locale(langValue);

  const [warehouseDataSetting, setWarehouseDataSetting] = useState([]);

  useEffect(() => {
    setWarehouseDataSetting(warehouseData);
  }, [warehouseData]);

  useEffect(() => {
    if (statusSet === true) {
      setFieldsValue({
        ['customer']: _.get(selectCustomer, 'customerId'),
      });
      resetFields(['address']);
      resetFields(['warehouse']);
      setStatusSet(false);
    }
  }, [statusSet, _.get(selectAddress, 'customerAddressBookId'), _.get(selectWareHouse, 'warehouseId')]);

  useEffect(() => {
    if (AddCustomerData) {
      setLoading(true);
      setFieldsValue({
        ['customer']: _.get(AddCustomerData, 'customerId'),
      });
      setFieldsValue({
        ['address']: undefined,
      });
      setFieldsValue({
        ['contact']: undefined,
      });
      setFieldsValue({
        ['phone']: undefined,
      });
      setFieldsValue({
        ['warehouse']: undefined,
      });
      setSelectAddress();
      setSelectWareHouse();
      setLoading(false);
    }
  }, [_.get(AddCustomerData, 'customerId')]);

  useEffect(() => {
    const customerId = getFieldValue('customer');
    if (customerId) {
      setLoading(true);
      setFieldsValue({
        ['contact']: undefined,
      });
      setFieldsValue({
        ['phone']: undefined,
      });
      setTimeout(() => {
        const filterData =
          customerData &&
          customerData.filter((item) => {
            return item.customerId === customerId;
          });
        setSelectCustomer(_.get(filterData, '[0]'));
        setLoading(false);
      }, 1000);
    }
  }, [getFieldValue('customer'), _.size(customerData)]);

  useEffect(() => {
    if (AddContactData) {
      setFieldsValue({
        ['contact']: AddContactData,
      });
      setAddContactData();
    }
  }, [AddContactData, contactData]);

  useEffect(() => {
    const warehouseId = getFieldValue('warehouse');
    if (warehouseId) {
      const filterdata =
        warehouseData &&
        warehouseData.filter((item) => {
          return item.warehouseId === warehouseId;
        });
      setSelectWareHouse(_.get(filterdata, '[0]'));
    }
  }, [getFieldValue('warehouse')]);

  useEffect(() => {
    if (AddAddressData) {
      setFieldsValue({
        ['address']: _.get(AddAddressData, 'customerAddressBookId'),
      });
      setSelectAddress(AddAddressData);
    }
  }, [AddAddressData, addressData]);

  useEffect(() => {
    const addressId = getFieldValue('address');
    if (addressData) {
      const filterdata =
        addressData &&
        addressData.filter((item) => {
          return item.customerAddressBookId === addressId;
        });
      if (addressId && _.size(filterdata) !== 0) {
        setSelectAddress(_.get(filterdata, '[0]'));
      }
    }
  }, [getFieldValue('address'), addressData, triggerAddress]);

  useEffect(() => {
    const ContactId = getFieldValue('contact');
    if (ContactId) {
      const filterdata =
        contactData &&
        contactData.filter((item) => {
          return item.customerContactId === ContactId;
        });
      setFieldsValue({
        ['phone']: _.get(filterdata, '[0].customerContactPhone'),
      });
    }
  }, [getFieldValue('contact'), contactData]);

  // useEffect(() => {

  //   const wareHouseAutoSelect = warehouseDataSetting && warehouseDataSetting.filter((item) => item.selected === true);

  //   setFieldsValue({
  //     ['warehouse']: _.get(warehouseDataSetting, '[0].distance') ? _.get(warehouseDataSetting[0], 'warehouseId') : undefined
  //   });
  // }, [_.get(selectAddress, 'customerAddressBookId'),warehouseDataSetting])

  // useMemo(() => {
  //   const wareHouseAutoSelect = warehouseData && warehouseData.filter((item) => item.selected === true);

  //   setFieldsValue({
  //     ['warehouse']: _.get(wareHouseAutoSelect[0], 'warehouseId')
  //   });
  // }, [_.get(selectAddress, 'customerAddressBookId')])

  useMemo(() => {
    const wareHouseAutoSelect = warehouseDataSetting && warehouseDataSetting.filter((item) => item.selected === true);

    setFieldsValue({
      ['warehouse']: _.get(warehouseDataSetting, '[0].distance') ? _.get(warehouseDataSetting[0], 'warehouseId') : undefined,
    });
    setSelectWareHouse(_.get(warehouseDataSetting, '[0].distance') ? _.get(warehouseDataSetting, '[0]') : undefined);
  }, [_.get(selectAddress, 'customerAddressBookId'), warehouseDataSetting]);

  const handleOpenModalEdit = (customerId) => {
    const filterData =
      customerData &&
      customerData.filter((item) => {
        return item.customerId === customerId;
      });
    setSelectCustomer(_.get(filterData, '[0]'));
    setVisibleEditCustomer(true);
  };

  const handleOpenModalEditContact = (ContactId) => {
    const filterData =
      contactData &&
      contactData.filter((item) => {
        return item.customerContactId === ContactId;
      });
    setSelectContactData(_.get(filterData, '[0]'));
    setVisibleEditContact(true);
  };

  const handleOpenModalEditAddress = (addressId) => {
    const filterData =
      addressData &&
      addressData.filter((item) => {
        return item.customerAddressBookId === addressId;
      });
    setSelectAddressData(_.get(filterData, '[0]'));
    setVisibleEditAddress(true);
  };

  const handleOnChangeCustomer = () => {
    setFieldsValue({
      ['address']: undefined,
    });
    setFieldsValue({
      ['contact']: undefined,
    });
    setFieldsValue({
      ['phone']: undefined,
    });
    setFieldsValue({
      ['warehouse']: undefined,
    });
    setSelectAddress();
    setSelectWareHouse();
    setItemObjectData([]);
  };

  const handleAddressCustomerChange = (e) => {
    if (e) {
      setItemObjectData([]);
    }
  };

  const handleAddressWareHouseChange = (e) => {
    if (e) {
      setItemObjectData([]);
    }
  };

  return (
    <div className="card-modal-setting">
      <Form className="card-modal-setting-form">
        <Row gutter={[16, 16]}>
          <Col span={6}>
            <Form.Item
              className="card-modal-setting-form-item"
              label={<LabeRequire text={intl.formatMessage({ id: 'quotationModalLblCustomer', defaultMessage: 'Customer' })} req={true} />}
            >
              {getFieldDecorator('customer', {
                initialValue: _.get(selectCustomer, 'customerId') ? _.get(selectCustomer, 'customerId') : undefined,
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'quotationFormValidateCustomer', defaultMessage: 'Please select Customer.' }),
                  },
                ],
              })(
                <Select
                  className="card-modal-setting-select"
                  placeholder={intl.formatMessage({ id: 'quotationPlaceholderCustomer', defaultMessage: 'Select Customer' })}
                  optionLabelProp="label"
                  showSearch
                  filterOption={(input, option) =>
                    option.props.children.props.children[0].props.children.props.children
                      .toString()
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={handleOnChangeCustomer}
                  dropdownRender={(menu) => (
                    <div>
                      {menu}
                      <Divider style={{ margin: '4px 0' }} />
                      <div
                        style={{ padding: '4px 8px', cursor: 'pointer' }}
                        onMouseDown={(e) => e.preventDefault()}
                        onClick={() => setVisibleAddCustomer(true)}
                      >
                        <Icon type="plus" className="customer-icon-plus" />
                        <span className="text-add-customer">
                          <FormattedMessage id="customerActionButtonAddCustomer" defaultMessage="Add Customer" />
                        </span>
                      </div>
                    </div>
                  )}
                >
                  {customerData &&
                    customerData.map((item) => (
                      <Option key={item.customerId} label={item.customerName}>
                        <Row>
                          <Col span={20} className="qutation-setting-text-select">
                            <span>{item.customerName}</span>
                          </Col>
                          <Col span={4}>
                            <span onClick={() => handleOpenModalEdit(item.customerId)}>Edit</span>
                          </Col>
                        </Row>
                      </Option>
                    ))}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col
            span={6}
            style={getFieldValue('customer') ? { cursor: 'pointer', paddingTop: '55px', color: '#1D3557' } : { paddingTop: '55px' }}
          >
            <span
              onClick={() => {
                getFieldValue('customer') ? setVisibleViewCustomer(true) : setVisibleViewCustomer(false);
              }}
            >
              <FormattedMessage id="quotationModalLblView" defaultMessage="View" />
            </span>
          </Col>
          <Col span={12}>
            <Form.Item
              className="card-modal-setting-form-item"
              label={
                <LabeRequire text={intl.formatMessage({ id: 'quotationModalLblSalesperson', defaultMessage: 'Salesperson' })} req={true} />
              }
            >
              {getFieldDecorator('sale', {
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'quotationFormValidateSalesperson', defaultMessage: 'Please select salesperson.' }),
                  },
                ],
              })(
                <Select
                  className="card-modal-setting-select"
                  placeholder={intl.formatMessage({ id: 'quotationPlaceholderSalesperson', defaultMessage: 'Select Salesperson' })}
                  showSearch
                  filterOption={(input, option) =>
                    option.props.children !== null &&
                    option.props.children
                      .toString()
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {defaultMembers &&
                    defaultMembers.map((item, index) => (
                      <Option key={index} value={item.mem_com_id}>
                        {item.fullname}
                      </Option>
                    ))}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
              className="card-modal-setting-form-item-address"
              label={
                <LabeRequire text={intl.formatMessage({ id: 'quotationModalLblAddress', defaultMessage: 'Customer Address' })} req={true} />
              }
            >
              <Icon type="environment" className="icon-qutation" style={{ color: '#1D3557' }} />
              {getFieldDecorator('address', {
                initialValue: _.get(selectAddress, 'customerAddressBookId') ? _.get(selectAddress, 'customerAddressBookId') : undefined,
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'quotationFormValidateAddress', defaultMessage: 'Please select customer address.' }),
                  },
                ],
              })(
                <Select
                  className="card-modal-setting-select-customer-address"
                  placeholder={intl.formatMessage({ id: 'quotationPlaceholderAddress', defaultMessage: 'Select Customer Address' })}
                  disabled={getFieldValue('customer') ? false : true}
                  onChange={(e) => handleAddressCustomerChange(e, warehouseDataSetting)}
                  optionLabelProp="label"
                  loading={loading}
                  showSearch
                  filterOption={(input, option) =>
                    option.props.children.props.children[0].props.children.props.children
                      .toString()
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  dropdownRender={(menu) => (
                    <div>
                      {menu}
                      <Divider style={{ margin: '4px 0' }} />
                      <div
                        style={{ padding: '4px 8px', cursor: 'pointer' }}
                        onMouseDown={(e) => e.preventDefault()}
                        onClick={() => setVisibleAddAddress(true)}
                      >
                        <Icon type="plus" className="customer-icon-plus" />
                        <span className="text-add-customer">
                          <FormattedMessage id="quotationModalLblAddLocation" defaultMessage="Add Location" />
                        </span>
                      </div>
                    </div>
                  )}
                >
                  {addressData &&
                    addressData.map((item) => (
                      <Option key={item.customerAddressBookId} label={`${item.addressName} · ${item.address}`}>
                        <Row>
                          <Col span={20} className="qutation-setting-text-select">
                            <span>{item.addressName}</span>
                          </Col>
                          <Col span={4}>
                            <span onClick={() => handleOpenModalEditAddress(item.customerAddressBookId)}>
                              <FormattedMessage id="quotationModalLblEdit" defaultMessage="Edit" />
                            </span>
                          </Col>
                        </Row>
                      </Option>
                    ))}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              className="card-modal-setting-form-item-address"
              label={
                <LabeRequire text={intl.formatMessage({ id: 'quotationModalLblWarehouse', defaultMessage: 'Warehouse' })} req={true} />
              }
            >
              <Icon type="environment" className="icon-qutation" style={{ color: '#0c4da2' }} />
              {getFieldDecorator('warehouse', {
                initialValue: _.get(selectWareHouse, 'warehouseId') ? _.get(selectWareHouse, 'warehouseId') : undefined,
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'quotationFormValidateWarehouse', defaultMessage: 'Please select warehouse.' }),
                  },
                ],
              })(
                <Select
                  className="card-modal-setting-select-customer-address"
                  placeholder={intl.formatMessage({ id: 'quotationPlaceholderWarehouse', defaultMessage: 'Select Warehouse' })}
                  optionLabelProp="label"
                  onChange={handleAddressWareHouseChange}
                  disabled={getFieldValue('address') ? false : true}
                  showSearch
                  filterOption={(input, option) =>
                    option.props.children.props.children[0].props.children
                      .toString()
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {warehouseData &&
                    warehouseData.map((item) => (
                      <Option key={item.warehouseId} label={`${item.warehouseName} · ${item.warehouseAddress}`}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <div className="qutation-setting-text-select">
                            {item.warehouseName} · {item.warehouseAddress}
                          </div>
                          <div>
                            <span>
                              <NumberFormat
                                value={parseFloat(item.distanceTxt)}
                                displayType={'text'}
                                thousandSeparator={true}
                                suffix={`  ${item.suffix}`}
                              />
                            </span>
                          </div>
                        </div>
                      </Option>
                    ))}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={12}>
            <div className="card-modal-container-row-3">
              <div className="card-modal-container-row-3-left">
                <Form.Item
                  className="card-modal-setting-form-item"
                  label={
                    <LabeRequire text={intl.formatMessage({ id: 'quotationModalLblContact', defaultMessage: 'Contact' })} req={true} />
                  }
                >
                  {getFieldDecorator('contact', {
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({ id: 'quotationFormValidateContact', defaultMessage: 'Please select contact.' }),
                      },
                    ],
                  })(
                    <Select
                      className="card-modal-setting-select-contact"
                      placeholder={intl.formatMessage({ id: 'quotationPlaceholderContact', defaultMessage: 'Select Contact' })}
                      disabled={getFieldValue('customer') ? false : true}
                      optionLabelProp="label"
                      loading={loading}
                      showSearch
                      filterOption={(input, option) =>
                        option.props.children.props.children[0].props.children.props.children
                          .toString()
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      dropdownRender={(menu) => (
                        <div>
                          {menu}
                          <Divider style={{ margin: '4px 0' }} />
                          <div
                            style={{ padding: '4px 8px', cursor: 'pointer' }}
                            onMouseDown={(e) => e.preventDefault()}
                            onClick={() => setVisibleAddContact(true)}
                          >
                            <Icon type="plus" className="customer-icon-plus" />
                            <span className="text-add-customer">
                              <FormattedMessage id="quotationModalLblAddLocation" defaultMessage="Add Contact" />
                            </span>
                          </div>
                        </div>
                      )}
                    >
                      {contactData &&
                        contactData.map((item) => (
                          <Option key={item.customerContactId} label={item.customerContactName}>
                            <Row>
                              <Col span={20} className="qutation-setting-text-select">
                                <span>{item.customerContactName}</span>
                              </Col>
                              <Col span={4}>
                                <span onClick={() => handleOpenModalEditContact(item.customerContactId)}>
                                  <FormattedMessage id="quotationModalLblEdit" defaultMessage="Edit" />
                                </span>
                              </Col>
                            </Row>
                          </Option>
                        ))}
                    </Select>
                  )}
                </Form.Item>
              </div>
              <div className="card-modal-container-row-3-right">
                <Form.Item
                  className="card-modal-setting-form-item"
                  label={<LabeRequire text={intl.formatMessage({ id: 'quotationModalLblPhone', defaultMessage: 'Phone' })} req={false} />}
                >
                  {getFieldDecorator(
                    'phone',
                    {}
                  )(
                    <Input
                      placeholder={intl.formatMessage({ id: 'quotationPlaceholderPhone', defaultMessage: 'Please select phone' })}
                      readOnly={true}
                      style={{ border: 'unset', padding: 'unset' }}
                    ></Input>
                  )}
                </Form.Item>
              </div>
            </div>
          </Col>
          <Col span={12}>
            <Form.Item
              className="card-modal-setting-form-item"
              label={
                <LabeRequire
                  text={intl.formatMessage({ id: 'quotationModalLblDateOfIssue', defaultMessage: 'Date of Issue' })}
                  req={true}
                />
              }
            >
              <span>{moment(Date.now()).format(dateFormat)}</span>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

const LabeRequire = (props) => {
  const { text, req } = props;
  return (
    <span>
      {text}&nbsp;
      {req ? <span style={{ fontSize: '13px', color: '#ff0000' }}>*</span> : ''}
    </span>
  );
};

const ModalSettingForm = Form.create({
  name: 'modal_form',
})(QuotationSetting);

export default ModalSettingForm;
