import React, { useEffect, useState } from 'react';
import { Stage, Layer, Rect, Circle, Text, Group } from 'react-konva';
import imageTAECO from '../../../components/image/TAECO_map.png';
import _ from 'lodash';

const AdvanchImage = (props) => {
  const { mapSelectFilter, mapItemDashboard } = props;

  const [dashboardData, setDashboardData] = useState([]);

  useEffect(() => {
    if (_.size(mapItemDashboard)) onMapData();
  }, [mapItemDashboard]);

  console.log('dashboardData', dashboardData, mapItemDashboard, mapSelectFilter);

  const onMapData = () => {
    let data = [];

    for (let i = 0; i < _.size(areaData); i++) {
      const filter = _.filter(mapItemDashboard, (o) => o.index === areaData[i].index);
      data[i] = {
        ...areaData[i],
        // text: _.get(filter, '[0].size'),
        contribl: _.get(filter, '[0].contribuitionRate') || '-',
        avail: _.get(filter, '[0].availableLots') || '-',
        occ: _.get(filter, '[0].occupancyRate') || '-',
        inOut: _.get(filter, '[0].inOutLots') || '-',
        arr: _.get(filter, '[0].arr') || '-',
        availPark: _.get(filter, '[0].availPark') || '-',
        util: _.get(filter, '[0].util') || '-',
        revenue: _.get(filter, '[0].revenue') || '-',
        sla: _.get(filter, '[0].sla') || '-',
        transaction: _.get(filter, '[0].transaction') || '-',
        // title: _.get(filter, '[0]marketAreaName')
      };
    }
    setDashboardData(data);
  };

  const areaData = [
    {
      index: 0,
      x: 265,
      y: 312,
      width: 55,
      height: 72,
      text: '20MB',
      lineNumber: 1,
      lineHeight: 220,
      title: 'Flower',
      avail: '130',
      contribl: '80%',
      custom: { line: 2 },
    },
    {
      index: 1,
      x: 367,
      y: 265,
      width: 95,
      height: 85,
      text: '20MB',
      lineNumber: 2,
      lineHeight: 120,
      title: 'Orange',
      avail: '130',
      contribl: '80%',
      custom: { line: 50 },
    },
    {
      index: 2,
      x: 474,
      y: 265,
      width: 67,
      height: 89,
      text: '20MB',
      lineNumber: 3,
      lineHeight: 185,
      title: 'Meat',
      avail: '130',
      contribl: '80%',
      action: 'right',
      custom: { line: 5 },
    },
    {
      index: 3,
      x: 551,
      y: 262,
      width: 100,
      height: 89,
      text: '20MB',
      lineNumber: 4,
      lineHeight: 120,
      title: 'Fisheries',
      avail: '130',
      contribl: '80%',
      action: 'right',
      custom: { line: 5 },
    },
    {
      index: 4,
      x: 820,
      y: 265,
      width: 358,
      height: 90,
      text: '20MB',
      lineNumber: 5,
      lineHeight: 120,
      title: 'Seasonal Fruit',
      avail: '130',
      contribl: '80%',
      action: 'right',
      custom: { line: 20 },
    },
    {
      index: 5,
      x: 1228,
      y: 239,
      width: 140,
      height: 55,
      text: '20MB',
      lineNumber: 6,
      lineHeight: 100,
      title: 'Container Yard',
      avail: '130',
      contribl: '80%',
      custom: { line: 70, backgroundWhite: true },
    },
    {
      index: 6,
      x: 1400,
      y: 273,
      width: 130,
      height: 112,
      text: '20MB',
      lineNumber: 7,
      lineHeight: 195,
      title: 'Plant',
      avail: '130',
      contribl: '80%',
      custom: { line: 1 },
    },
    {
      index: 7,
      x: 1548,
      y: 250,
      width: 15,
      height: 130,
      text: '20MB',
      lineNumber: 8,
      lineHeight: 105,
      title: 'Pet',
      avail: '130',
      contribl: '80%',
      custom: { boxY: 30 },
    },
    {
      index: 8,
      x: 1571,
      y: 250,
      width: 17,
      height: 130,
      text: '20MB',
      lineNumber: 9,
      lineHeight: 180,
      title: 'Fish Mini Cold',
      title2: 'Storage',
      avail: '130',
      contribl: '80%',
      custom: { infoSpan: 5, boxY: 40 },
    },
    {
      index: 9,
      x: 275,
      y: 443,
      width: 58,
      height: 90,
      text: '20MB',
      lineNumber: 10,
      lineHeight: 60,
      title: 'Fish Market',
      title2: '',
      avail: '130',
      contribl: '80%',
      action: 'left',
      custom: { line: 10, backgroundWhite: true },
      position: 'bottom',
    },
    {
      index: 10,
      x: 335,
      y: 410,
      width: 22,
      height: 120,
      text: '20MB',
      lineNumber: 11,
      lineHeight: 136,
      title: 'Seafood & Protein',
      title2: '',
      avail: '130',
      contribl: '80%',
      action: 'left',
      custom: { infoSpan: 15 },
      position: 'bottom',
    },
    {
      index: 11,
      x: 360,
      y: 443,
      width: 55,
      height: 90,
      text: '20MB',
      lineNumber: 12,
      lineHeight: 135,
      title: 'Fresh',
      title2: '',
      avail: '130',
      contribl: '80%',
      action: 'right',
      position: 'bottom',
      custom: { infoItemBot: true },
    },
    {
      index: 12,
      x: 421,
      y: 442,
      width: 40,
      height: 90,
      text: '20MB',
      lineNumber: 13,
      lineHeight: 60,
      title: 'Retailed',
      title2: 'Vegetable',
      avail: '130',
      contribl: '80%',
      action: 'right',
      position: 'bottom',
      custom: { infoItemBot: true, line: 1 },
    },
    {
      index: 13,
      x: 463,
      y: 443,
      width: 110,
      height: 91,
      text: '20MB',
      lineNumber: 14,
      lineHeight: 135,
      title: 'Vegetable',
      title2: 'Ground',
      avail: '130',
      contribl: '80%',
      action: 'right',
      position: 'bottom',
      custom: { infoItemBot: true, line: 55 },
    },
    {
      index: 14,
      x: 578,
      y: 443,
      width: 95,
      height: 90,
      text: '20MB',
      lineNumber: 15,
      lineHeight: 60,
      title: 'Vegetable',
      title2: 'Building',
      avail: '130',
      contribl: '80%',
      action: 'right',
      position: 'bottom',
      custom: { infoItemBot: true, line: 5 },
    },
    {
      index: 15,
      x: 677,
      y: 443,
      width: 50,
      height: 90,
      text: '20MB',
      lineNumber: 16,
      lineHeight: 135,
      title: 'VIP D',
      title2: '',
      avail: '130',
      contribl: '80%',
      action: 'right',
      position: 'bottom',
      custom: { infoItemBot: true, line: 26 },
    },
    {
      index: 16,
      x: 729,
      y: 355,
      width: 90,
      height: 85,
      text: '20MB',
      lineNumber: 17,
      lineHeight: 152,
      title: 'Shops',
      title2: '',
      avail: '130',
      contribl: '80%',
      action: 'right',
      position: 'bottom',
      custom: { infoItemBot: true, line: 8 },
    },
    {
      index: 17,
      x: 825,
      y: 443,
      width: 45,
      height: 90,
      text: '20MB',
      lineNumber: 18,
      lineHeight: 135,
      title: 'Crop',
      title2: '',
      avail: '130',
      contribl: '80%',
      action: 'right',
      position: 'bottom',
      custom: { infoItemBot: true, line: 27 },
    },
    {
      index: 18,
      x: 875,
      y: 443,
      width: 178,
      height: 90,
      text: '20MB',
      lineNumber: 19,
      lineHeight: 60,
      title: 'Watermelon',
      title2: '',
      avail: '130',
      contribl: '80%',
      action: 'right',
      position: 'bottom',
      custom: { infoItemBot: true, line: 20 },
    },
    {
      index: 19,
      x: 1060,
      y: 443,
      width: 75,
      height: 90,
      text: '20MB',
      lineNumber: 20,
      lineHeight: 135,
      title: 'Rice',
      title2: '',
      avail: '130',
      contribl: '80%',
      action: 'right',
      position: 'bottom',
      custom: { infoItemBot: true, line: 15 },
    },
    {
      index: 20,
      x: 1115,
      y: 411,
      width: 65,
      height: 28,
      text: '20MB',
      lineNumber: 21,
      lineHeight: 155,
      title: 'Car',
      title2: 'Accessory',
      avail: '130',
      contribl: '80%',
      action: 'right',
      position: 'bottom',
      custom: { infoItemBot: true, backgroundWhite: true },
    },
    {
      index: 21,
      x: 1228,
      y: 310,
      width: 140,
      height: 45,
      text: '20MB',
      lineNumber: 22,
      lineHeight: 315,
      title: 'Imported Fruit',
      title2: '',
      avail: '130',
      contribl: '80%',
      action: 'right',
      position: 'bottom',
      custom: { infoItemBot: true, backgroundWhite: true },
    },
    {
      index: 22,
      x: 1515,
      y: 410,
      width: 85,
      height: 60,
      text: '20MB',
      lineNumber: 23,
      lineHeight: 120,
      title: 'Cold Storage',
      title2: '',
      avail: '130',
      contribl: '80%',
      action: 'left',
      position: 'bottom',
      custom: { backgroundWhite: true },
    },
  ];

  return (
    <div
      style={{
        display: 'grid',
        placeItems: 'center',
      }}
    >
      <div style={{ position: 'relative', width: '1600px', height: '670px' }}>
        <img
          src={imageTAECO}
          alt="Background"
          style={{
            position: 'absolute',
            top: '0px',
            left: '0px',
            width: '1600px',
            objectFit: 'cover',
            margin: '24px',
            border: '1px solid #EEEEEE',
            padding: '100px 0px 50px 0px',
          }}
        />

        <Stage width={1600} height={800}>
          {/* 1000 700 */}
          {_.map(dashboardData, (item) => {
            if (_.get(item, 'position') === 'bottom' || true) {
              return <LayerCustomTop data={item} mapSelectFilter={mapSelectFilter} />; 
            } else {
              return <LayerCustomTop data={item} mapSelectFilter={mapSelectFilter} />;
            }
          })}

          <Layer>
            {/* <Rect x={292} y={205} width={37} height={60} fill="#03a9f4" /> */}
            {/* <Rect x={337} y={205} width={60} height={60} fill="#03a9f4" /> */}
            {/* <Rect x={498} y={205} width={210} height={60} fill="#03a9f4" /> */}
            {/* <Rect x={740} y={194} width={82} height={34} fill="#03a9f4" /> */}
            {/* <Rect x={740} y={235} width={82} height={30} fill="#03a9f4" /> */}

            {/* <Rect x={844} y={213} width={71} height={65} fill="#03a9f4" /> */}
            {/* <Rect x={926} y={199} width={10} height={77} fill="#03a9f4" /> */}
            {/* <Rect x={943} y={199} width={10} height={77} fill="#03a9f4" /> */}

            {/* <Rect x={173} y={313} width={33} height={52} fill="red" /> */}
            {/* <Rect x={207} y={294} width={12} height={71} fill="red" /> */}

            {/* <Rect x={225} y={313} width={29} height={52} fill="red" /> */}
            {/* <Rect x={258} y={313} width={24} height={52} fill="red" /> */}
            {/* <Rect x={285} y={313} width={64} height={52} fill="red" /> */}
            {/* <Rect x={355} y={313} width={52} height={52} fill="red" /> */}
            {/* <Rect x={411} y={313} width={34} height={52} fill="red" /> */}

            {/* <Rect x={442} y={262} width={51} height={50} fill="red" /> */}

            {/* <Rect x={498} y={316} width={27} height={49} fill="red" /> */}
            {/* <Rect x={530} y={316} width={105} height={49} fill="red" /> */}
            {/* <Rect x={638} y={316} width={45} height={49} fill="red" /> */}

            {/* <Rect x={673} y={295} width={37} height={15} fill="red" /> */}
            {/* <Rect x={908} y={287} width={47} height={42} fill="red" /> */}
          </Layer>
        </Stage>
      </div>
    </div>
  );
};

export default AdvanchImage;

const LayerCustomTop = (props) => {
  const { data, mapSelectFilter } = props;
  const {
    x,
    y,
    width,
    height,
    text,
    lineNumber,
    lineHeight,
    title,
    title2 = '',
    action = 'left',

    avail,
    contribl,
    occ,
    inOut,
    arr,
    availPark,
    util,
    revenue,
    sla,
    transaction,
    position = 'top',
    custom, // line ตำแหน่งของ // infoSpan ตำแหน่งของกล่องข้อมูล // boxY ตำแหน่ง text ในกล่อง -> custom ปรับเอง
  } = data;

  console.log('custom', custom);

  let lineX = x + (_.get(custom, 'line') ? _.get(custom, 'line') : width / 2 + 1); // เส้นตรงกลางใช้สูตร ความกว้าง หาร 2 + 1 (1 มาจากความกว้างของเส้นที่กำหนดเป็น 2)
  let lineY = y - lineHeight;

  let lineTextX = lineX - 2;
  let lineTextY = lineY - 1;

  let CircleX = lineX + 1;
  let CircleY = lineY + 3;

  let boxX = x;
  let boxY = y + (_.get(custom, 'boxY') ? _.get(custom, 'boxY') : height / 2 - 5); // ข้อความตรงกลาง ความสูง หาร 2 - 5 (5 มาจากขนาดของตัวอักษร 12px)

  let defaultLeft = 100 - _.get(custom, 'infoSpan', 0);
  let defaultRight = 20 + _.get(custom, 'infoSpan', 0);

  let textX = action === 'left' ? lineTextX - defaultLeft : lineTextX + defaultRight;
  let textY = lineTextY;

  let defaultYText1 = 25 + (title2 ? 15 : 0);
  let defaultYText2 = 40 + (title2 ? 15 : 0);

  let textItemX = textX;

  let backgroundWhite =
    action === 'left'
      ? {
          x: CircleX - 100,
          y: CircleY - 10,
        }
      : {
          x: CircleX - 10,
          y: CircleY - 20,
        };

  if (position === 'bottom') {
    lineY = y + height
    lineTextX = lineX - 6
    lineTextY = lineY + lineHeight - 1
    CircleY = lineY + lineHeight + 3
    defaultLeft = 100 + _.get(custom, 'infoSpan', 0);
    defaultRight = 20 - _.get(custom, 'infoSpan', 0);
    textX = action === 'left' ? lineTextX - defaultLeft : lineTextX + defaultRight;
    textY = lineTextY
    defaultYText1 = 25;
    defaultYText2 = 40;
    textItemX = _.get(custom, 'infoItemBot') ? CircleX - 10 : textX;
    backgroundWhite =
    action === 'left'
      ? {
          x: CircleX - 100,
          y: CircleY - 10,
        }
      : {
          x: CircleX - 10,
          y: CircleY - 20,
        };
  }

  return (
    <Layer>
      {_.get(custom, 'backgroundWhite') && (
        <Rect x={backgroundWhite.x} y={backgroundWhite.y} width={95} height={75} fill="#fff" opacity={1} />
      )}

      <Rect x={x} y={y} width={width} height={height} fill="#D1F5B3" opacity={0.8} cornerRadius={8} />
      <Text x={x} y={boxY} width={width > 35 ? width : 35} text={text} fontSize={12} fill="#327B22" align="center" />

      <Rect x={lineX} y={lineY} width={2} height={lineHeight} fill={'#63BC5C'} />
      <Circle x={CircleX} y={CircleY} radius={10} fill="#63BC5C" />
      <Text x={lineTextX} y={lineTextY} width={15} text={lineNumber} fontSize={12} fill="#FFF" />

      <Text x={textX} y={title2 === '' ? textY : textY - 9} width={150} text={title} fontSize={12} fill="#327B22" />
      {title2 !== '' && <Text x={textX} y={textY + (position === 'bottom' ? 6 : 15)} width={150} text={title2} fontSize={12} fill="#327B22" />}

      {_.map(mapSelectFilter, (item, index) => (
        <>
          {item === 'availableLots' && (
            <>
              <TextCustom
                x={textItemX}
                y={textY + (index === 0 ? defaultYText1 : defaultYText2)}
                text="Avail."
              />
              <TextCustom
                x={textItemX + 35}
                y={textY + (index === 0 ? defaultYText1 : defaultYText2)}
                text={avail}
                color={'#84C575'}
              />
              <TextCustom
                x={textItemX + 55}
                y={textY + (index === 0 ? defaultYText1 : defaultYText2)}
                text="Lots"
              />
            </>
          )}
          {item === 'contributionRate' && (
            <>
              <TextCustom
                x={textItemX}
                y={textY + (index === 0 ? defaultYText1 : defaultYText2)}
                text="%Contrib."
              />
              <TextCustom
                x={textItemX + 57}
                y={textY + (index === 0 ? defaultYText1 : defaultYText2)}
                text={contribl}
                color={'#84C575'}
              />
            </>
          )}
          {item === 'occupancyRate' && (
            <>
              <TextCustom
                x={textItemX}
                y={textY + (index === 0 ? defaultYText1 : defaultYText2)}
                text="Occ"
              />
              <TextCustom
                x={textItemX + 30}
                y={textY + (index === 0 ? defaultYText1 : defaultYText2)}
                text={occ}
                color={'#84C575'}
              />
              <TextCustom
                x={textItemX + 65}
                y={textY + (index === 0 ? defaultYText1 : defaultYText2)}
                text="Rate"
              />
            </>
          )}
           {item === 'inOut' && (
            <>
              <TextCustom
                x={textItemX}
                y={textY + (index === 0 ? defaultYText1 : defaultYText2)}
                text="In/Out"
              />
              <TextCustom
                x={textItemX + 40}
                y={textY + (index === 0 ? defaultYText1 : defaultYText2)}
                text={inOut}
                color={'#84C575'}
              />
              <TextCustom
                x={textItemX + 75}
                y={textY + (index === 0 ? defaultYText1 : defaultYText2)}
                text="Lots"
              />
            </>
          )}
          {item === 'arr' && (
            <>
              <TextCustom
                x={textItemX}
                y={textY + (index === 0 ? defaultYText1 : defaultYText2)}
                text="ARR"
              />
              <TextCustom
                x={textItemX + 30}
                y={textY + (index === 0 ? defaultYText1 : defaultYText2)}
                text={arr}
                color={'#84C575'}
              />
            </>
          )}
          {item === 'availableParkingLots' && (
            <>
              <TextCustom
                x={textItemX}
                y={textY + (index === 0 ? defaultYText1 : defaultYText2)}
                text="Avail."
              />
              <TextCustom
                x={textItemX + 35}
                y={textY + (index === 0 ? defaultYText1 : defaultYText2)}
                text={availPark}
                color={'#84C575'}
              />
              <TextCustom
                x={textItemX + 60}
                y={textY + (index === 0 ? defaultYText1 : defaultYText2)}
                text="Park"
              />
            </>
          )}
          {item === 'utilizationRate' && (
            <>
              <TextCustom
                x={textItemX}
                y={textY + (index === 0 ? defaultYText1 : defaultYText2)}
                text="Util."
              />
              <TextCustom
                x={textItemX + 25}
                y={textY + (index === 0 ? defaultYText1 : defaultYText2)}
                text={util}
                color={'#84C575'}
              />
              <TextCustom
                x={textItemX + 50}
                y={textY + (index === 0 ? defaultYText1 : defaultYText2)}
                text="Rate"
              />
            </>
          )}
          {item === 'revenue' && (
            <>
              <TextCustom
                x={textItemX}
                y={textY + (index === 0 ? defaultYText1 : defaultYText2)}
                text="Rev"
              />
              <TextCustom
                x={textItemX + 30}
                y={textY + (index === 0 ? defaultYText1 : defaultYText2)}
                text={revenue}
                color={'#84C575'}
              />
            </>
          )}
          {item === 'sla' && (
            <>
              <TextCustom
                x={textItemX}
                y={textY + (index === 0 ? defaultYText1 : defaultYText2)}
                text="SLA"
              />
              <TextCustom
                x={textItemX + 30}
                y={textY + (index === 0 ? defaultYText1 : defaultYText2)}
                text={sla}
                color={'#84C575'}
              />
            </>
          )}
           {item === 'transaction' && (
            <>
              <TextCustom 
                x={textItemX}
                y={textY + (index === 0 ? defaultYText1 : defaultYText2)}
                text="Transaction"
              />
              <TextCustom
                x={textItemX + 70}
                y={textY + (index === 0 ? defaultYText1 : defaultYText2)}
                text={transaction}
                color={'#84C575'}
              />
            </>
          )}
        </>
      ))}
    </Layer>
  );
};

const LayerCustomButton = (props) => {
  const { data, mapSelectFilter } = props;
  const {
    x,
    y,
    width,
    height,
    text,
    lineNumber,
    lineHeight,
    title,
    title2 = '',
    action = 'left',

    avail,
    contribl,
    occ,
    inOut,
    arr,

    custom,
    // line ตำแหน่งของ // infoSpan ตำแหน่งของกล่องข้อมูล // boxY ตำแหน่ง text ในกล่อง // infoItemBot ปรับตำแหน่ง ให้ item ไปอยู่ข้างล่าง วงกลม -> custom ปรับเอง
  } = data;

  console.log('custom', custom);

  const lineX = x + (_.get(custom, 'line') ? _.get(custom, 'line') : width / 2 + 1); // เส้นตรงกลางใช้สูตร ความกว้าง หาร 2 + 1 (1 มาจากความกว้างของเส้นที่กำหนดเป็น 2)
  const lineY = y + height;
  const lineTextX = lineX - 6;
  const lineTextY = lineY + lineHeight - 1;

  const CircleX = lineX + 1;
  const CircleY = lineY + lineHeight + 3;

  const boxX = x;
  const boxY = y + (_.get(custom, 'boxY') ? _.get(custom, 'boxY') : height / 2 - 5); // ข้อความตรงกลาง ความสูง หาร 2 - 5 (5 มาจากขนาดของตัวอักษร 12px)

  const defaultLeft = 100 + _.get(custom, 'infoSpan', 0);
  const defaultRight = 20 - _.get(custom, 'infoSpan', 0);

  const textX = action === 'left' ? lineTextX - defaultLeft : lineTextX + defaultRight;
  const textY = lineTextY;

  const defaultYText1 = 25;
  const defaultYText2 = 40;

  const textItemX = _.get(custom, 'infoItemBot') ? CircleX - 10 : textX;

  const backgroundWhite =
    action === 'left'
      ? {
          x: CircleX - 100,
          y: CircleY - 10,
        }
      : {
          x: CircleX - 10,
          y: CircleY - 20,
        };

  return (
    <Layer>
      {_.get(custom, 'backgroundWhite') && (
        <Rect x={backgroundWhite.x} y={backgroundWhite.y} width={95} height={75} fill="#fff" opacity={1} />
      )}

      <Rect x={x} y={y} width={width} height={height} fill="#D1F5B3" opacity={0.8} cornerRadius={8} />
      <Text x={x} y={boxY} width={width > 35 ? width : 35} text={text} fontSize={12} fill="#327B22" align="center" />

      <Rect x={lineX} y={lineY} width={2} height={lineHeight} fill={'#63BC5C'} />
      <Circle x={CircleX} y={CircleY} radius={10} fill="#63BC5C" />
      <Text x={lineTextX} y={lineTextY} width={15} text={lineNumber} fontSize={12} fill="#FFF" />

      <Text x={textX} y={title2 === '' ? textY : textY - 9} width={150} text={title} fontSize={12} fill="#327B22" />
      {title2 !== '' && <Text x={textX} y={textY + 6} width={150} text={title2} fontSize={12} fill="#327B22" />}

      {_.map(mapSelectFilter, (item, index, key) => (
        <>
          {item === 'availableLots' && (
            <>
              <Text
                x={textItemX}
                y={textY + (index === 0 ? defaultYText1 : defaultYText2)}
                width={150}
                text="Avail."
                fontSize={12}
                fill="#ACACAC"
              />
              <Text
                x={textItemX + 35}
                y={textY + (index === 0 ? defaultYText1 : defaultYText2)}
                width={150}
                text={avail}
                fontSize={12}
                fill="#84C575"
              />
              <Text
                x={textItemX + 55}
                y={textY + (index === 0 ? defaultYText1 : defaultYText2)}
                width={150}
                text="Lots"
                fontSize={12}
                fill="#ACACAC"
              />
            </>
          )}
          {item === 'contributionRate' && (
            <>
              <Text
                x={textItemX}
                y={textY + (index === 0 ? defaultYText1 : defaultYText2)}
                width={150}
                text="Contribl."
                fontSize={12}
                fill="#ACACAC"
              />
              <Text
                x={textItemX + 50}
                y={textY + (index === 0 ? defaultYText1 : defaultYText2)}
                width={150}
                text={contribl}
                fontSize={12}
                fill="#84C575"
              />
            </>
          )}
        </>
      ))}
    </Layer>
  );
};

const TextCustom = (props) => {
  const { x, y, text, color = '#ACACAC' } = props;

  return <Text x={x} y={y} width={150} text={text} fontSize={12} fill={color} />;
};
