import React from 'react';
import httpClient from '../../components/axiosClient';

const getTaxDiscount= async () => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.get(`/v3/maintenance/manager/company/${comCode}/gettaxdiscount`);
    if (response.status === 200) {
      return await response.data
    } else {
      return {};
    }
  } catch (error) {
    return {};
  }

}

export default getTaxDiscount;