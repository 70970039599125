import React, { useContext, useEffect, useState } from 'react';
import './css/index.css';
import { Modal, Select, Form, Row, Col } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import _ from 'lodash';
import { fncGetUsers } from '../../../controllers/user/users';
import Button_02 from '../../../components/v2/button_02';
import Button_01 from '../../../components/v2/button_01';
import LabeRequireForm from '../../../components/label-required-form';
import { GetRole } from '../../../controllers/organization/approver';
import WarehouseContext from '../context';
import { errorNotification, successNotification } from '../../../components/v2/notification';
import { saveWarehouseManager, updateWarehouseManager } from '../../../controllers/warehouse/warehouse';

const { Option } = Select;

const ModalResourceApprover = (props) => {
  const intl = useIntl();
  const { state, setState, fnc } = useContext(WarehouseContext);
  const { visible, setVisible, form, record, setRecord } = props;
  const { getFieldDecorator, validateFields, resetFields, getFieldValue } = form;
  const { warehouseId, warehouseManageTypeList } = state;
  const { setTrigger } = setState;
  // console.log('ModalResourceApprover', record);
  const [roleData, setRoleData] = useState([]);
  const [userData, setUserData] = useState([]);
  console.log('ModalResourceApprover', record);
  const comId = localStorage.getItem('comId');
  const memComId = localStorage.getItem('memComId');

  useEffect(() => {
    const getData = async () => {
      const response = await GetRole(comId);
      if (_.size(response) !== 0) {
        setRoleData(_.get(response, 'data'));
      }

      const getuser = await fncGetUsers(memComId, comId, true);
      if (_.size(response) !== 0) {
        setUserData(getuser);
      }
    };
    getData();
  }, []);

  const handleCreate = () => {
    validateFields(async (err, values) => {
      if (err) {
        return;
      }
      console.log('payload', values);

      if (values) {
        const payload = {
          warehouseId: warehouseId,
          userIdList: _.get(values, 'user') || [],
          roleIdList: _.get(values, 'role') || [],
          manageTypeCodeList: _.get(values, 'manageType') || [],
        };
        console.log('payload', payload);
        // return;
        if (_.get(record, 'managerId')) {
          try {
            const response = await updateWarehouseManager(_.get(record, 'managerId'), payload);
            console.log('updateWarehouseManager', response);
            if (_.get(response, 'status') === 200) {
              successNotification(_.get(response.data.status, 'message'));
              utilResetValue();
            } else {
              errorNotification(_.get(response.data.status, 'message'));
            }
          } catch (error) {}
        } else {
          try {
            const response = await saveWarehouseManager(payload);
            console.log('saveWarehouseSetting', response);
            if (_.get(response, 'status') === 200) {
              successNotification(_.get(response.data.status, 'message'));
              utilResetValue();
            } else {
              errorNotification(_.get(response.data.status, 'message'));
            }
          } catch (error) {}
        }
      }
    });
  };

  const utilResetValue = () => {
    setTrigger((current) => !current);
    setVisible(false);
    resetFields();
    setRecord();
  };

  const handleCancel = () => {
    setVisible(false);
    resetFields();
    setRecord();
  };

  return (
    <div>
      <Modal
        title={`${
          record
            ? intl.formatMessage({ id: 'modalApproverTitleEditWarehouse', defaultMessage: 'Edit Warehouse Manager' })
            : intl.formatMessage({ id: 'modalApproverTitleAddWarehouse', defaultMessage: 'Add New Warehouse Manager' })
        }`}
        centered={true}
        visible={visible}
        onCancel={handleCancel}
        onOk={handleCreate}
        footer={[
          <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="back" onClick={handleCancel}>
            <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
          </Button_02>,
          <Button_01
            key="submit"
            type="primary"
            onClick={handleCreate}
            disabled={
              (getFieldValue(`role`) !== undefined && _.size(getFieldValue(`role`)) !== 0) ||
              (getFieldValue(`user`) !== undefined && _.size(getFieldValue(`user`)) !== 0)
                ? false
                : true
            }
          >
            <FormattedMessage id="btnSave" defaultMessage="Save" />
          </Button_01>,
        ]}
      >
        <Form>
          <Row gutter={[24, 2]}>
            <Col span={24}>
              <Form.Item
                label={<LabeRequireForm text={intl.formatMessage({ id: 'modalApproverTextRole', defaultMessage: 'Role' })} req={true} />}
                className="modal-approver-label"
              >
                {getFieldDecorator('role', {
                  initialValue: _.get(record, 'role') ? _.get(record, 'role') : undefined,
                })(
                  <Select
                    placeholder={intl.formatMessage({ id: 'modalApproverPlaceholderRole', defaultMessage: 'Select Role' })}
                    mode="multiple"
                    showSearch
                    filterOption={(input, option) =>
                      option.props.label
                        .toString()
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    className="modal-approver-select-option"
                    optionLabelProp="label"
                    // maxTagCount={3}
                    // maxTagTextLength={15}
                  >
                    {roleData &&
                      roleData.map((item) => (
                        <Option key={item.per_gro_id} label={item.name}>
                          {item.name}
                        </Option>
                      ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label={<LabeRequireForm text={intl.formatMessage({ id: 'modalApproverTextUser', defaultMessage: 'Users' })} req={true} />}
                className="modal-approver-label"
              >
                {getFieldDecorator('user', {
                  initialValue: _.get(record, 'user') ? _.get(record, 'user') : undefined,
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({
                        id: 'modalApproverValidateRequestType',
                        defaultMessage: 'Please Select Manage Or Approve',
                      }),
                    },
                  ],
                })(
                  <Select
                    placeholder={intl.formatMessage({ id: 'modalApproverPlaceholderUser', defaultMessage: 'Select User' })}
                    mode="multiple"
                    showSearch
                    filterOption={(input, option) =>
                      option.props.label
                        .toString()
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    optionLabelProp="label"
                    className="modal-approver-select-option"
                  >
                    {userData &&
                      userData.map((item) => (
                        <Option key={item.mem_com_id} value={`${item.mem_com_id}`} label={item.fullname}>
                          {item.fullname}
                        </Option>
                      ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label={
                  <LabeRequireForm
                    text={intl.formatMessage({ id: 'modalApproverTextManageOrApprove', defaultMessage: 'Manage or Approve' })}
                    req={true}
                  />
                }
                className="modal-approver-label"
              >
                {getFieldDecorator('manageType', {
                  initialValue: _.get(record, 'manageTypeList') ? _.get(record, 'manageTypeList') : undefined,
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({
                        id: 'modalApproverValidateRequestType',
                        defaultMessage: 'Please Select Manage Or Approve',
                      }),
                    },
                  ],
                })(
                  <Select
                    placeholder={intl.formatMessage({
                      id: 'modalApproverPlaceholderManageOrApprove',
                      defaultMessage: 'Select Manage or Approve',
                    })}
                    mode="multiple"
                    showSearch
                    filterOption={(input, option) =>
                      option.props.label
                        .toString()
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    className="modal-approver-select-option"
                    optionLabelProp="label"
                  >
                    {warehouseManageTypeList.map((item) => (
                      <Option key={item.manageCode} value={item.manageCode} label={item.name}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

const ModalWarehouseManager = Form.create({})(ModalResourceApprover);

export default ModalWarehouseManager;
