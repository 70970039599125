import httpClient from '../../components/axiosClient';

const comCode = localStorage.getItem('comCode');

const getFinelist = async (payload) => {
  try {
    const response = await httpClient.post(`/v5/resource/manager/company/${comCode}/fine/get-all-where`, payload);
    if (response.status === 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    return error.response;
  }
};

const getReason = async (payload) => {
  try {
    const response = await httpClient.post(`/v5/resource/manager/company/${comCode}/fine-reason/get-all-where`, payload);
    if (response.status === 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    return error.response;
  }
};

const createFine = async (payload) => {
  try {
    const response = await httpClient.post(`/v5/resource/manager/company/${comCode}/fine/create`, payload);
    if (response.status === 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    return error.response;
  }
};

const updateFine = async (payload, id) => {
  try {
    const response = await httpClient.put(`/v5/resource/manager/company/${comCode}/fine/${id}/update`, payload);
    if (response.status === 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    return error.response;
  }
};

const deleteFine = async (id) => {
  try {
    const response = await httpClient.delete(`/v5/resource/manager/company/${comCode}/fine/${id}/delete`);
    if (response.status === 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    return error.response;
  }
};

export { getReason, getFinelist, createFine, updateFine,deleteFine };
