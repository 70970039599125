import React, { useEffect, useState } from 'react'
import { Modal, Form, Row, Col, Input } from 'antd'
import { useIntl, FormattedMessage } from 'react-intl'
import Button01 from '../../v2/button_01'
import Button02 from '../../v2/button_02'
import LabeRequireForm from '../../label-required-form'
import _ from 'lodash'
import { handleKeyDownDecimal2Fixed, handleKeyDownNumber } from '../../../component-function/fnc-number'
import { getConvertUoMBigger } from '../../../controllers/po/po'

const AddDeliveryItem = (props) => {
  const { visible, setVisible, typeItem, form, record, delivery, setDelivery, selectItemTemp, setSelectItemTemp, typeModal } = props
  const { getFieldDecorator, validateFields, resetFields } = form
  const intl = useIntl()
  const [uomList, setUomList] = useState([]);
  const [checkUOM, setCheckUOM] = useState(true);

  console.log('filterDelivery',record);

  useEffect(() => {
    const getStockList = async () => {
      const unitData = await getConvertUoMBigger(_.get(record, 'itemMasterId'));
      setUomList(_.get(unitData, 'data.data'));
    };
    if (record && visible) {
      getStockList();
    }
  }, [record, visible]);

  useEffect(() => {
    if (_.get(record, 'uomId') && visible === true && _.size(uomList) > 0) {
      const filteruom = _.filter(uomList, (item) => {
        return _.get(item, 'uomId') === _.get(record, 'uomId');
      });
      console.log('filteruom', filteruom, '/value:', _.get(record, 'uomId'), '/uomList:', uomList);
      if (_.get(filteruom[0], 'isDefault') === true) {
        setCheckUOM(true);
      } else {
        setCheckUOM(false);
      }
    }
  }, [visible, uomList]);

  const handleSave = () => {
    validateFields(async (err, values) => {
      if (err) {
        return;
      }
      if (typeItem === "add") {
        const item = {
          ...record,
          countTemp: parseFloat(values.qty),
          itemPoId: _.toString(record.itemMasterId+record.pricePerUnit+record.discountPercentage),
        }
        const mapNewSelect = _.map(selectItemTemp, (e) => {
          return e.itemPoId === _.get(record, 'itemPoId') ? {
            ...e,
            countTemp: e.countTemp - parseFloat(values.qty),
            itemPoId: _.toString(record.itemMasterId+record.pricePerUnit+record.discountPercentage),
          } : e
        })
        setDelivery([...delivery, item])
        setSelectItemTemp(mapNewSelect)
      } else if (typeItem === "edit") {
        const filterDelivery = _.filter(delivery, (e) => { return e.itemPoId === _.get(record, 'itemPoId') })
        // console.log('filterDelivery',record);
        if (parseFloat(values.qty) === 0) {
          const mapNewSelect = _.map(selectItemTemp, (e) => {
            return e.itemPoId === _.get(record, 'itemPoId') ? {
              ...e,
              countTemp: _.get(filterDelivery, '[0].countTemp') + e.countTemp
            } : e
          })
          setDelivery(_.filter(delivery, (e) => { return e.itemPoId !== _.get(record, 'itemPoId') }))
          setSelectItemTemp(mapNewSelect)
        } else {
          const mapNewSelectDeli = _.map(delivery, (e) => {
            return e.itemPoId === _.get(record, 'itemPoId') ? {
              ...e,
              countTemp: parseFloat(values.qty)
            } : e
          })

          const mapNewSelectModalAdd = _.map(selectItemTemp, (e) => {
            return e.itemPoId === _.get(record, 'itemPoId') ? {
              ...e,
              countTemp: _.get(filterDelivery, '[0].countTemp') - parseFloat(values.qty)
            } : e
          })

          const mapNewSelectModalEdit = _.map(selectItemTemp, (e) => {
            let value = 0

            if (parseFloat(values.qty) === _.get(filterDelivery, '[0].countTemp')) {
              value = e.countTemp
            } else {
              value = (_.get(filterDelivery, '[0].countTemp') - parseFloat(values.qty)) + e.countTemp
            }
            console.log('mapvalue',e.itemPoId,'/',_.get(record, 'itemPoId'));
            return e.itemPoId === _.get(record, 'itemPoId') ? {
              ...e,
              countTemp: value
            } : e
          })

          setDelivery(mapNewSelectDeli)
          setSelectItemTemp(mapNewSelectModalEdit)
          // setSelectItemTemp(typeModal === "add" ? mapNewSelectModalAdd : mapNewSelectModalEdit)
        }
      }
      setVisible(false)
      resetFields()
    })
  }

  const handleCancel = () => {
    setVisible(false)
    resetFields()
  }

  const validatorMinMax = (rule, value, callback) => {
    const parsInt = parseFloat(value || 0)
    if (value && typeItem === "add") {
      if (parsInt > _.get(record, 'countTemp')) {
        callback(<FormattedMessage id="modalDeliveryTitleOverthan" defaultMessage="Qty more than item." />)
      } else if (parsInt === 0) {
        callback(<FormattedMessage id="modalDeliveryTitleOverthan0" defaultMessage="Qty not eqaul 0" />)
      } else {
        callback()
      }
    } else if (value && typeItem === "edit") {
      const filterDelivery = _.filter(selectItemTemp, (e) => { return e.itemPoId === _.get(record, 'itemPoId') })
      if (parsInt > (_.get(record, 'countTemp') + _.get(filterDelivery, '[0].countTemp'))) {
        callback(<FormattedMessage id="modalDeliveryTitleOverthan" defaultMessage="Qty more than item." />)
      } else {
        callback()
      }
    } else {
      callback();
    }
  }

  return (
    <Modal
      title={typeItem === 'add' ? intl.formatMessage({ id: `modalDeliveryTitleAddItem`, defaultMessage: 'Add Item' }) :
        intl.formatMessage({ id: `modalDeliveryTitleRemoveItem`, defaultMessage: 'Remove Item' })}
      centered={true}
      width={500}
      visible={visible}
      bodyStyle={{ padding: 'unset' }}
      onCancel={handleCancel}
      footer={[
        <Button02 key="back" btnsize="wd_df" onClick={handleCancel}>
          <FormattedMessage id="userDetailModalBtnCancel" defaultMessage="Cancel" />
        </Button02>,
        <Button01 key="submit" type="primary" btnsize="wd_df" onClick={handleSave} style={{ margin: 'unset' }}>
          <FormattedMessage id="userDetailModalBtnSave" defaultMessage="Save" />
        </Button01>,
      ]}
    >
      <Form form={form} layout="vertical" style={{ padding: '16px' }}>
        <Row gutter={[16]}>
          <Col span={12}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'modalDeliveryColumnsItemCode', defaultMessage: 'Item Code' })} req={false} />
              }
            >
              {_.get(record, 'itemCode')}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'modalDeliveryColumnsItem', defaultMessage: 'Item' })} req={false} />
              }
            >
              {_.get(record, 'itemName')}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16]}>
          <Col span={12}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'modalDeliveryColumnsUnit', defaultMessage: 'Unit' })} req={false} />
              }
            >
              {_.get(record, 'uomName')}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'modalDeliveryColumnsQty', defaultMessage: 'Qty' })} req={true} />
              }
            >
              {getFieldDecorator('qty', {
                initialValue: typeItem === 'add' ? "0" : _.get(record, 'countTemp'),
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'modalDeliveryColumnsQtyValidate', defaultMessage: 'Please Enter Qty' }),
                  },
                  {
                    validator: validatorMinMax
                  }
                ],
              })(
                <Input
                  placeholder={intl.formatMessage({ id: 'modalDeliveryColumnsQtyPlaceholder', defaultMessage: 'Enter Qty' })}
                  onKeyDown={ checkUOM === false ? handleKeyDownDecimal2Fixed : handleKeyDownNumber}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

const ModalAddDeliveryItem = Form.create({
  name: 'assignTeam-form',
  mapPropsToFields() { },
})(AddDeliveryItem);

export default ModalAddDeliveryItem

