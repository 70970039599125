import React from 'react';
import { Checkbox, Row, Col, Icon, Divider, Select, Button, Input, DatePicker } from 'antd';
import _ from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';
import moment from 'moment';
import Button_01 from '../v2/button_01';
import Button_02 from '../v2/button_02';

const { Option } = Select;

const ListItemFilter = (props) => {
  const { onChange, searchGroup, refType,optionsBrand,optionsModel } = props;
  const intl = useIntl();
  const dateFormat = 'DD/MM/YYYY';

  return (
    <div>
      <div style={{ overflowY: 'auto', height: `calc(109vh - 357px)`, padding: '5px 0px 0px 24px' }}>
        <div style={{ padding: '16px 16px 8px 0px' }}>
          <div style={{ marginBottom: '16px' }}>
            <div className="listItemFilterContentHeadText" style={{ marginBottom: '8px' }}>
              <FormattedMessage id="deliveryModalItemFilterBrand" defaultMessage="Brand" />
            </div>
            <div>
              <Select
                allowClear
                showSearch
                onChange={(value) => onChange(value, 'brand')}
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                value={_.get(searchGroup, 'brand')}
                placeholder={intl.formatMessage({
                  id: 'deliveryModalItemFilterBrandPH',
                  defaultMessage: 'Select Brand',
                })}
                style={{ width: '100%' }}
              >
                {_.map(optionsBrand, (item) => (
                <Option value={item.item_brand_id}>{item.item_brand_name}</Option>
              ))}
                {/* <Option value={'Active'}>{'Active'}</Option>
                <Option value={'Inactive'}>{'Inactive'}</Option> */}
              </Select>
            </div>
          </div>

          <div style={{ marginBottom: '16px' }}>
            <div className="listItemFilterContentHeadText" style={{ marginBottom: '8px' }}>
              <FormattedMessage id="deliveryModalItemFilterModel" defaultMessage="Model" />
            </div>
            <div>
              <Select
                allowClear
                showSearch
                onChange={(value) => onChange(value, 'model')}
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                value={_.get(searchGroup, 'model')}
                placeholder={intl.formatMessage({ id: 'deliveryModalItemFilterModelPH', defaultMessage: 'Select Model' })}
                style={{ width: '100%' }}
                disabled={_.size(_.get(searchGroup, 'brand')) > 0 ? false : true}
              >
                {_.map(optionsModel, (item) => (
                <Option value={item.item_model_id}>{item.item_model_name}</Option>
              ))}
                {/* <Option value={'Active'}>{'Active'}</Option>
                <Option value={'Inactive'}>{'Inactive'}</Option> */}
              </Select>
            </div>
          </div>

          <div style={{ marginBottom: '16px' }}>
            <div className="listItemFilterContentHeadText" style={{ marginBottom: '8px' }}>
              <FormattedMessage id="deliveryModalItemFilterItemCode" defaultMessage="Item Code" />
            </div>
            <Input
              allowClear={true}
              onChange={(e) => onChange(e.target.value, 'code')}
              value={_.get(searchGroup, 'code')}
              placeholder={intl.formatMessage({ id: 'deliveryModalItemFilterItemCodePH', defaultMessage: 'Enter Item Code' })}
            />
          </div>

          <div style={{ marginBottom: '16px' }}>
            <div className="listItemFilterContentHeadText" style={{ marginBottom: '8px' }}>
              <FormattedMessage id="deliveryModalItemFilterItemName" defaultMessage="Item Name" />
            </div>
            <div>
              <Input
                allowClear={true}
                onChange={(e) => onChange(e.target.value, 'name')}
                value={_.get(searchGroup, 'name')}
                placeholder={intl.formatMessage({
                  id: 'deliveryModalItemFilterItemNamePH',
                  defaultMessage: 'Enter Item Name',
                })}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListItemFilter;
