import React from 'react'
import CollapseCustom from '../../../components/collapse-custom'
import { useIntl, FormattedMessage } from 'react-intl'
import ReportReconcileActionColumns from './action-columns'
import { Input, Form, Select, DatePicker, Row, Col } from 'antd'
import LabeRequireForm from '../../../components/label-required-form'
import _ from 'lodash'


const { RangePicker } = DatePicker
const { Option } = Select

const ReportReconcileAction = (props) => {
  const intl = useIntl()
  const { search, onFilterOrder, actionColumns, refTypeList } = props

  const formFilter = () => {
    return (
      <Form colon={false} className='deliveryForm'>
        <Row gutter={[24, 2]}>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'reportReconcileColumnsRefType', defaultMessage: 'Reference Type' })}
                  req={false}
                />
              }
            >
              <Select
                allowClear={true}
                placeholder={intl.formatMessage({ id: 'reportReconcilePHRefType', defaultMessage: 'Select Reference Type' })}
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                showSearch
                onChange={(value) => onFilterOrder(value, 'refType')}
              >
                {
                  _.map(refTypeList, (item) => (
                    <Option key={item.code}>
                      {item.label}
                    </Option>
                  ))
                }
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'reportReconcileColumnsRefNo', defaultMessage: 'Reference No.' })}
                  req={false}
                />
              }
            >
              <Input
                allowClear={true}
                placeholder={intl.formatMessage({ id: 'reportReconcilePHRefNo', defaultMessage: 'Enter Reference No' })}
                onChange={(e) => onFilterOrder(e.target.value, 'refNo')}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'reportReconcileColumnsTaxNo', defaultMessage: 'Tax No.' })}
                  req={false}
                />
              }
            >
              <Input
                allowClear={true}
                placeholder={intl.formatMessage({ id: 'reportReconcilePHTaxNo', defaultMessage: 'Enter Tax No.' })}
                onChange={(e) => onFilterOrder(e.target.value, 'taxNo')}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 2]}>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'reportReconcileColumnsCustomerCode', defaultMessage: 'Customer or Vendor Code' })}
                  req={false}
                />
              }
            >
              <Input
                allowClear={true}
                placeholder={intl.formatMessage({ id: 'reportReconcilePHCustomerCode', defaultMessage: 'Enter Customer or Vendor Code' })}
                onChange={(e) => onFilterOrder(e.target.value, 'customerCode')}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'reportReconcileColumnsCustomerName', defaultMessage: 'Customer or Vendor Name' })}
                  req={false}
                />
              }
            >
              <Input
                allowClear={true}
                placeholder={intl.formatMessage({ id: 'reportReconcilePHCustomerName', defaultMessage: 'Enter Customer or Vendor Name' })}
                onChange={(e) => onFilterOrder(e.target.value, 'customerName')}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  };


  return (
    <div style={{ marginTop: '16px' }}>
      <CollapseCustom
        label={intl.formatMessage({ id: `btnFilter`, defaultMessage: 'Filter' })}
        extra={
          <div style={{ display: 'flex' }}>
            <RangePicker
              allowClear={true}
              placeholder={[intl.formatMessage({ id: 'reportReconcilePHExpiredDateFrom', defaultMessage: 'Select From Date' }), intl.formatMessage({ id: 'reportReconcilePHExpiredDateTo', defaultMessage: 'Select To Date' })]}
              filterOption={(input, option) =>
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={(value) => onFilterOrder(value, 'searchdDate')}
              style={{ width: '100%' }}
              format={['DD/MM/YYYY', 'DD/MM/YYYY']}
            />
            <ReportReconcileActionColumns
              actionColumns={actionColumns}
            />
          </div>
        }
      >
        {formFilter()}
      </CollapseCustom>
    </div >
  )
}

export default ReportReconcileAction
