import React, { useState, useContext, useEffect, useRef, useCallback, useMemo } from 'react';
import { GoogleMap, LoadScript, useLoadScript, Polyline, Marker, OverlayView } from '@react-google-maps/api';
import { useIntl, FormattedMessage } from 'react-intl';
import _ from 'lodash';
import httpClient from '../../../components/axiosClient';
import { Icon, Row, Avatar } from 'antd';

// const delay = 2;
// const Marker = ({ children }) => children;

export default (props) => {
  const { value, fnc } = props;
  const { addressData, assigneeData, taskData, informationData, keys } = value;
  const intl = useIntl();
  const mapRef = useRef();
  const libraries = ['places'];

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: localStorage.getItem('GEO_APIKEY'),
    libraries,
  });

  const comCode = localStorage.getItem('comCode');

  const [trackingConfig, setTrackingConfig] = useState();

  const address = addressData && addressData ? addressData : {};

  const assignee = assigneeData && assigneeData ? assigneeData : [];

  const taskDetailId = _.get(taskData, 'taskId');

  const pathTaskStatus = informationData && _.get(informationData, 'pathTaskStatus');

  const resourceActive =
    assignee &&
    assignee.filter((e) => {
      return e.active !== 1;
    });

  const resourceSelect = resourceActive && resourceActive.filter((item) => !keys.includes(item.memComId));

  const [pathPolylineData, setPathPolylineData] = useState([]);

  useEffect(() => {
    getPolyline();
  }, [resourceSelect.length]);

  useEffect(() => {
    const intervalId = setInterval(() => {}, 300000);
    return () => clearInterval(intervalId);
  }, []);

  const getPixelPositionOffset = (width, height) => ({
    x: -(width / 2),
    y: -(height / 2),
  });

  const ResourceComponent = useMemo(
    () => (props) => {
      const { resourceSelectNew } = props;
      return (
        resourceSelectNew &&
        resourceSelectNew.map((item, index) => (
          <OverlayView
            id={item.memComId}
            position={{ lat: parseFloat(item.lat), lng: parseFloat(item.lng) }}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            getPixelPositionOffset={getPixelPositionOffset}
          >
            <Avatar key={item.memComId} src={item.pathImage} size="large" style={{ border: '3px #fff solid', zIndex: 1 }} />
          </OverlayView>
        ))
      );
    },
    [assignee]
  );

  const getPolyline = async () => {
    if (resourceSelect.length !== 0) {
      const memcomId = resourceSelect && resourceSelect !== undefined ? resourceSelect[0].memComId : undefined;
      if (resourceSelect.length === 1 && memcomId !== undefined) {
        try {
          const response = await httpClient.get(`/v3/task/manager/company/${comCode}/directions/task/${taskDetailId}/member/${memcomId}`);
          if (response.status == 200) {
            setPathPolylineData(response.data.data.directions);
            setTrackingConfig(_.reverse(response.data.data.trackingConfig));
          }
        } catch (error) {
          setPathPolylineData([]);
        }
      }
    } else {
      setPathPolylineData([]);
    }
  };

  const onMapLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);

  if (loadError) return 'Error loading maps';
  if (!isLoaded) return 'Loading Maps';

  const mapContainerStyle = {
    width: '100%',
    height: '400px',
    position: 'relative',
  };

  const defaultMapOption = {
    fullscreenControl: false,
    fullscreenControlOptions: false,
    streetViewControl: false,
    clickableIcons: false,
    zoomControl: true,
    gestureHandling: 'cooperative',
    mapTypeControl: true,
    mapTypeControlOptions: {
      style: 1,
      position: 3,
    },
  };

  const options = {
    strokeColor: '#1890ff',
    strokeOpacity: 0.8,
    strokeWeight: 6,
    fillColor: '#1890ff',
    fillOpacity: 1,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: 30000,
    zIndex: 1,
  };

  return (
    <div style={{ padding: '0px 24px 24px 24px' }}>
      <div className="div-map-address-toLocation">
        <Icon type="environment" style={{ fontSize: '16px', color: '#1D3557', paddingRight: '6px' }} />
        <span>{address ? address.toLocation : '-'}</span>
      </div>
      <div>
        <GoogleMap
          id={`map-tabs`}
          mapContainerStyle={mapContainerStyle}
          center={{ lat: parseFloat(address.lat), lng: parseFloat(address.lng) }}
          zoom={12}
          onLoad={onMapLoad}
          options={defaultMapOption}
        >
          <Marker
            key={address.toLocationId}
            position={{ lat: parseFloat(address.lat), lng: parseFloat(address.lng) }}
            icon={{
              url: pathTaskStatus,
              scaledSize: new window.google.maps.Size(40, 40),
            }}
          />

          {pathPolylineData.map(
            (object, index) =>
              index > 0 && (
                <Polyline
                  key={`polyline-${index}`}
                  path={[{ ...pathPolylineData[index] }, { ...pathPolylineData[index - 1] }]}
                  options={{
                    strokeColor: object.speedColor,
                    strokeWeight: 3,
                  }}
                />
              )
          )}

          <ResourceComponent resourceSelectNew={resourceSelect} />
        </GoogleMap>
      </div>
      <div className="div-map-address-mapSpeed">
        {trackingConfig &&
          trackingConfig.map &&
          trackingConfig.map((item) => (
            <>
              <div className="speed-color60" style={{ backgroundColor: item.codeColor }} />
              <span className="text-control">
                <FormattedMessage id={item.codeTxtFirst} defaultMessage="Speed" />
              </span>
              <span className="text-control">{item.codeTextCondition}</span>
              <span className="text-control" style={{ paddingRight: '20px' }}>
                <FormattedMessage id={item.codeTxtSecond} defaultMessage="km/hrs" />
              </span>
            </>
          ))}
      </div>
    </div>
  );
};
