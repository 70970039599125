import React, { useEffect, useState } from 'react';
import ModalRegister from '../../../../components/asset-components/add/index';
import { Card, Row, Col, Form, Modal } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import AssetSummaryContext from './context';
import CardCategory from './category';
import {
  getInitialAssetClass,
  getTypeAsset,
  getBrandAsset,
  getModelAsset,
  getStatusAsset,
} from '../../../../controllers/asset-resource/asset-controller';
import _ from 'lodash';
import './css/index.css';
import AssetSummaryReport from './asset-report';
import RegisterViewEdit from '../../../../components/asset-components/view-edit/index';
import { PageSettings } from '../../../../config/page-settings';
import Button_01 from '../../../../components/v2/button_01';

let date = new Date();

const TicketAssetModal = (props) => {
  const { visibleAsset, closeAsset, setSelectAssest, setVisibleAsset } = props;
  const [visibleRegister, setVisibleRegister] = useState(false);
  const [visibleViewAndEdit, setVisibleViewAndEdit] = useState(false);
  const [typeModal, setTypeModal] = useState('view');
  const [defaultEdit, setDefaultEdit] = useState();

  const [assetSelect, setAssetSelect] = useState();
  const [assetSelectObj, setAssetSelectObj] = useState([]);
  const [toggle, setToggle] = useState(false);

  const [scrollTableX, setScrollTableX] = useState(1200);

  const [orgData, setOrgData] = useState([]);
  const [orgId, setOrgId] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [fliterByColumn, setFliterByColumn] = useState('all');
  const [fieldChange, setFieldChange] = useState('');
  const [total, setTotal] = useState();
  const [fieldSort, setFieldSort] = useState();
  const [orderSort, setOrderSort] = useState();
  const [paginationPage, setPaginationPage] = useState(1);
  const [paginationSize, setPaginationSize] = useState(10);
  const [loading, setLoading] = useState(false);
  const [mainTrigger, setMainTrigger] = useState(false);

  const [dataAsset, setDataAsset] = useState({
    type: [],
    brand: [],
    model: [],
    status: [],
    assetObj: [],
  });

  const [dataChecked, setDataChecked] = useState({
    type: [],
    brand: [],
    model: [],
    status: [],
  });

  //----------------------------------------asset advance---------------------------------------------------//

  const [purChaseStartDate, setPurChaseStartDate] = useState();
  const [purChaseEndDate, setPurChaseEndDate] = useState();
  const [purChaseMinPrice, setPurChaseMinPrice] = useState();
  const [purChaseMaxPrice, setPurChaseMaxPrice] = useState();
  const [price, setPrice] = useState([]);

  const [checkedType, setCheckedType] = useState([]);
  const [checkedBrand, setCheckedBrand] = useState([]);
  const [checkedModel, setCheckedModel] = useState([]);
  const [checkedStatus, setCheckedStatus] = useState([]);

  const [triggerItem, setTriggerItem] = useState();

  const intl = useIntl();

  const handleToggle = () => {
    setToggle((current) => !current);
  };

  const handleView = (value, type) => {
    setVisibleViewAndEdit(true);
    setTypeModal(type);
    setDefaultEdit(value);
  };

  return (
    <PageSettings.Consumer>
      {({ checkPermissionAction }) => (
        <AssetSummaryContext.Provider
          value={{
            state: {
              fliterByColumn,
              fieldChange,
              orderSort,
              fieldSort,
              paginationSize,
              assetSelect,
              assetSelectObj,
              toggle,
              scrollTableX,
              paginationPage,
              total,
              dataSource,
              loading,
              orgData,
              orgId,
              purChaseStartDate,
              purChaseEndDate,
              purChaseMinPrice,
              purChaseMaxPrice,
              mainTrigger,
              price,
              checkedType,
              checkedBrand,
              checkedModel,
              checkedStatus,
              triggerItem,
              dataAsset,
              dataChecked,
            },
            setState: {
              setAssetSelect,
              setAssetSelectObj,
              setVisibleRegister,
              setScrollTableX,
              setPaginationPage,
              setPaginationSize,
              setOrderSort,
              setFieldSort,
              setDataSource,
              setFieldChange,
              setOrgData,
              setOrgId,
              setFliterByColumn,
              setPurChaseStartDate,
              setPurChaseEndDate,
              setPurChaseMinPrice,
              setPurChaseMaxPrice,
              setTotal,
              setLoading,
              setPrice,
              setCheckedType,
              setCheckedBrand,
              setCheckedModel,
              setCheckedStatus,
              setTriggerItem,
              setDataAsset,
              setDataChecked,
              setSelectAssest,
              setVisibleAsset,
            },
            fnc: {
              handleToggle,
              handleView,
            },
          }}
        >
          <div>
            {checkPermissionAction('P36PG1C1', 'P36PG1C1A1') ? (
              <Modal
                visible={visibleAsset}
                onCancel={() => closeAsset()}
                centered={true}
                title={intl.formatMessage({
                  id: 'CreateTicket',
                  defaultMessage: 'Submit a Ticket',
                })}
                zIndex={999}
                width={'70%'}
                style={{ marginTop: '15px' }}
                bodyStyle={{ padding: '0px 0px', height: 'calc(100vh - 230px)' }}
                footer={[
                  <div style={{ paddingTop: '10px' }}>
                    <Button_01 key="submit" type="primary" btnsize="wd_df" onClick={() => closeAsset()} style={{ marginTop: '10px' }}>
                      <FormattedMessage id="btnClose" defaultMessage="Close" />
                    </Button_01>
                  </div>,
                ]}
              >
                <div>
                  <Row gutter={[0, 0]}>
                    <Col span={toggle ? 0 : 6}>
                      <CardCategory />
                    </Col>
                    <Col span={toggle ? 24 : 18}>
                      <AssetSummaryReport />
                    </Col>
                  </Row>
                </div>
              </Modal>
            ) : null}

            <ModalRegister visible={visibleRegister} setVisible={setVisibleRegister} setMainTrigger={setMainTrigger} />

            <RegisterViewEdit
              visible={visibleViewAndEdit}
              setVisible={setVisibleViewAndEdit}
              typeModal={typeModal}
              defaultEdit={defaultEdit}
              setMainTrigger={setMainTrigger}
            />
          </div>
        </AssetSummaryContext.Provider>
      )}
    </PageSettings.Consumer>
  );
};

export default TicketAssetModal;
