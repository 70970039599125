import httpClient from '../../components/axiosClient';


const getListTask = async (comId, fromDate, toDate, orgId, fieldChang, selectSearchData, paginationPage, paginationSize, orderSort, fieldSort, status, customerId,invoiceId) => {

  const body = {
    fromDate: fromDate,
    utilDate: toDate,
    orgId: orgId,
    search: fieldChang,
    searchBy: selectSearchData,
    paging: paginationPage,
    rowsPerPages: paginationSize,
    ordertype: orderSort,
    orderby: fieldSort,
    statussearchBy: status,
    customer: customerId,
    invoiceId:invoiceId

  }

  try {
    const response = await httpClient.post(`/v3/task/manager/company/${comId}/gettaskfull`, body);
    if (response.status === 200) {

      return response.data
    } else {
      return [];
    }
  } catch (error) { return []; }
}

const getTeamTask = async (comId, memId, memComId) => {
  try {
    const response = await httpClient.get(`/v3/resource/manager/company/${comId}/team-leader-all/${memId}/member/${memComId}`);
    if (response.status === 200) {
      const newOrganization = response.data.data.organization.map(item => {
        return {
          orgId: parseInt(item.orgId),
          orgName: item.orgName,
          orgSelected: item.orgSelected
        }
      })
      return newOrganization;
    } else {
      return [];
    }
  } catch (error) { return []; }
}

const getStatusTask = async (comCode) => {
  try {
    const response = await httpClient.get(`/v3/task/manager/company/${comCode}/getstatusall`);
    if (response.status === 200) {
      return response.data
    } else {
      return [];
    }
  } catch (error) { return []; }
}



export {
  getListTask, getTeamTask, getStatusTask
}