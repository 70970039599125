import React, { useState, useCallback } from 'react';
import { useIntl, FormattedMessage } from 'react-intl'
import { Form, Modal, Input, notification } from 'antd';
import Button01 from '../../../components/v2/button_01';
import Button02 from '../../../components/v2/button_02';
import Api from '../../../components/httpClient';
import httpClient from '../../../components/axiosClient';
import styles from './css/personalInfomation-resetPassword.css';

const client = Api();
const qs = require('query-string');

const ModalFormComponent = ({ 
  visibleModalResetPassword, 
  setVisibleModalResetPassword,
  personalMemId,
  form 
}) => {
  const { getFieldDecorator, getFieldValue } = form;
  const intl = useIntl();
  const userName = localStorage.getItem('username');
  const comCode = localStorage.getItem('comCode');

  const [validateNewPassword, setValidateNewPassword] = useState();
  const [validateFirstPassword, setValidateFirstPassword] = useState();

  const handleConfirmResetPassword = async (e) => {
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      // checkCurrentPassword(values.newPassword, values.confirmPassword);

      Modal.confirm({
        className: "personal-reset-password-modal-confirm",
        centered: true,
        icon: null,
        title: intl.formatMessage({id: 'lblResetPassword', defaultMessage: 'Are you sure you want to Reset Password ?'}),
        okText: intl.formatMessage({id: 'modalConfirmBtnYes', defaultMessage: 'Yes'}),
        cancelText: intl.formatMessage({id: 'modalConfirmBtnNo', defaultMessage: 'No'}),
        okButtonProps: {
          style: {
            width: '96px',
          },
          type: "primary",
        },
        cancelButtonProps: {
          style: {
            width: '96px',
          },
          type: "danger",
        },
        async onOk() {
          try {
            const body = {
              "password": values.newPassword,
              "confirmPassword": values.confirmPassword, 
            };
            const response = await httpClient.put(`/v3/resource/manager/company/${comCode}/users/${personalMemId}/reset-password`, body);
            if (response.status === 200) {
              successNotification(response.data.status.message);
              setVisibleModalResetPassword(false);
              form.resetFields();
            } else {
              errorNotification(response.data.status.message);
              setVisibleModalResetPassword(false);
              form.resetFields();
            }
          } catch (error) {
            errorNotification(error.response.data.error_description);
            setVisibleModalResetPassword(false);
            form.resetFields();
          }
        }
      });
    })
  } 

  const handleCancelResetPassword = () => {
    form.validateFields(async (err, values) => {
      if (err) {
        return;
      }
      form.resetFields();
    });
    form.resetFields();
    setVisibleModalResetPassword(false);
  }

  const compareToNewPassword = (rule, value, callback) => {
    if (value && value !== getFieldValue('confirmPassword')) {
      callback(
        <span>
          {intl.formatMessage({id: 'userDetailResetPasswordValidateConfirmPasswordInconsistent', defaultMessage: 'Two passwords that you enter is inconsistent.'})}
        </span>
      );
    } else {
      callback();
    }
    // else if (value && value === getFieldValue('confirmPassword')) {
    //   setValidateFirstPassword("success");
    //   callback();
    // }
  }

  const compareToFirstPassword = (rule, value, callback) => {
    if (value && value !== getFieldValue('newPassword')) {
      callback(
        <span>
          {intl.formatMessage({id: 'userDetailResetPasswordValidateConfirmPasswordInconsistent', defaultMessage: 'Two passwords that you enter is inconsistent.'})}
        </span>
      );
    } else {
      callback();
    }
  }

  const checkCurrentPassword = async (valuePassword, valueConfirmPassword) => {
    const data = {
      client_id: `${process.env.REACT_APP_KEYCLOAK_CLIENT_ID}`,
      grant_type: 'password',
      client_secret: `${process.env.REACT_APP_KEYCLOAK_CLIENT_SECRET}`,
      username: userName,
      password: valueConfirmPassword,
    };

    const axiosConfig = {
      Header: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    try {
      client.defaults.baseURL = `${process.env.REACT_APP_KEYCLOAK_URL}`;
      client.defaults.headers = {
        Authorization: '',
        'Content-Type': 'application/x-www-form-urlencoded',
      };
      const response = await client.post(
        '/auth/realms/master/protocol/openid-connect/token',
        qs.stringify(data),
        axiosConfig,
      );

      if (response.status === 200) {
        Modal.warning({
          className: "personal-reset-password-modal-confirm-warning",
          centered: true,
          title: intl.formatMessage({id: 'lblNewPasswordSame', defaultMessage: 'Your new password is the same as the old password.'}),
          okText: intl.formatMessage({id: 'btnOK', defaultMessage: 'OK'}),
          okButtonProps: {
            style: {
              width: '96px',
            },
            type: "primary",
          }
        });
      }
    } catch (err) {
      Modal.confirm({
        className: "personal-reset-password-modal-confirm",
        centered: true,
        icon: null,
        title: intl.formatMessage({id: 'lblResetPassword', defaultMessage: 'Are you sure you want to Reset Password ?'}),
        okText: intl.formatMessage({id: 'modalConfirmBtnYes', defaultMessage: 'Yes'}),
        cancelText: intl.formatMessage({id: 'modalConfirmBtnNo', defaultMessage: 'No'}),
        okButtonProps: {
          style: {
            width: '96px',
          },
          type: "primary",
        },
        cancelButtonProps: {
          style: {
            width: '96px',
          },
          type: "danger",
        },
        async onOk() {
          try {
            const body = {
              "password": valuePassword,
              "confirmPassword": valueConfirmPassword, 
            };
            const response = await httpClient.put(`/v3/resource/manager/company/${comCode}/users/${personalMemId}/reset-password`, body);
            if (response.status === 200) {
              successNotification(response.data.status.message);
              setVisibleModalResetPassword(false);
            } else {
              errorNotification(response.data.status.message);
              setVisibleModalResetPassword(false);
            }
          } catch (error) {
            errorNotification(error.response.data.error_description);
            setVisibleModalResetPassword(false);
          }
        }
      });
    }
  }

  const successNotification = (message) => {
    notification.success({
      message: message,
    });
  };
  
  const errorNotification = (message) => {
    notification.error({
      message: message,
    });
  };

  return(
    <Modal
      className="personal-infomation-resetPassword-madal"
      title={
        <FormattedMessage id="userDetailResetPasswordMadalTitle" defaultMessage="Reset Password" />
      }
      centered="ture"
      visible={visibleModalResetPassword}
      okText={intl.formatMessage({id: 'btnSave', defaultMessage: 'Save'})}
      cancelText={intl.formatMessage({id: 'btnCancel', defaultMessage: 'Cancel'})}
      onCancel={handleCancelResetPassword}
      onOk={handleConfirmResetPassword}
      footer={[
        <Button02 style={{margin : '0px 0px 0px 10px'}} key="back" fontsize="sm" btnsize="wd_df" onClick={handleCancelResetPassword}>
          <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
        </Button02>,
        <Button01 key="submit" fontsize="sm" btnsize="wd_df" type="primary" onClick={() => handleConfirmResetPassword()}>
          <FormattedMessage id="btnSave" defaultMessage="Save" />
        </Button01>,
      ]}
    >
      <Form
        layout="vertical"
        onSubmit={handleConfirmResetPassword}
      >
        <Form.Item
          label={
            <FormattedMessage id="userDetailResetPasswordMadalLblNewPassword" defaultMessage="New Password" />
          }
          hasFeedback
          // validateStatus={validateNewPassword}
        >
          {getFieldDecorator('newPassword', {
            rules: [
              { 
                required: true, 
                message: intl.formatMessage({id: "userDetailResetPasswordValidateNewPassword", defaultMessage: "Please enter your new password."})
              },
              {
                min: 6,
                message: intl.formatMessage({id: "userDetailResetPasswordValidateCheckCharacterLengthNewPassword", defaultMessage: 'The password must be at least 6 leght.'}),
              },
              // {
              //   validator: compareToNewPassword,
              // },
            ],
          })(
            <Input.Password 
              placeholder={intl.formatMessage({id: 'userDetailResetPasswordMadalHintNewPassword', defaultMessage: 'Enter new password'})} 
            />
          )}
        </Form.Item>
        <Form.Item
          label={
            <FormattedMessage id="userDetailResetPasswordMadalLblConfirmPassword" defaultMessage="Confirm Password" />
          }
          hasFeedback
          // validateStatus={validateFirstPassword}
          // help="Should be combination of numbers & alphabets"
        >
          {getFieldDecorator('confirmPassword', {
            rules: [
              { 
                required: true, 
                message: intl.formatMessage({id: "userDetailResetPasswordValidateConfirmPassword", defaultMessage: "Please enter your confirm password."})
              },
              {
                validator: compareToFirstPassword,
              },
            ],
          })(
            <Input.Password 
              placeholder={intl.formatMessage({id: 'userDetailResetPasswordMadalHintConfirmPassword', defaultMessage: 'Enter confirm password'})} 
            />
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
}

const ModalForm = Form.create({ name: 'modal_form' })(ModalFormComponent);

export default ModalForm;