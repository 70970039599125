import React, { useEffect, useState } from 'react';
import { Modal, Form, Row, Col, Select, InputNumber, Input, DatePicker, Spin } from 'antd';
import './css/index.css';
import { useIntl, FormattedMessage } from 'react-intl';
import Button01 from '../../v2/button_01';
import Button02 from '../../v2/button_02';
import { saveEditVehical, calculateVehicle } from '../../../controllers/load-and-route/api';
import _ from 'lodash';
import moment from 'moment';
import { errorNotification, successNotification } from '../../v2/notification';

const { Option } = Select;

const ModalVehicleEdit = (props) => {
  const {
    visible,
    setVisible,
    form,
    licenseId,
    setTrigger,
    handleLicensePlatePopupScroll,
    handleLicensePlateChangeSearch,
    handleLicensePlateChange,
    licenseplateLoading,
    licenseplateList,
    setLicensePlateList,
    shippingDate,
    data,
  } = props;
  const intl = useIntl();
  const { getFieldDecorator, getFieldValue, setFieldsValue, resetFields, validateFields } = form;
  // const [carData, setCarData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadedVal, setLoadedVal] = useState();
  const [triggerAPI, setTriggerAPI] = useState(false);
  console.log('shippingDate ::', shippingDate);

  const LabeRequire = (props) => {
    const { text, req } = props;
    return (
      <span>
        {text}&nbsp;
        {req ? <span style={{ fontSize: '12px', color: '#ff0000' }}>*</span> : ''}
      </span>
    );
  };

  const onCreate = (load) => {
    validateFields(async (err, values) => {
      if (err) {
        return;
      }
      setLoading(true);
      const formateStartDate = _.get(values, 'startDate')
        ? moment(_.get(values, 'startDate'), 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm')
        : undefined;
      const formateDueDate = _.get(values, 'dueDate')
        ? moment(_.get(values, 'dueDate'), 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm')
        : undefined;

      const body = {
        id: _.get(values, 'licensePlate'),
        length: _.get(values, 'length'),
        height: _.get(values, 'height'),
        width: _.get(values, 'width'),
        planStartDate: formateStartDate,
        planDueDate: formateDueDate,
        maxCapacity: _.get(values, 'capicity'),
        maxWeight: _.get(values, 'weight'),
        loaded: load,
        shippingDate: moment(shippingDate).format('YYYY-MM-DD'),
      };

      const response = await saveEditVehical(body, data.assetId);
      if (response.status === 200) {
        successNotification(response.data.status.message);
        setTrigger((current) => !current);
      } else {
        errorNotification(response.data.status.message);
      }
      setLoading(false);
      setVisible(false);
      setLoadedVal();
      resetFields();
    });
  };

  const onCanecel = () => {
    setVisible(false);
    resetFields();
    setLoadedVal();
  };

  // useEffect(() => {
  //   let arrNew = [...licenseplateList];
  //   if (data && visible === true) {
  //     arrNew.unshift(data);
  //     console.log('arrNew', arrNew);
  //     setLicensePlateList(arrNew);
  //   }
  // }, [data, visible]);

  useEffect(() => {
    if (licenseId) {
      // console.log('unixStart unixEnd licenseId::', licenseId)

      const idPlate = getFieldValue('licensePlate');
      const DataFilter =
        licenseplateList &&
        licenseplateList.filter((item) => {
          return item.id === idPlate;
        });
      console.log('DataFilter', DataFilter);
      // console.log('check licenseId::', idPlate, DataFilter, licenseplateList, _.size(DataFilter) !== 0, idPlate && _.size(DataFilter) !== 0)

      if (idPlate && _.size(DataFilter) !== 0) {
        // console.log('unixStart unixEnd before ::', _.get(DataFilter, '[0].planStartDate'), _.get(DataFilter, '[0].planDueDate'))

        const unixStart = _.get(DataFilter, '[0].planStartDate')
          ? moment(_.get(DataFilter, '[0].planStartDate'), 'YYYY-MM-DD HH:mm')
          : undefined;
        const unixEnd = _.get(DataFilter, '[0].planDueDate') ? moment(_.get(DataFilter, '[0].planDueDate'), 'YYYY-MM-DD HH:mm') : undefined;
        // console.log('unixStart unixEnd ::', unixStart, unixEnd)

        // setFieldsValue({
        //   ['licensePlate']: _.get(DataFilter, '[0].id')
        // });
        // setFieldsValue({
        //   ['loaded']: _.get(DataFilter, '[0].loaded')
        // });
        setFieldsValue({
          ['capicity']: _.get(DataFilter, '[0].maxCapacity') ? parseFloat(_.get(DataFilter, '[0].maxCapacity')) : undefined,
        });
        setFieldsValue({
          ['width']: _.get(DataFilter, '[0].width') ? parseFloat(_.get(DataFilter, '[0].width')) : undefined,
        });
        setFieldsValue({
          ['length']: _.get(DataFilter, '[0].length') ? parseFloat(_.get(DataFilter, '[0].length')) : undefined,
        });
        setFieldsValue({
          ['height']: _.get(DataFilter, '[0].height') ? parseFloat(_.get(DataFilter, '[0].height')) : undefined,
        });
        setFieldsValue({
          ['weight']: _.get(DataFilter, '[0].maxWeight') ? parseFloat(_.get(DataFilter, '[0].maxWeight')) : undefined,
        });
        setFieldsValue({
          ['startDate']: unixStart ? unixStart : undefined,
        });
        setFieldsValue({
          ['dueDate']: unixEnd ? unixEnd : undefined,
        });
      }
    }
  }, [getFieldValue('licensePlate'), licenseplateList]);

  useEffect(() => {
    
    const calculate = async () => {
      const asset = getFieldValue('licensePlate');
      const width = getFieldValue('width');
      const length = getFieldValue('length');
      const height = getFieldValue('height');
      const body = {
        assetId: asset,
        length: length,
        height: height,
        width: width,
      };
      if (width && length && height) {
        const response = await calculateVehicle(body);
        setLoadedVal(_.get(response, 'data.data.loadedSet'));
        setFieldsValue({
          ['loaded']: _.get(response, 'data.data.loaded'),
        });
      }
    };
    if (visible === true) {
      calculate();
    }
    
  }, [getFieldValue('width'), getFieldValue('length'), getFieldValue('height'), getFieldValue('licensePlate')]);

  const validatorStartDate = (rule, value, callback) => {
    const startDate = value;
    const dueDate = getFieldValue(`dueDate`);
    const formateStartDate = moment(startDate, 'YYYY-MM-DD HH:mm')
      .format('YYYY-MM-DD HH:mm')
      .toString();
    const formateDueDate = moment(dueDate, 'YYYY-MM-DD HH:mm')
      .format('YYYY-MM-DD HH:mm')
      .toString();
    if (startDate && dueDate) {
      if (startDate > dueDate) {
        callback(
          <FormattedMessage id="monitorTabDetailModalDateTimeValidatorDueDate" defaultMessage="Start date should not less than due date" />
        );
      } else {
        if (formateStartDate === formateDueDate) {
          callback(
            <FormattedMessage id="monitorTabDetailModalDateTimeValidatorStartTime2" defaultMessage="Cannot select the same date and time" />
          );
        } else {
          callback();
        }
      }
    } else {
      callback();
    }
  };

  const validatorDueDate = (rule, value, callback) => {
    const startDate = getFieldValue(`startDate`);
    const dueDate = value;
    const formateStartDate = moment(startDate, 'YYYY-MM-DD HH:mm:ss')
      .format('YYYY-MM-DD HH:mm')
      .toString();
    const formateDueDate = moment(dueDate, 'YYYY-MM-DD HH:mm:ss')
      .format('YYYY-MM-DD HH:mm')
      .toString();

    if (startDate && dueDate) {
      if (startDate > dueDate) {
        callback(
          <FormattedMessage id="monitorTabDetailModalDateTimeValidatorDueDate" defaultMessage="Start date should not less than due date" />
        );
      } else {
        if (formateStartDate === formateDueDate) {
          callback(
            <FormattedMessage id="monitorTabDetailModalDateTimeValidatorStartTime2" defaultMessage="Cannot select the same date and time" />
          );
        } else {
          callback();
        }
      }
    } else {
      callback();
    }
  };

  // const setOptionLicense = (item) => {
  //   return ();
  // }
  const setOptionLicense = (item) => {
    return (
      <Option
        key={item.id}
        label={`${item.serialNo !== null ? item.serialNo : ''} ${item.serialNo !== null ? '·' : ''} ${item.assetClassName}`}
        value={item.id}
      >
        {item.serialNo} {item.serialNo !== null ? '·' : ''} {item.assetClassName}
      </Option>
    );
  };

  return (
    <div>
      <Modal
        title={intl.formatMessage({ id: `planningEditVehicle`, defaultMessage: 'Edit Vehicle' })}
        visible={visible}
        onOk={() => onCreate(loadedVal)}
        onCancel={onCanecel}
        centered={true}
        width={650}
        bodyStyle={{ padding: '17px 24px 24px 24px' }}
        footer={[
          <div key="group">
            <Button02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={onCanecel} disabled={loading}>
              <FormattedMessage id="userDetailModalBtnCancel" defaultMessage="Cancel" />
            </Button02>
            <Button01
              key="submit"
              type="primary"
              btnsize="wd_df"
              onClick={loading === false ? () => onCreate(loadedVal) : null}
              style={{ marginRight: 'unset' }}
              disabled={loading}
            >
              <FormattedMessage id="userDetailModalBtnSave" defaultMessage="Save" />
            </Button01>
          </div>,
        ]}
      >
        <Spin spinning={loading}>
          <Form>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Form.Item
                  className="edit-vehicle-form-item"
                  label={
                    <LabeRequire text={intl.formatMessage({ id: `modalEditVehicleFormVehicle`, defaultMessage: 'Vehicle' })} req={true} />
                  }
                >
                  {getFieldDecorator('licensePlate', {
                    initialValue: _.get(data, 'assetId'),
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({
                          id: `modalEditVehicleFormlicensePlateValidate`,
                          defaultMessage: 'Please Select License Plate',
                        }),
                      },
                    ],
                  })(
                    <Select
                      placeholder={intl.formatMessage({
                        id: `modalEditVehicleFormlicensePlaceHolder`,
                        defaultMessage: 'Select License Plate',
                      })}
                      showSearch
                      defaultActiveFirstOption={false}
                      onPopupScroll={handleLicensePlatePopupScroll}
                      onSearch={handleLicensePlateChangeSearch}
                      onChange={handleLicensePlateChange}
                      loading={licenseplateLoading}
                      optionLabelProp="label"
                      filterOption={false}
                    >
                      {licenseplateList && licenseplateList.map((item) => setOptionLicense(item))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  className="edit-vehicle-form-item"
                  label={
                    <LabeRequire
                      text={intl.formatMessage({ id: `modalEditVehicleFormCapicity`, defaultMessage: 'Maximum Capacity (%)' })}
                      req={true}
                    />
                  }
                >
                  {getFieldDecorator('capicity', {
                    initialValue: _.get(data, 'maxCapacity') ? parseFloat(_.get(data, 'maxCapacity')) : undefined,
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({
                          id: `modalEditVehicleFormCapicityValidate`,
                          defaultMessage: 'Please Input Maximum Capacity',
                        }),
                      },
                    ],
                  })(
                    <InputNumber
                      style={{ width: '100%' }}
                      placeholder={intl.formatMessage({ id: `modalEditVehicleFormCapicityPlaceHolder`, defaultMessage: 'Capacity' })}
                      min={0}
                      max={100}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  className="edit-vehicle-form-item"
                  label={
                    <LabeRequire
                      text={intl.formatMessage({ id: `modalEditVehicleFormWeight`, defaultMessage: 'Maximum Weight (kg)' })}
                      req={false}
                    />
                  }
                >
                  {getFieldDecorator('weight', {
                    initialValue: _.get(data, 'maxWeight') ? parseFloat(_.get(data, 'maxWeight')) : undefined,
                    rules: [
                      {
                        required: false,
                        message: intl.formatMessage({
                          id: `modalEditVehicleFormWeightValidate`,
                          defaultMessage: 'Please Input Maximum Weight',
                        }),
                      },
                    ],
                  })(
                    <InputNumber
                      style={{ width: '100%' }}
                      placeholder={intl.formatMessage({ id: `modalEditVehicleFormWeightPlaceHolder`, defaultMessage: 'Weight' })}
                      min={0}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={6}>
                <Form.Item
                  className="edit-vehicle-form-item"
                  label={
                    <LabeRequire text={intl.formatMessage({ id: `modalEditVehicleFormWidth`, defaultMessage: 'Width (m.)' })} req={true} />
                  }
                >
                  {getFieldDecorator('width', {
                    initialValue: _.get(data, 'width') ? parseFloat(_.get(data, 'width')) : undefined,
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({ id: `modalEditVehicleFormWidthValidate`, defaultMessage: 'Please Input Width' }),
                      },
                    ],
                  })(
                    <InputNumber
                      style={{ width: '100%' }}
                      placeholder={intl.formatMessage({ id: `modalEditVehicleFormWidthPlaceHolder`, defaultMessage: 'Width' })}
                      min={0}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  className="edit-vehicle-form-item"
                  label={
                    <LabeRequire
                      text={intl.formatMessage({ id: `modalEditVehicleFormLength`, defaultMessage: 'length (m.)' })}
                      req={true}
                    />
                  }
                >
                  {getFieldDecorator('length', {
                    initialValue: _.get(data, 'length') ? parseFloat(_.get(data, 'length')) : undefined,
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({ id: `modalEditVehicleFormLengthValidate`, defaultMessage: 'Please Input Length' }),
                      },
                    ],
                  })(
                    <InputNumber
                      style={{ width: '100%' }}
                      placeholder={intl.formatMessage({ id: `modalEditVehicleFormLengthPlaceHolder`, defaultMessage: 'length' })}
                      min={0}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  className="edit-vehicle-form-item"
                  label={
                    <LabeRequire
                      text={intl.formatMessage({ id: `modalEditVehicleFormHeight`, defaultMessage: 'Height (m.)' })}
                      req={true}
                    />
                  }
                >
                  {getFieldDecorator('height', {
                    initialValue: _.get(data, 'height') ? parseFloat(_.get(data, 'height')) : undefined,
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({ id: `modalEditVehicleFormHeightValidate`, defaultMessage: 'Please Input Height' }),
                      },
                    ],
                  })(
                    <InputNumber
                      style={{ width: '100%' }}
                      placeholder={intl.formatMessage({ id: `modalEditVehicleFormHeightPlaceHolder`, defaultMessage: 'Height' })}
                      min={0}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  className="edit-vehicle-form-item"
                  label={
                    <LabeRequire
                      text={intl.formatMessage({ id: `modalEditVehicleFormLoaded`, defaultMessage: 'Loaded (%)' })}
                      req={false}
                    />
                  }
                >
                  {getFieldDecorator('loaded', {
                    initialValue: _.get(data, 'loaded'),
                    rules: [
                      {
                        required: false,
                        message: intl.formatMessage({ id: `modalEditVehicleFormLoadedValidate`, defaultMessage: 'Please Input Loaded' }),
                      },
                    ],
                  })(
                    <Input
                      placeholder={intl.formatMessage({ id: `modalEditVehicleFormLoadedPlaceHolder`, defaultMessage: 'Loaded (%)' })}
                      disabled={true}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Form.Item
                  className="edit-vehicle-form-item"
                  label={
                    <LabeRequire
                      text={intl.formatMessage({ id: `planningTextStartDateandTime`, defaultMessage: 'Start Date and Time' })}
                      req={false}
                    />
                  }
                >
                  {getFieldDecorator('startDate', {
                    initialValue: _.get(data, 'planStartDateFormat') ? moment(_.get(data, 'planStartDateFormat')) : undefined,
                    rules: [
                      {
                        required: false,
                        message: intl.formatMessage({
                          id: `modalEditVehicleFormStartDateValidate`,
                          defaultMessage: 'Please Select Start Date',
                        }),
                      },
                      {
                        validator: validatorStartDate,
                      },
                    ],
                  })(
                    <DatePicker
                      style={{ width: '100%' }}
                      placeholder={intl.formatMessage({
                        id: `modalEditVehicleFormStartDatePlaceHolder`,
                        defaultMessage: 'Start Date And Time',
                      })}
                      showTime={{ format: 'HH:mm' }}
                      format="YYYY-MM-DD HH:mm"
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  className="edit-vehicle-form-item"
                  label={
                    <LabeRequire
                      text={intl.formatMessage({ id: `planningTextDueDateandTime`, defaultMessage: 'Due Date and Time' })}
                      req={false}
                    />
                  }
                >
                  {getFieldDecorator('dueDate', {
                    initialValue: _.get(data, 'planDueDateFormat') ? moment(_.get(data, 'planDueDateFormat')) : undefined,
                    rules: [
                      {
                        required: false,
                        message: intl.formatMessage({
                          id: `modalEditVehicleFormDueDateValidate`,
                          defaultMessage: 'Please Select Due Date',
                        }),
                      },
                      {
                        validator: validatorDueDate,
                      },
                    ],
                  })(
                    <DatePicker
                      style={{ width: '100%' }}
                      placeholder={intl.formatMessage({
                        id: `modalEditVehicleFormDueDatePlaceHolder`,
                        defaultMessage: 'Due Date And Time',
                      })}
                      // showTime={{ format: 'hh:mm A' }}
                      showTime={{ format: 'HH:mm' }}
                      format="YYYY-MM-DD HH:mm"
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Modal>
    </div>
  );
};
const ModalVehicle = Form.create({
  name: 'modal_form',
})(ModalVehicleEdit);

export default ModalVehicle;
