import React, { useEffect, useState } from 'react';
import { Modal, Tabs } from 'antd';
import Button01 from '../../../../components/v2/button_01';
import { FormattedMessage, useIntl } from 'react-intl';
import ViewDetail from './detail';
import ViewDescription from './description';
import ViewNote from './note';
import { getTicketDetail, getTicketNote } from '../../../../controllers/ticket/ticket';
import _ from 'lodash';
import ModalComment from './comment';
import ReferenceTab from '../../../../components/reference';

const { TabPane } = Tabs;

const ViewModal = (props) => {
  const { visible, setVisible, ticketId ,datarecord,setDataRecord} = props;
  const [viewData, setViewData] = useState();
  const [visibleComment, setVisibleComment] = useState(false);
  const [commentData, setCommentData] = useState();
  const [noteData, setNoteData] = useState([]);
  const [trigger, setTrigger] = useState(false);
  const [tab, setTab] = useState('detail');
  const [refItem, setRefItem] = useState([]);
  const intl = useIntl();

  useEffect(() => {
    const getById = async () => {
      const response = await getTicketDetail(ticketId);
      const getNote = await getTicketNote(ticketId);
      setViewData(_.get(response, 'data.data'));
      setNoteData(_.get(getNote, 'data.data.data.noteList'));
    };
    if (ticketId && visible) {
      getById();
    }
  }, [ticketId, trigger, visible]);

  const handleClose = () => {
    setVisible(false);
    setTab('detail');
    setRefItem([]);
    setDataRecord([]);
  };

  const TitleModal = () => {
    return (
      <div>
        <FormattedMessage id="ticket" defaultMessage="Ticket" />・{_.get(viewData, 'ticketNo')}
      </div>
    );
  };

  const hanldeClickMap = (lat, lng) => {
    window.open(`https://www.google.com/maps/search/?api=1&query=${lat},${lng}`, '_blank');
  };

  const onTabChange = (value) => {
    setTab(value);
  };

  console.log('RRF',datarecord);

  return (
    <Modal
      title={<TitleModal />}
      visible={visible}
      onCancel={handleClose}
      bodyStyle={{ padding: 'unset' }}
      centered={true}
      width={892}
      footer={[
        <Button01 key="submit" type="primary" btnsize="wd_df" onClick={handleClose}>
          <FormattedMessage id="btnClose" defaultMessage="Close" />
        </Button01>,
      ]}
    >
      <div style={{ height: '480px' }}>
        <Tabs animated={false} style={{ }} className="viewTicketTab" onChange={onTabChange} activeKey={tab}>
          <TabPane className="viewTicketTab" tab={<FormattedMessage id="TabDetail" defaultMessage="Detail" />} key="detail">
            <ViewDetail viewData={viewData} setVisibleComment={setVisibleComment} setCommentData={setCommentData} />
          </TabPane>
          <TabPane tab={<FormattedMessage id="TabDescription" defaultMessage="Description" />} key="description">
            <ViewDescription viewData={viewData} hanldeClickMap={hanldeClickMap} />
          </TabPane>
          <TabPane tab={<FormattedMessage id="TabNote" defaultMessage="Note" />} key="note">
            <ViewNote viewData={viewData} noteData={noteData} hanldeClickMap={hanldeClickMap} setTrigger={setTrigger} />
          </TabPane>
          <TabPane tab={intl.formatMessage({ id: `purchesModalTitleReference`, defaultMessage: 'Reference' })} key="1">
            <ReferenceTab
              visiblemodal={visible}
              selectItem={refItem}
              setSelectItem={setRefItem}
              typeRef={'ticket'}
              dataform={datarecord}
              typeModal={'view'}
            />
          </TabPane>
        </Tabs>
      </div>
      <ModalComment
        visible={visibleComment}
        setVisible={setVisibleComment}
        commentData={commentData}
        viewData={viewData}
        setTrigger={setTrigger}
      />
    </Modal>
  );
};

export default ViewModal;
