import React, { useEffect, useState } from 'react'
import { Modal, Form, Row, Col, Switch, Divider, AutoComplete, Input, Select } from 'antd'
import { useIntl, FormattedMessage } from 'react-intl'
import Button_01 from '../../../../../components/v2/button_01'
import Button_02 from '../../../../../components/v2/button_02'
import LabeRequireForm from '../../../../../components/label-required-form'
import { handleKeyDownNumber } from '../../../../../component-function/fnc-number'
import { getCustomer, getCustomerContact } from '../../../../../controllers/customer-vendor/customer-vendor'
import _ from 'lodash'
import { useDebounce } from '../../../../../controllers/debounce'
import CustomerLocation from '../../../../../components/modal-select-customer-location/index'
import { useAppContext } from '../../../../../includes/indexProvider'
import { v4 as uuidv4 } from 'uuid';

const { Option } = Select

const DeliveryEditOrder = (props) => {
  const { visible, setVisible, form, orderData, setEditData, statusAddress, setStatusAddress, handleSaveEdit } = props
  const { getFieldDecorator, validateFields, resetFields, setFieldsValue, getFieldValue } = form;
  const intl = useIntl()
  const app = useAppContext();
  const latlng = _.get(app, 'state.latlngCompany')
  const [customerList, setCustomerList] = useState([])
  const [searchCustomer, setSearchCustomer] = useState("")
  const [selectCustomer, setSelectCustomer] = useState()
  const debouceSearchBranch = useDebounce(searchCustomer, 1000)
  const [contactList, setContactList] = useState([])
  const [selectContact, setSelectContact] = useState()

  const [visibleMapCusotmer, setVisibleMapCusotmer] = useState(false)
  const [selectFromAddress, setSelectFromAddress] = useState([]);
  const [createFromData, setCreateFromData] = useState();
  const [fromOutSide, setFromOutSide] = useState();
  const [triggerFrom, setTriggerFrom] = useState(false);
  const [visibleAddNewCompany, setVisibleAddNewCompany] = useState(false);
  const [statusFromAdd, setStatuFromsAdd] = useState('in');
  const [keyMenu, setKeyMenu] = useState([]);
  const [centerFrom, setCenterFrom] = useState(latlng);
  const [positionFrom, setPositionFrom] = useState(latlng);
  const [visibleInfoWindowFrom, setVisibleInfoWindowFrom] = useState(false);

  const [visibleMapContact, setVisibleMapContact] = useState(false)
  const [selectToAddress, setSelectToAddress] = useState([]);
  const [createToData, setCreateToData] = useState();
  const [toOutSide, setToOutSide] = useState();
  const [triggerTo, setTriggerTo] = useState(false);
  const [visibleAddNewCustomer, setVisibleAddNewCustomer] = useState(false);
  const [statusToAdd, setStatusToAdd] = useState('in');
  const [keyMenuTo, setKeyMenuTo] = useState([]);
  const [centerTo, setCenterTo] = useState(latlng);
  const [positionTo, setPositionTo] = useState(latlng);
  const [visibleInfoWindowTo, setVisibleInfoWindowTo] = useState(false);

  const [customerSwitch, setCustomerSwitch] = useState(false)
  const [recipientSwitch, setRecipientSwitch] = useState(false)
console.log('orderData',orderData);
  useEffect(() => {
    if (orderData && visible) {
      if (Object.keys(_.get(orderData, 'sourceDetail')).length !== 0) {
        const formLocation = {
          address: _.get(orderData, 'sourceDetail.address'),
          fullAddress: _.get(orderData, 'sourceDetail.description'),
          lat: parseFloat(_.get(orderData, 'sourceDetail.lat')),
          lng: parseFloat(_.get(orderData, 'sourceDetail.lng')),
          name: _.get(orderData, 'sourceDetail.address_name'),
          addressBookId: _.get(orderData, 'sourceDetail.company_address_book_id') ? _.get(orderData, 'sourceDetail.company_address_book_id') : uuidv4(),
          status: _.get(orderData, 'sourceDetail.company_address_book_id') ? "customer_address" : "normal_address"
        }
        setSelectFromAddress([formLocation]);
        // setCustomerSwitch(_.get(orderData, 'sourceDetail.company_address_book_id') ? true : false)
        setCreateFromData(formLocation)
        setCenterFrom({ lat: parseFloat(_.get(orderData, 'sourceDetail.lat')), lng: parseFloat(_.get(orderData, 'sourceDetail.lng')), })
        setPositionFrom({ lat: parseFloat(_.get(orderData, 'sourceDetail.lat')), lng: parseFloat(_.get(orderData, 'sourceDetail.lng')), })
      }
      const toLocation = {
        address: _.get(orderData, 'destinationDetail.address'),
        fullAddress: _.get(orderData, 'destinationDetail.description'),
        lat: parseFloat(_.get(orderData, 'destinationDetail.lat')),
        lng: parseFloat(_.get(orderData, 'destinationDetail.lng')),
        name: _.get(orderData, 'destinationDetail.address_name'),
        addressBookId: _.get(orderData, 'destinationDetail.customer_address_book_id') ? _.get(orderData, 'destinationDetail.customer_address_book_id') : uuidv4(),
        status: _.get(orderData, 'destinationDetail.customer_address_book_id') ? "customer_address" : "normal_address"
      }
      setSearchCustomer(_.get(orderData, 'customerDetail.comtomerName'))
      setSelectCustomer(_.get(orderData, 'customerDetail.customerId') ? _.get(orderData, 'customerDetail') : undefined)
      setSelectContact(_.get(orderData, 'contactDetail.customerContactId') ? _.get(orderData, 'contactDetail') : undefined)
      // setCustomerSwitch(_.get(orderData, 'sourceDetail.company_address_book_id') || _.get(orderData, 'destinationDetail.customer_address_book_id') ? true : false)
      // setRecipientSwitch(_.get(orderData, 'destinationDetail.customer_address_book_id') ? true : false)
      setSelectToAddress([toLocation]);
      setCreateToData(toLocation)
      setCenterTo({ lat: parseFloat(_.get(orderData, 'destinationDetail.lat')), lng: parseFloat(_.get(orderData, 'destinationDetail.lng')), })
      setPositionTo({ lat: parseFloat(_.get(orderData, 'destinationDetail.lat')), lng: parseFloat(_.get(orderData, 'destinationDetail.lng')), })
      setFieldsValue({
        ['customerName']: _.get(orderData, 'customerDetail.customerId') ? _.get(orderData, 'customerDetail.customerId') : _.get(orderData, 'customerDetail.comtomerName'),
        ['customerPhone']: _.get(orderData, 'customerDetail.phone'),
        ['recipientName']: _.get(orderData, 'contactDetail.customerContactId') ? _.get(orderData, 'contactDetail.customerContactId') : _.get(orderData, 'contactDetail.customerContactName'),
        ['recipientPhone']: _.get(orderData, 'contactDetail.customerContactPhone'),
        ['parcel']: _.size(_.get(orderData, 'pacelDetails')),
        ['refNo']: _.get(orderData, 'referenceNo'),
        ['remark']: _.get(orderData, 'remark'),
      });
    }
  }, [orderData, visible])

  console.log("selectCustomer", customerSwitch, recipientSwitch, selectCustomer, selectContact)

  const handleSave = () => {
    validateFields(async (err, values) => {
      if (err) {
        return;
      }
      const body = {
        ...orderData,
        "customerDetail": {
          "customerId": _.get(selectCustomer, 'customerId') || undefined,
          "comtomerName": _.get(selectCustomer, 'customerId') ? _.get(selectCustomer, 'comtomerName') : _.get(values, 'customerName'),
          "businessType": _.get(selectCustomer, 'businessType') || undefined,
          "customerType": _.get(selectCustomer, 'customerType') || undefined,
          "phone": _.get(values, 'customerPhone'),
          "profileImg": _.get(selectCustomer, 'profileImg') || undefined,
          "position": _.get(selectCustomer, 'position') || undefined,
          "toSave": customerSwitch,
        },
        "sourceDetail": {
          "company_address_book_id": _.get(createFromData, 'status') !== 'normal_address' ? _.get(createFromData, 'addressBookId') : undefined,
          "address_name": _.get(createFromData, 'name'),
          "address": _.get(createFromData, 'address'),
          "lat": _.get(createFromData, 'lat'),
          "lng": _.get(createFromData, 'lng'),
          "description": _.get(createFromData, 'fullAddress'),
          "selectedFromDestination": _.get(createFromData, 'status') !== 'normal_address' ? true : false
        },
        "contactDetail": {
          "customerContactEmail": _.get(selectContact, 'customerContactEmail') || undefined,
          "customerContactId": _.get(selectContact, 'customerContactId') || undefined,
          "customerContactName": _.get(selectContact, 'customerContactId') ? _.get(selectContact, 'customerContactName') : _.get(values, 'recipientName'),
          "customerContactPhone": _.get(values, 'recipientPhone'),
          "position": _.get(selectContact, 'position') || undefined,
          "remark": _.get(selectContact, 'remark') || undefined,
          "toSave": recipientSwitch,
        },
        "destinationDetail": {
          "customer_address_book_id": _.get(createToData, 'status') !== 'normal_address' ? _.get(createToData, 'addressBookId') : undefined,
          "address_name": _.get(createToData, 'name'),
          "address": _.get(createToData, 'address'),
          "lat": _.get(createToData, 'lat'),
          "lng": _.get(createToData, 'lng'),
          "description": _.get(createToData, 'fullAddress'),
        },
        "remark": _.get(values, 'remark'),
        "referenceNo": _.get(values, 'refNo'),
        "parcel": _.get(values, 'parcel'),
      }
      setEditData(body)
      setVisible(false)
      // if (statusAddress)
      handleSaveEdit(body)
    })
  }

  const handleCancel = () => {
    setVisible(false)
    resetFields()
    setSearchCustomer("")
    setSelectCustomer()
    setSelectContact()
    setCreateFromData();
    setSelectFromAddress();
    setStatuFromsAdd('in');
    setCreateToData();
    setSelectToAddress();
    setStatusToAdd('in');
    setContactList([])
    setCustomerSwitch(false)
    setRecipientSwitch(false)
    setStatusAddress()
  }

  useEffect(() => {
    const getCustomerList = async () => {
      const body = { "searchBy": "all", "searchVal": searchCustomer, "page": 1, "limit": 100, "orderBy": "" }
      const response = await getCustomer(body)
      setCustomerList(_.get(response, 'customers'))
    }
    getCustomerList()
  }, [debouceSearchBranch])

  const handleSearchCustomer = (value) => {
    setSearchCustomer(value);
  }

  const handleChangeCustomer = (value) => {
    const filterCustomer = _.filter(customerList, (item) => {
      return item.customerId === value
    })
    if (_.size(filterCustomer) > 0) {
      setSelectCustomer(_.get(filterCustomer, '[0]'))
      setCustomerSwitch(false)
      setFieldsValue({
        ['customerPhone']: _.get(filterCustomer, '[0].phone'),
      });
    } else {
      setSelectCustomer()
      setSelectContact()
      setContactList([])
      setCreateToData()
      setRecipientSwitch(false)
      setFieldsValue({
        ['recipientName']: undefined,
        ['recipientPhone']: undefined,
        ['recipientAddress']: undefined,
      });
    }
  }


  useEffect(() => {
    const getCustomerContactList = async () => {
      const body = {
        "pageNumber": 1,
        "limit": 10,
        "orderType": "asc",
        "searchObj": { "customerContactName": "", "customerContactEmail": "", "customerContactPhone": "", "position": "" },
        "customerId": _.get(selectCustomer, 'customerId')
      }
      const response = await getCustomerContact(body)
      setContactList(_.get(response, 'data.list'))
    }
    if (selectCustomer) {
      getCustomerContactList()
    }
  }, [selectCustomer])

  const handleChangeContact = (value) => {
    const filterContact = _.filter(contactList, (item) => {
      return item.customerContactId === value
    })
    if (_.size(filterContact) > 0) {
      setSelectContact(_.get(filterContact, '[0]'))
      setRecipientSwitch(false)
      setFieldsValue({
        ['recipientPhone']: _.get(filterContact, '[0].customerContactPhone'),
      });
    } else {
      setSelectContact()
      setFieldsValue({
        ['recipientPhone']: undefined,
      });
    }
  }

  console.log("filterContact", getFieldValue('recipientPhone'))

  useEffect(() => {
    if (createFromData) {
      setFieldsValue({
        ['customerAddress']: _.get(createFromData, 'name') + ' · ' + _.get(createFromData, 'address'),
      });
    }
  }, [createFromData])


  useEffect(() => {
    if (createToData) {
      setFieldsValue({
        ['recipientAddress']: _.get(createToData, 'name') + ' · ' + _.get(createToData, 'address'),
      });
    }
  }, [createToData])

  const disbleContact = (customerId, customerswitch, contactId) => {
    let disbled = true
    if (contactId) {
      disbled = true
    } else if (customerswitch === false && !customerId) {
      disbled = true
    } else if (customerswitch === false && customerId) {
      disbled = false
    } else if (customerswitch === true && !customerId) {
      disbled = false
    } else if (!contactId && customerId) {
      disbled = false
    }
    return disbled
  }


  return (
    <Modal
      title={intl.formatMessage({ id: `orderModalTilteEdit`, defaultMessage: 'Edit Order' })}
      centered={true}
      width={600}
      visible={visible}
      onCancel={handleCancel}
      destroyOnClose={true}
      bodyStyle={{ padding: 'unset', height: 'calc(100vh - 400px)', overflowY: 'auto' }}
      footer={[
        <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={() => handleCancel()} margin=" unset">
          <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
        </Button_02>,
        <Button_01 key="submit" type="primary" btnsize="wd_df" onClick={() => handleSave()}>
          <FormattedMessage id="btnSave" defaultMessage="Save" />
        </Button_01>,
      ]}
    >
      <Form className='deliveryForm' colon={false} form={form}>
        <Row style={{ padding: '12px 24px' }}>
          <Col span={12} style={{ color: '#1D3557', fontSize: '14px', fontWeight: 600 }}>
            <FormattedMessage id="orderModalTilteCustomer" defaultMessage="Customer" />
          </Col>
          <Col span={12} style={{ justifyContent: 'end', display: 'flex' }}>
            <div>
              <FormattedMessage id="btnSave" defaultMessage="Save" />
            </div>
            <div style={{ marginLeft: '8px' }}>
              <Switch disabled={selectCustomer ? true : false} checked={customerSwitch} onChange={() => setCustomerSwitch(cur => !cur)} />
            </div>
          </Col>
        </Row>
        <Divider type='horizontal' style={{ margin: 'unset' }} />
        <div style={{ padding: '12px 24px' }}>
          <Row gutter={[16, 8]}>
            <Col span={12}>
              <Form.Item
                label={<LabeRequireForm text={intl.formatMessage({ id: 'orderModalName', defaultMessage: 'Name' })} req={true} />}
              >
                {getFieldDecorator('customerName', {
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'orderModalReqName', defaultMessage: 'Please enter name' }),
                    },
                  ],
                })(
                  <AutoComplete
                    placeholder={intl.formatMessage({ id: 'orderModalPlaceholderName', defaultMessage: 'Enter Name' })}
                    onSearch={handleSearchCustomer}
                    onChange={handleChangeCustomer}
                    dataSource={customerList}
                  >
                    {
                      _.map(customerList, (item) =>
                        <Option value={item.customerId}>
                          {item.customerName}
                        </Option>
                      )
                    }
                  </AutoComplete>
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={<LabeRequireForm text={intl.formatMessage({ id: 'orderModalPhone', defaultMessage: 'Phone Number' })} req={true} />}
              >
                {getFieldDecorator('customerPhone', {
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'orderModalReqPhone', defaultMessage: 'Please enter phone number' }),
                    },
                  ],
                })(
                  <Input
                    onKeyDown={handleKeyDownNumber}
                    maxLength={10}
                    placeholder={intl.formatMessage({ id: 'orderModalPlaceholderPhone', defaultMessage: 'Enter Phone Number' })}
                  >
                  </Input>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 8]}>
            <Col span={24}>
              <Form.Item
                label={<LabeRequireForm text={intl.formatMessage({ id: 'orderModalAddress', defaultMessage: 'Address' })} req={true} />}
              >
                {getFieldDecorator('customerAddress', {
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'orderModalReqAddress', defaultMessage: 'Please enter address' }),
                    },
                  ],
                })(
                  <Input
                    placeholder={intl.formatMessage({ id: 'orderModalPlaceholderAddress', defaultMessage: 'Enter Address' })}
                    readOnly={true}
                    onClick={() => setVisibleMapCusotmer(true)}
                  >
                  </Input>
                )}
              </Form.Item>
            </Col>
          </Row>
        </div>
        <Row style={{ padding: '12px 24px' }}>
          <Col span={12} style={{ color: '#1D3557', fontSize: '14px', fontWeight: 600 }}>
            <FormattedMessage id="orderModalTilteRecipient" defaultMessage="Recipient" />
          </Col>
          <Col span={12} style={{ justifyContent: 'end', display: 'flex' }}>
            <div>
              <FormattedMessage id="btnSave" defaultMessage="Save" />
            </div>
            <div style={{ marginLeft: '8px' }}>
              {getFieldDecorator('recipientSwitch', {
              })(
                <Switch disabled={disbleContact(_.get(selectCustomer, 'customerId'), customerSwitch, _.get(selectContact, 'customerContactId'))} checked={recipientSwitch} onChange={() => setRecipientSwitch(cur => !cur)} />
              )}
            </div>
          </Col>
        </Row>
        <Divider type='horizontal' style={{ margin: 'unset' }} />
        <div style={{ padding: '12px 24px' }}>
          <Row gutter={[16, 8]}>
            <Col span={12}>
              <Form.Item
                label={<LabeRequireForm text={intl.formatMessage({ id: 'orderModalName', defaultMessage: 'Name' })} req={true} />}
              >
                {getFieldDecorator('recipientName', {
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'orderModalReqName', defaultMessage: 'Please enter name' }),
                    },
                  ],
                })(
                  <AutoComplete
                    placeholder={intl.formatMessage({ id: 'orderModalPlaceholderName', defaultMessage: 'Enter Name' })}
                    onChange={handleChangeContact}
                    dataSource={contactList}
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {
                      _.map(contactList, (item) =>
                        <Option value={item.customerContactId}>
                          {item.customerContactName}
                        </Option>
                      )
                    }
                  </AutoComplete>
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={<LabeRequireForm text={intl.formatMessage({ id: 'orderModalPhone', defaultMessage: 'Phone Number' })} req={true} />}
              >
                {getFieldDecorator('recipientPhone', {
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'orderModalReqPhone', defaultMessage: 'Please enter phone number' }),
                    },
                  ],
                })(
                  <Input
                    placeholder={intl.formatMessage({ id: 'orderModalPlaceholderPhone', defaultMessage: 'Enter Phone Number' })}
                    onKeyDown={handleKeyDownNumber}
                    maxLength={10}
                  >
                  </Input>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 8]}>
            <Col span={24}>
              <Form.Item
                label={<LabeRequireForm text={intl.formatMessage({ id: 'orderModalAddress', defaultMessage: 'Address' })} req={true} />}
              >
                {getFieldDecorator('recipientAddress', {
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'orderModalReqAddress', defaultMessage: 'Please enter address' }),
                    },
                  ],
                })(
                  <Input
                    placeholder={intl.formatMessage({ id: 'orderModalPlaceholderAddress', defaultMessage: 'Enter Address' })}
                    readOnly={true}
                    onClick={() => setVisibleMapContact(true)}
                  >
                  </Input>
                )}
              </Form.Item>
            </Col>
          </Row>
        </div>
        <Row style={{ padding: '12px 24px' }}>
          <Col span={24} style={{ color: '#1D3557', fontSize: '14px', fontWeight: 600 }}>
            <FormattedMessage id="orderModalTilteOrderDetail" defaultMessage="Order Details" />
          </Col>
        </Row>
        <Divider type='horizontal' style={{ margin: 'unset' }} />
        <div style={{ padding: '12px 24px' }}>
          <Row gutter={[16, 8]}>
            <Col span={12}>
              <Form.Item
                label={<LabeRequireForm text={intl.formatMessage({ id: 'orderModalParcel', defaultMessage: 'Total Parcel' })} req={true} />}
              >
                {getFieldDecorator('parcel', {
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'orderModalReqParcel', defaultMessage: 'Please enter total parcel' }),
                    },
                  ],
                })(
                  <Input
                    placeholder={intl.formatMessage({ id: 'orderModalPlaceholderParcel', defaultMessage: 'Enter Total Parcel' })}
                    onKeyDown={handleKeyDownNumber}
                    disabled={true}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={<LabeRequireForm text={intl.formatMessage({ id: 'orderModalRefNo', defaultMessage: 'Reference No.' })} req={false} />}
              >
                {getFieldDecorator('refNo', {
                })(
                  <Input
                    placeholder={intl.formatMessage({ id: 'orderModalPlaceholderRefNo', defaultMessage: 'Enter Reference No.' })}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 8]}>
            <Col span={24}>
              <Form.Item
                label={<LabeRequireForm text={intl.formatMessage({ id: 'orderModalRemark', defaultMessage: 'Remark' })} req={false} />}
              >
                {getFieldDecorator('remark', {
                })(
                  <Input
                    placeholder={intl.formatMessage({ id: 'orderModalPlaceholderRemark', defaultMessage: 'Enter Remark' })}
                  >
                  </Input>
                )}
              </Form.Item>
            </Col>
          </Row>
        </div>
      </Form>


      <CustomerLocation
        visible={visibleMapCusotmer}
        setVisible={setVisibleMapCusotmer}
        selectAddress={selectFromAddress}
        setSelectAddress={setSelectFromAddress}
        setCreateFromData={setCreateFromData}
        fromOutSide={fromOutSide}
        setFromOutSide={setFromOutSide}
        trigger={triggerFrom}
        setTrigger={setTriggerFrom}
        visibleAdd={visibleAddNewCompany}
        setVisibleAdd={setVisibleAddNewCompany}
        statusFromAdd={statusFromAdd}
        setStatuFromsAdd={setStatuFromsAdd}
        customerId={_.get(selectCustomer, 'customerId') || ''}
        customerName={_.get(selectCustomer, 'customerName') || ''}
        keyMenu={keyMenu}
        setKeyMenu={setKeyMenu}
        createFromData={createFromData}
        center={centerFrom}
        setCenter={setCenterFrom}
        position={positionFrom}
        setPosition={setPositionFrom}
        visibleInfoWindow={visibleInfoWindowFrom}
        setVisibleInfoWindow={setVisibleInfoWindowFrom}
      />

      <CustomerLocation
        visible={visibleMapContact}
        setVisible={setVisibleMapContact}
        selectAddress={selectToAddress}
        setSelectAddress={setSelectToAddress}
        setCreateFromData={setCreateToData}
        fromOutSide={toOutSide}
        setFromOutSide={setToOutSide}
        trigger={triggerTo}
        setTrigger={setTriggerTo}
        visibleAdd={visibleAddNewCustomer}
        setVisibleAdd={setVisibleAddNewCustomer}
        statusFromAdd={statusToAdd}
        setStatuFromsAdd={setStatusToAdd}
        customerId={_.get(selectCustomer, 'customerId') || ''}
        customerName={_.get(selectCustomer, 'customerName') || ''}
        keyMenu={keyMenuTo}
        setKeyMenu={setKeyMenuTo}
        createToData={createToData}
        center={centerTo}
        setCenter={setCenterTo}
        position={positionTo}
        setPosition={setPositionTo}
        visibleInfoWindow={visibleInfoWindowTo}
        setVisibleInfoWindow={setVisibleInfoWindowTo}
      />

    </Modal >
  )
}

const DeliveryEditOrderForm = Form.create({
  name: 'warehouse-form',
})(DeliveryEditOrder);

export default DeliveryEditOrderForm
