import React, { useState, useEffect, useRef } from 'react';
import { Form, Modal, Input, Row, Col, Select, InputNumber, Checkbox } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import _ from 'lodash';
import { numberFormatter, numberParser } from '../../../../../component-function/fnc-inputnumber-currency';
import { handleKeyDownDecimal2Fixed, handleKeyDownNumber } from '../../../../../component-function/fnc-number';
import { getConvertUoM, getStockList } from '../../../../../controllers/po/po';
import LabeRequireForm from '../../../../../components/label-required-form';
import Button_01 from '../../../../../components/v2/button_01';
import Button_02 from '../../../../../components/v2/button_02';
import SuffixInput from '../../../../../components/v2/suffix';
import { Round } from '../../../../../component-function/lodash';
import chagneVatItem from '../../../../../components/list-item/calvat';

const { Option } = Select;

const ListItemCreate = (props) => {
  const { data, visible, setVisible, form, selectItem, setSelectItem } = props;
  const { getFieldDecorator, validateFields, resetFields, getFieldValue, setFieldsValue } = form;
  const [uomList, setUomList] = useState([]);
  const intl = useIntl();
  const qty = parseFloat(getFieldValue('qty') || 0);
  const price = parseFloat(getFieldValue('price') || 0.0);
  const discount = parseFloat(getFieldValue('discount') || 0);
  const amount = Round(qty * price);
  const inputNumberRef = useRef();
  const inputNumberDiscountRef = useRef();
  const inputNumberQTYRef = useRef();
  const [checkExVat, setCheckExVat] = useState(false);
  const inputNumberPrice = useRef();

  console.log('data', data);

  useEffect(() => {
    const getStockList = async () => {
      const unitData = await getConvertUoM(_.get(data, 'itemMasterId'));
      setUomList(_.get(unitData, 'data.data'));
    };
    if (data && visible) {
      getStockList();
    }
  }, [data, visible]);

  useEffect(() => {
    if (qty || price) {
      setFieldsValue({
        ['amount']: numberFormatter(qty * price),
      });
    }
  }, [qty, price]);

  const handleSave = async () => {
    validateFields(async (err, values) => {
      if (err) {
        return;
      }
      const response = await getStockList({ itemMasterId: [_.get(data, 'itemMasterId')] });
      const filterUoM = _.filter(uomList, (item) => {
        return item.uomId === _.get(values, 'unit');
      });
      const findDuplicate = _.find(selectItem, function(o) {
        return (
          o.itemMasterId === _.get(data, 'itemMasterId') &&
          o.uomId === _.get(values, 'unit') &&
          o.pricePerUnit === parseFloat(_.get(values, 'price')) &&
          o.discountPercentage === parseFloat(_.get(values, 'discount') || 0) &&
          o.remark === _.get(values, 'remark', '')
        );
      });
      if (findDuplicate) {
        const index = _.get(findDuplicate, 'index') - 1;
        selectItem[index].qty = selectItem[index].qty + parseFloat(_.get(values, 'qty'));
        selectItem[index].count = selectItem[index].count + parseFloat(_.get(values, 'qty'));
        selectItem[index].countTemp = selectItem[index].countTemp + parseFloat(_.get(values, 'qty'));
        selectItem[index].discountPrice = selectItem[index].discountPrice + parseFloat(_.get(values, 'discountNumber') || 0);
        selectItem[index].totalPrice = selectItem[index].totalPrice + numberParser(_.get(values, 'total'));
        setVisible(false);
        resetFields();
        setCheckExVat(false);
      } else {
        const itemBody = {
          index: _.size(selectItem) + 1,
          itemMasterId: _.get(data, 'itemMasterId'),
          itemCode: _.get(data, 'itemCode'),
          itemName: _.get(data, 'itemName'),
          qty: parseFloat(_.get(values, 'qty')),
          count: parseFloat(_.get(values, 'qty')),
          countTemp: parseFloat(_.get(values, 'qty')),
          uomId: _.get(values, 'unit'),
          uomName: _.get(filterUoM, '[0].name'),
          pricePerUnit: parseFloat(_.get(values, 'price')),
          discountPercentage: parseFloat(_.get(values, 'discount') || 0),
          discountPrice: parseFloat(_.get(values, 'discountNumber') || 0),
          totalPrice: numberParser(_.get(values, 'total')),
          remark: _.get(values, 'remark') || '',
          instock: _.get(response, 'data.data[0].qty') || 0,
          type: 'service',
          checkVat: checkExVat,
        };
        setSelectItem([...selectItem, itemBody]);
        setVisible(false);
        resetFields();
        setCheckExVat(false);
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
    resetFields();
    setCheckExVat(false);
  };

  const validatorQty = (rule, value, callback) => {
    if (value) {
      const parsValue = parseFloat(value || 0);
      if (parsValue === 0) {
        callback('QTY must more than 0');
      } else {
        callback();
      }
    } else {
      callback();
    }
  };

  const currencyFormatter = (value) => {
    return _.toString(value || '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const currencyParser = (value) => {
    return _.toString(value || '').replace(/\$\s?|(,*)/g, '');
  };

  const handleChangeQTY = (e) => {
    const calAmount = Round(parseFloat(e) * price);
    if (e && e > 0) {
      setFieldsValue({
        ['discount']: 0.0,
        ['discountNumber']: 0.0,
        ['total']: numberFormatter(calAmount),
      });
    } else {
      setFieldsValue({
        ['price']: 0.0,
        ['discount']: 0.0,
        ['discountNumber']: 0.0,
        ['total']: 0.0,
      });
    }
  };

  const handleChangePrice = (e) => {
    const amount = Round(qty * parseFloat(e));
    const calTotalPercent = Round((amount * discount) / 100);
    const calTotalPrice = amount - calTotalPercent;

    if (e && e > 0) {
      setFieldsValue({
        ['discountNumber']: calTotalPercent.toFixed(2),
        ['total']: numberFormatter(calTotalPrice),
      });
    } else {
      setFieldsValue({
        ['discount']: 0.0,
        ['discountNumber']: 0.0,
        ['total']: 0.0,
      });
    }
  };

  const handleChangeDiscount = (e) => {
    const calPercent = Round((amount * parseFloat(e)) / 100);
    const calTotalPrice = amount - calPercent;
    console.log('calPercent', calPercent, calTotalPrice);
    if (e && e > 0) {
      setFieldsValue({
        ['discountNumber']: calPercent.toFixed(2),
        ['total']: numberFormatter(calTotalPrice),
      });
    } else {
      setFieldsValue({
        ['discount']: 0.0,
        ['discountNumber']: 0.0,
        ['total']: numberFormatter(amount),
      });
    }
  };

  const handleChangeDiscountNumber = (e) => {
    const calPercent = Round((parseFloat(e) / amount) * 100);
    const calTotalPrice = amount - parseFloat(e);
    if (e && e > 0) {
      setFieldsValue({
        ['discount']: calPercent.toFixed(2),
        ['total']: numberFormatter(calTotalPrice),
      });
    } else {
      setFieldsValue({
        ['discount']: 0.0,
        ['discountNumber']: 0.0,
        ['total']: numberFormatter(amount),
      });
    }
  };

  const mouseOver = (value) => {
    inputNumberRef.current.blur();
    inputNumberQTYRef.current.blur();
    inputNumberDiscountRef.current.blur();
    inputNumberPrice.current.blur();
  };

  const chagneVat = (check) => {
    const finalcal = chagneVatItem(check, getFieldValue('price'));
    setCheckExVat(check);
    setFieldsValue({
      ['price']: finalcal,
    });
    // handleChangePrice(finalcal);
  };

  return (
    <Modal
      title={intl.formatMessage({ id: `modalListItemTitle`, defaultMessage: 'Add Item' })}
      centered={true}
      width={900}
      onCancel={handleCancel}
      visible={visible}
      bodyStyle={{ padding: 'unset', height: '315px' }}
      footer={[
        <Button_02 key="back" btnsize="wd_df" onClick={handleCancel}>
          <FormattedMessage id="userDetailModalBtnCancel" defaultMessage="Cancel" />
        </Button_02>,
        <Button_01
          key="submit"
          type="primary"
          btnsize="wd_df"
          onClick={handleSave}
          style={{ margin: 'unset' }}
          onMouseOver={() => {
            mouseOver();
          }}
        >
          <FormattedMessage id="userDetailModalBtnSave" defaultMessage="Save" />
        </Button_01>,
      ]}
    >
      <div style={{ padding: '16px' }}>
        <div style={{ marginBottom: '28px' }}>
          <div style={{ marginBottom: '8px', color: '#1D3557', fontSize: '12px' }}>
            <LabeRequireForm text={intl.formatMessage({ id: 'modalListItemFormTitleItem', defaultMessage: 'Item' })} req={false} />
          </div>
          <div style={{ color: '#050505', fontSize: '13px' }}>
            {_.get(data, 'itemCode')} {_.get(data, 'itemName')}
          </div>
        </div>
        <Form form={form} layout="vertical">
          <Row gutter={[16]}>
            <Col span={8}>
              <Form.Item
                label={<LabeRequireForm text={intl.formatMessage({ id: 'modalListItemFormTitleQty', defaultMessage: 'Qty' })} req={true} />}
              >
                {getFieldDecorator('qty', {
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'modalListItemFormValidateQty', defaultMessage: 'Please Enter Qty' }),
                    },
                    {
                      validator: validatorQty,
                    },
                  ],
                })(
                  <InputNumber
                    className="disabled-handle-count"
                    placeholder={intl.formatMessage({ id: 'modalListItemFormPlaceholderQty', defaultMessage: 'Enter Qty' })}
                    onKeyDown={handleKeyDownNumber}
                    formatter={currencyFormatter}
                    parser={currencyParser}
                    controls={false}
                    onChange={handleChangeQTY}
                    ref={inputNumberQTYRef}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={
                  <LabeRequireForm text={intl.formatMessage({ id: 'modalListItemFormTitleUnit', defaultMessage: 'Unit' })} req={true} />
                }
              >
                {getFieldDecorator('unit', {
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'modalListItemFormValidateUnit', defaultMessage: 'Please Select Unit' }),
                    },
                  ],
                })(
                  <Select
                    placeholder={intl.formatMessage({ id: 'modalListItemFormPlaceholderUnit', defaultMessage: 'Select Unit' })}
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    showSearch
                  >
                    {_.map(uomList, (item) => (
                      <Option value={item.uomId}>{item.name}</Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                className="custom-label"
                label={
                  <Row justify="space-between" align="middle" style={{ width: '100%' }}>
                    <Col span={10}>
                      <span>
                        <LabeRequireForm
                          text={intl.formatMessage({ id: 'modalListItemFormTitlePrice', defaultMessage: 'Price/Unit' })}
                          req={true}
                        />
                      </span>
                    </Col>
                    {/* <Col span={14} style={{ textAlign: 'right' }}>
                      <Checkbox checked={checkExVat} onChange={(e) => chagneVat(e.target.checked)}>
                        <FormattedMessage id="PoExclVAT7" defaultMessage="Excl. VAT 7%" />
                      </Checkbox>
                    </Col> */}
                  </Row>
                }
                labelCol={{ style: { width: '100%', paddingBottom: '2px' } }}
              >
                {getFieldDecorator('price', {
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'modalListItemFormValidatePrice', defaultMessage: 'Please Enter Price/Unit' }),
                    },
                  ],
                })(
                  <InputNumber
                    className="disabled-handle-count"
                    placeholder={intl.formatMessage({ id: 'modalListItemFormPlaceholderPrice', defaultMessage: 'Enter Price/Unit' })}
                    suffix="THB"
                    onKeyDown={handleKeyDownDecimal2Fixed}
                    formatter={currencyFormatter}
                    parser={currencyParser}
                    controls={false}
                    onChange={handleChangePrice}
                    ref={inputNumberPrice}
                  />
                )}
                <SuffixInput text="THB" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16]}>
            <Col span={8}>
              <Form.Item
                label={
                  <LabeRequireForm
                    text={intl.formatMessage({ id: 'modalListItemFormTitleAmount', defaultMessage: 'Amount' })}
                    req={false}
                  />
                }
              >
                {getFieldDecorator('amount', {
                  initialValue: '0.00',
                })(<Input maxLength={100} disabled={true} />)}
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item
                label={
                  <LabeRequireForm
                    text={intl.formatMessage({ id: 'modalListItemFormTitleDiscount', defaultMessage: 'Discount' })}
                    req={false}
                  />
                }
              >
                {getFieldDecorator('discount', {
                  initialValue: '0.00',
                })(
                  <InputNumber
                    className="disabled-handle-count"
                    onKeyDown={handleKeyDownDecimal2Fixed}
                    formatter={currencyFormatter}
                    parser={currencyParser}
                    controls={false}
                    onChange={handleChangeDiscount}
                    ref={inputNumberRef}
                  />
                )}
                <SuffixInput text="%" />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item label={<LabeRequireForm text="" req={false} />}>
                {getFieldDecorator('discountNumber', {
                  initialValue: '0.00',
                })(
                  <InputNumber
                    className="disabled-handle-count"
                    suffix="THB"
                    onKeyDown={handleKeyDownDecimal2Fixed}
                    formatter={currencyFormatter}
                    parser={currencyParser}
                    controls={false}
                    onChange={handleChangeDiscountNumber}
                    ref={inputNumberDiscountRef}
                  />
                )}
                <SuffixInput text="THB" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={
                  <LabeRequireForm text={intl.formatMessage({ id: 'modalListItemFormTitleTotal', defaultMessage: 'Total' })} req={false} />
                }
              >
                {getFieldDecorator('total', {
                  initialValue: '0.00',
                })(
                  <Input
                    placeholder={intl.formatMessage({ id: 'modalListItemFormPlaceholderTotal', defaultMessage: 'Enter Total' })}
                    maxLength={100}
                    onKeyDown={handleKeyDownDecimal2Fixed}
                    suffix="THB"
                    readOnly={true}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16]}>
            <Col span={24}>
              <Form.Item
                label={
                  <LabeRequireForm
                    text={intl.formatMessage({ id: 'modalListItemFormTitleRemark', defaultMessage: 'Remark' })}
                    req={false}
                  />
                }
              >
                {getFieldDecorator('remark', {})(<Input maxLength={100} />)}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};

const ModalCreateListServiceItemPO = Form.create({
  name: 'assignTeam-form',
  mapPropsToFields() {},
})(ListItemCreate);

export default ModalCreateListServiceItemPO;
