import React, { useState, useRef } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Table, Popover } from 'antd';
import styles from './css/index.css';
import styled from 'styled-components';
import _ from 'lodash';

const OrderReport = (props) => {
  const { loading, dataSource, columns, total, paginationPage, fnc } = props;
  const intl = useIntl();
  const [visiblePopoverTable, setVisiblePopoverTable] = useState();
  const [recordPopover, setRecordPopover] = useState();
  const [offsetPopover, setOffsetPopover] = useState([0, 0]);
  const ref = useRef();

  const showTotal = (total) => {
    return (
      <span style={{ fontSize: '13px' }}>
        <FormattedMessage id="lblRowPerPage" defaultMessage="Rows per page: " />
      </span>
    );
  };

  const TotalItem = styled.div`
    display: flex;
    position: relative;
    margin-top: -42px;
    margin-left: 10px;
  `;
  const handleMouseLeave = () => {
    setVisiblePopoverTable(false);
    setRecordPopover();
    setOffsetPopover([0, 0]);
  };

  return (
    <div onMouseLeave={handleMouseLeave}>
      <Table
        className="member-table-report"
        loading={loading}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              // console.log('event:::', event);
              // console.log('event:::target', event.target.href);
              // console.log('event:::record', record);
              // console.log('event:::ref', ref.current.getBoundingClientRect());
              // console.log('event:::x', event.pageX);
              // console.log('event:::y', event.pageY);
              if (!event.target.href) {
                const { x, y } = ref.current.getBoundingClientRect();
                setVisiblePopoverTable(true);
                setOffsetPopover([event.pageX - x, event.pageY - y]);
                setRecordPopover(record);
              }
            },
          };
        }}
        rowKey={(record) => record.mem_com_id}
        dataSource={dataSource}
        columns={columns}
        onChange={fnc.handleChange}
        scroll={
          _.size(columns) <= 6
            ? { y: `calc(100vh - 370px)` }
            : { x: 2000, y: `calc(100vh - 370px)` }
        }
        pagination={{
          total: total,
          showTotal: showTotal,
          current: paginationPage,
          defaultCurrent: 1,
          pageSizeOptions: ['10', '20', '30', '40', '50'],
          showSizeChanger: true,
          locale: { items_per_page: '' },
          onChange: fnc.handlePagination,
          onShowSizeChange: fnc.handleSizeChange,
        }}
      />
      {fnc.fncActionTable(recordPopover, offsetPopover, visiblePopoverTable, ref)}
      {/* <Popover
        placement="right"
        title="Popover"
        visible={visiblePopover}
        //  onVisibleChange={handleOnVisiblePopover}
        zIndex={999}
        content={'xxxx'}
        align={{ offset: offsetPopover }}
      >
        <div style={{ width: 0, height: 0 }} ref={ref}></div>
      </Popover> */}
      {dataSource && dataSource.length !== 0 ? (
        <TotalItem>
          <span style={{ fontSize: '13px' }}>
            <FormattedMessage id="lblTotal" defaultMessage="Total" />
            {` ${total || 0} `}
            <FormattedMessage id="lblitems" defaultMessage="items" />
          </span>
        </TotalItem>
      ) : null}
    </div>
  );
};

export default OrderReport;
