import React from 'react';
import { Modal } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import { PageOrganizationTabShift } from '../../../config/page-settings';
import ShiftModalDelete from '../shift-v1/shift-modal-delete-shift';
import Button01 from '../../../components/v2/button_01';
import Button02 from '../../../components/v2/button_02';

export default () => {

  const intl = useIntl();

  return (
    <PageOrganizationTabShift.Consumer>
      {({visibleViewShift, viewShiftDetail, visibleBtnDeleteShift, disableBtnDeleteShift, handleDeleteViewShift, handleCancelViewShift}) => (
        <Modal
          className='shift-modal-schedule'
          title={intl.formatMessage({ id: 'orgShiftPlanningModalTitleDelete', defaultMessage: 'Delete Shift' })}
          centered={true}
          width={550}
          visible={visibleViewShift}
          onCancel={handleCancelViewShift}
          footer={[
            <Button02 style={{margin : '0px 0px 0px 10px'}} key='back' fontsize='sm' btnsize='wd_df' onClick={handleCancelViewShift}>
              <FormattedMessage id='btnCancel' defaultMessage='Cancel' />
            </Button02>,
            visibleBtnDeleteShift === false ? (
              <Button01 key='submit' fontsize='sm' btnsize='wd_df' type='primary' onClick={handleDeleteViewShift}  disabled={disableBtnDeleteShift}>
                <FormattedMessage id='btnDelete' defaultMessage='Delete' />
              </Button01>
            ) : null
          ]}
        >
          <ShiftModalDelete formData={viewShiftDetail} />
        </Modal>
      )}
    </PageOrganizationTabShift.Consumer>
  );
}