import React, { useState, useContext, useEffect } from 'react';
import { ShiftContext } from './shift-context'; 
import { useIntl, FormattedMessage } from 'react-intl';
import { Row, Col, Card, Spin } from 'antd';
import httpClient from '../../../components/axiosClient';
import Provider from '../provider';
import ShiftAction from './shift-action';
import ShiftSchedule from './shift-schedule';
import _ from 'lodash';
import moment from 'moment';
import stylesShiftIndex from './css/index.css';

export default React.memo(({ orgIdSelect, refreshMain, refreshSaveFromMain }) => {
  const intl = useIntl();
  const app = Provider.useAppContext();

  const today = new Date();
  const datenow = moment();
  const dateTimeApi = 'YYYY-MM-DD HH:mm';
  const shiftsDateNow = moment(today).format(dateTimeApi);
  const comId = localStorage.getItem('comId');

  let treeOrgIdMain = _.get(app, 'state.orgId');
  let comIdMain = _.get(app, 'state.comId');
  let shiftsYearMain = _.get(app, 'state.shiftsYear');
  let shiftsMonthMain = _.get(app, 'state.shiftsMonth');
  // let refreshMain = _.get(app, 'state.refresh');
  // let refreshSaveFromMain = _.get(app, 'state.refreshSaveFrom');

  const [resourcesArr, setResourcesArr] = useState([]);
  const [resourceShiftData, setResourceShiftData] = useState([]);
  const [shiftsData, setShiftsData] = useState([]);
  const [loadingShift, setLoadingShift] = useState(false);

  const [shiftsYear, setShiftsYear] = useState(moment().format('YYYY'));
  const [shiftsMonth, setShiftsMonth] = useState(moment().format('MMMM'));
  const [mainShiftsDateTimePicker, setMainShiftsDateTimePicker] = useState(shiftsDateNow);

  const [planningVisibled, setPlanningVisibled] = useState(false);
  const [shiftPlanningDisabled, setShiftPlanningDisabled] = useState(true);

  const [refreshApiShift, setRefreshApiShift] = useState(false);

 


  useEffect(() => {
    if (app.state.keyTabsMain === 'shift') {
      getOrganizationShiftData();
    }
  }, [app.state.keyTabsMain, orgIdSelect, shiftsMonth, shiftsYear, refreshMain, refreshSaveFromMain, refreshApiShift]);

  useEffect(() => {
    getShiftSettings();
  }, [orgIdSelect, shiftsMonth, shiftsYear]);

  const getOrganizationShiftData = async () => {
    setLoadingShift(true);
    const data = {
      com_id: comId,
      org_id: orgIdSelect,
      search_team: {
        name: '',
      },
      members: {
        conditional: [],
        order: 'fullname ASC',
        limit: '100',
        index_page: '1',
      },
      services: {},
      shift: {
        date: `${shiftsYear}-${moment().month(shiftsMonth).format('MM')}`
      },
    };

    try {
      const response = await httpClient.post('/v2/organizationdetails', data);

      if (response.status === 200) {

        const resourceData = response.data.members.data ? response.data.members.data : [];
        const shiftData = response.data.shift ? response.data.shift : [];

        await setResourcesArr(resourceData);
        await newResourceShiftData(resourceData);
        await newShiftData(shiftData);
        await setLoadingShift(false);
      }
    } catch (error) {
      setLoadingShift(false);
    }
  };

  const getShiftSettings = async () => {
    try {
      const shiftSettings = await httpClient.get(`/resource/manager/organization/${app.state.orgId}/shift/rules?comId=${app.state.comId}`);
      if (shiftSettings.status === 200) {
        checkShiftPlanning();
        // if (shiftSettings.data.shiftTypeRules.length > 0 && shiftSettings.data.shiftRuleStatus === 'Enable') {
        //   checkShiftPlanning();
        // }
        // else {
        //   setShiftPlanningDisabled(true);
        // }
      }
      else {
        setShiftPlanningDisabled(true);
      }
    }
    catch (error) {
      setShiftPlanningDisabled(true);
    }
  }

  const newResourceShiftData = async (resourceData) => {
    let newResource = [];
    resourceData.map((object) => {
      const { mem_id, fullname, profile_img, email, phone, mem_com_id } = object;
      newResource.push({
        Id: mem_id,
        Text: fullname,
        email: email,
        phone: phone,
        pathImage: profile_img,
        memComId: mem_com_id,
      });
    });

    setResourceShiftData(newResource);



    // let matchResource = await _.isMatch(resourceShiftData, newResource);


    // if (matchResource === true) {
    //   return
    // } else {
    //   setResourceShiftData(newResource);
    // }
  }

  const newShiftData = async (shiftData) => {
    let newShift = [];
    shiftData.forEach((object) => {
      object.shifts.forEach((element) => {
        const { mem_shift_id, shift_name, shift_date, mem_id, color, is_holiday, teamName, start, finish, mem_com_id, default_profile_img, } = element;
        newShift.push({
          Id: mem_shift_id,
          Subject: shift_name,
          StartTime: moment(shift_date).format('YYYY-MM-DD 00:00'),
          EndTime: moment(shift_date).format('YYYY-MM-DD 23:59'),
          Color: color,
          ResourceId: Number(mem_id),
          MemComId: object.mem_com_id,
          PathImage: object.default_profile_img,
          Name: object.fullname,
          Email: object.email,
          Phone: object.phone,
          IsAllDay: true,
          isReadonly: is_holiday ? true : false,
          TeamName: teamName ? teamName : '',
          Start: moment(start, 'HH:mm:ss').format('HH:mm'),
          Finish: moment(finish, 'HH:mm:ss').format('HH:mm'),
          ThisOrgSelect: orgIdSelect
        });
      });
    });

    setShiftsData(newShift);
    // let matchShiftsData = await _.isMatch(shiftsData, newShift);


    // if (matchShiftsData === true) {
    //   return
    // } else {
    //   setShiftsData(newShift);
    // }

    // await setShiftsData(newShift);
  }

  const handleChangeMonth = (valueMonth) => {
    setShiftsMonth(valueMonth);
  }

  const handleChangeYear = (valueYear) => {
    setShiftsYear(valueYear);
  }

  const checkShiftPlanning = () => {
    const dateSelect = moment(shiftsYear + ' ' + shiftsMonth, 'YYYY MMMM');
    const diff = dateSelect.diff(datenow, 'months');
    if (diff >= 0) {
      setShiftPlanningDisabled(false);
    }
    else {
      setShiftPlanningDisabled(true);
    }
  };

  // -------------------------------------------------------
  const newShifts = (teamShift) => {
    let shiftsArr = [];
    teamShift && teamShift.forEach((object) => {
      object.shifts.forEach((element) => {
        const { mem_shift_id, shift_name, shift_date, mem_id, color, is_holiday, teamName, start, finish } = element;
        shiftsArr.push({
          Id: mem_shift_id,
          Subject: shift_name,
          StartTime: moment(shift_date).format('YYYY-MM-DD 00:00'),
          EndTime: moment(shift_date).format('YYYY-MM-DD 23:59'),
          Color: color,
          ResourceId: Number(mem_id),
          IsAllDay: true,
          isReadonly: is_holiday ? true : false,
          TeamName: teamName ? teamName : '',
          Start: moment(start, 'HH:mm:ss').format('HH:mm'),
          Finish: moment(finish, 'HH:mm:ss').format('HH:mm'),
        });
      });
    });
    return shiftsArr;
  }

    const newList = (teamMember) => {
    const list = teamMember && teamMember.map((object) => {
      const { mem_id, fullname, profile_img, email, phone } = object;
      return {
        Id: mem_id,
        Text: fullname,
        email: email,
        phone: phone,
        pathImage: profile_img
      };
    });
    return list;
  }

  return (
    <ShiftContext.Provider
      value={{
        stateShiftsMain: {
          resourceShiftData,
          resourcesArr,
          treeOrgIdMain,
          comIdMain,
          shiftsData,
          shiftsYear,
          shiftsMonth,
          planningVisibled,
          shiftPlanningDisabled,
          mainShiftsDateTimePicker,
          loadingShift,
        },
        setStateShiftsMain: {
          setShiftsYear,
          setShiftsMonth,
          setMainShiftsDateTimePicker,
          setPlanningVisibled,
          setShiftPlanningDisabled,
          setRefreshApiShift,
        },
        fncShiftsMain: {
          handleChangeMonth,
          handleChangeYear,
        }
      }}
    >
      <ShiftAction />
      <Spin spinning={loadingShift}>
        <div>

          {/* <ShiftSchedule 
            orgIdSelect={orgIdSelect}
            resourceShiftData={resourceShiftData} 
            shiftsData={shiftsData} 
            refreshMain={refreshMain} 
            setRefreshApiShift={setRefreshApiShift}
          /> */}

          {resourceShiftData.length && (
            <ShiftSchedule 
              orgIdSelect={orgIdSelect}
              resourceShiftData={resourceShiftData} 
              shiftsData={shiftsData} 
              refreshMain={refreshMain} 
              mainShiftsDateTimePicker={mainShiftsDateTimePicker}
              resourcesArr={resourcesArr} 
              refreshApiShift={refreshApiShift}
              setRefreshApiShift={setRefreshApiShift}
            />
          )}

          {/* {resourceShiftData && <ShiftSchedule 
              orgIdSelect={orgIdSelect}
              resourceShiftData={resourceShiftData} 
              shiftsData={shiftsData} 
              refreshMain={refreshMain} 
              setRefreshApiShift={setRefreshApiShift}
            />
          } */}
        </div>
      </Spin>
    </ShiftContext.Provider>
  )
})