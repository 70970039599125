import { Button, Divider, Modal, Popover, Table, Tag } from 'antd';
import _ from 'lodash';
import React, { useState, useContext, useRef } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import Button_01 from '../../../../components/v2/button_01';
import { errorNotification, successNotification } from '../../../../components/v2/notification';
import { deleteWarehouseManager, updateWarehouseManager } from '../../../../controllers/warehouse/warehouse';
import WarehouseContext from '../../context';
import ModalWarehouseManager from '../../warehouse-modal/warehouseModalManageForm';
import '../css/index.css';
import { PageSettings } from '../../../../config/page-settings';

const Manager = ({}) => {
  const app = useContext(PageSettings);
  const { checkPermissionAction } = app;
  const intl = useIntl();
  const ref = useRef();
  const { state, setState, fnc } = useContext(WarehouseContext);
  const { warehouseManagerList, warehouseId, warehouseDetail } = state;
  const { setTrigger } = setState;
  const [isOpen, setIsOpen] = useState(false);
  const [recordManage, setRecordManage] = useState();
  const [visiblePopoverTable, setVisiblePopoverTable] = useState();
  const [recordPopover, setRecordPopover] = useState();
  const [offsetPopover, setOffsetPopover] = useState([0, 0]);
  const columns = [
    {
      title: '#',
      select: 'Index',
      align: 'center',
      width: 70,
      key: 'index',
      render: (text, record, index) => index + 1,
    },
    {
      title: intl.formatMessage({
        id: 'warehouseManagerUserorRole',
        defaultMessage: 'User or Role',
      }),
      dataIndex: 'name',
      key: 'name',
      width: 375,
      render: (text, record, index) => (
        <div>
          {record.role &&
            record.role.map((item) => (
              <Tag
                key={item.roleId}
                className="tax-role-name-style"
                closable={
                  checkPermissionAction(`P52PG1C2`, `P52PG1C2A3`)  && _.size(_.get(warehouseDetail, 'isManager')) > 0 ? true : false
                }
                onClose={() => handleDeleteTag(record, item.roleId, 'roleId')}
              >
                {item.name}
              </Tag>
            ))}
          {record.user &&
            record.user.map((item) => (
              <Tag
                key={_.get(item, 'memComId')}
                className="tax-role-name-style"
                closable={
                  checkPermissionAction(`P52PG1C2`, `P52PG1C2A3`) && _.size(_.get(warehouseDetail, 'isManager')) > 0 ? true : false
                }
                onClose={() => handleDeleteTag(record, _.get(item, 'memComId'), 'memComId')}
              >
                {_.get(item, 'name')}
              </Tag>
            ))}
        </div>
      ),
    },
    {
      title: intl.formatMessage({
        id: 'warehouseManagerManageorApprove',
        defaultMessage: 'Manage or Approve',
      }),
      dataIndex: 'manageOrApprove',
      key: 'manageOrApprove',
      // width: 375,
    },
  ];

  const handleDeleteTag = async (record, Id, type) => {
    console.log('handleDeleteTag', record, Id, type);
    if (type === 'roleId') {
      if (_.size(record.role) === 1 && _.size(record.user) === 0) {
        handleDeleteAuto(_.get(record, 'managerId'));
      } else {
        const filterRole =
          record &&
          _.get(record, 'role').filter((item) => {
            return item.roleId !== Id;
          });
        const mapRole =
          filterRole &&
          filterRole.map((item) => {
            return item.roleId;
          });
        const mapUser =
          record &&
          _.get(record, 'user').map((item) => {
            return item.memComId;
          });
        const mapType =
          record &&
          _.get(record, 'manageTypeList').map((item) => {
            return item.manageCode;
          });

        const payload = {
          warehouseId: warehouseId,
          userIdList: mapUser || [],
          roleIdList: mapRole || [],
          manageTypeCodeList: mapType || [],
        };
        try {
          const response = await updateWarehouseManager(_.get(record, 'managerId'), payload);
          console.log('updateWarehouseManager', response);
          if (_.get(response, 'status') === 200) {
            successNotification(_.get(response.data.status, 'message'));
            setTrigger((current) => !current);
          } else {
            errorNotification(_.get(response.data.status, 'message'));
          }
        } catch (error) {}
      }
    } else if (type === 'memComId') {
      if (_.size(record.user) === 1 && _.size(record.role) === 0) {
        handleDeleteAuto(_.get(record, 'managerId'));
      } else {
        const filterUser =
          record &&
          _.get(record, 'user').filter((item) => {
            return item.memComId !== Id;
          });
        const mapUser =
          filterUser &&
          filterUser.map((item) => {
            return item.memComId;
          });
        const mapRole =
          record &&
          _.get(record, 'role').map((item) => {
            return item.roleId;
          });
        const mapType =
          record &&
          _.get(record, 'manageTypeList').map((item) => {
            return item.manageCode;
          });

        const payload = {
          warehouseId: warehouseId,
          userIdList: mapUser || [],
          roleIdList: mapRole || [],
          manageTypeCodeList: mapType || [],
        };
        try {
          const response = await updateWarehouseManager(_.get(record, 'managerId'), payload);
          console.log('updateWarehouseManager', response);
          if (_.get(response, 'status') === 200) {
            successNotification(_.get(response.data.status, 'message'));
            setTrigger((current) => !current);
          } else {
            errorNotification(_.get(response.data.status, 'message'));
          }
        } catch (error) {}
      }
    }
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  const openModalEdit = (record) => {
    setIsOpen(true);
    if (record) {
      let newType = _.map(_.get(record, 'manageTypeList'), (i) => i.manageCode);
      let newRole = _.map(_.get(record, 'role'), (i) => i.roleId);
      let newUser = _.map(_.get(record, 'user'), (i) => i.memComId);
      let newObj = {
        createdAt: _.get(record, 'createdAt'),
        manageOrApprove: _.get(record, 'manageOrApprove'),
        userOrRole: _.get(record, 'userOrRole'),
        manageTypeList: newType,
        role: newRole,
        user: newUser,
        managerId: _.get(record, 'managerId'),
      };
      setRecordManage(newObj);
    }
  };

  const handleDeleteAuto = async (id) => {
    try {
      const response = await deleteWarehouseManager(id);
      if (response.status === 200) {
        successNotification(_.get(response.data.status, 'message'));
        setTrigger((current) => !current);
      } else {
        errorNotification(_.get(response.data.status, 'message'));
      }
    } catch (error) {
      errorNotification(_.get(error.response.data.status, 'message'));
    }
  };

  const handleDelete = (id) => {
    Modal.confirm({
      className: 'maintenance-report-modal-confirm',
      centered: true,
      icon: null,
      title: intl.formatMessage({ id: 'lblDelete', defaultMessage: 'Are you sure you want to delete ?' }),
      okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        try {
          const response = await deleteWarehouseManager(id);
          if (response.status === 200) {
            successNotification(_.get(response.data.status, 'message'));
            setTrigger((current) => !current);
          } else {
            errorNotification(_.get(response.data.status, 'message'));
          }
        } catch (error) {
          errorNotification(_.get(error.response.data.status, 'message'));
        }
      },
      onCancel() {},
    });
  };

  const handleMouseLeave = () => {
    setVisiblePopoverTable(false);
    setRecordPopover();
    setOffsetPopover([0, 0]);
  };

  const fncActionTable = (record, offsetPopover, visiblePopoverTable, ref) => {
    return (
      <div>
        <Popover
          placement="right"
          trigger="hover"
          align={{ offset: offsetPopover }}
          visible={visiblePopoverTable}
          zIndex={10000}
          content={
            <div style={{ display: 'grid' }}>
              {checkPermissionAction(`P52PG1C2`, `P52PG1C2A3`) && _.size(_.get(warehouseDetail, 'isManager')) > 0 ? (
                <div>
                  <Button style={{ width: 100 }} type="link" ghost onClick={() => openModalEdit(record)}>
                    <FormattedMessage id="orgTabMemberBtnEdit" defaultMessage="Edit" />
                  </Button>
                </div>
              ) : null}
              {checkPermissionAction(`P52PG1C2`, `P52PG1C2A4`) && _.size(_.get(warehouseDetail, 'isManager')) > 0 ? (
                <div>
                  <Button style={{ width: 100 }} type="link" ghost onClick={() => handleDelete(record.managerId)}>
                    <FormattedMessage id="btnDelete" defaultMessage="Delete" />
                  </Button>
                </div>
              ) : null}
            </div>
          }
        >
          <div style={{ width: 0, height: 0 }} ref={ref}></div>
        </Popover>
      </div>
    );
  };

  return (
    <div>
      <div className="layout-wh-manager">
        <div>
          <p className="layout-wh-manager-title">
            <FormattedMessage id="warehouseManagerTitle" defaultMessage="Warehouse Manager" />
          </p>
        </div>
        {checkPermissionAction(`P52PG1C2`, `P52PG1C2A2`) && _.size(_.get(warehouseDetail, 'isManager')) > 0 ? (
          <div>
            <Button_01 key="submit" type="primary" btnsize="wd_df" style={{ marginRight: 'unset' }} onClick={() => handleOpen()}>
              <FormattedMessage id="btnAdd" defaultMessage="Add" />
            </Button_01>
          </div>
        ) : null}
      </div>
      <Divider type="horizontal" style={{ marginTop: '12px', marginBottom: '12px' }} />
      <div className="warehouse-layout-details" onMouseLeave={handleMouseLeave}>
        <Table
          rowKey={(record) => record.managerId}
          size={'middle'}
          columns={columns}
          sizeWidth="lg"
          dataSource={warehouseManagerList}
          pagination={false}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                if (
                  (_.size(_.get(warehouseDetail, 'isManager')) > 0 && checkPermissionAction(`P52PG1C2`, `P52PG1C2A3`)) ||
                  (_.size(_.get(warehouseDetail, 'isManager')) > 0 && checkPermissionAction(`P52PG1C2`, `P52PG1C2A4`))
                ) {
                  if (!event.target.href) {
                    const { x, y } = ref.current.getBoundingClientRect();
                    setVisiblePopoverTable(true);
                    setOffsetPopover([event.pageX - x, event.pageY - y]);
                    setRecordPopover(record);
                  }
                }
              },
            };
          }}
        />
        {fncActionTable(recordPopover, offsetPopover, visiblePopoverTable, ref)}
      </div>
      <ModalWarehouseManager visible={isOpen} setVisible={setIsOpen} record={recordManage} setRecord={setRecordManage} />
    </div>
  );
};

export default Manager;
