import React, { useState, useEffect, useRef } from 'react';
import { Modal, Input, Select, Icon, Spin } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import Button_02 from '../../../../../components/v2/button_02';
import Button_01 from '../../../../../components/v2/button_01';
import { handleKeyDownDecimal2Fixed } from '../../../../../component-function/fnc-number';
import _ from 'lodash';
import DeliveryWeightPacel from './weight-parcel';
import { updateParcel } from '../../../../../controllers/orders-controller/api';
import { successNotification, errorNotification } from '../../../../../components/v2/notification';
import PrintLabel from '../component/print';
import { useReactToPrint } from 'react-to-print';
import { getQueueMessages } from '../../../../../hooks/use-queue-api';

const comCode = localStorage.getItem('comCode');

const DeliveryParcel = (props) => {
  const { visible, setVisible, orderData, sizeList, typeModalParcel, setTypeModalParcel, orderId, setTrigger } = props;
  const componentRef = useRef();
  const intl = useIntl();
  const [shippingPrice, setShippingPrice] = useState('0.00');
  const [visibleWeight, setVisibleWeight] = useState(false);
  const [weight, setWeight] = useState('0.00');
  const [size, setSize] = useState();
  const [loading, setLoading] = useState(false)
  const address = _.get(orderData, 'destinationDetail.address') || '';
  const postalCodePattern = /\b\d{5}\b/;
  const postalCodeMatch = address.match(postalCodePattern);
  const postalCode = postalCodeMatch ? postalCodeMatch[0] : '00000';

  useEffect(() => {
    if (typeModalParcel) {
      setSize(_.get(typeModalParcel, 'size.sizeId'));
      setWeight((_.get(typeModalParcel, 'weight') || 0).toFixed(2));
      setShippingPrice(_.get(typeModalParcel, 'price'));
    }
  }, [visible]);

  useEffect(() => {
    if (weight && orderData) {
      if (parseFloat(weight) > 0) {
        const calParcel = _.get(orderData, 'calculationFormulaForPacel') || '';
        const result = calParcel.replace('${weight}', weight ? parseFloat(weight) : 0);
        const calResult = new Function('return ' + result)();
        setShippingPrice(parseFloat(calResult).toFixed(2));
      } else {
        setShippingPrice('0.00');
      }
    }
  }, [weight, orderData]);

  const handleSave = async (code) => {
    if (code === 'confirmed') {
      Modal.confirm({
        className: 'customer-modal-confirm',
        centered: true,
        icon: null,
        title: intl.formatMessage({
          id: 'deliveryParcelTrackingDetailConfirm',
          defaultMessage: 'Do you want to confirm for this parcel ?',
        }),
        okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
        cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
        okButtonProps: {
          style: {
            width: '96px',
          },
          type: 'primary',
        },
        cancelButtonProps: {
          style: {
            width: '96px',
          },
          type: 'danger',
        },
        async onOk() {
          handleSaveModal(code);
        },
        onCancel() {},
      });
    } else {
      handleSaveModal(code);
    }
  };

  const handleSaveModal = async (code) => {
    setLoading(true)
    const filterSize = _.filter(sizeList, (item) => {
      return item.id === size;
    });
    const body = {
      deliveryOrderDetailId: typeModalParcel ? _.get(typeModalParcel, '_id') : undefined,
      weight: parseFloat(weight).toFixed(2),
      shippingPrice: parseFloat(shippingPrice).toFixed(2),
      statusCode: code,
      size: {
        sizeId: _.get(filterSize, '[0].id'),
        sizeCode: _.get(filterSize, '[0].sizeCode'),
        sizeName: _.get(filterSize, '[0].sizeName'),
        width: _.get(filterSize, '[0].width'),
        length: _.get(filterSize, '[0].length'),
        height: _.get(filterSize, '[0].height'),
      },
    };

    const payload = {
      apiMethod: 'post',
      urlQueue: `/queue/manager/company/${comCode}/channgedeliveryorder`,
      apiUrl: `${process.env.REACT_APP_URL_MANAGER}/order-manager/v5/order/manager/company/${comCode}/delivery-order/${orderId}/upsert-delivery-order-detail`,
      payload: body,
    };

    try {
      const response = await getQueueMessages(payload);
      if (_.get(response, 'status.code') === 200) {
        successNotification(_.get(response, 'status.message'));
        setTrigger((cur) => !cur);
        setVisible(false);
        setSize();
        setWeight('0.00');
        setShippingPrice('0.00');
      } else {
        errorNotification(_.get(response, 'status.message'));
      }
    } catch (error) {
      errorNotification(_.get(error, 'response.status.message'));
    }
    setLoading(false)

    // const response = await updateParcel(orderId, body);
    // if (response.status === 200) {
    //   successNotification(response.data.status.message);
    //   setTrigger(cur => !cur)
    //   setVisible(false)
    //   setSize()
    //   setWeight('0.00')
    //   setShippingPrice('0.00')
    // } else {
    //   errorNotification(response.data.status.message);
    // }
  };

  const handleCancel = () => {
    setVisible(false);
    setTypeModalParcel();
    setSize();
    setWeight('0.00');
    setShippingPrice('0.00');
  };

  const handleChange = (value) => {
    setShippingPrice(value);
  };

  const handleOpenModal = () => {
    setVisibleWeight(true);
  };

  const handleSelectSize = (value) => {
    setSize(value);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'Parcel',
    copyStyles: true,
  });

  const checkType = typeModalParcel
    ? _.get(typeModalParcel, 'status.statusCode') === 'canceled'
      ? [
          <Button_02 key="back" btnsize="wd_df" onClick={() => handleCancel()} disabled={loading}>
            <FormattedMessage id="btnClose" defaultMessage="Close" />
          </Button_02>,
        ]
      : _.get(typeModalParcel, 'status.statusCode') === 'confirmed' && _.get(orderData, 'statusDetail.statusCode') === 'open'
      ? [
          <Button_02 key="back" btnsize="wd_df" onClick={() => handleCancel()} disabled={loading}>
            <FormattedMessage id="btnClose" defaultMessage="Close" />
          </Button_02>,
          <Button_02 style={{ margin: 'unset' }} key="back" btnsize="wd_at" onClick={() => handleSave('canceled')} margin="unset" disabled={loading}>
            <FormattedMessage id="deliveryCancelParcel" defaultMessage="Cancel Parcel" />
          </Button_02>,
          <Button_01 key="submit" type="primary" btnsize="wd_at" onClick={handlePrint} disabled={loading}>
            <FormattedMessage id="deliveryParcelbtnPrint" defaultMessage="Print" />
          </Button_01>,
        ]
      : _.get(typeModalParcel, 'status.statusCode') === 'waiting' && _.get(orderData, 'statusDetail.statusCode') === 'open'
      ? [
          <Button_02 key="back" btnsize="wd_df" onClick={() => handleCancel()} disabled={loading}>
            <FormattedMessage id="btnClose" defaultMessage="Close" />
          </Button_02>,
          <Button_02 style={{ margin: 'unset' }} key="back" btnsize="wd_at" onClick={() => handleSave('canceled')} margin="unset" disabled={loading}>
            <FormattedMessage id="deliveryCancelParcel" defaultMessage="Cancel Parcel" />
          </Button_02>,
          <Button_01
            key="submit"
            type="primary"
            btnsize="wd_df"
            disabled={parseFloat(weight || 0) <= 0 ? true : false || loading}
            onClick={() => handleSave('confirmed')}
          >
            <FormattedMessage id="btnConfirm" defaultMessage="Confirm" />
          </Button_01>,
        ]
      : [
          <Button_02 key="back" btnsize="wd_df" onClick={() => handleCancel()} disabled={loading}>
            <FormattedMessage id="btnClose" defaultMessage="Close" />
          </Button_02>,
          <Button_01 style={{ margin: 'unset' }} key="submit" type="primary" btnsize="wd_at" onClick={handlePrint} disabled={loading}>
            <FormattedMessage id="deliveryParcelbtnPrint" defaultMessage="Print" />
          </Button_01>,
        ]
    : [
        <Button_02 key="back" btnsize="wd_df" onClick={() => handleCancel()} margin="unset" disabled={loading}>
          <FormattedMessage id="btnClose" defaultMessage="Close" />
        </Button_02>,
        <Button_01
          key="submit"
          type="primary"
          btnsize="wd_df"
          onClick={() => handleSave('confirmed')}
          disabled={parseFloat(weight || 0) <= 0 ? true : false || loading}
        >
          <FormattedMessage id="btnConfirm" defaultMessage="Confirm" />
        </Button_01>,
      ];

  const getNumbersFromString = (str) => {
    const numbers = str.match(/\d+/g); // Regular expression to match one or more digits
    return numbers ? numbers.map(Number) : [];
  };

  console.log(
    'typeModalParcel',
    typeModalParcel,
    orderData,
    _.map(postalCode, (e) => getNumbersFromString(e))
  );

  return (
    <Modal
      title={`${intl.formatMessage({ id: `deliveryParcelTrackingDetail`, defaultMessage: 'Tracking Details' })} ${_.get(typeModalParcel, 'pacelNo') ? ` · ${_.get(typeModalParcel, 'pacelNo')}` : ''}`}
      centered={true}
      width={380}
      visible={visible}
      onCancel={loading === false ? handleCancel : null}
      footer={checkType}
    >
      <Spin spinning={loading}>
      
      <div style={{ marginBottom: '21px' }}>
        <div style={{ color: '#1D3557', fontSize: '12px', marginBottom: '8px', fontWeight: 600 }}>
          <FormattedMessage id="deliveryParcelSize" defaultMessage="Size" />
        </div>
        <div>
          <Select
            style={{ width: '100%' }}
            placeholder={intl.formatMessage({ id: `deliveryParcelPHSize`, defaultMessage: 'Select Size' })}
            showSearch
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            onSelect={handleSelectSize}
            value={size}
            disabled={!typeModalParcel || _.get(typeModalParcel, 'status.statusCode') === 'waiting' ? false : true}
          >
            {_.map(sizeList, (item) => (
              <Select.Option value={item.id}>{item.sizeName}</Select.Option>
            ))}
          </Select>
        </div>
      </div>
      <div style={{ textAlign: 'center', backgroundColor: '#F2F6FD', padding: '16px', marginBottom: '16px' }}>
        <div style={{ color: '#1D3557', fontSize: '12px', fontWeight: 600, marginBottom: '8px' }}>
          <FormattedMessage id="deliveryWeightParcelText" defaultMessage="Weight (kg)" />
          {_.get(typeModalParcel, 'status.statusCode') === 'canceled' ||
          _.get(typeModalParcel, 'status.statusCode') === 'confirmed' ? null : (
            <span>
              {' '}
              <Icon type="edit" style={{ color: '#6490CF', cursor: 'pointer' }} onClick={handleOpenModal} />
            </span>
          )}
        </div>
        <div className="deliveryTextSM">{parseFloat(weight).toFixed(2)}</div>
      </div>
      <div style={{ textAlign: 'center', backgroundColor: '#F2F6FD', padding: '16px' }}>
        <div style={{ color: '#1D3557', fontSize: '12px', fontWeight: 600, marginBottom: '8px' }}>
          <FormattedMessage id="deliveryShippingPriceParcelText" defaultMessage="Shipping Price" />
        </div>
        <div>
          <Input
            className="deliveryInputNumberSM"
            onKeyDown={handleKeyDownDecimal2Fixed}
            value={shippingPrice}
            onChange={(e) => handleChange(e.target.value)}
            readOnly={
              _.get(typeModalParcel, 'status.statusCode') === 'canceled' || _.get(typeModalParcel, 'status.statusCode') === 'confirmed'
                ? true
                : false
            }
          />
        </div>
      </div>

      <DeliveryWeightPacel visible={visibleWeight} setVisible={setVisibleWeight} setWeight={setWeight} weight={weight} />
      <div style={{ display: 'none' }}>
        <PrintLabel
          ref={componentRef}
          order={orderData}
          parcel={typeModalParcel}
          zipcode={_.map(postalCode, (e) => getNumbersFromString(e))}
        />
      </div>
      </Spin>
    </Modal>
  );
};

export default DeliveryParcel;
