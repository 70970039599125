import React, { useState, useEffect, useContext } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useAppContext } from '../../../includes/indexProvider';
import Provider from '../provider';
import { Form, Input, Select, DatePicker, Switch, Col, Row, notification, ConfigProvider, Button, Menu, Dropdown } from 'antd';
import * as moment from 'moment';
import httpClient from '../../../components/axiosClient';
import Button01 from '../../../components/v2/button_01';
import cssStyle from './css/index.css';
import styled from 'styled-components';
import _, { get } from 'lodash';
import { error } from 'jquery';
import { PageSettings } from '../../../config/page-settings';
import { OrganizationContext } from '../organization-context';
import enUS from 'antd/es/locale/en_US';
import thTH from 'antd/es/locale/th_TH';
import 'moment/locale/th';
import { DownOutlined } from '@ant-design/icons';

// moment.locale('en');

const DivDetail = styled.div`
  padding: 0px 24px;
`;

const LabelRequire = styled.label`
  color: #ff1010;
`;

const { Option } = Select;

const dateFormat = 'DD MMMM YYYY';

const ValidatedFields = React.memo((props) => {
  const { stateMain, setStateMain } = useContext(OrganizationContext);
  const getLangValue = useAppContext();
  const intl = useIntl();
  // const app = Provider.useAppContext();
  const langValue = localStorage.getItem('langValue');

  // const appState = app.state;
  // const appFnc = app.fnc;

  const { getFieldDecorator, getFieldValue, validateFields, resetFields } = props.form;
  const { data, newForm, setRefreshSaveFrom, handleDelete, sendDetailBtn, setSendDetailBtn } = props;

  const memComId = localStorage.getItem('memComId');

  let status = props.data.status === 1 ? true : false;

  const addnew = stateMain.addNewOrganization;

  // const [teamType, setTeamType] = useState([]);
  const [dataForm, setDataForm] = useState({});
  const [selectTeamTypeId, setSelectTeamTypeId] = useState();
  const [dateStartValue, setDateStartValue] = useState();
  const [dateStartString, setDateStartString] = useState();
  const [dateEndValue, setDateEndValue] = useState();
  const [dateEndString, setDateEndString] = useState();
  const [description, setDescription] = useState('');
  const [getStatus, setGetStatus] = useState(false);
  const [locale, setLocale] = useState(enUS);
  const [disabledButton, setDisabledButton] = useState(false);

  const parentId = props.parentId;
  const orgId = props.data.org_id;
  const comId = localStorage.getItem('comId');

  // useEffect(() => {
  //   // const newTeamType = props.data.teamType ? props.data.teamType : [];
  //   // setTeamType(newTeamType);
  //   setDataForm(props.data);
  // }, [stateMain.orgId, props.data]);

  useEffect(() => {
    setSwitch();
  }, [status, orgId]);

  useEffect(() => {
    changeLocale();
  }, []);

  useEffect(() => {
    console.log('sendDetailBtn', sendDetailBtn);
    if (sendDetailBtn === true) {
      sendTeamDetail();
      setSendDetailBtn(false);
    }
  }, [sendDetailBtn]);

  const changeLocale = () => {
    let codeLang = langValue ? langValue : 'EN';
    let changeLang = codeLang === 'EN' ? enUS : thTH;
    setLocale(changeLang);
  };

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
      duration: 1.7,
    });
  };

  const setSwitch = () => {
    if (addnew === true) {
      setGetStatus(false);
    } else {
      setGetStatus(props.data.status === 1 ? true : false);
    }
  };

  const sendTeamDetail = () => {
    setDisabledButton(true);
    validateFields(async (err, values) => {
      console.log('LOLOl',values);

      if (err) {
        return
      }

      if (!newForm) {

        const data = {
          org_id: orgId,
          name: values.teamName,
          team_type_id: values.teamType,
          start_date: moment(values.startDate._d.valueOf()).format('YYYY-MM-DD'),
          end_date: moment(values.endDate._d.valueOf()).format('YYYY-MM-DD'),
          description: values.description,
          updated_by: memComId,
        };

        httpClient.post('/v2/update/organization', data).then(
          (response) => {
            if (response.status === 200) {
              openNotificationWithIcon('success', response.data.data);
              setRefreshSaveFrom((curren) => !curren);
              setStateMain.setTriggerApi((curren) => !curren);
              setDisabledButton(false);
              resetFields();
            } else {
              openNotificationWithIcon('error', response.data.data);
              setDisabledButton(false);
            }
          },
          (error) => {
            openNotificationWithIcon('error');
            setDisabledButton(false);
          }
        );
      } else {

        const data = {
          created_by: memComId,
          com_id: comId,
          name: values.teamName,
          parent_lavel: '1',
          parent_id: parentId ? parentId : '',
          team_type_id: selectTeamTypeId,
          start_date: dateStartString,
          end_date: dateEndString,
          description: description,
        };

        httpClient.post('/v2/create/organization', data).then(
          (response) => {
            if (response.status === 200) {
              openNotificationWithIcon('success', response.data.data);
              setStateMain.setParentId();
              setStateMain.setPageDefault(true);
              setStateMain.setValueDefault(data);

              setRefreshSaveFrom((curren) => !curren);
              setStateMain.setTriggerApi((curren) => !curren);

              setTimeout(() => {
                setStateMain.setOrgId(_.get(response, 'data.orgId'));
                setStateMain.setOrgIdSelect(_.get(response, 'data.orgId'));
                setStateMain.setAddNewOrganization(false);
              }, 500);
              resetFields();
              setDisabledButton(false);
            } else {
              openNotificationWithIcon('error', response.data.data);
              setStateMain.setValueDefault();
              setDisabledButton(false);
            }
          },
          (error) => {
            openNotificationWithIcon('error');
            setDisabledButton(false);
          }
        );
      }
    });
  };

  const handleTeamNameChange = (e) => {
    console.log('e',e.target.value);
  };

  const handleSelectTeamType = (select) => {
    setSelectTeamTypeId(select);
  };

  const handleDateStart = (value, dateString) => {
    setDateStartValue(value);
    setDateStartString(value.format('YYYY-MM-DD'));
  };

  const handleDateEnd = (value, dateString) => {
    setDateEndValue(value);
    setDateEndString(value.format('YYYY-MM-DD'));
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const onSwitchChange = (checked) => {
    setGetStatus(checked);
    if (!addnew === true) {
      if (!parentId) {
        let status_work = checked ? 1 : 0;
        let data = {
          org_id: orgId,
          updated_by: memComId,
          status: status_work,
        };
        try {
          httpClient
            .post('/v2/update/organization/', data)
            .then((response) => {
              if (response.status === 200) {
                openNotificationWithIcon('success', response.data.data);
              } else {
                openNotificationWithIcon('error', response.data.data);
              }
            })
            .catch((error) => {
              openNotificationWithIcon('error', error.message);
            });
        } catch (error) {
          return;
        }
      }
    }
  };

  const handleChangeEndDate = (rule, value, callback) => {
    const errors = [];
    if (value && dateStartString) {
      let startDate = moment(dateStartString)._d.valueOf();
      let endDate = value._d.valueOf();
      if (endDate < startDate) {
        errors.push(
          <span>
            {intl.formatMessage({ id: 'orgTabDetailValidateEndDateLess', defaultMessage: 'End date is less than the start date.' })}
          </span>
        );
      }
    }
    callback(errors);
  };

  const LabeRequire = (props) => {
    const { text, req } = props;
    return (
      <span>
        {text}&nbsp;
        {req ? <LabelRequire>*</LabelRequire> : ''}
      </span>
    );
  };

  const menuColumn = () => {
    return (
      <PageSettings.Consumer>
        {({ checkPermissionAction }) => (
          <Menu>
            {checkPermissionAction('P3PG1C2', 'P3PG1C2A3') ? (
              <Menu.Item>
                <Button ghost type="link" onClick={sendTeamDetail} disabled={disabledButton}>
                  <FormattedMessage id="btnSave" defaultMessage="Save" />
                </Button>
              </Menu.Item>
            ) : null}

            {checkPermissionAction('P3PG1C1', 'P3PG1C1A3') ? (
              <Menu.Item>
                <Button ghost type="link" onClick={() => handleDelete()}>
                  <FormattedMessage id="orgTreeBtnDelete" defaultMessage="Delete" />
                </Button>
              </Menu.Item>
            ) : null}
          </Menu>
        )}
      </PageSettings.Consumer>
    );
  };

  return (
    <PageSettings.Consumer>
      {({ checkPermissionAction }) => (
        <DivDetail>
          <ConfigProvider locale={locale}>
            <Form className="organization-detail-form" colon={false} hideRequiredMark={true}>
              <Row gutter={[16, 16]} align="middle">
                <Col className="gutter-row" span={24} style={{ textAlign: 'right' }}>
                  <div>
                    {checkPermissionAction('P3PG1C2', 'P3PG1C2A2') ? (
                      <Switch onChange={onSwitchChange} checked={getStatus} defaultChecked={getStatus} />
                    ) : null}

                    {/* {checkPermissionAction('P3PG1C2', 'P3PG1C2A3') || checkPermissionAction('P3PG1C1', 'P3PG1C1A3') ? (
                      <Dropdown overlay={menuColumn} trigger={['click']} placement="bottomRight">
                        <Button01 key="submit" type="primary" btnsize="wd_df" onClick={() => {}}>
                          <FormattedMessage id="btnActionGroup" defaultMessage="Action" />
                          <DownOutlined style={{ fontSize: '14px', paddingLeft: '22%' }} />
                        </Button01>
                      </Dropdown>
                    ) : null} */}
                  </div>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col className="gutter-row" span={8}>
                  <Form.Item
                    className="organization-detail-formItem"
                    label={<LabeRequire text={<FormattedMessage id="orgTabDetailLblTeamName" defaultMessage="Team Name" />} req={true} />}
                  >
                    {getFieldDecorator('teamName', {
                       initialValue: data && data.name ? data.name : undefined,
                      rules: [
                        {
                          required: true,
                          message: intl.formatMessage({ id: 'orgTabDetailRequireTeamName', defaultMessage: 'Please enter team name.' }),
                        },
                        {
                          max: 30,
                          message: intl.formatMessage({
                            id: 'orgTabDetailRequireTeamNameChar',
                            defaultMessage: 'Team Name cannot be longer than 30 characters.',
                          }),
                        },
                      ],
                    })(
                      <Input
                        placeholder={intl.formatMessage({ id: 'orgTabDetailHintTeamName', defaultMessage: 'Team name' })}
                        className="organization-detail-input"
                        onChange={handleTeamNameChange}
                        autoComplete="off"
                        disabled={checkPermissionAction('P3PG1C2', 'P3PG1C2A3') ? false : true}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={8} offset={1}>
                  <Form.Item
                    className="organization-detail-formItem"
                    label={<LabeRequire text={<FormattedMessage id="orgTabDetailLblTeamType" defaultMessage="Team Type" />} req={true} />}
                  >
                    {getFieldDecorator('teamType', {
                       initialValue: data && data.team_type_id ? data.team_type_id : undefined,
                      rules: [
                        {
                          required: true,
                          message: intl.formatMessage({ id: 'orgTabDetailRequireTeamType', defaultMessage: 'Please select team type.' }),
                        },
                      ],
                    })(
                      <Select
                        className="organization-detail-select"
                        showSearch
                        placeholder={intl.formatMessage({ id: 'orgTabDetailHintSelectTeamType', defaultMessage: 'Team type' })}
                        onChange={handleSelectTeamType}
                        disabled={checkPermissionAction('P3PG1C2', 'P3PG1C2A3') ? false : true}
                      >
                        {props.teamTypeData &&
                          props.teamTypeData.map((item) => (
                            <Option key={item.team_type_id} value={item.team_type_id}>
                              {item.name}
                            </Option>
                          ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col className="gutter-row" span={8}>
                  <Form.Item
                    className="organization-detail-formItem"
                    label={<LabeRequire text={<FormattedMessage id="orgTabDetailLblStartDate" defaultMessage="Start Date" />} req={true} />}
                  >
                    {getFieldDecorator('startDate', {
                      initialValue: data && data.start_date ? moment(data.start_date, 'YYYY-MM-DD') : undefined,
                      rules: [
                        {
                          required: true,
                          message: intl.formatMessage({ id: 'orgTabDetailRequireStartDate', defaultMessage: 'Please select start date.' }),
                        },
                      ],
                    })(
                      <DatePicker
                        className="organization-detail-datePicker"
                        dropdownClassName="organization-datePicker-dropdown"
                        format={dateFormat}
                        placeholder={intl.formatMessage({ id: 'orgTabDetailLblStartDate', defaultMessage: 'Strat Time' })}
                        onChange={handleDateStart}
                        style={{ width: '100%' }}
                        allowClear={false}
                        disabled={checkPermissionAction('P3PG1C2', 'P3PG1C2A3') ? false : true}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={8} offset={1}>
                  <Form.Item
                    className="organization-detail-formItem"
                    label={<LabeRequire text={<FormattedMessage id="orgTabDetailLblEndDate" defaultMessage="End Date" />} req={true} />}
                  >
                    {getFieldDecorator('endDate', {
                      initialValue: data && data.end_date ? moment(data.end_date, 'YYYY-MM-DD') : undefined,
                      rules: [
                        {
                          required: true,
                          message: intl.formatMessage({ id: 'orgTabDetailRequireEndDate', defaultMessage: 'Please select end date.' }),
                        },
                        {
                          validator: handleChangeEndDate,
                        },
                      ],
                    })(
                      <DatePicker
                        className="organization-detail-datePicker"
                        dropdownClassName="organization-datePicker-dropdown"
                        format={dateFormat}
                        placeholder={intl.formatMessage({ id: 'orgTabDetailLblEndDate', defaultMessage: 'End Time' })}
                        onChange={handleDateEnd}
                        style={{ width: '100%' }}
                        allowClear={false}
                        disabled={checkPermissionAction('P3PG1C2', 'P3PG1C2A3') ? false : true}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col className="gutter-row" span={17}>
                  <Form.Item
                    className="organization-detail-formItem"
                    label={<LabeRequire text={<FormattedMessage id="orgTabDetailLblDescription" defaultMessage="Description" />} />}
                  >
                    {getFieldDecorator(
                      'description',
                      {
                        initialValue: data && data.description ? data.description : undefined,
                      }
                    )(
                      <Input
                        placeholder={intl.formatMessage({ id: 'orgTabDetailHintDescription', defaultMessage: 'Description' })}
                        autosize={{ minRows: 1, maxRows: 1 }}
                        onChange={handleDescriptionChange}
                        autoComplete="off"
                        disabled={checkPermissionAction('P3PG1C2', 'P3PG1C2A3') ? false : true}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </ConfigProvider>
        </DivDetail>
      )}
    </PageSettings.Consumer>
  );
});

// const onFieldsChange = (_, changedFiels) => {
//   const { username } = changedFiels;
// };

const DetailForm = Form.create({
  name: 'modal_form',
})(ValidatedFields);

// const DetailForm = React.memo(
//   Form.create({
//     onFieldsChange,

//     mapPropsToFields(props) {
//       const { data } = props;

//       return {
//         teamName: Form.createFormField({
//           value: data && data.name ? data.name : undefined,
//         }),
//         teamType: Form.createFormField({
//           value: data && data.team_type_id ? data.team_type_id : undefined,
//         }),
//         startDate: Form.createFormField({
//           value: data && data.start_date ? moment(data.start_date, 'YYYY-MM-DD') : undefined,
//         }),
//         endDate: Form.createFormField({
//           value: data && data.end_date ? moment(data.end_date, 'YYYY-MM-DD') : undefined,
//         }),
//         description: Form.createFormField({
//           value: data && data.description ? data.description : undefined,
//         }),
//       };
//     },

//     // mapPropsToFields(props) {
//     //   const orgId = props.orgId;
//     //   const valueDefault = props.valueDefault;
//     //   const { name, team_type_id, description, start_date, end_date } = props.data;
//     //   const newForm = props.newForm;

//     //   const teamDetailName = _.get(props,"teamDetail.name");
//     //   const teamDetailTeamTypeId = _.get(props,"teamDetail.team_type_id");
//     //   const teamDetailStartDate = _.get(props,"teamDetail.start_date");
//     //   const teamDetailEndDate = _.get(props,"teamDetail.end_date");
//     //   const teamDetailDescription = _.get(props,"teamDetail.description");

//     //   const defalutTeamName = _.get(valueDefault, 'name');
//     //   const defalutTeamType = _.get(valueDefault, 'team_type_id');
//     //   const defalutStartDate = _.get(valueDefault, 'start_date');
//     //   const defalutEndDate = _.get(valueDefault, 'end_date');
//     //   const defalutDescription = _.get(valueDefault, 'description');

//     //   const teamName = _.isUndefined(orgId) ? '' : name;
//     //   const teamType = _.isUndefined(orgId) ? undefined : team_type_id;
//     //   const descriptions = _.isUndefined(orgId) ? '' : description;
//     //   const startdate = _.isUndefined(orgId) ? '' : start_date;
//     //   const enddate = _.isUndefined(orgId) ? '' : end_date;

//     //   let formatStartDate = null;
//     //   if (teamDetailStartDate !== '') {
//     //     formatStartDate = moment(teamDetailStartDate, 'YYYY-MM-DDTHH:mm:ss.SSSSZ').format('YYYY-MM-DD');
//     //   } else if (teamDetailStartDate == '') {
//     //     formatStartDate = null;
//     //   }

//     //   let formatEndDate = null;
//     //   if (teamDetailEndDate !== '') {
//     //     formatEndDate = moment(teamDetailEndDate, 'YYYY-MM-DDTHH:mm:ss.SSSSZ').format('YYYY-MM-DD')
//     //   } else if (teamDetailEndDate == '') {
//     //     formatEndDate = null;
//     //   }

//     //   if (newForm === true) {
//     //     return {
//     //       teamName: Form.createFormField({ value: defalutTeamName ? defalutTeamName : undefined }),
//     //       teamType: Form.createFormField({ value: defalutTeamType ? defalutTeamType : undefined }),
//     //       startDate: Form.createFormField({ value: defalutStartDate ? moment(defalutStartDate, 'YYYY-MM-DD') : undefined }),
//     //       endDate: Form.createFormField({ value: defalutEndDate ? moment(defalutEndDate, 'YYYY-MM-DD') : undefined }),
//     //       description: Form.createFormField({ value: defalutDescription ? defalutDescription : undefined }),
//     //     };
//     //   } else if (newForm === false) {
//     //     return {
//     //       teamName: Form.createFormField({ value: teamDetailName }),
//     //       teamType: Form.createFormField({ value: teamDetailTeamTypeId }),
//     //       startDate: Form.createFormField({ value: moment(formatStartDate, 'YYYY-MM-DD') }),
//     //       endDate: Form.createFormField({ value: moment(formatEndDate, 'YYYY-MM-DD') }),
//     //       description: Form.createFormField({ value: teamDetailDescription }),
//     //     };
//     //   }
//     // },
//   })(ValidatedFields)
// );
export default DetailForm;
