import React, { useState, useEffect } from 'react'
import { Modal, Form, Spin } from 'antd'
import _ from 'lodash'
import { useIntl, FormattedMessage } from 'react-intl'
import Button_01 from '../../../../components/v2/button_01'
import Button_02 from '../../../../components/v2/button_02'
import ExpenseFormVendor from './form-vendor'
import ExpenseFormOrder from './form-order'
import ExpenseAdditional from './additional-item'
import ExpenseAttachment from './attachment'
import ListItem from '../list-po'
import { getVendor, getCustomerContact } from '../../../../controllers/customer-vendor/customer-vendor'
import { useDebounce } from '../../../../controllers/debounce'
import ListItemProduct from '../../../../components/list-item/list'
import ListItemService from '../../../../components/list-item/list-service'
import ModalCreateListItem from '../../../../components/list-item/add-product'
import ModalEditListItem from '../../../../components/list-item/edit-product'
import ModalCreateListServiceItem from '../../../../components/list-item/add-service'
import ModalEditListServiceItem from '../../../../components/list-item/edit-service'
import { editExpesne, updateAttExpense, getExpenseDetail, getAttExpense, deleteAttExpense } from '../../../../controllers/expense/expense'
import { successNotification, errorNotification } from '../../../../components/v2/notification'
import moment from 'moment'
import ModalUploadFile from '../../../../components/modal-upload'
import Resizer from 'react-image-file-resizer';

export const ExpenseModal = React.createContext();

const ExpenseEditForm = (props) => {
  const { visible, setVisible, form, userList, vendorList, taxList, setTrigger, defaultData } = props
  const intl = useIntl()
  const { setFieldsValue, validateFields, resetFields, getFieldValue } = form
  const [customerList, setCustomerList] = useState([])
  const [contactList, setContactList] = useState([])
  const [searchCustomer, setSearchCustomer] = useState('')
  const debouceSearchCustomer = useDebounce(searchCustomer, 1000)
  const [selectCustomer, setSelectCustomer] = useState()
  const [selectItem, setSelectItem] = useState([])
  const [visibleListPo, setVisibleListPo] = useState(false)
  const [poSelect, setPOSelect] = useState()

  const [visibleListProduct, setVisibleListProduct] = useState(false)
  const [visibleAddProduct, setVisibleAddProduct] = useState(false)
  const [visibleEditProduct, setVisibleEditProduct] = useState(false)
  const [creatProduct, setCreatProduct] = useState()
  const [editProduct, setEditProduct] = useState()

  const [visibleListService, setVisibleListService] = useState(false)
  const [visibleAddService, setVisibleAddService] = useState(false)
  const [visibleEditService, setVisibleEditService] = useState(false)
  const [creatService, setCreatService] = useState()
  const [editService, setEditService] = useState()
  const [openUpload, setOpenUpload] = useState(false);
  const [attachmentData, setAttachmentData] = useState([])
  const [typeAttachment, setTypeAttachment] = useState('add');
  const [defaultAttachment, setDefaultAttachment] = useState();
  const [editDefault, setEditDefault] = useState()
  const [loading, setLoading] = useState(false)

  const checkButton = () => {
    // if (poSelect) {
      if (parseFloat(getFieldValue('amount') || 0) === 0) {
        return false
      } else {
        return true
      }
    // } else {
    //   return false
    // }
  }

  const checkPO = checkButton()
  const checkItem = _.size(selectItem) > 0 ? true : false
  const disbleButton = checkPO ? false : checkItem ? false : true
  const loadingButton = loading ? loading : disbleButton


  useEffect(() => {
    const getDataDefault = async () => {
      setLoading(true)
      const response = await getExpenseDetail(_.get(defaultData, 'expenseId'));
      const edit = _.get(response, 'data.data[0]')
      setEditDefault(_.get(response, 'data.data[0]'))
      setSelectItem(_.map(_.get(response, 'data.data[0].expenseDetail'), (item, index) => {
        return {
          "index": index + 1,
          "itemMasterId": _.get(item, 'item.itemId'),
          "itemCode": _.get(item, 'item.itemCode'),
          "itemName": _.get(item, 'item.itemName'),
          "qty": parseFloat(_.get(item, 'qty')),
          "count": parseFloat(_.get(item, 'qty')),
          "countTemp": parseFloat(_.get(item, 'qty')),
          "uomId": _.get(item, 'unit.unitId'),
          "uomName": _.get(item, 'unit.unit'),
          "pricePerUnit": parseFloat(_.get(item, 'price')),
          "discountPercentage": parseFloat(_.get(item, 'discount.percent')),
          "discountPrice": parseFloat(_.get(item, 'discount.total')),
          "totalPrice": parseFloat(_.get(item, 'afterDiscount')),
          "remark": _.get(item, 'remark') || "",
          "type": _.get(item, 'item.type') || "",
          "instock": 0,
        }
      }))
      setSelectCustomer({
        "customerId": _.get(edit, 'vendor.vendorId'),
        "customerName": _.get(edit, 'vendor.vendorName'),
        "customerCode": _.get(edit, 'vendor.vendorCode')
      })
      setSearchCustomer(_.get(edit, 'vendor.vendorName'))
      if (_.get(edit, 'poNo')) setPOSelect({
        poNo: _.get(edit, 'poNo'),
        poDate: _.get(edit, 'poDate'),
        subtotal: parseFloat(_.get(edit, 'totalPo')),
        totolAccruedExpense: parseFloat(_.get(edit, 'accruedExpense')),
        vendorSupplierContactDetail: {
          vendorSupplierContactId: _.get(edit, 'vendor.vendorContactId')
        }
      })
      setFieldsValue({
        ['vendor']: _.get(edit, 'vendor.vendorName'),
        ['contact']: _.get(edit, 'vendor.vendorContactId'),
        ['phone']: _.get(edit, 'vendor.vendorContactPhone'),
        ['invoiceNo']: _.get(edit, 'invoiceNo'),
        ['invoiceDate']: _.get(edit, 'invoiceDate') ? moment(_.get(edit, 'invoiceDate'), 'YYYY-MM-DD') : undefined,
        ['amount']: _.get(edit, 'amountInvoice'),
        ['issuedBy']: parseInt(_.get(edit, 'issued.memComId')),
        ['issuedDate']: moment(_.get(edit, 'issuedDate'), 'YYYY-MM-DD'),
        ['numberDueDate']: parseInt(_.get(edit, 'dueDateDay')),
        ['dueDate']: moment(_.get(edit, 'dueDate'), 'YYYY-MM-DD'),
        ['tax']: _.get(edit, 'tax.tax'),
        ['remark']: _.get(edit, 'remark'),
      });
      setLoading(false)
    };
    if (defaultData && visible) {
      getDataDefault();
    }
  }, [defaultData, visible])

  useEffect(() => {
    if (!loading) {
      const vendor = getFieldValue('vendor')
      if (vendor) {
        const filterCustomer = _.filter(customerList, (item) => {
          return item.customerId === vendor
        })
        if (_.size(filterCustomer) > 0) {
          setSelectCustomer(_.get(filterCustomer, '[0]'))
          setPOSelect()
          setFieldsValue({
            ['contact']: undefined,
            ['phone']: undefined,
            ['orderNo']: undefined,
            ['total']: '0.00',
            ['accrued']: '0.00',
            ['invoiceNo']: undefined,
            ['invoiceDate']: undefined,
            ['amount']: undefined
          });
        } else {
          setSelectCustomer()
          setContactList([])
          setPOSelect()
          setFieldsValue({
            ['contact']: undefined,
            ['phone']: undefined,
            ['orderNo']: undefined,
            ['total']: '0.00',
            ['accrued']: '0.00',
            ['invoiceNo']: undefined,
            ['invoiceDate']: undefined,
            ['amount']: undefined
          });
        }
      } else {
        setSelectCustomer()
        setContactList([])
        setPOSelect()
        setFieldsValue({
          ['contact']: undefined,
          ['phone']: undefined,
          ['orderNo']: undefined,
          ['total']: '0.00',
          ['accrued']: '0.00',
          ['invoiceNo']: undefined,
          ['invoiceDate']: undefined,
          ['amount']: undefined
        });
      }
    }
  }, [getFieldValue('vendor')])

  useEffect(() => {
    const getFileDefault = async () => {
      const att = await getAttExpense(_.get(defaultData, 'expenseId'))
      const mapAtt = _.map(_.get(att, 'data'), (item, index) => {
        return {
          id: item.fileHash,
          index: index + 1,
          attachmentName: item.fileName,
          remark: item.remark,
          file: [...item.fileUrl],
          fileRaw: item.fileUrl,
          type: 'uploaded'
        }
      })
      setAttachmentData(mapAtt)
    };
    if (defaultData && visible) {
      getFileDefault();
    }
  }, [defaultData, visible])

  useEffect(() => {
    const getCustomerList = async () => {
      const body = { "searchBy": "all", "searchVal": searchCustomer, "page": 1, "limit": 100, "orderBy": "" }
      const response = await getVendor(body)
      setCustomerList(_.get(response, 'customers'))
    }
    getCustomerList()
  }, [debouceSearchCustomer])

  useEffect(() => {
    const getCustomerContactList = async () => {
      const body = {
        "pageNumber": 1,
        "limit": 1000,
        "orderType": "asc",
        "searchObj": { "customerContactName": "", "customerContactEmail": "", "customerContactPhone": "", "position": "" },
        "customerId": _.get(selectCustomer, 'customerId')
      }
      const response = await getCustomerContact(body)
      setContactList(_.get(response, 'data.list'))
    }
    if (selectCustomer) {
      getCustomerContactList()
    }
  }, [selectCustomer])

  const handleSave = (type) => {
    validateFields(async (err, values) => {
      if (err) {
        return;
      }
      const filterContact = _.filter(contactList, (item) => { return item.customerContactId === values.contact })
      const filterMember = _.filter(userList, (item) => { return item.mem_com_id === parseInt(values.issuedBy) })
      const filterTax = _.filter(taxList, (item) => { return item.tax === values.tax })
      const subtotal = _.sumBy(selectItem, 'totalPrice') + parseFloat(values.amount || 0)
      const subtotalTax = (parseFloat(subtotal) + parseFloat(values.taxNumber || 0))
      const body = {
        "vendor": {
          "vendorId": _.get(selectCustomer, 'customerId'),
          "vendorName": _.get(selectCustomer, 'customerName'),
          "vendorCode": _.get(selectCustomer, 'customerCode'),
          "vendorContactId": _.get(filterContact, '[0].customerContactId'),
          "vendorContactName": _.get(filterContact, '[0].customerContactName'),
          "vendorContactPhone": _.get(filterContact, '[0].customerContactPhone')
        },
        "issued": {
          "memComId": _.toString(_.get(filterMember, '[0].mem_com_id')),
          "name": _.get(filterMember, '[0].fullname')
        },
        "issuedDate": moment(values.issuedDate).format('YYYY-MM-DD'),
        "poNo": _.get(poSelect, 'poNo') || "",
        "poDate": _.get(poSelect, 'poDate') || "",
        "totalPo": _.toString(_.get(poSelect, 'subtotal') || 0.00),
        "accruedExpense": _.toString(_.get(poSelect, 'totolAccruedExpense') || 0.00),
        "invoiceNo": _.get(values, 'invoiceNo') || "",
        "invoiceDate": _.get(values, 'invoiceDate') ? moment(_.get(values, 'invoiceDate')).format('YYYY-MM-DD') : "",
        "amountInvoice": _.get(values, 'amount') || "",
        "dueDate": moment(values.dueDate).format('YYYY-MM-DD'),
        "dueDateDay": _.toString(values.numberDueDate),
        "expenseDetail": _.map(selectItem, (item) => {
          return {
            "item": {
              "itemId": item.itemMasterId,
              "itemCode": item.itemCode,
              "itemName": item.itemName,
              "type": item.type
            },
            "qty": _.toString(item.qty),
            "unit": {
              "unitId": item.uomId,
              "unit": item.uomName
            },
            "price": _.toString(item.pricePerUnit),
            "amount": _.toString(item.qty * item.pricePerUnit),
            "discount": {
              "percent": _.toString(item.discountPercentage),
              "total": _.toString(item.discountPrice)
            },
            "afterDiscount": _.toString((item.qty * item.pricePerUnit) - item.discountPrice),
            "remark": item.remark,
          }
        }),
        "status": {
          "code": type
        },
        "remark": _.get(values, 'remark') || "",
        "subTotal": _.toString(subtotal),
        "expenseTotal": _.toString(subtotalTax),
        "tax": {
          "taxId": _.get(filterTax, '[0]._id'),
          "tax": _.get(filterTax, '[0].tax'),
          "amount": _.toString(values.taxNumber)
        }
      }
      const response = await editExpesne(_.get(defaultData, 'expenseId'), body)
      if (response.status === 200) {
        successNotification(_.get(response, 'data.status.message'))
        setTrigger(cur => !cur)
        setVisible(false)
        resetFields()
        setSelectCustomer()
        setSelectItem([])
        setPOSelect()
        const filterNew = _.filter(attachmentData, (item) => {
          return _.get(item, 'type') !== 'uploaded' && _.get(item, 'delete') !== 'deleted'
        })
        if (_.size(attachmentData) > 0) await uploadData(filterNew, _.get(response, 'data.data.expenseId'))
        const deleteNew = _.filter(attachmentData, (item) => {
          return _.get(item, 'type') === 'uploaded' && _.get(item, 'delete') === 'deleted'
        })
        for (let index = 0; index < _.size(deleteNew); index++) {
          const element = deleteNew[index];
          await deleteAttExpense(element.id)
        }
        setAttachmentData([])
        setEditDefault()
      } else {
        errorNotification(_.get(response, 'data.status.message'))
      }
    })
  }

  const uploadData = async (att, id) => {
    for (let index = 0; index < att.length; index++) {
      const element = att[index];
      const temp = _.get(element, 'fileRaw')
      if (_.get(temp, 'type') === 'image/jpeg' || _.get(temp, 'type') === 'image/png') {
        const image = await resizeFile(temp);
        const fileChange = dataURLtoFile(image, _.get(temp, 'name').toLowerCase());
        let formData = new FormData();
        formData.append('file', fileChange);
        formData.append('expenseId', id);
        formData.append('remark', element.remark || "");
        await updateAttExpense(formData);
      } else if (_.get(temp, 'type') === 'application/pdf') {
        let formDataPDF = new FormData();
        formDataPDF.append('file', temp);
        formDataPDF.append('expenseId', id);
        formDataPDF.append('remark', element.remark || "");
        await updateAttExpense(formDataPDF);
      }
    }
  }

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        720,
        720,
        'JPG',
        70,
        0,
        (uri) => {
          resolve(uri);
        },
        'base64'
      );
    });

  const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    for (var i = 0; i < bstr.length; i++) {
      u8arr[i] = bstr.charCodeAt(i);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const handleCancel = () => {
    setVisible(false)
    resetFields()
    setSelectCustomer()
    setSelectItem([])
    setPOSelect()
    setAttachmentData([])
    setEditDefault()
  }

  const handleSearchCustomer = (value) => {
    setSearchCustomer(value);
  }

  const handleOpenModalPO = () => {
    setVisibleListPo(true)
  }

  const handleOpenModalListProduct = () => {
    setVisibleListProduct(true)
  }

  const handleOpenModalListService = () => {
    setVisibleListService(true)
  }

  const handleEditItem = (data) => {
    console.log("data", data)
    if (_.get(data, 'type') === "product") {
      setEditProduct(data)
      setVisibleEditProduct(true)
    } else if (_.get(data, 'type') === "service") {
      setEditService(data)
      setVisibleEditService(true)
    }
  }

  const handleCreateUpload = () => {
    setTypeAttachment('add')
    setOpenUpload(true)
  }

  const handleEditUpload = (data) => {
    setDefaultAttachment(data)
    setTypeAttachment('edit')
    setOpenUpload(true)
  }

  return (
    <ExpenseModal.Provider
      value={{
        customerList,
        contactList,
        userList,
        poSelect,
        selectItem,
        taxList,
        attachmentData,
        setSelectItem,
        setAttachmentData,
        handleSearchCustomer,
        handleOpenModalPO,
        handleOpenModalListProduct,
        handleOpenModalListService,
        handleEditItem,
        handleCreateUpload,
        handleEditUpload
      }}
    >
      <Modal
        title={intl.formatMessage({ id: `expenseEditTitle`, defaultMessage: 'Edit Expense' })}
        centered={true}
        width={1100}
        visible={visible}
        onCancel={handleCancel}
        zIndex={999}
        bodyStyle={{ padding: 'unset', height: 'calc(100vh - 280px)', overflowY: 'auto' }}
        footer={[
          <Button_02 key="back" btnsize="wd_df" onClick={() => handleCancel()} margin=" unset">
            <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
          </Button_02>,
          <Button_01 key="submit" type="primary" btnsize="wd_df" onClick={() => handleSave('draft')} >
            <FormattedMessage id="btnDraft" defaultMessage="Save Draft" />
          </Button_01>,
          <Button_01 style={{ margin: 'unset' }} key="submit" type="primary" btnsize="wd_df" disabled={loadingButton} onClick={() => handleSave('waiting')}>
            <FormattedMessage id="btnSave" defaultMessage="Save" />
          </Button_01>,
        ]}
      >
        <Spin spinning={loading}>
          <Form colon={false} className='expenseForm'>
            <ExpenseFormVendor form={form} />
            <ExpenseFormOrder form={form} />
            <ExpenseAdditional form={form} />
            <ExpenseAttachment />
          </Form>
        </Spin>
      </Modal>

      <ModalUploadFile
        visible={openUpload}
        setVisible={setOpenUpload}
        setAttData={setAttachmentData}
        attData={attachmentData}
        typeAtt={typeAttachment}
        defaultAtt={defaultAttachment}
      />

      <ListItem
        form={form}
        visible={visibleListPo}
        setVisible={setVisibleListPo}
        setPOSelect={setPOSelect}
        vendorData={vendorList}
      />

      <ListItemProduct
        visible={visibleListProduct}
        setVisible={setVisibleListProduct}
        setVisibleAdd={setVisibleAddProduct}
        setCreateData={setCreatProduct}
        vendorData={vendorList}
        form={form}
      />

      <ListItemService
        visible={visibleListService}
        setVisible={setVisibleListService}
        setVisibleAdd={setVisibleAddService}
        setCreateData={setCreatService}
        vendorData={vendorList}
        form={form}
      />

      <ModalCreateListItem
        visible={visibleAddProduct}
        setVisible={setVisibleAddProduct}
        data={creatProduct}
        selectItem={selectItem}
        setSelectItem={setSelectItem}
      />

      <ModalEditListItem
        visible={visibleEditProduct}
        setVisible={setVisibleEditProduct}
        data={editProduct}
        selectItem={selectItem}
        setSelectItem={setSelectItem}
      />

      <ModalCreateListServiceItem
        visible={visibleAddService}
        setVisible={setVisibleAddService}
        data={creatService}
        selectItem={selectItem}
        setSelectItem={setSelectItem}
      />

      <ModalEditListServiceItem
        visible={visibleEditService}
        setVisible={setVisibleEditService}
        data={editService}
        selectItem={selectItem}
        setSelectItem={setSelectItem}
      />

    </ExpenseModal.Provider>
  )
}

const ExpenseEdit = Form.create({
  name: "modal_form",
  mapPropsToFields(props) {

  }
})(ExpenseEditForm);

export default ExpenseEdit
