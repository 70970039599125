import { Button, Col, Divider, Icon, Input, Modal, Popover, Row, Select, Table, Form, AutoComplete, Progress } from 'antd';
import _ from 'lodash';
import React, { useState, useRef, useEffect } from 'react';
import { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { numberFormatDecimal, numberFormatNoDecimal } from '../../../component-function/fnc-number-format-decimal';
import CollapseCustom from '../../../components/collapse-custom';
import LabeRequireForm from '../../../components/label-required-form';
import { messageLabel } from '../../../components/message-component';
import Button_01 from '../../../components/v2/button_01';
import { errorNotification, successNotification } from '../../../components/v2/notification';
import { deleteWarehouseLocation, getWarehouseLocation } from '../../../controllers/warehouse/warehouse';
import WarehouseContext from '../context';
import WarehouseBinLocationModalForm from '../warehouse-modal/warehouseModalBinLocationForm';
import WarehouseBinLocationNobinModalForm from '../warehouse-modal/warehouseModalBinLocationNoBinForm';
import WarehouseLocationSwapModalForm from '../warehouse-modal/warehouseModalBinLocationSwapForm';
import './css/index.css';
import { PageSettings } from '../../../config/page-settings';

const { Option } = Select;
const CardWarehouseLocation = () => {
  const intl = useIntl();
  const ref = useRef();
  const app = useContext(PageSettings);
  const { checkPermissionAction } = app;

  const { state, setState, fnc } = useContext(WarehouseContext);
  const { setTrigger } = setState;
  const { warehouseServiceDataList, warehouseId, key, roleCheck } = state;

  const [isOpen, setIsopen] = useState(false);
  const [isOpenNobin, setIsopenNobin] = useState(false);
  const [isOpenSwap, setIsopenSwap] = useState(false);
  const [record, setRecord] = useState();
  const [visiblePopoverTable, setVisiblePopoverTable] = useState();
  const [recordPopover, setRecordPopover] = useState();
  const [offsetPopover, setOffsetPopover] = useState([0, 0]);

  const [total, setTotal] = useState();
  const [fieldSort, setFieldSort] = useState();
  const [orderSort, setOrderSort] = useState();
  const [paginationPage, setPaginationPage] = useState(1);
  const [paginationSize, setPaginationSize] = useState(10);
  const [loading, setLoading] = useState(false);
  const [searchGroup, setSearchGroup] = useState();
  const [dataLocationList, setDataLocationList] = useState([]);
  const [binCapacityStatusList, setBinCapacityStatusList] = useState([]);
  const [triggerLocation, setTriggerLocation] = useState(false);

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      fixed: 'left',
      width: 70,
      render: (text, record, index) => (paginationPage - 1) * paginationSize + (index + 1),
    },
    {
      title: messageLabel('binLocationLabelBin', 'Bin Location'),
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      fixed: 'left',
      width: 150,
    },
    {
      title: messageLabel('binLocationLabelItemCode', 'Item Code'),
      dataIndex: 'itemMasterDetail.itemCode',
      key: 'itemMasterDetail.itemCode',
      sorter: true,

      width: 150,
      ellipsis: true,
    },
    {
      title: messageLabel('binLocationLabelItem', 'Item'),
      dataIndex: 'itemMasterDetail.itemName',
      key: 'itemMasterDetail.itemName',
      sorter: true,
      //
      // width: 200,
    },
    {
      title: messageLabel('binLocationLabelQty', 'Qty'),
      dataIndex: 'qty',
      key: 'qty',
      sorter: true,
      render: (text, record, index) => numberFormatNoDecimal(_.get(record, 'qty')),
    },
    {
      title: messageLabel('binLocationLabelLoadCapacity', 'Load Capacity (%)'),
      dataIndex: 'useVolume',
      key: 'useVolume',
      sorter: true,
      render: (text, record, index) => renderProgress(record.useVolume, record.totalVolume),
    },
    {
      title: messageLabel('binLocationLabelBinWeightCapacity', 'Weight Capacity (%)'),
      dataIndex: 'useWeight',
      key: 'useWeight',
      sorter: true,
      render: (text, record, index) => renderProgress(record.useWeight, record.maxWeight),
    },
    {
      title: messageLabel('binLocationLabelDimension', 'Dimension'),
      dataIndex: 'dimension',
      key: 'dimension',
      sorter: true,
    },
    {
      title: messageLabel('binLocationLabelMaxWeight', 'Max Weight (kg)'),
      dataIndex: 'maxWeight',
      key: 'maxWeight',
      sorter: true,
      render: (text, record, index) => numberFormatDecimal(_.get(record, 'maxWeight')),
    },
  ];

  const renderProgress = (actual, max) => {
    let color = '';
    let percent = (actual / max) * 100;
    console.log('percent', percent);
    if (percent < 85) {
      color = '#6490cf';
    } else if (percent >= 85 && percent <= 99) {
      color = '#6490cf';
    } else {
      color = '#e73845';
    }

    return (
      <div className="progress-item">
        <Progress className="bar-progress-custom" strokeColor={color} percent={percent} showInfo={false} />{' '}
        <span className="progress-qty-value">{max === 0 ? 0 : percent}%</span>
      </div>
    );
  };

  useEffect(() => {
    if (warehouseId) {
      setFieldSort();
      setOrderSort();
      setPaginationPage(1);
      setPaginationSize(10);
      setLoading(false);
      setSearchGroup();
    }
  }, [warehouseId]);

  useEffect(() => {
    setLoading(true);
    const getApi = async () => {
      let search = {};

      if (_.get(searchGroup, 'code') || _.get(searchGroup, 'name') || _.get(searchGroup, 'status') || _.get(searchGroup, 'bin')) {
        search['name'] = _.get(searchGroup, 'bin');
        search['itemName'] = _.get(searchGroup, 'name');
        search['itemCode'] = _.get(searchGroup, 'code');
        search['status'] = _.get(searchGroup, 'status');
      } else {
        search = {};
      }
      const payload = {
        warehouseId: warehouseId, //warehouseId
        search: search,
        pageNumber: paginationPage,
        limit: paginationSize,
        orderBy: fieldSort,
        orderType: orderSort,
      };
      const response = await getWarehouseLocation(payload);
      setDataLocationList(_.get(response.data.data, 'binLocationList') || []);
      setBinCapacityStatusList(_.get(response.data.data, 'statusSelected'));
      setTotal(_.get(response.data.data, 'totalItems'));
      setLoading(false);
    };

    if (warehouseId && key === 'location') {
      getApi();
    }
  }, [warehouseId, paginationPage, paginationSize, fieldSort, orderSort, searchGroup, triggerLocation, key]);

  const openModal = () => {
    setIsopen(true);
  };

  const handleCreateFormNobin = (record) => {
    setRecord(record);
    setIsopenNobin(true);
  };

  const openModalEdit = async (record) => {
    setRecord(record);
    setIsopen(true);
  };

  const handleDelete = (id) => {
    Modal.confirm({
      className: 'maintenance-report-modal-confirm',
      centered: true,
      icon: null,
      title: intl.formatMessage({ id: 'lblDelete', defaultMessage: 'Are you sure you want to delete ?' }),
      okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        try {
          const response = await deleteWarehouseLocation(id);
          if (response.status === 200) {
            successNotification(_.get(response.data.status, 'message'));
            setTrigger((current) => !current);
            setTriggerLocation((current) => !current);
          } else {
            errorNotification(_.get(response.data.status, 'message'));
          }
        } catch (error) {
          errorNotification(_.get(error.response.data.status, 'message'));
        }
      },
      onCancel() {},
    });
  };

  const handleSwap = (record) => {
    setRecord(record);
    setIsopenSwap(true);
  };

  const handleMouseLeave = () => {
    setVisiblePopoverTable(false);
    setRecordPopover();
    setOffsetPopover([0, 0]);
  };

  const fncActionTable = (record, offsetPopover, visiblePopoverTable, ref) => {
    return (
      <div>
        <Popover
          placement="right"
          trigger="click"
          align={{ offset: offsetPopover }}
          visible={visiblePopoverTable}
          zIndex={10000}
          content={
            _.get(record, 'isNoBin') ? (
              <div style={{ display: 'grid', width: 180 }}>
                {checkPermissionAction(`P52PG1C5`, `P52PG1C5A2`)
                  ? _.get(record, 'action') &&
                    _.get(record, 'action').includes('create_from_nobin') && (
                      <div>
                        <Button style={{ width: 0 }} type="link" ghost onClick={() => handleCreateFormNobin(record)}>
                          <FormattedMessage id="btnCreateNoBin" defaultMessage="Create Bin Location" />
                        </Button>
                      </div>
                    )
                  : null}
                {checkPermissionAction(`P52PG1C5`, `P52PG1C5A3`)
                  ? _.get(record, 'action') &&
                    _.get(record, 'action').includes('swap') && (
                      <div>
                        <Button style={{ width: 0 }} type="link" ghost onClick={() => handleSwap(record)}>
                          <FormattedMessage id="btnSwap" defaultMessage="Swap or Change" />
                        </Button>
                      </div>
                    )
                  : null}
              </div>
            ) : (
              <div style={{ display: 'grid', width: 140 }}>
                {checkPermissionAction(`P52PG1C5`, `P52PG1C5A4`)
                  ? _.get(record, 'action') &&
                    _.get(record, 'action').includes('edit') && (
                      <div>
                        <Button style={{ width: 0 }} type="link" ghost onClick={() => openModalEdit(record)}>
                          <FormattedMessage id="orgTabMemberBtnEdit" defaultMessage="Edit" />
                        </Button>
                      </div>
                    )
                  : null}
                {checkPermissionAction(`P52PG1C5`, `P52PG1C5A3`)
                  ? _.get(record, 'action') &&
                    _.get(record, 'action').includes('swap') && (
                      <div>
                        <Button style={{ width: 0 }} type="link" ghost onClick={() => handleSwap(record)}>
                          <FormattedMessage id="btnSwap" defaultMessage="Swap or Change" />
                        </Button>
                      </div>
                    )
                  : null}
                {checkPermissionAction(`P52PG1C5`, `P52PG1C5A5`)
                  ? _.get(record, 'action') &&
                    _.get(record, 'action').includes('delete') && (
                      <div>
                        <Button style={{ width: 0 }} type="link" ghost onClick={() => handleDelete(record.binLoationId)}>
                          <FormattedMessage id="btnDelete" defaultMessage="Delete" />
                        </Button>
                      </div>
                    )
                  : null}
              </div>
            )
          }
        >
          <div style={{ width: 0, height: 0 }} ref={ref}></div>
        </Popover>
      </div>
    );
  };

  const onChange = (value, code) => {
    setSearchGroup({ ...searchGroup, [code]: value });
    setPaginationPage(1);
  };

  const formFilterLocation = () => {
    return (
      <Form colon={false}>
        <Row gutter={[24, 2]}>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'warehouseModalItemBinLocation', defaultMessage: 'Bin Location' })}
                  req={false}
                />
              }
            >
              <Input
                allowClear
                value={_.get(searchGroup, 'bin')}
                onChange={(e) => onChange(e.target.value, 'bin')}
                placeholder={intl.formatMessage({ id: 'ModalLocationPlaceholderBinLocation', defaultMessage: 'Enter Bin Location' })}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'warehouseModalBinCapacity', defaultMessage: 'Bin Capacity' })}
                  req={false}
                />
              }
            >
              <Select
                allowClear
                value={_.get(searchGroup, 'status')}
                onChange={(value) => onChange(value, 'status')}
                placeholder={intl.formatMessage({ id: 'ModalLocationPlaceholderBinCapacity', defaultMessage: 'Select Bin Capacity' })}
              >
                {binCapacityStatusList &&
                  binCapacityStatusList.map((item) => (
                    <Option key={item.code} value={item.code}>
                      {item.name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}></Col>
        </Row>
        <Row gutter={[24, 2]}>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'warehouseModalItemCode', defaultMessage: 'Item Code' })} req={false} />
              }
            >
              <Input
                allowClear
                value={_.get(searchGroup, 'code')}
                onChange={(e) => onChange(e.target.value, 'code')}
                placeholder={intl.formatMessage({ id: 'ModalLocationPlaceholderItemCode', defaultMessage: 'Enter Item Code' })}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'warehouseModalItemName', defaultMessage: 'Item Name' })} req={false} />
              }
            >
              <Input
                allowClear
                value={_.get(searchGroup, 'name')}
                onChange={(e) => onChange(e.target.value, 'name')}
                placeholder={intl.formatMessage({ id: 'ModalLocationPlaceholderItemName', defaultMessage: 'Enter Item Name' })}
              />
            </Form.Item>
          </Col>
          <Col span={8}></Col>
        </Row>
      </Form>
    );
  };

  const handleChange = (pagination, sorter, extra) => {
    let field = extra.field;
    if (extra.order) {
      if (extra.order === 'descend') {
        setOrderSort('desc');
      } else {
        setOrderSort('asc');
      }
    } else {
      setOrderSort('');
    }
    setFieldSort(field);
    setPaginationSize(pagination.pageSize);
    setPaginationPage(pagination.current);
  };

  const showTotal = () => {
    return (
      <span style={{ fontSize: '13px' }}>
        <FormattedMessage id="lblRowPerPage" defaultMessage="Rows per page: " />
      </span>
    );
  };

  return (
    <div className="warehouse-layout-content warehouse-tabs-details scroll-sm">
      <div>
        <CollapseCustom
          label={intl.formatMessage({ id: `btnFilter`, defaultMessage: 'Filter' })}
          extra={
            <div style={{ display: 'flex' }}>
              {checkPermissionAction(`P52PG1C5`, `P52PG1C5A2`) ? (
                roleCheck ? (
                  <Button_01 key="submit" type="primary" btnsize="wd_df" style={{ marginRight: 'unset' }} onClick={() => openModal()}>
                    <FormattedMessage id="btnCreate" defaultMessage="Create" />
                  </Button_01>
                ) : null
              ) : null}
            </div>
          }
        >
          {formFilterLocation()}
        </CollapseCustom>
      </div>
      <div className="warehouse-layout-stock" onMouseLeave={handleMouseLeave}>
        <Table
          className="table-global-scroll"
          rowKey={(record) => record.binLoationId}
          dataSource={dataLocationList}
          scroll={_.size(dataLocationList) ? { x: true } : { x: 'max-content' }}
          columns={columns}
          onChange={handleChange}
          loading={loading}
          pagination={{
            total: total,
            current: paginationPage,
            showTotal: showTotal,
            defaultCurrent: 1,
            pageSizeOptions: ['10', '20', '30', '40', '50'],
            showSizeChanger: true,
            locale: { items_per_page: '' },
          }}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                if (
                  checkPermissionAction('P52PG1C5', 'P52PG1C5A2') ||
                  checkPermissionAction('P52PG1C5', 'P52PG1C5A3') ||
                  checkPermissionAction('P52PG1C5', 'P52PG1C5A4') ||
                  checkPermissionAction('P52PG1C5', 'P52PG1C5A5')
                ) {
                  if (
                    _.get(record, 'isNoBin') &&
                    !checkPermissionAction('P52PG1C5', 'P52PG1C5A2') &&
                    !checkPermissionAction('P52PG1C5', 'P52PG1C5A3')
                  ) {
                    
                  } else if (
                    _.get(record, 'isNoBin') === false &&
                    !checkPermissionAction('P52PG1C5', 'P52PG1C5A3') &&
                    !checkPermissionAction('P52PG1C5', 'P52PG1C5A4') &&
                    !checkPermissionAction('P52PG1C5', 'P52PG1C5A5')
                  ) {
                    
                  } else {
                    if (!event.target.href && roleCheck) {
                      const { x, y } = ref.current.getBoundingClientRect();
                      setVisiblePopoverTable(true);
                      setOffsetPopover([event.pageX - x, event.pageY - y]);
                      setRecordPopover(record);
                    }
                  }
                }
              },
            };
          }}
        />
        {roleCheck ? fncActionTable(recordPopover, offsetPopover, visiblePopoverTable, ref) : null}
        {_.size(dataLocationList) > 0 ? (
          <div className="total-items-member">
            <span style={{ fontSize: '13px' }}>
              <FormattedMessage id="lblTotal" defaultMessage="Total" />
              {` ${total || 0} `}
              <FormattedMessage id="lblitems" defaultMessage="items" />
            </span>
          </div>
        ) : null}
      </div>
      <WarehouseBinLocationModalForm
        visible={isOpen}
        setVisible={setIsopen}
        setTriggerLocation={setTriggerLocation}
        setRecord={setRecord}
        record={record}
      />

      <WarehouseBinLocationNobinModalForm
        visible={isOpenNobin}
        setVisible={setIsopenNobin}
        setTriggerLocation={setTriggerLocation}
        setRecord={setRecord}
        record={record}
      />

      <WarehouseLocationSwapModalForm
        visible={isOpenSwap}
        setVisible={setIsopenSwap}
        setTriggerLocation={setTriggerLocation}
        setRecord={setRecord}
        record={record}
      />
    </div>
  );
};

export default CardWarehouseLocation;
