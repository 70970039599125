import React, { useEffect, useRef, useState } from 'react';
import { Modal, Form, Input, Button, Upload, Icon, Table, Popover } from 'antd';
import _ from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';
import Button02 from '../../../components/v2/button_02';
import Button01 from '../../../components/v2/button_01';
import LabeRequire from '../../../components/v2/label-require';
import { successNotification, errorNotification, warningNotification } from '../../../components/v2/notification';
import { deleteAttachment, getAttachment, upDateAttachment, uploadAttachment } from '../../../controllers/project-new/project-detail';
import Resizer from 'react-image-file-resizer';

const { TextArea } = Input;

const Attachment = (props) => {
  const { visible, setVisible, id } = props;
  const intl = useIntl();
  const ref = useRef();

  const [data, setData] = useState([]);
  const [paginationPage, setPaginationPage] = useState(1);
  const [paginationSize, setPaginationSize] = useState(10);
  const [visiblePopoverTable, setVisiblePopoverTable] = useState();
  const [recordPopover, setRecordPopover] = useState();
  const [offsetPopover, setOffsetPopover] = useState([0, 0]);

  const [attachment, setAttachment] = useState();
  const [remark, setRemark] = useState('');
  const [trigger, setTrigger] = useState(false)


  console.log('attachment', attachment)

  useEffect(() => {

    const getData = async () => {
      const response = await getAttachment({projectId: id})
      console.log('project getData', response)

      setData(_.get(response, 'data'))
    }
    if (id) getData()
  }, [id, trigger])

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
      fixed: 'left',
      render: (text, record, index) => index + 1 + (paginationPage - 1) * paginationSize,
    },
    {
      title: intl.formatMessage({ id: 'projectDetailLblColumnsFileName', defaultMessage: 'File Name' }),
      dataIndex: 'filename',
      key: 'filename',
      sorter: (a, b) => a.filename.localeCompare(b.filename),
      width: 350,
    },
    {
      title: intl.formatMessage({ id: 'projectDetailLblColumnsRemark', defaultMessage: 'Remark' }),
      dataIndex: 'project_remark',
      key: 'project_remark',
    },
  ];

  const handlePagination = (page, pagination) => {
    console.log('handlePagination', page, pagination)
    setPaginationPage(page)
  };

  const handleSizeChange = (page, pagination) => {
    console.log('handlePagination', page, pagination)
    setPaginationPage(page)
    setPaginationSize(pagination)
  };

  const showTotal = (totalstake) => {
    return (
      <span style={{ fontSize: '13px' }}>
        <FormattedMessage id="lblRowPerPage" defaultMessage="Rows per page: " />
      </span>
    );
  };

  const handleMouseLeave = () => {
    setVisiblePopoverTable(false);
    setRecordPopover();
    setOffsetPopover([0, 0]);
  };

  const fncActionTable = (record, offsetPopover, visible, ref) => {
    console.log('fncActionTable', record, offsetPopover, visiblePopoverTable, ref)
    return (
      <div>
        <Popover
          placement="right"
          trigger="click"
          align={{ offset: offsetPopover }}
          visible={visiblePopoverTable}
          zIndex={10000}
          content={
            <div style={{ display: 'grid' }}>
              <div>
                <Button style={{ width: 100 }} type="link" ghost onClick={() => editFile(record)}>
                  <FormattedMessage id="btnEdit" defaultMessage="Edit" />
                </Button>
              </div>
              <div>
                <Button style={{ width: 100 }} type="link" ghost onClick={() => dowloadFile(record)}>
                  <FormattedMessage id="projectDetailDownload" defaultMessage="Dowload" />
                </Button>
              </div>
              <div>
                <Button style={{ width: 100 }} type="link" ghost onClick={() => deleteFile(record)}>
                  <FormattedMessage id="btnDelete" defaultMessage="Delete" />
                </Button>
              </div>
            </div>
          }
        >
          <div style={{ width: 0, height: 0 }} ref={ref}></div>
        </Popover>
      </div>
    );
  };

  const editFile = (record) => {
    console.log('project record', record)
    setAttachment({...record, name: _.get(record, 'filename'), action: 'edit'})
    setRemark(_.get(record, 'project_remark'))
    setVisible(true)
    setVisiblePopoverTable(false)
  };

  const deleteFile = (record) => {
    setVisiblePopoverTable(false)
    Modal.confirm({
      className: 'customer-modal-confirm',
      centered: true,
      icon: null,
      title: intl.formatMessage({
        id: `projectDetailModalConfirmDeleteProject`,
        defaultMessage: 'Do you want to delete?',
      }),
      okText: intl.formatMessage({
        id: 'modalBtnConfirm',
        defaultMessage: 'Confirm',
      }),
      cancelText: intl.formatMessage({
        id: 'modalBtnCancel',
        defaultMessage: 'Cancel',
      }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        const body = {
            projectId: id,
            project_upload_id: _.get(record, 'project_upload_id')
        }
        const response = await deleteAttachment(body);
        console.log('project delete', response)
        if (response.status === 200) {
          setTrigger((current) => !current);
          successNotification(response.message);
        } else {
          errorNotification(response.message);
        }
      },
      onCancel() {},
    });
  };

  const dowloadFile = async (record) => {
    setVisiblePopoverTable(false)
    try {
      const response = await fetch(record.imageUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = record.filename;
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    } catch (error) {
    }
  };

  const handleOk = async () => {
    if (_.get(attachment, 'name')) {
      console.log('project upload', id, attachment);

      if (attachment.type === 'image/jpeg' || attachment.type === 'image/png' && _.get(attachment, 'action') !== 'edit') {
        const image = await resizeFile(attachment);
        const fileChange = dataURLtoFile(image, attachment.name.toLowerCase());
        let formData = new FormData();
        formData.append('file', fileChange);
        formData.append('projectId', id);
        formData.append('remark', remark);

        const response = await uploadAttachment(formData);
        console.log('project upload response', response);
        if (response.status === 200) {
          successNotification(_.get(response, 'message', ''));
        } else {
          errorNotification(_.get(response, 'message', ''));
        }
      } else {
        let formDataPDF = new FormData();
       
        if (_.get(attachment, 'action') === 'edit') {
          formDataPDF.append('project_upload_id', _.get(attachment, 'project_upload_id'))
          formDataPDF.append('projectId', id);
          formDataPDF.append('remark', remark);
        } else {
          formDataPDF.append('file', attachment);
          formDataPDF.append('projectId', id);
          formDataPDF.append('remark', remark);
        }

        const response = _.get(attachment, 'action') === 'edit' ? await upDateAttachment(formDataPDF) : await uploadAttachment(formDataPDF);
        console.log('project upload response', response);
        if (response.status === 200) {
          successNotification(_.get(response, 'message', ''));
          setTrigger((current) => !current);
        } else {
          errorNotification(_.get(response, 'message', ''));
        }
      }

      setVisible(false);
      resetModal();
    }
  };

  const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    for (var i = 0; i < bstr.length; i++) {
      u8arr[i] = bstr.charCodeAt(i);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        720,
        720,
        'JPG',
        70,
        0,
        (uri) => {
          resolve(uri);
        },
        'base64'
      );
    });

  const handleCancel = () => {
    setVisible(false);
    resetModal();
  };

  const resetModal = () => {
    setAttachment();
    setRemark('');
  };

  const uploadProps = {
    name: 'file',
    multiple: false,
    accept: '.pdf, .jpg, .jpeg, .png, .doc, .docx, .xls, .xlsx',
    onChange(info) {
      console.log('uploadinfo', info);
      setAttachment(info.file);
    },
    beforeUpload: () => {
      return false;
    },
  };

  return (
    <div onMouseLeave={handleMouseLeave}>
      <Table
        columns={columns}
        dataSource={data}
        pagination={{
          total: _.size(data),
          current: paginationPage,
          pageSize: paginationSize,
          
          showTotal: showTotal,
          defaultCurrent: 1,
          pageSizeOptions: ['10', '20', '30', '40', '50'],
          showSizeChanger: true,
          locale: { items_per_page: '' },
          onChange: handlePagination,
          onShowSizeChange: handleSizeChange,
        }}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              if (!event.target.href) {
                const { x, y } = ref.current.getBoundingClientRect();
                setVisiblePopoverTable(true);
                setOffsetPopover([event.pageX - x, event.pageY - y]);
                setRecordPopover(record);
              }
            },
          };
        }}
      />

      {fncActionTable(recordPopover, offsetPopover, visiblePopoverTable, ref)}
      {_.size(data) > 0 && (
        <div className="total-items-member">
          <span style={{ fontSize: '13px' }}>
            <FormattedMessage id="lblTotal" defaultMessage="Total" />
            {` ${_.size(data) || 0} `}
            <FormattedMessage id="lblitems" defaultMessage="items" />
          </span>
        </div>
      )}

      <Modal
        title={_.get(attachment, 'action') === 'edit' ? intl.formatMessage({ id: `projectModalAttachmentLabelEditUpdoad`, defaultMessage: 'Edit Attachment' }) : intl.formatMessage({ id: `projectModalAttachmentLabelUpdoad`, defaultMessage: 'Upload Attachment' })}
        visible={visible}
        onCancel={handleCancel}
        footer={[
          <Button02 onClick={() => handleCancel()}>
            <FormattedMessage id="btnClose" defaultMessage="Close" />
          </Button02>,
          <Button01 type="primary" onClick={() => handleOk()}>
            <FormattedMessage id="btnSave" defaultMessage="Save" />
          </Button01>,
        ]}
      >
        <Form layout="vertical">
          <div style={{ marginBottom: '8px' }}>
            <span className="formText">
              <FormattedMessage id="AssetModalTitleFormUploadFile" defaultMessage="File" />
              <LabeRequire req={true} />
            </span>
          </div>
          {_.get(attachment, 'name') ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '24px',
                border: '1px solid #D9D9D9',
                fontSize: '16px',
              }}
            >
              <div>
                <Icon type="file-pdf" style={{ fontSize: '28px' }} />
                <span style={{ marginLeft: 8 }}>{_.get(attachment, 'name')}</span>
              </div>
              {_.get(attachment, 'action') !== 'edit' ? <Icon type="delete" onClick={() => setAttachment()} /> : <></>}
            </div>
          ) : (
            <Upload.Dragger {...uploadProps}>
              <p className="ant-upload-text">
                <FormattedMessage id="projectDetailUpload1" defaultMessage="Click or drag file to this area to upload" />
              </p>
              <p className="ant-upload-hint">
                <FormattedMessage id="projectDetailUpload2" defaultMessage="Upload one supported file, maximum size 10 MB." />
              </p>
            </Upload.Dragger>
          )}
          <Form.Item label="Remark" style={{ marginTop: '24px', marginBottom: '8px' }}>
            <TextArea
              rows={3}
              placeholder={intl.formatMessage({ id: 'projectDetailUploadRemark', defaultMessage: 'Enter Remark' })}
              onChange={(e) => setRemark(e.target.value)}
              value={remark}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Attachment;
