import React from 'react';
import { Col, Form, Modal, Row, Select, Input, Tag } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';

import _ from 'lodash';
import LabeRequireForm from '../../label-required-form';
import Button_02 from '../../v2/button_02';
import Button_01 from '../../v2/button_01';
import { createBrandData, createModelData } from '../../../controllers/createBrandModel';
import { errorNotification, successNotification } from '../../v2/notification';

const { Option } = Select;
const { TextArea } = Input;

const ViewBrandModal = ({ form, title, visible, onOk, onCancel, list, setEvent, recordBrand,handleopenModalBrand }) => {
  const { getFieldDecorator, resetFields } = form;
  const intl = useIntl();

  console.log('ViewBrandModal', recordBrand)

  const handleDelete = (record) => {
    Modal.confirm({
      className: 'customer-modal-confirm',
      centered: true,
      icon: null,
      title: intl.formatMessage({ id: 'modalCreateGroupTitleViewGroupDeleted', defaultMessage: 'Are you sure to delete group ?' }),
      okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        handleSave();
      },
    });
  };

  const handleSave = () => {
    form.validateFields(async (err, values) => {
      console.log('values', values);
      if (err) {
        return;
      }

      const payload = {
        itemBrandId: _.get(recordBrand, 'itemBrandId'),
        itemBrandName: _.get(recordBrand, 'itemBrandName'),
        itemBrandNo: _.get(recordBrand, 'itemBrandNo'),
        itemBrandDescription: _.get(recordBrand, 'itemBrandDescription'),
        active: 'false',
      };

      try {
        const response = await createBrandData(payload);
        if (_.get(response, 'status.code') === 200) {
          successNotification(response.status.message);
          handleCancel();
          setEvent((prev) => !prev);
        } else {
          errorNotification(_.get(response, 'status.message'));
        }
      } catch (error) {
        errorNotification(error.response.data.data.message);
      }
    });
  };

  const handleCancel = () => {
    onCancel();
    resetFields();
  };

  return (
    <Modal
      title={title}
      visible={visible}
      onOk={handleDelete}
      onCancel={onCancel}
      width={767}
      centered={true}
      footer={ _.get(recordBrand, 'active') === 'true' ? [
        <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={() => handleCancel()}>
          <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
        </Button_02>,
        <Button_01 key="submit" type="primary" btnsize="wd_df" onClick={() => handleDelete()}>
          <FormattedMessage id="btnDelete" defaultMessage="Save" />
        </Button_01>,
        <Button_01 key="submit" type="primary" btnsize="wd_df" onClick={() => handleopenModalBrand()} style={{marginLeft: '0px'}}>
          <FormattedMessage id="btnEdit" defaultMessage="Edit" />
        </Button_01>,
      ] : [
        <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={() => handleCancel()}>
          <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
        </Button_02>,
      ]
    }
    >
      <Form name="status" colon={false} style={{ marginTop: '-24px' }}>
        <Row gutter={[24]}>
          <Col span={24}>
            <div
              className="listItemTextHeader"
              style={{ padding: 'unset', display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '0px -24px' }}
            >
              <span style={{ paddingLeft: '24px' }}>
                <FormattedMessage id="modalCreateGroupTitleCreateModelDetail" defaultMessage="Details" />
              </span>

              <div className="tag-center-div" style={{ padding: '12px 14px' }}>
                <Tag className="tag-center-style" color={_.get(recordBrand, 'statusColor')}>
                  {_.get(recordBrand, 'statusName')}
                </Tag>
              </div>
            </div>
          </Col>
        </Row>
        <Row gutter={[24]}>
          <Col span={12}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'modalCreateGroupTypeTitleGroup', defaultMessage: 'Group' })}
                  req={false}
                  bold={true}
                />
              }
            >
              {_.get(recordBrand, 'itemBrandNo') + ' ' + _.get(recordBrand, 'itemBrandName')}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'modalCreateGroupDescription', defaultMessage: 'Description' })}
                  req={false}
                  bold={true}
                />
              }
            >
              {_.get(recordBrand, 'itemBrandDescription')}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24]}>
          <Col span={12}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'modalCreateGroupTitleViewGroupCreatedby', defaultMessage: 'Created by' })}
                  // req={true}
                  bold={true}
                />
              }
            >
              {_.get(recordBrand, 'createdByName')}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'modalCreateGroupTitleViewGroupCreatedDate', defaultMessage: 'Created Date' })}
                  // req={true}
                  bold={true}
                />
              }
            >
              {_.get(recordBrand, 'createdAt')}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24]}>
          <Col span={12}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'modalCreateGroupTitleViewGroupDeletedby', defaultMessage: 'Deleted by' })}
                  // req={true}
                  bold={true}
                />
              }
            >
              {_.get(recordBrand, 'deletedByName')}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'modalCreateGroupTitleViewGroupDeletedDate', defaultMessage: 'Deleted Date' })}
                  // req={true}
                  bold={true}
                />
              }
            >
              {_.get(recordBrand, 'deletedAt')}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

ViewBrandModal.defaultProps = {
  title: 'Select Customer',
  // title: intl.formatMessage({ id: 'quotationLabelSelectCustomer', defaultMessage: 'Select Customer' }),
  visible: false,
  onOk: () => console.warn('onOk not function '),
  onCancel: () => console.warn('onCancel not function '),
};

const ModalViewBrandModal = Form.create({ name: 'reject_Form' })(ViewBrandModal);

export default ModalViewBrandModal;
