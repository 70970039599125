import React, { useState, useEffect, useContext } from 'react';
import './index.css';
import { Modal, Avatar, Row, Col, Divider, Icon, Upload, Form, Select, Input, Tag } from 'antd';
import Button01 from '../../v2/button_01';
import Button02 from '../../v2/button_02';
import { FormattedMessage, useIntl } from 'react-intl';
import _ from 'lodash';
import ReactBnbGallery from 'react-bnb-gallery';
import LabeRequireForm from '../../label-required-form';
import { getCustomerInformation } from '../../../controllers/customer-vendor/customer-vendor';
import { approveAllRequest, getDetailKYC, rejectAllRequest } from '../../../controllers/approval/approval-controller';
import { successNotification, errorNotification } from '../../v2/notification';
import { getReasonByCode } from '../../../controllers/approval/approval-controller';
import { PageSettings } from '../../../config/page-settings';

const { Option } = Select;
const { TextArea } = Input;

const ModalStatusConfirm = (props) => {
  const { visible, setVisible, type, form, requestIdList, mainVisible, setTrigger, reasonList, requestNo } = props;
  const { getFieldDecorator, validateFields, resetFields } = form;
  const intl = useIntl();

  const handleConfirmStatus = () => {
    validateFields(async (err, values) => {
      if (err) {
        return;
      }
      if (type === 'approve') {
        let payload = {
          requestIdList: [requestIdList],
          approverComment: values.remark,
        };
        const response = await approveAllRequest(payload);
        if (_.get(response.status, 'code') === 200) {
          successNotification(_.get(response.status, 'message'));
          setTrigger((cur) => !cur);
          setVisible(false);
          mainVisible(false);
          resetFields();
        } else {
          errorNotification(_.get(response.status, 'message'));
        }
      } else {
        let payload = {
          requestIdList: [requestIdList],
          approverComment: values.remark,
          approverReason: values.reason,
        };
        const response = await rejectAllRequest(payload);
        if (_.get(response.status, 'code') === 200) {
          successNotification(_.get(response.status, 'message'));
          setTrigger((cur) => !cur);
          setVisible(false);
          mainVisible(false);
          resetFields();
        } else {
          errorNotification(_.get(response.status, 'message'));
        }
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
    resetFields();
  };

  return (
    <Modal
      title={`${type === 'approve' ? 'Approved' : 'Rejected'}・${requestNo}`}
      visible={visible}
      width={370}
      centered={true}
      onCancel={handleCancel}
      footer={[
        <Button02 key="back" btnsize="wd_df" onClick={handleCancel}>
          <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
        </Button02>,
        <Button01 key="submit" type="primary" btnsize="wd_df" onClick={handleConfirmStatus} style={{ margin: 'unset' }}>
          <FormattedMessage id="btnSave" defaultMessage="Save" />
        </Button01>,
      ]}
    >
      <Form form={form} layout="vertical">
        {type === 'reject' ? (
          <Form.Item label={<LabeRequireForm text="Reason" req={true} />}>
            {getFieldDecorator('reason', {
              rules: [
                {
                  required: true,
                  message: intl.formatMessage({ id: 'modalRequestStatusFormReasonValidate', defaultMessage: 'Please Select Reason' }),
                },
              ],
            })(
              <Select placeholder={intl.formatMessage({ id: 'modalRequestStatusFormReasonPlaceholder', defaultMessage: 'Select Reason' })}>
                {_.map(reasonList, (item) => (
                  <Option key={item}>{item}</Option>
                ))}
              </Select>
            )}
          </Form.Item>
        ) : null}
        <Form.Item label={<LabeRequireForm text="Remark" req={false} />}>
          {getFieldDecorator('remark', {})(<TextArea autosize={{ minRows: 3, maxRows: 3 }} maxLength={250} />)}
        </Form.Item>
      </Form>
    </Modal>
  );
};
const ModalFormConfirm = Form.create({
  name: 'global_state',
})(ModalStatusConfirm);

const ModalRegisterKYC = (props) => {
  const { visible, setVisible, data, status, setTrigger } = props;
  const { checkPermissionAction } = useContext(PageSettings);
  const [collaps, setCollaps] = useState(true);
  const [visibleImgGallery, setVisibleImgGallery] = useState(false);
  const [startIndexGallery, setStartIndexGallery] = useState(0);
  const [modalViewGallery, setModalViewGallery] = useState([]);
  const [typeConfirm, setTypeConfirm] = useState();
  const [visibleConfirm, setVisibleConfirm] = useState(false);
  const [informationData, setInformationData] = useState();
  const [fileList, setFileList] = useState([]);
  const [fileListIdCard, setFileListIdCard] = useState([]);
  const [reasonList, setReasonList] = useState([]);
  const [collaps2, setCollaps2] = useState(false);
  const [collaps3, setCollaps3] = useState(false);
  const [collaps4, setCollaps4] = useState(false);
  const [collaps5, setCollaps5] = useState(false);
  const marginAttachment = '16px 0px';

  console.log('data', data);

  useEffect(() => {
    const getDatainfomation = async () => {
      const response = await getDetailKYC(_.get(data, 'modalViewId'));
      const getReason = await getReasonByCode(_.get(data, 'requestTypeCode'));
      setInformationData(_.get(response, 'data'));
      setReasonList(getReason);
      
      setFileList(
        _.map(_.get(response, 'data.idCardWithFacePhoto'), (item, index) => {
          return {
            uid: `image${index + 1}`,
            name: `image${index + 1}.png`,
            status: 'done',
            url: item,
          };
        })
      );

      setFileListIdCard(
        _.map(_.get(response, 'data.bookBankPhoto'), (item, index) => {
          return {
            uid: `image${index + 1}`,
            name: `image${index + 1}.png`,
            status: 'done',
            url: item,
          };
        })
      );
    };
    if (data && visible === true) {
      getDatainfomation();
    }
  }, [data]);

  console.log('ModalRegister', informationData);

  const handleUpdateStatus = (type) => {
    setTypeConfirm(type);
    setVisibleConfirm(true);
  };

  const handleClose = () => {
    setVisible(false);
    setModalViewGallery([]);
    setStartIndexGallery(0);
    setVisibleImgGallery(false);
  };

  const checkButton = status === "waiting" ? [
    <Button02 key="back" btnsize="wd_df" onClick={handleClose}>
      <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
    </Button02>,
    checkPermissionAction(`P49PG2C2`, `P49PG2C2A3`) ? (
    
    <Button01 key="submit" type="primary" btnsize="wd_df" onClick={() => handleUpdateStatus('reject')} style={{ margin: 'unset' }}>
      <FormattedMessage id="btnReject" defaultMessage="Reject" />
    </Button01>
    ) : null,
    checkPermissionAction(`P49PG2C2`, `P49PG2C2A2`) ? (
    <Button01 key="submit" type="primary" btnsize="wd_df" onClick={() => handleUpdateStatus('approve')}>
      <FormattedMessage id="btnApprove" defaultMessage="Approve" />
    </Button01>) : null,
  ] : [
    <Button02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleClose} margin="unset">
      <FormattedMessage id="btnClose" defaultMessage="Close" />
    </Button02>
  ]

  const handlePreview = (file) => {
    const mapPhoto =
      fileList && _.size(fileList) !== 0
        ? fileList.map((item, index) => {
            return {
              photo: _.get(item, 'url'),
              number: index,
              caption: _.get(item, 'name'),
              thumbnail: _.get(item, 'url'),
              uid: _.get(item, 'uid')
            }; 
          })
        : [];
    const found = mapPhoto.findIndex((element) => element.uid === file.uid);
    setModalViewGallery(mapPhoto);
    setVisibleImgGallery(true);
    setStartIndexGallery(found);
  };

  const handlePreviewIDCard = (file) => {
    const mapPhoto =
    fileListIdCard && _.size(fileListIdCard) !== 0
        ? fileListIdCard.map((item, index) => {
            return {
              photo: _.get(item, 'url'),
              number: index,
              caption: _.get(item, 'name'),
              thumbnail: _.get(item, 'url'),
              uid: _.get(item, 'uid')
            }; 
          })
        : [];
    const found = mapPhoto.findIndex((element) => element.uid === file.uid);
    setModalViewGallery(mapPhoto);
    setVisibleImgGallery(true);
    setStartIndexGallery(found);
  };

  return (
    <Modal
      title={`${_.get(data, 'requestTypeName')}・${_.get(data, 'requestNo')}`}
      visible={visible}
      onCancel={handleClose}
      centered={true}
      width={620}
      footer={checkButton}
      bodyStyle={{ overflowY: 'auto', maxHeight: 'calc(100vh - 400px)' }}
    >
      <div>
        <div>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <div className="modalRequestStatusContentTextHead" style={{ fontSize: '14px' }}>
                <FormattedMessage id="modalKYCIdentificationInfo" defaultMessage="Identification Information" />
              </div>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <div className="modalRequestStatusContentTextHead">
                <FormattedMessage id="modalKYCFirstName" defaultMessage="First Name" />
              </div>
              <div className="modalRequestStatusContentTextDescription">{_.get(informationData, 'firstName') || '-'}</div>
            </Col>
            <Col span={12}>
              <div className="modalRequestStatusContentTextHead">
                <FormattedMessage id="modalKYCLastName" defaultMessage="Last Name" />
              </div>
              <div className="modalRequestStatusContentTextDescription">{_.get(informationData, 'lastName') || '-'}</div>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <div className="modalRequestStatusContentTextHead">
                <FormattedMessage id="modalKYCIDNumber" defaultMessage="ID Number" />
              </div>
              <div className="modalRequestStatusContentTextDescription">{_.get(informationData, 'idNumber') || '-'}</div>
            </Col>
            {/* <Col span={12}>
              <div className="modalRequestStatusContentTextHead">
                <FormattedMessage id="modalRegisterReferredPhoneNumber" defaultMessage="Referred Phone Number" />
              </div>
              <div className="modalRequestStatusContentTextDescription">{_.get(informationData, 'email') || '-'}</div>
            </Col> */}
          </Row>

          <Divider type="horizontal" />

          <Row gutter={[16, 16]}>
            <Col span={12}>
              <div className="modalRequestStatusContentTextHead" style={{ fontSize: '14px' }}>
                <FormattedMessage id="modalKYCBankInformation" defaultMessage="Bank Information" />
              </div>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <div className="modalRequestStatusContentTextHead">
                <FormattedMessage id="modalKYCBank" defaultMessage="Bank" />
              </div>
              <div className="modalRequestStatusContentTextDescription">{_.get(informationData, 'bankName') || '-'}</div>
            </Col>
            <Col span={12}>
              <div className="modalRequestStatusContentTextHead">
                <FormattedMessage id="modalRegisterBankAccountNo" defaultMessage="Bank Account No." />
              </div>
              <div className="modalRequestStatusContentTextDescription">{_.get(informationData, 'bankAccountNo') || '-'}</div>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={12}>
              <div className="modalRequestStatusContentTextHead">
                <FormattedMessage id="modalRegisterBranchNo" defaultMessage="Branch No." />
              </div>
              <div className="modalRequestStatusContentTextDescription">{_.get(informationData, 'branchNo') || '-'}</div>
            </Col>
            <Col span={12}>
              <div className='modalRequestStatusContentTextHead'>
              <FormattedMessage id="modalRegisterBranch" defaultMessage="Branch" /> 
              </div>
              <div className='modalRequestStatusContentTextDescription'>
                {_.get(informationData, 'branch') || "-"}
              </div>
            </Col>
          </Row>

        </div>

        <div>
          <Divider type="horizontal" />
          <div className="modalRequestStatusAttachment">
            <FormattedMessage id="reportOverTimeReportColumnAttachment" defaultMessage="Attachment" />
          </div>

          {/* -------------------------------- ID Card with Cleary face ------------------------------------- */}
          <div>
            <div className="modalRequestStatusAttachmentContent">
              <div className="modalRequestStatusAttachmentContentHeader">
                <FormattedMessage id="modalRegisterIDCardClear" defaultMessage="ID Card with Cleary face" />
              </div>
              <div style={{ display: 'flex' }}>
                <div className="modalRequestStatusAttachmentContentUpload">
                  {(informationData && _.size(informationData.idCardWithFacePhoto)) || 0}{' '}
                  <FormattedMessage id="modalUploaded" defaultMessage="Uploaded" />
                </div>
                <div onClick={() => setCollaps2((cur) => !cur)}>{collaps2 ? <Icon type="down" /> : <Icon type="right" />}</div>
              </div>
            </div>
          </div>
          {collaps2 ? (
            <div style={{ marginTop: '18px' }}>
              <Upload
                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                listType="picture-card"
                fileList={fileList}
                showUploadList={{ showRemoveIcon: false }}
                onPreview={(file) => handlePreview(file)}
              />
            </div>
          ) : null}
          <Divider type="horizontal" style={{ margin: marginAttachment }} />

          {/* -------------------------------- Book Bank ------------------------------------- */}
          <div>
            <div className="modalRequestStatusAttachmentContent">
              <div className="modalRequestStatusAttachmentContentHeader">
                <FormattedMessage id="modalRegisterIBookBank" defaultMessage="Book Bank" />
              </div>
              <div style={{ display: 'flex' }}>
                <div className="modalRequestStatusAttachmentContentUpload">
                  {(informationData && _.size(informationData.bookBankPhoto)) || 0}{' '}
                  <FormattedMessage id="modalUploaded" defaultMessage="Uploaded" />
                </div>
                <div onClick={() => setCollaps3((cur) => !cur)}>{collaps3 ? <Icon type="down" /> : <Icon type="right" />}</div>
              </div>
            </div>
          </div>
          {collaps3 ? (
            <div style={{ marginTop: '18px' }}>
              <Upload
                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                listType="picture-card"
                fileList={fileListIdCard}
                showUploadList={{ showRemoveIcon: false }}
                onPreview={(file) => handlePreviewIDCard(file)}
              />
            </div>
          ) : null}
        </div>

        {(status === 'approve' || status === 'reject') && _.get(data, 'approverDetail') ? (
          <div>
            <Divider type="horizontal" />
            <div className="modalRequestStatusAttachment"><FormattedMessage id="approvalTabApprovalTitle" defaultMessage="Approval" /></div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex' }}>
                <div style={{ marginRight: '8px', textAlign: 'center' }}>
                  <Avatar src={_.get(data, 'approverDetail.approverProfileImg')} />
                </div>
                <div>
                  <div style={{ color: '#1D3557', fontSize: '15px', fontWeight: '700px' }}>
                    {_.get(data, 'approverDetail.approverName')}
                  </div>
                  <div style={{ marginBottom: '16px', color: '#9A9999', fontSize: '13px' }}>{_.get(data, 'approverDetail.reviewOn')}</div>
                  <div style={{ color: '#050505', fontSize: '14px' }}>
                    {_.get(data, 'approverDetail.approverReason') ? `${_.get(data, 'approverDetail.approverReason')},` : ''}{' '}
                    {_.get(data, 'approverDetail.approverComment')}
                  </div>
                </div>
              </div>
              <div>
                <Tag style={{ borderRadius: '15px' }} color={_.get(data, 'approverDetail.statusColor')}>
                  {' '}
                  {_.get(data, 'approverDetail.statusTxt')}
                </Tag>
              </div>
            </div>
          </div>
        ) : null}
      </div>
      <ReactBnbGallery
        show={visibleImgGallery}
        photos={modalViewGallery}
        onClose={() => setVisibleImgGallery(false)}
        activePhotoIndex={startIndexGallery}
      />
      <ModalFormConfirm
        visible={visibleConfirm}
        setVisible={setVisibleConfirm}
        type={typeConfirm}
        requestIdList={_.get(data, 'memReqId')}
        requestNo={_.get(data, 'requestNo')}
        mainVisible={setVisible}
        setTrigger={setTrigger}
        reasonList={reasonList}
      />
    </Modal>
  );
};

export default ModalRegisterKYC;
