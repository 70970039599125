import React from 'react';
import MyMapComponent from './mapComponent';
import _ from 'lodash';

export default ({ areaList }) => {
  const polygon = [];
  const point = [];

  areaList.forEach((element) => {
    if (element.type === 'polygon') {
      polygon.push(element);
    }
    if (element.type === 'point') {
      point.push(element);
    }
  });

  return (
    <MyMapComponent
      googleMapURL={`https://maps.googleapis.com/maps/api/js?&key=${localStorage.getItem(
        'GEO_APIKEY'
      )}&v=3.exp&libraries=geometry,drawing,places`}
      loadingElement={<div style={{ height: `100%` }} />}
      containerElement={<div style={{ height: `250px` }} />}
      mapElement={<div style={{ height: `100%` }} />}
      isMarkerShown={true}
      polygon={polygon}
      point={point}
    />
  );
};
