import React, { useEffect, useRef } from 'react';
import { Form, Select,Row } from 'antd';
import _ from 'lodash';
import style from './css/detail-invoice.css'

const { Option } = Select;

export default Form.create({
  name: 'customer-form',
  // mapPropsToFields: (props) => {
  //   const { datasource, customer } = props;

  //   const customerId = _.get(datasource, 'customerId');

  //   return {
  //     customer: Form.createFormField({
  //       value: customer ? customerId : '',
  //     }),
  //   };
  // },
})((props) => {

  const { getFieldDecorator, getFieldValue, resetFields } = props.form;
  const { discount, handleSelectDiscount,data } = props

  return (
    <Form>
        <Form.Item>
          {getFieldDecorator('tax', {
            initialValue:_.get(data,'discountId'),
          })(
            <Select className='margin-select' onSelect={(value) => handleSelectDiscount(value)}>
            {discount && discount.map(item =>
              <Option value={item.discountId} key={item.discountId}>
                {item.titleCode}
              </Option>
            )}
          </Select>
          )}
        </Form.Item>
    </Form>
  );

});