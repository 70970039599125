import React, { useState, useEffect, useMemo, useContext } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Row, Col, Form, Input, Select, Button } from 'antd';
import styled from 'styled-components';
import styles from './css/shift-rules.css';
import _ from 'lodash';

const { Option } = Select;

export default React.memo((props) => {
  const intl = useIntl();
  const {
    form,
    valueOverTime,
    index,
    last,
    handleAddMaxOverTime,
    handleDeleteMaxOverTime,
    workHours,
    switchShiftRules,
    hoursType,
    maxOverTime,
  } = props;
  const { getFieldDecorator, getFieldValue, getFieldError } = form;

  const maxDateType =
    valueOverTime.maxOverDateType === null
      ? 'day'
      : valueOverTime.maxOverDateType;

  const [checkDuplicate, setCheckDuplicate] = useState();
  const hoursPer = form.getFieldValue(`maxOverTimeRule`);

  const disabledMaxOverTimeHour = getFieldError(
    `maxOverTimeRule[${index}].maxOverTimeHour`,
  );
  const disabledMaxOverTimeMember = getFieldError(
    `maxOverTimeRule[${index}].maxOverTimeMember`,
  );
  const disabledMaxOverDateType = getFieldError(
    `maxOverTimeRule[${index}].maxOverDateType`,
  );

  useEffect(() => {
    if (hoursPer) {
      const reduceAsMap =
        hoursPer &&
        hoursPer.reduce(
          (acc, curr) => {
            const hour = curr.maxWorkDateType;
            return [...acc, hour];
          },
          [hoursPer],
        );
      setCheckDuplicate(checkIfDuplicateExists(reduceAsMap));
    }
  }, [hoursPer]);

  const checkIfDuplicateExists = (arr) => {
    return new Set(arr).size !== arr.length;
  };

  const disabledAdd = () => {
    if (
      getFieldValue(`maxOverTimeRule[${index}].maxOverTimeHour`) &&
      getFieldValue(`maxOverTimeRule[${index}].maxOverTimeMember`)
    ) {
      return false;
    } else {
      return true;
    }
  };

  const compareMaxOverTimeHour = (rule, value, callback) => {
    const hourDateType = form.getFieldValue(
      `maxOverTimeRule[${index}].maxOverDateType`,
    );
    const found = workHours.findIndex(
      (val) => val.dateType.toLowerCase() === hourDateType,
    );
    let hourTypeValue = workHours[found].hourDateType;

    if (found > -1) {
      if (value > workHours[found].hourDateType) {
        callback(
          <span>
            {intl.formatMessage({
              id: 'orgTabShiftSettingRequireMaximumOverTimeMustNotExceed',
              defaultMessage: 'Maximum work hours must not exceed',
            })}
            <span>{` ${hourTypeValue} `}</span>
          </span>,
        );
      }
    }
    callback();
  };

  const checkDateType = (rule, value, callback, index) => {
    if (hoursPer.length === maxOverTime.length) {
      _.forEach(hoursPer, (ele, i) => {
        if (index !== i) {
          if (value === ele.maxOverDateType) {
            callback(
              <span>
                {intl.formatMessage({
                  id: 'orgTabShiftSettingRequireDateType',
                  defaultMessage: 'Do not repeat the types of work days.',
                })}
              </span>,
            );
          }
        }
      });
      callback();
    } else {
      _.forEach(maxOverTime, (ele, i) => {
        if (index !== i) {
          if (value === ele.maxOverDateType) {
            callback(
              <span>
                {intl.formatMessage({
                  id: 'orgTabShiftSettingRequireDateType',
                  defaultMessage: 'Do not repeat the types of work days.',
                })}
              </span>,
            );
          }
        }
      });
      callback();
    }
  };

  return (
    <div key={index} className="div-form-item">
      <Form.Item
        className="form-item-working-minimum-hours"
        label={
          index === 0 ? (
            <LabeRequire
              text={
                <FormattedMessage
                  id="orgTabShiftSettingLblMaximumOvertimeHours"
                  defaultMessage="Maximum overtime hours"
                />
              }
              req={true}
            />
          ) : (
            ''
          )
        }
        validateStatus={
          disabledMaxOverTimeHour && switchShiftRules ? 'error' : ''
        }
        help={
          disabledMaxOverTimeHour && switchShiftRules
            ? disabledMaxOverTimeHour
            : ''
        }
      >
        {getFieldDecorator(`maxOverTimeRule[${index}].maxOverTimeHour`, {
          initialValue: valueOverTime.maxOverTimeHour,
          rules: [
            {
              required: switchShiftRules,
              message: intl.formatMessage({
                id: 'orgTabShiftSettingRequireMaximumOvertimeHours',
                defaultMessage: 'Please enter your maximum overtime hours.',
              }),
            },
            {
              pattern: new RegExp(/^[0-9\b]+$/),
              message: intl.formatMessage({
                id: 'orgTabShiftSettingRequireOnlyNumber',
                defaultMessage: 'Value should contain just number.',
              }),
            },
            {
              validator: compareMaxOverTimeHour,
            },
          ],
        })(
          <Input
            className="shift-rules-maximum-input"
            placeholder={intl.formatMessage({
              id: 'orgTabShiftSettingHintMaximumOvertimeHours',
              defaultMessage: 'Enter amount of hours',
            })}
            type="number"
            disabled={!switchShiftRules}
            autoComplete="off"
            min="0"
          />,
        )}
      </Form.Item>
      <Form.Item
        className="form-item-working-hours"
        label={
          index === 0 ? (
            <LabeRequire
              text={
                <FormattedMessage
                  id="orgTabShiftSettingLblMaximumOvertimeMember"
                  defaultMessage="Maximum overtime member"
                />
              }
              req={true}
            />
          ) : (
            ''
          )
        }
        validateStatus={
          disabledMaxOverTimeMember && disabledMaxOverTimeMember ? 'error' : ''
        }
        help={
          disabledMaxOverTimeMember && switchShiftRules
            ? disabledMaxOverTimeMember
            : ''
        }
      >
        {getFieldDecorator(`maxOverTimeRule[${index}].maxOverTimeMember`, {
          initialValue: valueOverTime.maxOverTimeMember,
          rules: [
            {
              required: switchShiftRules,
              message: intl.formatMessage({
                id: 'orgTabShiftSettingRequireMaximumOvertimeMember',
                defaultMessage: 'Please enter your maximum overtime member.',
              }),
            },
            {
              pattern: new RegExp(/^[0-9\b]+$/),
              message: intl.formatMessage({
                id: 'orgTabShiftSettingRequireOnlyNumber',
                defaultMessage: 'Value should contain just number.',
              }),
            },
          ],
        })(
          <Input
            className="shift-rules-maximum-input"
            placeholder={intl.formatMessage({
              id: 'orgTabShiftSettingHintMaximumOvertimeMember',
              defaultMessage: 'Enter amount of member',
            })}
            type="number"
            disabled={!switchShiftRules}
            autoComplete="off"
          />,
        )}
      </Form.Item>
      <Form.Item
        className="form-item-select-working-hours"
        label={index === 0 ? <span className="span-color">ฺ</span> : ''}
        validateStatus={
          disabledMaxOverDateType && switchShiftRules && checkDuplicate
            ? 'error'
            : ''
        }
        help={
          disabledMaxOverDateType && switchShiftRules && checkDuplicate
            ? disabledMaxOverDateType
            : ''
        }
      >
        {getFieldDecorator(`maxOverTimeRule[${index}].maxOverDateType`, {
          initialValue: maxDateType || null,
          rules: [
            {
              required: switchShiftRules,
              message: intl.formatMessage({
                id: 'orgTabShiftSettingRequireDateMonthYear',
                defaultMessage: 'Please enter your date, month, year.',
              }),
            },
            {
              validator: (rule, value, callback) =>
                checkDateType(rule, value, callback, index),
            },
          ],
        })(
          <Select
            style={{ width: '324px', fontSize: '13px' }}
            placeholder={intl.formatMessage({
              id: 'orgTabShiftSettingHintDateType',
              defaultMessage: 'Enter work type date',
            })}
            disabled={!switchShiftRules}
          >
            {workHours.map((workHours) => (
              <Option key={workHours.dateType}>{workHours.displayTxt2}</Option>
            ))}
          </Select>,
        )}
      </Form.Item>
      <Form.Item
        className="form-item-button"
        label={index === 0 ? <span className="span-color">ฺ</span> : ''}
      >
        {index === last - 1 && index !== hoursType ? (
          <ButtonAdd
            onClick={handleAddMaxOverTime}
            disabled={!switchShiftRules || disabledAdd()}
          >
            <FormattedMessage
              id="orgTabShiftSettingShiftRulesBtnAdd"
              defaultMessage="Add"
            />
          </ButtonAdd>
        ) : (
          <ButtonEdit
            onClick={() => handleDeleteMaxOverTime(valueOverTime, index)}
            disabled={!switchShiftRules}
          >
            <FormattedMessage
              id="orgTabShiftSettingShiftRulesBtnDelete"
              defaultMessage="Delete"
            />
          </ButtonEdit>
        )}
      </Form.Item>
    </div>
  );
});

const StyledInput = styled(Input)`
  width: 100%;
  .ant-input-group-addon {
    width: 65px;
    font-size: 13px;
  }
`;

const LabelRequire = styled.label`
  color: #ff1010;
  font-size: 13px;
`;

const LabeRequire = (props) => {
  const { text, req } = props;
  return (
    <p style={{ margin: '0px' }}>
      {text}&nbsp;
      {req ? <LabelRequire>*</LabelRequire> : ''}
    </p>
  );
};

const ButtonAdd = styled(Button)`
  width: 57px;
  font-size: 13px;
  border-radius: 2px;
`;

const ButtonEdit = styled(Button)`
  width: 57px;
  font-size: 13px;
  border-radius: 2px;
  padding: 0 8px;
`;
