import { Checkbox, Col, Divider, Form, Icon, Input, Modal, Row, Select, TimePicker } from 'antd';
import React, { useState, useEffect, useContext } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import _ from 'lodash';
import './css/index.css';
import moment from 'moment';
import Button_02 from '../../components/v2/button_02';
import Button_01 from '../../components/v2/button_01';
import LabeRequireForm from '../../components/label-required-form';
import { createAverageSpeed, updateAverageSpeed } from '../../controllers/service-area/average-speed';
import { ServiceAreaContext } from '.';
import { handleKeyDownPhone } from '../../component-function/fnc-number';
import { errorNotification, successNotification } from '../../components/v2/notification';
const { TextArea } = Input;
const { Option } = Select;
const ServiceAverageSpeedModal = ({ form, record, setRecord, visible, setVisible }) => {
  const intl = useIntl();
  const { mainState, mainSetState } = useContext(ServiceAreaContext);
  const { setTriggerApi } = mainSetState;
  const { serviceAreaId } = mainState;
  const { getFieldDecorator, validateFields, resetFields, setFieldsValue, getFieldValue } = form;
  const format = 'HH:mm';

  const days = [
    {
      id: 'mon',
      day: intl.formatMessage({ id: 'orgTabShiftSettingWorkDayMonday', defaultMessage: 'Monday' }),
    },
    {
      id: 'tue',
      day: intl.formatMessage({ id: 'orgTabShiftSettingWorkDayTuesday', defaultMessage: 'Tuesday' }),
    },
    {
      id: 'wed',
      day: intl.formatMessage({ id: 'orgTabShiftSettingWorkDayWednesday', defaultMessage: 'Wednesday' }),
    },
    {
      id: 'thu',
      day: intl.formatMessage({ id: 'orgTabShiftSettingWorkDayThursday', defaultMessage: 'Thursday' }),
    },
    {
      id: 'fri',
      day: intl.formatMessage({ id: 'orgTabShiftSettingWorkDayFriday', defaultMessage: 'Friday' }),
    },
    {
      id: 'sat',
      day: intl.formatMessage({ id: 'orgTabShiftSettingWorkDaySaturday', defaultMessage: 'Saturday' }),
    },
    {
      id: 'sun',
      day: intl.formatMessage({ id: 'orgTabShiftSettingWorkDaySunday', defaultMessage: 'Sunday' }),
    },
  ];

  useEffect(() => {
    let objNew = {};
    let arrNew = [];
    if (record) {
      objNew.sun = _.get(record, 'sun');
      objNew.mon = _.get(record, 'mon');
      objNew.tue = _.get(record, 'tue');
      objNew.wed = _.get(record, 'wed');
      objNew.thu = _.get(record, 'thu');
      objNew.fri = _.get(record, 'fri');
      objNew.sat = _.get(record, 'sat');
    }

    let keys = Object.keys(objNew);
    arrNew = keys.filter((key) => objNew[key] === true);
    setFieldsValue({ ['day']: arrNew });
  }, [record]);

  const onOk = () => {
    validateFields(async (err, values) => {
      if (err) {
        return;
      }
      if (values) {
        const payload = {
          areaMasterId: serviceAreaId,
          sun: compareBoolean('sun', _.get(values, 'day')),
          mon: compareBoolean('mon', _.get(values, 'day')),
          tue: compareBoolean('tue', _.get(values, 'day')),
          wed: compareBoolean('wed', _.get(values, 'day')),
          thu: compareBoolean('thu', _.get(values, 'day')),
          fri: compareBoolean('fri', _.get(values, 'day')),
          sat: compareBoolean('sat', _.get(values, 'day')),
          startTime: _.get(values, 'fromTime') ? moment(_.get(values, 'fromTime')).format(format) : undefined,
          endTime: _.get(values, 'toTime') ? moment(_.get(values, 'toTime')).format(format) : undefined,
          speed: _.toNumber(_.get(values, 'speed')),
        };

        if (_.get(record, 'id')) {
          try {
            const response = await updateAverageSpeed(_.get(record, 'id'), payload);
            console.log('updateAverageSpeed', response);
            if (_.get(response, 'status') === 200) {
              successNotification(_.get(response.data.status, 'message'));
              utilResetValue();
            } else {
              errorNotification(_.get(response.data.status, 'message'));
            }
          } catch (error) {
            errorNotification(_.get(error.response.data.status, 'message'));
          }
        } else {
          try {
            const response = await createAverageSpeed(payload);
            console.log('createAverageSpeed', response);
            if (_.get(response, 'status') === 200) {
              successNotification(_.get(response.data.status, 'message'));
              utilResetValue();
            } else {
              errorNotification(_.get(response.data.status, 'message'));
            }
          } catch (error) {
            errorNotification(_.get(error.response.data.status, 'message'));
          }
        }
      }
    });
  };

  const utilResetValue = () => {
    setTriggerApi((current) => !current);
    setVisible(false);
    resetFields();
    setRecord();
  };

  const compareBoolean = (key, array) => {
    return array.includes(key);
  };

  const onCancel = () => {
    setVisible(false);
    resetFields();
    setRecord();
  };

  const compareStartTime = (rule, value, callback) => {
    let valueEndTime = form.getFieldValue(`toTime`);
    if (value >= valueEndTime) {
      callback(
        <span>
          {intl.formatMessage({
            id: 'orgTabServiceValidateStartTime',
            defaultMessage: 'The start time should not be greater than or equal to the end time.',
          })}
        </span>
      );
    }
    callback();
  };

  const compareEndTime = (rule, value, callback) => {
    let valueStartTime = form.getFieldValue(`fromTime`);
    if (value <= valueStartTime) {
      callback(
        <span>
          {intl.formatMessage({
            id: 'orgTabServiceValidateEndTime',
            defaultMessage: 'The end time should not be less than or equal to the start time.',
          })}
        </span>
      );
    }
    callback();
  };

  return (
    <Modal
      title={
        _.get(record, 'id')
          ? intl.formatMessage({ id: `serviceTitleEditAddAverageSpeed`, defaultMessage: 'Edit Average Speed' })
          : intl.formatMessage({ id: `serviceTitleCreateAddAverageSpeed`, defaultMessage: 'Add Average Speed' })
      }
      visible={visible}
      centered
      width={700}
      bodyStyle={{ padding: '6px 24px 24px 24px' }}
      onOk={onOk}
      onCancel={onCancel}
      footer={[
        <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={() => onCancel()} margin=" unset">
          <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
        </Button_02>,
        <Button_01 key="submit" type="primary" btnsize="wd_df" onClick={() => onOk()}>
          <FormattedMessage id="btnSave" defaultMessage="Save" />
        </Button_01>,
      ]}
    >
      <Form colon={false}>
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Row gutter={[24, 2]}>
              <Col span={24}>
                <Form.Item
                  label={<LabeRequireForm text={<FormattedMessage id="warehouseTitleApplyon" defaultMessage="Apply on" />} req={true} />}
                >
                  {getFieldDecorator('day', {
                    // initialValue: _.get(record, 'warehouseCode') ? _.get(record, 'warehouseCode') : undefined,

                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({
                          id: 'warehouseValidateApplyon',
                          defaultMessage: 'Please select Apply on',
                        }),
                      },
                    ],
                  })(
                    <Checkbox.Group style={{ width: '100%' }}>
                      <div className="row-item-checkbox">
                        {days &&
                          days.map((item, key) => (
                            <div className="item-checkbox" key={item.id} style={{ width: 'auto' }}>
                              <Checkbox className="checkbox-border" value={item.id} role="checkbox" data-testid={`checkbox-${item.id}`}>
                                {item.day}
                              </Checkbox>
                            </div>
                          ))}
                      </div>
                    </Checkbox.Group>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24, 2]}>
              <Col span={8}>
                <Form.Item
                  label={
                    <LabeRequireForm text={intl.formatMessage({ id: 'warehouseModalFromTime', defaultMessage: 'From Time' })} req={true} />
                  }
                >
                  {getFieldDecorator('fromTime', {
                    initialValue: _.get(record, 'startTime') ? moment(_.get(record, 'startTime'), 'HH:mm') : undefined,
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({ id: 'warehouseSettingValidatefromtime', defaultMessage: 'Please select from time' }),
                      },
                      {
                        validator: compareStartTime,
                      },
                    ],
                  })(
                    <TimePicker
                      style={{ width: '100%' }}
                      format={format}
                      placeholder={intl.formatMessage({ id: 'warehouseSettingPlfromtime', defaultMessage: 'Select from Time' })}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label={
                    <LabeRequireForm text={intl.formatMessage({ id: 'warehouseModalToTime', defaultMessage: 'To Time' })} req={true} />
                  }
                >
                  {getFieldDecorator('toTime', {
                    initialValue: _.get(record, 'endTime') ? moment(_.get(record, 'endTime'), 'HH:mm') : undefined,
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({ id: 'warehouseSettingValidatetotime', defaultMessage: 'Please select to Time' }),
                      },
                      {
                        validator: compareEndTime,
                      },
                    ],
                  })(
                    <TimePicker
                      style={{ width: '100%' }}
                      format={format}
                      placeholder={intl.formatMessage({ id: 'warehouseSettingPltotime', defaultMessage: 'Select to Time' })}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label={
                    <LabeRequireForm text={intl.formatMessage({ id: 'settingModalAverageSpeed', defaultMessage: 'km/hr' })} req={true} />
                  }
                >
                  {getFieldDecorator('speed', {
                    initialValue: _.get(record, 'speed') ? _.get(record, 'speed') : undefined,
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({ id: 'settingValidateAverageSpeed', defaultMessage: 'Please Enter Average Speed' }),
                      },
                    ],
                  })(
                    <Input
                      style={{ width: '100%' }}
                      placeholder={intl.formatMessage({ id: 'settingPlAverageSpeed', defaultMessage: 'Enter Average Speed' })}
                      maxLength={3}
                      onKeyDown={handleKeyDownPhone}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

const ServiceAverageSpeedModalForm = Form.create({
  name: 'warehouse-form',
})(ServiceAverageSpeedModal);

export default ServiceAverageSpeedModalForm;
