import React from 'react';
import { Input, Icon } from 'antd';
import stylePreview from './css/preview-picture.css';

const { TextArea } = Input;

const Preview01 = (props) => {
  const { imageList, handlePreviewImage } = props;
  return (
    <div className='preview-picture-row'>
      {imageList && imageList.map((item, idex) => (
        <div className='preview-picture-column-01' key={idex}>
          <img className='preview-picture-imgage' src={item.pathImage} id={idex} key={idex} onClick={() => handlePreviewImage(idex)} />
        </div>
      ))}
    </div>
  )
}

const Preview02 = (props) => {
  const { imageList, handlePreviewImage } = props;
  return (
    <div className='preview-picture-row'>
      {imageList && imageList.map((item, idex) => (
        <div className='preview-picture-column-02' key={idex}>
          <img className='preview-picture-imgage' src={item.pathImage} id={idex} key={idex} onClick={() => handlePreviewImage(idex)} />
        </div>
      ))}
    </div>
  )
}

const Preview03 = (props) => {
  const { imageList, handlePreviewImage } = props;
  return (
    <div className='preview-picture-row'>
      <div className='preview-picture-column-03-01'>
        <img className='preview-picture-imgage' src={imageList[0].pathImage} id={0} key={0} onClick={() => handlePreviewImage(0)} />
      </div>
      <div className='preview-picture-column-03-xx'>
        <img className='preview-picture-imgage' src={imageList[1].pathImage} id={1} key={1} onClick={() => handlePreviewImage(1)} />
      </div>
      <div className='preview-picture-column-03-xx'>
        <img className='preview-picture-imgage' src={imageList[2].pathImage} id={2} key={2} onClick={() => handlePreviewImage(2)} />
      </div>
    </div>
  )
}

const Preview04 = (props) => {
  const { imageList, handlePreviewImage } = props;
  return (
    <div className='preview-picture-row'>
      {imageList && imageList.map((item, idex) => (
        idex < 1 ? (
          <div className='preview-picture-column-04-01' key={idex}>
            <img className='preview-picture-imgage' src={item.pathImage} id={idex} key={idex} onClick={() => handlePreviewImage(idex)} />
          </div>
        ) : idex < 4 ? (
          <div className='preview-picture-column-04-02' key={idex}>
            <img className='preview-picture-imgage' src={item.pathImage} id={idex} key={idex} onClick={() => handlePreviewImage(idex)} />
          </div>
        ) : null)
      )}
    </div>
  )
}

const Preview05 = (props) => {
  const { imageList, handlePreviewImage } = props;
  return (
    <div className='preview-picture-row' >
      {imageList && imageList.map((item, idex) => (
        idex < 2 ? (
          <div className='preview-picture-column-05-01' key={idex}>
            <img className='preview-picture-imgage' src={item.pathImage} id={idex} key={idex} onClick={() => handlePreviewImage(idex)} />
          </div>
        ) : idex < 5 ? (
          <div className='preview-picture-column-05-02' key={idex}>
            <img className='preview-picture-imgage' src={item.pathImage} id={idex} key={idex} onClick={() => handlePreviewImage(idex)} />
          </div>
        ) : null)
      )}
    </div>
  )
}

const PreviewPlus = (props) => {
  const { imageList, handlePreviewImage } = props;
  return (
    <div className='preview-picture-row'>
      {imageList && imageList.map((item, idex) => (
        idex < 2 ? (
          <div className='preview-picture-column-05-01'>
            <img className='preview-picture-imgage' src={item.pathImage} id={idex} key={idex} onClick={() => handlePreviewImage(idex)} />
          </div>
        ) : idex < 4 ? (
          <div className='preview-picture-column-05-02'>
            <img className='preview-picture-imgage' src={item.pathImage} id={idex} key={idex} onClick={() => handlePreviewImage(idex)} />
          </div>
        ) : idex === 4 ? (
          <div className='preview-picture-column-05-plus'>
            <div className='preview-picture-column-05-plus-box' onClick={() => handlePreviewImage(idex)}>
              <img className='preview-picture-imgage-05-plus' src={item.pathImage} id={idex} key={idex} />
              <div className='preview-picture-centered-05-plus'>+&nbsp;{imageList && imageList.length - 5}</div>
            </div>
          </div>
        ) : null)
      )}
    </div>
  )
}

const PreviewSingle = (props) => {
  const { action, imageList, handleDeleteSingleImage, onChangeCommentImg, handleBack, setOpenGallery, type } = props;
  const actionPreview = (action, imageData) => {
    switch (action) {
      case 'actionView':
        return (
          <div className='preview-picture-single-row'>
            {imageData && imageData.map((item, index) => (
              <div>
                <div className='preview-picture-single-column'>
                  <img className='preview-picture-single-imgage' src={item.pathImage} id={index} key={index} onClick={() => setOpenGallery(true)} />
                  <div className='preview-picture-single-top-right' onClick={() => handleDeleteSingleImage(item)}><Icon type='close' /></div>
                  {type === 'add' ? (
                    <div className='preview-picture-single-top-left' onClick={() => handleBack(item)}><Icon type='left' /></div>
                  ) : null}
                </div>
                <div className='preview-picture-single-comment'>
                  <TextArea
                    className='preview-picture-single-input'
                    placeholder='Comment Post Image'
                    value={item.description}
                    onChange={(e) => onChangeCommentImg(e, imageData, index)}
                    rows={1}
                  />
                </div>
              </div>
            ))}
          </div>
        );
      case 'view':
        return (
          <div className='preview-picture-single-row'>
            {imageData && imageData.map((item, index) => (
              <div>
                <div className='preview-picture-single-column'>
                  <img className='preview-picture-single-imgage' src={item.pathImage} id={index} key={index} />
                  <div className='preview-picture-single-top-right' onClick={() => handleBack(item)}><Icon type='left' /></div>
                </div>
                <div className='preview-picture-single-comment'>
                  <span className='preview-picture-comment-text'>
                    {item.description}
                  </span>
                </div>
              </div>
            ))}
          </div>
        );
      default:
        return null;
    }
  }
  return (
    actionPreview(action, imageList)
  )
}

export { Preview01, Preview02, Preview03, Preview04, Preview05, PreviewPlus, PreviewSingle }