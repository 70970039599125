import React, { useState, useEffect, useContext } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useAppContext } from '../../../includes/indexProvider';
import { ListViewContext } from '../monitor-context';
import { Button, Row, Input, Col, DatePicker, Select, List, Icon, Dropdown, Checkbox, ConfigProvider, Menu, } from 'antd';
import Button01 from '../../../components/v2/button_01';
import Styles from '../list-view/css/list-view.css';
import moment from 'moment';
import enUS from 'antd/es/locale/en_US';
import thTH from 'antd/es/locale/th_TH';
import 'moment/locale/th';

const { Option } = Select;
const { RangePicker } = DatePicker;

export default ({ stateMain, endDatePicker }) => {
  const intl = useIntl();
  const app = useAppContext();
  const langValue = app.state.langValue;
  const { state, fnc, setState } = useContext(ListViewContext);


  const dateFormat = 'ddd, MMM DD YYYY';
  const today = new Date();

  const ignoreColumn = [
    'index',
    'taskNo',
    'taskType',
    'customer',
  ];
  //const ignoreColumn = ['index'];
  const columnNew = state.columns.filter((col) => !ignoreColumn.includes(col.key));

  const ignoreSearchColumn = ['index'];
  const columnNewSearch = state.columns.filter((col) => !ignoreSearchColumn.includes(col.key));


  const [locale, setLocale] = useState(enUS);


  useEffect(() => {
    changeLocale();
  }, []);

  const changeLocale = () => {
    let codeLang = langValue ? langValue : 'EN';
    let changeLang = codeLang === 'EN' ? enUS : thTH;
    setLocale(changeLang);
    if (!changeLang) {
      moment.locale('en');
    } else {
      moment.locale('th-th');
    }
  };

  const handleChangeOption = (value, options) => {

    const dataCreate = [];
    value.forEach((element) => {
      dataCreate.push(element);
    });
    // setSelect(dataCreate);
    state.setStateMain.setMainOrgIdArr(dataCreate);
    var orgIdArr = dataCreate.toString();
    state.setStateMain.setMainOrgIdArrString(orgIdArr);
  };



  const menuColumn = () => {
    return (
      <Menu className="menu-columns">
        <Menu.Item  className="menu-columns-list-item" style={{ backgroundColor: '#ffffff' }}>
          {columnNew.map((item, i) => (
            <div key={i} style={{ display: 'block', marginBottom: '10px' }}>
              <Checkbox
                value={item.key}
                onChange={fnc.checkedValuecolumns}
                checked={state.defaultShowColumn[item.key] ? true : false}
              >
                <span style={{ fontSize: '13px' }}>{item.title}</span>
              </Checkbox>
            </div>
          ))}
        </Menu.Item>

        <Menu.Item style={{ color: '#FF1C00', textAlign: 'left', backgroundColor: '#ffffff' }}>
          {state.textErrorSelectColumn}
        </Menu.Item>

        <Menu.Item style={{ backgroundColor: '#ffffff' }}>
          <Button.Group style={{ display: 'flex', justifyContent: 'center' }}>
            <Button onClick={fnc.handleCheckOk} style={{ width: '50%', fontSize: '13px' }}>
              <FormattedMessage id="btnOK" defaultMessage="OK" />
            </Button>
            <Button onClick={fnc.handleReset} style={{ width: '50%', fontSize: '13px' }}>
              <FormattedMessage id="btnReset" defaultMessage="Reset" />
            </Button>
          </Button.Group>
        </Menu.Item>
      </Menu>
    )
  }

  return (
    <div>
      <Row>
        <Col span={12} style={{ margin: 0 }}>
          <Row>
            <List.Item className="list-view-list-left">
              <Input
                className="list-view-input"
                placeholder={intl.formatMessage({
                  id: 'hintSearch',
                  defaultMessage: 'Search',
                })}
                prefix={<Icon style={{ color: "rgba(0,0,0,.25)" }} type="search" />}
                onChange={(e) => fnc.handleSearch(e.target.value)}
                autoComplete="off"
              />
              <Select
                className="list-view-select"
                placeholder={intl.formatMessage({
                  id: 'hintSelect',
                  defaultMessage: 'Select',
                })}
                onSelect={(value) => fnc.handleSelectSearch(value)}
                value={state.selectFilter}
              >
                <Option value="all">
                  <FormattedMessage id="reportOutsideReportHintSeleteAllColumns" defaultMessage="All columns" />
                </Option>
                {columnNewSearch.map((item, i) => (
                  <Option key={i} value={item.key}>
                    {item.title}
                  </Option>
                ))}
              </Select>
            </List.Item>
          </Row>
        </Col>
        <Col span={12}>
          <Row>
            <List.Item className="list-view-list-right">
              <ConfigProvider locale={locale}>
                <RangePicker
                  className="list-view-rangePicker"
                  onChange={fnc.onSelectRangePicker}
                  value={[moment(stateMain.mainDateStartAction, dateFormat), moment(stateMain.mainDateEndAction, dateFormat)]}
                  format={dateFormat}
                  allowClear={false}
                  size="small"
                />
              </ConfigProvider>

              <Select
                className="list-view-select-team"
                placeholder={intl.formatMessage({
                  id: 'hintSelect',
                  defaultMessage: 'Select',
                })}
                value={stateMain.mainOrgIdArr}
                defaultValue={[]}
                onChange={handleChangeOption}
                maxTagCount={1}
                mode="multiple"
                showSearch
                filterOption={(input, option) =>
                  option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {stateMain.mainOrganizationTeamData && stateMain.mainOrganizationTeamData.map((e, index) => (
                  <Option
                    disabled={
                      state.stateMain.mainOrgIdArr.length > 3
                        ? state.stateMain.mainOrgIdArr.includes(e.orgId)
                          ? false
                          : true
                        : false
                    }
                    key={index} value={e.orgId}>
                    {e.orgName}</Option>
                ))}
              </Select>

              <Dropdown
                className="list-view-dropdown"
                trigger={['click']}
                placement="bottomRight"
                overlay={menuColumn}
                onVisibleChange={fnc.handleVisibleChange}
                visible={state.visible}
              >
                <Button01
                  type="primary"
                  btnsize="wd_at"
                  fontSize="sm"
                  onClick={fnc.handleOnVisiblecolumns}
                >
                  <FormattedMessage id="monitorTabListViewColumnSelect" defaultMessage="Columns" />{" "}
                  <Icon type="down" />
                </Button01>
              </Dropdown>
            </List.Item>
          </Row>
        </Col>
      </Row>
    </div>
  );
};
