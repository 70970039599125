import { Card, Col, Modal, Row, Tabs } from 'antd';
import React, { useState, useEffect } from 'react';
// import LeftCardApproval from './left-card';
import { AllticketContext } from './allticket-context';
// import RightCardApproval from './right-card';
import _ from 'lodash';
import LeftCardAllTicket from './left-card';
import RightCardAllticket from './right-card';
import { FormattedMessage, useIntl } from 'react-intl';
import { PageSettings } from '../../../../config/page-settings';
import { getMenuFilter } from '../../../../controllers/approval/approval-controller';
import {
  getBrandReportTicket,
  getModelReportTicket,
  getRepairTicketData,
  getTicketData,
  getTicketDetail,
} from '../../../../controllers/ticket/ticket';
import Button_01 from '../../../../components/v2/button_01';
import { getItemDetail, getItemTranferList, getTFModel } from '../../../../controllers/tranferinventory/tranferitem';
import { getItemGroupAndType } from '../../../../controllers/item-request/item-request';

const ModalItemTranfer = (props) => {
  const { isOpen, closeTicketModal, setRequestDetailData, requestDetailData, notWarehouseFromId, handleQTYOk, wareHouseFromList } = props;
  const intl = useIntl();
  const [checkedListRequest, setCheckedListRequest] = useState();
  const [checkteam, setCheckTeam] = useState();
  const [orgIdRaw, setOrgIdRaw] = useState();
  const [paginationPage, setPaginationPage] = useState();
  const [spanRight, setSpanRight] = useState(18);
  const [spanLeft, setSpanLeft] = useState(6);
  const [menuList, setMenuList] = useState([]);
  const [indeterminateTeam, setIndeterminateTeam] = useState(false);
  const [checkedKeysTeam, setCheckedKeysTeam] = useState([]);
  const [searchList, setSearchList] = useState([]);
  const [changeStatus, setChangeStatus] = useState([]);
  const [changeTab, setChangeTab] = useState('1');
  const [checkAllTeam, setCheckAllTeam] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [fillter, setFillter] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [visibleCreate, setVisibleCreate] = useState(false);
  const [visibleView, setVisibleView] = useState(false);
  const [ticketId, setTicketId] = useState();
  const [ticketData, setTicketData] = useState([]);
  const [event, setEvent] = useState(false);
  const [editRecord, setEditRecord] = useState();
  const [sort, setSort] = useState([]);
  const [total, setTotal] = useState();
  const [changeTicketNo, setChangeTicketNo] = useState('');
  //----------------------- Brand & Model ------------------------//
  const [brandData, setBrandData] = useState([]);
  const [brandPage, setBrandPage] = useState(10);
  const [brandLoading, setBrandLoading] = useState(false);
  const [modelData, setModelData] = useState([]);
  const [modelPage, setModelPage] = useState(10);
  const [modelLoading, setModelLoading] = useState(false);
  const [totalBrand, setTotalBrand] = useState(10);
  const [totalModel, setTotalModel] = useState(10);
  //--------------------------------------------------------------//
  const [warehouseName, setWarehouseName] = useState([]);

  useEffect(() => {
    const getDataTable = async () => {
      const body = {
        warehouseId: notWarehouseFromId,
        itemCode: _.get(fillter, 'itemCode'),
        itemName: _.get(fillter, 'itemName'),
        lotOrSerialNo: _.get(fillter, 'lotOrSerialNo'),
        brandIdList: _.get(fillter, 'brand') ? [_.get(fillter, 'brand')] : [],
        modelListId: _.get(fillter, 'model') ? [_.get(fillter, 'model')] : [],
        expiredDateFrom: _.get(fillter, 'startdate'),
        expiredDateTo: _.get(fillter, 'enddate'),
        pageNumber: page,
        limit: size,
        orderBy: _.get(sort, 'field'),
        orderType: _.get(sort, 'order') === 'descend' ? 'desc' : 'asc',
      };
      const warhousename = _.filter(wareHouseFromList, (e) => {
        return e.warehouseId === notWarehouseFromId;
      });
      setWarehouseName(warhousename[0]);
      console.log('bodyTicket', body);
      const responase = await getItemTranferList(body);
      setTicketData(_.get(responase, 'data'));
      setTotal(_.get(responase, 'data.totalItem'));
      setIsLoading(false);
    };
    if (isOpen === true) {
      setIsLoading(true);
      getDataTable();
      console.log('tikcetData', ticketData);
    }
  }, [event, page, size]);

  useEffect(() => {
    // if (_.size(requestDetailData) > 0) {
    setFillter({ ...fillter, ['reno']: _.get(requestDetailData, 'ticketNo') });
    setChangeTicketNo(_.get(requestDetailData, 'ticketNo'));
    setEvent((current) => !current);
    // }
    // else {
    //   setChangeTicketNo(_.get(requestDetailData,'ticketNo'))
    //   setEvent((current) => !current);
    // }
  }, [requestDetailData, isOpen]);

  useEffect(() => {
    getBrand();
  }, [totalBrand, isOpen]);

  useEffect(() => {
    if (_.get(fillter, 'brand') !== undefined) {
      getModel();
    }
  }, [totalModel, _.get(fillter, 'brand')]);

  const getBrand = async () => {
    const body = {
      warehouseIdList: notWarehouseFromId,
    };
    const response = await getItemGroupAndType(body);
    console.log('brandData', _.get(response, 'data.listBrand'));
    setBrandData(_.get(response, 'data.listBrand'));
    setIsLoading(false);
  };

  const handleBrandPopupScroll = (e) => {
    let target = e.target;
    if (!brandLoading && target.scrollTop + target.offsetHeight >= target.scrollHeight - 50) {
      setBrandPage(brandPage + 10);
      setBrandLoading(true);
    }
  };

  const handleModelPopupScroll = (e) => {
    let target = e.target;
    if (!modelLoading && target.scrollTop + target.offsetHeight >= target.scrollHeight - 50) {
      setModelPage(modelPage + 10);
      setModelLoading(true);
    }
  };

  const getModel = async () => {
    const body = {
      indexStart: 0,
      indexEnd: totalBrand,
      item_model_name: '',
      item_brand_id: [_.get(fillter, 'brand')],
    };

    const responase = await getTFModel(body);
    console.log('ModelData', responase.data);
    setModelData(_.get(responase, 'data.list'));
    // setTotal(_.get(responase, 'data.data.totalItems'));
    setIsLoading(false);
  };

  let getAllKeys = (tree) => {
    let result = [];

    _.forEach(tree, (x) => {
      let childKeys = [];

      if (x.reqTypeList) {
        childKeys = getAllKeys(x.reqTypeList);
      }
      result.push(...[x.reqTypeCode, ...childKeys]);
    });

    return result;
  };

  const onCheckTeam = (checkedKeys) => {};

  const handleToggle = () => {
    setToggle((current) => !current);
  };

  const handleChangePage = (page) => {
    console.log('pageFine', page);
    setPage(page);
  };

  const handleChangeSize = (current, size) => {
    setSize(size);
    setPage(current);
  };

  const handleVisibleCreate = () => {
    setVisibleCreate(true);
  };

  const handleView = (record) => {
    setTicketId(record.ticketId);
    setVisibleView(true);
  };

  const handleEdit = async (record) => {
    const responase = await getTicketDetail(_.get(record, 'ticketId'));
    setEditRecord(_.get(responase, 'data.data.rawData'));
    setTicketId(record.ticketId);
    handleVisibleCreate();
    console.log('editRecord1', _.get(responase, 'data.data'));
    // console.log('editRecord',_.get(responase,'data.data.rawData'));
  };

  const handleClose = (record) => {
    closeTicketModal();
    setPage(1);
    setSize(10);
  };

  return (
    // <PageSettings.Consumer>
    //   {({ checkPermissionAction }) =>
    //     checkPermissionAction(`P53PG1C1`, `P53PG1C1A1`) ? (
          <AllticketContext.Provider
            value={{
              state: {
                checkedListRequest,
                checkteam,
                orgIdRaw,
                paginationPage,
                spanRight,
                spanLeft,
                menuList,
                indeterminateTeam,
                checkedKeysTeam,
                searchList,
                checkAllTeam,
                changeTab,
                isLoading,
                fillter,
                selectedRowKeys,
                toggle,
                visibleCreate,
                ticketData,
                event,
                editRecord,
                sort,
                ticketId,
                page,
                total,
                isLoading,
                requestDetailData,
                changeTicketNo,
                brandData,
                modelData,
                totalBrand,
                totalModel,
              },
              setState: {
                setSpanRight,
                setSpanLeft,
                setCheckedListRequest,
                setCheckTeam,
                setOrgIdRaw,
                setPaginationPage,
                setCheckedKeysTeam,
                setIndeterminateTeam,
                setChangeStatus,
                setChangeTab,
                setCheckAllTeam,
                setIsLoading,
                setFillter,
                setSelectedRowKeys,
                setToggle,
                setVisibleCreate,
                setEditRecord,
                setEvent,
                setSort,
                setRequestDetailData,
                setChangeTicketNo,
                setBrandData,
                setModelData,
                setTotalBrand,
                setTotalModel,
                setPage,
              },
              fnc: {
                onCheckTeam,
                handleToggle,
                handleChangePage,
                handleChangeSize,
                handleVisibleCreate,
                handleView,
                handleEdit,
                closeTicketModal,
                handleBrandPopupScroll,
                handleModelPopupScroll,
                handleQTYOk,
              },
            }}
          >
            <Modal
              visible={isOpen}
              onCancel={() => handleClose()}
              centered={true}
              title={
                intl.formatMessage({ id: 'TFAddItem', defaultMessage: 'Add Item' }) +
                ' · ' +
                _.get(warehouseName, 'warehouseCode') +
                ' ' +
                _.get(warehouseName, 'warehouseName')
                // record ? (
                //   <FormattedMessage id="EditFine" defaultMessage="Edit Fine" />
                // ) : (
                //   <FormattedMessage id="CreateFine" defaultMessage="Create Fine" />
                // )
              }
              width={'90%'}
              style={{ marginTop: '15px' }}
              bodyStyle={{ padding: '0px 0px', height: 'calc(100vh - 230px)' }}
              footer={[
                <div style={{ paddingTop: '10px' }}>
                  <Button_01 key="submit" type="primary" btnsize="wd_df" onClick={() => handleClose()} style={{ marginTop: '10px' }}>
                    <FormattedMessage id="btnClose" defaultMessage="Close" />
                  </Button_01>
                </div>,
              ]}
            >
              <Row gutter={[16, 16]}>
                <Col span={toggle ? 0 : 6} style={{ padding: '8px 0px 8px 8px' }}>
                  <LeftCardAllTicket />
                </Col>
                <Col span={toggle ? 24 : 18} style={{ padding: '8px 8px 8px 0px' }}>
                  <RightCardAllticket />
                </Col>
              </Row>
            </Modal>
          </AllticketContext.Provider>
    //     ) : null
    //   }
    // </PageSettings.Consumer>
  );
};

export default ModalItemTranfer;
