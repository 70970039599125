import React, { useState, useEffect, useContext, useCallback, useRef } from 'react';
import { GoogleMap, useJsApiLoader, useLoadScript, Polyline, Marker, OverlayView } from '@react-google-maps/api';
import { useIntl, FormattedMessage } from 'react-intl';
import { MonitorContext } from '../monitor-context';
import MapControl from './map-control';
import moment from 'moment';
import _, { debounce } from 'lodash';
import { Icon, Card, Avatar } from 'antd';
import stylesMapTracking from './css/index.css';

const containerStyle = {
  width: '100%',
  height: 'calc(100vh - 540px)',
};

const center = {
  lat: 13.856785,
  lng: 100.520326
};

const defaultMapOptions = {
  fullscreenControl: false,
  fullscreenControlOptions: false,
  streetViewControl: false,
  clickableIcons: false,
  zoomControl: true,
  gestureHandling: "cooperative",
  mapTypeControl: true,
  mapTypeControlOptions: {
    style: 1,
    position: 3,
  },
};

const MapTracking = (props) => {
  const { value, setState } = props;
  const { stateMain, setStateMain } = useContext(MonitorContext);

  let statusColor = _.get(stateMain, 'mainResSpeedTrack.statusColor') || '#ffffff';
  const libraries = ["places"];
  const mapRef = useRef();
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: localStorage.getItem('GEO_APIKEY'),
    libraries,
  });

  const [openResource, setOpenResource] = useState(true);
  const [openTask, setOpenTask] = useState(true);
  const [resourceActive, setResourceActive] = useState('map-control-icon-focus');
  const [taskActive, setTaskActive] = useState('map-control-icon-focus-task');

  const [resourceData, setResourceData] = useState([]);
  const [taskData, setTaskData] = useState([]);
  const [polylineData, setPolylineData] = useState([]);

  useEffect(() => {
    if (stateMain.mainResSpeedTrack && stateMain.mainResSpeedTrack.openSpeedTrack === true) {
      let resData = _.get(stateMain, 'mainResSpeedTrack.dataSpeedTrack.memberProfile') ? [_.get(stateMain, 'mainResSpeedTrack.dataSpeedTrack.memberProfile')] : [];
      setTimeout(() => {
        setTaskData(_.get(stateMain, 'mainResSpeedTrack.dataTask'));
        setResourceData(resData);
        setPolylineData(_.get(stateMain, 'mainResSpeedTrack.dataSpeedTrack.directions'));

        setState.setlatLngResource({
          lat: parseFloat(_.get(stateMain, 'mainResSpeedTrack.dataSpeedTrack.memberProfile.location_lat')),
          lng: parseFloat(_.get(stateMain, 'mainResSpeedTrack.dataSpeedTrack.memberProfile.location_lng'))
        });
      }, 500);
    }
  }, [stateMain.mainResSpeedTrack]);

  const onMapLoad = useCallback((map) => {
    mapRef.current = map
  }, []);

  if (loadError) return "Error loading maps";
  if (!isLoaded) return "Loading Maps";

  const getPixelPositionOffset = (width, height) => ({
    x: -(width / 2),
    y: -(height / 2),
  });

  const renderTask = () => taskData && taskData.map((task, index) => {
    return (
      <Marker
        className="marker-task"
        outline={'none'}
        style={{ outline: "none"}}
        zIndex={-1}
        key={task.taskId}
        id={task.taskId}
        position={new window.google.maps.LatLng(parseFloat(task.lat), parseFloat(task.lng))}
        icon={{
          url: task.pathTaskStatus,
          scaledSize: new window.google.maps.Size(40, 40),
          origin: new window.google.maps.Point(0, 0),
        }}
      />
    );
  });

  const renderResource = () => resourceData && resourceData.map((item, index) => {
    return (
      <OverlayView
        id={item.mem_id}
        position={new window.google.maps.LatLng(parseFloat(item.location_lat), parseFloat(item.location_lng))}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        getPixelPositionOffset={getPixelPositionOffset}
      >
        <Avatar key={item.mem_id} src={item.default_profile_img} size='large' style={{ border: `3px ${statusColor} solid`, zIndex: 1 }} />
      </OverlayView>
    );
  });

  const renderPolyline = () => polylineData && polylineData.map((item, index) => {
    return (
      <Polyline
        key={`polyline-${index}`}
        path={[{...polylineData[index]}, {...polylineData[index-1]}]}
        options={{
          strokeColor: item.speedColor,
          strokeWeight: 3
        }}
      />
    );
  });

  const onClickShowTask = (e) => {
    if (openTask === true) {
      setOpenTask(false);
      setTaskActive('');
    } else if (openTask === false) {
      setOpenTask(true);
      setTaskActive('map-control-icon-focus-task');
    }
  };

  const onClickShowResource = (e) => {
    if (openResource === true) {
      setOpenResource(false);
      setResourceActive('map-control-icon-focus-default');
    } else if (openResource === false) {
      setOpenResource(true);
      setResourceActive('map-control-icon-focus');
    }
  };

  return (
    <div className="map-tracking">
      <div className="map-tracking-container">
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={value.latLngResource}
          options={defaultMapOptions}
          zoom={14}
          onLoad={onMapLoad}
        >
          <MapControl 
            resourceActive={resourceActive}
            taskActive={taskActive}
            onClickShowResource={onClickShowResource}
            onClickShowTask={onClickShowTask}
          />

          {openTask ? renderTask() : null}
          {openResource &&  resourceData.length ? renderResource() : null}
          {polylineData && polylineData.length ? renderPolyline() : null}

        </GoogleMap>
      </div>
    </div>
  );
}

export default MapTracking;