import React from 'react'
import { Form, Modal, Divider, Col, Row, Input } from 'antd'
import './css/index.css'
import Button02 from '../v2/button_02'
import Button01 from '../v2/button_01'
import { useIntl, FormattedMessage } from 'react-intl';

const { TextArea } = Input
const addItem = (props) => {
  const { form, setVisible, visible, intl } = props
  const { getFieldDecorator, validateFields, resetFields, getFieldsValue, getFieldValue, setFieldsValue } = form;

  const handleCreate = () => {
    setVisible(false)
    resetFields();
  }

  const handleCancel = () => {
    setVisible(false)
    resetFields();
  }

  return (
    <Modal
      title='Add Item'
      className='item-modal'
      centered={true}
      visible={visible}
      width={600}
      onCancel={handleCancel}
      footer={[
        <Button02 style={{margin : '0px 0px 0px 10px'}}
          key="back"
          btnsize="wd_df"
          onClick={() => handleCancel()}
        >
          <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
        </Button02>,
        <Button01
          key="submit"
          type="primary"
          btnsize="wd_df"
          onClick={() => handleCreate()}
        >
          <FormattedMessage id="btnSave" defaultMessage="Save" />
        </Button01>,
      ]}
    >
      <Form>
        <div className='padding-div-top'>
          <Row className='padding-div-item'>
            <Col span={8}>
              <Form.Item>
                {getFieldDecorator("itemCode", {
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'ModalContactValidateName', defaultMessage: 'Please Enter Name' })
                    }
                  ]
                })(<Input className='input-header-style' placeholder={intl.formatMessage({ id: 'ModalContactPlaceholderName', defaultMessage: 'Enter Name' })} autoComplete="off"></Input>)}
              </Form.Item>
            </Col>
            <Col span={1}>
            </Col>
            <Col span={8}>
              <Form.Item>
                {getFieldDecorator("itemName", {
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'ModalContactValidateName', defaultMessage: 'Please Enter Name' })
                    }
                  ]
                })(<Input className='input-header-style' placeholder={intl.formatMessage({ id: 'ModalContactPlaceholderName', defaultMessage: 'Enter Name' })} autoComplete="off"></Input>)}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={4}>
              <span>Brand Name: </span>
            </Col>
            <Col span={8}>
              <Form.Item>
                {getFieldDecorator("brandName", {
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'ModalContactValidateName', defaultMessage: 'Please Enter Name' })
                    }
                  ]
                })(<Input placeholder={intl.formatMessage({ id: 'ModalContactPlaceholderName', defaultMessage: 'Enter Name' })} autoComplete="off"></Input>)}
              </Form.Item>
            </Col>
            <Col span={4}>
              <span>Model Name: </span>
            </Col>
            <Col span={8}>
              <Form.Item>
                {getFieldDecorator("modelName", {
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'ModalContactValidateName', defaultMessage: 'Please Enter Name' })
                    }
                  ]
                })(<Input placeholder={intl.formatMessage({ id: 'ModalContactPlaceholderName', defaultMessage: 'Enter Name' })} autoComplete="off"></Input>)}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={4}>
              <span>Item No : </span>
            </Col>
            <Col span={8}>
              <Form.Item>
                {getFieldDecorator("itemNo", {
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'ModalContactValidateName', defaultMessage: 'Please Enter Name' })
                    }
                  ]
                })(<Input placeholder={intl.formatMessage({ id: 'ModalContactPlaceholderName', defaultMessage: 'Enter Name' })} autoComplete="off"></Input>)}
              </Form.Item>
            </Col>
            <Col span={4}>
              <span>Unit Price : </span>
            </Col>
            <Col span={8}>
              <Form.Item>
                {getFieldDecorator("unitPrice", {
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'ModalContactValidateName', defaultMessage: 'Please Enter Name' })
                    }
                  ]
                })(<Input placeholder={intl.formatMessage({ id: 'ModalContactPlaceholderName', defaultMessage: 'Enter Name' })} autoComplete="off"></Input>)}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={4}>
              <span>Currency : </span>
            </Col>
            <Col span={8}>
              <Form.Item>
                {getFieldDecorator("currency", {
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'ModalContactValidateName', defaultMessage: 'Please Enter Name' })
                    }
                  ]
                })(<Input placeholder={intl.formatMessage({ id: 'ModalContactPlaceholderName', defaultMessage: 'Enter Name' })} autoComplete="off"></Input>)}
              </Form.Item>
            </Col>
            <Col span={4}>
              <span>Unit : </span>
            </Col>
            <Col span={8}>
              <Form.Item>
                {getFieldDecorator("unit", {
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'ModalContactValidateName', defaultMessage: 'Please Enter Name' })
                    }
                  ]
                })(<Input placeholder={intl.formatMessage({ id: 'ModalContactPlaceholderName', defaultMessage: 'Enter Name' })} autoComplete="off"></Input>)}
              </Form.Item>
            </Col>
          </Row>

        </div>
        <Divider type='horizontal' />
        <div className='padding-div-bottom'>
          <p>Description</p>
          <Form.Item>
            {getFieldDecorator("descriptions", {
            })(<TextArea
              autosize={{ minRows: 3, maxRows: 3 }}
            />)}
          </Form.Item>
        </div>
      </Form>
    </Modal>
  )
}

const Item = Form.create({
  name: "modal_form",
  mapPropsToFields(props) {

  }
})(addItem);

export default Item;
