import Keycloak from 'keycloak-js'
 
// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'
const keycloak = new Keycloak({ 
  url: `${process.env.REACT_APP_KEYCLOAK_URL}/auth`, 
  realm: 'master', 
  clientId: `${process.env.REACT_APP_CLIENT_KEYCLOAK_WEB}`
});

export default keycloak;