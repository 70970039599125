import React from 'react';

const LabeRequireForm = (props) => {
  const { text, req, bold } = props;
  return (
    <span style={{fontWeight: bold === true ? 'bold' : undefined}}>
      {text}&nbsp;
      {req ? <span style={{ fontSize: '13px', color: '#ff0000'  }}>*</span> : ''}
    </span>
  );
};

export default LabeRequireForm;