import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { PageSettings, PageOrganizationTabShift } from '../../../config/page-settings';
import Button01 from '../../../components/v2/button_01';

export default (props) => {
  const { checkApprover, shiftPlanningDisabled } = props
  return (
    <PageSettings.Consumer>
      {({ checkPermissionAction }) => (
        checkPermissionAction('P3PG1C7', 'P3PG1C7A4') && checkApprover? (
          <PageOrganizationTabShift.Consumer>
            {({ handleClickShiftPlanning }) => (
              <Button01
                type='primary'
                fontsize='sm'
                btnsize='wd_at'
                onClick={handleClickShiftPlanning}
                disabled={shiftPlanningDisabled}
              >
                <FormattedMessage id='orgShiftPlanningLblShift' defaultMessage='Shift Planning' />
              </Button01>
            )}
          </PageOrganizationTabShift.Consumer>
        ) : null
      )}
    </PageSettings.Consumer>
  );
}