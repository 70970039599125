import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Table } from 'antd';
import styles from './css/index.css';
import styled from 'styled-components';

const ItemMasterTable = (props) => {
  const { loading, dataSource, columns, total, paginationPage, fnc } = props;

  const showTotal = (total) => {
    return (
      <span style={{ fontSize: '13px' }}>
        <FormattedMessage id="lblRowPerPage" defaultMessage="Rows per page: " />
      </span>
    );
  };

  const TotalItem = styled.div`
    display: flex;
    position: relative;
    margin-top: -42px;
    margin-left: 10px;
  `;
  return (
    <div className="maintenance-report">
      <Table
        className="maintenance-report-table"
        loading={loading}
        rowKey={(record) => record.maintenanceId}
        dataSource={dataSource}
        columns={columns}
        onChange={fnc.handleChange}
        scroll={{ x: 2200, y: `calc(100vh - 370px)` }}
        pagination={{
          total: total,
          showTotal: showTotal,
          current: paginationPage,
          defaultCurrent: 1,
          pageSizeOptions: ['10', '20', '30', '40', '50'],
          showSizeChanger: true,
          locale: { items_per_page: '' },
          onChange: fnc.handlePagination,
          onShowSizeChange: fnc.handleSizeChange,
        }}
      />
      {dataSource && dataSource.length !== 0 ? (
        <TotalItem>
          <span style={{ fontSize: '13px' }}>
            <FormattedMessage id="lblTotal" defaultMessage="Total" />
            {` ${total || 0} `}
            <FormattedMessage id="lblitems" defaultMessage="items" />
          </span>
        </TotalItem>
      ) : null}
    </div>
  );
};

export default ItemMasterTable;
