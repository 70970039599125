import httpClient from '../../components/axiosClient';

const getTravelExpenses = async (tab, fromDate, toDate, fieldChang, fliterByColumn, paginationPage, paginationSize, orgId, checkedAll ,type) => {
  const comCode = localStorage.getItem('comCode');

  const body = {
    orgId: orgId ? orgId : undefined,
    members: checkedAll,
    search: [{
      key: fliterByColumn,
      value: fieldChang,
    }],
    limit: paginationSize,
    paging: fieldChang && fieldChang !== "" ? 1 : paginationPage,
    startDate: fromDate,
    endDate: toDate,
  }



  try {
    const response = await httpClient.post(`/v3/task/manager/company/${comCode}/search/travel-exprenses/${tab}?${type}`, body);
    if (response.status === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
}

export default getTravelExpenses;