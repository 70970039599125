import React from 'react';
import _ from 'lodash';
import { Card, Input, Icon, Tree } from 'antd';
import { PageTaskDistance } from '../../config/page-settings';
import { useIntl } from 'react-intl';

const { TreeNode, DirectoryTree } = Tree;

export default () => {
  const intl = useIntl()
  const setTreeNode = (item, searchValue) => {
    const fromNameLowcase = _.toLower(item.fromName);
    const searchValueLowcase = _.toLower(searchValue);
    const index = fromNameLowcase.indexOf(searchValueLowcase);
    const beforeStr = item.fromName.substr(0, index);
    const centerStr = item.fromName.substr(index, searchValue.length);
    const afterStr = item.fromName.substr(index + searchValue.length);
    const title =
      index > -1 && searchValue ? (
        <span title={item.fromName}>
          {beforeStr}
          <span style={{ color: '#f50' }}>{centerStr}</span>
          {afterStr}
        </span>
      ) : (
        <span title={item.fromName}>{_.truncate(item.fromName, { length: 36 })}</span>
      );
    return (<TreeNode title={title} key={`${item.fromId}`}></TreeNode>);
  }

  return (
    <PageTaskDistance.Consumer>
      {({ fromLocationListSearch, fromLocationSearch, handleChangeSearch, handleChangeSelect }) => (
        <Card 
          title={intl.formatMessage({ id: `taskDistanceFromLocationText`, defaultMessage: 'From Location' })}
          style={{
            height: 'calc(100vh - 112px)'
          }}
          bodyStyle={{
            padding: 0
          }}
        >

          <div style={{ padding: '24px 30px 10px 30px' }}>
            <Input
              allowClear
              placeholder={intl.formatMessage({ id: `hintSearch`, defaultMessage: 'Search' })}
              prefix={<Icon type='search' style={{ color: 'rgba(0,0,0,.25)' }} />}
              onChange={(e) => handleChangeSearch(e.target.value)}
            />
          </div>

          <div className='scroll-sm' style={{ maxHeight: 'calc(100vh - 240px)', overflowX: 'hidden', overflowY: 'auto' }}>
            <DirectoryTree showIcon={false} onSelect={(keys) => handleChangeSelect(keys)}>
              {fromLocationListSearch && fromLocationListSearch.map((item) => setTreeNode(item, fromLocationSearch))}
            </DirectoryTree>
          </div>

        </Card>
      )}
    </PageTaskDistance.Consumer>
  );
}