import React, { useState, useEffect, useRef, useContext } from 'react';
import style from '../css/index.css';
import { useIntl, FormattedMessage } from 'react-intl';
import moment from 'moment';
import _ from 'lodash';
import { closest, addClass } from '@syncfusion/ej2-base';
import { TreeViewComponent } from '@syncfusion/ej2-react-navigations';
import { Divider, Badge, Icon, Collapse, DatePicker, Input, Modal, Row, Col } from 'antd';
import SettingSvg from '../../../../components/image/settings.svg';
import { successNotification, errorNotification } from '../../../transportation/gantt-view/notification';
import httpClient from '../../../../components/axiosClient';
import { v4 as uuidv4 } from 'uuid';

const { Panel } = Collapse;

export default (props) => {
  const { scheduleObjNew, taskPoolData, setTriggerTaskPool, setMainTaskId, setVisibleTaskDetailModal, LangCode, selectGroupBy, resultCheckTaskNo } = props;
  const intl = useIntl();
  const comId = localStorage.getItem('comId');
  const memId = localStorage.getItem('memId');
  const treeObj = useRef();

  const [searchTerm, setSearchTerm] = useState(null);
  const [filterDateStart, setFilterDateStart] = useState(null);
  const [filterDateEnd, setFilterDateEnd] = useState(null);
  const [filterResult, setFilterResult] = useState(0);
  const [isTreeItemDropped, setIsTreeItemDropped] = useState(false);
  const [draggedItemId, setDraggedItemId] = useState('');
  const [keyTab, setKeyTab] = useState();
  const [searchResults, setSearchResults] = useState({
    dataSource: [],
    id: 'Id',
    text: 'Name',
  });
  // let scheduleObjNew = _.get(props, 'scheduleObjNew');

  useEffect(() => {
    if (treeObj.current !== undefined && treeObj.current.dragObj.dragArea !== null) {
      onFilter(searchTerm, filterDateStart, filterDateEnd);
    }
  }, [taskPoolData, keyTab, filterDateStart, filterDateEnd, searchTerm]);

  const callback = (key) => {
    setKeyTab(key);
  };

  const onCreate = () => {
    treeObj.current.dragObj.dragArea = 'body';
  };

  const onFilter = (keyword, dateStart, dateEnd) => {
    let startDate = dateStart;
    let endDate = dateEnd;

    if (!startDate && endDate) {
      startDate = endDate;
    }

    if (startDate && !endDate) {
      endDate = startDate;
    }
    const excludeColumns = [
      'Id',
      'Color',
      'EndDateView',
      'EndTimeView',
      'Index',
      'StartDateView',
      'StartTimeView',
      'Planfinish',
      'Planstart',
      'StatusName',
      'ToAddress',
      'FromAddress',
      'Address',
      'TaskTypeName',
    ];

    const filterFirstDay = moment(startDate, 'YYYY/MM/DD').startOf('day').format('X');
    const filterLastDay = moment(endDate, 'YYYY/MM/DD').endOf('day').format('X');
    const taskPoolDataSearch =
      taskPoolData &&
      taskPoolData.filter((item) => {
        return (
          // (
          //   ((item.Planstart >= filterFirstDay && !dateEnd)  || (!dateStart || item.Planfinish <= filterLastDay))
          //   // || (item.Planfinish >= filterFirstDay || item.Planfinish <= filterLastDay)
          //   // || (item.Planstart <= filterFirstDay || item.Planfinish >= filterLastDay)
          //   || (!dateStart && !dateEnd)
          // )
          ((item.Planstart >= filterFirstDay && item.Planstart <= filterLastDay) ||
            (item.Planfinish >= filterFirstDay && item.Planfinish <= filterLastDay) ||
            (item.Planstart <= filterFirstDay && item.Planfinish >= filterLastDay) ||
            (!startDate && !endDate)) &&
          Object.keys(item).some((key) =>
            excludeColumns.includes(key)
              ? false
              : item[key]
                .toString()
                .toLowerCase()
                .includes(keyword ? keyword : '')
          )
        );
      });

    treeObj.current.fields = {
      dataSource: taskPoolDataSearch,
      id: 'Id',
      text: 'Name',
    };

    setFilterDateStart(dateStart);
    setFilterDateEnd(dateEnd);
    setSearchTerm(keyword);
    setFilterResult(_.size(taskPoolDataSearch));
  };

  const onItemDrag = (event) => {
    if (scheduleObjNew.current.isAdaptive) {
      let classElement = scheduleObjNew.current.element.querySelector('.e-device-hover');
      if (classElement) {
        classElement.classList.remove('e-device-hover');
      }
      if (event.target.classList.contains('e-work-cells')) {
        addClass([event.target], 'e-device-hover');
      }
    }
    if (document.body.style.cursor === 'not-allowed') {
      document.body.style.cursor = '';
    }
    if (event.name === 'nodeDragging') {
      let dragElementIcon = document.querySelectorAll('.e-drag-item.treeview-external-drag .e-icon-expandable');
      for (let i = 0; i < dragElementIcon.length; i++) {
        dragElementIcon[i].style.display = 'none';
      }
    }
  };

  const onTreeDragStop = (event) => {
    const taskCurrent = scheduleObjNew.current.getEvents();

    let treeElement = closest(event.target, '.e-treeview');
    let classElement = scheduleObjNew.current.element.querySelector('.e-device-hover');

    if (classElement) {
      classElement.classList.remove('e-device-hover');
    }

    if (!treeElement) {
      event.cancel = true;
      let scheduleElement = closest(event.target, '.e-content-wrap');

      if (scheduleElement) {
        let treeviewData = treeObj.current.treeData;

        if (event.target.classList.contains('e-work-cells')) {
          const filteredData = treeviewData.filter((item) => item.Id === event.draggedNodeData.id);
          let cellData = scheduleObjNew.current.getCellDetails(event.target);
          let resourceDetails = scheduleObjNew.current.getResourcesByIndex(cellData.groupIndex);
          setIsTreeItemDropped(true);
          setDraggedItemId(event.draggedNodeData.id);

          Modal.confirm({
            className: 'task-pool-confirm-assign-task',
            title: intl.formatMessage({
              id: `monitorTabTaskPoolModalAssignTitle${LangCode}`,
              defaultMessage: 'Are you sure to confirm assign task ?',
            }),
            centered: true,
            icon: null,
            okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
            cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
            okButtonProps: {
              style: {
                width: '96px',
                background: '#6490cf',
                border: '1px solid #6490cf',
                borderRadius: '8px',
                hover: {
                  background: '#ffffff',
                  color: '#6490cf',
                },
              },
              type: 'primary',
            },
            cancelButtonProps: {
              style: {
                width: '96px',
                border: '1px solid #6490cf',
                borderRadius: '8px',
                backgroundColor: '#ffffff',
                color: '#6490CF',
                
              },
              type: 'primary',
            },
            async onOk() {
              let resourceId = _.get(resourceDetails, 'groupData.ResourceId');
              let resourceMemOrgId = _.get(resourceDetails, 'resourceData.memOrgId');
              let resourceOrgId = _.get(resourceDetails, 'groupData.ResourceOrgId');
              let startTime = moment(cellData.startTime).unix();
              let endTime = moment(cellData.endTime).unix();
              let groupTeam = _.get(resourceDetails, 'groupData.OrgId');
              let taskId = filteredData[0].Id;

              try {
                const body =
                  selectGroupBy === 'team'
                    ? {
                      memComId: resourceId === 999999 ? null : resourceId.toString(),
                      planStart: startTime,
                      planFinish: endTime,
                      orgId: groupTeam.toString(),
                      memId: Number(memId),
                      version: '2',
                      memOrgId: resourceOrgId ? resourceOrgId : resourceMemOrgId.toString(),
                    }
                    : {
                      memComId: _.get(resourceDetails, 'resourceData.memComId').toString(),
                      planStart: startTime,
                      planFinish: endTime,
                      orgId: _.get(resourceDetails, 'resourceData.OrgId').toString(),
                      memId: Number(memId),
                      version: '2',
                      memOrgId: _.get(resourceDetails, 'resourceData.resourceOrgId').toString(),
                    };

                const response = await httpClient.put(
                  `/v3/task/manager/company/${comId}/monitor/task/${taskId}/reassign/taskpool?menuType=transportation`,
                  body
                );
                if (response.status === 200) {
                  successNotification(response.data.status.message);
                  setTriggerTaskPool((current) => !current);
                  const newEventData = {
                    OrgId: parseInt(response.data.data.orgId),
                    orgIdOld: parseInt(response.data.data.orgId),
                    ResourceId: parseInt(response.data.data.memComId),
                    memComOldId: parseInt(response.data.data.memComId),
                    StartDate: response.data.data.planStart,
                    DueDate: response.data.data.planFinish,
                    StartTime: response.data.data.startDatetime,
                    EndTime: response.data.data.dueDatetime,
                    Color: response.data.data.statusGroup[0].statusGroupColor,
                    CustomerName: response.data.data.customer,
                    // Id: response.data.data.taskId,
                    Id: uuidv4(),
                    TaskId: response.data.data.taskId,
                    TaskNo: response.data.data.taskNo,
                    Subject: response.data.data.taskTypeName,
                    Status: response.data.data.statusGroup[0].statusGroupName,
                    ToAddress: response.data.data.toLocation,
                    AssigneeName: response.data.data.AssigneeName,
                    Reference: response.data.data.reference_1,
                    Remark: response.data.data.remark,
                    TaskName: response.data.data.taskName,
                    PriorityName: response.data.data.priorityName,
                    ContactName: response.data.data.contact[0].contact_name,
                    ContactPhone: response.data.data.contact[0].contact_phone,
                    ResourceOrgId: response.data.data.memOrgId,
                    ResourceOrgIdOld: response.data.data.memOrgId,
                    CustomerId: response.data.data.customerId,
                    TagTask: resultCheckTaskNo
                      ? `${response.data.data.taskNo +
                      ' ' +
                      `<span style='width: 5px; height: 5px; background-color: #ffffff; border-radius: 50%; display: inline-flex; margin: 0 4px 2px 3px;'></span>` +
                      response.data.data.taskName
                      } `
                      : response.data.data.taskName,
                  };

                  scheduleObjNew.current.addEvent([newEventData]);
                  // setStateMain.setRefreshApiGanttView(!stateMain.refreshApiGanttView);
                  // setStateMain.setMainTaskId(null);
                } else {
                  errorNotification(response.data.status.message);
                }
              } catch (error) {
                if (error.response.status === 400) {
                  errorNotification(error.response.data.status.message);
                }
                if (error.response.status === 500) {
                  errorNotification(error.response.data.error);
                }
              }
            },
          });
        }
      }
    }
  };

  const fields = {
    dataSource: taskPoolData,
    id: 'Id',
    text: 'Name',
  };

  const onSelectDateStart = (e) => {
    const date = e ? e.format('YYYY/MM/DD') : null;
    setFilterDateStart(date);
    // onFilter(searchTerm, date);
  };

  const onSelectDateEnd = (e) => {
    const date = e ? e.format('YYYY/MM/DD') : null;
    setFilterDateEnd(date);
    // onFilter(searchTerm, date);
  };

  const onSearchKeyword = (e) => {
    const lowercasedValue = e.target.value.toLowerCase();
    setSearchTerm(lowercasedValue);
    // const date = filterDate ? filterDate : null;
    // onFilter(lowercasedValue, date);
  };

  const treeTemplate = (props, index) => {
    return (
      <div id="waiting">
        <div id="waitdetails">
          <div id="waitcategory">
            <div className="over-text-long-task-pool">
              <span className="task-pool-text-no-p-taskNo">
                {props.TaskNo} · {props.TaskName}
              </span>
            </div>
            <div className="over-text-long">
              <span class="task-pool-text-taskname">{props.OrgName}</span>
            </div>
            {props.StartDateView === 'Invalid date' && props.EndDateView === 'Invalid date' ? null : (
              <div>
                <div class="task-pool-text-date-time">
                  <span class="task-pool-text-date">{props.StartDateView}</span>
                  <span class="task-pool-text-time">{props.StartTimeView}</span>
                </div>
                <div class="task-pool-text-date-time">
                  <span class="task-pool-text-date">{props.EndDateView}</span>
                  <span class="task-pool-text-time">{props.EndTimeView}</span>
                </div>
              </div>
            )}
            {props.ReferenceDefault ? (
              <div className="over-text-long">
                <span class="task-pool-text-taskname">Ref. {props.ReferenceDefault}</span>
              </div>
            ) : null}
            {/* <div className='space-taskno-org'>
              <span class="task-pool-text-taskname">{props.TaskNo}</span>
              <span class="task-pool-text-team">{props.OrgName}</span>
            </div> */}
            <Divider className="task-pool-divider" />
          </div>
        </div>
      </div>
    );
  };

  const extraIcon = (res) => {
    return (
      <div style={{ display: 'flex', padding: '0px 0px 0px 24px', marginTop: '-16px', marginRight: '8px' }}>
        <Badge
          className="dot-filter"
          dot={res.dataSource && res.dataSource.length >= 0 && (searchTerm || filterDateStart || filterDateEnd) ? true : false}
        >
          <Icon component={filterSvg} />
        </Badge>
      </div>
    );
  };

  const filterSvg = () => <img className="filterSvg" src={SettingSvg} />;
  const headerTitle = (res) => {
    return (
      <div style={{ display: 'flex', padding: '0px 0px 0px 14px', marginTop: '2px' }}>
        <span className="color-filter">
          <FormattedMessage id={`monitorTabFilterTitle${LangCode}`} defaultMessage="Filter" />
        </span>
        &nbsp;{' '}
        {res.dataSource && res.dataSource.length >= 0 && (searchTerm || filterDateStart || filterDateEnd) ? (
          <span className="text-result-search">
            (<FormattedMessage id={`monitorTaskPoolTextFilterResult${LangCode}`} defaultMessage="Result" />: {filterResult})
          </span>
        ) : null}
      </div>
    );
  };

  const handleOnClickTaskId = (args) => {
    let getNodeDetails = treeObj.current.getNode(args.node);
    if (getNodeDetails.id) {
      setMainTaskId(getNodeDetails.id);
      setTimeout(() => {
        setVisibleTaskDetailModal(true);
      }, 500);
    }
  };

  return (
    <div className="all-object">
      <div>
        <Collapse
          defaultActiveKey={[]}
          // onChange={callback}
          expandIconPosition={false}
          className="header-style"
        >
          <Panel
            key="1"
            className="header-style-panel"
            header={headerTitle(searchResults)}
            showArrow={false}
            extra={extraIcon(searchResults)}
          >
            <br />
            <div className="task-pool-div-input">
              <p style={{ marginBottom: '8px' }}>
                <FormattedMessage
                  id={`monitorTabFilterTaskPoolTask${LangCode}`}
                  defaultMessage="Task No.,Task Name, Customer or Reference"
                />
              </p>
              <Input
                className="task-pool-input"
                placeholder={intl.formatMessage({
                  id: `hintSearchTaskPool${LangCode}`,
                  defaultMessage: 'Search Task No.,Task Name, Customer or Reference',
                })}
                prefix={<Icon style={{ color: 'rgba(0,0,0,.25)' }} type="search" />}
                onChange={onSearchKeyword}
                autoComplete="off"
              />
              <p style={{ marginBottom: '8px' }}>
                <FormattedMessage id={`monitorTabFilterTaskPoolStartDate${LangCode}`} defaultMessage="Start Date" />
              </p>
              <DatePicker
                className="task-pool-date-new"
                allowClear={true}
                format="ddd, MMM DD, YYYY"
                placeholder={intl.formatMessage({
                  id: `hintSearchTaskPoolStartDate${LangCode}`,
                  defaultMessage: 'Select Start Date',
                })}
                onChange={onSelectDateStart}
              />
              <p style={{ marginBottom: '8px' }}>
                <FormattedMessage id={`monitorTabFilterTaskPoolDueDate${LangCode}`} defaultMessage="Due Date" />
              </p>
              <DatePicker
                className="task-pool-date-new"
                allowClear={true}
                format="ddd, MMM DD, YYYY"
                placeholder={intl.formatMessage({
                  id: `hintSearchTaskPoolDueDate${LangCode}`,
                  defaultMessage: 'Select Due Date',
                })}
                onChange={onSelectDateEnd}
              />
            </div>
          </Panel>
        </Collapse>
      </div>
      <div className="left-tabs-manu-monitor-task-pool">
        <TreeViewComponent
          ref={treeObj}
          cssClass="treeview-external-drag-new"
          nodeTemplate={treeTemplate}
          fields={fields}
          nodeDragStop={onTreeDragStop}
          nodeDragging={onItemDrag}
          nodeClicked={handleOnClickTaskId}
          allowDragAndDrop={true}
          created={onCreate}
        />
      </div>
      <Row className="row-center-text">
        <Col span={2} />
        <Col span={18}>
          <div className="div-text-center">
            <p className="text-drag">
              <Icon type="info-circle" />
              <FormattedMessage id={`monitorTaskPoolTextFilter1${LangCode}`} defaultMessage=" You can schedule and assign tasks " />{' '}
            </p>
            <p className="text-drag2">
              <FormattedMessage id={`monitorTaskPoolTextFilter2${LangCode}`} defaultMessage="from Task Pool to Gantt Chart with" />
            </p>
            <p className="text-drag2">
              <FormattedMessage id={`monitorTaskPoolTextFilter3${LangCode}`} defaultMessage="drag and drop function." />
            </p>
          </div>
        </Col>
        <Col span={2} />
      </Row>
    </div>
  );
};
