import httpClient from '../../../components/axiosClient';

const getItemListLoadMore = async (body) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v1/warehouse/manager/company/${comCode}/web/warehousemanager/itemlist`, body)
    if (response.status === 200) {

      return response
    } else {
      return []
    }
  } catch (error) {
  }
}

const getReportItemMovement = async (body) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v1/report/manager/company/${comCode}/item-movement-report`, body)
    if (response.status === 200) {

      return response
    } else {
      return []
    }
  } catch (error) {
  }
}

const exportReportItemMovement = async (body) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v1/report/manager/company/${comCode}/item-movement-report-excel`, body)
    if (response.status === 200) {

      return response
    } else {
      return []
    }
  } catch (error) {
  }
}

const getReportItemPurchase = async (body) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v1/report/manager/company/${comCode}/item-purchase-report`, body)
    if (response.status === 200) {

      return response
    } else {
      return []
    }
  } catch (error) {
  }
}

const exportReportItemPurchase = async (body) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v1/report/manager/company/${comCode}/item-purchase-report-excel`, body)
    if (response.status === 200) {

      return response
    } else {
      return []
    }
  } catch (error) {
  }
}

const getMemberIssueBy = async (payload) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v3/item/manager/${comCode}/getlistmemberloadmore`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export { getItemListLoadMore, getReportItemMovement, exportReportItemMovement, getReportItemPurchase, exportReportItemPurchase, getMemberIssueBy};