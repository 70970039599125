import { Button, Col, Icon, Input, Modal, Row, Select, Tag } from 'antd';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Button_01 from '../v2/button_01';
import { messageLabel } from '../message-component';
import CustomTableComponent from './components/table';
import ModalItemForm from './modal-item';
import { getItemBasePay, updateItemBasePay } from '../../controllers/base-pay/basepay';
import { useAppContext } from '../../includes/indexProvider';
import { errorNotification, successNotification } from '../v2/notification';
import Button_02 from '../v2/button_02';
import { PageSettings } from '../../config/page-settings';

const { Option } = Select;

const ModalSelectItemPayDeduct = ({ visible, setVisble, menuCode, setItem, onSelectItem, setTrigger, onCheckItem,reApi }) => {
  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
      fixed: 'left',
      render: (text, record, index) => record.index,
    },
    {
      title: messageLabel('modalSelectItemColumnItemName', 'Item Name'),
      dataIndex: 'basePayName',
      key: 'basePayName',
      sorter: true,
      width: 250,
      ellipsis: true,
      fixed: 'left',
    },
    {
      title: messageLabel('modalSelectItemColumnType', 'Type'),
      dataIndex: 'typeCode',
      key: 'typeCode',
      sorter: true,
      width: 150,
    },

    {
      title: messageLabel('modalSelectItemColumnStatus', 'Status'),
      dataIndex: 'status',
      key: 'status',
      // sorter: true,
      width: 150,
      render: (text, record, index) => (
        <div className="tag-center-div">
          <Tag style={{ fontSize: '14px' }} className="tag-center-style" color={_.get(record, 'statusColor')}>
            {_.get(record, 'statusName')}
          </Tag>
        </div>
      ),
    },
    {
      title: messageLabel('modalSelectItemColumnCreatedby', 'Created By'),
      dataIndex: 'createdBy',
      key: 'createdBy',
      width: 200,
      sorter: true,
    },
    {
      title: messageLabel('modalSelectItemColumnCreatedDate', 'Created Date'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: true,
      width: 200,
    },
    {
      title: messageLabel('modalSelectItemColumnDeletedBy', 'Deleted By'),
      dataIndex: 'deletedBy',
      key: 'deletedBy',
      width: 200,
      sorter: true,
      render: (text, record, index) => (text ? text : '-'),
    },
    {
      title: messageLabel('modalSelectItemColumnDeletedDate', 'Deleted Date'),
      dataIndex: 'deletedDate',
      key: 'deletedDate',
      sorter: true,
      width: 200,
      render: (text, record, index) => (text ? text : '-'),
    },
  ];
  const ignoreColumn = menuCode === 'basePay' ? ['typeCode'] : [];
  const newColumns = columns.filter((col) => !ignoreColumn.includes(col.key));

  const [pageTable, setPageTable] = useState(1);
  const [sizeTable, setSizeTable] = useState(10);
  const [sortFieldTable, setSortFieldTable] = useState('');
  const [sortOrderTable, setSortOrderTable] = useState('');
  const [toggleFilter, setToggleFilter] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [total, setTotal] = useState(0);
  const [listStatus, setListStatus] = useState([]);
  const [listType, setListType] = useState([]);
  const [filter, setFilter] = useState({ status: 'active' });
  const [record, setRecord] = useState({});
  const [isModal, setIsModal] = useState(false);
  const intl = useIntl();
  const app = useAppContext();
  const langValue = app.state.langValue;

  console.log("datasource", dataSource)

  useEffect(() => {
    setTrigger((event) => !event);
    setFilter({ status: 'active' });
    setPageTable(1);
    setSizeTable(10);
  }, [visible]);

  useEffect(() => {
    const getAPI = async () => {
      const body = {
        basePayName: _.get(filter, 'itemName') || undefined,
        noType: menuCode === 'basePay' ? 'pay' : _.get(filter, 'type') || undefined,
        status: _.get(filter, 'status') || undefined,
        limit: sizeTable,
        page: pageTable,
        orderBy: sortFieldTable,
        orderType: sortOrderTable,
      };

      const response = await getItemBasePay(body);
      if (_.get(response, 'data.status.code') === 200) {
        setDataSource(response.data.data.basePayList);
        setTotal(response.data.totalItem);
        setListStatus(response.data.basePayStatus);
        setListType(response.data.basePayType);
      }
    };
    getAPI();
  }, [visible, toggleFilter]);

  const handleToggleFilter = () => {
    setToggleFilter((event) => !event);
    setPageTable(1);
  };

  const handleChangeTable = (pagination, sorter, extra) => {
    let field = extra.field;
    if (extra.order) {
      if (extra.order === 'descend') {
        setSortOrderTable('desc');
      } else {
        setSortOrderTable('asc');
      }
    } else {
      setSortOrderTable('');
    }
    setSortFieldTable(field);
    setPageTable(pagination.current);
    setSizeTable(pagination.pageSize);
    setToggleFilter((event) => !event);
  };

  const handleChangeFilter = async (value, code) => {
    setFilter({ ...filter, [code]: value });
    // setPage(1);
  };

  const handleSelect = (record) => {
    if (setItem) setItem(record);
    if (onSelectItem) onSelectItem(_.get(record, 'basePayId'));
    setVisble(false);
  };

  const handleOpenModalAdd = (record) => {
    setRecord(record ? record : {});
    setIsModal(true);
  };

  const contentAction = (record) => {
    return _.get(record, 'statusCode') === 'active' ? (
      <div style={{ display: 'grid' }}>
        <div>
          <Button style={{ width: 100 }} type="link" ghost onClick={() => handleSelect(record)}>
            <FormattedMessage id="btnSelect" defaultMessage="Select" />
          </Button>
        </div>

        <PageSettings.Consumer>
          {({ checkPermissionAction }) => (
            <div>
              {checkPermissionAction('P2S1PG2C16', 'P2S1PG2C16A6') || checkPermissionAction('P81PG1C2', 'P81PG1C2A3') ? (
                <Button
                  style={{ width: 100 }}
                  type="link"
                  ghost
                  onClick={() => {
                    handleOpenModalAdd(record);
                    // setEditData(record);
                  }}
                >
                  <FormattedMessage id="btnEdit" defaultMessage="Edit" />
                </Button>
              ) : null}
            </div>
          )}
        </PageSettings.Consumer>

        <PageSettings.Consumer>
          {({ checkPermissionAction }) => (
            <div>
              {checkPermissionAction('P2S1PG2C16', 'P2S1PG2C16A7') || checkPermissionAction('P81PG1C2', 'P81PG1C2A4') ? (
                <Button style={{ width: 100 }} type="link" ghost onClick={() => handleDelete(record)}>
                  <FormattedMessage id="btnDelete" defaultMessage="Delete" />
                </Button>
              ) : null}
            </div>
          )}
        </PageSettings.Consumer>
      </div>
    ) : null;
  };

  const handleDelete = (record) => {
    Modal.confirm({
      className: 'maintenance-report-modal-confirm',
      centered: true,
      icon: null,
      title: intl.formatMessage({ id: 'lblDelete', defaultMessage: 'Are you sure you want to delete ?' }),
      okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        const payload = {
          status: 'inactive',
        };
        try {
          const response = await updateItemBasePay(payload, _.get(record, 'basePayId'));
          if (_.get(response, 'data.status.code') === 200) {
            successNotification(response.data.status.message);
            if (_.isFunction(reApi)) {
              reApi();
            }
            setToggleFilter((event) => !event);
            setTrigger((event) => !event);
          }
        } catch (error) {
          errorNotification(error.response.data.data.message);
        }
      },
      onCancel() { },
    });
  };

  const handleCancel = () => {
    setVisble(false);
    if (_.isFunction(onCheckItem)) {
      onCheckItem();
    }
    
  };

  return (
    <Modal
      title={intl.formatMessage({ id: 'modalSelectItemTitleItem', defaultMessage: 'Item' })}
      visible={visible}
      onOk={() => setVisble(false)}
      onCancel={() => handleCancel()}
      width={1122}
      zIndex={1000}
      centered={true}
      bodyStyle={{ padding: 'unset', height: '645px' }}
      className="modalBrandAndModel"
      footer={[
        <Button_02 key="close" btnsize="wd_df" onClick={() => handleCancel()}>
          <FormattedMessage id="btnCloseItem" defaultMessage="Close" />
        </Button_02>,
      ]}
    >
      <Row>
        <Col span={6} style={{ borderRight: '1px solid #e8e8e8' }}>
          <div style={{ height: '600px' }}>
            <div
              className="listItemTextHeader"
              style={{
                padding: '24px 16px 16px 0px',
                marginTop: '-5px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <span style={{ width: '50%', marginLeft: '24px' }}>
                <FormattedMessage id="modalSelectItemTitleFilter" defaultMessage="Filter" />
              </span>
            </div>
            <div style={{ padding: '16px 16px 8px 0px', marginLeft: '15px' }}>
              <div style={{ marginBottom: '16px' }}>
                <div className="" style={{ marginBottom: '8px', color: '#1D3557' }}>
                  <FormattedMessage id="modalSelectItemLabelItemName" defaultMessage="Item Name" />
                </div>
                <div>
                  <Input
                    allowClear={true}
                    onChange={(e) => handleChangeFilter(e.target.value, 'itemName')}
                    value={_.get(filter, 'itemName')}
                    placeholder={intl.formatMessage({
                      id: 'modalSelectItemPHRefItemName',
                      defaultMessage: 'Enter Item Name',
                    })}
                  />
                </div>
              </div>

              {menuCode !== 'basePay' ? (
                <div style={{ marginBottom: '16px' }}>
                  <div className="" style={{ marginBottom: '8px', color: '#1D3557' }}>
                    <FormattedMessage id="modalSelectItemLabelType" defaultMessage="Type" />
                  </div>
                  <Select
                    allowClear
                    showSearch
                    onChange={(value) => handleChangeFilter(value, 'type')}
                    filterOption={false}
                    value={_.get(filter, 'type')}
                    placeholder={intl.formatMessage({ id: 'modalSelectItemPHRefType', defaultMessage: 'Select Type' })}
                    style={{ width: '100%' }}
                  >
                    {_.map(listType, (item) => (
                      <Option value={item.code}>{langValue === 'TH' ? item.lang.TH : item.lang.EN}</Option>
                    ))}
                  </Select>
                </div>
              ) : null}

              <div style={{ marginBottom: '16px' }}>
                <div className="" style={{ marginBottom: '8px', color: '#1D3557' }}>
                  <FormattedMessage id="modalSelectItemLabelStatus" defaultMessage="Status" />
                </div>
                <Select
                  allowClear
                  showSearch
                  onChange={(value) => handleChangeFilter(value, 'status')}
                  filterOption={false}
                  value={_.get(filter, 'status')}
                  placeholder={intl.formatMessage({ id: 'modalSelectItemPHRefStatus', defaultMessage: 'Select Status' })}
                  style={{ width: '100%' }}
                >
                  {_.map(listStatus, (item) => (
                    <Option value={item.code}>{langValue === 'TH' ? item.lang.TH : item.lang.EN}</Option>
                  ))}
                </Select>
              </div>
            </div>
          </div>

          <div style={{ height: '50px' }}>
            <div style={{ padding: '0px 16px 10px 24px' }}>
              <Button_01
                style={{ margin: '0px', width: '100%' }}
                key="print"
                type="primary"
                btnsize="wd_df"
                onClick={() => handleToggleFilter()}
              >
                <FormattedMessage id="btnApplyFilter" defaultMessage="Apply" />
              </Button_01>
            </div>
          </div>
        </Col>

        {/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}

        <Col span={18}>
          <div
            className="listItemTextHeader"
            style={{
              padding: '14px 24px 15px 24px',
              marginTop: '-5px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <span>
              <FormattedMessage id="modalSelectItemTitleItem" defaultMessage="Item" />
            </span>

            <PageSettings.Consumer>
              {({ checkPermissionAction }) => (
                <div>
                  {checkPermissionAction('P2S1PG2C16', 'P2S1PG2C16A5') || checkPermissionAction('P81PG1C2', 'P81PG1C2A2') ? (
                    <Button_01
                      style={{ marginRight: '-1px' }}
                      key="close"
                      type="primary"
                      btnsize="wd_df"
                      onClick={() => handleOpenModalAdd()}
                    >
                      <FormattedMessage id="btnCreate" defaultMessage="Create" />
                    </Button_01>
                  ) : null}
                </div>
              )}
            </PageSettings.Consumer>
          </div>
          <div
            style={{
              padding: '16px',
            }}
          >
            <CustomTableComponent
              columns={newColumns}
              dataSource={dataSource}
              total={total}
              onRow={true}
              role={true}
              onChange={handleChangeTable}
              paginationPage={pageTable}
              // setPaginationPage={setPageTable}
              paginationShow={true}
              // setPaginationSize={setSizeTable}
              paginationSize={sizeTable}
              scroll={{ x: _.sumBy(newColumns, 'width'), y: '450px' }}
              // 'calc(72vh - 222px)'
              contentAction={contentAction}
              // setFieldSort={setSortFieldTable}
              // setOrderSort={setSortOrderTable}
            />
          </div>
        </Col>
      </Row>

      <ModalItemForm
        title={
          _.size(record)
            ? intl.formatMessage({ id: 'modalSelectItemTitleItemEdit', defaultMessage: 'Edit Item' })
            : intl.formatMessage({ id: 'modalSelectItemTitleItemAdd', defaultMessage: 'Add Item' })
        }
        visible={isModal}
        setVisible={setIsModal}
        setToggle={setToggleFilter}
        record={record}
        menuCode={menuCode}
        listType={listType}
        reApi={reApi}
      />
    </Modal>
  );
};

ModalSelectItemPayDeduct.defaultProps = {};

export default ModalSelectItemPayDeduct;
