import React, { useContext } from 'react'
import { Row, Col, Form, Icon, DatePicker, TimePicker, Input } from 'antd'
import { FormattedMessage, useIntl } from 'react-intl'
import _ from 'lodash'
import { DeliveryShipmentDetailContext } from '..'
import LabeRequireForm from '../../../../../components/label-required-form'
import moment from 'moment'
import { numberFormatter } from '../../../../../component-function/fnc-inputnumber-currency'

const DeliveryOrderDetailInformation = (props) => {
  const { form } = props
  const { orderData, editFlag, handleOpenModalEditOrder, editData, handleOpenModalTotalWeight, handleOpenModalShipping } = useContext(DeliveryShipmentDetailContext)
  const { getFieldDecorator } = form
  const intl = useIntl()
  return (
    <div>
      <Form form={form}>
        <Row style={{ borderRight: '1px solid #E5E5E5', borderBottom: '1px solid #E5E5E5'}}>
          <Col span={12} style={{ borderRight: '1px solid #E5E5E5', padding: '24px' }}>
            <div className='deliveryHeaderStyle'>
              <FormattedMessage id="deliveryOrderDetailCustomer" defaultMessage="Customer" />{" "}{editFlag && _.get(orderData, 'statusDetail.statusCode') === "open" ? <Icon type="edit" style={{ color: '#6490CF', cursor: 'pointer' }} onClick={handleOpenModalEditOrder} /> : null}
            </div>
            <div className='deliveryValueStyle deliveryTextAddress' title={_.get(editData, 'contactDetail.comtomerName')}>
              {_.truncate(_.get(editData, 'customerDetail.comtomerName'))}
            </div>
            <div className='deliveryValueStyle'>
              {_.get(editData, 'customerDetail.phone')}
            </div>
            <div className='deliveryValueStyle ' style={{ cursor: 'pointer' }} title={_.get(editData, 'source.address')}>
              {_.get(editData, 'sourceDetail.address_name')} · {_.get(editData, 'sourceDetail.address')}
            </div>
          </Col>
          <Col span={12} style={{  padding: '24px' }}>
            <div className='deliveryHeaderStyle'>
              <FormattedMessage id="deliveryOrderDetailRecipient" defaultMessage="Recipient" />{" "}{editFlag && _.get(orderData, 'statusDetail.statusCode') === "open" ? <Icon type="edit" style={{ color: '#6490CF', cursor: 'pointer' }} onClick={handleOpenModalEditOrder} /> : null}
            </div>
            <div className='deliveryValueStyle deliveryTextAddress' title={_.get(editData, 'contactDetail.customerContactName')}>
              {_.truncate(_.get(editData, 'contactDetail.customerContactName'))}
            </div>
            <div className='deliveryValueStyle'>
              {_.get(editData, 'contactDetail.customerContactPhone')}
            </div>
            <div className='deliveryValueStyle ' style={{ cursor: 'pointer' }} title={_.get(editData, 'destination.address')}>
              {_.get(editData, 'destinationDetail.address_name')} · {_.get(editData, 'destinationDetail.address')}
            </div>
          </Col>
        </Row>
        <div style={{ padding: '24px' }}>
          <Row gutter={[16, 16]} >
            <Col span={12}>
              <div className='deliveryHeaderStyle'>
                <FormattedMessage id="deliveryOrderDetailCreditTerm" defaultMessage="Credit Term" />
              </div>
              <div className='deliveryValueStyle'>
                -
              </div>
            </Col>
            <Col span={12}>
              <div className='deliveryHeaderStyle'>
                <FormattedMessage id="deliveryOrderDetailTotalCredit" defaultMessage="Total Credit Balance" />
              </div>
              <div className='deliveryValueStyle'>
                -
              </div>
            </Col>
          </Row>
          <Row gutter={[16, 16]} >
            <Col span={12}>
              <div className='deliveryHeaderStyle'>
                <FormattedMessage id="deliveryOrderDetailConfirmedParcels" defaultMessage="Confirmed Parcels" />
              </div>
              <div className='deliveryValueStyle'>
                {_.get(editData, 'confirmedParcelsTxt') || '-'}
              </div>
            </Col>
            <Col span={12}>
              <div className='deliveryHeaderStyle'>
                <FormattedMessage id="deliveryOrderDetailPaymentStatus" defaultMessage="Payment Status" />
              </div>
              <div className='deliveryValueStyle'>
                {_.get(editData, 'paymentStatusText') || '-'}
              </div>
            </Col>
          </Row>
          <Row gutter={[16, 16]} >
            <Col span={12}>
              <div className='deliveryHeaderStyle'>
                <FormattedMessage id="deliveryOrderDetailReference" defaultMessage="Reference No." />
              </div>
              <div className='deliveryValueStyle'>
                {editFlag && _.get(orderData, 'statusDetail.statusCode') === "open" ? (
                  <Form.Item>
                    {
                      getFieldDecorator('refNo', {
                        initialValue: _.get(editData, 'referenceNo'),
                      })(
                        <Input
                          placeholder={intl.formatMessage({ id: 'deliveryOrderDetailPHReference', defaultMessage: 'Enter Reference No.' })}
                          maxLength={100}
                        />
                      )
                    }
                  </Form.Item>
                ) : _.get(editData, 'referenceNo')}
              </div>
            </Col>
          </Row>
          <Row gutter={[16, 16]} >
            <Col span={12}>
              <div className='deliveryHeaderStyle'>
                <LabeRequireForm text={intl.formatMessage({ id: 'deliveryOrderDetailShippingDate', defaultMessage: 'Shipping Date' })} req={editFlag} />
              </div>
              <div className='deliveryValueStyle'>
                {editFlag && _.get(orderData, 'statusDetail.statusCode') === "open" ? (
                  <Form.Item>
                    {getFieldDecorator('shippingDate', {
                      initialValue: _.get(editData, 'deliveryDate') ? moment(_.get(editData, 'deliveryDate'), 'YYYY-MM-DD') : undefined,
                      rules: [
                        {
                          required: true,
                          message: intl.formatMessage({ id: 'deliveryOrderDetailReqShippingDate', defaultMessage: 'Please Select Shipping Date' }),
                        },
                      ],
                    })(
                      <DatePicker
                        placeholder={intl.formatMessage({ id: 'deliveryOrderDetailPHShippingDate', defaultMessage: 'Select Shipping Date' })}
                        format='DD/MM/YYYY'
                        style={{ width: '100%' }}
                        allowClear={false}
                      />
                    )}
                  </Form.Item>
                ) : moment(_.get(editData, 'deliveryDate'), 'YYYY-MM-DD').format('DD/MM/YYYY') || '-'}
              </div>
            </Col>
            <Col span={12}>
              <div className='deliveryHeaderStyle'>
                <LabeRequireForm text={intl.formatMessage({ id: 'deliveryOrderDetailTimeWindow', defaultMessage: 'Time Window' })} req={editFlag} />
              </div>
              <div className='deliveryValueStyle'>
                {editFlag && _.get(orderData, 'statusDetail.statusCode') === "open" ? (
                  <Form.Item>
                    {
                      getFieldDecorator('timeWindow', {
                        initialValue: _.get(editData, 'deliveryTime') ? moment(_.get(editData, 'deliveryTime'), 'HH:mm') : undefined,
                        rules: [
                          {
                            required: true,
                            message: intl.formatMessage({ id: 'deliveryOrderDetailReqTimeWindow', defaultMessage: 'Please Select Time Window' }),
                          },
                        ],
                      })(
                        <TimePicker
                          format='HH:mm'
                          style={{ width: '100%' }}
                          placeholder={intl.formatMessage({ id: 'deliveryOrderDetailPHTimeWindow', defaultMessage: 'Select Time Window' })}
                          allowClear={false}
                          // disabledMinutes={() => Array.from({ length: 60 }, (_, index) => index + 1)}
                          minuteStep={60}
                        />
                      )
                    }
                  </Form.Item>
                ) : _.get(editData, 'deliveryTimeTxt') || '-'}
              </div>
            </Col>
          </Row>
          <Row gutter={[16, 16]} >
            <Col span={12}>
              <div style={{ height: '82px', backgroundColor: '#F2F6FD', textAlign: 'center', padding: '12px 24px' }}>
                <div className='deliveryHeaderStyle'>
                <FormattedMessage id="deliveryOrderDetailTotalWeight" defaultMessage="Total Weight" />
                </div>
                <div style={{ fontSize: '18px', fontWeight: 600, color: '#6490CF' }}>
                  {numberFormatter(_.get(editData, 'weight') || 0)}
                </div>
              </div>
            </Col>
            <Col span={12}>
              <div style={{ height: '82px', backgroundColor: '#F2F6FD', textAlign: 'center', padding: '12px 24px' }}>
                <div className='deliveryHeaderStyle'>
                  <FormattedMessage id="deliveryOrderDetailTotalShippingPrice" defaultMessage="Total Shipping Price" />{" "}{editFlag && _.get(orderData, 'statusDetail.statusCode') === "open" ? <Icon type="edit" style={{ color: '#6490CF', cursor: 'pointer' }} onClick={handleOpenModalShipping} /> : null}
                </div>
                <div style={{ fontSize: '18px', fontWeight: 600, color: '#6490CF' }}>
                  {numberFormatter(_.get(editData, 'amount') || 0)} <FormattedMessage id="deliveryTHB" defaultMessage="THB" />
                </div>
              </div>
            </Col>
          </Row>
          <Row gutter={[16, 16]} >
            <Col span={24}>
              <div className='deliveryHeaderStyle'>
                <LabeRequireForm text={intl.formatMessage({ id: 'deliveryOrderDetailRemark', defaultMessage: 'Remark' })} req={false} />
              </div>
              <div className='deliveryValueStyle'>
                {editFlag && _.get(orderData, 'statusDetail.statusCode') === "open" ? (
                  <Form.Item>
                    {
                      getFieldDecorator('remark', {
                        initialValue: _.get(editData, 'remark') || undefined,
                      })(
                        <Input
                          style={{ width: '100%' }}
                        />
                      )
                    }
                  </Form.Item>
                ) : _.get(editData, 'remark')}
              </div>
            </Col>
          </Row>
        </div>
      </Form>
    </div>
  )
}


export default DeliveryOrderDetailInformation
