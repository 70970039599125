import React, { useState, useEffect, useContext } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { MapViewContext } from '../monitor-context';
import { MonitorContext } from '../monitor-context';
import getResourceAndTask from '../../../controllers/map-view/map-api';
import getMapPolygonApi from '../../../controllers/map-view/map-polygon-api';
import ActionMapView from './action-map-view';
import MapView from './map-react-by-tae';
import MapSpeedTracking from '../map-speed-tracking/index';
import moment from 'moment';
import _, { debounce } from 'lodash';
import { Icon, Card, } from 'antd';
import Styles from './css/map-view.css';
import stylesMain from '../css/index.css';

moment.locale('en');

const today = new Date();
const dateApi = 'YYYY-MM-DD';
const dateTimeApi = 'YYYY-MM-DD HH:mm';
const dateTitlelModal = 'ddd, MMM DD, YYYY';

export default React.memo(({ mainResSpeedTrack }) => {
  const intl = useIntl();
  const { stateMain, setStateMain } = useContext(MonitorContext);
  const dateNow = moment(today).format(dateApi);
  const { LangCode } = stateMain
  const typeApi = LangCode && LangCode === `WorkDesk` ? `menuType=workdesk` : ``
  const [resourceShow, setResourceShow] = useState(true);
  const [taskShow, setTaskShow] = useState(true);

  const [resourceActive, setResourceActive] = useState('icon-focus');
  const [taskActive, setTaskActive] = useState('icon-focus-task');

  const [visiblePopover, setVisiblePopover] = useState(false);

  const [resourceData, setResourceData] = useState([]);
  const [taskData, setTaskData] = useState([]);

  const [taskValue, setTaskValue] = useState([]);

  const [search, setSearch] = useState("");
  const [selectFilter, setSelectFilter] = useState('all');

  const [orgIdArrString, setOrgIdArrString] = useState("");
  const [nowDate, setNowDate] = useState(dateNow);
  const [startDate, setStartDate] = useState(dateNow);
  const [endDate, setEndDate] = useState(dateNow);

  const [zoomLevel, setZoomLevel] = useState(12);
  const [dataSpeedTrack, setDataSpeedTrack] = useState();

  // const [polygonTeamService, setPolygonTeamService] = useState([]);
  // var orgIdArr = orgIdArrString.split(',');

  // useEffect(() => {
  //   const axiosGetData = async () => {
  //     setTimeout(async () => {
  //       const getData = await getResourceAndTask({ startDate, endDate, orgIdArrString, search, selectFilter, typeApi });
  //       let newResourceData = await getData.data.resource;
  //       let newTaskData = await getData.data.task;

  //       setResourceData(newResourceData);
  //       setTaskData(newTaskData);

  //       console.log('Map view getData:::', getData)

  //       // setStateMain.setSummaryData(getData.data.statusSummary);
  //       // setStateMain.setTasks(getData.data.totalTasks);
  //       // setStateMain.setWarning(getData.data.taskWarning);
  //       // setStateMain.setTimeout(getData.data.taskTimeout);

  //       setStateMain.setSummaryData({
  //         statusSummary: getData.data.statusSummary,
  //         tasks: getData.data.totalTasks,
  //         warning: getData.data.taskWarning,
  //         timeout: getData.data.taskTimeout,
  //       })

  //     }, 1000);
  //   }
  //   axiosGetData();

  //   const interval = setInterval(() => {
  //     axiosGetData();
  //   }, 180000);
  //   return () => clearInterval(interval);
  // }, [search, selectFilter, orgIdArrString]);

  useEffect(() => {
    setDataSpeedTrack(mainResSpeedTrack);
  }, [mainResSpeedTrack])

  // useEffect(() => {
  //   const getPolygonTeam = async () => {
  //     const getData = await getMapPolygonApi(orgIdArr);
  //     setPolygonTeamService(getData.data.data.services);
  //   }
  //   getPolygonTeam();

  //   // const interval = setInterval(() => {
  //   //   getPolygonTeam();
  //   // }, 180000);
  //   // return () => clearInterval(interval);
  // }, [orgIdArrString])

  const selectSearch = [
    {
      nameKey: intl.formatMessage({ id: `monitorTabMapViewSelectSearchTaskNoWorkDesk`, defaultMessage: 'Task Nos' }),
      key: 'task_no',
    },
    {
      nameKey: intl.formatMessage({ id: `monitorTabMapViewSelectSearchCustomerNameWorkDesk`, defaultMessage: 'Customer Name' }),
      key: 'customerName',
    },
    {
      nameKey: intl.formatMessage({ id: `monitorTabMapViewSelectSearchAssigneNameWorkDesk`, defaultMessage: 'Assign Name' }),
      key: 'assigneeName',
    },
  ];

  const onClickShowResource = (e) => {
    if (resourceShow === false) {
      setResourceShow(true);
      setResourceActive('icon-focus');
    } else if (resourceShow === true) {
      setResourceShow(false);
      setResourceActive('icon-focus-default');
    }
  };

  const onClickShowTask = (e) => {
    if (taskShow === false) {
      setTaskShow(true);
      setTaskActive('icon-focus-task');
    } else if (taskShow === true) {
      setTaskShow(false);
      setTaskActive('');
    }
  };

  const handleSearch = debounce((fieldChang) => {
    // setSearch(fieldChang ? fieldChang : '');
    setStateMain.setMainFieldSearch(fieldChang ? fieldChang : '');
  }, 500);

  const onSelect = (selectedKeys) => {
    // setSelectFilter(selectedKeys);
    setStateMain.setMainSearchColumn(selectedKeys);
  };

  const handleSelectTeam = (value) => {
    // setStateMain.setMainOrgId(value);
  };

  const onSelectDatePicker = (date) => {
    setStateMain.setMainDateTitleModal(moment(date).format(dateTitlelModal));
    setStateMain.setMainDateStartAction(date);
    setStateMain.setMainDateEndAction(date);
    setStateMain.setMainDate(moment(date).format(dateApi));
    setStateMain.setMainStartDate(moment(date).format(dateApi));
    setStateMain.setMainEndDate(moment(date).format(dateApi));

    // **********************************************************
    // setStartDate(moment(date).format(dateApi));
    // setEndDate(moment(date).format(dateApi));
    // setTimeout(() => {
    //   setNowDate(date);
    // }, 500);
  };

  const handleChangeOption = (value) => {
    const dataCreate = [];
    value.forEach((element) => {
      dataCreate.push(element);
    });
    const orgIdArr = dataCreate.toString();
    setOrgIdArrString(orgIdArr);

    setStateMain.setMainOrgIdArr(dataCreate);
    setStateMain.setMainOrgIdArrString(orgIdArr);
  };

  return (
    <MonitorContext.Consumer>
      {({ stateMain }) => (
        <MapViewContext.Provider
          value={{
            state: {
              resourceShow,
              taskShow,
              resourceActive,
              taskActive,
              visiblePopover,
              selectSearch,
              zoomLevel,
              stateMain,
              setStateMain,
              dataSpeedTrack
            },
            setState: {
              setResourceShow,
              setTaskShow,
              setVisiblePopover,
            },
            fnc: {
              onClickShowResource,
              onClickShowTask,
              onSelectDatePicker,
              handleChangeOption,
              handleSelectTeam,
              handleSearch,
              onSelect,
            },
          }}
        >
          <div className="stylesMain-monitor-tabs-body">
            <ActionMapView stateMain={stateMain} />
            {stateMain.mainOrgIdArrString === undefined ? (
              <Card className="stylesMain-map-result">
                <div className="stylesMain-map-detail">
                  <Icon className="stylesMain-map-result-icon" type="team" />
                  <p className="stylesMain-map-result-title">
                    <FormattedMessage
                      id={`monitorTabMapViewTxtDetailDescription1WorkDesk`}
                      defaultMessage="Please select a team"
                    />{' '}
                    <br />
                    <FormattedMessage
                      id={`monitorTabMapViewTxtDetailDescription2WorkDesk`}
                      defaultMessage="Map View will appear here."
                    />
                  </p>
                </div>
              </Card>
            ) : (
              stateMain.mainResSpeedTrack &&
                stateMain.mainResSpeedTrack.openSpeedTrack === true ?
                <MapSpeedTracking /> : <MapView mainResSpeedTrack={mainResSpeedTrack} orgIdArrString={stateMain.mainOrgIdArrString} />
            )}
          </div>
        </MapViewContext.Provider>
      )}
    </MonitorContext.Consumer>
  )
});
