import React, { useContext } from 'react'
import './index.css'
import { PettyCashContext } from './context'
import PettyCashContent from './content'
import { PageSettings } from '../../../config/page-settings'

const PagePettyCash = () => {
  const app = useContext(PageSettings);
  const { checkPermissionAction } = app
  return (
    <PettyCashContext>
      {
        checkPermissionAction('P83PG1C1', 'P83PG1C1A1') ? <PettyCashContent /> : null
      }

    </PettyCashContext>
  )
}

export default PagePettyCash
