import httpClient from '../../components/axiosClient';

const getTaskNote = async (taskId) => {
  const comCode = localStorage.getItem('comCode');
  const comId = localStorage.getItem('comId');
  try {
    const response = await httpClient.get(`/v4/task/manager/company/${comId}/note/getdata?taskId=${taskId}`);
    if (response.status === 200) {
      return response.data
    } else {
      return [];
    }
  } catch (error) { return []; }
}

const postTaskData = async (data) => {
  const comId = localStorage.getItem('comId');

  try {
    const response = await httpClient.post(`/v4/task/manager/company/${comId}/note/createdata`, data);
    if (response.status === 200) {
      return (response.data);
    } else {
      return ([]);
    }
  } catch (error) {
    return ([]);
  }
}

const putTaskData = async (id, data) => {
  const comId = localStorage.getItem('comId');

  try {
    const response = await httpClient.put(`/v4/task/manager/company/${comId}/note/updatedata/${id}`, data);
    if (response.status === 200) {
      return (response.data);
    } else {
      return ([]);
    }
  } catch (error) {
    return ([]);
  }
}

const postTaskDataImageFile = async (id, data) => {
  const comId = localStorage.getItem('comId');

  try {
    const response = await httpClient.put(`/v4/task/manager/company/${comId}/note/updatedata/photo/${id}`, data);
    if (response.status === 200) {
      return (response);
    } else {
      return ([]);
    }
  } catch (error) {
    return ([]);
  }
}
const deletePostData = async (id, data) => {
  const comId = localStorage.getItem('comId');

  try {
    const response = await httpClient.delete(`/v4/task/manager/company/${comId}/note/deletedata/${id}`);
    if (response.status === 200) {
      return (response);
    } else {
      return ([]);
    }
  } catch (error) {
    return ([]);
  }
}

const deleteFile = async (id, filehash) => {
  const comId = localStorage.getItem('comId');

  try {
    const response = await httpClient.delete(`/v4/task/manager/company/${comId}/note/deletedata/${id}/filehash/${filehash}`);
    if (response.status === 200) {
      return (response);
    } else {
      return ([]);
    }
  } catch (error) {
    return ([]);
  }
}
export { getTaskNote, postTaskData, putTaskData, postTaskDataImageFile, deletePostData, deleteFile };