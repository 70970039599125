import React, { useState, useRef, useContext } from 'react';
import { ApprovalContext } from '../../approval-context';
import _ from 'lodash';
import { Table, Col, Row, Form, Select, DatePicker, Input, Popover, Menu, Button } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import '../css/index.css';
import moment from 'moment';
import { PageSettings } from '../../../../config/page-settings';
const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';

const WaitingTab = (props) => {
  const { state, setState, fnc } = useContext(ApprovalContext);
  const { checkPermissionAction } = useContext(PageSettings);
  const { searchList, changeTab, isLoading, fillter, selectedRowKeys } = state;
  const { setChangeStatus, setFillter, setChangeTab, setSelectedRowKeys } = setState;
  const {
    handleChangePage,
    handleChangeSize,
    handleView,
    handleRegister,
    handleRedeem,
    handleKYC,
    onChangeTable,
    handleItemRequest,
    handleopenReject,
    handleOpenApprove,
  } = fnc;
  const { columns, form, tab } = props;
  const data = _.get(searchList, 'requestList');
  const page = _.get(searchList, 'totalPage') || 1;
  const totalItems = _.get(searchList, 'totalRequest') || 0;
  const [scrollTableX, setScrollTableX] = useState(1500);
  const [isFilter, setIsFilter] = useState(false);
  const [rangeDate, setRangeDate] = useState(false);
  const intl = useIntl();
  //------------------------------
  const ref = useRef();
  const [visiblePopoverTable, setVisiblePopoverTable] = useState();
  const [recordPopover, setRecordPopover] = useState();
  const [offsetPopover, setOffsetPopover] = useState([0, 0]);
  //-------------------------------
  const permissonApprove = checkPermissionAction(`P49PG2C2`, `P49PG2C2A2`);
  const permissonReject = checkPermissionAction(`P49PG2C2`, `P49PG2C2A3`);

  const handleChangeDate = (value) => {
    setRangeDate(value);
    setFillter({
      ...fillter,
      ['pmdatestart']: moment(_.get(value, '[0]')).format(dateFormat),
      ['pmdateto']: moment(_.get(value, '[1]')).format(dateFormat),
    });
    handleFillter(value);
  };

  const handleFillter = (value, code) => {
    setFillter({ ...fillter, [code]: value });
  };

  const rowSelection = {
    selectedRowKeys: selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      let selectList = [];
      setSelectedRowKeys(selectedRowKeys);
      _.map(selectedRows, (item) => {
        console.log('selectedRows: ', item.memReqId);
        selectList.push(item.memReqId);
      });
      setChangeStatus(selectList);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User', // Column configuration not to be checked
      name: record.name,
    }),
  };

  const showTotal = () => {
    return (
      <span style={{ fontSize: '13px' }}>
        <FormattedMessage id="lblRowPerPage" defaultMessage="Rows per page: " />
      </span>
    );
  };

  const fncActionTable = (record, offsetPopover, visiblePopoverTable, ref) => {
    return (
      <div>
        <Popover
          placement="right"
          trigger="hover"
          align={{ offset: offsetPopover }}
          visible={visiblePopoverTable}
          zIndex={10000}
          content={
            <div style={{ display: 'grid' }}>
              <>
                {_.get(record, 'modalViewCode') === 'customer_KYC' ? (
                  <Button style={{ width: 100 }} type="link" ghost onClick={() => handleKYC(record)}>
                    <FormattedMessage id="btnView" defaultMessage="View" />
                  </Button>
                ) : null}
                {_.get(record, 'modalViewCode') === 'registration_member' && (
                  <Button style={{ width: 100 }} type="link" ghost onClick={() => handleRegister(record)}>
                    <FormattedMessage id="btnView" defaultMessage="View" />
                  </Button>
                )}

                {console.log('modelstatus', _.get(record, 'modalViewCode'))}

                {_.get(record, 'modalViewCode') === 'registration_customer' && (
                  <Button style={{ width: 100 }} type="link" ghost onClick={() => handleView(record)}>
                    <FormattedMessage id="btnView" defaultMessage="View" />
                  </Button>
                )}

                {_.get(record, 'modalViewCode') === 'redeem_C-Point' && (
                  <Button style={{ width: 100 }} type="link" ghost onClick={() => handleRedeem(record)}>
                    <FormattedMessage id="btnView" defaultMessage="View" />
                  </Button>
                )}
                {_.get(record, 'requestTypeCode') === 'requestitem' && (
                  <Button style={{ width: 100 }} type="link" ghost onClick={() => handleItemRequest(record)}>
                    <FormattedMessage id="btnView" defaultMessage="View" />
                  </Button>
                )}
                {changeTab === '1' && _.size(selectedRowKeys) <= 1 && (
                  <>
                    {permissonApprove && (
                      <Button style={{ width: 100 }} type="link" ghost onClick={() => handleOpenApprove(record)}>
                        <FormattedMessage id="btnApprove" defaultMessage="Approve" />
                      </Button>
                    )}
                    {permissonReject &&   (
                      <Button style={{ width: 100 }} type="link" ghost onClick={() => handleopenReject(record)}>
                        <FormattedMessage id="btnReject" defaultMessage="Reject" />
                      </Button>
                    )}
                  </>
                )}
              </>
            </div>
          }
        >
          <div style={{ width: 0, height: 0 }} ref={ref}></div>
        </Popover>
      </div>
    );
  };

  const handleMouseLeave = () => {
    setVisiblePopoverTable(false);
    setRecordPopover();
    setOffsetPopover([0, 0]);
  };

  return (
    <div>
      {isFilter === true && (
        <div className="filterBackground">
          <Form>
            <Row gutter={24}>
              <Col span={11} style={{ padding: '16px 16px 0px' }}>
                <Form.Item>
                  <span className="formText-formate">
                    <FormattedMessage id="assetpreventiveItem" defaultMessage="Request No." />
                  </span>

                  <Input
                    className="inputWidth"
                    placeholder={intl.formatMessage({ id: 'assetpreventivePlaceholderItem', defaultMessage: 'Enter Request No.' })}
                    onChange={(e) => handleFillter(e.target.value, 'reno')}
                    allowClear
                  ></Input>
                </Form.Item>
              </Col>
              <Col span={11} style={{ padding: '16px 16px 0px' }}>
                <Form.Item>
                  <span className="formText-formate">
                    <FormattedMessage id="assetpreventiveItem" defaultMessage="Requestor" />
                  </span>

                  <Input
                    className="inputWidth"
                    placeholder={intl.formatMessage({ id: 'assetpreventivePlaceholderItem', defaultMessage: 'Enter Requestor' })}
                    onChange={(e) => handleFillter(e.target.value, 'requestor')}
                    allowClear
                  ></Input>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={11} style={{ padding: '16px 16px 16px' }}>
                <Form.Item>
                  <span className="formText-formate">
                    <FormattedMessage id="assetpreventiveDate" defaultMessage="Submitted Date" />
                  </span>
                  <RangePicker
                    className="inputWidth"
                    onChange={handleChangeDate}
                    placeholder={[
                      intl.formatMessage({
                        id: 'assetpreventivePlaceholderDate',
                        defaultMessage: 'Select From Date - ',
                      }),
                      intl.formatMessage({
                        id: 'assetpreventivePlaceholderDate',
                        defaultMessage: 'Select To Date',
                      }),
                    ]}
                    format="ddd, MMM DD YYYY"
                    allowClear={true}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      )}
      <div className="containerTable" onMouseLeave={handleMouseLeave}>
        <Row gutter={16}>
          <Table
            rowSelection={changeTab !== '2' && changeTab !== '3' ? (permissonApprove || permissonReject ? rowSelection : null) : null}
            className="custom-table-claim"
            // dataSource={[{
            //   index: 1
            // }]}
            dataSource={data || []}
            columns={columns}
            scroll={{ x: scrollTableX, y: `calc(100vh - 373px)` }}
            loading={isLoading}
            onChange={onChangeTable}
            pagination={{
              total: totalItems,
              // current: page,
              defaultCurrent: 1,
              pageSizeOptions: ['10', '20', '30', '40', '50'],
              showSizeChanger: true,
              locale: { items_per_page: '' },
              showTotal: showTotal,
              onChange: handleChangePage,
              onShowSizeChange: handleChangeSize,
            }}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  if (!event.target.href && record.modalViewCode === 'registration_customer') {
                    const { x, y } = ref.current.getBoundingClientRect();
                    setVisiblePopoverTable(true);
                    setOffsetPopover([event.pageX - x, event.pageY - y]);
                    setRecordPopover(record);
                  } else {
                    const { x, y } = ref.current.getBoundingClientRect();
                    setVisiblePopoverTable(true);
                    setOffsetPopover([event.pageX - x, event.pageY - y]);
                    setRecordPopover(record);
                  }
                },
              };
            }}
          />
          {fncActionTable(recordPopover, offsetPopover, visiblePopoverTable, ref)}
          <div className="total-items-member">
            <span style={{ fontSize: '13px' }}>
              <FormattedMessage id="lblTotal" defaultMessage="Total" />
              {` ${totalItems || 0} `}
              <FormattedMessage id="lblitems" defaultMessage="items" />
            </span>
          </div>
        </Row>
      </div>
    </div>
  );
};

const WaitingFillter = Form.create({
  name: 'Waiting_filter_form',
  mapPropsToFields() {},
})(WaitingTab);

export default WaitingFillter;
