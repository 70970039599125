import React, { useState, useEffect } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Card, Modal, Select, Typography, Icon, Col, Row, Divider, Form, InputNumber, Popover, Button, Input, Upload } from 'antd';
import pathUrt from '../../../function/pathUrl';
import Button01 from '../../../components/v2/button_01';
import Button02 from '../../../components/v2/button_02';
import { PageSettings } from '../../../config/page-settings';
// import { successNotification, errorNotification, warningNotification } from '../../../components/v2/notification';
import _ from 'lodash';
import LabeRequireForm from '../../../components/label-required-form';
import { handleKeyDownDecimal2Fixed } from '../../../component-function/fnc-number';
import SuffixInput from '../../../components/v2/suffix';
import style from './css/index.css';
import styled from 'styled-components';
import ImageGallery from 'react-image-gallery';
import httpClient from '../../../components/axiosClient';
import logoPDF from '../../../pages/login/image/pdf.png';
import { useAppContext } from '../../../includes/indexProvider';
import Resizer from 'react-image-file-resizer';
import { errorNotification, successNotification } from '../../../components/v2/notification';
import ReactBnbGallery from 'react-bnb-gallery';
import LabeRequire from '../../../components/v2/label-require';

const { Title, Text } = Typography;
const { Option } = Select;
const { confirm } = Modal;

const Bank = (props) => {
  const intl = useIntl();
  const comCode = localStorage.getItem('comCode');
  const app = useAppContext();
  const langValue = app.state.langValue;
  const { form, customerId } = props;
  const { getFieldDecorator, resetFields, getFieldValue, setFieldsValue } = form;

  const [stateModal, setStateModal] = useState(false);
  const [visibleModalAdd, setVisibleModalAdd] = useState(false);
  const checkPathUrl = pathUrt('/user-management/my-profile');

  const [itemBank, setItemBank] = useState([]);
  const [listItem, setListItem] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [dataForm, setDataForm] = useState({});
  const [visiblePopover, setVisiblePopover] = useState(null);

  

  const [fileList, setFileList] = useState([]);
  const [stateModalUpload, setStateModalUpload] = useState(false);
  const [modalView, setModalView] = useState();
  const [startIndexGallery, setStartIndexGallery] = useState(0);
  const [bank, setBank] = useState([]);

  console.log('fileList', fileList);

  useEffect(() => {
    const getAPI = async () => {
      const body = { customerId: customerId };
      const response = await httpClient.post(`/v3/business-partner/manager/company/${comCode}/getBankDetail`, body);
      console.log('getBankDetail', response);
      if (_.get(response, 'status') === 200) {
        const mapFileForBack = _.map(response.data.data, (item) => {
          const mapFile = _.map(_.get(item, 'images'), (file, index) => {
            return {
              uid: file.hash_id,
              name: file.fileName,
              status: 'done',
              url: file.image,
              thumbUrl: file.type === 'pdf' ? logoPDF : file.image,
              type: file.type,
              for: 'back',
            };
          });
          return {
            ...item,
            images: mapFile,
          };
        });
        console.log('mapFileForBack', mapFileForBack);
        setItemBank(mapFileForBack);
        setBank(_.get(response, 'data.bankList'));
      }
    };
    getAPI();
  }, [toggle]);

  console.log('itemBank', itemBank);

  const handleCancel = (e) => {
    resetFields();
    setStateModal(false);
  };

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        720,
        720,
        'JPG',
        70,
        0,
        (uri) => {
          resolve(uri);
        },
        'base64'
      );
    });

  const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    for (var i = 0; i < bstr.length; i++) {
      u8arr[i] = bstr.charCodeAt(i);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const handleSave = () => {
    form.validateFields(async (err, values) => {
      if (err) {
        return;
      }

      let formData = new FormData();
      const filterBank = _.filter(bank, (b) => _.get(b, 'bankCode') === _.get(values, 'bank'));

      // formData.append('files', mapFileChange);
      formData.append('bank', langValue === 'TH' ? _.get(filterBank, '[0].bankName.TH') : _.get(filterBank, '[0].bankName.EN'));
      formData.append('bankCode', _.get(values, 'bank'));
      formData.append('accountNo', _.get(values, 'accountNo') ? _.get(values, 'accountNo') : '');
      formData.append('accountName', _.get(values, 'accountName') ? _.get(values, 'accountName') : '');
      formData.append('branch', _.get(values, 'branch') ? _.get(values, 'branch') : '');
      formData.append('customerId', customerId)

      console.log('formData', formData);

      try {
        const response =
          _.get(dataForm, 'action') === 'edit'
            ? await httpClient.put(`/v3/business-partner/manager/company/${comCode}/updateBankInfo/${_.get(dataForm, 'id')}`, formData)
            : await httpClient.post(`/v3/business-partner/manager/company/${comCode}/createBankDetail`, formData);

        console.log('handleSave', response, fileList);
        if (fileList && _.size(fileList) > 0) {
          for (let i = 0; i < _.size(fileList); i++) {
            if (fileList[i].originFileObj && fileList[i].for === 'front') {
              let formDataFile = new FormData();
              formDataFile.append('files', fileList[i].originFileObj);
              await httpClient.put(
                `/v3/business-partner/manager/company/${comCode}/updateLoopImage/${
                  _.get(dataForm, 'action') === 'edit' ? _.get(dataForm, 'id') : _.get(response, 'data.createBankDetail.id')
                }`,
                formDataFile
              );
            }
          }
        }

        if (_.get(response, 'status') === 200) {
          successNotification(_.get(response, 'data.message'));
          handleCancel();
        } else {
          errorNotification(_.get(response, 'data.message'));
        }
      } catch (error) {
        errorNotification(error.response.data.message);
      }
      setToggle((event) => !event);
    });
  };

  const handleDelete = (record) => {
    Modal.confirm({
      className: 'maintenance-report-modal-confirm',
      centered: true,
      icon: null,
      title: intl.formatMessage({ id: 'lblDelete', defaultMessage: 'Are you sure you want to delete ?' }),
      okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        try {
          const response = await httpClient.delete(`/v3/business-partner/manager/company/${comCode}/deleteBankDetail/${record.id}`);
          console.log('responsede', response);
          if (_.get(response, 'status') === 200) {
            successNotification(response.data);
            setToggle((event) => !event);
          }
        } catch (error) {
          errorNotification(error.response.data);
        }
      },
      onCancel() {},
    });
  };

  const handleButtonClick = () => {
    setVisiblePopover(null);
  };

  const handleRowClick = (index) => {
    setVisiblePopover(visiblePopover === index ? null : index);
  };

  const handleMouseLeave = () => {
    setVisiblePopover(null);
    // setOffsetPopover([0, 0]);
  };

  let show = itemBank.length
    ? itemBank.map((item, index) => (
        <div>
          <Popover
            key={index}
            placement="leftTop"
            // trigger="hover"
            trigger="click"
            zIndex={999}
            visible={visiblePopover === index}
            content={
              <div style={{ display: 'grid' }}>
                <PageSettings.Consumer>
                  {({ checkPermissionAction }) => (
                    <div>
                      {/* {checkPermissionAction('P2S1PG2C16', 'P2S1PG2C16A3') ? ( */}
                      <Button
                        style={{ width: 100 }}
                        type="link"
                        ghost
                        onClick={() => {
                          handleButtonClick();
                          handleOpenModal(item, 'edit');
                        }}
                      >
                        <FormattedMessage id="btnEdit" defaultMessage="Edit" />
                      </Button>
                      {/* ) : null} */}
                    </div>
                  )}
                </PageSettings.Consumer>

                <PageSettings.Consumer>
                  {({ checkPermissionAction }) => (
                    <div>
                      {/* {checkPermissionAction('P2S1PG2C16', 'P2S1PG2C16A4') ? ( */}
                      <Button
                        style={{ width: 100 }}
                        type="link"
                        ghost
                        onClick={() => {
                          handleButtonClick();
                          handleDelete(item);
                        }}
                      >
                        <FormattedMessage id="btnDelete" defaultMessage="Delete" />
                      </Button>
                      {/* ) : null} */}
                    </div>
                  )}
                </PageSettings.Consumer>
              </div>
            }
          >
            <Row gutter={[4, 4]} onClick={() => handleRowClick(index)}>
              <Col span={24} style={{ color: '#050505' }}>
                {_.get(item, 'bank_name')}
              </Col>
              <Col span={24} style={{ color: '#050505' }}>
                {_.get(item, 'account_no')}
              </Col>
              <Col span={24} style={{ color: '#050505' }}>
                {_.get(item, 'account_name')}
              </Col>
              <Col span={24} style={{ color: '#707070' }}>
                {_.get(item, 'branch')}
              </Col>
            </Row>

            <Row style={{ marginTop: '15px' }}>
              <Col span={24}>
                <Upload
                  className={style.upload}
                  // onChange={handleChange}
                  // action={(file) => action(file, item.docComId, index)}
                  listType="picture-card"
                  fileList={_.get(item, 'images')}
                  onPreview={(file) => handlePreview(file, item.images || [])}
                  accept=".pdf, .jpg, .png"
                  showUploadList={{
                    showPreviewIcon: true,
                    showRemoveIcon: false,
                  }}
                  beforeUpload={() => false}
                ></Upload>
              </Col>
            </Row>
          </Popover>

          <Divider className="usersDetail-content-divider" />
        </div>
      ))
    : null;

  let option = bank.map((item, index) => (
    <Option key={_.get(item, 'bankCode')} value={_.get(item, 'bankCode')}>
      {langValue === 'TH' ? item.bankName.TH : item.bankName.EN}
    </Option>
  ));

  const handleOpenModal = (record, action) => {
    console.log('record', record);
    setDataForm({ ...record, action: action });
    setFileList(record.images);
    setStateModal(true);
  };

  // upload

  const uploadButton = (
    <div>
      <Icon type="plus" />
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  const handleChange = ({ fileList }) => {
    console.log('handleChangefileList', fileList);

    const newData = _.map(fileList, (file) => {
      return {
        ...file,
        // url: file.type === "application/pdf" ? logoPDF : file.path,
        // type: file.type === "application/pdf" ? 'pdf' : file.type,
        thumbUrl: file.type === 'application/pdf' ? logoPDF : file.thumbUrl,
        // original: file.type === 'pdf' ? logoPDF : thumbUrl,
        // thumbnail: file.type === 'pdf' ? logoPDF : thumbUrl,
        for: file.for ? file.for : 'front',
      };
    });

    setFileList(newData);
  };

  console.log('fileList', fileList);

  const handlePreview = async (item, fileList) => {
    console.log('handlePreview 1', item, fileList);

    if (item.type === 'pdf') return onPreviewPDFBack(item);
    if (item.type === 'application/pdf') return onPreviewPDFFront(item);

    let imageFiles = fileList.filter((file) => !/pdf/g.test(file.type));

    // const imageGalleryItems = imageFiles.map((file) => ({
    //   original: file.url || file.thumbUrl,
    //   thumbnail: file.thumbUrl,
    //   ...file,
    // }));

    const imageGalleryItems = imageFiles.map((file) => ({
      photo: file.url || file.thumbUrl,
      thumbnail: file.thumbUrl,
      caption: file.fileName || '',
    }));

    const currentIndex = imageFiles.findIndex((file) => file.uid === item.uid);

    console.log('handlePreview 2', imageGalleryItems, currentIndex, fileList);

    setModalView(imageGalleryItems);
    setStartIndexGallery(currentIndex);
    setStateModalUpload(!stateModalUpload);
  };

  const onPreviewPDFBack = (item) => {
    let isPDF = /pdf/g.test(item.type);
    if (isPDF) return window.open(item.url || item.originalPath, '_blank');
  };

  const onPreviewPDFFront = (item) => {
    let isPDF = /pdf/g.test(item.type);
    const fileUrl = URL.createObjectURL(item.originFileObj);
    if (isPDF) return window.open(fileUrl, '_blank');
  };

  const handleRemove = (file) => {
    return new Promise((resolve, reject) => {
      let documentId = file.document_id;
      console.log('handleRemove', file, fileList);

      confirm({
        className: 'document-modal-confirm',
        title: intl.formatMessage({ id: 'lblDelete', defaultMessage: 'Are you sure you want to delete ?' }),
        okText: intl.formatMessage({ id: 'modalConfirmBtnYes', defaultMessage: 'Yes' }),
        cancelText: intl.formatMessage({ id: 'modalConfirmBtnNo', defaultMessage: 'No' }),
        okType: 'danger',
        okButtonProps: {
          style: {
            width: '96px',
          },
          type: 'primary',
        },
        cancelButtonProps: {
          style: {
            width: '96px',
          },
          type: 'dashed',
        },
        onOk: async () => {
          if (_.get(file, 'for') === 'back') {
            try {
              const payload = {
                hashToRemove: _.get(file, 'uid'),
              };
              const resDeleteDoc = await httpClient.post(
                `/v3/business-partner/manager/company/${comCode}/removeBankImage/${_.get(dataForm, 'id')}`,
                payload
              );
              // if (resDeleteDoc.status === 200) {
              //   openNotificationSuccess(resDeleteDoc.data);
              //   setToggle((event) => !event)
              // } else {
              //   openNotificationError(resDeleteDoc.data);
              // }
            } catch (error) {
              console.error(error);
              reject();
            }
          }

          const newFileList = fileList.filter((f) => f.uid !== file.uid);
          setFileList(newFileList);

          resolve();
          setToggle((event) => !event);
        },
        onCancel: () => {
          reject();
        },
      });
    });
  };

  const handleCancelGallery = () => {
    setStateModalUpload(false);
  };

  // const HandlerModal = (item) => {
  //   return (
  //     <div>
  //       <StyleModal visible={stateModalUpload} onCancel={handleCancelGallery} footer={null} width={'100%'}>
  //         <ImageGallery
  //           className={style.imagegallery}
  //           items={modalView}
  //           showPlayButton={false}
  //           showIndex={true}
  //           startIndex={startIndexGallery}
  //           showFullscreenButton={false}
  //         />
  //       </StyleModal>
  //     </div>
  //   );
  // };

  const HandlerModal = () => {
    return (
      <ReactBnbGallery show={stateModalUpload} photos={modalView} activePhotoIndex={startIndexGallery} onClose={handleCancelGallery} />
    );
  };

  return (
    <Card
      onMouseLeave={handleMouseLeave}
      title={<FormattedMessage id="customerBankTitleBank" defaultMessage="Bank" />}
      extra={
        <PageSettings.Consumer>
          {({ checkPermissionAction }) => (
            <div>
              {/* {checkPermissionAction('P2S1PG2C16', 'P2S1PG2C16A2') ? ( */}
              <Button01 key="submit" type="primary" btnsize="wd_df" onClick={() => handleOpenModal({}, 'add')}>
                <FormattedMessage id="btnAdd" defaultMessage="Add" />
              </Button01>
              {/* ) : null} */}
            </div>
          )}
        </PageSettings.Consumer>
      }
    >
      {show}
      <Modal
        visible={stateModal}
        onOk={handleSave}
        onCancel={handleCancel}
        width={750}
        // okText={intl.formatMessage({ id: 'userDetailModalBtnSave', defaultMessage: 'Save' })}
        // cancelText={intl.formatMessage({ id: 'userDetailModalBtnCancel', defaultMessage: 'Cancel' })}
        className="button-modal"
        title={
          _.get(dataForm, 'action') === 'edit' ? (
            <Title style={{ fontSize: '15px', fontWeight: 400, color: '#1D3557' }}>
              <FormattedMessage id="customerBankModalTitleEditBank" defaultMessage="Edit Bank" />
            </Title>
          ) : (
            <Title style={{ fontSize: '15px', fontWeight: 400, color: '#1D3557' }}>
              <FormattedMessage id="customerBankModalTitleCreateBank" defaultMessage="Bank" />
            </Title>
          )
        }
        footer={[
          <Button02
            style={{ margin: '0px 0px 0px 10px', fontSize: '13px' }}
            className="btn-style-new"
            key="back"
            fontsize="sm"
            btnsize="wd_df"
            onClick={handleCancel}
          >
            <FormattedMessage id="btnCloseBasePay" defaultMessage="Close" />
          </Button02>,
          <Button01
            style={{ fontSize: '13px' }}
            className="btn-style-new2"
            key="submit"
            fontsize="sm"
            btnsize="wd_df"
            type="primary"
            onClick={handleSave}
          >
            <FormattedMessage id="btnSave" defaultMessage="Save" />
          </Button01>,
        ]}
      >
        <Form layout="vertical">
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <p className="item-label-detail">
                <FormattedMessage id="customerBankTextBank" defaultMessage="Bank" />
                <LabeRequire req={true} />
              </p>
              <Form.Item>
                {getFieldDecorator('bank', {
                  initialValue: _.get(dataForm, 'bank_code'),
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({
                        id: 'customerBankValidateBank',
                        defaultMessage: 'Please Select Bank',
                      }),
                    },
                  ],
                })(
                  <Select
                    placeholder={intl.formatMessage({ id: 'customerBankPlaceBank', defaultMessage: 'Select Bank' })}
                    className="user-management-role-select"
                    style={{ width: '100%' }}
                    showSearch
                    allowClear
                    filterOption={(input, option) =>
                      option.props.children
                        .toString()
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {option}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <p className="item-label-detail">
                <FormattedMessage id="customerBankTextAccountNo" defaultMessage="Account No." />
              </p>
              <Form.Item>
                {getFieldDecorator('accountNo', {
                  initialValue: _.get(dataForm, 'account_no'),
                })(
                  <InputNumber
                    className="disabled-handle-count"
                    placeholder={intl.formatMessage({ id: 'customerBankPlaceAccountNo', defaultMessage: 'Enter Account No.' })}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={12}>
              <p className="item-label-detail">
                <FormattedMessage id="customerBankTextAccountName" defaultMessage="Account Name" />
              </p>
              <Form.Item>
                {getFieldDecorator('accountName', {
                  initialValue: _.get(dataForm, 'account_name'),
                })(
                  <Input
                    className="disabled-handle-count"
                    placeholder={intl.formatMessage({ id: 'customerBankPlaceAccountName', defaultMessage: 'Enter Account Name' })}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <p className="item-label-detail">
                <FormattedMessage id="customerBankTextBranch" defaultMessage="Branch" />
              </p>
              <Form.Item>
                {getFieldDecorator('branch', {
                  initialValue: _.get(dataForm, 'branch'),
                })(
                  <Input
                    className="disabled-handle-count"
                    placeholder={intl.formatMessage({ id: 'customerBankPlaceBranch', defaultMessage: 'Enter Branch' })}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 10]}>
            <Col span={24}>
              <p className="item-label-detail">
                <FormattedMessage id="customerBankTextFile" defaultMessage="File" />
              </p>
            </Col>
            <Col span={24}>
              <Upload
                className={style.upload}
                onChange={handleChange}
                // action={(file) => action(file, item.docComId, index)}
                listType="picture-card"
                fileList={fileList}
                onPreview={(file) => handlePreview(file, fileList || [])}
                onRemove={(file) => handleRemove(file)}
                accept=".pdf, .jpg, .png"
                showUploadList={
                  {
                    // showPreviewIcon: (file) => console.log('showxxxxx', file),
                    // showRemoveIcon: checkPermissionAction('P2S1PG2C7', 'P2S1PG2C7A3') ? true : false,
                  }
                }
                beforeUpload={() => false}
              >
                {uploadButton}
              </Upload>
            </Col>
          </Row>
        </Form>
      </Modal>

      <HandlerModal />
    </Card>
  );
};

const BankForm = Form.create({
  name: 'global_state',
})(Bank);

export default BankForm;

const StyleModal = styled(Modal)`
  .ant-modal-content {
    background-color: transparent;
    box-shadow: none;
  }

  .image-gallery-slide .image-gallery-image {
    max-width: 400px;
    max-height: 500px;
  }

  .ant-modal-close {
    color: #ffffff;
    right: 667px;
  }

  .ant-modal-close-x {
    font-size: 24px;
  }

  .image-gallery-index {
    margin: 0px 20px 0px 20px;
  }

  .image-gallery-right-nav {
    right: 30%;
  }

  .image-gallery-left-nav {
    left: 30%;
  }
`;
