import { Card, Col, Row, Tabs } from 'antd';
import React, { useContext, useState } from 'react';
import Filter from './component/filter';
import { FormattedMessage, useIntl } from 'react-intl';
import { ApprovalContext } from '../approval-context';
import FillterForm from './component/filtterform';
import Button_01 from '../../../components/v2/button_01';
import _ from 'lodash';

const LeftCardApproval = () => {
  const intl = useIntl();
  const { state, setState, fnc } = useContext(ApprovalContext);
  const { menuList, indeterminateRequest, indeterminateTeam, checkedKeysRequest, checkedKeysTeam, checkAllRequest, checkAllTeam,disbleApply } = state;
  const {
    setCheckedKeysRequest,
    setCheckedKeysTeam,
    setIndeterminateTeam,
    setIndeterminateRequest,
    setCheckAllRequest,
    setCheckAllTeam,
    setTrigger,
    setDisbleApply,
    setRightCardDisplay
  } = setState;
  const { onCheckRequest, onCheckTeam } = fnc;
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);

  const onExpand = (expandedKeys) => {
    setExpandedKeys(expandedKeys);
    setAutoExpandParent(false);
  };

  const handelApply = () => {
   if (_.size(checkedKeysRequest) === 0 && _.size(checkedKeysTeam) === 0) {
    setRightCardDisplay(false)
   }
   else {
    setRightCardDisplay(true);
    setTrigger((current) => !current)
   } 
   setDisbleApply(true)
  }
  return (
    <Card className="left-card">
      <div>
        <div className="filter-text-summary-text">
          <span className="filter-text-summary-text-size">{intl.formatMessage({ id: `ToReviewFilter`, defaultMessage: 'Filter' })}</span>
        </div>
        <div style={{ overflowY: 'auto', height: 'calc(100vh - 220px)' }}>
          <Filter
            Data={menuList.data}
            indeterminateRequest={indeterminateRequest}
            indeterminateTeam={indeterminateTeam}
            setCheckedListRequest={setState.setCheckedListRequest}
            setCheckedListTeam={setState.setCheckTeam}
            setIndeterminateTeam={setIndeterminateTeam}
            setIndeterminateRequest={setIndeterminateRequest}
            setOrgIdRaw={setState.setOrgIdRaw}
            orgIdRaw={state.orgIdRaw}
            setPaginationPage={setState.setPaginationPage}
            checkedKeysRequest={checkedKeysRequest}
            checkedKeysTeam={checkedKeysTeam}
            autoExpandParent={autoExpandParent}
            expandedKeys={expandedKeys}
            onCheckRequest={onCheckRequest}
            onCheckTeam={onCheckTeam}
            onExpand={onExpand}
            setCheckAllRequest={setCheckAllRequest}
            checkAllRequest={checkAllRequest}
            setCheckAllTeam={setCheckAllTeam}
            checkAllTeam={checkAllTeam}
            setDisbleApply={setDisbleApply}
          />
          <FillterForm />
        </div>
        
        <div>
        
          <Row>
            <Col span={24} style={{ padding: '16px 16px 16px 16px' }}>
              <Button_01
                key="submit"
                type="primary"
                style={{ width: '100%', margin: 'unset' }}
                onClick={() => handelApply()}
                disabled={disbleApply}
              >
                <FormattedMessage id="btnFilterApply" defaultMessage="Apply" />
              </Button_01>
            </Col>
          </Row>
        </div>
      </div>
    </Card>
  );
};

export default LeftCardApproval;
