import React from 'react'
import { Icon, Avatar } from 'antd'
import './css/index.css'

export default (props) => {
  const { imageRef, imageFile, imageFileDefault } = props


  return (
    <div className="image-container-asset">
      <Avatar
        className='avartar-asset'
        shape="square"
        size={150}
        src={`${imageFile ? imageFile : imageFileDefault}`} />
      <input
        ref={imageRef}
        type="file"
        style={{ display: 'none' }}
        accept=".jpg, .png, .JPG, .PNG"
      />
    </div>
  )
}
