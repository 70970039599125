import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { Col, Row } from 'antd';
import { PageEngagement } from '../../config/page-settings';
import { getTabs, getCustomer, getMember, getEngagementTimeline, deletePostOne } from '../../controllers/engagement';
import { getReason, getDefaultStarted } from '../../controllers/quotation/quotation-api';
import { cancelQutation } from '../../components/view-quotation/modal-cancel-quotation.js';
import { deleteQuotation } from '../../components/view-quotation/modal-delete-quotation';
import getAllTeam from '../../controllers/get-all-team/get-all-team';
import EngagementFilter from './engagement-filter/index';
import EngagementAction from './engagement-action/index';
import EngagementTimeline from './timeline';
import ModalCustomerPost from './engagement-post/post-add';
import ModalCustomerView from './engagement-post/post-view';
import ModalCustomerEdit from './engagement-post/post-edit';
import ModalCustomerQuotation from '../../components/create-quotation/index';
import ViewCustomerQuotation from '../../components/view-quotation/index.js';
import ApproveCustomerQuotation from '../../components/view-quotation/modal-approve-quotation.js';
import ModalCustomerReject from '../../components/view-quotation/modal-reject-quotation.js';
import EditCustomerQuotation from '../../components/edit-quotation/index';
import PrintTest from '../../components/print-report/print-quotation';

import stylesIndex from './css/index.css';
const comId = localStorage.getItem('comId');

export default (props) => {
  const componentRef = useRef();

  const [initialLoad, setInitialLoad] = useState(true);
  const [initialPostLoad, setInitialPostLoad] = useState(true);
  const [initialCustomerLoad, setInitialCustomerLoad] = useState(true);
  const [initialMemberLoad, setInitialMemberLoad] = useState(true);
  const [loadingTimeline, setLoadingTimeline] = useState(true);
  const [tabs, setTabs] = useState('customer'); // 'customer' or 'postBy'
  const [customers, setCustomers] = useState([]);
  const [filterCustomer, setFilterCustomer] = useState([]);
  const [customerCheckAll, setCustomerCheckAll] = useState(true);
  const [customerIndeterminate, setCustomerIndeterminate] = useState(false);
  const [customerIsFilter, setCustomerIsFilter] = useState(false);
  const [customerCountChecked, setCustomerCountChecked] = useState(0);
  const [customerCountAll, setCustomerCountAll] = useState(0);
  const [customerSearch, setCustomerSearch] = useState();
  const [customerOrganizationSelect, setCustomerOrganizationSelect] = useState();
  const [customerActivitySelect, setCustomerActivitySelect] = useState();
  const [customerPage, setCustomerPage] = useState(1);
  const [customerPageLimit, setCutomerPageLimit] = useState(10);
  const [customerPageLoading, setCustomerPageLoading] = useState(false);
  const [customerPageHasMore, setCustomerPageHasMore] = useState(true);

  const [members, setMembers] = useState([]);
  const [memComIds, setMemComIds] = useState([]);
  const [filterOrganization, setFilterOrganization] = useState([]);
  const [memberCheckAll, setMemberCheckAll] = useState(true);
  const [memberIndeterminate, setMemberIndeterminate] = useState(false);
  const [memberCountChecked, setMemberCountChecked] = useState(0);
  const [memberCountAll, setMemberCountAll] = useState(0);
  const [memberOrganizationSelect, setMemberOrganizationSelect] = useState();
  const [memberPage, setMemberPage] = useState(1);
  const [memberPageLimit, setMemberPageLimit] = useState(20);
  const [memberPageLoading, setMemberPageLoading] = useState(false);
  const [memberPageHasMore, setMemberPageHasMore] = useState(true);

  const [txtSearch, setTxtSearch] = useState('');
  const [rangePicker, setRangePicker] = useState([moment().startOf('month'), moment().endOf('month')]);
  const [customerModules, setCustomerModules] = useState([]);
  const [selectModule, setSelectModule] = useState([]);
  const [selectAction, setSelectAction] = useState('');
  const [sorting, setSorting] = useState('desc');

  const [engagementTimeline, setEngagementTimeline] = useState([]);
  const [visibleAddPost, setVisibleAddPost] = useState(false);
  const [visibleEditPost, setVisibleEditPost] = useState(false);
  const [visibleViewPost, setVisibleViewPost] = useState(false);
  const [postId, setPostId] = useState();

  const [filterCustomerLog, setFilterCustomerLog] = useState([]);
  const [filterPostByLog, setFilterPostByLog] = useState([]);

  const [timelineRefresh, setTimelineRefresh] = useState(false);

  const [visibleAddQuotation, setVisibleAddQuotation] = useState(false);
  const [triggerAPICountPost, setTriggerAPICountPost] = useState(false);

  // --------------------- State Modal Edit Quotation -----------------------------------
  const [visibleEditModal, setVisibleEditModal] = useState(false);
  const [dataEdit, setDataEdit] = useState();
  const [dataView, setDataView] = useState();
  const [dataHeader, setDataHeader] = useState();

  // --------------------- State Modal View Quotation -----------------------------------
  const [visibleViewQuotation, setVisibleViewQuotation] = useState(false);
  const [viewData, setViewData] = useState();
  const [reason, setReason] = useState([]);

  const [visibleModalApprove, setVisibleModalApprove] = useState(false);
  const [visibleModalReject, setVisibleModalReject] = useState(false);
  const [visibleView, setVisibleView] = useState(false);
  const [remark, setRemark] = useState('');
  const [discount, setDiscount] = useState([]);
  const [selectDiscount, setSelectDiscount] = useState();

  useEffect(() => {
    const setData = async () => {
      const dataOrganization = await getAllTeam();
      const orgId = _.get(dataOrganization, '[0].value');

      const [dataModuleFilter] = await Promise.all([getTabs()]);

      let dataFilterDoc = [];
      if (_.isArray(dataOrganization)) {
        dataFilterDoc.push({
          filterCode: 'team',
          filterName: 'Team',
          filterList: dataOrganization,
          filterMode: 'default',
        });
      }

      const customerFilter = _.get(dataModuleFilter, 'customerFilter');
      if (customerFilter) {
        dataFilterDoc.push(...customerFilter);
      }
      // console.log('xxxxxx', _.get(dataModuleFilter, 'customerModules'));

      let getCustomerModules = [];
      if (comId === '29' || comId === 29) {
        getCustomerModules = _.get(dataModuleFilter, 'customerModules').filter((item) => item.moduleCode === 'post');
      } else {
        getCustomerModules = _.get(dataModuleFilter, 'customerModules');
      }
      // const getCustomerModules = _.get(dataModuleFilter, 'customerModules');////all

      // const getCustomerModules = _.get(dataModuleFilter, 'customerModules').filter((item) => item.moduleCode === 'post'); ////post

      const filterSelectModule = _.filter(getCustomerModules, ['selected', true]);
      const findSelectAction = _.find(getCustomerModules, ['selected', true]);

      setFilterCustomer(dataFilterDoc);
      setFilterOrganization(dataOrganization);
      setCustomerModules(getCustomerModules || []);
      setSelectModule(
        _.map(filterSelectModule, (o) => {
          return o.moduleCode;
        })
      );
      // setSelectAction(findSelectAction ? findSelectAction.moduleCode : '');
      // setMemberOrganizationSelect(orgId);
      setInitialLoad(false);
    };

    setData();
  }, []);

  useEffect(() => {
    const setData = async () => {
      if (customerPage >= 1) {
        const checked = checkLogCustomerLastCheckAll();
        const loadmoreCustomer = await getCustomer(
          customerPage,
          customerPageLimit,
          customerOrganizationSelect,
          customerActivitySelect,
          customerCheckAll,
          customerSearch
        );
        if (_.size(loadmoreCustomer.customers) || customerPage === 1) {
          setDataCustomer({ customers: _.concat(customers, loadmoreCustomer.customers), totalCustomer: loadmoreCustomer.totalCustomer });
        } else {
          setCustomerPageHasMore(false);
        }
      }
    };

    setData();
  }, [customerPage, customerSearch, customerOrganizationSelect, customerActivitySelect]);

  useEffect(() => {
    const setData = async () => {
      if (memberPage >= 1) {
        const checked = checkLogPostByLastCheckAll();
        const loadmoreMember = await getMember(memberPage, memberPageLimit, memberOrganizationSelect, checked);
        if (_.size(loadmoreMember.members) || memberPage === 1) {
          setDataMember({
            members: _.concat(members, loadmoreMember.members),
            totalMember: loadmoreMember.totalMember,
            memComIds: loadmoreMember.memComIds,
          });
        } else {
          setMemberPageHasMore(false);
        }

        if (initialMemberLoad) setInitialMemberLoad(false);
      }
    };

    setData();
  }, [memberPage, memberOrganizationSelect]);

  useEffect(() => {
    const setData = async () => {
      if (!initialPostLoad) {
        // setLoadingTimeline(true);
        let filter = {
          customer: {},
          member: {},
          orgId: _.toString(customerOrganizationSelect),
        };

        const findModulePost = _.indexOf(selectModule, 'post');
        const findModuleQuotation = _.indexOf(selectModule, 'quotation');
        if (findModulePost === -1 && findModuleQuotation === -1) {
          setEngagementTimeline([]);
          // setLoadingTimeline(false);
          return;
        }

        // if(tabs === 'customer') {
        if (!customerCheckAll && !customerIndeterminate) {
          // setEngagementTimeline([]);
          // setLoadingTimeline(false);
          // return;
        }

        if (customerCheckAll) {
          filter.customer = {
            type: 'in',
            value: [],
            checkAll: customerCheckAll,
          };
        } else {
          const checked = checkLogCustomerLastCheckAll();
          if (checked) {
            filter.customer = {
              type: 'notin',
              value: getCustomerIdUnchecked(),
              checkAll: customerCheckAll,
            };
          } else {
            filter.customer = {
              type: 'in',
              value: getCustomerIdChecked(),
              checkAll: customerCheckAll,
            };
          }
        }

        // }
        // else if(tabs === 'postBy') {
        if (!memberCheckAll && !memberIndeterminate) {
          // setEngagementTimeline([]);
          // setLoadingTimeline(false);
          // return;
        }

        if (memberCheckAll) {
          filter.member = {
            type: 'notin',
            value: [],
            // value: _.map(memComIds, (o) => { return _.toString(o); })
          };
        } else {
          const checked = checkLogPostByLastCheckAll();
          if (checked) {
            filter.member = {
              type: 'notin',
              value: getMemberIdUnchecked(),
            };
          } else {
            const memberIdChecked = getMemberIdChecked();
            const memberIdCheckedSize = _.size(memberIdChecked);
            filter.member = {
              type: memberIdCheckedSize ? 'in' : 'notin',
              value: memberIdCheckedSize
                ? memberIdChecked
                : _.map(memComIds, (o) => {
                    return _.toString(o);
                  }),
            };
          }
        }
        // }

        if (customerSearch) {
          filter.customer.search = customerSearch;
        }

        const dataEngagementTime = await getEngagementTimeline({
          txtSearch,
          startDate: rangePicker[0].format('YYYY-MM-DD'),
          endDate: rangePicker[1].format('YYYY-MM-DD'),
          filter,
          orderBy: 'created_at',
          orderType: sorting,
          tab: selectModule,
        });
        setEngagementTimeline(_.uniqBy(dataEngagementTime, 'moduleKey'));
        // setLoadingTimeline(false);
      }

      if (initialPostLoad && !initialLoad && !initialCustomerLoad && !initialMemberLoad) setInitialPostLoad(false);
    };

    setData();
  }, [
    initialPostLoad,
    initialLoad,
    customers,
    customerOrganizationSelect,
    members,
    txtSearch,
    rangePicker,
    sorting,
    timelineRefresh,
    selectModule,
  ]);

  useEffect(() => {
    if (visibleAddQuotation === true) {
      const getReasonData = async () => {
        const getData = await getReason();
        const getDefault = await getDefaultStarted();
        setReason(getData);
        setRemark(_.get(getDefault, 'remark'));
        setDiscount(_.get(getDefault, 'dataDiscount')); // selectDiscount
      };
      getReasonData();
    }
  }, [visibleAddQuotation]);

  useEffect(() => {
    const getReasonData = async () => {
      const getData = await getReason();
      setReason(getData);
    };
    getReasonData();
  }, []);

  useEffect(() => {
    const getData = async () => {
      const getDefault = await getDefaultStarted();
      setDiscount(_.get(getDefault, 'dataDiscount'));
    };
    getData();
  }, []);

  const setDataCustomer = ({ customers, totalCustomer }) => {
    let countCustomer = 0;
    const checked = checkLogCustomerLastCheckAll();
    const countCustomerAll = totalCustomer;
    const countCustomerChecked = fncCountMemberChecked(customers);
    const countCustomerUnchecked = fncCountMemberUnchecked(customers);

    if (checked) {
      countCustomer = totalCustomer - countCustomerUnchecked;
    } else {
      countCustomer = countCustomerChecked;
    }

    setCustomers(customers);
    setCustomerCountAll(countCustomerAll);
    setCustomerCountChecked(countCustomer);
    setCustomerPageLoading(true);
    if (initialCustomerLoad) {
      setCustomerCheckAll(true);
      setInitialCustomerLoad(false);
    }
  };

  const setDataMember = ({ members, totalMember, memComIds }) => {
    let countMember = 0;
    const checked = checkLogPostByLastCheckAll();
    const countMemberAll = totalMember;
    const countMemberChecked = fncCountMemberChecked(members);
    const countMemberUnchecked = fncCountMemberUnchecked(members);

    if (checked) {
      countMember = totalMember - countMemberUnchecked;
    } else {
      countMember = countMemberChecked;
    }

    setMemComIds(memComIds);
    setMembers(members);
    setMemberCountAll(countMemberAll);
    setMemberCountChecked(countMember);
    setMemberPageLoading(true);
  };

  const handleChangeTabs = (activeKey) => {
    setTabs(activeKey);
  };

  const handleCheckAll = (checked) => {
    let customerDoc = [...customers];
    let customerSelectDoc = customerDoc.map((o) => {
      let obj = { ...o };
      obj.selected = checked;
      return obj;
    });

    const countCustomer = checked ? customerCountAll : 0;

    setCustomerIndeterminate(false);
    setCustomerCheckAll(checked);
    setCustomers(customerSelectDoc);
    setCustomerCountChecked(countCustomer);
    handleChangeFilterCustomer([{ key: 'checkAll', value: checked }]);
  };

  const handleCheckMemberAll = (checked) => {
    let memberDoc = [...members];
    let memberSelectDoc = memberDoc.map((o) => {
      let obj = { ...o };
      obj.selected = checked;
      return obj;
    });

    const countMember = checked ? memberCountAll : 0;

    setMemberIndeterminate(false);
    setMemberCheckAll(checked);
    setMembers(memberSelectDoc);
    setMemberCountChecked(countMember);
    handleChangeFilterPostBy([{ key: 'checkAll', value: checked }]);
  };

  const handleChangeTxtSearch = (value) => {
    setTxtSearch(value);
  };

  const handleChangeRangePicker = (dates, dateStrings) => {
    setRangePicker(dates);
  };

  const handleSelectAction = (activeKeys) => {
    setSelectAction(activeKeys);
  };

  const handleSelectModule = (activeKeys) => {
    setSelectModule(activeKeys);
  };

  const handleSelectSorting = (activeKey) => {
    setSorting(activeKey);
  };

  const handleChangeSelectCustomer = (customerId, newPostStatus = null) => {
    let customerDoc = [...customers];

    const findIndexCustomer = _.findIndex(customerDoc, ['customerId', customerId]);
    if (findIndexCustomer > -1) {
      if (customerCheckAll) {
        _.forEach(customerDoc, (o, i) => {
          customerDoc[i].selected = i === findIndexCustomer ? true : false;
        });
      } else {
        customerDoc[findIndexCustomer].selected = newPostStatus ? true : !customerDoc[findIndexCustomer].selected;
      }
    }

    let countCustomer = 0;
    let checkedAll = false;
    let checkIndeterminate = false;
    const checked = checkLogCustomerLastCheckAll();
    const countCustomerChecked = fncCountCustomerChecked(customerDoc);
    const countCustomerUnchecked = fncCountCustomerUnchecked(customerDoc);

    if (checked) {
      countCustomer = countCustomerChecked;
      checkedAll = countCustomerUnchecked === 0 ? true : false;
      checkIndeterminate = countCustomerChecked === 0 ? false : checkedAll ? false : true;
    } else {
      countCustomer = countCustomerChecked;
      checkedAll = countCustomerChecked === customerCountAll ? true : false;
      checkIndeterminate = checkedAll ? false : countCustomerChecked === 0 ? false : true;
    }

    setCustomers(customerDoc);
    setCustomerCheckAll(checkedAll);
    setCustomerCountChecked(countCustomer);
    setCustomerIndeterminate(checkIndeterminate);
    handleChangeFilterCustomer([{ key: 'checkAll', value: checkedAll }]);
  };

  const handleChangeSelectMember = (memComId) => {
    let memberDoc = [...members];

    const findIndexMember = _.findIndex(memberDoc, ['memComId', memComId]);
    if (findIndexMember > -1) {
      if (memberCheckAll) {
        _.forEach(memberDoc, (o, i) => {
          memberDoc[i].selected = i === findIndexMember ? true : false;
        });
      } else {
        memberDoc[findIndexMember].selected = !memberDoc[findIndexMember].selected;
      }
    }

    let countMember = 0;
    let checkedAll = false;
    let checkIndeterminate = false;
    const checked = checkLogPostByLastCheckAll();
    const countMemberChecked = fncCountMemberChecked(memberDoc);
    const countMemberUnchecked = fncCountMemberUnchecked(memberDoc);

    if (checked) {
      countMember = countMemberChecked;
      checkedAll = countMemberUnchecked === 0 ? true : false;
      checkIndeterminate = countMemberChecked === 0 ? false : checkedAll ? false : true;
    } else {
      countMember = countMemberChecked;
      checkedAll = countMemberChecked === memberCountAll ? true : false;
      checkIndeterminate = checkedAll ? false : countMemberChecked === 0 ? false : true;
    }

    setMembers(memberDoc);
    setMemberCheckAll(checkedAll);
    setMemberCountChecked(countMember);
    setMemberIndeterminate(checkIndeterminate);
    handleChangeFilterPostBy([{ key: 'checkAll', value: checkedAll }]);
  };

  const handleSearchCustomer = _.debounce((value) => {
    setCustomers([]);
    setCustomerPage(1);
    setCustomerPageHasMore(true);
    setCustomerCheckAll(true);
    setCustomerIndeterminate(false);
    setCustomerIsFilter(value || customerActivitySelect || customerOrganizationSelect ? true : false);
    setCustomerSearch(value);
    handleChangeFilterCustomer([{ key: 'checkAll', value: true }]);
  }, 1000);

  const handleSelectCustomerOrganization = (activeKey) => {
    setCustomers([]);
    setCustomerPage(1);
    setCustomerPageHasMore(true);
    setCustomerCheckAll(true);
    setCustomerIndeterminate(false);
    setCustomerIsFilter(activeKey || customerSearch || customerActivitySelect ? true : false);
    setCustomerOrganizationSelect(activeKey);
    handleChangeFilterCustomer([{ key: 'checkAll', value: true }]);
  };

  const handleSelectCustomerActivity = (activeKey) => {
    setCustomers([]);
    setCustomerPage(1);
    setCustomerPageHasMore(true);
    setCustomerCheckAll(true);
    setCustomerIndeterminate(false);
    setCustomerIsFilter(activeKey || customerSearch || customerOrganizationSelect ? true : false);
    setCustomerActivitySelect(activeKey);
    handleChangeFilterCustomer([{ key: 'checkAll', value: true }]);
  };

  const handleSelectMemberOrganization = (activeKey) => {
    setMembers([]);
    setMemberPage(1);
    setMemberPageHasMore(true);
    setMemberCheckAll(true);
    setMemberIndeterminate(false);
    setMemberOrganizationSelect(activeKey);
    handleChangeFilterPostBy([{ key: 'checkAll', value: true }]);
  };

  const fncCountCustomerChecked = (customerSelectDoc) => {
    return _.sumBy(customerSelectDoc, (o) => {
      return o.selected ? 1 : 0;
    });
  };

  const fncCountCustomerUnchecked = (customerSelectDoc) => {
    return _.sumBy(customerSelectDoc, (o) => {
      return !o.selected ? 1 : 0;
    });
  };

  const fncCountMemberChecked = (membersSelectDoc) => {
    return _.sumBy(membersSelectDoc, (o) => {
      return o.selected ? 1 : 0;
    });
  };

  const fncCountMemberUnchecked = (membersSelectDoc) => {
    return _.sumBy(membersSelectDoc, (o) => {
      return !o.selected ? 1 : 0;
    });
  };

  const handleChangeCustomerPage = (page) => {
    if (customerPageLoading && customerPageHasMore) {
      setCustomerPage(customerPage + 1);
      setCustomerPageLoading(false);
    }
  };

  const handleChangeMemberPage = (page) => {
    if (memberPageLoading && memberPageHasMore) {
      setMemberPage(memberPage + 1);
      setMemberPageLoading(false);
    }
  };

  const handleOpenModalModuleAction = (key) => {
    if (selectAction === 'post' || key === 'post') setVisibleAddPost(true);
    else if (selectAction === 'quotation' || key === 'quotation') setVisibleAddQuotation(true);
  };

  const handleOkModalAddPost = () => {
    setVisibleAddPost(false);
    setSelectAction('');
  };

  const handleCancelModalAddPost = () => {
    setVisibleAddPost(false);
  };

  const handleChangeFilterCustomer = (logs) => {
    setFilterCustomerLog([...filterCustomerLog, ...logs]);
  };

  const handleChangeFilterPostBy = (logs) => {
    setFilterPostByLog([...filterPostByLog, ...logs]);
  };

  const checkLogCustomerLastCheckAll = () => {
    const getLastIndexCheckAll = _.findLastIndex(filterCustomerLog, { key: 'checkAll', value: true });
    const getLastIndexUnCheckAll = _.findLastIndex(filterCustomerLog, { key: 'checkAll', value: false });
    return getLastIndexUnCheckAll > getLastIndexCheckAll ? false : true;
  };

  const checkLogPostByLastCheckAll = () => {
    const getLastIndexCheckAll = _.findLastIndex(filterPostByLog, { key: 'checkAll', value: true });
    const getLastIndexUnCheckAll = _.findLastIndex(filterPostByLog, { key: 'checkAll', value: false });
    return getLastIndexUnCheckAll > getLastIndexCheckAll ? false : true;
  };

  const handleOpenViewModuleAction = (moduleCode, id) => {
    if (moduleCode === 'post') {
      setPostId(id);
      setVisibleViewPost(true);
    }
  };

  const handleOkModalViewPost = () => {
    setPostId();
    setVisibleViewPost(false);
  };

  const handleCancelModalViewPost = () => {
    setPostId();
    setVisibleViewPost(false);
  };

  const handleOpenEditModuleAction = (moduleCode, id) => {
    if (moduleCode === 'post') {
      setPostId(id);
      setVisibleEditPost(true);
    }
  };
  const handleOkModalEditPost = () => {
    setPostId();
    setVisibleEditPost(false);
  };

  const handleCancelModalEditPost = () => {
    setPostId();
    setVisibleEditPost(false);
  };

  const handleDeletedModuleAction = async (moduleCode, id) => {
    if (moduleCode === 'post') {
      const result = await deletePostOne(id);
      if (result.success) {
        let engagementTimelineDoc = [...engagementTimeline];
        _.remove(engagementTimelineDoc, (o) => {
          return o.postId === id ? true : false;
        });
        setEngagementTimeline(_.uniqBy(engagementTimelineDoc, 'postId'));
      }
      return result;
    }
  };

  const handleTimelineRefresh = () => {
    setTimelineRefresh((current) => !current);
  };

  const getCustomerIdChecked = () => {
    const filterCustomer = _.filter(customers, ['selected', true]);
    return _.map(filterCustomer, (o) => {
      return o.customerId;
    });
  };

  const getCustomerIdUnchecked = () => {
    const filterCustomer = _.filter(customers, ['selected', false]);
    return _.map(filterCustomer, (o) => {
      return o.customerId;
    });
  };

  const getMemberIdChecked = () => {
    const filterMember = _.filter(members, ['selected', true]);
    return _.map(filterMember, (o) => {
      return _.toString(o.memComId);
    });
  };

  const getMemberIdUnchecked = () => {
    const filterMember = _.filter(members, ['selected', false]);
    return _.map(filterMember, (o) => {
      return _.toString(o.memComId);
    });
  };

  const handleOkModalAddQuotation = () => {
    setVisibleAddQuotation(false);
  };

  const handleViewQuotationMain = (record, comInfo) => {
    if (record) {
      setDataView(record);
      setDataHeader(comInfo);
      setVisibleViewQuotation(true);
    }
  };

  const handleEditQuotationMain = (record) => {
    if (record) {
      setRemark(record.remark);
      setDataEdit(record);
      setVisibleEditModal(true);
    }
  };

  const handleApprovedMain = (record) => {
    setViewData(record);
    setVisibleModalApprove(true);
  };

  const handleRejectMain = (record) => {
    // console.log('handleRejectMain', record)
    setViewData(record);
    setVisibleModalReject(true);
  };

  const hanndleDeleteMain = (langConFirmDelete, record) => {
    deleteQuotation(langConFirmDelete, record, handleTimelineRefresh);
  };

  const hanndleCancelStatusMain = (langConFirmCancel, record) => {
    cancelQutation(langConFirmCancel, record, handleTimelineRefresh);
  };

  const handlePrintMain = (record, comInfo) => {
    if (record) {
      setDataView(record);
      setDataHeader(comInfo);
    }
  };

  return (
    <PageEngagement.Provider
      value={{
        initialLoad,
        loadingTimeline,
        tabs,
        customers,
        filterCustomer,
        customerCheckAll,
        customerIndeterminate,
        customerIsFilter,
        customerCountChecked,
        customerCountAll,
        members,
        filterOrganization,
        memberCheckAll,
        memberIndeterminate,
        memberCountChecked,
        memberCountAll,
        memberOrganizationSelect,
        txtSearch,
        rangePicker,
        customerModules,
        selectModule,
        selectAction,
        sorting,
        customerPage,
        customerPageLoading,
        customerPageHasMore,
        memberPage,
        memberPageLoading,
        memberPageHasMore,
        engagementTimeline,
        visibleAddPost,
        visibleEditPost,
        visibleViewPost,
        postId,
        componentRef,
        discount,
        selectDiscount,
        triggerAPICountPost,

        fnc: {
          handleChangeTabs,
          handleCheckAll,
          handleChangeTxtSearch,
          handleChangeRangePicker,
          handleSelectModule,
          handleSelectAction,
          handleSelectSorting,
          handleSearchCustomer,
          handleSelectCustomerOrganization,
          handleSelectCustomerActivity,
          handleChangeSelectCustomer,
          handleSelectMemberOrganization,
          handleCheckMemberAll,
          handleChangeSelectMember,
          handleChangeCustomerPage,
          handleChangeMemberPage,
          handleOpenModalModuleAction,
          handleOkModalAddPost,
          handleCancelModalAddPost,
          handleOpenViewModuleAction,
          handleOkModalViewPost,
          handleCancelModalViewPost,
          handleOpenEditModuleAction,
          handleOkModalEditPost,
          handleCancelModalEditPost,
          handleDeletedModuleAction,
          handleTimelineRefresh,
          handleViewQuotationMain,
          handleEditQuotationMain,
          handleApprovedMain,
          handleRejectMain,
          hanndleDeleteMain,
          hanndleCancelStatusMain,
          handlePrintMain,
          setSelectDiscount,
          setTriggerAPICountPost,
          setVisibleAddPost,
        },
      }}
    >
      <Row gutter={[16, 16]}>
        <Col span={6}>
          <EngagementFilter />
        </Col>
        <Col span={18}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <EngagementAction />
            </Col>
            <Col span={24}>
              <EngagementTimeline />
            </Col>
          </Row>
        </Col>
      </Row>

      <ModalCustomerPost />
      <ModalCustomerView />
      <ModalCustomerEdit />

      <ModalCustomerQuotation
        visibleModal={visibleAddQuotation}
        setVisibleModal={handleOkModalAddQuotation}
        setTriggerApi={handleTimelineRefresh}
        remark={remark}
        setRemark={setRemark}
        onCustomerSelect={handleChangeSelectCustomer}
      />

      <EditCustomerQuotation
        visibleModal={visibleEditModal}
        setVisibleModal={setVisibleEditModal}
        setTriggerApi={handleTimelineRefresh}
        dataEdit={dataEdit}
        remark={remark}
        setRemark={setRemark}
      />

      <ViewCustomerQuotation
        componentName="quotation-post"
        visible={visibleViewQuotation}
        setVisible={setVisibleViewQuotation}
        setTriggerApi={handleTimelineRefresh}
        data={dataView}
        dataHeader={dataHeader}
        setTriggerAPICountPost={setTriggerAPICountPost}
      />

      <ApproveCustomerQuotation
        visible={visibleModalApprove}
        setVisible={setVisibleModalApprove}
        setVisibleView={setVisibleViewQuotation}
        setTriggerApi={handleTimelineRefresh}
        data={viewData}
      />

      <ModalCustomerReject
        visible={visibleModalReject}
        setVisible={setVisibleModalReject}
        setVisibleView={setVisibleViewQuotation}
        setTriggerApi={handleTimelineRefresh}
        data={viewData}
        reason={reason}
      />

      {dataView && dataHeader ? (
        <PrintTest ref={componentRef} data={dataView} dataHeader={dataHeader} selectDiscount={selectDiscount} />
      ) : null}
    </PageEngagement.Provider>
  );
};
