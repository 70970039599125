import React, { useContext } from 'react';
import { Select, Form } from 'antd'
import _ from 'lodash';
import { TaskDetailContext } from '../context';
import LabeRequireForm from '../../label-required-form';
import { useIntl } from 'react-intl';

const { Option } = Select

const ModalTaskDetailTeam = () => {
  const { state } = useContext(TaskDetailContext);
  const { detailData, orgData } = state
  const information = _.get(detailData, 'information')
  const intl = useIntl()

  return (
    <Form style={{ padding: '24px 12px 12px 12px' }} layout="vertical" className="taskDetailForm">
      <Form.Item
        label={
          <LabeRequireForm
            text={intl.formatMessage({ id: 'taskDetailTeamText', defaultMessage: 'Team' })}
            req={true}
          />
        }
      >
        <Select
          style={{ width: '100%' }}
          value={_.get(information, 'information.orgId')}
          disabled={true}
        >
          {
            orgData && orgData.map(item =>
              <Option key={item.orgId} value={item.orgId}>
                {item.orgName}
              </Option>
            )
          }
        </Select>
      </Form.Item>
    </Form>
  )
}

export default ModalTaskDetailTeam
