import httpClient from '../../components/axiosClient';

const addRequestOption = async (body) => {

  const comCode = localStorage.getItem('comCode');

  try {
    const response = await httpClient.post(`/v1/warehouse/manager/company/${comCode}/mobile/menu-options`, body);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return error.response;
  }
}

const editRequestOption = async (id, body) => {

  const comCode = localStorage.getItem('comCode');

  try {
    const response = await httpClient.put(`/v1/warehouse/manager/company/${comCode}/mobile/menu-options/${id}`, body);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return error.response;
  }
}

const deleteRequestOption = async (id) => {

  const comCode = localStorage.getItem('comCode');

  try {
    const response = await httpClient.delete(`/v1/warehouse/manager/company/${comCode}/mobile/menu-options/${id}`);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return error.response;
  }
}

const getRequestOption = async (id) => {

  const comCode = localStorage.getItem('comCode');

  try {
    const response = await httpClient.get(`/v1/warehouse/manager/company/${comCode}/web/itemmaster/topicoption/${id}`);
    if (response.status === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {
    return error.response.data;
  }
}


export { addRequestOption, editRequestOption, deleteRequestOption, getRequestOption }
