import React, { useEffect, useState } from 'react';
import { Card, Modal } from 'antd';
import {
  Preview01,
  Preview02,
  Preview03,
  Preview04,
  Preview05,
  PreviewPlus,
} from './preview-picture';
import PostHeader from './post-header';
import PostMessage from './post-message';
import PostAddress from './post-address';
import PostTags from './post-tags.js';
import _ from 'lodash';
import { useIntl } from 'react-intl';
import { successNotification, errorNotification } from '../v2/notification';
import ReactBnbGallery from 'react-bnb-gallery';
import stylePost from './css/post.css';
import { getPostViewData, deletePostData } from '../../controllers/task/get-note';
import Button02 from '../v2/button_02';
import Button01 from '../v2/button_01';
import { FormattedMessage } from 'react-intl';

export default (props) => {
  const { id, setPostData, postData ,visible ,setVisible ,setEditPostData ,setTrigger } = props;
  const intl = useIntl();
  const [OpenGallery, setOpenGallery] = useState(false)
  const [galleryImg, setGalleryImg] = useState([])
  const [numberImage, setNumberImage] = useState(0)


  const handleHashtag = (data) => {
    // fnc.handleChangeTxtSearch(data);
  }

  const handleAddress = (data) => {
    if (data) {
      window.open(`https://www.google.com/maps/search/?api=1&query=${_.get(data, 'addressLat')}, ${_.get(data, 'addressLng',)}`, '_blank');
    }
  }

  const handlePreviewImage = (page, data, itemData) => {
    // fnc.handleOpenViewModuleAction('post', postData.postId);
    const mapPhoto = data && _.size(data) !== 0 ? data.map((item, index) => {
      return {
        photo: _.get(item, 'uri'),
        number: index,
        caption: _.get(item, 'descriptionPhoto'),
        subcaption: `Noted By ${_.get(itemData, 'member.fullname')}`,
        thumbnail: _.get(item, 'uri'),
      }
    }
    ) : []
    setGalleryImg(mapPhoto)
    setOpenGallery(true)
    setNumberImage(page)
  }

  const handleEditPost = (data) => {
    setVisible(true)
    setEditPostData(data)
  }

  const handleDeletePost = (data) => {
    Modal.confirm({
      className: 'maintenance-report-modal-confirm',
      centered: true,
      icon: null,
      title: intl.formatMessage({ id: 'lblDelete', defaultMessage: 'Are you sure you want to delete ?' }),
      okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        const response = await deletePostData(_.get(data, 'noteId'));
        if (response.status === 200) {
          successNotification(response.data.message)
          setTrigger(current => !current)
        }
        else {
          errorNotification(response.data.message)
        }
      },
      onCancel() {
      },
    });
  }

  const handleTags = (data) => {
    if (data) {

      let isPDF = /pdf/g.test(data.originalname.toLowerCase());
      if (isPDF) return window.open(data.uri, '_blank');
    }
  }

  const fncPreview = (imageList, item) => {
    let sum = imageList.length;
    switch (sum) {
      case 1:
        return <Preview01 imageList={imageList} handlePreviewImage={handlePreviewImage} itemData={item} />;
      case 2:
        return <Preview02 imageList={imageList} handlePreviewImage={handlePreviewImage} itemData={item} />;
      case 3:
        return <Preview03 imageList={imageList} handlePreviewImage={handlePreviewImage} itemData={item} />;
      case 4:
        return <Preview04 imageList={imageList} handlePreviewImage={handlePreviewImage} itemData={item} />;
      case 5:
        return <Preview05 imageList={imageList} handlePreviewImage={handlePreviewImage} itemData={item} />;
      default:
        return <PreviewPlus imageList={imageList} handlePreviewImage={handlePreviewImage} itemData={item} />;
    }

  };





  const handleNewPost = () => {
    // setVisibleAddPost(true)
  }

  const handleCancel = () => {
    // setVisible(false)
  }

  return (
    <div>
      {
        postData && postData.map(item => (
          <div className='post'>
            <Card className='post-card'>
              <div className='post-card-body'>
                <PostHeader postData={item} postAction={item.action} handleEditPost={handleEditPost} handleDeletePost={handleDeletePost} />
                <div className='post-card-create-post'>
                  <PostMessage postData={item} handleHashtag={handleHashtag} />
                  {item && item.address !== '' && item.address !== undefined && item.address !== null ? (
                    <PostAddress postData={item} handleAddress={handleAddress} />
                  ) : null}
                  <PostTags postData={_.get(item, 'attachmentsFile')} handleTags={handleTags} />
                </div>

                <div>{postData ? fncPreview(item.attachmentsPhoto, item) : null}</div>
                <ReactBnbGallery
                  show={OpenGallery}
                  photos={galleryImg}
                  onClose={() => setOpenGallery(false)}
                  activePhotoIndex={numberImage}
                />
              </div>
            </Card >
          </div >
        )
        )
      }
    </div>

  );
};
