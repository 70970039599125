import httpClient from '../../components/axiosClient';

const getPostViewData = async (taskId, subTaskWfId) => {
  const comId = localStorage.getItem('comId');

  try {
    const response = await httpClient.get(`/v3/task/manager/company/${comId}/notedetail/getdata?taskId=${taskId}&subTaskWfId=${subTaskWfId}`);
    if (response.status === 200) {
      return (response.data);
    } else {
      return ([]);
    }
  } catch (error) {
    return ([]);
  }
}

const postTaskData = async (data) => {
  const comId = localStorage.getItem('comId');

  try {
    const response = await httpClient.post(`/v3/task/manager/company/${comId}/notedetail/createdata`, data);
    if (response.status === 200) {
      return (response.data);
    } else {
      return ([]);
    }
  } catch (error) {
    return ([]);
  }
}

const putTaskData = async (id, data) => {
  const comId = localStorage.getItem('comId');

  try {
    const response = await httpClient.put(`/v3/task/manager/company/${comId}/notedetail/updatedata/${id}`, data);
    if (response.status === 200) {
      return (response.data);
    } else {
      return ([]);
    }
  } catch (error) {
    return ([]);
  }
}

const postTaskDataImageFile = async (id, data) => {
  const comId = localStorage.getItem('comId');

  try {
    const response = await httpClient.put(`/v3/task/manager/company/${comId}/notedetail/updatedata/photo/${id}`, data);
    if (response.status === 200) {
      return (response);
    } else {
      return ([]);
    }
  } catch (error) {
    return ([]);
  }
}

const deletePostData = async (id, data) => {
  const comId = localStorage.getItem('comId');

  try {
    const response = await httpClient.delete(`/v3/task/manager/company/${comId}/notedetail/deletedata/${id}`);
    if (response.status === 200) {
      return (response);
    } else {
      return ([]);
    }
  } catch (error) {
    return ([]);
  }
}

const deleteFile = async (id, filehash) => {
  const comId = localStorage.getItem('comId');

  try {
    const response = await httpClient.delete(`/v3/task/manager/company/${comId}/notedetail/deletedata/${id}/filehash/${filehash}`);
    if (response.status === 200) {
      return (response);
    } else {
      return ([]);
    }
  } catch (error) {
    return ([]);
  }
}

export { getPostViewData, postTaskData, postTaskDataImageFile, deletePostData, putTaskData, deleteFile };
