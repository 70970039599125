import { Col, Form, Icon, Input, Row, Select } from 'antd';
import React, { useEffect } from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import './style/collaspe.css';
import _ from 'lodash';

const CollapseTaskDetail = (props) => {
  const { label = '', children, extra, setIsCollapse } = props;
  const intl = useIntl();
  const ref = useRef();
  const [isOpen, setIsOpen] = useState(true);


  useEffect(() => {
    if (_.isFunction(setIsCollapse)) {
      setIsCollapse(isOpen);
    }
  }, [isOpen]);

  return (
    <div>
      <div className="collapse-layout">
        <div className="collapse-label">{label}</div>
        <div>
          {extra ? extra : null}{' '}
          <Icon style={{ cursor: 'poniter' }} type={isOpen ? 'up' : 'down'} onClick={() => setIsOpen((current) => !current)} />
        </div>
      </div>
      <div className="collapse-parent" ref={ref} style={isOpen ? { height: 'auto' } : { height: '0px' }}>
        <div>{children}</div>
      </div>
    </div>
  );
};
export default CollapseTaskDetail;
