import React, { useContext } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Form, Row, Col, Input, DatePicker, Select } from 'antd';
import CollapseCustom from '../../components/collapse-custom';
import ActionColumnPO from './action-column';
import LabeRequireForm from '../../components/label-required-form';
import _ from 'lodash';
import './index.css';
import { PurchaseContext } from './po-context';
import Button_01 from '../../components/v2/button_01';
import Button_02 from '../../components/v2/button_02';

const { Option } = Select;
const { RangePicker } = DatePicker;

const PurchaseOrderAction = () => {
  const props = useContext(PurchaseContext);
  const {
    setSearchGroup,
    setPaginationPage,
    searchGroup,
    columns,
    setShowColumn,
    newDataColumns,
    listArrayColumns,
    defaultShowColumn,
    newColumns,
    setNewDataColumns,
    setListArrayColumns,
    setDefaultShowColumn,
    textErrorSelectColumn,
    setTextErrorSelectColumn,
    vendorData,
    statusList,
    keyTabMonitor,
    visible,
    setVisible,
    setTrigger,
    actionColumns,
    optionsTeam,
    optionsIssueBy,
  } = props;
  const intl = useIntl();

  console.log('optionss', optionsTeam, optionsIssueBy, keyTabMonitor);

  const onChange = (value, code) => {
    setSearchGroup({ ...searchGroup, [code]: value });
    // setPaginationPage(1);
  };

  const onApply = () => {
    setPaginationPage(1);
    setTrigger((cur) => !cur);
  };

  const onReset = () => {
    setSearchGroup({});
    setPaginationPage(1);
    setTrigger((cur) => !cur);
  };

  const formFilter = () => {
    return (
      <Form colon={false} className="po-form-filter">
        {keyTabMonitor !== 'draft' ? (
          <>
            <Row gutter={[24, 2]}>
              <Col span={8}>
                <Form.Item
                  label={
                    <LabeRequireForm text={intl.formatMessage({ id: 'purchesOrderFormPONo', defaultMessage: 'PO No.' })} req={false} />
                  }
                >
                  <Input
                    allowClear
                    value={_.get(searchGroup, 'poNo')}
                    onChange={(e) => onChange(e.target.value, 'poNo')}
                    placeholder={intl.formatMessage({ id: 'purchesOrderFormPONoPlaceholder', defaultMessage: 'Enter PO No.' })}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label={
                    <LabeRequireForm
                      text={intl.formatMessage({ id: 'purchesOrderFormVendorOrSup', defaultMessage: 'Vendor or Supplier' })}
                      req={false}
                    />
                  }
                >
                  <Select
                    mode="multiple"
                    allowClear
                    onChange={(e) => onChange(e, 'vendor')}
                    value={_.get(searchGroup, 'vendor')}
                    maxTagCount={2}
                    placeholder={intl.formatMessage({
                      id: 'purchesOrderFormVendorOrSupPlaceholder',
                      defaultMessage: 'Enter Vendor or Supplier',
                    })}
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    showSearch
                  >
                    {vendorData && vendorData.map((item) => <Option key={item.customerId}>{item.customerName}</Option>)}
                  </Select>
                </Form.Item>
              </Col>
              {keyTabMonitor === 'approved' || keyTabMonitor === 'closed' ? (
                <Col span={8}>
                  <Form.Item
                    label={
                      <LabeRequireForm
                        text={intl.formatMessage({ id: 'purchesOrderFormPaymentStatus', defaultMessage: 'Payment Status' })}
                        req={false}
                      />
                    }
                  >
                    <Select
                      mode="multiple"
                      allowClear
                      value={_.get(searchGroup, 'status')}
                      onChange={(e) => onChange(e, 'status')}
                      maxTagCount={2}
                      placeholder={intl.formatMessage({ id: 'purchesOrderFormPONoPlaceholder', defaultMessage: 'Select Payment Status' })}
                      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      showSearch
                    >
                      {statusList && statusList.map((item) => <Option key={item.statusCode}>{item.stautsTxt}</Option>)}
                    </Select>
                  </Form.Item>
                </Col>
              ) : (
                <Col span={8}>
                  <Form.Item
                    label={
                      <LabeRequireForm
                        text={intl.formatMessage({ id: 'purchesOrderFormReferenceNo', defaultMessage: 'Reference No.' })}
                        req={false}
                      />
                    }
                  >
                    <Input
                      allowClear
                      value={_.get(searchGroup, 'refNo')}
                      onChange={(e) => onChange(e.target.value, 'refNo')}
                      placeholder={intl.formatMessage({
                        id: 'purchesOrderFormReferenceNoPlaceholder',
                        defaultMessage: 'Enter Reference No.',
                      })}
                    />
                  </Form.Item>
                </Col>
              )}

             
            {/* </Row>
            <Row gutter={[24, 2]}> */}
              {keyTabMonitor === 'approved' || keyTabMonitor === 'closed' ? (
                <Col span={8}>
                  <Form.Item
                    label={
                      <LabeRequireForm
                        text={intl.formatMessage({ id: 'purchesOrderFormReferenceNo', defaultMessage: 'Reference No.' })}
                        req={false}
                      />
                    }
                  >
                    <Input
                      allowClear
                      value={_.get(searchGroup, 'refNo')}
                      onChange={(e) => onChange(e.target.value, 'refNo')}
                      placeholder={intl.formatMessage({
                        id: 'purchesOrderFormReferenceNoPlaceholder',
                        defaultMessage: 'Enter Reference No.',
                      })}
                    />
                  </Form.Item>
                </Col>
              ) : null}
              {/* <Col span={8}>
                <Form.Item
                  label={
                    <LabeRequireForm
                      text={intl.formatMessage({ id: 'purchesOrderFormCreateDate', defaultMessage: 'Created Date' })}
                      req={false}
                    />
                  }
                >
                  <RangePicker
                    allowClear
                    style={{ width: '100%' }}
                    value={_.get(searchGroup, 'createDate')}
                    format={['DD/MM/YYYY', 'DD/MM/YYYY']}
                    onChange={(e) => onChange(e, 'createDate')}
                    placeholder={[
                      intl.formatMessage({ id: 'purchesOrderFormFromDatePlaceholder', defaultMessage: 'Select Form Date' }),
                      intl.formatMessage({ id: 'purchesOrderFormToDatePlaceholder', defaultMessage: 'Select To Date' }),
                    ]}
                  />
                </Form.Item>
              </Col> */}

{/* ///////////////////////////////////////////////////////////////////////////////////////////////////////// */}
              <Col span={8}>
                <Form.Item
                  label={
                    <LabeRequireForm text={intl.formatMessage({ id: 'purchesOrderFormTeam', defaultMessage: 'Team' })} req={false} />
                  }
                >
                  <Select
                    showSearch
                    allowClear={true}
                    onChange={(value) => onChange(value, 'team')}
                    filterOption={(input, option) => option.props.children.toLowerCase().includes(input.toLowerCase())}
                    value={_.get(searchGroup, 'team') || undefined}
                    placeholder={intl.formatMessage({ id: 'purchesOrderFormTeamPlaceholder', defaultMessage: 'Select Team' })}
                  >
                    {_.map(optionsTeam, (item) => (
                      <Option key={item.name} value={item.org_id}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label={
                    <LabeRequireForm
                      text={intl.formatMessage({ id: 'purchesOrderFormIssuedBy', defaultMessage: 'Issued By' })}
                      req={false}
                    />
                  }
                >
                  <Select
                    showSearch
                    allowClear={true}
                    onChange={(value) => onChange(value, 'issuedBy')}
                    filterOption={(input, option) => option.props.children.toLowerCase().includes(input.toLowerCase())}
                    value={_.get(searchGroup, 'issuedBy') || undefined}
                    placeholder={intl.formatMessage({ id: 'purchesOrderFormissuedByPlaceholder', defaultMessage: 'Select Issued By' })}
                  >
                    {_.map(optionsIssueBy, (item) => (
                      <Option value={item.memComId}>{item.fullname}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label={
                    <LabeRequireForm
                      text={intl.formatMessage({ id: 'purchesOrderFormIssuedDate', defaultMessage: 'Issued Date' })}
                      req={false}
                    />
                  }
                >
                  <RangePicker
                    allowClear
                    style={{ width: '100%' }}
                    value={_.get(searchGroup, 'issuedDate')}
                    format={['DD/MM/YYYY', 'DD/MM/YYYY']}
                    onChange={(e) => onChange(e, 'issuedDate')}
                    placeholder={[
                      intl.formatMessage({ id: 'purchesOrderFormIssuedFromDatePlaceholder', defaultMessage: 'Select Form Date' }),
                      intl.formatMessage({ id: 'purchesOrderFormIssuedToDatePlaceholder', defaultMessage: 'Select To Date' }),
                    ]}
                  />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label={
                    <LabeRequireForm
                      text={intl.formatMessage({ id: 'purchesOrderFormCreateDate', defaultMessage: 'Created Date' })}
                      req={false}
                    />
                  }
                >
                  <RangePicker
                    allowClear
                    style={{ width: '100%' }}
                    value={_.get(searchGroup, 'createDate')}
                    format={['DD/MM/YYYY', 'DD/MM/YYYY']}
                    onChange={(e) => onChange(e, 'createDate')}
                    placeholder={[
                      intl.formatMessage({ id: 'purchesOrderFormFromDatePlaceholder', defaultMessage: 'Select Form Date' }),
                      intl.formatMessage({ id: 'purchesOrderFormToDatePlaceholder', defaultMessage: 'Select To Date' }),
                    ]}
                  />
                </Form.Item>
              </Col>
              {keyTabMonitor !== 'approved' || keyTabMonitor !== 'closed' ? <Col span={8} /> : null}
        
              <Col span={8}>
                <div style={{ textAlign: 'center', padding: '45px 0px 0px 0px', display: 'flex', justifyContent: 'end' }}>
                  <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={onReset}>
                    <FormattedMessage id="btnResetFilter" defaultMessage="Reset" />
                  </Button_02>

                  <Button_01 style={{ margin: '0px 0px 0px 10px' }} key="print" type="primary" btnsize="wd_df" onClick={onApply}>
                    <FormattedMessage id="btnApplyFilter" defaultMessage="Apply" />
                  </Button_01>
                </div>
              </Col>
            </Row>
          </>
        ) : (
          <>
            <Row gutter={[24, 2]}>
              <Col span={8}>
                <Form.Item
                  label={
                    <LabeRequireForm
                      text={intl.formatMessage({ id: 'purchesOrderFormVendorOrSup', defaultMessage: 'Vendor or Supplier' })}
                      req={false}
                    />
                  }
                >
                  <Select
                    mode="multiple"
                    allowClear
                    onChange={(e) => onChange(e, 'vendor')}
                    value={_.get(searchGroup, 'vendor')}
                    maxTagCount={2}
                    placeholder={intl.formatMessage({
                      id: 'purchesOrderFormVendorOrSupPlaceholder',
                      defaultMessage: 'Enter Vendor or Supplier',
                    })}
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    showSearch
                  >
                    {vendorData && vendorData.map((item) => <Option key={item.customerId}>{item.customerName}</Option>)}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label={
                    <LabeRequireForm
                      text={intl.formatMessage({ id: 'purchesOrderFormReferenceNo', defaultMessage: 'Reference No.' })}
                      req={false}
                    />
                  }
                >
                  <Input
                    allowClear
                    value={_.get(searchGroup, 'refNo')}
                    onChange={(e) => onChange(e.target.value, 'refNo')}
                    placeholder={intl.formatMessage({
                      id: 'purchesOrderFormReferenceNoPlaceholder',
                      defaultMessage: 'Enter Reference No.',
                    })}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label={
                    <LabeRequireForm text={intl.formatMessage({ id: 'purchesOrderFormTeam', defaultMessage: 'Team' })} req={false} />
                  }
                >
                  <Select
                    showSearch
                    allowClear={true}
                    onChange={(value) => onChange(value, 'team')}
                    filterOption={(input, option) => option.props.children.toLowerCase().includes(input.toLowerCase())}
                    value={_.get(searchGroup, 'team') || undefined}
                    placeholder={intl.formatMessage({ id: 'purchesOrderFormTeamPlaceholder', defaultMessage: 'Select Team' })}
                  >
                    {_.map(optionsTeam, (item) => (
                      <Option key={item.name} value={item.org_id}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
             
            </Row>
            <Row gutter={[24, 2]}>
            <Col span={8}>
                <Form.Item
                  label={
                    <LabeRequireForm
                      text={intl.formatMessage({ id: 'purchesOrderFormIssuedBy', defaultMessage: 'Issued By' })}
                      req={false}
                    />
                  }
                >
                  <Select
                    showSearch
                    allowClear={true}
                    onChange={(value) => onChange(value, 'issuedBy')}
                    filterOption={(input, option) => option.props.children.toLowerCase().includes(input.toLowerCase())}
                    value={_.get(searchGroup, 'issuedBy') || undefined}
                    placeholder={intl.formatMessage({ id: 'purchesOrderFormissuedByPlaceholder', defaultMessage: 'Select Issued By' })}
                  >
                    {_.map(optionsIssueBy, (item) => (
                      <Option value={item.memComId}>{item.fullname}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label={
                    <LabeRequireForm
                      text={intl.formatMessage({ id: 'purchesOrderFormIssuedDate', defaultMessage: 'Issued Date' })}
                      req={false}
                    />
                  }
                >
                  <RangePicker
                    allowClear
                    style={{ width: '100%' }}
                    value={_.get(searchGroup, 'issuedDate')}
                    format={['DD/MM/YYYY', 'DD/MM/YYYY']}
                    onChange={(e) => onChange(e, 'issuedDate')}
                    placeholder={[
                      intl.formatMessage({ id: 'purchesOrderFormIssuedFromDatePlaceholder', defaultMessage: 'Select Form Date' }),
                      intl.formatMessage({ id: 'purchesOrderFormIssuedToDatePlaceholder', defaultMessage: 'Select To Date' }),
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <div style={{ textAlign: 'center', padding: '45px 0px 0px 0px', display: 'flex', justifyContent: 'end' }}>
                  <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={onReset}>
                    <FormattedMessage id="btnResetFilter" defaultMessage="Reset" />
                  </Button_02>

                  <Button_01 style={{ margin: '0px 0px 0px 10px' }} key="print" type="primary" btnsize="wd_df" onClick={onApply}>
                    <FormattedMessage id="btnApplyFilter" defaultMessage="Apply" />
                  </Button_01>
                </div>
              </Col>
            </Row>
          </>
        )}
      </Form>
    );
  };

  return (
    <div style={{ padding: '12px 0px' }}>
      <CollapseCustom
        label={intl.formatMessage({ id: `btnFilter`, defaultMessage: 'Filter' })}
        extra={
          <div style={{ display: 'flex' }}>
            <ActionColumnPO
              columns={actionColumns}
              setShowColumn={setShowColumn}
              newDataColumns={newDataColumns}
              listArrayColumns={listArrayColumns}
              defaultShowColumn={defaultShowColumn}
              newColumns={newColumns}
              setNewDataColumns={setNewDataColumns}
              setListArrayColumns={setListArrayColumns}
              setDefaultShowColumn={setDefaultShowColumn}
              textErrorSelectColumn={textErrorSelectColumn}
              setTextErrorSelectColumn={setTextErrorSelectColumn}
              visible={visible}
              setVisible={setVisible}
              columnMinimum={1}
            />
          </div>
        }
      >
        {formFilter()}
      </CollapseCustom>
    </div>
  );
};

export default PurchaseOrderAction;
