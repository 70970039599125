import React, { useEffect, useState } from 'react';
import CollapseCustom from '../../../components/collapse-custom';
import { useIntl, FormattedMessage } from 'react-intl';
import ReportAssetExpenseActionColumns from './action-columns';
import { Input, Form, Select, DatePicker, Row, Col } from 'antd';
import LabeRequireForm from '../../../components/label-required-form';
import _ from 'lodash';
import Button_01 from '../../../components/v2/button_01';
import Button_02 from '../../../components/v2/button_02';

const { RangePicker } = DatePicker;
const { Option } = Select;

const ReportAssetExpenseAction = (props) => {
  const intl = useIntl();
  const { search, setSearch, setPage, setIsFetch, onFilterOrder, actionColumns, movementRefType, form } = props;
  console.log('movementRefType', movementRefType)

  const [defaultItem, setDefaultItem] = useState()
  const { getFieldDecorator, } = form;


  // useEffect(() => {
  //   console.log('search', search)
  //   if(_.get(search, 'item')) {
  //     setDefaultItem(_.get(search, 'item'))
  //   }
  // }, [search])

  const handleApply = () => {
    setIsFetch((event) => !event)
    setPage(1)
  }

  const handleReset = () => {
    setIsFetch((event) => !event)
    setSearch((prev) => ({...prev, assetClass: '', assetType: '', owner: ''}))
    setPage(1)
  }

  const formFilter = () => {
    return (
      <Form colon={false} className="deliveryForm">
        <Row gutter={[24, 2]}>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'reportAssetExpenseColumnsAssetClass', defaultMessage: 'Asset Class' })} req={false} />
              }
            >
              <Input
                allowClear={true}
                placeholder={intl.formatMessage({ id: 'reportAssetExpensePHRefAssetClass', defaultMessage: 'Enter Asset Class' })}
                onChange={(e) => onFilterOrder(e.target.value, 'assetClass')}
                value={_.get(search, 'assetClass') || undefined}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'reportAssetExpenseColumnsAssetType', defaultMessage: 'Asset Type' })} req={false} />
              }
            >
              <Input
                allowClear={true}
                placeholder={intl.formatMessage({ id: 'reportAssetExpensePHRefAssetType', defaultMessage: 'Enter Asset Type' })}
                onChange={(e) => onFilterOrder(e.target.value, 'assetType')}
                value={_.get(search, 'assetType') || undefined}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'reportAssetExpenseColumnsOwner', defaultMessage: 'Owner' })} req={false} />
              }
            >
              <Input
                allowClear={true}
                placeholder={intl.formatMessage({ id: 'reportAssetExpensePHRefOwner', defaultMessage: 'Enter Owner' })}
                onChange={(e) => onFilterOrder(e.target.value, 'owner')}
                value={_.get(search, 'owner') || undefined}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[24, 2]}>
          <Col span={8}></Col>
          <Col span={8}></Col>
          <Col span={8} style={{ textAlign: 'right' }}>
          <Form.Item
              label={<LabeRequireForm text={''} req={false} />}
            >
              <Button_01 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleReset}>
                <FormattedMessage id="btnResetFilter" defaultMessage="Reset" />
              </Button_01>
              <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="print" type="primary" btnsize="wd_df" onClick={handleApply}>
                <FormattedMessage id="btnApplyFilter" defaultMessage="Apply" />
              </Button_02>
          </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  };

  return (
    <div style={{ marginTop: '16px' }}>
      <CollapseCustom
        label={intl.formatMessage({ id: `btnFilter`, defaultMessage: 'Filter' })}
        extra={
          <div style={{ display: 'flex' }}>

            <div style={{marginRight: 20, alignContent: 'center',  }}>
              {intl.formatMessage({ id: 'reportAssetExpenseColumnsAssetFilter', defaultMessage: 'Asset' })} :
            </div>
            <Input
              style={{width: '300px'}}
                allowClear={true}
                placeholder={intl.formatMessage({ id: 'reportAssetExpenseAssetColumns', defaultMessage: 'Search Asset No. or Name or Serial No.' })}
                onChange={(e) => onFilterOrder(e.target.value, 'asset')}
                value={_.get(search, 'asset') || undefined}
              />

            <ReportAssetExpenseActionColumns actionColumns={actionColumns} />
          </div>
        }
      >
        {formFilter()}
      </CollapseCustom>
    </div>
  );
};

export default ReportAssetExpenseAction;
