import React, { useState, useEffect, useRef, useContext } from 'react';
import { Card, Col, Row } from 'antd';
import { MonitorContext } from '../../transportation-context';
import ActionSearch from '../components/action-search';
import ActionTeam from '../components/action-team';
import ActionDatepicker from '../components/action-datepicker';
import Map from './map';
import MapTracking from './tracking/index';
import FooterStatusGroup from '../components/footer-status-group';
import _ from 'lodash';
import NodataIMG from '../../../../components/image/Nodata_web.svg';
import { FormattedMessage } from 'react-intl';

import '../css/index.css';
import SelectDate from '../components/action-select-date';

export default ({ speedTracking }) => {
  const { state, fnc, setState } = useContext(MonitorContext);
  const {
    initialData,
    mainOrgId,
    mainOrgIdStr,
    resourceData,
    taskDataMapView,
    taskDataMap,
    searchValue,
    selectBy,
    orgData,
    resourceId,
    selectResourceData,
    startDate,
    dueDate,
    trackingResource,
    inputValue,
    resourceIdChecked,
    satelliteChecked,
    areaChecked,
    keyTabMonitor,
    orgIdRaw,
    LangCode,
    selectGroupBy,
    dateDefault,
    selectDateDefault,
    checkedListTeam,
  } = state;

  const { handleChangeOption, handleSearch, handleSelect, handleChangeStartDate, onClickTrack } = fnc;
  const {
    setOrgData,
    setResourceId,
    setSelectResourceData,
    setSelectTaskData,
    setTrackingResource,
    setInputValue,
    setResourceIdChecked,
    setMainTaskId,
    setSatelliteChecked,
    setAreaChecked,
    setSearchValue,
    setVisibleTaskDetailModal,
    setMainTaskIdAssign,
    setVisibleAssignee,
    setMainResourceMemComId,
    setMainStatusTaskTitle,
    setMainStatusTaskObject,
    setVisibleTaskStatusModal,
    setOrgIdRaw,
    setSelectDateDefault,
  } = setState;
  const mainOrgIdString = mainOrgId ? mainOrgId.toString() : '';

  return (
    <div className="container-map">
      <div className="container-map-header">
        <div>
          <ActionSearch
            handleSearch={handleSearch}
            handleSelect={handleSelect}
            searchValue={searchValue}
            selectBy={selectBy}
            setInputValue={setInputValue}
            inputValue={inputValue}
            setSearchValue={setSearchValue}
          />
        </div>
        <div className="action-team-all">
          <SelectDate dateDefault={dateDefault} setSelectDateDefault={setSelectDateDefault} selectDateDefault={selectDateDefault} />
          {/* <ActionDatepicker
            startDate={startDate}
            handleChangeDate={handleChangeStartDate}
          />
          <ActionTeam
            orgData={orgData}
            setOrgData={setOrgData}
            mainOrgId={mainOrgId}
            handleChangeOption={handleChangeOption}
            setOrgIdRaw={setOrgIdRaw}
            orgIdRaw={orgIdRaw}
            selectGroupBy={selectGroupBy}
          /> */}
        </div>
      </div>
      {_.size(checkedListTeam) !== 0 ? (
        trackingResource && trackingResource.openSpeedTrack ? (
          <MapTracking
            resourceId={resourceId}
            date={startDate}
            mainOrgIdString={mainOrgIdString}
            resourceData={resourceData}
            setTrackingResource={setTrackingResource}
            trackingResource={trackingResource}
            LangCode={LangCode}
          />
        ) : (
          <>
            <Map
              resourceData={resourceData}
              taskDataMapView={taskDataMapView}
              taskDataMap={taskDataMap}
              setResourceId={setResourceId}
              setSelectResourceData={setSelectResourceData}
              resourceId={resourceId}
              selectResourceData={selectResourceData}
              date={startDate}
              mainOrgId={mainOrgId}
              setSelectTaskData={setSelectTaskData}
              onClickTrack={onClickTrack}
              setResourceIdChecked={setResourceIdChecked}
              resourceIdChecked={resourceIdChecked}
              satelliteChecked={satelliteChecked}
              areaChecked={areaChecked}
              setSatelliteChecked={setSatelliteChecked}
              setAreaChecked={setAreaChecked}
              setTrackingResource={setTrackingResource}
              setVisibleTaskDetailModal={setVisibleTaskDetailModal}
              setMainTaskId={setMainTaskId}
              setMainTaskIdAssign={setMainTaskIdAssign}
              setVisibleAssignee={setVisibleAssignee}
              setMainResourceMemComId={setMainResourceMemComId}
              setMainStatusTaskTitle={setMainStatusTaskTitle}
              setMainStatusTaskObject={setMainStatusTaskObject}
              setVisibleTaskStatusModal={setVisibleTaskStatusModal}
              LangCode={LangCode}
              dateCount={_.get(selectDateDefault, 'value')}
            />
            <div className="container-map-footer">
              <FooterStatusGroup initialData={initialData} keyTabMonitor={keyTabMonitor} />
            </div>
          </>
        )
      ) : (
        <div style={{ padding: '0px 18px' }} className="no-data-blank-svg-map">
          <div className="no-data-blank-div-map">
            <img src={NodataIMG} className="no-data-blank-svg-img" />
            <p className="no-data-blank-text-1 ">
              <FormattedMessage id={`monitorDescriptionMap1${LangCode}`} defaultMessage="Please select a Team " />
            </p>
            <p className="no-data-blank-text-2">
              <FormattedMessage id={`monitorDescriptionMap2${LangCode}`} defaultMessage="Map View will appear here. " />
            </p>
          </div>
        </div>
      )}
    </div>
  );
};
