import React, { useContext, useState } from 'react';
import { Collapse, Form, Icon, Input, Button, Menu, Select, Col, Row, DatePicker, message } from 'antd';
import '../TableMyrequest/FilterTable.css';
import { useIntl, FormattedMessage } from 'react-intl';
import { MyrequestContext } from '../../myrequest-context';

import moment from 'moment';
import _ from 'lodash';
const dateFormat = 'YYYY-MM-DD';

function FilterTable() {
  const intl = useIntl();
  const { Option } = Select;
  const { Panel } = Collapse;
  const { state, setState, fnc } = useContext(MyrequestContext);
  const { searchList, changeTab, isLoading, fillter, searchListMyrequest } = state;
  const { setChangeStatus, setFillter, setChangeTab } = setState;
  const { getApiSearch, changeToApproval, changeToReject, getApiApprove, getApiReject } = fnc;
  const [reqNo, setReqNo] = useState('');
  const [reqDate, setReqDate] = useState();
  const [rangeDate, setRangeDate] = useState(false);
  const [reqStatus, setReqStatus] = useState(false);
  const status = _.get(searchListMyrequest, 'statusList');

  const { RangePicker } = DatePicker;

  // const status = [
  //   {
  //     id: 1,
  //     value: 'Waiting',
  //   },
  //   {
  //     id: 2,
  //     value: 'Approved',
  //   },
  //   {
  //     id: 3,
  //     value: 'Rejected',
  //   },
  //   {
  //     id: 4,
  //     value: 'Canceled',
  //   },
  //   {
  //     id: 5,
  //     value: 'Draft',
  //   },
  // ];

  const handleChangeDate = (value) => {
    if (_.size(value) > 0) {
      setFillter({
        ...fillter,
        ['pmdatestart']: moment(_.get(value, '[0]')).format(dateFormat),
        ['pmdateto']: moment(_.get(value, '[1]')).format(dateFormat),
      });
    } else {
      setFillter({
        ...fillter,
        ['pmdatestart']: undefined,
        ['pmdateto']: undefined,
      });
    }
  };

  function onChange(value) {
    setFillter({ ...fillter, ['statusTxt']: value });
  }

  const handleFillter = (value, code) => {
    setFillter({ ...fillter, [code]: value });
  };

  return (
    <div>
      <Form>
        <div>
          <div className="filter-space-div" style={{ padding: '16px' }}>
            <Row>
              <Col>
                <p>
                  <FormattedMessage id="myrequestReq" defaultMessage="Request No." />
                </p>
                <Input
                  className="inputWidth"
                  placeholder={intl.formatMessage({ id: 'assetpreventivePlaceholderItem', defaultMessage: 'Enter Request No.' })}
                  onChange={(e) => handleFillter(e.target.value, 'reno')}
                  allowClear
                ></Input>
              </Col>
            </Row>
            <Row style={{ marginTop: '5%' }}>
              <p>
                <FormattedMessage id="myrequestStatus" defaultMessage="Status" />
              </p>
              <Select
                allowClear
                showSearch
                placeholder={intl.formatMessage({ id: 'SelectStatusemyrequest', defaultMessage: 'Select Status.' })}
                optionFilterProp="children"
                onChange={onChange}
                // filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {_.map(status, (item) => {
                  return <Option value={item.reqCode}>{item.txt}</Option>;
                })}
              </Select>
            </Row>
            <Row style={{ marginTop: '5%' }}>
              <Col>
                <p>
                  <FormattedMessage id="myrequestDate" defaultMessage="Request Date." />
                </p>
                <RangePicker
                  style={{ fontSize: '14px', fontFamily: 'tabular-nums' }}
                  format="DD/MM/YYYY"
                  onChange={handleChangeDate}
                  placeholder={[
                    intl.formatMessage({ id: 'SelectFromDatemyrequest', defaultMessage: 'Select From Date' }),
                    intl.formatMessage({ id: 'SelectToDatemyrequest', defaultMessage: 'Select To Date' }),
                  ]}
                />
              </Col>
            </Row>
          </div>
        </div>
      </Form>
    </div>
  );
}

export default FilterTable;
