import React from 'react';
import { Form, Select, Input, InputNumber } from 'antd';
import { MemberSettings } from './page-member-settings';
import css from './css/index.css';
import { useIntl, FormattedMessage } from 'react-intl';

const { Option } = Select;

export default React.memo(
  Form.create({
    name: 'member-setting',
    mapPropsToFields(props) {
      const { data } = props;
      const checkPriority = undefined;
      if (data.priority) {
        if (data.priority === 0) {
          return 0;
        } else if (data.priority === null) {
          return null;
        }
      }

      return {
        priority: Form.createFormField({
          value: data.priority,
        }),
        favoriteShiftType: Form.createFormField({
          value: data.favoriteShiftType ? data.favoriteShiftType : [],
        }),
        gropingMemRestriction: Form.createFormField({
          value: data.gropingMemRestriction ? data.gropingMemRestriction : [],
        }),
      };
    },
  })((props) => {
    const { data } = props;
    const { getFieldDecorator } = props.form;
    const intl = useIntl();

    const handleKeyDownNumber = (e) => {
      let keyCode = e.key.charCodeAt(0);
      if ((keyCode >= 48 && keyCode <= 57) || keyCode === 65 || keyCode === 66) {
        return true;
      }
      else {
        e.preventDefault();
      }
    }

    return data.memComId ? (
      <MemberSettings.Consumer>
        {({ memComId }) => (
          <Form layout="vertical">
            <Form.Item
              label={intl.formatMessage({ id: 'orgTabMemberModalTxtPriority', defaultMessage: 'Priority' })}
            >
              {getFieldDecorator('priority', {
                initialValue: '',
                rules: [
                  {
                    pattern: new RegExp(/^[0-9\b]+$/),
                    message: intl.formatMessage({ id: 'orgTabMemberModalHintNumberOnly', defaultMessage: 'Please enter numbers only.' }),
                  },
                ],
              })(<Input
                placeholder={intl.formatMessage({ id: 'orgTabMemberModalHintPriority', defaultMessage: 'Please enter priority' })}
                autoComplete="off"
                onKeyDown={handleKeyDownNumber}
                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
              />)}
            </Form.Item>

            <Form.Item
              label={intl.formatMessage({ id: 'orgTabMemberModalTxtFavoriteShift', defaultMessage: 'Favorite Shift Type' })}
            >
              {getFieldDecorator('favoriteShiftType', {
                initialValue: [],
                rules: [],
              })(
                <Select
                  className="member-form-select"
                  mode="multiple"
                  placeholder={intl.formatMessage({ id: 'orgTabMemberModalHintShiftType', defaultMessage: 'Please select favorite shift type' })}
                >
                  {data.shiftMaster &&
                    data.shiftMaster.map &&
                    data.shiftMaster.map((o) => (
                      <Option key={o.shiftId} value={String(o.shiftId)}>
                        {o.shiftName}
                      </Option>
                    ))}
                </Select>,
              )}
            </Form.Item>

            <Form.Item
              label={intl.formatMessage({ id: 'orgTabMemberModalTxtCoworking', defaultMessage: 'Grouping Member Restriction' })}
            >
              {getFieldDecorator('gropingMemRestriction', {
                initialValue: [],
                rules: [],
              })(
                <Select
                  className="member-form-select"
                  mode="multiple"
                  placeholder={intl.formatMessage({ id: 'orgTabMemberModalHintMember', defaultMessage: 'Please select grouping member restriction' })
                  }
                >
                  {data.orgMembers &&
                    data.orgMembers.map &&
                    data.orgMembers.map((o) =>
                      String(o.memComId) !== String(memComId) ? (
                        <Option key={o.memComId} value={String(o.memComId)}>
                          {o.name}
                        </Option>
                      ) : null,
                    )}
                </Select>,
              )}
            </Form.Item>
          </Form>
        )}
      </MemberSettings.Consumer>
    ) : null;
  }),
  (prev, next) => {
    return (prev.data.orgId =
      next.data.orgId &&
      prev.data.priority === next.data.priority &&
      prev.data.favoriteShiftType === next.data.favoriteShiftType &&
      prev.data.gropingMemRestriction === next.data.gropingMemRestriction &&
      prev.data.memComId === next.data.memComId &&
      prev.data.memOrgId === next.data.memOrgId);
  },
);
