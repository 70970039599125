import React, { useEffect, useState } from 'react';
import { Checkbox, Row, Col, Icon, Divider, Select, Button, Input, DatePicker } from 'antd';
import _ from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';
import moment from 'moment';
import Button_01 from '../v2/button_01';
import Button_02 from '../v2/button_02';

const { Option } = Select;

const ListItemBrand = (props) => {
  const { reportBrand, selectBrand, setSelectBrand, handleopenModalViewBrand, setPageTable, setSizeTable, toggleBrand } = props;
  const intl = useIntl();
  const dateFormat = 'DD/MM/YYYY';

  const [listItem, setListItem] = useState({});

  useEffect(() => {
    console.log('reportBrand', reportBrand);

    const brand = _.toLower(_.get(reportBrand, 'brand'));
    const remark = _.toLower(_.get(reportBrand, 'remark'));

    const filterBrandsActive = _.filter(
      _.get(reportBrand, 'itemBrandsActive'),
      (o) => {

        const itemBrand = _.toLower(o.itemBrandName)
        const itemBrandNo = _.toLower(o.itemBrandNo)
        const itemRemark = _.toLower(o.itemBrandDescription)
        
        if (brand && remark) {
          return (itemBrand.includes(brand) || itemBrandNo.includes(brand)) && itemRemark.includes(remark);
        } else if (brand) {
          return itemBrand.includes(brand) || itemBrandNo.includes(brand);
        } else if (remark) {
          return itemRemark.includes(remark);
        }
        
        return false;
      }
    );

    const filterBrandsInactive = _.filter(
      _.get(reportBrand, 'itemBrandsInactive'),
      (o) => {

        const itemBrand = _.toLower(o.itemBrandName)
        const itemBrandNo = _.toLower(o.itemBrandNo)
        const itemRemark = _.toLower(o.itemBrandDescription)
        
        if (brand && remark) {
          return (itemBrand.includes(brand) || itemBrandNo.includes(brand)) && itemRemark.includes(remark);
        } else if (brand) {
          return itemBrand.includes(brand) || itemBrandNo.includes(brand);
        } else if (remark) {
          return itemRemark.includes(remark);
        }
        
        return false;
      }
    );

    console.log('filterBrandsActive', filterBrandsActive, filterBrandsInactive, reportBrand);

    setListItem({
      itemBrandsActive: (brand || remark) ? filterBrandsActive : _.get(reportBrand, 'itemBrandsActive'),
      itemBrandsInactive: (brand || remark) ? filterBrandsInactive : _.get(reportBrand, 'itemBrandsInactive'),
      status: _.get(reportBrand, 'status'),
    });
  }, [_.get(reportBrand, 'apply')]);

  return (
    <div>
      <div className='scroll-sm' style={{ overflowY: 'auto', height: toggleBrand ? `calc(65vh - 357px)` : `calc(106vh - 357px)`, padding: '5px 0px 0px 24px' }}>
        {_.get(listItem, 'status') === 'Active' || _.get(listItem, 'status') === undefined ? (
          <div style={{ padding: '16px 0px 8px 0px' }}>
            <div className="listItemFilterContentHeadText" style={{ marginBottom: '8px' }}>
              <FormattedMessage id="modalListItemBrandActive" defaultMessage="Active" />
            </div>
            <div>
              {_.map(_.get(listItem, 'itemBrandsActive'), (item) => {
                return (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      padding: '16px 0px 16px 0px',
                      cursor: 'pointer',
                      background: _.get(item, 'itemBrandId') === selectBrand ? '#EBEFF8' : null,
                      marginLeft: _.get(item, 'itemBrandId') === selectBrand ? '-24px' : null,
                    }}
                    onClick={() => {
                      setSelectBrand(_.get(item, 'itemBrandId'));
                      setPageTable(1);
                      setSizeTable(10);
                    }}
                  >
                    <span style={{ width: '80%', paddingLeft: _.get(item, 'itemBrandId') === selectBrand ? '24px' : null }}>
                      {_.get(item, 'itemBrandNo') + " " +_.get(item, 'itemBrandName')}
                    </span>
                    {_.get(item, 'itemBrandId') === selectBrand ? (
                      <span style={{ width: '20%', fontSize: '17px' }}>
                        <Icon
                          type="file-search"
                          onClick={() => {
                            handleopenModalViewBrand(item);
                          }}
                        />
                      </span>
                    ) : null}
                  </div>
                );
              })}
            </div>
          </div>
        ) : null}

        {_.get(listItem, 'status') === 'Inactive' || _.get(listItem, 'status') === undefined ? (
          <div style={{ padding: '16px 0px 8px 0px' }}>
            <div className="listItemFilterContentHeadText" style={{ marginBottom: '8px' }}>
              <FormattedMessage id="modalListItemBrandInactive" defaultMessage="Inactive" />
            </div>
            <div>
              {_.map(_.get(listItem, 'itemBrandsInactive'), (item) => {
                return (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      padding: '16px 0px 16px 0px',
                      cursor: 'pointer',
                      background: _.get(item, 'itemBrandId') === selectBrand ? '#EBEFF8' : null,
                      marginLeft: _.get(item, 'itemBrandId') === selectBrand ? '-24px' : null,
                    }}
                    onClick={() => {
                      setSelectBrand(_.get(item, 'itemBrandId'));
                    }}
                  >
                    <span style={{ width: '80%', paddingLeft: _.get(item, 'itemBrandId') === selectBrand ? '24px' : null }}>
                      {_.get(item, 'itemBrandNo') + " " + _.get(item, 'itemBrandName')}
                    </span>
                    {_.get(item, 'itemBrandId') === selectBrand ? (
                      <span style={{ width: '20%', fontSize: '17px' }}>
                        <Icon type="file-search" onClick={() => handleopenModalViewBrand(item)} />
                      </span>
                    ) : null}
                  </div>
                );
              })}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ListItemBrand;
