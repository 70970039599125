import React, { useState, useEffect, useContext } from 'react';
import { RiBuildingLine } from 'react-icons/ri';
import { FaRegBuilding } from 'react-icons/fa';
import { Layout, Menu, Icon, Divider } from 'antd';
import { BrowserRouter as Router, Route, Link, useHistory } from 'react-router-dom';
import { useAppContext } from '../includes/indexProvider';
import { PageSettings } from '../config/page-settings';
import { useIntl, FormattedMessage } from 'react-intl';
import Logo from '../pages/login/image/RapidWorkLogo.svg';
import LogoD from '../components/image/WebIcon_RapidTech32px.png';

import cssStyle from './css/leftMenu.css';
import GetLang from './language';
import Group from 'antd/lib/input/Group';
import { Fragment } from 'react';
import logo from '../pages/login/image/Logo_WorkD_Text.png';
import _, { uniqueId } from 'lodash';
import LogoWorkD from '../pages/login/image/LogoWorkD.svg';
import getMenu from '../controllers/leftmenu/get-menu';
import RouteData from '../config/page-route';
import IconCompany from '../components/image/Company-menu.svg';
import IconUser from '../components/image/userManagement-menu.svg';
import IconTask from '../components/image/Task-menu.svg';
import IconCompanyOrenge from '../components/image/CompanyOrange.svg';
import IconProject from '../components/image/Project_web.svg';
import IconUserOrenge from '../components/image/userManagementOrange.svg';
import IconTaskOrenge from '../components/image/TaskOrange.svg';

const { Sider } = Layout;
const { SubMenu } = Menu;

export default function SideMenu(props) {
  const app = useAppContext();
  const pagecontext = useContext(PageSettings);
  const history = useHistory();
  const { onChangeMenu } = props;
  const leftmenuShow = app.state.show.leftmenu;
  const leftmenuUsersList = app.state.show.leftmenuUsersList;

  const [collapsed, setToCollapse] = useState(false);
  const [sideMenuDisplay, SetToSideMenuDisplay] = useState(true);
  const [menuList, setMenuList] = useState([]);
  const [item, setToItem] = useState();
  const labelShow = props.labelShow;

  const [statusCompany, setStatusCompany] = useState(false);
  const [statusUser, setStatusUser] = useState(false);
  const [statusWorkForce, setStatusWorkForce] = useState(false);

  // console.log('Menu menuList::: ', menuList);

  const toggle = () => {
    setToCollapse(!collapsed);
  };

  const onCollapse = (collapsed) => {
    setToCollapse({ collapsed });
  };

  const iconLogo = () => <img className="icon-logo" src={Logo} />;

  const iconLogoD = () => <img className="icon-logo-d" src={LogoD} />;

  const iconLogoMenu = () => <Icon className="unfold-image" type="menu" />;

  const icomCompay = () => <img src={RiBuildingLine} />;

  useEffect(() => {
    const getManuData = async () => {
      const response = await getMenu({ isMenu: true });
      if (_.size(response) !== 0) {
        setMenuList(response.data.leftMenu);
      }
    };
    getManuData();
  }, []);

  // const filterData = ({ item, selectedKeys }) => {

  // }

  const handleMouseOver = (menuCode) => {
    if (menuCode === 'company') {
      setStatusCompany(true);
    } else if (menuCode === 'workforce') {
    } else if (menuCode === 'user-management') {
    }
  };

  const handleMouseOut = (menuCode) => {
    if (menuCode === 'company') {
      setStatusCompany(false);
    } else if (menuCode === 'workforce') {
    } else if (menuCode === 'user-management') {
    }
  };

  const icomCompany = () => <img src={statusCompany === true ? IconCompanyOrenge : IconCompany} className="image-icon-menu" />;

  const icomTransporation = () => <img src={IconTask} className="image-icon-menu" />;

  const icomUserManagement = () => <img src={IconUser} className="image-icon-menu" />;

  const icomProject = () => <img src={IconProject} className="image-icon-menu" />;

  const manuMap =
    menuList &&
    menuList.map((menu) =>
      leftmenuShow === false ? null : menu.isAllowed !== true ? null : _.isObject(menu.children) ? (
        <SubMenu
          className={`sub-menu-${menu.menuCode}`}
          key={menu.menuCode}
          title={
            <span>
              {menu.menuCode === 'company' ? (
                <Icon component={icomCompany} className="image-icon-menu-component" />
              ) : menu.menuCode === 'workforce' ? (
                <Icon component={icomTransporation} className="image-icon-menu-component" />
              ) : menu.menuCode === 'user-management' ? (
                <Icon component={icomUserManagement} className="image-icon-menu-component" />
              ) : menu.menuCode === 'project' ? (
                <Icon component={icomProject} className="image-icon-menu-component" />
              ) : menu.menuCode === 'transportation' ? (
                <Icon component={icomTransporation} className="image-icon-menu-component" />
              ) : (
                <Icon type={`${menu.iconCode}`} style={{ alignContent: 'center' }} />
              )}
              <span>
                {' '}
                <FormattedMessage id={menu.menuName} defaultMessage={menu.menuName} />
              </span>
            </span>
          }
        >
          {menu.children &&
            menu.children.map((subMenu) =>
              _.isObject(subMenu.children) && subMenu.isShow === true ? (
                <SubMenu
                  key={subMenu.menuCode}
                  title={
                    <span>
                      <span>
                        {' '}
                        <FormattedMessage id={subMenu.menuName} defaultMessage={subMenu.menuName} />
                      </span>
                    </span>
                  }
                >
                  {subMenu.children &&
                    subMenu.children.map((children) =>
                      pagecontext.checkPermissionAction(
                        children.permissionActionCode[0].pageCode,
                        children.permissionActionCode[0].actionCode,
                        menu.licenseModuleCode
                      ) ? (
                        <Menu.Item key={children.menuCode} className="item-style">
                          <span>
                            {' '}
                            <FormattedMessage id={children.menuName} defaultMessage={children.menuName} />
                          </span>
                        </Menu.Item>
                      ) : null
                    )}
                </SubMenu>
              ) : subMenu.isShow === true ? (
                pagecontext.checkPermissionAction(subMenu.permissionActionCode[0].pageCode, subMenu.permissionActionCode[0].actionCode, menu.licenseModuleCode) ? (
                  <Menu.Item key={subMenu.menuCode} className="item-style">
                    <span>
                      {' '}
                      <FormattedMessage id={subMenu.menuName} defaultMessage={subMenu.menuName} />
                    </span>
                  </Menu.Item>
                ) : null
              ) : null
            )}
        </SubMenu>
      ) : pagecontext.checkPermissionAction(menu.permissionActionCode[0].pageCode, menu.permissionActionCode[0].actionCode, menu.licenseModuleCode) ? (
        <Menu.Item key={menu.menuCode} className="item-style">
          <Icon type={`${menu.iconCode}`} />
          <span>
            {' '}
            <FormattedMessage id={menu.menuName} defaultMessage={menu.menuName} />
          </span>
        </Menu.Item>
      ) : null
    );

  return (
    <PageSettings.Consumer>
      {({ pageSidebar, pageSidebarOpen, pageSidebarToggled, handleSidebarToggled, handleOnSelectMenu, checkPermissionAction, pathKey }) => (
        <Sider
          className="sider-style"
          theme="light"
          breakpoint="lg"
          width={sideMenuDisplay ? 224 : 0}
          collapsedWidth={sideMenuDisplay ? 50 : 0}
          collapsed={pageSidebarToggled}
        >
          <div className="space-Icon">
            {pageSidebarToggled === true ? '' : <Icon className="unfold-image" component={iconLogo} />}
            <Icon
              className={pageSidebarToggled ? 'fold-menu' : 'unfold-menu'}
              component={pageSidebarToggled ? iconLogoD : iconLogoMenu}
              onClick={handleSidebarToggled}
            />
          </div>

          <Divider className="div-side-menu" />

          <Menu
            className="menu-item-style"
            mode="inline"
            theme="light"
            defaultSelectedKeys={pageSidebar}
            defaultOpenKeys={pageSidebarOpen}
            selectedKeys={pageSidebar}
            onSelect={handleOnSelectMenu}
            // onClick={filterData}
          >
            {/* <div className='space-Icon'>
              {pageSidebarToggled === true ? "" : <Icon className='unfold-image' component={iconLogo} />}
              <Icon
                className={pageSidebarToggled ? 'fold-menu' : 'unfold-menu'}
                component={pageSidebarToggled ? iconLogoD : iconLogoMenu}
                onClick={handleSidebarToggled}
              />
            </div>

            <Divider className="div-side-menu" /> */}

            {manuMap}
          </Menu>
        </Sider>
      )}
    </PageSettings.Consumer>
  );
}
