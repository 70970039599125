import { notification } from 'antd';

const successNotification = (message) => {
  notification.success({
    message: message,
  });
};

const errorNotification = (message) => {
  notification.error({
    message: message,
  });
};

const warningNotification = (message) => {
  notification.warning({
    message: message,
  });
};

export {successNotification, errorNotification, warningNotification}