import React from 'react';

const SuffixInput = (props) => {
  const { text } = props;
// --- ใส่ padding ที่ input ด้วยเพื่อกัน text ทับ suffix
  return (
    <span>
      <span style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)' }}>{text}</span>
    </span>
  );
};

export default SuffixInput;
