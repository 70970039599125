import React, { useContext } from 'react';
import { SaleOrderProvider } from './context';
import './index.css'
import SaleOrderPage from './sale-order-page';

const SaleOrderProviderPage = () => {
  return (
    <SaleOrderProvider>
      <SaleOrderPage />
    </SaleOrderProvider>
  );
};

export default SaleOrderProviderPage;
