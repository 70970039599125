import React from 'react';
import { Icon, Avatar } from 'antd';
import './css/index.css';
import { FormattedMessage } from 'react-intl';

export default (props) => {
  const { imageRef, imageFile, imageFileDefault, handleChangePicture, showOpenFileDialog, deleteImage } = props;

  return (
    <div className="image-container-project">
      <Avatar className="avartar-asset" shape="circle" size={150} src={`${imageFile ? imageFile : imageFileDefault}`} />
      <input ref={imageRef} type="file" style={{ display: 'none' }} accept=".jpg, .png, .JPG, .PNG" onChange={handleChangePicture} />

      <div className="image-container-project after" style={{ paddingTop: 63, paddingLeft: 62, paddingRight: 62 }}>
        <Icon type="camera" theme="outlined" style={{ fontSize: '25px' }} onClick={showOpenFileDialog} />
      </div>
    </div>
  );
};
