import httpClient from '../../components/axiosClient';

const getSizeList = async (body) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v5/order/manager/company/${comCode}/list-parcel-size`, body);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
}

const createSize = async (body) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v5/order/manager/company/${comCode}/create-parcel-size`, body);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
}

const updateSize = async (id, body) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.put(`/v5/order/manager/company/${comCode}/parcel-size/${id}/update-parcel-size`, body);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
}

const deleteSize = async (id) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.delete(`/v5/order/manager/company/${comCode}/parcel-size/${id}/delete-parcel-size`);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
}

export { getSizeList, createSize, updateSize, deleteSize };