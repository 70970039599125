import React, { useState, useEffect, useRef } from 'react';
import { Modal, Form, Divider, Table, Row, Col, DatePicker, Select, Input, AutoComplete, Popover, Button } from 'antd';
import Button01 from '../../v2/button_01';
import Button02 from '../../v2/button_02';
import { FormattedMessage, useIntl } from 'react-intl';
import './index.css';
import LabeRequireForm from '../../label-required-form';
import ModalFormReceiveLotOrSerial from '../../lot-or-serial';
import { useDebounce } from '../../../controllers/debounce';
import { getItemsCode, getItemsMaster, getLocationByItem, saveMovementStock } from '../../../controllers/warehouse/warehouse';
import _ from 'lodash';
import { fncGetUsers } from '../../../controllers/user/users';
import { getVendor } from '../../../controllers/customer-vendor/customer-vendor';
import { getByCategoryData, getByConvertDataByItemMaster } from '../../../controllers/uom/uom';
import moment from 'moment';
import { successNotification, errorNotification } from '../../v2/notification';
import { getConvertUoMBigger } from '../../../controllers/po/po';

const { Option } = Select;

const ModalReceiveItem = (props) => {
  const { visible, setVisible, form, warehouseId, setTrigger, numbertypeList } = props;
  const { getFieldDecorator, setFieldsValue, resetFields, validateFields } = form;
  const intl = useIntl();
  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
    },
    {
      title: intl.formatMessage({ id: 'modalReceiveItemColumnLotNo', defaultMessage: 'Lot No. or Serial No.' }),
      dataIndex: 'lotNo',
      key: 'lotNo',
      sorter: (a, b) => a.lotNo - b.lotNo,
      width: 200,
    },
    {
      title: intl.formatMessage({ id: 'modalReceiveItemColumnCost', defaultMessage: 'Cost' }),
      dataIndex: 'cost',
      key: 'cost',
      sorter: (a, b) => a.cost - b.cost,
      width: 150,
    },
    {
      title: intl.formatMessage({ id: 'modalReceiveItemColumnQty', defaultMessage: 'Qty' }),
      dataIndex: 'qty',
      key: 'qty',
      sorter: (a, b) => a.qty - b.qty,
      width: 150,
    },
    {
      title: intl.formatMessage({ id: 'modalReceiveManufacturingDateColumnQty', defaultMessage: 'Manufacturing Date' }),
      dataIndex: 'manufacturingDate',
      key: 'manufacturingDate',
      render: (text, record, index) => record.manufacturingDate ? moment(record.manufacturingDate).format('ddd, DD MMM YYYY') : null,
      width: 200,
    },
    {
      title: intl.formatMessage({ id: 'modalReceiveItemColumnExpiredDate', defaultMessage: 'Expired Date' }),
      dataIndex: 'expiredDate',
      key: 'expiredDate',
      sorter: (a, b) => a.expiredDate - b.expiredDate,
      render: (text, record, index) => record.expiredDate ? moment(record.expiredDate).format('ddd, DD MMM YYYY') : null,
      width: 200,
    },
    {
      title: intl.formatMessage({ id: 'modalReceiveFDAColumnQty', defaultMessage: 'FDA' }),
      dataIndex: 'fda',
      key: 'fda',
      width: 150,
    },
    {
      title: intl.formatMessage({ id: 'modalReceiveItemColumnBinLocation', defaultMessage: 'Bin Location' }),
      dataIndex: 'binlocationData',
      key: 'binlocationData',
      sorter: (a, b) => a.binlocationData - b.binlocationData,
      render: (text, record, index) => <span>{record.binlocationData ? record.binlocationData.name : ''}</span>,
      width: 200,
    },
  ];
  const ref = useRef();
  const [visibleLotNo, setvisibleLotNo] = useState(false);
  const [lotNo, setLotNo] = useState([]);
  const [searhByCode, setSearchByCode] = useState('');
  const [searchByName, setSearchByName] = useState('');
  const [itemCodeList, setItemCodeList] = useState([]);
  const [itemNameList, setItemNameList] = useState([]);
  const [userData, setUserData] = useState([]);
  const [vendorData, setVendorData] = useState([]);
  const [uomData, setUomData] = useState([]);
  const [binLocationList, setBinLocationList] = useState([]);
  const [selectItem, setSelectItem] = useState();
  const debounceCode = useDebounce(searhByCode, 500);
  const debounceName = useDebounce(searchByName, 500);
  const [paginationPage, setPaginationPage] = useState(1);
  const [visiblePopoverTable, setVisiblePopoverTable] = useState();
  const [recordPopover, setRecordPopover] = useState();
  const [offsetPopover, setOffsetPopover] = useState([0, 0]);
  const [defaultData, setDefaultData] = useState();

  useEffect(() => {
    async function getUser() {
      try {
        const body = {
          searchBy: '',
          searchVal: 'all',
          page: 1,
          limit: 10000,
        };
        const memComId = localStorage.getItem('memComId');
        const response = await fncGetUsers(memComId);
        const vendorResponse = await getVendor(body);
        setUserData(response);
        setVendorData(vendorResponse.customers);
      } catch (error) { }
    }
    getUser();
  }, []);

  useEffect(() => {
    const getItemCode = async () => {
      const payload = {
        code: searhByCode,
        categoryCode: 'product',
      };
      const response = await getItemsCode(payload);
      const list = _.get(response.data.data, 'itemMasterList');
      setItemCodeList(list);
      // setItemCodeList(_.uniqBy(_.concat(itemCodeList, list), 'itemMasterId'));
    };

    getItemCode();
  }, [debounceCode]);

  useEffect(() => {
    const getItemCode = async () => {
      const payload = {
        name: searchByName,
        categoryCode: 'product',
      };
      const response = await getItemsMaster(payload);
      const list = _.get(response.data.data, 'itemMasterList');
      setItemNameList(list);
      // setItemNameList(_.uniqBy(_.concat(itemNameList, list), 'itemMasterId'));
    };

    getItemCode();
  }, [debounceName]);

  useEffect(() => {
    console.log('selectitem', selectItem);
    const getLocationForStock = async () => {
      const payload = {
        itemMasterDetail: selectItem,
        warehouseId: warehouseId,
      };

      const payloadUom = {
        itemMasterId: _.get(selectItem, 'itemMasterId') ? _.get(selectItem, 'itemMasterId') : [],
      };
      const response = await getLocationByItem(payload);
      // const getDataUoM = await getByConvertDataByItemMaster(payloadUom);
      const getDataUoM = await getConvertUoMBigger(_.get(selectItem, 'itemMasterId'));
      console.log('response', response);
      setBinLocationList(_.get(response.data.data, 'binLocationList'));
      setUomData(_.get(getDataUoM, 'data.data'));
    };
    if (selectItem && warehouseId) {
      getLocationForStock();
    }
  }, [selectItem, warehouseId]);

  const handleSave = () => {
    validateFields(async (err, values) => {
      console.log('selectitem', values);
      if (err) {
        return;
      }
      const body = {
        warehouseId: warehouseId,
        itemMasterId: _.get(selectItem, 'itemMasterId'),
        actionById: values.actionBy,
        receivedDate: moment(values.receivedDate).format('YYYY-MM-DD'),
        vendorOrSupplierId: values.vendor,
        remark: values.remark,
        movementList: _.map(lotNo, (item) => {
          return {
            numberType: item.lotNoSelect,
            no: item.lotNo,
            cost: parseFloat(item.cost),
            qty: parseFloat(item.qty),
            uomId: item.uom,
            expiredDate: item.expiredDate ? moment(item.expiredDate).format('YYYY-MM-DD') : undefined,
            fda: item.fda,
            manufacturingDate: item.manufacturingDate ? moment(item.manufacturingDate).format('YYYY-MM-DD') : undefined,
            binLocationId: item.binlocationData ? item.binlocationData.binLoationId : undefined,
          };
        }),
      };
      try {
        const response = await saveMovementStock(body);
        // if (response.data.status.code === 1) {
        successNotification(response.data.status.message);
        setVisible(false);
        resetFields();
        setLotNo([]);
        setTrigger((current) => !current);
        // } else {
        //   errorNotification(response.data.status.message)
        // }
      } catch (error) {
        errorNotification(error.response.data.status.message);
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
    resetFields();
    setLotNo([]);
  };

  const handleCreate = () => {
    setvisibleLotNo(true);
  };

  const handleSearchCode = _.debounce((value) => {
    setSearchByCode(value || '');
  }, 500);

  const handleSearchName = _.debounce((value) => {
    setSearchByName(value || '');
  }, 500);

  const handleSelectCode = (value) => {
    if (value) {
      const filterArr = itemCodeList.filter((item) => item.itemMasterId === value);
      setSelectItem(_.get(filterArr, '[0]'));
      setFieldsValue({
        itemName: _.get(filterArr[0], 'itemName'),
      });
    }
  };

  const handleSelectName = (value) => {
    if (value) {
      const filterArr = itemNameList.filter((item) => item.itemMasterId === value);
      setSelectItem(_.get(filterArr, '[0]'));
      setFieldsValue({
        itemCode: _.get(filterArr[0], 'itemCode'),
      });
    }
  };

  const handleChange = (pagination, sorter, extra) => {
    setPaginationPage(pagination.current);
  };

  const showTotal = () => {
    return (
      <span style={{ fontSize: '13px' }}>
        <FormattedMessage id="lblRowPerPage" defaultMessage="Rows per page: " />
      </span>
    );
  };

  const handleEditLotNo = (data) => {
    setvisibleLotNo(true);
    setDefaultData(data);
  };

  const handleDeleteLotNo = (value) => {
    const filterData = _.filter(lotNo, (item) => {
      return item.id !== value;
    });
    setLotNo(filterData);
  };

  const fncActionTable = (record, offsetPopover, visiblePopoverTable, ref) => {
    return (
      <div>
        <Popover
          placement="right"
          trigger="hover"
          align={{ offset: offsetPopover }}
          visible={visiblePopoverTable}
          zIndex={10000}
          content={
            <div style={{ display: 'grid' }}>
              <div>
                <Button style={{ width: 100 }} type="link" ghost onClick={() => handleEditLotNo(record)}>
                  <FormattedMessage id="orgTabMemberBtnEdit" defaultMessage="Edit" />
                </Button>
              </div>
              <div>
                <Button style={{ width: 100 }} type="link" ghost onClick={() => handleDeleteLotNo(record.id)}>
                  <FormattedMessage id="btnDelete" defaultMessage="Delete" />
                </Button>
              </div>
            </div>
          }
        >
          <div style={{ width: 0, height: 0 }} ref={ref}></div>
        </Popover>
      </div>
    );
  };

  const handleMouseLeave = () => {
    setVisiblePopoverTable(false);
    setRecordPopover();
    setOffsetPopover([0, 0]);
  };

  const checkView = [
    <Button02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleCancel}>
      <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
    </Button02>,
    <Button01 key="submit" type="primary" btnsize="wd_df" onClick={handleSave} disabled={_.size(lotNo) === 0 ? true : false}>
      <FormattedMessage id="btnSave" defaultMessage="Save" />
    </Button01>,
  ];

  return (
    <Modal
      title={intl.formatMessage({ id: 'modalReceiveItemHeader', defaultMessage: 'Receive Item' })}
      visible={visible}
      onCancel={handleCancel}
      width={767}
      centered={true}
      bodyStyle={{ padding: 'unset', overflowY: 'scroll', height: 'calc(100vh - 350px)' }}
      footer={checkView}
    >
      <div style={{ padding: '24px' }}>
        <Form form={form} layout="vertical" className="modalReceiveItemForm">
          <Row gutter={[16]}>
            <Col span={12}>
              <Form.Item
                label={
                  <LabeRequireForm text={intl.formatMessage({ id: 'modalReceiveItemCodeText', defaultMessage: 'Item Code' })} req={true} />
                }
              >
                {getFieldDecorator('itemCode', {
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'modalReceiveItemCodeTextValidate', defaultMessage: 'Please Select Item Code' }),
                    },
                  ],
                })(
                  <AutoComplete
                    onSearch={handleSearchCode}
                    onSelect={(value) => handleSelectCode(value)}
                    dataSource={itemCodeList}
                    showSearch={true}
                    // filterOption={(input, option) =>
                    //   option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                    // }
                    placeholder={intl.formatMessage({ id: 'modalReceiveItemCodeTextPlaceholeder', defaultMessage: 'Select Item Code' })}
                  >
                    {itemCodeList &&
                      itemCodeList.map((item) => (
                        <Option key={item.itemCode} value={item.itemMasterId}>
                          {item.itemCode}
                        </Option>
                      ))}
                  </AutoComplete>
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <LabeRequireForm text={intl.formatMessage({ id: 'modalReceiveItemNameText', defaultMessage: 'Item Name' })} req={true} />
                }
              >
                {getFieldDecorator('itemName', {
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'modalReceiveItemNameValidate', defaultMessage: 'Please Enter Item Name' }),
                    },
                  ],
                })(
                  <AutoComplete
                    onSearch={handleSearchName}
                    onSelect={handleSelectName}
                    // filterOption={(input, option) =>
                    //   option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                    // }
                    showSearch={true}
                    dataSource={itemNameList}
                    placeholder={intl.formatMessage({ id: 'modalReceiveItemNamePlaceholeder', defaultMessage: 'Select Item Name' })}
                  >
                    {itemNameList &&
                      itemNameList.map((item, index) => (
                        <Option key={item.itemCode} value={item.itemMasterId}>
                          {item.itemName}
                        </Option>
                      ))}
                  </AutoComplete>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16]}>
            <Col span={12}>
              <Form.Item
                label={
                  <LabeRequireForm
                    text={intl.formatMessage({ id: 'modalReceiveVendorText', defaultMessage: 'Vendor or Supplier' })}
                    req={true}
                  />
                }
              >
                {getFieldDecorator('vendor', {
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'modalReceiveVendorValidate', defaultMessage: 'Please Select Vendor or Supplier' }),
                    },
                  ],
                })(
                  <Select
                    placeholder={intl.formatMessage({ id: 'modalReceiveVendorPlaceholeder', defaultMessage: 'Select Vendor or Supplier' })}
                    showSearch
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    {_.map(vendorData, (item) => (
                      <Option key={item.customerId}>{item.customerName}</Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16]}>
            <Col span={12}>
              <Form.Item
                label={
                  <LabeRequireForm text={intl.formatMessage({ id: 'modalReceiveReceivedByText', defaultMessage: 'Received By' })} req={true} />
                }
              >
                {getFieldDecorator('actionBy', {
                  initialValue: localStorage.getItem('memComId') ? localStorage.getItem('memComId') : undefined,
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'modalReceiveActionByValidate', defaultMessage: 'Please Select Received By' }),
                    },
                  ],
                })(
                  <Select
                    showSearch
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    placeholder={intl.formatMessage({ id: 'modalReceiveActionByPlaceholeder', defaultMessage: 'Select Received By' })}
                  >
                    {_.map(userData, (item) => (
                      <Option key={item.mem_com_id}>{item.fullname}</Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <LabeRequireForm
                    text={intl.formatMessage({ id: 'modalReceiveReceivedDateText', defaultMessage: 'Received Date' })}
                    req={true}
                  />
                }
              >
                {getFieldDecorator('receivedDate', {
                  initialValue: moment(),
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({
                        id: 'modalReceiveReceivedDateValidate',
                        defaultMessage: 'Please Select Received Date',
                      }),
                    },
                  ],
                })(
                  <DatePicker
                    style={{ width: '100%' }}
                    format={'ddd, DD MMM YYYY'}
                    placeholder={intl.formatMessage({ id: 'modalReceiveReceivedDatePlaceholeder', defaultMessage: 'Select Received Date' })}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16]}>
            <Col span={24}>
              <Form.Item
                label={
                  <LabeRequireForm text={intl.formatMessage({ id: 'modalReceiveRemarkText', defaultMessage: 'Remark' })} req={false} />
                }
              >
                {getFieldDecorator('remark', {})(<Input style={{ width: '100%' }} />)}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
      <div style={{ padding: '0 24px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div className="modalReceiveItemTextLotNo"><FormattedMessage id="modalReceiveItemColumnLotNo" defaultMessage="Lot No. or Serial No." /></div>
        <div>
          <Button01 key="submit" type="primary" btnsize="wd_df" onClick={handleCreate}>
            <FormattedMessage id="btnAdd" defaultMessage="Add" />
          </Button01>
        </div>
      </div>
      <Divider type="horizontal" style={{ marginTop: '16px' }} />
      <div style={{ padding: '0 24px 24px 24px' }} onMouseLeave={handleMouseLeave}>
        <Table
          columns={columns}
          dataSource={lotNo}
          onChange={handleChange}
          scroll={{ x: _.sumBy(columns, 'width') }}
          pagination={{
            total: _.size(lotNo),
            current: paginationPage,
            showTotal: showTotal,
            defaultCurrent: 1,
            pageSizeOptions: ['10', '20', '30', '40', '50'],
            showSizeChanger: true,
            locale: { items_per_page: '' },
          }}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                if (!event.target.href) {
                  const { x, y } = ref.current.getBoundingClientRect();
                  setVisiblePopoverTable(true);
                  setOffsetPopover([event.pageX - x, event.pageY - y]);
                  setRecordPopover(record);
                }
              },
            };
          }}
        />
        {fncActionTable(recordPopover, offsetPopover, visiblePopoverTable, ref)}
        {_.size(lotNo) > 0 ? (
          <div className="total-items-member">
            <span style={{ fontSize: '13px' }}>
              <FormattedMessage id="lblTotal" defaultMessage="Total" />
              {` ${_.size(lotNo) || 0} `}
              <FormattedMessage id="lblitems" defaultMessage="items" />
            </span>
          </div>
        ) : null}
      </div>
      <ModalFormReceiveLotOrSerial
        visible={visibleLotNo}
        setVisible={setvisibleLotNo}
        lotNo={lotNo}
        setLotNo={setLotNo}
        binLocationList={binLocationList}
        uomData={uomData}
        defaultData={defaultData}
        setDefaultData={setDefaultData}
        numbertypeList={numbertypeList}
      />
    </Modal>
  );
};

const ModalFormReceiveItem = Form.create({
  name: 'modal_form',
})(ModalReceiveItem);

export default ModalFormReceiveItem;
