import httpCiient from '../../components/axiosClient';

const comCode = localStorage.getItem('comCode');

const getInitialAssetClass = async (payload) => {
  try {
    const response = await httpCiient.post(`/v3/item/manager/${comCode}/getlistassetclassloadmore`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getTypeAsset = async (payload) => {
  try {
    const response = await httpCiient.post(`/v3/item/manager/${comCode}/getlistassettypeloadmore`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getBrandAsset = async (payload) => {
  try {
    const response = await httpCiient.post(`/v3/item/manager/${comCode}/getlistassetbrandloadmore`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getModelAsset = async (payload) => {
  try {
    const response = await httpCiient.post(`/v3/item/manager/${comCode}/getlistassetmodelloadmore`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getStatusAsset = async (payload) => {
  try {
    const response = await httpCiient.post(`/v3/item/manager/${comCode}/getlistassetstatus`, payload);
    return response.data;
  } catch (error) {
    return error.response;
  }
};

const getInitial = async (payload) => {
  try {
    const response = await httpCiient.post(`/v3/item/manager/${comCode}/getlistassettransection`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getSuitable = async () => {
  try {
    const response = await httpCiient.get(`/v3/item/manager/${comCode}/getlistsuitable`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getMemberAsset = async (payload) => {
  try {
    const response = await httpCiient.post(`/v3/item/manager/${comCode}/getlistmemberloadmore`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const saveAsset = async (payload) => {
  try {
    const response = await httpCiient.post(`/v3/item/manager/${comCode}/createdataasset`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const updatAsset = async (assetId, payload) => {
  try {
    const response = await httpCiient.put(`/v3/item/manager/${comCode}/updatedataasset/${assetId}`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const saveAssetImg = async (assetId, payload) => {
  try {
    const response = await httpCiient.put(`/v3/item/manager/${comCode}/uploadphoto/${assetId}`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
const searchTableAsset = async (payload) => {
  try {
    const response = await httpCiient.post(`/v3/item/manager/${comCode}/searchnewfilter`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getByIdAsset = async (payload) => {
  try {
    const response = await httpCiient.get(`/v3/item/manager/${comCode}/getdatabyid/${payload}`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getA = async (payload) => {
  try {
    const response = await httpCiient.get(`/v3/item/manager/${comCode}/getdatabyid/${payload}`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getAssetInsurance = async (assetId, formDate, toDate, indexStart = 0, indexEnd = 1000, name = '') => {
  try {
    const response = await httpCiient.get(
      `/v5/item/manager/company/${comCode}/web/asset/insurance/${assetId}/from/${formDate}/to/${toDate}?indexStart=${indexStart}&indexEnd=${indexEnd}&insuranceName=${name}`
    );

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getAssetInsuranceByClaimDate = async (assetId, date, indexStart = 0, indexEnd = 1000, name = '') => {
  try {
    const response = await httpCiient.get(
      `/v5/item/manager/company/${comCode}/web/asset/insurance/${assetId}/claim/${date}?indexStart=${indexStart}&indexEnd=${indexEnd}&insuranceName=${name}`
    );

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getClaimReference = async (assetId, text) => {
  try {
    const response = await httpCiient.get(
      `/v5/item/manager/company/${comCode}/web/asset/claimreference/${assetId}?indexStart=0&indexEnd=100&searchTxt=`
    );

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getAssetClaimSummaryHistory = async (payload) => {
  try {
    const response = await httpCiient.post(`/v5/item/manager/company/${comCode}/web/asset/claim/history`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const calAsset = async (payload) => {
  try {
    const response = await httpCiient.post(`/v3/item/manager/${comCode}/getassetbookvalue`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const teamAsset = async (payload) => {
  try {
    const response = await httpCiient.post(`/v3/item/manager/${comCode}/getlistteamloadmore`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const historyAsset = async (assetNo) => {
  try {
    const response = await httpCiient.get(`/v3/log/manager/asset/${assetNo}/logs/list`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const uploadAssetDoc = async (assetId, docTypeId, formData) => {
  try {
    const response = await httpCiient.post(`/v5/item/manager/company/${comCode}/web/asset/${assetId}/document-type/${docTypeId}`, formData);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

const deleteAssetDoc = async (docTypeId) => {
  try {
    const response = await httpCiient.delete(`/v5/item/manager/company/${comCode}/web/document/${docTypeId}`);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

const assetHistory = async (payload, assetNo, assetCreateAt, date) => {
  try {
    const response = await httpCiient.post(`/v3/log/manager/asset/${assetNo}/logs/list/from/${assetCreateAt}/to/${date}`, payload);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

const assetClaimById = async (claimId) => {
  try {
    const response = await httpCiient.get(`/v5/item/manager/company/${comCode}/web/asset/claim/${claimId}`);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

const assetInsuranceSaveAtt = async (formData) => {
  try {
    const response = await httpCiient.post(`/v5/item/manager/company/${comCode}/web/asset/insurance/document`, formData);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

// const assetUserList = async (name) => {
//   const body = {
//     fullname: name
//   }
//   try {
//     const response = await httpCiient.post(`/v3/resource/manager/company/${comCode}/member/search-fullname`, body);
//     if (response.status === 200) {
//       return response.data
//     } else {
//       return [];
//     }
//   } catch (error) { return []; }
// }

const uploadClaimDocument = async (payload) => {
  try {
    const response = await httpCiient.post(`/v5/item/manager/company/${comCode}/web/asset/claim/document`, payload);
    return response.data;
  } catch (error) {
    return [];
  }
};

const assetUserList = async (name) => {
  const body = {
    fullname: name,
  };
  try {
    const response = await httpCiient.post(`/v3/resource/manager/company/${comCode}/member/search-fullname`, body);
    if (response.status === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};

const deleteAssetImageInsurance = async (fileHash) => {
  try {
    const response = await httpCiient.delete(`/v5/item/manager/company/${comCode}/web/document/${fileHash}`);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

const updateClaimDocument = async (id, payload) => {
  try {
    const response = await httpCiient.put(`/v5/item/manager/company/${comCode}/web/documentinfomation/${id}`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const deleteClaimDocument = async (id) => {
  try {
    const response = await httpCiient.delete(`/v5/item/manager/company/${comCode}/web/document/${id}`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const createClaimHistory = async (payload) => {
  try {
    const response = await httpCiient.post(`/v5/item/manager/company/${comCode}/web/asset/claim`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const updateInsuranceImage = async (fileHash, body) => {
  try {
    const response = await httpCiient.put(`/v5/item/manager/company/${comCode}/web/documentinfomation/${fileHash}`, body);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

const getInsuranceOrg = async () => {
  try {
    const response = await httpCiient.get(`/v5/item/manager/company/${comCode}/web/insurancelist?indexStart=1&indexEnd=10000`);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

const saveInsurance = async (body) => {
  try {
    const response = await httpCiient.post(`/v5/item/manager/company/${comCode}/web/asset/insurance`, body);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

const getInsuranceById = async (id) => {
  try {
    const response = await httpCiient.get(`/v5/item/manager/company/${comCode}/web/asset/insurance/${id}`);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

const updateAssetInsurance = async (id, body) => {
  try {
    const response = await httpCiient.put(`/v5/item/manager/company/${comCode}/web/asset/insurance/${id}`, body);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

const deleteAssetInsurance = async (id) => {
  try {
    const response = await httpCiient.delete(`/v5/item/manager/company/${comCode}/web/asset/insurance/${id}`);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

const deleteDocByHash = async (id) => {
  try {
    const response = await httpCiient.delete(`/v5/item/manager/company/${comCode}/web/document/${id}`);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

const deleteAssetClaimHistory = async (id) => {
  try {
    const response = await httpCiient.delete(`/v5/item/manager/company/${comCode}/web/asset/claim/${id}`);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

const updateAssetClaimHistory = async (id, payload) => {
  try {
    const response = await httpCiient.put(`/v5/item/manager/company/${comCode}/web/asset/claim/${id}`, payload);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

const getAssetPreventiveList = async (payload) => {
  try {
    const response = await httpCiient.post(`/v5/item/manager/company/${comCode}/web/pmasset/search`,payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getInitialData = async (payload) => {
  try {
    const response = await httpCiient.get(`/v5/item/manager/company/${comCode}/web/pmasset/initdatacreate`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const SavetNewAssetPreventive = async (payload) => {
  try {
    const response = await httpCiient.post(`/v5/item/manager/company/${comCode}/web/pmasset/create`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const cancelAssetPreventive = async (payload) => {
  try {
    const response = await httpCiient.post(`/v5/item/manager/company/${comCode}/web/pmasset/cancel`,payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export {
  getInitialAssetClass,
  getTypeAsset,
  getBrandAsset,
  getModelAsset,
  getStatusAsset,
  getInitial,
  getMemberAsset,
  saveAsset,
  saveAssetImg,
  searchTableAsset,
  getByIdAsset,
  calAsset,
  updatAsset,
  getSuitable,
  teamAsset,
  historyAsset,
  uploadAssetDoc,
  deleteAssetDoc,
  getAssetClaimSummaryHistory,
  getAssetInsurance,
  assetHistory,
  assetClaimById,
  getAssetInsuranceByClaimDate,
  getClaimReference,
  assetInsuranceSaveAtt,
  // assetUserList,
  uploadClaimDocument,
  updateClaimDocument,
  deleteClaimDocument,
  createClaimHistory,
  assetUserList,
  deleteAssetImageInsurance,
  updateInsuranceImage,
  getInsuranceOrg,
  saveInsurance,
  getInsuranceById,
  updateAssetInsurance,
  deleteAssetInsurance,
  deleteDocByHash,
  deleteAssetClaimHistory,
  updateAssetClaimHistory,
  getAssetPreventiveList,
  getInitialData,
  SavetNewAssetPreventive,
  cancelAssetPreventive,
};
