import httpClient from '../../components/axiosClient';

const checkDocument = async (value, id = null) => {
  const comCode = localStorage.getItem('comCode');

  const body = {
    referenceDocNo: value,
    maintenanceId: id
  }

  try {
    const response = await httpClient.post(`/v3/maintenance/manager/company/${comCode}/checkdocrefno`, body);
    if (response.status === 200) {
      if (response.data.status.code === 200) {
        return true;
      } else {
        return false;
      }
    }
  } catch (error) {
    return false;
  }
}

export { checkDocument };