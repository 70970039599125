import React from 'react'
import { Modal, Form, Row, Col, Select, Input } from 'antd'
import { useIntl, FormattedMessage } from 'react-intl'
import Button02 from '../../v2/button_02'
import Button01 from '../../v2/button_01'
import LabeRequireForm from '../../label-required-form'
import { handleKeyDownDecimal2Fixed } from '../../../component-function/fnc-number'
import _ from 'lodash'
import { addTravelExpense, editTravelExpense } from '../../../controllers/travel-expenses/travel-expenses'
import { successNotification, errorNotification } from '../../v2/notification'

const { TextArea } = Input
const { Option } = Select

const AddTravelExpense = (props) => {
  const { visible, setVisible, form, type, initialDetail, defaultData, detailData, setTrigger } = props
  const intl = useIntl()
  const { getFieldDecorator, resetFields, validateFields } = form;

  const handleSave = async () => {
    validateFields(async (err, values) => {
      if (err) {
        return;
      }
      try {
        const filterItemList = _.filter(_.get(initialDetail, 'additionalItemList'), (item) => {
          return item.additionalItemId === _.get(values, 'item')
        })
        const filterItemTypeList = _.filter(_.get(initialDetail, 'additionalItemTypeList'), (item) => {
          return item.additionalItemTypeCode === _.get(values, 'type')
        })
        const saveBody = {
          "taskNo": _.get(values, 'taskNo'),
          "additionalItemId": _.get(filterItemList, '[0].additionalItemId'),
          "additionalItemName": _.get(filterItemList, '[0].additionalItemName'),
          "additionalItemTypeCode": _.get(filterItemTypeList, '[0].additionalItemTypeCode'),
          "additionalItemTypeName": _.get(filterItemTypeList, '[0].additionalItemTypeName'),
          "amount": parseFloat(_.get(values, 'amount')),
          "description": _.get(values, 'description'),
          "travelExpenseId": _.get(detailData, 'travelExpenseId')
        }
        const response = type === "add" ? await addTravelExpense(saveBody) : await editTravelExpense(_.get(defaultData, 'travelExpenseDetailId'), saveBody)
        if (_.get(response, 'data.status.code') === 200) {
          successNotification(_.get(response, 'data.status.message'))
          setTrigger(current => !current)
          setVisible(false)
          resetFields();
        } else {
          errorNotification(_.get(response, 'data.status.message'))
        }
      } catch (error) {
        errorNotification(_.get(error, 'response.data.status.message'))
      }

    })

  }

  const handleClose = () => {
    setVisible(false)
    resetFields();
  }

  const checkTaskNo = (main, record) => {
    if (type === "add") {
      if (_.get(main, 'isTrip') === false) {
        return _.get(main, 'tripTaskNo')
      } else {
        return undefined
      }
    } else if (type === "edit") {
      return _.get(record, 'taskNo')
    } else {
      return undefined
    }
  }

  return (
    <Modal
      title={type === "add" ?
        intl.formatMessage({ id: 'modalTravelExpenseAddText', defaultMessage: 'Add Travelling Expense' }) :
        intl.formatMessage({ id: 'modalTravelExpenseEditText', defaultMessage: 'Edit Travelling Expense' })
      }
      width={620}
      centered={true}
      visible={visible}
      onCancel={handleClose}
      footer={[
        <Button02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleClose}>
          <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
        </Button02>,
        <Button01 key="submit" type="primary" btnsize="wd_df" onClick={handleSave}>
          <FormattedMessage id="btnSave" defaultMessage="Save" />
        </Button01>,
      ]}
    >
      <Form form={form} layout="vertical" className='modalTravelExpenseAddForm'>
        <Row gutter={[8, 8]}>
          <Col span={12}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'modalTravelExpenseAddTaskNoText', defaultMessage: 'Task No.' })} req={true} />
              }
            >
              {getFieldDecorator('taskNo', {
                initialValue: checkTaskNo(detailData, defaultData),
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'modalTravelExpenseAddTaskNoValidate', defaultMessage: 'Please Select Task No.' }),
                  },
                ],
              })(
                <Select
                  placeholder={intl.formatMessage({ id: 'modalTravelExpenseAddTaskNoPlaceholeder', defaultMessage: 'Select Task No.' })}
                  disabled={_.get(detailData, 'isTrip') ? false : true}
                >
                  {
                    _.get(detailData, 'travelExpenseDetails') ? _.map(_.uniqBy(_.get(detailData, 'travelExpenseDetails'), 'taskNo'), (item) => (
                      <Option key={item.taskNo}>
                        {item.taskNo}
                      </Option>
                    )) : <Option key={detailData.tripTaskNo}>{detailData.tripTaskNo}</Option>
                  }
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'modalTravelExpenseAddItemText', defaultMessage: 'Item' })} req={true} />
              }
            >
              {getFieldDecorator('item', {
                initialValue: defaultData ? _.get(defaultData, "additionalItemId") : undefined,
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'modalTravelExpenseAddItemValidate', defaultMessage: 'Please Select Item' }),
                  },
                ],
              })(
                <Select
                  placeholder={intl.formatMessage({ id: 'modalTravelExpenseAddItemPlaceholeder', defaultMessage: 'Select Item' })}
                >
                  {
                    _.map(_.get(initialDetail, 'additionalItemList'), (item) => (
                      <Option key={item.additionalItemId}>
                        {item.additionalItemName}
                      </Option>
                    ))
                  }
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col span={12}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'modalTravelExpenseAddTypeText', defaultMessage: 'Type' })} req={true} />
              }
            >
              {getFieldDecorator('type', {
                initialValue: defaultData ? _.get(defaultData, "additionalItemTypeCode") : undefined,
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'modalTravelExpenseAddTypeValidate', defaultMessage: 'Please Select Type' }),
                  },
                ],
              })(
                <Select
                  placeholder={intl.formatMessage({ id: 'modalTravelExpenseAddTypePlaceholeder', defaultMessage: 'Select Type' })}
                >
                  {
                    _.map(_.get(initialDetail, 'additionalItemTypeList'), (item) => (
                      <Option key={item.additionalItemTypeCode}>
                        {item.additionalItemTypeName}
                      </Option>
                    ))
                  }
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'modalTravelExpenseAddAmountText', defaultMessage: 'Amount' })} req={true} />
              }
            >
              {getFieldDecorator('amount', {
                initialValue: defaultData ? Math.abs(parseFloat(_.get(defaultData, "amount"))) : undefined,
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'modalTravelExpenseAddAmountValidate', defaultMessage: 'Please Enter Amount' }),
                  },
                ],
              })(
                <Input
                  placeholder={intl.formatMessage({ id: 'modalTravelExpenseAddAmountPlaceholeder', defaultMessage: 'Enter Amount' })}
                  onKeyDown={handleKeyDownDecimal2Fixed}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'modalTravelExpenseAddDescriptionText', defaultMessage: 'Description' })} req={false} />
              }
            >
              {getFieldDecorator('description', {
                initialValue: defaultData ? _.get(defaultData, "description") : undefined,
              })(
                <TextArea
                  autosize={{ minRows: 3, maxRows: 3 }}
                  maxLength={250}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

const FormModalAddTravel = Form.create({
  name: 'modal_form',
  mapPropsToFields(props) { },
})(AddTravelExpense);

export default FormModalAddTravel
