import httpClient from '../../components/axiosClient';

const getTeamUser = async (orgId, body) => {
  const comId = localStorage.getItem('comId');
  try {
    const response = await httpClient.post(`/v4/resource/manager/company/comid/${comId}/orgid/${orgId}/member/elastic-search`, body);
    if (response.status == 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
}

export default getTeamUser;