import React from 'react';
import { Tag, Avatar, Icon, Popover, Button } from 'antd';
import { ButtonApprove, ButtonReject, ButtonView, ButtonEdit, ButtonCancel, ButtonDelete } from '../../components/action-table/index';
import _ from 'lodash';
import './css/index.css';
import ReactToPrint from 'react-to-print';
import { useIntl, FormattedMessage } from 'react-intl';
import { PageSettings } from '../../config/page-settings';


export default (props) => {
  // console.log('propsPage', props);
  const { data, visiblePopover, fromPage, fnc, componentRef } = props;
  const { handleViewQuotation, handleOnVisiblePopover, handleEditQuotation, handleApproved, handleReject, hanndleDelete, hanndleCancelStatus, handlePrint, hanldeAfterPrint } = fnc;

  // console.log('props view:::', data, fromPage);
  const customer = _.get(data, 'customerName');
  const sale = _.get(data, 'sale');
  const createDate = _.get(data, 'createDate');
  const statusView = _.get(data, 'statusView');
  const statusColor = _.get(data, 'statusColor');
  const profileCustomer = _.get(data, 'profileCustomer');

  const fncActionTable = (record, index) => {
    return (
      <div>
        <Popover
          key={index}
          placement="leftTop"
          trigger="hover"
          visiblePopover={visiblePopover}
          onVisibleChange={handleOnVisiblePopover}
          zIndex={999}
          content={
            <div style={{ display: 'grid' }}>
              {record.statusCode === 'cancel' ?
                <PageSettings.Consumer>
                  {({ checkPermissionAction }) =>
                    <div>
                      {
                        checkPermissionAction('P28PG1C1', 'P28PG1C1A4') ? (
                          <ButtonView onClick={handleViewQuotation} record={record} />
                        ) : null
                      }
                    </div>}
                </PageSettings.Consumer>

                :
                record.statusCode === 'approve' ?
                  <>
                    <PageSettings.Consumer>
                      {({ checkPermissionAction }) =>
                        <div>
                          {
                            checkPermissionAction('P28PG1C1', 'P28PG1C1A4') ? (
                              <ButtonView onClick={handleViewQuotation} record={record} />
                            ) : null
                          }
                          {
                            checkPermissionAction('P28PG1C1', 'P28PG1C1A9') ? (
                              <ReactToPrint
                                trigger={() => <Button
                                  style={{ width: 100 }}
                                  type="link"
                                  ghost
                                >
                                  <p><FormattedMessage id="btnPrint" defaultMessage="Print" /></p>
                                </Button>}
                                content={() => componentRef.current}
                                onBeforeGetContent={() => handlePrint(record)}
                                onAfterPrint={() => hanldeAfterPrint(_.get(record, 'quotationId'))}
                              />
                            ) : null
                          }
                        </div>}
                    </PageSettings.Consumer>
                  </>
                  :
                  record.statusCode === 'draft' ?
                    <>
                      <PageSettings.Consumer>
                        {({ checkPermissionAction }) =>
                          <div>
                            {
                              checkPermissionAction('P28PG1C1', 'P28PG1C1A4') ? (
                                <ButtonView onClick={handleViewQuotation} record={record} />
                              ) : null
                            }
                            {
                              checkPermissionAction('P28PG1C1', 'P28PG1C1A3') ? (
                                <ButtonEdit onClick={handleEditQuotation} record={record} />
                              ) : null
                            }
                            {
                              checkPermissionAction('P28PG1C1', 'P28PG1C1A8') ? (
                                <ButtonDelete onClick={hanndleDelete} record={record} />
                              ) : null
                            }
                          </div>}
                      </PageSettings.Consumer>
                    </>
                    :
                    record.statusCode === 'reject' ?
                      <>
                        <PageSettings.Consumer>
                          {({ checkPermissionAction }) =>
                            <div>
                              {
                                checkPermissionAction('P28PG1C1', 'P28PG1C1A4') ? (
                                  <ButtonView onClick={handleViewQuotation} record={record} />
                                ) : null
                              }
                            </div>}
                        </PageSettings.Consumer>
                      </>
                      :
                      record.statusCode === 'waiting' ?
                        <>
                          <PageSettings.Consumer>
                            {({ checkPermissionAction }) =>
                              <div>
                                {
                                  checkPermissionAction('P28PG1C1', 'P28PG1C1A4') ? (
                                    <ButtonView onClick={handleViewQuotation} record={record} />
                                  ) : null
                                }
                                {
                                  checkPermissionAction('P28PG1C1', 'P28PG1C1A5') ? (
                                    <ButtonApprove onClick={handleApproved} record={record} />
                                  ) : null
                                }
                                {
                                  checkPermissionAction('P28PG1C1', 'P28PG1C1A6') ? (
                                    <ButtonReject onClick={handleReject} record={record} />
                                  ) : null
                                }
                                {
                                  checkPermissionAction('P28PG1C1', 'P28PG1C1A7') ? (
                                    <ButtonCancel onClick={hanndleCancelStatus} record={record} />
                                  ) : null
                                }
                              </div>}
                          </PageSettings.Consumer>
                        </>
                        : null
              }
            </div>
          }
        >
          <Icon type="more" />
        </Popover>
      </div>
    )
  }


  return (
    <div className="view-header">
      <div className="view-card-head">
        <div className="modal-view-card-head-left">
          <div className="modal-view-card-head-left-avatar">
            <Avatar size={52} src={profileCustomer} />
          </div>
          <div className="modal-view-card-left-text">
            <div className='modal-view-card-left-text-p'>
              <p className="modal-view-card-left-text-head">
                {customer}
              </p>
              <p className="modal-view-card-left-text-dot">.</p>
              <p className="modal-view-card-text-12">
                Create by  {sale} on {createDate}
              </p>
            </div>
            <div>
              <Tag className='status-tag-view' color={statusColor}>{statusView}</Tag>
            </div>
          </div>

        </div>
        <div className="modal-view-card-head-right">
          {
            fromPage === 'engagement-quotation' ?
              <div>
                {fncActionTable(data)}
              </div>
              :
              null
          }
        </div>
      </div>
    </div>
  )
}