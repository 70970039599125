import React, { createContext, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { numberFormatter } from "../../../component-function/fnc-inputnumber-currency";
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import ModalPettyCash from "../modal";
import { exportPettyCash, getListPettyCash, getPocketPettyCash } from "../../../controllers/petty-cash/petty-cash";
import { fncGetUsers } from "../../../controllers/user/users";
import { getTeamOrg } from "../../../controllers/team/team";
import getTeamUser from "../../../controllers/user/get-user-team";
import getTeam from "../../../controllers/user/get-team";
import { Tag } from "antd";
import moment from "moment";

const PettyCashProvider = createContext();

const PettyCashContext = ({ children }) => {
  const lang = localStorage.getItem('langValue')
  const intl = useIntl()

  let defaultColumns = {
    columns: [
      {
        title: '#',
        dataIndex: 'index',
        key: 'index',
        width: 70,
        align: 'center',
        fixed: 'left',
      },
      {
        title: intl.formatMessage({ id: 'pettyCashCLPCNo', defaultMessage: 'PC No.' }),
        dataIndex: 'pettyCashNo',
        key: 'pettyCashNo',
        width: 200,
        fixed: 'left',
        sorter: true,
      },
      {
        title: intl.formatMessage({ id: 'pettyCashCLPettyCash', defaultMessage: 'Petty Cash' }),
        dataIndex: 'pettyCashName',
        key: 'pettyCashName',
        width: 250,
        sorter: true,
      },
      {
        title: intl.formatMessage({ id: 'pettyCashCLCredit', defaultMessage: 'Credit' }),
        dataIndex: 'credit',
        key: 'credit',
        width: 200,
        sorter: true,
        align: 'right',
        render: (text, record, index) => numberFormatter(_.get(record, 'credit', 0)) + " " + 'THB'
      },
      {
        title: intl.formatMessage({ id: 'pettyCashCLDebit', defaultMessage: 'Debit' }),
        dataIndex: 'debit',
        key: 'debit',
        width: 200,
        sorter: true,
        align: 'right',
        render: (text, record, index) => numberFormatter(_.get(record, 'debit', 0)) + " " + 'THB'
      },
      {
        title: intl.formatMessage({ id: 'pettyCashCLBalance', defaultMessage: 'Balance' }),
        dataIndex: 'balance',
        key: 'balance',
        width: 200,
        sorter: true,
        align: 'right',
        render: (text, record, index) => <span style={{ color: _.get(record, 'balance', 0) < 0 ? 'red' : 'unset' }}>{numberFormatter(_.get(record, 'balance', 0)) + " " + 'THB'}</span>
      },
      {
        title: intl.formatMessage({ id: 'pettyCashCLStatus', defaultMessage: 'Status' }),
        dataIndex: 'status',
        key: 'status',
        width: 200,
        sorter: true,
        render: (text, record, index) => (
          <Tag style={{ fontSize: '14px', minWidth: '100px', textAlign: 'center', borderRadius: '10px' }} color={_.get(record, 'statusColor')}>
            {_.get(record, `status[${lang}]`)}
          </Tag>
        )
      },
      {
        title: intl.formatMessage({ id: 'pettyCashCLTeam', defaultMessage: 'Team' }),
        dataIndex: 'team',
        key: 'team',
        width: 200,
        sorter: true,
        render: (text, record, index) => _.get(record, `team.teamName`)
      },
      {
        title: intl.formatMessage({ id: 'pettyCashCLResponsibleby', defaultMessage: 'Responsible By' }),
        dataIndex: 'responsibleBy',
        key: 'responsibleBy',
        width: 200,
        sorter: true,
        render: (text, record, index) => _.get(record, `responsibleBy.responsibleBy`)
      },
      {
        title: intl.formatMessage({ id: 'pettyCashCLRemark', defaultMessage: 'Remark' }),
        dataIndex: 'remark',
        key: 'remark',
        width: 200,
        sorter: true,
        render: (text, record, index) => _.get(record, `remark`, '-') !== "" ? _.get(record, `remark`, '-') : '-'
      },
      {
        title: intl.formatMessage({ id: 'pettyCashCLCreatedby', defaultMessage: 'Created By' }),
        dataIndex: 'createdBy',
        key: 'createdBy',
        width: 200,
        sorter: true,
      },
      {
        title: intl.formatMessage({ id: 'pettyCashCLCreatedDate', defaultMessage: 'Created Date' }),
        dataIndex: 'createdAt',
        key: 'createdAt',
        width: 200,
        sorter: true,
        render: (text, record, index) => moment.unix(_.get(record, 'createdAt')).format('DD/MM/YYYY')
      },
    ],
    setShowColumnArr: [
      'index',
      'pettyCashNo',
      'pettyCashName',
      'credit',
      'debit',
      'balance',
      'status',
      'team',
      'responsibleBy',
      'remark',
      'createdBy',
      'createdAt'
    ],
    setShowColumn: {
      index: true,
      pettyCashNo: true,
      pettyCashName: true,
      credit: true,
      debit: true,
      balance: true,
      status: true,
      team: true,
      responsibleBy: true,
      remark: true,
      createdBy: true,
      createdAt: true
    },
  }
  const history = useHistory();
  const [searchGroup, setSearchGroup] = useState({});
  const [tableChange, setTableChange] = useState({ page: 1, pageSize: 10, orderBy: "", orderField: "" });
  const [listArrayColumns, setListArrayColumns] = useState([..._.get(defaultColumns, 'setShowColumnArr')]);
  const [defaultShowColumn, setDefaultShowColumn] = useState({ ..._.get(defaultColumns, 'setShowColumn') });
  const actionColumns = [..._.get(defaultColumns, 'columns')]
  const ignoreColumn = []
  const newColumns = defaultColumns.columns.filter((col) => !ignoreColumn.includes(col.key));
  const [newDataColumns, setNewDataColumns] = useState([...newColumns]);
  const [textErrorSelectColumn, setTextErrorSelectColumn] = useState('');
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const [dataSource, setDataSource] = useState([])
  const [total, setTotal] = useState(0)
  const [defaultModal, setDefaultModal] = useState({ type: '', data: {}, visible: false })
  const [statusList, setStatusList] = useState([])
  const [userList, setUserList] = useState({});
  const [teamList, setTeamList] = useState({});
  const [pocketList, setPocketList] = useState([])

  useEffect(() => {
    const getInitialData = async () => {
      const memComId = localStorage.getItem('memComId');
      const getAssignee = await fncGetUsers(memComId);
      const getTeam = await getTeamOrg();
      const responsePocker = await getPocketPettyCash();
      setUserList({
        list: getAssignee,
        master: getAssignee,
      });
      setTeamList({
        list: _.get(getTeam, 'data'),
        master: _.get(getTeam, 'data'),
      });
      setPocketList(_.get(responsePocker, 'data.data'))
    };
    getInitialData();
  }, []);

  useEffect(() => {
    const getListData = async () => {
      const body = {
        limit: _.get(tableChange, 'pageSize', 10),
        orderType: _.get(tableChange, 'orderBy', ""),
        orderBy: _.get(tableChange, 'orderField', ""),
        page: _.get(tableChange, 'page', 1),
        pettyCashName: _.get(searchGroup, 'pettyCashName', ""),
        status: _.get(searchGroup, 'status'),
        poNo: _.get(searchGroup, 'pettyCashNo'),
        responsibleBy: _.get(searchGroup, 'responsibleBy'),
        team: _.get(searchGroup, 'team'),
      }
      const response = await getListPettyCash(body)
      if (_.get(response, 'data.status.code') === 200) {
        setDataSource(_.get(response, 'data.data'))
        setTotal(_.get(response, 'data.total'))
        setStatusList(_.get(response, 'data.statusCode'))
      }
    }
    getListData()
  }, [tableChange, trigger])

  const handleExport = async () => {
    const filterIndex = _.filter(defaultColumns.columns, (item) => { return item.dataIndex !== 'index' })
    const body = {
      limit: total,
      orderType: _.get(tableChange, 'orderBy', ""),
      orderBy: _.get(tableChange, 'orderField', ""),
      page: 1,
      pettyCashName: _.get(searchGroup, 'pettyCashName', ""),
      status: _.get(searchGroup, 'status'),
      poNo: _.get(searchGroup, 'pettyCashNo'),
      responsibleBy: _.get(searchGroup, 'responsibleBy'),
      team: _.get(searchGroup, 'team'),
      viewFormat: _.map(filterIndex, (item, index) => {
        return {
          colName: item.title,
          sequence: index + 1,
          colCode: item.dataIndex,
        }
      })
    }
    const response = await exportPettyCash(body)
    if (response.status === 200) {
      window.open(response.data.data.excelExportUrl);
    }
  }


  const handleTableChange = (pagination, sorter, extra) => {
    let field = extra.field;

    if (extra.order) {
      if (extra.order === 'descend') {
        setTableChange((prev) => ({ ...prev, orderBy: 'desc' }));
      } else {
        setTableChange((prev) => ({ ...prev, orderBy: 'asc' }));
      }
    } else {
      setTableChange((prev) => ({ ...prev, orderBy: '' }));
    }
    setTableChange((prev) => ({ ...prev, orderField: field, page: pagination.current, pageSize: pagination.pageSize }));
  };

  const linkToView = (record) => {
    history.push(
      '/main/accounting/petty-cash/detail/' +
      record.pettyCashId
    );
  };

  const handleOpenModal = (type, record) => {
    setDefaultModal({
      type: type,
      data: record,
      visible: true
    })
  }

  const handleChangeTeam = async (value) => {
    if (value) {
      const assignee = _.get(searchGroup, 'responsibleBy')
      const body = { search: '', searchBy: 'all', paging: 1, rowsPerPages: 1000 };
      const getAssigneeByTeam = await getTeamUser(value, body);
      const mapAssign = _.map(_.get(getAssigneeByTeam, 'data.members.list'), (item) => {
        return {
          mem_com_id: parseFloat(item.memComId),
          fullname: item.fullname,
        };
      });
      const findAssignee = _.find(mapAssign, (item) => {
        return item.mem_com_id === parseFloat(assignee);
      });
      setUserList({
        ...userList,
        list: mapAssign,
      });
      setSearchGroup({
        ...searchGroup,
        team: value,
        responsibleBy: findAssignee ? assignee : undefined
      })
    } else {
      setTeamList({ ...teamList, list: _.get(teamList, 'master') });
      setUserList({ ...userList, list: _.get(userList, 'master') });
      setSearchGroup({
        ...searchGroup,
        responsibleBy: undefined,
        team: undefined,
      })
    }
  };

  const handleChangeUser = async (value) => {
    if (value) {
      const team = _.get(searchGroup, 'team')
      const getTeamByAssignee = await getTeam(value);
      const mapTeam = _.map(getTeamByAssignee, (item) => {
        return {
          org_id: parseFloat(item.org_id),
          name: item.name,
        };
      });
      const findTeam = _.find(mapTeam, (item) => {
        return item.org_id === parseFloat(team);
      });
      setTeamList({
        ...teamList,
        list: mapTeam,
      });
      setSearchGroup({
        ...searchGroup,
        responsibleBy: value,
        team: findTeam ? team : undefined
      })
    } else {
      setTeamList({ ...teamList, list: _.get(teamList, 'master') });
      setUserList({ ...userList, list: _.get(userList, 'master') });
      setSearchGroup({
        ...searchGroup,
        team: undefined,
        responsibleBy: undefined
      })
    }
  };

  return (
    <PettyCashProvider.Provider
      value={{
        state: {
          searchGroup,
          tableChange,
          listArrayColumns,
          defaultShowColumn,
          newColumns,
          textErrorSelectColumn,
          visible,
          actionColumns,
          loading,
          defaultColumns,
          newDataColumns,
          dataSource,
          total,
          statusList,
          lang,
          userList,
          teamList
        },
        setState: {
          setSearchGroup,
          setTableChange,
          setTrigger,
          setNewDataColumns,
          setListArrayColumns,
          setDefaultShowColumn,
          setTextErrorSelectColumn,
          setVisible,
        },
        fnc: {
          handleTableChange,
          linkToView,
          handleOpenModal,
          handleChangeTeam,
          handleChangeUser,
          handleExport
        }
      }}
    >
      {children}

      <ModalPettyCash
        defaultModal={defaultModal}
        setDefaultModal={setDefaultModal}
        userListMaster={userList}
        teamListMaster={teamList}
        pocketListMaster={pocketList}
        setTrigger={setTrigger}
      />

    </PettyCashProvider.Provider>
  )
}

export { PettyCashProvider, PettyCashContext };