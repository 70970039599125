import React, { useState, useEffect, useContext } from 'react';
import { Row, Col, Icon, Card } from 'antd';
import Provider from './provider';
import OrganizationTree from './organizationTree';
import Tabs from './tabs';
import Helper from '../../modules/helper';
import { PageSettings } from '../../config/page-settings';
import styles from './css/index.css';
import { FormattedMessage } from 'react-intl';

const helper = new Helper();

export default () => {
  const app = Provider.useAppContext();
  const pageSettingsContext = useContext(PageSettings);
  const perm = pageSettingsContext.checkPermissionAction('P3PG1C1', 'P3PG1C1A1');
  let orgId = app.state.orgId;
  // const refreshSaveFrom = app.state.refreshSaveFrom;

  const [gData, setGData] = useState([]);
  const [organizationTree, setOrganizationTree] = useState([]);
  const [orgIdSelect, setOrgIdSelect] = useState();
  const [span, setSpan] = useState(18);
  const [show, setShow] = useState(true);
  const [key, setKey] = useState();

  useEffect(() => {
    const count = app.state.organizations.length;
    if (count > 0) {
      const treeData = [...app.state.organizations];
      const newTreeData = helper.setSelectOrganization(treeData);
      const newData = helper.setOrganizationData(treeData);
      setOrganizationTree([...newTreeData]);
      setGData([...newData]);
    }
  }, [app.state.organizations]);

  const changeTabLayout = () => {
    if (perm === true) {
      setSpan(18);
    } else {
      setSpan(24);
    }
  };

  return (
    <PageSettings.Consumer>
      {({ checkPermissionAction }) => (
        <Row gutter={[16, 16]} type="flex">
          <Col key="orgtree" span={6}>
            <OrganizationTree data={organizationTree} gData={gData} setOrgIdSelect={setOrgIdSelect} />
          </Col>
          <Col key="tabs" span={18} className="organization-default-col-card">
            <div>
              {/* {orgId || (app.state.parentId && app.state.addNewOrganization) ? (
                <Tabs newForm={app.state.addNewOrganization} />
              ) : (
                <Card className="organization-default-card">
                  <div className="organization-default-card-content-detail">
                    <div className="organization-default-content-detail-div-icon">
                      <Icon className="organization-default-card-content-icon" type="cluster" />
                    </div>
                    <div className="organization-default-content-detail-div-p">
                      <p className="organization-default-card-content-text">Please select a team <br /> Oreganization will appear here.</p>
                    </div>
                  </div>
                </Card>
              )} */}
              {orgId || app.state.pageDefault == true ? (
                <Tabs newForm={app.state.addNewOrganization} orgIdSelect={orgIdSelect} />
              ) : (
                <Card className="organization-default-card">
                  <div className="organization-default-card-content-detail">
                    <div className="organization-default-content-detail-div-icon">
                      <Icon className="organization-default-card-content-icon" type="cluster" />
                    </div>
                    <div className="organization-default-content-detail-div-p">
                      <p className="organization-default-card-content-text">
                        <FormattedMessage id="UserManagementOrgTxtDefaultPage1" defaultMessage="Please select a team" /> <br />
                        <FormattedMessage id="UserManagementOrgTxtDefaultPage2" defaultMessage="Oreganization will appear here." />
                      </p>
                    </div>
                  </div>
                </Card>
              )}
            </div>
          </Col>
        </Row>
      )}
    </PageSettings.Consumer>
  );
};
