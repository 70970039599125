import { Form, Row, Col, Select, Divider, InputNumber, Input, DatePicker, Icon } from 'antd';
import React, { useContext } from 'react';
import LabeRequireForm from '../../../components/label-required-form';
import { useIntl, FormattedMessage } from 'react-intl';
import { ExportOutlined } from '@ant-design/icons';
import SuffixInput from '../../../components/v2/suffix';
import { handleKeyDownDecimal2Fixed } from '../../../component-function/fnc-number';
import _ from 'lodash';
import { capitalizeFirstLetter } from '../../../component-function/utility';
import moment from 'moment';
import { PageSettings } from '../../../config/page-settings';

const { Option } = Select;

const ModalTabsDetail = (props) => {
  const {
    form,
    isCreate,
    isEdit,
    handleOpenAllowance,
    defaultModal,
    dataFilter,
    handleAddItem,
    listItem,
    notAlw,
    handleSelectMember,
    fillTeam,
    handleSelectTeam,
    fillMember,
    handleSelectExpend,
    fillExpense,
    refAmount,
  } = props;
  const { getFieldDecorator, setFieldsValue, getFieldValue } = form;
  const intl = useIntl();
  const expenseType = getFieldValue('expenseType');
  const taxType = getFieldValue('taxType');
  const app = useContext(PageSettings);
  const { checkPermissionAction } = app;
  const permissonAccess = checkPermissionAction('P81PG1C2', 'P81PG1C2A1');

  const currencyFormatter = (value) => {
    return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const currencyParser = (value) => {
    return value.replace(/\$\s?|(,*)/g, '');
  };

  const handleChangeExpenseType = (value) => {
    handleSelectExpend(value);
    if (value === 'pay') {
      setFieldsValue({
        ['expenseItem']: undefined,
        ['netAmount']: getFieldValue('amount', 2),
      });
    } else if (value === 'deduct') {
      setFieldsValue({
        ['netAmount']: -getFieldValue('amount', 2),
        ['taxType']: _.get(dataFilter, 'whtRateList[0].value'),
        ['tax']: 0,
        ['expenseItem']: undefined,
      });
    }
  };

  const handleChangeAmount = (value) => {
    if (expenseType === 'pay') {
      const calc = (value * parseFloat(taxType)) / 100;
      setFieldsValue({
        ['tax']: _.round(calc, 2),
        ['netAmount']: _.round(value - calc, 2),
      });
    } else if (expenseType === 'deduct') {
      setFieldsValue({
        ['netAmount']: _.round(-value, 2),
      });
    }
  };

  const handleChangeTax = (value) => {
    const setAmount = getFieldValue('amount') ? getFieldValue('amount') || 0 : _.get(defaultModal, 'data.amount', 0);
    const amountTemp = parseFloat(setAmount);
    const taxTemp = ((amountTemp * parseFloat(value)) / 100).toFixed(2);
    console.log('taxTemp', taxTemp, '/amountTemp', amountTemp, '/value', value);

    setFieldsValue({
      ['tax']: _.round(taxTemp, 2),
      ['netAmount']: _.round(amountTemp - taxTemp, 2),
    });
  };

  const CheckNumberType = (value) => {
    if (value === 'pay') {
      return intl.formatMessage({ id: 'resourceAllowanceModalItemPay', defaultMessage: 'Pay' })
    } else if (value === 'deduct') {
      return intl.formatMessage({ id: 'resourceAllowanceModalItemDeduct', defaultMessage: 'Deduct' })
    } else {
      return value
    }
  }

  return (
    <div style={{ height: '480px', overflowY: 'auto', padding: '24px' }}>
      <Form layout="vertical" className="memberExpenseModalForm" form={form}>
        <Row gutter={[16, 8]}>
          <Col span={12}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'memberExpenseModalTeam', defaultMessage: 'Team' })}
                  req={isCreate ? true : false}
                />
              }
            >
              {isCreate || notAlw & isEdit ? (
                getFieldDecorator('team', {
                  initialValue: _.get(defaultModal, 'data.teamId') || undefined,
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'memberExpenseModalTeamVLD', defaultMessage: 'Please Select Team' }),
                    },
                  ],
                })(
                  <Select
                    allowClear
                    placeholder={intl.formatMessage({ id: 'memberExpenseModalTeamPH', defaultMessage: 'Select Team' })}
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    showSearch
                    onChange={(e) => handleSelectTeam(e)}
                  >
                    {_.map(_.size(fillTeam) > 0 ? fillTeam : _.get(dataFilter, 'teamList'), (item, index) => (
                      <Option key={item.teamId} value={item.teamId}>
                        {item.teamName}
                      </Option>
                    ))}
                  </Select>
                )
              ) : (
                <div>{_.get(defaultModal, 'data.teamName')}</div>
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'memberExpenseModalMember', defaultMessage: 'Member' })}
                  req={isCreate || notAlw & isEdit ? true : false}
                />
              }
            >
              {isCreate || notAlw & isEdit ? (
                getFieldDecorator('member', {
                  initialValue: _.get(defaultModal, 'data.memComId') || undefined,
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'memberExpenseModalMemberVLD', defaultMessage: 'Please Select Member' }),
                    },
                  ],
                })(
                  <Select
                    allowClear
                    placeholder={intl.formatMessage({ id: 'memberExpenseModalMemberPH', defaultMessage: 'Select Member' })}
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    showSearch
                    onChange={(e) => handleSelectMember(e)}
                  >
                    {_.map(_.size(fillMember) > 0 ? fillMember : _.get(dataFilter, 'memberList'), (item, index) => (
                      <Option key={item.memComId} value={item.memComId}>
                        {item.memberName}
                      </Option>
                    ))}
                  </Select>
                )
              ) : (
                <div>{_.get(defaultModal, 'data.memberName')}</div>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col span={12}>
            {isCreate || notAlw & isEdit ? (
              <Row>
                <Col span={8}>
                  <Form.Item
                    label={
                      <LabeRequireForm
                        text={intl.formatMessage({ id: 'memberExpenseModalExpense', defaultMessage: 'Expense' })}
                        req={true}
                      />
                    }
                  >
                    {getFieldDecorator('expenseType', {
                      initialValue: _.get(defaultModal, 'data.numberType') ? _.get(defaultModal, 'data.numberType') : 'pay',
                    })(
                      <Select
                        onChange={handleChangeExpenseType}
                        className="memberExpenseFormExpenseType"
                        placeholder={intl.formatMessage({ id: 'memberExpenseModalExpenseTypePH', defaultMessage: 'Select Expense Type' })}
                      >
                        <Option key="pay">
                          <FormattedMessage id="resourceAllowanceModalItemPay" defaultMessage="Pay" />
                        </Option>
                        <Option key="deduct">
                          <FormattedMessage id="resourceAllowanceModalItemDeduct" defaultMessage="Deduct" />
                        </Option>
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col span={16}>
                  <Form.Item label={<LabeRequireForm text="" req={false} />}>
                    {getFieldDecorator('expenseItem', {
                      initialValue: _.get(defaultModal, 'data.basePayId') || undefined,
                      rules: [
                        {
                          required: true,
                          message: intl.formatMessage({ id: 'memberExpenseModalExpenseItemVLD', defaultMessage: 'Please Select Item' }),
                        },
                      ],
                    })(
                      <Select
                        className="memberExpenseFormExpenseItem"
                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        showSearch
                        placeholder={intl.formatMessage({ id: 'memberExpenseModalExpenseItemPD', defaultMessage: 'Select Item' })}
                        dropdownRender={(menu) => (
                          <div>
                            {menu}
                            {permissonAccess ? (
                              <>
                                <Divider style={{ margin: '4px 0' }} />
                                <div
                                  style={{ padding: '4px 8px', cursor: 'pointer' }}
                                  onMouseDown={(e) => e.preventDefault()}
                                  onClick={handleAddItem}
                                >
                                  <Icon type="plus" /> <FormattedMessage id="modalBasePayFormItemAddItem" defaultMessage="Add Item" />
                                </div>
                              </>
                            ) : null}
                          </div>
                        )}
                      >
                        {_.map(fillExpense, (item, index) => (
                          <Option key={item.basePayId} value={item.basePayId}>
                            {item.basePayName}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            ) : (
              <Form.Item
                label={
                  <LabeRequireForm text={intl.formatMessage({ id: 'memberExpenseModalExpense', defaultMessage: 'Expense' })} req={false} />
                }
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div className="memberExpenseAllowanceTag">
                    {_.get(defaultModal, 'data.fromAllowance')
                      ? _.get(defaultModal, 'data.basePayName')
                      : CheckNumberType(_.get(defaultModal, 'data.numberType'))}
                  </div>
                  <div
                    className="memberExpenseAllowanceLink"
                    style={{ cursor: 'pointer', color: _.get(defaultModal, 'data.fromAllowance') ? undefined : '#050505' }}
                    onClick={() => {
                      if (_.get(defaultModal, 'data.fromAllowance')) handleOpenAllowance(_.get(defaultModal, 'data.allowanceId'));
                    }}
                  >
                    {_.get(defaultModal, 'data.fromAllowance')
                      ? _.get(defaultModal, 'data.allowanceNo')
                      : _.get(defaultModal, 'data.basePayName')}
                    {_.get(defaultModal, 'data.fromAllowance') ? <ExportOutlined style={{ marginLeft: '8px' }} /> : null}
                  </div>
                </div>
              </Form.Item>
            )}
          </Col>
          <Col span={12}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'memberExpenseModalAmount', defaultMessage: 'Amount' })}
                  req={isCreate || notAlw & isEdit ? true : false}
                />
              }
            >
              {isCreate || notAlw & isEdit ? (
                <>
                  {getFieldDecorator('amount', {
                    initialValue:
                      _.get(defaultModal, 'data.numberType') === 'deduct'
                        ? -_.get(defaultModal, 'data.amount')
                        : _.get(defaultModal, 'data.amount') || 0,
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({ id: 'memberExpenseModalAmountVLD', defaultMessage: 'Please Enter Amount' }),
                      },
                    ],
                  })(
                    <InputNumber
                      ref={refAmount}
                      className="disabled-handle-count"
                      placeholder={intl.formatMessage({ id: 'memberExpenseModalAmountPH', defaultMessage: 'Enter Amount' })}
                      onKeyDown={handleKeyDownDecimal2Fixed}
                      formatter={currencyFormatter}
                      parser={currencyParser}
                      controls={false}
                      onChange={handleChangeAmount}
                    />
                  )}
                  <SuffixInput text="THB" />
                </>
              ) : (
                <div>{_.get(defaultModal, 'data.amountTxt')}</div>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Divider type="horizontal" />
        <Row gutter={[16, 8]}>
          <Col span={12}>
            {isCreate || isEdit ? (
              <Row>
                <Col span={8}>
                  <Form.Item
                    label={
                      <LabeRequireForm
                        text={intl.formatMessage({ id: 'memberExpenseModalWithholdingTax', defaultMessage: 'Withholding Tax' })}
                        req={false}
                      />
                    }
                  >
                    {getFieldDecorator('taxType', {
                      initialValue: _.get(defaultModal, 'data.whtRate') || _.get(dataFilter, 'whtRateList[0].value'),
                    })(
                      <Select
                        onChange={handleChangeTax}
                        className="memberExpenseFormExpenseType"
                        placeholder={intl.formatMessage({ id: 'memberExpenseModalTaxTypePH', defaultMessage: 'Select Tax Type' })}
                        disabled={
                          expenseType === 'pay'
                            ? false
                            : expenseType === 'deduct' || _.get(defaultModal, 'data.numberType') === 'deduct'
                              ? true
                              : false
                        }
                      >
                        {_.map(_.get(dataFilter, 'whtRateList'), (item, index) => (
                          <Option key={item.value} value={item.value}>
                            {item.code}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col span={16}>
                  <Form.Item label={<LabeRequireForm text="" req={false} />}>
                    {getFieldDecorator('tax', {
                      initialValue: _.get(defaultModal, 'data.wht') || 0,
                    })(
                      <InputNumber
                        disabled={
                          expenseType === 'pay'
                            ? false
                            : expenseType === 'deduct' || _.get(defaultModal, 'data.numberType') === 'deduct'
                              ? true
                              : false
                        }
                        className="memberExpenseFormAmount disabled-handle-count"
                        placeholder={intl.formatMessage({ id: 'memberExpenseModalTaxPH', defaultMessage: 'Enter Tax' })}
                        onKeyDown={handleKeyDownDecimal2Fixed}
                        formatter={currencyFormatter}
                        parser={currencyParser}
                        controls={false}
                        readOnly={true}
                      />
                    )}
                    <SuffixInput text="THB" />
                  </Form.Item>
                </Col>
              </Row>
            ) : (
              <Form.Item
                label={
                  <LabeRequireForm
                    text={intl.formatMessage({ id: 'memberExpenseModalWithholdingTax', defaultMessage: 'Withholding Tax' })}
                    req={false}
                  />
                }
              >
                <div>
                  {_.get(defaultModal, 'data.whtRateTxt')}
                  {' - '} {_.get(defaultModal, 'data.whtTxt')}
                </div>
              </Form.Item>
            )}
          </Col>
          <Col span={12}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'memberExpenseModalNetAmount', defaultMessage: 'Net Amount' })}
                  req={false}
                />
              }
            >
              {isCreate || isEdit ? (
                <>
                  {getFieldDecorator('netAmount', { initialValue: _.get(defaultModal, 'data.netAmount') || 0 })(
                    <Input style={{ color: '#050505 ' }} disabled={true} />
                  )}
                  <SuffixInput text="THB" />
                </>
              ) : (
                <div>{_.get(defaultModal, 'data.netAmountTxt')}</div>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col span={12}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'memberExpenseModalPlannedPaymentDate', defaultMessage: 'Planned Payment Date' })}
                  req={false}
                />
              }
            >
              {isCreate || isEdit ? (
                getFieldDecorator('planPaymentDate', {
                  initialValue:
                    _.get(defaultModal, 'data.planedPaymentDate') === '-'
                      ? ''
                      : _.get(defaultModal, 'data.planedPaymentDate')
                        ? moment(_.get(defaultModal, 'data.planedPaymentDate'))
                        : '',
                })(
                  <DatePicker
                    style={{ width: '100%' }}
                    placeholder={intl.formatMessage({
                      id: 'memberExpenseModalPlannedPaymentDatePH',
                      defaultMessage: 'Select Planned Payment Date',
                    })}
                    format={'DD/MM/YYYY'}
                  />
                )
              ) : (
                <div>{_.get(defaultModal, 'data.planedPaymentDateTxt') ? _.get(defaultModal, 'data.planedPaymentDateTxt') : '-'}</div>
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'memberExpenseModalReference', defaultMessage: 'Reference' })}
                  req={false}
                />
              }
            >
              {isCreate || isEdit ? (
                getFieldDecorator('reference', {
                  initialValue: _.get(defaultModal, 'data.refereance') === '-' ? '' : _.get(defaultModal, 'data.refereance') || '',
                })(<Input placeholder={intl.formatMessage({ id: 'memberExpenseModalReferencePHD', defaultMessage: 'Enter Reference' })} />)
              ) : (
                <div>{_.get(defaultModal, 'data.refereance')}</div>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col span={24}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'memberExpenseModalRemark', defaultMessage: 'Remark' })} req={false} />
              }
            >
              {isCreate || isEdit ? (
                getFieldDecorator('remark', {
                  initialValue: _.get(defaultModal, 'data.remark') === '-' ? '' : _.get(defaultModal, 'data.remark') || '',
                })(<Input placeholder={intl.formatMessage({ id: 'memberExpenseModalRemarkPHD', defaultMessage: 'Enter Remark' })} />)
              ) : (
                <div>{_.get(defaultModal, 'data.remark')}</div>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default ModalTabsDetail;
