import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import _ from 'lodash';
import { getQuotationByIdWebView } from '../../../controllers/quotation/quotation-api';
import PrintPreview from '../../../components/print-report/view-quotation';
import MediaQuery from 'react-responsive'
import { useQuery } from '../../../helpers/general-helper';
import BlankPage from '../../../components/image/NodataBlankPage.svg'
import './index.css'

export default () => {

  const paramQuotationid = useParams();
  const query = useQuery();
  const quotationId = _.get(paramQuotationid, 'quotationId')
  const [data, setData] = useState([])
  const [dataHeader, setDataHeader] = useState()
  const [selectDiscount, setSelectDiscount] = useState()
  const [statusAPI, setStatusAPI] = useState()

  useEffect(() => {
    const getDataQuotation = async () => {
      const response = await getQuotationByIdWebView(quotationId, query)
      if (_.size(response) !== 0) {
        setData(_.get(response, 'data[0]'))
        setDataHeader(_.get(response, 'comInfo'))
        const filter = _.get(response, 'dataDiscount').filter(item => { return item.discountId === _.get(response, 'data[0].discountId') })
        if (_.size(filter) !== 0) {
          setSelectDiscount(_.get(filter, '[0]'))
          setStatusAPI(true)
        } else {
          setStatusAPI(false)
        }
      } else {
        setStatusAPI(false)
      }


    }
    getDataQuotation();

  }, [quotationId]);

  return (
    <div>
      <MediaQuery minWidth={600}>
        {statusAPI === true ? (
          <PrintPreview data={data} dataHeader={dataHeader} selectDiscount={selectDiscount} />
        ) : statusAPI === false ? (
          <div className='blankPage-img-centerdiv'>
            <div className='blankPage-img-align'>
              <img src={BlankPage} className='blankPage-img-size'></img>
              <div className='blankPage-img-divtext'>
                <div className='blankPage-text-1'>No Data Found</div>
                <div className='blankPage-text-2'>unable to find the data that you're looking for</div>
                <div>Contact us to get help by </div>
                <div>contactme@kgcorp.com or +66917740033</div>
              </div>
            </div>
          </div>
        ) : null}
      </MediaQuery>
    </div>
  )
}
