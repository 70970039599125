import React, { useContext } from 'react';
import { Col, Row, Tag } from 'antd'
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import { TaskDetailContext } from '../context';

const ModalTaskDetailStatus = () => {
  const { state } = useContext(TaskDetailContext);
  const { detailData } = state
  const information = _.get(detailData, 'information')

  return (
    <div className='taskDetailTextStatusText'>
      <Row>
        <Col span={7}>
          <span style={{ color: '#1d3557', fontSize: '14px' }}>
            <FormattedMessage id="taskDetailStatusText" defaultMessage="Status" />
          </span>
        </Col>
        <Col span={17}>
          <Tag style={{ borderRadius: '10px', minWidth: '50px', textAlign: 'center' }} color={_.get(information, 'information.statusColor')}>
            {_.get(information, 'information.statusName')}
          </Tag>
        </Col>
      </Row>
    </div>
  );
}

export default ModalTaskDetailStatus
