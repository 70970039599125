import React, { useState, useEffect } from 'react'
import { Modal, Form, Row, Col, Input, Select, DatePicker, AutoComplete, Spin } from 'antd';
import Button_02 from '../../../components/v2/button_02';
import Button_01 from '../../../components/v2/button_01';
import { useIntl, FormattedMessage } from 'react-intl';
import LabeRequireForm from '../../../components/label-required-form';
import { getItemsCodeNew, getItemsMasterNew, getWarehouseAdjust, warehouseAdjustIn, warehouseAdjustOut } from '../../../controllers/warehouse/warehouse';
import _ from 'lodash'
import './css/index.css'
import { handleKeyDownNumber } from '../../../component-function/fnc-number';
import { getLocationByItem } from '../../../controllers/warehouse/warehouse';
import { getByCategoryDataByItemMaster, getByConvertDataByItemMaster } from '../../../controllers/uom/uom'
import { useDebounce } from '../../../controllers/debounce'
import { successNotification, errorNotification } from '../../../components/v2/notification'
import moment from 'moment';
import { getConvertUoMBigger } from '../../../controllers/po/po';

const { Option } = Select
const { TextArea } = Input
let testData = []
for (let index = 0; index < 30; index++) {
  testData.push(index)

}

const WarehouseModalAdjustForm = (props) => {
  const { visible, setVisible, form, warehouseId, setTrigger, defaultAdjust } = props
  const { validateFields, getFieldDecorator, setFieldsValue, getFieldValue, resetFields } = form
  const intl = useIntl()
  const [itemCodeList, setItemCodeList] = useState([]);
  const [itemNameList, setItemNameList] = useState([])
  const [selectItem, setSelectItem] = useState()
  const [lotNoSelect, setLotNoSelect] = useState("lot")
  const [uomData, setUomData] = useState([])
  const [binLocationList, setBinLocationList] = useState([])
  const [size, setSize] = useState(10)
  const [search, setSearch] = useState("")
  const [lotNoData, setLotNoData] = useState([])
  const [itemCodeSearch, setItemCodeSearch] = useState("")
  const [itemNameSearch, setItemNameSearch] = useState("")
  const debounceSearch = useDebounce(search, 500)
  const debounceItemCodeSearch = useDebounce(itemCodeSearch, 500)
  const debounceItemNameSearch = useDebounce(itemNameSearch, 500)
  const [disbled, setDisbled] = useState(false)


  useEffect(() => {
    const getItemCodeAndName = async () => {
      const payloadCode = {
        searchName: itemCodeSearch ? itemCodeSearch : _.get(defaultAdjust, 'itemMasterDetail.itemCode'),
      };
      const payloadName = {
        searchName: itemNameSearch ? itemNameSearch : _.get(defaultAdjust, 'itemMasterDetail.itemName'),
      };
      const responseItemCode = await getItemsCodeNew(payloadCode);
      const responseItemName = await getItemsMasterNew(payloadName);
      const listItemCode = _.get(responseItemCode.data.data, 'itemMasterList');
      const listItemName = _.get(responseItemName.data.data, 'itemMasterList');
      setItemCodeList(_.uniqBy(_.concat(itemCodeList, listItemCode), 'itemMasterId'));
      setItemNameList(_.uniqBy(_.concat(itemNameList, listItemName), 'itemMasterId'));

    };
    if (defaultAdjust) {
      getItemCodeAndName();
    }
  }, [defaultAdjust, debounceItemCodeSearch, debounceItemNameSearch]);

  useEffect(() => {
    const GetLotNo = async () => {
      const body = {
        "searchNo": debounceSearch,
        "numberType": lotNoSelect,
        "indexStart": 0,
        "indexEnd": size,
        "itemMasterId": _.get(selectItem, 'itemMasterId') ? _.get(selectItem, 'itemMasterId') : _.get(defaultAdjust, 'rawData.itemMasterDetail.itemMasterId'),
        "warehouseId": warehouseId
      }
      const response = await getWarehouseAdjust(body)
      setLotNoData(_.get(response, 'data.data.data'))
    }
    if (getFieldValue("adjustType") === "out" && defaultAdjust && warehouseId) {
      GetLotNo()
    }
  }, [getFieldValue("adjustType"), size, selectItem, warehouseId, debounceSearch, lotNoSelect, defaultAdjust])

  const handleScroll = (e) => {
    const target = e.target;
    if (target.scrollHeight - target.scrollTop === target.clientHeight) {
      setSize(size + 10)
    }
  }

  const handleSearch = (value) => {
    setSearch(value)
  }


  useEffect(() => {
    const getLocationForStock = async () => {
      const payload = {
        itemMasterDetail: selectItem ? selectItem : _.get(defaultAdjust, 'rawData.itemMasterDetail'),
        warehouseId: warehouseId,
      };
      const response = await getLocationByItem(payload);
      setBinLocationList(_.get(response.data.data, 'binLocationList'));
    };
    if (warehouseId && defaultAdjust) {
      getLocationForStock();
    }
  }, [selectItem, warehouseId, defaultAdjust])

  useEffect(() => {
    const getUoM = async () => {
      const payload = {
        itemMasterId: _.get(selectItem, "itemMasterId") ? _.get(selectItem, "itemMasterId") : _.get(defaultAdjust, 'rawData.itemMasterDetail.itemMasterId'),
        
      };
      // const getDataUoM = await getByConvertDataByItemMaster(payload)
      const getDataUoM = await getConvertUoMBigger(_.get(selectItem, "itemMasterId") ? _.get(selectItem, "itemMasterId") : _.get(defaultAdjust, 'rawData.itemMasterDetail.itemMasterId'));
      // const getDataUoM = await getByCategoryDataByItemMaster(_.get(selectItem, "itemMasterId") ? _.get(selectItem, "itemMasterId") : _.get(defaultAdjust, 'rawData.itemMasterDetail.itemMasterId'))
      setUomData(_.get(getDataUoM, 'data.data'))
    };
    if (warehouseId && defaultAdjust) {
      getUoM();
    }
  }, [selectItem, warehouseId, defaultAdjust])

  console.log("defaultAdjust", uomData)


  const handleSave = () => {
    validateFields(async (err, values) => {
      if (err) {
        return;
      }
      setDisbled(true)
      const memComId = localStorage.getItem('memComId');
      if (values.adjustType === "in") {
        try {
          const body = {
            "warehouseId": warehouseId,
            "itemMasterId": _.get(selectItem, 'itemMasterId') ? _.get(selectItem, 'itemMasterId') : _.get(defaultAdjust, 'rawData.itemMasterDetail.itemMasterId'),
            "actionById": memComId,
            "receivedDate": moment().format("YYYY-MM-DD"),
            "numberType": lotNoSelect,
            "lotOrSerialNo": _.get(values, 'lotNo') || "",
            "qty": parseFloat(_.get(values, 'qty')),
            "cost": 0,
            "binLocationId": _.get(values, 'bin') || "",
            "expiredDate": _.get(values, 'expiredDate') ? moment(_.get(values, 'expiredDate')).format("YYYY-MM-DD") : "",
            "uomId": _.get(values, 'uom') || "",
            "remark": _.get(values, 'remark') || ""
          }
          const response = await warehouseAdjustIn(body)
          // if (_.get(response, 'data.status.code') === 1) {
            setDisbled(false)
            successNotification(_.get(response, 'data.status.message'))
            setTrigger(cur => !cur)
            setVisible(false)
            resetFields()
            resetFields()
            setSelectItem()
            setLotNoSelect("lot")
          // } else {
          //   setDisbled(false)
          //   errorNotification(_.get(response, 'data.status.message'))
          // }
        } catch (error) {
          setDisbled(false)
          errorNotification(_.get(error, 'response.data.status.message'))
        }
      } else {
        try {
          const filter = _.filter(lotNoData, (item) => { return item.movementId === _.get(values, 'lotNo') })
          const body = {
            "itemMasterId": _.get(selectItem, 'itemMasterId') ? _.get(selectItem, 'itemMasterId') : _.get(defaultAdjust, 'rawData.itemMasterDetail.itemMasterId'),
            "requestorId": memComId,
            "expectDate": moment().format("YYYY-MM-DD"),
            "remark": _.get(values, 'remark') || "",
            "warehouseId": warehouseId,
            "qty": parseFloat(_.get(values, 'qty')),
            "numberType": lotNoSelect,
            "movementId": _.get(filter, '[0].movementId'),
            "uomId": _.get(values, 'uom') || "",
            "binLocationId": _.get(values, 'bin') || "",
          }
          const response = await warehouseAdjustOut(body)
          if (_.get(response, 'data.status.code') === 200) {
            setDisbled(false)
            successNotification(_.get(response, 'data.status.message'))
            setTrigger(cur => !cur)
            setVisible(false)
            resetFields()
            setSelectItem()
            setLotNoSelect("lot")
          } else {
            setDisbled(false)
            errorNotification(_.get(response, 'data.status.message'))
          }
        } catch (error) {
          setDisbled(false)
          errorNotification(_.get(error, 'response.data.status.message'))

        }

      }

    })
  }

  const handleClose = () => {
    setVisible(false)
    resetFields()
    setSelectItem()
    setLotNoSelect("lot")
  }

  const handleSelectCode = (value) => {
    if (value) {
      const filterArr = itemCodeList.filter((item) => item.itemMasterId === value);
      setSelectItem(_.get(filterArr, '[0]'));
      setFieldsValue({
        itemName: _.get(filterArr[0], 'itemName'),
      });
    }
  };

  const handleSelectName = (value) => {
    if (value) {
      const filterArr = itemNameList.filter((item) => item.itemMasterId === value);
      setSelectItem(_.get(filterArr, '[0]'));
      setFieldsValue({
        itemCode: _.get(filterArr[0], 'itemCode'),
      });
    }
  };

  const handleSelectLot = (value) => {
    setLotNoSelect(value)
  }
  console.log("lotNo", getFieldValue('lotNo'))

  const validatorMaximum = (rule, value, callback) => {
    const lotNo = getFieldValue('lotNo')
    const filter = _.filter(lotNoData, (item) => { return item.movementId === lotNo })
    if (value && _.size(filter) !== 0) {
      if (parseFloat(value) > _.get(filter, '[0].count')) {
        callback(<FormattedMessage id="warehouseAdjustValidateQty" defaultMessage="Qty more than base qty." />);
      } else {
        callback();
      }
      callback();
    } else {
      callback();
    }
  };


  const handleSearchItemCode = (value) => {
    setItemCodeSearch(value)
  }

  const handleSearchItemName = (value) => {
    setItemNameSearch(value)
  }

  return (
    <Modal
      title={intl.formatMessage({ id: `warehouseAdjustTitle`, defaultMessage: 'Adjust Item' })}
      centered={true}
      width={767}
      visible={visible}
      onCancel={handleClose}
      bodyStyle={{ padding: '14px 24px 24px 24px' }}
      footer={[
        <Button_02 style={{ margin: '0px 0px 0px 10px' }} disabled={disbled} key="back" btnsize="wd_df" margin=" unset" onClick={() => handleClose()}>
          <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
        </Button_02>,
        <Button_01 style={{ margin: '0px 0px 0px 10px' }} disabled={disbled} key="submit" type="primary" btnsize="wd_df" onClick={() => handleSave()} >
          <FormattedMessage id="btnSave" defaultMessage="Save" />
        </Button_01>,
      ]}
    >
      <Spin spinning={disbled}>
        <Form colon={false} form={form} className='modalAdjustForm'>
          <Row gutter={[24, 8]}>
            <Col span={12}>
              <Form.Item
                label={
                  <LabeRequireForm
                    text={intl.formatMessage({ id: 'warehouseAdjustItemCodeTitle', defaultMessage: 'Item Code' })}
                    req={true}
                  />
                }
              >
                {getFieldDecorator('itemCode', {
                  initialValue: _.get(defaultAdjust, 'rawData.itemMasterDetail.itemMasterId'),
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'warehouseAdjustItemCodeValidate', defaultMessage: 'Please Select Item Code' }),
                    },
                  ],
                })(
                  <AutoComplete
                    onSearch={handleSearchItemCode}
                    onSelect={handleSelectCode}
                    showSearch={true}
                    placeholder={intl.formatMessage({ id: 'warehouseAdjustItemCodePlaceholder', defaultMessage: 'Select Item Code' })}
                  >
                    {itemCodeList &&
                      itemCodeList.map((item) => (
                        <Option key={item.itemCode} value={item.itemMasterId}>
                          {item.itemCode}
                        </Option>
                      ))}
                  </AutoComplete>
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <LabeRequireForm
                    text={intl.formatMessage({ id: 'warehouseAdjustItemNameTitle', defaultMessage: 'Item Name' })}
                    req={true}
                  />
                }
              >
                {getFieldDecorator('itemName', {
                  initialValue: _.get(defaultAdjust, 'rawData.itemMasterDetail.itemMasterId'),
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'warehouseAdjustItemCodeValidate', defaultMessage: 'Please Select Item Name' }),
                    },
                  ],
                })(
                  <AutoComplete
                    onSearch={handleSearchItemName}
                    onSelect={handleSelectName}
                    showSearch={true}
                    placeholder={intl.formatMessage({ id: 'warehouseAdjustItemNamePlaceholeder', defaultMessage: 'Select Item Name' })}
                  >
                    {itemNameList &&
                      itemNameList.map((item, index) => (
                        <Option key={item.itemCode} value={item.itemMasterId}>
                          {item.itemName}
                        </Option>
                      ))}
                  </AutoComplete>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[24, 8]}>
            <Col span={12}>
              <Form.Item
                label={
                  <LabeRequireForm
                    text={intl.formatMessage({ id: 'warehouseAdjustAdjustTypeTitle', defaultMessage: 'Adjust Type' })}
                    req={true}
                  />
                }
              >
                {getFieldDecorator('adjustType', {
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'warehouseAdjustAdjustTypeValidate', defaultMessage: 'Please Select Adjust Type' }),
                    },
                  ],
                })(
                  <Select
                    placeholder={intl.formatMessage({ id: 'warehouseAdjustAdjustTypePlaceholeder', defaultMessage: 'Select Item Name' })}
                  >
                    <Option key="in">
                      In
                    </Option>
                    <Option key="out">
                      Out
                    </Option>
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <LabeRequireForm text={intl.formatMessage({ id: 'warehouseAdjustLotNoTitle', defaultMessage: 'Add Lot No. or Serial No.' })} req={true} />
                }
              >
                <div style={{ display: 'flex', width: '100%' }}>
                  <div style={{ width: '40%' }}>
                    <Select className='modalAdjustSelect' value={lotNoSelect} onSelect={handleSelectLot}>
                      <Option value="lot">Lot No.</Option>
                      <Option value="serial">Serial</Option>
                    </Select>
                  </div>
                  {
                    getFieldValue("adjustType") === "out" ? (
                      <div style={{ width: '60%' }}>
                        {getFieldDecorator('lotNo', {
                          rules: [
                            {
                              required: true,
                              message: intl.formatMessage({ id: 'warehouseAdjustLotNoValidate', defaultMessage: 'Please Enter Lot No. or Serial No.' }),
                            },
                          ],
                        })(
                          <AutoComplete
                            className='modalAdjustSelectLot'
                            placeholder={intl.formatMessage({ id: 'modalLotNoLotNoSelectPlaceholeder', defaultMessage: 'Select Lot No. or Serial No.' })}
                            onPopupScroll={handleScroll}
                            showSearch
                            onSearch={handleSearch}
                            dataSource={lotNoData}
                          >
                            {
                              lotNoData && lotNoData.map((item) => <Option value={item.movementId}>{item.lotOrSerialNo}</Option>)
                            }
                          </AutoComplete>
                        )}
                      </div>
                    ) : (
                      <div style={{ width: '60%' }}>
                        {getFieldDecorator('lotNo', {
                          rules: [
                            {
                              required: true,
                              message: intl.formatMessage({ id: 'warehouseAdjustLotNoValidate', defaultMessage: 'Please Enter Lot No. or Serial No.' }),
                            },
                          ],
                        })(
                          <Input
                            className='modalAdjustInput'
                            placeholder={intl.formatMessage({ id: 'modalLotNoLotNoPlaceholeder', defaultMessage: 'Enter Lot No. or Serial No.' })}
                          />
                        )}
                      </div>
                    )
                  }
                </div>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[24, 8]}>
            <Col span={12}>
              <Form.Item
                label={
                  <LabeRequireForm
                    text={intl.formatMessage({ id: 'warehouseAdjustQtyTitle', defaultMessage: 'Qty' })}
                    req={true}
                  />
                }
              >
                {getFieldDecorator('qty', {
                  initialValue: lotNoSelect === "lot" ? undefined : 1,
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'warehouseAdjustQtyValidate', defaultMessage: 'Please enter Qty' }),
                    },
                    {
                      validator: validatorMaximum,
                    }
                  ],
                })(
                  <Input
                    disabled={lotNoSelect === "lot" ? false : true}
                    onKeyDown={handleKeyDownNumber}
                    placeholder={intl.formatMessage({
                      id: 'warehouseAdjustQtyPlaceholder',
                      defaultMessage: 'Enter Qty',
                    })}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <LabeRequireForm
                    text={intl.formatMessage({ id: 'warehouseAdjustUoMTitle', defaultMessage: 'UoM' })}
                    req={true}
                  />
                }
              >
                {getFieldDecorator('uom', {
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'warehouseAdjustUoMValidate', defaultMessage: 'Please Select UoM' }),
                    },
                  ],
                })(
                  <Select
                    placeholder={intl.formatMessage({ id: 'warehouseAdjustUoMPlaceholder', defaultMessage: 'Select UoM' })}
                    showSearch
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    disabled={getFieldValue('itemName') || getFieldValue('itemCode') ? false : true}
                  >
                    {uomData &&
                      uomData.map((item) => (
                        <Option key={item.uomId} value={item.uomId}>
                          {item.name}
                        </Option>
                      ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[24, 8]}>
            <Col span={12}>
              <Form.Item
                label={
                  <LabeRequireForm
                    text={intl.formatMessage({ id: 'warehouseAdjustExpiredDateTitle', defaultMessage: 'Expired Date' })}
                    req={false}
                  />
                }
              >
                {getFieldDecorator('expiredDate', {
                })(
                  <DatePicker
                    placeholder={intl.formatMessage({
                      id: 'warehouseAdjustExpiredDatePlaceholder',
                      defaultMessage: 'Select Expired Date',
                    })}
                    style={{ width: '100%' }}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <LabeRequireForm
                    text={intl.formatMessage({ id: 'warehouseAdjustBinLocationTitle', defaultMessage: 'Bin Location' })}
                    req={false}
                  />
                }
              >
                {getFieldDecorator('bin', {

                })(
                  <Select
                    placeholder={intl.formatMessage({ id: 'warehouseAdjustBinLocationPlaceholder', defaultMessage: 'Select Bin Location' })}
                    showSearch
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    disabled={getFieldValue('itemName') || getFieldValue('itemCode') ? false : true}
                  >
                    {binLocationList &&
                      binLocationList.map((item) => (
                        <Option key={item.binLoationId} value={item.binLoationId} label={item.name}>
                          {item.name}
                        </Option>
                      ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[24, 8]}>
            <Col span={24}>
              <Form.Item
                label={
                  <LabeRequireForm
                    text={intl.formatMessage({ id: 'warehouseAdjustRemarkTitle', defaultMessage: 'Remark' })}
                    req={false}
                  />
                }
              >
                {getFieldDecorator('remark', {
                })(
                  <TextArea maxLength={250} autoSize={{ minRows: 4, maxRows: 4 }} />
                )}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  )
}

const WarehouseAdjustModalForm = Form.create({
  name: 'warehouse-form',
})(WarehouseModalAdjustForm);

export default WarehouseAdjustModalForm;
