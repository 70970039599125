import React, { useEffect } from 'react';
import {
  ScheduleComponent,
  ViewsDirective,
  ViewDirective,
  TimelineViews,
  TimelineMonth,
  Inject,
  EventRenderedArgs,
  ResourcesDirective,
  ResourceDirective,
  ResourceDetails,
  ActionEventArgs,
  RenderCellEventArgs,
  PopupOpenEventArgs,
  Resize,
  DragAndDrop,
} from '@syncfusion/ej2-react-schedule';
import { extend, isNullOrUndefined } from '@syncfusion/ej2-base';
import { useIntl, FormattedMessage } from 'react-intl';
import moment from 'moment';
import { fncResizeStopApi, fncDragStopApi } from '../../../transportation/gantt-view/fnc-action-api';
import _ from 'lodash';
import '../css/gantt.css';

export default React.memo(
  (props) => {
    const {
      scheduleObjNew,
      handleEventClick,
      onNavigating,
      handleClickResource,
      onCreatedNowClock,
      setTriggerDragDrop,
      LangCode,
      selectGroupBy,
      setStatusDragDrop,
      checkTaskNo,
      setMainVisibleCreateTask,
      setInfoSchedule,
    } = props;
    const intl = useIntl();
    const dateTimeNew = 'ddd, MMM DD,YYYY h:mm A';
    // const dateTimeView = 'MMM dd, YYYY';
    const dateMoment = new Date(2022, 2, 14);
    const dateTimeApi = 'YYYY-MM-DD HH:mm';

    const onRenderCell = (args) => {
      let txtName = intl.formatMessage({ id: 'monitorGanttViewTxtName', defaultMessage: 'Name' });
      if (args.elementType === 'emptyCells' && args.element.classList.contains('e-resource-left-td')) {
        let target = args.element.querySelector('.e-resource-text');
        target.innerHTML = `<div style='padding: 0px 15px;'><span style='font-size: 14px;'>${txtName}</span></div>`;
      }
    };

    console.log('scheduleObjNew',scheduleObjNew.current);

    const onEventRendered = (args) => {
      console.log('checkTaskNo ::', args.data);
      args.element.querySelector('.e-inner-wrap').style.padding = '0px 8px 0px 8px';
      args.element.style.backgroundColor = args.data.Color;
      args.element.querySelector('.e-appointment-details').style.justifyContent = 'center';
      args.element.querySelector('.e-appointment-details').style.alignItems = 'center';
      args.element.querySelector('.e-appointment-details').style.display = 'flex';
      args.element.querySelector('.e-time').style.display = 'none';
      args.element.querySelector('.e-subject').style.justifyContent = 'center';
      args.element.querySelector('.e-subject').style.alignItems = 'center';
      args.element.querySelector('.e-subject').style.fontSize = '12px';
      args.element.querySelector('.e-subject').innerHTML = args.data.TagTask;
    };

    const onDataBinding = (e) => {
      let items = e.result;
      let scheduleData = [];
      if (items.length > 0) {
        items.forEach((item) => {
          scheduleData.push(item);
        });
      }
      e.result = scheduleData;
    };

    const tooltipData = (props) => {
      let start = moment(props.StartTime).format(dateTimeNew);
      let end = moment(props.EndTime).format(dateTimeNew);
      return (
        <div style={{ backgroundColor: 'whtie', padding: '13px' }}>
          <div style={{ padding: '2px' }}>
            <span style={{ paddingRight: '4px' }}>
              <FormattedMessage id={`monitorScheduleTaskNo${LangCode}`} defaultMessage="Task No" />:
            </span>
            <span style={{ paddingRight: '4px' }}>{props.TaskNo}</span>
            &nbsp;
            <span className="dot-tooltip" />
            &nbsp;
            <span style={{ paddingLeft: '4px' }}>{props.Status}</span>
          </div>
          <div style={{ padding: '2px' }}>
            <FormattedMessage id={`monitorScheduleTaskName${LangCode}`} defaultMessage="Task Name" />: {props.TaskName}
          </div>
          <div style={{ padding: '2px' }}>
            <FormattedMessage id={`monitorScheduleTaskType${LangCode}`} defaultMessage="Task Type" />: {props.Subject}
          </div>
          <div style={{ padding: '2px' }}>
            <FormattedMessage id={`monitorSchedulePriority${LangCode}`} defaultMessage="Priority" />: {props.PriorityName}
          </div>
          <div style={{ padding: '2px' }}>
            <FormattedMessage id={`monitorScheduleCustomer${LangCode}`} defaultMessage="Customer" />: {props.CustomerName}
          </div>
          <div style={{ padding: '2px' }}>
            <FormattedMessage id={`monitorScheduleContact${LangCode}`} defaultMessage="Contact" />: {props.ContactName},{props.ContactPhone}
          </div>
          <div style={{ padding: '2px' }}>
            <FormattedMessage id={`monitorScheduleDateTime${LangCode}`} defaultMessage="Date Time" />: {start} - {end}
          </div>
          <div style={{ padding: '2px' }}>
            <FormattedMessage id={`monitorScheduleReference${LangCode}`} defaultMessage="Reference" />: {props.Reference}
          </div>
          <div style={{ padding: '2px' }}>
            <FormattedMessage id={`monitorScheduleAddress${LangCode}`} defaultMessage="Address" />: {props.ToAddress}
          </div>

          <div style={{ padding: '2px' }}>
            <FormattedMessage id={`monitorScheduleRemark${LangCode}`} defaultMessage="Remark" />: {props.Remark}
          </div>
          <br />
        </div>
      );
    };

    const onDragStop = async (args) => {
      let newTask = { ...args.data };
      const events = scheduleObjNew.current.getEvents();
      const filterTaskDuplicateWithResource = _.filter(
        events,
        (o) =>
          o.Id !== newTask.Id && o.TaskId === newTask.TaskId && o.ResourceId === newTask.ResourceId && o.memComOldId === newTask.ResourceId
      );
      const filterTaskTranferTeam = _.filter(events, (o) => o.TaskId === newTask.TaskId);
      const filterTaskTranferTeamByMemCom = _.filter(filterTaskTranferTeam, (o) => o.ResourceId === newTask.memComOldId);
      console.log('filterTaskTranferTeamByMemCom ::', filterTaskTranferTeam, filterTaskTranferTeamByMemCom);

      const groupTeam = scheduleObjNew.current.resourceCollection;
      const groupTask = scheduleObjNew.current.eventsData;
      const filterOrg =
        groupTeam &&
        groupTeam[1].dataSource.filter((item) => {
          return item.resourceOrgId === newTask.ResourceOrgId;
        });
      const filterOrgOld =
        groupTeam &&
        groupTeam[1].dataSource.filter((item) => {
          return item.resourceOrgId === newTask.ResourceOrgIdOld;
        });
      const filterTaskByMemOrg =
        groupTask &&
        groupTask.filter((item) => {
          return item.ResourceOrgId === newTask.ResourceOrgId;
        });
      const filterTaskMemOrgByTask =
        filterTaskByMemOrg &&
        filterTaskByMemOrg.filter((item) => {
          return item.TaskId === newTask.TaskId;
        });

      const startTimeNew = moment(_.get(args, 'data.StartTime')).unix();
      const endTimeNew = moment(_.get(args, 'data.EndTime')).unix();
      const startTimeOld = moment(_.get(filterTaskMemOrgByTask, '[0].StartTime')).unix();
      const endTimeOld = moment(_.get(filterTaskMemOrgByTask, '[0].EndTime')).unix();
      args.data.memComOldId = selectGroupBy === 'team' ? newTask.ResourceId : _.get(filterOrgOld, '[0].memComId');
      args.data.orgIdOld = selectGroupBy === 'team' ? newTask.OrgId : _.get(filterOrgOld, '[0].OrgId');
      args.data.ResourceOrgIdOld = newTask.ResourceOrgIdOld;

      if (_.size(filterTaskDuplicateWithResource)) {
        args.cancel = true;
      }
      if (selectGroupBy === 'team') {
        if (newTask.ResourceId === newTask.memComOldId && startTimeNew === startTimeOld && endTimeNew === endTimeOld) {
          args.cancel = true;
        }
        if (newTask.ResourceId === undefined) {
          args.cancel = true;
        }
      }
      if (selectGroupBy === 'customer') {
        if (newTask.ResourceOrgId === newTask.ResourceOrgIdOld && startTimeNew === startTimeOld && endTimeNew === endTimeOld) {
          args.cancel = true;
        }
        if (newTask.ResourceOrgId !== newTask.ResourceOrgIdOld && _.get(filterTaskMemOrgByTask, '[0].TaskId') === newTask.TaskId) {
          args.cancel = true;
        }
        if (newTask.ResourceOrgId === undefined) {
          args.cancel = true;
        }
      }

      if (!args.cancel) {
        if (
          (selectGroupBy === 'team' && newTask.ResourceId === newTask.memComOldId) ||
          (selectGroupBy === 'customer' && newTask.ResourceOrgId === newTask.ResourceOrgIdOld)
        ) {
          const newEventData = {
            OrgId: newTask.OrgId,
            ResourceId: newTask.ResourceId,
            memComOldId: newTask.memComOldId,
            ResourceOrgIdOld: newTask.ResourceOrgIdOld,
            ResourceOrgId: newTask.ResourceOrgId,
            orgIdOld: newTask.OrgId,
            StartDate: newTask.StartDate,
            DueDate: newTask.DueDate,
            StartTime: moment(newTask.StartTime).format(dateTimeApi),
            EndTime: moment(newTask.EndTime).format(dateTimeApi),
            Color: newTask.Color,
            CustomerName: newTask.CustomerName,
            Id: newTask.Id,
            TaskId: newTask.TaskId,
            TaskNo: newTask.TaskNo,
            Subject: newTask.Subject,
            Status: newTask.Status,
            ToAddress: newTask.ToAddress,
            AssigneeName: newTask.AssigneeName,
            Reference: newTask.Reference,
            Remark: newTask.Remark,
          };
          if (moment(_.get(args, 'data.EndTime')).unix() > moment(_.get(args, 'data.StartTime')).unix()) {
            const responseResize = await fncResizeStopApi(args);
            if (responseResize.status.code === 200) {
              scheduleObjNew.current.saveEvent(newEventData);
              setEventCurrentOnChange(newEventData);
            }
          }
        } else {
          let newEventData = {};
          if (newTask) {
            newEventData = {
              OrgId: selectGroupBy === 'team' ? newTask.OrgId : _.get(filterOrg, '[0].OrgId'),
              ResourceId: selectGroupBy === 'team' ? newTask.ResourceId : _.get(filterOrg, '[0].memComId'),
              memComOldId: newTask.memComOldId,
              ResourceOrgIdOld: newTask.ResourceOrgIdOld,
              ResourceOrgId: newTask.ResourceOrgId,
              orgIdOld: newTask.orgIdOld,
              StartDate: newTask.StartDate,
              DueDate: newTask.DueDate,
              StartTime: moment(newTask.StartTime).format(dateTimeApi),
              EndTime: moment(newTask.EndTime).format(dateTimeApi),
              Color: newTask.Color,
              CustomerName: newTask.CustomerName,
              Id: newTask.Id,
              TaskId: newTask.TaskId,
              TaskNo: newTask.TaskNo,
              Subject: newTask.Subject,
              Status: newTask.Status,
              ToAddress: newTask.ToAddress,
              AssigneeName: newTask.AssigneeName,
              Reference: newTask.Reference,
              Remark: newTask.Remark,
            };
          }

          const responseDrag = await fncDragStopApi(args, newEventData);
          if (responseDrag.data.status.code === 200) {
            if (selectGroupBy === 'team') {
              if (_.size(filterTaskTranferTeamByMemCom) > 1) {
                const filterOrg = _.filter(filterTaskTranferTeamByMemCom, (o) => o.OrgId !== newTask.OrgId);
                if (_.size(filterOrg) !== 0) {
                  scheduleObjNew.current.addEvent(newEventData);
                  const htmlEleNewMoreTask = document.getElementById(`count-task${newTask.OrgId}-${newTask.ResourceId}`);
                  htmlEleNewMoreTask.innerHTML = `(${parseInt(htmlEleNewMoreTask.innerHTML.replace('(', '').replace(')', '')) + 1})`;
                  scheduleObjNew.current.deleteEvent(filterOrg);
                  filterOrg.map((item) => {
                    const htmlEleOldMoreTask = document.getElementById(`count-task${item.OrgId}-${item.ResourceId}`);

                    htmlEleOldMoreTask.innerHTML = `(${parseInt(htmlEleOldMoreTask.innerHTML.replace('(', '').replace(')', '')) - 1})`;
                  });
                } else {
                  const htmlEleNewOrgMoreTask = document.getElementById(`count-task${newTask.OrgId}-${newTask.ResourceId}`);
                  const htmlEleOldOrgMoreTask = document.getElementById(
                    `count-task${_.get(filterTaskTranferTeamByMemCom, '[0].OrgId')}-${newTask.memComOldId}`
                  );
                  htmlEleNewOrgMoreTask.innerHTML = `(${parseInt(htmlEleNewOrgMoreTask.innerHTML.replace('(', '').replace(')', '')) + 1})`;
                  htmlEleOldOrgMoreTask.innerHTML = `(${parseInt(htmlEleOldOrgMoreTask.innerHTML.replace('(', '').replace(')', '')) - 1})`;
                }
              } else {
                const htmlEleNewOrgOneTask = document.getElementById(`count-task${newTask.OrgId}-${newTask.ResourceId}`);
                const htmlEleOldOrgOneTask = document.getElementById(`count-task${newTask.OrgId}-${newTask.memComOldId}`);
                htmlEleNewOrgOneTask.innerHTML = `(${parseInt(htmlEleNewOrgOneTask.innerHTML.replace('(', '').replace(')', '')) + 1})`;
                htmlEleOldOrgOneTask.innerHTML = `(${parseInt(htmlEleOldOrgOneTask.innerHTML.replace('(', '').replace(')', '')) - 1})`;
              }
            } else {
              setStatusDragDrop(true);
              // scheduleObjNew.current.deleteEvent(groupTask);
              // groupTask && groupTask.map(item => {
              //   return scheduleObjNew.current.deleteEvent(item.Id)
              // })
              // groupTeam[0].dataSource && groupTeam[0].dataSource.map(item => {
              //   return scheduleObjNew.current.removeResource(item.IdCustomer, 'GroupCustomers')
              // })

              // groupTeam[0].dataSource && groupTeam[1].dataSource.map(item => {
              //   return scheduleObjNew.current.removeResource(item.resourceOrgId, 'MemOrg')
              // })

              // scheduleObjNew.current.removeResource(groupTeam[1].dataSource, 'MemOrg');
            }
          } else {
            const concat = filterTaskTranferTeam.concat(newTask);
            scheduleObjNew.current.deleteEvent(concat);
            scheduleObjNew.current.addEvent(filterTaskTranferTeam);
          }
          setEventCurrentOnChange(newEventData);
          setTimeout(() => {
            setTriggerDragDrop((current) => !current);
          }, 500);
        }
      }
    };

    const onResizeStop = async (args) => {
      let newTask = args.data;
      const newEventData = {
        OrgId: newTask.OrgId,
        ResourceId: newTask.ResourceId,
        memComOldId: newTask.memComOldId,
        ResourceOrgIdOld: newTask.ResourceOrgIdOld,
        ResourceOrgId: newTask.ResourceOrgId,
        orgIdOld: newTask.OrgId,
        StartDate: newTask.StartDate,
        DueDate: newTask.DueDate,
        StartTime: moment(newTask.StartTime).format(dateTimeApi),
        EndTime: moment(newTask.EndTime).format(dateTimeApi),
        Color: newTask.Color,
        CustomerName: newTask.CustomerName,
        Id: newTask.Id,
        TaskId: newTask.TaskId,
        TaskNo: newTask.TaskNo,
        Subject: newTask.Subject,
        Status: newTask.Status,
        ToAddress: newTask.ToAddress,
        AssigneeName: newTask.AssigneeName,
        Reference: newTask.Reference,
        Remark: newTask.Remark,
      };
      if (moment(_.get(args, 'data.EndTime')).unix() > moment(_.get(args, 'data.StartTime')).unix()) {
        const responseResize = await fncResizeStopApi(args);
        if (responseResize.status.code === 200) {
          scheduleObjNew.current.saveEvent(newEventData);
          setEventCurrentOnChange(newEventData);
        }
      }
    };

    const setEventCurrentOnChange = (newEventData) => {
      const events = scheduleObjNew.current.getEvents();
      const filterTaskById = _.filter(events, (o) => o.TaskId === newEventData.TaskId && o.ResourceId !== newEventData.ResourceId);
      if (_.size(filterTaskById)) {
        for (let i = 0; i < _.size(filterTaskById); i++) {
          const event = filterTaskById[i];
          const setEvent = {
            OrgId: event.OrgId,
            ResourceId: event.ResourceId,
            memComOldId: event.memComOldId,
            ResourceOrgIdOld: event.ResourceOrgIdOld,
            ResourceOrgId: event.ResourceOrgId,
            orgIdOld: event.OrgId,
            StartDate: event.StartDate,
            DueDate: event.DueDate,
            StartTime: newEventData.StartTime,
            EndTime: newEventData.EndTime,
            Color: event.Color,
            CustomerName: event.CustomerName,
            Id: event.Id,
            TaskId: event.TaskId,
            TaskNo: event.TaskNo,
            Subject: event.Subject,
            Status: event.Status,
            ToAddress: event.ToAddress,
            AssigneeName: event.AssigneeName,
            Reference: event.Reference,
            Remark: event.Remark,
          };
          scheduleObjNew.current.saveEvent(setEvent);
        }
      }
    };

    const cellDoubleClick = (args) => {
      args.cancel = true;
      setMainVisibleCreateTask(true)
      const scheduleObj = scheduleObjNew.current;
      const arrayScheduleObj = _.get(scheduleObj, 'resourceBase.renderedResources')
      const indexResources = _.get(args, 'groupIndex')

      let resources
      if (_.size(arrayScheduleObj) && indexResources) {
        resources = arrayScheduleObj[indexResources]
      }

      console.log('cellDoubleClick', args, resources, scheduleObj)
      

      const info = {
        startDate: moment(_.get(args, 'startTime')),
        dueDate: moment(_.get(args, 'startTime')).add(1, 'hours'),
        orgId: _.toString(_.get(resources, 'resourceData.OrgId')),
        assigneeName: _.get(resources, 'resourceData.assigneeName'),
        memComId: _.get(resources, 'resourceData.memComId'),
        memId: _.get(resources, 'resourceData.memId'),
        memOrgId: _.get(resources, 'resourceData.memOrgId'),
      }

      setInfoSchedule(info)
    };

    const onActionBegin = (args) => {
      if (args.requestType === 'toolbarItemRendering') {
        let timeScale15m = {
          align: 'Right',
          text: intl.formatMessage({ id: `monitorGanttViewTime5m`, defaultMessage: '15m' }),
          cssClass: 'e-schedule-timeScale-15m',
        };
        let timeScale30m = {
          align: 'Right',
          text: intl.formatMessage({ id: `monitorGanttViewTime30m`, defaultMessage: '30m' }),
          cssClass: 'e-schedule-timeScale-30m',
        };
        let timeScale1h = {
          align: 'Right',
          text: intl.formatMessage({ id: `monitorGanttViewTime1h`, defaultMessage: '1h' }),
          cssClass: 'e-schedule-timeScale-1h',
        };
        args.items.push(timeScale15m);
        args.items.push(timeScale30m);
        args.items.push(timeScale1h);
      }
    };

    const onActionComplete = async (args) => {
      let scheduleElement = document.getElementById('schedule');
      if (args.requestType === 'toolBarItemRendered') {
        let time15m = scheduleElement.querySelector('.e-schedule-timeScale-15m');
        let time30m = scheduleElement.querySelector('.e-schedule-timeScale-30m');
        let time1h = scheduleElement.querySelector('.e-schedule-timeScale-1h');
        time15m.onclick = () => {
          scheduleObjNew.current.timeScale.slotCount = 4;
        };
        time30m.onclick = () => {
          scheduleObjNew.current.timeScale.slotCount = 2;
        };
        time1h.onclick = () => {
          scheduleObjNew.current.timeScale.slotCount = 1;
        };
      }
    };

    const onPopupOpen = (args) => {
      args.cancel = true;
    };

    return (
      <div>
        <ScheduleComponent
          id="schedule"
          cssClass="schedule-new-scheduleComponent"
          ref={scheduleObjNew}
          width="100%"
          height="unset"
          delayUpdate="true"
          currentView="TimelineDay"
          // selectedDate={dateMoment}
          locale="en-US"
          // locale='th'
          // dateFormat={dateTimeView}
          // selectedDate={`${mainDate}`}
          showQuickInfo={false}
          showHeaderBar={true}
          rowAutoHeight={true}
          allowDragAndDrop={true}
          navigating={onNavigating}
          renderCell={onRenderCell}
          eventRendered={onEventRendered}
          cellDoubleClick={cellDoubleClick}
          resizeStop={onResizeStop}
          dragStop={onDragStop}
          eventClick={handleEventClick}
          actionBegin={onActionBegin}
          actionComplete={onActionComplete}
          created={onCreatedNowClock}
          // showTimeIndicator={true}
          popupOpen={onPopupOpen}
          dataBinding={onDataBinding}
          onClick={handleClickResource}
          timeScale={{
            enable: true,
            interval: 60,
            slotCount: 2,
            // majorSlotTemplate: majorSlotTemplate
          }}
          eventSettings={{
            // dataSource: taskDataGanttView,
            // dataSource: dataTaskPool,
            filds: {
              id: 'Id',
              subject: { name: 'Subject' },
              startTime: { name: 'StartTime' },
              endTime: { name: 'EndTime' },
              assigneeName: { name: 'AssigneeName' },
              color: { name: 'Color' },
              customerName: { name: 'CustomerName' },
              dueDate: { name: 'DueDate' },
              orgId: { name: 'OrgId' },
              reference: { name: 'Reference' },
              resourceId: { name: 'ResourceId' },
              startDate: { name: 'StartDate' },
              status: { name: 'Status' },
              taskNo: { name: 'TaskNo' },
              toAddress: { name: 'ToAddress' },
              memComOldId: { name: 'memComOldId' },
              remark: { name: 'Remark' },
              orgIdOld: { name: 'orgIdOld' },
              ResourceOrgIdOld: { name: 'ResourceOrgIdOld' },
              ResourceOrgId: { name: 'ResourceOrgId' },
              // customerId: { name: 'CustomerId' },
              // resourceOrgId: { name: 'ResourceOrgId' },
            },
            enableTooltip: true,
            tooltipTemplate: tooltipData,
          }}
          group={selectGroupBy === 'team' ? { resources: ['Groupteam', 'Resources'] } : { resources: ['GroupCustomers', 'MemOrg'] }}
          // group={selectGroupBy === 'team' ? { resources: ['Groupteam', 'Resources'] } : { resources: ['Rooms', 'Owners'] }}

          // resourceHeaderTemplate={resourceHeaderTemplate}
        >
          {selectGroupBy === 'team' ? (
            <ResourcesDirective className="resourceDirective">
              <ResourceDirective
                className="resourceDirective-group-team"
                field="OrgId"
                title={intl.formatMessage({ id: `monitorTabGanttViewTitleResourceDirectiveGroupTeam`, defaultMessage: 'Groupteam' })}
                name="Groupteam"
                allowMultiple={false}
                textField="orgName"
                idField="orgId"
              />

              <ResourceDirective
                className="resourceDirective-group-team"
                // className="resourceDirective-img"
                field="ResourceId"
                title={intl.formatMessage({ id: `monitorTabGanttViewTitleResourceDirective`, defaultMessage: 'Resource' })}
                name="Resources"
                allowMultiple={true}
                textField="fullname"
                idField="memComId"
                groupIDField="OrgId"
                disbled='isDisable'
              />
            </ResourcesDirective>
          ) : (
            <ResourcesDirective className="resourceDirective">
              <ResourceDirective
                className="resourceDirective-group-team"
                field="CustomerId"
                title={intl.formatMessage({
                  id: `monitorTabGanttViewTitleResourceDirectiveGroupCustomer`,
                  defaultMessage: 'Groupcustomer',
                })}
                name="GroupCustomers"
                // dataSource={[
                //   { customerName: 'ROOM 1', IdCustomer: 1 },
                //   { customerName: 'ROOM 2', IdCustomer: 2 }
                // ]}
                allowMultiple={false}
                textField="customerName"
                idField="IdCustomer"
              />

              <ResourceDirective
                className="resourceDirective-img"
                field="ResourceOrgId"
                title={intl.formatMessage({ id: `monitorTabGanttViewTitleResourceOrg`, defaultMessage: 'ResourceOrgId' })}
                name="MemOrg"
                // dataSource={[
                //   { ResourceName: 'Nancy', resourceOrgId: 12, GroupCustomerId: 1 },
                //   { ResourceName: 'Steven', resourceOrgId: 23, GroupCustomerId: 2 },
                //   { ResourceName: 'Michael', resourceOrgId: 34, GroupCustomerId: 1 }
                // ]}
                allowMultiple={true}
                textField="ResourceName"
                idField="resourceOrgId"
                groupIDField="GroupCustomerId"
              />

              {/* <ResourceDirective
                className='resourceDirective-group-team'
                field='RoomId'
                title='Room'
                name='Rooms'
                allowMultiple={false}
                dataSource={[
                  { RoomText: 'ROOM 1', Id: 1, RoomColor: '#cb6bb2' },
                  { RoomText: 'ROOM 2', Id: 2, RoomColor: '#56ca85' }
                ]}
                textField='RoomText'
                idField='Id'
              />

              <ResourceDirective
                className='resourceDirective-img'
                field='OwnerId'
                title='Owner'
                name='Owners'
                allowMultiple={true}
                dataSource={[
                  { OwnerText: 'Nancy', Id: 1, OwnerGroupId: 1, OwnerColor: '#ffaa00' },
                  { OwnerText: 'Steven', Id: 2, OwnerGroupId: 2, OwnerColor: '#f8a398' },
                  { OwnerText: 'Michael', Id: 3, OwnerGroupId: 1, OwnerColor: '#7499e1' }
                ]}
                textField='OwnerText'
                idField='Id'
                groupIDField='OwnerGroupId'
              /> */}
            </ResourcesDirective>
          )}

          <ViewsDirective>
            <ViewDirective option="TimelineDay" />
            <ViewDirective option="TimelineWeek" />
            <ViewDirective option="TimelineMonth" />
          </ViewsDirective>
          <Inject services={[TimelineViews, TimelineMonth, Resize, DragAndDrop]} />
        </ScheduleComponent>
      </div>
    );
  },
  (prev, next) => {
    // return _.get(prev, 'orgGanttData') === _.get(next, 'orgGanttData')
  }
);
