import React, { useState, useEffect, useContext,useRef  } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import Button01 from '../../../components/v2/button_01';
import { Popover, Icon, Modal, notification, Button } from 'antd';
import client from "../../../components/axiosClient";
import Table from '../../../components/v2/table';
import { debounce } from 'lodash';
import { OrganizationContext } from '../organization-context';
import getCustomerApi from '../../../controllers/getCustomer/get-customer-api';
import ActionServices from './actionServices';
import EditArea from './editArea';
import { PageSettings } from '../../../config/page-settings';
import { ServicesContext } from './services-context';
import './css/index.css';
import _ from 'lodash';

const memComId = localStorage.getItem('memComId');

export default (props) => {

  const intl = useIntl();
  const { stateMain, setStateMain } = useContext(OrganizationContext);
  const comId = stateMain.comId;
  const orgId = stateMain.orgId;
  const { fnc } = props;
  const [visiblePopover, setVisiblePopover] = useState(false);
  const [visibleEditModal, setVisibleEditModal] = useState(false);
  const [selectAreaList, setSelectAreaList] = useState([]);
  const [recordPopover, setRecordPopover] = useState();
  const [offsetPopover, setOffsetPopover] = useState([0, 0]);
  const ref = useRef();

  const columns = [
    {
      title: '#',
      align: 'center',
      width: 70,
      dataIndex: 'index',
      key: 'index',
      render: (text, record, index) => record.index
    },
    {
      title: intl.formatMessage({ id: 'orgTabServiceColumnName', defaultMessage: 'Services area name' }),
      select: intl.formatMessage({ id: 'orgTabServiceColumnName', defaultMessage: 'Services area name' }),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: intl.formatMessage({ id: 'orgTabServiceColumnArea', defaultMessage: 'Area' }),
      select: intl.formatMessage({ id: 'orgTabServiceColumnArea', defaultMessage: 'Area' }),
      dataIndex: 'areaName',
      key: 'areaName',
    },
    {
      title: intl.formatMessage({ id: 'orgTabServiceColumnTaskType', defaultMessage: 'Task Type' }),
      select: intl.formatMessage({ id: 'orgTabServiceColumnTaskType', defaultMessage: 'Task Type' }),
      dataIndex: 'taskTypesId',
      key: 'taskTypesId',
      render: (text, record, index) => {
        return (
          record.taskTypesId = (
            <p className="task-types-p">{record.taskTypesId}</p>
          )
        )
      }
    },
    {
      title: intl.formatMessage({ id: 'orgTabServiceColumnSLA', defaultMessage: 'SLA (min.)' }),
      select: intl.formatMessage({ id: 'orgTabServiceColumnSLA', defaultMessage: 'SLA (min.)' }),
      align: 'center',
      dataIndex: 'buffer',
      key: 'buffer',
    },
    {
      title: '',
      key: 'options',
      dataIndex: 'options',
      align: 'center',
      width: '5%',
      render: (text, record, index) => fncActionTable(record, index)
    }
  ];

  const setShowColumn = {
    index: true,
    name: true,
    areaName: true,
    taskTypesId: true,
    buffer: false,
    options: true
  };

  const setShowColumnArr = [
    'index',
    'name',
    'areaName',
    'taskTypesId',
    'options'
  ];

  const dataColumns = [...columns];
  dataColumns.splice(4, 1);

  const [defaultShowColumn, setDefaultShowColumn] = useState({ ...setShowColumn });
  const [listArrayColumns, setListArrayColumns] = useState([...setShowColumnArr]);
  const [newDataColumns, setNewDataColumns] = useState([...dataColumns]);
  const [textErrorSelectColumn, setTextErrorSelectColumn] = useState('');

  const [servicesData, setServicesData] = useState([]);
  const [editServices, setEditServices] = useState(null);
  const [areaList, setAreaList] = useState([]);
  const [taskTypes, setTaskTypes] = useState([]);
  const [totalServices, setTotalServices] = useState();
  const [maxBuffer, setMaxBuffer] = useState();

  const [selectSearchData, setSelectSearchData] = useState("");
  const [fieldChang, setFieldChang] = useState("");

  const [refreshTable, setRefreshTable] = useState(false);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const [customerData, setCustomerData] = useState([]);

  useEffect(() => {
    loadServices();
  }, [comId, orgId, refreshTable, fieldChang]);

  useEffect(() => {
    const getCustomerAll = () => {
      setTimeout(async () => {
        let dataCustomer = await getCustomerApi(undefined, undefined, 1000);
        setCustomerData(dataCustomer.customers);
      }, 1000)
    }
    getCustomerAll();
  }, []);

  const loadServices = async () => {
    setLoading(true);
    const setSelect = [];
    if (selectSearchData === '') {
      setSelect.push('all');
    } else if (selectSearchData === "areaName") {
      setSelect.push('area');
    } else if (selectSearchData === "taskTypesId") {
      setSelect.push('taskType');
    } else {
      setSelect.push(selectSearchData);
    }

    let setValue = fieldChang === undefined ? '' : fieldChang;

    const key = setSelect;
    const val = setValue;

    try {
      const url = `${process.env.REACT_APP_URL_MANAGER}`;
      const port = `${process.env.REACT_APP_RESOURCE_MANAGER_PORT}`;
      client.defaults.baseURL = `${url}:${port}`;

      const axiosConfig = {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'memcomid': memComId,
        },
      };
      const result = await client.get(`/organization/services/${comId}/${orgId}?${key}=${val}`, axiosConfig);
      setServicesData(result.data.services);
      setAreaList(result.data.areaList);
      setTaskTypes(result.data.taskTypes);
      setTotalServices(result.data.totalServices);
      setMaxBuffer(result.data.maxBuffer);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  const handleSearch = debounce((fieldChang) => {
    setFieldChang(fieldChang ? fieldChang : "");
  }, 500);

  const handleSelectData = (value) => {
    setSelectSearchData(value === undefined ? '' : value);
  }

  const handleOnVisiblecolumns = () => {
    setVisible(!visible);
  };

  const handleVisibleChange = flag => {
    setVisible(flag);
    if (!flag) {
      const newListArrayColumns = [];
      newDataColumns.forEach((element) => {
        newListArrayColumns.push(element.key);
      });

      const reduceOBJ = _.reduce(newListArrayColumns, function (result, value, key) {
        result[value] = true;
        return result;
      }, {});
      setListArrayColumns(newListArrayColumns);
      setDefaultShowColumn(reduceOBJ)
      setTextErrorSelectColumn('');
    }
  };

  const checkedValuecolumns = (e) => {
    let checked = e.target.checked;
    let val = e.target.value;
    let showColumn = { ...defaultShowColumn };
    if (checked === true) {
      const found = listArrayColumns.find((element) => element === val);
      if (found === undefined) {
        setListArrayColumns([...listArrayColumns, val]);
      }
      showColumn[val] = true;
    } else {
      const newListArray = [...listArrayColumns];
      const found = listArrayColumns.findIndex((element) => element === val);
      if (found >= 0) {
        newListArray.splice(found, 1);
        setListArrayColumns(newListArray);
      }
      showColumn[val] = false;
    }
    setDefaultShowColumn(showColumn);
  };

  const handleCheckOk = () => {
    const setNewArr = [];
    if (listArrayColumns.length <= 2) {
      setTextErrorSelectColumn(<FormattedMessage id="lblSelectAtLeastColumn" defaultMessage="Select at least 1 column." />);
      return;
    } else {
      setTextErrorSelectColumn('');
    }
    for (let i = 0; i < columns.length; i++) {
      const found = listArrayColumns.find(
        (element) => element == columns[i].key,
      );
      if (found != undefined) {
        setNewArr.push(columns[i]);
      }
    }
    setNewDataColumns(setNewArr);
    handleOnVisiblecolumns();
  };

  const handleReset = () => {
    const newListArrayColumns = [];
    dataColumns.forEach((element) => {
      newListArrayColumns.push(element.key);
    });
    setNewDataColumns(dataColumns);
    setListArrayColumns(newListArrayColumns);
    handleOnVisiblecolumns();
    setDefaultShowColumn(setShowColumn);
    setTextErrorSelectColumn('');
  };

  const confirm = (serviecs) => {
    Modal.confirm({
      className: "services-modal-confirm",
      centered: true,
      icon: <Icon type="delete" style={{ color: '#FF0000' }} />,
      title: intl.formatMessage({ id: 'lblDelete', defaultMessage: 'Are you sure you want to delete ?' }),
      okText: intl.formatMessage({ id: 'modalConfirmBtnYes', defaultMessage: 'Yes' }),
      cancelText: intl.formatMessage({ id: 'modalConfirmBtnNo', defaultMessage: 'No' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: "primary",
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: "danger",
      },
      onOk() {
        deleteServices(serviecs.serviceCode);
      },
      onCancel() {
      },
    });
  }

  const deleteServices = async (serviceCode) => {
    try {
      const url = `${process.env.REACT_APP_URL_MANAGER}`;
      const port = `${process.env.REACT_APP_RESOURCE_MANAGER_PORT}`;
      client.defaults.baseURL = `${url}:${port}`;
      const result = await client.delete(`/organization/services/${serviceCode}`);
      if (result.status === 200) {
        deletedNotification(result.data);
        setRefreshTable(current => !current);
      }
    } catch (error) {
      return
    }
  }

  const deletedNotification = (message) => {
    notification.success({
      message: message,
    });
  };

  const refreshDataServices = () => {
    setRefreshTable(current => !current);
  }

  const showTotal = (total) => {
    return (
      <span style={{ fontSize: '13px' }}>
        <FormattedMessage id="lblRowPerPage" defaultMessage="Rows per page: " />
      </span>
    );
  };

  const handleOnVisiblePopover = (visiblePopover) => {
    setVisiblePopover(visiblePopover);
  }

  const fncActionTable = (record, offsetPopover, visiblePopover, ref) => {
    return (
      <div>
        <Popover
          placement="right"
          trigger="hover"
          align={{ offset: offsetPopover }}
          visible={visiblePopover}
          zIndex={999}
          content={
            <div style={{ display: 'grid' }}>
              <>
              
                <PageSettings.Consumer>
                  {({ checkPermissionAction }) =>
                    <div>
                      {checkPermissionAction('P3PG1C6', 'P3PG1C6A3') ? (
                        <Button
                          style={{ width: 100 }}
                          type="link"
                          ghost
                          onClick={() => {
                            setVisibleEditModal(true);
                            setEditServices(record);
                            setSelectAreaList(record.coordinates)
                          }}
                        >
                          <FormattedMessage id="orgTabServiceBtnEdit" defaultMessage="Edit" />
                        </Button>
                      ) : null}
                    </div>}
                </PageSettings.Consumer>
              </>

              <>
                <PageSettings.Consumer>
                  {({ checkPermissionAction }) =>
                    <div>
                      {checkPermissionAction('P3PG1C6', 'P3PG1C6A4') ? (
                        <Button
                          style={{ width: 100 }}
                          type="link"
                          ghost
                          onClick={() => {
                            confirm(record);
                          }}
                        >
                          <FormattedMessage id="orgTabServiceBtnDelete" defaultMessage="Delete" />
                        </Button>
                      ) : null}
                    </div>}
                </PageSettings.Consumer>
              </>
            </div>
          }
        >
          <div style={{ width: 0, height: 0 }} ref={ref}></div>
        </Popover>
      </div>
    );
  };

  // const fncActionTable = (record, index) => {
  //   return (
  //     <div>
  //       <Popover
  //         key={index}
  //         placement="leftTop"
  //         trigger="hover"
  //         visiblePopover={visiblePopover}
  //         onVisibleChange={handleOnVisiblePopover}
  //         zIndex={999}
  //         content={
  //           <div style={{ display: 'grid' }}>
  //             <>
  //               <PageSettings.Consumer>
  //                 {({ checkPermissionAction }) =>
  //                   <div>
  //                     {checkPermissionAction('P3PG1C6', 'P3PG1C6A3') ? (
  //                       <Button
  //                         style={{ width: 100 }}
  //                         type="link"
  //                         ghost
  //                         onClick={() => {
  //                           setVisibleEditModal(true);
  //                           setEditServices(record);
  //                           setSelectAreaList(record.coordinates)
  //                         }}
  //                       >
  //                         <FormattedMessage id="orgTabServiceBtnEdit" defaultMessage="Edit" />
  //                       </Button>
  //                     ) : null}
  //                   </div>}
  //               </PageSettings.Consumer>
  //             </>

  //             <>
  //               <PageSettings.Consumer>
  //                 {({ checkPermissionAction }) =>
  //                   <div>
  //                     {checkPermissionAction('P3PG1C6', 'P3PG1C6A4') ? (
  //                       <Button
  //                         style={{ width: 100 }}
  //                         type="link"
  //                         ghost
  //                         onClick={() => {
  //                           confirm(record);
  //                         }}
  //                       >
  //                         <FormattedMessage id="orgTabServiceBtnDelete" defaultMessage="Delete" />
  //                       </Button>
  //                     ) : null}
  //                   </div>}
  //               </PageSettings.Consumer>
  //             </>
  //           </div>
  //         }
  //       >
  //         <Icon type="more" />
  //       </Popover>
  //     </div>
  //   )
  // }

  const handleMouseLeave = () => {
    setVisiblePopover(false);
    setRecordPopover();
    setOffsetPopover([0, 0]);
  };

  return (
    <ServicesContext.Provider
      value={{
        stateServicesMain: {
          editServices,
          visibleEditModal,
          selectAreaList,
        },
        setStateServicesMain: {
          setEditServices,
          setVisibleEditModal,
          setSelectAreaList,
        }
      }}
    >
      <PageSettings.Consumer>
        {({ checkPermissionAction }) => (
          <div className="services-card">
            <ActionServices
              columns={columns}
              areaList={areaList}
              taskTypes={taskTypes}
              maxBuffer={maxBuffer}
              checked={defaultShowColumn}
              selectSearchData={selectSearchData}
              onChange={handleSearch}
              onSelect={handleSelectData}
              handleReset={handleReset}
              handleCheckOk={handleCheckOk}
              checkedValuecolumns={checkedValuecolumns}
              handleOnVisiblecolumns={handleOnVisiblecolumns}
              handleVisibleChange={handleVisibleChange}
              textErrorSelectColumn={textErrorSelectColumn}
              visible={visible}
              comId={stateMain.comId}
              orgId={stateMain.orgId}
              refreshDataServices={refreshDataServices}
              customerData={customerData}
            />
            <div onMouseLeave={handleMouseLeave}>
            <Table
              className="services-table"
              size="middle"
              rowKey={record => record.serviceCode}
              columns={newDataColumns}
              dataSource={servicesData}
              loading={loading}
              scroll={{ y: `calc(100vh - 342px)` }}
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    if (!event.target.href) {
                      const { x, y } = ref.current.getBoundingClientRect();
                      setVisiblePopover(true);
                      setOffsetPopover([event.pageX - x, event.pageY - y]);
                      setRecordPopover(record);
                    }
                  },
                };
              }}
              pagination={{
                total: totalServices,
                showTotal: showTotal,
                defaultCurrent: 1,
                pageSizeOptions: ['10', '20', '30', '40', '50',],
                showSizeChanger: true,
                locale: { items_per_page: '' },
              }}
            />
            {fncActionTable(recordPopover, offsetPopover, visiblePopover, ref)}
            {servicesData.length > 0 ? (
              <div className="total-items-services">
                <span style={{ fontSize: '13px' }}>
                  <FormattedMessage id="lblTotal" defaultMessage="Total" />
                  {` ${totalServices || 0} `}
                  <FormattedMessage id="lblitems" defaultMessage="items" />
                </span>
              </div>
            ) : null}
            </div>

            {checkPermissionAction('P3PG1C6', 'P3PG1C6A3') ?
              editServices && (
                <EditArea comId={stateMain.comId} orgId={stateMain.orgId} refreshDataServices={refreshDataServices} customerData={customerData} />
              )
              : null}
          </div>
        )}
      </PageSettings.Consumer>
    </ServicesContext.Provider>
  )
}
