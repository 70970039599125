import React, { useState, useEffect, useRef } from 'react'
import { Modal, Form, Divider, Table, Row, Col, Button, Popover } from 'antd'
import { useIntl, FormattedMessage } from 'react-intl'
import LabeRequireForm from '../../../../components/label-required-form'
import Button01 from '../../../../components/v2/button_01'
import _ from 'lodash'
import { numberFormatter } from '../../../../component-function/fnc-inputnumber-currency'
import { numberFormatDecimal } from '../../../../component-function/fnc-number-format-decimal'
import ReactBnbGallery from 'react-bnb-gallery';
import { getExpensePaymentDetail, getAttPaymentExpense } from '../../../../controllers/expense/expense'
import moment from 'moment'
import ModalExpenseCancelForm from './cancel-paid'

const ExpenseViewPaid = (props) => {
  const intl = useIntl()
  const { visible, setVisible, editDefault, reasonPaymentList, setTrigger, setTriggerMain } = props
  const styleText = {
    color: '#050505',
    fontSize: '13px'
  }
  const langValue = localStorage.getItem('langValue');
  const [paidData, setPaidData] = useState()
  const ref = useRef()
  const [visiblePopoverTable, setVisiblePopoverTable] = useState();
  const [recordPopover, setRecordPopover] = useState();
  const [offsetPopover, setOffsetPopover] = useState([0, 0]);
  const [visibleCancel, setVisibleCancel] = useState(false)
  const [cancelData, setCancelData] = useState()
  const [attachmentData, setAttachmentData] = useState([])
  const [stateModal, setStateModal] = useState(false)
  const [galleryImg, setGalleryImg] = useState([])
  const [numberImage, setNumberImage] = useState(0)
  const filterCancel = _.filter(_.get(paidData, 'paymentDetail'), (item) => {
    return _.get(item, 'status.code') !== "canceled"
  })

  useEffect(() => {
    const getById = async () => {
      const response = await getExpensePaymentDetail(_.get(editDefault, 'expenseId'))
      setPaidData(_.get(response, 'data.data[0]'))
    }
    if (visible && editDefault) {
      getById()
    }
  }, [visible, editDefault])

  useEffect(() => {
    const getFileDefault = async () => {
      const att = await getAttPaymentExpense(_.get(paidData, 'paymentId'))
      const mapAtt = _.map(_.get(att, 'data'), (item, index) => {
        return {
          id: item.fileHash,
          index: index + 1,
          attachmentName: item.fileName,
          remark: item.remark,
          file: [...item.fileUrl],
          fileRaw: item.fileUrl,
          type: 'uploaded'
        }
      })
      setAttachmentData(mapAtt)
    };
    if (paidData && visible) {
      getFileDefault();
    }
  }, [paidData, visible])


  const columnsDetail = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
      fixed: 'left',
      render: (text, record, index) => index + 1
    },
    {
      title: intl.formatMessage({ id: 'expenseViewModalColumnsMethod', defaultMessage: 'Method' }),
      dataIndex: 'method',
      key: 'method',
      width: 120,
      fixed: 'left',
      render: (text, record, index) => _.get(record, `medthod.lang[${langValue}]`)
    },
    {
      title: intl.formatMessage({ id: 'expenseViewModalColumnsAmount', defaultMessage: 'Amount' }),
      dataIndex: 'amountTxt',
      key: 'amountTxt',
      align: 'right',
      render: (text, record, index) => numberFormatter(_.get(record, `amount`) || 0)
    },
    {
      title: intl.formatMessage({ id: 'expenseViewModalColumnsPaidDate', defaultMessage: 'Paid Date' }),
      dataIndex: 'paidDateTxt',
      key: 'paidDateTxt',
      render: (text, record, index) => moment(_.get(record, `paidDate`), 'YYYY-MM-DD').format('DD/MM/YYYY')
    },
    {
      title: intl.formatMessage({ id: 'expenseViewModalColumnBank', defaultMessage: 'Bank' }),
      dataIndex: 'bank',
      key: 'bank',
      render: (text, record, index) => _.get(record, `detail.bank.lang[${langValue}]`) || '-'
    },
    {
      title: intl.formatMessage({ id: 'expenseViewModalColumnDescription', defaultMessage: 'Description' }),
      dataIndex: 'remark',
      key: 'remark',
      width: 300,
      render: (text, record, index) => description(record)
    },
    {
      title: intl.formatMessage({ id: 'expenseViewModalColumnsStatus', defaultMessage: 'Status' }),
      dataIndex: 'status',
      key: 'status',
      render: (text, record, index) => _.get(record, `status.lang[${langValue}]`)
    },
    {
      title: intl.formatMessage({ id: 'expenseViewModalColumnsReason', defaultMessage: 'Reason' }),
      dataIndex: 'reason',
      key: 'reason',
      render: (text, record, index) => _.get(record, `reason.txt`) ? _.get(record, `reason.txt`) : '-'
    },
  ]

  const handlePreview = (record, data) => {
    const isPDF = _.includes(_.get(record, 'attachmentName'), 'pdf')
    if (isPDF) return window.open(_.get(record, 'fileRaw'), '_blank');
    const mapPhoto = data.map((item, index) => {
      return {
        photo: _.get(item, 'fileRaw'),
        number: index,
        caption: _.get(item, 'attachmentName'),
        subcaption: _.get(item, 'remark'),
        thumbnail: _.get(item, 'fileRaw'),
      }
    })
    const filerDefault = _.filter(mapPhoto, (item) => { return item.photo === record.fileRaw })
    setGalleryImg(mapPhoto)
    setNumberImage(_.get(filerDefault, '[0].number'))
    setStateModal(true)
  }

  const description = (data) => {
    const remark = _.get(data, 'remark') ? `,${_.get(data, 'remark')}` : ''
    let text = null
    if (_.get(data, 'medthod.code') === "banktransfer") {
      text = `account no.${_.get(data, 'detail.bank.accountNo')}` + remark
    } else if (_.get(data, 'medthod.code') === "creditcard") {
      text = `${_.get(data, 'detail.creditCardType.code') + " " + _.get(data, 'detail.creditCardNo') + " " + _.get(data, 'detail.cardHolderName')}` + remark
    } else if (_.get(data, 'medthod.code') === "cheque") {
      text = `${_.get(data, 'detail.chequeNo') + " " + _.get(data, 'detail.payTo') + ` ${intl.formatMessage({ id: 'expenseViewModalCheqDate', defaultMessage: 'Cheque Date' })} ` + moment(_.get(data, 'detail.chequeDate')).format('DD/MM/YYYY')}` + remark
    } else {
      text = _.get(data, 'remark') ? `${_.get(data, 'remark')}` : null
    }
    return text
  }
  const columnsAtt = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
      fixed: 'left'
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsFileName', defaultMessage: 'File Name' }),
      dataIndex: 'attachmentName',
      key: 'attachmentName',
      sorter: true,
      render: (text, record, index) => <span style={{ cursor: 'pointer' }} onClick={() => handlePreview(record, attachmentData)}>{record.attachmentName}</span>
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsRemark', defaultMessage: 'Remark' }),
      dataIndex: 'remark',
      key: 'remark',
    },
  ]


  const handleCancel = () => {
    setAttachmentData([])
    setVisible(false)
  }

  const handleMouseLeave = () => {
    setVisiblePopoverTable(false);
    setRecordPopover();
    setOffsetPopover([0, 0]);
  };

  const fncActionTable = (record, offsetPopover, visiblePopoverTable, ref) => {
    return (
      <div>
        <Popover
          placement="right"
          trigger="hover"
          align={{ offset: offsetPopover }}
          visible={visiblePopoverTable}
          zIndex={10000}
          content={
            <div style={{ display: 'grid' }}>
              {
                _.get(record, 'status.code') === 'success' ? (
                  <div>
                    <Button style={{ width: 100 }} type="link" ghost onClick={() => handleCancelPayment(record)}>
                      <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
                    </Button>
                  </div>
                ) : null
              }
            </div>
          }
        >
          <div style={{ width: 0, height: 0 }} ref={ref}></div>
        </Popover>
      </div>
    );
  };

  const handleCancelPayment = async (data) => {
    setCancelData(data)
    setVisibleCancel(true)
  }

  return (
    <Modal
      title={`${intl.formatMessage({ id: `expenseViewModalTitlePayment`, defaultMessage: 'Payment' })} ・ ${_.get(editDefault, 'expenseNo')} `}
      visible={visible}
      onCancel={handleCancel}
      width={900}
      centered={true}
      bodyStyle={{ padding: 'unset', height: '540px', overflowY: 'auto' }}
      footer={[
        <Button01 key="submit" type="primary" btnsize="wd_df" onClick={() => handleCancel()}>
          <FormattedMessage id="btnClose" defaultMessage="Close" />
        </Button01>,
      ]}
    >
      <Form layout="vertical">
        <div style={{ padding: '12px 24px' }}>
          <Row gutter={[24, 2]}>
            <Col span={8}>
              <Form.Item
                label={<LabeRequireForm text={intl.formatMessage({ id: 'expenseFormPONo', defaultMessage: 'PO No.' })} req={false} />}
              >
                <span style={styleText}>{_.get(editDefault, 'poNo') || '-'}</span>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={<LabeRequireForm text={intl.formatMessage({ id: 'expenseFormInvoiceNo', defaultMessage: 'Invoice No.' })} req={false} />}
              >
                <span style={styleText}>{_.get(editDefault, 'invoiceNo') || '-'}</span>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={<LabeRequireForm text={intl.formatMessage({ id: 'expenseFormInvoiceDate', defaultMessage: 'Invoice Date' })} req={false} />}
              >
                <span style={styleText}>{_.get(editDefault, 'invoiceDate') ? moment(_.get(editDefault, 'invoiceDate'), 'YYYY-MM-DD').format('DD/MM/YYYY') : '-'}</span>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[24, 2]}>
            <Col span={8}>
              <Form.Item
                label={<LabeRequireForm text={intl.formatMessage({ id: 'expenseFormTotal', defaultMessage: 'Total' })} req={false} />}
              >
                <span style={styleText}>{numberFormatDecimal(_.get(editDefault, 'expenseTotal') || 0)}</span>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={<LabeRequireForm text={intl.formatMessage({ id: 'expenseFormDueDate', defaultMessage: 'Due Date' })} req={false} />}
              >
                <span style={styleText}>{_.get(editDefault, 'dueDate') ? moment(_.get(editDefault, 'dueDate'), 'YYYY-MM-DD').format('DD/MM/YYYY') : '-'}</span>
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div style={{ padding: '0px 24px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ color: '#1D3557', fontSize: '14px', fontWeight: 'bold' }}>
              <FormattedMessage id="expenseViewTitleMethod" defaultMessage="Payment Method" />
            </div>
          </div>
        </div>
        <Divider type="horizontal" />
        <div style={{ padding: '0px 24px 24px 24px' }} onMouseLeave={handleMouseLeave}>
          <Table
            columns={columnsDetail}
            dataSource={_.get(paidData, 'paymentDetail') || []}
            pagination={false}
            scroll={{ x: 1200 }}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  if (!event.target.href && _.get(record, 'status.code') === "success") {
                    const { x, y } = ref.current.getBoundingClientRect();
                    setVisiblePopoverTable(true);
                    setOffsetPopover([event.pageX - x, event.pageY - y]);
                    setRecordPopover(record);
                  }
                },
              };
            }}
          />
          {fncActionTable(recordPopover, offsetPopover, visiblePopoverTable, ref)}
        </div>
        <div style={{ padding: '0px 24px', marginBottom: '24px' }}>
          <Row gutter={[24, 2]}>
            <Col span={8}>
              <Form.Item
                label={<LabeRequireForm text={intl.formatMessage({ id: 'expenseFormReceiveTotal', defaultMessage: 'Receive Total' })} req={false} />}
              >
                <span style={styleText}>{numberFormatter(_.sumBy(filterCancel || [], 'amount')) || '-'}</span>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={<LabeRequireForm text={intl.formatMessage({ id: 'expenseFormReceiveBy', defaultMessage: 'Receive By' })} req={false} />}
              >
                <span style={styleText}>{_.get(paidData, 'receive.name') || '-'}</span>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={<LabeRequireForm text={intl.formatMessage({ id: 'expenseFormReceiveDate', defaultMessage: 'Receive Date' })} req={false} />}
              >
                <span style={styleText}>{_.get(paidData, 'receiveDate') ? moment(_.get(paidData, 'receiveDate'), 'YYYY-MM-DD').format('DD/MM/YYYY') : '-'}</span>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[24, 2]}>
            <Col span={24}>
              <Form.Item
                label={<LabeRequireForm text={intl.formatMessage({ id: 'expenseFormRemark', defaultMessage: 'Remark' })} req={false} />}
              >
                <span style={styleText}>{_.get(paidData, 'remark') || '-'}</span>
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div style={{ padding: '0px 24px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ color: '#1D3557', fontSize: '14px', fontWeight: 'bold' }}>
              <FormattedMessage id="expenseAttachmentText" defaultMessage="Attachment" />
            </div>
          </div>
        </div>
        <Divider type="horizontal" />
        <div style={{ padding: '0px 24px 24px 24px' }}>
          <Table
            columns={columnsAtt}
            dataSource={attachmentData}
            pagination={false}
          />
        </div>
      </Form >

      <ModalExpenseCancelForm
        visible={visibleCancel}
        setVisible={setVisibleCancel}
        setVisibleMain={setVisible}
        data={cancelData}
        expenseId={_.get(editDefault, 'expenseId')}
        reasonPaymentList={reasonPaymentList}
        setTrigger={setTrigger}
        setTriggerMain={setTriggerMain}
      />

      {
        attachmentData.length > 0 ? <ReactBnbGallery
          show={stateModal}
          photos={galleryImg}
          onClose={() => setStateModal(false)}
          activePhotoIndex={numberImage}
        /> : null
      }

    </Modal >
  )
}

export default ExpenseViewPaid
