import React, { useRef, useCallback, useEffect } from 'react';
import { GoogleMap, useLoadScript, Polyline, Marker } from '@react-google-maps/api';
import _ from 'lodash';
import getPolylineAndDistance from '../../controllers/map-view/get-polyline';
import { FormattedMessage } from 'react-intl';
import FromMarker from '../image/MarkerArea.svg'
import ToMarker from '../image/LocationEngagement.svg'
import { useAppContext } from '../../includes/indexProvider';

export default (props) => {

  const { selectAddress, selectWareHouse, setPolyline, polyline, setDistance, distance, marker, setMarker, setLoading, setDataDistance, setDistanceMeter } = props
  const latlngFrom = _.get(selectWareHouse, 'latCenter') ? { lat: parseFloat(_.get(selectWareHouse, 'latCenter')), lng: parseFloat(_.get(selectWareHouse, 'lngCenter')) } : undefined
  const latlngTo = _.get(selectAddress, 'lat') ? { lat: parseFloat(_.get(selectAddress, 'lat')), lng: parseFloat(_.get(selectAddress, 'lng')) } : undefined
  const app = useAppContext();
  const latlng = _.get(app, 'state.latlngCompany')
  useEffect(() => {
    let marker = [];
    if (latlngFrom || latlngTo) {
      marker.push(
        {
          key: 1,
          lat: latlngFrom ? parseFloat(latlngFrom.lat) : undefined,
          lng: latlngFrom ? parseFloat(latlngFrom.lng) : undefined,
          markerSvg: 'From',
        },
        {
          key: 2,
          lat: latlngTo ? parseFloat(latlngTo.lat) : undefined,
          lng: latlngTo ? parseFloat(latlngTo.lng) : undefined,
          markerSvg: 'To',
        },
      );
      setMarker(marker)
      getPolyLineFromTo(marker)
    }


  }, [_.get(selectAddress, 'customerAddressBookId'), _.get(selectWareHouse, 'warehouseId')]);

  const getPolyLineFromTo = async (marker) => {
    setLoading(true)
    if (_.get(selectAddress, 'customerAddressBookId') && _.get(selectWareHouse, 'warehouseId')) {
      const response = await getPolylineAndDistance(marker)
      if (_.size(response) !== 0) {
        setDistance(_.get(response, 'data.distance'))
        setDistanceMeter(Number(_.get(response, 'data.meterAmout')))
        setPolyline(_.get(response, 'data.polyline'))
        setDataDistance(_.get(response, 'data'))
        setLoading(false)
      } else {
        setLoading(false)

      }
    }


  }


  const libraries = ["places"];

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: localStorage.getItem('GEO_APIKEY'),
    libraries,
  });
  const mapRef = useRef();
  const onMapLoad = useCallback((map) => {
    mapRef.current = map
  }, []);

  if (loadError) return "Error loading maps";
  if (!isLoaded) return "Loading Maps";

  const defaultMapOption = {
    fullscreenControl: false,
    fullscreenControlOptions: false,
    streetViewControl: false,
    clickableIcons: false,
    zoomControl: true,
    gestureHandling: "cooperative",
    mapTypeControl: true,
    mapTypeControlOptions: {
      style: 1,
      position: 3,
    },
  }

  const options = {
    strokeColor: '#1890ff',
    strokeOpacity: 0.8,
    strokeWeight: 6,
    fillColor: '#1890ff',
    fillOpacity: 1,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: 30000,
    zIndex: 1
  };

  const mapContainerStyle = {
    width: '100%',
    height: '100%',
    position: 'relative'
  }

  return (
    <div style={{ height: '459px' }}>
      <GoogleMap
        id={`${props.idMap}`}
        mapContainerStyle={mapContainerStyle}
        center={latlngTo ? latlngTo : { lat: latlng.lat, lng: latlng.lng }}
        zoom={12}
        onLoad={onMapLoad}
        options={defaultMapOption}
      >
        {distance !== undefined && distance !== '0 meter' ?
          (<div className='distance-value'>
            <FormattedMessage id="monitorCreateTaskTxtDistance" defaultMessage="Distance" />  {distance}
          </div>) : null
        }

        <Polyline
          path={_.size(polyline) !== 0 ? polyline : []}
          options={options}
        />

        {marker && marker.map((item) => (
          <Marker
            key={item.key}
            position={item}
            icon={{
              url: item.markerSvg === 'From' ? FromMarker : ToMarker,
              scaledSize: new window.google.maps.Size(40, 40),
            }}
          />
        ))}
      </GoogleMap>
    </div>
  )
}