import React from 'react';
import { withRouter, Switch } from 'react-router-dom';
import routes from '../../../config/page-route';
import { PageSettings } from '../../../config/page-settings';
import PrivateRoute from '../../../config/private-route';

const Content = (props) => {

  return (
    <PageSettings.Consumer>
      {() => (
        <div>
          <Switch>
            {routes.map((route, index) => (
              <PrivateRoute 
                key={index}
                path={route.path}
                exact={route.exact}
                component={route.component}
                pages={route.pages ? route.pages : ''}
                actions={route.actions ? route.actions : ''}
              />
            ))}
          </Switch>
        </div>
      )}
    </PageSettings.Consumer>
  );
}

export default withRouter(Content);