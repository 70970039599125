import React, { useEffect, useState, useRef } from 'react';
import './css/index.css';
import { Row, Col, Form, DatePicker, Select, Input, AutoComplete, Icon, Modal, Badge } from 'antd';
import Map from './map-location';
import Button01 from '../../components/v2/button_01';
import _, { debounce } from 'lodash';
import { getSearchLocation } from '../../controllers/support-service/api';
import { getProductType, addTicket } from '../../controllers/support-service/api';
import moment from 'moment';
import { successNotification, errorNotification } from '../../components/v2/notification';
import { useAppContext } from '../../includes/indexProvider';

const { Option } = Select;
const { TextArea } = Input;

const FormSupportService = (props) => {
  const { form } = props;
  const app = useAppContext();
  const latlng = _.get(app, 'state.latlngCompany')
  const { getFieldDecorator, getFieldValue, resetFields, validateFields } = form;
  const ref = useRef();
  const [center, setCenter] = useState(latlng);
  const [position, setPosition] = useState(latlng);
  const [latLng, setlatLng] = useState();
  const [address, setAddress] = useState();
  const [addressArea, setAddressArea] = useState([]);
  const [check, setCheck] = useState(false);
  const [loading, setLoading] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const [statusScroll, setStatusScrool] = useState(false);
  const [productData, setProductData] = useState([]);
  const [disbleOther, setDisbleOther] = useState(true);
  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    const getDefaultData = async () => {
      const response = await getProductType();
      if (response.status === 200) {
        setProductData(_.get(response, 'data.data.list'));
      }
    };

    getDefaultData();
  }, []);

  const LabeRequire = (props) => {
    const { text, req } = props;
    return (
      <span>
        {text}&nbsp;
        {req ? <span style={{ fontSize: '12px', color: '#ff0000' }}>*</span> : ''}
      </span>
    );
  };

  // useEffect(() => {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(positionMyself);
  //   }
  // }, [trigger]);


  useEffect(() => {
    if (latlng) {
      setPosition(latlng);
      setCenter(latlng);
    }
  }, [latlng])

  // const positionMyself = async (position) => {
  //   if (position && position !== null && position !== undefined) {
  //     setPosition({
  //       lat: _.get(position, 'coords.latitude'),
  //       lng: _.get(position, 'coords.longitude'),
  //     });
  //     setCenter({
  //       lat: _.get(position, 'coords.latitude'),
  //       lng: _.get(position, 'coords.longitude'),
  //     });
  //     setlatLng({ lat: _.get(position, 'coords.latitude'), lng: _.get(position, 'coords.longitude') });
  //     let area = await getSearchLocation({ Default: position });
  //     setAddress(area.address);
  //   } else {
  //     setPosition(latlng);
  //     setCenter(latlng);
  //   }
  // };

  const handleSearchLocation = debounce(async (fieldChang) => {
    let newData = await getSearchLocation({ name: fieldChang });
    setAddressArea(newData);
  }, 200);

  const handleSelect = (key, option) => {
    if (isNaN(key) == false) {
      form.setFieldsValue({ ['address']: option.props.children });
      setPosition({ lat: addressArea[key].lat, lng: addressArea[key].lng });
      setCenter({ lat: addressArea[key].lat, lng: addressArea[key].lng });
      setlatLng({ lat: addressArea[key].lat, lng: addressArea[key].lng });
    } else {
      setPosition(latlng);
      setCenter(latlng);
      form.resetFields();
    }
  };

  useEffect(() => {
    if (address) {
      form.setFieldsValue({ ['address']: address });
    }
  }, [address]);

  const children = addressArea && addressArea.map((item) => <Option key={item.key}>{item.address}</Option>);

  const handleCheckbox = () => {
    setLoading(true);
    setTimeout(() => {
      setCheck((current) => !current);
      setLoading(false);
    }, 500);
  };

  const onScroll = () => {
    if (ref.current) {
      const { scrollTop, scrollHeight, clientHeight } = ref.current;
      if (scrollTop + clientHeight + 10 >= scrollHeight) {
        setStatusScrool(true);
      } else {
        setStatusScrool(false);
      }
    }
  };

  const handleAceeptModal = () => {
    setVisibleModal(false);
    setLoading(true);
    setTimeout(() => {
      setCheck(true);
      setLoading(false);
    }, 500);
  };

  const handleCancelModal = () => {
    setVisibleModal(false);
    const scrollEle = document.getElementById('scroll-div');
    scrollEle.scrollTop = 0;
  };

  const dataDetail = {
    personalData: [
      {
        detail:
          'ข้อมูลที่บ่งชี้ตัวตน เช่น ชื่อ นามสกุล วันเกิด เพศ สัญชาติ ภาพถ่าย ภาพ และหมายเลขบัตรประจำตัวประชาชน ข้อมูลชีวภาพ (Biometric) ข้อมูลอุปกรณ์อิเล็กทรอนิกส์ ข้อมูลตำแหน่งที่อยู่',
      },
      {
        detail: 'ข้อมูลติดต่อ เช่น ที่อยู่ สถานที่ติดต่อ สถานที่ทำงาน หมายเลขโทรศัพท์ อีเมล ไอดีไลน์',
      },
      {
        detail: 'ข้อมูลที่เกี่ยวข้องกับการทำงาน เช่น ชื่อบริษัท รหัสพนักงาน รูปพนักงาน ระดับพนักงาน ตำแหน่ง แผนก เงินเดือน เวลาการทำงาน',
      },
      {
        detail:
          'ข้อมูลเกี่ยวกับอุปกรณ์ของท่านในการใช้บริการของบริษัทฯ รวมถึงที่อยู่ IP ของอุปกรณ์ ข้อมูลเกี่ยวกับรุ่นและการตั้งค่าอุปกรณ์ ข้อมูลเครือข่ายที่ใช้',
      },
      {
        detail: 'ข้อมูลเกี่ยวกับพฤติกรรมการใช้บริการของท่าน รวมถึงประวัติการใช้บริการและผลิตภัณฑ์ของบริษัทฯ',
      },
      {
        detail: 'ข้อมูลทางการเงิน เช่น ข้อมูลการชำระเงิน หมายเลขบัตรเครดิต และหมายเลขบัญชีธนาคาร',
      },
      {
        detail:
          'ข้อมูลในการวิเคราะห์การใช้บริการ เพื่อการตรวจสอบย้อนหลังในกรณีที่เกิดปัญหาการใช้งานรวมถึงข้อมูลที่บันทึกในระบบรักษาความปลอดภัย เช่น ข้อมูลโทรทัศน์วงจรปิด (กล้องวงจรปิด) ข้อมูลการบันทึกภาพผ่านสื่ออิเล็กทรอนิกส์ และ/หรือสื่ออื่น เป็นต้น',
      },
      {
        detail: 'ข้อมูลเพื่อให้บริการเฉพาะกลุ่มลูกค้า เช่น บุคคลที่มีความบกพร่องทางการมองเห็นและการได้ยิน',
      },
      {
        detail: 'ข้อมูลที่จำเป็นสำหรับบริษัทฯ ในการปฏิบัติตามกฎหมาย คำขอตามกฎหมายของหน่วยงานบังคับใช้กฎหมายและ/หรือคำสั่งศาล',
      },
    ],
    sourceData: [
      {
        detail: 'ขั้นตอนการสมัครใช้บริการ หรือขั้นตอนการยื่นคำร้องขอใช้สิทธิต่าง ๆ กับบริษัทฯ',
      },
      {
        detail: 'จากความสมัครใจของท่าน ในการทำแบบสอบถาม (Survey) หรือการโต้ตอบทางอีเมล หรือช่องทางการสื่อสารอื่น ๆ ระหว่างบริษัทฯ และท่าน',
      },
      {
        detail: 'เก็บจากข้อมูลการใช้เว็บไซต์ของบริษัทฯ ผ่าน Browser’s cookies ของท่าน',
      },
    ],
    objectiveData: [
      {
        detail: 'เพื่อการให้บริการตามสัญญาซึ่งท่านเป็นคู่สัญญา หรือเพื่อใช้ในการดำเนินการตามคำขอของท่านก่อนเข้าทำสัญญานั้น',
      },
      {
        detail: 'เพื่อปรับปรุงและพัฒนาการให้บริการของบริษัทฯ ให้มีประสิทธิภาพ และอำนวยความสะดวกในการให้บริการ',
      },
      {
        detail:
          'เพื่อวัตถุประสงค์ในการวิเคราะห์พฤติกรรมการใช้งานสินค้าและบริการของท่าน เพื่อการจัดทำฐานข้อมูลและนำเสนอบริการ นำเสนอหรือให้สิทธิประโยชน์ในผลิตภัณฑ์หรือบริการของบริษัทฯ แก่ท่าน',
      },
      {
        detail:
          'เพื่อแจ้งและนำเสนอสิทธิประโยชน์ ข้อมูล ข่าวสาร รายการส่งเสริมการขาย และข้อเสนอต่าง ๆ เกี่ยวกับการสมัคร การซื้อขายสินค้า หรือการใช้บริการต่าง ๆ ของบริษัทฯ แก่ท่าน',
      },
      {
        detail:
          'เพื่อการสมัคร หรือเปิดใช้บริการที่เกี่ยวข้องกับการสมัคร การซื้อขายสินค้า หรือการใช้บริการต่าง ๆ ของบริษัทฯ รวมถึงนิติบุคคลหรือบุคคลอื่นใด',
      },
      {
        detail: 'เพื่อวัตถุประสงค์ในการวิเคราะห์และวางแผนการตลาด กิจกรรมทางการตลาดของบริษัทฯ',
      },
      {
        detail: 'เพื่อความปลอดภัยหรือระงับอันตรายต่อชีวิต ร่างกาย หรือสุขภาพอนามัยของบุคคล',
      },
      {
        detail: 'เพื่อวัตถุประสงค์ของการปฏิบัติตามกฎหมาย และ/หรือมีเหตุจำเป็นอื่นใดเพื่อประโยชน์อันชอบด้วยกฎหมายของบริษัทฯ',
      },
      {
        detail:
          'เพื่อวัตถุประสงค์อื่นตามที่กฎหมายให้อำนาจในการเก็บรวบรวม ใช้ หรือเปิดเผย โดยไม่ต้องได้รับความยินยอมจากเจ้าของข้อมูลส่วนบุคคล',
      },
      {
        detail: 'เพื่อการพิสูจน์และยืนยันตัวตนของผู้สมัคร เพื่อสมัครลงทะเบียนและเข้าใช้บริการ',
      },
      {
        detail: 'เพื่อให้บริการหลังการขายให้บริการตามที่ท่านร้องขอ ตอบข้อสงสัย สนับสนุนการใช้งาน ให้ข้อมูล รับเรื่อง',
      },
      {
        detail: 'เพื่อทำความเข้าใจความต้องการและความพึงพอใจของท่าน',
      },
      {
        detail: 'เพื่อการแจ้งข้อมูล ข่าวสารจากหน่วยงานกำกับดูแลและภาครัฐ',
      },
      {
        detail:
          'เพื่อส่งหนังสือแจ้งค่าบริการ ใบสั่งซื้อสินค้าและบริการ การแจ้งเตือนให้ชำระค่าสินค้าและบริการ จัดทำใบเสร็จรับเงินและใบกำกับภาษี ติดตามทวงถามหนี้',
      },
      {
        detail:
          'เพื่อการรักษาความปลอดภัยของข้อมูลและเครือข่ายการให้บริการของบริษัทฯ รวมถึงตรวจสอบระบบและกิจกรรมของผู้ใช้บริการ เพื่อติดตามกิจกรรมที่เป็นอันตรายใด ๆ ต่อท่าน ผู้อื่น และบริษัทฯ',
      },
      {
        detail: 'เพื่อการรักษาความปลอดภัยของสถานประกอบการและสาขาของบริษัทฯ เช่น การติดตั้งกล้องวงจรปิด เพื่อความปลอดภัยและป้องกันอาชญากรรม',
      },
    ],
    storageData: [
      {
        detail:
          'ลักษณะการเก็บ: จัดเก็บเป็นหนังสือ (Hard copy) และจัดเก็บในระบบอิเล็กทรอนิกส์ (Soft copy) โดยมีมาตรการรักษาความมั่นคงปลอดภัยที่มีประสิทธิภาพ เพื่อป้องกันการสูญหาย เข้าถึง ใช้ เปลี่ยนแปลง แก้ไข หรือเปิดเผยข้อมูลส่วนบุคคลโดยปราศจากอำนาจหรือโดยมิชอบ',
      },
      {
        detail:
          'บริษัทมีขั้นตอนในการจัดการกับการละเมิดข้อมูลส่วนบุคคลที่น่าสงสัย ในกรณีที่มีการร้องเรียนเกี่ยวกับการละเมิดความเป็นส่วนตัวของลูกค้าและข้อมูลรั่วไหล หน่วยงานรักษาความมั่นคงปลอดภัยของบริษัทจะดำเนินการตรวจสอบทันทีเพื่อคลี่คลายข้อร้องเรียนนี้',
      },
      {
        detail:
          'ระยะเวลาจัดเก็บ: บริษัทฯ จะเก็บรวบรวมข้อมูลส่วนบุคคลของท่านภายในระยะเวลาเท่าที่จำเป็นตามวัตถุประสงค์ในการเก็บรวบรวมข้อมูลส่วนบุคคลนั้น หรือภายในระยะเวลาที่มีสัญญาหรือนิติสัมพันธ์ตามกฎหมายระหว่างท่านและบริษัทฯ หรือภายในระยะเวลาการใช้สิทธิเรียกร้องตามกฎหมายของคู่สัญญา',
      },
      {
        detail:
          'เมื่อพ้นระยะเวลาจัดเก็บ หรือบริษัทฯ ไม่มีสิทธิหรือไม่สามารถอ้างฐานในการประมวลผลข้อมูลส่วนบุคคลของท่านแล้ว บริษัทฯ จะดำเนินการลบหรือทำลายข้อมูลส่วนบุคคล หรือทำให้ข้อมูลส่วนบุคคลเป็นข้อมูลไม่สามารถระบุตัวบุคคลที่เป็นเจ้าของข้อมูลส่วนบุคคลได้ ภายในระยะเวลาอันควร',
      },
    ],
    personalDataSubject: [
      {
        Header: 'สิทธิในการถอนความยินยอม (Right to withdraw consent)',
        Detail: [
          {
            detail:
              'เมื่อท่านให้ความยินยอมกับบริษัทฯ เพื่อวัตถุประสงค์อย่างหนึ่งอย่างใดโดยเฉพาะแล้ว ท่านมีสิทธิถอนความยินยอมเมื่อใดก็ได้ เว้นแต่เป็นกรณีที่มีข้อจำกัดสิทธิตามกฎหมาย หรือเกี่ยวข้องกับสัญญาที่ให้ประโยชน์แก่ท่าน',
          },
          {
            detail:
              'ท่านมีสิทธิในการถอนความยินยอมในการประมวลผลข้อมูลส่วนบุคคลที่ท่านได้ให้ความยินยอมกับบริษัทฯ ได้ตลอดระยะเวลาที่ข้อมูลส่วนบุคคลของท่านอยู่กับบริษัทฯ',
          },
          {
            detail:
              'หากการถอนความยินยอมของท่านทำให้บริษัทฯ ไม่สามารถส่งมอบผลิตภัณฑ์หรือให้บริการบางอย่างให้แก่ท่านได้ บริษัทฯ จะแจ้งให้ท่านทราบถึงผลกระทบจากการถอนความยินยอม',
          },
        ],
      },
      {
        Header: 'สิทธิในการขอเข้าถึงและขอรับสำเนาข้อมูลส่วนบุคคล (Right to access)',
        Detail: [
          {
            detail: 'ท่านมีสิทธิในการขอเข้าถึงข้อมูลส่วนบุคคลของท่าน และขอให้บริษัทฯ ทำสำเนาข้อมูลส่วนบุคคลดังกล่าวให้แก่ท่าน',
          },
        ],
      },
      {
        Header: 'สิทธิในการขอให้โอนข้อมูลส่วนบุคคล (Right to data portability)',
        Detail: [
          {
            detail:
              'ท่านมีสิทธิในการขอให้บริษัทฯ โอนข้อมูลส่วนบุคคลของท่านที่ท่านให้ไว้กับบริษัทฯ ไปยังผู้ควบคุมข้อมูลส่วนบุคคลรายอื่นหรือตัวท่านเองด้วยเหตุบางประการได้ ทั้งนี้ สิทธิในการขอให้โอนนี้ใช้เฉพาะกรณีของข้อมูลส่วนบุคคลที่ท่านส่งมอบให้แก่บริษัทฯ และการเก็บรวบรวม ใช้ และ/หรือ เปิดเผยข้อมูลส่วนบุคคลดังกล่าวได้กระทำโดยอาศัยความยินยอมของท่าน หรือในกรณีที่ข้อมูลส่วนบุคคลดังกล่าวจำเป็นต้องได้รับการเก็บรวบรวม ใช้ และ/หรือ เปิดเผยเพื่อให้สามารถปฏิบัติตามภาระข้อผูกพันภายใต้สัญญาได้เท่านั้น',
          },
        ],
      },
      {
        Header: 'สิทธิในการคัดค้านการประมวลผลข้อมูลส่วนบุคคล (Right to object)',
        Detail: [
          {
            detail: 'ท่านมีสิทธิในการคัดค้านการประมวลผลข้อมูลส่วนบุคคลของท่านด้วยเหตุบางประการได้',
          },
        ],
      },
      {
        Header: 'สิทธิในการขอให้ลบข้อมูลส่วนบุคคล (Right to erasure)',
        Detail: [
          {
            detail: 'ท่านมีสิทธิในการขอให้บริษัทฯ ลบข้อมูลส่วนบุคคลของท่านด้วยเหตุบางประการได้',
          },
        ],
      },
      {
        Header: 'สิทธิในการขอให้ระงับการใช้ข้อมูลส่วนบุคคล (Right to restriction of processing)',
        Detail: [
          {
            detail: 'ท่านมีสิทธิในการขอให้บริษัทฯ ระงับการใช้ข้อมูลส่วนบุคคลของท่านด้วยเหตุบางประการได้',
          },
        ],
      },
      {
        Header: 'สิทธิในการแก้ไขข้อมูลส่วนบุคคลให้ถูกต้อง (Right to rectification)',
        Detail: [
          {
            detail:
              'ท่านมีสิทธิในการขอให้บริษัทฯ แก้ไขข้อมูลส่วนบุคคลของท่านที่ไม่ถูกต้อง ไม่เป็นปัจจุบันหรือไม่สมบูรณ์ โดยบริษัทฯ จะดำเนินการตรวจสอบและแก้ไขให้ถูกต้อง',
          },
          {
            detail:
              'ในกรณีที่ท่านขอแก้ไขข้อมูลส่วนบุคคลบางอย่างที่บริษัทฯ พิจารณาแล้วว่ามีความจำเป็นต้องยืนยันตัวตนท่านเพิ่มเติม บริษัทฯ จะขอเอกสารและ/หรือข้อมูลเพิ่มเติม',
          },
        ],
      },
    ],
    cookieData: [
      {
        detail: 'เพื่อศึกษาพฤติกรรมการใช้งานเว็บไซต์ของท่าน เพื่อนำไปพัฒนาให้สามารถใช้งานได้ง่ายรวดเร็ว และมีประสิทธิภาพยิ่งขึ้น',
      },
      {
        detail: 'เพื่อให้ท่านสามารถ Sign in บัญชีของท่านในเว็บไซต์ของบริษัทฯ ได้อย่างต่อเนื่อง',
      },
      {
        detail: 'เพื่อปกป้องท่านจากการฉ้อโกงและปรับปรุงความปลอดภัย',
      },
      {
        detail: 'เพื่อวิเคราะห์ว่าผลิตภัณฑ์บริการและข้อเสนอใดของบริษัทฯ ที่อาจเกี่ยวข้องกับท่าน',
      },
      {
        detail: 'เพื่อปรับปรุงการตลาดที่ท่านเห็นบนสื่อสังคมออนไลน์ แอปพลิเคชัน และเว็บไซต์อื่น ',
      },
      {
        detail: 'เพื่อปรับปรุงการตลาดที่ท่านเห็นบนสื่อสังคมออนไลน์ แอปพลิเคชัน และเว็บไซต์อื่น ',
      },
    ],
  };

  useEffect(() => {
    const itemType = getFieldValue('itemType');
    if (itemType) {
      const filter =
        productData &&
        productData.filter((item) => {
          return item.productTypeId === itemType;
        });
      if (_.get(filter, '[0].is_other') === true) {
        setDisbleOther(false);
      } else {
        setDisbleOther(true);
      }
    }
  }, [getFieldValue('itemType')]);

  const handleCreate = () => {
    validateFields(async (err, values) => {
      if (err) {
        return;
      }

      const filter =
        productData &&
        productData.filter((item) => {
          return item.productTypeId === values.itemType;
        });
      const momentDate = moment(values.date).format('YYYY-MM-DD');

      const body = {
        is_other: _.get(filter, '[0].is_other'),
        other_product_type_name: _.get(filter, '[0].is_other') !== false ? values.other : undefined,
        product_type_name: _.get(filter, '[0].product_type_name'),
        product_type_code: _.get(filter, '[0].product_type_code'),
        product_type_id: _.get(filter, '[0].productTypeId'),
        product_type_ref: _.get(filter, '[0].product_type_ref'),
        product_detail: values.model,
        sold_date: momentDate,
        product_no: values.itemNumber,
        store_name: values.shopName,
        issue_description: values.issue,
        name: values.fullName,
        phone: values.phone,
        lat: _.get(latLng, 'lat'),
        lng: _.get(latLng, 'lng'),
        address: values.address,
        remark: values.note,
      };
      const response = await addTicket(body);
      if (response.status === 200) {
        successNotification(response.data.status.message);
        resetFields();
        setCheck(false);
        setTrigger((current) => !current);
      } else {
        errorNotification(response.data.status.message);
      }
    });
  };

  return (
    <div className="support-service-main-background support-service-main-background-paddind">
      <div className="support-service-card-background">
        <Row className="support-service-card-detail support-service-card-detail-paddind">
          <div className="support-service-text-header">Diamond Service</div>
          <div className="support-service-div-divider" />
          <Form>
            <Row gutter={[16, 16]}>
              <Col lg={12} md={12} sm={24}>
                <Form.Item className="support-service-form-item" label={<LabeRequire text="ประเภทสินค้า" req={true} />}>
                  {getFieldDecorator('itemType', {
                    rules: [
                      {
                        required: true,
                        message: 'กรุณาเลือกประเภทสินค้า',
                      },
                    ],
                  })(
                    <Select>
                      {productData && productData.map((item) => <Option key={item.productTypeId}>{item.product_type_name}</Option>)}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col lg={12} md={12} sm={24}>
                <Form.Item
                  className="support-service-form-item"
                  label={<LabeRequire text="อื่น ๆ (โปรดระบุ)" req={disbleOther === false ? true : false} />}
                >
                  {getFieldDecorator('other', {
                    rules: [
                      {
                        required: disbleOther === false ? true : false,
                        message: 'กรุณาระบุ',
                      },
                    ],
                  })(
                    <Input
                      disabled={disbleOther}
                      maxLength={100}
                    // placeholder={intl.formatMessage({ id: `supportServiceOtherPlaceHolder`, defaultMessage: 'Other' })}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col lg={12} md={12} sm={24}>
                <Form.Item className="support-service-form-item" label={<LabeRequire text="รุ่น / ขนาด" req={false} />}>
                  {getFieldDecorator(
                    'model',
                    {}
                  )(
                    <Input
                      maxLength={100}
                    // placeholder={intl.formatMessage({ id: `supportServiceModelePlaceHolder`, defaultMessage: 'Model / Size ' })}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col lg={12} md={12} sm={24}>
                <Form.Item className="support-service-form-item" label={<LabeRequire text="วันที่ซื้อ" req={false} />}>
                  {getFieldDecorator('date', {})(<DatePicker style={{ width: '100%' }} placeholder={false} />)}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col lg={12} md={12} sm={24}>
                <Form.Item className="support-service-form-item" label={<LabeRequire text="หมายเลขสินค้า" req={false} />}>
                  {getFieldDecorator(
                    'itemNumber',
                    {}
                  )(
                    <Input
                      maxLength={100}
                    // placeholder={intl.formatMessage({ id: `supportServiceModelePlaceHolder`, defaultMessage: 'Model / Size ' })}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col lg={12} md={12} sm={24}>
                <Form.Item className="support-service-form-item" label={<LabeRequire text="ชื่อจากร้านค้า" req={false} />}>
                  {getFieldDecorator(
                    'shopName',
                    {}
                  )(
                    <Input
                      maxLength={100}
                    // placeholder={intl.formatMessage({ id: `supportServiceModelePlaceHolder`, defaultMessage: 'Model / Size ' })}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col lg={24} md={24} sm={24}>
                <Form.Item className="support-service-form-item" label={<LabeRequire text="แจ้งปัญหาที่พบ" req={true} />}>
                  {getFieldDecorator('issue', {
                    rules: [
                      {
                        required: true,
                        message: 'กรุณากรอกปัญหาที่พบ',
                      },
                    ],
                  })(
                    <TextArea
                      // placeholder={intl.formatMessage({ id: `supportServiceModelePlaceHolder`, defaultMessage: 'Model / Size ' })}
                      autosize={{ minRows: 3, maxRows: 3 }}
                      maxLength={250}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col lg={12} md={12} sm={24}>
                <Form.Item className="support-service-form-item" label={<LabeRequire text="ชื่อ - นามสกุล" req={true} />}>
                  {getFieldDecorator('fullName', {
                    rules: [
                      {
                        required: true,
                        message: 'กรุณากรอกชื่อ - นามสกุล',
                      },
                    ],
                  })(
                    <Input
                      maxLength={100}
                    // placeholder={intl.formatMessage({ id: `supportServiceModelePlaceHolder`, defaultMessage: 'Model / Size ' })}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col lg={12} md={12} sm={24}>
                <Form.Item className="support-service-form-item" label={<LabeRequire text="เบอร์โทรศัพท์" req={true} />}>
                  {getFieldDecorator('phone', {
                    rules: [
                      {
                        required: true,
                        message: 'กรุณากรอกเบอร์โทรศัพท์',
                      },
                    ],
                  })(
                    <Input
                      maxLength={20}
                    // placeholder={intl.formatMessage({ id: `supportServiceModelePlaceHolder`, defaultMessage: 'Model / Size ' })}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col lg={24} md={24} sm={24}>
                <Form.Item className="support-service-form-item" label={<LabeRequire text="ที่อยู่" req={false} />}>
                  {getFieldDecorator(
                    'address',
                    {}
                  )(
                    <AutoComplete
                      // placeholder={intl.formatMessage({ id: 'ModalLocationLocationPlaceholderMapLocation', defaultMessage: 'Enter Map Location' })}
                      dataSource={addressArea}
                      onSearch={handleSearchLocation}
                      onSelect={handleSelect}
                      filterOption={false}
                    >
                      {children}
                    </AutoComplete>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col lg={24} md={24} sm={24}>
                <Map center={center} position={position} setLatlngTo={setlatLng} setValueAddress={setAddress} />
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col lg={24} md={24} sm={24}>
                <Form.Item className="support-service-form-item" label={<LabeRequire text="หมายเหตุ" req={false} />}>
                  {getFieldDecorator(
                    'note',
                    {}
                  )(
                    <TextArea
                      // placeholder={intl.formatMessage({ id: `supportServiceModelePlaceHolder`, defaultMessage: 'Model / Size ' })}
                      autosize={{ minRows: 3, maxRows: 3 }}
                      maxLength={250}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col lg={14} md={14} sm={24} className="support-service-div-button" style={{ justifyContent: 'flex-start' }}>
                <div id="on_off" class="on_off_on" onClick={handleCheckbox} style={{ marginRight: '8px' }}>
                  {loading ? (
                    <Icon type="loading" style={{ color: '#1D3557' }} className="icon-support-service" />
                  ) : (
                    <Icon type={check ? 'check-square' : 'border'} style={{ color: '#1D3557' }} className="icon-support-service" />
                  )}
                </div>
                <span className="support-service-card-policy">
                  ฉันยอมรับ
                  {
                    <span
                      className="support-service-card-policy"
                      style={{ color: '#1D3557', cursor: 'pointer' }}
                      onClick={() => setVisibleModal(true)}
                    >
                      นโยบายความเป็นส่วนตัว
                    </span>
                  }
                  ของ WorkD
                </span>
              </Col>
              <Col lg={10} md={10} sm={24} className="support-service-div-button">
                <Button01
                  type="primary"
                  btnsize="wd_px"
                  fontSize="sm"
                  style={{ margin: 'unset' }}
                  disabled={check ? false : true}
                  onClick={handleCreate}
                >
                  ส่ง
                </Button01>
              </Col>
            </Row>
          </Form>
        </Row>
      </div>
      <Modal
        title={false}
        width={850}
        visible={visibleModal}
        centered={true}
        footer={false}
        onCancel={handleCancelModal}
        className="support-service-modal-main-body"
      >
        <div className="support-service-modal-main-padding">
          <div className="support-service-modal-text-header1">นโยบายคุ้มครองข้อมูลส่วนบุคคล</div>
          <div className="support-service-modal-text-detail-scroll" onScroll={() => onScroll()} ref={ref} id="scroll-div">
            <div className="support-service-modal-text-header2">
              นโยบายคุ้มครองข้อมูลส่วนบุคคลนี้จัดทำขึ้นเพื่ออธิบายให้ท่านทราบถึงแนวทาง ปฏิบัติของบริษัทฯ
              ในส่วนที่เกี่ยวข้องกับข้อมูลส่วนบุคคล และเหตุผลในการเก็บ รวบรวม การใช้ การเปิดเผยข้อมูลส่วนบุคคลของท่าน และสิทธิของท่านในฐานะ
              เจ้าของข้อมูลส่วนบุคคล
            </div>

            <div className="support-service-modal-text-detail1">เราคือใคร</div>
            <div className="support-service-modal-text-detail2">
              นโยบายคุ้มครองข้อมูลส่วนบุคคลนี้ใช้บังคับกับข้อมูลส่วนบุคคลลูกค้าผู้จัดหาหรือจัดจำหน่าย และบุคคลภายนอกที่ เก็บรวบรวมโดย บริษัท
              เค.จี. เทคโนโลยีส์ จำกัด (เรียกว่า "บริษัทฯ") บริษัทฯ พัฒนาและปรับปรุงการคุ้มครองข้อมูล
              ส่วนบุคคลโดยพิจารณาตามหลักการทั่วโลกและภายในประเทศกฎหมายที่มีผลบังคับใช้แล้วและแนวทางปฏิบัติรวมถึง
              พระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562 (พ.ร.บ. คุ้มครองข้อมูลส่วนบุคคล) ในกรณีที่ท่านมีข้อซักถาม
              เกี่ยวกับนโยบายคุ้มครองข้อมูลส่วนบุคคลกรุณาติดต่อเจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล ผ่านช่องทางการติดต่อด้านล่าง
            </div>
            <div className="support-service-modal-text-detail2">
              สถานที่: 6 ซอยเรวดี 7 ต.ตลาดขวัญ อ.เมืองนนทบุรี จ.นนทบุรี 11000 หมายเลขโทรศัพท์: 02-951-4455 อีเมล: contactme@kgcorp.com
            </div>
            <div className="support-service-modal-text-detail2 support-service-modal-text-detail-end">
              เมื่อได้รับคำถามหรือข้อกังวลของท่าน บริษัทฯจะติดต่อท่านภายในเวลาอันสมควรเพื่อตอบคำถามหรือข้อกังวลของ ท่าน
              ในบางกรณีบริษัทอาจต้องขอข้อมูลเพิ่มเติมเพื่อยืนยันตัวตนของท่าน
            </div>

            <div className="support-service-modal-text-detail1">ข้อมูลส่วนบุคคล คืออะไร</div>
            <div className="support-service-modal-text-detail2 support-service-modal-text-detail-end">
              ข้อมูลส่วนบุคคล หมายถึง ข้อมูลเกี่ยวกับบุคคลซึ่งทำให้สามารถระบุตัวบุคคลนั้นได้ไม่ว่าทางตรงหรือทางอ้อม แต่ไม่
              รวมถึงข้อมูลของผู้ถึงแก่กรรมโดยเฉพาะ
            </div>

            <div className="support-service-modal-text-detail1">ข้อมูลส่วนบุคคลที่บริษัทเก็บรวบรวม</div>
            <div className="support-service-modal-text-detail2" style={{ marginBottom: '44px' }}>
              บริษัทฯ จะเก็บรวบรวมข้อมูลส่วนบุคคลอันเกี่ยวกับการใช้บริการในปัจจุบันและที่อาจมีเพิ่มขึ้นในอนาคตดังต่อไปนี้
            </div>
            {dataDetail.personalData.map((item) => (
              <div className="support-service-modal-text-detail2" style={{ marginBottom: '20px' }}>
                <Badge color="#1D3557" text={item.detail} />
              </div>
            ))}

            <div className="support-service-modal-text-detail1" style={{ marginTop: '105px' }}>
              แหล่งที่มาของข้อมูลส่วนบุคคล
            </div>
            <div className="support-service-modal-text-detail2" style={{ marginBottom: '16px' }}>
              บริษัทฯ อาจได้รับข้อมูลส่วนบุคคลของท่านจากช่องทางดังนี้
            </div>
            <div className="support-service-modal-text-detail2" style={{ marginBottom: '16px' }}>
              บริษัทฯ ได้รับข้อมูลส่วนบุคคลจากท่านโดยตรง โดยบริษัทฯ จะเก็บรวบรวมข้อมูลส่วนบุคคลของท่านจากขั้นตอนการให้บริการต่าง ๆ รวมถึง
            </div>
            {dataDetail.sourceData.map((item) => (
              <div className="support-service-modal-text-detail2" style={{ marginBottom: '5px' }}>
                <Badge color="#1D3557" text={item.detail} />
              </div>
            ))}

            <div className="support-service-modal-text-detail1" style={{ marginTop: '105px' }}>
              วัตถุประสงค์ในการประมวลผลข้อมูลส่วนบุคคล
            </div>
            <div className="support-service-modal-text-detail2" style={{ marginBottom: '16px' }}>
              บริษัทฯ เก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของท่านเพื่อวัตถุประสงค์ดังต่อไปนี้
            </div>
            {dataDetail.objectiveData.map((item) => (
              <div className="support-service-modal-text-detail2" style={{ marginBottom: '20px' }}>
                <Badge color="#1D3557" text={item.detail} />
              </div>
            ))}

            <div className="support-service-modal-text-detail1" style={{ marginTop: '105px' }}>
              การประมวลผลข้อมูลส่วนบุคคล
            </div>
            <div className="support-service-modal-text-detail2">
              เมื่อได้รับข้อมูลส่วนบุคคลจากแหล่งที่มาของข้อมูลส่วนบุคคลแล้ว บริษัทฯ จะดำเนินการเก็บรวบรวม ใช้ หรือเปิดเผย
              ข้อมูลส่วนบุคคลของท่านตามวัตถุประสงค์ที่ได้แจ้งไว้ และจะประมวลผลข้อมูลส่วนบุคคลของท่านเท่าที่กฎหมาย อนุญาตให้กระทำได้เท่านั้น
              กล่าวคืออยู่บนฐานทางกฎหมายในการประมวลผลข้อมูล ทั้งนี้บริษัทฯ อาจเปิดเผยข้อมูล ส่วนบุคคลและข้อมูลใด ๆ
              อันเกี่ยวกับการใช้บริการของท่านหรือข้อมูลอื่นใดที่บริษัทฯ เก็บรวบรวมไว้ดังที่อธิบายไว้ในข้อ 3
              ข้างต้นให้แก่หน่วยงานราชการตามกฎหมาย ผู้ให้บริการด้านเทคโนโลยีสารสนเทศ ธนาคารและผู้ให้บริการด้านการ ชำระเงินและการทำธุรกรรม
              ผู้ให้บริการสนับสนุนลูกค้า ผู้ให้บริการจัดการด้านการตลาดและการโฆษณา ที่ปรึกษาทาง ธุรกิจ
              รวมถึงนิติบุคคลหรือบุคคลอื่นใดที่บริษัทฯ และ/หรือท่านเป็นคู่สัญญาหรือมีนิติสัมพันธ์ตามกฎหมาย นอกจากนี้ บริษัทฯ
              อาจจำเป็นต้องส่งข้อมูลส่วนบุคคลของท่านไปยังหน่วยงานกำกับดูแลเพื่อตรวจสอบ และอาจใช้ผลการตรวจ
              สอบข้อมูลดังกล่าวเพื่อป้องกันการฉ้อโกง และบริษัทฯ อาจเปิดเผยข้อมูลของท่านแก่บุคคลหรือนิติบุคคลภาย นอกบริษัทฯ ซึ่งบริษัทฯ
              อาจขายหรือโอนธุรกิจหรือสินทรัพย์บางส่วนของบริษัทฯ ให้ หรือในทางกลับกันบริษัทฯ อาจมี
              แผนเข้าซื้อหรือควบรวมกิจการกับบุคคลหรือนิติบุคคลภายนอก ทั้งนี้ หากมีการเปลี่ยนแปลงในธุรกิจของบริษัทฯ ใน ส่วนที่มีการจำหน่ายไป
              ได้มา หรือควบรวมกับบริษัทอื่น (แล้วแต่กรณี) อาจมีการใช้ข้อมูลของท่านในลักษณะเดียวกับที่
              อธิบายไว้ในนโยบายคุ้มครองข้อมูลส่วนบุคคลนี้ ในกรณีที่บริษัทฯ ว่าจ้างผู้อื่นให้ทำหน้าที่เป็นผู้ประมวลผลข้อมูลส่วนบุคคล บริษัทฯ
              จะดำเนินการตรวจสอบมาตรการ คุ้มครองข้อมูลส่วนบุคคล
              และจัดให้มีการลงนามในข้อตกลงเพื่อควบคุมและคุ้มครองข้อมูลส่วนบุคคลให้เหมาะสมและ เป็นไปตามหลักเกณฑ์ที่กฎหมายกำหนด บริษัทฯ
              อาจมีความจำเป็นในการโอนข้อมูลส่วนบุคคลของท่านไปยังบุคคล หน่วยงานต่างประเทศหรือองค์กรระหว่าง ประเทศ
              ที่มิได้มีมาตรฐานด้านการคุ้มครองข้อมูลส่วนบุคคลที่เพียงพอ (Appropriate safeguards) ตามที่คณะ
              กรรมการคุ้มครองข้อมูลส่วนบุคคลกำหนด ในกรณีดังกล่าว บริษัทฯ จะทำให้มั่นใจว่า การโอนข้อมูลดังกล่าวชอบด้วย กฎหมาย ตัวอย่างเช่น
              ข้อมูลส่วนบุคคลของท่านอาจถูกเปิดเผยให้กับบริษัทอื่น ๆ ตามนโยบายหรือกฎเกณฑ์การคุ้ม ครองข้อมูลส่วนบุคคลภายในองค์กร (Binding
              Corporate Rules - BCRs) หรือสัญญาโอนข้อมูลอื่น ๆ บริษัทฯ ดำเนินการตรวจสอบภายในและภายนอกเพื่อการปกป้องข้อมูลของลูกค้าเป็นประจำ
            </div>
            <div className="support-service-modal-text-detail2">
              ในกรณีที่บริษัทฯ ว่าจ้างผู้อื่นให้ทำหน้าที่เป็นผู้ประมวลผลข้อมูลส่วนบุคคล บริษัทฯ จะดำเนินการตรวจสอบมาตรการ
              คุ้มครองข้อมูลส่วนบุคคล และจัดให้มีการลงนามในข้อตกลงเพื่อควบคุมและคุ้มครองข้อมูลส่วนบุคคลให้เหมาะสมและ
              เป็นไปตามหลักเกณฑ์ที่กฎหมายกำหนด
            </div>
            <div className="support-service-modal-text-detail2">
              บริษัทฯ อาจมีความจำเป็นในการโอนข้อมูลส่วนบุคคลของท่านไปยังบุคคล หน่วยงานต่างประเทศหรือองค์กรระหว่าง ประเทศ
              ที่มิได้มีมาตรฐานด้านการคุ้มครองข้อมูลส่วนบุคคลที่เพียงพอ (Appropriate safeguards) ตามที่คณะ
              กรรมการคุ้มครองข้อมูลส่วนบุคคลกำหนด ในกรณีดังกล่าว บริษัทฯ จะทำให้มั่นใจว่า การโอนข้อมูลดังกล่าวชอบด้วย กฎหมาย ตัวอย่างเช่น
              ข้อมูลส่วนบุคคลของท่านอาจถูกเปิดเผยให้กับบริษัทอื่น ๆ ตามนโยบายหรือกฎเกณฑ์การคุ้ม ครองข้อมูลส่วนบุคคลภายในองค์กร (Binding
              Corporate Rules - BCRs) หรือสัญญาโอนข้อมูลอื่น ๆ บริษัทฯ ดำเนินการตรวจสอบภายในและภายนอกเพื่อการปกป้องข้อมูลของลูกค้าเป็นประจำ
            </div>

            <div className="support-service-modal-text-detail1" style={{ marginTop: '105px' }}>
              การเก็บรักษาและระยะเวลาในการเก็บรักษาข้อมูลส่วนบุคคล
            </div>
            <div className="support-service-modal-text-detail2" style={{ marginBottom: '16px' }}>
              บริษัทฯ มีมาตรการรักษาความมั่นคงปลอดภัยและระยะเวลาในการเก็บรักษาข้อมูลส่วนบุคคลของท่าน ดังนี้
            </div>
            {dataDetail.storageData.map((item) => (
              <div className="support-service-modal-text-detail2" style={{ marginBottom: '20px' }}>
                <Badge color="#1D3557" text={item.detail} />
              </div>
            ))}

            <div className="support-service-modal-text-detail1" style={{ marginTop: '105px' }}>
              สิทธิของเจ้าของข้อมูลส่วนบุคคล
            </div>
            <div className="support-service-modal-text-detail2" style={{ marginBottom: '16px' }}>
              เมื่อบทบัญญัติหมวดสิทธิของเจ้าของข้อมูลส่วนบุคคลตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562 มีผลบังคับใช้
              ท่านในฐานะเจ้าของข้อมูลส่วนบุคคลมีสิทธิเกี่ยวกับข้อมูลส่วนบุคคล ดังนี้
            </div>
            {dataDetail.personalDataSubject.map((item) => (
              <div>
                <div
                  className="support-service-modal-text-detail2 support-service-modal-text-detail2-header"
                  style={{ marginBottom: '15px' }}
                >
                  {item.Header}
                </div>
                {item.Detail.map((item) => (
                  <div className="support-service-modal-text-detail2" style={{ marginBottom: '20px' }}>
                    <Badge color="#1D3557" text={item.detail} />
                  </div>
                ))}
              </div>
            ))}
            <div className="support-service-modal-text-detail2">
              ท่านสามารถติดต่อเจ้าหน้าที่ของบริษัทฯ เพื่อยื่นคำร้องขอใช้สิทธิข้างต้นตามรายละเอียดที่ปรากฏในข้อ 1 หรือตาม ช่องทางที่บริษัทฯ
              กำหนด ทั้งนี้ ท่านไม่ต้องเสียค่าธรรมเนียมในการใช้สิทธิของท่าน แต่ทั้งนี้อาจมีการเรียกเก็บค่า
              ธรรมเนียมตามแต่กรณีหากพบว่าคำขอของท่านไม่มีมูล ซ้ำซาก หรือมีจำนวนมากเกินจำเป็น อนึ่ง บริษัทฯ มีสิทธิใน
              การปฏิเสธคำร้องขอที่เป็นการใช้สิทธิโดยไม่สุจริต คำร้องขอที่ไม่สมเหตุสมผล คำร้องขอที่กฎหมายไม่ได้บัญญัติไว้
              และ/หรือคำร้องขอที่ไม่สามารถปฏิบัติได้จริง นอกจากนี้ บริษัทฯ มีสิทธิในการปฏิเสธคำร้องขอของท่านตามหลัก เกณฑ์ที่กฎหมายกำหนด
              ทั้งนี้ ท่านมีสิทธิในการร้องเรียนต่อสำนักงานคณะกรรมการคุ้มครองข้อมูลส่วนบุคคลตาม หลักเกณฑ์ที่กฎหมายกำหนด
            </div>

            <div className="support-service-modal-text-detail1" style={{ marginTop: '105px' }}>
              ในกรณีที่ท่านปฏิเสธไม่ให้ใช้ข้อมูลส่วนบุคคล
            </div>
            <div className="support-service-modal-text-detail2" style={{ marginBottom: '16px' }}>
              บริษัทฯ มีความจำเป็นต้องรวบรวมข้อมูลส่วนบุคคลตามกฎหมายหรือเพื่อการเข้าทำสัญญาระหว่างท่านกับบริษัทฯ
              หากท่านเลือกที่จะไม่ให้ข้อมูลส่วนบุคคล บริษัทฯ อาจไม่สามารถให้บริการแก่ท่านได้
            </div>
            <div className="support-service-modal-text-detail2">
              นอกเหนือจากกรณีที่บริษัทฯ ประมวลผลข้อมูลส่วนบุคคลเพื่อการปฏิบัติตามสัญญาและการปฏิบัติตามกฎหมายแล้ว ในบางกรณี บริษัทฯ
              อาจมีการขอความยินยอมจากท่านในการเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของท่าน
              ตามวัตถุประสงค์ในการประมวลผลข้อมูลส่วนบุคคลที่ได้ระบุไว้ในข้อ 5 เพื่อให้ท่านได้รับประโยชน์สูงสุด และ/หรือ เพื่อให้บริษัทฯ
              สามารถให้บริการที่ตอบสนองต่อความต้องการของท่านได้อย่างเหมาะสม
            </div>

            <div className="support-service-modal-text-detail1" style={{ marginTop: '105px' }}>
              กิจกรรมทางการตลาดและการส่งเสริมการตลาด
            </div>
            <div className="support-service-modal-text-detail2">
              ในระหว่างการใช้บริการ บริษัทฯ จะส่งข้อมูลข่าวสารเกี่ยวกับกิจกรรมทางการตลาดและการส่งเสริมการตลาด ผลิตภัณฑ์และบริการของบริษัทฯ
              โดยท่านสามารถดำเนินการยกเลิกความยินยอมในการรับแจ้งข้อมูลข่าวสารได้ตาม ช่องทางที่บริษัทฯ กำหนด
            </div>

            <div className="support-service-modal-text-detail1" style={{ marginTop: '105px' }}>
              นโยบายคุกกี้ (Cookies)
            </div>
            <div className="support-service-modal-text-detail2">
              ในระหว่างการเข้าใช้งานเว็บไซต์ของบริษัทฯ ข้อมูลที่เกี่ยวข้องกับการเข้าใช้งานเว็บไซต์ของท่านจะถูกบันทึกไว้ในรูป แบบของคุกกี้
              โดยนโยบายคุกกี้นี้จะอธิบายความหมายของคุกกี้ การทำงาน วัตถุประสงค์ของคุกกี้ และวิธีการลบคุกกี้ เพื่อความเป็นส่วนตัวของท่าน
              โดยการเข้าใช้งานเว็บไซต์ของบริษัทฯ ถือว่าท่านได้อนุญาตให้บริษัทฯ ใช้คุกกี้ ตามนโยบายคุกกี้ที่มีรายละเอียดดังต่อไปนี้
            </div>
            <div className="support-service-modal-text-detail2">
              คุกกี้คืออะไร Cookies คือ Text files ที่อยู่ในคอมพิวเตอร์ และ/หรือ เครื่องมือสื่อสารที่เข้าใช้งานของท่าน เช่น แท็บเล็ต
              สมาร์ทโฟน ผ่านทางเว็บเบราว์เซอร์ในขณะที่ท่านเข้าสู่เว็บไซต์ของบริษัทฯ โดยคุกกี้จะไม่ก่อให้เกิดอันตรายต่ออุปกรณ์ คอมพิวเตอร์
              และ/หรือ เครื่องมือสื่อสารของท่าน ที่ใช้เพื่อจัดเก็บรายละเอียดข้อมูล Log การใช้งานอินเทอร์เน็ตหรือ
              พฤติกรรมการเยี่ยมชมเว็บไซต์ของท่าน ท่านสามารถศึกษารายละเอียดเพิ่มเติมของ Cookies ได้จาก https:// www.allaboutcookies.org
            </div>
            <div className="support-service-modal-text-detail2 support-service-modal-text-detail2-header">บริษัทใช้ Cookies อย่างไร</div>
            <div className="support-service-modal-text-detail2">
              บริษัทฯ จะจัดเก็บข้อมูลการเข้าเยี่ยมชมเว็บไซต์จากผู้เข้าเยี่ยมชมทุกรายผ่าน Cookies หรือเทคโนโลยีที่ใกล้เคียง และบริษัทฯ จะใช้
              Cookies เพื่อประโยชน์ในการพัฒนาประสิทธิภาพในการเข้าถึงบริการของบริษัทฯ ผ่านอินเทอร์เน็ต
              รวมถึงพัฒนาประสิทธิภาพในการใช้งานบริการของบริษัทฯ ทางอินเทอร์เน็ต โดยจะใช้เพื่อวัตถุประสงค์ดังต่อไปนี้
            </div>
            {dataDetail.cookieData.map((item) => (
              <div className="support-service-modal-text-detail2" style={{ marginBottom: '20px' }}>
                <Badge color="#1D3557" text={item.detail} />
              </div>
            ))}
            <div className="support-service-modal-text-detail2">
              ท่านสามารถปฏิเสธการรวบรวมข้อมูลผ่านคุกกี้ได้ผ่านการตั้งค่าเบราว์เซอร์ของท่าน ทั้งนี้ การตั้งค่าดังกล่าวอาจลด
              ประสิทธิภาพสูงสุดในการใช้งานเว็บไซต์และแอปพลิเคชันลง
            </div>
            <div className="support-service-modal-text-detail2">
              ข้อมูลเพิ่มเติมเกี่ยวกับวิธีที่เราใช้คุกกี้และเทคโนโลยีการติดตามอื่น ๆ และวิธีที่ท่านสามารถควบคุมสิ่งเหล่านี้ได้ สามารถพบได้ใน
              นโยบายการใช้คุกกี้
            </div>

            <div className="support-service-modal-text-detail1" style={{ marginTop: '105px' }}>
              ผู้เยาว์
            </div>
            <div className="support-service-modal-text-detail2">
              ด้วยลักษณะของบริการรวมถึงผลิตภัณฑ์ของบริษัทฯ นั้น บริษัทฯ อาจเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลที่
              เกี่ยวกับบุคคลที่มีอายุต่ำกว่า 20 ปีบริบูรณ์ ในกรณีดังกล่าวนั้น บริษัทฯ จะปฏิบัติตามกฎหมายคุ้มครองข้อมูลส่วน
              บุคคลที่เกี่ยวข้องกับการประมวลผลข้อมูลส่วนบุคคลของผู้เยาว์ ซึ่งรวมถึงการขอความยินยอมจากผู้แทนโดยชอบ
              ธรรมหรือผู้ใช้อำนาจปกครองตามที่กฎหมายคุ้มครองข้อมูลส่วนบุคคลกำหนด
            </div>

            <div className="support-service-modal-text-detail1" style={{ marginTop: '105px' }}>
              การเก็บรวบรวมและใช้ข้อมูลส่วนบุคคลตามวัตถุประสงค์เดิม
            </div>
            <div className="support-service-modal-text-detail2">
              บริษัทฯ จะประมวลผลข้อมูลส่วนบุคคลตามกฎหมายที่ใช้บังคับอยู่ในปัจจุบัน ทั้งนี้ บริษัทฯ มีสิทธิเก็บรวบรวมและใช้
              ข้อมูลส่วนบุคคลที่บริษัทฯ เก็บรวบรวมไว้ก่อนวันที่ พ.ร.บ. คุ้มครองข้อมูลส่วนบุคคลในส่วนที่เกี่ยวข้องกับการเก็บรวบ รวม การใช้
              และการเปิดเผยข้อมูลส่วนบุคคลมีผลใช้บังคับต่อไปได้ตามวัตถุประสงค์เดิม โดยหากท่านไม่ประสงค์ที่จะให้ บริษัทฯ
              เก็บรวมรวมและใช้ข้อมูลส่วนบุคคลดังกล่าวต่อไปภายหลัง พ.ร.บ. คุ้มครองข้อมูลส่วนบุคคลใช้บังคับแล้ว ท่าน
              สามารถแจ้งยกเลิกความยินยอมได้ตามช่องทางที่บริษัทฯ จะได้เผยแพร่ประชาสัมพันธ์ต่อไป
            </div>

            <div className="support-service-modal-text-detail1" style={{ marginTop: '105px' }}>
              นโยบายคุ้มครองข้อมูลส่วนบุคคลของเว็บไซต์อื่น
            </div>
            <div className="support-service-modal-text-detail2">
              นโยบายคุ้มครองข้อมูลส่วนบุคคลฉบับนี้ใช้เฉพาะสำหรับการให้บริการของบริษัทฯ และการใช้งานเว็บไซต์ของบริษัทฯ เท่านั้น
              หากท่านได้เชื่อมต่อไปยังเว็บไซต์อื่น แม้จะผ่านช่องทางในเว็บไซต์ของบริษัทฯ ก็ตาม ท่านจะต้องศึกษาและ
              ปฏิบัติตามนโยบายคุ้มครองข้อมูลส่วนบุคคลที่ปรากฏในเว็บไซต์นั้น ๆ แยกต่างหากจากของบริษัทฯ
            </div>

            <div className="support-service-modal-text-detail1" style={{ marginTop: '105px' }}>
              การเปลี่ยนแปลงนโยบายคุ้มครองข้อมูลส่วนบุคคล
            </div>
            <div className="support-service-modal-text-detail2">
              บริษัทฯจะทำการพิจารณาทบทวนนโยบายคุ้มครองข้อมูลส่วนบุคคลเป็นประจำเพื่อให้สอดคล้องกับแนวปฏิบัติ และ กฎหมาย
              ข้อบังคับที่เกี่ยวข้อง ทั้งนี้ บริษัทฯขอสงวนสิทธิในการเปลี่ยนแปลงนโยบายดังกล่าวในเว็บไซต์โดยไม่ต้องแจ้ง ให้ท่านทราบล่วงหน้า
            </div>
          </div>

          <div className="support-service-modal-div-button">
            <Button01
              className="support-service-modal-send-button"
              key="submit"
              type="primary"
              btnsize="wd_df"
              onClick={handleAceeptModal}
              disabled={statusScroll ? false : true}
            >
              ยินยอม
            </Button01>
          </div>
        </div>
      </Modal>
    </div>
  );
};

const SupportService = Form.create({
  name: 'modal_form',
})(FormSupportService);

export default SupportService;
