import React from 'react';
import {
  Form,
  Input,
  Row,
  Col,
} from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import './css/index.css';

const LabeRequire = (props) => {
  const { text, req } = props;
  return (
    <span>
      {text}&nbsp;
      {req ? <span style={{ fontSize: '13px', color: '#ff0000' }}>*</span> : ''}
    </span>
  );
};

const CompanyForm = ({ form, companyInfoData }) => {
  const { getFieldDecorator } = form;
  const intl = useIntl();

  return (
    <div>
      <Form className="edit-company-form">
        <Row gutter={[16, 8]}>
          <Col span={12}>
            <Form.Item
              className='edit-company-information-form'
              label={<LabeRequire text={<FormattedMessage id="companyInformationModalTextCompanyCode" defaultMessage="Company Code" />} req={true} />}
            >
              {getFieldDecorator(`companyCode`, {
                initialValue: companyInfoData && companyInfoData.comCode ? companyInfoData.comCode : undefined,
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'companyInformationModalValidateCompanyCode', defaultMessage: 'Please enter Company Code.' })
                  },
                ],
              })(
                <Input
                  className="edit-company-modal-form-input"
                  placeholder={intl.formatMessage({ id: 'companyInformationModalHinCompanyCode', defaultMessage: 'Enter Company Code' })}
                  disabled="false"
                  autoComplete="off" 
                />,
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              className='edit-company-information-form'
              label={<LabeRequire text={<FormattedMessage id="companyInformationModalTextCompanyName" defaultMessage="Company Name" />} req={true} />}
            >
              {getFieldDecorator(`companyName`, {
                initialValue: companyInfoData && companyInfoData.name ? companyInfoData.name : undefined,
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'companyInformationModalValidateCompanyName', defaultMessage: 'Please enter Company Name.' })
                  },
                ],
              })(
                <Input
                  className="edit-company-modal-form-input"
                  placeholder={intl.formatMessage({ id: 'companyInformationModalHinCompanyName', defaultMessage: 'Enter Company Name' })}
                  maxLength={100}
                  autoComplete="off" 
                />,
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col span={12}>
            <Form.Item
              className='edit-company-information-form'
              label={<LabeRequire text={<FormattedMessage id="companyInformationModalTextTaxNo" defaultMessage="Tax No." />} req={true} />}
            >
              {getFieldDecorator(`taxNo`, {
                initialValue: companyInfoData && companyInfoData.taxNo ? companyInfoData.taxNo : undefined,
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'companyInformationModalValidateTaxNo', defaultMessage: 'Please enter Tax No.' }),
                  },
                ],
              })(
                <Input
                  className="edit-company-modal-form-input"
                  placeholder={intl.formatMessage({ id: 'companyInformationModalHinTaxNo', defaultMessage: 'Enter Company Tax No' })}
                  maxLength={20}
                  autoComplete="off" 
                />,
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              className='edit-company-information-form'
              label={<LabeRequire text={<FormattedMessage id="companyInformationModalTextPhone" defaultMessage="Phone" />} req={true} />}
            >
              {getFieldDecorator(`phone`, {
                initialValue: companyInfoData && companyInfoData.phone ? companyInfoData.phone : undefined,
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'companyInformationModalValidatePhone', defaultMessage: 'Please enter Phone.' })
                  },
                ],
              })(
                <Input
                  className="edit-company-modal-form-input"
                  placeholder={intl.formatMessage({ id: 'companyInformationModalHinPhone', defaultMessage: 'Enter Company Phone' })}
                  maxLength={25}
                  autoComplete="off" 
                />,
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default CompanyForm;
