import React from 'react';
import '../css/index.css';
import { Row, Col } from 'antd';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';

export default (props) => {
  const { Data } = props;
  return (
    <div className="detail-information-div-padding">
      <Row gutter={[16, 16]}>
        <Col span={8}>
          <div className="detail-information-text-style">
            <FormattedMessage id="planningTextAssignee" defaultMessage="Assignee" />
          </div>
          <div className="detail-information-value-style">{_.get(Data, 'borrower.fullname') || '-'}</div>
        </Col>
        <Col span={8}>
          <div className="detail-information-text-style">
            <FormattedMessage id="planningTextPhone" defaultMessage="Phone" />
          </div>
          <div className="detail-information-value-style">{_.get(Data, 'borrower.phone') || '-'}</div>
        </Col>
        <Col span={8}>
          <div className="detail-information-text-style">
            <FormattedMessage id="planningTextTeam" defaultMessage="Team" />
          </div>
          <div className="detail-information-value-style" title={_.get(Data, 'borrower.team') || '-'}>
            {_.truncate(_.get(Data, 'borrower.team')) || '-'}
          </div>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={8}>
          <div className="detail-information-text-style">
            <FormattedMessage id="planningTextDimension" defaultMessage="Dimension (m.)" />
          </div>
          <div className="detail-information-value-style">{_.get(Data, 'dimention') || '-'}</div>
        </Col>
        <Col span={8}>
          <div className="detail-information-text-style">
            <FormattedMessage id="planningTextLoadedCapacity" defaultMessage="Loaded Capacity" />
          </div>
          <div className="detail-information-value-style">{_.get(Data, 'loadedView') || '-'}</div>
        </Col>
        <Col span={8}>
          <div className="detail-information-text-style">
            <FormattedMessage id="planningTextWeightCapacity(kg)" defaultMessage="Weight Capacity (kg)" />
          </div>
          <div className="detail-information-value-style">{_.get(Data, 'weightView') || '-'}</div>
        </Col>
      </Row>
      {/* <Row gutter={[16, 16]}>
        <Col span={8}>
          <div className="detail-information-text-style">
            <FormattedMessage id="planningTextTripNo" defaultMessage="Trip No." />
          </div>
          <div className="detail-information-value-style">{_.get(Data, 'tripNo') || '-'}</div>
        </Col>
        <Col span={8}>
          <div className="detail-information-text-style">
            <FormattedMessage id="planningTextStartDateandTime" defaultMessage="Start Date and Time" />
          </div>
          <div className="detail-information-value-style">{_.get(Data, 'planStartDateView') || '-'}</div>
        </Col>
        <Col span={8}>
          <div className="detail-information-text-style">
            <FormattedMessage id="planningTextDueDateandTime" defaultMessage="Due Date and Time" />
          </div>
          <div className="detail-information-value-style">{_.get(Data, 'planDueDateView') || '-'}</div>
        </Col>
      </Row> */}
    </div>
  );
};
