import React, { useEffect } from 'react';
import { Card, Row, Col, Tree, Icon, Input } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import Button01 from '../../../components/v2/button_01';
import './css/index.css';
import _ from 'lodash';
import { PageSettings } from '../../../config/page-settings';

const { TreeNode, DirectoryTree } = Tree;
export default (props) => {
  const intl = useIntl();
  const {
    menuData,
    setSelectSkill,
    setStatusAdd,
    statusAdd,
    setStatusEdit,
    handleSelectSkill,
    defaultKeys,
    setSkillList,
    setMenuData,
    stateFilter,
    sortMenu,
    setSortMenu
  } = props;

  useEffect(() => {
    setSortMenu([...menuData].sort((a, b) => (a.name > b.name ? 1 : -1)))
  }, [menuData]);

  const handleAddSkill = () => {
    setSelectSkill(true);
    setStatusAdd(true);
    setStatusEdit(false);
    setSkillList([]);
  };

  const onSearchChange = (e) => {
    const inputSrch = e.target.value.toLowerCase();

    const dataSearch = stateFilter.filter((e) => e.name.toLowerCase().includes(inputSrch.toLowerCase()));
    setMenuData(dataSearch);
  };

  return (
    <PageSettings.Consumer>
      {({ checkPermissionAction }) => (
        <div className="skill-group">
          <Card
            className="skill-group-card"
            title={intl.formatMessage({ id: 'skillMenuHeader', defaultMessage: 'Skill Group' })}
            extra={
              checkPermissionAction('P15PG1C1', 'P15PG1C1A2') ? (
                <div style={{ marginRight: '14px' }}>
                  <Button01 fontsize="sm" type="primary" btnsize="wd_at" onClick={handleAddSkill} disabled={statusAdd}>
                    <FormattedMessage id="UserManagementRoleMenuBtnAddNew" defaultMessage="Add New" />
                  </Button01>
                </div>
              ) : null
            }
          >
            <div className="skill-group-container">
              <div style={{ margin: '24px 24px 10px 24px' }}>
                <Input
                  prefix={<Icon type="search" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  suffix={null}
                  placeholder={intl.formatMessage({ id: 'hintSearch', defaultMessage: 'Search' })}
                  onChange={onSearchChange}
                  autoComplete="off"
                />
              </div>

              <div className="skill-group-menu">
                <DirectoryTree
                  showIcon={false}
                  onSelect={handleSelectSkill}
                  selectedKeys={[defaultKeys]}
                  // selectedKeys={[app.state.rolesSelect]}
                >
                  {sortMenu && sortMenu.map((item) => <TreeNode className="styleTreeNode" key={item.skillGroupId} title={item.name} />)}
                </DirectoryTree>
              </div>
            </div>
          </Card>
        </div>
      )}
    </PageSettings.Consumer>
  );
};
