import { Col, DatePicker, Form, Input, Row, Select, Tag, Button } from 'antd';
import React, { useContext, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import LabeRequireForm from '../../../components/label-required-form';
import CustomTableComponent from '../components/table';
import CollapseCustom from '../../../components/collapse-custom';
import { messageLabel } from '../../../components/message-component';
import _ from 'lodash';
import { numberFormatDecimal, numberFormatNoDecimal } from '../../../component-function/fnc-number-format-decimal';
import { AccountReceiveContext } from '../context';
import QuotationModal from '../modal/quotation/tranfer-modal';
import moment from 'moment';
import SummaryQuotation from '../components/summary-approved';
import Button_01 from '../../../components/v2/button_01';
import Button_02 from '../../../components/v2/button_02';

const { Option } = Select;
const { RangePicker } = DatePicker;
const dateFormat = 'ddd, MMM DD YYYY';

const CanceledTF = ({ handleOpenModal }) => {
  const { state, setState, func } = useContext(AccountReceiveContext);
  const { isOpenQuotation, reportDataCanceled, wareHouseFromList,wareHouseToList } = state;
  const { seIsOpenQuotation, setReportDataCanceled } = setState;
  const { handleOpenModalTransfer, handleWarhouseFromPopupScroll, handleWarhouseToPopupScroll, handleSearchFromWarehouse, handleSearchToWarehouse, onReset } = func;
  const { tranferList, tranferTotal, paginationPage, loading, rangePickerDate } = reportDataCanceled;
  const intl = useIntl();

  const handleFieldSort = (value) => {
    setReportDataCanceled((prev) => ({ ...prev, fieldSort: value }));
  };

  const handleOrderSort = (value) => {
    setReportDataCanceled((prev) => ({ ...prev, orderSort: value }));
  };

  const handlePaginationPage = (value) => {
    setReportDataCanceled((prev) => ({ ...prev, paginationPage: value }));
  };

  const handlePaginationSize = (value) => {
    setReportDataCanceled((prev) => ({ ...prev, paginationSize: value }));
  };

  const handleSelectRangePicker = (value) => {
    setReportDataCanceled((prev) => ({ ...prev, rangePickerDate: value }));
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      fixed: 'left',
      width: 70,
    },
    // {
    //   title: messageLabel('quotationLabelQuotationNo', 'Quotation No'),
    //   dataIndex: 'quotationNo',
    //   key: 'quotationNo',
    //   sorter: true,
    //   fixed: 'left',
    //   width: 150,
    // },
    {
      title: messageLabel('TFTranferNo', 'Tranfer No.'),
      dataIndex: 'transferNo',
      key: 'transferNo',
      sorter: true,
      fixed: 'left',
      width: 150,
      ellipsis: true,
    },
    {
      title: messageLabel('TFFromWareHouse', 'From WareHouse'),
      dataIndex: 'warehouseFrom',
      key: 'warehouseFrom',
      sorter: true,
      render: (text, record, index) => (_.get(record,'fromWarehouseNo') + ' · ' + _.get(record,'warehouseFrom')),
    },
    {
      title: messageLabel('TFToWareHouse', 'To WareHouse'),
      dataIndex: 'warehouseTo',
      key: 'warehouseTo',
      sorter: true,
      render: (text, record, index) => (_.get(record,'toWarehouseNo') + ' · ' + _.get(record,'warehouseTo')),
    },
    {
      title: messageLabel('TFTranferDate', 'Tranfer Date'),
      dataIndex: 'transferDateTxt',
      key: 'transferDateTxt',
      sorter: true,
      width: 150,
    },
    {
      title: messageLabel('TFItem', 'Item'),
      dataIndex: 'itemAmount',
      key: 'itemAmount',
      sorter: true,
      width: 200,
    },
    {
      title: messageLabel('TFAssignTo', 'Assign To'),
      dataIndex: 'assignTo',
      key: 'assignTo',
      sorter: true,
      // render: (text, record, index) => '',
    },
    {
      title: messageLabel('TFRemark', 'Remark'),
      dataIndex: 'remark',
      key: 'remark',
      sorter: true,
      render: (text, record, index) => (_.get(record, 'remark') ? _.get(record, 'remark') : '-'),
      // render: (text, record, index) =>
      //   _.get(record, 'issueDate') ? moment(_.get(record, 'issueDate'), 'YYYY-MM-DD').format('ddd, MMM DD YYYY') : null,
    },
    {
      title: messageLabel('TFSubmittedBy ', 'Submitted by '),
      dataIndex: 'submitBy',
      key: 'submitBy',
      sorter: true,
      // render: (text, record, index) =>
      //   _.get(record, 'issueDate') ? moment(_.get(record, 'issueDate'), 'YYYY-MM-DD').format('ddd, MMM DD YYYY') : null,
    },
    {
      title: messageLabel('TFSubmittedDate', 'Submitted Date'),
      dataIndex: 'submitDateTxt',
      key: 'submitDateTxt',
      sorter: true,
      // render: (text, record, index) =>
      //   _.get(record, 'issueDate') ? moment(_.get(record, 'issueDate'), 'YYYY-MM-DD').format('ddd, MMM DD YYYY') : null,
    },
  ];

  const advancedFilter = (action, value) => {
    const handleChange = (value, key) => {
      action((prev) => ({ ...prev, [key]: value }));

      if(key === 'fromWarehouseNo' && !value) {
        handleSearchFromWarehouse('')
      }

      if(key === 'toWarehouseNo' && !value) {
        handleSearchToWarehouse('')
      }
    };

    const onSearch = () => {
      action((prev) => ({ ...prev, event: !prev.event}))
    }

    return (
      <Form colon={false}>
        <Row gutter={[24, 2]}>
          <Col span={8}>
            <Form.Item
              label={<LabeRequireForm text={intl.formatMessage({ id: 'TransferNo', defaultMessage: 'Transfer No.' })} req={false} />}
            >
              <Input
                allowClear
                value={_.get(value, 'transferNo')}
                onChange={(e) => handleChange(e.target.value, 'transferNo')}
                placeholder={intl.formatMessage({ id: 'TFTranferNoPlaceHolder', defaultMessage: 'Enter Transfer No.' })}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<LabeRequireForm text={intl.formatMessage({ id: 'TFFromWareHouse', defaultMessage: 'From WareHouse' })} req={false} />}
            >
              <Select
                allowClear
                showSearch
                value={_.get(value, 'fromWarehouseNo') === '' ? undefined : _.get(value, 'fromWarehouseNo')}
                filterOption={(input, option) =>
                  option.props.children
                    .toString()
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                placeholder={intl.formatMessage({ id: 'TFFromWareHousePlaceHolder', defaultMessage: 'Select From WareHouse' })}
                onPopupScroll={handleWarhouseFromPopupScroll}
                onChange={(e) => handleChange(e, 'fromWarehouseNo')}
                onSearch={handleSearchFromWarehouse}
              >
                {_.map(wareHouseFromList, (item, index) => (
                  <Option key={item.warehouseId} value={item.warehouseCode}>
                    {item.warehouseCode} {item.warehouseName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label={<LabeRequireForm text={intl.formatMessage({ id: 'TFToWareHouse', defaultMessage: 'To WareHouse' })} req={false} />}
            >
              <Select
                allowClear
                showSearch
                value={_.get(value, 'toWarehouseNo') === '' ? undefined : _.get(value, 'toWarehouseNo')}
                filterOption={(input, option) =>
                  option.props.children
                    .toString()
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                placeholder={intl.formatMessage({ id: 'TFToWareHousePlaceHolder', defaultMessage: 'Select To WareHouse' })}
                onPopupScroll={handleWarhouseToPopupScroll}
                onChange={(e) => handleChange(e, 'toWarehouseNo')}
                onSearch={handleSearchToWarehouse}
              >
                {_.map(wareHouseToList, (item, index) => (
                  <Option key={item.warehouseId} value={item.warehouseCode}>
                    {item.warehouseCode} {item.warehouseName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 2]}>
          <Col span={8}>
            <Form.Item label={<LabeRequireForm text={intl.formatMessage({ id: 'TFAssignTo', defaultMessage: 'Assign To' })} req={false} />}>
              <Input
                allowClear
                value={_.get(value, 'assignTo')}
                onChange={(e) => handleChange(e.target.value, 'assignTo')}
                placeholder={intl.formatMessage({ id: 'TFAssignToPlaceHolder', defaultMessage: 'Enter Assign To' })}
              />
            </Form.Item>
          </Col>

          
          <Col span={8}></Col>

          <Col span={8} style={{ textAlign: 'right' }}>
            <Form.Item
                label={<LabeRequireForm text={''} req={false} />}
              >
                <Button_01 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={onReset}>
                  <FormattedMessage id="btnResetFilter" defaultMessage="Reset" />
                </Button_01>
                <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="print" type="primary" btnsize="wd_df" onClick={onSearch}>
                  <FormattedMessage id="btnApplyFilter" defaultMessage="Apply" />
                </Button_02>
            </Form.Item>
          </Col>

          {/* <Col span={8}>
            <Form.Item
              label={<LabeRequireForm text={intl.formatMessage({ id: 'TFExpiredDate', defaultMessage: 'Expired Date' })} req={false} />}
            >
              <RangePicker
                placeholder={[
                  intl.formatMessage({ id: 'SelectFromDatemyrequest', defaultMessage: 'Select From Date' }),
                  intl.formatMessage({ id: 'SelectToDatemyrequest', defaultMessage: 'Select To Date' }),
                ]}
                value={rangePickerDate}
                format={dateFormat}
                onChange={handleSelectRangePicker}
                allowClear={true}
              />
            </Form.Item>
          </Col> */}
        </Row>
      </Form>
    );
  };

  const contentAction = (record) => {
    // console.log('content', record);
    return (
      <div style={{ display: 'grid' }}>
        <div>
          <Button
            style={{ width: 100 }}
            type="link"
            ghost
            onClick={() => {
              handleOpenModalTransfer(record, 'cancel','view');
            }}
          >
            <FormattedMessage id="btnView" defaultMessage="View" />
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div className="warehouse-layout-content warehouse-tabs-details scroll-sm">
      <div style={{ paddingTop: 20 }}>
        <CollapseCustom
          label={intl.formatMessage({ id: `btnFilter`, defaultMessage: 'Filter' })}
          // extra={}
        >
          {advancedFilter(setReportDataCanceled, reportDataCanceled)}
        </CollapseCustom>
      </div>
      <div className="content-body-report">
        <CustomTableComponent
          rowKey={(record) => record.transferId}
          columns={columns}
          dataSource={tranferList}
          scroll={{ x: true }}
          role={true}
          onRow={true}
          total={tranferTotal}
          loading={loading}
          paginationPage={paginationPage}
          setFieldSort={handleFieldSort}
          setOrderSort={handleOrderSort}
          setPaginationSize={handlePaginationSize}
          setPaginationPage={handlePaginationPage}
          contentAction={contentAction}
        />
      </div>
    </div>
  );
};

export default CanceledTF;

