import React, { useState, useEffect } from 'react';
import { Card, Avatar, Row, Col, List, Divider, Icon, Spin } from 'antd';
import httpClient from '../../../../components/axiosClient';
import StylesCss from '../resource/css/resource.css';
import moment from 'moment';
import _ from 'lodash';
import { Link } from 'react-router-dom';


moment.locale('en');

export default React.memo((props) => {

  const comId = localStorage.getItem('comId');
  const { stateMain, setStateMain, resourceId, taskStatusData } = props;

  let mainDate = _.get(stateMain, 'mainDate');
  const fullname = props.resource.fullname;
  const pathImage = props.resource.pathImage;
  const phone = props.resource.phone;
  const statusOnGoingColor = props.resource.statusOnGoingColor;

  const arrayChunk = _.chunk(taskStatusData, 3);

  const sortTaskStatusData = [
    ..._.filter(arrayChunk[0], el => el.code === 'Todo')
    , ..._.filter(arrayChunk[0], el => el.code === 'Doing')
    , ..._.filter(arrayChunk[0], el => el.code === 'Done')
  ];


  const HeaderResource = () => {
    return (
      <div>
        <Row>
          <Col span={6} className='img-col-modal' >
            <Avatar src={pathImage} shape='circle' size='large' style={{ border: `3px solid ${statusOnGoingColor}` }} />
          </Col>
          <Col span={18} className='text-col-modal'>
            <p className='text-col-modal-head'>{fullname}</p>
            <p className='text-col-modal-h3'>{phone}</p>
          </Col>
        </Row>
      </div>
    )
  }

  const onhandleClick = (item) => {
    setStateMain.setMainResourceMemComId(resourceId);
    setStateMain.setMainStatusTaskTitle(item.title);
    setStateMain.setMainStatusTaskObject(item.tasks);
    setStateMain.setVisibleTaskStatusModal(true);
  }

  const handleSpeedTrackCilck = () => {
    let arrTask = [];
    _.map(arrayChunk[0], el => { arrTask.push(...el.tasks) });

    const colorData = _.filter(stateMain.mainMapViewResourceData, (el) => { return el.memComId === resourceId }).map((e) => { return e.statusOnGoingColor })
    const colorStatus = colorData && colorData.toString();

    setStateMain.setMainResSpeedTrack({
      resId: resourceId,
      openSpeedTrack: true,
      dataSpeedTrack: { ...arrayChunk[1][0] },
      dataTask: arrTask,
      statusColor: colorStatus
    });


  }

  return (
    <div>
      <Card
        title={<HeaderResource />}
        className='popover-size'
        bodyStyle={{ paddingTop: '0px', paddingBottom: '0px' }}
      >
        <Spin spinning={taskStatusData.length > 0 ? false : true} >
          <List
            size="default"
            dataSource={sortTaskStatusData}
            renderItem={(item) => (
              <List.Item className="resource-list" onClick={() => onhandleClick(item)}>
                <Col span={16}>
                  <span >
                    {item.title}
                  </span>
                </Col>
                <Col span={8} className='col-count-tasks'>
                  <Col> <span>{item.unit}</span></Col>
                </Col>
              </List.Item>
            )}
          />
          <Divider className='resource-popover-divider' />
          <List
            size="default"
            dataSource={arrayChunk[1]}
            renderItem={(item) => (
              <List.Item className="resource-list" style={{ paddingBottom: '10px' }} onClick={() => handleSpeedTrackCilck()}>
                <Col span={16}>
                  <span >
                    {item.title}
                  </span>
                </Col>
                <Col span={8} className='col-count-tasks' >
                  <Icon type="right" />
                </Col>
              </List.Item>
            )}
          />
        </Spin>
      </Card>
    </div >
  )
});