import httpClient from '../../components/axiosClient';

const getTeamSelf = async () => {

  // const comId = localStorage.getItem('comId');
  const memComId = localStorage.getItem('memComId');

  try {
    const response = await httpClient.get(`/v1/resource/manager/member/${memComId}/overtime-request/create-details`);


    if (response.status == 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
}

export default getTeamSelf;