import httpClient from '../../components/axiosClient';

const getVoucherList = async (payload) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v1/pointcommission/manager/company/${comCode}/web/voucherpage`, payload);
    if (response.status === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};

const getBrandVoucher = async (payload) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v5/item/manager/company/${comCode}/web/itembrand/loadmore`, payload);
    if (response.status === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};

const getModelVoucher = async (payload) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v5/item/manager/company/${comCode}/web/itemmodel/loadmore`, payload);
    if (response.status === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};

const saveVoucher = async (payload) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v1/pointcommission/manager/company/${comCode}/web/voucher`, payload);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return error.response;
  }
};

const getVoucherDetail = async (id) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.get(`/v1/pointcommission/manager/company/${comCode}/web/voucher/${id}`);
    if (response.status === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};

const cancelVoucherDetail = async (id, payload) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.put(`/v1/pointcommission/manager/company/${comCode}/web/cancelvoucher/${id}`, payload);
    if (response.status === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};

export { getVoucherList, getBrandVoucher, getModelVoucher, saveVoucher, getVoucherDetail,cancelVoucherDetail };
