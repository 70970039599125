import httpClient from '../../components/axiosClient';

const getbyId = async (skillId, type, name) => {
  const comCode = localStorage.getItem('comCode');

  try {
    const response = await httpClient.get(
      `/v3/resource/manager/company/${comCode}/skill-group/detail/${skillId}?orderType=${type}&orderBy=${name}`
    );
    if (response.status === 200) {
      return response.data.data;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};

export default getbyId;
