import React from 'react';
import { Row, Col, Form, Tag, Icon, Divider } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import _ from 'lodash';
import '../css/index.css';
import MapView from './information-map';

export default (props) => {
  const { form, data, cutomeFieldData, markerPointView, polylineDataView, addressData } = props;
  const intl = useIntl();
  // const { getFieldDecorator, validateFields, resetFields, getFieldsValue, getFieldValue, setFieldsValue } = form;

  //------------------- Lodash Data --------------------------
  const customerName = _.get(data, 'customer');
  const taskTypeName = _.get(data, 'taskTypeName');
  const statusCode = _.get(data, 'statusCode');
  const statusColor = _.get(data, 'statusColor');
  let contact = _.get(data, 'contact') || [];
  contact = contact[contact.length - 1];
  const priorityName = _.get(data, 'priorityName');
  const planStart = _.get(data, 'planStart');
  const planFinish = _.get(data, 'planFinish');
  const durationFinish = _.get(data, 'durationFinish');
  const durationStart = _.get(data, 'durationStart');
  const remark = _.get(data, 'remark') || '-';

  const DateTime = () => {
    return (
      <span className="date-time-invalid-div">
        {planStart || durationStart ? (
          <p>
            {' '}
            {planStart ? planStart : ''} {durationStart ? durationStart : ''}
          </p>
        ) : (
          'Select Start date'
        )}
        &nbsp; - &nbsp;
        {planFinish || durationFinish ? (
          <p>
            {' '}
            {planFinish ? planFinish : ''} {durationFinish ? durationFinish : ''}
          </p>
        ) : (
          'Select Due date'
        )}
        &nbsp;
      </span>
    );
  };

  const CustomToField = (props) => {
    const { taskDetail, taskCustomField } = props;
    const customFieldData = _.get(taskCustomField);

    return _.size(customFieldData)
      ? customFieldData.map((o) => (
          <Col span={8} className="information-padding-label">
            <p className="font-p">
              <FormattedMessage id={o.titleCode} defaultMessage={o.defaultTitle} />
            </p>
            <span>{_.get(taskDetail, o.entityToField) || '-'}</span>
          </Col>
        ))
      : null;
  };

  return (
    <div style={{ padding: '24px 24px 0px 24px', overflowX: 'unset', overflowY: 'auto' }}>
      <Form>
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <p className="font-p">
              <FormattedMessage id="monitorEditTaskTxtCustomer" defaultMessage="Customer" />
            </p>
            <Form.Item style={{ paddingBottom: '1px' }}>
              <span className="input-format">{customerName}</span>
            </Form.Item>
          </Col>

          <Col span={8}>
            <p className="font-p">
              <FormattedMessage id="monitorEditTaskTxtTaskType" defaultMessage="Task Type" />
            </p>
            <Form.Item style={{ paddingBottom: '1px' }}>
              <span className="input-format">{taskTypeName}</span>
            </Form.Item>
          </Col>

          <Col span={8}>
            <p className="font-p">
              <FormattedMessage id="monitorEditTaskTxtStatus" defaultMessage="Status" />
            </p>
            <Form.Item style={{ paddingBottom: '1px' }}>
              <Tag color={statusColor} className="modal-task-detail-tag">
                {statusCode}
              </Tag>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={8}>
            <p className="font-p">
              <FormattedMessage id="monitorEditTaskTxtContact" defaultMessage="Contact" />
            </p>
            <Form.Item style={{ paddingBottom: '1px' }}>
              <span className="input-format">{_.size(contact) !== 0 ? contact.contact_name : '-'}</span>
            </Form.Item>
          </Col>

          <Col span={8}>
            <p className="font-p">
              <FormattedMessage id="monitorEditTaskTxtPhone" defaultMessage="Phone" />
            </p>
            <Form.Item style={{ paddingBottom: '1px' }}>
              <span className="input-format">{_.size(contact) ? contact.contact_phone : ' - '}</span>
            </Form.Item>
          </Col>

          <Col span={8}>
            <p className="font-p">
              <FormattedMessage id="monitorEditTaskTxtPriority" defaultMessage="Priority" />
            </p>
            <Form.Item style={{ paddingBottom: '1px' }}>
              <span className="input-format">{priorityName}</span>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={12}>
            <p className="font-p">
              <FormattedMessage id="monitorEditTaskTxtDateAndTime" defaultMessage="Date & Time" />
            </p>
            <Form.Item style={{ paddingBottom: '1px' }}>
              <Row className="padding-row-date">
                <p className="information-label-p-click">
                  {planStart === 'Invalid date' && planStart === 'Invalid date' ? (
                    <span className="date-time-invalid">-</span>
                  ) : (
                    <DateTime />
                  )}
                  <Icon type="calendar" style={{ marginLeft: '3px', marginTop: '11px', fill: '#D3D3D3' }} />
                </p>
              </Row>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={24}>
            <p className="font-p">
              <FormattedMessage id="monitorEditTaskTxtRemark" defaultMessage="Remark" />
            </p>
            <Form.Item style={{ paddingBottom: '1px' }}>
              <span className="input-format">{remark}</span>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <CustomToField taskDetail={data} taskCustomField={cutomeFieldData} />
        </Row>

        <Divider type="horizontal" style={{ marginTop: 9 }}></Divider>

        <Row className="content-address-row">
          <Col span={12} className="address-padding">
            <MapView markerPointView={markerPointView} polylineDataView={polylineDataView} />
          </Col>

          <Col span={12} className="address-padding-form">
            <Row>
              <div className="address-form">
                <p className="font-address">
                  <FormattedMessage id="monitorEditTaskTxtAddress" defaultMessage="Address" />
                </p>
                <p className="format-from-to">
                  <FormattedMessage id="monitorEditTaskTxtFromLocation" defaultMessage="From Location" />
                </p>

                <div>
                  {_.get(addressData, 'fromLocationName') ? (
                    <span className="span-format" style={{ color: '#1D3557', fontSize: '14px' }}>
                      {_.get(addressData, 'fromLocationName')}
                    </span>
                  ) : (
                    <span className="span-format">
                      <FormattedMessage id="monitorEditTaskPlacholderNoAddressName" defaultMessage="No Address Name" />
                    </span>
                  )}
                </div>

                <div style={{ margin: '5px 0px' }}>
                  {_.get(addressData, 'fromLocationDescription') ? (
                    <span
                      placeholder={intl.formatMessage({ id: 'ModalLocationLocationAddress', defaultMessage: 'Address' })}
                      className="span-format"
                      style={{ color: '#1D3557', fontSize: '14px' }}
                    >
                      <Icon type="environment" style={{ fontSize: '12px', color: '#0c4da2' }} /> &nbsp;
                      {_.get(addressData, 'fromLocationDescription')}
                    </span>
                  ) : (
                    <span className="span-format">
                      <Icon type="environment" style={{ fontSize: '12px', color: '#0c4da2' }} /> &nbsp;
                      <FormattedMessage id="monitorEditTaskPlacholderNoAddress" defaultMessage="No Address" />
                    </span>
                  )}
                </div>
              </div>

              <div className="address-to">
                <p className="format-from-to" style={{ marginTop: '10px' }}>
                  <FormattedMessage id="monitorEditTaskTxtToLocation" defaultMessage="To Location" />
                </p>
                <div>
                  {_.get(addressData, 'toLocationName') ? (
                    <span className="span-format" style={{ color: '#1D3557', fontSize: '14px' }}>
                      {_.get(addressData, 'toLocationName')}
                    </span>
                  ) : (
                    <div>
                      <span className="span-format">
                        <FormattedMessage id="monitorEditTaskPlacholderNoAddressName" defaultMessage="No Address Name" />
                      </span>
                    </div>
                  )}
                </div>

                <div style={{ margin: '5px 0px' }}>
                  {_.get(addressData, 'toLocationAddress') ? (
                    <span
                      placeholder={intl.formatMessage({ id: 'ModalLocationLocationAddress', defaultMessage: 'Address' })}
                      className="span-format"
                      style={{ color: '#1D3557', fontSize: '14px' }}
                    >
                      <Icon type="environment" style={{ fontSize: '12px', color: '#1D3557' }} /> &nbsp;
                      {_.get(addressData, 'toLocationAddress')}
                    </span>
                  ) : (
                    <div>
                      <Icon type="environment" style={{ fontSize: '12px', color: '#1D3557' }} />
                      &nbsp;{' '}
                      <span className="span-format">
                        <FormattedMessage id="monitorEditTaskPlacholderNoAddress" defaultMessage="No Address" />
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </Row>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
