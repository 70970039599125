import React, { useState, useEffect } from 'react';
import { Modal, Upload, Icon, Table, Spin } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import Button02 from '../../../../components/v2/button_02';
import Button01 from '../../../../components/v2/button_01';
import LabeRequireForm from '../../../../components/label-required-form';
import '../../index.css';
import { importPointExcel, searchExcelPoint, confirmImportPoint } from '../../../../controllers/point/point';
import { successNotification, errorNotification } from '../../../../components/v2/notification';
import _ from 'lodash';

const { Dragger } = Upload;

const ImportTransaction = (props) => {
  const { visible, setVisible, setTrigger, template } = props;
  const intl = useIntl();
  const [file, setFile] = useState();
  const [fileHash, setFileHash] = useState();
  const [paginationPage, setPaginationPage] = useState(1);
  const [paginationSize, setPaginationSize] = useState(10);
  const [isValid, setIsValid] = useState(false);
  const [spinning, setSpinning] = useState(false);

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
    },
    {
      title: intl.formatMessage({ id: 'menuImportPointColumnsDate', defaultMessage: 'Date' }),
      dataIndex: 'date',
      key: 'date',
      width: 170,
      render: (text, record, index) =>
        _.get(record, 'highlightCol.date') === true ? (
          <span className="highlightRed">{_.get(record, 'date')}</span>
        ) : (
          <span>{_.get(record, 'date')}</span>
        ),
    },
    {
      title: intl.formatMessage({ id: 'menuImportPointColumnsRole', defaultMessage: 'Role' }),
      dataIndex: 'role',
      key: 'role',
      width: 100,
      render: (text, record, index) =>
        _.get(record, 'highlightCol.role') === true ? (
          <span className="highlightRed">{_.get(record, 'role')}</span>
        ) : (
          <span>{_.get(record, 'role')}</span>
        ),
    },
    {
      title: intl.formatMessage({ id: 'menuImportPointColumnsName', defaultMessage: 'Name' }),
      dataIndex: 'name',
      key: 'name',
      width: 200,
      render: (text, record, index) =>
        _.get(record, 'highlightCol.phone') === true ? (
          <span className="highlightRed">{_.get(record, 'name')}</span>
        ) : (
          <span>{_.get(record, 'name')}</span>
        ),
    },
    {
      title: intl.formatMessage({ id: 'menuImportPointColumnsPointType', defaultMessage: 'Point Type' }),
      dataIndex: 'pointType',
      key: 'pointType',
      width: 100,
      render: (text, record, index) =>
        _.get(record, 'highlightCol.pointType') === true ? (
          <span className="highlightRed">{_.get(record, 'pointType')}</span>
        ) : (
          <span>{_.get(record, 'pointType')}</span>
        ),
    },
    {
      title: intl.formatMessage({ id: 'menuImportPointColumnsPoint', defaultMessage: 'Point' }),
      dataIndex: 'point',
      key: 'point',
      width: 100,
      render: (text, record, index) =>
        _.get(record, 'highlightCol.point') === true ? (
          <span className="highlightRed">{_.get(record, 'point')}</span>
        ) : (
          <span>{_.get(record, 'point')}</span>
        ),
    },
    {
      title: intl.formatMessage({ id: 'menuImportPointColumnsDescription', defaultMessage: 'Description' }),
      dataIndex: 'description',
      key: 'description',
      width: 300,
      render: (text, record, index) =>
        _.get(record, 'highlightCol.description') === true ? (
          <span className="highlightRed">{_.get(record, 'description')}</span>
        ) : (
          <span>{_.get(record, 'description')}</span>
        ),
    },
  ];

  const handleSave = async () => {
    try {
      const body = { fileHash: fileHash };
      const response = await confirmImportPoint(body);
      console.log('messageError', response.data);
      if (_.get(response, 'data.status.code') === 1) {
        successNotification(_.get(response, 'data.status.message'));
        setTrigger((cur) => !cur);
        setVisible(false);
        setFile();
        setIsValid(false);
        setFileHash();
        setPaginationPage(1);
        setPaginationSize(10);
      } else {
        errorNotification(_.get(response, 'data.status.message'));
      }
    } catch (error) {
      errorNotification(_.get(error, 'response.data.status.message'));
    }

    // console.log("response", response)
  };

  const handleClose = () => {
    setVisible(false);
    setFile();
    setIsValid(false);
    setFileHash();
    setPaginationPage(1);
    setPaginationSize(10);
  };

  const handleChange = async (prop) => {
    let formData = new FormData();
    formData.append('file', prop.file);
    try {
      const uploadFile = await importPointExcel(formData);
      if (_.get(uploadFile, 'status') === 200) {
        setFileHash(_.get(uploadFile, 'data.data.fileHash'));
      } else {
        errorNotification('Cannot import file');
        // errorNotification(_.get(uploadFile, 'data.error.message'))
      }
    } catch (error) {
      errorNotification('Cannot import file');
    }
  };

  useEffect(() => {
    if (fileHash && isValid) {
      serchData();
    }
  }, [fileHash, paginationPage, paginationSize, isValid]);

  const serchData = async () => {
    const body = {
      fileHash: fileHash,
      pageNumber: paginationPage,
      limit: paginationSize,
    };
    const search = await searchExcelPoint(body);
    setFile(_.get(search, 'data.data'));
  };

  useEffect(() => {
    const intervalId = setInterval(async () => {
      if (isValid === true) {
        clearInterval(intervalId);
        setSpinning(false); // หยุดการทำงานเมื่อ apiValue เป็น true
      } else if (fileHash) {
        setSpinning(true);
        const body = {
          fileHash: fileHash,
          pageNumber: paginationPage,
          limit: paginationSize,
        };
        const search = await searchExcelPoint(body);
        if (_.get(search, 'data.data.verified')) {
          setIsValid(true);
          setFile(_.get(search, 'data.data'));
        }
      }
    }, 1000);

    return () => {
      clearInterval(intervalId); // ยกเลิก interval เมื่อ component unmount
    };
  }, [isValid, fileHash]);

  console.log('file', file);

  const handleChangeTable = (pagination, sorter, extra) => {
    setPaginationSize(pagination.pageSize);
    setPaginationPage(pagination.current);
  };

  const showTotal = () => {
    return (
      <span style={{ fontSize: '13px' }}>
        <FormattedMessage id="lblRowPerPage" defaultMessage="Rows per page: " />
      </span>
    );
  };

  const handleDownloadTemplate = () => {
    window.open(template, '_blank');
  };

  return (
    <Modal
      title={intl.formatMessage({ id: 'menuCampaignPointImportHeader', defaultMessage: 'Import Point Transections' })}
      onCancel={handleClose}
      visible={visible}
      centered={true}
      width={file ? 825 : 600}
      bodyStyle={{ padding: 'unset', maxHeight: '795px' }}
      footer={
        <div>
          <Button02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleClose} margin="unset">
            <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
          </Button02>
          {file ? (
            <Button01 key="submit" type="primary" btnsize="wd_df" onClick={handleSave}>
              <FormattedMessage id="btnSave" defaultMessage="Save" />
            </Button01>
          ) : null}
          <div style={{ float: 'left' }}>
            <span className="campaignPointModalImportDownload" onClick={handleDownloadTemplate}>
              {' '}
              <Icon type="file-text" style={{ marginRight: '5px' }} />
              <FormattedMessage id="menuCampaignPointImportDownload" defaultMessage="Download Template" />
            </span>
          </div>
        </div>
      }
    >
      {file ? (
        <div style={{ padding: '12px 24px 24px 24px' }}>
          <Table
            loading={spinning}
            columns={columns}
            dataSource={_.get(file, 'list')}
            scroll={{ x: _.sumBy(columns, 'width'), y: 'calc(100vh - 420px)' }}
            onChange={handleChangeTable}
            pagination={{
              total: _.get(file, 'totalItem'),
              current: paginationPage,
              showTotal: showTotal,
              defaultCurrent: 1,
              pageSizeOptions: ['10', '20', '30', '40', '50'],
              showSizeChanger: true,
              locale: { items_per_page: '' },
            }}
          />
          {_.get(file, 'totalItem') > 0 ? (
            <div className="total-items-member">
              <span style={{ fontSize: '13px' }}>
                <FormattedMessage id="lblTotal" defaultMessage="Total" />
                {` ${_.get(file, 'totalItem') || 0} `}
                <FormattedMessage id="lblitems" defaultMessage="items" />
              </span>
            </div>
          ) : null}
        </div>
      ) : (
        <div style={{ padding: '24px' }}>
          <div>
            <LabeRequireForm text={intl.formatMessage({ id: 'menuCampaignPointImportFileText', defaultMessage: 'File' })} req={true} />
          </div>
          <div style={{ marginTop: '8px' }}>
            <Dragger
              multiple={false}
              onChange={handleChange}
              accept=".xlsx"
              showUploadList={false}
              beforeUpload={() => {
                return false;
              }}
            >
              <p className="ant-upload-drag-icon">
                <Icon type="inbox" />
              </p>
              <p className="ant-upload-text" style={{ fontSize: '12px', color: '#1D3557' }}>
                {' '}
                <FormattedMessage id="menuCampaignPointImportDragText1" defaultMessage="Click or drag file to this area to upload" />
              </p>
              <p className="ant-upload-hint" style={{ fontSize: '10px' }}>
                <FormattedMessage
                  id="menuCampaignPointImportDragText2"
                  defaultMessage="Support for a single or bulk upload. Strictly prohibit from uploading company data or other band files"
                />
              </p>
            </Dragger>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default ImportTransaction;
