import React from 'react'
import { Select, Form } from 'antd'
import '../css/index.css'

const { Option } = Select

const FormTeam = (props) => {
  const { teamData, orgID } = props
  return (
    <div style={{ padding: '8px 24px 0px 24px' }}>
      <Form>
        <Form.Item>
          <Select
            className='modal-task-detail-team-select '
            disabled={true}
            value={orgID}
          >
            {teamData && teamData.map(item =>
              <Option key={item.orgId}>{item.orgName}</Option>
            )}
          </Select>
        </Form.Item>
      </Form>

    </div>
  )
}

const TeamForm = Form.create({
  name: "modal_form",
})(FormTeam);

export default TeamForm;
