import React, { useContext, useState, useRef } from 'react'
import { ExpenseModal } from '.'
import { Row, Col, Input, Form, Divider, Dropdown, Button, Menu, Popover, Table, Select } from 'antd'
import { useIntl, FormattedMessage } from 'react-intl'
import Button01 from '../../../../components/v2/button_01'
import _ from 'lodash'

const ExpenseAttachment = (props) => {
  const intl = useIntl()
  const { handleCreateUpload, handleEditUpload, attachmentData, setAttachmentData } = useContext(ExpenseModal)
  const ref = useRef();
  const [visiblePopoverTable, setVisiblePopoverTable] = useState();
  const [recordPopover, setRecordPopover] = useState();
  const [offsetPopover, setOffsetPopover] = useState([0, 0]);

  const columnsAtt = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
      fixed: 'left'
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsFileName', defaultMessage: 'File Name' }),
      dataIndex: 'attachmentName',
      key: 'attachmentName',
      sorter: true,
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsRemark', defaultMessage: 'Remark' }),
      dataIndex: 'remark',
      key: 'remark',
    },
  ]

  const fncActionTable = (record, offsetPopover, visiblePopoverTable, ref) => {
    return (
      <div>
        <Popover
          placement="right"
          trigger="hover"
          align={{ offset: offsetPopover }}
          visible={visiblePopoverTable}
          zIndex={10000}
          content={
            <div style={{ display: 'grid' }}>
              {/* <div>
                <Button style={{ width: 100 }} type="link" ghost onClick={() => handleEditUploadInside(record)}>
                  <FormattedMessage id="orgTabMemberBtnEdit" defaultMessage="Edit" />
                </Button>
              </div> */}
              <div>
                <Button style={{ width: 100 }} type="link" ghost onClick={() => deleteAtt(record.id)}>
                  <FormattedMessage id="btnDelete" defaultMessage="Delete" />
                </Button>
              </div>
            </div>
          }
        >
          <div style={{ width: 0, height: 0 }} ref={ref}></div>
        </Popover>
      </div>
    );
  };

  const handleEditUploadInside = (value) => {
    handleEditUpload(value)
    setVisiblePopoverTable(false);
    setRecordPopover();
    setOffsetPopover([0, 0]);
  }

  const handleMouseLeave = () => {
    setVisiblePopoverTable(false);
    setRecordPopover();
    setOffsetPopover([0, 0]);
  };


  const deleteAtt = (value) => {
    const filterSelect = _.map(attachmentData, (item) => {
      return {
        ...item,
        delete: item.id === value ? "deleted" : undefined
      }
    })
    setAttachmentData(filterSelect)
    setVisiblePopoverTable(false);
    setRecordPopover();
    setOffsetPopover([0, 0]);
  }



  return (
    <div>
      <div style={{ padding: '24px 24px 0 24px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div style={{ color: '#1D3557', fontWeight: 600 }}>
          <FormattedMessage id="expenseAttachmentText" defaultMessage="Attachment" />
        </div>
        <Button01 key="submit" type="primary" btnsize="wd_df" style={{ margin: 'unset' }} onClick={() => handleCreateUpload()}>
          <FormattedMessage id="btnUpload" defaultMessage="Upload" />
        </Button01>
      </div>
      <Divider type="horizontal" style={{ margin: '12px 0' }} />
      <div style={{ padding: '0px 24px 24px 24px' }} onMouseLeave={handleMouseLeave}>
        <Table
          columns={columnsAtt}
          dataSource={_.sortBy(_.filter(attachmentData, (item) => { return _.get(item, 'delete') !== "deleted" }), [function (o) { return o.index; }])}
          pagination={false}
          scroll={{ x: 1200 }}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                if (!event.target.href) {
                  const { x, y } = ref.current.getBoundingClientRect();
                  setVisiblePopoverTable(true);
                  setOffsetPopover([event.pageX - x, event.pageY - y]);
                  setRecordPopover(record);
                }
              },
            };
          }}
        />
        {fncActionTable(recordPopover, offsetPopover, visiblePopoverTable, ref)}
      </div>
    </div>
  )
}

export default ExpenseAttachment
