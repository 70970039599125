import React, { useContext } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Form, Row, Col, DatePicker, Select, Input } from 'antd';
import CollapseCustom from '../../../components/collapse-custom';
import LabeRequireForm from '../../../components/label-required-form';
import _ from 'lodash';
import { PettyCashDetailProvider } from './context';
import Button_01 from '../../../components/v2/button_01';
import Button_02 from '../../../components/v2/button_02';
import PettyCashDetailActionColumn from './action-columns';

const { Option } = Select;

const PettyCashDetailAction = () => {
  const { state, setState } = useContext(PettyCashDetailProvider);
  const intl = useIntl();
  const {
    searchGroup,
    tableChange,
    listArrayColumns,
    defaultShowColumn,
    newColumns,
    textErrorSelectColumn,
    visible,
    actionColumns,
    loading,
    defaultColumns,
    categoryList
  } = state;
  const {
    setSearchGroup,
    setTableChange,
    setNewDataColumns,
    setListArrayColumns,
    setDefaultShowColumn,
    setTextErrorSelectColumn,
    setVisible,
    setTriggerSearch
  } = setState;
  // const formatDate = 'DD/MM/YYYY';

  const onChange = (value, code) => {
    setSearchGroup({ ...searchGroup, [code]: value });
  };

  const onApply = () => {
    setTableChange({ ...tableChange, page: 1 });
    setTriggerSearch((cur) => !cur);
  };

  const onReset = () => {
    setSearchGroup({});
    setTableChange({ ...tableChange, page: 1 });
    setTriggerSearch((cur) => !cur);
  };

  const formFilter = (value) => {
    return (
      <Form colon={false} className="resourceAllowanceForm">
        <Row gutter={[24, 2]}>
          {/* <Col span={8}>
            <Form.Item
              label={<LabeRequireForm text={intl.formatMessage({ id: 'pettyCashFormDocNo', defaultMessage: 'Document No.' })} req={false} />}
            >
              <Input
                allowClear
                value={_.get(searchGroup, 'docNo')}
                onChange={(e) => onChange(e.target.value, 'docNo')}
                placeholder={intl.formatMessage({ id: 'pettyCashFormDocNoPH', defaultMessage: 'Enter Document No.' })}
              />
            </Form.Item>
          </Col> */}
          <Col span={8}>
            <Form.Item
              label={<LabeRequireForm text={intl.formatMessage({ id: 'pettyCashFormCategory', defaultMessage: 'Category' })} req={false} />}
            >
              <Select
                allowClear
                value={_.get(searchGroup, 'category')}
                onChange={(e) => onChange(e, 'category')}
                placeholder={intl.formatMessage({ id: 'pettyCashFormCategoryPH', defaultMessage: 'Select Category' })}
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                showSearch
              >
                {
                  _.map(categoryList, (item) => {
                    return (
                      <Option value={_.get(item, '_id')}>
                        {_.get(item, 'name')}
                      </Option>
                    )
                  })
                }
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<LabeRequireForm text={intl.formatMessage({ id: 'pettyCashFormReference', defaultMessage: 'Reference' })} req={false} />}
            >
              <Input
                allowClear
                value={_.get(searchGroup, 'reference')}
                onChange={(e) => onChange(e.target.value, 'reference')}
                placeholder={intl.formatMessage({ id: 'pettyCashFormReferencePH', defaultMessage: 'Enter Reference' })}
              />
            </Form.Item>
          </Col>
          <Col span={8} style={{ textAlign: 'right' }}>
            <Form.Item
              style={{ marginTop: '40px' }}>
              <Button_01 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={onReset}>
                <FormattedMessage id="btnResetFilter" defaultMessage="Reset" />
              </Button_01>
              <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="print" type="primary" btnsize="wd_df" onClick={onApply}>
                <FormattedMessage id="btnApplyFilter" defaultMessage="Apply" />
              </Button_02>
            </Form.Item>
          </Col>
        </Row>
        {/*--------------------------------------------------------------------------------------------------------------------*/}
        {/* <Row gutter={[24, 2]}>
          <Col span={8} />
          <Col span={8} />
          <Col span={8} style={{ textAlign: 'right' }}>
            <Form.Item style={{ marginTop: '16px' }}>
              <Button_01 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={onReset}>
                <FormattedMessage id="btnResetFilter" defaultMessage="Reset" />
              </Button_01>
              <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="print" type="primary" btnsize="wd_df" onClick={onApply}>
                <FormattedMessage id="btnApplyFilter" defaultMessage="Apply" />
              </Button_02>
            </Form.Item>
          </Col>
        </Row> */}
      </Form >
    );
  };

  return (
    <div style={{ padding: '12px 0px' }}>
      <CollapseCustom
        label={intl.formatMessage({ id: `btnFilter`, defaultMessage: 'Filter' })}
        extra={
          <div style={{ display: 'flex' }}>
            <PettyCashDetailActionColumn
              columnsNormal={defaultColumns}
              listArrayColumns={listArrayColumns}
              defaultShowColumn={defaultShowColumn}
              newColumns={newColumns}
              textErrorSelectColumn={textErrorSelectColumn}
              visible={visible}
              actionColumns={actionColumns}
              setNewDataColumns={setNewDataColumns}
              setListArrayColumns={setListArrayColumns}
              setDefaultShowColumn={setDefaultShowColumn}
              setTextErrorSelectColumn={setTextErrorSelectColumn}
              setVisible={setVisible}
              loading={loading}
            />
          </div>
        }
      >
        {formFilter(searchGroup)}
      </CollapseCustom>
    </div>
  );
};

export default PettyCashDetailAction;
