import React from 'react';
import httpClient from '../../components/axiosClient';

const deleteServiceArea= async (props) => {
  const comCode = localStorage.getItem('comCode');
  if (props.deleteAreaId) {
    try {
      const response = await httpClient.delete(`/v3/resource/manager/company/${comCode}/area-master/${props.deleteAreaId}`);
      if (response.status === 200) {
        return response.data;
      } else {
        return response.data;
      }
    } catch (error) {
      return error.response;
    }
  }
}

export default deleteServiceArea;