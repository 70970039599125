import React from 'react';
import { Tag } from 'antd';
import cssStyle from './css/checkable-tag.css';

const { CheckableTag } = Tag;

export default (props) => {
  return (
    <CheckableTag
      className={props.styleCss}
      {...props}
      checked={props.checked}
      onChange={props.onChange}
    />
  );
};
