import React from 'react';
import { Icon, Divider } from 'antd';
import styleCss from './css/map-control.css';
import TaskIcon from '../../../components/image/Task.svg';
import TaskOrange from '../../../components/image/TaskOrange.svg';

export default ((props) => {
  const BagIcon = () => (<img src={TaskIcon} style={{ width: 20 }}  ></img>)
  const BagIconOrange = () => (<img src={TaskOrange} style={{ width: 20 }}   ></img>)

  return (
    <div className='map-view-control'>
      <Icon 
        type="user" 
        onClick={(e) => props.onClickShowResource(e)} 
        className={props.resourceActive}
      />
      <Divider className='map-view-control-divider' type="vertical" />
      <Icon 
        onClick={props.onClickShowTask} 
        className={props.taskActive} 
        component={props.taskActive === '' ? BagIcon : BagIconOrange}
      />
      <Divider className='map-view-control-divider' type="vertical" />
      <Icon 
        type="gateway" 
        onClick={props.onClickShowPolygon} 
        className={props.polygonActive}
      />
    </div>
  )
})