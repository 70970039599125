import React, { useContext, useRef, useState } from 'react'
import ExpenseSummary from './summary'
import ExpenseAction from './action'
import { Table, Button, Popover, Tag } from 'antd'
import { useIntl } from 'react-intl'
import { ExpenseContext } from '..'
import { FormattedMessage } from 'react-intl'
import _ from 'lodash'

const ExpenseMain = (props) => {
  const { show, showStatus, columns } = props
  const intl = useIntl()
  const { expenseList, expenseTotal, paginationPage, handleDelete, handleChange, handleOpenView,
    tableLoading, handleOpenModalEdit } = useContext(ExpenseContext)

  const ref = useRef();
  const [visiblePopoverTable, setVisiblePopoverTable] = useState();
  const [recordPopover, setRecordPopover] = useState();
  const [offsetPopover, setOffsetPopover] = useState([0, 0]);


  const showTotal = () => {
    return (
      <span style={{ fontSize: '13px' }}>
        <FormattedMessage id="lblRowPerPage" defaultMessage="Rows per page: " />
      </span>
    );
  };

  const fncActionTable = (record, offsetPopover, visiblePopoverTable, ref) => {
    return (
      <div>
        <Popover
          placement="right"
          trigger="hover"
          align={{ offset: offsetPopover }}
          visible={visiblePopoverTable}
          zIndex={10000}
          content={
            <div style={{ display: 'grid' }}>
              <div>
                <Button style={{ width: 100 }} type="link" ghost onClick={() => handleOpenView(record)}>
                  <FormattedMessage id="btnView" defaultMessage="View" />
                </Button>
              </div>
              {
                _.get(record, 'status.code') === 'draft' ? (
                  <div>
                    <Button style={{ width: 100 }} type="link" ghost onClick={() => handleOpenModalEdit(record)}>
                      <FormattedMessage id="orgTabMemberBtnEdit" defaultMessage="Edit" />
                    </Button>
                  </div>
                ) : null
              }
              <div>
                <Button style={{ width: 100 }} type="link" ghost onClick={() => handleDelete(record.expenseId)}>
                  <FormattedMessage id="btnDelete" defaultMessage="Delete" />
                </Button>
              </div>
            </div>
          }
        >
          <div style={{ width: 0, height: 0 }} ref={ref}></div>
        </Popover>
      </div>
    );
  };

  const handleMouseLeave = () => {
    setVisiblePopoverTable(false);
    setRecordPopover();
    setOffsetPopover([0, 0]);
  };

  return (
    <div >
      <div style={{ height: 'calc(100vh - 160px)', overflowY: 'auto', width: '100%' }}>
        {
          show ? <ExpenseSummary /> : null
        }

        <ExpenseAction showStatus={showStatus} />
        <div style={{ padding: '12px 24px' }} onMouseLeave={handleMouseLeave}>
          <Table
            columns={columns}
            dataSource={expenseList}
            scroll={{ x: 2000 }}
            onChange={handleChange}
            loading={tableLoading}
            pagination={{
              total: expenseTotal,
              current: paginationPage,
              showTotal: showTotal,
              defaultCurrent: 1,
              pageSizeOptions: ['10', '20', '30', '40', '50'],
              showSizeChanger: true,
              locale: { items_per_page: '' },
            }}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  if (!event.target.href) {
                    const { x, y } = ref.current.getBoundingClientRect();
                    setVisiblePopoverTable(true);
                    setOffsetPopover([event.pageX - x, event.pageY - y]);
                    setRecordPopover(record);
                  }
                },
              };
            }}
          />
          {fncActionTable(recordPopover, offsetPopover, visiblePopoverTable, ref)}
          {expenseTotal > 0 ? (
            <div className="total-items-member">
              <span style={{ fontSize: '13px' }}>
                <FormattedMessage id="lblTotal" defaultMessage="Total" />
                {` ${expenseTotal || 0} `}
                <FormattedMessage id="lblitems" defaultMessage="items" />
              </span>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default ExpenseMain
