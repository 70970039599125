import React, { useEffect, useState } from 'react'
import { Card, Table, Switch, Modal } from 'antd'
import httpClient from '../../../components/axiosClient'
import { useIntl, FormattedMessage } from 'react-intl';
import {
  successNotification,
  errorNotification,
  warningNotification,
} from '../../../components/v2/notification';
import style from '../css/request-type.css'

export default () => {
  const { confirm } = Modal;
  const intl = useIntl();
  const comCode = localStorage.getItem('comCode');
  const [getRequestData, setGetRequestData] = useState([])
  const [triggerActive, setTriggerActive] = useState(false)
  const [total, setTotal] = useState()

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 80,
    },
    {
      title: intl.formatMessage({id: 'settingRequestTypeColRequestName' ,defaultMessage: 'Request name'}),
      dataIndex: 'name',
      key: 'name',
      width: 600
    },
    {
      title: intl.formatMessage({id: 'settingRequestTypeColDescription' ,defaultMessage: 'Description'}),
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: intl.formatMessage({id: 'settingRequestTypeColActive' ,defaultMessage: 'Active'}),
      dataIndex: 'display',
      key: 'display',
      align: 'center',
      width: 200,
      render: (text, record, index) =>
        <Switch
          checked={record.display == true ? false : true}
          onChange={() => setSwithChange(record.id, record.display)}
        />
    },

  ];


  useEffect(() => {
    getRequestType();
  }, [triggerActive])

  const getRequestType = async () => {
    try {
      const response = await httpClient.get(`/v3/resource/manager/company/${comCode}/getrequest`);
      if (response.status == 200) {
        setGetRequestData(response.data.data);
        setTotal(response.data.total);
      } else {

      }
    } catch (error) {

    }
  }

  const setSwithChange = (id, display) => {
    Modal.confirm({
      
      className: 'request-type-modal-confirm',
      title: intl.formatMessage({id: 'settingRequestTypeColConfirmChangeStatus' ,defaultMessage: 'Are you sure to change status ?'}),
      centered: true,
      icon: null,
      okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: "primary",
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: "danger",
      },
      async onOk() {
        const body = {
          display: display == true ? false : true
        }
        try {
          const response = await httpClient.put(`/v3/resource/manager/company/${comCode}/updaterequest/${id}`, body);
          if (response.status == 200) {
            setTriggerActive(!triggerActive)
            successNotification(response.data.message);
          } else {
            errorNotification(response.data.message);

          }
        } catch (error) {

        }
      },
      onCancel() {

      },
    });
  }

  
  const showTotal = (total) => {
    return <span style={{ fontSize: '13px' }}><FormattedMessage id="lblRowPerPage" defaultMessage="Rows per page:" /></span>
  };

  return (
    <Card
      className="request-type-card"
      title={intl.formatMessage({
        id: 'menuRequestType',
        defaultMessage: 'Request Type',
      })}
    >
      <Table
        className='request-table'
        columns={columns}
        dataSource={getRequestData}
        scroll={{ x: null, y: `calc(100vh - 374px)` }}
        pagination={{
          total: total,
          showTotal: showTotal,
          defaultCurrent: 1,
          pageSizeOptions: ['10', '20', '30', '40', '50'],
          showSizeChanger: true,
          locale: { items_per_page: '' },
          // onChange: fnc.handlePagination,
          // onShowSizeChange: fnc.handleSizeChange,
        }}
      />

      {getRequestData.length !== 0 ?
        <div
          className="request-type-total-items"
        >
          <span style={{ fontSize: '13px' }}>
            <FormattedMessage id="lblTotal" defaultMessage="Total" />
            {` ${total || 0} `}
            <FormattedMessage id="lblitems" defaultMessage="items" />
          </span>
        </div>
        : null
      }
    </Card>
  )
}