import { Col, DatePicker, Divider, Form, Row, Select, Input, Icon, Badge, TimePicker } from 'antd';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import LabeRequireForm from '../../../../components/label-required-form';
import ModalCustomerQuotationForm from '../select-customer/customer-modal';
import _ from 'lodash';
import ModalSelectQuotationForm from '../select-quotation/quotation-select-modal';
import moment from 'moment';

const { Option } = Select;
const { Search } = Input;
// const dateFormat = 'ddd, MMM DD YYYY';
const dateFormat = 'DD/MM/YYYY';
const timeformat = 'HH:mm';

const CustomerForm = ({
  form,
  setDataForm,
  dataForm,
  initialMemberList,
  formCustomerRef,
  isOpenQuotation,
  handleOpen,
  record,
  setRecord,
  handleWarhouseFromPopupScroll,
  wareHouseFromList,
  handleWarhouseToPopupScroll,
  wareHouseToList,
  setNotWarehouseFromId,
  setNotWarehouseToId,
  setTypeCode,
  setAssignName,
}) => {
  // console.log('CustomerForm', form);
  const { getFieldDecorator, getFieldValue, setFieldsValue } = form;
  const intl = useIntl();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenQT, setIsOpenQT] = useState(false);
  const AssignType = [
    { id: 'internal', name: intl.formatMessage({ id: 'TFAssignToInternal', defaultMessage: 'Internal' }) },
    { id: 'external', name: intl.formatMessage({ id: 'TFAssignToExternal', defaultMessage: 'External' }) },
  ];
  const [switchAssign, setSwitchAssign] = useState('internal');
  const memComId = localStorage.getItem('memComId');
  const str = _.get(record, 'transferDate');
  const timeValue = _.get(record, 'transferDate') ? str.split(/(\s+)/) : null;

  useEffect(() => {
    if (_.get(record, 'assignToTypeCode') !== undefined) {
      // console.log('assignToTypeCode', switchAssign, _.get(record, 'assignToTypeCode'));
      setSwitchAssign(_.get(record, 'assignToTypeCode'));
      setTypeCode(_.get(record, 'assignToTypeCode'));
    }
  }, [record]);

  // const handleOpen = () => {
  //   setIsOpen(true);
  // };

  // const handleSave = () => {
  //   setIsOpen(false);
  // };

  // const handleCancel = () => {
  //   setIsOpen(false);
  // };

  const handleOpenQuotationNo = () => {
    setIsOpenQT(true);
  };

  const handleSelectQuotationNo = (value) => {
    setIsOpenQT(false);
  };

  const handleCancelQuotationNo = () => {
    setSwitchAssign('internal');
    setTypeCode('internal');
    setIsOpenQT(false);
  };

  const MemberOption = (o, i) => {
    return (
      <Option key={i} value={o.memComId}>
        {o.fullname}
      </Option>
    );
  };

  const handleSwitchAssign = (value) => {
    setSwitchAssign(value);
    setTypeCode(value);
  };

  return (
    <Form name="customer" colon={false}>
      <ModalSelectQuotationForm
        visible={isOpenQT}
        onOk={handleSelectQuotationNo}
        onCancel={handleCancelQuotationNo}
        setDataForm={setDataForm}
        dataForm={dataForm}
      />

      <Row gutter={[24]}>
        <Col span={12}>
          <Form.Item
            label={
              <LabeRequireForm
                bold={true}
                text={intl.formatMessage({ id: 'TFFromWareHouse', defaultMessage: 'From WareHouse' })}
                req={true}
              />
            }
          >
            {getFieldDecorator('warehouseFromId', {
              initialValue: _.get(record, 'warehouseFromId') ? _.get(record, 'warehouseFromId') : undefined,
              rules: [
                {
                  required: true,
                  message: intl.formatMessage({ id: 'TFFromWareHouseValidate', defaultMessage: 'Please Select From WareHouse' }),
                },
              ],
            })(
              <Select
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.props.children
                    .toString()
                    .toLowerCase()
                    .indexOf(input.toString().toLowerCase()) >= 0
                }
                placeholder={intl.formatMessage({ id: 'TFFromWareHousePlaceHolder', defaultMessage: 'Select From WareHouse' })}
                onPopupScroll={handleWarhouseFromPopupScroll}
                onSelect={(e) => {
                  setNotWarehouseFromId(e);
                }}
              >
                {wareHouseFromList &&
                  _.map(wareHouseFromList, (item, index) => (
                    <Option key={item.warehouseId} value={item.warehouseId}>
                      {item.warehouseCode} · {item.warehouseName}
                    </Option>
                  ))}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={
              <LabeRequireForm bold={true} text={intl.formatMessage({ id: 'TFToWareHouse', defaultMessage: 'To WareHouse' })} req={true} />
            }
          >
            {getFieldDecorator('warehouseToId', {
              initialValue: _.get(record, 'warehouseToId') ? _.get(record, 'warehouseToId') : undefined,
              rules: [
                {
                  required: true,
                  message: intl.formatMessage({ id: 'TFToWareHouseValidate', defaultMessage: 'Please Select To WareHouse' }),
                },
              ],
            })(
              <Select
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.props.children
                    .toString()
                    .toLowerCase()
                    .indexOf(input.toString().toLowerCase()) >= 0
                }
                placeholder={intl.formatMessage({ id: 'TFToWareHousePlaceHolder', defaultMessage: 'Select To WareHouse' })}
                onPopupScroll={handleWarhouseToPopupScroll}
                onSelect={(e) => {
                  setNotWarehouseToId(e);
                }}
              >
                {_.map(wareHouseToList, (item, index) => (
                  <Option key={item.warehouseId} value={item.warehouseId}>
                    {item.warehouseCode} · {item.warehouseName}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[24]}>
        <Col span={12}>
          <Form.Item
            label={<LabeRequireForm bold={true} text={intl.formatMessage({ id: 'TFAssignTo', defaultMessage: 'Assign To' })} req={true} />}
            className="form-line-height"
          >
            <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
              <Select
                className="allticketinput"
                defaultValue="employee"
                value={switchAssign}
                style={{ width: '40%' }}
                onSelect={handleSwitchAssign}
              >
                {AssignType &&
                  _.map(AssignType, (item, index) => {
                    return (
                      <Option key={_.get(item, 'id')} value={_.get(item, 'id')}>
                        {_.get(item, 'name')}
                      </Option>
                    );
                  })}
              </Select>
              {switchAssign === 'internal'
                ? getFieldDecorator('assignToId', {
                    initialValue: _.get(record, 'assignToTypeCode') !== switchAssign ? undefined : _.get(record, 'assignToId') ? _.get(record, 'assignToId') : undefined,
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({
                          id: 'TFAssignToInternalValidate',
                          defaultMessage: 'Please Select Assign To',
                        }),
                      },
                    ],
                  })(
                    <Select
                      className="allticketSelect"
                      style={{ width: '100%' }}
                      placeholder={intl.formatMessage({ id: 'TFAssignToInternalPlaceHolder', defaultMessage: 'Select Assign To' })}
                    >
                      {initialMemberList &&
                        _.map(initialMemberList, (item, index) => {
                          return (
                            <Option key={_.get(item, 'memComId')} value={_.get(item, 'memComId')}>
                              {_.get(item, 'fullname')}
                            </Option>
                          );
                        })}
                    </Select>
                  )
                : getFieldDecorator('assignToName', {
                    initialValue: _.get(record, 'assignToTypeCode') !== switchAssign ? undefined : _.get(record, 'assignToName') ? _.get(record, 'assignToName') : undefined,
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({
                          id: 'TFAssignToIExternalValidate',
                          defaultMessage: 'Please Enter Assign To',
                        }),
                      },
                    ],
                  })(
                    <Input
                      className="allticketSelect"
                      style={{
                        width: '100%',
                        borderBottomLeftRadius: '0px !important',
                        borderTopLeftRadius: '0px !important',
                      }}
                      placeholder={intl.formatMessage({ id: 'TFAssignToPlaceHolder', defaultMessage: 'Enter Assign To' })}
                    />
                  )}
            </div>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label={
              <LabeRequireForm
                bold={true}
                text={intl.formatMessage({ id: 'TFTransferDate', defaultMessage: 'Transfer Date' })}
                req={true}
              />
            }
          >
            {getFieldDecorator('transferDate', {
              initialValue: _.get(record, 'transferDate') ? moment(_.get(record, 'transferDate'), dateFormat) : moment(),
              rules: [
                {
                  required: true,
                  message: intl.formatMessage({ id: 'TFTransferDateValidate', defaultMessage: 'Please Transfer Date' }),
                },
              ],
            })(
              <DatePicker
                allowClear={false}
                format={dateFormat}
                style={{ width: '100%' }}
                // placeholder={intl.formatMessage({ id: 'quotationHintIssueBy', defaultMessage: 'Select Issue by' })}
              />
            )}
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item
            label={
              <LabeRequireForm
                bold={true}
                text={intl.formatMessage({ id: 'TFTransferTime', defaultMessage: 'Transfer Time' })}
                req={true}
              />
            }
          >
            {getFieldDecorator('transferTime', {
              initialValue: _.get(record, 'transferDate') ? moment(timeValue[2], timeformat) : moment(),
              rules: [
                {
                  required: true,
                  message: intl.formatMessage({ id: 'TFTransferTimeValidate', defaultMessage: 'Please Transfer Time' }),
                },
              ],
            })(<TimePicker className="timeTF" format={timeformat} style={{ width: '100%' }} allowClear={false} />)}
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[24]}>
        <Col span={12}>
          <Form.Item
            label={
              <LabeRequireForm bold={true} text={intl.formatMessage({ id: 'TFSubmittedBy', defaultMessage: 'Submitted by' })} req={true} />
            }
          >
            {getFieldDecorator('submitById', {
              initialValue: _.get(record, 'submitById') ? _.get(record, 'submitById') : memComId,
              rules: [
                {
                  required: true,
                  message: intl.formatMessage({ id: 'TFSubmittedByValidate', defaultMessage: 'Please Select Submitted by' }),
                },
              ],
            })(
              <Select
                placeholder={intl.formatMessage({ id: 'TFSubmittedByPlaceHoler', defaultMessage: 'Select Submitted by' })}
                showSearch
                filterOption={(input, option) =>
                  option.props.children
                    .toString()
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {initialMemberList && initialMemberList.map((item, index) => MemberOption(item, index))}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={
              <LabeRequireForm
                bold={true}
                text={intl.formatMessage({ id: 'TFSubmittedDate', defaultMessage: 'Submitted Date' })}
                req={true}
              />
            }
          >
            {getFieldDecorator('submitDate', {
              initialValue: _.get(record, 'submitDate') ? moment(_.get(record, 'submitDate')) : moment(),
              rules: [
                {
                  required: true,
                  message: intl.formatMessage({ id: 'TFSubmittedDateValidate', defaultMessage: 'Please Select Submitted Date' }),
                },
              ],
            })(
              <DatePicker
                allowClear={false}
                format={dateFormat}
                style={{ width: '100%' }}
                // placeholder={intl.formatMessage({ id: 'quotationHintIssueBy', defaultMessage: 'Select Issue by' })}
              />
            )}
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[24]}>
        <Col span={24}>
          <Form.Item
            label={<LabeRequireForm bold={true} text={intl.formatMessage({ id: 'TFRemark', defaultMessage: 'Remark' })} req={false} />}
          >
            {getFieldDecorator('remark', { initialValue: _.get(record, 'remark') ? _.get(record, 'remark') : '' })(
              <Input style={{ width: '100%' }} />
            )}
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

CustomerForm.defaultProps = {};

export default CustomerForm;
