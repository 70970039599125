import React, {useState, useContext, useEffect} from 'react';
import { ShiftContext } from './shift-context'; 
import { PageSettings } from '../../../config/page-settings';
import { useIntl, FormattedMessage } from 'react-intl';
import { Row, Col, Card, DatePicker } from 'antd';
import Button01 from '../../../components/v2/button_01';
import httpClient from '../../../components/axiosClient';
import Provider from '../provider';
import _ from 'lodash';
import moment from 'moment';
import ShiftSchedulePlanning from './shift-planning/index';
import stylesShiftAction from './css/shift-action.css';

const { MonthPicker } = DatePicker;
const months = moment.months();
const years = [2022, 2021, 2020, 2019, 2018, 2017];

const dateTimeApi = 'YYYY-MM-DD HH:mm';


export default React.memo((props) => {
  // const {
  //   shiftsMonth, 
  //   shiftsYear, 
  //   setShiftsMonth,
  //   setShiftsYear,
  //   shiftPlanningDisabled, 
  //   planningVisibled,
  //   setMainShiftsDateTimePicker,
  //   setPlanningVisibled,
  //   handleChangeMonth,
  //   handleChangeYear
  // } = props;
  const intl = useIntl();
  const app = Provider.useAppContext();
  const { stateShiftsMain, setStateShiftsMain, fncShiftsMain, } = useContext(ShiftContext);

  const [month, setMonth] = useState(parseInt(moment().month(stateShiftsMain.shiftsMonth).format('MM') - 1));
  const [year, setYear] = useState(parseInt(stateShiftsMain.shiftsYear));

  // const [month, setMonth] = useState(parseInt(moment().month(shiftsMonth).format('MM') - 1));
  // const [year, setYear] = useState(parseInt(shiftsYear));


  const datepickerMonth = moment().month(month).format('MM');




  const handleChangeMonthPicker = (date, dateString) => {
    setStateShiftsMain.setMainShiftsDateTimePicker(moment(dateString).format(dateTimeApi));
    fncShiftsMain.handleChangeMonth(moment(date.format('MM'), 'MM').local('EN').format('MMMM'));
    fncShiftsMain.handleChangeYear(date.format('YYYY'));

    // setMainShiftsDateTimePicker(moment(dateString).format(dateTimeApi));
    // handleChangeMonth(moment(date.format('MM'), 'MM').local('EN').format('MMMM'));
    // handleChangeYear(date.format('YYYY'));
  }

  const handleOpenShiftPlanning = () => {
    setStateShiftsMain.setPlanningVisibled(true);
    // setPlanningVisibled(true);
  }

  const handlePlanningCancel = (year, month) => {
    setStateShiftsMain.setPlanningVisibled(false);
    if (!_.isObject(year)) setStateShiftsMain.setShiftsYear(year);
    if (month) setStateShiftsMain.setShiftsMonth(month);
    app.fnc.getRefresh();

    // setPlanningVisibled(false);
    // if (!_.isObject(year)) setShiftsYear(year);
    // if (month) setShiftsMonth(month);
    // app.fnc.getRefresh();
  }

  return (
    <PageSettings.Consumer>
      {({ checkPermissionAction }) => (
        <div>
          <Row gutter={[16]}>
            <Col span={12}>
              <div className="shift-div-month-picker"> 
                <MonthPicker 
                  className="shift-month-picker"
                  defaultValue={moment(`${year}-${datepickerMonth}`, 'YYYY-MM').locale(localStorage.getItem('langValue'))} 
                  format={'MMMM YYYY'} 
                  allowClear={false}
                  onChange={handleChangeMonthPicker}
                  style={{
                    width: '170px'
                  }}
                />
              </div>
            </Col>
            <Col span={12}>
              <div className="shift-div-button-planning">
                {checkPermissionAction('P3PG1C7', 'P3PG1C7A4') ? (
                  <Button01
                    type='primary'
                    fontsize='sm'
                    btnsize='wd_at'
                    onClick={handleOpenShiftPlanning}
                    disabled={stateShiftsMain.shiftPlanningDisabled}
                    // disabled={shiftPlanningDisabled}
                  >
                    <FormattedMessage id="orgShiftPlanningLblShift" defaultMessage="Shift Planning" />
                  </Button01>
                ) : null}

                {stateShiftsMain.planningVisibled ? (
                // {planningVisibled ? ( 
                  <ShiftSchedulePlanning
                    handlePlanningCancel={handlePlanningCancel}
                  />
                  ) : null}
              </div>
            </Col>
          </Row>
        </div>
      )}
    </PageSettings.Consumer>
  )
})



