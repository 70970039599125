import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import httpClient from '../../../../components/axiosClient';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { useIntl } from 'react-intl';

const { Option } = Select;

const UserAllSelect = (props) => {

  const comId = localStorage.getItem('comId');
  const memComId = localStorage.getItem('memComId');
  const [users, setUsers] = useState([]);
  const [backupUsers, setBackupUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(true);
  const { setuserCreateBy } = props
  const intl = useIntl();

  useEffect(() => {

    const getDataUser = async () => {
      try {
        const like = search ? [{ key: '', val: search }] : [];
        const indexPage = search ? 1 : page;
        const payload = {
          user_id: memComId,
          like: like,
          equal: [],
          date: [],
          order: 'created_at ASC',
          limit: 10,
          index_page: indexPage,
          com_id: comId
        };
        const result = await httpClient.post('/v2/searchusers', payload);

        if (result.status === 200) {

          const setDataMember = _.map(_.get(result, 'data.data'), (item) => {
            return {
              memComId: _.get(item, 'mem_com_id'),
              fullname: _.get(item, 'fullname'),
              phone: _.get(item, 'phone')
            };
          });

          if (_.size(setDataMember) > 0) {
            if (search) {
              if (_.size(backupUsers) === 0) setBackupUsers(users);
              setUsers(setDataMember);
            }
            else {
              setUsers([...users, ...setDataMember]);
            }
          }

        }
        else {
          console.log('ดึงข้อมูลไม่เจอ member');
        }
      }
      catch (error) {
        console.log('api error');
      }

      setLoading(false);
    }

    if (loading) getDataUser();

  }, [page, search]);

  const handleScroll = (event) => {
    let target = event.target;
    if (!loading && target.scrollTop + target.offsetHeight >= (target.scrollHeight - 20)) {
      setPage(page + 1);
      setLoading(true);
    }
  }

  const handleSearch = (value) => {
    if (!loading) {
      if (value) {
        setSearch(value);
        setLoading(true);
      }
      else {
        setSearch(value);
        setUsers(backupUsers);
        setBackupUsers([]);
      }
    }
  }

  const handleChange = (value) => {
    console.log('************** handle change');
    console.log(value);
    setuserCreateBy(value)

  }

  const options = users.map && users.map((item) =>
    <Option
      key={uuidv4()}
      value={item.memComId}
    >
      {item.fullname}
    </Option>);

  return (
    <Select
      loading={loading}
      allowClear
      showSearch
      style={{ width: 250 }}
      onPopupScroll={handleScroll}
      onSearch={handleSearch}
      onChange={handleChange}
      filterOption={(input, option) =>
        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      placeholder={intl.formatMessage({ id: 'historyModalTitleUser', defaultMessage: "Please Select User" })}
    >
      {options}
    </Select>
  );
}

export default UserAllSelect;