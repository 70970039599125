import React, { useState, useEffect, useRef, useCallback, useContext } from 'react';
import { Row, Col, DatePicker, notification } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import { PageOrganizationTabShift } from '../../../config/page-settings';
import { OrganizationContext } from '../organization-context';
import httpClient from '../../../components/axiosClient';
import axios from 'axios';
import BtnShiftPlanning from './btn-shift-planning';
import ScheduleShift from './schedule-shift';
import ScheduleViewShift from './schedule-view-shift';
import ScheduleAddShift from '../shift-v1/shift-modal-add-shift';
import SchedulePlanningShift from '../shift-v1/shift-planning/index';

const { MonthPicker } = DatePicker;

const comCode = localStorage.getItem('comCode');
const langValue = localStorage.getItem('langValue') || 'EN';
const thisYearFormat = 'YYYY';
const thisMonthFormat = 'MM';
const thisDate = moment().locale(langValue);
const thisYear = thisDate.format(thisYearFormat);
const thisMonth = thisDate.format(thisMonthFormat);
const thisDiffMonths = moment().diff(`${thisYear}-${thisMonth}-01`, 'months');

export default React.memo((props) => {
  // const { comId, orgId, orgDetail, shiftType } = props;
  // const { comId, orgId } = props;
  const { stateMain, setStateMain } = useContext(OrganizationContext);

  let orgId = stateMain.orgId;
  let comId = stateMain.comId;

  const [orgDetail, setOrgDetail] = useState({});
  const [membersData, setMembersData] = useState([]);
  const [year, setYear] = useState(thisYear);
  const [month, setMonth] = useState(thisMonth);
  const [visibleShiftPlanning, setVisibleShiftPlanning] = useState(false);
  const [visibleViewShift, setVisibleViewShift] = useState(false);
  const [visibleBtnDeleteShift, setVisibleBtnDeleteShift] = useState(false);
  const [disableBtnDeleteShift, setDisableBtnDeleteShift] = useState(false);
  const [visibleAddShift, setVisibleAddShift] = useState(false);
  const [disableBtnAddShift, setDisableBtnAddShift] = useState(false);
  const [viewShiftDetail, setViewShiftDetail] = useState();
  const [formRef, setFormRef] = useState(null);
  const [formData, setFormData] = useState({});
  const [rangePickerValue, setRangePickerValue] = useState([]);
  const [dateStart, setDateStart] = useState(undefined);
  const [dateEnd, setDateEnd] = useState(undefined);
  const [resourceCellgroupIndex, setResourceCellgroupIndex] = useState(null);
  const [resourceCellArgs, setResourceCellArgs] = useState({});
  const [statusPlanningApply, setStatusPlanningApply] = useState(false);
  const scheduleObj = useRef();

  const [dataResource, setDataResource] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [shiftPlanningDisabled, setShiftPlanningDisabled] = useState(thisDiffMonths > 0 ? true : false);

  // useEffect(() => {
  //   getOrganizationShiftData();
  // }, [stateMain.orgId, year, month]);

  // useEffect(() => {
  //   if(statusPlanningApply) getOrganizationShiftData();
  // }, [statusPlanningApply]);

  useEffect(() => {
    getOrganizationShiftDataTest();
  }, [stateMain.orgId, year, month]);

  useEffect(() => {
    if (statusPlanningApply) getOrganizationShiftDataTest();
  }, [statusPlanningApply]);


  const getOrganizationShiftDataTest = async () => {
    scheduleObj.current.showSpinner();

    if (stateMain.orgId) {
      // scheduleObj.current.showSpinner();
      // const bodyOrg = {
      //   com_id: stateMain.comId ,
      //   org_id: stateMain.orgId,
      //   search_team: {
      //     name: '',
      //   },
      //   members: {
      //     conditional: [],
      //     order: 'fullname ASC',
      //     limit: '1000',
      //     index_page: '1',
      //   },
      //   services: {},
      //   shift: {
      //     date: `${year}-${month}`
      //   },
      // };

      const bodyOrg = {
        search: "",
        searchBy: "all",
        orderType: "",
        orderBy: "",
        paging: 1,
        rowsPerPages: 200
      }

      const bodyShift = {
        date: `${year}-${month}`
      }


      // const requestMembers = httpClient.post(`/v4/resource/manager/company/comid/${stateMain.comId}/orgid/${stateMain.orgId}/member`, bodyOrg);
      const requestMembers = httpClient.get(`/v3/resource/manager/company/KGE/team/${stateMain.orgId}/all-members/forshiftorg`);
      const requestOrg = httpClient.get(`/v4/resource/manager/company/orgid/${stateMain.orgId}/details`);
      const requestShift = httpClient.post(`/v4/resource/manager/company/comid/${stateMain.comId}/orgid/${stateMain.orgId}/shift`, bodyShift);

      axios.all([requestMembers, requestOrg, requestShift]).then(axios.spread((...responses) => {
        const responseMem = responses[0];
        const responseOrg = responses[1];
        const responseShift = responses[2];

        if (responseMem.status === 200) {
          setMembersData(responseMem.data.data.members);
          const resource = setResource(responseMem.data.data.members);
          scheduleObj.current.resources[0].dataSource = resource;
        }

        if (responseShift.status === 200) {
          const shift = setShift(responseShift.data.shift);
          scheduleObj.current.eventSettings.dataSource = shift;
        }

        if (responseOrg.status === 200) {
          const teamDetails = _.isObject(responseOrg.data.organization) ? responseOrg.data.organization.teamDetails[0] : [];
          const teamType = _.isObject(responseOrg.data.organization) ? responseOrg.data.organization.teamType : [];
          const detail = { ...teamDetails, teamType: [...teamType] }
          setOrgDetail(detail);
        }

      })).catch(errors => {
        return
      })
      scheduleObj.current.hideSpinner();
    }
  }

  const getOrganizationShiftData = async () => {
    scheduleObj.current.showSpinner();

    const data = {
      com_id: stateMain.comId,
      org_id: stateMain.orgId,
      search_team: {
        name: '',
      },
      members: {
        conditional: [],
        order: 'fullname ASC',
        limit: '1000',
        index_page: '1',
      },
      services: {},
      shift: {
        date: `${year}-${month}`
      },
    };

    try {
      const response = await httpClient.post('/v2/organizationdetails', data);
      if (response.status === 200) {

        const resource = setResource(response.data.members.data);
        scheduleObj.current.resources[0].dataSource = resource;

        const shift = setShift(response.data.shift);
        scheduleObj.current.eventSettings.dataSource = shift;
      }
    } catch (error) {
      return
    }

    scheduleObj.current.hideSpinner();
  };

  const checkDeleteShift = async (memShiftId, ThisOrgSelect) => {
    let status = false;
    if (memShiftId) {
      try {
        const response = await httpClient.get(`/v3/resource/manager/company/${comCode}/validate-delete/member-shift/${memShiftId}?orgId=${ThisOrgSelect}`);
        if (response.status === 200) {
          status = response.data.data.hasClockIn;
        }
      } catch (error) {
        status = true;
      };
    }
    return status;
  }

  const setResource = (resource) => {
    return resource ? resource.map((o) => {
      return {
        Id: parseInt(o.mem_id),
        Text: o.fullname,
        email: o.email,
        phone: o.phone,
        pathImage: o.profile_img,
        memComId: parseInt(o.mem_com_id),
        memOrgId: parseInt(o.mem_org_id)
      }
    }) : [];
  }

  const setShift = (shift) => {

    let newShift = [];
    shift.forEach((object) => {
      object.shifts.forEach((element) => {
        const { mem_shift_id, shift_name, shift_date, mem_id, color, is_holiday, teamName, start, finish, shiftTime } = element;
        newShift.push({
          Id: mem_shift_id,
          Subject: shift_name,
          StartTime: moment(shift_date + ' ' + start, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm'),
          EndTime: moment(shift_date + ' ' + finish, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm'),
          Color: color,
          ResourceId: parseInt(mem_id),
          MemComId: parseInt(object.mem_com_id),
          PathImage: object.default_profile_img,
          Name: object.fullname,
          Email: object.email,
          Phone: object.phone,
          IsAllDay: true,
          isReadonly: is_holiday ? true : false,
          TeamName: teamName ? teamName : '',
          Start: moment(start, 'HH:mm:ss').format('HH:mm'),
          Finish: moment(finish, 'HH:mm:ss').format('HH:mm'),
          ThisOrgSelect: parseInt(orgId),
          shiftTime: shiftTime
        });
      });
    });
    return newShift;
  }

  const handleChangeMonthPicker = (date) => {
    const sYears = date.format(thisYearFormat);
    const sMonths = date.format(thisMonthFormat);
    const sDiffMonths = moment().diff(`${sYears}-${sMonths}-01`, 'months');
    // console.log('-------------handleChangeMonthPicker----------------');
    // console.log(date.format(thisYearFormat));
    // console.log(date.format(thisMonthFormat));
    // console.log(`${sYears}-${sMonths}-01`);
    // console.log(moment().diff(`${sYears}-${sMonths}-01`, 'months'));
    scheduleObj.current.selectedDate = date._d;
    setYear(sYears);
    setMonth(sMonths);
    setShiftPlanningDisabled(sDiffMonths > 0 ? true : false);
  }

  const handleClickShiftPlanning = () => {
    setStatusPlanningApply(false);
    setVisibleShiftPlanning(true);
  }

  const handleClickViewShift = async (args) => {

    args.cancel = true;
    if (args.name === 'eventClick' && !args.event.isReadonly) {
      let checkStatusDelete = false;
      if (!args.event.isHoliday && !args.event.isLeave) checkStatusDelete = await checkDeleteShift(args.event.Id, args.event.ThisOrgSelect);
      setDisableBtnDeleteShift(false);
      setVisibleBtnDeleteShift(checkStatusDelete);
      setViewShiftDetail({
        Id: args.event.Id,
        Subject: args.event.Subject,
        Color: args.event.Color,
        memId: args.event.ResourceId,
        memComId: args.event.MemComId,
        pathImage: args.event.PathImage,
        name: args.event.Name,
        email: args.event.Email,
        phone: args.event.Phone,
        startDate: moment(args.event.StartTime).locale(langValue.toLowerCase()).format('DD/MM/YYYY hh:mm A'),
        endDate: moment(args.event.EndTime).locale(langValue.toLowerCase()).format('DD/MM/YYYY hh:mm A'),
        shiftTime: _.get(args, 'event.shiftTime')
      });
      setVisibleViewShift(true);
    }
  }

  const handleDeleteViewShift = async () => {
    setDisableBtnDeleteShift(true);
    try {
      const response = await httpClient.delete(`/v2/members-shifts/delete/${viewShiftDetail.Id}`);
      if (response.status === 200) {
        scheduleObj.current.deleteEvent(viewShiftDetail.Id);
        successNotification(response.data.data);
        handleCancelViewShift();
      } else {
        errorNotification(response.data.data);
        handleCancelViewShift();
      }
    } catch (error) {
      errorNotification('');
      handleCancelViewShift();
    }
  }

  const handleCancelViewShift = () => {
    setVisibleViewShift(false);
  }

  const handleClickAddShift = (args) => {
    if (args.name === 'cellDoubleClick') args.cancel = true;
    if (args.name === 'cellClick') {
      const getResourceData = scheduleObj.current.getResourcesByIndex(args.groupIndex);
      const resourceData = getResourceData.resourceData;
      setDateStart(args.startTime);
      setDateEnd(args.startTime);
      setResourceCellgroupIndex(args.groupIndex);
      setResourceCellArgs(args);
      setRangePickerValue([args.startTime, args.endTime]);
      setFormData({
        memId: resourceData.Id,
        memComId: resourceData.memComId,
        pathImage: resourceData.pathImage,
        name: resourceData.Text,
        email: resourceData.email || '',
        phone: resourceData.phone || '',
        startDate: args.startTime,
        endDate: args.endTime
      });
      if (stateMain.checkApprover) {
        setVisibleAddShift(true);
      } else {
        setVisibleAddShift(false);
      }
      setDisableBtnAddShift(false);
    }
  }

  const handleOkAddShift = async (e) => {
    formRef.validateFields(async (err, values) => {
      if (err) {
        return;
      }

      setDisableBtnAddShift(true);

      const getResourceData = scheduleObj.current.getResourcesByIndex(resourceCellgroupIndex);
      const resourceData = getResourceData.resourceData;

      try {
        const body = {
          comId: comId,
          orgId: _.toString(orgId),
          memId: _.toString(resourceData.Id),
          memOrgId: _.toString(resourceData.memOrgId),
          shiftId: values.shift,
          from: moment(values.range_picker[0]).format('YYYY-MM-DD'),
          to: moment(values.range_picker[1]).format('YYYY-MM-DD')
        }
        const response = await httpClient.post(`/v2/members-shifts/create`, body);
        if (response.status === 200) {
          const newShiftArr = _.get(response.data, 'memberShiftNew');
          if (_.size(newShiftArr)) {
            _.forEach(newShiftArr, (o) => {
              const newShift = {
                Id: o.mem_shift_id,
                Subject: o.shift_name,
                StartTime: moment(o.shift_date + ' ' + o.start, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm'),
                EndTime: moment(o.shift_date + ' ' + o.finish, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm'),
                Color: o.color,
                ResourceId: Number(o.mem_id),
                MemComId: resourceData.memComId,
                PathImage: resourceData.pathImage,
                Name: resourceData.Text,
                Email: resourceData.email,
                Phone: resourceData.phone,
                IsAllDay: true,
                isReadonly: o.is_holiday ? true : false,
                TeamName: _.get(orgDetail, 'name') || '',
                Start: moment(o.start, 'HH:mm:ss').format('HH:mm'),
                Finish: moment(o.finish, 'HH:mm:ss').format('HH:mm'),
                ThisOrgSelect: orgId
              };
              scheduleObj.current.addEvent(newShift);
            });
          }

          successNotification(response.data.message);
          handleCancelAddShift();
        } else {
          errorNotification(response.data.data);
          handleCancelAddShift();
        }
      } catch (error) {
        errorNotification('');
        handleCancelAddShift();
      }
    })
  }

  const handleCancelAddShift = () => {
    setVisibleAddShift(false);
    formRef.resetFields();
  }

  const handleCancelShiftPlanning = (sY, sM, sA = '') => {
    if (sA === 'apply') setStatusPlanningApply(true);
    setVisibleShiftPlanning(false);
  }

  const saveFormRef = useCallback(node => {
    if (node !== null) {
      setFormRef(node);
    }
  }, []);

  const successNotification = (message) => {
    notification.success({
      message: message,
    });
  };

  const errorNotification = (message) => {
    notification.error({
      message: message,
    });
  };

  const handleDoubleClickCell = (args) => {
    args.cancel = true;
  }

  const handleMoreEventClick = (args) => {
    args.cancel = true;
  }

  const onPopupOpen = (args) => {
    args.cancel = true;
  }

  return (
    <PageOrganizationTabShift.Provider
      value={{
        orgId,
        year,
        month,
        scheduleObj,
        dataResource,
        dataSource,
        visibleViewShift,
        visibleBtnDeleteShift,
        viewShiftDetail,
        disableBtnDeleteShift,
        disableBtnAddShift,
        handleClickShiftPlanning,
        handleClickViewShift,
        handleDeleteViewShift,
        handleCancelViewShift,
        handleClickAddShift,
        handleCancelAddShift,
        handleDoubleClickCell,
        handleMoreEventClick,
        onPopupOpen
      }}
    >
      <div style={{ padding: '8px 24px 24px 24px' }}>
        <Row gutter={[24, 12]}>
          <Col span={12}>
            <MonthPicker
              className='shift-month-picker'
              defaultValue={thisDate}
              format={'MMMM YYYY'}
              allowClear={false}
              onChange={handleChangeMonthPicker}
              style={{ width: 200 }}
            />
          </Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            <BtnShiftPlanning checkApprover={stateMain.checkApprover} shiftPlanningDisabled={shiftPlanningDisabled} />
          </Col>
        </Row>

        <Row gutter={[24, 24]}>
          <Col span={24}>
            <ScheduleShift />
          </Col>
        </Row>

        <ScheduleAddShift
          ref={saveFormRef}
          formData={formData}
          dateStart={dateStart}
          dateEnd={dateEnd}
          setDateStart={setDateStart}
          setDateEnd={setDateEnd}
          onCreate={handleOkAddShift}
          onCancel={handleCancelAddShift}
          rangePickerValue={rangePickerValue}
          visible={visibleAddShift}
          rangePickerDisabled={'false'}
          shiftsType={stateMain.shiftsType}
          disableBtnAddShift={disableBtnAddShift}
        />

        <ScheduleViewShift />

        {visibleShiftPlanning ?
          <SchedulePlanningShift
            scheduleMainObj={scheduleObj}
            handlePlanningCancel={handleCancelShiftPlanning}
            comId={comId}
            orgId={orgId}
            shiftsYear={year}
            shiftsMonth={month}
            planningVisibled={visibleShiftPlanning}
            membersData={membersData}
            orgDetail={orgDetail}
          /> : null}
      </div>
    </PageOrganizationTabShift.Provider>
  );

}, (prev, next) => {
  return _.isEqual(prev, next);
});