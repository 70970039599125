import React from 'react'
import { AllowanceContext } from './context'
import AllowanceContent from './components/content'

const PageResourceAllowance = () => {

  return (
    <AllowanceContext>
      <AllowanceContent />
    </AllowanceContext>
  )
}

export default PageResourceAllowance
