import httpClient from '../../components/axiosClient';

const getFeedList = async (body) => {
  const comCode = localStorage.getItem('comCode');

  try {
    const response = await httpClient.post(`/note/manager/company/${comCode}/feedlist`, body);
    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

const saveFeedList = async (body) => {
  const comCode = localStorage.getItem('comCode');

  try {
    const response = await httpClient.post(`/note/manager/company/${comCode}/feed`, body);
    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

const uploadFeedList = async (body) => {
  const comCode = localStorage.getItem('comCode');

  try {
    const response = await httpClient.post(`/note/manager/company/${comCode}/feedfile`, body);
    if (response.status === 200) {
      return response;
    } else {
      return response;
    }
  } catch (error) {
    return error.response;
  }
};

const getTagList = async (body) => {
  const comCode = localStorage.getItem('comCode');

  try {
    const response = await httpClient.post(`/note/manager/company/${comCode}/tagList`, body);
    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

export { getFeedList, saveFeedList, uploadFeedList, getTagList };
