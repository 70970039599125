import React, { useContext } from 'react';
import { PageEngagement } from '../../../config/page-settings';
import ComponentPost from '../../../components/post/post';
import stylesPost from '../css/engagement-post.css';
import _ from 'lodash';


export default React.memo((props) => {
  const { fnc } = useContext(PageEngagement);
  let postData = props.data;

  return (
    <div className='engagement-post'>
      <ComponentPost fnc={fnc} postData={postData} />
    </div>
  );
}, (prev, next) => {
  return _.isMatch(prev, next);
});