import React from 'react'
import { Form, Row, Col, Input } from 'antd'
import LabeRequireForm from '../../label-required-form/index'
import { useIntl } from 'react-intl'

const { TextArea } = Input

const ModalUOMAdd = (props) => {
  const { form } = props
  const intl = useIntl()
  const { getFieldDecorator } = form
  return (
    <div>
      <Form form={form} layout="vertical">
        <Row>
          <Col span={24}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'modalCategoryUOMCategorytext', defaultMessage: 'UoM Category' })} req={true} />
              }
            >
              {getFieldDecorator('uomCategoryAdd', {
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'modalCategoryUOMCategoryValidate', defaultMessage: 'Please Enter UoM Category' }),
                  },
                ],
              })(
                <Input
                  placeholder={intl.formatMessage({ id: 'modalCategoryUOMCategoryPlaceholeder', defaultMessage: 'Enter UoM Category' })}
                  maxLength={100}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'modalCategoryUOMDescriptionText', defaultMessage: 'Description' })} req={false} />
              }
            >
              {getFieldDecorator('descriptionAdd', {
              })(
                <TextArea
                  autoSize={{ minRows: 5, maxRows: 5 }}
                />
              )}
            </Form.Item>
          </Col>
        </Row>

      </Form>
    </div>
  )
}

export default ModalUOMAdd