import React, { useState, useEffect, useContext } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useAppContext } from '../../../includes/indexProvider';
import Provider from '../provider';
import { Form, Input, Select, DatePicker, Switch, Col, Row, notification, ConfigProvider } from 'antd';
// import * as moment from 'moment';
import moment from 'moment';
import httpClient from '../../../components/axiosClient';
import Button01 from '../../../components/v2/button_01';
import cssStyle from './css/index.css';
import styled from 'styled-components';
import _, { get } from 'lodash';
import { error } from 'jquery';
import { PageSettings } from '../../../config/page-settings';
import enUS from 'antd/es/locale/en_US';
import thTH from 'antd/es/locale/th_TH';
import 'moment/locale/th';

// moment.locale('en');

const DivDetail = styled.div`
  padding: 0px 24px;
`;

const LabelRequire = styled.label`
  color: #ff1010;
`;

const { Option } = Select;

const dateFormat = 'DD MMMM YYYY';

const ValidatedFields = React.memo((props) => {
  const getLangValue = useAppContext();
  const intl = useIntl();
  const app = Provider.useAppContext();
  const langValue = localStorage.getItem('langValue');
  const appState = app.state;
  const appFnc = app.fnc;



  const { getFieldDecorator, getFieldValue, validateFields, resetFields } = props.form;
  const { newForm } = props;


  const memComId = localStorage.getItem('memComId');

  let status = props.data.status === 1 ? true : false;

  const addnew = app.state.addNewOrganization;

  const [teamType, setTeamType] = useState([]);
  const [selectTeamTypeId, setSelectTeamTypeId] = useState();
  const [dateStartValue, setDateStartValue] = useState();
  const [dateStartString, setDateStartString] = useState();
  const [dateEndValue, setDateEndValue] = useState();
  const [dateEndString, setDateEndString] = useState();
  const [description, setDescription] = useState('');
  const [getStatus, setGetStatus] = useState(false);
  const [locale, setLocale] = useState(enUS);


  const parentId = props.parentId;
  const orgId = props.data.org_id;
  const comId = localStorage.getItem('comId');

  useEffect(() => {
    const newTeamType = props.data.teamType ? props.data.teamType : [];
    setTeamType(newTeamType);
  }, [props]);

  useEffect(() => {
    setSwitch();
  }, [status, orgId]);

  useEffect(() => {
    changeLocale();
  }, []);

  const changeLocale = () => {
    let codeLang = langValue ? langValue : 'EN';
    let changeLang = codeLang === 'EN' ? enUS : thTH;
    setLocale(changeLang);
  };

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
      duration: 1.7,
    });
  };

  const setSwitch = () => {
    if (addnew === true) {
      setGetStatus(false);
    } else {
      setGetStatus(props.data.status === 1 ? true : false);
    }
  };

  const sendTeamDetail = () => {
    const error = validateFields();
    error.then(
      (response) => {
        if (!newForm) {
          const data = {
            org_id: orgId,
            name: response.teamName,
            team_type_id: response.teamType,
            start_date: moment(response.startDate._d.valueOf()).format(
              'YYYY-MM-DD',
            ),
            end_date: moment(response.endDate._d.valueOf()).format(
              'YYYY-MM-DD',
            ),
            description: response.description,
            updated_by: memComId,
          };

          httpClient.post('/v2/update/organization', data).then(
            (response) => {
              if (response.status === 200) {
                openNotificationWithIcon('success', response.data.data);
                app.fnc.getRefresh(!app.state.refreshSaveFrom);
              } else {
                openNotificationWithIcon('error', response.data.data);
              }
            },
            (error) => {
              openNotificationWithIcon('error');
            },
          );
        } else {
          const data = {
            created_by: memComId,
            com_id: comId,
            name: response.teamName,
            parent_lavel: '1',
            parent_id: parentId ? parentId : '',
            team_type_id: selectTeamTypeId,
            start_date: dateStartString,
            end_date: dateEndString,
            description: description,
          };


          httpClient.post('/v2/create/organization', data).then(
            (response) => {
              // app.fnc.setRefreshSaveForm(!app.state.refreshSaveFrom);
              if (response.status === 200) {
                openNotificationWithIcon('success', response.data.data);
                app.fnc.setOrgId(orgId);
                app.fnc.setParentId();
                app.fnc.setAddNewOrganization(true);
                app.fnc.getRefresh(!app.state.refreshSaveFrom);
                app.fnc.setPageDefault(true);
                app.fnc.setValueDefault(data);
                // resetFields();


              } else {
                openNotificationWithIcon('error', response.data.data);
                app.fnc.setValueDefault();
              }
            },
            (error) => {
              openNotificationWithIcon('error');
            },
          );
        }
      },
      (error) => {
      },
    );
  };

  const handleTeamNameChange = (e) => {
  };

  const handleSelectTeamType = (select) => {
    setSelectTeamTypeId(select);
  };

  const handleDateStart = (value, dateString) => {
    setDateStartValue(value);
    setDateStartString(value.format('YYYY-MM-DD'));
  };

  const handleDateEnd = (value, dateString) => {
    setDateEndValue(value);
    setDateEndString(value.format('YYYY-MM-DD'));
  };

  const disabledStartDate = (current) => {
    const newCurrent = current >= dateEndValue;
    return newCurrent;
  };

  const disableEndDate = (current) => {
    const newEndDate = current < dateStartValue;
    return newEndDate;
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const onSwitchChange = (checked) => {
    setGetStatus(checked);
    if (!addnew === true) {
      if (!parentId) {
        let status_work = checked ? 1 : 0;
        let data = {
          org_id: orgId,
          updated_by: memComId,
          status: status_work,
        };
        try {
          httpClient
            .post('/v2/update/organization/', data)
            .then((response) => {
              if (response.status === 200) {
                openNotificationWithIcon('success', response.data.data);
              } else {
                openNotificationWithIcon('error', response.data.data);
              }
            })
            .catch((error) => {
              openNotificationWithIcon('error', error.message);
            });
        } catch (error) {
          return
        }
      }
    }
  };

  const optionItems = teamType.map((item, index) => {
    return (
      <Option key={index} value={item.team_type_id}>
        {item.name}
      </Option>
    );
  });

  const handleChangeEndDate = (rule, value, callback) => {
    const errors = [];
    if (value && dateStartString) {
      let startDate = moment(dateStartString)._d.valueOf();
      let endDate = value._d.valueOf();
      if (endDate < startDate) {
        errors.push(
          <span>
            {intl.formatMessage({ id: 'orgTabDetailValidateEndDateLess', defaultMessage: 'End date is less than the start date.' })}
          </span>
        );
      }
    }
    callback(errors);
  };

  const LabeRequire = (props) => {
    const { text, req } = props;
    return (
      <span>
        {text}&nbsp;
        {req ? <LabelRequire>*</LabelRequire> : ''}
      </span>
    );
  };


  return (
    <PageSettings.Consumer>
      {({ checkPermissionAction }) => (
        <DivDetail>
          <ConfigProvider locale={locale}>
            <Form className="organization-detail-form" colon={false} hideRequiredMark={true}>
              <Row gutter={[16, 16]} align="middle">
                <Col className="gutter-row" span={24} style={{ textAlign: 'right' }}>
                  <div>
                    {checkPermissionAction('P3PG1C2', 'P3PG1C2A2') ? (
                      <Switch
                        onChange={onSwitchChange}
                        checked={getStatus}
                        defaultChecked={getStatus}
                      />
                    ) : null}

                    {checkPermissionAction('P3PG1C2', 'P3PG1C2A3') ? (
                      <Button01
                        type="primary"
                        fontsize="sm"
                        btnsize="wd_df"
                        onClick={sendTeamDetail}
                      >
                        <FormattedMessage id="btnSave" defaultMessage="Save" />
                      </Button01>
                    ) : null}
                  </div>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col className="gutter-row" span={8}>
                  <Form.Item
                    className="organization-detail-formItem"
                    label={
                      <LabeRequire text={<FormattedMessage id="orgTabDetailLblTeamName" defaultMessage="TEAM NAME" />}
                        req={true}
                      />
                    }
                  >
                    {getFieldDecorator('teamName', {
                      rules: [
                        {
                          required: true,
                          message: intl.formatMessage({ id: 'orgTabDetailRequireTeamName', defaultMessage: 'Please enter team name.' }),
                        },
                        {
                          max: 30,
                          message: intl.formatMessage({ id: 'orgTabDetailRequireTeamNameChar', defaultMessage: 'Team Name cannot be longer than 30 characters.' }),
                        },
                      ],
                    })(
                      <Input
                        placeholder={intl.formatMessage({ id: 'orgTabDetailHintTeamName', defaultMessage: 'Team name' })}
                        className="organization-detail-input"
                        onChange={handleTeamNameChange}
                        autoComplete="off"
                        disabled={checkPermissionAction('P3PG1C2', 'P3PG1C2A3') ? false : true}
                        autoComplete="off"
                      />,
                    )}
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={8} offset={1}>
                  <Form.Item
                    className="organization-detail-formItem"
                    label={
                      <LabeRequire
                        text={<FormattedMessage id="orgTabDetailLblTeamType" defaultMessage="TEAM TYPE" />}
                        req={true}
                      />
                    }
                  >
                    {getFieldDecorator('teamType', {
                      rules: [
                        {
                          required: true,
                          message: intl.formatMessage({ id: 'orgTabDetailRequireTeamType', defaultMessage: 'Please select team type.' }),
                        },
                      ],
                    })(
                      <Select
                        className="organization-detail-select"
                        showSearch
                        placeholder={intl.formatMessage({ id: 'orgTabDetailHintSelectTeamType', defaultMessage: 'Team type' })}
                        onChange={handleSelectTeamType}
                        disabled={checkPermissionAction('P3PG1C2', 'P3PG1C2A3') ? false : true}
                      >
                        {teamType.map((item) => (
                          <Option key={item.team_type_id} value={item.team_type_id}>
                            {item.name}
                          </Option>
                        ))}
                      </Select>,
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col className="gutter-row" span={8}>
                  <Form.Item
                    className="organization-detail-formItem"
                    label={
                      <LabeRequire text={<FormattedMessage id="orgTabDetailLblStartDate" defaultMessage="Start Date" />}
                        req={true}
                      />
                    }
                  >
                    {getFieldDecorator('startDate', {
                      rules: [
                        {
                          required: true,
                          message: intl.formatMessage({ id: 'orgTabDetailRequireStartDate', defaultMessage: 'Please select start date.' }),
                        },
                      ],
                    })(
                      <DatePicker
                        className="organization-detail-datePicker"
                        format={dateFormat}
                        placeholder={intl.formatMessage({ id: 'orgTabDetailLblStartDate', defaultMessage: 'Strat Time' })}
                        onChange={handleDateStart}
                        style={{ width: '100%' }}
                        allowClear={false}
                        disabled={checkPermissionAction('P3PG1C2', 'P3PG1C2A3') ? false : true}

                      />
                    )}
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={8} offset={1}>
                  <Form.Item
                    className="organization-detail-formItem"
                    label={
                      <LabeRequire text={<FormattedMessage id="orgTabDetailLblEndDate" defaultMessage="End Date" />}
                        req={true}
                      />
                    }
                  >
                    {getFieldDecorator('endDate', {
                      rules: [
                        {
                          required: true,
                          message: intl.formatMessage({ id: 'orgTabDetailRequireEndDate', defaultMessage: 'Please select end date.' }),
                        },
                        {
                          validator: handleChangeEndDate,
                        },
                      ],
                    })(
                      // <ConfigProvider locale={locale}>

                      // </ConfigProvider>,
                      <DatePicker
                        // format='DD MMMMM YYYY'
                        className="organization-detail-datePicker"
                        format={dateFormat}
                        placeholder={intl.formatMessage({ id: 'orgTabDetailLblEndDate', defaultMessage: 'End Time' })}
                        onChange={handleDateEnd}
                        style={{ width: '100%' }}
                        allowClear={false}
                        disabled={checkPermissionAction('P3PG1C2', 'P3PG1C2A3') ? false : true}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col className="gutter-row" span={17}>
                  <Form.Item
                    className="organization-detail-formItem"
                    label={
                      <LabeRequire text={<FormattedMessage id="orgTabDetailLblDescription" defaultMessage="Description" />} />
                    }
                  >
                    {getFieldDecorator('description', {
                    })(
                      <Input
                        placeholder={intl.formatMessage({ id: 'orgTabDetailHintDescription', defaultMessage: 'Description' })}
                        autosize={{ minRows: 1, maxRows: 1 }}
                        onChange={handleDescriptionChange}
                        autoComplete="off"
                        disabled={checkPermissionAction('P3PG1C2', 'P3PG1C2A3') ? false : true}
                        autoComplete="off"
                      />,
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </ConfigProvider>
        </DivDetail>
      )}
    </PageSettings.Consumer>
  );
});

const onFieldsChange = (_, changedFiels) => {
  const { username } = changedFiels;
};

const DetailForm = React.memo(Form.create({
  onFieldsChange,
  mapPropsToFields(props) {
    const orgId = props.orgId;
    const valueDefault = props.valueDefault;
    const { name, team_type_id, description, start_date, end_date } = props.data;
    const newForm = props.newForm;

    const teamDetailName = _.get(props, "teamDetail.name");
    const teamDetailTeamTypeId = _.get(props, "teamDetail.team_type_id");
    const teamDetailStartDate = _.get(props, "teamDetail.start_date");
    const teamDetailEndDate = _.get(props, "teamDetail.end_date");
    const teamDetailDescription = _.get(props, "teamDetail.description");

    const defalutTeamName = _.get(valueDefault, 'name');
    const defalutTeamType = _.get(valueDefault, 'team_type_id');
    const defalutStartDate = _.get(valueDefault, 'start_date');
    const defalutEndDate = _.get(valueDefault, 'end_date');
    const defalutDescription = _.get(valueDefault, 'description');

    const teamName = _.isUndefined(orgId) ? '' : name;
    const teamType = _.isUndefined(orgId) ? undefined : team_type_id;
    const descriptions = _.isUndefined(orgId) ? '' : description;
    const startdate = _.isUndefined(orgId) ? '' : start_date;
    const enddate = _.isUndefined(orgId) ? '' : end_date;


    let formatStartDate = null;
    if (teamDetailStartDate !== '') {
      formatStartDate = moment(teamDetailStartDate, 'YYYY-MM-DDTHH:mm:ss.SSSSZ').format('YYYY-MM-DD');
    } else if (teamDetailStartDate == '') {
      formatStartDate = null;
    }

    let formatEndDate = null;
    if (teamDetailEndDate !== '') {
      formatEndDate = moment(teamDetailEndDate, 'YYYY-MM-DDTHH:mm:ss.SSSSZ').format('YYYY-MM-DD')
    } else if (teamDetailEndDate == '') {
      formatEndDate = null;
    }

    if (newForm === true) {
      return {
        teamName: Form.createFormField({ value: defalutTeamName ? defalutTeamName : undefined }),
        teamType: Form.createFormField({ value: defalutTeamType ? defalutTeamType : undefined }),
        startDate: Form.createFormField({ value: defalutStartDate ? moment(defalutStartDate, 'YYYY-MM-DD') : undefined }),
        endDate: Form.createFormField({ value: defalutEndDate ? moment(defalutEndDate, 'YYYY-MM-DD') : undefined }),
        description: Form.createFormField({ value: defalutDescription ? defalutDescription : undefined }),
      };
    } else if (newForm === false) {
      return {
        teamName: Form.createFormField({ value: teamDetailName }),
        teamType: Form.createFormField({ value: teamDetailTeamTypeId }),
        startDate: Form.createFormField({ value: moment(formatStartDate, 'YYYY-MM-DD') }),
        endDate: Form.createFormField({ value: moment(formatEndDate, 'YYYY-MM-DD') }),
        description: Form.createFormField({ value: teamDetailDescription }),
      };
    }
  },
}
)(ValidatedFields));
export default DetailForm;
