import { Button, Popover, Table, Modal, Icon } from 'antd';
import React, { useContext, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import _ from 'lodash';
import { FineContext } from './finecontext';
import { deleteFine, getFinelist } from '../../controllers/fine/fineapi';
import { errorNotification, successNotification } from '../../components/v2/notification';
import { PageSettings } from '../../config/page-settings';

const FineTable = (props) => {
  const { state, setState, fnc } = useContext(FineContext);
  const { isLoading, dataFine, toggle } = state;
  const { setEvent, setIsLoading, setRecord, setSort } = setState;
  const { handleChangePage, handleChangeSize, handleOpenViewModal, handleOpenModal, handleToggle } = fnc;
  const { columns } = props;
  const page = 1;
  const totalItems = _.get(dataFine, 'count');
  const [scrollTableX, setScrollTableX] = useState(1500);
  const [visiblePopoverTable, setVisiblePopoverTable] = useState();
  const [recordPopover, setRecordPopover] = useState();
  const [offsetPopover, setOffsetPopover] = useState([0, 0]);
  // const data = [{ tasktype: 'กาก' }, { tasktype: 'กาก' }, { tasktype: 'กาก' }, { tasktype: 'กาก' }, { tasktype: 'กาก' }];
  const data = _.get(dataFine, 'rows');
  const intl = useIntl();
  const ref = useRef();

  const fncActionTable = (record, offsetPopover, visiblePopoverTable, ref) => {
    return (
      <div>
        <Popover
          placement="right"
          trigger="hover"
          align={{ offset: offsetPopover }}
          visible={visiblePopoverTable}
          zIndex={999}
          content={
            <div style={{ display: 'grid' }}>
              {record !== undefined ? (
                <PageSettings.Consumer>
                  {({ checkPermissionAction }) => (
                    <>
                      {checkPermissionAction(`P51PG1C1`, `P51PG1C1A5`) ? (
                        <>
                          <Button style={{ width: 100 }} type="link" ghost onClick={() => handleOpenViewModal(record)}>
                            <FormattedMessage id="btnView" defaultMessage="View" />
                          </Button>
                        </>
                      ) : null}
                      {checkPermissionAction(`P51PG1C1`, `P51PG1C1A3`) ? (
                        <>
                          <Button style={{ width: 100 }} type="link" ghost onClick={() => handleOpenModal('edit', record)}>
                            <FormattedMessage id="btnEdit" defaultMessage="Edit" />
                          </Button>
                        </>
                      ) : null}
                      {checkPermissionAction(`P51PG1C1`, `P51PG1C1A4`) ? (
                      <>
                        <Button style={{ width: 100 }} type="link" ghost onClick={() => handleDelete(record)}>
                          <FormattedMessage id="btnDelete" defaultMessage="Delete" />
                        </Button>
                      </>
                      ) : null}
                    </>
                  )}
                </PageSettings.Consumer>
              ) : null}
            </div>
          }
        >
          <div style={{ width: 0, height: 0 }} ref={ref}></div>
        </Popover>
      </div>
    );
  };

  const handleDelete = (record) => {
    Modal.confirm({
      className: 'maintenance-report-modal-confirm',
      centered: true,
      icon: null,
      title: intl.formatMessage({ id: 'ConfirmDeleteFine', defaultMessage: 'Are you sure to Delete this ?' }),
      okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
          background: '#6490cf',
          border: '1px solid #6490cf',
          borderRadius: '8px',
          hover: {
            background: '#ffffff',
            color: '#6490cf',
          },
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
          background: '#6490cf',
          border: '1px solid #6490cf',
          borderRadius: '8px',
        },
        type: 'danger',
      },
      async onOk() {
        try {
          const response = await deleteFine(_.get(record, 'fineId'));
          console.log('response', response);
          if (_.get(response.status, 'code') === 200) {
            successNotification(_.get(response.status, 'message'));
            setEvent((current) => !current);
          }
        } catch (error) {
          console.log('errorFine', error.response);
          errorNotification(_.get(error.response, 'status.message'));
        }
      },
      onCancel() {},
    });
  };

  const handleMouseLeave = () => {
    setVisiblePopoverTable(false);
    setRecordPopover();
    setOffsetPopover([0, 0]);
  };

  const showTotal = () => {
    return (
      <span style={{ fontSize: '13px' }}>
        <FormattedMessage id="lblRowPerPage" defaultMessage="Rows per page: " />
      </span>
    );
  };

  const onChangeTable = (value, index, sort) => {
    setSort(sort);
    setEvent((current) => !current);
  };

  return (
    <div style={{ padding: '16px 26px' }} onMouseLeave={handleMouseLeave}>
      <Table
        className="custom-table-claim"
        dataSource={data}
        columns={columns}
        scroll={{ x: scrollTableX }}
        loading={isLoading}
        onChange={onChangeTable}
        pagination={{
          total: totalItems,
          // current: page,
          defaultCurrent: 1,
          pageSizeOptions: ['10', '20', '30', '40', '50'],
          showSizeChanger: true,
          locale: { items_per_page: '' },
          showTotal: showTotal,
          onChange: handleChangePage,
          onShowSizeChange: handleChangeSize,
        }}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              if (!event.target.href) {
                const { x, y } = ref.current.getBoundingClientRect();
                setVisiblePopoverTable(true);
                setOffsetPopover([event.pageX - x, event.pageY - y]);
                setRecordPopover(record);
              }
            },
          };
        }}
      />

      {fncActionTable(recordPopover, offsetPopover, visiblePopoverTable, ref)}

      <div className="total-items-member">
        <span style={{ fontSize: '13px' }}>
          <FormattedMessage id="lblTotal" defaultMessage="Total" />
          {` ${totalItems || 0} `}
          <FormattedMessage id="lblitems" defaultMessage="items" />
        </span>
      </div>
    </div>
  );
};

export default FineTable;
