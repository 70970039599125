import React, { useEffect } from 'react'
import { Select, Checkbox, Table, Form } from 'antd'
import { FormattedMessage, useIntl } from 'react-intl'
import LabeRequireForm from '../../label-required-form'
import _ from 'lodash'

const { Option } = Select

const ListItemDetail = (props) => {
  const { columns, handleClickRow, onChange, vendorData, itemList, form } = props
  const intl = useIntl()
  const { getFieldDecorator, getFieldValue } = form

  useEffect(() => {
    if (getFieldValue('vendor')) {
      onChange(getFieldValue('vendor'), 'vendor')
    }
  }, [getFieldValue('vendor')])

  return (
    <div style={{ height: '625px', padding: "16px" }}>
      <div style={{ marginBottom: '24px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'end' }}>
          <Form form={form} layout="vertical">
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'purchesModalFormVendor', defaultMessage: 'Vendor or Supplier' })} req={false} />
              }
            >
              {getFieldDecorator('vendor', {
              })(
                <Select
                  placeholder={intl.formatMessage({ id: 'purchesModalFormPlaceholderVendor', defaultMessage: 'Select Vendor or Supplier' })}
                  filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  showSearch
                  style={{ width: '400px' }}
                >
                  {vendorData && vendorData.map((item) => <Option key={item.customerId}>{item.customerName}</Option>)}
                </Select>
              )}
            </Form.Item>
          </Form>
          <Checkbox onChange={(e) => onChange(e.target.checked, 'purchase')}><FormattedMessage id="modalListItemDetailRecent" defaultMessage="Recently Purchased Items" /></Checkbox>
        </div>
      </div>
      <div>
        <Table
          columns={columns}
          dataSource={itemList}
          scroll={{ y: 380 }}
          pagination={false}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                if (!event.target.href) {
                  handleClickRow(record)
                }
              },
            };
          }}
        />
      </div>
    </div>
  )
}

export default ListItemDetail