import React, { useState, useEffect } from 'react'
import { Modal, Form, Select, Input } from 'antd'
import Button01 from '../../../../components/v2/button_01'
import Button02 from '../../../../components/v2/button_02'
import { FormattedMessage, useIntl } from 'react-intl'
import _ from 'lodash'
import LabeRequireForm from '../../../../components/label-required-form'
import { getExpenseReason, updateExpenseStatus } from '../../../../controllers/expense/expense'
import { successNotification, errorNotification } from '../../../../components/v2/notification'

const { Option } = Select
const { TextArea } = Input

const ModalExpenseStatus = (props) => {
  const { visible, setVisible, type, form, data, setTrigger, setVisibleMain, setDefaultData, setEditDefault } = props
  const { getFieldDecorator, validateFields, resetFields } = form
  const intl = useIntl()
  const nameType = type === "draft" ? "Draft" : type === "waiting" ? "Waiting" : type === "canceled" ? "Canceled" : type === "rejected" ? "Rejected" : "Approved"
  const [reasonList, setReasonList] = useState([])
  const [disbleButton, setDisbleButton] = useState(false)

  useEffect(() => {
    const getDataDefault = async () => {
      const response = await getExpenseReason();
      setReasonList(_.get(response, 'data.result'))
    };
    getDataDefault();
  }, [])

  const handleConfirmStatus = () => {
    validateFields(async (err, values) => {
      if (err) {
        return;
      }
      try {
        setDisbleButton(true)
        const filterReason = _.filter(reasonList, (item) => { return item.reason === _.get(values, 'reason') })
        const body = {
          "status": {
            "code": type || ""
          },
          "reason": _.get(filterReason, '[0]'),
          "remark": _.get(values, 'remark') || "",
        }
        const response = await updateExpenseStatus(_.get(data, 'expenseId'), body)
        if (response.status === 200) {
          successNotification(_.get(response, 'data.status.message'))
          setVisible(false)
          setVisibleMain(false)
          setTrigger(cur => !cur)
          setDisbleButton(false)
          resetFields();
          setDefaultData()
          setEditDefault()
        } else {
          errorNotification(_.get(response, 'data.status.message'))
          setDisbleButton(false)
        }

      } catch (error) {
        console.log("updateStatus", error)
        setDisbleButton(false)
      }

    })

  }

  const handleCancel = () => {
    setVisible(false)
    resetFields();
  }

  return (
    <Modal
      title={`${nameType}・${_.get(data, 'poNo')}`}
      visible={visible}
      width={370}
      centered={true}
      onCancel={handleCancel}
      footer={[
        <Button02 key="back" btnsize="wd_df" onClick={handleCancel} disabled={!disbleButton > 0 ? false : true}>
          <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
        </Button02 >,
        <Button01 key="submit" type="primary" btnsize="wd_df" onClick={handleConfirmStatus} style={{ margin: 'unset' }} disabled={!disbleButton > 0 ? false : true}>
          <FormattedMessage id="btnSave" defaultMessage="Save" />
        </Button01>
      ]}
    >
      <Form form={form} layout="vertical">
        {
          type === "rejected" || type === "canceled" ? (
            <Form.Item
              label={
                <LabeRequireForm text="Reason" req={true} />
              }
            >
              {getFieldDecorator('reason', {
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'modalRequestStatusFormReasonValidate', defaultMessage: 'Please Select Reason' }),
                  },
                ],
              })(
                <Select
                  placeholder={intl.formatMessage({ id: 'modalRequestStatusFormReasonPlaceholder', defaultMessage: 'Select Reason' })}
                >
                  {
                    _.map(reasonList, (item) => <Option key={item.reason}>{item.reason}</Option>)
                  }
                </Select>
              )}
            </Form.Item>
          ) : null
        }
        <Form.Item
          label={
            <LabeRequireForm text="Remark" req={false} />
          }
        >
          {getFieldDecorator('remark', {
          })(
            <TextArea
              autosize={{ minRows: 3, maxRows: 3 }}
              maxLength={250}
            />
          )}
        </Form.Item>
      </Form>
    </Modal >
  )
}
const ModalExpenseStatusForm = Form.create({
  name: 'global_state',
})(ModalExpenseStatus);

export default ModalExpenseStatusForm