import React, { useContext, useEffect, useState } from 'react';
import FilterMultiple from '../component/filter-multiple';
import AdvanchImage from './advanch-image';
import { OverviewContext } from '../context';

const TabMapOverview = ({ fullscreenPageRef }) => {
  const { state, setState, func } = useContext(OverviewContext);

  const { mapSelectFilter, mapItemDashboard } = state;
  const { setMapSelectFilter } = setState;

  return (
    <div>
      <div>
      <FilterMultiple mapSelectFilter={mapSelectFilter} setMapSelectFilter={setMapSelectFilter}/>
      </div>
      <div style={{marginTop: '24px'}}>
      <AdvanchImage mapSelectFilter={mapSelectFilter} mapItemDashboard={mapItemDashboard} />
      </div>
    </div>
  );
};

export default TabMapOverview;
