import httpClient from '../../components/axiosClient';

const getInitializeMonitorData = async (type) => {

  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.get(`/v4/task/manager/company/${comCode}/monitor/initialize?menuType=${type}`);
    if (response.status == 200) {
      return response;
    } else {
      return response;
    }
  } catch (error) {
    return error.response;
  }

}

const getSearchResource = async (body, type) => {

  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v4/task/manager/company/${comCode}/monitor/resource/${type}`, body);
    if (response.status == 200) {
      return response;
    } else {
      return response;
    }
  } catch (error) {
    return error.response;
  }

}

const getSummary = async (body, type) => {

  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v4/task/manager/company/${comCode}/monitor/summary/${type}`, body);
    if (response.status == 200) {
      return response;
    } else {
      return response;
    }
  } catch (error) {
    return error.response;
  }

}

const getSummaryCount = async (body, type) => {

  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v4/task/manager/company/${comCode}/monitor/sumaryfilter/${type}`, body);
    if (response.status == 200) {
      return response;
    } else {
      return response;
    }
  } catch (error) {
    return error.response;
  }

}

const getDateDefault = () => {
  const data = [
    {
      value: 1,
      label: '1 Days'
    },
    {
      value: 3,
      label: '3 Days'
    },
    {
      value: 5,
      label: '7 Days'
    },
    {
      value: 7,
      label: '7 Days'
    },
    {
      value: 15,
      label: '15 Days'
    },
    {
      value: 30,
      label: '30 Days'
    }
  ]

  return data
}

export { getInitializeMonitorData, getSearchResource, getSummary, getSummaryCount, getDateDefault };