import React, { useContext, useEffect, useState } from 'react';
import { Form, Row, Col, Input, Divider, Icon, Select, DatePicker, Avatar, Modal, AutoComplete } from 'antd';
import '../css/information.css';
import _, { debounce } from 'lodash';
import { TaskDetail } from '../../task-detail-context';
import { getTaskInfo } from '../../../../../../controllers/task/task-detail';
import NavigatorSVG from '../../../../../image/navigator.svg';
import ArrowSVG from '../../../../../image/arrow_location.svg';
import {
  getDisTance,
  getCustomeField,
  getTaskTypeData,
  getCustomerLocationAndConatact,
  getRefAuto,
} from '../../../../../../controllers/task/create-task';
import { useIntl, FormattedMessage } from 'react-intl';
import AddContact from '../../../../../add-contact-v2/index';
import AddFromLocation from '../../../../../from-location/add/index';
import EditFromLocation from '../../../../../from-location/edit/index';
import AddAddressBookV2 from '../../../../../modal-create-adress-map-v2/index';
import EditAddressBookV2 from '../../../../../modal-edit-adress-map-v2/index';
import moment from 'moment';
import { useAppContext } from '../../../../../../includes/indexProvider';
import { successNotification, errorNotification } from '../../../../../v2/notification';
import { addContact } from '../../../../../../controllers/task/create-task';
import CompanyLocation from '../../../../../modal-select-company-location/index';
import CustomerLocation from '../../../../../modal-select-customer-location/index';
import { v4 as uuidv4 } from 'uuid';
import TeamTab from '../team/index';

const { Option } = Select;

export default (props) => {
  const { form } = props;
  const { getFieldDecorator, getFieldValue, setFieldsValue, validateFields } = form;
  const { stateTask, setStateTask, fncStateTask } = useContext(TaskDetail);
  const intl = useIntl();
  const app = useAppContext();
  const langValueState = _.get(app, 'state.langValue');
  moment.locale(langValueState);
  const format = langValueState == 'EN' ? 'YYYY-MM-DD h:mm A' : 'YYYY-MM-DD HH:mm';
  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    const getDefaultData = async () => {
      if ((_.get(stateTask, 'visible') === true || _.get(stateTask, 'visibleResource') === true) && _.get(stateTask, 'taskId')) {
        setStateTask.setLoading(true);
        const responseInfo = await getTaskInfo(_.get(stateTask, 'taskId'));
        if (responseInfo.status === 200) {
          setStateTask.setViewInformationData(responseInfo.data);
          setStateTask.setSelectTeam(parseFloat(responseInfo.data.information.orgId));
          setStateTask.setStatusTask(responseInfo.data.information.statusName);
          setStateTask.setAssignee(responseInfo.data.assignee);
          const bodyLatlng = {
            fromLat: _.get(responseInfo, 'data.address.fromlat'),
            fromLng: _.get(responseInfo, 'data.address.fromlng'),
            toLat: _.get(responseInfo, 'data.address.lat'),
            toLng: _.get(responseInfo, 'data.address.lng'),
          };

          // if (_.get(responseInfo, 'data.address.fromAddressId') === null || _.get(responseInfo, 'data.address.fromAddressId')) {
          //   const uuid = uuidv4()
          //   const mapData = {
          //     address: _.get(responseInfo, 'data.address.fromLocation'),
          //     addressBookId: uuid,
          //     lat: _.get(responseInfo, 'data.address.fromlat'),
          //     lng: _.get(responseInfo, 'data.address.fromlng'),
          //     name: _.get(responseInfo, 'data.address.fromLocationName'),
          //     status: _.get(responseInfo, 'data.address.fromAddressId') === null ? "normal_address" : "task_address"
          //   }
          //   const latlngFrom = {
          //     lat: _.get(responseInfo, 'data.address.fromlat'),
          //     lng: _.get(responseInfo, 'data.address.fromlng'),
          //   }
          //   setStateTask.setFromLocationData([mapData])
          //   setStateTask.setSelectFromAddress([mapData])
          //   setStateTask.setCreateFromData(mapData)
          //   setStateTask.setKeyMenu([uuid])
          //   setStateTask.setCenterFrom(latlngFrom)
          //   setStateTask.setPositionFrom(latlngFrom)
          //   setStateTask.setVisibleInfoWindowFrom(true)
          // }
          // else {
          //   const mapData = {
          //     address: _.get(responseInfo, 'data.address.fromLocation'),
          //     addressBookId: _.get(responseInfo, 'data.address.fromAddressId'),
          //     lat: _.get(responseInfo, 'data.address.fromlat'),
          //     lng: _.get(responseInfo, 'data.address.fromlng'),
          //     name: _.get(responseInfo, 'data.address.fromLocationName'),
          //     status: "customer_address"
          //   }
          //   const latlngFrom = {
          //     lat: _.get(responseInfo, 'data.address.fromlat'),
          //     lng: _.get(responseInfo, 'data.address.fromlng'),
          //   }
          //   // setStateTask.setFromLocationData([mapData])
          //   setStateTask.setSelectFromAddress([mapData])
          //   setStateTask.setCreateFromData(mapData)
          //   setStateTask.setKeyMenu([_.get(responseInfo, 'data.address.fromAddressId')])
          //   setStateTask.setCenterFrom(latlngFrom)
          //   setStateTask.setPositionFrom(latlngFrom)
          // }

          if (_.get(responseInfo, 'data.address.toLocationId') === null || _.get(responseInfo, 'data.address.toLocationId')) {
            const uuid = uuidv4();
            const mapData = {
              address: _.get(responseInfo, 'data.address.toLocation'),
              addressBookId: uuid,
              lat: _.get(responseInfo, 'data.address.lat'),
              lng: _.get(responseInfo, 'data.address.lng'),
              name: _.get(responseInfo, 'data.address.toLocationName'),
              status: _.get(responseInfo, 'data.address.toLocationId') === null ? 'normal_address' : 'task_address',
            };
            const latlngTo = {
              lat: _.get(responseInfo, 'data.address.lat'),
              lng: _.get(responseInfo, 'data.address.lng'),
            };
            setStateTask.setToLocationData([mapData]);
            setStateTask.setSelectToAddress([mapData]);
            setStateTask.setCreateToData(mapData);
            setStateTask.setKeyMenuTo([uuid]);
            setStateTask.setCenterTo(latlngTo);
            setStateTask.setPositionTo(latlngTo);
            setStateTask.setVisibleInfoWindowTo(true);
          }
          // else {
          //   const mapData = {
          //     address: _.get(responseInfo, 'data.address.toLocation'),
          //     addressBookId: _.get(responseInfo, 'data.address.toAddressId'),
          //     lat: _.get(responseInfo, 'data.address.lat'),
          //     lng: _.get(responseInfo, 'data.address.lng'),
          //     name: _.get(responseInfo, 'data.address.toLocationName'),
          //     status: "customer_address"
          //   }
          //   const latlngTo = {
          //     lat: _.get(responseInfo, 'data.address.lat'),
          //     lng: _.get(responseInfo, 'data.address.lng'),
          //   }
          //   // setStateTask.setToLocationData([mapData])
          //   setStateTask.setSelectToAddress([mapData])
          //   setStateTask.setCreateToData(mapData)
          //   setStateTask.setKeyMenuTo([_.get(responseInfo, 'data.address.toAddressId')])
          //   setStateTask.setCenterTo(latlngTo)
          //   setStateTask.setPositionTo(latlngTo)
          // }

          const responseDistance = await getDisTance(bodyLatlng, 'workforce');
          const responseCustome = await getCustomeField('task_type', _.get(responseInfo, 'data.information.taskTypeId'), 'workforce');
          Promise.all([responseDistance, responseCustome]).then((value) => {
            setStateTask.setDisTance(_.get(value, '[0]data.data.distance'));
            setStateTask.setCustomFieldData(_.get(value, '[1]data'));
          });
          setStateTask.setLoading(false);
        }
      }
    };
    getDefaultData();
  }, [
    _.get(stateTask, 'taskId'),
    _.get(stateTask, 'visible'),
    _.get(stateTask, 'triggerInformation'),
    _.get(stateTask, 'visibleResource'),
    _.get(stateTask, 'triggerApi'),
    ,
    _.get(stateTask, 'triggerTaskDetail'),
  ]);

  useEffect(() => {
    const getDefaultDataInfor = async () => {
      const responseTaskType = await getTaskTypeData('workforce');
      setStateTask.setPriorityData(_.get(responseTaskType, 'data.priority'));
    };

    getDefaultDataInfor();
  }, [stateTask.taskId]);

  useEffect(() => {
    const getContactAndLocation = async () => {
      const customerId = _.get(stateTask, 'viewInformationData.information.customer_id');
      if (customerId && (_.get(stateTask, 'visible') === true || _.get(stateTask, 'visibleResource') === true)) {
        const responseContact = await getCustomerLocationAndConatact(customerId, 'transportation');

        const temporary = [
          {
            customerContactId:
              _.get(stateTask, 'viewInformationData.information.contact[0].entity_ref') !== null
                ? _.get(stateTask, 'viewInformationData.information.contact[0].entity_ref')
                : 'temporary',
            customerContactName: _.get(stateTask, 'viewInformationData.information.contact[0].contact_name'),
            customerContactEmail: _.get(stateTask, 'viewInformationData.information.contact[0].contact_email'),
            customerContactPhone: _.get(stateTask, 'viewInformationData.information.contact[0].contact_phone'),
            position: _.get(stateTask, 'viewInformationData.information.contact[0].position'),
            remark: _.get(stateTask, 'viewInformationData.information.contact[0].remark'),
            status: _.get(stateTask, 'viewInformationData.information.contact[0].entity_ref') === null ? 'task_temp' : undefined,
          },
        ];

        const concatData =
          _.size(stateTask.viewInformationData.information.contact) !== 0
            ? temporary.concat(_.get(responseContact, 'data.data.contactCustomer'))
            : _.get(responseContact, 'data.data.contactCustomer');
        const uniqBy = _.uniqBy(concatData, 'customerContactId');

        setStateTask.setContactData(uniqBy);
        const customerContactId = _.get(stateTask, 'viewInformationData.information.contact[0].entity_ref') || '';
        if (_.size(stateTask.viewInformationData.information.contact) !== 0) {
          if (customerContactId === '') {
            setStateTask.setStatusSave(true);
          } else {
            setStateTask.setStatusSave(false);
          }
        }
      }
    };
    getContactAndLocation();
  }, [
    _.get(stateTask, 'viewInformationData.information.customer_id'),
    _.get(stateTask, 'triggerContactAndLocation'),
    _.get(stateTask, 'taskId'),
    _.get(stateTask, 'viewInformationData.information.contact[0].contact_id'),
    _.get(stateTask, 'statusView'),
  ]);
  const NavigatorIcon = () => <img src={NavigatorSVG} style={{ marginBottom: '10px', marginLeft: '3.4px' }}></img>;
  const ArrowIcon = () => <img src={ArrowSVG} style={{ width: '32px' }}></img>;

  const LabeRequire = (props) => {
    const { text, req } = props;
    return (
      <span>
        {text}&nbsp;
        {req ? <span style={{ fontSize: '12px', color: '#ff0000' }}>*</span> : ''}
      </span>
    );
  };

  const NavigatorClick = async () => {
    if (_.get(stateTask, 'createToData')) {
      window.open(
        `https://www.google.com/maps/search/?api=1&query=${parseFloat(_.get(stateTask, 'createToData.lat'))}, ${parseFloat(
          _.get(stateTask, 'createToData.lng')
        )}`,
        '_blank'
      );
    }
  };

  const CustomToField = (props) => {
    const { taskDetail, taskCustomField } = props;
    const customFieldData = _.get(taskCustomField, 'customFieldData');
    return _.size(customFieldData)
      ? customFieldData.map((o) => (
          <Col span={8} className="information-padding-label">
            <p className="task-detail-information-form-item-ref">
              <FormattedMessage key={o.titleCode} id={o.titleCode} defaultMessage={o.defaultTitle} />
            </p>
            <span className="task-detail-information-text-value">{_.get(taskDetail, o.entityToField) || '-'}</span>
          </Col>
        ))
      : null;
  };

  getFieldDecorator('keys', { initialValue: [] });
  const formItems =
    stateTask.customFieldData &&
    stateTask.customFieldData.map((k, index) => {
      const information = _.get(stateTask, 'viewInformationData.information');
      return (
        <div>
          {
            <Col span={8}>
              <Form.Item
                key={k.entityToField}
                className="task-detail-information-form-item"
                label={
                  <LabeRequire
                    text={intl.formatMessage({
                      id: `${k.titleCode}`,
                      defaultMessage: `${k.defaultTitle}`,
                    })}
                    req={false}
                  />
                }
              >
                {getFieldDecorator(`${k.entityToField}`, {
                  initialValue: _.get(information, k.entityToField) || '-',
                  rules: [
                    {
                      required: k.required && k.required !== null ? true : false,
                      whitespace: true,
                      message: 'Please input reference',
                    },
                  ],
                })(<Input placeholder="Enter Reference" autoComplete="off" />)}
              </Form.Item>
            </Col>
          }
        </div>
      );
    });

  // useEffect(() => {
  //   const getDistancePolypline = async () => {
  //     if (_.get(stateTask, 'createFromData') && _.get(stateTask, 'createToData')) {
  //       const bodyLatlng = {
  //         fromLat: _.get(stateTask, 'createFromData.lat'),
  //         fromLng: _.get(stateTask, 'createFromData.lng'),
  //         toLat: _.get(stateTask, 'createToData.lat'),
  //         toLng: _.get(stateTask, 'createToData.lng'),
  //       }
  //       const responseDistance = await getDisTance(bodyLatlng, 'workforce')
  //       setStateTask.setDisTance(_.get(responseDistance, 'data.data.distance'))
  //     }
  //   }

  //   getDistancePolypline();

  // }, [_.get(stateTask, 'createFromData.addressBookId'), _.get(stateTask, 'createToData.addressBookId')]);

  useEffect(() => {
    if (_.get(stateTask, 'newContact') && _.get(stateTask, 'statusView')) {
      if (_.size(stateTask.temporaryData) !== 0) {
        const slice = _.get(stateTask, 'contactData').slice(1);
        if (_.get(stateTask, 'statusSave') === true) {
          const concatData = _.get(stateTask, 'temporaryData').concat(slice);
          setStateTask.setContactData(concatData);
          setTrigger((current) => !current);
        } else {
          const filterTemp = _.get(stateTask, 'contactData').filter((item) => item.status !== 'contact_temp');
          const concatData = _.get(stateTask, 'temporaryData').concat(filterTemp);
          setStateTask.setContactData(concatData);
          setTrigger((current) => !current);
        }
      }

      setTimeout(() => {
        setFieldsValue({
          ['contact']: _.get(stateTask, 'newContact'),
        });
      }, 1000);
    }
  }, [_.get(stateTask, 'newContact'), _.get(stateTask, 'temporaryData[0].uuid')]);

  useEffect(() => {
    const contactId = getFieldValue('contact');
    if (contactId && _.get(stateTask, 'statusView')) {
      const filterData =
        _.get(stateTask, 'contactData') !== undefined &&
        _.get(stateTask, 'contactData').filter((item) => {
          return item.customerContactId === contactId;
        });

      setStateTask.setStatusSave(_.get(filterData, '[0].status') ? true : false);
      setFieldsValue({
        ['phone']: _.get(filterData, '[0].customerContactPhone'),
      });
    }
  }, [getFieldValue('contact'), trigger]);

  useEffect(() => {
    if (_.get(stateTask, 'newCustomerAddress') && _.get(stateTask, 'statusView')) {
      setTimeout(() => {
        setFieldsValue({
          ['toLocation']: _.get(stateTask, 'newCustomerAddress.customerAddressBookId'),
        });
      }, 1000);
    }
  }, [_.get(stateTask, 'newCustomerAddress.customerAddressBookId')]);

  const validatorStartDate = (rule, value, callback) => {
    const startDate = value;
    const dueDate = getFieldValue(`dueDate`);
    const formateStartDate = moment(startDate, 'YYYY-MM-DD HH:mm:ss').toString();
    const formateDueDate = moment(dueDate, 'YYYY-MM-DD HH:mm:ss').toString();

    if (startDate && dueDate) {
      if (startDate > dueDate) {
        callback(
          <FormattedMessage id="monitorTabDetailModalDateTimeValidatorDueDate" defaultMessage="Start date should not less than due date" />
        );
      } else {
        if (formateStartDate === formateDueDate) {
          callback(
            <FormattedMessage id="monitorTabDetailModalDateTimeValidatorStartTime2" defaultMessage="Cannot select the same date and time" />
          );
        } else {
          callback();
        }
      }
    } else {
      callback();
    }
  };

  const validatorDueDate = (rule, value, callback) => {
    const startDate = getFieldValue(`startDate`);
    const dueDate = value;
    const formateStartDate = moment(startDate, 'YYYY-MM-DD HH:mm:ss').toString();
    const formateDueDate = moment(dueDate, 'YYYY-MM-DD HH:mm:ss').toString();

    if (startDate && dueDate) {
      if (startDate > dueDate) {
        callback(
          <FormattedMessage id="monitorTabDetailModalDateTimeValidatorDueDate" defaultMessage="Start date should not less than due date" />
        );
      } else {
        if (formateStartDate === formateDueDate) {
          callback(
            <FormattedMessage id="monitorTabDetailModalDateTimeValidatorStartTime2" defaultMessage="Cannot select the same date and time" />
          );
        } else {
          callback();
        }
      }
    } else {
      callback();
    }
  };

  const handleSaveContact = () => {
    Modal.confirm({
      className: 'customer-modal-confirm',
      centered: true,
      icon: null,
      title: intl.formatMessage({
        id: `monitorCreateTaskModalConfirmCustomerContact`,
        defaultMessage: 'Are you sure to save customer contact.',
      }),
      okText: intl.formatMessage({ id: 'modalBtnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'modalBtnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        const contactId = getFieldValue('contact');
        const phone = getFieldValue('phone');
        const filterData =
          stateTask.contactData &&
          stateTask.contactData.filter((item) => {
            return item.customerContactId === contactId;
          });
        const body = {
          customerId: _.get(stateTask, 'viewInformationData.information.customer_id'),
          customerContactName: _.get(filterData, '[0].customerContactName'),
          customerContactEmail: _.get(filterData, '[0].customerContactEmail'),
          customerContactPhone: phone,
          position: _.get(filterData, '[0].position'),
          remark: _.get(filterData, '[0].remark'),
        };
        const response = await addContact(body);
        if (response.status === 200) {
          successNotification(response.data.status.message);
          setStateTask.setTriggerContactAndLocation((current) => !current);
          setStateTask.setNewConatact(response.data.data.customerContactId);
        } else {
          errorNotification(response.data.status.message);
        }
      },
      onCancel() {},
    });
  };

  const getValueItemCode = debounce(async (fieldChang) => {
    if (fieldChang !== '') {
      const body = {
        entityReference: fieldChang,
      };
      const response = await getRefAuto(body);
      setStateTask.setRefData(_.get(response, 'data'));
    }
  }, 200);

  const childrenRef =
    stateTask.refData &&
    stateTask.refData.map((item) => (
      <Option key={item.referenceNo} label={item.referenceNo}>
        {item.referenceNo} · {item.referenceName}
      </Option>
    ));

  return (
    <div className="task-detail-information-div-padding">
      <Form>
        {stateTask.statusView ? (
          <Row>
            <Row gutter={[24, 24]}>
              <Col span={8}>
                <Form.Item
                  className="task-detail-information-form-item"
                  label={
                    <LabeRequire
                      text={intl.formatMessage({ id: `taskDetailInformationTextTaskNo`, defaultMessage: 'Task No' })}
                      req={false}
                    />
                  }
                >
                  {getFieldDecorator('taskNo', {
                    initialValue: _.get(stateTask, 'viewInformationData.information.taskNo'),
                    rules: [
                      {
                        required: false,
                        message: intl.formatMessage({ id: `taskDetailInformationTextTaskNo`, defaultMessage: 'Task No' }),
                      },
                    ],
                  })(
                    <Input
                      className="task-detail-information-input-style"
                      placeholder={intl.formatMessage({ id: `taskDetailInformationPlaceHolderTaskNo`, defaultMessage: 'Task No' })}
                      readOnly={true}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={16}>
                <Form.Item
                  className="task-detail-information-form-item"
                  label={
                    <LabeRequire
                      text={intl.formatMessage({ id: `taskDetailInformationTextTaskName`, defaultMessage: 'Task Name' })}
                      req={true}
                    />
                  }
                >
                  {getFieldDecorator('taskName', {
                    initialValue: _.get(stateTask, 'viewInformationData.information.taskName'),
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({
                          id: `taskDetailInformationValidateTaskName`,
                          defaultMessage: 'Please Input Task Name',
                        }),
                      },
                    ],
                  })(
                    <Input
                      className="task-detail-information-input-style"
                      placeholder={intl.formatMessage({
                        id: `taskDetailInformationPlaceHolderTaskName`,
                        defaultMessage: 'Enter Task Name',
                      })}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24]}>
              <Col span={8} style={{ paddingTop: '7px' }}>
                <TeamTab />
              </Col>
              <Col span={8}>
                <Form.Item
                  className="task-detail-information-form-item"
                  label={
                    <LabeRequire
                      text={intl.formatMessage({ id: `taskDetailInformationTextTaskType`, defaultMessage: 'Task Type' })}
                      req={true}
                    />
                  }
                >
                  {getFieldDecorator('taskType', {
                    initialValue: _.get(stateTask, 'viewInformationData.information.taskTypeName'),
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({
                          id: `taskDetailInformationValidateTaskType`,
                          defaultMessage: 'Please Select Task Type',
                        }),
                      },
                    ],
                  })(
                    <Input
                      className="task-detail-information-input-style"
                      placeholder={intl.formatMessage({ id: `taskDetailInformationPlaceHolderTaskType`, defaultMessage: 'Task Type' })}
                      readOnly={true}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  className="task-detail-information-form-item"
                  label={
                    <LabeRequire
                      text={intl.formatMessage({ id: `taskDetailInformationTextPriority`, defaultMessage: 'Priority' })}
                      req={true}
                    />
                  }
                >
                  {getFieldDecorator('priority', {
                    initialValue: _.get(stateTask, 'viewInformationData.information.priorityId'),
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({
                          id: `taskDetailInformationValidatePriority`,
                          defaultMessage: 'Please Select Priority',
                        }),
                      },
                    ],
                  })(
                    <Select
                      showSearch
                      filterOption={(input, option) =>
                        option.props.children
                          .toString()
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      placeholder={intl.formatMessage({
                        id: `taskDetailInformationPlaceHolderPriority`,
                        defaultMessage: 'Select Priority',
                      })}
                    >
                      {stateTask.priorityData &&
                        stateTask.priorityData.map((item) => (
                          <Option key={item.priorityId} value={item.priorityId}>
                            <img src={item.icon} style={{ width: '7px', height: '7px' }}></img> {item.name}
                          </Option>
                        ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24, 24]}>
              <Col span={8}>
                <Form.Item
                  className="task-detail-information-form-item"
                  label={
                    <LabeRequire
                      text={intl.formatMessage({ id: `taskDetailInformationTextCustomer`, defaultMessage: 'Customer' })}
                      req={true}
                    />
                  }
                >
                  {getFieldDecorator('customer', {
                    initialValue: _.get(stateTask, 'viewInformationData.information.customer'),
                    rules: [
                      {
                        required: false,
                        message: intl.formatMessage({
                          id: `taskDetailInformationValidateCustomer`,
                          defaultMessage: 'Please Select Customer',
                        }),
                      },
                    ],
                  })(
                    <Input
                      className="task-detail-information-input-style"
                      placeholder={intl.formatMessage({ id: `taskDetailInformationPlaceHolderCustomer`, defaultMessage: 'Customer' })}
                      readOnly={true}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  className="task-detail-information-form-item"
                  label={
                    <LabeRequire
                      text={intl.formatMessage({ id: `taskDetailInformationTextContact`, defaultMessage: 'Contact' })}
                      req={true}
                    />
                  }
                >
                  {getFieldDecorator('contact', {
                    initialValue:
                      _.get(stateTask, 'viewInformationData.information.contact[0].entity_ref') !== null
                        ? _.get(stateTask, 'viewInformationData.information.contact[0].entity_ref')
                        : 'temporary',
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({
                          id: `taskDetailInformationValidateContact`,
                          defaultMessage: 'Please Select Contact',
                        }),
                      },
                    ],
                  })(
                    <Select
                      placeholder={intl.formatMessage({ id: `taskDetailInformationPlaceHolderContact`, defaultMessage: 'Select Contact' })}
                      showSearch
                      filterOption={(input, option) =>
                        option.props.children
                          .toString()
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      optionLabelProp="label"
                      dropdownRender={(menu) => (
                        <div>
                          {menu}
                          <Divider style={{ margin: '4px 0' }} />
                          <div
                            style={{ padding: '4px 8px', cursor: 'pointer' }}
                            onMouseDown={(e) => e.preventDefault()}
                            onClick={() => {
                              setStateTask.setVisibleAddContact(true);
                            }}
                          >
                            <Icon type="plus" className="customer-icon-plus" />
                            <span className="text-add-customer">
                              <FormattedMessage id="btnAdd" defaultMessage="Add" />{' '}
                              <FormattedMessage id="customerContactCardHeader" defaultMessage="Contact" />
                            </span>
                          </div>
                        </div>
                      )}
                    >
                      {stateTask.contactData &&
                        stateTask.contactData.map((item) => (
                          <Option key={item.customerContactId} value={item.customerContactId} label={item.customerContactName}>
                            <Row>
                              <Col span={6}>
                                <Avatar size="large" icon="user" />
                              </Col>
                              <Col span={18}>
                                <div style={{ fontSize: '13px', color: '#1D3557' }}>{item.customerContactName}</div>
                                <div style={{ fontSize: '11px', color: '#1D3557' }}>
                                  {item.position} · {item.customerContactPhone}
                                </div>
                              </Col>
                            </Row>
                          </Option>
                        ))}
                    </Select>
                  )}
                  {stateTask.statusSave ? (
                    <div className="create-task-status-save" onClick={handleSaveContact}>
                      <FormattedMessage id="monitorTabDetailSaveContactCustomer" defaultMessage="Save to Customer Contact" />
                    </div>
                  ) : null}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  className="task-detail-information-form-item"
                  label={
                    <LabeRequire text={intl.formatMessage({ id: `taskDetailInformationTextPhone`, defaultMessage: 'Phone' })} req={false} />
                  }
                >
                  {getFieldDecorator('phone', {
                    initialValue: _.get(stateTask, 'viewInformationData.information.contact[0].contact_phone'),
                    rules: [
                      {
                        required: false,
                        message: intl.formatMessage({ id: `taskDetailInformationValidatePhone`, defaultMessage: 'Please Input Phone' }),
                      },
                    ],
                  })(
                    <Input
                      className="task-detail-information-input-style"
                      placeholder={intl.formatMessage({ id: `taskDetailInformationPlaceHolderPhone`, defaultMessage: 'Phone' })}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24]}>
              <Col span={8}>
                <Form.Item
                  className="task-detail-information-form-item"
                  label={
                    <LabeRequire
                      text={intl.formatMessage({ id: `taskDetailInformationTextStartDate`, defaultMessage: 'Start Date' })}
                      req={false}
                    />
                  }
                >
                  {getFieldDecorator('startDate', {
                    initialValue:
                      _.get(stateTask, 'viewInformationData.information.startDate') !== '' &&
                      _.get(stateTask, 'viewInformationData.information.durationStart') !== ''
                        ? moment(
                            _.get(stateTask, 'viewInformationData.information.startDate').concat(
                              ' ',
                              _.get(stateTask, 'viewInformationData.information.durationStart')
                            ),
                            format
                          )
                        : undefined,
                    rules: [
                      {
                        required: getFieldValue('dueDate') ? true : false,
                        message: intl.formatMessage({
                          id: `taskDetailInformationValidateStartDate`,
                          defaultMessage: 'Please Select Start Date Time',
                        }),
                      },
                      {
                        validator: validatorStartDate,
                      },
                    ],
                  })(
                    <DatePicker
                      showTime={{ format: 'HH:mm' }}
                      format="YYYY-MM-DD HH:mm"
                      placeholder={intl.formatMessage({
                        id: `taskDetailInformationPlaceHolderStartDate`,
                        defaultMessage: 'Select Start Date Time',
                      })}
                      style={{ width: '100%' }}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  className="task-detail-information-form-item"
                  label={
                    <LabeRequire
                      text={intl.formatMessage({ id: `taskDetailInformationTextDueDate`, defaultMessage: 'Due Date' })}
                      req={false}
                    />
                  }
                >
                  {getFieldDecorator('dueDate', {
                    initialValue:
                      _.get(stateTask, 'viewInformationData.information.dueDate') !== '' &&
                      _.get(stateTask, 'viewInformationData.information.durationFinish') !== ''
                        ? moment(
                            _.get(stateTask, 'viewInformationData.information.dueDate').concat(
                              ' ',
                              _.get(stateTask, 'viewInformationData.information.durationFinish')
                            ),
                            format
                          )
                        : undefined,
                    rules: [
                      {
                        required: getFieldValue('startDate') ? true : false,
                        message: intl.formatMessage({
                          id: `taskDetailInformationValidateDueDate`,
                          defaultMessage: 'Please Select Due Date Time',
                        }),
                      },
                      {
                        validator: validatorDueDate,
                      },
                    ],
                  })(
                    <DatePicker
                      showTime={{ format: 'HH:mm' }}
                      format="YYYY-MM-DD HH:mm"
                      placeholder={intl.formatMessage({
                        id: `taskDetailInformationPlaceHolderDueDate`,
                        defaultMessage: 'Select Due Date Time',
                      })}
                      style={{ width: '100%' }}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  className="task-detail-information-form-item"
                  label={
                    <LabeRequire
                      text={intl.formatMessage({ id: `taskDetailInformationTextReference`, defaultMessage: 'Reference' })}
                      req={false}
                    />
                  }
                >
                  {getFieldDecorator('reference', {
                    initialValue: _.get(stateTask, 'viewInformationData.information.reference'),
                    rules: [
                      {
                        required: false,
                        message: intl.formatMessage({
                          id: `taskDetailInformationValidateReference`,
                          defaultMessage: 'Please Input Reference',
                        }),
                      },
                    ],
                  })(
                    <AutoComplete
                      placeholder={intl.formatMessage({ id: 'createTaskMntLBReference', defaultMessage: 'Reference' })}
                      dataSource={stateTask.refData}
                      filterOption={false}
                      style={{ width: '100%' }}
                      onSearch={getValueItemCode}
                      optionLabelProp="label"
                    >
                      {childrenRef}
                    </AutoComplete>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24, 24]}>{formItems}</Row>
            <Row gutter={_.size(stateTask.customFieldData) > 0 ? [24] : [24, 24]}>
              <Col span={24}>
                <Form.Item
                  className="task-detail-information-form-item"
                  label={
                    <LabeRequire
                      text={intl.formatMessage({ id: `taskDetailInformationTextRemark`, defaultMessage: 'Remark' })}
                      req={false}
                    />
                  }
                >
                  {getFieldDecorator('remark', {
                    initialValue: _.get(stateTask, 'viewInformationData.information.remark'),
                  })(<Input className="task-detail-tab-information-input-style" />)}
                </Form.Item>
              </Col>
            </Row>
            <Divider type="horizontal" />
            <Row>
              <div style={{ paddingBottom: '5px' }}>
                <span className="task-detail-information-text-address">
                  <FormattedMessage id="taskDetailInformationTextAddress" defaultMessage="Address" />
                </span>
                <Icon component={NavigatorIcon} className="task-detail-information-text-icon" onClick={NavigatorClick} />
              </div>
              <Row>
                <Col span={10}>
                  <Form.Item
                    className="task-detail-information-form-item"
                    label={
                      <LabeRequire
                        text={intl.formatMessage({ id: `taskDetailInformationTextDestination`, defaultMessage: 'Destination' })}
                        req={true}
                      />
                    }
                  >
                    <Row>
                      <Col span={1}>
                        <Icon type="environment" style={{ fontSize: '12px', color: '#1D3557', marginRight: '5px' }} />
                      </Col>
                      <Col span={22}>
                        {_.get(stateTask, 'createToData') ? (
                          <div>
                            <div
                              onClick={() => setStateTask.setVisibleAddCustomerAddress(true)}
                              style={{ cursor: 'pointer', marginLeft: '11px' }}
                            >
                              <span className="task-detail-information-text-address-value">{`${
                                _.get(stateTask, 'createToData.name') !== null ? _.get(stateTask, 'createToData.name') : ''
                              } ${
                                _.get(stateTask, 'createToData.status') === 'normal_address'
                                  ? `(${intl.formatMessage({ id: 'unsaveText', defaultMessage: 'Unsaved' })})`
                                  : ''
                              } · ${_.get(stateTask, 'createToData.address')}`}</span>
                            </div>
                            {_.get(stateTask, 'createToData.status') === 'normal_address' ? (
                              <div
                                onClick={fncStateTask.saveToAddress}
                                className="create-task-status-save"
                                style={{ cursor: 'pointer', marginLeft: '11px' }}
                              >
                                <FormattedMessage id="monitorTabDetailSaveAddressCustomer" defaultMessage="Save to Customer Address Book" />
                              </div>
                            ) : null}
                          </div>
                        ) : (
                          <div>
                            {getFieldDecorator('toLocation', {
                              rules: [
                                {
                                  required: true,
                                  message: intl.formatMessage({
                                    id: `taskDetailInformationValidateDestination`,
                                    defaultMessage: 'Please Select Destination',
                                  }),
                                },
                              ],
                            })(
                              <Input
                                readOnly={true}
                                placeholder={intl.formatMessage({
                                  id: 'createTaskMntPHDestination',
                                  defaultMessage: 'Select a Destination Location',
                                })}
                                onClick={() => (getFieldValue('customer') ? setStateTask.setVisibleAddCustomerAddress(true) : null)}
                                style={
                                  getFieldValue('customer')
                                    ? { border: 'unset', cursor: 'pointer' }
                                    : { border: 'unset', cursor: 'not-allowed' }
                                }
                              />
                            )}
                          </div>
                        )}
                      </Col>
                    </Row>
                  </Form.Item>
                </Col>
              </Row>
            </Row>
          </Row>
        ) : (
          <Row>
            <Row gutter={[24, 24]}>
              <Col span={8}>
                <Form.Item
                  className="task-detail-information-form-item"
                  label={
                    <LabeRequire
                      text={intl.formatMessage({ id: `taskDetailInformationTextTaskNo`, defaultMessage: 'Task No' })}
                      req={false}
                    />
                  }
                >
                  <span className="task-detail-information-text-value">{_.get(stateTask, 'viewInformationData.information.taskNo')}</span>
                </Form.Item>
              </Col>
              <Col span={16}>
                <Form.Item
                  className="task-detail-information-form-item"
                  label={
                    <LabeRequire
                      text={intl.formatMessage({ id: `taskDetailInformationTextTaskName`, defaultMessage: 'Task Name' })}
                      req={false}
                    />
                  }
                >
                  <span className="task-detail-information-text-value">{_.get(stateTask, 'viewInformationData.information.taskName')}</span>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24]}>
              <Col span={8}>
                <Form.Item
                  className="task-detail-information-form-item"
                  label={<LabeRequire text={intl.formatMessage({ id: `taskDetailTeamText`, defaultMessage: 'Team' })} req={false} />}
                >
                  <span className="task-detail-information-text-value">{_.get(stateTask, 'viewInformationData.information.orgName')}</span>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  className="task-detail-information-form-item"
                  label={
                    <LabeRequire
                      text={intl.formatMessage({ id: `taskDetailInformationTextTaskType`, defaultMessage: 'Task Type' })}
                      req={false}
                    />
                  }
                >
                  <span className="task-detail-information-text-value">
                    {_.get(stateTask, 'viewInformationData.information.taskTypeName')}
                  </span>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  className="task-detail-information-form-item"
                  label={
                    <LabeRequire
                      text={intl.formatMessage({ id: `taskDetailInformationTextPriority`, defaultMessage: 'Priority' })}
                      req={false}
                    />
                  }
                >
                  <img src={_.get(stateTask, 'viewInformationData.information.iconPriority')} style={{ width: '7px', height: '7px' }}></img>{' '}
                  <span className="task-detail-information-text-value">
                    <Icon />
                    {_.get(stateTask, 'viewInformationData.information.priorityName')}
                  </span>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24, 24]}>
              <Col span={8}>
                <Form.Item
                  className="task-detail-information-form-item"
                  label={
                    <LabeRequire
                      text={intl.formatMessage({ id: `taskDetailInformationTextCustomer`, defaultMessage: 'Customer' })}
                      req={false}
                    />
                  }
                >
                  <span className="task-detail-information-text-value">{_.get(stateTask, 'viewInformationData.information.customer')}</span>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  className="task-detail-information-form-item"
                  label={
                    <LabeRequire
                      text={intl.formatMessage({ id: `taskDetailInformationTextContact`, defaultMessage: 'Contact' })}
                      req={false}
                    />
                  }
                >
                  <span className="task-detail-information-text-value">
                    {_.get(stateTask, 'viewInformationData.information.contact[0].contact_name') || '-'}
                  </span>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  className="task-detail-information-form-item"
                  label={
                    <LabeRequire text={intl.formatMessage({ id: `taskDetailInformationTextPhone`, defaultMessage: 'Phone' })} req={false} />
                  }
                >
                  <span className="task-detail-information-text-value">
                    {_.get(stateTask, 'viewInformationData.information.contact[0].contact_phone') || '-'}
                  </span>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24]}>
              <Col span={8}>
                <Form.Item
                  className="task-detail-information-form-item"
                  label={
                    <LabeRequire
                      text={intl.formatMessage({ id: `taskDetailInformationTextStartDate`, defaultMessage: 'Start Date' })}
                      req={false}
                    />
                  }
                >
                  {_.get(stateTask, 'viewInformationData.information.planStart') !== '' &&
                  _.get(stateTask, 'viewInformationData.information.durationStart') !== '' ? (
                    <span className="task-detail-information-text-value">{`${_.get(
                      stateTask,
                      'viewInformationData.information.planStart'
                    )}  ${_.get(stateTask, 'viewInformationData.information.durationStart')}`}</span>
                  ) : (
                    ' - '
                  )}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  className="task-detail-information-form-item"
                  label={
                    <LabeRequire
                      text={intl.formatMessage({ id: `taskDetailInformationTextDueDate`, defaultMessage: 'Due Date' })}
                      req={false}
                    />
                  }
                >
                  {_.get(stateTask, 'viewInformationData.information.planFinish') !== '' &&
                  _.get(stateTask, 'viewInformationData.information.durationFinish') !== '' ? (
                    <span className="task-detail-information-text-value">{`${_.get(
                      stateTask,
                      'viewInformationData.information.planFinish'
                    )}  ${_.get(stateTask, 'viewInformationData.information.durationFinish')}`}</span>
                  ) : (
                    ' - '
                  )}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  className="task-detail-information-form-item"
                  label={
                    <LabeRequire
                      text={intl.formatMessage({ id: `taskDetailInformationTextReference`, defaultMessage: 'Reference' })}
                      req={false}
                    />
                  }
                >
                  <span
                    className={
                      _.get(stateTask, 'viewInformationData.information.reference_type') === 'project'
                        ? 'task-detail-information-text-value-ref'
                        : 'task-detail-information-text-value'
                    }
                    onClick={() =>
                      _.get(stateTask, 'viewInformationData.information.reference_type') === 'project'
                        ? fncStateTask.handleViewProject()
                        : null
                    }
                    style={
                      _.get(stateTask, 'viewInformationData.information.reference_type') === 'project'
                        ? { cursor: 'pointer' }
                        : { cursor: 'none' }
                    }
                  >
                    {_.get(stateTask, 'viewInformationData.information.reference')}
                  </span>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24, 24]}>
              <CustomToField taskDetail={_.get(stateTask, 'viewInformationData.information')} taskCustomField={stateTask} />
            </Row>
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Form.Item
                  className="task-detail-information-form-item"
                  label={
                    <LabeRequire
                      text={intl.formatMessage({ id: `taskDetailInformationTextRemark`, defaultMessage: 'Remark' })}
                      req={false}
                    />
                  }
                >
                  <span className="task-detail-information-text-value">
                    {_.get(stateTask, 'viewInformationData.information.remark') || ' - '}
                  </span>
                </Form.Item>
              </Col>
            </Row>
            <Divider type="horizontal" />
            <Row>
              <div style={{ paddingBottom: '5px' }}>
                <span className="task-detail-information-text-address">
                  <FormattedMessage id="taskDetailInformationTextAddress" defaultMessage="Address" />
                </span>
                <Icon component={NavigatorIcon} className="task-detail-information-text-icon" onClick={NavigatorClick} />
              </div>
              <Row>
                <Col span={10}>
                  <Form.Item
                    className="task-detail-information-form-item"
                    label={
                      <LabeRequire
                        text={intl.formatMessage({ id: `taskDetailInformationTextDestination`, defaultMessage: 'Destination' })}
                        req={false}
                      />
                    }
                  >
                    <Row>
                      <Col span={2}>
                        <Icon type="environment" style={{ fontSize: '12px', color: '#1D3557', marginRight: '5px' }} />
                      </Col>
                      <Col span={22}>
                        <span className="task-detail-information-text-address-value">{`${
                          _.get(stateTask, 'viewInformationData.address.toLocationName') !== null
                            ? _.get(stateTask, 'viewInformationData.address.toLocationName')
                            : ''
                        } ${
                          _.get(stateTask, 'createToData.status') === 'normal_address'
                            ? `(${intl.formatMessage({ id: 'unsaveText', defaultMessage: 'Unsaved' })})`
                            : ''
                        } · ${_.get(stateTask, 'viewInformationData.address.toLocation')}`}</span>
                      </Col>
                    </Row>
                  </Form.Item>
                </Col>
              </Row>
            </Row>
          </Row>
        )}
      </Form>

      <AddContact
        visible={stateTask.visibleAddContact}
        setVisible={setStateTask.setVisibleAddContact}
        customerId={_.get(stateTask, 'viewInformationData.information.customer_id')}
        setTriggerAPI={setStateTask.setTriggerContactAndLocation}
        setContactID={setStateTask.setNewConatact}
        setTemporaryData={setStateTask.setTemporaryData}
      />

      {/* <AddFromLocation
        visible={stateTask.visibleAddFromLocation}
        setVisible={setStateTask.setVisibleAddFromLocation}
        setTrigger={setStateTask.setTriggerContactAndLocation}
        toLocation={setStateTask.setNewCompanyAddress}
      />

      <EditFromLocation
        visible={stateTask.visibleEditComapanyAddress}
        setVisible={setStateTask.setVisibleEditComapanyAddress}
        valueDefault={stateTask.editFromLocationData}
        setTrigger={setStateTask.setTriggerContactAndLocation}
      />

      <AddAddressBookV2
        visible={stateTask.visibleAddCustomerAddress}
        setVisible={setStateTask.setVisibleAddCustomerAddress}
        customerId={_.get(stateTask, 'viewInformationData.information.customer_id')}
        customerName={_.get(stateTask, 'viewInformationData.information.customer')}
        toLocation={setStateTask.setNewCustomerAddress}
        setTrigger={setStateTask.setTriggerContactAndLocation}
      />

      <EditAddressBookV2
        visible={stateTask.visibleEditCustomerAddress}
        setVisible={setStateTask.setVisibleEditCustomerAddress}
        customerId={_.get(stateTask, 'viewInformationData.information.customer_id')}
        customerName={_.get(stateTask, 'viewInformationData.information.customer')}
        valueDefault={stateTask.editTolocationData}
        setTrigger={setStateTask.setTriggerContactAndLocation}
      /> */}

      <CompanyLocation
        visible={stateTask.visibleAddFromLocation}
        setVisible={setStateTask.setVisibleAddFromLocation}
        selectAddress={stateTask.selectFromAddress}
        setSelectAddress={setStateTask.setSelectFromAddress}
        setCreateFromData={setStateTask.setCreateFromData}
        fromOutSide={stateTask.fromOutSide}
        setFromOutSide={setStateTask.setFromOutSide}
        trigger={stateTask.triggerFrom}
        setTrigger={setStateTask.setTriggerFrom}
        visibleAdd={stateTask.visibleAddNewCompany}
        setVisibleAdd={setStateTask.setVisibleAddNewCompany}
        statusFromAdd={stateTask.statusFromAdd}
        setStatuFromsAdd={setStateTask.setStatuFromsAdd}
        keyMenu={stateTask.keyMenu}
        setKeyMenu={setStateTask.setKeyMenu}
        fromLocationData={stateTask.fromLocationData}
        createFromData={stateTask.createFromData}
        center={stateTask.centerFrom}
        setCenter={setStateTask.setCenterFrom}
        position={stateTask.positionFrom}
        setPosition={setStateTask.setPositionFrom}
        visibleInfoWindow={stateTask.visibleInfoWindowFrom}
        setVisibleInfoWindow={setStateTask.setVisibleInfoWindowFrom}
      />

      <CustomerLocation
        visible={stateTask.visibleAddCustomerAddress}
        setVisible={setStateTask.setVisibleAddCustomerAddress}
        selectAddress={stateTask.selectToAddress}
        setSelectAddress={setStateTask.setSelectToAddress}
        setCreateFromData={setStateTask.setCreateToData}
        fromOutSide={stateTask.toOutSide}
        setFromOutSide={setStateTask.setToOutSide}
        trigger={stateTask.triggerTo}
        setTrigger={setStateTask.setTriggerTo}
        visibleAdd={stateTask.visibleAddNewCustomer}
        setVisibleAdd={setStateTask.setVisibleAddNewCustomer}
        statusFromAdd={stateTask.statusToAdd}
        setStatuFromsAdd={setStateTask.setStatusToAdd}
        customerId={_.get(stateTask, 'viewInformationData.information.customer_id')}
        customerName={_.get(stateTask, 'viewInformationData.information.customer')}
        keyMenu={stateTask.keyMenuTo}
        setKeyMenu={setStateTask.setKeyMenuTo}
        toLocationData={stateTask.toLocationData}
        createToData={stateTask.createToData}
        center={stateTask.centerTo}
        setCenter={setStateTask.setCenterTo}
        position={stateTask.positionTo}
        setPosition={setStateTask.setPositionTo}
        visibleInfoWindow={stateTask.visibleInfoWindowTo}
        setVisibleInfoWindow={setStateTask.setVisibleInfoWindowTo}
      />
    </div>
  );
};
