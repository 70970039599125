import React, { useContext, useEffect, useState } from 'react';
import WorkDayForm from './work-day-form';
import HolidaysReport from '../holiday/public-holiday';
import { ShiftSettings } from '../config/shift-type-settings';
import httpClient from '../../../components/axiosClient';


export const workDay = [
  {
    id: 'mon',
    day: 'Monday',
  },
  {
    id: 'tue',
    day: 'Tuesday',
  },
  {
    id: 'wed',
    day: 'Wednesday',
  },
  {
    id: 'thu',
    day: 'Thursday',
  },
  {
    id: 'fri',
    day: 'Friday',
  },
  {
    id: 'sat',
    day: 'Saturday',
  },
  {
    id: 'sun',
    day: 'Sunday',
  },
];

const year = (new Date()).getFullYear();

const minYear = year - 3;
const maxYear = year + 3;

export default () => {

  const comId = localStorage.getItem('comId');

  const [yearAll, setYearAll] = useState([]);

  const [workDays, setWorkDays] = useState();
  const [publicDay, setPublicDay] = useState();
  const [holidaysRow, setHolidaysRow] = useState();

  useEffect(() => {
    getYears(minYear, maxYear);
    getApi();
  }, [year])

  const getYears = (minYear, maxYear) => {
    let arr = [];
    for (var i = minYear; i <= maxYear; i++) {
      arr.push(i);
    }
    setYearAll(arr);
  }

  const getApi = async () => {
    try {
      const results = await httpClient.get(`/resource/manager/company/${comId}/holiday?year=${year}&sortby=start_date&sort=asc`);

      if (results.status === 200) {
        setWorkDays(results.data.workDay);
        setPublicDay(results.data.publicHoliday);
        setHolidaysRow(results.data.publicHoliday.holidays.rows);
      }
    } catch {
      console.error('........................');
    }

  }



  const mockSelectDay = {
    mon: true,
    tue: true,
    wed: false,
    thu: false,
    fri: false,
    sat: false,
    sun: true,
  };


  const mockApi = {
    workDay: {
      mon: true,
      tue: true,
      wed: false,
      thu: false,
      fri: false,
      sat: false,
      sun: false,
    },
    publicHoliday: {
      language: [
        "en",
        "th",
        "ch"
      ],
      list: [
        {
          no: 1,
          holidayId: "45139a95-1c62-462f-98aa-587cd2e680c5",
          date: "2020-01-01",
          dateName: "1 january 2020",
          language: {
            en: "EN - English",
            th: "TH - ไทย"
          },
          holidayName: {
            en: "New Year's Day",
            th: "วันขึ้นปีใหม"
          },
          repeat: {
            status: true,
            repeatName: "Annually"
          }
        },
        {
          no: 2,
          holidayId: "763c0349-4ad7-4c36-989c-10580f580dd4",
          date: "2020-01-14",
          dateName: "14 january 2020",
          language: {
            en: "EN - English",
            th: "TH - ไทย"
          },
          holidayName: {
            en: "National childrens day",
            th: "วันเด็ก"
          },
          repeat: {
            status: true,
            repeatName: "Annually"
          }
        }
      ]
    }
  }


  return (
    <div>
      <WorkDayForm workDay={workDay} mockSelectDay={mockSelectDay} workDays={workDays} />
      <HolidaysReport yearAll={yearAll} year={year} publicDay={publicDay} holidaysRow={holidaysRow} />
    </div>
  )
}