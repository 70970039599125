import React from 'react';
import httpClient from '../../components/axiosClient';

export const getMemberData = async (comCode, OrgID) => {
  try {
    const result = await httpClient.get(`/v3/resource/manager/company/${comCode}/team/${OrgID}/all-members`);
    if (result.status === 200) {
      return result.data.data;
    }
    else {
      return false;
    }
  }
  catch (e) {
    return false;
  }
}