import React, { useState, useEffect } from 'react';
import { compose, withProps } from 'recompose';
import { withScriptjs, withGoogleMap, GoogleMap, Marker, Polygon } from 'react-google-maps';
import markerIcon from '../../components/image/MarkerArea.svg';
import _ from 'lodash';
import { useAppContext } from '../../includes/indexProvider';

const MapComponent = compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?&key=${localStorage.getItem('GEO_APIKEY')}`,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `240px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)((props) => {
  const polygon = _.get(props, 'polygon');
  const point = _.get(props, 'point');
  const app = useAppContext();
  const latlng = _.get(app, 'state.latlngCompany')
  const [defaultCenterx, setDefaultCenterx] = useState(latlng);

  useEffect(() => {
    mapCenter();
  }, [polygon, point]);

  const mapCenterPolygon = polygon.map((item, key) => {
    return item.defaultCenter;
  });

  const mapCenterPoint = point.map((item, key) => {
    return item.defaultCenter;
  });

  const mapCenter = () => {
    if (polygon.length === 1) {
      setDefaultCenterx({ lat: mapCenterPolygon[0].lat, lng: mapCenterPolygon[0].lng });
    }

    if (point.length === 1) {
      setDefaultCenterx({ lat: mapCenterPoint[0].lat, lng: mapCenterPoint[0].lng });
    }
  };

  // ************************Polygon*****************************************
  const mapPolygon = polygon.map((item, key) => {
    const reversedCoordsTest = item.coordinates.map((ll) => {
      return { lat: ll.lat, lng: ll.lng };
    });

    return (
      <div>
        <Polygon
          path={reversedCoordsTest}
          key={key}
          options={{
            fillOpacity: 0.4,
            strokeOpacity: 1,
            fillColor: '#707070',
            strokeColor: '#707070',
            strokeWeight: 2,
          }}
        />
        <Marker
          key={1}
          position={polygon[0].defaultCenter}
          icon={{
            url: markerIcon,
            scaledSize: new window.google.maps.Size(50, 50),
            anchor: new window.google.maps.Point(12, 42),
          }}
        />
      </div>
    );
  });

  // ************************Point*****************************************
  const mapPoint = point.map((item, key) => {
    return <Marker key={key} position={item.coordinates} />;
  });

  return (
    <GoogleMap defaultZoom={11} center={defaultCenterx}>
      {props.isMarkerShown && mapPoint}
      {mapPolygon}
    </GoogleMap>
  );
});

export default MapComponent;
