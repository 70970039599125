import React from 'react';
import { Layout, Row, Col } from 'antd';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useRouteMatch
} from 'react-router-dom';

import Logo from './logo';
import LoginForm from './loginForm';
import Company from './company';
import ForgotPassword from './forgotPassword';
import SecurityCode from './securityCode';
import Newpassword from './newPassword';
import Background from './background';
import cssStyle from './css/index.css';
import routes from '../../config/login-route';

export default () => {
  let { path } = useRouteMatch();
  return (
    <Router>
      <div className="div">
        <Layout className="index-style">
          <Row>
            <Col span={6} className="left-form">
              <Logo />
              <Switch>
                {routes.map((route, index) => (
                  <Route path={path + route.path} key={index} exact={route.exact} children={route.component} />
                ))}
              </Switch>
            </Col>

            <Col span={18}>
              <Background />
            </Col>
          </Row>
        </Layout>
      </div>
    </Router>
  );
};
