import { Table, Card, Col, Row, Tabs, Button, Icon, Menu, Dropdown, Popover } from 'antd';
import React, { useContext, useState, useRef } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';

import { Sticky, StickyContainer } from 'react-sticky';
import Css from './css/index.css';
import _ from 'lodash';
import { DownOutlined } from '@ant-design/icons';
import { AllticketContext } from '../allticket-context';
import { PageSettings } from '../../../../../config/page-settings';
import NoDataBackground from '../../../../../components/no-data-page';

const { TabPane } = Tabs;

const RightCardAllticket = () => {
  const { state, setState, fnc } = useContext(AllticketContext);
  const { checkedKeysRequest, checkedKeysTeam, isLoading, toggle, selectedRowKeys, ticketData, page, total } = state;
  const { setToggle, setEvent, setSort, setRequestDetailData } = setState;
  const { handleChangePage, handleChangeSize, handleVisibleCreate, handleView, handleEdit, closeTicketModal, handleQTYOk } = fnc;
  const totalItems = total;
  const [scrollTableX, setScrollTableX] = useState(1700);
  const intl = useIntl();
  const ref = useRef();
  const [visiblePopoverTable, setVisiblePopoverTable] = useState();
  const [recordPopover, setRecordPopover] = useState();
  const [offsetPopover, setOffsetPopover] = useState([0, 0]);
  const data = _.get(ticketData, 'transferItemList');

  console.log('totalItem', total);

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: 50,
      fixed: 'left',
    },
    {
      title: <FormattedMessage id="TFItemCode" defaultMessage="Item Code" />,
      dataIndex: 'itemCode',
      key: 'itemCode',
      sorter: true,
      fixed: 'left',
      width: 200,
    },
    {
      title: <FormattedMessage id="TFItem" defaultMessage="Item" />,
      dataIndex: 'itemName',
      key: 'itemName',
      sorter: true,
      width: 250,
    },
    {
      title: <FormattedMessage id="TFLotNoorSerailNo" defaultMessage="Lot No. or Serial No." />,
      key: 'lotOrSerialNo',
      dataIndex: 'lotOrSerialNo',
      sorter: true,
      width: 200,
    },
    {
      title: <FormattedMessage id="TFQTY" defaultMessage="Qty" />,
      dataIndex: 'qty',
      key: 'qty',
      sorter: true,
      width: 150,
      align: 'right',
    },
    {
      title: <FormattedMessage id="TFUnit" defaultMessage="Unit" />,
      dataIndex: 'uomName',
      key: 'uomName',
      sorter: true,
      width: 100,
    },
    {
      title: <FormattedMessage id="TFManufacturingDate" defaultMessage="Manufacturing Date" />,
      dataIndex: 'manufacturingDateTxt',
      key: 'manufacturingDateTxt',
      sorter: true,
      width: 200,
    },
    {
      title: <FormattedMessage id="TFExpiredDate" defaultMessage="Expired Date" />,
      key: 'expiredDateTxt',
      dataIndex: 'expiredDateTxt',
      width: 200,
      sorter: true,
    },
    {
      title: <FormattedMessage id="TFRecivedFDA" defaultMessage="FDA" />,
      key: 'fda',
      dataIndex: 'fda',
      width: 150,
    },
    {
      title: <FormattedMessage id="TFRecivedDate" defaultMessage="Recived Date" />,
      key: 'receiveDate',
      dataIndex: 'receiveDate',
      width: 200,
      sorter: true,
    },
    {
      title: <FormattedMessage id="TFRecivedBy" defaultMessage="Recived By" />,
      key: 'receiveBy',
      dataIndex: 'receiveBy',
      width: 200,
      sorter: true,
    },
  ];

  const menuColumn = () => {
    return (
      <Menu>
        <Menu.Item>
          <Button ghost type="link" onClick={handleVisibleCreate}>
            <FormattedMessage id="btnCreate" defaultMessage="Create" />
          </Button>
        </Menu.Item>
      </Menu>
    );
  };

  const showTotal = () => {
    return (
      <span style={{ fontSize: '13px' }}>
        <FormattedMessage id="lblRowPerPage" defaultMessage="Rows per page: " />
      </span>
    );
  };

  const HeaderAndToggle = () => {
    return (
      <div style={{ display: 'flex' }}>
        <div>
          <FormattedMessage id="TFItem" defaultMessage="Item" />
        </div>
      </div>
    );
  };

  const handleToggle = () => {
    setToggle((current) => !current);
  };

  const handleMouseLeave = () => {
    setVisiblePopoverTable(false);
    setRecordPopover();
    setOffsetPopover([0, 0]);
  };


  const onChangeTable = (value, index, sort) => {
    setSort(sort);
    setEvent((current) => !current);
  };

  return (
    <div>
      {/* {_.size(checkedKeysRequest) !== 0 || _.size(checkedKeysTeam) !== 0 ? ( */}
      {/* <PageSettings.Consumer>
        {({ checkPermissionAction }) =>
          checkPermissionAction(`P1PG1`, `P1PG1A1`) ? ( */}
            <Card title={<HeaderAndToggle />} className="right-cards">
              <div className="containerTables" onMouseLeave={handleMouseLeave}>
                <Row gutter={16}>
                  <Table
                    className="custom-table-claim"
                    dataSource={data || []}
                    columns={columns}
                    scroll={{ x: scrollTableX, y: `calc(100vh - 313px)` }}
                    loading={isLoading}
                    onChange={onChangeTable}
                    rowClassName="rowcursor"
                    pagination={{
                      total: totalItems,
                      current: page,
                      defaultCurrent: 1,
                      pageSizeOptions: ['10', '20', '30', '40', '50'],
                      showSizeChanger: true,
                      locale: { items_per_page: '' },
                      showTotal: showTotal,
                      onChange: handleChangePage,
                      onShowSizeChange: handleChangeSize,
                    }}
                    onRow={(record, rowIndex) => {
                      return {
                        onClick: (event) => {
                          // if (!event.target.href) {
                            setRequestDetailData(record);
                            handleQTYOk(record);
                          // }
                        },
                      };
                    }}
                  />
                  {/* {fncActionTable(recordPopover, offsetPopover, visiblePopoverTable, ref)} */}
                  <div className="total-items-member">
                    <span style={{ fontSize: '13px' }}>
                      <FormattedMessage id="lblTotal" defaultMessage="Total" />
                      {` ${totalItems || 0} `}
                      <FormattedMessage id="lblitems" defaultMessage="items" />
                    </span>
                  </div>
                </Row>
              </div>
            </Card>
          {/* ) : null
        }
      </PageSettings.Consumer> */}

      {/* //   <div style={{ padding: '24px', backgroundColor: '#fff' }}>
      //     <Card className="detail-card-blank-page">
      //       <NoDataBackground
      //         text1={{ id: 'nodataApproval1', text: 'Please select a Request' }}
      //         text2={{ id: 'nodataApproval2', text: 'Request will appear here.' }}
      //       />
      //     </Card>
      //   </div>
      // )} */}
    </div>
  );
};

export default RightCardAllticket;
