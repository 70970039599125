import React, { useContext, useEffect, useState } from 'react';
import { Col, Form, Icon, Modal, Row, Select, Tag } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import Button_01 from '../../../../components/v2/button_01';
import _ from 'lodash';
import { messageLabel } from '../../../../components/message-component';
import moment from 'moment';
import { getItemCheckStock, getQuotationById, getQuotationList } from '../../../../controllers/quotation/quotation-api-new';
import CustomTableComponent from '../../components/table';
import LabeRequireForm from '../../../../components/label-required-form';
import { SelectCustomerHook } from '../select-customer-new/selectHook';
import { getSaleOrderPendingList } from '../../../../controllers/account-receive';
import { AccountReceiveContext, InvoiceContext } from '../../context';
import { getDefaultAllList, getInvoiceList, getTaskAtbillingList } from '../../../../controllers/invoice-new/invoice-api';
import ListItemFilterTask from './filter-item-task';
import { getInitializeMonitorData } from '../../../../controllers/monitor/initial';
import { errorNotification, successNotification } from '../../../../components/v2/notification';

const { Option } = Select;

const SelectCustomerItemTaskModal = ({ form, title, visible, onOk, onCancel, setDataForm, dataForm, setSelectItem, selectItem }) => {
  const [reportDataPending, setReportDataPending] = useState({
    quotationList: [],
    quotationTotal: 0,
    extraSort: undefined,
    fieldSort: '',
    orderSort: undefined,
    orderBy: undefined,
    paginationPage: 1,
    paginationSize: 10,
    loading: false,
    quotationNo: '',
    invoiceNo: '',
    paymentTermNo: '',
    saleOrderNo: '',
    customerName: '',
    rangePickerDate: [],
    statusCode: [],
    issueDateStart: '',
    issueDateEnd: '',
  });
  const clearReport = reportDataPending;
  console.log('dataFormCus', dataForm);
  const { getFieldDecorator, setFieldsValue, resetFields, getFieldValue } = form;
  const [pageTable, setPageTable] = useState(1);
  const [sizeTable, setSizeTable] = useState(10);
  const { customerList, contactList, addressList, handleSearchCustomer, resetCustomer } = SelectCustomerHook();
  const [searchGroup, setSearchGroup] = useState([]);
  const [event, setEvent] = useState([]);
  const { state, setState, func } = useContext(AccountReceiveContext);
  // const { setDefaultSaleOrder } = setState;
  // const { handleOpenModalQuotationPending } = func;

  const [panelTeam, setPanelTeam] = useState(false);
  const [checkAllTeam, setCheckAllTeam] = useState(false);
  const [indeterminateTeam, setIndeterminateTeam] = useState(false);
  const [planOptionsTeam, setPlanOptionsTeam] = useState([]);
  const [teamList, setTeamList] = useState([]);
  const [checkedListTeam, setCheckedListTeam] = useState([]);
  const [teamId, setTeamId] = useState([]);
  const [teamSelect, setTeamSelect] = useState([]);
  const [teamTaskTypeList, setTaskTypeList] = useState([]);
  const [teamStatusList, setTeamStatusList] = useState([]);
  const intl = useIntl();

  console.log('reportDataPending', reportDataPending);
  useEffect(() => {
    const getDataInitial = async () => {
      const response = await getDefaultAllList({});
      console.log('getDataInitial', response);
      if (response) {
        const taskTypelist = _.get(response, 'taskType') || [];
        const statuslist = _.get(response, 'status') || [];
        const tasktypex = _.map(taskTypelist, (o) => {
          return {
            ...o,
            taskTypeId: o.taskTypeId,
            taskTypeName: o.name,
          };
        });

        const statusx = _.map(statuslist, (o) => {
          return {
            ...o,
            statusId: o.statusId,
            statusName: o.txt,
          };
        });
        setTaskTypeList(tasktypex);
        setTeamStatusList(statusx);
      }
    };
    getDataInitial();
  }, []);

  useEffect(() => {
    const getDataInitial = async () => {
      const response = await getInitializeMonitorData('transportation');
      if (response.status === 200) {
        console.log('teamm', _.get(response, 'data.teamChkbox.teamChkboxList'));
        const teamlist = _.get(response, 'data.teamChkbox.teamChkboxList') || [];
        const teamNew = teamlist.map((item, index) => {
          return {
            label: item.orgName,
            value: item.orgId,
          };
        });
        setPlanOptionsTeam(teamNew);
        setTeamList(teamlist);
      }
    };
    getDataInitial();
  }, []);

  useEffect(() => {
    const getDataInitial = async () => {
      const response = await getInitializeMonitorData('transportation');
      if (response.status === 200) {
        console.log('teamm', _.get(response, 'data.teamChkbox.teamChkboxList'));
        const teamlist = _.get(response, 'data.teamChkbox.teamChkboxList') || [];
        const teamNew = teamlist.map((item, index) => {
          return {
            label: item.orgName,
            value: item.orgId,
          };
        });
        setPlanOptionsTeam(teamNew);
        setTeamList(teamlist);
      }
    };
    getDataInitial();
  }, []);

  useEffect(() => {
    setReportDataPending((prev) => ({ ...prev, loading: true }));
    const getApi = async () => {
      const selectItemCode = _.map(selectItem, (o) => o.taskId);

      const payload = {
        taskTypeId: _.get(reportDataPending, 'taskTypeId') ? [_.get(reportDataPending, 'taskTypeId')] : [],
        statusId: _.get(reportDataPending, 'statusId') ? [_.get(reportDataPending, 'statusId')] : [],
        taskNo: _.get(reportDataPending, 'taskNo'),
        taskName: _.get(reportDataPending, 'taskName'),
        toAddress: _.get(reportDataPending, 'toLocation'),
        formAddress: _.get(reportDataPending, 'fromLocation'),
        createdAt: _.get(reportDataPending, 'createdAt') ? moment(_.get(reportDataPending, 'createdAt')).format('YYYY-MM-DD') : undefined,
        team: checkedListTeam,
        customerId: _.get(dataForm, 'customerId') ? _.get(dataForm, 'customerId') : '',
        pageNumber:
          _.get(reportDataPending, 'taskNo') ||
          _.get(reportDataPending, 'taskName') ||
          _.get(reportDataPending, 'fromLocation') ||
          _.get(reportDataPending, 'toLocation') ||
          _.get(reportDataPending, 'taskTypeId') ||
          _.get(reportDataPending, 'statusId') ||
          _.get(reportDataPending, 'createdAt')
            ? 1
            : pageTable,
        limit: sizeTable,
        orderBy: _.get(reportDataPending, 'fieldSort'),
        orderType: _.get(reportDataPending, 'orderSort'),
        taskNotIn: selectItemCode || [],
      };
      const response = await getTaskAtbillingList(payload);
      if (response) {
        setReportDataPending((prev) => ({
          ...prev,
          quotationList: _.get(response, 'data.listView'),
          quotationTotal: _.get(response, 'data.totalItem'),
        }));
      }
      console.log('getTaskAtbillingList', _.get(response, 'data.listView'));
      // setReportDataPending((prev) => ({
      //   ...prev,
      //   quotationList: _.get(response, 'data.invoiceList'),
      //   quotationTotal: _.get(response, 'data.totalItem'),
      // }));
    };
    setReportDataPending((prev) => ({ ...prev, loading: false }));
    if (visible === true) {
      getApi();
    }
  }, [
    visible,
    _.get(reportDataPending, 'paginationPage'),
    _.get(reportDataPending, 'paginationSize'),
    _.get(reportDataPending, 'fieldSort'),
    _.get(reportDataPending, 'orderSort'),
    _.get(reportDataPending, 'statusCode'),
    _.get(reportDataPending, 'customerName'),
    pageTable,
    sizeTable,
    event,
  ]);

  // useEffect(() => {
  //   setReportDataPending((prev) => ({ ...prev, loading: true }));

  //   const mapCustomerID = _.map(selectItem, (item) => {
  //     return _.get(item, 'invoiceId');
  //   });

  //   const getApi = async () => {
  //     const payload = {
  //       isCreateBilling: true,
  //       tabCode: 'pending',
  //       startDate: _.get(reportDataPending, 'issueDateStart')
  //         ? moment(_.get(reportDataPending, 'issueDateStart')).format('YYYY-MM-DD')
  //         : undefined,
  //       endDate: _.get(reportDataPending, 'issueDateEnd')
  //         ? moment(_.get(reportDataPending, 'issueDateEnd')).format('YYYY-MM-DD')
  //         : undefined,
  //       pageNumber: pageTable,
  //       limit: sizeTable,
  //       orderBy: _.get(reportDataPending, 'fieldSort'),
  //       orderType: _.get(reportDataPending, 'orderSort'),
  //       paymentTermNo: _.get(reportDataPending, 'paymentTermNo'),
  //       saleOrderNo: _.get(reportDataPending, 'saleOrderNo'),
  //       customerId: _.get(reportDataPending, 'customerName') ? [_.get(reportDataPending, 'customerName')] : [],
  //       invoiceNo: _.get(reportDataPending, 'invoiceNo') ? _.get(reportDataPending, 'invoiceNo') : '',
  //       statusCode: ['waiting'],
  //       invoiceIdNin: mapCustomerID ? mapCustomerID : undefined,
  //     };
  //     const response = await getInvoiceList(payload);

  //     setReportDataPending((prev) => ({
  //       ...prev,
  //       quotationList: _.get(response, 'data.invoiceList'),
  //       quotationTotal: _.get(response, 'data.totalItem'),
  //     }));
  //   };
  //   setReportDataPending((prev) => ({ ...prev, loading: false }));
  //   if (visible === true) {
  //     getApi();
  //   }
  // }, [
  //   visible,
  //   _.get(reportDataPending, 'paginationPage'),
  //   _.get(reportDataPending, 'paginationSize'),
  //   _.get(reportDataPending, 'fieldSort'),
  //   _.get(reportDataPending, 'orderSort'),
  //   _.get(reportDataPending, 'statusCode'),
  //   _.get(reportDataPending, 'customerName'),
  //   pageTable,
  //   sizeTable,
  //   event,
  // ]);

  useEffect(() => {
    if (getFieldValue('customerName')) {
      const onChange = async (value, code) => {
        setReportDataPending({ ...reportDataPending, [code]: value });
      };
      onChange(getFieldValue('customerName'), 'customerName');
    }
  }, [getFieldValue('customerName')]);

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      fixed: 'left',
      width: 70,
      render: (text, record, index) => record.index,
    },
    {
      title: messageLabel('blTaskNo', 'Task No.'),
      dataIndex: 'taskNo',
      key: 'taskNo',
      sorter: true,
      fixed: 'left',
      width: 150,
      ellipsis: true,
    },
    {
      title: messageLabel('blTaskType', 'Task Type'),
      dataIndex: 'taskType.taskTypeName',
      key: 'taskType.taskTypeName',
      width: 150,
      sorter: true,
    },
    {
      title: messageLabel('blTaskName', 'Task Name'),
      dataIndex: 'taskName',
      key: 'taskName',
      width: 150,
      sorter: true,
    },
    {
      title: messageLabel('blTaskTeam', 'Team'),
      dataIndex: 'orgName',
      key: 'orgName',
      width: 150,
      sorter: true,
    },
    {
      title: messageLabel('blTaskContact', 'Contact'),
      dataIndex: 'contact.contactName',
      key: 'contact.contactName',
      width: 150,
      sorter: true,
    },
    {
      title: messageLabel('blTaskContactPhone', 'Contact Phone'),
      dataIndex: 'contact.contactPhone',
      key: 'contact.contactPhone',
      width: 180,
      sorter: true,
    },
    {
      title: messageLabel('blTaskFromLocation', 'From Location'),
      dataIndex: 'formAddress',
      key: 'formAddress',
      width: 300,
      sorter: true,
    },
    {
      title: messageLabel('blTaskToLocation', 'To Location'),
      dataIndex: 'toAddress',
      key: 'toAddress',
      width: 300,
      sorter: true,
    },
    {
      title: messageLabel('blTaskStatus', 'Status'),
      dataIndex: 'totalTxt',
      key: 'totalTxt',
      width: 150,
      sorter: true,
      render: (text, record, index) => (
        <div className="tag-center-div">
          <Tag className="tag-center-style" color={_.get(record, 'status.color')}>
            {_.get(record, 'status.en')}
          </Tag>
        </div>
      ),
    },
    {
      title: messageLabel('blTaskStartDate', 'Start Date'),
      dataIndex: 'startDateTxt',
      key: 'startDateTxt',
      width: 150,

      sorter: true,
    },
    {
      title: messageLabel('blTaskDueDate', 'Due Date'),
      dataIndex: 'endDateTxt',
      key: 'endDateTxt',
      width: 150,
      sorter: true,
    },
    {
      title: messageLabel('blTaskActualStartDate', 'Actual Start Date'),
      dataIndex: 'actualStartTxt',
      key: 'actualStartTxt',
      width: 200,
      sorter: true,
    },
    {
      title: messageLabel('blTaskActualCompletedDate', 'Actual Completed Date'),
      dataIndex: 'actualEndTxt',
      key: 'actualEndTxt',
      width: 220,
      sorter: true,
    },
    {
      title: messageLabel('blTaskReference', 'Reference'),
      dataIndex: 'reference',
      key: 'reference',
      width: 200,
      sorter: true,
    },
    {
      title: messageLabel('blTaskReference1', 'Reference 1'),
      dataIndex: 'reference1',
      key: 'reference1',
      width: 200,
      sorter: true,
    },
    {
      title: messageLabel('blTaskReference2', 'Reference 2'),
      dataIndex: 'reference2',
      key: 'reference2',
      width: 200,
      sorter: true,
    },
    {
      title: messageLabel('blTaskReference3', 'Reference 3'),
      dataIndex: 'reference3',
      key: 'reference3',
      width: 200,
      sorter: true,
    },
    {
      title: messageLabel('blTaskReference4', 'Reference 4'),
      dataIndex: 'reference4',
      key: 'reference4',
      width: 200,
      sorter: true,
    },
    {
      title: messageLabel('blRemark', 'Remark'),
      dataIndex: 'remark',
      key: 'remark',
      width: 200,
      sorter: true,
      render: (text, record, index) => _.get(record, 'remark') || '-',
    },
    {
      title: messageLabel('blCreatedDate', 'Created Date'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 150,
      sorter: true,
      render: (text, record, index) => _.get(record, 'createdAt') || '-',
    },
    {
      title: messageLabel('blAssigneeName', 'Assignee Name'),
      dataIndex: 'assignee',
      key: 'assignee',
      width: 200,
      sorter: true,
      // render: (text, record, index) => _.map(_.get(record, 'assignee'), (assigne) => _.get(assigne, 'fullname') ? _.get(assigne, 'fullname') : '').slice(),
      render: (text, record) => {
        const assignees = _.map(_.get(record, 'assignee', []), (assignee) => _.get(assignee, 'fullname', ''));
        return assignees.filter(name => name).join(', ');
      }
    },
  ];

  const handleClose = () => {
    onCancel();
    setReportDataPending({
      quotationList: [],
      quotationTotal: 0,
      extraSort: undefined,
      fieldSort: '',
      orderSort: 'asc',
      paginationPage: 1,
      paginationSize: 10,
      loading: false,
      quotationNo: '',
      invoiceNo: '',
      paymentTermNo: '',
      saleOrderNo: '',
      customerName: '',
      rangePickerDate: [],
      statusCode: [],
      issueDateStart: '',
      issueDateEnd: '',
    });
    setSearchGroup();
  };

  const onSelectQuotationRow = async (value) => {
    let temp = [...selectItem];
    value.taskNoTemp = value.taskNo;
    value.taskTypeTemp = _.get(value, 'taskType.taskTypeName');
    // value.descriptionTemp = value.fromAddressName + ' - ' + value.toAddressName + ', ' + value.startDateTxt + ' - ' + value.endDateTxt;
    value.descriptionTemp = `${value.formAddressName || ''} - ${value.toAddressName || ''}, ${value.startDateTxt ||
      ''} - ${value.endDateTxt || ''}`;
    value.amountTemp = value.freigtPrice || 0.0;
    value.otherValue = 0.0;
    value.sumOtherAmount = parseFloat(value.freigtPrice || 0.0);
    temp.push(value);
    const mapselectitem = _.map(temp, (item, i) => {
      return {
        ...item,
        index: i + 1,
      };
    });

    setSelectItem(mapselectitem);
    onCancel();
  };

  const handleSave = async (value) => {
    // handleOpenModalQuotationPending(value);
    onCancel();
  };

  const handleReplace = (value) => {
    Modal.confirm({
      className: 'maintenance-report-modal-confirm',
      centered: true,
      icon: null,
      title: intl.formatMessage({ id: 'lblReplace', defaultMessage: 'Are you sure you want to replace ?' }),
      okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        if (value) {
          console.log('saleselectvalue', value);
          // handleOpenModalQuotationPending(value);
          onCancel();
        }
      },
      onCancel() {},
    });
  };

  const handleChange = (value, key) => {
    setReportDataPending((prev) => ({ ...prev, [key]: value }));
  };

  const onAllowClear = (value, code) => {
    if (value) {
      setReportDataPending({ ...reportDataPending, [code]: '' });
    }
  };

  const onClick = () => {
    setEvent((prev) => !prev);
  };

  return (
    <Modal
      className="custom-modal-add-task-billing"
      title={intl.formatMessage({ id: 'BLAddTask', defaultMessage: 'Add Task' })}
      visible={visible}
      onOk={handleClose}
      onCancel={handleClose}
      width={1120}
      zIndex={1000}
      centered={true}
      footer={[
        <Button_01 key="close" type="primary" btnsize="wd_df" onClick={() => handleClose()}>
          <FormattedMessage id="btnClose" defaultMessage="Close" />
        </Button_01>,
      ]}
    >
      <Row>
        <Col span={6}>
          <div className="listItemTextHeaderTask" style={{ padding: '16px 16px 16px 16px' }}>
            <FormattedMessage id="modalListItemFilterText" defaultMessage="Filter" />
          </div>
        </Col>
        <Col span={18}>
          <div className="listItemTextHeader" style={{ padding: '16px 16px 16px 16px' }}>
            <FormattedMessage id="BLAddTaskTitle" defaultMessage="Task" />
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={6}>
          <div className="listItemDividerCenter">
            <ListItemFilterTask
              onChange={handleChange}
              onAllowClear={onAllowClear}
              searchGroup={reportDataPending}
              onClick={onClick}
              team={{
                panelTeam: panelTeam,
                planOptionsTeam: planOptionsTeam,
                indeterminateTeam: indeterminateTeam,
                checkAllTeam: checkAllTeam,
                checkedListTeam: checkedListTeam,
                teamId: teamId,
                setCheckedListTeam: setCheckedListTeam,
                setTeamId: setTeamId,
                setIndeterminateTeam: setIndeterminateTeam,
                setCheckAllTeam: setCheckAllTeam,
                setPanelTeam: setPanelTeam,
                setTeamSelect: setTeamSelect,
                teamSelect: teamSelect,
                teamList: teamList,
                teamTaskTypeList: teamTaskTypeList,
                teamStatusList: teamStatusList,
              }}
            />
          </div>
        </Col>
        <Col span={18}>
          <div style={{ height: '625px', padding: '16px' }}>
            <Row style={{ marginBottom: '15px' }}>
              <Form form={form} layout="vertical" className="po-form">
                <Form.Item
                  label={
                    <LabeRequireForm text={intl.formatMessage({ id: 'invoiceLabelCustomer', defaultMessage: 'Customer' })} req={false} />
                  }
                  style={{ width: '300px' }}
                >
                  {_.get(dataForm, 'customerName') || '-'}
                </Form.Item>
              </Form>
            </Row>
            <CustomTableComponent
              columns={columns}
              rowKey={(record) => record.quotationId}
              dataSource={_.get(reportDataPending, 'quotationList')}
              scroll={{ x: true, y: 400 }}
              total={_.get(reportDataPending, 'quotationTotal')}
              role={false}
              onRow={false}
              onRowClick={(o) => onSelectQuotationRow(o)}
              paginationPage={pageTable}
              setPaginationPage={setPageTable}
              paginationShow={sizeTable}
              paginationSize={sizeTable}
              setPaginationSize={setSizeTable}
              type={'biling-note-task'}
            />
          </div>
        </Col>
      </Row>
      {/* <Row>
        <Col span={6}>
          <div className="listItemTextHeader" style={{ padding: '0px 16px 16px 0px' }}>
            <FormattedMessage id="modalListItemFilterText" defaultMessage="Filter" />
          </div>
          <div className="listItemDividerCenter">
            <ListItemFilter onChange={handleChange} onAllowClear={onAllowClear} searchGroup={reportDataPending} onClick={onClick} />
          </div>
        </Col>
        <Col span={18}>
          <div className="listItemTextHeader" style={{ paddingTop: '0px' }}>
            <FormattedMessage id="BLAddTaskTitle" defaultMessage="Task" />
          </div>
          <div style={{ height: '625px', padding: '16px' }}>
            <Row style={{ marginBottom: '15px' }}>
              <Form form={form} layout="vertical" className="po-form">
                <Form.Item
                  label={
                    <LabeRequireForm text={intl.formatMessage({ id: 'invoiceLabelCustomer', defaultMessage: 'Customer' })} req={false} />
                  }
                  style={{ width: '300px' }}
                >
                  {getFieldDecorator('customerName', {
                    initialValue: _.get(dataForm, 'customerId'),
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({ id: 'invoiceLabelCustomerPlaceholder', defaultMessage: 'please select customer' }),
                      },
                    ],
                  })(
                    <Select
                      placeholder={intl.formatMessage({
                        id: 'purchesModalFormPlaceholderVendor',
                        defaultMessage: 'Select Vendor or Supplier',
                      })}
                      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      showSearch
                      style={{ width: '400px' }}
                      onSearch={handleSearchCustomer}
                    >
                      {customerList && customerList.map((item) => <Option key={item.customerId}>{item.customerName}</Option>)}
                    </Select>
                  )}
                </Form.Item>
              </Form>
            </Row>
            <CustomTableComponent
              columns={columns}
              rowKey={(record) => record.quotationId}
              dataSource={_.get(reportDataPending, 'quotationList')}
              scroll={{ x: true, y: 400 }}
              total={_.get(reportDataPending, 'quotationTotal')}
              role={false}
              onRow={false}
              onRowClick={(o) => onSelectQuotationRow(o)}
              paginationPage={pageTable}
              setPaginationPage={setPageTable}
              paginationShow={sizeTable}
              setPaginationSize={setSizeTable}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={6}>
          <div className="listItemTextHeader" style={{ padding: '0px 16px 16px 0px' }}>
            <FormattedMessage id="modalListItemFilterText" defaultMessage="Filter" />
          </div>
          <div className="listItemDividerCenter">
            <ListItemFilter onChange={handleChange} onAllowClear={onAllowClear} searchGroup={reportDataPending} onClick={onClick} />
          </div>
        </Col>
        <Col span={18}>
          <div className="listItemTextHeader" style={{ paddingTop: '0px' }}>
            <FormattedMessage id="BLAddTaskTitle" defaultMessage="Task" />
          </div>
          <div style={{ height: '625px', padding: '16px' }}>
            <Row style={{ marginBottom: '15px' }}>
              <Form form={form} layout="vertical" className="po-form">
                <Form.Item
                  label={
                    <LabeRequireForm text={intl.formatMessage({ id: 'invoiceLabelCustomer', defaultMessage: 'Customer' })} req={false} />
                  }
                  style={{ width: '300px' }}
                >
                  {getFieldDecorator('customerName', {
                    initialValue: _.get(dataForm, 'customerId'),
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({ id: 'invoiceLabelCustomerPlaceholder', defaultMessage: 'please select customer' }),
                      },
                    ],
                  })(
                    <Select
                      placeholder={intl.formatMessage({
                        id: 'purchesModalFormPlaceholderVendor',
                        defaultMessage: 'Select Vendor or Supplier',
                      })}
                      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      showSearch
                      style={{ width: '400px' }}
                      onSearch={handleSearchCustomer}
                    >
                      {customerList && customerList.map((item) => <Option key={item.customerId}>{item.customerName}</Option>)}
                    </Select>
                  )}
                </Form.Item>
              </Form>
            </Row>
            <CustomTableComponent
              columns={columns}
              rowKey={(record) => record.quotationId}
              dataSource={_.get(reportDataPending, 'quotationList')}
              scroll={{ x: true, y: 400 }}
              total={_.get(reportDataPending, 'quotationTotal')}
              role={false}
              onRow={false}
              onRowClick={(o) => onSelectQuotationRow(o)}
              paginationPage={pageTable}
              setPaginationPage={setPageTable}
              paginationShow={sizeTable}
              setPaginationSize={setSizeTable}
            />
          </div>
        </Col>
      </Row> */}
    </Modal>
  );
};

SelectCustomerItemTaskModal.defaultProps = {
  // const intl = useIntl();
  // title: ,
  // title: intl.formatMessage({ id: 'quotationLabelSelectCustomer', defaultMessage: 'Select Customer' }),
  visible: false,
  onOk: () => console.warn('onOk not function '),
  onCancel: () => console.warn('onCancel not function '),
};

const ModalAddCustomerItemTask = Form.create({ name: 'select_qt_Form' })(SelectCustomerItemTaskModal);

export default ModalAddCustomerItemTask;
