import React, { useContext } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Form, Row, Col, Input, DatePicker, Select } from 'antd';
import CollapseCustom from '../../../components/collapse-custom';
// import ActionColumnPO from './action-column'
import LabeRequireForm from '../../../components/label-required-form';
import _ from 'lodash';
import { AllowanceProvider } from '../context';
import Button_01 from '../../../components/v2/button_01';
import Button_02 from '../../../components/v2/button_02';
import '../index.css';
import AllowanceActionColumns from './actionColumns';
import LoadmoreProject from '../../../components/loadmore/project';
import LoadmoreSource from '../../../components/loadmore/source';

const { Option } = Select;

const AllowanceAction = () => {
  const { state, setState, fnc } = useContext(AllowanceProvider);
  const intl = useIntl();
  const {
    searchGroup,
    tableChange,
    itemType,
    taskType,
    columnsNormal,
    listArrayColumns,
    defaultShowColumn,
    newColumns,
    textErrorSelectColumn,
    visible,
    actionColumns,
    loading,
    tabValue,
  } = state;
  const {
    setSearchGroup,
    setTableChange,
    setTrigger,
    setNewDataColumns,
    setListArrayColumns,
    setDefaultShowColumn,
    setTextErrorSelectColumn,
    setVisible,
  } = setState;
  const formatDate = 'DD/MM/YYYY';

  const onChange = (value, code) => {
    setSearchGroup({ ...searchGroup, [code]: value });
  };

  const onApply = () => {
    setTableChange({ ...tableChange, page: 1 });
    setTrigger((cur) => !cur);
  };

  const onReset = () => {
    setSearchGroup({});
    setTableChange({ ...tableChange, page: 1 });
    setTrigger((cur) => !cur);
  };

  const formFilter = (value) => {
    return (
      <Form colon={false} className="resourceAllowanceForm">
        <Row gutter={[24, 2]}>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'resourceAllowanceFillterAllowanceNo', defaultMessage: 'Allowance No.' })}
                  req={false}
                />
              }
            >
              <Input
                allowClear
                value={_.get(value, 'allowanceNo')}
                onChange={(e) => onChange(e.target.value, 'allowanceNo')}
                placeholder={intl.formatMessage({ id: 'resourceAllowanceFillterAllowanceNoPLH', defaultMessage: 'Enter Allowance No.' })}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'resourceAllowanceFillterType', defaultMessage: 'Type' })} req={false} />
              }
            >
              <Select
                allowClear
                showSearch
                value={_.get(value, 'allowanceTypeCodeList')}
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                placeholder={intl.formatMessage({ id: 'resourceAllowanceFillterTypePLH', defaultMessage: 'Select Type' })}
                onSearch={(e) => onChange(e, 'allowanceTypeCodeList')}
                onChange={(e) => onChange(e, 'allowanceTypeCodeList')}
              >
                {_.map(itemType, (item, index) => (
                  <Option key={item.code} value={item.code}>
                    {item.txt}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'resourceAllowanceFillterTaskNo', defaultMessage: 'Task No.' })}
                  req={false}
                />
              }
            >
              <Input
                allowClear
                value={_.get(value, 'taskNo')}
                onChange={(e) => onChange(e.target.value, 'taskNo')}
                placeholder={intl.formatMessage({ id: 'resourceAllowanceFillterTaskNoPLH', defaultMessage: 'Enter Task No.' })}
              />
            </Form.Item>
          </Col>
        </Row>
        {/*--------------------------------------------------------------------------------------------------------------------*/}
        <Row gutter={[24, 2]}>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'resourceAllowanceFillterCustomer', defaultMessage: 'Customer' })}
                  req={false}
                />
              }
            >
              <Input
                allowClear
                value={_.get(value, 'customerName')}
                onChange={(e) => onChange(e.target.value, 'customerName')}
                placeholder={intl.formatMessage({ id: 'resourceAllowanceFillterCustomerPLH', defaultMessage: 'Enter Customer' })}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <LoadmoreProject data={searchGroup} setData={setSearchGroup} required={false} notSpec={true} />
          </Col>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'resourceAllowanceFillterTaskType', defaultMessage: 'Task Type' })}
                  req={false}
                />
              }
            >
              <Select
                allowClear
                showSearch
                value={_.get(value, 'taskTypeId')}
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                placeholder={intl.formatMessage({ id: 'resourceAllowanceFillterTaskTypePLH', defaultMessage: 'Select Task Type' })}
                onSearch={(e) => onChange(e, 'taskTypeId')}
                onChange={(e) => onChange(e, 'taskTypeId')}
              >
                {_.map(taskType, (item, index) => (
                  <Option key={item.taskTypeId} value={item.taskTypeId}>
                    {item.taskTypeName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        {/*--------------------------------------------------------------------------------------------------------------------*/}
        <Row gutter={[24, 2]}>
          <Col span={8}>
            <LoadmoreSource data={searchGroup} setData={setSearchGroup} required={false} notSpec={true} />
          </Col>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'resourceAllowanceFillterStartDate', defaultMessage: 'Start Date' })}
                  req={false}
                />
              }
            >
              <DatePicker
                style={{ width: '100%' }}
                allowClear
                format={formatDate}
                value={_.get(value, 'startDate')}
                placeholder={intl.formatMessage({ id: 'resourceAllowanceFillterStartDatePLH', defaultMessage: 'Select Start Date' })}
                onChange={(e) => onChange(e, 'startDate')}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'resourceAllowanceFillterEndDate', defaultMessage: 'Due Date ' })}
                  req={false}
                />
              }
            >
              <DatePicker
                style={{ width: '100%' }}
                allowClear
                format={formatDate}
                value={_.get(value, 'endDate')}
                placeholder={intl.formatMessage({ id: 'resourceAllowanceFillterEndDatePLH ', defaultMessage: 'Select Due Date' })}
                onChange={(e) => onChange(e, 'endDate')}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 2]}>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'resourceAllowanceFillterAssignee2', defaultMessage: 'Assignee 1' })}
                  req={false}
                />
              }
            >
              <Input
                allowClear
                value={_.get(value, 'assignee1')}
                onChange={(e) => onChange(e.target.value, 'assignee1')}
                placeholder={intl.formatMessage({ id: 'resourceAllowanceFillterAssignee1PLH', defaultMessage: 'Enter Assignee 1' })}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'resourceAllowanceFillterAssignee2', defaultMessage: 'Assignee 2' })}
                  req={false}
                />
              }
            >
              <Input
                allowClear
                value={_.get(value, 'assignee2')}
                onChange={(e) => onChange(e.target.value, 'assignee2')}
                placeholder={intl.formatMessage({ id: 'resourceAllowanceFillterAssignee2PLH', defaultMessage: 'Enter Assignee 2' })}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'resourceAllowanceFillterLicensePlate', defaultMessage: 'License Plate' })}
                  req={false}
                />
              }
            >
              <Input
                allowClear
                value={_.get(value, 'licensePlate')}
                onChange={(e) => onChange(e.target.value, 'licensePlate')}
                placeholder={intl.formatMessage({ id: 'resourceAllowanceFillterLicensePlatePLH', defaultMessage: 'Enter License Plate' })}
              />
            </Form.Item>
          </Col>
        </Row>

        {/*--------------------------------------------------------------------------------------------------------------------*/}
        <Row gutter={[24, 2]}>
          <Col span={8}></Col>
          <Col span={8}></Col>
          <Col span={8} style={{ textAlign: 'right' }}>
            <Form.Item label={<LabeRequireForm text={''} req={false} />}>
              <Button_01 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={onReset}>
                <FormattedMessage id="btnResetFilter" defaultMessage="Reset" />
              </Button_01>
              <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="print" type="primary" btnsize="wd_df" onClick={onApply}>
                <FormattedMessage id="btnApplyFilter" defaultMessage="Apply" />
              </Button_02>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  };

  return (
    <div style={{ padding: '12px 0px' }}>
      <CollapseCustom
        label={intl.formatMessage({ id: `btnFilter`, defaultMessage: 'Filter' })}
        extra={
          <div style={{ display: 'flex' }}>
            <AllowanceActionColumns
              columnsNormal={columnsNormal}
              listArrayColumns={listArrayColumns}
              defaultShowColumn={defaultShowColumn}
              newColumns={newColumns}
              textErrorSelectColumn={textErrorSelectColumn}
              visible={visible}
              actionColumns={actionColumns}
              setNewDataColumns={setNewDataColumns}
              setListArrayColumns={setListArrayColumns}
              setDefaultShowColumn={setDefaultShowColumn}
              setTextErrorSelectColumn={setTextErrorSelectColumn}
              setVisible={setVisible}
              loading={loading}
              tabValue={tabValue}
            />
          </div>
        }
      >
        {formFilter(searchGroup)}
      </CollapseCustom>
    </div>
  );
};

export default AllowanceAction;
