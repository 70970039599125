import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { PageSettings } from '../config/page-settings';

export default ({component: Component, path, pages, actions, ...rest}) => {

  return (
    <PageSettings.Consumer>
      {({checkPermissionAction}) => (
        <Route {...rest} render={(props) => (
          checkPermissionAction(pages, actions) ?
          <Component {...props} /> : <Redirect to='/main/not-found' />
        )} />
      )}
    </PageSettings.Consumer>
  );
}