import React from 'react'
import CollapseCustom from '../../../components/collapse-custom'
import { useIntl, FormattedMessage } from 'react-intl'
import ReportItemRequestActionColumns from './action-columns'
import { Input, Form, Select, DatePicker, Row, Col, Dropdown, Menu, Button } from 'antd'
import LabeRequireForm from '../../../components/label-required-form'
import _ from 'lodash'
import Button01 from '../../../components/v2/button_01'
import { DownOutlined } from '@ant-design/icons'

const { RangePicker } = DatePicker
const { Option } = Select

const ReportItemRequestAction = (props) => {
  const intl = useIntl()
  const { onFilterOrder, actionColumns } = props

  const formFilter = () => {
    return (
      <Form colon={false} className='deliveryForm'>
        <Row gutter={[24, 2]}>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'reportItemRequestColumnsRefType', defaultMessage: 'Reference Type' })}
                  req={false}
                />
              }
            >
              <Select
                allowClear={true}
                placeholder={intl.formatMessage({ id: 'reportItemRequestPHRefType', defaultMessage: 'Select Reference Type' })}
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                showSearch
                onSelect={(value) => onFilterOrder(value, 'refType')}
              >
                {/* {
                  _.map(_.get(orderData, 'data.orderTypes'), (item) => (
                    <Option key={item.orderTypeCode}>
                      {item.orderTypeCode}
                    </Option>
                  ))
                } */}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'reportItemRequestColumnsRefDetail', defaultMessage: 'Reference Detail' })}
                  req={false}
                />
              }
            >
              <Input
                allowClear={true}
                placeholder={intl.formatMessage({ id: 'reportItemRequestPHRefDetail', defaultMessage: 'Enter Reference Detail' })}
                onChange={(e) => onFilterOrder(e.target.value, 'refDetail')}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'reportItemRequestColumnsItemNo', defaultMessage: 'Item Request No.' })}
                  req={false}
                />
              }
            >
              <Input
                allowClear={true}
                placeholder={intl.formatMessage({ id: 'reportItemRequestPHItemNo', defaultMessage: 'Enter Item Request No.' })}
                onChange={(e) => onFilterOrder(e.target.value, 'itemReqNo')}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 2]}>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'reportItemRequestColumnsRecipent', defaultMessage: 'Recipent' })}
                  req={false}
                />
              }
            >
              <Input
                allowClear={true}
                placeholder={intl.formatMessage({ id: 'reportItemRequestPHRecipent', defaultMessage: 'Enter Recipent' })}
                onChange={(e) => onFilterOrder(e.target.value, 'recipent')}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'reportItemRequestColumnsStatus', defaultMessage: 'Status' })}
                  req={false}
                />
              }
            >
              <Select
                allowClear={true}
                placeholder={intl.formatMessage({ id: 'reportItemRequestPHStatus', defaultMessage: 'Select Status' })}
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                showSearch
                onSelect={(value) => onFilterOrder(value, 'status')}
              >
                {/* {
                  _.map(_.get(orderData, 'data.orderTypes'), (item) => (
                    <Option key={item.orderTypeCode}>
                      {item.orderTypeCode}
                    </Option>
                  ))
                } */}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'reportItemRequestColumnsRequestedDate', defaultMessage: 'Requested Date' })}
                  req={false}
                />
              }
            >
              <RangePicker
                allowClear={true}
                placeholder={[intl.formatMessage({ id: 'reportItemRequestPHRequestedDateFrom', defaultMessage: 'Select From Date' }), intl.formatMessage({ id: 'reportItemRequestPHRequestedDateTo', defaultMessage: 'Select To Date' })]}
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                onChange={(value) => onFilterOrder(value, 'requestedDate')}
                style={{ width: '100%' }}
                format="DD/MM/YYYY"
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  };

  const menuColumn = (
    <Menu>
      <Menu.Item>
        <Button style={{ padding: 'unset' }} ghost type="link">
          <FormattedMessage id="expenseBtnExport" defaultMessage="Export Excel" />
        </Button>
      </Menu.Item>
    </Menu>
  );

  return (
    <div style={{ marginTop: '16px' }}>
      <CollapseCustom
        label={intl.formatMessage({ id: `btnFilter`, defaultMessage: 'Filter' })}
        extra={
          <div style={{ display: 'flex' }}>
            <ReportItemRequestActionColumns
              actionColumns={actionColumns}
            />
            <Dropdown overlay={menuColumn} trigger={['click']} placement="bottomRight">
              <Button01 key="submit" type="primary" btnsize="wd_df">
                <FormattedMessage id="btnActionGroup" defaultMessage="Action" />
                <DownOutlined style={{ fontSize: '14px', paddingLeft: '22%' }} />
              </Button01>
            </Dropdown>
          </div>
        }
      >
        {formFilter()}
      </CollapseCustom>
    </div >
  )
}

export default ReportItemRequestAction
