import _ from 'lodash';

const handleKeyDownDecimal2Fixed = (e) => {
  let keyCode = e.key.charCodeAt(0);
  const splitValue = _.split(e.target.value, '.');
  if ((keyCode >= 48 && keyCode <= 57) || keyCode === 65 || keyCode === 66 || keyCode === 46) {
    if (keyCode === 46 && e.target.value.indexOf('.') > 1) e.preventDefault();
    if (keyCode === 46) {
      let sumDot = _.sumBy(e.target.value, (o) => {
        return o.charCodeAt(0) === 46 ? 1 : 0;
      });
      if (sumDot >= 1) e.preventDefault();
    }
  } else if (keyCode === 45) {
    e.preventDefault();
  } else {
    e.preventDefault();
  }
  if (_.get(splitValue, '[1]')) {
    if (_.size(splitValue[1]) >= 2 && keyCode !== 66 && keyCode !== 65 && e.target.selectionStart > e.target.value.indexOf('.'))
      e.preventDefault();
  }
};


const handleKeyDownDecimal3Fixed = (e) => {
  let keyCode = e.key.charCodeAt(0);
  const splitValue = _.split(e.target.value, '.');
  if ((keyCode >= 48 && keyCode <= 57) || keyCode === 65 || keyCode === 66 || keyCode === 46) {
    if (keyCode === 46 && e.target.value.indexOf('.') > 1) e.preventDefault();
    if (keyCode === 46) {
      let sumDot = _.sumBy(e.target.value, (o) => {
        return o.charCodeAt(0) === 46 ? 1 : 0;
      });
      if (sumDot >= 1) e.preventDefault();
    }
  } else if (keyCode === 45) {
    e.preventDefault();
  } else {
    e.preventDefault();
  }
  if (_.get(splitValue, '[1]')) {
    if (_.size(splitValue[1]) >= 3 && keyCode !== 66 && keyCode !== 65 && e.target.selectionStart > e.target.value.indexOf('.'))
      e.preventDefault();
  }
};

const handleKeyDownPhone = (e) => {
  let keyCode = e.key.charCodeAt(0);
  if ((keyCode >= 48 && keyCode <= 57) || keyCode === 65 || keyCode === 66) {
    return true;
  } else {
    e.preventDefault();
  }
};

const handleKeyDownNumber = (e) => {
  let keyCode = e.key.charCodeAt(0);
  if ((keyCode >= 48 && keyCode <= 57) || keyCode === 65 || keyCode === 66) {
    return true;
  } else {
    e.preventDefault();
  }
};

const handleKeyDownDecimal2FixedNegative = (e) => {
  let keyCode = e.key.charCodeAt(0);
  const splitValue = _.split(e.target.value, '.');
  if ((keyCode >= 48 && keyCode <= 57) || keyCode === 65 || keyCode === 66 || keyCode === 46 || keyCode === 45) {
    if (keyCode === 46) {
      let sumDot = _.sumBy(e.target.value, (o) => {
        return o.charCodeAt(0) === 46 ? 1 : 0;
      });
      if (sumDot >= 1) e.preventDefault();
    }
    if (keyCode === 45 && e.target.selectionStart !== 0) {
      e.preventDefault();
    }
  } else {
    e.preventDefault();
  }
  if (_.get(splitValue, '[1]')) {
    if (_.size(splitValue[1]) >= 2 && keyCode !== 66 && keyCode !== 65 && e.target.selectionStart > e.target.value.indexOf('.'))
      e.preventDefault();
  }
};


export { handleKeyDownDecimal2Fixed, handleKeyDownDecimal3Fixed, handleKeyDownPhone, handleKeyDownNumber, handleKeyDownDecimal2FixedNegative };
