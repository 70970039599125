import React from 'react'
import { Checkbox, Row, Col } from 'antd';
import '../../css/index.css'
import { FormattedMessage } from 'react-intl';
import _ from 'lodash'
import Nodata from '../../../image/Nodata.svg'

const SearchItemBrand = (props) => {
  const { itemBrand } = props

  const handleCheckboxChange = (checkedList) => {
    itemBrand.setItemBrandSelect(checkedList);
    itemBrand.setItemBrandInterminate(!!checkedList.length && checkedList.length < itemBrand.itemBrandData.length);
    itemBrand.setItemBrandCheckAll(checkedList.length === itemBrand.itemBrandData.length);
  };

  const handleCheckAllChange = (e) => {
    itemBrand.setItemBrandSelect(e.target.checked ? _.map(itemBrand.itemBrandData, (item) => { return item.itemBrandId }) : []);
    itemBrand.setItemBrandInterminate(false);
    itemBrand.setItemBrandCheckAll(e.target.checked);
  };

  return (
    <div className='searchItemRequestContent'>
      <div className='searchItemRequestContentHeader'>
        <div>
          <span>{_.size(itemBrand.itemBrandSelect)} <FormattedMessage id="textItem" defaultMessage="item" /></span>
        </div>
        <div>
          <span><FormattedMessage id="searchItemRequestTextItemBrand" defaultMessage="Item Brand" /></span>
        </div>
      </div>
      <div className='searchItemRequestContentCheckAll'>
        <Checkbox
          indeterminate={itemBrand.itemBrandInterminate}
          onChange={handleCheckAllChange}
          checked={itemBrand.itemBrandCheckAll}
        >
          <FormattedMessage id="searchItemRequestTextItemBrand" defaultMessage="Item Brand" />
        </Checkbox>
      </div>
      <div style={{ maxHeight: '360px', overflowY: _.size(itemBrand.itemBrandData) < 9 ? 'hidden' : 'scroll' }}>
        {
          _.size(itemBrand.itemBrandData) > 0 ? (
            <Checkbox.Group
              style={{ width: '100%' }}
              value={itemBrand.itemBrandSelect}
              onChange={handleCheckboxChange}
            >
              {
                _.map(itemBrand.itemBrandData, (item) => (
                  <Row className='searchItemRequestContentDetail' style={{ paddingTop: '12px' }}>
                    <Col span={24}>
                      <Checkbox value={item.itemBrandId}>{item.itemBrandName}</Checkbox>
                    </Col>
                  </Row>
                ))
              }
            </Checkbox.Group>
          ) : (
            <div className='searchItemRequestContentNoDataValidate'>
              <div>
                <img src={Nodata} style={{ width: '100px' }}></img>
              </div>
              <div style={{ color: '#e4e4e4' }}>
                <FormattedMessage id="searchItemRequestTextWarehouseNoItemBrand" defaultMessage="No Item Brand" />
              </div>
              <div style={{ color: '#e4e4e4' }}>
                <FormattedMessage id="searchItemRequestTextWarehouseValidateItem" defaultMessage="Please Select Warehouse" />
              </div>
            </div >
          )
        }
      </div >
    </div >
  )
}

export default SearchItemBrand
