import httpClient from '../../components/axiosClient';

const getTaskTypeData = async (type) => {
  const comCode = localStorage.getItem('comCode');
  // transportation
  try {
    const response = await httpClient.get(`/v3/task/manager/company/${comCode}/initial/data?menuType=${type}`);
    if (response.status === 200) {
      return response;
    } else {
      return false;
    }
  } catch (error) {
    return error.response;
  }
};

const getCustomerData = async (allCustomer, type) => {
  const comCode = localStorage.getItem('comCode');
  // transportation
  try {
    // const response = await httpClient.get(`/v3/business-partner/manager/company/${comCode}/customer-informations/get/load-more?indexStart=1&indexEnd=${allCustomer}&menuType=${type}`);
    const response = await httpClient.get(
      `/v3/business-partner/manager/company/${comCode}/customer-informations/get/load-more?indexStart=1&indexEnd=1500&menuType=${type}`
    );
    if (response.status === 200) {
      return response;
    } else {
      return false;
    }
  } catch (error) {
    return error.response;
  }
};

const getCustomerLocationAndConatact = async (id, type) => {
  const comCode = localStorage.getItem('comCode');
  const body = {
    customerId: id,
  };
  // transportation
  try {
    const response = await httpClient.post(
      `/v3/business-partner/manager/company/${comCode}/customer-address-books/from-to-address?menuType=${type}`,
      body
    );
    if (response.status === 200) {
      return response;
    } else {
      return false;
    }
  } catch (error) {
    return error.response;
  }
};

const getDisTance = async (latlng, type) => {
  const comCode = localStorage.getItem('comCode');
  const body = {
    latFrom: parseFloat(latlng.fromLat),
    lngFrom: parseFloat(latlng.fromLng),
    latTo: parseFloat(latlng.toLat),
    lngTo: parseFloat(latlng.toLng),
  };
  // transportation
  try {
    const response = await httpClient.post(`/v3/task/manager/company/${comCode}/polyline/point-to-point?menuType=${type}`, body);
    if (response.status === 200) {
      return response;
    } else {
      return false;
    }
  } catch (error) {
    return error.response;
  }
};

const getMemberAssignee = async (body, type) => {
  const comCode = localStorage.getItem('comCode');

  // transportation
  try {
    const response = await httpClient.post(`/v3/task/manager/company/${comCode}/member/candidate?menuType=${type}`, body);
    if (response.status === 200) {
      return response;
    } else {
      return false;
    }
  } catch (error) {
    return error.response;
  }
};

const getCustomeField = async (taskTypeText, taskTypeId, type) => {
  const comCode = localStorage.getItem('comCode');

  // transportation
  try {
    const response = await httpClient.get(
      `/v3/custom-field/manager/company/${comCode}/custom-fields/entity/${taskTypeText}/entity-id/${taskTypeId}?menuType=${type}`
    );
    if (response.status === 200) {
      return response;
    } else {
      return false;
    }
  } catch (error) {
    return error.response;
  }
};

const addContact = async (body) => {
  const comCode = localStorage.getItem('comCode');

  try {
    const response = await httpClient.post(`/v3/business-partner/manager/company/${comCode}/customer-contacts`, body);
    if (response.status === 200) {
      return response;
    } else {
      return false;
    }
  } catch (error) {
    return error.response;
  }
};

const searchLocationCompany = async (value) => {
  const comCode = localStorage.getItem('comCode');
  const body = {
    name: value ? value : '',
    comCode: comCode,
  };

  try {
    const response = await httpClient.post(`/v1/resource/manager/search/company/location/type`, body);
    if (response.status === 200) {
      return response;
    } else {
      return false;
    }
  } catch (error) {
    return error.response;
  }
};

const searchLocationCustomer = async (value, customer) => {
  const comCode = localStorage.getItem('comCode');
  const body = {
    name: value ? value : '',
    customerId: customer,
  };

  try {
    const response = await httpClient.post(`/v1/resource/manager/search/customer/location/type`, body);
    if (response.status === 200) {
      return response;
    } else {
      return false;
    }
  } catch (error) {
    return error.response;
  }
};

const addFromLocation = async (body) => {
  const comCode = localStorage.getItem('comCode');

  try {
    const response = await httpClient.post(`/v3/business-partner/manager/company/${comCode}/add/address-book`, body);
    if (response.status === 200) {
      return response;
    } else {
      return false;
    }
  } catch (error) {
    return error.response;
  }
};

const deletedToAddress = async (id) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.delete(`/v3/business-partner/manager/company/${comCode}/customer-address-books/${id}`);

    if (response.status === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};

const getTaskTypeByTeam = async (body) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v4/task/manager/company/${comCode}/task/service/task-type`, body);

    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};

const getMemberAssigneeNew = async (body, type) => {
  const comCode = localStorage.getItem('comCode');

  // transportation
  try {
    const response = await httpClient.post(`/v4/task/manager/company/${comCode}/task-candidates/${type}`, body);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return error.response;
  }
};

const getRefAuto = async (body) => {
  const comId = localStorage.getItem('comId');
  // transportation
  try {
    const response = await httpClient.post(`/v4/elasticsearch/manager/company/${comId}/reference`, body);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return error.response;
  }
};

const getMemberAssigneeNewer = async (body) => {
  const comCode = localStorage.getItem('comCode');

  // transportation
  try {
    const response = await httpClient.post(`/v5/resource/manager/company/${comCode}/web/memberteam/list`, body);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return error.response;
  }
};

export {
  getTaskTypeData,
  getCustomerData,
  getCustomerLocationAndConatact,
  getDisTance,
  getMemberAssignee,
  getCustomeField,
  addContact,
  searchLocationCompany,
  addFromLocation,
  searchLocationCustomer,
  deletedToAddress,
  getTaskTypeByTeam,
  getMemberAssigneeNew,
  getRefAuto,
  getMemberAssigneeNewer,
};
