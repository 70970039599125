import React, { useState, useEffect } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import {
  Row,
  Input,
  Col,
  DatePicker,
  Select,
  Icon,
  Dropdown,
  Menu,
  ConfigProvider,
} from 'antd';
import { useAppContext } from '../../includes/indexProvider';
import Button01 from '../v2/button_01';
import moment from 'moment';
import enUS from 'antd/es/locale/en_US';
import thTH from 'antd/es/locale/th_TH';
import styles from './css/action-report.css';
import _ from 'lodash';

const { Option } = Select;
const { RangePicker } = DatePicker;

const ActionReportV5 = (props) => {
  const { value, fnc, tabStatusData } = props;
  const intl = useIntl();
  const app = useAppContext();
  const langValue = app.state.langValue;
  const dateFormat = 'ddd, MMM DD YYYY';
  const [locale, setLocale] = useState(enUS);

  useEffect(() => {
    changeLocale();
  }, []);

  const changeLocale = () => {
    let codeLang = langValue ? langValue : 'EN';
    let changeLang = codeLang === 'EN' ? enUS : thTH;

    setLocale(changeLang);
    if (!changeLang) {
      moment.locale('en');
    } else {
      moment.locale('th-th');
    }
  };

  const columnNewSearc = value.columns.filter(
    (col) => !value.ignoreSearchColumns.includes(col.key),
  );

  const filterNextStatus =
    tabStatusData &&
    tabStatusData.filter((item) => {
      return item.isNextStatus === true;
    });

  const handleClikMenu = ({ item, key, keyPath, domEvent }) => {
    const filterName =
      tabStatusData &&
      tabStatusData.filter((item) => {
        return item.statusCode === key;
      });
    fnc.setNameStatus(_.get(filterName[0], 'name'));
    fnc.setNameStatusCode(_.get(filterName[0], 'statusCode'));
  };

  const menuOverlay = (
    <Menu onClick={handleClikMenu}>
      {filterNextStatus &&
        filterNextStatus.map((item) => (
          <Menu.Item key={item.statusCode}>{item.name}</Menu.Item>
        ))}
    </Menu>
  );


  return (
    <div className="action-report">
      <Row className="action-report-row">
        <Col span={12} className="action-report-col">
          <div className="action-report-div-left">
            <Input
              className="action-report-input"
              placeholder={intl.formatMessage({
                id: 'hintSearch',
                defaultMessage: 'Search',
              })}
              prefix={
                <Icon style={{ color: 'rgba(0,0,0,.25)' }} type="search" />
              }
              value={value.textValue}
              onChange={(e) => fnc.onChangeSearch(e.target.value)}
              autoComplete="off" 
            />
            <Select
              className="action-report-select-column"
              placeholder={intl.formatMessage({
                id: 'hintSelect',
                defaultMessage: 'Select',
              })}
              value={value.fliterByColumn}
              onSelect={(value) => fnc.onFliterByColumn(value)}
            >
              <Option value="all">
                <FormattedMessage
                  id="lblSeleteAllColumns"
                  defaultMessage="All columns"
                />
              </Option>
              {columnNewSearc.map((item, i) => (
                <Option key={i} value={item.key}>
                  {item.title}
                </Option>
              ))}
            </Select>
          </div>
        </Col>
        <Col span={12} className="action-report-col">
          <div className="action-report-div-right">
            <div style={{ paddingRight: '20px' }}>
              <ConfigProvider locale={locale}>
                <RangePicker
                  className="action-report-rangePicker"
                  // defaultValue={[moment(new Date(), dateFormat), moment(new Date(), dateFormat)]}
                  value={value.defaultDate}
                  format={dateFormat}
                  onChange={fnc.onSelectRangePicker}
                  allowClear={false}
                />
              </ConfigProvider>
            </div>

            <div>
              <Dropdown.Button
                className={
                  _.size(value.selectKeysTable) !== 0
                    ? 'action-report-dropdown-color'
                    : null
                }
                overlay={menuOverlay}
                icon={<Icon type="down" />}
                disabled={_.size(value.selectKeysTable) !== 0 ? false : true}
                onClick={() =>
                  fnc.handleClickStatus(
                    value.nameStatusCode !== undefined
                      ? value.nameStatusCode
                      : _.get(filterNextStatus[0], 'statusCode'),
                  )
                }
              >
                {value.nameStatus !== undefined
                  ? value.nameStatus
                  : _.get(filterNextStatus[0], 'name')}
              </Dropdown.Button>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ActionReportV5;
