import React from 'react';
import CustomerForm from './customer-pending';
import { FormattedMessage, useIntl } from 'react-intl';
import Button_01 from '../../../../../components/v2/button_01';
import CustomTableComponent from '../../../components/table';
import PriceForm from './price-form-pending';
import { Button, Divider } from 'antd';
import { numberFormatter } from '../../../../../component-function/fnc-inputnumber-currency';
import _ from 'lodash';

const DetailsTabView = ({ form, property }) => {
  const intl = useIntl();
  const {
    handleOpenModalUpload,
    setDataForm,
    record,
    setVisibleItem,
    initialMemberList,
    handleOpen,
    selectItem,
    setSelectItem,
    attData,
    setAttData,
    initialTaxList,
    pageTable,
    sizeTable,
    setPageTable,
    setSizeTable,
  } = property;

  const dataForm = record;

  console.log('qtyTxt', selectItem);

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: 50,
      fixed: 'left',
      render: (text, record, index) => <span>{index + 1}</span>,
    },
    {
      title: <FormattedMessage id="TFItemCode" defaultMessage="Item Code" />,
      dataIndex: 'itemCode',
      key: 'itemCode',
      sorter: true,
      fixed: 'left',
      width: 200,
    },
    {
      title: <FormattedMessage id="TFItem" defaultMessage="Item" />,
      dataIndex: 'itemName',
      key: 'itemName',
      sorter: true,
      width: 250,
    },
    {
      title: <FormattedMessage id="TFLotNoorSerailNo" defaultMessage="Lot No. or Serial No." />,
      key: 'lotOrSerialNo',
      dataIndex: 'lotOrSerialNo',
      sorter: true,
      width: 200,
    },
    {
      title: <FormattedMessage id="TFQTY" defaultMessage="Qty" />,
      dataIndex: 'qty',
      key: 'qty',
      sorter: true,
      width: 150,
      render: (text, record, index) => (_.get(record, 'qty') ? _.get(record, 'qty') + ' ' + _.get(record, 'uom')   : '-'),
    },
    {
      title: <FormattedMessage id="TFManufacturingDate" defaultMessage="Manufacturing Date" />,
      dataIndex: 'manufacturingDateTxt',
      key: 'manufacturingDateTxt',
      sorter: true,
      width: 200,
    },
    {
      title: <FormattedMessage id="TFExpiredDate" defaultMessage="Expired Date" />,
      key: 'expiredDateTxt',
      dataIndex: 'expiredDateTxt',
      width: 200,
      sorter: true,
    },
    {
      title: <FormattedMessage id="TFRecivedFDA" defaultMessage="FDA" />,
      key: 'fda',
      dataIndex: 'fda',
      width: 150,
    },
    {
      title: <FormattedMessage id="TFRecivedDate" defaultMessage="Recived Date" />,
      key: 'receiveDate',
      dataIndex: 'receiveDate',
      width: 200,
      sorter: true,
    },
    {
      title: <FormattedMessage id="TFRecivedBy" defaultMessage="Recived By" />,
      key: 'receiveBy',
      dataIndex: 'receiveBy',
      width: 200,
      sorter: true,
    },
    {
      title: <FormattedMessage id="TFRemark" defaultMessage="Remark" />,
      key: 'remarkTran',
      dataIndex: 'remarkTran',
      width: 200,
      sorter: true,
      render: (text, record, index) => (_.get(record, 'remarkTran') ? _.get(record, 'remarkTran')  : _.get(record, 'remark') || '-'),
    },
  ];

  const columnsAttach = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
      fixed: 'left',
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsFileName', defaultMessage: 'File Name' }),
      dataIndex: 'fileName',
      key: 'fileName',
      sorter: true,
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsRemark', defaultMessage: 'Remark' }),
      dataIndex: 'remark',
      key: 'remark',
    },
  ];

  const deleteAtt = (value) => {
    const filterSelect = _.filter(attData, (item) => {
      return item.fileHash !== value;
    });
    console.log('fileHash', filterSelect);
    setAttData(filterSelect);
  };

  const handleEditUploadInside = (value) => {
    handleOpenModalUpload(value);
  };

  const deleteItem = (value) => {
    const filterSelect = _.filter(selectItem, (item) => {
      return item.itemMasterId !== value;
    });
    setSelectItem(filterSelect);
  };

  const contentActionItem = (record) => {
    console.log('contentAction', record);
    return (
      <div style={{ display: 'grid' }}>
        <div>
          <Button style={{ width: 100 }} type="link" ghost onClick={() => deleteItem(record.itemMasterId)}>
            <FormattedMessage id="btnDelete" defaultMessage="Delete" />
          </Button>
        </div>
      </div>
    );
  };

  const contentAction = (record) => {
    console.log('contentAction', record);
    return null;
  };

  return (
    <div className="scroll-sm scroll-height">
      <>
        <div className="item-title">
          <span className="item-title-text">
            <FormattedMessage id="quotationLabelDetails" defaultMessage="Details" />
          </span>

          <div style={{ width: '15%', paddingTop: '3px' }}>
            <p
              style={{
                // backgroundColor: record['statusColor'],
                backgroundColor: _.get(dataForm, 'statusColor'),
                color: 'white',
                textAlign: 'center',
                borderRadius: '10px',
                // height: '23px',
                // width: '100%',
                padding: ' 0px 10px',
              }}
            >
              {_.get(dataForm, 'statusName')}
            </p>
          </div>
        </div>

        <Divider type="horizontal" style={{ marginBottom: '10px', marginTop: '10px' }} />
      </>
      <div className="content-body">
        <CustomerForm form={form} setDataForm={setDataForm} record={record} initialMemberList={initialMemberList} handleOpen={handleOpen} />
      </div>
      <div>
        <div className="item-title">
          <span className="item-title-text">
            <FormattedMessage id="quotationLabelItem" defaultMessage="Item" />
          </span>
          <div>
            {/* <Button_01 key="add" style={{ margin: '0px 0px 0px 10px' }} type="primary" btnsize="wd_df" onClick={() => setVisibleItem(true)}>
              <FormattedMessage id="btnAdd" defaultMessage="Add" />
            </Button_01> */}
          </div>
        </div>
        <Divider type="horizontal" style={{ marginBottom: '10px', marginTop: '10px' }} />
        <div className="content-body">
          <CustomTableComponent
            onRow={false}
            role={false}
            columns={columns}
            dataSource={_.get(dataForm, 'itemList')}
            scroll={{ x: 1500 }}
            contentAction={contentActionItem}
            paginationPage={pageTable}
            setPaginationPage={setPageTable}
            paginationShow={sizeTable}
            setPaginationSize={setSizeTable}
          />
        </div>
      </div>
    </div>
  );
};

export default DetailsTabView;
