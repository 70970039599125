import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Row, Col } from 'antd';
import NumberFormat from 'react-number-format';
import _ from 'lodash';
import LabeRequireForm from '../../../components/label-required-form';

const SummaryWaiting = (props) => {
  const intl = useIntl();
  const { summary } = props;
console.log('summary',summary,_.get(summary,'activeQuote.[0].val'));
  return (
    <div className="price-report">
      <Row className="price-report-row">
        <Col span={8} className="price-report-col">
          <div className="price-report-div-text">
            <p className="price-report-text-p">
            <LabeRequireForm text={intl.formatMessage({ id: 'BLUnpaidBalance', defaultMessage: 'Unpaid Balance' })} req={false} bold={true} />
            {/* <FormattedMessage id="" defaultMessage="" /> */}
            </p>
            <p className="price-report-text-span" >{_.get(summary,'balance.[0].val') }</p>
          </div>
        </Col>
        <Col span={8} className="price-report-col">
          <div className="price-report-div-text">
            <p className="price-report-text-p">
            <LabeRequireForm text={intl.formatMessage({ id: 'BLUnpaidOndue', defaultMessage: 'Unpaid On due' })} req={false} bold={true} />
            </p>
            <p className="price-report-text-span" >{_.get(summary,'ondue.[0].val')}</p>
          </div>
        </Col>
        <Col span={8} className="price-report-col">
          <div className="price-report-div-text">
            <p className="price-report-text-p">
            <LabeRequireForm text={intl.formatMessage({ id: 'BLUnpaidOverdue', defaultMessage: 'Unpaid Overdue' })} req={false} bold={true} />
            </p>
            <p className="price-report-text-span" >{_.get(summary,'overdue.[0].val')}</p>
            {/* <NumberFormat value={_.floor(summary.avgWonQuote, 2).toFixed(2)} displayType={'text'} thousandSeparator={true}
              suffix={' ' + intl.formatMessage({ id: 'labelTHB', defaultMessage: 'THB' })} className="price-report-text-span" /> */}
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default SummaryWaiting;
