import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Card, Progress, Input, Select, Icon, Tag, Modal } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import Button01 from '../../components/v2/button_01';
import ItemMasterSummaryTable from './item-master-summary-table';
import AssignWarehouseModal from '../../components/modal-assign-warehouse';
import AssignWarehouseEditModal from '../../components/modal-assign-warehouse-edit'
import { getWarehouseByItemMaster } from '../../controllers/item-master/warehouse';
import _, { debounce } from 'lodash'
import { deleteWarehouseStock } from '../../controllers/warehouse/warehouse';
import { successNotification, errorNotification } from '../../components/v2/notification';

const { Option } = Select;



const renderProgress = (record) => {
  let color = '';
  let percent = 0;

  if (record.itemQty === 0) {
    color = '#e73845';
    percent = 100;
  } else if (record.itemQty < record.iteMinQty) {
    color = '#febb02';
    percent = (record.itemQty / record.itemMaxQty) * 100;
  } else if (record.itemQty > record.iteMinQty) {
    color = '#6490cf';
    percent = (record.itemQty / record.itemMaxQty) * 100;
  }

  return (
    <div className="progress-item">
      <Progress style={{ width: '70%' }} className="bar-progress-custom" strokeColor={color} percent={percent} showInfo={false} />{' '}
      <span className="progress-qty-value">{record.itemQty}</span>
    </div>
  );
}

const ItemMasterSummary = (props) => {
  const { itemasterId, data } = props
  const [openAssignModal, setOpenAssignModal] = useState(false)
  const [openAssignEditModal, setOpenAssignEditModal] = useState(false)
  const [warehouseData, setWarehouseData] = useState([])
  const [searchValue, setSearchValue] = useState()
  const [searchColumn, setSearchColumn] = useState()
  const [total, setTotal] = useState();
  const [paginationPage, setPaginationPage] = useState(1);
  const [paginationSize, setPaginationSize] = useState(10);
  const [extraSort, setExtraSort] = useState();
  const [fieldSort, setFieldSort] = useState();
  const [orderSort, setOrderSort] = useState();
  const [trigger, setTrigger] = useState(false)
  const [stockId, setStockId] = useState()
  const intl = useIntl();
  const summaryColumn = [
    {
      title: '#',
      dataIndex: 'key',
      key: 'key',
      render: (text, record, index) => index + 1,
    },
    {
      title: intl.formatMessage({ id: 'itemMasterSummaryWarehouseCode', defaultMessage: 'Warehouse Code' }),
      dataIndex: 'warehouseCode',
      key: 'warehouseCode',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: 'itemMasterSummaryWarehouse', defaultMessage: 'Warehouse' }),
      dataIndex: 'warehouseName',
      key: 'warehouseName',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: 'itemMasterSummaryType', defaultMessage: 'Type' }),
      dataIndex: 'warehouseTypeDisplay',
      key: 'warehouseTypeDisplay',
    },
    {
      title: intl.formatMessage({ id: 'itemMasterSummaryQty', defaultMessage: 'Qty' }),
      dataIndex: 'qty',
      key: 'qty',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      width: 240,
      render: (text, record, index) => renderProgress(record),
    },
    {
      title: intl.formatMessage({ id: 'itemMasterSummaryStatus', defaultMessage: 'Status' }),
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (text, record, index) => <Tag className='status-tag' color={record.statusColor}>{record.status}</Tag>
    }
  ]

  const handleOpenModal = () => {
    setOpenAssignModal(true)
  }

  useEffect(() => {
    async function getWarehouse() {
      const body = {
        "searchVal": searchValue,
        "searchColumn": searchColumn,
        "page": searchValue === '' ? paginationPage : 1,
        "limit": paginationSize,
        "orderBy": orderSort,
        "orderType": fieldSort
      }
      const response = await getWarehouseByItemMaster(itemasterId, body)
      setWarehouseData(_.get(response, 'data.data.warehouseList'))
      setTotal(_.get(response, 'data.data.allItem'))
      // await setDataModel(response);
    }
    if (itemasterId) {
      getWarehouse();
    }
  }, [itemasterId, searchValue, searchColumn, extraSort, trigger])

  const handleSearch = debounce((fieldChang) => {
    setSearchValue(fieldChang)
  }, 200);

  const handleSelectColumns = (value) => {
    setSearchColumn(value)
  }

  const handlePagination = (page) => {
    setPaginationPage(page);
  };

  const handleSizeChange = (current, size) => {
    setPaginationSize(size);
    setPaginationPage(current);
  };

  const handleChange = (pagination, sorter, extra) => {
    let field = extra.field;
    if (extra.order) {
      if (extra.order === 'descend') {
        setOrderSort('desc');
      } else {
        setOrderSort('asc');
      }
    } else {
      setOrderSort();
    }
    setExtraSort(extra);
    setFieldSort(field);
  };

  const handleEdit = (record) => {
    setOpenAssignEditModal(true)
    setStockId(record.stockId)
  }
  console.log("stockId", stockId)
  const handleDelete = (id) => {
    Modal.confirm({
      className: 'maintenance-report-modal-confirm',
      centered: true,
      icon: null,
      title: intl.formatMessage({ id: 'lblDelete', defaultMessage: 'Are you sure you want to delete ?' }),
      okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        try {
          const response = await deleteWarehouseStock(id);
          if (response.status === 200) {
            successNotification(_.get(response.data.status, 'message'));
            setTrigger((current) => !current);
          } else {
            errorNotification(_.get(response.data.status, 'message'));
          }
        } catch (error) {
          errorNotification(_.get(error.response.data.status, 'message'));
        }
      },
      onCancel() { },
    });
  }

  return (
    <div>
      <Card
        title={intl.formatMessage({ id: 'itemMasterSummaryTextHeader', defaultMessage: 'Summary By Warehouse' })}
        extra={
          <Button01
            style={{ margin: '0' }}
            onClick={handleOpenModal}
            key="submit" type="primary" btnsize="wd_df"
          >
            <FormattedMessage id="userMgntUsersDetailBtnAssign" defaultMessage="Assign" />
          </Button01>
        }
      >
        <div style={{ padding: '10px 0' }}>
          <Input
            className="action-report-input action-input"
            placeholder={intl.formatMessage({ id: 'hintSearch', defaultMessage: 'Search' })}
            prefix={<Icon style={{ color: 'rgba(0,0,0,.25)' }} type="search" />}
            onChange={(e) => handleSearch(e.target.value)}
          />
          <Select
            className="action-report-select-column action-select"
            defaultValue="all"
            onSelect={handleSelectColumns}
          >
            <Option value="all"><FormattedMessage id="itemMasterSummaryAllColumns" defaultMessage="All Columns" /></Option>
            <Option value="warehouseCode"><FormattedMessage id="itemMasterSummaryWarehouseCode" defaultMessage="Warehouse Code" /></Option>
            <Option value="warehouseName"><FormattedMessage id="itemMasterSummaryWarehouse" defaultMessage="Warehouse" /></Option>
            <Option value="qty"><FormattedMessage id="itemMasterSummaryQty" defaultMessage="Qty" /></Option>
            <Option value="Status"><FormattedMessage id="itemMasterSummaryStatus" defaultMessage="Status" /></Option>
          </Select>
        </div>
        <ItemMasterSummaryTable
          columns={summaryColumn}
          dataSource={warehouseData}
          total={total}
          paginationPage={paginationPage}
          fnc={{
            handlePagination,
            handleSizeChange,
            handleChange,
            handleEdit,
            handleDelete,
          }}
        />
      </Card>
      <AssignWarehouseModal
        triggerVisible={openAssignModal}
        setTriggerVisible={setOpenAssignModal}
        itemasterId={itemasterId}
        data={data}
        setTrigger={setTrigger}
      />

      <AssignWarehouseEditModal
        triggerVisible={openAssignEditModal}
        setTriggerVisible={setOpenAssignEditModal}
        itemasterId={itemasterId}
        data={data}
        stockId={stockId}
        setTrigger={setTrigger}
      />
    </div>
  )
}

export default ItemMasterSummary