import React, { useState, useEffect, useRef, useCallback, createContext, useContext, useMemo } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import {
  Button,
  // Card,
  Select,
  Form,
  Modal,
  Switch,
  Icon,
  Popover,
  notification,
} from 'antd';
import { PageSettings } from './config/page-setting';
import { PageSettings as mPageSettings } from '../../../config/page-settings';
import AddShiftType from './shift-type-add';
import EditShiftType from './shift-type-edit';
//import EditShiftType from './shift-type-form-edit'
import httpClient from '../../../components/axiosClient';
import _ from 'lodash';
import Card from '../../../components/v2/card';
import Table from '../../../components/v2/table';
import Button1 from '../../../components/v2/button_01';
import Button2 from '../../../components/v2/button_02';
import styled from 'styled-components';

const { Option } = Select;
let id = 0;

// const StylePopover = styled(Popover)`
//   .ant-popover-placement-leftTop > .ant-popover-content > .ant-popover-arrow {
//     border-top-color: #1D3557;
//     border-right-color: #1D3557;
//   }
// `

export const ShiftSkillSettings = createContext();

export default () => {
  const mApp = useContext(mPageSettings);
  const intl = useIntl();
  const {
    shiftData,
    orgId,
    keyTab,
    refreshTable,
    setRefreshTable,
    switchShiftRules,
    setswitchSubteam,
    comId,
    parentOrg,
    setParentOrg,
    setStatusSubTeam,
    statusSubTeam,
  } = useContext(PageSettings);

  const [visible, setVisible] = useState(false);
  const [visibleEdit, setVisibleEdit] = useState(false);
  const [triggerApi, setTriggerApi] = useState(false);
  const refContainer = useRef(null);
  const [formRef, setFormRef] = useState(null);
  const [formRefEdit, setFormRefEdit] = useState(null);
  const [skillArr, setSkillArr] = useState([{}]);
  const [shiftTypeEdit, setShiftTypeEdit] = useState([]);
  const [visiblePopover, setVisiblePopover] = useState(false);
  const [shiftTypeRuleId, setShiftTypeRuleId] = useState('');

  const [showPopover, setShowPopover] = useState(false);
  const [shiftType, setShiftType] = useState([]);
  const [trigerLoading, setTrigerLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const [extra, setExtra] = useState();
  const [field, setField] = useState();
  const [order, setOrder] = useState();

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      render: (text, record, index) => index + 1,
    },
    {
      title: intl.formatMessage({ id: 'orgTabShiftSettingShiftTypeColumnShiftType', defaultMessage: 'Shift type' }),
      dataIndex: 'shiftName',
      key: 'shiftName',
      // onFilter: (value, record) => record.shiftName.indexOf(value) === 0,
      // sorter: (a, b) => a.shiftName - b.shiftName,
      sorter: 'true',
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: 'orgTabShiftSettingShiftTypeColumnMember', defaultMessage: 'Member' }),
      dataIndex: 'members',
      key: 'members',
      // sorter: (a, b) => a.minMem - b.minMem,
      sorter: 'true',
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: 'orgTabShiftSettingShiftTypeColumnApplyOn', defaultMessage: 'Apply on' }),
      dataIndex: 'applyOn',
      key: 'applyOn',
      // onFilter: (value, record) => record.applyOn.indexOf(value) === 0,
      // sorter: (a, b) => a.applyOn - b.applyOn,
      sorter: 'true',
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: 'orgTabShiftSettingShiftTypeColumnRole', defaultMessage: 'Role' }),
      dataIndex: 'roles',
      key: 'roles',
      // onFilter: (value, record) => record.role.indexOf(value) === 0,
      // sorter: (a, b) => a.roles - b.roles,
      sorter: 'true',
      sortDirections: ['descend', 'ascend'],
      render: (text, record, index) => <span>{record.rolesName}</span>,
    },
    // {
    //   title: intl.formatMessage({ id: 'orgTabShiftSettingShiftTypeColumnSkills', defaultMessage: 'Skills' }),
    //   dataIndex: 'shiftSkillRules',
    //   key: 'shiftSkillRules',
    //   // onFilter: (value, record) => record.shiftSkillRules.indexOf(value) === 0,
    //   sorter: (a, b) => a.shiftSkillRules - b.shiftSkillRules,
    //   sortDirections: ['descend', 'ascend'],
    // },
    {
      title: intl.formatMessage({ id: 'orgTabShiftSettingShiftTypeColumnPriority', defaultMessage: 'Priority' }),
      dataIndex: 'priority',
      key: 'priority',
      // onFilter: (value, record) => record.priority.indexOf(value) === 0,
      // sorter: (a, b) => a.priority - b.priority,
      sorter: 'true',
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: '',
      key: 'options',
      dataIndex: 'options',
      align: 'center',
      width: '5%',
      render: (text, record, index) => {
        return (record.options = (
          <Popover
            // className="popover-style"
            // visible={showPopover}
            // visiblePopover={showPopover}
            // onVisibleChange={handleVisibleChange}
            zIndex={999}
            key={index}
            content={actionPopover(record)}
            placement="leftTop"
          // trigger="click"
          // onBlur={() => setShowPopover(false)}
          >
            <Icon key={index} type="more" />
          </Popover>
        ));
      },
    },
  ];

  const workDay = [
    {
      id: 'mon',
      day: intl.formatMessage({ id: 'orgTabShiftSettingWorkDayMonday', defaultMessage: 'Monday' }),
    },
    {
      id: 'tue',
      day: intl.formatMessage({ id: 'orgTabShiftSettingWorkDayTuesday', defaultMessage: 'Tuesday' }),
    },
    {
      id: 'wed',
      day: intl.formatMessage({ id: 'orgTabShiftSettingWorkDayWednesday', defaultMessage: 'Wednesday' }),
    },
    {
      id: 'thu',
      day: intl.formatMessage({ id: 'orgTabShiftSettingWorkDayThursday', defaultMessage: 'Thursday' }),
    },
    {
      id: 'fri',
      day: intl.formatMessage({ id: 'orgTabShiftSettingWorkDayFriday', defaultMessage: 'Friday' }),
    },
    {
      id: 'sat',
      day: intl.formatMessage({ id: 'orgTabShiftSettingWorkDaySaturday', defaultMessage: 'Saturday' }),
    },
    {
      id: 'sun',
      day: intl.formatMessage({ id: 'orgTabShiftSettingWorkDaySunday', defaultMessage: 'Sunday' }),
    },
  ];

  // useEffect(() => {
  //   shiftTypeData();
  // }, [orgId, refreshTable, keyTab, trigerLoading, extra]);

  useEffect(() => {
    shiftTypeData();
  }, [orgId, triggerApi, keyTab, trigerLoading, extra]);

  const shiftTypeData = async () => {
    setLoading(true);
    let sorter = extra === undefined ? `orderby=shiftName&ordertype=asc` : `orderby=${field}&ordertype=${order}`;

    try {
      const result = await httpClient.get(`/resource/manager/organization/${orgId}/shift/rules?${sorter}&comId=${mApp.comId}`);
      if (result.status === 200) {
        setShiftType(result.data.shiftTypeRules);
        setLoading(false);
      } else {
        setShiftType([]);
        setLoading(false);
      }
    } catch {
      setShiftType([]);
      setLoading(false);
    }
  };

  const showModal = () => {
    setVisible(true);
  };

  const showModalEdit = (record) => {
    setVisibleEdit(true);
    setShiftTypeEdit(record);
    setShowPopover(false);
  };

  const handleApply = async (e) => {
    formRef.validateFields(async (err, values) => {
      if (err) {
        return;
      }
      Modal.confirm({
        className: 'shift-modal-confirm',
        icon: null,
        title: intl.formatMessage({
          id: 'orgTabShiftSettingShiftTypeModalConfirmSaveTeamShift',
          defaultMessage: 'Are you sure to save team shift ?',
        }),
        centered: true,
        okButtonProps: {
          style: {
            width: '96px',
            background: '#6490cf',
            border: '1px solid #6490cf',
            borderRadius: '8px',
            hover: {
              background: '#ffffff',
              color: '#6490cf',
            },
          },
          type: 'primary',
        },
        cancelButtonProps: {
          style: {
            width: '96px',
            border: '1px solid #6490cf',
            borderRadius: '8px',
            backgroundColor: '#ffffff',
            color: '#6490CF',
            
          },
          type: 'primary',
        },
        async onOk() {
          const {
            shiftType,
            minmum,
            maximun,
            shiftSkillRules,
            work_day,
            priority,
            continueShiftRestrict,
            maxContinueWorkDay,
            roles,
          } = values;

          const shiftSkill = shiftSkillRules.filter((item) => {
            return item.minSkillLv !== undefined;
          });

          const newArrShiftSkill = shiftSkill.map((item) => {
            return {
              skillId: String(item.skillId),
              minSkillLv: item.minSkillLv,
              minReqMem: 1,
            };
          });

          const mapDay = work_day.map((item) => {
            let found = workDay.findIndex((e) => (e.day.indexOf(item) !== -1 ? true : false));
            let myObj = new Object();
            if (found > -1) {
              myObj[workDay[found].id] = true;
            } else {
              myObj[workDay[found].id] = false;
            }
            return myObj;
          });

          const mon = mapDay.find((item) => item.mon) === undefined ? false : true;
          const tue = mapDay.find((item) => item.tue) === undefined ? false : true;
          const wed = mapDay.find((item) => item.wed) === undefined ? false : true;
          const thu = mapDay.find((item) => item.thu) === undefined ? false : true;
          const fri = mapDay.find((item) => item.fri) === undefined ? false : true;
          const sat = mapDay.find((item) => item.sat) === undefined ? false : true;
          const sun = mapDay.find((item) => item.sun) === undefined ? false : true;

          const body = {
            comId: comId,
            orgId: orgId,
            shiftId: shiftType,
            minMem: minmum,
            maxMem: maximun,
            mon: mon,
            tue: tue,
            wed: wed,
            thu: thu,
            fri: fri,
            sat: sat,
            sun: sun,
            shiftSkillRules: newArrShiftSkill,
            priority: priority,
            roles: roles,
            continueShiftRestrict: continueShiftRestrict,
            maxContinueWorkDay: parseInt(maxContinueWorkDay),
          };

          try {
            const response = await httpClient.post('/resource/manager/organization/shift-type/rules/create', body);
            if (response.status === 200) {
              shiftTypyNotificationSuccess(response.data.message);
              // setRefreshTable(!refreshTable);
              setTriggerApi((current) => !current);
              setTrigerLoading(!trigerLoading);
            } else {
              shiftTypeNotificationError(response.message);
            }
          } catch (error) {
            shiftTypeNotificationError(error.response.data.message);
          }
          formRef.resetFields();
          setVisible(false);
        },
        onCancel() { },
      });
    });
    setShowPopover(false);
  };

  const handleChange = (pagination, sorter, extra) => {
    // let field = extra.field;
    // let order = extra.order === 'descend' ? 'desc' : 'asc';
    // shiftTypeData(extra, field, order);
    let field = extra.field;
    if (extra.order) {
      if (extra.order === 'descend') {
        setOrder('desc');
      } else {
        setOrder('asc');
      }
    } else {
      setOrder('');
    }
    setExtra(extra);
    setField(field);
  };

  const handleCancel = () => {
    formRef.validateFields(async (err, values) => {
      if (err) {
        return;
      }
      formRef.resetFields();
    });
    formRef.resetFields();
    setVisible(false);
  };

  const saveFormRef = useCallback((node) => {
    if (node !== null) {
      setFormRef(node);
    }
  }, []);

  const saveFormRefEdit = useCallback((node) => {
    if (node !== null) {
      setFormRefEdit(node);
    }
  }, []);

  const validateChecked = (rule, value, callback) => {
    try {
      if (!value) {
        throw new Error('Please Checkbox Work Day!');
      } else {
        callback();
      }
    } catch (err) {
      callback(err);
    }
  };

  const handleVisibleChange = (visiblePopover) => {
    setShowPopover(true);
  };

  const actionPopover = (record) => {
    const confirm = (record) => {
      Modal.confirm({
        className: 'services-modal-confirm',
        icon: <Icon type="delete" style={{ color: '#FF0000' }} />,
        title: intl.formatMessage({ id: 'lblDelete', defaultMessage: 'Are you sure you want to delete ?' }),
        okText: intl.formatMessage({ id: 'modalConfirmBtnYes', defaultMessage: 'Yes' }),
        cancelText: intl.formatMessage({ id: 'modalConfirmBtnNo', defaultMessage: 'No' }),
        centered: true,
        okButtonProps: {
          style: {
            width: '96px',
          },
          type: 'primary',
        },
        cancelButtonProps: {
          style: {
            width: '96px',
          },
          type: 'danger',
        },
        onOk() {
          deleteShiftType(record.shiftTypeRuleId);
        },
        onCancel() { },
      });
      setShowPopover(false);
    };

    return (
      <div className="action-button-popover">
        <Button className="popover-action" disabled={!switchShiftRules} onClick={() => showModalEdit(record)}>
          <FormattedMessage id="btnEdit" defaultMessage="Edit" />
        </Button>
        <Button className="popover-action" disabled={!switchShiftRules} onClick={() => confirm(record)}>
          <p className="action-delete-p">
            <FormattedMessage id="btnDelete" defaultMessage="Delete" />
          </p>
        </Button>
      </div>
    );
  };

  const deleteShiftType = async (shiftTypeRuleId) => {
    try {
      const response = await httpClient.delete(`/resource/manager/shift-type/rules/${shiftTypeRuleId}/delete`);
      if (response.status === 200) {
        // setRefreshTable(!refreshTable);
        setTriggerApi((current) => !current);
        shiftTypyNotificationSuccess(response.data.message);
      }
    } catch (err) {
      shiftTypeNotificationError(err.message);
    }
  };

  const shiftTypyNotificationSuccess = (message) => {
    notification.success({
      message: message,
    });
  };

  const shiftTypeNotificationError = (message) => {
    notification.error({
      message: message,
    });
  };

  const onSwitchChange = (checked) => {
    setswitchSubteam(checked);
    setStatusSubTeam(checked);
  };

  return (
    <PageSettings.Consumer>
      {() => (
        <ShiftSkillSettings.Provider
          value={{
            state: { visibleEdit, workDay, formRefEdit, trigerLoading, shiftTypeRuleId },
            fnc: {
              saveFormRefEdit,
              setShiftTypeRuleId,
              validateChecked,
              setVisibleEdit,
              setShiftTypeEdit,
              shiftTypyNotificationSuccess,
              setTrigerLoading,
              shiftTypeNotificationError,
            },
          }}
        >
          <Card
            title={<FormattedMessage id="orgTabShiftSettingTitleShiftType" defaultMessage="Shift Type" />}
            bordered={false}
            extra={
              <Button1 type="primary" btnsize="wd_df" fontsize="sm" disabled={!switchShiftRules} onClick={() => showModal()}>
                <FormattedMessage id="btnAddNew" defaultMessage="Add New" />
              </Button1>
            }
          >
            <div className="shift-type-from-body">
              <Table
                rowKey={(record) => record.shiftTypeRuleId}
                loading={loading}
                columns={columns}
                dataSource={shiftType}
                pagination={false}
                onChange={handleChange}
              />
              {parentOrg == true ? (
                <div style={{ marginTop: '37px', textAlign: 'right' }}>
                  <span style={{ marginRight: '24px' }}>
                    <FormattedMessage id="orgShiftSettingApplySubTeam" defaultMessage="Apply with sub-team" />
                  </span>
                  <Switch onChange={onSwitchChange} checked={statusSubTeam} />
                </div>
              ) : null}

              <AddShiftType
                ref={saveFormRef}
                visible={visible}
                onCancel={handleCancel}
                onCreate={handleApply}
                validateChecked={validateChecked}
                workDay={workDay}
                skillArr={skillArr}
                setSkillArr={setSkillArr}
              />

              <EditShiftType
                ref={saveFormRefEdit}
                visible={visibleEdit}
                validateChecked={validateChecked}
                workDay={workDay}
                shiftTypeEdit={shiftTypeEdit}
                setShiftTypeRuleId={setShiftTypeRuleId}
                setTriggerApi={setTriggerApi}
              />
            </div>
          </Card>
        </ShiftSkillSettings.Provider>
      )}
    </PageSettings.Consumer>
  );
};
