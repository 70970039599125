import React, { useState } from 'react';
import { Card, Row, Col } from 'antd';
import Button01 from '../../components/v2/button_01';
import { FormattedMessage } from 'react-intl';
import { PageSettings } from '../../config/page-settings';
import { OrdersContext } from './context';
import OrderList from './orders';
import SummaryComponent from './summary';
import moment from 'moment';

const dateFormat = 'ddd, MMM DD YYYY';
const dateFormatChange = 'YYYY-MM-DD';
const today = new Date();

export default () => {
  const [toggle, setToggle] = useState(true);
  const [summaryData, setSummaryData] = useState([]);

  const [scrollTableX, setScrollTableX] = useState(2000);
  const [dataSource, setDataSource] = useState([]);
  const [fliterByColumn, setFliterByColumn] = useState('all');
  const [fieldChange, setFieldChange] = useState('');
  const [total, setTotal] = useState();
  const [fieldSort, setFieldSort] = useState();
  const [orderSort, setOrderSort] = useState();
  const [paginationPage, setPaginationPage] = useState(1);
  const [paginationSize, setPaginationSize] = useState(10);
  const [loading, setLoading] = useState(false);
  const [mainTrigger, setMainTrigger] = useState(false);

  const [itemTypeSelect, setItemTypeSelect] = useState();
  const [customerSelect, setCustomerSelect] = useState();
  const [destinationSelect, setDestinationSelect] = useState();
  const [sourceSelect, setSourceSelect] = useState();
  const [dateSelect, setDateSelect] = useState(moment(today).format(dateFormatChange));
  const [timeSelect, setTimeSelect] = useState();
  const [sizeSelect, setSizeSelect] = useState();
  const [statusListData, setStatusListData] = useState([])
  const [statusSelect, setStatusSelect] = useState([])
  const [openImport, setOpenImport] = useState(false);
  const [fileHash, setFileHash] = useState()
  const handleToggle = () => {
    setToggle(!toggle);
  };

  return (
    <PageSettings.Consumer>
      {({ checkPermissionAction }) => (
        <OrdersContext.Provider
          value={{
            state: {
              toggle,
              summaryData,
              scrollTableX,
              dataSource,
              fliterByColumn,
              fieldChange,
              total,
              fieldSort,
              orderSort,
              paginationPage,
              paginationSize,
              loading,
              mainTrigger,
              itemTypeSelect,
              customerSelect,
              destinationSelect,
              sourceSelect,
              dateSelect,
              timeSelect,
              sizeSelect,
              openImport,
              fileHash,
              statusListData,
              statusSelect,
            },
            setState: {
              setScrollTableX,
              setDataSource,
              setSummaryData,
              setFliterByColumn,
              setFieldChange,
              setTotal,
              setFieldSort,
              setOrderSort,
              setPaginationPage,
              setPaginationSize,
              setLoading,
              setMainTrigger,
              setItemTypeSelect,
              setCustomerSelect,
              setDestinationSelect,
              setSourceSelect,
              setDateSelect,
              setTimeSelect,
              setSizeSelect,
              setOpenImport,
              setFileHash,
              setStatusListData,
              setStatusSelect,
            },
            fnc: {
              handleToggle,
            },
          }}
        >
          <Row gutter={[16, 16]}>
            <Col span={toggle ? 6 : 0}>
              <SummaryComponent />
            </Col>
            <Col span={toggle ? 18 : 24}>
              <OrderList />
            </Col>
          </Row>
        </OrdersContext.Provider>
      )}
    </PageSettings.Consumer>
  );
};
