import React from 'react';
import CustomerForm from './customer-pending';
import { FormattedMessage, useIntl } from 'react-intl';
import Button_01 from '../../../../../components/v2/button_01';
import CustomTableComponent from '../../../components/table';
import PriceForm from './price-form-pending';
import { Button, Divider } from 'antd';
import { numberFormatter } from '../../../../../component-function/fnc-inputnumber-currency';
import _ from 'lodash';

const DetailsTabPending = ({ form, property }) => {
  const intl = useIntl();
  const {
    handleOpenModalUpload,
    setDataForm,
    dataForm,
    setVisibleItem,
    initialMemberList,
    handleOpen,
    selectItem,
    setSelectItem,
    attData,
    setAttData,
    initialTaxList,
    handleOpenAddItem,
    saleOrderQTY,
    setSaleOrderQTY,
    recordList
  } = property;

  console.log('qtyTxt', selectItem);
  console.log('attData', attData);

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
      fixed: 'left',
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsItemCode', defaultMessage: 'Item Code' }),
      dataIndex: 'itemCode',
      key: 'itemCode',
      sorter: (a, b) => { return a.itemCode.localeCompare(b.itemCode)},
      width: 150,
      fixed: 'left',
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsItem', defaultMessage: 'Item' }),
      dataIndex: 'itemName',
      key: 'itemName',
      sorter: (a, b) => { return a.itemName.localeCompare(b.itemName)},
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsQty', defaultMessage: 'Qty' }),
      dataIndex: 'qtyTxt',
      key: 'qtyTxt',
      sorter: (a, b) => { return a.qtyTxt.localeCompare(b.qtyTxt)},
      // render: (text, record, index) => console.log('qtyTxt');,
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnPrice', defaultMessage: 'Price/Unit' }),
      dataIndex: 'pricePerUnit',
      key: 'pricePerUnit',
      sorter: (a, b) => { return a.pricePerUnit.localeCompare(b.pricePerUnit)},
      // render: (text, record, index) => _.get(record,'pricePerUnit') + ' ' + 'THB',
    },
    {
      title: intl.formatMessage({ id: 'ARColumnCredits', defaultMessage: 'Credit (days)' }),
      dataIndex: 'credit',
      key: 'credit',
      sorter: (a, b) => { return a.credit.localeCompare(b.credit)},
      render: (text, record, index) => (record.credit ? record.credit : 0),
    },
    // {
    //   title: intl.formatMessage({ id: 'purchesOrderModalColumnPromotionCampaign', defaultMessage: 'Promotion or Campaign' }),
    //   dataIndex: 'promotionCampaign',
    //   key: 'promotionCampaign',
    //   render: (text, record, index) => record.promotionCampaign || '-',
    // },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsDiscount', defaultMessage: 'Discount' }),
      dataIndex: 'discountTHB',
      key: 'discountTHB',
      sorter: (a, b) => { return a.discountTHB.localeCompare(b.discountTHB)},
      render: (text, record, index) => _.get(record, 'discountPercent').toFixed(2) + '%' + ' · ' + _.get(record, 'discountTHB'),
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsAmount', defaultMessage: 'Amount' }),
      dataIndex: 'totalTxt',
      key: 'totalTxt',
      fixed: 'right',
      width: 150,
      sorter: (a, b) => { return a.totalTxt.localeCompare(b.totalTxt)},
      // render: (text, record, index) => _.get(record,'totalTxt') + ' ' + 'THB',
    },
  ];

  const columnsAttach = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
      fixed: 'left',
      render: (text, record, index) => (index + 1),
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsFileName', defaultMessage: 'File Name' }),
      dataIndex: 'fileName',
      key: 'fileName',
      sorter: true,
      render: (text, record, index) => (
        _.get(dataForm, 'code') === 'view' ? <>{_.get(record, 'fileName')}</> : 
        <Button
        style={{color: '#050505'}}
          onClick={() => {
            window.open(_.get(record, 'fileUrl'), _.get(record, 'fileName', '_blank'));
          }}
          type="link"
        >
          {_.get(record, 'fileName')}
        </Button>
      ),
      // render: (text, record, index) => (<a href={_.get(record,'fileUrl')}>{_.get(record,'fileName')}</a>),
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsRemark', defaultMessage: 'Remark' }),
      dataIndex: 'remark',
      key: 'remark',
    },
  ];

  const deleteAtt = (value) => {
    const filterSelect = _.filter(attData, (item) => {
      return item.id !== value.id;
    });
    console.log('fileHash', value);
    setAttData(filterSelect);
  };

  const handleEditUploadInside = (value) => {
    handleOpenModalUpload(value);
  };

  const deleteItem = (value) => {
    const filterSelect = _.filter(selectItem, (item) => {
      return item.itemMasterId !== value.itemMasterId;
    });
    let tempSO = [...saleOrderQTY];
    const fSoIndex = _.findIndex(saleOrderQTY,(i) => {
      return i.saleOrderItemId === value.saleOrderItemId
    });
    tempSO[fSoIndex].qtyDeliver = 0;
    setSaleOrderQTY(tempSO);
    setSelectItem(filterSelect);
  };

  const contentActionItem = (record) => {
    console.log('contentAction', record);
    return (
      _.get(dataForm, 'code') === 'view' ? (
      <div style={{ display: 'grid' }}>
        <div>
          <Button style={{ width: 100 }} type="link" ghost onClick={() => deleteItem(record)}>
            <FormattedMessage id="btnDelete" defaultMessage="Delete" />
          </Button>
        </div>
      </div>
      ) : null
    );
  };

  const contentAction = (record) => {
    console.log('contentAction', record);
    return _.get(dataForm, 'code') === 'view' ? (
      <div style={{ display: 'grid' }}>
        <div>
          <Button style={{ width: 100 }} type="link" ghost onClick={() => handleEditUploadInside(record)}>
            <FormattedMessage id="orgTabMemberBtnEdit" defaultMessage="Edit" />
          </Button>
        </div>
        <div>
          <Button style={{ width: 100 }} type="link" ghost onClick={() => deleteAtt(record)}>
            <FormattedMessage id="btnDelete" defaultMessage="Delete" />
          </Button>
        </div>
      </div>
    ) : null;
  };

  return (
    <div className="scroll-sm scroll-height">
      {_.get(dataForm, 'code') !== 'view' ? (
        <>
          <div className="item-title">
            <span className="item-title-text">
              <FormattedMessage id="quotationLabelDetails" defaultMessage="Details" />
            </span>

            <div style={{ width: '18%', paddingTop: '3px' }}>
              <p
                style={{
                  // backgroundColor: record['statusColor'],
                  backgroundColor: _.get(dataForm, 'statusColor'),
                  color: 'white',
                  textAlign: 'center',
                  borderRadius: '10px',
                  // height: '23px',
                  width: '100%',
                  padding: ' 0px 10px',
                }}
              >
                {_.get(dataForm, 'stautsTxt')}
              </p>
            </div>
          </div>

          <Divider type="horizontal" style={{ marginBottom: '10px', marginTop: '10px' }} />
        </>
      ) : null}
      <div className="content-body">
        <CustomerForm
          form={form}
          setDataForm={setDataForm}
          dataForm={dataForm}
          initialMemberList={initialMemberList}
          handleOpen={handleOpen}
        />
      </div>
      <div>
        <div className="item-title">
          <span className="item-title-text">
            <FormattedMessage id="quotationLabelItem" defaultMessage="Item" />
          </span>
          <div>
            {_.get(dataForm, 'code') === 'view' ? (
              <Button_01
                key="add"
                style={{ margin: '0px 0px 0px 10px' }}
                type="primary"
                btnsize="wd_df"
                onClick={() => handleOpenAddItem()}
              >
                <FormattedMessage id="btnAdd" defaultMessage="Add" />
              </Button_01>
            ) : null}
          </div>
        </div>
        <Divider type="horizontal" style={{ marginBottom: '10px', marginTop: '10px' }} />
        <div className="content-body">
          <CustomTableComponent
            onRow={true}
            role={true}
            columns={columns}
            dataSource={selectItem}
            scroll={{ x: 1500 }}
            contentAction={contentActionItem}
          />
          <div className="content-body-price">
            <PriceForm form={form} selectItem={selectItem} setDataForm={setDataForm} dataForm={dataForm} initialTaxList={initialTaxList} />
          </div>
        </div>
      </div>
      <div>
        <div className="item-title">
          <span className="item-title-text">
            <FormattedMessage id="quotationLabelAttactment" defaultMessage="Attactment" />
          </span>
          <div>
            {_.get(dataForm, 'code') === 'view' ? (
              <Button_01
                key="add"
                style={{ margin: '0px 0px 0px 10px' }}
                type="primary"
                btnsize="wd_df"
                onClick={() => handleOpenModalUpload()}
              >
                <FormattedMessage id="btnUpload" defaultMessage="Upload" />
              </Button_01>
            ) : null}
          </div>
        </div>
        <Divider type="horizontal" style={{ marginBottom: '10px', marginTop: '10px' }} />
        <div className="content-body">
          <CustomTableComponent
            onRow={true}
            role={true}
            columns={columnsAttach}
            dataSource={attData}
            scroll={{ x: true }}
            contentAction={contentAction}
          />
        </div>
      </div>
    </div>
  );
};

export default DetailsTabPending;
