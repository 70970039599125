import React, { useEffect, useState } from 'react'
import { Row, Col, Card, Modal } from 'antd'
import { useIntl, FormattedMessage } from 'react-intl';
import './index.css'
import UOMCategory from './uomCategory'
import UOMDetail from './uomDetail';
import { getByCategoryData, deleteUoMCategory, deleteUoM, searchConvertUoM, deleteConvertUoM } from '../../controllers/uom/uom';
import _ from 'lodash'
import Button_01 from '../../components/v2/button_01'
import { getDataAll } from '../../controllers/item-master/get-data-all';
import NoDataBackground2 from '../../components/no-data-new';
import ModalFormUOMCategory from '../../components/uom-category';
import { successNotification, errorNotification } from '../../components/v2/notification';
import ModalUOMAdd from '../../components/uom';
import ModalFormUOMConvert from '../../components/uom-convert';

const UOMMain = () => {
  const intl = useIntl();
  const [categoryData, setCategoryData] = useState([])
  const [selectCategory, setSelectCategory] = useState()
  const [selectUoM, setSelectUoM] = useState()
  const [categoryId, setCategoryId] = useState()
  const [uomData, setUomData] = useState()
  const [visibleCategory, setVisibleCategory] = useState(false)
  const [visibleUOM, setVisibleUOM] = useState(false)
  const [typeCategory, setTypeCategory] = useState()
  const [typeUOM, setTypeUOM] = useState()
  const [trigger, setTrigger] = useState(false)
  const [tabChange, setTabChange] = useState("1")
  const [visibleConvert, setVisibleConvert] = useState(false)
  const [convertType, setConvertType] = useState('')
  const [convertList, setConvertList] = useState([])
  const [convertDefault, setConvertDefault] = useState()
  const [statusList, setStatusList] = useState([])

  const [searchConvert, setSearchConvert] = useState({
    page: 1,
    limit: 10,
    active: null,
    baseUomId: undefined,
    altUomId: undefined,
  });
  const [isFetch, setIsFetch] = useState(false)


  const changeTab = (key) => {
    setTabChange(key)
  }

  useEffect(() => {
    const getCategory = async () => {
      const getDefault = await getDataAll()
      setCategoryData(_.get(getDefault, 'uomCategory'))
    }
    getCategory();
  }, [trigger])

  useEffect(() => {
    const getById = async () => {
      console.log('searchConvert',searchConvert);
      const convertBody = {
        "categoryId": categoryId,
        "limit": _.get(searchConvert, 'limit'),
        "page": _.get(searchConvert, 'page'),
        "active": _.get(searchConvert, 'active'),
        "baseUomId": _.get(searchConvert, 'baseUomId'), 
        "altUomId": _.get(searchConvert, 'altUomId')
      }
      const getData = await getByCategoryData(categoryId)
      const convertData = await searchConvertUoM(convertBody)
      setUomData(_.get(getData, 'data.data'))
      setConvertList(_.get(convertData, 'data.data'))
      setStatusList(_.get(convertData, 'data.data.statusList'))
      console.log("getDefault", convertData)

    }
    if (categoryId) {
      getById()
    }
  }, [categoryId, trigger, isFetch])

  const handleCategory = (type) => {
    setVisibleCategory(true)
    setTypeCategory(type)
  }

  const handleDelete = (data) => {
    Modal.confirm({
      className: 'maintenance-report-modal-confirm',
      centered: true,
      icon: null,
      title: intl.formatMessage({ id: 'lblDelete', defaultMessage: 'Are you sure you want to delete ?' }),
      okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        try {
          const response = await deleteUoMCategory(_.get(data, 'uomCategoryId'))
          if (response.status === 200) {
            setTrigger(current => !current)
            setSelectCategory()
            setCategoryId()
            successNotification(_.get(response, 'data.status.message'))
          } else {
            errorNotification(_.get(response, 'data.status.message'))
          }
        } catch (error) {
          errorNotification(_.get(error, 'response.data.status.message'))
        }
      },
      onCancel() { },
    });
  }

  const handleDeleteUoM = (value) => {
    Modal.confirm({
      className: 'maintenance-report-modal-confirm',
      centered: true,
      icon: null,
      title: intl.formatMessage({ id: 'lblDelete', defaultMessage: 'Are you sure you want to delete ?' }),
      okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        console.log("value", value)
        try {
          const response = await deleteUoM(_.get(value, 'uomId'))
          if (response.status === 200) {
            setTrigger(current => !current)
            successNotification(_.get(response, 'data.status.message'))
          } else {
            errorNotification(_.get(response, 'data.status.message'))
          }
        } catch (error) {
          errorNotification(_.get(error, 'response.data.status.message'))
        }
      },
      onCancel() { },
    });
  }


  const handleOpenUom = (type) => {
    setTypeUOM(type)
    setVisibleUOM(true)
  }

  const handleOpenConvert = () => {
    setVisibleConvert(true)
    setConvertType("add")
  }

  const handleOpenConvertEdit = (record) => {
    setVisibleConvert(true)
    setConvertType("edit")
    setConvertDefault(record)
  }

  const handleDeleteConvertUoM = (value) => {
    Modal.confirm({
      className: 'maintenance-report-modal-confirm',
      centered: true,
      icon: null,
      title: intl.formatMessage({ id: 'lblDelete', defaultMessage: 'Are you sure you want to delete ?' }),
      okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        console.log("value", value)
        try {
          const response = await deleteConvertUoM(_.get(value, 'uomConvertId'))
          if (response.status === 200) {
            setTrigger(current => !current)
            successNotification(_.get(response, 'data.status.message'))
          } else {
            errorNotification(_.get(response, 'data.status.message'))
          }
        } catch (error) {
          errorNotification(_.get(error, 'response.data.status.message'))
        }
      },
      onCancel() { },
    });
  }

  const hanldeSearchUoM = (value) => {
    if (value) {
      const filterLike = _.filter(categoryData, (item) => {
        return _.includes(_.toLower(item.name), _.toLower(value));
      })
      console.log("filterLike", filterLike)
      setCategoryData(filterLike)
    } else {
      setTrigger(cur => !cur)
    }
  }

  return (
    <div>
      <Row gutter={16}>
        <Col span={6}>
          <Card
            title={intl.formatMessage({ id: `uomCategoryText`, defaultMessage: 'Category' })}
            bodyStyle={{ minHeight: 'calc(100vh - 165px)', padding: 'unset' }}
            extra={
              <Button_01 type="primary" btnfontsize="sm" style={{ margin: 'unset' }} onClick={() => handleCategory("add")}>
                <FormattedMessage id="btnCreate" defaultMessage="Create" />
              </Button_01>
            }
          >
            <div>
              <UOMCategory
                data={categoryData}
                categoryId={categoryId}
                setCategoryId={setCategoryId}
                setSelectCategory={setSelectCategory}
                hanldeSearchUoM={hanldeSearchUoM}
                setSearchConvert={setSearchConvert}
              />
            </div>
          </Card>
        </Col>
        <Col span={18}>
          <Card
            title=''
            bodyStyle={{ padding: 'unset', minHeight: 'calc(100vh - 113px)', maxHeight: 'calc(100vh - 111px)', overflowY: 'scroll' }}
          >
            {
              categoryId ? (
                <UOMDetail
                  changeTab={changeTab}
                  uomData={uomData}
                  selectCategory={selectCategory}
                  handleCategory={handleCategory}
                  handleDelete={handleDelete}
                  handleOpenUom={handleOpenUom}
                  setVisibleUOM={setVisibleUOM}
                  setSelectUoM={setSelectUoM}
                  handleDeleteUoM={handleDeleteUoM}
                  tabChange={tabChange}
                  handleOpenConvert={handleOpenConvert}
                  handleOpenConvertEdit={handleOpenConvertEdit}
                  convertList={convertList}
                  handleDeleteConvertUoM={handleDeleteConvertUoM}
                  setTypeUOM={setTypeUOM}
                  searchConvert={searchConvert}
                  setSearchConvert={setSearchConvert}
                  setIsFetch={setIsFetch}
                  statusList={statusList}
                />
              ) : (
                <div
                  className='UOMColContent'
                >
                  <NoDataBackground2
                    text1={{ id: 'uomCategoryTextBlank1', text: 'Please Select Category' }}
                    text2={{ id: 'uomCategoryTextBlank2', text: 'Category will appear here.' }}
                  />
                </div>
              )
            }
          </Card>
        </Col>
      </Row>
      <ModalFormUOMCategory
        visible={visibleCategory}
        setVisible={setVisibleCategory}
        type={typeCategory}
        setTrigger={setTrigger}
        selectCategory={selectCategory}
        setSelectCategory={setSelectCategory}
      />

      <ModalUOMAdd
        visible={visibleUOM}
        setVisible={setVisibleUOM}
        setTrigger={setTrigger}
        type={typeUOM}
        uomCategoryData={categoryData}
        categoryId={categoryId}
        selectUoM={selectUoM}
      />

      <ModalFormUOMConvert
        visible={visibleConvert}
        setVisible={setVisibleConvert}
        uomData={uomData}
        convertType={convertType}
        categoryId={categoryId}
        convertDefault={convertDefault}
        setTrigger={setTrigger}
      />

    </div>
  )
}

export default UOMMain
