import { Col, Tag } from 'antd';
import React from 'react'
import _ from 'lodash'

const FeedNoteFile = (props) => {
  const { isMemComId, fileList } = props

  const handleOpenFile = (url) => {
    window.open(url, '_blank');
  }

  return (
    <Col
      span={24}
      style={isMemComId ? { display: 'flex', justifyContent: 'end', textAlign: 'right' } : undefined}
    >
      {
        _.map(fileList, (item) => {
          return <Tag
            style={{ color: '#6490CF', border: '1px solid #6490CF', backgroundColor: 'white', cursor: 'pointer' }}
            onClick={() => handleOpenFile(item.url)}
            key={item.fileHash}
            closable={false}
          >
            {item.fileName}
          </Tag>
        })
      }
    </Col>
  )
}

export default FeedNoteFile
