import React from 'react'
import { Form, Input } from 'antd'
import styled from 'styled-components';
import { useIntl, FormattedMessage } from 'react-intl';


const addContact = ({ form, onCreate,intl }) => {

  const { getFieldDecorator, validateFields, resetFields, getFieldsValue, getFieldValue, setFieldsValue } = form;
  const LabelRequire = styled.label`
  color: #ff1010;
`;
  const LabeRequire = (props) => {
    const { text, req } = props;
    return (
      <span className="font-p">
        {text}&nbsp;
        {req ? <LabelRequire>*</LabelRequire> : ''}
      </span>
    );
  };

  return (
    <div>
      <Form>

        <Form.Item>
          {/* <span><LabeRequire text={<FormattedMessage id="monitorCreateTaskTxtFromLocation" defaultMessage="Name" />} req={true} /></span> */}
          <span className='text-style'><FormattedMessage id="ModalContactHeadTextName" defaultMessage="Name" /><LabeRequire req={true} /></span>
          {getFieldDecorator("name", {
            rules: [
              {
                required: true,
                message: intl.formatMessage({ id: 'ModalContactValidateName', defaultMessage: 'Please Enter Name' })
                // message: intl.formatMessage({ id: 'monitorCreateTaskValidationFromLocation', defaultMessage: 'Please select Location' })

              }
            ]
          })(<Input placeholder={intl.formatMessage({ id: 'ModalContactPlaceholderName', defaultMessage: 'Enter Name' })} autoComplete="off" ></Input>)}
        </Form.Item>


        <Form.Item>
          <span className='text-style'><FormattedMessage id="ModalContactHeadTextPostion" defaultMessage="Position" /><LabeRequire req={true} /></span>
          {getFieldDecorator("position", {
            rules: [
              {
                required: true,
                message: intl.formatMessage({ id: 'ModalContactValidatePosition', defaultMessage: 'Please Enter Position' })
              }
            ]
          })(<Input placeholder={intl.formatMessage({ id: 'ModalContactPlaceholderPosition', defaultMessage: 'Enter Position' })} autoComplete="off" ></Input>)}
        </Form.Item>


        <Form.Item>
          <span className='text-style'><FormattedMessage id="ModalContactHeadTextEmail" defaultMessage="Email" /></span>
          {getFieldDecorator("email", {
            rules: [
              {
                type: 'email',
                pattern: new RegExp("^[0-9A-Za-z@._]*$"),
                message: intl.formatMessage({ id: 'ModalContactValidateEmail', defaultMessage: 'Please input email format' })
              },
            ]
          })(<Input placeholder={intl.formatMessage({ id: 'ModalContactPlaceholderEmail', defaultMessage: 'Enter Email' })} autoComplete="off" ></Input>)}
        </Form.Item>

        <Form.Item>
          <span className='text-style'><FormattedMessage id="ModalContactHeadTextPhone" defaultMessage="Phone" /><LabeRequire req={true} /></span>
          {getFieldDecorator("phone", {
            rules: [
              {
                required: true,
                message: intl.formatMessage({ id: 'ModalContactValidatePhone', defaultMessage: 'Please Enter Phone' })
              }
            ]
          })(<Input placeholder={intl.formatMessage({ id: 'ModalContactPlaceholderPhone', defaultMessage: 'Enter Phone' })} autoComplete="off" ></Input>)}
        </Form.Item>

        <Form.Item>
          <span className='text-style'><FormattedMessage id="ModalContactHeadTextRemark" defaultMessage="Remark" /></span>
          {getFieldDecorator("remark", {
          })(<Input placeholder={intl.formatMessage({ id: 'ModalContactPlaceholderRemark', defaultMessage: 'Enter Remark' })} autoComplete="off" ></Input>)}
        </Form.Item>

      </Form>

    </div>
  )
}
const Contact = Form.create({
  name: "modal_form",
  mapPropsToFields(props) {

  }
})(addContact);

export default Contact;