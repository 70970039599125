import React, { useState, useEffect, useContext, useMemo } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import moment from 'moment';
import { Icon, Card } from 'antd';
import { GanttViewContext, MonitorContext } from '../monitor-context';
import { PageSettings } from '../../../config/page-settings';
import ActionGanttView from './action-gantt-view';
import _, { debounce } from 'lodash';
import getResourceAndTask from '../../../controllers/gantt-view/gantt-api';
import { v4 as uuidv4 } from 'uuid';
import GanttView from './ganttView';
import styles from './css/gentt-view.css';
import stylesMain from '../css/index.css';

const today = new Date();
const dateApi = 'YYYY-MM-DD';
const dateTimeApi = 'YYYY-MM-DD HH:mm';
const dateTitlelModal = 'ddd, MMM DD, YYYY';

export default React.memo(() => {
  const intl = useIntl();
  const app = useContext(PageSettings);
  const { stateMain, setStateMain, fncMain } = useContext(MonitorContext);
  let keyTabMonitor = stateMain.keyTabMonitor;
  let mainOrgIdArrString = stateMain.mainOrgIdArrString;
  const organizationTeam = stateMain.mainOrganizationTeamData;
  const { LangCode } = stateMain;
  const typeApi = `menuType=workdesk`;
  const dateNow = moment(today).format(dateApi);
  const memId = app.memberProfile.member.mem_id;

  const [resourceData, setResourceData] = useState([]);
  const [taskData, setTaskData] = useState([]);
  const [taskMatchData, setTaskMatchData] = useState([]);
  const [orgTeamData, setOrgTeamData] = useState([]);

  const [taskValue, setTaskValue] = useState([]);

  const [search, setSearch] = useState('');
  const [selectFilter, setSelectFilter] = useState('all');

  const [orgIdArrString, setOrgIdArrString] = useState('');
  const [navigateBar, setNavigateBar] = useState(false);

  const [nowDate, setNowDate] = useState(today);
  const [startDate, setStartDate] = useState(dateNow);
  const [endDate, setEndDate] = useState(dateNow);

  // console.log('Gantt View index taskData::: ', taskData);
  // console.log('Gantt View index taskMatchData::: ', taskMatchData);
  // console.log('Gantt View mainOrgIdArrString::: ', mainOrgIdArrString);
  // console.log('Gantt View orgTeam::: ', organizationTeam);

  useEffect(() => {
    updateData(search, selectFilter, mainOrgIdArrString);
  }, [keyTabMonitor, nowDate, search, selectFilter, orgIdArrString, stateMain.refreshApiGanttView]);

  useEffect(() => {
    const interval = setInterval(async () => {
      if (mainOrgIdArrString) {
        await updateData(search, selectFilter, mainOrgIdArrString, resourceData, taskMatchData); // API call
      }
    }, 180000);
    return () => clearInterval(interval);
  }, [mainOrgIdArrString]);

  // useEffect(() => {
  //   updateData(search, selectFilter, mainOrgIdArrString);
  //   const interval = setInterval(async () => {
  //     await updateData(search, selectFilter, mainOrgIdArrString);
  //   }, 180000);
  //   return () => clearInterval(interval);
  // }, [mainOrgIdArrString, keyTabMonitor, nowDate, search, selectFilter, orgIdArrString, stateMain.refreshApiGanttView]);

  const updateData = async (search, selectFilter, mainOrgIdArrString) => {
    let startDate_new = '';
    let endDate_new = '';

    if (stateMain.scheduleObjNew.current && stateMain.scheduleObjNew.current.activeViewOptions.option === 'TimelineDay') {
      let currentDate = stateMain.scheduleObjNew.current.activeView.renderDates[0];
      startDate_new = moment(currentDate).format(dateApi);
      endDate_new = moment(currentDate).format(dateApi);
    } else if (stateMain.scheduleObjNew.current && stateMain.scheduleObjNew.current.activeViewOptions.option === 'TimelineWeek') {
      let currentDate = stateMain.scheduleObjNew.current.activeView.renderDates;
      startDate_new = moment(currentDate[0]).format(dateApi);
      endDate_new = moment(currentDate[currentDate.length - 1]).format(dateApi);
    } else if (stateMain.scheduleObjNew.current && stateMain.scheduleObjNew.current.activeViewOptions.option === 'TimelineMonth') {
      let currentDate = stateMain.scheduleObjNew.current.activeView.monthDates;
      startDate_new = moment(currentDate.start).format(dateApi);
      endDate_new = moment(currentDate.end).format(dateApi);
    }

    if (startDate_new && endDate_new) {
      const getData = await getResourceAndTask({ startDate_new, endDate_new, mainOrgIdArrString, search, selectFilter, typeApi });
      // console.log('Gantt res data::: ', getData.data.resource);

      if (getData && getData.data) {
        mapResourceData(getData.data.resource, resourceData);
        mapTaskData(getData.data.task, taskMatchData);
        mapOrgTeamData(getData.data.resource, getData.data.totalTaskpool);

        // console.log('Gantt View getData:::', getData.data);
        // setStateMain.setSummaryDataGantt(getData.data.statusSummary);
        // setStateMain.setTasksGantt(getData.data.totalTasks);
        // setStateMain.setWarningGantt(getData.data.taskWarning);
        // setStateMain.setTimeoutGantt(getData.data.taskTimeout);;

        setStateMain.setSummaryDataGantt({
          statusSummary: getData.data.statusSummary,
          tasks: getData.data.totalTasks,
          warning: getData.data.taskWarning,
          timeout: getData.data.taskTimeout,
        });
      } else {
        setResourceData([]);
        setTaskData([]);
        setOrgTeamData([]);
        // setStateMain.setSummaryDataGantt([]);
        // setStateMain.setTasksGantt(0);
        // setStateMain.setWarningGantt(0);
        // setStateMain.setTimeoutGantt(0);

        setStateMain.setSummaryDataGantt({
          statusSummary: [],
          tasks: 0,
          warning: 0,
          timeout: 0,
        });
      }
    }
  };

  const handleSearch = debounce((fieldChang) => {
    setSearch(fieldChang ? fieldChang : '');
  }, 500);

  const handleSelect = (value) => {
    setSelectFilter(value);
  };

  const onSelectDatePicker = (date, dateString) => {
    setNowDate(date);
    setStartDate(moment(date).format(dateApi));
    setEndDate(moment(date).format(dateApi));
  };

  const handleChangeOption = (value) => {
    // setStateMain.setSpinLoading(true)
    const dataCreate = [];
    value.forEach((element) => {
      dataCreate.push(element);
    });
    const orgIdArr = dataCreate.toString();

    setStateMain.setMainOrgIdArr(dataCreate);
    setStateMain.setMainOrgIdArrString(orgIdArr);
    setTimeout(() => {
      setOrgIdArrString(orgIdArr);
    }, 500);
  };

  const mapResourceData = (data, resourceData) => {
    // console.log('Gantt View ResourceData::: ', data);
    const newResourceDate =
      data &&
      data.map((item, index) => {
        return {
          fullname:
            `<div style='cursor: pointer;' class='fullname text-dot' orgId='${parseInt(item.OrgId)}' memComId='${parseInt(item.memComId) ||
              999999}'><img src=${item.pathImage} class="fullname-img gantt-avatar" orgId='${parseInt(item.OrgId)}' memComId='${parseInt(
              item.memComId
            ) || 999999}' />` +
            `<p class='fullname-p text-p' orgId='${parseInt(item.OrgId)}' memComId='${parseInt(item.memComId) || 999999}'>${
              item.fullname
            }</p>` +
            `<span class='fullname-c text-count' orgId='${parseInt(item.OrgId)}' memComId='${parseInt(item.memComId) || 999999}'>(${
              item.totalTask
            })</span></div>`,
          pathImage: item.pathImage,
          memId: item.memId,
          memComId: parseInt(item.memComId) || 999999,
          orgId: item.OrgId,
          assigneeName: item.fullname,
        };
      });
    // console.log('Gantt View ResourceData newResourceDate::: ', newResourceDate);
    setResourceData(newResourceDate);
  };

  const mapTaskData = (data, taskMatchData) => {
    // console.log('Gantt View data::: ', data);
    // console.log('Gantt View taskMatchData::: ', taskMatchData);
    const newTaskDate =
      data &&
      data.map((item, index) => {
        return {
          memComOldId: parseInt(item.memComId),
          Id: uuidv4(),
          TaskId: item.taskId,
          TaskNo: item.task_no,
          Subject: item.taskTypeName,
          CustomerName: item.customerName,
          AssigneeName: item.assigneeName,
          Color: item.statusColor,
          OrgId: parseInt(item.orgId),
          ResourceId: parseInt(item.memComId) || 999999,
          StartTime: moment.unix(item.startTime).format(dateTimeApi),
          EndTime: moment.unix(item.endTime).format(dateTimeApi),
          StartDate: item.startDate,
          DueDate: item.dueDate,
          Status: item.statusName,
          Reference: item.reference1,
          ToAddress: item.toLocationDescription,
          Remark: item.remark,
        };
      });

    const newTaskDateMatch =
      data &&
      data.map((item, index) => {
        return {
          memComOldId: parseInt(item.memComId),
          TaskId: item.taskId,
          TaskNo: item.task_no,
          Subject: item.taskTypeName,
          CustomerName: item.customerName,
          AssigneeName: item.assigneeName,
          Color: item.statusColor,
          OrgId: parseInt(item.orgId),
          ResourceId: parseInt(item.memComId) || 999999,
          StartTime: moment.unix(item.startTime).format(dateTimeApi),
          EndTime: moment.unix(item.endTime).format(dateTimeApi),
          StartDate: item.startDate,
          DueDate: item.dueDate,
          Status: item.statusName,
          Reference: item.reference1,
          ToAddress: item.toLocationDescription,
          Remark: item.remark,
        };
      });

    // return newTaskDate;
    setTaskData(newTaskDate);

    // console.log('Gantt View taskMatchData::: ', taskMatchData);
    // console.log('Gantt View newTaskDateMatch::: ', newTaskDateMatch);

    // const matchTask = _.isMatch(taskMatchData, newTaskDateMatch);

    // console.log('Gantt View matchTask::: ', matchTask);
    // console.log('Gantt View newTaskDate::: ', newTaskDate);

    // if (matchTask === true) {
    //   return
    // } else {
    //   setTaskData(newTaskDate);
    //   setTaskMatchData(newTaskDateMatch);
    // }
  };

  const mapOrgTeamData = (resData, totalTask) => {
    // console.log('mapOrgTeamData::: ', resData);
    let newDataOrg = [];
    _.forEach(organizationTeam, (obj) => {
      const sumResourceByOrgId = _.sumBy(resData, (e) => (e.OrgId === obj.orgId ? 1 : 0));
      let txt = '';
      if (sumResourceByOrgId > 2) {
        txt = intl.formatMessage({ id: 'monitorGanttViewTxtMembers', defaultMessage: 'Members' });
      } else {
        txt = intl.formatMessage({ id: 'monitorGanttViewTxtMember', defaultMessage: 'Member' });
      }
      // const unass = _.get(totalTask, 'resView')
      const filterobj =
        totalTask &&
        totalTask.filter((item) => {
          return parseInt(item.orgId) === parseInt(obj.orgId);
        });
      newDataOrg.push({
        orgId: obj.orgId,
        orgName: `<div><span>${obj.orgName}</span> <br /> <span style='font-size: 12px; color: #1D3557;'>${sumResourceByOrgId}&nbsp;${txt}
        &nbsp;${_.get(filterobj, '[0].resView') ? _.get(filterobj, '[0].resView') : 'Unassigned 0 Task'}</span></div>`,
        orgSelected: obj.orgSelected,
      });
    });
    // console.log('mapOrgTeamData newDataOrg::: ', newDataOrg);
    setOrgTeamData(newDataOrg);
  };

  const onNavigating = (args) => {
    // console.log('args ::',moment(args.currentDate))
    setTimeout(() => {
      if (args.currentDate) {
        setNowDate(args.currentDate);
        // setStateMain.setMainDate(moment(args.currentDate).format(dateApi));
        setStateMain.setMainDateStartAction(moment(args.currentDate));
      } else if (args.currentView) {
        setNowDate(args.currentView);
        // setStateMain.setMainDate(moment(args.currentView).format(dateApi));
        setStateMain.setMainDateStartAction(moment(args.currentView));
      }
    }, 500);
  };

  return (
    <MonitorContext.Consumer>
      {({ stateMain }) => (
        <GanttViewContext.Provider
          value={{
            state: {
              resourceData,
              taskData,
              selectFilter,
              taskValue,
              search,
              stateMain,
              setStateMain,
            },
            setState: {},
            fnc: {
              onSelectDatePicker,
              handleSearch,
              handleSelect,
              handleChangeOption,
              onNavigating,
            },
          }}
        >
          <div className="stylesMain-monitor-tabs-body">
            <ActionGanttView memId={memId} stateMain={stateMain} fncMain={fncMain} />
            {stateMain.mainOrgIdArrString === undefined || stateMain.mainOrgIdArrString === '' ? (
              <Card className="stylesMain-monitor-result">
                <div className="stylesMain-monitor-detail">
                  <Icon className="stylesMain-monitor-result-icon" type="team" />
                  <p className="stylesMain-monitor-result-title">
                    <FormattedMessage id={`monitorTabGanttViewTxtDetailDescription1WorkDesk`} defaultMessage="Please select a team" />{' '}
                    <br />
                    <FormattedMessage
                      id={`monitorTabGanttViewTxtDetailDescription2WorkDesk`}
                      defaultMessage="Gantt View will appear here."
                    />
                  </p>
                </div>
              </Card>
            ) : (
              <GanttView
                resourceData={resourceData}
                taskData={taskData}
                navigateBar={navigateBar}
                setNavigateBar={setNavigateBar}
                organizationTeam={orgTeamData}
                LangCode={LangCode}
              />
            )}
          </div>
        </GanttViewContext.Provider>
      )}
    </MonitorContext.Consumer>
  );
});
