import httpClient from '../../components/axiosClient';

const getCustomerVenderApi = async (allCustomers) => {
  const comCode = localStorage.getItem('comCode');
  try {
    // const response = await httpClient.get(`/v3/business-partner/manager/company/${comCode}/customer-informations/get/load-more/noprivacy?indexStart=1&indexEnd=${allCustomers}&type=vendor`);
    const response = await httpClient.get(
      `/v3/business-partner/manager/company/${comCode}/customer-informations/get/load-more/noprivacy?indexStart=1&indexEnd=${1500}&type=vendor`
    );
    if (response.status == 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};

export default getCustomerVenderApi;
