import React, { useState, useEffect } from 'react'
import { Row, Col, Form, Input, Select, TreeSelect } from 'antd'
import './css/index.css'
import { useIntl, FormattedMessage } from 'react-intl'
import _ from 'lodash'
import { getInitialAssetClass, getTypeAsset, getBrandAsset, getModelAsset, getMemberAsset } from '../../../controllers/asset-resource/asset-controller'
import getAllTeam from '../../../controllers/get-all-team/get-all-team.js';


const { TextArea } = Input
const { Option } = Select

export default (props) => {
  const intl = useIntl()
  const { getFieldDecorator, validateFields, resetFields, getFieldValue } = props.form;
  const [teamData, setTeamData] = useState([]);
  const [initialAssetClassList, setInitialAssetClassList] = useState([]);
  const [initialAssetTypeList, setInitialAssetTypeList] = useState([]);
  const [initialBrandList, setInitialBrandList] = useState([]);
  const [initialModelList, setInitialModelList] = useState([]);
  const [initialMemberList, setInitialMemberList] = useState([]);


  const LabeRequire = (props) => {
    const { text, req } = props;
    return (
      <span>
        {text}&nbsp;
        {req ? <span style={{ fontSize: '13px', color: '#fa383e' }}>*</span> : ''}
      </span>
    );
  };

  useEffect(() => {
    const getTeam = async () => {
      const response = await getAllTeam()
      if (response) {
        setTeamData(response)
      }
    }
    getTeam();
  }, []);

  //------------------------- Asset Class ---------------------------//

  useEffect(() => {
    const getApi = async () => {
      let payload = {}
      const result = await getInitialAssetClass(payload);
      setInitialAssetClassList(_.get(result, 'dataList') || [])
    }
    getApi();
  }, []);

  const assetClassOption = (o, i) => {
    return (
      <Option key={i} value={o.assetClassId}>{o.assetClassName}</Option>
    )
  }

  useEffect(() => {
    if (getFieldValue('assetClass')) {
      setInitialAssetTypeList([]);
      setInitialBrandList([]);
      setInitialModelList([]);
      resetFields(['assetType', 'brand', 'model']);
    }
  }, [getFieldValue('assetClass')]);

  useEffect(() => {
    const getApi = async () => {
      if (getFieldValue('assetClass')) {
        let payload = {
          assetClassId: getFieldValue('assetClass')
        }
        const result = await getTypeAsset(payload);
        setInitialAssetTypeList(_.get(result, 'dataList') || [])
      }
    }
    getApi();
  }, [getFieldValue('assetClass')]);

  const assetTypeOption = (o, i) => {
    return (
      <Option key={i} value={o.assetTypeId}>{o.assetTypeName}</Option>
    )
  }

  useEffect(() => {
    const getApi = async () => {
      if (getFieldValue('assetClass')) {
        let payload = {
          assetClassId: getFieldValue('assetClass')
        }
        const result = await getBrandAsset(payload);
        setInitialBrandList(_.get(result, 'dataList') || [])
      }
    }
    getApi();
  }, [getFieldValue('assetClass')]);

  const BrandOption = (o, i) => {
    return (
      <Option key={i} value={o.assetBrandId}>{o.assetBrandName}</Option>
    )
  }

  useEffect(() => {
    const getApi = async () => {
      if (getFieldValue('brand')) {
        let payload = {
          assetBrandId: getFieldValue('brand')
        }
        const result = await getModelAsset(payload);
        setInitialModelList(_.get(result, 'dataList') || [])
      }
    }
    getApi();
  }, [getFieldValue('brand')]);

  const ModelOption = (o, i) => {
    return (
      <Option key={i} value={o.assetModelId}>{o.assetModelName}</Option>
    )
  }

  useEffect(() => {
    if (getFieldValue('brand')) {
      setInitialModelList([]);
      resetFields(['model']);
    }
  }, [getFieldValue('brand')]);

  useEffect(() => {
    const getApi = async () => {
      let payload = {}
      const result = await getMemberAsset(payload);
      setInitialMemberList(result || [])
    }
    getApi();
  }, []);

  const MemberOption = (o, i) => {
    return (
      <Option key={i} value={o.memComId}>{o.fullname}</Option>
    )
  }





  return (
    <div>
      <Form>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
              className='modal-asset-register'
              label={<LabeRequire text={intl.formatMessage({ id: 'modalAssetAssetName', defaultMessage: 'Asset Name' })} req={true} />}>
              {getFieldDecorator('assetName', {
                rules: [{
                  required: true,
                  message: intl.formatMessage({ id: 'modalAssetAssetNameValidate', defaultMessage: 'Please input asset name' })
                }],
              })(
                <Input
                  placeholder={intl.formatMessage({ id: 'modalAssetAssetNamePlaceholder', defaultMessage: 'Enter Asset' })}
                ></Input>
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              className='modal-asset-register'
              label={<LabeRequire text={intl.formatMessage({ id: 'modalAssetOwner', defaultMessage: 'Owner' })} req={true} />}>
              {getFieldDecorator('owner', {
                rules: [{
                  required: true,
                  message: intl.formatMessage({ id: 'modalAssetOwnerValidate', defaultMessage: 'Please select owner' })
                }],
              })(

                <TreeSelect
                  className="member-asset-tree-select"
                  showSearch
                  dropdownStyle={{ maxHeight: '460px', overflow: 'auto', width: '300px' }}
                  placeholder={intl.formatMessage({ id: 'modalAssetOwnerPlaceholder', defaultMessage: 'Select Team' })}
                  treeNodeFilterProp={'title'}
                  treeDefaultExpandAll
                  treeData={teamData}
                ></TreeSelect>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
              className='modal-asset-register'
              label={<LabeRequire text={intl.formatMessage({ id: 'modalAssetAssetClass', defaultMessage: 'Asset Class' })} req={true} />}>
              {getFieldDecorator('assetClass', {
                rules: [{
                  required: true,
                  message: intl.formatMessage({ id: 'modalAssetAssetClassValidate', defaultMessage: 'Please select asset class' })
                }],
              })(
                <Select
                  placeholder={intl.formatMessage({ id: 'modalAssetAssetClassPlaceholder', defaultMessage: 'Select Asset Class' })}
                  style={{ width: '100%' }}
                  defaultActiveFirstOption={false}
                  filterOption={(input, option) =>
                    option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  showSearch
                // loading={initialAssetClassLoading}
                // onPopupScroll={handleAssetPopupScroll}
                // onSelect={handleAssetChangeSelect}
                // onSearch={handleAssetChangeSearch}
                // onChange={handleAssetChange}

                >
                  {
                    initialAssetClassList && initialAssetClassList.map((item, index) => assetClassOption(item, index))
                  }
                </Select>

              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              className='modal-asset-register'
              label={<LabeRequire text={intl.formatMessage({ id: 'modalAssetAssetType', defaultMessage: 'Asset Type' })} req={true} />}>
              {getFieldDecorator('assetType', {
                rules: [{
                  required: true,
                  message: intl.formatMessage({ id: 'modalAssetAssetTypeValidate', defaultMessage: 'Please select asset type' })
                }],
              })(

                <Select
                  disabled={getFieldValue('assetClass') ? false : true}
                  placeholder={intl.formatMessage({ id: 'modalAssetAssetTypePlaceholder', defaultMessage: 'Select Asset Type' })}
                  style={{ width: '100%' }}
                  defaultActiveFirstOption={false}
                  showSearch
                  filterOption={(input, option) =>
                    option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {
                    initialAssetTypeList && initialAssetTypeList.map((item, index) => assetTypeOption(item, index))
                  }
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
              className='modal-asset-register'
              label={<LabeRequire text={intl.formatMessage({ id: 'modalAssetBrand', defaultMessage: 'Brand' })} req={true} />}>
              {getFieldDecorator('brand', {
                rules: [{
                  required: true,
                  message: intl.formatMessage({ id: 'modalAssetBrandValidate', defaultMessage: 'Please select brand' })
                }],
              })(
                <Select
                  disabled={getFieldValue('assetClass') ? false : true}
                  placeholder={intl.formatMessage({ id: 'modalAssetBrandPlaceholder', defaultMessage: 'Select Brand' })}
                  style={{ width: '100%' }}
                  defaultActiveFirstOption={false}
                  showSearch
                  filterOption={(input, option) =>
                    option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {
                    initialBrandList && initialBrandList.map((item, index) => BrandOption(item, index))
                  }
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              className='modal-asset-register'
              label={<LabeRequire text={intl.formatMessage({ id: 'modalAssetModel', defaultMessage: 'Model' })} req={true} />}>
              {getFieldDecorator('model', {
                rules: [{
                  required: true,
                  message: intl.formatMessage({ id: 'modalAssetModelValidate', defaultMessage: 'Please select model' })
                }],
              })(
                <Select
                  disabled={getFieldValue('brand') ? false : true}
                  placeholder={intl.formatMessage({ id: 'modalAssetModelPlaceholder', defaultMessage: 'Select Model' })}
                  style={{ width: '100%' }}
                  defaultActiveFirstOption={false}
                  showSearch
                  filterOption={(input, option) =>
                    option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {
                    initialModelList && initialModelList.map((item, index) => ModelOption(item, index))
                  }
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
              className='modal-asset-register'
              label={<LabeRequire text={intl.formatMessage({ id: 'modalAssetSerialNo', defaultMessage: 'Serial No.' })} req={false} />}>
              {getFieldDecorator('serialNo', {
              })(
                <Input
                  placeholder={intl.formatMessage({ id: 'modalAssetSerialNoPlaceholder', defaultMessage: 'Enter Serial No.' })}
                ></Input>
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              className='modal-asset-register'
              label={<LabeRequire text={intl.formatMessage({ id: 'modalAssetSRegisterBy', defaultMessage: 'Register By' })} req={true} />}>
              {getFieldDecorator('register', {
                initialValue: localStorage.getItem('memComId'),
                rules: [{
                  required: true,
                  message: intl.formatMessage({ id: 'modalAssetSRegisterByValidate', defaultMessage: 'Please select register by' })
                }],
              })(
                <Select
                  placeholder={intl.formatMessage({ id: 'modalAssetSRegisterByPlaceholder', defaultMessage: 'Select Register by' })}
                  style={{ width: '100%' }}
                  defaultActiveFirstOption={false}
                  showSearch
                  filterOption={(input, option) =>
                    option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {
                    initialMemberList && initialMemberList.map((item, index) => MemberOption(item, index))
                  }
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Form.Item
              className='modal-asset-register'
              label={<LabeRequire text={intl.formatMessage({ id: 'modalAssetRemark', defaultMessage: 'Remark' })} req={false} />}>
              {getFieldDecorator('remark', {
              })(
                <TextArea
                  autosize={{ minRows: 3, maxRows: 3 }}
                  maxLength={250}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  )
}


