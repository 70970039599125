import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Modal, Form, Input, Col, Row, Divider, Badge, Card, TextArea, Popover, Button, Icon } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import Button01 from '../../components/v2/button_01';
import { PageSettings } from '../../config/page-settings';
import AppAvatar from '../../components/avatar';
import DefaultProfile from '../../components/image/NoImageR.png';
import ModalForm from '../../components/create-edit-item-master/index';
import './css/index.css';
import _ from 'lodash';
import NumberFormat from 'react-number-format';

export default ({
  data,
  setTriggerAPI,
  dataDefault,
  setBrandId,
  dataModel,
  visibleModal,
  setVisibleModal,
  setCreateItem,
  createItem,
  setEventDataDefault,
  groupTypeId,
  setGroupTypeId,
}) => {
  // const [visiblePopover, setVisiblePopover] = useState(false);
  // const handleOnVisiblePopover = (visiblePopover) => {
  //   setVisiblePopover(visiblePopover);
  // };

  const intl = useIntl();

  const [imageFile, setImageFile] = useState();
  const [imageFileDefault, setImageFileDefault] = useState('');
  const [recordItemMaster, setRecordItemMaster] = useState();

  const [popOverVisible, setpopOverVisible] = useState(false);

  const handlePopOverVisible = (newOpen) => {
    setpopOverVisible(newOpen);
  };

  useEffect(() => {
    setImageFileDefault(DefaultProfile);
  }, [DefaultProfile]);

  const handleOpenModal = (record) => {
    setVisibleModal(true);
    setRecordItemMaster(record);
    setpopOverVisible(false);
  };

  return (
    <PageSettings.Consumer>
      {({ checkPermissionAction }) => (
        <div>
          <Card
            title={intl.formatMessage({ id: 'itemMasterTextHeaderInformation', defaultMessage: 'Information' })}
            bodyStyle={{ height: '790px' }}
            extra={
              // <div>
              //   {
              //     // checkPermissionAction('P8PG2C1', 'P8PG2C1A2') ? (
              //     <Button03 type="link" onClick={() => handleOpenModal(data)}>
              //       <FormattedMessage id="btnEdit" defaultMessage="Edit" />
              //     </Button03>
              //     // ) : null
              //   }
              // </div>
              <div>
                {
                  // checkPermissionAction('P8PG2C1', 'P8PG2C1A2') ? (
                  <Popover
                    placement="bottomLeft"
                    trigger="click"
                    visible={popOverVisible}
                    onVisibleChange={handlePopOverVisible}
                    zIndex={999}
                    content={
                      <div style={{ display: 'grid' }}>
                        <div>
                          <Button style={{ width: 100 }} type="link" ghost onClick={() => handleOpenModal(data)}>
                            <FormattedMessage id="btnEdit" defaultMessage="Edit" />
                          </Button>
                        </div>
                      </div>
                    }
                  >
                    <Button01 btnsize="wd_at" fontSize="sm" type="link">
                      <FormattedMessage id="btnActionGroup" defaultMessage="Action" /> <Icon fontSize="sm" type="down" />
                    </Button01>
                  </Popover>
                  // ) : null
                }
              </div>
            }
          >
            <div>
              <Form>
                <Row gutter={[16, 16]}>
                  <Col span={6}>
                    <AppAvatar
                      shape="square"
                      size={152}
                      src={`${_.get(data, 'pathImage') ? _.get(data, 'pathImage') : imageFileDefault}`}
                    />
                  </Col>
                  <Col span={18}>
                    <div className="padding-div-top">
                      <Row className="padding-div-item-detail">
                        <Col span={24}>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span className="information-itemcode-itemname-style">{data.itemCode}</span>
                            <div style={{ margin: '0 5px' }}>
                              <Badge className="badge-text-item-master-data" color="#1D3557" />
                            </div>
                            <span className="information-itemcode-itemname-style">{data.itemName}</span>
                          </div>
                        </Col>
                      </Row>

                      <Row gutter={[12, 6]}>
                        <Col span={12}>
                          <span className="item-label-detail">
                            <FormattedMessage id="modalItemMasterFormItemNameTextItemGroup" defaultMessage="Item Group" />
                          </span>
                          <Form.Item>{data.itemGroupName}</Form.Item>
                        </Col>
                        <Col span={12}>
                          <span className="item-label-detail">
                            <FormattedMessage id="modalItemMasterFormItemNameTextItemType" defaultMessage="Item Type" />
                          </span>
                          <Form.Item>
                            {data.modelName
                              ? data.brandName + ' ' + data.modelName + ' ' + data.itemName
                              : data.brandName + ' ' + data.itemName}
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row gutter={[12, 6]}>
                        <Col span={12}>
                          <span className="item-label-detail">
                            <FormattedMessage id="itemMasterColumnDimension" defaultMessage="Dimension (m)" />
                          </span>
                          <Form.Item>{`${data.width} * ${data.length} * ${data.height}`}</Form.Item>
                          {/* <Form.Item>{data.itemTypeCode}</Form.Item> */}
                        </Col>
                        <Col span={12}>
                          <span className="item-label-detail">
                            <FormattedMessage id="modalItemMasterFormItemNameTextWeight" defaultMessage="Weight (kg)" />
                          </span>
                          <Form.Item>{data.weight ? data.weight : '-'}</Form.Item>
                        </Col>
                      </Row>

                      <Row gutter={[12, 6]}>
                        <Col span={12}>
                          <span className="item-label-detail">
                            <FormattedMessage id="uomCategoryText" defaultMessage="Category" />
                          </span>
                          <Form.Item>
                            {data.categoryCode === 'service' ? (
                              <FormattedMessage id="modalItemMasterFormItemNameTextService" defaultMessage="Service" />
                            ) : (
                              <FormattedMessage id="modalItemMasterFormItemNameTextProduct" defaultMessage="Product" />
                            )}
                          </Form.Item>
                          {/* <Form.Item>{data.itemTypeCode}</Form.Item> */}
                        </Col>
                      </Row>

                      <Row gutter={[12, 6]}>
                        <Col span={12}>
                          <span className="item-label-detail">
                            <FormattedMessage id="modalItemMasterFormItemNameTextUoMCategory" defaultMessage="UoM Category" />
                          </span>
                          <Form.Item>{data.uomCategory ? data.uomCategory.name : '-'}</Form.Item>
                        </Col>
                        <Col span={12}>
                          <span className="item-label-detail">
                            <FormattedMessage id="modalItemMasterFormItemNameTextUom" defaultMessage="UoM" />
                          </span>
                          <Form.Item>{data.uom ? data.uom.name : '-'}</Form.Item>
                        </Col>
                      </Row>

                      <Row gutter={[12, 6]}>
                        <Col span={12}>
                          <span className="item-label-detail">
                            <FormattedMessage id="modalItemMasterFormItemNamePickingType" defaultMessage="Picking Type" />
                          </span>
                          <Form.Item>{data.pickingType ? data.pickingType.nampickingTypeNamee : '-'}</Form.Item>
                        </Col>
                        <Col span={12}>
                          {' '}
                          <span className="item-label-detail">
                            <FormattedMessage id="modalItemMasterFormItemTextCredit" defaultMessage="Credit (Days)" />
                          </span>
                          <Form.Item>{data.creditDay ? data.creditDay : '-'}</Form.Item>
                        </Col>
                      </Row>

                      <Row gutter={[12, 6]}>
                        <Col span={12}>
                          <span className="item-label-detail">
                            <FormattedMessage id="modalItemMasterFormItemNameStandardCost" defaultMessage="Standard Cost" />
                          </span>
                          <Form.Item>
                            <NumberFormat
                              value={(parseFloat(data.standardCost) || 0).toFixed(2)}
                              displayType={'text'}
                              thousandSeparator={true}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <span className="item-label-detail">
                            <FormattedMessage id="modalItemMasterFormItemNameStandardPrice" defaultMessage="Standard Price" />
                          </span>
                          <Form.Item>
                            <NumberFormat
                              value={(parseFloat(data.standardPrice) || 0).toFixed(2)}
                              displayType={'text'}
                              thousandSeparator={true}
                            />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row gutter={[12, 6]}>
                        <Col span={12}>
                          <span className="item-label-detail">
                            <FormattedMessage id="modalItemMasterFormItemTextFDA" defaultMessage="FDA" />
                          </span>
                          <Form.Item>{data.FDA ? data.FDA : '-'}</Form.Item>
                          {/* <Form.Item>{data.itemTypeCode}</Form.Item> */}
                        </Col>
                      </Row>
                    </div>
                    <Divider type="horizontal" />
                    <div className="padding-div-bottom-detail">
                      <p className="item-label-detail">
                        <FormattedMessage id="warehouseDetailRemark" defaultMessage="Remark" />
                      </p>
                      <p className="div-description-itemaster">{data.description ? data.description : ''}</p>
                    </div>
                  </Col>
                </Row>
              </Form>
            </div>
          </Card>
          <ModalForm
            visible={visibleModal}
            setVisible={setVisibleModal}
            setTriggerAPI={setTriggerAPI}
            record={recordItemMaster}
            setRecord={setRecordItemMaster}
            dataDefault={dataDefault}
            setBrandId={setBrandId}
            dataModel={dataModel}
            setCreateItem={setCreateItem}
            createItem={createItem}
            setEventDataDefault={setEventDataDefault}
            groupTypeId={groupTypeId}
            setGroupTypeId={setGroupTypeId}
          />
        </div>
      )}
    </PageSettings.Consumer>
  );
};
