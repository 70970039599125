import React, { useState } from 'react';
import { Card, Row, Col } from 'antd'
import { FormattedMessage } from 'react-intl'
import './css/blankAssetClass.css'
import Nodata from '../../../../../components/image/Nodata_web.svg';

const BlankAssetClass = () => {
  return (
    <div className='container-blank-nodata'>
      <div className='div-blank-nodata'>
        <div>
          <img src={Nodata} style={{ width: '60px' }}></img>
        </div>
        <span>
          <FormattedMessage id='assetCardListPleaseSelect' defaultMessage='Please select' />
        </span>
        <span>
          <FormattedMessage id='assetCardListAnAssetClass' defaultMessage='an Asset Class' />
        </span>
      </div>
    </div>
  )
}

export default BlankAssetClass