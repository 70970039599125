import React from 'react'
import './css/index.css';
import _ from 'lodash';
import NumberFormat from 'react-number-format'
import { Divider, Table } from 'antd'
import { FormattedMessage } from 'react-intl';

export default (props) => {
  const Data = _.get(props, 'data')
  const Header = _.get(props, 'dataHeader')
  const Discount = _.get(props, 'selectDiscount')
  const MaximumRow = 15
  const DataTable = _.get(props, 'data.detail')
  const chunkArray = _.chunk(_.get(props, 'data.detail'), MaximumRow)
  let i = 0
  let runningNumber = 1
  const subTotal = (_.get(Data, 'subTotal'))
  let discount = null
  if (_.get(Discount, 'isBath') === true) {
    discount = _.get(Data, 'discount')
  } else {
    discount = (subTotal * _.get(Data, 'discount')) / 100
  }
  const subTotalLess = subTotal - discount
  const tax = (subTotalLess * _.get(Data, 'tax')) / 100
  const totalPrice = subTotalLess + tax;

  // console.log('Dataaaaaaaa:::', Data);

  return (
    <div>
      {chunkArray.map((el, index) =>
        <div className='print-report'>
          <div className='print-report-contener' style={{ height: '1500px' }}>
            <div className='print-report-header'>
              <div className='print-report-header-contect-left'>
                <div className='print-report-lable'>{_.get(Header, 'invHeaderName')}</div>
                <span className='print-report-text'>{_.get(Header, 'invHeaderAddress')}</span>
                <div className='print-report-header-tax-phone'>
                  <div className='print-report-header-tax'>
                    <span className='print-report-text-2'><FormattedMessage id="ViewQutotationHeaderTaxNo" defaultMessage="Tax No." />:&nbsp;</span>
                    <span className='print-report-text-1'>{_.get(Header, 'invHeaderTaxNo')}</span>
                  </div>
                  <div className='print-report-header-tax'>
                    <span className='print-report-text-2'><FormattedMessage id="ViewQutotationHeaderPhone" defaultMessage="Phone" /> :&nbsp;</span>
                    <span className='print-report-text-1'>{_.get(Header, 'invHeaderPhone')}</span>
                  </div>
                </div>
              </div>
              <div className='print-report-header-contect-right'>
                <div className='print-report-lable-big'><FormattedMessage id="ViewQutotationHeaderQuotation" defaultMessage="Quotation" /></div>
              </div>
            </div>
            <Divider className='print-report-divider' />
            <div className='print-report-customer'>
              <div className='print-report-customer-left'>
                <span className='print-report-text-2'><FormattedMessage id="ViewQutotationHeaderCustomer" defaultMessage="Customer" /></span>
                <div className='print-report-lable'>{_.get(Data, 'customerName')}</div>
                <span className='print-report-text-1'>{_.get(Data, 'customerAddress')}</span>
                <div className='print-report-customer-contact'>
                  <div className='print-report-customer-text-div'>
                    <span className='print-report-text-2'><FormattedMessage id="ViewQutotationHeaderContact" defaultMessage="Contact" />:</span>
                  </div>
                  <span className='print-report-text-1'>{_.get(Data, 'customerContactName')}</span>
                </div>
                <div className='print-report-customer-contact'>
                  <div className='print-report-customer-text-div'>
                    <span className='print-report-text-2'><FormattedMessage id="ViewQutotationHeaderPhone" defaultMessage="Phone" />:</span>
                  </div>
                  <span className='print-report-text-1'>{_.get(Data, 'customerContactPhone')}</span>
                </div>
              </div>
              <div className='print-report-customer-right'>
                <span className='print-report-text-2'><FormattedMessage id="ViewQutotationHeaderQuotationNo" defaultMessage="Quotation No" />.</span>
                <div className='print-report-lable'>{_.get(Data, 'quotationNo')}</div>
                <span className='print-report-text-2'><FormattedMessage id="ViewQutotationHeaderDate" defaultMessage="Date Of Issue" /></span>
                <div className='print-report-lable'>{_.get(Data, 'createDate')}</div>
              </div>
            </div>

            <div className='print-report-table'>
              <table class='quotation-table-item'>
                <tr>
                  <th>#</th>
                  <th><FormattedMessage id="ViewQutotationTableItemName" defaultMessage="Item Name" /></th>
                  <th><FormattedMessage id="ViewQutotationTableUnitPrice" defaultMessage="Unit Price (Baht)" /></th>
                  <th><FormattedMessage id="ViewQutotationTableQty" defaultMessage="Qty" /></th>
                  <th><FormattedMessage id="ViewQutotationTableUnit" defaultMessage="Unit" /></th>
                  <th><FormattedMessage id="ViewQutotationTableTotal" defaultMessage="Total (Baht)" /></th>
                </tr>
                {el && el.map((item, index) => (
                  <tr>
                    <td>{(runningNumber++)}</td>
                    <td>{_.get(item, 'itemName')}</td>
                    <td><NumberFormat value={_.get(item, 'price').toFixed(2)} displayType={'text'} thousandSeparator={true} /></td>
                    <td>{_.get(item, 'qty')}</td>
                    <td>{_.get(item, 'itemUnitName')}</td>
                    <td><NumberFormat value={_.get(item, 'totalPrice').toFixed(2)} displayType={'text'} thousandSeparator={true} /></td>
                  </tr>
                ))}
              </table>
            </div>
            {(chunkArray.length - 1) === i++ ? (
              <div>
                <div className='print-report-remark'>
                  <div className='print-report-remark-left'>
                    <span className='print-report-text-1'><FormattedMessage id="ViewQutotationFooterRemark" defaultMessage="Remark" /></span>
                    <div className='print-report-remark-content'>
                      <div dangerouslySetInnerHTML={{ __html: _.get(Data, 'remark').replace(/(?:\r\n|\r|\n)/g, '<br>') }} />
                    </div>
                  </div>

                  <div className='print-report-remark-right'>

                    <div className="print-calculate-money">
                      <div className="print-calculate-money-left">
                        <span className="print-report-text-1">
                          <FormattedMessage id="ViewQutotationFooterSubtotal" defaultMessage="Subtotal" />
                        </span>
                      </div>
                      <div className="print-calculate-money-right">
                        <span className="print-report-text-1"><NumberFormat value={(subTotal).toFixed(2)} displayType={'text'} thousandSeparator={true} /></span> &nbsp; <span className="print-report-text-1"><FormattedMessage id="ViewQutotationFooterBaht" defaultMessage="Baht" /></span>
                      </div>
                    </div>

                    <div className="print-calculate-money">
                      <div className="print-calculate-money-left">
                        <span className="print-report-text-1">
                          <FormattedMessage id="ViewQutotationFooterDiscount" defaultMessage="Discount" /> {_.get(Discount, 'isBath') === true ? `Bath` : `${_.get(Data, 'discount')}%`}
                        </span>
                      </div>
                      <div className="print-calculate-money-right">
                        <span className="print-report-text-1"><NumberFormat value={(discount).toFixed(2)} displayType={'text'} thousandSeparator={true} /></span> &nbsp; <span className="print-report-text-1"><FormattedMessage id="ViewQutotationFooterBaht" defaultMessage="Baht" /></span>
                      </div>
                    </div>

                    <div className="print-calculate-money">
                      <div className="print-calculate-money-left">
                        <span className="print-report-text-1">
                          <FormattedMessage id="ViewQutotationFooterSubtotalLess" defaultMessage="Subtotal Less Discount" />
                        </span>
                      </div>
                      <div className="print-calculate-money-right">
                        <span className="print-report-text-1"><NumberFormat value={(subTotalLess).toFixed(2)} displayType={'text'} thousandSeparator={true} /></span> &nbsp; <span className="print-report-text-1"><FormattedMessage id="ViewQutotationFooterBaht" defaultMessage="Baht" /></span>
                      </div>
                    </div>

                    <div className="print-calculate-money">
                      <div className="print-calculate-money-left">
                        <span className="print-report-text-1">
                          <FormattedMessage id="ViewQutotationFooterTax" defaultMessage="Tax" /> {_.get(Data, 'tax')}%
                        </span>
                      </div>
                      <div className="print-calculate-money-right">
                        <span className="print-report-text-1"><NumberFormat value={(tax).toFixed(2)} displayType={'text'} thousandSeparator={true} /></span> &nbsp; <span className="print-report-text-1"><FormattedMessage id="ViewQutotationFooterBaht" defaultMessage="Baht" /></span>
                      </div>
                    </div>

                    <div className="print-calculate-money">
                      <div className="print-calculate-money-left">
                        <span className="print-report-text-1">
                          <FormattedMessage id="ViewQutotationFooterTotal" defaultMessage="Total" />
                        </span>
                      </div>
                      <div className="print-calculate-money-right">
                        <span className="print-report-text-1"><NumberFormat value={(totalPrice).toFixed(2)} displayType={'text'} thousandSeparator={true} /></span> &nbsp; <span className="print-report-text-1"><FormattedMessage id="ViewQutotationFooterBaht" defaultMessage="Baht" /></span>
                      </div>
                    </div>

                  </div>
                </div>

                <div className='print-report-signature'>
                  <div className='print-report-signature-div'>
                    <span className='print-report-text-1'><FormattedMessage id="ViewQutotationFooterCustomer" defaultMessage="Customer" /></span>
                    <hr style={{ width: 177 }} className='print-signature-divider' />
                    <span className='print-report-text-1'>({_.get(Data, 'customerName')})</span><br />
                    <span className='print-report-text-1'><FormattedMessage id="ViewQutotationFooterCustomer" defaultMessage="Customer" /></span>
                  </div>
                  <div className='print-report-signature-div'>
                    <span className='print-report-text-1'><FormattedMessage id="ViewQutotationFooterApprover" defaultMessage="Approver" /></span>
                    <hr style={{ width: 177 }} className='print-signature-divider' />
                    <span className='print-report-text-1'>{_.get(Data, 'updatebyName') ? `(${_.get(Data, 'updatebyName')})` : null}</span><br />
                    <span className='print-report-text-1'><FormattedMessage id="ViewQutotationFooterApprover" defaultMessage="Approver" /></span>
                  </div>
                  <div className='print-report-signature-div'>
                    <span className='print-report-text-1'><FormattedMessage id="ViewQutotationFooterSale" defaultMessage="Sale" /></span>
                    <hr style={{ width: 177 }} className='print-signature-divider' />
                    <span className='print-report-text-1'>({_.get(Data, 'sale')})</span><br />
                    <span className='print-report-text-1'><FormattedMessage id="ViewQutotationFooterSale" defaultMessage="Sale" /></span>
                  </div>
                </div>
              </div>
            ) : null}
            <div style={{ textAlign: 'center', fontSize: '12px' }}>
              <FormattedMessage id="ViewQutotationFooterPage" defaultMessage="Page" /> {index + 1} <FormattedMessage id="ViewQutotationFooterOf" defaultMessage="of" /> {_.size(chunkArray)}
            </div>
          </div>
        </div>
      )}
    </div>

  )
}
