import React from 'react';
import { Col, Form, Modal, Row, Select, Input } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import _ from 'lodash';
import LabeRequireForm from '../label-required-form';
import Button_02 from '../v2/button_02';
import Button_01 from '../v2/button_01';
import { approveAllRequest } from '../../controllers/approval/approval-controller';
import { errorNotification, successNotification } from '../v2/notification';

const { Option } = Select;
const { TextArea } = Input;

const ReasonModalRequest = ({
  form,
  title,
  visible,
  onOk,
  onCancel,
  code,
  saleOrderId,
  initialReasonList,
  typeModalReason,
  recordRequest,
  mainModalCancel,
}) => {
  const { getFieldDecorator, resetFields } = form;
  const intl = useIntl();

  const handleSave = () => {
    form.validateFields(async (err, values) => {
      console.log('values', recordRequest);
      if (err) {
        return;
      }

      let payload =
        typeModalReason === 'cancel'
          ? { reason: values.reasonId, remark: values.resonRemark }
          : typeModalReason === 'reject'
          ? { requestIdList: [recordRequest], approverReason: values.reasonId, approverComment: values.resonRemark }
          : { requestIdList: [recordRequest], approverComment: values.resonRemark };

      onOk(payload);
      handleCancel();
      mainModalCancel();
    });
  };

  const handleCancel = () => {
    onCancel();
    resetFields();
  };

  const setReasonOption = (reason) => {
    return (
      // <Option key={`${reason.reasonId}`} value={`${reason.reasonId}`}>
      //   {reason.titleCode}
      // </Option>
      <Option key={`${reason}`} value={`${reason}`}>
        {reason}
      </Option>
    );
  };

  return (
    <Modal
      title={title}
      visible={visible}
      onOk={handleSave}
      onCancel={onCancel}
      width={500}
      centered={true}
      bodyStyle={{ paddingTop: '12px' }}
      footer={[
        <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={() => handleCancel()}>
          <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
        </Button_02>,
        <Button_01 key="submit" type="primary" btnsize="wd_df" onClick={() => handleSave()}>
          <FormattedMessage id="btnSave" defaultMessage="Save" />
        </Button_01>,
      ]}
    >
      <Form name="status" colon={false}>
        {typeModalReason !== 'approve' ? (
          <Row gutter={[24]}>
            <Col span={24}>
              <Form.Item
                label={<LabeRequireForm text={intl.formatMessage({ id: 'quotationLabelReason', defaultMessage: 'Reason' })} req={true} />}
              >
                {getFieldDecorator('reasonId', {
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'quotationWarnReason', defaultMessage: 'please select Reason' }),
                    },
                  ],
                })(
                  <Select
                    className="select-list-customer-monitor"
                    placeholder={intl.formatMessage({ id: 'quotationHintReason', defaultMessage: 'Select Reason' })}
                    defaultActiveFirstOption={false}
                    allowClear={false}
                  >
                    {initialReasonList && initialReasonList.map((reason) => setReasonOption(reason))}
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
        ) : null}
        <Row gutter={[24]}>
          <Col span={24}>
            <Form.Item
              label={<LabeRequireForm text={intl.formatMessage({ id: 'quotationLabelRemark', defaultMessage: 'Remark' })} req={false} />}
            >
              {getFieldDecorator('resonRemark', {})(<TextArea autoSize={{ minRows: 3, maxRows: 3 }} maxLength={250} />)}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

ReasonModalRequest.defaultProps = {
  title: 'Select Customer',
  // title: intl.formatMessage({ id: 'quotationLabelSelectCustomer', defaultMessage: 'Select Customer' }),
  visible: false,
  onOk: () => console.warn('onOk not function '),
  onCancel: () => console.warn('onCancel not function '),
};

const ModalReasonRequestForm = Form.create({ name: 'reject_Form' })(ReasonModalRequest);

export default ModalReasonRequestForm;
