import React, { useState, useEffect, memo } from 'react';
import { Modal, Transfer, Table, Input, Icon, Spin } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import _ from 'lodash';
import { getMemberCompanyAllWhere, saveMemberWarehouse, getMemberWarehouseAllWhere } from '../../../controllers/warehouse/member';
import Button01 from '../../../components/v2/button_01';
import Button02 from '../../../components/v2/button_02';
import { successNotification, errorNotification } from '../../../components/v2/notification';
import cssStyle from '../../organization/member/css/modalMember.css';

const filterOption = (inputValue, items) => {
  if (!inputValue) {
    return items;
  }
  const lowercasedFilter = inputValue.toLowerCase();
  const filteredData = items.filter((item) => {
    return Object.keys(item).some((key) => typeof item[key] === 'string' && item[key].toLowerCase().includes(lowercasedFilter));
  });
  return filteredData;
};

const TableTransfer = memo(({ intl, leftColumns, rightColumns, ...restProps }) => (
  <Transfer className="transfer-member" {...restProps} showSelectAll={false}>
    {({ direction, filteredItems, onItemSelectAll, onItemSelect, selectedKeys: listSelectedKeys, disabled: listDisabled }) => {
      const columns = direction === 'left' ? leftColumns : rightColumns;
      const rowSelection = {
        getCheckboxProps: (item) => ({ disabled: listDisabled || item.disabled }),
        onSelectAll(selected, selectedRows) {
          const treeSelectedKeys = selectedRows.filter((item) => !item.disabled).map(({ key }) => key);
          const diffKeys = selected ? _.difference(treeSelectedKeys, listSelectedKeys) : _.difference(listSelectedKeys, treeSelectedKeys);
          onItemSelectAll(diffKeys, selected);
        },
        onSelect({ key }, selected) {
          onItemSelect(key, selected);
        },
        selectedRowKeys: listSelectedKeys,
      };

      let defalutData = filterOption(restProps.inputSearch[direction === 'left' ? 0 : 1], filteredItems);

      return (
        <div>
          <Input
            className="input-search-member"
            value={restProps.inputSearch[direction === 'left' ? 0 : 1]}
            onChange={(e) => restProps.onChangeInputSearch(direction, e.target.value)}
            placeholder={intl.formatMessage({ id: 'orgTabMemberModalHintSearch', defaultMessage: 'Search' })}
            prefix={<Icon className="input-search-member-prefix" type="search" />}
            autoComplete="off"
          />
          <Table
            className="transfer-table-member"
            rowKey={(record) => record.key}
            rowSelection={rowSelection}
            columns={columns}
            dataSource={defalutData}
            size="small"
            pagination={false}
            style={{ pointerEvents: listDisabled ? 'none' : null, minHeight: 350 }}
            onRow={({ key, disabled: itemDisabled }) => ({
              onClick: () => {
                if (itemDisabled || listDisabled) return;
                onItemSelect(key, !listSelectedKeys.includes(key));
              },
            })}
            scroll={{ y: 300 }}
          />
        </div>
      );
    }}
  </Transfer>
));

const WarehouseModalMemberTransfer = (props) => {
  const { warehouseId, visible, onOk, onCancel, setMainTrigger } = props;
  const intl = useIntl();
  const showSearch = false;
  const [member, setMemeber] = useState([]);
  const [memberWarehouse, setMemberWarehouse] = useState([]);
  const [selected, setSelected] = useState([]);
  const [targetKeys, setTargetKeys] = useState([]);
  const [inputSearch, setInputSearch] = useState(['', '']);
  const [spinning, setSpinning] = useState(false);
  const [btnSaveDisable, setBtnSaveDisable] = useState(false);

  useEffect(() => {
    if (visible) {
      fncGetInitial();
    } else {
      fncAfterCloseModal();
    }
  }, [visible]);

  const fncGetInitial = async () => {
    setBtnSaveDisable(true);
    setSpinning(true);
    const [gMember, gMemberWarehouse] = await Promise.all([getMemberCompanyAllWhere(), getMemberWarehouseAllWhere({}, warehouseId)]);
    setMemeber(gMember);
    setMemberWarehouse(gMemberWarehouse.data);
    setTargetKeys(
      _.chain(gMemberWarehouse.data)
        .map((o) => {
          return o.memComId;
        })
        .filter()
        .value()
    );
    setBtnSaveDisable(false);
    setSpinning(false);
  };

  const fncAfterCloseModal = async () => {
    setMemeber([]);
    setMemberWarehouse([]);
    setSelected([]);
    setTargetKeys([]);
    setInputSearch(['', '']);
  };

  const handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    setSelected([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  const handleChange = (targetKeys, direction, moveKeys) => {
    setTargetKeys(targetKeys);
  };

  const handleChangeInputSearch = (inputPosition, value) => {
    let option = [...inputSearch];
    if (inputPosition === 'left') {
      option[0] = value;
    } else {
      option[1] = value;
    }
    setInputSearch(option);
  };

  const handleSaveMemberWarehouse = async () => {
    setBtnSaveDisable(true);
    setSpinning(true);
    const result = await saveMemberWarehouse(targetKeys, warehouseId);
    if (result.status) {
      successNotification(result.message);
      setMainTrigger((curent) => !curent);
    } else {
      errorNotification(result.message);
    }
    setBtnSaveDisable(false);
    setSpinning(false);
    onOk();
  };

  const handleCancel = async () => {
    onCancel();
  };

  const leftTableColumns = [
    {
      dataIndex: 'fullname',
      title: intl.formatMessage({ id: 'orgTabMemberColumnName', defaultMessage: 'name' }),
    },
    {
      dataIndex: 'email',
      title: intl.formatMessage({ id: 'orgTabMemberColumnEmail', defaultMessage: 'email' }),
    },
  ];

  const rightTableColumns = [
    {
      dataIndex: 'fullname',
      title: intl.formatMessage({ id: 'orgTabMemberColumnName', defaultMessage: 'name' }),
    },
    {
      dataIndex: 'email',
      title: intl.formatMessage({ id: 'orgTabMemberColumnEmail', defaultMessage: 'email' }),
    },
  ];

  return (
    <Modal
      title={<FormattedMessage id="orgTabMember" defaultMessage="Member" />}
      visible={visible}
      centered
      width="85%"
      bodyStyle={{ padding: '24px 24px 24px 24px' }}
      onCancel={onCancel}
      footer={[
        <Button02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleCancel} margin="unset">
          {' '}
          <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
        </Button02>,
        <Button01 key="submit" type="primary" btnsize="wd_df" onClick={handleSaveMemberWarehouse} disabled={btnSaveDisable}>
          <FormattedMessage id="btnSave" defaultMessage="Save" />
        </Button01>,
      ]}
    >
      <Spin spinning={spinning} tip="Loading...">
        <TableTransfer
          intl={intl}
          dataSource={member}
          targetKeys={targetKeys}
          showSearch={showSearch}
          onChange={handleChange}
          leftColumns={leftTableColumns}
          rightColumns={rightTableColumns}
          inputSearch={inputSearch}
          onChangeInputSearch={handleChangeInputSearch}
        />
      </Spin>
    </Modal>
  );
};

export default WarehouseModalMemberTransfer;
