import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import { QuotationContext } from './quotation-context';
import {
  getDefaultItemAndVehicle,
  getMembers,
  getDefaultStarted,
  editQuotation,
} from '../../controllers/quotation/quotation-api';
import { PageSettings } from '../../config/page-settings';
import {
  successNotification,
  errorNotification,
  warningNotification,
} from '../v2/notification';
import { useIntl, FormattedMessage } from 'react-intl';
import { Modal, Spin } from 'antd';
import QuotationSetting from './quotation-setting';
import QuotationReport from './quotation-report';
import QuotationCalculate from './quotation-calculate';

import Button01 from '../v2/button_01';
import Button02 from '../v2/button_02';
import httpClient from '../axiosClient';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import axios from 'axios';
import _, { debounce } from 'lodash';

import stylesModal from './css/index.css';
import GetCustomerApi from '../../controllers/getCustomer/get-customer-api';
import ViewCustomer from '../view-customer/index';
import Addcustomer from '../add-business-partner/index';
import EditbusinessPartner from '../edit-business-partner/index';
import getCustomerInformation from '../../controllers/getCustomer/get-customer-information';
import getWareHouse from '../../controllers/sale-order/get-werehouse';
import AddContact from '../add-contact-v2/index';
import EditContact from '../edit-contact-v2/index';
import QuotationItem from '../quotation-item/index';
import AddAddress from '../modal-create-adress-map-v2/index';
import EditAddress from '../modal-edit-adress-map-v2/index';
import { getItemAll } from '../../controllers/quotation/quotation-api';


const today = new Date();
const dateFormat = 'ddd, MMM DD YYYY';

const CreateQuotation = (props) => {
  const intl = useIntl();
  const {
    visibleModal,
    setVisibleModal,
    setTriggerApi,
    dataEdit,
    remark,
    setRemark,
  } = props;
  const comCode = localStorage.getItem('comCode');

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
      render: (text, record, index) => index + 1,
    },
    {
      title: intl.formatMessage({
        id: 'modalQuotationColumnsItemName',
        defaultMessage: 'Item Name',
      }),
      dataIndex: 'itemName',
      key: 'itemName',
    },
    {
      title: intl.formatMessage({
        id: 'modalQuotationColumnsUnitPrice',
        defaultMessage: 'Unit Price (Baht)',
      }),
      dataIndex: 'price',
      key: 'price',
      align: 'right',
      width: 180,
      render: (text, record, index) => (
        <NumberFormat
          value={parseFloat(record.price).toFixed(2)}
          displayType={'text'}
          thousandSeparator={true}
        />
      ),
    },
    {
      title: intl.formatMessage({
        id: 'modalQuotationrColumnsQty',
        defaultMessage: 'Qty',
      }),
      dataIndex: 'qty',
      key: 'qty',
      align: 'center',
      width: 120,
      render: (text, record, index) => (
        <NumberFormat
          value={parseFloat(record.qty)}
          displayType={'text'}
          thousandSeparator={true}
        />
      ),
    },
    {
      title: intl.formatMessage({
        id: 'modalQuotationColumnsUnit',
        defaultMessage: 'Unit',
      }),
      dataIndex: 'itemUnitName',
      key: 'itemUnitName',
      align: 'right',
      width: 120,
    },
    {
      title: intl.formatMessage({
        id: 'modalQuotationColumnsTotal',
        defaultMessage: 'Total (Baht)',
      }),
      dataIndex: 'totalPrice',
      key: 'totalPrice',
      align: 'right',
      width: 140,
      render: (text, record, index) => (
        <NumberFormat
          value={parseFloat(record.totalPrice).toFixed(2)}
          displayType={'text'}
          thousandSeparator={true}
        />
      ),
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      width: 50,
      render: (text, record, index) => (
        <div
          className="table-column-delete-dot"
          onClick={() => handleDeleteItem(record, index)}
        >
          <div className="table-column-delete-line-style" />
        </div>
        // <Button className="table-column-delete" shape="circle" icon="minus" size="small" />
      ),
    },
  ];

  const [formRefSetting, setFormRefSetting] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingModal, setloadingModal] = useState(false);
  const [discountSelete, setDiscountSelete] = useState({});
  const [taxSelete, setTaxSelete] = useState({});
  const [discountData, setDiscountData] = useState([]);
  const [taxData, setTaxData] = useState([]);
  const [reasonData, setReasonData] = useState([]);
  const [valueInputDiscount, setValueInputDiscount] = useState(0.0);
  const [sumCalculate, setSumCalculate] = useState({});

  const [triggerCustomer, setTriggerCustomer] = useState(false); //Trigger สร้าง แก้ไข Customer
  const [triggerAddress, setTriggerAddress] = useState(false); // Trigger สร้าง แก้ไข Address
  const [visibleViewCustomer, setVisibleViewCustomer] = useState(false);
  const [visibleAddCustomer, setVisibleAddCustomer] = useState(false);
  const [visibleAddContact, setVisibleAddContact] = useState(false);
  const [visibleAddAddress, setVisibleAddAddress] = useState(false);
  const [visibleEditAddress, setVisibleEditAddress] = useState(false);
  const [visibleEditCustomer, setVisibleEditCustomer] = useState(false);
  const [visibleEditContact, setVisibleEditContact] = useState(false);
  const [customerData, setCustomerData] = useState([]); // data ตั้งต้น Customer
  const [addressData, setAddressData] = useState([]); // data ตั้งต้น Address
  const [contactData, setContactData] = useState([]); // data ตั้งต้น Contact
  const [warehouseData, setWarehouseData] = useState([]); // data ตั้งต้น Warehouse
  const [selectCustomer, setSelectCustomer] = useState(); // เลือก Customer
  const [selectAddress, setSelectAddress] = useState(); // เลือก Address
  const [selectWareHouse, setSelectWareHouse] = useState(); // เลือก Warehouse



  const [selectContact, setSelectContact] = useState();
  const [selectContactData, setSelectContactData] = useState(); // Data ตั้งต้น Edit contact
  const [selectAddressData, setSelectAddressData] = useState(); // Data ตั้งต้น Edit Address
  const [AddCustomerData, setAddCustomerData] = useState(); // สร้าง New Customer
  const [AddAddressData, setAddAddressData] = useState(); // สร้าง New Address
  const [AddContactData, setAddContactData] = useState(); // สร้าง New Contact
  const [editContactData, setEditContactData] = useState(); // Data แก้ไข Contact
  const [statusSet, setStatusSet] = useState(false);

  const [defaultProducts, setDefaultProducts] = useState([]);
  const [defaultVehicles, setDefaultVehicles] = useState([]);
  const [defaultUnits, setDefaultUnits] = useState([]);
  const [defaultMembers, setDefaultMembers] = useState([]);
  const [subTotalResult, setSubTotalResult] = useState(0.0);
  const [polyline, setPolyline] = useState([]); // Data Plypline
  const [distance, setDistance] = useState(); // ระยะทาง
  const [marker, setMarker] = useState();
  // const [remark, setRemark] = useState("");

  // const [remark, setRemark] = useState(`กำหนดยืนราคา : 30 วัน
  // การชำระเงิน : เครดิต
  // หมายเหตุ : 1. ราคาดังกล่าวเป็นราคายังไม่รวมภาษีมูลค่าเพิ่ม
  // 2. ราคาดังกล่าวเป็นราคาขายด้วยระบบชั่งน้ำหนัก โดยผ่านตราชั่งที่ได้มาตรฐาน
  //     จากกระทรวงพาณิชย์
  // 3. จัดส่งด้วยรถหกล้อ (บรรทุกได้ประมาณ 5 คิว หรือ 7.5 ตัน / เที่ยว),
  //     จัดส่งด้วยรถสิบล้อ (บรรทุกไดประมาณ 10 คิว หรือ 15 ตัน / เที่ยว),
  //     จัดส่งด้วยรถพ่วงเทลเลอร์ (บรรทุกได้ประมาณ 20 คิว หรือ 30 ตัน / เที่ยว)
  // 4. ติดต่อสอบถามได้ที่ Call Center 0-2951-5533
  //     มือถือ 091-772-5000,6000,7000,8000,
  //     9000 บริษัทฯ หวังเป็นอย่างยิ่งว่าราคาที่บริษัทเรียนเสนอนี้ จะได้รับการ
  //     พิจารณาสั่งซื้อจากท่าน`);

  // const [item, setItem] = useState([]);

  const [itemObjectData, setItemObjectData] = useState([]);
  const [visibleAddItem, setVisibleAddItem] = useState(false);

  useEffect(() => {
    if (
      dataEdit &&
      customerData &&
      warehouseData &&
      contactData &&
      visibleModal === true
    ) {
      const filterCustomer =
        customerData &&
        customerData.filter((item) => {
          return item.customerId === _.get(dataEdit, 'customerId');
        });
      // const filterWareHouse =
      //   warehouseData &&
      //   warehouseData.filter((item) => {
      //     return (
      //       item.warehouseId === _.get(dataEdit, 'wareHourse[0].warehouseId')
      //     );
      //   });
      setItemObjectData(_.get(dataEdit, 'detail'));
      setSelectCustomer(_.get(filterCustomer, '[0]'));
      // setSelectWareHouse(_.get(filterWareHouse, '[0]'));
      setValueInputDiscount(_.get(dataEdit, 'discount'))
    }
  }, [_.get(dataEdit, 'quotationId'), visibleModal]);

  useEffect(() => {
    const filterAddress =
      addressData &&
      addressData.filter((item) => {
        return item.customerAddressBookId === _.get(dataEdit, 'addressBookId');
      });
    if (_.size(filterAddress) !== 0) {
      setSelectAddress(_.get(filterAddress, '[0]'));
    }
  }, [addressData]);

  useEffect(() => {
    const getCustomerAll = async () => {
      let dataCustomer = await GetCustomerApi();
      setCustomerData(dataCustomer.customers);
    };
    getCustomerAll();
  }, [triggerCustomer]);

  useEffect(() => {
    const customerId = _.get(selectCustomer, 'customerId');
    const getAddress = async () => {
      setloadingModal(true);
      if (customerId) {
        setLoading(true);
        const response = await getCustomerInformation(customerId);
        if (response) {
          setContactData(_.get(response, 'contacts'));
          const filterContact = _.get(response, 'contacts').filter((item) => {
            return (
              item.customerContactId === _.get(dataEdit, 'customerContactId')
            );
          });
          if (_.size(filterContact) !== 0) {
            setSelectContact(_.get(filterContact, '[0]'));
          }
          setAddressData(_.get(response, 'addressBooks'));
          setLoading(false);
          setloadingModal(false);
        }
      }
    };
    getAddress();
  }, [_.get(selectCustomer, 'customerId'), triggerAddress]);

  // useEffect(() => {
  //   const getDataWareHouse = async () => {
  //     const response = await getWareHouse();
  //     if (response) {
  //       setWarehouseData(_.get(response, 'Warehouse'));
  //     }
  //   };
  //   getDataWareHouse();
  // }, []);

  useEffect(() => {

    let customerId = _.get(selectCustomer, 'customerId');
    let lat = _.get(selectAddress, 'lat');
    let lng = _.get(selectAddress, 'lng');
    let warehouseId = _.get(selectWareHouse, 'warehouseId')


    let distance = _.get(selectWareHouse, 'distance') !== undefined ? _.get(selectWareHouse, 'distance') : _.get(dataEdit, 'wareHourse[0].distance');


    const getItem = async () => {
      const response = await getItemAll(
        customerId,
        lat,
        lng,
        distance,
        warehouseId
      );

      if (dataEdit && visibleModal === true && _.get(selectWareHouse, 'warehouseId')) {
        const filterWareHouse =
          _.get(response.data, 'warehouses').filter((item) => {
            return (
              item.selected === true
            );
          });
        setWarehouseData(_.get(response.data, 'warehouses'));
        setSelectWareHouse(_.get(filterWareHouse, '[0]'));
      } else {
        const filterWareHouse =
          _.filter(_.get(response.data, 'warehouses'),(item) => {
            return (
              item.warehouseId === _.get(dataEdit, 'wareHourse[0].warehouseId')
            );
          });
        setWarehouseData(_.get(response.data, 'warehouses'));
        setSelectWareHouse(_.get(filterWareHouse, '[0]'));
      }


    };
    getItem();
  }, [_.get(selectCustomer, 'customerId'), _.get(selectWareHouse, 'warehouseId'), _.get(dataEdit, 'quotationId'), _.get(selectAddress, 'customerAddressBookId'), visibleModal]);

  useEffect(() => {
    const getApi = async () => {
      // const response = await getDefaultItemAndVehicle();
      const responseMembers = await getMembers();
      // setDefaultProducts(_.get(response.data, 'products'));
      // setDefaultVehicles(_.get(response.data, 'vehicles'));
      // setDefaultUnits(_.get(response.data, 'units'));
      setDefaultMembers(_.get(responseMembers.data, 'data'));
    };
    getApi();
  }, []);

  useEffect(() => {
    if (dataEdit !== undefined) {
      const getDefault = async () => {
        const response = await getDefaultStarted();
        const resultDiscount =
          response &&
          response.dataDiscount.filter((discountData) => {
            return discountData.discountId === dataEdit.discountId;
          });
        const resultTax =
          response &&
          response.dataTax.filter((taxData) => {
            return taxData.taxId === dataEdit.taxId;
          });

        setReasonData(_.get(response, 'dataReason'));
        setDiscountData(_.get(response, 'dataDiscount'));
        setTaxData(_.get(response, 'dataTax'));
        setDiscountSelete(resultDiscount[0]);
        setTaxSelete(resultTax[0]);
      };
      getDefault();
    }
  }, [dataEdit]);

  useEffect(() => {
    const totalItem =
      itemObjectData &&
      itemObjectData.map((item) => {
        return parseFloat(item.totalPrice);
      });
    const numbers = totalItem.map((n) => {
      return parseFloat(n, 10);
    });
    const sumTotal = numbers && numbers.reduce((a, b) => a + b, 0);
    setSubTotalResult(_.floor(sumTotal, 2).toFixed(2));
  }, [itemObjectData, visibleAddItem]);

  let valueSubTotalResult = parseFloat(subTotalResult).toFixed(2);

  const handleCancel = () => {
    setVisibleModal(false);
    formRefSetting.resetFields();
    setRemark('');
    setItemObjectData([]);
    setSelectCustomer(); // เลือก Customer
    setSelectAddress(); // เลือก Address
    setSelectWareHouse(); // เลือก Warehouse
    setSelectContactData(); // Data ตั้งต้น Edit contact
    setSelectAddressData(); // Data ตั้งต้น Edit Address
    setAddCustomerData(); // สร้าง New Customer
    setAddAddressData(); // สร้าง New Address
    setAddContactData(); // สร้าง New Contact0
    setStatusSet(false);
    setPolyline([]);
    setDistance();
    setMarker();
  };

  const handleSave = (status) => {
    formRefSetting.validateFields(async (err, values) => {
      if (err) {
        return;
      }

      const body = {
        status: status,
        customerId: values.customer,
        addressbookId: values.address,
        contactId: values.contact,
        memComId: values.sale,
        warehouseId: values.warehouse,
        remark: remark,
        taxId: taxSelete.taxId ? taxSelete.taxId : undefined,
        discountId: discountSelete.discountId
          ? discountSelete.discountId
          : undefined,
        subTotal: parseFloat(valueSubTotalResult),
        discount: discountSelete.percentage ? discountSelete.percentage : valueInputDiscount,
        tax: taxSelete.percentage ? taxSelete.percentage : 0,
        total:
          sumCalculate && sumCalculate.total
            ? parseFloat(sumCalculate.total)
            : 0,
        print: 0,
        detail: itemObjectData,
        quotationId: _.get(dataEdit, 'quotationId'),
      };

      const response = await editQuotation(body);
      if (response.status === 200) {
        successNotification(response.message);
        setTriggerApi((current) => !current);
        setVisibleModal(false);
        setRemark('');
        setItemObjectData([]);
        formRefSetting.resetFields();
        setSelectCustomer(); // เลือก Customer
        setSelectAddress(); // เลือก Address
        setSelectWareHouse(); // เลือก Warehouse
        setSelectContactData(); // Data ตั้งต้น Edit contact
        setSelectAddressData(); // Data ตั้งต้น Edit Address
        setAddCustomerData(); // สร้าง New Customer
        setAddAddressData(); // สร้าง New Address
        setAddContactData(); // สร้าง New Contact
        setStatusSet(false);
        setPolyline([]);
        setDistance();
        setMarker();
      }
    });
  };

  const saveFormRefSetting = useCallback((node) => {
    if (node !== null) {
      setFormRefSetting(node);
    }
  }, []);

  const handleDeleteItem = (record, index) => {
    const columnNew =
      itemObjectData && itemObjectData.filter((col, i) => i !== index);
    setItemObjectData(columnNew);
  };

  const onChangeDiscount = (value, option) => {
    if (value) {
      const result =
        discountData &&
        discountData.filter((item) => {
          return item.discountId === value;
        });
      setDiscountSelete(result[0]);
      setValueInputDiscount(0.0);
    }
  };

  const onChangeTax = (value, option) => {
    if (value) {
      const result =
        taxData &&
        taxData.filter((item) => {
          return item.taxId === value;
        });
      setTaxSelete(result[0]);
    }
  };

  const onChangeInput = (value) => {
    if (value === undefined) {
      setValueInputDiscount('');
    } else {
      if (parseFloat(valueSubTotalResult) <= parseFloat(value)) {
        setValueInputDiscount(0.0);
      } else {
        setValueInputDiscount(value);
      }
    }
  };

  const handleOpenAddItem = () => {
    setVisibleAddItem(true);
  };

  const handleAppplyItem = () => {
    setVisibleAddItem(false);
  };

  const handleCancelItem = () => {
    setVisibleAddItem(false);
  };

  return (
    <div className="quotation-modal">
      <QuotationContext.Provider
        value={{
          discountSelete,
          taxSelete,
          remark,
          discountData,
          taxData,
          valueInputDiscount,
          subTotalResult,
          valueSubTotalResult,
          itemObjectData,
          setStateMain: {
            setSumCalculate,
          },
          fnc: {
            onChangeDiscount,
            onChangeTax,
            onChangeInput,
          },
        }}
      >
        <Modal
          className="quotation-card-modal"
          title="Edit Quotation"
          centered="true"
          width={1100}
          visible={visibleModal}
          onCancel={handleCancel}
          footer={[
            <>
              <Button02 style={{margin : '0px 0px 0px 10px'}} key="back" btnsize="wd_df" onClick={handleCancel}>
                <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
              </Button02>
              <Button02 style={{margin : '0px 0px 0px 10px'}}
                key="back"
                btnsize="wd_df"
                disabled={_.size(itemObjectData) !== 0 ? false : true}
                onClick={() => handleSave('draft')}
              >
                <FormattedMessage id="quotationModalBtnDraft" defaultMessage="Draft" />
              </Button02>
              <Button01
                key="submit"
                type="primary"
                btnsize="wd_df"
                disabled={_.size(itemObjectData) !== 0 ? false : true}
                onClick={() => handleSave('waiting')}
              >
                <FormattedMessage id="btnSave" defaultMessage="Save" />
              </Button01>
            </>,
          ]}
        >
          <Spin spinning={loadingModal}>
            <div className="card-modal-body">
              <div className="modal-container">
                <QuotationSetting
                  ref={saveFormRefSetting}
                  value={{
                    customerData,
                    addressData,
                    contactData,
                    AddCustomerData,
                    warehouseData,
                    AddContactData,
                    editContactData,
                    AddAddressData,
                    loading,
                    defaultMembers,
                    dataEdit,
                    selectAddress,
                    selectCustomer,
                    selectWareHouse,
                    statusSet,
                    selectContact,
                  }}
                  fnc={{
                    setVisibleViewCustomer,
                    setSelectCustomer,
                    setVisibleAddCustomer,
                    setVisibleEditCustomer,
                    setLoading,
                    setVisibleAddContact,
                    setVisibleEditContact,
                    setSelectContactData,
                    setVisibleAddAddress,
                    setSelectAddressData,
                    setVisibleEditAddress,
                    setSelectAddress,
                    setSelectWareHouse,
                    setStatusSet,
                    setSelectContact,
                    setItemObjectData,
                    setAddContactData,
                  }}
                />
              </div>

              <div className="modal-container">
                <QuotationReport
                  item={itemObjectData}
                  columns={columns}
                  onOpen={handleOpenAddItem}
                />
              </div>

              <div className="modal-container">
                <QuotationCalculate remark={remark} setRemark={setRemark} />
              </div>
            </div>
          </Spin>
        </Modal>

        <QuotationItem
          value={{
            visibleAddItem,
            customerData,
            selectCustomer,
            selectAddress,
            selectWareHouse,
            warehouseData,
            defaultProducts,
            defaultVehicles,
            defaultUnits,
            itemObjectData,
            discountSelete,
            taxSelete,
            taxData,
            discountData,
            valueInputDiscount,
            subTotalResult,
            quotationDataDefault: dataEdit,
            marker,
            polyline,
            distance,
          }}
          fnc={{
            setItemObjectData,
            handleAppplyItem,
            handleCancelItem,
            setTriggerCustomer,
            onChangeDiscount,
            onChangeTax,
            onChangeInput,
            setSelectAddress,
            setSelectWareHouse,
            setSelectCustomer,
            setStatusSet,
            setSelectContact,
            setSubTotalResult,
            setDiscountSelete,
            setValueInputDiscount,
            setMarker,
            setPolyline,
            setDistance,
          }}
        />

        <Addcustomer
          visible={visibleAddCustomer}
          setVisible={setVisibleAddCustomer}
          setTrigger={setTriggerCustomer}
          setCustomerData={setAddCustomerData}
        />

        <EditbusinessPartner
          visible={visibleEditCustomer}
          setVisible={setVisibleEditCustomer}
          setTrigger={setTriggerCustomer}
          CustomerData={selectCustomer}
        // setCustomerData={setCustomerDataDefalse}
        />

        <ViewCustomer
          visible={visibleViewCustomer}
          setVisible={setVisibleViewCustomer}
          customer={selectCustomer}
        />

        <AddContact
          visible={visibleAddContact}
          setVisible={setVisibleAddContact}
          customerId={_.get(selectCustomer, 'customerId')}
          setContactID={setAddContactData}
          setTriggerAPI={setTriggerAddress}
        />

        <EditContact
          visible={visibleEditContact}
          setVisible={setVisibleEditContact}
          customerId={_.get(selectCustomer, 'customerId')}
          setTriggerAPI={setTriggerAddress}
          setContactID={setEditContactData}
          contactData={selectContactData}
        />

        <AddAddress
          visible={visibleAddAddress}
          setVisible={setVisibleAddAddress}
          customerId={_.get(selectCustomer, 'customerId')}
          customerName={_.get(selectCustomer, 'customerName')}
          setTrigger={setTriggerAddress}
          toLocation={setAddAddressData}
        />

        <EditAddress
          visible={visibleEditAddress}
          setVisible={setVisibleEditAddress}
          setTrigger={setTriggerAddress}
          valueDefault={selectAddressData}
          //  setValueDefault={setValueDefaultEdit}
          customerId={_.get(selectCustomer, 'customerId')}
        />

      </QuotationContext.Provider>
    </div>
  );
};

export default CreateQuotation;
