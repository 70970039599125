import React, { useState, useEffect, useMemo, useRef } from 'react'
import style from './css/detail-invoice.css'
import { Col, Row, Select, Icon, Form } from 'antd'
import moment from 'moment';
import getCustomerApi from '../../controllers/getCustomer/get-customer-api'
import styled from 'styled-components';
import CustomerForm from './customer';
import _ from 'lodash';
import { useIntl, FormattedMessage } from 'react-intl';
import enUS from 'antd/es/locale/en_US';
import thTH from 'antd/es/locale/th_TH';
import { useAppContext } from '../../includes/indexProvider';

const { Option } = Select;

const AddInvoice = ({ form, setVisibleSelectTask, saveFormRef, setCustomerData,
  customerData, triggerOnClickCreate, data, setCustomerId }) => {

  const { getFieldDecorator, validateFields, resetFields, getFieldsValue, getFieldValue, setFieldsValue } = form;
  const LabelRequire = styled.label`
  color: #ff1010;
`;
  const LabeRequire = (props) => {
    const { text, req } = props;
    return (
      <span className="font-p">
        {text}&nbsp;
        {req ? <LabelRequire>*</LabelRequire> : ''}
      </span>
    );
  };

  const app = useAppContext();
  const langValueState = _.get(app, 'state.langValue');
  moment.locale(langValueState);


  const intl = useIntl()
  const today = new Date();
  const [loading, setLoading] = useState(false);
  const [allCustomer, setAllCustomer] = useState(1000);
  // const [customerId, setCustomerId] = useState();
  const customerFormRef = useRef();

  useEffect(() => {
    const getCustomerAll = () => {
      setTimeout(async () => {
        let dataCustomer = await getCustomerApi(allCustomer);
        setAllCustomer(dataCustomer.allCustomers);
        setCustomerData(dataCustomer);
        setLoading(false);
      }, 1000)
    }
    getCustomerAll();
  }, [loading, triggerOnClickCreate]);


  const onScroll = (event) => {
    const target = event.target;
    if (!loading && Math.ceil(target.scrollTop + target.offsetHeight) === target.scrollHeight) {
      setLoading(!loading, () => { target.scrollTo(0, target.scrollHeight) });
    }
  }

  const handleLeaveSelect = () => {
    setAllCustomer(10);
    setLoading(!loading)
  }




  return (
    <div>
      <div className='div-style'>
        <Row>
          <Col span={12}>
            <CustomerForm
              ref={saveFormRef}
              onScroll={onScroll}
              handleLeaveSelect={handleLeaveSelect}
              loading={loading}
              customer={customerData}
              data={data}
              setCustomerId={setCustomerId} />
          </Col>
          <Col span={12} className='align-text-right'>
            <p className='text-customer' style={{marginTop:'10px'}}><FormattedMessage id="modalInvoiceDetailInvoiceNo" defaultMessage="Invoice No" /></p>
            <p className='text-invoice'>-</p>
            <p className='text-customer'><FormattedMessage id="modalInvoiceDetailDate" defaultMessage="Date Of Issue" /></p>
            <p className='text-invoice-date'>{moment(_.get(data,'billDate'), 'YYYY-MM-DD').format('ddd, MMM DD YYYY')}</p>
            <p className='button-select-task' onClick={() => setVisibleSelectTask(true)} style={{ cursor: 'pointer' }}>
              <FormattedMessage id="btnSelectTask" defaultMessage="Select Task" /></p>
          </Col>
        </Row>
      </div>


    </div>
  )
}
const Invoice = Form.create({
  name: "modal_form",
  mapPropsToFields(props) {

  }
})(AddInvoice);

export default React.memo(Invoice);
