import React, { useEffect, useState } from 'react';
import { Checkbox, Row, Col, Icon, Divider, Select, Button, Input, DatePicker } from 'antd';
import _ from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';
// import DeliveryCreateOrderForm from '../../pages/delivery-order-new/component/shipment/modal/create-order';
import DeliveryCreateOrderForm from '../modal-create-order';
import { getOrdersListNew } from '../../controllers/orders-controller/api';

const { Option } = Select;

const CreateOrderDO = (props) => {
  const { visible, setVisible, orgIdPlanning, shippingDate, assetId,setTrigger } = props;
  const intl = useIntl();
  const [orderData, setOrderData] = useState();
  const memComId = localStorage.getItem('memComId');
  const comId = localStorage.getItem('comId');

  useEffect(() => {
    const getOrderList = async () => {
      const body = {
        orderStatus: undefined,
        orderTypeCode: undefined,
        detailTypeCode: undefined,
        customerName: undefined,
        orderNo: undefined,
        source: undefined,
        destination: undefined,
        fromDate: undefined,
        toDate: undefined,
        orderBy: '',
        orderType: '',
        pageNumber: 1,
        limit: 10,
      };
      const response = await getOrdersListNew(body);
      setOrderData(response);
    };
    if (visible) {
      getOrderList();
    }
  }, [visible]);
  const bodyForCheck = {
    orgIdPlanning: orgIdPlanning,
    shippingDate: shippingDate,
    assetId: assetId,
    comId: comId,
  };

  const bodyForAddLoad = {
    assetId: assetId,
  };

  return (
    <div>
      <DeliveryCreateOrderForm
        visible={visible}
        setVisible={setVisible}
        orderCheckType={'deliveryOrderParcel'}
        orderData={orderData}
        bodyForCheck={bodyForCheck}
        bodyForAddLoad={bodyForAddLoad}
        setTriggerPlanning={setTrigger}
        menuCode={'loadPlanning'}
      />
    </div>
  );
};

CreateOrderDO.defaultProps = {
  visible: false,
  setVisible: () => console.warn('onCancel not function '),
  orgIdPlanning: '',
  shippingDate: '',
  assetId: '',
  setTrigger: () => console.warn('onCancel not function '),
};

export default CreateOrderDO;
