import React, { useState, useContext, useEffect, useRef } from 'react';
import GoogleMapReact from 'google-map-react';
import { useLayer } from 'react-laag';
import { useIntl } from 'react-intl';
import httpClient from '../../../../components/axiosClient';
import getMapPolygonApi from '../../../../controllers/map-view/map-polygon-api';
import useSupercluster from 'use-supercluster';
import MapControl from './map-control';
import MarkerResource from './marker-resource';
import MarkerTask from './marker-task';
import _ from 'lodash';
import moment from 'moment';
import '../css/index.css';

const delay = 2;
const Marker = ({ children }) => children;
var polygonService = [];
const comId = localStorage.getItem('comId');

export default (props) => {
  const {
    resourceData,
    taskDataMapView,
    taskDataMap,
    setResourceId,
    setSelectResourceData,
    setSelectTaskData,
    resourceId,
    selectResourceData,
    date,
    mainOrgId,
    selectTaskData,
    onClickTrack,
    resourceIdChecked,
    setResourceIdChecked,
    satelliteChecked,
    areaChecked,
    setSatelliteChecked,
    setAreaChecked,
    setTrackingResource,
    setVisibleTaskDetailModal,
    setMainTaskId,
    setMainTaskIdAssign,
    setVisibleAssignee,
    setMainResourceMemComId,
    setMainStatusTaskTitle,
    setMainStatusTaskObject,
    setVisibleTaskStatusModal,
    LangCode,
    dateCount,
  } = props;
  const mapRef = useRef(null);
  const mapPolyRef = useRef(null);
  const intl = useIntl();
  const [googleMapcenter, setGoogleMapcenter] = useState({ lat: 13.90607, lng: 100.51913 });
  const [googleMapzoom, setGoogleMapzoom] = useState(12);
  const [showResource, setShowResource] = useState(true);
  const [showTask, setShowTask] = useState(true);
  const [isOpen, setOpen] = useState(false);
  const [bounds, setBounds] = useState(null);
  const [pointsTask, setPointsTask] = useState([]);
  const [pointsResource, setPointsResource] = useState([]);
  const [taskStatusData, setTaskStatusData] = useState([]);

  const [polygonShow, setPolygonShow] = useState(false);
  const [polygonTeamService, setPolygonTeamService] = useState([]);

  const dateMoment = date ? moment(date).format('YYYY-MM-DD') : moment(date).format('YYYY-MM-DD');
  const mainOrgIdString = mainOrgId ? mainOrgId.toString() : '';
  const orgIdStrArr =
    mainOrgId &&
    mainOrgId.map(function (e) {
      return e.toString();
    });

  useEffect(() => {
    if (taskDataMapView) {
      const newPointsTask = taskDataMapView.map((crime) => ({
        type: 'Feature',
        properties: { cluster: false, crimeId: crime.taskId },
        geometry: {
          type: 'Point',
          coordinates: [parseFloat(crime.lng), parseFloat(crime.lat)],
        },
        taskData: crime,
      }));
      setPointsTask(newPointsTask);
    }
  }, [taskDataMapView]);

  useEffect(() => {
    if (resourceData) {
      const newPointsResource = resourceData.map((resource) => ({
        type: 'Feature',
        properties: { cluster: false, crimeId: resource.memComId },
        geometry: {
          type: 'Point',
          coordinates: [parseFloat(resource.lng), parseFloat(resource.lat)],
        },
        resourceData: resource,
      }));
      setPointsResource(newPointsResource);
    }
  }, [resourceData]);

  useEffect(() => {
    if (resourceId) {
      if (selectResourceData && resourceIdChecked && mapRef.current) {
        const position = { lat: _.toNumber(selectResourceData.lat), lng: _.toNumber(selectResourceData.lng) };
        mapRef.current.setZoom(21);
        mapRef.current.panTo(position);
      }
      getTaskStatus();
    }
  }, [resourceId]);

  useEffect(() => {
    const getPolygonTeam = async () => {
      const getData = await getMapPolygonApi(orgIdStrArr, '');
      if (areaChecked) {
        onGoogleApiLoaded(mapPolyRef.current.map_, mapPolyRef.current.maps_, getData.data.data.services);
        setPolygonTeamService(getData.data.data.services);
      } else {
        onGoogleApiLoaded(mapPolyRef.current.map_, mapPolyRef.current.maps_, []);
        setPolygonTeamService([]);
      }
    };
    getPolygonTeam();
  }, [mainOrgId, areaChecked]);

  const getTaskStatus = async () => {
    const startDate = moment(dateMoment, 'YYYY-MM-DD')
      .add(-(dateCount === 1 ? 0 : dateCount), 'day')
      .format('YYYY-MM-DD');
    try {
      const response = await httpClient.get(
        `/v3/task/manager/company/${comId}/my/${resourceId}/task/group?startDate=${startDate}&endDate=${dateMoment}&orgId=${mainOrgIdString}&menuType=transportation`
      );
      if (response.status === 200) {
        setTaskStatusData(_.toArray(response.data));
      }
    } catch (error) {
      return;
    }
  };

  const clustersTask = useSupercluster({
    points: pointsTask,
    bounds,
    zoom: googleMapzoom,
    options: { radius: 75, maxZoom: 20 },
  });

  const clustersResource = useSupercluster({
    points: pointsResource,
    bounds,
    zoom: googleMapzoom,
    options: { radius: 75, maxZoom: 20 },
  });

  const getMapOptions = (maps) => {
    return {
      fullscreenControl: false,
      fullscreenControlOptions: false,
      streetViewControl: false,
      zoomControl: true,
      clickableIcons: false,
      disableDefaultUI: true,
      mapTypeId: satelliteChecked ? 'satellite' : 'roadmap',
    };
  };

  const bootstrapURLKeys = {
    key: `${localStorage.getItem('GEO_APIKEY')}`,
    libraries: ['drawing'].join(','),
  };

  const hideResource = () => {
    setShowResource((current) => !current);
  };

  const hideTask = () => {
    setShowTask((current) => !current);
  };

  const openPopoverResource = (item) => {
    setSelectResourceData(item);
    setResourceId(item.memComId);
  };

  const openPopoverTask = (task) => (e) => {
    setSelectTaskData(task);
  };

  const onGoogleApiLoaded = (map, maps, polygonTeamService) => {
    if (polygonTeamService.length > 0 && areaChecked === true) {
      /* const HTMLMarker = (lat,lng) => {

      } */

      /* HTMLMarker.prototype = new maps.OverlayView();
      const onAdd = () => {
        const div = document.createElement('DIV');
        div.className = 'arrow_box';
        div.innerHTML = '<img src='http://sve.hr/oglasnik/apartmani/male/vYfWhoqazs1-sItPo.jpg' alt=''>';
        // var panes = HTMLMarker.getPanes();
  
        // panes.overlayImage.appendChild(div);
      } */

      /* onAdd();

      
       */
      // HTMLMarker.prototype.onAdd= function(){
      //   div = document.createElement('DIV');
      //   div.className = 'arrow_box';
      //   div.innerHTML = '<img src='http://sve.hr/oglasnik/apartmani/male/vYfWhoqazs1-sItPo.jpg' alt=''>';
      //   var panes = this.getPanes();
      //   panes.overlayImage.appendChild(div);
      // }

      // HTMLMarker.prototype.draw = function(){
      //   var overlayProjection = this.getProjection();
      //   var position = overlayProjection.fromLatLngToDivPixel(this.pos);
      //   var panes = this.getPanes();
      //   panes.overlayImage.style.left = position.x + 'px';
      //   panes.overlayImage.style.top = position.y - 30 + 'px';
      // }

      // let overlayViewTest = new maps.OverlayView({
      //   position: {
      //     lat: 13.850980,
      //     lng: 100.494875
      //   }
      // });

      let m = 0;
      if (polygonService.length > 0) {
        for (let i = 0; i < polygonService.length; i++) {
          polygonService[i].setMap(null);
        }
      }

      let polygonArr = [];
      let polygonArrimage = [];

      _.map(polygonTeamService, (el) => {
        polygonArr.push(...el.coordinates);
        polygonArrimage.push({
          image: el.icon,
          defaultCenter: el,
        });
      });

      for (let i = 0; i < polygonArr.length; i++) {
        if (polygonArr[i].type === 'polygon') {
          polygonService[m] = new maps.Polygon({
            paths: polygonArr[i].coordinates,
            strokeColor: polygonArr[i].color,
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: polygonArr[i].color,
            fillOpacity: 0.35,
          });
          polygonService[m].setMap(map);
          m++;
          polygonService[m] = new maps.Marker({
            position: polygonArr[i].defaultCenter,
            icon: polygonArrimage[i].image,
            map: map,
          });
        } else if (polygonArr[i].type === 'point') {
          polygonService[m] = new maps.Marker({
            position: {
              lat: polygonArr[i].coordinates.lat ? polygonArr[i].coordinates.lat : undefined,
              lng: polygonArr[i].coordinates.lng ? polygonArr[i].coordinates.lng : undefined,
            },
            icon: polygonArrimage[i].image,
            map: map,
          });
        }
        m++;
      }
    } else {
      let m = 0;
      if (polygonService.length > 0) {
        for (let i = 0; i < polygonService.length; i++) {
          polygonService[i].setMap(null);
        }
        m++;
      }
    }
  };

  return (
    <div className="container-map-body">
      {<MapControl hideUser={hideResource} hideTask={hideTask} showResource={showResource} showTask={showTask} />}
      <GoogleMapReact
        id={'monitor-map-view'}
        ref={mapPolyRef}
        bootstrapURLKeys={bootstrapURLKeys}
        zoom={googleMapzoom}
        center={googleMapcenter}
        options={getMapOptions}
        yesIWantToUseGoogleMapApiInternals={true}
        onGoogleApiLoaded={({ map, maps }) => {
          mapRef.current = map;
          window.monitor = {
            map,
            maps,
          };
          onGoogleApiLoaded(map, maps, polygonTeamService);
          // ondrawingTools(map, maps);
        }}
        onChange={({ zoom, bounds }) => {
          setGoogleMapzoom(zoom);
          setBounds([bounds.nw.lng, bounds.se.lat, bounds.se.lng, bounds.nw.lat]);
        }}
      >
        {showTask &&
          clustersTask.clusters.map((cluster) => {
            const [longitude, latitude] = cluster.geometry.coordinates;
            const { cluster: isCluster, point_count: pointCount } = cluster.properties;
            if (isCluster) {
              return (
                <Marker key={`cluster-${cluster.id}`} lat={latitude} lng={longitude}>
                  <div
                    className="cluster-marker"
                    style={{
                      width: `${36 + (pointCount / pointsTask.length) * 20}px`,
                      height: `${36 + (pointCount / pointsTask.length) * 20}px`,
                      fontSize: `${12 + (pointCount / pointsTask.length) * 20}px`,
                      zIndex: 1000,
                    }}
                    onClick={() => {
                      const expansionZoom = Math.min(clustersTask.supercluster.getClusterExpansionZoom(cluster.id), 20);
                      mapRef.current.setZoom(expansionZoom);
                      mapRef.current.panTo({ lat: latitude, lng: longitude });
                    }}
                  >
                    {pointCount}
                  </div>
                </Marker>
              );
            }

            return (
              <MarkerTask
                key={`crime-${cluster.properties.crimeId}`}
                intl={intl}
                lat={latitude}
                lng={longitude}
                task={cluster.taskData}
                resource={resourceData}
                onClick={openPopoverTask(cluster.taskData)}
                setVisibleTaskDetailModal={setVisibleTaskDetailModal}
                setMainTaskId={setMainTaskId}
                setMainTaskIdAssign={setMainTaskIdAssign}
                setVisibleAssignee={setVisibleAssignee}
                LangCode={LangCode}
              />
            );
          })}

        {showResource &&
          clustersResource.clusters.map((cluster) => {
            const [longitude, latitude] = cluster.geometry.coordinates;
            const { cluster: isCluster, point_count: pointCount } = cluster.properties;
            if (isCluster) {
              return (
                <Marker key={`cluster-${cluster.id}`} lat={latitude} lng={longitude}>
                  <div
                    className="cluster-marker-resource"
                    style={{
                      width: `${36 + (pointCount / pointsResource.length) * 20}px`,
                      height: `${36 + (pointCount / pointsResource.length) * 20}px`,
                      fontSize: `${12 + (pointCount / pointsResource.length) * 20}px`,
                      zIndex: 1000,
                    }}
                    onClick={() => {
                      const expansionZoom = Math.min(clustersResource.supercluster.getClusterExpansionZoom(cluster.id), 20);
                      mapRef.current.setZoom(expansionZoom);
                      mapRef.current.panTo({ lat: latitude, lng: longitude });
                    }}
                  >
                    {pointCount}
                  </div>
                </Marker>
              );
            }

            return (
              <MarkerResource
                key={`crime-${cluster.properties.crimeId}`}
                intl={intl}
                lat={latitude}
                lng={longitude}
                resource={cluster.resourceData}
                resourceId={cluster.resourceData.memComId}
                thisResourceId={resourceId}
                setResourceId={setResourceId}
                openPopoverResource={openPopoverResource}
                taskStatusData={taskStatusData}
                onClickTrack={onClickTrack}
                setResourceIdChecked={setResourceIdChecked}
                resourceIdChecked={resourceIdChecked}
                setTrackingResource={setTrackingResource}
                setMainResourceMemComId={setMainResourceMemComId}
                setMainStatusTaskTitle={setMainStatusTaskTitle}
                setMainStatusTaskObject={setMainStatusTaskObject}
                setVisibleTaskStatusModal={setVisibleTaskStatusModal}
                LangCode={LangCode}
              />
            );
          })}
      </GoogleMapReact>
    </div>
  );
};
