import React from 'react';
import CollapseCustom from '../../components/collapse-custom';
import { useIntl, FormattedMessage } from 'react-intl';
import ReportItemPurchaseActionColumns from './action-columns';
import { Input, Form, Select, DatePicker, Row, Col } from 'antd';
import LabeRequireForm from '../../components/label-required-form';
import _ from 'lodash';
import Button_02 from '../../components/v2/button_02';
import Button_01 from '../../components/v2/button_01';

const { RangePicker } = DatePicker;
const { Option } = Select;

const ReportItemPurchaseAction = (props) => {
  const intl = useIntl();
  const { search, setSearch, onFilterOrder, actionColumns, refTypeList, setPage, brand, model, setIsFetch, team, issueBy } = props;

  const handleApply = () => {
    setIsFetch((event) => !event);
    setPage(1);
  };

  const handleReset = () => {
    setIsFetch((event) => !event);
    setSearch((prev) => ({
      ...prev,
      poNo: '',
      vendorOrSupplier: '',
      item: '',
      brand: [],
      model: [],
      referenceNo: '',
      team: '',
      issuedBy: '',
      createdAt: [],
    }));
    setPage(1);
  };

  const formFilter = () => {
    return (
      <Form colon={false} className="deliveryForm">
        <Row gutter={[24, 2]}>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'reportPurchaseColumnsPoNo', defaultMessage: 'PO No.' })} req={false} />
              }
            >
              <Input
                allowClear={true}
                placeholder={intl.formatMessage({
                  id: 'reportItemPurchasePHRefPoNo',
                  defaultMessage: 'Enter PO No.',
                })}
                onChange={(e) => onFilterOrder(e.target.value, 'poNo')}
                value={_.get(search, 'poNo') || undefined}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'reportPurchaseColumnsVendorOrSupplier', defaultMessage: 'Vendor or Supplier' })}
                  req={false}
                />
              }
            >
              <Input
                allowClear={true}
                placeholder={intl.formatMessage({
                  id: 'reportItemPurchasePHRefVendorOrSupplier',
                  defaultMessage: 'Enter Vendor or Supplier',
                })}
                onChange={(e) => onFilterOrder(e.target.value, 'vendorOrSupplier')}
                value={_.get(search, 'vendorOrSupplier') || undefined}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<LabeRequireForm text={intl.formatMessage({ id: 'reportPurchaseColumnsItem', defaultMessage: 'Item' })} req={false} />}
            >
              <Input
                allowClear={true}
                placeholder={intl.formatMessage({ id: 'reportItemPurchasePHRefItem', defaultMessage: 'Enter Item Code or Item Name' })}
                onChange={(e) => onFilterOrder(e.target.value, 'item')}
                value={_.get(search, 'item') || undefined}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[24, 2]}>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'reportPurchaseColumnsItemBrand', defaultMessage: 'Brand' })} req={false} />
              }
            >
              <Select
                showSearch
                allowClear={true}
                mode="multiple"
                onSearch={(value) => brand.setBrandSearch(value)}
                onChange={(value) => onFilterOrder(value, 'brand')}
                dataSource={brand.optionsBrand}
                filterOption={false}
                value={_.get(search, 'brand') || undefined}
                placeholder={intl.formatMessage({ id: 'reportItemPurchasePHRefBrand', defaultMessage: 'Select Brand' })}
                // style={{ width: '100%' }}
                notFoundContent={brand.loadingBrand ? 'Loading...' : null}
              >
                {_.map(brand.optionsBrand, (item) => (
                  <Option value={item.item_brand_id}>{item.item_brand_name}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'reportPurchaseColumnsItemModel', defaultMessage: 'Model' })} req={false} />
              }
            >
              <Select
                showSearch
                allowClear={true}
                mode="multiple"
                onSearch={(value) => model.setModelSearch(value)}
                onChange={(value) => onFilterOrder(value, 'model')}
                dataSource={model.optionsModel}
                filterOption={false}
                value={_.size(_.get(search, 'brand')) && _.get(search, 'model') ? _.get(search, 'model') : []}
                placeholder={intl.formatMessage({ id: 'reportItemPurchasePHRefModel', defaultMessage: 'Select Model' })}
                style={{ width: '100%' }}
                notFoundContent={model.loadingModel ? 'Loading...' : null}
                disabled={_.size(_.get(search, 'brand')) ? false : true}
              >
                {_.map(model.optionsModel, (item) => (
                  <Option value={item.item_model_id}>{item.item_model_name}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'reportPurchaseColumnsRefNo', defaultMessage: 'Ref No.' })} req={false} />
              }
            >
              <Input
                allowClear={true}
                placeholder={intl.formatMessage({ id: 'reportItemPurchasePHRefNo', defaultMessage: 'Enter Ref No.' })}
                onChange={(e) => onFilterOrder(e.target.value, 'referenceNo')}
                value={_.get(search, 'referenceNo') || undefined}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 2]}>
          <Col span={8}>
            <Form.Item
              label={<LabeRequireForm text={intl.formatMessage({ id: 'reportPurchaseColumnsTeam', defaultMessage: 'Team' })} req={false} />}
            >
              <Select
                showSearch
                allowClear={true}
                // mode="multiple"
                onChange={(value) => onFilterOrder(value, 'team')}
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().includes(input.toLowerCase())
                }
                value={_.get(search, 'team') || undefined}
                placeholder={intl.formatMessage({ id: 'reportItemPurchasePHRefTeam', defaultMessage: 'Select Team' })}
              >
                {_.map(team.optionsTeam, (item) => (
                  <Option key={item.name} value={item.org_id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'reportPurchaseColumnsIssuedBy', defaultMessage: 'Issued By' })}
                  req={false}
                />
              }
            >
              <Select
                showSearch
                allowClear={true}
                onChange={(value) => onFilterOrder(value, 'issuedBy')}
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().includes(input.toLowerCase())
                }
                value={_.get(search, 'issuedBy') || undefined}
                placeholder={intl.formatMessage({ id: 'reportItemPurchasePHRefIssuedBy', defaultMessage: 'Select Issued By' })}
              >
                {_.map(issueBy.optionsIssueBy, (item) => (
                  <Option value={item.fullname}>
                    {item.fullname}
                  </Option>
                ))}
              </Select>
              {/* <Input
                allowClear={true}
                placeholder={intl.formatMessage({
                  id: 'reportItemPurchasePHRefIssuedBy',
                  defaultMessage: 'Enter Issued By',
                })}
                onChange={(e) => onFilterOrder(e.target.value, 'issuedBy')}
                value={_.get(search, 'issuedBy') || undefined}
              /> */}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'reportPurchaseColumnsCreatedDate', defaultMessage: 'Created Date' })} req={false} />
              }
            >
              <RangePicker
              allowClear={true}
              placeholder={[
                intl.formatMessage({ id: 'reportItemPurchasePHRefCreatedDateFrom', defaultMessage: 'Select From Date' }),
                intl.formatMessage({ id: 'reportItemPurchasePHRefCreatedDateTo', defaultMessage: 'Select To Date' }),
              ]}
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              onChange={(value) => onFilterOrder(value, 'createdAt')}
              style={{ width: '100%' }}
              format={['DD/MM/YYYY', 'DD/MM/YYYY']}
              value={_.get(search, 'createdAt')}
            />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 2]}>
          <Col span={8}></Col>
          <Col span={8}></Col>
          <Col span={8} style={{ marginTop: '10px', textAlign: 'right' }}>
            <Form.Item label={<LabeRequireForm text={''} req={false} />}>
              <Button_01 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleReset}>
                <FormattedMessage id="btnResetFilter" defaultMessage="Reset" />
              </Button_01>
              <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="print" type="primary" btnsize="wd_df" onClick={handleApply}>
                <FormattedMessage id="btnApplyFilter" defaultMessage="Apply" />
              </Button_02>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  };

  return (
    <div style={{ marginTop: '16px' }}>
      <CollapseCustom
        label={intl.formatMessage({ id: `btnFilter`, defaultMessage: 'Filter' })}
        extra={
          <div style={{ display: 'flex' }}>
            <RangePicker
              allowClear={true}
              placeholder={[
                intl.formatMessage({ id: 'reportItemPurchasePHRefReceivedDateFrom', defaultMessage: 'Select From Date' }),
                intl.formatMessage({ id: 'reportItemPurchasePHRefReceivedDateTo', defaultMessage: 'Select To Date' }),
              ]}
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              onChange={(value) => onFilterOrder(value, 'searchDate')}
              style={{ width: '100%' }}
              format={['DD/MM/YYYY', 'DD/MM/YYYY']}
              value={_.get(search, 'searchDate')}
            />
            <ReportItemPurchaseActionColumns actionColumns={actionColumns} />
          </div>
        }
      >
        {formFilter()}
      </CollapseCustom>
    </div>
  );
};

export default ReportItemPurchaseAction;
