import { Col } from 'antd'
import React, { useCallback, useRef } from 'react'
import _ from 'lodash'
import { useJsApiLoader, GoogleMap, Marker } from '@react-google-maps/api'
import Geocode from "react-geocode";

Geocode.setApiKey(`${localStorage.getItem('GEO_APIKEY')}`);
Geocode.enableDebug();

const FeedNoteLocation = (props) => {
  const { isMemComId, isOnlyMap, item } = props
  const mapRef = useRef();
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: localStorage.getItem('GEO_APIKEY'),
    id: 'google-map-script',
  });

  const onLoad = useCallback((map) => {
    mapRef.current = map
  }, []);

  const latlng = {
    lat: parseFloat(_.get(item, 'lat')),
    lng: parseFloat(_.get(item, 'lng')),
  }

  const mapContainerStyle = {
    height: `200px`,
    maxWidth: '458px',
    width: '100%',
    position: 'relative'
  }

  if (loadError) return "Error loading maps";
  if (!isLoaded) return "Loading Maps";

  const defaultMapOption = {
    fullscreenControl: false,
    fullscreenControlOptions: false,
    streetViewControl: false,
    clickableIcons: false,
    zoomControl: false,
    gestureHandling: "cooperative",
    mapTypeControl: false,
    mapTypeControlOptions: {
      style: 1,
      position: 3,
    },
  };

  const handleClickMap = () => {
    window.open(`https://www.google.com/maps/search/?api=1&query=${_.get(latlng, 'lat')}, ${_.get(latlng, 'lng')}`, '_blank');
  }

  return (
    <Col
      span={24}
      style={isMemComId ? { display: 'flex', justifyContent: 'end', textAlign: 'right' } : undefined}
    >
      {
        isOnlyMap ? (
          <div>
            <GoogleMap
              ref={mapRef}
              id='1'
              mapContainerStyle={mapContainerStyle}
              center={latlng}
              zoom={10}
              onLoad={onLoad}
              options={defaultMapOption}
            >
              <Marker
                position={latlng}
                animation={window.google.maps.Animation.DROP}
                draggable={false}
                onClick={handleClickMap}
              />
            </GoogleMap>
            <div style={{ padding: '6px 0px', textAlign: 'left', fontSize: '12px', maxWidth: '458px' }}>{_.get(item, 'fullAddress')}</div>
          </div>

        ) : (
          <div style={{ padding: '6px 0px', color: '#707070', cursor: 'pointer', fontSize: '12px', maxWidth: '458px' }} onClick={handleClickMap}>{'- at'} {_.get(item, 'fullAddress')} {'>'}</div>
        )
      }
    </Col>
  )
}

export default FeedNoteLocation
