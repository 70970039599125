import _ from 'lodash';


const GeneratorPDF = (response) => {
  const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
  const pdfUrlObject = URL.createObjectURL(pdfBlob);
  window.open(pdfUrlObject, '_blank');
}

const UrlPDF = (response) => {
  const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
  const pdfUrlObject = URL.createObjectURL(pdfBlob);
  return pdfUrlObject
}

const ThaiNumberToText = (number) => {
  const splitDot = _.split(number, '.');
  const fullNumber = _.get(splitDot, '[0]');
  const dotNumber = _.get(splitDot, '[1]');
  const num = ['ศูนย์', 'หนึ่ง', 'สอง', 'สาม', 'สี่', 'ห้า', 'หก', 'เจ็ด', 'แปด', 'เก้า'];
  const pos = ['หน่วย', 'สิบ', 'ร้อย', 'พัน', 'หมื่น', 'แสน', 'ล้าน', 'สิบ', 'ร้อย', 'พัน', 'หมื่น', 'แสน'];
  let res = '';

  for (let index = 0; index < _.size(fullNumber); index++) {
    if (fullNumber[index] !== '0') {
      console.log('fullNumber', fullNumber[index]);
      res = res + num[parseInt(fullNumber[index])];
      res = res + pos[_.size(fullNumber) - (index + 1)];
    }
  }
  let resDot = '';
  for (let index = 0; index < _.size(dotNumber); index++) {
    if (dotNumber[index] !== '0') {
      resDot = resDot + num[parseInt(dotNumber[index])];
      resDot = resDot + pos[_.size(dotNumber) - (index + 1)];
    }
  }
  res = res.replace('หนึ่งสิบ', 'สิบ');
  res = res.replace('ศูนย์หน่วย', '');
  res = res.replace('หนึ่งหน่วย', 'เอ็ด');
  res = res.replace('หนึ่งหน่วย', 'เอ็ด');
  res = res.replace('สองสิบ', 'ยี่สิบ');
  res = res.replace('หน่วย', '');
  if (res === 'เอ็ด') {
    res = 'หนึ่ง';
  }
  resDot = resDot.replace('หนึ่งสิบ', 'สิบ');
  resDot = resDot.replace('ศูนย์หน่วย', '');
  resDot = resDot.replace('หนึ่งหน่วย', 'เอ็ด');
  resDot = resDot.replace('หนึ่งหน่วย', 'เอ็ด');
  resDot = resDot.replace('สองสิบ', 'ยี่สิบ');
  resDot = resDot.replace('หน่วย', '');
  if (resDot === 'เอ็ด') {
    resDot = 'หนึ่ง';
  }
  // if () {

  // }
  if (parseInt(dotNumber) === 0) {
    return res + 'บาทถ้วน';
  }
  if (parseInt(dotNumber) > 0) {
    return res + 'บาท' + resDot + 'สตางค์';
  }
};

export { GeneratorPDF, ThaiNumberToText, UrlPDF }
