import httpClient from '../../components/axiosClient';

const getTeam = async (memCom) => {

  const memComId = localStorage.getItem('memComId');


  try {
    const response = await httpClient.get(`/v2/teams/${memCom ? memCom : memComId}`);


    if (response.status == 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
}

export default getTeam;