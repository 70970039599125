import httpClient from '../../components/axiosClient';

const getCustomer = async (body) => {
  const comCode = localStorage.getItem('comCode');

  try {
    const response = await httpClient.post(`/v3/business-partner/manager/company/${comCode}/customer-informations/search`, body);
    if (response.status === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
}

const getCustomerContact = async (body) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v5/business-partner/manager/company/${comCode}/web/customer-contacts/search`, body);
    if (response.status === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
}

const getVendor = async (body) => {
  const comCode = localStorage.getItem('comCode');

  try {
    const response = await httpClient.post(`/v3/business-partner/manager/company/${comCode}/customer-informations/search?type=vendor`, body);
    if (response.status === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
}

const getCustomerInformation = async (customerId) => {
  const comCode = localStorage.getItem('comCode');

  try {
    const response = await httpClient.get(`/v3/business-partner/manager/company/${comCode}/customer-informations/${customerId}`);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
}

const getTabCustomer = async () => {
  const comId = localStorage.getItem('comId');
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.get(`/v5/business-partner/manager/company/${comCode}/customer-stage/stage-list?comId=${comId}`);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
}

export { getCustomer, getCustomerContact, getVendor, getCustomerInformation, getTabCustomer };