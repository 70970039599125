import httpClient from '../../components/axiosClient';

const comCode = localStorage.getItem('comCode');

const getTicketData = async (payload) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v1/ticket/manager/company/${comCode}/web/ticketpage`, payload);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return error.responase;
  }
};

const createTicket = async (payload) => {
  try {
    const response = await httpClient.post(`/v1/ticket/manager/company/${comCode}/web/ticket`, payload);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return error.responase;
  }
};

const updateTicket = async (payload, id) => {
  try {
    const response = await httpClient.put(`/v1/ticket/manager/company/${comCode}/web/ticket/${id}`, payload);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return error.responase;
  }
};

const getTicketDetail = async (id) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.get(`/v1/ticket/manager/company/${comCode}/web/ticketdetail/${id}`);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return error.responase;
  }
};

const updateStatusTicket = async (id, payload) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.put(`/v1/ticket/manager/company/${comCode}/web/ticketstatus/${id}`, payload);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return error.responase;
  }
};

const getTicketNote = async (id) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.get(`/v1/ticket/manager/company/${comCode}/web/ticketnote/${id}`);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return error.responase;
  }
};

const saveImageTicketNote = async (payload) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v1/ticket/manager/company/${comCode}/web/notefile`, payload);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return error.responase;
  }
};

const createTicketNote = async (payload) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v1/ticket/manager/company/${comCode}/web/noteticket`, payload);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return error.responase;
  }
};

const editTicketNote = async (id, payload) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.put(`/v1/ticket/manager/company/${comCode}/web/noteticket/${id}`, payload);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return error.responase;
  }
};

const deleteTicketNote = async (id) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.delete(`/v1/ticket/manager/company/${comCode}/web/noteticket/${id}`);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return error.responase;
  }
};

const saveImageTicketDescription = async (payload) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v1/ticket/manager/company/${comCode}/web/descriptionfile`, payload);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return error.responase;
  }
};

const searchReportTicket = async (payload) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v1/ticket/manager/company/${comCode}/web/repairticketpage`, payload);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return error.responase;
  }
};

const getBrandReportTicket = async (payload) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v5/item/manager/company/${comCode}/web/assetbrand/loadmore`, payload);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return error.responase;
  }
};

const getModelReportTicket = async (payload) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v5/item/manager/company/${comCode}/web/assetbrandmodel/loadmore`, payload);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return error.responase;
  }
};

const getRepairTicketData = async (payload) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v1/ticket/manager/company/${comCode}/web/repairticketpage`, payload);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return error.responase;
  }
};

const getExportTicket = async (payload) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v1/ticket/manager/company/${comCode}/web/ticket/export`, payload);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return error.responase;
  }
};


export {
  getTicketData,
  getTicketDetail,
  updateStatusTicket,
  getTicketNote,
  saveImageTicketNote,
  createTicketNote,
  editTicketNote,
  deleteTicketNote,
  createTicket,
  updateTicket,
  saveImageTicketDescription,
  searchReportTicket,
  getBrandReportTicket,
  getModelReportTicket,
  getRepairTicketData,
  getExportTicket,
};
