import React, { useState, useEffect } from 'react';
import { Card, Row, Col } from 'antd';
import TravelAssignee from './travel-assignee';
import TravelReport from './travel-report';
import getTravelExpenses from '../../../controllers/travel-expenses/get-all-data-travel-expenses';
import _ from 'lodash';
import moment from 'moment';
import updateTravelExpenses from '../../../controllers/travel-expenses/post-update-status-travel-expense';
import { successNotification, errorNotification } from '../../../components/v2/notification';
import exportTaskExcel from '../../../controllers/task/post-task-export-excel';
import { useIntl } from 'react-intl';

let newMemComId = [];


export default () => {

  //--------------- Data State ---------------
  const intl = useIntl()
  const [assigneeData, setAssigneeData] = useState([])
  const [tabStatusData, setTabStatusData] = useState([])
  const [tableData, setTableData] = useState([])
  const [teamData, setTeamData] = useState([])

  //------------- Normal State -------------
  const today = new Date();
  const dateFormat = 'ddd, MMM DD YYYY';
  const [tabChange, setTabChange] = useState('waiting')
  const [fliterByColumn, setFliterByColumn] = useState('all');
  const [orgTeamIdArr, setOrgTeamIdArr] = useState([]);
  const [fieldChang, setFieldChang] = useState('');
  const [nameStatus, setNameStatus] = useState()
  const [nameStatusCode, setNameStatusCode] = useState()
  const [fromDate, setFromDate] = useState(moment(today).format("YYYY-MM-DD"));
  const [toDate, setToDate] = useState(moment(today).format("YYYY-MM-DD"));
  const [defaultDate, setDefaultDate] = useState([moment(new Date(), dateFormat), moment(new Date(), dateFormat)])
  const [paginationPage, setPaginationPage] = useState(1);
  const [selectKeysTable, setSelectKeysTable] = useState([])
  const [selectAllRow, setSelectAllRow] = useState(false)
  const [paginationSize, setPaginationSize] = useState(10);
  const [orgId, setOrgId] = useState(undefined);
  const [checkedAll, setCheckedAll] = useState(["all"]);
  const [defaultChecked, setDefaultChecked] = useState(true);
  const [memberIdStr, setMemberIdStr] = useState(['']);

  const [triggerAPI, setTriggerAPI] = useState(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [idMember, setIdMember] = useState(['all']);
  const [tabActive, setTabActive] = useState('waiting')
  const [tableLoding, setTableLoding] = useState(false)
  const [pageLoading, setPageLoading] = useState(false)
  const [textValue, setTextValue] = useState()
  const type = 'menuType=workforce';
  

  useEffect(() => {

    const getData = async () => {
      setTableLoding(true)

      const response = await getTravelExpenses(tabChange, fromDate, toDate, fieldChang, fliterByColumn, paginationPage, paginationSize, orgId, checkedAll, type);
      if (_.size(response) !== 0) {
        newMemComId = [];
        setAssigneeData(response.data.assignees)
        response.data.assignees.map((e) => e.selected === true ? newMemComId.push(e.memComId) : null);

        // fncFilterMemComId(response.data.assignees);
        setTabStatusData(response.data.statuses)
        setTableData(response.data.table)
        setTeamData(response.data.teams)
        setTableLoding(false)
      }
    }

    getData();
  }, [tabChange, fromDate, toDate, fieldChang, fliterByColumn, paginationPage, paginationSize, orgId, checkedAll, triggerAPI, tabActive])


  const handleSelectOrg = (e) => {
    setOrgId(e);
    setDefaultChecked(true)
    setCheckedAll(['all'])

  }


  const onSelectMember = (memComId, checked) => {

    if (memComId) {
      const checkMemComId = newMemComId.includes(memComId)
      if (checkMemComId === false) {
        newMemComId.push(...[memComId]);
      } else {
        newMemComId = _.remove(newMemComId, el => {
          return el !== memComId
        });
      }
    }

    const filterKeys = assigneeData.filter((el) => newMemComId.includes(el.memComId));

    const memComIdArr = filterKeys.map((e) => { return e.memComId })

    setCheckedAll(memComIdArr);

    if (assigneeData.length !== memComIdArr.length) {
      setDefaultChecked(false)
    } else {
      setDefaultChecked(true)

    }
  }



  const handleClickStatus = async (key) => {
    const limitRow = _.get(tableData, 'totalNoLimit')
    setPageLoading(true)
    if (selectAllRow === true) {
      const updateAll = true
      const listRow = undefined
      const response = await updateTravelExpenses(tabChange, fromDate, toDate, fieldChang, fliterByColumn, paginationPage, limitRow, orgId, key, updateAll, listRow, checkedAll,type);
      if (_.size(response) !== 0) {
        successNotification(response.status.message)
        setTriggerAPI(current => !current)
        setSelectedRowKeys([])
        setSelectKeysTable([])
        setNameStatus()
        setNameStatusCode()
        setSelectAllRow(false)
        setTimeout(() => {
          setPageLoading(false)
        }, 1000);
      }

    } else {
      const updateAll = false
      const listRow = selectKeysTable && selectKeysTable.map(item => {
        return {
          taskId: item.taskId,
          memComId: item.memComId,
        }
      })
      const response = await updateTravelExpenses(tabChange, fromDate, toDate, fieldChang, fliterByColumn, paginationPage, limitRow, orgId, key, updateAll, listRow, checkedAll ,type);
      if (_.size(response) !== 0) {
        successNotification(response.status.message)
        setTriggerAPI(current => !current)
        setSelectedRowKeys([])
        setSelectKeysTable([])
        setNameStatus()
        setNameStatusCode()
        setSelectAllRow(false)
        setTimeout(() => {
          setPageLoading(false)
        }, 1000);
      }
    }
  }

  const handleExportExcel = async (rowData) => {
    if (_.size(rowData) !== 0) {
      const viewFormate = [
        { colName: intl.formatMessage({ id: 'travelExpenseColumnsTaskNo', defaultMessage: 'Task No' }), sequence: 1, colCode: "taskNo" },
        { colName: intl.formatMessage({ id: 'travelExpenseColumnsCustomer', defaultMessage: 'Customer' }), sequence: 2, colCode: "customer" },
        { colName: intl.formatMessage({ id: 'travelExpenseColumnsAssignee', defaultMessage: 'Assignee' }), sequence: 3, colCode: "assignee" },
        { colName: intl.formatMessage({ id: 'travelExpenseColumnsTaskType', defaultMessage: 'Task Type' }), sequence: 4, colCode: "taskType" },
        { colName: intl.formatMessage({ id: 'travelExpenseColumnsCompletedDate', defaultMessage: 'Completed Date' }), sequence: 5, colCode: "completedDate" }
      ]
      const response = await exportTaskExcel(tabChange, fromDate, toDate, fieldChang, fliterByColumn, paginationPage, paginationSize, orgId, checkedAll, viewFormate)
      if (_.size(response) !== 0) {
        window.open(response.data.url);
        successNotification(response.status.message);
      } else {
        errorNotification(response.status.message);
      }
    }
  }

  const checkAllAssignee = (e) => {
    if (e.target.checked === false) {
      setCheckedAll([])
      setDefaultChecked(false)
      newMemComId = []
    } else {
      setCheckedAll(['all'])
      setDefaultChecked(true)
      newMemComId = []
    }
  }

  return (
    <div>
      <Row gutter={[16, 16]}>
        <Col span={6}>
          <TravelAssignee
            value={{
              assigneeData,
              teamData,
              checkedAll,
              defaultChecked,
              tabStatusData,
              memberIdStr,
            }}
            fnc={{
              handleSelectOrg,
              setCheckedAll,
              setDefaultChecked,
              setMemberIdStr,
              onSelectMember,
              checkAllAssignee
            }}
          />
        </Col>
        <Col span={18}>
          <TravelReport
            value={{
              tabStatusData,
              tableData,
              fliterByColumn,
              orgTeamIdArr,
              fieldChang,
              nameStatus,
              fromDate,
              toDate,
              selectKeysTable,
              paginationPage,
              paginationSize,
              nameStatusCode,
              selectedRowKeys,
              defaultDate,
              tabChange,
              tabActive,
              tableLoding,
              pageLoading,
              textValue,
            }}
            fnc={{
              setTabChange,
              setFliterByColumn,
              setOrgTeamIdArr,
              setFieldChang,
              setNameStatus,
              setFromDate,
              setToDate,
              setSelectKeysTable,
              setPaginationPage,
              setPaginationSize,
              handleClickStatus,
              setNameStatusCode,
              setSelectedRowKeys,
              setSelectAllRow,
              setDefaultDate,
              handleExportExcel,
              setTabActive,
              setTextValue,
            }}
          />
        </Col>
      </Row>
    </div>
  )
}