import React from 'react';
import { Icon } from 'antd';

export default ((props) => {
  const { action, postData, handleAddress, handleCloseAddress } = props;

  return (
    <div className='post-address' >
      {console.log('postData',postData)}
      <p
        className='post-card-text-11'
        onClick={() => handleAddress({
          addressLat: postData.addressLat,
          addressLng: postData.addressLng,
          addressName: postData.addressName,
        })}
      >
        - at {`${postData.addressName} >`}
      </p>
      {(action === 'edit') || (action === 'create') ? (
        <div className='post-card-close' onClick={() => handleCloseAddress()}>
          <Icon className='post-card-icon-close' type='close-circle' />
        </div>
      ) : null}
    </div>
  )
})