import React, { useState, useContext, useMemo, useEffect } from 'react';
import { TaskDetail } from '../../task-detail-context';
import { EditTruckWeight, subTaskApi, getParcel, updateParcel } from '../../../../../../controllers/task/task-detail';
import '../css/subtask.css';
import { Collapse, Avatar, Icon, Col, Row, Divider, Upload, Modal, notification, Badge, Card, Input, InputNumber, Select, Tag } from 'antd';
import 'antd/dist/antd.css';
import style from '../css/subtask.css';
import _ from 'lodash';
import ImageGallery from 'react-image-gallery';
import styled from 'styled-components';
import downloadImage from '../../../../../../controllers/downLoad-image';
import { useIntl, FormattedMessage } from 'react-intl';
import ModalAddEngagment from '../../../../../../components/modal-add-post-engagement';
import ModalEditEngagment from '../../../../../../components/modal-edit-post-engagement';
import ModalViewEngagment from '../../../../../../components/modal-view-post/index';
import ReactBnbGallery from 'react-bnb-gallery';
import NoDataBackgroundModal from '../../../../../no-data-page/modal-no-data';
import iconnumeric from '../../../../../../assets/svg/numeric.svg';
import { numberFormatter, numberFormatterCheckDot, numberParser } from '../../../../../../component-function/fnc-inputnumber-currency';
import { handleKeyDownDecimal2Fixed, handleKeyDownNumber } from '../../../../../../component-function/fnc-number';
import { Round } from '../../../../../../component-function/lodash';
import { errorNotification, successNotification } from '../../../../../v2/notification';
import { PageSettings } from '../../../../../../config/page-settings';
import ParcelModal from '../../../../../modal-parcel';
import ParcelReOrderModal from '../../../../../modal-parcel-re-order';
import LabeRequire from '../../../../../v2/label-require';

const { Panel } = Collapse;
const { Option } = Select;
const StyleModal = styled(Modal)`
  .ant-modal-content {
    background-color: transparent;
    box-shadow: none;
  }

  .image-gallery-slide .image-gallery-image {
    max-width: 400px;
    max-height: 500px;
  }

  .ant-modal-close {
    color: #ffffff;
    right: 667px;
  }

  .ant-modal-close-x {
    font-size: 24px;
  }

  .image-gallery-index {
    margin: 0px 20px 0px 20px;
  }

  .image-gallery-right-nav {
    right: 30%;
  }

  .image-gallery-left-nav {
    left: 30%;
  }
`;

export default () => {
  const intl = useIntl();

  const app = useContext(PageSettings);
  const { checkPermissionAction } = app;
  const langValue = localStorage.getItem('langValue');

  const { stateTask, setStateTask, fncStateTask } = useContext(TaskDetail);

  const taskId = stateTask && stateTask.taskId ? _.get(stateTask, 'taskId') : undefined;

  const tabKey = stateTask && stateTask.tabKey ? _.get(stateTask, 'tabKey') : undefined;

  const assigneeDetail = stateTask && _.get(stateTask, 'assignee') ? _.get(stateTask, 'assignee') : undefined;

  const taskNo =
    stateTask && stateTask.viewInformationData.information ? _.get(stateTask.viewInformationData.information, 'taskNo') : undefined;

  const taskName =
    stateTask && stateTask.viewInformationData.information ? _.get(stateTask.viewInformationData.information, 'taskName') : undefined;

  const { statusView } = stateTask;
  const { setTriggerInformation, setItemUpdateParcel } = setStateTask;
  // const taskTypeName = stateTask && stateTask.taskDetailsData
  // ? _.lowerCase(_.get(stateTask, 'checkTaskType'))
  // : undefined;

  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');

  const [stateModal, setStateModal] = useState(false);
  const [modalView, setModalView] = useState([]);

  const [startIndexGallery, setStartIndexGallery] = useState(0);

  const [subtask, setSubtask] = useState([]);

  const [triggerPost, setTriggerPost] = useState(false);
  const [visibleAddPost, setVisibleAddPost] = useState(false);
  const [visibleEditPost, setVisibleEditPost] = useState(false);
  const [visibleViewPost, setVisibleViewPost] = useState(false);
  const [editPostData, setEditPostData] = useState();
  const [subTaskWfId, setSubTaskWfId] = useState();
  // const [subTaskId, setSubTaskId] = useState();

  const [openGallery, setOpenGallery] = useState(false);
  const [galleryImg, setGalleryImg] = useState([]);
  const [numberImage, setNumberImage] = useState(0);
  // const [subTaskId, setSubTaskId] = useState();

  const [subTaskWFIdChange, setSubTaskWFIdChange] = useState();

  const [openParcel, setOpenParcel] = useState(false);
  const [openParcelReOrder, setOpenParcelReOrder] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [checkedResend, setCheckedResend] = useState(false);

  console.log('subtaskxxxxx', subtask, tabKey);

  useEffect(() => {
    if (tabKey === 'subTask' && taskId) {
      const callApi = async () => {
        const response = await subTaskApi(taskId);
        setSubtask(_.get(response.data, 'subtask'));
      };
      callApi();
    }
  }, [taskId, triggerPost, tabKey]);

  useEffect(() => {
    setStateTask.setActiveKey([]);
  }, [taskId]);

  const SelectStatus = styled(Select)`
    width: 'auto';
    .ant-select-selection--single {
      border-radius: 20px !important;
      height: 24px;
      color: #fff;
      width: fit-content;
      font-size: 13px;
      background-color: ${(props) => props.statusColor || _.get(stateTask, 'viewInformationData.information.statusColor')};
      border: 1px solid ${(props) => props.statusColor || _.get(stateTask, 'viewInformationData.information.statusColor')};
    }
    .ant-select-selection-selected-value {
      margin-right: 5px;
      margin-top: 0px;
    }
    .ant-select-arrow .ant-select-arrow-icon svg {
      fill: #fff;
    }
    .ant-select-focused .ant-select-selection,
    .ant-select-selection:focus,
    .ant-select-selection:active,
    .ant-select-selection:hover,
    .ant-select-open .ant-select-selection {
      border-color: ${(props) => props.statusColor || _.get(stateTask, 'viewInformationData.information.statusColor')}; !important;
      box-shadow: none !important;
    }
  `;

  const resourceSelect = assigneeDetail && assigneeDetail.filter((item) => stateTask.keys.includes(item.memComId));
  const selectResKeys = resourceSelect.map((a) => a.memComId);

  console.log('resourceSelect', resourceSelect);
  console.log('selectResKeys', selectResKeys);
  console.log('taskNo' ,taskNo)
  console.log('stateTask' ,stateTask)
  console.log("subtask.length" , subtask.length)
  console.log("subtask" , subtask)

  const handlePreview = async (files, file) => {
    const found = files && files.findIndex((element) => element.uid === file.uid);
    setModalView(files);
    setStartIndexGallery(found);
    setStateModal(!stateModal);
  };

  const handleCancel = () => {
    setStateModal(false);
  };

  const handleDownload = async (val, taskNo, totalAssigneeFile, typePhoto, subtask) => {
    if (val) {
      try {
        const response = await downloadImage(val, taskNo, typePhoto);
        await openNotification(response, totalAssigneeFile);
        if (response) {
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${taskNo}_${subtask}.zip`);
          document.body.appendChild(link);
          link.click();
          link.remove();
        }
      } catch (error) {}
    }
  };

  const key = 'download';
  const openNotification = (response, totalAssigneeFile) => {
    notification.open({
      key,
      message: `${intl.formatMessage({ id: 'notiDownloading', defaultMessage: 'Downloading' })} ${totalAssigneeFile} ${
        totalAssigneeFile > 1
          ? `${intl.formatMessage({ id: 'notiPhotos', defaultMessage: 'photos' })}`
          : `${intl.formatMessage({ id: 'notiPhoto', defaultMessage: 'photo' })}`
      }`,
      icon: <Icon type="loading" />,
    });
    if (response) {
      setTimeout(() => {
        notification.open({
          key,
          message: `${intl.formatMessage({ id: 'notiPhotohas', defaultMessage: 'Photo has' })} ${totalAssigneeFile} ${intl.formatMessage({
            id: 'notiDownloaded',
            defaultMessage: 'downloaded',
          })}`,
          icon: <Icon type="check-circle" style={{ color: '#52c41a' }} />,
        });
      }, 1000);
    }
  };

  const handleDownloadNote = async (val, taskNo, totalAssigneeFile, typePhoto, subtask, subTaskWf) => {
    if (val) {
      try {
        const response = await downloadImage(val, taskNo, typePhoto, subTaskWf);
        await openNotificationNote(response, totalAssigneeFile);
        if (response) {
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${taskNo}_${subtask}.zip`);
          document.body.appendChild(link);
          link.click();
          link.remove();
        }
      } catch (error) {}
    }
  };

  const openNotificationNote = (response) => {
    notification.open({
      key,
      message: intl.formatMessage({ id: 'subtaskNoteDownloading', defaultMessage: 'Downloding Files' }),
      icon: <Icon type="loading" />,
    });
    if (response) {
      setTimeout(() => {
        notification.open({
          key,
          message: intl.formatMessage({ id: 'subtaskNoteDownloaded', defaultMessage: 'File has downloaded' }),
          icon: <Icon type="check-circle" style={{ color: '#52c41a' }} />,
        });
      }, 1000);
    }
  };

  const HandlerModal = (item) => {
    return (
      <div>
        <StyleModal visible={stateModal} onCancel={handleCancel} footer={null} width={'100%'}>
          <ImageGallery
            className={style.imagegallery}
            items={modalView}
            showPlayButton={false}
            showIndex={true}
            startIndex={startIndexGallery}
            showFullscreenButton={false}
          />
        </StyleModal>
      </div>
    );
  };

  const handlePreviewImage = (data, itemData, items) => {
    const mapPhoto =
      data && _.size(data) !== 0
        ? data.map((item, index) => {
            return {
              photo: _.get(item, 'url'),
              number: index,
              caption: '',
              subcaption: `Photo By ${_.get(items, 'fullname')}`,
              thumbnail: _.get(item, 'url'),
            };
          })
        : [];

    setGalleryImg(mapPhoto);
    setOpenGallery(true);
    setNumberImage(itemData.uid);
  };

  const callback = (val) => {
    setStateTask.setActiveKey(val);

    let lastElement = val.slice(-1);
    setSubTaskWFIdChange(_.get(lastElement, [0]));
  };

  const TitleName = () => (
    <div>
      <div style={{ textAlign: 'left' }}>
        <span className="assignee-details-title">{taskNo}</span>
        <Badge color="#1D3557" className="beetwen-badge-dot" />
        <span className="assignee-details-title">{taskName}</span>
      </div>
    </div>
  );

  const headerNoExpand = (item, selectResKeys, assigneeDetail, taskDetailId, taskNo, subtaskCode, subTaskWf, subTaskWFIdChange) => {
    const assigneeRender = item.assignee.filter(
      (e) =>
        selectResKeys.includes(e.memComId) ||
        _.findIndex(assigneeDetail, (obj) => {
          return obj.memComId === e.memComId;
        }) === -1
    );
    let totalAssignee = assigneeRender.reduce((accumulator, item) => {
      return accumulator + _.get(item, 'subtaskDetail[0].totalFileView');
    }, 0);

    console.log('headerassigneeRender', assigneeRender, item.assignee);

    return (
      <Row className="header-collapse">
        <Col span={7} className="subtask-header-icon-text">
          <img src={item.icon} style={{ width: '30px', height: '18px', paddingRight: '10px' }}></img>
          {/* <LabeRequire text={item.subtaskName} req={true} /> */}
          <span>
            {item.subtaskName ? item.subtaskName : ''}&nbsp;
            {item.mandatory === 1 ? <span style={{ fontSize: '13px', color: '#ff0000' }}>*</span> : ''}
          </span>
          {/* <span className="text-space-header">{item.subtaskName}</span> */}
        </Col>
        <Col span={17}>
          <div>
            <Col span={assigneeRender.length === 0 && subtaskCode === 'note_task' ? 6 : 5} style={{ display: 'flex' }}>
              {assigneeRender.length !== 0 ? (
                subtaskCode === 'note_task' ? (
                  <p className="text-space-by">{intl.formatMessage({ id: 'subtaskNoteNotedby', defaultMessage: 'Noted by' })}</p>
                ) : (
                  <p className="text-space-by">{intl.formatMessage({ id: 'subtaskNoteDoneby', defaultMessage: 'Done by' })}</p>
                )
              ) : assigneeRender.length === 0 && subtaskCode === 'note_task' ? (
                <p
                  className="text-space-by button-note-sub-task"
                  onClick={() => {
                    setVisibleAddPost(true);
                    setSubTaskWfId(subTaskWFIdChange);
                  }}
                >
                  {' '}
                  <FormattedMessage id="subtaskNoteAddNote" defaultMessage="Add Note" />
                </p>
              ) : (
                ''
              )}
              &nbsp;
            </Col>
            <Col span={assigneeRender.length === 0 && subtaskCode === 'note_task' ? 17 : 18} style={{ display: 'flex' }}>
              <div className="text-space-name">
                {selectResKeys &&
                  assigneeRender.map((res, index) => (
                    <span className="text-space">
                      {(index ? ', ' : '') + res.fullname}
                      {item && item.subtaskCode === 'take_photo' && item.assignee.length !== 0
                        ? `(${_.get(res, 'subtaskDetail[0].totalFileView')})`
                        : null}
                      {item && item.subtaskCode === 'take_photo_solar' && item.assignee.length !== 0
                        ? `(${_.get(res, 'subtaskDetail[0].totalFileView')})`
                        : null}
                    </span>
                  ))}
                &nbsp;
              </div>
              {item && item.subtaskCode === 'take_photo' && assigneeRender.length !== 0 ? (
                <Icon
                  type="download"
                  className="icon-download-subtask"
                  style={{ color: '#1D3557', marginTop: '2px', marginRight: '8px' }}
                  onClick={() => handleDownload(taskDetailId, taskNo, totalAssignee, 'take_photo', item.subtaskName)}
                />
              ) : (
                undefined
              )}
              {item && item.subtaskCode === 'take_photo_solar' && assigneeRender.length !== 0 ? (
                <Icon
                  type="download"
                  className="icon-download-subtask"
                  style={{ color: '#1D3557', marginTop: '2px', marginRight: '8px' }}
                  onClick={() => handleDownload(taskDetailId, taskNo, totalAssignee, 'take_photo_solar', 'Take_Photo_Solar')}
                />
              ) : (
                undefined
              )}
              {item && item.subtaskCode === 'note_task' && assigneeRender.length !== 0 ? (
                <Icon
                  type="download"
                  className="icon-download-subtask"
                  style={{ color: '#1D3557', marginTop: '2px', marginRight: '8px' }}
                  onClick={() => handleDownloadNote(taskDetailId, taskNo, totalAssignee, 'note_task', item.subtaskName, subTaskWf)}
                />
              ) : (
                undefined
              )}
            </Col>
          </div>
          {item.assignee.length > 3 ? <Col span={1}> ({item.assignee.length})&nbsp; </Col> : null}
        </Col>
      </Row>
    );
  };

  const StartTask = useMemo(() => (props) => {
    const { sequence, assignee } = props.item;
    const { resourceSelectNew, resourceSelectObject } = props;

    const assigneeRender = assignee.filter(
      (e) =>
        resourceSelectNew.includes(e.memComId) ||
        _.findIndex(resourceSelectObject, (obj) => {
          return obj.memComId === e.memComId;
        }) === -1
    );

    return (
      resourceSelectNew &&
      assigneeRender.map((item, i) => (
        <Row key={item.memComId}>
          <Col span={10} offset={6}>
            {i ? <div className="divider-style" /> : ''}
            <Row className="data-list-css">
              <Col span={3}>
                <Avatar src={item.pathImage} size="large" />
              </Col>
              {item.subtaskDetail.map((item, index) => (
                <Col span={21}>
                  <div className="text-space-address">
                    <Icon type="environment" className="icon-space" style={{ color: '#b2b2b2' }} />
                    <span>{item.address ? item.address : '-'}</span>
                  </div>
                  <div className="text-space">
                    <Icon type="calendar" className="icon-space" style={{ color: '#b2b2b2' }} />
                    <span>{item.updatedAt ? item.updatedAt : '-'}</span>
                  </div>
                  <div className="text-space">
                    <Icon type="message" className="icon-space" style={{ color: '#b2b2b2' }} />
                    <span>{item.comment ? item.comment : '-'}</span>
                  </div>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      ))
    );
  });

  const TruckWeight = useMemo(() => (props) => {
    console.log('TruckWeight', props);
    const { sequence, assignee, icon, version } = props.item;
    const { resourceSelectNew, resourceSelectObject } = props;

    const [editPrice, setEditPrice] = useState(false);
    const [dataPrice, setDataPrice] = useState();

    const assigneeRender = assignee.filter(
      (e) =>
        resourceSelectNew.includes(e.memComId) ||
        _.findIndex(resourceSelectObject, (obj) => {
          return obj.memComId === e.memComId;
        }) === -1
    );

    const onChangeTruckWeight = (value, item) => {
      const data = { ...item, newCurrentNumber: value };
      setDataPrice(data);
    };

    const handleEditPrice = (item) => {
      setEditPrice(true);
    };

    const handleSave = async (item) => {
      const body = {
        id: _.get(dataPrice, '_id'),
        subtaskCode: _.get(dataPrice, 'subtaskCode'),
        currentNumber: _.toNumber(_.get(dataPrice, 'newCurrentNumber')) || 0,
      };

      const response = await EditTruckWeight(body);
      // console.log('EditTruckWeight', response)

      setEditPrice(false);
      setTriggerPost((current) => !current);

      if (_.get(response, 'data.status') === 200) {
        successNotification(response.data.message);
      }
    };

    console.log('assigneeRender', assigneeRender);

    return (
      resourceSelectNew &&
      assigneeRender.map((item, i) => (
        <Row key={item.memComId}>
          <Col span={10} offset={6}>
            {i ? <div className="divider-style" /> : ''}
            <Row className="data-list-css">
              <Col span={3}>
                <Avatar src={item.pathImage} size="large" />
              </Col>
              {item.subtaskDetail.map((item, index) => (
                <Col span={21}>
                  <div className="text-space">
                    <Icon type="calendar" className="icon-space" style={{ color: '#b2b2b2' }} height={30} width={30} />
                    {/* <Icon type="environment" className="icon-space" style={{ color: '#b2b2b2' }} /> */}
                    <span>{item.updatedAt ? item.updatedAt : '-'}</span>
                  </div>
                  <div className="text-space" style={{ display: 'flex', alignItems: 'center' }}>
                    {/* <iconnumeric fill={{color: 'rgb(178, 178, 178)'}} /> */}
                    <img
                      src={iconnumeric}
                      fill={{ color: 'rgb(178, 178, 178)' }}
                      style={{ width: '30px', height: '18px', paddingRight: '10px' }}
                    ></img>

                    {/* <span>{item.updatedAt ? item.updatedAt : '-'}</span> */}

                    {editPrice ? (
                      <>
                        <span>
                          <Input
                            onChange={(e) => onChangeTruckWeight(e.target.value, item)}
                            defaultValue={Round(item.currentNumber)}
                            onKeyDown={version === 1 ? handleKeyDownNumber : handleKeyDownDecimal2Fixed}
                            suffix="kg"
                          />
                        </span>

                        <span style={{ paddingLeft: '10px', cursor: 'pointer' }} onClick={() => handleSave(item)}>
                          <Icon type="check" style={{ color: '#1890FF', fontSize: '16px' }} />
                        </span>

                        <span style={{ paddingLeft: '10px', cursor: 'pointer' }} onClick={() => setEditPrice(false)}>
                          <Icon type="close" style={{ fontSize: '16px' }} />
                        </span>
                      </>
                    ) : (
                      <>
                        <span>{item.currentNumber ? <>{numberFormatterCheckDot(item.currentNumber)} kg</> : '-'}</span>
                        {checkPermissionAction('P5PG1C9', 'P5PG1C9A2') ? (
                          <span style={{ paddingLeft: '10px', cursor: 'pointer' }} onClick={() => handleEditPrice(item)}>
                            <Icon type="edit" style={{ color: '#1890FF', fontSize: '16px' }} />
                          </span>
                        ) : null}
                      </>
                    )}
                  </div>
                  <div className="text-space">
                    <Icon type="message" className="icon-space" style={{ color: '#b2b2b2' }} />
                    <span>{item.comment ? item.comment : '-'}</span>
                  </div>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      ))
    );
  });

  const CheckIn = useMemo(() => (props) => {
    const { sequence, assignee } = props.item;
    const { resourceSelectNew, resourceSelectObject } = props;

    const assigneeRender = assignee.filter(
      (e) =>
        resourceSelectNew.includes(e.memComId) ||
        _.findIndex(resourceSelectObject, (obj) => {
          return obj.memComId === e.memComId;
        }) === -1
    );

    return (
      resourceSelectNew &&
      assigneeRender.map((item, i) => (
        <Row key={item.memComId}>
          <Col span={10} offset={6}>
            {i ? <div className="divider-style" /> : ''}
            <Row className="data-list-css">
              <Col span={3}>
                <Avatar src={item.pathImage} size="large" />
              </Col>
              {item.subtaskDetail.map((item, index) => (
                <Col span={21}>
                  <div className="text-space-address">
                    <Icon type="environment" className="icon-space" style={{ color: '#b2b2b2' }} />
                    <span>{item.address ? item.address : '-'}</span>
                  </div>
                  <div className="text-space">
                    <Icon type="calendar" className="icon-space" style={{ color: '#b2b2b2' }} />
                    <span>{item.updatedAt ? item.updatedAt : '-'}</span>
                  </div>
                  <div className="text-space">
                    <Icon type="message" className="icon-space" style={{ color: '#b2b2b2' }} />
                    <span>{item.comment ? item.comment : '-'}</span>
                  </div>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      ))
    );
  });

  const PickupCheckIn = useMemo(() => (props) => {
    const { sequence, assignee } = props.item;
    const { resourceSelectNew, resourceSelectObject } = props;

    const assigneeRender = assignee.filter(
      (e) =>
        resourceSelectNew.includes(e.memComId) ||
        _.findIndex(resourceSelectObject, (obj) => {
          return obj.memComId === e.memComId;
        }) === -1
    );

    return (
      resourceSelectNew &&
      assigneeRender.map((item, i) => (
        <Row key={item.memComId}>
          <Col span={10} offset={6}>
            {i ? <div className="divider-style" /> : ''}
            <Row className="data-list-css">
              <Col span={3}>
                <Avatar src={item.pathImage} size="large" />
              </Col>
              {item.subtaskDetail.map((item, index) => (
                <Col span={21}>
                  <div className="text-space-address">
                    <Icon type="environment" className="icon-space" style={{ color: '#b2b2b2' }} />
                    <span>{item.address ? item.address : '-'}</span>
                  </div>
                  <div className="text-space">
                    <Icon type="calendar" className="icon-space" style={{ color: '#b2b2b2' }} />
                    <span>{item.updatedAt ? item.updatedAt : '-'}</span>
                  </div>
                  <div className="text-space">
                    <span className="icon-space">Reason :</span>&nbsp;
                    <span>{item.reason ? item.reason : '-'}</span>
                  </div>
                  <div className="text-space">
                    <Icon type="message" className="icon-space" style={{ color: '#b2b2b2' }} />
                    <span>{item.comment ? item.comment : '-'}</span>
                  </div>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      ))
    );
  });

  const TakePhoto = useMemo(() => (props) => {
    const { sequence, assignee } = props.item;
    const { resourceSelectNew, resourceSelectObject } = props;

    const assigneeRender = assignee.filter(
      (e) =>
        resourceSelectNew.includes(e.memComId) ||
        _.findIndex(resourceSelectObject, (obj) => {
          return obj.memComId === e.memComId;
        }) === -1
    );

    const assigneeFiles = assigneeRender.map((item) => {
      return item;
    });

    return (
      resourceSelectNew &&
      assigneeRender.map((items, i) => (
        <Row key={items.memComId}>
          <Col span={10} offset={6}>
            {i ? <div className="divider-style" /> : ''}
            <Row className="data-list-photo-css">
              <Col span={3}>
                {' '}
                <Avatar src={items.pathImage} size="large" />{' '}
              </Col>
              <Col span={21}>
                <div className="subtask-preview-div-upload">
                  {items.subtaskDetail.map((item, index) => {
                    return (
                      <div style={{ width: 'fit-content' }}>
                        <Upload
                          className="subtask-preview-upload"
                          listType="picture-card"
                          fileList={item.files}
                          onPreview={(files) => handlePreviewImage(item.files || [], files, items)}
                          showUploadList={{
                            showRemoveIcon: false,
                          }}
                        />
                        <ReactBnbGallery
                          show={openGallery}
                          photos={galleryImg}
                          onClose={() => setOpenGallery(false)}
                          activePhotoIndex={numberImage}
                        />
                      </div>
                    );
                  })}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      ))
    );
  });

  const TakePhotoSolar = useMemo(() => (props) => {
    const { sequence, assignee } = props.item;
    const { resourceSelectNew, resourceSelectObject } = props;

    const assigneeRender = assignee.filter(
      (e) =>
        resourceSelectNew.includes(e.memComId) ||
        _.findIndex(resourceSelectObject, (obj) => {
          return obj.memComId === e.memComId;
        }) === -1
    );

    const assigneeFiles = assigneeRender.map((item) => {
      return item;
    });

    return (
      resourceSelectNew &&
      assigneeRender.map((items, i) => (
        <Row key={items.memComId}>
          <Col span={10} offset={6}>
            {i ? <div className="divider-style" /> : ''}
            <Row className="data-list-photo-css">
              <Col span={3}>
                {' '}
                <Avatar src={items.pathImage} size="large" />{' '}
              </Col>
              <Col span={21}>
                <div className="subtask-preview-div-upload">
                  {items.subtaskDetail.map((item, index) => {
                    return (
                      <div style={{ width: 'fit-content' }}>
                        <Upload
                          className="subtask-preview-upload"
                          listType="picture-card"
                          fileList={item.files}
                          onPreview={(files) => handlePreviewImage(item.files || [], files, items)}
                          showUploadList={{
                            showRemoveIcon: false,
                          }}
                        />
                        <ReactBnbGallery
                          show={openGallery}
                          photos={galleryImg}
                          onClose={() => setOpenGallery(false)}
                          activePhotoIndex={numberImage}
                        />
                      </div>
                    );
                  })}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      ))
    );
  });

  const POD = useMemo(() => (props) => {
    const { sequence, assignee } = props.item;
    const { resourceSelectNew, resourceSelectObject } = props;

    const assigneeRender = assignee.filter(
      (e) =>
        resourceSelectNew.includes(e.memComId) ||
        _.findIndex(resourceSelectObject, (obj) => {
          return obj.memComId === e.memComId;
        }) === -1
    );

    return (
      resourceSelectNew &&
      assigneeRender.map((items, i) => (
        <Row key={items.memComId}>
          <Col span={10} offset={6}>
            {i ? <div className="divider-style" /> : ''}
            <Row className="data-list-css">
              <Col span={3}>
                {' '}
                <Avatar src={items.pathImage} size="large" />{' '}
              </Col>
              {items.subtaskDetail.map((item, index) => {
                let newPathPOD = [];
                const path = {
                  uid: index,
                  url: item.files.path,
                  original: item.files.path,
                  thumbnail: item.files.path,
                };
                newPathPOD.push(path);
                return (
                  <Col span={21}>
                    <div className="text-space">
                      <Icon type="user" className="icon-space" style={{ color: '#b2b2b2' }} />
                      <span>
                        {item.role ? item.role : '-'} / {item.typeName ? item.typeName : '-'}
                      </span>
                    </div>
                    <div className="text-space">
                      <Icon type="calendar" className="icon-space" style={{ color: '#b2b2b2' }} />
                      <span>{item.updatedAt}</span>
                    </div>
                    <div>
                      <Upload
                        listType="picture-card"
                        fileList={newPathPOD}
                        onPreview={(files) => handlePreviewImage(newPathPOD || [], files, items)}
                        showUploadList={{
                          showRemoveIcon: false,
                        }}
                      />
                      <ReactBnbGallery
                        show={openGallery}
                        photos={galleryImg}
                        onClose={() => setOpenGallery(false)}
                        activePhotoIndex={numberImage}
                      />
                    </div>
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Row>
      ))
    );
  });

  const CompleteTask = useMemo(() => (props) => {
    const { sequence, assignee } = props.item;
    const { resourceSelectNew, resourceSelectObject } = props;

    const assigneeRender = assignee.filter(
      (e) =>
        resourceSelectNew.includes(e.memComId) ||
        _.findIndex(resourceSelectObject, (obj) => {
          return obj.memComId === e.memComId;
        }) === -1
    );

    return (
      resourceSelectNew &&
      assigneeRender.map((item, i) => (
        <Row key={item.memComId}>
          <Col span={10} offset={6}>
            {i ? <div className="divider-style" /> : ''}
            <Row className="data-list-css">
              <Col span={3}>
                <Avatar src={item.pathImage} size="large" />
              </Col>
              {item.subtaskDetail.map((item, index) => (
                <Col span={21}>
                  <div className="text-space-address">
                    <Icon type="environment" className="icon-space" style={{ color: '#b2b2b2' }} />
                    <span>{item.address ? item.address : '-'}</span>
                  </div>
                  <div className="text-space">
                    <Icon type="calendar" className="icon-space" style={{ color: '#b2b2b2' }} />
                    <span>{item.updatedAt ? item.updatedAt : '-'}</span>
                  </div>
                  <div className="text-space">
                    <Icon type="message" className="icon-space" style={{ color: '#b2b2b2' }} />
                    <span>{item.comment ? item.comment : '-'}</span>
                  </div>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      ))
    );
  });

  const Note = useMemo(() => (props) => {
    const { sequence, assignee } = props.item;
    const { resourceSelectNew, resourceSelectObject, subWf } = props;

    const assigneeRender = assignee.filter(
      (e) =>
        resourceSelectNew.includes(e.memComId) ||
        _.findIndex(resourceSelectObject, (obj) => {
          return obj.memComId === e.memComId;
        }) === -1
    );

    const pustKeyValue = _.map(assigneeRender, (el) => {
      el.subtaskDetail = _.map(el.subtaskDetail, (e) => {
        e.memComId = el.memComId;
        e.fullname = el.fullname;
        e.pathImage = el.pathImage;
        return e;
      });
      return el;
    });

    let arrAssigneeRender = [];
    _.map(pustKeyValue, (el) => {
      arrAssigneeRender.push(...el.subtaskDetail);
    });
    let arrAssigneeRenderSort = _.orderBy(arrAssigneeRender, ['createdAt'], ['desc']);
    let arrAssigneeRenderShow = _.filter(arrAssigneeRenderSort, (ele, index) => index < 3);
    return (
      <div>
        {resourceSelectNew &&
          arrAssigneeRenderShow.map((item, i) => (
            <Row key={i}>
              <Col span={10} offset={6}>
                <Row className="data-list-css">
                  <Col span={3}>
                    <Avatar src={item.pathImage} size="large" />
                  </Col>
                  <Col span={21}>
                    <div className="text-space">
                      <Icon type="calendar" className="icon-space" style={{ color: '#b2b2b2' }} />
                      <span>{item.updatedAt ? item.updatedAt : '-'}</span>
                    </div>
                    {item.description ? (
                      <div className="text-space">
                        <Icon type="message" className="icon-space" style={{ color: '#b2b2b2' }} />
                        <span>{item.description ? item.description : '-'}</span>
                      </div>
                    ) : null}
                    {item.attachmentsPhoto.length > 0 ? (
                      <div className="text-space">
                        <Icon type="picture" className="icon-space" style={{ color: '#b2b2b2' }} />
                        <span>{item.attachmentsPhoto.length} Photos</span>
                      </div>
                    ) : null}
                  </Col>
                </Row>
              </Col>
            </Row>
          ))}
        <Row>
          <Col span={10} offset={6}>
            <Row className="data-list-css">
              <Col span={3}>
                <div
                  className="button-note-sub-task"
                  onClick={() => {
                    setVisibleAddPost(true);
                    setSubTaskWfId(subWf);
                  }}
                >
                  {' '}
                  <FormattedMessage id="subtaskNoteAddNote" defaultMessage="Add Note" />
                </div>
              </Col>
              <Col span={1}>{arrAssigneeRenderSort.length > 0 ? <Divider type="vertical" /> : null}</Col>
              <Col span={20}>
                {arrAssigneeRenderSort.length > 0 ? (
                  <div
                    className="button-note-sub-task"
                    onClick={() => {
                      setVisibleViewPost(true);
                      setSubTaskWfId(subWf);
                    }}
                  >
                    <FormattedMessage id="subtaskNoteViewAll" defaultMessage="View All" /> ({arrAssigneeRenderSort.length}{' '}
                    <FormattedMessage id="subtaskNoteNotes" defaultMessage="Notes" />)
                  </div>
                ) : null}
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  });

  const Parcel = useMemo(() => (props) => {
    console.log('ParcelProps:::', props);
    const { sequence, assignee, partialCompleteId } = props.item;
    const { resourceSelectNew, resourceSelectObject, key } = props;
    const [parcels, setParcels] = useState();
    const [status, setStatus] = useState();
    const [event, setEvent] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      setLoading(true)
      const getParcelApi = async () => {
        const response = await getParcelDetail(partialCompleteId);
        console.log('Parcelresponse', response);
        setParcels(_.get(response, 'data.data') || undefined);
        setStatus(_.get(response, 'data.status') || undefined);
      };
      if (partialCompleteId && key === subTaskWfId) {
        getParcelApi();
        setLoading(false)
      }
    }, [partialCompleteId, event]);

    const assigneeRender = assignee.filter(
      (e) =>
        resourceSelectNew.includes(e.memComId) ||
        _.findIndex(resourceSelectObject, (obj) => {
          return obj.memComId === e.memComId;
        }) === -1
    );

    const onChangeStatus = (value, id) => {
      console.log('onChangeStatus', value, id);

      Modal.confirm({
        className: 'maintenance-report-modal-confirm',
        centered: true,
        icon: null,
        title: intl.formatMessage({ id: 'lblconfirmUpdateStatusTask', defaultMessage: 'Are you sure you want to update status ?' }),
        okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
        cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
        okButtonProps: {
          style: {
            width: '96px',
          },
          type: 'primary',
        },
        cancelButtonProps: {
          style: {
            width: '96px',
          },
          type: 'danger',
        },
        async onOk() {
          try {
            const payload = {
              status: value,
              remark: '',
              reason: '',
              id: id,
            };
            const response = await updateParcel(payload, id);
            console.log('updateParcelstatus', response);

            if (_.get(response, 'data.status') === 200) {
              setEvent((prev) => !prev);
              successNotification(_.get(response, 'data.message'));
            } else {
              errorNotification(_.get(response, 'data.message'));
            }
          } catch (error) {
            console.log('updateParcelstatus error', error.response);
            errorNotification(_.get(error, 'response.data.message'));
          }
        },
        onCancel() {},
      });

      // const updateStatusApi = async () => {
      //   const payload = {
      //     status: value,
      //     remark: '',
      //     reason: '',
      //     id: id,
      //   };

      //   setItemUpdateParcel((parcel) => {
      //     const filterItem = _.filter(parcel, (item) => item.id !== payload.id);

      //     return [...filterItem, payload];
      //   });

      //   const filterColor = _.chain(status)
      //     .filter((o) => o.statusCode === value)
      //     .head()
      //     .value();

      //   setParcels((parcel) => {
      //     return _.map(parcel, (item) =>
      //       item.subPartialCompleteId === id
      //         ? {
      //             ...item,
      //             statusDetail: {
      //               ...item.statusDetail,
      //               statusCode: value,
      //               color: filterColor.color,
      //             },
      //           }
      //         : item
      //     );
      //   });

      // loop ยิง อัพ ใช้ตัว => setItemUpdateParcel ที่หน้า task-detail ที่หน้าใหญ่

      // const response = await updateParcel(payload, id)
      // setEvent((prev) => !prev)
      // };

      // updateStatusApi();
    };

    const getParcelDetail = async (id) => {
      try {
        const payload = {
          partailCompleteId: id,
          statusCode: '',
          search: '',
        };
        const getParcelApi = await getParcel(payload);
        return getParcelApi;
      } catch (err) {
        console.log('getParcelApierr', err.response);
        return undefined;
      }
    };

    console.log('parcelsItem', parcels, assigneeRender, resourceSelectNew, status);

    return (
      _.size(parcels) > 0 &&
      parcels.map((item, i) => (
        <Row key={item.memComId}>
          <Col span={10} offset={6}>
            {i ? <div className="divider-style" /> : ''}
            <Row className="data-list-css">
              {/* <Col span={3}>
                <Avatar src={item.imageProfile ? item.imageProfile : ''} size="large" />
              </Col> */}
              {/* {item.map((item, index) => ( */}
              <Col span={24}>
                <div className="text-space-parcel-status position-work-no">
                  <span className="text-work-no-title">{item.subPartialCompleteNo ? item.subPartialCompleteNo : ''}</span>
                  <div className="text-work-no-select">
                    {statusView ? (
                      <SelectStatus
                        dropdownStyle={{ minWidth: '200px' }}
                        size="small"
                        value={_.get(item, 'statusDetail.statusCode')}
                        onChange={(value) => onChangeStatus(value, item.subPartialCompleteId)}
                        // disabled={stateTask.statusView ? false : true}
                        // showArrow={stateTask.statusView ? true : false}
                        statusColor={_.get(item, 'statusDetail.color')}
                      >
                        {status &&
                          status.map((item) => (
                            <Option value={item.statusCode}>{langValue === 'TH' ? item.statusName.TH : item.statusName.EN}</Option>
                          ))}
                      </SelectStatus>
                    ) : (
                      <Tag
                        color={_.get(item, 'statusDetail.color')}
                        style={{ width: 100, borderRadius: '20px', display: 'flex', justifyContent: 'center' }}
                      >
                        {langValue === 'TH' ? _.get(item, 'statusDetail.statusName.TH') : _.get(item, 'statusDetail.statusName.EN')}
                      </Tag>
                    )}
                  </div>
                </div>
                <div className="text-space">
                  <Icon type="file-image" className="icon-space" style={{ color: '#b2b2b2' }} />
                  <span>{item.photos ? _.toString(_.size(item.photos)) : '0'} Photos</span>
                </div>
                <div className="text-space">
                  <Icon type="message" className="icon-space" style={{ color: '#b2b2b2' }} />
                  <span>{item.remark ? item.remark : '-'}</span>
                </div>
              </Col>
              {/* ))} */}
            </Row>
          </Col>
        </Row>
      ))
    );
  });

  return (
    <div className="div-subtask-collapse">
      {subtask.length !== 0 ? (
        <Collapse
          className="sub-task-collapse"
          expandIconPosition="right"
          bordered={true}
          activeKey={stateTask.activeKey}
          onChange={callback}
          expandIcon={({ isActive }) => <Icon type="up" rotate={isActive ? 0 : 180} />}
        >
          {subtask !== undefined &&
            subtask.map((item, index) => {
              return (
                <Panel
                  className="panel-css"
                  header={headerNoExpand(
                    item,
                    selectResKeys,
                    assigneeDetail,
                    taskId,
                    taskNo,
                    item.subtaskCode,
                    item.subTaskWfId,
                    subTaskWFIdChange
                  )}
                  key={item.subTaskWfId}
                >
                  {item.subtaskCode === 'start_task' ? (
                    <StartTask item={item} resourceSelectNew={selectResKeys} resourceSelectObject={assigneeDetail} key={item.subTaskWfId} />
                  ) : null}
                  {console.log('menusubtask', item)}
                  {item.subtaskCode === 'update_number' ? (
                    <TruckWeight
                      item={item}
                      resourceSelectNew={selectResKeys}
                      resourceSelectObject={assigneeDetail}
                      key={item.subTaskWfId}
                    />
                  ) : null}
                  {item.subtaskCode === 'pickup_check_in' ? (
                    <PickupCheckIn
                      item={item}
                      resourceSelectNew={selectResKeys}
                      resourceSelectObject={assigneeDetail}
                      key={item.subTaskWfId}
                    />
                  ) : null}
                  {item.subtaskCode === 'check_in' ? (
                    <CheckIn item={item} resourceSelectNew={selectResKeys} resourceSelectObject={assigneeDetail} key={item.subTaskWfId} />
                  ) : null}
                  {item.subtaskCode === 'take_photo' ? (
                    <TakePhoto item={item} resourceSelectNew={selectResKeys} resourceSelectObject={assigneeDetail} key={item.subTaskWfId} />
                  ) : null}
                  {item.subtaskCode === 'take_photo_solar' ? (
                    <TakePhotoSolar
                      item={item}
                      resourceSelectNew={selectResKeys}
                      resourceSelectObject={assigneeDetail}
                      key={item.subTaskWfId}
                    />
                  ) : null}
                  {item.subtaskCode === 'pod_task' ? (
                    <POD item={item} resourceSelectNew={selectResKeys} resourceSelectObject={assigneeDetail} key={item.subTaskWfId} />
                  ) : null}
                  {item.subtaskCode === 'complete_task' ? (
                    <CompleteTask
                      item={item}
                      resourceSelectNew={selectResKeys}
                      resourceSelectObject={assigneeDetail}
                      key={item.subTaskWfId}
                    />
                  ) : null}
                  {item.subtaskCode === 'note_task' ? (
                    <Note
                      item={item}
                      resourceSelectNew={selectResKeys}
                      resourceSelectObject={assigneeDetail}
                      key={item.subTaskWfId}
                      subWf={item.subTaskWfId}
                    />
                  ) : null}
                  {item.subtaskCode === 'partial_complete_task' ? (
                    <Parcel
                      item={item}
                      resourceSelectNew={selectResKeys}
                      resourceSelectObject={assigneeDetail}
                      key={item.subTaskWfId}
                      subWf={item.subTaskWfId}
                      fileList={fileList}
                      setFileList={setFileList}
                    />
                  ) : null}
                </Panel>
              );
            })}
        </Collapse>
      ) : (
        <div className="container-div-component-content-subtask">
          <Card className="task-status-card" style={{ height: '479px' }}>
            {/* <div className="task-status-content-detail">
                <Icon className="task-status-content-icon" type="profile" />
                <p className="task-status-content-text">
                  <FormattedMessage
                    id='taskDetailNoSubtask'
                    defaultMessage="No Subtask" />
                </p>
              </div> */}
            <NoDataBackgroundModal text1={{ id: 'taskDetailNoSubtask', text: 'No Subtask' }} />
          </Card>
        </div>
      )}

      <HandlerModal />
      {visibleAddPost && (
        <ModalAddEngagment
          visible={visibleAddPost}
          setVisible={setVisibleAddPost}
          taskNo={taskNo}
          taskDetailId={taskId}
          setTrigger={setTriggerPost}
          subTaskWfId={subTaskWfId}
        />
      )}
      {visibleEditPost && (
        <ModalEditEngagment
          visible={visibleEditPost}
          setVisible={setVisibleEditPost}
          taskNo={taskNo}
          taskDetailId={taskId}
          setTrigger={setTriggerPost}
          editPostData={editPostData}
          setEditPostData={setEditPostData}
        />
      )}
      {visibleViewPost && (
        <ModalViewEngagment
          visible={visibleViewPost}
          setVisible={setVisibleViewPost}
          id={taskId}
          TitleName={TitleName}
          setVisibleAddPost={setVisibleAddPost}
          trigger={triggerPost}
          setTrigger={setTriggerPost}
          setVisibleEditPost={setVisibleEditPost}
          setEditPostData={setEditPostData}
          subTaskWfId={subTaskWfId}
        />
      )}

      {openParcel && (
        <ParcelModal
          title={'Loop-00000000000000'}
          visible={openParcel}
          setVisible={setOpenParcel}
          trigger={triggerPost}
          setTrigger={setTriggerPost}
          subTaskWfId={subTaskWfId}
          intl={intl}
        />
      )}

      {openParcelReOrder && (
        <ParcelReOrderModal
          title={'Loop-00000000000000'}
          visible={openParcelReOrder}
          setVisible={setOpenParcelReOrder}
          trigger={triggerPost}
          setTrigger={setTriggerPost}
          subTaskWfId={subTaskWfId}
          intl={intl}
          checkedResend={checkedResend}
          setCheckedResend={setCheckedResend}
        />
      )}
    </div>
  );
};
