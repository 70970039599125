import { Card, Col, Modal, Row, Tabs } from 'antd';
import React, { useState, useEffect } from 'react';
// import LeftCardApproval from './left-card';
import { AllticketContext } from './allticket-context';
// import RightCardApproval from './right-card';
import _ from 'lodash';
import {
  getMenuFilter,
  getWaiting,
  getApprove,
  getReject,
  getSearchApproval,
  approveAllRequest,
  rejectAllRequest,
} from '../../controllers/approval/approval-controller';
import { PageSettings } from '../../config/page-settings';
import { errorNotification, successNotification } from '../v2/notification';
import LeftCardAllTicket from './left-card';
import RightCardAllticket from './right-card';
import {
  getBrandReportTicket,
  getModelReportTicket,
  getRepairTicketData,
  getTicketData,
  getTicketDetail,
} from '../../controllers/ticket/ticket';
import { FormattedMessage } from 'react-intl';
import Button_01 from '../v2/button_01';

const ModalAllTicket = (props) => {
  const { visibleTicket, closeTicketModal, setRequestDetailData, requestDetailData } = props;
  const [checkedListRequest, setCheckedListRequest] = useState();
  const [checkteam, setCheckTeam] = useState();
  const [orgIdRaw, setOrgIdRaw] = useState();
  const [paginationPage, setPaginationPage] = useState();
  const [spanRight, setSpanRight] = useState(18);
  const [spanLeft, setSpanLeft] = useState(6);
  const [menuList, setMenuList] = useState([]);
  const [indeterminateTeam, setIndeterminateTeam] = useState(false);
  const [checkedKeysTeam, setCheckedKeysTeam] = useState([]);
  const [searchList, setSearchList] = useState([]);
  const [changeStatus, setChangeStatus] = useState([]);
  const [changeTab, setChangeTab] = useState('1');
  const [checkAllTeam, setCheckAllTeam] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [fillter, setFillter] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [visibleCreate, setVisibleCreate] = useState(false);
  const [visibleView, setVisibleView] = useState(false);
  const [ticketId, setTicketId] = useState();
  const [ticketData, setTicketData] = useState([]);
  const [event, setEvent] = useState(false);
  const [editRecord, setEditRecord] = useState();
  const [sort, setSort] = useState([]);
  const [total, setTotal] = useState();
  const [changeTicketNo, setChangeTicketNo] = useState('');
  const [brandData, setBrandData] = useState([]);
  const [modelData, setModelData] = useState([]);
  const [totalBrand, setTotalBrand] = useState(10);
  const [totalModel, setTotalModel] = useState(10);

  useEffect(() => {
    const getApiMenu = async (props) => {
      const response = await getMenuFilter();
      setMenuList(response.data);
      const allteam = _.map(response.data.data.team, 'orgId');
      onCheckTeam(allteam);
    };
    getApiMenu();
  }, []);

  useEffect(() => {
    const getDataTable = async () => {
      const body = {
        teamList: checkedKeysTeam,
        ticketNo: _.get(fillter, 'reno'),
        startDate: _.get(fillter, 'startdate'),
        endDate: _.get(fillter, 'enddate'),
        ticketTypeList: ['repair'],
        statusList: 'open',
        pageNumber: page,
        limit: size,
        brand: _.get(fillter, 'brand') ? [_.get(fillter, 'brand')] : [],
        model: _.get(fillter, 'model') ? [_.get(fillter, 'model')] : [],
        serialNo: _.get(fillter, 'serailno') || '',
        orderBy: _.get(sort, 'field'),
        orderType: _.get(sort, 'order') === 'descend' ? 'desc' : 'asc',
      };
      console.log('bodyTicket', body);
      const responase = await getTicketData(body);
      setTicketData(_.get(responase, 'data'));
      setTotal(_.get(responase, 'data.data.totalItems'));
      setIsLoading(false);
    };
    if (visibleTicket === true) {
      setIsLoading(true);
      getDataTable();
      console.log('tikcetData', ticketData);
    }
  }, [event, checkedKeysTeam, page, size]);

  useEffect(() => {
    // if (_.size(requestDetailData) > 0) {
    setFillter({ ...fillter, ['reno']: _.get(requestDetailData, 'ticketNo') });
    setChangeTicketNo(_.get(requestDetailData, 'ticketNo'));
    setEvent((current) => !current);
    // }
    // else {
    //   setChangeTicketNo(_.get(requestDetailData,'ticketNo'))
    //   setEvent((current) => !current);
    // }
  }, [requestDetailData, visibleTicket]);

  useEffect(() => {
    getBrand();
  }, [totalBrand]);

  useEffect(() => {
    if (_.get(fillter, 'brand') !== undefined) {
      getModel();
    }
  }, [totalModel, _.get(fillter, 'brand')]);

  const getBrand = async () => {
    const body = {
      indexStart: 0,
      indexEnd: totalBrand,
      searchName: '',
    };

    const responase = await getBrandReportTicket(body);
    console.log('brandData', responase.data);
    setBrandData(_.get(responase, 'data.data.list'));
    // setTotal(_.get(responase, 'data.data.totalItems'));
    setIsLoading(false);
  };

  const getModel = async () => {
    const body = {
      indexStart: 0,
      indexEnd: totalBrand,
      searchName: '',
      assetBrandId: [_.get(fillter, 'brand')],
    };

    const responase = await getModelReportTicket(body);
    console.log('ModelData', responase.data);
    setModelData(_.get(responase, 'data.data.list'));
    // setTotal(_.get(responase, 'data.data.totalItems'));
    setIsLoading(false);
  };

  let getAllKeys = (tree) => {
    let result = [];

    _.forEach(tree, (x) => {
      let childKeys = [];

      if (x.reqTypeList) {
        childKeys = getAllKeys(x.reqTypeList);
      }
      result.push(...[x.reqTypeCode, ...childKeys]);
    });

    return result;
  };

  const allKeysTeam = getAllKeys(_.get(menuList, 'data.team'));
  const onCheckTeam = (checkedKeys) => {
    console.log('allKeysTeam', checkedKeys);
    setCheckedKeysTeam(checkedKeys);
    setIndeterminateTeam(!!_.size(checkedKeys) && _.size(checkedKeys) < _.size(allKeysTeam));
    setCheckAllTeam(_.size(checkedKeys) === _.size(allKeysTeam));
  };

  const handleToggle = () => {
    setToggle((current) => !current);
  };

  const handleChangePage = (page) => {
    console.log('pageFine', page);
    setPage(page);
  };

  const handleChangeSize = (current, size) => {
    setSize(size);
    setPage(current);
  };

  const handleVisibleCreate = () => {
    setVisibleCreate(true);
  };

  const handleView = (record) => {
    setTicketId(record.ticketId);
    setVisibleView(true);
  };

  const handleEdit = async (record) => {
    const responase = await getTicketDetail(_.get(record, 'ticketId'));
    setEditRecord(_.get(responase, 'data.data.rawData'));
    setTicketId(record.ticketId);
    handleVisibleCreate();
    console.log('editRecord1', _.get(responase, 'data.data'));
    // console.log('editRecord',_.get(responase,'data.data.rawData'));
  };

  return (
    // <PageSettings.Consumer>
    //   {({ checkPermissionAction }) =>
    //     checkPermissionAction(`P53PG1C1`, `P53PG1C1A1`) ? (
          <AllticketContext.Provider
            value={{
              state: {
                checkedListRequest,
                checkteam,
                orgIdRaw,
                paginationPage,
                spanRight,
                spanLeft,
                menuList,
                indeterminateTeam,
                checkedKeysTeam,
                searchList,
                checkAllTeam,
                changeTab,
                isLoading,
                fillter,
                selectedRowKeys,
                toggle,
                visibleCreate,
                ticketData,
                event,
                editRecord,
                sort,
                ticketId,
                page,
                total,
                isLoading,
                requestDetailData,
                changeTicketNo,
                brandData,
                modelData,
                totalBrand,
                totalModel,
              },
              setState: {
                setSpanRight,
                setSpanLeft,
                setCheckedListRequest,
                setCheckTeam,
                setOrgIdRaw,
                setPaginationPage,
                setCheckedKeysTeam,
                setIndeterminateTeam,
                setChangeStatus,
                setChangeTab,
                setCheckAllTeam,
                setIsLoading,
                setFillter,
                setSelectedRowKeys,
                setToggle,
                setVisibleCreate,
                setEditRecord,
                setEvent,
                setSort,
                setRequestDetailData,
                setChangeTicketNo,
                setBrandData,
                setModelData,
                setTotalBrand,
                setTotalModel,
              },
              fnc: {
                onCheckTeam,
                handleToggle,
                handleChangePage,
                handleChangeSize,
                handleVisibleCreate,
                handleView,
                handleEdit,
                closeTicketModal,
              },
            }}
          >
            <Modal
              visible={visibleTicket}
              onCancel={() => closeTicketModal()}
              centered={true}
              title={
                <FormattedMessage id="modalSelectTicket" defaultMessage="Select Ticket" />
                // record ? (
                //   <FormattedMessage id="EditFine" defaultMessage="Edit Fine" />
                // ) : (
                //   <FormattedMessage id="CreateFine" defaultMessage="Create Fine" />
                // )
              }
              zIndex={999}
              width={'90%'}
              style={{ marginTop: '15px' }}
              bodyStyle={{ padding: '0px 0px', height: 'calc(100vh - 230px)' }}
              footer={[
                <div style={{ paddingTop: '10px' }}>
                  <Button_01 key="submit" type="primary" btnsize="wd_df" onClick={() => closeTicketModal()} style={{ marginTop: '10px' }}>
                    <FormattedMessage id="btnClose" defaultMessage="Close" />
                  </Button_01>
                </div>,
              ]}
            >
              <Row gutter={[16, 16]}>
                <Col span={toggle ? 0 : 6} style={{ padding: '8px 0px 8px 8px' }}>
                  <LeftCardAllTicket />
                </Col>
                <Col span={toggle ? 24 : 18} style={{ padding: '8px 8px 8px 0px' }}>
                  <RightCardAllticket />
                </Col>
              </Row>
            </Modal>
          </AllticketContext.Provider>
    //     ) : null
    //   }
    // </PageSettings.Consumer>
  );
};

export default ModalAllTicket;
