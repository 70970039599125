import React, { useState } from 'react';
import './index.css';
import _ from 'lodash';
import ReactBnbGallery from 'react-bnb-gallery';
import { Preview01, Preview02, Preview03, Preview04, Preview05, PreviewPlus } from '../../../../components/note-monitor/preview-picture';
import { RenderTag } from './post-tag';
import { FormattedMessage } from 'react-intl';
import { Avatar, Col, Divider, Row, Steps } from 'antd';
import AppAvatar from '../../../../components/avatar';

const { Step } = Steps;

const ViewMoreDetails = (props) => {
  const { viewData, hanldeClickMap } = props;
  const [OpenGallery, setOpenGallery] = useState(false);
  const [galleryImg, setGalleryImg] = useState([]);
  const [numberImage, setNumberImage] = useState(0);
  const [imageFileDefault, setImageFileDefault] = useState('');
  const statusTracking = _.get(viewData, 'tracking');

  const test = [{ test: 1 }, { test: 2 }, { test: 1 }, { test: 2 }];
  const testtrack = [
    { statusName: 'test1', statusDescription: 'Description Testttt', timestampTxt: 'Fri,Jan 9 5:30 PM' },
    { statusName: 'test1', statusDescription: 'Description Testttt', timestampTxt: 'Fri,Jan 9 5:30 PM' },
    { statusName: 'test1', statusDescription: 'Description Testttt', timestampTxt: 'Fri,Jan 9 5:30 PM' },
  ];
  // const testDay = [{ timestampTxt: 'Fri,Jan 9 5:30 PM' }, { timestampTxt: 'Fri,Jan 9 5:30 PM' }, { timestampTxt: 'Fri,Jan 9 5:30 PM' }, { timestampTxt: 'Fri,Jan 9 5:30 PM' }];

  return (
    <div
      className="warehouse-layout-content warehouse-tabs-details scroll-sm"
      style={{ padding: '24px', height: '560px', overflowY: 'scroll' }}
    >
      <div>
        <div>
          {/* ------------------------------------------ Order Summary ------------------------------------------------- */}
          <div>
            <div className="viewTicketTextTopic" style={{ fontSize: '14px' }}>
              <FormattedMessage id="AllOrderOrderSummary" defaultMessage="Order Summary" />
            </div>
            {_.map(_.get(viewData, 'orderSummary'), (item) => {
              return (
                <div style={{ marginTop: '8px' }}>
                  <Row gutter={[8, 8]}>
                    <Col span={4}>
                      <img
                        className="boxOrderSummaryMoreDetail"
                        src={_.get(item, 'itemImg')}
                        // src={`${_.get(viewData, 'ticketImage') ? _.get(viewData, 'ticketImage') : imageFileDefault}`}
                      />
                    </Col>
                    <Col span={13}>
                      <Row>
                        <span className="viewTicketTextHead" style={{ margin: 'unset' }}>
                          {_.get(item, 'itemName')}
                        </span>
                      </Row>
                      <Row>
                        <span className="viewTicketTextHead" style={{ margin: 'unset' }}>
                          x{_.get(item, 'qty')}
                        </span>
                      </Row>
                      <Row>
                      {_.map(_.get(item, 'topicOptions'), (role, index) => {
                            return _.chain(_.get(role, 'options'))
                              .filter(['selected', true])
                              .map((o, test) => {
                                return <span>{(index || test ? ', ' : '') + o.name}</span>;
                              })
                              .value();
                          })}
                      </Row>
                    </Col>
                    <Col span={7} style={{ textAlign: 'end' }}>
                      <span className="viewTicketTextHead" style={{ margin: 'unset' }}>
                        {_.get(item, 'priceTxt')}
                      </span>
                    </Col>
                  </Row>
                </div>
              );
            })}
          </div>
          {/* ------------------------------------------ Total ------------------------------------------------- */}
          <div style={{ marginTop: '24px' }}>
            <div>
              <Row gutter={[8, 8]}>
                <Col span={17}>
                  <span className="viewTicketTextValue" style={{ margin: 'unset' }}>
                    <FormattedMessage id="AllOrderSubtotal" defaultMessage="Subtotal" />
                  </span>
                </Col>
                <Col span={7} style={{ textAlign: 'end' }}>
                  <span className="viewTicketTextValue" style={{ margin: 'unset' }}>
                    {_.get(viewData, 'subtotalTxt')}
                  </span>
                </Col>
              </Row>
            </div>
            {/* ----------------------------------------------------------------------------------------------- */}
            <div style={{ marginTop: '8px' }}>
              <Row gutter={[8, 8]}>
                <Col span={17}>
                  <span className="viewTicketTextValue" style={{ margin: 'unset' }}>
                    <FormattedMessage id="AllOrderMerchantAdjusted" defaultMessage="Merchant Adjusted" />
                  </span>
                </Col>
                <Col span={7} style={{ textAlign: 'end' }}>
                  <span className="viewTicketTextValue" style={{ margin: 'unset' }}>
                    {_.get(viewData, 'merchantAdjustedTxt')}
                  </span>
                </Col>
              </Row>
            </div>
            <div style={{ marginTop: '8px' }}>
              <Row>
                <p className="viewTicketTextDescriptionValue" style={{ margin: 'unset' }}>
                  {_.get(viewData, 'merchantAdjustRemark')}
                </p>
              </Row>
            </div>
            {/* ----------------------------------------------------------------------------------------------- */}
            <div style={{ marginTop: '8px' }}>
              <Row gutter={[8, 8]}>
                <Col span={17}>
                  <span className="viewTicketTextValue" style={{ margin: 'unset' }}>
                    <FormattedMessage id="AllOrderDeliveryFee" defaultMessage="Delivery Fee" />
                  </span>
                </Col>
                <Col span={7} style={{ textAlign: 'end' }}>
                  <span className="viewTicketTextValue" style={{ margin: 'unset' }}>
                    {_.get(viewData, 'deliveryFeeTxt')}
                  </span>
                </Col>
              </Row>
            </div>
            {/* ----------------------------------------------------------------------------------------------- */}
            <div style={{ marginTop: '8px' }}>
              <Row gutter={[8, 8]}>
                <Col span={17}>
                  <span className="viewTicketTextValue" style={{ margin: 'unset' }}>
                    <FormattedMessage id="AllOrderRiderAdjusted" defaultMessage="Rider Adjusted" />
                  </span>
                </Col>
                <Col span={7} style={{ textAlign: 'end' }}>
                  <span className="viewTicketTextValue" style={{ margin: 'unset' }}>
                    {_.get(viewData, 'riderAdjustedTxt')}
                  </span>
                </Col>
              </Row>
            </div>
            <div style={{ marginTop: '8px' }}>
              <Row>
                <p className="viewTicketTextDescriptionValue" style={{ margin: 'unset' }}>
                  {_.get(viewData, 'riderAdjustRemark')}
                </p>
              </Row>
            </div>
            {/* ----------------------------------------------------------------------------------------------- */}
            <div style={{ marginTop: '8px' }}>
              <Row gutter={[8, 8]}>
                <Col span={17}>
                  <span className="viewTicketTextValue" style={{ margin: 'unset' }}>
                    <FormattedMessage id="AllOrderTotal" defaultMessage="Total" />
                  </span>
                </Col>
                <Col span={7} style={{ textAlign: 'end' }}>
                  <span className="viewTicketTextHead" style={{ margin: 'unset' }}>
                    {_.get(viewData, 'totalTxt')}
                  </span>
                </Col>
              </Row>
            </div>
            {/* ----------------------------------------------------------------------------------------------- */}
            <Divider type="horizontal" />
            <div className="viewTicketTextTopic" style={{ fontSize: '14px', marginTop: '40px' }}>
              <FormattedMessage id="AllOrderTracking" defaultMessage="Tracking" />
            </div>
            {/* <Row style={{ marginTop: '8px' }}> */}
            {/* <Col span={24} className="d-flex flex-nowrap align-items-center"> */}
            <div style={{ display: 'flex' ,marginTop: '8px',width: '873px' }}>
              <Steps className="trackingTitle" progressDot direction="vertical" current={0}>
                {_.map(statusTracking, (item, key) => {
                  return (
                    <Step
                      key={key}
                      title={
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <span className="viewTicketTextValue" style={{ margin: 'unset', width: '50%' }}>
                            {item.statusTxt}
                          </span>

                          <span style={{ width: '50%', fontSize: '12px', color: '#909aaf', textAlign: 'right' }}>{item.trackingDate}</span>
                        </div>
                      }
                    />
                  );
                })}
              </Steps>
            </div>
            {/* </Col> */}
            {/* <Col span={11}>
                {_.map(_.get(viewData, 'tracking'), (item, key) => {
                  return (
                    <div style={{ fontSize: '12px', color: '909aaf', textAlign: 'right', marginBottom: '30px' }}>{item.trackingDate}</div>
                  );
                })}
              </Col> */}
            {/* </Row> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewMoreDetails;
