import React, { useContext } from 'react';
import { InvoiceProvider } from './context';
import './index.css'
import InvoicePage from './invoice-page';

const InvoiceProviderPage = () => {
  return (
    <InvoiceProvider>
      <InvoicePage />
    </InvoiceProvider>
  );
};

export default InvoiceProviderPage;
