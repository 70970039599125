import React, { useEffect, useState } from 'react'
import './css/index.css'
import { Modal, Row, Col, Spin } from 'antd'
import Button01 from '../v2/button_01'
import { FormattedMessage, useIntl } from 'react-intl';
import Detail from './detail'
import Map from './map'
import { CompanyLocation } from './context'
import _, { debounce } from 'lodash'
import { searchLocationCompany } from '../../controllers/task/create-task'
import { v4 as uuidv4 } from 'uuid';
import { getSearchLocation } from '../../controllers/support-service/api';
import AddFromLocation from '../from-location/add/index'
import EditFromLocation from '../from-location/edit/index'
import { successNotification } from '../v2/notification'
import deletedFromAddress from '../../controllers/company-profile/deleted-from-address'
import { useAppContext } from '../../includes/indexProvider';
import { useDebounce } from '../../controllers/debounce';

export default (props) => {
  const { visible, setVisible, selectAddress, setSelectAddress, setCreateFromData, fromOutSide, setFromOutSide, trigger, setTrigger, visibleAdd, setVisibleAdd,
    statusFromAdd, setStatuFromsAdd, keyMenu, setKeyMenu, fromLocationData, createFromData, center, setCenter, position, setPosition,
    visibleInfoWindow, setVisibleInfoWindow } = props
  const app = useAppContext();
  const latlng = _.get(app, 'state.latlngCompany')
  const intl = useIntl();
  const [addressData, setAddressData] = useState([]);
  const [dragMarker, setDragMarker] = useState();
  const [loading, setLoading] = useState(false);
  const [visibleEdit, setVisibleEdit] = useState(false);
  const [locationEditData, setLocationEditData] = useState();
  const [fromLocationAdd, setFromLocationAdd] = useState();
  const [fromLocationEdit, setFromLocationEdit] = useState();
  const [searchValue, setSearchValue] = useState("");
  const searchDebounce = useDebounce(searchValue, 500)


  const handleCreate = (data) => {
    setCreateFromData(data)
    setVisible(false)
  }

  const hanldeCancel = () => {
    setVisible(false)
  }

  useEffect(() => {
    handleSearchLocation(searchValue);
  }, [trigger, _.get(fromLocationData, '[0].addressBookId'), searchDebounce]);



  const handleSearchLocation = debounce(async (fieldChang) => {
    let newData = await searchLocationCompany(fieldChang);
    const mapData = _.get(newData, 'data.data').map(item => {
      return {
        address: item.address,
        addressBookId: item.addressBookId === "" ? uuidv4() : item.addressBookId,
        lat: item.lat,
        lng: item.lng,
        name: item.name,
        status: item.addressBookId === "" ? "normal_address" : "customer_address"
      }
    })
    if (_.get(fromLocationData, '[0].addressBookId')) {
      setAddressData(fromLocationData.concat(mapData))
    } else {
      setAddressData(mapData)
    }

  }, 200);

  const handleClickMenu = (props) => {
    setKeyMenu(props.selectedKeys)
    const filter = addressData && addressData.filter(item => { return item.addressBookId === props.key })
    if (_.size(filter) !== 0) {
      setSelectAddress(filter)
      setCenter({
        lat: parseFloat(_.get(filter, '[0].lat')),
        lng: parseFloat(_.get(filter, '[0].lng')),
      })
      setPosition({
        lat: parseFloat(_.get(filter, '[0].lat')),
        lng: parseFloat(_.get(filter, '[0].lng')),
      })

      setTimeout(() => {
        setDragMarker(_.get(filter, '[0].addressBookId'))
        setVisibleInfoWindow(true)
      }, 500);

    }
  }


  const onClickMarker = (id) => {
    setDragMarker(id)
    setVisibleInfoWindow(true)
  }

  const onCloseMarker = () => {
    setDragMarker()
    setVisibleInfoWindow(false)
  }


  const onDragEnd = async (evt) => {
    let area = await getSearchLocation({ location: evt.latLng });
    if (area) {
      const uuid = uuidv4()
      setSelectAddress([{
        address: area.address,
        lat: area.lat,
        lng: area.lng,
        name: area.name,
        addressBookId: uuid,
        status: "normal_address"
      }]);

      setCenter(
        {
          lat: parseFloat(_.get(area, 'lat')),
          lng: parseFloat(_.get(area, 'lng')),
        }
      )
      setPosition(
        {
          lat: parseFloat(_.get(area, 'lat')),
          lng: parseFloat(_.get(area, 'lng')),
        }
      )
      setVisibleInfoWindow(true)
      setDragMarker(uuid)
      setKeyMenu([])
    }
  }

  useEffect(() => {
    if (visible === true && _.size(selectAddress) === 0) {
      errorNavigator()
    }
  }, [visible, trigger])


  // const positionMyself = async (position) => {
  //   setLoading(true)
  //   let area = await getSearchLocation({ Default: position });
  //   if (area) {
  //     const uuid = uuidv4()
  //     setSelectAddress([{
  //       address: area.address,
  //       lat: area.lat,
  //       lng: area.lng,
  //       name: area.name,
  //       addressBookId: uuid,
  //       status: "normal_address"
  //     }]);

  //     setCenter(
  //       {
  //         lat: parseFloat(_.get(area, 'lat')),
  //         lng: parseFloat(_.get(area, 'lng')),
  //       }
  //     )
  //     setPosition(
  //       {
  //         lat: parseFloat(_.get(area, 'lat')),
  //         lng: parseFloat(_.get(area, 'lng')),
  //       }
  //     )
  //     setVisibleInfoWindow(true)
  //     setDragMarker(uuid)
  //     setKeyMenu([])
  //     setLoading(false)
  //   }
  // }

  const errorNavigator = async () => {
    setLoading(true)
    let area = await getSearchLocation({ latlngPure: latlng });
    if (area) {
      const uuid = uuidv4()
      setSelectAddress([{
        address: area.address,
        lat: area.lat,
        lng: area.lng,
        name: area.name,
        addressBookId: uuid,
        status: "normal_address"
      }]);

      setCenter(
        {
          lat: parseFloat(_.get(area, 'lat')),
          lng: parseFloat(_.get(area, 'lng')),
        }
      )
      setPosition(
        {
          lat: parseFloat(_.get(area, 'lat')),
          lng: parseFloat(_.get(area, 'lng')),
        }
      )
      setVisibleInfoWindow(true)
      setDragMarker(uuid)
      setKeyMenu([])
    }
    setLoading(false)
  }

  useEffect(() => {
    if (fromLocationAdd) {
      setSelectAddress([
        {
          address: _.get(fromLocationAdd, 'address'),
          lat: parseFloat(_.get(fromLocationAdd, 'lat')),
          lng: parseFloat(_.get(fromLocationAdd, 'lng')),
          name: _.get(fromLocationAdd, 'addressName'),
          addressBookId: _.get(fromLocationAdd, 'comAddressBookId'),
          status: "customer_address"
        }
      ])
      setCenter(
        {
          lat: parseFloat(_.get(fromLocationAdd, 'lat')),
          lng: parseFloat(_.get(fromLocationAdd, 'lng')),
        }
      )
      setPosition(
        {
          lat: parseFloat(_.get(fromLocationAdd, 'lat')),
          lng: parseFloat(_.get(fromLocationAdd, 'lng')),
        }
      )
      setKeyMenu(_.get(fromLocationAdd, 'comAddressBookId'))

      setTimeout(() => {
        setDragMarker(_.get(fromLocationAdd, 'comAddressBookId'))
        setVisibleInfoWindow(true)
      }, 500);

      setFromLocationAdd()
      setSearchValue("")
    }
  }, [_.get(fromLocationAdd, 'comAddressBookId')]);

  useEffect(() => {
    if (fromLocationEdit) {
      setSelectAddress([
        {
          address: _.get(fromLocationEdit, 'address'),
          lat: parseFloat(_.get(fromLocationEdit, 'lat')),
          lng: parseFloat(_.get(fromLocationEdit, 'lng')),
          name: _.get(fromLocationEdit, 'addressName'),
          addressBookId: _.get(fromLocationEdit, 'comAddressBookId'),
          status: "customer_address"
        }
      ])
      setCenter(
        {
          lat: parseFloat(_.get(fromLocationEdit, 'lat')),
          lng: parseFloat(_.get(fromLocationEdit, 'lng')),
        }
      )
      setPosition(
        {
          lat: parseFloat(_.get(fromLocationEdit, 'lat')),
          lng: parseFloat(_.get(fromLocationEdit, 'lng')),
        }
      )
      setKeyMenu(_.get(fromLocationEdit, 'comAddressBookId'))

      setTimeout(() => {
        setDragMarker(_.get(fromLocationEdit, 'comAddressBookId'))
        setVisibleInfoWindow(true)
      }, 500);

      setFromLocationEdit();
      setSearchValue("")
    }
  }, [_.get(fromLocationEdit, 'comAddressBookId')]);

  useEffect(() => {
    if (fromOutSide) {
      setSelectAddress([
        {
          address: _.get(fromOutSide, 'address'),
          lat: parseFloat(_.get(fromOutSide, 'lat')),
          lng: parseFloat(_.get(fromOutSide, 'lng')),
          name: _.get(fromOutSide, 'addressName'),
          addressBookId: _.get(fromOutSide, 'comAddressBookId'),
          status: "customer_address"
        }
      ])
      setCenter(
        {
          lat: parseFloat(_.get(fromOutSide, 'lat')),
          lng: parseFloat(_.get(fromOutSide, 'lng')),
        }
      )
      setPosition(
        {
          lat: parseFloat(_.get(fromOutSide, 'lat')),
          lng: parseFloat(_.get(fromOutSide, 'lng')),
        }
      )
      setKeyMenu(_.get(fromOutSide, 'comAddressBookId'))

      // setTimeout(() => {
      //   setDragMarker(_.get(fromOutSide, 'comAddressBookId'))
      //   setVisibleInfoWindow(true)
      // }, 1000);

      setFromOutSide();
      setSearchValue("")
    }
  }, [_.get(fromOutSide, 'comAddressBookId')]);

  const handleOpenEdit = (data) => {
    setLocationEditData(data)
    setVisibleEdit(true)
  }

  const deletedAddress = async (record) => {
    Modal.confirm({
      className: "customer-modal-confirm",
      centered: true,
      icon: null,
      title: intl.formatMessage({ id: 'companyProfileDeleteAddress', defaultMessage: 'Are you sure to delete address ?' }),
      okText: intl.formatMessage({ id: 'modalBtnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'modalBtnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: "primary",
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: "danger",
      },
      async onOk() {

        try {
          const response = await deletedFromAddress(record.addressBookId)
          if (_.size(response) !== 0) {
            successNotification(response.status.message)
            setKeyMenu([])
            setSelectAddress([])
            setTrigger(curent => !curent)
            if (_.get(createFromData, 'addressBookId') === record.addressBookId) {
              setCreateFromData();
            }
          }
        } catch (error) {
          return
        }
      },
      onCancel() {
        return
      }
    })
  }


  return (
    <CompanyLocation.Provider
      value={{
        companyState: {
          addressData,
          position,
          center,
          dragMarker,
          visibleInfoWindow,
          selectAddress,
          keyMenu,
          searchValue,
        },
        companySetState: {
          setSelectAddress,
          setVisibleAdd,
          setStatuFromsAdd,
          setSearchValue,
        },
        companyFNC: {
          handleSearchLocation,
          handleClickMenu,
          onClickMarker,
          onDragEnd,
          onCloseMarker,
          handleOpenEdit,
          deletedAddress,
          handleCreate,
        }
      }}
    >
      <div>
        <Modal
          title={intl.formatMessage({ id: 'modalSelectAddressTextHeader', defaultMessage: 'Select Location' })}
          width={1200}
          centered={true}
          onCancel={hanldeCancel}
          visible={visible}
          bodyStyle={{ padding: 'unset' }}
          footer={[
            <Button01
              key="submit"
              type="primary"
              btnsize="wd_df"
              onClick={() => hanldeCancel()}
              style={{ margin: 'unset' }}
            >
              <FormattedMessage id="btnClose" defaultMessage="Close" />
            </Button01>,
          ]}
        >
          <Spin spinning={loading}>
            <Row>
              <Col span={8}>
                <Detail />
              </Col>
              <Col span={16}>
                <Map />
              </Col>
            </Row>
          </Spin>
        </Modal>

        <AddFromLocation
          visible={visibleAdd}
          setVisible={setVisibleAdd}
          triggerAPI={handleSearchLocation}
          selectAddress={selectAddress}
          toLocation={setFromLocationAdd}
          statusFromAdd={statusFromAdd}
          setCreateFromData={setCreateFromData}
        />

        <EditFromLocation
          visible={visibleEdit}
          setVisible={setVisibleEdit}
          valueDefault={locationEditData}
          triggerAPI={handleSearchLocation}
          toLocation={setFromLocationEdit}
        />

      </div>
    </CompanyLocation.Provider>
  )
}
