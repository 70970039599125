import React, { useState, useEffect, useContext } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useAppContext } from '../../../includes/indexProvider';
import styles from './css/map-view.css';
import {
  Row,
  Input,
  Col,
  DatePicker,
  Select,
  List,
  Icon,
  ConfigProvider,
} from 'antd';
import moment from 'moment';
import enUS from 'antd/es/locale/en_US';
import thTH from 'antd/es/locale/th_TH';
import 'moment/locale/th';
import { MapViewContext } from '../monitor-context';

const { Option } = Select;

export default React.memo(({ stateMain }) => {

  const intl = useIntl();


  const app = useAppContext();
  const langValue = app.state.langValue;
  const { state, fnc } = useContext(MapViewContext);

  const dateFormat = 'ddd, MMM DD YYYY';
  const today = new Date();

  const [locale, setLocale] = useState(enUS);

  useEffect(() => {
    changeLocale();
  }, []);

  const changeLocale = () => {
    let codeLang = langValue ? langValue : 'EN';
    let changeLang = codeLang === 'EN' ? enUS : thTH;

    setLocale(changeLang);
    if (!changeLang) {
      moment.locale('en');
    } else {
      moment.locale('th-th');
    }
  };
  
  return (
    <div>
      <Row>
        <Col span={12} style={{ margin: 0 }}>
          <Row>
            <List.Item className="map-view-list-left">
              <Input
                className="map-view-input"
                placeholder={intl.formatMessage({
                  id: 'hintSearch',
                  defaultMessage: 'Search',
                })}
                prefix={<Icon style={{ color: "rgba(0,0,0,.25)" }} type="search" />}
                onChange={(e) => fnc.handleSearch(e.target.value)}
                autoComplete="off"
              />
              <Select
                className="map-view-select"
                placeholder={intl.formatMessage({
                  id: 'hintSelect',
                  defaultMessage: 'Select',
                })}
                onSelect={(value) => fnc.onSelect(value)}
                defaultValue='all'
              >
                <Option value="all">
                  <FormattedMessage id="reportOutsideReportHintSeleteAllColumns" defaultMessage="All columns" />
                </Option>
                {state.selectSearch.map((item, i) => (
                  <Option key={i} value={item.key}>
                    {item.nameKey}
                  </Option>
                ))}
              </Select>
            </List.Item>
          </Row>
        </Col>
        <Col span={12}>
          <Row>
            <List.Item className="map-view-list-right">

              <ConfigProvider locale={locale}>
                <DatePicker
                  className="map-view-rangePicker"
                  format={dateFormat}
                  allowClear={false}
                  onChange={fnc.onSelectDatePicker}
                  value={moment(stateMain.mainDateStartAction, dateFormat)}
                />
              </ConfigProvider>

              <Select
                className="map-view-select-team"
                placeholder={intl.formatMessage({
                  id: 'hintSelect',
                  defaultMessage: 'Select',
                })}
                //onSelect={(value) => fnc.handleSelectTeam(value)}
                // value={stateMain.mainOrgId}
                value={stateMain.mainOrgIdArr}
                defaultValue={[]}
                onChange={fnc.handleChangeOption}
                maxTagCount={1}
                mode="multiple"
                showSearch
                filterOption={(input, option) =>
                  option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {/* {stateMain.mainOrganizationTeamData && stateMain.mainOrganizationTeamData.map((e, index) => (
                  <Option key={index} value={e.orgId}>{e.orgName}</Option>
                ))} */}

                {stateMain.mainOrganizationTeamData && stateMain.mainOrganizationTeamData.map((e, index) => (
                  <Option
                    disabled={
                      state.stateMain.mainOrgIdArr.length > 3
                        ? state.stateMain.mainOrgIdArr.includes(e.orgId)
                          ? false
                          : true
                        : false
                    }
                    key={index} value={e.orgId}>
                    {e.orgName}</Option>
                ))}
              </Select>
            </List.Item>
          </Row>
        </Col>
      </Row>
    </div >
  );
});
