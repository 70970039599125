import { Col, DatePicker, Divider, Form, Row, Select, Input, Icon, Badge } from 'antd';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import LabeRequireForm from '../../../../components/label-required-form';
import ModalCustomerQuotationForm from '../select-customer/customer-modal';
import _ from 'lodash';
import moment from 'moment';

const { Option } = Select;
const { Search } = Input;
const dateFormat = 'DD/MM/YYYY';

const CustomerForm = ({ form, setDataForm, dataForm, initialMemberList, formCustomerRef, isOpenQuotation, record }) => {
  // console.log('CustomerForm', form);
  const { getFieldDecorator } = form;
  const intl = useIntl();
  const [isOpen, setIsOpen] = useState(false);
  const memComId = localStorage.getItem('memComId');
  console.log('memComId', memComId);
  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleSave = () => {
    setIsOpen(false);
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  const MemberOption = (o, i) => {
    return (
      <Option key={i} value={o.memComId}>
        {o.fullname}
      </Option>
    );
  };

  const onChangeDate = (value) => {
    form.setFieldsValue({
      ['validDay']: 0,
      ['validDate']: undefined,
    });
  };

  return (
    <Form name="customer" colon={false} className='po-form'>
      <ModalCustomerQuotationForm
        visible={isOpen}
        onCancel={handleCancel}
        onOk={handleSave}
        setDataForm={setDataForm}
        dataForm={dataForm}
        ref={formCustomerRef}
        isOpenQuotation={isOpenQuotation}
      />

      <Row gutter={[24]}>
        <Col span={8}>
          <Form.Item
            label={
              <LabeRequireForm
                text={intl.formatMessage({ id: 'quotationLabelCustomer', defaultMessage: 'Customer' })}
                req={_.get(record, 'code') ? false : true}
              />
            }
          >
            {getFieldDecorator('customerName', {
              initialValue: _.get(record, 'customerName') || '',
              rules: [
                {
                  required: true,
                  message: intl.formatMessage({ id: 'quotationWarnCustomer', defaultMessage: 'Please select customer' }),
                },
              ],
            })(
              <Search
                placeholder={intl.formatMessage({ id: 'quotationHintCustomer', defaultMessage: 'Select Customer' })}
                onSearch={(value) => handleOpen()}
                enterButton
                className="search-icon-input"
                readOnly
              />
            )}
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={
              <LabeRequireForm
                text={intl.formatMessage({ id: 'quotationLabelCustomerAddress', defaultMessage: 'Customer Address' })}
                req={false}
              />
            }
            className="form-line-height"
          >
            {_.get(dataForm, 'customerAddressBookId') ? (
              <div className="text-group-customer">
                <div className="text-location-short">
                  <Icon
                    type="environment"
                    style={{
                      fontSize: '12px',
                      color: '#1D3557',
                      paddingLeft: '0px',
                      paddingRight: '4px',
                      cursor: 'pointer',
                    }}
                  />
                  <span
                    title={`${_.get(dataForm, 'address')}} ${(<Badge color="#000000" className="badge-text" />)} ${_.get(
                      dataForm,
                      'fullAddress'
                    )}`}
                    style={{ color: '#050505' }}
                  >
                    {_.get(dataForm, 'address')}  -  {/* <Badge color="#000000" className="badge-text" />  */}{_.get(dataForm, 'fullAddress')}
                  </span>
                </div>
                <div className="text-short-placeholer">
                  <span style={{ color: '#898989' }}>
                    {intl.formatMessage({ id: 'quotationLabelTaxNo', defaultMessage: 'Tax No.' })} {_.get(dataForm, 'customerTaxNo') || '-'}
                  </span>
                </div>
              </div>
            ) : (
              <div className="text-group-customer">
                <div className="text-short-placeholer">
                  <Icon
                    type="environment"
                    style={{
                      fontSize: '12px',
                      color: '#1D3557',
                      paddingLeft: '0px',
                      paddingRight: '4px',
                      cursor: 'pointer',
                    }}
                  />
                  <span style={{ color: '#898989' }}>
                    {intl.formatMessage({ id: 'quotationLabelCustomerAddress', defaultMessage: 'Customer Address' })}
                  </span>
                </div>
                <div className="text-short-placeholer">
                  <span style={{ color: '#898989' }}>{intl.formatMessage({ id: 'quotationLabelTaxNo', defaultMessage: 'Tax No.' })}</span>
                </div>
              </div>
            )}
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            label={
              <LabeRequireForm text={intl.formatMessage({ id: 'quotationLabelCustomerContact', defaultMessage: 'Contact' })} req={false} />
            }
            className="form-line-height"
          >
            {_.get(dataForm, 'customerContactId') ? (
              <div className="text-group-customer">
                <div className="text-location-short">
                  <span style={{ color: '#050505' }}>{_.get(dataForm, 'customerContactName')}</span>
                </div>
                <div className="text-location-short">
                  <span style={{ color: '#050505' }}>
                    {_.get(dataForm, 'customerContactPhone')}, {_.get(dataForm, 'customerContactEmail')}
                  </span>
                </div>
              </div>
            ) : (
              <div className="text-group-customer">
                <div className="text-short-placeholer">
                  <span style={{ color: '#898989' }}>
                    {intl.formatMessage({ id: 'quotationLabelCustomerContact', defaultMessage: 'Contact' })}
                  </span>
                </div>
                <div className="text-short-placeholer">
                  <span style={{ color: '#898989' }}>
                    {intl.formatMessage({ id: 'quotationLabelEmail', defaultMessage: 'Email' })},{' '}
                    {intl.formatMessage({ id: 'quotationLabelPhone', defaultMessage: 'Phone' })}
                  </span>
                </div>
              </div>
            )}
          </Form.Item>
        </Col>
      </Row>
      <Divider type="horizontal" style={{ marginBottom: '10px' }} />
      <Row gutter={[24]}>
        <Col span={8}>
          <Form.Item
            label={<LabeRequireForm text={intl.formatMessage({ id: 'quotationLabelIssueBy', defaultMessage: 'Issue by' })} req={false} />}
          >
            {getFieldDecorator('by', {
              initialValue: _.get(record, 'issueBy') || memComId,
            })(
              <Select
                placeholder={intl.formatMessage({ id: 'quotationHintIssueBy', defaultMessage: 'Select Issue by' })}
                showSearch
                filterOption={(input, option) =>
                  option.props.children
                    .toString()
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {initialMemberList && initialMemberList.map((item, index) => MemberOption(item, index))}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={
              <LabeRequireForm text={intl.formatMessage({ id: 'quotationLabelIssueDate', defaultMessage: 'Issue Date' })} req={true} />
            }
          >
            {getFieldDecorator('date', {
              initialValue: _.get(record, 'issueDate') ? moment(_.get(record, 'issueDate'), 'YYYY-MM-DD') : moment(new Date()),
              rules: [
                {
                  required: true,
                  message: intl.formatMessage({ id: 'quotationWarnIssueDate', defaultMessage: 'Please select Issue Date' }),
                },
              ],
            })(
              <DatePicker
                allowClear={false}
                format={dateFormat}
                style={{ width: '100%' }}
                onChange={(e) => onChangeDate(e)}
                placeholder={intl.formatMessage({ id: 'quotationHintIssueDate', defaultMessage: 'Select Issue Date' })}
              />
            )}
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={
              <LabeRequireForm
                text={intl.formatMessage({ id: 'quotationLabelReferenceNo', defaultMessage: 'Reference No.' })}
                req={false}
              />
            }
          >
            {getFieldDecorator('refNo', { initialValue: _.get(record, 'referenceNo') || '' })(<Input />)}
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

CustomerForm.defaultProps = {};

export default CustomerForm;
