import React, { useState, useRef, useContext, useEffect } from 'react'
import { Card, Table, Form, Modal, Row, Col, Input, Button, Popover } from 'antd'
import { useIntl, FormattedMessage } from 'react-intl'
import Button01 from '../../components/v2/button_01'
import Button02 from '../../components/v2/button_02'
import LabeRequireForm from '../../components/label-required-form'
import { handleKeyDownDecimal2Fixed } from '../../component-function/fnc-number'
import _ from 'lodash'
import { PageSettings } from '../../config/page-settings'
import { getSizeList, createSize, updateSize, deleteSize } from '../../controllers/delivery-order/size'
import { successNotification, errorNotification } from '../../components/v2/notification'

const ModalSize = (props) => {
  const { visible, setVisible, form, type, editData, setTrigger } = props
  const { getFieldDecorator, validateFields, resetFields, } = form
  const intl = useIntl()

  const handleSave = () => {
    validateFields(async (err, values) => {
      if (err) {
        return;
      }
      const body = {
        sizeCode: _.get(values, 'size'),
        sizeName: _.get(values, 'size'),
        width: _.get(values, 'width'),
        length: _.get(values, 'length'),
        height: _.get(values, 'height'),
        remark: _.get(values, 'remark') || ""
      }
      const response = type === "add" ? await createSize(body) : await updateSize(_.get(editData, 'id'), body)
      if (_.get(response, 'status') === 200) {
        successNotification(_.get(response, 'data.status.message'))
        setTrigger(cur => !cur)
        setVisible(false)
        resetFields()
      } else {
        errorNotification(_.get(response, 'data.status.message'))
      }
    })
  }

  const handleCancel = () => {
    setVisible(false)
    resetFields()
  }

  return (
    <Modal
      title={type === "add" ? intl.formatMessage({ id: `sizeTileCreate`, defaultMessage: 'Create Size' }) : intl.formatMessage({ id: `sizeTileEdit`, defaultMessage: 'Edit Size' })}
      visible={visible}
      onCancel={handleCancel}
      width={600}
      centered={true}
      footer={[
        <Button02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleCancel}>
          <FormattedMessage id="userDetailModalBtnCancel" defaultMessage="Cancel" />
        </Button02>,
        <Button01 key="submit" type="primary" btnsize="wd_df" onClick={handleSave}>
          <FormattedMessage id="userDetailModalBtnSave" defaultMessage="Save" />
        </Button01>,
      ]}
    >
      <Form form={form} layout="vertical">
        <Row gutter={[8]}>
          <Col span={12}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'sizeFormTitleSize', defaultMessage: 'Size' })} req={true} />
              }
            >
              {getFieldDecorator('size', {
                initialValue: type === "edit" ? _.get(editData, 'sizeName') : undefined,
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'sizeFormValidateSize', defaultMessage: 'Please Enter Size' }),
                  },
                ],
              })(
                <Input
                  placeholder={intl.formatMessage({ id: 'sizeFormPlaceholderSize', defaultMessage: 'Enter Size' })}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'sizeFormTitleWidth', defaultMessage: 'Width(cm.)' })} req={true} />
              }
            >
              {getFieldDecorator('width', {
                initialValue: type === "edit" ? _.get(editData, 'width') : undefined,
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'sizeFormValidateWidth', defaultMessage: 'Please Enter Width(cm.)' }),
                  },
                ],
              })(
                <Input
                  onKeyDown={handleKeyDownDecimal2Fixed}
                  placeholder={intl.formatMessage({ id: 'sizeFormPlaceholderWidth', defaultMessage: 'Enter Width(cm.)' })}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[8]}>
          <Col span={12}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'sizeFormTitleLength', defaultMessage: 'Length(cm.)' })} req={true} />
              }
            >
              {getFieldDecorator('length', {
                initialValue: type === "edit" ? _.get(editData, 'length') : undefined,
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'sizeFormValidateLength', defaultMessage: 'Please Enter Length(cm.)' }),
                  },
                ],
              })(
                <Input
                  onKeyDown={handleKeyDownDecimal2Fixed}
                  placeholder={intl.formatMessage({ id: 'sizeFormPlaceholderLength', defaultMessage: 'Enter Length(cm.)' })}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'sizeFormTitleHeight', defaultMessage: 'Height(cm.)' })} req={true} />
              }
            >
              {getFieldDecorator('height', {
                initialValue: type === "edit" ? _.get(editData, 'height') : undefined,
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'sizeFormValidateHeight', defaultMessage: 'Please Enter Height(cm.)' }),
                  },
                ],
              })(
                <Input
                  onKeyDown={handleKeyDownDecimal2Fixed}
                  placeholder={intl.formatMessage({ id: 'sizeFormPlaceholderHeight', defaultMessage: 'Enter Height(cm.)' })}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[8]}>
          <Col span={24}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'sizeFormTitleRemark', defaultMessage: 'Remark' })} req={false} />
              }
            >
              {getFieldDecorator('remark', {
                initialValue: type === "edit" ? _.get(editData, 'remark') : undefined,
              })(
                <Input
                  maxLength={200}
                  placeholder={intl.formatMessage({ id: 'sizeFormPlaceholderRemark', defaultMessage: 'Enter Remark' })}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

const FormModalSize = Form.create({
  name: 'modal_form',
  mapPropsToFields(props) { },
})(ModalSize);

const Size = () => {
  const intl = useIntl()
  const ref = useRef()
  const [visibleModal, setVisibleModal] = useState(false)
  const [typeModal, setTypeModal] = useState('add')
  const [sizeList, setSizeList] = useState([])
  const [sizeTotal, setSizeTotal] = useState(0)
  const [paginationPage, setPaginationPage] = useState(1);
  const [paginationSize, setPaginationSize] = useState(10);
  const [editData, setEditData] = useState()
  const [visiblePopoverTable, setVisiblePopoverTable] = useState();
  const [recordPopover, setRecordPopover] = useState();
  const [offsetPopover, setOffsetPopover] = useState([0, 0]);
  const [fieldSort, setFieldSort] = useState('createdAt');
  const [orderSort, setOrderSort] = useState('desc');
  const [trigger, setTrigger] = useState(false)
  const { checkPermissionAction } = useContext(PageSettings)

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: '5%',
      render: (text, record, index) => ((paginationPage - 1) * paginationSize) + (index + 1)
    },
    {
      title: intl.formatMessage({ id: `sizeColumnsSize`, defaultMessage: 'Size' }),
      dataIndex: 'sizeName',
      key: 'sizeName',
      width: '20%',
      sorter: true
    },
    {
      title: intl.formatMessage({ id: `sizeColumnsDimension`, defaultMessage: 'Dimension' }),
      dataIndex: 'dimension',
      key: 'dimension',
      width: '30%',
      sorter: true
    },
    {
      title: intl.formatMessage({ id: `sizeColumnsRemark`, defaultMessage: 'Remark' }),
      dataIndex: 'remark',
      key: 'remark',
      width: '45%',
    },
  ];

  useEffect(() => {
    const listData = async () => {
      const body = {
        where: {},
        page: paginationPage,
        limit: paginationSize,
        sortBy: fieldSort,
        sort: orderSort
      }
      const response = await getSizeList(body)
      setSizeList(_.get(response, 'data.data.list'))
      setSizeTotal(_.get(response, 'data.data.total'))
    }
    listData()
  }, [paginationPage, paginationSize, fieldSort, orderSort, trigger])


  const handleOpenModal = (type) => {
    setTypeModal(type)
    setVisibleModal(true)
  }

  const showTotal = (total) => {
    return (
      <span style={{ fontSize: '13px' }}>
        <FormattedMessage id="lblRowPerPage" defaultMessage="Rows per page: " />
      </span>
    );
  };

  const handlePagination = (page) => {
    setPaginationPage(page);
  };

  const handleSizeChange = (current, size) => {
    setPaginationSize(size);
    setPaginationPage(current);
  };

  const handleEditModal = (data, type) => {
    setEditData(data)
    handleOpenModal(type)
  }

  const handleMouseLeave = () => {
    setVisiblePopoverTable(false);
    setRecordPopover();
    setOffsetPopover([0, 0]);
  };

  const handleDelete = async (id) => {
    Modal.confirm({
      className: 'customer-modal-confirm',
      centered: true,
      icon: null,
      title: intl.formatMessage({ id: 'lblDelete', defaultMessage: 'Are you sure you want to delete ?' }),
      okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        const response = await deleteSize(id)
        if (_.get(response, 'status') === 200) {
          successNotification(_.get(response, 'data.status.message'))
          setTrigger(cur => !cur)
        } else {
          errorNotification(_.get(response, 'data.status.message'))
        }
      },
      onCancel() { },
    });

  }

  const fncActionTable = (record, offsetPopover, visiblePopoverTable, ref) => {
    return (
      <div>
        {
          checkPermissionAction(`P59PG1C1`, `P59PG1C1A3`) || checkPermissionAction(`P59PG1C1`, `P59PG1C1A4`) ? (
            <Popover
              placement="right"
              trigger="hover"
              align={{ offset: offsetPopover }}
              visible={visiblePopoverTable}
              zIndex={10000}
              content={
                <div style={{ display: 'grid' }}>
                  <div>
                    {
                      checkPermissionAction(`P59PG1C1`, `P59PG1C1A3`) ? (
                        <Button style={{ width: 100 }} type="link" ghost onClick={() => handleEditModal(record, 'edit')}>
                          <FormattedMessage id="orgTabMemberBtnEdit" defaultMessage="Edit" />
                        </Button>
                      ) : null
                    }
                  </div>
                  <div>
                    {
                      checkPermissionAction(`P59PG1C1`, `P59PG1C1A4`) ? (
                        <Button style={{ width: 100 }} type="link" ghost onClick={() => handleDelete(record.id)}>
                          <FormattedMessage id="btnDelete" defaultMessage="Delete" />
                        </Button>
                      ) : null
                    }
                  </div>
                </div>
              }
            >
              <div style={{ width: 0, height: 0 }} ref={ref}></div>
            </Popover>
          ) : null
        }
      </div>
    );
  };

  const handleChangeTable = (pagination, sorter, extra) => {
    let field = extra.field;
    if (extra.order) {
      if (extra.order === 'descend') {
        setOrderSort('desc');
      } else {
        setOrderSort('asc');
      }
    } else {
      setOrderSort('');
    }
    setFieldSort(field);
  };

  return (
    <PageSettings.Consumer>
      {({ checkPermissionAction }) =>
        <div>
          {
            checkPermissionAction(`P59PG1C1`, `P59PG1C1A1`) ? (
              <Card
                title={intl.formatMessage({ id: `menuParcelSize`, defaultMessage: 'Size' })}
                extra={
                  checkPermissionAction(`P59PG1C1`, `P59PG1C1A2`) ? (
                    <Button01 key="submit" type="primary" btnsize="wd_df" onClick={() => handleOpenModal('add')}>
                      <FormattedMessage id="btnCreate" defaultMessage="Create" />
                    </Button01>
                  ) : null
                }
                bodyStyle={{ height: 'calc(100vh - 190px)' }}
              >
                <div onMouseLeave={handleMouseLeave}>
                  <Table
                    dataSource={sizeList}
                    columns={columns}
                    scroll={{ y: 'calc(100vh - 330px)' }}
                    onChange={handleChangeTable}
                    pagination={{
                      total: sizeTotal,
                      showTotal: showTotal,
                      defaultCurrent: 1,
                      pageSizeOptions: ['10', '20', '30', '40', '50'],
                      showSizeChanger: true,
                      locale: { items_per_page: '' },
                      onChange: handlePagination,
                      onShowSizeChange: handleSizeChange,
                    }}
                    onRow={(record, rowIndex) => {
                      return {
                        onClick: (event) => {
                          if (!event.target.href) {
                            const { x, y } = ref.current.getBoundingClientRect();
                            setVisiblePopoverTable(true);
                            setOffsetPopover([event.pageX - x, event.pageY - y]);
                            setRecordPopover(record);
                          }
                        },
                      };
                    }}
                  />
                  {fncActionTable(recordPopover, offsetPopover, visiblePopoverTable, ref)}
                  {sizeList && sizeList.length !== 0 ? (
                    <div className="total-items-timeAttendance">
                      <span style={{ fontSize: '13px' }}>
                        <FormattedMessage id="lblTotal" defaultMessage="Total" />
                        {` ${sizeTotal || 0} `}
                        <FormattedMessage id="lblitems" defaultMessage="items" />
                      </span>
                    </div>
                  ) : null}
                </div>
                <FormModalSize
                  visible={visibleModal}
                  setVisible={setVisibleModal}
                  type={typeModal}
                  editData={editData}
                  setTrigger={setTrigger}
                />
              </Card>
            ) : null
          }
        </div>
      }
    </PageSettings.Consumer>
  )
}

export default Size
