import React, { useState, useEffect, useRef } from 'react'
import { Modal, Form, Divider, Table, Row, Col, Dropdown, Button, Menu, Input, DatePicker, Select, Popover } from 'antd'
import { useIntl, FormattedMessage } from 'react-intl'
import LabeRequireForm from '../../../../components/label-required-form'
import { DownOutlined } from '@ant-design/icons'
import Button01 from '../../../../components/v2/button_01'
import Button02 from '../../../../components/v2/button_02'
import _ from 'lodash'
import { numberFormatter } from '../../../../component-function/fnc-inputnumber-currency'
import { numberFormatDecimal } from '../../../../component-function/fnc-number-format-decimal'
import { successNotification, errorNotification } from '../../../../components/v2/notification'
import ReactBnbGallery from 'react-bnb-gallery';
import ExpensePaidForm from './form'
import { getExpensePaymentDetail, createPaymentExpesne, updateAttPaymentExpense, getAttPaymentExpense, deleteAttPaymentExpense } from '../../../../controllers/expense/expense'
import moment from 'moment'
import ModalExpenseStatusCancelForm from './status'
import ModalUploadFile from '../../../../components/modal-upload-edit'
import Resizer from 'react-image-file-resizer';

const { Option } = Select

const ExpensePaid = (props) => {
  const intl = useIntl()
  const { visible, setVisible, form, editDefault, bankList, creditCardTypeList, userList, setTrigger, total, setTriggerView,
    reasonPaymentList } = props
  const { getFieldDecorator, validateFields, setFieldsValue, resetFields } = form;
  const styleText = {
    color: '#050505',
    fontSize: '13px'
  }
  const [visiblePaid, setVisiblePaid] = useState(false)
  const [paidType, setPaidType] = useState()
  const [paidData, setPaidData] = useState()
  const [paidItem, setPaidItem] = useState([])
  const langValue = localStorage.getItem('langValue');
  const ref = useRef();
  const [visiblePopoverTable, setVisiblePopoverTable] = useState();
  const [recordPopover, setRecordPopover] = useState();
  const [offsetPopover, setOffsetPopover] = useState([0, 0]);
  const [visibleCancel, setVisibleCancel] = useState(false)
  const [cancelData, setCancelData] = useState()

  const ref2 = useRef();
  const [openUpload, setOpenUpload] = useState(false);
  const [attachmentData, setAttachmentData] = useState([])
  const [typeAttachment, setTypeAttachment] = useState('add');
  const [defaultAttachment, setDefaultAttachment] = useState();
  const [visiblePopoverTableAtt, setVisiblePopoverTableAtt] = useState();
  const [recordPopoverAtt, setRecordPopoverAtt] = useState();
  const [offsetPopoverAtt, setOffsetPopoverAtt] = useState([0, 0]);
  const paidNoCancel = _.sumBy(_.filter(paidItem, (item) => {
    return _.get(item, 'status.code') !== "canceled"
  }), 'amount') || 0

  useEffect(() => {
    const getById = async () => {
      const response = await getExpensePaymentDetail(_.get(editDefault, 'expenseId'))
      setPaidData(_.get(response, 'data.data[0]'))
      setPaidItem(_.get(response, 'data.data[0].paymentDetail') || [])
    }
    if (visible && editDefault) {
      getById()
    }
  }, [visible, editDefault])

  useEffect(() => {
    const getFileDefault = async () => {
      const att = await getAttPaymentExpense(_.get(paidData, 'paymentId'))
      const mapAtt = _.map(_.get(att, 'data'), (item, index) => {
        return {
          id: item.fileHash,
          index: index + 1,
          attachmentName: item.fileName,
          remark: item.remark,
          file: [...item.fileUrl],
          fileRaw: item.fileUrl,
          type: 'uploaded'
        }
      })
      setAttachmentData(mapAtt)
    };
    if (paidData && visible) {
      getFileDefault();
    }
  }, [paidData, visible])


  const columnsDetail = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
      fixed: 'left',
      render: (text, record, index) => index + 1
    },
    {
      title: intl.formatMessage({ id: 'expenseViewModalColumnsMethod', defaultMessage: 'Method' }),
      dataIndex: 'method',
      key: 'method',
      width: 120,
      fixed: 'left',
      render: (text, record, index) => _.get(record, `medthod.lang[${langValue}]`)
    },
    {
      title: intl.formatMessage({ id: 'expenseViewModalColumnsAmount', defaultMessage: 'Amount' }),
      dataIndex: 'amountTxt',
      key: 'amountTxt',
      align: 'right',
      render: (text, record, index) => numberFormatter(_.get(record, `amount`) || 0)
    },
    {
      title: intl.formatMessage({ id: 'expenseViewModalColumnsPaidDate', defaultMessage: 'Paid Date' }),
      dataIndex: 'paidDateTxt',
      key: 'paidDateTxt',
      render: (text, record, index) => moment(_.get(record, `paidDate`), 'YYYY-MM-DD').format('DD/MM/YYYY')
    },
    {
      title: intl.formatMessage({ id: 'expenseViewModalColumnBank', defaultMessage: 'Bank' }),
      dataIndex: 'bank',
      key: 'bank',
      render: (text, record, index) => _.get(record, `detail.bank.lang[${langValue}]`) || '-'
    },
    {
      title: intl.formatMessage({ id: 'expenseViewModalColumnDescription', defaultMessage: 'Description' }),
      dataIndex: 'description',
      key: 'description',
      width: 300,
      render: (text, record, index) => description(record)
    },
    {
      title: intl.formatMessage({ id: 'expenseViewModalColumnsStatus', defaultMessage: 'Status' }),
      dataIndex: 'status',
      key: 'status',
      render: (text, record, index) => _.get(record, `status.lang[${langValue}]`)
    },
    {
      title: intl.formatMessage({ id: 'expenseViewModalColumnsReason', defaultMessage: 'Reason' }),
      dataIndex: 'reason',
      key: 'reason',
      render: (text, record, index) => _.get(record, `reason.txt`) ? _.get(record, `reason.txt`) : '-'
    },
  ]

  const description = (data) => {
    const remark = _.get(data, 'remark') ? `,${_.get(data, 'remark')}` : ''
    let text = null
    if (_.get(data, 'medthod.code') === "banktransfer") {
      text = `${intl.formatMessage({ id: 'expenseViewModalAccountNo', defaultMessage: 'account no.' })}${_.get(data, 'detail.bank.accountNo')}` + remark
    } else if (_.get(data, 'medthod.code') === "creditcard") {
      text = `${_.get(data, 'detail.creditCardType.code') + " " + _.get(data, 'detail.creditCardNo') + " " + _.get(data, 'detail.cardHolderName')}` + remark
    } else if (_.get(data, 'medthod.code') === "cheque") {
      text = `${_.get(data, 'detail.chequeNo') + " " + _.get(data, 'detail.payTo') + ` ${intl.formatMessage({ id: 'expenseViewModalCheqDate', defaultMessage: 'Cheque Date' })} ` + moment(_.get(data, 'detail.chequeDate')).format('DD/MM/YYYY')} ` + remark
    } else {
      text = _.get(data, 'remark') ? `${_.get(data, 'remark')} ` : null
    }
    return text
  }

  const columnsAtt = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
      fixed: 'left'
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsFileName', defaultMessage: 'File Name' }),
      dataIndex: 'attachmentName',
      key: 'attachmentName',
      sorter: true,
      // render: (text, record, index) => <span style={{ cursor: 'pointer' }} onClick={() => handlePreview(record, attachmentData)}>{record.attachmentName}</span>
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsRemark', defaultMessage: 'Remark' }),
      dataIndex: 'remark',
      key: 'remark',
    },
  ]


  const handleSave = async (type) => {
    validateFields(async (err, values) => {
      if (err) {
        return;
      }
      const filterMember = _.filter(userList, (item) => { return item.mem_com_id === parseInt(values.receiveBy) })

      const body = {
        "expenseId": _.get(editDefault, 'expenseId'),
        "remark": _.get(values, 'remark') || "",
        "receiveDate": _.get(values, 'receiveDate') ? moment(_.get(values, 'receiveDate')).format('YYYY-MM-DD') : "",
        "receive": {
          "memComId": _.toString(_.get(filterMember, '[0].mem_com_id')),
          "name": _.get(filterMember, '[0].fullname')
        },
        paymentDetail: paidItem
      }
      const response = await createPaymentExpesne(body)
      if (response.status === 200) {
        successNotification(_.get(response, 'data.status.message'))
        const filterNew = _.filter(attachmentData, (item) => {
          return _.get(item, 'type') !== 'uploaded' && _.get(item, 'delete') !== 'deleted'
        })
        if (_.size(filterNew) > 0) await uploadData(filterNew, _.get(response, 'data.data.paymentId'))
        const deleteNew = _.filter(attachmentData, (item) => {
          return _.get(item, 'type') === 'uploaded' && _.get(item, 'delete') === 'deleted'
        })
        for (let index = 0; index < _.size(deleteNew); index++) {
          const element = deleteNew[index];
          await deleteAttPaymentExpense(element.id)
        }
        setTrigger(cur => !cur)
        setTriggerView(cur => !cur)
        setAttachmentData([])
        setVisible(false)
        resetFields()
        setPaidData()
        setPaidItem([])
      } else {
        errorNotification(_.get(response, 'data.status.message'))
      }
    })
  }

  const uploadData = async (att, id) => {
    for (let index = 0; index < att.length; index++) {
      const element = att[index];
      const temp = _.get(element, 'fileRaw')
      if (_.get(temp, 'type') === 'image/jpeg' || _.get(temp, 'type') === 'image/png') {
        const image = await resizeFile(temp);
        const fileChange = dataURLtoFile(image, _.get(temp, 'name').toLowerCase());
        let formData = new FormData();
        formData.append('file', fileChange);
        formData.append('paymentId', id);
        formData.append('remark', element.remark || "");
        await updateAttPaymentExpense(formData);
      } else if (_.get(temp, 'type') === 'application/pdf') {
        let formDataPDF = new FormData();
        formDataPDF.append('file', temp);
        formDataPDF.append('paymentId', id);
        formDataPDF.append('remark', element.remark || "");
        await updateAttPaymentExpense(formDataPDF);
      }
    }
  }

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        720,
        720,
        'JPG',
        70,
        0,
        (uri) => {
          resolve(uri);
        },
        'base64'
      );
    });

  const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    for (var i = 0; i < bstr.length; i++) {
      u8arr[i] = bstr.charCodeAt(i);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const handleCancel = () => {
    setVisible(false)
    setPaidData()
    setPaidItem([])
    setAttachmentData([])
    resetFields()
  }


  const handleOpenModal = (type) => {
    setPaidType(type)
    setVisiblePaid(true)
  }

  const menuColumn = (
    <Menu>
      <Menu.Item style={{ padding: 'unset' }} onClick={() => handleOpenModal('cash')}>
        <Button ghost type="link" >
          <FormattedMessage id="expenseBtnCash" defaultMessage="Cash" />
        </Button>
      </Menu.Item>
      <Menu.Item style={{ padding: 'unset' }} onClick={() => handleOpenModal('banktransfer')}>
        <Button ghost type="link" >
          <FormattedMessage id="expenseBtnBankTranfer" defaultMessage="Bank Tranfer" />
        </Button>
      </Menu.Item>
      <Menu.Item style={{ padding: 'unset' }} onClick={() => handleOpenModal('creditcard')}>
        <Button ghost type="link" >
          <FormattedMessage id="expenseBtnCreditCard" defaultMessage="Credit Card" />
        </Button>
      </Menu.Item>
      <Menu.Item style={{ padding: 'unset' }} onClick={() => handleOpenModal('cheque')}>
        <Button ghost type="link" >
          <FormattedMessage id="expenseBtnCheque" defaultMessage="Cheque" />
        </Button>
      </Menu.Item>
      <Menu.Item style={{ padding: 'unset' }} onClick={() => handleOpenModal('claim')}>
        <Button ghost type="link" >
          <FormattedMessage id="expenseBtnClaim" defaultMessage="Claim" />
        </Button>
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    if (_.size(paidItem) > 0) {
      setFieldsValue({
        ['receiveTotal']: numberFormatter(_.sumBy(_.filter(paidItem, (item) => {
          return _.get(item, 'status.code') !== "canceled"
        }), 'amount'))
      });
      validateFields(['receiveTotal'], (errors, values) => {
        if (errors) {
          console.log('Validation failed:', errors);
        } else {
          console.log('Validation passed:', values);
        }
      });
    }
  }, [paidItem])


  const validatorReceive = (rule, value, callback) => {
    if (value) {
      const parsValue = parseFloat(value)
      if (parsValue > parseFloat(paidNoCancel || 0)) {
        const diff = parsValue - paidNoCancel
        callback(`${intl.formatMessage({ id: `expenseViewModalPaidOver`, defaultMessage: 'Over Total Amount' })} (${numberFormatter(diff)} THB)`)
      } else {
        callback();
      }
    } else {
      callback();
    }
  }

  const fncActionTable = (record, offsetPopover, visiblePopoverTable, ref) => {
    return (
      <div>
        <Popover
          placement="right"
          trigger="hover"
          align={{ offset: offsetPopover }}
          visible={visiblePopoverTable}
          zIndex={10000}
          content={
            <div style={{ display: 'grid' }}>
              {
                _.get(record, 'status.code') === 'success' ? (
                  <div>
                    <Button style={{ width: 100 }} type="link" ghost onClick={() => handleCancelPayment(record)}>
                      <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
                    </Button>
                  </div>
                ) : null
              }
            </div>
          }
        >
          <div style={{ width: 0, height: 0 }} ref={ref}></div>
        </Popover>
      </div>
    );
  };

  const handleCancelPayment = async (data) => {
    setCancelData(data)
    setVisibleCancel(true)
  }

  const handleCreateUpload = () => {
    setTypeAttachment('add')
    setOpenUpload(true)
  }

  const handleEditUpload = (data) => {
    setDefaultAttachment(data)
    setTypeAttachment('edit')
    setOpenUpload(true)
  }

  const deleteAtt = (value) => {
    const filterSelect = _.map(attachmentData, (item) => {
      return {
        ...item,
        delete: item.id === value ? "deleted" : undefined
      }
    })
    setAttachmentData(filterSelect)
    setVisiblePopoverTableAtt(false);
    setRecordPopoverAtt();
    setOffsetPopoverAtt([0, 0]);
  }

  const handleEditUploadInside = (value) => {
    handleEditUpload(value)
    setVisiblePopoverTableAtt(false);
    setRecordPopoverAtt();
    setOffsetPopoverAtt([0, 0]);
  }

  const fncActionTable2 = (record, offsetPopover, visiblePopoverTable, ref) => {
    return (
      <div>
        <Popover
          placement="right"
          trigger="hover"
          align={{ offset: offsetPopover }}
          visible={visiblePopoverTable}
          zIndex={10000}
          content={
            <div style={{ display: 'grid' }}>
              {/* <div>
                <Button style={{ width: 100 }} type="link" ghost onClick={() => handleEditUploadInside(record)}>
                  <FormattedMessage id="orgTabMemberBtnEdit" defaultMessage="Edit" />
                </Button>
              </div> */}
              <div>
                <Button style={{ width: 100 }} type="link" ghost onClick={() => deleteAtt(record.id)}>
                  <FormattedMessage id="btnDelete" defaultMessage="Delete" />
                </Button>
              </div>
            </div>
          }
        >
          <div style={{ width: 0, height: 0 }} ref={ref}></div>
        </Popover>
      </div>
    );
  };

  const handleMouseLeave = () => {
    setVisiblePopoverTable(false);
    setRecordPopover();
    setOffsetPopover([0, 0]);
    setVisiblePopoverTableAtt(false);
    setRecordPopoverAtt();
    setOffsetPopoverAtt([0, 0]);
  };


  return (
    <Modal
      title={`${intl.formatMessage({ id: `expenseViewModalTitlePaid`, defaultMessage: 'Paid' })} ・ ${_.get(editDefault, 'expenseNo')} `}
      visible={visible}
      onCancel={handleCancel}
      width={900}
      centered={true}
      bodyStyle={{ padding: 'unset', height: '540px', overflowY: 'auto' }}
      footer={[
        <Button02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={() => handleCancel()} margin=" unset">
          <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
        </Button02>,
        <Button01 key="submit" type="primary" btnsize="wd_df" onClick={() => handleSave()}>
          <FormattedMessage id="btnSave" defaultMessage="Save" />
        </Button01>,
      ]}
    >
      <Form form={form} layout="vertical">
        <div style={{ padding: '12px 24px' }}>
          <Row gutter={[24, 2]}>
            <Col span={8}>
              <Form.Item
                label={<LabeRequireForm text={intl.formatMessage({ id: 'expenseFormPONo', defaultMessage: 'PO No.' })} req={false} />}
              >
                <span style={styleText}>{_.get(editDefault, 'poNo') || '-'}</span>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={<LabeRequireForm text={intl.formatMessage({ id: 'expenseFormInvoiceNo', defaultMessage: 'Invoice No.' })} req={false} />}
              >
                <span style={styleText}>{_.get(editDefault, 'invoiceNo') || '-'}</span>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={<LabeRequireForm text={intl.formatMessage({ id: 'expenseFormInvoiceDate', defaultMessage: 'Invoice Date' })} req={false} />}
              >
                <span style={styleText}>{_.get(editDefault, 'invoiceDate') ? moment(_.get(editDefault, 'invoiceDate'), 'YYYY-MM-DD').format('DD/MM/YYYY') : '-'}</span>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[24, 2]}>
            <Col span={8}>
              <Form.Item
                label={<LabeRequireForm text={intl.formatMessage({ id: 'expenseFormTotal', defaultMessage: 'Total' })} req={false} />}
              >
                <span style={styleText}>{numberFormatDecimal(total || 0)}</span>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={<LabeRequireForm text={intl.formatMessage({ id: 'expenseFormDueDate', defaultMessage: 'Due Date' })} req={false} />}
              >
                <span style={styleText}>{_.get(editDefault, 'dueDate') ? moment(_.get(editDefault, 'dueDate'), 'YYYY-MM-DD').format('DD/MM/YYYY') : '-'}</span>
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div style={{ padding: '0px 24px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ color: '#1D3557', fontSize: '14px', fontWeight: 'bold' }}>
              <FormattedMessage id="expenseViewTitleMethod" defaultMessage="Payment Method" />
            </div>
            <Dropdown overlay={menuColumn} trigger={['click']} placement="bottomRight">
              <Button01 key="submit" type="primary" btnsize="wd_df" style={{ margin: 'unset' }}>
                <FormattedMessage id="btnAdd" defaultMessage="Add" />
                <DownOutlined style={{ fontSize: '14px', paddingLeft: '22%' }} />
              </Button01>
            </Dropdown>
          </div>
        </div>
        <Divider type="horizontal" />
        <div style={{ padding: '0px 24px 24px 24px' }} onMouseLeave={handleMouseLeave}>
          <Table
            columns={columnsDetail}
            dataSource={paidItem}
            pagination={false}
            scroll={{ x: 1200 }}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  if (!event.target.href && _.get(record, 'status.code') === "success") {
                    const { x, y } = ref.current.getBoundingClientRect();
                    setVisiblePopoverTable(true);
                    setOffsetPopover([event.pageX - x, event.pageY - y]);
                    setRecordPopover(record);
                  }
                },
              };
            }}
          />
          {fncActionTable(recordPopover, offsetPopover, visiblePopoverTable, ref)}
        </div>
        <div style={{ padding: '0px 24px', marginBottom: '24px' }}>
          <Row gutter={[24, 2]}>
            <Col span={8}>
              <Form.Item
                label={<LabeRequireForm text={intl.formatMessage({ id: 'expenseFormReceiveTotal', defaultMessage: 'Receive Total' })} req={false} />}
              >
                {getFieldDecorator('receiveTotal', {
                  initialValue: paidNoCancel,
                  rules: [
                    {
                      validator: validatorReceive
                    }
                  ],
                })(
                  <Input disabled={true} />
                )}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={<LabeRequireForm text={intl.formatMessage({ id: 'expenseFormReceiveBy', defaultMessage: 'Receive By' })} req={false} />}
              >
                {getFieldDecorator('receiveBy', {
                  initialValue: _.get(paidData, 'receive.memComId') ? parseInt(_.get(paidData, 'receive.memComId')) : parseInt(localStorage.getItem('memComId')),
                })(
                  <Select
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    showSearch
                  >
                    {
                      _.map(userList, (item) =>
                        <Option value={item.mem_com_id}>
                          {item.fullname}
                        </Option>
                      )
                    }
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={<LabeRequireForm text={intl.formatMessage({ id: 'expenseFormReceiveDate', defaultMessage: 'Receive Date' })} req={false} />}
              >
                {getFieldDecorator('receiveDate', {
                  initialValue: _.get(paidData, 'receiveDate') ? moment(_.get(paidData, 'receiveDate'), 'YYYY-MM-DD') : moment(),
                })(
                  <DatePicker
                    placeholder={intl.formatMessage({ id: 'expenseFormPHReceiveDate', defaultMessage: 'Select Receive Date' })}
                    style={{ width: '100%' }}
                    format='DD/MM/YYYY'
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[24, 2]}>
            <Col span={24}>
              <Form.Item
                label={<LabeRequireForm text={intl.formatMessage({ id: 'expenseFormRemark', defaultMessage: 'Remark' })} req={false} />}
              >
                {getFieldDecorator('remark', {
                  initialValue: _.get(paidData, 'remark'),
                })(
                  <Input.TextArea
                    autoSize={{ minRows: 3, maxRows: 3 }}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div style={{ padding: '0px 24px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ color: '#1D3557', fontSize: '14px', fontWeight: 'bold' }}>
              <FormattedMessage id="expenseAttachmentText" defaultMessage="Attachment" />
            </div>
            <Button01 key="submit" type="primary" btnsize="wd_df" style={{ margin: 'unset' }} onClick={handleCreateUpload}>
              <FormattedMessage id="btnUpload" defaultMessage="Upload" />
            </Button01>
          </div>
        </div>
        <Divider type="horizontal" />
        <div style={{ padding: '0px 24px 24px 24px' }} onMouseLeave={handleMouseLeave}>
          <Table
            columns={columnsAtt}
            dataSource={_.filter(attachmentData, (item) => { return _.get(item, 'delete') !== "deleted" })}
            pagination={false}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  if (!event.target.href) {
                    const { x, y } = ref2.current.getBoundingClientRect();
                    setVisiblePopoverTableAtt(true);
                    setOffsetPopoverAtt([event.pageX - x, event.pageY - y]);
                    setRecordPopoverAtt(record);
                  }
                },
              };
            }}
          />
          {fncActionTable2(recordPopoverAtt, offsetPopoverAtt, visiblePopoverTableAtt, ref2)}
        </div>
      </Form >

      <ModalUploadFile
        visible={openUpload}
        setVisible={setOpenUpload}
        setAttData={setAttachmentData}
        attData={attachmentData}
        typeAtt={typeAttachment}
        defaultAtt={defaultAttachment}
      />

      <ExpensePaidForm
        visible={visiblePaid}
        setVisible={setVisiblePaid}
        type={paidType}
        bankList={bankList}
        creditCardTypeList={creditCardTypeList}
        paidItem={paidItem}
        setPaidItem={setPaidItem}
        total={total}
        receive={paidNoCancel}
      />

      <ModalExpenseStatusCancelForm
        visible={visibleCancel}
        setVisible={setVisibleCancel}
        data={cancelData}
        paidItem={paidItem}
        setPaidItem={setPaidItem}
        reasonPaymentList={reasonPaymentList}
      />

    </Modal >
  )
}

const ModalExpensePaid = Form.create({
  name: 'po-form',
  mapPropsToFields() { }
})(ExpensePaid);

export default ModalExpensePaid
