import React from 'react';
import { Redirect } from 'react-router-dom';
import Login from '../pages/login/index';
import Main from '../route/routes';
import ViewQuotation from '../pages/web-view/quotation/index'
import Service from '../pages/support-service/index'
import ViewMenu from '../pages/order-manu';

const routes = [
  {
    path: '/',
    title: '',
    exact: true,
    component: () => <Redirect to='/main' />
  },
  {
    path: '/webview/quotation/:quotationId',
    title: '/Not Match',
    component: () => <ViewQuotation />
  },
  {
    path: '/support/service',
    title: '/Not Match',
    component: () => <Service />
  },
  {
    path: '/order/view',
    title: '/Not Match',
    component: () => <ViewMenu />
  },
  {
    path: '/login',
    title: 'Login',
    component: () => <Login />
  },
  {
    path: '/main',
    title: 'Main',
    component: () => <Main />
  },
  {
    path: '*',
    title: '/Not Match',
    component: () => <Redirect to='/' />
  }

];

export default routes;