import React, { Component } from 'react';
import { Divider, Table, Input } from 'antd';
import './css/index.css';
import _ from 'lodash';
import NumberFormat from 'react-number-format'
import { FormattedMessage } from 'react-intl';

const { TextArea } = Input

export default class Printtest extends Component {
  constructor(props) {
    super(props)
    this.state = props
  }

  render(props) {
    const Data = _.get(this, 'props.data')
    const Header = _.get(this, 'props.dataHeader')
    const Discount = _.get(this, 'props.selectDiscount')
    const MaximumRow = 15
    const chunkArray = _.chunk(_.get(this, 'props.data.detail'), MaximumRow)

    let i = 0
    let runningNumber = 1
    const subTotal = (_.get(Data, 'subTotal'))
    // const discount = (subTotal * _.get(Data, 'discount')) / 100

    let discount = null
    if (_.get(Discount, 'isBath') === true) {
      discount = _.get(Data, 'discount')
    } else {
      discount = (subTotal * _.get(Data, 'discount')) / 100
    }

    const subTotalLess = subTotal - discount
    const tax = (subTotalLess * _.get(Data, 'tax')) / 100
    const totalPrice = subTotalLess + tax;

    return (
      <div className='print-source'>
        <div className="print-container" style={{ margin: "0", padding: "0" }}>
          {
            chunkArray.map((item, index) =>
              // <div class='book'>
              <div class='page' style={{ height: '1500px' }}>
                <div class='subpage'>
                  <div class='subpage-header'>
                    <p class='subpage-header-company-name'>{_.get(Header, 'invHeaderName')}</p>
                    <div style={{ width: '500px' }}>
                      <p>{_.get(Header, 'invHeaderAddress')}</p>
                    </div>
                    <p><span><FormattedMessage id="ViewQutotationHeaderTaxNo" defaultMessage="Tax No." />: {_.get(Header, 'invHeaderTaxNo')}</span><span class='m-l-30'><FormattedMessage id="ViewQutotationHeaderPhone" defaultMessage="Phone" />: {_.get(Header, 'invHeaderPhone')}</span></p>
                    <div class='header-logo'><FormattedMessage id="ViewQutotationHeaderQuotation" defaultMessage="Quotation" /></div>
                  </div>
                  <div class='subpage-customer'>
                    <div class='subpage-customer-left'>
                      <p class='subpage-customer-name'><FormattedMessage id="ViewQutotationHeaderCustomer" defaultMessage="Customer" /></p>
                      <p class='subpage-customer-address-name'>{_.get(Data, 'customerName')}</p>
                      <p class='subpage-customer-address'>{_.get(Data, 'customerAddress')}</p>
                      <p><FormattedMessage id="ViewQutotationHeaderContact" defaultMessage="Contact" /> : {_.get(Data, 'customerContactName')}</p>
                      <p><FormattedMessage id="ViewQutotationHeaderPhone" defaultMessage="Phone" />: {_.get(Data, 'phone')}</p>
                    </div>
                    <div class='subpage-customer-right'>
                      <p class='subpage-inovice-header'><FormattedMessage id="ViewQutotationHeaderQuotationNo" defaultMessage="Quotation No" />.</p>
                      <p class='subpage-inovice-no'>{_.get(Data, 'quotationNo')}</p>
                      <p class='subpage-date-of-issue-header'><FormattedMessage id="ViewQutotationHeaderDate" defaultMessage="Date Of Issue" /></p>
                      <p class='subpage-invoice-date'>{_.get(Data, 'createDate')}</p>
                    </div>
                  </div>
                  <div class='subpage-item'>
                    <table class='quotation-table-item'>
                      <tr>
                        <th>#</th>
                        <th><FormattedMessage id="ViewQutotationTableItemName" defaultMessage="Item Name" /></th>
                        <th><FormattedMessage id="ViewQutotationTableUnitPrice" defaultMessage="Unit Price (Baht)" /></th>
                        <th><FormattedMessage id="ViewQutotationTableQty" defaultMessage="Qty" /></th>
                        <th><FormattedMessage id="ViewQutotationTableUnit" defaultMessage="Unit" /></th>
                        <th><FormattedMessage id="ViewQutotationTableTotal" defaultMessage="Total (Baht)" /></th>
                      </tr>
                      {item.map((item, index) => (
                        <tr>
                          <td>{(runningNumber++)}</td>
                          <td>{_.get(item, 'itemName')}</td>
                          <td><NumberFormat value={_.get(item, 'price').toFixed(2)} displayType={'text'} thousandSeparator={true} /></td>
                          <td>{_.get(item, 'qty')}</td>
                          <td>{_.get(item, 'itemUnitName')}</td>
                          <td><NumberFormat value={_.get(item, 'totalPrice').toFixed(2)} displayType={'text'} thousandSeparator={true} /></td>
                        </tr>
                      ))}

                    </table>
                  </div>
                  {(chunkArray.length - 1) === i++ ? (
                    <div>
                      <div className='print-report-remark'>
                        <div className='print-report-remark-left'>
                          <span className='print-report-text-1'><FormattedMessage id="ViewQutotationFooterRemark" defaultMessage="Remark" /></span>
                          <div className='print-report-remark-content'>
                            {_.get(Data, 'remark')}
                          </div>
                        </div>

                        <div className='print-report-remark-right'>

                          <div className="print-calculate-money">
                            <div className="print-calculate-money-left">
                              <span className="print-report-text-1">
                                <FormattedMessage id="ViewQutotationFooterSubtotal" defaultMessage="Subtotal" />
                              </span>
                            </div>
                            <div className="print-calculate-money-right">
                              <span className="print-report-text-1"><NumberFormat value={(subTotal).toFixed(2)} displayType={'text'} thousandSeparator={true} /></span> &nbsp; <span className="print-report-text-1"><FormattedMessage id="ViewQutotationFooterBaht" defaultMessage="Baht" /></span>
                            </div>
                          </div>

                          <div className="print-calculate-money">
                            <div className="print-calculate-money-left">
                              <span className="print-report-text-1">
                                <FormattedMessage id="ViewQutotationFooterDiscount" defaultMessage="Discount" /> {_.get(Discount, 'isBath') === true ? `Bath` : `${_.get(Data, 'discount')}%`}
                              </span>
                            </div>
                            <div className="print-calculate-money-right">
                              <span className="print-report-text-1"><NumberFormat value={(discount).toFixed(2)} displayType={'text'} thousandSeparator={true} /></span> &nbsp; <span className="print-report-text-1"><FormattedMessage id="ViewQutotationFooterBaht" defaultMessage="Baht" /></span>
                            </div>
                          </div>

                          <div className="print-calculate-money">
                            <div className="print-calculate-money-left">
                              <span className="print-report-text-1">
                                <FormattedMessage id="ViewQutotationFooterSubtotalLess" defaultMessage="Subtotal Less Discount" />
                              </span>
                            </div>
                            <div className="print-calculate-money-right">
                              <span className="print-report-text-1"><NumberFormat value={(subTotalLess).toFixed(2)} displayType={'text'} thousandSeparator={true} /></span> &nbsp; <span className="print-report-text-1"><FormattedMessage id="ViewQutotationFooterBaht" defaultMessage="Baht" /></span>
                            </div>
                          </div>

                          <div className="print-calculate-money">
                            <div className="print-calculate-money-left">
                              <span className="print-report-text-1">
                                <FormattedMessage id="ViewQutotationFooterTax" defaultMessage="Tax" /> {_.get(Data, 'tax')}%
                              </span>
                            </div>
                            <div className="print-calculate-money-right">
                              <span className="print-report-text-1"><NumberFormat value={(tax).toFixed(2)} displayType={'text'} thousandSeparator={true} /></span> &nbsp; <span className="print-report-text-1"><FormattedMessage id="ViewQutotationFooterBaht" defaultMessage="Baht" /></span>
                            </div>
                          </div>

                          <div className="print-calculate-money">
                            <div className="print-calculate-money-left">
                              <span className="print-report-text-1">
                                <FormattedMessage id="ViewQutotationFooterTotal" defaultMessage="Total" />
                              </span>
                            </div>
                            <div className="print-calculate-money-right">
                              <span className="print-report-text-1"><NumberFormat value={(totalPrice).toFixed(2)} displayType={'text'} thousandSeparator={true} /></span> &nbsp; <span className="print-report-text-1"><FormattedMessage id="ViewQutotationFooterBaht" defaultMessage="Baht" /></span>
                            </div>
                          </div>

                        </div>
                      </div>

                      <div className='print-report-signature'>
                        <div className='print-report-signature-div'>
                          <span className='print-report-text-1'><FormattedMessage id="ViewQutotationFooterCustomer" defaultMessage="Customer" /></span>
                          <hr style={{ width: 177 }} className='print-signature-divider' />
                          <span className='print-report-text-1'>({_.get(Data, 'customerName')})</span><br />
                          <span className='print-report-text-1'><FormattedMessage id="ViewQutotationFooterCustomer" defaultMessage="Customer" /></span>
                        </div>
                        <div className='print-report-signature-div'>
                          <span className='print-report-text-1'><FormattedMessage id="ViewQutotationFooterApprover" defaultMessage="Approver" /></span>
                          <hr style={{ width: 177 }} className='print-signature-divider' />
                          <span className='print-report-text-1'>({_.get(Data, 'updatebyName')})</span><br />
                          <span className='print-report-text-1'><FormattedMessage id="ViewQutotationFooterApprover" defaultMessage="Approver" /></span>
                        </div>
                        <div className='print-report-signature-div'>
                          <span className='print-report-text-1'><FormattedMessage id="ViewQutotationFooterSale" defaultMessage="Sale" /></span>
                          <hr style={{ width: 177 }} className='print-signature-divider' />
                          <span className='print-report-text-1'>({_.get(Data, 'sale')})</span><br />
                          <span className='print-report-text-1'><FormattedMessage id="ViewQutotationFooterSale" defaultMessage="Sale" /></span>
                        </div>
                      </div>
                    </div>
                  ) : null

                  }
                  <div style={{ textAlign: 'center', fontSize: '12px' }}>
                    <FormattedMessage id="ViewQutotationFooterPage" defaultMessage="Page" /> {index + 1} <FormattedMessage id="ViewQutotationFooterOf" defaultMessage="of" /> {_.size(chunkArray)}
                  </div>
                </div>
              </div>
              // </div>
            )

          }

        </div>
      </div >
    )
  }
}

