import React, { Component, useEffect } from 'react';

import { Col, Divider, Modal, Row, Table } from 'antd';

import { useReactToPrint } from 'react-to-print';

import _ from 'lodash';

import { FormattedMessage, useIntl } from 'react-intl';
import { numberFormatter } from '../../component-function/fnc-inputnumber-currency';
import moment from 'moment';
import './index.css';

// import { useState } from "react";

export default class POPrint extends Component {
  constructor(props) {
    super(props);
    this.state = props;
    this.state = {
      ComloopTable: [],
      ComloopTableDetail: [],
      DataPO: this.props.record,
      totalCredit: 0,
      page: 0,
    };
  }

  render() {
    const DataQT = this.props.record;
    const depo = _.get(DataQT, 'deliveryPo');
    const DataDetail = _.get(this.props.record, 'itemPo');
    const signatureUser = this.props.signatureUser;
    const totalNum = _.get(DataQT, 'totalNum') ? _.get(DataQT, 'totalNum') : 0;
    const memComName = localStorage.getItem('name');
    const sizeChange = 13;
    const MaximumCal = 16;
    const MaximumRow = 17;
    const chunkArrayTest = _.chunk(DataDetail, MaximumRow);
    const chuckLastIndex = _.findLastIndex(chunkArrayTest, (e) => {
      return e;
    });
    const chunkArrayDe = [...chunkArrayTest, { blank: _.size(chunkArrayTest) }];
    const chunkArray =
      _.size(chunkArrayTest[chuckLastIndex]) <= MaximumRow && _.size(chunkArrayTest[chuckLastIndex]) >= sizeChange
        ? chunkArrayDe
        : chunkArrayTest;

    console.log('LastINdex', chunkArray, chunkArrayTest);
    console.log('DataQT', DataQT);

    const LoopTableCredit = (indexchuck) => {
      const viewloop = [];
      let indexcount = 0;
      let chuckcount = indexchuck;

      const callsize = this.props.sizeRecordReport + 2 - (this.props.sizeRecordReport - _.size(this.state.DataCreadit));
      const callsizeLarge = callsize + 10;

      console.log('chunkArray', chuckcount, chunkArray, _.size(this.state.DataCreadit));
      for (
        let index = 0;
        _.size(chunkArray[chuckcount]) >= MaximumCal ? index < MaximumRow : _.size(chunkArray[chuckcount]) >= sizeChange ? index < MaximumRow : index < sizeChange;
        index++
      ) {
        // for (let index = 0; this.props.sizeRecordReport >= 9 ? index < callsizeLarge: index < callsize; index++) {
        console.log('POPRintIndex', index);
        console.log('ArrayIndex', chunkArray[chuckcount], '/', index >= _.size(chunkArray[chuckcount]));
        let borderBottomLine = _.size(chunkArray[chuckcount]) >= sizeChange && index === MaximumRow ? 'unset' : 'unset';
        let HeightSpace = _.size(chunkArray[chuckcount]) >= sizeChange ? 'tdCreaditTextBlank' : 'tdCreaditTextBlank10';
        // : _.size(chunkArray[chuckcount]) > sizeChange && index === 19

        viewloop.push(
          index > _.size(chunkArray[chuckcount]) || _.get(chunkArray[chuckcount], 'blank') ? (
            <tr
              style={{
                height: '35px',
                paddingTop: '10px',
                fontSize: '17px',
              }}
            >
              <td className={HeightSpace} style={{ borderBottom: borderBottomLine }}></td>
              <td className={HeightSpace} style={{ borderBottom: borderBottomLine }}></td>
              <td className={HeightSpace} style={{ borderBottom: borderBottomLine }}></td>
              <td className={HeightSpace} style={{ borderBottom: borderBottomLine }}></td>
              <td className={HeightSpace} style={{ borderBottom: borderBottomLine }}></td>
              <td className={HeightSpace} style={{ borderBottom: borderBottomLine }}></td>
              <td className={HeightSpace} style={{ borderBottom: borderBottomLine }}></td>
            </tr>
          ) : (
            <>
              <tr
                style={{
                  height: '5px',
                  paddingTop: '0px',
                  fontSize: '17px',
                }}
              >
                <td
                  className="tdCreaditText"
                  span={1}
                  style={{
                    textAlign: 'center',
                    borderBottom: borderBottomLine,
                  }}
                >
                  {_.get(chunkArray[chuckcount][index], 'index')}
                </td>
                <td
                  className="tdCreaditText"
                  span={11}
                  style={{
                    textAlign: 'left',
                    paddingLeft: '10px',
                    borderBottom: borderBottomLine,
                  }}
                >
                  {_.get(chunkArray[chuckcount][index], 'itemCode')}
                </td>
                <td
                  className="tdCreaditText"
                  span={11}
                  style={{
                    textAlign: 'left',
                    paddingLeft: '10px',
                    borderBottom: borderBottomLine,
                  }}
                >
                  {_.get(chunkArray[chuckcount][index], 'itemName')}
                </td>
                <td
                  className="tdCreaditText"
                  span={2}
                  style={{
                    textAlign: 'right',
                    paddingRight: '10px',
                    borderBottom: borderBottomLine,
                  }}
                >
                  {_.get(chunkArray[chuckcount][index], 'qtyWithUom')}
                </td>
                <td
                  className="tdCreaditText"
                  span={4}
                  style={{
                    textAlign: 'right',
                    paddingRight: '10px',
                    borderBottom: borderBottomLine,
                  }}
                >
                  {numberFormatter(_.get(chunkArray[chuckcount][index], 'pricePerUnit'))}
                </td>
                <td
                  className="tdCreaditText"
                  span={2}
                  style={{
                    textAlign: 'right',
                    paddingRight: '10px',
                    borderBottom: borderBottomLine,
                  }}
                >
                  {numberFormatter(_.get(chunkArray[chuckcount][index], 'discountPrice'))}
                </td>
                {/* <td
                  className="tdCreaditText"
                  span={2}
                  style={{
                    borderLeft: '1px solid',
                    textAlign: 'left',
                    borderBottom: borderBottomLine,
                  }}
                >
                  {_.get(chunkArray[chuckcount][index], 'uomName')}
                </td> */}
                <td
                  className="tdCreaditText"
                  span={4}
                  style={{
                    textAlign: 'right',
                    paddingRight: '10px',
                    borderBottom: borderBottomLine,
                  }}
                >
                  {numberFormatter(_.get(chunkArray[chuckcount][index], 'totalPrice'))}
                </td>
              </tr>
            </>
          )
        );
      }
      let countPage = chuckcount + 1;
      if (countPage === _.size(chunkArray) - 1) {
        countPage = _.size(chunkArray) - 1;
      }
      // console.log('chunkArrayviewloop', viewloop);
      return viewloop;
      // this.setState({ ComloopTable: viewloop, page: countPage });
    };

    const ThaiNumberToText = (number) => {
      console.log('numberPrint', number);
      const splitDot = _.split(number, '.');
      const fullNumber = _.get(splitDot, '[0]');
      const dotNumber = _.get(splitDot, '[1]');
      const num = ['ศูนย์', 'หนึ่ง', 'สอง', 'สาม', 'สี่', 'ห้า', 'หก', 'เจ็ด', 'แปด', 'เก้า'];
      const pos = ['หน่วย', 'สิบ', 'ร้อย', 'พัน', 'หมื่น', 'แสน', 'ล้าน', 'สิบ', 'ร้อย', 'พัน', 'หมื่น', 'แสน'];
      let res = '';

      for (let index = 0; index < _.size(fullNumber); index++) {
        if (fullNumber[index] !== '0') {
          console.log('fullNumber', fullNumber[index]);
          res = res + num[parseInt(fullNumber[index])];
          res = res + pos[_.size(fullNumber) - (index + 1)];
        }
      }
      let resDot = '';
      for (let index = 0; index < _.size(dotNumber); index++) {
        if (dotNumber[index] !== '0') {
          resDot = resDot + num[parseInt(dotNumber[index])];
          resDot = resDot + pos[_.size(dotNumber) - (index + 1)];
        }
      }
      res = res.replace('หนึ่งสิบ', 'สิบ');
      res = res.replace('ศูนย์หน่วย', '');
      res = res.replace('หนึ่งหน่วย', 'เอ็ด');
      res = res.replace('หนึ่งหน่วย', 'เอ็ด');
      res = res.replace('สองสิบ', 'ยี่สิบ');
      res = res.replace('หน่วย', '');
      if (res === 'เอ็ด') {
        res = 'หนึ่ง';
      }
      resDot = resDot.replace('หนึ่งสิบ', 'สิบ');
      resDot = resDot.replace('ศูนย์หน่วย', '');
      resDot = resDot.replace('หนึ่งหน่วย', 'เอ็ด');
      resDot = resDot.replace('หนึ่งหน่วย', 'เอ็ด');
      resDot = resDot.replace('สองสิบ', 'ยี่สิบ');
      resDot = resDot.replace('หน่วย', '');
      if (resDot === 'เอ็ด') {
        resDot = 'หนึ่ง';
      }
      // if () {

      // }
      if (parseInt(dotNumber) === 0) {
        return res + 'บาทถ้วน';
      }
      if (parseInt(dotNumber) > 0) {
        return res + 'บาท' + resDot + 'สตางค์';
      }
    };

    const LoopDelivery = () => {
      const viewloop = [];
      console.log('depo', _.size(depo));
      for (let index = 0; index < 5; index++) {
        if (_.get(depo[index], 'deliveryDate')) {
          viewloop.push(
            <>
              {moment(_.get(depo[index], 'deliveryDate'))
                .add(543, 'year')
                .format('DD/MM/YYYY')}{' '}
              {_.get(depo[index], 'deliveryTypeCode') === 'delivery' ? (
                <FormattedMessage id="POPrintTitleDelivery" defaultMessage="ส่งที่" />
              ) : (
                <FormattedMessage id="POPrintTitlePickUp" defaultMessage="รับที่" />
              )}{' '}
              {_.get(depo[index], 'deliveryAddressDetail.addressName')}
              {/* {index === 3 && _.size(depo) > 4 ? ' ....' : null} */}
              <br />
            </>
          );
        }

        // viewloop.push(
        //   <>
        //     {moment(_.get(depo[index],'deliveryDate')).format('DD/MM/YYYY')}{' '}
        //     {_.get(depo[index], 'deliveryTypeCode') === 'delivery' ? (
        //       <FormattedMessage id="POPrintTitleDelivery" defaultMessage="ส่งที่" />
        //     ) : (
        //       <FormattedMessage id="POPrintTitlePickUp" defaultMessage="รับที่" />
        //     )}{' '}
        //     {_.get(depo[index], 'deliveryAddressDetail.addressName')} { index === 3 && _.size(depo) > 4 ? ' ....' : null}
        //     <br />
        //   </>
        // );
      }
      return viewloop;
    };

    return (
      <div ref={this.props.ref}>
        {chunkArray.map((item, index) => {
          return (
            <div className="printable" style={{ padding: '55px', width: '1000px', height: '1200px' }}>
              <Row type="flex" align="middle">
                <Col span={12} style={{ fontSize: '24px' }}>
                  {_.get(DataQT, 'companyName')}
                  {/* <FormattedMessage id="QTPrintTitleCompany" defaultMessage="บริษัทแสงศิริเคมีเกษตรจำกัด" /> */}
                </Col>
                <Col span={12} style={{ textAlign: 'right', fontSize: '24px' }}>
                  <FormattedMessage id="POTitleType" defaultMessage="ใบสั่งซื้อ" />
                </Col>
              </Row>
              <Row>
                <Col span={20} style={{ fontSize: '17px' }}>
                  {_.get(DataQT, 'companyAddress')}
                </Col>
                <Col span={4} style={{ fontSize: '20px', textAlign: 'right' }}>
                  <FormattedMessage id="QTPrintTitlePage" defaultMessage="หน้า" /> {index + 1} / {_.size(chunkArray)}
                </Col>
              </Row>
              <Row style={{ color: '#050505' }}>
                <Col span={8} style={{ fontSize: '17px',display: 'flex' }}>
                <span style={{ width: '115px' }}>
                  <FormattedMessage id="QTPrintTitlePhone" defaultMessage="เบอร์โทรศัพท์" />:{' '}</span>
                  <span style={{ color: '#050505' }}>{_.get(DataQT, 'companyPhone')}</span>
                </Col>
              </Row>
              <Row style={{ color: '#050505' }}>
                <Col span={8} style={{ fontSize: '17px',display: 'flex' }}>
                <span style={{ width: '115px' }}>
                  <FormattedMessage id="QTPrintTitleTax" defaultMessage="เลขผู้เสียภาษี" />:{' '}</span>
                  <span style={{ color: '#050505' }}>{_.get(DataQT, 'companyTaxNo')}</span>
                </Col>
              </Row>
              {/* <Divider className="lineblack" /> */}
              <div className="borderInfoTop" style={{ display: 'flex', marginTop: '10px' }}>
                <div className="paddingInFoTop" style={{ width: '570px', paddingBottom: '10px' }}>
                  <div className="TopicData" style={{ display: 'flex',paddingTop: '10px' }}>
                    <span style={{ width: '50%' ,display: 'flex' }}>
                      <span style={{ width: '105px' }}>
                        <FormattedMessage id="POPrintTitleCustomerCode" defaultMessage="รหัสผู้ขาย" />:
                      </span>
                      <span>{_.get(DataQT, 'vendorSupplierCode')} </span>
                    </span>
                    <span style={{ width: '50%' }}>
                      {' '}
                      <FormattedMessage id="QTPrintTitleTax" defaultMessage="เลขผู้เสียภาษี" />:&nbsp; {_.get(DataQT, 'vendorSupplierTaxNo')}
                    </span>
                  </div>
                  <div className="TopicData" style={{ display: 'flex' }}>
                    <span style={{ width: '105px' }}>
                      <FormattedMessage id="SOPrintTitleCustomer" defaultMessage="ผู้ขาย" />:
                    </span>
                    <span style={{}}>{_.get(DataQT, 'vendorSupplier')}</span>
                  </div>
                  {/* <div className="topiData30">{_.get(DataQT, 'vendorSupplier')} </div> */}
                  <div className="TopicData" style={{ display: 'flex' }}>
                    <span style={{ width: '105px' }}>
                      <FormattedMessage id="SOPrintTitleAddress" defaultMessage="ที่อยู่" />:
                    </span>
                    <span style={{ width: '82%' }}>{_.get(DataQT, 'vendorSupplierAddress')}</span>
                  </div>
                  <div className="TopicData" style={{ display: 'flex' }}>
                    {' '}
                    <span style={{ width: '50%' }}>
                      <FormattedMessage id="QTPrintTitleContact" defaultMessage="ผู้ติดต่อ" />:{' '}
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      {_.get(DataQT, 'vendorSupplierContactDetail.vendorSupplierContactName')}{' '}
                    </span>
                    <span style={{ width: '50%' }}>
                      <FormattedMessage id="QTPrintTitlePhoneContact" defaultMessage="เบอร์โทรศัพท์" />:{' '}
                      &nbsp;
                      {_.get(DataQT, 'vendorSupplierContactDetail.vendorSupplierContactPhone')}
                    </span>
                  </div>
                  {/* <div className="TopicData" style={{ paddingTop: '0px' }}>
                    <FormattedMessage id="QTPrintTitlePhoneContact" defaultMessage="เบอร์โทรศัพท์" />:{' '}
                    {_.get(DataQT, 'vendorSupplierContactDetail.vendorSupplierContactPhone')}
                  </div> */}
                </div>

                <div style={{ width: '351px', borderLeft: '1px solid' }}>
                  <div className="paddingInFoTop" style={{ height: '50%', borderBottom: '1px solid' }}>
                    <div className="TopicDataRPO" style={{ paddingTop: '10px' }}>
                      <span style={{ width: '50%' }}>
                        <FormattedMessage id="POPrintTitlePONO" defaultMessage="เลขที่ใบสั่งซื้อ" />:{' '}
                      </span>
                      <span style={{ width: '50%' }}> {_.get(DataQT, 'poNo')}</span>
                    </div>
                    {/* <div className="topiData30R">{_.get(DataQT, 'poNo')}</div> */}
                    <div className="TopicDataRPO">
                      {' '}
                      <span style={{ width: '50%' }}>
                        <FormattedMessage id="QTPrintTitleDate" defaultMessage="วันที่ทำรายการ" />:
                      </span>
                      <span>
                        {' '}
                        {moment(_.get(DataQT, 'issueDate'))
                          .add(543, 'year')
                          .format('DD/MM/YYYY')}
                      </span>
                    </div>
                  </div>

                  <div className="paddingInFoTop" style={{ height: '50%' }}>
                    <div className="TopicDataRPO" style={{ paddingTop: '10px' }}>
                      <span style={{ width: '50%' }}>
                        <FormattedMessage id="POTitleRef" defaultMessage="เอกสารอ้างอิง" />:{' '}
                      </span>
                      <span style={{ width: '50%' }}>
                        {' '}
                        {_.get(DataQT, 'referenceNo')
                          ? _.truncate(_.get(DataQT, 'referenceNo'), {
                              length: 18,
                              separator: ' ',
                            })
                          : '-'}
                      </span>
                    </div>
                    <div className="TopicDataRPO">
                      {' '}
                      <span style={{ width: '50%' }}>
                        <FormattedMessage id="POCondition" defaultMessage="เงื่อนไขการชำระเงิน" />:
                      </span>
                      <span>{_.get(DataQT, 'paymentTerm')} วัน</span>
                    </div>
                  </div>
                </div>
              </div>
              <table className="table" style={{ border: '1px solid', width: '890px', marginBottom: '0px', borderBottom: 'unset' }}>
                {/* <thead style={{ backgroundColor: '#E2E2E2' }}> */}
                <thead>
                  {/* <tr className="tdBG" style={{ fontWeight: 'bold' }}> */}
                  <tr className="tdBG">
                    <th className="thHeadCredit" style={{ width: '50px' }}>
                      <div className="conTh">#</div>
                    </th>
                    {/* <th style={{ border: '1px solid', textAlign: 'center', width: '400px',fontWeight: 'bold' }}> */}
                    <th className="thHeadCredit" style={{ width: '125px' }}>
                      <div className="conTh">
                        <FormattedMessage id="QTPrintTitleCode" defaultMessage="รหัสสินค้า" />
                      </div>
                    </th>
                    <th className="thHeadCredit" style={{ width: '270px' }}>
                      <div className="conTh">
                        <FormattedMessage id="QTPrintTitleList" defaultMessage="ชื่อรายการ" />
                      </div>
                    </th>
                    <th className="thHeadCredit" style={{ width: '106px' }}>
                      <div className="conTh">
                        <FormattedMessage id="QTPrintTitleNumber" defaultMessage="จำนวน" />
                      </div>
                    </th>
                    <th className="thHeadCredit" style={{ width: '117x' }}>
                      <div className="conTh">
                        {' '}
                        <FormattedMessage id="QTPrintTitleUnitprice" defaultMessage="ราคาต่อหน่วย" />
                      </div>
                    </th>
                    <th className="thHeadCredit" style={{ width: '97px' }}>
                      <div className="conTh">
                        <FormattedMessage id="QTPrintTitleDiscount" defaultMessage="ส่วนลด" />
                      </div>
                    </th>
                    <th className="thHeadCredit" style={{ width: '125px' }}>
                      <div className="conTh">
                        {' '}
                        <FormattedMessage id="QTPrintTitlePrice" defaultMessage="ราคา" />
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody style={{ height: '10px' }}>{_.map(LoopTableCredit(index), (item) => item)}</tbody>
              </table>
              {_.size(chunkArray[index]) >= sizeChange ? null : (
                <>
                  {' '}
                  <table width="100%" style={{ border: '1px solid', borderBottom: '1px' }}>
                    <tbody style={{ height: '10px' }}>
                      <tr>
                        <td colSpan="4" style={{ width: '50%' }}>
                          <table width="100%" style={{ height: '100%' }}>
                            <tbody style={{ height: '100%' }}>
                              <tr>
                                <td
                                  style={{
                                    fontSize: '17px',
                                    paddingBottom: '0px',
                                    padding: ' 3px 12px',
                                    verticalAlign: 'top',
                                  }}
                                >
                                  <FormattedMessage id="PODueDate" defaultMessage="กําหนดวันส่งของ" />:
                                </td>
                              </tr>

                              <tr style={{ padding: ' 5px 12px', height: '20px' }}>
                                <td rowspan="4" colSpan="2" style={{ padding: ' 3px 12px', paddingTop: '1px', verticalAlign: 'top' }}>
                                  {_.map(LoopDelivery(index), (item) => item)}
                                </td>
                              </tr>

                              <tr style={{ padding: ' 5px 12px', height: '20px' }}>
                                <td style={{ padding: ' 3px 12px', paddingTop: '1px', verticalAlign: 'top' }}></td>
                              </tr>

                              <tr style={{ padding: ' 5px 12px', height: '20px' }}>
                                <td style={{ padding: ' 3px 12px', paddingTop: '1px', verticalAlign: 'top' }}></td>
                              </tr>
                              <tr style={{ padding: ' 5px 12px', height: '20px' }}>
                                <td style={{ padding: ' 3px 12px', paddingTop: '1px', verticalAlign: 'top' }}></td>
                              </tr>
                              <tr style={{ height: '20px', borderTop: '1px solid' }}>
                                <td style={{ fontSize: '17px', padding: ' 5px 12px' }}>
                                  <span>
                                    <FormattedMessage id="QTPrintTitlesubAllLabel" defaultMessage="ตัวอักษร" />
                                  </span>
                                  : {_.get(DataQT, 'total') === null ? 'ศูนย์บาทถ้วน' : ThaiNumberToText(totalNum.toFixed(2))}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                        <td colSpan="4" style={{ width: '50%', borderLeft: '1px solid' }}>
                          <table width="100%">
                            <tbody>
                              <tr>
                                <td
                                  className="tdPriceTextPO"
                                  style={{
                                    width: '60%',
                                    fontSize: '17px',

                                    borderTop: '1px solid',
                                    verticalAlign: 'bottom',
                                  }}
                                >
                                  <FormattedMessage id="QTPrintTitlePhoneTotalPrice" defaultMessage="ราคารวม" />
                                </td>
                                <td
                                  className="tdPrice"
                                  style={{
                                    width: '50%',
                                    textAlign: 'right',
                                    paddingRight: '10px',
                                    borderTop: '1px solid',
                                    verticalAlign: 'bottom',
                                  }}
                                >
                                  {numberFormatter(_.get(DataQT, 'subtotal'))}
                                </td>
                              </tr>
                              <tr style={{ padding: ' 5px 10px', height: '20px' }}>
                                <td className="tdPriceTextPO">
                                  <FormattedMessage id="QTPrintTitleDiscount" defaultMessage="ส่วนลด" /> {_.get(DataQT, 'discountPercent')}%
                                </td>
                                <td className="tdPrice">{numberFormatter(_.get(DataQT, 'discount'))}</td>
                              </tr>
                              <tr style={{ padding: ' 5px 10px', height: '20px' }}>
                                <td className="tdPriceTextPO">
                                  <FormattedMessage id="QTPrintTitlesubTotalAfterDiscount" defaultMessage="ราคารวมหลังหักส่วนลด" />
                                </td>
                                <td className="tdPrice">{numberFormatter(_.get(DataQT, 'subtotalAfterDis'))}</td>
                              </tr>
                              <tr style={{ padding: ' 5px 10px', height: '20px' }}>
                                <td className="tdPriceTextPO">
                                  <FormattedMessage id="QTPrintTitleTaxPre" defaultMessage="ภาษี" /> {_.get(DataQT, 'taxPercent')}%
                                </td>
                                <td className="tdPrice">{numberFormatter(_.get(DataQT, 'tax'))}</td>
                              </tr>
                              <tr style={{ padding: ' 5px 10px', height: '20px' }}>
                                <td className="tdPriceTextPO">
                                  <FormattedMessage id="QTPrintTitlesubAllTotal" defaultMessage="รวมทั้ั้งสิ้น" />
                                </td>
                                <td className="tdPrice">{numberFormatter(_.get(DataQT, 'totalNum'))}</td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>

                      {/* <tr style={{ borderTop: '1px solid', height: '20px' }}>
                        <td
                          colSpan="2"
                          style={{
                            fontSize: '17px',
                            borderTop: '1px solid',
                            paddingBottom: '0px',
                            padding: ' 3px 12px',
                            fontWeight: 'bold',
                          }}
                        >
                          <FormattedMessage id="PODueDate" defaultMessage="กําหนดวันส่งของ" />:
                        </td>
                        <td
                          className="tdPriceTextPO"
                          colspan="3"
                          style={{ fontSize: '17px', borderLeft: '1px solid', borderTop: '1px solid', verticalAlign: 'bottom' }}
                        >
                          <FormattedMessage id="QTPrintTitlePhoneTotalPrice" defaultMessage="ราคารวม" />
                        </td>
                        <td
                          className="tdPrice"
                          style={{ textAlign: 'right', paddingRight: '10px', borderTop: '1px solid', verticalAlign: 'bottom' }}
                        >
                          {numberFormatter(_.get(DataQT, 'subtotal'))}
                          {/* {_.get(DataQT, 'subtotalTxt')} */}
                      {/*</td>
                      </tr> */}
                      {/* <tr style={{ padding: ' 5px 10px', height: '20px' }}>
                        {/* <td
                          colSpan="2"
                          style={{
                            fontSize: '17px',
                            borderTop: '1px solid',
                            paddingBottom: '0px',
                            padding: ' 3px 12px',
                            fontWeight: 'bold',
                          }}
                        >
                          <FormattedMessage id="PODueDate" defaultMessage="กําหนดวันส่งของ" />:
                        </td> */}
                      {/*<td
                          className="tdPriceTextPO"
                          rowspan="4"
                          colSpan="2"
                          style={{ paddingTop: '1px', verticalAlign: 'top', borderRight: '1px solid' }}
                        >
                          {_.map(LoopDelivery(index), (item) => item)}
                        </td>
                        <td colspan="3" className="tdPriceTextPO">
                          <FormattedMessage id="QTPrintTitleDiscount" defaultMessage="ส่วนลด" /> {_.get(DataQT, 'discountPercent')}%
                        </td>
                        <td className="tdPrice">{numberFormatter(_.get(DataQT, 'discount'))}</td>
                      </tr>*/}

                      {/*<tr style={{ padding: ' 5px 10px', height: '20px' }}>
                        {/* <td
                          className="tdPriceTextPO"
                          rowspan="4"
                          colSpan="2"
                          style={{ paddingTop: '1px', verticalAlign: 'top', borderRight: '1px solid' }}
                        >
                          {_.map(LoopDelivery(index), (item) => item)}
                        </td> */}

                      {/*<td colspan="3" className="tdPriceTextPO">
                          <FormattedMessage id="QTPrintTitlesubTotalAfterDiscount" defaultMessage="ราคารวมหลังหักส่วนลด" />
                        </td>
                        <td className="tdPrice">{numberFormatter(_.get(DataQT, 'subtotalAfterDis'))}</td>
                      </tr>*/}

                      {/*<tr style={{ padding: ' 5px 10px', height: '20px' }}>
                        <td colspan="3" className="tdPriceTextPO">
                          <FormattedMessage id="QTPrintTitleTaxPre" defaultMessage="ภาษี" /> {_.get(DataQT, 'taxPercent')}%
                        </td>
                        <td className="tdPrice">{numberFormatter(_.get(DataQT, 'tax'))}</td>
                      </tr>

                      <tr style={{ padding: ' 5px 10px', height: '20px' }}>
                        <td colspan="2" style={{ fontSize: '17px', borderLeft: '1px solid', borderTop: '1px solid', padding: ' 5px 12px' }}>
                          <span style={{ fontWeight: 'bold' }}>
                            <FormattedMessage id="QTPrintTitlesubAllLabel" defaultMessage="ตัวอักษร" />
                          </span>
                          : {_.get(DataQT, 'total') === null ? 'ศูนย์บาทถ้วน' : ThaiNumberToText(totalNum.toFixed(2))}
                        </td>
                        <td colspan="3" className="tdPriceTextPO">
                          <FormattedMessage id="QTPrintTitlesubAllTotal" defaultMessage="รวมทั้ั้งสิ้น" />
                        </td>
                        <td className="tdPrice">{numberFormatter(_.get(DataQT, 'totalNum'))}</td>
                      </tr>
                      <tr style={{ padding: ' 6px 10px', height: '20px' }}></tr>*/}
                      <tr style={{ padding: ' 6px 10px', height: '20px' }}>
                        <td colspan="6" style={{ fontSize: '17px', borderLeft: '1px solid', borderTop: '1px solid', padding: ' 5px 12px' }}>
                          <span>
                            {' '}
                            <FormattedMessage id="QTPrintTitleRemark" defaultMessage="หมายเหตุ" />
                          </span>
                          : {_.get(DataQT, 'remark')}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </>
              )}
              <table className='page-footer' width="100%" style={{ border: '1px solid' }}>
                <tbody style={{ height: '10px' }}>
                  <tr style={{ padding: ' 5px 15px', height: '100%', width: '100%', textAlign: 'center' }}>
                    <td className="tdSignatureText">
                      <FormattedMessage id="QTPrintTitleCheck" defaultMessage="ผู้ตรวจรับสินค้า" />
                    </td>
                    <td className="tdSignatureText">
                      <FormattedMessage id="QTPrintTitleRecord" defaultMessage="ผู้บันทึกรายการ" />
                    </td>
                    <td className="tdSignatureText">
                      <FormattedMessage id="QTTitleBuy" defaultMessage="ผู้สั่งซื้อ" />
                    </td>
                    <td className="tdSignatureText">
                      <FormattedMessage id="QTPrintTitleIssue" defaultMessage="ผู้อนุมัติ" />
                    </td>
                  </tr>
                  <tr style={{ padding: ' 5px 15px', height: '100%', width: '100%', textAlign: 'center' }}>
                    <td className="tdSignatureLinePO">
                      <>
                        <div style={{ height: '10px' }} />
                        _______________________
                      </>
                    </td>
                    <td className="tdSignatureLinePO">
                      <div style={{ height: '10px' }} />
                      _______________________
                    </td>
                    <td className="tdSignatureLinePO">
                      <div style={{ height: '10px' }} />
                      _______________________
                    </td>
                    <td className="tdSignatureLinePO">
                      <div style={{ height: '10px' }} />
                      _______________________
                    </td>
                  </tr>
                  <tr style={{ padding: ' 5px 15px', height: '100%', width: '100%', textAlign: 'center' }}>
                    <td className="tdSignatureCircle">
                      (<span style={{ marginRight: '125px', marginLeft: '50px' }} />)
                    </td>
                    <td className="tdSignatureCircle">
                      (<span style={{ marginRight: '125px', marginLeft: '50px' }} />)
                    </td>
                    <td className="tdSignatureCircle">
                      (<span style={{ marginRight: '125px', marginLeft: '50px' }} />)
                    </td>
                    <td className="tdSignatureCircle">
                      (<span style={{ marginRight: '125px', marginLeft: '50px' }} />)
                    </td>
                  </tr>
                  <tr style={{ padding: ' 5px 15px', height: '100%', width: '100%', textAlign: 'center' }}>
                    <td className="tdSignatureLinePO">
                      <FormattedMessage id="QTPrintTitleDateSignature" defaultMessage="วันที่" /> ____/____/____
                    </td>
                    <td className="tdSignatureLinePO">
                      <FormattedMessage id="QTPrintTitleDateSignature" defaultMessage="วันที่" /> ____/____/____
                    </td>
                    <td className="tdSignatureLinePO">
                      <FormattedMessage id="QTPrintTitleDateSignature" defaultMessage="วันที่" /> ____/____/____
                    </td>
                    <td className="tdSignatureLinePO">
                      <FormattedMessage id="QTPrintTitleDateSignature" defaultMessage="วันที่" /> ____/____/____
                    </td>
                  </tr>
                </tbody>
              </table>{' '}
            </div>
          );
        })}
      </div>
    );
  }
}
