import React, { useState } from 'react';
import { Upload, Icon, Modal, Spin } from 'antd';
import './css/index.css';
import { errorNotification } from '../v2/notification';
import Resizer from 'react-image-file-resizer';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';

export default (props) => {
  const { setUploadStatus, uploadStatus, setUploadImg, uploadImg, setFileList, fileList } = props;

  const [visiblePreview, setVisiblePreview] = useState(false);

  const beforeUpload = async (file) => {
    const isJpgOrPng = file.type === 'image/png';

    if (!isJpgOrPng) {
      errorNotification('PNG Only');
    }
    return isJpgOrPng;
  };

  const resizeFile = (file, width, height) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        width,
        height,
        'PNG',
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        'file'
      );
    });

  const resizeFileBase64 = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(file, 10000, 10000, 'PNG', 100, 0, (uri) => {
        resolve(uri);
      });
    });

  const onChangeImg = async (file) => {
    setUploadStatus(true);
    if (file.type === 'image/png') {
      const newFile = await resizeNewFile(file);
      if (newFile) {
        const base64Img = await resizeFileBase64(newFile);
        setUploadImg(newFile);
        // setUploadStatus(false)
        setFileList([
          {
            uid: '-1',
            name: 'image.png',
            status: 'done',
            url: base64Img,
          },
        ]);
      }
    }
    setTimeout(() => {
      setUploadStatus(false);
    }, 1500);
  };

  const resizeNewFile = async (file) => {
    let newfile = file;
    let newfile1;
    const getWH = await resizeFileBase64(newfile);
    const convertData = await getImageDimensions(getWH);
    const persentage =
      _.get(file, 'size') > 10000000
        ? 70
        : _.get(newfile, 'size') < 10000000 && _.get(newfile, 'size') > 8000000
        ? 60
        : _.get(newfile, 'size') < 8000000 && _.get(newfile, 'size') > 6000000
        ? 50
        : _.get(newfile, 'size') < 6000000 && _.get(newfile, 'size') > 4000000
        ? 40
        : _.get(newfile, 'size') < 4000000 && _.get(newfile, 'size') > 2000000
        ? 30
        : 20;

    const convertW = convertData.w - (convertData.w * persentage) / 100;
    const convertH = convertData.h - (convertData.h * persentage) / 100;
    if (newfile.size > 2000000) {
      const resizeFileLoop = await resizeFile(newfile, convertW, convertH);
      newfile1 = resizeFileLoop;
      resizeNewFile(resizeFileLoop);
    } else {
      newfile1 = newfile;
    }
    return newfile1;
  };

  const getImageDimensions = (file) => {
    return new Promise(function(resolved, rejected) {
      const i = new Image();
      i.onload = function() {
        resolved({ w: i.width, h: i.height });
      };
      i.src = file;
    });
  };

  const uploadButton = (
    <div className="uploader-div-icon-empty">
      <Icon type="plus" />
      <p style={{ marginTop: '10px' }}>
        <FormattedMessage id="signatureCardButtonUpload" defaultMessage="Upload" />
      </p>
    </div>
  );

  const onPreview = () => {
    setVisiblePreview(true);
  };

  const onRemove = (file) => {
    setUploadStatus(true);
    setFileList([]);
    setUploadImg();
    setTimeout(() => {
      setUploadStatus(false);
    }, 1000);
  };

  return (
    <div className="uploader-image-div-padding">
      <Spin spinning={uploadStatus}>
        <div>
          <Upload
            name="avatar"
            listType="picture-card"
            className="uploader-image-background"
            // showUploadList={false}
            fileList={fileList}
            beforeUpload={beforeUpload}
            action={(file) => onChangeImg(file)}
            onPreview={onPreview}
            onRemove={onRemove}
            maxCount={1}
          >
            {fileList.length >= 1 ? null : uploadButton}
          </Upload>
          <p style={{ fontSize: '10px', color: '#1D3557' }}>
            <FormattedMessage
              id="modalSignatureTextDescription"
              defaultMessage="Only PNG file and recommended size: 356 x 200 pixels or 16:9 ratio"
            />
          </p>
        </div>
      </Spin>
      <Modal visible={visiblePreview} onCancel={() => setVisiblePreview(false)} footer={null} centered={true} width={'auto'}>
        <div>
          <img style={{ width: '100%', maxWidth: '500px', maxHeight: '700px' }} src={_.get(fileList, '[0].url')} />
        </div>
      </Modal>
    </div>
  );
};
